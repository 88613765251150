import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { RestApiService } from '@shared/service/restapi/RestApi.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { catchError, last, map } from 'rxjs/operators';

@Injectable()
export class SafeCountCompatiblityCheckService extends RestApiService {
    private _controller = 'safecount';
    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftRestApiBase, _httpClient);
    }

    public checkSafeCountCompatible(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.checkSafeCountCompatibleInternal(process).pipe(
            map(compatible => {
                if(isNullOrUndefined(compatible)){
                    return false;
                }

                return compatible === 'true';
            }),
            last(),
            catchError((error, caught) => of(false)));
    }

    private checkSafeCountCompatibleInternal(process?: ProcessMonitorServiceProcess): Observable<string>{
        const url = `${this._config.riftRestApiBase}local/${this._controller}/safecount_capable`;

        const req = new HttpRequest('GET', url, null, {
            responseType: 'text',
            reportProgress: true,
            withCredentials: true,
        });

        return this.request<string, null>(req, 'string', null, process, false, false, { disabled: true }) as Observable<string>;
    }
}
