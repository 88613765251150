import { Component, ElementRef, HostBinding, Injector, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FillHeightParentComponentBase } from '@rift/components/base/FillHeightParentComponentBase';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { UrlUtility } from '@shared/utility/Url.Utility';

@Component({
    selector: 'rift-settings',
    templateUrl: './Settings.Component.html',
    styleUrls: ['./Settings.Component.scss'],
})
export class SettingsComponent extends FillHeightParentComponentBase implements IFillHeight, OnInit {
    public static className: string = 'SettingsComponent';

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @HostBinding()
    public id: string = 'rift-settings';

    public bluetoothRelativeUrl: string = null;
    public configHistoryRelativeUrl: string = null;
    public countingRelativeUrl: string = null;
    public countLoggingRelativeUrl: string = null;
    public httpPostRelativeUrl: string = null;
    public mqttRelativeUrl: string = null;
    public networkRelativeUrl: string = null;
    public offsetTop: 48;
    public outboundConnectionsRelativeUrl: string = null;
    public relaysRelativeUrl: string = null;
    public securityRelativeUrl: string = null;
    public siteRelativeUrl: string = null;
    public videoRelativeUrl: string = null;
    public wideTrackerRelativeUrl: string = null;
    public bacnetRelativeUrl: string = null;

    public constructor(
        private readonly _renderer: Renderer2,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_renderer, _injector, _dialog);

        this.networkRelativeUrl = UrlUtility.getRelativeUrl(['network'], this._router, this._activatedRoute);
        this.siteRelativeUrl = UrlUtility.getRelativeUrl(['site'], this._router, this._activatedRoute);
        this.outboundConnectionsRelativeUrl = UrlUtility.getRelativeUrl(['outboundconnections'], this._router, this._activatedRoute);
        this.securityRelativeUrl = UrlUtility.getRelativeUrl(['security'], this._router, this._activatedRoute);
        this.countLoggingRelativeUrl = UrlUtility.getRelativeUrl(['countlogging'], this._router, this._activatedRoute);
        this.bluetoothRelativeUrl = UrlUtility.getRelativeUrl(['bluetooth'], this._router, this._activatedRoute);
        this.httpPostRelativeUrl = UrlUtility.getRelativeUrl(['httppost'], this._router, this._activatedRoute);
        this.relaysRelativeUrl = UrlUtility.getRelativeUrl(['relays'], this._router, this._activatedRoute);
        this.countingRelativeUrl = UrlUtility.getRelativeUrl(['counting'], this._router, this._activatedRoute);
        this.videoRelativeUrl = UrlUtility.getRelativeUrl(['video'], this._router, this._activatedRoute);
        this.wideTrackerRelativeUrl = UrlUtility.getRelativeUrl(['widetracker'], this._router, this._activatedRoute);
        this.mqttRelativeUrl = UrlUtility.getRelativeUrl(['mqtt'], this._router, this._activatedRoute);
        this.configHistoryRelativeUrl = UrlUtility.getRelativeUrl(['history'], this._router, this._activatedRoute);
        this.bacnetRelativeUrl = UrlUtility.getRelativeUrl(['bacnet'], this._router, this._activatedRoute);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }
}
