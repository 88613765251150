import { Injectable } from '@angular/core';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { RegisterBaseCollectionModel } from '@rift/models/restapi/RegisterBaseCollection.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiRegistersService } from '@rift/service/restapi/v1/RestApi.Registers.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RegisterActionInfoCollectionModel } from '@rift/models/restapi/RegisterActionInfoCollection.Model';

@Injectable()
export class RegisterService extends RiftBaseService {
    private _registersCache: RegisterBaseCollectionModel = null;
    private _addRegisterLoadingTracker = new ObservableTracker<ResultModel>();
    private _copyRegisterLoadingTracker = new ObservableTracker<ResultModel>();
    private _deleteRegisterLoadingTracker = new ObservableTracker<ResultModel>();
    private _getRegistersLoadingTracker = new ObservableTracker<RegisterBaseCollectionModel>();
    private _updateRegisterLoadingTracker = new ObservableTracker<RegisterBaseModel | ResultModel>();
    private _modifyRegisterValueLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiRegistersService: RestApiRegistersService) {
        super();
    }

    public getActions(process?: ProcessMonitorServiceProcess): Observable<RegisterActionInfoCollectionModel> {
        return this._restApiRegistersService.getActions(process);
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._registersCache = null;
    }

    public setCache(registersCache: RegisterBaseCollectionModel): Observable<boolean> {
        this._registersCache = registersCache;
        return of(true);
    }

    public addRegister(register: RegisterBaseModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._addRegisterLoadingTracker
            .getLoading(register)
            .observable(this._restApiRegistersService.addRegister(register, process).pipe(
                tap(result => {
                    this._registersCache = null;
                })
            ));
    }

    public copyRegister(id: number, name: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._copyRegisterLoadingTracker
            .getLoading(id, name)
            .observable(this._restApiRegistersService.copyRegister(id, name, process).pipe(
                tap(result => {
                    this._registersCache = null;
                })
            ));
    }

    public deleteRegister(id: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._deleteRegisterLoadingTracker
            .getLoading(id)
            .observable(this._restApiRegistersService.deleteRegister(id, process).pipe(
                tap(result => {
                    if (isNullOrUndefined(result) || isNullOrUndefined(result.error)) {
                        this._registersCache = null;
                    }
                })
            ));
    }

    public getRegisters(process?: ProcessMonitorServiceProcess): Observable<RegisterBaseCollectionModel> {
        if (isNullOrUndefined(this._registersCache)) {
            return this._getRegistersLoadingTracker
                .getLoading()
                .observable(this._restApiRegistersService.getRegisters(process).pipe(
                    map(result => {
                        this._registersCache = result;
                        return this._registersCache;
                    })
                ));
        } else {
            return of(this._registersCache);
        }
    }

    public updateRegisters(registers: RegisterBaseModel[], process?: ProcessMonitorServiceProcess): Observable<RegisterBaseModel | ResultModel> {
        return this._updateRegisterLoadingTracker
            .getLoading(registers)
            .observable(this._restApiRegistersService.updateRegisters(registers, process).pipe(
                tap(result => {
                    this._registersCache = null;
                })
            ));
    }

    public updateRegister(register: RegisterBaseModel, process?: ProcessMonitorServiceProcess): Observable<RegisterBaseModel | ResultModel> {
        return this._updateRegisterLoadingTracker
            .getLoading(register)
            .observable(this._restApiRegistersService.updateRegister(register, process).pipe(
                tap(result => {
                    this._registersCache = null;
                })
            ));
    }

    public modifyRegisterValue(id: number, incrementDecrement: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._modifyRegisterValueLoadingTracker
            .getLoading(id, incrementDecrement)
            .observable(this._restApiRegistersService.modifyRegisterValue(id, incrementDecrement, process));
    }
}
