<div class="shr-navbar">
    <mat-toolbar class="toolbar">
        <mat-toolbar-row class="row">
            <button *ngIf="showMenuDrawerToggle" mat-button class="menu-button" (click)="menuDrawerToggle()">
                <mat-icon>menu</mat-icon>
            </button>

            <div class="header">
                <a fxLayout="row" (click)="onHomeClick()">
                    <mat-icon *ngIf="showHomeIcon === true" class="home-icon">
                        homes
                    </mat-icon>
                    <ng-template [ngIf]="!isNullOrUndefined(text) && showHomeIcon === true">
                        <span class="title" fxShow.gt-md="true" fxHide>
                            {{ text }}
                        </span>
                    </ng-template>
                    <ng-template [ngIf]="!isNullOrUndefined(text) && showHomeIcon === false">
                        <span [ngClass]="{ 'spacing-left': showHomeIcon === false }" class="title">
                            {{ text }}
                        </span>
                    </ng-template>
                    <ng-template [ngIf]="showLogo === true">
                        <img class="spacing-left spacing-right" [src]="logoSrc" [style.width.px]="logoWidth" [style.height.px]="logoHeight">
                    </ng-template>
                </a>
            </div>

            <div fxShow.gt-sm="true" fxHide class="pre-breadcrumb-text" fxLayout="row">
                {{ preBreadCrumbText }}
            </div>

            <div fxShow.gt-sm="true" fxHide class="breadcrumb" fxLayout="row">
                <shr-breadcrumb></shr-breadcrumb>
            </div>

            <div fxShow.gt-sm="true" fxHide class="post-breadcrumb-text" fxLayout="row">
                {{ postBreadCrumbText }}
            </div>

            <div fxFlex>
            </div>

            <div flLayout="row" class="license-state spacing-right">
                <div class="error" *ngIf="emLicenseServerUnavailable === true">
                    License Server Unavailable
                </div>
                <div class="error" *ngIf="emLicenseServerUnavailable === false && emUnLicensed === true">
                    Your License Has Expired
                </div>
                <div class="error" *ngIf="emLicenseServerUnavailable === false && emDeviceUnLicensed === true">
                    Licence Device Limit Exceeded.
                </div>
            </div>

            <div *ngIf="emLicenseServerUnavailable === false && emUnLicensed === false && emDeviceUnLicensed === false && showRiftInfo === true"
                class="connection-state spacing-right" flLayout="row" fxLayoutAlign="center center">
                <div class="spacing-right"
                    matTooltip="Database view shows the last know state of the device from the EM database.">
                    <div fxHide fxShow.lt-xl="true">
                        DB
                    </div>
                    <div fxHide fxShow.gt-lg="true">
                        Database View
                    </div>
                </div>
                <mat-slide-toggle
                    matTooltip="{{'You are currently in ' + (riftConnectionState === ConnectionStatusEnum.online ? 'Live View' : 'Database View')}}"
                    [checked]="riftConnectionState === ConnectionStatusEnum.online"
                    (change)="riftConnectionStateToggle($event)">
                </mat-slide-toggle>
                <div class="spacing-left"
                    matTooltip="Live view connects you directly to the device all data is shown in real time">
                    <div fxHide fxShow.lt-xl="true">
                        Live
                    </div>
                    <div fxHide fxShow.gt-lg="true">
                        Live View
                    </div>
                </div>
            </div>

            <div *ngIf="!isNullOrUndefined(emDeviceConnected)" class="link-state spacing-right"
                matTooltip="Connection state" flLayout="row" fxLayoutAlign="center center">
                <img [src]="emDeviceConnected === true ? './assets/connected.png' : './assets/disconnected.png'"
                    [matTooltip]="emDeviceConnected === true ? 'Device Connected To Estate Manager' : 'Device Disconnected From Estate Manager'" />
            </div>

            <div class="spacing-right" matTooltip="Number Of Connections To Device"
                *ngIf="showRiftInfo === true && !isNullOrUndefined(connectionCount)" flLayout="row"
                fxLayoutAlign="center center">
                <span class="spacing-right">
                    {{ connectionCount }}
                </span>
                <shr-fa style="margin-bottom: 4px;" name="users"></shr-fa>
            </div>

            <div class="spacing-right" matTooltip="When the device was last connected"
                *ngIf="emDeviceConnected === false" flLayout="row"
                fxLayoutAlign="center center">
                <span class="spacing-right">
                    Last seen: {{ emLastConnectedText }}
                </span>
            </div>

            <div *ngIf="showNotification === true" class="spacing-right">
                <button mat-icon-button [style.color]="bottomInfoStateColor"
                    [matTooltip]="(userNotificationService.bottomInfoVisible ? 'Hide' : 'Show') + ' Notifications'"
                    (click)="userNotificationService.toggleShowHideBottomInfo()">
                    <mat-icon>info</mat-icon>
                </button>
            </div>

            <div *ngIf="autoActions.length === 0 || showVersion === true" class="spacing-right version-number">
                Ver: {{ versionService.uiVersion + '/' + versionService.appVersion }}
            </div>

            <div>
                <ng-template [ngIf]="!isNullOrUndefined(saveAllChangesAction)">
                    <button id="navbar-saveAllChanges-button" class="spacing-right"
                        [disabled]="saveAllChangesAction?.disabled"
                        (click)="saveAllChangesClicked()" mat-raised-button>
                        <span fxShow.lt-sm="true" fxHide>Save</span>
                        <span fxHide fxShow.gt-xs="true">Save Changes</span>
                    </button>
                </ng-template>

                <ng-template [ngIf]="autoActions.length === 1">
                    <button *ngIf="!firstAutoAction.hasMenuComponent" [disabled]="firstAutoAction.disabled"
                        (click)="actionButtonClicked($event, firstAutoAction)" mat-raised-button>
                        {{ firstAutoAction.text }}
                    </button>

                    <button *ngIf="firstAutoAction.hasMenuComponent" [disabled]="firstAutoAction.disabled"
                        (click)="actionButtonClicked($event, firstAutoAction)" mat-raised-button
                        [matMenuTriggerFor]="actionMenu">
                        {{ firstAutoAction.text }}
                    </button>
                </ng-template>

                <ng-template [ngIf]="autoActions.length > 1">
                    <button *ngIf="isNullOrUndefined(actionsMatIcon)" mat-raised-button
                        [matMenuTriggerFor]="actionMenu">
                        Actions
                    </button>
                    <button *ngIf="!isNullOrUndefined(actionsMatIcon)" mat-icon-button [matMenuTriggerFor]="actionMenu">
                        <mat-icon>{{ actionsMatIcon }}</mat-icon>
                    </button>
                </ng-template>

                <mat-menu #actionMenu="matMenu" hasBackdrop="true">
                    <button [disabled]="actionButton.disabled" (click)="actionButtonClicked($event, actionButton)"
                        *ngFor="let actionButton of actionButtons" mat-menu-item>
                        <mat-icon *ngIf="!isNullOrUndefined(actionButton.matIconName)">{{ actionButton.matIconName }}
                        </mat-icon>
                        <shr-fa *ngIf="!isNullOrUndefined(actionButton.faIconName)" [name]="actionButton.faIconName">
                        </shr-fa>
                        {{actionButton.text}}
                    </button>
                    <ng-template #actionHost></ng-template>
                    <mat-divider *ngIf="showMenuVersion"></mat-divider>
                    <div *ngIf="showMenuVersion" class="version-number-menu">
                        Ver: {{ versionService.uiVersion + '/' + versionService.appVersion }}
                    </div>
                </mat-menu>
            </div>
        </mat-toolbar-row>
        <mat-toolbar-row class="row" fxHide fxShow.lt-md="true">
            <div class="spacing-left" fxLayout="row" style="overflow: hidden;">
                <div class="pre-breadcrumb-text" fxLayout="row">
                    {{ preBreadCrumbText }}
                </div>

                <div class="breadcrumb" fxLayout="row">
                    <shr-breadcrumb></shr-breadcrumb>
                </div>

                <div class="post-breadcrumb-text" fxLayout="row">
                    {{ postBreadCrumbText }}
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
