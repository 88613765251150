export enum DeviceErrorTypeEnum {
    set = 1,
    clear = 2,
    stillAt = 3,
    validateHeightsXandY = 4,
    checkUnitPositions = 5,
    fatalError = 6,
    information = 7,
    warning = 8,
}

export class DeviceErrorTypeEnumHelpers {
    public static toStringHumanized(type: DeviceErrorTypeEnum): string {
        switch (type) {
            case DeviceErrorTypeEnum.set:
                return 'Set';
            case DeviceErrorTypeEnum.clear:
                return 'Clear';
            case DeviceErrorTypeEnum.stillAt:
                return 'Still At';
            case DeviceErrorTypeEnum.validateHeightsXandY:
                return 'Height & Positions';
            case DeviceErrorTypeEnum.checkUnitPositions:
                return 'Relative Positions';
            case DeviceErrorTypeEnum.fatalError:
                return 'Fatal Error';
            case DeviceErrorTypeEnum.information:
                return 'Information';
            case DeviceErrorTypeEnum.warning:
                return 'Warning';
        }
    }
}
