import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IGlobalHeightFilterModel extends IRestModel {
    lowerBound: number;
    upperBound: number;
}

export class GlobalHeightFilterModel extends BaseModel implements IGlobalHeightFilterModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public lowerBound: number = null;
    public upperBound: number = null;

    public constructor() {
        super();

        this.registerProperty('lowerBound');
        this.registerProperty('upperBound');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-GlobalHeightFilterModel');

        this.setPropertyOriginalValue('lowerBound', this.lowerBound);
        this.setPropertyOriginalValue('upperBound', this.upperBound);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
