<div class="em-myaccount main-content">
    <div fxLayout="row"
        class="spacing-bottom">
        <mat-card fxFlex
            class="spacing-right">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <span>{{user?.realName}}</span>
                <div fxFlex></div>
                <button
                    mat-raised-button
                    (click)="editDetails()"
                    class="spacing-right">Edit Details</button>
                <button *ngIf="user?.authType === AuthTypeEnum.system"
                    mat-raised-button
                    (click)="changePassword()">Change Password</button>
            </mat-card-title>
            <mat-card-content class="user-details">
                <span class="mat-subheading-1"
                    *ngIf="isEmptyOrWhiteSpace(user?.jobTitle) && isEmptyOrWhiteSpace(user?.organisation) && isEmptyOrWhiteSpace(user?.email)">Add
                    more information about yourself</span>
                <span class="mat-subheading-1"
                    *ngIf="!isEmptyOrWhiteSpace(user?.jobTitle)">{{ user?.jobTitle }}</span>
                <span class="mat-subheading-1"
                    *ngIf="!isEmptyOrWhiteSpace(user?.organisation)">{{ user?.organisation }}</span>
                <span class="mat-subheading-1"
                    *ngIf="!isEmptyOrWhiteSpace(user?.email)">{{ user?.email }}</span>
            </mat-card-content>
        </mat-card>        
    </div>
    <div fxLayout="row">
        <mat-card fxFlex
            class="spacing-right">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <span>API Access Information</span>
                <div fxFlex></div>
                <button *ngIf="user?.authType === AuthTypeEnum.system"
                    mat-raised-button
                    (click)="getRestApiKey()">Reset API Key</button>
            </mat-card-title>
            <mat-card-content>
                <div fxLayout="column">
                    <div>
                        <span class="mat-subheading-1 header">
                            Hostname:
                        </span>
                        <span class="mat-subheading-1 value">
                            {{hostname}}
                        </span>
                    </div>
                    <div>
                        <span class="mat-subheading-1 header">
                            Port:
                        </span>
                        <span class="mat-subheading-1 value">
                            {{port}}
                        </span>
                    </div>
                    <div *ngIf="user?.authType === AuthTypeEnum.system">
                        <span class="mat-subheading-1 header">
                            API Key:
                        </span>
                        <span class="mat-subheading-1 value">
                            {{user?.webAPIKey}}
                        </span>
                    </div>
                    <div *ngIf="user?.authType === AuthTypeEnum.openIDConnect">
                        <span class="mat-subheading-1 header">
                            Authenticate via Oauth 2
                        </span>                        
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card fxFlex
            class="card">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <span>Estate Manager API</span>
                <div fxFlex></div>
                <button mat-raised-button
                    routerLink="/documentation/restapi">API Documentation</button>
            </mat-card-title>
            <mat-card-content>
                <span class="mat-subheading-1">Estate Manager has a built in Web API that can be used to retrieve data and interact with the service.
                    Requests are
                    restricted by an API key, only accessible by Estate Manager users. Please view the API Documentation to learn more.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>