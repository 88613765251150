<div #mainContent id="rift-recordings" class="main-content scroll-content rift-recordings">
    <mat-card
        *ngIf="!isNullOrUndefined(videoStorageCapacity) && (videoStorageCapacity.noSDCard === true || videoStorageCapacity.sdCardNotFormatted === true || videoStorageCapacity.storageError === true)">
        <mat-card-title>
            <ng-template
                [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.storageWarning === true">
                <div class="warning">Warning: Storage is getting low</div>
            </ng-template>
            <ng-template [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.noSDCard === true">
                <div class="error">No video storage present</div>
            </ng-template>
            <ng-template
                [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.sdCardNotFormatted === true">
                <div class="error">Video storage not formatted </div>
            </ng-template>
            <ng-template [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.storageError === true">
                <div class="error">Not enough storage to record</div>
            </ng-template>
        </mat-card-title>
    </mat-card>

    <ng-template
        [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.noSDCard === false && videoStorageCapacity.sdCardNotFormatted === false">
        <div fxLayout="column">
            <ng-template ngFor let-sessionType [ngForOf]="showSessionTypes">
                <shr-table *ngIf="sessionType.type === 'schedule' || sessionType.type === 'onDevice' || (sessionType.type === 'local' && connectionService.isOnDevice === false)" matSort matSortDisableClear #sessionSort="matSort" [sort]="sessionSort" class="spacing-bottom"
                    [dataSource]="sessionType.dataSource" (filter)="applyFilter($event, sessionType.dataSource)"
                    [timeSetup]="timeSetup" [displayedColumns]="sessionType.displayedColumns"
                    [dataExportEnabled]="false">
                    <ng-template #shrTableHeader>
                        {{ sessionType.title }} ({{sessionType.dataSource.data?.length}})
                    </ng-template>

                    <ng-template #shrTableHeaderActions>
                        <ng-template [ngIf]="sessionType.type === 'schedule'">
                            <button mat-raised-button (click)="scheduledVideo_Add()"
                                *ngIf="(userIsInstaller | async) === true">Add Schedule</button>
                        </ng-template>
                        <ng-template [ngIf]="sessionType.type === 'onDevice'">

                        </ng-template>
                        <ng-template [ngIf]="sessionType.type === 'local' && connectionService.isOnDevice === false">

                        </ng-template>
                    </ng-template>

                    <ng-template #shrTableActions let-vm>
                        <ng-template [ngIf]="sessionType.type === 'schedule'">
                            <button
                                *ngIf="(userIsInstaller | async) === true && vm.isAnySessionTypeProcessing(sessionType.type) === false"
                                matTooltip="Cancel" mat-icon-button (click)="scheduledVideo_Cancel(vm)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template [ngIf]="sessionType.type === 'onDevice'">
                            <button
                                *ngIf="(userIsInstaller | async) === true && vm.isAnySessionTypeProcessing(sessionType.type) === false && vm.isAnySessionTypeActive(sessionType.type) === false && vm.isAllDevicesPresent === true"
                                matTooltip="Delete On Device Recording" mat-icon-button (click)="onDevice_Delete(vm)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button
                                *ngIf="connectionService.isOnDevice === false && vm.isAnySessionTypeProcessing(sessionType.type) === false && videos_AnyActive === false && vm.isAnySessionTypeActive(sessionType.type) === false && vm.isAnySessionTypePaused(sessionType.type) === false && vm.isAllSessionTypeComplete(sessionType.type) === false && vm.isAllRecordingsPresent"
                                matTooltip="Synchronize" mat-icon-button (click)="onDevice_Download(vm)">
                                <mat-icon>cloud_download</mat-icon>
                            </button>
                            <button
                                *ngIf="(userIsInstaller | async) === true && connectionService.isOnDevice === false && vm.isAnySessionTypeProcessing(sessionType.type) === false && vm.isAllSessionTypeComplete(sessionType.type) === false && vm.isAnySessionTypePaused(sessionType.type) === true"
                                matTooltip="Resume Synchronization" mat-icon-button
                                (click)="onDevice_Download_Resume(vm)">
                                <mat-icon>cloud_download</mat-icon>
                            </button>
                            <button
                                *ngIf="(userIsInstaller | async) === true && connectionService.isOnDevice === false && vm.isAnySessionTypeProcessing(sessionType.type) === false && vm.isAnySessionTypeActive(sessionType.type) === true && vm.isAnySessionTypePaused(sessionType.type) === false"
                                matTooltip="Cancel Synchronization" mat-icon-button
                                (click)="onDevice_Download_Cancel(vm)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                            <button
                                *ngIf="(userIsInstaller | async) === true && connectionService.isOnDevice === false && vm.isAnySessionTypeProcessing(sessionType.type) === false && vm.isAnySessionTypeActive(sessionType.type) === true && vm.isAnySessionTypePaused(sessionType.type) === false"
                                matTooltip="Pause Synchronization" mat-icon-button
                                (click)="onDevice_Download_Pause(vm)">
                                <mat-icon>pause</mat-icon>
                            </button>
                            <button
                                *ngIf="connectionService.isOnDevice && vm.isAnySessionTypeProcessing(sessionType.type) === false && vm.isAllDevicesPresent === true"
                                matTooltip="Validate" mat-icon-button (click)="scheduledVideo_Validate(vm)">
                                <mat-icon>videocam</mat-icon>
                            </button>
                        </ng-template>
                        <ng-template [ngIf]="sessionType.type === 'local' && connectionService.isOnDevice === false">
                            <button matTooltip="Delete Synchronized Recording" mat-icon-button
                                *ngIf="(userIsInstaller | async) === true && vm.isAnySessionTypeProcessing(sessionType.type) === false && vm.isAnySessionTypeQueued(sessionType.type) === false && vm.isAnySessionTypeActive(sessionType.type) === false && vm.isAnySessionTypePaused(sessionType.type) === false"
                                (click)="local_Delete(vm)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button
                                *ngIf="vm.isAnySessionTypeProcessing(sessionType.type) === false && vm.isAllSessionTypeComplete(sessionType.type) === true"
                                matTooltip="Validate" mat-icon-button (click)="scheduledVideo_Validate(vm)">
                                <mat-icon>videocam</mat-icon>
                            </button>
                        </ng-template>
                    </ng-template>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="stateText">State</th>
                        <td mat-cell *matCellDef="let vm" style="width: 100px;" [matTooltip]="vm.stateText">
                            {{vm.stateText}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="devices">
                        <th mat-header-cell *matHeaderCellDef>
                            Devices
                        </th>
                        <td mat-cell *matCellDef="let vm" style="width: 100px;">
                            <table class="devices-table">
                                <tr>
                                    <td>
                                        {{ vm.device.serialNumber }} (Master)
                                    </td>
                                </tr>
                                <tr *ngFor="let nodeVM of vm.nodeVideos">
                                    <td>
                                        {{ nodeVM.serialInfo() }}
                                        (Node)
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="download">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="isSynced">Synchronize</th>
                        <td mat-cell *matCellDef="let vm" style="width: 80px; padding-right: 10px; padding-left: 10px;">
                            <table class="devices-table">
                                <tr>
                                    <td>
                                        <span matTooltip="{{vm.synchronizeText}}">
                                            {{ vm.synchronizeText }}
                                        </span>
                                    </td>
                                </tr>
                                <tr *ngFor="let nodeVM of vm.nodeVideos">
                                    <td>
                                        <span matTooltip="{{nodeVM.synchronizeText}}">
                                            {{ nodeVM.synchronizeText }}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="progress">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="progress">Progress</th>
                        <td mat-cell *matCellDef="let vm" style="width: 80px; padding-right: 10px; padding-left: 10px;">
                            <table class="devices-table" *ngIf="vm.isAnySessionTypeActive(sessionType.type) === true">
                                <tr>
                                    <td *ngIf="vm.isThisSessionTypeActive(sessionType.type) === true">
                                        <mat-progress-bar *ngIf="vm.sessionTypeProgress(sessionType.type) !== 0"
                                            mode="determinate" [value]="vm.sessionTypeProgress(sessionType.type)">
                                        </mat-progress-bar>
                                        <mat-progress-bar *ngIf="vm.sessionTypeProgress(sessionType.type) === 0"
                                            mode="indeterminate" [value]="vm.sessionTypeProgress(sessionType.type)">
                                        </mat-progress-bar>
                                    </td>
                                </tr>
                                <tr *ngFor="let nodeVM of vm.nodeVideos">
                                    <td *ngIf="nodeVM.isThisSessionTypeActive(sessionType.type) === true">
                                        <mat-progress-bar *ngIf="nodeVM.sessionTypeProgress(sessionType.type) !== 0"
                                            mode="determinate" [value]="nodeVM.sessionTypeProgress(sessionType.type)">
                                        </mat-progress-bar>
                                        <mat-progress-bar *ngIf="nodeVM.sessionTypeProgress(sessionType.type) === 0"
                                            mode="indeterminate" [value]="nodeVM.sessionTypeProgress(sessionType.type)">
                                        </mat-progress-bar>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="startDate"> Date </th>
                        <td mat-cell *matCellDef="let vm">
                            <shr-table-cell dateFormat="shortDateFormat" [data]="vm" [timeSetup]="timeSetup"
                                keyPath="startDate"></shr-table-cell>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="start">
                        <th mat-header-cell *matHeaderCellDef> Start </th>
                        <td mat-cell *matCellDef="let vm">
                            <shr-table-cell dateFormat="shortTimeFormat" [data]="vm" [timeSetup]="timeSetup"
                                keyPath="startTime"></shr-table-cell>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="duration">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="durationText"> Duration </th>
                        <td mat-cell *matCellDef="let vm">
                            <shr-table-cell [data]="vm" keyPath="durationText"></shr-table-cell>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="end">
                        <th mat-header-cell *matHeaderCellDef> End </th>
                        <td mat-cell *matCellDef="let vm">
                            <shr-table-cell dateFormat="shortTimeFormat" [data]="vm" [timeSetup]="timeSetup"
                                keyPath="endTime"></shr-table-cell>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="thumb">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let vm" style="width: 75px; text-align: center;">
                            <shr-process-running [diameter]="30" [process]="vm.frameResultLoadingProcess">
                                <img *ngIf="!isNullOrUndefined(vm.imageDataUri)" class="thumb"
                                    [src]="isNullOrUndefined(vm.imageDataUri) ? 'assets/blank.png' : vm.imageDataUri">
                            </shr-process-running>
                        </td>
                    </ng-container>
                </shr-table>
            </ng-template>
        </div>
    </ng-template>
</div>