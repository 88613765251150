import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IVideoStatusModel extends IRestModel {
    isRecording: boolean;
    sessionsCount: number;
    recordingProgress: number;
}

export class VideoStatusModel extends BaseModel implements IVideoStatusModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public isRecording: boolean = null;
    public sessionsCount: number = null;
    public recordingProgress: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-VideoStatusModel');
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
