import { IFrameStore } from '@rift/service/validation/database/syncrecording/IFrameStore';
import { IObjectStore } from '@shared/service/Indexeddb/IObjectStore';

export interface IDatabaseVideoFramesStore extends IFrameStore {
}

export class DatabaseVideoFramesStore implements IObjectStore {
    public static readonly keyPath: string = 'id';
    public static readonly recordingIdBlockIndex: string = 'RecordingIdBlockIndex';
    public static readonly recordingIdFrameNumberIndex: string = 'RecordingIdFrameNumberIndex';
    public static readonly recordingIdIndex: string = 'RecordingIdIndex';
    public static readonly recordingIdTimestamp: string = 'RecordingIdTimestamp';
    public static readonly storeDisplayName: string = 'Video';
    public static readonly storeName: string = 'video';

    public get storeName(): string {
        return DatabaseVideoFramesStore.storeName;
    };

    public static toInterface(): IDatabaseVideoFramesStore {
        return {
            storeName: this.storeName,
            keyPath: this.keyPath,
            recordingIdFrameNumberIndex: this.recordingIdFrameNumberIndex,
            recordingIdIndex: this.recordingIdIndex,
            recordingIdBlockIndex: this.recordingIdBlockIndex,
        } as IDatabaseVideoFramesStore;
    }

    public createStores(db: IDBDatabase): void {
        const store: IDBObjectStore = db.createObjectStore(DatabaseVideoFramesStore.storeName, { keyPath: DatabaseVideoFramesStore.keyPath, autoIncrement: true });
        store.createIndex(DatabaseVideoFramesStore.recordingIdFrameNumberIndex, ['recordingId', 'frameNumber'], { unique: true });
        store.createIndex(DatabaseVideoFramesStore.recordingIdBlockIndex, ['recordingId', 'block']);
        store.createIndex(DatabaseVideoFramesStore.recordingIdIndex, 'recordingId');
    }
}
