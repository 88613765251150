import { FocusMonitor } from '@angular/cdk/a11y';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ComponentRef, ElementRef, EventEmitter, Injector, Input, OnDestroy, Optional, Output, Self, ViewChild, HostBinding } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { AddressBookAutocompleteTreeContainerComponent, TREE_CONTAINER_DATA, TreeContainerData } from '@em/components/shared/addressbookautocomplete/treecontainer/AddressBookAutocomplete.TreeContainer.Component';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { GroupService } from '@em/service/data/group/Group.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { Observable, Subject, Subscription, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNumeric } from '@shared/utility/General.Utility';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'em-address-book-auto-complete',
    templateUrl: './AddressBookAutocomplete.Component.html',
    styleUrls: ['./AddressBookAutocomplete.Component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: AddressBookAutocompleteComponent
        }
    ]
})
export class AddressBookAutocompleteComponent extends BaseComponent implements MatFormFieldControl<number>, ControlValueAccessor, OnDestroy, ILoadDate {
    public static className: string = 'AddressBookAutocompleteComponent';

    @Output()
    public groupSelected: EventEmitter<GroupModel> = new EventEmitter();

    @Input()
    public id: string;

    @ViewChild('groupNameInput', { static: true })
    public groupName: ElementRef;

    public autofilled: boolean = false;
    public backdropClickProcess: ProcessMonitorServiceProcess;
    public controlType?: string = 'addressbook-group';
    public describedBy: string = '';
    public focusMonitorProcess: ProcessMonitorServiceProcess;
    public focused = false;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;
    public getAddressBookGroupsProcess: ProcessMonitorServiceProcess;
    public groupNameChangeProcess: ProcessMonitorServiceProcess;
    public groupNameCtrl: FormControl;
    public itemClickProcess: ProcessMonitorServiceProcess;
    public onChange: (any) => any;
    public onTouched: (any) => any;
    public root: GroupModel;
    public stateChanges = new Subject<void>();

    private _disabled = false;
    private _flatGroups: Array<GroupModel> = new Array<GroupModel>();
    private _isDropDownOpen: boolean = false;
    private _overlayRef: OverlayRef;
    private _placeholder: string = 'Group';
    private _required = false;
    private _treeCompRef: ComponentRef<AddressBookAutocompleteTreeContainerComponent>;
    private _value: number;
    private _valueChangesSub: Subscription;

    public constructor(
        private readonly _dialog: MatDialog,
        @Optional() @Self() public ngControl: NgControl,
        private readonly _focusMonitor: FocusMonitor,
        private readonly _elementRef: ElementRef,
        private readonly _groupService: GroupService,
        private readonly _overlay: Overlay,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.groupNameChangeProcess = this.processMonitorService.getProcess(AddressBookAutocompleteComponent.className, 'Group name change');
        this.itemClickProcess = this.processMonitorService.getProcess(AddressBookAutocompleteComponent.className, 'Item click');
        this.focusMonitorProcess = this.processMonitorService.getProcess(AddressBookAutocompleteComponent.className, 'Focus monitor');
        this.backdropClickProcess = this.processMonitorService.getProcess(AddressBookAutocompleteComponent.className, 'Backdrop click');
        this.formValuesChangeProcess = this.processMonitorService.getProcess(AddressBookAutocompleteComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(AddressBookAutocompleteComponent.className, this.loadDataProcessText);
        this.getAddressBookGroupsProcess = this.processMonitorService.getProcess(AddressBookAutocompleteComponent.className, 'Getting address book groups.');

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        this.addSubscription(this.observableHandlerBase(this._focusMonitor.monitor(this._elementRef.nativeElement, true), this.focusMonitorProcess).subscribe((origin) => {
            this.focused = !!origin;
            this.stateChanges.next();
        }), this.focusMonitorProcess);

        this.groupNameCtrl = new FormControl();

        this.addSubscription(this.observableHandlerBase(this.groupNameCtrl.valueChanges, this.formValuesChangeProcess).subscribe(v => {
            if (this.isEmptyOrWhiteSpace(v)) {
                this.onGroupSelected();
            }
        }), this.formValuesChangeProcess);

        this.loadDataStartBase(this);
    }

    public closeDropdown(): void {
        this._isDropDownOpen = false;
        this._overlayRef.dispose();
    }

    @Input()
    public get disabled() {
        return this._disabled;
    }
    public set disabled(value: boolean) {
        this._disabled = value;
        this.stateChanges.next();
    }

    public get empty(): boolean {
        return this.isNullOrUndefined(this.value);
    }

    public get errorState(): boolean {
        return !this.ngControl.valid;
    }

    public getGroup(groupId: number): GroupModel {
        if (!this.isNullOrUndefined(this._flatGroups)) {
            const group = this._flatGroups.find(i => i.id === groupId);
            if (!this.isNullOrUndefined(group)) {
                return group;
            }
        }
    }

    public getGroupName(groupId: number): string {
        if (!this.isNullOrUndefined(groupId)) {
            const group = this.getGroup(groupId);
            if (!this.isNullOrUndefined(group)) {
                return group.name;
            }
        }
        return '';
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._groupService.getNested(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.root = result;
                        this._flatGroups = ArrayUtility.flatten([], this.root);

                        const rootIndex = this._flatGroups.findIndex(g => g.id === this.root.id);
                        if (rootIndex !== -1) {
                            this._flatGroups.splice(rootIndex, 1);
                        }

                        this.clearfilter();
                        this.subValueChanges();
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
    }

    public onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this._elementRef.nativeElement.querySelector('input').focus();
        }
    }

    public openDropdown(): void {
        this._isDropDownOpen = true;

        const connectedToElementRef = this.groupName;
        const overlayPosition = this._overlay.position();
        const connectedPositionStrategy = overlayPosition
            .flexibleConnectedTo(connectedToElementRef)
            .withPositions([{ originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' }]);

        this._overlayRef = this._overlay.create({
            minWidth: '200px',
            width: connectedToElementRef.nativeElement.width,
            positionStrategy: connectedPositionStrategy,
            scrollStrategy: this._overlay.scrollStrategies.reposition(),
            backdropClass: 'cdk-overlay-backdrop',
            hasBackdrop: true,
        });

        this.addSubscription(this.observableHandlerBase(this._overlayRef.backdropClick(), this.backdropClickProcess).subscribe(() => {
            this.closeDropdown();
        }), this.backdropClickProcess);


        const data = new TreeContainerData();
        data.root = this.root;

        const componentPortal = new ComponentPortal(AddressBookAutocompleteTreeContainerComponent, null, this.createInjector(data));

        this._treeCompRef = this._overlayRef.attach(componentPortal);
        this._treeCompRef.instance.tree.expandAll();

        this.addSubscription(this.observableHandlerBase(this._treeCompRef.instance.itemClicked, this.itemClickProcess).subscribe((group: GroupModel) => {
            this.onGroupSelected(group);

            this.unSubValueChanges();
            this.groupNameCtrl.setValue(group.name);
            this.subValueChanges();
            this.closeDropdown();
        }), this.itemClickProcess);
    }

    @Input()
    public get placeholder() {
        return this._placeholder;
    }
    public set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    @Input()
    public get required() {
        return this._required;
    }
    public set required(value: boolean) {
        this._required = value;
        this.stateChanges.next();
    }

    public setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    @Input()
    public get value() {
        return this._value;
    }
    public set value(value: number) {
        this._value = value;
        this.stateChanges.next();
    }

    public writeValue(obj: any): void {
        this.value = obj as number;
        if (!this.isNullOrUndefined(this.value) && isNumeric(this.value)) {
            const group = this._flatGroups.find(g => g.id === this.value);
            if (!this.isNullOrUndefined(group)) {
                this.groupNameCtrl.setValue(group.name, { emitEvent: false });
            }
        }
    }

    private clearfilter(): void {
        const length = this._flatGroups.length;

        for (let i = 0; i < length; i++) {
            this._flatGroups[i].visable = true;
        }

        if (!this.isNullOrUndefined(this._treeCompRef) && !this.isNullOrUndefined(this._treeCompRef.instance)) {
            this._treeCompRef.instance.tree.renderNodeChanges();
        }
    }

    private createInjector(data: TreeContainerData): Injector {
        return Injector.create({providers:[{provide: TREE_CONTAINER_DATA, useValue: data}], parent:this._injector});
    }

    private filterGroups(): void {
        if (this._isDropDownOpen === false) {
            this.openDropdown();
        } else {
            const length = this._flatGroups.length;

            let selected: GroupModel;
            let count = 0;

            for (let i = 0; i < length; i++) {
                const group = this._flatGroups[i];
                if (group.name.toLocaleLowerCase().indexOf(this.groupNameCtrl.value.toLocaleLowerCase()) === 0) {
                    group.visable = true;

                    selected = group;
                    count++;
                } else if (this.isNullOrUndefined(group.children) || group.children.length === 0 || !group.children.some(c => c.visable === true)) {
                    group.visable = false;
                }
            }

            this._treeCompRef.instance.tree.renderNodeChanges();

            if (count === 1) {
                this.onGroupSelected(selected);
            }
        }
    }

    private onGroupSelected(group?: GroupModel): void {
        if (!this.isNullOrUndefined(group)) {
            this.value = group.id;
            this.groupSelected.emit(group);
        } else {
            this.value = null;
        }
        if (!this.isNullOrUndefined(this.onChange)) {
            this.onChange(this.value);
        }
    }

    private subValueChanges(): void {
        this._valueChangesSub = this.addSubscription(this.observableHandlerBase(this.groupNameCtrl.valueChanges, this.groupNameChangeProcess).subscribe(this.filterGroups.bind(this)), this.groupNameChangeProcess);
    }

    private unSubValueChanges(): void {
        if (!this.isNullOrUndefined(this._valueChangesSub)) {
            this._valueChangesSub.unsubscribe();
        }
    }
}
