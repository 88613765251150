import { DeviceModel } from '@em/models/restapi/Device.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class MetricsModel extends BaseModel implements IRestModel {
    public connectedDevicesCount: number = null;
    public devices: Array<DeviceModel> = null;
    public devicesCount: number = null;
    public devicesInErrorBeforePeriodCount: number = null;
    public devicesInErrorCount: number = null;
    public devicesInWarningCount: number = null;
    public readonly isIRestModel = true;
    public unlicensedDevices: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-MetricsModel');

        this.devices = RestModelUtility.loadFromArray(restModel.Devices, DeviceModel);
    }

    public toRestApiModel(): any {
        throw new Error('Method not implemented.');
    }
}
