export enum AutoHeightDetectionStateEnum {
    notSupported = 0,
    none = 1,
    preset = 2,
    autoInitializing = 3,
    autoValid = 4,
    autoInvalid = 5,
    failedHeight = 6,
    failedOrientation = 7,
    failedHeightAndOrientation = 8,
    croppedOrientation = 9,
    unknownState = 10,
}

export class AutoHeightDetectionStateEnumHelpers {
    public static toString(value: AutoHeightDetectionStateEnum): string {
        switch (value) {
            case AutoHeightDetectionStateEnum.notSupported:
                return 'Not Supported';
            case AutoHeightDetectionStateEnum.none:
                return 'Unconfigured';
            case AutoHeightDetectionStateEnum.preset:
                return 'Preset';
            case AutoHeightDetectionStateEnum.autoInitializing:
                return 'Auto Initializing';
            case AutoHeightDetectionStateEnum.autoValid:
                return 'Auto Valid';
            case AutoHeightDetectionStateEnum.autoInvalid:
                return 'Invalid Height (Auto)';
            case AutoHeightDetectionStateEnum.failedHeight:
                return 'Invalid Height';
            case AutoHeightDetectionStateEnum.failedOrientation:
                return 'Invalid Orientation';
            case AutoHeightDetectionStateEnum.failedHeightAndOrientation:
                return 'Invalid Orientation and Height';
            case AutoHeightDetectionStateEnum.croppedOrientation:
                return 'Orientation cropped';
            case AutoHeightDetectionStateEnum.unknownState:
                return 'Unknown State';
        }
    }

    public static toPrettyString(value: AutoHeightDetectionStateEnum): string {
        switch (value) {
            case AutoHeightDetectionStateEnum.notSupported:
                return `Not Supported`;
            case AutoHeightDetectionStateEnum.none:
                return `Unconfigured`;
            case AutoHeightDetectionStateEnum.preset:
                return `Height Configured`;
            case AutoHeightDetectionStateEnum.autoInitializing:
                return `Detecting Height`;
            case AutoHeightDetectionStateEnum.autoValid:
                return `Height Configured`;
            case AutoHeightDetectionStateEnum.autoInvalid:
                return `Height cannot be determined - please make sure the unit is mounted in the range 2.0-4.5m`;
            case AutoHeightDetectionStateEnum.failedHeight:
                return `Height cannot be determined - please make sure the unit is  mounted in the range 2.0-4.5m`;
            case AutoHeightDetectionStateEnum.failedOrientation:
                return `Orientation cannot be determined - please make sure the unit is orientated correctly`;
            case AutoHeightDetectionStateEnum.failedHeightAndOrientation:
                return `Height And Orientation cannot be determined - please make sure the unit is mounted and orientated correctly`;
            case AutoHeightDetectionStateEnum.croppedOrientation:
                return `Height Configured (Orientation cropped)`;
            case AutoHeightDetectionStateEnum.unknownState:
                return `Error Unknown State`;
        }
    }
}
