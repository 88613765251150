import { Component, HostBinding, Injector } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

interface IComponentConfig {
    baseId: string;
}

export const RiftComponentConfig: IComponentConfig = {
    baseId: 'rift',
};

@Component({
    selector: 'rift-root',
    templateUrl: './Rift.Component.html',
    styleUrls: ['./Rift.Component.scss'],
})
export class RiftComponent extends RiftBaseComponent {
    public static className: string = 'RiftComponent';
    public static id: string = 'rift';

    @HostBinding()
    public id: string = RiftComponent.id;

    public constructor(
        private readonly _ViewPortLoadQueueService: ViewPortLoadQueueService,
        private readonly _injector: Injector) {
        super(_injector);

        const loadingSplash = document.getElementById('loading-splash');
        if (!isNullOrUndefined(loadingSplash)) {
            loadingSplash.style.visibility = 'hidden';
        }
    }
}
