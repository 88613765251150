import { IRegisterConfig } from '@rift/shared/IRegisterConfig';
import {
    alternative_validateSelectedRegisterType,
    basic_validateSelectedRegisterType,
    beamBreak_validateSelectedRegisterType,
    compareMaster_validateSelectedRegisterType,
    occupancyMaster_validateSelectedRegisterType,
    queueWaitMaster_validateSelectedRegisterType,
    sequential_onRegisterSelected,
    sequential_validateSelectedRegisterType,
    summation_validateSelectedRegisterType,
    summationRegisterModel_getAssociatedRegisterVMs,
    summationRegisterModel_onAutoConfigAddAssociatedRegister,
    summationRegisterModel_onRegisterSelected,
    tamper_validateSelectedRegisterType,
    fIFOOccupancyMaster_validateSelectedRegisterType,
    remoteReceiver_validateSelectedRegisterType,
    wirelessPIR_validateSelectedRegisterType,
} from '@rift/shared/Settings.Register.Actions';
import { CountModeEnum } from '@shared/enum/CountMode.Enum';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { ALL_ASSOCIABLE_TYPES, RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { RegisterTypeCategoryEnum } from '@shared/enum/RegisterTypeCategoryEnum';
import { UnitOfMeasurementEnum } from '@shared/enum/UnitOfMeasurement.Enum';


const registerTypeConfig: Array<IRegisterConfig> = [];

// #############################################################################
// IF YOU ARE ADDING NEW REGISTER MAKE SURE YOU ADD "new" and "loadFromRestApi"
// IN "Settings.RegisterConfig.New.ts"
// #############################################################################

registerTypeConfig[RegisterTypeEnum.invalid] = {
    displayName: 'Invalid',
    faIconName: 'exclamation-triangle',
};

registerTypeConfig[RegisterTypeEnum.alternative] = {
    description: 'Logical OR whereby a target crossing any of the lines within this group will only be counted once',
    displayName: 'Alternative',
    faIconName: 'random',
    orderInCategory: 1,
    userEditInRegistersList: true,
    isUserAddable: true,
    showInRegistersList: true,
    category: RegisterTypeCategoryEnum.line,
    validateSelectedRegisterType: alternative_validateSelectedRegisterType,
    associatedRegisterConfigs: [
        {
            crossDescriptionText: 'A target will need to cross any of the following registers to create a count',
            helpText: 'Please select the registers that will only count a target once',
            associatedMin: 2,
            associatedMax: 8,
            associatedRequireRegisterTypes: [RegisterTypeEnum.basic, RegisterTypeEnum.heightLine],
            isLinesStore: true,
            linesStorePropertyName: 'lines',
        }
    ]
};

registerTypeConfig[RegisterTypeEnum.basicExternal] = {
    displayName: 'Basic External',
    faIconName: 'share-square',
    userEditInRegistersList: false,
    showInRegistersList: true,
    category: RegisterTypeCategoryEnum.line,
};

registerTypeConfig[RegisterTypeEnum.basic] = {
    description: 'A basic counting line that increments when a target walks across it',
    displayName: 'Line',
    faIconName: 'exchange-alt',
    orderInCategory: 0,
    category: RegisterTypeCategoryEnum.line,
    isCopyable: true,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    hasCountMode: true,
    hasHeightFiltering: true,
    hasHeightHistogram: true,
    hasStaffDetection: true,
    isLineHost: true,
    hasTags: true,
    canSnapTo: true,
    viewPort: {
        visible: true,
    },
    validateSelectedRegisterType: basic_validateSelectedRegisterType,
};

registerTypeConfig[RegisterTypeEnum.beamBreak] = {
    description: 'This register will group together targets which cross an immediate count line within a specified time window, and give a count of one. \n It mimics a side mounted beam break device which is only capable of outputting a count of one when people walk side by side.',
    displayName: 'Beam break',
    faIconName: 'ellipsis-h',
    orderInCategory: 4,
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.beambreakMode],
    validateSelectedRegisterType: beamBreak_validateSelectedRegisterType,
    simpleProperties: [
        {
            propertyName: 'eventDelay',
            name: 'Beam Break',
            placeholder: 'Beam Break Delay',
            inputType: 'number',
            matSuffix: 'S',
            defaultModelValue: 1.3,
            required: true,
            min: 0.1,
            max: 10,
            toModelValue: (value) => Math.floor(parseFloat(value) * 10),
            fromModelValue: (value) => parseFloat(value) / 10.0,
            summaryPrefixText: 'Beam break delay',
            summaryPostfixText: 'S',
        },
    ],
    associatedRegisterConfigs: [
        {
            helpText: 'Please select the register that will trigger the beam break',
            crossDescriptionText: 'The count will be triggered by the following register',
            associatedMin: 1,
            associatedMax: 1,
            associatedRequireLineCountMode: [CountModeEnum.immediate, CountModeEnum.immediateAntiDither],
            associatedRequireRegisterTypes: [RegisterTypeEnum.basic, RegisterTypeEnum.heightLine],
            isRegisterStore: true,
            registerStorePropertyName: 'associatedRegisterIndex',
        }
    ]
};

registerTypeConfig[RegisterTypeEnum.custom] = {
    displayName: 'Custom',
    faIconName: 'code',
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
};

registerTypeConfig[RegisterTypeEnum.sequential] = {
    description: 'A group of lines of which all must be crossed sequentially for a count to be registered',
    displayName: 'Sequential',
    faIconName: 'bars',
    orderInCategory: 2,
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    validateSelectedRegisterType: sequential_validateSelectedRegisterType,
    associatedRegisterConfigs: [
        {
            helpText: 'Please select the registers that must be crossed sequentially for a count to be registered',
            crossDescriptionText: 'A target will need to cross all of the following registers in order to create a count',
            associatedMin: 2,
            associatedMax: 8,
            associatedRequireLineCountMode: [CountModeEnum.immediate, CountModeEnum.immediateAntiDither],
            associatedRequireRegisterTypes: [RegisterTypeEnum.basic, RegisterTypeEnum.heightLine],
            isLinesStore: true,
            linesStorePropertyName: 'lines',
            isOrderable: true,
            onRegisterSelected: sequential_onRegisterSelected,
        }
    ]
};

registerTypeConfig[RegisterTypeEnum.summation] = {
    description: 'A valid crossing increment can be configured to add to or subtract from this registers total',
    displayName: 'Summation',
    faIconName: 'plus',
    orderInCategory: 3,
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    validateSelectedRegisterType: summation_validateSelectedRegisterType,
    associatedRegisterConfigs: [
        {
            helpText: 'Please select the registers that will be added or subtracted to this registers count',
            crossDescriptionText: 'The count calculation will be made up from all the following registers',
            associatedMin: 1,
            associatedMax: 8,
            isAddSubtract: true,
            isRegistersStore: true,
            registerStorePropertyName: 'registers',
            onRegisterSelected: summationRegisterModel_onRegisterSelected,
            getAssociatedRegisterVMs: summationRegisterModel_getAssociatedRegisterVMs,
            onAutoConfigAddAssociatedRegister: summationRegisterModel_onAutoConfigAddAssociatedRegister,
        }
    ]
};

registerTypeConfig[RegisterTypeEnum.tamper] = {
    description: 'Detects tamper attempts for the device',
    displayName: 'Tamper Detection',
    faIconName: 'bullseye',
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.tamperDetect],
    fixedName: 'Tamper Detection',
    validateSelectedRegisterType: tamper_validateSelectedRegisterType,
};

registerTypeConfig[RegisterTypeEnum.heightLine] = {
    displayName: 'Height Line',
    faIconName: 'arrows-alt-v',
    isCopyable: true,
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.height],
    hasCountMode: true,
    hasHeightFiltering: true,
    hasHeightHistogram: true,
    hasStaffDetection: true,
    isLineHost: true,
    canSnapTo: true,
    viewPort: {
        visible: true,
    }
};

registerTypeConfig[RegisterTypeEnum.zone] = {
    description: 'Can be used to monitor activity in an area',
    displayName: 'Zone',
    faIconName: 'draw-polygon',
    orderInCategory: 0,
    category: RegisterTypeCategoryEnum.zone,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.zones],
    hasHeightFiltering: true,
    hasHeightHistogram: true,
    hasZone: true,
    hasDwellHistogram: true,
    hasStaffDetection: true,
    hasMaxDwell: true,
    viewPort: {
        visible: true,
    },
    simpleProperties: [
        {
            propertyName: 'minDwellDuration',
            name: 'Min Dwell Duration',
            placeholder: 'Min Dwell Duration',
            inputType: 'number',
            defaultModelValue: 5,
            required: true,
            min: 0,
            max: 3600,
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
            matSuffix: 'S',
            summaryPrefixText: 'Min Dwell duration',
            summaryPostfixText: 'S',
        }
    ]
};

registerTypeConfig[RegisterTypeEnum.zoneOccupancy] = {
    description: 'Instantaneous count of how many people are in an area',
    displayName: 'Zone Occupancy',
    faIconName: 'dice-one',
    orderInCategory: 1,
    category: RegisterTypeCategoryEnum.zone,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.zoneDwellHistogram],
    hasHeightFiltering: true,
    hasHeightHistogram: true,
    hasStaffDetection: true,
    hasInstantDwellHistogram: true,
    hasMaxDwell: true,
    viewPort: {
        visible: true,
    },
    simpleProperties: [
        {
            propertyName: 'minDwellDuration',
            name: 'Min Dwell Duration',
            placeholder: 'Min Dwell Duration',
            inputType: 'number',
            defaultModelValue: 5,
            required: true,
            min: 0,
            max: 3600,
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
            matSuffix: 'S',
            summaryPrefixText: 'Min Dwell duration',
            summaryPostfixText: 'S',
        }
    ]
};

registerTypeConfig[RegisterTypeEnum.lineExclusion] = {
    description: 'A basic counting line that increments when a person walks across it that also includes an exclusion area',
    displayName: 'Line Exclusion',
    faIconName: 'user-minus',
    orderInCategory: 5,
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.zones],
    hasCountMode: true,
    hasHeightFiltering: true,
    hasHeightHistogram: true,
    hasStaffDetection: true,
    hasExclusionType: true,
    canSnapTo: true,
    viewPort: {
        visible: true,
    },
};

registerTypeConfig[RegisterTypeEnum.portal] = {
    description: 'Count people that appear in one area (start) and disappear in another (stop)',
    displayName: 'Portal',
    faIconName: 'clone',
    orderInCategory: 2,
    category: RegisterTypeCategoryEnum.zone,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.portalCounting],
    hasHeightFiltering: true,
    hasHeightHistogram: true,
    hasStaffDetection: true,
    viewPort: {
        visible: true,
    },
};

registerTypeConfig[RegisterTypeEnum.staffCall] = {
    description: 'Increments a count when a customer (un-tagged) is stood in an area and a member of staff (tagged) is not present',
    displayName: 'Staff Call',
    faIconName: 'users',
    orderInCategory: 3,
    category: RegisterTypeCategoryEnum.zone,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    canSnapTo: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.staffCallAttend],
    viewPort: {
        visible: true,
    },
    simpleProperties: [
        {
            propertyName: 'untaggedTime',
            name: 'Customer Dwell',
            placeholder: 'Customer Dwell Duration',
            inputType: 'number',
            defaultModelValue: 5,
            required: true,
            min: 0,
            max: 3600,
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
            matSuffix: 'S',
            summaryPrefixText: 'Customer dwell duration',
            summaryPostfixText: 'S',
        }
    ]
};

registerTypeConfig[RegisterTypeEnum.staffAttend] = {
    description: 'Increments a count when a member of staff (tagged) attends to a customer (un-tagged)',
    displayName: 'Staff Attend',
    faIconName: 'user',
    orderInCategory: 4,
    category: RegisterTypeCategoryEnum.zone,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.staffCallAttend],
    hasUnattendedTimeDwellHistogram: true,
    canSnapTo: true,
    viewPort: {
        visible: true,
    },
};

registerTypeConfig[RegisterTypeEnum.occupancyMaster] = {
    description: 'Monitors the occupancy of a room',
    displayName: 'Occupancy',
    faIconName: 'dice-two',
    orderInCategory: 5,
    category: RegisterTypeCategoryEnum.zone,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.occupancy],
    validateSelectedRegisterType: occupancyMaster_validateSelectedRegisterType,
    simpleProperties: [
        {
            propertyName: 'resetTimeout',
            name: 'Reset Timeout',
            placeholder: 'Reset Timeout',
            inputType: 'number',
            defaultModelValue: 10,
            required: true,
            min: 1,
            max: 43200,
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
            matSuffix: 'S',
            summaryPrefixText: 'Reset Timeout',
            summaryPostfixText: 'S',
        },
        {
            propertyName: 'waitRegisterEnabled',
            placeholder: 'Wait time register enabled',
            name: 'Wait time register enabled',
            inputType: 'checkbox',
            defaultModelValue: true,
            summaryPrefixText: 'Wait time register enabled'
        }
    ],
    associatedRegisterConfigs: [
        {
            title: 'Occupancy Inputs',
            crossDescriptionText: 'The targets will need to cross one of these following registers to count as a occupant',
            helpText: 'The registers tracking targets entering',
            associatedMin: 1,
            associatedMax: 8,
            associatedRequireRegisterTypes: [
                RegisterTypeEnum.alternative,
                RegisterTypeEnum.basic,
                RegisterTypeEnum.beamBreak,
                RegisterTypeEnum.custom,
                RegisterTypeEnum.sequential,
                RegisterTypeEnum.summation,
                RegisterTypeEnum.tamper,
                RegisterTypeEnum.heightLine,
                RegisterTypeEnum.zone,
                RegisterTypeEnum.zoneOccupancy,
                RegisterTypeEnum.lineExclusion,
                RegisterTypeEnum.portal,
                RegisterTypeEnum.staffCall,
                RegisterTypeEnum.staffAttend,
                RegisterTypeEnum.smartBoundary,
                RegisterTypeEnum.zoneWaitTime,
                RegisterTypeEnum.remoteValue,
                RegisterTypeEnum.remoteReceiver
            ],
            isRegistersStore: true,
            registersStorePropertyName: 'inRegisters',
        },
        {
            title: 'Occupancy Outputs',
            crossDescriptionText: 'The targets will need to cross one of the following registers to be removed as an occupant',
            helpText: 'The registers tracking targets exiting',
            associatedMin: 1,
            associatedMax: 8,
            associatedRequireRegisterTypes: [
                RegisterTypeEnum.alternative,
                RegisterTypeEnum.basic,
                RegisterTypeEnum.beamBreak,
                RegisterTypeEnum.custom,
                RegisterTypeEnum.sequential,
                RegisterTypeEnum.summation,
                RegisterTypeEnum.tamper,
                RegisterTypeEnum.heightLine,
                RegisterTypeEnum.zone,
                RegisterTypeEnum.zoneOccupancy,
                RegisterTypeEnum.lineExclusion,
                RegisterTypeEnum.portal,
                RegisterTypeEnum.staffCall,
                RegisterTypeEnum.staffAttend,
                RegisterTypeEnum.smartBoundary,
                RegisterTypeEnum.zoneWaitTime,
                RegisterTypeEnum.remoteValue,
                RegisterTypeEnum.remoteReceiver
            ],
            isRegistersStore: true,
            registersStorePropertyName: 'outRegisters',
        },
        {
            title: 'Occupancy Reset',
            helpText: 'The area occupancy reset register',
            associatedMin: 0,
            associatedMax: 8,
            associatedRequireRegisterTypes: [
                RegisterTypeEnum.basicExternal,
                RegisterTypeEnum.wirelessPIR
            ],
            isRegistersStore: true,
            registersStorePropertyName: 'externalRegisters',
        },
    ]
};

registerTypeConfig[RegisterTypeEnum.occupancy] = {
    displayName: 'Occupancy',
    faIconName: 'dice-two',
    category: RegisterTypeCategoryEnum.zone,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.occupancy],
};

registerTypeConfig[RegisterTypeEnum.occupancyReset] = {
    displayName: 'Occupancy Reset',
    faIconName: 'history',
    category: RegisterTypeCategoryEnum.zone,
    showInRegistersList: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.occupancy],
};

registerTypeConfig[RegisterTypeEnum.occupancyWait] = {
    displayName: 'Occupancy Wait',
    faIconName: 'hourglass',
    category: RegisterTypeCategoryEnum.zone,
    showInRegistersList: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.occupancy],
};

registerTypeConfig[RegisterTypeEnum.queueWaitMaster] = {
    description: 'Captures wait time for a queue',
    displayName: 'Queue Wait',
    faIconName: 'hourglass',
    orderInCategory: 6,
    category: RegisterTypeCategoryEnum.zone,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.occupancy],
    validateSelectedRegisterType: queueWaitMaster_validateSelectedRegisterType,
    simpleProperties: [
        {
            propertyName: 'inactivityReset',
            name: 'Inactivity Timeout',
            placeholder: 'Inactivity Timeout',
            inputType: 'number',
            defaultModelValue: 10,
            required: true,
            min: 1,
            max: 3600,
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
            matSuffix: 'S',
            summaryPrefixText: 'Inactivity Timeout',
            summaryPostfixText: 'S',
        },
        {
            propertyName: 'rejoinTimeout',
            name: 'Rejoin Timeout',
            placeholder: 'Rejoin Timeout',
            inputType: 'number',
            defaultModelValue: 10,
            min: 1,
            max: 3600,
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
            matSuffix: 'S',
            summaryPrefixText: 'Rejoin Timeout',
            summaryPostfixText: 'S',
        }
    ],
    associatedRegisterConfigs: [
        {
            title: 'Queue back enter',
            helpText: 'The registers that will track targets entering the back of the queue',
            crossDescriptionText: 'A target will need to cross the following registers to enter the queue.',
            associatedMin: 1,
            associatedMax: 8,
            associatedRequireRegisterTypes: [
                RegisterTypeEnum.alternative,
                RegisterTypeEnum.basic,
                RegisterTypeEnum.beamBreak,
                RegisterTypeEnum.custom,
                RegisterTypeEnum.sequential,
                RegisterTypeEnum.summation,
                RegisterTypeEnum.tamper,
                RegisterTypeEnum.heightLine,
                RegisterTypeEnum.zone,
                RegisterTypeEnum.zoneOccupancy,
                RegisterTypeEnum.lineExclusion,
                RegisterTypeEnum.portal,
                RegisterTypeEnum.staffCall,
                RegisterTypeEnum.staffAttend,
                RegisterTypeEnum.smartBoundary,
                RegisterTypeEnum.zoneWaitTime,
            ],
            isRegistersStore: true,
            registersStorePropertyName: 'enterQueueBack',
        },
        {
            title: 'Queue back exit',
            helpText: 'The registers that will track targets exiting the back of the queue',
            crossDescriptionText: 'A target will need to cross the following registers to exit the back of the queue.',
            associatedMin: 1,
            associatedMax: 8,
            associatedRequireRegisterTypes: [
                RegisterTypeEnum.alternative,
                RegisterTypeEnum.basic,
                RegisterTypeEnum.beamBreak,
                RegisterTypeEnum.custom,
                RegisterTypeEnum.sequential,
                RegisterTypeEnum.summation,
                RegisterTypeEnum.tamper,
                RegisterTypeEnum.heightLine,
                RegisterTypeEnum.zone,
                RegisterTypeEnum.zoneOccupancy,
                RegisterTypeEnum.lineExclusion,
                RegisterTypeEnum.portal,
                RegisterTypeEnum.staffCall,
                RegisterTypeEnum.staffAttend,
                RegisterTypeEnum.smartBoundary,
                RegisterTypeEnum.zoneWaitTime,
            ],
            isRegistersStore: true,
            registersStorePropertyName: 'exitQueueBack',
        },
        {
            title: 'Queue front enter',
            helpText: 'The registers that will track targets entering the front of the queue',
            crossDescriptionText: 'A target will need to cross the following registers to enter the front of the queue.',
            associatedMin: 1,
            associatedMax: 8,
            associatedRequireRegisterTypes: [
                RegisterTypeEnum.alternative,
                RegisterTypeEnum.basic,
                RegisterTypeEnum.beamBreak,
                RegisterTypeEnum.custom,
                RegisterTypeEnum.sequential,
                RegisterTypeEnum.summation,
                RegisterTypeEnum.tamper,
                RegisterTypeEnum.heightLine,
                RegisterTypeEnum.zone,
                RegisterTypeEnum.zoneOccupancy,
                RegisterTypeEnum.lineExclusion,
                RegisterTypeEnum.portal,
                RegisterTypeEnum.staffCall,
                RegisterTypeEnum.staffAttend,
                RegisterTypeEnum.smartBoundary,
                RegisterTypeEnum.zoneWaitTime,
            ],
            isRegistersStore: true,
            registersStorePropertyName: 'enterQueueFront',
        },
        {
            title: 'Queue front exit',
            helpText: 'The registers that will track targets exiting the front of the queue',
            crossDescriptionText: 'A target will need to cross the following registers to exit the queue.',
            associatedMin: 1,
            associatedMax: 8,
            associatedRequireRegisterTypes: [
                RegisterTypeEnum.alternative,
                RegisterTypeEnum.basic,
                RegisterTypeEnum.beamBreak,
                RegisterTypeEnum.custom,
                RegisterTypeEnum.sequential,
                RegisterTypeEnum.summation,
                RegisterTypeEnum.tamper,
                RegisterTypeEnum.heightLine,
                RegisterTypeEnum.zone,
                RegisterTypeEnum.zoneOccupancy,
                RegisterTypeEnum.lineExclusion,
                RegisterTypeEnum.portal,
                RegisterTypeEnum.staffCall,
                RegisterTypeEnum.staffAttend,
                RegisterTypeEnum.smartBoundary,
                RegisterTypeEnum.zoneWaitTime,
            ],
            isRegistersStore: true,
            registersStorePropertyName: 'exitQueueFront',
        },
    ]
};

registerTypeConfig[RegisterTypeEnum.queueWait] = {
    displayName: 'Queue Wait',
    faIconName: 'hourglass',
    category: RegisterTypeCategoryEnum.zone,
    showInRegistersList: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.occupancy],
};

registerTypeConfig[RegisterTypeEnum.smartBoundary] = {
    displayName: 'Smart Boundary',
    faIconName: 'border-style',
    orderInCategory: 7,
    category: RegisterTypeCategoryEnum.line,
    isCopyable: true,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    hasStaffDetection: true,
    isLineHost: true,
    isPolygonHost: true,
    canSnapTo: true,
    viewPort: {
        visible: true,
    },
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.smartBoundary, DeviceCapabilitiesEnum.markedTargets],
    simpleProperties: [
        {
            propertyName: 'minimumHeight',
            name: 'Minimum Height',
            placeholder: 'Minimum',
            inputType: 'unitsOfMeasurement',
            imperialUnit: UnitOfMeasurementEnum.inch,
            metricUnit: UnitOfMeasurementEnum.centimeter,
            min: 1,
            max: 300,
            required: true,
            defaultModelValue: 100,
            summaryPrefixText: 'Minimum Height',
        },
        {
            propertyName: 'maximumHeight',
            name: 'Maximum Height',
            placeholder: 'Maximum',
            inputType: 'unitsOfMeasurement',
            imperialUnit: UnitOfMeasurementEnum.inch,
            metricUnit: UnitOfMeasurementEnum.centimeter,
            min: 1,
            max: 300,
            required: true,
            defaultModelValue: 200,
            summaryPrefixText: 'Maximum Height',
        },
        {
            propertyName: 'entryTimeout',
            name: 'Entry Timeout',
            placeholder: 'Timeout',
            inputType: 'number',
            min: 0,
            max: 3600,
            required: true,
            step: 0.1,
            matSuffix: 'S',
            summaryPrefixText: 'Entry Timeout',
            summaryPostfixText: 'S',
            toModelValue: (value) => Math.floor(parseFloat(value) * 10),
            fromModelValue: (value) => parseFloat(value) / 10.0,
        },
        {
            propertyName: 'exitTimeout',
            name: 'Exit Timeout',
            placeholder: 'Timeout',
            inputType: 'number',
            min: 0,
            max: 3600,
            required: true,
            step: 0.1,
            matSuffix: 'S',
            summaryPrefixText: 'Exit Timeout',
            summaryPostfixText: 'S',
            toModelValue: (value) => Math.floor(parseFloat(value) * 10),
            fromModelValue: (value) => parseFloat(value) / 10.0,
        },
        {
            propertyName: 'maxGroupSize',
            name: 'Maximum Group Size',
            placeholder: 'Maximum',
            inputType: 'number',
            min: 0,
            max: 10,
            required: true,
            summaryPrefixText: 'Maximum Group Size',
        },
        {
            propertyName: 'maxTimeBetweenTargets',
            name: 'Maximum Time Between Targets',
            placeholder: 'Maximum',
            inputType: 'number',
            min: 0,
            max: 3600,
            step: 0.1,
            required: true,
            matSuffix: 'S',
            summaryPrefixText: 'Maximum Time Between Targets',
            summaryPostfixText: 'S',
            toModelValue: (value) => Math.floor(parseFloat(value) * 10),
            fromModelValue: (value) => parseFloat(value) / 10.0,
        },
        {
            propertyName: 'maxDistanceBetweenTargets',
            name: 'Maximum Distance Between Targets',
            placeholder: 'Maximum',
            inputType: 'unitsOfMeasurement',
            imperialUnit: UnitOfMeasurementEnum.inch,
            metricUnit: UnitOfMeasurementEnum.centimeter,
            min: 0,
            max: 100,
            required: true,
            summaryPrefixText: 'Maximum Distance Between Targets',
        }
    ]
};

registerTypeConfig[RegisterTypeEnum.zoneWaitTime] = {
    description: 'Wait time of targets in an area',
    displayName: 'Zone Wait Time',
    faIconName: 'draw-polygon',
    orderInCategory: 8,
    category: RegisterTypeCategoryEnum.zone,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.zoneWaitTime],
    hasHeightFiltering: true,
    hasMaxDwell: true,
    viewPort: {
        visible: true,
    },
    simpleProperties: [
        {
            propertyName: 'minDwellDuration',
            name: 'Min Dwell Duration',
            placeholder: 'Min Dwell Duration',
            inputType: 'number',
            defaultModelValue: 5,
            required: true,
            min: 0,
            max: 3600,
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
            matSuffix: 'S',
            summaryPrefixText: 'Min Dwell duration',
            summaryPostfixText: 'S',
        }
    ]
};

registerTypeConfig[RegisterTypeEnum.greaterThanCompare] = {
    displayName: 'Greater Than or Equal',
    faIconName: 'greater-than-equal',
    showInRegistersList: true,
    userEditInRegistersList: true,
    category: RegisterTypeCategoryEnum.line,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.compareRegister],
    validateSelectedRegisterType: compareMaster_validateSelectedRegisterType,
    simpleProperties: [
        {
            propertyName: 'greaterThanValue',
            name: 'Greater Than or Equal',
            placeholder: 'Greater Than or Equal',
            inputType: 'number',
            min: 0,
            max: 100000,
            required: true,
            summaryPrefixText: 'Greater Than or Equal',
            summaryPostfixText: 'S',
        }
    ],
    associatedRegisterConfigs: [
        {
            title: 'Greater Than or Equal to Register',
            helpText: 'Please select the register that will be used for greater than or equal count',
            crossDescriptionText: '',
            associatedMin: 1,
            associatedMax: 1,
            associatedRequireRegisterTypes: [
                RegisterTypeEnum.alternative,
                RegisterTypeEnum.basic,
                RegisterTypeEnum.beamBreak,
                RegisterTypeEnum.custom,
                RegisterTypeEnum.sequential,
                RegisterTypeEnum.summation,
                RegisterTypeEnum.tamper,
                RegisterTypeEnum.heightLine,
                RegisterTypeEnum.zone,
                RegisterTypeEnum.zoneOccupancy,
                RegisterTypeEnum.lineExclusion,
                RegisterTypeEnum.portal,
                RegisterTypeEnum.staffCall,
                RegisterTypeEnum.staffAttend,
                RegisterTypeEnum.smartBoundary,
                RegisterTypeEnum.zoneWaitTime,
                RegisterTypeEnum.occupancyWait,
                RegisterTypeEnum.occupancyReset,
                RegisterTypeEnum.queueWait,
                RegisterTypeEnum.occupancyMaster,
                RegisterTypeEnum.queueWaitMaster,
                RegisterTypeEnum.fifoOccupancyMaster,
                RegisterTypeEnum.remoteValue,
                RegisterTypeEnum.remoteReceiver
            ],
            isRegisterStore: true,
            registerStorePropertyName: 'greaterThanRegister',
        }
    ]
};

registerTypeConfig[RegisterTypeEnum.lessThanCompare] = {
    displayName: 'Less Than or Equal',
    faIconName: 'less-than-equal',
    showInRegistersList: true,
    userEditInRegistersList: true,
    category: RegisterTypeCategoryEnum.line,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.compareRegister],
    validateSelectedRegisterType: compareMaster_validateSelectedRegisterType,
    simpleProperties: [
        {
            propertyName: 'lessThanValue',
            name: 'Less Than or Equal',
            placeholder: 'Less Than or Equal',
            inputType: 'number',
            min: 0,
            max: 100000,
            required: true,
            summaryPrefixText: 'Less Than or Equal',
            summaryPostfixText: 'S',
        },
    ],
    associatedRegisterConfigs: [
        {
            title: 'Less Than or Equal to Register',
            helpText: 'Please select the register that will be used for less than or equal count',
            crossDescriptionText: '',
            associatedMin: 1,
            associatedMax: 1,
            associatedRequireRegisterTypes: [
                RegisterTypeEnum.alternative,
                RegisterTypeEnum.basic,
                RegisterTypeEnum.beamBreak,
                RegisterTypeEnum.custom,
                RegisterTypeEnum.sequential,
                RegisterTypeEnum.summation,
                RegisterTypeEnum.tamper,
                RegisterTypeEnum.heightLine,
                RegisterTypeEnum.zone,
                RegisterTypeEnum.zoneOccupancy,
                RegisterTypeEnum.lineExclusion,
                RegisterTypeEnum.portal,
                RegisterTypeEnum.staffCall,
                RegisterTypeEnum.staffAttend,
                RegisterTypeEnum.smartBoundary,
                RegisterTypeEnum.zoneWaitTime,
                RegisterTypeEnum.occupancyWait,
                RegisterTypeEnum.occupancyReset,
                RegisterTypeEnum.queueWait,
                RegisterTypeEnum.occupancyMaster,
                RegisterTypeEnum.queueWaitMaster,
                RegisterTypeEnum.fifoOccupancyMaster,
                RegisterTypeEnum.remoteValue,
                RegisterTypeEnum.remoteReceiver
            ],
            isRegisterStore: true,
            registerStorePropertyName: 'lessThanRegister',
        }
    ]
};

registerTypeConfig[RegisterTypeEnum.zoneWaitTimeSum] = {
    description: 'The Sum of the wait times inside a zone',
    displayName: 'Zone Wait Time Sum',
    faIconName: 'draw-polygon',
    orderInCategory: 9,
    category: RegisterTypeCategoryEnum.zone,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.zoneWaitTime],
    hasHeightFiltering: true,
    hasStaffDetection: true,
    hasMaxDwell: true,
    viewPort: {
        visible: true,
    },
    simpleProperties: [
        {
            propertyName: 'minDwellDuration',
            name: 'Min Dwell Duration',
            placeholder: 'Min Dwell Duration',
            inputType: 'number',
            defaultModelValue: 5,
            required: true,
            min: 0,
            max: 3600,
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
            matSuffix: 'S',
            summaryPrefixText: 'Min Dwell duration',
            summaryPostfixText: 'S',
        }
    ]
};

registerTypeConfig[RegisterTypeEnum.pulseGenerator] = {
    description: 'Pulse generator register',
    displayName: 'Pulse generator',
    faIconName: 'wave-square',
    orderInCategory: 10,
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.pulseGeneratorRegister],
    simpleProperties: [
        {
            propertyName: 'incrementPeriod',
            name: 'Increment Period',
            placeholder: 'Increment Period',
            inputType: 'number',
            min: 1,
            max: 3600,
            required: true,
            summaryPrefixText: 'Increment Period',
            summaryPostfixText: 'S',
            matSuffix: 'S',
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
        },
    ],
    associatedRegisterConfigs: [
        {
            title: 'Trigger Register',
            helpText: 'Please select the register that will be used to trigger the pulse',
            crossDescriptionText: '',
            associatedMin: 1,
            associatedMax: 1,
            associatedRequireRegisterTypes: [
                ...ALL_ASSOCIABLE_TYPES,
            ],
            isRegisterStore: true,
            registerStorePropertyName: 'sourceRegisterIndex',
        },
    ]
};

registerTypeConfig[RegisterTypeEnum.tailgate] = {
    description: 'Tailgate register',
    displayName: 'Tailgate',
    faIconName: 'people-arrows',
    orderInCategory: 11,
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.tailgateRegister],
    simpleProperties: [
        {
            propertyName: 'timeout',
            name: 'Time out',
            placeholder: 'Time out',
            inputType: 'number',
            min: 1,
            max: 3600,
            required: true,
            summaryPrefixText: 'Time out',
            summaryPostfixText: 'S',
            matSuffix: 'S',
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
        },
    ],
    associatedRegisterConfigs: [
        {
            title: 'Access Event Register',
            helpText: 'Please select the register that will be used to trigger the access event',
            crossDescriptionText: 'Access event trigger',
            associatedMin: 1,
            associatedMax: 1,
            associatedRequireRegisterTypes: [
                ...ALL_ASSOCIABLE_TYPES,
                RegisterTypeEnum.basicExternal
            ],
            isRegisterStore: true,
            registerStorePropertyName: 'accessEventRegisterIndex',
        },
        {
            title: 'Entry Counting Register',
            helpText: 'Please select the register that will be used for entry counting',
            crossDescriptionText: 'Entry register',
            associatedMin: 1,
            associatedMax: 1,
            associatedRequireRegisterTypes: [
                ...ALL_ASSOCIABLE_TYPES,
            ],
            isRegisterStore: true,
            registerStorePropertyName: 'entryCountingRegisterIndex',
        },
    ]
};

registerTypeConfig[RegisterTypeEnum.remoteValue] = {
    description: 'Remote register',
    displayName: 'Remote',
    faIconName: 'link',
    orderInCategory: 12,
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.remoteValueRegister],
};

registerTypeConfig[RegisterTypeEnum.universalTrigger] = {
    description: 'Universal Trigger register',
    displayName: 'Universal Trigger',
    faIconName: 'bell',
    orderInCategory: 13,
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.compareRegister],
    simpleProperties: [
        {
            propertyName: 'thresholdValue',
            name: 'Threshold Value',
            placeholder: 'Threshold Value',
            inputType: 'number',
            min: 1,
            max: 1000000,
            required: true,
            summaryPrefixText: 'Threshold Value',
        },
        {
            propertyName: 'thresholdOffset',
            name: 'Threshold Offset',
            placeholder: 'Threshold Offset',
            inputType: 'number',
            min: -1000000,
            max: 1000000,
            required: true,
            summaryPrefixText: 'Threshold Offset',
        },
        {
            propertyName: 'polarityBool',
            placeholder: 'Compare greater than or equal',
            name: 'Compare greater than or equal',
            inputType: 'checkbox',
            defaultModelValue: true,
            summaryPrefixText: 'Compare greater than or equal'
        },
        {
            propertyName: 'holdOff',
            name: 'Hold off period',
            placeholder: 'Hold off period',
            inputType: 'number',
            min: 0,
            max: 10000000,
            required: true,
            summaryPrefixText: 'Hold off',
            summaryPostfixText: 'S',
            matSuffix: 'S',
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
        },
        {
            propertyName: 'repeatPeriod',
            name: 'Repeat period',
            placeholder: 'Repeat period',
            inputType: 'number',
            min: 0,
            max: 10000000,
            required: true,
            summaryPrefixText: 'Repeat',
            summaryPostfixText: 'S',
            matSuffix: 'S',
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
        },
    ],
    associatedRegisterConfigs: [
        {
            title: 'Trigger Register',
            helpText: 'Please select the register that will be used as the trigger',
            crossDescriptionText: '',
            associatedMin: 1,
            associatedMax: 1,
            associatedRequireRegisterTypes: [
                ...ALL_ASSOCIABLE_TYPES,
            ],
            isRegisterStore: true,
            registerStorePropertyName: 'sourceRegisterIndex',
        },
    ]
};

registerTypeConfig[RegisterTypeEnum.fifoOccupancyMaster] = {
    description: 'Monitors occupancy (FIFO)',
    displayName: 'Occupancy (FIFO)',
    faIconName: 'dice-two',
    orderInCategory: 10,
    category: RegisterTypeCategoryEnum.zone,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.tailgateRegister],
    validateSelectedRegisterType: fIFOOccupancyMaster_validateSelectedRegisterType,
    simpleProperties: [
        {
            propertyName: 'timeout',
            name: 'Individual Timeout',
            placeholder: 'Individual Timeout',
            inputType: 'number',
            defaultModelValue: 10,
            required: true,
            min: 1,
            max: 2147483647,
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
            matSuffix: 'S',
            summaryPrefixText: 'Individual Timeout',
            summaryPostfixText: 'S',
        },
        {
            propertyName: 'resetTimeout',
            name: 'Reset Timeout',
            placeholder: 'Reset Timeout',
            inputType: 'number',
            defaultModelValue: 10,
            required: true,
            min: 1,
            max: 2147483647,
            toModelValue: (value) => parseInt(value, 10) * 10,
            fromModelValue: (value) => parseInt(value, 10) / 10,
            matSuffix: 'S',
            summaryPrefixText: 'Reset Timeout',
            summaryPostfixText: 'S',
        },
    ],
    associatedRegisterConfigs: [
        {
            title: 'Occupancy Inputs',
            crossDescriptionText: 'The targets will need to cross one of these following registers to count as a occupant',
            helpText: 'The registers tracking targets entering',
            associatedMin: 1,
            associatedMax: 8,
            associatedRequireRegisterTypes: [
                RegisterTypeEnum.alternative,
                RegisterTypeEnum.basic,
                RegisterTypeEnum.beamBreak,
                RegisterTypeEnum.custom,
                RegisterTypeEnum.sequential,
                RegisterTypeEnum.summation,
                RegisterTypeEnum.tamper,
                RegisterTypeEnum.heightLine,
                RegisterTypeEnum.zone,
                RegisterTypeEnum.zoneOccupancy,
                RegisterTypeEnum.lineExclusion,
                RegisterTypeEnum.portal,
                RegisterTypeEnum.staffCall,
                RegisterTypeEnum.staffAttend,
                RegisterTypeEnum.smartBoundary,
                RegisterTypeEnum.zoneWaitTime,
                RegisterTypeEnum.remoteValue,
                RegisterTypeEnum.remoteReceiver
            ],
            isRegistersStore: true,
            registersStorePropertyName: 'inRegisters',
        },
        {
            title: 'Occupancy Outputs',
            crossDescriptionText: 'The targets will need to cross one of the following registers to be removed as an occupant',
            helpText: 'The registers tracking targets exiting',
            associatedMin: 1,
            associatedMax: 8,
            associatedRequireRegisterTypes: [
                RegisterTypeEnum.alternative,
                RegisterTypeEnum.basic,
                RegisterTypeEnum.beamBreak,
                RegisterTypeEnum.custom,
                RegisterTypeEnum.sequential,
                RegisterTypeEnum.summation,
                RegisterTypeEnum.tamper,
                RegisterTypeEnum.heightLine,
                RegisterTypeEnum.zone,
                RegisterTypeEnum.zoneOccupancy,
                RegisterTypeEnum.lineExclusion,
                RegisterTypeEnum.portal,
                RegisterTypeEnum.staffCall,
                RegisterTypeEnum.staffAttend,
                RegisterTypeEnum.smartBoundary,
                RegisterTypeEnum.zoneWaitTime,
                RegisterTypeEnum.remoteValue,
                RegisterTypeEnum.remoteReceiver
            ],
            isRegistersStore: true,
            registersStorePropertyName: 'outRegisters',
        },
        {
            title: 'Occupancy Reset',
            helpText: 'The area occupancy reset register',
            associatedMin: 0,
            associatedMax: 8,
            associatedRequireRegisterTypes: [
                RegisterTypeEnum.basicExternal,
                RegisterTypeEnum.wirelessPIR
            ],
            isRegistersStore: true,
            registersStorePropertyName: 'externalRegisters',
        },
    ]
};

registerTypeConfig[RegisterTypeEnum.fifoOccupancy] = {
    displayName: 'FIFO Occupancy',
    faIconName: 'dice-two',
    category: RegisterTypeCategoryEnum.zone,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.tailgateRegister],
};

registerTypeConfig[RegisterTypeEnum.fifoOccupancyTimeout] = {
    displayName: 'FIFO Occupancy Timeout',
    faIconName: 'history',
    category: RegisterTypeCategoryEnum.zone,
    showInRegistersList: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.tailgateRegister],
};

registerTypeConfig[RegisterTypeEnum.errorStatus] = {
    description: 'Triggers when the device is in an error state',
    displayName: 'Error Status',
    faIconName: 'exclamation',
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.errorStatusRegister],
    fixedName: 'Error Status',
};

registerTypeConfig[RegisterTypeEnum.remoteReceiver] = {
    description: 'Remote value receiver register',
    displayName: 'Remote Receiver',
    faIconName: 'satellite-dish',
    category: RegisterTypeCategoryEnum.line,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.remoteValueRegister],
    validateSelectedRegisterType: remoteReceiver_validateSelectedRegisterType,
};

registerTypeConfig[RegisterTypeEnum.wirelessPIR] = {
    description: 'Fine Motion Room Sensor PIR',
    displayName: 'Fine Motion Room Sensor PIR',
    faIconName: 'signal',
    category: RegisterTypeCategoryEnum.zone,
    userEditInRegistersList: true,
    showInRegistersList: true,
    isUserAddable: true,
    requiresDeviceCapabilities: [DeviceCapabilitiesEnum.wirelessPIR],
    validateSelectedRegisterType: wirelessPIR_validateSelectedRegisterType,
};


export const RegisterTypeConfig = registerTypeConfig;
