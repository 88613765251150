import { IProgressUpdateResponse } from '@rift/service/validation/models/webworker/syncrecording/IProgressUpdate.Response';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { UniqueIdUtility } from '@shared/utility/UniqueId.Utility';
import { IRequest } from '@shared/webworker/IRequest';
import { IResponse } from '@shared/webworker/IResponse';
import { ResponseTypesEnum } from '@shared/webworker/ResponseTypes.Enum';
import { Observable, Observer, Subject, timer } from 'rxjs';

/**
 * Client for commas with web worker.
 *
 * @export
 * @class WebWorkerClient
 */
export class WebWorkerClient {
    /**
     * Fires when a {ResponseTypesEnum.update} message is received.
     *
     * @type {Subject<IProgressUpdateResponse>}
     * @memberof WebWorkerClient
     */
    public progressUpdate: Subject<IProgressUpdateResponse> = new Subject<IProgressUpdateResponse>();
    /**
     * Fires when a {ResponseTypesEnum.error} message is received.
     *
     * @type {Subject<ErrorEvent>}
     * @memberof WebWorkerClient
     */
    public error: Subject<ErrorEvent> = new Subject<ErrorEvent>();

    private _messageReceived: Subject<IResponse> = new Subject<IResponse>();
    private _webWorker: Worker = null;
    private _maxReties: number = 5;
    private _retryDelayMs: number = 500;

    public constructor(worker: Worker) {
        this._webWorker = worker;
        this._webWorker.onerror = (error: ErrorEvent) => this.error.next(error);
        this._webWorker.onmessage = (event: MessageEvent) => this._messageReceived.next(event.data as IResponse);
    }

    /**
     * Sends a request message to the web worker.
     *
     * @template TRequest The request message type.
     * @template TResponse The response message type.
     * @param {TRequest} request The request to send.
     * @param {ProcessMonitorServiceProcess} [process]
     * @returns {Observable<TResponse>}
     * @memberof WebWorkerClient
     */
    public sendMessage<TRequest extends IRequest, TResponse extends IResponse>(request: TRequest, process?: ProcessMonitorServiceProcess, failCount?: number): Observable<TResponse> {
        return new Observable((observer: Observer<TResponse>) => {
            if (isNullOrUndefined(failCount)) {
                failCount = 0;
            }
            if (isNullOrUndefined(request.id)) {
                request.id = UniqueIdUtility.getNext('WebWorkerClient');
            }

            const responseSub = this._messageReceived.subscribe(
                response => {
                    if (response.id === request.id) {
                        switch (response.type) {
                            case ResponseTypesEnum.block:
                                observer.next(response as TResponse);
                                break;
                            case ResponseTypesEnum.complete:
                                observer.next(response as TResponse);
                                observer.complete();
                                if (!isNullOrUndefined(responseSub)) {
                                    responseSub.unsubscribe();
                                }
                                break;
                            case ResponseTypesEnum.update:
                                this.progressUpdate.next(response as IProgressUpdateResponse);
                                break;
                            case ResponseTypesEnum.error:
                                if (failCount < this._maxReties) {
                                    if (!isNullOrUndefined(responseSub)) {
                                        responseSub.unsubscribe();
                                    }
                                    failCount++;
                                    console.log(`Retry request attempts:${failCount} request:${JSON.stringify(request)} error:${response.errorMessage}`);
                                    timer((failCount - 1) * this._retryDelayMs).subscribe(() => {
                                        this.sendMessage(request, process, failCount).subscribe(() => { }, error => observer.error(new Error(response.errorMessage)));
                                    });
                                } else {
                                    process.error(response.errorMessage);
                                    observer.error(new Error(response.errorMessage));
                                    if (!isNullOrUndefined(responseSub)) {
                                        responseSub.unsubscribe();
                                    }
                                }

                                const errorInitEvent: ErrorEventInit = {
                                    error : new Error(response.errorMessage),
                                    message : response.errorMessage
                                };

                                const errorEvent = new ErrorEvent('WebWorkerError', errorInitEvent);

                                this.error.next(errorEvent);
                                break;
                        }
                    }
                },
                error => {
                    process.error('Receiving socket response.', error);
                    if (!isNullOrUndefined(responseSub)) {
                        responseSub.unsubscribe();
                    }
                    observer.error(error);
                    observer.complete();
                }
            );

            this._webWorker.postMessage(request);
        });

    }

    public terminate(): void {
        this._webWorker.terminate();
    }

}
