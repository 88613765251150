import { HistogramConfigModel } from '@rift/models/restapi/HistogramConfig.Model';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';
import { RegisterCustomActionModel } from '@rift/models/restapi/RegisterCustomAction.Model';
import { RegisterPushEntryModel } from '@rift/models/restapi/RegisterPushEntry.Model';
import { getConfigBooleanValue } from '@rift/shared/getConfigBooleanValue';
import { Settings } from '@rift/shared/Settings';
import { RegisterTypeConfig } from '@rift/shared/Settings.RegisterConfig';
import { BaseModel } from '@shared/base/Base.Model';
import { CountModeEnum } from '@shared/enum/CountMode.Enum';
import { DirectionTypeEnum } from '@shared/enum/DirectionType.Enum';
import { HistogramTypeEnum } from '@shared/enum/HistogramType.Enum';
import { RegisterTypeEnum, RegisterTypeEnumHelpers } from '@shared/enum/RegisterType.Enum';
import { RegisterTypeCategoryEnum } from '@shared/enum/RegisterTypeCategoryEnum';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { IRestModel } from '@shared/interface/IRestModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export const REGISTER_BASE_EXCLUDE_PROPERTIES = [
    'canSnapTo',
    'countMode',
    'dwellHistograms',
    'fillColor',
    'fillColor',
    'hasAssociated',
    'hasDwellHistogram',
    'hasDwellHistogram',
    'hasHeightHistogram',
    'hasInstantDwellHistogram',
    'hasUnattendedTimeHistogram',
    'heightFilteringEnabled',
    'heightHistograms',
    'instantDwellHistograms',
    'isAssociatedCompatible',
    'isBeamBreakCompatible',
    'isCopyable',
    'isCountModeCompatible',
    'isCountModeCompatible',
    'isCustomerDwellCompatible',
    'isDwellDurationCompatible',
    'isDwellHistogramCompatible',
    'isHeightFilteringCompatible',
    'isHeightHistogramCompatible',
    'isInstantDwellHistogramCompatible',
    'isLineHost',
    'isPolygonHost',
    'isStaffDetectionCompatible',
    'isUnattendedTimeDwellHistogramCompatible',
    'isViewPortVisibleInViewPort',
    'isZoneCompatible',
    'lineColor',
    'lineIds',
    'polygonIds',
    'registerIds',
    'registerTypeCategory',
    'tagDirection',
    'typeDisplayName',
    'typeFaIconName',
    'unattendedTimeHistograms',
    'polarityBool',
];

export abstract class RegisterBaseModel extends BaseModel implements IRegisterBaseModel, IRestModel {
    public countMode: CountModeEnum = null;
    public error: string = null;
    public histogramConfigs: RestModelChangeTrackerArray<HistogramConfigModel> = new RestModelChangeTrackerArray<HistogramConfigModel>();
    public registerPushEntries: RegisterPushEntryModel[] = null;
    public instantaneous: boolean = null;
    public readonly isIRestModel = true;
    public registerIndex: number = null;
    public registerName: string = null;
    public registerType: RegisterTypeEnum = null;
    public registerUUID: string = null;
    public statusCode: number = null;
    public tags: string[] = null;
    public customAction: RegisterCustomActionModel = null;

    private _fillColor: string = null;

    private _heightHistograms: Array<HistogramConfigModel> = [];
    private _dwellHistograms: Array<HistogramConfigModel> = [];
    private _instantDwellHistograms: Array<HistogramConfigModel> = [];
    private _lineColor: string = null;
    private _lineIds: Array<number> = null;
    private _polygonIds: Array<number> = null;
    private _registerIds: Array<number> = null;
    private _registerTypeCategory: RegisterTypeCategoryEnum = null;
    private _typeDisplayName: string = null;
    private _typeFaIconName: string = null;
    private _unattendedTimeHistograms: Array<HistogramConfigModel> = [];
    /**
     * This is any to stop circular dependance
     *
     * @private
     * @type {*}
     * @memberof RegisterBaseModel
     */
    private _typeConfig: any = null;
    private _tagDirection: DirectionTypeEnum = null;
    private _tagGroup: string = null;

    public constructor() {
        super();

        this.registerProperty('countMode');
        this.registerProperty('registerName');
        this.registerProperty('registerIndex');
        this.registerProperty('registerType');
        this.registerProperty('instantaneous');
        this.registerProperty('tags');
        this.registerProperty('tagDirection');
        this.registerProperty('tagGroup');
        this.registerChangeTrackerArray('histogramConfigs');
        this.registerProperty('customAction');
        this.registerProperty('registerPushEntries');
    }

    public static equal(registerA: RegisterBaseModel, registerB: RegisterBaseModel): boolean {
        return registerA.registerIndex === registerB.registerIndex && registerA.registerType === registerB.registerType;
    }

    public get tagDirection(): DirectionTypeEnum {
        return this._tagDirection;
    }

    public set tagDirection(value: DirectionTypeEnum) {
        this._tagDirection = value;
    }

    public get tagGroup(): string {
        return this._tagGroup;
    }

    public set tagGroup(value: string) {
        this._tagGroup = value;
    }

    /**
     * Is a direct line host.
     *
     * @readonly
     * @type {boolean}
     * @memberof RegisterBaseModel
     */
    public get isLineHost(): boolean {
        return getConfigBooleanValue(this._typeConfig, 'isLineHost');
    }

    public get isPolygonHost(): boolean {
        return getConfigBooleanValue(this._typeConfig, 'isPolygonHost');
    }

    /**
     * The ids of all associated lines.
     * If lines are updated call setIndexes().
     *
     * @readonly
     * @type {Array<number>}
     * @memberof RegisterBaseModel
     */
    public get lineIds(): Array<number> {
        return this._lineIds;
    }

    /**
     * The ids of all associated polygons.
     * If polygons are updated call setIndexes().
     *
     * @readonly
     * @type {Array<number>}
     * @memberof RegisterBaseModel
     */
    public get polygonIds(): Array<number> {
        return this._polygonIds;
    }

    /**
     * The ids of all associated registers.
     * If registers are updated call setIndexes().
     *
     * @readonly
     * @type {Array<number>}
     * @memberof RegisterBaseModel
     */
    public get registerIds(): Array<number> {
        return this._registerIds;
    }

    public abstract setIndexes(): void;

    public get dwellHistograms(): Array<HistogramConfigModel> {
        return this._dwellHistograms;
    }

    public get fillColor(): string {
        return this._fillColor;
    }

    public get hasDwellHistogram(): boolean {
        return this._dwellHistograms.length > 0;
    }

    public get hasAssociated(): boolean {
        return isNullOrUndefined(this._typeConfig) ? false : isNullOrUndefined(this._typeConfig.associatedRegisterConfigs) ? false : true;
    }

    public get hasHeightHistogram(): boolean {
        return this._heightHistograms.length > 0;
    }

    public get hasInstantDwellHistogram(): boolean {
        return this._instantDwellHistograms.length > 0;
    }

    public get hasUnattendedTimeHistogram(): boolean {
        return this._unattendedTimeHistograms.length > 0;
    }

    public get heightFilteringEnabled(): boolean {
        return !isNullOrUndefined((this as any).lowerBound) && !isNullOrUndefined((this as any).upperBound);
    }

    public get heightHistogram(): HistogramConfigModel {
        return isNullOrUndefined(this._heightHistograms) || this._heightHistograms.length === 0 ? null : this._heightHistograms[0];
    }

    public get heightHistograms(): Array<HistogramConfigModel> {
        return this._heightHistograms;
    }

    public get instantDwellHistograms(): Array<HistogramConfigModel> {
        return this._instantDwellHistograms;
    }

    public get isCountModeCompatible(): boolean {
        return getConfigBooleanValue(this._typeConfig, 'hasCountMode');
    }

    public get isHeightFilteringCompatible(): boolean {
        return getConfigBooleanValue(this._typeConfig, 'hasHeightFiltering');
    }

    public get isViewPortVisibleInViewPort(): boolean {
        return isNullOrUndefined(this._typeConfig) ? true : getConfigBooleanValue(this._typeConfig.viewPort, 'visible', false);
    }

    public get canSnapTo(): boolean {
        return getConfigBooleanValue(this._typeConfig, 'canSnapTo', false);
    }

    public get isCopyable(): boolean {
        return isNullOrUndefined(this._typeConfig) ? false : getConfigBooleanValue(this._typeConfig, 'isCopyable', false);
    }

    public get isZoneCompatible(): boolean {
        return getConfigBooleanValue(this._typeConfig, 'hasZone');
    }

    public get lineColor(): string {
        return this._lineColor;
    }

    public get registerTypeCategory(): RegisterTypeCategoryEnum {
        return this._registerTypeCategory;
    }

    public get typeDisplayName(): string {
        return this._typeDisplayName;
    }

    public get typeFaIconName(): string {
        return this._typeFaIconName;
    }

    public get unattendedTimeHistograms(): Array<HistogramConfigModel> {
        return this._unattendedTimeHistograms;
    }

    public loadFromRestApiModel(restModel: any): void {
        this.registerType = RegisterTypeEnumHelpers.fromRestApi(restModel.RegisterType);

        this.customAction = RestModelUtility.loadFrom(restModel.CustomAction, RegisterCustomActionModel);

        this.histogramConfigs = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.HistogramConfigs, HistogramConfigModel);
        this.setHistogramConfigFlags();

        this.registerPushEntries = RestModelUtility.loadFromArray(restModel.RegisterPushEntries, RegisterPushEntryModel);

        this.setPropertyOriginalValue('countMode', this.countMode);
        this.setPropertyOriginalValue('registerName', this.registerName);
        this.setPropertyOriginalValue('registerIndex', this.registerIndex);
        this.setPropertyOriginalValue('registerType', this.registerType);
        this.setPropertyOriginalValue('instantaneous', this.instantaneous);
        this.setPropertyOriginalValue('histogramConfigs', this.histogramConfigs);
        this.setPropertyOriginalValue('registerPushEntries', this.registerPushEntries);
        this.setPropertyOriginalValue('tags', this.tags);
        this.setPropertyOriginalValue('customAction', this.customAction);

        this.setFlags();
    }

    public setFlags(): void {
        this._typeConfig = RegisterTypeConfig[this.registerType];

        if (!isNullOrUndefined(this.registerIndex)) {
            this._fillColor = Settings.register.colors[this.registerIndex].fillColor;
            this._lineColor = Settings.register.colors[this.registerIndex].lineColor;
        }

        this._typeFaIconName = this._typeConfig.faIconName;
        this._typeDisplayName = isNullOrUndefined(this._typeConfig.category) ? 'No Name Configured' : this._typeConfig.displayName;
        this._registerTypeCategory = isNullOrUndefined(this._typeConfig.category) ? RegisterTypeCategoryEnum.line : this._typeConfig.category;

        this._heightHistograms = [];
        this._dwellHistograms = [];
        this._unattendedTimeHistograms = [];
        this._instantDwellHistograms = [];

        const histogramConfigsLength = this.histogramConfigs.length;
        if (histogramConfigsLength > 0) {
            this._heightHistograms = [];
            this._dwellHistograms = [];
            this._unattendedTimeHistograms = [];
            this._instantDwellHistograms = [];

            for (let index = 0; index < histogramConfigsLength; index++) {
                const histogramConfig = this.histogramConfigs[index];

                if (histogramConfig.expression.startsWith('h')) {
                    this._heightHistograms.push(histogramConfig);
                }

                if (histogramConfig.expression.startsWith('X')) {
                    this._dwellHistograms.push(histogramConfig);
                }

                if (this.registerType === RegisterTypeEnum.staffAttend && histogramConfig.expression.startsWith('I')) {
                    this._unattendedTimeHistograms.push(histogramConfig);
                }

                if (this.registerType === RegisterTypeEnum.zoneOccupancy && histogramConfig.expression.startsWith('I')) {
                    this._instantDwellHistograms.push(histogramConfig);
                }
            }

            const heightHistogramsLength = this._heightHistograms.length;
            for (let index = 0; index < heightHistogramsLength; index++) {
                this._heightHistograms[index].type = HistogramTypeEnum.height;
            }

            const dwellHistogramsLength = this._dwellHistograms.length;
            for (let index = 0; index < dwellHistogramsLength; index++) {
                this._dwellHistograms[index].type = HistogramTypeEnum.dwell;
            }

            const unattendedTimeHistogramsLength = this._unattendedTimeHistograms.length;
            for (let index = 0; index < unattendedTimeHistogramsLength; index++) {
                this._unattendedTimeHistograms[index].type = HistogramTypeEnum.unattendedTime;
            }

            const instantDwellHistogramsLength = this._instantDwellHistograms.length;
            for (let index = 0; index < instantDwellHistogramsLength; index++) {
                this._instantDwellHistograms[index].type = HistogramTypeEnum.instantDwell;
            }
        }

        if (this.tags != null) {
            this._tagDirection = DirectionTypeEnum.UNSET;
            this._tagGroup = null;

            this.tags.forEach(tag => {
                if (tag === 'direction=IN') {
                    this._tagDirection = DirectionTypeEnum.IN;
                } else if (tag === 'direction=OUT') {
                    this._tagDirection = DirectionTypeEnum.OUT;
                } else if (tag.startsWith('group=')) {
                    this._tagGroup = tag.substring('group='.length);
                }
            });
        } else {
            this._tagDirection = DirectionTypeEnum.UNSET;
            this._tagGroup = null;
        }

        this.setPropertyOriginalValue('tagDirection', this._tagDirection);
        this.setPropertyOriginalValue('tagGroup', this._tagGroup);
    }

    public setHistogramConfigFlags(): void {
        if (!isNullOrUndefined(this.histogramConfigs)) {
            const histogramConfigsLength = this.histogramConfigs.length;
            if (histogramConfigsLength > 0) {
                for (let i = 0; i < histogramConfigsLength; i++) {
                    const config = this.histogramConfigs[i];
                    config.Id = i;
                }
            }
        }
    }

    public toRestApiModel(): any {
        if (isNullOrUndefined(this.tags)) {
            this.tags = [];
        }

        const directionTagIndex = this.tags.findIndex((val) => {
            if (val.startsWith('direction=')) {
                return true;
            }

            return false;
        });

        if (directionTagIndex !== -1) {
            this.tags.splice(directionTagIndex, 1);
        }

        if (this._tagDirection === DirectionTypeEnum.IN) {
            this.tags.push('direction=IN');
        } else if (this._tagDirection === DirectionTypeEnum.OUT) {
            this.tags.push('direction=OUT');
        }

        const groupTagIndex = this.tags.findIndex((val) => {
            if (val.startsWith('group=')) {
                return true;
            }

            return false;
        });

        if (groupTagIndex !== -1) {
            this.tags.splice(groupTagIndex, 1);
        }

        if (!isNullOrUndefined(this._tagGroup) && this._tagGroup !== '') {
            this.tags.push('group=' + this._tagGroup);
        }

        return {
            ...RestModelUtility.toJson(this, ['registerType', 'registerName', ...REGISTER_BASE_EXCLUDE_PROPERTIES]),
            registerName: this.registerName,
            registerType: RegisterTypeEnumHelpers.toRestApi(this.registerType),
            HistogramConfigs: this.histogramConfigs.toRestApiModel(),
            Tags: this.tags,
            CustomAction: isNullOrUndefined(this.customAction) ? (new RegisterCustomActionModel()).toRestApiModel() : this.customAction.toRestApiModel(),
            RegisterPushEntries: RestModelUtility.toJsonArray(this.registerPushEntries),
        };
    }

    protected setIndexesBase(lineIds: Array<number>, registerIds: Array<number>, polygonIds: Array<number>): void {
        // line indexes
        this._lineIds = [];
        if (!isNullOrUndefined(lineIds)) {
            const lineIdsLength = lineIds.length;
            for (let index = 0; index < lineIdsLength; index++) {
                const id = lineIds[index];
                if (!isNullOrUndefined(id) && this._lineIds.indexOf(id) === -1) {
                    this._lineIds.push(id);
                }
            }
        }

        // register indexes
        this._registerIds = [];
        if (!isNullOrUndefined(registerIds)) {
            const registerIdsLength = registerIds.length;
            for (let index = 0; index < registerIdsLength; index++) {
                const id = registerIds[index];
                if (!isNullOrUndefined(id) && this._registerIds.indexOf(id) === -1) {
                    this._registerIds.push(id);
                }
            }
        }

        // polygon indexes
        this._polygonIds = [];
        if (!isNullOrUndefined(polygonIds)) {
            const polygonIdsLength = polygonIds.length;
            for (let index = 0; index < polygonIdsLength; index++) {
                const id = polygonIds[index];
                if (!isNullOrUndefined(id) && this._polygonIds.indexOf(id) === -1) {
                    this._polygonIds.push(id);
                }
            }
        }
    }
}
