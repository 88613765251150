import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';

/**
 * Manages collections of ProcessMonitorServiceProcess.
 *
 * @export
 * @class ProcessTracker
 */
export class ProcessTracker {
    private _processes: Array<ProcessMonitorServiceProcess> = [];

    /**
     *Creates an instance of ProcessTracker.
     *
     * @memberof ProcessTracker
     */
    public constructor() {
    }

    /**
     * Adds a process to the tracker.
     *
     * @param {ProcessMonitorServiceProcess} item The process to add.
     * @returns {ProcessMonitorServiceProcess} The added process.
     * @memberof ProcessTracker
     */
    public track(item: ProcessMonitorServiceProcess): ProcessMonitorServiceProcess {
        this._processes.push(item);
        return item;
    }

    /**
     * True if any of the processes are running. else false
     *
     * @readonly
     * @type {boolean}
     * @memberof ProcessTracker
     */
    public get isRunning(): boolean {
        return this._processes.some(i => i.isRunning === true);
    }

    /**
     * True if any of the processes have errors. else false
     *
     * @readonly
     * @type {boolean}
     * @memberof ProcessTracker
     */
    public get hasError(): boolean {
        return this._processes.some(i => i.hasError === true);
    }
}
