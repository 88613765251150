<div mat-dialog-title>
    <mat-card-title>
        <span>
            Group Edit
        </span>
    </mat-card-title>
    <div fxLayout="column"
        fxLayoutAlign="start stretch">
        <mat-form-field *ngIf="!isNullOrUndefined(data.addressBook.selectedGroup)">
            <input matInput
                [disabled]="data.addressBook.selectedGroup.noEdit === true"
                placeholder="Group Name"
                (keyup)="onNameChanged()"
                [(ngModel)]="data.addressBook.selectedGroup.name">
        </mat-form-field>
    </div>
</div>
<div class="content" mat-dialog-content>
    <em-settings-address-book-edit #edit
        (filterValidChanged)="onFilterValidChanged($event)"
        (filterChanged)="onFilterChanged()"
        (deleteCriteria)="onDeleteCriteria($event)"
        (addCriteria)="onAddCriteria($event)"
        (addCriteriaGroup)="onAddCriteriaGroup($event)"
        [addressBook]="data.addressBook"
        [selectedGroup]="data.selectedGroup"
        [filterOnEntriesSettings]="data.filterOnEntriesSettings"
        [filterOnEntriesMeta]="data.filterOnEntriesMeta">
    </em-settings-address-book-edit>
</div>
<div mat-dialog-actions>
    <button (click)="complete()"
        [disabled]="edit.filtersValid === false"
        mat-raised-button
        color="warn">OK</button>
    <div fxFlex></div>
</div>