import { REGISTER_BASE_EXCLUDE_PROPERTIES, RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterCompareGreaterThanModel extends IRegisterBaseModel {
    greaterThanRegister: number;
    greaterThanValue: number;
}

export class RegisterCompareGreaterThanModel extends RegisterBaseModel implements IRegisterCompareGreaterThanModel, IRestModel {
    public greaterThanRegister: number = null;
    public greaterThanValue: number = null;

    public constructor() {
        super();

        this.registerProperty('greaterThanRegister');
        this.registerProperty('greaterThanValue');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterCompareGreaterThanModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('greaterThanRegister', this.greaterThanRegister);
        this.setPropertyOriginalValue('greaterThanValue', this.greaterThanValue);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], [this.greaterThanRegister], []);
    }
}
