/**
 * Sort direction enums.
 *
 * @export
 * @enum {number}
 */
export enum SortDirectionEnum {
    /**
     * Ascending order.
     */
    ascending = 0,
    /**
     * Descending order.
     */
    descending = 1
}
