function FlattenFilter(frame, videoSettings, manualAlpha) {
    this.Filter_constructor();

    this.usesContext = true;

    this._frame = frame;
    this._videoSettings = videoSettings;
    this._manualAlpha = manualAlpha;
    this._texture = null;
    this._textureBuffer = null;
    this._glContext = null;

    this.FRAG_SHADER_BODY = (
        'precision highp float;\n' +
        'uniform float fragScale;\n' +
        'uniform float fragOffsetX;\n' +
        'uniform float fragOffsetY;\n' +
        'uniform float fragPixelPitch;\n' +
        'uniform float fragA;\n' +
        'uniform int fragInputWidth;\n' +
        'uniform int fragInputHeight;\n' +
        'uniform int fragDestWidth;\n' +
        'uniform int fragDestHeight;\n' +
        'uniform float fragCropWindowWidth;\n' +
        'uniform float fragCropWindowHeight;\n' +
        'uniform mat3 fragTiltRotation;\n' +
        'uniform float fragDeviceHeight;\n' +
        'uniform float fragCoeffA;\n' +
        'uniform float fragCoeffK;\n' +
        'uniform float fragCoeffE;\n' +
        'uniform int useManualAlpha;\n' +
        'uniform float manualAlpha;\n' +
        'varying vec3 vPosition;\n' +
        'void main(void){\n' +
        '    vec2 textureSize = vec2(fragInputWidth, fragInputHeight);\n' +
        '    float xPosPre = ((float(int(floor(gl_FragCoord.x)) - (fragDestWidth / 2)) + 0.5) * fragPixelPitch);\n' +
        '    float yPosPre = -((float(int(floor(gl_FragCoord.y)) - (fragDestHeight / 2)) + 0.5) * fragPixelPitch);\n' +
        '    vec3 transformedPos = fragTiltRotation * vec3(xPosPre, yPosPre, -fragDeviceHeight);\n' +
        '    float xPos = transformedPos.x;\n' +
        '    float yPos = transformedPos.y;\n' +
        '    float d = sqrt((xPos * xPos) + (yPos * yPos));\n' +
        '    float localFragScale = fragCoeffK / (fragCoeffA * (-transformedPos.z - fragCoeffE));\n' +
        '    float D = fragA * atan(d * localFragScale);\n' +
        '    float winX = fragCropWindowWidth / 1600.0;\n' +
        '    float winY = fragCropWindowHeight / 1600.0;\n' +
        '    float X = (xPos / d) * D;\n' +
        '    float Y = (yPos / d) * D;\n' +
        '    X = (((X + fragOffsetX) / winX) + 1.0) * float(fragInputWidth / 2) - 0.5;\n' +
        '    Y = (((Y + fragOffsetY) / winY) + 1.0) * float(fragInputHeight / 2) - 0.5;\n' +
        '    int S = int(floor(X));\n' +
        '    int T = int(floor(Y));\n' +
        '    int U = S + 1;\n' +
        '    int V = T + 1;\n' +
        '    if(S < fragInputWidth && S > 0 && T < fragInputHeight && T > 0 && U < fragInputWidth && U > 0 && V < fragInputHeight && V > 0){\n' +
        '        float DX = X - float(S);\n' +
        '        float DY = Y - float(T);\n' +
        '        \n' +
        '        float weight00 = (1.0 - DX) * (1.0 - DY);\n' +
        '        float weight10 = DX * (1.0 - DY);\n' +
        '        float weight01 = (1.0 - DX) * DY;\n' +
        '        float weight11 = DX * DY;\n' +
        '        \n' +
        '        vec2 srcIndex0 = vec3(S, T, 1).xy;\n' +
        '        \n' +
        '        vec2 srcIndex1 = vec3(U, T, 1).xy;\n' +
        '        \n' +
        '        vec2 srcIndex2 = vec3(S, V, 1).xy;\n' +
        '        \n' +
        '        vec2 srcIndex3 = vec3(U, V, 1).xy;\n' +
        '        \n' +
        '        vec4 val0 = texture2D(uSampler, srcIndex0 / textureSize);\n' +
        '        vec4 val1 = texture2D(uSampler, srcIndex1 / textureSize);\n' +
        '        vec4 val2 = texture2D(uSampler, srcIndex2 / textureSize);\n' +
        '        vec4 val3 = texture2D(uSampler, srcIndex3 / textureSize);\n' +
        '        float red = (weight00 * val0.x) + (weight10 * val1.x) + (weight01 * val2.x) + (weight11 * val3.x);\n' +
        '        float green = (weight00 * val0.y) + (weight10 * val1.y) + (weight01 * val2.y) + (weight11 * val3.y);\n' +
        '        float blue = (weight00 * val0.z) + (weight10 * val1.z) + (weight01 * val2.z) + (weight11 * val3.z);\n' +
        '        float alpha = (weight00 * val0.w) + (weight10 * val1.w) + (weight01 * val2.w) + (weight11 * val3.w);\n' +
        '        if(useManualAlpha == 1){\n' + 
        '           vec4 endColour = vec4(red, green, blue, manualAlpha);\n'+
        '           gl_FragColor = endColour;\n' +
        '        }\n' +
        '        else{\n' +
        '           vec4 endColour = vec4(red, green, blue, alpha);\n' +
        '           gl_FragColor = endColour;\n' +
        '        }\n' +
        '    }\n' +
        '    else{\n' +
        '        gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);\n' +
        '    }\n' +
        '}\n'
    );
}
var p = FlattenFilter.prototype = Object.create(createjs.Filter.prototype);
p.constructor = FlattenFilter;

p.toString = function () {
    return "[FlattenFilter]";
};

p.clone = function () {
    return new FlattenFilter(this._videoSettings);
};

p.shaderParamSetup = function (gl, stage, shaderProgram) {
    this._glContext = gl;
    var uvPosition = gl.getAttribLocation(shaderProgram, "uvPosition");
    var uSampler = gl.getUniformLocation(shaderProgram, "uSampler");

    var fragScale = gl.getUniformLocation(shaderProgram, 'fragScale');
    var fragOffsetX = gl.getUniformLocation(shaderProgram, 'fragOffsetX');
    var fragOffsetY = gl.getUniformLocation(shaderProgram, 'fragOffsetY');
    var fragPixelPitch = gl.getUniformLocation(shaderProgram, 'fragPixelPitch');
    var fragA = gl.getUniformLocation(shaderProgram, 'fragA');
    var fragInputWidth = gl.getUniformLocation(shaderProgram, 'fragInputWidth');
    var fragInputHeight = gl.getUniformLocation(shaderProgram, 'fragInputHeight');
    var fragDestWidth = gl.getUniformLocation(shaderProgram, 'fragDestWidth');
    var fragDestHeight = gl.getUniformLocation(shaderProgram, 'fragDestHeight');
    var fragCropWindowWidth = gl.getUniformLocation(shaderProgram, 'fragCropWindowWidth');
    var fragCropWindowHeight = gl.getUniformLocation(shaderProgram, 'fragCropWindowHeight');
    var fragTiltRotation = gl.getUniformLocation(shaderProgram, "fragTiltRotation");
    var fragDeviceHeight = gl.getUniformLocation(shaderProgram, "fragDeviceHeight");
    var fragCoeffK = gl.getUniformLocation(shaderProgram, "fragCoeffK");
    var fragCoeffA = gl.getUniformLocation(shaderProgram, "fragCoeffA");
    var fragCoeffE = gl.getUniformLocation(shaderProgram, "fragCoeffE");
    var useManualAlpha = gl.getUniformLocation(shaderProgram, "useManualAlpha");
    var manualAlpha = gl.getUniformLocation(shaderProgram, "manualAlpha");

    this._textureBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this._textureBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(this._videoSettings.model.textureCoords), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ARRAY_BUFFER, null);

    this._texture = gl.createTexture();

    // load frame
    gl.bindTexture(gl.TEXTURE_2D, this._texture);
    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, this._frame);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR); //gl.NEAREST is also allowed, instead of gl.LINEAR, as neither mipmap.
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE); //Prevents s-coordinate wrapping (repeating).
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE); //Prevents t-coordinate wrapping (repeating).

    gl.bindTexture(gl.TEXTURE_2D, null);

    // flatten frame
    gl.enableVertexAttribArray(uvPosition);

    gl.bindBuffer(gl.ARRAY_BUFFER, this._textureBuffer);
    gl.vertexAttribPointer(uvPosition, 2, gl.FLOAT, false, 0, 0);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, this._texture);
    gl.uniform1i(uSampler, 0);

    gl.uniform1f(fragScale, this._videoSettings.fragScale);
    gl.uniform1f(fragOffsetX, this._videoSettings.fragOffsetX);
    gl.uniform1f(fragOffsetY, this._videoSettings.fragOffsetY);
    gl.uniform1f(fragPixelPitch, this._videoSettings.coefficients.pixelPitch);
    gl.uniform1f(fragA, this._videoSettings.coefficients.a);

    gl.uniform1i(fragInputWidth, this._frame.width);
    gl.uniform1i(fragInputHeight, this._frame.height);
    gl.uniform1i(fragDestWidth, this._videoSettings.flattenedWidth);
    gl.uniform1i(fragDestHeight, this._videoSettings.flattenedHeight);

    gl.uniform1f(fragCropWindowWidth, this._videoSettings.fragCropWindowWidth);
    gl.uniform1f(fragCropWindowHeight, this._videoSettings.fragCropWindowHeight);

    gl.uniformMatrix3fv(fragTiltRotation, false, this._videoSettings.fragTiltRotation);

    gl.uniform1f(fragDeviceHeight, this._videoSettings.fragDeviceHeight);
    gl.uniform1f(fragCoeffA, this._videoSettings.coefficients.a);
    gl.uniform1f(fragCoeffK, this._videoSettings.coefficients.k);
    gl.uniform1f(fragCoeffE, this._videoSettings.coefficients.e);

    if (this._manualAlpha == null){
        gl.uniform1i(useManualAlpha, 0);
        gl.uniform1f(manualAlpha, 1.0);
    }
    else{
        gl.uniform1i(useManualAlpha, 1);
        gl.uniform1f(manualAlpha, this._manualAlpha);
    }

    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, false);

    // this.outputToConsole();
};

p.outputToConsole = function () {
    const data = {
        fragScale: this._videoSettings.fragScale,
        fragOffsetX: this._videoSettings.fragOffsetX,
        fragOffsetY: this._videoSettings.fragOffsetY,
        fragPixelPitch: this._videoSettings.coefficients.pixelPitch,
        fragA: this._videoSettings.coefficients.a,
        fragInputWidth: this._frame.width,
        fragInputHeight: this._frame.height,
        fragDestWidth: this._videoSettings.flattenedWidth,
        fragDestHeight: this._videoSettings.flattenedHeight,
        fragCropWindowWidth: this._videoSettings.fragCropWindowWidth,
        fragCropWindowHeight: this._videoSettings.fragCropWindowHeight,
        fragTiltRotation: this._videoSettings.fragTiltRotation,
        fragDeviceHeight: this._videoSettings.fragDeviceHeight,
        fragCoeffA: this._videoSettings.coefficients.a,
        fragCoeffK: this._videoSettings.coefficients.k,
        fragCoeffE: this._videoSettings.coefficients.e,
    };
    console.log(`shader data:${JSON.stringify(data)}`);
}

p.applyFilter = function (ctx, x, y, width, height, targetCtx, targetX, targetY) {
    targetCtx = targetCtx || ctx;

    if (this._frame instanceof HTMLImageElement) {
        targetCtx.drawImage(this._frame, x, y, this._videoSettings.flattenedWidth, this._videoSettings.flattenedHeight);
        targetCtx.restore();
    }
};

p.destroy = function () {
    if (this._glContext !== null) {
        this._glContext.deleteTexture(this._texture);
        this._glContext.deleteBuffer(this._textureBuffer);

        this._texture = null;
        this._textureBuffer = null;
    }

    this._frame = null;
};

var flattenFilter = createjs.promote(FlattenFilter, "Filter");

module.exports.FlattenFilter = flattenFilter;