<div class="bottom-info-component shr-bottominfo-message-component sync-videos" fxLayout="column">
    <div class="sync-video" *ngFor="let vm of downloadingDeviceSessions | async" fxLayout="row"
        fxLayoutAlign="start center">
        <div class="state-text" [matTooltip]="vm.stateText">
            {{vm.stateText}}
        </div>
        <div class="state-icon">
            <mat-icon matTooltip="{{vm.onDevice.isComplete === true ? 'Synchronized' : 'Partially Synchronized'}}"
                [style.color]="vm.onDevice.isComplete === true ? 'blue' : 'white'">
                cloud_download</mat-icon>
        </div>
        <div class="progress">
            <mat-progress-bar *ngIf="vm.onDevice.isActive === true && vm.onDevice.isQueued === false" mode="determinate"
                [value]="vm.onDevice.progress"></mat-progress-bar>
        </div>
        <div class="actions">
            <button
                *ngIf="vm.onDevice.isProcessing === false && vm.onDevice.isComplete === false && vm.onDevice.isActive === true && vm.onDevice.isPaused === true"
                matTooltip="Resume Synchronization" mat-icon-button (click)="resumeDownloadSession(vm)">
                <mat-icon>cloud_download</mat-icon>
            </button>
            <button
                *ngIf="isReadOnly === false && vm.onDevice.isProcessing === false && vm.onDevice.isActive === true && vm.onDevice.isPaused === false"
                matTooltip="Cancel Synchronization" mat-icon-button (click)="cancelDownloadSession(vm)">
                <mat-icon>cancel</mat-icon>
            </button>
            <button
                *ngIf="isReadOnly === false && vm.onDevice.isProcessing === false && vm.onDevice.isActive === true && vm.onDevice.isPaused === false"
                matTooltip="Pause Synchronization" mat-icon-button (click)="pauseDownloadSession(vm)">
                <mat-icon>pause</mat-icon>
            </button>
        </div>
    </div>
</div>
