import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class VideoEntryCountsModel extends BaseModel implements IRestModel {
    public canceled: number = null;
    public paused: number = null;
    public queued: number = null;
    public readonly isIRestModel = true;

    public recording: number = null;
    public syncing: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-VideoEntryCountsModel');
    }

    public toRestApiModel(): any {
        throw new Error('Method not implemented.');
    }
}
