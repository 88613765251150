import { Component, Inject, Injector, HostBinding } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';
import { BookmarkModel } from '@rift/models/generic/Bookmark.Model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

export class BookmarkDetailsDialogData {
    public constructor(public mode: 'new' | 'view', public bookmark?: BookmarkModel) {

    }
}

export class BookmarkDetailsDialogResult {
    public constructor(public action: 'add' | 'delete' | 'none', public bookmark: BookmarkModel) {

    }
}

@Component({
    selector: 'rift-bookmark-details-dialog',
    templateUrl: './BookmarkDetails.Dialog.Component.html',
    styleUrls: ['./BookmarkDetails.Dialog.Component.scss']
})
export class BookmarkDetailsDialogComponent extends BaseComponent {
    @HostBinding()
    public id: string = 'rift-bookmark-details-dialog';

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: BookmarkDetailsDialogData,
        private readonly _dialogRef: MatDialogRef<BookmarkDetailsDialogComponent>,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        if (this.data.mode === 'new') {
            this.data.bookmark = new BookmarkModel();
        }

    }

    public save(): void {
        this._dialogRef.close(new BookmarkDetailsDialogResult('add', this.data.bookmark));
    }

    public close(): void {
        this._dialogRef.close(new BookmarkDetailsDialogResult('none', this.data.bookmark));
    }

    public delete(): void {
        this._dialogRef.close(new BookmarkDetailsDialogResult('delete', this.data.bookmark));
    }
}
