import { ShapeModel } from '@rift/models/restapi/ShapeModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IRestModel } from '@shared/interface/IRestModel';

export interface IPolygonModel extends IRestModel {
    iD: number;
    enabled: boolean;
    name: string;
}

export class PolygonModel extends ShapeModel implements IPolygonModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public iD: number = null;
    public enabled: boolean = null;
    public name: string = null;

    public constructor() {
        super();

        this.registerProperty('iD');
        this.registerProperty('enabled');
        this.registerProperty('name');
    }

    public loadFromRestApiModel(restModel: any): void {
        super.loadFromRestApiModel(restModel);

        RestModelUtility.loadProperties(restModel, this, 'RIFT-PolygonModel');

        this.setPropertyOriginalValue('iD', this.iD);
        this.setPropertyOriginalValue('enabled', this.enabled);
        this.setPropertyOriginalValue('name', this.name);
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
        };
    }
}
