<div class="rift-managesynchronizedrecordings-title"
    mat-dialog-title>
    <div fxLayout="row"
        fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Manage Locally Cached Validations
            </div>
            <div class="subheader">
                Deletion may take several minutes
            </div>
        </div>
        <div fxFlex></div>
        <div class="device-toggle">
            This Device <mat-slide-toggle (change)="onDeviceSelectChange($event)"></mat-slide-toggle> All Devices
        </div>
    </div>
</div>
<div class="rift-managesynchronizedrecordings-content"
    mat-dialog-content
    fxLayout="column">
    <shr-table [hideMatCard]="true"
        matSort
        #sort="matSort"
        (filter)="applyFilter($event)"
        [sort]="sort"
        [dataSource]="dataSource"
        [displayedColumns]="displayedColumns">
        <ng-template #shrTableActions
            let-vm>
            <div fxLayout="row"
                fxLayoutAlign="start center">
                <button *ngIf="deleteNodesProcess.isRunning === false && deleteMasterProcess.isRunning === false && cancelProcess.isRunning === false"
                    matTooltip="Delete"
                    mat-icon-button
                    (click)="delete(vm)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </ng-template>
        <ng-container matColumnDef="friendlySerial">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header="friendlySerial"> Serial </th>
            <td mat-cell
                *matCellDef="let vm"
                matTooltip="{{vm.item.friendlySerial}}">
                {{vm.item.friendlySerial}} {{isNullOrUndefined(vm.item.onNodes) ? '' : '( +' + vm.item.onNodes.length + ' Nodes)'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header="startDate"> Date </th>
            <td mat-cell
                *matCellDef="let vm"
                matTooltip="{{vm.startDateText}}">
                {{vm.startDateText}}
            </td>
        </ng-container>

        <ng-container matColumnDef="start">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header="startTime"> Start </th>
            <td mat-cell
                *matCellDef="let vm"
                matTooltip="{{vm.startTimeText}}">
                {{vm.startTimeText}}
            </td>
        </ng-container>

        <ng-container matColumnDef="duration">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header="durationText"> Duration </th>
            <td mat-cell
                *matCellDef="let vm"
                matTooltip="{{vm.durationText}}">
                {{vm.durationText}}
            </td>
        </ng-container>

        <ng-container matColumnDef="end">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header="endTime"> End </th>
            <td mat-cell
                *matCellDef="let vm"
                matTooltip="{{vm.endTimeText}}">
                {{vm.endTimeText}}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell
                *matHeaderCellDef> Status </th>
            <td mat-cell
                *matCellDef="let vm"
                matTooltip="{{vm.status}}"
                style="width: 200px;">
                <ng-template [ngIf]="vm.isDeleting === true">
                    <div fxLayout="row">
                        <mat-spinner diameter="25"></mat-spinner> Deleting
                    </div>
                </ng-template>
                <ng-template [ngIf]="vm.isDeleting !== true">
                    {{vm.status}}
                </ng-template>
            </td>
        </ng-container>
    </shr-table>
</div>
<div class="rift-managesynchronizedrecordings-actions"
    mat-dialog-actions>
    <button (click)="close()"
        [disabled]="deleteNodesProcess.isRunning === true || deleteMasterProcess.isRunning === true || deleteAllProcess.isRunning === true || cancelProcess.isRunning === true"
        mat-raised-button
        color="primary">Close</button>
    <div fxFlex></div>
</div>