<div shrResized
    #mainContent
    (resized)="onContentResize($event)">
    <shr-nav-bar-pagination>
        <nav mat-tab-nav-bar #matTabNav>
            <a mat-tab-link
                [routerLink]="summaryRelativeUrl"
                routerLinkActive
                #summary="routerLinkActive"
                [active]="summary.isActive">
                Summary
            </a>
            <a mat-tab-link
                [routerLink]="settingsRelativeUrl"
                routerLinkActive
                #settings="routerLinkActive"
                [active]="settings.isActive">
                Settings
            </a>
            <a mat-tab-link
                [routerLink]="diagnosticsRelativeUrl"
                routerLinkActive
                #diagnostics="routerLinkActive"
                [active]="diagnostics.isActive">
                Diagnostics
            </a>
            <a mat-tab-link
                [routerLink]="metaDataRelativeUrl"
                routerLinkActive
                #metaData="routerLinkActive"
                [active]="metaData.isActive">
                Meta Data
            </a>
            <a mat-tab-link
                *ngIf="(isHostMaster() | async) === true"
                [routerLink]="countsRelativeUrl"
                routerLinkActive
                #counts="routerLinkActive"
                [active]="counts.isActive">
                Counts Graph
            </a>
            <a mat-tab-link
                *ngIf="(isHostMaster() | async) === true && (isDeviceCapable(DeviceCapabilitiesEnum.videoRecording) | async) === true"
                [routerLink]="recordingsRelativeUrl"
                routerLinkActive
                #recordings="routerLinkActive"
                [active]="recordings.isActive">
                Recordings
            </a>
            <a mat-tab-link
                *ngIf="(isHostMaster() | async) === true && (isDeviceCapable(DeviceCapabilitiesEnum.videoRecording) | async) === true"
                [routerLink]="validationRelativeUrl"
                routerLinkActive
                #validation="routerLinkActive"
                [active]="validation.isActive">
                Validation
            </a>
            <a mat-tab-link
                [routerLink]="activityRelativeUrl"
                routerLinkActive
                #activity="routerLinkActive"
                [active]="activity.isActive">
                Activity
            </a>
            <a mat-tab-link
                [routerLink]="locationRelativeUrl"
                routerLinkActive
                #location="routerLinkActive"
                [active]="location.isActive">
                Location
            </a>
            <a mat-tab-link
                [routerLink]="healthRelativeUrl"
                routerLinkActive
                #health="routerLinkActive"
                [active]="health.isActive">
                Health
            </a>
        </nav>
    </shr-nav-bar-pagination>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>