import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export enum LoginResultEnum {
    invalid = 0,
    valid = 1,
    locked = 2,
}

export interface ILoginAttemptResultModel extends IRestModel{
    role: string;
    result: LoginResultEnum;
}

export class LoginAttemptResultModel extends BaseModel implements ILoginAttemptResultModel, IRestModel {

    public readonly isIRestModel = true;
    public role: string = null;
    public result: LoginResultEnum = null;

    public constructor() {
        super();

        this.registerProperty('role');
        this.registerProperty('result');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'SHR-LoginAttemptResultModel');

        switch(restModel.Result){
            case 0:
                this.result = LoginResultEnum.invalid;
                break;
            case 1:
                this.result = LoginResultEnum.valid;
                break;
            case 2:
                this.result = LoginResultEnum.locked;
                break;
        }

        this.setPropertyOriginalValue('role', this.role);
        this.setPropertyOriginalValue('result', this.result);
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
