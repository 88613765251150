<div class="rift-settings-relays-limits-title"
    mat-dialog-title>
    Pulse Width Settings
</div>
<div class="rift-settings-relays-limits-content"
    mat-dialog-content>
    <form [formGroup]="pulseWidthFormGroup"
        fxLayout="column">
        <div fxLayout="row"
            fxLayoutAlign="start center">
            <mat-form-field>
                <input type="number"
                    step="1"
                    #pulseWidth
                    matInput
                    formControlName="pulseWidth"
                    placeholder="Pulse Width">
                <mat-error>{{getFormControlErrorMessage(pulseWidthFormGroup.controls['pulseWidth'])}}</mat-error>
            </mat-form-field>
        </div>
    </form>
</div>
<div class="rift-settings-relays-limits-actions"
    mat-dialog-actions>
    <button [disabled]="!pulseWidthFormGroup.valid"
        (click)="apply()"
        mat-raised-button
        color="primary">Apply</button>
    <div fxFlex></div>
    <button (click)="cancel()"
        mat-raised-button
        color="warn">Cancel</button>
</div>