import { Injectable } from '@angular/core';
import { EmBaseService } from '@em/service/base/EmBase.Service';

@Injectable()
export class EmRiftService extends EmBaseService {
    public masterDeviceId: number;

    public constructor() {
        super();
    }
}
