import {
    Component,
    OnChanges,
    HostBinding,
    Input,
    Injector,
    SimpleChanges,
} from '@angular/core';
import { SettingsCountingBaseComponent } from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RegisterCustomActionModel } from '@rift/models/restapi/RegisterCustomAction.Model';
import { StringUtility } from '@shared/utility/String.Utility';
import { RegisterActionInfoCollectionModel } from '@rift/models/restapi/RegisterActionInfoCollection.Model';
import { RegisterActionInfoModel } from '@rift/models/restapi/RegisterActionInfo.Model';

@Component({
    selector: 'rift-settings-counting-custom-action',
    templateUrl: './Settings.Counting.CustomAction.Component.html',
    styleUrls: ['./Settings.Counting.CustomAction.Component.scss'],
})
export class SettingsCountingCustomActionComponent extends SettingsCountingBaseComponent implements OnChanges {
    public static className: string = 'SettingsCountingCustomActionComponent';

    @HostBinding()
    public id: string = 'rift-settings-counting-custom-action';

    public form: FormGroup;

    @Input()
    public register: RegisterBaseModel = null;

    @Input()
    public availableActions: RegisterActionInfoCollectionModel = null;

    public actions: string[] = ['None'];
    public triggers: string[] = ['onChange'];
    public parameters: string = null;

    public selectedActionInfo: RegisterActionInfoModel = null;

    public constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector
    ) {
        super(_injector);

        this.form = this._formBuilder.group({
            action: [''],
            trigger: [''],
            parameters: [''],
        });

        this.addSubscription(
            this.form.controls.action.valueChanges.subscribe(() => {
                if(this.form.controls.action.value === 'None'){
                    this.selectedActionInfo = null;
                }
                else{
                    for(let i = 0; i < this.availableActions.items.length; i++){
                        if(this.availableActions.items[i].name === this.form.controls.action.value){
                            this.selectedActionInfo = this.availableActions.items[i];
                            break;
                        }
                    }
                }
            })
        );

        this.addSubscription(
            this.form.valueChanges.subscribe(() => {
                if (!this.isNullOrUndefined(this.register)) {
                    if (this.form.controls.action.value === 'None') {
                        this.register.customAction = null;
                        this.form.controls.trigger.setValue('', { emitEvent: false });
                        this.form.controls.trigger.disable({ emitEvent: false });
                        this.form.controls.parameters.setValue('', {emitEvent: false});
                    } else if (!StringUtility.isEmptyOrWhiteSpace(this.form.controls.action.value) && !StringUtility.isEmptyOrWhiteSpace(this.form.controls.trigger.value)) {
                        const actionModel = new RegisterCustomActionModel();
                        actionModel.action = this.form.controls.action.value;
                        actionModel.trigger = this.form.controls.trigger.value;
                        actionModel.parameters = this.form.controls.parameters.value;
                        this.register.customAction = actionModel;
                    } else {
                        this.form.controls.trigger.enable({ emitEvent: false });
                    }
                }
            })
        );

        this.formGroupTracker.track(this.form);

        this.initConnectionState();
    }

    public getSettingsDescription(): string {
        return this.isNullOrUndefined(this.register) || this.isNullOrUndefined(this.register.customAction) ? '' : `${this.register.customAction.trigger} run ${this.register.customAction.action}`;
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this.register)
            ? false
            : this.register.propertyHasChanges('customAction');
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.availableActions) && !this.isNullOrUndefined(changes.availableActions.currentValue) && !this.isNullOrUndefined(changes.availableActions.currentValue.items)) {
            const actionNames = changes.availableActions.currentValue.items.map(x=>x.name);

            this.actions = this.actions.concat(actionNames);
        }
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this.register = changes.register.currentValue;
            this.setFormValues();
        }
    }

    protected offline(): void {
        super.offline();
    }

    protected online(): void {
        super.online();
    }

    private setFormValues(): void {
        if (this.isNullOrUndefined(this.register.customAction)) {
            this.form.controls.action.setValue('None', { emitEvent: false });
            this.form.controls.trigger.disable({ emitEvent: false });
            this.form.controls.parameters.setValue('', {emitEvent: false});
        } else {
            this.form.controls.action.setValue(this.register.customAction.action, { emitEvent: false });
            this.form.controls.trigger.setValue(this.register.customAction.trigger, { emitEvent: false });
            this.form.controls.parameters.setValue(this.register.customAction.parameters, {emitEvent: false});

            for(let i = 0; i < this.availableActions.items.length; i++){
                if(this.availableActions.items[i].name === this.form.controls.action.value){
                    this.selectedActionInfo = this.availableActions.items[i];
                    break;
                }
            }
        }
    }
}
