import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AllDataModel } from '@rift/models/restapi/AllData.Model';
import { BackupFileModel } from '@rift/models/restapi/BackupFile.Model';
import { DiscriminationModel } from '@rift/models/restapi/Discrimination.Model';
import { ErrorsAndWarningsModel } from '@rift/models/restapi/ErrorsAndWarnings.Model';
import { GlobalModel } from '@rift/models/restapi/Global.Model';
import { ImageSnapShotModel } from '@rift/models/restapi/ImageSnapShot.Model';
import { IPSetupModel } from '@rift/models/restapi/IPSetup.Model';
import { RestoreSummaryInfoModel } from '@rift/models/restapi/RestoreSummaryInfo.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { SyncTimeModel } from '@rift/models/restapi/SyncTime.Model';
import { TestConnectionDataModel } from '@rift/models/restapi/TestConnectionData.Model';
import { TestConnectionResultModel } from '@rift/models/restapi/TestConnectionResult.Model';
import { ValidationResultModel } from '@rift/models/restapi/ValidationResult.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { IFileResponse, IRetryOptions } from '@shared/service/restapi/RestApi.Service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class RestApiGlobalService extends RiftRestApiService {
    private _controller = 'global';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public getAllData(process?: ProcessMonitorServiceProcess, retryDisabled: boolean = false): Observable<AllDataModel> {
        return this.get(`${this._controller}/alldata`, AllDataModel, this.getTokenParams(), process, { disabled: retryDisabled }).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getBackup(process?: ProcessMonitorServiceProcess): Observable<IFileResponse> {
        return this.getFile(`${this._controller}/backup`, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDiagnostics(process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ErrorsAndWarningsModel> {
        return this.get(`${this._controller}/diagnostics`, ErrorsAndWarningsModel, this.getTokenParams(), process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDiagnosticsCounts(process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ErrorsAndWarningsModel> {
        return this.get(`${this._controller}/diagnostics/counts`, ErrorsAndWarningsModel, this.getTokenParams(), process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDiscrimination(process?: ProcessMonitorServiceProcess): Observable<DiscriminationModel> {
        return this.get(`${this._controller}/discrimination_data`, DiscriminationModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getGlobal(process?: ProcessMonitorServiceProcess): Observable<GlobalModel> {
        return this.get(`${this._controller}`, GlobalModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getImageSnapshot(process?: ProcessMonitorServiceProcess): Observable<ImageSnapShotModel> {
        return this.get(`${this._controller}/image_snapshot`, ImageSnapShotModel, this.getTokenParams(), process, { disabled: true }).pipe(
            map(result => result)
        );
    }

    public getIpSetup(process?: ProcessMonitorServiceProcess): Observable<IPSetupModel> {
        return this.get(`${this._controller}/ip_setup`, IPSetupModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getLogFile(process?: ProcessMonitorServiceProcess): Observable<IFileResponse> {
        return this.getFile(`${this._controller}/diagnostics/file`, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getRestoreSummaryInfo(backupFile: BackupFileModel, process?: ProcessMonitorServiceProcess): Observable<RestoreSummaryInfoModel> {
        return this.post(`${this._controller}/getrestoresummaryinfo`, backupFile, RestoreSummaryInfoModel, this.getTokenParams(), process, { excludeStatusCodes: [400] });
    }

    public getTimeSetup(process?: ProcessMonitorServiceProcess): Observable<TimeSetupModel> {
        return this.get(`${this._controller}/time`, TimeSetupModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public rebootDevices(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.get(`${this._controller}/reboot_devices`, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public resetCounts(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.get(`${this._controller}/resetcounts`, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public restoreDeviceSettings(backupFile: BackupFileModel, physicalDevice: string, fileDevice: string, restoreIPSettings: boolean, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post(`${this._controller}/restoredevicesettings`, backupFile, ResultModel, this.getTokenParams(['physicalDevice', physicalDevice, 'fileDevice', fileDevice, 'restoreIPSettings', restoreIPSettings.toString()]), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public syncTime(time: SyncTimeModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post(`${this._controller}/synctime`, time, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateDiscrimination(discrimination: DiscriminationModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put(`${this._controller}/discrimination_data`, discrimination, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateGlobal(global: GlobalModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put(`${this._controller}`, global, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateIpSetup(ipSetup: IPSetupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put(`${this._controller}/ip_setup`, ipSetup, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateTimeSetup(timeSetup: TimeSetupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put(`${this._controller}/time`, timeSetup, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public validateNetwork(process?: ProcessMonitorServiceProcess): Observable<ValidationResultModel> {
        return this.get(`${this._controller}/validate`, ValidationResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
