import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DateTimeUtility, TimeZone } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
    selector: '[shrTimezoneTooltip]'
})
export class TimeZoneTooltipDirective extends MatTooltip implements OnChanges {

    @Input()
    public shrTimezoneTooltipFormat: string;

    @Input()
    public shrTimezoneTooltipUTCDate: Date;

    @Input()
    public shrTimezoneTooltipTimezone: TimeZone;

    public ngOnChanges(changes: SimpleChanges): void {
        this.tooltipClass = 'shr-timezone-tooltip';

        if (!isNullOrUndefined(this.shrTimezoneTooltipTimezone) && !isNullOrUndefined(this.shrTimezoneTooltipTimezone.timeOffsetMinutes) && !isNullOrUndefined(this.shrTimezoneTooltipUTCDate) && !isNullOrUndefined(this.shrTimezoneTooltipFormat)) {
            const local = DateTimeUtility.getTimeZoneByTimeOffsetNameShort(Intl.DateTimeFormat().resolvedOptions().timeZone);

            if (!isNullOrUndefined(local) && !isNullOrUndefined(local.timeOffsetMinutes)) {
                const deviceText = `(${this.shrTimezoneTooltipTimezone.timeOffsetShortText}) - ${DateTimeUtility.format(this.shrTimezoneTooltipUTCDate, DateTimeUtility[this.shrTimezoneTooltipFormat], this.shrTimezoneTooltipTimezone)}`;

                if (local.timeOffsetMinutes !== this.shrTimezoneTooltipTimezone.timeOffsetMinutes) {
                    const localText = `(${local.timeOffsetShortText}) - ${DateTimeUtility.format(this.shrTimezoneTooltipUTCDate, DateTimeUtility[this.shrTimezoneTooltipFormat], local)}`;
                    this.message = `${deviceText}\n${localText}`;
                } else {
                    this.message = deviceText;
                }
            }
        }
    }
}
