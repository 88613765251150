import { DeviceInfo } from '@shared/component/deviceinfotooltip/DeviceInfoTooltip.Component';
import { Subject } from 'rxjs';


/**
 * A bread crumb.
 *
 * @export
 * @class BreadCrumb
 */
export class BreadCrumb {
    /**
     *  True if the bread crum is paret of the current route. else false.
     *
     * @type {boolean}
     * @memberof BreadCrumb
     */
    public inRoute: boolean = false;
    /**
     * Event fired when the bread crumb is clicked
     *
     * @type {Subject<void>}
     * @memberof BreadCrumb
     */
    public clicked: Subject<void> = new Subject();
    /**
     * The name to display in the component.
     *
     * @type {string}
     * @memberof BreadCrumb
     */
    public text?: string;
    /**
     * The device info.
     *
     * @type {DeviceInfo}
     * @memberof BreadCrumb
     */
    public deviceInfo: DeviceInfo;
    /**
     * The name.
     *
     * @type {string}
     * @memberof BreadCrumb
     */
    public name: string;
    /**
     * The path to redirect to when the crumb is clicked.
     *
     * @type {string}
     * @memberof BreadCrumb
     */
    public routerLink: string;
    /**
     * The posistion of the bread crumb in the route.
     *
     * @type {number}
     * @memberof BreadCrumb
     */
    public routeIndex: number;
    /**
     * True if has custom event else false.
     *
     * @type {boolean}
     * @memberof BreadCrumb
     */
    public isCustomEvent: boolean;
    /**
     * True if last bread crumb else false.
     *
     * @memberof BreadCrumb
     */
    public isLast: boolean = false;

    /**
     * Creates an instance of BreadCrumb.
     *
     * @param {string} name The name of the bread crumb
     * @param {string} text The display text of the bread crumb
     * @param {string} routerLink The routerLink
     * @param {boolean} [isCustomEvent=false] If true clicked is fired when bread crumb is clicked and not routerlink
     * @memberof BreadCrumb
     */
    public constructor(name: string, text: string, routerLink: string, isCustomEvent: boolean = false) {
        this.name = name;
        this.text = text;
        this.routerLink = routerLink;
        this.isCustomEvent = isCustomEvent;
    }
}
