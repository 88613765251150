import { PointModel, IPointModel } from '@rift/models/restapi/Point.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IShapeModel extends IRestModel {
    points: Array<IPointModel>;
}

export abstract class ShapeModel extends BaseModel implements IShapeModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public points: Array<PointModel> = [];

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ShapeModel');

        this.points = RestModelUtility.loadFromArray(restModel.Points, PointModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, ['countMode']),
            Points: RestModelUtility.toJsonArray(this.points),
        };
    }
}
