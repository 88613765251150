import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { PageModel } from '@em/models/restapi/Page.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { TaskDescriptionModel } from '@em/models/restapi/TaskDescription.Model';
import { TaskToExecuteModel } from '@em/models/restapi/TaskToExecute.Model';
import { EmRestApiService } from '@em/service/restapi/base/EmRestApi.Service';
import { BaseModel } from '@shared/base/Base.Model';
import { TaskToExecuteStateEnum } from '@shared/enum/TaskToExecuteState.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

class GetTaskPackageListResultModel extends BaseModel implements IRestModel {

    public list: Array<TaskDescriptionModel> = null;
    public readonly isIRestModel: boolean = true;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        this.list = RestModelUtility.loadFromArray(restModel.List, TaskDescriptionModel);
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}

class TaskResultModel extends BaseModel implements IRestModel {
    public readonly isIRestModel: boolean = true;

    public result: boolean = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, TaskResultModel.name);
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}

class AddTaskForGroupModel extends BaseModel implements IRestModel {
    public readonly isIRestModel: boolean = true;

    public task: TaskToExecuteModel = null;
    public group: GroupModel = null;

    public constructor(task: TaskToExecuteModel, group: GroupModel) {
        super();

        this.task = task;
        this.group = group;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, TaskResultModel.name);
    }

    public toRestApiModel() {
        return {
            task: this.task.toRestApiModel(),
            group: this.group.toRestApiModel(),
        };
    }
}

@Injectable()
export class RestApiTaskService extends EmRestApiService {
    private _controller = 'task/';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.emRestApiBase, _httpClient);
    }

    public getTaskPage(state: TaskToExecuteStateEnum, paginationOptions?: PaginationOptionsModel, process?: ProcessMonitorServiceProcess): Observable<PageModel<TaskToExecuteModel>> {
        let params = new HttpParams();
        params = params.append('state', StringUtility.toString(state as number));
        params = this.setPaginationOptionsParams(paginationOptions, params);

        return this.getPage<TaskToExecuteModel, PageModel<TaskToExecuteModel>>(`${this._controller}gettasklist`, TaskToExecuteModel, PageModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getTaskResultLog(taskId: number, resultId: number, process?: ProcessMonitorServiceProcess): Observable<string> {
        let params = new HttpParams();
        params = params.append('id', StringUtility.toString(taskId));
        params = params.append('taskResultId', StringUtility.toString(resultId));

        const url = `${this.urlBase}${this._controller}gettaskresultlog`;
        const req = new HttpRequest('GET', url, {
            responseType: 'text/plain',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return (this.request<string, null>(req, 'string', null, process, false) as Observable<string>).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getTaskPackageList(process?: ProcessMonitorServiceProcess): Observable<Array<TaskDescriptionModel>> {
        return this.get<GetTaskPackageListResultModel>(`${this._controller}gettaskpackagelist`, GetTaskPackageListResultModel, null, process).pipe(
            map(result => result.list)
        ).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public addTaskForDevice(task: TaskToExecuteModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.post<TaskToExecuteModel, TaskResultModel>(`${this._controller}addtask`, task, TaskResultModel, null, process).pipe(
            map(result => result.result)
        ).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public addTaskForGroup(task: TaskToExecuteModel, group: GroupModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.post<AddTaskForGroupModel, TaskResultModel>(`${this._controller}addtaskforgroup`, new AddTaskForGroupModel(task, group), TaskResultModel, null, process).pipe(
            map(result => result.result)
        ).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public addTaskForAllDevices(task: TaskToExecuteModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.post<TaskToExecuteModel, TaskResultModel>(`${this._controller}addtaskforalldevices`, task, TaskResultModel, null, process).pipe(
            map(result => result.result)
        ).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteTask(task: TaskToExecuteModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.post<TaskToExecuteModel, TaskResultModel>(`${this._controller}deletetask`, task, TaskResultModel, null, process).pipe(
            map(result => result.result)
        ).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public editTask(task: TaskToExecuteModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.post<TaskToExecuteModel, TaskResultModel>(`${this._controller}edittask`, task, TaskResultModel, null, process).pipe(
            map(result => result.result)
        ).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteTaskPackage(taskPackage: TaskDescriptionModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.post<TaskDescriptionModel, TaskResultModel>(`${this._controller}removetaskpackage`, taskPackage, TaskResultModel, null, process).pipe(
            map(result => result.result)
        ).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

}
