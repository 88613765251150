import { Component, Injector, HostBinding } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { IPSetupModel } from '@rift/models/restapi/IPSetup.Model';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UrlUtility } from '@shared/utility/Url.Utility';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

interface IDevice {
    model: DeviceModel;
    url: string;
}

@Component({
    selector: 'rift-settings-network-select',
    templateUrl: './Settings.Network.Select.Component.html',
    styleUrls: ['./Settings.Network.Select.Component.scss']
})
export class SettingsNetworkSelectComponent extends RiftBaseComponent implements ILoadDate {
    public static className: string = 'SettingsNetworkSelectComponent';

    @HostBinding()
    public id: string = 'rift-settings-network-select';

    public dnsSetupFormGroup: FormGroup;
    public devices: Array<IDevice>;
    public iPSetup: IPSetupModel;
    public networkSetupFormGroup: FormGroup;
    public serverPortFormGroup: FormGroup;
    public routeParamsProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.routeParamsProcess = this.processMonitorService.getProcess(SettingsNetworkSelectComponent.className, 'Router params change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsNetworkSelectComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsNetworkSelectComponent.className, this.saveAllChangesProcessText);

        this.initConnectionState();
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.deviceService.getDevices(process).pipe(
                flatMap(deviceColl => {
                    if (!this.isNullOrUndefined(deviceColl)) {
                        return this.getHostDevice().pipe(
                            map(host => {
                                if (!this.isNullOrUndefined(host)) {
                                    let hostUrl: string;

                                    this.devices = [];
                                    deviceColl.items.forEach(d => {
                                        if (d.master === true || d.isGen(UnitGenerationEnum.kestrel) === true || d.isGen(UnitGenerationEnum.falcon) === true) {
                                            const url = UrlUtility.getRelativeUrl([d.serialNumber], this._router, this._activatedRoute);

                                            if (host.serialNumber === d.serialNumber) {
                                                hostUrl = url;
                                            }

                                            this.devices.push({
                                                model: d,
                                                url,
                                            } as IDevice);
                                        }
                                    });

                                    this.addSubscription(this.observableHandlerBase(this._activatedRoute.params, this.routeParamsProcess).subscribe(params => {
                                        if (this.isNullOrUndefined(params.serial)) {
                                            this._router.navigateByUrl(hostUrl);
                                        }
                                    }), this.routeParamsProcess);
                                }
                                return true;
                            })
                        );
                    }
                    return of(true);
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public onActivated(): void {
    }

    public onDeactivated(): void {
        if (this._router.url.indexOf('settings/network') !== -1) {
            if (!this.isNullOrUndefined(this.devices) && this.devices.length > 0) {
                const master = this.devices.find(i => i.model.master === true);
                if (!this.isNullOrUndefined(master)) {
                    this._router.navigateByUrl(master.url);
                }
            }
        }
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
    }
}
