import { IRestModel } from '@shared/interface/IRestModel';
import { BaseModel } from '@shared/base/Base.Model';
import { isBoolean, isNullOrUndefined } from '@shared/utility/General.Utility';


export class ResultModel extends BaseModel implements IRestModel {
    public errorMessage: string = null;
    public readonly isIRestModel = true;
    public success: boolean = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        if (isBoolean(restModel)) {
            this.success = restModel;
        }

        if (!isNullOrUndefined(restModel.result)) {
            restModel = restModel.result;
        }

        if (!isNullOrUndefined(restModel.Success)) {
            this.success = restModel.Success;
        } else if (!isNullOrUndefined(restModel.Item1)) {
            this.success = restModel.Item1;
        }

        if (!isNullOrUndefined(restModel.ErrorMessage)) {
            this.errorMessage = restModel.ErrorMessage;
        } else if (!isNullOrUndefined(restModel.Message)) {
            this.errorMessage = restModel.Message;
        } else if (!isNullOrUndefined(restModel.Item2)) {
            this.errorMessage = restModel.Item2;
        }
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
