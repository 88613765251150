export enum AdvSearchSettingPropertyEnum {
    serialNumber = 1,
    deviceName = 2,
    deviceID = 3,
    siteName = 4,
    siteID = 5,
    userString = 6,
    metaData = 7,
    iPAddress = 8,
    mACAddress = 9
}

export class AdvSearchSettingPropertyEnumHelpers {
    public static toDisplayString(value: AdvSearchSettingPropertyEnum): string {
        switch (value) {
            case AdvSearchSettingPropertyEnum.serialNumber:
                return 'Serial Number';
            case AdvSearchSettingPropertyEnum.deviceName:
                return 'Device Name';
            case AdvSearchSettingPropertyEnum.deviceID:
                return 'Device ID';
            case AdvSearchSettingPropertyEnum.siteName:
                return 'Site Name';
            case AdvSearchSettingPropertyEnum.siteID:
                return 'Site ID';
            case AdvSearchSettingPropertyEnum.userString:
                return 'User String';
            case AdvSearchSettingPropertyEnum.metaData:
                return 'Meta Data';
            case AdvSearchSettingPropertyEnum.iPAddress:
                return 'IP Address';
            case AdvSearchSettingPropertyEnum.mACAddress:
                return 'MAC Address';
        }
    }
}
