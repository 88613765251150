import { Component, HostBinding, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { DeviceInfo } from '@shared/component/deviceinfotooltip/DeviceInfoTooltip.Component';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionService } from '@rift/service/connection/Connection.Service';

@Component({
    selector: 'em-device-serial-link',
    templateUrl: './DeviceSerialLink.Component.html',
    styleUrls: ['./DeviceSerialLink.Component.scss']
})
export class DeviceSerialLinkComponent extends BaseComponent implements OnInit {
    public static className: string = 'DeviceSerialLinkComponent';

    @Input()
    public get device(): DeviceModel {
        return this._device;
    }
    public set device(value: DeviceModel) {
        this._device = value;
    }

    @Input()
    public paths: Array<string>;

    @Input()
    public get serial(): string {
        return this._serial;
    }
    public set serial(value: string) {
        this._serial = value;
    }

    @Input()
    public deviceInfo: DeviceInfo;

    @Input()
    public viewMode: 'icon' | 'button' | 'link' = 'button';

    @HostBinding()
    public id: string = 'em-device-serial-link';

    private _device: DeviceModel;
    private _serial: string;

    public constructor(
        private readonly _connectionService: ConnectionService,
        private readonly _deviceService: DeviceService,
        private readonly _router: Router,
        private readonly _injector: Injector) {
        super(_injector);
    }

    public static getDeviceNavigationUrl(router: Router, friendlySerial: string, paths?: Array<string>): string {
        if (!isNullOrUndefined(paths)) {
            return router.createUrlTree(['rift', 'em', friendlySerial, ...paths]).toString();
        } else {
            return router.createUrlTree(['rift', 'em', friendlySerial, 'summary']).toString();
        }
    }

    public static navigateToDevice(router: Router, friendlySerial: string, connectionService: ConnectionService, paths?: Array<string>): void {
        if(connectionService.connectedToFriendlySerial !== friendlySerial){
            if (connectionService.isConnected === true) {
                connectionService.disconnectAsync().subscribe(() => {
                    this.navToDevice(router, friendlySerial, paths);
                });
            } else {
                this.navToDevice(router, friendlySerial, paths);
            }
        }
    }

    public getDeviceInfo(): Observable<DeviceInfo> {
        return this._deviceService.getDeviceCached(this.friendlySerial, false);
    }

    public get friendlySerial(): string {
        return this.isNullOrUndefined(this.device) ? this.serial : this.device.friendlySerial;
    }

    public get deviceInfoSub(): Observable<DeviceInfo> {
        let serial = null;

        if (!this.isNullOrUndefined(this.serial)) {
            serial = this.serial;
        } else if (!this.isNullOrUndefined(this.device)) {
            serial = this.device.friendlySerial;
        }

        if (!this.isNullOrUndefined(serial)) {
            return this._deviceService.getDeviceCached(serial, false).pipe(
                map(
                    (device) => device.deviceInfo
                )
            );
        } else {
            return of(null);
        }
    }

    public onClick(): void {
        DeviceSerialLinkComponent.navigateToDevice(this._router, this.friendlySerial, this._connectionService, this.paths);
    }

    private static navToDevice(router: Router, friendlySerial: string, paths?: Array<string>): void {
        if (!isNullOrUndefined(paths)) {
            router.navigate(['rift', 'em', friendlySerial, ...paths]);
        } else {
            router.navigate(['rift', 'em', friendlySerial, 'summary']);
        }
    }
}
