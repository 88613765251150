import { Component, HostBinding, HostListener, Inject, Injector, NgZone, OnDestroy } from '@angular/core';
import { WideTrackerService } from '@rift/service/data/widetracker/WideTracker.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

export class NetworkChangeDetectedDialogData {
    public constructor() {
    }
}
export class NetworkChangeDetectedDialogResult {
    public constructor(public readonly ok: boolean) {
    }
}

@Component({
    selector: 'rift-validate-wide-tracker-config-network-change-detected-dialog',
    templateUrl: './NetworkChangeDetected.Dialog.Component.html',
    styleUrls: ['./NetworkChangeDetected.Dialog.Component.scss'],
})
export class NetworkChangeDetectedDialogComponent extends BaseComponent implements ISaveAllChanges, OnDestroy {
    public static className: string = 'NetworkChangeDetectedDialogComponent';

    @HostBinding()
    public id: string = 'rift-validate-wide-tracker-config-network-change-detected-dialog';

    public isNullOrUndefined = isNullOrUndefined;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: NetworkChangeDetectedDialogData,
        private readonly _dialogRef: MatDialogRef<NetworkChangeDetectedDialogComponent>,
        private readonly _wideTrackerService: WideTrackerService,
        private readonly _zone: NgZone,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this._dialogRef.disableClose = true;

        this.saveAllChangesProcess = this.processMonitorService.getProcess(NetworkChangeDetectedDialogComponent.className, this.saveAllChangesProcessText);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public get hasChanges(): boolean {
        return true;
    }

    public get isValid(): boolean {
        return true;
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            this._wideTrackerService.updateConfig(process).pipe(
                map(() => true)
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            tap(() => {
                this._dialogRef.close(new NetworkChangeDetectedDialogResult(true));
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this);
    }

    public onCancelClick(): void {
        this._zone.run(()=>{
            this._dialogRef.close(new NetworkChangeDetectedDialogResult(false));
        });
    }

    public onOkClick(): void {
        this.saveAllChangesStartBase(this, this.openPleaseWaitSavingDialog());
    }
}
