import { IObjectStore } from '@shared/service/Indexeddb/IObjectStore';

export interface IDatabaseRecordingStore {
    readonly storeName: string;
    readonly storeDisplayName: string;
    readonly keyPath: string;
    readonly friendlySerialSessionIndex: string;
}

export class DatabaseRecordingStore implements IObjectStore {
    public static readonly friendlySerialSessionIndex: string = 'FriendlySerialSessionIndex';
    public static readonly keyPath: string = 'id';
    public static readonly storeDisplayName: string = 'Recordings';
    public static readonly storeName: string = 'recording';

    public get storeName(): string {
        return DatabaseRecordingStore.storeName;
    };

    public static toInterface(): IDatabaseRecordingStore {
        return {
            storeName: this.storeName,
            keyPath: this.keyPath,
            friendlySerialSessionIndex: this.friendlySerialSessionIndex,
        } as IDatabaseRecordingStore;
    }

    public createStores(db: IDBDatabase): void {
        const store: IDBObjectStore = db.createObjectStore(DatabaseRecordingStore.storeName, { keyPath: DatabaseRecordingStore.keyPath, autoIncrement: true });
        store.createIndex(DatabaseRecordingStore.friendlySerialSessionIndex, ['friendlySerial', 'sessionId', 'startTime'], { unique: true });
    }
}
