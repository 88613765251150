import { REGISTER_BASE_EXCLUDE_PROPERTIES, RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterOccupancyWaitModel extends IRegisterBaseModel {

}

/**
 * Represents a
 *
 * @export
 * @class RegisterOccupancyWaitModel
 * @extends {RegisterBaseModel}
 * @implements {IRegisterOccupancyWaitModel}
 * @implements {IRestModel}
 */
export class RegisterOccupancyWaitModel extends RegisterBaseModel implements IRegisterOccupancyWaitModel, IRestModel {

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterOccupancyWaitModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], [], []);
    }

}
