import { Component, HostBinding, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { QuickLinkMetricsModel } from '@em/models/restapi/QuickLinkMetrics.Model';
import { GroupService } from '@em/service/data/group/Group.Service';
import { RestApiDeviceService } from '@em/service/restapi/RestApi.Device.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Platform } from '@angular/cdk/platform';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'em-navmenu',
    templateUrl: './NavMenu.Component.html',
    styleUrls: ['./NavMenu.Component.scss']
})
export class NavMenuComponent extends BaseComponent implements OnInit, OnDestroy {
    public static className: string = 'NavMenuComponent';

    public addressBookChangeProcess: ProcessMonitorServiceProcess;
    public addressBookGroupsProcess: ProcessMonitorServiceProcess;
    public quickLinkMetrics: QuickLinkMetricsModel;
    public dataPollProcess: ProcessMonitorServiceProcess;
    public quickLinksProcess: ProcessMonitorServiceProcess;
    public rootTreeGroup: GroupModel;
    public isSAFARI: boolean = false;

    @HostBinding()
    public id: string = 'em-navmenu';

    private _dataPollingEvent: DataPollingEvent;
    private _addressBookPollingEvent: DataPollingEvent;

    public constructor(
        private readonly _platform: Platform,
        private readonly _eventsService: EventsService,
        private readonly _groupService: GroupService,
        private readonly _router: Router,
        private readonly _dataPollingService: DataPollingService,
        private readonly _restApiDeviceService: RestApiDeviceService,
        private readonly _injector: Injector) {
        super(_injector);

        this.isSAFARI = this._platform.SAFARI;

        this.dataPollProcess = this.processMonitorService.getProcess(NavMenuComponent.className, 'Polling data.');
        this.quickLinksProcess = this.processMonitorService.getProcess(NavMenuComponent.className, 'Loading quick link data.');
        this.addressBookGroupsProcess = this.processMonitorService.getProcess(NavMenuComponent.className, 'Getting address book groups.');
        this.addressBookChangeProcess = this.processMonitorService.getProcess(NavMenuComponent.className, 'Address book changed.');

        this._dataPollingEvent = new DataPollingEvent('NavMenuComponent:Refresh', 0, 20000, this.quickLinksProcess);
        this._addressBookPollingEvent = new DataPollingEvent('NavMenuComponent:AddressBook', 0, 20000, this.addressBookGroupsProcess);
    }

    public addressBookItemClicked(group: GroupModel): void {
        this._router.navigate(['/devicegroup/', group.id]);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.stopDataPolling();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.startDataPolling();
        this.getAddressBookData();
        this.getQuickLinks();

        this.addSubscription(this.observableHandlerBase(this._eventsService.onAddressBookChanged, this.addressBookChangeProcess).subscribe(() => {
            this.getAddressBookData();
        }), this.addressBookChangeProcess);
    }

    private getAddressBookData(): void {
        this.addSubscription(this.observableHandlerBase(this._groupService.getNested(this.addressBookGroupsProcess), this.addressBookGroupsProcess).subscribe(
            result => {
                this.rootTreeGroup = result;
            }
        ), this.addressBookGroupsProcess);
    }

    private getQuickLinks(): void {
        this.addSubscription(this._restApiDeviceService.getQuickLinks(null, {disabled: true}).pipe(
            map(result => {
                this.quickLinkMetrics = result;
            }),
            catchError((err: any) => {
                console.log(err);
                return of(true);
            })
        ).subscribe(), this.quickLinksProcess);
    }

    private subDataPolling(): void {
        this.addSubscription(this._dataPollingEvent.poll.subscribe(() => {
            this.getQuickLinks();
        }), this.dataPollProcess);

        this.addSubscription(this._addressBookPollingEvent.poll.subscribe(() => {
            this._groupService.clearCache();

            this.addSubscription(this._groupService.getNested(this.addressBookGroupsProcess, {disabled: true}).pipe(
                map(result => {
                    this.rootTreeGroup = result;
                }),
                catchError((err: any)=>{
                    console.log(err);
                    return of(true);
                })
            ).subscribe(), this.addressBookGroupsProcess);

        }), this.addressBookGroupsProcess);
    }

    private startDataPolling(): void {
        this.subDataPolling();
        this._dataPollingService.startEvent(this._dataPollingEvent);
        this._dataPollingService.startEvent(this._addressBookPollingEvent);
    }

    private stopDataPolling(): void {
        this._dataPollingService.stopEvent(this._dataPollingEvent);
        this._dataPollingService.stopEvent(this._addressBookPollingEvent);
    }
}
