import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceValidateErrorDialogComponent, DeviceValidateErrorDialogData } from '@rift/components/devicevalidation/validatecountingnetwork/Error.Dialog.Component';
import { UniqueMasterSelectDialogComponent, UniqueMasterSelectDialogData } from '@rift/components/devicevalidation/validatecountingnetwork/UniqueMasterSelect.Dialog.Component';
import { PositionDialogComponent, PositionDialogData } from '@rift/components/devicevalidation/validateheights/Position.Dialog.Component';
import { NetworkChangeDetectedDialogComponent, NetworkChangeDetectedDialogData as NetworkChangeDetectedDialogData, NetworkChangeDetectedDialogResult } from '@rift/components/devicevalidation/validatewidetrackerconfig/NetworkChangeDetected.Dialog.Component';
import { BuildVersionErrorDialogComponent, BuildVersionErrorDialogData } from '@rift/components/devicevalidation/validationbuildversion/BuildVersionError.Dialog.Component';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { RestApiGlobalService } from '@rift/service/restapi/v1/RestApi.Global.Service';
import { RestApiWideTrackerService } from '@rift/service/restapi/v1/RestApi.WideTracker.Service';
import { HeightRanges } from '@rift/shared/Settings.Device.Height';
import { DeviceLensTypeEnum } from '@shared/enum/DeviceLensType.Enum';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { UnitTypeEnum } from '@shared/enum/UnitType.Enum';
import { ValidationResultEnum } from '@shared/enum/ValidationResult.Enum';
import { Dictionary } from '@shared/generic/Dictionary';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';

export class DeviceValidationResult {
    public deviceDataUpdate: boolean = false;
    public state: ValidationResultEnum;

    public constructor(public readonly valid: boolean) { }
}

@Injectable()
export class DeviceValidationService extends RiftBaseService {
    private readonly _validationResultEnumTitles: Dictionary<ValidationResultEnum, string> = new Dictionary<ValidationResultEnum, string>();

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _deviceService: DeviceService,
        private readonly _restApiWideTrackerService: RestApiWideTrackerService,
        private readonly _restApiGlobalService: RestApiGlobalService) {
        super();

        this._validationResultEnumTitles.addOrUpdate(ValidationResultEnum.failedErrorState, 'Validation failed, device in fatal error');
        this._validationResultEnumTitles.addOrUpdate(ValidationResultEnum.failedBuildVersion, 'Validation failed, incorrect build versions');
        this._validationResultEnumTitles.addOrUpdate(ValidationResultEnum.failedProtocolVersion, 'Validation failed, incorrect protocol version');
        this._validationResultEnumTitles.addOrUpdate(ValidationResultEnum.failedLensType, 'Validation failed, incorrect lens type');
        this._validationResultEnumTitles.addOrUpdate(ValidationResultEnum.failedMixedDeviceTypeNetwork, 'Validation failed, mixed device types on network');
        this._validationResultEnumTitles.addOrUpdate(ValidationResultEnum.failedMissingDetectFlag, 'Validation failed, missing detect flags');
        this._validationResultEnumTitles.addOrUpdate(ValidationResultEnum.failedInvalidDetectFlag, 'Validation failed, invalid detect flags');
        this._validationResultEnumTitles.addOrUpdate(ValidationResultEnum.failedMultiUnitMk2TooManyMasters, 'Validation failed, too many master devices detected');
    }

    public validateDevices(hostFriendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<DeviceValidationResult> {
        return this.validationBuildVersion(process).pipe(
            flatMap(buildResult => {
                if (buildResult.valid === true) {
                    return this.validateCountingNetwork(hostFriendlySerial, process);
                } else {
                    return of(buildResult);
                }
            })
        );
    }

    public validateCountingNetwork(hostFriendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<DeviceValidationResult> {
        return this._restApiGlobalService.validateNetwork(process).pipe(
            flatMap(result => {

                if (isNullOrUndefined(result)){
                    const failResult = new DeviceValidationResult(false);
                    failResult.state = ValidationResultEnum.noDevices;
                    return of(failResult);
                }

                switch (result.result) {
                    case ValidationResultEnum.noDevices:
                    case ValidationResultEnum.ok:
                    case ValidationResultEnum.failedMultiUnitMk2NoMaster:
                        const okResult = new DeviceValidationResult(true);
                        okResult.state = result.result;
                        return of(okResult);
                    case ValidationResultEnum.failedRelayCheckAutoUnits:
                    case ValidationResultEnum.failedRelayCheckTooManyMasters:
                    case ValidationResultEnum.failedRelayCheckNoMaster:
                    case ValidationResultEnum.failedMultiUnitMk2TooManyMasters:
                        return this._deviceService.getDevices(process).pipe(
                            flatMap(deviceColl => this._dialog.open(UniqueMasterSelectDialogComponent, { data: new UniqueMasterSelectDialogData(deviceColl.items), disableClose: true }).afterClosed().pipe(
                                    flatMap(() => this.validateDevices(hostFriendlySerial, process).pipe(tap(r => r.state = result.result)))
                                ))
                        );
                    default:
                        const title = this._validationResultEnumTitles.get(result.result);
                        if (!isNullOrUndefined(title)) {
                            return this._dialog.open(DeviceValidateErrorDialogComponent, { data: new DeviceValidateErrorDialogData(title, hostFriendlySerial), disableClose: true }).afterClosed().pipe(
                                map(() => {
                                    const failResult = new DeviceValidationResult(false);
                                    failResult.state = result.result;
                                    return failResult;
                                })
                            );
                        } else {
                            return this._dialog.open(DeviceValidateErrorDialogComponent, { data: new DeviceValidateErrorDialogData('Validation failed, unknown response', hostFriendlySerial), disableClose: true }).afterClosed().pipe(
                                map(() => {
                                    const failResult = new DeviceValidationResult(false);
                                    failResult.state = result.result;
                                    return failResult;
                                })
                            );
                        }
                }
            }),
        );
    }

    public validateWideTrackerConfig(hostFriendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<DeviceValidationResult> {
        return this._restApiWideTrackerService.validateConfig(process).pipe(
            flatMap(result => {
                if (result === true) {
                    return of(new DeviceValidationResult(true));
                } else {
                    return this._dialog.open(NetworkChangeDetectedDialogComponent, { data: new NetworkChangeDetectedDialogData(), disableClose: true }).afterClosed().pipe(
                        flatMap((dialogResult: NetworkChangeDetectedDialogResult) => {
                            if (!isNullOrUndefined(dialogResult) && dialogResult.ok) {
                                return this.validateDevices(hostFriendlySerial, process);
                            } else {
                                return of(new DeviceValidationResult(true));
                            }
                        })
                    );
                }
            })
        );
    }

    public validationBuildVersion(process?: ProcessMonitorServiceProcess): Observable<DeviceValidationResult> {
        return this._deviceService.getDevices(process).pipe(
            flatMap(deviceColl => {
                const hasUnsupported = deviceColl.items.some(device => {
                    if (device.unitGen === UnitGenerationEnum.gen4 && device.unitType !== UnitTypeEnum.valCam) {
                        const parts = device.mainImageVersion.split('.');
                        const last = parts[parts.length - 1];
                        if (parseInt(last, 10) < 486) {
                            return true;
                        }
                        return false;
                    }
                });

                if (hasUnsupported) {
                    return this._dialog.open(BuildVersionErrorDialogComponent, { data: new BuildVersionErrorDialogData(), disableClose: true }).afterClosed().pipe(
                        map(() => new DeviceValidationResult(false))
                    );
                } else {
                    return of(new DeviceValidationResult(true));
                }
            })
        );
    }

    public validateDeviceHeights(hostFriendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<DeviceValidationResult> {
        return this._deviceService.getDevices(process).pipe(
            flatMap(deviceColl => {
                const invalidHeight = deviceColl.items.some(device => {
                    if (device.getAutoHeightCompleted === true && !(device.unitGen === UnitGenerationEnum.gen4 && device.unitType === UnitTypeEnum.valCam)) {
                        return !this.validateHeight(device.actualHeight, device.lensType).valid;
                    }
                    return false;
                });

                if (invalidHeight) {
                    return this._dialog.open(PositionDialogComponent, { data: new PositionDialogData(deviceColl.items), disableClose: true }).afterClosed().pipe(
                        flatMap(() => this.validateDevices(hostFriendlySerial, process))
                    );
                } else {
                    return of(new DeviceValidationResult(true));
                }
            })
        );
    }

    public validateHeight(height: number, lens: DeviceLensTypeEnum, process?: ProcessMonitorServiceProcess): DeviceValidationResult {
        const range = HeightRanges.find(i => i.lens === lens);

        if (!isNullOrUndefined(range)) {
            if (height < range.overallMin || height > range.overallMax) {
                return new DeviceValidationResult(false);
            }

            return new DeviceValidationResult(true);
        } else {
            return new DeviceValidationResult(false);
        }
    }
}
