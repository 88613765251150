import { Injectable } from '@angular/core';
import { MetaDataKeyCollectionModel } from '@em/models/restapi/MetaDataKeyCollectionModel';
import { MetaDataMapModel } from '@em/models/restapi/MetaDataMap.Model';
import { MetaDataMapCollectionModel } from '@em/models/restapi/MetaDataMapCollection.Model';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiDeviceService } from '@em/service/restapi/RestApi.Device.Service';
import { RestApiSettingsService } from '@em/service/restapi/RestApi.Settings.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';


@Injectable()
export class MetaDataKeysService extends EmBaseService {
    private _getDeviceValuesCache: MetaDataMapCollectionModel;
    private _getDeviceValuesLoadingTracker = new ObservableTracker<MetaDataMapCollectionModel>();
    private _getKeysCache: MetaDataKeyCollectionModel;
    private _getKeysLoadingTracker = new ObservableTracker<MetaDataKeyCollectionModel>();
    private _saveAllChangesLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiSettingsService: RestApiSettingsService,
        private readonly _restApiDeviceService: RestApiDeviceService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getKeysCache = null;
        this._getDeviceValuesCache = null;
    }

    public getDeviceValues(friendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<MetaDataMapCollectionModel> {
        if (isNullOrUndefined(this._getDeviceValuesCache)) {
            return this._getDeviceValuesLoadingTracker
                .getLoading(friendlySerial)
                .observable(
                    this._restApiDeviceService.getDevice(friendlySerial, false, process).pipe(
                        flatMap(device => {
                            if (!isNullOrUndefined(device)) {
                                return this.getKeys(process).pipe(
                                    map(metaDataKeys => {
                                        const coll = new MetaDataMapCollectionModel();

                                        const metaDataKeysLength = metaDataKeys.items.length;
                                        for (let metaDataKeyIndex = 0; metaDataKeyIndex < metaDataKeysLength; metaDataKeyIndex++) {
                                            const metaDataKey = metaDataKeys.items[metaDataKeyIndex];

                                            const deviceMetaData = device.metaData.find(deviceMetaDataMap => deviceMetaDataMap.metaDataKey.metaDataKeyId === metaDataKey.metaDataKeyId);
                                            const metaDataMap = new MetaDataMapModel();
                                            metaDataMap.metaDataKey = metaDataKey;
                                            metaDataMap.metaDataMapId = metaDataKey.metaDataKeyId;

                                            if (!isNullOrUndefined(deviceMetaData)) {
                                                metaDataMap.value = deviceMetaData.value;
                                            }

                                            metaDataMap.commitChanges();

                                            coll.items.push(metaDataMap);
                                        }

                                        coll.items.originalValuesSet();

                                        this._getDeviceValuesCache = coll;

                                        return this._getDeviceValuesCache;
                                    })
                                );
                            } else {
                                return of(null);
                            }
                        })
                    )
                );
        } else {
            return of(this._getDeviceValuesCache);
        }
    }

    public getKeys(process?: ProcessMonitorServiceProcess): Observable<MetaDataKeyCollectionModel> {
        if (isNullOrUndefined(this._getKeysCache)) {
            return this._getKeysLoadingTracker
                .getLoading()
                .observable(
                    this._restApiDeviceService.getMetaDataKeys(process).pipe(
                        map(metaDataKeys => {
                            this._getKeysCache = metaDataKeys;
                            return this._getKeysCache;
                        })
                    ));
        } else {
            return of(this._getKeysCache);
        }
    }

    public saveAllChanges(metaDataKeys: MetaDataKeyCollectionModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._saveAllChangesLoadingTracker
            .getLoading(metaDataKeys)
            .observable(this._restApiSettingsService.saveAllMetaDataChanges(metaDataKeys, process).pipe(tap(() => {
                this._getKeysCache = null;
                this._getDeviceValuesCache = null;
            })));
    }

    public updateDeviceValues(metaDataMaps: MetaDataMapCollectionModel, deviceID: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._saveAllChangesLoadingTracker
            .getLoading(metaDataMaps, deviceID)
            .observable(this._restApiDeviceService.updateMetaDataKeys(metaDataMaps, deviceID, process).pipe(tap(() => this._getDeviceValuesCache = null)));
    }
}
