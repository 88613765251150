import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { LoginModel } from '@shared/models/restapi/Login.Model';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { LoginAttemptResultModel } from '@shared/models/restapi/LoginAttemptResult.Model';

@Injectable()
export class RestApiAccountService extends RiftRestApiService {
    private _controller = 'account';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.appBase, _httpClient);
    }

    public login(userName: string, password: string, process?: ProcessMonitorServiceProcess): Observable<LoginAttemptResultModel> {
        const url = `${this._config.appBase}${this._controller}/login`;

        const login = new LoginModel();
        login.userName = userName;
        login.password = password;

        const req = new HttpRequest('POST', url, login.toRestApiModel(), {
            responseType: 'json',
            reportProgress: true,
            withCredentials: true,
        });

        return this.request<LoginAttemptResultModel, null>(req, LoginAttemptResultModel, null, process, false, false, { disabled: true }) as Observable<LoginAttemptResultModel>;
    }

    public logoff(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.get<boolean>(`${this._controller}/logoff`, 'boolean', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
