import { Injectable } from '@angular/core';
import { IBookmarkModel } from '@rift/models/generic/Bookmark.Model';
import { INewValidationSessionDetailsModel } from '@rift/models/restapi/NewValidationSessionDetails.Model';
import { ConnectionService } from '@rift/service/connection/Connection.Service';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { DatabaseSessionBookmarkStore } from '@rift/service/validation/database/syncsession/Database.SessionBookmarks.Store';
import { DatabaseSessionInfoStore } from '@rift/service/validation/database/syncsession/Database.SessionInfo.Store';
import { DatabaseSettings } from '@rift/service/validation/database/syncsession/Database.Settings';
import { DatabaseUserCountStore } from '@rift/service/validation/database/syncsession/Database.UserCount.Store';
import { IDbValidationSessionInfoModel } from '@rift/service/validation/models/database/syncsession/IDbValidationSessionInfo.Model';
import { SyncActionEnum } from '@rift/service/validation/models/SyncState.Enum';
import { IValidationRecordingModel } from '@rift/service/validation/models/ValidationRecording.Model';
import { IStopRequest } from '@rift/service/validation/models/webworker/generic/IStop.Request';
import { IProgressUpdateResponse } from '@rift/service/validation/models/webworker/syncrecording/IProgressUpdate.Response';
import { IAddBookmarkRequest } from '@rift/service/validation/models/webworker/syncsession/IAddBookmark.Request';
import { IAddBookmarkResponse } from '@rift/service/validation/models/webworker/syncsession/IAddBookmark.Response';
import { IAddSessionRequest } from '@rift/service/validation/models/webworker/syncsession/IAddSessions.Request';
import { IAddSessionResponse } from '@rift/service/validation/models/webworker/syncsession/IAddSessions.Response';
import { IBookmark } from '@rift/service/validation/models/webworker/syncsession/IBookmark';
import { IDeleteBookmarkRequest } from '@rift/service/validation/models/webworker/syncsession/IDeleteBookmark.Request';
import { IDeleteBookmarkResponse } from '@rift/service/validation/models/webworker/syncsession/IDeleteBookmarkResponse';
import { IDeleteSessionRequest } from '@rift/service/validation/models/webworker/syncsession/IDeleteSession.Request';
import { IDeleteSessionResponse } from '@rift/service/validation/models/webworker/syncsession/IDeleteSession.Response';
import { IGetBookmarksRequest } from '@rift/service/validation/models/webworker/syncsession/IGetBookmarks.Request';
import { IGetBookmarksResponse } from '@rift/service/validation/models/webworker/syncsession/IGetBookmarks.Response';
import { IGetSessionRequest } from '@rift/service/validation/models/webworker/syncsession/IGetSession.Request';
import { IGetSessionResponse } from '@rift/service/validation/models/webworker/syncsession/IGetSession.Response';
import { IGetSessionsRequest } from '@rift/service/validation/models/webworker/syncsession/IGetSessions.Request';
import { IGetSessionsResponse } from '@rift/service/validation/models/webworker/syncsession/IGetSessions.Response';
import { IGetSessionSyncStateRequest } from '@rift/service/validation/models/webworker/syncsession/IGetSessionSyncState.Request';
import { IGetSessionSyncStateResponse } from '@rift/service/validation/models/webworker/syncsession/IGetSessionSyncState.Response';
import { IGetUserCountsRequest } from '@rift/service/validation/models/webworker/syncsession/IGetUserCounts.Request';
import { IGetUserCountsResponse } from '@rift/service/validation/models/webworker/syncsession/IGetUserCounts.Response';
import { IInitializeRequest } from '@rift/service/validation/models/webworker/syncsession/Initialize.Request.Model';
import { ISessionSyncState } from '@rift/service/validation/models/webworker/syncsession/ISessionSyncState';
import { IUpdateStateSessionRequest } from '@rift/service/validation/models/webworker/syncsession/IUpdateStateSession.Request';
import { IUpdateStateSessionResponse } from '@rift/service/validation/models/webworker/syncsession/IUpdateStateSession.Response';
import { IUserCount } from '@rift/service/validation/models/webworker/syncsession/IUserCount';
import { BaseService } from '@shared/base/Base.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IResponse } from '@shared/webworker/IResponse';
import { ResponseTypesEnum } from '@shared/webworker/ResponseTypes.Enum';
import { WebWorkerClient } from '@shared/webworker/WebWorker';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ValidationSyncSessionWorkerService extends BaseService {

    public onError: Subject<Error> = new Subject<Error>();
    public onProgressUpdate: Subject<IProgressUpdateResponse> = new Subject<IProgressUpdateResponse>();
    private _client: WebWorkerClient;

    public constructor(
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _configurationService: ConfigurationService,
        private readonly _connectionService: ConnectionService) {
        super();
    }

    public addBookmark(frameNumber: number, bookmark: IBookmarkModel, session: IDbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IAddBookmarkRequest, IAddBookmarkResponse>({
                action: 'addBookmark',
                frameNumber,
                bookmark,
                session,
                validationRecording,
            }, process).pipe(
                map((response) => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return true;
                    }

                    return false;
                })
            );
        }
    }

    public addSession(session: INewValidationSessionDetailsModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<IDbValidationSessionInfoModel> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IAddSessionRequest, IAddSessionResponse>({
                action: 'addSession',
                session,
                validationRecording,
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response.session;
                    }

                    return null;
                })
            );
        }
    }

    public deleteBookmark(frameNumber: number, bookmark: IBookmarkModel, session: IDbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IDeleteBookmarkRequest, IDeleteBookmarkResponse>({
                action: 'deleteBookmark',
                frameNumber,
                bookmark,
                session,
                validationRecording,
            }, process).pipe(
                map((response) => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return true;
                    }

                    return null;
                })
            );
        }
    }

    public deleteSession(session: IDbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IDeleteSessionRequest, IDeleteSessionResponse>({
                action: 'deleteSession',
                session,
                validationRecording,
            }, process).pipe(
                map((response) => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return true;
                    }

                    return null;
                })
            );
        }
    }

    public getBookmarks(sessionInfoId: number, process?: ProcessMonitorServiceProcess): Observable<Array<IBookmark>> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IGetBookmarksRequest, IGetBookmarksResponse>({
                action: 'getBookmarks',
                sessionInfoId,
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response.bookmarks;
                    }

                    return null;
                })
            );
        }
    }

    public getSession(creationDate: string, validationRecording: IValidationRecordingModel, syncAction?: SyncActionEnum, process?: ProcessMonitorServiceProcess): Observable<IDbValidationSessionInfoModel> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IGetSessionRequest, IGetSessionResponse>({
                action: 'getSession',
                validationRecording,
                creationDate,
                syncAction,
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response.session;
                    }

                    return null;
                })
            );
        }
    }

    public getSessionSyncState(creationDate: string, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<ISessionSyncState> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IGetSessionSyncStateRequest, IGetSessionSyncStateResponse>({
                action: 'getSessionSyncState',
                validationRecording,
                creationDate,
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response.syncState;
                    }

                    return null;
                })
            );
        }
    }

    public getSessions(validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<IDbValidationSessionInfoModel>> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IGetSessionsRequest, IGetSessionsResponse>({
                action: 'getSessions',
                validationRecording,
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response.sessions;
                    }

                    return null;
                })
            );
        }
    }

    public getUserCounts(sessionInfoId: number, process?: ProcessMonitorServiceProcess): Observable<Array<IUserCount>> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IGetUserCountsRequest, IGetUserCountsResponse>({
                action: 'getUserCounts',
                sessionInfoId
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response.userCounts;
                    }

                    return null;
                })
            );
        }
    }

    public start(process?: ProcessMonitorServiceProcess): void {
        this._client = new WebWorkerClient(new Worker(new URL('@rift/workers/validation-sync-session.worker', import.meta.url), { type: 'module' }));
        this.addSubscription(this._client.progressUpdate.subscribe(update => this.onProgressUpdate.next(update)));
        this.addSubscription(this._client.error.subscribe(error => this.onError.next(error.error)));

        this.addSubscription(this._client.sendMessage<IInitializeRequest, IResponse>({
            action: 'initialize',

            hostFriendlySerial: this._connectionService.hostFriendlySerial,

            socketUrl: this._configurationService.riftWebSocket,
            connectionToken: this._connectionTokenService.connectionToken,
            secureToken: this._connectionTokenService.secureToken,
            riftV1RestApiBase: this._configurationService.riftV1RestApiBase,

            databaseName: DatabaseSettings.databaseName,
            databaseVersion: DatabaseSettings.databaseVersion,

            databaseSessionInfoConfig: DatabaseSessionInfoStore.toInterface(),
            databaseUserCountConfig: DatabaseUserCountStore.toInterface(),
            databaseSessionBookmarkConfig: DatabaseSessionBookmarkStore.toInterface(),
        }, process).subscribe(
            response => {
                if (response.type === ResponseTypesEnum.error) {
                    this.onError.next(new Error('Unable to start web worker'));
                }
            },
            error => {
                this.onError.next(new Error(`Unable to start web worker:${error}`));
            }
        ));
    }

    public stop(): void {
        if (!isNullOrUndefined(this._client)) {
            this.addSubscription(this._client.sendMessage<IStopRequest, IResponse>({ action: 'stop' }).subscribe(
                () => this._client.terminate()
            ));
        }
    }

    public updateSessionState(session: IDbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<IDbValidationSessionInfoModel> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IUpdateStateSessionRequest, IUpdateStateSessionResponse>({
                action: 'updateStateSession',
                session,
                validationRecording
            }, process).pipe(
                map(response => {
                    if (response.type === ResponseTypesEnum.complete) {
                        return response.session;
                    }
                })
            );
        }
    }
}
