/**
 * Logging service log
 *
 * @export
 * @class LoggingServiceLog
 */
export class LoggingServiceLog {

    /**
     * The process start time.
     *
     * @type {number}
     * @memberof LoggingServiceLog
     */
    public processStartedTime: number;
    /**
     * The date and time the log was created.
     *
     * @type {Date}
     * @memberof LoggingServiceLog
     */
    public time: Date;
    /**
     * The level of the log.
     *
     * @type {string}
     * @memberof LoggingServiceLog
     */
    public level: string;
    /**
     * The location the log was created.
     * e.g the class name
     *
     * @type {string}
     * @memberof LoggingServiceLog
     */
    public location: string;
    /**
     * The log message.
     *
     * @type {string}
     * @memberof LoggingServiceLog
     */
    public message: string;
    /**
     * The process completed time.
     *
     * @type {number}
     * @memberof LoggingServiceLog
     */
    public processCompletedTime: number;
    /**
     * The process error message(s).
     *
     * @type {(string | Array<string>)}
     * @memberof LoggingServiceLog
     */
    public processErrorMessages: string | Array<string>;
    /**
     * The process percentage completed.
     *
     * @type {number}
     * @memberof LoggingServiceLog
     */
    public processPercentage: number;
    /**
     * The process status.
     *
     * @type {string}
     * @memberof LoggingServiceLog
     */
    public processStatus: string;
    /**
     * The system error.
     *
     * @type {Error}
     * @memberof LoggingServiceLog
     */
    public error: Error;
    /**
     * True if the process has errors else false.
     *
     * @type {boolean}
     * @memberof LoggingServiceLog
     */
    public processHasError: boolean;

    /**
     * Creates an instance of LoggingServiceLog.
     *
     * @param {string} location The location the log originated.
     * @param {string} message The log message.
     * @memberof LoggingServiceLog
     */
    public constructor(
        level: string,
        location: string,
        message: string) {

        this.time = new Date();
        this.level = level;
        this.location = location;
        this.message = message;
    }
}
