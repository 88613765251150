import { AnyUtility } from '@shared/utility/Any.Utility';

export class ObjectUtility {
    /**
     * Checks to see if objectA and objectB are equal.
     *
     * @static
     * @template TItem The type of the object.
     * @param {TItem} objectA The A object to compare.
     * @param {TItem} objectB The B object to compare.
     * @returns {boolean} True if objectA and objectB are equal. else false.
     * @memberof ObjectUtility
     */
    public static equal<TItem extends object>(objectA: TItem, objectB: TItem): boolean {
        return AnyUtility.equal(objectA, objectB);
    }
}
