<div class="rift-load-validation-recording-title" mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Loading Validation Recording
            </div>
            <div class="sub-header">
                {{ isNullOrUndefined(updatingText) ? '' : updatingText }}
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div class="rift-load-validation-recording-content" mat-dialog-content fxLayout="column">
    <div *ngIf="isCancelled === false" class="progress" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-progress-bar mode="determinate" [value]="percentageComplete"></mat-progress-bar>
        </div>
        <div class="spacing-top" fxLayout="row" fxLayoutAlign="start center">
            Loading
        </div>
    </div>
    <div *ngIf="isCancelled === true" class="progress" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="spacing-top" fxLayout="row" fxLayoutAlign="start center">
            Cancelling
        </div>
    </div>
</div>
<div class="rift-load-validation-recording-actions" mat-dialog-actions>
    <button *ngIf="percentageComplete < 100 && isCancelled === false && !isNullOrUndefined(recordingId)" mat-button
        (click)="onCancelClick()">Cancel</button>
</div>