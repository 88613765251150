import { RelayChannelModel, IRelayChannelModel } from '@rift/models/restapi/RelayChannel.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IRelayModel extends IRestModel {
    globalHeartbeatPeriod: number;
    cANIOEnabled: boolean;
    relayChannels: Array<IRelayChannelModel>;
}

export class RelayModel extends BaseModel implements IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public globalHeartbeatPeriod: number = null;
    public cANIOEnabled: boolean = null;
    public relayChannels: RestModelChangeTrackerArray<RelayChannelModel> = new RestModelChangeTrackerArray<RelayChannelModel>();

    public constructor() {
        super();

        this.registerProperty('cANIOEnabled');
        this.registerProperty('globalHeartbeatPeriod');
        this.registerChangeTrackerArray('relayChannels');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RelayModel');

        this.relayChannels = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.RelayChannels, RelayChannelModel);

        this.setPropertyOriginalValue('cANIOEnabled', this.cANIOEnabled);
        this.setPropertyOriginalValue('relayChannels', this.relayChannels);
        this.setPropertyOriginalValue('globalHeartbeatPeriod', this.globalHeartbeatPeriod);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            RelayChannels: RestModelUtility.toJsonArray(this.relayChannels),
        };
    }
}
