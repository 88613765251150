import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IAutoSpeedSettings } from '@rift/components/validation/Validation.Defaults';
import { BookmarkModel } from '@rift/models/generic/Bookmark.Model';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { GlobalModel } from '@rift/models/restapi/Global.Model';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { NewValidationSessionDetailsModel } from '@rift/models/restapi/NewValidationSessionDetails.Model';
import { PolygonModel } from '@rift/models/restapi/Polygon.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { SyncedVideoSessionModel } from '@rift/models/restapi/SyncedVideoSession.Model';
import { VideoFrameImageModel } from '@rift/models/restapi/VideoFrameImage.Model';
import { CountModel } from '@rift/models/websocket/Count.Model';
import { TargetModel } from '@rift/models/websocket/Target.Model';
import { TargetCollectionModel } from '@rift/models/websocket/TargetCollection.Model';
import { VideoModel } from '@rift/models/websocket/Video.Model';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { RecordingService } from '@rift/service/data/recording/Recording.Service';
import { DatabaseCountFramesStore } from '@rift/service/validation/database/syncrecording/Database.CountFrames.Store';
import { DatabaseDeviceFramesStore } from '@rift/service/validation/database/syncrecording/Database.DeviceFrames.Store';
import {
    DatabaseGlobalBookmarkFramesStore,
} from '@rift/service/validation/database/syncrecording/Database.GlobalBookmark.Store';
import {
    DatabaseGlobalDataFramesStore,
} from '@rift/service/validation/database/syncrecording/Database.GlobalDataFrames.Store';
import { DatabaseLineFramesStore } from '@rift/service/validation/database/syncrecording/Database.LineFrames.Store';
import { DatabasePolygonFramesStore } from '@rift/service/validation/database/syncrecording/Database.PolygonFrames.Store';
import { DatabaseRecordingStore } from '@rift/service/validation/database/syncrecording/Database.Recording.Store';
import { DatabaseRegisterFramesStore } from '@rift/service/validation/database/syncrecording/Database.RegisterFrames.Store';
import {
    DatabaseSettings as SyncRecordingDatabaseSettings,
} from '@rift/service/validation/database/syncrecording/Database.Settings';
import { DatabaseTargetFramesStore } from '@rift/service/validation/database/syncrecording/Database.TargetFrames.Store';
import { DatabaseTimeDataFramesStore } from '@rift/service/validation/database/syncrecording/Database.TimeDataFrames.Store';
import { DatabaseVideoFramesStore } from '@rift/service/validation/database/syncrecording/Database.VideoFrames.Store';
import {
    DatabaseVideoSettingFramesStore,
} from '@rift/service/validation/database/syncrecording/Database.VideoSettings.Store';
import { DatabaseSessionBookmarkStore } from '@rift/service/validation/database/syncsession/Database.SessionBookmarks.Store';
import { DatabaseSessionInfoStore } from '@rift/service/validation/database/syncsession/Database.SessionInfo.Store';
import {
    DatabaseSettings as SyncSessionDatabaseSettings,
} from '@rift/service/validation/database/syncsession/Database.Settings';
import { DatabaseUserCountStore } from '@rift/service/validation/database/syncsession/Database.UserCount.Store';
import { IFrameModel } from '@rift/service/validation/models/database/syncrecording/Frame.Model';
import { IRecordingModel } from '@rift/service/validation/models/database/syncrecording/Recording.Model';
import {
    DbValidationSessionInfoModel,
} from '@rift/service/validation/models/database/syncsession/IDbValidationSessionInfo.Model';
import { SyncActionEnum } from '@rift/service/validation/models/SyncState.Enum';
import { IValidatableRecordingModel } from '@rift/service/validation/models/ValidatableRecording.Model';
import { fromSyncedVideoSessionModel, toVideoSessionModel, fromVideoSessionModel, toSyncedVideoSessionModel } from '@rift/service/validation/models/ValidatableRecording.Model.Helpers';
import { IValidationRecordingModel } from '@rift/service/validation/models/ValidationRecording.Model';
import { IProgressUpdateResponse } from '@rift/service/validation/models/webworker/syncrecording/IProgressUpdate.Response';
import { ISessionSyncState } from '@rift/service/validation/models/webworker/syncsession/ISessionSyncState';
import { IUserCount } from '@rift/service/validation/models/webworker/syncsession/IUserCount';
import {
    ValidationSyncRecordingWebWorkerService,
} from '@rift/service/validation/Validation.SyncRecording.WebWorker.Service';
import { ValidationSyncSessionWorkerService } from '@rift/service/validation/Validation.SyncSession.WebWorker.Service';
import {
    ValidationUserCountsWebWorkerService,
} from '@rift/service/validation/Validation.UserCounts.WebWorker.Service';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';
import { ValidationWorkerShared } from '@rift/workers/validation.worker.shared';
import { BaseService } from '@shared/base/Base.Service';
import {
    ErrorDialogComponent,
    ErrorDialogData,
} from '@shared/component/dialog/error/Error.Dialog.Component';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { IndexedDBService } from '@shared/service/Indexeddb/IndexedDB.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isArray, isNullOrUndefined } from '@shared/utility/General.Utility';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';
import { merge, Observable, of, Subject, zip } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';

import { IGetBlockLoadStatusResponse } from './models/webworker/syncrecording/IGetBlockLoadStatus.Response';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { VideoSessionModel } from '@rift/models/restapi/VideoSession.Model';
import { DatabaseSyncFramesStore } from './database/syncrecording/Database.SyncFrames.Store';
import { ValidationSyncFrameEntryModel } from '@rift/models/websocket/ValidationSyncFrameEntry.Model';
import { SyncedVideoSessionNodeModel } from '@rift/models/restapi/SyncedVideoSessionNode.Model';
import { ValidationSyncFrameEntryCollectionModel } from '@rift/models/websocket/ValidationSyncFrameEntryCollection.Model';
import { ISyncFrameBuffer } from './ISyncFrameBuffer';
import { VideoViewModel } from '../data/recording/Video.ViewModel1';

export interface IFramesData {
    video: Array<VideoModel>;
    targets: Array<TargetCollectionModel>;
    syncFrames: Array<ISyncFrameBuffer>;
    autoSpeedProfile: Array<number>;
}

export interface IKeyFramesData {
    video?: Array<VideoModel>;
    targets?: Array<TargetCollectionModel>;
    syncFrames?: Array<ISyncFrameBuffer>;
}

const DELETE_DB_ON_ERROR = true;

@Injectable()
export class ValidationService extends BaseService {
    public onProgressUpdate: Subject<IProgressUpdateResponse> = new Subject<IProgressUpdateResponse>();
    public onError: Subject<Error> = new Subject<Error>();

    public constructor(
        private readonly _deviceService: DeviceService,
        private readonly _zone: NgZone,
        private readonly _dialog: MatDialog,
        private readonly _syncRecording: ValidationSyncRecordingWebWorkerService,
        private readonly _syncSession: ValidationSyncSessionWorkerService,
        private readonly _syncSessionUserCounts: ValidationUserCountsWebWorkerService,
        private readonly _recordingService: RecordingService,
        private readonly _indexedDBService: IndexedDBService) {
        super();

        merge(
            this._syncRecording.onProgressUpdate,
            this._syncSession.onProgressUpdate,
        ).subscribe(p => this.onProgressUpdate.next(p));

        merge(
            this._syncRecording.onError,
            this._syncSession.onError,
        ).subscribe(e => {
            this.onError.next(e);
            if (DELETE_DB_ON_ERROR === true) {
                this.removeDatabase();
            }
        });
    }

    public getRecordingDownloadStatus(recordingId: number, process?: ProcessMonitorServiceProcess): Observable<IGetBlockLoadStatusResponse> {
        return this._zone.runOutsideAngular(() => this._syncRecording.getBlockLoadStatus(recordingId, process).pipe(
                map((results) => results)
            ));
    }

    public cancel(recordingId: number, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._zone.runOutsideAngular(() => this._syncRecording.cancel(recordingId, process)).pipe(
            catchError(this.errorHandler('Cancelling validation recording syncing'))
        );
    }

    public getRecordingKeyFrames(recordingId: number, targetsRequired: boolean, syncFramesRequired: boolean, process?: ProcessMonitorServiceProcess): Observable<IKeyFramesData> {
        return this._zone.runOutsideAngular(() => this._syncRecording.getKeyFrames(recordingId, targetsRequired, syncFramesRequired, process).pipe(
                map(response => {
                    const framesData: IKeyFramesData = {};

                    if (!isNullOrUndefined(response.video)) {
                        framesData.video = response.video.map(frame => {
                            const model = new VideoModel();
                            model.loadFromWebSocketMessage(frame);
                            return model;
                        });
                    }

                    if (!isNullOrUndefined(response.targets)) {
                        framesData.targets = response.targets.map(frame => {
                            const model = new TargetCollectionModel();
                            model.items = WebSocketModelUtility.loadFromArray(frame.data, TargetModel, false);
                            return model;
                        });
                    }

                    if (!isNullOrUndefined(response.syncFrames)) {
                        framesData.syncFrames = response.syncFrames.map(frame => {
                            const model = {syncFrames: null};
                            model.syncFrames = WebSocketModelUtility.loadFromArray(frame.data, ValidationSyncFrameEntryModel, false);
                            return model;
                        });
                    }

                    return framesData;
                })
            )).pipe(
            catchError(this.errorHandler('Getting validation recording ket frames'))
        );
    }

    public addRecordingBookmark(frameNumber: number, bookmark: BookmarkModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<BookmarkModel> {
        return this._zone.runOutsideAngular(() => this._syncRecording.addBookmark(frameNumber, bookmark.toInterface(), validationRecording, process).pipe(
                map(response => {
                    const model = new BookmarkModel();
                    model.id = response.id;
                    model.frameNumber = response.frameNumber;
                    model.comment = response.comment;
                    return model;
                })
            )).pipe(
            catchError(this.errorHandler('Adding validation recording bookmark'))
        );
    }

    public addSession(session: NewValidationSessionDetailsModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<DbValidationSessionInfoModel> {
        return this._zone.runOutsideAngular(() => this._syncSession.addSession(session.toInterface(), validationRecording, process).pipe(
                map(result => {
                    const model = new DbValidationSessionInfoModel();
                    model.loadFromInterface(result);
                    return model;
                })
            )).pipe(
            catchError(this.errorHandler('Adding validation session'))
        );
    }

    public addSessionBookmark(frameNumber: number, bookmark: BookmarkModel, session: DbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._zone.runOutsideAngular(() => this._syncSession.addBookmark(frameNumber, bookmark.toInterface(), session.toInterface(), validationRecording, process)).pipe(
            catchError(this.errorHandler('Adding validation session bookmark'))
        );
    }

    public openUserCountsSession(validationSessionId: number, recordingId: number, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._syncSessionUserCounts.openSession(validationSessionId, recordingId, process).pipe(
            catchError(this.errorHandler('Getting validation session user counts'))
        );
    }

    public closeUserCountsSession(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._syncSessionUserCounts.closeSession(process).pipe(
            catchError(this.errorHandler('Closing validation session user counts'))
        );
    }

    public userCountsIncrement(frameNumber: number, registerIndex: number, session: DbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<number[][]> {
        return this._syncSessionUserCounts.incrementCount(frameNumber, registerIndex, session.toInterface(), validationRecording, process).pipe(
            catchError(this.errorHandler('Incrementing validation session user count'))
        );
    }

    public userCountsDecrement(frameNumber: number, registerIndex: number, session: DbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<number[][]> {
        return this._syncSessionUserCounts.decrementCount(frameNumber, registerIndex, session.toInterface(), validationRecording, process).pipe(
            catchError(this.errorHandler('Decrementing validation session user count'))
        );
    }

    public userCountsDelete(frameNumber: number, registerIndex: number, session: DbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<number[][]> {
        return this._syncSessionUserCounts.deleteCount(frameNumber, registerIndex, session.toInterface(), validationRecording, process).pipe(
            catchError(this.errorHandler('Deleting validation session user count'))
        );
    }

    public clearCache(recordingId: number, process?: ProcessMonitorServiceProcess, onProgress?: (name: string, count: number, deleted: number) => void): Observable<boolean> {
        return this._zone.runOutsideAngular(() => {
            const openDatabasesSubs = zip(
                this._indexedDBService.open(SyncSessionDatabaseSettings.databaseName, SyncSessionDatabaseSettings.databaseVersion, SyncSessionDatabaseSettings.databaseStores),
                this._indexedDBService.open(SyncRecordingDatabaseSettings.databaseName, SyncRecordingDatabaseSettings.databaseVersion, SyncRecordingDatabaseSettings.databaseStores),
            );

            return openDatabasesSubs.pipe(
                flatMap((databases) => {
                    const sessionDB = databases[0];
                    const recordingDB = databases[1];

                    const deleteSubs = zip(
                        this._indexedDBService.deleteRecordsByIndex(sessionDB, DatabaseSessionBookmarkStore.storeName, DatabaseSessionBookmarkStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(sessionDB, DatabaseSessionInfoStore.storeName, DatabaseSessionInfoStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(sessionDB, DatabaseUserCountStore.storeName, DatabaseUserCountStore.recordingIdIndex, recordingId),

                        this._indexedDBService.deleteRecordsByIndex(recordingDB, DatabaseCountFramesStore.storeName, DatabaseCountFramesStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(recordingDB, DatabaseDeviceFramesStore.storeName, DatabaseDeviceFramesStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(recordingDB, DatabaseGlobalBookmarkFramesStore.storeName, DatabaseGlobalBookmarkFramesStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(recordingDB, DatabaseGlobalDataFramesStore.storeName, DatabaseGlobalDataFramesStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(recordingDB, DatabaseLineFramesStore.storeName, DatabaseLineFramesStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(recordingDB, DatabasePolygonFramesStore.storeName, DatabasePolygonFramesStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(recordingDB, DatabaseRegisterFramesStore.storeName, DatabaseRegisterFramesStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(recordingDB, DatabaseTargetFramesStore.storeName, DatabaseTargetFramesStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(recordingDB, DatabaseTimeDataFramesStore.storeName, DatabaseTimeDataFramesStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(recordingDB, DatabaseVideoFramesStore.storeName, DatabaseVideoFramesStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(recordingDB, DatabaseVideoSettingFramesStore.storeName, DatabaseVideoSettingFramesStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecordsByIndex(recordingDB, DatabaseSyncFramesStore.storeName, DatabaseSyncFramesStore.recordingIdIndex, recordingId),
                        this._indexedDBService.deleteRecord(recordingDB, DatabaseRecordingStore.storeName, recordingId),
                    );

                    return deleteSubs.pipe(map(() => true));
                }),
            );
        }).pipe(
            catchError(this.errorHandler('Clearing validation sync cache'))
        );
    }

    public deleteRecordingBookmark(frameNumber: number, bookmark: BookmarkModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._zone.runOutsideAngular(() => this._syncRecording.deleteBookmark(frameNumber, bookmark.toInterface(), validationRecording, process)).pipe(
            catchError(this.errorHandler('Deleting validation recording bookmark'))
        );
    }

    public deleteSession(session: DbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._zone.runOutsideAngular(() => this._syncSession.deleteSession(session.toInterface(), validationRecording, process)).pipe(
            catchError(this.errorHandler('Deleting validation session'))
        );
    }

    public deleteSessionBookmark(frameNumber: number, bookmark: BookmarkModel, session: DbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._zone.runOutsideAngular(() => this._syncSession.deleteBookmark(frameNumber, bookmark.toInterface(), session.toInterface(), validationRecording, process));
    }

    public getRecording(validatableRecording: IValidatableRecordingModel, preLoadOnly: boolean, process?: ProcessMonitorServiceProcess): Observable<IValidationRecordingModel> {
        return this._syncRecording.getValidationRecording(validatableRecording, preLoadOnly, process).pipe(
            catchError(this.errorHandler('Getting validation recording'))
        );
    }

    public getRecordingBookmarks(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<BookmarkModel>> {
        return this._zone.runOutsideAngular(() => this._syncRecording.getBookmarks(recording, process).pipe(
                map(frames => {
                    const models: BookmarkModel[] = [];
                    if (!isNullOrUndefined(frames)) {
                        frames.forEach(frame => {
                            frame.data.forEach(data => {
                                const model = new BookmarkModel();
                                model.loadFromRestApiModel(data);
                                model.id = frame.id;
                                model.frameNumber = frame.frameNumber;
                                models[frame.frameNumber] = model;
                            });
                        });
                    }
                    return models;
                })
            )).pipe(
            catchError(this.errorHandler('Getting validation recording bookmarks'))
        );
    }

    public getRecordingCounts(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<CountModel>> {
        return this._zone.runOutsideAngular(() => this._syncRecording.getCounts(recording, process).pipe(
                map(frames => {
                    const models: CountModel[] = [];
                    if (!isNullOrUndefined(frames)) {
                        frames.forEach(frame => {
                            const model = new CountModel();
                            model.counts = frame.data;
                            models[frame.frameNumber] = model;
                        });
                    }
                    return models;
                })
            )).pipe(
            catchError(this.errorHandler('Getting validation recording counts'))
        );
    }

    public getRecordingSyncFrames(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<ValidationSyncFrameEntryCollectionModel>> {
        return this._zone.runOutsideAngular(() => this._syncRecording.getSyncFrames(recording, process).pipe(
                map(frames => {
                    const models: ValidationSyncFrameEntryCollectionModel[] = [];
                    if (!isNullOrUndefined(frames)) {
                        frames.forEach(frame => {
                            const model = new ValidationSyncFrameEntryCollectionModel();
                            model.syncFrames = WebSocketModelUtility.loadFromArray(frame.data, ValidationSyncFrameEntryModel, true);
                            models[frame.frameNumber] = model;
                        });
                    }
                    return models;
                })
            )).pipe(
            catchError(this.errorHandler('Getting validation sync frames'))
        );
    }

    public getRecordingDevices(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<DeviceModel>> {
        return this._zone.runOutsideAngular(() => this._syncRecording.getVideoSettings(recording, process).pipe(
                flatMap(videoSettingsFrames => this._syncRecording.getDevices(recording, process).pipe(
                        map(frames => this.getRecordingFramesData(frames,
                                data => {
                                    const model = new DeviceModel();
                                    model.loadFromRestApiModel(data);

                                    let videoSettings: IFrameModel = null;

                                    if (model.unitGen === UnitGenerationEnum.gen4 && model.master) {
                                        videoSettings = videoSettingsFrames[0];
                                    }
                                    else {
                                        videoSettings = videoSettingsFrames.find(i => i.data.SerialNumber === model.serialNumber);
                                    }

                                    if (!isNullOrUndefined(videoSettings)) {
                                        model.videoCroppingWindow.xPos1 = videoSettings.data.VideoCropping.XPos1;
                                        model.videoCroppingWindow.xPos2 = videoSettings.data.VideoCropping.XPos2;
                                        model.videoCroppingWindow.yPos1 = videoSettings.data.VideoCropping.YPos1;
                                        model.videoCroppingWindow.yPos2 = videoSettings.data.VideoCropping.YPos2;
                                        model.videoOffsets = videoSettings.data.VideoOffsets;
                                    }

                                    return model;
                                }
                            ))
                    ))
            )).pipe(
            catchError(this.errorHandler('Getting validation recording devices'))
        );
    }

    public getRecordingFirstFrame(masterSerialNumber: string, validatableRecording: IValidatableRecordingModel, process?: ProcessMonitorServiceProcess): Observable<VideoFrameImageModel> {
        return this._zone.runOutsideAngular(() => this._recordingService.getSyncedVideoSessionFirstFrame(toSyncedVideoSessionModel(validatableRecording), masterSerialNumber, process)).pipe(
            catchError(this.errorHandler('Getting validation recording first frame'))
        );
    }

    public getRecordingFrames(autoSpeedSettings: IAutoSpeedSettings, startFrame: number, frameCount: number, recordingId: number, process?: ProcessMonitorServiceProcess): Observable<IFramesData> {
        return this._zone.runOutsideAngular(() => this._syncRecording.getFrames(autoSpeedSettings, startFrame, frameCount, recordingId, process).pipe(
                map(response => {
                    const framesData: IFramesData = { video: null, targets: null, syncFrames: null, autoSpeedProfile: response.autoSpeedProfile };

                    framesData.video = response.video.map(frame => {
                        const model = new VideoModel();
                        model.loadFromWebSocketMessage(frame);
                        return model;
                    });

                    framesData.targets = response.targets.map(frame => {
                        const model = new TargetCollectionModel();
                        model.items = WebSocketModelUtility.loadFromArray(frame.data, TargetModel, false);
                        return model;
                    });

                    framesData.syncFrames = response.sync.map(frame => {
                        const model = {syncFrames: null};
                        model.syncFrames = WebSocketModelUtility.loadFromArray(frame.data, ValidationSyncFrameEntryModel, false);
                        return model;
                    });

                    return framesData;
                })
            )).pipe(
            catchError(this.errorHandler('Getting validation recording frames'))
        );
    }

    public getRecordingGlobalData(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<GlobalModel>> {
        return this._zone.runOutsideAngular(() => this._syncRecording.getGlobalData(recording, process).pipe(
                map(frames => this.getRecordingFramesData(frames,
                        data => {
                            const model = new GlobalModel();
                            model.loadFromRestApiModel(data);
                            return model;
                        }
                    ))
            )).pipe(
            catchError(this.errorHandler('Getting validation recording global data'))
        );
    }

    public getRecordingLines(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<LineModel>> {
        return this._zone.runOutsideAngular(() => this._syncRecording.getLines(recording, process).pipe(
                map(frames => this.getRecordingFramesData(frames,
                        data => {
                            const model = new LineModel();
                            model.loadFromRestApiModel(data);
                            return model;
                        }
                    ))
            )).pipe(
            catchError(this.errorHandler('Getting validation recording lines data'))
        );
    }

    public getRecordingPolygons(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<PolygonModel>> {
        return this._zone.runOutsideAngular(() => this._syncRecording.getPolygons(recording, process).pipe(
                map(frames => this.getRecordingFramesData(frames,
                        data => {
                            const model = new PolygonModel();
                            model.loadFromRestApiModel(data);
                            return model;
                        }
                    ))
            )).pipe(
            catchError(this.errorHandler('Getting validation recording polygons data'))
        );
    }

    public getRecordingRegisters(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<RegisterBaseModel>> {
        return this._zone.runOutsideAngular(() => this._syncRecording.getRegisters(recording, process).pipe(
                map(frames => this.getRecordingFramesData(frames,
                        data => RegisterBaseUtility.getRegisterTypeFromRestApiModel(data)
                    ))
            )).pipe(
            catchError(this.errorHandler('Getting validation recording registers data'))
        );
    }

    public getRecordingTimeData(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<TimeSetupModel>> {
        return this._zone.runOutsideAngular(() => this._syncRecording.getTimeData(recording, process).pipe(
                map(frames => this.getRecordingFramesData(frames,
                        data => {
                            const model = new TimeSetupModel();
                            model.loadFromRestApiModel(data);
                            return model;
                        }
                    ))
            )).pipe(
            catchError(this.errorHandler('Getting validation recording time setup data'))
        );
    }

    public getDeviceRecordings(masterSerialNumber?: string, process?: ProcessMonitorServiceProcess): Observable<Array<IValidatableRecordingModel>> {
        return this._zone.runOutsideAngular(() => this._recordingService.getSyncedVideoSessions(masterSerialNumber, process).pipe(
                flatMap(syncedSessionInfo => {
                    if (syncedSessionInfo) {
                        return this._indexedDBService.open(SyncRecordingDatabaseSettings.databaseName, SyncRecordingDatabaseSettings.databaseVersion, SyncRecordingDatabaseSettings.databaseStores).pipe(
                            flatMap(database => this._indexedDBService.getAllRecords<IRecordingModel>(database, DatabaseRecordingStore.storeName).pipe(
                                    map(recordings => {
                                        const recordingsLength = recordings.length;
                                        const sessionsLength = syncedSessionInfo.sessions.length;
                                        const validatableRecordings: IValidatableRecordingModel[] = [];
                                        for (let sessionI = 0; sessionI < sessionsLength; sessionI++) {
                                            const syncedSession = syncedSessionInfo.sessions[sessionI];
                                            if (!isNullOrUndefined(syncedSession) && ((syncedSession instanceof SyncedVideoSessionModel && syncedSession.isComplete === true) || syncedSession instanceof VideoSessionModel)) {
                                                const validatableRecording = syncedSession instanceof SyncedVideoSessionModel ? fromSyncedVideoSessionModel(syncedSession) : fromVideoSessionModel(syncedSession);

                                                for (let recordingI = 0; recordingI < recordingsLength; recordingI++) {
                                                    const recording = ValidationWorkerShared.convert_Database_RecordingModelValueOfToDates(recordings[recordingI]);

                                                    if (recording.sessionId === validatableRecording.id && (isNullOrUndefined(masterSerialNumber) || recording.friendlySerial === masterSerialNumber)) {
                                                        validatableRecording.recordingId = recording.id;
                                                        validatableRecording.friendlySerial = recording.friendlySerial;

                                                        validatableRecording.preloadFrames = recording.preloadFrames;
                                                        validatableRecording.preloadComplete = recording.preloadComplete;
                                                        validatableRecording.postLoadFrames = recording.postLoadFrames;
                                                        validatableRecording.postLoadCompleted = recording.postLoadCompleted;

                                                        break;
                                                    }
                                                }

                                                validatableRecordings.push(validatableRecording);
                                            }
                                        }

                                        return validatableRecordings;
                                    }),
                                )),
                        );
                    }
                }),
            )).pipe(
            catchError(this.errorHandler('Getting device recording'))
        );
    }

    public getRecordings(masterSerialNumber?: string, process?: ProcessMonitorServiceProcess): Observable<Array<IValidatableRecordingModel>> {
        return this._zone.runOutsideAngular(() => this._indexedDBService.open(SyncRecordingDatabaseSettings.databaseName, SyncRecordingDatabaseSettings.databaseVersion, SyncRecordingDatabaseSettings.databaseStores).pipe(
                flatMap(database => this._indexedDBService.getAllRecords<IRecordingModel>(database, DatabaseRecordingStore.storeName).pipe(
                        map(recordings => {
                            const recordingsLength = recordings.length;
                            const validatableRecordings: IValidatableRecordingModel[] = [];

                            for (let recordingI = 0; recordingI < recordingsLength; recordingI++) {
                                const recording = ValidationWorkerShared.convert_Database_RecordingModelValueOfToDates(recordings[recordingI]);
                                if (isNullOrUndefined(masterSerialNumber) || recording.friendlySerial === masterSerialNumber) {
                                    let isNode = false;

                                    recordings.forEach(r => {
                                        if (r.nodes?.some(s => s.friendlySerial === recording.friendlySerial) ?? false){
                                            isNode = true;
                                        }
                                    });

                                    if (!isNode){
                                        // if we have nodes see if we can find them
                                        // from the main list
                                        const nodes: Array<IValidatableRecordingModel> = [];

                                        if (recording.nodes?.length > 0){
                                            recording.nodes.forEach(n => {
                                                recordings.forEach(r => {
                                                    const testRec = ValidationWorkerShared.convert_Database_RecordingModelValueOfToDates(r);

                                                    if (testRec.friendlySerial === n.friendlySerial && testRec.startTime.valueOf() === recording.startTime.valueOf()){
                                                        // We've found our node
                                                        nodes.push({
                                                            id: testRec.sessionId,
                                                            startTime: testRec.startTime,
                                                            endTime: testRec.endTime,
                                                            frames: testRec.frames,
                                                            bytes: testRec.bytes,
                                                            timezoneOffsetMins: testRec.timezoneOffsetMins,
                                                            recordingId: testRec.id,
                                                            friendlySerial: testRec.friendlySerial,
                                                            preloadFrames: testRec.preloadFrames,
                                                            preloadComplete: testRec.preloadComplete,
                                                            postLoadFrames: testRec.postLoadFrames,
                                                            postLoadCompleted: testRec.postLoadCompleted,
                                                            onNodes: null,
                                                            isSynced: true
                                                        });
                                                    }
                                                });
                                            });
                                        }

                                        validatableRecordings.push({
                                            id: recording.sessionId,
                                            startTime: recording.startTime,
                                            endTime: recording.endTime,
                                            frames: recording.frames,
                                            bytes: recording.bytes,
                                            timezoneOffsetMins: recording.timezoneOffsetMins,
                                            recordingId: recording.id,
                                            friendlySerial: recording.friendlySerial,
                                            preloadFrames: recording.preloadFrames,
                                            preloadComplete: recording.preloadComplete,
                                            postLoadFrames: recording.postLoadFrames,
                                            postLoadCompleted: recording.postLoadCompleted,
                                            onNodes: nodes,
                                            isSynced: true
                                        });
                                    }
                                }
                            }

                            return validatableRecordings;
                        }),
                    )),
            )).pipe(
            catchError(this.errorHandler('Getting validation recordings'))
        );
    }

    public getSession(creationDate: string, validationRecording: IValidationRecordingModel, syncAction?: SyncActionEnum, process?: ProcessMonitorServiceProcess): Observable<DbValidationSessionInfoModel> {
        return this._zone.runOutsideAngular(() => this._syncSession.getSession(creationDate, validationRecording, syncAction, process).pipe(
                map(item => {
                    const model = new DbValidationSessionInfoModel();
                    model.loadFromInterface(item);
                    return model;
                })
            )).pipe(
            catchError(this.errorHandler('Getting validation session'))
        );
    }

    public getSessionBookmarks(sessionInfoId: number, process?: ProcessMonitorServiceProcess): Observable<Array<BookmarkModel>> {
        return this._zone.runOutsideAngular(() => this._syncSession.getBookmarks(sessionInfoId, process).pipe(
                map(
                    iBookmarks => {
                        const models: BookmarkModel[] = [];
                        const iBookmarksLength = iBookmarks.length;

                        for (let ibi = 0; ibi < iBookmarksLength; ibi++) {
                            const iBookmark = iBookmarks[ibi];
                            const commentsLength = iBookmark.comments.length;
                            for (let ibci = 0; ibci < commentsLength; ibci++) {
                                const model = new BookmarkModel();
                                model.comment = iBookmark.comments[ibci];
                                model.id = iBookmark.id;
                                model.frameNumber = iBookmark.frameNumber;
                                models[iBookmark.frameNumber] = model;
                            }
                        }

                        return models;
                    }
                ),
            )).pipe(
            catchError(this.errorHandler('Getting validation session bookmarks'))
        );
    }

    public getSessionSyncState(creationDate: string, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<ISessionSyncState> {
        return this._zone.runOutsideAngular(() => this._syncSession.getSessionSyncState(creationDate, validationRecording, process)).pipe(
            catchError(this.errorHandler('Getting validation session sync state'))
        );
    }

    public getSessionUserCounts(sessionInfoId: number, process?: ProcessMonitorServiceProcess): Observable<Array<CountModel>> {
        return this._zone.runOutsideAngular(() => this._syncSession.getUserCounts(sessionInfoId, process).pipe(
                map(iUserCounts => this.iUserCountsToCountModels(iUserCounts))
            )).pipe(
            catchError(this.errorHandler('Getting validation session user counts'))
        );
    }

    public getSessions(validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<DbValidationSessionInfoModel>> {
        return this._zone.runOutsideAngular(() => this._syncSession.getSessions(validationRecording, process).pipe(
                map(items => {
                    const models: DbValidationSessionInfoModel[] = [];
                    if (!isNullOrUndefined(items)) {
                        const length = items.length;
                        for (let i = 0; i < length; i++) {
                            const model = new DbValidationSessionInfoModel();
                            model.loadFromInterface(items[i]);
                            models.push(model);
                        }
                    }
                    return models;
                })
            )).pipe(
            catchError(this.errorHandler('Getting validation sessions'))
        );
    }

    public getValidatableRecording(masterSerialNumber: string, sessionId: number, startTime: Date, process?: ProcessMonitorServiceProcess): Observable<IValidatableRecordingModel> {
        return this._zone.runOutsideAngular(() => this._recordingService.getSyncedVideoSession(sessionId, startTime, masterSerialNumber, process).pipe(
                flatMap(syncedSession => {
                    if (!isNullOrUndefined(syncedSession) && syncedSession.isComplete === true) {
                        return this._indexedDBService.open(SyncRecordingDatabaseSettings.databaseName, SyncRecordingDatabaseSettings.databaseVersion, SyncRecordingDatabaseSettings.databaseStores).pipe(
                            flatMap(database => this._indexedDBService.getRecordByIndex<IRecordingModel>(database, DatabaseRecordingStore.storeName, DatabaseRecordingStore.friendlySerialSessionIndex, [masterSerialNumber, sessionId]).pipe(
                                    map(recording => {
                                        recording = ValidationWorkerShared.convert_Database_RecordingModelValueOfToDates(recording);
                                        const validatableRecording = syncedSession instanceof SyncedVideoSessionModel ? fromSyncedVideoSessionModel(syncedSession) : fromVideoSessionModel(syncedSession);

                                        if (!isNullOrUndefined(recording)) {
                                            validatableRecording.recordingId = recording.id;
                                            validatableRecording.friendlySerial = recording.friendlySerial;
                                            validatableRecording.startTime = recording.startTime;
                                        }
                                        else{
                                            validatableRecording.friendlySerial = masterSerialNumber;
                                        }

                                        return validatableRecording;
                                    }),
                                )),
                        );
                    } else {
                        return of(null);
                    }
                }),
            )).pipe(
            catchError(this.errorHandler('Getting validation recording'))
        );
    }

    public removeDatabase(): Observable<boolean> {
        return this._zone.runOutsideAngular(() => this._indexedDBService.delete(SyncRecordingDatabaseSettings.databaseName).pipe(
                flatMap(() => this._indexedDBService.delete(SyncSessionDatabaseSettings.databaseName))
            )).pipe(
            catchError(this.errorHandler('Removing sync database'))
        );
    }

    public startWorkers(process?: ProcessMonitorServiceProcess): void {
        return this._zone.runOutsideAngular(() => {
            this.addSubscription(this._indexedDBService.open(SyncRecordingDatabaseSettings.databaseName, SyncRecordingDatabaseSettings.databaseVersion, SyncRecordingDatabaseSettings.databaseStores).subscribe(
                database => {
                    this._syncRecording.start(process);
                }
            ));
            this.addSubscription(this._indexedDBService.open(SyncSessionDatabaseSettings.databaseName, SyncSessionDatabaseSettings.databaseVersion, SyncSessionDatabaseSettings.databaseStores).subscribe(
                database => {
                    this._syncSession.start(process);
                    this._syncSessionUserCounts.start(process);
                }
            ));
        });
    }

    public stopWorkers(): void {
        return this._zone.runOutsideAngular(() => {
            this._syncRecording.stop();
            this._syncSession.stop();
            this._syncSessionUserCounts.stop();
        });
    }

    public updateSessionState(session: DbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<DbValidationSessionInfoModel> {
        return this._zone.runOutsideAngular(() => this._syncSession.updateSessionState(session.toInterface(), validationRecording, process).pipe(
                map(result => {
                    const model = new DbValidationSessionInfoModel();
                    model.loadFromInterface(result);
                    return model;
                })
            )).pipe(
            catchError(this.errorHandler('Getting validation recording'))
        );
    }

    private isNodeInSyncedVideoSession(node: DeviceModel, masterSyncedSession: SyncedVideoSessionModel, process?: ProcessMonitorServiceProcess): Observable<{ serial: string; result: boolean; validatableRecording?: IValidatableRecordingModel }> {
        const failed = { serial: node.serialNumber, result: false };
        if (node.isCapable(DeviceCapabilitiesEnum.videoRecording)) {
            return this._recordingService.getSyncedVideoSessions(node.serialNumber).pipe(
                map(syncedSession => {
                    if (!isNullOrUndefined(syncedSession) && !isNullOrUndefined(syncedSession.sessions) && syncedSession.sessions.length > 0) {
                        const length = syncedSession.sessions.length;
                        for (let i = 0; i < length; i++) {
                            const session = syncedSession.sessions[i];
                            if (VideoViewModel.match(session, masterSyncedSession)) {
                                const validatableRecording = fromSyncedVideoSessionModel(session);
                                validatableRecording.friendlySerial = node.serialNumber;
                                return { serial: node.serialNumber, result: true, validatableRecording };
                            }
                        }
                    }
                    return failed;
                })
            );
        } else {
            return of(failed);
        }
    }

    private getRecordingFramesData<TItem>(frames: Array<IFrameModel>, modelBuilder: (data: any) => TItem): Array<TItem> {
        return this._zone.runOutsideAngular(() => {
            const models: TItem[] = [];

            if (!isNullOrUndefined(frames)) {
                frames.forEach(frame => {
                    if (isArray(frame.data)) {
                        frame.data.forEach(i => {
                            models.push(modelBuilder(i));
                        });
                    } else {
                        models.push(modelBuilder(frame.data));
                    }
                });
            }

            return models;
        });
    }

    private iUserCountsToCountModels(userCounts: IUserCount[]): Array<CountModel> {
        const models: CountModel[] = [];
        userCounts.forEach(iUserCount => {
            if (!isNullOrUndefined(iUserCount)) {
                const model = new CountModel();
                model.counts = iUserCount.counts;
                models[iUserCount.frameNumber] = model;
            }
        });
        return models;
    }

    private errorHandler(message: string): (error: Error, caught: Observable<any>) => Observable<any> {
        return (error: Error, caught: Observable<any>) => {
            this._dialog.open(ErrorDialogComponent, { data: new ErrorDialogData('Validation Error', error, message), disableClose: true });
            throw error;
        };
    }
}
