import { Component, EventEmitter, Inject, InjectionToken, Injector, Output, ViewChild, HostBinding } from '@angular/core';
import { AddressBookTreeComponent } from '@em/components/shared/addressbooktree/AddressBookTree.Component';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { BaseComponent } from '@shared/base/Base.Component';

export const TREE_CONTAINER_DATA = new InjectionToken<{}>('TREE_CONTAINER_DATA');

export class TreeContainerData {
    public root: GroupModel;
}

@Component({
    selector: 'em-address-book-auto-complete-tree-container',
    templateUrl: './AddressBookAutocomplete.TreeContainer.Component.html',
    styleUrls: ['./AddressBookAutocomplete.TreeContainer.Component.scss']
})
export class AddressBookAutocompleteTreeContainerComponent extends BaseComponent {
    public static className: string = 'AddressBookAutocompleteTreeContainerComponent';

    public root: GroupModel;

    @HostBinding()
    public id: string = 'em-address-book-auto-complete-tree-container';

    @ViewChild('addressbooktree', { static: true })
    public tree: AddressBookTreeComponent;

    @Output()
    public itemClicked: EventEmitter<GroupModel> = new EventEmitter();

    public constructor(
        @Inject(TREE_CONTAINER_DATA) private readonly _data: TreeContainerData,
        private readonly _injector: Injector) {
        super(_injector);

        this.root = this._data.root;
    }

    public onItemClicked(group: GroupModel): void {
        this.itemClicked.emit(group);
    }
}
