import { TaskModel } from '@rift/models/restapi/Task.Model';
import { ChangeTrackedCollectionModel, IChangeTrackedCollectionModel } from '@shared/models/restapi/ChangeTrackedCollection.Model';

export interface ITaskCollectionModel extends IChangeTrackedCollectionModel<TaskModel> {

}

export class TaskCollectionModel extends ChangeTrackedCollectionModel<TaskModel> implements ITaskCollectionModel {
    public constructor() {
        super(TaskModel);
    }
}
