import { Routes } from '@angular/router';
import { SettingsAddressBookComponent } from '@em/components/settings/addressbook/Settings.AddressBook.Component';
import { SettingsConnectionForwardingComponent } from '@em/components/settings/connectionforwarding/Settings.ConnectionForwarding.Component';
import { SettingsFirmwareVersionSummaryComponent } from '@em/components/settings/firmwareversionsummary/Settings.FirmwareVersionSummary.Component';
import { SettingsHomeComponent } from '@em/components/settings/home/Settings.Home.Component';
import { SettingsLicenceComponent } from '@em/components/settings/licence/Settings.Licence.Component';
import { SettingsManageUsersComponent } from '@em/components/settings/manageusers/Settings.ManageUsers.Component';
import { SettingsMetaDataComponent } from '@em/components/settings/metadata/Settings.MetaData.Component';
import { SettingsNotificationsComponent } from '@em/components/settings/notifications/Settings.Notifications.Component';
import { SettingsOptionsDataCullingComponent } from '@em/components/settings/options/dataculling/Settings.Options.DataCulling.Component';
import { SettingsOptionsNotificationsComponent } from '@em/components/settings/options/notifications/Settings.Options.Notifications.Component';
import { SettingsOptionsComponent } from '@em/components/settings/options/Settings.Options.Component';
import { SettingsOptionsTaskLauncherComponent } from '@em/components/settings/options/tasklauncher/Settings.Options.TaskLauncher.Component';
import { SettingsOptionsVideoComponent } from '@em/components/settings/options/video/Settings.Options.Video.Component';
import { SettingsOutboundConnectionsComponent } from '@em/components/settings/outboundconnections/Settings.OutboundConnections.Component';
import { SettingsSchedulesListComponent } from '@em/components/settings/schedules/schedulelist/Settings.Schedules.List.Component';
import { SettingsSchedulesViewComponent } from '@em/components/settings/schedules/scheduleview/Settings.Schedules.View.Component';
import { SettingsSchedulesComponent } from '@em/components/settings/schedules/Settings.Schedules.Component';
import { SettingsServiceStatusAndConfigurationComponent } from '@em/components/settings/servicestatusandconfiguration/Settings.ServiceStatusAndConfiguration.Component';
import { SettingsComponent } from '@em/components/settings/Settings.Component';
import { SettingsTaskLauncherComponent } from '@em/components/settings/tasklauncher/Settings.TaskLauncher.Component';
import { SettingsTaskLauncherListComponent } from '@em/components/settings/tasklauncher/tasklauncherlist/Settings.TaskLauncher.List.Component';
import { UserIsAdmin, UserIsSystemAdmin, UserIsAuthenticated, UserIsSystemManager } from '@em/service/user/UserIsLevel';
import { IRouteData } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { PendingChangesGuard } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { SettingsOptionsTempStoreComponent } from './options/tempstorage/Settings.Options.TempStore.Component';

export const EmSettingsRoutes: Routes = [
    {
        path: 'settings',
        canActivate: [UserIsAuthenticated],
        component: SettingsComponent,
        data: { breadcrumb: { name: 'em-settings', text: 'Settings' } } as IRouteData,
        children: [
            {
                path: '',
                canActivate: [UserIsAuthenticated],
                component: SettingsHomeComponent
            },
            {
                path: 'addressbook',
                component: SettingsAddressBookComponent,
                canActivate: [UserIsAuthenticated, UserIsAdmin],
                canDeactivate: [PendingChangesGuard],
                data: { breadcrumb: { name: 'em-settings-addressbook', text: 'Manage Address Book' } } as IRouteData,
            },
            {
                path: 'connectionforwarding',
                canActivate: [UserIsAuthenticated, UserIsSystemAdmin],
                canDeactivate: [PendingChangesGuard],
                component: SettingsConnectionForwardingComponent,
                data: { breadcrumb: { name: 'em-settings-connectionforwarding', text: 'Connection Forwarding' } } as IRouteData,
            },
            {
                path: 'firmwarevirsionsummary',
                canActivate: [UserIsAuthenticated],
                component: SettingsFirmwareVersionSummaryComponent,
                data: { breadcrumb: { name: 'em-settings-firmwarevirsionsummary', text: 'Firmware Summary' } } as IRouteData,
            },
            {
                path: 'licence',
                canActivate: [UserIsAuthenticated, UserIsSystemAdmin],
                component: SettingsLicenceComponent,
                data: { breadcrumb: { name: 'em-settings-licence', text: 'Licence' } } as IRouteData,
            },
            {
                path: 'manageusers',
                canActivate: [UserIsAuthenticated, UserIsAdmin],
                component: SettingsManageUsersComponent,
                data: { breadcrumb: { name: 'em-settings-manageusers', text: 'Manage Users' } } as IRouteData,
            },
            {
                path: 'metadata',
                canActivate: [UserIsAuthenticated, UserIsSystemManager],
                component: SettingsMetaDataComponent,
                canDeactivate: [PendingChangesGuard],
                data: { breadcrumb: { name: 'em-settings-metadata', text: 'Meta Data' } } as IRouteData,
            },
            {
                path: 'notifications',
                canActivate: [UserIsAuthenticated, UserIsSystemAdmin],
                canDeactivate: [PendingChangesGuard],
                component: SettingsNotificationsComponent,
                data: { breadcrumb: { name: 'em-settings-notifications', text: 'Notifications' } } as IRouteData,
            },
            {
                path: 'options',
                canActivate: [UserIsAuthenticated, UserIsSystemAdmin],
                component: SettingsOptionsComponent,
                data: { breadcrumb: { name: 'em-settings-options', text: 'Options', isCustomEvent: true } } as IRouteData,
                children: [
                    {
                        path: 'dataculling',
                        canActivate: [UserIsAuthenticated],
                        canDeactivate: [PendingChangesGuard],
                        component: SettingsOptionsDataCullingComponent,
                        data: { breadcrumb: { name: 'em-settings-options-dataculling', text: 'Data Culling' } } as IRouteData,
                    },
                    {
                        path: 'notifications',
                        canActivate: [UserIsAuthenticated],
                        canDeactivate: [PendingChangesGuard],
                        component: SettingsOptionsNotificationsComponent,
                        data: { breadcrumb: { name: 'em-settings-options-notifications', text: 'Notifications' } } as IRouteData,
                    },
                    {
                        path: 'tasklauncher',
                        canActivate: [UserIsAuthenticated],
                        canDeactivate: [PendingChangesGuard],
                        component: SettingsOptionsTaskLauncherComponent,
                        data: { breadcrumb: { name: 'em-settings-options-tasklauncher', text: 'Task Launcher' } } as IRouteData,
                    },
                    {
                        path: 'video',
                        canActivate: [UserIsAuthenticated],
                        canDeactivate: [PendingChangesGuard],
                        component: SettingsOptionsVideoComponent,
                        data: { breadcrumb: { name: 'em-settings-options-video', text: 'Video' } } as IRouteData,
                    },
                    {
                        path: 'tempstore',
                        canActivate: [UserIsAuthenticated],
                        canDeactivate: [PendingChangesGuard],
                        component: SettingsOptionsTempStoreComponent,
                        data: { breadcrumb: { name: 'em-settings-options-temp-store', text: 'Shared Temporary Storage' } } as IRouteData,
                    },
                ]
            },
            {
                path: 'outboundconnections',
                canActivate: [UserIsAuthenticated, UserIsAdmin],
                canDeactivate: [PendingChangesGuard],
                component: SettingsOutboundConnectionsComponent,
                data: { breadcrumb: { name: 'em-settings-outboundconnections', text: 'Outbound connections' } } as IRouteData,
            },
            {
                path: 'schedules',
                canActivate: [UserIsAuthenticated],
                component: SettingsSchedulesComponent,
                data: { breadcrumb: { name: 'em-settings-schedules', text: 'Schedules', isCustomEvent: true } } as IRouteData,
                children: [
                    {
                        path: 'list/:state',
                        canActivate: [UserIsAuthenticated],
                        component: SettingsSchedulesListComponent,
                        data: {
                            breadcrumb: {
                                name: 'em-settings-schedules-list',
                                text: [
                                    { name: 'em-settings-schedules-active', state: 'active', text: 'Active' },
                                    { name: 'em-settings-schedules-finished', state: 'finished', text: 'Finished' },
                                    { name: 'em-settings-schedules-disabled', state: 'disabled', text: 'Disabled' },
                                    { name: 'em-settings-schedules-system', state: 'system', text: 'System' },
                                ]
                            }
                        } as IRouteData,
                    },
                    {
                        path: 'view/:scheduleId',
                        canActivate: [UserIsAuthenticated],
                        component: SettingsSchedulesViewComponent,
                        data: {
                            breadcrumb: {
                                name: 'em-settings-schedules-view',
                                text: [
                                    { lookupSetName: 'schedules' },
                                ]
                            }
                        } as IRouteData,
                    }
                ]
            },
            {
                path: 'servicestatusandconfiguration',
                canActivate: [UserIsAuthenticated],
                component: SettingsServiceStatusAndConfigurationComponent,
                data: { breadcrumb: { name: 'em-settings-servicestatusandconfiguration', text: 'Service Status and Configuration' } } as IRouteData,
            },
            {
                path: 'tasklaucher',
                canActivate: [UserIsAuthenticated, UserIsAdmin],
                component: SettingsTaskLauncherComponent,
                data: { breadcrumb: { name: 'em-settings-tasklaucher', text: 'Task Launcher', isCustomEvent: true } } as IRouteData,
                children: [
                    {
                        path: 'list/:state',
                        canActivate: [UserIsAuthenticated],
                        component: SettingsTaskLauncherListComponent,
                        data: {
                            breadcrumb: {
                                name: 'em-settings-tasklaucher-list',
                                text: [
                                    { name: 'em-settings-tasklaucher-list-active', state: 'active', text: 'Active' },
                                    { name: 'em-settings-tasklaucher-list-failed', state: 'failed', text: 'Failed' },
                                    { name: 'em-settings-tasklaucher-list-disconnected', state: 'disconnected', text: 'Disconnected' },
                                    { name: 'em-settings-tasklaucher-list-complete', state: 'complete', text: 'Complete' },
                                ]
                            }
                        } as IRouteData,
                    },
                ]
            }
        ]
    },
];
