<div class="counting-setting-content">
    <form [formGroup]="form" fxFlex>
        <div fxLayout="row">
            <div fxFlex="50" class="spacing-right" fxLayout="column">
                <mat-form-field>
                    <mat-select formControlName="action" placeholder="Action">
                        <mat-option *ngFor="let action of actions" [value]="action">
                            {{action}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="50" class="spacing-right" fxLayout="column">
                <mat-form-field>
                    <mat-select formControlName="trigger" placeholder="Trigger">
                        <mat-option *ngFor="let trigger of triggers" [value]="trigger">
                            {{trigger}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="selectedActionInfo !== null && selectedActionInfo.hasParameters">
            <div>
                Description: {{selectedActionInfo.parametersDescription}}
            </div>
            <mat-form-field>
                <input matInput formControlName="parameters" pattern="{{selectedActionInfo.parametersPattern}}" placeholder="{{selectedActionInfo.parametersDefaults}}" [value]="parameters">
            </mat-form-field>
        </div>
    </form>
</div>