export enum UnitOfMeasurementEnum {
    inch = 1,
    feet = 2,
    yard = 3,
    millimeter = 4,
    centimeter = 5,
    meter = 6,
}

export class UnitOfMeasurementEnumHelpers {
    public static toShortName(value: UnitOfMeasurementEnum): string {
        switch (value) {
            case UnitOfMeasurementEnum.inch:
                return 'in';
            case UnitOfMeasurementEnum.feet:
                return 'ft';
            case UnitOfMeasurementEnum.yard:
                return 'yd';
            case UnitOfMeasurementEnum.millimeter:
                return 'mm';
            case UnitOfMeasurementEnum.centimeter:
                return 'cm';
            case UnitOfMeasurementEnum.meter:
                return 'm';
        }
    }

    public static toLongName(value: UnitOfMeasurementEnum): string {
        switch (value) {
            case UnitOfMeasurementEnum.inch:
                return 'inch';
            case UnitOfMeasurementEnum.feet:
                return 'foot';
            case UnitOfMeasurementEnum.yard:
                return 'yard';
            case UnitOfMeasurementEnum.millimeter:
                return 'millimeter';
            case UnitOfMeasurementEnum.centimeter:
                return 'centimeter';
            case UnitOfMeasurementEnum.meter:
                return 'meter';
        }
    }
}
