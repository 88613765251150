import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { StoreDataModel } from '@rift/models/restapi/StoreData.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class RestApiLocalService extends RiftRestApiService {
    private _controller = 'local';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public getStoreData(process?: ProcessMonitorServiceProcess): Observable<StoreDataModel> {
        return this.get<StoreDataModel>(`${this._controller}/getstoredata`, StoreDataModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setStoreData(date: StoreDataModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<StoreDataModel, ResultModel>(`${this._controller}/setstoredata`, date, ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
