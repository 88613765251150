<div class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="spacing-bottom">
            <mat-card-title>
                MQTT Broker Settings
                <div fxFlex></div>
                <div>
                    <button mat-raised-button
                        (click)="onAddBrokerClick()"
                        [disabled]="isReadOnly === true">Add</button>
                </div>
            </mat-card-title>
        </mat-card>
        <form [formGroup]="formGroup">
            <div formArrayName="entries"
                fxLayout="column">
                <ng-template ngFor
                    let-entry
                    [ngForOf]="mqtt?.publishEntries"
                    let-entryIndex="index">
                    <div [formGroupName]="entryIndex"
                        class="spacing-bottom"
                        fxLayout="row"
                        fxFlex>
                        <mat-card fxFlex>
                            <mat-card-title>
                                MQTT Broker ({{entry.address}}:{{entry.port}})
                                <div fxFlex></div>
                                <div>
                                    <button hidden></button>
                                    <button mat-raised-button                                        
                                        (click)="onDeleteBrokerClick(entry, entryIndex)"
                                        [disabled]="isReadOnly === true">Remove</button>
                                </div>
                            </mat-card-title>
                            <mat-card-content>
                                <div fxLayout="row">
                                    <mat-form-field fxFlex>
                                        <input type="text"
                                            #address
                                            matInput
                                            formControlName="address"
                                            placeholder="Address">
                                        <span matSuffix>{{ entry.propertyHasChangesText('address') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['entries']).at(entryIndex)).controls['address'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row">
                                    <mat-form-field fxFlex>
                                        <input type="number"
                                            step="1"
                                            #port
                                            matInput
                                            formControlName="port"
                                            placeholder="Port">
                                        <span matSuffix>{{ entry.propertyHasChangesText('port') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['entries']).at(entryIndex)).controls['port'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row">
                                    <div fxFlex></div>
                                    <div>
                                        <shr-progress-button-spinner [raised]="true"
                                            [diameter]="30"
                                            text="Test Connection"
                                            mode="indeterminate"
                                            [disabled]="isReadOnly === true || isValid === false"
                                            [active]="testConnectionProcess.isRunning === true"
                                            (shrClick)="onTestBrokerClick(entry)"
                                            color="primary">
                                        </shr-progress-button-spinner>
                                    </div>
                                </div>
                                <div fxLayout="row">
                                    <mat-form-field fxFlex>
                                        <input type="number"
                                            step="1"
                                            #keepAlive
                                            matInput
                                            formControlName="keepAlive"
                                            placeholder="Keep Alive (seconds)">
                                        <span matSuffix>{{ entry.propertyHasChangesText('keepAlive') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['entries']).at(entryIndex)).controls['keepAlive'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row">
                                    <mat-form-field fxFlex>
                                        <input type="number"
                                            step="1"
                                            #maxHistory
                                            matInput
                                            formControlName="maxHistory"
                                            placeholder="Max History (hours)">
                                        <span matSuffix>{{ entry.propertyHasChangesText('maxHistory') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['entries']).at(entryIndex)).controls['maxHistory'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row">
                                    <mat-form-field fxFlex>
                                        <input type="number"
                                            step="1"
                                            #maxSize
                                            matInput
                                            formControlName="maxSize"
                                            placeholder="Max Size (bytes)">
                                        <span matSuffix>{{ entry.propertyHasChangesText('maxSize') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['entries']).at(entryIndex)).controls['maxSize'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row"
                                    class="spacing-bottom">
                                    <mat-slide-toggle #credentialsRequired
                                        formControlName="credentialsRequired">
                                        Credentials{{ entry.propertyHasChangesText('credentialsRequired')}}
                                    </mat-slide-toggle>
                                </div>
                                <div fxLayout="row"
                                    *ngIf="entry.credentialsRequired === true">
                                    <mat-form-field *ngIf="entry.credentialsSet === false" fxFlex>
                                        <input type="text"
                                            #username
                                            matInput
                                            autocomplete="mqtt-username"
                                            formControlName="username"
                                            placeholder="Username">
                                        <span matSuffix>{{ entry.credentials?.propertyHasChangesText('username') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['entries']).at(entryIndex)).controls['username'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row"
                                    *ngIf="entry.credentialsRequired === true">
                                    <mat-form-field *ngIf="entry.credentialsSet === false" fxFlex>
                                        <input type="password"
                                            #password
                                            matInput
                                            autocomplete="new-password"
                                            formControlName="password"
                                            placeholder="Password">
                                        <span matSuffix>{{ entry.credentials?.propertyHasChangesText('password') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['entries']).at(entryIndex)).controls['password'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="entry.credentialsRequired === true && entry.credentialsSet === true">                                    
                                    <button mat-button mat-raised-button (click)="changeCredentials(entry)" [disabled]="isReadOnly">Credentials secured click to change</button>
                                </div>
                                <div *ngIf="tLSCapable === true"
                                    fxLayout="row"
                                    class="spacing-top spacing-bottom">
                                    TLS
                                </div>
                                <div *ngIf="tLSCapable === true"
                                    fxLayout="row"
                                    class="spacing-top spacing-bottom">
                                    To configure TLS certificates see the security tab
                                </div>
                                <div *ngIf="tLSCapable === true"
                                    fxLayout="column"
                                    class="spacing-bottom">
                                    <mat-slide-toggle #tLSEnabled
                                        formControlName="tLSEnabled">TLS
                                        Enabled{{entry.propertyHasChangesText('tLSEnabled')}}
                                    </mat-slide-toggle>                                    
                                    <div fxLayout="row" *ngIf="tLSEnabled.checked && multiCertCapable === true" style="margin-top:10px;">                                        
                                        <h4 style="margin-top:unset; margin-right: 10px;">Certificate: </h4>
                                        <mat-select formControlName="sslDir" name="sslDir" style="max-width:150px;">
                                            <mat-option *ngFor="let opt of certPairNames" [value]="opt">
                                                {{opt === '' ? 'Default' : opt}}
                                            </mat-option>
                                        </mat-select>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div *ngIf="tLSCapable === true"
                                    fxLayout="row"
                                    class="spacing-bottom">
                                    <mat-slide-toggle #tLSInsecure
                                        formControlName="tLSInsecure">TLS
                                        Insecure{{entry.propertyHasChangesText('tLSInsecure')}}
                                    </mat-slide-toggle>
                                </div>
                                <div *ngIf="registerFilterCapable === true" 
                                    fxLayout="row"
                                    class="spacing-top spacing-bottom">
                                    Register Filter
                                </div>
                                <div *ngIf="registerFilterCapable === true"
                                    fxLayout="column"
                                    class="spacing-bottom">
                                    <mat-slide-toggle #registerFilterEnabled
                                        formControlName="registerFilterEnabled"
                                        (change)="registerFilterEnabledDisabled($event.checked, entry)"
                                        [checked]="isRegisterFilterEnabled(entry)">Enable{{entry.propertyHasChangesText('registerFilterEnabled')}}
                                    </mat-slide-toggle>
                                    <div fxLayout="column" *ngIf="registerFilterEnabled.checked === true">
                                        <mat-checkbox *ngFor="let reg of registers.items" 
                                            [checked]="isRegisterSelected(entry, reg)"
                                            (change)="registerSelected($event.checked, entry, reg)"
                                            [disabled]="(entry.registerFilter.length <= 1 && isRegisterSelected(entry, reg) === true) || isReadOnly">
                                            {{reg.registerName}}
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div fxLayout="row"
                                    class="spacing-top spacing-bottom">
                                    Topics
                                </div>
                                <div formArrayName="topics"
                                    fxLayout="column">
                                    <ng-template ngFor
                                        let-topic
                                        [ngForOf]="entry.topics"
                                        let-topicIndex="index">
                                        <div [formGroupName]="topicIndex">
                                            <div fxLayout="row"
                                                class="spacing-bottom">
                                                <mat-slide-toggle #enabled
                                                    formControlName="enabled">
                                                    {{ MQTTTopicIdentifierEnumHelpers.getDisplayName(topic.identifier) }}{{topic.propertyHasChangesText('enabled')}}
                                                </mat-slide-toggle>
                                            </div>
                                            <div fxLayout="row"
                                                *ngIf="topic.enabled === true">
                                                <mat-form-field fxFlex>
                                                    <input type="text"
                                                        #topicAddress
                                                        matInput
                                                        formControlName="topicAddress"
                                                        placeholder="Address">
                                                    <span matSuffix>{{ topic.propertyHasChangesText('topicAddress') }}</span>
                                                    <mat-error>
                                                        {{getFormControlErrorMessage(asFormGroup(asFormArray(asFormGroup(asFormArray(formGroup.controls['entries']).at(entryIndex)).controls['topics']).at(topicIndex)).controls['topicAddress'])}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row"
                                                *ngIf="topic.enabled === true && topic.hasPeriod === true">
                                                <mat-form-field fxFlex>
                                                    <input type="number"
                                                        step="1"
                                                        #period
                                                        matInput
                                                        formControlName="period"
                                                        placeholder="Period (seconds)">
                                                    <span matSuffix>{{ topic.propertyHasChangesText('period') }}</span>
                                                    <mat-error>
                                                        {{getFormControlErrorMessage(asFormGroup(asFormArray(asFormGroup(asFormArray(formGroup.controls['entries']).at(entryIndex)).controls['topics']).at(topicIndex)).controls['period'])}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row">
                                                <div fxLayout="row"
                                                    *ngIf="topic.enabled === true && qOSCapable === true">
                                                    <mat-form-field fxFlex>
                                                        <input type="number"
                                                            step="1"
                                                            #qOS
                                                            matInput
                                                            formControlName="qOS"
                                                            placeholder="QOS">
                                                        <span matSuffix>{{ topic.propertyHasChangesText('qOS') }}</span>
                                                        <mat-error>
                                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(asFormGroup(asFormArray(formGroup.controls['entries']).at(entryIndex)).controls['topics']).at(topicIndex)).controls['qOS'])}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="topic.enabled === true && retainCapable === true"
                                                    fxLayout="row"
                                                    class="spacing-bottom">
                                                    <mat-slide-toggle
                                                        formControlName="retain">Retain
                                                        Enabled{{topic.propertyHasChangesText('retain')}}
                                                    </mat-slide-toggle>
                                                </div>
                                            </div>
                                            <div fxLayout="column"
                                                *ngIf="topic.enabled === true && topic.hasFilter === true">
                                                <div fxLayout="row"
                                                    class="spacing-top spacing-bottom">
                                                    Event Filter
                                                </div>
                                                <div fxLayout="column"
                                                    class="spacing-bottom">
                                                    <mat-slide-toggle #eventFilterEnabled
                                                        formControlName="eventFilterEnabled"
                                                        (change)="eventFilterEnabledDisabled($event.checked, topic)"
                                                        [checked]="isEventFilterEnabled(topic)">Enable{{topic.propertyHasChangesText('filterEnabled')}}
                                                    </mat-slide-toggle>
                                                    <div fxLayout="column" *ngIf="eventFilterEnabled.checked === true">
                                                        <mat-checkbox *ngFor="let event of EventTypes"
                                                            [checked]="isEventSelected(topic, event.type)"
                                                            (change)="eventSelected($event.checked, topic, event.type)"
                                                            [disabled]="(topic.filter.length <= 1 && isEventSelected(topic, event.type) === true) || isReadOnly">
                                                            {{event.display}}
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </ng-template>
            </div>
        </form>
    </div>
    <div class="spacing-bottom"
        fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card">
            <mat-card-title>
                MQTT Broker
            </mat-card-title>
            <mat-card-subtitle>
                Address
            </mat-card-subtitle>
            <mat-card-content>
                <span>Address of the MQTT Broker.</span>
            </mat-card-content>
            <mat-card-subtitle>
                Port
            </mat-card-subtitle>
            <mat-card-content>
                <span>Port the MQTT Broker is listening on.</span>
            </mat-card-content>
            <mat-card-subtitle>
                Keep Alive
            </mat-card-subtitle>
            <mat-card-content>
                <span>How often keep alive packets are sent.</span>
            </mat-card-content>
            <mat-card-subtitle>
                Max History
            </mat-card-subtitle>
            <mat-card-content>
                <span>The maximum amount of history the device will buffer.</span>
            </mat-card-content>
            <mat-card-subtitle>
                Max Size
            </mat-card-subtitle>
            <mat-card-content>
                <span>The maximum amount of data that will be sent.</span>
            </mat-card-content>
            <mat-card-subtitle>
                Username
            </mat-card-subtitle>
            <mat-card-content>
                <span>The username required for the broker.</span>
            </mat-card-content>
            <mat-card-subtitle>
                Password
            </mat-card-subtitle>
            <mat-card-content>
                <span>The password required for the broker.</span>
            </mat-card-content>
            <mat-card-subtitle>
                TLS Enabled
            </mat-card-subtitle>
            <mat-card-content>
                <span>Whether the connection to the broker should use TLS.</span>
            </mat-card-content>
            <mat-card-subtitle>
                TLS Insecure
            </mat-card-subtitle>
            <mat-card-content>
                <span>Whether TLS certificate checks should occur.</span>
            </mat-card-content>
            <mat-card-subtitle *ngIf="registerFilterCapable === true">
                Register Filter
            </mat-card-subtitle>
            <mat-card-content *ngIf="registerFilterCapable === true">
                <span>The register filter allows you to select which registers data will be sent in MQTT packets</span>
            </mat-card-content>
            <mat-card-subtitle>
                Counts Topic
            </mat-card-subtitle>
            <mat-card-content>
                <span>The topic that will be used for publishing count data.</span>
            </mat-card-content>
            <mat-card-subtitle>
                Status Topic
            </mat-card-subtitle>
            <mat-card-content>
                <span>The topic that will be used for publishing status data.</span>
            </mat-card-content>
            <mat-card-subtitle *ngIf="mqttEventStreamCapable === true">
                Events Topic
            </mat-card-subtitle>
            <mat-card-content *ngIf="mqttEventStreamCapable === true">
                <span>The topic that will be used for publishing event data.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>