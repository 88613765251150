import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { LicenceService } from '@em/service/data/licence/Licence.Service';
import { isDate, isNullOrUndefined, isNumber, isString } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class ValidationValidators {
    public static ftpAndFtpsAddressPattern: RegExp = /^ftps?:\/\/[a-z0-9-_~\.]+(:[0-9]{1,5})?([a-z0-9-_~\.]+\/)*$/;
    public static httpAndHttpsAddressPattern: RegExp = /^https?:\/\/[a-z0-9-_~\.]+(:[0-9]{1,5})?\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?$/;
    public static ipAddressOrHostNamePattern: RegExp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/;
    public static ipAddressOrHostNameWithPortPattern: RegExp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\:[0-9]*)?$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])(\:[0-9]*)?$/;
    public static ipAddressRegexPattern: RegExp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    public static portPattern: RegExp = /^([0-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
    public static uUIDPattern: RegExp = /^[0-9a-f]{8}-?[0-9a-f]{4}-?[0-5][0-9a-f]{3}-?[089ab][0-9a-f]{3}-?[0-9a-f]{12}$/;
    public static emailAddressPattern: RegExp = /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;
    public static passwordDigitPattern: RegExp = /\d/;
    public static passwordNonCharPattern: RegExp = /[^A-Za-z0-9]/;
    public static passwordUppercase: RegExp = /[A-Z]/;
    public static passwordLowercase: RegExp = /[a-z]/;
    public static registerNamePattern: RegExp = /^[^_|]+$/;
    public static certNamePattern: RegExp = /^[A-Za-z]*$/;

    public static password(control: AbstractControl): ValidationErrors | null {
        if (!isNullOrUndefined(control.value)) {
            if (ValidationValidators.passwordDigitPattern.test(control.value) &&
                ValidationValidators.passwordNonCharPattern.test(control.value) &&
                ValidationValidators.passwordUppercase.test(control.value) &&
                ValidationValidators.passwordLowercase.test(control.value)) {
                return null;
            } else {
                return { password: true };
            }
        }
    }

    /**
     * Checks value is valid email address.
     *
     * @static
     * @param {AbstractControl} control The control to validate.
     * @returns {(ValidationErrors | null)} A validator.
     * @memberof ValidationValidators
     */
    public static emailAddress(control: AbstractControl): ValidationErrors | null {
        if (!isNullOrUndefined(control.value)) {
            if (ValidationValidators.emailAddressPattern.test(control.value)) {
                return null;
            } else {
                return { emailAddress: true };
            }
        }
    }

    public static registerName(control: AbstractControl): ValidationErrors | null {
        if (!isNullOrUndefined(control.value)) {
            if (ValidationValidators.registerNamePattern.test(control.value)) {
                return null;
            } else {
                return { registerName: true };
            }
        }
    }

    public static certName(control: AbstractControl): ValidationErrors | null {
        if (!isNullOrUndefined(control.value)) {
            if (ValidationValidators.certNamePattern.test(control.value)) {
                return null;
            } else {
                return { certName: true };
            }
        }
    }

    /**
     * Checks a range of controls for totaled max value.
     *
     * @static
     * @param {Array<string>} keys The control names to check.
     * @param {number} max the max value.
     * @returns {ValidatorFn}
     * @memberof ValidationValidators
     */
    public static maxCombined(keys: Array<string>, max: number, message?: string): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            if (!isNullOrUndefined(group)) {
                let total: number = 0;

                const length = keys.length;
                for (let index = 0; index < length; index++) {
                    const control: AbstractControl = group.get(keys[index]);
                    if (!isNullOrUndefined(control.value)) {
                        const controlValue = parseInt(control.value, 10);
                        if (!Number.isNaN(controlValue)) {
                            total += controlValue;
                        }
                    }
                }

                if (total > max) {
                    return { maxCombined: { max, message } };
                }

                return null;
            }
            return null;
        };
    }

    /**
     * Checks a range of controls for totaled min value.
     *
     * @static
     * @param {Array<string>} keys The control names to check.
     * @param {number} min the min value.
     * @returns {ValidatorFn}
     * @memberof ValidationValidators
     */
    public static minCombined(keys: Array<string>, min: number, message?: string): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            if (!isNullOrUndefined(group)) {
                let total: number = 0;

                const length = keys.length;
                for (let index = 0; index < length; index++) {
                    const control: AbstractControl = group.get(keys[index]);
                    if (!isNullOrUndefined(control.value)) {
                        const controlValue = parseInt(control.value, 10);
                        if (!Number.isNaN(controlValue)) {
                            total += controlValue;
                        }
                    }
                }

                if (total < min) {
                    return { minCombined: { min, message } };
                }

                return null;
            }
            return null;
        };
    }

    /**
     * Checks if two password controls match.
     *
     * @static
     * @param {string} passwordKey The name of the password key.
     * @param {string} confirmPasswordKey The name of the confirm password key.
     * @returns {ValidatorFn} A validator.
     * @memberof ValidationValidators
     */
    public static passwordMatch(passwordKey: string, confirmPasswordKey: string, message?: string): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            if (!isNullOrUndefined(group)) {
                const password: AbstractControl = group.get(passwordKey);
                const passwordConfirm: AbstractControl = group.get(confirmPasswordKey);

                if (!isNullOrUndefined(password.value) || !isNullOrUndefined(passwordConfirm.value)) {
                    if (password.dirty || passwordConfirm.dirty) {
                        if (password.value !== passwordConfirm.value) {
                            return { mismatchedPasswords: { message } };
                        }
                    }
                    return null;
                }
                return null;
            }
            return null;
        };
    }

    /**
     * Checks that controls value is grater than min date.
     *
     * @static
     * @param {Date} min The min date.
     * @param {string} [message] custom error message.
     * @returns {ValidatorFn}
     * @memberof ValidationValidators
     */
    public static minDate(min: Date, message?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isDate(control.value) && control.value.valueOf() < min.valueOf()) {
                return { minDate: { min, message } };
            }
            return null;
        };
    }

    /**
     * Checks that controls value is less than max date.
     *
     * @static
     * @param {Date} max
     * @param {string} [message] custom error message.
     * @returns {ValidatorFn}
     * @memberof ValidationValidators
     */
    public static maxDate(max: Date, message?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isDate(control.value) && control.value.valueOf() < max.valueOf()) {
                return { maxDate: { max, message } };
            }
            return null;
        };
    }

    /**
     * Checks that graterControlKey is grater than lessControlKey.
     *
     * @static
     * @param {string} graterControlKey The control key.
     * @param {string} lessControlKey The control key.
     * @param {string} [message] The error message to display.
     * @returns {ValidatorFn}
     * @memberof ValidationValidators
     */
    public static graterThan(graterControlKey: string, lessControlKey: string, message?: string): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            if (!isNullOrUndefined(group)) {
                const graterControl: AbstractControl = group.get(graterControlKey);
                const lessControl: AbstractControl = group.get(lessControlKey);

                if (!isNullOrUndefined(graterControl.value) || !isNullOrUndefined(lessControl.value)) {
                    if (graterControl.dirty || lessControl.dirty) {
                        if (!(parseFloat(graterControl.value) > parseFloat(lessControl.value))) {
                            return { graterThan: { message } };
                        }
                    }
                    return null;
                }
                return null;
            }
            return null;
        };
    }

    /**
     * Checks that graterControlKey is grater than or equal to lessControlKey.
     *
     * @static
     * @param {string} graterControlKey The control key.
     * @param {string} lessControlKey The control key.
     * @param {string} [message] The error message to display.
     * @returns {ValidatorFn}
     * @memberof ValidationValidators
     */
    public static graterThanOrEqualTo(graterControlKey: string, lessControlKey: string, message?: string): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            if (!isNullOrUndefined(group)) {
                const graterControl: AbstractControl = group.get(graterControlKey);
                const lessControl: AbstractControl = group.get(lessControlKey);

                if (!isNullOrUndefined(graterControl.value) || !isNullOrUndefined(lessControl.value)) {
                    if (graterControl.dirty || lessControl.dirty) {
                        if (!(parseFloat(graterControl.value) >= parseFloat(lessControl.value))) {
                            return { graterThanOrEqualTo: { message } };
                        }
                    }
                    return null;
                }
                return null;
            }
            return null;
        };
    }

    /**
     * Checks that lessControlKey is less than graterControlKey.
     *
     * @static
     * @param {string} lessControlKey The control key.
     * @param {string} graterControlKey The control key.
     * @param {string} [message] The error message to display.
     * @returns {ValidatorFn}
     * @memberof ValidationValidators
     */
    public static lessThan(lessControlKey: string, graterControlKey: string, message?: string): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            if (!isNullOrUndefined(group)) {
                const graterControl: AbstractControl = group.get(graterControlKey);
                const lessControl: AbstractControl = group.get(lessControlKey);

                if (!isNullOrUndefined(graterControl.value) || !isNullOrUndefined(lessControl.value)) {
                    if (graterControl.dirty || lessControl.dirty) {
                        if (!(parseFloat(lessControl.value) < parseFloat(graterControl.value))) {
                            return { lessThan: { message } };
                        }
                    }
                    return null;
                }
                return null;
            }
            return null;
        };
    }

    /**
     * Checks that lessControlKey is less than or equal to graterControlKey.
     *
     * @static
     * @param {string} lessControlKey The control key.
     * @param {string} graterControlKey The control key.
     * @param {string} [message] The error message to display.
     * @returns {ValidatorFn}
     * @memberof ValidationValidators
     */
    public static lessThanOrEqualTo(lessControlKey: string, graterControlKey: string, message?: string): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            if (!isNullOrUndefined(group)) {
                const graterControl: AbstractControl = group.get(graterControlKey);
                const lessControl: AbstractControl = group.get(lessControlKey);

                if (!isNullOrUndefined(graterControl.value) || !isNullOrUndefined(lessControl.value)) {
                    if (graterControl.dirty || lessControl.dirty) {
                        if (!(parseFloat(lessControl.value) <= parseFloat(graterControl.value))) {
                            return { lessThanOrEqualTo: { message } };
                        }
                    }
                    return null;
                }
                return null;
            }
            return null;
        };
    }

    /**
     * Checks value is less than getMaxLength.
     *
     * @static
     * @param {(() => number)} getMaxLength The call back to get the max length value.
     * @returns {ValidatorFn} A validator.
     * @memberof ValidationValidators
     */
    public static maxLength(getMaxLength: (() => number), message?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = getMaxLength();
            if (!isNullOrUndefined(control.value)) {
                return control.value.length <= value ? null : { maxlength: { requiredLength: value, message } };
            }
            return null;
        };
    }

    /**
     * Checks value is less than getMinLength.
     *
     * @static
     * @param {(() => number)} getMinLength The call back to get the min length value.
     * @returns {ValidatorFn} A validator.
     * @memberof ValidationValidators
     */
    public static minLength(getMinLength: (() => number), message?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = getMinLength();
            if (!isNullOrUndefined(control.value) && !StringUtility.isEmptyOrWhiteSpace(control.value)) {
                return control.value >= value ? null : { minlength: { requiredLength: value, message } };
            }
            return null;
        };
    }

    /**
     * Checks value is unique is results of getValues.
     *
     * @static
     * @param {(() => any[])} getValues The call back to get values.
     * @returns {ValidatorFn} A validator.
     * @memberof ValidationValidators
     */
    public static unique(getValues: (() => string[] | number[] | Date[]), message?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const values = getValues();
            const valuesLength = values.length;
            if (!isNullOrUndefined(control.value) && valuesLength > 0) {
                const first = values[0];
                if (isNumber(first)) {
                    return values.findIndex(value => value === control.value) === -1 ? null : { unique: { message } };
                } else if (isDate(first)) {
                    return values.findIndex(value => value.valueOf() === control.value.valueOf()) === -1 ? null : { unique: { message } };
                } else {
                    return values.findIndex(value => value !== null && value.trim().toLocaleLowerCase() === control.value.trim().toLocaleLowerCase()) === -1 ? null : { unique: { message } };
                }
            }
            return null;
        };
    }

    /**
     * Checks value is valid FTP address.
     *
     * @static
     * @param {AbstractControl} control The control to validate.
     * @returns {(ValidationErrors | null)} A validator.
     * @memberof ValidationValidators
     */
    public static ftpAndFtpsAddress(control: AbstractControl): ValidationErrors | null {
        if (!isNullOrUndefined(control.value) && !StringUtility.isEmptyOrWhiteSpace(control.value)) {
            if (ValidationValidators.ftpAndFtpsAddressPattern.test(control.value)) {
                return null;
            } else {
                return { ftpAndFtpsAddress: true };
            }
        }
    }

    /**
     * Checks value is valid HTTP/s address.
     *
     * @static
     * @param {AbstractControl} control The control to validate.
     * @returns {(ValidationErrors | null)} A validator.
     * @memberof ValidationValidators
     */
    public static httpAndHttpsAddress(control: AbstractControl): ValidationErrors | null {
        if (!isNullOrUndefined(control.value) && !StringUtility.isEmptyOrWhiteSpace(control.value)) {
            if (ValidationValidators.httpAndHttpsAddressPattern.test(control.value)) {
                return null;
            } else {
                return { httpAndHttpsAddress: true };
            }
        }
    }

    /**
     * Checks value is valid IP address.
     *
     * @static
     * @param {AbstractControl} control The control to validate.
     * @returns {(ValidationErrors | null)} A validator.
     * @memberof ValidationValidators
     */
    public static ipAddress(control: AbstractControl): ValidationErrors | null {
        if (!isNullOrUndefined(control.value) && !StringUtility.isEmptyOrWhiteSpace(control.value)) {
            if (ValidationValidators.ipAddressRegexPattern.test(control.value)) {
                return null;
            } else {
                return { ipAddress: true };
            }
        }
    }

    /**
     * Checks value is valid host name or ip address with port.
     *
     * @static
     * @param {AbstractControl} control The control to validate.
     * @returns {(ValidationErrors | null)} A validator.
     * @memberof ValidationValidators
     */
    public static ipAddressOrHostNameWithPort(control: AbstractControl): ValidationErrors | null {
        if (!isNullOrUndefined(control.value) && !StringUtility.isEmptyOrWhiteSpace(control.value)) {
            if (ValidationValidators.ipAddressOrHostNameWithPortPattern.test(control.value)) {
                return null;
            } else {
                return { ipAddressOrHostNameWithPort: true };
            }
        }
    }

    /**
     * Checks value is valid host name or ip address.
     *
     * @static
     * @param {AbstractControl} control The control to validate.
     * @returns {(ValidationErrors | null)} A validator.
     * @memberof ValidationValidators
     */
    public static ipAddressOrHostName(control: AbstractControl): ValidationErrors | null {
        if (!isNullOrUndefined(control.value) && !StringUtility.isEmptyOrWhiteSpace(control.value)) {
            if (ValidationValidators.ipAddressOrHostNamePattern.test(control.value)) {
                return null;
            } else {
                return { ipAddressOrHostName: true };
            }
        }
    }

    /**
     * Checks value is valid port.
     *
     * @static
     * @param {AbstractControl} control The control to validate.
     * @returns {(ValidationErrors | null)} A validator.
     * @memberof ValidationValidators
     */
    public static port(control: AbstractControl): ValidationErrors | null {
        if (!isNullOrUndefined(control.value)) {
            if (ValidationValidators.portPattern.test(control.value)) {
                return null;
            } else {
                return { port: true };
            }
        }
    }

    /**
     * Checks value is valid uUID.
     *
     * @static
     * @param {AbstractControl} control The control to validate.
     * @returns {(ValidationErrors | null)} A validator.
     * @memberof ValidationValidators
     */
    public static uUID(control: AbstractControl): ValidationErrors | null {
        if (!isNullOrUndefined(control.value)) {
            if (ValidationValidators.uUIDPattern.test(control.value)) {
                return null;
            } else {
                return { uUID: true };
            }
        }
    }

    /**
     * Validates em licence keys.
     *
     * @static
     * @param {LicenceService} licenceService The licence service.
     * @returns {AsyncValidatorFn} A validator.
     * @memberof ValidationValidators
     */
    public static licenceKeyValidatorAsync(licenceService: LicenceService, message?: string): AsyncValidatorFn {
        return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
            if (!isNullOrUndefined(control.value) && isString(control.value)) {
                if (control.value.length >= 39) {
                    return licenceService.validateLicenceKey(control.value).pipe(
                        map(result => result === true ? null : { licencekey: { message } } as ValidationErrors)
                    );
                } else {
                    return of({ licencekey: { message } } as ValidationErrors);
                }
            } else {
                return of(null);
            }
        };
    }
}
