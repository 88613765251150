import { AfterViewInit, Component, Injector, Input, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { HistogramConfigModel } from '@rift/models/restapi/HistogramConfig.Model';
import { HistogramDataModel } from '@rift/models/restapi/HistogramData.Model';
import { HistogramLogModel } from '@rift/models/restapi/HistogramLog.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { CountsService } from '@rift/service/data/counts/Counts.Service';
import { HistogramService } from '@rift/service/data/histogram/Histogram.Service';
import { HistogramTypeEnum } from '@shared/enum/HistogramType.Enum';
import { UnitsOfMeasurementService } from '@rift/service/unitsofmeasurement/UnitsOfMeasurement.Service';
import { UnitOfMeasurementEnum } from '@shared/enum/UnitOfMeasurement.Enum';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';

declare const Plotly: any;

enum Units {
    cm = 0,
    seconds = 1,
}

@Component({
    selector: 'rift-histogram-chart',
    templateUrl: './Histogram.Chart.Component.html',
    styleUrls: ['./Histogram.Chart.Component.scss']
})
export class HistogramChartComponent extends RiftBaseComponent implements OnChanges, AfterViewInit {
    public static className: string = 'HistogramChartComponent';

    @HostBinding()
    public id: string = 'rift-histogram-chart';

    @Input()
    public config: HistogramConfigModel;

    @Input()
    public histogramLogs: Array<HistogramLogModel>;

    @Input()
    public register: RegisterBaseModel;

    @Input()
    public registerCount: number;

    @Input()
    public title: string;

    @Input()
    public titlePrefix: string;

    @Input()
    public titleShowOfCounts: boolean;
    public dataLoading: boolean = true;
    public histogramCount: number = 0;
    public noData: boolean = false;

    private _chartBuilt: boolean = false;
    private _chartData = { x: [], y: [], text: [], hoverinfo: 'y+text', type: 'bar' };
    private _chartLayout = { title: '', width: 580, height: 420, margin: { r: 30, l: 70, t: 70, b: 70 }, bargap: 0.05, showlegend: false, xaxis: { title: '', tickmode: 'array', ticktext: [], tickvals: [], showspikes: false, dtick: 0, tick0: 0, }, yaxis: { title: 'Counts' } };
    private _chartOptions: any = null;
    private _firstDataLoaded: boolean = false;
    private _viewInit: boolean = false;

    public constructor(
        private readonly _countsService: CountsService,
        private readonly _histogramService: HistogramService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._chartOptions = this.configurationService.plotlyOptions.defaultOptions;

        this.addSubscription(this.unitsOfMeasurementService.unitsChange.subscribe(units => {
            this.initTraces();
            this.refreshData();
        }));

        this.initConnectionState();
    }

    @Input()
    public get height(): number {
        return this._chartLayout.height;
    }

    public set height(value: number) {
        this._chartLayout.height = value;
    }

    @Input()
    public get width(): number {
        return this._chartLayout.width;
    }

    public set width(value: number) {
        this._chartLayout.width = value;
    }

    public ngAfterViewInit(): void {
        this._viewInit = true;
        this.buildChart();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.noData = false;

        this.initTraces();

        if (!this.isNullOrUndefined(changes.registerCount) && !this.isNullOrUndefined(changes.registerCount.currentValue)) {
            this.setChartTitle(changes.registerCount.currentValue);
        }

        if (!this.isNullOrUndefined(changes.histogramLogs) && !this.isNullOrUndefined(changes.histogramLogs.currentValue)) {
            if (this._firstDataLoaded === false) {
                this.setData();
            } else {
                this.refreshData();
            }
        }
    }

    private buildChart(): void {
        if (!this.isNullOrUndefined(this.register) && !this.isNullOrUndefined(this.config) && this._chartBuilt === false && this._viewInit === true && this.noData === false && this.dataLoading === false) {
            Plotly.newPlot(`histogram-chart-${this.register.registerIndex}-${this.config.Id}`, [this._chartData], this._chartLayout, this._chartOptions).then(() => {
                this._chartBuilt = true;
            });
        }
    }

    private convertUnits(value: number, units: Units): number {
        switch (units) {
            case Units.cm:
                if (this.unitsOfMeasurementService.units === UnitsOfMeasurementEnum.imperial) {
                    return this.unitsOfMeasurementService.convertToImperial(value, UnitOfMeasurementEnum.centimeter, UnitOfMeasurementEnum.inch, 2);
                } else {
                    return value;
                }
            case Units.seconds:
                return value / 10;
        }
    }

    private initTrace(config: HistogramConfigModel, xTitle: string, units: Units): void {
        this._chartLayout.xaxis.title = xTitle;
        this.setChartTitle(this.registerCount);

        this._chartData.text = [];
        this._chartData.x = [];
        this._chartLayout.xaxis.tickvals = [];
        this._chartLayout.xaxis.ticktext = [];

        for (let bi = config.minimumValue; bi < config.minimumValue + (config.numberOfBins * config.binWidth); bi += config.binWidth) {
            this._chartData.text.push(`${this.convertUnits(bi, units)} to ${this.convertUnits(((bi + config.binWidth) - 1), units)} ${units === Units.seconds ? 'seconds' : this.unitsOfMeasurementService.getSuffix(UnitOfMeasurementEnum.centimeter, UnitOfMeasurementEnum.inch, true)}`);
            this._chartData.x.push(bi);
            this._chartLayout.xaxis.tickvals.push(bi);
            this._chartLayout.xaxis.ticktext.push(this.convertUnits(bi, units));
        }

        this._chartLayout.xaxis.dtick = Math.ceil((config.minimumValue + (config.numberOfBins * config.binWidth)) / (config.numberOfBins / 2));
        this._chartLayout.xaxis.tick0 = config.minimumValue;
    }

    private initTraces(): void {
        if (!this.isNullOrUndefined(this.config)) {
            switch (this.config.type) {
                case HistogramTypeEnum.height:
                    this.initTrace(this.config, 'Height', Units.cm);
                    break;
                case HistogramTypeEnum.dwell:
                    this.initTrace(this.config, 'Total Wait Time', Units.seconds);
                    break;
                case HistogramTypeEnum.instantDwell:
                    this.initTrace(this.config, 'Current Wait Time', Units.seconds);
                    break;
                case HistogramTypeEnum.unattendedTime:
                    this.initTrace(this.config, 'Total Unattended Time', Units.seconds);
                    break;
            }
        }
    }

    private refreshChart(): void {
        if (!this.isNullOrUndefined(this.register) && !this.isNullOrUndefined(this.config) && this._chartBuilt === true && this._viewInit === true && this.noData === false && this.dataLoading === false) {
            Plotly.redraw(`histogram-chart-${this.register.registerIndex}-${this.config.Id}`);
        }
    }

    private refreshData(): void {
        if (!this.isNullOrUndefined(this.histogramLogs)) {
            const logLength = this.histogramLogs.length;
            if (logLength > 0) {
                const log = this.histogramLogs[logLength - 1];
                if (!this.isNullOrUndefined(log) && !this.isNullOrUndefined(log.data) && log.data.length > 0) {
                    const histogramData: HistogramDataModel[] = [];
                    const logDataLength = log.data.length;
                    for (let index = 0; index < logDataLength; index++) {
                        const item = log.data[index];
                        if (item.registerID === this.register.registerIndex) {
                            histogramData.push(item);
                        }
                    }

                    const histogramDataLast = histogramData[histogramData.length - 1];

                    if (histogramDataLast.values.length > 0) {
                        this._chartData.y = histogramDataLast.values;
                        this.histogramCount = histogramDataLast.values.reduce((a, b) => a + b, 0);
                        this.setChartTitle(this.registerCount);
                    } else {
                        this.noData = true;
                    }

                    this.refreshChart();
                    return;
                }
            }
        }
        this.noData = true;
    }

    private setChartTitle(registerCount: number): void {
        if (!this.isNullOrUndefined(this.config)) {
            if (this.isNullOrUndefined(this.title)) {
                const prefix = this.isNullOrUndefined(this.titlePrefix) ? '' : this.titlePrefix;
                const ofCounts = this.isNullOrUndefined(this.titleShowOfCounts) || this.titleShowOfCounts === false ? '' : ` (${this.histogramCount} of ${registerCount} counts)`;
                switch (this.config.type) {
                    case HistogramTypeEnum.height:
                        this._chartLayout.title = `${prefix}Height${ofCounts}`;
                        break;
                    case HistogramTypeEnum.dwell:
                        this._chartLayout.title = `${prefix}Dwell${ofCounts}`;
                        break;
                    case HistogramTypeEnum.instantDwell:
                        this._chartLayout.title = `${prefix}Occupancy Time${ofCounts}`;
                        break;
                    case HistogramTypeEnum.unattendedTime:
                        this._chartLayout.title = `${prefix}Unattended Time${ofCounts}`;
                        break;
                }
            } else {
                this._chartLayout.title = this.title;
            }
        }
    }

    private setData(): void {
        if (!this.isNullOrUndefined(this.histogramLogs) && !this.isNullOrUndefined(this.register) && !this.isNullOrUndefined(this.config)) {
            const logLength = this.histogramLogs.length;
            if (logLength > 0) {
                const log = this.histogramLogs[logLength - 1];
                if (!this.isNullOrUndefined(log) && !this.isNullOrUndefined(log.data) && log.data.length > 0) {
                    const histogramData: HistogramDataModel[] = [];
                    const logDataLength = log.data.length;
                    for (let index = 0; index < logDataLength; index++) {
                        const item = log.data[index];
                        if (item.registerID === this.register.registerIndex && item.histogramID === this.config.Id) {
                            histogramData.push(item);
                        }
                    }

                    const histogramDataLast = histogramData[histogramData.length - 1];

                    if (histogramDataLast.values.length > 0) {
                        this._chartData.y = histogramDataLast.values;
                        this.histogramCount = histogramDataLast.values.reduce((a, b) => a + b, 0);
                        this.setChartTitle(this.registerCount);
                        this.dataLoading = false;
                    } else {
                        this.noData = true;
                        this.dataLoading = false;
                    }

                    this._firstDataLoaded = true;
                    this.buildChart();
                    return;
                }
            }
        }
        this.noData = true;
    }
}
