<div>
    <mat-card>
        <shr-table [isLoadingData]="loadDataProcess.isRunning" [pageEnabled]="false" [filterEnabled]="false"
            [dataSource]="relayChannelsDataSource" [displayedColumns]="displayedColumns" [dataExportEnabled]="false"
            [hideMatCard]="true">
            <ng-template #shrTableHeaderActions>
                <mat-slide-toggle *ngIf="canIOCapable"
                    [disabled]="isReadOnly === true" [checked]="relays?.cANIOEnabled"
                    (change)="canIOEnabledChanged($event)">CAN IO
                    {{ relays?.cANIOEnabled === true ? 'Enabled' : 'Disabled'}}</mat-slide-toggle>
            </ng-template>

            <ng-container matColumnDef="channelName">
                <th mat-header-cell *matHeaderCellDef> Channel </th>
                <td mat-cell *matCellDef="let relayChannel">
                    <span matTooltip="{{relayChannel.channelName}}">{{relayChannel.channelName}} </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="operation">
                <th mat-header-cell *matHeaderCellDef> Operation </th>
                <td mat-cell *matCellDef="let relayChannel">
                    <mat-form-field>
                        <mat-select [disabled]="isReadOnly === true" [(value)]="relayChannel.operationType"
                            (selectionChange)="operationSelectionChange($event, relayChannel)">
                            <mat-option
                                *ngIf="relayChannel.validOperationTypes.indexOf(RelayOperationTypeEnum.disabled) > -1"
                                [value]="RelayOperationTypeEnum.disabled">
                                Disabled
                            </mat-option>
                            <mat-option
                                *ngIf="relayChannel.validOperationTypes.indexOf(RelayOperationTypeEnum.input) > -1"
                                [value]="RelayOperationTypeEnum.input">
                                Input
                            </mat-option>
                            <mat-option
                                *ngIf="relayChannel.validOperationTypes.indexOf(RelayOperationTypeEnum.output) > -1"
                                [value]="RelayOperationTypeEnum.output">
                                Output
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="limits">
                <th mat-header-cell *matHeaderCellDef> Limits </th>
                <td mat-cell *matCellDef="let relayChannel">
                    <ng-template [ngIf]="relayChannel.operationType === RelayOperationTypeEnum.input">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <ng-template
                                [ngIf]="isNullOrUndefined(relayChannel.inputFilterLowerLimit) && isNullOrUndefined(relayChannel.inputFilterUpperLimit)">
                                Disabled
                            </ng-template>
                            <ng-template
                                [ngIf]="!isNullOrUndefined(relayChannel.inputFilterLowerLimit) && isNullOrUndefined(relayChannel.inputFilterUpperLimit)">
                                {{ 'from ' + relayChannel.inputFilterLowerLimit + 'ms' }}
                            </ng-template>
                            <ng-template
                                [ngIf]="isNullOrUndefined(relayChannel.inputFilterLowerLimit) && !isNullOrUndefined(relayChannel.inputFilterUpperLimit)">
                                {{ 'to ' + relayChannel.inputFilterUpperLimit + 'ms' }}
                            </ng-template>
                            <ng-template
                                [ngIf]="!isNullOrUndefined(relayChannel.inputFilterLowerLimit) && !isNullOrUndefined(relayChannel.inputFilterUpperLimit)">
                                {{ relayChannel.inputFilterLowerLimit + ' to ' + relayChannel.inputFilterUpperLimit + 'ms' }}
                            </ng-template>
                            <button [disabled]="isReadOnly === true" matTooltip="Edit" mat-icon-button
                                (click)="editRelayChannelLimits(relayChannel)" color="primary">
                                <mat-icon>settings</mat-icon>
                            </button>
                        </div>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="pullUp">
                <th mat-header-cell *matHeaderCellDef> Pull-Up </th>
                <td mat-cell *matCellDef="let relayChannel">
                    <ng-template [ngIf]="relayChannel.operationType !== RelayOperationTypeEnum.disabled">
                        <mat-checkbox [disabled]="isReadOnly === true" [checked]="relayChannel.pullupEnabled"
                            (change)="onPullUpChange($event, relayChannel)"
                            *ngIf="relayChannel.relayLocation === RelayLocationEnum.canIo && relayChannel.channelID !== 8 && relayChannel.channelID !== 9">
                        </mat-checkbox>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="defaultState">
                <th mat-header-cell *matHeaderCellDef> Default State </th>
                <td mat-cell *matCellDef="let relayChannel">
                    <ng-template [ngIf]="relayChannel.operationType !== RelayOperationTypeEnum.disabled">
                        <mat-form-field>
                            <mat-select [disabled]="isReadOnly === true" (selectionChange)="onDefaultStateChange()"
                                [(value)]="relayChannel.defaultState">
                                <ng-template [ngIf]="relayChannel.operationType === RelayOperationTypeEnum.input">
                                    <mat-option
                                        *ngIf="relayChannel.validOperationTypes.indexOf(RelayOperationTypeEnum.input) > -1"
                                        [value]="RelayDefaultStateEnum.high">
                                        {{unitGen === UnitGenerationEnum.kestrel ? 'Low' : 'High' }}
                                    </mat-option>
                                    <mat-option
                                        *ngIf="relayChannel.validOperationTypes.indexOf(RelayOperationTypeEnum.input) > -1"
                                        [value]="RelayDefaultStateEnum.low">
                                        {{unitGen === UnitGenerationEnum.kestrel ? 'High' : 'Low'}}
                                    </mat-option>
                                </ng-template>
                                <ng-template [ngIf]="relayChannel.operationType === RelayOperationTypeEnum.output">
                                    <mat-option
                                        *ngIf="relayChannel.validOperationTypes.indexOf(RelayOperationTypeEnum.output) > -1"
                                        [value]="RelayDefaultStateEnum.high">
                                        {{unitGen === UnitGenerationEnum.kestrel ? 'Closed (Low)' : 'Open (High)'}}
                                    </mat-option>
                                    <mat-option
                                        *ngIf="relayChannel.validOperationTypes.indexOf(RelayOperationTypeEnum.output) > -1"
                                        [value]="RelayDefaultStateEnum.low">
                                        {{unitGen === UnitGenerationEnum.kestrel ? 'Open (High)' : 'Closed (Low)'}}
                                    </mat-option>
                                    <mat-option
                                        *ngIf="relayChannel.relayLocation === RelayLocationEnum.canIo && (relayChannel.channelID === 8 && relayChannel.channelID === 9)"
                                        [value]="RelayDefaultStateEnum.high">
                                        Open
                                    </mat-option>
                                    <mat-option
                                        *ngIf="relayChannel.relayLocation === RelayLocationEnum.canIo && (relayChannel.channelID === 8 && relayChannel.channelID === 9)"
                                        [value]="RelayDefaultStateEnum.low">
                                        Closed
                                    </mat-option>
                                </ng-template>
                            </mat-select>
                        </mat-form-field>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="registers">
                <th mat-header-cell *matHeaderCellDef> Registers </th>
                <td mat-cell class="resisters-td" *matCellDef="let relayChannel">
                    <ng-template [ngIf]="relayChannel.operationType !== RelayOperationTypeEnum.disabled">
                        <ng-template [ngIf]="relayChannel.operationType === RelayOperationTypeEnum.output">
                            <div fxLayout="column" fxLayoutAlign="start start">
                                <ng-template [ngIf]="relayChannel.relayRegisters.length === 0">
                                    <div fxLayout="row" fxLayoutAlign="start center" style="min-height: 40px;">
                                        <span>None</span>
                                    </div>
                                </ng-template>
                                <ng-template ngFor let-relayRegister [ngForOf]="relayChannel.relayRegisters"
                                    let-relayRegisterIndex="index">
                                    <div class="register-row" fxLayout="row" fxLayoutAlign="start center">
                                        <div style="min-width: 180px;">
                                            {{ relayRegister.registerName }}
                                        </div>
                                        <div>
                                            <button [disabled]="isReadOnly === true" matTooltip="Delete" mat-icon-button
                                                (click)="deleteRelayRegister(relayChannel, relayRegister)" color="warn">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                                <mat-form-field *ngIf="isReadOnly === false">
                                    <mat-select [disabled]="isReadOnly"
                                        (selectionChange)="addRelayRegisterSelectionChange($event, relayChannel)">
                                        <mat-option
                                            *ngFor="let register of registerBaseCollection?.items | orderBy: 'registerName'"
                                            [value]="register.registerIndex"
                                            [disabled]="isRegisterUsed(register.registerIndex)">
                                            {{ register.registerName }}
                                        </mat-option>
                                        <mat-option [value]="-2">
                                            Heartbeat
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="relayChannel.operationType === RelayOperationTypeEnum.input">
                            <ng-template ngFor let-relayRegister [ngForOf]="relayChannel.relayRegisters"
                                let-relayRegisterIndex="index">
                                <mat-form-field class="spacing-right">
                                    <input [disabled]="isReadOnly === true" type="text" #registerName matInput
                                        (keyup)="registerNameKeyup($event, relayRegister)"
                                        [value]="relayRegister.registerName"
                                        maxlength="23">
                                    <mat-hint align="end">{{registerName.value.length}} / 23</mat-hint>
                                </mat-form-field>
                            </ng-template>
                        </ng-template>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="mode">
                <th mat-header-cell *matHeaderCellDef> Mode </th>
                <td mat-cell class="resisters-td" *matCellDef="let relayChannel">
                    <div fxLayout="column" *ngIf="relayChannel.operationType === RelayOperationTypeEnum.output"
                        fxLayoutAlign="start start">
                        <ng-template ngFor let-relayRegister [ngForOf]="relayChannel.relayRegisters"
                            let-relayRegisterIndex="index">
                            <div class="register-row" fxLayout="row" fxLayoutAlign="start center">
                                <mat-form-field>
                                    <mat-select [disabled]="isReadOnly === true" (selectionChange)="onModeChange()"
                                        [(value)]="relayRegister.relayMode">
                                        <mat-option [value]="RelayModeEnum.pulse">
                                            {{ RelayModeEnumHelpers.toDisplayString(RelayModeEnum.pulse) }}
                                        </mat-option>
                                        <mat-option [value]="RelayModeEnum.holdHigh"
                                            *ngIf="relayRegister.registerID !== -2 && (isDeviceCapable(DeviceCapabilitiesEnum.extendedRelayOut) | async) === true">
                                            {{(unitGen === UnitGenerationEnum.kestrel || unitGen === UnitGenerationEnum.falcon) ? RelayModeEnumHelpers.toDisplayString(RelayModeEnum.holdLow) : RelayModeEnumHelpers.toDisplayString(RelayModeEnum.holdHigh) }}
                                        </mat-option>
                                        <mat-option [value]="RelayModeEnum.holdLow"
                                            *ngIf="relayRegister.registerID !== -2 && (isDeviceCapable(DeviceCapabilitiesEnum.extendedRelayOut) | async) === true">
                                            {{(unitGen === UnitGenerationEnum.kestrel || unitGen === UnitGenerationEnum.falcon) ? RelayModeEnumHelpers.toDisplayString(RelayModeEnum.holdHigh) : RelayModeEnumHelpers.toDisplayString(RelayModeEnum.holdLow) }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-template>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="pulseWidth">
                <th mat-header-cell class="resisters-td" *matHeaderCellDef>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div>
                            Pulse Width
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let relayChannel">
                    <ng-template [ngIf]="relayChannel.operationType !== RelayOperationTypeEnum.disabled">
                        <div fxLayout="column" fxLayoutAlign="start start">
                            <ng-template [ngIf]="relayChannel.operationType === RelayOperationTypeEnum.output">
                                <ng-template ngFor let-relayRegister [ngForOf]="relayChannel.relayRegisters">
                                    <div class="register-row" fxLayout="row" fxLayoutAlign="start center">
                                        <ng-template
                                            [ngIf]="relayRegister.relayMode === RelayModeEnum.pulse && relayRegister.registerID === -2">
                                            <ng-template [ngIf]="isNullOrUndefined(relayChannel.heartbeatPulseWidth)">
                                                <span class="error">Not Set</span>
                                            </ng-template>
                                            <ng-template [ngIf]="!isNullOrUndefined(relayChannel.heartbeatPulseWidth)">
                                                <span>{{ relayChannel.heartbeatPulseWidth }}ms</span>
                                            </ng-template>
                                        </ng-template>
                                        <ng-template
                                            [ngIf]="relayRegister.relayMode === RelayModeEnum.pulse && relayRegister.registerID !== -2">
                                            <ng-template [ngIf]="isNullOrUndefined(relayRegister.pulseWidth)">
                                                <span class="error">Not Set</span>
                                            </ng-template>
                                            <ng-template [ngIf]="!isNullOrUndefined(relayRegister.pulseWidth)">
                                                <span>{{ relayRegister.pulseWidth }}ms</span>
                                            </ng-template>
                                        </ng-template>
                                        <button [disabled]="isReadOnly"
                                            *ngIf="relayRegister.relayMode === RelayModeEnum.pulse && isReadOnly === false"
                                            matTooltip="Edit" mat-icon-button
                                            (click)="editRelayRegisterPulseWidth(relayRegister, relayChannel)"
                                            color="primary">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </div>
                                </ng-template>
                                <div *ngIf="isReadOnly === false" style="height: 56px;"></div>
                            </ng-template>
                        </div>
                    </ng-template>
                </td>
            </ng-container>
        </shr-table>
        <div *ngIf="globalHeartbeatPeriodEnabled === true">            
            <form [formGroup]="globalHeartbeatFormGroup">
                <div fxLayout="row">
                    <mat-form-field fxFlex>
                        <input type="number"
                            #globalHeartbeatPeriod
                            matInput
                            formControlName="globalHeartbeatPeriod"
                            placeholder="Global heartbeat (seconds)">                        
                        <mat-error>{{getFormControlErrorMessage(globalHeartbeatFormGroup.controls['globalHeartbeatPeriod'])}}</mat-error>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </mat-card>
</div>