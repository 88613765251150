import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';

export interface IInputSourceModel extends IRestModel {
    deviceSerial: string;
    registers: string[];
}

export class InputSourceModel extends BaseModel implements IInputSourceModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public deviceSerial: string = null;
    public registers: string[] = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-InputSourceModel');

        this.registers = restModel.Registers;
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            Registers: this.registers,
        };
    }
}
