import { Component, HostBinding, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import {
    SettingsCountingBaseComponent,
} from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { concat, Observable, zip } from 'rxjs';
import { concatAll, map, switchMap, tap } from 'rxjs/operators';
import { RegisterWirelessPIRModel } from '@rift/models/restapi/RegisterWirelessPIR.Model';
import { WirelessPIRService } from '@rift/service/data/wireless_pir/WirelessPIR.Service';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';


@Component({
    selector: 'rift-settings-counting-wireless-pir-devices',
    templateUrl: './Settings.Counting.WirelessPIRDevices.Component.html',
    styleUrls: ['./Settings.Counting.WirelessPIRDevices.Component.scss']
})
export class SettingsWirelessPIRDevicesComponent extends SettingsCountingBaseComponent implements OnChanges, ILoadDate {
    public static className: string = 'SettingsWirelessPIRDevicesComponent';
    private _pirRegister: RegisterWirelessPIRModel = null;

    @HostBinding()
    public id: string = 'rift-settings-counting-wireless-pir-devices';

    @Input()
    public set register(value: RegisterBaseModel){
        this._pirRegister = (value as RegisterWirelessPIRModel);
    }

    public masterDevice: DeviceModel = null;

    public devices: string[];
    public selectedDevice: string = null;
    public loading: boolean = false;

    public constructor(
        private readonly _wirelessPIRService: WirelessPIRService,
        _dialog: MatDialog,
        _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsWirelessPIRDevicesComponent.className, this.loadDataProcessText);

        this.initConnectionState();
    }

    public loadData(pleaseWaitDialogRequest?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this.loading = true;
        const loadDataSub = zip(
            this.getHostDevice(process).pipe(
                tap(masterDevice => {
                    if (!this.isNullOrUndefined(masterDevice)) {
                        this.masterDevice = masterDevice;
                        return true;
                    }

                    this.loading = false;
                    return false;
                }),
                switchMap(val => this.getWirelessPIRData())
            )
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRequest, process);
    }

    public getWirelessPIRData(): Observable<boolean>{
        return this._wirelessPIRService.getWirelessPIRData(this.masterDevice.serialNumber, this.loadDataProcess).pipe(
            map((result) => {
                this.devices = result.devices;

                this.devices.forEach(d=>{
                    if(d === this._pirRegister.deviceIdentifier){
                        this.selectedDevice = d;
                    }
                });

                this.loading = false;
                return true;
            })
        );
    }

    public deviceSelected(event: MatSelectChange): void {
        this.selectedDevice = event.value;
        this._pirRegister.deviceIdentifier = this.selectedDevice;
    }

    public getSettingsDescription(): string {
        return this.isNullOrUndefined(this._pirRegister) ? '' : '';
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this._pirRegister) ? false : this._pirRegister.propertyHasChanges('deviceIdentifier');
    }

    public get isValid(): boolean {
        return this.isValidBase && !this.isNullOrUndefined(this.selectedDevice) && this.selectedDevice !== '';
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this._pirRegister = changes.register.currentValue;
        }
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this);
    }
}
