import { ComponentType } from '@angular/cdk/overlay';
import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { BaseService } from '@shared/base/Base.Service';
import { BottomInfoComponent } from '@shared/component/bottominfo/BottomInfo.Component';
import { BottomInfoStateEnum } from '@shared/component/bottominfo/BottomInfo.State.Enum';
import { IBottomInfoChildComponent } from '@shared/component/bottominfo/IBottomInfoChild.Component';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class UserNotificationService extends BaseService {
    public bottomInfoVisibleChange: Subject<boolean> = new Subject<boolean>();
    public bottomInfoCountChange: Subject<number> = new Subject<number>();

    private _bottomInfoComponent: BottomInfoComponent = null;
    private _rootViewContainer: ViewContainerRef = null;

    public constructor() {
        super();
    }

    public get bottomInfoCurrentState(): BottomInfoStateEnum {
        return this._bottomInfoComponent.currentState;
    }

    public get bottomInfoNotificationCount(): Observable<number> {
        return this._bottomInfoComponent.notificationCount;
    }

    public get bottomInfoVisible(): boolean {
        return this._bottomInfoComponent.visible;
    }

    public hideBottomInfo(): void {
        this._bottomInfoComponent.hide();
    }

    public showBottomInfo(): void {
        this._bottomInfoComponent.show();
    }

    public toggleShowHideBottomInfo(): void {
        this._bottomInfoComponent.toggleShowHide();
    }

    public addComponent<T extends IBottomInfoChildComponent>(componentType: ComponentType<T>): ComponentRef<T> {
        if (!isNullOrUndefined(this._bottomInfoComponent)) {
            return this._bottomInfoComponent.addComponent(componentType);
        }
    }

    public removeAllComponentType<T extends IBottomInfoChildComponent>(componentType: ComponentType<T>): void {
        if (!isNullOrUndefined(this._bottomInfoComponent)) {
            this._bottomInfoComponent.removeAllComponentTypeInstances(componentType);
        }
    }

    public containsComponent<T extends IBottomInfoChildComponent>(instanceIdOrComponentType: number | ComponentType<T>): boolean {
        if (!isNullOrUndefined(this._bottomInfoComponent)) {
            return this._bottomInfoComponent.containsComponent(instanceIdOrComponentType);
        }
    }

    public getComponents<T extends IBottomInfoChildComponent>(componentType: ComponentType<T>): Array<ComponentRef<IBottomInfoChildComponent>> {
        if (!isNullOrUndefined(this._bottomInfoComponent)) {
            return this._bottomInfoComponent.getComponents(componentType);
        }
    }

    public removeComponent(instanceId: number): void {
        if (!isNullOrUndefined(this._bottomInfoComponent)) {
            this._bottomInfoComponent.removeComponent(instanceId);
        }
    }

    public setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
        this._rootViewContainer = viewContainerRef;
        this.addBottomInfoComponent();
    }

    private addBottomInfoComponent(): void {
        this._bottomInfoComponent = null;
        const component = this._rootViewContainer.createComponent(BottomInfoComponent);
        this._rootViewContainer.insert(component.hostView);
        this._bottomInfoComponent = component.instance;
        this.addSubscription(this._bottomInfoComponent.visibleChange.subscribe(result => this.bottomInfoVisibleChange.next(result)));
        this.addSubscription(this._bottomInfoComponent.countChange.subscribe(result => this.bottomInfoCountChange.next(result)));
    }
}
