<div class="em-myaccount-editdetails-title"
    mat-dialog-title>
    Edit Details
</div>
<form [formGroup]="editDetailsForm">
    <div class="em-myaccount-editdetails-content"
        mat-dialog-content
        fxLayout="column">
        <mat-form-field>
            <input
                type="text"
                #realName
                matInput
                formControlName="realName"
                placeholder="Real Name"
                autofocus>
            <span matSuffix>{{ user?.propertyHasChangesText('realName') }}</span>
            <mat-error>{{getFormControlErrorMessage(editDetailsForm.controls['realName'])}}</mat-error>
            <mat-hint align="end">{{realName.value.length}} / 100</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <input
                type="text"
                #jobTitle
                matInput
                formControlName="jobTitle"
                placeholder="Job Title">
            <span matSuffix>{{ user?.propertyHasChangesText('jobTitle') }}</span>
            <mat-error>{{getFormControlErrorMessage(editDetailsForm.controls['jobTitle'])}}</mat-error>
            <mat-hint align="end">{{jobTitle.value.length}} / 254</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <input
                type="text"
                #organisation
                matInput
                formControlName="organisation"
                placeholder="Organisation">
            <span matSuffix>{{ user?.propertyHasChangesText('organisation') }}</span>
            <mat-error>{{getFormControlErrorMessage(editDetailsForm.controls['organisation'])}}</mat-error>
            <mat-hint align="end">{{organisation.value.length}} / 254</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <input
                type="email"
                #email
                matInput
                formControlName="email"
                placeholder="Email Address">
            <span matSuffix>{{ user?.propertyHasChangesText('email') }}</span>
            <mat-error>{{getFormControlErrorMessage(editDetailsForm.controls['email'])}}</mat-error>
            <mat-hint align="end">{{email.value.length}} / 254</mat-hint>
        </mat-form-field>
    </div>
    <div class="em-myaccount-editdetails-actions"
        mat-dialog-actions>
        <shr-progress-button-spinner
            [raised]="true"
            [diameter]="30"
            text="Save"
            mode="indeterminate"
            [active]="saveAllChangesProcess.isRunning === true"
            [disabled]="!editDetailsForm.valid || !hasChanges"
            (shrClick)="save()"
            color="primary"></shr-progress-button-spinner>
        <div fxFlex></div>
        <button
            [disabled]="saveAllChangesProcess.isRunning === true"
            (click)="cancel()"
            mat-raised-button
            color="warn">Cancel</button>
    </div>
</form>