import { Injectable } from '@angular/core';
import { SafeSubscriptionBase } from '@shared/base/SafeSubscription.Base';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, Subscription, timer, Subject } from 'rxjs';

/**
 * createjs.Container for event timer and subscription.
 *
 * @class EventContainer
 */
class EventContainer {
    public event: DataPollingEvent;
    public timer: Observable<number>;
    public timerSub: Subscription;

    public constructor(_event: DataPollingEvent, _timer: Observable<number>) {
        this.event = _event;
        this.timer = _timer;
    }
}

/**
 * Manages data polling event timing.
 *
 * @export
 * @class DataPollingService
 */
@Injectable()
export class DataPollingService extends SafeSubscriptionBase {
    public disabled = false;

    private _nextEventId = 0;
    private _eventContainers: Array<EventContainer> = [];

    /**
     * Creates an instance of DataPollingService.
     *
     * @memberof DataPollingService
     */
    public constructor() {
        super();
    }

    public getEvent(name: string): DataPollingEvent {
        const container = this._eventContainers.find(e => e.event.name === name);
        if (!isNullOrUndefined(container)) {
            return container.event;
        }
    }

    public stopAll(): void {
        this._eventContainers.forEach(i => this.stopEvent(i.event));
    }

    /**
     * Sets the timer back to start value
     *
     * @param {DataPollingEvent} event The event to push back.
     * @memberof DataPollingService
     */
    public pushBack(event: DataPollingEvent): void {
        if (!isNullOrUndefined(event)) {
            const eventContainer = this._eventContainers.find(e => e.event.name === event.name);
            if (!isNullOrUndefined(eventContainer)) {
                this.pushBackEventContainer(eventContainer);
            }
        }
    }

    /**
     * Starts a data polling event.
     *
     * @param {DataPollingEvent} event The event to start.
     * @returns {DataPollingEvent} The started event.
     * @memberof DataPollingService
     */
    public startEvent(event: DataPollingEvent): DataPollingEvent {
        if (!isNullOrUndefined(event)) {
            let eventContainer = this._eventContainers.find(e => e.event.name === event.name);
            if (isNullOrUndefined(eventContainer)) {
                this.assignNewId(event);

                eventContainer = new EventContainer(event, timer(event.delay, event.interval));
                this._eventContainers.push(eventContainer);

                this.subToTimer(eventContainer);
            } else {
                eventContainer.event = event;

                if (isNullOrUndefined(eventContainer.timer)) {
                    eventContainer.timer = timer(event.delay, event.interval);
                }

                this.subToTimer(eventContainer);

                if (event.delay !== 0) {
                    this.pushBack(event);
                    event.poll.next(eventContainer.event);
                }
            }

            return event;
        }
    }

    /**
     * Stops a data polling event
     *
     * @param {DataPollingEvent} event The event to stop.
     * @returns {boolean} True of the event was stopped. else false.
     * @memberof DataPollingService
     */
    public stopEvent(event: DataPollingEvent): boolean {
        if (!isNullOrUndefined(event)) {
            const index = this._eventContainers.findIndex(e => e.event.name === event.name);
            if (index !== -1) {
                const eventContainer = this._eventContainers[index];

                eventContainer.timer = null;

                if (!isNullOrUndefined(eventContainer.timerSub)) {
                    eventContainer.timerSub.unsubscribe();
                }
                eventContainer.timerSub = null;

                return true;
            }

            return false;
        }
    }

    private assignNewId(event: DataPollingEvent): void {
        event.id = this._nextEventId;
        this._nextEventId++;
    }

    private pushBackEventContainer(eventContainer: EventContainer): void {
        if (!isNullOrUndefined(eventContainer)) {
            if (!isNullOrUndefined(eventContainer.timerSub)) {
                eventContainer.timerSub.unsubscribe();
            }
            eventContainer.timer = timer(eventContainer.event.interval, eventContainer.event.interval);
            this.subToTimer(eventContainer);
        }
    }

    private subToTimer(eventContainer: EventContainer): void {
        eventContainer.timerSub = eventContainer.timer.subscribe(() => {
            if (this.disabled === false) {
                if (eventContainer.event.processes.every(p => p.isRunning === false)) {
                    eventContainer.event.poll.next(eventContainer.event);
                }
            }
            this.pushBackEventContainer(eventContainer);
        });
    }
}
