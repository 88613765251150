import { Component, ComponentRef, HostBinding, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllDataService } from '@em/service/data/alldata/AllData.Service';
import { LicenceService } from '@em/service/data/licence/Licence.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { BottomInfoStateEnum } from '@shared/component/bottominfo/BottomInfo.State.Enum';
import { BottomInfoMessageComponent } from '@shared/component/bottominfo/message/BottomInfo.Message.Component';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { UserNotificationService } from '@shared/service/usernotification/UserNotification.Service';
import { BrowserUtility } from '@shared/utility/Browser.Utility';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { TimeLineLoadQueueService } from '@rift/service/timeline/TimeLine.LoadQueueService';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { NavBarService } from '@shared/service/navbar/NavBar.Service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'em-root',
    templateUrl: './em.component.html',
    styleUrls: ['./em.component.scss']
})
export class EmComponent extends BaseComponent implements OnInit, OnDestroy {
    public static className: string = 'EmComponent';
    public checkLicensedStatusProcess: ProcessMonitorServiceProcess;
    public checkUserStatusProcess: ProcessMonitorServiceProcess;
    public licensedStatusPollProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id = 'app-root';

    public userStatusPollProcess: ProcessMonitorServiceProcess;
    private _licenceNotificationRef: ComponentRef<BottomInfoMessageComponent>;
    private _licensedStatusDataPollingEvent: DataPollingEvent;
    private _userStatusDataPollingEvent: DataPollingEvent;

    public constructor(
        private readonly _navBarService: NavBarService,
        private readonly _timeLineLoadQueueService: TimeLineLoadQueueService,
        private readonly _viewPortLoadQueueService: ViewPortLoadQueueService,
        private readonly _allDataService: AllDataService,
        private readonly _userNotificationService: UserNotificationService,
        private readonly _licenceService: LicenceService,
        private readonly _dataPollingService: DataPollingService,
        private readonly _currentUserService: UserCurrentService,
        private readonly _routerService: Router,
        private readonly _injector: Injector) {
        super(_injector);

        const loadingSplash = document.getElementById('loading-splash');
        if (!isNullOrUndefined(loadingSplash)) {
            loadingSplash.style.visibility = 'hidden';
        }

        if (!BrowserUtility.isCompatible) {
            this._routerService.navigate(['/', 'browser-incompatible']);
        }

        this.checkUserStatusProcess = this.processMonitorService.getProcess(EmComponent.className, 'Checking user status');
        this.userStatusPollProcess = this.processMonitorService.getProcess(EmComponent.className, 'User status polling');
        this.licensedStatusPollProcess = this.processMonitorService.getProcess(EmComponent.className, 'Licence state status polling');
        this.checkLicensedStatusProcess = this.processMonitorService.getProcess(EmComponent.className, 'Checking licence state polling');

        this._userStatusDataPollingEvent = new DataPollingEvent('EmComponent:UserStatus', 20000, 20000, this.checkUserStatusProcess);
        this._licensedStatusDataPollingEvent = new DataPollingEvent('EmComponent:LicensedStatus', 0, 60000, this.checkLicensedStatusProcess);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.userCurrentService.refresh();
        this._allDataService.clearAllCache();
        this.stopUserStatusDataPolling();
        this.stopLicensedStatusDataPolling();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.startUserStatusDataPolling();
        this.startLicensedStatusDataPolling();

        this.checkUserStatus();
    }

    private checkLicensedStatus(): void {
        this.addSubscription(this._licenceService.getShortStatus(this.checkLicensedStatusProcess, {disabled: true}).pipe(
            map(status => {
                if (!this.isNullOrUndefined(status)) {
                    const dateNow = new Date();

                    const expireIn: string = DateTimeUtility.millisecondsToRelativeDuration(status.expiry.valueOf() - dateNow.valueOf());
                    let addNotification = false;
                    let state: BottomInfoStateEnum = BottomInfoStateEnum.info;
                    let message = null;

                    if (status.isLicensed === true) {
                        if (DateTimeUtility.add(dateNow, 1, 'months').valueOf() > status.expiry.valueOf()) {
                            addNotification = true;
                            state = BottomInfoStateEnum.error;
                        } else if (DateTimeUtility.add(dateNow, 2, 'months').valueOf() > status.expiry.valueOf()) {
                            addNotification = true;
                            state = BottomInfoStateEnum.warn;
                        } else if (DateTimeUtility.add(dateNow, 3, 'months').valueOf() > status.expiry.valueOf()) {
                            addNotification = true;
                            state = BottomInfoStateEnum.info;
                        }
                        message = `Your licence will expire in ${expireIn}`;

                        this._navBarService.emUnLicensed = false;
                        this._navBarService.emLicenseServerUnavailable = false;
                    } else {
                        addNotification = true;
                        state = BottomInfoStateEnum.error;
                        message = `Your licence has expired`;

                        if (status.statusText === 'Server Unavailable') {
                            this._navBarService.emLicenseServerUnavailable = true;
                        } else {
                            this._navBarService.emUnLicensed = true;
                        }
                    }

                    if (this.isNullOrUndefined(this._licenceNotificationRef)) {
                        this._licenceNotificationRef = this._userNotificationService.addComponent(BottomInfoMessageComponent);
                    }

                    if (addNotification) {
                        if (!this.isNullOrUndefined(this._licenceNotificationRef.instance)) {
                            this._licenceNotificationRef.instance.message = message;
                            this._licenceNotificationRef.instance.state = state;
                        }
                    } else if (!this.isNullOrUndefined(this._licenceNotificationRef) && !this.isNullOrUndefined(this._licenceNotificationRef.instance)) {
                        this._userNotificationService.removeComponent(this._licenceNotificationRef.instance.instanceId);
                    }
                }
            }),
            catchError((err: any) => {
                console.log(err);
                return of(true);
            })
        ).subscribe(), this.checkLicensedStatusProcess);
    }

    private checkUserStatus(): void {
        this._currentUserService.refresh();
        this.addSubscription(this.observableHandlerBase(this._currentUserService.isAuthenticated, this.checkUserStatusProcess).subscribe(
            isAuthenticated => {
                if (isAuthenticated === false) {
                    this._routerService.navigate(['/login']);
                } else if (this._routerService.url === '/login') {
                    this._routerService.navigate(['/']);
                }
            },
            error => {
                this._routerService.navigate(['/login']);
            }
        ), this.checkUserStatusProcess);
    }

    private subUserStatusDataPolling(): void {
        this.addSubscription(this.observableHandlerBase(this._userStatusDataPollingEvent.poll, this.userStatusPollProcess).subscribe(() => {
            this.checkUserStatus();
        }), this.userStatusPollProcess);
    }

    private startUserStatusDataPolling(): void {
        this.subUserStatusDataPolling();
        this._dataPollingService.startEvent(this._userStatusDataPollingEvent);
    }

    private stopUserStatusDataPolling(): void {
        this._dataPollingService.stopEvent(this._userStatusDataPollingEvent);
    }

    private subLicensedStatusDataPolling(): void {
        this.addSubscription(this._licensedStatusDataPollingEvent.poll.subscribe(() => {
            this.checkLicensedStatus();
        }), this.licensedStatusPollProcess);
    }

    private startLicensedStatusDataPolling(): void {
        this.subLicensedStatusDataPolling();
        this._dataPollingService.startEvent(this._licensedStatusDataPollingEvent);
    }

    private stopLicensedStatusDataPolling(): void {
        this._dataPollingService.stopEvent(this._licensedStatusDataPollingEvent);
    }

}
