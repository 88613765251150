import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

const EXCLUDE_FIELDS = ['isNew'];

export class RoutingDestinationModel extends BaseModel implements IRestModel {
    public iPAddress: string = null;
    public id: number = null;
    public port: number = null;
    public readonly isIRestModel = true;

    public constructor() {
        super();

        this.registerProperty('iPAddress');
        this.registerProperty('port');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-RoutingDestinationModel', EXCLUDE_FIELDS);

        this.setPropertyOriginalValue('iPAddress', this.iPAddress);
        this.setPropertyOriginalValue('port', this.port);
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this, EXCLUDE_FIELDS)
        };
    }
}
