import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageModel } from '@em/models/restapi/Page.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { ScheduleModel } from '@em/models/restapi/Schedule.Model';
import { ScheduleDeviceResultCollectionModel } from '@em/models/restapi/ScheduleDeviceResultCollection.Model';
import { ScheduleOverviewModel } from '@em/models/restapi/ScheduleOverview.Model';
import { ScheduleResultCollectionModel } from '@em/models/restapi/ScheduleResultCollection.Model';
import { WorkflowCollectionModel } from '@em/models/restapi/WorkflowCollection.Model';
import { EmRestApiService } from '@em/service/restapi/base/EmRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';

@Injectable()
export class RestApiSchedulesService extends EmRestApiService {
    private _controller = 'schedules/';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.emRestApiBase, _httpClient);
    }

    public addSchedule(schedule: ScheduleModel, process?: ProcessMonitorServiceProcess): Observable<number> {
        return this.post<ScheduleModel, number>(`${this._controller}addschedule`, schedule, 'number', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteSchedule(scheduleId: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        let params = new HttpParams();
        params = params.append('scheduleId', StringUtility.toString(scheduleId));

        return this.post<null, ResultModel>(`${this._controller}deleteschedule`, null, ResultModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getSchedule(scheduleId: number, process?: ProcessMonitorServiceProcess): Observable<ScheduleModel> {
        return this.get<ScheduleModel>(`${this._controller}getschedule/${StringUtility.toString(scheduleId)}`, ScheduleModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getScheduleOverview(scheduleId: number, process?: ProcessMonitorServiceProcess): Observable<ScheduleOverviewModel> {
        return this.get<ScheduleOverviewModel>(`${this._controller}getscheduleoverview/${StringUtility.toString(scheduleId)}`, ScheduleOverviewModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getScheduleOverviewResults(scheduleId: number, paginationOptions?: PaginationOptionsModel, process?: ProcessMonitorServiceProcess): Observable<ScheduleResultCollectionModel> {
        let params = new HttpParams();
        params = params.append('id', StringUtility.toString(scheduleId));
        params = this.setPaginationOptionsParams(paginationOptions, params);

        return this.get<ScheduleResultCollectionModel>(`${this._controller}getscheduleoverviewresults`, ScheduleResultCollectionModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getScheduleDeviceResults(scheduleId: number, scheduleResultId: number, process?: ProcessMonitorServiceProcess): Observable<ScheduleDeviceResultCollectionModel> {
        let params = new HttpParams();
        params = params.append('scheduleId', StringUtility.toString(scheduleId));
        params = params.append('scheduleResultId', StringUtility.toString(scheduleResultId));

        return this.get<ScheduleDeviceResultCollectionModel>(`${this._controller}getscheduledeviceresults`, ScheduleDeviceResultCollectionModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getSchedulesPage(paginationOptions?: PaginationOptionsModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<PageModel<ScheduleOverviewModel>> {
        let params = new HttpParams();
        params = this.setPaginationOptionsParams(paginationOptions, params);

        return this.getPage<ScheduleOverviewModel, PageModel<ScheduleOverviewModel>>(`${this._controller}getschedules`, ScheduleOverviewModel, PageModel, params, process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getWorkflowNames(process?: ProcessMonitorServiceProcess): Observable<WorkflowCollectionModel> {
        return this.get<WorkflowCollectionModel>(`${this._controller}getworkflownames`, WorkflowCollectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setScheduleEnabledState(scheduleId: number, enabled: boolean, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        let params = new HttpParams();
        params = params.append('scheduleId', StringUtility.toString(scheduleId));
        params = params.append('enabled', StringUtility.toString(enabled));

        return this.post<null, ResultModel>(`${this._controller}setscheduleenabledstate`, null, ResultModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateSchedule(schedule: ScheduleModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.post<ScheduleModel, boolean>(`${this._controller}addschedule`, schedule, 'boolean', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
