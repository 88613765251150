import { NgZone } from '@angular/core';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { FOVCoveragePointModel } from '@rift/models/restapi/FOVCoveragePoint.Model';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { ColorUtility } from '@shared/utility/Color.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { PointUtility } from '@shared/utility/Point.Utility';

interface ICoverageSettings {
    lineWidth: number;
    lineAlpha: number;
    lineColor: string;
    fillAlpha: number;
    fillColor: string;
    selectedLineAlpha?: number;
    selectedLineColor?: string;
}

interface IFieldOfViewSettings {
    inner: ICoverageSettings;
    outer: ICoverageSettings;
}

interface IDeviceBodyColourSettings {
    baseFill: string;
    outline: string;
    sensorFill: string;
    sensorEyeFill: string;
    cameraFill: string;
    cameraEyeFill: string;
    ledLeftFill: string;
    ledRightFill: string;
    ledHeightFill: string;
    textFill: string;
    bodySelectedHighlight: string;
}

interface IDeviceBodySettings {
    width?: number;
    height?: number;
    bodyRadius?: number;
    cameraWidth?: number;
    cameraHeight?: number;
    cameraCornerRad?: number;
    cornerRad: number;
    sensorRadius: number;
    sensorEyeRadius: number;
    cameraRadius: number;
    cameraEyeRadius: number;
    ledRadius: number;
    colours: IDeviceBodyColourSettings;
}

interface IDeviceGenSettings {
    [propName: string]: IDeviceBodySettings;
}

export class DeviceSpriteBuilder {
    private readonly _fieldOfViewSettings = {
        inner: {
            show: true,
            lineWidth: 2,
            lineAlpha: 0.2,
            lineColor: '#2D6D7F',
            fillAlpha: 0.2,
            fillColor: '#F0F0F0'
        } as ICoverageSettings,
        outer: {
            show: true,
            lineWidth: 3,
            lineAlpha: 0.2,
            lineColor: '#2D6D7F',
            selectedLineColor: '#2D6D7F',
            selectedLineAlpha: 1,
            fillAlpha: 0.1,
            fillColor: '#F0F0F0'
        } as ICoverageSettings
    } as IFieldOfViewSettings;

    private readonly _deviceGenSettings = {
        kestrel: {
            width: 80,
            height: 130,
            cornerRad: 6,
            sensorRadius: 10,
            sensorEyeRadius: 4,
            cameraRadius: 10,
            cameraEyeRadius: 8,
            ledRadius: 4,
            colours: {
                baseFill: '#F5F5F5',
                outline: '#000000',
                sensorFill: '#000000',
                sensorEyeFill: '#556B2F',
                cameraFill: '#000000',
                cameraEyeFill: '#2F4F4F',
                ledLeftFill: '#FF0000',
                ledRightFill: '#FF0000',
                ledHeightFill: '#3333ff',
                textFill: '#000000',
                bodySelectedHighlight: '#ff0000'
            } as IDeviceBodyColourSettings,
        } as IDeviceBodySettings,
        gazelle: {
            width: 80,
            height: 130,
            cornerRad: 6,
            sensorRadius: 10,
            sensorEyeRadius: 4,
            cameraRadius: 10,
            cameraEyeRadius: 8,
            ledRadius: 4,
            colours: {
                baseFill: '#F5F5F5',
                outline: '#000000',
                sensorFill: '#000000',
                sensorEyeFill: '#556B2F',
                cameraFill: '#000000',
                cameraEyeFill: '#2F4F4F',
                ledLeftFill: '#008000',
                ledRightFill: '#FF0000',
                textFill: '#000000',
                bodySelectedHighlight: '#ff0000'
            } as IDeviceBodyColourSettings,
        } as IDeviceBodySettings,
        gen4: {
            bodyRadius: 40,
            sensorRadius: 10,
            sensorEyeRadius: 4,
            cameraRadius: 15,
            cameraEyeRadius: 8,
            cameraWidth: 50,
            cameraHeight: 90,
            cameraCornerRad: 20,
            ledRadius: 4,
            colours: {
                baseFill: '#F5F5F5',
                outline: '#000000',
                sensorFill: '#000000',
                sensorEyeFill: '#556B2F',
                cameraFill: '#ECF0F1',
                cameraEyeFill: '#2F4F4F',
                ledLeftFill: '#008000',
                ledRightFill: '#FF0000',
                textFill: '#000000',
                bodySelectedHighlight: '#ff0000'
            } as IDeviceBodyColourSettings,
        } as IDeviceBodySettings
    } as IDeviceGenSettings;

    private _fieldOfViewBuilder: createjs.SpriteSheetBuilder = null;
    private _fieldOfViewSpriteSheet: createjs.SpriteSheet = null;

    private _fieldOfViewOuterBounds: createjs.Rectangle = null;
    private _fieldOfViewInnerBounds: createjs.Rectangle = null;

    private _fieldOfViewContainer: createjs.Container = null;
    private _fieldOfViewOuterShape: createjs.Shape = null;
    private _fieldOfViewInnerShape: createjs.Shape = null;

    private _selectedFieldOfViewContainer: createjs.Container = null;
    private _selectedFieldOfViewOuterShape: createjs.Shape = null;
    private _selectedFieldOfViewInnerShape: createjs.Shape = null;

    private _fieldOfViewSprite: createjs.Sprite = null;
    private _deviceSprite: createjs.Sprite = null;
    private _selectedDeviceSprite: createjs.Sprite = null;

    public constructor(
        private readonly _zone: NgZone,
        private readonly _deviceModel: DeviceModel) {

        this._zone.runOutsideAngular(() => {
            this.buildFieldOfView();
        });
    }

    public getFieldOfViewSprite(): createjs.Sprite {
        return this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._fieldOfViewSprite)) {
                this._fieldOfViewSprite.gotoAndStop(0);
                return this._fieldOfViewSprite;
            } else {
                return null;
            }
        });
    }

    public getSelectedFieldOfViewSprite(): createjs.Sprite {
        return this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._fieldOfViewSprite)) {
                this._fieldOfViewSprite.gotoAndStop(1);
                return this._fieldOfViewSprite;
            } else {
                return null;
            }
        });
    }

    public getDeviceSprite(device: DeviceModel): createjs.Sprite {
        return this._zone.runOutsideAngular(() => {
            if (isNullOrUndefined(this._deviceSprite)) {
                this._deviceSprite = new createjs.Sprite(this.buildDevice(device));
            }
            this._deviceSprite.gotoAndStop(0);
            return this._deviceSprite;
        });
    }

    public getSelectedDeviceSprite(device: DeviceModel): createjs.Sprite {
        return this._zone.runOutsideAngular(() => {
            if (isNullOrUndefined(this._deviceSprite)) {
                this._deviceSprite = new createjs.Sprite(this.buildDevice(device));
            }
            this._deviceSprite.gotoAndStop(1);
            return this._deviceSprite;
        });
    }

    public buildFieldOfView(outerCoverage?: Array<FOVCoveragePointModel>, innerCoverage?: Array<FOVCoveragePointModel>): void {
        return this._zone.runOutsideAngular(() => {
            if (isNullOrUndefined(outerCoverage) && !isNullOrUndefined(this._deviceModel.outerCoverage)) {
                outerCoverage = this._deviceModel.outerCoverage;
            }
            if (isNullOrUndefined(innerCoverage) && !isNullOrUndefined(this._deviceModel.innerCoverage)) {
                innerCoverage = this._deviceModel.innerCoverage;
            }

            if (!isNullOrUndefined(outerCoverage) && !isNullOrUndefined(innerCoverage)) {
                this._fieldOfViewContainer = new createjs.Container();
                this._fieldOfViewOuterShape = new createjs.Shape();
                this._fieldOfViewInnerShape = new createjs.Shape();

                this._fieldOfViewContainer.addChild(this._fieldOfViewOuterShape);
                this._fieldOfViewContainer.addChild(this._fieldOfViewInnerShape);

                this._selectedFieldOfViewContainer = new createjs.Container();
                this._selectedFieldOfViewOuterShape = new createjs.Shape();
                this._selectedFieldOfViewInnerShape = new createjs.Shape();

                this._selectedFieldOfViewContainer.addChild(this._selectedFieldOfViewOuterShape);
                this._selectedFieldOfViewContainer.addChild(this._selectedFieldOfViewInnerShape);

                this._fieldOfViewBuilder = new createjs.SpriteSheetBuilder();

                this._fieldOfViewSpriteSheet = null;
                this._fieldOfViewSprite = null;

                this.getFieldOfViewBounds(outerCoverage, innerCoverage);

                this.drawFieldOfView(false, 'outer', outerCoverage, this._fieldOfViewOuterShape);
                this.drawFieldOfView(false, 'inner', innerCoverage, this._fieldOfViewInnerShape);

                this.drawFieldOfView(true, 'outer', outerCoverage, this._selectedFieldOfViewOuterShape);
                this.drawFieldOfView(false, 'inner', innerCoverage, this._selectedFieldOfViewInnerShape);

                this._fieldOfViewBuilder.addAnimation(
                    'field-of-view',
                    [
                        this._fieldOfViewBuilder.addFrame(this._fieldOfViewContainer, this._fieldOfViewOuterBounds),
                        this._fieldOfViewBuilder.addFrame(this._selectedFieldOfViewContainer, this._fieldOfViewOuterBounds)
                    ],
                );

                this._fieldOfViewSpriteSheet = this._fieldOfViewBuilder.build();
                this._fieldOfViewSprite = new createjs.Sprite(this._fieldOfViewSpriteSheet);
            }
        });
    }

    private getFieldOfViewBounds(outerCoverage?: Array<FOVCoveragePointModel>, innerCoverage?: Array<FOVCoveragePointModel>): void {
        return this._zone.runOutsideAngular(() => {
            if (isNullOrUndefined(outerCoverage) && !isNullOrUndefined(this._deviceModel.outerCoverage)) {
                outerCoverage = this._deviceModel.outerCoverage;
            }
            if (isNullOrUndefined(innerCoverage) && !isNullOrUndefined(this._deviceModel.innerCoverage)) {
                innerCoverage = this._deviceModel.innerCoverage;
            }

            this._fieldOfViewOuterBounds = !isNullOrUndefined(outerCoverage) ? PointUtility.getBounds(outerCoverage.map(i => ({ x: i.x, y: i.y * -1 }))) : null;
            this._fieldOfViewInnerBounds = !isNullOrUndefined(innerCoverage) ? PointUtility.getBounds(innerCoverage.map(i => ({ x: i.x, y: i.y * -1 }))) : null;
        });
    }

    private buildDevice(device: DeviceModel): createjs.SpriteSheet {
        return this._zone.runOutsideAngular(() => {
            const builder = new createjs.SpriteSheetBuilder();

            let deviceGen: (device: DeviceModel, selected: boolean) => { container: createjs.Container; bounds: createjs.Rectangle };
            switch (device.unitGen) {
                case UnitGenerationEnum.gazelle:
                    deviceGen = this.drawGazelleBody.bind(this);
                    break;
                case UnitGenerationEnum.gen4:
                    deviceGen = this.drawGen4Body.bind(this);
                    break;
                case UnitGenerationEnum.kestrel:
                case UnitGenerationEnum.falcon:
                    deviceGen = this.drawKestrelBody.bind(this);
                    break;
            }
            const unselected = deviceGen(device, false);
            const selected = deviceGen(device, true);

            builder.addAnimation(
                'device',
                [
                    builder.addFrame(unselected.container, unselected.bounds),
                    builder.addFrame(selected.container, selected.bounds),
                ],
            );

            return builder.build();
        });
    }

    private drawKestrelBody(device: DeviceModel, selected: boolean = false): { container: createjs.Container; bounds: createjs.Rectangle } {
        return this._zone.runOutsideAngular(() => {
            const settings = this._deviceGenSettings.kestrel;
            const width = settings.width;
            const height = settings.height;

            const container = new createjs.Container();
            container.removeAllChildren();

            // Draw the body of the device.
            const eBody = new createjs.Shape();

            eBody.graphics.clear();
            eBody.graphics.setStrokeStyle(5);
            eBody.graphics.beginFill(settings.colours.baseFill);
            eBody.graphics.beginStroke(selected ? settings.colours.bodySelectedHighlight : settings.colours.outline);
            eBody.graphics.drawRoundRect(0, 0, width, height, settings.cornerRad);

            container.addChild(eBody);

            // Draw the sensor of the device.
            const eSensor = new createjs.Shape();

            const sensorCenter = { x: (width / 2) - (settings.sensorRadius + 2), y: 2 * settings.sensorRadius };

            eSensor.graphics.clear();

            eSensor.graphics.beginFill(settings.colours.sensorFill);
            eSensor.graphics.arc(sensorCenter.x, sensorCenter.y, settings.sensorRadius, 0, Math.PI * 2, false);

            eSensor.graphics.beginFill(settings.colours.sensorEyeFill);
            eSensor.graphics.arc(sensorCenter.x, sensorCenter.y, settings.sensorEyeRadius, 0, Math.PI * 2, false);

            container.addChild(eSensor);

            if (device.videoDevice === true) {
                // Draw the camera of the device.
                const eCamera = new createjs.Shape();

                const cameraCenter = { x: (width / 2) + (settings.cameraRadius + 2), y: 2 * settings.cameraRadius };

                eCamera.graphics.clear();

                eCamera.graphics.beginFill(settings.colours.cameraFill);
                eCamera.graphics.arc(cameraCenter.x, cameraCenter.y, settings.cameraRadius, 0, Math.PI * 2, false);

                eCamera.graphics.beginFill(settings.colours.cameraEyeFill);
                eCamera.graphics.arc(cameraCenter.x, cameraCenter.y, settings.cameraEyeRadius, 0, Math.PI * 2, false);

                container.addChild(eCamera);
            }

            if (device.isCapable(DeviceCapabilitiesEnum.height) === true) {
                // Draw the time of flight led's of the device.
                const eTimeOfFlightLed = new createjs.Shape();

                const stepX = width / 6;
                const timeOfFlightFirstY = height - (2 * settings.ledRadius);
                const timeOfFlightSecondY = height - (5 * settings.ledRadius);

                eTimeOfFlightLed.graphics.clear();

                eTimeOfFlightLed.graphics.beginFill(settings.colours.ledHeightFill);
                eTimeOfFlightLed.graphics.arc(1 * stepX, timeOfFlightFirstY, settings.ledRadius, 0, Math.PI * 2, false);
                eTimeOfFlightLed.graphics.arc(2 * stepX, timeOfFlightFirstY, settings.ledRadius, 0, Math.PI * 2, false);
                eTimeOfFlightLed.graphics.arc(3 * stepX, timeOfFlightFirstY, settings.ledRadius, 0, Math.PI * 2, false);
                eTimeOfFlightLed.graphics.arc(4 * stepX, timeOfFlightFirstY, settings.ledRadius, 0, Math.PI * 2, false);
                eTimeOfFlightLed.graphics.arc(5 * stepX, timeOfFlightFirstY, settings.ledRadius, 0, Math.PI * 2, false);
                eTimeOfFlightLed.graphics.endFill();

                eTimeOfFlightLed.graphics.beginFill(settings.colours.ledHeightFill);
                eTimeOfFlightLed.graphics.arc(1 * stepX, timeOfFlightSecondY, settings.ledRadius, 0, Math.PI * 2, false);
                eTimeOfFlightLed.graphics.arc(2 * stepX, timeOfFlightSecondY, settings.ledRadius, 0, Math.PI * 2, false);
                eTimeOfFlightLed.graphics.arc(3 * stepX, timeOfFlightSecondY, settings.ledRadius, 0, Math.PI * 2, false);
                eTimeOfFlightLed.graphics.arc(4 * stepX, timeOfFlightSecondY, settings.ledRadius, 0, Math.PI * 2, false);
                eTimeOfFlightLed.graphics.arc(5 * stepX, timeOfFlightSecondY, settings.ledRadius, 0, Math.PI * 2, false);
                eTimeOfFlightLed.graphics.endFill();

                container.addChild(eTimeOfFlightLed);
            }

            // Draw the status leds of the device.
            const eLed = new createjs.Shape();

            eLed.graphics.clear();

            eLed.graphics.beginFill(settings.colours.ledLeftFill);
            eLed.graphics.arc((width / 2) - (2 * settings.ledRadius), 40, settings.ledRadius, 0, Math.PI * 2, false);
            eLed.graphics.endFill();

            eLed.graphics.beginFill(settings.colours.ledRightFill);
            eLed.graphics.arc((width / 2) + (2 * settings.ledRadius), 40, settings.ledRadius, 0, Math.PI * 2, false);
            eLed.graphics.endFill();

            container.addChild(eLed);

            // Draw the mater, node label of the device.
            const eTypeLabel = new createjs.Text(device.master === true ? 'Master' : 'Node', '21px Arial', settings.colours.textFill);

            eTypeLabel.x = (width - eTypeLabel.getBounds().width) / 2;
            eTypeLabel.y = height / 2;

            container.addChild(eTypeLabel);

            // Draw the serial number label of the device.
            const eSerialLabel = new createjs.Text(device.serialNumber, '10px Arial', settings.colours.textFill);

            eSerialLabel.x = (width - eSerialLabel.getBounds().width) / 2;
            eSerialLabel.y = (height / 2) - 10;

            container.addChild(eSerialLabel);

            return { container, bounds: new createjs.Rectangle(0, 0, width, height) };
        });
    }

    private drawGazelleBody(device: DeviceModel, selected: boolean = false): { container: createjs.Container; bounds: createjs.Rectangle } {
        return this._zone.runOutsideAngular(() => {
            const settings = this._deviceGenSettings.gazelle;
            const width = settings.width;
            const height = settings.height;

            const container = new createjs.Container();
            container.removeAllChildren();

            // Draw the body of the device.
            const eBody = new createjs.Shape();

            eBody.graphics.clear();
            eBody.graphics.setStrokeStyle(5);
            eBody.graphics.beginFill(settings.colours.baseFill);
            eBody.graphics.beginStroke(selected ? settings.colours.bodySelectedHighlight : settings.colours.outline);
            eBody.graphics.drawRoundRect(0, 0, width, height, settings.cornerRad);

            container.addChild(eBody);

            // Draw the sensor of the device.
            const eSensor = new createjs.Shape();

            const sensorCenter = { x: (width / 2) - (settings.sensorRadius + 2), y: 2 * settings.sensorRadius };

            eSensor.graphics.clear();

            eSensor.graphics.beginFill(settings.colours.sensorFill);
            eSensor.graphics.arc(sensorCenter.x, sensorCenter.y, settings.sensorRadius, 0, Math.PI * 2, false);

            eSensor.graphics.beginFill(settings.colours.sensorEyeFill);
            eSensor.graphics.arc(sensorCenter.x, sensorCenter.y, settings.sensorEyeRadius, 0, Math.PI * 2, false);

            container.addChild(eSensor);

            if (device.videoDevice === true) {
                // Draw the camera of the device.
                const eCamera = new createjs.Shape();

                const cameraCenter = { x: (width / 2) + (settings.cameraRadius + 2), y: 2 * settings.cameraRadius };

                eCamera.graphics.clear();

                eCamera.graphics.beginFill(settings.colours.cameraFill);
                eCamera.graphics.arc(cameraCenter.x, cameraCenter.y, settings.cameraRadius, 0, Math.PI * 2, false);

                eCamera.graphics.beginFill(settings.colours.cameraEyeFill);
                eCamera.graphics.arc(cameraCenter.x, cameraCenter.y, settings.cameraEyeRadius, 0, Math.PI * 2, false);

                container.addChild(eCamera);
            }

            // Draw the status leds of the device.
            const eLed = new createjs.Shape();

            eLed.graphics.clear();

            eLed.graphics.beginFill(settings.colours.ledLeftFill);
            eLed.graphics.arc((width / 2) - (2 * settings.ledRadius), 40, settings.ledRadius, 0, Math.PI * 2, false);
            eLed.graphics.endFill();

            eLed.graphics.beginFill(settings.colours.ledRightFill);
            eLed.graphics.arc((width / 2) + (2 * settings.ledRadius), 40, settings.ledRadius, 0, Math.PI * 2, false);
            eLed.graphics.endFill();

            container.addChild(eLed);

            // Draw the mater, node label of the device.
            const eTypeLabel = new createjs.Text(device.master === true ? 'Master' : 'Node', '21px Arial', settings.colours.textFill);

            eTypeLabel.x = (width - eTypeLabel.getBounds().width) / 2;
            eTypeLabel.y = height / 2;

            container.addChild(eTypeLabel);

            // Draw the serial number label of the device.
            const eSerialLabel = new createjs.Text(device.serialNumber, '14px Arial', settings.colours.textFill);

            eSerialLabel.x = (width - eSerialLabel.getBounds().width) / 2;
            eSerialLabel.y = (height / 2) - 15;

            container.addChild(eSerialLabel);

            return { container, bounds: new createjs.Rectangle(-2, -2, width + 4, height + 4) };
        });
    }

    private drawGen4Body(device: DeviceModel, selected: boolean = false): { container: createjs.Container; bounds: createjs.Rectangle } {
        return this._zone.runOutsideAngular(() => {
            const settings = this._deviceGenSettings.gen4;
            const width = 2 * settings.bodyRadius;
            let height = 2 * settings.bodyRadius;

            const container = new createjs.Container();
            container.removeAllChildren();

            if (device.videoDevice === true) {
                height += settings.cameraHeight;

                // Draw the camera of the device.
                const eCamera = new createjs.Shape();

                eCamera.graphics.clear();
                eCamera.graphics.setStrokeStyle(5);

                eCamera.graphics.beginFill(settings.colours.baseFill);
                eCamera.graphics.beginStroke(settings.colours.outline);
                eCamera.graphics.drawRoundRect((width - settings.cameraWidth) / 2, settings.bodyRadius * 2, settings.cameraWidth, settings.cameraHeight, settings.cameraCornerRad);
                eCamera.graphics.endStroke();

                eCamera.graphics.beginFill(settings.colours.cameraFill);
                eCamera.graphics.arc(width / 2, -15, settings.cameraRadius, 0, Math.PI * 2, false);

                eCamera.graphics.beginFill(settings.colours.cameraEyeFill);
                eCamera.graphics.arc(width / 2, -15, settings.cameraEyeRadius, 0, Math.PI * 2, false);

                container.addChild(eCamera);
            }

            // Draw the body of the device.
            const eBody = new createjs.Shape();

            eBody.graphics.clear();
            eBody.graphics.setStrokeStyle(5);
            eBody.graphics.beginFill(settings.colours.baseFill);
            eBody.graphics.beginStroke(selected ? settings.colours.bodySelectedHighlight : settings.colours.outline);
            eBody.graphics.arc(width / 2, height / 2, settings.bodyRadius, 0, Math.PI * 2, false);

            container.addChild(eBody);

            // Draw the sensor of the device.
            const eSensor = new createjs.Shape();

            const sensorCenter = { x: width / 2, y: height / 2 };

            eSensor.graphics.clear();

            eSensor.graphics.beginFill(settings.colours.sensorFill);
            eSensor.graphics.arc(sensorCenter.x, sensorCenter.y, settings.sensorRadius, 0, Math.PI * 2, false);

            eSensor.graphics.beginFill(settings.colours.sensorEyeFill);
            eSensor.graphics.arc(sensorCenter.x, sensorCenter.y, settings.sensorEyeRadius, 0, Math.PI * 2, false);

            container.addChild(eSensor);

            // Draw the status leds of the device.
            const eLed = new createjs.Shape();

            eLed.graphics.clear();
            eSensor.graphics.beginFill(settings.colours.ledLeftFill);
            eSensor.graphics.arc((width / 2) - 8, (height / 2) + 15, settings.ledRadius, 0, Math.PI * 2, false);

            eSensor.graphics.beginFill(settings.colours.ledRightFill);
            eSensor.graphics.arc((width / 2) + 8, (height / 2) + 15, settings.ledRadius, 0, Math.PI * 2, false);

            container.addChild(eLed);

            return { container, bounds: new createjs.Rectangle(-5, -5, width + 10, height + 10) };
        });
    }

    private drawFieldOfView(selected: boolean, type: 'inner' | 'outer', points: Array<{ x: number; y: number }>, shape: createjs.Shape): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(points) && points.length > 0) {
                const settings = this._fieldOfViewSettings[type];

                shape.graphics.clear();
                shape.graphics.setStrokeStyle(settings.lineWidth);

                if (selected === true) {
                    shape.graphics.beginStroke(ColorUtility.hexToRGBA(settings.selectedLineColor, settings.selectedLineAlpha));
                } else {
                    shape.graphics.beginStroke(ColorUtility.hexToRGBA(settings.lineColor, settings.lineAlpha));
                }

                shape.graphics.beginFill(ColorUtility.hexToRGBA(settings.fillColor, settings.fillAlpha));

                shape.graphics.moveTo(points[0].x, points[0].y * -1);

                const length = points.length;
                for (let i = 1; i < length; i++) {
                    shape.graphics.lineTo(points[i].x, points[i].y * -1);
                }

                shape.graphics.closePath();
            }
        });
    }
}
