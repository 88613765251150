import { Injectable } from '@angular/core';
import { DeviceActivityModel } from '@em/models/restapi/DeviceActivity.Model';
import { DeviceActivitySummaryModel } from '@em/models/restapi/DeviceActivitySummary.Model';
import { PageModel } from '@em/models/restapi/Page.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiDeviceService } from '@em/service/restapi/RestApi.Device.Service';
import { DeviceActivityActionEnum } from '@shared/enum/DeviceActivityAction.Enum';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

@Injectable()
export class ActivityService extends EmBaseService {
    private _getActivityPageLoadingTracker = new ObservableTracker<PageModel<DeviceActivityModel>>();
    private _getActivitySummaryCache: DeviceActivitySummaryModel;
    private _getActivitySummaryLoadingTracker = new ObservableTracker<DeviceActivitySummaryModel>();

    public constructor(
        private readonly _restApiDeviceService: RestApiDeviceService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getActivitySummaryCache = null;
    }

    public getActivityPage(friendlySerial: string, paginationOptions?: PaginationOptionsModel, fromDate?: Date, actions?: Array<DeviceActivityActionEnum>, process?: ProcessMonitorServiceProcess): Observable<PageModel<DeviceActivityModel>> {
        return this._getActivityPageLoadingTracker
            .getLoading(friendlySerial, paginationOptions, fromDate, actions)
            .observable(this._restApiDeviceService.getActivityPage(friendlySerial, paginationOptions, fromDate, actions, process));
    }
    public getActivitySummary(friendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<DeviceActivitySummaryModel> {
        if (isNullOrUndefined(this._getActivitySummaryCache)) {
            return this._getActivitySummaryLoadingTracker
                .getLoading(friendlySerial)
                .observable(this._restApiDeviceService.getActivitySummary(friendlySerial, process).pipe(
                    map(result => {
                        this._getActivitySummaryCache = result;
                        return this._getActivitySummaryCache;
                    })
                ));
        } else {
            return of(this._getActivitySummaryCache);
        }
    }
}
