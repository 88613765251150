import { Injectable } from '@angular/core';
import { ConnectionService } from '@rift/service/connection/Connection.Service';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { DatabaseSessionInfoStore } from '@rift/service/validation/database/syncsession/Database.SessionInfo.Store';
import { DatabaseSettings } from '@rift/service/validation/database/syncsession/Database.Settings';
import { DatabaseUserCountStore } from '@rift/service/validation/database/syncsession/Database.UserCount.Store';
import { IDbValidationSessionInfoModel } from '@rift/service/validation/models/database/syncsession/IDbValidationSessionInfo.Model';
import { IValidationRecordingModel } from '@rift/service/validation/models/ValidationRecording.Model';
import { IStopRequest } from '@rift/service/validation/models/webworker/generic/IStop.Request';
import { IProgressUpdateResponse } from '@rift/service/validation/models/webworker/syncrecording/IProgressUpdate.Response';
import { ICloseSessionRequest } from '@rift/service/validation/models/webworker/usercounts/ICloseSession.Request';
import { ICloseSessionResponse } from '@rift/service/validation/models/webworker/usercounts/ICloseSession.Response';
import { IDecrementCountRequest } from '@rift/service/validation/models/webworker/usercounts/IDecrementCount.Request';
import { IDecrementCountResponse } from '@rift/service/validation/models/webworker/usercounts/IDecrementCount.Response';
import { IDeleteCountRequest } from '@rift/service/validation/models/webworker/usercounts/IDeleteCount.Request';
import { IDeleteCountResponse } from '@rift/service/validation/models/webworker/usercounts/IDeleteCount.Response';
import { IIncrementCountRequest } from '@rift/service/validation/models/webworker/usercounts/IIncrementCount.Request';
import { IIncrementCountResponse } from '@rift/service/validation/models/webworker/usercounts/IIncrementCount.Response';
import { IInitializeRequest } from '@rift/service/validation/models/webworker/usercounts/IInitialize.Request';
import { IOpenSessionRequest } from '@rift/service/validation/models/webworker/usercounts/IOpenSession.Request';
import { IOpenSessionResponse } from '@rift/service/validation/models/webworker/usercounts/IOpenSession.Response';
import { BaseService } from '@shared/base/Base.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IResponse } from '@shared/webworker/IResponse';
import { ResponseTypesEnum } from '@shared/webworker/ResponseTypes.Enum';
import { WebWorkerClient } from '@shared/webworker/WebWorker';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ValidationUserCountsWebWorkerService extends BaseService {
    public onError: Subject<Error> = new Subject<Error>();
    public onProgressUpdate: Subject<IProgressUpdateResponse> = new Subject<IProgressUpdateResponse>();
    private _client: WebWorkerClient;

    public constructor(
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _configurationService: ConfigurationService,
        private readonly _connectionService: ConnectionService) {
        super();
    }

    public openSession(sessionId: number, sessionRecordingId: number, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IOpenSessionRequest, IOpenSessionResponse>({
                action: 'openSession',
                sessionId,
                sessionRecordingId,
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return true;
                    }

                    return false;
                })
            );
        }
    }

    public closeSession(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<ICloseSessionRequest, ICloseSessionResponse>({
                action: 'closeSession',
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return true;
                    }

                    return false;
                })
            );
        }
    }

    public incrementCount(frameNumber: number, registerIndex: number, session: IDbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<number[][]> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IIncrementCountRequest, IIncrementCountResponse>({
                action: 'incrementCount',
                frameNumber,
                registerIndex,
                session,
                validationRecording,
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response.userCounts;
                    }

                    return null;
                })
            );
        }
    }

    public decrementCount(frameNumber: number, registerIndex: number, session: IDbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<number[][]> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IDecrementCountRequest, IDecrementCountResponse>({
                action: 'decrementCount',
                frameNumber,
                registerIndex,
                session,
                validationRecording,
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response.userCounts;
                    }

                    return null;
                })
            );
        }
    }

    public deleteCount(frameNumber: number, registerIndex: number, session: IDbValidationSessionInfoModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<number[][]> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IDeleteCountRequest, IDeleteCountResponse>({
                action: 'deleteCount',
                frameNumber,
                registerIndex,
                session,
                validationRecording,
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response.userCounts;
                    }

                    return null;
                })
            );
        }
    }

    public start(process?: ProcessMonitorServiceProcess): void {
        this._client = new WebWorkerClient(new Worker(new URL('@rift/workers/validation-user-counts.worker', import.meta.url), { type: 'module' }));
        this.addSubscription(this._client.progressUpdate.subscribe(update => this.onProgressUpdate.next(update)));
        this.addSubscription(this._client.error.subscribe(error => this.onError.next(error.error)));

        this.addSubscription(this._client.sendMessage<IInitializeRequest, IResponse>({
            action: 'initialize',

            hostFriendlySerial: this._connectionService.hostFriendlySerial,

            socketUrl: this._configurationService.riftWebSocket,
            connectionToken: this._connectionTokenService.connectionToken,
            secureToken: this._connectionTokenService.secureToken,
            riftV1RestApiBase: this._configurationService.riftV1RestApiBase,

            databaseName: DatabaseSettings.databaseName,
            databaseVersion: DatabaseSettings.databaseVersion,

            databaseSessionInfoConfig: DatabaseSessionInfoStore.toInterface(),
            databaseUserCountConfig: DatabaseUserCountStore.toInterface(),
        }, process).subscribe(
            response => {
                if (response.type === ResponseTypesEnum.error) {
                    this.onError.next(new Error('Unable to start web worker'));
                }
            },
            error => {
                this.onError.next(new Error(`Unable to start web worker:${error}`));
            }
        ));
    }

    public stop(): void {
        if (!isNullOrUndefined(this._client)) {
            this.addSubscription(this._client.sendMessage<IStopRequest, IResponse>({ action: 'stop' }).subscribe(
                () => this._client.terminate()
            ));
        }
    }

}
