import { Injectable } from '@angular/core';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { DeviceAdvancedSettingsModel } from '@rift/models/restapi/DeviceAdvancedSettings.Model';
import { DeviceCollectionModel } from '@rift/models/restapi/DeviceCollection.Model';
import { ErrorsAndWarningsModel } from '@rift/models/restapi/ErrorsAndWarnings.Model';
import { IPSetupModel } from '@rift/models/restapi/IPSetup.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { TestConnectionDataModel } from '@rift/models/restapi/TestConnectionData.Model';
import { TestConnectionResultModel } from '@rift/models/restapi/TestConnectionResult.Model';
import { TimeOfFlightConfigDataModel } from '@rift/models/restapi/TimeOfFlightConfigData.Model';
import { UpTimeModel } from '@rift/models/restapi/UpTime.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiDevicesService } from '@rift/service/restapi/v1/RestApi.Devices.Service';
import { AutoHeightDetectionStateEnum } from '@shared/enum/AutoHeightDetectionState.Enum';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { DiagnosticsLogRangeEnum } from '@shared/enum/DiagnosticsLogRange.Enum';
import { LEDStateEnum } from '@shared/enum/LEDState.Enum';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { UnitTypeEnum } from '@shared/enum/UnitType.Enum';
import { Dictionary } from '@shared/generic/Dictionary';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined, isString } from '@shared/utility/General.Utility';
import { ObservableUtility } from '@shared/utility/Observable.Utility';
import { Observable, of, timer, zip, Observer, from } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { ZipUtility } from '@shared/utility/Zip.Utility';
import { IFileResponse } from '@shared/service/restapi/RestApi.Service';
import { FormatSystemStorageEnum } from '@shared/enum/FormatSystemStorage.Enum';
import { ImageSnapShotModel } from '@rift/models/restapi/ImageSnapShot.Model';

@Injectable()
export class DeviceService extends RiftBaseService {

    private _advancedSettingsCache: DeviceAdvancedSettingsModel = null;
    private _connectedDeviceCache: DeviceModel = null;
    private _devicesCache: DeviceCollectionModel = null;
    private _hostDeviceCache: DeviceModel = null;
    private _nodeCountCache: number = null;
    private _nodeDevicesCache: Array<DeviceModel> = null;
    private _ipSettingsCache: Dictionary<string, IPSetupModel> = new Dictionary<string, IPSetupModel>();

    private _autoDetectHeightLoadingTracker = new ObservableTracker<boolean>();
    private _getAdvancedSettingsLoadingTracker = new ObservableTracker<DeviceAdvancedSettingsModel>();
    private _getDetectedHeightLoadingTracker = new ObservableTracker<boolean>();
    private _getDevicesLoadingTracker = new ObservableTracker<DeviceCollectionModel>();
    private _getDiagnosticsByRangeLoadingTracker = new ObservableTracker<ErrorsAndWarningsModel>();
    private _getDiagnosticsCountsLoadingTracker = new ObservableTracker<ErrorsAndWarningsModel>();
    private _getHostDeviceLoadingTracker = new ObservableTracker<DeviceModel>();
    private _getIPSettingsLoadingTracker = new ObservableTracker<IPSetupModel>();
    private _getNodeCountLoadingTracker = new ObservableTracker<number>();
    private _getNodeDevicesLoadingTracker = new ObservableTracker<Array<DeviceModel>>();
    private _getUpTimeLoadingTracker = new ObservableTracker<UpTimeModel>();
    private _loadDevicesLoadingTracker = new ObservableTracker<DeviceCollectionModel>();
    private _refreshDeviceTiltLoadingTracker = new ObservableTracker<boolean>();
    private _setIPSettingsLoadingTracker = new ObservableTracker<ResultModel>();
    private _setLedStateLoadingTracker = new ObservableTracker<ResultModel>();
    private _testConnectionLoadingTracker = new ObservableTracker<TestConnectionResultModel>();
    private _setTimeOfFlightFrameRateLoadingTracker = new ObservableTracker<ResultModel>();
    private _createDiagnosticsPackageLoadingTracker = new ObservableTracker<ResultModel>();
    private _listDiagnosticsPackageLoadingTracker = new ObservableTracker<Array<string>>();
    private _removeDiagnosticsPackageLoadingTracker = new ObservableTracker<ResultModel>();
    private _getDiagnosticsPackageLoadingTracker = new ObservableTracker<IFileResponse>();
    private _formatSystemStorageLoadingTracker = new ObservableTracker<ResultModel>();
    private _getFormatSystemStorageResultLoadingTracker = new ObservableTracker<string>();

    public constructor(
        private readonly _restApiDevicesService: RestApiDevicesService) {
        super();
    }

    public resetLogs(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._restApiDevicesService.resetLogs(process);
    }

    public autoDetectHeight(device: DeviceModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (device.isCapable(DeviceCapabilitiesEnum.height)) {
            return this._autoDetectHeightLoadingTracker
                .getLoading(device.serialNumber)
                .observable(this._restApiDevicesService.autoDetectHeight(device.serialNumber, process).pipe(
                    flatMap(() => this.getDeviceAutoHeight(device, process)),
                ));
        } else {
            return of(true);
        }
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._ipSettingsCache = new Dictionary<string, IPSetupModel>();
        this._devicesCache = null;
        this._nodeCountCache = null;
        this._nodeDevicesCache = null;
        this._hostDeviceCache = null;
        this._connectedDeviceCache = null;
        this._advancedSettingsCache = null;
    }

    public getAdvancedSettings(process?: ProcessMonitorServiceProcess): Observable<DeviceAdvancedSettingsModel> {
        if (isNullOrUndefined(this._advancedSettingsCache)) {
            return this._getAdvancedSettingsLoadingTracker
                .getLoading()
                .observable(
                    this._restApiDevicesService.getAdvancedSettings(process).pipe(
                        map(result => {
                            this._advancedSettingsCache = result;
                            return this._advancedSettingsCache;
                        })
                    )
                );
        } else {
            return of(this._advancedSettingsCache);
        }
    }

    public getDevices(process?: ProcessMonitorServiceProcess, skipDeviceHeight: boolean = true): Observable<DeviceCollectionModel> {
        if (isNullOrUndefined(this._devicesCache)) {
            return this._getDevicesLoadingTracker
                .getLoading()
                .observable(this._restApiDevicesService.getDevices(process).pipe(
                    flatMap(devices => this.loadDevices(devices, skipDeviceHeight))
                ));
        } else {
            // We need to make sure that if the device height info
            // is required that the cache has it present
            // if it doesn't then we need to get it rather than just return the cache
            if (skipDeviceHeight === false){
                // Check the cache first
                if (this._devicesCache.items.length !== 0 && !this._devicesCache.items.every(e=>e.getAutoHeightCompleted)){
                    return this._getDevicesLoadingTracker
                        .getLoading()
                        .observable(this._restApiDevicesService.getDevices(process).pipe(
                            flatMap(devices => this.loadDevices(devices, skipDeviceHeight))
                        ));
                }
            }

            return of(this._devicesCache);
        }
    }

    public getDiagnosticsByRange(serialNumber: string, count: number, nextIndex?: number, range?: DiagnosticsLogRangeEnum, newestFirst?: boolean, process?: ProcessMonitorServiceProcess): Observable<ErrorsAndWarningsModel> {
        return this._getDiagnosticsByRangeLoadingTracker
            .getLoading(serialNumber, count, nextIndex, range, newestFirst)
            .observable(this._restApiDevicesService.getDiagnosticsByRange(serialNumber, count, nextIndex, range, newestFirst, process));
    }

    public getDiagnosticsCounts(serialNumber: string, range?: DiagnosticsLogRangeEnum, process?: ProcessMonitorServiceProcess): Observable<ErrorsAndWarningsModel> {
        return this._getDiagnosticsCountsLoadingTracker
            .getLoading(serialNumber, range)
            .observable(this._restApiDevicesService.getDiagnosticsCounts(serialNumber, range, process));
    }

    public getHostDevice(connectedToFriendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<DeviceModel> {

        if (isNullOrUndefined(this._hostDeviceCache)) {
            return this._getHostDeviceLoadingTracker
                .getLoading(connectedToFriendlySerial)
                .observable(this.getDevices(process).pipe(
                    map(deviceColl => {
                        let masterDevice: DeviceModel = null;
                        this._connectedDeviceCache = null;

                        const length = deviceColl.items.length;
                        for (let i = 0; i < length; i++) {
                            const device = deviceColl.items[i];
                            if (device.master === true) {
                                masterDevice = device;
                            }
                            if (device.serialNumber === connectedToFriendlySerial) {
                                this._connectedDeviceCache = device;
                            }
                        }

                        if (!isNullOrUndefined(masterDevice)) {
                            this._hostDeviceCache = masterDevice;
                            return this._hostDeviceCache;
                        } else if (!isNullOrUndefined(this._connectedDeviceCache)) {
                            this._hostDeviceCache = this._connectedDeviceCache;
                            return this._connectedDeviceCache;
                        } else {
                            throw new Error(deviceColl.error);
                        }
                    })
                ));
        } else {
            return of(this._hostDeviceCache);
        }
    }

    public getIPSettings(serial: string, process?: ProcessMonitorServiceProcess): Observable<IPSetupModel> {
        const cache = this._ipSettingsCache.get(serial);
        if (isNullOrUndefined(cache)) {
            return this._getIPSettingsLoadingTracker
                .getLoading(serial)
                .observable(this._restApiDevicesService.getIPSettings(serial, process).pipe(
                    map(result => {
                        this._ipSettingsCache.addOrUpdate(serial, result);
                        return result;
                    })
                ));
        } else {
            return of(cache);
        }
    }

    public getNodeCount(process?: ProcessMonitorServiceProcess): Observable<number> {
        if (isNullOrUndefined(this._nodeCountCache)) {
            return this._getNodeCountLoadingTracker
                .getLoading()
                .observable(this.getDevices(process).pipe(
                    map(result => {
                        this._nodeCountCache = 0;
                        const length = result.items.length;
                        for (let index = 0; index < length; index++) {
                            const device = result.items[index];
                            if (device.master === false && !(device.unitGen === UnitGenerationEnum.gen4 && device.unitType === UnitTypeEnum.valCam)) {
                                this._nodeCountCache++;
                            }
                        }
                        return this._nodeCountCache;
                    })
                ));
        } else {
            return of(this._nodeCountCache);
        }
    }

    public getNodeDevices(process?: ProcessMonitorServiceProcess): Observable<Array<DeviceModel>> {
        if (isNullOrUndefined(this._nodeDevicesCache)) {
            return this._getNodeDevicesLoadingTracker
                .getLoading()
                .observable(this._restApiDevicesService.getDevices(process).pipe(
                    map(result => {
                        this._nodeDevicesCache = [];
                        const length = result.items.length;
                        for (let index = 0; index < length; index++) {
                            const item = result.items[index];
                            if (item.master === false) {
                                this._nodeDevicesCache.push(item);
                            }
                        }
                        return this._nodeDevicesCache;
                    })
                ));
        } else {
            return of(this._nodeDevicesCache);
        }
    }

    public getUpTime(process?: ProcessMonitorServiceProcess): Observable<UpTimeModel> {
        return this._getUpTimeLoadingTracker
            .getLoading()
            .observable(this._restApiDevicesService.getUpTime(process));
    }

    public refreshDeviceTilt(device: DeviceModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._refreshDeviceTiltLoadingTracker
            .getLoading(device.serialNumber)
            .observable(
                this._restApiDevicesService.refreshDeviceTilt(device.serialNumber, process).pipe(
                    flatMap(() => timer(500).pipe(
                            flatMap(() => this._restApiDevicesService.getDevice(device.serialNumber, process).pipe(
                                    map(cleanDevices => {
                                        if (cleanDevices.items.length === 1) {
                                            const cleanDevice = cleanDevices.items[0];
                                            device.gVector = cleanDevice.gVector;
                                            device.outerCoverage = cleanDevice.outerCoverage;
                                            device.innerCoverage = cleanDevice.innerCoverage;
                                            device.commitChanges();
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    })
                                ))
                        )),
                )
            );
    }

    public setCache(devicesCache: DeviceCollectionModel, advancedSettingsCache: DeviceAdvancedSettingsModel): Observable<boolean> {
        this._advancedSettingsCache = advancedSettingsCache;
        this._nodeCountCache = null;
        this._nodeDevicesCache = null;
        this._hostDeviceCache = null;
        this._connectedDeviceCache = null;

        return this.loadDevices(devicesCache, false).pipe(
            map(() => true),
        );
    }

    public setIPSettings(serial: string, ipSettings: IPSetupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setIPSettingsLoadingTracker
            .getLoading(serial, ipSettings)
            .observable(this._restApiDevicesService.setIPSettings(serial, ipSettings, process).pipe(
                map(result => {
                    this._ipSettingsCache.remove(serial);
                    return result;
                })
            ));
    }

    public updateAdvancedSettings(settings: DeviceAdvancedSettingsModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setIPSettingsLoadingTracker
            .getLoading(settings)
            .observable(this._restApiDevicesService.updateAdvancedSettings(settings, process));
    }

    public updateDevice(device: DeviceModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setIPSettingsLoadingTracker
            .getLoading(device)
            .observable(this._restApiDevicesService.updateDevice(device, process).pipe(tap(() => this.clearCache())));
    }

    public setLedState(serial: string, state: LEDStateEnum, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setLedStateLoadingTracker
            .getLoading(serial, state)
            .observable(this._restApiDevicesService.setLedState(serial, state, process));
    }

    public testConnection(serial: string, connection: TestConnectionDataModel, process?: ProcessMonitorServiceProcess): Observable<TestConnectionResultModel> {
        return this._testConnectionLoadingTracker
            .getLoading(connection)
            .observable(this._restApiDevicesService.testConnection(serial, connection, process));
    }

    public setTimeOfFlightFrameRate(serial: string, tofConfig: TimeOfFlightConfigDataModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setTimeOfFlightFrameRateLoadingTracker
            .getLoading(tofConfig)
            .observable(this._restApiDevicesService.setTimeOfFlightFrameRate(serial, tofConfig, process));
    }

    public createDiagnosticsPackage(serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._createDiagnosticsPackageLoadingTracker
            .getLoading(serial)
            .observable(this._restApiDevicesService.createDiagnosticsPackage(serial, process));
    }

    public listDiagnosticsPackage(serial: string, process?: ProcessMonitorServiceProcess): Observable<Array<string>> {
        return this._listDiagnosticsPackageLoadingTracker
            .getLoading(serial)
            .observable(this._restApiDevicesService.listDiagnosticsPackages(serial, process));
    }

    public deleteDiagnosticsPackage(serial: string, packageName: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._removeDiagnosticsPackageLoadingTracker
            .getLoading(serial, packageName)
            .observable(this._restApiDevicesService.deleteDiagnosticsPackage(serial, packageName, process));
    }

    public getDiagnosticsPackage(serial: string, packageName: string, process?: ProcessMonitorServiceProcess): Observable<IFileResponse> {
        return this._getDiagnosticsPackageLoadingTracker
            .getLoading(serial, packageName)
            .observable(this._restApiDevicesService.getDiagnosticsPackage(serial, packageName, process));
    }

    public formatSystemStorage(serial: string, formatType: FormatSystemStorageEnum, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._formatSystemStorageLoadingTracker
            .getLoading(serial, formatType)
            .observable(this._restApiDevicesService.formatSystemStorage(serial, formatType, process));
    }

    public getFormatSystemStorageResult(serial: string, process?: ProcessMonitorServiceProcess): Observable<string> {
        return this._getFormatSystemStorageResultLoadingTracker
            .getLoading(serial)
            .observable(this._restApiDevicesService.getFormatSystemStorageResult(serial, process));
    }

    public getImageSnapshot(serial: string, res: string, process?: ProcessMonitorServiceProcess): Observable<ImageSnapShotModel> {
        return this._restApiDevicesService.getImageSnapshot(serial, res, process);
    }

    public filterGen4(devices: DeviceModel[]): DeviceModel[] {
        const master: DeviceModel = devices.find(i => i.master === true);

        if (!isNullOrUndefined(master)) {
            if (master.isGen(UnitGenerationEnum.gen4)) {
                const videoNodeIndex = devices.findIndex(d => d.videoDevice === true && d.master === false);
                if (videoNodeIndex !== -1) {
                    const videoNode = devices[videoNodeIndex];
                    devices.splice(videoNodeIndex, 1);

                    videoNode.capabilities.forEach(capability => master.capabilities.addOrUpdate(capability, true));

                    master.videoOffsets = videoNode.videoOffsets;
                    master.videoDevice = true;
                    master.videoCroppingWindow = videoNode.videoCroppingWindow;
                    master.videoSerialNumber = videoNode.serialNumber;
                }
            }
        }

        return devices;
    }

    private getAutoHeight(device: DeviceModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (device.isCapable(DeviceCapabilitiesEnum.height)) {
            return this._getDetectedHeightLoadingTracker
                .getLoading(device.serialNumber)
                .observable(
                    this._restApiDevicesService.getDetectedHeight(device.serialNumber, process).pipe(
                        map(autoHeight => {
                            device.autoHeight = autoHeight;
                            device.commitChanges();
                            device.getAutoHeightCompleted = true;
                            return true;
                        })
                    )
                );
        } else {
            device.getAutoHeightCompleted = true;
            return of(true);
        }
    }

    private getDeviceAutoHeight(device: DeviceModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (device.isCapable(DeviceCapabilitiesEnum.height)) {
            return this._restApiDevicesService.getAutoHeightDetectionState(device.serialNumber, process).pipe(
                flatMap(state => {
                    if (!isString(state)) {
                        device.autoHeightState = state;

                        switch (state) {
                            case AutoHeightDetectionStateEnum.notSupported:
                                device.autoHeightMessage = 'Not Supported';
                                device.autoHeightEnabled = false;
                                device.getAutoHeightCompleted = true;
                                return of(true);
                            case AutoHeightDetectionStateEnum.none:
                                device.autoHeightMessage = 'Unconfigured';
                                device.autoHeightEnabled = false;
                                device.getAutoHeightCompleted = true;
                                return of(true);
                            case AutoHeightDetectionStateEnum.preset:
                                device.autoHeightMessage = 'Preset';
                                if (device.height === 0) {
                                    device.autoHeightMessage += ' (Auto)';
                                    return this.getAutoHeight(device, process);
                                } else {
                                    device.autoHeightMessage += ' (Manual)';
                                    device.autoHeightEnabled = false;
                                    device.getAutoHeightCompleted = true;
                                    return of(true);
                                }
                            case AutoHeightDetectionStateEnum.autoInitializing:
                                device.autoHeightMessage = 'Auto Initializing';
                                device.autoHeightEnabled = true;
                                return timer(1000).pipe(
                                    flatMap(() => this.getDeviceAutoHeight(device, process))
                                );
                            case AutoHeightDetectionStateEnum.autoValid:
                                device.autoHeightMessage = 'Auto Valid';
                                device.autoHeightEnabled = true;
                                return this.getAutoHeight(device, process);
                            case AutoHeightDetectionStateEnum.autoInvalid:
                                device.autoHeightMessage = 'Invalid Height (Auto)';
                                device.autoHeightEnabled = false;
                                device.getAutoHeightCompleted = true;
                                return of(true);
                            case AutoHeightDetectionStateEnum.failedHeight:
                                device.autoHeightMessage = 'Invalid Height';
                                device.autoHeightEnabled = false;
                                device.getAutoHeightCompleted = true;
                                return of(true);
                            case AutoHeightDetectionStateEnum.failedOrientation:
                                device.autoHeightMessage = 'Invalid Orientation';
                                device.autoHeightEnabled = false;
                                device.getAutoHeightCompleted = true;
                                return of(true);
                            case AutoHeightDetectionStateEnum.failedHeightAndOrientation:
                                device.autoHeightMessage = 'Invalid Orientation and Height';
                                device.autoHeightEnabled = false;
                                device.getAutoHeightCompleted = true;
                                return of(true);
                            case AutoHeightDetectionStateEnum.croppedOrientation:
                                device.autoHeightMessage = 'Orientation cropped';
                                device.autoHeightEnabled = true;
                                return this.getAutoHeight(device, process);
                            case AutoHeightDetectionStateEnum.unknownState:
                                device.autoHeightMessage = 'Unknown State';
                                device.autoHeightEnabled = false;
                                device.getAutoHeightCompleted = true;
                                return of(true);
                        }
                    } else {
                        return of(false);
                    }
                })
            );
        } else {
            device.getAutoHeightCompleted = true;
            return of(true);
        }
    }

    private loadDevices(devices: DeviceCollectionModel, skipDeviceHeight: boolean, process?: ProcessMonitorServiceProcess): Observable<DeviceCollectionModel> {
        if (isNullOrUndefined(devices)){
            return of(new DeviceCollectionModel());
        }

        return this._loadDevicesLoadingTracker
            .getLoading(...devices.items.map(i => i.serialNumber), skipDeviceHeight)
            .observable(this.loadDevicesObs(devices, skipDeviceHeight));
    }

    private loadDevicesObs(devices: DeviceCollectionModel, skipDeviceHeight: boolean, process?: ProcessMonitorServiceProcess): Observable<DeviceCollectionModel> {
        this.filterGen4(devices.items);

        const getDeviceAutoHeightSubs: Observable<boolean>[] = [];
        if (skipDeviceHeight === false) {
            const length = devices.items.length;
            for (let i = 0; i < length; i++) {
                const device = devices.items[i];
                getDeviceAutoHeightSubs.push(this.getDeviceAutoHeight(device, process));
            }
        }

        return zip(...ObservableUtility.zipArrayCheck(getDeviceAutoHeightSubs, of(true))).pipe(
            map(() => {
                this._devicesCache = devices;
                return this._devicesCache;
            })
        );
    }
}
