import { DeviceModel } from '@em/models/restapi/Device.Model';
import { WorkflowModel } from '@em/models/restapi/Workflow.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { ScheduleEventTypeEnum } from '@shared/enum/ScheduleEventType.Enum';
import { ScheduleGranularityEnum } from '@shared/enum/ScheduleGranularity.Enum';
import { WorkflowOperationTypeEnum } from '@shared/enum/WorkflowOperationType.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';


const EXCLUDE_FIELDS = ['workflow', 'lastExecutedText', 'nextExecutionText', 'endExecutionText', 'operatesOnText', 'executionWaitPeriodText', 'friendlySerialNumber', 'scheduledDevice'];

export class ScheduleOverviewModel extends BaseModel implements IRestModel {

    public coveragePercentage: number = null;
    public currentProgress: number = null;
    public description: string = null;
    public devices: Array<DeviceModel> = null;
    public endExecution: Date = null;
    public executeOnEvent: ScheduleEventTypeEnum = null;
    public executionWaitPeriod: number = null;
    public folderId: number = null;
    public folderName: string = null;
    public id: number = null;
    public isCurrentlyExecuting: boolean = null;
    public isDisabled: boolean = null;
    public isUserSchedule: boolean = null;
    public lastExecuted: Date = null;
    public name: string = null;
    public nextExecution: Date = null;
    public numScheduleResults: number = null;
    public operatesOn: ScheduleGranularityEnum = null;
    public parentSchedule: ScheduleOverviewModel = null;
    public readonly isIRestModel = true;
    public successRatePercentage: number = null;
    public workflow: WorkflowModel = null;

    private _endExecutionText: string;
    private _executionWaitPeriodText: string;
    private _isActive: boolean;
    private _isFinished: boolean;
    private _isSystem: boolean;
    private _lastExecutedText: string;
    private _nextExecutionText: string;
    private _operatesOnText: string;

    public constructor() {
        super();
    }

    public get endExecutionText(): string {
        return this._endExecutionText;
    }

    public get isNA(): boolean {
        return (isNullOrUndefined(this.lastExecuted) || (!isNullOrUndefined(this.workflow) && this.workflow.operationType === WorkflowOperationTypeEnum.database));
    }

    public get executionWaitPeriodText(): string {
        return this._executionWaitPeriodText;
    }

    public get isActive(): boolean {
        return this._isActive;
    }

    public get isFinished(): boolean {
        return this._isFinished;
    }

    public get isSystem(): boolean {
        return this._isSystem;
    }

    public get lastExecutedText(): string {
        return this._lastExecutedText;
    }

    public get nextExecutionText(): string {
        return this._nextExecutionText;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ScheduleOverviewModel', EXCLUDE_FIELDS);

        this.lastExecuted = DateTimeNonMomentUtility.fromRestApiDateTime(this.lastExecuted);
        this.nextExecution = DateTimeNonMomentUtility.fromRestApiDateTime(this.nextExecution);
        this.endExecution = DateTimeNonMomentUtility.fromRestApiDateTime(this.endExecution);

        this.devices = RestModelUtility.loadFromArray<DeviceModel>(restModel.Devices, DeviceModel);
        this.parentSchedule = RestModelUtility.loadFrom(restModel.ParentSchedule, ScheduleOverviewModel);

        this.setText();
    }

    public setText(): void {
        this._executionWaitPeriodText = null;
        this._operatesOnText = null;
        this._lastExecutedText = null;
        this._nextExecutionText = null;
        this._endExecutionText = null;
        this._isFinished = null;
        this._isActive = null;
        this._isSystem = null;

        if (isNullOrUndefined(this._executionWaitPeriodText)) {
            this._executionWaitPeriodText = DateTimeUtility.minutesToRelativeDuration(this.executionWaitPeriod);
            this._executionWaitPeriodText = this._executionWaitPeriodText.replace('an ', 'every ');
            this._executionWaitPeriodText = this._executionWaitPeriodText.replace('a ', 'every ');
            if (this._executionWaitPeriodText.indexOf('every ') !== 0) {
                this._executionWaitPeriodText = 'every ' + this._executionWaitPeriodText;
            }
        }

        if (isNullOrUndefined(this._operatesOnText)) {
            switch (this.operatesOn) {
                case ScheduleGranularityEnum.all:
                    this._operatesOnText = 'All Devices';
                    break;
                case ScheduleGranularityEnum.device:
                    if(!isNullOrUndefined(this.devices) && this.devices.length > 0){
                        this._operatesOnText = `Device:${this.devices[0].friendlySerial}`;
                    }
                    else{
                        this._operatesOnText = `Device: Unknown`;
                    }
                    break;
                case ScheduleGranularityEnum.folder:
                    this._operatesOnText = `Folder:${this.folderName}`;
                    break;
            }
        }

        if (isNullOrUndefined(this._lastExecutedText) || DateTimeUtility.isInvalidDate(this._lastExecutedText)) {
            if (isNullOrUndefined(this.lastExecuted)) {
                this._lastExecutedText = 'Never';
            } else if (this.lastExecuted.valueOf() === 0) {
                this._lastExecutedText = 'Never';
            } else {
                this._lastExecutedText = DateTimeUtility.toShortDateTime(this.lastExecuted);
            }
        }

        if (isNullOrUndefined(this._nextExecutionText) || DateTimeUtility.isInvalidDate(this._lastExecutedText)) {
            switch (this.executeOnEvent) {
                case ScheduleEventTypeEnum.unitConnected:
                    this._nextExecutionText = 'Device Connected';
                    break;
                case ScheduleEventTypeEnum.unitDisconnected:
                    this._nextExecutionText = 'Device Disconnected';
                    break;
                case ScheduleEventTypeEnum.unitFlashSettingsChanged:
                    this._nextExecutionText = 'Device Flash Settings Changed';
                    break;
                case ScheduleEventTypeEnum.unitRediscovered:
                    this._nextExecutionText = 'Device Rediscovered';
                    break;
                case ScheduleEventTypeEnum.scheduleCompleted:
                    this._nextExecutionText = `On Schedule Completed: ${isNullOrUndefined(this.parentSchedule) ? '' : this.parentSchedule.description}`;
                    break;
                default:
                    if (isNullOrUndefined(this.nextExecution) || this.nextExecution < new Date() || this.nextExecution.getFullYear() === 9999) {
                        this._nextExecutionText = 'Never';
                    } else {
                        this._nextExecutionText = DateTimeUtility.toRelativeDateTime(this.nextExecution);
                    }
                    break;
            }
        }

        if (isNullOrUndefined(this._endExecutionText) || DateTimeUtility.isInvalidDate(this._lastExecutedText)) {
            this._endExecutionText = DateTimeUtility.toShortDateTime(this.endExecution);
        }

        if ((isNullOrUndefined(this._isFinished) || isNullOrUndefined(this._isActive) || isNullOrUndefined(this._isSystem)) && !isNullOrUndefined(this.nextExecution) && !isNullOrUndefined(this.endExecution)) {
            this._isFinished = this.isUserSchedule === true && (this.nextExecution.getFullYear() > 9000 || this.nextExecution >= this.endExecution) && this.isDisabled === false;
            this._isActive = this.isUserSchedule === true && !this._isFinished && this.isDisabled === false;
            this._isSystem = this.isUserSchedule === false && this.isDisabled === false;
        }
    }

    public get operatesOnText(): string {
        return this._operatesOnText;
    }

    public toRestApiModel(): any {
        throw new Error('Method not implemented.');
    }
}
