import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class ImageSnapShotModel extends BaseModel implements IRestModel {
    public readonly isIRestModel = true;

    public imageData: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ImageSnapShotModel');
    }

    public toRestApiModel(): any {
    }
}
