import { IVideoCroppingWindowModel, VideoCroppingWindowModel } from '@rift/models/restapi/VideoCroppingWindow.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { TargetStatusEnum } from '@shared/enum/TargetStatus.Enum';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface IVideoSettingsModel {
    serialNumber: string;
    unitGen: number;
    videoCropping: IVideoCroppingWindowModel;
    videoOffsets: Array<number>;
}

export class VideoSettingsModel extends BaseModel implements IVideoSettingsModel, IWebSocketModel {

    public status: TargetStatusEnum = null;
    public readonly isIWebSocketModel: boolean = true;
    public serialNumber: string = null;
    public unitGen: number = null;
    public videoCropping: VideoCroppingWindowModel = null;
    public videoOffsets: Array<number> = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any, upperCase?: boolean): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-VideoSettingsModel', null, upperCase);

        this.videoCropping = RestModelUtility.loadFrom(message.VideoCropping, VideoCroppingWindowModel);
        this.videoOffsets = message.VideoOffsets;
    }

    public toWebSocketMessage(): void {
        throw new Error('Method not implemented.');
    }
}
