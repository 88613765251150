import { Component, HostBinding, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '@rift/service/data/global/Global.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { FileUtility } from '@shared/utility/File.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import * as FileSaver from 'file-saver';

export class DeviceValidateErrorDialogData {
    public constructor(public readonly title: string, public readonly hostFriendlySerial: string) {
    }
}

@Component({
    selector: 'rift-validate-counting-network-error-dialog',
    templateUrl: './Error.Dialog.Component.html',
    styleUrls: ['./Error.Dialog.Component.scss'],
})
export class DeviceValidateErrorDialogComponent extends BaseComponent {
    public static className: string = 'ErrorDialogComponent';

    @HostBinding()
    public id: string = 'rift-validate-counting-network-error-dialog';

    public title: string = null;

    public isNullOrUndefined = isNullOrUndefined;
    public getLogFileProcess: ProcessMonitorServiceProcess;

    public constructor(
        @Inject(MAT_DIALOG_DATA) private readonly _data: DeviceValidateErrorDialogData,
        private readonly _dialogRef: MatDialogRef<DeviceValidateErrorDialogComponent>,
        private readonly _dataPollingService: DataPollingService,
        private readonly _globalService: GlobalService,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.getLogFileProcess = this.processMonitorService.getProcess(DeviceValidateErrorDialogComponent.className, 'Getting log file data');

        this.title = this._data.title;
    }

    public onDownloadLogClicked(): void {
        this.addSubscription(this.observableHandlerBase(this._globalService.getLogFile(this.getLogFileProcess), this.getLogFileProcess).subscribe(
            result => {
                const blob = new Blob([result.data], { type: 'text/plain;charset=utf-8' });
                FileSaver.saveAs(blob, FileUtility.sanitize(`${this._data.hostFriendlySerial}_${DateTimeUtility.format(new Date(), 'yyyyMMdd')}.iew`));
                this._dialogRef.close();
            }
        ), this.getLogFileProcess);
    }
}
