import { NgZone, Directive } from '@angular/core';
import { TimeLineRowsSettings } from '@rift/components/shared/timeline/rows/TimeLine.Rows.Settings';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { IRegLineColorSettings, Settings, GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { ColorUtility } from '@shared/utility/Color.Utility';
import { Subject } from 'rxjs';


@Directive()
export class TimeLineRowHeader extends DisplayItem {
    public onCollapseClick: Subject<void> = new Subject<void>();
    public onExpandClick: Subject<void> = new Subject<void>();
    private _background: createjs.Shape = new createjs.Shape();

    private _grabBox: createjs.Shape = new createjs.Shape();
    private _indent: number = 0;
    private _minus: createjs.Shape = new createjs.Shape();
    private _plus: createjs.Shape = new createjs.Shape();
    private _regLineColorSettings: IRegLineColorSettings = null;

    public constructor(
        protected readonly _registerBase: RegisterBaseModel,
        protected readonly _playService: ValidationPlayService,
        protected readonly _zone: NgZone) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            this._regLineColorSettings = Settings.register.colors[this._registerBase.registerIndex];

            this.container.addChild(this._grabBox);
            this.container.addChild(this._background);
            this.container.addChild(this._plus);
            this.container.addChild(this._minus);

            this._plus.visible = false;
            this._minus.visible = true;

            this.addEventHandlers();
        });
    }

    public bringToFront(displayItem?: IDisplayItem): void {

    }
    public get indent(): number {
        return this._indent;
    }

    public set indent(value: number) {
        if (this._indent !== value) {
            this._indent = value;
        }
    }
    public get minusVisible(): boolean {
        return this._minus.visible;
    }

    public set minusVisible(value: boolean) {
        if (this._minus.visible !== value) {
            this._minus.visible = value;
            if (this.plusVisible || this.minusVisible) {
                this._grabBox.cursor = 'pointer';
            } else {
                this._grabBox.cursor = null;
            }
            this.requireStageUpdate.next();
        }
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();
            this._background.uncache();
            this._grabBox.uncache();
            this._minus.uncache();
            this._plus.uncache();
        });
    }
    public get plusVisible(): boolean {
        return this._plus.visible;
    }

    public set plusVisible(value: boolean) {
        if (this._plus.visible !== value) {
            this._plus.visible = value;
            if (this.plusVisible || this.minusVisible) {
                this._grabBox.cursor = 'pointer';
            } else {
                this._grabBox.cursor = null;
            }
            this.requireStageUpdate.next();
        }
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            this.drawGrabBox();
            this.drawBackground();
            this.drawPlus();
            this.drawMinus();
            this.requireStageUpdate.next();
        });
    }
    public get visible(): boolean {
        return this.container.visible;
    }

    public set visible(value: boolean) {
        if (this.container.visible !== value) {
            this.container.visible = value;
        }
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this._grabBox.on('click', this.click.bind(this));
        });
    }

    private click(): void {
        if (this.plusVisible) {
            this.plusVisible = false;
            this.minusVisible = true;
            this.onExpandClick.next();
        } else if (this.minusVisible) {
            this.plusVisible = true;
            this.minusVisible = false;
            this.onCollapseClick.next();
        }
    }

    private drawBackground(): void {
        const graphics = this._background.graphics;
        const startColor = ColorUtility.hexToRGBA(this._regLineColorSettings.fillColor, TimeLineRowsSettings.header.backgroundStartAlpha);
        const endColor = ColorUtility.hexToRGBA(this._regLineColorSettings.fillColor, TimeLineRowsSettings.header.backgroundEndAlpha);

        graphics.clear();
        graphics.beginRadialGradientFill([startColor, endColor], [1, 0], this.width / 2, TimeLineRowsSettings.row.height / 2, 0, this.width / 2, TimeLineRowsSettings.row.height / 2, this.width);
        graphics.drawRoundRect(0, 0, this.width, TimeLineRowsSettings.row.height, 6);
        graphics.endFill();

        this._background.cache(0, 0, this.width, TimeLineRowsSettings.row.height, GLOBAL_CACHE_SCALE);
        this._background.x = this.indent;
    }

    private drawGrabBox(): void {
        const graphics = this._grabBox.graphics;

        graphics.clear();
        graphics.beginFill(ColorUtility.hexToRGBA(Settings.grabBox.fillColor, Settings.grabBox.lineAlpha));
        graphics.rect(0, 0, this.width, TimeLineRowsSettings.row.height);
        graphics.endFill();

        this._grabBox.cache(0, 0, this.width, TimeLineRowsSettings.row.height, GLOBAL_CACHE_SCALE);
    }

    private drawMinus(): void {
        const graphics = this._minus.graphics;
        const circleFill: string = '#000000';
        const circleLine: string = '#000000';
        const circleRadius: number = 6;
        const line: string = '#ffffff';

        graphics.clear();
        graphics.setStrokeStyle(1);

        graphics.beginStroke(circleLine);
        graphics.beginFill(circleFill);

        graphics.drawCircle(circleRadius, circleRadius, circleRadius);

        graphics.setStrokeStyle(2);
        graphics.beginStroke(line);

        const diameter = (2 * circleRadius);

        graphics.moveTo(1, circleRadius);
        graphics.lineTo(diameter - 1, circleRadius);

        graphics.endStroke();

        this._minus.cache(0, 0, 2 * circleRadius, 2 * circleRadius, GLOBAL_CACHE_SCALE);
        this._minus.regX = diameter / 2;
        this._minus.regY = diameter / 2;
        this._minus.x = this.width / 2;
        this._minus.y = TimeLineRowsSettings.row.height / 2;
    }

    private drawPlus(): void {
        const graphics = this._plus.graphics;
        const circleFill: string = '#000000';
        const circleLine: string = '#000000';
        const circleRadius: number = 6;
        const line: string = '#ffffff';

        graphics.clear();
        graphics.setStrokeStyle(1);

        graphics.beginStroke(circleLine);
        graphics.beginFill(circleFill);

        graphics.drawCircle(circleRadius, circleRadius, circleRadius);

        graphics.setStrokeStyle(2);
        graphics.beginStroke(line);

        const diameter = (2 * circleRadius);

        graphics.moveTo(1, circleRadius);
        graphics.lineTo(diameter - 1, circleRadius);

        graphics.moveTo(circleRadius, 1);
        graphics.lineTo(circleRadius, diameter - 1);

        graphics.endStroke();

        this._plus.cache(0, 0, diameter, diameter, GLOBAL_CACHE_SCALE);
        this._plus.regX = diameter / 2;
        this._plus.regY = diameter / 2;
        this._plus.x = this.width / 2;
        this._plus.y = TimeLineRowsSettings.row.height / 2;
    }

    private removeEventHandlers(): void {
        super.onDestroy();
    }

    private get width(): number {
        return TimeLineRowsSettings.header.width - this.indent;
    }
}
