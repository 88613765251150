import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Optional, Output, Self, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, Subject, zip } from 'rxjs';
import { map, startWith, filter } from 'rxjs/operators';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'em-device-autocomplete',
    templateUrl: './DeviceAutocomplete.Component.html',
    styleUrls: ['./DeviceAutocomplete.Component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: DeviceAutocompleteComponent
        }
    ]
})
export class DeviceAutocompleteComponent extends BaseComponent implements MatFormFieldControl<string>, OnInit, ControlValueAccessor, OnDestroy, ILoadDate {
    public static className: string = 'DeviceAutocompleteComponent';

    @Output()
    public serialSelected: EventEmitter<string> = new EventEmitter();

    @Input()
    public formControl: string;

    @Input()
    public id: string;

    @Input()
    public maxListLength: number = 100;

    public autofilled: boolean = false;
    public controlType?: string = 'device-friendlySerialNumber';
    public describedBy: string = '';
    public deviceSerials: Array<string> = [];
    public deviceSerialsChangesProcess: ProcessMonitorServiceProcess;
    public inputValue: string;
    public deviceSerialsCtrl: FormControl;
    public filteredDeviceSerials: Observable<Array<string>>;
    public focusMonitorProcess: ProcessMonitorServiceProcess;
    public focused = false;
    public stateChanges = new Subject<void>();

    private _value: string = null;
    private _disabled = false;
    private _placeholder: string = 'Serial';
    private _required = false;
    private _firstLoad = true;

    public constructor(
        private readonly _dialog: MatDialog,
        @Optional() @Self() public ngControl: NgControl,
        private readonly _focusMonitor: FocusMonitor,
        private readonly _elementRef: ElementRef,
        private readonly _deviceService: DeviceService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.focusMonitorProcess = this.processMonitorService.getProcess(DeviceAutocompleteComponent.className, 'Focus monitor');
        this.deviceSerialsChangesProcess = this.processMonitorService.getProcess(DeviceAutocompleteComponent.className, 'Device serials value changes');
        this.loadDataProcess = this.processMonitorService.getProcess(DeviceAutocompleteComponent.className, this.loadDataProcessText);

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        this.addSubscription(this.observableHandlerBase(this._focusMonitor.monitor(this._elementRef.nativeElement, true), this.focusMonitorProcess).subscribe((origin) => {
            this.focused = !!origin;
            this.stateChanges.next();
        }), this.focusMonitorProcess);

        this.deviceSerialsCtrl = new FormControl();

        this.addSubscription(this.observableHandlerBase(this.deviceSerialsCtrl.valueChanges, this.deviceSerialsChangesProcess).subscribe(value => {
            if (this.isEmptyOrWhiteSpace(value)) {
                this.optionSelected();
            }
        }), this.deviceSerialsChangesProcess);

        this.loadDataStartBase(this);
    }

    @Input()
    public get disabled() {
        return this._disabled;
    }
    public set disabled(value: boolean) {
        this._disabled = value;
        this.stateChanges.next();
    }

    public get empty(): boolean {
        return this.isNullOrUndefined(this.deviceSerialsCtrl.value) || this.isEmptyOrWhiteSpace(this.deviceSerialsCtrl.value);
    }

    public get errorState(): boolean {
        if (!this.isNullOrUndefined(this.ngControl)) {
            return !this.ngControl.valid;
        } else {
            return !this.deviceSerialsCtrl.valid;
        }
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._deviceService.getAllSerials(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.deviceSerials = result.sort((a, b) => {
                            if (a < b) {
                                return -1;
                            } else if (a > b) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });

                        this.filteredDeviceSerials = this.deviceSerialsCtrl.valueChanges
                            .pipe(
                                startWith(''),
                                map(serial => this.filterDeviceSerials(serial))
                            );
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public filterDeviceSerials(value: string): Array<string> {
        if (!this.isNullOrUndefined(value) && !this.isEmptyOrWhiteSpace(value)) {
            const serials: string[] = [];
            const length = this.deviceSerials.length;
            for (let index = 0; index < length; index++) {
                const serial = this.deviceSerials[index];
                if (serial.toLowerCase().indexOf(value.toLowerCase()) === 0) {
                    serials.push(serial);
                }
            }

            if (serials.length === 1) {
                this.optionSelected(serials[0]);
            } else {
                this.optionSelected();
            }

            if (serials.length > this.maxListLength) {
                return serials.splice(0, this.maxListLength);
            } else {
                return serials;
            }
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public propagateChange(event: any): void {

    }

    public onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this._elementRef.nativeElement.querySelector('input').focus();
        }
    }

    public onOptionSelected(event: Event): void {
        const element: HTMLInputElement = event.target as HTMLInputElement;

        this.optionSelected(element.value);
    }

    private optionSelected(serial?: string): void {
        this.value = serial;
        if (!this.isNullOrUndefined(serial)) {
            this.serialSelected.emit(this.value);
        }
        if (!this.isNullOrUndefined(this.propagateChange)) {
            this.propagateChange(this.value);
        }
    }

    public onTouched(event: any): void {

    }

    @Input()
    public get placeholder() {
        return this._placeholder;
    }
    public set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    @Input()
    public get required() {
        return this._required;
    }
    public set required(value: boolean) {
        this._required = value;
        this.stateChanges.next();
    }

    public setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    @Input()
    public get value() {
        return this._value;
    }
    public set value(value: string) {
        this._value = value;
        this.stateChanges.next();
    }

    public writeValue(obj: any): void {
        this.value = obj as string;
        this.deviceSerialsCtrl.setValue(this.value, { emitEvent: false });
        this.optionSelected(this.value);
    }
}
