<div mat-dialog-title>
    <div fxLayout="row"
        fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Register Name
            </div>
        </div>
    </div>
</div>
<form [formGroup]="formGroup">
    <div mat-dialog-content
        fxLayout="column">
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <input type="string"
                    #name
                    matInput
                    formControlName="name"
                    placeholder="Register Name">
                <mat-error>{{getFormControlErrorMessage(formGroup.controls['name'])}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions>
        <button matTooltip="Cancel"
            (click)="onCancelClick()"
            mat-raised-button
            color="primary"
            type="button">Cancel</button>
        <div fxFlex></div>
        <button matTooltip="OK"
            [disabled]="!formGroup.valid"
            (click)="onOkClick()"
            mat-raised-button
            color="primary"
            type="submit">OK</button>
    </div>
</form>