import { NgZone, Directive } from '@angular/core';
import { JointBase } from '@rift/components/shared/viewport/base/JointBase';
import { SegmentBase } from '@rift/components/shared/viewport/base/SegmentBase';
import { ShapeBase } from '@rift/components/shared/viewport/base/ShapeBase';
import { Register } from '@rift/components/shared/viewport/registers/Register';
import { ViewPortHelpers } from '@rift/components/shared/viewport/ViewPort.Helpers';
import { PolygonModel } from '@rift/models/restapi/Polygon.Model';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { Settings, GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { ColorUtility } from '@shared/utility/Color.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ViewPortModeEnum } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { RegisterPortalModel } from '@rift/models/restapi/RegisterPortal.Model';
import { RegisterStaffCallModel } from '@rift/models/restapi/RegisterStaffCall.Model';
import { RegisterStaffAttendModel } from '@rift/models/restapi/RegisterStaffAttend.Model';

@Directive()
export class Polygon extends ShapeBase {
    private _fillShape: createjs.Shape = new createjs.Shape();
    private _label: createjs.Text = null;
    private _labelText: string = '';

    public constructor(
        private readonly _zone: NgZone,
        private readonly _register: Register,
        private readonly _loadQueue: ViewPortLoadQueueService,
        private readonly _polygonModel: PolygonModel) {
        super(_zone, false, 16, 3, _register, _loadQueue, _polygonModel);

        this._zone.runOutsideAngular(() => {
            const shapeSettings = Settings.register.colors[this.register.registerBaseModel.registerIndex];
            const labelFont = 'bold 12px Arial';
            const labelColor = shapeSettings.lineColor;
            this._fillShape.mouseEnabled = false;
            this.container.addChildAt(this._fillShape, 0);

            // Work out what our label should be
            if(_register.registerBaseModel.registerType === RegisterTypeEnum.portal){
                const portalReg = _register.registerBaseModel as RegisterPortalModel;

                if(_polygonModel.iD === portalReg.startPolygon){
                    this._labelText = 'Start';
                }

                if(_polygonModel.iD === portalReg.endPolygon){
                    this._labelText = 'End';
                }
            }
            else if (_register.registerBaseModel.registerType === RegisterTypeEnum.staffCall){
                const staffCallReg = _register.registerBaseModel as RegisterStaffCallModel;

                if(_polygonModel.iD === staffCallReg.taggedPolygon){
                    this._labelText = 'Tagged';
                }

                if(_polygonModel.iD === staffCallReg.untaggedPolygon){
                    this._labelText = 'Un-tagged';
                }
            }
            else if(_register.registerBaseModel.registerType === RegisterTypeEnum.staffAttend){
                const staffAttendReg = _register.registerBaseModel as RegisterStaffAttendModel;

                if(_polygonModel.iD === staffAttendReg.taggedPolygon){
                    this._labelText = 'Tagged';
                }

                if(_polygonModel.iD === staffAttendReg.untaggedPolygon){
                    this._labelText = 'Un-tagged';
                }
            }

            this._label = new createjs.Text(this._labelText, labelFont, labelColor);
            this.container.addChild(this._label);
        });
    }

    public loadPoints(): void {
        this._zone.runOutsideAngular(() => {
            let firstJoint: JointBase = null;
            let lastSegment: SegmentBase = null;
            const length = this.shapeModel.points.length;
            for (let index = 0; index < length; index++) {
                const point = this.shapeModel.points[index];
                const joint = new JointBase(this._zone, this, this._showArrow, this._loadQueue);
                joint.x = point.x;
                joint.y = point.y;
                const segment = new SegmentBase(this._zone, this, this._loadQueue, this.shapeModel);

                if (isNullOrUndefined(firstJoint)) {
                    firstJoint = joint;
                }

                if (!isNullOrUndefined(lastSegment)) {
                    lastSegment.toJoint = joint;
                    joint.fromSegment = lastSegment;
                }

                segment.fromJoint = joint;
                joint.toSegment = segment;

                lastSegment = segment;

                this.addSegment(segment);
                this.addJoint(joint);
            }

            lastSegment.toJoint = firstJoint;
            firstJoint.fromSegment = lastSegment;
        });
    }

    public get polygonModel(): PolygonModel {
        return this._zone.runOutsideAngular(() => this._polygonModel);
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            super.update();
            this.updateFill();
        });
    }

    protected addJointEventHandlers(joint: JointBase): void {
        this._zone.runOutsideAngular(() => {
            this.addSubscription(joint.beforeDrag.subscribe(event => this.onJointBeforeDrag(event)));
            super.addJointEventHandlers(joint);
        });
    }

    protected onJointBeforeDrag(event: DisplayItemMouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                // Create a list of lines based on the positions of the joints
                const eventJoint: JointBase = event.displayItem as JointBase;
                let currentJoint: JointBase = eventJoint;
                let nextJoint: JointBase = eventJoint.toSegment.toJoint;
                const lines = [];

                // Add the first line using the modified point
                lines.push([{ x: event.moveTo.x, y: event.moveTo.y }, { x: nextJoint.x, y: nextJoint.y }]);

                while (nextJoint !== eventJoint) {
                    currentJoint = nextJoint;
                    nextJoint = nextJoint.toSegment.toJoint;

                    lines.push([{ x: currentJoint.x, y: currentJoint.y }, { x: nextJoint.x, y: nextJoint.y }]);
                }

                // modify the last point to be the same as the moved point
                lines[lines.length - 1][1].x = event.moveTo.x;
                lines[lines.length - 1][1].y = event.moveTo.y;

                // Now we've got all our lines we want to make sure none of them cross each other
                const linesLength = lines.length;

                let intersect = false;

                for (let i = 0; i < linesLength; i++) {
                    for (let j = i + 1; j < linesLength; j++) {
                        const line1 = lines[i];
                        const line2 = lines[j];

                        const x1 = line1[0].x;
                        const y1 = line1[0].y;

                        const x2 = line1[1].x;
                        const y2 = line1[1].y;

                        const x3 = line2[0].x;
                        const y3 = line2[0].y;

                        const x4 = line2[1].x;
                        const y4 = line2[1].y;

                        const denominator = (((y4 - y3) * (x2 - x1)) - ((x4 - x3) * (y2 - y1)));

                        if (denominator === 0) {
                            continue;
                        }

                        const numerator1 = (((x4 - x3) * (y1 - y3)) - ((y4 - y3) * (x1 - x3)));
                        const numerator2 = (((x2 - x1) * (y1 - y3)) - ((y2 - y1) * (x1 - x3)));

                        const a = numerator1 / denominator;
                        const b = numerator2 / denominator;

                        if (a > 0 && a < 1 && b > 0 && b < 1) {
                            intersect = true;
                            break;
                        }
                    }

                    if (intersect) {
                        break;
                    }
                }

                if (intersect) {
                    event.preventDefault();
                }
            }
        });
    }

    protected onJointDrag(event: DisplayItemMouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.updateFill();
                super.onJointDrag(event);
            }
        });
    }

    protected onSegmentDrag(event: DisplayItemMouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                super.onSegmentDrag(event);
                this.updateFill();
            }
        });
    }

    protected removeJointEventHandlers(joint: JointBase): void {
        this._zone.runOutsideAngular(() => {
            joint.beforeDrag.unsubscribe();
            super.removeJointEventHandlers(joint);
        });
    }

    private updateFill(): void {
        this._zone.runOutsideAngular(() => {
            const shapeSettings = Settings.register.colors[this.register.registerBaseModel.registerIndex];
            const points = this.getPointModels().map(i => ViewPortHelpers.devicePointToViewPortPoint(i));
            const firstPoint = points[0];
            const xPoints = points.map(i => i.x);
            const yPoints = points.map(i => i.y);
            const xMin = Math.min(...xPoints);
            const xMax = Math.max(...xPoints);
            const yMin = Math.min(...yPoints);
            const yMax = Math.max(...yPoints);
            const width = Math.abs(xMax - xMin);
            const height = Math.abs(yMax - yMin);

            this._fillShape.graphics.clear();
            this._fillShape.graphics.beginFill(ColorUtility.hexToRGBA(shapeSettings.fillColor, 0.2));
            this._fillShape.graphics.moveTo(firstPoint.x, firstPoint.y);

            const pointsLength = points.length;
            for (let i = 1; i < pointsLength; i++) {
                const point = points[i];

                this._fillShape.graphics.lineTo(point.x, point.y);
            }

            this._fillShape.graphics.closePath();

            this._fillShape.cache(xMin, yMin, width, height, GLOBAL_CACHE_SCALE);

            const labelWidth = this._label.getMeasuredWidth();
            const labelHeight = this._label.getMeasuredHeight();

            let centerX = 0;
            let centerY = 0;
            let polygonArea = 0;

            // Find the center of the polygon
            for (let a = 0; a < pointsLength; a++) {
                const pointi = points[a];
                let pointii = null;

                if (a === pointsLength - 1) {
                    pointii = points[0];
                }
                else {
                    pointii = points[a + 1];
                }

                polygonArea += ((pointi.x * pointii.y) - (pointii.x * pointi.y));

                centerX += (pointi.x + pointii.x) * ((pointi.x * pointii.y) - (pointii.x * pointi.y));
                centerY += (pointi.y + pointii.y) * ((pointi.x * pointii.y) - (pointii.x * pointi.y));
            }

            polygonArea /= 2;

            centerX = (1 / (6 * Math.abs(polygonArea))) * centerX;
            centerY = ((1 / (6 * Math.abs(polygonArea))) * centerY) + 10;

            this._label.x = (centerX * -1) - (labelWidth / 2);
            this._label.y = centerY * -1;

            this._label.cache(0, 0, labelWidth, labelHeight, GLOBAL_CACHE_SCALE);

            this.requireStageUpdate.next();
        });
    }
}
