export enum RegisterTypeEnum {
    invalid = 1,
    alternative = 2,
    basicExternal = 3,
    basic = 4,
    beamBreak = 5,
    custom = 6,
    sequential = 7,
    summation = 8,
    tamper = 9,
    heightLine = 10,
    zone = 11,
    zoneOccupancy = 12,
    lineExclusion = 13,
    portal = 14,
    staffCall = 15,
    staffAttend = 16,
    occupancyMaster = 17,
    occupancy = 18,
    occupancyReset = 19,
    occupancyWait = 20,
    queueWaitMaster = 21,
    queueWait = 22,
    smartBoundary = 23,
    zoneWaitTime = 24,
    compareMaster = 25,
    greaterThanCompare = 26,
    lessThanCompare = 27,
    zoneWaitTimeSum = 28,
    pulseGenerator = 29,
    tailgate = 30,
    remoteValue = 31,
    universalTrigger = 32,
    fifoOccupancy = 33,
    fifoOccupancyTimeout = 34,
    fifoOccupancyMaster = 35,
    errorStatus = 36,
    remoteReceiver = 37,
    wirelessPIR = 38
}

export const ALL_REGISTER_TYPES: RegisterTypeEnum[] = [
    RegisterTypeEnum.alternative,
    RegisterTypeEnum.basic,
    RegisterTypeEnum.basicExternal,
    RegisterTypeEnum.beamBreak,
    RegisterTypeEnum.custom,
    RegisterTypeEnum.fifoOccupancy,
    RegisterTypeEnum.fifoOccupancyMaster,
    RegisterTypeEnum.fifoOccupancyTimeout,
    RegisterTypeEnum.greaterThanCompare,
    RegisterTypeEnum.heightLine,
    RegisterTypeEnum.invalid,
    RegisterTypeEnum.lessThanCompare,
    RegisterTypeEnum.lineExclusion,
    RegisterTypeEnum.occupancy,
    RegisterTypeEnum.occupancyMaster,
    RegisterTypeEnum.occupancyReset,
    RegisterTypeEnum.occupancyWait,
    RegisterTypeEnum.portal,
    RegisterTypeEnum.pulseGenerator,
    RegisterTypeEnum.queueWait,
    RegisterTypeEnum.queueWaitMaster,
    RegisterTypeEnum.remoteValue,
    RegisterTypeEnum.sequential,
    RegisterTypeEnum.smartBoundary,
    RegisterTypeEnum.staffCall,
    RegisterTypeEnum.summation,
    RegisterTypeEnum.tailgate,
    RegisterTypeEnum.tamper,
    RegisterTypeEnum.universalTrigger,
    RegisterTypeEnum.zone,
    RegisterTypeEnum.zoneOccupancy,
    RegisterTypeEnum.zoneWaitTime,
    RegisterTypeEnum.zoneWaitTimeSum,
    RegisterTypeEnum.fifoOccupancy,
    RegisterTypeEnum.fifoOccupancyTimeout,
    RegisterTypeEnum.fifoOccupancyMaster,
    RegisterTypeEnum.errorStatus,
    RegisterTypeEnum.remoteReceiver,
    RegisterTypeEnum.wirelessPIR
];

export const ALL_ASSOCIABLE_TYPES: RegisterTypeEnum[] = [
    RegisterTypeEnum.alternative,
    RegisterTypeEnum.basic,
    RegisterTypeEnum.beamBreak,
    RegisterTypeEnum.custom,
    RegisterTypeEnum.fifoOccupancyMaster,
    RegisterTypeEnum.fifoOccupancyTimeout,
    RegisterTypeEnum.greaterThanCompare,
    RegisterTypeEnum.heightLine,
    RegisterTypeEnum.lessThanCompare,
    RegisterTypeEnum.lineExclusion,
    RegisterTypeEnum.occupancyMaster,
    RegisterTypeEnum.occupancyReset,
    RegisterTypeEnum.occupancyWait,
    RegisterTypeEnum.portal,
    RegisterTypeEnum.pulseGenerator,
    RegisterTypeEnum.queueWaitMaster,
    RegisterTypeEnum.remoteValue,
    RegisterTypeEnum.sequential,
    RegisterTypeEnum.smartBoundary,
    RegisterTypeEnum.staffCall,
    RegisterTypeEnum.summation,
    RegisterTypeEnum.tailgate,
    RegisterTypeEnum.tamper,
    RegisterTypeEnum.universalTrigger,
    RegisterTypeEnum.zone,
    RegisterTypeEnum.zoneOccupancy,
    RegisterTypeEnum.zoneWaitTime,
    RegisterTypeEnum.zoneWaitTimeSum,
    RegisterTypeEnum.fifoOccupancyMaster,
    RegisterTypeEnum.fifoOccupancyTimeout,
    RegisterTypeEnum.errorStatus,
    RegisterTypeEnum.remoteReceiver
];

export class RegisterTypeEnumHelpers {
    public static fromRestApi(value: string): RegisterTypeEnum {
        switch (value) {
            case 'invalid':
                return RegisterTypeEnum.invalid;
            case 'alt':
                return RegisterTypeEnum.alternative;
            case 'basic_ext':
                return RegisterTypeEnum.basicExternal;
            case 'basic':
                return RegisterTypeEnum.basic;
            case 'beam_break':
                return RegisterTypeEnum.beamBreak;
            case 'custom':
                return RegisterTypeEnum.custom;
            case 'seq':
                return RegisterTypeEnum.sequential;
            case 'sum':
                return RegisterTypeEnum.summation;
            case 'tamper':
                return RegisterTypeEnum.tamper;
            case 'height_line':
                return RegisterTypeEnum.heightLine;
            case 'zone':
                return RegisterTypeEnum.zone;
            case 'zone_occupancy':
                return RegisterTypeEnum.zoneOccupancy;
            case 'line_exclusion':
                return RegisterTypeEnum.lineExclusion;
            case 'portal':
                return RegisterTypeEnum.portal;
            case 'staff_call':
                return RegisterTypeEnum.staffCall;
            case 'staff_attend':
                return RegisterTypeEnum.staffAttend;
            case 'occupancy_master':
                return RegisterTypeEnum.occupancyMaster;
            case 'occupancy':
                return RegisterTypeEnum.occupancy;
            case 'occupancy_reset':
                return RegisterTypeEnum.occupancyReset;
            case 'occupancy_wait':
                return RegisterTypeEnum.occupancyWait;
            case 'queue_wait_master':
                return RegisterTypeEnum.queueWaitMaster;
            case 'queue_wait':
                return RegisterTypeEnum.queueWait;
            case 'smart_boundary':
                return RegisterTypeEnum.smartBoundary;
            case 'zone_wait_time':
                return RegisterTypeEnum.zoneWaitTime;
            case 'greater_than_compare':
                return RegisterTypeEnum.greaterThanCompare;
            case 'less_than_compare':
                return RegisterTypeEnum.lessThanCompare;
            case 'zone_wait_time_sum':
                return RegisterTypeEnum.zoneWaitTimeSum;
            case 'pulse_generator':
                return RegisterTypeEnum.pulseGenerator;
            case 'tailgate':
                return RegisterTypeEnum.tailgate;
            case 'remote_value':
                return RegisterTypeEnum.remoteValue;
            case 'universal_trigger':
                return RegisterTypeEnum.universalTrigger;
            case 'fifo_occupancy':
                return RegisterTypeEnum.fifoOccupancy;
            case 'fifo_occupancy_timeout':
                return RegisterTypeEnum.fifoOccupancyTimeout;
            case 'fifo_occupancy_master':
                return RegisterTypeEnum.fifoOccupancyMaster;
            case 'error_status':
                return RegisterTypeEnum.errorStatus;
            case 'remote_receiver':
                return RegisterTypeEnum.remoteReceiver;
            case 'wireless_pir':
                return RegisterTypeEnum.wirelessPIR;
            default:
                console.log(`RegisterTypeEnumHelpers: Unknown register type ${value}`);
        }
    }

    public static toRestApi(value: RegisterTypeEnum): string {
        switch (value) {
            case RegisterTypeEnum.invalid:
                return 'invalid';
            case RegisterTypeEnum.alternative:
                return 'alt';
            case RegisterTypeEnum.basicExternal:
                return 'basic_ext';
            case RegisterTypeEnum.basic:
                return 'basic';
            case RegisterTypeEnum.beamBreak:
                return 'beam_break';
            case RegisterTypeEnum.custom:
                return 'custom';
            case RegisterTypeEnum.sequential:
                return 'seq';
            case RegisterTypeEnum.summation:
                return 'sum';
            case RegisterTypeEnum.tamper:
                return 'tamper';
            case RegisterTypeEnum.heightLine:
                return 'height_line';
            case RegisterTypeEnum.zone:
                return 'zone';
            case RegisterTypeEnum.zoneOccupancy:
                return 'zone_occupancy';
            case RegisterTypeEnum.lineExclusion:
                return 'line_exclusion';
            case RegisterTypeEnum.portal:
                return 'portal';
            case RegisterTypeEnum.staffCall:
                return 'staff_call';
            case RegisterTypeEnum.staffAttend:
                return 'staff_attend';
            case RegisterTypeEnum.occupancyMaster:
                return 'occupancy_master';
            case RegisterTypeEnum.occupancy:
                return 'occupancy';
            case RegisterTypeEnum.occupancyReset:
                return 'occupancy_reset';
            case RegisterTypeEnum.occupancyWait:
                return 'occupancy_wait';
            case RegisterTypeEnum.queueWaitMaster:
                return 'queue_wait_master';
            case RegisterTypeEnum.queueWait:
                return 'queue_wait';
            case RegisterTypeEnum.smartBoundary:
                return 'smart_boundary';
            case RegisterTypeEnum.zoneWaitTime:
                return 'zone_wait_time';
            case RegisterTypeEnum.greaterThanCompare:
                return 'greater_than_compare';
            case RegisterTypeEnum.lessThanCompare:
                return 'less_than_compare';
            case RegisterTypeEnum.zoneWaitTimeSum:
                return 'zone_wait_time_sum';
            case RegisterTypeEnum.pulseGenerator:
                return 'pulse_generator';
            case RegisterTypeEnum.tailgate:
                return 'tailgate';
            case RegisterTypeEnum.remoteValue:
                return 'remote_value';
            case RegisterTypeEnum.universalTrigger:
                return 'universal_trigger';
            case RegisterTypeEnum.fifoOccupancy:
                return 'fifo_occupancy';
            case RegisterTypeEnum.fifoOccupancyTimeout:
                return 'fifo_occupancy_timeout';
            case RegisterTypeEnum.fifoOccupancyMaster:
                return 'fifo_occupancy_master';
            case RegisterTypeEnum.errorStatus:
                return 'error_status';
            case RegisterTypeEnum.remoteReceiver:
                return 'remote_receiver';
            case RegisterTypeEnum.wirelessPIR:
                return 'wireless_pir';
            default:
                console.log(`RegisterTypeEnumHelpers: Unknown register type ${value}`);
        }
    }
}
