import { Component, EventEmitter, HostBinding, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
    SettingsCountingBaseComponent,
} from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { AssociatedRegisterViewModel } from '@rift/components/settings/counting/viewmodels/AssociatedRegister.ViewModel';
import { RegisterTypeViewModel } from '@rift/components/settings/counting/viewmodels/RegisterType.ViewModel';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { RegisterSummationModel } from '@rift/models/restapi/RegisterSummation.Model';
import { IAssociatedRegisterConfig } from '@rift/shared/IAssociatedRegisterConfig';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { isFunction } from '@shared/utility/General.Utility';

@Component({
    selector: 'rift-settings-counting-associate-registers',
    templateUrl: './Settings.Counting.AssociateRegisters.Component.html',
    styleUrls: ['./Settings.Counting.AssociateRegisters.Component.scss']
})
export class SettingsCountingAssociateRegistersComponent extends SettingsCountingBaseComponent implements OnChanges {
    public static className: string = 'SettingsCountingAssociateRegistersComponent';

    @HostBinding()
    public id: string = 'rift-settings-counting-associate-registers';

    @Output()
    public selectedChange: EventEmitter<Array<AssociatedRegisterViewModel>> = new EventEmitter<Array<AssociatedRegisterViewModel>>();

    public RegisterTypeEnum = RegisterTypeEnum;
    public availableAssociated: Array<AssociatedRegisterViewModel> = [];
    public selectedCount: number = null;
    public selectedRegisters: Array<RegisterBaseModel> = null;

    @Input()
    public config: IAssociatedRegisterConfig;

    @Input()
    public register: RegisterBaseModel = null;

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
        this.initConnectionState();
    }

    public getSettingsDescription(): string {
        return this.isNullOrUndefined(this.register) ? '' : '';
    }

    public get hasChanges(): boolean {
        if (this.isNullOrUndefined(this.register)) {
            return false;
        } else {
            return this.register.propertyHasChanges('lines') || this.register.propertyHasChanges('associatedRegisterIndex') || this.register.propertyHasChanges('registers');
        }
    }

    public get isValid(): boolean {
        return !this.isNullOrUndefined(this.config) && this.selectedCount >= this.config.associatedMin && this.selectedCount <= this.config.associatedMax;
    }

    public get selected(): Array<AssociatedRegisterViewModel> {
        const items: AssociatedRegisterViewModel[] = [];
        const length = this.availableAssociated.length;
        for (let index = 0; index < length; index++) {
            const item = this.availableAssociated[index];
            if (item.selected === true) {
                items.push(item);
            }
        }

        return items;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.registerTypeViewModel)) {
            const regType: RegisterTypeViewModel = changes.registerTypeViewModel.currentValue;

            if (changes.registerTypeViewModel.previousValue !== changes.registerTypeViewModel.currentValue) {
                const availableAssociatedLength = this.availableAssociated.length;
                for (let index = 0; index < availableAssociatedLength; index++) {
                    const available = this.availableAssociated[index];
                    available.sequentialOrder = null;
                    available.summationAdd = true;
                }
                this.selectedCount = 0;
                this.selectedChange.next(this.selected);
            }

            this.availableAssociated = [];
            if (!this.isNullOrUndefined(this.register)) {
                this.availableAssociated = RegisterBaseUtility.getAvailableAssociatedRegisterVMs(this.config, this.register, this.registers, this.lines);
            }
        }

        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this.register = changes.register.currentValue;
            this.selectedCount = 0;

            const availableAssociatedLength = this.availableAssociated.length;
            const associatedVMs = RegisterBaseUtility.getAssociatedRegisterVMs(this.config, this.register, this.registers, this.lines);
            for (let index = 0; index < availableAssociatedLength; index++) {
                const available = this.availableAssociated[index];
                available.selected = false;
                available.summationAdd = true;
                available.sequentialOrder = null;

                const associatedVM = associatedVMs.find(vm => vm.register.registerIndex === available.register.registerIndex);
                if (!this.isNullOrUndefined(associatedVM)) {
                    available.selected = true;
                    available.summationAdd = associatedVM.summationAdd;
                    available.sequentialOrder = associatedVM.sequentialOrder;
                    this.selectedCount++;
                }
            }

            this.selectedChange.next(this.selected);
        }
    }

    public onAssociateRegisterSelectedChanged(vm: AssociatedRegisterViewModel): void {
        if (!this.isNullOrUndefined(this.register)) {
            const selected = vm.selected;
            const length = this.availableAssociated.length;
            let selectedCount = 0;
            for (let index = 0; index < length; index++) {
                if (this.availableAssociated[index].selected === true) {
                    selectedCount++;
                }
            }

            this.selectedCount = selectedCount;

            if (isFunction(this.config.onRegisterSelected)) {
                this.config.onRegisterSelected(this.register, vm, selected, this.availableAssociated);
            }

            if (isFunction(this.config.onSetRegisterValues)) {
                this.config.onSetRegisterValues(this.config, this.register, this.selected);
            } else {
                this.setRegisterValues();
            }

            this.register.setIndexes();

            this.selectedChange.next(this.selected);
        }
    }

    public onSeqMoveDown(vm: AssociatedRegisterViewModel): void {
        const toOrder = vm.sequentialOrder + 1;

        const availableAssociatedLength = this.availableAssociated.length;
        for (let index = 0; index < availableAssociatedLength; index++) {
            const available = this.availableAssociated[index];
            if (available.sequentialOrder === toOrder) {
                available.sequentialOrder--;
            }
        }

        vm.sequentialOrder = toOrder;

        if (!this.isNullOrUndefined(this.register)) {
            this.setRegisterValues();
        }
    }

    public onSeqMoveUp(vm: AssociatedRegisterViewModel): void {
        const toOrder = vm.sequentialOrder - 1;

        const availableAssociatedLength = this.availableAssociated.length;
        for (let index = 0; index < availableAssociatedLength; index++) {
            const available = this.availableAssociated[index];
            if (available.sequentialOrder === toOrder) {
                available.sequentialOrder++;
            }
        }

        vm.sequentialOrder = toOrder;

        if (!this.isNullOrUndefined(this.register)) {
            this.setRegisterValues();
        }
    }

    public onSummationAddChange(vm: AssociatedRegisterViewModel): void {
        if (!this.isNullOrUndefined(this.register) && this.register instanceof RegisterSummationModel) {
            const register = this.register.registers.find(i => i.register === vm.register.registerIndex);
            if (!this.isNullOrUndefined(register)) {
                register.subtraction = !vm.summationAdd;
            }
        }
    }

    private setRegisterValues(): void {
        const selectedLength = this.selected.length;
        if (this.config.isLinesStore === true) {
            this.register[this.config.linesStorePropertyName] = this.selected.sort((a, b) => a.sequentialOrder - b.sequentialOrder).map(i => i.register.lineIds[0]);
        } else if (this.config.isLineStore === true) {
            if (selectedLength > 0) {
                this.register[this.config.lineStorePropertyName] = this.selected[0].register.lineIds[0];
            } else {
                this.register[this.config.lineStorePropertyName] = [];
            }
        } else if (this.config.isRegistersStore === true) {
            this.register[this.config.registersStorePropertyName] = this.selected.sort((a, b) => a.sequentialOrder - b.sequentialOrder).map(i => i.register.registerIndex);
        } else if (this.config.isRegisterStore === true) {
            if (selectedLength > 0) {
                this.register[this.config.registerStorePropertyName] = this.selected[0].register.registerIndex;
            } else {
                this.register[this.config.registerStorePropertyName] = [];
            }
        }
    }
}
