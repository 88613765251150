import { BreadCrumbIParameterText } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';

/**
 * A set of route parameter matches that can be added
 * dynamacly.
 *
 * @export
 * @class BreadCrumbLookupSet
 */
export class BreadCrumbLookupSet {
    /**
     * The name of the lookup set
     *
     * @type {string}
     * @memberof BreadCrumbLookupSet
     */
    public name: string;
    /**
     * The paramter match sets.
     *
     * @type {Array<BreadCrumbIParameterText>}
     * @memberof BreadCrumbLookupSet
     */
    public lookups: Array<BreadCrumbIParameterText> = [];

    /**
     * Creates an instance of BreadCrumbLookupSet.
     *
     * @param {string} name
     * @memberof BreadCrumbLookupSet
     */
    public constructor(name: string) {
        this.name = name;
    }
}
