import { IMQTTCredentialsModel, MQTTCredentialsModel } from '@rift/models/restapi/IMQTTCredentials.Model';
import { IMQTTTopicConfigModel, MQTTTopicConfigModel } from '@rift/models/restapi/MQTTTopicConfig.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { IRestModel } from '@shared/interface/IRestModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export const MQTT_PUBLISH_CONFIG_EXCLUDE_PROPERTIES = ['registerFilterEnabled'];

export interface IMQTTPublishConfigEntryModel extends IRestModel {
    address: string;
    keepAlive: number;
    maxHistory: number;
    maxSize: number;
    port: number;
    credentialsRequired: boolean;
    credentialsSet: boolean;
    credentials: IMQTTCredentialsModel;
    topics: Array<IMQTTTopicConfigModel>;
    tLSEnabled: boolean;
    tLSInsecure: boolean;
    sSLDir: string;
    registerFilter: Array<string>;
    uniqueIdentifier: string;
}

export class MQTTPublishConfigEntryModel extends BaseModel implements IMQTTPublishConfigEntryModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public address: string = null;
    public keepAlive: number = null;
    public maxHistory: number = null;
    public maxSize: number = null;
    public port: number = null;
    public credentialsRequired: boolean = null;
    public credentialsSet: boolean = null;
    public credentials: MQTTCredentialsModel = null;
    public topics: RestModelChangeTrackerArray<MQTTTopicConfigModel> = new RestModelChangeTrackerArray<MQTTTopicConfigModel>();
    public tLSEnabled: boolean = null;
    public tLSInsecure: boolean = null;
    public sSLDir: string = null;
    public registerFilter: Array<string> = null;
    public uniqueIdentifier: string = null;

    public get registerFilterEnabled(): boolean{
        if(isNullOrUndefined(this.registerFilter) || this.registerFilter.length === 0){
            return false;
        }

        return true;
    }

    public constructor() {
        super();

        this.registerChangeTrackerArray('topics');
        this.registerChangeTrackerModel('credentials');
        this.registerProperty('address');
        this.registerProperty('keepAlive');
        this.registerProperty('maxHistory');
        this.registerProperty('maxSize');
        this.registerProperty('port');
        this.registerProperty('credentialsRequired');
        this.registerProperty('credentialsSet');
        this.registerProperty('tLSEnabled');
        this.registerProperty('tLSInsecure');
        this.registerProperty('registerFilter');
        this.registerProperty('registerFilterEnabled');
        this.registerProperty('uniqueIdentifier');
        this.registerProperty('sSLDir');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-MQTTPublishConfigEntryModel', MQTT_PUBLISH_CONFIG_EXCLUDE_PROPERTIES);

        this.topics = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.Topics, MQTTTopicConfigModel);
        this.credentials = RestModelUtility.loadFrom(restModel.Credentials, MQTTCredentialsModel);

        this.setPropertyOriginalValue('topics', this.topics);
        this.setPropertyOriginalValue('credentials', this.credentials);
        this.setPropertyOriginalValue('address', this.address);
        this.setPropertyOriginalValue('keepAlive', this.keepAlive);
        this.setPropertyOriginalValue('maxHistory', this.maxHistory);
        this.setPropertyOriginalValue('maxSize', this.maxSize);
        this.setPropertyOriginalValue('port', this.port);
        this.setPropertyOriginalValue('credentialsRequired', this.credentialsRequired);
        this.setPropertyOriginalValue('credentialsSet', this.credentialsSet);
        this.setPropertyOriginalValue('tLSEnabled', this.tLSEnabled);
        this.setPropertyOriginalValue('tLSInsecure', this.tLSInsecure);
        this.setPropertyOriginalValue('registerFilter', this.registerFilter.filter(r=>true)); // Make a copy of the array
        this.setPropertyOriginalValue('registerFilterEnabled', this.registerFilterEnabled);
        this.setPropertyOriginalValue('uniqueIdentifier', this.uniqueIdentifier);
        this.setPropertyOriginalValue('sSLDir', this.sSLDir);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, MQTT_PUBLISH_CONFIG_EXCLUDE_PROPERTIES),
            Topics: RestModelUtility.toJsonArray(this.topics),
            Credentials: RestModelUtility.toJson(this.credentials),
            RegisterFilter: isNullOrUndefined(this.registerFilter) ? [] : this.registerFilter
        };
    }
}
