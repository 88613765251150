import { Component, HostListener, Injector, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { CountLogConfigModel } from '@rift/models/restapi/CountLogConfig.Model';
import { CountsService } from '@rift/service/data/counts/Counts.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'rift-settings-countlogging',
    templateUrl: './Settings.CountLogging.Component.html',
    styleUrls: ['./Settings.CountLogging.Component.scss']
})
export class SettingsCountLoggingComponent extends RiftBaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsCountLoggingComponent';

    @HostBinding()
    public id: string = 'rift-settings-countlogging';

    public countLogConfig: CountLogConfigModel;
    public countLoggingFormGroup: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _countsService: CountsService,
        private readonly _dialog: MatDialog,
        private readonly _formBuilder: FormBuilder,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsCountLoggingComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountLoggingComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsCountLoggingComponent.className, this.saveAllChangesProcessText);

        this.addSaveAllAction(this);
        this.countLoggingFormGroup = this._formBuilder.group({
            countLogPeriod: ['', Validators.compose([Validators.required, Validators.min(60), Validators.max(3600), Validators.pattern('[0-9]*')])],
        });
        this.formGroupTracker.track(this.countLoggingFormGroup);

        this.addSubscription(this.observableHandlerBase(this.countLoggingFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesCountLoggingFormGroup()), this.formValuesChangeProcess);

        this.initConnectionState();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._countsService.getConfig(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.countLogConfig = result;
                        this.changeTracker.track(this.countLogConfig);
                        this.setCountLoggingFormGroupValues();
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.countLogConfig.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._countsService.setConfig(this.countLogConfig, process).pipe(
                            map(() => true)
                        );
                    } else {
                        return of(true);
                    }
                })
            )
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(false);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._countsService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
        this.countLogConfig = null;
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.countLoggingFormGroup.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.countLoggingFormGroup.enable();
    }

    private setCountLoggingFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.countLogConfig)) {
            this.countLoggingFormGroup.setValue({
                countLogPeriod: this.countLogConfig.countLogPeriod,
            });
        }
    }

    private updateModelValuesCountLoggingFormGroup(): void {
        if (!this.isNullOrUndefined(this.countLogConfig) && this.isReadOnly === false) {
            const formValues = this.countLoggingFormGroup.value;

            this.countLogConfig.countLogPeriod = formValues.countLogPeriod;

            this.updateSaveAllAction(this);
        }
    }
}
