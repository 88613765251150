<div #mainContent
    id="rift-validation"
    class="rift-validation">
    <mat-toolbar #toolbar
        class="top-bar-content toolbar"
        fxLayout="row">
        <div fxLayout="column"
            fxFlexFill>
            <div fxLayout="row"
                fxLayoutAlign="space-between center">
                <div fxLayout="row"
                    fxLayoutAlign="start center">
                    <div>
                        <button (click)="settingsClick()"
                            class="flat-icon-button"
                            mat-stroked-button
                            matTooltip="Settings">
                            <mat-icon>settings</mat-icon>
                        </button>
                    </div>
                    <div class="date-duration">
                        <table ngClass.gt-xs="table-date"
                            ngClass.lt-sm="table-small">
                            <tr>
                                <td>
                                    Date:
                                </td>
                                <td>
                                    {{ dateText }}
                                </td>
                                <td>
                                    Start:
                                </td>
                                <td>
                                    {{ startText }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Duration:
                                </td>
                                <td>
                                    {{ durationText }}
                                </td>
                                <td>
                                    End:
                                </td>
                                <td>
                                    {{ endText }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div fxLayout="row"
                    fxLayoutAlign="center center"
                    fxShow.gt-sm="true"
                    fxShow.lt-md="false">
                    <div>
                        {{ modeText }}
                    </div>
                </div>
                <div class="controls spacing-left">
                    <button id="showcountlines"
                        (click)="setToggleOption('showcountlines')"
                        #option
                        class="flat-icon-button"
                        mat-stroked-button
                        matTooltip="Visuals">
                        <img [src]="'./assets/icons/line.png'">
                    </button>
                    <button id="showtargets"
                        (click)="setToggleOption('showtargets')"
                        #option
                        class="flat-icon-button"
                        mat-stroked-button
                        matTooltip="Targets">
                        <img [src]="'./assets/icons/target.png'">
                    </button>
                    <button id="showtargetheight"
                        (click)="setToggleOption('showtargetheight')"
                        #option
                        class="flat-icon-button"
                        mat-stroked-button
                        matTooltip="Heights">
                        <img [src]="'./assets/icons/height.png'">
                    </button>
                    <button id="showtargetspaths"
                        (click)="setToggleOption('showtargetspaths')"
                        #option
                        class="flat-icon-button"
                        mat-stroked-button
                        matTooltip="Target Paths">
                        <img [src]="'./assets/icons/target-with-path.png'">
                    </button>
                    <button id="showtargetstails"
                        (click)="setToggleOption('showtargetstails')"
                        #option
                        class="flat-icon-button"
                        mat-stroked-button
                        matTooltip="Target Tails">
                        <img [src]="'./assets/icons/target-with-tail.png'">
                    </button>
                    <button id="showtargetsovervideo"
                        (click)="setToggleOption('showtargetsovervideo')"
                        #option
                        class="flat-icon-button"
                        mat-stroked-button
                        matTooltip="Target Over Video">
                        <img [src]="'./assets/icons/target-over-video.png'">
                    </button>
                    <button id="showsystemcounts"
                        (click)="setToggleOption('showsystemcounts')"
                        #option
                        class="flat-icon-button"
                        mat-stroked-button
                        matTooltip="System Counts">
                        <img [src]="'./assets/icons/system-count.png'">
                    </button>
                    <button id="fieldofview"
                        (click)="setToggleOption('fieldofview')"
                        #option
                        class="flat-icon-button"
                        mat-stroked-button
                        matTooltip="Field Of View">
                        <img [src]="'./assets/icons/field-of-view.png'">
                    </button>
                </div>
            </div>
            <div fxLayout="row"
                fxLayoutAlign="center center"
                fxShow.gt-sm="false"
                fxShow.lt-md="true">
                <div>
                    {{ modeText }}
                </div>
            </div>
        </div>
    </mat-toolbar>
    <div #viewPortContent
        class="view-port-content">
        <div class="right-menu">
            <rift-validation-sessions-menu #sessionsMenu
                *ngIf="mode === ValidationModeEnum.preview || mode === ValidationModeEnum.review"
                (newSession)="newSession()"
                (sessionActionResult)="sessionAction($event)"
                [sessions]="sessions"
                [registers]="registers"
                [lines]="lines"
                [systemCounts]="systemCounts"
                [devices]="devices"
                [timeSetup]="timeSetup"
                [globalData]="globalData"
                [showDurationAsTime]="showDurationAsTime"
                [lineReportBucketSize]="lineReportBucketSize"
                [validationRecording]="validationRecording">
            </rift-validation-sessions-menu>
            <rift-validation-counts-menu #countsMenu
                [showSystemCounts]="showSystemCounts"
                [registers]="registers"
                [validationSession]="validationSession"></rift-validation-counts-menu>
        </div>
        <div class="bottom-left-menu">
            <rift-validation-user-count-menu #userCountMenu
                *ngIf="mode === ValidationModeEnum.validation"
                [disabled]="timeLinePlayPointDragging"
                [keyBindings]="countsKeyBindings"
                [validationSession]="validationSession"
                [registers]="registers"
                [lines]="lines"
                (decrement)="decrementUserCount($event)"
                (increment)="incrementUserCount($event)">
            </rift-validation-user-count-menu>
        </div>
        <div class="top-left-menu">
            <rift-validation-device-selector
                *ngIf="isNullOrUndefined(validatableRecording) === false && isNullOrUndefined(validatableRecording.onNodes) === false && validatableRecording?.onNodes?.length !== 0"
                [validationSession]="validatableRecording"
                [currentVideoDevice]="currentVideoDevice"
                (deviceSelected)="userSelectedVideoDevice($event)">
            </rift-validation-device-selector>
        </div>
        <rift-viewport #viewPort [fovClickEnabled]="true"></rift-viewport>
    </div>
    <div #timeLineContent
        class="time-line-content">
        <rift-timeline #timeLine
            (sessionBookmarkClick)="sessionBookmarkClick($event)"
            (globalBookmarkClick)="globalBookmarkClick($event)"></rift-timeline>
    </div>
    <div #playControlsContent
        class="play-controls-content">

        <div fxLayout="row"
            fxLayoutAlign="space-between center"
            fxShow.gt-md="true"
            fxShow.lt-lg="false">
            <div class="controls-row"
                fxLayout="row"
                fxLayoutAlign="start center">
                <button mat-raised-button
                    color="primary"
                    (click)="backClick()"
                    *ngIf="mode !== ValidationModeEnum.validation">Back</button>
                <button mat-raised-button
                    color="primary"
                    (click)="closeSessionClick()"
                    *ngIf="mode === ValidationModeEnum.validation">Close</button>
            </div>
            <div class="controls-row"
                fxLayout="row"
                fxLayoutAlign="center center">
                <div>Speed</div>
                <mat-slider style="min-width: 200px;" class="spacing-left"
                    min="0.2"
                    max="20"
                    step="0.2"
                    [(ngModel)]="playService.speedScale"
                    thumbLabel="true"></mat-slider>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move to start"
                    (click)="playService.goToStart()">
                    <mat-icon>skip_previous</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move back 5 seconds."
                    (click)="playService.stepBackward(5000)">
                    <mat-icon>fast_rewind</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    [matTooltip]="playService.playState === PlayStateEnum.playing ? 'Pause' : 'Play'"
                    (click)="playService.togglePlayState()">
                    <mat-icon>{{ playService.playState === PlayStateEnum.playing ? 'pause' : 'play_arrow' }}</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move forward 5 seconds."
                    (click)="playService.stepForward(5000)">
                    <mat-icon>fast_forward</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move to end"
                    (click)="playService.goToEnd()">
                    <mat-icon>skip_next</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="accent"
                    matTooltip="Zoom to green timeline markers."
                    (click)="zoomInViewRange()">
                    <mat-icon>zoom_in</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="accent"
                    matTooltip="Zoom out to full timeline view."
                    (click)="zoomOutViewRange()">
                    <mat-icon>zoom_out</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="accent"
                    matTooltip="Add a bookmark."
                    (click)="addBookmark()">
                    <mat-icon>bookmark</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    matTooltip="Center field of view in viewport."
                    (click)="centerViewPort()"
                    color="accent">
                    <mat-icon>filter_center_focus</mat-icon>
                </button>
                <mat-checkbox class="spacing-left"
                    [(ngModel)]="timeLine.stickyZoom">Sticky Zoom</mat-checkbox>
                <mat-checkbox class="spacing-left"
                    [(ngModel)]="autoSpeedEnabled">Auto Speed</mat-checkbox>
                <mat-icon class="auto-play-settings-icon spacing-left"
                    (click)="autoSpeedSettingOpen()">settings</mat-icon>
            </div>
            <div class="controls-row"
                fxLayout="row"
                fxLayoutAlign="end center">
                <div class="error error-message">{{ newSessionErrorMessage }}</div>
                <button mat-raised-button
                    color="primary"
                    *ngIf="mode === ValidationModeEnum.createSession"
                    [disabled]="!newSessionValid"
                    (click)="nextClick()">Next</button>
                <button mat-raised-button
                    color="primary"
                    *ngIf="mode === ValidationModeEnum.validation"
                    (click)="completeClick()">Complete</button>
                <button mat-raised-button
                    color="primary"
                    *ngIf="mode === ValidationModeEnum.validation"
                    (click)="rejectClick()">Reject</button>
            </div>
        </div>

        <div fxLayout="column"
            fxShow.gt-md="false"
            fxShow.lt-lg="true">
            <!-- Play Speed Settings -->
            <div class="controls-row"
                fxShow.gt-sm="true"
                fxShow.lt-md="false"
                fxLayout="row"
                fxLayoutAlign="center center">
                <div>Speed</div>
                <mat-slider class="spacing-left"
                    min="0.15"
                    max="20"
                    step="0.05"
                    [(ngModel)]="playService.speedScale"
                    thumbLabel="true"></mat-slider>

                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move to start"
                    (click)="playService.goToStart()">
                    <mat-icon>skip_previous</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move back 5 seconds."
                    (click)="playService.stepBackward(5000)">
                    <mat-icon>fast_rewind</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    [matTooltip]="playService.playState === PlayStateEnum.playing ? 'Pause' : 'Play'"
                    (click)="playService.togglePlayState()">
                    <mat-icon>{{ playService.playState === PlayStateEnum.playing ? 'pause' : 'play_arrow' }}</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move forward 5 seconds."
                    (click)="playService.stepForward(5000)">
                    <mat-icon>fast_forward</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move to end"
                    (click)="playService.goToEnd()">
                    <mat-icon>skip_next</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="accent"
                    matTooltip="Zoom to green timeline markers."
                    (click)="zoomInViewRange()">
                    <mat-icon>zoom_in</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="accent"
                    matTooltip="Zoom out to full timeline view."
                    (click)="zoomOutViewRange()">
                    <mat-icon>zoom_out</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="accent"
                    matTooltip="Add a bookmark."
                    (click)="addBookmark()">
                    <mat-icon>bookmark</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    matTooltip="Center field of view in viewport."
                    (click)="centerViewPort()"
                    color="accent">
                    <mat-icon>filter_center_focus</mat-icon>
                </button>

                <mat-checkbox class="spacing-left"
                    [(ngModel)]="timeLine.stickyZoom">Sticky Zoom</mat-checkbox>
                <mat-checkbox class="spacing-left"
                    [(ngModel)]="autoSpeedEnabled">Auto Speed</mat-checkbox>
                <mat-icon class="auto-play-settings-icon spacing-left"
                    (click)="autoSpeedSettingOpen()">settings</mat-icon>
            </div>

            <!-- Play -->
            <div class="controls-row"
                fxShow.gt-sm="false"
                fxShow.lt-md="true"
                fxShow.gt-xs="false"
                fxShow.lt-sm="false"
                fxLayout="row"
                fxLayoutAlign="center center">
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move to start"
                    (click)="playService.goToStart()">
                    <mat-icon>skip_previous</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move back 5 seconds."
                    (click)="playService.stepBackward(5000)">
                    <mat-icon>fast_rewind</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    [matTooltip]="playService.playState === PlayStateEnum.playing ? 'Pause' : 'Play'"
                    (click)="playService.togglePlayState()">
                    <mat-icon>{{ playService.playState === PlayStateEnum.playing ? 'pause' : 'play_arrow' }}</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move forward 5 seconds."
                    (click)="playService.stepForward(5000)">
                    <mat-icon>fast_forward</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move to end"
                    (click)="playService.goToEnd()">
                    <mat-icon>skip_next</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="accent"
                    matTooltip="Zoom to green timeline markers."
                    (click)="zoomInViewRange()">
                    <mat-icon>zoom_in</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="accent"
                    matTooltip="Zoom out to full timeline view."
                    (click)="zoomOutViewRange()">
                    <mat-icon>zoom_out</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="accent"
                    matTooltip="Add a bookmark."
                    (click)="addBookmark()">
                    <mat-icon>bookmark</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    matTooltip="Center field of view in viewport."
                    (click)="centerViewPort()"
                    color="accent">
                    <mat-icon>filter_center_focus</mat-icon>
                </button>
            </div>

            <!-- Play - Mobile -->
            <div class="controls-row"
                fxShow.gt-sm="false"
                fxShow.lt-md="false"
                fxShow.gt-xs="false"
                fxShow.lt-sm="true"
                fxLayout="row"
                fxLayoutAlign="center center">
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move to start"
                    (click)="playService.goToStart()">
                    <mat-icon>skip_previous</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move back 5 seconds."
                    (click)="playService.stepBackward(5000)">
                    <mat-icon>fast_rewind</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    [matTooltip]="playService.playState === PlayStateEnum.playing ? 'Pause' : 'Play'"
                    (click)="playService.togglePlayState()">
                    <mat-icon>{{ playService.playState === PlayStateEnum.playing ? 'pause' : 'play_arrow' }}</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move forward 5 seconds."
                    (click)="playService.stepForward(5000)">
                    <mat-icon>fast_forward</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="primary"
                    matTooltip="Move to end"
                    (click)="playService.goToEnd()">
                    <mat-icon>skip_next</mat-icon>
                </button>
            </div>

            <div class="controls-row"
                fxShow.gt-sm="false"
                fxShow.lt-md="false"
                fxShow.gt-xs="false"
                fxShow.lt-sm="true"
                fxLayout="row"
                fxLayoutAlign="center center">
                <button class="spacing-left"
                    mat-mini-fab
                    color="accent"
                    matTooltip="Zoom to green timeline markers."
                    (click)="zoomInViewRange()">
                    <mat-icon>zoom_in</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="accent"
                    matTooltip="Zoom out to full timeline view."
                    (click)="zoomOutViewRange()">
                    <mat-icon>zoom_out</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    color="accent"
                    matTooltip="Add a bookmark."
                    (click)="addBookmark()">
                    <mat-icon>bookmark</mat-icon>
                </button>
                <button class="spacing-left"
                    mat-mini-fab
                    matTooltip="Center field of view in viewport."
                    (click)="centerViewPort()"
                    color="accent">
                    <mat-icon>filter_center_focus</mat-icon>
                </button>
            </div>

            <!-- Speed Settings -->
            <div class="controls-row"
                fxShow.gt-sm="false"
                fxShow.lt-md="true"
                fxLayout="row"
                fxLayoutAlign="center center">
                <div>Speed</div>
                <mat-slider class="spacing-left"
                    min="0.15"
                    max="20"
                    step="0.05"
                    [(ngModel)]="playService.speedScale"
                    thumbLabel="true"></mat-slider>
                <mat-checkbox class="spacing-left" (change)="onStickyZoomChange($event)"
                    [(ngModel)]="timeLine.stickyZoom">Sticky Zoom</mat-checkbox>
                <mat-checkbox class="spacing-left"
                    [(ngModel)]="autoSpeedEnabled">Auto Speed</mat-checkbox>
                <mat-icon class="auto-play-settings-icon spacing-left"
                    (click)="autoSpeedSettingOpen()">settings</mat-icon>
            </div>

            <!-- Navigation -->
            <div class="controls-row"
                fxLayout="row"
                fxLayoutAlign="start center">
                <button mat-raised-button
                    color="primary"
                    (click)="backClick()"
                    *ngIf="mode !== ValidationModeEnum.validation">Back</button>
                <button mat-raised-button
                    color="primary"
                    (click)="closeSessionClick()"
                    *ngIf="mode === ValidationModeEnum.validation">Close</button>
                <div fxFlex></div>
                <div class="error error-message">{{ newSessionErrorMessage }}</div>
                <button mat-raised-button
                    color="primary"
                    *ngIf="mode === ValidationModeEnum.createSession"
                    [disabled]="!newSessionValid"
                    (click)="nextClick()">Next</button>
                <button mat-raised-button
                    color="primary"
                    *ngIf="mode === ValidationModeEnum.validation"
                    (click)="completeClick()">Complete</button>
                <button mat-raised-button
                    color="primary"
                    *ngIf="mode === ValidationModeEnum.validation"
                    (click)="rejectClick()">Reject</button>
            </div>
        </div>
    </div>
</div>
