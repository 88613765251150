/**
 * The workflow operation type.
 *
 * @export
 * @enum {number}
 */
export enum WorkflowOperationTypeEnum {
    /**
     * This workflow requires units.
     */
    devices = 0,

    /**
     * This workflow only operates on the database.
     */
    database = 1,
}
