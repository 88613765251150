import { Routes } from '@angular/router';
import { DashboardComponent } from '@em/components/dashboard/Dashboard.Component';
import { DashboardDefaultComponent } from '@em/components/dashboard/default/Dashboard.Default.Component';
import { DashboardDeviceHealthComponent } from '@em/components/dashboard/devicehealth/Dashboard.DeviceHealth.Component';
import { DashboardRecordingsComponent } from '@em/components/dashboard/recordings/Dashboard.Recordings.Component';
import { IRouteData } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { UserIsAuthenticated } from '@em/service/user/UserIsLevel';

export const EmDashboardRoutes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [UserIsAuthenticated],
        data: { breadcrumb: { name: 'dashboard', text: 'Dashboard' } } as IRouteData,
        children: [
            {
                path: '',
                canActivate: [UserIsAuthenticated],
                component: DashboardDefaultComponent,
            },
            {
                path: 'devicehealth/:level',
                canActivate: [UserIsAuthenticated],
                component: DashboardDeviceHealthComponent,
                data: {
                    breadcrumb: {
                        name: 'em-dashboard-devicehealth',
                        text: [
                            { name: 'em-dashboard-devicehealth-green', level: '1', text: 'Devices At Level Green' },
                            { name: 'em-dashboard-devicehealth-orange', level: '2', text: 'Devices At Level Orange' },
                            { name: 'em-dashboard-devicehealth-red', level: '3', text: 'Devices At Level Red' }
                        ]
                    }
                } as IRouteData,
            },
            {
                path: 'recordings',
                canActivate: [UserIsAuthenticated],
                component: DashboardRecordingsComponent,
                data: { breadcrumb: { name: 'em-dashboard-recordings', text: 'Recordings' } } as IRouteData,
            }
        ]
    },
];
