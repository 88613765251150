import { NgZone } from '@angular/core';
import { SegmentBase } from '@rift/components/shared/viewport/base/SegmentBase';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { DisplayItemCollection } from '@shared/generic/canvas/DisplayItemCollection';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class SegmentCollectionBase extends DisplayItemCollection<SegmentBase> {
    public constructor(
        protected readonly _zone: NgZone,
        protected readonly _loadQueueBase: ViewPortLoadQueueService) {
        super(_zone);
        Object.setPrototypeOf(this, Object.create(SegmentCollectionBase.prototype));
    }

    public bringToFront(displayItem: SegmentBase): void {
        this._zone.runOutsideAngular(() => {
            this.container.setChildIndex(displayItem.container, this.container.children.length - 1);
            this.requireStageUpdate.next();
        });
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
        });
    }

    public push(...segments: Array<SegmentBase>): number {
        return this._zone.runOutsideAngular(() => {
            const length = segments.length;
            for (let index = 0; index < length; index++) {
                const segment = segments[index];
                this.initItem(segment);
                segment.update();
                super.push(segment);
            }

            return this.length;
        });
    }

    public remove(segment: SegmentBase): void {
        this._zone.runOutsideAngular(() => {
            const index = this.findIndex(i => i.uniqueId === segment.uniqueId);
            if (index !== -1) {
                this.splice(index, 1);
            }
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            const length = this.length;
            for (let index = 0; index < length; index++) {
                const segment = this[index];
                segment.update();
                if (!isNullOrUndefined(segment.addPoint)) {
                    segment.addPoint.update();
                }
            }
        });
    }

    protected addEventHandlers(segment: SegmentBase): void {
        this._zone.runOutsideAngular(() => {
            segment.requireStageUpdate.subscribe(() => this.requireStageUpdate.next());
            super.addEventHandlers(segment);
        });
    }

    protected removeEventHandlers(segment: SegmentBase): void {
        this._zone.runOutsideAngular(() => {
            super.removeEventHandlers(segment);
        });
    }
}
