import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class ProfileResponseModel extends BaseModel implements IRestModel {

    public attemptAverageMs: number = null;
    public attemptCount: number = null;
    public attemptMaxMs: number = null;
    public attemptMinMs: number = null;
    public cachedFailureCount: number = null;
    public failedAverageMs: number = null;
    public failedCount: number = null;
    public failedMaxMs: number = null;
    public failedMinMs: number = null;
    public readonly isIRestModel = true;
    public retryCount: number = null;
    public successAverageMs: number = null;
    public successCount: number = null;
    public successMaxMs: number = null;
    public successMinMs: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ProfileResponseModel');
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
