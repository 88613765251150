import { Injectable } from '@angular/core';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { TaskModel } from '@rift/models/restapi/Task.Model';
import { TaskCollectionModel } from '@rift/models/restapi/TaskCollection.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiTaskLauncherService } from '@rift/service/restapi/v1/RestApi.TaskLauncher.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class TaskLauncherService extends RiftBaseService {

    private _addTasksLoadingTracker = new ObservableTracker<ResultModel>();
    private _deleteTaskLoadingTracker = new ObservableTracker<ResultModel>();
    private _getTasksLoadingTracker = new ObservableTracker<TaskCollectionModel>();
    private _tasksCache: TaskCollectionModel;

    public constructor(
        private readonly _restApiTaskLauncherService: RestApiTaskLauncherService) {
        super();
    }
    public addTasks(tasks: Array<TaskModel>, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._addTasksLoadingTracker
            .getLoading(tasks)
            .observable(this._restApiTaskLauncherService.addTasks(tasks, process).pipe(tap(() => this._tasksCache = null)));
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._tasksCache = null;
    }
    public getTasks(process?: ProcessMonitorServiceProcess): Observable<TaskCollectionModel> {
        if (isNullOrUndefined(this._tasksCache)) {
            return this._getTasksLoadingTracker
                .getLoading()
                .observable(this._restApiTaskLauncherService.getTasks(process).pipe(
                    map(result => {
                        this._tasksCache = result;
                        return this._tasksCache;
                    })
                ));
        } else {
            return of(this._tasksCache);
        }
    }

    public deleteTask(task: TaskModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel>{
        return this._deleteTaskLoadingTracker
                .getLoading(task)
                .observable(this._restApiTaskLauncherService.deleteTask(task, process).pipe(tap(() => this._tasksCache = null)));
    }
}
