<div class="em-navmenu">
    <mat-list fxLayout="column">
        <!-- Search -->
        <mat-list-item class="item-nohover">
            <mat-icon mat-list-icon
                class="icon">search</mat-icon>
            <mat-form-field placeholder="Search"
                fxFlex>
                <em-search></em-search>
            </mat-form-field>
        </mat-list-item>
        <mat-divider></mat-divider>
        <em-navmenu-expand-item fxFlex="none">
            <ng-template #expandItemHeaderCollapsed>
                <mat-icon class="quick-icon"
                    matTooltip="My Account"
                    routerLink="/myaccount">person</mat-icon>
                <mat-icon class="quick-icon"
                    matTooltip="Settings"
                    routerLink="/settings">settings</mat-icon>
                <mat-icon class="quick-icon"
                    matTooltip="Recordings"
                    routerLink="/dashboard/recordings">videocam
                </mat-icon>
            </ng-template>
            <ng-template #expandItemHeaderExpanded>
                Hide Links
            </ng-template>
            <ng-template #expandItemContent>
                <mat-list-item class="item"
                    routerLink="/myaccount">
                    <mat-icon mat-list-icon
                        class="icon">person</mat-icon>
                    <a matLine
                        class="text">My Account</a>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item class="item"
                    routerLink="/settings">
                    <mat-icon mat-list-icon
                        class="icon">settings</mat-icon>
                    <a matLine
                        class="text">Settings</a>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item class="item"
                    routerLink="/dashboard/recordings">
                    <mat-icon mat-list-icon
                        class="icon">videocam</mat-icon>
                    <a matLine
                        class="text">Recordings</a>
                </mat-list-item>
            </ng-template>
        </em-navmenu-expand-item>
        <mat-divider></mat-divider>
        <!-- Favourite devices -->
        <em-navmenu-favourite-devices>
        </em-navmenu-favourite-devices>
        <mat-divider></mat-divider>
        <!-- Address bar -->
        <mat-list-item class="item-addressbook" style="overflow: hidden">
            <mat-icon mat-list-icon
                class="icon">import_contacts</mat-icon>
            <a matLine
                class="text">Address Book</a>
            <mat-icon class="icon"
                style="cursor: pointer;"
                routerLink="/settings/addressbook"
                *ngIf="(userIsAdmin | async) === true">edit</mat-icon>
        </mat-list-item>
        <!-- Address Book -->
        <mat-list-item
            class="item-addressbook scroll1"
            fxFlex="100"
            style="max-height: 65vh;">
            <em-address-book-tree [root]="rootTreeGroup"
                (itemClicked)="addressBookItemClicked($event)">
            </em-address-book-tree>
        </mat-list-item>
        <!-- Quick links -->
        <mat-divider></mat-divider>
        <em-navmenu-expand-item fxFlex="none">
            <ng-template #expandItemHeaderCollapsed>
                <shr-fa matTooltip="New Devices"
                    routerLink="/devicegroup/new"
                    class="quick-icon"
                    name="plus">plus
                </shr-fa>
                <span class="quick-count">({{isNullOrUndefined(quickLinkMetrics) ? 0 : quickLinkMetrics.newDevices | largeNumber: 1}})</span>

                <shr-fa matTooltip="Devices In Error"
                    routerLink="/devicegrouperrorwarning/inerror"
                    class="quick-icon"
                    name="exclamation-circle">
                    exclamation-circle</shr-fa>
                <span class="quick-count">({{isNullOrUndefined(quickLinkMetrics) ? 0 : quickLinkMetrics.devicesInError | largeNumber: 1}})</span>

                <shr-fa matTooltip="Devices In Warning"
                    routerLink="/devicegrouperrorwarning/inwarning"
                    class="quick-icon"
                    name="exclamation-triangle">
                    exclamation-triangle
                </shr-fa>
                <span class="quick-count">({{isNullOrUndefined(quickLinkMetrics) ? 0 : quickLinkMetrics.devicesInWarning | largeNumber: 1}})</span>

                <shr-fa matTooltip="Connected Devices"
                    routerLink="/devicegroup/connected"
                    class="quick-icon"
                    name="link">
                    link</shr-fa>
                <span class="quick-count">({{isNullOrUndefined(quickLinkMetrics) ? 0 : quickLinkMetrics.connectedDevices | largeNumber: 1}})</span>
            </ng-template>
            <ng-template #expandItemHeaderExpanded>
                Hide Device Statuses
            </ng-template>
            <ng-template #expandItemContent>
                <ng-template [ngIf]="quickLinkMetrics?.unlicensedDevices > 0">
                    <mat-list-item class="item"
                        routerLink="/devicegroup/unlicensed">
                        <shr-fa mat-list-icon
                            class="icon"
                            name="ban">ban</shr-fa>
                        <a matLine
                            class="text">Unlicensed Devices
                            {{ isNullOrUndefined(quickLinkMetrics) ? 0 : quickLinkMetrics.unlicensedDevices | largeNumber: 1 }}</a>
                        <shr-background-load-spinner [trigger]="quickLinksProcess.isRunning"
                            class="change-led"></shr-background-load-spinner>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-template>
                <ng-template [ngIf]="quickLinkMetrics?.newDevices > 0">
                    <mat-list-item class="item"
                        routerLink="/devicegroup/new">
                        <shr-fa mat-list-icon
                            class="icon"
                            name="plus">plus</shr-fa>
                        <a matLine
                            class="text">New Devices {{ isNullOrUndefined(quickLinkMetrics) ? 0 : quickLinkMetrics.newDevices | largeNumber: 1 }}</a>
                        <shr-background-load-spinner [trigger]="quickLinksProcess.isRunning"
                            class="change-led"></shr-background-load-spinner>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-template>
                <ng-template [ngIf]="quickLinkMetrics?.devicesInError > 0">
                    <mat-list-item class="item"
                        routerLink="/devicegrouperrorwarning/inerror">
                        <shr-fa mat-list-icon
                            class="icon"
                            name="exclamation-circle">exclamation-circle</shr-fa>
                        <a matLine
                            class="text">Devices In Error
                            {{ isNullOrUndefined(quickLinkMetrics) ? 0 : quickLinkMetrics.devicesInError | largeNumber: 1 }}</a>
                        <shr-background-load-spinner [trigger]="quickLinksProcess.isRunning"
                            class="change-led"></shr-background-load-spinner>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-template>
                <ng-template [ngIf]="quickLinkMetrics?.devicesInWarning > 0">
                    <mat-list-item class="item"
                        routerLink="/devicegrouperrorwarning/inwarning">
                        <shr-fa mat-list-icon
                            class="icon"
                            name="exclamation-triangle">exclamation-triangle</shr-fa>
                        <a matLine
                            class="text">Devices In Warning
                            {{ isNullOrUndefined(quickLinkMetrics) ? 0 : quickLinkMetrics.devicesInWarning | largeNumber: 1 }}</a>
                        <shr-background-load-spinner [trigger]="quickLinksProcess.isRunning"
                            class="change-led"></shr-background-load-spinner>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-template>
                <ng-template [ngIf]="quickLinkMetrics?.connectedDevices > 0">
                    <mat-list-item class="item"
                        routerLink="/devicegroup/connected">
                        <shr-fa mat-list-icon
                            class="icon"
                            name="link">link</shr-fa>
                        <a matLine
                            class="text">Connected Devices
                            {{ isNullOrUndefined(quickLinkMetrics) ? 0 : quickLinkMetrics.connectedDevices | largeNumber: 1 }}</a>
                        <shr-background-load-spinner [trigger]="quickLinksProcess.isRunning"
                            class="change-led"></shr-background-load-spinner>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-template>
                <ng-template [ngIf]="quickLinkMetrics?.disconnectedDevices > 0">
                    <mat-list-item class="item"
                        routerLink="/devicegroup/disconnected">
                        <shr-fa mat-list-icon
                            class="icon"
                            name="unlink">unlink</shr-fa>
                        <a matLine
                            class="text">Disconnected Devices
                            {{ isNullOrUndefined(quickLinkMetrics) ? 0 : quickLinkMetrics.disconnectedDevices | largeNumber: 1 }}</a>
                        <shr-background-load-spinner [trigger]="quickLinksProcess.isRunning"
                            class="change-led"></shr-background-load-spinner>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-template>
                <ng-template [ngIf]="quickLinkMetrics?.orphanedDevices > 0">
                    <mat-list-item class="item"
                        routerLink="/devicegroup/orphaned">
                        <shr-fa mat-list-icon
                            class="icon"
                            name="street-view">street-view</shr-fa>
                        <a matLine
                            class="text">Orphaned Devices
                            {{ isNullOrUndefined(quickLinkMetrics) ? 0 : quickLinkMetrics.orphanedDevices | largeNumber: 1 }}</a>
                        <shr-background-load-spinner [trigger]="quickLinksProcess.isRunning"
                            class="change-led"></shr-background-load-spinner>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-template>
            </ng-template>
        </em-navmenu-expand-item>
    </mat-list>
</div>