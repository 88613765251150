<div class="em-settings-options-dataculling"
    fxLayout="column">
    <mat-card class="header">
        <mat-card-title>
            <span class="mat-title">Data Culling</span>
        </mat-card-title>
        <mat-card-subtitle>
            <span class="mat-subheading-1">Estate Manager is a real time system that logs a large amount of data. After a while this data is no longer
                relevant
                or needed. This page allows you to configure how long to keep that data. Removing old, redundant data can
                help keep the database size down as well as keep the system performing well.</span>
        </mat-card-subtitle>
    </mat-card>
    <form [formGroup]="dataCullingFormGroup">
        <div class="options-row"
            fxLayout.gt-sm="row"
            fxLayout.lt-md="column">
            <mat-card fxFlex
                ngClass.gt-sm="option-card spacing-right"
                ngClass.lt-md="option-card spacing-bottom">
                <mat-card-title>
                    <span class="mat-title">Schedule Result Logs</span>
                </mat-card-title>
                <mat-card-subtitle>
                    <span class="mat-subheading-1">Schedule Result Logs contain the status of every schedule ran.</span>
                </mat-card-subtitle>
                <mat-card-content>
                    <mat-form-field>
                        <mat-select #scheduleResultsCullingPeriod
                            formControlName="scheduleResultsCullingPeriod"
                            placeholder="Keep For">
                            <mat-option *ngFor="let option of scheduleResultsCullingPeriods | orderBy: 'text'"
                                [value]="option.value">
                                {{ option.text }}
                            </mat-option>
                        </mat-select>
                        <span matSuffix>{{ settingScheduleResultsCullingPeriod?.propertyHasChangesText('value') }}</span>
                        <mat-error>{{getFormControlErrorMessage(dataCullingFormGroup.controls['scheduleResultsCullingPeriod'])}}</mat-error>
                    </mat-form-field>
                </mat-card-content>
            </mat-card>
            <mat-card fxFlex
                ngClass.gt-sm="option-card spacing-right"
                ngClass.lt-md="option-card spacing-bottom">
                <mat-card-title>
                    <span class="mat-title">Count Logs</span>
                </mat-card-title>
                <mat-card-subtitle>
                    <span class="mat-subheading-1">Count Logs contain the count information retrieved from counters.</span>
                </mat-card-subtitle>
                <mat-card-content>
                    <mat-form-field>
                        <mat-select #countLogsCullingPeriod
                            formControlName="countLogsCullingPeriod"
                            placeholder="Keep For">
                            <mat-option *ngFor="let option of countLogsCullingPeriods | orderBy: 'text'"
                                [value]="option.value">
                                {{ option.text }}
                            </mat-option>
                        </mat-select>
                        <span matSuffix>{{ settingCountLogsCullingPeriod?.propertyHasChangesText('value') }}</span>
                        <mat-error>{{getFormControlErrorMessage(dataCullingFormGroup.controls['countLogsCullingPeriod'])}}</mat-error>
                    </mat-form-field>
                </mat-card-content>
            </mat-card>
            <mat-card fxFlex
                class="option-card">
                <mat-card-title>
                    <span class="mat-title">Histogram Logs</span>
                </mat-card-title>
                <mat-card-subtitle>
                    <span class="mat-subheading-1">Histogram Logs contain the height information retrieved from counters.</span>
                </mat-card-subtitle>
                <mat-card-content>
                    <mat-form-field>
                        <mat-select #histogramLogsCullingPeriod
                            formControlName="histogramLogsCullingPeriod"
                            placeholder="Keep For">
                            <mat-option *ngFor="let option of histogramLogsCullingPeriods | orderBy: 'text'"
                                [value]="option.value">
                                {{ option.text }}
                            </mat-option>
                        </mat-select>
                        <span matSuffix>{{ settingHistogramLogsCullingPeriod?.propertyHasChangesText('value') }}</span>
                        <mat-error>{{getFormControlErrorMessage(dataCullingFormGroup.controls['histogramLogsCullingPeriod'])}}</mat-error>
                    </mat-form-field>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="options-row"
            fxLayout.gt-sm="row"
            fxLayout.lt-md="column">
            <mat-card fxFlex
                ngClass.gt-sm="option-card spacing-right"
                ngClass.lt-md="option-card spacing-bottom">
                <mat-card-title>
                    <span class="mat-title">Bluetooth Logs</span>
                </mat-card-title>
                <mat-card-subtitle>
                    <span class="mat-subheading-1">Bluetooth Logs contain the hashed bluetooth information retrieved from counters.</span>
                </mat-card-subtitle>
                <mat-card-content>
                    <mat-form-field>
                        <mat-select #bluetoothLogsCullingPeriod
                            formControlName="bluetoothLogsCullingPeriod"
                            placeholder="Keep For">
                            <mat-option *ngFor="let option of bluetoothLogsCullingPeriods | orderBy: 'text'"
                                [value]="option.value">
                                {{ option.text }}
                            </mat-option>
                        </mat-select>
                        <span matSuffix>{{ settingBluetoothLogsCullingPeriod?.propertyHasChangesText('value') }}</span>
                        <mat-error>{{getFormControlErrorMessage(dataCullingFormGroup.controls['bluetoothLogsCullingPeriod'])}}</mat-error>
                    </mat-form-field>
                </mat-card-content>
            </mat-card>
            <mat-card fxFlex
                ngClass.gt-sm="option-card spacing-right"
                ngClass.lt-md="option-card spacing-bottom">
                <mat-card-title>
                    <span class="mat-title">Synchronized Videos</span>
                </mat-card-title>
                <mat-card-subtitle>
                    <span class="mat-subheading-1">Synchronized videos are stored at the video storage location.</span>
                </mat-card-subtitle>
                <mat-card-content fxLayout="column">
                    <div fxLayout="row">
                        <mat-form-field fxFlex="50"
                            class="spacing-right">
                            <mat-select #synchronizedVideosWhenOption
                                formControlName="synchronizedVideosWhenOption"
                                placeholder="Remove when">
                                <mat-option *ngFor="let option of synchronizedVideosWhenOptions | orderBy: 'text'"
                                    [value]="option.value">
                                    {{ option.text }}
                                </mat-option>
                            </mat-select>
                            <span matSuffix>{{ settingSynchronizedVideosWhen?.propertyHasChangesText('value') }}</span>
                            <mat-error>{{getFormControlErrorMessage(dataCullingFormGroup.controls['synchronizedVideosWhenOption'])}}</mat-error>
                        </mat-form-field>
                        <ng-template [ngIf]="dataCullingFormGroup.controls['synchronizedVideosWhenOption'].value === 'duration'">
                            <mat-form-field fxFlex="50">
                                <mat-select #synchronizedVideosCullingPeriod
                                    formControlName="synchronizedVideosCullingPeriod"
                                    placeholder="Older Than">
                                    <mat-option *ngFor="let option of synchronizedVideosCullingPeriods | orderBy: 'text'"
                                        [value]="option.value">
                                        {{ option.text }}
                                    </mat-option>
                                </mat-select>
                                <span matSuffix>{{ settingSynchronizedVideosOlderThan?.propertyHasChangesText('value') }}</span>
                                <mat-error>{{getFormControlErrorMessage(dataCullingFormGroup.controls['synchronizedVideosCullingPeriod'])}}
                                </mat-error>
                            </mat-form-field>
                        </ng-template>
                        <ng-template [ngIf]="dataCullingFormGroup.controls['synchronizedVideosWhenOption'].value === 'diskspace'">
                            <mat-form-field fxFlex="50">
                                <mat-select #synchronizedVideosDiskOption
                                    formControlName="synchronizedVideosDiskOption"
                                    placeholder="Less Than">
                                    <mat-option *ngFor="let option of synchronizedVideosDiskOptions | orderBy: 'text'"
                                        [value]="option.value">
                                        {{ option.text }}
                                    </mat-option>
                                </mat-select>
                                <span matSuffix>{{ settingSynchronizedVideosLessThan?.propertyHasChangesText('value') }}</span>
                                <mat-error>{{getFormControlErrorMessage(dataCullingFormGroup.controls['synchronizedVideosDiskOption'])}}</mat-error>
                            </mat-form-field>
                        </ng-template>
                    </div>
                    <div fxLayout="row">
                        <mat-checkbox #synchronizedVideosKeepValidationSessions
                            formControlName="synchronizedVideosKeepValidationSessions">Keep videos with validation sessions</mat-checkbox>
                        <span>{{ settingSynchronizedVideosKeepValidationSessions?.propertyHasChangesText('value') }}</span>
                    </div>
                    <div fxLayout="row">
                        <mat-checkbox #synchronizedVideosKeepGlobalBookmarks
                            formControlName="synchronizedVideosKeepGlobalBookmarks">Keep videos with global bookmarks</mat-checkbox>
                        <span>{{ settingSynchronizedVideosKeepGlobalBookmarks?.propertyHasChangesText('value') }}</span>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card fxFlex
                ngClass.gt-sm="option-card spacing-right"
                ngClass.lt-md="option-card spacing-bottom">
                <mat-card-title>
                    <span class="mat-title">Devices</span>
                </mat-card-title>
                <mat-card-subtitle>
                    <span class="mat-subheading-1">Remove devices that have not connected to the system for a specified time period.</span>
                </mat-card-subtitle>
                <mat-card-content>
                    <mat-form-field>
                        <mat-select #disconnectedDevicesCullingPeriod
                            formControlName="disconnectedDevicesCullingPeriod"
                            placeholder="Keep For">
                            <mat-option *ngFor="let option of disconnectedDevicesCullingPeriods | orderBy: 'text'"
                                [value]="option.value">
                                {{ option.text }}
                            </mat-option>
                        </mat-select>
                        <span matSuffix>{{ settingDisconnectedDevicesCullingPeriod?.propertyHasChangesText('value') }}</span>
                        <mat-error>{{getFormControlErrorMessage(dataCullingFormGroup.controls['disconnectedDevicesCullingPeriod'])}}</mat-error>
                    </mat-form-field>
                </mat-card-content>
            </mat-card>
        </div>
    </form>
</div>