import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface ISSLCertModel extends IRestModel {
    privateKey: string;
    publicKey: string;
    certName: string;
}

export class SSLCertModel extends BaseModel implements ISSLCertModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public privateKey: string = null;
    public publicKey: string = null;
    public certName: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-SSLCertModel');
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
