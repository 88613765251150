import { BaseModel } from '@shared/base/Base.Model';
import { AdvSearchSettingPropertyEnum } from '@shared/enum/AdvSearchSettingProperty.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class MatchedPropertyModel extends BaseModel implements IRestModel {
    public message: string = null;
    public propertyMatched: string = null;
    public readonly isIRestModel = true;
    public result: boolean = null;
    public type: AdvSearchSettingPropertyEnum = null;
    public valueMatched: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-MatchedPropertyModel');
    }

    public toRestApiModel() {
        return RestModelUtility.toJson(this);
    }
}
