import { Component, HostBinding, HostListener, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserModel } from '@em/models/restapi/User.Model';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'em-my-account-edit-details',
    templateUrl: './MyAccount.EditDetails.Component.html',
    styleUrls: ['./MyAccount.EditDetails.Component.scss']
})
export class MyAccountEditDetailsComponent extends BaseComponent implements ISaveAllChanges {
    public static className: string = 'MyAccountEditDetailsComponent';
    public editDetailsForm: FormGroup;

    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'em-my-account-edit-details';
    public user: UserModel;

    public constructor(
        @Inject(MAT_DIALOG_DATA) private readonly _user: UserModel,
        private readonly _dialog: MatDialog,
        private readonly _dialogRef: MatDialogRef<MyAccountEditDetailsComponent>,
        private readonly _formBuilder: FormBuilder,
        private readonly _navBarService: NavBarActionService,
        private readonly _userService: UserCurrentService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this._dialogRef.disableClose = true;

        this.user = this._user;

        this.changeTracker.track(this.user);

        this.saveAllChangesProcess = this.processMonitorService.getProcess(MyAccountEditDetailsComponent.className, this.saveAllChangesProcessText);
        this.formValuesChangeProcess = this.processMonitorService.getProcess(MyAccountEditDetailsComponent.className, 'Form values change');

        this.editDetailsForm = this._formBuilder.group({
            realName: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
            jobTitle: ['', Validators.compose([Validators.maxLength(254)])],
            organisation: ['', Validators.compose([Validators.maxLength(254)])],
            email: ['', Validators.compose([ValidationValidators.emailAddress, Validators.maxLength(254)])],
        });
        this.formGroupTracker.track(this.editDetailsForm);

        this.addSubscription(this.observableHandlerBase(this.editDetailsForm.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            this.updateModel(this.user);
        }), this.formValuesChangeProcess);

        this.setFormValues(this.user);
    }

    public cancel(): void {
        this.setFormValues(this.user);
        this._dialogRef.close();
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public save(): void {
        this.saveAllChangesStartBase(this, this.openPleaseWaitSavingDialog());
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            this._userService.saveCurrentUser(this.user, process).pipe(
                map(result => {
                    if (result.response === true) {
                        return true;
                    } else {
                        return false;
                    }
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            map(zipResults => {
                if (this.isZipResultSuccess(zipResults)) {
                    this._dialogRef.close();
                } else {
                    return zipResults;
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this);
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    private setFormValues(user: UserModel): void {
        this.editDetailsForm.setValue({
            realName: this.isNullOrUndefined(user.realName) ? '' : user.realName,
            jobTitle: this.isNullOrUndefined(user.jobTitle) ? '' : user.jobTitle,
            organisation: this.isNullOrUndefined(user.organisation) ? '' : user.organisation,
            email: this.isNullOrUndefined(user.email) ? '' : user.email,
        });
    }

    private updateModel(user: UserModel): void {
        const formModel = this.editDetailsForm.value;

        user.realName = formModel.realName;
        user.jobTitle = formModel.jobTitle;
        user.organisation = formModel.organisation;
        user.email = formModel.email;
    }
}
