export enum ValidationResultEnum {
    ok = 0,
    failedErrorState = 1,
    failedBuildVersion = 2,
    failedProtocolVersion = 3,
    failedLensType = 4,
    failedMixedDeviceTypeNetwork = 5,
    failedMissingDetectFlag = 6,
    failedInvalidDetectFlag = 7,
    failedRelayCheckAutoUnits = 8,
    failedRelayCheckTooManyMasters = 9,
    failedRelayCheckNoMaster = 10,
    failedMultiUnitMk2NoMaster = 11,
    failedMultiUnitMk2TooManyMasters = 12,
    noDevices = 13,
}
