/**
 * The schedule granularity.
 *
 * @export
 * @enum {number}
 */
export enum ScheduleGranularityEnum {

    /**
     * All devices.
     */
    all = 0,

    /**
     * A single folder.
     */
    folder = 1,

    /**
     * A single device.
     */
    device = 2
}
