import { ContributionType } from '@shared/enum/ContributionType.Enum';
import { RegisterCountLogModel } from '@em/models/restapi/RegisterCountLog.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';

export class RegisterModel extends BaseModel implements IRestModel {
    public contribution: ContributionType = null;
    public countLogs: Array<RegisterCountLogModel> = null;
    public deviceSerial: number = null;
    public readonly isIRestModel = true;
    public registerId: number = null;
    public registerName: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-RegisterModel');

        this.countLogs = RestModelUtility.loadFromArray<RegisterCountLogModel>(restModel.CountLogs, RegisterCountLogModel);
    }

    public toRestApiModel(): any {
        throw new Error('Method not implemented.');
    }
}
