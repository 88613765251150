/**
 * States of messages in bottom info.
 *
 * @export
 * @enum {number}
 */
export enum BottomInfoStateEnum {
    info = 0,
    warn = 1,
    error = 2,
}
