import { Injectable } from '@angular/core';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BACNETModel } from '@rift/models/restapi/BACNET.Model';
import { RestApiBACNETService } from '@rift/service/restapi/v1/RestApi.BACNET.Service';

@Injectable()
export class BACNETService extends RiftBaseService {

    private _getBACNETLoadingTracker = new ObservableTracker<BACNETModel>();
    private _bacnetCache: BACNETModel;
    private _setBACNETLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiBACNETService: RestApiBACNETService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._bacnetCache = null;
    }

    public getBACNET(process?: ProcessMonitorServiceProcess): Observable<BACNETModel> {
        if (isNullOrUndefined(this._bacnetCache)) {
            return this._getBACNETLoadingTracker
                .getLoading()
                .observable(this._restApiBACNETService.getBACNET(process).pipe(
                    map(result => {
                        this._bacnetCache = result;
                        return this._bacnetCache;
                    })
                ));
        } else {
            return of(this._bacnetCache);
        }
    }

    public setCache(bacnetCache: BACNETModel): void {
        this._bacnetCache = bacnetCache;
    }

    public setBACNET(bacnet: BACNETModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setBACNETLoadingTracker
            .getLoading(bacnet)
            .observable(this._restApiBACNETService.setBACNET(bacnet, process).pipe(tap(() => this._bacnetCache = null)));
    }
}
