import { IFrameStore } from '@rift/service/validation/database/syncrecording/IFrameStore';
import { IObjectStore } from '@shared/service/Indexeddb/IObjectStore';

export interface IDatabaseGlobalDataFramesStore extends IFrameStore {
}

export class DatabaseGlobalDataFramesStore implements IObjectStore {
    public static readonly keyPath: string = 'id';
    public static readonly recordingIdBlockIndex: string = 'RecordingIdBlockIndex';
    public static readonly recordingIdFrameNumberIndex: string = 'RecordingIdFrameNumberIndex';
    public static readonly recordingIdIndex: string = 'RecordingIdIndex';
    public static readonly storeDisplayName: string = 'Counts';
    public static readonly storeName: string = 'globaldata';

    public get storeName(): string {
        return DatabaseGlobalDataFramesStore.storeName;
    };

    public static toInterface(): IDatabaseGlobalDataFramesStore {
        return {
            storeName: this.storeName,
            keyPath: this.keyPath,
            recordingIdFrameNumberIndex: this.recordingIdFrameNumberIndex,
            recordingIdIndex: this.recordingIdIndex,
            recordingIdBlockIndex: this.recordingIdBlockIndex,
        } as IDatabaseGlobalDataFramesStore;
    }

    public createStores(db: IDBDatabase): void {
        const store: IDBObjectStore = db.createObjectStore(DatabaseGlobalDataFramesStore.storeName, { keyPath: DatabaseGlobalDataFramesStore.keyPath, autoIncrement: true });
        store.createIndex(DatabaseGlobalDataFramesStore.recordingIdFrameNumberIndex, ['recordingId', 'frameNumber'], { unique: true });
        store.createIndex(DatabaseGlobalDataFramesStore.recordingIdBlockIndex, ['recordingId', 'block']);
        store.createIndex(DatabaseGlobalDataFramesStore.recordingIdIndex, 'recordingId');
    }
}
