import { ScheduleTypeEnum } from '@shared/enum/ScheduleType.Enum';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';

const EXCLUDE_FIELDS = ['startDateText', 'startTimeText', 'durationText', 'endTimeText'];

export interface IVideoScheduleModel extends IRestModel {
    type: number;
    startTime: Date;
    endTime: Date;
    endCount: number;
    timezoneOffsetMins: number;
    includeDiagnosticData: boolean;
    nodes: Array<string>;
    groupRecordingIdentifier: string;
}

export class VideoScheduleModel extends BaseModel implements IVideoScheduleModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public type: ScheduleTypeEnum = null;
    public startTime: Date = null;
    public endTime: Date = null;
    public endCount: number = null;
    public timezoneOffsetMins: number = null;
    public includeDiagnosticData: boolean = null;
    public captureActivityOnly: boolean = null;
    public nodes: Array<string> = null;
    public groupRecordingIdentifier: string = null;

    private _startDateText: string = null;
    private _startTimeText: string = null;
    private _durationText: string = null;
    private _endTimeText: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-VideoScheduleModel', EXCLUDE_FIELDS);

        this.startTime = DateTimeNonMomentUtility.fromRestApiDateTime(this.startTime);
        this.endTime = DateTimeNonMomentUtility.fromRestApiDateTime(this.endTime);

        this.setTimeText();
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, EXCLUDE_FIELDS),
            StartTime: DateTimeNonMomentUtility.toRestApiDateTime(this.startTime),
            EndTime: DateTimeNonMomentUtility.toRestApiDateTime(this.endTime),
            Nodes: this.nodes
        };
    }

    public setTimeText(): any {
        if (isNullOrUndefined(this._startDateText) || DateTimeUtility.isInvalidDate(this._startDateText)) {
            this._startDateText = DateTimeUtility.toShortDate(this.startTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.timezoneOffsetMins));
        }

        if (isNullOrUndefined(this._startTimeText) || DateTimeUtility.isInvalidDate(this._startTimeText)) {
            this._startTimeText = DateTimeUtility.toShortTime(this.startTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.timezoneOffsetMins));
        }

        if (isNullOrUndefined(this._durationText) || this._durationText === 'NaN:NaN:NaN') {
            if (this.type === ScheduleTypeEnum.timeBased){
                this._durationText = DateTimeUtility.toDuration(this.startTime, this.endTime);
            }
            else{
                this._durationText = this.endCount.toString() + ' Counts';
            }
        }

        if (isNullOrUndefined(this._endTimeText) || DateTimeUtility.isInvalidDate(this._endTimeText)) {
            this._endTimeText = DateTimeUtility.toShortTime(this.endTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.timezoneOffsetMins));
        }
    }

    public get startDateText(): string {
        return this._startDateText;
    }

    public get startTimeText(): string {
        return this._startTimeText;
    }

    public get durationText(): string {
        return this._durationText;
    }

    public get endTimeText(): string {
        return this._endTimeText;
    }
}
