import { REGISTER_BASE_EXCLUDE_PROPERTIES, RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterPulseGeneratorModel extends IRegisterBaseModel {
    sourceRegisterIndex: number;
    incrementPeriod: number;
}

/**
 * Represents a
 *
 * @export
 * @class RegisterPulseGeneratorModel
 * @extends {RegisterBaseModel}
 * @implements {IRegisterOccupancyMasterModel}
 * @implements {IRestModel}
 */
export class RegisterPulseGeneratorModel extends RegisterBaseModel implements IRegisterPulseGeneratorModel, IRestModel {

    public sourceRegisterIndex: number = null;
    public incrementPeriod: number = null;

    public constructor() {
        super();

        this.registerProperty('sourceRegisterIndex');
        this.registerProperty('incrementPeriod');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterPulseGeneratorModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('sourceRegisterIndex', this.sourceRegisterIndex);
        this.setPropertyOriginalValue('incrementPeriod', this.incrementPeriod);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], isNullOrUndefined(this.sourceRegisterIndex) ? [] : [this.sourceRegisterIndex], []);
    }

}
