<div class="em-settings-options-video"
    fxLayout="column">    
    <form [formGroup]="videoFormGroup">
        <mat-card class="header">
            <mat-card-title>
                <span class="mat-title">Automatic Video Download</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span class="mat-subheading-1">Estate Manager can automatically download new recordings from video-capable devices. "New" is defined
                    as being recorded within the last 48 hours.</span>
            </mat-card-subtitle>
            <mat-card-content>
                <mat-checkbox #autoDownload
                    formControlName="autoDownload">Enable automatic download for any video recorded within the last:</mat-checkbox>
                <span>{{ settingAutoDownload?.propertyHasChangesText('value') }}</span>
                <mat-form-field fxFlex="50"
                    class="spacing-right">
                    <mat-select #cullingPeriod
                        formControlName="cullingPeriod">
                        <mat-option *ngFor="let option of cullingPeriods | orderBy: 'text'"
                            [value]="option.value">
                            {{ option.text }}
                        </mat-option>
                    </mat-select>
                    <span matSuffix>{{ settingCullingPeriod?.propertyHasChangesText('value') }}</span>
                    <mat-error>{{getFormControlErrorMessage(videoFormGroup.controls['cullingPeriod'])}}</mat-error>
                </mat-form-field>
            </mat-card-content>
            <mat-card-title>
                <span class="mat-title">Downloaded Video Storage Location</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span class="mat-subheading-1">This is where Estate Manager will store downloaded videos, whether they are retrieved manually or automatically (services must be restarted for new paths to be applied).</span>
            </mat-card-subtitle>
            <mat-card-content>
                <mat-form-field style="width: 400px;">
                    <input type="text"
                        #downloadRootPath
                        matInput
                        formControlName="downloadRootPath"
                        placeholder="Storage URI">
                    <span matSuffix>{{ settingDownloadRootPath?.propertyHasChangesText('value') }}</span>
                    <mat-error>{{getFormControlErrorMessage(videoFormGroup.controls['downloadRootPath'])}}</mat-error>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </form>
</div>