import { SyncStateEnum } from '@rift/service/validation/models/SyncState.Enum';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IBookmarkModel extends IRestModel {
    id: number;
    comment: string;
    frameNumber: number;
    syncState: SyncStateEnum;
}

export class BookmarkModel extends BaseModel implements IBookmarkModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public id: number = null;
    public comment: string = null;
    public frameNumber: number = null;
    public syncState: SyncStateEnum = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-BookmarkModel');
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }

    public toInterface(): IBookmarkModel {
        return {
            id: this.id,
            comment: this.comment,
            frameNumber: this.frameNumber,
        } as IBookmarkModel;
    }
}
