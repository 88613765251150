import { ColumnFilterOptionModel } from '@em/models/restapi/ColumnFilterOption.Model';
import { ColumnSortingOptionModel } from '@em/models/restapi/ColumnSortingOption.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';
import { isNullOrUndefined } from '@shared/utility/General.Utility';


export class PaginationOptionsModel extends BaseModel implements IRestModel {
    public columnFilterOptions: Array<ColumnFilterOptionModel> = null;
    public columnSortingOptions: Array<ColumnSortingOptionModel> = null;

    public enabled: boolean = null;
    public page: number = null;
    public readonly isIRestModel = true;
    public resultsPerPage: number = null;
    public totalResults: number = null;

    public constructor(page?: number, resultsPerPage?: number, totalResults?: number) {
        super();
        if (!isNullOrUndefined(page)) {
            this.page = page;
        }
        if (!isNullOrUndefined(resultsPerPage)) {
            this.resultsPerPage = resultsPerPage;
        }
        if (!isNullOrUndefined(totalResults)) {
            this.totalResults = totalResults;
        }
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-PaginationOptionsModel');

        this.columnSortingOptions = RestModelUtility.loadFromArray<ColumnSortingOptionModel>(restModel.ColumnSortingOptions, ColumnSortingOptionModel);
        this.columnFilterOptions = RestModelUtility.loadFromArray<ColumnFilterOptionModel>(restModel.ColumnFilterOptions, ColumnFilterOptionModel);
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
