
export class DeviceGroupErrorWarningMapping{
    public userDescription: string;
    public messageMatch: string;
    public messageDisplay: string;
    public helpURL: string;
}

export class DeviceGroupErrorWarningEntry extends DeviceGroupErrorWarningMapping{
    public numDevices: number;
}

export const deviceGroupErrorWarningMappings: Array<DeviceGroupErrorWarningMapping> = [
    {
        messageMatch: 'KCS: data partition not found, or could not be mounted',
        messageDisplay: 'Data partition not found, or could not be mounted',
        userDescription: 'There is an issue with the data partition on the device, sometimes this can be resolved by formatting the data partition however it can also indicate an issue with the SD card on device',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/DataPartition'
    },
    {
        messageMatch: 'KCS: config partition not found, or could not be mounted',
        messageDisplay: 'Config partition not found, or could not be mounted',
        userDescription: 'There is an issue with the config partition on the device',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/ConfigPartition'
    },
    {
        messageMatch: 'KCS: factory partition not found, or could not be mounted',
        messageDisplay: 'Config partition not found, or could not be mounted',
        userDescription: 'There is an issue with the config partition on the device',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/FactoryPartition'
    },
    {
        messageMatch: 'KCS: webfiles partition not found, or could not be mounted',
        messageDisplay: 'Webfiles partition not found, or could not be mounted',
        userDescription: 'There is an issue with the webfiles partition on the device, sometimes this can be resolved by formatting the webfiles partition and reapplying the latest firmware package however it can also indicate an issue with the SD card on device',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/WebfilesPartition'
    },
    {
        messageMatch: 'KCS: system partition not found, or could not be mounted',
        messageDisplay: 'System partition not found, or could not be mounted',
        userDescription: 'There is an issue with the system partition on the device, sometimes this can be resolved by formatting the system partition and reapplying the latest firmware package however it can also indicate an issue with the SD card on device',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/SystemPartition'
    },
    {
        messageMatch: 'DepthTracker: detected height',
        messageDisplay: 'Detected height significantly differs from configured',
        userDescription: 'The last recorded height value differs from the current measured mounting height. Follow the procedure for accessing the main counting setup and run the Auto-height routine',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/DetectedHeightTilt'
    },
    {
        messageMatch: 'DepthTracker: detected tilt',
        messageDisplay: 'Detected tilt significantly differs from configured',
        userDescription: 'The last recorded tilt angle values differ from the current measured values. Follow the procedure for accessing the main counting setup and run the Auto-height, or Refresh Tilt routines',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/DetectedHeightTilt'
    },
    {
        messageMatch: 'DepthTracker: tracker running with cropped tilt',
        messageDisplay: 'Tracker running with cropped tilt',
        userDescription: 'The device is tilted beyond its specification. Refreshing the tilt detection in the counting setup may resolve this issue, if not, the device needs to be re-installed at less of an angle',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/TiltCropped'
    },
    {
        messageMatch: 'DepthTracker: auto-configuration failed',
        messageDisplay: 'Tracker auto-configuration failed',
        userDescription: 'The device has not been configured with a valid height. Go to the main Count setup tab to complete the setup',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/AutoConfigFailed'
    },
    {
        messageMatch: 'DepthTracker::reconfigure',
        messageDisplay: 'Tracker reconfiguration failed',
        userDescription: 'The device has an invalid tracking configuration, see each individual device for more details',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/TrackerReconfiguration'
    },
    {
        messageMatch: 'ClientConnection: ',
        messageDisplay: 'Outbound client connection issue',
        userDescription: 'The device is having issues with its outbound connections, see each individual device for more details',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/ClientConnection'
    },
    {
        messageMatch: 'WirelessPIR: No data received from the following:',
        messageDisplay: 'Wireless PIR not receiving data',
        userDescription: 'The device is not receiving data from the configured wireless PIR (Fine Motion Room Sensor)',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/WirelessPIR'
    },
    {
        messageMatch: 'HTTP Post: no connection to',
        messageDisplay: 'HTTP Post cannot connect',
        userDescription: 'The device is not able to connect to a configured HTTP Post end point. Check the configured HTTP post server details and confirm a network path from the device to the server',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/HTTPpost'
    },
    {
        messageMatch: 'MQTT: MQTT: no connection to',
        messageDisplay: 'No MQTT connection',
        userDescription: 'The device is unable to connect to a configured MQTT broker, check the configured broker details and confirm a network path from the device to the broker',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/MQTTconnection'
    },
    {
        messageMatch: 'disabled - accelerometer z-axis threshold breached',
        messageDisplay: 'Time of flight illuminator disabled',
        userDescription: 'This is usually caused when the device is being tested on a bench and is upside down, or has been installed at an extreme angle out of specification',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/IlluminatorDisabled'
    },
    {
        messageMatch: 'MasterComms: Dependent devices with warnings',
        messageDisplay: 'Dependent devices have warnings',
        userDescription: 'Devices that send data to this device, or which receive data from this device, are in a warning state',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/DependentDevices'
    },
    {
        messageMatch: 'MasterCommsFailure: Dependent devices in error',
        messageDisplay: 'Dependent devices have errors',
        userDescription: 'Devices that send data to this device, or which receive data from this device, are in an error state',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/DependentDevices'
    },
    {
        messageMatch: 'MasterCommsFailure: Missing nodes',
        messageDisplay: 'Missing nodes',
        userDescription: 'The device cannot communicate with one or more assigned node devices',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/MissingNodes'
    },
    {
        messageMatch: 'MasterComms: Missing secondary masters',
        messageDisplay: 'Missing secondary masters',
        userDescription: 'Secondary masters required by this device are missing',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/SecondaryMasters'
    },
    {
        messageMatch: 'USBIO: USBIO: module expected but not found',
        messageDisplay: 'USB IO module not present',
        userDescription: 'Make sure the USB IO module is correctly attached to the device',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/USBIOexpected'
    },
    {
        messageMatch: 'Device count log has been reset. Estate Manager will be out of sync with the device until old device data is removed from the database',
        messageDisplay: 'Device count log reset. Estate Manager cannot collect data',
        userDescription: 'To allow Estate Manager to continue to gather count data old data must first be removed. On the device page select Delete Device Data from the actions menu and follow the instructions',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/ResetLogs'
    },
    {
        messageMatch: 'Device histogram log has been reset. Estate Manager will be out of sync with the device until old device data is removed from the database',
        messageDisplay: 'Device histogram log reset. Estate Manager cannot collect data',
        userDescription: 'To allow Estate Manager to continue to gather histogram data old data must first be removed. On the device page select Delete Device Data from the actions menu and follow the instructions',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/ResetLogs'
    },
    {
        messageMatch: 'Device diagnostics log has been reset. Estate Manager will be out of sync with the device until old device data is removed from the database',
        messageDisplay: 'Device diagnostics log reset. Estate Manager cannot collect data',
        userDescription: 'To allow Estate Manager to continue to gather diagnostics data old data must first be removed. On the device page select Delete Device Data from the actions menu and follow the instructions',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/ResetLogs'
    },
    {
        messageMatch: 'The device has not been seen within the last 24 hours',
        messageDisplay: 'The device has not been seen within the last 24 hours',
        userDescription: 'Device is offline and so its status cannot be known. While it is possible that the device is still operating correctly, it is not clear from Estate Manager alone, and further investigation will be required.',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/Device_Offline'
    },
    {
        messageMatch: 'BootFromFallback: Boot: Device fell back to boot from nor',
        messageDisplay: 'BootFromFallback: Boot: Device fell back to boot from nor',
        userDescription: 'If the SD card is missing or faulty - this error message will be the result',
        helpURL: 'https://partners.irisys.net/wikis/estate-manager/BootFromFallback'
    }
];


