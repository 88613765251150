import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { NgZone, Directive } from '@angular/core';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { FrameTrackerStates } from '@rift/workers/validationsyncrecording/FrameTrackerStates.Enum';
import { ColorUtility } from '@shared/utility/Color.Utility';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';

@Directive()
export class TimeLineDurationBarBuffer extends DisplayItem {
    public readonly height: number = 3;

    private _width: number = 0;
    private _bar: createjs.Shape = new createjs.Shape();
    private _color: string = '#000000';
    private _alpha: number = 1;
    private _frameTracker: FrameTrackerStates[];
    private _isComplete: boolean;
    private _fullSegmentWidth: number;

    public constructor(
        protected readonly _playService: ValidationPlayService,
        protected readonly _zone: NgZone) {
        super(_zone);

        this.container.addChild(this._bar);

        this.addEventHandlers();
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
            this._bar.uncache();
            this._bar = null;

            this._frameTracker?.splice(0, this._frameTracker.length);
        });
    }

    public set width(value: number) {
        this._zone.runOutsideAngular(() => {
            if (this._width !== value) {
                this._width = value;
            }
        });
    }
    public get width(): number {
        return this._width;
    }

    public set color(value: string) {
        this._zone.runOutsideAngular(() => {
            if (this._color !== value) {
                this._color = value;
            }
        });
    }
    public get color(): string {
        return this._color;
    }

    public set alpha(value: number) {
        this._zone.runOutsideAngular(() => {
            if (this._alpha !== value) {
                this._alpha = value;
            }
        });
    }
    public get alpha(): number {
        return this._alpha;
    }

    public bringToFront(displayItem?: IDisplayItem): void {

    }

    public update(): void {
        this.drawBuffer();
        this.requireStageUpdate.next();
    }

    public setState(frameTracker: FrameTrackerStates[], isComplete: boolean): void {
        this._frameTracker = frameTracker;
        this._isComplete = isComplete;
        this.update();
    }

    public resize(width: number, fullSegmentWidth: number): void {
        this.width = width;
        this._fullSegmentWidth = fullSegmentWidth;
        this.update();
    }

    private drawBuffer(): void {
        this._zone.runOutsideAngular(() => {
            const graphics = this._bar.graphics;

            graphics.clear();

            if (!isNullOrUndefined(this._frameTracker) && !isNullOrUndefined(this._isComplete)) {

                graphics.setStrokeStyle(this.height);
                graphics.beginStroke(ColorUtility.hexToRGBA(this.color, this.alpha));

                const minFrameNumber = this._playService.viewStartLocation.frameNumber;
                const maxFrameNumber = this._playService.viewEndLocation.frameNumber;

                if (this._isComplete === false) {
                    let startFrame: number = null;
                    let endFrame: number = null;
                    for (let frameNumber = minFrameNumber; frameNumber <= maxFrameNumber; frameNumber++) {
                        const frameState = this._frameTracker[frameNumber];

                        if (frameState !== FrameTrackerStates.notDownloaded) {
                            if (startFrame === null) {
                                startFrame = frameNumber;
                            } else {
                                endFrame = frameNumber;
                            }
                        } else {
                            if (startFrame !== null && endFrame !== null) {
                                const startX = this._playService.playLocationToPixel({ frameNumber: startFrame }, this._fullSegmentWidth);
                                const endX = this._playService.playLocationToPixel({ frameNumber: endFrame }, this._fullSegmentWidth);
                                graphics.moveTo(startX, this.height / 2);
                                graphics.lineTo(endX, this.height / 2);
                            }

                            startFrame = null;
                            endFrame = null;
                        }
                    }

                    if (startFrame !== null && endFrame !== null) {
                        const startX = this._playService.playLocationToPixel({ frameNumber: startFrame }, this._fullSegmentWidth);
                        const endX = this._playService.playLocationToPixel({ frameNumber: endFrame }, this._fullSegmentWidth);
                        graphics.moveTo(startX, this.height / 2);
                        graphics.lineTo(endX, this.height / 2);
                    }
                } else {
                    graphics.moveTo(this._playService.playLocationToPixel({ frameNumber: minFrameNumber }, this._fullSegmentWidth), this.height / 2);
                    graphics.lineTo(this._playService.playLocationToPixel({ frameNumber: maxFrameNumber }, this._fullSegmentWidth), this.height / 2);
                }
            }
            this._bar.cache(0, 0, this.width, this.height, GLOBAL_CACHE_SCALE);
        });
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this.addSubscription(this._playService.onViewUpdated.subscribe(() => this.update()));
        });
    }
}
