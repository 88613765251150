import { NgZone, Directive } from '@angular/core';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { Line } from '@rift/components/shared/viewport/lines/Line';
import { LineCollection } from '@rift/components/shared/viewport/lines/LineCollection';
import { Polygon } from '@rift/components/shared/viewport/polygons/Polygon';
import { PolygonCollection } from '@rift/components/shared/viewport/polygons/PolygonCollection';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';

@Directive()
export class Register extends DisplayItem {
    private _fadeOut: boolean = false;
    private _lines: LineCollection = null;
    private _polygons: PolygonCollection = null;

    public constructor(
        private readonly _zone: NgZone,
        private readonly _loadQueue: ViewPortLoadQueueService,
        private readonly _registerBaseModel: RegisterBaseModel) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            this._lines = new LineCollection(_zone, this._loadQueue);
            this._polygons = new PolygonCollection(_zone, this._loadQueue);

            this.container.addChild(this._lines.container);
            this.container.addChild(this._polygons.container);

            this.addEventHandlers();
        });
    }

    public bringToFront(displayItem?: Line | Polygon): void {
        this._zone.runOutsideAngular(() => {
            if (displayItem instanceof Line) {
                this.lines.bringToFront(displayItem);
            } else if (displayItem instanceof Polygon) {
                this.polygons.bringToFront(displayItem);
            }
        });
    }

    public get fadeOut(): boolean {
        return this._fadeOut;
    }
    public set fadeOut(value: boolean) {
        if (this._fadeOut !== value) {
            this._fadeOut = value;
            if (value === true) {
                this.container.alpha = 0.5;
            } else {
                this.container.alpha = 1;
            }
            this.requireStageUpdate.next();
        }
    }

    public get lines(): LineCollection {
        return this._zone.runOutsideAngular(() => this._lines);
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();

            this.container.removeAllChildren();

            this._lines.onDestroy();
            this._polygons.onDestroy();

            this._lines = null;
            this._polygons = null;
        });
    }

    public get polygons(): PolygonCollection {
        return this._zone.runOutsideAngular(() => this._polygons);
    }

    public get registerBaseModel(): RegisterBaseModel {
        return this._zone.runOutsideAngular(() => this._registerBaseModel);
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            this.lines.update();
            this.polygons.update();
        });
    }

    public set mouseEnabled(value: boolean) {
        this._zone.runOutsideAngular(() => {
            this.container.mouseEnabled = value;
        });
    }

    public get visible(): boolean {
        return this._zone.runOutsideAngular(() => this.container.visible);
    }

    public set visible(value: boolean) {
        this._zone.runOutsideAngular(() => {
            if (this.container.visible !== value) {
                this.container.visible = value;
                this.requireStageUpdate.next();
            }
        });
    }

    protected addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this.addSubscription(this.lines.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
            this.addSubscription(this.polygons.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
        });
    }

    protected modeSet(): void {
        this._zone.runOutsideAngular(() => {
            const linesLength = this.lines.length;
            for (let index = 0; index < linesLength; index++) {
                this.lines[index].mode = this.mode;
            }

            const polygonsLength = this.polygons.length;
            for (let index = 0; index < polygonsLength; index++) {
                this.polygons[index].mode = this.mode;
            }

            super.modeSet();
        });
    }
}
