import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface ITLSCertModel extends IRestModel {
    name: string;
    data: string;
}

export class TLSCertModel extends BaseModel implements ITLSCertModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public name: string = null;
    public data: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-TLSCertModel');
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
