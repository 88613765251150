import { IResultModel } from '@rift/models/restapi/Result.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IClientConnectionModel extends IRestModel {
    enabled: boolean;
    address: string;
    port: number;
    reconnectionInterval: number;
}

export class ClientConnectionModel extends BaseModel implements IClientConnectionModel, IResultModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public enabled: boolean = null;
    public address: string = null;
    public port: number = null;
    public reconnectionInterval: number = null;

    public constructor() {
        super();

        this.registerProperty('enabled');
        this.registerProperty('address');
        this.registerProperty('port');
        this.registerProperty('reconnectionInterval');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ClientConnectionModel');

        this.setPropertyOriginalValue('enabled', this.enabled);
        this.setPropertyOriginalValue('address', this.address);
        this.setPropertyOriginalValue('port', this.port);
        this.setPropertyOriginalValue('reconnectionInterval', this.reconnectionInterval);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
