import { Injectable } from '@angular/core';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';

@Injectable()
export class ConnectionTokenService extends RiftBaseService {
    public connectionToken: string = null;
    public secureToken: string = null;

    public constructor() {
        super();
    }
}
