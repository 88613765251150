<div class="em-settings-outboundconnections-addconnection-title"
    mat-dialog-title>
    Add Connection
</div>
<div class="em-settings-outboundconnections-addconnection-content"
    mat-dialog-content
    fxLayout="column">
    <form [formGroup]="connectionFormGroup"
        fxLayout="column">
        <mat-form-field>
            <input #address
                formControlName="address"
                type="text"
                matInput
                placeholder="Address">
            <mat-error>{{getFormControlErrorMessage(connectionFormGroup.controls['address'])}}</mat-error>
            <mat-hint align="end">{{address.value.length}} / 2083</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <input #port
                formControlName="port"
                type="number"
                matInput
                placeholder="Port">
            <mat-error>{{getFormControlErrorMessage(connectionFormGroup.controls['port'])}}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <input #username
                formControlName="username"
                type="text"
                matInput
                placeholder="Username (Vector Optional)">
            <mat-error>{{getFormControlErrorMessage(connectionFormGroup.controls['username'])}}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <input #passsword
                formControlName="password"
                type="password"
                matInput
                placeholder="Password (Vector Optional)">
            <mat-error>{{getFormControlErrorMessage(connectionFormGroup.controls['password'])}}</mat-error>
        </mat-form-field>
    </form>
    <form [formGroup]="connectionsFormGroup"
        fxLayout="column">
        <mat-form-field>
            <textarea #multiples
                formControlName="multiples"
                matInput
                placeholder="Multiples"
                style="min-height: 100px"></textarea>
            <mat-error>{{getFormControlErrorMessage(connectionFormGroup.controls['multiples'])}}</mat-error>
        </mat-form-field>
    </form>
    <div class="help">
        <span>The multiples and CSV format must be Address, Port, Username, Password, Group Name followed by new line.</span>
        <span>The Address, Port, Username and Password are required (Username and Password can be blank but must be present).</span>
        <span>The Group Name is optional.</span>
        <span class="error">{{errorText}}</span>
    </div>
</div>
<div class="em-settings-outboundconnections-addconnection-actions"
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <button (click)="add()"
        mat-raised-button
        color="primary"
        [disabled]="(connectionFormGroup.valid === false && connectionFormGroup.disabled === false) || (connectionsFormGroup.valid === false && connectionsFormGroup.disabled === false)">Add</button>
    <input type="file"
        #uploadCsv
        (change)="onSelectCsvFile($event)"
        style="display: none"
        accept=".csv">
    <button mat-raised-button
        color="primary"
        class="spacing-left"
        (click)="uploadCsv.click()">Upload CSV</button>
    <div fxFlex></div>
    <button (click)="cancel()"
        mat-raised-button
        color="primary">Cancel</button>
</div>