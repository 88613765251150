import { Component, Inject, Injector, OnInit, ViewChild, HostBinding } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AddressBookTreeComponent } from '@em/components/shared/addressbooktree/AddressBookTree.Component';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { GroupCollectionModel } from '@em/models/restapi/GroupCollection.Model';
import { RoleModel } from '@em/models/restapi/Role.Model';
import { UserModel } from '@em/models/restapi/User.Model';
import { GroupService } from '@em/service/data/group/Group.Service';
import { UserService } from '@em/service/data/user/User.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { OkCancelDialogComponent, OkCancelDialogData } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent, PleaseWaitDialogData } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { TreeUtility } from '@shared/utility/Tree.Utility';
import { Observable, timer, zip, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


export class SettingsManageUsersEditData {
    public static className: string = 'SettingsManageUsersEditData';

    public roles: Array<RoleModel>;
    public user: UserModel;
}

export class SettingsManageUsersEditResult {
    public static className: string = 'SettingsManageUsersEditResult';

    public ok: boolean = false;

    public constructor(ok: boolean) {
        this.ok = ok;
    }
}

@Component({
    selector: 'em-settings-manage-users-edit',
    templateUrl: './Settings.ManageUsers.Edit.Component.html',
    styleUrls: ['./Settings.ManageUsers.Edit.Component.scss']
})
export class SettingsManageUsersEditComponent extends BaseComponent implements ILoadDate {
    public static className: string = 'SettingsManageUsersEditComponent';

    public saveProcess: ProcessMonitorServiceProcess;
    public getSystemGroupsProcess: ProcessMonitorServiceProcess;
    public getUserGroupsProcess: ProcessMonitorServiceProcess;
    public savingChangesProcess: ProcessMonitorServiceProcess;
    public updateUserProcess: ProcessMonitorServiceProcess;
    public addToGroupProcesses: Array<ProcessMonitorServiceProcess> = [];
    public removeFromGroupProcesses: Array<ProcessMonitorServiceProcess> = [];

    public user: UserModel;
    public userRoleId: number;
    public userGroups: GroupCollectionModel;
    public roles: Array<RoleModel>;
    public rootTreeGroup: GroupModel;

    @HostBinding()
    public id: string = 'em-settings-manage-users-edit';

    @ViewChild('usertree', { static: true })
    private _userAddressBook: AddressBookTreeComponent;

    @ViewChild('systemtree', { static: true })
    private _systemAddressBook: AddressBookTreeComponent;

    private _groupsFlat: Array<GroupModel>;
    private _groupsHaveChanges: boolean = false;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _groupService: GroupService,
        private readonly _userService: UserService,
        private readonly _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private readonly _data: SettingsManageUsersEditData,
        private readonly _dialogRef: MatDialogRef<SettingsManageUsersEditComponent>,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.user = this._data.user;
        this.roles = this._data.roles;
        this.userRoleId = this.user.role.roleId;

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsManageUsersEditComponent.className, this.loadDataProcessText);
        this.saveProcess = this.processMonitorService.getProcess(SettingsManageUsersEditComponent.className, 'Saving user changes.');
        this.getSystemGroupsProcess = this.processMonitorService.getProcess(SettingsManageUsersEditComponent.className, 'Getting system address book groups.');
        this.getUserGroupsProcess = this.processMonitorService.getProcess(SettingsManageUsersEditComponent.className, 'Getting user address book groups.');
        this.updateUserProcess = this.processMonitorService.getProcess(SettingsManageUsersEditComponent.className, 'Updating user.');
        this.savingChangesProcess = this.processMonitorService.getProcess(SettingsManageUsersEditComponent.className, 'Saving user changes.');

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public isRoleIdSystemManagerOrAbove(roleId: number): boolean{
        const role = this.roles.find(i => i.roleId === roleId);

        if (role.name.toLocaleLowerCase() === 'systemadmin' || role.name.toLocaleLowerCase() === 'systemmanager'){
            return true;
        }

        return false;
    }

    public systemGroupSelected(group: GroupModel): void {
        this.addUserToGroup(group);

        if (!this.isNullOrUndefined(this.rootTreeGroup) && !this.isNullOrUndefined(this.rootTreeGroup.children)) {
            this._userAddressBook.renderNodeChanges();
        }
    }

    public userGroupSelected(group: GroupModel): void {
        this.removeUserToGroup(group);

        if (!this.isNullOrUndefined(this.rootTreeGroup) && !this.isNullOrUndefined(this.rootTreeGroup.children)) {
            this._userAddressBook.renderNodeChanges();
        }
    }

    private get hasChanges(): boolean {
        return this.userRoleId !== this.user.role.roleId || this._groupsHaveChanges === true || this.user.hasChanges === true;
    }

    public save(): void {
        if (this.hasChanges === true) {
            const dialogRef = this._dialog.open(PleaseWaitDialogComponent, { data: new PleaseWaitDialogData('Saving User Changes'), disableClose: true, minWidth: 250 });

            this.savingChangesProcess.started();

            this.user.role = this.roles.find(i => i.roleId === this.userRoleId);

            if(this.user.role.name.toLocaleLowerCase() !== 'systemadmin' &&
                this.user.role.name.toLocaleLowerCase() !== 'systemmanager') {

                const selectedUserGroups: GroupModel[] = [];
                const groupsFlatLength = this._groupsFlat.length;
                for (let index = 0; index < groupsFlatLength; index++) {
                    const group = this._groupsFlat[index];
                    if (group.visable === true && !group.grayed) {
                        selectedUserGroups.push(group);
                    }
                }

                const selLength = selectedUserGroups.length;
                for (let seli = 0; seli < selLength; seli++) {
                    const selGroup = selectedUserGroups[seli];

                    const curGroup = this.userGroups.items.find(i => i.id === selGroup.id);
                    if (this.isNullOrUndefined(curGroup)) {
                        const addToGroupProcess = this.processMonitorService.getProcess('SettingsManageUsersEditComponent', 'Adding user to group.');
                        this.addToGroupProcesses.push(addToGroupProcess);

                        this.addSubscription(this.observableHandlerBase(this._userService.addToGroup(this.user, selGroup, addToGroupProcess), addToGroupProcess).subscribe(), addToGroupProcess);
                    }
                }

                const curLength = this.userGroups.items.length;
                for (let curi = 0; curi < curLength; curi++) {
                    const curGroup = this.userGroups.items[curi];

                    const selGroup = selectedUserGroups.find(i => i.id === curGroup.id);
                    if (this.isNullOrUndefined(selGroup)) {
                        const removeFromGroupProcess = this.processMonitorService.getProcess('SettingsManageUsersEditComponent', 'Removing user from group.');
                        this.removeFromGroupProcesses.push(removeFromGroupProcess);

                        this.addSubscription(this.observableHandlerBase(this._userService.removeFromGroup(this.user, curGroup, removeFromGroupProcess), removeFromGroupProcess).subscribe(), removeFromGroupProcess);
                    }
                }
            }

            this.user.fullyInit = true;

            this.addSubscription(this.observableHandlerBase(this._userService.updateOrAdd(this.user, this.updateUserProcess), this.updateUserProcess).subscribe(), this.updateUserProcess);

            const timerSub = this.addSubscription(this.observableHandlerBase(timer(0, 100), this.updateUserProcess).subscribe(() => {
                if (!this.updateUserProcess.isRunning && !this.addToGroupProcesses.some(i => i.isRunning) && !this.removeFromGroupProcesses.some(i => i.isRunning)) {
                    if (!this.isNullOrUndefined(timerSub)) {
                        timerSub.unsubscribe();
                    }
                    dialogRef.close();
                    this.addToGroupProcesses = [];
                    this.removeFromGroupProcesses = [];
                    this.savingChangesProcess.completed();
                    this._dialogRef.close();
                }
            }), this.updateUserProcess);
        } else {
            this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('No Changes', 'There are no changes to save.', false) });
        }
    }

    public cancel(): void {
        this._dialogRef.close(new SettingsManageUsersEditResult(false));
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._groupService.getNested(process).pipe(
                flatMap(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this._groupsFlat = (ArrayUtility.flatten([], result) as Array<GroupModel>);

                        const groupsFlatlength = this._groupsFlat.length;
                        for (let gfi = 0; gfi < groupsFlatlength; gfi++) {
                            const group = this._groupsFlat[gfi];
                            group.visable = false;
                            group.grayed = true;
                        }

                        return this._userService.getGroups(this.user.userId, this.getUserGroupsProcess).pipe(
                            map(permissionGroups => {
                                if (!this.isNullOrUndefined(permissionGroups)) {
                                    this.userGroups = permissionGroups;

                                    const permissionGroupslength = permissionGroups.items.length;
                                    for (let pgi = 0; pgi < permissionGroupslength; pgi++) {
                                        const group = permissionGroups.items[pgi];
                                        const index = this._groupsFlat.findIndex(i => i.id === group.id);

                                        if (index !== -1){
                                            this._groupsFlat[index].grayed = false;
                                            this._groupsFlat[index].visable = true;

                                            let parent = this._groupsFlat[index].parent;
                                            while (!this.isNullOrUndefined(parent)) {
                                                parent.visable = true;
                                                parent = parent.parent;
                                            }
                                        }
                                    }

                                    this.rootTreeGroup = result;

                                    this._userAddressBook.expandAll();
                                    this._systemAddressBook.expandAll();

                                    this._groupsHaveChanges = false;
                                }
                                return true;
                            })
                        );
                    }
                    return of(true);
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    private addUserToGroup(group: GroupModel, updatePerents: boolean = true): void {
        this._groupsHaveChanges = true;

        group.visable = true;
        group.grayed = false;

        if (!this.isNullOrUndefined(group.children) && group.children.length > 0) {
            const length = group.children.length;
            for (let i = 0; i < length; i++) {
                const child = group.children[i];
                this.addUserToGroup(child, false);
            }
        }

        if (updatePerents === true) {
            let parent = group.parent;
            while (!this.isNullOrUndefined(parent)) {
                parent.visable = true;
                parent.grayed = true;
                parent = parent.parent;
            }
        }
    }

    private removeUserToGroup(group: GroupModel, updateChildren: boolean = true): void {
        this._groupsHaveChanges = true;

        group.visable = false;
        group.grayed = true;

        if (updateChildren === true && !this.isNullOrUndefined(group.children) && group.children.length > 0) {
            const length = group.children.length;
            for (let i = 0; i < length; i++) {
                const child = group.children[i];
                this.removeUserToGroup(child);
            }
        }

        if (!this.isNullOrUndefined(group.parent) && !group.parent.children.some(i => i.visable === true)) {
            this.removeUserToGroup(group.parent, false);
        }
    }
}
