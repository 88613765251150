import { Component, Injector, OnInit, HostBinding } from '@angular/core';
import { ScheduleService } from '@em/service/data/schedule/Schedule.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';

@Component({
    selector: 'em-settings-schedules',
    templateUrl: './Settings.Schedules.Component.html',
    styleUrls: ['./Settings.Schedules.Component.scss']
})
export class SettingsSchedulesComponent extends BaseComponent implements OnInit {
    public static className: string = 'SettingsSchedulesComponent';

    public getSchedulesProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'em-settings-schedules';

    public constructor(
        private readonly _scheduleService: ScheduleService,
        private readonly _injector: Injector) {
        super(_injector);

        this.getSchedulesProcess = this.processMonitorService.getProcess(SettingsSchedulesComponent.className, 'Getting schedules.');

        this.addSubscription(this.observableHandlerBase(this._scheduleService.getAllScheduleOverviews(this.getSchedulesProcess), this.getSchedulesProcess).subscribe(), this.getSchedulesProcess);
    }

    public ngOnInit(): void {
        super.ngOnInit();

    }
}
