<div #mainContent
    id="rift-config-history"
    class="main-content scroll-content">
    <div fxLayout="column">
        <mat-card class="spacing-bottom"
            fxFlex>
            <mat-card-title>
                <div fxLayout="row">
                    <div fxLayout="column">
                        <div>
                            Config History ({{ changeCount }})
                        </div>
                        <div class="loading-in-background-text"
                            *ngIf="isLoadingLogs === true">
                            Loading data in the background.
                        </div>
                    </div>
                </div>
            </mat-card-title>
            <mat-card-content>
                <div fxLayout="row">
                    <div fxLayout="column">
                        <div fxLayout="row">
                            <button (click)="showAllSections(sectionVMs)"
                                class="spacing-right"
                                mat-icon-button>
                                <shr-fa name="eye"
                                    matTooltip="Show All"></shr-fa>
                            </button>
                            <button (click)="hideAllSections(sectionVMs)"
                                mat-icon-button>
                                <shr-fa name="eye-slash"
                                    matTooltip="Hide All"></shr-fa>
                            </button>
                            <div fxFlex></div>
                            <mat-paginator (page)="pageChange($event)"
                                [length]="changeCount"
                                [pageSizeOptions]="[5]">
                            </mat-paginator>
                        </div>
                        <div fxLayout="row">
                            <form [formGroup]="searchFormGroup">
                                <mat-form-field class="spacing-right">
                                    <input [owlDateTimeTrigger]="fromDateSelect"
                                        [owlDateTime]="fromDateSelect"
                                        matInput
                                        formControlName="fromDate"
                                        placeholder="From">
                                    <mat-error>{{getFormControlErrorMessage(searchFormGroup.controls['fromDate'])}}
                                    </mat-error>
                                </mat-form-field>
                                <owl-date-time #fromDateSelect
                                    pickerType="calendar"></owl-date-time>
                                <mat-form-field class="spacing-right">
                                    <input [owlDateTimeTrigger]="toDateSelect"
                                        [owlDateTime]="toDateSelect"
                                        matInput
                                        formControlName="toDate"
                                        placeholder="To">
                                    <mat-error>{{getFormControlErrorMessage(searchFormGroup.controls['toDate'])}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="spacing-right">
                                    <input matInput
                                        placeholder="Setting Value"
                                        formControlName="keyValue">
                                    <mat-error>{{getFormControlErrorMessage(searchFormGroup.controls['toDate'])}}
                                    </mat-error>
                                </mat-form-field>
                                <owl-date-time #toDateSelect
                                    pickerType="calendar"></owl-date-time>
                                <mat-checkbox class="spacing-right"
                                    [checked]="includeUnChanged"
                                    (change)="includeUnChangedChanged($event)">Unchanged</mat-checkbox>
                                <button class="spacing-right"
                                    (click)="clearSearch()"
                                    mat-icon-button>
                                    <mat-icon matTooltip="Clear">clear</mat-icon>
                                </button>
                                <button class="spacing-right"
                                    [disabled]="searchFormGroup.valid === false"
                                    (click)="search()"
                                    mat-icon-button>
                                    <mat-icon matTooltip="search">search</mat-icon>
                                </button>
                            </form>
                        </div>
                        <div fxLayout="row">
                            <rift-config-history-section-select (sectionShowChanged)="sectionShowChanged()"
                                [sectionVMs]="sectionVMs"
                                showFirstLastButtons="true">
                            </rift-config-history-section-select>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card fxFlex>
            <mat-card-content>
                <div class="config-changes">
                    <div class="change"
                        *ngFor="let configChange of changes | async">
                        <div class="date-time"> Config Change at {{ DateTimeUtility.toShortDateLongTime(configChange.toDate) }} </div>
                        <div class="details"
                            *ngFor="let childSectionVM of sectionVMs">
                            <rift-config-history-section [sectionVM]="childSectionVM"
                                [sectionChange]="getSection(childSectionVM.section, configChange.sectionsChanged)">
                            </rift-config-history-section>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>