import { AnyUtility } from '@shared/utility/Any.Utility';
import { isDate, isNull, isNullOrUndefined } from '@shared/utility/General.Utility';
import { ObjectUtility } from '@shared/utility/Object.Utility';

type MinMaxTypes = number | Date;
type MinMaxTypesPropertyConstraint<TItem> = { [Key in keyof TItem]: TItem[Key] extends MinMaxTypes ? Key : never }[keyof TItem];

/**
 * Helper methods for array.
 *
 * @export
 * @class ArrayUtility
 */
export class ArrayUtility {

    /**
     * Checks to see if arrayA and ArrayB are equal.
     *
     * @static
     * @template TItem The type of the item in the array.
     * @param {Array<TItem>} arrayA The A array to compare.
     * @param {Array<TItem>} arrayB The B array to compare.
     * @returns {boolean} True if arrayA and ArrayB are equal. else false.
     * @memberof ArrayUtility
     */
    public static equal<TItem>(arrayA: TItem[], arrayB: TItem[]): boolean {
        return ObjectUtility.equal(arrayA, arrayB);
    }

    public static count<T>(array: T[], callbackfn: (value: T, index: number, array: T[]) => boolean): number {
        let count = 0;

        if (!isNullOrUndefined(array) && array.length > 0) {
            const length = array.length;
            for (let i = 0; i < length; i++) {
                if (callbackfn(array[i], i, array)) {
                    count++;
                }
            }
        }

        return count;
    }

    /**
     * Fills and array with values.
     * When array is null or undefined new array is initialized.
     *
     * @static
     * @template TItem The type of the item in the array.
     * @param {TItem} value The value to fill the array with.
     * @param {number} [length] The length to fill the array to.
     * @param {Array<TItem>} [array] The array to fill.
     * @param {number} [startIndex] The index to start filling values at.
     * @param {number} [endIndex] The index to stop filling values at.
     * @returns {Array<TItem>} The filled array.
     * @memberof ArrayUtility
     */
    public static fill<TItem>(value: TItem, array?: TItem[], length?: number, startIndex?: number, endIndex?: number): TItem[] {
        if (isNullOrUndefined(array)) {
            array = [];
        }
        const start = (isNullOrUndefined(startIndex) ? 0 : startIndex);

        const lengthIsNullOrUndefined = isNullOrUndefined(length);
        const startIndexIsNullOrUndefined = isNullOrUndefined(startIndex);
        const endIndexIsNullOrUndefined = isNullOrUndefined(endIndex);

        const end = lengthIsNullOrUndefined && startIndexIsNullOrUndefined && !endIndexIsNullOrUndefined ? endIndex :
            lengthIsNullOrUndefined && !startIndexIsNullOrUndefined && !endIndexIsNullOrUndefined ? endIndex - startIndex :
                !lengthIsNullOrUndefined && !startIndexIsNullOrUndefined && endIndexIsNullOrUndefined ? startIndex + length :
                    !lengthIsNullOrUndefined && startIndexIsNullOrUndefined && !endIndexIsNullOrUndefined ? endIndex + length :
                        lengthIsNullOrUndefined && startIndexIsNullOrUndefined && endIndexIsNullOrUndefined ? array.length :
                            !lengthIsNullOrUndefined && startIndexIsNullOrUndefined && endIndexIsNullOrUndefined ? length :
                                !lengthIsNullOrUndefined && !startIndexIsNullOrUndefined && !endIndexIsNullOrUndefined ? (startIndex + length) > endIndex ? endIndex : startIndex + length :
                                    0;

        for (let i = start; i < end; i++) {
            array[i] = value;
        }

        return array;
    }

    /**
     * Determines whether the specified array has null or undefined indexes.
     *
     * @static
     * @template TItem The type of the item in the array.
     * @param {Array<TItem>} array The array to check for null or undefined indexes.
     * @returns {boolean} True if the array has null or undefined indexes. else false.
     * @memberof ArrayUtility
     */
    public static someIsNullOrUndefined<TItem>(array: TItem[], length: number): boolean {
        if (!isNullOrUndefined(array)) {
            for (let i = 0; i < length; i++) {
                if (isNullOrUndefined(array[i])) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Flattens a nested array with children.
     *
     * @static
     * @template T The type of the item in the array.
     * @param {T[]} into The array to flatten into.
     * @param {(T | T[])} node he root of the nesting.
     * @param {string} childrenKey The name of the child property.
     * @returns {any[]} The flattened array.
     * @memberof ArrayUtility
     */
    public static flatten<T>(into: T[], node: T | T[], childrenKey: string = 'children'): T[] {
        if (isNull(node)) { return into; }
        if (Array.isArray(node)) {
            return node.reduce((i: T[], n: T | T[]) => this.flatten(i, n, childrenKey), into);
        } else {
            into.push(node);
        }
        if (!isNullOrUndefined(node[childrenKey])) {
            return this.flatten(into, node[childrenKey], childrenKey);
        } else {
            return into;
        }
    }

    /**
     * Gets item from an array with distinct property values.
     *
     * @static
     * @template TItem The type of the item in the array.
     * @param {TItem[]} array The array to get items with distinct property values.
     * @param {keyof TItem} propertyName The name of the property to check for distinctness.
     * @returns {Array<TItem>} The items with distinct property values.
     * @memberof ArrayUtility
     */
    public static distinct<TItem>(array: TItem[], propertyName?: keyof TItem): TItem[] {
        if (isNullOrUndefined(array)) {
            return [];
        }

        const res = [];
        const arrLength = array.length;
        for (let iA = 0; iA < arrLength; iA++) {
            const itemA = array[iA];
            const resLength = res.length;

            if (resLength === 0) {
                res.push(itemA);
            } else {
                let found = false;
                for (let iB = 0; iB < resLength; iB++) {
                    const itemB = res[iB];
                    if (isNullOrUndefined(propertyName) ? itemA === itemB : AnyUtility.equal(itemA[propertyName], itemB[propertyName])) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    res.push(itemA);
                }
            }
        }
        return res;
    }

    /**
     * get item at index if item is null or undefined moves backwards returning first non null or undefined item.
     *
     * @static
     * @template T
     * @param {T[]} arr
     * @param {number} index
     * @returns {T}
     * @memberof ArrayUtility
     */
    public static previousNotNullOrUndefined<T>(arr: T[], index?: number): T {
        if (isNullOrUndefined(arr)) {
            return null;
        }

        const indexOf = ArrayUtility.indexOfPreviousNotNullOrUndefined(arr, index);
        if (indexOf !== -1) {
            return arr[indexOf];
        }
        return null;
    }

    /**
     * Gets the index of array if its not {isNullOrUndefined}.
     * Or the first previous index that is not {isNullOrUndefined}.
     *
     * @static
     * @template TItem The type of the item in the array.
     * @param {Array<TItem>} array The array to get the index from.
     * @param {number} index The index to start at.
     * @returns {number} The {isNullOrUndefined} index.
     * @memberof ArrayUtil
     */
    public static indexOfPreviousNotNullOrUndefined<T>(array: T[], index?: number): number {
        if (isNullOrUndefined(array)) {
            return -1;
        }

        const length = array.length;
        for (let i = isNullOrUndefined(index) ? length : index; i >= 0; i--) {
            if (!isNullOrUndefined(array[i])) {
                return i;
            }
        }
        return -1;
    }

    /**
     * get item at index if item is null or undefined moves forwards returning first non null or undefined item.
     *
     * @static
     * @template T
     * @param {T[]} arr
     * @param {number} index
     * @returns {T}
     * @memberof ArrayUtility
     */
    public static nextNotNullOrUndefined<T>(arr: T[], index?: number): T {
        if (isNullOrUndefined(arr)) {
            return null;
        }

        const indexOf = ArrayUtility.indexOfNextNotNullOrUndefined(arr, index);
        if (indexOf !== -1) {
            return arr[indexOf];
        }
        return null;
    }

    /**
     * Gets the index of array if its not {isNullOrUndefined}.
     * Or the first next index that is not {isNullOrUndefined}.
     *
     * @static
     * @template TItem The type of the item in the array.
     * @param {Array<TItem>} array The array to get the index from.
     * @param {number} index The index to start at.
     * @returns {number} The {isNullOrUndefined} index.
     * @memberof ArrayUtility
     */
    public static indexOfNextNotNullOrUndefined<T>(array: T[], index?: number): number {
        if (isNullOrUndefined(array)) {
            return -1;
        }

        const length = array.length;
        for (let i = isNullOrUndefined(index) ? 0 : index; i < length; i++) {
            if (!isNullOrUndefined(array[i])) {
                return i;
            }
        }
        return -1;
    }

    public static closestNotNullOrUndefined<T>(arr: T[], index?: number): T {
        if (isNullOrUndefined(arr)) {
            return null;
        }

        const closestIndex = ArrayUtility.indexOfClosestNotNullOrUndefined(arr, index);
        if (closestIndex !== -1) {
            return arr[closestIndex];
        }
        return null;
    }

    /**
     * Finds the closest array index that is not null or undefined.
     *
     * @static
     * @template T
     * @param {T[]} arr The array to search.
     * @param {number} index The target index.
     * @returns {number} The closest index.
     * @memberof ArrayUtility
     */
    public static indexOfClosestNotNullOrUndefined<T>(arr: T[], index?: number): number {
        if (isNullOrUndefined(arr)) {
            return -1;
        }

        const arrLength = arr.length;
        if (!(arr) || arrLength === 0) {
            return -1;
        }
        if (arrLength === 1) {
            return 0;
        }
        if (isNullOrUndefined(index)) {
            index = 0;
        }
        if (!isNullOrUndefined(arr[index])) {
            return index;
        }

        const firstNullOrUndefinedIndex = this.indexOfPreviousNotNullOrUndefined(arr, index);
        const lastNullOrUndefinedIndex = this.indexOfNextNotNullOrUndefined(arr, index);

        if (firstNullOrUndefinedIndex !== -1 && lastNullOrUndefinedIndex === -1) {
            return firstNullOrUndefinedIndex;
        } else if (firstNullOrUndefinedIndex === -1 && lastNullOrUndefinedIndex !== -1) {
            return lastNullOrUndefinedIndex;
        }

        const firstGap = Math.abs(firstNullOrUndefinedIndex - index);
        const lastGap = Math.abs(lastNullOrUndefinedIndex - index);

        return firstGap > lastGap ? lastNullOrUndefinedIndex : firstNullOrUndefinedIndex;
    }

    /**
     * Finds the first item in the array where value is the smaller difference to target.
     *
     * @static
     * @template T The item type of the array.
     * @param {T[]} array The array.
     * @param {MinMaxTypesPropertyConstraint<TItem>} propertyName The property name to inspect. or null if type array
     * @param {MinMaxTypes} propertyValue The value to match.
     * @returns {number} The index of the matched item or -1 if no match.
     * @memberof ArrayUtility
     */
    public static indexOfClosestValue<TItem extends object | MinMaxTypes>(array: TItem[], propertyName: MinMaxTypesPropertyConstraint<TItem>, propertyValue: MinMaxTypes): number {
        if (isNullOrUndefined(array)) {
            return -1;
        }

        const target = isDate(propertyValue) ? propertyValue.valueOf() : propertyValue;

        if (!(array) || array.length === 0) {
            return -1;
        }
        if (array.length === 1) {
            return 0;
        }

        const length = array.length;
        for (let i = 1; i < length; i++) {
            if (((isNullOrUndefined(propertyName) ? array[i] : array[i][propertyName]) as any) > target) {
                const p = array[i - 1];
                const c = array[i];
                return Math.abs(((isNullOrUndefined(propertyName) ? p : p[propertyName]) as any) - target) < Math.abs(((isNullOrUndefined(propertyName) ? c : c[propertyName]) as any) - target) ? i - 1 : i;
            }
        }

        return length - 1;
    }

    /**
     * Gets the index to insert a new value into a sorted array.
     *
     * @static
     * @template T The type of the array.
     * @param {Array<TItem>} array The array.
     * @param {MinMaxTypesPropertyConstraint<TItem>} propertyName The name of the property.
     * @param {number} propertyValue The value to compare to the property.
     * @returns
     * @memberof ArrayUtility
     */
    public static sortedIndex<TItem extends object | MinMaxTypes>(array: TItem[], propertyName: MinMaxTypesPropertyConstraint<TItem>, propertyValue: MinMaxTypes): number {
        if (isNullOrUndefined(array)) {
            return -1;
        }

        let low = 0;
        let high = array.length;

        while (low < high) {
            // tslint:disable-next-line:no-bitwise
            const mid = low + high >>> 1;
            if (array[mid][propertyName.valueOf()] < propertyValue) {
                low = mid + 1;
            } else {
                high = mid;
            }
        }

        return low;
    }

    /**
     * Gets the index of the 'key' or array element with the min value.
     * If there are multiple min values the min value with the lowest index will be returned.
     *
     * @static
     * @template TItem The type of the item in the array.
     * @param {Array<TItem>} array The array to find the min value index in.
     * @param {MinMaxTypesPropertyConstraint<TItem>} [propertyName] The name of the property to find min value of. Or null undefined for value array.
     * @returns {number} The index of the min value.
     * @memberof ArrayUtility
     */
    public static indexOfMin<TItem extends object | MinMaxTypes>(array: TItem[], propertyName?: MinMaxTypesPropertyConstraint<TItem>): number {
        if (isNullOrUndefined(array)) {
            return -1;
        }

        let min: number = Number.MAX_SAFE_INTEGER;
        let index = -1;
        const length = array.length;
        for (let i = 0; i < length; i++) {
            const value = Number(isNullOrUndefined(propertyName) ? array[i] : array[i][propertyName]);
            if (value < min) {
                min = value;
                index = i;
            }
        }
        return index;
    }

    /**
     * Gets the index of the 'key' or array element with the max value.
     * If there are multiple max values the max value with the lowest index will be returned.
     *
     * @static
     * @template TItem The type of the item in the array.
     * @param {Array<TItem>} array The array to find the max value index in.
     * @param {MinMaxTypesPropertyConstraint<TItem>} [propertyName] The name of the property to find max value of. Or null undefined for value array.
     * @returns {number} The index of the max value.
     * @memberof ArrayUtility
     */
    public static indexOfMax<TItem extends object | MinMaxTypes>(array: TItem[], propertyName?: MinMaxTypesPropertyConstraint<TItem>): number {
        if (isNullOrUndefined(array)) {
            return -1;
        }

        let max: number = Number.MIN_SAFE_INTEGER;
        let index = -1;
        const length = array.length;
        for (let i = 0; i < length; i++) {
            const value = Number(isNullOrUndefined(propertyName) ? array[i] : array[i][propertyName]);
            if (value > max) {
                max = value;
                index = i;
            }
        }
        return index;
    }

    /**
     * Gets the value of the 'key' or array element with the min value.
     * If there are multiple min values the min value with the lowest index will be returned.
     *
     * @static
     * @template TItem The type of the item in the array.
     * @param {Array<TItem>} array The array to find the min value index in.
     * @param {MinMaxTypesPropertyConstraint<TItem>} [propertyName] The name of the property to find min value of. Or null undefined for value array.
     * @returns {TItem} The item from the array.
     * @memberof ArrayUtility
     */
    public static min<TItem extends object | MinMaxTypes>(array: TItem[], propertyName?: MinMaxTypesPropertyConstraint<TItem>): TItem {
        if (isNullOrUndefined(array)) {
            return null;
        }

        const index = this.indexOfMin(array, propertyName);
        return index !== -1 ? array[index] : null;
    }

    /**
     * Gets the value of the 'key' or array element with the max value.
     * If there are multiple max values the max value with the lowest index will be returned.
     *
     * @static
     * @template TItem The type of the item in the array.
     * @param {Array<TItem>} array The array to find the max value index in.
     * @param {MinMaxTypesPropertyConstraint<TItem>} [propertyName] The name of the property to find max value of. Or null undefined for value array.
     * @returns {TItem} The item from the array.
     * @memberof ArrayUtility
     */
    public static max<TItem extends object | MinMaxTypes>(array: TItem[], propertyName?: MinMaxTypesPropertyConstraint<TItem>): TItem {
        if (isNullOrUndefined(array)) {
            return null;
        }

        const index = this.indexOfMax(array, propertyName);
        return index !== -1 ? array[index] : null;
    }
}
