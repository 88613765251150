import { FOVCoveragePointModel, IFOVCoveragePointModel } from '@rift/models/restapi/FOVCoveragePoint.Model';
import { GVectorModel, IGVectorModel } from '@rift/models/restapi/GVector.Model';
import { IVideoCroppingWindowModel, VideoCroppingWindowModel } from '@rift/models/restapi/VideoCroppingWindow.Model';
import { getConfigBooleanValue } from '@rift/shared/getConfigBooleanValue';
import { IRegisterConfig } from '@rift/shared/IRegisterConfig';
import { RegisterTypeConfig } from '@rift/shared/Settings.RegisterConfig';
import { BaseModel } from '@shared/base/Base.Model';
import { DeviceInfo } from '@shared/component/deviceinfotooltip/DeviceInfoTooltip.Component';
import { AutoHeightDetectionStateEnum } from '@shared/enum/AutoHeightDetectionState.Enum';
import { DeviceCapabilitiesEnum, DeviceCapabilitiesEnumHelpers } from '@shared/enum/DeviceCapabilities.Enum';
import { DeviceLensTypeEnum, DeviceLensTypeEnumHelpers } from '@shared/enum/DeviceLensType.Enum';
import { MasterModeEnum, MasterModeEnumHelpers } from '@shared/enum/MasterMode.Enum';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { TimeOfFlightFrameRateEnum, TimeOfFlightFrameRateEnumHelpers } from '@shared/enum/TimeOfFlightFrameRate.Enum';
import { UnitGenerationEnum, UnitGenerationEnumHelpers } from '@shared/enum/UnitGeneration.Enum';
import { UnitTypeEnum, UnitTypeEnumHelpers } from '@shared/enum/UnitType.Enum';
import { Dictionary, IDictionary } from '@shared/generic/Dictionary';
import { IRestModel } from '@shared/interface/IRestModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

import { AssignedIPInformationModel } from './AssignedIPInformation.Model';

export interface IDeviceModel extends IRestModel {
    capabilities: IDictionary<number, boolean>;
    height: number;
    innerCoverage: Array<IFOVCoveragePointModel>;
    ipVersion: string;
    lensType: DeviceLensTypeEnum;
    mainImageVersion: string;
    master: boolean;
    outerCoverage: Array<IFOVCoveragePointModel>;
    productID: string;
    safeImageVersion: string;
    serialNumber: string;
    ubootVersion: string;
    unitGen: number;
    unitType: UnitTypeEnum;
    upTime: number;
    videoCroppingWindow: IVideoCroppingWindowModel;
    videoDevice: boolean;
    videoOffsets: Array<number>;
    x: number;
    y: number;
    gVector: IGVectorModel;
    videoSerialNumber: string;
    isLocalUnit: boolean;
    masterMode: MasterModeEnum;
    timeOfFlightFrameRate: TimeOfFlightFrameRateEnum;
}

export class DeviceModel extends BaseModel implements IDeviceModel, IRestModel {
    public autoHeightEnabled: boolean = null;
    public autoHeightMessage: string = null;
    public autoHeightState: AutoHeightDetectionStateEnum = null;
    public capabilities: Dictionary<DeviceCapabilitiesEnum, boolean> = null;
    public error: string = null;
    public getAutoHeightCompleted: boolean = false;
    public gVector: GVectorModel = null;
    public innerCoverage: Array<FOVCoveragePointModel> = null;
    public ipVersion: string = null;
    public isIPEnabled: boolean = null;
    public isLocalUnit: boolean = null;
    public lensType: DeviceLensTypeEnum = null;
    public mainImageVersion: string = null;
    public master: boolean = null;
    public outerCoverage: Array<FOVCoveragePointModel> = null;
    public outerCoverageScaled: Array<FOVCoveragePointModel> = null;
    public productID: string = null;
    public readonly isIRestModel = true;
    public safeImageVersion: string = null;
    public serialNumber: string = null;
    public setToMaster: boolean = null;
    public statusCode: number = null;
    public ubootVersion: string = null;
    public unitGen: UnitGenerationEnum = null;
    public unitType: UnitTypeEnum = null;
    public upTime: number = null;
    public videoCroppingWindow: VideoCroppingWindowModel = null;
    public videoDevice: boolean = null;
    public videoOffsets: Array<number> = null;
    public videoSerialNumber: string = null;
    public x: number = null;
    public y: number = null;
    public assignedIPInformation: AssignedIPInformationModel = null;
    public masterMode: MasterModeEnum = null;
    public timeOfFlightFrameRate: TimeOfFlightFrameRateEnum = null;

    public _fullProductID: string = null;
    private _height: number = null;
    private _autoHeight: number = null;

    public constructor() {
        super();

        this.registerProperty('x');
        this.registerProperty('y');
        this.registerProperty('height');
        this.registerProperty('autoHeight');
        this.registerProperty('setToMaster');
        this.registerProperty('timeOfFlightFrameRate');
    }

    public get autoHeight(): number {
        return this._autoHeight;
    }
    public set autoHeight(value: number) {
        this._autoHeight = value;
        this._height = 0;
    }

    public get height(): number {
        return this._height;
    }
    public set height(value: number) {
        this._height = value;
        this.autoHeightState = AutoHeightDetectionStateEnum.preset;
    }

    public get fullProductID(): string {
        return this._fullProductID;
    }

    public get deviceInfo(): DeviceInfo {
        return {
            friendlySerial: this.serialNumber,
        };
    }

    public get autoHeightStateVideoOK(): boolean {
        if (this.getAutoHeightCompleted === true) {
            switch (this.autoHeightState) {
                case AutoHeightDetectionStateEnum.notSupported:
                case AutoHeightDetectionStateEnum.none:
                case AutoHeightDetectionStateEnum.preset:
                case AutoHeightDetectionStateEnum.autoValid:
                case AutoHeightDetectionStateEnum.croppedOrientation:
                    return true;
                case AutoHeightDetectionStateEnum.autoInitializing:
                case AutoHeightDetectionStateEnum.autoInvalid:
                case AutoHeightDetectionStateEnum.failedHeight:
                case AutoHeightDetectionStateEnum.failedOrientation:
                case AutoHeightDetectionStateEnum.failedHeightAndOrientation:
                case AutoHeightDetectionStateEnum.unknownState:
                    return false;
                default:
                    return true;
            }
        }
        return true;
    }

    public get actualHeight(): number {
        return this.height !== 0 ? this.height : this.autoHeight;
    }

    public get centerPoint(): { x: number; y: number } {
        return { x: this.x, y: this.y };
    }

    public isCapable(capability: DeviceCapabilitiesEnum): boolean {
        return this.capabilities.get(capability) === true;
    }

    public isGen(gen: UnitGenerationEnum): boolean {
        return this.unitGen === gen;
    }

    public isRegisterSettingCompatible(registerType: RegisterTypeEnum, key: keyof IRegisterConfig): boolean {
        const registerTypeConfig = RegisterTypeConfig[registerType];
        switch (key) {
            case 'hasHeightFiltering':
                return getConfigBooleanValue(registerTypeConfig, 'hasHeightFiltering') && this.isCapable(DeviceCapabilitiesEnum.height);
            case 'hasHeightHistogram':
                return getConfigBooleanValue(registerTypeConfig, 'hasHeightHistogram') && this.isCapable(DeviceCapabilitiesEnum.height);
            case 'hasStaffDetection':
                return getConfigBooleanValue(registerTypeConfig, 'hasStaffDetection') && this.isCapable(DeviceCapabilitiesEnum.markedTargets);
            case 'hasZone':
                return getConfigBooleanValue(registerTypeConfig, 'hasZone') && this.isCapable(DeviceCapabilitiesEnum.zones);
            case 'hasTags':
                return getConfigBooleanValue(registerTypeConfig, 'hasTags') && this.isCapable(DeviceCapabilitiesEnum.registerTags);
            case 'isCustomActionCompatible':
                return getConfigBooleanValue(registerTypeConfig, 'isCustomActionCompatible', true) && this.isCapable(DeviceCapabilitiesEnum.registerCustomActions);
            case 'hasMaxDwell':
                return getConfigBooleanValue(registerTypeConfig, 'hasMaxDwell') && this.isCapable(DeviceCapabilitiesEnum.zones);
            default:
                return getConfigBooleanValue(registerTypeConfig, key);
        }
    }

    public isRegisterTypeCompatible(registerType: RegisterTypeEnum): boolean {
        const registerTypeConfig = RegisterTypeConfig[registerType];
        if (!isNullOrUndefined(registerTypeConfig.requiresDeviceCapabilities)) {
            return registerTypeConfig.requiresDeviceCapabilities.every(rc => this.capabilities.get(rc) === true);
        }
        return true;
    }

    public isType(type: UnitTypeEnum): boolean {
        return this.unitType === type;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'Rift-DeviceModel', ['fullProductID', 'autoHeightMessage', 'capabilities', 'unitGen', 'autoHeight', 'autoHeightState', 'autoHeightEnabled', 'setToMaster']);

        const capabilitiesLength = restModel.Capabilities.length;
        const capabilities = new Dictionary<DeviceCapabilitiesEnum, boolean>();
        for (let i = 0; i < capabilitiesLength; i++) {
            const capability = DeviceCapabilitiesEnumHelpers.fromRestApi(restModel.Capabilities[i]);
            capabilities.addOrUpdate(capability, true);
        }
        this.capabilities = capabilities;

        this.unitGen = UnitGenerationEnumHelpers.fromRestApi(restModel.UnitGen);
        this.unitType = UnitTypeEnumHelpers.fromRestApi(restModel.UnitType);
        this.lensType = DeviceLensTypeEnumHelpers.fromRestApi(restModel.LensType);
        this.masterMode = MasterModeEnumHelpers.fromRestApi(restModel.MasterMode);
        this.timeOfFlightFrameRate = TimeOfFlightFrameRateEnumHelpers.fromRestApi(restModel.TimeOfFlightFrameRate);

        this.gVector = RestModelUtility.loadFrom(restModel.GVector, GVectorModel);
        this.videoCroppingWindow = RestModelUtility.loadFrom(restModel.VideoCroppingWindow, VideoCroppingWindowModel);
        this.innerCoverage = RestModelUtility.loadFromArray(restModel.InnerCoverage, FOVCoveragePointModel);
        this.outerCoverage = RestModelUtility.loadFromArray(restModel.OuterCoverage, FOVCoveragePointModel);
        this.assignedIPInformation = RestModelUtility.loadFrom(restModel.AssignedIPInformation, AssignedIPInformationModel);

        this.setToMaster = this.master;

        this._fullProductID = `${this.productID} (${UnitTypeEnumHelpers.toFullName(this.unitType)})`;

        this.setPropertyOriginalValue('x', this.x);
        this.setPropertyOriginalValue('y', this.y);
        this.setPropertyOriginalValue('height', this.height);
        this.setPropertyOriginalValue('autoHeight', this.autoHeight);
        this.setPropertyOriginalValue('setToMaster', this.setToMaster);
        this.setPropertyOriginalValue('timeOfFlightFrameRate', this.timeOfFlightFrameRate);
    }

    public toRestApiModel(): any {
        return {
            Height: this.height,
            X: this.x,
            Y: this.y,
            YawAngle: this.isCapable(DeviceCapabilitiesEnum.tilt) || this.isCapable(DeviceCapabilitiesEnum.correctiveTilt) ? this.gVector.yaw : undefined,
        };
    }

    public setNullOrUndefinedFromDeviceModel(device: DeviceModel): void {
        if (this.serialNumber === device.serialNumber) {
            if (isNullOrUndefined(this.capabilities)) {
                this.capabilities = device.capabilities;
            }
            if (isNullOrUndefined(this.height)) {
                this.height = device.height;
            }
            if (isNullOrUndefined(this.innerCoverage)) {
                this.innerCoverage = device.innerCoverage;
            }
            if (isNullOrUndefined(this.ipVersion)) {
                this.ipVersion = device.ipVersion;
            }
            if (isNullOrUndefined(this.lensType)) {
                this.lensType = device.lensType;
            }
            if (isNullOrUndefined(this.mainImageVersion)) {
                this.mainImageVersion = device.mainImageVersion;
            }
            if (isNullOrUndefined(this.master)) {
                this.master = device.master;
            }
            if (isNullOrUndefined(this.outerCoverage)) {
                this.outerCoverage = device.outerCoverage;
            }
            if (isNullOrUndefined(this.productID)) {
                this.productID = device.productID;
            }
            if (isNullOrUndefined(this.safeImageVersion)) {
                this.safeImageVersion = device.safeImageVersion;
            }
            if (isNullOrUndefined(this.ubootVersion)) {
                this.ubootVersion = device.ubootVersion;
            }
            if (isNullOrUndefined(this.unitGen)) {
                this.unitGen = device.unitGen;
            }
            if (isNullOrUndefined(this.unitType)) {
                this.unitType = device.unitType;
            }
            if (isNullOrUndefined(this.upTime)) {
                this.upTime = device.upTime;
            }
            if (isNullOrUndefined(this.videoCroppingWindow)) {
                this.videoCroppingWindow = device.videoCroppingWindow;
            }
            if (isNullOrUndefined(this.videoDevice)) {
                this.videoDevice = device.videoDevice;
            }
            if (isNullOrUndefined(this.videoOffsets)) {
                this.videoOffsets = device.videoOffsets;
            }
            if (isNullOrUndefined(this.x)) {
                this.x = device.x;
            }
            if (isNullOrUndefined(this.y)) {
                this.y = device.y;
            }
            if (isNullOrUndefined(this.gVector)) {
                this.gVector = device.gVector;
            }
            if(isNullOrUndefined(this.masterMode)){
                this.masterMode = device.masterMode;
            }
            if(isNullOrUndefined(this.timeOfFlightFrameRate)){
                this.timeOfFlightFrameRate = device.timeOfFlightFrameRate;
            }
        } else {
            throw new Error('Device serial must match');
        }
    }
}
