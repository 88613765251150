<div *ngIf="loadTestComplete" class="em-login" fxLayout="column" (window:keypress)="onKeyDown($event)">
    <div [style.backgroundColor]="backgroundColor" class="header" fxLayout="column" fxLayoutAlign="end center">
        <div fxLayout="row" fxLayoutAlign="center end" class="title">
            <img [src]="'./assets/logo.png'" />
            <div *ngIf="showBaseTitle">{{configurationService.baseTitle}}</div>
        </div>
    </div>
    <div class="content" fxLayout="column" fxLayoutAlign="start center">        
        <mat-card class="details">
            <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
                <form name="login" fxLayout="column" fxLayoutAlign="start stretch">
                    <mat-form-field class="input">
                        <mat-icon matPrefix>person</mat-icon>
                        <input name="username" (keydown)="onKeyDown($event)" matInput [(ngModel)]="loginModel.userName"
                            alt="User name" placeholder="User name" type="text">
                    </mat-form-field>
                    <mat-form-field class="input">
                        <mat-icon matPrefix>lock</mat-icon>
                        <input name="password" (keydown)="onKeyDown($event)" matInput [(ngModel)]="loginModel.password"
                            alt="Password" placeholder="Password" type="password">
                    </mat-form-field>
                </form>
                <span class="error">{{ error }} </span>
            </mat-card-content>
            <mat-card-actions fxLayout="column">               
                <shr-progress-button-spinner [raised]="true" [diameter]="30" text="Login" mode="indeterminate"
                    [active]="loginProcess.isRunning === true" (shrClick)="login()" color="primary">
                </shr-progress-button-spinner>                
            </mat-card-actions>
        </mat-card>
        <div *ngIf="systemAuthOnly === false">
            <br/>
            <br/>
            <br/>

            <shr-progress-button-spinner [raised]="true" [diameter]="30" text="Login with OpenID Connect" mode="indeterminate"
                [active]="preAuthCheckProcess.isRunning === true" (shrClick)="loginWithOpenIDConnect()" color="primary">
            </shr-progress-button-spinner>
        </div>
    </div>
</div>
