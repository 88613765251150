import { Component, HostBinding, Inject, Injector } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { VideoScheduleModel } from '@rift/models/restapi/VideoSchedule.Model';
import { RecordingService } from '@rift/service/data/recording/Recording.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ScheduleTypeEnum } from '@shared/enum/ScheduleType.Enum';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined, isNumber } from '@shared/utility/General.Utility';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, of, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { VideoStorageCapacityModel } from '@rift/models/restapi/VideoStorageCapacity.Model';
import { Guid } from '@shared/utility/Guid.Utility';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';


export class AddScheduleData {
    public constructor(public videoDeviceSerial: string, public inGroupRecording: boolean = false) {
    }
}

export class AddScheduleResult {
    public ok: boolean = false;
    public schedule: VideoScheduleModel;
    public onNodes?: string[];

    public constructor(ok: boolean, schedule?: VideoScheduleModel, onNodes?: string[]) {
        this.ok = ok;
        this.schedule = schedule;
        this.onNodes = onNodes;
    }
}

@Component({
    selector: 'rift-recordings-add-schedule',
    templateUrl: './Recordings.AddSchedule.Component.html',
    styleUrls: ['./Recordings.AddSchedule.Component.scss']
})
export class RecordingsAddScheduleComponent extends RiftBaseComponent implements ILoadDate {
    public static className: string = 'RecordingsAddScheduleComponent';

    @HostBinding()
    public id: string = 'rift-recordings-add-schedule';

    public getAllProcess: ProcessMonitorServiceProcess;

    public timeSetup: TimeSetupModel;
    public durationFormGroup: FormGroup;
    public countsFormGroup: FormGroup;
    public durationType: string = '';
    public schedules: Array<VideoScheduleModel>;
    public showAdvancedOptions: boolean = false;
    public diagData: boolean = false;
    public diagDataCapable: boolean = false;
    public activityOnlyData: boolean = false;
    public activityOnlyDataCapable: boolean = false;
    public nodes: DeviceModel[];
    public selectedNodes: string[] = [];
    public videoStorageCapacity: VideoStorageCapacityModel;
    public masterDevice: DeviceModel = null;

    public constructor(
        private readonly _recordingService: RecordingService,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public readonly data: AddScheduleData,
        private readonly _dialogRef: MatDialogRef<RecordingsAddScheduleComponent>,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess(RecordingsAddScheduleComponent.className, this.loadDataProcessText);
        this.connectedProcess = this.processMonitorService.getProcess(RecordingsAddScheduleComponent.className, 'Connected');
        this.disconnectedProcess = this.processMonitorService.getProcess(RecordingsAddScheduleComponent.className, 'Disconnected');

        this.addSubscription(this.observableHandlerBase(this.connectionService.connected, this.connectedProcess).subscribe(() => this.onConnected()), this.connectedProcess);
        this.addSubscription(this.observableHandlerBase(this.connectionService.disconnected, this.disconnectedProcess).subscribe(() => this.onDisconnected()), this.disconnectedProcess);

        this.durationFormGroup = this._formBuilder.group({
            startDateTime: ['', Validators.compose([Validators.required])],
            hours: [1, Validators.compose([Validators.required, Validators.min(0), Validators.max(23)])],
            minutes: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(59)])],
        });
        this.durationFormGroup.enable();

        this.countsFormGroup = this._formBuilder.group({
            startDateTime: ['', Validators.compose([Validators.required])],
            counts: [200, Validators.compose([Validators.required, Validators.min(1), Validators.max(9999)])],
        });
        this.countsFormGroup.disable();

        this.durationType = 'duration';

        if (this.data.inGroupRecording === true) {
            const dateFuture = new Date();
            dateFuture.setMinutes(dateFuture.getMinutes() + 2);
            this.setStartDateTime(dateFuture);
        }

        this.initConnectionState();
    }

    public static duration(startDateTimeKey: string, hoursKey: string, minutesKey: string, schedules: Array<VideoScheduleModel>): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            if (!isNullOrUndefined(group) && group.enabled === true) {
                const startDateTimeControl: AbstractControl = group.get(startDateTimeKey);
                const hoursControl: AbstractControl = group.get(hoursKey);
                const minutesControl: AbstractControl = group.get(minutesKey);

                if (!isNullOrUndefined(startDateTimeControl) && !isNullOrUndefined(hoursControl) && !isNullOrUndefined(minutesControl) &&
                    DateTimeUtility.isDate(startDateTimeControl.value) && isNumber(hoursControl.value) && isNumber(minutesControl.value)) {
                    const startTime: Date = startDateTimeControl.value;
                    const endTime: Date = new Date(startTime.valueOf() + (hoursControl.value * 60 * 60000) + (minutesControl.value * 60000));
                    let message: string;

                    if (schedules.some(i => {
                        const iStartTime = DateTimeUtility.toTimeZone(i.startTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(i.timezoneOffsetMins));
                        const iEndTime = DateTimeUtility.toTimeZone(i.endTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(i.timezoneOffsetMins));

                        const overlap = !isNullOrUndefined(iStartTime) && !isNullOrUndefined(iEndTime) && (startTime >= iStartTime && startTime <= iEndTime) || (endTime >= iStartTime && endTime <= iEndTime);
                        const countsBefore = (i.type === ScheduleTypeEnum.countBased && (iStartTime <= startTime));

                        if (overlap) {
                            message = 'Schedules cannot overlap';
                        } else if (countsBefore) {
                            message = 'Cannot add after counts based schedule';
                        }

                        return overlap || countsBefore;
                    })) {
                        return { addScheduleDuration: { message } };
                    }

                    return null;
                }
                return null;
            }
            return null;
        };
    }

    public static counts(startDateTimeKey: string, countsKey: string, schedules: Array<VideoScheduleModel>): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            if (!isNullOrUndefined(group) && group.enabled === true) {
                const startDateTimeControl: AbstractControl = group.get(startDateTimeKey);
                const countsControl: AbstractControl = group.get(countsKey);

                if (!isNullOrUndefined(startDateTimeControl) && !isNullOrUndefined(countsControl) && DateTimeUtility.isDate(startDateTimeControl.value) && isNumber(countsControl.value)) {
                    const startTime = startDateTimeControl.value.valueOf();
                    let message: string;

                    if (schedules.some(i => {
                        const iEndTime = isNullOrUndefined(i.endTime) ? null : i.endTime.valueOf();

                        const before = !isNullOrUndefined(iEndTime) && startTime <= iEndTime;
                        const countsBefore = isNullOrUndefined(iEndTime) || (i.type === ScheduleTypeEnum.countBased && (iEndTime <= startTime));

                        if (before) {
                            message = 'Cannot add before another schedule';
                        } else if (countsBefore) {
                            message = 'Cannot add after counts based schedule';
                        }

                        return before || countsBefore;
                    })) {
                        return { addScheduleCounts: { message } };
                    }

                    return null;
                }
                return null;
            }
            return null;
        };
    }

    public nodeSelectionChanged(serialNumber: string): void {
        const index = this.selectedNodes.indexOf(serialNumber);
        if (index === -1) {
            this.selectedNodes.push(serialNumber);
        } else {
            this.selectedNodes.splice(index, 1);
        }

        if (this.selectedNodes.length > 0){
            this.activityOnlyDataCapable = false;
            this.activityOnlyData = false;
        }
        else{
            if (this.masterDevice.capabilities.containsKey(this.DeviceCapabilitiesEnum.activityOnlyRecording)) {
                this.activityOnlyDataCapable = true;
            } else {
                this.activityOnlyDataCapable = false;
            }
        }
    }

    public onTypesSlectionChange(event: MatSelectChange): void {
        if (event.value === 'duration') {
            this.durationFormGroup.enable();
            this.countsFormGroup.disable();
        } else {
            this.selectedNodes.splice(0, this.selectedNodes.length);
            if (this.masterDevice.capabilities.containsKey(this.DeviceCapabilitiesEnum.activityOnlyRecording)) {
                this.activityOnlyDataCapable = true;
            } else {
                this.activityOnlyDataCapable = false;
            }
            this.activityOnlyData = false;
            this.durationFormGroup.disable();
            this.countsFormGroup.enable();
        }
    }

    public cancel(): void {
        this._dialogRef.close(new AddScheduleResult(false));
    }

    public add(): void {
        if ((!this.isNullOrUndefined(this.timeSetup) && !this.isNullOrUndefined(this.timeSetup.timeZone)) || this.data.inGroupRecording === true) {
            const schedule: VideoScheduleModel = new VideoScheduleModel();
            schedule.includeDiagnosticData = this.diagData;

            if (this.data.inGroupRecording === false) {
                schedule.timezoneOffsetMins = this.timeSetup.timeZone.timeOffsetMinutes;
            }
            schedule.captureActivityOnly = this.activityOnlyData;

            schedule.nodes = this.selectedNodes;

            let startTime: Date;
            let endTime: Date;

            switch (this.durationType) {
                case 'duration':
                    const durationFormValues = this.durationFormGroup.value;

                    schedule.type = ScheduleTypeEnum.timeBased;

                    if (this.data.inGroupRecording === true){
                        startTime = DateTimeUtility.fromBrowserToUTC(durationFormValues.startDateTime);
                        endTime = DateTimeUtility.fromBrowserToUTC(new Date(durationFormValues.startDateTime.valueOf() + (durationFormValues.hours * 60 * 60000) + (durationFormValues.minutes * 60000)));
                    }
                    else{
                        startTime = DateTimeUtility.toUTCFromTimeZone(durationFormValues.startDateTime, this.timeSetup.timeZone);
                        endTime = DateTimeUtility.toUTCFromTimeZone(new Date(durationFormValues.startDateTime.valueOf() + (durationFormValues.hours * 60 * 60000) + (durationFormValues.minutes * 60000)), this.timeSetup.timeZone);
                    }

                    schedule.startTime = startTime;
                    schedule.endTime = endTime;

                    break;
                case 'counts':
                    const countsFormValues = this.countsFormGroup.value;

                    if (this.data.inGroupRecording === true){
                        startTime = DateTimeUtility.fromBrowserToUTC(countsFormValues.startDateTime);
                    }
                    else{
                        startTime = DateTimeUtility.toUTCFromTimeZone(countsFormValues.startDateTime, this.timeSetup.timeZone);
                    }

                    schedule.startTime = startTime;
                    schedule.endCount = countsFormValues.counts;

                    schedule.type = ScheduleTypeEnum.countBased;
                    break;
            }

            this._dialogRef.close(new AddScheduleResult(true, schedule, this.selectedNodes));
        }
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        let loadDataSub = zip(
            this.getHostDevice(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.masterDevice = result;

                        if (result.isCapable(DeviceCapabilitiesEnum.diagnosticsDataRecording)) {
                            this.diagDataCapable = true;
                        } else {
                            this.diagDataCapable = false;
                        }

                        if (result.isCapable(DeviceCapabilitiesEnum.activityOnlyRecording)) {
                            this.activityOnlyDataCapable = true;
                        } else {
                            this.activityOnlyDataCapable = false;
                        }
                    }

                    return true;
                })
            ),
            this.deviceService.getNodeDevices().pipe(
                map(nodes => {
                    this.nodes = nodes;
                    return true;
                })
            ),
            this.globalService.getTimeSetup(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.timeSetup = result;

                        if (!this.isNullOrUndefined(this.timeSetup)) {
                            let dateFuture = new Date();
                            dateFuture.setMinutes(dateFuture.getMinutes() + 2);
                            dateFuture = DateTimeUtility.toTimeZone(dateFuture, this.timeSetup.timeZone);

                            this.setStartDateTime(dateFuture);
                        }
                    }

                    return true;
                })
            ),
            this._recordingService.getVideoSchedules(this.data.videoDeviceSerial, process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.schedules = result.schedules;

                        this.durationFormGroup.setValidators(Validators.compose([
                            ValidationValidators.minCombined(['hours', 'minutes'], 1, 'Duration must be at least 1 minute'),
                            RecordingsAddScheduleComponent.duration('startDateTime', 'hours', 'minutes', this.schedules),
                        ]));

                        this.countsFormGroup.setValidators(Validators.compose([
                            RecordingsAddScheduleComponent.counts('startDateTime', 'counts', this.schedules)
                        ]));
                    }

                    return true;
                })
            ),
            this._recordingService.getVideoStorageCapacity(this.data.videoDeviceSerial, process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.videoStorageCapacity = result;
                    }
                    return true;
                })
            )
        ).pipe(
            tap(() => {
                this.durationFormGroup.updateValueAndValidity();
                this.countsFormGroup.updateValueAndValidity();
            })
        );

        if (this.data.inGroupRecording) {
            loadDataSub = of([true, true, true, true, true]);
        }

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onConnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    private setStartDateTime(dateFuter: Date): void {
        this.durationFormGroup.controls.startDateTime.setValue(dateFuter);
        this.durationFormGroup.controls.startDateTime.setValidators(Validators.compose([
            Validators.required,
            ValidationValidators.minDate(dateFuter, 'Date must be in the future')
        ]));

        this.countsFormGroup.controls.startDateTime.setValue(dateFuter);
        this.countsFormGroup.controls.startDateTime.setValidators(Validators.compose([
            Validators.required,
            ValidationValidators.minDate(dateFuter, 'Date must be in the future')
        ]));
    }
}
