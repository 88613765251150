import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { EndPointModel } from '@em/models/restapi/EndPoint.Model';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';


export class NotificationRuleModel extends BaseModel implements IRestModel {
    public endPoints: RestModelChangeTrackerArray<EndPointModel> = new RestModelChangeTrackerArray<EndPointModel>();
    public notificationId: number = null;
    public readonly isIRestModel = true;

    public ruleId: number = null;
    public ruleType: 'AddressBookRule' | 'DeviceRule' | 'AllRule' = null;
    public value1: string = null;
    public value2: string = null;
    public value3: string = null;
    public value4: string = null;
    public value5: string = null;
    public value6: string = null;

    private _ruleTypeText: string = null;

    public constructor() {
        super();

        this.registerProperty('value1');
        this.registerChangeTrackerArray('endPoints');
    }

    public get isDeviceRule(): boolean {
        return this.ruleType === 'DeviceRule';
    }

    public get isGlobalRule(): boolean {
        return this.ruleType === 'AllRule';
    }

    public get isGroupRule(): boolean {
        return this.ruleType === 'AddressBookRule';
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-NotificationRuleModel');

        this.endPoints = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.EndPoints, EndPointModel);

        this.setPropertyOriginalValue('value1', this.value1);
        this.setPropertyOriginalValue('endPoints', this.endPoints);

        if (isNullOrUndefined(this._ruleTypeText)) {
            switch (this.ruleType) {
                case 'AddressBookRule':
                    this._ruleTypeText = 'Group';
                    break;
                case 'DeviceRule':
                    this._ruleTypeText = 'Device';
                    break;
                case 'AllRule':
                    this._ruleTypeText = 'All Devices';
                    break;
            }
        }
    }

    public get ruleTypeText(): string {
        return this._ruleTypeText;
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this),
            EndPoints: RestModelUtility.toJsonArray(this.endPoints),
        };
    }
}
