import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';

export class DeviceLiveDataRemovalModel extends BaseModel implements IRestModel {

    public deviceSerial: string = null;
    public countLogs: boolean = null;
    public histogramLogs: boolean = null;
    public diagnosticLogs: boolean = null;
    public readonly isIRestModel = true;

    public constructor() {
        super();

        this.registerProperty('deviceSerial');
        this.registerProperty('countLogs');
        this.registerProperty('histogramLogs');
        this.registerProperty('diagnosticLogs');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-DeviceLiveDataRemovalModel');

        this.setPropertyOriginalValue('deviceSerial', this.deviceSerial);
        this.setPropertyOriginalValue('countLogs', this.countLogs);
        this.setPropertyOriginalValue('histogramLogs', this.histogramLogs);
        this.setPropertyOriginalValue('diagnosticLogs', this.diagnosticLogs);
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
