<div mat-dialog-title tabindex="1">
    {{ rule.ruleId === -1 ? 'Add' : 'Update'}} Rule
</div>
<div mat-dialog-content
    fxLayout="column">
    <div class="spacing-bottom">Will apply to</div>
    <form [formGroup]="formGroup">
        <ng-template [ngIf]="rule.isGroupRule === true">
            <mat-form-field>
                <em-address-book-auto-complete #groupId
                    formControlName="groupId">
                </em-address-book-auto-complete>
                <span matSuffix>{{ rule.propertyHasChangesText('value1') }}</span>
                <mat-error>{{getFormControlErrorMessage(formGroup.controls['groupId'])}}
                </mat-error>
            </mat-form-field>
        </ng-template>
        <ng-template [ngIf]="rule.isDeviceRule === true">
            <mat-form-field>
                <em-device-autocomplete #friendlySerialNumber
                    formControlName="friendlySerialNumber">
                </em-device-autocomplete>
                <span matSuffix>{{ rule.propertyHasChangesText('value1') }}</span>
                <mat-error>
                    {{getFormControlErrorMessage(formGroup.controls['friendlySerialNumber'])}}
                </mat-error>
            </mat-form-field>
        </ng-template>
        <ng-template [ngIf]="rule.isGlobalRule === true">
            <div class="spacing-bottom">All Devices</div>
        </ng-template>
    </form>
    <div class="spacing-bottom">And be sent to locations</div>
    <ng-template ngFor
        let-endPoint
        [ngForOf]="rule.endPoints"
        let-i="index">
        <div fxLayout="row"
            fxLayoutAlign="start center">
            <ng-template [ngIf]="addingEndPoint === false || (addingEndPoint === true && i < (rule.endPoints.length - 1))">
                <div class="endPoint-row"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    style="min-width: 180px;">
                    {{ endPoint.text }}
                </div>
            </ng-template>
            <ng-template [ngIf]="addingEndPoint === true && i === (rule.endPoints.length - 1)">
                <mat-form-field>
                    <mat-select #selectEndPoint
                        (selectionChange)="addEndPointSelectionChange($event)"
                        placeholder="Location">
                        <mat-option matTooltip="{{newEndPoint?.protocolText}} - {{newEndPoint?.contentTypeText}} - {{newEndPoint?.address}}"
                            *ngFor="let newEndPoint of getUnSelectedEndPoints(rule) | orderBy: 'text'"
                            [value]="newEndPoint">
                            {{newEndPoint?.text}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-template>
            <button mat-icon-button
                [disabled]="true"
                *ngIf="addingEndPoint === true || getUnSelectedEndPoints(rule).length === 0 || i !== (rule.endPoints.length - 1)">
            </button>
            <button matTooltip="Add"
                mat-icon-button
                (click)="addEndPoint(rule)"
                *ngIf="addingEndPoint === false && i === (rule.endPoints.length - 1) && getUnSelectedEndPoints(rule).length > 0">
                <mat-icon>add</mat-icon>
            </button>
            <button *ngIf="rule.endPoints.length > 0"
                matTooltip="Delete"
                mat-icon-button
                (click)="deleteEndPoint(endPoint, rule)"
                color="warn">
                <mat-icon>delete</mat-icon>
            </button>
            <div *ngIf="!isNullOrUndefined(endPoint)"
                fxFlex
                fxLayout="row"
                fxLayoutAlign="start center"
                class="endPoint-row">
                <div>
                    {{ !isNullOrUndefined(endPoint.protocolText) ? endPoint.protocolText + ' > ' : ''}}
                </div>
                <div>
                    {{ !isNullOrUndefined(endPoint.address) ? endPoint.address + ' > ' : '' }}
                </div>
                <div>
                    {{ !isNullOrUndefined(endPoint.contentTypeText) ? endPoint.contentTypeText : ''}}
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template [ngIf]="rule.endPoints.length === 0">
        <div fxLayout="row"
            class="endPoint-row"
            fxLayoutAlign="start center">
            <div fxLayout="row"
                fxLayoutAlign="start center"
                style="min-width: 180px;">
                None Configured
            </div>
            <button matTooltip="Add"
                mat-icon-button
                (click)="addEndPoint(rule)">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </ng-template>
</div>
<div mat-dialog-actions>
    <button [disabled]="!isValid || !hasChangesBase "
        (click)="close()"
        mat-raised-button
        color="warn">{{ rule.ruleId === -1 ? 'Add' : 'Update'}}</button>
    <div fxFlex></div>
    <button (click)="cancel()"
        mat-raised-button
        color="warn">Cancel</button>
</div>