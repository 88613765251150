<div class="counting-setting-content">
    <div *ngIf="isDependent"
        class="error dependent">
        Count mode settings cannot be changed as register is linked to <span *ngFor="let reg of dependentOn">{{reg.registerName}}, </span>
    </div>
    <div fxLayout="row"
        fxLayoutAlign="start stretch"
        style="padding-bottom: 10px;">
        <div fxFlex="50"
            class="spacing-right"
            fxLayout="column">
            <mat-radio-group [disabled]="isReadOnly || isDependent"
                [value]="modeGroup">
                <mat-radio-button (change)="onImmediateGroupChange($event)"
                    [value]="CountModeEnum.immediate">Immediate</mat-radio-button>
            </mat-radio-group>
            <mat-checkbox [disabled]="isReadOnly || isNullOrUndefined(mode) || modeGroup !== CountModeEnum.immediate"
                class="spacing-left"
                (change)="onAntiDitherChange($event)"
                [checked]="mode === CountModeEnum.immediateAntiDither">Anti Dither</mat-checkbox>
        </div>
        <div fxFlex="50"
            fxLayout="column">
            <mat-radio-group [disabled]="isReadOnly || isDependent"
                [value]="modeGroup">
                <mat-radio-button (change)="onDeferredGroupChange($event)"
                    [value]="CountModeEnum.deferred">Deferred</mat-radio-button>
            </mat-radio-group>
            <mat-radio-group [disabled]="isReadOnly || isDependent || isNullOrUndefined(mode) || modeGroup !== CountModeEnum.deferred"
                class="spacing-left"
                [value]="mode"
                (change)="onDeferredChange($event)">
                <mat-radio-button [value]="CountModeEnum.deferred">Ignore U-turns</mat-radio-button>
                <mat-radio-button [value]="CountModeEnum.deferredCountUTurns">Count U-turns</mat-radio-button>
                <mat-radio-button [value]="CountModeEnum.deferredOnlyCountUTurns">Count U-turns only</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>