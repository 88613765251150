<shr-table [hideMatCard]="true" [filterEnabled]="false" [title]="'Upload Task Package (' + uploader?.queue?.length + ')'"
    [dataExportEnabled]="false" [dataSource]="dataSource" [displayedColumns]="displayedColumns">
    <ng-template let-item #shrTableActions>
        <button matTooltip="Upload" mat-icon-button (click)="item.upload(); updateDataSource();"
            [disabled]="item.isReady || item.isUploading || item.isSuccess">
            <mat-icon>cloud_upload</mat-icon>
        </button>
        <button matTooltip="Cancel" mat-icon-button (click)="item.cancel(); updateDataSource();"
            [disabled]="!item.isUploading" color="warn">
            <mat-icon>not_interested</mat-icon>
        </button>
        <button matTooltip="Remove" mat-icon-button (click)="item.remove(); updateDataSource();" color="warn">
            <mat-icon>delete</mat-icon>
        </button>
    </ng-template>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let item">
            {{ item?.file?.name }}
        </td>
    </ng-container>

    <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef> Size </th>
        <td mat-cell *matCellDef="let item">
            <span matTooltip="{{item.durationText}}">{{item?.file?.size/1024/1024 | number:'.2'}} MB</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="progress" style="width: 100px;">
        <th mat-header-cell *matHeaderCellDef> Progress </th>
        <td mat-cell *matCellDef="let item">
            <mat-progress-bar mode="determinate" [value]="item.progress"></mat-progress-bar>
        </td>
    </ng-container>

    <ng-container matColumnDef="status" style="width: 50px;">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let item">
            <mat-icon *ngIf="item.isSuccess">done</mat-icon>
            <mat-icon *ngIf="item.isCancel" color="accent">warning</mat-icon>
            <mat-icon *ngIf="item.isError" color="warn">error</mat-icon>
        </td>
    </ng-container>
</shr-table>

<div class="em-settings-tasklaucher-upload-actions" mat-dialog-actions>
    <input style="display: none;" type="file" accept=".tpg" id="avatar" ng2FileSelect [uploader]="uploader"
        (change)="updateDataSource()" multiple #uploadFiles>
    <button matTooltip="Select Files" mat-raised-button (click)="uploadFiles.click(); updateDataSource();">
        Select Files
    </button>
    <button matTooltip="Upload all" mat-raised-button (click)="uploader.uploadAll(); updateDataSource();"
        [disabled]="!uploader.getNotUploadedItems().length">
        Upload all
    </button>
    <button matTooltip="Cancel all" mat-raised-button (click)="uploader.cancelAll(); updateDataSource();"
        [disabled]="!uploader.isUploading">
        Cancel all
    </button>
    <button matTooltip="Remove all" mat-raised-button (click)="uploader.clearQueue(); updateDataSource();"
        [disabled]="!uploader.queue.length">
        Remove all
    </button>
    <button matTooltip="Close" mat-raised-button (click)="cancel();">
        Close
    </button>
</div>