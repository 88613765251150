import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { ActiveConnectionState } from './ActiveConnectionState.Model';

export class ActiveConnectionModel extends BaseModel implements IRestModel {

    public activeConnectionId: number = null;
    public connectionId: number = null;
    public deviceSerial: string = null;
    public lastConnectionTime: Date = null;
    public lastUpdated: Date = null;
    public activeConnectionState: ActiveConnectionState = null;
    public activeConnectionStateString: string = null;
    public connectionError: string = null;
    public readonly isIRestModel = true;
    public serviceIdentifier: string = null;

    private _lastConnectionTimeText: string = null;

    public constructor() {
        super();
    }

    public get lastConnectionTimeText(): string {
        return this._lastConnectionTimeText;
    }

    public loadFromRestApiModel(restModel: any, upperCase?: boolean): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ActiveConnectionModel', null, upperCase);

        this.lastUpdated = DateTimeNonMomentUtility.fromRestApiDateTime(this.lastUpdated);
        this.lastConnectionTime = DateTimeNonMomentUtility.fromRestApiDateTime(this.lastConnectionTime);

        if (isNullOrUndefined(this._lastConnectionTimeText) || DateTimeUtility.isInvalidDate(this._lastConnectionTimeText)) {
            if (isNullOrUndefined(this.lastConnectionTime)) {
                this._lastConnectionTimeText = 'Never';
            } else if (this.lastConnectionTime.getFullYear() <= 1752) {
                this._lastConnectionTimeText = 'Never';
            } else {
                this._lastConnectionTimeText = DateTimeUtility.toShortDateTime(this.lastConnectionTime);
            }
        }

        switch (this.activeConnectionState) {
            case ActiveConnectionState.Connected:
                this.activeConnectionStateString = 'Connected';
                break;
            case ActiveConnectionState.Connecting:
                this.activeConnectionStateString = 'Connecting';
                break;
            case ActiveConnectionState.Disconnected:
                this.activeConnectionStateString = 'Disconnected';
                break;
            case ActiveConnectionState.Error:
                this.activeConnectionStateString = this.connectionError;
                break;
        }
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
