import { ChangeDetectorRef, Component, ElementRef, HostBinding, Injector, OnDestroy, ViewChild } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { ErrorsAndWarningsModel } from '@rift/models/restapi/ErrorsAndWarnings.Model';
import { GlobalModel } from '@rift/models/restapi/Global.Model';
import { IPSetupModel } from '@rift/models/restapi/IPSetup.Model';
import { UpTimeModel } from '@rift/models/restapi/UpTime.Model';
import { VideoStorageCapacityModel } from '@rift/models/restapi/VideoStorageCapacity.Model';
import { RecordingService } from '@rift/service/data/recording/Recording.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { ILoadDate } from '@shared/interface/ILoadData';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { from, Observable, of, zip } from 'rxjs';
import { flatMap, map, tap, catchError } from 'rxjs/operators';
import { AssignedIPInformationModel } from '@rift/models/restapi/AssignedIPInformation.Model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StringUtility } from '@shared/utility/String.Utility';
import { ConnectionService } from '@rift/service/connection/Connection.Service';

interface IDeviceInfo {
    serial: string;
    isMaster: boolean;
    videoStorageCapacity: VideoStorageCapacityModel;
}

@Component({
    selector: 'rift-summary',
    templateUrl: './Summary.Component.html',
    styleUrls: ['./Summary.Component.scss']
})
export class SummaryComponent extends RiftBaseComponent implements IFillHeight, OnDestroy, ILoadDate {
    public static className: string = 'SummaryComponent';

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @HostBinding()
    public id: string = 'rift-summary';

    public DeviceCapabilitiesEnum = DeviceCapabilitiesEnum;

    public upTime: UpTimeModel;
    public connectedDevice: DeviceModel;
    public diagnosticsCounts: ErrorsAndWarningsModel;
    public global: GlobalModel;
    public ipSetup: IPSetupModel;
    public assignedIpSetup: AssignedIPInformationModel;
    public nodeCount: number;
    public taskLauncherAction: NavBarAction;
    public timeInfo: TimeSetupModel;
    public imageSnapshot: string;
    public dataPollingProcess: ProcessMonitorServiceProcess;
    public getDiagnosticsCountsProcess: ProcessMonitorServiceProcess;
    public deviceInfos: IDeviceInfo[];

    private _dataPollingEvent: DataPollingEvent;

    public constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _dataPollingService: DataPollingService,
        private readonly _recordingService: RecordingService,
        private readonly _connectionService: ConnectionService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.dataPollingProcess = this.processMonitorService.getProcess(SummaryComponent.className, 'Polling Diagnostics Counts');
        this.getDiagnosticsCountsProcess = this.processMonitorService.getProcess(SummaryComponent.className, 'Getting Diagnostics Counts');
        this.loadDataProcess = this.processMonitorService.getProcess(SummaryComponent.className, this.loadDataProcessText);

        this._dataPollingEvent = new DataPollingEvent('SummaryComponent:DiagnosticsCounts', 1000, 20000, this.getDiagnosticsCountsProcess);

        this.initConnectionState();
    }

    public get relaySupportText(): string {
        if (this.isNullOrUndefined(this.global)) {
            return '';
        } else if (this.global.hasCapability(DeviceCapabilitiesEnum.relayIn) && !this.global.hasCapability(DeviceCapabilitiesEnum.relayOut)) {
            return 'Relay input';
        } else if ((this.global.hasCapability(DeviceCapabilitiesEnum.relayIn) && this.global.hasCapability(DeviceCapabilitiesEnum.relayOut)) || this.global.hasCapability(DeviceCapabilitiesEnum.canIo)) {
            return 'Relay input and output';
        } else if (!this.global.hasCapability(DeviceCapabilitiesEnum.relayIn) && this.global.hasCapability(DeviceCapabilitiesEnum.relayOut)) {
            return 'Relay output';
        } else if (!this.global.hasCapability(DeviceCapabilitiesEnum.relayIn) && !this.global.hasCapability(DeviceCapabilitiesEnum.relayOut)) {
            return 'No relay support';
        }
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this.deviceInfos = [];

        const loadDataSub = zip(
            this.getHostDevice(process).pipe(
                flatMap(hostDevice => {
                    if (!this.isNullOrUndefined(hostDevice)) {
                        this.connectedDevice = hostDevice;
                        if (hostDevice.isIPEnabled) {
                            this.assignedIpSetup = hostDevice.assignedIPInformation;

                            return this.globalService.getIpSetup(process).pipe(
                                map(ipSetup => {
                                    this.ipSetup = ipSetup;
                                    return true;
                                })
                            );
                        } else {
                            return of(true);
                        }
                    }
                    return of(true);
                })
            ),
            this.deviceService.getUpTime(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.upTime = result;
                    }
                    return true;
                })
            ),
            this.deviceService.getDevices().pipe(
                flatMap(result => {
                    if (!this.isNullOrUndefined(result)) {
                        return zip(...result.items.map(device => {
                            if (device.master === true && device.isCapable(DeviceCapabilitiesEnum.videoRecording)) {
                                return zip(
                                    this._recordingService.getVideoStorageCapacity(device.serialNumber, process),
                                ).pipe(
                                    map(results => {
                                        const videoStorageCapacity: VideoStorageCapacityModel = results[0] instanceof VideoStorageCapacityModel ? results[0] as VideoStorageCapacityModel : null;

                                        this.deviceInfos.push({
                                            serial: device.serialNumber,
                                            isMaster: device.master,
                                            videoStorageCapacity,
                                        });

                                        return true;
                                    }),
                                );
                            } else {
                                return of(true);
                            }
                        })).pipe(map(() => {
                            this.deviceInfos = this.deviceInfos.sort((a, b) => (a.isMaster === b.isMaster) ? 0 : a.isMaster ? -1 : 1);
                            return true;
                        }));
                    }
                    return of(true);
                })
            ),
            from(this.isDeviceCapable(DeviceCapabilitiesEnum.video)).pipe(
                map(
                    video => {
                        if (video === true) {
                            this.addSubscription(this.globalService.getImageSnapshot(process).subscribe(
                                (result) => {
                                    if (!this.isNullOrUndefined(result) && !StringUtility.isEmptyOrWhiteSpace(result.imageData)) {
                                        this.imageSnapshot = `data:image/jpeg;base64,${result.imageData}`;
                                        this._changeDetectorRef.detectChanges();
                                    }
                                }
                            ));

                        }
                        return true;
                    }
                ),
            ),
            this.globalService.getGlobal(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.global = result;
                    }
                    return true;
                })
            ),
            this.globalService.getTimeSetup(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.timeInfo = result;
                    }
                    return true;
                })
            ),
            this.deviceService.getNodeCount(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.nodeCount = result;
                    }
                    return true;
                })
            ),
        ).pipe(
            tap(() => {
                this.startDataPolling();
                this._changeDetectorRef.detectChanges();
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.stopDataPolling();
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
        this.upTime = null;
        this.diagnosticsCounts = null;
        this.global = null;
        this.nodeCount = null;
        this.timeInfo = null;
        this.ipSetup = null;
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    private getDiagnosticsCounts(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.globalService.getDiagnosticsCounts(process, {disabled: true}).pipe(
            map(result => {
                if (!this.isNullOrUndefined(result)) {
                    this.diagnosticsCounts = result;
                }
                return true;
            }),
            catchError((err: any)=>{
                console.log(err);

                return of(true);
            })
        );
    }

    private subDataPolling(): void {
        this.addSubscription(this._dataPollingEvent.poll.subscribe(() => {
            this.addSubscription(this.getDiagnosticsCounts().subscribe(), this.getDiagnosticsCountsProcess);
        }), this.dataPollingProcess);
    }

    private startDataPolling(): void {
        this.subDataPolling();
        this._dataPollingService.startEvent(this._dataPollingEvent);
    }

    private stopDataPolling(): void {
        this._dataPollingService.stopEvent(this._dataPollingEvent);
    }
}
