import { ConnectionModel } from '@em/models/restapi/Connection.Model';
import { ConnectionGroupModel } from '@em/models/restapi/ConnectionGroup.Model';
import { IViewModel } from '@shared/interface/IViewModel';
import { isString } from '@shared/utility/General.Utility';
import { MatTableDataSource } from '@angular/material/table';

export class GroupViewModel implements IViewModel {
    public connections: Array<ConnectionModel> = [];
    public dataSource = new MatTableDataSource<ConnectionModel>();
    public item: ConnectionGroupModel;

    public constructor(name: string | ConnectionGroupModel) {
        if (isString(name)) {
            this.item = new ConnectionGroupModel();
            this.item.name = name as string;
            this.item.setPropertyOriginalValue('name', name as string);
        } else {
            this.item = name as ConnectionGroupModel;
        }
    }
}
