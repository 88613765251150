import { isFunction } from '@shared/utility/General.Utility';
import { IRequest } from '@shared/webworker/IRequest';

/**
 * Manages the execution of request messages on web worker.
 *
 * @export
 * @class WebWorkerServiceManager
 */
export class WebWorkerServiceManager {
    public service: any;

    /**
     * Handel message received.
     *
     * @param {MessageEvent} message The message that was received.
     * @memberof WebWorkerServiceManager
     */
    public onmessage(message: MessageEvent): void {
        const request = (message.data as IRequest);
        if (isFunction(this.service[request.action])) {
            this.service[request.action](request);
        }
    }
}
