import { MetaDataKeyModel } from '@em/models/restapi/MetaDataKey.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';

export class MetaDataMapModel extends BaseModel implements IRestModel {
    public metaDataKey: MetaDataKeyModel = null;
    public metaDataMapId: number = null;
    public readonly isIRestModel = true;
    public value: string = null;

    public constructor() {
        super();

        this.registerProperty('value');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-MetaDataMapModel');

        this.metaDataKey = RestModelUtility.loadFrom(restModel.MetaDataKey, MetaDataKeyModel);

        this.setPropertyOriginalValue('value', this.value);
    }

    public toRestApiModel(): any {
        return {
            MetaDataMapId: this.metaDataMapId,
            Value: this.value,
            MetaDataKey: RestModelUtility.toJson(this.metaDataKey),
        };
    }
}
