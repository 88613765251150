import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { TranslationsUtility } from '@shared/utility/Translations.Utility';
import { DateTime, Duration, IANAZone, Interval, SystemZone } from 'luxon';
import { HumanizeDuration, HumanizeDurationLanguage } from 'humanize-duration-ts';

export type DateRangeKeys = 'years' | 'quarters' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds';

export class TimeZone {
    public index: number;
    public IANAName: string;
    public supportsDST: boolean;
    public timeOffsetMinutes: number;
    public timeOffsetName: string;
    public timeOffsetNameShort: string;
    public timeOffsetShortText: string;
    public timeOffsetText: string;

    public constructor(id: number, timeOffsetName: string, supportsDST: boolean, momentName?: string) {
        this.index = id;
        this.supportsDST = supportsDST;

        this.timeOffsetName = timeOffsetName;
        this.timeOffsetNameShort = this.timeOffsetName.substring(this.timeOffsetName.indexOf(')') + 1).trim();
        // file deepcode ignore GlobalReplacementRegex: This is string match not regex
        this.timeOffsetText = timeOffsetName.replace('/', ' / ').replace('_', ' ');
        this.timeOffsetShortText = this.timeOffsetText.substring(this.timeOffsetText.indexOf(')') + 1).trim();
        this.IANAName = !isNullOrUndefined(momentName) ? momentName : timeOffsetName.substring(timeOffsetName.indexOf(')') + 1).trim();
        if (this.timeOffsetName === 'Etc/UTC') {
            this.timeOffsetMinutes = 0;
        } else {
            const zone = new IANAZone(this.IANAName);
            this.timeOffsetMinutes = zone.offset(DateTime.now().toMillis());
        }
    }
}

export interface IConvertFormat {
    locale: string;
    format: string;
}

/**
 * Helper methods for Date.
 *
 * @export
 * @class DateTimeUtility
 */
export class DateTimeUtility {
    public static timeZones: Array<TimeZone> = [
        new TimeZone(0, 'Etc/UTC', false),
        new TimeZone(1, '(-12:00:00) Etc/GMT-12', false),
        new TimeZone(2, '(-11:00:00) Pacific/Apia', false),
        new TimeZone(3, '(-10:00:00) Pacific/Honolulu', false),
        new TimeZone(4, '(-09:00:00) America/Anchorage', true),
        new TimeZone(5, '(-08:00:00) America/Los_Angeles', true),
        new TimeZone(6, '(-08:00:00) America/Tijuana', true),
        new TimeZone(7, '(-07:00:00) America/Phoenix', false),
        new TimeZone(8, '(-07:00:00) America/Chihuahua (Mexico)', true, 'America/Chihuahua'),
        new TimeZone(9, '(-07:00:00) America/Denver', true),
        new TimeZone(10, '(-06:00:00) America/Guatemala', false),
        new TimeZone(11, '(-06:00:00) America/Chicago', true),
        new TimeZone(12, '(-06:00:00) America/Mexico_City', true),
        new TimeZone(13, '(-06:00:00) America/Mexico_City', true),
        new TimeZone(14, '(-06:00:00) America/Regina', false),
        new TimeZone(15, '(-05:00:00) America/Bogota', false),
        new TimeZone(16, '(-05:00:00) America/New_York', true),
        new TimeZone(17, '(-05:00:00) America/Indianapolis', false),
        new TimeZone(18, '(-04:30:00) America/Caracas', false),
        new TimeZone(19, '(-04:00:00) America/Asuncion', true),
        new TimeZone(20, '(-04:00:00) America/Halifax', true),
        new TimeZone(21, '(-04:00:00) America/La_Paz', false),
        new TimeZone(22, '(-04:00:00) America/Cuiaba', true),
        new TimeZone(23, '(-04:00:00) America/Santiago', true),
        new TimeZone(24, '(-03:30:00) America/St_Johns', true),
        new TimeZone(25, '(-03:00:00) America/Sao_Paulo', true),
        new TimeZone(26, '(-03:00:00) America/Buenos_Aires', true),
        new TimeZone(27, '(-03:00:00) America/Cayenne', false),
        new TimeZone(28, '(-03:00:00) America/Godthab', true),
        new TimeZone(29, '(-02:00:00) America/Noronha', false),
        new TimeZone(30, '(-01:00:00) Atlantic/Azores', true),
        new TimeZone(31, '(-01:00:00) Atlantic/Cape_Verde', false),
        new TimeZone(32, '(00:00:00) Africa/Casablanca', true),
        new TimeZone(33, '(00:00:00) Europe/London', true),
        new TimeZone(34, '(01:00:00) Europe/Berlin', true),
        new TimeZone(35, '(01:00:00) Europe/Budapest', true),
        new TimeZone(36, '(01:00:00) Europe/Paris', true),
        new TimeZone(37, '(01:00:00) Europe/Warsaw', true),
        new TimeZone(38, '(01:00:00) Africa/Lagos', false),
        new TimeZone(39, '(02:00:00) Asia/Amman', true),
        new TimeZone(40, '(02:00:00) Europe/Bucharest', true),
        new TimeZone(41, '(02:00:00) Asia/Beirut', true),
        new TimeZone(42, '(02:00:00) Europe/Chisinau', true),
        new TimeZone(43, '(02:00:00) Africa/Cairo', true),
        new TimeZone(44, '(02:00:00) Africa/Johannesburg', false),
        new TimeZone(45, '(02:00:00) Europe/Kiev', true),
        new TimeZone(46, '(02:00:00) Asia/Jerusalem', true),
        new TimeZone(47, '(02:00:00) Africa/Windhoek', true),
        new TimeZone(48, '(03:00:00) Asia/Baghdad', true),
        new TimeZone(49, '(03:00:00) Asia/Riyadh', false),
        new TimeZone(50, '(03:00:00) Europe/Moscow', true),
        new TimeZone(51, '(03:00:00) Africa/Nairobi', false),
        new TimeZone(52, '(03:30:00) Asia/Tehran', true),
        new TimeZone(53, '(04:00:00) Asia/Dubai', false),
        new TimeZone(54, '(04:00:00) Asia/Baku', true),
        new TimeZone(55, '(04:00:00) Asia/Yerevan', true),
        new TimeZone(56, '(04:00:00) Indian/Mauritius', true),
        new TimeZone(57, '(04:00:00) Asia/Tbilisi', false),
        new TimeZone(58, '(04:00:00) Asia/Yerevan', true),
        new TimeZone(59, '(04:30:00) Asia/Kabul', false),
        new TimeZone(60, '(05:00:00) Asia/Yekaterinburg', true),
        new TimeZone(61, '(05:00:00) Asia/Karachi', true),
        new TimeZone(62, '(05:00:00) Asia/Tashkent', false),
        new TimeZone(63, '(05:30:00) Asia/Calcutta', false),
        new TimeZone(64, '(05:45:00) Asia/Katmandu', false),
        new TimeZone(65, '(06:00:00) Asia/Novosibirsk', true),
        new TimeZone(66, '(06:00:00) Asia/Almaty', false),
        new TimeZone(67, '(06:00:00) Asia/Colombo', false),
        new TimeZone(68, '(06:30:00) Asia/Rangoon', false),
        new TimeZone(69, '(07:00:00) Asia/Bangkok', false),
        new TimeZone(70, '(07:00:00) Asia/Krasnoyarsk', true),
        new TimeZone(71, '(08:00:00) Asia/Shanghai', false),
        new TimeZone(72, '(08:00:00) Asia/Irkutsk', true),
        new TimeZone(73, '(08:00:00) Asia/Singapore', false),
        new TimeZone(74, '(08:00:00) Australia/Perth', true),
        new TimeZone(75, '(08:00:00) Asia/Taipei', false),
        new TimeZone(76, '(09:00:00) Asia/Tokyo', false),
        new TimeZone(77, '(09:00:00) Asia/Seoul', false),
        new TimeZone(78, '(09:00:00) Asia/Yakutsk', true),
        new TimeZone(79, '(09:30:00) Australia/Adelaide', true),
        new TimeZone(80, '(09:30:00) Australia/Darwin', false),
        new TimeZone(81, '(10:00:00) Australia/Brisbane', false),
        new TimeZone(82, '(10:00:00) Australia/Sydney', true),
        new TimeZone(83, '(10:00:00) Pacific/Port_Moresby', false),
        new TimeZone(84, '(10:00:00) Australia/Hobart', true),
        new TimeZone(85, '(10:00:00) Asia/Vladivostok', true),
        new TimeZone(86, '(11:00:00) Pacific/Guadalcanal', false),
        new TimeZone(87, '(12:00:00) Pacific/Auckland', true),
        new TimeZone(88, '(12:00:00) Pacific/Fiji', false),
        new TimeZone(89, '(13:00:00) Pacific/Tongatapu', false),
    ];
    public static timeZoneUtc: TimeZone = DateTimeUtility.timeZones[0];

    public static fileNameDateTimeFormat = 'ddMMyyHHmmss';

    public static shortDateFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'dd/MM/yy' },
        { locale: 'en-US', format: 'MM/dd/yy' },
    ];
    public static get shortDateFormat(): string {
        return this.getLocalFormat(this.shortDateFormats);
    }

    public static shortDateTimeFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'dd/MM/yy HH:mm' },
        { locale: 'en-US', format: 'MM/dd/yy HH:mm' },
    ];
    public static get shortDateTimeFormat(): string {
        return this.getLocalFormat(this.shortDateTimeFormats);
    }

    public static shortDateLongTimeFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'dd/MM/yy HH:mm:ss' },
        { locale: 'en-US', format: 'MM/dd/yy HH:mm:ss' },
    ];
    public static get shortDateLongTimeFormat(): string {
        return this.getLocalFormat(this.shortDateLongTimeFormats);
    }

    public static shortDateTimeWithSecondsFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'dd/MM/yy HH:mm:ss' },
        { locale: 'en-US', format: 'MM/dd/yy HH:mm:ss' },
    ];
    public static get shortDateTimeFormatWithSeconds(): string {
        return this.getLocalFormat(this.shortDateTimeWithSecondsFormats);
    }

    public static dayMonthShortTimeNoSecondsFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'dd/MM HH:mm' },
        { locale: 'en-US', format: 'MM/dd HH:mm' },
    ];
    public static get dayMonthShortTimeNoSeconds(): string {
        return this.getLocalFormat(this.dayMonthShortTimeNoSecondsFormats);
    }

    public static dayMonthShortTimeSecondsFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'dd/MM HH:mm:ss' },
        { locale: 'en-US', format: 'MM/dd HH:mm:ss' },
    ];
    public static get dayMonthShortTimeSeconds(): string {
        return this.getLocalFormat(this.dayMonthShortTimeSecondsFormats);
    }

    public static dayMonthFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'dd/MM' },
        { locale: 'en-US', format: 'MM/dd' },
    ];
    public static get dayMonth(): string {
        return this.getLocalFormat(this.dayMonthFormats);
    }

    public static longDateTimeFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'MMMM d yyyy, HH:mm:ss' },
    ];
    public static get longDateTimeFormat(): string {
        return this.getLocalFormat(this.longDateTimeFormats);
    }

    public static shortTimeFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'HH:mm' },
    ];
    public static get shortTimeFormat(): string {
        return this.getLocalFormat(this.shortTimeFormats);
    }

    public static longTimeFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'HH:mm:ss' },
    ];
    public static get longTimeFormat(): string {
        return this.getLocalFormat(this.longTimeFormats);
    }

    public static dayOfMonthFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'd' },
    ];
    public static get dayOfMonthFormat(): string {
        return this.getLocalFormat(this.dayOfMonthFormats);
    }

    public static shortMonthNameFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'MMM' },
    ];
    public static get shortMonthName(): string {
        return this.getLocalFormat(this.shortMonthNameFormats);
    }

    public static longMonthNameFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'MMMM' },
    ];
    public static get longMonthName(): string {
        return this.getLocalFormat(this.longMonthNameFormats);
    }

    public static millisecondsToDurationFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'hh:mm:ss.SSS' },
    ];
    public static get millisecondsToDurationFormat(): string {
        return this.getLocalFormat(this.millisecondsToDurationFormats);
    }

    public static millisecondsToDurationShortFormats: IConvertFormat[] = [
        { locale: 'en-GB', format: 'hh:mm:ss' },
    ];
    public static get millisecondsToDurationShortFormat(): string {
        return this.getLocalFormat(this.millisecondsToDurationShortFormats);
    }


    /**
     * Mutates the original date by subtracting time.
     *
     * @static
     * @param {Date} date The date to subtract from
     * @param {number} count The count of key to subtract
     * @param {DateRangeKeys} key The range key
     * @returns {Date}
     * @memberof DateTimeUtility
     */
    public static subtract(date: Date | string, count: number, key: DateRangeKeys): Date {
        const luxon = this.initLuxon(date);

        switch(key){
            case 'days':
                return luxon.minus({days: count}).toJSDate();
            case 'hours':
                return luxon.minus({hours: count}).toJSDate();
            case 'milliseconds':
                return luxon.minus({milliseconds: count}).toJSDate();
            case 'minutes':
                return luxon.minus({minutes: count}).toJSDate();
            case 'months':
                return luxon.minus({months: count}).toJSDate();
            case 'quarters':
                return luxon.minus({quarters: count}).toJSDate();
            case 'seconds':
                return luxon.minus({seconds: count}).toJSDate();
            case 'weeks':
                return luxon.minus({weeks: count}).toJSDate();
            case 'years':
                return luxon.minus({years: count}).toJSDate();
        }
    }

    /**
     * Mutates the original date by adding time.
     *
     * @static
     * @param {Date} date The date to add to
     * @param {number} count The count of key to add
     * @param {DateRangeKeys} key The range key
     * @returns {Date}
     * @memberof DateTimeUtility
     */
    public static add(date: Date | string, count: number, key: DateRangeKeys): Date {
        const luxon = this.initLuxon(date);

        switch(key){
            case 'days':
                return luxon.plus({days: count}).toJSDate();
            case 'hours':
                return luxon.plus({hours: count}).toJSDate();
            case 'milliseconds':
                return luxon.plus({milliseconds: count}).toJSDate();
            case 'minutes':
                return luxon.plus({minutes: count}).toJSDate();
            case 'months':
                return luxon.plus({months: count}).toJSDate();
            case 'quarters':
                return luxon.plus({quarters: count}).toJSDate();
            case 'seconds':
                return luxon.plus({seconds: count}).toJSDate();
            case 'weeks':
                return luxon.plus({weeks: count}).toJSDate();
            case 'years':
                return luxon.plus({years: count}).toJSDate();
        }
    }

    public static isBefore(date: Date | string, subtract: number, key: DateRangeKeys): boolean{
        const initalDate = this.initLuxon(date);

        const compareDate = DateTime.fromJSDate(DateTimeUtility.subtract(DateTime.now().toJSDate(), subtract, key));

        return initalDate < compareDate;
    }

    /**
     * Gets a TimeZone by its timeOffsetNameShort
     *
     * @static
     * @param {string} name The timeOffsetNameShort.
     * @returns {TimeZone} The TimeZone.
     * @memberof DateTimeUtility
     */
    public static getTimeZoneByTimeOffsetNameShort(name: string): TimeZone {
        return DateTimeUtility.timeZones.find(i => i.timeOffsetNameShort === name);
    }

    /**
     * Gets a TimeZone by its timeOffsetMinutes.
     *
     * @static
     * @param {number} minutes The timeOffsetMinutes.
     * @returns {TimeZone} The time zone.
     * @memberof DateTimeUtility
     */
    public static getTimeZoneByTimeOffsetMinutes(minutes: number): TimeZone {
        return DateTimeUtility.timeZones.find(i => !isNullOrUndefined(i) && i.timeOffsetMinutes === minutes);
    }

    /**
     * Gets the date and time of now in sthe specified timezone.
     *
     * @static
     * @param {TimeZone} [timeZone] The time zone to return the current date and time for.
     * @returns {Date} The date and time in the specified timezone.
     * @memberof DateTimeUtility
     */
    public static getDateTimeNow(timeZone?: TimeZone): Date {
        if (!isNullOrUndefined(timeZone)) {
            return DateTimeUtility.toTimeZone(new Date(), timeZone);
        } else {
            return new Date();
        }
    }

    /**
     * Converts a date to the specified timezone
     *
     * @static
     * @param {Date} date The date and time to convert.
     * @param {TimeZone} timeZone The time zone to convert the date to.
     * @returns {Date} The date and time to convert to the specified timezone.
     * @memberof DateTimeUtility
     */
    public static toTimeZone(date: Date | string, timeZone: TimeZone): Date {
        const luxon = this.initLuxon(date);

        const converted = luxon.setZone(timeZone.IANAName);
        const iso = converted.toISO({includeOffset: false});

        const newTime = DateTime.fromISO(iso);

        return newTime.toJSDate();
    }

    public static toBrowserTimeZone(date: Date | string): Date {
        const luxon = this.initLuxon(date);

        const converted = luxon.setZone(new SystemZone());
        const iso = converted.toISO({includeOffset: false});

        const newTime = DateTime.fromISO(iso);

        return newTime.toJSDate();
    }

    public static fromBrowserToUTC(date: Date | string): Date{
        const luxon = this.initLuxon(date);
        const converted = luxon.setZone(new SystemZone(), {keepLocalTime: true});

        return converted.toJSDate();
    }

    /**
     * Converts a date from time zone to UTC.
     *
     * @static
     * @param {Date} date The date and time to convert.
     * @param {TimeZone} fromTimeZone The time zone to convert the date from.
     * @returns {Date} The date and time converted to UTC.
     * @memberof DateTimeUtility
     */
    public static toUTCFromTimeZone(date: Date | string, fromTimeZone: TimeZone): Date {
        const luxon = this.initLuxon(date);
        const adjusted = luxon.setZone(fromTimeZone.IANAName, {keepLocalTime: true});

        return adjusted.toUTC().toJSDate();
    }

    public static toUTC(date: Date | string): Date{
        return DateTimeUtility.toTimeZone(date, DateTimeUtility.timeZoneUtc);
    }

    /**
     * Formats a date and time using timezone
     *
     * @static
     * @param {Date} dateTime The date to format.
     * @param {string} format the format to apply.
     * @param {string} [timeZone] the time zone the dateTime is in.
     * @returns
     * @memberof DateTimeUtility
     */
    public static format(dateTime: Date | string, format: string, timeZone?: TimeZone) {
        let luxon: DateTime;
        if (!isNullOrUndefined(timeZone)) {
            luxon = this.initLuxonWithZone(dateTime, timeZone.IANAName);
        } else {
            luxon = this.initLuxon(dateTime);
        }

        return luxon.toFormat(format);
    }

    public static toISOFormatWithoutOffset(dateTime: Date | string){
        const luxon: DateTime = this.initLuxon(dateTime);

        return luxon.toISO({includeOffset: false, suppressMilliseconds: true});
    }

    /**
     * Converts Date to short date string.
     * DD/MM/YY
     *
     * @static
     * @param {Date} dateTime The Date to convert.
     * @returns {string} The short date
     * @memberof DateTimeUtility
     */
    public static toShortDate(dateTime: Date | string, timeZone?: TimeZone, local?: string): string {
        return DateTimeUtility.format(dateTime, this.getLocalFormat(this.shortDateFormats, local), timeZone);
    }

    /**
     * Converts Date to short date and time string.
     * DD/MM/YY HH:mm
     *
     * @static
     * @param {Date} dateTime The Date to convert.
     * @returns {string} The short date and time.
     * @memberof DateTimeUtility
     */
    public static toShortDateTime(dateTime: Date | string, timeZone?: TimeZone, local?: string): string {
        return DateTimeUtility.format(dateTime, this.getLocalFormat(this.shortDateTimeFormats, local), timeZone);
    }

    public static toDayMonth(dateTime: Date | string, timeZone?: TimeZone, local?: string): string {
        return DateTimeUtility.format(dateTime, this.getLocalFormat(this.dayMonthFormats, local), timeZone);
    }

    /**
     * Converts Date to short date and time to long time.
     * DD/MM/YY HH:mm:ss
     *
     * @static
     * @param {Date} dateTime The Date to convert.
     * @returns {string} The short date and time.
     * @memberof DateTimeUtility
     */
    public static toShortDateLongTime(dateTime: Date | string, timeZone?: TimeZone, local?: string): string {
        return DateTimeUtility.format(dateTime, this.getLocalFormat(this.shortDateLongTimeFormats, local), timeZone);
    }

    /**
     * Converts Date to day of mont e.g. 1st 2nd ... 30th 31st
     *
     * @static
     * @param {Date} dateTime The Date to convert.
     * @param {TimeZone} [timeZone]
     * @returns {string} The day of the moneth.
     * @memberof DateTimeUtility
     */
    public static toDayOfMonth(dateTime: Date | string, timeZone?: TimeZone, local?: string): string {
        return DateTimeUtility.format(dateTime, this.getLocalFormat(this.dayOfMonthFormats, local), timeZone);
    }

    /**
     * Converts Date to short month name e.g. Jan Feb ... Nov Dec
     *
     * @static
     * @param {Date} dateTime The Date to convert.
     * @param {TimeZone} [timeZone]
     * @returns {string} The short month name.
     * @memberof DateTimeUtility
     */
    public static toShortMonthName(dateTime: Date | string, timeZone?: TimeZone, local?: string): string {
        return DateTimeUtility.format(dateTime, this.getLocalFormat(this.shortMonthNameFormats, local), timeZone);
    }

    /**
     * Converts Date to long month name e.g. January February ... November December
     *
     * @static
     * @param {Date} dateTime The Date to convert.
     * @param {TimeZone} [timeZone]
     * @returns {string} The long month name.
     * @memberof DateTimeUtility
     */
    public static toLongMonthName(dateTime: Date | string, timeZone?: TimeZone, local?: string): string {
        return DateTimeUtility.format(dateTime, this.getLocalFormat(this.longMonthNameFormats, local), timeZone);
    }

    /**
     * Converts Date to long date and time string.
     * MMMM Do YYYY, h:mm:ss a
     *
     * @static
     * @param {Date} dateTime
     * @param {string} [timeZone]
     * @returns {string}
     * @memberof DateTimeUtility
     */
    public static toLongDateTime(dateTime: Date | string, timeZone?: TimeZone, local?: string): string {
        return DateTimeUtility.format(dateTime, this.getLocalFormat(this.longDateTimeFormats, local), timeZone);
    }

    /**
     * Converts Date to file name date string.
     * DDMMYYHHmmss
     *
     * @static
     * @param {Date} dateTime The Date to convert.
     * @returns {string} The file name date and time.
     * @memberof DateTimeUtility
     */
    public static toFileNameDateTime(dateTime: Date | string, format?: string): string {
        return DateTimeUtility.format(dateTime, isNullOrUndefined(format) ? DateTimeUtility.fileNameDateTimeFormat : format);
    }

    /**
     * Converts Date to relative string.
     *
     * @static
     * @param {Date} dateTime The Date to convert.
     * @returns {string} The relative string.
     * @memberof DateTimeUtility
     */
    public static toRelativeDateTime(dateTime: Date | string): string {
        return this.initLuxon(dateTime).toRelative();
    }

    public static durationInMinutes(duration: number, unit: DateRangeKeys): number{
        switch(unit){
            case 'days':
                return Duration.fromObject({days: duration}).as('minutes');
            case 'hours':
                return Duration.fromObject({hours: duration}).as('minutes');
            case 'milliseconds':
                return Duration.fromObject({milliseconds: duration}).as('minutes');
            case 'minutes':
                return Duration.fromObject({minutes: duration}).as('minutes');
            case 'months':
                return Duration.fromObject({months: duration}).as('minutes');
            case 'quarters':
                return Duration.fromObject({quarters: duration}).as('minutes');
            case 'seconds':
                return Duration.fromObject({seconds: duration}).as('minutes');
            case 'weeks':
                return Duration.fromObject({weeks: duration}).as('minutes');
            case 'years':
                return Duration.fromObject({years: duration}).as('minutes');
        }
    }

    /**
     * Converts milliseconds to a duration string.
     *
     * @static
     * @param {number} milliseconds The seconds to convert to duration string.
     * @returns {string} The duration string.
     * @memberof DateTimeUtility
     */
    public static millisecondsToDuration(milliseconds: number, local?: string): string {
        return Duration.fromMillis(milliseconds).toFormat(this.getLocalFormat(this.millisecondsToDurationFormats, local));
    }

    /**
     * Converts milliseconds to a short duration string.
     *
     * @static
     * @param {number} milliseconds The seconds to convert to duration string.
     * @returns {string} The duration string.
     * @memberof DateTimeUtility
     */
    public static millisecondsToDurationShort(milliseconds: number, local?: string): string {
        return Duration.fromMillis(milliseconds).toFormat(this.getLocalFormat(this.millisecondsToDurationShortFormats, local));
    }

    public static nowToRelativeDuration(to: Date | string): string {
        return this.initLuxon(to).toRelative();
    }

    /**
     * Converts milliseconds to a relative string.
     *
     * @static
     * @param {number} milliseconds The seconds to convert to relative string.
     * @returns {string} The relative string.
     * @memberof DateTimeUtility
     */
    public static millisecondsToHumanizeDuration(milliseconds: number): string {
        // Have to use external humanize lib at the moment as luxon support
        // is a little poor. If it gets better we'll want to move back to it
        // at a later date.
        const langService: HumanizeDurationLanguage = new HumanizeDurationLanguage();
        const humanizer: HumanizeDuration = new HumanizeDuration(langService);

        return humanizer.humanize(milliseconds, {round: true});
    }

    /**
     * Converts a time span to a relative string.
     *
     * @static
     * @param {string} duration The duration string to convert.
     * @returns {string} The relative string.
     * @memberof DateTimeUtility
     */
    public static timeSpanToRelativeDuration(duration: string): string {
        return this.durationToRelativeDuration(Duration.fromISOTime(duration));
    }

    /**
     * Converts milliseconds to a relative string.
     *
     * @static
     * @param {number} milliseconds The seconds to convert to relative string.
     * @returns {string} The relative string.
     * @memberof DateTimeUtility
     */
    public static millisecondsToRelativeDuration(milliseconds: number): string {
        return this.durationToRelativeDuration(Duration.fromMillis(milliseconds));
    }

    /**
     * Converts seconds to a relative string.
     *
     * @static
     * @param {number} seconds The seconds to convert to relative string.
     * @returns {string} The relative string.
     * @memberof DateTimeUtility
     */
    public static secondsToRelativeDuration(seconds: number): string {
        return this.durationToRelativeDuration(Duration.fromObject({seconds}));
    }

    /**
     * Converts minutes to a relative string.
     *
     * @static
     * @param {number} minutes The minutes to convert to relative string.
     * @returns {string} The relative string.
     * @memberof DateTimeUtility
     */
    public static minutesToRelativeDuration(minutes: number): string {
        return this.durationToRelativeDuration(Duration.fromObject({minutes}));
    }

    /**
     * Converts date to short time HH:mm
     *
     * @static
     * @param {Date} dateTime
     * @returns {*}
     * @memberof DateTimeUtility
     */
    public static toShortTime(dateTime: Date | string, timeZone?: TimeZone, local?: string): string {
        return DateTimeUtility.format(dateTime, this.getLocalFormat(this.shortTimeFormats, local), timeZone);
    }

    /**
     * Converts date to short time HH:mm:ss
     *
     * @static
     * @param {Date} dateTime
     * @returns {*}
     * @memberof DateTimeUtility
     */
    public static toLongTime(dateTime: Date | string, timeZone?: TimeZone, local?: string): string {
        return DateTimeUtility.format(dateTime, this.getLocalFormat(this.longTimeFormats, local), timeZone);
    }

    /**
     * Converts start end dates to duration.
     *
     * @static
     * @param {Date} start
     * @param {Date} end
     * @returns {Duration}
     * @memberof DateTimeUtility
     */
    private static duration(start: Date | string, end: Date | string): Duration {
        if (isNullOrUndefined(start)) {
            start = new Date();
        }
        if (isNullOrUndefined(end)) {
            end = new Date();
        }

        return Interval.fromDateTimes(this.initLuxon(start), this.initLuxon(end)).toDuration();
    }

    /**
     * Converts to dates to duration HH:mm:ss.
     *
     * @static
     * @param {Date} start
     * @param {Date} end
     * @returns {string}
     * @memberof DateTimeUtility
     */
    public static toDuration(start: Date | string, end: Date | string): string {
        const duration: Duration = this.duration(start, end).shiftTo('hours', 'minutes', 'seconds');
        let hours = StringUtility.toString(duration.hours);
        let minutes = StringUtility.toString(duration.minutes);
        let seconds = StringUtility.toString(duration.seconds);

        if (hours.length < 2) {
            hours = `0${hours}`;
        }

        if (minutes.length < 2) {
            minutes = `0${minutes}`;
        }

        if (seconds.length < 2) {
            seconds = `0${seconds}`;
        }

        return `${hours}:${minutes}:${seconds}`;
    }

    /**
     * Get the number of milliseconds between two Date's
     *
     * @static
     * @param {Date} startTime The start date time.
     * @param {Date} endTime The end date time.
     * @returns {number} Number of milliseconds between two Date's
     * @memberof DateTimeUtility
     */
    public static toDurationMilliseconds(startTime: Date | string, endTime: Date): number {
        return this.duration(startTime, endTime).as('milliseconds');
    }

    public static toDurationMinutes(startTime: Date | string, endTime: Date): number {
        return this.duration(startTime, endTime).as('minutes');
    }

    public static toDurationHours(startTime: Date | string, endTime: Date): number {
        return this.duration(startTime, endTime).as('hours');
    }

    public static toDurationDays(startTime: Date | string, endTime: Date): number {
        return this.duration(startTime, endTime).as('days');
    }

    public static toDurationWeeks(startTime: Date | string, endTime: Date): number {
        return this.duration(startTime, endTime).as('weeks');
    }

    public static toDurationMonths(startTime: Date | string, endTime: Date): number {
        return this.duration(startTime, endTime).as('months');
    }


    /**
     * True if date string is Invalid date. else false.
     *
     * @static
     * @param {string} date
     * @returns {boolean}
     * @memberof DateTimeUtility
     */
    public static isInvalidDate(value: string | Date): boolean {
        return value === undefined || value === null || typeof value !== 'object' || typeof value.getTime !== 'function' || isNaN(value.getTime());
    }

    public static getLocalFormat(formats: IConvertFormat[], local?: string): string {
        if (isNullOrUndefined(local)) {
            local = TranslationsUtility.getFirstBrowserLanguageLocal();
        }
        const convertFormat = formats.find(f => f.locale === local);
        if (isNullOrUndefined(convertFormat)) {
            return formats.find(f => f.locale === 'en-GB').format;
        }
        return convertFormat.format;
    }

    public static isDate(date: any): date is Date{
        return date instanceof Date;
    }

    private static durationToRelativeDuration(duration: Duration): string {
        const seconds: number = Math.round(duration.as('seconds'));

        if (seconds > 0) {
            const upTimeMins = seconds / 60;
            const upTimeHours = upTimeMins / 60;
            const upTimeDays = upTimeHours / 24;

            if (upTimeDays >= 1) {
                return upTimeDays.toFixed().toString() + ' days';
            } else if (upTimeHours >= 1) {
                return upTimeHours.toFixed().toString() + ' hours';
            } else if (upTimeMins >= 1) {
                return upTimeMins.toFixed().toString() + ' mins';
            } else {
                return seconds.toString() + ' secs';
            }
        }

        return 'N/A';
    }

    private static initLuxon(date: Date | string): DateTime{
        if (this.isDate(date)){
            return DateTime.fromJSDate(date).setLocale('en-US');
        }

        return DateTime.fromISO(date).setLocale('en-US');
    }

    private static initLuxonWithZone(date: Date | string, zone: string | luxon.Zone): DateTime{
        if (this.isDate(date)){
            return DateTime.fromJSDate(date, {zone}).setLocale('en-US');
        }

        return DateTime.fromISO(date, {zone}).setLocale('en-US');
    }
}
