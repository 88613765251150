/**
 * Comparison Operator
 *
 * @export
 * @enum {number}
 */
export enum ComparisonOperatorEnum {

    /**
     * Contains the value aka *value*
     */
    contains = 0,

    /**
     * Starts with the value, aka value*
     */
    startsWith = 1,

    /**
     * The ends with the value aka *value
     */
    endsWith = 2,

    /**
     * The value is matched exactly
     */
    isExactly = 3,
}
