import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';
import { ModuleStatusModel } from '@em/models/restapi/ModuleStatus.Model';

export class ServiceStatusAndConfigurationModel extends BaseModel implements IRestModel {
    public expectedModuleNames: Array<string> = null;

    public moduleData: Array<ModuleStatusModel> = null;
    public readonly isIRestModel = true;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ServiceStatusAndConfigurationModel');

        this.moduleData = RestModelUtility.loadFromArray(restModel.moduleData, ModuleStatusModel);
        this.expectedModuleNames = restModel.expectedModuleNames;
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
