import { NgZone, Directive } from '@angular/core';
import { JointBase } from '@rift/components/shared/viewport/base/JointBase';
import { SegmentBase } from '@rift/components/shared/viewport/base/SegmentBase';
import { ShapeBase } from '@rift/components/shared/viewport/base/ShapeBase';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

@Directive()
export class Line extends ShapeBase {
    public constructor(
        private readonly _zone: NgZone,
        private readonly _register: any,
        private readonly _loadQueue: ViewPortLoadQueueService,
        private readonly _lineModel: LineModel) {
        super(_zone, true, 16, 2, _register, _loadQueue, _lineModel);
    }

    public get lineModel(): LineModel {
        return this._zone.runOutsideAngular(() => this._lineModel);
    }

    public loadPoints(): void {
        this._zone.runOutsideAngular(() => {
            let lastSegment: SegmentBase = null;
            if (!isNullOrUndefined(this.shapeModel) && !isNullOrUndefined(this.shapeModel.points)) {
                const length = this.shapeModel.points.length;

                for (let index = 0; index < length; index++) {
                    const point = this.shapeModel.points[index];
                    const joint = new JointBase(this._zone, this, this._showArrow, this._loadQueue);
                    joint.x = point.x;
                    joint.y = point.y;

                    if (!isNullOrUndefined(lastSegment)) {
                        lastSegment.toJoint = joint;
                        joint.fromSegment = lastSegment;

                        this.addSegment(lastSegment);
                    }

                    if (index < (length - 1)) {
                        const segment = new SegmentBase(this._zone, this, this._loadQueue, this.shapeModel);
                        segment.fromJoint = joint;
                        joint.toSegment = segment;

                        lastSegment = segment;
                    }

                    this.addJoint(joint);
                }
            }
        });
    }
}
