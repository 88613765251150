import { Component, ElementRef, HostBinding, Inject, Injector, ViewChild, Renderer2 } from '@angular/core';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { GlobalModel } from '@rift/models/restapi/Global.Model';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ValidationReportModel } from '@rift/models/restapi/ValidationReport.Model';
import { CountModel } from '@rift/models/websocket/Count.Model';
import { DbValidationSessionInfoModel } from '@rift/service/validation/models/database/syncsession/IDbValidationSessionInfo.Model';
import { IValidationRecordingModel } from '@rift/service/validation/models/ValidationRecording.Model';
import { ValidationAggregationReport, ValidationReportService } from '@rift/service/validation/Validation.Report.Service';
import { ValidationService } from '@rift/service/validation/Validation.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ValidationSessionStateEnum } from '@shared/enum/ValidationSessionState.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

export class SessionReportDialogData {
    public constructor(
        public readonly showDurationAsTime: boolean,
        public readonly lineReportBucketSize: number,
        public readonly session: DbValidationSessionInfoModel,
        public readonly registers: Array<RegisterBaseModel>,
        public readonly lines: Array<LineModel>,
        public readonly systemCounts: Array<CountModel>,
        public readonly devices: Array<DeviceModel>,
        public readonly timeSetup: TimeSetupModel,
        public readonly globalData: GlobalModel,
        public readonly validationRecording: IValidationRecordingModel) {

    }
}

export class SessionReportDialogResult {

}

@Component({
    selector: 'rift-session-report-dialog',
    templateUrl: './SessionReport.Dialog.Component.html',
    styleUrls: ['./SessionReport.Dialog.Component.scss']
})
export class SessionReportDialogComponent extends BaseComponent implements ILoadDate {
    public static className: string = 'SessionReportDialogComponent';

    @HostBinding()
    public id: string = 'rift-session-report-dialog';

    public session: DbValidationSessionInfoModel;
    public ValidationSessionStateEnum = ValidationSessionStateEnum;
    public aggregationReport: ValidationAggregationReport = null;
    public accuracyReport: ValidationReportModel = null;

    private _userCounts: Array<CountModel> = null;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: SessionReportDialogData,
        private readonly _renderBase: Renderer2,
        private readonly _dialogRef: MatDialogRef<SessionReportDialogComponent>,
        private readonly _validationService: ValidationService,
        private readonly _reportService: ValidationReportService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess(SessionReportDialogComponent.className, this.loadDataProcessText);

        this.session = data.session;

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._validationService.getSessionUserCounts(this.data.session.id).pipe(
                map(
                    userCounts => {
                        if (!this.isNullOrUndefined(userCounts)) {
                            this._userCounts = userCounts;
                        }
                        return true;
                    }
                )
            )
        ).pipe(
            tap(() => {
                if (isNullOrUndefined(this.session.report)) {
                    this.accuracyReport = this._reportService.generateAccuracyReport(this.data.registers, this.data.lines, this._userCounts, this.data.systemCounts, this.data.devices.find(i => i.master === true), this.data.devices.filter(i => i.master !== true), this.data.timeSetup, this.data.globalData, this.data.validationRecording, this.data.session);
                } else {
                    this.accuracyReport = this.session.report;
                }

                this.aggregationReport = this._reportService.generateAggregationReport(this.data.lineReportBucketSize, this.data.registers, this.data.lines, this._userCounts, this.data.systemCounts, this.data.devices.find(i => i.master === true), this.data.devices.filter(i => i.master !== true), this.data.timeSetup, this.data.globalData, this.data.validationRecording, this.data.session);
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public close(): void {
        this._dialogRef.close(new SessionReportDialogResult());
    }

    public formatStart(offset: number): string {
        if (this.data.showDurationAsTime === true) {
            return DateTimeUtility.toShortDate(new Date(this.data.validationRecording.startTime.valueOf() + offset), this.data.timeSetup.timeZone);
        } else {
            return DateTimeUtility.millisecondsToDurationShort(offset);
        }
    }
}
