import { Injectable } from '@angular/core';
import { ActivityService } from '@em/service/data/activity/Activity.Service';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { DeviceHealthService } from '@em/service/data/devicehealth/DeviceHealth.Service';
import { GroupService } from '@em/service/data/group/Group.Service';
import { HomeService } from '@em/service/data/home/Home.Service';
import { LicenceService } from '@em/service/data/licence/Licence.Service';
import { MetaDataKeysService } from '@em/service/data/metadatakeys/MetaDataKeys.Service';
import { NotificationService } from '@em/service/data/notification/Notification.Service';
import { OutboundConnectionService } from '@em/service/data/outboundconnection/OutboundConnection.Service';
import { RecordingsService } from '@em/service/data/recordings/Recordings.Service';
import { RoleService } from '@em/service/data/role/Role.Service';
import { RoutingRuleService } from '@em/service/data/routingrule/RoutingRule.Service';
import { ScheduleService } from '@em/service/data/schedule/Schedule.Service';
import { SettingService } from '@em/service/data/setting/Setting.Service';
import { TaskService } from '@em/service/data/task/Task.Service';
import { UserService } from '@em/service/data/user/User.Service';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';

@Injectable()
export class AllDataService extends RiftBaseService {
    public constructor(
        private readonly _deviceService: DeviceService,
        private readonly _deviceHealthService: DeviceHealthService,
        private readonly _groupService: GroupService,
        private readonly _homeService: HomeService,
        private readonly _licenceService: LicenceService,
        private readonly _metaDataKeysService: MetaDataKeysService,
        private readonly _notificationService: NotificationService,
        private readonly _outboundConnectionService: OutboundConnectionService,
        private readonly _recordingsService: RecordingsService,
        private readonly _roleService: RoleService,
        private readonly _routingRuleService: RoutingRuleService,
        private readonly _scheduleService: ScheduleService,
        private readonly _settingService: SettingService,
        private readonly _taskService: TaskService,
        private readonly _userService: UserService,
        private readonly _activityService: ActivityService,
    ) {
        super();
    }

    public clearAllCache(): void {
        this._deviceService.clearCache();
        this._deviceHealthService.clearCache();
        this._groupService.clearCache();
        this._homeService.clearCache();
        this._licenceService.clearCache();
        this._metaDataKeysService.clearCache();
        this._notificationService.clearCache();
        this._outboundConnectionService.clearCache();
        this._recordingsService.clearCache();
        this._roleService.clearCache();
        this._routingRuleService.clearCache();
        this._scheduleService.clearCache();
        this._settingService.clearCache();
        this._taskService.clearCache();
        this._userService.clearCache();
        this._activityService.clearCache();
    }
}
