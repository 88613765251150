import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { VideoEntryModel } from './VideoEntry.Model';


export class NodeVideoEntryModel extends BaseModel implements IRestModel {
    public friendlyDeviceSerial: string = null;
    public videoDataEntry: VideoEntryModel = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-NodeVideoEntryModel');

        this.videoDataEntry = RestModelUtility.loadFrom(restModel.VideoDataEntry, VideoEntryModel);
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this)
        };
    }
}
