import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface ISummationRegisterModel extends IRestModel {
    register: number;
    subtraction: boolean;
}

export class SummationRegisterModel extends BaseModel implements ISummationRegisterModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public register: number = null;
    public subtraction: boolean = null;

    public constructor() {
        super();

        this.registerProperty('register');
        this.registerProperty('subtraction');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-SummationRegisterModel');

        this.setPropertyOriginalValue('register', this.register);
        this.setPropertyOriginalValue('subtraction', this.subtraction);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
