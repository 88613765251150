<div class="rift-restore-backup-title"
    mat-dialog-title>
    <div fxLayout="row"
        fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Restore Backup
            </div>
        </div>
        <div fxFlex></div>
        <div class="spacing-right"
            *ngIf="!isNullOrUndefined(selectedLiveDevice) && selectedLiveDevice.isIPEnabled === true">
            <mat-checkbox matTooltip="Restore Network IP Configuration"
                [(ngModel)]="restoreIPConfig">Restore IP config</mat-checkbox>
        </div>
        <ng-template #backupFileSelectHost></ng-template>
    </div>
</div>
<div class="rift-restore-backup-content"
    mat-dialog-content
    fxLayout="column">
    <div class="spacing-bottom"
        fxLayout="row">
        <div>
            {{ message }}
        </div>
        <div class="shr-error">
            {{ error }}
        </div>
    </div>
    <div class="spacing-bottom"
        fxLayout="row">
        Backup File Units
    </div>
    <div class="spacing-bottom"
        fxLayout="row">
        <button [matTooltip]="isNullOrUndefined(device) ? '' : device.master === true ? 'Master' : 'Node'"
            mat-raised-button
            class="spacing-right"
            (click)="onBackupDeviceClick(device)"
            *ngFor="let device of backupDevices">
            {{ isNullOrUndefined(device) ? '' : device.master === true ? 'M' : 'N' }}
        </button>
    </div>
    <div class="spacing-bottom"
        fxLayout="row">
        <rift-viewport #backupViewPort
            [height]="170"
            [width]="700"></rift-viewport>
    </div>
    <div class="spacing-bottom"
        fxLayout="row">
        Live Units
    </div>
    <div class="spacing-bottom"
        fxLayout="row">
        <button [matTooltip]="isNullOrUndefined(device) ? '' : device.master === true ? 'Master' : 'Node'"
            mat-raised-button
            class="spacing-right"
            (click)="onLiveDeviceClick(device)"
            *ngFor="let device of liveDevices">
            {{ isNullOrUndefined(device) ? '' : device.master === true ? 'M' : 'N' }}
        </button>
    </div>
    <div fxLayout="row">
        <rift-viewport #liveViewPort
            [height]="170"
            [width]="700"></rift-viewport>
    </div>
</div>
<div class="rift-restore-backup-actions"
    mat-dialog-actions>
    <button matTooltip="Close"
        (click)="onCloseClick()"
        mat-raised-button
        color="primary">Close</button>
    <div fxFlex></div>
    <button matTooltip="Restore Backup to selected unit"
        class="spacing-left"
        mat-raised-button
        color="primary"
        (click)="onRestoreClick()"
        [disabled]="canRestore === false">Restore</button>
</div>