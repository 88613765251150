export function debug(string: string): void {
    console.error(string);
}
export function error(...param: any[]): void {
    console.error(param);
}
export function puts(...param: any[]): void {
    console.log(param);
}
export function print(...param: any[]): void {
    console.log(param);
}
export function log(string: string): void {
    console.log(string);
}
export function isArray(object: any): object is any[] {
    return Array.isArray(object);
}
export function isDate(object: any): object is Date {
    return object && Object.prototype.toString.call(object) === '[object Date]' && !Number.isNaN(object);
}
export function isBoolean(object: any): object is boolean {
    return typeof object === 'boolean';
}
export function isFunction(object: any): boolean {
    return typeof object === 'function';
}
export function isNull(object: any): object is null {
    return object === null;
}
export function isNullOrUndefined(object: any): object is null | undefined {
    return object === null || object === undefined;
}
export function isNumber(object: any): object is number {
    return typeof object === 'number';
}
export function isNumeric(object: any): object is number {
    return isNullOrUndefined(object) ? false : isNumber(object) ? true : isString(object) ? /^[0-9.]*$/g.test(object) : false;
}
export function isObject(object: any): boolean {
    return object !== null && typeof object === 'object';
}
export function isPrimitive(object: any): boolean {
    return (typeof object !== 'object' && typeof object !== 'function') || object === null;
}
export function isString(object: any): object is string {
    return typeof object === 'string';
}
export function isSymbol(object: any): object is symbol {
    return typeof object === 'symbol';
}
export function isUndefined(object: any): object is undefined {
    return object === undefined;
}
export function isConstructor(object: any): boolean {
    try {
        const n = new object();
    } catch (err) {
        return false;
    }
    return true;
}
