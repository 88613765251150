import { NgZone, Directive } from '@angular/core';
import { TimeLineDurationView } from '@rift/components/shared/timeline/duration/TimeLine.Duration.View';
import { TimeLineRowBody } from '@rift/components/shared/timeline/rows/TimeLine.Row.Body';
import { TimeLineRowHeader } from '@rift/components/shared/timeline/rows/TimeLine.Row.Header';
import { TimeLineRowSelect } from '@rift/components/shared/timeline/rows/TimeLine.Row.Select';
import { TimeLineRows } from '@rift/components/shared/timeline/rows/TimeLine.Rows';
import { TimeLineRowsSettings } from '@rift/components/shared/timeline/rows/TimeLine.Rows.Settings';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { Subject } from 'rxjs';

export interface IUserCountClickEvent {
    frameNumber: number;
    registerIndex: number;
}


@Directive()
export class TimeLineRow extends DisplayItem {
    public onBodyMouseDown: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onBodyDrag: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onBodyPressUp: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onUserCountClick: Subject<IUserCountClickEvent> = new Subject<IUserCountClickEvent>();
    public onExpand: Subject<TimeLineRow> = new Subject<TimeLineRow>();
    public onCollapse: Subject<TimeLineRow> = new Subject<TimeLineRow>();

    private _body: TimeLineRowBody = null;
    private _header: TimeLineRowHeader = null;
    private _rows: TimeLineRows = null;
    private _select: TimeLineRowSelect = null;

    private _width: number = 0;

    public constructor(
        protected readonly _durationView: TimeLineDurationView,
        protected readonly _registerBase: RegisterBaseModel,
        protected readonly _playService: ValidationPlayService,
        protected readonly _zone: NgZone) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            this._header = new TimeLineRowHeader(this._registerBase, this._playService, this._zone);
            this.container.addChild(this.header.container);

            this._body = new TimeLineRowBody(this._durationView, this._registerBase, this._playService, this._zone);
            this.container.addChild(this.body.container);

            this._select = new TimeLineRowSelect(this._registerBase, this._playService, this._zone);
            this.container.addChild(this.select.container);

            this._rows = new TimeLineRows(this._durationView, this._playService, this._zone);
            this.container.addChild(this.rows.container);

            this.addEventHandlers();
        });
    }

    public get body(): TimeLineRowBody {
        return this._body;
    }

    public bringToFront(displayItem?: IDisplayItem): void {

    }

    public get header(): TimeLineRowHeader {
        return this._header;
    }

    public mouseMove(stageX: number): void {
        this.rows.mouseMove(stageX);
        this.body.mouseMove(stageX);
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();
            this.container.removeAllChildren();

            this._header.onDestroy();
            this._body.onDestroy();
            this._select.onDestroy();
            this._rows.onDestroy();
        });
    }

    public get registerIndex(): number {
        return this._registerBase.registerIndex;
    }

    public resize(width: number): void {
        this._zone.runOutsideAngular(() => {
            this._width = width;

            this.body.resize(width - (TimeLineRowsSettings.header.width + TimeLineRowsSettings.header.spacing + TimeLineRowsSettings.select.spacing + TimeLineRowsSettings.select.width));
            this.rows.resize(width);

            this.header.container.x = 0;
            this.body.container.x = TimeLineRowsSettings.header.width + TimeLineRowsSettings.header.spacing;
            this.select.container.x = TimeLineRowsSettings.header.width + TimeLineRowsSettings.header.spacing + this.body.width + TimeLineRowsSettings.select.spacing;
        });
    }

    public get rows(): TimeLineRows {
        return this._rows;
    }

    public get select(): TimeLineRowSelect {
        return this._select;
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            this.header.update();
            this.body.update();
            this.select.update();
            this.rows.update();
            this.requireStageUpdate.next();
        });
    }

    public get visible(): boolean {
        return this.container.visible;
    }

    public set visible(value: boolean) {
        if (this.container.visible !== value) {
            this.container.visible = value;
        }
    }

    public get width(): number {
        return this._width;
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this.addSubscription(this.header.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
            this.addSubscription(this.header.onCollapseClick.subscribe(() => this.collapseSubRows()));
            this.addSubscription(this.header.onExpandClick.subscribe(() => this.expandSubRows()));

            this.addSubscription(this.body.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
            this.addSubscription(this.body.onDrag.subscribe(event => this.onBodyDrag.next(event)));
            this.addSubscription(this.body.onMouseDown.subscribe(event => this.onBodyMouseDown.next(event)));
            this.addSubscription(this.body.onPressUp.subscribe(event => this.onBodyPressUp.next(event)));
            this.addSubscription(this.body.onUserCountClick.subscribe(event => this.onUserCountClick.next({ frameNumber: event, registerIndex: this._registerBase.registerIndex })));

            this.addSubscription(this.select.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));

            this.addSubscription(this.rows.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
        });
    }

    private collapseSubRows(): void {
        this.rows.collapse();
        this.onCollapse.next(this);
    }

    private expandSubRows(): void {
        this.rows.expand();
        this.onExpand.next(this);
    }

    private removeEventHandlers(): void {
        super.onDestroy();
    }
}
