<div *ngIf="show" class="shr-okcancel-dialog">
    <div class="title"
        mat-dialog-title
        fxLayout="row"
        fxLayoutAlign="center center">
        {{data.title}}
    </div>
    <div class="content"
        mat-dialog-content
        fxLayout="column"
        fxLayoutAlign="center center"
        [innerHTML]="data.messageHtml">
    </div>
    <div class="content"
        mat-dialog-content
        fxLayout="row"
        fxLayoutAlign="center center">
        {{data.message}}
    </div>
    <div class="actions"
        fxLayout="row"
        fxLayoutAlign="space-around center"
        mat-dialog-actions>
        <button *ngIf="data.showOk === true && okClicked === false"
            (click)="onOkClicked()"
            mat-raised-button
            color="primary">{{ isNullOrUndefined(data.okText) ? 'OK' : data.okText}}</button>
        <button *ngIf="data.showCancel === true"
            (click)="onCancelClicked()"
            mat-raised-button
            color="warn">{{ isNullOrUndefined(data.cancelText) ? 'Cancel' : data.cancelText}}</button>
    </div>
</div>