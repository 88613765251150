<mat-card class="shr-table"
    [ngClass]="class  + (hideMatCard === true ? ' shr-table-hide-mat-card' : '')">
    <mat-card-title>
        <div class="title-bar shr-table-header-row"
            fxLayout="row">
            <div fxLayout="column">
                <div class="header">
                    {{ title }}
                    <ng-template [ngTemplateOutlet]="shrTableHeader">

                    </ng-template>
                </div>
                <div class="timezone-text"
                    *ngIf="!isNullOrUndefined(timeSetup)">
                    Time Zone ({{ timeSetup.timeZone.timeOffsetShortText }})
                </div>
                <div class="loading-in-background-text"
                    *ngIf="!isNullOrUndefined(isLoadingInBackground) && isLoadingInBackground === true">
                    Loading data in the background.
                </div>
            </div>
            <div fxFlex></div>
            <div class="shr-table-header-actions"
                *ngIf="!isNullOrUndefined(shrTableHeaderPreFilterActions)">
                <ng-template [ngTemplateOutlet]="shrTableHeaderPreFilterActions">

                </ng-template>
            </div>
            <div class="shr-table-header-filter filter"
                *ngIf="filterEnabled === true">
                <mat-form-field>
                    <input matInput
                        [disabled]="isLoadingData === true"
                        (keyup)="onFilterUpdated($event)"
                        placeholder="Filter">
                </mat-form-field>
            </div>
            <div class="shr-table-header-actions"
                *ngIf="!isNullOrUndefined(shrTableHeaderActions)">
                <ng-template [ngTemplateOutlet]="shrTableHeaderActions">

                </ng-template>
            </div>
            <div class="shr-table-data-refresh">
                <shr-background-load-spinner [trigger]="dataRefreshedTrigger"></shr-background-load-spinner>
            </div>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="shr-table-container">
            <div *ngIf="isLoadingData === true"
                class="loading-cover"
                fxLayout="row"
                fxLayoutAlign="center center">
                <mat-spinner diameter="40"></mat-spinner>
            </div>

            <table [id]="idPreFix + '-table'"
                mat-table
                class="shr-table-table"
                [dataSource]="dataSource"
                [multiTemplateDataRows]="multiDataRows === true">
                <!-- Actions Column -->
                <ng-container matColumnDef="actions"
                    [sticky]="true">
                    <th class="actions-header"
                        mat-header-cell
                        *matHeaderCellDef></th>
                    <td class="actions-cell"
                        mat-cell
                        *matCellDef="let data">
                        <shr-table-actions-tray>
                            <ng-template [ngTemplateOutlet]="shrTableActions"
                                [ngTemplateOutletContext]="{$implicit: data}">
                            </ng-template>
                        </shr-table-actions-tray>
                    </td>
                </ng-container>

                <tr [class.disabled]="isLoadingData === true"
                    [ngClass]="rowHeaderClass"
                    mat-header-row
                    [id]="idPreFix + '-header'"
                    *matHeaderRowDef="displayedColumns"></tr>
                <tr [class.selected]="!isNullOrUndefined(data.isSelected) && data.isSelected === true"
                    [id]="idPreFix + '-' + i + '-data'"
                    [class.tr-hover]="(!isNullOrUndefined(data.isSelected) && data.isSelected === false && rowMouseHover === true) || ((isNullOrUndefined(data.isSelected) || data.isSelected === false) && rowMouseHover === true)"
                    [ngClass]="rowClass"
                    mat-row
                    *matRowDef="let data; let i = index; columns: displayedColumns;"
                    (click)="onRowClicked($event, data)">
                </tr>
            </table>
        </div>
        <div fxLayout="row"
            fxLayoutAlign="start center">
            <div>
                <mat-icon style="cursor: pointer;"
                    *ngIf="dataExportEnabled === true"
                    (click)="downloadToCSV()"
                    [matTooltip]="'Download ' + (pageEnabled === true && serverPage === true ? 'page ' : '') + 'to csv'">cloud_download</mat-icon>
            </div>
            <div fxFlex></div>
            <div>
                <mat-paginator [disabled]="isLoadingData === true"
                    #paginator
                    *ngIf="pageEnabled === true"
                    (page)="page.emit($event)"
                    [pageIndex]="pageIndex"
                    [length]="recordLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    [showFirstLastButtons]="pageShowFirstLastButtons">
                </mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>