import { AfterViewInit, ElementRef, EventEmitter, Injector, Input, Output, Renderer2, ViewChild, Directive } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ElementStyleUtility } from '@shared/utility/ElementStyle.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { AngularDraggableDirective, AngularResizableDirective, IPosition } from 'angular2-draggable';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { ISize } from 'angular2-draggable/lib/models/size';
import { Observable, of, Subscription, timer } from 'rxjs';

@Directive()
export abstract class SettingsCountingMenuBaseComponent extends RiftBaseComponent implements AfterViewInit {
    public static className: string = 'SettingsCountingMenuBaseComponent';

    public RegisterTypeEnum = RegisterTypeEnum;

    public abstract show: boolean;
    public abstract position: IPosition;
    public abstract size: ISize;
    public closeProcess: ProcessMonitorServiceProcess;

    public abstract set bounds(value: HTMLElement);
    public abstract get bounds(): HTMLElement;

    public abstract set zIndex(value: number);
    public abstract get zIndex(): number;

    public canShow: boolean = false;

    public get isFullScreen(): boolean {
        return this._isFullScreen;
    }
    public set isFullScreen(value: boolean) {
        this._isFullScreen = value;
        if (!this.isNullOrUndefined(this.draggable)) {
            if (this._isFullScreen === true) {
                this.draggable.inBounds = false;
            } else {
                this.draggable.inBounds = true;
            }
        }
    }

    @Input()
    public get minHeight(): number {
        return this._minHeight;
    }
    public set minHeight(value: number) {
        this._minHeight = value;
    }

    @Input()
    public get minWidth(): number {
        return this._minWidth;
    }
    public set minWidth(value: number) {
        this._minWidth = value;
    }

    @Input()
    public get maxHeight(): number {
        return this._maxHeight;
    }
    public set maxHeight(value: number) {
        this._maxHeight = value;
    }

    @Input()
    public get maxWidth(): number {
        return this._maxWidth;
    }
    public set maxWidth(value: number) {
        this._maxWidth = value;
    }

    @Output()
    public broughtToFront: EventEmitter<SettingsCountingMenuBaseComponent> = new EventEmitter<SettingsCountingMenuBaseComponent>();

    @Output()
    public closed: EventEmitter<void> = new EventEmitter<void>();

    protected _bounds: HTMLElement;
    protected _containment: HTMLElement;
    protected _minHeight: number = null;
    protected _minWidth: number = null;
    protected _maxHeight: number = null;
    protected _maxWidth: number = null;
    protected _zIndex: number;
    protected _show: boolean;

    private _timerSub: Subscription = null;
    private _firstLoad: number = null;
    private _checkPositionDelay: number = 1500;
    private _canShowDelay: number = 1500;
    private _isFullScreen: boolean = false;

    @ViewChild('draggable', { static: false })
    public get draggable(): AngularDraggableDirective {
        return this._draggable;
    }
    public set draggable(value: AngularDraggableDirective) {
        this._draggable = value;
        this.setStyles();
    }
    private _draggable: AngularDraggableDirective;

    @ViewChild('resizable', { static: false })
    public get resizable(): AngularResizableDirective {
        return this._resizable;
    }
    public set resizable(value: AngularResizableDirective) {
        this._resizable = value;
        this.setStyles();
    }
    private _resizable: AngularResizableDirective;

    @ViewChild('container', { static: false })
    public get container(): ElementRef<HTMLElement> {
        return this._container;
    }
    public set container(value: ElementRef<HTMLElement>) {
        this._container = value;
        this.setSizePositionDefaults();
        this.setStyles();
        this.checkPosition();
    }
    private _container: ElementRef;

    public constructor(
        private readonly _renderBase: Renderer2,
        private readonly _baseInjector: Injector,
        private readonly _baseDialog: MatDialog) {
        super(_baseInjector, _baseDialog);

        this.closeProcess = this.processMonitorService.getProcess(SettingsCountingMenuBaseComponent.className, 'Close');

        this._zIndex = 1;
        this._firstLoad = new Date().valueOf();

        this.addSubscription(timer(this._canShowDelay).subscribe(() => this.canShow = true));
    }

    public ngAfterViewInit(): void {
        this.setSizePositionDefaults();
        this.checkPosition();
    }

    public bringToFront(): void {
        this.zIndex = 100;
        this.broughtToFront.next(this);
    }

    public checkPosition(): void {
        if (this.isFullScreen === false) {
            const gap = this._firstLoad + this._checkPositionDelay;
            if (gap < new Date().valueOf()) {
                if (!this.isNullOrUndefined(this.bounds) && !this.isNullOrUndefined(this.container) && !this.isNullOrUndefined(this.container.nativeElement) && !this.isNullOrUndefined(this.position)) {
                    const containerElement = this.container.nativeElement;
                    const containerElementTransform = ElementStyleUtility.getTransformTranslate(containerElement);

                    if (!this.isNullOrUndefined(containerElementTransform)) {
                        this.setStyles();

                        const boundsBoundingClientRect = this.bounds.getBoundingClientRect();
                        const boundsStyle = window.getComputedStyle(this.bounds);
                        const boundsHeight = parseInt(boundsStyle.getPropertyValue('height'), 10);
                        const boundsWidth = parseInt(boundsStyle.getPropertyValue('width'), 10);
                        const boundsTopPos = boundsBoundingClientRect.top + window.scrollY;
                        const boundsTotalHeight = (boundsHeight + boundsTopPos + 20);

                        if (containerElementTransform.x < 0) {
                            this.setContainerPosition(this.position.x > 0 ? this.position.x : 0, this.position.y);
                        }

                        if (containerElementTransform.y < 0) {
                            this.setContainerPosition(this.position.x, this.position.y > 0 ? this.position.y : 0);
                        }

                        if (boundsTotalHeight >= window.innerHeight) {
                            const containerHeight = this.isNullOrUndefined(this.resizable) ? containerElement.clientHeight : this.size.height;
                            const atBottom = (containerHeight + this.position.y) > boundsHeight;

                            if (this.position.y > 0) {
                                const overY = (containerHeight + this.position.y) - boundsHeight;
                                if (overY > 0) {
                                    const newY = this.position.y - overY;
                                    this.setContainerPosition(this.position.x < 0 ? 0 : this.position.x, newY < 0 ? this.position.y : newY);
                                }
                            }

                            if (containerHeight >= boundsHeight) {
                                let newHeight = 0;
                                if (atBottom) {
                                    newHeight = boundsHeight - this.position.y;
                                } else {
                                    newHeight = containerHeight;
                                }

                                if ((this.isNullOrUndefined(this.minHeight) || newHeight >= this.minHeight) && (this.isNullOrUndefined(this.maxHeight) || newHeight <= this.maxHeight)) {
                                    this._renderBase.setStyle(containerElement, 'height', `${newHeight}px`);
                                }
                            }

                            const containerWidth = this.isNullOrUndefined(this.resizable) ? containerElement.clientWidth : this.size.width;
                            const atRight = (containerWidth + this.position.x) > boundsWidth;

                            if (this.position.x > 0) {
                                const overX = (containerWidth + this.position.x) - boundsWidth;
                                if (overX > 0) {
                                    const newX = this.position.x - overX;
                                    this.setContainerPosition(newX < 0 ? this.position.x : newX, this.position.y < 0 ? 0 : this.position.y);
                                }
                            }

                            if (containerWidth >= boundsWidth) {
                                let newWidth = 0;
                                if (atRight) {
                                    newWidth = boundsWidth - this.position.x;
                                } else {
                                    newWidth = containerWidth;
                                }

                                if ((this.isNullOrUndefined(this.minWidth) || newWidth >= this.minWidth) && (this.isNullOrUndefined(this.maxWidth) || newWidth <= this.maxWidth)) {
                                    this._renderBase.setStyle(containerElement, 'width', `${newWidth}px`);
                                }
                            }
                        }
                    }
                }
            } else if (this.isNullOrUndefined(this._timerSub)) {
                this._timerSub = timer(100, 100).subscribe(() => {
                    if (gap < new Date().valueOf()) {
                        this._firstLoad = 0;
                        if (!isNullOrUndefined(this._timerSub)) {
                            this._timerSub.unsubscribe();
                        }
                        this._timerSub = null;
                        this.checkPosition();
                        if (!this.isNullOrUndefined(this.draggable)) {
                            this.draggable.boundsCheck();
                        }
                    }
                });
            }
        }
    }

    public close(): void {
        if (((this as unknown) as ISaveAllChanges).hasChanges) {
            const result = this.showSaveChangesWarningBase(((this as unknown) as ISaveAllChanges), () => {
                this.reset();
                this.show = false;
                this.closed.next();
                return of(true);
            });
            if (result instanceof Observable) {
                this.addSubscription(this.observableHandlerBase(result, this.closeProcess).subscribe(), this.closeProcess);
            }
        } else {
            this.reset();
            this.show = false;
            this.closed.next();
        }
    }

    public onMenuClick(): void {
        this.bringToFront();
    }

    public onDrStarted(nativeElement: any): void {
        this.bringToFront();
        this.checkPosition();
    }

    public onDrStopped(nativeElement: any): void {
        this.checkPosition();

    }

    public onDrEdge(edge: { top: boolean; right: boolean; bottom: boolean; left: boolean }): void {
        this.checkPosition();
    }

    public onDrMovingOffset(position: IPosition): void {
        this.checkPosition();
    }

    public onDrEndOffset(position: IPosition): void {
        this.position = position;
    }


    public onRzStart(resizeEvent: IResizeEvent): void {
        this.bringToFront();
    }

    public onRzResizing(resizeEvent: IResizeEvent): void {

    }

    public onRzStop(resizeEvent: IResizeEvent): void {
        if (!this.isNullOrUndefined(this.size)) {
            this.size = resizeEvent.size;
        }
    }

    public open(): void {
        this.show = true;
        this.checkPosition();
        this.addSubscription(timer(100).subscribe(() => this.bringToFront()));
    }

    public reset(): void {
    }

    private setContainerPosition(x: number, y: number): void {
        ElementStyleUtility.setTransformTranslate(this.container.nativeElement, { x, y });
        this.position = { x, y };
    }

    private setStyles(): void {
        if (!this.isNullOrUndefined(this.container) && !this.isNullOrUndefined(this.container.nativeElement)) {
            if (!this.isNullOrUndefined(this.size)) {
                this._renderBase.setStyle(this.container.nativeElement, 'width', `${this.size.width}px`);
                this._renderBase.setStyle(this.container.nativeElement, 'height', `${this.size.height}px`);
            }
            this._renderBase.setStyle(this.container.nativeElement, 'zIndex', this.zIndex);
            this._renderBase.setStyle(this.container.nativeElement, 'max-width', `${this.maxWidth}px`);
            this._renderBase.setStyle(this.container.nativeElement, 'max-height', `${this.maxHeight}px`);
            this._renderBase.setStyle(this.container.nativeElement, 'min-width', `${this.minWidth}px`);
            this._renderBase.setStyle(this.container.nativeElement, 'min-height', `${this.minHeight}px`);
        }
    }

    private setSizePositionDefaults(): void {
        if (!this.isNullOrUndefined(this.resizable) && this.isNullOrUndefined(this.size)) {
            if (!this.isNullOrUndefined(this.minWidth) && !isNaN(this.minWidth) && !this.isNullOrUndefined(this.minHeight) && !isNaN(this.minHeight)) {
                this.size = { width: this.minWidth, height: this.minHeight };
                this.resizable.rzResizing.emit({ size: this.size, host: null, handle: null, position: null, direction: null });
            }
        }
    }
}
