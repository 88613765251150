import { Injector, NgZone, OnDestroy, OnInit, Renderer2, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FillHeightParentComponentBase } from '@rift/components/base/FillHeightParentComponentBase';
import { ConnectionRequestModel } from '@rift/models/restapi/ConnectionRequest.Model';
import { AllDataService } from '@rift/service/data/alldata/AllData.Service';
import { OkCancelDialogComponent, OkCancelDialogResult } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { LoginModel } from '@shared/models/restapi/Login.Model';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { RestApiServiceOptions } from '@shared/service/restapi/RestApi.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of, Subject, timer } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConnectionAuth } from '@rift/models/generic/ConnectionAuth.Model';
import { FormatSystemStorageEnum } from '@shared/enum/FormatSystemStorage.Enum';

@Directive()
export abstract class HomeComponentBase extends FillHeightParentComponentBase implements OnInit, OnDestroy {
    public static className: string = 'HomeComponentBase';

    public rebootProcess: ProcessMonitorServiceProcess;
    public formatStorageProcess: ProcessMonitorServiceProcess;

    protected cancelConnection: Subject<void> = new Subject<void>();
    protected connectProcess: ProcessMonitorServiceProcess;

    protected connectionOptions: ConnectionRequestModel;
    protected errorConnecting: Subject<Array<Error>> = new Subject<Array<Error>>();

    private _parsedConnectionParamsSub: Subject<ConnectionRequestModel> = new Subject<ConnectionRequestModel>();
    private _parsedConnectionParams: ConnectionRequestModel = null;
    private _externalChangesDialogRef: MatDialogRef<OkCancelDialogComponent> = null;

    private _conAuth: ConnectionAuth = null;

    protected constructor(
        private readonly _homeZone: NgZone,
        private readonly _homeRenderer: Renderer2,
        private readonly _allDataServiceBase: AllDataService,
        private readonly _homeConfigurationService: ConfigurationService,
        private readonly _homeDialog: MatDialog,
        private readonly _homeActivatedRoute: ActivatedRoute,
        private readonly _homeRouter: Router,
        private readonly _homeInjector: Injector) {
        super(_homeRenderer, _homeInjector, _homeDialog);

        this.rebootProcess = this.processMonitorService.getProcess(HomeComponentBase.className, 'Reboot device');
        this.formatStorageProcess = this.processMonitorService.getProcess(HomeComponentBase.className, 'Format Storage');

        this.addSubscription(this.webSocketService.externalSettingsChanged.subscribe(() => this.onExternalSettingsChanged()));
        this.addSubscription(this.webSocketService.physicalNetworkChanged.subscribe(() => this.onPhysicalNetworkChanged()));

        this.addSubscription(this.observableHandlerBase(this.connectionService.connectedCancelled, this.connectProcess).subscribe(() => {
            this.cancelConnection.next();
        }));
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    protected parsedConnectionParams(): Observable<ConnectionRequestModel> {
        if (isNullOrUndefined(this._parsedConnectionParams)) {
            return this._parsedConnectionParamsSub;
        } else {
            return of(this._parsedConnectionParams);
        }
    }

    protected formatSystemStorage(serialNumber: string, formatType: FormatSystemStorageEnum): void {
        this.addSubscription(this.openOkCancelDialog('Format System Storage', 'Please confirm format').afterClosed().subscribe((result: OkCancelDialogResult) => {
            if (!isNullOrUndefined(result) && result.ok === true) {
                RestApiServiceOptions.retryOptions = { disabled: true };

                let triedReconnect = false;
                const formatDialog = this.openPleaseWaitDialog('Formatting');
                this.addSubscription(this.observableHandlerBase(this.deviceService.formatSystemStorage(serialNumber, formatType), this.formatStorageProcess).subscribe(() => {
                    this.addSubscription(this.observableHandlerBase(this.connectionService.disconnectAsync(), this.disconnectedProcess).subscribe(() => {
                        const timerSub = this.addSubscription(timer(60000, 2000).subscribe(() => {
                            this.connectionService.connectionRetriesExceeded.subscribe(() => {
                                delete RestApiServiceOptions.retryOptions;
                                formatDialog.close();
                            });

                            if (triedReconnect === false) {
                                triedReconnect = true;
                                this.connect(this._conAuth);
                            }
                            if (this.connectionService.isConnected === true) {
                                if (!isNullOrUndefined(timerSub)){
                                    timerSub.unsubscribe();
                                }

                                delete RestApiServiceOptions.retryOptions;
                                formatDialog.close();

                                if (this.connectionService.emConnection === true){
                                    // Need to go online before we can check the result
                                    this.addSubscription(this.observableHandlerBase(this.connectionService.goOnline(), null).subscribe(() => {
                                        // Get the format result
                                        this.addSubscription(this.observableHandlerBase(this.deviceService.getFormatSystemStorageResult(serialNumber), null).subscribe((formatResult) => {
                                            this.openOkCancelDialog('Format Complete', formatResult, false);
                                        }));
                                    }));
                                }
                                else{
                                    // Get the format result
                                    this.addSubscription(this.observableHandlerBase(this.deviceService.getFormatSystemStorageResult(serialNumber), null).subscribe((formatResult) => {
                                        this.openOkCancelDialog('Format Complete', formatResult, false);
                                    }));
                                }
                            }

                        }));
                    }), this.disconnectedProcess);
                }), this.formatStorageProcess);
            }
        }));
    }

    protected reboot(): void {
        this.addSubscription(this.openOkCancelDialog('Reboot Device', 'Please confirm device reboot').afterClosed().subscribe((result: OkCancelDialogResult) => {
            if (!isNullOrUndefined(result) && result.ok === true) {
                RestApiServiceOptions.retryOptions = { disabled: true };

                let triedReconnect = false;
                const rebootDialog = this.openPleaseWaitDialog('Rebooting');
                this.addSubscription(this.observableHandlerBase(this.globalService.rebootDevices(), this.rebootProcess).subscribe(() => {
                    this.addSubscription(this.observableHandlerBase(this.connectionService.disconnectAsync(), this.disconnectedProcess).subscribe(() => {
                        const timerSub = this.addSubscription(timer(60000, 2000).subscribe(() => {
                            this.connectionService.connectionRetriesExceeded.subscribe(() => {
                                delete RestApiServiceOptions.retryOptions;
                                rebootDialog.close();
                            });

                            if (triedReconnect === false) {
                                triedReconnect = true;
                                this.connect(this._conAuth);
                            }
                            if (this.connectionService.isConnected === true) {
                                if (!isNullOrUndefined(timerSub)){
                                    timerSub.unsubscribe();
                                }

                                delete RestApiServiceOptions.retryOptions;
                                rebootDialog.close();
                            }

                        }));
                    }), this.disconnectedProcess);
                }), this.rebootProcess);
            }
        }));
    }

    protected offline(): void {
        super.offline();

    }

    protected online(): void {
        super.online();
    }

    protected connect(exAuth: ConnectionAuth = null): void {
        this.addSubscription(this.login().subscribe(
            () => {
                this.addSubscription(this.observableHandlerBase(this._homeActivatedRoute.params, this.connectProcess).subscribe(params => {
                    if (!this.isNullOrUndefined(params.protocol) && !this.isNullOrUndefined(params.connectionData)) {
                        this.connectionOptions = this.connectionService.parseConnectionParams(params.protocol, params.connectionData);
                        if (!this.isNullOrUndefined(this.connectionOptions)) {
                            this._parsedConnectionParams = this.connectionOptions;
                            this._parsedConnectionParamsSub.next(this._parsedConnectionParams);

                            if(isNullOrUndefined(exAuth)){
                                const auth: ConnectionAuth = (this._homeRouter.getCurrentNavigation()?.extras?.state as ConnectionAuth);

                                if(!isNullOrUndefined(auth)){
                                    this.connectionOptions.username = auth.username;
                                    this.connectionOptions.password = auth.password;
                                }

                                this._conAuth = auth;
                            }
                            else{
                                this.connectionOptions.username = exAuth?.username;
                                this.connectionOptions.password = exAuth?.password;

                                this._conAuth = exAuth;
                            }

                            this.addSubscription(this.connectionService.connect(this.connectionOptions, this._homeConfigurationService.deviceConnectionTimeOutMs, this.connectProcess).subscribe(
                                result => {
                                    if (result !== true) {
                                        this.errorConnecting.next([new Error('Unable to connect to device')]);
                                    }
                                },
                                error => {
                                    this.errorConnecting.next([new Error('Unable to connect to device'), error]);
                                }
                            ));
                        } else {
                            this.errorConnecting.next([new Error('Invalid connection protocol or data')]);
                        }
                    }
                }));
            }
        ));
    }

    protected onExternalSettingsChanged(): void {
        this._homeZone.run(() => {
            if (this.isNullOrUndefined(this._externalChangesDialogRef)) {
                this._externalChangesDialogRef = this.openOkCancelDialog('External Changes', 'External changes have been made to this device', false);
                this.addSubscription(this._externalChangesDialogRef.afterClosed().subscribe(() => {
                    this._allDataServiceBase.clearAllCache();
                    this.connectionService.fakeConnectionEvents();
                    this._externalChangesDialogRef = null;
                }));
            }
        });
    }

    protected onPhysicalNetworkChanged(): void {
        this._homeZone.run(() => {
            if (this.isNullOrUndefined(this._externalChangesDialogRef)) {
                this._externalChangesDialogRef = this.openOkCancelDialog('Physical Network Changes', 'The counting network has changed', false);
                this.addSubscription(this._externalChangesDialogRef.afterClosed().subscribe(() => {
                    this._allDataServiceBase.clearAllCache();
                    this.connectionService.fakeConnectionEvents();
                    this._externalChangesDialogRef = null;
                }));
            }
        });
    }

    protected login(): Observable<boolean> {
        return this.userCurrentService.isAuthenticated.pipe(
            flatMap(
                isAuthenticated => {
                    if (isAuthenticated === true) {
                        return of(true);
                    } else {
                        return this.userCurrentService.login(new LoginModel('', '')).pipe(map(result => result.success));
                    }
                }
            )
        );
    }
}
