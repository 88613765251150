<div class="shr-error-dialog">
    <div class="error" mat-dialog-title fxLayout="column">
        <div class="title">
            Application Error
        </div>
        <div class="sub-header">
            Please send to <a href="mailto:counting.support@irisys.co.uk">counting.support@irisys.co.uk</a>
        </div>
    </div>
    <div class="content" mat-dialog-content fxLayout="column">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let errorData of errors;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ isNullOrUndefined(errorData.title) ? 'Error' : errorData.title}}
                    </mat-panel-title>
                    <mat-panel-description>
                        {{ errorData.errorMessage}}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-accordion>
                    <mat-expansion-panel class="processes">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Processes
                            </mat-panel-title>
                            <mat-panel-description>
                                The running processes
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-template ngFor let-process [ngForOf]="errorData.processes">
                            <div fxLayout="column">
                                <div class="row" fxLayout="row">
                                    <span class="title">Name:</span>
                                    <span class="value">{{ process.name }}</span>
                                </div>
                                <div class="row" fxLayout="row">
                                    <span class="title">Id:</span>
                                    <span class="value">{{ process.id }}</span>
                                </div>
                                <div class="row" fxLayout="row">
                                    <span class="title">In:</span>
                                    <span class="value">{{ process.inName }}</span>
                                </div>
                                <ng-template ngFor let-errorMessage [ngForOf]="process.errorMessages">
                                    <div class="row" fxLayout="row">
                                        <span class="title">Message:</span>
                                        <span class="value">{{errorMessage}}</span>
                                    </div>
                                </ng-template>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="errorData.processes.length === 0">
                            <div fxLayout="column">
                                <div class="row" fxLayout="row">
                                    <span class="title">None</span>
                                </div>
                            </div>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="details">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Details
                            </mat-panel-title>
                            <mat-panel-description>
                                The extended details
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxLayout="column">
                            <div class="row" fxLayout="row">
                                <span class="title">Message:</span>
                                <span class="value">{{ errorData.error?.message }}</span>
                            </div>
                            <div *ngIf="!isNullOrUndefined(errorData.errorJson)" class="row" fxLayout="row">
                                <span class="title">Data:</span>
                                <span class="code" [innerHTML]="errorData.errorJson"></span>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <ng-template [ngIf]="!isNullOrUndefined(errorData.stackFrames)">
                        <mat-expansion-panel class="stack">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Stack
                                </mat-panel-title>
                                <mat-panel-description>
                                    Stack details
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div fxLayout="column" class="content">
                                <div class="row" fxLayout="row" *ngFor="let stackFrame of errorData.stackFrames">
                                    <span>at&nbsp;</span>
                                    <span>{{stackFrame.fileName}}</span>
                                    <span>:</span>
                                    <span>{{stackFrame.lineNumber}}</span>
                                    <span>:</span>
                                    <span>{{stackFrame.columnNumber}}</span>
                                    <span>:</span>
                                    <span>{{stackFrame.functionName}}</span>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </ng-template>
                </mat-accordion>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="actions" fxLayout="row" fxLayoutAlign="space-around center" mat-dialog-actions>
        <button (click)="ok()" mat-raised-button color="primary">Reload</button>
        <button (click)="save()" mat-raised-button>Save Error</button>
    </div>
</div>
