import { isNullOrUndefined, isUndefined } from '@shared/utility/General.Utility';

/**
 * elper methods for generating unique keys.
 *
 * @export
 * @class UniqueIdUtility
 */
export class UniqueIdUtility {
    private static _keyIdStore: { [key: string]: number } = {};
    private static _lastId: number = -1;

    /**
     * Gets the next unique key.
     *
     * @readonly
     * @static
     * @type {number} The next unique key.
     * @memberof UniqueIdUtility
     */
    public static get nextId(): number {
        UniqueIdUtility._lastId++;
        return UniqueIdUtility._lastId;
    }

    public static getNext(key: string): number {
        let value: number = this._keyIdStore[key];
        if (isUndefined(value)) {
            value = 0;
            this._keyIdStore[key] = value;
        }
        this._keyIdStore[key]++;
        return value;
    }
}
