import { DeviceCapabilitiesEnum, DeviceCapabilitiesEnumHelpers } from '@shared/enum/DeviceCapabilities.Enum';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export interface IGlobalModel extends IRestModel {
    logInterval: number;
    histogramLogInterval: number;
    capabilities: Array<number>;
    deviceId: string;
    deviceName: string;
    userString: string;
    siteId: string;
    siteName: string;
}

export class GlobalModel extends BaseModel implements IGlobalModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public logInterval: number = null;
    public histogramLogInterval: number = null;
    public capabilities: Array<DeviceCapabilitiesEnum> = null;
    public deviceId: string = null;
    public deviceName: string = null;
    public userString: string = null;
    public siteId: string = null;
    public siteName: string = null;

    public constructor() {
        super();

        this.registerProperty('deviceId');
        this.registerProperty('deviceName');
        this.registerProperty('userString');
        this.registerProperty('siteId');
        this.registerProperty('siteName');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-GlobalModel');

        this.capabilities = (restModel.Capabilities as Array<string>).map(c => DeviceCapabilitiesEnumHelpers.fromRestApi(c));

        this.setPropertyOriginalValue('deviceId', this.deviceId);
        this.setPropertyOriginalValue('deviceName', this.deviceName);
        this.setPropertyOriginalValue('userString', this.userString);
        this.setPropertyOriginalValue('siteId', this.siteId);
        this.setPropertyOriginalValue('siteName', this.siteName);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, ['logInterval', 'histogramLogInterval']),
            LogInterval: !isNullOrUndefined(this.logInterval) ? this.logInterval > 0 ? this.logInterval : null : null,
            HistogramLogInterval: !isNullOrUndefined(this.histogramLogInterval) ? this.histogramLogInterval > 0 ? this.histogramLogInterval : null : null,
        };
    }

    public hasCapability(value: DeviceCapabilitiesEnum): boolean {
        return this.capabilities.indexOf(value) !== -1;
    }
}
