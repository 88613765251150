<div class="em-settings-outboundconnections-managelocations-title" mat-dialog-title>
    Manage Locations
</div>
<form [formGroup]="endPointFormGroup">
    <div class="em-settings-outboundconnections-managelocations-content" mat-dialog-content fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field fxFlex class="spacing-right">
                <mat-select #endPoint placeholder="Location" [(value)]="selectedEndPoint"
                    (valueChange)="onEndPointvalueChange()" [disabled]="selectedEndPoint?.hasChanges">
                    <mat-option *ngFor="let endPoint of endPointCollection?.items | orderBy: 'text'" [value]="endPoint">
                        {{ endPoint.text }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button
                [disabled]="saveProcess.isRunning === true || deleteProcess.isRunning === true || testProcess.isRunning === true || infoProcess.isRunning === true"
                (click)="add()" mat-raised-button color="primary">New</button>
        </div>
        <div *ngIf="!isNullOrUndefined(selectedEndPoint)">
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input #name formControlName="name" matInput placeholder="Name">
                    <span matSuffix>{{ selectedEndPoint?.propertyHasChangesText('text') }}</span>
                    <mat-error>{{getFormControlErrorMessage(endPointFormGroup.controls['name'])}}</mat-error>
                    <mat-hint align="end">{{name.value.length}} / 256</mat-hint>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex="50" class="spacing-right">
                    <mat-select #protocol formControlName="protocol" placeholder="Protocol">
                        <mat-option [value]="1">
                            Http
                        </mat-option>
                        <mat-option [value]="2">
                            Email
                        </mat-option>
                        <mat-option [value]="3">
                            Ftp
                        </mat-option>
                    </mat-select>
                    <span matSuffix>{{ selectedEndPoint?.propertyHasChangesText('protocol') }}</span>
                    <mat-error>{{getFormControlErrorMessage(endPointFormGroup.controls['protocol'])}}</mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="50">
                    <mat-select #contentType formControlName="contentType" placeholder="ContentType">
                        <mat-option [value]="1">
                            XML
                        </mat-option>
                        <mat-option [value]="2">
                            JSON
                        </mat-option>
                    </mat-select>
                    <span matSuffix>{{ selectedEndPoint?.propertyHasChangesText('contentType') }}</span>
                    <mat-error>{{getFormControlErrorMessage(endPointFormGroup.controls['contentType'])}}</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input #address formControlName="address" matInput placeholder="Address">
                    <span matSuffix>{{ selectedEndPoint?.propertyHasChangesText('address') }}</span>
                    <mat-error>{{getFormControlErrorMessage(endPointFormGroup.controls['address'])}}</mat-error>
                    <mat-hint align="end">{{address.value.length}} / 2083</mat-hint>
                </mat-form-field>
            </div>
            <div fxLayout="row" *ngIf="endPointFormGroup.controls['protocol'].value === 3">
                <mat-form-field fxFlex>
                    <input #userName formControlName="userName" matInput placeholder="User Name">
                    <span matSuffix>{{ selectedEndPoint?.propertyHasChangesText('userName') }}</span>
                    <mat-error>{{getFormControlErrorMessage(endPointFormGroup.controls['userName'])}}</mat-error>
                    <mat-hint align="end">{{userName.value.length}} / 2083</mat-hint>
                </mat-form-field>
                <mat-form-field fxFlex>
                    <input #password type="password" formControlName="password" matInput placeholder="Password">
                    <span matSuffix>{{ selectedEndPoint?.propertyHasChangesText('password') }}</span>
                    <mat-error>{{getFormControlErrorMessage(endPointFormGroup.controls['password'])}}</mat-error>
                    <mat-hint align="end">{{password.value.length}} / 2083</mat-hint>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="em-settings-outboundconnections-managelocations-actions" mat-dialog-actions fxLayout="row"
        fxLayoutAlign="space-between center">
        <shr-progress-button-spinner [raised]="true" [diameter]="30" text="Save" mode="indeterminate"
            [active]="saveProcess.isRunning === true"
            [disabled]="deleteProcess.isRunning || testProcess.isRunning || infoProcess.isRunning || !isValid || !selectedEndPoint?.hasChanges"
            (shrClick)="save()" color="primary"></shr-progress-button-spinner>
        <div fxFlex></div>
        <shr-progress-button-spinner [raised]="true" [diameter]="30" text="Delete" mode="indeterminate"
            [active]="deleteProcess.isRunning === true"
            [disabled]="saveProcess.isRunning || testProcess.isRunning || infoProcess.isRunning || isNullOrUndefined(selectedEndPoint)"
            (shrClick)="delete()" color="warn"></shr-progress-button-spinner>
        <div fxFlex></div>
        <shr-progress-button-spinner [raised]="true" [diameter]="30" text="Test" mode="indeterminate"
            [active]="testProcess.isRunning === true"
            [disabled]="saveProcess.isRunning || deleteProcess.isRunning || infoProcess.isRunning || !isValid || selectedEndPoint?.hasChanges"
            (shrClick)="test()" color="primary"></shr-progress-button-spinner>
        <div fxFlex></div>
        <shr-progress-button-spinner [raised]="true" [diameter]="30" text="Info" mode="indeterminate"
            [active]="infoProcess.isRunning === true"
            [disabled]="saveProcess.isRunning || deleteProcess.isRunning || testProcess.isRunning || !isValid || selectedEndPoint?.hasChanges"
            (shrClick)="info()" color="primary"></shr-progress-button-spinner>
        <div fxFlex></div>
        <button
            [disabled]="saveProcess.isRunning === true || deleteProcess.isRunning === true"
            (click)="cancel()" mat-raised-button color="warn">Close</button>
    </div>
    <div *ngIf="!isNullOrUndefined(testResult)" class="em-settings-outboundconnections-managelocations-actions"
        mat-dialog-actions fxLayout="column">
        <p *ngIf="testResult?.success === true">The location test was successful.</p>
        <p *ngIf="testResult?.success === false">The location test failed.</p>
        <p *ngIf="testResult?.success === false">The following errors were returned.</p>
        <p *ngFor="let exception of testResult.exceptions">{{exception}}</p>
    </div>
    <div *ngIf="!isNullOrUndefined(profileResult)" class="em-settings-outboundconnections-managelocations-actions"
        mat-dialog-actions fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between start" style="margin-top: 10px;">
            <div fxLayout="column">
                <div fxLayout="row">
                    <div class="pr-title-h1">Attempts</div>
                    <div class="pr-value">{{profileResult?.attemptCount}}</div>
                </div>
                <div fxLayout="row">
                    <div class="pr-title">Average</div>
                    <div class="pr-value">{{profileResult?.attemptAverageMs | number }}ms</div>
                </div>
                <div fxLayout="row">
                    <div class="pr-title">Max</div>
                    <div class="pr-value">{{profileResult?.attemptMaxMs | number }}ms</div>
                </div>
                <div fxLayout="row">
                    <div class="pr-title">Min</div>
                    <div class="pr-value">{{profileResult?.attemptMinMs | number }}ms</div>
                </div>
                <div fxLayout="row">
                    <div class="pr-title-h1">Retries</div>
                    <div class="pr-value">{{profileResult?.retryCount}}</div>
                </div>
                <div fxLayout="row">
                    <div class="pr-title-h1">Failure Cache</div>
                    <div class="pr-value">{{profileResult?.cachedFailureCount}}</div>
                </div>
            </div>
            <div fxLayout="column">
                <div fxLayout="row">
                    <div class="pr-title-h1">Success</div>
                    <div class="pr-value">{{profileResult?.successCount}}</div>
                </div>
                <div fxLayout="row">
                    <div class="pr-title">Average</div>
                    <div class="pr-value">{{profileResult?.successAverageMs | number }}ms</div>
                </div>
                <div fxLayout="row">
                    <div class="pr-title">Max</div>
                    <div class="pr-value">{{profileResult?.successMaxMs | number }}ms</div>
                </div>
                <div fxLayout="row">
                    <div class="pr-title">Min</div>
                    <div class="pr-value">{{profileResult?.successMinMs | number }}ms</div>
                </div>
            </div>
            <div fxLayout="column">
                <div fxLayout="row">
                    <div class="pr-title-h1">Failed</div>
                    <div class="pr-value">{{profileResult?.failedCount}}</div>
                </div>
                <div fxLayout="row">
                    <div class="pr-title">Average</div>
                    <div class="pr-value">{{profileResult?.failedAverageMs | number }}ms</div>
                </div>
                <div fxLayout="row">
                    <div class="pr-title">Max</div>
                    <div class="pr-value">{{profileResult?.failedMaxMs | number }}ms</div>
                </div>
                <div fxLayout="row">
                    <div class="pr-title">Min</div>
                    <div class="pr-value">{{profileResult?.failedMinMs | number }}ms</div>
                </div>
            </div>
        </div>
    </div>
</form>