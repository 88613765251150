<div class="em-settings-firmware-version-summary">
    <div fxLayout="column">
        <div *ngIf="blackfinSummaries?.length > 0"
            class="versions spacing-bottom"
            fxLayout="row">
            <ng-template ngFor
                let-summary
                [ngForOf]="blackfinSummaries">
                <mat-card class="card"
                    (click)="summarySelected(summary)"
                    [class.selected]="selectedSummary?.uniqueId === summary.uniqueId">
                    <mat-card-title>
                        <span class="mat-subheading-2">{{ DeviceTypeEnumHelpers.toStringHumanized(summary.type) }}:
                            {{summary.firmwareVersion}} ({{summary.numberOfDevices}}) devices</span>
                    </mat-card-title>
                </mat-card>
            </ng-template>
        </div>
        <div *ngIf="gazelleSummaries?.length > 0"
            class="versions spacing-bottom"
            fxLayout="row">
            <ng-template ngFor
                let-summary
                [ngForOf]="gazelleSummaries">
                <mat-card class="card"
                    (click)="summarySelected(summary)"
                    [class.selected]="selectedSummary?.uniqueId === summary.uniqueId">
                    <mat-card-title>
                        <span class="mat-subheading-2">{{ DeviceTypeEnumHelpers.toStringHumanized(summary.type) }}:
                            {{summary.firmwareVersion}} ({{summary.numberOfDevices}}) devices</span>
                    </mat-card-title>
                </mat-card>
            </ng-template>
        </div>
        <div *ngIf="kestrelSummaries?.length > 0"
            class="versions spacing-bottom"
            fxLayout="row">
            <ng-template ngFor
                let-summary
                [ngForOf]="kestrelSummaries">
                <mat-card class="card"
                    (click)="summarySelected(summary)"
                    [class.selected]="selectedSummary?.uniqueId === summary.uniqueId">
                    <mat-card-title>
                        <span class="mat-subheading-2">{{ DeviceTypeEnumHelpers.toStringHumanized(summary.type) }}:
                            {{summary.firmwareVersion}} ({{summary.numberOfDevices}}) devices</span>
                    </mat-card-title>
                </mat-card>
            </ng-template>
        </div>
        <div *ngIf="falconSummaries?.length > 0"
            class="versions spacing-bottom"
            fxLayout="row">
            <ng-template ngFor
                let-summary
                [ngForOf]="falconSummaries">
                <mat-card class="card"
                    (click)="summarySelected(summary)"
                    [class.selected]="selectedSummary?.uniqueId === summary.uniqueId">
                    <mat-card-title>
                        <span class="mat-subheading-2">{{ DeviceTypeEnumHelpers.toStringHumanized(summary.type) }}:
                            {{summary.firmwareVersion}} ({{summary.numberOfDevices}}) devices</span>
                    </mat-card-title>
                </mat-card>
            </ng-template>
        </div>
        <mat-card>
            <mat-card-title>
                <div fxLayout="row">
                    <div fxLayout="column">
                        <div class="header">
                            {{ isNullOrUndefined(selectedSummary) ? 'Loading' :
                            DeviceTypeEnumHelpers.toStringHumanized(selectedSummary.type) + ': ' +
                            selectedSummary.firmwareVersion}}
                        </div>
                    </div>
                </div>
            </mat-card-title>
            <mat-card-content>
                <em-device-list style="width: 100%;"
                    [devices]="devices"
                    (pageOptionsChanged)="onPageOptionsChanged($event)"
                    [pageOptions]="pageOptions"></em-device-list>
            </mat-card-content>
        </mat-card>
    </div>
</div>