import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserModel } from '@em/models/restapi/User.Model';
import { UserDeviceDisplayColumnModel } from '@em/models/restapi/UserDeviceDisplayColumn.Model';
import { EmRestApiService } from '@em/service/restapi/base/EmRestApi.Service';
import { ChangeUserPasswordModel } from '@shared/models/restapi/ChangeUserPassword.Model';
import { LoginModel } from '@shared/models/restapi/Login.Model';
import { UserPasswordModel } from '@shared/models/restapi/UserPassword.Model';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MatDialog } from '@angular/material/dialog';
import { UpdateCurrentUserResponseModel } from '@em/models/restapi/UpdateCurrentUserResponse.Model';
import { LoginAttemptResultModel } from '@shared/models/restapi/LoginAttemptResult.Model';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { PreAuthResult } from '@em/models/restapi/PreAuthResult.Model';

@Injectable()
export class RestApiAccountService extends EmRestApiService {
    private _controller = 'account/';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.emRestApiBase, _httpClient);
    }

    public changeCurrentUserPassword(passwords: ChangeUserPasswordModel, process?: ProcessMonitorServiceProcess): Observable<UserPasswordModel> {
        return this.post<ChangeUserPasswordModel, UserPasswordModel>(`${this._controller}changecurrentuserpassword`, passwords, UserPasswordModel, null, process).pipe(
            map(result => {
                if (RestModelUtility.hasError(result)) {
                    const failed = new UserPasswordModel();
                    failed.changed = false;
                    return failed;
                } else {
                    result.changed = true;
                    return result;
                }
            })
        );
    }

    public getCurrentUser(process?: ProcessMonitorServiceProcess): Observable<UserModel> {
        return this.get<UserModel>(`${this._controller}getcurrentuser`, UserModel, null, process, {disabled: true}).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getQuickSetupFile(hostName: string, port: number, process?: ProcessMonitorServiceProcess): Observable<string> {
        let params = new HttpParams();
        params = params.append('hostName', hostName);
        params = params.append('port', isNullOrUndefined(port) || port === 0 ? '443' : StringUtility.toString(port));

        const url = `${this.urlBase}${this._controller}downloadquicksetupfile`;
        const req = new HttpRequest('GET', url, {
            responseType: 'application/xml',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return (this.request<string, null>(req, 'string', null, process, false) as Observable<string>).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public login(login: LoginModel, process?: ProcessMonitorServiceProcess): Observable<LoginAttemptResultModel> {
        return this.post<LoginModel, LoginAttemptResultModel>(`${this._controller}login`, login, LoginAttemptResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public logoff(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.get<boolean>(`${this._controller}logoff`, 'boolean', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public resetAPIKey(process?: ProcessMonitorServiceProcess): Observable<string> {
        return this.post<null, string>(`${this._controller}resetapikey`, null, 'string', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public saveUserColumnPreferences(columns: Array<UserDeviceDisplayColumnModel>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.post<UserDeviceDisplayColumnModel, boolean>(`${this._controller}saveusercolumnpreferences`, columns, 'boolean', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateCurrentUser(user: UserModel, process?: ProcessMonitorServiceProcess): Observable<UpdateCurrentUserResponseModel> {
        return this.post<UserModel, UpdateCurrentUserResponseModel>(`${this._controller}savecurrentuser`, user, UpdateCurrentUserResponseModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public preAuth(process?: ProcessMonitorServiceProcess): Observable<PreAuthResult> {
        return this.get<PreAuthResult>(`${this._controller}pre_auth`, PreAuthResult, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public availableAuth(process?: ProcessMonitorServiceProcess): Observable<PreAuthResult> {
        return this.get<PreAuthResult>(`${this._controller}available_auth`, PreAuthResult, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
