<div class="breadcrumb" fxLayout="row">
    <ng-template ngFor let-crumb [ngForOf]="crumbs" let-i="index">
        <ng-template [ngIf]="crumb.isLast === false && crumb.isCustomEvent === false">
            <a [shrDeviceInfoTooltip]="crumb.deviceInfo" class="crumb" [routerLink]="crumb.routerLink">
                {{crumb.text}}
            </a>
            <span class="divider">/</span>
        </ng-template>
        <ng-template [ngIf]="crumb.isLast === false && crumb.isCustomEvent === true">
            <a [shrDeviceInfoTooltip]="crumb.deviceInfo" class="crumb" (click)="onClicked(crumb)">
                {{crumb.text}}
            </a>
            <span class="divider">/</span>
        </ng-template>
        <ng-template [ngIf]="crumb.isLast === true">
            <span [shrDeviceInfoTooltip]="crumb.deviceInfo" class="crumb-last">
                {{crumb.text}}
            </span>
        </ng-template>
    </ng-template>
</div>
