import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, HostBinding, Injector, AfterViewInit, OnInit, HostListener } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { EventsService } from '@shared/service/events/Events.Service';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map, switchMap, tap } from 'rxjs/operators';
import { DeviceLiveDataRemovalModel } from '@em/models/restapi/DeviceLiveDataRemoval.Model';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';


@Component({
    selector: 'em-rift-delete-live-data',
    templateUrl: './Rift.DeleteLiveData.Component.html',
    styleUrls: ['./Rift.DeleteLiveData.Component.scss'],
})
export class RiftDeleteLiveDataComponent extends RiftBaseComponent implements OnInit, ILoadDate, ISaveAllChanges {
    public loadStateProcess: ProcessMonitorServiceProcess;
    public deleteLiveDataState: DeviceLiveDataRemovalModel = new DeviceLiveDataRemovalModel();
    public initialState: DeviceLiveDataRemovalModel = new DeviceLiveDataRemovalModel();
    public stateExists: boolean = false;

    public hostDevice: DeviceModel = null;

    @HostBinding()
    public id: string = 'em-rift-delete-live-data';

    public constructor(
        private readonly _eventsService: EventsService,
        private readonly _dialog: MatDialog,
        private readonly _dialogRef: MatDialogRef<RiftDeleteLiveDataComponent>,
        private readonly _deviceService: DeviceService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess('RiftDeleteLiveDataComponent', this.loadDataProcessText);
        this.loadStateProcess = this.processMonitorService.getProcess('RiftDeleteLiveDataComponent', 'Loading State');
        this.saveAllChangesProcess = this.processMonitorService.getProcess('RiftDeleteLiveDataComponent', this.saveAllChangesProcessText);

        this.initConnectionState();

        this.changeTracker.track(this.deleteLiveDataState);
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public onCancelClicked(): void {
        this._dialogRef.close();
    }

    public onSaveClicked(): void{
        this.saveAllChangesStartBase(this, this.openPleaseWaitSavingDialog());
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => of(true));
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.getHostDevice(process).pipe(
                tap(hostDevice => {
                    if (!this.isNullOrUndefined(hostDevice)){
                        this.hostDevice = hostDevice;
                        return true;
                    }

                    return false;
                }),
                switchMap(val => this._deviceService.getDeviceLiveDataRemovalState(this.hostDevice.serialNumber, process).pipe(
                            map(result => {
                                if(this.isNullOrUndefined(result)){
                                    this.stateExists = false;
                                    this.deleteLiveDataState.deviceSerial = this.hostDevice.serialNumber;
                                    this.deleteLiveDataState.countLogs = false;
                                    this.deleteLiveDataState.diagnosticLogs = false;
                                    this.deleteLiveDataState.histogramLogs = false;
                                    this.changeTracker.commitChanges();
                                }
                                else{
                                    this.stateExists = true;
                                    this.deleteLiveDataState = result;
                                    this.changeTracker.track(this.deleteLiveDataState);

                                    this.initialState.countLogs = this.deleteLiveDataState.countLogs;
                                    this.initialState.diagnosticLogs = this.deleteLiveDataState.diagnosticLogs;
                                    this.initialState.histogramLogs = this.deleteLiveDataState.histogramLogs;
                                }

                                return true;
                            })
                        )
                )
            )
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            this._deviceService.addOrUpdateLiveDataRemovalState(this.deleteLiveDataState, process).pipe(
                map(() => {
                    this._dialogRef.close();
                    return true;
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process);
    }


    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }
}
