import { Injectable } from '@angular/core';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { UnitOfMeasurementEnum, UnitOfMeasurementEnumHelpers } from '@shared/enum/UnitOfMeasurement.Enum';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';

export interface IUomValues {
    imperialFractionDigits?: number;
    imperialUnit?: UnitOfMeasurementEnum;
    metricUnit?: UnitOfMeasurementEnum;
    metricValue?: number;
    unitShortName?: boolean;
}

@Injectable()
export class UnitsOfMeasurementService extends RiftBaseService {

    public unitsChange: Subject<UnitsOfMeasurementEnum> = new Subject<UnitsOfMeasurementEnum>();

    @LocalStorage('UnitsOfMeasurementService', 'units')
    private _units: UnitsOfMeasurementEnum = UnitsOfMeasurementEnum.metric;
    private readonly imperials = {
        in: 1,
        ft: 12,
        yd: 36,
    };

    private readonly metrics = {
        mm: 10,
        cm: 1,
        m: .01,
    };

    public convertTo(units: UnitsOfMeasurementEnum, value: number, metricUnit: UnitOfMeasurementEnum, imperialUnit: UnitOfMeasurementEnum, fractionDigits?: number): number {
        if (units === UnitsOfMeasurementEnum.imperial) {
            return this.convertToImperial(value, metricUnit, imperialUnit, fractionDigits);
        } else if (units === UnitsOfMeasurementEnum.metric) {
            return this.convertToMetric(value, metricUnit, imperialUnit, fractionDigits);
        }
    }

    public convertToImperial(value: number, metricUnit: UnitOfMeasurementEnum, imperialUnit: UnitOfMeasurementEnum, fractionDigits?: number): number {
        const centimeters = value / this.metrics[UnitOfMeasurementEnumHelpers.toShortName(metricUnit)];
        const inches = centimeters / 2.54;
        const ret = inches / this.imperials[UnitOfMeasurementEnumHelpers.toShortName(imperialUnit)];
        return isNullOrUndefined(fractionDigits) ? ret : parseFloat(ret.toFixed(fractionDigits));
    }

    public convertToMetric(value: number, metricUnit: UnitOfMeasurementEnum, imperialUnit: UnitOfMeasurementEnum, fractionDigits?: number): number {
        const inches = value * this.imperials[UnitOfMeasurementEnumHelpers.toShortName(imperialUnit)];
        const centimeters = inches * 2.54;
        const ret = centimeters * this.metrics[UnitOfMeasurementEnumHelpers.toShortName(metricUnit)];
        return isNullOrUndefined(fractionDigits) ? ret : parseFloat(ret.toFixed(fractionDigits));
    }

    public getSuffix(metricUnit: UnitOfMeasurementEnum, imperialUnit: UnitOfMeasurementEnum, unitShortName: boolean = true): string {
        return this.units === UnitsOfMeasurementEnum.metric ?
            unitShortName === true ? UnitOfMeasurementEnumHelpers.toShortName(metricUnit) : UnitOfMeasurementEnumHelpers.toLongName(metricUnit)
            :
            unitShortName === true ? UnitOfMeasurementEnumHelpers.toShortName(imperialUnit) : UnitOfMeasurementEnumHelpers.toLongName(imperialUnit);
    }

    public parseUOMString(uom: string): string {
        const matches = uom.match(/uom:(\{{1}[^\}]*\}{1})/g);
        const matchesLength = matches.length;
        if (matchesLength > 0) {
            for (let i = 0; i < matchesLength; i++) {
                const match = matches[i];
                if (!isNullOrUndefined(match)) {
                    const json = match.replace('uom:', '');
                    try {
                        const uomValues: IUomValues = JSON.parse(json);
                        const value = this.getUOMReplaceString(uomValues);
                        uom = uom.replace(match, value);
                    } catch (e) {
                        if (!isNullOrUndefined(e) && !isNullOrUndefined(e.message)) {
                            console.log(`uom:error:${e.message}`);
                        }
                    }
                }
            }
        }
        return uom;
    }

    public get units(): UnitsOfMeasurementEnum {
        return this._units;
    }

    public set units(value: UnitsOfMeasurementEnum) {
        if (this._units !== value) {
            this._units = value;
            this.unitsChange.next(value);
        }
    }

    private getUOMReplaceString(uomValues: IUomValues): string {
        let value: string = null;

        if (isNullOrUndefined(uomValues.imperialFractionDigits)) {
            uomValues.imperialFractionDigits = 2;
        }

        if (!isNullOrUndefined(uomValues.metricValue) && !isNullOrUndefined(uomValues.metricUnit) && !isNullOrUndefined(uomValues.imperialUnit)) {
            if (this.units === UnitsOfMeasurementEnum.metric) {
                value += uomValues.metricValue;
            } else {
                value += this.convertToImperial(uomValues.metricValue, uomValues.metricUnit, uomValues.imperialUnit, uomValues.imperialFractionDigits);
            }
            value += ' ' + this.getSuffix(uomValues.metricUnit, uomValues.imperialUnit, uomValues.unitShortName);
        }

        return value;
    }
}
