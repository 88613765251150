import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';
import { ConnectionStatusEnum } from '@shared/enum/ConnectionStatus.Enum';

export interface IConnectionStatusModel {
    connectionStatus: number;
}

export class ConnectionStatusModel extends BaseModel implements IConnectionStatusModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'device_connection_status';

    public isIWebSocketModel: boolean = true;
    public packetType: string = null;
    public id: number = null;

    public connectionStatus: ConnectionStatusEnum = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-ConnectionStatusModel', ['connectionStatus']);


        switch (message.connectionStatus) {
            case 'Online':
                this.connectionStatus = ConnectionStatusEnum.online;
                break;
            case 'Offline':
                this.connectionStatus = ConnectionStatusEnum.offline;
                break;
            case 'Disconnected':
                this.connectionStatus = ConnectionStatusEnum.disconnected;
                break;
        }
    }

    public toWebSocketMessage(): any {
        return {
            ...WebSocketModelUtility.toJson(this),
        };
    }
}
