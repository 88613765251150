<mat-card class="spacing-bottom">
    <mat-card-title fxLayout="row"
        fxLayoutAlign="center center">
        <span *ngIf="count === 0">
            There are no devices at health level&nbsp;
        </span>
        <span *ngIf="count > 0">
            There {{count > 1 ? 'are' : 'is'}} {{count}} {{count > 1 ? 'devices' : 'device'}}, {{percentage | number : '1.0-0'}}% of your estate at health level&nbsp;
        </span>
        <span style="text-transform: lowercase;">
            {{deviceHealthService.getLevelName(level)}}
        </span>
        <i [style.color]="deviceHealthService.getLevelColor(level)"
            class="spacing-left icon-overall fa"
            [class.fa-thumbs-up]="level === 1 || level === 2"
            [class.fa-thumbs-down]="level === 3"></i>
    </mat-card-title>
</mat-card>
<shr-table [idPreFix]="id"
    [isLoadingData]="loadDataProcess.isRunning"
    [rowMouseHover]="true"
    (rowClicked)="rowClicked($event)"
    (filter)="applyFilter($event)"
    [title]="title"
    [dataSource]="dataSource"
    [pageSize]="10"
    [pageShowFirstLastButtons]="true"
    [displayedColumns]="displayedColumns">

    <ng-container matColumnDef="status">
        <th mat-header-cell
            *matHeaderCellDef> </th>
        <td mat-cell
            *matCellDef="let device">
            <i [style.color]="deviceHealthService.getLevelColor(device.overall.level)"
                [class.fa-thumbs-up]="device.overall.level === 1 || device.overall.level === 2"
                [class.fa-thumbs-down]="device.overall.level === 3"
                class="icon-overall fa"></i>
        </td>
    </ng-container>

    <ng-container matColumnDef="serial">
        <th mat-header-cell
            *matHeaderCellDef> Serial # </th>
        <td mat-cell
            *matCellDef="let device">
            <shr-table-cell [data]="device"
                keyPath="serial"></shr-table-cell>
        </td>
    </ng-container>

    <ng-container matColumnDef="connectionStatus">
        <th mat-header-cell
            *matHeaderCellDef> Connection Status </th>
        <td mat-cell
            *matCellDef="let device">
            <span [style.color]="device.isConnected ? 'green' : 'red'"
                matTooltip="{{device.isConnected ? 'Connected' : 'Disconnected'}}">{{device.isConnected
                        ? 'Connected' : 'Disconnected'}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="lastActivity">
        <th mat-header-cell
            *matHeaderCellDef> Last Activity </th>
        <td mat-cell
            *matCellDef="let device">
            <shr-table-cell [data]="device"
                keyPath="lastActivityText"></shr-table-cell>
        </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell
            *matHeaderCellDef>Action (click for more details)</th>
        <td mat-cell
            *matCellDef="let device">
            <shr-table-cell [data]="device"
                keyPath="recommendedAction"></shr-table-cell>
        </td>
    </ng-container>
</shr-table>