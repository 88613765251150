import { NgZone, Directive } from '@angular/core';
import { DeviceSpriteBuilder } from '@rift/components/shared/viewport/devices/DeviceSpriteBuilder';
import { Grid } from '@rift/components/shared/viewport/grid/Grid';
import { Video } from '@rift/components/shared/viewport/video/Video';
import { VideoSettings } from '@rift/components/shared/viewport/video/VideoSettings';
import { ViewPortModeEnum } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { FOVCoveragePointModel } from '@rift/models/restapi/FOVCoveragePoint.Model';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';

@Directive()
export class Device extends DisplayItem {
    public deviceClick: Subject<Device> = new Subject<Device>();
    public fovClickEnabled: boolean = false;

    private _deviceSprite: createjs.Sprite = null;

    private _deviceSpriteBuilder: DeviceSpriteBuilder = null;
    private _fieldOfViewSprite: createjs.Sprite = null;
    private _selected: boolean = false;
    private _video: Video = null;
    private _videoSettings: VideoSettings = null;

    public constructor(
        private readonly _zone: NgZone,
        private readonly _loadQueue: ViewPortLoadQueueService,
        private readonly _deviceModel: DeviceModel,
    ) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            this.container.x = (Grid.Width / 2);
            this.container.y = (Grid.Height / 2);

            if (this._loadQueue.isLoaded === true) {
                this.onLoadQueueComplete();
            } else {
                this.addSubscription(this._loadQueue.loaded.subscribe(() => this.onLoadQueueComplete()));
            }

            this._video = new Video(_zone, this);
            this._video.requireStageUpdate.subscribe(() => this.requireStageUpdate.next());

            this._deviceSpriteBuilder = new DeviceSpriteBuilder(this._zone, this._deviceModel);

            this.addEventHandlers();
        });
    }

    public bringToFront(): void {
    }

    public createVideoSettings(width?: number, height?: number, devices?: Array<Device>): VideoSettings {
        return this._zone.runOutsideAngular(() => {
            this._videoSettings = null;
            this._videoSettings = new VideoSettings(width, height, this, devices);
            return this._videoSettings;
        });
    }

    public get deviceModel(): DeviceModel {
        return this._zone.runOutsideAngular(() => this._deviceModel);
    }

    public getBounds(): createjs.Rectangle {
        return this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._fieldOfViewSprite)) {
                return this._fieldOfViewSprite.getBounds();
            }
            if (!isNullOrUndefined(this._deviceSprite)) {
                return this._deviceSprite.getBounds();
            }
            return new createjs.Rectangle(0, 0, 0, 0);
        });
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();

            this.container.removeAllChildren();

            this._video.onDestroy();
            this._deviceSprite = null;
            this._fieldOfViewSprite = null;
        });
    }

    public rebuildFieldOfViewSprite(outerCoverage?: Array<FOVCoveragePointModel>, innerCoverage?: Array<FOVCoveragePointModel>): void {
        this._zone.runOutsideAngular(() => {
            this.container.removeChild(this._fieldOfViewSprite);
            this._deviceSpriteBuilder.buildFieldOfView(outerCoverage, innerCoverage);
            if (this._selected === true) {
                this._fieldOfViewSprite = this._deviceSpriteBuilder.getSelectedFieldOfViewSprite();
            } else {
                this._fieldOfViewSprite = this._deviceSpriteBuilder.getFieldOfViewSprite();
            }
            this.container.addChild(this._fieldOfViewSprite);
            this.update();
        });
    }

    public get selected(): boolean {
        return this._zone.runOutsideAngular(() => this._selected);
    }

    public set selected(value: boolean) {
        this._zone.runOutsideAngular(() => {
            if (this._selected !== value) {
                this._selected = value;
                if (this.mode === ViewPortModeEnum.edit || this.mode === ViewPortModeEnum.view) {
                    if (this._selected === true) {
                        this._fieldOfViewSprite = this._deviceSpriteBuilder.getSelectedFieldOfViewSprite();
                    } else {
                        this._fieldOfViewSprite = this._deviceSpriteBuilder.getFieldOfViewSprite();
                    }
                } else if (this.mode === ViewPortModeEnum.deviceGraphics) {
                    if (this._selected === true) {
                        this._deviceSprite = this._deviceSpriteBuilder.getSelectedDeviceSprite(this.deviceModel);
                    } else {
                        this._deviceSprite = this._deviceSpriteBuilder.getDeviceSprite(this.deviceModel);
                    }
                }
                this.update();
            }
        });
    }

    public get showFieldOfView(): boolean {
        return this._zone.runOutsideAngular(() => this._fieldOfViewSprite.visible);
    }

    public set showFieldOfView(value: boolean) {
        this._zone.runOutsideAngular(() => {
            if (this._fieldOfViewSprite.visible !== value) {
                this._fieldOfViewSprite.visible = value;
            }
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            this.requireStageUpdate.next();
        });
    }

    public get video(): Video {
        return this._zone.runOutsideAngular(() => this._video);
    }

    public get videoSettings(): VideoSettings {
        return this._zone.runOutsideAngular(() => this._videoSettings);
    }

    protected addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
        });
    }

    protected modeSet(): void {
        this._zone.runOutsideAngular(() => {
            this.video.mode = this.mode;

            if (this.mode === ViewPortModeEnum.edit || this.mode === ViewPortModeEnum.view) {
                this.container.removeChild(this._deviceSprite);

                if (isNullOrUndefined(this._fieldOfViewSprite)) {
                    this._fieldOfViewSprite = this._deviceSpriteBuilder.getFieldOfViewSprite();
                }

                this.container.addChild(this._fieldOfViewSprite);

                if (this.fovClickEnabled === true){
                    this._fieldOfViewSprite.on('click', this.onFieldOfViewClick.bind(this));
                }

            } else if (this.mode === ViewPortModeEnum.deviceGraphics) {
                this.container.removeChild(this._fieldOfViewSprite);

                this._deviceSprite = this._deviceSpriteBuilder.getDeviceSprite(this.deviceModel);

                const bounds = this._deviceSprite.getBounds();
                this._deviceSprite.regX = bounds.width / 2;
                this._deviceSprite.regY = bounds.height / 2;
                this._deviceSprite.x = this.deviceModel.x;
                this._deviceSprite.y = this.deviceModel.y;

                this.container.addChild(this._deviceSprite);

                this._deviceSprite.on('click', this.onDeviceSpriteClick.bind(this));
            }

            this.update();
        });
    }

    protected onDeviceSpriteClick(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.deviceClick.next(this);
        });
    }

    protected onFieldOfViewClick(event: createjs.MouseEvent): void{
        this._zone.runOutsideAngular(() => {
            this.deviceClick.next(this);
        });
    }

    protected removeEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._deviceSprite)) {
                this._deviceSprite.removeAllEventListeners();
            }

            if (!isNullOrUndefined(this._fieldOfViewSprite)) {
                this._fieldOfViewSprite.removeAllEventListeners();
            }
        });
    }

    private onLoadQueueComplete(): void {
        this._zone.runOutsideAngular(() => {
        });
    }
}
