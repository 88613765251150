<div fxLayout="row" class="spacing-bottom">
    <shr-background-load-spinner [trigger]="getTaskPageProcess.isRunning" class="led"></shr-background-load-spinner>
    <nav fxFlex mat-tab-nav-bar fxLayout="row" fxLayoutAlign="center center">
        <a mat-tab-link routerLink="/settings/tasklaucher/list/active" routerLinkActive #active="routerLinkActive"
            [active]="active.isActive">
            Active ({{activeCount}})
        </a>
        <a mat-tab-link routerLink="/settings/tasklaucher/list/failed" routerLinkActive #finished="routerLinkActive"
            [active]="finished.isActive">
            Failed ({{failedCount}})
        </a>
        <a mat-tab-link routerLink="/settings/tasklaucher/list/disconnected" routerLinkActive
            #disabled="routerLinkActive" [active]="disabled.isActive">
            Disconnected ({{disconnectedCount}})
        </a>
        <a mat-tab-link routerLink="/settings/tasklaucher/list/complete" routerLinkActive #system="routerLinkActive"
            [active]="system.isActive">
            Complete ({{completeCount}})
        </a>
    </nav>
</div>

<ng-template [ngIf]="enabled === false">
    <mat-card class="spacing-bottom">
        <mat-card-title>
            <div fxLayout="row">
                <div fxLayout="column">
                    <div class="header">
                        Task Manager is disabled in the Options
                    </div>
                </div>
            </div>
        </mat-card-title>
        <mat-card-content>
        </mat-card-content>
    </mat-card>
</ng-template>

<ng-template
    [ngIf]="enabled === true && mode === 'active' && !isNullOrUndefined(dataSourcePaused) && dataSourcePaused.data.length > 0">
    <mat-card class="spacing-bottom">
        <mat-card-title>
            <div fxLayout="row">
                <div fxLayout="column">
                    <div class="header">
                        <mat-icon>warning</mat-icon>
                        Task Launcher Paused
                    </div>
                </div>
            </div>
        </mat-card-title>
        <mat-card-content>
            Task Launcher has been paused as a task, shown below, has failed. You can choose to skip or retry the failed
            task. Retrying
            will recreate the task leaving the logs for the failure intact.
        </mat-card-content>
        <mat-card-actions>
            <div fxFlex></div>
            <button (click)="retry(dataSourcePaused.data[0])" mat-raised-button color="primary"
                class="spacing-right">Retry</button>
            <button (click)="skip(dataSourcePaused.data[0])" mat-raised-button color="warn">Skip</button>
        </mat-card-actions>
    </mat-card>
</ng-template>

<!-- Active -->
<ng-template [ngIf]="enabled === true && (mode !== 'active' || dataSource.data.length > 0)">
    <shr-table [isLoadingData]="getTaskPageProcess.isRunning"
        [dataRefreshedTrigger]="refreshingTaskPageProcess.isRunning" class="spacing-bottom" [serverPage]="true"
        [filterEnabled]="false" (page)="onPageChanged($event)" [pageIndex]="pageOptions.page - 1"
        [recordLength]="pageOptions.totalResults" [pageSize]="pageOptions.resultsPerPage" [dataSource]="dataSource"
        [displayedColumns]="displayedColumns" [dataExportEnabled]="true" [dataExportGetDataHandler]="dataExportGetData"
        [dataExportGetHeaderHandler]="dataExportGetHeader">

        <ng-template #shrTableActions let-task>
            <button matTooltip="View" mat-icon-button (click)="view(task)">
                <mat-icon>pageview</mat-icon>
            </button>
            <button matTooltip="Retry" mat-icon-button (click)="retry(task)"
                *ngIf="task.taskState === TaskToExecuteStateEnum.failedExecutionPaused">
                <mat-icon>refresh</mat-icon>
            </button>
            <button matTooltip="Skip" mat-icon-button (click)="skip(task)" color="warn"
                *ngIf="task.taskState === TaskToExecuteStateEnum.failedExecutionPaused">
                <mat-icon>skip_next</mat-icon>
            </button>
            <button matTooltip="Delete" mat-icon-button (click)="delete(task)" color="warn"
                *ngIf="task.taskState === TaskToExecuteStateEnum.readyToExecute">
                <mat-icon>delete</mat-icon>
            </button>
        </ng-template>

        <ng-container matColumnDef="icons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let task">
                <mat-spinner *ngIf="task.isCurrentlyExecuting === true" diameter="20"></mat-spinner>
            </td>
        </ng-container>

        <ng-container matColumnDef="finished">
            <th mat-header-cell *matHeaderCellDef> {{ mode === 'active' ? 'Added' : 'Finished'}} </th>
            <td mat-cell *matCellDef="let task">
                <span
                    matTooltip="{{mode === 'active' ? task.timeAddedText : task.timeFinishedText}}">{{mode === 'active' ? task.timeAddedText : task.timeFinishedText}}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let task">
                <shr-table-cell [data]="task" keyPath="taskName"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="device">
            <th mat-header-cell *matHeaderCellDef> Device </th>
            <td mat-cell *matCellDef="let task">
                <em-device-serial-link [serial]="task.friendlyDeviceSerial" viewMode="link">
                </em-device-serial-link>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let task">
                <span
                    matTooltip="{{task.taskState}}">{{TaskToExecuteStateEnumHelpers.toStringHumanized(task.taskState)}}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef> Duration </th>
            <td mat-cell *matCellDef="let task">
                <span
                    matTooltip="{{task.isCurrentlyExecuting === true ? 'Executing' : task.durationText}}">{{task.isCurrentlyExecuting === true ? 'Executing' : task.durationText}}</span>
            </td>
        </ng-container>
    </shr-table>
</ng-template>

<ng-template
    [ngIf]="enabled === true && mode === 'active' && dataSource.data.length === 0 && dataSourcePaused.data.length === 0 && dataSourceQueued.data.length === 0">
    <mat-card class="spacing-bottom">
        <mat-card-title>
            <div fxLayout="row">
                <div fxLayout="column">
                    <div class="header">
                        There no active tasks
                    </div>
                </div>
            </div>
        </mat-card-title>
        <mat-card-content>
        </mat-card-content>
    </mat-card>
</ng-template>

<!-- Paused -->
<ng-template [ngIf]="enabled === true && mode === 'active'">
    <ng-template [ngIf]="dataSourcePaused.data.length > 0">
        <shr-table [isLoadingData]="getTaskPageProcess.isRunning"
            [dataRefreshedTrigger]="refreshingTaskPageProcess.isRunning" [serverPage]="true" class="spacing-bottom"
            title="Paused" [filterEnabled]="false" [dataSource]="dataSourcePaused" [displayedColumns]="displayedColumns"
            (page)="onPageChangedPaused($event)" [pageIndex]="pageOptionsPaused.page - 1"
            [recordLength]="pageOptionsPaused.totalResults" [pageSize]="pageOptionsPaused.resultsPerPage"
            [dataExportEnabled]="true" [dataExportGetDataHandler]="dataExportGetData"
            [dataExportGetHeaderHandler]="dataExportGetHeader">
            <ng-template #shrTableActions let-task>
                <button matTooltip="View" mat-icon-button (click)="view(task)">
                    <mat-icon>pageview</mat-icon>
                </button>
                <button matTooltip="Retry" mat-icon-button (click)="retry(task)"
                    *ngIf="task.taskState === TaskToExecuteStateEnum.failedExecutionPaused">
                    <mat-icon>refresh</mat-icon>
                </button>
                <button matTooltip="Skip" mat-icon-button (click)="skip(task)" color="warn"
                    *ngIf="task.taskState === TaskToExecuteStateEnum.failedExecutionPaused">
                    <mat-icon>skip_next</mat-icon>
                </button>
                <button matTooltip="Delete" mat-icon-button (click)="delete(task)" color="warn"
                    *ngIf="task.taskState === TaskToExecuteStateEnum.readyToExecute">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>

            <ng-container matColumnDef="icons">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let task">
                    <mat-spinner *ngIf="task.isCurrentlyExecuting === true" diameter="20"></mat-spinner>
                </td>
            </ng-container>

            <ng-container matColumnDef="finished">
                <th mat-header-cell *matHeaderCellDef> {{ mode === 'active' ? 'Added' : 'Finished'}} </th>
                <td mat-cell *matCellDef="let task">
                    <span
                        matTooltip="{{mode === 'active' ? task.timeAddedText : task.timeFinishedText}}">{{mode === 'active' ? task.timeAddedText : task.timeFinishedText}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let task">
                    <span matTooltip="{{task.taskName}}">{{task.taskName}} </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="device">
                <th mat-header-cell *matHeaderCellDef> Device </th>
                <td mat-cell *matCellDef="let task">
                    <em-device-serial-link [serial]="task.friendlyDeviceSerial" viewMode="link">
                    </em-device-serial-link>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let task">
                    <span
                        matTooltip="{{task.taskState}}">{{TaskToExecuteStateEnumHelpers.toStringHumanized(task.taskState)}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef> Duration </th>
                <td mat-cell *matCellDef="let task">
                    <span
                        matTooltip="{{task.isCurrentlyExecuting === true ? 'Executing' : task.durationText}}">{{task.isCurrentlyExecuting === true ? 'Executing' : task.durationText}}</span>
                </td>
            </ng-container>
        </shr-table>
    </ng-template>

    <ng-template [ngIf]="dataSourceQueued.data.length > 0">
        <shr-table [isLoadingData]="getTaskPageProcess.isRunning"
            [dataRefreshedTrigger]="refreshingTaskPageProcess.isRunning" [serverPage]="true" title="Queued"
            [dataSource]="dataSourceQueued" [displayedColumns]="displayedColumns" (page)="onPageChangedeQueued($event)"
            [pageIndex]="pageOptionsQueued.page - 1" [recordLength]="pageOptionsQueued.totalResults"
            [pageSize]="pageOptionsQueued.resultsPerPage" [dataExportEnabled]="true"
            [dataExportGetDataHandler]="dataExportGetData" [dataExportGetHeaderHandler]="dataExportGetHeader">
            <ng-template #shrTableActions let-task>
                <button matTooltip="View" mat-icon-button (click)="view(task)">
                    <mat-icon>pageview</mat-icon>
                </button>
                <button matTooltip="Retry" mat-icon-button (click)="retry(task)"
                    *ngIf="task.taskState === TaskToExecuteStateEnum.failedExecutionPaused">
                    <mat-icon>refresh</mat-icon>
                </button>
                <button matTooltip="Skip" mat-icon-button (click)="skip(task)" color="warn"
                    *ngIf="task.taskState === TaskToExecuteStateEnum.failedExecutionPaused">
                    <mat-icon>skip_next</mat-icon>
                </button>
                <button matTooltip="Delete" mat-icon-button (click)="delete(task)" color="warn"
                    *ngIf="task.taskState === TaskToExecuteStateEnum.readyToExecute">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>

            <ng-container matColumnDef="icons">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let task">
                    <mat-spinner *ngIf="task.isCurrentlyExecuting === true" diameter="20"></mat-spinner>
                </td>
            </ng-container>

            <ng-container matColumnDef="finished">
                <th mat-header-cell *matHeaderCellDef> {{ mode === 'active' ? 'Added' : 'Finished'}} </th>
                <td mat-cell *matCellDef="let task">
                    <span
                        matTooltip="{{mode === 'active' ? task.timeAddedText : task.timeFinishedText}}">{{mode === 'active' ? task.timeAddedText : task.timeFinishedText}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let task">
                    <span matTooltip="{{task.taskName}}">{{task.taskName}} </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="device">
                <th mat-header-cell *matHeaderCellDef> Device </th>
                <td mat-cell *matCellDef="let task">
                    <em-device-serial-link [serial]="task.friendlyDeviceSerial" viewMode="link">
                    </em-device-serial-link>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let task">
                    <span
                        matTooltip="{{task.taskState}}">{{TaskToExecuteStateEnumHelpers.toStringHumanized(task.taskState)}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef> Duration </th>
                <td mat-cell *matCellDef="let task">
                    <span
                        matTooltip="{{task.isCurrentlyExecuting === true ? 'Executing' : task.durationText}}">{{task.isCurrentlyExecuting === true ? 'Executing' : task.durationText}}</span>
                </td>
            </ng-container>
        </shr-table>
    </ng-template>
</ng-template>
