import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IConnectionOptionPropertyModel extends IRestModel {
    name: string;
    value: string;
}

export class ConnectionOptionPropertyModel extends BaseModel implements IConnectionOptionPropertyModel, IRestModel {
    public readonly isIRestModel = true;

    public name: string = null;
    public value: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ConnectionOptionPropertyModel');
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
