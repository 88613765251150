import { Component, HostBinding, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EndPointModel } from '@em/models/restapi/EndPoint.Model';
import { EndPointCollectionModel } from '@em/models/restapi/EndPointCollection.Model';
import { NotificationRuleModel } from '@em/models/restapi/NotificationRule.Model';
import { UserService } from '@em/service/data/user/User.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { StringUtility } from '@shared/utility/String.Utility';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

export class SettingsNotificationsAddEditData {
    public static className: string = 'SettingsNotificationsAddEditData';

    public constructor(public readonly rule: NotificationRuleModel, public readonly endPoints: EndPointCollectionModel) { }
}

export class SettingsNotificationsAddEditResult {
    public static className: string = 'SettingsNotificationsAddEditResult';

    public constructor(public readonly rule?: NotificationRuleModel) { }
}

@Component({
    selector: 'em-settings-notifications-add-edit-component',
    templateUrl: './Settings.Notifications.AddEdit.Component.html',
    styleUrls: ['./Settings.Notifications.AddEdit.Component.scss']
})
export class SettingsNotificationsAddEditComponent extends BaseComponent implements OnInit {
    public static className: string = 'SettingsNotificationsAddEditComponent';

    @HostBinding()
    public id: string = 'em-settings-notifications-add-edit-component';

    public rule: NotificationRuleModel;
    public endPoints: EndPointCollectionModel;
    public formGroup: FormGroup;
    public addingEndPoint: boolean = false;
    public formValuesChangesProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _userService: UserService,
        private readonly _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private readonly _data: SettingsNotificationsAddEditData,
        private readonly _dialogRef: MatDialogRef<SettingsNotificationsAddEditComponent>,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.rule = this._data.rule;
        this.changeTracker.track(this.rule);
        this.endPoints = this._data.endPoints;

        this.formValuesChangesProcess = this.processMonitorService.getProcess(SettingsNotificationsAddEditComponent.className, 'Form values change');

        this.formGroup = this._formBuilder.group({
            groupId: ['', Validators.compose([])],
            friendlySerialNumber: ['', Validators.compose([])],
        });
        this.formGroupTracker.track(this.formGroup);

        this.setOperateOnValidators();
        this.setFormGroupFromRule();

        this.addSubscription(this.observableHandlerBase(this.formGroup.valueChanges, this.formValuesChangesProcess).subscribe(status => {
            this.updateRuleFromGroup();
        }));
    }

    public get isValid(): boolean {
        return this.isValidBase && this.rule.endPoints.length > 0 && !this.rule.endPoints.some(i => this.isNullOrUndefined(i.endPointId));
    }

    public close(): void {
        this._dialogRef.close(new SettingsNotificationsAddEditResult(this.rule));
    }

    public cancel(): void {
        this._dialogRef.close(new SettingsNotificationsAddEditResult());
    }

    public addEndPoint(rule: NotificationRuleModel): void {
        const endPoint = new EndPointModel();
        rule.endPoints.push(endPoint);
        this.addingEndPoint = true;
    }

    public deleteEndPoint(endPoint: EndPointModel, rule: NotificationRuleModel): void {
        if (!this.isNullOrUndefined(endPoint.endPointId)) {
            const endPointIndex = rule.endPoints.findIndex(i => i.uniqueId === endPoint.uniqueId);
            if (endPointIndex !== -1) {
                rule.endPoints.splice(endPointIndex, 1);
            }
        } else {
            rule.endPoints.splice(rule.endPoints.length - 1, 1);
            this.addingEndPoint = false;
        }
    }

    public addEndPointSelectionChange(event: MatSelectChange): void {
        const addEndPoint = this.rule.endPoints[this.rule.endPoints.length - 1];
        const selectedEndpoint = event.value as EndPointModel;

        if (!this.isNullOrUndefined(selectedEndpoint) && !this.isNullOrUndefined(selectedEndpoint.endPointId)) {
            addEndPoint.endPointId = selectedEndpoint.endPointId;
            addEndPoint.maxRetrys = selectedEndpoint.maxRetrys;
            addEndPoint.retryDelay = selectedEndpoint.retryDelay;
            addEndPoint.userName = selectedEndpoint.userName;
            addEndPoint.password = selectedEndpoint.password;
            addEndPoint.text = selectedEndpoint.text;
            addEndPoint.address = selectedEndpoint.address;
            addEndPoint.protocol = selectedEndpoint.protocol;
            addEndPoint.contentType = selectedEndpoint.contentType;
            addEndPoint.setTextValues();

            this.addingEndPoint = false;
        }
    }

    public getUnSelectedEndPoints(rule: NotificationRuleModel): Array<EndPointModel> {
        const items: EndPointModel[] = [];
        const endPointsLength = this.endPoints.items.length;
        const selectedRuleEndPointsLength = rule.endPoints.length;
        for (let endPointIndex = 0; endPointIndex < endPointsLength; endPointIndex++) {
            const endPoint = this.endPoints.items[endPointIndex];
            let match = false;
            for (let selectedRuleEndIndex = 0; selectedRuleEndIndex < selectedRuleEndPointsLength; selectedRuleEndIndex++) {
                const selectedRuleEndPoint = rule.endPoints[selectedRuleEndIndex];
                if (!this.isNullOrUndefined(selectedRuleEndPoint) && selectedRuleEndPoint.endPointId === endPoint.endPointId) {
                    match = true;
                    break;
                }
            }
            if (!match) {
                items.push(endPoint);
            }
        }
        return items;
    }

    private setOperateOnValidators(): void {
        if (this.rule.isGlobalRule) {
            this.formGroup.controls.groupId.setValidators(Validators.compose([]));
            this.formGroup.controls.friendlySerialNumber.setValidators(Validators.compose([]));
        } else if (this.rule.isDeviceRule) {
            this.formGroup.controls.groupId.setValidators(Validators.compose([]));
            this.formGroup.controls.friendlySerialNumber.setValidators(Validators.compose([Validators.required]));
        } else if (this.rule.isGroupRule) {
            this.formGroup.controls.groupId.setValidators(Validators.compose([Validators.required]));
            this.formGroup.controls.friendlySerialNumber.setValidators(Validators.compose([]));
        }
    }

    private updateRuleFromGroup(): void {
        const operateOnModel = this.formGroup.value;

        if (this.rule.isDeviceRule && !this.isNullOrUndefined(operateOnModel.friendlySerialNumber)) {
            this.rule.value1 = operateOnModel.friendlySerialNumber;
        } else if (this.rule.isGroupRule && !isNaN(operateOnModel.groupId)) {
            this.rule.value1 = StringUtility.toString(operateOnModel.groupId);
        }
    }

    private setFormGroupFromRule(): void {
        this.formGroup.setValue({
            groupId: this.rule.isGroupRule && !this.isNullOrUndefined(this.rule.value1) ? parseInt(this.rule.value1, 10) : null,
            friendlySerialNumber: this.rule.isDeviceRule && !this.isNullOrUndefined(this.rule.value1) ? this.rule.value1 : null,
        });
    }
}
