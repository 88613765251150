import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpParams,
    HttpProgressEvent,
    HttpRequest,
} from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseService } from '@shared/base/Base.Service';
import {
    CommsRetryDialogComponent,
    CommsRetryDialogData,
    CommsRetryDialogResult,
} from '@shared/component/dialog/commsretry/CommsRetry.Component';
import { IRestModel } from '@shared/interface/IRestModel';
import { IRestPage } from '@shared/interface/IRestPage';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isArray, isNullOrUndefined, isString, isConstructor } from '@shared/utility/General.Utility';
import { PageModelUtility } from '@shared/utility/PageModel.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, last, map, mergeMap, retryWhen } from 'rxjs/operators';
import { Directive } from '@angular/core';

export interface IFileResponse {
    fileName: string;
    data: any;
}

export interface IRetryOptions {
    disabled?: boolean;
    excludeStatusCodes?: number[];
    retryMax?: number;
}

export interface IErrorOptions {
    excludeStatusCodes?: number[];
}

export interface IRestApiServiceOptions {
    retryOptions?: IRetryOptions;
}

export const RestApiServiceOptions: IRestApiServiceOptions = {};

/**
 * Base class for calling the rest api.
 *
 * @export
 * @abstract
 * @class RestApiService
 */
@Directive()
export abstract class RestApiService extends BaseService {
    protected throwOnError: boolean = true;
    protected urlBase: string;

    private _retrySubject: Subject<boolean> = new Subject<boolean>();
    private _retryDialogRef: MatDialogRef<CommsRetryDialogComponent> = null;
    private _retryMax: number = 3;

    /**
     * Creates an instance of RestApiService.
     *
     * @param {HttpClient} _httpClientBase The http client service.
     * @memberof RestApiService
     */
    protected constructor(
        private readonly _dialogBase: MatDialog,
        private readonly _urlBase: string,
        private readonly _httpClientBase: HttpClient) {
        super();

        this.urlBase = this._urlBase;
    }

    /**
     * Get to rest api and maps the response to a IPage<TResponse>.
     *
     * @protected
     * @template TItem The type of the item in the page.
     * @template TPage The type of the page model to map the response to.
     * @param {string} route The route to the rest api from the _urlBase.
     * @param {((new () => TItem) | string)} itemType The type of TItem.
     * @param {(new () => TPage)} pageType The type of TPage.
     * @param {HttpParams} [params] The query string parameters and values.
     * @param {ProcessMonitorServiceProcess} [process] The process to update with upload and download status.
     * @returns {Observable<TPage>} The mapped result from the rest api.
     * @memberof RestApiService
     */
    protected getPage<TItem, TPage extends IRestPage<TItem>>(route: string, itemType: (new () => TItem) | string, pageType: (new () => TPage), params?: HttpParams, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions, errorOptions?: IErrorOptions): Observable<TPage> {
        const url = `${this._urlBase}${route}`;
        const req = new HttpRequest('GET', url, {
            responseType: 'json',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return this.request<TItem, TPage>(req, itemType, pageType, process, false, false, RestApiServiceOptions.retryOptions || retryOptions, errorOptions) as Observable<TPage>;
    }

    /**
     * Get to rest api and maps the response to a TResponse.
     *
     * @protected
     * @template TItem The type of the item.
     * @param {string} route The route to the rest api from the _urlBase.
     * @param {((new () => TItem) | string)} itemType The type of TItem.
     * @param {HttpParams} [params] The query string parameters and values.
     * @param {ProcessMonitorServiceProcess} [process] The process to update with upload and download status.
     * @returns {Observable<TItem>} The mapped result from the rest api.
     * @memberof RestApiService
     */
    protected get<TItem>(route: string, itemType: (new () => TItem) | string, params?: HttpParams, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions, errorOptions?: IErrorOptions): Observable<TItem> {
        const url = `${this._urlBase}${route}`;
        const req = new HttpRequest('GET', url, {
            responseType: 'json',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return this.request<TItem, null>(req, itemType, null, process, false, false, RestApiServiceOptions.retryOptions || retryOptions, errorOptions) as Observable<TItem>;
    }

    /**
     * Get to rest api and maps the response to a TResponse.
     *
     * @protected
     * @template TItem The type of the item.
     * @param {string} route The route to the rest api from the _urlBase.
     * @param {((new () => TItem) | string)} itemType The type of TItem.
     * @param {HttpParams} [params] The query string parameters and values.
     * @param {ProcessMonitorServiceProcess} [process] The process to update with upload and download status.
     * @returns {Observable<TItem>} The mapped result from the rest api.
     * @memberof RestApiService
     */
    protected getArray<TItem>(route: string, itemType: (new () => TItem) | string, params?: HttpParams, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions, errorOptions?: IErrorOptions): Observable<TItem> {
        const url = `${this._urlBase}${route}`;
        const req = new HttpRequest('GET', url, {
            responseType: 'json',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return this.request<TItem, null>(req, itemType, null, process, true, false, RestApiServiceOptions.retryOptions || retryOptions, errorOptions) as Observable<TItem>;
    }

    /**
     * Handles the updating of process download status.
     *
     * @protected
     * @param {HttpProgressEvent} event The progress event.
     * @param {ProcessMonitorServiceProcess} process The process to be updated.
     * @memberof RestApiService
     */
    protected handleDownloadProgress(event: HttpProgressEvent, process: ProcessMonitorServiceProcess) {
        if (!isNullOrUndefined(event.total) && !isNullOrUndefined(event.loaded)) {
            if (isNullOrUndefined(process.restApi.downloadPercent)) {
                process.restApi.downloadPercent = 100 / event.total;
            }

            const progress = event.loaded * process.restApi.downloadPercent;

            process.restApi.downloadProgress = progress;
        }
    }

    /**
     * Handles the updating of process upload status.
     *
     * @protected
     * @param {HttpProgressEvent} event The progress event.
     * @param {ProcessMonitorServiceProcess} process The process to be updated.
     * @memberof RestApiService
     */
    protected handleUploadProgress(event: HttpProgressEvent, process: ProcessMonitorServiceProcess) {
        if (!isNullOrUndefined(event.total) && !isNullOrUndefined(event.loaded)) {
            if (isNullOrUndefined(process.restApi.uploadPercent)) {
                process.restApi.uploadPercent = 100 / event.total;
            }

            const progress = event.loaded * process.restApi.uploadPercent;

            process.restApi.uploadProgress = progress;
        }
    }

    /**
     * Post the TPost to the rest api and maps the response to a Array<TResponse>
     *
     * @protected
     * @template TPost The type of the post data
     * @template TResponse The type to map the response array to.
     * @param {string} route The route to the rest api from the _urlBase.
     * @param {(TPost | Array<TPost>)} post The TPost to post to the rest api.
     * @param {((new () => TResponse) | string)} responseType The type of TResponse.
     * @param {HttpParams} [params] The query string parameters and values.
     * @param {ProcessMonitorServiceProcess} [process] The process to update with upload and download status.
     * @returns {Observable<Array<TResponse>>} The mapped result from the rest api.
     * @memberof RestApiService
     */
    protected postArray<TPost, TResponse>(route: string, post: TPost | Array<TPost>, responseType: (new () => TResponse) | string, params?: HttpParams, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions, errorOptions?: IErrorOptions): Observable<Array<TResponse>> {
        const url = `${this._urlBase}${route}`;

        const postAny: any = post;
        let body: any;
        if (RestModelUtility.isIRestModel(post)) {
            body = this.toBody(postAny);
        } else {
            body = postAny;
        }

        const req = new HttpRequest('POST', url, body, {
            responseType: 'json',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return this.request<TResponse, null>(req, responseType, null, process, true, false, RestApiServiceOptions.retryOptions || retryOptions, errorOptions) as Observable<Array<TResponse>>;
    }

    /**
     * Post the TPost to the rest api and maps the response to a TResponse
     *
     * @protected
     * @template TPost The type of the post data
     * @template TResponse The type to map the response to.
     * @param {string} route The route to the rest api from the _urlBase.
     * @param {(TPost | Array<TPost>)} post The TPost to post to the rest api.
     * @param {((new () => TResponse) | string)} responseType The type of TResponse.
     * @param {HttpParams} [params] The query string parameters and values.
     * @param {ProcessMonitorServiceProcess} [process] The process to update with upload and download status.
     * @returns {Observable<TResponse>} The mapped result from the rest api.
     * @memberof RestApiService
     */
    protected post<TPost, TResponse>(route: string, post: TPost | Array<TPost>, responseType: (new () => TResponse) | string, params?: HttpParams, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions, errorOptions?: IErrorOptions): Observable<TResponse> {
        const url = `${this._urlBase}${route}`;

        const postAny: any = post;
        let body: any;
        if (RestModelUtility.isIRestModel(post)) {
            body = this.toBody(postAny);
        } else {
            body = postAny;
        }

        const req = new HttpRequest('POST', url, body, {
            responseType: 'json',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return this.request<TResponse, null>(req, responseType, null, process, false, false, RestApiServiceOptions.retryOptions || retryOptions, errorOptions) as Observable<TResponse>;
    }

    /**
     * Send a put request to the rest api and maps the response to a TResponse
     *
     * @protected
     * @template TPut The type of the put data
     * @template TResponse The type to map the response to.
     * @param {string} route The route to the rest api from the _urlBase.
     * @param {(TPut | Array<TPut>)} put The TPost to put to the rest api.
     * @param {((new () => TResponse) | string)} responseType The type of TResponse.
     * @param {HttpParams} [params] The query string parameters and values.
     * @param {ProcessMonitorServiceProcess} [process] The process to update with upload and download status.
     * @returns {Observable<TResponse>} The mapped result from the rest api.
     * @memberof RestApiService
     */
    protected put<TPut, TResponse>(route: string, put: TPut | Array<TPut>, responseType: (new () => TResponse) | string, params?: HttpParams, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions, errorOptions?: IErrorOptions): Observable<TResponse> {
        const url = `${this._urlBase}${route}`;

        const putAny: any = put;
        let body: any;
        if (RestModelUtility.isIRestModel(put)) {
            body = this.toBody(putAny);
        } else {
            body = putAny;
        }

        const req = new HttpRequest('PUT', url, body, {
            responseType: 'json',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return this.request<TResponse, null>(req, responseType, null, process, false, false, RestApiServiceOptions.retryOptions || retryOptions, errorOptions) as Observable<TResponse>;
    }

    /**
     * Sends delete request to the rest api and maps the response to a TResponse.
     *
     * @protected
     * @template TResponse The type to map the response array to.
     * @param {string} route The route to the rest api from the _urlBase.
     * @param {((new () => TResponse) | string)} responseType
     * @param {HttpParams} [params] The query string parameters and values.
     * @param {ProcessMonitorServiceProcess} [process] The process to update with upload and download status.
     * @returns {Observable<TResponse>} The mapped result from the rest api.
     * @memberof RestApiService
     */
    protected delete<TResponse>(route: string, responseType: (new () => TResponse) | string, params?: HttpParams, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions, errorOptions?: IErrorOptions): Observable<TResponse> {
        const url = `${this._urlBase}${route}`;

        const req = new HttpRequest('DELETE', url, {
            responseType: 'json',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return this.request<TResponse, null>(req, responseType, null, process, false, false, RestApiServiceOptions.retryOptions || retryOptions, errorOptions) as Observable<TResponse>;
    }

    /**
     * Sends delete request to the rest api and maps the response to a TResponse.
     *
     * @protected
     * @template TResponse The type to map the response array to.
     * @param {string} route The route to the rest api from the _urlBase.
     * @param {((new () => TResponse) | string)} responseType
     * @param {HttpParams} [params] The query string parameters and values.
     * @param {ProcessMonitorServiceProcess} [process] The process to update with upload and download status.
     * @returns {Observable<TResponse>} The mapped result from the rest api.
     * @memberof RestApiService
     */
    protected deleteWithBody<TItem extends IRestModel, TResponse>(route: string, deleteItem: TItem, responseType: (new () => TResponse) | string, params?: HttpParams, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions, errorOptions?: IErrorOptions): Observable<TResponse> {
        const url = `${this._urlBase}${route}`;

        const req = new HttpRequest('DELETE', url, deleteItem.toRestApiModel(), {
            responseType: 'json',
            params,
            reportProgress: true,
            withCredentials: true,
        });

        return this.request<TResponse, null>(req, responseType, null, process, false, false, RestApiServiceOptions.retryOptions || retryOptions, errorOptions) as Observable<TResponse>;
    }

    /**
     * Sends the HttpRequest<any> to the rest api.
     *
     * @protected
     * @template TResponse The type to map the response array to.
     * @template TPage The type of the page model.
     * @param {HttpRequest<any>} request The http request event.
     * @param {((new () => TResponse) | string)} responseType The type of TResponse.
     * @param {(new () => TPage)} pageType The type of TPage.
     * @param {ProcessMonitorServiceProcess} process The process to update with upload and download status.
     * @param {boolean} responseIsArray If true the response will be mapped to Array<TResponse>. else false.
     * @returns {(Observable<TResponse | Array<TResponse> | IRestPage<TResponse>>)} The mapped result from the rest api.
     * @memberof RestApiService
     */
    protected request<TResponse, TPage extends IRestPage<TResponse>>(request: HttpRequest<any>, responseType: (new () => TResponse) | string, pageType: (new () => TPage), process: ProcessMonitorServiceProcess, responseIsArray: boolean, responseIsFile?: boolean, retryOptions?: IRetryOptions, errorOptions?: IErrorOptions): Observable<TResponse | Array<TResponse> | IRestPage<TResponse>> {
        let retryCancel = false;
        return this._httpClientBase.request(request).pipe(
            map((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Response:
                        // Response from api.

                        if (responseIsFile === true) {
                            const contentDisposition = event.headers.get('content-disposition');
                            let fileName = null;
                            if (!isNullOrUndefined(contentDisposition)) {
                                const regExpResult = new RegExp(/attachment;\sfilename=(.*);/).exec(contentDisposition);
                                fileName = regExpResult[1];
                            }

                            return ({ fileName, data: event.body } as unknown) as TResponse;
                        } else {
                            if (isString(responseType)) {
                                // responseType is string eg 'string' 'number' just cast to TResponse.
                                if (isNullOrUndefined(responseIsArray) || responseIsArray === false) {
                                    return event.body as TResponse;
                                } else {
                                    return event.body as Array<TResponse>;
                                }
                            } else {
                                // responseType is object.
                                let returnType: IRestModel;

                                try {
                                    returnType = new (responseType as (new () => any))();
                                } catch {
                                    return null;
                                }

                                if (event.status === 204) {
                                    return returnType;
                                }

                                if (!isNullOrUndefined(pageType)) {
                                    // returnType is a IPage.
                                    if (event.body === null) {
                                        return null;
                                    } else {
                                        if (isArray(event.body)) {
                                            return PageModelUtility.loadFromArray<TResponse, TPage>(event.body, (responseType as (new () => any)), pageType);
                                        } else {
                                            return PageModelUtility.LoadFrom<TResponse, TPage>(event.body, (responseType as (new () => any)), pageType);
                                        }
                                    }
                                } else if (RestModelUtility.isIRestModel(returnType)) {
                                    // returnType is a IModel.
                                    if (isNullOrUndefined(responseIsArray) || responseIsArray === false) {
                                        if (event.body === null) {
                                            return null;
                                        } else {
                                            return RestModelUtility.loadFrom(event.body, (responseType as (new () => any)));
                                        }
                                    } else {
                                        if (event.body === null) {
                                            return null;
                                        } else {
                                            return RestModelUtility.loadFromArray(event.body, (responseType as (new () => any)));
                                        }
                                    }
                                } else {
                                    throw new Error('Unable to map response.');
                                }
                            }
                        }

                    case HttpEventType.DownloadProgress:
                        if (!isNullOrUndefined(process)) {
                            this.handleDownloadProgress(event, process);
                        }
                        break;
                    case HttpEventType.UploadProgress:
                        if (!isNullOrUndefined(process)) {
                            this.handleUploadProgress(event, process);
                        }
                        break;
                }
            }),
            retryWhen(errors => errors.pipe(
                mergeMap((error, i) => {
                    if (error instanceof HttpErrorResponse) {
                        if ((isNullOrUndefined(retryOptions) || isNullOrUndefined(retryOptions.disabled) || retryOptions.disabled === false) && retryCancel === false) {
                            if (!isNullOrUndefined(retryOptions)) {
                                if (!isNullOrUndefined(retryOptions.excludeStatusCodes) && retryOptions.excludeStatusCodes.indexOf(error.status) !== -1) {
                                    return throwError(error);
                                }
                            }

                            const retryAttempt = i + 1;

                            if (retryAttempt > (!isNullOrUndefined(retryOptions) && !isNullOrUndefined(retryOptions.retryMax) ? retryOptions.retryMax : this._retryMax)) {
                                if (isNullOrUndefined(this._retryDialogRef) && error.status !== 304) {
                                    this._retryDialogRef = this._dialogBase.open(CommsRetryDialogComponent, { data: new CommsRetryDialogData() });
                                    this._retryDialogRef.afterClosed().subscribe((result: CommsRetryDialogResult) => {
                                        if (!isNullOrUndefined(result)) {
                                            if (result.retry === false) {
                                                retryCancel = true;
                                                this._retrySubject.next(false);
                                            } else {
                                                this._retrySubject.next(true);
                                            }
                                        }
                                        this._retryDialogRef = null;
                                    });
                                }

                                return this._retrySubject.pipe(
                                    map(result => {
                                        if (result === false) {
                                            return error;
                                        } else {
                                            return null;
                                        }
                                    })
                                );
                            } else {
                                return timer(500);
                            }
                        }
                    }
                    return throwError(error);
                }),
            )),
            catchError((err: Error) => {
                if (isConstructor(responseType)) {
                    const returnType: IRestModel = new (responseType as (new () => any))();

                    if (retryCancel === true) {
                        returnType.userCanceled = { userOnRetry: true };
                    }

                    if (err instanceof HttpErrorResponse) {
                        if ((isNullOrUndefined(responseIsFile) || responseIsFile === false) && !isString(responseType)) {
                            if (!isNullOrUndefined(errorOptions) && !isNullOrUndefined(errorOptions.excludeStatusCodes) && errorOptions.excludeStatusCodes.indexOf(err.status) !== -1) {
                                return of(returnType);
                            }

                            if (!isNullOrUndefined(err.error)) {
                                returnType.httpError = err;
                                if (!isNullOrUndefined(err.error.Error)) {
                                    returnType.error = err.error.Error;
                                } else {
                                    returnType.error = err.message;
                                }
                            }

                            return of(returnType);
                        }
                    }
                }

                throw err;
            }),
            last()
        );
    }

    protected getFile(route: string, params?: HttpParams, process?: ProcessMonitorServiceProcess, responseType?: 'arraybuffer' | 'blob' | 'json' | 'text'): Observable<IFileResponse> {
        const url = `${this._urlBase}${route}`;
        const req = new HttpRequest('GET', url, {
            responseType: isNullOrUndefined(responseType) ? 'text' : responseType,
            params,
            reportProgress: true,
            withCredentials: true
        });

        return this.request<IFileResponse, null>(req, 'string', null, process, false, true) as Observable<IFileResponse>;
    }

    protected getFileUrl(route: string, params?: HttpParams, process?: ProcessMonitorServiceProcess): string {
        return `${this._urlBase}${route}${isNullOrUndefined(params) ? '' : '?' + params.toString()}`;
    }

    protected toBody(data: any): any {
        if (isArray(data)) {
            const length = data.length;
            const postItems: any[] = [];
            for (let i = 0; i < length; i++) {
                postItems.push(data[i].toRestApiModel());
            }
            return postItems;
        } else {
            return data.toRestApiModel();
        }
    }
}
