import { RoutingDestinationModel } from '@em/models/restapi/RoutingDestination.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';

export class RoutingRuleModel extends BaseModel implements IRestModel {
    public destinations: RestModelChangeTrackerArray<RoutingDestinationModel> = new RestModelChangeTrackerArray<RoutingDestinationModel>();
    public deviceSerial: number = null;
    public friendlySerialNumber: string = null;
    public groupId: number = null;

    public id: number = null;
    public isDeviceRule: boolean = false;
    public isGlobalRule: boolean = false;
    public isGroupRule: boolean = false;
    public readonly isIRestModel = true;
    public retryPeriod: number = null;

    public constructor() {
        super();

        this.registerProperty('retryPeriod');
        this.registerProperty('groupId');
        this.registerProperty('friendlySerialNumber');
        this.registerChangeTrackerArray('destinations');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-RoutingRuleModel');

        this.destinations = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.Destinations, RoutingDestinationModel);

        this.setPropertyOriginalValue('retryPeriod', this.retryPeriod);
        this.setPropertyOriginalValue('groupId', this.groupId);
        this.setPropertyOriginalValue('friendlySerialNumber', this.friendlySerialNumber);
        this.setPropertyOriginalValue('destinations', this.destinations);
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this),
            Destinations: RestModelUtility.toJsonArray(this.destinations),
        };
    }
}
