import { AfterViewInit, Component, ElementRef, HostBinding, Injector, NgZone, ViewChild } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { CountLogPageModel } from '@rift/models/restapi/CountLogPage.Model';
import { RegisterBaseCollectionModel } from '@rift/models/restapi/RegisterBaseCollection.Model';
import { CountsService } from '@rift/service/data/counts/Counts.Service';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ResizedEvent } from '@shared/directive/resized/Resized.Directive.ResizedEvent';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { ILoadDate } from '@shared/interface/ILoadData';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

declare const Plotly: any;

@Component({
    selector: 'rift-counts',
    templateUrl: './Counts.Component.html',
    styleUrls: ['./Counts.Component.scss']
})
export class CountsComponent extends RiftBaseComponent implements IFillHeight, ILoadDate, AfterViewInit {
    public static className: string = 'CountsComponent';

    public getAllProcess: ProcessMonitorServiceProcess;
    public loadCountsProcess: ProcessMonitorServiceProcess;

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @HostBinding()
    public id: string = 'rift-counts';
    public registers: RegisterBaseCollectionModel;

    public timeSetup: TimeSetupModel;
    private _chartOptions: any;
    private _countsLineChartBuilt: boolean = false;
    private _countsLineChartData: Array<any> = [];

    private _countsLineChartDataLoaded: boolean = false;
    private _countsLineChartLayout: any = {
        showlegend: false,
        autosize: true,
        xaxis: {
            type: 'date',
            title: 'Date & Time Recorded',
            autorange: false,
        },
        yaxis: {
            title: 'Count'
        },
        margin: {
            l: 60,
            r: 60,
            t: 60,
            b: 60,
        },
    };
    private _viewInit: boolean = false;

    public constructor(
        private readonly _mediaObserver: MediaObserver,
        private readonly _dialog: MatDialog,
        private readonly _countsService: CountsService,
        private readonly _registerService: RegisterService,
        private readonly _zone: NgZone,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._chartOptions = this.configurationService.plotlyOptions.defaultOptions;

        this.loadDataProcess = this.processMonitorService.getProcess(CountsComponent.className, this.loadDataProcessText);
        this.loadCountsProcess = this.processMonitorService.getProcess(CountsComponent.className, 'Load Counts');

        this.addSubscription(this._mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
            if (!this.isNullOrUndefined(changes)){
                changes.forEach(change => {
                    if (change.mqAlias === 'xs' || change.mqAlias === 'sm') {
                        this._countsLineChartLayout.showlegend = false;
                    } else {
                        this._countsLineChartLayout.showlegend = true;
                    }
                });
            }
        }));

        this.initConnectionState();
    }

    public resetAxesClick(): void {

    }

    public buildCountsLinechart(): void {
        if (this._viewInit === true && this._countsLineChartDataLoaded === true) {
            const itemsLength = this.registers.items.length;
            for (let itemIndex = 0; itemIndex < itemsLength; itemIndex++) {
                const register = this.registers.items[itemIndex];
                this._countsLineChartData.push({
                    x: [],
                    xcalendar: 'gregorian',
                    y: [],
                    mode: 'lines',
                    name: register.registerName,
                    registerId: register.registerIndex,
                    line: {
                        color: register.fillColor
                    }
                });
            }

            Plotly.newPlot('counts-linechart', this._countsLineChartData, this._countsLineChartLayout, this._chartOptions).then(() => {
                this._countsLineChartBuilt = true;
            });
        }
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        // Clear out any existing chart data
        this._countsLineChartData = [];

        const loadDataSub = zip(
            this.globalService.getTimeSetup(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.timeSetup = result;

                        const now = DateTimeUtility.toTimeZone(new Date(), this.timeSetup.timeZone);
                        this._countsLineChartLayout.xaxis.range = [
                            (new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).valueOf(),
                            now.valueOf()
                        ];
                    }
                    return true;
                })
            ),
            this._registerService.getRegisters(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.registers = result;
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process).pipe(
            tap(() => {
                this._countsLineChartDataLoaded = true;
                this.buildCountsLinechart();
                this._zone.run(()=>{
                    this.loadCountsProcess.started();
                });
                this.addSubscription(this.getCountsRecurse(0, process).subscribe());
            }),
        );
    }

    public ngAfterViewInit(): void {
        this._viewInit = true;
        if (!this._countsLineChartBuilt) {
            this.buildCountsLinechart();
        }
    }

    public onCountsLineChartResized(event: ResizedEvent): void {
        if (this._countsLineChartBuilt === true) {
            Plotly.relayout('counts-linechart', { width: event.newWidth - 35 });
        }
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
        this._countsLineChartBuilt = false;
        this.timeSetup = null;
        this.registers = null;
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    private getCountsRecurse(nextIndex: number, process: ProcessMonitorServiceProcess): Observable<CountLogPageModel> {
        return this._countsService.getCountLogPage(nextIndex, true, process).pipe(
            flatMap(result => {
                if (!this.isNullOrUndefined(this.timeSetup)) {
                    if (nextIndex === 0 && result.countLogs.length > 0) {
                        const firstDate = DateTimeUtility.toTimeZone(result.countLogs[0].timestamp, this.timeSetup.timeZone);
                        const dateNow = DateTimeUtility.getDateTimeNow(this.timeSetup.timeZone);

                        if (firstDate.valueOf() < dateNow.valueOf()) {
                            this._countsLineChartLayout.xaxis.range = [(new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate(), 0, 0, 0, 0)).valueOf(), firstDate.valueOf()];
                        }
                    }

                    const countLogsLength = result.countLogs.length;
                    for (let countLogIndex = 0; countLogIndex < countLogsLength; countLogIndex++) {
                        const countLog = result.countLogs[countLogIndex];
                        const countsLength = countLog.counts.length;
                        for (let countIndex = 0; countIndex < countsLength; countIndex++) {
                            const count = countLog.counts[countIndex];
                            const chartData = this._countsLineChartData.find(r => r.registerId === count.registerId);

                            if (!this.isNullOrUndefined(chartData)) {
                                chartData.x.push(DateTimeUtility.toTimeZone(countLog.timestamp, this.timeSetup.timeZone).valueOf());
                                chartData.y.push(count.value);
                            }
                        }
                    }

                    this._zone.run(()=>{
                        Plotly.redraw('counts-linechart');
                        Plotly.Plots.resize('counts-linechart');
                    });

                    if (result.countLogs.length > 0) {
                        return this.getCountsRecurse(result.nextIndex, process);
                    } else {
                        this._zone.run(()=>{
                            this.loadCountsProcess.completed();
                        });
                        return of(result);
                    }
                }
                this._zone.run(()=>{
                    this.loadCountsProcess.completed();
                });
                return of(result);
            })
        );
    }
}
