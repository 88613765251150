import { BaseModel } from '@shared/base/Base.Model';
import { TaskToExecuteStateEnum } from '@shared/enum/TaskToExecuteState.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';


export class TaskToExecuteResultModel extends BaseModel implements IRestModel {
    public percentageComplete: number = null;
    public readonly isIRestModel = true;

    public resultID: number = null;
    public state: TaskToExecuteStateEnum = null;
    public taskEntryID: number = null;
    public taskLogLocation: string = null;
    public timeFinished: Date = null;
    public timeStarted: Date = null;
    private _durationText: string = null;
    private _duration: number = null;

    private _timeFinishedText: any = null;

    public constructor() {
        super();
    }

    public get durationText(): string {
        return this._durationText;
    }

    public get duration(): number {
        return this._duration;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-TaskToExecuteResultModel');

        this.timeStarted = DateTimeNonMomentUtility.fromRestApiDateTime(this.timeStarted);
        this.timeFinished = DateTimeNonMomentUtility.fromRestApiDateTime(this.timeFinished);

        if (isNullOrUndefined(this._timeFinishedText) || DateTimeUtility.isInvalidDate(this._timeFinishedText)) {
            this._timeFinishedText = DateTimeUtility.toShortDateTime(this.timeFinished);
        }

        if (isNullOrUndefined(this._durationText) && !isNullOrUndefined(this.timeStarted) && !isNullOrUndefined(this.timeFinished)) {
            this._duration = DateTimeUtility.toDurationMilliseconds(this.timeStarted, this.state !== TaskToExecuteStateEnum.executing ? this.timeFinished : new Date());
            this._durationText = DateTimeUtility.millisecondsToHumanizeDuration(this._duration);
        }
    }

    public get timeFinishedText(): string {
        return this._timeFinishedText;
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
