import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class UpdateLocationModel extends BaseModel implements IRestModel {
    public latitude: number = null;
    public location: string = null;
    public longitude: number = null;
    public readonly isIRestModel = true;

    public serial: string = null;

    public constructor() {
        super();

        this.registerProperty('location');
        this.registerProperty('longitude');
        this.registerProperty('latitude');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-UpdateLocationModel');

        this.setPropertyOriginalValue('location', this.location);
        this.setPropertyOriginalValue('longitude', this.longitude);
        this.setPropertyOriginalValue('latitude', this.latitude);
    }

    public toRestApiModel(): any {
        return {
            serial: this.serial,
            location: this.location,
            longitude: this.longitude,
            latitude: this.latitude,
        };
    }
}
