<div class="shr-comms-retry-dialog">
    <div class="title"
        mat-dialog-title
        fxLayout="row"
        fxLayoutAlign="center center">
        Unable to complete request
    </div>
    <div class="content"
        mat-dialog-content
        fxLayout="column"
        fxLayoutAlign="center center">
        {{ data.errorMessage }}<br *ngIf="!isNullOrUndefined(data.errorMessage)" />
        Please click retry to reattempt the operation or cancel.
    </div>
    <div class="actions"
        fxLayout="row"
        fxLayoutAlign="space-around center"
        mat-dialog-actions>
        <button (click)="retry()"
            mat-raised-button
            color="primary">Retry</button>
        <button (click)="cancel()"
            mat-raised-button
            color="warn">Cancel</button>
    </div>
</div>