<div #container
    *ngIf="show === true && canShow === true"
    class="float-dialog rift-settings-counting-devices"
    (click)="onMenuClick()"
    ngResizable
    #resizable="ngResizable"
    (rzStop)="onRzStop($event)"
    (rzStart)="onRzStart($event)"
    (rzResizing)="onRzResizing($event)"
    [rzMinHeight]="minHeight"
    [rzMinWidth]="minWidth"
    [rzMinWidth]="minWidth"
    [rzMinHeight]="minHeight"
    [rzMaxWidth]="maxWidth"
    [rzMaxHeight]="maxHeight"
    ngDraggable
    #draggable="ngDraggable"
    (started)="onDrStarted($event)"
    (stopped)="onDrStopped($event)"
    (edge)="onDrEdge($event)"
    (movingOffset)="onDrMovingOffset($event)"
    (endOffset)="onDrEndOffset($event)"
    [handle]="title"
    [bounds]="bounds"
    [position]="position"
    [inBounds]="!isNullOrUndefined(bounds)">
    <div #title
        class="title drag-handle"
        fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex
            fxLayout="row"
            fxLayoutAlign="start center">
            <div fxLayout="column">
                <div class="header">
                    Devices
                </div>
            </div>
            <div fxFlex></div>
            <div class="actions">
                <button class="flat-icon-button"
                    [disabled]="saveAllChangesProcess.isRunning === true"
                    mat-stroked-button
                    matTooltip="Close"
                    (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="content"
        fxLayout="column">
        <div #deviceList
            class="device-list"
            [ngStyle]="{'height': devicesListHeight + 'px'}">
            <div *ngFor="let vm of deviceVMs"
                class="device"
                fxLayout="row"
                fxLayoutAlign="start center"
                (click)="onDeviceSelectedClick(vm, $event)"
                [class.selected]="vm.selected === true">
                <div fxLayout="column">
                    <div class="header"
                        fxLayout="row"
                        fxLayoutAlign="start center">
                        <div class="value">
                            {{ vm.device.serialNumber }} ({{ vm.device.master ? 'Master' : 'Node' }})
                        </div>
                        <mat-icon class="edit-icon"
                            *ngIf="!isNullOrUndefined(editingDevice) && editingDevice.serialNumber === vm.device.serialNumber">
                            edit</mat-icon>
                    </div>
                    <div class="details"
                        fxLayout="row"
                        fxLayoutAlign="start center">
                        <div class="value">
                            Ver: {{ vm.device.mainImageVersion }}
                        </div>
                        <div class="value">
                            Lens: {{ DeviceLensTypeEnumHelpers.toString(vm.device.lensType) }}
                        </div>
                    </div>
                </div>
                <div fxFlex></div>
                <div class="actions">
                    <button (click)="onEditDeviceClick(vm, $event)"
                        [disabled]="editDeviceDisabled"
                        class="mini-fab-icon-button"
                        mat-mini-fab
                        matTooltip="Edit Device">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button *ngIf="vm.device.isCapable(DeviceCapabilitiesEnum.video)"
                        (click)="onToggleDeviceVideoClick(vm, $event)"
                        [disabled]="getDeviceVideoPlayState(vm.device.serialNumber)"
                        class="mini-fab-icon-button"
                        mat-mini-fab
                        matTooltip="{{getDeviceVideoPlayState(vm.device.serialNumber) ? 'Playing Video' : 'Play Video'}}">
                        <mat-icon>{{ getDeviceVideoPlayState(vm.device.serialNumber) ? 'videocam' : 'play_arrow' }}
                        </mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>