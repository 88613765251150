/**
 * Logging service log levels.
 *
 * @export
 * @enum {number}
 */
export enum LoggingServiceLevel {
    /**
     * No logging.
     */
    Off = 0,
    /**
     * Errors logged.
     */
    Error = 1,
    /**
     * Errors, Warnings logged.
     */
    Warn = 2,
    /**
     * Info, Errors, Warnings logged.
     */
    Info = 3,
    /**
     * Debug, Info, Errors, Warnings logged.
     */
    Debug = 4,
    /**
     * Log, Debug, Info, Errors, Warnings logged.
     */
    Log = 5
}
