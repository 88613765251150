import { IObjectStore } from '@shared/service/Indexeddb/IObjectStore';
import { IFrameStore } from '@rift/service/validation/database/syncrecording/IFrameStore';

export interface IDatabaseUserCountStore extends IFrameStore {
    sessionInfoIdIndex: string;
    sessionInfoIdFrameNumberIndex: string;
}

export class DatabaseUserCountStore implements IObjectStore {
    public static readonly keyPath: string = 'id';
    public static readonly recordingIdIndex: string = 'RecordingIdIndex';
    public static readonly sessionInfoIdIndex: string = 'SessionInfoIdIndex';
    public static readonly sessionInfoIdFrameNumberIndex: string = 'SessionInfoIdFrameNumberIndex';
    public static readonly storeDisplayName: string = 'Count';
    public static readonly storeName: string = 'count';

    public get storeName(): string {
        return DatabaseUserCountStore.storeName;
    };

    public static toInterface(): IDatabaseUserCountStore {
        return {
            storeName: this.storeName,
            keyPath: this.keyPath,
            recordingIdIndex: this.recordingIdIndex,
            sessionInfoIdIndex: this.sessionInfoIdIndex,
            sessionInfoIdFrameNumberIndex: this.sessionInfoIdFrameNumberIndex,
        } as IDatabaseUserCountStore;
    }

    public createStores(db: IDBDatabase): void {
        const store: IDBObjectStore = db.createObjectStore(DatabaseUserCountStore.storeName, { keyPath: DatabaseUserCountStore.keyPath, autoIncrement: true });
        store.createIndex(DatabaseUserCountStore.sessionInfoIdFrameNumberIndex, ['sessionInfoId', 'frameNumber'], { unique: true });
        store.createIndex(DatabaseUserCountStore.recordingIdIndex, 'recordingId');
        store.createIndex(DatabaseUserCountStore.sessionInfoIdIndex, 'sessionInfoId');
    }
}
