import { Injectable } from '@angular/core';
import { HistogramLogConfigModel } from '@rift/models/restapi/HistogramLogConfig.Model';
import { HistogramLogPageModel } from '@rift/models/restapi/HistogramLogPage.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiHistogramsService } from '@rift/service/restapi/v1/RestApi.Histograms.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';

@Injectable()
export class HistogramService extends RiftBaseService {

    private _getConfigLoadingTracker = new ObservableTracker<HistogramLogConfigModel>();

    private _getHistogramsCountsLoadingTracker = new ObservableTracker<HistogramLogPageModel>();
    private _histogramLogConfigCache: HistogramLogConfigModel;

    private _setConfigLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiHistogramsService: RestApiHistogramsService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._histogramLogConfigCache = null;
    }

    public getConfig(process?: ProcessMonitorServiceProcess): Observable<HistogramLogConfigModel> {
        if (isNullOrUndefined(this._histogramLogConfigCache)) {
            return this._getConfigLoadingTracker
                .getLoading()
                .observable(this._restApiHistogramsService.getConfig(process).pipe(
                    map(result => {
                        this._histogramLogConfigCache = result;
                        return this._histogramLogConfigCache;
                    })
                ));
        } else {
            return of(this._histogramLogConfigCache);
        }
    }

    public getHistogramsCounts(from?: Date, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<HistogramLogPageModel> {
        return this._getHistogramsCountsLoadingTracker
            .getLoading()
            .observable(this._restApiHistogramsService.getHistogramsCounts(from, process, retryOptions));
    }

    public setCache(histogramLogConfigCache: HistogramLogConfigModel): Observable<boolean> {
        this._histogramLogConfigCache = histogramLogConfigCache;
        return of(true);
    }

    public setConfig(config: HistogramLogConfigModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setConfigLoadingTracker
            .getLoading()
            .observable(this._restApiHistogramsService.setConfig(config, process));
    }
}
