import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SyncedVideoSessionModel } from '@rift/models/restapi/SyncedVideoSession.Model';
import { ValidationSessionInfoModel } from '@rift/models/restapi/ValidationSessionInfo.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { Observable } from 'rxjs';
import { last, map } from 'rxjs/operators';

@Injectable()
export class RestApiValidationService extends RiftRestApiService {
    private _controller = 'validation';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public getAllSessionsForAllVideos(friendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<Array<[SyncedVideoSessionModel, Array<ValidationSessionInfoModel>]>> {
        const url = `${this.urlBase}${this._controller}/all_video_sessions`;
        const request = new HttpRequest('GET', url, {
            responseType: 'json',
            params: this.getTokenParams(['masterDevice', friendlySerial]),
            reportProgress: true,
            withCredentials: true
        });

        return this._httpClient.request(request).pipe(
            map((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Response:
                        // Response from api.
                        const body = event.body;
                        const retVal = new Array<[SyncedVideoSessionModel, Array<ValidationSessionInfoModel>]>();

                        const data = body.getallsessionsforvideos;

                        const length = data.length;
                        for (let i = 0; i < length; i++) {
                            const key = new SyncedVideoSessionModel();
                            key.loadFromRestApiModel(data[i].key);

                            retVal.push([key, RestModelUtility.loadFromArray(data[i].value, ValidationSessionInfoModel)]);
                        }

                        return retVal;
                }
            }),
            last()
        );
    }
}
