import { NgStyle } from '@angular/common';
import { Component, HostBinding, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isDate, isFunction, isNullOrUndefined, isNumber, isString } from '@shared/utility/General.Utility';
import { DataTypes, ViewModes, ValueUtility } from '@shared/utility/Value.Utility';

/**
 * Table cell that auto formats values.
 *
 * @export
 * @class TableCellComponent
 * @extends {BaseComponent}
 * @implements {OnChanges}
 */
@Component({
    selector: 'shr-table-cell',
    templateUrl: './Table.Cell.Component.html',
    styleUrls: ['./Table.Cell.Component.scss'],
})
export class TableCellComponent extends BaseComponent implements OnChanges {

    @HostBinding()
    public id: string = 'shr-table-cell';

    @Input()
    public spanStyle: any;

    /**
     * The data to format and display in the cell.
     *
     * @type {*}
     * @memberof TableCellComponent
     */
    @Input()
    public data: any;

    /**
     * Value to add to end of formated value.
     *
     * @type {string}
     * @memberof TableCellComponent
     */
    @Input()
    public postFix: string;

    /**
     * Value to add to start of formated value.
     *
     * @type {string}
     * @memberof TableCellComponent
     */
    @Input()
    public preFix: string;

    /**
     * The key path of the value to get from data item.
     * e.g. property name path dataItem.aObject.aDateProperty
     *
     * @type {string}
     * @memberof TableCellComponent
     */
    @Input()
    public keyPath: string;

    /**
     * The time setup to apply to the formatted value.
     *
     * @type {TimeSetupModel}
     * @memberof TableCellComponent
     */
    @Input()
    public timeSetup: TimeSetupModel;

    /**
     * The date format to apply to the value from {DateTimeUtility[dateFormat]}
     *
     * @type {string}
     * @memberof TableCellComponent
     */
    @Input()
    public dateFormat: string;

    public dataType: DataTypes = DataTypes.nullOrUndefined;
    public viewMode: ViewModes = ViewModes.default;
    public value: any;
    public dateValue: Date;
    public hasChangesText: string;

    public DataTypes = DataTypes;
    public ViewModes = ViewModes;
    public DateTimeUtility = DateTimeUtility;

    public constructor(
        private readonly _injector: Injector,
    ) {
        super(_injector);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.data) && !this.isNullOrUndefined(changes.data.currentValue)) {
            const dataAtPath: any = this.getData(this.data, this.keyPath);
            const displayValue = ValueUtility.getDisplayValue(dataAtPath, { dateFormat: this.dateFormat, timeSetup: this.timeSetup, preFix: this.preFix, postFix: this.postFix });

            this.dataType = displayValue.dataType;
            this.value = displayValue.value;
            this.viewMode = displayValue.viewMode;
            this.dateValue = displayValue.dateValue;
        }
    }

    private getData(data: any, keyPath: string): any {
        if (!this.isNullOrUndefined(keyPath)) {
            let last: any = (data as any);
            let item: any = (data as any);
            for (let i = 0, path = keyPath.split('.'), len = path.length; i < len; i++) {
                if (this.isNullOrUndefined(item)) {
                    return null;
                }
                item = item[path[i]];

                if (!isNullOrUndefined(last) && isFunction(last.propertyHasChangesText)) {
                    this.hasChangesText = last.propertyHasChangesText(path);
                }

                last = item;
            }
            return item;
        }
        return null;
    }

}
