import { BaseService } from '@shared/base/Base.Service';
import { Directive } from '@angular/core';

@Directive()
export abstract class RiftBaseService extends BaseService {
    public constructor() {
        super();
    }

    public clearCache(): void {
    }
}
