import { IResultModel } from '@rift/models/restapi/Result.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

const EXCLUDE_FIELDS = ['httpsEnabled'];

export interface IHttpEnabledDataModel extends IRestModel {
    httpEnabled: boolean;
}

export class HttpEnabledDataModel extends BaseModel implements IHttpEnabledDataModel, IResultModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public httpEnabled: boolean = null;

    public get httpsEnabled(): boolean{
        return !this.httpEnabled;
    }

    public set httpsEnabled(value: boolean){
        this.httpEnabled = !value;
    }

    public constructor() {
        super();

        this.registerProperty('httpEnabled');
        this.registerProperty('httpsEnabled');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-HttpEnabledDataModel', EXCLUDE_FIELDS);

        this.setPropertyOriginalValue('httpsEnabled', !this.httpEnabled);
        this.setPropertyOriginalValue('httpEnabled', this.httpEnabled);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, EXCLUDE_FIELDS),
        };
    }
}
