import { IResultModel } from '@rift/models/restapi/Result.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { BluetoothLowEnergyEnum } from '@shared/enum/BluetoothLowEnergy.Enum';
import { BluetoothTxPowerEnum } from '@shared/enum/BluetoothTxPower.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IBluetoothModel extends IRestModel {
    uUID: string;
    majorId: number;
    minorId: number;
    transmitPower: number;
    macHarvestingEnabled: boolean;
    mode: number;
    serialTimeout: number;
}

export class BluetoothModel extends BaseModel implements IBluetoothModel, IResultModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public uUID: string = null;
    public majorId: number = null;
    public minorId: number = null;
    public transmitPower: BluetoothTxPowerEnum = null;
    public macHarvestingEnabled: boolean = null;
    public mode: BluetoothLowEnergyEnum = null;
    public serialTimeout: number = null;

    public constructor() {
        super();

        this.registerProperty('uUID');
        this.registerProperty('majorId');
        this.registerProperty('minorId');
        this.registerProperty('transmitPower');
        this.registerProperty('macHarvestingEnabled');
        this.registerProperty('mode');
        this.registerProperty('serialTimeout');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-BluetoothModel');

        this.setPropertyOriginalValue('uUID', this.uUID);
        this.setPropertyOriginalValue('majorId', this.majorId);
        this.setPropertyOriginalValue('minorId', this.minorId);
        this.setPropertyOriginalValue('transmitPower', this.transmitPower);
        this.setPropertyOriginalValue('macHarvestingEnabled', this.macHarvestingEnabled);
        this.setPropertyOriginalValue('mode', this.mode);
        this.setPropertyOriginalValue('serialTimeout', this.serialTimeout);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}

export interface IBluetoothModel {
    loadFromRestApiModel(restModel: any): void;
    toRestApiModel(): any;
}
