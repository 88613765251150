import { NgZone } from '@angular/core';
import { Device } from '@rift/components/shared/viewport/devices/Device';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { DisplayItemCollection } from '@shared/generic/canvas/DisplayItemCollection';
import { isNullOrUndefined, isString } from '@shared/utility/General.Utility';

export class DeviceCollection extends DisplayItemCollection<Device> {
    public fovClickEnabled: boolean = false;

    private _showFieldOfView: boolean = true;

    public constructor(
        protected readonly _zone: NgZone,
        protected readonly _loadQueue: ViewPortLoadQueueService) {
        super(_zone);
        Object.setPrototypeOf(this, Object.create(DeviceCollection.prototype));
    }

    public bringDeviceToFront(deviceOrModelOrFriendlySerial: Device | DeviceModel | string): void {
        this._zone.runOutsideAngular(() => {
            let device: Device;
            if (isString(deviceOrModelOrFriendlySerial)) {
                device = this.find(i => i.deviceModel.serialNumber === deviceOrModelOrFriendlySerial);
            } else if (deviceOrModelOrFriendlySerial instanceof Device) {
                device = deviceOrModelOrFriendlySerial as Device;
            } else if (deviceOrModelOrFriendlySerial instanceof DeviceModel) {
                device = this.find(i => i.deviceModel.serialNumber === deviceOrModelOrFriendlySerial.serialNumber);
            }

            if (!isNullOrUndefined(device)) {
                this.bringToFront(device);
            }
        });
    }

    public bringToFront(displayItem: Device): void {
        this._zone.runOutsideAngular(() => {
            this.container.setChildIndex(displayItem.container, this.container.children.length - 1);
            this.requireStageUpdate.next();
        });
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
        });
    }

    public push(...items: Array<Device>): number {
        return this._zone.runOutsideAngular(() => {
            const length = items.length;
            for (let index = 0; index < length; index++) {
                const item = items[index];
                this.initItem(item);
                this.requireStageUpdate.next();
                item.update();
                super.push(item);
            }

            const master = this.find(i => i.deviceModel.master === true);
            if (!isNullOrUndefined(master)) {
                this.bringDeviceToFront(master);
            }

            return this.length;
        });
    }

    public clear(): void {
        this.forEach(d => d.video.visible = false);
        super.clear();
    }

    public get showFieldOfView(): boolean {
        return this._zone.runOutsideAngular(() => this._showFieldOfView);
    }

    public set showFieldOfView(value: boolean) {
        this._zone.runOutsideAngular(() => {
            if (this._showFieldOfView !== value) {
                this._showFieldOfView = value;
                const length = this.length;
                for (let index = 0; index < length; index++) {
                    this[index].showFieldOfView = value;
                }
            }
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            const length = this.length;
            for (let index = 0; index < length; index++) {
                this[index].update();
            }
        });
    }

    protected addEventHandlers(item: Device): void {
        this._zone.runOutsideAngular(() => {
            super.addEventHandlers(item);
        });
    }

    protected initItem(item: Device): void {
        this._zone.runOutsideAngular(() => {
            item.fovClickEnabled = this.fovClickEnabled;
            super.initItem(item);
        });
    }

    protected removeEventHandlers(item: Device): void {
        this._zone.runOutsideAngular(() => {
            super.removeEventHandlers(item);
        });
    }
}
