<div class="rift-validate-build-version-error-dialog-title" mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Build Version Invalid
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div class="rift-validate-build-version-error-dialog-content" mat-dialog-content fxLayout="column">
    The device you are trying to connect to has an unsupported firmware version.
</div>
<div class="rift-validate-build-version-error-dialog-actions" mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onDisconnectClick()">Disconnect</button>
</div>
