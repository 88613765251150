<div class="em-settings-metadata"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div fxFlex
        ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom">
        <mat-card class="interaction"
            fxFlex>
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <span>Edit Meta Data Keys </span>
                <div fxFlex></div>
                <button mat-raised-button
                    (click)="add()">Add Key</button>
            </mat-card-title>
            <ng-template ngFor
                let-metaData
                [ngForOf]="metaDataCollection?.items">
                <em-settings-metadata-key (valueChanges)="onKeyValueChanges()"
                    (deleteClicked)="delete(metaData)"
                    [metaData]="metaData"></em-settings-metadata-key>
                <mat-divider></mat-divider>
            </ng-template>
            <div class="error spacing-top">
                {{ matchingKeysNames === true ? 'The key names must be unique.' : '' }}
            </div>
        </mat-card>
    </div>
    <div fxFlex>
        <mat-card shrScrollFixed
            class="help">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <span>What is Meta Data?</span>
            </mat-card-title>
            <span>Meta Data allows the creation of user configurable data entries for devices. Once set up, the Meta Data page
                on all devices will allow data to be entered for each configured key. The data is stored within Estate
                Manager.
            </span>
        </mat-card>
    </div>
</div>