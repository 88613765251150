import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { HistogramTypeEnum } from '@shared/enum/HistogramType.Enum';

export interface IHistogramConfigModel extends IRestModel {
    minimumValue: number;
    binWidth: number;
    numberOfBins: number;
    expression: string;
}

export class HistogramConfigModel extends BaseModel implements IHistogramConfigModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public expression: string = null;
    public minimumValue: number = null;
    public binWidth: number = null;
    public numberOfBins: number = null;
    public Id: number = null;
    public type: HistogramTypeEnum = null;

    public constructor() {
        super();

        this.registerProperty('minimumValue');
        this.registerProperty('binWidth');
        this.registerProperty('numberOfBins');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-HistogramConfigModel', ['histogramId', 'type']);

        this.setPropertyOriginalValue('minimumValue', this.minimumValue);
        this.setPropertyOriginalValue('binWidth', this.binWidth);
        this.setPropertyOriginalValue('numberOfBins', this.numberOfBins);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, ['histogramId', 'type']),
        };
    }
}
