import { isNullOrUndefined, isNumber } from '@shared/utility/General.Utility';

export class PointUtility {

    public static getBounds(points: Array<createjs.Point | { x: number; y: number }>): createjs.Rectangle {
        if (!isNullOrUndefined(points)) {
            const length = points.length;
            if (length > 0) {
                let minX: number = Number.MAX_SAFE_INTEGER;
                let maxX: number = Number.MIN_SAFE_INTEGER;

                let minY: number = Number.MAX_SAFE_INTEGER;
                let maxY: number = Number.MIN_SAFE_INTEGER;

                for (let index = 0; index < length; index++) {
                    const point = points[index];

                    if (point.x < minX) {
                        minX = point.x;
                    }

                    if (point.x > maxX) {
                        maxX = point.x;
                    }

                    if (point.y < minY) {
                        minY = point.y;
                    }

                    if (point.y > maxY) {
                        maxY = point.y;
                    }
                }

                return new createjs.Rectangle(minX, minY, maxX - minX, maxY - minY);
            }
        }
        return new createjs.Rectangle(0, 0, 0, 0);
    }

    public static getPolygonPath(pts: Array<createjs.Point | { x: number; y: number }>, radius: number): Path2D {
        const path = new Path2D();
        let points: Array<Array<number>> = pts.map(p => [p.x, p.y]);

        if (radius > 0) {
            points = PointUtility.getRoundedPoints(points, radius);
        }

        const len = points.length;
        for (let i = 0; i < len; i++) {
            const point: Array<number> = points[i];

            if (i === 0) {
                path.moveTo(point[0], point[1]);
            } else {
                path.lineTo(point[0], point[1]);
            }

            if (radius > 0) {
                path.quadraticCurveTo(point[2], point[3], point[4], point[5]);
            }
        }
        path.closePath();

        return path;
    }

    public static getRoundedPoint(x1: number, y1: number, x2: number, y2: number, radius: number, first: boolean): Array<number> {
        const total = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        const idx = first === true ? radius / total : (total - radius) / total;
        return [x1 + (idx * (x2 - x1)), y1 + (idx * (y2 - y1))];
    }

    public static getRoundedPoints(pts: Array<Array<number>>, radius: number): Array<Array<number>> {
        let i1: number;
        let i2: number;
        let i3: number;
        let p1: Array<number>;
        let p2: Array<number>;
        let p3: Array<number>;
        let prevPt: Array<number>;
        let nextPt: Array<number>;
        const len = pts.length;
        const res = new Array<Array<number>>(len);
        for (i2 = 0; i2 < len; i2++) {
            i1 = i2 - 1;
            i3 = i2 + 1;
            if (i1 < 0) {
                i1 = len - 1;
            }
            if (i3 === len) {
                i3 = 0;
            }
            p1 = pts[i1];
            p2 = pts[i2];
            p3 = pts[i3];
            prevPt = this.getRoundedPoint(p1[0], p1[1], p2[0], p2[1], radius, false);
            nextPt = this.getRoundedPoint(p2[0], p2[1], p3[0], p3[1], radius, true);
            res[i2] = [prevPt[0], prevPt[1], p2[0], p2[1], nextPt[0], nextPt[1]];
        }
        return res;
    }

    public static getCenter(rectOrX: createjs.Rectangle | number, y?: number, bounds?: createjs.Rectangle | { width: number; height: number }): createjs.Point {
        if (rectOrX instanceof createjs.Rectangle) {
            return new createjs.Point(rectOrX.x + (rectOrX.width / 2), rectOrX.y + (rectOrX.height / 2));
        } else if (isNumber(rectOrX) && !isNullOrUndefined(y) && isNumber(y) && !isNullOrUndefined(bounds)) {
            return new createjs.Point(rectOrX + (bounds.width / 2), y + (bounds.height / 2));
        }
    }
}
