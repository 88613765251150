import { RegisterBaseModel, REGISTER_BASE_EXCLUDE_PROPERTIES } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterBeamBreakModel extends IRegisterBaseModel {
    associatedRegisterIndex: number;
    groupSize: number;
    eventDelay: number;
}

export class RegisterBeamBreakModel extends RegisterBaseModel implements IRegisterBeamBreakModel, IRestModel {

    public associatedRegisterIndex: number = null;
    public groupSize: number = 4;
    public eventDelay: number = null;

    public constructor() {
        super();

        this.registerProperty('associatedRegisterIndex');
        this.registerProperty('eventDelay');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterBeamBreakModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('associatedRegisterIndex', this.associatedRegisterIndex);
        this.setPropertyOriginalValue('eventDelay', this.eventDelay);

        this.groupSize = 4;

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
            GroupSize: 4,
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], [this.associatedRegisterIndex], []);
    }
}
