import { ModuleConfigModel } from '@em/models/restapi/ModuleConfig.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class ConfigDataModel extends BaseModel implements IRestModel {

    public commsTimeout: ModuleConfigModel;
    public counterServerAddress: ModuleConfigModel;
    public counterServerPort: ModuleConfigModel;
    public readonly isIRestModel = true;
    public sLNATAddress: ModuleConfigModel;
    public sLNATAddressOn: ModuleConfigModel;
    public toolServerEndPort: ModuleConfigModel;
    public toolServerStartPort: ModuleConfigModel;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        this.commsTimeout = RestModelUtility.loadFrom(restModel.ConfigData.CommsTimeout, ModuleConfigModel);
        this.counterServerAddress = RestModelUtility.loadFrom(restModel.ConfigData.CounterServerAddress, ModuleConfigModel);
        this.counterServerPort = RestModelUtility.loadFrom(restModel.ConfigData.CounterServerPort, ModuleConfigModel);
        this.sLNATAddress = RestModelUtility.loadFrom(restModel.ConfigData.SLNATAddress, ModuleConfigModel);
        this.sLNATAddressOn = RestModelUtility.loadFrom(restModel.ConfigData.SLNATAddressOn, ModuleConfigModel);
        this.toolServerEndPort = RestModelUtility.loadFrom(restModel.ConfigData.ToolServerEndPort, ModuleConfigModel);
        this.toolServerStartPort = RestModelUtility.loadFrom(restModel.ConfigData.ToolServerStartPort, ModuleConfigModel);
    }

    public toRestApiModel(): any {
        return {
            CommsTimeout: RestModelUtility.toJson(this.commsTimeout),
            CounterServerAddress: RestModelUtility.toJson(this.counterServerAddress),
            CounterServerPort: RestModelUtility.toJson(this.counterServerPort),
            SLNATAddress: RestModelUtility.toJson(this.sLNATAddress),
            SLNATAddressOn: RestModelUtility.toJson(this.sLNATAddressOn),
            ToolServerEndPort: RestModelUtility.toJson(this.toolServerEndPort),
            ToolServerStartPort: RestModelUtility.toJson(this.toolServerStartPort),
        };
    }
}
