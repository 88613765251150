import { Component, HostBinding, Injector, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'em-my-account-rest-api-key',
    templateUrl: './MyAccount.RestApiKey.Component.html',
    styleUrls: ['./MyAccount.RestApiKey.Component.scss']
})
export class MyAccountRestApiKeyComponent extends BaseComponent {
    public static className: string = 'MyAccountRestApiKeyComponent';

    @Output()
    public keyReset: EventEmitter<string> = new EventEmitter<string>();

    @HostBinding()
    public id: string = 'em-my-account-rest-api-key';

    public resetApiKeyProcess: ProcessMonitorServiceProcess;
    public resetComplete = false;

    public constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _dialogRef: MatDialogRef<MyAccountRestApiKeyComponent>,
        private readonly _userCurrentService: UserCurrentService,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.resetApiKeyProcess = this.processMonitorService.getProcess(MyAccountRestApiKeyComponent.className, 'Resetting user API Key.');
    }

    public cancel(): void {
        this._dialogRef.close();
    }

    public reset(): void {
        this.addSubscription(this.observableHandlerBase(this._userCurrentService.resetAPIKey(this.resetApiKeyProcess), this.resetApiKeyProcess).subscribe(
            key => {
                this.resetComplete = true;
                this._changeDetectorRef.markForCheck();
                this.keyReset.emit(key);
            }
        ), this.resetApiKeyProcess);
    }
}
