<div class="device-info-container mat-elevation-z2" [ngStyle]="{ 'display': visibility === 'hidden' ? 'none' : 'block' }"
    [@state]="visibility" (@state.start)="animationStart()" (@state.done)="animationDone($event)">
    <table>
        <tr>
            <td>
                Name
            </td>
            <td>
                {{ deviceInfo?.deviceName }}
            </td>
        </tr>
        <tr>
            <td>
                Device Id
            </td>
            <td>
                {{ deviceInfo?.deviceId }}
            </td>
        </tr>
        <tr>
            <td>
                Site Id
            </td>
            <td>
                {{ deviceInfo?.siteId }}
            </td>
        </tr>
        <tr>
            <td>
                Site Name
            </td>
            <td>
                {{ deviceInfo?.siteName }}
            </td>
        </tr>
        <tr>
            <td>
                Firmware Version
            </td>
            <td>
                {{ deviceInfo?.firmwareVersion }}
            </td>
        </tr>
        <tr>
            <td>
                IP Address
            </td>
            <td>
                {{ deviceInfo?.iPAddress }}
            </td>
        </tr>
        <tr>
            <td>
                Node Count
            </td>
            <td>
                {{ deviceInfo?.nodeCount }}
            </td>
        </tr>
    </table>
</div>
