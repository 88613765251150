import { isNullOrUndefined, isDate, isString, isNumber, isBoolean, isArray } from '@shared/utility/General.Utility';

export interface IEqualInfo {
    equal: boolean;
    propertyName?: string;
}

export enum PropertyModeEnum {
    include = 0,
    exclude = 1,
}

export class AnyUtility {
    /**
     * Checks to see if anyA and anyB are equal.
     *
     * @static
     * @template TItem The type of the object.
     * @param {TItem} anyA The A any to compare.
     * @param {TItem} anyB The B any to compare.
     * @param {string[]} propertyNames Property names to include/exclude can use anyA.x.y.
     * @returns {boolean} True if anyA and anyB are equal. else false.
     * @memberof AnyUtility
     */
    public static equal<TItem>(anyA: TItem, anyB: TItem, propertyNames?: string[], propertyMode?: PropertyModeEnum, keyPathTo?: string): boolean {
        return this.equalInfo(anyA, anyB, propertyNames, propertyMode, keyPathTo).equal;
    }


    public static equalInfo<TItem>(anyA: TItem, anyB: TItem, propertyNames?: string[], propertyMode?: PropertyModeEnum, keyPathTo?: string): IEqualInfo {
        if (!isNullOrUndefined(anyA) && !isNullOrUndefined(anyB)) {
            if (isDate(anyA) && isDate(anyB)) {
                return anyA.valueOf() === anyB.valueOf() ? { equal: true } : { equal: false, propertyName: keyPathTo };
            } else if (isString(anyA) && isString(anyB)) {
                return anyA.valueOf() === anyB.valueOf() ? { equal: true } : { equal: false, propertyName: keyPathTo };
            } else if (isNumber(anyA) && isNumber(anyB)) {
                return anyA.valueOf() === anyB.valueOf() ? { equal: true } : { equal: false, propertyName: keyPathTo };
            } else if (isBoolean(anyA) && isBoolean(anyB)) {
                return anyA.valueOf() === anyB.valueOf() ? { equal: true } : { equal: false, propertyName: keyPathTo };
            } else if (isArray(anyA) && isArray(anyB)) {
                const anyALength = anyA.length;
                if (anyA.length !== anyB.length) {
                    return { equal: false, propertyName: keyPathTo };
                } else {
                    for (let i = 0; i < anyALength; i++) {
                        const anyAItem = anyA[i];
                        const anyBItem = anyB[i];
                        if (!AnyUtility.equal((anyAItem as any), (anyBItem as any), propertyNames, propertyMode, keyPathTo)) {
                            return { equal: false, propertyName: keyPathTo };
                        }
                    }
                }

                return { equal: true };
            } else {
                for (const propertyNameA in anyA) {
                    if (!this.inExclude(propertyNameA, propertyNames, propertyMode, keyPathTo)) {
                        if (anyA.hasOwnProperty(propertyNameA) !== anyB.hasOwnProperty(propertyNameA)) {
                            return { equal: false, propertyName: this.appendPropertyName(keyPathTo, propertyNameA) };
                        } else if (typeof anyA[propertyNameA] !== typeof anyB[propertyNameA]) {
                            return { equal: false, propertyName: this.appendPropertyName(keyPathTo, propertyNameA) };
                        }
                    }
                }

                for (const propertyNameB in anyB) {
                    if (!this.inExclude(propertyNameB, propertyNames, propertyMode, keyPathTo)) {
                        if (anyA.hasOwnProperty(propertyNameB) !== anyB.hasOwnProperty(propertyNameB)) {
                            return { equal: false, propertyName: this.appendPropertyName(keyPathTo, propertyNameB) };
                        } else if (typeof anyA[propertyNameB] !== typeof anyB[propertyNameB]) {
                            return { equal: false, propertyName: this.appendPropertyName(keyPathTo, propertyNameB) };
                        } else if (!anyA.hasOwnProperty(propertyNameB)) {
                            continue;
                        } else if (anyA[propertyNameB] instanceof Object && anyB[propertyNameB] instanceof Object) {
                            const propResult = AnyUtility.equalInfo((anyA[propertyNameB] as any), (anyB[propertyNameB] as any), propertyNames, propertyMode, isNullOrUndefined(keyPathTo) ? propertyNameB : `${keyPathTo}.${propertyNameB}`);
                            if (!propResult.equal) {
                                return propResult;
                            }
                        } else if (anyA[propertyNameB] !== anyB[propertyNameB]) {
                            return { equal: false, propertyName: this.appendPropertyName(keyPathTo, propertyNameB) };
                        }
                    }
                }

                return { equal: true };
            }
        } else if (isNullOrUndefined(anyA) && isNullOrUndefined(anyB)) {
            return { equal: true };
        }
        return { equal: false, propertyName: keyPathTo };
    }

    private static appendPropertyName(propertyName: string, keyPathTo?: string): string {
        return isNullOrUndefined(keyPathTo) ? propertyName : isNullOrUndefined(propertyName) ? keyPathTo : `${keyPathTo}.${propertyName}`;
    }

    private static inExclude(propertyName: string, propertyNames?: string[], propertyMode?: PropertyModeEnum, keyPathTo?: string,): boolean {
        if (!isNullOrUndefined(propertyNames)) {
            if (isNullOrUndefined(propertyMode) || propertyMode === PropertyModeEnum.exclude) {
                return propertyNames.some(e => (isNullOrUndefined(keyPathTo) || e.startsWith(keyPathTo)) && e.endsWith(propertyName));
            } else {
                return !propertyNames.some(e => (isNullOrUndefined(keyPathTo) || e.startsWith(keyPathTo)) && e.endsWith(propertyName));
            }
        }

        return false;
    }
}
