import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IWirelessPIRDataModel extends IRestModel {
    enabled: boolean;
    mAC: string;
    description: string;
    version: string;
    devices: string[];
}

export class WirelessPIRDataModel extends BaseModel implements IWirelessPIRDataModel, IRestModel {
    public readonly isIRestModel = true;

    public enabled: boolean = null;
    public mAC: string = null;
    public description: string = null;
    public version: string = null;
    public devices: string[] = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-WirelessPIRDataModel');
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
