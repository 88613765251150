import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';

export interface IEmConnectionModel extends IRestModel {
    address: string;
    port: string;
    key: string;
}

export class EmConnectionModel extends BaseModel implements IEmConnectionModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public address: string = null;
    public port: string = null;
    public key: string = null;
    public lastUsed: Date = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-EmConnectionModel');

        this.lastUsed = DateTimeNonMomentUtility.fromRestApiDateTime(this.lastUsed);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            LastUsed: DateTimeNonMomentUtility.toRestApiDateTime(this.lastUsed),
        };
    }
}
