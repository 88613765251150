/**
 * Maintains a minimum tick interval.
 *
 * @export
 * @class IntervalTracker
 */
export class IntervalTracker {
    private _lastTick: number = null;

    public constructor(public minInterval: number = 500) { }

    public tick(): boolean {
        const valueOfNow = new Date().valueOf();

        if (this._lastTick === null) {
            this._lastTick = valueOfNow;
            return true;
        }

        const interval = valueOfNow - this._lastTick;
        if (interval > this.minInterval) {
            this._lastTick = valueOfNow;
            return true;
        }

        return false;
    }
}
