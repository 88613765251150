<div class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                <div fxLayout="row">
                    <div fxLayout="column">
                        <div class="header">
                            Wide Tracker Settings
                        </div>
                    </div>
                    <div fxFlex></div>
                    <div>

                    </div>
                </div>
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="settingsFormGroup">
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <mat-select #frameRate
                                formControlName="setToMaster"
                                placeholder="Master / Node">
                                <mat-option [value]="true">
                                    Master
                                </mat-option>
                                <mat-option [value]="false">
                                    Node
                                </mat-option>
                            </mat-select>
                            <span matSuffix>{{ connectedDevice?.propertyHasChangesText('setToMaster') }}</span>
                            <mat-error>{{getFormControlErrorMessage(settingsFormGroup.controls['setToMaster'])}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                Master / Node
            </mat-card-title>
            <mat-card-content>
                <span>Change this device into either a master or node device.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div *ngIf="(isHostMaster() | async) === true"
    class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                Counting network configuration
            </mat-card-title>
            <mat-card-content>
                <div fxLayout="row"
                    fxLayoutAlign="start stretch">
                    <div fxFlex="45"
                        fxLayout="column"
                        fxLayoutAlign="start stretch">
                        <div class="list-title"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            Node List
                        </div>
                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            class="no-nodes"
                            *ngIf="(devices | listDeviceFilter:true).length === 0">
                            <span>There are no nodes linked with this device.</span>
                        </div>
                        <div *ngIf="(devices | listDeviceFilter:true).length > 0">
                            <mat-list [ngClass]="{'select-list': !isReadOnly}"
                                role="list">
                                <mat-list-item class="list-item"
                                    *ngFor="let device of devices | listDeviceFilter:true; trackBy: deviceTrackByFn"
                                    (click)="onNodeClick(device, 'remove')"
                                    role="listitem">
                                    <div fxLayout.lt-sm="column"
                                        fxLayout.gt-xs="row">
                                        <div class="serial">
                                            {{ device.serial }}
                                        </div>
                                        <div class="iPAddress">
                                            ({{ device.iPAddress }})
                                        </div>                                        
                                    </div>
                                    <div fxFlex></div>
                                    <button mat-mini-fab
                                        [disabled]="isReadOnly" 
                                        color="primary" 
                                        (click)="onConvert($event, device)"
                                        *ngIf="device.assignedOrg === true && device.iPAddress !== 'Unknown'"><mat-icon>menu</mat-icon></button>                                    
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </div>
                    <div fxFlex="10"></div>
                    <div fxFlex="45"
                        fxLayout="column"
                        fxLayoutAlign="start stretch">
                        <div class="list-title"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            Discovered devices
                            <div fxFlex></div>
                            <button mat-icon-button
                                [disabled]="isReadOnly"
                                (click)="refreshDiscoveredDevices()">
                                <mat-icon>refresh</mat-icon>
                            </button>
                        </div>
                        <div fxLayout="row"
                            fxLayoutAlign="start center"
                            class="no-nodes"
                            *ngIf="(devices | listDeviceFilter:false).length === 0">
                            <span *ngIf="!isReadOnly">There are no available nodes to link to this device.</span>
                            <span *ngIf="isReadOnly">Please switch to live view to view nodes.</span>
                        </div>
                        <div *ngIf="(devices | listDeviceFilter:false).length > 0">
                            <mat-list [ngClass]="{'select-list': !isReadOnly}"
                                role="list">
                                <mat-list-item class="list-item"
                                    *ngFor="let device of devices | listDeviceFilter:false; trackBy: deviceTrackByFn"
                                    (click)="onNodeClick(device, 'add')"
                                    [ngStyle]="{'pointer-events' : device.canBeSelected === true ? 'auto':'none', 'color' : device.canBeSelected ? 'green' : 'lightgray'}"
                                    role="listitem">
                                    <div fxLayout.lt-sm="column"
                                        fxLayout.gt-xs="row">
                                        <div class="serial">
                                            {{ device.serial }}
                                        </div>
                                        <div class="iPAddress">
                                            ({{ device.iPAddress }})
                                        </div>
                                    </div>
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                Counting network configuration
            </mat-card-title>
            <mat-card-content>
                <span>Choose devices from the discovered device list to be part of the counting network.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>