import { Component, HostListener, Injector, QueryList, ViewChildren, HostBinding, ChangeDetectorRef } from '@angular/core';
import { SettingsMetaDataKeyComponent } from '@em/components/settings/metadata/key/Settings.MetaData.Key.Component';
import { MetaDataKeyModel } from '@em/models/restapi/MetaDataKey.Model';
import { MetaDataKeyCollectionModel } from '@em/models/restapi/MetaDataKeyCollectionModel';
import { MetaDataKeysService } from '@em/service/data/metadatakeys/MetaDataKeys.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { EventsService } from '@shared/service/events/Events.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { UserDeviceListColumnService } from '@em/service/data/user/User.DeviceList.Column.Service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'em-settings-metadata',
    templateUrl: './Settings.MetaData.Component.html',
    styleUrls: ['./Settings.MetaData.Component.scss']
})
export class SettingsMetaDataComponent extends BaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsMetaDataComponent';

    @ViewChildren(SettingsMetaDataKeyComponent)
    public keyComponents: QueryList<SettingsMetaDataKeyComponent>;

    public metaDataCollection: MetaDataKeyCollectionModel;

    @HostBinding()
    public id: string = 'em-settings-metadata';

    public constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _eventsService: EventsService,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _metaDataKeysService: MetaDataKeysService,
        private readonly _userDeviceListColumnService: UserDeviceListColumnService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsMetaDataComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsMetaDataComponent.className, this.saveAllChangesProcessText);

        this.addSaveAllAction(this);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public add(): void {
        const key = new MetaDataKeyModel();
        key.metaDataKeyId = -1;
        key.name = 'New Key';
        this.metaDataCollection.items.push(key);

        this._changeDetectorRef.detectChanges();

        this.updateSaveAllAction(this);
    }

    public delete(metaData: MetaDataKeyModel): void {
        const index = this.metaDataCollection.items.findIndex(i => i.uniqueId === metaData.uniqueId);
        this.metaDataCollection.items.splice(index, 1);

        this._changeDetectorRef.detectChanges();

        this.updateSaveAllAction(this);
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return !this.keyComponents.some(i => i.metaDataForm.valid === false) && !this.matchingKeysNames;
    }

    public get matchingKeysNames(): boolean {
        if (!this.isNullOrUndefined(this.keyComponents)) {
            return this.keyComponents.some(aKey => this.keyComponents.some(bKey => {
                    if (aKey.metaData.uniqueId !== bKey.metaData.uniqueId) {
                        if (aKey.metaData.name === bKey.metaData.name) {
                            return true;
                        }
                    }
                    return false;
                }));
        }
        return false;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._metaDataKeysService.getKeys(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.metaDataCollection = result;
                        this.changeTracker.track(result);
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public onKeyValueChanges(): void {
        this.updateSaveAllAction(this);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            this._metaDataKeysService.saveAllChanges(this.metaDataCollection, process).pipe(
                map(() => true)
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process).pipe(
                        tap(() => {
                            this._metaDataKeysService.clearCache();
                            this._userDeviceListColumnService.clearCache();
                            this._eventsService.changedMetaDataKeys();
                        })
                    );
                } else {
                    return of(result);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._metaDataKeysService.clearCache();
            this._userDeviceListColumnService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }
}
