import { FocusMonitor } from '@angular/cdk/a11y';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ComponentRef, ElementRef, Injector, Input, OnDestroy, OnInit, Optional, Self, ViewChild, HostBinding } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { DevicesGroup, IResult, ResultTypeEnum, SearchItems } from '@em/components/shared/search/Search';
import { SearchResultsComponent } from '@em/components/shared/search/Search.Results.Component';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { merge, Observable, of, Subject, Subscription, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'em-search',
    templateUrl: './Search.Component.html',
    styleUrls: ['./Search.Component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: SearchComponent
        }
    ]
})
export class SearchComponent extends BaseComponent implements MatFormFieldControl<string>, OnInit, ControlValueAccessor, OnDestroy, ILoadDate {
    public static className: string = 'SearchComponent';

    @Input()
    public formControl: string;

    @Input()
    public id: string;

    @ViewChild('input', { static: true })
    public input: ElementRef<HTMLInputElement>;

    @HostBinding()
    public class: string = 'em-search';

    public autofilled: boolean = false;
    public controlType?: string = 'text';
    public describedBy: string = '';
    public deviceSerials: Array<string> = [];
    public focusMonitorProcess: ProcessMonitorServiceProcess;
    public inputCtrl: FormControl;
    public inputValueChangesProcess: ProcessMonitorServiceProcess;
    public isAdminProcess: ProcessMonitorServiceProcess;
    public isInstallerProcess: ProcessMonitorServiceProcess;
    public isSystemAdminProcess: ProcessMonitorServiceProcess;
    public isUserProcess: ProcessMonitorServiceProcess;
    public searchProcess: ProcessMonitorServiceProcess;
    public stateChanges = new Subject<void>();

    private _deviceSearchSub: Subscription = null;
    private _disabled = false;
    private _focused: boolean = false;
    private _placeholder: string = 'Search';
    private _required = false;
    private _resultsOverlayPortal: ComponentPortal<SearchResultsComponent>;
    private _resultsOverlayRef: OverlayRef;
    private _searchResultsComponentRef: ComponentRef<SearchResultsComponent>;
    private _userIsAdmin: boolean;
    private _userIsInstaller: boolean;
    private _userIsSystemAdmin: boolean;
    private _userIsUser: boolean;
    private _value: string = null;

    public constructor(
        private readonly _overlay: Overlay,
        private readonly _dialog: MatDialog,
        @Optional() @Self() public ngControl: NgControl,
        private readonly _focusMonitor: FocusMonitor,
        private readonly _elementRef: ElementRef,
        private readonly _deviceService: DeviceService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.isAdminProcess = this.processMonitorService.getProcess(SearchComponent.className, 'Is admin check');
        this.isSystemAdminProcess = this.processMonitorService.getProcess(SearchComponent.className, 'Is system admin check');
        this.isInstallerProcess = this.processMonitorService.getProcess(SearchComponent.className, 'Is user check');
        this.isUserProcess = this.processMonitorService.getProcess(SearchComponent.className, 'Is admin check');
        this.focusMonitorProcess = this.processMonitorService.getProcess(SearchComponent.className, 'Focus monitor');
        this.inputValueChangesProcess = this.processMonitorService.getProcess(SearchComponent.className, 'Input value changes');
        this.loadDataProcess = this.processMonitorService.getProcess(SearchComponent.className, this.loadDataProcessText);
        this.searchProcess = this.processMonitorService.getProcess(SearchComponent.className, this.loadDataProcessText);

        this.addSubscription(this.observableHandlerBase(this.userCurrentService.isAdmin, this.isAdminProcess).subscribe(i => this._userIsAdmin = i), this.isAdminProcess);
        this.addSubscription(this.observableHandlerBase(this.userCurrentService.isSystemAdmin, this.isSystemAdminProcess).subscribe(i => this._userIsSystemAdmin = i), this.isSystemAdminProcess);
        this.addSubscription(this.observableHandlerBase(this.userCurrentService.isInstaller, this.isInstallerProcess).subscribe(i => this._userIsInstaller = i), this.isInstallerProcess);
        this.addSubscription(this.observableHandlerBase(this.userCurrentService.isUser, this.isUserProcess).subscribe(i => this._userIsUser = i), this.isUserProcess);

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        this.addSubscription(this.observableHandlerBase(this._focusMonitor.monitor(this._elementRef.nativeElement, true), this.focusMonitorProcess).subscribe((origin) => {
            this.focused = !!origin;
            this.stateChanges.next();
        }), this.focusMonitorProcess);

        this.inputCtrl = new FormControl();

        this.addSubscription(this.observableHandlerBase(this.inputCtrl.valueChanges, this.inputValueChangesProcess).subscribe(value => {
            if (this._value !== value) {
                this._value = value;
                this.onValueChanged(this._value);
            }
        }), this.inputValueChangesProcess);

        this.loadDataStartBase(this);
    }

    @Input()
    public get disabled() {
        return this._disabled;
    }
    public set disabled(value: boolean) {
        this._disabled = value;
        if (value) {
            this.inputCtrl.disable();
        } else {
            this.inputCtrl.enable();
        }
        this.stateChanges.next();
    }

    public get empty(): boolean {
        return this.isNullOrUndefined(this.value) || this.isEmptyOrWhiteSpace(this.value);
    }

    public get errorState(): boolean {
        if (!this.isNullOrUndefined(this.ngControl)) {
            return !this.ngControl.valid;
        } else {
            return !this.inputCtrl.valid;
        }
    }


    public get focused() {
        return this._focused;
    }
    public set focused(value: boolean) {
        this._focused = value;
        if (value) {
            this.onValueChanged(this.value);
        }
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._deviceService.getAllSerials(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.deviceSerials = result.sort((a, b) => {
                            if (a < b) {
                                return -1;
                            } else if (a > b) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.createResultsOverlay();
        this.inputCtrl.setValue(this.value);
    }

    public onChange(event: any): void {

    }

    public onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this._elementRef.nativeElement.querySelector('input').focus();
        }
    }

    public onTouched(event: any): void {

    }

    @Input()
    public get placeholder() {
        return this._placeholder;
    }
    public set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    @Input()
    public get required() {
        return this._required;
    }
    public set required(value: boolean) {
        this._required = value;
        this.stateChanges.next();
    }

    public setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public get shouldLabelFloat(): boolean {
        return !this.empty || this.focused;
    }

    @Input()
    public get value() {
        return this._value;
    }
    public set value(value: string) {
        this._value = value;
        this.stateChanges.next();
    }

    public writeValue(obj: any): void {
        this.value = obj as string;
        this.inputCtrl.setValue(this.value);
    }

    private createResultsOverlay(): void {
        const positionStrategy = this._overlay
            .position()
            .flexibleConnectedTo(this.input)
            .withPositions([{ originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top', offsetX: 10, offsetY: -15 }]);

        this._resultsOverlayRef = this._overlay.create({ maxHeight: '90%', positionStrategy, panelClass: ['search-results-overlay', 'mat-elevation-z8'] });
        this._resultsOverlayPortal = new ComponentPortal(SearchResultsComponent);
    }

    private getDeviceResults(searchValueLowerCase: string, process: ProcessMonitorServiceProcess): Observable<{ items: Array<IResult>; type: ResultTypeEnum }> {
        return this._deviceService.advancedSearch(searchValueLowerCase, process).pipe(
            map(
                result => ({
                        items: result.items.map(i => ({
                                id: i.device.friendlySerial,
                                type: ResultTypeEnum.device,
                                groupId: DevicesGroup.id,
                                deviceSearchResult: i
                            } as IResult)),
                        type: ResultTypeEnum.device,
                    })
            ),
        );
    }

    private getLocalResults(searchValueLowerCase: string): Observable<{ items: Array<IResult>; type: ResultTypeEnum }> {
        let results: Array<IResult> = null;

        results = SearchItems
            .filter(
                i =>
                    (
                        (isNullOrUndefined(i.isAdmin) || i.isAdmin === this._userIsAdmin) ||
                        (isNullOrUndefined(i.isSystemAdmin) || i.isSystemAdmin === this._userIsSystemAdmin) ||
                        (isNullOrUndefined(i.isInstaller) || i.isInstaller === this._userIsInstaller) ||
                        (isNullOrUndefined(i.isUser) || i.isUser === this._userIsUser)
                    )
                    &&
                    (
                        i.name.toLowerCase().indexOf(searchValueLowerCase) !== -1 ||
                        i.description.toLowerCase().indexOf(searchValueLowerCase) !== -1 ||
                        (!isNullOrUndefined(i.values) && i.values.some(v => v.toLowerCase().indexOf(searchValueLowerCase) !== -1))
                    )
            )
            .map(
                i => ({
                        id: i.id,
                        type: ResultTypeEnum.internal,
                        groupId: i.groupId,
                        searchItem: i
                    } as IResult)
            );

        return of({ items: results, type: ResultTypeEnum.internal });
    }

    private onValueChanged(searchValue: string): void {

        if (isNullOrUndefined(this._resultsOverlayRef)) {
            return;
        }

        if (!isNullOrUndefined(this._deviceSearchSub)) {
            this._deviceSearchSub.unsubscribe();
            this._deviceSearchSub = null;
        }

        if (!isNullOrUndefined(searchValue) && !StringUtility.isEmptyOrWhiteSpace(searchValue)) {
            const lcValue = searchValue.toLowerCase();

            this._deviceSearchSub = this.addSubscription(this.observableHandlerBase(merge(this.getDeviceResults(lcValue, this.searchProcess), this.getLocalResults(lcValue)), this.searchProcess).subscribe(
                result => {
                    if (isNullOrUndefined(this._searchResultsComponentRef)) {
                        this._searchResultsComponentRef = this._resultsOverlayRef.attach(this._resultsOverlayPortal);

                        this.addSubscription(this.observableHandlerBase(this._searchResultsComponentRef.instance.close, this.searchProcess).subscribe(() => {
                            if (!isNullOrUndefined(this._resultsOverlayRef)) {
                                this._resultsOverlayRef.detach();
                            }
                            this._searchResultsComponentRef = null;
                        }), this.searchProcess);
                        this.addSubscription(this.observableHandlerBase(this._searchResultsComponentRef.instance.clickOutside, this.searchProcess).subscribe(() => {
                            if (!this.focused) {
                                if (!isNullOrUndefined(this._resultsOverlayRef)) {
                                    this._resultsOverlayRef.detach();
                                }
                                this._searchResultsComponentRef = null;
                            }
                        }), this.searchProcess);
                    }

                    if (!this.isNullOrUndefined(this._searchResultsComponentRef.instance.results)) {
                        this._searchResultsComponentRef.instance.results = this.removeType(this._searchResultsComponentRef.instance.results, result.type).concat(result.items);
                    } else {
                        this._searchResultsComponentRef.instance.results = result.items;
                    }


                    if (!isNullOrUndefined(this._searchResultsComponentRef) && this._searchResultsComponentRef.instance.results.length === 0) {
                        if (!isNullOrUndefined(this._resultsOverlayRef)) {
                            this._resultsOverlayRef.detach();
                        }
                        this._searchResultsComponentRef = null;
                    }

                    if (!isNullOrUndefined(this._deviceSearchSub)) {
                        this._deviceSearchSub.unsubscribe();
                        this._deviceSearchSub = null;
                    }
                }
            ), this.searchProcess);
        } else {
            if (!isNullOrUndefined(this._resultsOverlayRef)) {
                this._resultsOverlayRef.detach();
            }
            this._searchResultsComponentRef = null;
        }
    }

    private removeType(arr: Array<IResult>, type: ResultTypeEnum): Array<IResult> {
        const res: IResult[] = [];
        const arrLength = arr.length;

        for (let iA = 0; iA < arrLength; iA++) {
            const itemA = arr[iA];

            if (itemA.type !== type) {
                res.push(itemA);
            }
        }

        return res;
    }
}
