import { ActiveConnectionModel } from '@em/models/restapi/ActiveConnection.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class ConnectionGroupModel extends BaseModel implements IRestModel {
    public connectionGroupId: number = null;
    public errorMessage: string = null;
    public name: string = null;
    public readonly isIRestModel = true;

    public success: boolean = null;

    public constructor() {
        super();

        this.registerProperty('name');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ConnectionGroupModel');

        this.setPropertyOriginalValue('name', this.name);
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this)
        };
    }
}
