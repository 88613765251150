import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export abstract class UserBaseModel extends BaseModel implements IRestModel {

    public isSystemAdmin: boolean = false;
    public isSystemManager: boolean = false;
    public isAdmin: boolean = false;
    public isInstaller: boolean = false;
    public isUser: boolean = false;
    public readonly isIRestModel = true;
    public roleNames: string[];

    public canAddRole(name: string): boolean {
        if (!isNullOrUndefined(this.roleNames) && this.roleNames.length > 1) {
            const length = this.roleNames.length;
            for (let i = 1; i < length; i++) {
                if (this.roleNames[i] === name.toLocaleLowerCase()) {
                    return true;
                }
            }
        }
        return false;
    }

    public loadFromRestApiModel(restModel: any): void {
    }

    public toRestApiModel(): any {
    }
}
