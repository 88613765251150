import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export interface IIPSetupModel extends IRestModel {
    mACAddress: string;
    hostname: string;
    dhcp: boolean;
    iPV4: string;
    subnet: string;
    gateway: string;
    dNS1: string;
    dNS2: string;
    dNS3: string;
    serverPort: number;
}

export class IPSetupModel extends BaseModel implements IIPSetupModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public mACAddress: string = null;
    public hostname: string = null;
    public dhcp: boolean = null;
    public iPV4: string = null;
    public subnet: string = null;
    public gateway: string = null;
    public serverPort: number = null;

    private _dNS: Array<string> = null;

    public constructor() {
        super();

        this.registerProperty('hostname');
        this.registerProperty('dhcp');
        this.registerProperty('iPV4');
        this.registerProperty('subnet');
        this.registerProperty('gateway');
        this.registerProperty('dNS1', false, false);
        this.registerProperty('dNS2', false, false);
        this.registerProperty('dNS3', false, false);
        this.registerProperty('serverPort');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-IPSetupModel');

        this._dNS = restModel.DNS;

        this.setPropertyOriginalValue('hostname', this.hostname);
        this.setPropertyOriginalValue('dhcp', this.dhcp);
        this.setPropertyOriginalValue('iPV4', this.iPV4);
        this.setPropertyOriginalValue('subnet', this.subnet);
        this.setPropertyOriginalValue('gateway', this.gateway);
        this.setPropertyOriginalValue('dNS1', this._dNS[0]);
        this.setPropertyOriginalValue('dNS2', this._dNS[1]);
        this.setPropertyOriginalValue('dNS3', this._dNS[2]);
        this.setPropertyOriginalValue('serverPort', this.serverPort);
    }

    public connectionDataChanged(): boolean {
        return this.propertyHasChanges('dhcp') || this.propertyHasChanges('subnet') || this.propertyHasChanges('iPV4') || this.propertyHasChanges('hostname') || this.propertyHasChanges('serverPort');
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            DNS: this._dNS,
        };
    }

    public get dNS1(): string {
        return isNullOrUndefined(this._dNS) ? null : isNullOrUndefined(this._dNS[0]) ? null : this._dNS[0];
    }

    public set dNS1(value: string) {
        if (isNullOrUndefined(this._dNS)) {
            this._dNS = [];
        }
        if (this._dNS[0] !== value) {
            this._dNS[0] = value;
            this.onPropertyChanged('dNS1', value);
        }
    }

    public get dNS2(): string {
        return isNullOrUndefined(this._dNS) ? null : isNullOrUndefined(this._dNS[1]) ? null : this._dNS[1];
    }

    public set dNS2(value: string) {
        if (isNullOrUndefined(this._dNS)) {
            this._dNS = [];
        }
        if (this._dNS[1] !== value) {
            this._dNS[1] = value;
            this.onPropertyChanged('dNS2', value);
        }
    }

    public get dNS3(): string {
        return isNullOrUndefined(this._dNS) ? null : isNullOrUndefined(this._dNS[2]) ? null : this._dNS[2];
    }

    public set dNS3(value: string) {
        if (isNullOrUndefined(this._dNS)) {
            this._dNS = [];
        }
        if (this._dNS[2] !== value) {
            this._dNS[2] = value;
            this.onPropertyChanged('dNS3', value);
        }
    }
}
