import { ConfigDataTypeEnum } from '@shared/enum/ConfigDataType.Enum';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class ModuleConfigModel extends BaseModel implements IRestModel {

    public dataType: ConfigDataTypeEnum = null;
    public readonly isIRestModel = true;
    public validValues: Array<string> = null;
    public value: string = null;

    public constructor() {
        super();

        this.registerProperty('value');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ModuleConfigModel');
        this.validValues = restModel.ValidValues;

        this.setPropertyOriginalValue('value', this.value);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            ValidValues: this.validValues,
        };
    }
}
