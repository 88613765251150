import { Injectable, NgZone } from '@angular/core';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { GlobalModel } from '@rift/models/restapi/Global.Model';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ValidationReportModel } from '@rift/models/restapi/ValidationReport.Model';
import { ValidationReportLineModel } from '@rift/models/restapi/ValidationReportLine.Model';
import { ValidationReportNodeModel } from '@rift/models/restapi/ValidationReportNode.Model';
import { CountModel } from '@rift/models/websocket/Count.Model';
import { DbValidationSessionInfoModel } from '@rift/service/validation/models/database/syncsession/IDbValidationSessionInfo.Model';
import { IValidationRecordingModel } from '@rift/service/validation/models/ValidationRecording.Model';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { BaseService } from '@shared/base/Base.Service';
import { CountModeEnumHelpers } from '@shared/enum/CountMode.Enum';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IPlayLocation } from '@rift/service/validation/IPlayLocation';

export interface IReportBucket {
    index: number;
    start: IPlayLocation;
    end: IPlayLocation;
}

export interface ILineBucket {
    systemCount: number;
    userCount: number;
}

export class ValidationAggregationReportLine extends ValidationReportLineModel {
    public buckets: Array<ILineBucket>;
}

export class ValidationAggregationReport extends ValidationReportModel {
    public bucketSizeMs: number;
    public buckets: Array<IReportBucket>;
    public lines: Array<ValidationAggregationReportLine> = null;
}

@Injectable()
export class ValidationReportService extends BaseService {
    public constructor(
        private readonly _zone: NgZone,
        private readonly _playService: ValidationPlayService) {
        super();
    }

    public generateAggregationReport(bucketSize: number, registers: Array<RegisterBaseModel>, lines: Array<LineModel>, userCounts: Array<CountModel>, systemCounts: Array<CountModel>, hostDevice: DeviceModel, nodes: Array<DeviceModel>, timeSetup: TimeSetupModel, global: GlobalModel, validationRecording: IValidationRecordingModel, sessionInfo: DbValidationSessionInfoModel): ValidationAggregationReport {
        const report = new ValidationAggregationReport();

        this.generateReportHead(report, hostDevice, nodes, timeSetup, global, validationRecording, sessionInfo);

        const start = this._playService.fillPlayLocation({ frameNumber: sessionInfo.startFrame }).offset;
        const end = this._playService.fillPlayLocation({ frameNumber: sessionInfo.endFrame }).offset;
        const bucketSizeMs = bucketSize * 1000;
        const bucketCount = Math.ceil((end - start) / bucketSizeMs);
        let bucketI = 0;

        report.bucketSizeMs = bucketSizeMs;
        report.buckets = [];

        for (bucketI = 0; bucketI < bucketCount; bucketI++) {
            const bucketStart = (bucketI * bucketSizeMs) + start;
            let bucketEnd = (((bucketI + 1) * bucketSizeMs) + start) - 1;
            bucketEnd = bucketEnd > end ? end : bucketEnd;
            report.buckets.push({ index: bucketI, start: this._playService.fillPlayLocation({ offset: bucketStart }), end: this._playService.fillPlayLocation({ offset: bucketEnd }) });
        }

        const registersLength = registers.length;
        for (let i = 0; i < registersLength; i++) {
            const register = registers[i];
            if (sessionInfo.registersToValidate.indexOf(register.registerIndex) !== -1) {
                const lineReport = new ValidationAggregationReportLine();
                const line = lines.find(l => l.iD === register.lineIds[0]);
                lineReport.buckets = [];

                for (bucketI = 0; bucketI < bucketCount; bucketI++) {
                    const bucket = report.buckets[bucketI];

                    let systemCountFirst: number = null;
                    let systemCountLast: number = null;
                    let previousSystemCount: CountModel = null;

                    let userCountFirst: number = null;
                    let userCountLast: number = null;
                    let previousUserCount: CountModel = null;
                    for (let frameNumber = 0; frameNumber < validationRecording.frames; frameNumber++) {
                        const currentSystemCount = systemCounts[frameNumber];

                        if (!isNullOrUndefined(currentSystemCount)) {
                            if (frameNumber < bucket.start.frameNumber) {
                                systemCountFirst = currentSystemCount.counts[register.registerIndex];
                            }

                            if (frameNumber >= bucket.start.frameNumber) {
                                if (isNullOrUndefined(systemCountFirst) && isNullOrUndefined(previousSystemCount)) {
                                    systemCountFirst = currentSystemCount.counts[register.registerIndex];
                                }

                                if (isNullOrUndefined(systemCountFirst) && !isNullOrUndefined(previousSystemCount)) {
                                    systemCountFirst = previousSystemCount.counts[register.registerIndex];
                                }

                                if (frameNumber <= bucket.end.frameNumber) {
                                    systemCountLast = currentSystemCount.counts[register.registerIndex];
                                }
                            }

                            previousSystemCount = currentSystemCount;
                        }

                        const currentUserCount = userCounts[frameNumber];

                        if (!isNullOrUndefined(currentUserCount)) {
                            if (frameNumber < bucket.start.frameNumber) {
                                userCountFirst = currentUserCount.counts[register.registerIndex];
                            }

                            if (frameNumber >= bucket.start.frameNumber) {
                                if (isNullOrUndefined(userCountFirst) && isNullOrUndefined(previousUserCount)) {
                                    userCountFirst = currentUserCount.counts[register.registerIndex];
                                }

                                if (isNullOrUndefined(userCountFirst) && !isNullOrUndefined(previousUserCount)) {
                                    userCountFirst = previousUserCount.counts[register.registerIndex];
                                }

                                if (frameNumber <= bucket.end.frameNumber) {
                                    userCountLast = currentUserCount.counts[register.registerIndex];
                                }
                            }

                            previousUserCount = currentUserCount;
                        }
                    }

                    if (isNullOrUndefined(systemCountLast)) {
                        systemCountLast = systemCountFirst;
                    }

                    if (isNullOrUndefined(userCountLast)) {
                        userCountLast = userCountFirst;
                    }

                    lineReport.buckets.push({
                        systemCount: systemCountLast - systemCountFirst,
                        userCount: userCountLast - userCountFirst,
                    });
                }

                if (!isNullOrUndefined(line)) {
                    lineReport.mode = CountModeEnumHelpers.toFullLongName(line.countMode);
                }
                lineReport.name = register.registerName;

                report.lines.push(lineReport);
            }
        }

        return report;
    }

    public generateAccuracyReport(registers: Array<RegisterBaseModel>, lines: Array<LineModel>, userCounts: Array<CountModel>, systemCounts: Array<CountModel>, hostDevice: DeviceModel, nodes: Array<DeviceModel>, timeSetup: TimeSetupModel, global: GlobalModel, validationRecording: IValidationRecordingModel, sessionInfo: DbValidationSessionInfoModel): ValidationReportModel {
        const report = new ValidationReportModel();

        this.generateReportHead(report, hostDevice, nodes, timeSetup, global, validationRecording, sessionInfo);

        const length = sessionInfo.registersToValidate.length;
        for (let i = 0; i < length; i++) {
            const registerIndex = sessionInfo.registersToValidate[i];
            const register = registers.find((item) => item.registerIndex === registerIndex);

            const systemCountFirst = ArrayUtility.previousNotNullOrUndefined(systemCounts, sessionInfo.startFrame) || ArrayUtility.nextNotNullOrUndefined(systemCounts, sessionInfo.startFrame);
            const systemCountLast = ArrayUtility.previousNotNullOrUndefined(systemCounts, sessionInfo.endFrame) || ArrayUtility.nextNotNullOrUndefined(systemCounts, sessionInfo.endFrame);

            const systemCount = isNullOrUndefined(systemCountLast) ? 0 : systemCountLast.counts[registerIndex] - systemCountFirst.counts[registerIndex];

            const userCountFirst = ArrayUtility.previousNotNullOrUndefined(userCounts, sessionInfo.startFrame) || ArrayUtility.nextNotNullOrUndefined(userCounts, sessionInfo.startFrame);
            const userCountLast = ArrayUtility.previousNotNullOrUndefined(userCounts, sessionInfo.endFrame) || ArrayUtility.nextNotNullOrUndefined(userCounts, sessionInfo.endFrame);

            const userCount = isNullOrUndefined(userCountLast) ? 0 : userCountLast.counts[registerIndex] - userCountFirst.counts[registerIndex];

            let accuracy = 0;

            if (systemCount !== 0 && userCount !== 0) {
                accuracy = (systemCount / userCount) * 100;
            } else if (systemCount === userCount) {
                accuracy = 100;
            }

            if (register.lineIds.length !== 0) {
                const line = lines.find(l => l.iD === register.lineIds[0]);

                const lineReport = new ValidationReportLineModel();
                if (!isNullOrUndefined(line)) {
                    lineReport.mode = CountModeEnumHelpers.toFullLongName(line.countMode);
                }
                lineReport.systemCount = systemCount;
                lineReport.userCount = userCount;
                lineReport.accuracy = parseInt(accuracy.toFixed(0), 10);
                lineReport.name = register.registerName;

                report.lines.push(lineReport);
            }

        }

        return report;
    }

    private generateReportHead(report: ValidationReportModel, hostDevice: DeviceModel, nodes: Array<DeviceModel>, timeSetup: TimeSetupModel, global: GlobalModel, validationRecording: IValidationRecordingModel, sessionInfo: DbValidationSessionInfoModel): void {
        report.lines = [];
        report.nodes = [];
        report.siteName = global.siteName;
        report.siteId = global.siteId;
        report.timeZone = timeSetup.timezoneIrisysString;
        report.serialNumber = hostDevice.serialNumber;
        report.creationDate = DateTimeUtility.toLongDateTime(sessionInfo.creationDateDate, timeSetup.timeZone);
        report.dateRecorded = DateTimeUtility.toLongDateTime(validationRecording.startTime, timeSetup.timeZone);
        report.duration = DateTimeUtility.toDuration(validationRecording.startTime, validationRecording.endTime);

        const length = nodes.length;
        for (let i = 0; i < length; i++) {
            const node = nodes[i];
            const reportNode = new ValidationReportNodeModel();

            reportNode.deviceID = global.deviceId;
            reportNode.deviceName = global.deviceName;
            reportNode.serialNumber = node.serialNumber;

            report.nodes.push(reportNode);
        }
    }
}
