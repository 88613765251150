import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IDiscriminationModel extends IRestModel {
    integrity: number;
    isExtended: boolean;
    isCoupling: boolean;
}

export class DiscriminationModel extends BaseModel implements IDiscriminationModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public integrity: number = null;
    public isExtended: boolean = null;
    public isCoupling: boolean = null;

    public constructor() {
        super();

        this.registerProperty('integrity');
        this.registerProperty('isExtended');
        this.registerProperty('isCoupling');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-DiscriminationModel');

        this.setPropertyOriginalValue('integrity', this.integrity);
        this.setPropertyOriginalValue('isExtended', this.isExtended);
        this.setPropertyOriginalValue('isCoupling', this.isCoupling);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
