<div mat-dialog-title>
    Select Backup File
</div>
<div mat-dialog-content
    style="max-height: 455px;">
    <div fxLayout="row"
        class="restore-file-select">
        <div fxFlex="33"
            fxLayout="column">
            <div>
                Address Book
            </div>
            <div>
                <mat-list>
                    <mat-list-item [ngClass]="{ 'selected': orphanedDevicesSelected }"
                        (click)="onOrphanedDevicesClicked()">Orphaned Devices</mat-list-item>
                </mat-list>
            </div>
            <div>
                <em-address-book-tree #addressBook
                    [selectedGroup]="selectedGroup"
                    (itemClicked)="onGroupClicked($event)"
                    [badgeHidden]="true"></em-address-book-tree>
            </div>
        </div>
        <div fxFlex="33"
            fxLayout="column">
            <div>
                Devices
            </div>
            <div>
                <mat-list>
                    <mat-list-item [shrDeviceInfoTooltip]="device.deviceInfo"
                        [ngClass]="{ 'selected': device === selectedDevice }"
                        *ngFor="let device of devices"
                        (click)="onDeviceClicked(device)">{{ device.friendlySerial }}</mat-list-item>
                </mat-list>
            </div>
        </div>
        <div fxFlex="33"
            fxLayout="column">
            <div>
                Backups
            </div>
            <div>
                <mat-list>
                    <mat-list-item [ngClass]="{ 'selected': backup === selectedBackup }"
                        *ngFor="let backup of backups"
                        (click)="onBackupClicked(backup)">{{ backup.dateText }}</mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button (click)="onCancelClicked()"
        mat-raised-button
        color="primary">Close</button>
    <div fxFlex></div>
    <button matTooltip="Select A Backup File To Restore"
        mat-raised-button
        (click)="backupFileSelect.click()">File</button>
    <input style="display:none"
        #backupFileSelect
        (change)="onBackupFileSelectChange($event)"
        type='file'
        accept=".irb" />
</div>