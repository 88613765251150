import { ConnectionOptionModel, IConnectionOptionModel } from '@rift/models/restapi/ConnectionOption.Model';
import { IResultModel } from '@rift/models/restapi/Result.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IConnectionOptionsModel extends IRestModel {
    options: Array<IConnectionOptionModel>;
}

export class ConnectionOptionsModel extends BaseModel implements IConnectionOptionsModel, IResultModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public options: Array<ConnectionOptionModel> = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ConnectionOptionsModel');

        this.options = RestModelUtility.loadFromArray(restModel.Options, ConnectionOptionModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
