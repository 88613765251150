import { Component, EventEmitter, HostBinding, Inject, Injector, Output, ChangeDetectorRef } from '@angular/core';
import { FilterOnEntry } from '@em/components/settings/addressbook/Settings.AddressBook.Component';
import { AddressBookTreeComponent } from '@em/components/shared/addressbooktree/AddressBookTree.Component';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { SearchCriteriaModel } from '@em/models/restapi/SearchCriteria.Model';
import { SearchCriteriaGroupModel } from '@em/models/restapi/SearchCriteriaGroup.Model';
import { BaseComponent } from '@shared/base/Base.Component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

export class GroupEditDialogData {
    public constructor(
        public addressBook: AddressBookTreeComponent,
        public selectedGroup: GroupModel,
        public filterOnEntriesSettings: Array<FilterOnEntry>,
        public filterOnEntriesMeta: Array<FilterOnEntry>,
    ) {
    }
}

export class GroupEditDialogResult {
    public constructor() { }
}

@Component({
    selector: 'em-settings-address-book-edit-dialog',
    templateUrl: './Settings.AddressBook.Edit.Dialog.Component.html',
    styleUrls: ['./Settings.AddressBook.Edit.Dialog.Component.scss']
})
export class SettingsAddressBookEditDialogComponent extends BaseComponent {
    public static className: string = 'SettingsAddressBookComponent';

    @HostBinding()
    public id: string = 'em-settings-address-book-edit-dialog';

    @Output()
    public filterValidChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public nameChanged: EventEmitter<null> = new EventEmitter<null>();

    @Output()
    public filterChanged: EventEmitter<null> = new EventEmitter<null>();

    @Output()
    public deleteCriteria: EventEmitter<{ criteria: SearchCriteriaModel; criteriaGroup: SearchCriteriaGroupModel }> = new EventEmitter<{ criteria: SearchCriteriaModel; criteriaGroup: SearchCriteriaGroupModel }>();

    @Output()
    public addCriteria: EventEmitter<SearchCriteriaGroupModel> = new EventEmitter<SearchCriteriaGroupModel>();

    @Output()
    public addCriteriaGroup: EventEmitter<GroupModel> = new EventEmitter<GroupModel>();

    public constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public readonly data: GroupEditDialogData,
        private readonly _dialogRef: MatDialogRef<SettingsAddressBookEditDialogComponent>,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;
    }

    public complete(): void {
        this._dialogRef.close(new GroupEditDialogResult());
    }

    public onFilterValidChanged(valid: boolean): void {
        this.filterValidChanged.emit(valid);
        this._changeDetectorRef.detectChanges();
    }

    public onFilterChanged(): void {
        this.filterChanged.emit(null);
        this._changeDetectorRef.detectChanges();
    }

    public onDeleteCriteria(event: { criteria: SearchCriteriaModel; criteriaGroup: SearchCriteriaGroupModel }): void {
        this.deleteCriteria.emit(event);
        this._changeDetectorRef.detectChanges();
    }

    public onAddCriteria(criteriaGroup: SearchCriteriaGroupModel): void {
        this.addCriteria.emit(criteriaGroup);
        this._changeDetectorRef.detectChanges();
    }

    public onAddCriteriaGroup(group: GroupModel): void {
        this.addCriteriaGroup.emit(group);
        this._changeDetectorRef.detectChanges();
    }

    public onNameChanged(): void {
        this.nameChanged.emit(null);
    }
}
