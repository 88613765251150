import { Injectable } from '@angular/core';
import { AdvancedSearchResultCollectionModel } from '@em/models/restapi/AdvancedSearchResultCollection.Model';
import { BridgeAddressModel } from '@em/models/restapi/BridgeAddress.Model';
import { BridgeConnectionModel } from '@em/models/restapi/BridgeConnection.Model';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { FirmwareSummaryCollectionModel } from '@em/models/restapi/FirmwareSummaryCollection.Model';
import { PageModel } from '@em/models/restapi/Page.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { UpdateLocationModel } from '@em/models/restapi/UpdateLocation.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiDeviceService } from '@em/service/restapi/RestApi.Device.Service';
import { RestApiGroupService } from '@em/service/restapi/RestApi.Group.Service';
import { DeviceGroupEnum } from '@shared/enum/DeviceGroup.Enum';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { IFileResponse, IRetryOptions } from '@shared/service/restapi/RestApi.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceConnectionStatusModel } from '@em/models/restapi/DeviceConnectionStatus.Model';
import { Dictionary } from '@shared/generic/Dictionary';
import { DeviceLiveDataRemovalModel } from '@em/models/restapi/DeviceLiveDataRemoval.Model';
import { ErrorWarningSummaryCollectionModel } from '@em/models/restapi/ErrorWarningSummaryCollection.Model';

@Injectable()
export class DeviceService extends EmBaseService {
    private _deleteDeviceLoadingTracker = new ObservableTracker<ResultModel>();
    private _getActivitySummaryLoadingTracker = new ObservableTracker<AdvancedSearchResultCollectionModel>();
    private _getAllSerialsCache: Array<string>;
    private _getAllSerialsLoadingTracker = new ObservableTracker<Array<string>>();
    private _getBackupDataLoadingTracker = new ObservableTracker<string>();
    private _getBridgeConnectionDetailsLoadingTracker = new ObservableTracker<BridgeConnectionModel>();
    private _getByGroupLoadingTracker = new ObservableTracker<PageModel<DeviceModel>>();
    private _getErrorWarningByGroupLoadingTracker = new ObservableTracker<PageModel<DeviceModel>>();
    private _getDeviceActivityBinaryDataLoadingTracker = new ObservableTracker<IFileResponse>();
    private _getDeviceFirmwareVersionSummaryCache: FirmwareSummaryCollectionModel;
    private _getDeviceLoadingTracker = new ObservableTracker<DeviceModel>();
    private _getDeviceCachedLoadingTracker = new ObservableTracker<DeviceModel>();
    private _getDeviceConnectionStatusLoadingTracker = new ObservableTracker<DeviceConnectionStatusModel>();
    private _getFirmwareVersionSummaryLoadingTracker = new ObservableTracker<FirmwareSummaryCollectionModel>();
    private _updateLocationLoadingTracker = new ObservableTracker<ResultModel>();
    private _getBackupLoadingTracker = new ObservableTracker<IFileResponse>();
    private _addGroupRecordingTracker = new ObservableTracker<ResultModel>();
    private _getVideoLoadingTracker = new ObservableTracker<IFileResponse>();
    private _getDeviceLiveDataRemovalStateTracker = new ObservableTracker<DeviceLiveDataRemovalModel>();
    private _addOrUpdateLiveDataRemovalStateTracker = new ObservableTracker<ResultModel>();

    private _deviceCache: Dictionary<string, DeviceModel> = new Dictionary<string, DeviceModel>();

    public constructor(
        private readonly _restApiDeviceService: RestApiDeviceService,
        private readonly _restApiGroupService: RestApiGroupService) {
        super();
    }

    public addGroupRecording(groupId: DeviceGroupEnum | number, plannedStartTime: Date, recordingDuration: number, recordingDurationIsMinutes: boolean, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._addGroupRecordingTracker
            .getLoading(groupId, plannedStartTime, recordingDuration, recordingDurationIsMinutes)
            .observable(this._restApiDeviceService.addGroupRecording(groupId, plannedStartTime, recordingDuration, recordingDurationIsMinutes, process));
    }


    public advancedSearch(value: string, process?: ProcessMonitorServiceProcess): Observable<AdvancedSearchResultCollectionModel> {
        return this._getActivitySummaryLoadingTracker
            .getLoading(value)
            .observable(this._restApiDeviceService.advancedSearch(value, process));
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getAllSerialsCache = null;
        this._getDeviceFirmwareVersionSummaryCache = null;
        this._deviceCache = new Dictionary<string, DeviceModel>();
    }

    public getDeviceLiveDataRemovalState(friendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<DeviceLiveDataRemovalModel>{
        return this._getDeviceLiveDataRemovalStateTracker
            .getLoading(friendlySerial)
            .observable(this._restApiDeviceService.getDeviceLiveDataRemovalState(friendlySerial, process));
    }

    public addOrUpdateLiveDataRemovalState(data: DeviceLiveDataRemovalModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel>{
        return this._addOrUpdateLiveDataRemovalStateTracker
            .getLoading(data)
            .observable(this._restApiDeviceService.addOrUpdateLiveDataRemovalState(data, process));
    }

    public deleteDevice(friendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._deleteDeviceLoadingTracker
            .getLoading(friendlySerial)
            .observable(this._restApiDeviceService.deleteDevice(friendlySerial, process));
    }


    public getAllSerials(process?: ProcessMonitorServiceProcess): Observable<Array<string>> {
        if (isNullOrUndefined(this._getAllSerialsCache)) {
            return this._getAllSerialsLoadingTracker
                .getLoading()
                .observable(this._restApiGroupService.getSerials(DeviceGroupEnum.all, process).pipe(
                    map(result => {
                        this._getAllSerialsCache = result;
                        return this._getAllSerialsCache;
                    })
                ));
        } else {
            return of(this._getAllSerialsCache);
        }
    }


    public getBackupData(friendlySerial: string, activityId: number, process?: ProcessMonitorServiceProcess): Observable<string> {
        return this._getBackupDataLoadingTracker
            .getLoading(friendlySerial, activityId)
            .observable(this._restApiDeviceService.getBackupData(friendlySerial, activityId, process));
    }

    public getBackup(friendlySerial: string, activityId: number, process?: ProcessMonitorServiceProcess): Observable<IFileResponse> {
        return this._getBackupLoadingTracker
            .getLoading(friendlySerial, activityId)
            .observable(this._restApiDeviceService.getBackup(friendlySerial, activityId, process));
    }

    public getBridgeConnectionDetails(friendlySerial: string, address: BridgeAddressModel, process?: ProcessMonitorServiceProcess): Observable<BridgeConnectionModel> {
        return this._getBridgeConnectionDetailsLoadingTracker
            .getLoading(friendlySerial, address)
            .observable(this._restApiDeviceService.getBridgeConnectionDetails(friendlySerial, address, process));
    }


    public getByGroup(groupId: number | DeviceGroupEnum, paginationOptions?: PaginationOptionsModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<PageModel<DeviceModel>> {
        return this._getByGroupLoadingTracker
            .getLoading(groupId, paginationOptions)
            .observable(this._restApiGroupService.getDevices(groupId, paginationOptions, process, retryOptions));
    }

    public getErrorWarningByGroup(message: string, paginationOptions?: PaginationOptionsModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<PageModel<DeviceModel>> {
        return this._getErrorWarningByGroupLoadingTracker
            .getLoading(message, paginationOptions)
            .observable(this._restApiGroupService.getErrorWarningDevices(message, paginationOptions, process, retryOptions));
    }

    public getErrorWarningSummary(process?: ProcessMonitorServiceProcess): Observable<ErrorWarningSummaryCollectionModel> {
        return this._restApiGroupService.getErrorWarningSummary(process);
    }

    public getDeviceCached(friendlySerial: string, includeNodes: boolean = false, process?: ProcessMonitorServiceProcess): Observable<DeviceModel> {
        const cache = this._deviceCache.get(friendlySerial);
        if (isNullOrUndefined(cache)) {
            return this._getDeviceCachedLoadingTracker
                .getLoading(friendlySerial)
                .observable(this._restApiDeviceService.getDevice(friendlySerial, false, process).pipe(
                    map(result => {
                        if(result !== null){
                            this._deviceCache.addOrUpdate(result.friendlySerial, result);
                        }
                        return result;
                    })
                ));
        } else {
            return of(cache);
        }
    }

    public getDevice(friendlySerial: string, includeNodes: boolean = false, process?: ProcessMonitorServiceProcess): Observable<DeviceModel> {
        return this._getDeviceLoadingTracker
            .getLoading(friendlySerial, includeNodes)
            .observable(this._restApiDeviceService.getDevice(friendlySerial, includeNodes, process));
    }

    public getDeviceConnectionStatus(friendlySerial: string, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<DeviceConnectionStatusModel> {
        return this._getDeviceConnectionStatusLoadingTracker
            .getLoading(friendlySerial)
            .observable(this._restApiDeviceService.getDeviceConnectionStatus(friendlySerial, process, retryOptions));
    }


    public getDeviceActivityBinaryData(friendlySerial: string, activityId: number, process?: ProcessMonitorServiceProcess): Observable<IFileResponse> {
        return this._getDeviceActivityBinaryDataLoadingTracker
            .getLoading(friendlySerial, activityId)
            .observable(this._restApiDeviceService.getDeviceActivityBinaryData(friendlySerial, activityId, process));
    }


    public getFirmwareVersionSummary(process?: ProcessMonitorServiceProcess): Observable<FirmwareSummaryCollectionModel> {
        if (isNullOrUndefined(this._getDeviceFirmwareVersionSummaryCache)) {
            return this._getFirmwareVersionSummaryLoadingTracker
                .getLoading()
                .observable(this._restApiDeviceService.getFirmwareVersionSummary(process).pipe(
                    map(result => {
                        this._getDeviceFirmwareVersionSummaryCache = result;
                        return this._getDeviceFirmwareVersionSummaryCache;
                    })
                ));
        } else {
            return of(this._getDeviceFirmwareVersionSummaryCache);
        }
    }


    public updateLocation(location: UpdateLocationModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateLocationLoadingTracker
            .getLoading(location)
            .observable(this._restApiDeviceService.updateLocation(location, process));
    }

    public getVideo(friendlySerial: string, videoEntryId: number, process?: ProcessMonitorServiceProcess): Observable<IFileResponse> {
        return this._getVideoLoadingTracker
            .getLoading(friendlySerial, videoEntryId)
            .observable(this._restApiDeviceService.getVideo(friendlySerial, videoEntryId, process));
    }
}
