import { Injectable } from '@angular/core';
import { RoleModel } from '@em/models/restapi/Role.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiUserService } from '@em/service/restapi/RestApi.User.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';

@Injectable()
export class RoleService extends EmBaseService {
    private _getCache: Array<RoleModel>;

    private _getLoadingTracker = new ObservableTracker<Array<RoleModel>>();

    public constructor(
        private readonly _restApiUserService: RestApiUserService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getCache = null;
    }
    public get(process?: ProcessMonitorServiceProcess): Observable<Array<RoleModel>> {
        if (isNullOrUndefined(this._getCache)) {
            return this._getLoadingTracker
                .getLoading()
                .observable(this._restApiUserService.getRoles(process).pipe(
                    map(result => {
                        this._getCache = result;
                        return this._getCache;
                    })
                ));
        } else {
            return of(this._getCache);
        }
    }
}
