import { DeviceDataType } from '@shared/enum/DeviceDataType.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';

export class ExtendedDataModel extends BaseModel implements IRestModel {
    public readonly isIRestModel = true;
    public type: DeviceDataType = null;
    public value: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ExtendedDataModel');
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
