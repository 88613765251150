import { IRestModel } from '@shared/interface/IRestModel';
import { IRestPage } from '@shared/interface/IRestPage';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

/**
 * Helper methods for PageModel.
 *
 * @export
 * @class PageModelUtility
 */
export class PageModelUtility {

    /**
     * Loads page model from JSON object array.
     *
     * @static
     * @template TItem The page item type.
     * @template TPage The page model type.
     * @param {*} data The JSON object array to load page model from.
     * @param {(new () => IRestModel)} modelType The item type.
     * @param {(new () => IRestPage<TItem>)} pageType The page model type.
     * @returns {TPage} A PageModel.
     * @memberof PageModelUtility
     */
    public static loadFromArray<TItem, TPage extends IRestPage<TItem>>(data: any, modelType: (new () => IRestModel), pageType: (new () => IRestPage<TItem>)): TPage {
        const model = new pageType();
        model.loadFromRestApiModel(data);
        model.items = RestModelUtility.loadFromArray(data, modelType) as any;
        return model as TPage;
    }

    /**
     * True if object is page model. else false.
     *
     * @static
     * @param {*} object The object to check.
     * @returns {boolean} True if object is page model. else false.
     * @memberof PageModelUtility
     */
    public static isIPage(object: any): boolean {
        return !isNullOrUndefined(object) && !isNullOrUndefined(object.isIPage) && object.isIPage === true;
    }
    /**
     * Loads page model from JSON object.
     *
     * @static
     * @template TItem The page item type.
     * @template TPage The page model type.
     * @param {*} data The JSON object to load page model from.
     * @param {(new () => IRestModel)} modelType The item type.
     * @param {(new () => IRestPage<TItem>)} pageType The page model type.
     * @returns {TPage} A PageModel.
     * @memberof PageModelUtility
     */
    public static LoadFrom<TItem, TPage extends IRestPage<TItem>>(data: any, modelType: (new () => IRestModel), pageType: (new () => IRestPage<TItem>)): TPage {
        const model = new pageType();
        model.loadFromRestApiModel(data);
        return model as TPage;
    }
}
