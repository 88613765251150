<div class="em-settings-options-tasklauncher"
    fxLayout="column">    
    <form [formGroup]="taskLauncherFormGroup">
        <mat-card class="header">
            <mat-card-title>
                <span class="mat-title">Task Launcher</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span class="mat-subheading-1">Task Launcher can be enabled and disabled at any time. Disabling Task Launcher will finish the current task
                    but process no more, this allows for manual control over when Task Launcher processes tasks.</span>
            </mat-card-subtitle>
            <mat-card-content>
                <mat-checkbox #taskLauncherEnabled
                    formControlName="taskLauncherEnabled">Enable Task Launcher</mat-checkbox>
                <span>{{ settingTaskLauncherEnabled?.propertyHasChangesText('value') }}</span>
            </mat-card-content>
            <mat-card-title>
                <span class="mat-title">Task Launcher Log Location</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span class="mat-subheading-1">This is where Estate Manager will store logs generated whilst running tasks (services must be restarted for new paths to be applied).</span>
            </mat-card-subtitle>
            <mat-card-content>
                <mat-form-field style="width: 400px;">
                    <input type="text"
                        #taskLauncherRootPath
                        matInput
                        formControlName="taskLauncherRootPath"
                        placeholder="Storage URI">
                    <span matSuffix>{{ settingTaskLauncherRootPath?.propertyHasChangesText('value') }}</span>
                    <mat-error>{{getFormControlErrorMessage(taskLauncherFormGroup.controls['taskLauncherRootPath'])}}</mat-error>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </form>
</div>