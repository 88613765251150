import { TargetStatusEnum } from '@shared/enum/TargetStatus.Enum';

export class TargetLocation {
    public constructor(
        private readonly _point: createjs.Point,
        private readonly _height: number,
        private readonly _frame: number,
        private readonly _status: Array<TargetStatusEnum>) {

    }

    public get frame(): number {
        return this._frame;
    }

    public get point(): createjs.Point {
        return this._point;
    }

    public get height(): number {
        return this._height;
    }

    public get status(): Array<TargetStatusEnum> {
        return this._status;
    }
}
