import { Location } from '@angular/common';
import { Component, Inject, Injector, OnInit, ViewChild, HostBinding } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { IValidatableRecordingModel } from '@rift/service/validation/models/ValidatableRecording.Model';
import { ValidationService } from '@rift/service/validation/Validation.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { IViewModel } from '@shared/interface/IViewModel';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IViewModelUtility } from '@shared/utility/IViewModel.Utility';
import { Observable, zip, of, concat } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { ValidationDataService } from '@rift/service/data/validation/Validation.Service';
import { ValidationSessionStateEnum } from '@shared/enum/ValidationSessionState.Enum';
import { RecordingService } from '@rift/service/data/recording/Recording.Service';
import { VideoStorageCapacityModel } from '@rift/models/restapi/VideoStorageCapacity.Model';
import { ITableRowClicked } from '@shared/component/table/Table.Component';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '@rift/service/connection/Connection.Service';

export class SelectValidationRecordingData {
    public constructor() { }
}

export class SelectValidationRecordingResult {
    public constructor(public readonly sessionId?: number, public readonly startTime?: Date) { }
}

class ValidatableRecordingViewModel implements IViewModel {
    public item: IValidatableRecordingModel;
    public isSelected: boolean = false;
    public dataUri: string = null;
    public nodeCount: number = 0;
    public sessionsCount: number = 0;
    public openSessionsCount: number = 0;
    public completedSessionsCount: number = 0;
    public passedSessionsCount: number = 0;
    public failedSessionsCount: number = 0;
    public startDate: Date = null;
    public startTime: Date = null;
    public endTime: Date = null;

    private _startDateText: string = null;
    private _startTimeText: string = null;
    private _durationText: string = null;
    private _endTimeText: string = null;

    public constructor(validatableRecording: IValidatableRecordingModel) {
        this.item = validatableRecording;
        this.nodeCount = validatableRecording.onNodes.length;
        this.setTimeText();
    }

    public setTimeText(): any {
        this.startDate = this.item.startTime;
        this.startTime = this.item.startTime;
        this.endTime = this.item.endTime;

        if (isNullOrUndefined(this._startDateText) || DateTimeUtility.isInvalidDate(this._startDateText)) {
            this._startDateText = DateTimeUtility.toShortDate(this.item.startTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.item.timezoneOffsetMins));
        }

        if (isNullOrUndefined(this._startTimeText) || DateTimeUtility.isInvalidDate(this._startTimeText)) {
            this._startTimeText = DateTimeUtility.toShortTime(this.item.startTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.item.timezoneOffsetMins));
        }

        if (isNullOrUndefined(this._durationText) || this._durationText === 'NaN:NaN:NaN') {
            this._durationText = DateTimeUtility.toDuration(this.item.startTime, this.item.endTime);
        }

        if (isNullOrUndefined(this._endTimeText) || DateTimeUtility.isInvalidDate(this._endTimeText)) {
            this._endTimeText = DateTimeUtility.toShortTime(this.item.endTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.item.timezoneOffsetMins));
        }
    }

    public get startDateText(): string {
        return this._startDateText;
    }

    public get startTimeText(): string {
        return this._startTimeText;
    }

    public get durationText(): string {
        return this._durationText;
    }

    public get endTimeText(): string {
        return this._endTimeText;
    }
}

@Component({
    selector: 'rift-select-validation-recording',
    templateUrl: './SelectValidationRecording.Component.html',
    styleUrls: ['./SelectValidationRecording.Component.scss']
})
export class SelectValidationRecordingComponent extends RiftBaseComponent implements OnInit, ILoadDate {
    public static className = 'SelectValidationRecordingComponent';

    @HostBinding()
    public id: string = 'rift-select-validation-recording';

    public displayedColumns = ['frame', 'startDateText', 'startTimeText', 'durationText', 'endTimeText', 'nodes', 'sessions', 'open', 'completed', 'passed', 'failed'];
    public dataSource: MatTableDataSource<ValidatableRecordingViewModel> = new MatTableDataSource<ValidatableRecordingViewModel>();
    public selectedVM: ValidatableRecordingViewModel;
    public getRecordingFirstFrameProcess: ProcessMonitorServiceProcess;
    public videoStorageCapacity: VideoStorageCapacityModel;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: SelectValidationRecordingData,
        private readonly _recordingService: RecordingService,
        private readonly _location: Location,
        private readonly _dialog: MatDialog,
        private readonly _dialogRef: MatDialogRef<SelectValidationRecordingComponent>,
        private readonly _validationService: ValidationService,
        private readonly _validationDataService: ValidationDataService,
        private readonly _connectionService: ConnectionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess(SelectValidationRecordingComponent.className, this.loadDataProcessText);
        this.getRecordingFirstFrameProcess = this.processMonitorService.getProcess(SelectValidationRecordingComponent.className, this.loadDataProcessText);

        this.initConnectionState();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.dataSource.sortingDataAccessor = IViewModelUtility.getDataSourceSortingDataAccessor(this.dataSource);
        this.setDataSourceFilterPredicate(this.dataSource);
    }

    public setDataSourceFilterPredicate(dataSource: MatTableDataSource<ValidatableRecordingViewModel>): void {
        dataSource.filterPredicate = (data: ValidatableRecordingViewModel, filter: string) =>
            data.startTimeText.indexOf(filter) !== -1 ||
            data.endTimeText.indexOf(filter) !== -1 ||
            data.durationText.indexOf(filter) !== -1 ||
            data.startDateText.indexOf(filter) !== -1;
    }

    public applyFilter(filterValue: string) {
        if (!this.isNullOrUndefined(this.dataSource.data) && this.dataSource.data.length > 0) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            this.dataSource.filter = filterValue;
        }
    }

    public selected(event: ITableRowClicked<ValidatableRecordingViewModel>) {
        if (!this.isNullOrUndefined(this.selectedVM)) {
            this.selectedVM.isSelected = false;
        }
        this.selectedVM = event.data;
        this.selectedVM.isSelected = true;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._recordingService.getVideoStorageCapacity(this.connectionService.hostFriendlySerial, process).pipe(
                flatMap(videoStorageCapacity => {
                    this.videoStorageCapacity = videoStorageCapacity;
                    if (this.videoStorageCapacity.noSDCard === false && this.videoStorageCapacity.sdCardNotFormatted === false) {
                        return this.getHostDevice(process).pipe(
                            flatMap(hostDevice => {
                                if (!this.isNullOrUndefined(hostDevice)) {
                                    return this._validationService.getDeviceRecordings(hostDevice.serialNumber, process).pipe(
                                        flatMap(validatableRecordings => {
                                            if (!this.isNullOrUndefined(validatableRecordings)) {
                                                const getSessionsSubs: Observable<ValidatableRecordingViewModel>[] = [];
                                                const length = validatableRecordings.length;

                                                const obs: Observable<boolean>[] = [];

                                                for (let i = 0; i < length; i++) {
                                                    const validatableRecording = validatableRecordings[i];

                                                    if (validatableRecording.isSynced === false || (!isNullOrUndefined(validatableRecording.onNodes) && validatableRecording.onNodes.some(val => val.isSynced === false))){
                                                        continue;
                                                    }

                                                    const vm = new ValidatableRecordingViewModel(validatableRecording);

                                                    const firstFrameOb = this._validationService.getRecordingFirstFrame(hostDevice.serialNumber, vm.item, this.getRecordingFirstFrameProcess).pipe(flatMap(
                                                        result => {
                                                            vm.dataUri = result.dataUri;
                                                            return of(true);
                                                        }
                                                    ));

                                                    obs.push(firstFrameOb);

                                                    getSessionsSubs.push(
                                                        this._validationDataService.getAllSessionsForAllVideos(hostDevice.serialNumber, process).pipe(
                                                            map(sessionsMap => {
                                                                if (!isNullOrUndefined(sessionsMap)) {
                                                                    const sessionsValue = sessionsMap.filter((k, v) => vm.item.id === k[0].id && vm.item.startTime.toString() === k[0].startTime.toString()).map(kv => kv[1]);
                                                                    if (!this.isNullOrUndefined(sessionsValue) && sessionsValue.length === 1) {
                                                                        const sessions = sessionsValue[0];
                                                                        vm.sessionsCount = sessions.length;

                                                                        const openSessions = sessions.filter(s => s.state === ValidationSessionStateEnum.incomplete);
                                                                        const completedSessions = sessions.filter(s => s.state !== ValidationSessionStateEnum.incomplete);
                                                                        const failedSessions = sessions.filter(s => s.state === ValidationSessionStateEnum.rejected);
                                                                        const passedSessions = sessions.filter(s => s.state === ValidationSessionStateEnum.validated);

                                                                        vm.openSessionsCount = !this.isNullOrUndefined(openSessions) ? openSessions.length : 0;
                                                                        vm.completedSessionsCount = !this.isNullOrUndefined(completedSessions) ? completedSessions.length : 0;
                                                                        vm.failedSessionsCount = !this.isNullOrUndefined(failedSessions) ? failedSessions.length : 0;
                                                                        vm.passedSessionsCount = !this.isNullOrUndefined(passedSessions) ? passedSessions.length : 0;
                                                                    }
                                                                }
                                                                return vm;
                                                            })
                                                        )
                                                    );
                                                }

                                                this.addSubscription(concat(...obs).subscribe(), this.getRecordingFirstFrameProcess);

                                                if (getSessionsSubs.length === 0) {
                                                    return of(true);
                                                } else {
                                                    return zip(...getSessionsSubs).pipe(
                                                        map(vms => {
                                                            this.dataSource.data = vms;
                                                            return true;
                                                        })
                                                    );
                                                }
                                            }
                                            return of(true);
                                        })
                                    );
                                }
                                return of(true);
                            })
                        );
                    } else {
                        return of(true);
                    }
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public close(): void {
        this._dialogRef.close(new SelectValidationRecordingResult());
    }

    public validateRecording(): void {
        this._dialogRef.close(new SelectValidationRecordingResult(this.selectedVM.item.id, this.selectedVM.item.startTime));
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();

    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }
}
