import { ChangeDetectorRef, Component, HostBinding, HostListener, Injector, QueryList, ViewChild, ViewChildren, OnDestroy, OnInit } from '@angular/core';
import { SettingsAddressBookFilterComponent } from '@em/components/settings/addressbook/filter/Settings.AddressBook.Filter.Component';
import { AddressBookSelectComponent } from '@em/components/shared/addressbookselect/AddressBookSelect.Component';
import { AddressBookTreeComponent } from '@em/components/shared/addressbooktree/AddressBookTree.Component';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { SearchCriteriaModel } from '@em/models/restapi/SearchCriteria.Model';
import { SearchCriteriaGroupModel } from '@em/models/restapi/SearchCriteriaGroup.Model';
import { GroupService } from '@em/service/data/group/Group.Service';
import { MetaDataKeysService } from '@em/service/data/metadatakeys/MetaDataKeys.Service';
import { GroupUtil } from '@em/utility/GroupUtil';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ComparisonOperatorEnum } from '@shared/enum/ComparisonOperator.Enum';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { EventsService } from '@shared/service/events/Events.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { from, Observable, of, zip, PartialObserver, concat, forkJoin } from 'rxjs';
import { combineAll, concatMap, flatMap, last, map, tap, toArray, zipAll } from 'rxjs/operators';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { SettingsAddressBookEditDialogComponent, GroupEditDialogData, GroupEditDialogResult } from '@em/components/settings/addressbook/edit/Settings.AddressBook.Edit.Dialog.Component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UserService } from '@em/service/data/user/User.Service';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { UserModel } from '@em/models/restapi/User.Model';


export class FilterOnEntry {
    public static className: string = 'FilterOnEntry';
    public text: string;

    public value: string;
    public valueMaxLength: number;

    public constructor(value: string, text: string, valueMaxLength: number) {
        this.value = value;
        this.text = text;
        this.valueMaxLength = valueMaxLength;
    }
}

@Component({
    selector: 'em-settings-address-book',
    templateUrl: './Settings.AddressBook.Component.html',
    styleUrls: ['./Settings.AddressBook.Component.scss']
})
export class SettingsAddressBookComponent extends BaseComponent implements OnInit, OnDestroy, OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsAddressBookComponent';

    @HostBinding()
    public id: string = 'em-settings-address-book';

    public getGroupProcess: ProcessMonitorServiceProcess;
    public moveGroupProcess: ProcessMonitorServiceProcess;
    public ComparisonOperatorEnum = ComparisonOperatorEnum;

    public rootTreeGroup: GroupModel;
    public selectedGroup: GroupModel;
    public showItemEditIcon: boolean = false;

    public filterOnEntriesSettings: Array<FilterOnEntry> = [
        new FilterOnEntry('/config/GeneralSection/DeviceName', 'Device Name', 60),
        new FilterOnEntry('/config/GeneralSection/DeviceID', 'Device ID', 100),
        new FilterOnEntry('/config/GeneralSection/SiteName', 'Site Name', 60),
        new FilterOnEntry('/config/GeneralSection/SiteID', 'Site ID', 60),
        new FilterOnEntry('/config/GeneralSection/SerialNumber', 'Serial Number', 60),
        new FilterOnEntry('/config/GeneralSection/UserString', 'User String', 63),
    ];

    public filterOnEntriesMeta: Array<FilterOnEntry> = [];

    @ViewChild(AddressBookTreeComponent, { static: true })
    public addressBook: AddressBookTreeComponent;

    @ViewChildren(SettingsAddressBookFilterComponent)
    public filters: QueryList<SettingsAddressBookFilterComponent>;

    private _deletedGroupIds: Array<number> = [];
    private _editGroupDialogRef: MatDialogRef<SettingsAddressBookEditDialogComponent>;

    public constructor(
        private readonly _mediaObserver: MediaObserver,
        private readonly _dataPollingService: DataPollingService,
        private readonly _eventsService: EventsService,
        private readonly _metaDataKeysService: MetaDataKeysService,
        private readonly _dialog: MatDialog,
        private readonly _groupService: GroupService,
        private readonly _userService: UserService,
        private readonly _currentUserService: UserCurrentService,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector,
        private readonly _changeDetectorRef: ChangeDetectorRef) {
        super(_injector, _dialog, _navBarService);

        this.addSubscription(this._mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
            if (!this.isNullOrUndefined(changes)){
                changes.forEach(change =>{
                    if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
                        this.showItemEditIcon = true;
                    } else {
                        this.showItemEditIcon = false;
                        this.closeEditGroupDialog();
                    }
                });
            }
        }));

        this._dataPollingService.stopEvent(this._dataPollingService.getEvent('NavMenuComponent:AddressBook'));

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsAddressBookComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsAddressBookComponent.className, this.saveAllChangesProcessText);
        this.getGroupProcess = this.processMonitorService.getProcess(SettingsAddressBookComponent.className, 'Getting group extended info');
        this.moveGroupProcess = this.processMonitorService.getProcess(SettingsAddressBookComponent.className, 'Moving group');

        this.addSaveAllAction(this);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public editGroup(group: GroupModel): void {
        this.openEditGroupDialog();
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this._dataPollingService.stopEvent(this._dataPollingService.getEvent('NavMenuComponent:AddressBook'));
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this._dataPollingService.startEvent(this._dataPollingService.getEvent('NavMenuComponent:AddressBook'));
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._groupService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    public nameChanged(): void {
        this.setTreeGroupError(this.selectedGroup);
        this.updateSaveAllActionAndChangeDetect();
    }

    public filterChanged(): void {
        this.setTreeGroupError(this.selectedGroup);
        this.updateSaveAllActionAndChangeDetect();
    }

    public filterValidChanged(): void {
        this.setTreeGroupError(this.selectedGroup);
        this.updateSaveAllActionAndChangeDetect();
    }

    public editGroupNameComplete(group: GroupModel): void {
        const cleanValue = group.name.trim();

        if (this.isEmptyOrWhiteSpace(cleanValue) || cleanValue.length > 100) {
            group.name = group.getPropertyOriginalValue('name');
        } else {
            group.name = cleanValue;
        }

        this.updateSaveAllActionAndChangeDetect();
    }

    public reOrderGroupChildren(group: GroupModel): void {
        const length = group.children.length;

        for (let i = 0; i < length; i++) {
            const child = group.children[i];

            if (child.order !== (i + 1)) {
                child.order = i + 1;
            }
        }

        this.updateSaveAllActionAndChangeDetect();
    }

    public moveGroup(moveGroup: GroupModel): void {
        const dialogRef = this._dialog.open(AddressBookSelectComponent, { data: this.rootTreeGroup, disableClose: true });
        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.moveGroupProcess).subscribe((toGroup: GroupModel) => {
            if (!this.isNullOrUndefined(toGroup) && !this.isNullOrUndefined(moveGroup) && moveGroup.uniqueId !== toGroup.uniqueId && !GroupUtil.isChildOf(moveGroup, toGroup)) {
                const moveParent = moveGroup.parent;
                const moveIndex = moveParent.children.findIndex(i => i.uniqueId === moveGroup.uniqueId);

                moveParent.children.splice(moveIndex, 1);
                toGroup.children.push(moveGroup);
                moveGroup.parent = toGroup;
                moveGroup.parentId = toGroup.id;

                this.reOrderGroupChildren(moveParent);
                this.reOrderGroupChildren(toGroup);

                this.addressBook.renderNodeChanges();
                this.addressBook.renderNodeChanges();

                dialogRef._containerInstance.dispose();

                this.updateSaveAllActionAndChangeDetect();
            }
        }), this.moveGroupProcess);
    }

    public addChildGroup(parent?: GroupModel): void {
        if (this.isNullOrUndefined(parent)) {
            parent = this.rootTreeGroup;
        }

        const child = new GroupModel();
        child.parent = parent;
        child.parentId = parent.id;
        child.children = new RestModelChangeTrackerArray<GroupModel>();
        child.isNew = true;
        child.noEdit = false;
        child.isRoot = false;

        if (this.isNullOrUndefined(parent.children)) {
            parent.children = new RestModelChangeTrackerArray<GroupModel>();
        }

        parent.children.push(child);

        this.reOrderGroupChildren(parent);

        child.name = `New Node (${child.order})`;

        this.addressBook.renderNodeChanges();

        this.updateSaveAllActionAndChangeDetect();

        this.groupSelected(child);
    }

    public deleteGroup(group: GroupModel): void {
        const parent = group.parent;

        const index = parent.children.findIndex(i => i.uniqueId === group.uniqueId);

        if (group.isNew === false) {
            this._deletedGroupIds.push(group.id);
        }

        parent.children.splice(index, 1);

        this.reOrderGroupChildren(parent);

        this.addressBook.renderNodeChanges();

        this.updateSaveAllActionAndChangeDetect();

        this.groupSelected(parent);
    }

    public moveGroupUp(group: GroupModel): void {
        const parent = group.parent;

        const index = parent.children.findIndex(i => i.uniqueId === group.uniqueId);

        parent.children.splice(index, 1);
        parent.children.splice(index - 1, 0, group);

        this.reOrderGroupChildren(parent);

        this.addressBook.renderNodeChanges();

        this.updateSaveAllActionAndChangeDetect();
    }

    public moveGroupDown(group: GroupModel): void {
        const parent = group.parent;

        const index = parent.children.findIndex(i => i.uniqueId === group.uniqueId);
        parent.children.splice(index, 1);
        parent.children.splice(index + 1, 0, group);

        this.reOrderGroupChildren(parent);

        this.addressBook.renderNodeChanges();

        this.updateSaveAllActionAndChangeDetect();
    }

    public get hasSelectedGroup(): boolean {
        return !this.isNullOrUndefined(this.selectedGroup);
    }

    public isGroupValid(group: GroupModel): boolean {
        const groupValid = !this.isNullOrUndefined(group.name) &&
            !StringUtility.isEmptyOrWhiteSpace(group.name) &&
            group.name.length <= 120 &&
            (
                this.isNullOrUndefined(group.searchCriteriaGroups) ||
                group.searchCriteriaGroups.length === 0 ||
                !group.searchCriteriaGroups.some(scg => scg.searchCriteria.some(sc => sc.isValid === false))
            );

        return groupValid;
    }

    public groupSelected(group: GroupModel): void {
        let selectedGroupValid = true;
        if (!this.isNullOrUndefined(this.selectedGroup)) {
            selectedGroupValid = this.setTreeGroupError(this.selectedGroup);
        }

        if (selectedGroupValid === true) {
            this.setTreeGroupError(group);
            if (group.parent !== null) {
                this.selectedGroup = group;
            } else {
                this.selectedGroup = null;
            }
        }

        this.updateSaveAllActionAndChangeDetect();
    }

    public deleteCriteria(event: { criteria: SearchCriteriaModel; criteriaGroup: SearchCriteriaGroupModel }): void {
        const index = event.criteriaGroup.searchCriteria.findIndex(i => i.uniqueId === event.criteria.uniqueId);
        event.criteriaGroup.searchCriteria.splice(index, 1);

        if (!this.isNullOrUndefined(this.selectedGroup.searchCriteriaGroups) && event.criteriaGroup.searchCriteria.length === 0) {
            const groupIndex = this.selectedGroup.searchCriteriaGroups.findIndex(i => i.uniqueId === event.criteriaGroup.uniqueId);
            this.selectedGroup.searchCriteriaGroups.splice(groupIndex, 1);
        }
        this.setTreeGroupError(this.selectedGroup);

        this.updateSaveAllActionAndChangeDetect();
    }

    public addCriteria(criteriaGroup: SearchCriteriaGroupModel): void {
        const criteria = new SearchCriteriaModel();
        criteria.isValid = false;
        criteria.searchParameter = StringUtility.empty;
        criteriaGroup.searchCriteria.push(criteria);

        this.setTreeGroupError(this.selectedGroup);

        this.updateSaveAllActionAndChangeDetect();
    }

    public addCriteriaGroup(group: GroupModel): void {
        if (group.searchCriteriaGroups) {
            const criteriaGroup = new SearchCriteriaGroupModel();
            criteriaGroup.searchCriteria = new RestModelChangeTrackerArray<SearchCriteriaModel>();
            group.searchCriteriaGroups.push(criteriaGroup);

            this.addCriteria(criteriaGroup);
            this.setTreeGroupError(this.selectedGroup);

            this.updateSaveAllActionAndChangeDetect();
        }
    }

    public setTreeGroupError(group: GroupModel): boolean {
        if (!this.isNullOrUndefined(group)) {
            const valid = this.isGroupValid(group);
            if (valid) {
                group.treeError = null;
            } else {
                group.treeError = 'Invalid';
            }
            this.updateSaveAllActionAndChangeDetect();
            return valid;
        }
    }

    public get hasChanges(): boolean {
        const groups = ArrayUtility.flatten([], this.rootTreeGroup).some(group =>
            group.hasChanges === true ||
            this.isNullOrUndefined(group.searchCriteriaGroups) ||
            group.searchCriteriaGroups.hasChanges === true ||
            group.searchCriteriaGroups.some(cGroup => cGroup.searchCriteria.some(c => c.hasChanges === true))
        );
        const deleted = this._deletedGroupIds.length > 0;
        return groups || deleted;
    }

    public get isValid(): boolean {
        return !ArrayUtility.flatten([], this.rootTreeGroup).some(group => this.isGroupValid(group) === false);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._groupService.getNested(process).pipe(
                flatMap(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.rootTreeGroup = result;

                        return this._currentUserService.user.pipe(
                            flatMap(userDetails=>this._userService.getGroups((userDetails as UserModel).userId).pipe(
                                    flatMap(userGroups=>{
                                        const flatGroups = ArrayUtility.flatten([], this.rootTreeGroup);

                                        userGroups.items.forEach(ug=>{
                                            const foundGroup = flatGroups.find(g=>g.id === ug.id);

                                            if(!this.isNullOrUndefined(foundGroup) && foundGroup.parent !== null && foundGroup.parent.noEdit === true){
                                                foundGroup.isRoot = true;
                                            }
                                        });

                                        return zip(...flatGroups.map(i => this._groupService.getSearchCriteriaGroups(i.id).pipe(map((items) => ({ items, id: i.id }))))).pipe(
                                            map(criteriaResults => {
                                                const length = criteriaResults.length;
                                                for (let i = 0; i < length; i++) {
                                                    const criteriaResult = criteriaResults[i];
                                                    const group = flatGroups.find(g => g.id === criteriaResult.id);
                                                    if (!this.isNullOrUndefined(group)) {
                                                        group.searchCriteriaGroups = new RestModelChangeTrackerArray<SearchCriteriaGroupModel>(...criteriaResult.items);
                                                        group.searchCriteriaGroups.commitChanges();
                                                    }
                                                }
                                                return true;
                                            }),
                                        );
                                    })
                                ))
                        );
                    } else {
                        return of(false);
                    }
                }),
            ),
            this._metaDataKeysService.getKeys(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        // Remove the old meta values first
                        if (!this.isNullOrUndefined(this.filterOnEntriesMeta)) {
                            this.filterOnEntriesMeta.splice(0, this.filterOnEntriesMeta.length);
                        }

                        const keysLength = result.items.length;

                        for (let i = 0; i < keysLength; i++) {
                            const key = result.items[i];
                            const filterOnEntry = new FilterOnEntry(StringUtility.toString(key.metaDataKeyId), key.name, 100);
                            this.filterOnEntriesMeta.push(filterOnEntry);
                        }
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = forkJoin([concat(
            this.saveGroupChanges(this.rootTreeGroup, process),
            of(this._deletedGroupIds).pipe(
                flatMap(deleteGroupIds => {
                    if (!this.isNullOrUndefined(deleteGroupIds) && deleteGroupIds.length > 0) {
                        return from(deleteGroupIds).pipe(
                            map(deleteGroupId => this._groupService.delete(deleteGroupId, process).pipe(
                                    map(() => true)
                                )),
                            zipAll(),
                            map(deleteResult => this.isZipResultSuccess(deleteResult))
                        );
                    } else {
                        return of(true);
                    }
                }),
            ))]
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(zipResults => {
                if (this.isZipResultSuccess(zipResults)) {
                    this._groupService.clearCache();
                    this.selectedGroup = null;
                    this._deletedGroupIds = [];
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process).pipe(
                        tap(() => {
                            this._eventsService.changedAddressBook();
                            this.updateSaveAllActionAndChangeDetect();
                        })
                    );
                } else {
                    return of(zipResults);
                }
            })
        );
    }

    public saveGroupChanges(group: GroupModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if ((group.hasChanges === true || this.isNullOrUndefined(group.searchCriteriaGroups) || group.searchCriteriaGroups.some(scg => scg.searchCriteria.some(sc => sc.hasChanges === true))) && group.isNew === false) {
            return this._groupService.update(group, process).pipe(
                flatMap(() => {
                    if (group.children.length > 0) {
                        return from(group.children).pipe(
                            map(child => this.saveGroupChanges(child, process)),
                            zipAll(),
                            map(result => this.isZipResultSuccess(result))
                        );
                    } else {
                        return of(true);
                    }
                })
            );
        } else if (group.isNew === true) {
            return this._groupService.add(group.parent.id, group.name, process).pipe(
                flatMap(addGroupResult => {
                    group.isNew = false;
                    group.id = addGroupResult;

                    return this._groupService.update(group).pipe(
                        flatMap(() => {
                            if (group.children.length > 0) {
                                return from(group.children).pipe(
                                    map(child => this.saveGroupChanges(child, process)),
                                    zipAll(),
                                    map(result => this.isZipResultSuccess(result))
                                );
                            } else {
                                return of(true);
                            }
                        })
                    );
                })
            );
        } else if (group.children.length > 0) {
            return from(group.children).pipe(
                map(child => this.saveGroupChanges(child, process)),
                zipAll(),
                map(result => this.isZipResultSuccess(result))
            );
        }

        return of(true);
    }

    private openEditGroupDialog(): void {
        if (!this.isNullOrUndefined(this.selectedGroup) && this.isNullOrUndefined(this._editGroupDialogRef)) {
            this._editGroupDialogRef = this._dialog.open(SettingsAddressBookEditDialogComponent, { data: new GroupEditDialogData(this.addressBook, this.selectedGroup, this.filterOnEntriesSettings, this.filterOnEntriesMeta), disableClose: true });

            this._editGroupDialogRef.componentInstance.addCriteria.subscribe(() => this.filterValidChanged());
            this._editGroupDialogRef.componentInstance.filterChanged.subscribe(() => this.filterChanged());
            this._editGroupDialogRef.componentInstance.deleteCriteria.subscribe((event) => this.deleteCriteria(event));
            this._editGroupDialogRef.componentInstance.addCriteria.subscribe((event) => this.addCriteria(event));
            this._editGroupDialogRef.componentInstance.addCriteriaGroup.subscribe((event) => this.addCriteriaGroup(event));
            this._editGroupDialogRef.componentInstance.nameChanged.subscribe(() => this.nameChanged());

            this.addSubscription(this._editGroupDialogRef.afterClosed().subscribe((result: GroupEditDialogResult) => {
                this._editGroupDialogRef = null;
            }));
        }
    }

    private closeEditGroupDialog(): void {
        if (!this.isNullOrUndefined(this._editGroupDialogRef)) {
            this._editGroupDialogRef.close();
        }
    }

    private updateSaveAllActionAndChangeDetect(): void {
        this.updateSaveAllAction(this);
        this._changeDetectorRef.detectChanges();
        if (!this.isNullOrUndefined(this.addressBook)) {
            this.addressBook.detectChanges();
        }
    }
}
