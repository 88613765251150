import { Injectable } from '@angular/core';
import { CanDeleteEndPointModel } from '@em/models/restapi/CanDeleteEndPoint.Model';
import { EndPointModel } from '@em/models/restapi/EndPoint.Model';
import { EndPointCollectionModel } from '@em/models/restapi/EndPointCollection.Model';
import { EndPointTestResponseModel } from '@em/models/restapi/EndPointTestResponse.Model';
import { NotificationCollectionModel } from '@em/models/restapi/NotificationCollection.Model';
import { NotificationRuleModel } from '@em/models/restapi/NotificationRule.Model';
import { ProfileResponseModel } from '@em/models/restapi/ProfileResponse.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiNotificationsService } from '@em/service/restapi/RestApi.Notifications.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';


@Injectable()
export class NotificationService extends EmBaseService {

    private _addEndPointLoadingTracker = new ObservableTracker<EndPointModel>();

    private _addRuleLoadingTracker = new ObservableTracker<NotificationRuleModel>();

    private _canDeleteEndPointLoadingTracker = new ObservableTracker<CanDeleteEndPointModel>();

    private _clearFailureCacheLoadingTracker = new ObservableTracker<null>();

    private _deleteEndPointLoadingTracker = new ObservableTracker<null>();

    private _deleteRuleLoadingTracker = new ObservableTracker<null>();
    private _getEndPointsCache: EndPointCollectionModel;

    private _getEndPointsLoadingTracker = new ObservableTracker<EndPointCollectionModel>();
    private _getNotificationsCache: NotificationCollectionModel;

    private _getNotificationsLoadingTracker = new ObservableTracker<NotificationCollectionModel>();

    private _getProfileLoadingTracker = new ObservableTracker<ProfileResponseModel>();

    private _testEndPointLoadingTracker = new ObservableTracker<EndPointTestResponseModel>();

    private _updateEndPointLoadingTracker = new ObservableTracker<null>();

    private _updateRuleLoadingTracker = new ObservableTracker<null>();

    public constructor(
        private readonly _restApiNotificationsService: RestApiNotificationsService) {
        super();
    }
    public addEndPoint(endPoint: EndPointModel, process?: ProcessMonitorServiceProcess): Observable<EndPointModel> {
        return this._addEndPointLoadingTracker
            .getLoading(endPoint)
            .observable(this._restApiNotificationsService.addEndPoint(endPoint, process).pipe(tap(() => this.clearCache())));
    }
    public addRule(rule: NotificationRuleModel, process?: ProcessMonitorServiceProcess): Observable<NotificationRuleModel> {
        return this._addRuleLoadingTracker
            .getLoading(rule)
            .observable(this._restApiNotificationsService.addRule(rule, process).pipe(tap(() => this.clearCache())));
    }
    public canDeleteEndPoint(endPointId: number, process?: ProcessMonitorServiceProcess): Observable<CanDeleteEndPointModel> {
        return this._canDeleteEndPointLoadingTracker
            .getLoading(endPointId)
            .observable(this._restApiNotificationsService.canDeleteEndPoint(endPointId, process));
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getNotificationsCache = null;
        this._getEndPointsCache = null;
    }

    public clearFailureCache(notificationName: string, endPointId: number, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this._clearFailureCacheLoadingTracker
            .getLoading(notificationName, endPointId)
            .observable(this._restApiNotificationsService.clearFailureCache(notificationName, endPointId, process));
    }

    public deleteEndPoint(endPointId: number, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this._deleteEndPointLoadingTracker
            .getLoading(endPointId)
            .observable(this._restApiNotificationsService.deleteEndPoint(endPointId, process).pipe(tap(() => this.clearCache())));
    }

    public deleteRule(ruleId: number, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this._deleteRuleLoadingTracker
            .getLoading(ruleId)
            .observable(this._restApiNotificationsService.deleteRule(ruleId, process).pipe(tap(() => this.clearCache())));
    }

    public getEndPoints(process?: ProcessMonitorServiceProcess): Observable<EndPointCollectionModel> {
        if (isNullOrUndefined(this._getNotificationsCache)) {
            return this._getEndPointsLoadingTracker
                .getLoading()
                .observable(this._restApiNotificationsService.getEndPoints(process).pipe(
                    map(result => {
                        this._getEndPointsCache = result;
                        return this._getEndPointsCache;
                    })
                ));
        } else {
            return of(this._getEndPointsCache);
        }
    }

    public getNotifications(process?: ProcessMonitorServiceProcess): Observable<NotificationCollectionModel> {
        if (isNullOrUndefined(this._getNotificationsCache)) {
            return this._getNotificationsLoadingTracker
                .getLoading()
                .observable(this._restApiNotificationsService.getNotifications(process).pipe(
                    map(result => {
                        this._getNotificationsCache = result;
                        return this._getNotificationsCache;
                    })
                ));
        } else {
            return of(this._getNotificationsCache);
        }
    }

    public getProfile(notificationName: string, endPointId: number, process?: ProcessMonitorServiceProcess): Observable<ProfileResponseModel> {
        return this._getProfileLoadingTracker
            .getLoading(notificationName, endPointId)
            .observable(this._restApiNotificationsService.getProfile(notificationName, endPointId, process));
    }

    public testEndPoint(endPoint: EndPointModel, process?: ProcessMonitorServiceProcess): Observable<EndPointTestResponseModel> {
        return this._testEndPointLoadingTracker
            .getLoading(endPoint)
            .observable(this._restApiNotificationsService.testEndPoint(endPoint, process));
    }

    public updateEndPoint(endPoint: EndPointModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this._updateEndPointLoadingTracker
            .getLoading(endPoint)
            .observable(this._restApiNotificationsService.updateEndPoint(endPoint, process).pipe(tap(result => this.clearCache())));
    }

    public updateRule(rule: NotificationRuleModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this._updateRuleLoadingTracker
            .getLoading(rule)
            .observable(this._restApiNotificationsService.updateRule(rule, process).pipe(tap(result => this.clearCache())));
    }
}
