import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { GroupCollectionModel } from '@em/models/restapi/GroupCollection.Model';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { RoleModel } from '@em/models/restapi/Role.Model';
import { UpdateUserModel } from '@em/models/restapi/UpdateUser.Model';
import { UserModel } from '@em/models/restapi/User.Model';
import { UserCollectionModel } from '@em/models/restapi/UserCollection.Model';
import { EmRestApiService } from '@em/service/restapi/base/EmRestApi.Service';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';


class AddGroupPermissions extends BaseModel implements IRestModel {
    public group: GroupModel = null;
    public readonly isIRestModel = true;

    public user: UserModel = null;

    public constructor(user: UserModel, group: GroupModel) {
        super();
        this.user = user;
        this.group = group;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, AddGroupPermissions.name);
    }

    public toRestApiModel() {
        return {
            user: this.user.toRestApiModel(),
            group: this.group.toRestApiModel(),
        };
    }
}

class ResetPasswordModel extends BaseModel implements IRestModel {
    public readonly isIRestModel = true;

    public password: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, ResetPasswordModel.name);
    }

    public toRestApiModel(): void {
        throw new Error('Method not implemented.');
    }
}

class ResetApiKeyModel extends BaseModel implements IRestModel {
    public readonly isIRestModel = true;

    public newApiKey: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, ResetApiKeyModel.name);
    }

    public toRestApiModel(): void {
        throw new Error('Method not implemented.');
    }
}

class GetRolesResponse extends BaseModel implements IRestModel {
    public readonly isIRestModel = true;

    public roles: Array<RoleModel> = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        this.roles = RestModelUtility.loadFromArray(restModel.Roles, RoleModel);
    }

    public toRestApiModel(): void {
        throw new Error('Method not implemented.');
    }
}

@Injectable()
export class RestApiUserService extends EmRestApiService {
    private _controller = 'users/';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.emRestApiBase, _httpClient);
    }

    public getUsers(process?: ProcessMonitorServiceProcess): Observable<UserCollectionModel> {
        return this.get<UserCollectionModel>(`${this._controller}getusers`, UserCollectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getRoles(process?: ProcessMonitorServiceProcess): Observable<Array<RoleModel>> {
        return this.get<GetRolesResponse>(`${this._controller}getroles`, GetRolesResponse, null, process).pipe(map(i => i.roles)).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getGroupPermissions(userId: number, process?: ProcessMonitorServiceProcess): Observable<GroupCollectionModel> {
        return this.get<GroupCollectionModel>(`${this._controller}getusergrouppermissions/${StringUtility.toString(userId)}`, GroupCollectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public addGroupPermissions(user: UserModel, group: GroupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<AddGroupPermissions, ResultModel>(`${this._controller}addusergrouppermissions`, new AddGroupPermissions(user, group), ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteGroupPermissions(user: UserModel, group: GroupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<AddGroupPermissions, ResultModel>(`${this._controller}deleteusergrouppermissions`, new AddGroupPermissions(user, group), ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public resetPassword(userName: string, process?: ProcessMonitorServiceProcess): Observable<string> {
        return this.get<ResetPasswordModel>(`${this._controller}resetpassword/${userName}`, ResetPasswordModel, null, process).pipe(map(i => i.password)).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public resetApiKey(userName: string, process?: ProcessMonitorServiceProcess): Observable<string> {
        return this.get<ResetApiKeyModel>(`${this._controller}resetapikey/${userName}`, ResetApiKeyModel, null, process).pipe(map(i => i.newApiKey)).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public unlockAccount(userName: string, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.get<boolean>(`${this._controller}unlock/${userName}`, 'boolean', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setEnabledState(userName: string, enabled: boolean, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        let params = new HttpParams();
        params = params.append('username', userName);
        params = params.append('enabled', isNullOrUndefined(enabled) ? 'true' : StringUtility.toString(enabled));

        return this.get<ResultModel>(`${this._controller}setuserenabledstate`, ResultModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteUser(userName: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.get<ResultModel>(`${this._controller}deleteuser/${userName}`, ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateOrAdd(user: UserModel, process?: ProcessMonitorServiceProcess): Observable<UpdateUserModel> {
        return this.post<UserModel, UpdateUserModel>(`${this._controller}edituser`, user, UpdateUserModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
