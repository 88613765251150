import { Injectable } from '@angular/core';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { LineCollectionModel } from '@rift/models/restapi/LineCollection.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiLinesService } from '@rift/service/restapi/v1/RestApi.Lines.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class LineService extends RiftBaseService {

    private _getLinesLoadingTracker = new ObservableTracker<LineCollectionModel>();
    private _linesCache: LineCollectionModel = null;

    private _updateLineLoadingTracker = new ObservableTracker<ResultModel>();

    private _updateLinesLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiLinesService: RestApiLinesService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._linesCache = null;
    }

    public getLines(process?: ProcessMonitorServiceProcess): Observable<LineCollectionModel> {
        if (isNullOrUndefined(this._linesCache)) {
            return this._getLinesLoadingTracker
                .getLoading()
                .observable(this._restApiLinesService.getLines(process).pipe(
                    map(result => {
                        this._linesCache = result;
                        return this._linesCache;
                    })
                ));
        } else {
            return of(this._linesCache);
        }
    }

    public setCache(linesCache: LineCollectionModel): Observable<boolean> {
        this._linesCache = linesCache;
        return of(true);
    }

    public updateLine(line: LineModel, id: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateLineLoadingTracker
            .getLoading(line, id)
            .observable(this._restApiLinesService.updateLine(line, id, process).pipe(
                map(result => {
                    this._linesCache = null;
                    return result;
                })
            ));
    }

    public updateLines(lines: Array<LineModel>, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateLinesLoadingTracker
            .getLoading(lines)
            .observable(this._restApiLinesService.updateLines(lines, process).pipe(tap(() => this._linesCache = null)));
    }
}
