<div mat-dialog-title>
    <b>Delete Device Data</b>
</div>
<div mat-dialog-content
    style="max-height: 455px;">
    <div fxLayout="column">
        <div>
            If a device SD card has been formatted or replaced Estate Manager can become out of sync with the data on the device.
            <br>
            <br>
            By selecting any of the options below it is possible to remove the data that Estate Manager holds for this device
            <br>
            so it can be collected again and Estate Manager can be re-synced with the device.
            <br>
            <br>
            <b>Warning</b>: Once this operation has been started it can not be stopped, data will be removed from Estate Manager and can not 
            <br>
            be recovered. Make sure you have extracted whatever data you wish to keep before performing this operation.
            <br>
            <br>
            This operation can take some time to run, if Estate Manager holds a lot of data for this device it could take several hours to complete.
            <br>
            <br>
            <b>Select which data to remove:</b>
        </div>
        <div fxLayout="row">
            <div class="checkbox">
                <mat-checkbox [(ngModel)]="deleteLiveDataState.countLogs" [disabled]="initialState.countLogs === true">Count Logs</mat-checkbox>                
            </div>
            <div class="checkbox">
                <mat-checkbox [(ngModel)]="deleteLiveDataState.histogramLogs" [disabled]="initialState.histogramLogs === true">Histogram Logs</mat-checkbox>
            </div>
            <div class="checkbox">
                <mat-checkbox [(ngModel)]="deleteLiveDataState.diagnosticLogs" [disabled]="initialState.diagnosticLogs === true">Diagnostics Logs</mat-checkbox>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button (click)="onCancelClicked()"
        mat-raised-button>Close</button>
    <div fxFlex></div>
    <button
        mat-raised-button
        color="primary"
        (click)="onSaveClicked()" [disabled]="hasChanges === false">Save</button>
</div>