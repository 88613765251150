import { Component, Inject, Injector, OnInit, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoleModel } from '@em/models/restapi/Role.Model';
import { UserModel } from '@em/models/restapi/User.Model';
import { UserService } from '@em/service/data/user/User.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { OkCancelDialogComponent, OkCancelDialogData } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthTypeEnum } from '@shared/enum/AuthType.Enum';

export class SettingsManageUsersAddData {
    public static className: string = 'SettingsManageUsersAddData';
    public emails: Array<string>;

    public roles: Array<RoleModel>;
    public authTypes: Array<AuthTypeEnum>;
}

export class SettingsManageUsersAddResult {
    public static className: string = 'SettingsManageUsersAddResult';

    public user: UserModel;
    public ok: boolean = false;

    public constructor(ok: boolean, user?: UserModel) {
        this.ok = ok;
        this.user = user;
    }
}

@Component({
    selector: 'em-settings-manage-users-add',
    templateUrl: './Settings.ManageUsers.Add.Component.html',
    styleUrls: ['./Settings.ManageUsers.Add.Component.scss']
})
export class SettingsManageUsersAddComponent extends BaseComponent {
    public static className: string = 'SettingsManageUsersAddComponent';

    public addProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'em-settings-manage-users-add';

    public addUserForm: FormGroup;
    public user: UserModel = new UserModel();
    public roles: Array<RoleModel>;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _userService: UserService,
        private readonly _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private readonly _data: SettingsManageUsersAddData,
        private readonly _dialogRef: MatDialogRef<SettingsManageUsersAddComponent>,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.addProcess = this.processMonitorService.getProcess(SettingsManageUsersAddComponent.className, 'Adding user.');

        this.roles = this._data.roles;

        this.addUserForm = this._formBuilder.group({
            userName: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
            realName: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
            jobTitle: ['', Validators.compose([Validators.maxLength(254)])],
            organisation: ['', Validators.compose([Validators.maxLength(254)])],
            email: ['', Validators.compose([Validators.required, ValidationValidators.emailAddress, Validators.maxLength(254), ValidationValidators.unique((() => this._data.emails).bind(this))])],
            userType: ['', Validators.compose([Validators.required])],
        });

        this.setFormValues(this.user);
    }

    public add(): void {
        this.updateModel(this.user);
        this.addSubscription(this.observableHandlerBase(this._userService.updateOrAdd(this.user, this.addProcess), this.addProcess).subscribe(
            result => {
                if (!this.isNullOrUndefined(result.user)){
                    this.user.userId = result.user.userId;
                    this.user.authType = result.user.authType;

                    if (this.user.authType === AuthTypeEnum.system){
                        this.user.webAPIKey = result.user.webAPIKey;

                        const dialogData = new OkCancelDialogData('User Password', null);
                        dialogData.messageHtml = `Their password, including spaces, is: <br> <b>${result.password}</b> <br> <br>Please provide the password to the user.`;
                        dialogData.showCancel = false;
                        dialogData.okText = 'Close';

                        const dialogRef = this._dialog.open(OkCancelDialogComponent, { data: dialogData, disableClose: true });
                        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.addProcess).subscribe(() => {
                            this._dialogRef.close(new SettingsManageUsersAddResult(true, this.user));
                        }));
                    }
                    else{
                        const dialogData = new OkCancelDialogData('User Added', null);
                        dialogData.messageHtml = `User can login via their SSO provider`;
                        dialogData.showCancel = false;
                        dialogData.okText = 'Close';

                        const dialogRef = this._dialog.open(OkCancelDialogComponent, { data: dialogData, disableClose: true });
                        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.addProcess).subscribe(() => {
                            this._dialogRef.close(new SettingsManageUsersAddResult(true, this.user));
                        }));
                    }
                }
                else{
                    const dialogData = new OkCancelDialogData('Add user error', null);
                    dialogData.messageHtml = `Failed to add user`;
                    dialogData.showCancel = false;
                    dialogData.okText = 'Close';

                    const dialogRef = this._dialog.open(OkCancelDialogComponent, { data: dialogData, disableClose: true });
                    this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.addProcess).subscribe(() => {
                        this._dialogRef.close(new SettingsManageUsersAddResult(false, null));
                    }));
                }
            }
        ), this.addProcess);
    }

    public cancel(): void {
        this.setFormValues(this.user);
        this._dialogRef.close(new SettingsManageUsersAddResult(false));
    }

    private setFormValues(user: UserModel): void {
        this.addUserForm.setValue({
            userName: this.isNullOrUndefined(user.username) ? '' : user.username,
            realName: this.isNullOrUndefined(user.realName) ? '' : user.realName,
            jobTitle: this.isNullOrUndefined(user.jobTitle) ? '' : user.jobTitle,
            organisation: this.isNullOrUndefined(user.organisation) ? '' : user.organisation,
            email: this.isNullOrUndefined(user.email) ? '' : user.email,
            userType: this.isNullOrUndefined(user.roles) ? '' : this.user.roles.length === 0 ? '' : this.user.roles[0],
        });
    }

    private updateModel(user: UserModel): void {
        const formModel = this.addUserForm.value;

        user.username = (formModel.userName as string).trim();
        user.realName = formModel.realName;
        user.jobTitle = formModel.jobTitle;
        user.organisation = formModel.organisation;
        user.email = formModel.email;
        user.roles = [formModel.userType];
    }
}
