import { Component, HostBinding, Injector, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ISectionChange } from '@rift/service/configjsoncompare/ConfigJsonCompare.Service';
import { ConfigHistoryBaseComponent } from '@rift/components/settings/confighistory/ConfigHistory.Base.Component';

@Component({
    selector: 'rift-config-history-section',
    templateUrl: './ConfigHistory.Section.Component.html',
})
export class ConfigHistorySectionComponent extends ConfigHistoryBaseComponent {

    @HostBinding()
    public id: string = 'rift-config-history-section';

    @Input()
    public sectionChange: ISectionChange;

    public constructor(
        private readonly _sanitizer: DomSanitizer,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_sanitizer, _injector, _dialog);
    }
}
