import { RegisterBaseModel, REGISTER_BASE_EXCLUDE_PROPERTIES } from '@rift/models/restapi/RegisterBase.Model';
import { SummationRegisterModel, ISummationRegisterModel } from '@rift/models/restapi/SummationRegister.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterSummationModel extends IRegisterBaseModel {
    registers: Array<ISummationRegisterModel>;
}

export class RegisterSummationModel extends RegisterBaseModel implements IRegisterSummationModel, IRestModel {

    public registers: RestModelChangeTrackerArray<SummationRegisterModel> = new RestModelChangeTrackerArray<SummationRegisterModel>();

    public constructor() {
        super();

        this.registerChangeTrackerArray('registers');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterSummationModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.registers = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.Registers, SummationRegisterModel);

        this.setPropertyOriginalValue('registers', this.registers);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
            Registers: RestModelUtility.toJsonArray(this.registers),
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], isNullOrUndefined(this.registers) || this.registers.length === 0 ? [] : this.registers.map(i => i.register), []);
    }
}
