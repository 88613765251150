import { Injectable } from '@angular/core';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { PageModel } from '@em/models/restapi/Page.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { TaskDescriptionModel } from '@em/models/restapi/TaskDescription.Model';
import { TaskToExecuteModel } from '@em/models/restapi/TaskToExecute.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiTaskService } from '@em/service/restapi/RestApi.Task.Service';
import { TaskToExecuteStateEnum } from '@shared/enum/TaskToExecuteState.Enum';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';


@Injectable()
export class TaskService extends EmBaseService {

    private _addTaskForDeviceLoadingTracker = new ObservableTracker<boolean>();
    private _addTaskForGroupLoadingTracker = new ObservableTracker<boolean>();
    private _deleteTaskLoadingTracker = new ObservableTracker<boolean>();
    private _editTaskLoadingTracker = new ObservableTracker<boolean>();
    private _getTaskPackageListCache: Array<TaskDescriptionModel>;
    private _getTaskPackageListLoadingTracker = new ObservableTracker<Array<TaskDescriptionModel>>();
    private _getTaskPageLoadingTracker = new ObservableTracker<PageModel<TaskToExecuteModel>>();
    private _getTaskResultLogLoadingTracker = new ObservableTracker<string>();
    private _setModuleConfigLoadingTracker = new ObservableTracker<boolean>();
    private _deleteTaskPackageTracker = new ObservableTracker<boolean>();

    public constructor(
        private readonly _restApiTaskService: RestApiTaskService) {
        super();
    }

    public addTaskForAllDevices(task: TaskToExecuteModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._setModuleConfigLoadingTracker
            .getLoading(task)
            .observable(this._restApiTaskService.addTaskForAllDevices(task, process));
    }

    public addTaskForDevice(task: TaskToExecuteModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._addTaskForDeviceLoadingTracker
            .getLoading(task)
            .observable(this._restApiTaskService.addTaskForDevice(task, process));
    }

    public addTaskForGroup(task: TaskToExecuteModel, group: GroupModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._addTaskForGroupLoadingTracker
            .getLoading(task)
            .observable(this._restApiTaskService.addTaskForGroup(task, group, process));
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getTaskPackageListCache = null;
    }

    public deleteTask(task: TaskToExecuteModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._deleteTaskLoadingTracker
            .getLoading(task)
            .observable(this._restApiTaskService.deleteTask(task, process));
    }

    public editTask(task: TaskToExecuteModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._editTaskLoadingTracker
            .getLoading(task)
            .observable(this._restApiTaskService.editTask(task, process));
    }

    public getTaskPackageList(process?: ProcessMonitorServiceProcess): Observable<Array<TaskDescriptionModel>> {
        if (isNullOrUndefined(this._getTaskPackageListCache)) {
            return this._getTaskPackageListLoadingTracker
                .getLoading()
                .observable(this._restApiTaskService.getTaskPackageList().pipe(
                    map(result => {
                        this._getTaskPackageListCache = result;
                        return this._getTaskPackageListCache;
                    })
                ));
        } else {
            return of(this._getTaskPackageListCache);
        }
    }

    public deleteTaskPackage(taskPackage: TaskDescriptionModel, process: ProcessMonitorServiceProcess): Observable<boolean>{
        return this._deleteTaskPackageTracker
            .getLoading(taskPackage)
            .observable(this._restApiTaskService.deleteTaskPackage(taskPackage, process));
    }

    public getTaskCounts(process?: ProcessMonitorServiceProcess): Observable<{ state: TaskToExecuteStateEnum; count: number }[]> {
        const page = new PaginationOptionsModel();
        page.resultsPerPage = 1;

        return zip(
            this.getTaskPage(TaskToExecuteStateEnum.disconnected, page, process),
            this.getTaskPage(TaskToExecuteStateEnum.executing, page, process),
            this.getTaskPage(TaskToExecuteStateEnum.failedExecution, page, process),
            this.getTaskPage(TaskToExecuteStateEnum.failedExecutionPaused, page, process),
            this.getTaskPage(TaskToExecuteStateEnum.notReadyToExecute, page, process),
            this.getTaskPage(TaskToExecuteStateEnum.passedExecution, page, process),
            this.getTaskPage(TaskToExecuteStateEnum.readyToExecute, page, process),
        ).pipe(
            map(results => {
                const counts: { state: TaskToExecuteStateEnum; count: number }[] = [];

                counts.push({ state: TaskToExecuteStateEnum.disconnected, count: results[0].options.totalResults });
                counts.push({ state: TaskToExecuteStateEnum.executing, count: results[1].options.totalResults });
                counts.push({ state: TaskToExecuteStateEnum.failedExecution, count: results[2].options.totalResults });
                counts.push({ state: TaskToExecuteStateEnum.failedExecutionPaused, count: results[3].options.totalResults });
                counts.push({ state: TaskToExecuteStateEnum.notReadyToExecute, count: results[4].options.totalResults });
                counts.push({ state: TaskToExecuteStateEnum.passedExecution, count: results[5].options.totalResults });
                counts.push({ state: TaskToExecuteStateEnum.readyToExecute, count: results[6].options.totalResults });

                return counts;
            })
        );
    }

    public getTaskPage(state: TaskToExecuteStateEnum, paginationOptions: PaginationOptionsModel, process?: ProcessMonitorServiceProcess): Observable<PageModel<TaskToExecuteModel>> {
        return this._getTaskPageLoadingTracker
            .getLoading(state, paginationOptions)
            .observable(this._restApiTaskService.getTaskPage(state, paginationOptions, process));
    }

    public getTaskResultLog(taskId: number, resultId: number, process?: ProcessMonitorServiceProcess): Observable<string> {
        return this._getTaskResultLogLoadingTracker
            .getLoading(taskId, resultId)
            .observable(this._restApiTaskService.getTaskResultLog(taskId, resultId, process));
    }
}
