import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';
import { IPStatusEnum } from '@shared/enum/IPStatus.Enum';

export class DeviceTraceLocationModel extends BaseModel implements IRestModel {
    public errorMessage: string = null;

    public hostName: string = null;
    public iPAddress: string = null;
    public iPStatus: IPStatusEnum = null;
    public latitude: number = null;
    public longitude: number = null;
    public readonly isIRestModel = true;
    public roundtripTime: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-DeviceTraceLocationModel');
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
