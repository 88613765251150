import { Component, HostListener, Injector, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeyValuePairModel } from '@em/models/restapi/KeyValuePair.Model';
import { SettingService } from '@em/service/data/setting/Setting.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { SelectOption } from '@shared/generic/SelectOption';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { BooleanUtility } from '@shared/utility/Boolean.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'em-settings-options-data-culling',
    templateUrl: './Settings.Options.DataCulling.Component.html',
    styleUrls: ['./Settings.Options.DataCulling.Component.scss']
})
export class SettingsOptionsDataCullingComponent extends BaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsOptionsDataCullingComponent';
    public bluetoothLogsCullingPeriods: Array<SelectOption<number>> = [
        new SelectOption<number>(31, '1 month'),
        new SelectOption<number>(31 * 3, '3 months'),
        new SelectOption<number>(31 * 6, '6 months'),
        new SelectOption<number>(31 * 12, '12 months'),
        new SelectOption<number>(31 * 24, '24 months'),
        new SelectOption<number>(0, 'ever')
    ];
    public countLogsCullingPeriods: Array<SelectOption<number>> = [
        new SelectOption<number>(31, '1 month'),
        new SelectOption<number>(31 * 3, '3 months'),
        new SelectOption<number>(31 * 6, '6 months'),
        new SelectOption<number>(31 * 12, '12 months'),
        new SelectOption<number>(31 * 24, '24 months'),
        new SelectOption<number>(0, 'ever')
    ];
    public dataCullingFormGroup: FormGroup;
    public disconnectedDevicesCullingPeriods: Array<SelectOption<number>> = [
        new SelectOption<number>(31, '1 month'),
        new SelectOption<number>(31 * 3, '3 months'),
        new SelectOption<number>(31 * 6, '6 months'),
        new SelectOption<number>(31 * 12, '12 months'),
        new SelectOption<number>(31 * 24, '24 months'),
        new SelectOption<number>(0, 'ever')
    ];
    public histogramLogsCullingPeriods: Array<SelectOption<number>> = [
        new SelectOption<number>(31, '1 month'),
        new SelectOption<number>(31 * 3, '3 months'),
        new SelectOption<number>(31 * 6, '6 months'),
        new SelectOption<number>(31 * 12, '12 months'),
        new SelectOption<number>(31 * 24, '24 months'),
        new SelectOption<number>(0, 'ever')
    ];

    @HostBinding()
    public id: string = 'em-settings-options-data-culling';

    public scheduleResultsCullingPeriods: Array<SelectOption<number>> = [
        new SelectOption<number>(7, '1 week'),
        new SelectOption<number>(14, '2 weeks'),
        new SelectOption<number>(21, '3 weeks'),
        new SelectOption<number>(30, '1 month')
    ];

    public settingBluetoothLogsCullingPeriod: KeyValuePairModel;
    public settingCountLogsCullingPeriod: KeyValuePairModel;
    public settingDisconnectedDevicesCullingPeriod: KeyValuePairModel;
    public settingHistogramLogsCullingPeriod: KeyValuePairModel;
    public settingScheduleResultsCullingPeriod: KeyValuePairModel;
    public settingSynchronizedVideosKeepGlobalBookmarks: KeyValuePairModel;
    public settingSynchronizedVideosKeepValidationSessions: KeyValuePairModel;
    public settingSynchronizedVideosLessThan: KeyValuePairModel;
    public settingSynchronizedVideosOlderThan: KeyValuePairModel;
    public settingSynchronizedVideosWhen: KeyValuePairModel;
    public synchronizedVideosCullingPeriods: Array<SelectOption<number>> = [
        new SelectOption<number>(31, '1 month'),
        new SelectOption<number>(31 * 3, '3 months'),
        new SelectOption<number>(31 * 6, '6 months'),
        new SelectOption<number>(31 * 12, '12 months'),
        new SelectOption<number>(31 * 24, '24 months')
    ];
    public synchronizedVideosDiskOptions: Array<SelectOption<number>> = [
        new SelectOption<number>(10, '10%'),
        new SelectOption<number>(20, '20%'),
        new SelectOption<number>(30, '30%'),
    ];

    public synchronizedVideosWhenOptions: Array<SelectOption<string>> = [
        new SelectOption<string>('duration', 'duration'),
        new SelectOption<string>('diskspace', 'disk space'),
        new SelectOption<string>('never', 'never'),
    ];
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _formBuilder: FormBuilder,
        private readonly _settingService: SettingService,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsOptionsDataCullingComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsOptionsDataCullingComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsOptionsDataCullingComponent.className, this.saveAllChangesProcessText);

        this.addSaveAllAction(this);

        this.dataCullingFormGroup = this._formBuilder.group({
            scheduleResultsCullingPeriod: ['', Validators.compose([Validators.required])],
            countLogsCullingPeriod: ['', Validators.compose([Validators.required])],
            histogramLogsCullingPeriod: ['', Validators.compose([Validators.required])],
            bluetoothLogsCullingPeriod: ['', Validators.compose([Validators.required])],
            disconnectedDevicesCullingPeriod: ['', Validators.compose([Validators.required])],
            synchronizedVideosKeepValidationSessions: ['', Validators.compose([])],
            synchronizedVideosKeepGlobalBookmarks: ['', Validators.compose([])],
            synchronizedVideosWhenOption: ['', Validators.compose([Validators.required])],
            synchronizedVideosCullingPeriod: ['', Validators.compose([])],
            synchronizedVideosDiskOption: ['', Validators.compose([])],
        });
        this.formGroupTracker.track(this.dataCullingFormGroup);

        this.addSubscription(this.observableHandlerBase(this.dataCullingFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.onFormGroupValueChanges()), this.formValuesChangeProcess);
        this.addSubscription(this.observableHandlerBase(this.dataCullingFormGroup.controls.synchronizedVideosWhenOption.valueChanges, this.formValuesChangeProcess).subscribe(() => this.onSynchronizedVideosWhenOptionChanges()), this.formValuesChangeProcess);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._settingService.getSetting('CullingPeriod', 'ScheduleResults', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingScheduleResultsCullingPeriod = result;
                        this.changeTracker.track(result);
                        this.dataCullingFormGroup.controls.scheduleResultsCullingPeriod.setValue(parseInt(result.value, 10), { emitEvent: false });
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('CullingPeriod', 'CountLogs', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingCountLogsCullingPeriod = result;
                        this.changeTracker.track(result);
                        this.dataCullingFormGroup.controls.countLogsCullingPeriod.setValue(parseInt(result.value, 10), { emitEvent: false });
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('CullingPeriod', 'HistogramLogs', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingHistogramLogsCullingPeriod = result;
                        this.changeTracker.track(result);
                        this.dataCullingFormGroup.controls.histogramLogsCullingPeriod.setValue(parseInt(result.value, 10), { emitEvent: false });
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('CullingPeriod', 'BluetoothLogs', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingBluetoothLogsCullingPeriod = result;
                        this.changeTracker.track(result);
                        this.dataCullingFormGroup.controls.bluetoothLogsCullingPeriod.setValue(parseInt(result.value, 10), { emitEvent: false });
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('CullingPeriod', 'DisconnectedDevices', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingDisconnectedDevicesCullingPeriod = result;
                        this.changeTracker.track(result);
                        this.dataCullingFormGroup.controls.disconnectedDevicesCullingPeriod.setValue(parseInt(result.value, 10), { emitEvent: false });
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('When', 'SynchronizedVideos', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingSynchronizedVideosWhen = result;
                        this.changeTracker.track(result);
                        this.dataCullingFormGroup.controls.synchronizedVideosWhenOption.setValue(result.value, { emitEvent: false });
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('OlderThan', 'SynchronizedVideos', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingSynchronizedVideosOlderThan = result;
                        this.changeTracker.track(result);
                        this.dataCullingFormGroup.controls.synchronizedVideosCullingPeriod.setValue(parseInt(result.value, 10), { emitEvent: false });
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('LessThan', 'SynchronizedVideos', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingSynchronizedVideosLessThan = result;
                        this.changeTracker.track(result);
                        this.dataCullingFormGroup.controls.synchronizedVideosDiskOption.setValue(parseInt(result.value, 10), { emitEvent: false });
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('KeepValidationSessions', 'SynchronizedVideos', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingSynchronizedVideosKeepValidationSessions = result;
                        this.changeTracker.track(result);
                        this.dataCullingFormGroup.controls.synchronizedVideosKeepValidationSessions.setValue(BooleanUtility.toBoolean(result.value), { emitEvent: false });
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('KeepGlobalBookmarks', 'SynchronizedVideos', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingSynchronizedVideosKeepGlobalBookmarks = result;
                        this.changeTracker.track(result);
                        this.dataCullingFormGroup.controls.synchronizedVideosKeepGlobalBookmarks.setValue(BooleanUtility.toBoolean(result.value), { emitEvent: false });
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.settingScheduleResultsCullingPeriod.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('CullingPeriod', 'ScheduleResults', this.settingScheduleResultsCullingPeriod.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingCountLogsCullingPeriod.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('CullingPeriod', 'CountLogs', this.settingCountLogsCullingPeriod.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingHistogramLogsCullingPeriod.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('CullingPeriod', 'HistogramLogs', this.settingHistogramLogsCullingPeriod.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingBluetoothLogsCullingPeriod.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('CullingPeriod', 'BluetoothLogs', this.settingBluetoothLogsCullingPeriod.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingDisconnectedDevicesCullingPeriod.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('CullingPeriod', 'DisconnectedDevices', this.settingDisconnectedDevicesCullingPeriod.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingSynchronizedVideosWhen.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('When', 'SynchronizedVideos', this.settingSynchronizedVideosWhen.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingSynchronizedVideosOlderThan.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('OlderThan', 'SynchronizedVideos', this.settingSynchronizedVideosOlderThan.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingSynchronizedVideosLessThan.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('LessThan', 'SynchronizedVideos', this.settingSynchronizedVideosLessThan.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingSynchronizedVideosKeepValidationSessions.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('KeepValidationSessions', 'SynchronizedVideos', this.settingSynchronizedVideosKeepValidationSessions.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingSynchronizedVideosKeepGlobalBookmarks.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('KeepGlobalBookmarks', 'SynchronizedVideos', this.settingSynchronizedVideosKeepGlobalBookmarks.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(result);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._settingService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    private onFormGroupValueChanges(): void {
        this.updateModels();
        this.updateSaveAllAction(this);
    }

    private onSynchronizedVideosWhenOptionChanges(): void {
        const value = this.dataCullingFormGroup.controls.synchronizedVideosWhenOption.value as string;
        switch (value) {
            case 'duration':
                this.dataCullingFormGroup.controls.synchronizedVideosCullingPeriod.setValidators(Validators.compose([Validators.required]));
                this.dataCullingFormGroup.controls.synchronizedVideosDiskOption.setValidators(Validators.compose([]));
                break;
            case 'diskspace':
                this.dataCullingFormGroup.controls.synchronizedVideosCullingPeriod.setValidators(Validators.compose([]));
                this.dataCullingFormGroup.controls.synchronizedVideosDiskOption.setValidators(Validators.compose([Validators.required]));
                break;
            case 'never':
                this.dataCullingFormGroup.controls.synchronizedVideosCullingPeriod.setValidators(Validators.compose([]));
                this.dataCullingFormGroup.controls.synchronizedVideosDiskOption.setValidators(Validators.compose([]));
                break;
        }
    }

    private updateModels(): void {
        const formModel = this.dataCullingFormGroup.value;

        if (!this.isNullOrUndefined(this.settingScheduleResultsCullingPeriod)) {
            this.settingScheduleResultsCullingPeriod.value = StringUtility.toString(formModel.scheduleResultsCullingPeriod);
        }
        if (!this.isNullOrUndefined(this.settingCountLogsCullingPeriod)) {
            this.settingCountLogsCullingPeriod.value = StringUtility.toString(formModel.countLogsCullingPeriod);
        }
        if (!this.isNullOrUndefined(this.settingHistogramLogsCullingPeriod)) {
            this.settingHistogramLogsCullingPeriod.value = StringUtility.toString(formModel.histogramLogsCullingPeriod);
        }
        if (!this.isNullOrUndefined(this.settingBluetoothLogsCullingPeriod)) {
            this.settingBluetoothLogsCullingPeriod.value = StringUtility.toString(formModel.bluetoothLogsCullingPeriod);
        }
        if (!this.isNullOrUndefined(this.settingDisconnectedDevicesCullingPeriod)) {
            this.settingDisconnectedDevicesCullingPeriod.value = StringUtility.toString(formModel.disconnectedDevicesCullingPeriod);
        }

        if (!this.isNullOrUndefined(this.settingSynchronizedVideosKeepValidationSessions)) {
            this.settingSynchronizedVideosKeepValidationSessions.value = StringUtility.toString(formModel.synchronizedVideosKeepValidationSessions);
        }
        if (!this.isNullOrUndefined(this.settingSynchronizedVideosKeepGlobalBookmarks)) {
            this.settingSynchronizedVideosKeepGlobalBookmarks.value = StringUtility.toString(formModel.synchronizedVideosKeepGlobalBookmarks);
        }
        if (!this.isNullOrUndefined(this.settingSynchronizedVideosWhen)) {
            this.settingSynchronizedVideosWhen.value = StringUtility.toString(formModel.synchronizedVideosWhenOption);
        }
        if (!this.isNullOrUndefined(this.settingSynchronizedVideosLessThan)) {
            this.settingSynchronizedVideosLessThan.value = StringUtility.toString(formModel.synchronizedVideosDiskOption);
        }
        if (!this.isNullOrUndefined(this.settingSynchronizedVideosOlderThan)) {
            this.settingSynchronizedVideosOlderThan.value = StringUtility.toString(formModel.synchronizedVideosCullingPeriod);
        }
    }
}
