<div class="em-device-list-select-columns">
    <mat-list>
        <mat-list-item>Select Visible Columns</mat-list-item>
        <mat-divider></mat-divider>
    </mat-list>
    <mat-selection-list #columnsSelect
        (selectionChange)="onSelectionChange($event)">
        <mat-list-option [value]="column"
            [selected]="column.selected"
            *ngFor="let column of columns">
            {{column.text}}
        </mat-list-option>
    </mat-selection-list>
    <mat-list>
        <mat-list-item>
            <button (click)="save()"
                mat-raised-button
                color="primary">Save</button>
            <div fxFlex></div>
            <button (click)="cancel()"
                mat-raised-button
                color="warn">Cancel</button>
        </mat-list-item>
    </mat-list>
</div>