import { Injectable } from '@angular/core';
import { GlobalHeightFilterModel } from '@rift/models/restapi/GlobalHeightFilter.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiHeightService } from '@rift/service/restapi/v1/RestApi.Height.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HeightService extends RiftBaseService {

    private _getGlobalHeightFilterLoadingTracker = new ObservableTracker<GlobalHeightFilterModel>();
    private _globalHeightFilterCache: GlobalHeightFilterModel = null;

    private _updateGlobalHeightFilterLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _RestApiHeightService: RestApiHeightService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._globalHeightFilterCache = null;
    }

    public getGlobalHeightFilter(process?: ProcessMonitorServiceProcess): Observable<GlobalHeightFilterModel> {
        if (isNullOrUndefined(this._globalHeightFilterCache)) {
            return this._getGlobalHeightFilterLoadingTracker
                .getLoading()
                .observable(this._RestApiHeightService.getGlobalHeightFilter(process).pipe(
                    map(result => {
                        this._globalHeightFilterCache = result;
                        return this._globalHeightFilterCache;
                    })
                ));
        } else {
            return of(this._globalHeightFilterCache);
        }
    }

    public setCache(globalHeightFilterCache: GlobalHeightFilterModel): Observable<boolean> {
        this._globalHeightFilterCache = globalHeightFilterCache;
        return of(true);
    }

    public updateGlobalHeightFilter(global: GlobalHeightFilterModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateGlobalHeightFilterLoadingTracker
            .getLoading(global)
            .observable(this._RestApiHeightService.updateGlobalHeightFilter(global, process).pipe(
                map(result => {
                    this._globalHeightFilterCache = null;
                    return result;
                })
            ));
    }
}
