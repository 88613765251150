import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { SyncedVideoSessionModel } from '@rift/models/restapi/SyncedVideoSession.Model';
import { VideoScheduleModel } from '@rift/models/restapi/VideoSchedule.Model';
import { VideoSessionModel } from '@rift/models/restapi/VideoSession.Model';
import { ScheduleTypeEnum } from '@shared/enum/ScheduleType.Enum';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, Subscription } from 'rxjs';

export type SessionTypes = 'onDevice' | 'schedule' | 'local';

export abstract class VideoViewModelState {
    protected _isActive: boolean = false;
    protected _isQueued: boolean = false;
    protected _isPaused: boolean = false;
    protected _isProcessing: boolean = false;
    protected _isComplete: boolean = false;
    protected _progress: number = 0;
    protected _item: VideoScheduleModel | VideoSessionModel | SyncedVideoSessionModel;

    public constructor(public device: DeviceModel) {

    }

    public get isActive(): boolean {
        return this._isActive;
    }
    public set isActive(value: boolean) {
        if (this._isActive !== value) {
            this._isActive = value;
        }
    }

    public get isQueued(): boolean {
        return this._isQueued;
    }
    public set isQueued(value: boolean) {
        if (this._isQueued !== value) {
            this._isQueued = value;
        }
    }

    public get isPaused(): boolean {
        return this._isPaused;
    }
    public set isPaused(value: boolean) {
        if (this._isPaused !== value) {
            this._isPaused = value;
        }
    }

    public get isProcessing(): boolean {
        return this._isProcessing;
    }
    public set isProcessing(value: boolean) {
        if (this._isProcessing !== value) {
            this._isProcessing = value;
        }
    }

    public get progress(): number {
        return this._progress;
    }
    public set progress(value: number) {
        if (this._progress !== value) {
            this._progress = value;
        }
    }

    public get isComplete(): boolean {
        return this._isComplete;
    }
    public set isComplete(value: boolean) {
        if (this._isComplete !== value) {
            this._isComplete = value;
        }
    }

    public get startTime(): Date {
        return !isNullOrUndefined(this._item) && !isNullOrUndefined(this._item.startTime) ? this._item.startTime : null;
    }

    public get endTime(): Date {
        return !isNullOrUndefined(this._item) && !isNullOrUndefined(this._item.endTime) ? this._item.endTime : null;
    }

    public get groupRecordingIdentifier(): string{
        return !isNullOrUndefined(this._item) && !isNullOrUndefined(this._item.groupRecordingIdentifier) ? this._item.groupRecordingIdentifier : null;
    }
}

export class ScheduleViewModel extends VideoViewModelState {
    public constructor(public videoSchedule: VideoScheduleModel, public device: DeviceModel) {
        super(device);
        this._item = videoSchedule;
    }

    public get item(): VideoScheduleModel {
        return this._item as VideoScheduleModel;
    }
    public set item(value: VideoScheduleModel) {
        this._item = value;
    }
}

export class OnDeviceViewModel extends VideoViewModelState {
    protected _isNew: boolean = false;

    public constructor(public videoSession: VideoSessionModel, public device: DeviceModel) {
        super(device);
        this._item = videoSession;
        if(!isNullOrUndefined(this._item)){
            this._isNew = ((new Date()).valueOf() - this.item.endTime.valueOf()) < 2592000000;
        }
        else{
            this._isNew = false;
        }
    }

    public get isNew(): boolean {
        return this._isNew;
    }

    public get item(): VideoSessionModel {
        return this._item as VideoSessionModel;
    }
    public set item(value: VideoSessionModel) {
        this._item = value;
    }
}

export class LocalViewModel extends VideoViewModelState {
    protected _isNew: boolean = false;

    public constructor(public syncedVideoSession: SyncedVideoSessionModel, public device: DeviceModel) {
        super(device);
        this._item = syncedVideoSession;
        if(!isNullOrUndefined(this._item)){
            this._isNew = ((new Date()).valueOf() - this.item.endTime.valueOf()) < 2592000000;
        }
        else{
            this._isNew = false;
        }
    }

    public get isNew(): boolean {
        return this._isNew;
    }

    public get isComplete(): boolean {
        return !isNullOrUndefined(this.item) ? this.item.isComplete : this._isComplete;
    }
    public set isComplete(value: boolean) {
        if (this._isComplete !== value) {
            this._isComplete = value;
            if(!isNullOrUndefined(this.item)){
                this.item.isComplete = value;
            }
        }
    }

    public get item(): SyncedVideoSessionModel {
        return this._item as SyncedVideoSessionModel;
    }
    public set item(value: SyncedVideoSessionModel) {
        this._item = value;
    }
}

export class VideoViewModel {
    public frameResultLoadingProcess: ProcessMonitorServiceProcess;
    public frameResultLoadingSub: Subscription;

    public onDevice: OnDeviceViewModel;
    public schedule: ScheduleViewModel;
    public local: LocalViewModel;
    public nodeVideos: VideoViewModel[] = [];
    public devicePresent: boolean = true;
    public recordingPresent: boolean = true;
    public schedulePresent: boolean = true;
    public imageDataUri: string = null;
    public getFirstFrame: () => Observable<boolean>;

    private _durationText: string;

    public constructor(public device: DeviceModel) {
    }

    public static match(a: VideoViewModel | VideoSessionModel | SyncedVideoSessionModel | VideoScheduleModel, b: VideoViewModel | VideoSessionModel | SyncedVideoSessionModel | VideoScheduleModel, device?: DeviceModel): boolean {
        const aDevice = a instanceof VideoViewModel ? a.device : null;
        const bDevice = b instanceof VideoViewModel ? b.device : null;

        if (isNullOrUndefined(aDevice) && isNullOrUndefined(bDevice) && !isNullOrUndefined(device)) {
            return false;
        }

        if (isNullOrUndefined(device) || ((isNullOrUndefined(aDevice) || aDevice.serialNumber === device.serialNumber) && (isNullOrUndefined(bDevice) || bDevice.serialNumber === device.serialNumber))) {
            if (!isNullOrUndefined(a.groupRecordingIdentifier) && !isNullOrUndefined(b.groupRecordingIdentifier) && a.groupRecordingIdentifier !== '' && b.groupRecordingIdentifier !== '' && a.groupRecordingIdentifier === b.groupRecordingIdentifier) {
                return true;
            }

            if (isNullOrUndefined(a.groupRecordingIdentifier) || isNullOrUndefined(b.groupRecordingIdentifier) || a.groupRecordingIdentifier === '' || b.groupRecordingIdentifier === ''){
                const aStartTime = a.startTime;
                const bStartTime = b.startTime;

                if (!isNullOrUndefined(a.startTime) && !isNullOrUndefined(b.startTime) && aStartTime.valueOf() === bStartTime.valueOf()) {
                    return true;
                }
            }
        }

        return false;
    }

    public serialInfo(): string{
        if(!this.devicePresent){
            return 'Device not present';
        }

        if(!this.recordingPresent){
            return 'Recording not present';
        }

        if(!this.schedulePresent){
            return 'Schedule not present';
        }

        return this.device.serialNumber;
    }

    public sessionTypeProgress(sessionType: SessionTypes): number {
        return !isNullOrUndefined(this[sessionType]) ? this[sessionType].progress : 0;
    }

    public isAnySessionTypeQueued(sessionType: SessionTypes): boolean {
        return this.getMasterAndNodesAnyTrue(sessionType, 'isQueued');
    }

    public isAnySessionTypePaused(sessionType: SessionTypes): boolean {
        return this.getMasterAndNodesAnyTrue(sessionType, 'isPaused');
    }

    public isAnySessionTypeProcessing(sessionType: SessionTypes): boolean {
        return this.getMasterAndNodesAnyTrue(sessionType, 'isProcessing');
    }

    public isAnySessionTypeActive(sessionType: SessionTypes): boolean {
        return this.getMasterAndNodesAnyTrue(sessionType, 'isActive');
    }

    public isThisSessionTypeActive(sessionType: SessionTypes): boolean {
        return this.getThisSessionTrue(sessionType, 'isActive');
    }

    public isAnySessionTypeComplete(sessionType: SessionTypes): boolean {
        return this.getMasterAndNodesAnyTrue(sessionType, 'isComplete');
    }

    public isAllSessionTypeComplete(sessionType: SessionTypes): boolean {
        return this.getMasterAndNodesAllTrue(sessionType, 'isComplete');
    }

    public get isAnyQueued(): boolean {
        return this.getMasterAndNodesAnyTrue('schedule', 'isQueued') || this.getMasterAndNodesAnyTrue('onDevice', 'isQueued') || this.getMasterAndNodesAnyTrue('local', 'isQueued');
    }

    public get isThisQueued(): boolean {
        return this.getThisSessionTrue('schedule', 'isQueued') || this.getThisSessionTrue('onDevice', 'isQueued') || this.getThisSessionTrue('local', 'isQueued');
    }

    public get isAnyPaused(): boolean {
        return this.getMasterAndNodesAnyTrue('schedule', 'isPaused') || this.getMasterAndNodesAnyTrue('onDevice', 'isPaused') || this.getMasterAndNodesAnyTrue('local', 'isPaused');
    }

    public get isThisPaused(): boolean {
        return this.getThisSessionTrue('schedule', 'isPaused') || this.getThisSessionTrue('onDevice', 'isPaused') || this.getThisSessionTrue('local', 'isPaused');
    }

    public get isAnyProcessing(): boolean {
        return this.getMasterAndNodesAnyTrue('schedule', 'isProcessing') || this.getMasterAndNodesAnyTrue('onDevice', 'isProcessing') || this.getMasterAndNodesAnyTrue('local', 'isProcessing');
    }

    public get isthisProcessing(): boolean {
        return this.getThisSessionTrue('schedule', 'isProcessing') || this.getThisSessionTrue('onDevice', 'isProcessing') || this.getThisSessionTrue('local', 'isProcessing');
    }

    public get isAnyActive(): boolean {
        return this.getMasterAndNodesAnyTrue('schedule', 'isActive') || this.getMasterAndNodesAnyTrue('onDevice', 'isActive') || this.getMasterAndNodesAnyTrue('local', 'isActive');
    }

    public get isThisActive(): boolean {
        return this.getThisSessionTrue('schedule', 'isActive') || this.getThisSessionTrue('onDevice', 'isActive') || this.getThisSessionTrue('local', 'isActive');
    }

    public get isAnyComplete(): boolean {
        return this.getMasterAndNodesAnyTrue('schedule', 'isComplete') || this.getMasterAndNodesAnyTrue('onDevice', 'isComplete') || this.getMasterAndNodesAnyTrue('local', 'isComplete');
    }

    public get isThisComplete(): boolean {
        return this.getThisSessionTrue('schedule', 'isComplete') || this.getThisSessionTrue('onDevice', 'isComplete') || this.getThisSessionTrue('local', 'isComplete');
    }

    public get isAllDevicesPresent(): boolean{
        if(!this.devicePresent){
            return false;
        }

        const numNodes = this.nodeVideos.length;

        for(let i = 0; i < numNodes; i++){
            if(!this.nodeVideos[i].devicePresent){
                return false;
            }
        }

        return true;
    }

    public get isAllRecordingsPresent(): boolean{
        if(!this.recordingPresent){
            return false;
        }

        const numNodes = this.nodeVideos.length;

        for(let i = 0; i < numNodes; i++){
            if(!this.nodeVideos[i].recordingPresent){
                return false;
            }
        }

        return true;
    }

    public get startTime(): Date {
        return !isNullOrUndefined(this.schedule) ? this.schedule.startTime : !isNullOrUndefined(this.onDevice) ? this.onDevice.startTime : !isNullOrUndefined(this.local) ? this.local.startTime : null;
    }

    public get startDate(): Date {
        return !isNullOrUndefined(this.schedule) ? this.schedule.startTime : !isNullOrUndefined(this.onDevice) ? this.onDevice.startTime : !isNullOrUndefined(this.local) ? this.local.startTime : null;
    }

    public get endTime(): Date {
        return !isNullOrUndefined(this.schedule) ? this.schedule.endTime : !isNullOrUndefined(this.onDevice) ? this.onDevice.endTime : !isNullOrUndefined(this.local) ? this.local.endTime : null;
    }

    public get groupRecordingIdentifier(): string{
        return !isNullOrUndefined(this.schedule) ? this.schedule.groupRecordingIdentifier : !isNullOrUndefined(this.onDevice) ? this.onDevice.groupRecordingIdentifier : !isNullOrUndefined(this.local) ? this.local.groupRecordingIdentifier : null;
    }

    public isSessionType(sessionType: SessionTypes): boolean {
        return !isNullOrUndefined(this[sessionType]);
    }

    public get isSchedule(): boolean {
        return this.isSessionType('schedule');
    }

    public get isLocal(): boolean {
        return this.isSessionType('local');
    }

    public get isOnDevice(): boolean {
        return this.isSessionType('onDevice');
    }

    public get durationText(): string {
        if (isNullOrUndefined(this._durationText)) {
            if (this.isSchedule === true && !isNullOrUndefined(this.schedule.item)){
                if (this.schedule.item.type === ScheduleTypeEnum.timeBased){
                    this._durationText = DateTimeUtility.toDuration(this.startTime, this.endTime);
                }
                else{
                    this._durationText = this.schedule.item.endCount.toString() + ' Counts';
                }
            }
            else{
                this._durationText = DateTimeUtility.toDuration(this.startTime, this.endTime);
            }
        }
        return this._durationText;
    }

    public get stateText(): string {
        if (this.isAnyProcessing === true) {
            return 'Processing';
        }
        if (this.isAnyActive === true) {
            if (this.isSchedule === true) {
                return 'Recording';
            } else if (this.isOnDevice) {
                return 'Synchronizing';
            }
        }
        if (this.isAnyQueued === true) {
            return 'Queued';
        }
        if (this.isAnyPaused === true) {
            return 'Paused';
        }

        if (!isNullOrUndefined(this.onDevice)) {
            if (this.onDevice.isNew === true) {
                return 'New';
            }
        }

        if (!isNullOrUndefined(this.local)) {
            if (this.local.isNew) {
                return 'New';
            }
        }

        return '';
    }

    public get synchronizeText(): string {

        if (this.isthisProcessing === true) {
            return 'Processing';
        }
        if (this.isThisActive === true) {
            if (this.isSchedule === true) {
                return 'Recording';
            } else if (this.isOnDevice) {
                return 'Synchronizing';
            }
        }
        if (this.isThisQueued === true) {
            return 'Queued';
        }
        if (this.isThisPaused === true) {
            return 'Paused';
        }

        if (this.isThisComplete === true) {
            return 'Complete';
        }

        if (this.isLocal === true) {
            return 'Partial';
        }

        return '';
    }

    private getMasterAndNodesAllTrue(sessionType: SessionTypes, key: keyof VideoViewModelState): boolean {
        if (!isNullOrUndefined(this[sessionType])) {
            if (this[sessionType][key] === false) {
                return false;
            }

            const nodeLength = this.nodeVideos.length;
            if (nodeLength > 0) {
                for (let i = 0; i < nodeLength; i++) {
                    const node = this.nodeVideos[i];
                    if (!isNullOrUndefined(node) && node.devicePresent) {
                        if (node.isSessionType(sessionType) === true) {
                            const session = node[sessionType];
                            const value = session[key];
                            if (value === false) {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }
                }
            }

            return true;
        }

        return false;
    }

    private getMasterAndNodesAnyTrue(sessionType: SessionTypes, key: keyof VideoViewModelState): boolean {
        if (!isNullOrUndefined(this[sessionType])) {
            if (this[sessionType][key] === true) {
                return true;
            }

            const nodeLength = this.nodeVideos.length;
            if (nodeLength > 0) {
                for (let i = 0; i < nodeLength; i++) {
                    const node = this.nodeVideos[i];
                    if (!isNullOrUndefined(node) && node.isSessionType(sessionType) === true) {
                        const session = node[sessionType];
                        const value = session[key];
                        if ((value as boolean) === true){
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    private getThisSessionTrue(sessionType: SessionTypes, key: keyof VideoViewModelState): boolean {
        if (!isNullOrUndefined(this[sessionType])) {
            if (this[sessionType][key] === true) {
                return true;
            }
        }

        return false;
    }
}
