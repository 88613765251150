<div #mainContent
    id="rift-diagnostics"
    class="main-content scroll-content">
    <div fxLayout.gt-sm="row"
        fxLayout.lt-md="column">
        <div fxShow.gt-sm="false"
            fxShow.lt-md="true">
            <mat-card class="spacing-bottom"
                style="min-width: 130px;">
                <mat-card-title>
                    Devices
                </mat-card-title>
                <mat-card-subtitle>
                    Master
                </mat-card-subtitle>
                <mat-card-content>
                    <div fxLayout.lt-sm="column"
                        fxLayout.gt-xs="row">
                        <div fxLayout="row"
                            fxLayoutAlign="center center">
                            <button class="spacing-right"
                                [color]="!isNullOrUndefined(selectedDeviceLogs) && !isNullOrUndefined(selectedDeviceLogs.device) && !isNullOrUndefined(master) && selectedDeviceLogs.device.serialNumber === master.serialNumber ? 'primary' : ''"
                                (click)="deviceSelected(master)"
                                mat-raised-button>{{ master?.serialNumber }}</button>
                        </div>
                    </div>
                </mat-card-content>
                <ng-template [ngIf]="!isNullOrUndefined(nodes) && nodes.length > 0">
                    <mat-card-subtitle>
                        Nodes
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div fxLayout.lt-sm="column"
                            fxLayout.gt-xs="row">
                            <ng-template ngFor
                                let-node
                                [ngForOf]="nodes | orderBy: 'serialNumber'">
                                <div fxLayout="row"
                                    fxLayoutAlign="center center"
                                    class="spacing-bottom">
                                    <button class="spacing-right"
                                        [color]="!isNullOrUndefined(selectedDeviceLogs) && !isNullOrUndefined(selectedDeviceLogs.device) && !isNullOrUndefined(node) && selectedDeviceLogs.device.serialNumber === node.serialNumber ? 'primary' : ''"
                                        (click)="deviceSelected(node)"
                                        mat-raised-button>{{ node.serialNumber }}</button>
                                </div>
                            </ng-template>
                        </div>
                    </mat-card-content>
                </ng-template>
                <mat-card-actions>
                    <shr-progress-button-spinner [raised]="true"
                        [diameter]="30"
                        text="Download Logs"
                        mode="indeterminate"
                        [active]="getLogFileProcess.isRunning  === true"
                        (shrClick)="downloadLogs()">
                    </shr-progress-button-spinner>
                    <shr-progress-button-spinner *ngIf="isReadOnly === false && ((isDeviceGen(UnitGenerationEnum.gen4) | async) === true || (isDeviceGen(UnitGenerationEnum.gazelle) | async) === true)"
                        [raised]="true"
                        [diameter]="30"
                        text="Reset Recent Logs"
                        mode="indeterminate"
                        [active]="resetLogProcess.isRunning === true"
                        (shrClick)="resetLogs()">
                    </shr-progress-button-spinner>
                </mat-card-actions>
            </mat-card>
        </div>
        <div fxLayout="column"
            fxFlex="100"
            ngClass.gt-sm="spacing-right">
            <shr-table *ngIf="globalDataSource.data?.length > 0"
                matSort
                #globalSort="matSort"
                [isLoadingInBackground]="getGlobalLogsProcess.isRunning"
                [sort]="globalSort"
                class="spacing-bottom"
                (filter)="applyFilter($event, globalDataSource)"
                [timeSetup]="timeSetup"
                [dataSource]="globalDataSource"
                [displayedColumns]="displayedColumnsGlobal">
                <ng-template #shrTableHeader>
                    Global Current Logs
                    ({{ globalLogs.length }})
                </ng-template>

                <ng-container matColumnDef="class">
                    <th mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header> </th>
                    <td mat-cell
                        *matCellDef="let log">
                        <mat-icon class="shr-error"
                            *ngIf="log.class === DeviceErrorClassEnum.error">error
                        </mat-icon>
                        <mat-icon class="shr-warning"
                            *ngIf="log.class === DeviceErrorClassEnum.warning">warning
                        </mat-icon>
                        <mat-icon class="shr-info"
                            *ngIf="log.class === DeviceErrorClassEnum.information">info
                        </mat-icon>
                    </td>
                </ng-container>                

                <ng-container matColumnDef="friendlySerialNumber">
                    <th mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header> Serial </th>
                    <td mat-cell
                        *matCellDef="let log">
                        <div fxLayout="column"
                            fxLayoutAlign="center start">
                            <button (click)="deviceSerialSelected(log.friendlySerialNumber)"
                                mat-button>{{ log.friendlySerialNumber }}</button>
                            <button (click)="deviceSerialSelected(serial)"
                                class="spacing-left"
                                *ngFor="let serial of log.linksToDevicesFriendlySerialNumber"
                                mat-button>{{ serial }}</button>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="typeText">
                    <th mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header> Type </th>
                    <td mat-cell
                        *matCellDef="let log">
                        <shr-table-cell [data]="log"
                            keyPath="typeText"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="message">
                    <th mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header> Message </th>
                    <td mat-cell
                        *matCellDef="let log">
                        <shr-table-cell warp="true"
                            [data]="log"
                            [spanStyle]="{'white-space': 'pre-wrap', 'line-break': 'loose', 'word-break': 'break-all'}"
                            keyPath="message"></shr-table-cell>
                    </td>
                </ng-container>
            </shr-table>            
            <shr-table matSort
                #historicalSort="matSort"
                [isLoadingInBackground]="getHistoricalLogsProcess.isRunning"
                [sort]="historicalSort"
                class="spacing-bottom"
                (filter)="applyFilter($event, historicalDataSource)"
                [timeSetup]="timeSetup"
                [dataSource]="historicalDataSource"
                [displayedColumns]="displayedColumns">
                <ng-template #shrTableHeader>
                    {{ isNullOrUndefined(selectedDeviceLogs) ||
                                isNullOrUndefined(selectedDeviceLogs.device) ? 'Loading' :
                                selectedDeviceLogs.device.serialNumber
                                }} Historical Device Logs ({{ isNullOrUndefined(selectedDeviceLogs) ||
                                isNullOrUndefined(selectedDeviceLogs.historicalLogs)
                                ? 'Loading' : selectedDeviceLogs.historicalLogs.length }})
                </ng-template>

                <ng-container matColumnDef="class">
                    <th mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header> </th>
                    <td mat-cell
                        *matCellDef="let log">
                        <mat-icon class="shr-error"
                            *ngIf="log.class === DeviceErrorClassEnum.error">error
                        </mat-icon>
                        <mat-icon class="shr-warning"
                            *ngIf="log.class === DeviceErrorClassEnum.warning">warning
                        </mat-icon>
                        <mat-icon class="shr-info"
                            *ngIf="log.class === DeviceErrorClassEnum.information">info
                        </mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="shortDateTimeText">
                    <th mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header="timestamp"> Date </th>
                    <td mat-cell
                        *matCellDef="let log">
                        <shr-table-cell dateFormat="shortDateTimeFormatWithSeconds"
                            [data]="log"
                            [timeSetup]="timeSetup"
                            keyPath="timestamp"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="typeText">
                    <th mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header> Type </th>
                    <td mat-cell
                        *matCellDef="let log">
                        <shr-table-cell [data]="log"
                            keyPath="typeText"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="message">
                    <th mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header> Message </th>
                    <td mat-cell
                        *matCellDef="let log">
                        <shr-table-cell [data]="log"                            
                            [spanStyle]="{'white-space': 'pre-wrap', 'line-break': 'loose', 'word-break': 'break-all'}"
                            keyPath="message"></shr-table-cell>
                    </td>
                </ng-container>
            </shr-table>
        </div>
        <div fxShow.gt-sm="true"
            fxShow.lt-md="false">
            <mat-card class="spacing-bottom"
                style="min-width: 130px;">
                <mat-card-title>
                    Devices
                </mat-card-title>
                <mat-card-subtitle>
                    Master
                </mat-card-subtitle>
                <mat-card-content>
                    <div fxLayout="column">
                        <div fxLayout="row"
                            fxLayoutAlign="center center">
                            <button
                                [color]="!isNullOrUndefined(selectedDeviceLogs) && !isNullOrUndefined(selectedDeviceLogs.device) && !isNullOrUndefined(master) && selectedDeviceLogs.device.serialNumber === master.serialNumber ? 'primary' : ''"
                                (click)="deviceSelected(master)"
                                mat-raised-button>{{ master?.serialNumber }}</button>
                        </div>
                    </div>
                </mat-card-content>
                <ng-template [ngIf]="!isNullOrUndefined(nodes) && nodes.length > 0">
                    <mat-card-subtitle>
                        Nodes
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div fxLayout="column">
                            <ng-template ngFor
                                let-node
                                [ngForOf]="nodes | orderBy: 'serialNumber'">
                                <div fxLayout="row"
                                    fxLayoutAlign="center center"
                                    class="spacing-bottom">
                                    <button
                                        [color]="!isNullOrUndefined(selectedDeviceLogs) && !isNullOrUndefined(selectedDeviceLogs.device) && !isNullOrUndefined(node) && selectedDeviceLogs.device.serialNumber === node.serialNumber ? 'primary' : ''"
                                        (click)="deviceSelected(node)"
                                        mat-raised-button>{{ node.serialNumber }}</button>
                                </div>
                            </ng-template>
                        </div>
                    </mat-card-content>
                </ng-template>
                <mat-card-actions>
                    <shr-progress-button-spinner [raised]="true"
                        [diameter]="30"
                        text="Download Logs"
                        mode="indeterminate"
                        [active]="getLogFileProcess.isRunning === true"
                        (shrClick)="downloadLogs()">
                    </shr-progress-button-spinner>
                </mat-card-actions>
                <shr-progress-button-spinner *ngIf="((isDeviceGen(UnitGenerationEnum.gen4) | async) === true || (isDeviceGen(UnitGenerationEnum.gazelle) | async) === true) && isReadOnly === false"
                    [raised]="true"
                    [diameter]="30"
                    text="Reset Recent Logs"
                    mode="indeterminate"
                    [active]="resetLogProcess.isRunning === true"
                    (shrClick)="resetLogs()">
                </shr-progress-button-spinner>
            </mat-card>
        </div>
    </div>
</div>