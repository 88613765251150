import { Injectable, NgZone } from '@angular/core';
import { UserModel } from '@em/models/restapi/User.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiAccountService } from '@em/service/restapi/RestApi.Account.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ChangeUserPasswordModel } from '@shared/models/restapi/ChangeUserPassword.Model';
import { LoginModel } from '@shared/models/restapi/Login.Model';
import { UserBaseModel } from '@shared/models/restapi/UserBase.Model';
import { UserPasswordModel } from '@shared/models/restapi/UserPassword.Model';
import { ProcessMonitorService } from '@shared/service/processmonitor/ProcessMonitor.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UserLoginResult, UserLogoffResult } from '@shared/service/user/User.Current.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first, last, map } from 'rxjs/operators';
import { MyAccountChangePasswordComponent, MyAccountChangePasswordData } from '@em/components/myaccount/changepassword/MyAccount.ChangePassword.Component';
import { UpdateCurrentUserResponseModel } from '@em/models/restapi/UpdateCurrentUserResponse.Model';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LoginAttemptResultModel, LoginResultEnum } from '@shared/models/restapi/LoginAttemptResult.Model';
import { AuthTypeEnum } from '@shared/enum/AuthType.Enum';
import { OkCancelDialogComponent, OkCancelDialogData } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { Router } from '@angular/router';


@Injectable()
export class EmUserCurrentService extends EmBaseService {
    public static readonly className = 'EmUserCurrentService';

    public userChanged: Subject<UserBaseModel> = new Subject();

    private _isAuthenticated = false;
    private _user: UserModel;
    private _userLoadingTracker = new ObservableTracker<UserBaseModel>();
    private _changePasswordDialogRef: MatDialogRef<MyAccountChangePasswordComponent>;
    private _contactAdminDialogRef: MatDialogRef<OkCancelDialogComponent>;

    public constructor(
        private readonly _zone: NgZone,
        private readonly _router: Router,
        private readonly _dialog: MatDialog,
        private readonly _processMonitorService: ProcessMonitorService,
        public readonly _restApiAccount: RestApiAccountService) {
        super();
    }

    public changePassword(passwords: ChangeUserPasswordModel, process?: ProcessMonitorServiceProcess): Observable<UserPasswordModel> {
        return this._restApiAccount.changeCurrentUserPassword(passwords, process);
    }

    public getQuickSetupFile(hostName: string, port: number, process?: ProcessMonitorServiceProcess): Observable<string> {
        return this._restApiAccount.getQuickSetupFile(hostName, port);
    }

    public get isAdmin(): Observable<boolean> {
        if (isNullOrUndefined(this._user)) {
            return this.user.pipe(
                map(user => user.isSystemAdmin === true || user.isSystemManager === true || user.isAdmin === true)
            );
        } else {
            return of(this._user.isSystemAdmin === true || this._user.isSystemManager === true || this._user.isAdmin === true);
        }
    }

    public get isAuthenticated(): Observable<boolean> {
        if (isNullOrUndefined(this._user)) {
            return this.user.pipe(
                map(user => this._isAuthenticated),
                first(),
            );
        } else {
            return of(this._isAuthenticated);
        }
    }

    public get isInstaller(): Observable<boolean> {
        if (isNullOrUndefined(this._user)) {
            return this.user.pipe(
                map(user => user.isSystemAdmin === true || user.isSystemManager === true || user.isAdmin === true || user.isInstaller === true)
            );
        } else {
            return of(this._user.isSystemAdmin === true || this._user.isSystemManager === true || this._user.isAdmin === true || this._user.isInstaller === true);
        }
    }

    public get isSystemAdmin(): Observable<boolean> {
        if (isNullOrUndefined(this._user)) {
            return this.user.pipe(
                map(user => user.isSystemAdmin === true)
            );
        } else {
            return of(this._user.isSystemAdmin === true);
        }
    }

    public get isSystemManager(): Observable<boolean> {
        if (isNullOrUndefined(this._user)) {
            return this.user.pipe(
                map(user => user.isSystemAdmin === true || user.isSystemManager === true)
            );
        } else {
            return of(this._user.isSystemAdmin === true || this._user.isSystemManager === true);
        }
    }

    public get isUser(): Observable<boolean> {
        if (isNullOrUndefined(this._user)) {
            return this.user.pipe(
                map(user => user.isSystemAdmin === true || user.isSystemManager === true || user.isAdmin === true || user.isInstaller === true || user.isUser === true)
            );
        } else {
            return of(this._user.isSystemAdmin === true || this._user.isSystemManager === true || this._user.isAdmin === true || this._user.isInstaller === true || this._user.isUser === true);
        }
    }

    public login(login: LoginModel, process?: ProcessMonitorServiceProcess): Observable<UserLoginResult> {
        return this._restApiAccount.login(login, process).pipe(
            map(loginAttemptResult => {
                const result = new UserLoginResult();

                let loginResult: LoginAttemptResultModel = null;

                if(isNullOrUndefined(loginAttemptResult.httpError)){
                    loginResult = loginAttemptResult;
                }
                else{
                    loginResult = new LoginAttemptResultModel();
                    loginResult.loadFromRestApiModel(loginAttemptResult.httpError.error);
                }

                if (loginResult.result === LoginResultEnum.valid) {
                    result.success = true;
                }
                else if (loginResult.result === LoginResultEnum.locked){
                    result.error = 'Incorrect username or password, account locked for 15 minutes.';
                    result.success = false;
                }
                else {
                    result.error = 'Unable to login username or password is incorrect';
                    result.success = false;
                }

                return result;
            }),
            last(),
            catchError((error, caught) => {
                const result = new UserLoginResult();
                result.success = false;
                result.error = 'Unable to login failed to connect to authentication service';
                return of(result);
            })
        );
    }

    public logoff(process?: ProcessMonitorServiceProcess): Observable<UserLogoffResult> {
        return this._restApiAccount.logoff(process).pipe(
            map(success => {
                const result = new UserLoginResult();
                result.success = success;
                if (!success) {
                    result.error = 'Unable to logoff please try again';
                }
                return result;
            }),
            last(),
            catchError((error, caught) => {
                const result = new UserLoginResult();
                result.success = false;
                result.error = 'Unable to logoff failed to connect to authentication service';
                return of(result);
            })
        );
    }

    public refresh(): void {
        this._user = null;
        this._isAuthenticated = false;
    }

    public resetAPIKey(process?: ProcessMonitorServiceProcess): Observable<string> {
        return this._restApiAccount.resetAPIKey(process).pipe(map(i => {
            this.addSubscription(this.user.subscribe(user => {
                (user as UserModel).webAPIKey = i;
            }));
            return i;
        }));
    }

    public get user(): Observable<UserBaseModel> {
        if (isNullOrUndefined(this._user)) {
            return this._userLoadingTracker
                .getLoading()
                .observable(this._restApiAccount.getCurrentUser().pipe(
                    map(user => {
                        if (!isNullOrUndefined(user)) {
                            this._user = user;

                            this._user.isSystemAdmin = this._user.roles.some(role => role.name.toLocaleLowerCase() === 'systemadmin');
                            this._user.isSystemManager = this._user.roles.some(role => role.name.toLocaleLowerCase() === 'systemmanager');
                            this._user.isAdmin = this._user.roles.some(role => role.name.toLocaleLowerCase() === 'admin');
                            this._user.isInstaller = this._user.roles.some(role => role.name.toLocaleLowerCase() === 'installer');
                            this._user.isUser = this._user.roles.some(role => role.name.toLocaleLowerCase() === 'user');

                            this._user.roleNames = [];
                            if (this._user.isSystemAdmin) {
                                this._user.roleNames.push('systemadmin');
                                this._user.roleNames.push('systemmanager');
                                this._user.roleNames.push('admin');
                                this._user.roleNames.push('installer');
                                this._user.roleNames.push('user');
                            } else if (this._user.isSystemManager) {
                                this._user.roleNames.push('systemmanager');
                                this._user.roleNames.push('admin');
                                this._user.roleNames.push('installer');
                                this._user.roleNames.push('user');
                            } else if (this._user.isAdmin) {
                                this._user.roleNames.push('admin');
                                this._user.roleNames.push('installer');
                                this._user.roleNames.push('user');
                            } else if (this._user.isInstaller) {
                                this._user.roleNames.push('installer');
                                this._user.roleNames.push('user');
                            } else if (this._user.isUser) {
                                this._user.roleNames.push('user');
                            }

                            this._isAuthenticated = true;

                            if (isNullOrUndefined(this._changePasswordDialogRef) && this._user.forcePasswordChange === true && this._user.authType === AuthTypeEnum.system) {
                                this._changePasswordDialogRef = this._dialog.open(MyAccountChangePasswordComponent, { data: new MyAccountChangePasswordData(false, 'Forced Password Change', 'Your password was recently reset, and must now be changed'), minWidth: 400, disableClose: true });
                                this._changePasswordDialogRef.afterClosed().subscribe(
                                    () => {
                                        this._changePasswordDialogRef = null;
                                    }
                                );
                            }

                            if (isNullOrUndefined(this._contactAdminDialogRef) && this._user.fullyInit === false){
                                this._contactAdminDialogRef = this._dialog.open(OkCancelDialogComponent, {data: new OkCancelDialogData('Contact Administrator', 'Please contact your administrator to grant access rights', false, true), disableClose: true});
                                this._contactAdminDialogRef.afterClosed().subscribe(
                                    () => {
                                        this._contactAdminDialogRef = null;
                                        this.addSubscription(this.logoff().subscribe(() => {
                                            this._zone.run(() => {
                                                this._router.navigate(['/', 'login']);
                                            });
                                        }));
                                    }
                                );
                            }

                            this.userChanged.next(this._user);
                            return this._user;
                        } else {
                            this._user = null;
                            this._isAuthenticated = false;
                            this.userChanged.next(this._user);
                            return this._user;
                        }
                    }),
                ));
        } else {
            return of(this._user);
        }
    }

    public saveCurrentUser(user: UserModel, process?: ProcessMonitorServiceProcess): Observable<UpdateCurrentUserResponseModel> {
        return this._restApiAccount.updateCurrentUser(user, process);
    }
}
