import { Component, EventEmitter, HostBinding, Injector, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionDetailsDialogComponent, SessionDetailsDialogData, SessionDetailsDialogResult } from '@rift/components/validation/sessiondetails/SessionDetails.Dialog.Component';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { GlobalModel } from '@rift/models/restapi/Global.Model';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { CountModel } from '@rift/models/websocket/Count.Model';
import { DbValidationSessionInfoModel, IDbValidationSessionInfoModel } from '@rift/service/validation/models/database/syncsession/IDbValidationSessionInfo.Model';
import { IValidationRecordingModel } from '@rift/service/validation/models/ValidationRecording.Model';
import { BaseComponent } from '@shared/base/Base.Component';
import { ValidationSessionStateEnum } from '@shared/enum/ValidationSessionState.Enum';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';

@Component({
    selector: 'rift-validation-sessions-menu',
    templateUrl: './SessionsMenu.Component.html',
    styleUrls: ['./SessionsMenu.Component.scss']
})
export class SessionsMenuComponent extends BaseComponent {
    public static className: string = 'SessionsMenuComponent';

    @Output()
    public newSession: EventEmitter<null> = new EventEmitter<null>();

    @Output()
    public sessionActionResult: EventEmitter<SessionDetailsDialogResult> = new EventEmitter<SessionDetailsDialogResult>();

    @Input()
    public devices: Array<DeviceModel>;

    @Input()
    public globalData: GlobalModel;

    @Input()
    public lineReportBucketSize: number;

    @Input()
    public lines: Array<LineModel>;

    @Input()
    public registers: Array<RegisterBaseModel>;

    @Input()
    public sessions: Array<IDbValidationSessionInfoModel>;

    @Input()
    public showDurationAsTime: boolean;

    @Input()
    public systemCounts: Array<CountModel>;

    @Input()
    public timeSetup: TimeSetupModel;

    @Input()
    public validationRecording: IValidationRecordingModel;

    public DateTimeUtility = DateTimeUtility;
    public ValidationSessionStateEnum = ValidationSessionStateEnum;

    @HostBinding()
    public id: string = 'rift-validation-sessions-menu';
    public sessionClickedProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector);

        this.sessionClickedProcess = this.processMonitorService.getProcess(SessionsMenuComponent.className, this.loadDataProcessText);
    }

    public newSessionClick(): void {
        this.newSession.emit(null);
    }

    public sessionClicked(session: IDbValidationSessionInfoModel): void {
        const sessionModel = new DbValidationSessionInfoModel();
        sessionModel.loadFromInterface(session);
        this.addSubscription(
            this.observableHandlerBase(
                this._dialog.open(SessionDetailsDialogComponent, {
                    data: new SessionDetailsDialogData(this.showDurationAsTime, this.lineReportBucketSize, sessionModel, this.registers, this.lines, this.systemCounts, this.devices, this.timeSetup, this.globalData, this.validationRecording)
                }
                ).afterClosed(), this.sessionClickedProcess).subscribe(
                    (result: SessionDetailsDialogResult) => {
                        if (!this.isNullOrUndefined(result)) {
                            this.sessionActionResult.emit(result);
                        }
                    }
                ), this.sessionClickedProcess);
    }

    public creationDateStringToDate(creationDate: string): Date{
        return new Date(creationDate);
    }
}
