import { NgZone } from '@angular/core';
import { TargetLocation } from '@rift/components/shared/viewport/targets/TargetLocation';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class TargetLocationChangeEvent {
    public location: TargetLocation = null;
    public index: number = null;

    public constructor(location: TargetLocation, index: number) {
        this.location = location;
        this.index = index;
    }
}

export class TargetLocationCollection extends Array<TargetLocation> {
    public currentLocationChange: Subject<TargetLocationChangeEvent> = new Subject<TargetLocationChangeEvent>();

    private readonly _isLiveMaxLocations: number = 2;

    private _currentLocation: TargetLocation = null;

    public constructor(
        protected readonly _zone: NgZone,
        protected readonly _isLive: boolean) {
        super();
        Object.setPrototypeOf(this, Object.create(TargetLocationCollection.prototype));
    }

    public get currentLocation(): TargetLocation {
        return this._zone.runOutsideAngular(() => this._currentLocation);
    }

    public get isLive(): boolean {
        return this._zone.runOutsideAngular(() => this._isLive);
    }

    public get isPersistent(): boolean {
        return this._zone.runOutsideAngular(() => this._isLive === null ? null : this._isLive === false);
    }

    public push(...locations: Array<TargetLocation>): number {
        return this._zone.runOutsideAngular(() => {
            const length = locations.length;
            for (let index = 0; index < length; index++) {
                const location = locations[index];
                if (this.isPersistent === true) {
                    // if (isNullOrUndefined(this.find(f => f.frame === location.frame))) {
                    const insertIndex = ArrayUtility.sortedIndex(this, 'frame', location.frame);
                    super.splice(insertIndex, 0, location);
                    // }
                } else {
                    const pushLength = super.push(location);
                    this.setCurrentLocation(location, pushLength - 1);
                }
            }

            return this.length;
        });
    }

    public setCurrentLocation(location: TargetLocation, index: number): void {
        this._zone.runOutsideAngular(() => {
            this._currentLocation = location;
            this.currentLocationChange.next(new TargetLocationChangeEvent(this.currentLocation, index));
        });
    }

    public clear(): void {
        this._zone.runOutsideAngular(() => {
            this.splice(0, this.length - 1);
        });
    }
}
