import { isArray } from '@shared/utility/General.Utility';
import { WebStorageUtility } from '@shared/utility/WebStorage.Utility';

/**
 * A marker metadata that marks a item for local storage.
 *
 * @export
 * @param {string} className The name of the class that is storing the values.
 * @param {string} key The storage key.
 * @returns
 */
export function LocalStorage(className: string, key: string) {
    return (target: object, propertyName: string): void => {
        key = `${className}-${key}` || propertyName;

        // Do not remove
        const storedValue = WebStorageUtility.get(localStorage, key);

        Object.defineProperty(target, propertyName, {
            get: () => {
                const value = WebStorageUtility.get(localStorage, key);

                if (isArray(value)) {
                    const array = (value as Array<any>);
                    array.push = function <T>(...items: T[]): number {
                        Array.prototype.push.apply(this, arguments);
                        WebStorageUtility.set(localStorage, key, array);
                        return array.length;
                    };

                    array.splice = function splice<T>(start: number, deleteCount?: number): T[] {
                        Array.prototype.splice.apply(this, arguments);
                        WebStorageUtility.set(localStorage, key, array);
                        return array;
                    };
                }

                return value;
            },
            set: (value: any) => {
                WebStorageUtility.set(localStorage, key, value);
            },
        });
    };
}

/**
 * A marker metadata that marks a item for session storage.
 *
 * @export
 * @param {string} className The name of the class that is storing the values.
 * @param {string} key The storage key.
 * @returns
 */
export function SessionStorage(className: string, key: string) {
    return (target: object, propertyName: string): void => {
        key = `${className}-${key}` || propertyName;

        // Do not remove
        const storedValue = WebStorageUtility.get(sessionStorage, key);

        Object.defineProperty(target, propertyName, {
            get: () => {
                const value = WebStorageUtility.get(sessionStorage, key);

                if (isArray(value)) {
                    const array = (value as Array<any>);
                    array.push = function <T>(...items: T[]): number {
                        Array.prototype.push.apply(this, arguments);
                        WebStorageUtility.set(sessionStorage, key, array);
                        return array.length;
                    };

                    array.splice = function splice<T>(start: number, deleteCount?: number): T[] {
                        Array.prototype.splice.apply(this, arguments);
                        WebStorageUtility.set(sessionStorage, key, array);
                        return array;
                    };
                }

                return value;
            },
            set: (value: any) => {
                WebStorageUtility.set(sessionStorage, key, value);
            },
        });
    };
}
