import { ISyncedVideoSessionModel, SyncedVideoSessionModel } from '@rift/models/restapi/SyncedVideoSession.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IValidationVideoIdentifierModel extends IRestModel {
    masterSerial: string;
    videoSession: ISyncedVideoSessionModel;
}

export class ValidationVideoIdentifierModel extends BaseModel implements IValidationVideoIdentifierModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public masterSerial: string = null;
    public videoSession: SyncedVideoSessionModel = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any, upperCase: boolean = true): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ValidationVideoIdentifierModel', null, upperCase);
        this.videoSession = RestModelUtility.loadFrom(restModel.VideoSession, SyncedVideoSessionModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            VideoSession: this.videoSession.toRestApiModel(),
        };
    }
}
