import { AfterViewInit, Component, ElementRef, HostBinding, Injector, OnDestroy, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { DeviceHealthService, DurationRangesEnum, HealthCounts } from '@em/service/data/devicehealth/DeviceHealth.Service';
import { HomeService } from '@em/service/data/home/Home.Service';
import { RecordingsService } from '@em/service/data/recordings/Recordings.Service';
import { RestApiHomeService } from '@em/service/restapi/RestApi.Home.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ResizedEvent } from '@shared/directive/resized/Resized.Directive.ResizedEvent';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { ILoadDate } from '@shared/interface/ILoadData';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, zip, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

declare const Plotly: any;

@Component({
    selector: 'em-dashboard-default',
    templateUrl: './Dashboard.Default.Component.html',
    styleUrls: ['./Dashboard.Default.Component.scss']
})
export class DashboardDefaultComponent extends BaseComponent implements ILoadDate, IFillHeight, OnDestroy, AfterViewInit {
    public static className: string = 'DashboardDefaultComponent';

    public dataPollingProcess: ProcessMonitorServiceProcess;
    public deviceHealthService: DeviceHealthService;
    public devices: Array<DeviceModel>;
    public healthCounts: HealthCounts;

    @ViewChild('healthContent', { static: true })
    public healthContent: ElementRef;

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @ViewChildren('mapContent')
    public mapContentNotification: QueryList<ElementRef>;

    @HostBinding()
    public id: string = 'em-dashboard-default';

    private _dataPollingEvent: DataPollingEvent;
    private _fillHeight: number = null;

    private _healthPollingEvent: DataPollingEvent;
    private _healthPollingProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _render: Renderer2,
        private readonly _restApiHomeService: RestApiHomeService,
        private readonly _dataPollingService: DataPollingService,
        private readonly _deviceHealthService: DeviceHealthService,
        private readonly _dialog: MatDialog,
        private readonly _homeService: HomeService,
        private readonly _recordingsService: RecordingsService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.deviceHealthService = this._deviceHealthService;

        this.loadDataProcess = this.processMonitorService.getProcess(DashboardDefaultComponent.className, this.loadDataProcessText);
        this.dataPollingProcess = this.processMonitorService.getProcess(DashboardDefaultComponent.className, 'Data Polling');
        this._healthPollingProcess = this.processMonitorService.getProcess(DashboardDefaultComponent.className, 'Health Polling');

        this._dataPollingEvent = new DataPollingEvent(`${DashboardDefaultComponent.className}:LoadData`, 0, 10000, this.loadDataProcess);
        this._healthPollingEvent = new DataPollingEvent(`${DashboardDefaultComponent.className}:HealthPolling`, 0, 1000 * 60 * 15, this.loadDataProcess);

        this.startDataPolling();

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public googleMapsPresent(): boolean{
        return typeof google === 'object';
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.stopDataPolling();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.setMapHeight();
    }

    public filledHeight(height: number): void {
        this._fillHeight = height;
        if (!this.isNullOrUndefined(this.mainContent) && !this.isNullOrUndefined(this.healthContent) && !this.isNullOrUndefined(this.mapContentNotification) && !this.isNullOrUndefined(this.mapContentNotification.first)) {
            this.setMapHeight();
        }
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._restApiHomeService.getMetrics(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.devices = result.devices;
                    }
                    return true;
                }),
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public onHealthContentResized(event: ResizedEvent): void {
        if (!this.isNullOrUndefined(this._fillHeight) && !this.isNullOrUndefined(this.mainContent) && !this.isNullOrUndefined(this.healthContent) && !this.isNullOrUndefined(this.mapContentNotification) && !this.isNullOrUndefined(this.mapContentNotification.first)) {
            this.setMapHeight();
        }
    }

    private getHealthData(): void{
        this.addSubscription(this._deviceHealthService.getOverallHealth(DurationRangesEnum.day, this._healthPollingProcess).subscribe(
            (result) => {
                if (!this.isNullOrUndefined(result)) {
                    this.healthCounts = result;
                }
                return true;
            }
        ));
    }

    private setMapHeight(): void {
        if (!this.isNullOrUndefined(this._fillHeight) && !this.isNullOrUndefined(this.mainContent) && !this.isNullOrUndefined(this.healthContent) && !this.isNullOrUndefined(this.mapContentNotification) && !this.isNullOrUndefined(this.mapContentNotification.first)) {
            this._render.setStyle(this.mapContentNotification.first.nativeElement, 'height', `${(this._fillHeight - this.healthContent.nativeElement.getBoundingClientRect().height) - 10}px`);
        }
    }

    private subDataPolling(): void {
        this.addSubscription(this._dataPollingEvent.poll.subscribe(() => {
            this._homeService.clearCache();
            this._recordingsService.clearCache();

            this.addSubscription(this._restApiHomeService.getMetrics(this.dataPollingProcess, {disabled: true}).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.devices = result.devices;
                    }
                    return true;
                }),
                catchError((err: any)=>{
                    console.log(err);
                    return of(true);
                })
            ).subscribe(), this.dataPollingProcess);
        }), this.dataPollingProcess);


        this.addSubscription(this._healthPollingEvent.poll.subscribe(() => {
            this._deviceHealthService.clearCache();

            this.addSubscription(this._deviceHealthService.getOverallHealth(DurationRangesEnum.day, this._healthPollingProcess, {disabled : true}).pipe(
                map((result) => {
                    if (!this.isNullOrUndefined(result)) {
                        this.healthCounts = result;
                    }
                    return true;
                }),
                catchError((err: any)=>{
                    console.log(err);
                    return of(true);
                })
            ).subscribe(), this._healthPollingProcess);
        }), this._healthPollingProcess);

        this.getHealthData();
    }

    private startDataPolling(): void {
        this.subDataPolling();
        this._dataPollingService.startEvent(this._dataPollingEvent);
        this._dataPollingService.startEvent(this._healthPollingEvent);
    }

    private stopDataPolling(): void {
        this._dataPollingService.stopEvent(this._dataPollingEvent);
        this._dataPollingService.stopEvent(this._healthPollingEvent);
    }
}
