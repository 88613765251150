import { Component, HostBinding, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { DeviceGroupEnum } from '@shared/enum/DeviceGroup.Enum';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { ErrorWarningSummaryModel } from '@em/models/restapi/ErrorWarningSummary.Model';
import { ErrorWarningSummaryStateEnum } from '@shared/enum/ErrorWarningSummaryState.Enum';
import { DeviceGroupErrorWarningEntry, DeviceGroupErrorWarningMapping, deviceGroupErrorWarningMappings } from './DeviceGroupErrorWarning.Mapping';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'em-device-group-error-warning',
    templateUrl: './DeviceGroupErrorWarning.Component.html',
    styleUrls: ['./DeviceGroupErrorWarning.Component.scss']
})
export class DeviceGroupErrorWarningComponent extends BaseComponent implements OnInit, OnDestroy {
    public static className: string = 'DeviceGroupErrorWarningComponent';

    @HostBinding()
    public id: string = 'em-device-group-error-warning';

    @LocalStorage(DeviceGroupErrorWarningComponent.className, 'pageOptions')
    public pageOptions: PaginationOptionsModel;

    public refreshingDevicesProcess: ProcessMonitorServiceProcess;
    public deviceQuickLinksPollProcess: ProcessMonitorServiceProcess;
    public devices: Array<DeviceModel>;
    public getErrorWarningSummaryProcess: ProcessMonitorServiceProcess;
    public getDevicesProcess: ProcessMonitorServiceProcess;
    public getGroupIdProcess: ProcessMonitorServiceProcess;

    public summary: Array<DeviceGroupErrorWarningEntry>;
    public selectedSummary: DeviceGroupErrorWarningEntry;

    private _groupId: DeviceGroupEnum;
    private _dataPollingEvent: DataPollingEvent;

    public constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _dataPollingService: DataPollingService,
        private readonly _deviceService: DeviceService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        if (this.isNullOrUndefined(this.pageOptions)){
            this.pageOptions = new PaginationOptionsModel();
            this.pageOptions.page = 1;
            this.pageOptions.resultsPerPage = 10;
        }

        this.getDevicesProcess = this.processMonitorService.getProcess(DeviceGroupErrorWarningComponent.className, 'Getting devices in group');
        this.refreshingDevicesProcess = this.processMonitorService.getProcess(DeviceGroupErrorWarningComponent.className, 'Refreshing devices in group');
        this.getGroupIdProcess = this.processMonitorService.getProcess(DeviceGroupErrorWarningComponent.className, 'Getting group id from router');
        this.deviceQuickLinksPollProcess = this.processMonitorService.getProcess(DeviceGroupErrorWarningComponent.className, 'Device quick links poll');
        this.getErrorWarningSummaryProcess = this.processMonitorService.getProcess(DeviceGroupErrorWarningComponent.className, 'Get Error Warning Summary');

        this._dataPollingEvent = new DataPollingEvent('DeviceGroupErrorWarningComponent:DeviceQuickLinks', 0, 20000, this.refreshingDevicesProcess);
    }

    public ngOnDestroy(): void {
        this.stopDataPolling();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.addSubscription(this.observableHandlerBase(this._activatedRoute.params, this.getGroupIdProcess).subscribe(params => {
            this._groupId = params.groupId;

            this.pageOptions.page = 1;
            this.selectedSummary = null;
            this._deviceService.clearCache();
            this.devices = null;
            this.summary = null;
            this.stopDataPolling();
            this.getErrorWarningSummary(this.getErrorWarningSummaryProcess);
        }), this.getGroupIdProcess);
    }

    public summarySelected(summaryEntry: DeviceGroupErrorWarningEntry): void{
        this.stopDataPolling();
        this.selectedSummary = summaryEntry;
        this.startDataPolling();
    }

    public onPageOptionsChanged(pageOptions: PaginationOptionsModel): void {
        this.pageOptions = pageOptions;
        this.getDevicesPage(this.getDevicesProcess);
    }

    private getDevicesPage(process: ProcessMonitorServiceProcess): void {
        this.addSubscription(this.observableHandlerBase(this._deviceService.getErrorWarningByGroup(this.selectedSummary.messageMatch, this.pageOptions, process), process).subscribe(
            page => {
                this.devices = page.items;
                this.pageOptions = page.options;
            }
        ), process);
    }

    private getErrorWarningSummary(process: ProcessMonitorServiceProcess): void {
        const loadingDialog = this.openPleaseWaitLoadingDialog();
        this.addSubscription(this.observableHandlerBase(this._deviceService.getErrorWarningSummary(process), process).subscribe(
            summary => {
                if(!this.isNullOrUndefined(loadingDialog)){
                    loadingDialog.close();
                }

                // Summary info
                const data = summary.items.filter((val=>{
                    if (this._groupId === DeviceGroupEnum.inError) {
                        return val.entryState === ErrorWarningSummaryStateEnum.error;
                    }
                    else if (this._groupId === DeviceGroupEnum.inWarning) {
                        return val.entryState === ErrorWarningSummaryStateEnum.warning;
                    }

                    return false;
                }));

                this.summary = [];

                // Process the summary info to compact certain groups together
                data.forEach(d => {
                    let entry = this.summary.find(s=>d.description.includes(s.messageMatch));

                    if(this.isNullOrUndefined(entry)){
                        const mapping = deviceGroupErrorWarningMappings.find(m => d.description.includes(m.messageMatch));

                        entry = new DeviceGroupErrorWarningEntry();

                        if(!this.isNullOrUndefined(mapping)){
                            entry.messageDisplay = mapping.messageDisplay;
                            entry.messageMatch = mapping.messageMatch;
                            entry.userDescription = mapping.userDescription;
                            entry.helpURL = mapping.helpURL;
                        }
                        else{
                            entry.messageDisplay = d.description;
                            entry.messageMatch = d.description;
                            entry.userDescription = 'Contact Irisys support for more details';
                            entry.helpURL = 'https://partners.irisys.net/';
                        }

                        entry.numDevices = d.numDevices;

                        this.summary.push(entry);
                    }
                    else{
                        entry.numDevices += d.numDevices;
                    }
                });
            }
        ), process);
    }

    private subDataPolling(): void {
        this.addSubscription(this._dataPollingEvent.poll.subscribe(() => {
            this.addSubscription(this._deviceService.getErrorWarningByGroup(this.selectedSummary.messageMatch, this.pageOptions, this.refreshingDevicesProcess, {disabled: true}).pipe(
                map(page => {
                    this.devices = page.items;
                    this.pageOptions = page.options;
                }),
                catchError((err: any) => {
                    console.log(err);
                    return of(true);
                })
            ).subscribe(), this.refreshingDevicesProcess);

        }), this.deviceQuickLinksPollProcess);
    }

    private startDataPolling(): void {
        this.subDataPolling();
        this._dataPollingService.startEvent(this._dataPollingEvent);
    }

    private stopDataPolling(): void {
        this._dataPollingService.stopEvent(this._dataPollingEvent);
    }
}
