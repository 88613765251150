import { NgZone, Directive } from '@angular/core';
import { TimeLineDurationView } from '@rift/components/shared/timeline/duration/TimeLine.Duration.View';
import { TimeLineRowBodyCounts } from '@rift/components/shared/timeline/rows/TimeLine.Row.Body.Counts';
import { TimeLineRowsSettings } from '@rift/components/shared/timeline/rows/TimeLine.Rows.Settings';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { IRegLineColorSettings, Settings, GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { ColorUtility } from '@shared/utility/Color.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';

@Directive()
export class TimeLineRowBody extends DisplayItem {
    public onDrag: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onMouseDown: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onPressUp: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onUserCountClick: Subject<number> = new Subject<number>();

    private _background: createjs.Shape = new createjs.Shape();
    private _grabBox: createjs.Shape = new createjs.Shape();
    private _isDragging: boolean = false;
    private _label: createjs.Text = null;
    private _labelFont: string = '20px Arial';
    private _regLineColorSettings: IRegLineColorSettings = null;
    private _systemCounts: TimeLineRowBodyCounts = null;
    private _userCounts: TimeLineRowBodyCounts = null;
    private _width: number = 0;

    public constructor(
        protected readonly _durationView: TimeLineDurationView,
        protected readonly _registerBase: RegisterBaseModel,
        protected readonly _playService: ValidationPlayService,
        protected readonly _zone: NgZone) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            this._regLineColorSettings = Settings.register.colors[this._registerBase.registerIndex];

            this._systemCounts = new TimeLineRowBodyCounts('s', this._durationView, this._playService, this._zone, this._registerBase);
            this._systemCounts.color = '#000000';
            this._systemCounts.colorMouseOver = '#00a000';
            this._systemCounts.visible = true;

            this._userCounts = new TimeLineRowBodyCounts('u', this._durationView, this._playService, this._zone, this._registerBase);
            this._userCounts.color = '#0026ff';
            this._userCounts.colorMouseOver = '#ff0000';
            this._userCounts.container.y = this.systemCountsVisible ? TimeLineRowsSettings.row.height / 2 : 0;
            this._userCounts.visible = false;

            this.container.addChild(this._grabBox);
            this.container.addChild(this._background);
            this.container.addChild(this._systemCounts.container);
            this.container.addChild(this._userCounts.container);

            this.drawLabel();
            this.container.addChild(this._label);

            this.addEventHandlers();
        });
    }

    public bringToFront(displayItem?: IDisplayItem): void {

    }

    public get isDragging(): boolean {
        return this._isDragging;
    }

    public mouseMove(stageX: number): void {
        if (this.isMouseOver) {
            this.systemCounts.mouseMove(stageX);
            this.userCounts.mouseMove(stageX);
        }
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            this.userCounts.onDestroy();
            this.systemCounts.onDestroy();
            super.onDestroy();
            this.removeEventHandlers();
            this._background.uncache();
            this._grabBox.uncache();
        });
    }

    public resize(width: number): void {
        this._zone.runOutsideAngular(() => {
            this._width = width;
            this.countsResize();
            this.drawGrabBox();
            this.drawBackground();
            this.update();
        });
    }

    public get systemCounts(): TimeLineRowBodyCounts {
        return this._systemCounts;
    }

    public get systemCountsVisible(): boolean {
        return this._systemCounts.visible;
    }

    public set systemCountsVisible(value: boolean) {
        this._systemCounts.visible = value;
        this._userCounts.container.y = value ? TimeLineRowsSettings.row.height / 2 : 0;
        this.countsResize();
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            this.userCounts.update();
            this.systemCounts.update();
            this.requireStageUpdate.next();
        });
    }

    public get userCounts(): TimeLineRowBodyCounts {
        return this._userCounts;
    }

    public get userCountsVisible(): boolean {
        return this._userCounts.visible;
    }

    public set userCountsVisible(value: boolean) {
        this._userCounts.visible = value;
        this.countsResize();
    }

    public get visible(): boolean {
        return this.container.visible;
    }

    public set visible(value: boolean) {
        if (this.container.visible !== value) {
            this.container.visible = value;
        }
    }

    public get width(): number {
        return this._width;
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this._grabBox.on('mouseover', this.mouseOver.bind(this));
            this._grabBox.on('mouseout', this.mouseOut.bind(this));
            this._grabBox.on('click', this.click.bind(this));
            this._grabBox.on('mousedown', this.mouseDown.bind(this));
            this._grabBox.on('pressmove', this.pressMove.bind(this));
            this._grabBox.on('pressup', this.pressUp.bind(this));

            this.addSubscription(this._userCounts.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
            this.addSubscription(this._systemCounts.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
        });
    }

    private click(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (this._userCounts.mouseOverCount === 1) {
                this.onUserCountClick.next(this._userCounts.mouseOverCountFrameNumber);
            } else if (!isNullOrUndefined(this.systemCounts.mouseOverStartLocation) && !isNullOrUndefined(this.systemCounts.mouseOverEndLocation)) {
                this._playService.setViewRange(this.systemCounts.mouseOverStartLocation, this.systemCounts.mouseOverEndLocation);
            }
        });
    }

    private countsResize() {
        if (this.userCountsVisible && this.systemCountsVisible) {
            this.userCounts.resize(this.width, TimeLineRowsSettings.row.height / 2);
            this.systemCounts.resize(this.width, TimeLineRowsSettings.row.height / 2);
        } else if (this.userCountsVisible) {
            this.userCounts.resize(this.width, TimeLineRowsSettings.row.height);
        } else if (this.systemCountsVisible) {
            this.systemCounts.resize(this.width, TimeLineRowsSettings.row.height);
        }
    }

    private drawBackground(): void {
        const graphics = this._background.graphics;
        graphics.clear();

        if (this.width > 0) {
            const startColor = ColorUtility.hexToRGBA(this._regLineColorSettings.fillColor, TimeLineRowsSettings.body.backgroundStartAlpha);
            const endColor = ColorUtility.hexToRGBA(this._regLineColorSettings.fillColor, TimeLineRowsSettings.body.backgroundEndAlpha);

            graphics.beginRadialGradientFill([startColor, endColor], [1, 0], this.width / 2, TimeLineRowsSettings.row.height / 2, 0, this.width / 2, TimeLineRowsSettings.row.height / 2, this.width);
            graphics.drawRect(0, 0, this.width, TimeLineRowsSettings.row.height);
            graphics.endFill();
        }

        this._background.cache(0, 0, this.width, TimeLineRowsSettings.row.height, GLOBAL_CACHE_SCALE);
    }

    private drawGrabBox(): void {
        const graphics = this._grabBox.graphics;
        graphics.clear();

        if (this.width > 0) {
            graphics.beginFill(ColorUtility.hexToRGBA(Settings.grabBox.fillColor, Settings.grabBox.lineAlpha));
            graphics.rect(0, 0, this.width, TimeLineRowsSettings.row.height);
            graphics.endFill();
        }

        this._grabBox.cache(0, 0, this.width, TimeLineRowsSettings.row.height, GLOBAL_CACHE_SCALE);
    }

    private drawLabel(): void {
        this._label = new createjs.Text(`${this._registerBase.registerName} (${this._registerBase.typeDisplayName})`, this._labelFont);

        const height = this._label.getMeasuredHeight();
        const width = this._label.getMeasuredWidth();

        this._label.regY = height / 2;
        this._label.y = TimeLineRowsSettings.row.height / 2;
        this._label.x = 10;
        this._label.cache(0, 0, width, height, GLOBAL_CACHE_SCALE);
    }

    private mouseDown(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.isMouseDown = true;

            const moveTo = this._durationView.container.globalToLocal(event.stageX, null);
            const moveEvent = new DisplayItemMouseEvent(this, event);
            moveEvent.moveTo = moveTo;
            this.onMouseDown.next(moveEvent);
        });
    }

    private mouseOut(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.userCounts.mouseOut(event);
            this.systemCounts.mouseOut(event);
            this.isMouseOver = false;
        });
    }

    private mouseOver(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.isMouseOver = true;
        });
    }

    private pressMove(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (this.isMouseDown === true) {
                this._isDragging = true;

                const moveTo = this._durationView.container.globalToLocal(event.stageX, null);

                const dragEvent = new DisplayItemMouseEvent(this, event);
                dragEvent.moveTo = moveTo;
                this.onDrag.next(dragEvent);
            }
        });
    }

    private pressUp(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.isMouseDown = false;
            this._isDragging = false;

            const moveTo = this._durationView.container.globalToLocal(event.stageX, null);

            const upEvent = new DisplayItemMouseEvent(this, event);
            upEvent.moveTo = moveTo;

            this.onPressUp.next(upEvent);
        });
    }

    private removeEventHandlers(): void {
        super.onDestroy();
    }
}
