export enum DeviceGroupEnum {
    all = 'all',
    orphaned = 'orphaned',
    connected = 'connected',
    disconnected = 'disconnected',
    new = 'new',
    inError = 'inerror',
    inWarning = 'inwarning',
    unlicensed = 'unlicensed',
}

export class DeviceGroupEnumHelpers {
    public static toRestApi(group: DeviceGroupEnum): string {
        switch (group) {
            case DeviceGroupEnum.all:
                return 'all';
            case DeviceGroupEnum.orphaned:
                return 'orphaned';
            case DeviceGroupEnum.connected:
                return 'connected';
            case DeviceGroupEnum.disconnected:
                return 'disconnected';
            case DeviceGroupEnum.new:
                return 'new';
            case DeviceGroupEnum.inError:
                return 'inerror';
            case DeviceGroupEnum.inWarning:
                return 'inwarning';
            case DeviceGroupEnum.unlicensed:
                return 'unlicensed';
        }
    }

    public static parse(value: string): DeviceGroupEnum {
        switch (value.trim().toLocaleLowerCase()) {
            case 'all':
                return DeviceGroupEnum.all;
            case 'orphaned':
                return DeviceGroupEnum.orphaned;
            case 'connected':
                return DeviceGroupEnum.connected;
            case 'disconnected':
                return DeviceGroupEnum.disconnected;
            case 'new':
                return DeviceGroupEnum.new;
            case 'inerror':
                return DeviceGroupEnum.inError;
            case 'inwarning':
                return DeviceGroupEnum.inWarning;
            case 'unlicensed':
                return DeviceGroupEnum.unlicensed;
        }
    }
}
