import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConnectionModel } from '@em/models/restapi/Connection.Model';
import { ConnectionCollectionModel } from '@em/models/restapi/ConnectionCollection.Model';
import { ConnectionGroupModel } from '@em/models/restapi/ConnectionGroup.Model';
import { ConnectionGroupCollectionModel } from '@em/models/restapi/ConnectionGroupCollection.Model';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { EmRestApiService } from '@em/service/restapi/base/EmRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MatDialog } from '@angular/material/dialog';


@Injectable()
export class RestApiOutboundConnectionService extends EmRestApiService {
    private _controller = 'outboundconnection/';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.emRestApiBase, _httpClient);
    }

    public addConnection(connection: ConnectionModel, process?: ProcessMonitorServiceProcess): Observable<ConnectionModel> {
        return this.post<ConnectionModel, ConnectionModel>(`${this._controller}connection`, connection, ConnectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public addGroup(group: ConnectionGroupModel, process?: ProcessMonitorServiceProcess): Observable<ConnectionGroupModel> {
        return this.post<ConnectionGroupModel, ConnectionGroupModel>(`${this._controller}group`, group, ConnectionGroupModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteConnection(connectionId: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        let params = new HttpParams();
        params = params.append('connectionId', StringUtility.toString(connectionId));

        return this.delete<ResultModel>(`${this._controller}connection`, ResultModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteGroup(groupId: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        let params = new HttpParams();
        params = params.append('groupId', StringUtility.toString(groupId));

        return this.delete<ResultModel>(`${this._controller}group`, ResultModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getConnections(process?: ProcessMonitorServiceProcess): Observable<ConnectionCollectionModel> {
        return this.get<ConnectionCollectionModel>(`${this._controller}connection`, ConnectionCollectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getGroups(process?: ProcessMonitorServiceProcess): Observable<ConnectionGroupCollectionModel> {
        return this.get<ConnectionGroupCollectionModel>(`${this._controller}group`, ConnectionGroupCollectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateConnection(connection: ConnectionModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<ConnectionModel, ResultModel>(`${this._controller}connection`, connection, ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateGroup(group: ConnectionGroupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<ConnectionGroupModel, ResultModel>(`${this._controller}group`, group, ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
