import { REGISTER_BASE_EXCLUDE_PROPERTIES, RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterQueueWaitMasterModel extends IRegisterBaseModel {
    enterQueueBack: Array<number>;
    exitQueueBack: Array<number>;
    enterQueueFront: Array<number>;
    exitQueueFront: Array<number>;
    inactivityReset: number;
    rejoinTimeout: number;
    waitIndex: number;
}

/**
 * Represents a
 *
 * @export
 * @class RegisterQueueWaitMasterModel
 * @extends {RegisterBaseModel}
 * @implements {IRegisterQueueWaitMasterModel}
 * @implements {IRestModel}
 */
export class RegisterQueueWaitMasterModel extends RegisterBaseModel implements IRegisterQueueWaitMasterModel, IRestModel {
    public enterQueueBack: Array<number> = null;
    public exitQueueBack: Array<number> = null;
    public enterQueueFront: Array<number> = null;
    public exitQueueFront: Array<number> = null;
    public inactivityReset: number = null;
    public rejoinTimeout: number = null;
    public waitIndex: number = null;

    public constructor() {
        super();

        this.registerProperty('enterQueueBack');
        this.registerProperty('exitQueueBack');
        this.registerProperty('enterQueueFront');
        this.registerProperty('exitQueueFront');
        this.registerProperty('inactivityReset');
        this.registerProperty('rejoinTimeout');
        this.registerProperty('waitIndex');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterQueueWaitMasterModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('enterQueueBack', this.enterQueueBack);
        this.setPropertyOriginalValue('exitQueueBack', this.exitQueueBack);
        this.setPropertyOriginalValue('enterQueueFront', this.enterQueueFront);
        this.setPropertyOriginalValue('exitQueueFront', this.exitQueueFront);
        this.setPropertyOriginalValue('inactivityReset', this.inactivityReset);
        this.setPropertyOriginalValue('rejoinTimeout', this.rejoinTimeout);
        this.setPropertyOriginalValue('waitIndex', this.waitIndex);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
            EnterQueueBack: this.enterQueueBack,
            ExitQueueBack: this.exitQueueBack,
            EnterQueueFront: this.enterQueueFront,
            ExitQueueFront: this.exitQueueFront,
        };
    }

    public setIndexes(): void {
        if (isNullOrUndefined(this.enterQueueBack)) {
            this.enterQueueBack = [];
        }
        if (isNullOrUndefined(this.exitQueueBack)) {
            this.exitQueueBack = [];
        }
        if (isNullOrUndefined(this.enterQueueFront)) {
            this.enterQueueFront = [];
        }
        if (isNullOrUndefined(this.exitQueueFront)) {
            this.exitQueueFront = [];
        }

        super.setIndexesBase([], [...this.enterQueueBack, ...this.exitQueueBack, ...this.enterQueueFront, ...this.exitQueueFront,], []);
    }

}
