import { Injectable } from '@angular/core';
import { UserModel } from '@em/models/restapi/User.Model';
import { UserDeviceDisplayColumnModel } from '@em/models/restapi/UserDeviceDisplayColumn.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiAccountService } from '@em/service/restapi/RestApi.Account.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { last, map } from 'rxjs/operators';


@Injectable()
export class UserDeviceListColumnService extends EmBaseService {
    private _cache: Array<UserDeviceDisplayColumnModel>;

    private _getLoadingTracker = new ObservableTracker<Array<UserDeviceDisplayColumnModel>>();
    private _updateLoadingTracker = new ObservableTracker<boolean>();

    public constructor(
        private readonly _restApiAccountService: RestApiAccountService,
        private readonly _userCurrentService: UserCurrentService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._cache = null;
    }

    public get(process?: ProcessMonitorServiceProcess): Observable<Array<UserDeviceDisplayColumnModel>> {
        if (isNullOrUndefined(this._cache)) {
            return this._getLoadingTracker
                .getLoading()
                .observable(this._userCurrentService.user.pipe(
                    map(user => {
                        const emUser = user as UserModel;
                        this._cache = emUser.deviceDisplayColumns;
                        return emUser.deviceDisplayColumns;
                    })
                ));
        } else {
            return of(this._cache);
        }
    }
    public update(columns: Array<UserDeviceDisplayColumnModel>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this._cache = columns;
        return this._updateLoadingTracker
            .getLoading()
            .observable(this._restApiAccountService.saveUserColumnPreferences(columns, process).pipe(
                last(i => i)
            ));
    }
}
