import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { DeviceAdvancedSettingsModel } from '@rift/models/restapi/DeviceAdvancedSettings.Model';
import { DeviceCollectionModel } from '@rift/models/restapi/DeviceCollection.Model';
import { ErrorsAndWarningsModel } from '@rift/models/restapi/ErrorsAndWarnings.Model';
import { IPSetupModel } from '@rift/models/restapi/IPSetup.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { UpTimeModel } from '@rift/models/restapi/UpTime.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { AutoHeightDetectionStateEnum } from '@shared/enum/AutoHeightDetectionState.Enum';
import { DiagnosticsLogRangeEnum } from '@shared/enum/DiagnosticsLogRange.Enum';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LEDStateEnum } from '@shared/enum/LEDState.Enum';
import { TestConnectionDataModel } from '@rift/models/restapi/TestConnectionData.Model';
import { TestConnectionResultModel } from '@rift/models/restapi/TestConnectionResult.Model';
import { TimeOfFlightConfigDataModel } from '@rift/models/restapi/TimeOfFlightConfigData.Model';
import { IFileResponse } from '@shared/service/restapi/RestApi.Service';
import { FormatSystemStorageEnum } from '@shared/enum/FormatSystemStorage.Enum';
import { ImageSnapShotModel } from '@rift/models/restapi/ImageSnapShot.Model';

@Injectable()
export class RestApiDevicesService extends RiftRestApiService {
    private _controller = 'devices';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public resetLogs(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.get(`${this._controller}/resetlog`, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public refreshDeviceTilt(serialNumber: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.get(`${this._controller}/${serialNumber}/refresh_tilt`, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public autoDetectHeight(serialNumber: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.get(`${this._controller}/${serialNumber}/enableautoheight`, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getAdvancedSettings(process?: ProcessMonitorServiceProcess): Observable<DeviceAdvancedSettingsModel> {
        return this.get(`${this._controller}/advancedsettings`, DeviceAdvancedSettingsModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getAutoHeightDetectionState(serialNumber: string, process?: ProcessMonitorServiceProcess): Observable<AutoHeightDetectionStateEnum | string> {
        return this.get<any>(`${this._controller}/${serialNumber}/autoheightdetectionstate`, 'string', this.getTokenParams(), process).pipe(
            map(result => {
                if (!isNullOrUndefined(result.Error)) {
                    return result.Error;
                } else {
                    return result as AutoHeightDetectionStateEnum;
                }
            })
        ).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDetectedHeight(serialNumber: string, process?: ProcessMonitorServiceProcess): Observable<number> {
        return this.get<number>(`${this._controller}/${serialNumber}/detectedheight`, 'number', this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDevice(serialNumber: string, process?: ProcessMonitorServiceProcess): Observable<DeviceCollectionModel> {
        return this.get<DeviceCollectionModel>(`${this._controller}`, DeviceCollectionModel, this.getTokenParams(['id', serialNumber]), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDevices(process?: ProcessMonitorServiceProcess): Observable<DeviceCollectionModel> {
        return this.get<DeviceCollectionModel>(`${this._controller}`, DeviceCollectionModel, this.getTokenParams(['include3000valcams', 'true']), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDiagnosticsByRange(serialNumber: string, count: number, nextIndex?: number, range?: DiagnosticsLogRangeEnum, newestFirst?: boolean, process?: ProcessMonitorServiceProcess): Observable<ErrorsAndWarningsModel> {
        let params = this.getTokenParams();

        if (!isNullOrUndefined(count)) {
            params = params.append('count', count.toString());
        }
        if (!isNullOrUndefined(nextIndex)) {
            params = params.append('nextIndex', nextIndex.toString());
        }
        if (!isNullOrUndefined(range) && range === DiagnosticsLogRangeEnum.recent) {
            params = params.append('range', DiagnosticsLogRangeEnum.recent.toString());
        }
        if (!isNullOrUndefined(newestFirst)) {
            params = params.append('newestFirst', newestFirst.toString());
        }

        return this.get<ErrorsAndWarningsModel>(`${this._controller}/${serialNumber}/diagnosticspagerange`, ErrorsAndWarningsModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDiagnosticsCounts(serialNumber: string, range?: DiagnosticsLogRangeEnum, process?: ProcessMonitorServiceProcess): Observable<ErrorsAndWarningsModel> {
        let params = this.getTokenParams();

        if (!isNullOrUndefined(range) && range === DiagnosticsLogRangeEnum.recent) {
            params = params.append('range', DiagnosticsLogRangeEnum.recent.toString());
        }

        return this.get<ErrorsAndWarningsModel>(`${this._controller}/${serialNumber}/diagnostics/counts`, ErrorsAndWarningsModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getIPSettings(serial: string, process?: ProcessMonitorServiceProcess): Observable<IPSetupModel> {
        return this.get(`${this._controller}/${serial}/ip_setup`, IPSetupModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getUpTime(process?: ProcessMonitorServiceProcess): Observable<UpTimeModel> {
        return this.get<UpTimeModel>(`${this._controller}/uptime`, UpTimeModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setIPSettings(serial: string, ipSettings: IPSetupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put(`${this._controller}/${serial}/ip_setup`, ipSettings, ResultModel, this.getTokenParams(), process, { disabled: true, excludeStatusCodes: [504] }, { excludeStatusCodes: [504] }).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateAdvancedSettings(settings: DeviceAdvancedSettingsModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put(`${this._controller}/advancedsettings`, settings, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateDevice(device: DeviceModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put(`${this._controller}/${device.serialNumber}`, device, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public testConnection(serial: string, connection: TestConnectionDataModel, process?: ProcessMonitorServiceProcess): Observable<TestConnectionResultModel> {
        return this.post(`${this._controller}/${serial}/test_connection`, connection, TestConnectionResultModel, this.getTokenParams(), process, { disabled: true }).pipe(
            map(result => result)
        );
    }

    public setTimeOfFlightFrameRate(serial: string, tofConfig: TimeOfFlightConfigDataModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post(`${this._controller}/${serial}/time_of_flight_config`, tofConfig, ResultModel, this.getTokenParams(), process).pipe(
            map(result => result)
        );
    }

    public setLedState(serial: string, state: LEDStateEnum, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        let params = this.getTokenParams();

        if (!isNullOrUndefined(state)) {
            params = params.append('state', state.toString());
        }

        return this.put(`${this._controller}/${serial}/led`, null, ResultModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public createDiagnosticsPackage(serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.get<ResultModel>(`${this._controller}/${serial}/diagnostics_package/create`, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public listDiagnosticsPackages(serial: string, process?: ProcessMonitorServiceProcess): Observable<Array<string>> {
        return this.get(`${this._controller}/${serial}/diagnostics_package/list`, 'string', this.getTokenParams(), process).pipe(
            map(result => result as Array<string>)
        );
    }

    public deleteDiagnosticsPackage(serial: string, packageName: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        let params = this.getTokenParams();
        params = params.append('packageName', encodeURIComponent(packageName));

        return this.delete(`${this._controller}/${serial}/diagnostics_package/remove`, ResultModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDiagnosticsPackage(serial: string, packageName: string, process?: ProcessMonitorServiceProcess): Observable<IFileResponse> {
        let params = this.getTokenParams();
        params = params.append('packageName', encodeURIComponent(packageName));

        return this.getFile(`${this._controller}/${serial}/diagnostics_package/get`, params, process, 'arraybuffer').pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public formatSystemStorage(serial: string, formatType: FormatSystemStorageEnum, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        let params = this.getTokenParams();
        params = params.append('formatType', formatType);

        return this.get<ResultModel>(`${this._controller}/${serial}/format_system_storage`, ResultModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getFormatSystemStorageResult(serial: string, process?: ProcessMonitorServiceProcess): Observable<string> {
        return this.get(`${this._controller}/${serial}/format_system_storage_result`, 'string', this.getTokenParams(), process).pipe(
            map(result => result as string)
        );
    }

    public getImageSnapshot(serial: string, res: string, process?: ProcessMonitorServiceProcess): Observable<ImageSnapShotModel> {
        let params = this.getTokenParams();
        params = params.append('res', encodeURIComponent(res));

        return this.get(`${this._controller}/${serial}/image_snapshot`, ImageSnapShotModel, params, process).pipe(
            map(result => result)
        );
    }
}
