import { Component, HostBinding, Injector, OnChanges, SimpleChanges, Directive, Input } from '@angular/core';
import { SettingsCountingBaseComponent } from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';
import { CountModeEnum, CountModeEnumHelpers } from '@shared/enum/CountMode.Enum';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';

@Component({
    selector: 'rift-settings-counting-count-mode',
    templateUrl: './Settings.Counting.CountMode.Component.html',
    styleUrls: ['./Settings.Counting.CountMode.Component.scss']
})
export class SettingsCountingCountModeComponent extends SettingsCountingBaseComponent implements OnChanges {
    public static className: string = 'SettingsCountingCountModeComponent';

    @HostBinding()
    public id: string = 'rift-settings-counting-count-mode';

    @Input()
    public register: RegisterBaseModel = null;

    public CountModeEnum = CountModeEnum;
    public mode: CountModeEnum = CountModeEnum.deferred;
    public modeGroup: CountModeEnum = CountModeEnum.deferred;
    public isDependent: boolean = false;
    public dependentOn: Array<RegisterBaseModel> = null;

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
        this.initConnectionState();
    }

    public getSettingsDescription(): string {
        return this.isNullOrUndefined(this.register) ? '' : CountModeEnumHelpers.toFullLongName(this.mode);
    }

    public get hasChanges(): boolean {
        if (!this.isNullOrUndefined(this.register)) {
            const length = this.lines.length;
            let countModeHasChange = false;
            for (let index = 0; index < length; index++) {
                const line = this.lines[index];
                if (this.register.lineIds.indexOf(line.iD) !== -1 && line.propertyHasChanges('countMode') === true) {
                    countModeHasChange = true;
                    break;
                }
            }

            return this.register.propertyHasChanges('addCountMode') || countModeHasChange;
        }
        return false;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue) && !this.isNullOrUndefined(changes.lines) && !this.isNullOrUndefined(changes.lines.currentValue)) {
            const register: RegisterBaseModel = changes.register.currentValue;
            const lines: Array<LineModel> = changes.lines.currentValue;

            if (!this.isNullOrUndefined(lines) && register.lineIds.length > 0) {
                const mode = lines.find(l => l.iD === register.lineIds[0]).countMode;
                this.setModeGroup(mode);
                this.mode = mode;
            }

            this.checkDependency();
        }
    }

    public onAntiDitherChange(event: MatCheckboxChange): void {
        this.onSelectedCountModeChange(event.checked === true ? CountModeEnum.immediateAntiDither : CountModeEnum.immediate);
    }

    public onDeferredChange(event: MatRadioChange): void {
        this.onSelectedCountModeChange(event.value);
    }

    public onDeferredGroupChange(event: MatRadioChange): void {
        this.onSelectedCountModeChange(event.value);
        this.mode = CountModeEnum.deferred;
    }

    public onImmediateGroupChange(event: MatRadioChange): void {
        this.onSelectedCountModeChange(event.value);
        this.mode = CountModeEnum.immediate;
    }

    public onSelectedCountModeChange(selectedCountMode: CountModeEnum): void {
        this.setModeGroup(selectedCountMode);
        this.mode = selectedCountMode;
        if (!this.isNullOrUndefined(this.register)) {
            this.register.countMode = this.mode;
            const linesLength = this.lines.length;
            for (let index = 0; index < linesLength; index++) {
                const line = this.lines[index];
                if (this.register.lineIds.indexOf(line.iD) !== -1) {
                    line.countMode = this.mode;
                }
            }
        }
    }

    private setModeGroup(mode: CountModeEnum): void {
        this.modeGroup = (mode === CountModeEnum.deferred || mode === CountModeEnum.deferredCountUTurns || mode === CountModeEnum.deferredOnlyCountUTurns) ? CountModeEnum.deferred : CountModeEnum.immediate;
    }

    private checkDependency(): void {
        if (!this.isNullOrUndefined(this.register) && !this.isNullOrUndefined(this.registers)) {
            this.dependentOn = [];
            const dependants = RegisterBaseUtility.getDependents(this.register, this.registers);
            if (!this.isNullOrUndefined(dependants) && !this.isNullOrUndefined(dependants.registers)) {
                const dependantsLength = dependants.registers.length;

                for(let i = 0; i < dependantsLength; i++){
                    if(dependants.registers[i].registerType === RegisterTypeEnum.sequential){
                        this.isDependent = true;
                        this.dependentOn.push(dependants.registers[i]);
                    }
                }
            }
        }
    }
}
