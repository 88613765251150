import { TestConnectionDataModel } from '@rift/models/restapi/TestConnectionData.Model';
import { DeviceCapabilitiesEnum } from './../../../../../shared/src/enum/DeviceCapabilities.Enum';
import { Component, HostListener, Injector, HostBinding } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { MQTTCredentialsModel } from '@rift/models/restapi/IMQTTCredentials.Model';
import { MQTTModel } from '@rift/models/restapi/MQTT.Model';
import { MQTTPublishConfigEntryModel } from '@rift/models/restapi/MQTTPublishConfigEntry.Model';
import { MQTTTopicConfigModel } from '@rift/models/restapi/MQTTTopicConfig.Model';
import { MQTTService } from '@rift/service/data/mqtt/MQTT.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { MQTTTopicIdentifierEnum, MQTTTopicIdentifierEnumHelpers } from '@shared/enum/MQTTTopicIdentifier.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, zip, of } from 'rxjs';
import { map, flatMap, filter } from 'rxjs/operators';
import { OkCancelDialogComponent, OkCancelDialogData } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RegisterBaseCollectionModel } from '@rift/models/restapi/RegisterBaseCollection.Model';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { SecurityService } from '@rift/service/data/security/Security.Service';

class MQTTEventType{
    public type: string;
    public display: string;
}

@Component({
    selector: 'rift-settings-mqtt',
    templateUrl: './Settings.MQTT.Component.html',
    styleUrls: ['./Settings.MQTT.Component.scss']
})
export class SettingsMQTTComponent extends RiftBaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsMQTTComponent';

    @HostBinding()
    public id: string = 'rift-settings-mqtt';

    public mqtt: MQTTModel;
    public formGroup: FormGroup;
    public hostDevice: DeviceModel;
    public qOSCapable: boolean;
    public targetsCapable: boolean;
    public deviceInfoCapable: boolean;
    public tLSCapable: boolean;
    public mqttLiveCounts: boolean;
    public mqttEventStreamCapable: boolean;
    public registerFilterCapable: boolean;
    public multiCertCapable: boolean;
    public retainCapable: boolean;
    public registers: RegisterBaseCollectionModel;
    public certPairNames: string[];

    public testConnectionProcess: ProcessMonitorServiceProcess;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    public readonly MQTTTopicIdentifierEnumHelpers = MQTTTopicIdentifierEnumHelpers;
    public readonly Identifiers = [MQTTTopicIdentifierEnum.counts, MQTTTopicIdentifierEnum.status, MQTTTopicIdentifierEnum.targets, MQTTTopicIdentifierEnum.liveCounts, MQTTTopicIdentifierEnum.deviceInfo, MQTTTopicIdentifierEnum.events];
    public readonly EventTypes: Array<MQTTEventType> = [
        {type: 'TARGET_BIRTH', display: 'Target Birth'},
        {type: 'TARGET_DEATH', display: 'Target Death'},
        {type: 'LINE_COUNT', display: 'Line Count'},
        {type: 'LINE_FWD_XING', display: 'Line Forward Crossing'},
        {type: 'LINE_REV_XING', display: 'Line Reverse Crossing'},
        {type: 'POLYGON_BIRTH', display: 'Polygon Birth'},
        {type: 'POLYGON_ENTER', display: 'Polygon Enter'},
        {type: 'POLYGON_EXIT', display: 'Polygon Exit'},
        {type: 'POLYGON_DEATH', display: 'Polygon Death'}
    ];

    public constructor(
        private readonly _mqttService: MQTTService,
        private readonly _registerService: RegisterService,
        private readonly _securityService: SecurityService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsMQTTComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsMQTTComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsMQTTComponent.className, this.saveAllChangesProcessText);
        this.testConnectionProcess = this.processMonitorService.getProcess(SettingsMQTTComponent.className, 'Testing connection');

        this.addSaveAllAction(this);

        this.formGroup = this._formBuilder.group({
            entries: this._formBuilder.array([]),
        });
        this.formGroupTracker.track(this.formGroup);

        this.initConnectionState();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._mqttService.clearCache();
            return of(true);
        });
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public onAddBrokerClick(): void {
        this.addBroker();
    }

    public onDeleteBrokerClick(entry: MQTTPublishConfigEntryModel, formArrayIndex: number): void {
        this.removeBroker(entry, formArrayIndex);
    }

    public onTestBrokerClick(entry: MQTTPublishConfigEntryModel): void {
        const data = new TestConnectionDataModel();
        data.address = entry.address;
        data.port = entry.port;
        this.addSubscription(this.observableHandlerBase(this.deviceService.testConnection(this.hostDevice.serialNumber, data), this.testConnectionProcess).subscribe(
            result => {
                const dialogData = new OkCancelDialogData(`MQTT Test (${entry.address}:${entry.port})`, null, false);
                dialogData.messageHtml = result.details.map(detail => `<span>${detail}</span><br>`).join('');

                this._dialog.open(OkCancelDialogComponent, { data: dialogData });
            }
        ), this.testConnectionProcess);
    }

    public eventFilterEnabledDisabled(checked: boolean, entry: MQTTTopicConfigModel){
        if(checked){
            entry.filter = [];

            this.EventTypes.forEach(t=>{
                entry.filter.push(t.type);
            });
        }
        else{
            entry.filter = [];
        }

        this.updateSaveAllAction(this);
    }

    public isEventFilterEnabled(entry: MQTTTopicConfigModel){
        if(this.isNullOrUndefined(entry) || this.isNullOrUndefined(entry.filter) || entry.filter.length === 0){
            return false;
        }

        return true;
    }

    public isEventSelected(entry: MQTTTopicConfigModel, event: string): boolean{
        if(this.isNullOrUndefined(entry.filter)){
            return false;
        }

        const entryFound = entry.filter.find(e=>e === event);

        if(this.isNullOrUndefined(entryFound)){
            return false;
        }

        return true;
    }

    public eventSelected(checked: boolean, entry: MQTTTopicConfigModel, event: string){
        if(this.isNullOrUndefined(entry.filter)){
            entry.filter = [];
        }

        const newOriginal = [];

        entry.filter.forEach(v=>newOriginal.push(v));

        entry.setPropertyOriginalValue('filter', newOriginal);

        if(checked){
            entry.filter.push(event);
        }
        else{
            const entryIndex = entry.filter.indexOf(event);

            if(entryIndex !== -1){
                entry.filter.splice(entryIndex, 1);
            }
        }

        entry.onPropertyChanged('filter', entry.filter);
        this.updateSaveAllAction(this);
    }

    public changeCredentials(entry: MQTTPublishConfigEntryModel){
        entry.credentialsSet = false;
        this.setFormArrayEnabledDisable();
    }

    public registerFilterEnabledDisabled(checked: boolean, entry: MQTTPublishConfigEntryModel){
        if(checked){
            entry.registerFilter = [];
            this.registers.items.forEach(r=>{
                entry.registerFilter.push(r.registerUUID);
            });
        }
        else{
            entry.registerFilter = [];
        }

        this.updateSaveAllAction(this);
    }

    public isRegisterFilterEnabled(entry: MQTTPublishConfigEntryModel): boolean{
        if(this.isNullOrUndefined(entry) || this.isNullOrUndefined(entry.registerFilter) || entry.registerFilter.length === 0){
            return false;
        }

        return true;
    }

    public isRegisterSelected(entry: MQTTPublishConfigEntryModel, register: RegisterBaseModel): boolean{
        if(this.isNullOrUndefined(entry.registerFilter)){
            return false;
        }

        const entryFound = entry.registerFilter.find(r=>r === register.registerUUID);

        if(this.isNullOrUndefined(entryFound)){
            return false;
        }

        return true;
    }

    public registerSelected(checked: boolean, entry: MQTTPublishConfigEntryModel, register: RegisterBaseModel){
        if(this.isNullOrUndefined(entry.registerFilter)){
            entry.registerFilter = [];
        }

        if(checked){
            entry.registerFilter.push(register.registerUUID);
        }
        else{
            const entryIndex = entry.registerFilter.indexOf(register.registerUUID);

            if(entryIndex !== -1){
                entry.registerFilter.splice(entryIndex, 1);
            }
        }

        entry.onPropertyChanged('registerFilter', entry.registerFilter);
        this.updateSaveAllAction(this);
    }

    public minRegistersSelected(entry: MQTTPublishConfigEntryModel): boolean{
        if(isNullOrUndefined(entry) || isNullOrUndefined(entry.registerFilter)){
            return false;
        }

        if(entry.registerFilter.length >= 1){
            return true;
        }

        return false;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this.changeTracker.clear();

        const loadDataSub = zip(
            this.getHostDevice().pipe(
                map(device => {
                    if (!this.isNullOrUndefined(device)) {
                        this.hostDevice = device;
                    }
                    return true;
                })
            ),
            this.isDeviceCapable(DeviceCapabilitiesEnum.mqttQOS).pipe(
                map(mqttQOS => {
                    if (!this.isNullOrUndefined(mqttQOS)) {
                        this.qOSCapable = mqttQOS;
                    }
                    return true;
                })
            ),
            this.isDeviceCapable(DeviceCapabilitiesEnum.mqttTargets).pipe(
                map(mqttTargets => {
                    if (!this.isNullOrUndefined(mqttTargets)) {
                        this.targetsCapable = mqttTargets;
                    }
                    return true;
                })
            ),
            this.isDeviceCapable(DeviceCapabilitiesEnum.mqttDeviceInfo).pipe(
                map(mqttDeviceInfo => {
                    if (!this.isNullOrUndefined(mqttDeviceInfo)) {
                        this.deviceInfoCapable = mqttDeviceInfo;
                    }
                    return true;
                })
            ),
            this.isDeviceCapable(DeviceCapabilitiesEnum.mqttTLS).pipe(
                map(mqttTLS => {
                    if (!this.isNullOrUndefined(mqttTLS)) {
                        this.tLSCapable = mqttTLS;
                    }
                    return true;
                })
            ),
            this.isDeviceCapable(DeviceCapabilitiesEnum.mqttLiveCounts).pipe(
                map(mqttLiveCounts => {
                    if (!this.isNullOrUndefined(mqttLiveCounts)) {
                        this.mqttLiveCounts = mqttLiveCounts;
                    }
                    return true;
                })
            ),
            this.isDeviceCapable(DeviceCapabilitiesEnum.mQTTRegisterFilter).pipe(
                map(registerFilterCapable => {
                    if (!this.isNullOrUndefined(registerFilterCapable)) {
                        this.registerFilterCapable = registerFilterCapable;
                    }
                    return true;
                })
            ),
            this.isDeviceCapable(DeviceCapabilitiesEnum.mqttEventStream).pipe(
                map(eventStreamCapable => {
                    if (!this.isNullOrUndefined(eventStreamCapable)) {
                        this.mqttEventStreamCapable = eventStreamCapable;
                    }
                    return true;
                })
            ),
            this.isDeviceCapable(DeviceCapabilitiesEnum.multiCert).pipe(
                map(multiCertCap => {
                    if (!this.isNullOrUndefined(multiCertCap)) {
                        this.multiCertCapable = multiCertCap;
                    }
                    return true;
                })
            ),
            this.isDeviceCapable(DeviceCapabilitiesEnum.mQTTRetain).pipe(
                map(retainCap => {
                    if (!this.isNullOrUndefined(retainCap)) {
                        this.retainCapable = retainCap;
                    }
                    return true;
                })
            ),
            this.getCertPairNames(process),
            this._registerService.getRegisters(process).pipe(
                map(result => {
                    this.registers = new RegisterBaseCollectionModel();

                    const filteredData = result.items.filter(r=>r.registerType !== RegisterTypeEnum.compareMaster &&
                                                                r.registerType !== RegisterTypeEnum.occupancyMaster &&
                                                                r.registerType !== RegisterTypeEnum.queueWaitMaster &&
                                                                r.registerType !== RegisterTypeEnum.fifoOccupancyMaster);
                    this.registers.items.push(...filteredData);

                    return true;
                })
            ),
            this._mqttService.getMQTT(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        const publishEntriesLength = result.publishEntries.length;
                        for (let peI = 0; peI < publishEntriesLength; peI++) {
                            const publishEntry = result.publishEntries[peI];
                            if (this.isNullOrUndefined(publishEntry.tLSEnabled)) {
                                publishEntry.setPropertyOriginalValue('tLSEnabled', false);
                            }
                            if (this.isNullOrUndefined(publishEntry.tLSInsecure)) {
                                publishEntry.setPropertyOriginalValue('tLSInsecure', false);
                            }

                            const topicsLength = publishEntry.topics.length;
                            for (let tI = 0; tI < topicsLength; tI++) {
                                const topic = publishEntry.topics[tI];
                                topic.enabled = !this.isNullOrUndefined(topic.topicAddress) && !this.isEmptyOrWhiteSpace(topic.topicAddress) ? true : false;
                                topic.hasPeriod = MQTTTopicIdentifierEnumHelpers.hasPeriod(topic.identifier);
                                topic.hasFilter = MQTTTopicIdentifierEnumHelpers.hasFilter(topic.identifier);
                                topic.commitChanges();
                            }

                            this.addEntryFormGroup(result.publishEntries[peI]);
                        }

                        this.mqtt = result;
                        this.changeTracker.track(this.mqtt);

                        this.setFormArrayEnabledDisable();
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.mqtt.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._mqttService.setMQTT(this.mqtt, process).pipe(
                            map(() => true)
                        );
                    } else {
                        return of(true);
                    }
                }
                )
            )
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    // Init everything
                    this.changeTracker.clear();
                    this.formGroupTracker.clear();

                    this.mqtt = null;
                    this.formGroup = this._formBuilder.group({
                        entries: this._formBuilder.array([]),
                    });
                    this.formGroupTracker.track(this.formGroup);

                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(false);
                }
            })
        );
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.setFormArrayEnabledDisable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.setFormArrayEnabledDisable();
    }

    private getCertPairNames(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.isDeviceCapable(DeviceCapabilitiesEnum.multiCert).pipe(
            flatMap(
                isMultiCert => {
                    if (isMultiCert === true) {
                        return this._securityService.getCertPairNames(process).pipe(
                            map(
                                names => {
                                    this.certPairNames = [];
                                    this.certPairNames.push('');
                                    this.certPairNames = this.certPairNames.concat(names);
                                    return true;
                                }
                            )
                        );
                    } else {
                        return of(true);
                    }
                }
            )
        );
    }

    private setFormArrayEnabledDisable(): void {
        const fArray = this.formGroup.controls.entries as FormArray;
        const length = fArray.length;
        for (let i = 0; i < length; i++) {
            this.setFormGroupEnabledDisable(this.mqtt.publishEntries[i], fArray.at(i) as FormGroup);
        }
    }

    private addBroker(): void {
        const entry = new MQTTPublishConfigEntryModel();
        entry.tLSEnabled = false;
        entry.tLSInsecure = false;
        entry.sSLDir = '';
        entry.credentialsRequired = false;
        entry.credentialsSet = false;

        this.Identifiers.forEach(identifier => {
            if (identifier !== MQTTTopicIdentifierEnum.targets &&
                identifier !== MQTTTopicIdentifierEnum.deviceInfo &&
                identifier !== MQTTTopicIdentifierEnum.events ||
                 (identifier === MQTTTopicIdentifierEnum.targets && this.targetsCapable === true) ||
                 (identifier === MQTTTopicIdentifierEnum.deviceInfo && this.deviceInfoCapable === true) ||
                 (identifier === MQTTTopicIdentifierEnum.events && this.mqttEventStreamCapable === true)) {
                const topic = new MQTTTopicConfigModel();
                topic.enabled = false;
                topic.hasPeriod = MQTTTopicIdentifierEnumHelpers.hasPeriod(identifier);
                topic.topicAddress = MQTTTopicIdentifierEnumHelpers.getIdentifierUrl(identifier, this.hostDevice.serialNumber);
                topic.hasFilter = MQTTTopicIdentifierEnumHelpers.hasFilter(identifier);
                topic.identifier = identifier;

                topic.period = MQTTTopicIdentifierEnumHelpers.getPeriodDefault(identifier);
                this.isDeviceCapable(DeviceCapabilitiesEnum.mqttQOS).subscribe(qosCapable => qosCapable === true ? topic.qOS = MQTTTopicIdentifierEnumHelpers.getQosDefault(identifier) : null);
                this.isDeviceCapable(DeviceCapabilitiesEnum.mQTTRetain).subscribe(retainCapable => retainCapable === true ? topic.retain = MQTTTopicIdentifierEnumHelpers.getRetainDefault(identifier) : null);

                entry.topics.push(topic);
            }
        });
        entry.commitChanges();
        this.addEntryFormGroup(entry);
        this.mqtt.publishEntries.push(entry);
        this.updateSaveAllAction(this);
    }

    private removeBroker(entry: MQTTPublishConfigEntryModel, formArrayIndex: number, formArray?: FormArray): void {
        const fArray = this.isNullOrUndefined(formArray) ? this.formGroup.controls.entries as FormArray : formArray;
        if (!this.isNullOrUndefined(fArray) && formArrayIndex > -1) {
            const entryIndex = this.mqtt.publishEntries.findIndex(i => i.uniqueId === entry.uniqueId);
            if (entryIndex !== -1) {
                this.mqtt.publishEntries.splice(entryIndex, 1);
                fArray.removeAt(formArrayIndex);
            }
        }
        this.updateSaveAllAction(this);
    }

    private addEntryFormGroup(entry: MQTTPublishConfigEntryModel, formArray?: FormArray): void {
        const fArray = this.isNullOrUndefined(formArray) ? this.formGroup.controls.entries as FormArray : formArray;
        if (!this.isNullOrUndefined(fArray)) {
            const formGroup = this.createEntryFormGroup(entry);
            fArray.push(formGroup);
            formGroup.markAllAsTouched();
        }
    }

    private createEntryFormGroup(entry: MQTTPublishConfigEntryModel): FormGroup {
        const formGroup = this._formBuilder.group({
            address: ['', Validators.compose([Validators.required, ValidationValidators.ipAddressOrHostName])],
            port: ['', Validators.compose([Validators.required, ValidationValidators.port])],
            keepAlive: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(3600), Validators.pattern('[0-9]*')])],
            maxHistory: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(30), Validators.pattern('[0-9]*')])],
            maxSize: ['', Validators.compose([Validators.required, Validators.min(128), Validators.max(1048576), Validators.pattern('[0-9]*')])],
            credentialsRequired: ['', Validators.compose([Validators.required])],
            username: ['', Validators.compose([Validators.required])],
            password: ['', Validators.compose([Validators.required])],
            tLSEnabled: ['', Validators.compose([Validators.required])],
            tLSInsecure: ['', Validators.compose([Validators.required])],
            sslDir: ['', []],
            registerFilterEnabled: ['', Validators.compose([Validators.required])],
            topics: this._formBuilder.array(entry.topics.map(topic => this._formBuilder.group({
                    enabled: ['', Validators.compose([Validators.required])],
                    topicAddress: ['', Validators.compose([Validators.required])],
                    period: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(300), Validators.pattern('[0-9]*')])],
                    qOS: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(2), Validators.pattern('[0-9]*')])],
                    retain:['', Validators.compose([Validators.required])],
                    eventFilterEnabled: ['', Validators.compose([Validators.required])]
                }))),
        });
        this.setEntryFormGroupValues(entry, formGroup);
        this.setFormGroupEnabledDisable(entry, formGroup);
        this.addEntryFormGroupSubscriptions(entry, formGroup);

        return formGroup;
    }

    private setEntryFormGroupValues(entry: MQTTPublishConfigEntryModel, formGroup: FormGroup): void {
        if (!this.isNullOrUndefined(entry)) {
            formGroup.setValue({
                address: entry.address,
                port: this.isNullOrUndefined(entry.port) ? 1883 : entry.port,
                keepAlive: this.isNullOrUndefined(entry.keepAlive) ? 60 : entry.keepAlive,
                maxHistory: this.isNullOrUndefined(entry.maxHistory) ? 24 : entry.maxHistory,
                maxSize: this.isNullOrUndefined(entry.maxSize) ? 8192 : entry.maxSize,
                credentialsRequired: entry.credentialsRequired,
                username: this.isNullOrUndefined(entry.credentials) ? null : entry.credentials.username,
                password: this.isNullOrUndefined(entry.credentials) ? null : entry.credentials.password,
                tLSEnabled: entry.tLSEnabled,
                tLSInsecure: entry.tLSInsecure,
                sslDir: entry.sSLDir,
                registerFilterEnabled: this.isRegisterFilterEnabled(entry),
                topics: entry.topics.map(topic => ({
                        enabled: topic.enabled,
                        topicAddress: topic.topicAddress,
                        period: topic.period,
                        qOS: topic.qOS,
                        retain: topic.retain,
                        eventFilterEnabled: this.isEventFilterEnabled(topic)
                    })),
            }, { emitEvent: false });
        }
    }

    private setFormGroupEnabledDisable(entry: MQTTPublishConfigEntryModel, formGroup: FormGroup): void {
        if (!this.isNullOrUndefined(entry) && !this.isNullOrUndefined(formGroup) && !this.isNullOrUndefined(formGroup.controls.topics)) {
            const topicsFormArray = formGroup.controls.topics as FormArray;
            const topicsLength = topicsFormArray.length;
            const options = { emitEvent: false };

            if (this.isReadOnly === false) {
                formGroup.enable(options);
                formGroup.controls.credentialsRequired.enable(options);
                if (this.tLSCapable === true) {
                    formGroup.controls.tLSEnabled.enable(options);
                    formGroup.controls.tLSInsecure.enable(options);

                    if(this.multiCertCapable === true){
                        formGroup.controls.sslDir.enable(options);
                    }
                } else {
                    formGroup.controls.tLSEnabled.disable(options);
                    formGroup.controls.tLSInsecure.disable(options);
                    formGroup.controls.sslDir.disable(options);
                }

                if (formGroup.controls.credentialsRequired.value === true) {
                    if (entry.credentialsSet === true){
                        formGroup.controls.username.disable(options);
                        formGroup.controls.password.disable(options);
                    }
                    else{
                        formGroup.controls.username.enable(options);
                        formGroup.controls.password.enable(options);
                    }
                } else {
                    formGroup.controls.username.disable(options);
                    formGroup.controls.password.disable(options);
                }

                formGroup.controls.registerFilterEnabled.enable(options);


                for (let i = 0; i < topicsLength; i++) {
                    const topicFormGroup = topicsFormArray.at(i) as FormGroup;
                    const topicModel = entry.topics[i];
                    if (topicFormGroup.controls.enabled.value === true) {
                        topicFormGroup.controls.topicAddress.enable(options);

                        if (topicModel.hasPeriod === true) {
                            topicFormGroup.controls.period.enable(options);
                        } else {
                            topicFormGroup.controls.period.disable(options);
                        }
                        if (this.qOSCapable === true) {
                            topicFormGroup.controls.qOS.enable(options);
                        } else {
                            topicFormGroup.controls.qOS.disable(options);
                        }

                        if (this.retainCapable === true) {
                            topicFormGroup.controls.retain.enable(options);
                        }
                        else {
                            topicFormGroup.controls.retain.disable(options);
                        }

                    } else {
                        topicFormGroup.controls.topicAddress.disable(options);
                        topicFormGroup.controls.period.disable(options);
                        topicFormGroup.controls.qOS.disable(options);
                        topicFormGroup.controls.retain.disable(options);
                        topicFormGroup.controls.eventFilterEnabled.disable(options);
                    }
                }
            } else {
                formGroup.disable(options);
                formGroup.controls.credentialsRequired.disable(options);
                formGroup.controls.tLSEnabled.disable(options);
                formGroup.controls.tLSInsecure.disable(options);
                formGroup.controls.sslDir.disable(options);
                formGroup.controls.registerFilterEnabled.disable(options);

                for (let i = 0; i < topicsLength; i++) {
                    const topicFormGroup = topicsFormArray.at(i) as FormGroup;
                    topicFormGroup.disable(options);
                    topicFormGroup.controls.enabled.disable(options);
                }
            }
        }
    }

    private addEntryFormGroupSubscriptions(entry: MQTTPublishConfigEntryModel, formGroup: FormGroup): void {
        this.addSubscription(this.observableHandlerBase(formGroup.controls.credentialsRequired.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            this.setFormGroupEnabledDisable(entry, formGroup);
        }), this.formValuesChangeProcess);

        this.addSubscription(this.observableHandlerBase(formGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            this.updateModelValuesEntryFormGroup(formGroup, entry);
        }), this.formValuesChangeProcess);

        const topicsLength = entry.topics.length;
        const formArray = formGroup.controls.topics as FormArray;
        for (let i = 0; i < topicsLength; i++) {
            const topicFormGroup = formArray.at(i) as FormGroup;
            const topicModel = entry.topics[i];
            this.addSubscription(this.observableHandlerBase(topicFormGroup.controls.enabled.valueChanges, this.formValuesChangeProcess).subscribe(() => {
                if (topicFormGroup.controls.enabled.value === true) {
                    const address = MQTTTopicIdentifierEnumHelpers.getIdentifierUrl(topicModel.identifier, this.hostDevice.serialNumber);
                    topicFormGroup.controls.topicAddress.setValue(address);
                    topicModel.setPropertyOriginalValue('topicAddress', address);
                } else {
                    topicFormGroup.controls.topicAddress.setValue(null);
                    topicModel.setPropertyOriginalValue('topicAddress', null);
                }
                this.setFormGroupEnabledDisable(entry, formGroup);
            }), this.formValuesChangeProcess);
        }
    }

    private updateModelValuesEntryFormGroup(formGroup: FormGroup, entry: MQTTPublishConfigEntryModel): void {
        if (!this.isNullOrUndefined(entry) && this.isReadOnly === false) {
            const formValues = formGroup.value;

            entry.address = this.isNullOrUndefined(formValues.address) ? null : formValues.address;
            entry.port = this.isNullOrUndefined(formValues.port) ? null : formValues.port;
            entry.keepAlive = this.isNullOrUndefined(formValues.keepAlive) ? null : formValues.keepAlive;
            entry.maxHistory = this.isNullOrUndefined(formValues.maxHistory) ? null : formValues.maxHistory;
            entry.maxSize = this.isNullOrUndefined(formValues.maxSize) ? null : formValues.maxSize;
            entry.credentialsRequired = this.isNullOrUndefined(formValues.credentialsRequired) ? false : formValues.credentialsRequired;

            if (entry.credentialsRequired === false) {
                entry.credentials = null;
            } else {
                if (this.isNullOrUndefined(entry.credentials)) {
                    entry.credentials = new MQTTCredentialsModel();
                    entry.credentials.commitChanges();
                }
                entry.credentials.username = this.isNullOrUndefined(formValues.username) ? null : formValues.username;
                entry.credentials.password = this.isNullOrUndefined(formValues.password) ? null : formValues.password;
            }

            entry.tLSEnabled = this.isNullOrUndefined(formValues.tLSEnabled) ? false : formValues.tLSEnabled;
            entry.tLSInsecure = this.isNullOrUndefined(formValues.tLSInsecure) ? false : formValues.tLSInsecure;
            entry.sSLDir = formValues.sslDir;

            const topicsLength = formValues.topics.length;
            for (let i = 0; i < topicsLength; i++) {
                const modelTopic = entry.topics[i];
                const formTopic = formValues.topics[i];

                if (!this.isNullOrUndefined(modelTopic) && !this.isNullOrUndefined(formTopic)) {
                    modelTopic.enabled = this.isNullOrUndefined(formTopic.enabled) ? null : formTopic.enabled;
                    if (modelTopic.enabled === true) {
                        modelTopic.topicAddress = this.isNullOrUndefined(formTopic.topicAddress) ? null : formTopic.topicAddress;
                        modelTopic.period = this.isNullOrUndefined(formTopic.period) ? null : formTopic.period;
                        modelTopic.qOS = this.isNullOrUndefined(formTopic.qOS) ? null : formTopic.qOS;
                        modelTopic.retain = this.isNullOrUndefined(formTopic.retain) ? null: formTopic.retain;
                    } else {
                        modelTopic.topicAddress = '';
                        modelTopic.filter = [];
                    }
                }
            }

            const formArray: FormArray = this.formGroup.controls.entries as FormArray;

            formArray.controls.forEach(c => {
                c.markAllAsTouched();
            });

            this.formGroup.controls.entries.updateValueAndValidity();
            this.formGroup.updateValueAndValidity();
            this.updateSaveAllAction(this);
        }
    }
}
