<div fxLayout="column">
    <shr-table [idPreFix]="'schedules-' + id" class="spacing-bottom" title="Scheduled Recordings"
        (page)="onSchedulesPageChanged($event)" [dataSource]="schedulesDataSource" [filterEnabled]="false"
        [pageIndex]="schedulesPageOptions.page - 1" [recordLength]="schedulesPageOptions.totalResults"
        [pageSize]="schedulesPageOptions.resultsPerPage" [displayedColumns]="schedulesDisplayedColumns"
        [isLoadingData]="getSchedulesPageProcess.isRunning">

        <ng-container matColumnDef="multiSelect" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                <mat-icon style="cursor: pointer;" (click)="schedulesToggleAll()">{{ schedulesAllSelected ?
                'check_box' : 'check_box_outline_blank' }}</mat-icon>
            </th>
            <td mat-cell *matCellDef="let vm" style="width: 35px; text-align: center;">
                <mat-icon (click)="onScheduleSelected(vm)" style="cursor: pointer;">
                    {{ vm.isSelected ? 'check_box' : 'check_box_outline_blank' }}
                </mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="icons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let vm" style="width: 100px; text-align: center;">                
                <shr-fa matTooltip="Syncing to device"
                    *ngIf="vm.groupItem.master.synchronizationState === SynchronizationStateEnum.needsSaving || vm.groupItem.master.synchronizationState === SynchronizationStateEnum.needsDeleting"
                    name="cloud-upload-alt"></shr-fa>
                <shr-fa matTooltip="On Device"
                    *ngIf="vm.groupItem.master.synchronizationState === SynchronizationStateEnum.synchronized" name="cloud"></shr-fa>                
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>State</th>
            <td mat-cell *matCellDef="let vm" style="width: 100px;">
                <ng-template [ngIf]="vm.groupItem.master.recordingState === RecordingStateEnum.isCurrentlyRecording">
                    <span matTooltip="Recording">Recording</span>
                </ng-template>
                <ng-template [ngIf]="vm.processing === true">
                    <span matTooltip="Processing">Processing</span>
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="progress">
            <th mat-header-cell *matHeaderCellDef>Progress</th>
            <td mat-cell *matCellDef="let vm" style="width: 80px; padding-right: 10px; padding-left: 10px;">
                <mat-progress-bar
                    *ngIf="vm.processing === true || vm.groupItem.master.recordingState === RecordingStateEnum.isCurrentlyRecording"
                    mode="indeterminate"></mat-progress-bar>
            </td>
        </ng-container>

        <ng-container matColumnDef="serial">
            <th mat-header-cell *matHeaderCellDef> Serial # </th>
            <td mat-cell *matCellDef="let vm">
                <em-device-serial-link [serial]="vm.groupItem.master.friendlyDeviceSerial" [paths]="['recordings']" viewMode="link">
                </em-device-serial-link>
                <div *ngFor="let node of vm.groupItem.nodes" class="nodeSerial">
                    {{node.friendlyDeviceSerial}} (Node)
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let vm">
                <shr-table-cell [data]="vm.groupItem.master" keyPath="startDateText"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef> Start </th>
            <td mat-cell *matCellDef="let vm">
                <shr-table-cell [data]="vm.groupItem.master" keyPath="startTimeText"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef> Duration </th>
            <td mat-cell *matCellDef="let vm">
                <shr-table-cell [data]="vm.groupItem.master" keyPath="durationText"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="end">
            <th mat-header-cell *matHeaderCellDef> End </th>
            <td mat-cell *matCellDef="let vm">
                <shr-table-cell [data]="vm.groupItem.master" keyPath="endTimeText"></shr-table-cell>
            </td>
        </ng-container>
    </shr-table>

    <shr-table [idPreFix]="'recordings-' + id" class="spacing-bottom" title="Recordings" [serverPage]="true"
        [filterEnabled]="false" (page)="onSessionsPageChanged($event)" [displayedColumns]="sessionsDisplayedColumns"
        [dataSource]="sessionsDataSource" [pageIndex]="sessionsPageOptions.page - 1"
        [recordLength]="sessionsPageOptions.totalResults" [pageSize]="sessionsPageOptions.resultsPerPage"
        [isLoadingData]="getSessionsPageProcess.isRunning">
        <ng-template #shrTableHeaderPreFilterActions let-vm>
            <div class="actions-row" fxLayout="row" fxLayoutAlign="start center">
                <div class="spacing-right">
                    <mat-slide-toggle (change)="onSessionsShowHideChanged($event, 'sessionsShowLocal')"
                        [checked]="sessionsShowLocal">{{sessionsShowLocal === true ? 'Hide' :'Show'}} Local
                    </mat-slide-toggle>
                </div>
                <div class="spacing-right">
                    <mat-slide-toggle (change)="onSessionsShowHideChanged($event, 'sessionsShowOnDevice')"
                        [checked]="sessionsShowOnDevice">{{sessionsShowOnDevice === true ? 'Hide' :'Show'}} On Device
                    </mat-slide-toggle>
                </div>
                <div>
                    <mat-slide-toggle (change)="onSessionsShowHideChanged($event, 'sessionsShowSyncing')"
                        [checked]="sessionsShowSyncing">{{sessionsShowSyncing === true ? 'Hide' :'Show'}} Syncing
                    </mat-slide-toggle>
                </div>
            </div>
        </ng-template>

        <ng-container matColumnDef="multiSelect" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                <mat-icon style="cursor: pointer;" (click)="sessionsToggleAll()">{{ sessionsAllSelected ?
                'check_box' : 'check_box_outline_blank' }}</mat-icon>
            </th>
            <td mat-cell *matCellDef="let vm" style="width: 35px; text-align: center;">
                <mat-icon style="cursor: pointer;" (click)="onSessionSelected(vm)">
                    {{ vm.isSelected ? 'check_box' : 'check_box_outline_blank' }}
                </mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="icons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let vm" style="width: 100px; text-align: center;">
                <div>
                    <shr-fa matTooltip="Local" *ngIf="vm.groupItem.downloadGroupDownloaded === true"
                        name="hdd">
                    </shr-fa>
                    <shr-fa style="color: orange;" matTooltip="Partial"
                        *ngIf="vm.groupItem.downloadGroupCancelled === true || vm.groupItem.downloadGroupPaused === true || vm.groupItem.downloadGroupDownloading === true || vm.groupItem.downloadGroupRequiresDownloading === true"
                        name="cloud-download-alt"></shr-fa>
                    <shr-fa matTooltip="On Device"
                        *ngIf="vm.groupItem.syncGroupNotLocalOnly" name="cloud">
                    </shr-fa>
                    <shr-fa class="error" matTooltip="Corrupt"
                        *ngIf="vm.groupItem.downloadGroupAnyCorruption === true" name="exclamation-circle">
                    </shr-fa>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>State</th>
            <td mat-cell *matCellDef="let vm" style="width: 100px;">
                <ng-template
                    [ngIf]="vm.processing !== true && vm.groupItem.master.synchronizationState === SynchronizationStateEnum.synchronized">
                    <ng-template [ngIf]="vm.groupItem.master.downloadState === VideoDownloadStateEnum.requiresDownloading">
                        <span matTooltip="Queued">Queued</span>
                    </ng-template>
                    <ng-template [ngIf]="vm.groupItem.master.downloadState === VideoDownloadStateEnum.downloading">
                        <span matTooltip="Synchronising">Synchronising</span>
                    </ng-template>
                    <ng-template [ngIf]="vm.groupItem.master.downloadState === VideoDownloadStateEnum.paused">
                        <span matTooltip="Paused">Paused</span>
                    </ng-template>
                </ng-template>
                <ng-template
                    [ngIf]="vm.processing === true || vm.groupItem.master.synchronizationState === SynchronizationStateEnum.needsSaving || vm.groupItem.master.synchronizationState === SynchronizationStateEnum.needsDeleting">
                    <span matTooltip="Processing">Processing</span>
                </ng-template>

                <div *ngFor="let node of vm.groupItem.nodes" style="margin-top: 5px;">
                    <ng-template
                        [ngIf]="vm.processing !== true && node.videoDataEntry?.synchronizationState === SynchronizationStateEnum.synchronized">
                        <ng-template [ngIf]="node.videoDataEntry?.downloadState === VideoDownloadStateEnum.requiresDownloading">
                            <span matTooltip="Queued">Queued</span>
                        </ng-template>
                        <ng-template [ngIf]="node.videoDataEntry?.downloadState === VideoDownloadStateEnum.downloading">
                            <span matTooltip="Synchronising">Synchronising</span>
                        </ng-template>
                        <ng-template [ngIf]="node.videoDataEntry?.downloadState === VideoDownloadStateEnum.paused">
                            <span matTooltip="Paused">Paused</span>
                        </ng-template>
                    </ng-template>
                    <ng-template
                        [ngIf]="vm.processing === true || node.videoDataEntry?.synchronizationState === SynchronizationStateEnum.needsSaving || node.videoDataEntry?.synchronizationState === SynchronizationStateEnum.needsDeleting">
                        <span matTooltip="Processing">Processing</span>
                    </ng-template>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="progress">
            <th mat-header-cell *matHeaderCellDef>Progress</th>
            <td mat-cell *matCellDef="let vm" style="width: 80px; padding-right: 10px; padding-left: 10px;">
                <mat-progress-bar *ngIf="vm.groupItem.master.downloadState === VideoDownloadStateEnum.downloading" mode="determinate"
                    [value]="vm.groupItem.master.percentageDownloaded"></mat-progress-bar>
                <mat-progress-bar
                    *ngIf="vm.processing === true || vm.groupItem.master.downloadState === VideoDownloadStateEnum.requiresDownloading"
                    mode="indeterminate"></mat-progress-bar>

                <div *ngFor="let node of vm.groupItem.nodes" style="margin-top: 5px;">
                    <mat-progress-bar *ngIf="node.videoDataEntry?.downloadState === VideoDownloadStateEnum.downloading" mode="determinate"
                        [value]="node.videoDataEntry?.percentageDownloaded"></mat-progress-bar>
                    <mat-progress-bar
                        *ngIf="vm.processing === true || node.videoDataEntry?.downloadState === VideoDownloadStateEnum.requiresDownloading"
                        mode="indeterminate"></mat-progress-bar>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="serial">
            <th mat-header-cell *matHeaderCellDef> Serial # </th>
            <td mat-cell *matCellDef="let vm">
                <em-device-serial-link [serial]="vm.groupItem.master.friendlyDeviceSerial" [paths]="['recordings']" viewMode="link">
                </em-device-serial-link>
                <div *ngFor="let node of vm.groupItem.nodes" class="nodeSerial">
                    {{node.friendlyDeviceSerial}} (Node) {{isNullOrUndefined(node.videoDataEntry) === true ? ' (Not Present)' : ''}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let vm">
                <shr-table-cell [data]="vm.groupItem.master" keyPath="startDateText"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef> Start </th>
            <td mat-cell *matCellDef="let vm">
                <shr-table-cell [data]="vm.groupItem.master" keyPath="startTimeText"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef> Duration </th>
            <td mat-cell *matCellDef="let vm">
                <shr-table-cell [data]="vm.groupItem.master" keyPath="durationText"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="end">
            <th mat-header-cell *matHeaderCellDef> End </th>
            <td mat-cell *matCellDef="let vm">
                <shr-table-cell [data]="vm.groupItem.master" keyPath="endTimeText"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="thumb">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let vm" style="width: 75px; text-align: center;">
                <img class="thumb"
                    [src]="isNullOrUndefined(vm.groupItem.master.imageDataUri) ? 'assets/blank.png' : vm.groupItem.master.imageDataUri">
            </td>
        </ng-container>
    </shr-table>

    <mat-toolbar class="mat-elevation-z4 control-bar">
        <button mat-mini-fab color="primary" style="margin-right: 10px;" matTooltip="Synchronise Recordings"
            [disabled]="synchroniseRecordingsEnabled === false" (click)="synchroniseRecordings()">
            <shr-fa name="download"></shr-fa>
        </button>
        <button mat-mini-fab *ngIf="installerUser === true" color="primary" style="margin-right: 10px;"
            matTooltip="Pause Synchronizations" [disabled]="pauseSynchronizationsEnabled === false"
            (click)="pauseSynchronizations()">
            <shr-fa name="pause"></shr-fa>
        </button>
        <button mat-mini-fab *ngIf="installerUser === true" color="primary" style="margin-right: 10px;"
            matTooltip="Resume Synchronizations" [disabled]="resumeSynchronizationsEnabled === false"
            (click)="resumeSynchronizations()">
            <shr-fa name="play"></shr-fa>
        </button>
        <button mat-mini-fab *ngIf="installerUser === true" color="primary" style="margin-right: 10px;"
            matTooltip="Cancel Synchronizations" [disabled]="cancelSynchronizationsEnabled === false"
            (click)="cancelSynchronizations()">
            <shr-fa name="ban"></shr-fa>
        </button>
        <button mat-mini-fab *ngIf="installerUser === true" color="primary" style="margin-right: 30px;"
            matTooltip="Delete Synchronizations" [disabled]="deleteSynchronizationsEnabled === false"
            (click)="deleteSynchronizations()">
            <shr-fa name="eraser"></shr-fa>
        </button>
        <button mat-mini-fab *ngIf="installerUser === true" color="primary" style="margin-right: 30px;"
            matTooltip="Delete Recordings / Schedules" [disabled]="deleteRecordingsSchedulesEnabled === false"
            (click)="deleteRecordingsSchedules()">
            <shr-fa name="trash"></shr-fa>
        </button>
        <button mat-mini-fab color="primary" style="margin-right: 30px;" matTooltip="Validate selected"
            (click)="validateSelected()" [disabled]="validateSelectedEnabled === false">
            <shr-fa name="video"></shr-fa>
        </button>
        <button mat-mini-fab color="primary" style="margin-right: 3S0px;" matTooltip="Download selected"
            [disabled]="downloadSelectedEnabled === false" (click)="downloadSelected()">
            <shr-fa name="cloud-download-alt"></shr-fa>
        </button>
    </mat-toolbar>
</div>
