import { AfterContentInit, Injector, OnDestroy, OnInit, Renderer2, Directive } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';
import { ResizedEvent } from '@shared/directive/resized/Resized.Directive.ResizedEvent';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { IFillHeightParent } from '@shared/interface/IFillHeightParent';
import { FillHeightUtility } from '@shared/utility/FillHeightParent.Utility';
import { MatDialog } from '@angular/material/dialog';

@Directive()
export abstract class FillHeightParentComponentBase extends BaseComponent implements IFillHeightParent, OnInit, OnDestroy, AfterContentInit {
    public fillHeightComponent: IFillHeight;
    public height: number = 0;
    public viewInit: boolean = false;

    protected constructor(
        private readonly _rendererBase: Renderer2,
        private readonly _fillInjector: Injector,
        private readonly _fillDialog?: MatDialog) {
        super(_fillInjector, _fillDialog);
    }

    public ngAfterContentInit(): void {
        this.viewInit = true;
        this.setComponentHeight();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.fillHeightComponent = null;
        this.height = 0;
        this.viewInit = false;
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public onActivate(event: any): void {
        if (!this.isNullOrUndefined(event.mainContent)) {
            this.fillHeightComponent = event as IFillHeight;
            this.setComponentHeight();
        } else {
            this.fillHeightComponent = null;
        }
    }

    public onContentResize(event: ResizedEvent): void {
        this.setComponentHeight();
    }

    public setComponentHeight(): void {
        FillHeightUtility.setComponentHeight(this, this._rendererBase);
    }
}
