import { Component, HostBinding, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { DiscoveredDeviceRegisterModel } from '@rift/models/restapi/DiscoveredDeviceRegister.Model';
import { InputSourceModel } from '@rift/models/restapi/InputSource.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { WideTrackerService } from '@rift/service/data/widetracker/WideTracker.Service';
import { RegisterConfig } from '@rift/service/device/auto-config/IRegisterConfig';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

export class RemoteDeviceSelectDialogData {
    public constructor(public readonly registerConfig: RegisterConfig<any>, public readonly newRegister: RegisterBaseModel) {
    }
}

export class RemoteDeviceSelectDialogResult {
    public constructor(public readonly inputSources: InputSourceModel[]) {
    }
}

export interface IRemoteDevice {
    serial: string;
    ip: string;
    selected: boolean;
    registersValid: boolean;
    registers: DiscoveredDeviceRegisterModel[];
}

@Component({
    selector: 'rift-auto-config-remote-device-select-dialog',
    templateUrl: './RemoteDeviceSelect.Dialog.Component.html',
    styleUrls: ['./RemoteDeviceSelect.Dialog.Component.scss']
})
export class AutoConfigRemoteDeviceSelectDialogComponent extends RiftBaseComponent implements ILoadDate {
    public static className: string = 'AutoConfigRemoteDeviceSelectDialogComponent';

    public isNullOrUndefined = isNullOrUndefined;

    @HostBinding()
    public id: string = 'rift-ok-cancel-dialog';

    public remoteDevices: IRemoteDevice[];
    public inputSources: InputSourceModel[];
    public isValid: boolean = false;

    public constructor(
        private readonly _wideTrackerService: WideTrackerService,
        @Inject(MAT_DIALOG_DATA) public readonly data: RemoteDeviceSelectDialogData,
        private readonly _dialogRef: MatDialogRef<AutoConfigRemoteDeviceSelectDialogComponent>,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess(AutoConfigRemoteDeviceSelectDialogComponent.className, this.loadDataProcessText);

        this.initConnectionState();
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._wideTrackerService.discoveredDevices(process).pipe(
                map((discoveredDevices) => {
                    this.remoteDevices = [];

                    const discoveredDevicesLength = discoveredDevices.items.length;
                    for (let ddI = 0; ddI < discoveredDevicesLength; ddI++) {
                        const discoveredDevice = discoveredDevices.items[ddI];

                        if (this.data.registerConfig.inputSources.every(is => discoveredDevice.registers.some(ddr => ddr.registerName === is.registerName))) {
                            this.remoteDevices.push({
                                serial: discoveredDevice.serialNumber,
                                ip: discoveredDevice.iPAddress,
                                selected: false,
                                registers: discoveredDevice.registers,
                                registersValid: true,
                            });
                        }
                    }

                    return true;
                })
            )
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public deviceSelected(event: MatSelectionListChange): void {
        this.inputSources = [];

        const remoteDevicesLength = event.source.selectedOptions.selected.length;
        for (let rdI = 0; rdI < remoteDevicesLength; rdI++) {
            const remoteDevice: IRemoteDevice = event.source.selectedOptions.selected[rdI].value;

            const inputSource = new InputSourceModel();
            inputSource.deviceSerial = remoteDevice.serial;
            inputSource.registers = [];

            const remoteRegistersLength = remoteDevice.registers.length;
            for (let rdrI = 0; rdrI < remoteRegistersLength; rdrI++) {
                const remoteRegister = remoteDevice.registers[rdrI];

                const inputSourcesLength = this.data.registerConfig.inputSources.length;
                for (let isI = 0; isI < inputSourcesLength; isI++) {
                    const rcInputSource = this.data.registerConfig.inputSources[isI];
                    if (remoteRegister.registerName === rcInputSource.registerName) {
                        inputSource.registers.push(remoteRegister.registerUUID);
                    }
                }
            }

            if (inputSource.registers.length > 0) {
                this.inputSources.push(inputSource);
            }
        }
    }

    public continue(): void {
        this._dialogRef.close(new RemoteDeviceSelectDialogResult(this.inputSources));
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }
}
