import { AfterViewInit, Component, ElementRef, HostBinding, Injector, Input, NgZone, OnDestroy, ViewChild, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { Device } from '@rift/components/shared/viewport/devices/Device';
import { DeviceCollection } from '@rift/components/shared/viewport/devices/DeviceCollection';
import { Grid } from '@rift/components/shared/viewport/grid/Grid';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { UnitsOfMeasurementService } from '@rift/service/unitsofmeasurement/UnitsOfMeasurement.Service';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { PointUtility } from '@shared/utility/Point.Utility';
import { fromEvent, Observable, Subject, Subscription, zip } from 'rxjs';
import { MAX_CANVAS_FPS, GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';
import { ViewPortModeEnum } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { AlignmentAlphaChanged, XPositionChanged, YawChanged, YPositionChanged } from './Settings.Counting.Alignment.DeviceList.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';

export class AlignDeviceFrame{
    public serialNumber: string;
    public frame: string;
    public alpha: number;
}

@Component({
    selector: 'rift-multi-unit-alignment',
    templateUrl: './Settings.Counting.Alignment.Component.html',
    styleUrls: ['./Settings.Counting.Alignment.Component.scss'],
})
export class MultiUnitAlignmentComponent extends RiftBaseComponent implements AfterViewInit, OnDestroy {
    public static className: string = 'MultiUnitAlignmentComponent';

    @Input()
    public fovClickEnabled: boolean = false;

    public addDevicesProcess: ProcessMonitorServiceProcess;
    public dOMMouseScrollProcess: ProcessMonitorServiceProcess;

    @ViewChild('canvas', { static: true })
    public canvas: ElementRef<HTMLCanvasElement>;
    public loadQueueLoadedProcess: ProcessMonitorServiceProcess;
    public mouseDownProcess: ProcessMonitorServiceProcess;
    public mousewheelProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'rift-multi-unit-alignment';

    public onRequireStageUpdate: Subject<void> = new Subject<void>();
    public pressMoveProcess: ProcessMonitorServiceProcess;
    public pressUpProcess: ProcessMonitorServiceProcess;
    public requireStageUpdateProcess: ProcessMonitorServiceProcess;
    public getSnapshotProcess: ProcessMonitorServiceProcess;
    public saveChangesProcess: ProcessMonitorServiceProcess;

    private _dOMMouseScrollSub: Subscription = null;
    private _devices: DeviceCollection = null;
    private _grid: Grid = null;
    private _height: number = 0;
    private _isMouseDown: boolean = false;
    private _lastDragLocation: createjs.Point = null;
    private _mouseCordsContainer: createjs.Container;
    private _mouseCordsShape: createjs.Shape;
    private _mouseCordsText: createjs.Text;
    private _mouseDisabled: boolean = false;
    private _mouseDownSub: Subscription = null;
    private _mouseWheelSub: Subscription = null;
    private _pinchDistance: number = null;
    private _pressMoveSub: Subscription = null;
    private _pressUpSub: Subscription = null;
    private _showMouseCords: boolean = false;
    private _stage: createjs.StageGL = null;
    private _stageUpdateRequired: boolean = false;
    private _tickerListener: Function = null;
    private _unitOfMeasurement: UnitsOfMeasurementEnum = UnitsOfMeasurementEnum.metric;
    private _width: number = 0;
    private _zoomContainer: createjs.Container = new createjs.Container();
    private _zoomHeight: number = Grid.Height;
    private _zoomRatio: number = 1;
    private _zoomWidth: number = Grid.Width;
    private _devicesFrame: Array<AlignDeviceFrame> = [];
    private readonly _ZoomIncrement: number = 0.08;
    private readonly _ZoomMax: number = 3.8;
    private readonly _ZoomMin: number = 0.4;

    public constructor(
        private readonly _renderBase: Renderer2,
        private readonly _unitsOfMeasurementService: UnitsOfMeasurementService,
        private readonly _dialog: MatDialog,
        private readonly _elementRef: ElementRef,
        private readonly _loadQueue: ViewPortLoadQueueService,
        private readonly _zone: NgZone,
        private readonly _deviceService: DeviceService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._zone.runOutsideAngular(() => {
            this.loadQueueLoadedProcess = this.processMonitorService.getProcess(MultiUnitAlignmentComponent.className, 'Load queue loaded');
            this.requireStageUpdateProcess = this.processMonitorService.getProcess(MultiUnitAlignmentComponent.className, 'Require stage update');
            this.loadDataProcess = this.processMonitorService.getProcess(MultiUnitAlignmentComponent.className, this.loadDataProcessText);
            this.addDevicesProcess = this.processMonitorService.getProcess(MultiUnitAlignmentComponent.className, 'Add Devices');
            this.mousewheelProcess = this.processMonitorService.getProcess(MultiUnitAlignmentComponent.className, 'Mouse wheel');
            this.dOMMouseScrollProcess = this.processMonitorService.getProcess(MultiUnitAlignmentComponent.className, 'DOM Mouse Scroll');
            this.mouseDownProcess = this.processMonitorService.getProcess(MultiUnitAlignmentComponent.className, 'Mouse Down');
            this.pressUpProcess = this.processMonitorService.getProcess(MultiUnitAlignmentComponent.className, 'Press Up');
            this.pressMoveProcess = this.processMonitorService.getProcess(MultiUnitAlignmentComponent.className, 'Press Move');
            this.getSnapshotProcess = this.processMonitorService.getProcess(MultiUnitAlignmentComponent.className, 'Get Snapshot');
            this.saveChangesProcess = this.processMonitorService.getProcess(MultiUnitAlignmentComponent.className, 'Save Changes');

            this._devices = new DeviceCollection(this._zone, this._loadQueue);
            this._grid = new Grid(this._zone, this._loadQueue);

            this.addSubscription(this.observableHandlerBase(this._grid.requireStageUpdate, this.requireStageUpdateProcess).subscribe(() => this.requireStageUpdate()), this.requireStageUpdateProcess);
            this.addSubscription(this.observableHandlerBase(this._devices.requireStageUpdate, this.requireStageUpdateProcess).subscribe(() => this.requireStageUpdate()), this.requireStageUpdateProcess);

            this._grid.mode = ViewPortModeEnum.view;
            this._devices.mode = ViewPortModeEnum.view;

            this._zoomContainer.addChild(this._grid.container);
            this._zoomContainer.addChild(this._devices.container);

            createjs.Ticker.framerate = MAX_CANVAS_FPS;

            if (this._loadQueue.isLoaded === true) {
                this.onLoadQueueComplete();
            } else {
                this.addSubscription(this.observableHandlerBase(this._loadQueue.loaded, this.loadQueueLoadedProcess).subscribe(() => this.onLoadQueueComplete()), this.loadQueueLoadedProcess);
            }
        });

        this.initConnectionState();
    }

    public addDevices(devices: Array<DeviceModel>): Array<Device> {
        return this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(devices)) {
                this._devicesFrame = [];

                const addDevicesLength = devices.length;
                for (let i = 0; i < addDevicesLength; i++) {
                    this._devices.push(new Device(this._zone, this._loadQueue, devices[i]));
                }

                const deviceObs: Observable<boolean>[] = [];

                this.devices.forEach(device => {
                    device.video.mode = ViewPortModeEnum.view;
                    device.video.devices = [device];
                    device.video.visible = true;
                    device.showFieldOfView = false;
                    device.video.hullScaleFactor = 2.0;

                    this.addSubscription(this.observableHandlerBase(device.video.requireStageUpdate, this.addDevicesProcess).subscribe(() => this.requireStageUpdate()));

                    this._zoomContainer.addChildAt(device.video.container, this._zoomContainer.getChildIndex(this._grid.container) + 1);

                    deviceObs.push(this.getDeviceSnapshot(device));

                    this.changeTracker.track(device.deviceModel);
                    this.changeTracker.track(device.deviceModel.gVector);
                });

                let dialogRef: MatDialogRef<PleaseWaitDialogComponent, any> = null;
                this._zone.run(()=>{
                    dialogRef = this.openPleaseWaitLoadingDialog();
                });

                this.addSubscription(this.observableHandlerBase(zip(...deviceObs), this.getSnapshotProcess).subscribe(()=>{
                    this._zone.run(()=>{
                        dialogRef.close();
                    });
                }));

                return this._devices;
            }
        });
    }

    public saveChanges(): void{
        const deviceObs: Observable<boolean>[] = [];

        this.devices.forEach(d => {
            const result = this.updateDevice(d);

            deviceObs.push(result);
        });

        const savingDialog = this.openPleaseWaitSavingDialog();

        this.addSubscription(this.observableHandlerBase(zip(...deviceObs), this.saveChangesProcess).subscribe(()=>{
            this._zone.run(()=>{
                savingDialog.close();
                this.changeTracker.commitChanges();
            });
        }));
    }

    public separateDevices(): void {
        this.addSubscription(this.openOkCancelDialog('Separate Devices', 'Separating devices will organise them into a grid, any existing adjustments will be lost, do you want to continue?', true).afterClosed().subscribe(result => {
            if (!this.isNullOrUndefined(result) && result.ok === true) {
                let xStart = 0;
                let yStart = 0;
                let devicesProcessed = 0;

                this.devices.forEach((device) => {
                    // Set the device position based on xStart yStart
                    const dev = this._devicesFrame.find(d => d.serialNumber === device.deviceModel.serialNumber);
                    const xDiff = xStart - device.deviceModel.x;
                    const yDiff = yStart - device.deviceModel.y;

                    device.deviceModel.x = xStart;
                    device.deviceModel.y = yStart;

                    device.deviceModel.outerCoverage.forEach(m => {
                        m.x += xDiff;
                        m.y += yDiff;
                    });

                    device.deviceModel.innerCoverage.forEach(m => {
                        m.x += xDiff;
                        m.y += yDiff;
                    });

                    device.videoSettings?.update(device.deviceModel.outerCoverage, device.deviceModel.innerCoverage, {x: device.deviceModel.gVector.x, y: device.deviceModel.gVector.y, z: device.deviceModel.gVector.z, yaw: device.deviceModel.gVector.yaw, device: device.deviceModel.serialNumber });
                    device.rebuildFieldOfViewSprite(device.deviceModel.outerCoverage, device.deviceModel.innerCoverage);
                    device.update();
                    device.showFieldOfView = false;

                    device.video.visible = false;
                    device.video.visible = true;
                    device.video.setCurrentFrame({data: dev.frame}, dev.alpha);

                    devicesProcessed++;

                    // Update xStart yStart to move along the devices
                    if (devicesProcessed % 4 === 0) {
                        const fovMaxY = Math.max(...device.deviceModel.outerCoverage.map(v => v.y));
                        const fovMinY = Math.min(...device.deviceModel.outerCoverage.map(v => v.y));

                        yStart -= Math.floor(((fovMaxY - fovMinY) * 2));
                        yStart -= 150;
                    }
                    else {
                        const fovMaxX = Math.max(...device.deviceModel.outerCoverage.map(v => v.x));
                        const fovMinX = Math.min(...device.deviceModel.outerCoverage.map(v => v.x));

                        xStart += Math.floor(((fovMaxX - fovMinX) * 2));
                        xStart += 150;
                    }
                });

                this.requireStageUpdate();
            }
        }));
    }

    public toggleVideoVisibilty(device: Device){
        const dev = this._devicesFrame.find(d=>d.serialNumber === device.deviceModel.serialNumber);

        if (!isNullOrUndefined(dev)){
            device.video.visible = !device.video.visible;
            device.video.setCurrentFrame({data: dev.frame}, dev.alpha);
            this.requireStageUpdate();
        }
    }

    public alphaChanged(e: AlignmentAlphaChanged){
        const dev = this._devicesFrame.find(d=>d.serialNumber === e.device.deviceModel.serialNumber);

        if (!isNullOrUndefined(dev)){
            dev.alpha = e.alpha;
            e.device.video.setCurrentFrame({data: dev.frame}, e.alpha);
            this.requireStageUpdate();
        }
    }

    public xPosChanged(e: XPositionChanged){
        const dev = this._devicesFrame.find(d=>d.serialNumber === e.device.deviceModel.serialNumber);

        if (!isNullOrUndefined(dev)){
            const diff = e.x - e.device.deviceModel.x;
            e.device.deviceModel.x = e.x;
            e.device.deviceModel.outerCoverage.forEach(m => {
                m.x += diff;
            });

            e.device.deviceModel.innerCoverage.forEach(m => {
                m.x += diff;
            });

            e.device.videoSettings?.update(e.device.deviceModel.outerCoverage, e.device.deviceModel.innerCoverage, {x: e.device.deviceModel.gVector.x, y: e.device.deviceModel.gVector.y, z: e.device.deviceModel.gVector.z, yaw: e.device.deviceModel.gVector.yaw, device: e.device.deviceModel.serialNumber });
            e.device.rebuildFieldOfViewSprite(e.device.deviceModel.outerCoverage, e.device.deviceModel.innerCoverage);
            e.device.update();
            e.device.showFieldOfView = false;

            e.device.video.visible = false;
            e.device.video.visible = true;
            e.device.video.setCurrentFrame({data: dev.frame}, dev.alpha);

            this.requireStageUpdate();
        }
    }

    public yPosChanged(e: YPositionChanged){
        const dev = this._devicesFrame.find(d=>d.serialNumber === e.device.deviceModel.serialNumber);

        if (!isNullOrUndefined(dev)){
            const diff = e.y - e.device.deviceModel.y;
            e.device.deviceModel.y = e.y;
            e.device.deviceModel.outerCoverage.forEach(m => {
                m.y += diff;
            });

            e.device.deviceModel.innerCoverage.forEach(m => {
                m.y += diff;
            });

            e.device.videoSettings?.update(e.device.deviceModel.outerCoverage, e.device.deviceModel.innerCoverage, {x: e.device.deviceModel.gVector.x, y: e.device.deviceModel.gVector.y, z: e.device.deviceModel.gVector.z, yaw: e.device.deviceModel.gVector.yaw, device: e.device.deviceModel.serialNumber });
            e.device.rebuildFieldOfViewSprite(e.device.deviceModel.outerCoverage, e.device.deviceModel.innerCoverage);
            e.device.update();
            e.device.showFieldOfView = false;

            e.device.video.visible = false;
            e.device.video.visible = true;
            e.device.video.setCurrentFrame({data: dev.frame}, dev.alpha);

            this.requireStageUpdate();
        }
    }

    public yawChanged(e: YawChanged){
        const dev = this._devicesFrame.find(d=>d.serialNumber === e.device.deviceModel.serialNumber);

        if (!isNullOrUndefined(dev)){
            const diff = e.yaw - e.device.deviceModel.gVector.yaw;
            e.device.deviceModel.gVector.yaw = e.yaw;
            e.device.deviceModel.outerCoverage.forEach(m => {
                const translateToOriginX = m.x - e.device.deviceModel.x;
                const translateToOriginY = m.y - e.device.deviceModel.y;

                const newX = (translateToOriginX * Math.cos(diff)) - (translateToOriginY * Math.sin(diff));
                const newY = (translateToOriginX * Math.sin(diff)) + (translateToOriginY * Math.cos(diff));

                const translateToPosX = newX + e.device.deviceModel.x;
                const translateToPosY = newY + e.device.deviceModel.y;

                m.x = translateToPosX;
                m.y = translateToPosY;
            });

            e.device.deviceModel.innerCoverage.forEach(m => {
                const translateToOriginX = m.x - e.device.deviceModel.x;
                const translateToOriginY = m.y - e.device.deviceModel.y;

                const newX = (translateToOriginX * Math.cos(diff)) - (translateToOriginY * Math.sin(diff));
                const newY = (translateToOriginX * Math.sin(diff)) + (translateToOriginY * Math.cos(diff));

                const translateToPosX = newX + e.device.deviceModel.x;
                const translateToPosY = newY + e.device.deviceModel.y;

                m.x = translateToPosX;
                m.y = translateToPosY;
            });

            e.device.videoSettings?.update(e.device.deviceModel.outerCoverage, e.device.deviceModel.innerCoverage, {x: e.device.deviceModel.gVector.x, y: e.device.deviceModel.gVector.y, z: e.device.deviceModel.gVector.z, yaw: e.device.deviceModel.gVector.yaw, device: e.device.deviceModel.serialNumber });
            e.device.rebuildFieldOfViewSprite(e.device.deviceModel.outerCoverage, e.device.deviceModel.innerCoverage);
            e.device.update();
            e.device.showFieldOfView = false;

            e.device.video.visible = false;
            e.device.video.visible = true;
            e.device.video.setCurrentFrame({data: dev.frame}, dev.alpha);

            this.requireStageUpdate();
        }
    }

    public deviceSelected(device: Device){
        const dev = this._devicesFrame.find(d=>d.serialNumber === device.deviceModel.serialNumber);

        if (!isNullOrUndefined(dev)){
            this.devices.bringDeviceToFront(device);
            this._zoomContainer.setChildIndex(device.video.container, this._zoomContainer.children.length - 1);

            device.video.setCurrentFrame({data: dev.frame}, dev.alpha);

            this.requireStageUpdate();
        }
    }

    public center(): void {
        this._zone.runOutsideAngular(() => {
            this.zoomToPoint(this._zoomRatio, this.getCenter());
        });
    }

    public clear(): void {
        this._zone.runOutsideAngular(() => {
            this.changeTracker.clear();
            if (!this.isNullOrUndefined(this._devices)) {
                this._devices.clear();
            }
        });
    }

    public get devices(): DeviceCollection {
        return this._devices;
    }

    public fillElement(element: ElementRef | HTMLElement): void {
        this._zone.runOutsideAngular(() => {
            const nativeElement = (element instanceof ElementRef ? element.nativeElement as HTMLElement : element);
            if (!this.isNullOrUndefined(nativeElement)) {
                const computedStyle = getComputedStyle(nativeElement);

                const paddingX = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
                const paddingY = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);

                this.width = nativeElement.offsetWidth - paddingX;
                this.height = nativeElement.offsetHeight - paddingY;

                this.setCanvasWidthHeight();
            }
        });
    }
    public get height(): number {
        return this._height;
    }

    @Input()
    public set height(value: number) {
        this._zone.runOutsideAngular(() => {
            if (this._height !== value) {
                this._height = value;
                this.setCanvasWidthHeight();
            }
        });
    }

    public get mouseDisabled(): boolean {
        return this._mouseDisabled;
    }

    @Input()
    public set mouseDisabled(value: boolean) {
        this._zone.runOutsideAngular(() => {
            if (this._mouseDisabled !== value) {
                this._mouseDisabled = value;
                if (value === true) {
                    this.removeMouseEventHandlers();
                } else {
                    this.addMouseEventHandlers();
                }
            }
        });
    }

    public ngAfterViewInit(): void {
        this._zone.runOutsideAngular(() => {
            this._devices.fovClickEnabled = this.fovClickEnabled;
            this._stage = new createjs.StageGL(this.canvas.nativeElement, { antialias: true });
            this._stage.enableMouseOver(20);
            createjs.Touch.enable(this._stage);

            this._stage.addChild(this._zoomContainer);

            this.addEventHandlers();

            this.setCanvasWidthHeight();
        });
    }

    public ngOnDestroy(): void {
        this._zone.runOutsideAngular(() => {
            this.removeEventHandlers();
            this._grid?.onDestroy();
            this._devices?.onDestroy();

            this._stage.tickEnabled = false;
            this._stage.enableMouseOver(0);
            createjs.Touch.disable(this._stage);
            this._stage.enableDOMEvents(false);
            this._stage.removeAllChildren();
            this._stage.uncache();
            this._stage.purgeTextures();
            this._stage = null;
            this._loadQueue.clearTargetCache();
            this._loadQueue.ngOnDestroy();
        });
        super.ngOnDestroy();
    }

    public requireStageUpdate(): void {
        this._zone.runOutsideAngular(() => {
            this._stageUpdateRequired = true;
            this.onRequireStageUpdate.next();
        });
    }

    public get showMouseCords(): boolean {
        return this._showMouseCords;
    }
    public set showMouseCords(value: boolean) {
        this._showMouseCords = value;
        this.toggleMouseCords();
    }

    public get unitOfMeasurement(): UnitsOfMeasurementEnum {
        return this._unitOfMeasurement;
    }

    @Input()
    public set unitOfMeasurement(value: UnitsOfMeasurementEnum) {
        this._zone.runOutsideAngular(() => {
            if (this._unitOfMeasurement !== value) {
                this._unitOfMeasurement = value;
                this._grid.unitOfMeasurement = value;
            }
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            this._devices.update();
            this._grid.update();
        });
    }

    public get width(): number {
        return this._width;
    }

    @Input()
    public set width(value: number) {
        this._zone.runOutsideAngular(() => {
            if (this._width !== value) {
                this._width = value;
                this.setCanvasWidthHeight();
            }
        });
    }

    public zoomIncrement(increment: number, centerOnMouse: boolean): void {
        this._zone.runOutsideAngular(() => {
            if (this.isNullOrUndefined(increment)) {
                return;
            }

            this.zoom(this._zoomRatio + increment, centerOnMouse);
        });
    }

    public zoomTo(ratio: number, centerOnMouse: boolean): void {
        this._zone.runOutsideAngular(() => {
            if (this.isNullOrUndefined(ratio)) {
                return;
            }

            this.zoom(ratio, centerOnMouse);
        });
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this._tickerListener = createjs.Ticker.on('tick', this.onTick.bind(this));

            if (this.isNullOrUndefined(this._mouseDisabled) || this._mouseDisabled === false) {
                this.addMouseEventHandlers();
            }
        });
    }

    private addMouseEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this._mouseWheelSub = this.addSubscription(this.observableHandlerBase(fromEvent(this._stage.canvas as HTMLElement, 'mousewheel'), this.mousewheelProcess).subscribe(event => { this.onCanvasWheel(event as MouseEvent); }), this.mousewheelProcess);
            this._dOMMouseScrollSub = this.addSubscription(this.observableHandlerBase(fromEvent(this._stage.canvas as HTMLElement, 'DOMMouseScroll'), this.dOMMouseScrollProcess).subscribe(event => { this.onCanvasWheel(event as MouseEvent); }), this.dOMMouseScrollProcess);

            this._mouseDownSub = this.addSubscription(this.observableHandlerBase(this._grid.mouseDown, this.mouseDownProcess).subscribe(event => { this.onGridMouseDown(event); }), this.mouseDownProcess);
            this._pressMoveSub = this.addSubscription(this.observableHandlerBase(this._grid.pressMove, this.pressMoveProcess).subscribe(event => { this.onGridPressMove(event); }), this.pressMoveProcess);
            this._pressUpSub = this.addSubscription(this.observableHandlerBase(this._grid.pressUp, this.pressUpProcess).subscribe(event => { this.onGridPressUp(event); }), this.pressUpProcess);
        });
    }

    private getCenter(): createjs.Point {
        return this._zone.runOutsideAngular(() => {
            let devicesLength = 0;

            if (!this.isNullOrUndefined(this._devices)) {
                devicesLength = this._devices.length;
            }

            return this.getCenterFieldOfView();
        });
    }

    private getCenterFieldOfView(): createjs.Point {
        return this._zone.runOutsideAngular(() => {
            const bounds = this.getFieldOfViewBounds();
            const zoomContainerCenter = this.getZoomContainerCenter();

            if (!this.isNullOrUndefined(bounds) && !this.isNullOrUndefined(zoomContainerCenter)) {
                const aX = ((Grid.Width / 2) - bounds.x);
                const bX = bounds.width - aX;
                const cX = bX - aX;
                const dX = zoomContainerCenter.x - (cX / 2);

                const aY = ((Grid.Height / 2) - bounds.y);
                const bY = bounds.height - aY;
                const cY = bY - aY;
                const dY = zoomContainerCenter.y - (cY / 2);

                return new createjs.Point(dX, dY);
            }
        });
    }

    private getFieldOfViewBounds(): createjs.Rectangle {
        return this._zone.runOutsideAngular(() => {
            const devicesLength = this._devices.length;
            const points: createjs.Point[] = [];
            for (let deviceIndex = 0; deviceIndex < devicesLength; deviceIndex++) {
                const device = this._devices[deviceIndex];
                if (!this.isNullOrUndefined(device)) {
                    const deviceBounds = device.getBounds();
                    if (!this.isNullOrUndefined(deviceBounds)) {
                        points.push(device.container.localToLocal(deviceBounds.x, deviceBounds.y, this._zoomContainer));
                        points.push(device.container.localToLocal(deviceBounds.x, deviceBounds.y + deviceBounds.height, this._zoomContainer));
                        points.push(device.container.localToLocal(deviceBounds.x + deviceBounds.width, deviceBounds.y + deviceBounds.height, this._zoomContainer));
                        points.push(device.container.localToLocal(deviceBounds.x + deviceBounds.width, deviceBounds.y, this._zoomContainer));
                    }
                }
            }

            return PointUtility.getBounds(points);
        });
    }

    private getZoomContainerCenter(): createjs.Point {
        return this._zone.runOutsideAngular(() => {
            if (!this.isNullOrUndefined(this.width) && !this.isNullOrUndefined(this._zoomWidth) && !this.isNullOrUndefined(this.height) && !this.isNullOrUndefined(this._zoomHeight)) {
                return new createjs.Point((this.width / 2) - (this._zoomWidth / 2), (this.height / 2) - (this._zoomHeight / 2));
            }
        });
    }

    private onCanvasWheel(event: MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            event.preventDefault();
            const increment = (Math.max(-1, Math.min(1, ((event as any).wheelDelta || -event.detail))) > 0) ? this._ZoomIncrement : -this._ZoomIncrement;
            this.zoomIncrement(increment, true);
        });
    }

    private onGridMouseDown(event: DisplayItemMouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this._isMouseDown = true;
            this._lastDragLocation = new createjs.Point(event.mouseEvent.stageX, event.mouseEvent.stageY);
        });
    }

    private onGridPressMove(event: DisplayItemMouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (!this.isNullOrUndefined((event.mouseEvent.nativeEvent as any).touches) && (event.mouseEvent.nativeEvent as any).touches.length === 2) {
                const nativeEvent = (event.mouseEvent.nativeEvent as any);
                const touch1 = nativeEvent.touches.item(0);
                const touch2 = nativeEvent.touches.item(1);

                const dx = touch1.clientX - touch2.clientX;
                const dy = touch2.clientY - touch1.clientY;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (!this.isNullOrUndefined(this._pinchDistance)) {
                    if (distance > this._pinchDistance) {
                        this.zoomIncrement(0.02, false);
                    } else if (distance < this._pinchDistance) {
                        this.zoomIncrement(-0.02, false);
                    }
                }

                this._pinchDistance = distance;
            } else {
                if (this._isMouseDown) {
                    this._zoomContainer.x = this._zoomContainer.x - (this._lastDragLocation.x - event.mouseEvent.stageX);
                    this._zoomContainer.y = this._zoomContainer.y - (this._lastDragLocation.y - event.mouseEvent.stageY);

                    this._lastDragLocation = new createjs.Point(event.mouseEvent.stageX, event.mouseEvent.stageY);
                }

                this._pinchDistance = null;
            }

            this.requireStageUpdate();
        });
    }

    private onGridPressUp(event: DisplayItemMouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this._isMouseDown = false;
            this._lastDragLocation = null;
            this._pinchDistance = null;
        });
    }

    private onLoadQueueComplete(): void {
        this._zone.runOutsideAngular(() => {
            this.center();
        });
    }

    private onStageMouseMove(event: createjs.MouseEvent): void {
        const offsetX = 20;
        const offsetY = 20;
        const textPadding = 3;

        const localPoint = new createjs.Point(event.localX, event.localY);
        const gridPoint = this._stage.localToLocal(localPoint.x, localPoint.y, this._grid.container);
        const gpPoint = new createjs.Point(gridPoint.x - (Grid.Width / 2), gridPoint.y - (Grid.Height / 2));

        this._mouseCordsText.x = textPadding;
        this._mouseCordsText.y = textPadding;

        this._mouseCordsContainer.x = localPoint.x + offsetX;
        this._mouseCordsContainer.y = localPoint.y + offsetY;

        this._mouseCordsText.text = `X:${gpPoint.x.toFixed(2)},Y:${gpPoint.y.toFixed(2)}`;
        const textWidth = this._mouseCordsText.getMeasuredWidth();
        const textHeight = this._mouseCordsText.getMeasuredHeight();
        this._mouseCordsText.cache(0, 0, textWidth, textHeight, GLOBAL_CACHE_SCALE);

        const shapeWidth = textWidth + (2 * textPadding);
        const shapeHeight = textHeight + (2 * textPadding);

        this._mouseCordsShape.graphics.beginFill('#ffffff');
        this._mouseCordsShape.graphics.drawRoundRect(0, 0, shapeWidth, shapeHeight, 3);
        this._mouseCordsShape.cache(0, 0, shapeWidth, shapeHeight, GLOBAL_CACHE_SCALE);

        this.requireStageUpdate();
    }

    private onTick(event: createjs.TickerEvent): void {
        this._zone.runOutsideAngular(() => {
            if (this._stageUpdateRequired === true) {
                try {
                    this._stage.update();
                    this._stageUpdateRequired = false;
                } catch { }
            }
        });
    }

    private removeEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            createjs.Ticker.off('tick', this._tickerListener);
        });
    }

    private removeMouseEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            if (!this.isNullOrUndefined(this._mouseWheelSub)) {
                this._mouseWheelSub.unsubscribe();
            }
            if (!this.isNullOrUndefined(this._dOMMouseScrollSub)) {
                this._dOMMouseScrollSub.unsubscribe();
            }

            if (!this.isNullOrUndefined(this._mouseDownSub)) {
                this._mouseDownSub.unsubscribe();
            }
            if (!this.isNullOrUndefined(this._pressMoveSub)) {
                this._pressMoveSub.unsubscribe();
            }
            if (!this.isNullOrUndefined(this._pressUpSub)) {
                this._pressUpSub.unsubscribe();
            }
        });
    }

    private setCanvasWidthHeight(): void {
        this._zone.runOutsideAngular(() => {
            if (!this.isNullOrUndefined(this.canvas) && !this.isNullOrUndefined(this.canvas.nativeElement) && !this.isNullOrUndefined(this.width) && !this.isNullOrUndefined(this.height)) {
                this._renderBase.setProperty(this.canvas.nativeElement, 'width', this.width.toString());
                this._renderBase.setProperty(this.canvas.nativeElement, 'height', this.height.toString());

                if (!this.isNullOrUndefined(this._stage) && this._stage instanceof createjs.StageGL) {
                    this._stage.updateViewport(this.width, this.height);
                }

                this.center();

                this.requireStageUpdate();
            }
        });
    }

    private toggleMouseCords(): void {
        if (this.isNullOrUndefined(this._mouseCordsText) || this.isNullOrUndefined(this._mouseCordsContainer) || this.isNullOrUndefined(this._mouseCordsShape)) {
            this._mouseCordsContainer = new createjs.Container();

            this._mouseCordsShape = new createjs.Shape();
            this._mouseCordsContainer.addChild(this._mouseCordsShape);

            this._mouseCordsText = new createjs.Text('', '14px Arial');
            this._mouseCordsContainer.addChild(this._mouseCordsText);
        }

        if (!this.isNullOrUndefined(this._showMouseCords) && this._showMouseCords === true) {
            this._stage.addChild(this._mouseCordsContainer);
            this._stage.on('stagemousemove', this.onStageMouseMove.bind(this));
        } else {
            this._stage.removeChild(this._mouseCordsContainer);
            this._stage.off('stagemousemove', this.onStageMouseMove.bind(this));
        }
    }

    private zoom(ratio: number, centerOnMouse: boolean): void {
        this._zone.runOutsideAngular(() => {
            const min_ratio = this._ZoomMin;
            const max_ratio = this._ZoomMax;

            if (ratio < min_ratio) {
                ratio = min_ratio;
            }

            if (ratio > max_ratio) {
                ratio = max_ratio;
            }

            // zoom center point
            if (centerOnMouse === true) {
                this.zoomToMouse(ratio, new createjs.Point(this._stage.mouseX, this._stage.mouseY));
            } else {
                this.zoomToPoint(ratio, this.getCenter());
                this.center();
            }
        });
    }

    private zoomToMouse(zoomRatio: number, mousePoint: createjs.Point): void {
        this._zone.runOutsideAngular(() => {
            if (this.isNullOrUndefined(zoomRatio)) {
                return;
            }

            // percentages from side
            const pXZoom = ((this._zoomContainer.x * -1) + mousePoint.x) * 100 / this._zoomWidth;
            const pYZoom = ((this._zoomContainer.y * -1) + mousePoint.y) * 100 / this._zoomHeight;

            // update ratio and dimensions
            this._zoomRatio = zoomRatio;
            this._zoomWidth = Grid.Width * this._zoomRatio;
            this._zoomHeight = Grid.Height * this._zoomRatio;

            // translate view back to center point
            const xZoom = ((this._zoomWidth * pXZoom / 100) - mousePoint.x);
            const yZoom = ((this._zoomHeight * pYZoom / 100) - mousePoint.y);

            this._zoomContainer.x = xZoom * -1;
            this._zoomContainer.y = yZoom * -1;
            this._zoomContainer.scaleX = this._zoomRatio;
            this._zoomContainer.scaleY = this._zoomRatio;

            this.requireStageUpdate();
        });
    }

    private zoomToPoint(zoomRatio: number, point: createjs.Point): void {
        this._zone.runOutsideAngular(() => {
            if (this.isNullOrUndefined(zoomRatio)) {
                return;
            }

            // update ratio and dimensions
            this._zoomRatio = zoomRatio;
            this._zoomWidth = Grid.Width * this._zoomRatio;
            this._zoomHeight = Grid.Height * this._zoomRatio;

            this._zoomContainer.x = point.x;
            this._zoomContainer.y = point.y;
            this._zoomContainer.scaleX = this._zoomRatio;
            this._zoomContainer.scaleY = this._zoomRatio;

            this.requireStageUpdate();
        });
    }

    private getDeviceSnapshot(device: Device): Observable<boolean>{
        const retVal: Subject<boolean> = new Subject<boolean>();

        this.addSubscription(this.observableHandlerBase(this._deviceService.getImageSnapshot(device.deviceModel.serialNumber, 'high'), this.getSnapshotProcess).subscribe((snapShot)=>{
            const newFrame: AlignDeviceFrame = new AlignDeviceFrame();
            newFrame.serialNumber = device.deviceModel.serialNumber;
            newFrame.frame = snapShot.imageData;
            newFrame.alpha = 1;

            this._devicesFrame.push(newFrame);

            device.video.setCurrentFrame({data: newFrame.frame});

            retVal.next(true);
            retVal.complete();
        }));

        return retVal;
    }

    private updateDevice(device: Device): Observable<boolean>{
        const retVal: Subject<boolean> = new Subject<boolean>();

        device.deviceModel.x = Math.floor(device.deviceModel.x);
        device.deviceModel.y = Math.floor(device.deviceModel.y);

        this.addSubscription(this.observableHandlerBase(this._deviceService.updateDevice(device.deviceModel), this.saveChangesProcess).subscribe(()=>{
            retVal.next(true);
            retVal.complete();
        }));

        return retVal;
    }
}
