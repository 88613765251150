import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { SyncedVideoSessionModel } from '@rift/models/restapi/SyncedVideoSession.Model';
import { SyncedVideoSessionInfoModel } from '@rift/models/restapi/SyncedVideoSessionInfo.Model';
import { VideoFrameImageModel } from '@rift/models/restapi/VideoFrameImage.Model';
import { VideoScheduleModel } from '@rift/models/restapi/VideoSchedule.Model';
import { VideoScheduleInfoModel } from '@rift/models/restapi/VideoScheduleInfo.Model';
import { VideoSessionModel } from '@rift/models/restapi/VideoSession.Model';
import { VideoSessionInfoModel } from '@rift/models/restapi/VideoSessionInfo.Model';
import { VideoSettingsModel } from '@rift/models/restapi/VideoSettings.Model';
import { VideoStatusModel } from '@rift/models/restapi/VideoStatus.Model';
import { VideoStorageCapacityModel } from '@rift/models/restapi/VideoStorageCapacity.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

@Injectable()
export class RestApiRecordingsService extends RiftRestApiService {
    private _controller = 'recordings';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public addVideoSchedule(schedule: VideoScheduleModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<VideoScheduleModel, ResultModel>(`${this._controller}/${serial}/videoschedule`, schedule, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public cancelSynchroniseVideoSession(session: VideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<VideoSessionModel, ResultModel>(`${this._controller}/${serial}/cancelsynchronisevideosession`, session, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteSyncedVideoSession(syncedSession: SyncedVideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.deleteWithBody<SyncedVideoSessionModel, ResultModel>(`${this._controller}/${serial}/syncedvideosession`, syncedSession, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteVideoSchedule(schedule: VideoScheduleModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.deleteWithBody<VideoScheduleModel, ResultModel>(`${this._controller}/${serial}/videoschedule`, schedule, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteVideoSession(session: VideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.deleteWithBody<VideoSessionModel, ResultModel>(`${this._controller}/${serial}/videosession`, session, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public formatVideoStorage(serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<null, ResultModel>(`${this._controller}/${serial}/format_storage`, null, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getSettings(serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoSettingsModel> {
        return this.get<VideoSettingsModel>(`${this._controller}/${serial}/settings`, VideoSettingsModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getSyncedVideoSessionFirstFrame(session: SyncedVideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoFrameImageModel> {
        return this.post<SyncedVideoSessionModel, VideoFrameImageModel>(`${this._controller}/${serial}/syncedframeimage`, session, VideoFrameImageModel, this.getTokenParams(), process, { disabled: true }).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getSyncedVideoSessions(serial: string, process?: ProcessMonitorServiceProcess): Observable<SyncedVideoSessionInfoModel> {
        return this.get<SyncedVideoSessionInfoModel>(`${this._controller}/${serial}/syncedvideosessions`, SyncedVideoSessionInfoModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getVideoSchedules(serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoScheduleInfoModel> {
        return this.get<VideoScheduleInfoModel>(`${this._controller}/${serial}/videoschedules`, VideoScheduleInfoModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getVideoSessionFirstFrame(session: VideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoFrameImageModel> {
        return this.post<VideoSessionModel, VideoFrameImageModel>(`${this._controller}/${serial}/frameimage`, session, VideoFrameImageModel, this.getTokenParams(), process, { disabled: true }).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getVideoSessions(serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoSessionInfoModel> {
        return this.get<VideoSessionInfoModel>(`${this._controller}/${serial}/videosessions`, VideoSessionInfoModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getVideoStatus(serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoStatusModel> {
        return this.get<VideoStatusModel>(`${this._controller}/${serial}/videostatus`, VideoStatusModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getVideoStorageCapacity(serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoStorageCapacityModel> {
        return this.get<VideoStorageCapacityModel>(`${this._controller}/${serial}/storage_capacity`, VideoStorageCapacityModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public pauseSynchroniseVideoSession(session: VideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<VideoSessionModel, ResultModel>(`${this._controller}/${serial}/pausesynchronisevideosession`, session, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public reportSyncStates(serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<null, ResultModel>(`${this._controller}/${serial}/reportsyncstates`, null, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public resetCroppingWindow(serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<null, ResultModel>(`${this._controller}/${serial}/reset_crop`, null, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public resumeSynchroniseVideoSession(session: VideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<VideoSessionModel, ResultModel>(`${this._controller}/${serial}/resumesynchronisevideosession`, session, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setSettings(settings: VideoSettingsModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<VideoSettingsModel, ResultModel>(`${this._controller}/${serial}/settings`, settings, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public synchroniseVideoSession(session: VideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<VideoSessionModel, ResultModel>(`${this._controller}/${serial}/synchronisevideosession`, session, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateSyncedVideoSession(syncedSession: SyncedVideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<SyncedVideoSessionModel, ResultModel>(`${this._controller}/${serial}/syncedvideosession`, syncedSession, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
