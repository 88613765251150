import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IBACNETModel extends IRestModel {
    deviceId: number;
    port: number;
    enabled: boolean;
    networkNumber: number;
}

export class BACNETModel extends BaseModel implements IBACNETModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public deviceId: number = null;
    public port: number = null;
    public enabled: boolean = null;
    public networkNumber: number = null;

    public constructor() {
        super();

        this.registerProperty('deviceId');
        this.registerProperty('port');
        this.registerProperty('enabled');
        this.registerProperty('networkNumber');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-BACNETModel');

        this.setPropertyOriginalValue('deviceId', this.deviceId);
        this.setPropertyOriginalValue('port', this.port);
        this.setPropertyOriginalValue('enabled', this.enabled);
        this.setPropertyOriginalValue('networkNumber', this.networkNumber);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this)
        };
    }
}
