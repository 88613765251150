import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ConnectionAuth } from '@rift/models/generic/ConnectionAuth.Model';

export class ConnectionUtility {
    public static navigateAndConnect(protocol: string, connectionData: string, router: Router, paths?: string[], auth?: ConnectionAuth): Observable<boolean> {
        if (!isNullOrUndefined(paths) && paths.length > 0) {
            if(!isNullOrUndefined(auth)){
                return from(router.navigate([protocol, connectionData, ...paths], {state: auth}));
            }
            else{
                return from(router.navigate([protocol, connectionData, ...paths]));
            }
        } else {
            if(!isNullOrUndefined(auth)){
                return from(router.navigate([protocol, connectionData], {state: auth}));
            }
            else{
                return from(router.navigate([protocol, connectionData]));
            }
        }
    }

    public static navigateAndReconnect(protocol: string, connectionData: string, router: Router, paths?: string[]): Observable<boolean> {
        if (!isNullOrUndefined(paths) && paths.length > 0) {
            return from(router.navigate(['reconnect', protocol, connectionData, ...paths]));
        } else {
            return from(router.navigate(['reconnect', protocol, connectionData]));
        }
    }
}
