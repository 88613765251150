import { EventBase } from '@shared/base/EventBase';

export abstract class EventPreventDefaultBase extends EventBase {
    private _isDefaultPrevented: boolean = false;

    protected constructor() {
        super();
    }

    /**
     * Determine if the default behavior has been prevented.
     *
     * @readonly
     * @type {boolean}
     * @memberof EventPreventDefaultBase
     */
    public get isDefaultPrevented(): boolean {
        return (this._isDefaultPrevented);
    }

    /**
     * Prevent the default behavior for this event.
     *
     * @memberof EventPreventDefaultBase
     */
    public preventDefault(): void {
        this._isDefaultPrevented = true;
    }
}
