<div #mainContent
    id="em-rift-metadata"
    class="em-rift-metadata main-content scroll-content">
    <div fxLayout.gt-sm="row"
        fxLayout.lt-md="column">
        <div ngClass.gt-sm="spacing-right"
            ngClass.lt-md="spacing-bottom"
            fxFlex.gt-sm="60"
            fxFlex.lt-md="100">
            <mat-card class="interaction"
                fxFlex>
                <mat-card-title fxLayout="row"
                    fxLayoutAlign="start center">
                    <span>Edit Device Meta Data</span>
                    <div fxFlex></div>
                    <button *ngIf="(userIsSystemAdmin | async) === true"
                        mat-raised-button
                        routerLink="/settings/metadata">Edit Keys</button>
                </mat-card-title>
                <form [formGroup]="metaDataKeysFormGroup">
                    <div formArrayName="metaDataKeys">
                        <ng-template ngFor
                            let-metaDataMap
                            [ngForOf]="metaDataMapCollection?.items"
                            let-i="index">
                            <div [formGroupName]="i"
                                fxLayout="column">
                                <mat-form-field class="spacing-top">
                                    <textarea matInput
                                        #value
                                        formControlName="value"
                                        [placeholder]="metaDataMap.metaDataKey.name"></textarea>
                                    <span matSuffix>{{ metaDataMapCollection?.items[i].propertyHasChangesText('value') }}</span>
                                    <mat-error>
                                        {{getFormControlErrorMessage(asFormGroup(asFormArray(metaDataKeysFormGroup.controls['metaDataKeys']).at(i)).controls['value'])}}
                                    </mat-error>
                                    <mat-hint align="end">{{value.value.length}} / 1000</mat-hint>
                                </mat-form-field>
                                <mat-divider></mat-divider>
                            </div>
                        </ng-template>
                    </div>
                </form>
            </mat-card>
        </div>
        <div fxFlex.gt-sm="40"
            fxFlex.lt-md="100">
            <mat-card shrScrollFixed
                [disable.lt-sm]="true"
                [disable.gt-sm]="false"
                class="help">
                <mat-card-title fxLayout="row"
                    fxLayoutAlign="start center">
                    <span>What is Meta Data?</span>
                </mat-card-title>
                <span>Meta Data allows the creation of user configurable data entries for devices. Once set up, the Meta Data page on all
                    devices will allow data to be entered for each configured key. The data is stored within Estate Manager.
                </span>
            </mat-card>
        </div>
    </div>
</div>