<shr-table #table
    matSort
    #sort="matSort"
    [filterEnabled]="false"
    [serverPage]="true"
    [serverSort]="true"
    [rowMouseHover]="true"
    [matSortDisableClear]="true"
    (matSortChange)="onSortChanged($event)"
    (page)="onPageChanged($event)"
    (rowClicked)="onRowClicked($event)"
    [matSortActive]="sortActive"
    [isLoadingData]="isLoadingData"
    [dataRefreshedTrigger]="dataRefreshedTrigger"
    [sort]="sort"
    [matSortDirection]="sortDirection"
    matSortStart="asc"
    [dataSource]="devicesDataSource"
    [pageIndex]="pageOptions?.page - 1"
    [recordLength]="pageOptions?.totalResults"
    [pageSize]="pageOptions?.resultsPerPage"
    [displayedColumns]="displayedColumns"
    [dataExportEnabled]="true"
    [dataExportGetDataHandler]="dataExportGetData"
    [dataExportGetHeaderHandler]="dataExportGetHeader">
    <!-- Friendly Serial Number Column -->
    <ng-container matColumnDef="FriendlySerial"
        [sticky]="true">
        <th mat-header-cell
            mat-sort-header="FriendlyDeviceSerial"
            *matHeaderCellDef>Friendly Serial</th>
        <td mat-cell
            *matCellDef="let data">
            <shr-table-cell [data]="data"
                keyPath="friendlySerial"></shr-table-cell>
        </td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="Status">
        <th mat-header-cell
            *matHeaderCellDef>Status</th>
        <td mat-cell
            *matCellDef="let data">
            <shr-fa matTooltip="Connected"
                class="icon"
                *ngIf="data?.isConnected === true"
                name="sign-in-alt">
            </shr-fa>
            <shr-fa matTooltip="Has Error"
                class="icon error"
                *ngIf="data?.state === DeviceStateEnum.error"
                name="exclamation-triangle"></shr-fa>
            <shr-fa matTooltip="Has Warning"
                class="icon warning"
                *ngIf="data?.state === DeviceStateEnum.warning"
                name="exclamation-triangle"></shr-fa>
            <shr-fa matTooltip="Video"
                class="icon"
                *ngIf="data?.hasCapability(DeviceCapabilitiesEnum.video) === true"
                name="video"></shr-fa>
            <shr-fa matTooltip="Bluetooth"
                class="icon"
                *ngIf="data?.hasCapability(DeviceCapabilitiesEnum.bluetooth) === true"
                fontSet="fab"
                name="bluetooth"></shr-fa>
            <shr-fa matTooltip="Height measuring"
                class="icon"
                *ngIf="data?.hasCapability(DeviceCapabilitiesEnum.height) === true"
                name="arrows-alt-v"></shr-fa>
            <img matTooltip="Tilt"
                style="padding-left: 11px; padding-top: 6px;"
                width="14"
                height="14"
                *ngIf="data?.hasCapability(DeviceCapabilitiesEnum.tilt) === true"
                src="/assets/angle.png">
        </td>
    </ng-container>
    <!-- Site Name Column -->
    <ng-container matColumnDef="SiteName">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Site Name</th>
        <td mat-cell
            *matCellDef="let data">
            <shr-table-cell [data]="data"
                keyPath="siteName"></shr-table-cell>
        </td>
    </ng-container>
    <!-- SiteId Column -->
    <ng-container matColumnDef="SiteId">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Site Id</th>
        <td mat-cell
            *matCellDef="let data">
            <shr-table-cell [data]="data"
                keyPath="siteId"></shr-table-cell>
        </td>
    </ng-container>
    <!-- IP Address Column -->
    <ng-container matColumnDef="IPAddress">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>IP Address</th>
        <td mat-cell
            *matCellDef="let data">
            <shr-table-cell [data]="data"
                keyPath="iPAddress"></shr-table-cell>
        </td>
    </ng-container>
    <!-- MAC Address Column -->
    <ng-container matColumnDef="MACAddress">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>MAC Address</th>
        <td mat-cell
            *matCellDef="let data">
            <shr-table-cell [data]="data"
                keyPath="mACAddress"></shr-table-cell>
        </td>
    </ng-container>
    <!-- DeviceId Column -->
    <ng-container matColumnDef="DeviceId">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Device Id</th>
        <td mat-cell
            *matCellDef="let data">
            <shr-table-cell [data]="data"
                keyPath="deviceId"></shr-table-cell>
        </td>
    </ng-container>
    <!-- Device Name Column -->
    <ng-container matColumnDef="DeviceName">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Device Name</th>
        <td mat-cell
            *matCellDef="let data">
            <shr-table-cell [data]="data"
                keyPath="deviceName"></shr-table-cell>
        </td>
    </ng-container>
    <!-- User String Column -->
    <ng-container matColumnDef="UserString">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>User String</th>
        <td mat-cell
            *matCellDef="let data">
            <shr-table-cell [data]="data"
                keyPath="userString"></shr-table-cell>
        </td>
    </ng-container>
    <!-- User String Column -->
    <ng-container matColumnDef="LocationName">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Location</th>
        <td mat-cell
            *matCellDef="let data">
            <shr-table-cell [data]="data"
                keyPath="location"></shr-table-cell>
        </td>
    </ng-container>
    <!-- Firmware Version Column -->
    <ng-container matColumnDef="FirmwareVersion">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Firmware Version</th>
        <td mat-cell
            *matCellDef="let data">
            <shr-table-cell [data]="data"
                keyPath="firmwareVersion"></shr-table-cell>
        </td>
    </ng-container>
    <!-- Last Connected Column -->
    <ng-container matColumnDef="LastConnected">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Last Seen</th>
        <td mat-cell
            *matCellDef="let data">
            <span>
                {{data.lastSeenText}}
            </span>
        </td>
    </ng-container>
    <!-- Node Count Column -->
    <ng-container matColumnDef="NodeCount">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Node Count</th>
        <td mat-cell
            *matCellDef="let data">
            <shr-table-cell [data]="data"
                keyPath="nodeCount"></shr-table-cell>
        </td>
    </ng-container>
    <!-- Meta Data Columns -->
    <ng-container *ngFor="let metaDataKey of metaDataKeys"
        [matColumnDef]="META_DATA_PREFIX + metaDataKey.name">
        <th mat-header-cell
            *matHeaderCellDef>{{metaDataKey.name}}</th>
        <td mat-cell
            *matCellDef="let data">
            <span matTooltip="{{data?.getMetaDataValue(metaDataKey.metaDataKeyId)}}">
                {{data?.getMetaDataValue(metaDataKey.metaDataKeyId)}}
            </span>
        </td>
    </ng-container>
</shr-table>