import { Component, HostBinding, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceSerialLinkComponent } from '@em/components/shared/deviceseriallink/DeviceSerialLink.Component';
import {
    DeviceCounts,
    DeviceHealthService,
    DurationRangesEnum,
    HealthCounts,
} from '@em/service/data/devicehealth/DeviceHealth.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ITableRowClicked } from '@shared/component/table/Table.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionService } from '@rift/service/connection/Connection.Service';

@Component({
    selector: 'em-dashboard-device-health',
    templateUrl: './Dashboard.DeviceHealth.Component.html',
    styleUrls: ['./Dashboard.DeviceHealth.Component.scss']
})
export class DashboardDeviceHealthComponent extends BaseComponent implements OnInit, ILoadDate {
    public static className: string = 'DashboardDeviceHealthComponent';
    public count: number = 0;
    public dataSource = new MatTableDataSource();
    public deviceHealthService: DeviceHealthService;
    public displayedColumns = ['status', 'serial', 'connectionStatus', 'lastActivity', 'action'];

    public healthCounts: HealthCounts;

    @ViewChild(MatPaginator, { static: true })
    public paginator: MatPaginator;

    public level: number = 0;

    @HostBinding()
    public id: string = 'em-dashboard-device-health';
    public pageBeginIndex: number = 0;
    public paramsProcess: ProcessMonitorServiceProcess;
    public percentage: number = 0;
    public title: string = '';

    public constructor(
        private readonly _connectionService: ConnectionService,
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _deviceHealthService: DeviceHealthService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.deviceHealthService = this._deviceHealthService;

        this.loadDataProcess = this.processMonitorService.getProcess(DashboardDeviceHealthComponent.className, this.loadDataProcessText);
        this.paramsProcess = this.processMonitorService.getProcess(DashboardDeviceHealthComponent.className, 'Get router prams');

        this.addSubscription(this.observableHandlerBase(this._activatedRoute.params, this.paramsProcess).subscribe(params => {
            if (this.isNullOrUndefined(params.level)) {
                this.level = 1;
                this.title = 'Devices at level ' + this.deviceHealthService.getLevelName(this.level);
            } else {
                this.level = parseInt(params.level, 10);
                this.title = 'Devices at level ' + this.deviceHealthService.getLevelName(this.level);
            }

            this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
        }), this.paramsProcess);
    }

    public applyFilter(filterValue: string) {
        if (!this.isNullOrUndefined(this.dataSource.data) && this.dataSource.data.length > 0) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            this.dataSource.filter = filterValue;
        }
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._deviceHealthService.getOverallHealth(DurationRangesEnum.day, process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.healthCounts = result;

                        this.percentage = this.healthCounts.overall['level' + this.level + 'Percentage'];
                        this.count = this.healthCounts.overall['level' + this.level + 'Count'];

                        const data: DeviceCounts[] = [];
                        const length = this.healthCounts.devices.length;
                        for (let index = 0; index < length; index++) {
                            const device = this.healthCounts.devices[index];
                            if (device.overall.level === this.level) {
                                data.push(device);
                            }
                        }

                        this.dataSource.data = data;
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.dataSource.paginator = this.paginator;
    }

    public rowClicked(event: ITableRowClicked<DeviceCounts>): void {
        DeviceSerialLinkComponent.navigateToDevice(this._router, event.data.serial, this._connectionService, ['health']);
    }
}
