<div fxLayout="column"
    class="sessions-menu">
    <div class="header">
        Sessions
    </div>
    <div class="content"
        fxLayout="column">
        <div fxLayout="row"
            class="row">
            <div class="cell"
                fxFlex="33">
                Date
            </div>
            <div fxFlex="33"
                class="cell">
                Completed
            </div>
            <div fxFlex="33"
                class="cell">
                Passes
            </div>
        </div>
        <div *ngFor="let session of sessions"
            fxLayout="row"
            class="row session"
            (click)="sessionClicked(session)">
            <div class="cell"
                fxFlex="33">
                {{ DateTimeUtility.toShortDate(creationDateStringToDate(session.creationDate)) }}
            </div>
            <div fxFlex="33"
                class="cell">
                <mat-icon *ngIf="session.state !== ValidationSessionStateEnum.incomplete">
                    check_box
                </mat-icon>
            </div>
            <div fxFlex="33"
                class="cell">
                <mat-icon *ngIf="session.state === ValidationSessionStateEnum.validated">
                    check_box
                </mat-icon>
            </div>
        </div>
    </div>
    <div class="actions">
        <button mat-raised-button
            fxFlex
            color="primary"
            (click)="newSessionClick()">New</button>
    </div>
</div>