<div mat-dialog-title>
    <div fxLayout="row"
        fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Session Details
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div mat-dialog-content
    fxLayout="column">
    <div class="options"
        fxLayout="column">
        <div class="header"
            fxLayout="row"
            fxFlex>
            <div fxFlex
                fxLayout="row"
                fxLayoutAlign="start center">
                Option
            </div>
            <div class="check"
                fxLayout="row"
                fxLayoutAlign="center center">
                Enabled
            </div>
            <div class="check"
                fxLayout="row"
                fxLayoutAlign="center center">
                Locked
            </div>
        </div>
        <div class="row"
            fxLayout="row"
            *ngFor="let option of session?.options"
            fxFlex>
            <div fxFlex
                fxLayout="row"
                fxLayoutAlign="start center">
                {{ getDisplayName(option) }}
            </div>
            <div class="check"
                fxLayout="row"
                fxLayoutAlign="center center">
                <mat-checkbox [(ngModel)]="option.enabled"
                    [disabled]="!isNullOrUndefined(session.state)">
                </mat-checkbox>
            </div>
            <div class="check"
                fxLayout="row"
                fxLayoutAlign="center center">
                <mat-checkbox [(ngModel)]="option.locked"
                    [disabled]="!isNullOrUndefined(session.state)">
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="details"
        fxLayout="column">
        <div [formGroup]="sessionFormGroup">
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input id="session-details-createdOn-input"
                        type="text"
                        matInput
                        placeholder="Created On"
                        disabled="true"
                        [value]="DateTimeUtility.toShortDateTime(session.creationDateDate)">
                </mat-form-field>
                <mat-form-field fxFlex>
                    <input id="session-details-modifiedOn-input"
                        type="text"
                        matInput
                        placeholder="Modified On"
                        disabled="true"
                        [value]="DateTimeUtility.toShortDateTime(session.modificationDateDate)">
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input id="session-details-name-input"
                        type="text"
                        #name
                        matInput
                        formControlName="name"
                        placeholder="Name">
                    <span matSuffix>{{ session?.propertyHasChangesText('name') }}</span>
                    <mat-error>{{getFormControlErrorMessage(sessionFormGroup.controls['name'])}}</mat-error>
                    <mat-hint align="end">{{name.value.length}} / 256</mat-hint>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input id="session-details-username-input"
                        type="text"
                        #username
                        matInput
                        formControlName="username"
                        placeholder="For User">
                    <span matSuffix>{{ session?.propertyHasChangesText('username') }}</span>
                    <mat-error>{{getFormControlErrorMessage(sessionFormGroup.controls['username'])}}</mat-error>
                    <mat-hint align="end">{{username.value.length}} / 256</mat-hint>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <textarea id="session-details-notes-input"
                        type="text"
                        #notes
                        matInput
                        formControlName="notes"
                        placeholder="Notes"></textarea>
                    <span matSuffix>{{ session?.propertyHasChangesText('notes') }}</span>
                    <mat-error>{{getFormControlErrorMessage(sessionFormGroup.controls['notes'])}}</mat-error>
                    <mat-hint align="end">{{notes.value.length}} / 2056</mat-hint>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="partial"
        fxLayout="row"
        *ngIf="session.startFrame && session.endFrame">
        <mat-checkbox [(ngModel)]="session.partial"
            *ngIf="isNullOrUndefined(session.state)"></mat-checkbox>
        {{ isNullOrUndefined(session.state) ? 'Please confirm p' : 'P' }}artial validation from {{start}} to {{end}}
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button
        (click)="close()">Close</button>
    <div fxFlex></div>
    <button mat-raised-button
        (click)="delete()"
        *ngIf="!isNullOrUndefined(session.state)">Delete</button>
    <button mat-raised-button
        (click)="savePreview()"
        *ngIf="isNullOrUndefined(session.state)">Save Goto
        Preview</button>
    <button mat-raised-button
        (click)="saveValidate()"
        *ngIf="isNullOrUndefined(session.state)">Save Goto
        Validation</button>
    <button mat-raised-button
        (click)="report()"
        *ngIf="!isNullOrUndefined(session.state)">Report</button>
    <button mat-raised-button
        (click)="review()"
        *ngIf="!isNullOrUndefined(session.state) && (session.state === ValidationSessionStateEnum.rejected || session.state === ValidationSessionStateEnum.validated)">Review</button>
    <button mat-raised-button
        (click)="validate()"
        *ngIf="!isNullOrUndefined(session.state) && session.state === ValidationSessionStateEnum.incomplete"
        color="primary">Validate</button>
</div>