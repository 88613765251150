import { REGISTER_BASE_EXCLUDE_PROPERTIES, RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterFIFOOccupancyMasterModel extends IRegisterBaseModel {
    inRegisters: Array<number>;
    outRegisters: Array<number>;
    externalRegisters: Array<number>;
    timeout: number;
    resetTimeout: number;
    fIFOInstanceIndex: number;
    fIFOTimeoutInstanceIndex: number;
}

/**
 * Represents a
 *
 * @export
 * @class RegisterFIFOOccupancyMasterModel
 * @extends {RegisterBaseModel}
 * @implements {IRegisterFIFOOccupancyMasterModel}
 * @implements {IRestModel}
 */
export class RegisterFIFOOccupancyMasterModel extends RegisterBaseModel implements IRegisterFIFOOccupancyMasterModel, IRestModel {

    public inRegisters: Array<number> = null;
    public outRegisters: Array<number> = null;
    public externalRegisters: Array<number> = null;
    public timeout: number = null;
    public resetTimeout: number = null;
    public fIFOInstanceIndex: number = null;
    public fIFOTimeoutInstanceIndex: number = null;

    public constructor() {
        super();

        this.registerProperty('inRegisters');
        this.registerProperty('outRegisters');
        this.registerProperty('externalRegisters');
        this.registerProperty('timeout');
        this.registerProperty('resetTimeout');
        this.registerProperty('fIFOInstanceIndex');
        this.registerProperty('fIFOTimeoutInstanceIndex');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterFIFOOccupancyMasterModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('inRegisters', this.inRegisters);
        this.setPropertyOriginalValue('outRegisters', this.outRegisters);
        this.setPropertyOriginalValue('externalRegisters', this.externalRegisters);
        this.setPropertyOriginalValue('timeout', this.timeout);
        this.setPropertyOriginalValue('resetTimeout', this.resetTimeout);
        this.setPropertyOriginalValue('fIFOInstanceIndex', this.fIFOInstanceIndex);
        this.setPropertyOriginalValue('fIFOTimeoutInstanceIndex', this.fIFOTimeoutInstanceIndex);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
            InRegisters: this.inRegisters,
            OutRegisters: this.outRegisters,
            ExternalRegisters: this.externalRegisters
        };
    }

    public setIndexes(): void {
        if (isNullOrUndefined(this.inRegisters)) {
            this.inRegisters = [];
        }
        if (isNullOrUndefined(this.outRegisters)) {
            this.outRegisters = [];
        }
        if(isNullOrUndefined(this.externalRegisters)){
            this.externalRegisters = [];
        }

        super.setIndexesBase([], [...this.inRegisters, ...this.outRegisters, ...this.externalRegisters], []);
    }

}
