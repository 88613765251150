import { Subject } from 'rxjs';

/**
 * Rest Api process status info.
 *
 * @export
 * @class ProcessMonitorServiceRestApi
 */
export class ProcessMonitorServiceRestApi {
    public downloadProgressChange: Subject<number> = new Subject<number>();
    public uploadProgressChange: Subject<number> = new Subject<number>();
    public downloadPercent: number;
    public uploadPercent: number;

    public get downloadProgress() {
        return this._downloadProgress;
    }
    public set downloadProgress(value) {
        if (this._downloadProgress !== value) {
            this._downloadProgress = value;
            this.downloadProgressChange.next(this._downloadProgress);
        }
    }
    public get uploadProgress() {
        return this._uploadProgress;
    }
    public set uploadProgress(value) {
        if (this._uploadProgress !== value) {
            this._uploadProgress = value;
            this.uploadProgressChange.next(this._uploadProgress);
        }
    }

    private _downloadProgress = 0;
    private _uploadProgress = 0;
}
