import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export interface IUpTimeModel extends IRestModel {
    upTime: number;
}

export class UpTimeModel extends BaseModel implements IUpTimeModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public upTime: number = null;

    private _upTimeText: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-UpTimeModel');

        if (!isNullOrUndefined(this.upTime)) {
            this._upTimeText = DateTimeUtility.secondsToRelativeDuration(this.upTime);
        }
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }

    public get upTimeText(): string {
        return this._upTimeText;
    }
}
