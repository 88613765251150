import { Component, EventEmitter, HostBinding, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetaDataKeyModel } from '@em/models/restapi/MetaDataKey.Model';
import { BaseComponent } from '@shared/base/Base.Component';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';

@Component({
    selector: 'em-settings-metadata-key',
    templateUrl: './Settings.MetaData.Key.Component.html',
    styleUrls: ['./Settings.MetaData.Key.Component.scss']
})
export class SettingsMetaDataKeyComponent extends BaseComponent implements OnInit {
    public static className: string = 'SettingsMetaDataKeyComponent';

    public statusChangesProcess: ProcessMonitorServiceProcess;
    public valueChangesProcess: ProcessMonitorServiceProcess;

    @Output()
    public deleteClicked: EventEmitter<MetaDataKeyModel> = new EventEmitter();

    @Output()
    public valueChanges: EventEmitter<null> = new EventEmitter();

    @Input()
    public metaData: MetaDataKeyModel;
    public isValid: boolean = false;

    public metaDataForm: FormGroup;

    @HostBinding()
    public id: string = 'em-settings-metadata-key';

    public constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector);

        this.statusChangesProcess = this.processMonitorService.getProcess(SettingsMetaDataKeyComponent.className, 'Form valid state change');
        this.valueChangesProcess = this.processMonitorService.getProcess(SettingsMetaDataKeyComponent.className, 'Form values change');

        this.metaDataForm = this._formBuilder.group({
            name: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
        });

        this.addSubscription(this.observableHandlerBase(this.metaDataForm.statusChanges, this.statusChangesProcess).subscribe(status => {
            if (status === 'VALID') {
                this.isValid = true;
                this.updateModel();
                this.valueChanges.emit();
            } else {
                this.isValid = false;
                this.valueChanges.emit();
            }
        }), this.statusChangesProcess);

        this.addSubscription(this.observableHandlerBase(this.metaDataForm.valueChanges, this.valueChangesProcess).subscribe(() => {
            this.valueChanges.emit();
        }), this.valueChangesProcess);
    }

    public delete(): void {
        this.deleteClicked.emit(this.metaData);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.setFormValues();
    }

    private setFormValues(): void {
        this.metaDataForm.setValue({
            name: this.isNullOrUndefined(this.metaData.name) ? '' : this.metaData.name,
        });
    }

    private updateModel(): void {
        const formModel = this.metaDataForm.value;

        this.metaData.name = formModel.name;
    }
}
