<div shrResized
    id="em-home"
    (resized)="onContentResize($event)"
    #mainContent
    class="em-home error-screen-shot-from"
    fxLayout="column">
    <shr-navbar text="Estate Manager"
        (homeClick)="onHomeClick()"
        (drawerToggleClicked)="sidenav.toggle()"></shr-navbar>
    <mat-sidenav-container class="drawer-container">
        <mat-sidenav #sidenav
            mode="side"
            opened="true"
            position="start"
            [mode]="mobileQuery.matches ? 'over' : 'side'">
            <em-navmenu></em-navmenu>
        </mat-sidenav>
        <!-- shrScrollFixedHost -->
        <mat-sidenav-content class="content scroll2"
            fxLayout="column">
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>