<div class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column"
    *ngIf="canChangePassword">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                Web Interface Password
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="webInterfaceFormGroup">
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="password"
                                #oldPassword
                                matInput
                                autocomplete="new-password"
                                formControlName="oldPassword"
                                placeholder="Current Password">
                            <span matSuffix>{{ changePassword?.propertyHasChangesText('oldPassword') }}</span>
                            <mat-error>{{getFormControlErrorMessage(webInterfaceFormGroup.controls['oldPassword'])}}</mat-error>
                            <mat-hint align="end">{{oldPassword.value.length}} / 30</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="password"
                                #newPassword
                                matInput
                                autocomplete="new-password"
                                formControlName="newPassword"
                                placeholder="New Password">
                            <span matSuffix>{{ changePassword?.propertyHasChangesText('newPassword') }}</span>
                            <mat-error>{{getFormControlErrorMessage(webInterfaceFormGroup.controls['newPassword'])}}</mat-error>
                            <mat-hint align="end">{{newPassword.value.length}} / 30</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="password"
                                #confirmPassword
                                matInput
                                autocomplete="new-password"
                                formControlName="confirmPassword"
                                placeholder="Confirm Password">
                            <span matSuffix>{{ changePassword?.propertyHasChangesText('confirmPassword') }}</span>
                            <mat-error>{{getFormControlErrorMessage(webInterfaceFormGroup.controls['confirmPassword'])}}</mat-error>
                            <mat-hint align="end">{{confirmPassword.value.length}} / 30</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="error">
                        {{getFormControlErrorMessage(webInterfaceFormGroup)}}
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                Web Interface Password
            </mat-card-title>
            <mat-card-content>
                <span>If connecting directly to an Irisys device via a web interface you will require this password. When connecting
                    via Estate Manager this password is not required.</span>
                <br>
                <br>
                <span>
                    Password must be between 8 and 30 characters long and contain:
                    <br>
                    <ul>
                        <li>Uppercase letters</li>
                        <li>Lowercase letters</li>
                        <li>Numbers</li>
                        <li>Special characters</li>
                    </ul>
                </span>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column"
    *ngIf="!canChangePassword">
    <mat-card fxFlex>
        <mat-card-title>
            Security settings can not be changed using this connection method.
        </mat-card-title>
    </mat-card>
</div>
<ng-template [ngIf]="!isNullOrUndefined(webAPIKey) && !isNullOrUndefined(webAPIKey.key) && !isEmptyOrWhiteSpace(webAPIKey.key)">
    <div fxLayout.gt-sm="row"
        fxLayout.lt-md="column"
        class="spacing-bottom">
        <div ngClass.gt-sm="spacing-right"
            ngClass.lt-md="spacing-bottom"
            fxFlex.gt-sm="60"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card fxFlex>
                <mat-card-title>
                    Device Web API Key
                </mat-card-title>
                <mat-card-content>
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        <div>
                            Key: {{ webAPIKey?.key }}
                        </div>
                        <div fxFlex>
                        </div>
                        <div>
                            <shr-progress-button-spinner [disabled]="isReadOnly === true"
                                [raised]="true"
                                [diameter]="30"
                                text="Regenerate"
                                mode="indeterminate"
                                color="primary"
                                [active]="regenerateKeyProcess.isRunning === true"
                                (shrClick)="regenerateKey()">
                            </shr-progress-button-spinner>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex.gt-sm="40"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card class="shr-card-sub help-card"
                fxFlex>
                <mat-card-title>
                    Device Web API Key
                </mat-card-title>
                <mat-card-content>
                    <span>The Device Web API Key is used to access the REST APIs directly on the device itself.</span>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</ng-template>
<ng-template [ngIf]="(isDeviceCapable(DeviceCapabilitiesEnum.mqttTLS) | async) === true">
    <div fxLayout.gt-sm="row"
        fxLayout.lt-md="column"
        class="spacing-bottom">
        <div ngClass.gt-sm="spacing-right"
            ngClass.lt-md="spacing-bottom"
            fxFlex.gt-sm="60"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card fxFlex>
                <mat-card-title>
                    Security Certificate Authority
                </mat-card-title>
                <mat-card-content>
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        <div fxLayout="column">
                            <div fxLayout="row"
                                fxLayoutAlign="start center"
                                *ngFor="let certName of certCANames">
                                <div class="spacing-right">
                                    Current cert: {{ certName }}
                                </div>
                                <shr-progress-button-spinner [disabled]="isReadOnly === true"
                                    [raised]="true"
                                    [diameter]="30"
                                    text="Remove"
                                    mode="indeterminate"
                                    color="primary"
                                    [active]="removeCACertProcess.isRunning === true"
                                    (shrClick)="removeCert(certName)">
                                </shr-progress-button-spinner>
                            </div>
                        </div>
                        <div fxFlex>
                        </div>
                        <div>
                            <shr-progress-button-spinner [disabled]="isReadOnly === true"
                                [raised]="true"
                                [diameter]="30"
                                text="Upload"
                                mode="indeterminate"
                                color="primary"
                                [active]="uploadCertProcess.isRunning === true"
                                (shrClick)="uploadCert()">
                            </shr-progress-button-spinner>
                            <input type="file"
                                style="display: none;"
                                #fileUpload
                                (change)="certFileSelected($event)" />
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex.gt-sm="40"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card class="shr-card-sub help-card"
                fxFlex>
                <mat-card-title>
                    Security Certificate Authority
                </mat-card-title>
                <mat-card-content>
                    <span>Upload an additional security certificate authority to the device.</span>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</ng-template>
<ng-template [ngIf]="(isDeviceCapable(DeviceCapabilitiesEnum.customSSLCerts) | async) === true">
    <div fxLayout.gt-sm="row"
        fxLayout.lt-md="column"
        class="spacing-bottom">
        <div ngClass.gt-sm="spacing-right"
            ngClass.lt-md="spacing-bottom"
            fxFlex.gt-sm="60"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card fxFlex>
                <mat-card-title>
                    Custom Security Certificates
                </mat-card-title>
                <mat-card-content>
                    <div fxLayout="column"
                        fxLayoutAlign="start">
                        <div fxLayout="row">
                            <div class="buttonBorder">
                                <shr-progress-button-spinner [disabled]="isReadOnly === true"
                                    [raised]="true"
                                    [diameter]="30"
                                    [text]="isNullOrUndefined(sslCertModel?.privateKey) ? 'Select Private Key' : 'Private Key Selected'"
                                    mode="indeterminate"
                                    color="primary"
                                    [active]="uploadPrivateKeyProcess.isRunning === true"
                                    (shrClick)="uploadPrivateSSLCert()">
                                </shr-progress-button-spinner>
                                <input type="file"
                                    style="display: none;"
                                    #privateKeyUpload
                                    (change)="sslCertPrivateKeyFileSelected($event)" />
                            </div>
                            <div class="buttonBorder">
                                <shr-progress-button-spinner [disabled]="isReadOnly === true"
                                    [raised]="true"
                                    [diameter]="30"
                                    [text]="isNullOrUndefined(sslCertModel?.publicKey) ? 'Select Certificate' : 'Certificate Selected'"
                                    mode="indeterminate"
                                    color="primary"
                                    [active]="uploadPublicKeyProcess.isRunning === true"
                                    (shrClick)="uploadPublicSSLCert()">
                                </shr-progress-button-spinner>
                                <input type="file"
                                    style="display: none;"
                                    #publicKeyUpload
                                    (change)="sslCertPublicKeyFileSelected($event)" />
                            </div>
                            <div class="buttonBorder">
                                <shr-progress-button-spinner [disabled]="isReadOnly === true || isNullOrUndefined(sslCertModel?.privateKey) || isNullOrUndefined(sslCertModel?.publicKey)"
                                    [raised]="true"
                                    [diameter]="30"
                                    text="Clear Selections"
                                    mode="indeterminate"
                                    color="primary"
                                    [active]="clearSSLKeysProcess.isRunning === true"
                                    (shrClick)="clearSSLKeys()">
                                </shr-progress-button-spinner>                            
                            </div>
                        </div>                       
                        <div class="buttonBorder" *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.multiCert) | async) === true">
                            <form fxLayout="row" [formGroup]="certPairFormGroup">
                                <mat-radio-group [disabled]="isReadOnly === true" class="buttonBorder" fxLayout="column" [(ngModel)]="certType" [ngModelOptions]="{standalone: true}" (change)="certTypeChanged()">
                                    <mat-radio-button value="default">Default</mat-radio-button>
                                    <mat-radio-button value="custom">Custom</mat-radio-button>
                                </mat-radio-group>

                                <mat-form-field *ngIf="certType === 'custom'" style="margin-top:20px;">
                                    <input                                        
                                        type="text"
                                        #certPairNameField
                                        matInput
                                        formControlName="certName"
                                        placeholder="Name">                                    
                                    <mat-error>{{getFormControlErrorMessage(certPairFormGroup.controls['certName'])}}</mat-error>
                                    <mat-hint align="end">{{certPairNameField.value.length}} / 20</mat-hint>
                                </mat-form-field>
                            </form>
                        </div>                        
                        <div class="buttonBorder">
                            <shr-progress-button-spinner [disabled]="isReadOnly === true || isNullOrUndefined(sslCertModel?.privateKey) || isNullOrUndefined(sslCertModel?.publicKey) || ((isDeviceCapable(DeviceCapabilitiesEnum.multiCert) | async) === true && certPairFormGroup.invalid === true)"
                                [raised]="true"
                                [diameter]="30"
                                text="Upload"
                                mode="indeterminate"
                                color="primary"
                                [active]="uploadSSLCertProcess.isRunning === true"
                                (shrClick)="uploadSSLCert()">
                            </shr-progress-button-spinner>                            
                        </div>
                    </div>
                    <div *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.multiCert) | async) === true">
                        <mat-card-title>
                            Current certificates on device
                        </mat-card-title>
                        <div>
                            <div><h3>Default</h3></div>
                            <div *ngFor="let certItem of certPairNames" style="max-width: 300px;">
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <h3>{{certItem}}</h3>
                                    <div fxFlex></div>
                                    <button mat-icon-button [disabled]="isReadOnly === true" (click)="onDeleteCertPair(certItem)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex.gt-sm="40"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card class="shr-card-sub help-card"
                fxFlex>
                <mat-card-title>
                    Custom Security Certificates
                </mat-card-title>
                <mat-card-content>
                    <span>Upload a custom security certificate used for device communications (HTTPS, Secure MQTT etc).</span>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</ng-template>
<ng-template [ngIf]="(isDeviceCapable(DeviceCapabilitiesEnum.https) | async) === true && isNullOrUndefined(httpEnabledData) === false">
    <div fxLayout.gt-sm="row"
        fxLayout.lt-md="column"
        class="spacing-bottom">
        <div ngClass.gt-sm="spacing-right"
            ngClass.lt-md="spacing-bottom"
            fxFlex.gt-sm="60"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card fxFlex>
                <mat-card-title>
                    HTTPS Only
                </mat-card-title>
                <mat-card-content>
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        <div>
                            <mat-checkbox [(ngModel)]="httpEnabledData.httpsEnabled"
                            (change)="httpsEnabledChanged()"
                            [disabled]="isReadOnly">HTTPS Only Enabled</mat-checkbox>
                        </div>
                        <div fxFlex>
                        </div>                        
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex.gt-sm="40"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card class="shr-card-sub help-card"
                fxFlex>
                <mat-card-title>
                    HTTPS Only
                </mat-card-title>
                <mat-card-content>
                    <span>This setting can be used to force the device to use HTTPS for all web based traffic</span>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</ng-template>
<ng-template [ngIf]="(isDeviceCapable(DeviceCapabilitiesEnum.commsAuthentication) | async) === true && isNullOrUndefined(authenticationEnabledData) === false">
    <div fxLayout.gt-sm="row"
        fxLayout.lt-md="column"
        class="spacing-bottom">
        <div ngClass.gt-sm="spacing-right"
            ngClass.lt-md="spacing-bottom"
            fxFlex.gt-sm="60"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card fxFlex>
                <mat-card-title>
                    Authenticated communications
                </mat-card-title>
                <mat-card-content>
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        <div>
                            <mat-checkbox [(ngModel)]="authenticationEnabledData.authenticationEnabled"
                            (change)="authenticatedCommunicationsEnabledChanged()"
                            [disabled]="isReadOnly">Authenticated Communications Enabled</mat-checkbox>
                        </div>
                        <div fxFlex>
                        </div>                        
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex.gt-sm="40"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card class="shr-card-sub help-card"
                fxFlex>
                <mat-card-title>
                    Authenticated Communications
                </mat-card-title>
                <mat-card-content>
                    <span>This setting can be used to force the device to use authenticated communications when tools such as standalone RIFT are being used</span>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</ng-template>
<ng-template [ngIf]="true">
    <div fxLayout.gt-sm="row"
        fxLayout.lt-md="column"
        style="margin-bottom: 40px;">
        <div ngClass.gt-sm="spacing-right"
            ngClass.lt-md="spacing-bottom"
            fxFlex.gt-sm="60"
            fxFlex.lt-md="100"
            fxLayout="column">
        </div>
    </div>
</ng-template>
