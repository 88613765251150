import { VideoSessionModel, IVideoSessionModel } from '@rift/models/restapi/VideoSession.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IVideoSessionInfoModel extends IRestModel {
    storageCapacityKB: number;
    storageFreeCapacityKB: number;
    sessions: Array<IVideoSessionModel>;
}

export class VideoSessionInfoModel extends BaseModel implements IVideoSessionInfoModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public storageCapacityKB: number = null;
    public storageFreeCapacityKB: number = null;
    public sessions: Array<VideoSessionModel> = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-VideoSessionInfoModel');

        this.sessions = RestModelUtility.loadFromArray(restModel.Sessions, VideoSessionModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
