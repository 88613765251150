import { animate, style, transition, trigger } from '@angular/animations';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, EmbeddedViewRef, Injector, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, HostBinding } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';

/**
 * Table actions tray.
 *
 * @export
 * @class TableActionsTrayComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'shr-table-actions-tray',
    templateUrl: './Table.ActionsTray.Component.html',
    styleUrls: ['./Table.ActionsTray.Component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ opacity: 0, width: '0%' }),
                animate('200ms ease-in', style({ width: '100%', opacity: 1 })),
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({ width: '0%', opacity: 0 })),
            ])
        ])
    ]
})
export class TableActionsTrayComponent extends BaseComponent implements OnInit, OnDestroy {
    public static className: string = 'TableActionsTrayComponent';

    @HostBinding()
    public id: string = 'shr-table-actions-tray';


    @ViewChild('actionsIcon', { static: true })
    public actionsIcon: MatIcon;

    @ViewChild('popOutOverlayTemplate', { static: true })
    public popOutOverlayTemplate: TemplateRef<any>;

    private _overlayRef: OverlayRef;
    private _overlayPortal: TemplatePortal<any>;
    private _overlayEmbeddedViewRef: EmbeddedViewRef<any>;
    private _animationRunning: boolean = false;

    public constructor(
        private readonly _viewContainerRef: ViewContainerRef,
        private readonly _overlay: Overlay,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.createResultsOverlay();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        if (!this.isNullOrUndefined(this._overlayRef)) {
            this._overlayRef.detach();
        }
    }

    public onClickOutside(): void {
        if (this._animationRunning === false) {
            this.detachOverlay();
        }
    }

    public onActionsIconClicked(): void {
        if (this.isNullOrUndefined(this._overlayEmbeddedViewRef)) {
            this.attachOverlay();
        } else {
            this.detachOverlay();
        }
    }

    public animStart(event): void {
        this._animationRunning = true;
    }

    public animEnd(event): void {
        this._animationRunning = false;
    }

    private attachOverlay(): void {
        if (this.isNullOrUndefined(this._overlayEmbeddedViewRef) && !this.isNullOrUndefined(this._overlayRef)) {
            this._overlayEmbeddedViewRef = this._overlayRef.attach(this._overlayPortal);
        }
    }

    private detachOverlay(): void {
        if (!this.isNullOrUndefined(this._overlayEmbeddedViewRef) && !this.isNullOrUndefined(this._overlayRef)) {
            this._overlayEmbeddedViewRef = null;
            this._overlayRef.detach();
        }
    }

    private createResultsOverlay(): void {
        const positionStrategy = this._overlay
            .position()
            .flexibleConnectedTo(this.actionsIcon._elementRef)
            .withPositions([{
                originX: 'end',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'top'
              } as ConnectedPosition])
            .withDefaultOffsetY(0)
            .withDefaultOffsetX(0);

        const scrollStrategy = this._overlay.scrollStrategies.reposition();

        this._overlayRef = this._overlay.create({ height: '40px', maxHeight: '40px', positionStrategy, scrollStrategy });
        this._overlayPortal = new TemplatePortal(this.popOutOverlayTemplate, this._viewContainerRef);
    }
}
