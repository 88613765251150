<div #mainContent
    id="rift-summary"
    class="rift-summary main-content scroll-content">
    <div fxLayout="row"
        class="spacing-bottom">
        <mat-card fxFlex="33"
            class="info-block spacing-right">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="center center">
                {{ isNullOrUndefined(upTime) ? 0 : upTime?.upTimeText }}
            </mat-card-title>
            <mat-card-content fxLayout="row"
                fxLayoutAlign="center center">
                Uptime
            </mat-card-content>
        </mat-card>
        <mat-card fxFlex="33"
            class="info-block spacing-right">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="center center">
                {{ isNullOrUndefined(diagnosticsCounts) ? 0 : diagnosticsCounts.warningsCount }}
            </mat-card-title>
            <mat-card-content fxLayout="row"
                fxLayoutAlign="center center">
                Warnings
            </mat-card-content>
        </mat-card>
        <mat-card fxFlex="33"
            class="info-block">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="center center">
                {{ isNullOrUndefined(diagnosticsCounts) ? 0 : diagnosticsCounts?.errorsCount }}
            </mat-card-title>
            <mat-card-content fxLayout="row"
                fxLayoutAlign="center center">
                Errors
            </mat-card-content>
        </mat-card>
    </div>
    <div fxLayout.gt-xs="row"
        fxLayout.lt-sm="column">
        <div ngClass.gt-xs="spacing-right"
            ngClass.lt-sm="spacing-bottom"
            fxFlex.gt-xs="50"
            fxFlex.lt-sm="100"
            fxLayout="column">
            <mat-card style="height:max-content;">
                <mat-card-title>
                    Installation Details
                </mat-card-title>
                <mat-card-content>
                    <div fxLayout="column"
                        fxLayoutAlign="start stretch">
                        <div class="row"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxFlex.lt-md="100px"
                                fxFlex.gt-sm="200px"
                                class="label">
                                Model
                            </div>
                            <div class="value">
                                {{ connectedDevice?.fullProductID }}
                            </div>
                        </div>
                        <div class="row"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxFlex.lt-md="100px"
                                fxFlex.gt-sm="200px"
                                class="label">
                                Device ID
                            </div>
                            <div class="value">
                                {{ global?.deviceId }}
                            </div>
                        </div>
                        <div class="row"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxFlex.lt-md="100px"
                                fxFlex.gt-sm="200px"
                                class="label">
                                Device Name
                            </div>
                            <div class="value">
                                {{ global?.deviceName }}
                            </div>
                        </div>
                        <div class="row"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxFlex.lt-md="100px"
                                fxFlex.gt-sm="200px"
                                class="label">
                                Site ID
                            </div>
                            <div class="value">
                                {{ global?.siteId }}
                            </div>
                        </div>
                        <div class="row"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxFlex.lt-md="100px"
                                fxFlex.gt-sm="200px"
                                class="label">
                                Site Name
                            </div>
                            <div class="value">
                                {{ global?.siteName }}
                            </div>
                        </div>
                        <div class="row"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxFlex.lt-md="100px"
                                fxFlex.gt-sm="200px"
                                class="label">
                                User String
                            </div>
                            <div class="value">
                                {{ global?.userString }}
                            </div>
                        </div>
                        <div class="row"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxFlex.lt-md="100px"
                                fxFlex.gt-sm="200px"
                                class="label">
                                Nodes
                            </div>
                            <div class="value">
                                {{ nodeCount }}
                            </div>
                        </div>
                        <div class="row"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxFlex.lt-md="100px"
                                fxFlex.gt-sm="200px"
                                class="label">
                                Timezone
                            </div>
                            <div class="value">
                                {{ timeInfo?.timezoneIrisysString }}
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex.gt-xs="50"
            fxFlex.lt-sm="100"
            fxLayout="column">
            <mat-card id="rightCard">
                <mat-card-title>
                    More Information
                </mat-card-title>
                <mat-card-content>
                    <div fxLayout="column"
                        fxLayoutAlign="start stretch">
                        <div class="row"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxFlex.lt-md="100px"
                                fxFlex.gt-sm="200px"
                                class="label">
                                Build Version
                            </div>
                            <div class="value">
                                {{ connectedDevice?.mainImageVersion }}
                            </div>
                        </div>
                        <div *ngIf="(isIPEnabled() | async) === true"
                            class="row"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxFlex.lt-md="100px"
                                fxFlex.gt-sm="200px"
                                class="label">
                                IP Address
                            </div>
                            <div class="value">
                                {{ assignedIpSetup?.dHCP ? (assignedIpSetup.iPAddress === '' ? 'DHCP (Address unknown)' : 'DHCP (' + assignedIpSetup?.iPAddress + ')') : assignedIpSetup?.iPAddress }}
                            </div>
                        </div>
                        <div *ngIf="(isIPEnabled() | async) === true"
                            class="row"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxFlex.lt-md="100px"
                                fxFlex.gt-sm="200px"
                                class="label">
                                MAC Address
                            </div>
                            <div class="value">
                                {{ ipSetup?.mACAddress }}
                            </div>
                        </div>
                        <div class="cap-img"
                            fxLayout="row"
                            fxLayoutAlign="start center">
                            <div fxFlex
                                class="capabilities"
                                fxLayout="column"
                                fxLayoutAlign="start stretch">
                                <div class="title row"
                                    fxLayout="row"
                                    fxLayoutAlign="start center">
                                    Device Capabilities
                                </div>
                                <div class="row"
                                    fxLayout="row"
                                    fxLayoutAlign="start center">
                                    <span class="fa-stack spacing-right">
                                        <i class="fab fa-bluetooth fa-stack-1x"></i>
                                        <i class="fas fa-ban fa-stack-2x ban"
                                            *ngIf="isNullOrUndefined(global) || !global.hasCapability(DeviceCapabilitiesEnum.bluetooth)"></i>
                                    </span>
                                    <div class="value capability">
                                        {{ global?.hasCapability(DeviceCapabilitiesEnum.bluetooth) ?
                                    'Bluetooth' : 'No bluetooth'
                                    }}
                                    </div>
                                </div>
                                <div class="row"
                                    fxLayout="row"
                                    fxLayoutAlign="start center">
                                    <span class="fa-stack spacing-right">
                                        <i class="fas fa-camera fa-stack-1x"></i>
                                        <i class="fas fa-ban fa-stack-2x ban"
                                            *ngIf="isNullOrUndefined(global) || !global.hasCapability(DeviceCapabilitiesEnum.video)"></i>
                                    </span>
                                    <div class="value capability">
                                        {{ global?.hasCapability(DeviceCapabilitiesEnum.video) ? 'Video' : 'No Video' }}
                                    </div>
                                </div>
                                <div class="row"
                                    fxLayout="row"
                                    fxLayoutAlign="start center">
                                    <span class="fa-stack spacing-right">
                                        <i class="fas fa-share-square fa-stack-1x"></i>
                                        <i class="fas fa-ban fa-stack-2x ban"
                                            *ngIf="isNullOrUndefined(global) || (!global.hasCapability(DeviceCapabilitiesEnum.relayIn) && !global.hasCapability(DeviceCapabilitiesEnum.relayOut) && !global.hasCapability(DeviceCapabilitiesEnum.canIo))"></i>
                                    </span>
                                    <div class="value capability">
                                        {{relaySupportText}}
                                    </div>
                                </div>
                                <div class="row"
                                    fxLayout="row"
                                    fxLayoutAlign="start center">
                                    <span class="fa-stack spacing-right">
                                        <i class="fas fa-arrows-alt-v fa-stack-1x"></i>
                                        <i class="fas fa-ban fa-stack-2x ban"
                                            *ngIf="isNullOrUndefined(global) || !global.hasCapability(DeviceCapabilitiesEnum.height)"></i>
                                    </span>
                                    <div class="value capability">
                                        {{ global?.hasCapability(DeviceCapabilitiesEnum.height) ? 'Height measuring' : 'No height measuring' }}
                                    </div>
                                </div>
                                <div class="row"
                                    fxLayout="row"
                                    fxLayoutAlign="start center">
                                    <span class="fa-stack spacing-right">
                                        <img style="padding-left: 11px; padding-top: 6px;"
                                            width="14"
                                            height="14"
                                            src="/assets/angle.png">
                                        <i class="fas fa-ban fa-stack-2x ban"
                                            *ngIf="isNullOrUndefined(global) || !global.hasCapability(DeviceCapabilitiesEnum.tilt)"></i>
                                    </span>
                                    <div class="value capability">
                                        {{ global?.hasCapability(DeviceCapabilitiesEnum.tilt) ? 'Tilt' : 'No tilt' }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img fxShow
                                    fxHide.lt-sm="true"
                                    *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.video) | async) === true"
                                    [src]="isNullOrUndefined(imageSnapshot) ? 'assets/blank.png' : imageSnapshot"
                                    style="max-height:180px;">

                                <img fxHide
                                    fxShow.lt-sm="true"
                                    *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.video) | async) === true"
                                    [src]="isNullOrUndefined(imageSnapshot) ? 'assets/blank.png' : imageSnapshot"
                                    style="max-height:80px;">
                            </div>
                        </div>
                        <div *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.videoRecording) | async) === true" 
                            class="videoStorage"
                            fxLayout="column"
                            fxLayoutAlign="start stretch">
                            <div class="title row"
                                fxLayout="row"
                                fxLayoutAlign="start center">
                                Video Storage
                            </div>
                        </div>
                        <ng-template ngFor
                            let-deviceInfo
                            [ngForOf]="deviceInfos">
                            <div class="videoStorage"
                                fxLayout="column"
                                fxLayoutAlign="start stretch">
                                <div class="title row"
                                    fxLayout="row"
                                    fxLayoutAlign="start center">
                                    {{deviceInfo.serial}} ({{ deviceInfo.isMaster ? 'Master' : 'Node' }})
                                </div>
                                <ng-template
                                    [ngIf]="!isNullOrUndefined(deviceInfo.videoStorageCapacity) && deviceInfo.videoStorageCapacity.noSDCard === false && deviceInfo.videoStorageCapacity.sdCardNotFormatted === false">
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <mat-progress-bar
                                            [mode]="isNullOrUndefined(deviceInfo.videoStorageCapacity) ? 'indeterminate' : 'determinate'"
                                            [value]="100 - deviceInfo.videoStorageCapacity?.freePercent"></mat-progress-bar>
                                    </div>
                                    <div fxLayout="row"
                                        fxLayoutAlign="start center">
                                        {{ deviceInfo.videoStorageCapacity?.deviceStorageFreeCapacityKBHumanized + ' (free) / ' + deviceInfo.videoStorageCapacity?.deviceStorageCapacityKBHumanized + ' (total)' }}
                                    </div>
                                </ng-template>
                                <ng-template
                                    [ngIf]="!isNullOrUndefined(deviceInfo.videoStorageCapacity) && deviceInfo.videoStorageCapacity.storageWarning === true">
                                    <div class="warning">Warning: Storage is getting low</div>
                                </ng-template>
                                <ng-template
                                    [ngIf]="!isNullOrUndefined(deviceInfo.videoStorageCapacity) && deviceInfo.videoStorageCapacity.noSDCard === true">
                                    <div class="error">No video storage present</div>
                                </ng-template>
                                <ng-template
                                    [ngIf]="!isNullOrUndefined(deviceInfo.videoStorageCapacity) && deviceInfo.videoStorageCapacity.sdCardNotFormatted === true">
                                    <div class="error">Video storage not formatted </div>
                                </ng-template>
                                <ng-template
                                    [ngIf]="!isNullOrUndefined(deviceInfo.videoStorageCapacity) && deviceInfo.videoStorageCapacity.storageError === true">
                                    <div class="error">Not enough storage to record</div>
                                </ng-template>
                            </div>
                        </ng-template>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>