import { Component, Inject, Injector, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@shared/base/Base.Component';
import { IAutoSpeedSettings } from '@rift/components/validation/Validation.Defaults';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

export class AutoSpeedSettingsDialogData {
    public constructor(public readonly autoSpeedSettings: IAutoSpeedSettings) { }
}

export class AutoSpeedSettingsDialogResult {
    public constructor(public readonly autoSpeedSettings: IAutoSpeedSettings) { }
}

@Component({
    selector: 'rift-auto-speed-settings-dialog',
    templateUrl: './AutoSpeedSettings.Dialog.Component.html',
    styleUrls: ['./AutoSpeedSettings.Dialog.Component.scss']
})
export class AutoSpeedSettingsDialogComponent extends BaseComponent {
    public static className: string = 'AutoSpeedSettingsDialogComponent';

    public autoSpeedFormGroup: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'rift-auto-speed-settings-dialog';

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: AutoSpeedSettingsDialogData,
        private readonly _dialogRef: MatDialogRef<AutoSpeedSettingsDialogComponent>,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.formValuesChangeProcess = this.processMonitorService.getProcess(AutoSpeedSettingsDialogComponent.className, 'Form values change');

        this.autoSpeedFormGroup = this._formBuilder.group({
            noTargetsSpeed: [this.data.autoSpeedSettings.noTargetsSpeed, Validators.compose([Validators.required, Validators.min(1), Validators.max(40)])],
            targetsSpeed: [this.data.autoSpeedSettings.targetsSpeed, Validators.compose([Validators.required, Validators.min(0), Validators.max(40)])],
            inWindow: [this.data.autoSpeedSettings.inWindow, Validators.compose([Validators.required, Validators.min(1), Validators.max(20)])],
            outWindow: [this.data.autoSpeedSettings.outWindow, Validators.compose([Validators.required, Validators.min(1), Validators.max(20)])],
        });
        this.formGroupTracker.track(this.autoSpeedFormGroup);

        this.addSubscription(this.observableHandlerBase(this.autoSpeedFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesAutoSpeedFormGroup()), this.formValuesChangeProcess);
    }

    public close(): void {
        this._dialogRef.close(new AutoSpeedSettingsDialogResult(this.data.autoSpeedSettings));
    }

    private updateModelValuesAutoSpeedFormGroup(): void {
        if (!this.isNullOrUndefined(this.data.autoSpeedSettings)) {
            const formValues = this.autoSpeedFormGroup.getRawValue();

            this.data.autoSpeedSettings.noTargetsSpeed = formValues.noTargetsSpeed;
            this.data.autoSpeedSettings.targetsSpeed = formValues.targetsSpeed;
            this.data.autoSpeedSettings.inWindow = formValues.inWindow;
            this.data.autoSpeedSettings.outWindow = formValues.outWindow;
        }
    }
}
