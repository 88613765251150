import { IRestModel } from '@shared/interface/IRestModel';
import { RegisterBaseModel, REGISTER_BASE_EXCLUDE_PROPERTIES } from '@rift/models/restapi/RegisterBase.Model';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MarkedTargetEnum } from '@shared/enum/MarkedTarget.Enum';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterPortalModel extends IRegisterBaseModel {
    startPolygon: number;
    endPolygon: number;
    markedTarget: MarkedTargetEnum;
    heightFilterEnabled: boolean;
    lowerBound: number;
    upperBound: number;
}

export class RegisterPortalModel extends RegisterBaseModel implements IRegisterPortalModel, IRestModel {
    public startPolygon: number = null;
    public endPolygon: number = null;
    public markedTarget: MarkedTargetEnum = null;
    public heightFilterEnabled: boolean = null;
    public lowerBound: number = null;
    public upperBound: number = null;

    public constructor() {
        super();

        this.registerProperty('startPolygon');
        this.registerProperty('endPolygon');
        this.registerProperty('markedTarget');
        this.registerProperty('heightFilterEnabled');
        this.registerProperty('lowerBound');
        this.registerProperty('upperBound');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterPortalModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('startPolygon', this.startPolygon);
        this.setPropertyOriginalValue('endPolygon', this.endPolygon);
        this.setPropertyOriginalValue('markedTarget', this.markedTarget);
        this.setPropertyOriginalValue('heightFilterEnabled', this.heightFilterEnabled);
        this.setPropertyOriginalValue('lowerBound', this.lowerBound);
        this.setPropertyOriginalValue('upperBound', this.upperBound);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], [], [this.startPolygon, this.endPolygon]);
    }
}
