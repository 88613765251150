import { AfterViewInit, EventEmitter, Injector, Directive, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { SafeSubscriptionBase } from '@shared/base/SafeSubscription.Base';
import { ErrorDialogComponent, ErrorDialogData } from '@shared/component/dialog/error/Error.Dialog.Component';
import { OkCancelDialogComponent, OkCancelDialogData } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent, PleaseWaitDialogData } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { UnsavedChangesDialogComponent, UnsavedChangesDialogDialogData, UnsavedChangesDialogResult } from '@shared/component/dialog/unsavedchanges/UnsavedChanges.Dialog.Component';
import { ChangeTrackerCollection } from '@shared/generic/ChangeTrackerCollection';
import { FormGroupTrackerCollection } from '@shared/generic/FormGroupTrackerCollection';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { NavBarService } from '@shared/service/navbar/NavBar.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorService } from '@shared/service/processmonitor/ProcessMonitor.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { UserNotificationService } from '@shared/service/usernotification/UserNotification.Service';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { isArray, isBoolean, isFunction, isNull, isNullOrUndefined, isUndefined } from '@shared/utility/General.Utility';
import { IdUtil } from '@shared/utility/Id.Util';
import { StringUtility } from '@shared/utility/String.Utility';
import { ValueUtility } from '@shared/utility/Value.Utility';
import { ValidationMessageConfig } from '@shared/validation/Validation.MessageConfig';
import { ValidationMessages } from '@shared/validation/Validation.Messages';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, flatMap } from 'rxjs/operators';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

export interface IBaseDialogRef {
    ErrorDialogRef: MatDialogRef<ErrorDialogComponent>;
    InvalidDialogRef: MatDialogRef<OkCancelDialogComponent>;
    PleaseWaitDialogRef: MatDialogRef<PleaseWaitDialogComponent>;
    OkCancelDialogRef: MatDialogRef<OkCancelDialogComponent>;
    NoChangesDialogRef: MatDialogRef<OkCancelDialogComponent>;
    UnsavedChangesDialogRef: MatDialogRef<UnsavedChangesDialogComponent>;
}

/**
 * The component base class.
 *
 * @export
 * @abstract
 * @class BaseComponent
 * @extends {SafeSubscriptionBase}
 * @implements {AfterViewInit}
 */
@Directive()
export abstract class BaseComponent extends SafeSubscriptionBase implements AfterViewInit, OnDestroy, OnInit {

    public abstract id: string;
    public loadDataProcess: ProcessMonitorServiceProcess;
    public readonly IdUtil = IdUtil;
    public readonly changeTracker: ChangeTrackerCollection = new ChangeTrackerCollection();

    public readonly configurationService: ConfigurationService;
    public readonly focusEventEmitter = new EventEmitter<boolean>();
    public readonly formGroupTracker: FormGroupTrackerCollection = new FormGroupTrackerCollection();
    public readonly isEmptyOrWhiteSpace = StringUtility.isEmptyOrWhiteSpace;
    public readonly isNull = isNull;
    public readonly isNullOrUndefined = isNullOrUndefined;
    public readonly isUndefined = isUndefined;
    public readonly loadDataProcessText = 'Loading data';
    public readonly navBarService: NavBarService;
    public readonly processMonitorService: ProcessMonitorService;
    public readonly saveAllChangesProcessText = 'Saving changes';
    public readonly userNotificationService: UserNotificationService;
    public readonly userCurrentService: UserCurrentService;
    public saveAllAction: NavBarAction;
    public saveAllChangesProcess: ProcessMonitorServiceProcess;

    private _processes: ProcessMonitorServiceProcess[] = [];

    /**
     * Creates an instance of BaseComponent.
     *
     * @param {Injector} _injectorBase
     * @memberof BaseComponent
     */
    /**
     * Creates an instance of BaseComponent.
     *
     * @param {Injector} _injectorBase Service to resolve dependency injection.
     * @param {MatDialog} [_dialogBase] Service to open Material Design modal dialogs.
     * @param {NavBarActionService} [_navBarServiceBase] Service to manage actions on the navigation bar.
     * @memberof BaseComponent
     */
    public constructor(
        private readonly _injectorBase: Injector,
        private readonly _dialogBase?: MatDialog,
        private readonly _navBarServiceBase?: NavBarActionService) {
        super();

        this.userCurrentService = this._injectorBase.get(UserCurrentService);
        this.processMonitorService = this._injectorBase.get(ProcessMonitorService);
        this.configurationService = this._injectorBase.get(ConfigurationService);
        this.userNotificationService = this._injectorBase.get(UserNotificationService);
        this.navBarService = this._injectorBase.get(NavBarService);
    }

    public asFormArray(control: AbstractControl): FormArray {
        return control as FormArray;
    }

    public asFormGroup(control: AbstractControl): FormGroup {
        return control as FormGroup;
    }

    public get userIsInstaller(): Observable<boolean> {
        return !this.isNullOrUndefined(this.userCurrentService) ? this.userCurrentService.isInstaller : of(false);
    }

    public get userIsSystemAdmin(): Observable<boolean> {
        return !this.isNullOrUndefined(this.userCurrentService) ? this.userCurrentService.isSystemAdmin : of(false);
    }

    public get userIsSystemManager(): Observable<boolean> {
        return !this.isNullOrUndefined(this.userCurrentService) ? this.userCurrentService.isSystemManager : of(false);
    }

    public get userIsAdmin(): Observable<boolean> {
        return !this.isNullOrUndefined(this.userCurrentService) ? this.userCurrentService.isAdmin : of(false);
    }

    public get userIsUser(): Observable<boolean> {
        return !this.isNullOrUndefined(this.userCurrentService) ? this.userCurrentService.isUser : of(false);
    }

    /**
     * Adds a subscription to be disposed when the component is destroyed.
     *
     * @param {Subscription} subscription The subscription to add for disposal.
     * @param {ProcessMonitorServiceProcess} [process] The process to associate with the subscription.
     * @returns {Subscription} The added subscription.
     * @memberof BaseComponent
     */
    public addSubscription(subscription: Subscription, process?: ProcessMonitorServiceProcess): Subscription {
        this.registerProcess(process);
        return super.addSubscription(subscription, process);
    }

    /**
     * Base implementation of deactivation. checking if component has changes and can be deactivated.
     *
     * @param {ISaveAllChanges} iSaveAllChanges The top level save all changes implementation.
     * @returns {(boolean | Observable<boolean>)}
     * @memberof BaseComponent
     */
    public deactivateBase(iSaveAllChanges: ISaveAllChanges): Observable<boolean> {
        if (iSaveAllChanges.hasChanges === true) {
            return of(false);
        }
        return of(true);
    }

    /**
     * Gets to priority error message for control within component.
     *
     * @param {(FormControl | AbstractControl)} control The control to ger error for.
     * @returns {string} The error message.
     * @memberof BaseComponent
     */
    public getFormControlErrorMessage(control: FormControl | AbstractControl | FormGroup): string {
        if (!isNullOrUndefined(control) && !isNullOrUndefined(control.errors)) { // && (control.dirty || control.touched)
            const configs: ValidationMessageConfig[] = [];
            const validators: any[] = [];

            for (const propertyName in control.errors) {
                if (control.errors.hasOwnProperty(propertyName) && control.touched) {
                    const config = ValidationMessages.getValidatorConfig(propertyName);
                    if (!isNullOrUndefined(config)) {
                        configs.push(config);
                        validators.push(control.errors[propertyName]);
                    }
                }
            }

            const index = ArrayUtility.indexOfMax(configs, 'priority');
            if (!isNullOrUndefined(index)) {
                const config = configs[index];
                if (!isNullOrUndefined(config)) {
                    const message = config.getMessage(control, validators[index]);
                    return message;
                }
            }

            // there is no config for the validator just try and get first error.
            if (Object.keys(control.errors).length > 0) {
                if (!isNullOrUndefined(control.errors[Object.keys(control.errors)[0]].message)) {
                    const message = control.errors[Object.keys(control.errors)[0]].message;
                    return message;
                }
            }
        }
        return StringUtility.empty;
    }

    /**
     * Check if any of the tracked items in changeTracker have changes.
     *
     * @readonly
     * @type {boolean}
     * @memberof BaseComponent
     */
    public get hasChangesBase(): boolean {
        return this.changeTracker.hasChanges;
    }

    /**
     * Check if all of the tracked items in formGroupTracker are valid.
     *
     * @readonly
     * @type {boolean}
     * @memberof BaseComponent
     */
    public get isValidBase(): boolean {
        return this.formGroupTracker.valid;
    }

    public ngAfterViewInit() {
        this.focusEventEmitter.emit(true);
    }

    public ngOnDestroy(): void {
        this.closeAllProcesses();

        super.ngOnDestroy();

        if (!this.isNullOrUndefined(this._navBarServiceBase) && !this.isNullOrUndefined(this.saveAllAction)) {
            this._navBarServiceBase.removeAction(this.saveAllAction);
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();

        if (!this.isNullOrUndefined(this._navBarServiceBase) && !this.isNullOrUndefined(this.saveAllAction)) {
            this._navBarServiceBase.addAction(this.saveAllAction);
        }
    }

    /**
     * Adds the save all changes action to the toolbar menu.
     *
     * @protected
     * @param {ISaveAllChanges} iSaveAllChanges The top level save all changes implementation.
     * @param [saveAllChangesClickHandler] The save changes handler.
     * @memberof BaseComponent
     */
    protected addSaveAllAction(iSaveAllChanges: ISaveAllChanges, saveAllChangesClickHandler?: () => void): void {
        if (this.isNullOrUndefined(this._navBarServiceBase)) {
            throw new Error('_navBarServiceBase must be passed to BaseComponent super()');
        }

        this.saveAllAction = new NavBarAction();
        this.saveAllAction.isSaveChanges = true;
        this.saveAllAction.disabled = true;
        this.saveAllAction.name = 'savechanges';
        this.saveAllAction.text = 'Save All Changes';
        this.addSubscription(this.saveAllAction.onButtonClick.subscribe(() => {
            if (!this.isNullOrUndefined(saveAllChangesClickHandler) && isFunction(saveAllChangesClickHandler)) {
                saveAllChangesClickHandler();
            } else {
                this.saveAllChangesStartBase(iSaveAllChanges, this.openPleaseWaitSavingDialog());
            }
        }), this.saveAllChangesProcess);
    }

    /**
     * Checks a zipped observable of boolean to ensure all results are true.
     *
     * @protected
     * @param {(boolean | Array<boolean>)} result The array of results to check.
     * @returns {boolean}
     * @memberof BaseComponent
     */
    protected isZipResultSuccess(result: boolean | Array<boolean>): boolean {
        if ((isArray(result) && (result as Array<boolean>).every(i => i === true)) || (isBoolean(result) && result === true)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Generic Observable handler.
     *
     * @protected
     * @template T
     * @param {Observable<T>} action The observable to add base handler to.
     * @param {ProcessMonitorServiceProcess} process THe process the observable is executing in.
     * @returns {Observable<T>}
     * @memberof BaseComponent
     */
    protected observableHandlerBase<T>(action: Observable<T>, process: ProcessMonitorServiceProcess): Observable<T> {
        this.registerProcess(process);

        return of(null).pipe(
            flatMap(
                () => {
                    if (!isNullOrUndefined(process)) {
                        process.started();
                    }
                    return action.pipe(
                        catchError(error => {
                            this.openErrorDialog('Error', null, error, process);
                            return action;
                        }),
                        finalize(() => {
                            if (!isNullOrUndefined(process)) {
                                process.completed();
                            }
                        }),
                    );
                }
            )
        );
    }

    /**
     * Open a invalid input dialog.
     *
     * @protected
     * @returns {MatDialogRef<OkCancelDialogComponent>}
     * @memberof BaseComponent
     */
    protected openInvalidDialog(): MatDialogRef<OkCancelDialogComponent> {
        if (this.isNullOrUndefined(this._dialogBase)) {
            throw new Error('_dialogBase must be passed to BaseComponent super()');
        }
        return this._dialogBase.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Input Invalid', 'There is one or more invalid value.', false), disableClose: true });
    }

    /**
     * Open a ok cancel dialog.
     *
     * @protected
     * @param {string} title The title of the dialog
     * @param {string} message The message to show
     * @param {boolean} [showCancel] True if the cancel button should be shown.
     * @returns {MatDialogRef<OkCancelDialogComponent>}
     * @memberof BaseComponent
     */
    protected openOkCancelDialog(title: string, message: string, showCancel?: boolean): MatDialogRef<OkCancelDialogComponent> {
        if (this.isNullOrUndefined(this._dialogBase)) {
            throw new Error('_dialogBase must be passed to BaseComponent super()');
        }
        return this._dialogBase.open(OkCancelDialogComponent, { data: new OkCancelDialogData(title, message, showCancel), disableClose: true });
    }

    /**
     * Open a please wait dialog.
     *
     * @protected
     * @param {string} title The title of the dialog
     * @param {PleaseWaitDialogData} data The dialog data.
     * @returns {MatDialogRef<PleaseWaitDialogComponent>} The open state of the dialog.
     * @memberof BaseComponent
     */
    protected openPleaseWaitDialog(title: string, data?: PleaseWaitDialogData): MatDialogRef<PleaseWaitDialogComponent> {
        if (this.isNullOrUndefined(this._dialogBase)) {
            throw new Error('_dialogBase must be passed to BaseComponent super()');
        }
        return this._dialogBase.open(PleaseWaitDialogComponent, { data: !this.isNullOrUndefined(data) ? data : new PleaseWaitDialogData(title), disableClose: true, minWidth: 250 });
    }

    /**
     * Open a please wait dialog.
     *
     * @protected
     * @returns {MatDialogRef<PleaseWaitDialogComponent>}
     * @memberof BaseComponent
     */
    protected openPleaseWaitLoadingDialog(): MatDialogRef<PleaseWaitDialogComponent> {
        if (this.isNullOrUndefined(this._dialogBase)) {
            throw new Error('_dialogBase must be passed to BaseComponent super()');
        }
        return this._dialogBase.open(PleaseWaitDialogComponent, { data: new PleaseWaitDialogData('Please Wait Loading Data'), disableClose: true, minWidth: 250 });
    }

    /**
     * Open a please wait dialog.
     *
     * @protected
     * @returns {MatDialogRef<PleaseWaitDialogComponent>}
     * @memberof BaseComponent
     */
    protected openPleaseWaitSavingDialog(): MatDialogRef<PleaseWaitDialogComponent> {
        if (this.isNullOrUndefined(this._dialogBase)) {
            throw new Error('_dialogBase must be passed to BaseComponent super()');
        }
        return this._dialogBase.open(PleaseWaitDialogComponent, { data: new PleaseWaitDialogData('Please Wait Saving Data'), disableClose: true, minWidth: 250 });
    }

    /**
     * Open error dialog.
     *
     * @protected
     * @param {string} title The title of the dialog
     * @param {string} [errorMessage] The error message to show in the dialog.
     * @param {Error} [error] The {Error} that was thrown.
     * @param {ProcessMonitorServiceProcess} [process] The process the execution was running in.
     * @returns {MatDialogRef<ErrorDialogComponent>}
     * @memberof BaseComponent
     */
    protected openErrorDialog(title: string, errorMessage?: string, error?: Error, process?: ProcessMonitorServiceProcess, serial?: string): MatDialogRef<ErrorDialogComponent> {
        this.registerProcess(process);
        return this._dialogBase.open(ErrorDialogComponent, { data: new ErrorDialogData(title, error, errorMessage, process, false, serial) , disableClose: true });
    }

    /**
     * Open error loading data dialog.
     *
     * @protected
     * @param {Error} [error] The {Error} that was thrown.
     * @param {ProcessMonitorServiceProcess} [process] The process the execution was running in.
     * @returns {MatDialogRef<ErrorDialogComponent>}
     * @memberof BaseComponent
     */
    protected openErrorLoadingDataDialog(error?: Error, process?: ProcessMonitorServiceProcess): MatDialogRef<ErrorDialogComponent> {
        this.registerProcess(process);

        if (this.isNullOrUndefined(this._dialogBase)) {
            throw new Error('_dialogBase must be passed to BaseComponent super()');
        }
        return this._dialogBase.open(ErrorDialogComponent, { data: new ErrorDialogData('Error Loading Data', error, 'The page was unable to load data', process), disableClose: true });
    }

    /**
     * Open error saving data dialog.
     *
     * @protected
     * @param {Error} [error] The {Error} that was thrown.
     * @param {ProcessMonitorServiceProcess} [process] The process the execution was running in.
     * @returns {MatDialogRef<ErrorDialogComponent>}
     * @memberof BaseComponent
     */
    protected openErrorSavingChangesDialog(error?: Error, process?: ProcessMonitorServiceProcess): MatDialogRef<ErrorDialogComponent> {
        this.registerProcess(process);

        if (this.isNullOrUndefined(this._dialogBase)) {
            throw new Error('_dialogBase must be passed to BaseComponent super()');
        }
        return this._dialogBase.open(ErrorDialogComponent, { data: new ErrorDialogData('Error Saving Changes', error, 'The changes were not saved', process), disableClose: true });
    }

    /**
     * Open no changes dialog.
     *
     * @protected
     * @returns {MatDialogRef<OkCancelDialogComponent>}
     * @memberof BaseComponent
     */
    protected openNoChangesDialog(): MatDialogRef<OkCancelDialogComponent> {
        if (this.isNullOrUndefined(this._dialogBase)) {
            throw new Error('_dialogBase must be passed to BaseComponent super()');
        }
        return this._dialogBase.open(OkCancelDialogComponent, { data: new OkCancelDialogData('No Changes', 'There are no changes to save.', false), disableClose: true });
    }

    /**
     * Open unsaved changes dialog.
     *
     * @protected
     * @returns {MatDialogRef<UnsavedChangesDialogComponent>}
     * @memberof BaseComponent
     */
    protected openUnsavedChangesDialog(): MatDialogRef<UnsavedChangesDialogComponent> {
        if (this.isNullOrUndefined(this._dialogBase)) {
            throw new Error('_dialogBase must be passed to BaseComponent super()');
        }
        return this._dialogBase.open(UnsavedChangesDialogComponent, { data: new UnsavedChangesDialogDialogData(), disableClose: true });
    }

    /**
     * Show save changes warning when navigating away from component.
     *
     * @protected
     * @param {ISaveAllChanges} iSaveAllChanges The top level save all changes implementation.
     * @param {() => Observable<boolean>} [discard] Action called if changes are discarded.
     * @returns {(boolean | Observable<boolean>)}
     * @memberof BaseComponent
     */
    protected showSaveChangesWarningBase(iSaveAllChanges: ISaveAllChanges, discard?: () => Observable<boolean>): Observable<boolean> {
        const openRequest = this.openUnsavedChangesDialog();

        return openRequest.afterClosed().pipe(
            flatMap((result: UnsavedChangesDialogResult): Observable<boolean> => {
                if (!this.isNullOrUndefined(result)) {
                    if (result.save) {
                        openRequest.close();
                        return iSaveAllChanges.saveAllChanges();
                    } else if (result.discard) {
                        if (!this.isNullOrUndefined(discard)) {
                            openRequest.close();
                            this.changeTracker.clearChanges();
                            return discard();
                        } else {
                            openRequest.close();
                            this.changeTracker.clearChanges();
                            return of(true);
                        }
                    } else if (result.stay) {
                        openRequest.close();
                        return of(false);
                    }
                } else {
                    if (!this.isNullOrUndefined(discard)) {
                        openRequest.close();
                        this.changeTracker.clearChanges();
                        return discard();
                    } else {
                        openRequest.close();
                        this.changeTracker.clearChanges();
                        return of(true);
                    }
                }
            }),
        );
    }

    /**
     * Shows relevant loading and error dialogs thrown by the iLoadDate implementation.
     *
     * @protected
     * @param {ILoadDate} iLoadDate The top level load data implementation.
     * @param {(Observable<boolean | boolean[]>)} loadDataZipSub The zipped load data observable.
     * @param {MatDialogRef<PleaseWaitDialogComponent>} [pleaseWaitDialogRequest] The please wait dialog ref.
     * @param {ProcessMonitorServiceProcess} [process] The process the execution is running in.
     * @returns {Observable<boolean>}
     * @memberof BaseComponent
     */
    protected loadDataBase(iLoadDate: ILoadDate, loadDataZipSub: Observable<boolean | boolean[]>, pleaseWaitDialogRequest?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this.registerProcess(process);

        return loadDataZipSub.pipe(
            catchError(error => {
                if (!this.isNullOrUndefined(pleaseWaitDialogRequest)) {
                    pleaseWaitDialogRequest.close();
                }
                this.openErrorLoadingDataDialog(error, process);
                if (!this.isNullOrUndefined(process)) {
                    process.error(error);
                }
                return of(false);
            }),
            flatMap(getAllSubResult => {
                this.formGroupTracker.markAllAsTouched();
                if (this.isZipResultSuccess(getAllSubResult)) {
                    if (!this.isNullOrUndefined(pleaseWaitDialogRequest)) {
                        pleaseWaitDialogRequest.close();
                    }
                    return of(true);
                } else {
                    if (!this.isNullOrUndefined(pleaseWaitDialogRequest)) {
                        pleaseWaitDialogRequest.close();
                    }
                    return of(false);
                }
            })
        );
    }

    /**
     * Shows relevant saving and error dialogs thrown by the iSaveAllChanges implementation.
     *
     * @protected
     * @param {ISaveAllChanges} iSaveAllChanges The top level save all changes implementation.
     * @param {(Observable<boolean | boolean[]>)} saveAllZipSub The zipped save data observable.
     * @param {MatDialogRef<PleaseWaitDialogComponent>} [pleaseWaitDialogRequest] The please wait dialog ref.
     * @param {ProcessMonitorServiceProcess} [process] The process the execution is running in.
     * @returns {Observable<boolean>}
     * @memberof BaseComponent
     */
    protected saveAllChangesBase(iSaveAllChanges: ISaveAllChanges, saveAllZipSub: Observable<boolean | boolean[]>, pleaseWaitDialogRequest?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this.registerProcess(process);

        if (iSaveAllChanges.isValid === true) {
            if (iSaveAllChanges.hasChanges === true) {
                return saveAllZipSub.pipe(
                    catchError(error => {
                        if (!this.isNullOrUndefined(pleaseWaitDialogRequest)) {
                            pleaseWaitDialogRequest.close();
                        }
                        this.openErrorSavingChangesDialog(error, process);
                        return of(false);
                    }),
                    flatMap(saveAllSubResult => {
                        if (this.isZipResultSuccess(saveAllSubResult)) {
                            this.changeTracker.commitChanges();
                            if (!this.isNullOrUndefined(this.saveAllAction)) {
                                this.updateSaveAllAction(iSaveAllChanges);
                            }
                            if (!this.isNullOrUndefined(pleaseWaitDialogRequest)) {
                                pleaseWaitDialogRequest.close();
                            }
                            return of(true);
                        } else {
                            if (!this.isNullOrUndefined(pleaseWaitDialogRequest)) {
                                pleaseWaitDialogRequest.close();
                            }
                            this.openErrorSavingChangesDialog(null, process);
                            return of(false);
                        }
                    })
                );
            } else {
                if (!this.isNullOrUndefined(pleaseWaitDialogRequest)) {
                    pleaseWaitDialogRequest.close();
                }
                this.openNoChangesDialog();
                return of(true);
            }
        } else {
            if (!this.isNullOrUndefined(pleaseWaitDialogRequest)) {
                pleaseWaitDialogRequest.close();
            }
            this.openInvalidDialog();
            return of(false);
        }
    }

    /**
     * Starts the load of component data and wraps up is subscription.
     * Shows relevant loading and error dialogs thrown by the iLoadDate implementation.
     *
     * @protected
     * @param {ILoadDate} iLoadDate The top level load data implementation.
     * @param {MatDialogRef<PleaseWaitDialogComponent>} [pleaseWaitDialogRequest] The please wait dialog ref.
     * @memberof BaseComponent
     */
    protected loadDataStartBase(iLoadDate: ILoadDate, pleaseWaitDialogRequest?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): void {
        this.registerProcess(process);

        if (this.isNullOrUndefined(process) && this.isNullOrUndefined(this.loadDataProcess)) {
            throw new Error('loadDataProcess must be initialized in BaseComponent to call loadDataStartBase');
        }
        const useProcess = this.isNullOrUndefined(process) ? this.loadDataProcess : process;
        this.addSubscription(this.observableHandlerBase(iLoadDate.loadData(pleaseWaitDialogRequest, useProcess), useProcess).subscribe(), useProcess);
    }

    /**
     * Starts the save of component data and wraps up is subscription.
     * Shows relevant saving and error dialogs thrown by the iSaveAllChanges implementation.
     *
     * @protected
     * @param {ISaveAllChanges} iSaveAllChanges The top level save all changes implementation.
     * @param {MatDialogRef<PleaseWaitDialogComponent>} [pleaseWaitDialogRequest] The please wait dialog ref.
     * @memberof BaseComponent
     */
    protected saveAllChangesStartBase(iSaveAllChanges: ISaveAllChanges, pleaseWaitDialogRequest?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): void {
        this.registerProcess(process);

        if (this.isNullOrUndefined(process) && this.isNullOrUndefined(this.saveAllChangesProcess)) {
            throw new Error('saveAllChangesProcess must be initialized in BaseComponent to call saveAllChangesStartBase');
        }
        const useProcess = this.isNullOrUndefined(process) ? this.saveAllChangesProcess : process;
        this.addSubscription(this.observableHandlerBase(iSaveAllChanges.saveAllChanges(pleaseWaitDialogRequest, useProcess), useProcess).subscribe(), useProcess);
    }

    /**
     * Updates the disabled state of the save all changes menu action.
     *
     * @protected
     * @param {ISaveAllChanges} iSaveAllChanges The top level save all changes implementation.
     * @memberof BaseComponent
     */
    protected updateSaveAllAction(iSaveAllChanges: ISaveAllChanges): void {
        this.formGroupTracker.markAllAsTouched();

        if (iSaveAllChanges.hasChanges === true && iSaveAllChanges.isValid === true) {
            this.saveAllAction.disabled = false;
        } else {
            this.saveAllAction.disabled = true;
        }
    }

    protected registerProcess(process?: ProcessMonitorServiceProcess): void {
        if (!this.isNullOrUndefined(process)) {
            const hasProcess = this._processes.find(p => !this.isNullOrUndefined(p) && p.id === process.id);
            if (isNullOrUndefined(hasProcess)) {
                this._processes.push(hasProcess);
            }
        }
    }

    private closeAllProcesses(): void {
        const length = this._processes.length;
        for (let i = 0; i < length; i++) {
            const process = this._processes[i];
            if (!this.isNullOrUndefined(process)) {
                process.completed();
            }
        }
    }
}
