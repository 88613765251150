import { Component, EventEmitter, Injector, HostBinding, ViewChild, OnInit } from '@angular/core';
import { UnitsOfMeasurementService } from '@rift/service/unitsofmeasurement/UnitsOfMeasurement.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';
import { INavBarMenuComponent } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { MatMenu } from '@angular/material/menu';

@Component({
    selector: 'rift-units-of-measurement-menu-select',
    templateUrl: './UnitsOfMeasurementMenuSelect.Component.html',
    styleUrls: ['./UnitsOfMeasurementMenuSelect.Component.scss'],
})
export class UnitsOfMeasurementMenuSelectComponent extends BaseComponent implements INavBarMenuComponent {
    public static className: string = 'UnitsOfMeasurementMenuSelectComponent';

    @HostBinding()
    public id: string = 'rift-units-of-measurement-menu-select';

    @ViewChild(MatMenu, { static: true })
    public menu: MatMenu;

    public closeMenu: EventEmitter<null> = new EventEmitter<null>();

    public metric: boolean;

    public constructor(
        private readonly _unitsOfMeasurementService: UnitsOfMeasurementService,
        private readonly _injector: Injector) {
        super(_injector);

        this.metric = this._unitsOfMeasurementService.units === UnitsOfMeasurementEnum.metric;
    }

    public toggle(): void {
        this.metric = !this.metric;
        this._unitsOfMeasurementService.units = this.metric === true ? UnitsOfMeasurementEnum.metric : UnitsOfMeasurementEnum.imperial;
        this.closeMenu.emit();
    }
}
