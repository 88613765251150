export enum MasterModeEnum {
    auto = 0,
    master = 1,
    node = 2,
    proxyMaster = 3,
    uberMaster = 4,
}

export class MasterModeEnumHelpers {
    public static fromRestApi(value: string): MasterModeEnum {
        switch (value) {
            case 'Auto':
                return MasterModeEnum.auto;
            case 'Master':
                return MasterModeEnum.master;
            case 'Node':
                return MasterModeEnum.node;
            case 'ProxyMaster':
                return MasterModeEnum.proxyMaster;
            case 'UberMaster':
                return MasterModeEnum.uberMaster;
        }
    }

    public static toFullName(value: MasterModeEnum): string {
        switch (value) {
            case MasterModeEnum.auto:
                return 'Auto';
            case MasterModeEnum.master:
                return 'Master';
            case MasterModeEnum.node:
                return 'Node';
            case MasterModeEnum.proxyMaster:
                return 'ProxyMaster';
            case MasterModeEnum.uberMaster:
                return 'UberMaster';
            default:
                return 'Unknown';
        }
    }
}
