import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';

/**
 * Interface must be implemented by PendingChangesGuard components.
 *
 * @export
 * @interface OnDeactivate
 */
export interface OnDeactivate {
    /**
     * Fired on navigate away attempt.
     *
     * @memberof OnDeactivate
     * @returns True allow navigate away. False call showSaveChangesWarning.
     */
    deactivate: () => Observable<boolean>;
    /**
     * Show save changes dialog.
     *
     * @memberof OnDeactivate
     * @returns True allow navigate away. False prevent navigate away.
     */
    showSaveChangesWarning: () => Observable<boolean>;
}

/**
 * Service to call back to component when navigation away attempt is made
 * to check for changes.
 *
 * @export
 * @class PendingChangesGuard
 * @implements {CanDeactivate<OnDeactivate>}
 */
@Injectable()
export class PendingChangesGuard implements CanDeactivate<OnDeactivate> {
    /**
     * Creates an instance of PendingChangesGuard.
     *
     * @memberof PendingChangesGuard
     */
    public constructor() {
    }

    /**
     * Fired on navigate away attempt.
     *
     * @param {OnDeactivate} component
     * @returns {(boolean | Observable<boolean>)}
     * @memberof PendingChangesGuard
     */
    public canDeactivate(component: OnDeactivate): boolean | Observable<boolean> {
        return component.deactivate().pipe(
            flatMap(canDeactivate => {
                if (canDeactivate === false) {
                    return component.showSaveChangesWarning();
                } else {
                    return of(true);
                }
            })
        );
    }
}
