import { Routes } from '@angular/router';
import { MyAccountComponent } from '@em/components/myaccount/MyAccount.Component';
import { IRouteData } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { UserIsAuthenticated } from '@em/service/user/UserIsLevel';

export const EmMyAccountRoutes: Routes = [
    {
        path: 'myaccount',
        canActivate: [UserIsAuthenticated],
        component: MyAccountComponent,
        data: { breadcrumb: { name: 'em-myaccount', text: 'My Account' } } as IRouteData
    },
];
