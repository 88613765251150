import { Component, HostBinding, Injector, OnDestroy, OnInit } from '@angular/core';
import { ColumnFilterOptionModel } from '@em/models/restapi/ColumnFilterOption.Model';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { FirmwareSummaryModel } from '@em/models/restapi/FirmwareSummary.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { DeviceGroupEnum } from '@shared/enum/DeviceGroup.Enum';
import { DeviceTypeEnumHelpers, DeviceTypeEnum } from '@shared/enum/DeviceType.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import compareVersions from 'compare-versions';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'em-settings-firmware-version-summary',
    templateUrl: './Settings.FirmwareVersionSummary.Component.html',
    styleUrls: ['./Settings.FirmwareVersionSummary.Component.scss']
})
export class SettingsFirmwareVersionSummaryComponent extends BaseComponent implements OnInit, OnDestroy, ILoadDate {
    public static className: string = 'SettingsFirmwareVersionSummaryComponent';
    public DeviceTypeEnumHelpers = DeviceTypeEnumHelpers;

    public devices: Array<DeviceModel>;

    public getDevicesByGroupProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'em-settings-firmware-version-summary';
    public pageOptions: PaginationOptionsModel;
    public selectedSummary: FirmwareSummaryModel;

    public blackfinSummaries: FirmwareSummaryModel[];
    public gazelleSummaries: FirmwareSummaryModel[];
    public kestrelSummaries: FirmwareSummaryModel[];
    public falconSummaries: FirmwareSummaryModel[];

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _deviceService: DeviceService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsFirmwareVersionSummaryComponent.className, this.loadDataProcessText);
        this.getDevicesByGroupProcess = this.processMonitorService.getProcess(SettingsFirmwareVersionSummaryComponent.className, 'Getting devices');

        this.pageOptions = new PaginationOptionsModel();
        this.pageOptions.page = 1;
        this.pageOptions.resultsPerPage = 10;

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public getDevicesPage(): void {
        this.pageOptions.columnFilterOptions = [];

        const filter = new ColumnFilterOptionModel();

        filter.columnName = 'FirmwareVersion';
        filter.filter = this.selectedSummary.firmwareVersion;

        this.pageOptions.columnFilterOptions.push(filter);

        this.addSubscription(this.observableHandlerBase(this._deviceService.getByGroup(DeviceGroupEnum.all, this.pageOptions, this.getDevicesByGroupProcess), this.getDevicesByGroupProcess).subscribe(
            page => {
                this.devices = page.items;
                this.pageOptions = page.options;
            }
        ), this.getDevicesByGroupProcess);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._deviceService.getFirmwareVersionSummary(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.blackfinSummaries = this.versionSort(result.items.filter(i => i.type === DeviceTypeEnum.blackfin || i.type === DeviceTypeEnum.blackfinValCam));
                        this.gazelleSummaries = this.versionSort(result.items.filter(i => i.type === DeviceTypeEnum.gazelle));
                        this.kestrelSummaries = this.versionSort(result.items.filter(i => i.type === DeviceTypeEnum.kestrel));
                        this.falconSummaries = this.versionSort(result.items.filter(i => i.type === DeviceTypeEnum.falcon));

                        if (!this.isNullOrUndefined(this.blackfinSummaries) && this.blackfinSummaries.length > 0) {
                            this.summarySelected(this.blackfinSummaries[0]);
                        } else if (!this.isNullOrUndefined(this.gazelleSummaries) && this.gazelleSummaries.length > 0) {
                            this.summarySelected(this.gazelleSummaries[0]);
                        } else if (!this.isNullOrUndefined(this.kestrelSummaries) && this.kestrelSummaries.length > 0) {
                            this.summarySelected(this.kestrelSummaries[0]);
                        } else if (!this.isNullOrUndefined(this.falconSummaries) && this.falconSummaries.length > 0) {
                            this.summarySelected(this.falconSummaries[0]);
                        }
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public onPageOptionsChanged(pageOptions: PaginationOptionsModel): void {
        this.pageOptions = pageOptions;
        this.getDevicesPage();
    }

    public summarySelected(summary: FirmwareSummaryModel): void {
        this.selectedSummary = summary;
        this.getDevicesPage();
    }

    private versionSort(versions: FirmwareSummaryModel[]): FirmwareSummaryModel[] {
        return versions.sort((a, b) => {
            if (compareVersions.compare(a.firmwareVersion, b.firmwareVersion, '>')) {
                return -1;
            } else if (compareVersions.compare(a.firmwareVersion, b.firmwareVersion, '<')) {
                return 1;
            }
            return 0;
        });
    }
}
