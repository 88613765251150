import { N } from '@angular/cdk/keycodes';
import { Component, HostBinding, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DeviceSerialLinkComponent } from '@em/components/shared/deviceseriallink/DeviceSerialLink.Component';
import { NodeVideoEntryModel } from '@em/models/restapi/NodeVideoEntry.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { VideoEntryModel } from '@em/models/restapi/VideoEntry.Model';
import { VideoStateModel } from '@em/models/restapi/VideoState.Model';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { DeviceVideoDownloadService } from '@em/service/data/device/Device.Video.Download.Service';
import { RecordingsService } from '@em/service/data/recordings/Recordings.Service';
import { Video } from '@rift/components/shared/viewport/video/Video';
import { ConnectionService } from '@rift/service/connection/Connection.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { RecordingStateEnum } from '@shared/enum/RecordingState.Enum';
import { SynchronizationStateEnum } from '@shared/enum/SynchronizationState.Enum';
import { VideoDownloadStateEnum } from '@shared/enum/VideoDownloadState.Enum';
import { ProcessTracker } from '@shared/generic/ProcessTracker';
import { IViewModel } from '@shared/interface/IViewModel';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { IViewModelUtility } from '@shared/utility/IViewModel.Utility';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

class VideoEntryGroupViewModel{
    private _item: VideoEntryModel;

    public constructor(item: VideoEntryModel){
        this._item = item;
    }

    public get master(): VideoEntryModel{
        return this._item;
    }

    public get nodes(): NodeVideoEntryModel[]{
        const retVal: NodeVideoEntryModel[] = [];

        this._item.nodes.forEach(n => {
            retVal.push(n);
        });

        return retVal;
    }

    public get recordingGroupIsPlanned(): boolean{
        return this.master.recordingState === RecordingStateEnum.isPlanned &&
                (this.nodes.length === 0 || this.nodes.every(n => n.videoDataEntry?.recordingState === RecordingStateEnum.isPlanned));
    }

    public get recordingGroupIsRecorded(): boolean{
        return this.master.recordingState === RecordingStateEnum.isRecorded &&
                (this.nodes.length === 0 || this.nodes.every(n => n.videoDataEntry?.recordingState === RecordingStateEnum.isRecorded));
    }

    public get recordingGroupIsNew(): boolean{
        return this.master.recordingState === RecordingStateEnum.isNew &&
                (this.nodes.length === 0 || this.nodes.every(n => n.videoDataEntry?.recordingState === RecordingStateEnum.isNew));
    }

    public get syncGroupNotLocalOnly(): boolean{
        return this.master.synchronizationState !== SynchronizationStateEnum.localOnly &&
                (this.nodes.length === 0 || this.nodes.every(n => n.videoDataEntry?.synchronizationState !== SynchronizationStateEnum.localOnly));
    }

    public get downloadGroupNotDownloaded(): boolean{
        return this.master.downloadState === VideoDownloadStateEnum.notDownloaded &&
                (this.nodes.length === 0 || this.nodes.every(n => n.videoDataEntry?.downloadState === VideoDownloadStateEnum.notDownloaded));
    }

    public get downloadGroupDownloading(): boolean{
        return this.master.downloadState === VideoDownloadStateEnum.downloading &&
                (this.nodes.length === 0 || this.nodes.every(n => n.videoDataEntry?.downloadState === VideoDownloadStateEnum.downloading));
    }

    public get downloadGroupPaused(): boolean{
        return this.master.downloadState === VideoDownloadStateEnum.paused &&
                (this.nodes.length === 0 || this.nodes.every(n => n.videoDataEntry?.downloadState === VideoDownloadStateEnum.paused));
    }

    public get downloadGroupDownloaded(): boolean{
        return this.master.downloadState === VideoDownloadStateEnum.downloaded &&
                (this.nodes.length === 0 || this.nodes.every(n => n.videoDataEntry?.downloadState === VideoDownloadStateEnum.downloaded));
    }

    public get downloadGroupRequiresDownloading(): boolean{
        return this.master.downloadState === VideoDownloadStateEnum.requiresDownloading &&
                (this.nodes.length === 0 || this.nodes.every(n => n.videoDataEntry?.downloadState === VideoDownloadStateEnum.requiresDownloading));
    }

    public get downloadGroupCancelled(): boolean{
        return this.master.downloadState === VideoDownloadStateEnum.cancelled &&
                (this.nodes.length === 0 || this.nodes.every(n => n.videoDataEntry?.downloadState === VideoDownloadStateEnum.cancelled));
    }

    public get downloadGroupAnyCorruption(): boolean{
        return this.master.downloadState === VideoDownloadStateEnum.corruption ||
                this.nodes.some(n => n.videoDataEntry?.downloadState === VideoDownloadStateEnum.corruption);
    }

}

class VideoEntryViewModel implements IViewModel {
    public static className: string = 'VideoEntryViewModel';

    public item: VideoEntryModel;
    public groupItem: VideoEntryGroupViewModel;
    public isSelected: boolean = false;
    public processing: boolean = false;
    public type: 'session' | 'schedule' = null;

    public constructor(item: VideoEntryModel, type: 'session' | 'schedule') {
        this.item = item;
        this.type = type;

        this.groupItem = new VideoEntryGroupViewModel(item);
    }
}

@Component({
    selector: 'em-dashboard-recordings',
    templateUrl: './Dashboard.Recordings.Component.html',
    styleUrls: ['./Dashboard.Recordings.Component.scss']
})
export class DashboardRecordingsComponent extends BaseComponent implements OnInit, OnDestroy {
    public static className: string = 'DashboardRecordingsComponent';

    @HostBinding()
    public id: string = 'em-dashboard-recordings';

    public cancelSynchronizationsProcess: ProcessMonitorServiceProcess;
    public dataPollingProcess: ProcessMonitorServiceProcess;
    public deleteRecordingsSchedulesProcess: ProcessMonitorServiceProcess;
    public deleteSynchronizationsProcess: ProcessMonitorServiceProcess;
    public getFirstVideoFrameProcess: ProcessMonitorServiceProcess;
    public getSchedulesPageProcess: ProcessMonitorServiceProcess;
    public getSessionsPageProcess: ProcessMonitorServiceProcess;
    public getStatesProcess: ProcessMonitorServiceProcess;
    public pauseSynchronizationsProcess: ProcessMonitorServiceProcess;
    public RecordingStateEnum = RecordingStateEnum;
    public resumeSynchronizationsProcess: ProcessMonitorServiceProcess;
    public schedules: Array<VideoEntryViewModel>;
    public schedulesAllSelected: boolean = false;
    public schedulesDataSource = new MatTableDataSource<VideoEntryViewModel>();
    public schedulesDisplayedColumns = ['multiSelect', 'icons', 'state', 'progress', 'serial', 'date', 'start', 'duration', 'end'];
    public schedulesPageOptions: PaginationOptionsModel;
    public schedulesShowRecording: boolean = true;
    public schedulesShowScheduled: boolean = true;
    public sessions: Array<VideoEntryViewModel>;
    public sessionsAllSelected: boolean = false;
    public sessionsDataSource = new MatTableDataSource<VideoEntryViewModel>();
    public sessionsDisplayedColumns = ['multiSelect', 'icons', 'state', 'progress', 'serial', 'date', 'start', 'duration', 'end', 'thumb'];
    public sessionsPageOptions: PaginationOptionsModel;
    public sessionsShowLocal: boolean = true;
    public sessionsShowOnDevice: boolean = true;
    public sessionsShowSyncing: boolean = true;
    public synchroniseRecordingsProcess: ProcessMonitorServiceProcess;
    public SynchronizationStateEnum = SynchronizationStateEnum;
    public VideoDownloadStateEnum = VideoDownloadStateEnum;
    public waitSynchroniseRecordingsProcess: ProcessMonitorServiceProcess;
    public synchroniseRecordingsEnabled: boolean = false;
    public pauseSynchronizationsEnabled: boolean = false;
    public resumeSynchronizationsEnabled: boolean = false;
    public cancelSynchronizationsEnabled: boolean = false;
    public deleteSynchronizationsEnabled: boolean = false;
    public deleteRecordingsSchedulesEnabled: boolean = false;
    public validateSelectedEnabled: boolean = false;
    public downloadSelectedEnabled: boolean = false;
    public installerUser: boolean = false;

    private _statesDataPollingEvent: DataPollingEvent;

    public constructor(
        private readonly _deviceVideoDownloadService: DeviceVideoDownloadService,
        private readonly _connectionService: ConnectionService,
        private readonly _dialog: MatDialog,
        private readonly _router: Router,
        private readonly _config: ConfigurationService,
        private readonly _dataPollingService: DataPollingService,
        private readonly _recordingsService: RecordingsService,
        private readonly _deviceService: DeviceService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.dataPollingProcess = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Data refresh polling');
        this.getStatesProcess = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Getting states');
        this.synchroniseRecordingsProcess = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Syncing sessions');
        this.pauseSynchronizationsProcess = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Pausing syncing sessions');
        this.resumeSynchronizationsProcess = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Resuming syncing sessions');
        this.cancelSynchronizationsProcess = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Cancelling syncing sessions');
        this.deleteSynchronizationsProcess = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Deleting syncing sessions');
        this.deleteRecordingsSchedulesProcess = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Deleting recording schedules sessions');
        this.getFirstVideoFrameProcess = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Getting frame');
        this.waitSynchroniseRecordingsProcess = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Waiting for syncing sessions to complete');
        this.getSessionsPageProcess = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Getting sessions page.');
        this.getSchedulesPageProcess = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Getting schedules page.');

        this.sessionsPageOptions = new PaginationOptionsModel();
        this.sessionsPageOptions.page = 1;
        this.sessionsPageOptions.resultsPerPage = 10;

        this.schedulesPageOptions = new PaginationOptionsModel();
        this.schedulesPageOptions.page = 1;
        this.schedulesPageOptions.resultsPerPage = 10;

        this._statesDataPollingEvent = new DataPollingEvent('DashboardRecordingsComponent:GetStates', 0, 5000, this.getStatesProcess);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.stopStatesDataPolling();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.sessionsDataSource.sortingDataAccessor = IViewModelUtility.getDataSourceSortingDataAccessor(this.sessionsDataSource);
        this.schedulesDataSource.sortingDataAccessor = IViewModelUtility.getDataSourceSortingDataAccessor(this.schedulesDataSource);

        this.setDataSourceFilterPredicate(this.sessionsDataSource);
        this.setDataSourceFilterPredicate(this.schedulesDataSource);

        this.startStatesDataPolling();

        this.getSchedulesPage();
        this.getSessionsPage();

        this.addSubscription(this.observableHandlerBase(this.userIsInstaller, null).subscribe(result => {
            this.installerUser = result;
        }), null);
    }

    public applyFilter(filterValue: string, dataSource: MatTableDataSource<any>) {
        if (!this.isNullOrUndefined(dataSource.data) && dataSource.data.length > 0) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            dataSource.filter = filterValue;
        }
    }

    public setDataSourceFilterPredicate(dataSource: MatTableDataSource<VideoEntryViewModel>): void {
        dataSource.filterPredicate = (data: VideoEntryViewModel, filter: string) => {
            if ((!this.isNullOrUndefined(data.item.startDateText) && data.item.startDateText.toLocaleLowerCase().indexOf(filter) !== -1) ||
                (!this.isNullOrUndefined(data.item.startTimeText) && data.item.startTimeText.toLocaleLowerCase().indexOf(filter) !== -1) ||
                (!this.isNullOrUndefined(data.item.durationText) && data.item.durationText.toLocaleLowerCase().indexOf(filter) !== -1) ||
                (!this.isNullOrUndefined(data.item.endTimeText) && data.item.endTimeText.toLocaleLowerCase().indexOf(filter) !== -1) ||
                (!this.isNullOrUndefined(data.item.friendlyDeviceSerial) && data.item.friendlyDeviceSerial.toLocaleLowerCase().indexOf(filter) !== -1)) {
                return true;
            } else {
                data.isSelected = false;
                return false;
            }
        };
    }

    public checkSessionsAction(checkHandler: (session: VideoEntryViewModel) => boolean, title: string, message: string): Observable<boolean> {
        const sessionsLength = this.sessions.length;
        for (let sessionIndex = 0; sessionIndex < sessionsLength; sessionIndex++) {
            const session = this.sessions[sessionIndex];
            if (session.isSelected && checkHandler(session) === false) {
                return this.openOkCancelDialog(title, message, false).afterClosed().pipe(map(() => true));
            }
        }
        return of(true);
    }

    public synchroniseRecordings(): void {
        this.addSubscription(this.checkSessionsAction(
            (session) => session.groupItem.downloadGroupNotDownloaded === true,
            'Synchronise Recordings',
            'Some of the selected recordings will not be synchronized'
        ).subscribe(() => {
            const processTracker = new ProcessTracker();
            const sessionsLength = this.sessions.length;
            for (let sessionIndex = 0; sessionIndex < sessionsLength; sessionIndex++) {
                const session = this.sessions[sessionIndex];
                if (session.isSelected) {
                    session.processing = true;
                    const process = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Syncing session.');
                    processTracker.track(process);
                    this.addSubscription(this.observableHandlerBase(this._recordingsService.syncVideoSession(session.item, process), process).subscribe(), process);
                }
            }

            const timerSub = this.addSubscription(this.observableHandlerBase(timer(0, 100), this.waitSynchroniseRecordingsProcess).subscribe(() => {
                if (!processTracker.isRunning) {
                    if (!this.isNullOrUndefined(timerSub)) {
                        timerSub.unsubscribe();
                    }
                    this.sessionsDeSelectAll();
                    this._dataPollingService.pushBack(this._statesDataPollingEvent);
                    this.getStates();
                }
            }), this.waitSynchroniseRecordingsProcess);
        }));
    }

    public pauseSynchronizations(): void {
        this.addSubscription(this.checkSessionsAction(
            (session) => session.groupItem.downloadGroupDownloading === true,
            'Pause Synchronizations',
            'Some of the selected recordings synchronization will not be paused'
        ).subscribe(() => {
            const processTracker = new ProcessTracker();
            const sessionsLength = this.sessions.length;
            for (let sessionIndex = 0; sessionIndex < sessionsLength; sessionIndex++) {
                const session = this.sessions[sessionIndex];
                if (session.isSelected) {
                    session.processing = true;
                    const process = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Pausing session.');
                    processTracker.track(process);
                    this.addSubscription(this.observableHandlerBase(this._recordingsService.pauseSyncVideoSession(session.item, process), process).subscribe(), process);
                }
            }

            const timerSub = this.addSubscription(this.observableHandlerBase(timer(0, 100), this.pauseSynchronizationsProcess).subscribe(() => {
                if (!processTracker.isRunning) {
                    if (!this.isNullOrUndefined(timerSub)) {
                        timerSub.unsubscribe();
                    }
                    this.sessionsDeSelectAll();
                    this._dataPollingService.pushBack(this._statesDataPollingEvent);
                    this.getStates();
                }
            }), this.pauseSynchronizationsProcess);
        }));
    }

    public resumeSynchronizations(): void {
        this.addSubscription(this.checkSessionsAction(
            (session) => session.groupItem.downloadGroupPaused === true,
            'Resume Synchronizations',
            'Some of the selected recordings synchronization will not be resumed'
        ).subscribe(() => {
            const processTracker = new ProcessTracker();
            const sessionsLength = this.sessions.length;
            for (let sessionIndex = 0; sessionIndex < sessionsLength; sessionIndex++) {
                const session = this.sessions[sessionIndex];
                if (session.isSelected) {
                    session.processing = true;
                    const process = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Resuming session.');
                    processTracker.track(process);
                    this.addSubscription(this.observableHandlerBase(this._recordingsService.resumeSyncVideoSession(session.item, process), process).subscribe(), process);
                }
            }

            const timerSub = this.addSubscription(this.observableHandlerBase(timer(0, 100), this.resumeSynchronizationsProcess).subscribe(() => {
                if (!processTracker.isRunning) {
                    if (!this.isNullOrUndefined(timerSub)) {
                        timerSub.unsubscribe();
                    }
                    this.sessionsDeSelectAll();
                    this._dataPollingService.pushBack(this._statesDataPollingEvent);
                    this.getStates();
                }
            }), this.resumeSynchronizationsProcess);
        }));
    }

    public cancelSynchronizations(): void {
        this.addSubscription(this.checkSessionsAction(
            (session) => session.groupItem.downloadGroupDownloading === true || session.groupItem.downloadGroupPaused === true || session.groupItem.downloadGroupRequiresDownloading === true,
            'Cancel Synchronization',
            'Some of the selected recordings synchronization will not be canceled'
        ).subscribe(() => {
            const processTracker = new ProcessTracker();
            const sessionsLength = this.sessions.length;
            for (let sessionIndex = 0; sessionIndex < sessionsLength; sessionIndex++) {
                const session = this.sessions[sessionIndex];
                if (session.isSelected) {
                    session.processing = true;
                    const process = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Cancelling session.');
                    processTracker.track(process);
                    this.addSubscription(this.observableHandlerBase(this._recordingsService.cancelSyncVideoSession(session.item, process), process).subscribe(), process);
                }
            }

            const timerSub = this.addSubscription(this.observableHandlerBase(timer(0, 100), this.cancelSynchronizationsProcess).subscribe(() => {
                if (!processTracker.isRunning) {
                    if (!this.isNullOrUndefined(timerSub)) {
                        timerSub.unsubscribe();
                    }
                    this.sessionsDeSelectAll();
                    this._dataPollingService.pushBack(this._statesDataPollingEvent);
                    this.getStates();
                }
            }), this.cancelSynchronizationsProcess);
        }));
    }

    public deleteSynchronizations(): void {
        this.addSubscription(this.checkSessionsAction(
            (session) => session.groupItem.downloadGroupDownloaded === true || session.groupItem.downloadGroupCancelled === true,
            'Delete Synchronizations',
            'Some of the selected recordings synchronization will not be deleted'
        ).subscribe(() => {
            const processTracker = new ProcessTracker();
            const sessionsLength = this.sessions.length;
            for (let sessionIndex = 0; sessionIndex < sessionsLength; sessionIndex++) {
                const session = this.sessions[sessionIndex];
                if (session.isSelected) {
                    session.processing = true;
                    const process = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Deleting session.');
                    processTracker.track(process);
                    this.addSubscription(this.observableHandlerBase(this._recordingsService.deleteVideoSyncedSession(session.item, process), process).subscribe(), process);
                }
            }

            const timerSub = this.addSubscription(this.observableHandlerBase(timer(0, 100), this.deleteSynchronizationsProcess).subscribe(() => {
                if (!processTracker.isRunning) {
                    if (!this.isNullOrUndefined(timerSub)) {
                        timerSub.unsubscribe();
                    }
                    this.sessionsDeSelectAll();
                    this._dataPollingService.pushBack(this._statesDataPollingEvent);
                    this.getStates();
                }
            }), this.deleteSynchronizationsProcess);
        }));
    }

    public deleteRecordingsSchedules(): void {
        const processTracker = new ProcessTracker();
        const sessionsLength = this.sessions.length;
        for (let sessionIndex = 0; sessionIndex < sessionsLength; sessionIndex++) {
            const session = this.sessions[sessionIndex];
            if (session.isSelected) {
                session.processing = true;
                const process = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Deleting session.');
                processTracker.track(process);
                this.addSubscription(this.observableHandlerBase(this._recordingsService.deleteVideoSession(session.item, process), process).subscribe(), process);
            }
        }

        const schedulesLength = this.schedules.length;
        for (let scheduleIndex = 0; scheduleIndex < schedulesLength; scheduleIndex++) {
            const schedule = this.schedules[scheduleIndex];
            if (schedule.isSelected) {
                schedule.processing = true;
                const process = this.processMonitorService.getProcess(DashboardRecordingsComponent.className, 'Deleting schedule.');
                processTracker.track(process);
                this.addSubscription(this.observableHandlerBase(this._recordingsService.deleteVideoSchedule(schedule.item, process), process).subscribe(), process);
            }
        }

        const timerSub = this.addSubscription(this.observableHandlerBase(timer(0, 100), this.deleteRecordingsSchedulesProcess).subscribe(() => {
            if (!processTracker.isRunning) {
                if (!this.isNullOrUndefined(timerSub)) {
                    timerSub.unsubscribe();
                }
                this.schedulesDeSelectAll();
                this.sessionsDeSelectAll();
                this._dataPollingService.pushBack(this._statesDataPollingEvent);
                this.getSchedulesPage();
                this.getSessionsPage();
            }
        }), this.deleteRecordingsSchedulesProcess);
    }

    public validateSelected(): void {
        const sessionVM = this.sessions.find(s => s.isSelected === true);
        if (!this.isNullOrUndefined(sessionVM)) {
            DeviceSerialLinkComponent.navigateToDevice(this._router, sessionVM.item.friendlyDeviceSerial, this._connectionService, ['validation', sessionVM.item.sessionID.toString(), sessionVM.item.startTime.toString()]);
        }
    }

    public downloadSelected(): void {
        this.addSubscription(this.checkSessionsAction(
            (session) => session.groupItem.downloadGroupDownloaded === true,
            'Download selected',
            'Some of the selected recordings will not be download'
        ).subscribe(() => {
            const sessionsLength = this.sessions.length;
            for (let sessionIndex = 0; sessionIndex < sessionsLength; sessionIndex++) {
                const session = this.sessions[sessionIndex];
                if (session.isSelected && session.groupItem.downloadGroupDownloaded === true) {
                    session.processing = true;
                    this._deviceVideoDownloadService.startVideoDownload(session.groupItem.master.friendlyDeviceSerial, session.groupItem.master.videoDataEntryID, session.groupItem.master.startTime);

                    session.groupItem.nodes.forEach(node => {
                        if (!this.isNullOrUndefined(node.videoDataEntry)){
                            this._deviceVideoDownloadService.startVideoDownload(node.videoDataEntry.friendlyDeviceSerial, node.videoDataEntry.videoDataEntryID, node.videoDataEntry.startTime);
                        }
                    });

                }
            }
        }));
    }

    public onSchedulesPageChanged(event: PageEvent): void {
        this.schedulesPageOptions.page = (event.pageIndex + 1);
        this.schedulesPageOptions.resultsPerPage = event.pageSize;
        this.getSchedulesPage();
    }

    public onScheduleSelected(vm: VideoEntryViewModel): void {
        vm.isSelected = !vm.isSelected;
        this.updateAllSelected();
    }

    public schedulesToggleAll(): void {
        this.schedulesAllSelected = !this.schedulesAllSelected;

        const schedulesLength = this.schedules.length;
        for (let scheduleIndex = 0; scheduleIndex < schedulesLength; scheduleIndex++) {
            this.schedules[scheduleIndex].isSelected = this.schedulesAllSelected;
        }
        this.updateAllSelected();
    }

    public schedulesDeSelectAll(): void {
        const schedulesLength = this.schedules.length;
        for (let scheduleIndex = 0; scheduleIndex < schedulesLength; scheduleIndex++) {
            this.schedules[scheduleIndex].isSelected = false;
        }
        this.updateAllSelected();
    }

    public onSessionsPageChanged(event: PageEvent): void {
        this.sessionsPageOptions.page = (event.pageIndex + 1);
        this.sessionsPageOptions.resultsPerPage = event.pageSize;
        this.getSessionsPage();
    }

    public onSessionSelected(vm: VideoEntryViewModel): void {
        vm.isSelected = !vm.isSelected;
        this.updateAllSelected();
    }

    public sessionsToggleAll(): void {
        this.sessionsAllSelected = !this.sessionsAllSelected;

        const sessionsLength = this.sessions.length;
        for (let sessionIndex = 0; sessionIndex < sessionsLength; sessionIndex++) {
            this.sessions[sessionIndex].isSelected = this.sessionsAllSelected;
        }
        this.updateAllSelected();
    }

    public sessionsDeSelectAll(): void {
        const sessionsLength = this.sessions.length;
        for (let sessionIndex = 0; sessionIndex < sessionsLength; sessionIndex++) {
            this.sessions[sessionIndex].isSelected = false;
        }
        this.updateAllSelected();
    }

    public updateAllSelected(): void {
        this.synchroniseRecordingsEnabled = false;
        this.pauseSynchronizationsEnabled = false;
        this.resumeSynchronizationsEnabled = false;
        this.cancelSynchronizationsEnabled = false;
        this.deleteSynchronizationsEnabled = false;
        this.deleteRecordingsSchedulesEnabled = false;
        this.validateSelectedEnabled = false;
        this.downloadSelectedEnabled = false;

        const sessionsLength = this.sessions.length;
        for (let index = 0; index < sessionsLength; index++) {
            const session = this.sessions[index];
            if (session.isSelected === true) {
                if (session.groupItem.downloadGroupNotDownloaded === true) {
                    this.synchroniseRecordingsEnabled = true;
                    this.deleteRecordingsSchedulesEnabled = true;
                }
                if (session.groupItem.downloadGroupDownloading === true) {
                    this.pauseSynchronizationsEnabled = true;
                    this.cancelSynchronizationsEnabled = true;
                }
                if (session.groupItem.downloadGroupPaused === true) {
                    this.resumeSynchronizationsEnabled = true;
                    this.cancelSynchronizationsEnabled = true;
                }
                if (session.groupItem.downloadGroupDownloaded === true) {
                    this.deleteSynchronizationsEnabled = true;

                    if (session.groupItem.syncGroupNotLocalOnly === true) {
                        this.deleteRecordingsSchedulesEnabled = true;
                    }

                    this.downloadSelectedEnabled = true;
                }
                if (session.groupItem.downloadGroupRequiresDownloading === true) {
                    this.cancelSynchronizationsEnabled = true;
                }
                if (session.groupItem.downloadGroupCancelled === true) {
                    this.synchroniseRecordingsEnabled = true;
                    this.deleteSynchronizationsEnabled = true;
                }

                if (session.groupItem.nodes.some(n => this.isNullOrUndefined(n.videoDataEntry))){
                    this.deleteRecordingsSchedulesEnabled = true;
                }
            }
        }

        const schedulesLength = this.schedules.length;
        for (let index = 0; index < schedulesLength; index++) {
            const schedule = this.schedules[index];
            if (schedule.isSelected === true) {
                if (schedule.groupItem.recordingGroupIsPlanned === true) {
                    this.deleteRecordingsSchedulesEnabled = true;
                }
                if (schedule.groupItem.recordingGroupIsRecorded === true) {
                    this.deleteRecordingsSchedulesEnabled = true;
                }
                if (schedule.groupItem.recordingGroupIsNew === true) {
                    this.deleteRecordingsSchedulesEnabled = true;
                }
            }
        }

        const selectedSessions = this.sessions.filter(s => s.isSelected);

        if (selectedSessions.length === 1 &&
            selectedSessions[0].groupItem.downloadGroupDownloaded === true) {
            this.validateSelectedEnabled = true;
        }
        else {
            this.validateSelectedEnabled = false;
        }
    }

    public onSessionsShowHideChanged(event: MatSlideToggleChange, key: string): void {
        this[key] = event.checked;
        this.getSessionsPage();
    }

    private getStates(): void {
        if (!this.isNullOrUndefined(this.schedules) && !this.isNullOrUndefined(this.sessions)) {
            this.addSubscription(
                this.observableHandlerBase(
                    this._recordingsService.getVideoSessionStates(this.schedules
                        .map(i => i.item.videoDataEntryID)
                        .concat(this.sessions.map(i => i.item.videoDataEntryID)), this.getStatesProcess),
                    this.getStatesProcess)
                    .subscribe(
                        result => {
                            const resultLength = result.length;
                            let recordingStateCount = 0;
                            for (let index = 0; index < resultLength; index++) {
                                if (result[index].recordingState !== RecordingStateEnum.isRecorded) {
                                    recordingStateCount++;
                                }
                            }


                            if (this.schedules.length === recordingStateCount) {
                                const schedulesLength = this.schedules.length;
                                for (let scheduleIndex = 0; scheduleIndex < schedulesLength; scheduleIndex++) {
                                    const schedule = this.schedules[scheduleIndex];
                                    schedule.processing = false;
                                    const state = result.find(i => i.videoDataEntryID === schedule.item.videoDataEntryID);
                                    if (!this.isNullOrUndefined(state)) {
                                        this.updateVideoEntryState(schedule, state);
                                    }
                                }

                                const sessionsLength = this.sessions.length;
                                for (let sessionIndex = 0; sessionIndex < sessionsLength; sessionIndex++) {
                                    const session = this.sessions[sessionIndex];
                                    session.processing = false;
                                    const state = result.find(i => i.videoDataEntryID === session.item.videoDataEntryID);
                                    if (!this.isNullOrUndefined(state)) {
                                        this.updateVideoEntryState(session, state);
                                    }
                                }
                            } else {
                                this.getSchedulesPage();
                                this.getSessionsPage();
                            }
                        }
                    ), this.getStatesProcess);
        }
    }

    private updateVideoEntryState(vm: VideoEntryViewModel, state: VideoStateModel): void {
        vm.item.downloadState = state.downloadState;
        vm.item.percentageDownloaded = state.percentageDownloaded;
        vm.item.recordingState = state.recordingState;
        vm.item.sessionID = state.sessionID;
        vm.item.synchronizationState = state.synchronizationState;

        // Update the node states
        vm.groupItem.nodes.forEach(n => {
            if (!this.isNullOrUndefined(n.videoDataEntry)){
                const nodeState = state.nodes.find(f => f.videoDataEntryID === n.videoDataEntry.videoDataEntryID);

                if (!this.isNullOrUndefined(nodeState)){
                    n.videoDataEntry.downloadState = nodeState.downloadState;
                    n.videoDataEntry.percentageDownloaded = nodeState.percentageDownloaded;
                    n.videoDataEntry.recordingState = nodeState.recordingState;
                    n.videoDataEntry.sessionID = nodeState.sessionID;
                    n.videoDataEntry.synchronizationState = nodeState.synchronizationState;
                }
            }
        });

        this.updateAllSelected();
    }

    private getSessionsPage(): void {
        this.addSubscription(this.observableHandlerBase(this._recordingsService.getVideoSessions(this.sessionsShowLocal, this.sessionsShowOnDevice, this.sessionsShowSyncing, this.sessionsPageOptions, this.getSessionsPageProcess), this.getSessionsPageProcess).subscribe(
            result => {
                this.sessionsPageOptions.totalResults = result.options.totalResults;
                this.sessions = result.items.map(i => {
                    const vm = new VideoEntryViewModel(i, 'session');
                    this.getVideoFrame(vm);
                    return vm;
                });
                this.sessionsDataSource.data = this.sessions;

                this.getStates();
            }
        ), this.getSessionsPageProcess);
    }

    private getSchedulesPage(): void {
        this.addSubscription(this.observableHandlerBase(this._recordingsService.getVideoSchedules(true, true, true, this.schedulesPageOptions, this.getSchedulesPageProcess), this.getSchedulesPageProcess).subscribe(
            result => {
                this.schedulesPageOptions.totalResults = result.options.totalResults;
                this.schedules = result.items.map(i => {
                    const vm = new VideoEntryViewModel(i, 'schedule');
                    return vm;
                });
                this.schedulesDataSource.data = this.schedules;

                this.getStates();
            }
        ), this.getSchedulesPageProcess);
    }

    private getVideoFrame(vm: VideoEntryViewModel): void {
        if (this.isNullOrUndefined(vm.item.imageDataUri) || this.isEmptyOrWhiteSpace(vm.item.imageDataUri)) {
            this.addSubscription(this.observableHandlerBase(this._recordingsService.getFirstVideoFrame(vm.item.videoDataEntryID), this.getFirstVideoFrameProcess).subscribe(
                result => {
                    if (this.isNullOrUndefined(result) || this.isNullOrUndefined(result.imageDataUri) || this.isEmptyOrWhiteSpace(result.imageDataUri)) {
                        this.addSubscription(
                            this.observableHandlerBase(timer(5000), this.getFirstVideoFrameProcess).subscribe(() => { this.getVideoFrame(vm); })
                            , this.getFirstVideoFrameProcess);
                    } else {
                        vm.item.imageDataUri = result.imageDataUri;
                    }
                }
            ), this.getFirstVideoFrameProcess);
        }
    }

    private subDataPolling(): void {
        this.addSubscription(this.observableHandlerBase(this._statesDataPollingEvent.poll, this.dataPollingProcess).subscribe(() => {
            this.getStates();
        }), this.dataPollingProcess);
    }

    private startStatesDataPolling(): void {
        this.subDataPolling();
        this._dataPollingService.startEvent(this._statesDataPollingEvent);
    }

    private stopStatesDataPolling(): void {
        this._dataPollingService.stopEvent(this._statesDataPollingEvent);
    }
}
