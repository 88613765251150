import { Component, HostBinding, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchedulesAddEditDialogData, SettingsSchedulesAddEditComponent } from '@em/components/settings/schedules/scheduleaddedit/Settings.Schedules.AddEdit.Component';
import { ScheduleOverviewModel } from '@em/models/restapi/ScheduleOverview.Model';
import { WorkflowCollectionModel } from '@em/models/restapi/WorkflowCollection.Model';
import { ScheduleService } from '@em/service/data/schedule/Schedule.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { OkCancelDialogComponent, OkCancelDialogData, OkCancelDialogResult } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent, PleaseWaitDialogData } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { WorkflowOperationTypeEnum } from '@shared/enum/WorkflowOperationType.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { EventsService } from '@shared/service/events/Events.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable, zip, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'em-settings-schedules-list',
    templateUrl: './Settings.Schedules.List.Component.html',
    styleUrls: ['./Settings.Schedules.List.Component.scss']
})
export class SettingsSchedulesListComponent extends BaseComponent implements OnInit, OnDestroy, ILoadDate {
    public static className: string = 'SettingsSchedulesListComponent';

    public WorkflowOperationTypeEnum = WorkflowOperationTypeEnum;
    public addNewAction: NavBarAction;
    public addProcess: ProcessMonitorServiceProcess;
    public dataPollingProcess: ProcessMonitorServiceProcess;
    public dataSource = new MatTableDataSource<ScheduleOverviewModel>();
    public deleteScheduleProcess: ProcessMonitorServiceProcess;
    public displayedColumns = ['status', 'name', 'schedule', 'operatesOn', 'lastExecuted', 'nextExecution', 'success', 'coverage'];

    @HostBinding()
    public id: string = 'em-settings-schedules-list';

    public numActive = 0;
    public numDisabled = 0;
    public numFinished = 0;
    public numSystem = 0;
    public paramsChangeProcess: ProcessMonitorServiceProcess;
    public refreshDataProcess: ProcessMonitorServiceProcess;
    public refreshDataPollProcess: ProcessMonitorServiceProcess;
    public scheduleOverviews: Array<ScheduleOverviewModel>;
    public schedulesChangedProcess: ProcessMonitorServiceProcess;
    public state: 'active' | 'finished' | 'disabled' | 'system' = 'active';
    public toggleDisableScheduleProcess: ProcessMonitorServiceProcess;
    public workFlows: WorkflowCollectionModel;

    private _dataPollingEvent: DataPollingEvent;

    public constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _dataPollingService: DataPollingService,
        private readonly _dialog: MatDialog,
        private readonly _eventsService: EventsService,
        private readonly _router: Router,
        private readonly _navBarService: NavBarActionService,
        private readonly _scheduleService: ScheduleService,
        private readonly _userCurrentService: UserCurrentService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.addProcess = this.processMonitorService.getProcess(SettingsSchedulesListComponent.className, 'Add schedule');
        this.paramsChangeProcess = this.processMonitorService.getProcess(SettingsSchedulesListComponent.className, 'Route Params change');
        this.dataPollingProcess = this.processMonitorService.getProcess(SettingsSchedulesListComponent.className, 'Data polling');
        this.schedulesChangedProcess = this.processMonitorService.getProcess(SettingsSchedulesListComponent.className, 'Schedule Changed');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsSchedulesListComponent.className, this.loadDataProcessText);
        this.deleteScheduleProcess = this.processMonitorService.getProcess(SettingsSchedulesListComponent.className, 'Deleting schedule');
        this.toggleDisableScheduleProcess = this.processMonitorService.getProcess(SettingsSchedulesListComponent.className, 'Setting schedule disabled state');
        this.refreshDataPollProcess = this.processMonitorService.getProcess(SettingsSchedulesListComponent.className, 'Refreshing data poll');
        this.refreshDataProcess = this.processMonitorService.getProcess(SettingsSchedulesListComponent.className, 'Refreshing data');

        this._dataPollingEvent = new DataPollingEvent('SettingsSchedulesListComponent', 0, 5000, this.refreshDataPollProcess);

        this.addSubscription(this.observableHandlerBase(this.userCurrentService.isSystemAdmin, null).subscribe(isSystemAdmin => {
            if (isSystemAdmin) {
                this.addNewAction = new NavBarAction();
                this.addNewAction.name = 'addschedule';
                this.addNewAction.text = 'Add Schedule';
                this.addSubscription(this.observableHandlerBase(this.addNewAction.onButtonClick, this.addProcess).subscribe(() => { this.add(); }), this.addProcess);
            }
        }), null);

        this.addSubscription(this.observableHandlerBase(this._activatedRoute.params, this.paramsChangeProcess).subscribe(params => {
            if (this.isNullOrUndefined(params.state)) {
                this.state = 'active';
            } else {
                this.state = params.state;
            }

            this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
        }), this.paramsChangeProcess);

        this.startDataPolling();

        this.addSubscription(this.observableHandlerBase(this._eventsService.onSchedulesChanged, this.schedulesChangedProcess).subscribe(() => {
            this._dataPollingService.pushBack(this._dataPollingEvent);
            this.loadDataStartBase(this);
        }), this.schedulesChangedProcess);
    }

    public dataExportGetData = (displayedColumn: string, data: ScheduleOverviewModel) => {
        switch (displayedColumn) {
            case 'status':
                return data.isCurrentlyExecuting === true ? data.currentProgress : 'Idle';
            case 'name':
                return data.description;
            case 'schedule':
                return data.name;
            case 'operatesOn':
                return data.operatesOnText;
            case 'lastExecuted':
                return data.lastExecutedText;
            case 'nextExecution':
                return data.isCurrentlyExecuting === true ? 'Running...' : data.nextExecutionText;
            case 'success':
                return data.successRatePercentage;
            case 'coverage':
                return data.coveragePercentage;
            default:
                return data[displayedColumn];
        }
    };

    public dataExportGetHeader = (displayedColumn: string) => {
        switch (displayedColumn) {
            case 'status':
                return 'Progress';
            default:
                return displayedColumn;
        }
    };

    public add(): void {
        this._dialog.open(SettingsSchedulesAddEditComponent, { data: new SchedulesAddEditDialogData('add'), disableClose: true, maxWidth: 850 });
    }

    public applyFilter(filterValue: string) {
        if (!this.isNullOrUndefined(this.dataSource.data) && this.dataSource.data.length > 0) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            this.dataSource.filter = filterValue;
        }
    }

    public delete(schedule: ScheduleOverviewModel): void {
        const dialogRef = this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData(`Delete this schedule`, `Are you sure you want to delete this schedule?`) });

        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.deleteScheduleProcess).subscribe((result: OkCancelDialogResult) => {
            if (!this.isNullOrUndefined(result) && result.ok === true) {
                const pleaseWaitDialogRef = this._dialog.open(PleaseWaitDialogComponent, { data: new PleaseWaitDialogData(`Deleting The Schedule`), disableClose: true, minWidth: 250 });

                this.addSubscription(this.observableHandlerBase(this._scheduleService.delete(schedule.id, this.deleteScheduleProcess), this.deleteScheduleProcess).subscribe(
                    () => {
                        this._eventsService.changedSchedules();
                        pleaseWaitDialogRef.close();
                    }
                ), this.deleteScheduleProcess);
            }
        }), this.deleteScheduleProcess);
    }

    public disable(schedule: ScheduleOverviewModel): void {
        const dialogRef = this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData(`${schedule.isDisabled === true ? 'Enable' : 'Disable'} this schedule`, `Are you sure you want to ${schedule.isDisabled === true ? 'enable' : 'disable'} this schedule?`) });

        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.toggleDisableScheduleProcess).subscribe((result: OkCancelDialogResult) => {
            if (!this.isNullOrUndefined(result) && result.ok === true) {
                const pleaseWaitDialogRef = this._dialog.open(PleaseWaitDialogComponent, { data: new PleaseWaitDialogData(`${schedule.isDisabled === true ? 'Enabling' : 'Disabling'} The Schedule`), disableClose: true, minWidth: 250 });

                this.addSubscription(this.observableHandlerBase(this._scheduleService.setEnabledState(schedule.id, schedule.isDisabled, this.toggleDisableScheduleProcess), this.toggleDisableScheduleProcess).subscribe(
                    () => {
                        this._eventsService.changedSchedules();
                        pleaseWaitDialogRef.close();
                    }
                ), this.toggleDisableScheduleProcess);
            }
        }), this.toggleDisableScheduleProcess);
    }

    public edit(schedule: ScheduleOverviewModel): void {
        this._dialog.open(SettingsSchedulesAddEditComponent, { data: new SchedulesAddEditDialogData('edit', schedule.id), disableClose: true, maxWidth: 850 });
    }

    public filterActive(schedule: ScheduleOverviewModel): boolean {
        return schedule.isActive;
    }

    public filterByState(overviews: Array<ScheduleOverviewModel>): Array<ScheduleOverviewModel> {
        if (!this.isNullOrUndefined(overviews)) {
            const scheduleOverviews: ScheduleOverviewModel[] = [];
            const length = overviews.length;

            this.numActive = 0;
            this.numDisabled = 0;
            this.numFinished = 0;
            this.numSystem = 0;

            for (let index = 0; index < length; index++) {
                const item = overviews[index];

                this.numActive += this.filterActive(item) ? 1 : 0;
                this.numDisabled += this.filterDisabled(item) ? 1 : 0;
                this.numFinished += this.filterFinished(item) ? 1 : 0;
                this.numSystem += this.filterSystem(item) ? 1 : 0;

                switch (this.state) {
                    case 'finished':
                        if (this.filterFinished(item)) {
                            scheduleOverviews.push(item);
                        }
                        break;
                    case 'disabled':
                        if (this.filterDisabled(item)) {
                            scheduleOverviews.push(item);
                        }
                        break;
                    case 'active':
                        if (this.filterActive(item)) {
                            scheduleOverviews.push(item);
                        }
                        break;
                    case 'system':
                        if (this.filterSystem(item)) {
                            scheduleOverviews.push(item);
                        }
                        break;
                }
            }
            return scheduleOverviews;
        }
    }

    public filterDisabled(schedule: ScheduleOverviewModel): boolean {
        return schedule.isDisabled;
    }

    public filterFinished(schedule: ScheduleOverviewModel): boolean {
        return schedule.isFinished;
    }

    public filterSystem(schedule: ScheduleOverviewModel): boolean {
        return schedule.isSystem;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._scheduleService.getAllScheduleOverviews(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.scheduleOverviews = this.filterByState(result);
                        this.dataSource.data = this.scheduleOverviews;
                    }
                    return true;
                })
            ),
            this._scheduleService.getWorkflows(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.workFlows = result;
                    }
                    return true;
                })
            ),
        ).pipe(
            tap(() => {
                this.mapWorkflowToSchedules(this.workFlows, this.scheduleOverviews);
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this.stopDataPolling();

        this._navBarService.removeAction(this.addNewAction);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        if (this.userCurrentService.isAdmin) {
            this._navBarService.addAction(this.addNewAction);
        }
    }

    public refreshData(): void {
        this.addSubscription(this._scheduleService.getAllScheduleOverviews(null, {disabled: true}).pipe(
            map(result => {
                if (!this.isNullOrUndefined(result) && !this.isNullOrUndefined(this.dataSource.data)) {
                    const fromOverviews = this.filterByState(result);

                    const fromLength = fromOverviews.length;
                    const updateLength = this.dataSource.data.length;

                    if (fromLength === updateLength) {
                        for (let ia = 0; ia < fromLength; ia++) {
                            const from = fromOverviews[ia];
                            for (let ib = 0; ib < updateLength; ib++) {
                                const update = this.dataSource.data[ia];

                                if (update.id === from.id) {
                                    update.isCurrentlyExecuting = from.isCurrentlyExecuting;
                                    update.currentProgress = from.currentProgress;
                                    update.lastExecuted = from.lastExecuted;
                                    update.nextExecution = from.nextExecution;
                                    update.successRatePercentage = from.successRatePercentage;
                                    update.coveragePercentage = from.coveragePercentage;

                                    update.setText();

                                    break;
                                }
                            }
                        }
                    } else {
                        this.loadDataStartBase(this);
                    }
                }
            }),
            catchError((err: any)=>{
                console.log(err);
                return of(true);
            })
        ).subscribe(), this.refreshDataProcess);
    }

    public view(schedule: ScheduleOverviewModel): void {
        this._router.navigate([`/settings/schedules/view/${StringUtility.toString(schedule.id)}`], { queryParams: { returnPath: `/settings/schedules/list/${this.state}` } });
    }

    private mapWorkflowToSchedules(workflows: WorkflowCollectionModel, schedules: ScheduleOverviewModel[]): void {
        schedules.forEach(schedule => {
            const workflow = workflows.items.find(wf => wf.workflowName === schedule.name);
            if (!this.isNullOrUndefined(workflow)) {
                schedule.workflow = workflow;
            }
        });
    }

    private startDataPolling(): void {
        this.subDataPolling();
        this._dataPollingService.startEvent(this._dataPollingEvent);
    }

    private stopDataPolling(): void {
        this._dataPollingService.stopEvent(this._dataPollingEvent);
    }

    private subDataPolling(): void {
        this.addSubscription(this._dataPollingEvent.poll.subscribe(() => {
            this._scheduleService.clearCache();
            this.refreshData();
        }), this.dataPollingProcess);
    }
}
