import { Component, HostBinding, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { timer } from 'rxjs';


/**
 * Background loading spinner indicator.
 *
 * @export
 * @class BackgroundLoadSpinnerComponent
 * @extends {BaseComponent}
 * @implements {OnChanges}
 */
@Component({
    selector: 'shr-background-load-spinner',
    templateUrl: './BackgroundLoadSpinner.Component.html',
    styleUrls: ['./BackgroundLoadSpinner.Component.scss']
})
export class BackgroundLoadSpinnerComponent extends BaseComponent implements OnChanges {
    public static className: string = 'BackgroundLoadSpinnerComponent';

    @HostBinding()
    public id: string = 'shr-background-load-spinner';

    public show = false;
    public showTimerProcess: ProcessMonitorServiceProcess;

    /**
     * The diameter of the spinner.
     *
     * @memberof BackgroundLoadSpinnerComponent
     */
    @Input()
    public diameter = 15;
    /**
     * The duration to show the spinner for.
     *
     * @memberof BackgroundLoadSpinnerComponent
     */
    @Input()
    public duration = 3000;
    /**
     * Triggers the spinner to show when trigger changes.
     *
     * @memberof BackgroundLoadSpinnerComponent
     */
    @Input()
    public trigger = false;

    constructor(
        private readonly _injector: Injector) {
        super(_injector);

        this.showTimerProcess = this.processMonitorService.getProcess(BackgroundLoadSpinnerComponent.className, 'Show Timer');
    }


    public ngOnChanges(changes: SimpleChanges): void {
        if (!isNullOrUndefined(changes.trigger) && changes.trigger.currentValue === false) {
            this.show = true;

            this.addSubscription(this.observableHandlerBase(timer(this.duration), this.showTimerProcess).subscribe(() => {
                this.show = false;
            }));
        }
    }
}
