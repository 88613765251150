import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, Injector, NgZone, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { FillHeightParentComponentBase } from '@em/components/base/FillHeightParentComponentBase';
import { AllDataService } from '@em/service/data/alldata/AllData.Service';
import { IFillHeight } from '@shared/interface/IFillHeight';

@Component({
    selector: 'em-home',
    templateUrl: './Home.Component.html',
    styleUrls: ['./Home.Component.scss']
})
export class HomeComponent extends FillHeightParentComponentBase implements OnInit, IFillHeight, OnDestroy {
    public static className: string = 'HomeComponent';

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    public mobileQuery: MediaQueryList;

    @HostBinding()
    public id: string = 'em-home';
    private _mobileQueryListener: () => void;

    public constructor(
        private readonly _renderer: Renderer2,
        private readonly _allDataService: AllDataService,
        @Inject(ViewContainerRef) private readonly _viewContainerRef: ViewContainerRef,
        private readonly _zone: NgZone,
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _media: MediaMatcher,
        private readonly _router: Router,
        private readonly _injector: Injector) {
        super(_renderer, _injector);

        this.userNotificationService.setRootViewContainerRef(this._viewContainerRef);

        this.mobileQuery = this._media.matchMedia('(max-width: 1025px)');
        this._mobileQueryListener = () => this._changeDetectorRef.detectChanges();
        this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    }

    public ngOnDestroy(): void {
        this.userCurrentService.refresh();
        this._allDataService.clearAllCache();
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public onHomeClick(): void {
        this._zone.run(() => {
            this._router.navigate(['/']);
        });
    }
}
