<div class="shr-pleasewait-dialog">
    <div id="pleasewait-dialog-title"
        class="title"
        mat-dialog-title
        fxLayout="row"
        fxLayoutAlign="center center">
        <div fxLayout="column"
            fxLayoutAlign="start center">
            <div>
                {{ data.title }}
            </div>
            <div class="error error-message">
                {{ errorMessage }}
            </div>
        </div>
    </div>
    <div id="pleasewait-dialog-content"
        class="content"
        mat-dialog-content
        fxLayout="column"
        fxLayoutAlign="start center">
        <div class="spinner"
            fxLayout="row"
            fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!isNullOrUndefined(actionMessage)"
            class="action-message"
            fxLayout="row"
            fxLayoutAlign="start center">
            {{ actionMessage }}...
        </div>
    </div>
    <div id="pleasewait-dialog-actions"
        *ngIf="data.canCancel === true"
        class="actions"
        mat-dialog-actions
        fxLayout="row"
        fxLayoutAlign="center center">
        <button *ngIf="data.canCancel === true"
            mat-button
            (click)="onCancelClick()">Cancel</button>
    </div>
</div>