import { Routes } from '@angular/router';
import { EmDashboardRoutes } from '@em/components/dashboard/Dashboard.Routes';
import { EmDeviceGroupRoutes } from '@em/components/devicegroup/DeviceGroup.Routes';
import { EmDocumentationRoutes } from '@em/components/documentation/Documentation.Routes';
import { HomeComponent } from '@em/components/home/Home.Component';
import { EmMyAccountRoutes } from '@em/components/myaccount/MyAccount.Routes';
import { EmRiftRoutes } from '@em/components/rift/Rift.Routes';
import { EmSettingsRoutes } from '@em/components/settings/Settings.Routes';
import { UserIsAuthenticated } from '@em/service/user/UserIsLevel';
import { BrowserIncompatibleComponent } from '@shared/component/browserincompatible/BrowserIncompatible.component';
import { NotFoundComponent } from '@shared/component/notfound/NotFound.Component';
import { IRouteData } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { EmDeviceGroupErrorWarningRoutes } from './components/devicegrouperrorwarning/DeviceGroupErrorWarning.Routes';
import { EMLoginComponent } from './components/emlogin/EMLogin.Component';

export const EmRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'browser-incompatible',
        component: BrowserIncompatibleComponent,
    },
    {
        path: '',
        canActivate: [UserIsAuthenticated],
        component: HomeComponent,
        children: [
            ...EmDashboardRoutes,
            ...EmSettingsRoutes,
            ...EmMyAccountRoutes,
            ...EmDeviceGroupRoutes,
            ...EmDeviceGroupErrorWarningRoutes,
            ...EmDocumentationRoutes,
            ...EmRiftRoutes,
        ]
    },
    {
        path: 'login',
        component: EMLoginComponent,
        data: { breadcrumb: { name: 'em-login', text: 'Login' } } as IRouteData
    },
    {
        path: '404',
        component: NotFoundComponent,
    },
    {
        path: '**',
        redirectTo: '404'
    }
];
