import { HistogramDataModel } from '@rift/models/restapi/HistogramData.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IHistogramLogModel extends IRestModel {
    timeStamp: Date;
    data: Array<HistogramDataModel>;
    index: number;
    configIndex: number;
}

export class HistogramLogModel extends BaseModel implements IHistogramLogModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public timeStamp: Date = null;
    public data: Array<HistogramDataModel> = null;
    public index: number = null;
    public configIndex: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-HistogramLogModel');

        this.data = RestModelUtility.loadFromArray(restModel.Data, HistogramDataModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
