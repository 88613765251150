<div fxLayout="row">
    <input #input
        matInput
        tabindex="-1"
        autofocus="false"
        [placeholder]="placeholder"
        [formControl]="inputCtrl"
        (blur)="onTouched($event)">
    <mat-spinner *ngIf="searchProcess.isRunning"
        diameter="15"
        color="warn"></mat-spinner>
</div>