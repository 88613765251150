import { AfterViewInit, Component, ElementRef, HostBinding, HostListener, Injector, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { UpdateLocationModel } from '@em/models/restapi/UpdateLocation.Model';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

declare const google: any;

@Component({
    selector: 'em-rift-location',
    templateUrl: './Rift.Location.Component.html',
    styleUrls: ['./Rift.Location.Component.scss']
})
export class RiftLocationComponent extends RiftBaseComponent implements IFillHeight, OnInit, AfterViewInit, ILoadDate, ISaveAllChanges {
    public static className: string = 'RiftLocationComponent';

    public defaultLatitude: number = 52.2397844;
    public defaultLongitude: number = -0.8803982;
    public defaultZoom: number = 8;

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @ViewChild('search', { static: true })
    public searchElementRef: ElementRef;

    public locationFormGroup: FormGroup;
    public master: DeviceModel;

    @HostBinding()
    public id: string = 'em-rift-location';
    public updateLocation: UpdateLocationModel;

    public constructor(
        private readonly _deviceService: DeviceService,
        private readonly _dialog: MatDialog,
        private readonly _formBuilder: FormBuilder,
        private readonly _navBarService: NavBarActionService,
        private readonly _ngZone: NgZone,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.loadDataProcess = this.processMonitorService.getProcess(RiftLocationComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(RiftLocationComponent.className, this.saveAllChangesProcessText);

        this.locationFormGroup = this._formBuilder.group({
            location: ['', Validators.compose([])],
        });
        this.formGroupTracker.track(this.locationFormGroup);

        this.userCurrentService.isInstaller.subscribe(isInstaller => {
            if (isInstaller === false) {
                this.locationFormGroup.disable();
            }
        });

        this.addSaveAllAction(this);

        this.initConnectionState();
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public googleMapsPresent(): boolean{
        return typeof google === 'object';
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.deviceService.getHostDevice(this.connectionService.connectedToFriendlySerial).pipe(
                flatMap((hostDevice) => this._deviceService.getDevice(hostDevice.serialNumber, false, process).pipe(
                        map(result => {
                            if (!this.isNullOrUndefined(result)) {
                                this.master = result;

                                this.updateLocation = new UpdateLocationModel();
                                this.updateLocation.serial = this.master.friendlySerial;
                                this.updateLocation.location = this.master.location;

                                if (!(this.master.longitude === 0 && this.master.latitude === 0)){
                                    this.updateLocation.longitude = this.master.longitude;
                                    this.updateLocation.latitude = this.master.latitude;
                                }
                                else{
                                    this.updateLocation.longitude = this.defaultLongitude;
                                    this.updateLocation.latitude = this.defaultLatitude;
                                }

                                this.updateLocation.commitChanges();
                                this.changeTracker.track(this.updateLocation);

                                this.locationFormGroup.controls.location.setValue(this.master.location, { emitEvent: false });
                            }
                            return true;
                        }),
                    ))
            )
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void{
        super.ngAfterViewInit();

        if (this.googleMapsPresent() && !isNullOrUndefined(google) && !isNullOrUndefined(google.maps) && !isNullOrUndefined(google.maps.places)) {
            const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
            autocomplete.addListener('place_changed', () => {
                this._ngZone.run(() => {
                    // get the place result
                    const place = autocomplete.getPlace();

                    // verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    // set latitude, longitude and zoom
                    this.updateLocation.latitude = place.geometry.location.lat();
                    this.updateLocation.longitude = place.geometry.location.lng();
                    this.updateLocation.location = place.formatted_address;

                    this.updateSaveAllAction();
                });
            });
        }
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            this._deviceService.updateLocation(this.updateLocation, process).pipe(
                map(() => true)
            )
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(result);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this);
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
    }

    protected updateSaveAllAction(): void {
        if (this.hasChanges === true) {
            this.saveAllAction.disabled = false;
        } else {
            this.saveAllAction.disabled = true;
        }
    }
}
