import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Injectable } from '@angular/core';
import { ConfigDataModel } from '@em/models/restapi/ConfigData.Model';
import { KeyValuePairModel } from '@em/models/restapi/KeyValuePair.Model';
import { ServiceStatusAndConfigurationModel } from '@em/models/restapi/ServiceStatusAndConfiguration.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiInfoService } from '@em/service/restapi/RestApi.Info.Service';
import { RestApiSettingsService } from '@em/service/restapi/RestApi.Settings.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';


class SettingCache {
    public category: string;
    public name: string;
    public value: KeyValuePairModel;
}

@Injectable()
export class SettingService extends EmBaseService {
    private _settingCache: Array<SettingCache> = [];

    public constructor(
        private readonly _RestApiInfoService: RestApiInfoService,
        private readonly _restApiSettingsService: RestApiSettingsService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._settingCache = [];
    }

    private _updateLoadingTracker = new ObservableTracker<KeyValuePairModel>();
    public getSetting(name: string, category: string, process?: ProcessMonitorServiceProcess): Observable<KeyValuePairModel> {
        const setting = this._settingCache.find(i => i.name === name && i.category === category);
        if (isNullOrUndefined(setting)) {
            return this._updateLoadingTracker
                .getLoading(name, category)
                .observable(this._RestApiInfoService.getSetting(name, category, process).pipe(
                    map(result => {
                        const cache = new SettingCache();
                        cache.name = name;
                        cache.category = category;
                        cache.value = result;

                        this._settingCache.push(cache);

                        return cache.value;
                    })
                ));
        } else {
            return of(setting.value);
        }
    }

    private _setSettingLoadingTracker = new ObservableTracker<boolean>();
    public setSetting(name: string, category: string, value: string, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._setSettingLoadingTracker
            .getLoading(name, category)
            .observable(this._restApiSettingsService.setSetting(name, category, value, process).pipe(
                map(() => {
                    const settingIndex = this._settingCache.findIndex(i => i.name === name && i.category === category);
                    if (settingIndex !== -1) {
                        this._settingCache.splice(settingIndex, 1);
                    }
                    return true;
                })
            ));
    }

    private _getServiceStatusAndConfigurationLoadingTracker = new ObservableTracker<ServiceStatusAndConfigurationModel>();
    public getServiceStatusAndConfiguration(process?: ProcessMonitorServiceProcess): Observable<ServiceStatusAndConfigurationModel> {
        return this._getServiceStatusAndConfigurationLoadingTracker
            .getLoading()
            .observable(this._restApiSettingsService.getServiceStatusAndConfiguration(process).pipe(tap(() => this.clearCache())));
    }

    private _getModuleConfigLoadingTracker = new ObservableTracker<ConfigDataModel>();
    public getModuleConfig(moduleId: string, process?: ProcessMonitorServiceProcess): Observable<ConfigDataModel> {
        return this._getModuleConfigLoadingTracker
            .getLoading(moduleId)
            .observable(this._restApiSettingsService.getModuleConfig(moduleId, process).pipe(tap(() => this.clearCache())));
    }

    private _setModuleConfigLoadingTracker = new ObservableTracker<boolean>();
    public setModuleConfig(moduleId: string, configData: ConfigDataModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._setModuleConfigLoadingTracker
            .getLoading(moduleId, configData)
            .observable(this._restApiSettingsService.setModuleConfig(moduleId, configData, process).pipe(tap(() => this.clearCache())));
    }
}
