import { Component, HostListener, Inject, Injector, OnInit, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { ScheduleModel } from '@em/models/restapi/Schedule.Model';
import { ScheduleOverviewModel } from '@em/models/restapi/ScheduleOverview.Model';
import { WorkflowModel } from '@em/models/restapi/Workflow.Model';
import { GroupService } from '@em/service/data/group/Group.Service';
import { ScheduleService } from '@em/service/data/schedule/Schedule.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { OkCancelDialogComponent, OkCancelDialogData } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { UnsavedChangesDialogComponent, UnsavedChangesDialogDialogData, UnsavedChangesDialogResult } from '@shared/component/dialog/unsavedchanges/UnsavedChanges.Dialog.Component';
import { ScheduleEventTypeEnum } from '@shared/enum/ScheduleEventType.Enum';
import { ScheduleGranularityEnum } from '@shared/enum/ScheduleGranularity.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNumber } from '@shared/utility/General.Utility';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


export class SchedulesAddEditDialogData {
    public mode: 'add' | 'edit';
    public scheduleId: number;

    public constructor(mode: 'add' | 'edit', scheduleId?: number) {
        this.mode = mode;
        this.scheduleId = scheduleId;
    }
}

export class SchedulesAddEditDialogResult {
    public constructor() { }
}

@Component({
    selector: 'em-settings-schedules-add-edit',
    templateUrl: './Settings.Schedules.AddEdit.Component.html',
    styleUrls: ['./Settings.Schedules.AddEdit.Component.scss']
})
export class SettingsSchedulesAddEditComponent extends BaseComponent implements ILoadDate {
    public static className: string = 'SettingsSchedulesAddEditComponent';

    @HostBinding()
    public id: string = 'em-settings-schedules-add-edit';

    public getScheduleProcess: ProcessMonitorServiceProcess;
    public addScheduleProcess: ProcessMonitorServiceProcess;
    public saveProcess: ProcessMonitorServiceProcess;
    public saveScheduleProcess: ProcessMonitorServiceProcess;

    public actionsFormGroup: FormGroup;
    public descriptionFormGroup: FormGroup;
    public deviceOrGroupFormGroup: FormGroup;

    public groups: GroupModel[];
    public mode: 'add' | 'edit';
    public schedule: ScheduleModel;
    public ScheduleGranularityEnum = ScheduleGranularityEnum;
    public scheduleId: number;
    public scheduleOverview: ScheduleOverviewModel;
    public scheduleOverviews: Array<ScheduleOverviewModel>;
    public whenFormGroup: FormGroup;
    public workflows: Array<WorkflowModel>;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;
    public cancelProcess: ProcessMonitorServiceProcess;
    public nextShow = true;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _eventsService: EventsService,
        private readonly _scheduleService: ScheduleService,
        private readonly _groupService: GroupService,
        private readonly _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private readonly _data: SchedulesAddEditDialogData,
        private readonly _dialogRef: MatDialogRef<SettingsSchedulesAddEditComponent>,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.cancelProcess = this.processMonitorService.getProcess(SettingsSchedulesAddEditComponent.className, 'Cancel schedule.');
        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsSchedulesAddEditComponent.className, 'Form values change.');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsSchedulesAddEditComponent.className, this.loadDataProcessText);
        this.saveProcess = this.processMonitorService.getProcess(SettingsSchedulesAddEditComponent.className, 'Saving schedule.');
        this.addScheduleProcess = this.processMonitorService.getProcess(SettingsSchedulesAddEditComponent.className, 'Adding schedule.');
        this.saveScheduleProcess = this.processMonitorService.getProcess(SettingsSchedulesAddEditComponent.className, 'Saving schedule.');
        this.getScheduleProcess = this.processMonitorService.getProcess(SettingsSchedulesAddEditComponent.className, 'Getting schedule.');

        this.mode = this._data.mode;

        if (this.mode === 'add') {
            this.schedule = new ScheduleModel();
        } else {
            this.scheduleId = this._data.scheduleId;
        }

        this.descriptionFormGroup = this._formBuilder.group({
            description: ['', Validators.compose([Validators.required, Validators.maxLength(200)])],
        });

        this.deviceOrGroupFormGroup = this._formBuilder.group({
            operatesOn: ['', Validators.compose([Validators.required])],
            folderId: ['', Validators.compose([])],
            friendlySerialNumber: ['', Validators.compose([])],
        });

        this.whenFormGroup = this._formBuilder.group({
            execute: ['', Validators.compose([])],
            datetime: ['', Validators.compose([])],
            repeats: ['', Validators.compose([])],
            every: ['', Validators.compose([])],
            everyRange: ['', Validators.compose([])],
            for: ['', Validators.compose([])],
            forRange: ['', Validators.compose([])],
            action: ['', Validators.compose([])],
            scheduleCompletes: ['', Validators.compose([])],
        });

        this.actionsFormGroup = this._formBuilder.group({
            action: ['', Validators.compose([Validators.required])],
        });

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        if (this.hasChanges === true) {
            return of(false);
        }
        return of(true);
    }

    public showSaveChangesWarning(): Observable<boolean> {
        const dialogRef = this._dialog.open(UnsavedChangesDialogComponent, { data: new UnsavedChangesDialogDialogData(), disableClose: true });

        return dialogRef.afterClosed().pipe(
            map((result: UnsavedChangesDialogResult): boolean => {
                if (!this.isNullOrUndefined(result) && result.save) {
                    this.saveAllChanges();
                    this._dialogRef.close();
                } else if (result.discard) {
                    this._dialogRef.close();
                }
                return false;
            })
        );
    }

    public get hasChanges(): boolean {
        return this.schedule.hasChanges;
    }

    public get isValid(): boolean {
        return this.descriptionFormGroup.valid === true && this.deviceOrGroupFormGroup.valid === true && this.whenFormGroup.valid === true && this.actionsFormGroup.valid === true;
    }

    public onRepeatsChanged(value: string): void {
        switch (value) {
            case 'never':
                this.whenFormGroup.controls.datetime.setValidators(Validators.compose([Validators.required]));
                this.whenFormGroup.controls.repeats.setValidators(Validators.compose([Validators.required]));
                this.whenFormGroup.controls.every.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.everyRange.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.for.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.forRange.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.action.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.scheduleCompletes.setValidators(Validators.compose([]));


                this.whenFormGroup.controls.every.setValue('');
                this.whenFormGroup.controls.everyRange.setValue('');
                this.whenFormGroup.controls.for.setValue('');
                this.whenFormGroup.controls.forRange.setValue('');

                break;
            case 'repeats':
                this.whenFormGroup.controls.datetime.setValidators(Validators.compose([Validators.required]));
                this.whenFormGroup.controls.repeats.setValidators(Validators.compose([Validators.required]));
                this.whenFormGroup.controls.every.setValidators(Validators.compose([Validators.required]));
                this.whenFormGroup.controls.everyRange.setValidators(Validators.compose([Validators.required]));
                this.whenFormGroup.controls.for.setValidators(Validators.compose([Validators.required]));
                this.whenFormGroup.controls.forRange.setValidators(Validators.compose([Validators.required]));
                this.whenFormGroup.controls.action.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.scheduleCompletes.setValidators(Validators.compose([]));
                break;
        }
        this.whenFormGroup.updateValueAndValidity();
    }

    public onForRangeChanged(value: string): void {
        switch (value) {
            case 'ever':
                this.whenFormGroup.controls.for.setValidators(Validators.compose([]));

                this.whenFormGroup.controls.for.setValue('');
                break;
            case '':
                this.whenFormGroup.controls.for.setValidators(Validators.compose([]));

                this.whenFormGroup.controls.for.setValue('');
                break;
            default:
                this.whenFormGroup.controls.for.setValidators(Validators.compose([Validators.required]));
                break;
        }

        this.whenFormGroup.updateValueAndValidity();
    }

    public onExecuteChanged(value: string): void {
        switch (value) {
            case 'immediately':
                this.whenFormGroup.controls.datetime.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.repeats.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.every.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.everyRange.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.for.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.forRange.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.action.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.scheduleCompletes.setValidators(Validators.compose([]));
                break;
            case 'attime':
                this.whenFormGroup.controls.datetime.setValidators(Validators.compose([Validators.required]));
                this.whenFormGroup.controls.repeats.setValidators(Validators.compose([Validators.required]));
                this.whenFormGroup.controls.every.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.everyRange.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.for.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.forRange.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.action.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.scheduleCompletes.setValidators(Validators.compose([]));
                break;
            case 'when':
                this.whenFormGroup.controls.datetime.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.repeats.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.every.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.everyRange.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.for.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.forRange.setValidators(Validators.compose([]));
                this.whenFormGroup.controls.action.setValidators(Validators.compose([Validators.required]));
                this.whenFormGroup.controls.scheduleCompletes.setValidators(Validators.compose([]));
                break;
        }

        this.whenFormGroup.controls.datetime.setValue('');
        this.whenFormGroup.controls.repeats.setValue('');
        this.whenFormGroup.controls.every.setValue('');
        this.whenFormGroup.controls.everyRange.setValue('');
        this.whenFormGroup.controls.for.setValue('');
        this.whenFormGroup.controls.forRange.setValue('');
        this.whenFormGroup.controls.action.setValue('');
        this.whenFormGroup.controls.scheduleCompletes.setValue('');

        this.whenFormGroup.updateValueAndValidity();
    }

    public onOperatesOnChanged(value: ScheduleGranularityEnum): void {
        switch (value) {
            case ScheduleGranularityEnum.all:
                this.deviceOrGroupFormGroup.controls.folderId.setValidators(Validators.compose([]));
                this.deviceOrGroupFormGroup.controls.friendlySerialNumber.setValidators(Validators.compose([]));

                this.deviceOrGroupFormGroup.controls.folderId.setValue('');
                this.deviceOrGroupFormGroup.controls.friendlySerialNumber.setValue('');
                break;
            case ScheduleGranularityEnum.device:
                this.deviceOrGroupFormGroup.controls.folderId.setValidators(Validators.compose([]));
                this.deviceOrGroupFormGroup.controls.friendlySerialNumber.setValidators(Validators.compose([Validators.required]));

                this.deviceOrGroupFormGroup.controls.friendlySerialNumber.setValue('');
                break;
            case ScheduleGranularityEnum.folder:
                this.deviceOrGroupFormGroup.controls.folderId.setValidators(Validators.compose([Validators.required]));
                this.deviceOrGroupFormGroup.controls.friendlySerialNumber.setValidators(Validators.compose([]));

                this.deviceOrGroupFormGroup.controls.folderId.setValue('');
                break;
        }
        this.deviceOrGroupFormGroup.updateValueAndValidity();
    }

    public onActionChanged(value: string): void {
        switch (value) {
            case 'connects':
            case 'disconnects':
            case 'rediscovers':
            case 'settingschanged':
                this.whenFormGroup.controls.scheduleCompletes.setValidators(Validators.compose([]));
                break;
            case 'schedulecompletes':
                this.whenFormGroup.controls.scheduleCompletes.setValidators(Validators.compose([Validators.required]));
                break;
            default:
                this.whenFormGroup.controls.scheduleCompletes.setValidators(Validators.compose([]));
                break;
        }
    }

    public getSummery(): string {
        return `${this.descriptionFormGroup.controls.description.value}
        will
        ${this.getActionText()}
         on
        ${this.getOperatesOnText(this.deviceOrGroupFormGroup.controls.operatesOn.value)}
        ${this.getExecuteText(this.whenFormGroup.controls.execute.value)}`;
    }

    public getActionText(): string {
        if (!this.isNullOrUndefined(this.workflows)) {
            const workflow = this.workflows.find(i => i.workflowName === this.actionsFormGroup.controls.action.value);
            if (!this.isNullOrUndefined(workflow)) {
                return workflow.description.toLocaleLowerCase();
            }
        }
    }

    public getOperatesOnText(value: ScheduleGranularityEnum): string {
        switch (value) {
            case ScheduleGranularityEnum.all:
                return `all devices`;
            case ScheduleGranularityEnum.device:
                return `device ${this.deviceOrGroupFormGroup.controls.friendlySerialNumber.value}`;
            case ScheduleGranularityEnum.folder:
                return `group ${this.getGroupName(this.deviceOrGroupFormGroup.controls.folderId.value)}`;
        }
    }

    public getGroupName(value: number): string {
        const group = this.getGroup(value);
        if (!this.isNullOrUndefined(group)) {
            return group.name;
        }
    }

    public getGroup(value: number): GroupModel {
        const group = this.groups.find(i => i.id === value);
        if (!this.isNullOrUndefined(group)) {
            return group;
        }
    }

    public getExecuteText(value: string): string {
        switch (value) {
            case 'immediately':
                return `immediately`;
            case 'attime':
                return `starting at ${DateTimeUtility.toShortDateTime(this.whenFormGroup.controls.datetime.value)} ${this.getRepeatsText()}`;
            case 'when':
                return `when ${this.getWhenText()}`;
        }
    }

    public getWhenText(): string {
        switch (this.whenFormGroup.controls.action.value) {
            case 'connects':
                return `it connects`;
            case 'disconnects':
                return `it disconnects`;
            case 'rediscovers':
                return `it is rediscovered`;
            case 'settingschanged':
                return `it's settings change`;
            case 'schedulecompletes':
                return `schedule ${this.getScheduleName(this.whenFormGroup.controls.scheduleCompletes.value)} completes`;
        }
    }

    public getRepeatsText(): string {
        const everyRange = this.whenFormGroup.controls.everyRange.value as string;
        const forRange = this.whenFormGroup.controls.forRange.value as string;

        switch (this.whenFormGroup.controls.repeats.value) {
            case 'never':
                return ` and finish`;
            case 'repeats':
                return ` and repeat every
                ${this.whenFormGroup.controls.every.value > 0 ? this.whenFormGroup.controls.every.value : ''}
                ${this.whenFormGroup.controls.every.value === 1 ? everyRange.substring(0, everyRange.length - 1) : everyRange}
                ${forRange === 'ever' ? 'for ever' : `for the next ${this.whenFormGroup.controls.for.value > 0 ? this.whenFormGroup.controls.for.value : ''}
                ${this.whenFormGroup.controls.for.value === 1 ? forRange.substring(0, forRange.length - 1) : forRange}`}`;
        }
    }

    public getScheduleName(scheduleId: number) {
        const schedule = this.scheduleOverviews.find(i => i.id === scheduleId);
        if (!this.isNullOrUndefined(schedule)) {
            return schedule.description;
        }
    }

    public saveAllChanges(): void {
        switch (this.mode) {
            case 'add':
                this.add();
                break;
            case 'edit':
                this.save();
                break;
        }
    }

    public cancel(): void {
        if (this.schedule.hasChanges === true) {
            const dialogRef = this._dialog.open(UnsavedChangesDialogComponent, { data: new UnsavedChangesDialogDialogData(), disableClose: true });

            this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.cancelProcess).subscribe((result: UnsavedChangesDialogResult) => {
                if (!this.isNullOrUndefined(result) && result.save) {
                    this.saveAllChanges();
                    this._dialogRef.close();
                } else if (result.discard) {
                    this._dialogRef.close();
                }
            }), this.cancelProcess);
        } else {
            this._dialogRef.close();
        }
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._groupService.getNested(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.groups = ArrayUtility.flatten([], result);
                    }
                    return true;
                })
            ),
            this._scheduleService.getWorkflows(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        const workflows: WorkflowModel[] = [];
                        const length = result.items.length;
                        for (let index = 0; index < length; index++) {
                            const item = result.items[index];
                            if (item.external === true) {
                                workflows.push(item);
                            }
                        }
                        this.workflows = workflows;
                    }
                    return true;
                })
            ),
            this._scheduleService.getAllScheduleOverviews(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.scheduleOverviews = result;
                    }
                    return true;
                })
            ),
            of(this.mode).pipe(
                flatMap(mode => {
                    if (mode === 'edit') {
                        return this._scheduleService.getSchedule(this.scheduleId, process).pipe(
                            flatMap(result => {
                                if (!this.isNullOrUndefined(result)) {
                                    this.schedule = result;
                                    return this._scheduleService.getScheduleOverview(this.scheduleId, process).pipe(
                                        map(overview => {
                                            this.scheduleOverview = overview;
                                            this.setFormValues(this.scheduleOverview, this.schedule);
                                            this.addFormsSubscriptions();
                                            return true;
                                        })
                                    );
                                }
                                return of(true);
                            })
                        );
                    } else {
                        this.addFormsSubscriptions();
                        return of(true);
                    }
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    private setFormValues(scheduleOverview: ScheduleOverviewModel, schedule: ScheduleModel): void {
        if (!this.isNullOrUndefined(scheduleOverview)) {
            this.descriptionFormGroup.setValue({
                description: this.isNullOrUndefined(scheduleOverview.description) ? '' : scheduleOverview.description,
            });

            this.deviceOrGroupFormGroup.controls.operatesOn.setValue(this.isNullOrUndefined(scheduleOverview.operatesOn) ? '' : scheduleOverview.operatesOn);
            this.onOperatesOnChanged(this.deviceOrGroupFormGroup.controls.operatesOn.value);

            if (!this.isNullOrUndefined(scheduleOverview.operatesOn) && scheduleOverview.operatesOn === ScheduleGranularityEnum.folder) {
                this.deviceOrGroupFormGroup.controls.folderId.setValue(scheduleOverview.folderId);
            } else {
                this.deviceOrGroupFormGroup.controls.folderId.setValue('');
            }

            if (!this.isNullOrUndefined(scheduleOverview.operatesOn) && scheduleOverview.operatesOn === ScheduleGranularityEnum.device && !this.isNullOrUndefined(scheduleOverview.devices) && scheduleOverview.devices.length > 0) {
                this.deviceOrGroupFormGroup.controls.friendlySerialNumber.setValue(scheduleOverview.devices[0].friendlySerial);
            } else {
                this.deviceOrGroupFormGroup.controls.friendlySerialNumber.setValue('');
            }

            if (!this.isNullOrUndefined(scheduleOverview.executeOnEvent) && scheduleOverview.executeOnEvent === ScheduleEventTypeEnum.period) {
                this.whenFormGroup.controls.execute.setValue('attime');
                this.onExecuteChanged(this.whenFormGroup.controls.execute.value);

                if (!this.isNullOrUndefined(schedule.startTime)) {
                    this.whenFormGroup.controls.datetime.setValue(schedule.startTime);
                } else {
                    this.whenFormGroup.controls.datetime.setValue('');
                }

                if (!this.isNullOrUndefined(scheduleOverview.executionWaitPeriod) && scheduleOverview.executionWaitPeriod > 0) {
                    this.whenFormGroup.controls.repeats.setValue('repeats');
                    this.onRepeatsChanged(this.whenFormGroup.controls.repeats.value);

                    if (scheduleOverview.executionWaitPeriod < 60) {
                        this.whenFormGroup.controls.every.setValue(scheduleOverview.executionWaitPeriod);
                        this.whenFormGroup.controls.everyRange.setValue('minutes');
                    } else if (scheduleOverview.executionWaitPeriod < 1440) {
                        this.whenFormGroup.controls.every.setValue(scheduleOverview.executionWaitPeriod / 60);
                        this.whenFormGroup.controls.everyRange.setValue('hours');
                    } else if (scheduleOverview.executionWaitPeriod < 10080) {
                        this.whenFormGroup.controls.every.setValue(scheduleOverview.executionWaitPeriod / 1440);
                        this.whenFormGroup.controls.everyRange.setValue('days');
                    } else if (scheduleOverview.executionWaitPeriod < 43800) {
                        this.whenFormGroup.controls.every.setValue(scheduleOverview.executionWaitPeriod / 10080);
                        this.whenFormGroup.controls.everyRange.setValue('weeks');
                    } else {
                        this.whenFormGroup.controls.every.setValue(scheduleOverview.executionWaitPeriod / 43800);
                        this.whenFormGroup.controls.everyRange.setValue('months');
                    }

                    if (!this.isNullOrUndefined(schedule.endTime) && schedule.endTime.getFullYear() === 9999) {
                        this.whenFormGroup.controls.forRange.setValue('ever');
                        this.onForRangeChanged(this.whenFormGroup.controls.forRange.value);
                    } else if (!this.isNullOrUndefined(schedule.endTime) && schedule.endTime.getFullYear() < 9999) {
                        const durationAsMonths = DateTimeUtility.toDurationMonths(schedule.startTime, schedule.endTime);
                        const durationAsWeeks = DateTimeUtility.toDurationWeeks(schedule.startTime, schedule.endTime);
                        const durationAsDays = DateTimeUtility.toDurationDays(schedule.startTime, schedule.endTime);
                        const durationAsHours = DateTimeUtility.toDurationHours(schedule.startTime, schedule.endTime);

                        if (Math.floor(durationAsMonths) === durationAsMonths) {
                            this.whenFormGroup.controls.for.setValue(durationAsMonths);
                            this.whenFormGroup.controls.forRange.setValue('months');
                            this.onForRangeChanged(this.whenFormGroup.controls.forRange.value);
                        } else if (Math.floor(durationAsWeeks) === durationAsWeeks) {
                            this.whenFormGroup.controls.for.setValue(durationAsWeeks);
                            this.whenFormGroup.controls.forRange.setValue('weeks');
                            this.onForRangeChanged(this.whenFormGroup.controls.forRange.value);
                        } else if (Math.floor(durationAsDays) === durationAsDays) {
                            this.whenFormGroup.controls.for.setValue(durationAsDays);
                            this.whenFormGroup.controls.forRange.setValue('days');
                            this.onForRangeChanged(this.whenFormGroup.controls.forRange.value);
                        } else {
                            this.whenFormGroup.controls.for.setValue(durationAsHours);
                            this.whenFormGroup.controls.forRange.setValue('hours');
                            this.onForRangeChanged(this.whenFormGroup.controls.forRange.value);
                        }

                    } else {
                        this.whenFormGroup.controls.forRange.setValue('');
                        this.onForRangeChanged(this.whenFormGroup.controls.forRange.value);
                    }

                } else {
                    this.whenFormGroup.controls.repeats.setValue('never');
                    this.onRepeatsChanged(this.whenFormGroup.controls.repeats.value);
                }

            } else if (!this.isNullOrUndefined(scheduleOverview.executeOnEvent)) {
                this.whenFormGroup.controls.execute.setValue('when');
                this.onExecuteChanged(this.whenFormGroup.controls.execute.value);

                if (!this.isNullOrUndefined(scheduleOverview.executeOnEvent) && scheduleOverview.executeOnEvent === ScheduleEventTypeEnum.scheduleCompleted) {
                    this.whenFormGroup.controls.action.setValue('schedulecompletes');
                    this.onActionChanged(this.whenFormGroup.controls.action.value);

                    if (!this.isNullOrUndefined(schedule.parentSchedule)) {
                        this.whenFormGroup.controls.scheduleCompletes.setValue(schedule.parentSchedule.id);
                    }
                } else if (!this.isNullOrUndefined(scheduleOverview.executeOnEvent)) {
                    switch (scheduleOverview.executeOnEvent) {
                        case ScheduleEventTypeEnum.unitConnected:
                            this.whenFormGroup.controls.action.setValue('connects');
                            this.onActionChanged(this.whenFormGroup.controls.action.value);
                            break;
                        case ScheduleEventTypeEnum.unitDisconnected:
                            this.whenFormGroup.controls.action.setValue('disconnects');
                            this.onActionChanged(this.whenFormGroup.controls.action.value);
                            break;
                        case ScheduleEventTypeEnum.unitFlashSettingsChanged:
                            this.whenFormGroup.controls.action.setValue('settingschanged');
                            this.onActionChanged(this.whenFormGroup.controls.action.value);
                            break;
                        case ScheduleEventTypeEnum.unitRediscovered:
                            this.whenFormGroup.controls.action.setValue('rediscovers');
                            this.onActionChanged(this.whenFormGroup.controls.action.value);
                            break;
                    }
                } else {
                    this.whenFormGroup.controls.action.setValue('');
                    this.onActionChanged(this.whenFormGroup.controls.action.value);
                }

            } else {
                this.whenFormGroup.controls.execute.setValue('');
                this.onExecuteChanged(this.whenFormGroup.controls.execute.value);
            }

            this.actionsFormGroup.setValue({
                action: schedule.workflowName,
            });
        }
    }

    private updateModel(schedule: ScheduleModel): void {
        const dateNow = new Date();

        if (this.mode === 'add') {
            schedule.id = 0;
        }

        const descriptionModel = this.descriptionFormGroup.value;

        schedule.description = descriptionModel.description;

        const deviceOrGroupModel = this.deviceOrGroupFormGroup.value;

        switch (deviceOrGroupModel.operatesOn) {
            case ScheduleGranularityEnum.all:
                schedule.allDevices = true;
                schedule.scheduledDevice = null;
                schedule.scheduledGroup = null;
                break;
            case ScheduleGranularityEnum.device:
                schedule.allDevices = false;
                schedule.scheduledDevice = deviceOrGroupModel.friendlySerialNumber;
                schedule.scheduledGroup = null;
                break;
            case ScheduleGranularityEnum.folder:
                schedule.allDevices = false;
                schedule.scheduledDevice = null;
                schedule.scheduledGroup = this.getGroup(deviceOrGroupModel.folderId);
                break;
        }

        const whenModel = this.whenFormGroup.value;

        switch (whenModel.execute) {
            case 'immediately':
                schedule.executeOnEvent = ScheduleEventTypeEnum.period;
                schedule.startTime = dateNow;
                schedule.endTime = new Date(dateNow.getFullYear() + 10, dateNow.getMonth(), dateNow.getDate());
                schedule.iterationPeriod = null;
                break;
            case 'attime':
                schedule.executeOnEvent = ScheduleEventTypeEnum.period;

                switch (whenModel.repeats) {
                    case 'never':
                        schedule.startTime = whenModel.datetime;
                        schedule.endTime = new Date(dateNow.getFullYear() + 10, dateNow.getMonth(), dateNow.getDate());
                        schedule.iterationPeriod = 0;
                        break;
                    case 'repeats':
                        schedule.startTime = whenModel.datetime;

                        switch (whenModel.forRange) {
                            case 'hours':
                            case 'days':
                            case 'weeks':
                            case 'months':
                                schedule.endTime = DateTimeUtility.add(whenModel.dateTime, whenModel.for, whenModel.forRange);
                                break;
                            case 'ever':
                                schedule.endTime = new Date('12/31/9999 23:59:59:59 UTC');
                                break;
                        }

                        schedule.iterationPeriod = DateTimeUtility.durationInMinutes(whenModel.every, whenModel.everyRange);
                        break;
                }

                break;
            case 'when':
                schedule.iterationPeriod = null;
                schedule.startTime = dateNow;
                schedule.endTime = new Date(dateNow.getFullYear() + 10, dateNow.getMonth(), dateNow.getDate());

                switch (whenModel.action) {
                    case 'connects':
                        schedule.executeOnEvent = ScheduleEventTypeEnum.unitConnected;
                        schedule.parentSchedule = null;
                        break;
                    case 'disconnects':
                        schedule.executeOnEvent = ScheduleEventTypeEnum.unitDisconnected;
                        schedule.parentSchedule = null;
                        break;
                    case 'rediscovers':
                        schedule.executeOnEvent = ScheduleEventTypeEnum.unitRediscovered;
                        schedule.parentSchedule = null;
                        break;
                    case 'settingschanged':
                        schedule.executeOnEvent = ScheduleEventTypeEnum.unitFlashSettingsChanged;
                        schedule.parentSchedule = null;
                        break;
                    case 'schedulecompletes':
                        schedule.executeOnEvent = ScheduleEventTypeEnum.scheduleCompleted;

                        if (!this.isNullOrUndefined(whenModel.scheduleCompletes) && isNumber(whenModel.scheduleCompletes)) {
                            this.addSubscription(this.observableHandlerBase(this._scheduleService.getSchedule(whenModel.scheduleCompletes, this.getScheduleProcess), this.getScheduleProcess).subscribe(
                                result => {
                                    schedule.parentSchedule = result;
                                }
                            ), this.getScheduleProcess);
                        }

                        break;
                }
                break;
        }

        const actionsModel = this.actionsFormGroup.value;

        schedule.workflowName = actionsModel.action;
    }

    private add(): void {
        this.addSubscription(this.observableHandlerBase(this._scheduleService.add(this.schedule, this.addScheduleProcess), this.addScheduleProcess).subscribe(
            result => {
                this._eventsService.changedSchedules();
                this._dialogRef.close();
            }
        ), this.addScheduleProcess);
    }

    private save(): void {
        if (this.schedule.hasChanges === true) {
            this.addSubscription(this.observableHandlerBase(this._scheduleService.update(this.schedule, this.saveScheduleProcess), this.saveScheduleProcess).subscribe(
                result => {
                    this._eventsService.changedSchedules();
                    this._dialogRef.close();
                }
            ), this.saveScheduleProcess);
        } else {
            this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('No Changes', 'There are no changes to save.', false) });
        }
    }

    private addFormsSubscriptions(): void {
        this.addSubscription(this.observableHandlerBase(this.deviceOrGroupFormGroup.controls.operatesOn.valueChanges, this.formValuesChangeProcess).subscribe(this.onOperatesOnChanged.bind(this)));
        this.addSubscription(this.observableHandlerBase(this.deviceOrGroupFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModel(this.schedule)));

        this.addSubscription(this.observableHandlerBase(this.whenFormGroup.controls.execute.valueChanges, this.formValuesChangeProcess).subscribe(this.onExecuteChanged.bind(this)));
        this.addSubscription(this.observableHandlerBase(this.whenFormGroup.controls.repeats.valueChanges, this.formValuesChangeProcess).subscribe(this.onRepeatsChanged.bind(this)));
        this.addSubscription(this.observableHandlerBase(this.whenFormGroup.controls.forRange.valueChanges, this.formValuesChangeProcess).subscribe(this.onForRangeChanged.bind(this)));
        this.addSubscription(this.observableHandlerBase(this.whenFormGroup.controls.action.valueChanges, this.formValuesChangeProcess).subscribe(this.onActionChanged.bind(this)));
        this.addSubscription(this.observableHandlerBase(this.whenFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModel(this.schedule)));

        this.addSubscription(this.observableHandlerBase(this.descriptionFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModel(this.schedule)));
        this.addSubscription(this.observableHandlerBase(this.actionsFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModel(this.schedule)));
    }

    private removeFormsSubscriptions(): void {

    }
}
