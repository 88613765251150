import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, EventEmitter, HostBinding, Injector, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Column, DeviceListSelectColumnsComponent } from '@em/components/shared/devicelist/selectcolumns/DeviceList.SelectColumns.Component';
import { DeviceSerialLinkComponent } from '@em/components/shared/deviceseriallink/DeviceSerialLink.Component';
import { ColumnSortingOptionModel } from '@em/models/restapi/ColumnSortingOption.Model';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { MetaDataKeyModel } from '@em/models/restapi/MetaDataKey.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { UserDeviceDisplayColumnModel } from '@em/models/restapi/UserDeviceDisplayColumn.Model';
import { MetaDataKeysService } from '@em/service/data/metadatakeys/MetaDataKeys.Service';
import { UserDeviceListColumnService } from '@em/service/data/user/User.DeviceList.Column.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ITableRowClicked, TableComponent } from '@shared/component/table/Table.Component';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { DeviceStateEnum } from '@shared/enum/DeviceState.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '@rift/service/connection/Connection.Service';

export const META_DATA_PREFIX: string = 'metadata_';

@Component({
    selector: 'em-device-list',
    templateUrl: './DeviceList.Component.html',
    styleUrls: ['./DeviceList.Component.scss']
})
export class DeviceListComponent extends BaseComponent implements ILoadDate, OnChanges, OnDestroy, AfterViewInit {
    public static className: string = 'DeviceListComponent';

    @Output()
    public pageOptionsChanged: EventEmitter<PaginationOptionsModel> = new EventEmitter();

    @Input()
    public devices: Array<DeviceModel>;

    @Input()
    public pageOptions: PaginationOptionsModel;

    @ViewChild('table', { static: true })
    public table: TableComponent<DeviceModel>;

    @HostBinding()
    public id: string = 'em-device-list';

    public columnAction: NavBarAction;
    public DateTimeUtility = DateTimeUtility;
    public DeviceCapabilitiesEnum = DeviceCapabilitiesEnum;
    public devicesDataSource = new MatTableDataSource<DeviceModel>();
    public DeviceStateEnum = DeviceStateEnum;
    public displayedColumns: Array<string> = [];
    public metaDataKeys: Array<MetaDataKeyModel>;
    public now = new Date();
    public sortActive: string;
    public sortDirection: SortDirection;
    public userColumns: Array<UserDeviceDisplayColumnModel>;
    public META_DATA_PREFIX = META_DATA_PREFIX;

    private _dataRefreshedTrigger: boolean = false;
    private _isLoadingData: boolean = false;

    public constructor(
        private readonly _connectionService: ConnectionService,
        private readonly _dialog: MatDialog,
        private readonly _router: Router,
        private readonly _navBarService: NavBarActionService,
        private readonly _userDeviceListColumnService: UserDeviceListColumnService,
        private readonly _metaDataKeysService: MetaDataKeysService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(DeviceListComponent.className, this.loadDataProcessText);

        this.columnAction = new NavBarAction();
        this.columnAction.name = 'selectColumns';
        this.columnAction.text = 'Select Columns';
        this.columnAction.menuComponent = DeviceListSelectColumnsComponent;
        this._navBarService.addAction(this.columnAction);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    @Input()
    public get dataRefreshedTrigger(): boolean {
        return this._dataRefreshedTrigger;
    }
    public set dataRefreshedTrigger(value: boolean) {
        this._dataRefreshedTrigger = coerceBooleanProperty(value);
    }

    @Input()
    public get isLoadingData(): boolean {
        return this._isLoadingData;
    }
    public set isLoadingData(value: boolean) {
        this._isLoadingData = coerceBooleanProperty(value);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(

            this._metaDataKeysService.getKeys(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.metaDataKeys = result.items;
                    }
                    return true;
                }),
            ),
            this._userDeviceListColumnService.get(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.userColumns = result;
                    }
                    return true;
                }),
            ),
        ).pipe(
            tap(() => {
                this.setupColumns(this.userColumns);
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngAfterViewInit(): void {
        this.addSubscription((this.columnAction.menuComponentRef.instance as DeviceListSelectColumnsComponent).selectionChange.subscribe(
            (column: Column) => {
                const columns = this.displayedColumns;

                let name = null;
                if (this.isNullOrUndefined(column.metaDataKeyId)) {
                    name = column.name;
                } else {
                    name = META_DATA_PREFIX + this.metaDataKeys.find(k => k.metaDataKeyId === column.metaDataKeyId).name;
                }

                const index = columns.indexOf(name);
                if (index !== -1) {
                    columns.splice(index, 1);
                } else {
                    const insertAt = this.getColumnSort(name);
                    columns.splice(insertAt, 0, name);
                }

                this.displayedColumns = columns;
                this.table.setDisplayedColumns(this.displayedColumns);
            }
        ));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.pageOptions) && !this.isNullOrUndefined(changes.pageOptions.currentValue)) {
            if (!this.isNullOrUndefined(changes.pageOptions.currentValue.columnSortingOptions) && changes.pageOptions.currentValue.columnSortingOptions.length > 0) {
                const columnSortingOption = changes.pageOptions.currentValue.columnSortingOptions[0];
                if (!this.isNullOrUndefined(columnSortingOption)) {
                    this.sortActive = columnSortingOption.columnName;
                    this.sortDirection = columnSortingOption.matDirection;
                }
            }
        }

        if (!this.isNullOrUndefined(changes.devices) && !this.isNullOrUndefined(changes.devices.currentValue)) {
            this.devicesDataSource.data = changes.devices.currentValue as Array<DeviceModel>;
        }
    }

    public dataExportGetData = (displayedColumn: string, data: DeviceModel) => {
        switch (displayedColumn) {
            case 'FriendlySerial':
                return data.friendlySerial;
            case 'Status':
                let status = '';

                status += data.isConnected === true ? 'Connected:' : '';
                status += data.state === DeviceStateEnum.error ? 'Has Error:' : '';
                status += data.state === DeviceStateEnum.warning ? 'Has Warning:' : '';
                status += data.hasCapability(DeviceCapabilitiesEnum.video) === true ? 'Video:' : '';

                return StringUtility.trimEnd(status, ':');
            case 'SiteName':
                return data.siteName;
            case 'SiteId':
                return data.siteId;
            case 'IPAddress':
                return data.iPAddress;
            case 'MACAddress':
                return data.mACAddress;
            case 'DeviceId':
                return data.deviceId;
            case 'DeviceName':
                return data.deviceName;
            case 'UserString':
                return data.userString;
            case 'Location':
                return data.location;
            case 'FirmwareVersion':
                return data.firmwareVersion;
            case 'LastConnected':
                return data.lastSeenText;
            case 'NodeCount':
                return data.nodeCount;
            default:
                const metaDataKey = this.metaDataKeys.find(k => k.name === displayedColumn.replace(META_DATA_PREFIX, ''));
                if (!this.isNullOrUndefined(metaDataKey)) {
                    const value = data.getMetaDataValue(metaDataKey.metaDataKeyId);
                    return this.isNullOrUndefined(value) ? '' : value;
                }
                return '';
        }
    };

    public dataExportGetHeader = (displayedColumn: string) => {
        switch (displayedColumn) {
            case 'FriendlySerial':
                return 'Friendly Serial';
            case 'Status':
                return 'Status';
            case 'SiteName':
                return 'Site Name';
            case 'SiteId':
                return 'Site Id';
            case 'IPAddress':
                return 'IP Address';
            case 'MACAddress':
                return 'MAC Address';
            case 'DeviceId':
                return 'Device Id';
            case 'DeviceName':
                return 'Device Name';
            case 'UserString':
                return 'User String';
            case 'Location':
                return 'Location';
            case 'FirmwareVersion':
                return 'Firmware Version';
            case 'LastConnected':
                return 'Last Seen';
            case 'NodeCount':
                return 'Node Count';
            default:
                return displayedColumn;
        }
    };

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this._navBarService.removeAction(this.columnAction);
    }

    public onPageChanged(event: PageEvent): void {
        this.pageOptions.page = (event.pageIndex + 1);
        this.pageOptions.resultsPerPage = event.pageSize;
        this.pageOptionsChanged.emit(this.pageOptions);
    }

    public onRowClicked(event: ITableRowClicked<DeviceModel>): void {
        if (event.event.ctrlKey === true) {
            window.open(DeviceSerialLinkComponent.getDeviceNavigationUrl(this._router, event.data.friendlySerial), '_blank');
        } else {
            DeviceSerialLinkComponent.navigateToDevice(this._router, event.data.friendlySerial, this._connectionService);
        }
    }

    public onSortChanged(event: Sort): void {
        if (event.direction !== '') {
            const columnSortingOption = new ColumnSortingOptionModel();
            columnSortingOption.columnName = event.active;
            columnSortingOption.matDirection = event.direction;

            this.pageOptions.columnSortingOptions = [columnSortingOption];
        } else {
            this.pageOptions.columnSortingOptions = [];
        }
        this.pageOptionsChanged.emit(this.pageOptions);
    }

    private getColumnSort(name: string): number {
        switch (name) {
            case 'FriendlySerial':
                return 0;
            case 'SiteName':
                return 1;
            case 'SiteId':
                return 2;
            case 'IPAddress':
                return 3;
            case 'MACAddress':
                return 4;
            case 'NodeCount':
                return 5;
            case 'DeviceId':
                return 6;
            case 'Location':
                return 7;
            case 'DeviceName':
                return 8;
            case 'UserString':
                return 9;
            case 'FirmwareVersion':
                return 10;
            case 'LastConnected':
                return 11;
            case 'DeviceID':
                return 12;
        }

        return 9999;
    }

    private setupColumns(userColumns: Array<UserDeviceDisplayColumnModel>): void {
        let displayedColumns: Array<string> = null;
        displayedColumns = userColumns
            .sort((a, b) => this.getColumnSort(a.columnName) - this.getColumnSort(b.columnName))
            .map(column => {
                if (this.isNullOrUndefined(column.metaDataKeyId)) {
                    return column.columnName;
                } else {
                    return META_DATA_PREFIX + this.metaDataKeys.find(key => key.metaDataKeyId === column.metaDataKeyId).name;
                }
            });
        displayedColumns.splice(1, 0, 'Status');
        this.displayedColumns = displayedColumns;
    }
}
