<ng-template [ngIf]="!isNullOrUndefined(register)">
    <!-- name and type -->
    <ng-template [ngIf]="register.registerType !== RegisterTypeEnum.tamper">
        <div class="summary-line">
            A {{ getDisplayName(register.registerType).toLocaleLowerCase() }} register called
            {{ register.registerName }}{{ isNullOrUndefined(registerNamePostfix) ? '' : ' ' + registerNamePostfix }}.
        </div>
    </ng-template>
    <ng-template [ngIf]="register.registerType === RegisterTypeEnum.tamper">
        <div class="summary-line">
            A tamper detection register {{ registerNamePostfix }}.
        </div>
    </ng-template>

    <!-- count mode -->
    <ng-template [ngIf]="registerType.hasSettingsCountMode === true">
        <!-- count mode -->
        <ng-template [ngIf]="!isNullOrUndefined(line) || (!isNullOrUndefined(register) && !isNullOrUndefined(register.countMode))">
            <div class="summary-line">
                Count mode is
                {{ CountModeEnumHelpers.toFullLongName(!isNullOrUndefined(line) ? line.countMode : register.countMode).toLocaleLowerCase() }}.
            </div>
            <div [ngSwitch]="!isNullOrUndefined(line) ? line.countMode : register.countMode">
                <div *ngSwitchCase="CountModeEnum.deferred"
                    class="sub-summary-line">
                    Count increments are not given immediately as the line is crossed. Instead, any registers are
                    incremented only when the target leaves the field of view. If the target u turns back across the line it will not be counted.
                </div>
                <div *ngSwitchCase="CountModeEnum.deferredCountUTurns"
                    class="sub-summary-line">
                    Count increments are not given immediately as the line is crossed. Instead, any registers are
                    incremented only when the target leaves the field of view. If the target u turns back across the line it will still be counted.
                </div>
                <div *ngSwitchCase="CountModeEnum.deferredOnlyCountUTurns"
                    class="sub-summary-line">
                    Count increments are not given immediately as the line is crossed. Instead, any registers are
                    incremented only when the target leaves the field of view. The target will only be counted if it u turns back across the line.
                </div>
                <div *ngSwitchCase="CountModeEnum.immediate"
                    class="sub-summary-line">
                    The first count increment is given at the time the target crosses the count line all subsequent line crossings are also
                    registered.
                </div>
                <div *ngSwitchCase="CountModeEnum.immediateAntiDither"
                    class="sub-summary-line">
                    The first count increment is given at the time the target crosses the count line only the first crossing is registered
                </div>
            </div>
            <div class="sub-summary-line">
                The following is a example walking patterns and the increments given for
                {{ CountModeEnumHelpers.toFullLongName(!isNullOrUndefined(line) ? line.countMode : register.countMode).toLocaleLowerCase() }} count
                mode.
            </div>
            <div class="countmode-example-table">
                <table [ngSwitch]="!isNullOrUndefined(line) ? line.countMode : register.countMode">
                    <tr>
                        <td>
                            <img src="./assets/settings/counting/shared/summary/line-mode-label.png">
                        </td>
                        <td colspan="2">
                            <img src="./assets/settings/counting/shared/summary/line-mode-1.png"
                                width="100px">
                        </td>
                        <td colspan="2">
                            <img src="./assets/settings/counting/shared/summary/line-mode-2.png"
                                width="100px">
                        </td>
                        <td colspan="2">
                            <img src="./assets/settings/counting/shared/summary/line-mode-3.png"
                                width="100px">
                        </td>
                        <td colspan="2">
                            <img src="./assets/settings/counting/shared/summary/line-mode-4.png"
                                width="100px">
                        </td>
                    </tr>
                    <!-- Immediate Anti-Dither Enabled -->
                    <tr *ngSwitchCase="CountModeEnum.immediateAntiDither">
                        <td> </td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                        <td class="line1">Line 1</td>
                        <td>0</td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                    </tr>
                    <tr *ngSwitchCase="CountModeEnum.immediateAntiDither">
                        <td> </td>
                        <td class="line2">Line 2</td>
                        <td>0</td>
                        <td class="line2">Line 2</td>
                        <td>1</td>
                        <td class="line2">Line 2</td>
                        <td>0</td>
                        <td class="line2">Line 2</td>
                        <td>0</td>
                    </tr>
                    <!-- Immediate Anti-Dither Disabled -->
                    <tr *ngSwitchCase="CountModeEnum.immediate">
                        <td> </td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                        <td class="line1">Line 1</td>
                        <td>3</td>
                        <td class="line1">Line 1</td>
                        <td>3</td>
                    </tr>
                    <tr *ngSwitchCase="CountModeEnum.immediate">
                        <td> </td>
                        <td class="line2">Line 2</td>
                        <td>1</td>
                        <td class="line2">Line 2</td>
                        <td>1</td>
                        <td class="line2">Line 2</td>
                        <td>2</td>
                        <td class="line2">Line 2</td>
                        <td>3</td>
                    </tr>
                    <!-- Deferred Ignore U-Turns -->
                    <tr *ngSwitchCase="CountModeEnum.deferred">
                        <td> </td>
                        <td class="line1">Line 1</td>
                        <td>0</td>
                        <td class="line1">Line 1</td>
                        <td>0</td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                        <td class="line1">Line 1</td>
                        <td>0</td>
                    </tr>
                    <tr *ngSwitchCase="CountModeEnum.deferred">
                        <td> </td>
                        <td class="line2">Line 2</td>
                        <td>0</td>
                        <td class="line2">Line 2</td>
                        <td>0</td>
                        <td class="line2">Line 2</td>
                        <td>0</td>
                        <td class="line2">Line 2</td>
                        <td>0</td>
                    </tr>
                    <!-- Deferred Count U-Turns -->
                    <tr *ngSwitchCase="CountModeEnum.deferredCountUTurns">
                        <td> </td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                    </tr>
                    <tr *ngSwitchCase="CountModeEnum.deferredCountUTurns">
                        <td> </td>
                        <td class="line2">Line 2</td>
                        <td>1</td>
                        <td class="line2">Line 2</td>
                        <td>1</td>
                        <td class="line2">Line 2</td>
                        <td>0</td>
                        <td class="line2">Line 2</td>
                        <td>0</td>
                    </tr>
                    <!-- Deferred Count U-Turns only -->
                    <tr *ngSwitchCase="CountModeEnum.deferredOnlyCountUTurns">
                        <td> </td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                        <td class="line1">Line 1</td>
                        <td>0</td>
                        <td class="line1">Line 1</td>
                        <td>1</td>
                    </tr>
                    <tr *ngSwitchCase="CountModeEnum.deferredOnlyCountUTurns">
                        <td> </td>
                        <td class="line2">Line 2</td>
                        <td>1</td>
                        <td class="line2">Line 2</td>
                        <td>0</td>
                        <td class="line2">Line 2</td>
                        <td>0</td>
                        <td class="line2">Line 2</td>
                        <td>0</td>
                    </tr>
                </table>
            </div>
        </ng-template>
    </ng-template>

    <!-- associated registers -->
    <ng-template [ngIf]="registerType.hasSettingsAssociated === true">
        <ng-template ngFor
            let-associatedRegisterVM
            [ngForOf]="associatedRegisterConfigsVMs">
            <div class="summary-line">
                {{ associatedRegisterVM.config.crossDescriptionText }}
            </div>

            <div [ngSwitch]="register.registerType">
                <!-- sequential -->
                <div *ngSwitchCase="RegisterTypeEnum.sequential">
                    <div class="associated-register-line"
                        fxLayout="column"
                        *ngFor="let vm of associatedRegisterVM.associatedRegisterVMs; index as i">
                        <rift-register-icon-name style="cursor: pointer; color: blue; text-decoration: underline;"
                            (click)="onAssociatedRegisterClicked(vm)"
                            [registerBase]="vm.register"></rift-register-icon-name>
                        <div *ngIf="i < (associatedRegisterVM.associatedRegisterVMs.length - 1)"
                            style="margin-left: 20px; margin-top: 10px;">then</div>
                    </div>
                </div>

                <!-- summation -->
                <div *ngSwitchCase="RegisterTypeEnum.summation">
                    <div class="associated-register-line"
                        fxLayout="column"
                        *ngFor="let vm of associatedRegisterVM.associatedRegisterVMs; index as i">
                        <div fxLayout="row"
                            fxLayoutAlign="start center">
                            <div style="min-width: 60px;">{{ getSummationAdd(vm) === true ? ' add' : ' subtract'}}</div>
                            <rift-register-icon-name style="cursor: pointer; color: blue; text-decoration: underline;"
                                (click)="onAssociatedRegisterClicked(vm)"
                                [registerBase]="vm.register"></rift-register-icon-name>
                        </div>
                    </div>
                </div>

                <!-- default -->
                <div *ngSwitchDefault>
                    <div class="associated-register-line"
                        fxLayout="column"
                        *ngFor="let vm of associatedRegisterVM.associatedRegisterVMs; index as i">
                        <rift-register-icon-name style="cursor: pointer; color: blue; text-decoration: underline;"
                            (click)="onAssociatedRegisterClicked(vm)"
                            [registerBase]="vm.register"></rift-register-icon-name>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-template>

    <!-- simpleProperties -->
    <ng-template ngFor
        let-simpleProperty
        [ngForOf]="registerType.registerTypeConfig.simpleProperties">
        <div class="summary-line">
            {{ simpleProperty.summaryPrefixText }}
            {{ isFunction(simpleProperty.fromModelValue) ? simpleProperty.fromModelValue(register[simpleProperty.propertyName]) : register[simpleProperty.propertyName] }}
            {{ simpleProperty.summaryPostfixText }}.
        </div>
    </ng-template>

    <!-- max dwell -->
    <ng-template [ngIf]="registerType.hasMaxDwell === true && (isNullOrUndefined(registerType.registerTypeConfig.maxDwellAllowed) || registerType.registerTypeConfig.maxDwellAllowed(register) === true)">
        <div class="summary-line">
            Max Dwell Duration is {{ !isNullOrUndefined(register['maxDwellEnabled']) && register['maxDwellEnabled'] === true ? (register['maxDwellDuration'] / 10) + ' S.' : 'disabled.'}}
        </div>
    </ng-template>

    <!-- exclusion type -->
    <ng-template [ngIf]="registerType.hasExclusionType === true && !isNullOrUndefined(register['lineExclusionRegisterType'])">
        <div class="summary-line">
            Exclusion type is {{ LineExclusionTypeEnumHelpers.toFullLongName(register['lineExclusionRegisterType']) }}
        </div>
    </ng-template>

    <!-- height filtering -->
    <ng-template [ngIf]="registerType.hasSettingsHeightFiltering === true">
        <div class="summary-line">
            Height filtering is {{ register['heightFilterEnabled'] === true ? 'enabled' : 'disabled'}}.
        </div>
        <ng-template [ngIf]="register['heightFilterEnabled'] === true">
            <div class="sub-summary-line">
                The register will only count targets from
                <rift-units-of-measurement-label [metricValue]="register['lowerBound']"
                    [metricUnit]="UnitOfMeasurementEnum.centimeter"
                    [imperialUnit]="UnitOfMeasurementEnum.inch"></rift-units-of-measurement-label>
                to
                <rift-units-of-measurement-label [metricValue]="register['upperBound']"
                    [metricUnit]="UnitOfMeasurementEnum.centimeter"
                    [imperialUnit]="UnitOfMeasurementEnum.inch"></rift-units-of-measurement-label>.
            </div>
            <ng-template
                [ngIf]="!isNullOrUndefined(globalHeightFilter) && (globalHeightFilter.lowerBound > register['lowerBound'] || globalHeightFilter.upperBound < register['upperBound'])">
                <div class="sub-summary-line error">
                    The register height filtering will be clipped by the global height filtering configuration to
                    <rift-units-of-measurement-label
                        [metricValue]="globalHeightFilter.lowerBound > register['lowerBound'] ? globalHeightFilter.lowerBound : register['lowerBound']"
                        [metricUnit]="UnitOfMeasurementEnum.centimeter"
                        [imperialUnit]="UnitOfMeasurementEnum.inch"></rift-units-of-measurement-label>
                    to
                    <rift-units-of-measurement-label
                        [metricValue]="globalHeightFilter.upperBound < register['upperBound'] ? globalHeightFilter.upperBound : register['upperBound'] "
                        [metricUnit]="UnitOfMeasurementEnum.centimeter"
                        [imperialUnit]="UnitOfMeasurementEnum.inch"></rift-units-of-measurement-label>.
                </div>
            </ng-template>
        </ng-template>
    </ng-template>

    <!-- staff detection -->
    <ng-template [ngIf]="registerType.hasSettingsStaffDetection === true">
        <div class="summary-line">
            Staff detection is {{ register['markedTarget'] === MarkedTargetEnum.none ? 'disabled' : 'enabled' }}.
        </div>
        <div class="sub-summary-line"
            *ngIf="register['markedTarget'] !== MarkedTargetEnum.none">
            {{ register['markedTarget'] === MarkedTargetEnum.exclude ? 'The register will not count staff' : 'The register
            will
            only count staff' }}
        </div>
    </ng-template>

    <!-- zone -->
    <ng-template [ngIf]="registerType.hasSettingsZone === true">
        <div class="summary-line">
            Zone mode is {{ register['zoneType'] === ZoneTypeEnum.deferred ? 'On Exit' : 'On Enter' }}
        </div>
    </ng-template>

    <!-- height histogram -->
    <ng-template [ngIf]="registerType.hasSettingsHeightHistogram === true">
        <div class="summary-line">
            Height histogram is {{ register.hasHeightHistogram === true ? 'enabled' : 'disabled'}}.
        </div>
        <ng-template [ngIf]="register.hasHeightHistogram === true">
            <ng-template ngFor
                let-heightHistogram
                [ngForOf]="register.heightHistograms">
                <div class="sub-summary-line">
                    The min value is <rift-units-of-measurement-label [metricValue]="heightHistogram?.minimumValue"
                        [metricUnit]="UnitOfMeasurementEnum.centimeter"
                        [imperialUnit]="UnitOfMeasurementEnum.inch"></rift-units-of-measurement-label>
                    and the bin width is <rift-units-of-measurement-label [metricValue]="heightHistogram?.binWidth"
                        [metricUnit]="UnitOfMeasurementEnum.centimeter"
                        [imperialUnit]="UnitOfMeasurementEnum.inch"></rift-units-of-measurement-label> there
                    are {{ heightHistogram?.numberOfBins }} bins. This will result in a height range of
                    <rift-units-of-measurement-label [metricValue]="heightHistogram?.minimumValue"
                        [metricUnit]="UnitOfMeasurementEnum.centimeter"
                        [imperialUnit]="UnitOfMeasurementEnum.inch"></rift-units-of-measurement-label>
                    to <rift-units-of-measurement-label
                        [metricValue]="(heightHistogram?.numberOfBins * heightHistogram?.binWidth) + heightHistogram?.minimumValue"
                        [metricUnit]="UnitOfMeasurementEnum.centimeter"
                        [imperialUnit]="UnitOfMeasurementEnum.inch"></rift-units-of-measurement-label>.
                </div>
                <ng-template
                    [ngIf]="register.isHeightFilteringCompatible === true && !isNullOrUndefined(register['lowerBound']) && !isNullOrUndefined(register['upperBound']) && (register['lowerBound'] > heightHistogram?.minimumValue || register['upperBound'] < (heightHistogram?.numberOfBins * heightHistogram?.binWidth) + heightHistogram?.minimumValue)">
                    <div class="sub-summary-line error">
                        There will be blank regions of data in the histogram as height filtering excludes areas of the
                        histogram
                        range.
                    </div>
                </ng-template>
            </ng-template>

        </ng-template>
    </ng-template>

    <!-- unattended time histogram -->
    <ng-template [ngIf]="registerType.hasSettingsUnattendedTimeDwellHistogram === true">
        <div class="summary-line">
            Unattended time histogram is {{ register.hasUnattendedTimeHistogram === true ? 'enabled' : 'disabled'}}.
        </div>
        <ng-template [ngIf]="register.hasUnattendedTimeHistogram === true">
            <ng-template ngFor
                let-unattendedTimeHistogram
                [ngForOf]="register.unattendedTimeHistograms">
                <div class="sub-summary-line">
                    The min value is {{unattendedTimeHistogram?.minimumValue / 10 }} s and the bin width is
                    {{unattendedTimeHistogram?.binWidth / 10 }} s there are {{ unattendedTimeHistogram?.numberOfBins }}
                    bins. This will result in a dwell range of {{ unattendedTimeHistogram?.minimumValue / 10 }} to
                    {{ ((unattendedTimeHistogram?.numberOfBins * unattendedTimeHistogram?.binWidth) + unattendedTimeHistogram?.minimumValue) / 10 }}.
                </div>
            </ng-template>
        </ng-template>
    </ng-template>

    <!-- dwell histogram -->
    <ng-template [ngIf]="registerType.hasSettingsDwellHistogram === true && (isNullOrUndefined(registerType.registerTypeConfig.dwellHistogramAllowed) || registerType.registerTypeConfig.dwellHistogramAllowed(register) === true)">
        <div class="summary-line">
            Dwell histogram is {{ register.hasDwellHistogram === true ? 'enabled' : 'disabled'}}.
        </div>
        <ng-template [ngIf]="register.hasDwellHistogram === true">
            <ng-template ngFor
                let-dwellHistogram
                [ngForOf]="register.dwellHistograms">
                <div class="sub-summary-line">
                    The min value is {{dwellHistogram?.minimumValue / 10 }} s and the bin width is
                    {{dwellHistogram?.binWidth / 10 }} s there are {{ dwellHistogram?.numberOfBins }}
                    bins. This will result in a dwell range of {{ dwellHistogram?.minimumValue / 10 }} to
                    {{ ((dwellHistogram?.numberOfBins * dwellHistogram?.binWidth) + dwellHistogram?.minimumValue) / 10 }}.
                </div>
            </ng-template>
        </ng-template>
    </ng-template>

    <!-- instant dwell histogram -->
    <ng-template [ngIf]="registerType.hasSettingsInstantDwellHistogram === true">
        <div class="summary-line">
            Instant dwell histogram is {{ register.hasInstantDwellHistogram === true ? 'enabled' : 'disabled'}}.
        </div>
        <ng-template [ngIf]="register.hasInstantDwellHistogram === true">
            <ng-template ngFor
                let-instantDwellHistogram
                [ngForOf]="register.instantDwellHistograms">
                <div class="sub-summary-line">
                    The min value is {{instantDwellHistogram?.minimumValue / 10 }} s and the bin width is
                    {{instantDwellHistogram?.binWidth / 10 }} s there are {{ instantDwellHistogram?.numberOfBins }}
                    bins. This will result in a dwell range of
                    {{ instantDwellHistogram.minimumValue / 10 }}
                    to {{ ((instantDwellHistogram?.numberOfBins * instantDwellHistogram?.binWidth) + instantDwellHistogram?.minimumValue) / 10 }}.
                </div>
            </ng-template>
        </ng-template>
    </ng-template>
</ng-template>
