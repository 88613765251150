<div class="em-settings-schedules-view" fxLayout="row">
    <div class="column left" fxFlex="50" fxLayout="column">
        <div class="row">
            <mat-card class="status">
                <shr-background-load-spinner [trigger]="loadDataProcess.isRunning" class="led">
                </shr-background-load-spinner>
                <mat-card-title>
                    {{ schedule?.name }}
                </mat-card-title>
                <mat-card-content>
                    <div fxLayout="column">
                        <div class="row" fxLayout="row">
                            <span class="label">Action</span>
                            <span class="value">{{schedule?.workflow?.description}}</span>
                        </div>
                        <div class="row" fxLayout="row">
                            <span class="label">Operates On</span>
                            <span class="value">{{schedule?.operatesOnText}}</span>
                        </div>
                        <div class="row" fxLayout="row">
                            <span class="label">Next Execution</span>
                            <span class="value" fxLayout="row" fxLayoutAlign="start center">
                                <mat-spinner *ngIf="schedule?.isCurrentlyExecuting === true" diameter="20"
                                    style="margin-right: 5px;"></mat-spinner>
                                {{schedule?.isCurrentlyExecuting === true ? ' Executing' : schedule?.nextExecutionText}}
                            </span>
                        </div>
                        <div class="row" fxLayout="row">
                            <span class="label">Repeats</span>
                            <span
                                class="value">{{schedule?.executionWaitPeriod === 0 ? 'N/A' : schedule?.executionWaitPeriodText}}</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="row">
            <mat-card class="percentages">
                <shr-background-load-spinner [trigger]="loadDataProcess.isRunning" class="led">
                </shr-background-load-spinner>
                <mat-card-content>
                    <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
                        <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
                            <span class="mat-subheading-1">Average Success Rate</span>
                            <span *ngIf="schedule?.isNA === true">N/A</span>
                            <span *ngIf="schedule?.isNA === false"
                                class="percent-value">{{schedule?.successRatePercentage}}%</span>
                        </div>
                        <mat-divider class="divider" [vertical]="true" style="height: 95px;"></mat-divider>
                        <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
                            <span class="mat-subheading-1">Average Device Coverage</span>
                            <span *ngIf="schedule?.isNA === true">N/A</span>
                            <span *ngIf="schedule?.isNA === false"
                                class="percent-value">{{schedule?.coveragePercentage}}%</span>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div shrResized (resized)="onDeviceSuccessRateResized($event)" class="row">
            <mat-card>
                <shr-background-load-spinner [trigger]="loadDataProcess.isRunning" class="led">
                </shr-background-load-spinner>
                <mat-card-title>
                    Device Success Rate
                </mat-card-title>
                <mat-card-content>
                    <div id="device-success-rate-barchart" fxFlex>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div shrResized (resized)="onDeviceCoverageResized($event)" class="row">
            <mat-card>
                <shr-background-load-spinner [trigger]="loadDataProcess.isRunning" class="led">
                </shr-background-load-spinner>
                <mat-card-title>
                    Device Coverage
                </mat-card-title>
                <mat-card-content>
                    <div id="device-coverage-barchart" fxFlex>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="column right" fxFlex="50" fxLayout="column">
        <div class="row">
            <shr-table [isLoadingData]="getPageResultsProcess.isRunning || loadDataProcess.isRunning" [serverPage]="true"
                title="Results" [rowMouseHover]="true" [multiDataRows]="true" multiDataRowColumns="['expandedDetail']"
                (rowClicked)="onRowClicked($event)" (page)="onPageChanged($event)" [dataSource]="resultsDataSource"
                [displayedColumns]="displayedColumns" [pageIndex]="resultsPageOptions.page - 1"
                [recordLength]="resultsPageOptions.totalResults" [pageSize]="resultsPageOptions.resultsPerPage"
                [dataExportEnabled]="true" [dataExportGetDataHandler]="dataExportGetData"
                [dataExportGetHeaderHandler]="dataExportGetHeader">

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let result" [attr.colspan]="displayedColumns.length">
                        <div *ngIf="!isNullOrUndefined(expandedScheduleResult) && result.scheduleResultId === expandedScheduleResult.scheduleResultId"
                            class="result-detail"
                            [@detailExpand]="!isNullOrUndefined(expandedScheduleResult) && result.scheduleResultId === expandedScheduleResult.scheduleResultId ? 'expanded' : 'collapsed'">

                            <div class="result-columns" fxLayout="row" fxLayoutAlign="space-between stretch">
                                <div *ngIf="deviceResultsWarning?.length > 0" class="result-column" fxLayout="column">
                                    <div class="result-header">
                                        Warning ({{deviceResultsWarning?.length}})
                                    </div>
                                    <div fxFill class="result-rows">
                                        <div class="result-row" *ngFor="let friendlySerial of deviceResultsWarning">
                                            <em-device-serial-link viewMode="link" [serial]="friendlySerial">
                                            </em-device-serial-link>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="deviceResultsError?.length > 0" class="result-column" fxLayout="column">
                                    <div class="result-header">
                                        Error ({{deviceResultsError?.length}})
                                    </div>
                                    <div fxFill class="result-rows">
                                        <div class="result-row" *ngFor="let friendlySerial of deviceResultsError">
                                            <em-device-serial-link viewMode="link" [serial]="friendlySerial">
                                            </em-device-serial-link>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="deviceResultsNotPresent?.length > 0" class="result-column"
                                    fxLayout="column">
                                    <div class="result-header">
                                        Missing ({{deviceResultsNotPresent?.length}})
                                    </div>
                                    <div fxFill class="result-rows">
                                        <div class="result-row" *ngFor="let friendlySerial of deviceResultsNotPresent">
                                            <em-device-serial-link viewMode="link" [serial]="friendlySerial">
                                            </em-device-serial-link>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="deviceResultsSuccess?.length > 0" class="result-column" fxLayout="column">
                                    <div class="result-header">
                                        Success ({{deviceResultsSuccess?.length}})
                                    </div>
                                    <div fxFill class="result-rows">
                                        <div class="result-row" *ngFor="let friendlySerial of deviceResultsSuccess">
                                            <em-device-serial-link viewMode="link" [serial]="friendlySerial">
                                            </em-device-serial-link>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="deviceResultsPlaceholder?.length > 0" class="result-column"
                                    fxLayout="column">
                                    <div class="result-header">
                                        Placeholder ({{deviceResultsPlaceholder?.length}})
                                    </div>
                                    <div fxFill class="result-rows">
                                        <div class="result-row" *ngFor="let friendlySerial of deviceResultsPlaceholder">
                                            <em-device-serial-link viewMode="link" [serial]="friendlySerial">
                                            </em-device-serial-link>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="deviceResultsInvalid?.length > 0" class="result-column" fxLayout="column">
                                    <div class="result-header">
                                        Invalid ({{deviceResultsInvalid?.length}})
                                    </div>
                                    <div fxFill class="result-rows">
                                        <div class="result-row" *ngFor="let friendlySerial of deviceResultsInvalid">
                                            <em-device-serial-link viewMode="link" [serial]="friendlySerial">
                                            </em-device-serial-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let result">
                        <shr-fa matTooltip="Passed" class="icon"
                            *ngIf="result?.type === ScheduleResultStatusTypeEnum.pass" name="check"></shr-fa>
                        <shr-fa matTooltip="Failed" class="icon"
                            *ngIf="result?.state === ScheduleResultStatusTypeEnum.fail" name="exclamation-triangle">
                        </shr-fa>
                        <shr-fa matTooltip="Aborted" class="icon"
                            *ngIf="result?.type === ScheduleResultStatusTypeEnum.abort" name="minus-circle"></shr-fa>

                        <shr-table-cell [data]="result" keyPath="executedText"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="success">
                    <th mat-header-cell *matHeaderCellDef> Success </th>
                    <td mat-cell *matCellDef="let result">
                        <span *ngIf="schedule?.isNA === true">N/A</span>
                        <shr-table-cell *ngIf="schedule?.isNA === false" [data]="result" keyPath="successRatePercentage"
                            postFix="%"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="coverage">
                    <th mat-header-cell *matHeaderCellDef> Coverage </th>
                    <td mat-cell *matCellDef="let result">
                        <span *ngIf="schedule?.isNA === true">N/A</span>
                        <shr-table-cell *ngIf="schedule?.isNA === false" [data]="result" keyPath="coveragePercentage"
                            postFix="%"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef> Duration </th>
                    <td mat-cell *matCellDef="let result">
                        <shr-table-cell [data]="result" keyPath="executionTimeText"></shr-table-cell>
                    </td>
                </ng-container>

                <tr mat-row *matRowDef="let data; columns: ['expandedDetail']" class="detail-row"></tr>
            </shr-table>
        </div>
    </div>
</div>
