<div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Bookmark Details
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div mat-dialog-content fxLayout="column">
    <mat-form-field>
        <textarea matInput [(ngModel)]="data.bookmark.comment" [disabled]="data.mode === 'view'" placeholder="Comments"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="close()">Close</button>
    <div fxFlex></div>
    <button *ngIf="data.mode === 'view'" mat-raised-button (click)="delete()">Delete</button>
    <button *ngIf="data.mode === 'new'" mat-raised-button (click)="save()">Save</button>
</div>
