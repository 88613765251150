import { Routes } from '@angular/router';
import { DocumentationComponent } from '@em/components/documentation/Documentation.Component';
import { DocumentationRestApiComponent } from '@em/components/documentation/restapi/Documentation.RestApi.Component';
import { IRouteData } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { UserIsAuthenticated } from '@em/service/user/UserIsLevel';

export const EmDocumentationRoutes: Routes = [
    {
        path: 'documentation',
        canActivate: [UserIsAuthenticated],
        component: DocumentationComponent,
        data: { breadcrumb: { name: 'em-documentation', text: 'Documentation' } } as IRouteData,
        children: [
            {
                path: 'restapi',
                canActivate: [UserIsAuthenticated],
                component: DocumentationRestApiComponent,
                data: { breadcrumb: { name: 'em-documentation-restapi', text: 'Rest API' } } as IRouteData
            }
        ]
    },
];
