import { IValidationDataFrameModel, ValidationDataFrameModel } from '@rift/models/websocket/ValidationDataFrame.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface IValidationDataResponseModel {
    id: number;
    data: IValidationDataFrameModel;
    type: string;
}

export class ValidationDataResponseModel extends BaseModel implements IValidationDataResponseModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'validation_data_response_message';

    public readonly isIWebSocketModel: boolean = true;
    public readonly packetType: string = 'validation_data_response_message';

    public id: number = null;
    public data: ValidationDataFrameModel = null;
    public type: string = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-ValidationDataResponseModel');

        this.data = WebSocketModelUtility.loadFrom(message.data, ValidationDataFrameModel);
    }

    public toWebSocketMessage(): void {
        throw new Error('Method not implemented.');
    }
}
