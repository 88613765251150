import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IValidationReportNodeModel extends IRestModel {
    deviceName: string;
    serialNumber: string;
    deviceID: string;
}

export class ValidationReportNodeModel extends BaseModel implements IValidationReportNodeModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public deviceName: string = null;
    public serialNumber: string = null;
    public deviceID: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any, upperCase: boolean = true): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ValidationReportNodeModel', null, upperCase);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }

    public toInterface(): IValidationReportNodeModel {
        return {
            deviceName: this.deviceName,
            serialNumber: this.serialNumber,
            deviceID: this.deviceID,
        } as IValidationReportNodeModel;
    }
}
