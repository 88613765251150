import { Component, EventEmitter, HostBinding, Injector, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsAddressBookFilterComponent } from '@em/components/settings/addressbook/filter/Settings.AddressBook.Filter.Component';
import { FilterOnEntry } from '@em/components/settings/addressbook/Settings.AddressBook.Component';
import { AddressBookTreeComponent } from '@em/components/shared/addressbooktree/AddressBookTree.Component';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { SearchCriteriaModel } from '@em/models/restapi/SearchCriteria.Model';
import { SearchCriteriaGroupModel } from '@em/models/restapi/SearchCriteriaGroup.Model';
import { BaseComponent } from '@shared/base/Base.Component';

@Component({
    selector: 'em-settings-address-book-edit',
    templateUrl: './Settings.AddressBook.Edit.Component.html',
    styleUrls: ['./Settings.AddressBook.Edit.Component.scss']
})
export class SettingsAddressBookEditComponent extends BaseComponent {
    public static className: string = 'SettingsAddressBookEditComponent';

    @HostBinding()
    public id: string = 'em-settings-address-book-edit';

    @ViewChildren('filter')
    public filters: QueryList<SettingsAddressBookFilterComponent>;

    @Input()
    public addressBook: AddressBookTreeComponent;

    @Input()
    public selectedGroup: GroupModel;

    @Input()
    public filterOnEntriesSettings: Array<FilterOnEntry>;

    @Input()
    public filterOnEntriesMeta: Array<FilterOnEntry>;

    @Output()
    public filterValidChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public filterChanged: EventEmitter<null> = new EventEmitter<null>();

    @Output()
    public deleteCriteria: EventEmitter<{ criteria: SearchCriteriaModel; criteriaGroup: SearchCriteriaGroupModel }> = new EventEmitter<{ criteria: SearchCriteriaModel; criteriaGroup: SearchCriteriaGroupModel }>();

    @Output()
    public addCriteria: EventEmitter<SearchCriteriaGroupModel> = new EventEmitter<SearchCriteriaGroupModel>();

    @Output()
    public addCriteriaGroup: EventEmitter<GroupModel> = new EventEmitter<GroupModel>();

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);
    }

    public onFilterValidChanged(valid: boolean): void {
        this.filterValidChanged.emit(valid);
    }

    public onFilterChanged(): void {
        this.filterChanged.emit(null);
    }

    public onDeleteCriteria(criteria: SearchCriteriaModel, criteriaGroup: SearchCriteriaGroupModel): void {
        this.deleteCriteria.emit({ criteria, criteriaGroup });
    }

    public onAddCriteria(criteriaGroup: SearchCriteriaGroupModel): void {
        this.addCriteria.emit(criteriaGroup);
    }

    public onAddCriteriaGroup(group: GroupModel): void {
        this.addCriteriaGroup.emit(group);
    }

    public get filtersValid(): boolean {
        if (!this.isNullOrUndefined(this.filters)) {
            const valid = !this.filters.some(i => i.searchCriteriaForm.valid === false);
            return valid;
        }
    }
}
