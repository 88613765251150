import { Injectable } from '@angular/core';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { StoreDataModel } from '@rift/models/restapi/StoreData.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiLocalService } from '@rift/service/restapi/v1/RestApi.Local.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';

@Injectable()
export class StoreDataService extends RiftBaseService {

    private _getLoadingTracker = new ObservableTracker<StoreDataModel>();

    private _setLoadingTracker = new ObservableTracker<ResultModel>();
    private _storeDataCache: StoreDataModel;

    public constructor(
        private readonly _restApiLocalService: RestApiLocalService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._storeDataCache = null;
    }
    public get(process?: ProcessMonitorServiceProcess): Observable<StoreDataModel> {
        if (isNullOrUndefined(this._storeDataCache)) {
            return this._getLoadingTracker
                .getLoading()
                .observable(this._restApiLocalService.getStoreData(process).pipe(
                    map(result => {
                        this._storeDataCache = result;
                        return this._storeDataCache;
                    })
                ));
        } else {
            return of(this._storeDataCache);
        }
    }
    public set(date: StoreDataModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setLoadingTracker
            .getLoading(date)
            .observable(this._restApiLocalService.setStoreData(date, process));
    }
}
