import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface ITaskUpdateModel {
    type: string;
    action: string;
    result: number;
    exceptionMessage: string;
    exceptionStackTrace: string;
    connectionMessage: string;
    displayText: string;
    options: Array<string>;
    status: string;
    newToken: string;
}

export class TaskUpdateModel extends BaseModel implements ITaskUpdateModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'tasklauncher_case';

    public isIWebSocketModel: boolean = true;
    public packetType: string = null;
    public id: number = null;

    public type: string = null;
    public action: string = null;
    public result: number = null;
    public exceptionMessage: string = null;
    public exceptionStackTrace: string = null;
    public connectionMessage: string = null;
    public displayText: string = null;
    public options: Array<string> = null;
    public status: string = null;
    public newToken: string = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-TaskUpdateModel');
        this.options = message.options;
    }

    public toWebSocketMessage(): any {
        return {
            ...WebSocketModelUtility.toJson(this),
        };
    }
}
