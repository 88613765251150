import { IViewModel } from '@shared/interface/IViewModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { MatTableDataSource } from '@angular/material/table';

export class IViewModelUtility {
    public static getDataSourceSortingDataAccessor(dataSource: MatTableDataSource<IViewModel>): (data: IViewModel, sortHeaderId: string) => string | number {
        const base = dataSource.sortingDataAccessor;
        return (data: any, sortHeaderId: string) => !isNullOrUndefined(data[sortHeaderId]) ? base(data, sortHeaderId) : base(data.item, sortHeaderId);
    }
}
