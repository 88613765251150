<div class="em-myaccount-changepassword-title"
    mat-dialog-title>
    {{isNullOrUndefined(data) ? 'Change Password' : !isNullOrUndefined(data.title) ? data.title : 'Change Password'}}
    <div class="sub-title">
        {{isNullOrUndefined(data) ? '' : !isNullOrUndefined(data.subTitle) ? data.subTitle : ''}}
    </div>
</div>
<form [formGroup]="changePasswordForm">
    <div class="em-myaccount-changepassword-content"
        mat-dialog-content
        fxLayout="column">
        <mat-form-field>
            <input type="password"
                #oldPassword
                matInput
                formControlName="oldPassword"
                placeholder="Old Password"
                autofocus>
            <mat-error>{{getFormControlErrorMessage(changePasswordForm.controls['oldPassword'])}}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <input type="password"
                #newPassword
                matInput
                formControlName="newPassword"
                placeholder="New Password">
            <mat-error>{{getFormControlErrorMessage(changePasswordForm.controls['newPassword'])}}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <input type="password"
                #confirmPassword
                matInput
                formControlName="confirmPassword"
                placeholder="Confirm Password">
            <mat-error>{{getFormControlErrorMessage(changePasswordForm.controls['confirmPassword'])}}</mat-error>
        </mat-form-field>
        <div class="error"
            *ngIf="!isNullOrUndefined(result) && result.changed === false">
            Unable to change your password please try again.
        </div>
        <div class="error"
            *ngIf="!isNullOrUndefined(changePasswordForm.errors?.mismatchedPasswords)">
            {{changePasswordForm.errors?.mismatchedPasswords.message}}
        </div>
    </div>
    <div class="em-myaccount-changepassword-actions"
        mat-dialog-actions>
        <shr-progress-button-spinner [raised]="true"
            [diameter]="30"
            text="Save"
            mode="indeterminate"
            [active]="saveAllChangesProcess.isRunning === true"
            [disabled]="!isValid || !hasChanges"
            (shrClick)="save()"
            color="primary"></shr-progress-button-spinner>
        <div fxFlex></div>
        <button *ngIf="isNullOrUndefined(data) || data.allowCancel === true"
            [disabled]="saveAllChangesProcess.isRunning === true"
            (click)="cancel()"
            mat-raised-button
            color="warn">Cancel</button>
    </div>
</form>