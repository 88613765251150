import { AfterViewInit, Component, EventEmitter, HostBinding, Injector, Input, OnChanges, Output, SimpleChanges, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlider, MatSliderChange } from '@angular/material/slider';
import { SettingsCountingMenuBaseComponent } from '@rift/components/settings/counting/base/Settings.Counting.MenuBase.Component';
import { Device } from '@rift/components/shared/viewport/devices/Device';
import { DeviceCollection } from '@rift/components/shared/viewport/devices/DeviceCollection';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { DeviceLensTypeEnumHelpers } from '@shared/enum/DeviceLensType.Enum';
import { EventsService } from '@shared/service/events/Events.Service';
import { IPosition } from 'angular2-draggable';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { ISize } from 'angular2-draggable/lib/models/size';
import { UnitsOfMeasurementService } from '@rift/service/unitsofmeasurement/UnitsOfMeasurement.Service';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';
import { UnitOfMeasurementEnum } from '@shared/enum/UnitOfMeasurement.Enum';

export class DeviceViewModel {
    public device: DeviceModel;

    public constructor(private readonly _device: DeviceModel) {
        this.device = _device;
    }
}

export class AlignmentAlphaChanged {
    public device: Device;
    public alpha: number;
}

export class XPositionChanged {
    public device: Device;
    public x: number;
}

export class YPositionChanged {
    public device: Device;
    public y: number;
}

export class YawChanged {
    public device: Device;
    public yaw: number;
}

@Component({
    selector: 'rift-multi-unit-alignment-device-list',
    templateUrl: './Settings.Counting.Alignment.DeviceList.Component.html',
    styleUrls: ['./Settings.Counting.Alignment.DeviceList.Component.scss'],
})
export class SettingsCountingAlignmentDeviceListComponent extends SettingsCountingMenuBaseComponent implements AfterViewInit, OnChanges {
    public static className: string = 'SettingsCountingAlignmentDeviceListComponent';

    @HostBinding()
    public id: string = 'rift-multi-unit-alignment-device-list';

    public deviceVMs: Array<DeviceViewModel> = null;
    public devicesListHeight: number = 0;

    @Input()
    public get bounds(): HTMLElement {
        return this._bounds;
    }
    public set bounds(value: HTMLElement) {
        this._bounds = value;
        this.checkPosition();
    }

    @Input()
    public get zIndex(): number {
        return this._zIndex;
    }
    public set zIndex(value: number) {
        this._zIndex = value;
    }

    @Input()
    public devices: DeviceCollection = null;

    @Output()
    public toggleVisibility: EventEmitter<Device> = new EventEmitter<Device>();

    @Output()
    public alphaChanged: EventEmitter<AlignmentAlphaChanged> = new EventEmitter<AlignmentAlphaChanged>();

    @Output()
    public xPosChanged: EventEmitter<XPositionChanged> = new EventEmitter<XPositionChanged>();

    @Output()
    public yPosChanged: EventEmitter<YPositionChanged> = new EventEmitter<YPositionChanged>();

    @Output()
    public yawChanged: EventEmitter<YawChanged> = new EventEmitter<YawChanged>();

    @Output()
    public deviceSelected: EventEmitter<Device> = new EventEmitter<Device>();

    @Output()
    public exitAlignment: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public saveChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public separateDevices: EventEmitter<boolean> = new EventEmitter<boolean>();

    @LocalStorage(SettingsCountingAlignmentDeviceListComponent.className, 'position')
    public position: IPosition;

    public show: boolean;

    @LocalStorage(SettingsCountingAlignmentDeviceListComponent.className, 'size')
    public size: ISize;

    public constructor(
        private readonly _render: Renderer2,
        private readonly _eventsService: EventsService,
        private readonly _dialog: MatDialog,
        private readonly _unitsOfMeasurementService: UnitsOfMeasurementService,
        private readonly _injector: Injector) {
        super(_render, _injector, _dialog);

        this.minHeight = 380;
        this.minWidth = 380;
        this.maxWidth = 385;
        this.setDevicesListHeight({height: this.minHeight, width: this.minWidth});

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingAlignmentDeviceListComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsCountingAlignmentDeviceListComponent.className, this.saveAllChangesProcessText);

        this.initConnectionState();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (!this.isNullOrUndefined(this.size)) {
            this.setDevicesListHeight(this.size);
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.devices) && !this.isNullOrUndefined(changes.devices.currentValue)) {
            this.deviceVMs = changes.devices.currentValue.map(i => {
                const vm = new DeviceViewModel(i);
                return vm;
            });
        }
    }

    public onRzResizing(event: IResizeEvent): void {
        super.onRzResizing(event);
        this.setDevicesListHeight(event.size);
    }

    public onRzStop(event: IResizeEvent): void {
        super.onRzStop(event);
        this.setDevicesListHeight(event.size);
    }

    public onShowHideDeviceClick(vm: Device, e: MouseEvent): void {
        this.toggleVisibility.emit(vm);

        e.stopPropagation();
    }

    public onAlphaSliderChange(vm: Device, e: MatSliderChange): void {
        this.alphaChanged.emit({device: vm, alpha: e.value});
    }

    public onXPosChange(vm: Device, e: Event): void {
        const inputElement = e.target as HTMLInputElement;

        let xVal = parseFloat(inputElement.value);

        if (this.unitsOfMeasurementService.units === UnitsOfMeasurementEnum.imperial) {
            xVal = this.unitsOfMeasurementService.convertToMetric(xVal, UnitOfMeasurementEnum.centimeter, UnitOfMeasurementEnum.inch);
        }
        else{
            xVal = Math.ceil(xVal);
        }

        this.xPosChanged.emit({device: vm, x: xVal});
    }

    public onYPosChange(vm: Device, e: Event): void {
        const inputElement = e.target as HTMLInputElement;

        let yVal = parseFloat(inputElement.value);

        if (this.unitsOfMeasurementService.units === UnitsOfMeasurementEnum.imperial) {
            yVal = this.unitsOfMeasurementService.convertToMetric(yVal, UnitOfMeasurementEnum.centimeter, UnitOfMeasurementEnum.inch);
        }
        else{
            yVal = Math.ceil(yVal);
        }

        this.yPosChanged.emit({device: vm, y: yVal});
    }

    public onYawChange(vm: Device, e: Event): void {
        const inputElement = e.target as HTMLInputElement;
        let yawDeg = parseFloat(inputElement.value);

        if(yawDeg > 359.9){
            yawDeg = 0;
        }

        if(yawDeg < 0){
            yawDeg = 359.9;
        }

        const yawRad = this.degreeToRad(yawDeg);

        this.yawChanged.emit({device: vm, yaw: yawRad});
    }

    public onExit(): void{
        this.exitAlignment.emit(true);
    }

    public onSave(): void{
        this.saveChanges.emit(true);
    }

    public onSeparateDevices(): void{
        this.separateDevices.emit(true);
    }

    public radToDegree(rad: number): number{
        return parseFloat((rad * (180 / Math.PI)).toFixed(1));
    }

    public degreeToRad(degree: number): number{
        return (degree * (Math.PI / 180));
    }

    public onDeviceSelected(device: Device): void{
        this.deviceSelected.emit(device);
    }

    public uomAdjustToCurrentUOM(val: number): number{
        if (this.unitsOfMeasurementService.units === UnitsOfMeasurementEnum.imperial){
            return this.unitsOfMeasurementService.convertToImperial(val, UnitOfMeasurementEnum.centimeter, UnitOfMeasurementEnum.inch, 2);
        }

        // By default everything is stored and measured in cm so the input value
        // will be cm so just return cm if not imperial
        return val;
    }

    private setDevicesListHeight(size: ISize): void {
        this.devicesListHeight = size.height - 73;
    }
}
