<div class="em-address-book-tree"
    fxFlex>
    <mat-tree [dataSource]="nestedDataSource"
        [treeControl]="nestedTreeControl"
        class="tree">
        <mat-tree-node [id]="'tree-node-' + group.uniqueId"
            class="mat-tree-node node"
            *matTreeNodeDef="let group"
            matTreeNodeToggle>
            <li ng-option-tmp
                class="mat-tree-node node"
                (click)="onItemClicked(group)"
                [class.haschanges]="group.hasChanges"
                [class.selected]="group.uniqueId === selectedGroup?.uniqueId"
                [class.disabled]="disabledEnabled === true && group.disabled === true"
                [class.grayed]="grayedEnabled === true && group.grayed === true">
                <button mat-icon-button
                    disabled></button>
                <span
                    *ngIf="mode === 'view' ||  inlineNameEditing === false || selectedGroup?.uniqueId !== group.uniqueId || (selectedGroup?.uniqueId === group.uniqueId && isEditingName === false)"
                    [matBadgeHidden]="matBadgeHidden(group)"
                    [matBadge]="group.hasErrors === true ? group.devicesInErrorCount : group.devicesWithWarningsCount"
                    [matBadgeColor]="group.hasErrors === true ? 'warn' : 'accent'"
                    matBadgeSize="small"
                    matBadgeOverlap="false"
                    class="tree-text">{{group.name}} {{ group.hasChanges ? '*' : '' }}</span>
                <mat-form-field
                    *ngIf="selectedGroup?.uniqueId === group.uniqueId && inlineNameEditing === true && isEditingName === true && mode === 'edit'">
                    <input matInput
                        type="text"
                        [(ngModel)]="group.name">
                </mat-form-field>
                <button matTooltip="Edit Name"
                    *ngIf="selectedGroup?.uniqueId === group.uniqueId && group.noEdit === false && inlineNameEditing === true && isEditingName === false && mode === 'edit'"
                    (click)="editName(group)"
                    mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
                <span *ngIf="!isEmptyOrWhiteSpace(group.treeMessage) && selectedGroup?.uniqueId === group.uniqueId">&nbsp;{{group.treeMessage}}</span>
                <span *ngIf="!isEmptyOrWhiteSpace(group.treeError) && selectedGroup?.uniqueId === group.uniqueId"
                    class="error">&nbsp;{{group.treeError}}</span>
                <button matTooltip="Complete"
                    *ngIf="selectedGroup?.uniqueId === group.uniqueId && isEditingName === true && mode === 'edit'"
                    (click)="editNameComplete(group)"
                    mat-icon-button>
                    <mat-icon>done</mat-icon>
                </button>
                <div fxFlex></div>
                <button matTooltip="Edit"
                    *ngIf="selectedGroup?.uniqueId === group.uniqueId && group.noEdit === false && group.isRoot === false && showItemEditIcon === true && mode === 'edit'"
                    (click)="edit(group)"
                    mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
                <button matTooltip="Add Child"
                    *ngIf="selectedGroup?.uniqueId === group.uniqueId && (group.noEdit === false || group.isRoot === true) && mode === 'edit' && getDepth(group) < 6"
                    (click)="addChild(group)"
                    mat-icon-button>
                    <mat-icon>add</mat-icon>
                </button>
                <button matTooltip="Move"
                    *ngIf="selectedGroup?.uniqueId === group.uniqueId && group.noEdit === false && group.isRoot === false && mode === 'edit'"
                    (click)="move(group)"
                    mat-icon-button>
                    <mat-icon>unfold_more</mat-icon>
                </button>
                <button matTooltip="Move Up"
                    [disabled]="group.order === 1"
                    *ngIf="selectedGroup?.uniqueId === group.uniqueId && group.noEdit === false && group.isRoot === false && mode === 'edit'"
                    (click)="moveUp(group)"
                    mat-icon-button>
                    <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
                <button matTooltip="Move Down"
                    [disabled]="group.order === (group.parent?.children?.length)"
                    *ngIf="selectedGroup?.uniqueId === group.uniqueId && group.noEdit === false && group.isRoot === false && mode === 'edit'"
                    (click)="moveDown(group)"
                    mat-icon-button>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <button matTooltip="Delete"
                    *ngIf="selectedGroup?.uniqueId === group.uniqueId && group.noEdit === false && group.isRoot === false && mode === 'edit'"
                    (click)="delete(group)"
                    color="warn"
                    mat-icon-button>
                    <mat-icon>delete</mat-icon>
                </button>
            </li>
        </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let group; when: hasNestedChild">
            <li>
                <div ng-option-tmp
                    [id]="'tree-node-' + group.uniqueId"
                    class="mat-tree-node node"
                    (click)="onItemClicked(group)"
                    [class.haschanges]="group.hasChanges"
                    [class.selected]="group.uniqueId === selectedGroup?.uniqueId"
                    [class.disabled]="disabledEnabled === true && group.disabled === true"
                    [class.grayed]="grayedEnabled === true && group.grayed === true">
                    <button mat-icon-button
                        matTreeNodeToggle>
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{nestedTreeControl.isExpanded(group) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <span
                        *ngIf="mode === 'view' || inlineNameEditing === false || selectedGroup?.uniqueId !== group.uniqueId || (selectedGroup?.uniqueId === group.uniqueId && isEditingName === false)"
                        [matBadgeHidden]="matBadgeHidden(group)"
                        matBadge="{{group.hasErrors === true ? group.devicesInErrorCount : group.devicesWithWarningsCount}}"
                        [matBadgeColor]="group.hasErrors === true ? 'warn' : 'accent'"
                        matBadgeSize="small"
                        matBadgeOverlap="false"
                        class="tree-text">{{group.name}} {{ group.hasChanges ? '*' : '' }}</span>
                    <mat-form-field
                        *ngIf="selectedGroup?.uniqueId === group.uniqueId  && inlineNameEditing === true && isEditingName === true && mode === 'edit'">
                        <input matInput
                            type="text"
                            [(ngModel)]="group.name">
                    </mat-form-field>
                    <button matTooltip="Edit Name"
                        *ngIf="selectedGroup?.uniqueId === group.uniqueId && group.noEdit === false && inlineNameEditing === true && isEditingName === false && mode === 'edit'"
                        (click)="editName(group)"
                        mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>
                    <span *ngIf="!isEmptyOrWhiteSpace(group.treeMessage) && selectedGroup?.uniqueId === group.uniqueId">&nbsp;{{group.treeMessage}}</span>
                    <span *ngIf="!isEmptyOrWhiteSpace(group.treeError) && selectedGroup?.uniqueId === group.uniqueId"
                        class="error">&nbsp;{{group.treeError}}</span>
                    <button matTooltip="Complete"
                        *ngIf="selectedGroup?.uniqueId === group.uniqueId && isEditingName === true && mode === 'edit'"
                        (click)="editNameComplete(group)"
                        mat-icon-button>
                        <mat-icon>done</mat-icon>
                    </button>
                    <div fxFlex></div>
                    <button matTooltip="Edit"
                        *ngIf="selectedGroup?.uniqueId === group.uniqueId && group.noEdit === false && group.isRoot === false && showItemEditIcon === true && mode === 'edit'"
                        (click)="edit(group)"
                        mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button matTooltip="Add Child"
                        *ngIf="selectedGroup?.uniqueId === group.uniqueId && (group.noEdit === false || group.isRoot === true) && mode === 'edit' && getDepth(group) < 6"
                        (click)="addChild(group)"
                        mat-icon-button>
                        <mat-icon>add</mat-icon>
                    </button>
                    <button matTooltip="Move"
                        *ngIf="selectedGroup?.uniqueId === group.uniqueId && group.noEdit === false && group.isRoot === false && mode === 'edit'"
                        (click)="move(group)"
                        mat-icon-button>
                        <mat-icon>unfold_more</mat-icon>
                    </button>
                    <button matTooltip="Mone Up"
                        [disabled]="group.order === 1"
                        *ngIf="selectedGroup?.uniqueId === group.uniqueId && group.noEdit === false && group.isRoot === false && mode === 'edit'"
                        (click)="moveUp(group)"
                        mat-icon-button>
                        <mat-icon>keyboard_arrow_up</mat-icon>
                    </button>
                    <button matTooltip="Move Down"
                        [disabled]="group.order === (group.parent?.children?.length)"
                        *ngIf="selectedGroup?.uniqueId === group.uniqueId && group.noEdit === false && group.isRoot === false && mode === 'edit'"
                        (click)="moveDown(group)"
                        mat-icon-button>
                        <mat-icon>keyboard_arrow_down </mat-icon>
                    </button>
                    <button matTooltip="Delete"
                        *ngIf="selectedGroup?.uniqueId === group.uniqueId && group.noEdit === false && group.isRoot === false && mode === 'edit'"
                        (click)="delete(group)"
                        color="warn"
                        mat-icon-button>
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <ul [class.tree-invisible]="!nestedTreeControl.isExpanded(group)">
                    <ng-container matTreeNodeOutlet></ng-container>
                </ul>
            </li>
        </mat-nested-tree-node>
    </mat-tree>
</div>