<div class="em-settings-options-notifications"
    fxLayout="column">    
    <form [formGroup]="notificationsFormGroup">
        <mat-card class="header">
            <mat-card-title>
                <span class="mat-title">SMTP</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span class="mat-subheading-1">SMTP server configuration. The SMTP server will be used for sending email notifications from the notification
                    service.
                </span>
            </mat-card-subtitle>
            <mat-card-content>
                <div>
                    <mat-form-field style="width: 400px; margin-right: 10px;">
                        <input type="text"
                            #smtpHost
                            matInput
                            formControlName="smtpHost"
                            placeholder="Host">
                        <span matSuffix>{{ settingSmtpHost?.propertyHasChangesText('value') }}</span>
                        <mat-error>{{getFormControlErrorMessage(notificationsFormGroup.controls['smtpHost'])}}</mat-error>
                    </mat-form-field>
                    <mat-form-field style="width: 70px;">
                        <input type="number"
                            #smtpPort
                            matInput
                            formControlName="smtpPort"
                            placeholder="Port">
                        <span matSuffix>{{ settingSmtpPort?.propertyHasChangesText('value') }}</span>
                        <mat-error>{{getFormControlErrorMessage(notificationsFormGroup.controls['smtpPort'])}}</mat-error>
                    </mat-form-field>

                </div>
                <div>
                    <mat-form-field style="width: 400px;">
                        <input type="text"
                            #smtpFrom
                            matInput
                            formControlName="smtpFrom"
                            placeholder="From Address">
                        <span matSuffix>{{ settingSmtpFrom?.propertyHasChangesText('value') }}</span>
                        <mat-error>{{getFormControlErrorMessage(notificationsFormGroup.controls['smtpFrom'])}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field style="width: 400px; margin-right: 10px;">
                        <input type="text"
                            #smtpUserName
                            matInput
                            formControlName="smtpUserName"
                            placeholder="User Name">
                        <span matSuffix>{{ settingSmtpUserName?.propertyHasChangesText('value') }}</span>
                        <mat-error>{{getFormControlErrorMessage(notificationsFormGroup.controls['smtpUserName'])}}</mat-error>
                    </mat-form-field>
                    <mat-form-field style="width: 400px;">
                        <input type="password"
                            #smtpPassword
                            matInput
                            formControlName="smtpPassword"
                            placeholder="Password">
                        <span matSuffix>{{ settingSmtpPassword?.propertyHasChangesText('value') }}</span>
                        <mat-error>{{getFormControlErrorMessage(notificationsFormGroup.controls['smtpPassword'])}}</mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>
    </form>
</div>