import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface IConnectionErrorModel {
    details: string;
    expDetails: string;
}

export class ConnectionErrorModel extends BaseModel implements IConnectionErrorModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'connection_error';

    public isIWebSocketModel: boolean = true;
    public packetType: string = null;
    public id: number = null;

    public details: string = null;
    public expDetails: string = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-ConnectionErrorModel');
    }

    public toWebSocketMessage(): any {
        return {
            ...WebSocketModelUtility.toJson(this),
        };
    }
}
