import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionOptionsModel } from '@rift/models/restapi/ConnectionOptions.Model';
import { ConnectionRequestModel } from '@rift/models/restapi/ConnectionRequest.Model';
import { ConnectionTokenModel } from '@rift/models/restapi/ConnectionToken.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RestApiConnectionService extends RiftRestApiService {
    private _controller = 'connection';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public connectToDevice(options: ConnectionRequestModel, process?: ProcessMonitorServiceProcess): Observable<ConnectionTokenModel> {
        return this.post<ConnectionRequestModel, ConnectionTokenModel>(`connection`, options, ConnectionTokenModel, null, process, { disabled: true }).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getConnectionOptions(connectionProviderToken: string, process?: ProcessMonitorServiceProcess): Observable<ConnectionOptionsModel> {
        let params = new HttpParams();
        params = params.append('connectionProviderToken', connectionProviderToken);

        return this.get<ConnectionOptionsModel>(`${this._controller}/options`, ConnectionOptionsModel, params, process, { disabled: true }).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public goOffline(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.get<ResultModel>(`${this._controller}/go_offline`, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public goOnline(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.get<ResultModel>(`${this._controller}/go_online`, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
