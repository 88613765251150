import { Component, Inject, Injector, OnInit, HostBinding } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoutingDestinationModel } from '@em/models/restapi/RoutingDestination.Model';
import { RoutingRuleModel } from '@em/models/restapi/RoutingRule.Model';
import { RoutingRuleCollectionModel } from '@em/models/restapi/RoutingRuleCollection.Model';
import { UserService } from '@em/service/data/user/User.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class SettingsConnectionForwardingAddEditData {
    public static className: string = 'SettingsConnectionForwardingAddEditData';

    public constructor(public readonly rule: RoutingRuleModel, public readonly routingRules: RoutingRuleCollectionModel) { }
}

export class SettingsConnectionForwardingAddEditResult {
    public static className: string = 'SettingsConnectionForwardingAddEditResult';

    public constructor(public readonly rule?: RoutingRuleModel) { }
}

@Component({
    selector: 'em-settings-connection-forwarding-add-edit',
    templateUrl: './Settings.ConnectionForwarding.AddEdit.Component.html',
    styleUrls: ['./Settings.ConnectionForwarding.AddEdit.Component.scss']
})
export class SettingsConnectionForwardingAddEditComponent extends BaseComponent implements OnInit {
    public static className: string = 'SettingsConnectionForwardingAddEditComponent';

    public rule: RoutingRuleModel;
    public routingRules: RoutingRuleCollectionModel;
    public formGroup: FormGroup;
    public formGroupValueChangesProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'em-settings-connection-forwarding-add-edit';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _userService: UserService,
        private readonly _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private readonly _data: SettingsConnectionForwardingAddEditData,
        private readonly _dialogRef: MatDialogRef<SettingsConnectionForwardingAddEditComponent>,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.rule = this._data.rule;
        this.changeTracker.track(this.rule);
        this.routingRules = this._data.routingRules;

        this.formGroupValueChangesProcess = this.processMonitorService.getProcess(SettingsConnectionForwardingAddEditComponent.className, 'Form group values changed');

        this.formGroup = this._formBuilder.group({
            groupId: ['', Validators.compose([])],
            friendlySerialNumber: ['', Validators.compose([])],
            retryPeriod: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(9999)])],
            destinations: this._formBuilder.array([]),
        });
        this.formGroupTracker.track(this.formGroup);

        this.setOperateOnValidators();
        if (this.rule.id === -1) {
            this.addDestinationToRule();
        }
        this.setFormGroupValue();

        this.addSubscription(this.observableHandlerBase(this.formGroup.valueChanges, this.formGroupValueChangesProcess).subscribe(() => {
            this.updateRuleModelFromForm();
        }), this.formGroupValueChangesProcess);
    }

    public close(): void {
        this._dialogRef.close(new SettingsConnectionForwardingAddEditResult(this.rule));
    }

    public cancel(): void {
        this._dialogRef.close(new SettingsConnectionForwardingAddEditResult());
    }

    public addDestinationToRule(): RoutingDestinationModel {
        const destination = new RoutingDestinationModel();
        destination.id = -1;
        this.rule.destinations.push(destination);
        return destination;
    }

    public addDestination(): void {
        this.addDestinationFormGroup(this.addDestinationToRule());
    }

    public deleteDestination(destination: RoutingDestinationModel): void {
        const index = this.rule.destinations.findIndex(i => i.uniqueId === destination.uniqueId);
        if (index !== -1) {
            this.rule.destinations.splice(index, 1);
            this.removeDestinationFormGroup(index);
        }
    }

    public createDestinationFormGroup(): FormGroup {
        return this._formBuilder.group({
            iPAddress: ['', Validators.compose([Validators.required, Validators.maxLength(15), ValidationValidators.ipAddress])],
            port: ['', Validators.compose([Validators.required, ValidationValidators.port])],
        });
    }

    public removeDestinationFormGroup(index: number, formArray?: FormArray): void {
        const fArray = this.isNullOrUndefined(formArray) ? this.formGroup.controls.destinations as FormArray : formArray;

        if (!this.isNullOrUndefined(fArray)) {
            fArray.removeAt(index);
        }
    }

    public updateRuleModelFromForm(): void {
        const values = this.formGroup.value;
        const formArray = this.formGroup.controls.destinations as FormArray;

        if (!this.isNullOrUndefined(formArray)) {
            const length = this.rule.destinations.length;
            for (let i = 0; i < length; i++) {
                this.updateDestinationModel(this.rule.destinations[i], i, formArray);
            }
        }

        this.rule.friendlySerialNumber = values.friendlySerialNumber;
        this.rule.groupId = values.groupId;
        this.rule.retryPeriod = values.retryPeriod;
    }

    public updateDestinationModel(destination: RoutingDestinationModel, index: number, formArray?: FormArray): void {
        const fArray = this.isNullOrUndefined(formArray) ? this.formGroup.controls.destinations as FormArray : formArray;
        const formModel = fArray.value[index];
        if (!this.isNullOrUndefined(formModel)) {
            destination.iPAddress = formModel.iPAddress;
            destination.port = formModel.port;
        }
    }

    public setOperateOnValidators(): void {
        if (this.rule.isGlobalRule) {
            this.formGroup.controls.groupId.setValidators(Validators.compose([]));
            this.formGroup.controls.friendlySerialNumber.setValidators(Validators.compose([]));
        } else if (this.rule.isDeviceRule) {
            this.formGroup.controls.groupId.setValidators(Validators.compose([]));
            this.formGroup.controls.friendlySerialNumber.setValidators(Validators.compose([Validators.required, ValidationValidators.unique(() => this.getUsedFriendlySerialNumbers())]));
        } else if (this.rule.isGroupRule) {
            this.formGroup.controls.groupId.setValidators(Validators.compose([Validators.required, ValidationValidators.unique(() => this.getUsedGroupIds())]));
            this.formGroup.controls.friendlySerialNumber.setValidators(Validators.compose([]));
        }
    }

    public getUsedFriendlySerialNumbers(): Array<string> {
        const items: Array<string> = [];
        const length = this.routingRules.items.length;

        for (let index = 0; index < length; index++) {
            const item = this.routingRules.items[index];
            if (item.isDeviceRule === true && item.uniqueId !== this.rule.uniqueId) {
                items.push(item.friendlySerialNumber);
            }
        }

        return items;
    }

    public getUsedGroupIds(): Array<number> {
        const items: Array<number> = [];
        const length = this.routingRules.items.length;

        for (let index = 0; index < length; index++) {
            const item = this.routingRules.items[index];
            if (item.isGroupRule === true && item.uniqueId !== this.rule.uniqueId) {
                items.push(item.groupId);
            }
        }

        return items;
    }

    public setFormGroupValue(): void {
        const destinationsArray = this.formGroup.controls.destinations as FormArray;
        if (!this.isNullOrUndefined(destinationsArray)) {
            const length = this.rule.destinations.length;
            if (length > 0) {
                for (let i = 0; i < length; i++) {
                    this.addDestinationFormGroup(this.rule.destinations[i], destinationsArray);
                }
            } else {
                destinationsArray.push(this.createDestinationFormGroup());
            }
        }

        if (!this.isNullOrUndefined(this.rule.retryPeriod)) {
            this.formGroup.controls.retryPeriod.setValue(this.rule.retryPeriod);
        }

        if (!this.isNullOrUndefined(this.rule.groupId)) {
            this.formGroup.controls.groupId.setValue(this.rule.groupId);
        }

        if (!this.isNullOrUndefined(this.rule.friendlySerialNumber)) {
            this.formGroup.controls.friendlySerialNumber.setValue(this.rule.friendlySerialNumber);
        }
    }

    public addDestinationFormGroup(destination: RoutingDestinationModel, formArray?: FormArray): void {
        const fArray = this.isNullOrUndefined(formArray) ? this.formGroup.controls.destinations as FormArray : formArray;
        if (!this.isNullOrUndefined(fArray)) {
            const form = this.createDestinationFormGroup();
            form.setValue({
                iPAddress: destination.iPAddress,
                port: destination.port,
            });
            fArray.push(form);
        }
    }
}
