import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Injector,
    Input,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import {
    SettingsCountingAssociateRegistersComponent,
} from '@rift/components/settings/counting/shared/settings/associateregisters/Settings.Counting.AssociateRegisters.Component';
import {
    SettingsCountingCountModeComponent,
} from '@rift/components/settings/counting/shared/settings/countmode/Settings.Counting.CountMode.Component';
import {
    SettingsCountingCustomActionComponent,
} from '@rift/components/settings/counting/shared/settings/customaction/Settings.Counting.CustomAction.Component';
import {
    SettingsCountingDwellHistogramComponent,
} from '@rift/components/settings/counting/shared/settings/dwellhistogram/Settings.Counting.DwellHistogram.Component';
import {
    SettingsCountingExclusionTypeComponent,
} from '@rift/components/settings/counting/shared/settings/exclusiontype/Settings.Counting.ExclusionType.Component';
import {
    SettingsCountingHeightFilteringComponent,
} from '@rift/components/settings/counting/shared/settings/heightfiltering/Settings.Counting.HeightFiltering.Component';
import {
    SettingsCountingHeightHistogramComponent,
} from '@rift/components/settings/counting/shared/settings/heighthistogram/Settings.Counting.HeightHistogram.Component';
import {
    SettingsCountingInstantDwellHistogramComponent,
} from '@rift/components/settings/counting/shared/settings/instantdwellhistogram/Settings.Counting.InstantDwellHistogram.Component';
import {
    SettingsCountingRemotePushComponent,
} from '@rift/components/settings/counting/shared/settings/remote-push/Settings.Counting.RemotePush.Component';
import {
    SettingsCountingRemoteComponent,
} from '@rift/components/settings/counting/shared/settings/remote/Settings.Counting.Remote.Component';
import {
    SettingsCountingSimplePropertyComponent,
} from '@rift/components/settings/counting/shared/settings/simpleproperty/Settings.Counting.SimpleProperty.Component';
import {
    SettingsCountingStaffDetectComponent,
} from '@rift/components/settings/counting/shared/settings/staffdetection/Settings.Counting.StaffDetect.Component';
import {
    SettingsCountingUnattendedTimeHistogramComponent,
} from '@rift/components/settings/counting/shared/settings/unattendedtimehistogram/Settings.Counting.UnattendedTimeHistogram.Component';
import {
    SettingsCountingZoneComponent,
} from '@rift/components/settings/counting/shared/settings/zone/Settings.Counting.Zone.Component';
import { AssociatedRegisterViewModel } from '@rift/components/settings/counting/viewmodels/AssociatedRegister.ViewModel';
import { RegisterTypeViewModel } from '@rift/components/settings/counting/viewmodels/RegisterType.ViewModel';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { PolygonModel } from '@rift/models/restapi/Polygon.Model';
import { RegisterActionInfoCollectionModel } from '@rift/models/restapi/RegisterActionInfoCollection.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { CountModeEnumHelpers } from '@shared/enum/CountMode.Enum';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { MarkedTargetEnumHelpers } from '@shared/enum/MarkedTarget.Enum';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { ZoneTypeEnumHelpers } from '@shared/enum/ZoneType.Enum';
import { SettingsCountingMaxDwellComponent } from './maxdwell/Settings.Counting.MaxDwell.Component';

import { SettingsCountingTagsComponent } from './tags/Settings.Counting.Tags.Component';
import { SettingsWirelessPIRDevicesComponent } from './wireless-pir-devices/Settings.Counting.WirelessPIRDevices.Component';

@Component({
    selector: 'rift-settings-counting-register-settings',
    templateUrl: './Settings.Counting.RegisterSettings.Component.html',
    styleUrls: ['./Settings.Counting.RegisterSettings.Component.scss']
})
export class SettingsCountingRegisterSettingsComponent extends RiftBaseComponent {
    public static className: string = 'SettingsCountingRegisterSettingsComponent';

    public readonly CountModeEnumHelpers = CountModeEnumHelpers;
    public readonly MarkedTargetEnumHelpers = MarkedTargetEnumHelpers;
    public readonly ZoneTypeEnumHelpers = ZoneTypeEnumHelpers;
    public readonly RegisterTypeEnum = RegisterTypeEnum;

    @HostBinding()
    public id: string = 'rift-settings-counting-register-settings';

    @Input()
    public lines: Array<LineModel> = null;

    @Input()
    public polygons: Array<PolygonModel> = null;

    @Input()
    public register: RegisterBaseModel = null;

    @Input()
    public registerTypeViewModel: RegisterTypeViewModel = null;

    @Input()
    public registers: Array<RegisterBaseModel> = null;

    @Output()
    public associateSelectedChange: EventEmitter<Array<AssociatedRegisterViewModel>> = new EventEmitter<Array<AssociatedRegisterViewModel>>();

    @ViewChildren('associate')
    public associates: QueryList<SettingsCountingAssociateRegistersComponent>;

    @ViewChild('countMode', { static: false })
    public countMode: SettingsCountingCountModeComponent;

    @ViewChild('exclusionType', { static: false })
    public exclusionType: SettingsCountingExclusionTypeComponent;

    @ViewChild('dwellHistogram', { static: false })
    public dwellHistogram: SettingsCountingDwellHistogramComponent;

    @ViewChild('heightFiltering', { static: false })
    public heightFiltering: SettingsCountingHeightFilteringComponent;

    @ViewChild('heightHistogram', { static: false })
    public heightHistogram: SettingsCountingHeightHistogramComponent;

    @ViewChild('instantDwellHistogram', { static: false })
    public instantDwellHistogram: SettingsCountingInstantDwellHistogramComponent;

    @ViewChild('staffDetection', { static: false })
    public staffDetection: SettingsCountingStaffDetectComponent;

    @ViewChild('unattendedTimeHistogram', { static: false })
    public unattendedTimeHistogram: SettingsCountingUnattendedTimeHistogramComponent;

    @ViewChild('tags', { static: false })
    public tags: SettingsCountingTagsComponent;

    @ViewChild('zone', { static: false })
    public zone: SettingsCountingZoneComponent;

    @ViewChildren('simpleProperty')
    public simpleProperties: QueryList<SettingsCountingSimplePropertyComponent>;

    @ViewChild('customAction', { static: false })
    public customAction: SettingsCountingCustomActionComponent;

    @ViewChild('remote', { static: false })
    public remote: SettingsCountingRemoteComponent;

    @ViewChild('remotePush', { static: false })
    public remotePush: SettingsCountingRemotePushComponent;

    @ViewChild('wirelessPIR', { static: false })
    public wirelessPIR: SettingsWirelessPIRDevicesComponent;

    @ViewChild('maxDwell', { static: false })
    public maxDwell: SettingsCountingMaxDwellComponent;

    public availableCustomActions: RegisterActionInfoCollectionModel;

    public constructor(
        private readonly _deviceService: DeviceService,
        private readonly _registerService: RegisterService,
        private readonly _changeDetectionRef: ChangeDetectorRef,
        private readonly _injector: Injector) {
        super(_injector);

        this.addSubscription(
            this._deviceService.getHostDevice(this.connectionService.connectedToFriendlySerial).subscribe((devices) => {
                if (devices.isCapable(DeviceCapabilitiesEnum.registerCustomActions)) {
                    this._registerService.getActions().subscribe(actions => {
                        this.availableCustomActions = actions;
                    });
                }
            })
        );
    }

    public get invalid(): boolean {
        return !this.isValid;
    }

    public get isValid(): boolean {
        this._changeDetectionRef.detectChanges();
        return (this.isNullOrUndefined(this.countMode) || this.countMode.isValid === true) &&
            (this.isNullOrUndefined(this.associates) || this.associates.length === 0 || !this.associates.some(associate => associate.isValid === false)) &&
            (this.isNullOrUndefined(this.exclusionType) || this.exclusionType.isValid === true) &&
            (this.isNullOrUndefined(this.heightFiltering) || this.heightFiltering.isValid === true) &&
            (this.isNullOrUndefined(this.staffDetection) || this.staffDetection.isValid === true) &&
            (this.isNullOrUndefined(this.zone) || this.zone.isValid === true) &&
            (this.isNullOrUndefined(this.heightHistogram) || this.heightHistogram.isValid === true) &&
            (this.isNullOrUndefined(this.dwellHistogram) || this.dwellHistogram.isValid === true) &&
            (this.isNullOrUndefined(this.unattendedTimeHistogram) || this.unattendedTimeHistogram.isValid === true) &&
            (this.isNullOrUndefined(this.instantDwellHistogram) || this.instantDwellHistogram.isValid === true) &&
            (this.isNullOrUndefined(this.customAction) || this.customAction.isValid === true) &&
            (this.isNullOrUndefined(this.remote) || this.remote.isValid === true) &&
            (this.isNullOrUndefined(this.remotePush) || this.remotePush.isValid === true) &&
            (this.isNullOrUndefined(this.wirelessPIR) || this.wirelessPIR.isValid === true) &&
            (this.isNullOrUndefined(this.maxDwell) || this.maxDwell.isValid === true) &&
            (this.isNullOrUndefined(this.simpleProperties) || this.allSimplePropertiesValid());
    }

    public get valid(): boolean {
        return this.isValid;
    }

    public onAssociateSelectedChange(selected: Array<AssociatedRegisterViewModel>): void {
        this.associateSelectedChange.next(selected);
    }

    private allSimplePropertiesValid(): boolean {
        let isValid = false;
        if (!this.isNullOrUndefined(this.simpleProperties)) {
            isValid = !this.simpleProperties.some(sp => sp.isValid === false);
        }
        return isValid;
    }
}
