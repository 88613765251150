import { BaseTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, HostBinding, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { BaseComponent } from '@shared/base/Base.Component';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { TreeUtility } from '@shared/utility/Tree.Utility';
import { Observable, of, timer } from 'rxjs';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { MatTreeNestedDataSource, MatTree, MatNestedTreeNode } from '@angular/material/tree';
import { ThemePalette } from '@angular/material/core';

let componentId = 0;

@Component({
    selector: 'em-address-book-tree',
    templateUrl: './AddressBookTree.Component.html',
    styleUrls: ['./AddressBookTree.Component.scss']
})
export class AddressBookTreeComponent extends BaseComponent implements OnChanges {
    public static className: string = 'AddressBookTreeComponent';

    public nestedDataSource: MatTreeNestedDataSource<GroupModel>;
    public nestedTreeControl: NestedTreeControl<GroupModel>;
    public expandAllProcess: ProcessMonitorServiceProcess;
    public instanceId = componentId++;
    public isEditingName: boolean = false;

    @Output()
    public addChildClicked: EventEmitter<GroupModel> = new EventEmitter();

    @Output()
    public deleteClicked: EventEmitter<GroupModel> = new EventEmitter();

    @Output()
    public editNameClicked: EventEmitter<GroupModel> = new EventEmitter();

    @Output()
    public editNameCompleteClicked: EventEmitter<GroupModel> = new EventEmitter();

    @Output()
    public itemClicked: EventEmitter<GroupModel> = new EventEmitter();

    @Output()
    public moveClicked: EventEmitter<GroupModel> = new EventEmitter();

    @Output()
    public moveDownClicked: EventEmitter<GroupModel> = new EventEmitter();

    @Output()
    public moveUpClicked: EventEmitter<GroupModel> = new EventEmitter();

    @Output()
    public editItemClicked: EventEmitter<GroupModel> = new EventEmitter();

    @Input()
    public showItemEditIcon: boolean = true;

    @Input()
    public inlineNameEditing: boolean = true;

    @Input()
    public badgeHidden: boolean = false;

    @Input()
    public disabledEnabled: boolean = false;

    @Input()
    public grayedEnabled: boolean = false;

    @Input()
    public mode: 'edit' | 'view' = 'view';

    @Input()
    public root: GroupModel;

    @Input()
    public selectedGroup: GroupModel;

    @Input()
    public showRoot: boolean = false;

    @Input()
    public visableEnabled: boolean = false;

    @ViewChild(MatTree, { static: true })
    public tree: MatTree<GroupModel>;

    @ViewChildren(MatNestedTreeNode)
    public nested: MatNestedTreeNode<GroupModel>;

    @HostBinding()
    public id: string = 'em-address-book-tree-' + this.instanceId;

    public constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _injector: Injector) {
        super(_injector);

        this.expandAllProcess = this.processMonitorService.getProcess(AddressBookTreeComponent.className, 'Expand all');

        this.nestedTreeControl = new NestedTreeControl<GroupModel>(this.getChildren.bind(this));
        this.nestedDataSource = new MatTreeNestedDataSource();
    }

    public edit(group: GroupModel): void {
        this.editItemClicked.emit(group);
    }

    public hasNestedChild = (_: number, nodeData: GroupModel) => {
        let hasVisable = false;
        const length = nodeData.children.length;
        for (let index = 0; index < length; index++) {
            const item = nodeData.children[index];
            if (this.filterVisable(item)) {
                hasVisable = true;
                break;
            }
        }

        return !this.isNullOrUndefined(nodeData) && !this.isNullOrUndefined(nodeData.children) && hasVisable === true;
    };

    public addChild(group: GroupModel): void {
        this.addChildClicked.emit(group);
        this.nestedTreeControl.expand(group);
    }

    public delete(group: GroupModel): void {
        this.deleteClicked.emit(group);
    }

    public editName(group: GroupModel): void {
        this.editNameClicked.emit(group);
        this.isEditingName = true;
    }

    public editNameComplete(group: GroupModel): void {
        this.editNameCompleteClicked.emit(group);
        this.isEditingName = false;
    }

    public expandAll(): void {
        const subs = this.addSubscription(this.observableHandlerBase(timer(0, 100), this.expandAllProcess).subscribe(() => {
            if (!this.isNullOrUndefined(this.tree) && !this.isNullOrUndefined(this.tree.treeControl) && !this.isNullOrUndefined(this.root)) {
                TreeUtility.expandAll(this.tree.treeControl, this.root);
                if (!this.isNullOrUndefined(subs)) {
                    subs.unsubscribe();
                }
            }
        }), this.expandAllProcess);
    }

    public filterVisable(group: GroupModel): boolean {
        return this.visableEnabled === false || (this.visableEnabled === true && group.visable === true);
    }

    public getChildren(group: GroupModel): Observable<Array<GroupModel>> {
        const sorted = group.children.sort((a, b) => a.order - b.order);
        const items: GroupModel[] = [];
        const length = sorted.length;
        for (let index = 0; index < length; index++) {
            const item = sorted[index];
            if (this.filterVisable(item)) {
                items.push(item);
            }
        }

        return of(items);
    }

    public matBadgeHidden(group: GroupModel): boolean {
        return (this.badgeHidden === true || (this.badgeHidden === false && group.hasErrors === false && group.hasWarnings === false));
    }

    public move(group: GroupModel): void {
        this.moveClicked.emit(group);
    }

    public moveDown(group: GroupModel): void {
        this.moveDownClicked.emit(group);
    }

    public moveUp(group: GroupModel): void {
        this.moveUpClicked.emit(group);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.root) && !this.isNullOrUndefined(changes.root.currentValue)) {
            this.setDataSource(changes.root.currentValue);
        }
    }

    public onItemClicked(group: GroupModel): void {
        if (!isNullOrUndefined(this.selectedGroup) && this.isEditingName === true && this.selectedGroup.uniqueId !== group.uniqueId) {
            this.selectedGroup.name = this.selectedGroup.getPropertyOriginalValue('name');
            this.isEditingName = false;
        }

        this.itemClicked.emit(group);
    }

    public renderNodeChanges(): void {
        if (!this.isNullOrUndefined(this.tree)) {
            this.tree.renderNodeChanges([]);

            if (this.showRoot === false) {
                const items: GroupModel[] = [];
                const length = this.root.children.length;
                for (let index = 0; index < length; index++) {
                    const item = this.root.children[index];
                    if (this.filterVisable(item)) {
                        items.push(item);
                    }
                }
                this.tree.renderNodeChanges(items);
            } else {
                this.tree.renderNodeChanges([this.root]);
            }
        }
    }

    public getDepth(group: GroupModel, depth: number = 1): number {
        if (this.isNullOrUndefined(group.parent)) {
            return depth;
        }
        if (depth > 6) {
            return 6;
        } else {
            return this.getDepth(group.parent, depth + 1);
        }
    }

    public setDataSource(root: GroupModel): void {
        const newFlatGroups = ArrayUtility.flatten([], root);
        const newFlatGroupsLength = newFlatGroups.length;

        if (!this.isNullOrUndefined(this.nestedDataSource.data) && this.nestedDataSource.data.length > 0) {
            const oldFlatGroups = ArrayUtility.flatten([], this.nestedDataSource.data);
            for (let ni = 0; ni < newFlatGroupsLength; ni++) {
                const newGroup = newFlatGroups[ni];
                if (!this.isNullOrUndefined(newGroup)) {
                    const oldGroup = oldFlatGroups.find(o => o.id === newGroup.id);
                    if (!this.isNullOrUndefined(oldGroup)) {
                        newGroup.isExpanded = this.nestedTreeControl.isExpanded(oldGroup);
                    }
                }
            }
        }

        if (this.showRoot === false) {
            const data: GroupModel[] = [];
            const length = root.children.length;
            for (let index = 0; index < length; index++) {
                const item = root.children[index];
                if (this.filterVisable(item)) {
                    data.push(item);
                }
            }
            this.nestedDataSource.data = data;
        } else {
            this.nestedDataSource.data = [root];
        }

        if (!this.isNullOrUndefined(this.nestedDataSource.data) && this.nestedDataSource.data.length > 0) {
            for (let ni = 0; ni < newFlatGroupsLength; ni++) {
                const newGroup = newFlatGroups[ni];
                if (!this.isNullOrUndefined(newGroup)) {
                    if (newGroup.isExpanded) {
                        this.nestedTreeControl.expand(newGroup);
                    } else {
                        this.nestedTreeControl.collapse(newGroup);
                    }
                }
            }
        }
    }

    public detectChanges(): void {
        this._changeDetectorRef.detectChanges();
    }
}
