
/**
 * Device State.
 *
 * @export
 * @enum {number}
 */
export enum DeviceStateEnum {
    /**
     * he device is ok.
     */
    ok = 0,

    /**
     * The device has a warning.
     */
    warning = 1,

    /**
     * The device has an error.
     */
    error = 2,

    /**
     * Could not connect to the device.
     */
    couldNotConnect = 3,
}


export class DeviceStateEnumHelpers {
     static toDisplayName(value: DeviceStateEnum): string {
        switch (value) {
            case DeviceStateEnum.ok:
                return 'OK';
            case DeviceStateEnum.warning:
                return 'Warning';
            case DeviceStateEnum.error:
                return 'Error';
            case DeviceStateEnum.couldNotConnect:
                return 'Could not connect';
        }
    }
}
