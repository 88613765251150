import { AssociatedRegisterViewModel } from '@rift/components/settings/counting/viewmodels/AssociatedRegister.ViewModel';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { RegisterSummationModel } from '@rift/models/restapi/RegisterSummation.Model';
import { RegisterTamperModel } from '@rift/models/restapi/RegisterTamper.Model';
import { SummationRegisterModel } from '@rift/models/restapi/SummationRegister.Model';
import { AssociatedRegisterConfig, AutoCreateRegisters } from '@rift/service/device/auto-config/IRegisterConfig';
import { IAssociatedRegisterConfig } from '@rift/shared/IAssociatedRegisterConfig';
import { IRegisterConfig } from '@rift/shared/IRegisterConfig';
import { CountModeEnum } from '@shared/enum/CountMode.Enum';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { RegisterRemoteReceiverModel } from '@rift/models/restapi/RegisterRemoteReceiver.Model';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { RegisterWirelessPIRModel } from '@rift/models/restapi/RegisterWirelessPIR.Model';

export const summationRegisterModel_onAutoConfigAddAssociatedRegister = <T extends AutoCreateRegisters>(tcUseAssociatedConfig: IAssociatedRegisterConfig, rcAssociatedConfig: AssociatedRegisterConfig<T>, linkRegister: any, toRegister: any) => {
    const add = new SummationRegisterModel();
    add.register = linkRegister.registerIndex;
    add.subtraction = rcAssociatedConfig.subtraction;
    toRegister.registers.push(add);
};

export const summationRegisterModel_onRegisterSelected = (parentRegister: any, registerVM: AssociatedRegisterViewModel, selected: boolean, availableAssociated: AssociatedRegisterViewModel[]) => {
    const registerSummation = parentRegister as RegisterSummationModel;
    if (selected === true) {
        const add = new SummationRegisterModel();
        add.register = registerVM.register.registerIndex;
        add.subtraction = !registerVM.summationAdd;
        registerSummation.registers.push(add);
    } else {
        registerSummation.registers.splice(registerSummation.registers.findIndex(sr => sr.register === registerVM.register.registerIds[0]), 1);
    }
};

export const summationRegisterModel_getAssociatedRegisterVMs = (config: IAssociatedRegisterConfig, register: any, allRegisters: Array<any>, allLines: Array<LineModel>) => {
    if (register instanceof RegisterSummationModel) {
        if (register.registers.length > 0) {
            return register.registers.map(r => {
                const vm = new AssociatedRegisterViewModel(allRegisters.find(ar => ar.registerIndex === r.register));
                vm.summationAdd = !r.subtraction;
                return vm;
            });
        }
    }
    return [];
};

export const alternative_validateSelectedRegisterType = (config: IRegisterConfig, allRegisters: Array<any>, allLines: Array<LineModel>) => ArrayUtility.count(allLines, l => l.enabled === true) < 2 ?
        'There must be at least two registers in the system to create an alternative register' :
        null;

export const basic_validateSelectedRegisterType = (config: IRegisterConfig, allRegisters: Array<any>, allLines: Array<LineModel>) => ArrayUtility.count(allLines, l => l.enabled === true) >= 16 ?
        'No more basic counting registers can be added' :
        null;

export const beamBreak_validateSelectedRegisterType = (config: IRegisterConfig, allRegisters: Array<any>, allLines: Array<LineModel>) => ArrayUtility.count(allLines, l => l.enabled === true && (l.countMode === CountModeEnum.immediate || l.countMode === CountModeEnum.immediateAntiDither)) < 1 ?
        'There must be a line register with a count mode of immediate to create a beam break register' :
        null;

export const sequential_validateSelectedRegisterType = (config: IRegisterConfig, allRegisters: Array<any>, allLines: Array<LineModel>) => ArrayUtility.count(allLines, l => l.enabled === true && (l.countMode === CountModeEnum.immediate || l.countMode === CountModeEnum.immediateAntiDither)) < 2 ?
        'There must be at least two line registers with a count mode of immediate to create a sequential register' :
        null;

export const sequential_onRegisterSelected = (parentRegister: any, registerVM: AssociatedRegisterViewModel, selected: boolean, availableAssociated: AssociatedRegisterViewModel[]) => {
    if (selected === true) {
        let max = 0;

        const availableAssociatedLength = availableAssociated.length;
        for (let index = 0; index < availableAssociatedLength; index++) {
            const available = availableAssociated[index];
            if (available.sequentialOrder !== null && available.sequentialOrder > max) {
                max = available.sequentialOrder;
            }
        }

        registerVM.sequentialOrder = max + 1;
    }

    if (selected === false) {
        const availableAssociatedLength = availableAssociated.length;
        for (let index = 0; index < availableAssociatedLength; index++) {
            const available = availableAssociated[index];
            if (available.sequentialOrder > registerVM.sequentialOrder) {
                available.sequentialOrder--;
            }
        }

        registerVM.sequentialOrder = null;
    }
};

export const summation_validateSelectedRegisterType = (config: IRegisterConfig, allRegisters: Array<any>, allLines: Array<LineModel>) => ArrayUtility.count(allLines, l => l.enabled === true) < 1 ?
        'There must be at least one register in the system to create a summation register' :
        null;

export const tamper_validateSelectedRegisterType = (config: IRegisterConfig, allRegisters: Array<any>, allLines: Array<LineModel>) => ArrayUtility.count(allRegisters, r => r.registerType === r instanceof RegisterTamperModel) > 0 ?
        'Can only add one tamper detector' :
        null;

export const wirelessPIR_validateSelectedRegisterType = (config: IRegisterConfig, allRegisters: Array<any>, allLines: Array<LineModel>) => ArrayUtility.count(allRegisters, r => r.registerType === r instanceof RegisterWirelessPIRModel) > 7 ?
        'Can only add seven fine motion room sensor PIR registers' :
        null;

export const occupancyMaster_validateSelectedRegisterType = (config: IRegisterConfig, allRegisters: Array<any>, allLines: Array<LineModel>) => ArrayUtility.count(allLines, l => l.enabled === true) < 2 ?
        'There must be at least two registers in the system to create an occupancy register' :
        null;

export const queueWaitMaster_validateSelectedRegisterType = (config: IRegisterConfig, allRegisters: Array<any>, allLines: Array<LineModel>) => ArrayUtility.count(allLines, l => l.enabled === true) < 4 ?
        'There must be at least four registers in the system to create an queue wait register' :
        null;

export const compareMaster_validateSelectedRegisterType = (config: IRegisterConfig, allRegisters: Array<any>, allLines: Array<LineModel>) => allRegisters.length < 1 ?
        'There must be at least one register in the system to create a compare register' :
        null;

export const fIFOOccupancyMaster_validateSelectedRegisterType = (config: IRegisterConfig, allRegisters: Array<any>, allLines: Array<LineModel>) => ArrayUtility.count(allLines, l => l.enabled === true) < 2 ?
        'There must be at least two registers in the system to create an occupancy (FIFO) register' :
        null;

export const remoteReceiver_validateSelectedRegisterType = (config: IRegisterConfig, allRegisters: Array<any>, allLines: Array<LineModel>) => ArrayUtility.count(allRegisters, r => r.registerType === RegisterTypeEnum.remoteReceiver) > 7 ?
        'There can only be 8 Remote value receiver register' :
        null;
