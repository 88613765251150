<div *ngIf="!isNullOrUndefined(sectionChange) && !isNullOrUndefined(sectionVM) && sectionVM.show === true">
    <rift-config-history-action-header [sectionChange]="sectionChange">
    </rift-config-history-action-header>
    <table class="values-table">
        <ng-template [ngIf]="!isNullOrUndefined(sectionVM.valueProperties) && sectionVM.valueProperties.length > 0">
            <tr>
                <th class="label">Property</th>
                <th class="value"
                    *ngIf="updateOrRemove(sectionChange)">
                    From
                </th>
                <th class="value"
                    *ngIf="updateOrAdd(sectionChange)">
                    To
                </th>
            </tr>
            <ng-template ngFor
                let-valueProperty
                [ngForOf]="sectionVM.valueProperties">
                <tr riftConfigHistoryValueOutput
                    [sectionChange]="sectionChange"
                    [valueProperty]="valueProperty"
                    *ngIf="settingChangedOrShowUnchanged(sectionChange, valueProperty.key)"
                    [ngClass]="{ 'error': settingChanged(sectionChange, valueProperty.key)}">
                </tr>
            </ng-template>
        </ng-template>
        <ng-template ngFor
            let-childSectionVM
            [ngForOf]="sectionVM.children">
            <tr>
                <td colspan="2">
                    <rift-config-history-section [sectionVM]="childSectionVM"
                        [sectionChange]="getSection(childSectionVM.section, sectionChange)">
                    </rift-config-history-section>
                </td>
            </tr>
        </ng-template>
    </table>
</div>