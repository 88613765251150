import { Injectable } from '@angular/core';
import { PolygonModel } from '@rift/models/restapi/Polygon.Model';
import { PolygonCollectionModel } from '@rift/models/restapi/PolygonCollection.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiPolygonsService } from '@rift/service/restapi/v1/RestApi.Polygons.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class PolygonService extends RiftBaseService {

    private _getPolygonsLoadingTracker = new ObservableTracker<PolygonCollectionModel>();
    private _polygonsCache: PolygonCollectionModel = null;
    private _updatePolygonLoadingTracker = new ObservableTracker<ResultModel>();
    private _updatePolygonsLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiPolygonsService: RestApiPolygonsService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._polygonsCache = null;
    }

    public getPolygons(process?: ProcessMonitorServiceProcess): Observable<PolygonCollectionModel> {
        if (isNullOrUndefined(this._polygonsCache)) {
            return this._getPolygonsLoadingTracker
                .getLoading()
                .observable(this._restApiPolygonsService.getPolygons(process).pipe(
                    map(result => {
                        this._polygonsCache = result;
                        return this._polygonsCache;
                    })
                ));
        } else {
            return of(this._polygonsCache);
        }
    }

    public setCache(polygonsCache: PolygonCollectionModel): Observable<boolean> {
        this._polygonsCache = polygonsCache;
        return of(true);
    }

    public updatePolygon(polygon: PolygonModel, id: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updatePolygonLoadingTracker
            .getLoading(polygon)
            .observable(this._restApiPolygonsService.updatePolygon(polygon, id, process).pipe(tap(() => this._polygonsCache = null)));
    }

    public updatePolygons(polygons: Array<PolygonModel>, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updatePolygonsLoadingTracker
            .getLoading(polygons)
            .observable(this._restApiPolygonsService.updatePolygons(polygons, process).pipe(tap(() => this._polygonsCache = null)));
    }
}
