import { Injectable } from '@angular/core';
import { ViewPortModeEnum, ViewPortModeEnumHelpers } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { ViewPortSpriteSheetMap } from '@rift/components/shared/viewport/ViewPortSpriteSheetMap';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { TargetStatusEnum, TargetStatusEnumHelpers } from '@shared/enum/TargetStatus.Enum';
import { ISpriteSheetMap } from '@shared/generic/ISpriteSheetMap';
import { ILoadQueue, IManifest } from '@shared/interface/ILoadQueue';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { from, Observable, Subject, Observer, throwError, of } from 'rxjs';
import { map, zipAll } from 'rxjs/operators';

@Injectable()
export class ViewPortLoadQueueService extends RiftBaseService implements ILoadQueue {
    public loaded: Subject<void> = new Subject<void>();

    private _isLoaded: boolean = false;
    private _getTargetCache = {};

    private readonly _loadManifest: IManifest[] = [
        {
            id: 'spritesheet',
            src: './assets/viewport/spritesheet.png'
        },
    ];
    private _spriteSheetCanvas: HTMLCanvasElement;
    private _spriteSheetContext: CanvasRenderingContext2D;

    public constructor() {
        super();

        this._spriteSheetCanvas = document.createElement('canvas');
        this._spriteSheetContext = this._spriteSheetCanvas.getContext('2d');

        this.load();
    }

    public get isLoaded(): boolean {
        return this._isLoaded;
    }

    public getAddPoint(registerId: number, mouseOver?: boolean): Observable<HTMLImageElement> {
        if (this.isLoaded === true) {
            if (!isNullOrUndefined(mouseOver) && mouseOver === true) {
                return this.getSprite(`addpoint-mouseover-${registerId.toString()}`);
            }
            return this.getSprite(`addpoint-${registerId.toString()}`);
        } else {
            return throwError('ViewPortLoadQueueService: Attempt to load image before load queue complete.');
        }
    }

    public getImperialGrid(): HTMLImageElement {
        if (this.isLoaded === true) {
            return this.getImage('imperial-grid');
        } else {
            throw new Error('ViewPortLoadQueueService: Attempt to load image before load queue complete.');
        }
    }

    public getJoint(mode: ViewPortModeEnum, registerId: number, mouseOver?: boolean, dragging?: boolean): Observable<HTMLImageElement> {
        if (this.isLoaded === true) {
            if (!isNullOrUndefined(dragging) && dragging === true) {
                return this.getSprite(`joint-${ViewPortModeEnumHelpers.toString(mode)}-dragging-${registerId.toString()}`);
            }
            if (!isNullOrUndefined(mouseOver) && mouseOver === true) {
                return this.getSprite(`joint-${ViewPortModeEnumHelpers.toString(mode)}-mouseover-${registerId.toString()}`);
            }
            return this.getSprite(`joint-${ViewPortModeEnumHelpers.toString(mode)}-${registerId.toString()}`);
        } else {
            return throwError('ViewPortLoadQueueService: Attempt to load image before load queue complete.');
        }
    }

    public getJointArrow(registerId: number): Observable<HTMLImageElement> {
        if (this.isLoaded === true) {
            return this.getSprite(`joint-arrow-${registerId.toString()}`);
        } else {
            return throwError('ViewPortLoadQueueService: Attempt to load image before load queue complete.');
        }
    }

    public clearTargetCache(): void {
        this._getTargetCache = {};
    }

    public getTarget(status: Array<TargetStatusEnum>): Observable<HTMLImageElement> {
        if (this.isLoaded === true) {
            const name = TargetStatusEnumHelpers.toString(status[status.length - 1]);
            const target: HTMLImageElement = this._getTargetCache[name];
            if (isNullOrUndefined(target)) {
                return this.getSprite(`target-${name}`).pipe(map((image) => {
                    this._getTargetCache[name] = image;
                    return image;
                }));

            } else {
                return of(target);
            }
        } else {
            return throwError('ViewPortLoadQueueService: Attempt to load image before load queue complete.');
        }
    }

    public getVideoIcon(): Observable<HTMLImageElement> {
        if (this.isLoaded === true) {
            return this.getSprite('video');
        } else {
            return throwError('ViewPortLoadQueueService: Attempt to load image before load queue complete.');
        }
    }

    private getImage(name: string): HTMLImageElement {
        const manifest: IManifest = this._loadManifest.find(i => i.id === name);
        if (!isNullOrUndefined(manifest) && !isNullOrUndefined(manifest.image)) {
            return manifest.image;
        } else {
            throw new Error(`ViewPortLoadQueueService: image ${name} not found.`);
        }
    }

    private getSprite(name: string): Observable<HTMLImageElement> {
        return new Observable((observer: Observer<HTMLImageElement>) => {
            const spriteMap: ISpriteSheetMap = ViewPortSpriteSheetMap.find(i => i.name === name);
            if (!isNullOrUndefined(spriteMap) && !isNullOrUndefined(spriteMap.image)) {
                const retVal = spriteMap.image.cloneNode() as HTMLImageElement;
                retVal.onload = () => {
                    retVal.onload = null;
                    observer.next(retVal);
                    observer.complete();
                };
            } else {
                observer.error(Error(`ViewPortLoadQueueService: spriteMap or spriteMap.image null or undefined.`));
            }
        });
    }

    private initSprite(sheetMap: ISpriteSheetMap): Observable<boolean> {
        return new Observable<boolean>(subscriber => {
            const imageData = this._spriteSheetContext.getImageData(sheetMap.x, sheetMap.y, sheetMap.width, sheetMap.height);

            const convertCanvas = document.createElement('canvas');
            const convertContext = convertCanvas.getContext('2d');

            convertCanvas.width = imageData.width;
            convertCanvas.height = imageData.height;
            convertContext.putImageData(imageData, 0, 0);

            sheetMap.image = new Image();

            sheetMap.image.onload = () => {
                sheetMap.image.onload = null;
                subscriber.next(true);
                subscriber.complete();
            };

            const dataURL = convertCanvas.toDataURL();
            if (!isNullOrUndefined(dataURL)) {
                sheetMap.image.src = dataURL;
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    private initSpriteCanvas(image: HTMLImageElement, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void {
        if (!isNullOrUndefined(image) && !isNullOrUndefined(canvas) && !isNullOrUndefined(ctx)) {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
        }
    }

    private load(): void {
        if (!this.isLoaded) {
            this.loadImage(this._loadManifest[0]).subscribe(() => {
                this.initSpriteCanvas(this._loadManifest[0].image, this._spriteSheetCanvas, this._spriteSheetContext);

                this.addSubscription(from(ViewPortSpriteSheetMap).pipe(
                    map(sheetMap => this.initSprite(sheetMap)),
                    zipAll(),
                ).subscribe(() => {
                    this._isLoaded = true;
                    this.loaded.next();
                    this._spriteSheetContext = null;
                    this._spriteSheetCanvas = null;
                }));
            });
        }
    }

    private loadImage(manifest: IManifest): Observable<IManifest> {
        return new Observable((observer: Observer<IManifest>) => {
            const image = new Image();

            image.onload = () => {
                manifest.image = image;
                observer.next(manifest);
                observer.complete();
                image.onload = null;
            };

            image.onerror = (e) => {
                console.error(e);
                observer.error(e);
            };

            image.src = manifest.src;
        });
    }
}
