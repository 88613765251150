import { HttpPostEntryModel, IHttpPostEntryModel } from '@rift/models/restapi/HttpPostEntry.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IHttpPostModel extends IRestModel {
    entries: Array<IHttpPostEntryModel>;
}

export class HttpPostModel extends BaseModel implements IHttpPostModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public entries: RestModelChangeTrackerArray<HttpPostEntryModel> = new RestModelChangeTrackerArray<HttpPostEntryModel>();

    public get enabledEntries(): RestModelChangeTrackerArray<HttpPostEntryModel> {
        const retVal = new RestModelChangeTrackerArray<HttpPostEntryModel>();

        this.entries.forEach(element => {
            if (element.enabled) {
                retVal.push(element);
            }
        });

        return retVal;
    }

    public constructor() {
        super();

        this.registerChangeTrackerArray('entries');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-HttpPostModel');

        this.entries = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.Entries, HttpPostEntryModel);

        this.setPropertyOriginalValue('entries', this.entries);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            Entries: RestModelUtility.toJsonArray(this.enabledEntries),
        };
    }
}
