<div class="rift-validate-counting-network-dialog-title" mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                WideTracker Network change detected
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div class="rift-validate-counting-network-dialog-content" mat-dialog-content fxLayout="column">
    The number of node units connected has changed since last configuration, or you are configuring a new WideTracker
    network.
    <br>
    <br>
    If this is expected (you made a change) click OK to agree the change and clear this warning, otherwise
    click Cancel to investigate further
</div>
<div class="rift-managesynchronizedrecordings-actions" mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onCancelClick()">Cancel</button>
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">OK</button>
</div>
