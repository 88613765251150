import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Injector,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import {
    SettingsCountingAddRegisterComponent,
} from '@rift/components/settings/counting/addregister/Settings.Counting.AddRegister.Component';
import {
    SettingsCountingAdvancedSettingsComponent,
} from '@rift/components/settings/counting/advancedsettings/Settings.Counting.AdvancedSettings.Component';
import {
    SettingsCountingMenuBaseComponent,
} from '@rift/components/settings/counting/base/Settings.Counting.MenuBase.Component';
import {
    SettingsCountingDevicesComponent,
} from '@rift/components/settings/counting/devices/Settings.Counting.Devices.Component';
import {
    IGVector,
    SettingsCountingEditDeviceComponent,
} from '@rift/components/settings/counting/editdevice/Settings.Counting.EditDevice.Component';
import {
    SettingsCountingEditRegisterComponent,
} from '@rift/components/settings/counting/editregister/Settings.Counting.EditRegister.Component';
import {
    SettingsCountingGlobalHeightFilterComponent,
} from '@rift/components/settings/counting/globalheightfilter/Settings.Counting.GlobalHeightFilter.Component';
import {
    SettingsCountingHistogramChartComponent,
} from '@rift/components/settings/counting/histogramchart/Settings.Counting.HistogramChart.Component';
import {
    CountChangedEvent,
    SettingsCountingRegistersComponent,
} from '@rift/components/settings/counting/registers/Settings.Counting.Registers.Component';
import {
    SettingsCountingTrackingComponent,
} from '@rift/components/settings/counting/tracking/Settings.Counting.Tracking.Component';
import { ShapeBase } from '@rift/components/shared/viewport/base/ShapeBase';
import { Line } from '@rift/components/shared/viewport/lines/Line';
import { Polygon } from '@rift/components/shared/viewport/polygons/Polygon';
import { Register } from '@rift/components/shared/viewport/registers/Register';
import { ViewPortComponent } from '@rift/components/shared/viewport/ViewPort.Component';
import { ViewPortModeEnum } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { DeviceCollectionModel } from '@rift/models/restapi/DeviceCollection.Model';
import { HistogramLogModel } from '@rift/models/restapi/HistogramLog.Model';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { PolygonModel } from '@rift/models/restapi/Polygon.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ShapeModel } from '@rift/models/restapi/ShapeModel';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { CountsService } from '@rift/service/data/counts/Counts.Service';
import { HistogramService } from '@rift/service/data/histogram/Histogram.Service';
import { LineService } from '@rift/service/data/line/Line.Service';
import { PathMapService } from '@rift/service/data/pathmap/PathMap.Service';
import { PolygonService } from '@rift/service/data/polygon/Polygon.Service';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { DeviceValidationResult } from '@rift/service/devicevalidation/DeviceValidation.Service';
import { RestApiDevicesService } from '@rift/service/restapi/v1/RestApi.Devices.Service';
import { IPointData } from '@rift/workers/countingsetup/IPointData';
import { IRequestMessage } from '@rift/workers/countingsetup/IRequestMessage';
import { IResponseMessage } from '@rift/workers/countingsetup/IResponseMessage';
import { ISetupData } from '@rift/workers/countingsetup/ISetupData';
import { IShapeData } from '@rift/workers/countingsetup/IShapeData';
import { RequestTypeEnum } from '@rift/workers/countingsetup/RequestType.Enum';
import { ResponseTypeEnum } from '@rift/workers/countingsetup/ResponseType.Enum';
import { ShapeTypeEnum } from '@rift/workers/countingsetup/ShapeType.Enum';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { UnsavedChangesDialogResult } from '@shared/component/dialog/unsavedchanges/UnsavedChanges.Dialog.Component';
import { CountModeEnumHelpers } from '@shared/enum/CountMode.Enum';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { LEDStateEnum } from '@shared/enum/LEDState.Enum';
import { StreamTypeEnum } from '@shared/enum/StreamType.Enum';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { EventsService } from '@shared/service/events/Events.Service';
import { NavBarService } from '@shared/service/navbar/NavBar.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { merge, Observable, of, Subscription, zip } from 'rxjs';
import { flatMap, map, tap, catchError } from 'rxjs/operators';
import { MultiUnitAlignmentComponent } from './alignment/Settings.Counting.Alignment.Component';
import { SettingsCountingAlignmentDeviceListComponent } from './alignment/Settings.Counting.Alignment.DeviceList.Component';

enum QuickActionsEnum {
    snap = 0,
    flip = 1,
}

enum FlipStagesEnum {
    selectFlipItem = 0,
}

enum SnapStagesEnum {
    selectMoveItem = 0,
    selectToItem = 1,
}

enum SnapMoveTypesEnum {
    line = 0,
    polygon = 1,
}

@Component({
    selector: 'rift-settings-counting',
    templateUrl: './Settings.Counting.Component.html',
    styleUrls: ['./Settings.Counting.Component.scss']
})
export class SettingsCountingComponent extends RiftBaseComponent implements IFillHeight, ILoadDate, OnInit, AfterViewInit, OnDestroy, ISaveAllChanges {
    public static className: string = 'SettingsCountingComponent';
    public ViewPortModeEnum = ViewPortModeEnum;

    public addRegisterMenuZIndex: number = 0;
    public advancedSettingsMenuMenuZIndex: number = 0;
    public changeConnectionStateProcess: ProcessMonitorServiceProcess;
    public dataPollingProcess: ProcessMonitorServiceProcess;
    public devices: Array<DeviceModel> = null;
    public devicesMenuZIndex: number = 0;
    public editDevice: DeviceModel = null;
    public editDeviceMenuZIndex: number = 0;
    public editRegister: RegisterBaseModel = null;
    public editRegisterMenuZIndex: number = 0;
    public emUnLicensed: boolean = false;
    public errorProcess: ProcessMonitorServiceProcess;
    public getHistogramLogsProcess: ProcessMonitorServiceProcess;
    public globalHeightFilterMenuZIndex: number = 0;
    public histogramChartZIndex: number = 0;
    public histogramLogs: Array<HistogramLogModel> = null;
    public histogramRegister: RegisterBaseModel = null;
    public histogramRegisterCount: number = null;

    @ViewChild('addRegisterMenu', { static: false })
    public addRegisterMenu: SettingsCountingAddRegisterComponent;

    @ViewChild('advancedSettingsMenu', { static: false })
    public advancedSettingsMenu: SettingsCountingAdvancedSettingsComponent;

    @ViewChild('devicesMenu', { static: false })
    public devicesMenu: SettingsCountingDevicesComponent;

    @ViewChild('alignmentDeviceList', { static: false })
    public alignmentDeviceList: SettingsCountingAlignmentDeviceListComponent;

    @ViewChild('editDeviceMenu', { static: false })
    public editDeviceMenu: SettingsCountingEditDeviceComponent;

    @ViewChild('editRegisterMenu', { static: false })
    public editRegisterMenu: SettingsCountingEditRegisterComponent;

    @ViewChild('globalHeightFilterMenu', { static: false })
    public globalHeightFilterMenu: SettingsCountingGlobalHeightFilterComponent;

    @ViewChild('histogramChartMenu', { static: false })
    public histogramChartMenu: SettingsCountingHistogramChartComponent;

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @ViewChild('registersMenu', { static: false })
    public registersMenu: SettingsCountingRegistersComponent;

    @ViewChild('trackingMenu', { static: false })
    public trackingMenu: SettingsCountingTrackingComponent;

    @ViewChild('viewPort', { static: true })
    public viewPort: ViewPortComponent;

    @ViewChild('multiUnitAlignment', { static: true })
    public multiUnitAlignment: MultiUnitAlignmentComponent;

    @ViewChild('viewPortContent', { static: true })
    public viewPortContent: ElementRef;
    public lineUpdatedProcess: ProcessMonitorServiceProcess;
    public lines: Array<LineModel> = null;
    public masterDevice: DeviceModel = null;
    public mismatchedProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'rift-settings-counting';
    public pathMapDropDownShow: boolean = false;
    public polygonUpdatedProcess: ProcessMonitorServiceProcess;
    public polygons: Array<PolygonModel> = null;
    public quickAction: QuickActionsEnum = null;
    public quickActionMessage: string = null;
    public registers: RestModelChangeTrackerArray<RegisterBaseModel> = null;
    public registersMenuZIndex: number = 0;
    public resetPathMapProcess: ProcessMonitorServiceProcess;
    public selectedDevice: DeviceModel;
    public selectedRegister: RegisterBaseModel = null;
    public snapMoveItem: ShapeModel = null;
    public snapMoveType: SnapMoveTypesEnum = null;
    public snapStage: SnapStagesEnum = null;
    public targetsMessageReceivedProcess: ProcessMonitorServiceProcess;
    public timeSetup: TimeSetupModel = null;
    public toolbarHeight: number = 40;
    public trackingMenuZIndex: number = 0;
    public updateEventsProcess: ProcessMonitorServiceProcess;
    public validateDeviceProcess: ProcessMonitorServiceProcess;
    public videoMessageReceivedProcess: ProcessMonitorServiceProcess;
    public viewPortMode: ViewPortModeEnum = null;
    public inAlignmentMode: boolean = false;

    private _dataPollingEvent: DataPollingEvent = null;
    private _fullScreen: boolean = false;
    private _fullScreenMainPreHeight: string = null;
    private _fullScreenViewPortPreHeight: string = null;
    private _getDeviceFromRestApiLoadingTracker = new ObservableTracker<DeviceCollectionModel>();
    private _histogramLogsLoaded: boolean = true;
    private _lineToFront: LineModel;
    private _loadDataComplete: boolean = false;
    private _polygonToFront: PolygonModel;
    private _registerToFront: RegisterBaseModel;
    private _targetStreamId: number = null;
    private _targetsMessageReceivedSub: Subscription = null;
    private _updateDeviceEventsSub: Subscription = null;
    private _updateEventsSub: Subscription = null;
    private _videoDevice: DeviceModel = null;
    private _videoMessageReceivedSub: Subscription = null;
    private _videoStreamId: number = null;
    private _viewInit: boolean = false;
    private _webWorker: Worker = null;

    public constructor(
        private readonly _navBarService: NavBarService,
        private readonly _renderBase: Renderer2,
        private readonly _restApiDevicesService: RestApiDevicesService,
        private readonly _pathMapService: PathMapService,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _histogramService: HistogramService,
        private readonly _dataPollingService: DataPollingService,
        private readonly _countsService: CountsService,
        private readonly _zone: NgZone,
        private readonly _eventsService: EventsService,
        private readonly _dialog: MatDialog,
        private readonly _polygonService: PolygonService,
        private readonly _lineService: LineService,
        private readonly _registerService: RegisterService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.emUnLicensed = this._navBarService.emUnLicensed === true || this._navBarService.emDeviceUnLicensed === true || this._navBarService.emLicenseServerUnavailable === true;
        this.addSubscription(merge(
            this._navBarService.emUnLicensedChanged,
            this._navBarService.emDeviceUnLicensedChanged,
            this._navBarService.emLicenseServerUnavailableChanged,
        ).subscribe(() => {
            this.emUnLicensed = this._navBarService.emUnLicensed === true || this._navBarService.emDeviceUnLicensed === true || this._navBarService.emLicenseServerUnavailable === true;
        }));

        this.userNotificationService.hideBottomInfo();

        this.dataPollingProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, 'Data polling');
        this.getHistogramLogsProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, 'Get histogram logs');
        this.resetPathMapProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, 'resetPathMap');
        this.validateDeviceProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, 'validateDevice');
        this.targetsMessageReceivedProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, 'targetsMessageReceived');
        this.videoMessageReceivedProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, 'videoMessageReceived');
        this.lineUpdatedProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, 'Line updated');
        this.polygonUpdatedProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, 'Polygon updated');
        this.mismatchedProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, 'Mismatched');
        this.errorProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, 'Error');
        this.updateEventsProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, 'Update events');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, this.saveAllChangesProcessText);
        this.changeConnectionStateProcess = this.processMonitorService.getProcess(SettingsCountingComponent.className, 'Changing connection state');

        this._zone.runOutsideAngular(() => {
            this.updateEventsSubscribe();
        });

        this.addSubscription(this.unitsOfMeasurementService.unitsChange.subscribe(
            (units) => {
                this.viewPort.unitOfMeasurement = units;
                this.multiUnitAlignment.unitOfMeasurement = units;
            }
        ));

        this.initConnectionState();
    }

    public closeMenu(menu: SettingsCountingMenuBaseComponent): void {
        menu?.close();
    }

    public filledHeight(height: number): void {
        this._zone.runOutsideAngular(() => {
            if (this._fullScreen === true) {
                this._renderBase.setStyle(this.mainContent.nativeElement, 'height', null);
                this._renderBase.setStyle(this.viewPortContent.nativeElement, 'height', `${(height - this.toolbarHeight) + 10}px`);
            } else {
                this._renderBase.setStyle(this.viewPortContent.nativeElement, 'height', `${height - this.toolbarHeight}px`);
            }

            this.viewPort.fillElement(this.viewPortContent);
            this.multiUnitAlignment.fillElement(this.viewPortContent);
        });
    }

    public getDeviceVideoPlayState(serialNumber: string): boolean {
        if (!isNullOrUndefined(this._videoDevice) && this._videoDevice.serialNumber === serialNumber) {
            return true;
        }
        return false;
    }

    public goOnline(): void {
        this.addSubscription(this.observableHandlerBase(this.connectionService.goOnline(this.changeConnectionStateProcess), this.changeConnectionStateProcess).subscribe(), this.changeConnectionStateProcess);
    }

    public get allDevicesHighResCapable(): boolean{
        let retVal: boolean = true;

        if(this.isNullOrUndefined(this.devices) || this.devices.length < 2){
            return false;
        }

        this.devices.forEach(d=>{
            if(!d.isCapable(DeviceCapabilitiesEnum.highResSnapshot)){
                retVal = false;
            }
        });

        return retVal;
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public hideAllRegister(): void {
        this._zone.runOutsideAngular(() => {
            this.viewPort.registers.forEach(i => {
                i.visible = false;
                i.fadeOut = false;
            });
        });
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.deviceService.getDevices(process, false).pipe(
                map(deviceModels => {
                    if (!this.isNullOrUndefined(deviceModels)) {
                        this.devices = deviceModels.items;
                    }
                    return true;
                })
            ),
            this.getHostDevice(process).pipe(
                map(masterDevice => {
                    if (!this.isNullOrUndefined(masterDevice)) {
                        this.masterDevice = masterDevice;
                    }
                    return true;
                })
            ),
            this._lineService.getLines(process).pipe(
                map(lineModels => {
                    if (!this.isNullOrUndefined(lineModels)) {
                        this.lines = lineModels.items;
                    }
                    return true;
                })
            ),
            this._polygonService.getPolygons(process).pipe(
                map(polygonModels => {
                    if (!this.isNullOrUndefined(polygonModels)) {
                        this.polygons = polygonModels.items;
                    }
                    return true;
                })
            ),
            this._registerService.getRegisters(process).pipe(
                map(registerModels => {
                    if (!this.isNullOrUndefined(registerModels)) {
                        this.registers = registerModels.items;
                    }
                    return true;
                })
            ),
            this.globalService.getTimeSetup(process).pipe(
                map(timeSetup => {
                    if (!this.isNullOrUndefined(timeSetup)) {
                        this.timeSetup = timeSetup;
                    }
                    return true;
                })
            ),
            this._countsService.getConfig(process).pipe(
                map(countsConfig => {
                    this._dataPollingEvent = new DataPollingEvent('SettingsCountingComponent:HistogramLogs', 0, (countsConfig.countLogPeriod * 1000) + 1000, this.getHistogramLogsProcess);
                    this.subDataPolling();
                    return true;
                })
            ),
        ).pipe(
            tap(() => {
                this._loadDataComplete = true;
                this.setViewPortData();

                if (!this.isNullOrUndefined(this.editRegister)) {
                    const registersLength = this.registers.length;
                    for (let i = 0; i < registersLength; i++) {
                        const register = this.registers[i];
                        if (RegisterBaseModel.equal(register, this.editRegister)) {
                            this.onEditRegister(register);
                            break;
                        }
                    }
                }

                if (!this.isNullOrUndefined(this.editDevice)) {
                    const devicesLength = this.devices.length;
                    for (let i = 0; i < devicesLength; i++) {
                        const device = this.devices[i];
                        if (device.serialNumber === this.editDevice.serialNumber) {
                            this.onEditDevice(device);
                            break;
                        }
                    }
                }
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public loadDataHistogramLogs(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const midnight = new Date();
        midnight.setHours(0);
        midnight.setMinutes(0);
        midnight.setSeconds(0);
        midnight.setMilliseconds(0);

        const loadDataSub = zip(
            this._histogramService.getHistogramsCounts(midnight, process, {disabled: true}).pipe(
                map(histogramsCounts => {
                    this.histogramLogs = histogramsCounts.histogramLogs;
                    this._histogramLogsLoaded = true;
                    return true;
                }),
                catchError((err: any)=>{
                    console.log(err);

                    return of(true);
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngAfterViewInit(): void {
        this._zone.runOutsideAngular(() => {
            this._viewInit = true;
            this.addViewPortEventHandlers();
            this.setViewPortData();
            this.viewPort.fillElement(this.viewPortContent);
            this.viewPort.unitOfMeasurement = this.unitsOfMeasurementService.units;

            this.multiUnitAlignment.fillElement(this.viewPortContent);
            this.multiUnitAlignment.unitOfMeasurement = this.unitsOfMeasurementService.units;
        });
    }

    public ngOnDestroy(): void {
        this.userNotificationService.showBottomInfo();
        this._zone.runOutsideAngular(() => {
            this.stopWebWorker();
            this.stopDataPolling();
            this.stopStreams();
            if (!this.isNullOrUndefined(this._videoDevice)) {
                this.stopVideoStreams(isNullOrUndefined(this._videoDevice.videoSerialNumber) ? this._videoDevice.serialNumber : this._videoDevice.videoSerialNumber);
            }
            this.removeStreamHandlers();
        });

        super.ngOnDestroy();
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public onAddRegister(): void {
        this.openMenu(this.addRegisterMenu);
    }

    public onContentResize(): void {
        this.getMenus().forEach(menu => {
            if (!this.isNullOrUndefined(menu)) {
                menu.checkPosition();
            }
        });
    }

    public onCountChanged(event: CountChangedEvent): void {
        if (!this.isNullOrUndefined(this.histogramChartMenu) && !this.isNullOrUndefined(this.histogramRegister) && this.histogramRegister.registerIndex === event.index) {
            this.histogramRegisterCount = event.newCount;
        }
    }

    public onDeviceRealTimeUpdate(event: IGVector): void {
        this.viewPort.devices.forEach(d => {
            if (d.deviceModel.serialNumber === event.device) {
                this.addSubscription(
                    this.getDeviceFromRestApi(d.deviceModel.serialNumber).subscribe(devices => {
                        if (devices.items.length === 1) {
                            const apiDevice = devices.items[0];
                            d.videoSettings.update(apiDevice.outerCoverage, apiDevice.innerCoverage, event);
                            d.rebuildFieldOfViewSprite(apiDevice.outerCoverage, apiDevice.innerCoverage);
                            d.update();
                        }
                    })
                );
            }
        });
    }

    public onDeviceSelected(device: DeviceModel): void {
        this.selectedDevice = device;
        this.setHighlightedDevice();
    }

    public onEditDevice(device: DeviceModel): void {
        this.editDevice = device;
        this.openMenu(this.editDeviceMenu);
        this.setHighlightedDevice();
    }

    public onEditDeviceMenuClose(): void {
        this.editDevice = null;
        this.setHighlightedDevice();
    }

    public onEditRegister(registerBase: RegisterBaseModel): void {
        this.selectedRegister = registerBase;
        this.editRegister = registerBase;
        this.openMenu(this.editRegisterMenu);
    }

    public onEditRegisterMenuClose(): void {
        this.editRegister = null;
    }

    public onFullScreenClick(): void {
        const mainContentElement: HTMLDivElement = this.mainContent.nativeElement;
        const viewPortContentElement: HTMLDivElement = this.viewPortContent.nativeElement;

        if (this._fullScreen === false) {
            this._renderBase.setStyle(mainContentElement, 'position', 'absolute');
            this._renderBase.setStyle(mainContentElement, 'top', '0px');
            this._renderBase.setStyle(mainContentElement, 'left', '0px');
            this._renderBase.setStyle(mainContentElement, 'right', '0px');
            this._renderBase.setStyle(mainContentElement, 'bottom', '0px');
            this._renderBase.setStyle(mainContentElement, 'zIndex', '10');
            this._fullScreenMainPreHeight = mainContentElement.style.height;
            this._renderBase.setStyle(mainContentElement, 'height', null);

            const mainContentStyle = window.getComputedStyle(mainContentElement);

            this._fullScreenViewPortPreHeight = viewPortContentElement.style.height;
            this._renderBase.setStyle(viewPortContentElement, 'height', (parseInt(mainContentStyle.height, 10) - this.toolbarHeight) + 'px');

            this._fullScreen = true;
        } else {
            this._renderBase.setStyle(mainContentElement, 'position', 'relative');
            this._renderBase.setStyle(mainContentElement, 'top', null);
            this._renderBase.setStyle(mainContentElement, 'left', null);
            this._renderBase.setStyle(mainContentElement, 'right', null);
            this._renderBase.setStyle(mainContentElement, 'bottom', null);
            this._renderBase.setStyle(mainContentElement, 'zIndex', null);
            this._renderBase.setStyle(mainContentElement, 'height', this._fullScreenMainPreHeight);

            this._renderBase.setStyle(viewPortContentElement, 'height', this._fullScreenViewPortPreHeight);

            this._fullScreen = false;
        }

        this.viewPort.fillElement(this.viewPortContent);
        this.multiUnitAlignment.fillElement(this.viewPortContent);

        this.getMenus().forEach(menu => {
            if (!this.isNullOrUndefined(menu)) {
                menu.isFullScreen = this._fullScreen;
            }
        });
    }

    public onHistogramMenuClose(): void {
        this.stopDataPolling();
        this.histogramRegister = null;
    }

    public onMenuBroughtToFront(broughtToFrontMenu: SettingsCountingMenuBaseComponent): void {
        this.getMenus().forEach(menu => {
            if (!this.isNullOrUndefined(menu) && menu !== broughtToFrontMenu) {
                menu.zIndex = 1;
            }
        });
    }

    public onPathMapDropDownClick(): void {
        this.pathMapDropDownShow = false;
    }

    public onPathMapDropDownMouseEnter(): void {
        this.pathMapDropDownShow = true;
    }

    public onPathMapDropDownMouseLeave(): void {
        this.pathMapDropDownShow = false;
    }

    public onResetPathMapClick(): void {
        this.addSubscription(this.openOkCancelDialog('Reset Path Map', 'Are you sure you want to reset the Path Map?', true).afterClosed().subscribe(result => {
            if (!this.isNullOrUndefined(result) && result.ok === true) {
                this._zone.runOutsideAngular(() => {
                    this.addSubscription(this.observableHandlerBase(this._pathMapService.reset(), this.resetPathMapProcess).subscribe(() => {
                        this.addSubscription(this.observableHandlerBase(this.viewPort.pathMapRefresh(), this.resetPathMapProcess).subscribe(), this.resetPathMapProcess);
                    }), this.resetPathMapProcess);
                });
            }
        }));
    }

    public onShowHideHistogram(registerBase: RegisterBaseModel): void {
        this.startDataPolling();
        this.histogramRegister = registerBase;
        this.histogramRegisterCount = this.registersMenu.getRegisterCount(this.histogramRegister);
        this.openMenu(this.histogramChartMenu);
    }

    public onShowHideRegister(registerBase: RegisterBaseModel): void {
        this._zone.runOutsideAngular(() => {
            const register = this.viewPort.registers.find(i => RegisterBaseModel.equal(i.registerBaseModel, registerBase));
            if (!this.isNullOrUndefined(register)) {
                register.visible = !register.visible;
                if (register.visible === false) {
                    this.viewPort.registers.forEach(r => r.fadeOut = false);
                }
            }
        });
    }

    public onToggleDeviceVideo(device: DeviceModel): void {
        this.setVideoDevice(device);
    }

    public onTogglePathMapClick(): void {
        this.viewPort.showPathMap = !this.viewPort.showPathMap;
    }

    public onViewPortModeChange(event: MatSlideToggleChange): void {
        this._zone.runOutsideAngular(() => {
            if (this.viewPort.mode === ViewPortModeEnum.view) {
                this.setViewPortMode(ViewPortModeEnum.edit);
                this.stopVideoStreams(isNullOrUndefined(this._videoDevice.videoSerialNumber) ? this._videoDevice.serialNumber : this._videoDevice.videoSerialNumber);
            } else {
                this.setViewPortMode(ViewPortModeEnum.view);
                this.startVideoStreams(isNullOrUndefined(this._videoDevice.videoSerialNumber) ? this._videoDevice.serialNumber : this._videoDevice.videoSerialNumber);
            }
            this.viewPortMode = this.viewPort.mode;
        });
    }

    public onZoomInClick(): void {
        this.viewPort.zoomIncrement(0.5, false);
        this.multiUnitAlignment.zoomIncrement(0.5, false);
    }

    public onZoomOutClick(): void {
        this.viewPort.zoomIncrement(-0.5, false);
        this.multiUnitAlignment.zoomIncrement(-0.5, false);
    }

    public openMenu(menu: SettingsCountingMenuBaseComponent): void {
        menu.open();
        menu.bringToFront();
    }

    public registerSelected(register: RegisterBaseModel): void {
        if (!this.isNullOrUndefined(register) && register.isViewPortVisibleInViewPort === true) {
            const vpRegister = this.viewPort.registers.find(vpr => RegisterBaseModel.equal(vpr.registerBaseModel, register));

            if (vpRegister.visible === true) {
                this.viewPort.registers.forEach(viewPortRegister => {
                    if (RegisterBaseModel.equal(viewPortRegister.registerBaseModel, register)) {
                        viewPortRegister.fadeOut = false;
                        this.viewPort.registers.bringToFront(viewPortRegister);
                    } else {
                        viewPortRegister.fadeOut = true;
                    }
                });

                return;
            }
        }
        this.viewPort.registers.forEach(viewPortRegister => viewPortRegister.fadeOut = false);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(true),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process);
    }

    public setHighlightedDevice(): void {
        const device = this.editDevice || this.selectedDevice;
        this.viewPort.devices.forEach(d => {
            if (!isNullOrUndefined(device) && d.deviceModel.serialNumber === device.serialNumber) {
                this.deviceService.setLedState(d.deviceModel.serialNumber, LEDStateEnum.on).subscribe();
                d.selected = true;
            } else {
                this.deviceService.setLedState(d.deviceModel.serialNumber, LEDStateEnum.off).subscribe();
                d.selected = false;
            }
        });
    }

    public showAllRegister(): void {
        this._zone.runOutsideAngular(() => {
            this.viewPort.registers.forEach(i => {
                i.visible = true;
                i.fadeOut = false;
            });
        });
    }

    public showSaveChangesWarning(): Observable<boolean> {
        const openRequest = this.openUnsavedChangesDialog();

        return openRequest.afterClosed().pipe(
            flatMap((result: UnsavedChangesDialogResult): Observable<boolean> => {
                if (!this.isNullOrUndefined(result) && result.save) {
                    this.updateEventsUnsubscribe();
                    openRequest.close();
                    return zip(
                        of(this.addRegisterMenu).pipe(
                            flatMap(addRegisterMenu => {
                                if (!this.isNullOrUndefined(addRegisterMenu) && !this.isNullOrUndefined(addRegisterMenu.addRegister) && addRegisterMenu.hasChanges) {
                                    return addRegisterMenu.saveAllChanges(null, this.saveAllChangesProcess);
                                } else {
                                    return of(true);
                                }
                            }),
                        ),
                        of(this.editRegisterMenu).pipe(
                            flatMap(editRegisterMenu => {
                                if (!this.isNullOrUndefined(editRegisterMenu) && !this.isNullOrUndefined(editRegisterMenu.editRegister) && editRegisterMenu.hasChanges) {
                                    return editRegisterMenu.saveAllChanges(null, this.saveAllChangesProcess);
                                } else {
                                    return of(true);
                                }
                            }),
                        ),
                        of(this.editDeviceMenu).pipe(
                            flatMap(editDeviceMenu => {
                                if (!this.isNullOrUndefined(editDeviceMenu) && !this.isNullOrUndefined(editDeviceMenu.editDevice) && editDeviceMenu.hasChanges) {
                                    return editDeviceMenu.saveAllChanges(null, this.saveAllChangesProcess);
                                } else {
                                    return of(true);
                                }
                            }),
                        ),
                        of(this.trackingMenu).pipe(
                            flatMap(trackingMenu => {
                                if (!this.isNullOrUndefined(trackingMenu) && !this.isNullOrUndefined(trackingMenu.discrimination) && trackingMenu.hasChanges) {
                                    return trackingMenu.saveAllChanges(null, this.saveAllChangesProcess);
                                } else {
                                    return of(true);
                                }
                            }),
                        ),
                        of(this.advancedSettingsMenu).pipe(
                            flatMap(advancedSettingsMenu => {
                                if (!this.isNullOrUndefined(advancedSettingsMenu) && !this.isNullOrUndefined(advancedSettingsMenu.advancedSettings) && advancedSettingsMenu.hasChanges) {
                                    return advancedSettingsMenu.saveAllChanges(null, this.saveAllChangesProcess);
                                } else {
                                    return of(true);
                                }
                            }),
                        ),
                        of(this.globalHeightFilterMenu).pipe(
                            flatMap(globalHeightFilterMenu => {
                                if (!this.isNullOrUndefined(globalHeightFilterMenu) && !this.isNullOrUndefined(globalHeightFilterMenu.globalHeightFilter) && globalHeightFilterMenu.hasChanges) {
                                    return globalHeightFilterMenu.saveAllChanges(null, this.saveAllChangesProcess);
                                } else {
                                    return of(true);
                                }
                            }),
                        ),
                    ).pipe(
                        map(zipResult => {
                            if (this.isZipResultSuccess(zipResult)) {
                                return true;
                            } else {
                                this.updateEventsSubscribe();
                                return false;
                            }
                        })
                    );
                } else if (!this.isNullOrUndefined(result) && result.discard) {
                    openRequest.close();
                    if (!this.isNullOrUndefined(this.addRegisterMenu)) {
                        this.addRegisterMenu.reset();
                    }
                    if (!this.isNullOrUndefined(this.editRegisterMenu)) {
                        this.editRegisterMenu.reset();
                    }
                    if (!this.isNullOrUndefined(this.editDeviceMenu)) {
                        this.editDeviceMenu.reset();
                    }
                    if (!this.isNullOrUndefined(this.trackingMenu)) {
                        this.trackingMenu.reset();
                    }
                    if (!this.isNullOrUndefined(this.advancedSettingsMenu)) {
                        this.advancedSettingsMenu.reset();
                    }
                    if (!this.isNullOrUndefined(this.globalHeightFilterMenu)) {
                        this.globalHeightFilterMenu.reset();
                    }
                    return of(true);
                } else if (result.stay) {
                    openRequest.close();
                    return of(false);
                }
            }),
        );
    }

    public startFlip(): void {
        this._zone.run(() => {
            this.setLinesPolygonsModeState(false);

            this.quickActionMessage = 'Please click the line to flip';
            this.quickAction = QuickActionsEnum.flip;
        });
    }

    public startSnap(): void {
        this._zone.run(() => {
            this.setLinesPolygonsModeState(false);

            this.quickActionMessage = 'Please click the line or polygon to move';
            this.quickAction = QuickActionsEnum.snap;

            this.snapStage = SnapStagesEnum.selectMoveItem;
            this.snapMoveType = null;
            this.snapMoveItem = null;
        });
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return zip(
            of(this.addRegisterMenu).pipe(flatMap(menu => !this.isNullOrUndefined(menu) ? menu.deactivate() : of(true))),
            of(this.advancedSettingsMenu).pipe(flatMap(menu => !this.isNullOrUndefined(menu) ? menu.deactivate() : of(true))),
            of(this.editDeviceMenu).pipe(flatMap(menu => !this.isNullOrUndefined(menu) ? menu.deactivate() : of(true))),
            of(this.editRegisterMenu).pipe(flatMap(menu => !this.isNullOrUndefined(menu) ? menu.deactivate() : of(true))),
            of(this.globalHeightFilterMenu).pipe(flatMap(menu => !this.isNullOrUndefined(menu) ? menu.deactivate() : of(true))),
            of(this.trackingMenu).pipe(flatMap(menu => !this.isNullOrUndefined(menu) ? menu.deactivate() : of(true))),
        ).pipe(
            map(results => results.every(r => r === true))
        );
    }

    @HostListener('window:resize', ['$event'])
    public onWindowResize(): void {
    }

    public stopFlip(): void {
        this._zone.run(() => {
            this.setLinesPolygonsModeState(true);

            this.quickAction = null;
            this.quickActionMessage = null;
        });
    }

    public stopQuickAction(): void {
        this.stopSnap();
        this.stopFlip();
    }

    public stopSnap(): void {
        this._zone.run(() => {
            this.setLinesPolygonsModeState(true);

            this.quickAction = null;
            this.quickActionMessage = null;

            this.snapStage = null;
            this.snapMoveType = null;
            this.snapMoveItem = null;
        });
    }

    public toggleAlignmentMode(): void {
        if (this.inAlignmentMode === true && this.multiUnitAlignment.hasChangesBase === true) {
            this.addSubscription(this.openOkCancelDialog('Exit Alignment', 'Are you sure you want to exit alignment mode? Any changes will be lost', true).afterClosed().subscribe(result => {
                if (!this.isNullOrUndefined(result) && result.ok === true) {
                    this.inAlignmentMode = !this.inAlignmentMode;
                    this.deviceService.clearCache();

                    this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());

                    this.closeMenu(this.alignmentDeviceList);
                    if (this.viewPort.mode === ViewPortModeEnum.view) {
                        this.startVideoStreams(isNullOrUndefined(this._videoDevice.videoSerialNumber) ? this._videoDevice.serialNumber : this._videoDevice.videoSerialNumber);
                    }
                    this.startStreams();
                }
            }));
        }
        else {
            this.inAlignmentMode = !this.inAlignmentMode;

            if (this.inAlignmentMode === true){
                this.closeMenu(this.registersMenu);
                this.closeMenu(this.addRegisterMenu);
                this.closeMenu(this.editRegisterMenu);
                this.closeMenu(this.devicesMenu);
                this.closeMenu(this.editDeviceMenu);
                this.closeMenu(this.histogramChartMenu);
                this.closeMenu(this.trackingMenu);
                this.closeMenu(this.globalHeightFilterMenu);
                this.closeMenu(this.advancedSettingsMenu);

                this.openMenu(this.alignmentDeviceList);

                this.stopVideoStreams(isNullOrUndefined(this._videoDevice.videoSerialNumber) ? this._videoDevice.serialNumber : this._videoDevice.videoSerialNumber);
                this.stopStreams();

                this.setMultiUnitAlignmentData();
            }
            else {
                this.deviceService.clearCache();

                this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());

                this.closeMenu(this.alignmentDeviceList);
                if (this.viewPort.mode === ViewPortModeEnum.view) {
                    this.startVideoStreams(isNullOrUndefined(this._videoDevice.videoSerialNumber) ? this._videoDevice.serialNumber : this._videoDevice.videoSerialNumber);
                }
                this.startStreams();
            }
        }
    }

    public toggleMenuShow(menu: SettingsCountingMenuBaseComponent): void {
        if (!this.isNullOrUndefined(menu)) {
            if (menu.show) {
                this.closeMenu(menu);
            } else {
                this.openMenu(menu);
            }
        }
    }

    protected preOffline(): Observable<boolean> {
        return super.preOffline().pipe(
            map(() => {
                this.viewPort.showPathMap = false;
                return true;
            })
        );
    }

    protected offline(): void {
        super.offline();
        this.stopStreams();
        this.stopWebWorker();
        if(this.inAlignmentMode === true){
            this.inAlignmentMode = false;
            this.closeMenu(this.alignmentDeviceList);
            this.deviceService.clearCache();
        }
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.startWebWorker();
        this.addSubscription(this.observableHandlerBase(this.deviceValidationService.validateDeviceHeights(this.connectionService.hostFriendlySerial, this.validateDeviceProcess), this.validateDeviceProcess).subscribe(
            (deviceValidationResult: DeviceValidationResult) => {
                if (deviceValidationResult.deviceDataUpdate === true) {
                    this.deviceService.clearCache();
                }
                this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
            }
        ), this.validateDeviceProcess);
    }

    protected setReadOnly(): void {
        super.setReadOnly();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
    }

    private addStreamHandlers(): void {
        this._zone.runOutsideAngular(() => {
            if (this.isNullOrUndefined(this._targetsMessageReceivedSub)) {
                this._targetsMessageReceivedSub = this.addSubscription(this.observableHandlerBase(this.webSocketService.targetsMessageReceived, this.targetsMessageReceivedProcess).subscribe(targets => {
                    this.viewPort.addLifetimeTargets(targets.items);
                }), this.targetsMessageReceivedProcess);
            }

            if (this.isNullOrUndefined(this._videoMessageReceivedSub)) {
                this._videoMessageReceivedSub = this.addSubscription(this.observableHandlerBase(this.webSocketService.videoMessageReceived, this.videoMessageReceivedProcess).subscribe(frame => {
                    if (frame.device !== this._videoDevice.serialNumber) {
                        this.webSocketService.reStopStream(StreamTypeEnum.video, 0, frame.device);
                    }
                    this.viewPort.videoDevice.video.setCurrentFrame(frame);
                }), this.videoMessageReceivedProcess);
            }
        });
    }

    private addViewPortEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this.addSubscription(this.observableHandlerBase(this.viewPort.lineUpdated, this.lineUpdatedProcess).subscribe(line => {
                const message = {
                    type: RequestTypeEnum.update,
                    shape: {
                        type: ShapeTypeEnum.line,
                        id: line.lineModel.iD,
                        name: line.lineModel.name,
                        enabled: line.lineModel.enabled,
                        points: line.getPointModels().map(p => ({ x: Math.round(p.x), y: Math.round(p.y) } as IPointData)),
                        countMode: CountModeEnumHelpers.toRestApi(line.lineModel.countMode),
                    } as IShapeData
                } as IRequestMessage;

                this._webWorker.postMessage(message);
            }), this.lineUpdatedProcess);
            this.addSubscription(this.observableHandlerBase(this.viewPort.polygonUpdated, this.polygonUpdatedProcess).subscribe(polygon => {
                const message = {
                    type: RequestTypeEnum.update,
                    shape: {
                        type: ShapeTypeEnum.polygon,
                        id: polygon.polygonModel.iD,
                        name: polygon.polygonModel.name,
                        enabled: polygon.polygonModel.enabled,
                        points: polygon.getPointModels().map(p => ({ x: Math.round(p.x), y: Math.round(p.y) } as IPointData)),
                    } as IShapeData
                } as IRequestMessage;

                this._webWorker.postMessage(message);
            }), this.polygonUpdatedProcess);
        });
    }

    private addViewPortRegisterEventHandlers(vpRegister: Register): void {
        vpRegister.lines.forEach(i => i.click.subscribe(event => this.onViewPortLineClicked(event)));
        vpRegister.polygons.forEach(i => i.click.subscribe(event => this.onViewPortPolygonClicked(event)));
    }

    private bringLineToFrontOnReload(line: LineModel): void {
        this._lineToFront = line;
    }

    private bringPolygonToFrontOnReload(polygon: PolygonModel): void {
        this._polygonToFront = polygon;
    }

    private bringRegisterToFrontOnReload(item: RegisterBaseModel): void {
        this._registerToFront = item;
    }

    private getDeviceFromRestApi(serialNumber: string, process?: ProcessMonitorServiceProcess): Observable<DeviceCollectionModel> {
        return this._getDeviceFromRestApiLoadingTracker
            .getLoading(serialNumber)
            .observable(this._restApiDevicesService.getDevice(serialNumber, process));
    }

    private getMenus(): SettingsCountingMenuBaseComponent[] {
        return [
            this.registersMenu,
            this.addRegisterMenu,
            this.editRegisterMenu,
            this.devicesMenu,
            this.alignmentDeviceList,
            this.editDeviceMenu,
            this.histogramChartMenu,
            this.trackingMenu,
            this.globalHeightFilterMenu,
            this.advancedSettingsMenu,
        ];
    }

    private onQuickActionFlipLineClicked(event: DisplayItemMouseEvent): void {
        const line = (event.displayItem as Line).lineModel;
        if (!this.isNullOrUndefined(line)) {
            const waitDialog = this.openPleaseWaitSavingDialog();
            line.points.reverse();
            this.addSubscription(this._lineService.updateLine(line, line.iD).subscribe(() => {
                this.stopFlip();
                this._eventsService.flipDeviceRegister();
                waitDialog.close();
            }));
        }
    }

    private onQuickActionSnapLineClicked(event: DisplayItemMouseEvent): void {
        if (!this.isNullOrUndefined(this.snapStage)) {
            switch (this.snapStage) {
                case SnapStagesEnum.selectMoveItem:
                    this.snapStage = SnapStagesEnum.selectToItem;
                    this.snapMoveType = SnapMoveTypesEnum.line;
                    this.snapMoveItem = (event.displayItem as Line).lineModel;
                    this._zone.run(() => this.quickActionMessage = 'Please click the line to move to');
                    break;
                case SnapStagesEnum.selectToItem:
                    if (this.snapMoveType === SnapMoveTypesEnum.line) {
                        const snapToPoints = (event.displayItem as ShapeBase).getPointModels();
                        const snapMoveItem = this.snapMoveItem as LineModel;

                        if ((event.displayItem as ShapeBase).shapeModel.uniqueId !== snapMoveItem.uniqueId) {

                            snapMoveItem.points = snapToPoints;

                            const waitDialog = this.openPleaseWaitSavingDialog();
                            this.addSubscription(this._lineService.updateLine(snapMoveItem, snapMoveItem.iD).subscribe(
                                () => {
                                    this.stopSnap();
                                    this.bringLineToFrontOnReload(snapMoveItem);
                                    this._eventsService.snappedDeviceRegister();
                                    waitDialog.close();
                                }
                            ));
                        }
                    }
            }
        }
    }

    private onQuickActionSnapPolygonClicked(event: DisplayItemMouseEvent): void {
        if (!this.isNullOrUndefined(this.snapStage)) {
            switch (this.snapStage) {
                case SnapStagesEnum.selectMoveItem:
                    this.snapStage = SnapStagesEnum.selectToItem;
                    this.snapMoveType = SnapMoveTypesEnum.polygon;
                    this.snapMoveItem = (event.displayItem as Polygon).polygonModel;
                    this._zone.run(() => this.quickActionMessage = 'Please click the polygon to move to');
                    break;
                case SnapStagesEnum.selectToItem:
                    if (this.snapMoveType === SnapMoveTypesEnum.polygon) {
                        const snapToPoints = (event.displayItem as ShapeBase).getPointModels();
                        const snapMoveItem = this.snapMoveItem as PolygonModel;

                        if ((event.displayItem as ShapeBase).shapeModel.uniqueId !== snapMoveItem.uniqueId) {

                            snapMoveItem.points = snapToPoints;

                            this.addSubscription(this._polygonService.updatePolygon(snapMoveItem, snapMoveItem.iD).subscribe(
                                () => {
                                    this.stopSnap();
                                    this.bringPolygonToFrontOnReload(snapMoveItem);
                                    this._eventsService.snappedDeviceRegister();
                                }
                            ));
                        }
                    }
            }
        }
    }

    private onViewPortLineClicked(event: DisplayItemMouseEvent): void {
        switch (this.quickAction) {
            case QuickActionsEnum.snap:
                this.onQuickActionSnapLineClicked(event);
                break;
            case QuickActionsEnum.flip:
                this.onQuickActionFlipLineClicked(event);
                break;
        }
    }

    private onViewPortPolygonClicked(event: DisplayItemMouseEvent): void {
        switch (this.quickAction) {
            case QuickActionsEnum.snap:
                this.onQuickActionSnapPolygonClicked(event);
        }
    }

    private removeStreamHandlers(): void {
        this._zone.runOutsideAngular(() => {
            if (!this.isNullOrUndefined(this._targetsMessageReceivedSub)) {
                this._targetsMessageReceivedSub.unsubscribe();
                this._targetsMessageReceivedSub = null;
            }

            if (!this.isNullOrUndefined(this._videoMessageReceivedSub)) {
                this._videoMessageReceivedSub.unsubscribe();
                this._videoMessageReceivedSub = null;
            }
        });
    }

    private setLinesPolygonsModeState(enabled: boolean): void {
        this.viewPort.registers.forEach(r => {
            r.lines.forEach(l => l.editEnabled = enabled);
            r.polygons.forEach(p => p.editEnabled = enabled);
        });
    }

    private setVideoDevice(device: DeviceModel): void {
        this._zone.runOutsideAngular(() => {
            if (isNullOrUndefined(device)) {
                this.stopVideoStreams(isNullOrUndefined(this._videoDevice.videoSerialNumber) ? this._videoDevice.serialNumber : this._videoDevice.videoSerialNumber);
                this._videoDevice = null;
            } else {
                if (!isNullOrUndefined(this._videoDevice)) {
                    this.stopVideoStreams(isNullOrUndefined(this._videoDevice.videoSerialNumber) ? this._videoDevice.serialNumber : this._videoDevice.videoSerialNumber);
                }
                this.startVideoStreams(isNullOrUndefined(device.videoSerialNumber) ? device.serialNumber : device.videoSerialNumber);
                this._videoDevice = device;
            }
            this.viewPort.setVideoDevice(device ? device.serialNumber : null);
        });
    }

    private setViewPortData(): void {
        this._zone.runOutsideAngular(() => {
            if (this._viewInit === true && this.connectionService.isConnected === true && this._loadDataComplete === true) {
                if (!this.isNullOrUndefined(this._videoDevice)) {
                    this.setVideoDevice(null);
                }

                const registerVisibleStates = [];
                this.viewPort.registers.forEach(vpRegister => {
                    registerVisibleStates[vpRegister.registerBaseModel.registerIndex] = vpRegister.visible;
                });

                this.viewPort.clear();
                this.viewPort.targets.setLive();
                this.viewPort.addDevices(this.devices);
                this.viewPort.addRegisters(this.registers, this.lines, this.polygons);
                this.viewPort.targets.markedTargetsEnabled = this.viewPort.registers.markedTargetsEnabled();
                this.viewPort.center();

                this.viewPort.registers.forEach(vpRegister => this.addViewPortRegisterEventHandlers(vpRegister));

                if (registerVisibleStates.length > 0) {
                    this.viewPort.registers.forEach(vpRegister => {
                        const registerVisibleState = registerVisibleStates[vpRegister.registerBaseModel.registerIndex];
                        if (!isNullOrUndefined(registerVisibleState)) {
                            vpRegister.visible = registerVisibleState;
                        }
                    });
                }

                this.addStreamHandlers();

                this.addSubscription(this.isDeviceCapable(DeviceCapabilitiesEnum.video).subscribe(
                    videoCapable => {
                        if (this.isReadOnly === false) {
                            if (videoCapable === true) {
                                if (!this.isNullOrUndefined(this.viewPortMode)) {
                                    this.setViewPortMode(this.viewPortMode);
                                } else {
                                    this.setViewPortMode(ViewPortModeEnum.view);
                                }
                            } else {
                                this.setViewPortMode(ViewPortModeEnum.edit);
                            }
                        } else {
                            this.setViewPortMode(ViewPortModeEnum.view);
                        }
                    }));

                if (this.connectionService.isOnline) {
                    this.startStreams();
                    if (isNullOrUndefined(this._videoDevice) && !this.isNullOrUndefined(this.masterDevice)) {
                        if (this.masterDevice.isCapable(DeviceCapabilitiesEnum.video)) {
                            this.setVideoDevice(this.masterDevice);
                        } else {
                            const devicesLength = this.devices.length;
                            for (let i = 0; i < devicesLength; i++) {
                                const device = this.devices[i];
                                if (device.isCapable(DeviceCapabilitiesEnum.video)) {
                                    this.setVideoDevice(device);
                                    break;
                                }
                            }
                        }
                    }
                }

                if (!this.isNullOrUndefined(this._lineToFront)) {
                    this.viewPort.registers.forEach(vpR => {
                        vpR.lines.forEach(vpL => {
                            if (vpL.lineModel.iD === this._lineToFront.iD) {
                                this.viewPort.registers.bringToFront(vpR);
                            }
                        });
                    });
                }

                if (!this.isNullOrUndefined(this._polygonToFront)) {
                    this.viewPort.registers.forEach(vpR => {
                        vpR.polygons.forEach(vpP => {
                            if (vpP.polygonModel.iD === this._polygonToFront.iD) {
                                this.viewPort.registers.bringToFront(vpR);
                            }
                        });
                    });
                }

                if (!this.isNullOrUndefined(this._registerToFront)) {
                    this.viewPort.registers.forEach(vpR => {
                        if (vpR.registerBaseModel.registerIndex === this._registerToFront.registerIndex) {
                            this.viewPort.registers.bringToFront(vpR);
                        }
                    });
                }
            }
        });
    }

    private setMultiUnitAlignmentData(): void {
        this._zone.runOutsideAngular(() => {
            if (this._viewInit === true && this.connectionService.isConnected === true && this._loadDataComplete === true) {
                this.multiUnitAlignment.clear();
                this.multiUnitAlignment.addDevices(this.devices.sort((a, b) => a.master === true ? -1 : b.master === true ? 1 : 0));
                this.multiUnitAlignment.center();
            }
        });
    }

    private setViewPortMode(mode: ViewPortModeEnum): void {
        this._zone.runOutsideAngular(() => {
            if (!this.isNullOrUndefined(this.viewPort)) {
                this.viewPort.mode = mode;
                this.viewPort.update();
            }
        });
    }

    private startDataPolling(): void {
        this._dataPollingService.startEvent(this._dataPollingEvent);
    }

    private startStreams(): void {
        this._zone.runOutsideAngular(() => {
            this._targetStreamId = this.webSocketService.startStream(StreamTypeEnum.target);
        });
    }

    private startVideoStreams(serialNumber: string): void {
        this._zone.runOutsideAngular(() => {
            this._videoStreamId = this.webSocketService.startStream(StreamTypeEnum.video, serialNumber);
        });
    }

    private startWebWorker(): void {
        this._zone.runOutsideAngular(() => {
            if (!this.isNullOrUndefined(this._webWorker)) {
                this._webWorker.terminate();
                this._webWorker = null;
            }

            this._webWorker = new Worker(new URL('@rift/workers/counting-setup-line-save.worker', import.meta.url), { type: 'module' });

            this._webWorker.onerror = (event: ErrorEvent) => {
                this.openErrorDialog('Unable to update device', 'Error updating line data', event.error);
            };

            this._webWorker.onmessage = (event: MessageEvent) => {
                const message = event.data as IResponseMessage;
                switch (message.type) {
                    case ResponseTypeEnum.mismatched:
                        this.addSubscription(this.observableHandlerBase(this.openOkCancelDialog('Unable to update device', 'Data out of sync with device', false).afterClosed(), this.mismatchedProcess).subscribe(() => {
                            this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
                        }), this.mismatchedProcess);
                        break;
                    case ResponseTypeEnum.error:
                        const error: any = new Error('Error updating line data');
                        error.response = message.errorResponse;
                        error.status = message.errorStatus;
                        error.statusText = message.errorStatusText;

                        this.addSubscription(this.observableHandlerBase(this.openErrorDialog('Unable to update device', 'Error updating line data', error, this.errorProcess).afterClosed(), this.errorProcess).subscribe(() => {
                            this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
                        }), this.errorProcess);
                        break;
                }
            };

            this._webWorker.postMessage({ type: RequestTypeEnum.setup, setup: { baseUrl: this.configurationService.riftV1RestApiBase, token: this._connectionTokenService.connectionToken } as ISetupData } as IRequestMessage);
        });
    }

    private stopDataPolling(): void {
        this._dataPollingService.stopEvent(this._dataPollingEvent);
    }

    private stopStreams(): void {
        this._zone.runOutsideAngular(() => {
            this.webSocketService.stopStream(StreamTypeEnum.target, this._targetStreamId);
        });
    }

    private stopVideoStreams(serialNumber: string): void {
        this._zone.runOutsideAngular(() => {
            this.webSocketService.stopStream(StreamTypeEnum.video, this._videoStreamId, serialNumber);
        });
    }

    private stopWebWorker(): void {
        this._zone.runOutsideAngular(() => {
            if (!this.isNullOrUndefined(this._webWorker)) {
                this._webWorker.terminate();
            }
        });
    }

    private subDataPolling(): void {
        this.addSubscription(this._dataPollingEvent.poll.subscribe(() => {
            if (this._histogramLogsLoaded) {
                this._histogramLogsLoaded = false;
                this.addSubscription(this.loadDataHistogramLogs().subscribe(), this.getHistogramLogsProcess);
            }
        }));
    }

    private updateEventsSubscribe(): void {
        this._zone.runOutsideAngular(() => {
            this._updateEventsSub = this.addSubscription(this.observableHandlerBase(merge(
                this._eventsService.onDeviceRegisterAdded,
                this._eventsService.onDeviceRegisterDeleted,
                this._eventsService.onDeviceRegisterFlip,
                this._eventsService.onDeviceRegisterReset,
                this._eventsService.onDeviceRegisterUpdated,
                this._eventsService.onDeviceRegisterCopied,
                this._eventsService.onDeviceRegisterSnapped,
                this._eventsService.onDeviceUpdated,
            ), this.updateEventsProcess).subscribe(() => {
                this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
            }), this.updateEventsProcess);
        });
    }

    private updateEventsUnsubscribe(): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._updateEventsSub)) {
                this._updateEventsSub.unsubscribe();
            }
            if (!isNullOrUndefined(this._updateDeviceEventsSub)) {
                this._updateDeviceEventsSub.unsubscribe();
            }
        });
    }
}
