import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { DeviceActivityModel } from '@em/models/restapi/DeviceActivity.Model';
import { DeviceActivitySummaryModel } from '@em/models/restapi/DeviceActivitySummary.Model';
import { DeviceHealthCountsCollectionModel } from '@em/models/restapi/DeviceHealthCountsCollectionModel';
import { DeviceTraceCollectionModel } from '@em/models/restapi/DeviceTraceCollection.Model';
import { FirmwareSummaryCollectionModel } from '@em/models/restapi/FirmwareSummaryCollection.Model';
import { MetaDataKeyCollectionModel } from '@em/models/restapi/MetaDataKeyCollectionModel';
import { MetaDataMapCollectionModel } from '@em/models/restapi/MetaDataMapCollection.Model';
import { PageModel } from '@em/models/restapi/Page.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { QuickLinkMetricsModel } from '@em/models/restapi/QuickLinkMetrics.Model';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { UpdateLocationModel } from '@em/models/restapi/UpdateLocation.Model';
import { EmRestApiService } from '@em/service/restapi/base/EmRestApi.Service';
import { BaseModel } from '@shared/base/Base.Model';
import { DeviceActivityActionEnum, DeviceActivityActionEnumHelpers } from '@shared/enum/DeviceActivityAction.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { AdvancedSearchResultCollectionModel } from '@em/models/restapi/AdvancedSearchResultCollection.Model';
import { IFileResponse, IRetryOptions } from '@shared/service/restapi/RestApi.Service';
import { BridgeAddressModel } from '@em/models/restapi/BridgeAddress.Model';
import { BridgeConnectionModel } from '@em/models/restapi/BridgeConnection.Model';
import { MatDialog } from '@angular/material/dialog';
import { DeviceConnectionStatusModel } from '@em/models/restapi/DeviceConnectionStatus.Model';
import { DeviceGroupEnum } from '@shared/enum/DeviceGroup.Enum';
import { DeviceLiveDataRemovalModel } from '@em/models/restapi/DeviceLiveDataRemoval.Model';


class GetDeviceResult extends BaseModel implements IRestModel {

    public device: DeviceModel;
    public readonly isIRestModel = true;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        this.device = RestModelUtility.loadFrom(restModel.Device, DeviceModel);
    }

    public toRestApiModel(): any {
        throw new Error('Method not implemented.');
    }
}

class MetaDataUpdateModel extends BaseModel implements IRestModel {
    public readonly isIRestModel = true;

    public metaDataMap: MetaDataMapCollectionModel = new MetaDataMapCollectionModel();
    public serialNumber: number = null;

    public constructor(
        private readonly _metaDataMap: MetaDataMapCollectionModel,
        private readonly _serialNumber: number) {
        super();

        this.metaDataMap = this._metaDataMap;
        this.serialNumber = this._serialNumber;
    }

    public loadFromRestApiModel(restModel: any): void {
        throw new Error('Method not implemented.');
    }

    public toRestApiModel(): any {
        return {
            MetaDataMap: RestModelUtility.toJsonArray(this.metaDataMap.items),
            DeviceID: this.serialNumber,
        };
    }
}

class SearchValueModel extends BaseModel implements IRestModel {
    public readonly isIRestModel = true;

    public searchParameter: string;

    public constructor(
        private readonly _searchParameter: string) {
        super();
        this.searchParameter = this._searchParameter;
    }

    public loadFromRestApiModel(restModel: any): void {
        throw new Error('Method not implemented.');
    }

    public toRestApiModel(): any {
        return {
            searchParameter: this.searchParameter,
        };
    }
}

class DeviceBackupData extends BaseModel implements IRestModel {
    public data: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        this.data = restModel.Data;
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}

@Injectable()
export class RestApiDeviceService extends EmRestApiService {
    private _controller = 'device/';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.emRestApiBase, _httpClient);
    }

    public addGroupRecording(groupId: DeviceGroupEnum | number, plannedStartTime: Date, recordingDuration: number, recordingDurationIsMinutes: boolean, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post(`${this._controller}addgrouprecording`, { groupId, plannedStartTime: DateTimeUtility.toISOFormatWithoutOffset(plannedStartTime), recordingDuration, recordingDurationIsMinutes }, ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteDevice(friendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post(`${this._controller}deletedevice/${friendlySerial}`, null, ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDeviceActivityBinaryData(friendlySerial: string, activityId: number, process?: ProcessMonitorServiceProcess): Observable<IFileResponse> {
        return this.getFile(`${this._controller}getdeviceactivitybinarydata/${friendlySerial}/${activityId}`, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getBackupData(friendlySerial: string, activityId: number, process?: ProcessMonitorServiceProcess): Observable<string> {
        return this.get(`${this._controller}getdevicebackupdata/${friendlySerial}/${activityId}`, DeviceBackupData, null, process).pipe(map(result => result.data)).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getBackup(friendlySerial: string, activityId: number, process?: ProcessMonitorServiceProcess): Observable<IFileResponse> {
        return this.getFile(`${this._controller}getdevicebackup/${friendlySerial}/${activityId}`, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getBridgeConnectionDetails(friendlySerial: string, address: BridgeAddressModel, process?: ProcessMonitorServiceProcess): Observable<BridgeConnectionModel> {
        return this.post(`${this._controller}getbridgeconnection/${friendlySerial}`, address, BridgeConnectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public advancedSearch(value: string, process?: ProcessMonitorServiceProcess): Observable<AdvancedSearchResultCollectionModel> {
        return this.post<SearchValueModel, AdvancedSearchResultCollectionModel>(`${this._controller}advancedsearch`, new SearchValueModel(value), AdvancedSearchResultCollectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDevice(friendlySerial: string, includeNodes: boolean = false, process?: ProcessMonitorServiceProcess): Observable<DeviceModel> {
        let params = new HttpParams();
        params = params.append('includeNodes', includeNodes.toString());

        return this.get<GetDeviceResult>(`${this._controller}get/${friendlySerial}`, GetDeviceResult, params, process).pipe(
            map(result => {
                if (!isNullOrUndefined(result) && !isNullOrUndefined(result.device)) {
                    return result.device;
                } else {
                    return null;
                }
            })
        ).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDeviceConnectionStatus(friendlySerial: string, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<DeviceConnectionStatusModel> {
        return this.get<DeviceConnectionStatusModel>(`${this._controller}device_connection_status/${friendlySerial}`, DeviceConnectionStatusModel, null, process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDeviceLiveDataRemovalState(friendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<DeviceLiveDataRemovalModel> {
        return this.get<DeviceLiveDataRemovalModel>(`${this._controller}delete_live_data_state/${friendlySerial}`, DeviceLiveDataRemovalModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public addOrUpdateLiveDataRemovalState(data: DeviceLiveDataRemovalModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel>{
        return this.post<DeviceLiveDataRemovalModel, ResultModel>(`${this._controller}delete_live_data_state`, data, ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getMetaDataKeys(process?: ProcessMonitorServiceProcess): Observable<MetaDataKeyCollectionModel> {
        return this.get<MetaDataKeyCollectionModel>(`${this._controller}metadatakeys`, MetaDataKeyCollectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateMetaDataKeys(metaDataMaps: MetaDataMapCollectionModel, serialNumber: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<MetaDataUpdateModel, ResultModel>(`${this._controller}editdevicemetadata`, new MetaDataUpdateModel(metaDataMaps, serialNumber), ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getQuickLinks(process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<QuickLinkMetricsModel> {
        return this.get<QuickLinkMetricsModel>(`${this._controller}quicklinks`, QuickLinkMetricsModel, null, process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getFirmwareVersionSummary(process?: ProcessMonitorServiceProcess): Observable<FirmwareSummaryCollectionModel> {
        return this.get<FirmwareSummaryCollectionModel>(`${this._controller}getdevicefirmwareversionsummary`, FirmwareSummaryCollectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getHealth(from: Date, to: Date, pageIndex: number, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<DeviceHealthCountsCollectionModel> {
        let params = new HttpParams();
        params = params.append('from', DateTimeNonMomentUtility.toRestApiDateTime(from));
        params = params.append('to', DateTimeNonMomentUtility.toRestApiDateTime(to));
        params = params.append('pageIndex', StringUtility.toString(pageIndex));

        return this.get<DeviceHealthCountsCollectionModel>(`${this._controller}health`, DeviceHealthCountsCollectionModel, params, process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getTraceRoute(friendlySerial: string, from: Date, to: Date, process?: ProcessMonitorServiceProcess): Observable<DeviceTraceCollectionModel> {
        let params = new HttpParams();
        params = params.append('from', DateTimeNonMomentUtility.toRestApiDateTime(from));
        params = params.append('to', DateTimeNonMomentUtility.toRestApiDateTime(to));

        return this.get<DeviceTraceCollectionModel>(`${this._controller}devicetrace/${friendlySerial}`, DeviceTraceCollectionModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getActivitySummary(friendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<DeviceActivitySummaryModel> {
        return this.get<DeviceActivitySummaryModel>(`${this._controller}getdeviceactivitysummary/${friendlySerial}`, DeviceActivitySummaryModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getActivityPage(friendlySerial: string, paginationOptions?: PaginationOptionsModel, fromDate?: Date, actions?: Array<DeviceActivityActionEnum>, process?: ProcessMonitorServiceProcess): Observable<PageModel<DeviceActivityModel>> {
        let params = new HttpParams();
        params = this.setPaginationOptionsParams(paginationOptions, params);
        if (!isNullOrUndefined(actions) && actions.length > 0) {
            actions.forEach(action => params = params.append('actions', DeviceActivityActionEnumHelpers.toRestApi(action)));
        }
        if (!isNullOrUndefined(fromDate)) {
            params = params.append('fromDate', DateTimeNonMomentUtility.toRestApiDateTime(fromDate));
        }

        return this.getPage<DeviceActivityModel, PageModel<DeviceActivityModel>>(`${this._controller}getdeviceactivity/${friendlySerial}`, DeviceActivityModel, PageModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateLocation(location: UpdateLocationModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<UpdateLocationModel, ResultModel>(`${this._controller}editdevicelocation`, location, ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getActivityCount(friendlySerial: string, from: Date, to: Date, process?: ProcessMonitorServiceProcess): Observable<number> {
        let params = new HttpParams();
        params = params.append('from', DateTimeNonMomentUtility.toRestApiDateTime(from));
        params = params.append('to', DateTimeNonMomentUtility.toRestApiDateTime(to));

        return this.get<number>(`${this._controller}deviceactivitycount/${friendlySerial}`, 'number', params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getFailedScheduleCount(friendlySerial: string, from: Date, to: Date, process?: ProcessMonitorServiceProcess): Observable<number> {
        let params = new HttpParams();
        params = params.append('from', DateTimeNonMomentUtility.toRestApiDateTime(from));
        params = params.append('to', DateTimeNonMomentUtility.toRestApiDateTime(to));

        return this.get<number>(`${this._controller}devicefailedschedulecount/${friendlySerial}`, 'number', params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getErrorCount(friendlySerial: string, from: Date, to: Date, process?: ProcessMonitorServiceProcess): Observable<number> {
        let params = new HttpParams();
        params = params.append('from', DateTimeNonMomentUtility.toRestApiDateTime(from));
        params = params.append('to', DateTimeNonMomentUtility.toRestApiDateTime(to));

        return this.get<number>(`${this._controller}deviceerrorcount/${friendlySerial}`, 'number', params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getConnectsCount(friendlySerial: string, from: Date, to: Date, process?: ProcessMonitorServiceProcess): Observable<number> {
        let params = new HttpParams();
        params = params.append('from', DateTimeNonMomentUtility.toRestApiDateTime(from));
        params = params.append('to', DateTimeNonMomentUtility.toRestApiDateTime(to));

        return this.get<number>(`${this._controller}deviceactivitycount/${friendlySerial}`, 'number', params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getVideo(friendlySerial: string, videoEntryId: number, process?: ProcessMonitorServiceProcess): Observable<IFileResponse> {
        let params = new HttpParams();
        params = params.append('serialNumber', friendlySerial);
        params = params.append('videoEntryId', videoEntryId.toString());

        return this.getFile(`${this._controller}downloadvideo`, params, process, 'arraybuffer').pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
