import { Component, HostBinding, Injector, Input, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsCountingMenuBaseComponent } from '@rift/components/settings/counting/base/Settings.Counting.MenuBase.Component';
import { HistogramConfigModel } from '@rift/models/restapi/HistogramConfig.Model';
import { HistogramLogModel } from '@rift/models/restapi/HistogramLog.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { IPosition } from 'angular2-draggable';
import { ISize } from 'angular2-draggable/lib/models/size';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';

class HistogramChartViewModel {
    public config: HistogramConfigModel;
}

@Component({
    selector: 'rift-settings-counting-histogram-chart',
    templateUrl: './Settings.Counting.HistogramChart.Component.html',
    styleUrls: ['./Settings.Counting.HistogramChart.Component.scss'],
})
export class SettingsCountingHistogramChartComponent extends SettingsCountingMenuBaseComponent implements OnChanges {
    public static className: string = 'SettingsCountingHistogramChartComponent';

    @Input()
    public get bounds(): HTMLElement {
        return this._bounds;
    }
    public set bounds(value: HTMLElement) {
        this._bounds = value;
        this.checkPosition();
    }

    @Input()
    public get zIndex(): number {
        return this._zIndex;
    }
    public set zIndex(value: number) {
        this._zIndex = value;
    }

    @HostBinding()
    public id: string = 'rift-settings-counting-histogram-chart';

    public charts: Array<HistogramChartViewModel> = [];

    @Input()
    public registerCount: number;

    @Input()
    public register: RegisterBaseModel;

    @Input()
    public timeSetup: TimeSetupModel;

    @Input()
    public histogramLogs: Array<HistogramLogModel>;

    @LocalStorage(SettingsCountingHistogramChartComponent.className, 'position')
    public position: IPosition;

    public size: ISize;

    public show: boolean = false;
    public isLoading: boolean = false;

    public constructor(
        private readonly _dataPollingService: DataPollingService,
        private readonly _render: Renderer2,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_render, _injector, _dialog);

        this.minWidth = 600;

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingHistogramChartComponent.className, this.loadDataProcessText);
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingHistogramChartComponent.className, this.loadDataProcessText);

        this.initConnectionState();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this.isLoading = true;
            this.charts = this.register.histogramConfigs.map(config => {
                const vm = new HistogramChartViewModel();
                vm.config = config;
                return vm;
            });
            this.isLoading = false;
        }
    }
}
