<div class="em-settings-outboundconnections-addeditremovegroup-title"
    mat-dialog-title>
    {{ mode === 'add' ? 'Add' : mode === 'edit' ? 'Edit' : mode === 'remove' ? 'Remove': 'Select' }} Group
</div>

<!-- Add Group -->
<ng-template [ngIf]="mode === 'add'">
    <form [formGroup]="addFormGroup">
        <div class="em-settings-outboundconnections-addeditremovegroup-content"
            mat-dialog-content
            fxLayout="column">
            <mat-form-field fxFlex>
                <input #name
                    formControlName="name"
                    matInput
                    placeholder="Name">
                <mat-error>{{getFormControlErrorMessage(addFormGroup.controls['name'])}}</mat-error>
                <mat-hint align="end">{{name.value.length}} / 128</mat-hint>
            </mat-form-field>
        </div>
        <div class="em-settings-outboundconnections-addeditremovegroup-actions"
            mat-dialog-actions
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <button (click)="add()"
                mat-raised-button
                color="primary"
                [disabled]="!addFormGroup.valid">Add</button>
            <div fxFlex></div>
            <button (click)="cancel()"
                mat-raised-button
                color="warn">Close</button>
        </div>
    </form>
</ng-template>

<!-- Edit Group -->
<ng-template [ngIf]="mode === 'edit'">
    <form [formGroup]="editFormGroup">
        <div class="em-settings-outboundconnections-addeditremovegroup-content"
            mat-dialog-content
            fxLayout="column">
            <mat-form-field fxFlex>
                <input #name
                    formControlName="name"
                    matInput
                    placeholder="Name">
                <span matSuffix>{{ group?.propertyHasChangesText('name') }}</span>
                <mat-error>{{getFormControlErrorMessage(editFormGroup.controls['name'])}}</mat-error>
                <mat-hint align="end">{{name.value.length}} / 128</mat-hint>
            </mat-form-field>
        </div>
        <div class="em-settings-outboundconnections-addeditremovegroup-actions"
            mat-dialog-actions
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <button (click)="save()"
                mat-raised-button
                color="primary"
                [disabled]="!editFormGroup.valid">Update</button>
            <div fxFlex></div>
            <button (click)="cancel()"
                mat-raised-button
                color="warn">Close</button>
        </div>
    </form>
</ng-template>

<!-- Remove Group -->
<ng-template [ngIf]="mode === 'remove'">
    <form [formGroup]="removeFormGroup">
        <div class="em-settings-outboundconnections-addeditremovegroup-content"
            mat-dialog-content
            fxLayout="column">
            <mat-form-field>
                <mat-select #group
                    formControlName="group"
                    placeholder="Group">
                    <mat-option *ngFor="let group of groups?.items | orderBy: 'name'"
                        [value]="group">
                        {{ group.name }}
                    </mat-option>
                </mat-select>
                <mat-error>{{getFormControlErrorMessage(removeFormGroup.controls['group'])}}</mat-error>
            </mat-form-field>
        </div>
        <div class="em-settings-outboundconnections-addeditremovegroup-actions"
            mat-dialog-actions
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <button (click)="remove()"
                mat-raised-button
                color="primary"
                [disabled]="!removeFormGroup.valid">Remove</button>
            <div fxFlex></div>
            <button (click)="cancel()"
                mat-raised-button
                color="warn">Close</button>
        </div>
    </form>
</ng-template>

<!-- Select Group -->
<ng-template [ngIf]="mode === 'select'">
    <form [formGroup]="selectFormGroup">
        <div class="em-settings-outboundconnections-addeditremovegroup-content"
            mat-dialog-content
            fxLayout="column">
            <mat-form-field>
                <mat-select #group
                    formControlName="group"
                    placeholder="Group">
                    <mat-option value="ungrouped">Ungrouped</mat-option>
                    <mat-option *ngFor="let group of groups?.items | orderBy: 'name'"
                        [value]="group"
                        [disabled]="isNullOrUndefined(group.connectionGroupId)">
                        {{ group.name }} {{ isNullOrUndefined(group.connectionGroupId) ? '(Please Save Changes)' : '' }}
                    </mat-option>
                </mat-select>
                <mat-error>{{getFormControlErrorMessage(selectFormGroup.controls['group'])}}</mat-error>
            </mat-form-field>
        </div>
        <div class="em-settings-outboundconnections-addeditremovegroup-actions"
            mat-dialog-actions
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <button (click)="select()"
                mat-raised-button
                color="primary"
                [disabled]="!selectFormGroup.valid">Select</button>
            <div fxFlex></div>
            <button (click)="cancel()"
                mat-raised-button
                color="warn">Close</button>
        </div>
    </form>
</ng-template>