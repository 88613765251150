<div #container
    *ngIf="show === true && canShow === true"
    class="float-dialog rift-settings-counting-histogram-chart"
    (click)="onMenuClick()"
    ngDraggable
    #draggable="ngDraggable"
    (started)="onDrStarted($event)"
    (stopped)="onDrStopped($event)"
    (edge)="onDrEdge($event)"
    (movingOffset)="onDrMovingOffset($event)"
    (endOffset)="onDrEndOffset($event)"
    [handle]="title"
    [bounds]="bounds"
    [position]="position"
    [inBounds]="!isNullOrUndefined(bounds)">
    <div #title
        class="title drag-handle"
        fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex
            fxLayout="row"
            fxLayoutAlign="start center">
            <div fxLayout="column">
                <div class="header"
                    fxLayout="row">
                    Histograms ({{ register.registerName }}) (Midnight to present)
                    <shr-background-load-spinner [trigger]="isLoading"
                        class="spacing-left">
                    </shr-background-load-spinner>
                </div>
                <div class="timezone-text">
                    Time Zone ({{ isNullOrUndefined(timeSetup) ? 'Loading' : timeSetup.timeZone.timeOffsetShortText }})
                </div>
            </div>
            <div fxFlex></div>
            <div class="actions">
                <button class="flat-icon-button"
                    mat-stroked-button
                    matTooltip="Close"
                    (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="content"
        fxLayout="column">
        <div class="charts">
            <div *ngFor="let chart of charts">
                <rift-histogram-chart [histogramLogs]="histogramLogs"
                    [titleShowOfCounts]="true"
                    [register]="register"
                    [registerCount]="registerCount"
                    [config]="chart.config">
                </rift-histogram-chart>
            </div>
        </div>
    </div>
</div>