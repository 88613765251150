import { AfterViewInit, Component, HostBinding, Inject, Injector } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

export class RegisterNameDialogData {
    public constructor(public registers: Array<RegisterBaseModel>) { }
}

export class RegisterNameDialogResult {
    public constructor(public name?: string) { }
}

@Component({
    selector: 'rift-settings.counting-register-name',
    templateUrl: './Settings.Counting.RegisterName.Component.html',
    styleUrls: ['./Settings.Counting.RegisterName.Component.scss'],
})
export class SettingsCountingRegisterNameComponent extends RiftBaseComponent implements AfterViewInit {
    public static className: string = 'SettingsCountingRegisterNameComponent';

    public formGroup: FormGroup;

    @HostBinding()
    public id: string = 'rift-restore-backup';

    public constructor(
        @Inject(MAT_DIALOG_DATA) private readonly data: RegisterNameDialogData,
        private readonly _dialogRef: MatDialogRef<SettingsCountingRegisterNameComponent>,
        private readonly _dialog: MatDialog,
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingRegisterNameComponent.className, this.loadDataProcessText);

        this.formGroup = this._formBuilder.group({
            name: ['', Validators.compose([Validators.required, Validators.maxLength(23), ValidationValidators.unique(this.getRegisterNames.bind(this))])],
        });

        this.initConnectionState();
    }

    public getRegisterNames(): Array<string> {
        return this.data.registers.map(i => i.registerName);
    }

    public onCancelClick(): void {
        this._dialogRef.close(new RegisterNameDialogResult());
    }

    public onOkClick(): void {
        this._dialogRef.close(new RegisterNameDialogResult(this.formGroup.controls.name.value));
    }
}
