import { Component, Input, Injector, HostBinding } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';

/**
 * Simple process running spinner component.
 *
 * @export
 * @class ProcessRunningComponent
 * @extends {BaseComponent}
 */
@Component({
    selector: 'shr-process-running',
    templateUrl: './ProcessRunning.Component.html',
    styleUrls: ['./ProcessRunning.Component.scss']
})
export class ProcessRunningComponent extends BaseComponent {
    @HostBinding()
    public id: string = 'shr-process-running';

    @Input()
    public process: ProcessMonitorServiceProcess = null;

    @Input()
    public diameter: number;

    public constructor(
        private readonly _injector: Injector,
    ) {
        super(_injector);
    }
}
