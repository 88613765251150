import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ContentChild, HostBinding, Injector, TemplateRef } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';
import { EXPANSION_PANEL_ANIMATION_TIMING } from '@angular/material/expansion';

@Component({
    selector: 'em-navmenu-expand-item',
    templateUrl: './NavMenu.ExpandItem.Component.html',
    styleUrls: ['./NavMenu.ExpandItem.Component.scss'],
    animations: [
        trigger('bodyExpansion', [
            state('collapsed, void', style({ height: '0px', 'min-height': '0px', visibility: 'hidden' })),
            state('expanded', style({ height: '*', 'min-height': '*', visibility: 'visible' })),
            transition('expanded <=> collapsed, void => collapsed',
                animate(EXPANSION_PANEL_ANIMATION_TIMING)),
        ])
    ],
})
export class NavMenuExpandItemComponent extends BaseComponent {
    public static className: string = 'NavMenuExpandItemComponent';

    @HostBinding()
    public id: string = 'em-navmenu-expand-item';

    @ContentChild('expandItemContent', { static: true })
    public expandItemContent: TemplateRef<any>;

    @ContentChild('expandItemHeaderCollapsed', { static: true })
    public expandItemHeaderCollapsed: TemplateRef<any>;

    @ContentChild('expandItemHeaderExpanded', { static: true })
    public expandItemHeaderExpanded: TemplateRef<any>;

    private _expanded: boolean = false;

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
    }

    public collapse(): void {
        this._expanded = false;
    }

    public expand(): void {
        this._expanded = true;
    }

    public get expanded(): boolean {
        return this._expanded;
    }
}
