<div shrResized
    (resized)="onContentResize()"
    id="rift-settings-counting"
    #mainContent
    class="rift-settings-counting no-select"
    fxLayout="column">
    <mat-toolbar class="toolbar"
        [ngStyle]="{ height: toolbarHeight + 'px' }"
        fxLayout="row"
        fxLayoutAlign="start center">
        <div fxLayout="row"
            fxLayoutAlign="start center">
            <button class="flat-icon-button"
                mat-stroked-button
                matTooltip="Full screen"
                (click)="onFullScreenClick()">
                <mat-icon>zoom_out_map</mat-icon>
            </button>
            <mat-slide-toggle *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.video) | async) && isReadOnly === false && inAlignmentMode === false"
                (change)="onViewPortModeChange($event)">
                <div fxShow.gt-xs="false"
                    fxShow.lt-sm="true">
                    {{ (isDeviceCapable(DeviceCapabilitiesEnum.video) | async) === true ?
                        viewPort.mode === ViewPortModeEnum.view ? "Video" : "Edit" :
                        viewPort.mode === ViewPortModeEnum.view ? "View" : "Edit" }}
                </div>
                <div fxShow.gt-xs="true"
                    fxShow.lt-sm="false">
                    {{ (isDeviceCapable(DeviceCapabilitiesEnum.video) | async) === true ?
                        viewPort.mode === ViewPortModeEnum.view ? "Video View Mode" : "Edit Mode: Video Not Available" :
                        viewPort.mode === ViewPortModeEnum.view ? "View Mode" : "Edit Mode" }}
                </div>
            </mat-slide-toggle>
            <div fxLayout="row"
                fxLayoutAlign="start center"
                class="spacing-left"
                *ngIf="viewPort.mode === ViewPortModeEnum.edit && inAlignmentMode === false">
                <button class="flat-icon-button"
                    mat-stroked-button
                    matTooltip="Moves a Line or Polygon to align with another"
                    (click)="startSnap()">
                    <shr-fa name="link"></shr-fa>
                </button>
                <button class="flat-icon-button"
                    mat-stroked-button
                    matTooltip="Flip a Line"
                    (click)="startFlip()">
                    <shr-fa name="random"></shr-fa>
                </button>
                <button *ngIf="!isNullOrUndefined(quickAction)"
                    class="flat-icon-button"
                    mat-stroked-button
                    matTooltip="Stop Action"
                    (click)="stopQuickAction()">
                    <shr-fa name="stop"></shr-fa>
                </button>
                <div class="action-message">
                    {{ quickActionMessage }}
                </div>
            </div>
            <div *ngIf="connectionService.isOffline === true">
                <div fxShow.gt-sm="false"
                    fxShow.lt-md="true">
                    <span class="offline-header">
                        DB view
                    </span>
                </div>
                <div fxShow.gt-sm="true"
                    fxShow.lt-md="false">
                    <span class="offline-header">
                        You are in database view
                    </span>
                    <span class="offline-subheader">
                        (read only, no video or targets)
                    </span>
                </div>
            </div>
            <div fxShow.gt-xs="true"
                fxShow.lt-sm="false"
                *ngIf="emUnLicensed === false && connectionService.isOffline === true && navBarService.emDeviceConnected === true"
                class="spacing-left">
                <button (click)="goOnline()"
                    mat-raised-button>Go Live</button>
            </div>
        </div>
        <div fxFlex></div>
        <div fxLayout="row"
            fxLayoutAlign="start center"
            style="position: relative;">            
            <button class="flat-icon-button"
                mat-stroked-button
                matTooltip="Registers"
                (click)="toggleMenuShow(registersMenu)" 
                *ngIf="inAlignmentMode === false">
                <img [src]="'./assets/icons/register.png'" />
            </button>
            <button class="flat-icon-button"
                mat-stroked-button
                matTooltip="Devices"
                (click)="toggleMenuShow(devicesMenu)"
                *ngIf="inAlignmentMode === false">
                <img style="background-image: url('./assets/icons/device.png')"
                    *ngIf="isNullOrUndefined(getDeviceGenString() | async)"
                    [src]="'./assets/icons/device.png'" />
                <img style="background-image: url('./assets/icons/device.png')"
                    *ngIf="!isNullOrUndefined(getDeviceGenString() | async)"
                    [src]="'./assets/icons/device-' + (getDeviceGenString() | async) + '.png'" />
            </button>
            <button class="flat-icon-button"
                mat-stroked-button
                matTooltip="Global Height Filter"
                (click)="toggleMenuShow(globalHeightFilterMenu)"
                *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.height) | async) === true && inAlignmentMode === false">
                <shr-fa name="arrows-alt-v"></shr-fa>
            </button>
            <button class="flat-icon-button"
                mat-stroked-button
                matTooltip="Tracking"
                (click)="toggleMenuShow(trackingMenu)"
                *ngIf="((isDeviceGen(UnitGenerationEnum.gen4) | async) === true || (isDeviceGen(UnitGenerationEnum.gazelle) | async) === true) && inAlignmentMode === false">
                <mat-icon>memory</mat-icon>
            </button>
            <button class="flat-icon-button"
                mat-stroked-button
                matTooltip="Advanced Settings"
                (click)="toggleMenuShow(advancedSettingsMenu)"
                *ngIf="((isDeviceGen(UnitGenerationEnum.gen4) | async) === true || (isDeviceGen(UnitGenerationEnum.gazelle) | async) === true) && inAlignmentMode === false">
                <mat-icon>settings</mat-icon>
            </button>
            <button class="flat-icon-button"
                fxShow.gt-xs="true"
                fxShow.lt-sm="false"
                mat-stroked-button
                matTooltip="Zoom In"
                (click)="onZoomInClick()">
                <mat-icon>zoom_in</mat-icon>
            </button>
            <button class="flat-icon-button"
                fxShow.gt-xs="true"
                fxShow.lt-sm="false"
                mat-stroked-button
                matTooltip="Zoom Out"
                (click)="onZoomOutClick()">
                <mat-icon>zoom_out</mat-icon>
            </button>
            <button class="flat-icon-button"
                mat-stroked-button
                matTooltip="Alignment"
                (click)="toggleAlignmentMode()"
                *ngIf="devices?.length > 1 && connectionService.isOnline === true && allDevicesHighResCapable === true">
                <shr-fa name="sliders"></shr-fa>
            </button>
            <button class="flat-icon-button path-map-drop-down-button"
                mat-stroked-button
                matTooltip="Path Map"
                (mouseenter)="onPathMapDropDownMouseEnter()"
                *ngIf="connectionService.isOnline === true && inAlignmentMode === false && (isDeviceCapable(DeviceCapabilitiesEnum.pathMap) | async) === true">
                <shr-fa name="map"></shr-fa>
            </button>
            <div class="path-map-drop-down"
                fxLayout="column"
                fxLayoutAlign="space-around center"
                (mouseleave)="onPathMapDropDownMouseLeave()"
                [ngStyle]="{'visibility': pathMapDropDownShow === true ? 'visible' : 'hidden'}">
                <button class="flat-icon-button drop-down-button"
                    mat-stroked-button
                    matTooltip="Path Map"
                    (click)="onPathMapDropDownClick()"
                    *ngIf="connectionService.isOnline === true && (isDeviceCapable(DeviceCapabilitiesEnum.pathMap) | async) === true">
                    <shr-fa name="map"></shr-fa>
                </button>
                <button class="flat-icon-button drop-down-button"
                    mat-stroked-button
                    [matTooltip]="viewPort.showPathMap === true ? 'Hide' : 'Show'"
                    (click)="onTogglePathMapClick()"
                    *ngIf="connectionService.isOnline === true && (isDeviceCapable(DeviceCapabilitiesEnum.pathMap) | async) === true">
                    <shr-fa *ngIf="viewPort.showPathMap === false"
                        name="eye"></shr-fa>
                    <shr-fa *ngIf="viewPort.showPathMap === true"
                        name="eye-slash"></shr-fa>
                </button>
                <button class="flat-icon-button drop-down-button"
                    mat-stroked-button
                    matTooltip="Reset"
                    (click)="onResetPathMapClick()"
                    *ngIf="connectionService.isOnline === true && (isDeviceCapable(DeviceCapabilitiesEnum.pathMap) | async) === true">
                    <shr-fa name="sync"></shr-fa>
                </button>
            </div>
        </div>
    </mat-toolbar>
    <div class="viewPortContent"
        #viewPortContent>
        <rift-settings-counting-registers #registersMenu
            (broughtToFront)="onMenuBroughtToFront($event)"
            (registerSelected)="registerSelected($event)"
            (showAllRegister)="showAllRegister()"
            (hideAllRegister)="hideAllRegister()"
            [registers]="registers"
            [editingRegister]="editRegister"
            [zIndex]="registersMenuZIndex"
            [bounds]="viewPortContent"
            [addRegisterDisabled]="addRegisterMenu?.show"
            [selectedRegister]="selectedRegister"
            [editRegisterDisabled]="editRegisterMenu?.show"
            (countChanged)="onCountChanged($event)"
            (addRegister)="onAddRegister()"
            (showHideRegister)="onShowHideRegister($event)"
            (showHideHistogram)="onShowHideHistogram($event)"
            (editRegister)="onEditRegister($event)">
        </rift-settings-counting-registers>

        <rift-settings-counting-add-register #addRegisterMenu
            (broughtToFront)="onMenuBroughtToFront($event)"
            [masterDevice]="masterDevice"
            [registers]="registers"
            [lines]="lines"
            [polygons]="polygons"
            [zIndex]="addRegisterMenuZIndex"
            [bounds]="viewPortContent">
        </rift-settings-counting-add-register>

        <rift-settings-counting-edit-register #editRegisterMenu
            (broughtToFront)="onMenuBroughtToFront($event)"
            [masterDevice]="masterDevice"
            [registers]="registers"
            [lines]="lines"
            [polygons]="polygons"
            [editRegister]="editRegister"
            [zIndex]="editRegisterMenuZIndex"
            [bounds]="viewPortContent"
            (closed)="onEditRegisterMenuClose()">
        </rift-settings-counting-edit-register>

        <rift-settings-counting-edit-device #editDeviceMenu
            (broughtToFront)="onMenuBroughtToFront($event)"
            [editDevice]="editDevice"
            [zIndex]="editDeviceMenuZIndex"
            [bounds]="viewPortContent"
            (closed)="onEditDeviceMenuClose()"
            (realTimeUpdate)="onDeviceRealTimeUpdate($event)">
        </rift-settings-counting-edit-device>

        <rift-settings-counting-devices #devicesMenu
            (broughtToFront)="onMenuBroughtToFront($event)"
            [editDeviceDisabled]="editDeviceMenu?.show"
            [editingDevice]="editDevice"
            [selectedDevice]="editDevice"
            [devices]="devices"
            [bounds]="viewPortContent"
            [zIndex]="devicesMenuZIndex"
            [getDeviceVideoPlayState]="getDeviceVideoPlayState.bind(this)"
            (editDevice)="onEditDevice($event)"
            (toggleDeviceVideo)="onToggleDeviceVideo($event)"
            (deviceSelected)="onDeviceSelected($event)">
        </rift-settings-counting-devices>        

        <rift-settings-counting-histogram-chart #histogramChartMenu
            (broughtToFront)="onMenuBroughtToFront($event)"
            [histogramLogs]="histogramLogs"
            [register]="histogramRegister"
            [registerCount]="histogramRegisterCount"
            [timeSetup]="timeSetup"
            [zIndex]="histogramChartZIndex"
            [bounds]="viewPortContent">
        </rift-settings-counting-histogram-chart>

        <rift-settings-counting-tracking #trackingMenu
            *ngIf="(isDeviceGen(UnitGenerationEnum.gen4) | async) === true || (isDeviceGen(UnitGenerationEnum.gazelle) | async) === true"
            (broughtToFront)="onMenuBroughtToFront($event)"
            [zIndex]="trackingMenuZIndex"
            [bounds]="viewPortContent">
        </rift-settings-counting-tracking>

        <rift-settings-counting-global-height-filter #globalHeightFilterMenu
            *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.height) | async) === true"
            (broughtToFront)="onMenuBroughtToFront($event)"
            [zIndex]="globalHeightFilterMenuZIndex"
            [bounds]="viewPortContent">
        </rift-settings-counting-global-height-filter>

        <rift-settings-counting-advanced-settings #advancedSettingsMenu
            *ngIf="(isDeviceGen(UnitGenerationEnum.gen4) | async) === true || (isDeviceGen(UnitGenerationEnum.gazelle) | async) === true"
            (broughtToFront)="onMenuBroughtToFront($event)"
            [zIndex]="advancedSettingsMenuMenuZIndex"
            [bounds]="viewPortContent">
        </rift-settings-counting-advanced-settings>

        <rift-multi-unit-alignment-device-list #alignmentDeviceList
            (broughtToFront)="onMenuBroughtToFront($event)"
            (toggleVisibility)="multiUnitAlignment.toggleVideoVisibilty($event)"
            (alphaChanged)="multiUnitAlignment.alphaChanged($event)"
            (xPosChanged)="multiUnitAlignment.xPosChanged($event)"
            (yPosChanged)="multiUnitAlignment.yPosChanged($event)"
            (yawChanged)="multiUnitAlignment.yawChanged($event)"
            (deviceSelected)="multiUnitAlignment.deviceSelected($event)"
            (saveChanges)="multiUnitAlignment.saveChanges()"
            (separateDevices)="multiUnitAlignment.separateDevices()"
            (exitAlignment)="toggleAlignmentMode()"
            [devices]="multiUnitAlignment.devices"
            [bounds]="viewPortContent"
            [zIndex]="devicesMenuZIndex">
        </rift-multi-unit-alignment-device-list>

        <rift-viewport [hidden]="inAlignmentMode === true" #viewPort></rift-viewport>
        <rift-multi-unit-alignment [hidden]="inAlignmentMode === false" #multiUnitAlignment></rift-multi-unit-alignment>
    </div>
</div>
