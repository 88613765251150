<div fxLayout="column"
    fxLayoutAlign="center center">
    <div class="title"
        fxLayout="row">
        Your browser is incompatible please use on of the following browsers.
    </div>
    <div *ngIf="!isNullOrUndefined(BrowserUtility.browser)"
        class="browser-current"
        fxLayout="row">
        You are using {{ BrowserUtility.browser.displayName }} version {{ BrowserUtility.version }}
    </div>
    <div class="browser-useragent"
        fxLayout="row">
        You user agent is {{ BrowserUtility.userAgent }}
    </div>
    <div class="browser-row"
        fxLayout="row"
        fxLayoutAlign="start center"
        *ngFor="let browser of BrowserUtility.compatibleBrowsers;">
        <div class="logo"
            *ngIf="!isNullOrUndefined(browser.logoImg)">
            <img [src]="browser.logoImg">
        </div>
        <div class="name spacing-left">
            <a *ngIf="!isNullOrUndefined(browser.downloadLink)"
                [href]="browser.downloadLink">{{ browser.displayName }}</a>
            <span *ngIf="isNullOrUndefined(browser.downloadLink)">{{ browser.displayName }}</span>
        </div>
        <div class="version"
            *ngIf="!isNullOrUndefined(browser.compatibleFrom)">
            &nbsp;<span>version {{browser.compatibleFrom}} or greater</span>
        </div>
    </div>
</div>