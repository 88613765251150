import { Component, HostBinding, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsTaskLauncherRunComponent, SettingsTaskLauncherRunDialogData } from '@em/components/settings/tasklauncher/tasklauncherrun/Settings.TaskLauncher.Run.Component';
import { SettingsTaskLauncherUploadComponent, SettingsTaskLauncherUploadDialogData } from '@em/components/settings/tasklauncher/tasklauncherupload/Settings.TaskLauncher.Upload.Component';
import { SettingsTaskLauncherViewComponent, SettingsTaskLauncherViewDialogData } from '@em/components/settings/tasklauncher/tasklauncherview/Settings.TaskLauncher.View.Component';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { TaskToExecuteModel } from '@em/models/restapi/TaskToExecute.Model';
import { SettingService } from '@em/service/data/setting/Setting.Service';
import { TaskService } from '@em/service/data/task/Task.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { TaskToExecuteStateEnum, TaskToExecuteStateEnumHelpers } from '@shared/enum/TaskToExecuteState.Enum';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { EventsService } from '@shared/service/events/Events.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { SettingsTaskLauncherDeleteComponent, SettingsTaskLauncherDeleteDialogData } from '@em/components/settings/tasklauncher/tasklauncherdelete/Settings.TaskLauncher.Delete.Component';

@Component({
    selector: 'em-settings-task-launcher-list',
    templateUrl: './Settings.TaskLauncher.List.Component.html',
    styleUrls: ['./Settings.TaskLauncher.List.Component.scss']
})
export class SettingsTaskLauncherListComponent extends BaseComponent implements OnDestroy, OnInit {
    public static className: string = 'SettingsTaskLauncherListComponent';
    public TaskToExecuteStateEnum = TaskToExecuteStateEnum;
    public TaskToExecuteStateEnumHelpers = TaskToExecuteStateEnumHelpers;
    public dataPollingProcess: ProcessMonitorServiceProcess;
    public dataSource = new MatTableDataSource<TaskToExecuteModel>();
    public dataSourcePaused = new MatTableDataSource<TaskToExecuteModel>();
    public dataSourceQueued = new MatTableDataSource<TaskToExecuteModel>();
    public deleteTaskProcess: ProcessMonitorServiceProcess;
    public displayedColumns = ['icons', 'finished', 'description', 'device', 'status', 'duration'];
    public getTaskPageProcess: ProcessMonitorServiceProcess;
    @ViewChild(MatPaginator, { static: true })
    public paginator: MatPaginator;
    public mode: 'active' | 'failed' | 'disconnected' | 'complete';
    @HostBinding()
    public id: string = 'em-settings-task-launcher-list';
    public optionsAction: NavBarAction;
    public deleteTaskPackageAction: NavBarAction;
    public optionsTaskProcess: ProcessMonitorServiceProcess;
    public pageOptions: PaginationOptionsModel;
    public pageOptionsPaused: PaginationOptionsModel;
    public pageOptionsQueued: PaginationOptionsModel;
    public refreshingTaskPageProcess: ProcessMonitorServiceProcess;
    public routerParamsProcess: ProcessMonitorServiceProcess;
    public runAction: NavBarAction;
    public runTaskProcess: ProcessMonitorServiceProcess;
    public taskChangeProcess: ProcessMonitorServiceProcess;
    public updateTaskProcess: ProcessMonitorServiceProcess;
    public uploadAction: NavBarAction;
    public uploadTaskProcess: ProcessMonitorServiceProcess;
    public activeCount: number = 0;
    public failedCount: number = 0;
    public disconnectedCount: number = 0;
    public completeCount: number = 0;
    public getCountsProcess: ProcessMonitorServiceProcess;
    public getSettingsProcess: ProcessMonitorServiceProcess;
    public deleteTaskPackageProcess: ProcessMonitorServiceProcess;
    public enabled: boolean;

    private _dataPollingEvent: DataPollingEvent;

    public constructor(
        private readonly _settingService: SettingService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _dataPollingService: DataPollingService,
        private readonly _dialog: MatDialog,
        private readonly _eventsService: EventsService,
        private readonly _navBarService: NavBarActionService,
        private readonly _router: Router,
        private readonly _taskService: TaskService,
        private readonly _userService: UserCurrentService,
        private readonly _injector: Injector) {
        super(_injector);

        this.runTaskProcess = this.processMonitorService.getProcess(SettingsTaskLauncherListComponent.className, 'Run task.');
        this.uploadTaskProcess = this.processMonitorService.getProcess(SettingsTaskLauncherListComponent.className, 'Upload task');
        this.optionsTaskProcess = this.processMonitorService.getProcess(SettingsTaskLauncherListComponent.className, 'Options task.');
        this.routerParamsProcess = this.processMonitorService.getProcess(SettingsTaskLauncherListComponent.className, 'Router params change');
        this.dataPollingProcess = this.processMonitorService.getProcess(SettingsTaskLauncherListComponent.className, 'Data polling');
        this.taskChangeProcess = this.processMonitorService.getProcess(SettingsTaskLauncherListComponent.className, 'Task changed');
        this.getTaskPageProcess = this.processMonitorService.getProcess(SettingsTaskLauncherListComponent.className, 'Getting tasks page.');
        this.refreshingTaskPageProcess = this.processMonitorService.getProcess(SettingsTaskLauncherListComponent.className, 'Refreshing tasks page.');
        this.deleteTaskProcess = this.processMonitorService.getProcess(SettingsTaskLauncherListComponent.className, 'Deleting task.');
        this.getCountsProcess = this.processMonitorService.getProcess(SettingsTaskLauncherListComponent.className, 'Getting task counts.');
        this.getSettingsProcess = this.processMonitorService.getProcess(SettingsTaskLauncherListComponent.className, 'Getting settings.');
        this.deleteTaskPackageProcess = this.processMonitorService.getProcess(SettingsTaskLauncherListComponent.className, 'Delete Task Package.');

        this._dataPollingEvent = new DataPollingEvent('SettingsTaskLauncherListComponent', 10000, 10000, this.refreshingTaskPageProcess);

        this.runAction = new NavBarAction();
        this.runAction.name = 'runtask';
        this.runAction.text = 'Run Task';
        this.addSubscription(this.observableHandlerBase(this.runAction.onButtonClick, this.runTaskProcess).subscribe(() => { this.run(); }), this.runTaskProcess);

        this.addSubscription(this.observableHandlerBase(this.userCurrentService.isSystemAdmin, null).subscribe(isSystemAdmin => {
            if (isSystemAdmin) {
                this.uploadAction = new NavBarAction();
                this.uploadAction.name = 'uploadtask';
                this.uploadAction.text = 'Upload Task Package';
                this.addSubscription(this.observableHandlerBase(this.uploadAction.onButtonClick, this.uploadTaskProcess).subscribe(() => { this.upload(); }), this.uploadTaskProcess);

                this.optionsAction = new NavBarAction();
                this.optionsAction.name = 'optionstask';
                this.optionsAction.text = 'Task Options';
                this.addSubscription(this.observableHandlerBase(this.optionsAction.onButtonClick, this.optionsTaskProcess).subscribe(() => { this.options(); }), this.optionsTaskProcess);

                this.deleteTaskPackageAction = new NavBarAction();
                this.deleteTaskPackageAction.name = 'deletetask';
                this.deleteTaskPackageAction.text = 'Delete Task Package';
                this.addSubscription(this.observableHandlerBase(this.deleteTaskPackageAction.onButtonClick, this.deleteTaskPackageProcess).subscribe(() => { this.deleteTaskPackage(); }), this.deleteTaskPackageProcess);
            }
        }), null);

        this.pageOptions = new PaginationOptionsModel();
        this.pageOptions.page = 1;
        this.pageOptions.resultsPerPage = 10;

        this.pageOptionsPaused = new PaginationOptionsModel();
        this.pageOptionsPaused.page = 1;
        this.pageOptionsPaused.resultsPerPage = 10;

        this.pageOptionsQueued = new PaginationOptionsModel();
        this.pageOptionsQueued.page = 1;
        this.pageOptionsQueued.resultsPerPage = 10;

        this.addSubscription(this.observableHandlerBase(this._settingService.getSetting('TaskLauncherEnabled', 'TaskLauncherSettings', this.getSettingsProcess), this.getSettingsProcess).subscribe(
            result => {
                if (!this.isNullOrUndefined(result)) {
                    this.enabled = coerceBooleanProperty(result.value.toLocaleLowerCase());
                }
            }
        ), this.getSettingsProcess);

        this.addSubscription(this.observableHandlerBase(this._activatedRoute.params, this.routerParamsProcess).subscribe(params => {
            if (this.isNullOrUndefined(params.state)) {
                this.mode = 'active';
            } else {
                this.mode = params.state;
            }

            this.pageOptions.page = 1;
            this.pageOptionsPaused.page = 1;
            this.pageOptionsQueued.page = 1;

            this.getPage(this.getTaskPageProcess);
        }), this.routerParamsProcess);

        this.startDataPolling();

        this.addSubscription(this.observableHandlerBase(this._eventsService.onTasksChanged, this.taskChangeProcess).subscribe(() => {
            this._dataPollingService.pushBack(this._dataPollingEvent);
            this.getPage(this.refreshingTaskPageProcess);
        }), this.taskChangeProcess);
    }

    public dataExportGetData = (displayedColumn: string, data: TaskToExecuteModel) => {
        switch (displayedColumn) {
            case 'icons':
                return data.isCurrentlyExecuting === true ? 'Executing' : 'Idle';
            case 'finished':
                return this.mode === 'active' ? data.timeAddedText : data.timeFinishedText;
            case 'description':
                return data.taskName;
            case 'device':
                return data.friendlyDeviceSerial;
            case 'status':
                return TaskToExecuteStateEnumHelpers.toStringHumanized(data.taskState);
            case 'icons':
                return data.isCurrentlyExecuting === true ? 'Executing' : data.durationText;
            default:
                return data[displayedColumn];
        }
    };

    public dataExportGetHeader = (displayedColumn: string) => {
        switch (displayedColumn) {
            case 'icons':
                return 'state';
            case 'finished':
                return 'added/finished';
            default:
                return displayedColumn;
        }
    };

    public delete(task: TaskToExecuteModel): void {
        if (!this.isNullOrUndefined(task)) {
            this.addSubscription(this.observableHandlerBase(this._taskService.deleteTask(task, this.deleteTaskProcess), this.deleteTaskProcess).subscribe(
                result => {
                    this._eventsService.changedTasks();
                }
            ), this.deleteTaskProcess);
        }
    }

    public getPage(process: ProcessMonitorServiceProcess): void {
        this.updateCounts();

        switch (this.mode) {
            case 'active':
                this.addSubscription(this.observableHandlerBase(this.getTaskPage(TaskToExecuteStateEnum.executing, this.pageOptions, this.dataSource, process), process).subscribe(
                    result => {
                        this.pageOptions = result.pageOptions;
                        this.dataSource = result.dataSource;
                    }
                ), process);

                this.addSubscription(this.observableHandlerBase(this.getTaskPage(TaskToExecuteStateEnum.failedExecutionPaused, this.pageOptionsPaused, this.dataSourcePaused, process), process).subscribe(
                    result => {
                        this.pageOptionsPaused = result.pageOptions;
                        this.dataSourcePaused = result.dataSource;
                    }
                ), process);


                this.addSubscription(this.observableHandlerBase(this.getTaskPage(TaskToExecuteStateEnum.readyToExecute, this.pageOptionsQueued, this.dataSourceQueued, process), process).subscribe(
                    result => {
                        this.pageOptionsQueued = result.pageOptions;
                        this.dataSourceQueued = result.dataSource;
                    }
                ), process);
                break;
            case 'complete':
                this.addSubscription(this.observableHandlerBase(this.getTaskPage(TaskToExecuteStateEnum.passedExecution, this.pageOptions, this.dataSource, process), process).subscribe(
                    result => {
                        this.pageOptions = result.pageOptions;
                        this.dataSource = result.dataSource;
                    }
                ), process);
                break;
            case 'disconnected':
                this.addSubscription(this.observableHandlerBase(this.getTaskPage(TaskToExecuteStateEnum.disconnected, this.pageOptions, this.dataSource, process), process).subscribe(
                    result => {
                        this.pageOptions = result.pageOptions;
                        this.dataSource = result.dataSource;
                    }
                ), process);
                break;
            case 'failed':
                this.addSubscription(this.observableHandlerBase(this.getTaskPage(TaskToExecuteStateEnum.failedExecution, this.pageOptions, this.dataSource, process), process).subscribe(
                    result => {
                        this.pageOptions = result.pageOptions;
                        this.dataSource = result.dataSource;
                    }
                ), process);
                break;
        }
    }

    public getTaskPage(state: TaskToExecuteStateEnum, pageOptions: PaginationOptionsModel, dataSource: MatTableDataSource<TaskToExecuteModel>, process: ProcessMonitorServiceProcess): Observable<{ pageOptions: PaginationOptionsModel; dataSource: MatTableDataSource<TaskToExecuteModel> }> {
        return this._taskService.getTaskPage(state, pageOptions, process).pipe(
            map(page => {
                pageOptions.totalResults = page.options.totalResults;
                dataSource.data = page.items;

                return {
                    pageOptions,
                    dataSource,
                };
            })
        );
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this.stopDataPolling();

        this._navBarService.removeAction(this.runAction);
        this._navBarService.removeAction(this.uploadAction);
        this._navBarService.removeAction(this.optionsAction);
        this._navBarService.removeAction(this.deleteTaskPackageAction);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this._navBarService.addAction(this.runAction);
        this._navBarService.addAction(this.uploadAction);
        this._navBarService.addAction(this.optionsAction);
        this._navBarService.addAction(this.deleteTaskPackageAction);
    }

    public onPageChanged(event: PageEvent): void {
        this.pageOptions.page = (event.pageIndex + 1);
        this.pageOptions.resultsPerPage = event.pageSize;
        this.getPage(this.getTaskPageProcess);
    }

    public onPageChangedPaused(event: PageEvent): void {
        this.pageOptionsPaused.page = (event.pageIndex + 1);
        this.pageOptionsPaused.resultsPerPage = event.pageSize;
        this.getPage(this.getTaskPageProcess);
    }

    public onPageChangedeQueued(event: PageEvent): void {
        this.pageOptionsQueued.page = (event.pageIndex + 1);
        this.pageOptionsQueued.resultsPerPage = event.pageSize;
        this.getPage(this.getTaskPageProcess);
    }

    public options(): void {
        this._router.navigateByUrl('/settings/options/tasklauncher');
    }

    public retry(task: TaskToExecuteModel): void {
        if (!this.isNullOrUndefined(task)) {
            task.taskState = TaskToExecuteStateEnum.readyToExecute;
            this.updateTask(task);
        }
    }

    public run(): void {
        this._dialog.open(SettingsTaskLauncherRunComponent, { data: new SettingsTaskLauncherRunDialogData(), minWidth: 500, maxWidth: 500 });
    }

    public skip(task: TaskToExecuteModel): void {
        if (!this.isNullOrUndefined(task)) {
            const dataTask = this.dataSourcePaused.data[0];
            dataTask.taskState = TaskToExecuteStateEnum.failedExecution;
            this.updateTask(dataTask);
        }
    }

    public updateTask(task: TaskToExecuteModel): void {
        this.addSubscription(this.observableHandlerBase(this._taskService.editTask(task, this.updateTaskProcess), this.updateTaskProcess).subscribe(
            result => {
                this._eventsService.changedTasks();
            }
        ), this.updateTaskProcess);
    }

    public upload(): void {
        this._dialog.open(SettingsTaskLauncherUploadComponent, { data: new SettingsTaskLauncherUploadDialogData() });
    }

    public deleteTaskPackage(): void{
        this._dialog.open(SettingsTaskLauncherDeleteComponent, { data: new SettingsTaskLauncherDeleteDialogData() });
    }

    public view(task: TaskToExecuteModel): void {
        this._dialog.open(SettingsTaskLauncherViewComponent, { data: new SettingsTaskLauncherViewDialogData(task) });
    }

    private updateCounts(): void {
        this.activeCount = 0;
        this.failedCount = 0;
        this.disconnectedCount = 0;
        this.completeCount = 0;

        this.addSubscription(this.observableHandlerBase(this._taskService.getTaskCounts(this.getCountsProcess), this.getCountsProcess).subscribe(
            counts => {
                counts
                    .filter(c =>
                        c.state === TaskToExecuteStateEnum.executing ||
                        c.state === TaskToExecuteStateEnum.failedExecutionPaused ||
                        c.state === TaskToExecuteStateEnum.readyToExecute
                    ).forEach(c => this.activeCount += c.count);


                counts
                    .filter(c =>
                        c.state === TaskToExecuteStateEnum.failedExecution
                    ).forEach(c => this.failedCount += c.count);


                counts
                    .filter(c =>
                        c.state === TaskToExecuteStateEnum.disconnected
                    ).forEach(c => this.disconnectedCount += c.count);


                counts
                    .filter(c =>
                        c.state === TaskToExecuteStateEnum.passedExecution
                    ).forEach(c => this.completeCount += c.count);
            }
        ), this.getCountsProcess);
    }

    private subDataPolling(): void {
        this.addSubscription(this.observableHandlerBase(this._dataPollingEvent.poll, this.dataPollingProcess).subscribe(() => {
            this._taskService.clearCache();
            this.getPage(this.refreshingTaskPageProcess);
        }), this.dataPollingProcess);
    }

    private startDataPolling(): void {
        this._dataPollingService.startEvent(this._dataPollingEvent);
        this.subDataPolling();
    }

    private stopDataPolling(): void {
        this._dataPollingService.stopEvent(this._dataPollingEvent);
    }
}
