import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';
import { REGISTER_BASE_EXCLUDE_PROPERTIES, RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IRegisterRemoteReceiverModel extends IRegisterBaseModel {
    remoteReceiverIndex: number;
}

export class RegisterRemoteReceiverModel extends RegisterBaseModel implements IRegisterRemoteReceiverModel, IRestModel {
    public error: string = null;
    public statusCode: number = null;
    public remoteReceiverIndex: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterRemoteReceiverModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel()
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], [], []);
    }
}
