<div #container *ngIf="show === true && canShow === true" class="float-dialog rift-settings-counting-edit-devices"
    (click)="onMenuClick()" ngDraggable #draggable="ngDraggable" (started)="onDrStarted($event)"
    (stopped)="onDrStopped($event)" (edge)="onDrEdge($event)" (movingOffset)="onDrMovingOffset($event)"
    (endOffset)="onDrEndOffset($event)" [handle]="title" [bounds]="bounds" [position]="position"
    [inBounds]="!isNullOrUndefined(bounds)">
    <div #title class="title drag-handle" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column">
                <div class="header">
                    Edit Device ({{ editDevice?.serialNumber }}) ({{ editDevice?.master ? 'Master' : 'Node' }})
                </div>
                <div class="subheader">
                    <div class="details" fxLayout="row" fxLayoutAlign="start center">
                        <div class="value">
                            Ver: {{ editDevice?.mainImageVersion }}
                        </div>
                        <div class="value">
                            Lens: {{ DeviceLensTypeEnumHelpers.toString(editDevice?.lensType) }}
                        </div>
                    </div>
                </div>
            </div>
            <div fxFlex></div>
            <div class="actions">
                <button class="flat-icon-button"
                    [disabled]="saveAllChangesProcess.isRunning === true || autoDetectHeightProcess.isRunning === true || refreshTiltProcess.isRunning === true"
                    mat-stroked-button matTooltip="Close" (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="content" fxLayout="column">
        <form [formGroup]="form" fxFlex>
            <div class="edit-content"
            style="max-height: 300px; overflow: auto;">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field fxFlex="50" class="spacing-right">
                        <input riftUnitsOfMeasurementInput #xInput=riftUnitsOfMeasurementInput formControlName="x"
                            placeholder="X" [metricMin]="-10000" [metricMax]="10000"
                            [metricUnit]="UnitOfMeasurementEnum.centimeter" [imperialUnit]="UnitOfMeasurementEnum.inch">
                        <span matSuffix>
                            {{xInput.suffix}}
                        </span>
                        <mat-error>{{getFormControlErrorMessage(form.controls['x'])}}</mat-error>
                        <span matSuffix>{{ editDevice?.propertyHasChangesText('x') }}</span>
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <input riftUnitsOfMeasurementInput #yInput=riftUnitsOfMeasurementInput formControlName="y"
                            placeholder="Y" [metricMin]="-10000" [metricMax]="10000"
                            [metricUnit]="UnitOfMeasurementEnum.centimeter" [imperialUnit]="UnitOfMeasurementEnum.inch">
                        <span matSuffix>
                            {{yInput.suffix}}
                        </span>
                        <mat-error>{{getFormControlErrorMessage(form.controls['y'])}}</mat-error>
                        <span matSuffix>{{ editDevice?.propertyHasChangesText('y') }}</span>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field fxFlex>
                        <input riftUnitsOfMeasurementInput #heightInput=riftUnitsOfMeasurementInput
                            formControlName="height" placeholder="Height"
                            [metricMin]="isNullOrUndefined(editDevice) ? 0 : HeightRanges[editDevice.lensType].overallMin"
                            [metricMax]="isNullOrUndefined(editDevice) ? 0 : HeightRanges[editDevice.lensType].overallMax"
                            [metricUnit]="UnitOfMeasurementEnum.centimeter" [imperialUnit]="UnitOfMeasurementEnum.inch">
                        <span matSuffix>
                            {{heightInput.suffix}}
                        </span>
                        <mat-error>{{getFormControlErrorMessage(form.controls['height'])}}</mat-error>
                        <span matSuffix>{{ editDevice?.propertyHasChangesText('height') }}</span>
                    </mat-form-field>
                    <div class="spacing-left" fxLayout="row" fxLayoutAlign="start center"
                        *ngIf="editDevice?.isCapable(DeviceCapabilitiesEnum.height)">
                        <shr-progress-button-spinner [raised]="true" [diameter]="30" text="Auto Detect" mode="indeterminate"
                            [disabled]="isReadOnly === true || refreshTiltProcess.isRunning === true"
                            [active]="autoDetectHeightProcess.isRunning" (shrClick)="onAutoDetectClick()"
                            color="primary">
                        </shr-progress-button-spinner>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" *ngIf="isTiltCapable">
                    <mat-form-field fxFlex>
                        <input matInput formControlName="yaw" type="number" placeholder="Direction Angle" step="0.1">
                        <span matSuffix>&#176;</span>
                        <mat-error>{{getFormControlErrorMessage(form.controls['yaw'])}}</mat-error>
                    </mat-form-field>
                    <div fxLayout="column" class="pitch-roll">
                        <div>
                            Pitch: {{ pitch }}&#176;
                        </div>
                        <div>
                            Roll: {{ roll }}&#176;
                        </div>
                    </div>
                    <div>
                        <shr-progress-button-spinner [raised]="true" [diameter]="30" text="Refresh Tilt"
                            mode="indeterminate"
                            [disabled]="isReadOnly === true || autoDetectHeightProcess.isRunning === true"
                            [active]="refreshTiltProcess.isRunning" (shrClick)="onRefreshTiltClick()" color="primary">
                        </shr-progress-button-spinner>
                    </div>
                </div>
                <div *ngIf="editDevice?.isCapable(DeviceCapabilitiesEnum.height)" style="padding-bottom: 20px;">
                    Device Status: {{ editDevice?.autoHeightMessage }}
                </div>
                <mat-expansion-panel
                *ngIf="editDevice?.isCapable(DeviceCapabilitiesEnum.timeOfFlightFrameRate)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Advanced
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field style="width: 100%">
                        <mat-label>Time of Flight Frame Rate</mat-label>
                        <mat-select [(value)]="editDevice.timeOfFlightFrameRate">
                            <mat-option *ngFor="let tofFrame of tofFrameRates" [value]="tofFrame.id">
                                {{tofFrame.name}}
                            </mat-option>
                        </mat-select>
                        <div style="padding-top: 20px;">
                            This setting allows you to modify the frame rate at which the device
                            operates. This should only be modified under guidance from Irisys.
                        </div>
                    </mat-form-field>
                </mat-expansion-panel>
            </div>
            <div class="actions">
                <button mat-raised-button (click)="onSaveDeviceClick()" type="submit"
                    [disabled]="isNullOrUndefined(editDevice) || isValid === false || hasChanges === false">
                    Save Device
                </button>
            </div>
        </form>
    </div>
    
</div>