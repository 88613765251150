import { CountModel, ICountModel } from '@rift/models/restapi/Count.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface ICountLogModel extends IRestModel {
    timestamp: Date;
    logEntryId: string;
    counts: Array<ICountModel>;
}

export class CountLogModel extends BaseModel implements ICountLogModel, IRestModel {

    public counts: Array<CountModel> = null;

    public error: string = null;

    public timestamp: Date = null;
    public logEntryId: string = null;
    public readonly isIRestModel = true;
    public statusCode: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-CountLogModel');

        this.timestamp = DateTimeNonMomentUtility.fromRestApiDateTime(this.timestamp);

        this.counts = RestModelUtility.loadFromArray(restModel.Counts, CountModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
