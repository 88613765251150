import { Component, EventEmitter, HostBinding, HostListener, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { UserDeviceDisplayColumnModel } from '@em/models/restapi/UserDeviceDisplayColumn.Model';
import { MetaDataKeysService } from '@em/service/data/metadatakeys/MetaDataKeys.Service';
import { UserDeviceListColumnService } from '@em/service/data/user/User.DeviceList.Column.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { INavBarMenuComponent } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { StringUtility } from '@shared/utility/String.Utility';

export class Column {
    public metaDataKeyId?: number;
    public name: string;
    public selected = false;
    public text: string;

    public constructor(name: string, text: string, metaDataKeyId?: number) {
        this.name = name;
        this.text = text;
        this.metaDataKeyId = metaDataKeyId;
    }
}

@Component({
    selector: 'em-device-list-select-columns',
    templateUrl: './DeviceList.SelectColumns.Component.html',
    styleUrls: ['./DeviceList.SelectColumns.Component.scss']
})
export class DeviceListSelectColumnsComponent extends BaseComponent implements INavBarMenuComponent, OnInit {
    public static className: string = 'DeviceListSelectColumnsComponent';

    @HostBinding()
    public id: string = 'em-device-list-select-columns';

    public closeMenu: EventEmitter<null> = new EventEmitter();

    public columns: Array<Column> = [
        new Column('DeviceId', 'Device Id'),
        new Column('DeviceName', 'Device Name'),
        new Column('FirmwareVersion', 'Firmware Version'),
        new Column('FriendlySerial', 'Serial #'),
        new Column('IPAddress', 'IP Address'),
        new Column('LastConnected', 'Last Connected'),
        new Column('LocationName', 'Location'),
        new Column('MACAddress', 'MAC Address'),
        new Column('NodeCount', 'Node Count'),
        new Column('SiteId', 'Site Id'),
        new Column('SiteName', 'Site Name'),
        new Column('UserString', 'User String'),
    ];

    public getMetaDataKeysProcess: ProcessMonitorServiceProcess;
    public getUserColumnsProcess: ProcessMonitorServiceProcess;
    public saveUserColumnsProcess: ProcessMonitorServiceProcess;

    @Output()
    public selectionChange: EventEmitter<Column> = new EventEmitter();

    @ViewChild(MatSelectionList, { static: true })
    public columnSelect: MatSelectionList;

    constructor(
        private readonly _dialog: MatDialog,
        private readonly _userDeviceListColumnService: UserDeviceListColumnService,
        private readonly _metaDataKeysService: MetaDataKeysService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.getMetaDataKeysProcess = this.processMonitorService.getProcess(DeviceListSelectColumnsComponent.className, 'Getting meta data keys');
        this.getUserColumnsProcess = this.processMonitorService.getProcess(DeviceListSelectColumnsComponent.className, 'Getting user columns');
        this.saveUserColumnsProcess = this.processMonitorService.getProcess(DeviceListSelectColumnsComponent.className, 'Saving user columns');
    }

    public onSelectionChange(event: MatSelectionListChange): void {
        if(!this.isNullOrUndefined(event) && !this.isNullOrUndefined(event.options)){
            event.options.forEach(option =>{
                this.selectionChange.emit(option.value);
            });
        }
    }

    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        event.stopPropagation();
    }

    public save(): void {
        const saveData = this.columnSelect.selectedOptions.selected
            .map(option => {
                const column = option.value as Column;
                const model = new UserDeviceDisplayColumnModel();
                model.columnName = column.name;
                model.metaDataKeyId = column.metaDataKeyId;
                model.sorted = false;
                model.sortedAsc = true;
                return model;
            });

        this.addSubscription(this.observableHandlerBase(this._userDeviceListColumnService.update(saveData, this.saveUserColumnsProcess), this.saveUserColumnsProcess).subscribe(
            result => {
                this.closeMenu.emit();
            },
        ), this.saveUserColumnsProcess);
    }

    public cancel(): void {
        this.closeMenu.emit();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.addSubscription(this.observableHandlerBase(this._metaDataKeysService.getKeys(this.getMetaDataKeysProcess), this.getMetaDataKeysProcess).subscribe(
            metaDataKeys => {
                if (metaDataKeys.items.length > 0) {
                    this.columns = this.columns.concat(metaDataKeys.items.map(i => new Column(StringUtility.toString(i.metaDataKeyId), i.name, i.metaDataKeyId)));
                }

                this.addSubscription(this.observableHandlerBase(this._userDeviceListColumnService.get(this.getUserColumnsProcess), this.getUserColumnsProcess).subscribe(
                    userColumns => {
                        const columnsLength = this.columns.length;
                        const userColumnsLength = userColumns.length;
                        for (let ci = 0; ci < columnsLength; ci++) {
                            const column = this.columns[ci];

                            for (let uci = 0; uci < userColumnsLength; uci++) {
                                const userColumn = userColumns[uci];

                                if (column.name === userColumn.columnName || (!this.isNullOrUndefined(column.metaDataKeyId) && !this.isNullOrUndefined(userColumn.metaDataKeyId) && column.metaDataKeyId === userColumn.metaDataKeyId)) {
                                    column.selected = true;
                                    break;
                                }
                            }
                        }

                    },
                ), this.getUserColumnsProcess);
            },
        ), this.getMetaDataKeysProcess);
    }
}
