import { Component, HostBinding } from '@angular/core';
import { BrowserUtility } from '@shared/utility/Browser.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

@Component({
    selector: 'shr-browser-incompatible',
    templateUrl: './BrowserIncompatible.component.html',
    styleUrls: ['./BrowserIncompatible.component.scss']
})
export class BrowserIncompatibleComponent {
    public static className: string = 'EmComponent';

    public BrowserUtility = BrowserUtility;
    public isNullOrUndefined = isNullOrUndefined;

    @HostBinding()
    public id = 'app-root';

    public constructor() {
    }
}
