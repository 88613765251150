import { IObjectStore } from '@shared/service/Indexeddb/IObjectStore';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, Observer } from 'rxjs';

export class IndexedDBServiceBase {

    /**
     * Creates an instance of IndexedDBService.
     *
     * @memberof IndexedDBService
     */
    public constructor() {
    }

    public static getAllFromCursor<T>(request: IDBRequest): Observable<T[]> {
        return new Observable((observer: Observer<T[]>) => {
            const items = [];
            request.onsuccess = (event: any) => {
                const cursor = event.target.result;
                if (cursor) {
                    items.push(cursor.value);
                    cursor.continue();
                } else {
                    observer.next(items);
                    observer.complete();
                }
            };

            // Error.
            request.onerror = () => {
                const error = request.error;
                observer.error(error);
            };
        });
    }

    /**
     * Adds a record to the store.
     *
     * @template TItem The record type.
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @param {TItem} record The record to add
     * @returns {Observable<number>} An observable of autoIncrement value added.
     * @memberof IndexedDBService
     */
    public addRecord<TItem>(database: IDBDatabase, storeName: string, record: TItem, ignoreErrors?: Array<string>): Observable<number> {
        return new Observable((observer: Observer<number>) => {
            const transaction: IDBTransaction = database.transaction(storeName, 'readwrite');
            const store: IDBObjectStore = transaction.objectStore(storeName);
            const request: IDBRequest = store.add(record);

            // IDBRequest
            // Success.
            request.onsuccess = () => {
                const a = '';
            };

            // Error.
            request.onerror = () => {
                const error = request.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };

            // IDBTransaction
            // Success.
            transaction.oncomplete = () => {
                observer.next(request.result);
                observer.complete();
            };
            // Error.
            transaction.onerror = () => {
                const error = transaction.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };
        });
    }

    /**
     * Adds records to the store.
     *
     * @template TItem The record type.
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @param {Array<TItem>} records The records to add.
     * @returns {Observable<Array<number>>} An observable of autoIncrement values added.
     * @memberof IndexedDBService
     */
    public addRecords<TItem>(database: IDBDatabase, storeName: string, records: Array<TItem>, ignoreErrors?: Array<string>): Observable<Array<number>> {
        return new Observable((observer: Observer<Array<number>>) => {
            const recordsLength = records.length;
            if (recordsLength > 0) {
                const transaction: IDBTransaction = database.transaction(storeName, 'readwrite');
                const store: IDBObjectStore = transaction.objectStore(storeName);
                let recordIndex = 0;
                const keys: Array<number> = [];

                // IDBTransaction
                // Success.
                transaction.oncomplete = () => {
                    observer.next(keys);
                    observer.complete();
                };
                // Error.
                transaction.onerror = () => {
                    const error = transaction.error;
                    if (!this.ignoreError(error, ignoreErrors)) {
                        observer.error(error);
                    }
                };

                const addRecord = () => {
                    const record = records[recordIndex];
                    recordIndex++;

                    let request: IDBRequest;

                    try {
                        request = store.add(record);
                    } catch (error) {
                        if (!this.ignoreError(error, ignoreErrors)) {
                            observer.error(error);
                        }
                    }

                    // IDBRequest
                    // Success.
                    request.onsuccess = () => {
                        keys.push(request.result);

                        if (recordIndex < recordsLength) {
                            addRecord();
                        }
                    };

                    // Error.
                    request.onerror = () => {
                        const error = request.error;
                        if (!this.ignoreError(error, ignoreErrors)) {
                            observer.error(error);
                        }
                    };
                };

                addRecord();
            } else {
                observer.next([]);
                observer.complete();
            }
        });
    }

    /**
     * Adds records to the store, using a bulk operation.
     *
     * @template TItem The record type.
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @param {Array<TItem>} records The records to add.
     * @returns {Observable<Array<number>>} An observable of autoIncrement values added.
     * @memberof IndexedDBService
     */
     public addRecordsBulk<TItem>(database: IDBDatabase, storeName: string, records: Array<TItem>, ignoreErrors?: Array<string>): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            const recordsLength = records.length;
            if (recordsLength > 0) {
                const transaction: IDBTransaction = database.transaction(storeName, 'readwrite');
                const store: IDBObjectStore = transaction.objectStore(storeName);

                // IDBTransaction
                // Success.
                transaction.oncomplete = () => {
                    observer.next(true);
                    observer.complete();
                };
                // Error.
                transaction.onerror = () => {
                    const error = transaction.error;
                    if (!this.ignoreError(error, ignoreErrors)) {
                        observer.error(error);
                    }
                };

                for (let i = 0; i < recordsLength; i++){
                    store.add(records[i]);
                }

                (transaction as any).commit();

            } else {
                observer.next(true);
                observer.complete();
            }
        });
    }


    /**
     * Clears an object store.
     *
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @returns {Observable<IDBRequestReadyState>} An observable of readyState.
     * @memberof IndexedDBService
     */
    public clearObjectStore(database: IDBDatabase, storeName: string, ignoreErrors?: Array<string>): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            const transaction: IDBTransaction = database.transaction(storeName, 'readwrite');
            const store: IDBObjectStore = transaction.objectStore(storeName);
            const request: IDBRequest = store.clear(); // Clears the object store.

            // IDBRequest
            // Success.
            request.onsuccess = () => {
            };
            // Error.
            request.onerror = () => {
                const error = request.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };

            // IDBTransaction
            // Success.
            transaction.oncomplete = () => {
                observer.next(true);
                observer.complete();
            };
            // Error.
            transaction.onerror = () => {
                const error = transaction.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };
        });
    }

    /**
     * Closes the database.
     *
     * @param {IDBDatabase} database The IDBDatabase database.
     * @memberof IndexedDBService
     */
    public closeDB(database: IDBDatabase) {
        database.close();
    }

    /**
     * Delete the database.
     *
     * @param {string} dbName The database name to delete.
     * @returns {Observable<boolean>}
     * @memberof IndexedDBService
     */
    public delete(dbName: string, ignoreErrors?: Array<string>): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            const request: IDBOpenDBRequest = indexedDB.deleteDatabase(dbName);

            // Success.
            request.onsuccess = () => {
                observer.next(true);
                observer.complete();
            };

            // Error.
            request.onerror = () => {
                const error = request.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };
        });
    }

    /**
     * Deletes a record from the store.
     *
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @param {IDBKeyRange | number | string | Date | IDBArrayKey} key The key of the item to delete.
     * @returns {Observable<IDBRequestReadyState>} An observable of readyState.
     * @memberof IndexedDBService
     */
    public deleteRecord(database: IDBDatabase, storeName: string, key: IDBKeyRange | number | string | Date | Array<IDBValidKey>, ignoreErrors?: Array<string>): Observable<number> {
        return new Observable((observer: Observer<number>) => {
            const transaction: IDBTransaction = database.transaction(storeName, 'readwrite');
            const store: IDBObjectStore = transaction.objectStore(storeName);
            const request: IDBRequest = store.delete(key);

            // IDBRequest
            // Success.
            request.onsuccess = () => {
            };

            // Error.
            request.onerror = () => {
                const error = request.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };

            // IDBTransaction
            // Success.
            transaction.oncomplete = () => {
                observer.next(1);
                observer.complete();
            };
            // Error.
            transaction.onerror = () => {
                const error = transaction.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };
        });
    }

    /**
     * Deletes records from the store by index key.
     *
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @param {string} indexName The name of the index.
     * @param {(IDBKeyRange | number | string | Date | IDBArrayKey)} indexKey The index key value to find.
     * @returns {Observable<IDBRequestReadyState>} An observable of readyState.
     * @memberof IndexedDBService
     */
    public deleteRecordsByIndex(database: IDBDatabase, storeName: string, indexName: string, indexKey: IDBKeyRange | number | string | Date | Array<IDBValidKey>, onProgress?: (count: number, deleted: number) => void, ignoreErrors?: Array<string>): Observable<number> {
        return new Observable((observer: Observer<number>) => {
            try {
                const transaction: IDBTransaction = database.transaction(storeName, 'readwrite');
                const store: IDBObjectStore = transaction.objectStore(storeName);
                const index: IDBIndex = store.index(indexName);

                const request = index.openCursor(indexKey);

                const reportProgressEvery = 100;

                let deletedCount: number = 0;
                let count: number = 0;

                IndexedDBServiceBase.getAllFromCursor(request).subscribe(records => {
                    records.forEach(record => {
                        if (!isNullOrUndefined((record as any).id)) {
                            store.delete((record as any).id); // Deletes the record by the key.
                            deletedCount++;

                            if (!isNullOrUndefined(onProgress) && (deletedCount / reportProgressEvery) % 1 === 0) {
                                onProgress(count, deletedCount);
                            }
                        }
                    });
                });

                const countRequest = index.count(indexKey);
                countRequest.onsuccess = () => {
                    count = countRequest.result;
                };

                countRequest.onerror = () => {
                    const error = countRequest.error;
                    if (!this.ignoreError(error, ignoreErrors)) {
                        observer.error(error);
                    }
                };

                // Error.
                request.onerror = () => {
                    const error = request.error;
                    if (!this.ignoreError(error, ignoreErrors)) {
                        observer.error(error);
                    }
                };

                // IDBTransaction
                // Success.
                transaction.oncomplete = () => {
                    if (!isNullOrUndefined(onProgress)) {
                        onProgress(count, deletedCount);
                    }
                    observer.next(deletedCount);
                    observer.complete();
                };
                // Error.
                transaction.onerror = () => {
                    const error = transaction.error;
                    if (!this.ignoreError(error, ignoreErrors)) {
                        observer.error(error);
                    }
                };
            } catch (e) {
                throw e;
            }
        });
    }

    /**
     * Gets a record from the store by its index key.
     *
     * @template TItem The item type in the store.
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @param {string} indexName The name of the index.
     * @param {(IDBKeyRange | number | string | Date | IDBArrayKey)} indexKey The index key value to find.
     * @returns {Observable<TItem>} An observable of record.
     * @memberof IndexedDBService
     */
    public getRecordByIndex<TItem>(database: IDBDatabase, storeName: string, indexName: string, indexKey: IDBKeyRange | number | string | Date | Array<IDBValidKey>, ignoreErrors?: Array<string>): Observable<TItem> {
        return new Observable((observer: Observer<TItem>) => {
            const transaction: IDBTransaction = database.transaction(storeName, 'readonly');
            const store: IDBObjectStore = transaction.objectStore(storeName);
            const index = store.index(indexName);
            const request: IDBRequest = index.get(indexKey);

            // Success.
            request.onsuccess = () => {
            };

            // Error.
            request.onerror = () => {
                const error = request.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };

            // IDBTransaction
            // Success.
            transaction.oncomplete = () => {
                observer.next(request.result);
                observer.complete();
            };
            // Error.
            transaction.onerror = () => {
                const error = transaction.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };
        });
    }

    /**
     * Gets a record from the store by its key.
     *
     * @template TItem The item type in the store.
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @param {*} key The key value of the item to return
     * @returns {Observable<TItem>} An observable of record.
     * @memberof IndexedDBService
     */
    public getRecordByKey<TItem>(database: IDBDatabase, storeName: string, key: any, ignoreErrors?: Array<string>): Observable<TItem> {
        return new Observable((observer: Observer<TItem>) => {
            const transaction: IDBTransaction = database.transaction(storeName, 'readonly');
            const store: IDBObjectStore = transaction.objectStore(storeName);
            const request: IDBRequest = store.get(key);

            // Success.
            request.onsuccess = () => {
            };

            // Error.
            request.onerror = () => {
                const error = request.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };

            // IDBTransaction
            // Success.
            transaction.oncomplete = () => {
                observer.next(request.result);
                observer.complete();
            };
            // Error.
            transaction.onerror = () => {
                const error = transaction.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };
        });
    }

    /**
     * Gets all records from the store by index key.
     *
     * @template TItem The item type in the store.
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @param {string} indexName The name of the index.
     * @param {(IDBKeyRange | number | string | Date | IDBArrayKey)} indexKey The index key value to find.
     * @returns {Observable<Array<TItem>>} An observable of record.
     * @memberof IndexedDBService
     */
    public getRecordsByIndex<TItem>(database: IDBDatabase, storeName: string, indexName: string, indexKey: IDBKeyRange | number | string | Date | Array<IDBValidKey>, ignoreErrors?: Array<string>): Observable<Array<TItem>> {
        return new Observable((observer: Observer<Array<TItem>>) => {
            const transaction: IDBTransaction = database.transaction(storeName, 'readonly');
            const store: IDBObjectStore = transaction.objectStore(storeName);
            const index: IDBIndex = store.index(indexName);

            const request: IDBRequest = index.openCursor(indexKey);

            IndexedDBServiceBase.getAllFromCursor<TItem>(request).subscribe(items => observer.next(items));

            // Error.
            request.onerror = () => {
                const error = request.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };

            // IDBTransaction
            // Success.
            transaction.oncomplete = () => {
                observer.complete();
            };
            // Error.
            transaction.onerror = () => {
                const error = transaction.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };
        });
    }

    /**
     * Gets all records from the store.
     *
     * @template TItem The item type in the store.
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @returns {Observable<TItem>} An observable of record.
     * @memberof IndexedDBService
     */
    public getAllRecords<TItem>(database: IDBDatabase, storeName: string, ignoreErrors?: Array<string>): Observable<Array<TItem>> {
        return new Observable((observer: Observer<Array<TItem>>) => {
            const transaction: IDBTransaction = database.transaction(storeName, 'readonly');
            const store: IDBObjectStore = transaction.objectStore(storeName);
            const request: IDBRequest = store.openCursor();

            IndexedDBServiceBase.getAllFromCursor<TItem>(request).subscribe(items => observer.next(items));

            // Error.
            request.onerror = () => {
                const error = request.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };

            // IDBTransaction
            // Success.
            transaction.oncomplete = () => {
                observer.complete();
            };
            // Error.
            transaction.onerror = () => {
                const error = transaction.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };
        });
    }

    /**
     * Gets the count of records in a store that matc the indexName and indexKey.
     *
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @param {string} indexName The name of the index.
     * @param {(IDBKeyRange | number | string | Date | IDBArrayKey)} indexKey The index key value to find.
     * @returns {Observable<number>} An observable of number of records.
     * @memberof IndexedDBService
     */
    public getRecordCountByIndex(database: IDBDatabase, storeName: string, indexName: string, indexKey: IDBKeyRange | number | string | Date | Array<IDBValidKey>, ignoreErrors?: Array<string>): Observable<number> {
        return new Observable((observer: Observer<number>) => {
            const transaction: IDBTransaction = database.transaction(storeName, 'readonly');
            const store: IDBObjectStore = transaction.objectStore(storeName);
            const index = store.index(indexName);
            const request: IDBRequest = index.count(indexKey);

            // Success.
            request.onsuccess = () => {
            };

            // Error.
            request.onerror = () => {
                const error = request.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };

            // IDBTransaction
            // Success.
            transaction.oncomplete = () => {
                observer.next(request.result);
                observer.complete();
            };
            // Error.
            transaction.onerror = () => {
                const error = transaction.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };
        });
    }

    /**
     * Gets the count of records in a store.
     *
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @returns {Observable<number>} An observable of number of records.
     * @memberof IndexedDBService
     */
    public getAllRecordCount(database: IDBDatabase, storeName: string, ignoreErrors?: Array<string>): Observable<number> {
        return new Observable((observer: Observer<number>) => {
            const transaction: IDBTransaction = database.transaction(storeName, 'readonly');
            const store: IDBObjectStore = transaction.objectStore(storeName);
            const request: IDBRequest = store.count();

            // Success.
            request.onsuccess = () => {
            };

            // Error.
            request.onerror = () => {
                const error = request.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };

            // IDBTransaction
            // Success.
            transaction.oncomplete = () => {
                observer.next(request.result);
                observer.complete();
            };
            // Error.
            transaction.onerror = () => {
                const error = transaction.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };
        });
    }

    /**
     * Opens the database.
     *
     * @param {string} dbName The name of the database which identifies it within a specific origin.
     * @param {number} version The version of the database.
     * @param {Array<IObjectStore>} objectStores The IObjectStore used in the database.
     * @returns {Observable<IDBDatabase>} An observable of IDBDatabase.
     * @memberof IndexedDBService
     */
    public open(dbName: string, version: number, objectStores?: Array<(new () => IObjectStore)>, ignoreErrors?: Array<string>): Observable<IDBDatabase> {
        return new Observable((observer: Observer<IDBDatabase>) => {

            // Opens the database.
            const request: IDBOpenDBRequest = indexedDB.open(dbName, version);

            // Success.
            request.onsuccess = () => {
                // Instances the db object.
                const database: IDBDatabase = request.result;

                observer.next(database);
                observer.complete();
            };

            // Error.
            request.onerror = () => {
                const error = request.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };

            // The db doesn't exist, so create it.
            request.onupgradeneeded = () => {
                if (!isNullOrUndefined(objectStores)) {
                    // Instances the db object.
                    const database = request.result;
                    const dbStoreNames = database.objectStoreNames;

                    // Instances the ObjectStores class and calls the createStores method.
                    const length = objectStores.length;
                    for (let index = 0; index < length; index++) {
                        const staticStore: any = objectStores[index];
                        const store = new staticStore();

                        if (database.objectStoreNames.contains(staticStore.storeName)) {
                            database.deleteObjectStore(staticStore.storeName);
                        }

                        store.createStores(database);
                    }
                }
            };
        });
    }

    /**
     * Update or add records.
     *
     * @template TItem
     * @param {IDBDatabase} database
     * @param {string} storeName
     * @param {Array<TItem>} records
     * @param {Array<string>} [ignoreErrors]
     * @returns {Observable<Array<number>>}
     * @memberof IndexedDBServiceBase
     */
    public updateOrAddRecords<TItem>(database: IDBDatabase, storeName: string, records: Array<TItem>, ignoreErrors?: Array<string>): Observable<Array<number>> {
        return new Observable((observer: Observer<Array<number>>) => {
            const recordsLength = records.length;
            if (recordsLength > 0) {
                const transaction: IDBTransaction = database.transaction(storeName, 'readwrite');
                const store: IDBObjectStore = transaction.objectStore(storeName);
                let recordIndex = 0;
                const keys: Array<number> = [];

                // IDBTransaction
                // Success.
                transaction.oncomplete = () => {
                    observer.next(keys);
                    observer.complete();
                };
                // Error.
                transaction.onerror = () => {
                    const error = transaction.error;
                    if (!this.ignoreError(error, ignoreErrors)) {
                        observer.error(error);
                    }
                };

                const addRecord = () => {
                    const record = records[recordIndex];
                    recordIndex++;

                    let request: IDBRequest;

                    try {
                        request = store.put(record);
                    } catch (error) {
                        if (!this.ignoreError(error, ignoreErrors)) {
                            observer.error(error);
                        }
                    }

                    // IDBRequest
                    // Success.
                    request.onsuccess = () => {
                        keys.push(request.result);

                        if (recordIndex < recordsLength) {
                            addRecord();
                        }
                    };

                    // Error.
                    request.onerror = () => {
                        const error = request.error;
                        if (!this.ignoreError(error, ignoreErrors)) {
                            observer.error(error);
                        }
                    };
                };

                addRecord();
            } else {
                observer.next([]);
                observer.complete();
            }
        });
    }

    /**
     * Updates a record in the store.
     *
     * @template TItem The record type.
     * @param {IDBDatabase} database The IDBDatabase database.
     * @param {string} storeName The name of the object store.
     * @param {TItem} record The record to update.
     * @returns {Observable<boolean>} An observable of readyState.
     * @memberof IndexedDBService
     */
    public updateOrAddRecord<TItem>(database: IDBDatabase, storeName: string, record: TItem, ignoreErrors?: Array<string>): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            const transaction: IDBTransaction = database.transaction(storeName, 'readwrite');
            const store: IDBObjectStore = transaction.objectStore(storeName);
            const request: IDBRequest = store.put(record); // Puts the updated record back into the database.

            // IDBRequest
            // Success.
            request.onsuccess = () => {
            };

            // Error.
            request.onerror = () => {
                const error = request.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };

            // IDBTransaction
            // Success.
            transaction.oncomplete = () => {
                observer.next(true);
                observer.complete();
            };
            // Error.
            transaction.onerror = () => {
                const error = transaction.error;
                if (!this.ignoreError(error, ignoreErrors)) {
                    observer.error(error);
                }
            };
        });
    }

    private ignoreError(error: DOMException, ignoreErrors: Array<string>): boolean {
        return !isNullOrUndefined(error) && !isNullOrUndefined(error.name) && !isNullOrUndefined(ignoreErrors) && ignoreErrors.indexOf(error.name) !== -1;
    }
}
