import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import { ProcessMonitorService } from '@shared/service/processmonitor/ProcessMonitor.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ErrorDialogComponent, ErrorDialogData } from '@shared/component/dialog/error/Error.Dialog.Component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

/**
 * Global event handler service.
 *
 * @export
 * @class GlobalErrorHandler
 * @implements {ErrorHandler}
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    /**
     * Creates an instance of GlobalErrorHandler.
     *
     * @param {MatDialog} _dialog
     * @param {ProcessMonitorService} _processMonitorService
     * @memberof GlobalErrorHandler
     */
    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _processMonitorService: ProcessMonitorService) {
    }

    /**
     * Handles all errors thrown.
     *
     * @param {*} error The error thrown.
     * @memberof GlobalErrorHandler
     */
    public handleError(error: Error) {
        const anyError = error as any;

        if (!isNullOrUndefined(error.message) && error.message.startsWith('ExpressionChangedAfterItHasBeenCheckedError:')) {
            console.warn(error.message);
        } else if (!isNullOrUndefined(error.message) && error.message.startsWith('object unsubscribed')) {
            console.warn(error.message);
        } else if(!isNullOrUndefined(anyError.rejection) && !isNullOrUndefined(anyError.rejection.srcElement) && anyError.rejection.srcElement.id === 'agmGoogleMapsApiScript'){
            console.warn(error.message);
        } else {
            if (error instanceof HttpErrorResponse) {
                if (error.status === 304) {
                    return;
                }
            }

            let errorDialog: MatDialogRef<ErrorDialogComponent> = null;

            const openDialogs = this._dialog.openDialogs;
            const length = openDialogs.length;
            for (let i = 0; i < length; i++) {
                const dialog = openDialogs[i];
                if (dialog.componentInstance instanceof ErrorDialogComponent) {
                    errorDialog = dialog;
                    break;
                }
            }

            if (isNullOrUndefined(errorDialog)) {
                errorDialog = this._dialog.open(ErrorDialogComponent, { data: new ErrorDialogData('Error', error, null, this._processMonitorService.running, true), disableClose: true });
            }

            console.error(error);

            throw error;
        }
    }
}
