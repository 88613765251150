import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { SyncedVideoSessionModel, ISyncedVideoSessionModel } from '@rift/models/restapi/SyncedVideoSession.Model';

export interface ISyncedVideoSessionInfoModel extends IRestModel {
    sessions: Array<ISyncedVideoSessionModel>;
}

export class SyncedVideoSessionInfoModel extends BaseModel implements ISyncedVideoSessionInfoModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public sessions: Array<SyncedVideoSessionModel> = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-SyncedVideoSessionInfoModel');

        this.sessions = RestModelUtility.loadFromArray(restModel.Sessions, SyncedVideoSessionModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
