import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';

export class WorkflowArgumentModel extends BaseModel implements IRestModel {
    public acceptedValues: Array<string> = null;
    public displayName: string = null;
    public external: string = null;
    public invalidValueDescription: string = null;
    public name: string = null;
    public readonly isIRestModel = true;
    public validValueExpression: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-WorkflowArgumentModel');

        this.acceptedValues = restModel.AcceptedValues;
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
