import { Injectable } from '@angular/core';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { SearchCriteriaGroupModel } from '@em/models/restapi/SearchCriteriaGroup.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiAddressBookService } from '@em/service/restapi/RestApi.AddressBook.Service';
import { RestApiGroupService } from '@em/service/restapi/RestApi.Group.Service';
import { RestApiSettingsService } from '@em/service/restapi/RestApi.Settings.Service';
import { DeviceGroupEnum } from '@shared/enum/DeviceGroup.Enum';
import { BreadCrumbService } from '@shared/service/breadcrumb/BreadCrumb.Service';
import { BreadCrumbLookupSet } from '@shared/service/breadcrumb/BreadCrumb.Service.LookupSet';
import { BreadCrumbIParameterText } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';


@Injectable()
export class GroupService extends EmBaseService {
    private _flatNestedCache: Array<GroupModel>;
    private _getNestedCache: GroupModel;

    private _addLoadingTracker = new ObservableTracker<number>();
    private _deleteLoadingTracker = new ObservableTracker<ResultModel>();
    private _getLoadingTracker = new ObservableTracker<GroupModel>();
    private _getNestedLoadingTracker = new ObservableTracker<GroupModel>();
    private _getSearchCriteriaGroupsLoadingTracker = new ObservableTracker<Array<SearchCriteriaGroupModel>>();
    private _updateLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiSettingsService: RestApiSettingsService,
        private readonly _breadCrumbService: BreadCrumbService,
        private readonly _restApiAddressBookService: RestApiAddressBookService,
        private readonly _restApiGroupService: RestApiGroupService) {
        super();
    }

    public add(parentId: number, name: string, process?: ProcessMonitorServiceProcess): Observable<number> {
        return this._addLoadingTracker
            .getLoading(parentId, name)
            .observable(this._restApiSettingsService.addGroup(parentId, name, process));
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getNestedCache = null;
        this._flatNestedCache = null;
    }

    public delete(groupId: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._deleteLoadingTracker
            .getLoading(groupId)
            .observable(this._restApiSettingsService.deleteGroup(groupId, process));
    }

    public get(groupId: number | DeviceGroupEnum, process?: ProcessMonitorServiceProcess): Observable<GroupModel> {
        if (!isNullOrUndefined(this._flatNestedCache)) {
            const group = this._flatNestedCache.find(i => i.id === groupId);
            if (!isNullOrUndefined(group)) {
                return of(group);
            }
        } else {
            return this._getLoadingTracker
                .getLoading(groupId)
                .observable(this._restApiGroupService.getGroup(groupId, process));
        }
    }

    public getNested(process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<GroupModel> {
        if (isNullOrUndefined(this._getNestedCache)) {
            return this._getNestedLoadingTracker
                .getLoading()
                .observable(
                    this._restApiAddressBookService.getIncludeErrorsAndWarnings(process, retryOptions).pipe(
                        map(root => {
                            if (!isNullOrUndefined(root)) {
                                this.setGroupParent(root.children, root);

                                const lookupset = new BreadCrumbLookupSet('addressbook');

                                const groups = ArrayUtility.flatten([], root.children) as Array<GroupModel>;
                                const groupsLength = groups.length;
                                for (let i = 0; i < groupsLength; i++) {
                                    const group = groups[i];
                                    lookupset.lookups.push(({ name: `addressbook-group-${group.name}`, groupId: StringUtility.toString(group.id), text: group.name } as BreadCrumbIParameterText));
                                }

                                this._breadCrumbService.addUpdateLookupSet(lookupset);

                                this._getNestedCache = root;
                                this._flatNestedCache = ArrayUtility.flatten([], root);

                                return root;
                            } else {
                                return null;
                            }
                        })
                    )
                );
        } else {
            return of(this._getNestedCache);
        }
    }

    public getSearchCriteriaGroups(groupId: number | DeviceGroupEnum, process?: ProcessMonitorServiceProcess): Observable<Array<SearchCriteriaGroupModel>> {
        return this._getSearchCriteriaGroupsLoadingTracker
            .getLoading(groupId)
            .observable(this._restApiGroupService.getGroup(groupId, process).pipe(map(result => result.searchCriteriaGroups)));
    }

    public update(group: GroupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateLoadingTracker
            .getLoading(group)
            .observable(this._restApiSettingsService.updateGroup(group, process));
    }

    private setGroupParent(children: Array<GroupModel>, parent: GroupModel): void {
        if (!isNullOrUndefined(children) && children.length > 0) {
            const childrenLength = children.length;
            for (let i = 0; i < childrenLength; i++) {
                const child = children[i];
                child.parent = parent;
                child.parentId = parent.id;
                child.setPropertyOriginalValue('parentId', child.parentId);

                if (!isNullOrUndefined(child.children) && child.children.length > 0) {
                    this.setGroupParent(child.children, child);
                }
            }
        }
    }
}
