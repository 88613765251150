import { Component, HostBinding, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { GroupViewModel } from '@em/components/settings/outboundconnections/GroupViewModel';
import { ConnectionModel } from '@em/models/restapi/Connection.Model';
import { UserService } from '@em/service/data/user/User.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class SettingsOutboundConnectionsEditData {
    public static className: string = 'SettingsConnectionForwardingAddEditData';

    public constructor(public readonly connection: ConnectionModel, public readonly groups: Array<GroupViewModel>) { }
}

export class SettingsOutboundConnectionsEditResult {
    public static className: string = 'SettingsConnectionForwardingAddEditResult';

    public constructor(public readonly connection?: ConnectionModel) { }
}

@Component({
    selector: 'em-settings-outbound-connections-edit-connection',
    templateUrl: './Settings.OutboundConnections.EditConnection.Component.html',
    styleUrls: ['./Settings.OutboundConnections.EditConnection.Component.scss']
})
export class SettingsOutboundConnectionsEditConnectionComponent extends BaseComponent {
    public static className: string = 'SettingsOutboundConnectionsEditConnectionComponent';

    @HostBinding()
    public id: string = 'em-settings-outbound-connections-edit-connection';

    public connection: ConnectionModel;
    public groups: Array<GroupViewModel>;
    public formGroup: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _userService: UserService,
        private readonly _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private readonly _data: SettingsOutboundConnectionsEditData,
        private readonly _dialogRef: MatDialogRef<SettingsOutboundConnectionsEditConnectionComponent>,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.groups = this._data.groups;
        this.connection = this._data.connection;
        this.changeTracker.track(this.connection);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsEditConnectionComponent.className, 'Form values change');

        this.formGroup = this._formBuilder.group({
            address: ['', Validators.compose([Validators.required, ValidationValidators.ipAddressOrHostName])],
            port: ['', Validators.compose([Validators.required, ValidationValidators.port])],
            username: [''],
            password: ['']
        }, {validators: SettingsOutboundConnectionsEditConnectionComponent.addressPortPairValidation(this.getAddresses.bind(this))});
        this.formGroupTracker.track(this.formGroup);

        this.setFormGroupValues();

        this.addSubscription(this.observableHandlerBase(this.formGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            this.connection.address = this.formGroup.controls.address.value;
            this.connection.port = this.formGroup.controls.port.value;
            this.connection.username = this.formGroup.controls.username.value;
            this.connection.password = this.formGroup.controls.password.value;
        }), this.formValuesChangeProcess);
    }

    public static addressPortPairValidation(getValues: (() => [string, number][]), message?: string): ValidatorFn {
        return (formGroup: FormGroup): ValidationErrors | null => {
            const address = formGroup.get('address').value;
            const port = formGroup.get('port').value;
            const values = getValues();
            const valuesLength = values.length;
            if (!isNullOrUndefined(address) && !isNullOrUndefined(port) && valuesLength > 0) {
                for(let i = 0; i < valuesLength; i++){
                    if(values[i][0] === address && values[i][1] === port){
                        // clash
                        return {
                            addressPortPairValidation:{message}
                        };
                    }
                }
            }
            return null;
        };
    }

    public close(): void {
        this._dialogRef.close(new SettingsOutboundConnectionsEditResult(this.connection));
    }

    public cancel(): void {
        this._dialogRef.close(new SettingsOutboundConnectionsEditResult());
    }

    private setFormGroupValues(): void {
        this.formGroup.setValue({ address: this.connection.address, port: this.connection.port, username: this.connection.username, password: this.connection.password });
    }

    private getAddresses(): [string, number][] {
        const addresses: [string, number][] = [];
        const groupsLength = this.groups.length;
        for (let groupIndex = 0; groupIndex < groupsLength; groupIndex++) {
            const group = this.groups[groupIndex];
            const connectionsLength = group.connections.length;
            for (let connectionIndex = 0; connectionIndex < connectionsLength; connectionIndex++) {
                const connection = group.connections[connectionIndex];
                if (this.connection.uniqueId !== connection.uniqueId) {
                    addresses.push([group.connections[connectionIndex].address, group.connections[connectionIndex].port]);
                }
            }
        }
        return addresses;
    }
}
