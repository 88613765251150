import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { UniqueChangeTrackerArray } from '@shared/generic/UniqueChangeTrackerArray';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export interface IHttpOptionFormat {
    value: string;
    displayName: string;
}

const Exclude_Properties = ['enabled'];

export const HTTP_POST_ENTRY_MODEL_FORMAT_OPTIONS = [
    { value: 'Value', displayName: 'Value' },
    { value: 'LogPeriodValue', displayName: 'Log Period Value' },
    { value: 'Timestamp', displayName: 'Timestamp' },
    { value: 'StartTimestamp', displayName: 'Start Timestamp' },
    { value: 'UUID', displayName: 'UUID' },
    { value: 'Tags', displayName: 'Tags' },
    { value: 'MAC', displayName: 'MAC' },
    { value: 'Authentication', displayName: 'Authentication' },
    { value: 'Authorization', displayName: 'Authorization'},
    { value: 'IPv4Address', displayName: 'IPV4 Address' },
    { value: 'IPv6Address', displayName: 'IPv6 Address' },
    { value: 'DeviceName', displayName: 'Device Name' },
    { value: 'DeviceID', displayName: 'Device ID' },
    { value: 'SiteName', displayName: 'Site Name' },
    { value: 'SiteID', displayName: 'Site ID' },
    { value: 'TimeZone', displayName: 'Time Zone' },
    { value: 'EnableDST', displayName: 'Enable DST' },
    { value: 'UserString', displayName: 'User String' },
    { value: 'Histograms', displayName: 'Histograms' },
    { value: 'LogPeriodHistograms', displayName: 'Log Period Histograms' },
    { value: 'LocalTime', displayName: 'Local Time' }
];

export interface IHttpPostEntryModel extends IRestModel {
    maxAttempts: number;
    maxHistory: number;
    maxToPost: number;
    timeout: number;
    url: string;
    authenticationToken: string;
    authorizationToken: string;
    format: string[];
    authenticationTokenSet: boolean;
    authorizationTokenSet: boolean;
    uniqueIdentifier: string;
}

export class HttpPostEntryModel extends BaseModel implements IHttpPostEntryModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public maxAttempts: number = null;
    public maxHistory: number = null;
    public maxToPost: number = null;
    public timeout: number = null;
    public url: string = null;
    public enabled: boolean = true;
    public authenticationToken: string = null;
    public authorizationToken: string = null;
    public format: UniqueChangeTrackerArray<string> = null;
    public authenticationTokenSet: boolean = null;
    public authorizationTokenSet: boolean = null;
    public uniqueIdentifier: string = null;

    public constructor() {
        super();

        this.registerProperty('maxAttempts');
        this.registerProperty('maxHistory');
        this.registerProperty('maxToPost');
        this.registerProperty('timeout');
        this.registerProperty('url');
        this.registerProperty('enabled');
        this.registerProperty('authenticationToken');
        this.registerProperty('authorizationToken');
        this.registerProperty('format', true);
        this.registerProperty('authenticationTokenSet');
        this.registerProperty('authorizationTokenSet');
        this.registerProperty('uniqueIdentifier');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-HttpPostEntryModel', Exclude_Properties);

        this.format = new UniqueChangeTrackerArray(...restModel.Format);

        this.setPropertyOriginalValue('maxAttempts', this.maxAttempts);
        this.setPropertyOriginalValue('maxHistory', this.maxHistory);
        this.setPropertyOriginalValue('maxToPost', this.maxToPost);
        this.setPropertyOriginalValue('timeout', this.timeout);
        this.setPropertyOriginalValue('url', this.url);
        this.setPropertyOriginalValue('enabled', this.enabled);
        this.setPropertyOriginalValue('authenticationToken', this.authenticationToken);
        this.setPropertyOriginalValue('authorizationToken', this.authorizationToken);
        this.setPropertyOriginalValue('format', this.format);
        this.setPropertyOriginalValue('authenticationTokenSet', this.authenticationTokenSet);
        this.setPropertyOriginalValue('authorizationTokenSet', this.authorizationTokenSet);
        this.setPropertyOriginalValue('uniqueIdentifier', this.uniqueIdentifier);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, Exclude_Properties),
            AuthenticationToken: isNullOrUndefined(this.authenticationToken) ? '' : this.authenticationToken,
            AuthorizationToken: isNullOrUndefined(this.authorizationToken) ? '' : this.authorizationToken,
            Format: this.format,
        };
    }
}
