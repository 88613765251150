import { DeviceModel } from '@em/models/restapi/Device.Model';
import { Component, Injector, HostBinding } from '@angular/core';
import { IResult, Groups, IResultGroup, ISearchItem } from '@em/components/shared/search/Search';
import { BaseComponent } from '@shared/base/Base.Component';
import { DeviceSerialLinkComponent } from '@em/components/shared/deviceseriallink/DeviceSerialLink.Component';
import { Router } from '@angular/router';
import { DeviceStateEnum } from '@shared/enum/DeviceState.Enum';
import { AdvSearchSettingPropertyEnumHelpers } from '@shared/enum/AdvSearchSettingProperty.Enum';
import { Subject } from 'rxjs';
import { ConnectionService } from '@rift/service/connection/Connection.Service';

@Component({
    selector: 'em-search-results',
    templateUrl: './Search.Results.component.html',
    styleUrls: ['./Search.Results.component.scss']
})
export class SearchResultsComponent extends BaseComponent {
    public static className: string = 'SearchResultsComponent';
    public AdvSearchSettingPropertyEnumHelpers = AdvSearchSettingPropertyEnumHelpers;
    public DeviceStateEnum = DeviceStateEnum;
    public clickOutside: Subject<boolean> = new Subject<boolean>();

    public close: Subject<boolean> = new Subject<boolean>();

    @HostBinding()
    public id: string = 'em-search-results';

    public results: Array<IResult> = null;

    public constructor(
        private readonly _connectionService: ConnectionService,
        private readonly _router: Router,
        private readonly _injector: Injector) {
        super(_injector);
    }

    public deviceClick(device: DeviceModel): void {
        this.close.next();
        DeviceSerialLinkComponent.navigateToDevice(this._router, device.friendlySerial, this._connectionService);
    }

    public getGroupResults(group: IResultGroup): Array<IResult> {
        return this.isNullOrUndefined(this.results) ? [] : this.results.filter(i => i.groupId === group.id);
    }

    public get groupsWithResults(): Array<IResultGroup> {
        return Groups.filter(i => this.getGroupResults(i).length > 0);
    }

    public onClickOutside(): void {
        this.clickOutside.next();
    }

    public searchItemClick(searchItem: ISearchItem): void {
        this.close.next();
        this._router.navigateByUrl(searchItem.routerLink);
    }
}
