<div #container
    *ngIf="show === true && canShow === true"
    class="float-dialog rift-settings-counting-edit-register"
    (click)="onMenuClick()"
    ngDraggable
    #draggable="ngDraggable"
    (started)="onDrStarted($event)"
    (stopped)="onDrStopped($event)"
    (edge)="onDrEdge($event)"
    (movingOffset)="onDrMovingOffset($event)"
    (endOffset)="onDrEndOffset($event)"
    [handle]="title"
    [bounds]="bounds"
    [position]="position"
    [inBounds]="!isNullOrUndefined(bounds)">
    <div #title
        class="title drag-handle"
        fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex
            fxLayout="row"
            fxLayoutAlign="start center">
            <div fxLayout="column">
                <div class="header">
                    Advanced Settings
                </div>
            </div>
            <div fxFlex></div>
            <div class="actions">
                <button class="flat-icon-button"
                    [disabled]="saveAllChangesProcess.isRunning === true"
                    mat-stroked-button
                    matTooltip="Close"
                    (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="edit-content">
            <form [formGroup]="form"
                fxFlex>
                <div fxLayout="row"
                    fxLayoutAlign="center center">
                    Deferred Initialization
                </div>
                <div fxLayout="row"
                    fxLayoutAlign="center center">
                    <div>
                        Off
                    </div>
                    <div class="slide">
                        <mat-slide-toggle formControlName="deferredInitialization"></mat-slide-toggle>
                    </div>
                    <div>
                        On
                    </div>
                </div>
            </form>
        </div>
        <div class="actions">
            <button mat-raised-button
                (click)="onSaveClick()"
                [disabled]="isNullOrUndefined(advancedSettings) || isValid === false || hasChanges === false">
                Save Advanced Settings
            </button>
        </div>
    </div>
</div>