import { Injectable, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConnectedToolsCountModel } from '@rift/models/websocket/ConnectedToolsCount.Model';
import { ConnectionErrorModel } from '@rift/models/websocket/ConnectionError.Model';
import { ConnectionRequestModel } from '@rift/models/websocket/ConnectionRequest.Model';
import { ConnectionResponseModel } from '@rift/models/websocket/ConnectionResponse.Model';
import { ConnectionStatusModel } from '@rift/models/websocket/ConnectionStatus.Model';
import { CountModel } from '@rift/models/websocket/Count.Model';
import { ExternalSettingsChangeModel } from '@rift/models/websocket/ExternalSettingsChange.Model';
import { GVectorModel } from '@rift/models/websocket/GVector.Model';
import { StreamRequestModel } from '@rift/models/websocket/StreamRequest.Model';
import { TargetCollectionModel } from '@rift/models/websocket/TargetCollection.Model';
import { TaskUpdateModel } from '@rift/models/websocket/TaskUpdate.Model';
import { TimeModel } from '@rift/models/websocket/Time.Model';
import { ValidationDataResponseModel } from '@rift/models/websocket/ValidationDataResponse.Model';
import { VideoModel } from '@rift/models/websocket/Video.Model';
import { VideoSessionProgressModel } from '@rift/models/websocket/VideoSessionProgress.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { StreamCommandEnum } from '@shared/enum/StreamCommand.Enum';
import { StreamTypeEnum } from '@shared/enum/StreamType.Enum';
import { Dictionary } from '@shared/generic/Dictionary';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { UniqueIdUtility } from '@shared/utility/UniqueId.Utility';
import { QueueingSubject } from 'queueing-subject';
import { Observable, Subject, Subscription, TimeoutError, timer } from 'rxjs';
import makeWebSocketObservable, { GetWebSocketResponses, normalClosureMessage } from 'rxjs-websockets';
import { catchError, filter, map, share, switchMap } from 'rxjs/operators';
import { PhysicalNetworkChangeModel } from '@rift/models/websocket/PhysicalNetworkChange.Model';
import { PingModel } from '@rift/models/websocket/Ping.Model';
import { PongModel } from '@rift/models/websocket/Pong.Model';
import { UrlUtility } from '@shared/utility/Url.Utility';

class ResponseWatch<TResponse extends IWebSocketModel> {
    public eventEmitter: Subject<TResponse>;
    public id: number;
    public packetType: string;
    public responseType: new () => TResponse;
    public sent: Date;
    public timerSub: Subscription;

    public constructor(
        eventEmitter: Subject<TResponse>,
        id: number,
        packetType: string,
        responseType: new () => TResponse
    ) {
        this.eventEmitter = eventEmitter;
        this.id = id;
        this.packetType = packetType;
        this.responseType = responseType;
        this.sent = new Date();
    }
}

interface INoDataTracker {
    type: StreamTypeEnum;
    active?: boolean;
    lastReceived?: number;
}

interface IRunningStream {
    type: StreamTypeEnum;
    forDeviceSerialNumber?: string;
}

@Injectable()
export class WebSocketService extends RiftBaseService {
    public connected: Subject<null> = new Subject<null>();
    public disconnected: Subject<null> = new Subject<null>();
    public messageNoDataFor: Subject<INoDataTracker> = new Subject<INoDataTracker>();

    public connectionResponseMessageReceived: Subject<ConnectionResponseModel> = new Subject<ConnectionResponseModel>();
    public connectionErrorMessageReceived: Subject<ConnectionErrorModel> = new Subject<ConnectionErrorModel>();
    public connectedToolsCountMessageReceived: Subject<ConnectedToolsCountModel> = new Subject<ConnectedToolsCountModel>();
    public connectionStatusMessageReceived: Subject<ConnectionStatusModel> = new Subject<ConnectionStatusModel>();
    public timeMessageReceived: Subject<TimeModel> = new Subject<TimeModel>();
    public videoMessageReceived: Subject<VideoModel> = new Subject<VideoModel>();
    public targetsMessageReceived: Subject<TargetCollectionModel> = new Subject<TargetCollectionModel>();
    public videoSessionProgressMessageReceived: Subject<VideoSessionProgressModel> = new Subject<VideoSessionProgressModel>();
    public validationDataMessageReceived: Subject<ValidationDataResponseModel> = new Subject<ValidationDataResponseModel>();
    public countMessageReceived: Subject<CountModel> = new Subject<CountModel>();
    public taskUpdateMessageReceived: Subject<TaskUpdateModel> = new Subject<TaskUpdateModel>();
    public externalSettingsChanged: Subject<ExternalSettingsChangeModel> = new Subject<ExternalSettingsChangeModel>();
    public gVectorChanged: Subject<GVectorModel> = new Subject<GVectorModel>();
    public physicalNetworkChanged: Subject<PhysicalNetworkChangeModel> = new Subject<PhysicalNetworkChangeModel>();
    public pingReceived: Subject<PingModel> = new Subject<PingModel>();

    private _sendMessageSubject: QueueingSubject<string>;
    private _onMessageReceivedSub: Subscription;
    private _onConnectionStatusChangeSub: Subscription;
    private _firstConnectResponse: boolean = true;
    private _runningStreams: Array<IRunningStream>;
    private _responsesWatches: Dictionary<number, ResponseWatch<any>>;
    private _responsesWatchesCount: number = 0;
    private _socket: Observable<GetWebSocketResponses<string | ArrayBuffer | Blob>>;
    private _pingSub: Subscription = null;
    private _noDataTrackerSub: Subscription = null;
    private _videoMessageReceivedNoDataTracker: INoDataTracker = { type: StreamTypeEnum.video };
    private _countMessageReceivedNoDataTracker: INoDataTracker = { type: StreamTypeEnum.count };

    public constructor(
        protected readonly _activatedRoute: ActivatedRoute,
        protected readonly _zone: NgZone,
        protected readonly _router: Router,
        protected readonly _connectionTokenService: ConnectionTokenService,
        protected readonly _config: ConfigurationService) {
        super();

        this._sendMessageSubject = new QueueingSubject<string>();
    }

    public connect(timeOutMs?: number, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._zone.runOutsideAngular(() => {
            this._firstConnectResponse = true;
            this._runningStreams = [];
            this._responsesWatches = new Dictionary<number, ResponseWatch<any>>();

            const connectionRequest = new ConnectionRequestModel();
            connectionRequest.id = UniqueIdUtility.nextId;
            connectionRequest.token = this._connectionTokenService.connectionToken;
            connectionRequest.secureToken = this._connectionTokenService.secureToken;

            this._sendMessageSubject = new QueueingSubject<string>();

            this._socket = makeWebSocketObservable(this._config.riftWebSocket);

            this._onMessageReceivedSub = this.addSubscription(
                this._socket
                    .pipe(
                        switchMap((getResponses: GetWebSocketResponses) =>
                            getResponses(this._sendMessageSubject)
                        ),
                        share()
                    )
                    .subscribe(
                        (message: string) => this.onMessageReceived(message),
                        (error: Error) => {
                            const { message } = error;
                            const errorMessage = new ConnectionErrorModel();
                            errorMessage.expDetails = message;
                            errorMessage.packetType = ConnectionErrorModel.expectedPacketType;

                            if (message !== normalClosureMessage) {
                                errorMessage.details = 'Socket was disconnected due to error';
                            } else {
                                errorMessage.details = 'Socket was disconnected by back end';
                            }

                            this.connectionErrorMessageReceived.next(errorMessage);
                        }
                    )
            );

            return this.sendWithResponseObservable(connectionRequest, ConnectionResponseModel, timeOutMs).pipe(
                filter(() => this._firstConnectResponse === true),
                map(result => {
                    if (result.status === 'ok') {
                        this._firstConnectResponse = false;

                        this.addSubscription(
                            this.connectionErrorMessageReceived.subscribe(message => this.onConnectionErrorMessageReceived(message))
                        );

                        this._zone.run(() => {
                            this.connected.next();
                        });

                        this._pingSub = this.pingReceived.subscribe(() => {
                            const pong = new PongModel();
                            pong.id = UniqueIdUtility.nextId;
                            this.send(pong);
                        });

                        this._noDataTrackerSub = timer(1000, 1000).subscribe(() => {
                            this.checkNoDataTrackers(1000);
                        });

                        return true;
                    } else {
                        process.error('Failed to connect web socket', null);
                        return false;
                    }
                }),
                catchError(error => {
                    if (error instanceof TimeoutError) {
                        process.error(
                            'Failed to connect web socket. Connection response timeout',
                            error
                        );
                    } else {
                        process.error('Failed to connect web socket', error);
                    }
                    throw error;
                })
            );
        });
    }

    public disconnect(process?: ProcessMonitorServiceProcess): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._pingSub)) {
                this._pingSub.unsubscribe();
                this._pingSub = null;
            }
            this._runningStreams = [];
            this._sendMessageSubject = null;
            this._responsesWatches = new Dictionary<number, ResponseWatch<any>>();
            this._firstConnectResponse = true;
            if (!isNullOrUndefined(this._onMessageReceivedSub)) {
                this._onMessageReceivedSub.unsubscribe();
            }
            if (!isNullOrUndefined(this._noDataTrackerSub)) {
                this._noDataTrackerSub.unsubscribe();
            }
            if (!isNullOrUndefined(this._onConnectionStatusChangeSub)) {
                this._onConnectionStatusChangeSub.unsubscribe();
            }
            this._socket = null;
            this._zone.run(() => {
                this.disconnected.next();
            });
        });
    }

    public send<TSend extends IWebSocketModel>(send: TSend): void {
        this._zone.runOutsideAngular(() => {
            this._sendMessageSubject.next(
                JSON.stringify(send.toWebSocketMessage())
            );
        });
    }

    public sendWithResponseObservable<TSend extends IWebSocketModel, TResponse extends IWebSocketModel>(send: TSend, responseType: new () => TResponse, timeOutMs?: number): Observable<TResponse> {
        return this._zone.runOutsideAngular(() => {
            this._sendMessageSubject.next(
                JSON.stringify(send.toWebSocketMessage())
            );

            const responseWatch = new ResponseWatch<TResponse>(
                new Subject<TResponse>(),
                send.id,
                send.packetType,
                responseType
            );
            this._responsesWatches.addOrUpdate(responseWatch.id, responseWatch);
            this._responsesWatchesCount++;

            if (!isNullOrUndefined(timeOutMs)) {
                responseWatch.timerSub = this.addSubscription(
                    timer(timeOutMs).subscribe(() => {
                        responseWatch.timerSub.unsubscribe();
                        responseWatch.eventEmitter.error(new TimeoutError());
                        this._responsesWatches.remove(responseWatch.id);
                        this._responsesWatchesCount--;
                    })
                );
            }

            return responseWatch.eventEmitter;
        });
    }

    public startStream(stream: StreamTypeEnum, forDeviceSerialNumber?: string, taskName?: string): number {
        return this._zone.runOutsideAngular(() => {
            const index = this._runningStreams.findIndex(
                i =>
                    i.type === stream &&
                    i.forDeviceSerialNumber === forDeviceSerialNumber
            );
            if (index === -1) {
                this._runningStreams.push({
                    type: stream,
                    forDeviceSerialNumber
                });

                switch (stream) {
                    case StreamTypeEnum.count:
                        this._countMessageReceivedNoDataTracker.active = true;
                        this._countMessageReceivedNoDataTracker.lastReceived = null;
                        break;
                    case StreamTypeEnum.video:
                        this._videoMessageReceivedNoDataTracker.active = true;
                        this._videoMessageReceivedNoDataTracker.lastReceived = null;
                        break;
                }

                const streamRequest = new StreamRequestModel();
                streamRequest.command = StreamCommandEnum.on;
                streamRequest.stream = [stream];
                streamRequest.id = UniqueIdUtility.nextId;
                streamRequest.token = this._connectionTokenService.connectionToken;
                streamRequest.secureToken = this._connectionTokenService.secureToken;
                streamRequest.specificDevice = forDeviceSerialNumber;
                streamRequest.taskName = taskName;

                this.send(streamRequest);

                return streamRequest.id;
            } else {
                return index;
            }
        });
    }

    public stopStream(stream: StreamTypeEnum, id: number, forDeviceSerialNumber?: string): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._runningStreams) && !isNullOrUndefined(id) && id > -1) {
                const runningIndex = this._runningStreams.findIndex(
                    i =>
                        i.type === stream &&
                        (isNullOrUndefined(i.forDeviceSerialNumber) ||
                            i.forDeviceSerialNumber === forDeviceSerialNumber)
                );
                if (runningIndex !== -1) {
                    this._runningStreams.splice(runningIndex, 1);

                    switch (stream) {
                        case StreamTypeEnum.count:
                            this._countMessageReceivedNoDataTracker.active = false;
                            this._countMessageReceivedNoDataTracker.lastReceived = null;
                            break;
                        case StreamTypeEnum.video:
                            this._videoMessageReceivedNoDataTracker.active = false;
                            this._videoMessageReceivedNoDataTracker.lastReceived = null;
                            break;
                    }

                    const streamRequest = new StreamRequestModel();
                    streamRequest.command = StreamCommandEnum.off;
                    streamRequest.stream = [stream];
                    streamRequest.id = id;
                    streamRequest.token = this._connectionTokenService.connectionToken;
                    streamRequest.secureToken = this._connectionTokenService.secureToken;
                    streamRequest.specificDevice = forDeviceSerialNumber;

                    this.send(streamRequest);
                }
            }
        });
    }

    public reStopStream(stream: StreamTypeEnum, id: number, forDeviceSerialNumber?: string): void {
        this._zone.runOutsideAngular(() => {
            let runningIndex = null;
            if (!isNullOrUndefined(this._runningStreams)) {
                runningIndex = this._runningStreams.findIndex(
                    i =>
                        i.type === stream &&
                        (isNullOrUndefined(i.forDeviceSerialNumber) ||
                            i.forDeviceSerialNumber === forDeviceSerialNumber)
                );
            }

            if (runningIndex !== -1) {
                this._runningStreams.splice(runningIndex, 1);
            }

            const streamRequest = new StreamRequestModel();
            streamRequest.command = StreamCommandEnum.off;
            streamRequest.stream = [stream];
            streamRequest.id = id;
            streamRequest.token = this._connectionTokenService.connectionToken;
            streamRequest.secureToken = this._connectionTokenService.secureToken;
            streamRequest.specificDevice = forDeviceSerialNumber;

            this.send(streamRequest);
        });
    }

    private onConnectionErrorMessageReceived(message: ConnectionErrorModel): void {
        this._zone.run(() => {
            if (!isNullOrUndefined(this._pingSub)) {
                this._pingSub.unsubscribe();
                this._pingSub = null;
            }
            this._router.navigate(['connect'], {
                queryParams: { reason: 'Device connection lost', goto: UrlUtility.getURLArray(this._router, 2) }
            });
        });
    }

    private onMessageReceived(message: string): void {
        this._zone.runOutsideAngular(() => {

            const jObject = JSON.parse(message);
            message = null;

            if (!isNullOrUndefined(jObject)) {
                this.checkForResponseWatch(jObject);

                if (this.handelMessageReceived(jObject, this.videoMessageReceived, VideoModel, this._videoMessageReceivedNoDataTracker)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.targetsMessageReceived, TargetCollectionModel)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.countMessageReceived, CountModel, this._countMessageReceivedNoDataTracker)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.validationDataMessageReceived, ValidationDataResponseModel)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.connectionResponseMessageReceived, ConnectionResponseModel)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.connectionErrorMessageReceived, ConnectionErrorModel)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.connectedToolsCountMessageReceived, ConnectedToolsCountModel)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.connectionStatusMessageReceived, ConnectionStatusModel)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.timeMessageReceived, TimeModel)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.videoSessionProgressMessageReceived, VideoSessionProgressModel)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.taskUpdateMessageReceived, TaskUpdateModel)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.externalSettingsChanged, ExternalSettingsChangeModel)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.gVectorChanged, GVectorModel)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.physicalNetworkChanged, PhysicalNetworkChangeModel)) {
                    return;
                }
                if (this.handelMessageReceived(jObject, this.pingReceived, PingModel)) {
                    return;
                }
            }
        });
    }

    private checkForResponseWatch(jObject: any): void {
        this._zone.runOutsideAngular(() => {
            if (this._responsesWatchesCount > 0) {
                const keys = this._responsesWatches.keys;
                if (!isNullOrUndefined(jObject.id) && keys.length > 0) {
                    const responseWatch = this._responsesWatches.get(
                        jObject.id
                    );
                    if (!isNullOrUndefined(responseWatch)) {
                        if (!isNullOrUndefined(responseWatch.timerSub)) {
                            responseWatch.timerSub.unsubscribe();
                        }
                        this.handelMessageReceived(
                            jObject,
                            responseWatch.eventEmitter,
                            responseWatch.responseType
                        );
                        this._responsesWatches.remove(jObject.id);
                    }
                }
            }
        });
    }

    private handelMessageReceived<TWebSocketModel extends IWebSocketModel>(object: any, eventEmitter: Subject<TWebSocketModel>, modelType: new () => TWebSocketModel, noDataTracker?: INoDataTracker): any {
        this._zone.runOutsideAngular(() => {
            if ((modelType as any).expectedPacketType === object.packetType) {
                if (!isNullOrUndefined(noDataTracker)) {
                    noDataTracker.lastReceived = Date.now();
                }
                const model = new modelType();
                model.loadFromWebSocketMessage(object);
                eventEmitter.next(model);
                return true;
            }
            return false;
        });
    }

    private checkNoDataTrackers(duration: number): void {
        const to = Date.now() - duration;

        if (!isNullOrUndefined(this._countMessageReceivedNoDataTracker.active) && this._countMessageReceivedNoDataTracker.active === true && this._countMessageReceivedNoDataTracker.lastReceived < to) {
            this.messageNoDataFor.next(this._countMessageReceivedNoDataTracker);
        }

        if (!isNullOrUndefined(this._countMessageReceivedNoDataTracker.active) && this._videoMessageReceivedNoDataTracker.active === true && this._videoMessageReceivedNoDataTracker.lastReceived < to) {
            this.messageNoDataFor.next(this._videoMessageReceivedNoDataTracker);
        }
    }
}
