import { Injector, Input, SecurityContext, Directive } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { ConfigSectionsEnum, ConfigSectionsEnumHelpers, IGroupedConfigSection, ISectionChange, SectionActionEnum, SectionActionEnumHelpers } from '@rift/service/configjsoncompare/ConfigJsonCompare.Service';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isArray } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';

@Directive()
export abstract class ConfigHistoryBaseComponent extends RiftBaseComponent {

    public readonly ConfigSectionsEnumHelpers = ConfigSectionsEnumHelpers;
    public readonly SectionActionEnum = SectionActionEnum;
    public readonly SectionActionEnumHelpers = SectionActionEnumHelpers;
    public readonly DateTimeUtility = DateTimeUtility;
    public readonly ConfigSectionsEnum = ConfigSectionsEnum;

    @Input()
    public onlyShowChangedKey: boolean = false;

    @Input()
    public sectionVM: IGroupedConfigSection;

    public constructor(
        private readonly _sanitizerBase: DomSanitizer,
        private readonly _injectorCBase: Injector,
        private readonly _dialogCBase: MatDialog) {
        super(_injectorCBase, _dialogCBase);
    }

    public stringify(value: any): string {
        return JSON.stringify(value);
    }

    public settingChanged(sectionChange: ISectionChange, settingKey: string): boolean {
        return !this.isNullOrUndefined(sectionChange) && !this.isNullOrUndefined(sectionChange.settingsChanged) ? sectionChange.settingsChanged.some(s => s.key === settingKey) : false;
    }

    public settingChangedOrShowUnchanged(sectionChange: ISectionChange, settingKey: string): boolean {
        return this.onlyShowChangedKey === false || this.settingChanged(sectionChange, settingKey);
    }

    public getSectionVM(section: ConfigSectionsEnum, parentSectionVM: IGroupedConfigSection | IGroupedConfigSection[]): IGroupedConfigSection {
        let sectionVMs;
        if (isArray(parentSectionVM)) {
            sectionVMs = parentSectionVM;
        } else {
            if (!this.isNullOrUndefined(parentSectionVM.children)) {
                sectionVMs = parentSectionVM.children;
            }
        }
        if (!this.isNullOrUndefined(sectionVMs)) {
            const sectionVM = sectionVMs.find(svm => svm.section === section);
            return this.isNullOrUndefined(sectionVM) ? null : sectionVM;
        }
    }

    public getSection(section: ConfigSectionsEnum, sectionChange: ISectionChange | ISectionChange[]): ISectionChange {
        let sectionChanges;
        if (isArray(sectionChange)) {
            sectionChanges = sectionChange;
        } else {
            sectionChanges = sectionChange.sectionsChanged;
        }

        if (!this.isNullOrUndefined(sectionChanges)) {
            const childSectionChange = sectionChanges.find(child => child.section === section);
            return this.isNullOrUndefined(childSectionChange) ? null : childSectionChange;
        }
    }

    public showSection(parentSectionVM: ConfigSectionsEnum): boolean {
        if (!this.isNullOrUndefined(this.sectionVM.children)) {
            const sectionVM = this.sectionVM.children.find(svm => svm.section === parentSectionVM);
            return this.isNullOrUndefined(sectionVM) ? false : sectionVM.show;
        }
    }

    public updateOrRemove(sectionChange: ISectionChange): boolean {
        return this.isNullOrUndefined(sectionChange) ? false : sectionChange.action === SectionActionEnum.update || sectionChange.action === SectionActionEnum.remove;
    }

    public updateOrAdd(sectionChange: ISectionChange): boolean {
        return this.isNullOrUndefined(sectionChange) ? false : sectionChange.action === SectionActionEnum.update || sectionChange.action === SectionActionEnum.add;
    }

    public valueFormat(value: any): string {
        const valueSanitized = this._sanitizerBase.sanitize(SecurityContext.HTML, value);
        if (!this.isNullOrUndefined(valueSanitized)) {
            return StringUtility.replace(valueSanitized, ' ', '&nbsp;');
        }
    }
}
