import { Component, HostListener, Injector, HostBinding, ViewChild, ElementRef } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { ChangePasswordModel } from '@rift/models/restapi/ChangePassword.Model';
import { WebAPIKeyModel } from '@rift/models/restapi/WebAPIKey.Model';
import { SecurityService } from '@rift/service/data/security/Security.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, of, zip, from, fromEvent, Observer, timer } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { FileUtility } from '@shared/utility/File.Utility';
import { TLSCertModel } from '@rift/models/restapi/TLSCert.Model';
import { ConnectionService } from '@rift/service/connection/Connection.Service';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { SSLCertModel } from '@rift/models/restapi/SSLCert.Model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpEnabledDataModel } from '@rift/models/restapi/HttpEnabledData.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { Router } from '@angular/router';
import { AuthenticationEnabledDataModel } from '@rift/models/restapi/AuthenticationEnabledData.Model';
import { ValidationMessages } from '@shared/validation/Validation.Messages';
import { ValidationMessageConfig } from '@shared/validation/Validation.MessageConfig';


@Component({
    selector: 'rift-settings-security',
    templateUrl: './Settings.Security.Component.html',
    styleUrls: ['./Settings.Security.Component.scss']
})
export class SettingsSecurityComponent extends RiftBaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsSecurityComponent';

    @HostBinding()
    public id: string = 'rift-settings-security';

    @ViewChild('fileUpload', { static: false })
    public fileUpload: ElementRef<HTMLInputElement>;

    @ViewChild('privateKeyUpload', { static: false })
    public privateKeyUpload: ElementRef<HTMLInputElement>;

    @ViewChild('publicKeyUpload', { static: false })
    public publicKeyUpload: ElementRef<HTMLInputElement>;

    public webInterfaceFormGroup: FormGroup;
    public certPairFormGroup: FormGroup;
    public changePassword: ChangePasswordModel;
    public regenerateKeyProcess: ProcessMonitorServiceProcess;
    public webAPIKey: WebAPIKeyModel;
    public uploadCertProcess: ProcessMonitorServiceProcess;
    public uploadPrivateKeyProcess: ProcessMonitorServiceProcess;
    public uploadPublicKeyProcess: ProcessMonitorServiceProcess;
    public uploadSSLCertProcess: ProcessMonitorServiceProcess;
    public removeCACertProcess: ProcessMonitorServiceProcess;
    public removeCertPairProcess: ProcessMonitorServiceProcess;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;
    public clearSSLKeysProcess: ProcessMonitorServiceProcess;
    public certCANames: string[];
    public certPairNames: string[];
    public certType: string = 'default';
    public canChangePassword: boolean;
    public httpEnabledData: HttpEnabledDataModel = new HttpEnabledDataModel();
    public authenticationEnabledData: AuthenticationEnabledDataModel = new AuthenticationEnabledDataModel();

    public sslCertModel: SSLCertModel = new SSLCertModel();

    public constructor(
        private readonly _securityService: SecurityService,
        private readonly _formBuilder: FormBuilder,
        private readonly _connectionService: ConnectionService,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _router: Router,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.changePassword = new ChangePasswordModel();
        this.changePassword.loadFromRestApiModel({});
        this.changeTracker.track(this.changePassword);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsSecurityComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsSecurityComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsSecurityComponent.className, this.saveAllChangesProcessText);
        this.regenerateKeyProcess = this.processMonitorService.getProcess(SettingsSecurityComponent.className, 'Regenerating web api key.');
        this.uploadCertProcess = this.processMonitorService.getProcess(SettingsSecurityComponent.className, 'Uploading CA Certificates.');
        this.removeCACertProcess = this.processMonitorService.getProcess(SettingsSecurityComponent.className, 'Removing CA Certificates.');
        this.removeCertPairProcess = this.processMonitorService.getProcess(SettingsSecurityComponent.className, 'Removing Certificate Pair.');
        this.uploadPrivateKeyProcess = this.processMonitorService.getProcess(SettingsSecurityComponent.className, 'Uploading Private Key.');
        this.uploadPublicKeyProcess = this.processMonitorService.getProcess(SettingsSecurityComponent.className, 'Uploading Public Key.');
        this.uploadSSLCertProcess = this.processMonitorService.getProcess(SettingsSecurityComponent.className, 'Uploading Certificate Pair.');
        this.clearSSLKeysProcess = this.processMonitorService.getProcess(SettingsSecurityComponent.className, 'Clear SSL Certificate.');

        this.addSaveAllAction(this);

        this.webInterfaceFormGroup = this._formBuilder.group(
            {
                oldPassword: [this.changePassword.oldPassword, Validators.compose([Validators.required, Validators.maxLength(30)])],
                newPassword: [this.changePassword.newPassword, Validators.compose([Validators.required, Validators.maxLength(30), Validators.minLength(8), ValidationValidators.password])],
                confirmPassword: [this.changePassword.confirmPassword, Validators.compose([Validators.required, Validators.maxLength(30), Validators.minLength(8), ValidationValidators.password])],
            },
            {
                validator: ValidationValidators.passwordMatch('newPassword', 'confirmPassword')
            } as AbstractControlOptions
        );
        this.formGroupTracker.track(this.webInterfaceFormGroup);

        this.addSubscription(this.observableHandlerBase(this.webInterfaceFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesWebInterfaceFormGroup()), this.formValuesChangeProcess);

        this.certPairFormGroup = this._formBuilder.group({
            certName:[this.sslCertModel.certName, Validators.compose([Validators.required, Validators.maxLength(20), ValidationValidators.certName])]
        });
        this.certPairFormGroup.markAllAsTouched();

        this.certType = 'default';
        this.certPairFormGroup.controls.certName.disable();
        this.certPairFormGroup.updateValueAndValidity();

        this.addSubscription(this.observableHandlerBase(this.certPairFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesCertForm()), this.formValuesChangeProcess);

        this.addSubscription(this.observableHandlerBase(this.getHostDevice(), null).subscribe(
            hostDevice => {
                if ((this._connectionService?.connectionRequest?.connectionType === 'serial' && hostDevice?.unitGen === UnitGenerationEnum.gen4) || hostDevice?.unitGen !== UnitGenerationEnum.gen4) {
                    this.canChangePassword = true;
                }
                else {
                    this.canChangePassword = false;
                }
            }
        ), null);

        this.initConnectionState();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public certTypeChanged(): void{
        if(this.certType === 'default'){
            this.certPairFormGroup.controls.certName.disable();
            this.certPairFormGroup.controls.certName.setValue(null);
            this.sslCertModel.certName = null;
        }
        else{
            this.certPairFormGroup.controls.certName.enable();
            this.certPairFormGroup.controls.certName.setValue(null);
            this.sslCertModel.certName = null;
        }

        this.certPairFormGroup.updateValueAndValidity();
    }

    public onDeleteCertPair(certName: string): void{
        this.removeCertPair(certName);
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase || !this.changePassword.hasChanges;
    }

    public httpsEnabledChanged(){
        if(this.connectionService.isOnDevice){
            this.openOkCancelDialog('HTTPS', 'Note when changing HTTPS Enabled the browser will refresh when saving is complete', false);
        }

        this.updateSaveAllAction(this);
    }

    public authenticatedCommunicationsEnabledChanged(){
        this.updateSaveAllAction(this);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            of(this.userCurrentService.isInstaller).pipe(
                flatMap(isInstaller => {
                    if (isInstaller) {
                        return this._securityService.getWebAPIKey(process).pipe(
                            map(result => {
                                if (!this.isNullOrUndefined(result)) {
                                    this.webAPIKey = result;
                                    this.changeTracker.track(this.webAPIKey);
                                }
                                return true;
                            })
                        );
                    } else {
                        return of(true);
                    }
                })),
            this.isDeviceCapable(DeviceCapabilitiesEnum.https).pipe(
                flatMap(httpsCapable=>{
                    if(httpsCapable){
                        return this._securityService.getHttpEnabled(this.connectionService.connectedToFriendlySerial, process).pipe(
                            map(result =>{
                                this.httpEnabledData = result;
                                this.changeTracker.track(this.httpEnabledData);
                                return true;
                            })
                        );
                    }

                    return of(true);
                })
            ),
            this.isDeviceCapable(DeviceCapabilitiesEnum.commsAuthentication).pipe(
                flatMap(authCapable=>{
                    if(authCapable){
                        return this._securityService.getAuthenticationEnabled(this.connectionService.connectedToFriendlySerial, process).pipe(
                            map(result =>{
                                this.authenticationEnabledData = result;
                                this.changeTracker.track(this.authenticationEnabledData);
                                return true;
                            })
                        );
                    }

                    return of(true);
                })
            ),
            this.getCACertName(process),
            this.getCertPairNames(process),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public regenerateKey(): void {
        this.addSubscription(this.observableHandlerBase(this._securityService.regenerateWebAPIKey(this.regenerateKeyProcess), this.regenerateKeyProcess).subscribe(
            () => {
                this.addSubscription(this.observableHandlerBase(this._securityService.getWebAPIKey(this.regenerateKeyProcess), this.regenerateKeyProcess).subscribe(
                    webAPIKeyResult => {
                        this.webAPIKey = webAPIKeyResult;
                    }
                ), this.regenerateKeyProcess);
            }
        ), this.regenerateKeyProcess);
    }

    public removeCert(name: string): void {
        const cert = new TLSCertModel();
        cert.name = name;

        this.addSubscription(this.observableHandlerBase(this._securityService.removeCACert(cert, this.removeCACertProcess), this.removeCACertProcess).subscribe(
            () => {
                this.addSubscription(this.getCACertName().subscribe());
            }
        ), this.removeCACertProcess);
    }

    public removeCertPair(name: string): void {
        const cert = new SSLCertModel();
        cert.certName = name;

        this.addSubscription(this.observableHandlerBase(this._securityService.removeCertPair(cert, this.removeCertPairProcess, {disabled: true}), this.removeCertPairProcess).subscribe(
            (result) => {
                if (!this.isNullOrUndefined(result) && !this.isNullOrUndefined(result.error)){
                    this.openOkCancelDialog('Remove Certificate Error', result.error, false);
                }
                else if (!this.isNullOrUndefined(result)){
                    this.openOkCancelDialog('Remove Certificate', 'Removed certificate.', false);
                }
                else{
                    this.openOkCancelDialog('Remove Certificate Error', 'Unknown Error', false);
                }

                this.addSubscription(this.getCertPairNames().subscribe());
            }
        ), this.removeCertPairProcess);
    }

    public uploadCert(): void {
        this.fileUpload.nativeElement.click();
    }

    public uploadPrivateSSLCert(): void {
        this.privateKeyUpload.nativeElement.click();
    }

    public uploadPublicSSLCert(): void {
        this.publicKeyUpload.nativeElement.click();
    }

    public clearSSLKeys(): void {
        this.sslCertModel.privateKey = null;
        this.sslCertModel.publicKey = null;
        this.sslCertModel.certName = null;

        this.publicKeyUpload.nativeElement.value = '';
        this.privateKeyUpload.nativeElement.value = '';
    }

    public uploadSSLCert(): void {
        this.addSubscription(this.observableHandlerBase(this._securityService.uploadCertPair(this.sslCertModel, this.uploadSSLCertProcess, {disabled: true}), this.uploadSSLCertProcess).subscribe(
            (result) => {
                this.sslCertModel.privateKey = null;
                this.sslCertModel.publicKey = null;
                this.sslCertModel.certName = null;
                this.certType = 'default';

                this.publicKeyUpload.nativeElement.value = '';
                this.privateKeyUpload.nativeElement.value = '';

                if (!this.isNullOrUndefined(result) && !this.isNullOrUndefined(result.error)){
                    this.openOkCancelDialog('Certificate Error', result.error, false);
                }
                else if (!this.isNullOrUndefined(result)){
                    this.openOkCancelDialog('Certificate', 'Uploaded certificate. Please reboot for them to take effect.', false);
                }
                else{
                    this.openOkCancelDialog('Certificate Error', 'Unknown Error', false);
                }

                this.addSubscription(this.getCertPairNames().subscribe());
            }
        ), this.uploadSSLCertProcess);
    }

    public certFileSelected(e: Event): void {
        this.uploadCertProcess.started();
        const target = e.target as HTMLInputElement;
        if (!this.isNullOrUndefined(target) && !this.isNullOrUndefined(target.files) && target.files.length > 0) {
            const file = target.files[0];
            if (!this.isNullOrUndefined(file)) {
                this.addSubscription(
                    this.validateCACertFile(file).subscribe(
                        isValid => {
                            if (isValid === true) {
                                this.addSubscription(this.uploadCACertFile(file).subscribe(() => this.uploadCertProcess.completed()), this.uploadCertProcess);
                            } else {
                                this.openOkCancelDialog('Certificate Authority', 'The provided Certificate Authority is invalid.', false);
                                this.uploadCertProcess.completed();
                            }
                        })
                    , this.uploadCertProcess);
            }
        }
    }

    public sslCertPrivateKeyFileSelected(e: Event): void {
        this.uploadPrivateKeyProcess.started();
        const target = e.target as HTMLInputElement;
        if (!this.isNullOrUndefined(target) && !this.isNullOrUndefined(target.files) && target.files.length > 0) {
            const file = target.files[0];
            if (!this.isNullOrUndefined(file)) {
                this.addSubscription(
                    this.validatePrivateKeyFile(file).subscribe(
                        isValid => {
                            if (isValid === true) {
                                this.addSubscription(this.uploadPrivateKeyCertFile(file).subscribe(() => this.uploadPrivateKeyProcess.completed()), this.uploadPrivateKeyProcess);
                            } else {
                                this.openOkCancelDialog('Certificate', 'The provided private key is invalid.', false);
                                this.uploadPrivateKeyProcess.completed();
                            }
                        })
                    , this.uploadPrivateKeyProcess);
            }
        }
    }

    public sslCertPublicKeyFileSelected(e: Event): void {
        this.uploadPublicKeyProcess.started();
        const target = e.target as HTMLInputElement;
        if (!this.isNullOrUndefined(target) && !this.isNullOrUndefined(target.files) && target.files.length > 0) {
            const file = target.files[0];
            if (!this.isNullOrUndefined(file)) {
                this.addSubscription(
                    this.validatePublicKeyFile(file).subscribe(
                        isValid => {
                            if (isValid === true) {
                                this.addSubscription(this.uploadPublicKeyCertFile(file).subscribe(() => this.uploadPublicKeyProcess.completed()), this.uploadPublicKeyProcess);
                            } else {
                                this.openOkCancelDialog('Certificate', 'The provided Certificate is invalid.', false);
                                this.uploadPublicKeyProcess.completed();
                            }
                        })
                    , this.uploadPublicKeyProcess);
            }
        }
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        let httpEnabledChanged: boolean = false;

        const saveAllSub = zip(
            of(this.changePassword.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._securityService.changePassword(this.changePassword, process, {disabled: true}).pipe(
                            map((result) =>{
                                if(this.isNullOrUndefined(result) || this.isNullOrUndefined(result.error)){
                                    return true;
                                }

                                // Something went wrong with the change
                                this.openOkCancelDialog('Password change failed', result.error, false);
                                return true;
                            })
                        );
                    } else {
                        return of(true);
                    }
                })
            ),
            this.isDeviceCapable(DeviceCapabilitiesEnum.https).pipe(
                flatMap(httpsCapable=>{
                    if(httpsCapable && this.httpEnabledData.hasChanges){
                        return this._securityService.setHttpEnabled(this.connectionService.connectedToFriendlySerial, this.httpEnabledData, process).pipe(
                            map(result=>{
                                httpEnabledChanged = true;
                                return true;
                            })
                        );
                    }

                    return of(true);
                })
            ),
            this.isDeviceCapable(DeviceCapabilitiesEnum.commsAuthentication).pipe(
                flatMap(authCapable=>{
                    if(authCapable && this.authenticationEnabledData.hasChanges){
                        return this._securityService.setAuthenticationEnabled(this.connectionService.connectedToFriendlySerial, this.authenticationEnabledData, process).pipe(
                            map(result=>true)
                        );
                    }

                    return of(true);
                })
            )
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    if(this.connectionService.isOnDevice && httpEnabledChanged === true){
                        // Refresh the page
                        timer(5000).subscribe(t=>{
                            window.location.reload();
                        });

                        return of(true);
                    }
                    else{
                        return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                    }
                } else {
                    return of(false);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._securityService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
        this.webAPIKey = null;
        this.changePassword = null;
        this.httpEnabledData = null;
        this.authenticationEnabledData = null;
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.webInterfaceFormGroup.disable();
        this.certPairFormGroup.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.webInterfaceFormGroup.enable();

        if(this.certType === 'default'){
            this.certPairFormGroup.disable();
        }
        else{
            this.certPairFormGroup.enable();
        }
    }

    private updateModelValuesWebInterfaceFormGroup(): void {
        if (!this.isNullOrUndefined(this.changePassword) && this.isReadOnly === false) {
            const formValues = this.webInterfaceFormGroup.value;

            this.changePassword.oldPassword = formValues.oldPassword;
            this.changePassword.newPassword = formValues.newPassword;
            this.changePassword.confirmPassword = formValues.confirmPassword;

            this.updateSaveAllAction(this);
        }
    }

    private updateModelValuesCertForm(): void{
        if(!this.isNullOrUndefined(this.sslCertModel) && this.isReadOnly === false){
            const formValues = this.certPairFormGroup.value;

            this.sslCertModel.certName = formValues.certName;
        }
    }

    private getCACertName(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.isDeviceCapable(DeviceCapabilitiesEnum.mqttTLS).pipe(
            flatMap(
                isMqttTLS => {
                    if (isMqttTLS === true) {
                        return this._securityService.getCACertNames(process).pipe(
                            map(
                                names => {
                                    this.certCANames = names;
                                    return true;
                                }
                            )
                        );
                    } else {
                        return of(true);
                    }
                }
            )
        );
    }

    private getCertPairNames(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.isDeviceCapable(DeviceCapabilitiesEnum.multiCert).pipe(
            flatMap(
                isMultiCert => {
                    if (isMultiCert === true) {
                        return this._securityService.getCertPairNames(process).pipe(
                            map(
                                names => {
                                    this.certPairNames = names;
                                    return true;
                                }
                            )
                        );
                    } else {
                        return of(true);
                    }
                }
            )
        );
    }

    private validateCACertFile(file: File): Observable<boolean> {
        const regex = /-----BEGIN CERTIFICATE-----[\s\S]*?-----END CERTIFICATE-----/g;

        return new Observable((observer: Observer<boolean>) => {
            const reader = new FileReader();

            reader.onload = () => {
                const certValue = reader.result as string;
                observer.next(regex.test(certValue));
                observer.complete();
            };

            reader.readAsText(new Blob([file]));
        });
    }

    private validatePublicKeyFile(file: File): Observable<boolean> {
        const regex = /-----BEGIN CERTIFICATE-----[\s\S]*?-----END CERTIFICATE-----/g;

        return new Observable((observer: Observer<boolean>) => {
            const reader = new FileReader();

            reader.onload = () => {
                const certValue = reader.result as string;
                observer.next(regex.test(certValue));
                observer.complete();
            };

            reader.readAsText(new Blob([file]));
        });
    }

    private validatePrivateKeyFile(file: File): Observable<boolean> {
        const regex = /-----BEGIN [a-zA-Z ]*PRIVATE KEY-----[\s\S]*?-----END [a-zA-Z ]*PRIVATE KEY-----/g;

        return new Observable((observer: Observer<boolean>) => {
            const reader = new FileReader();

            reader.onload = () => {
                const certValue = reader.result as string;
                observer.next(regex.test(certValue));
                observer.complete();
            };

            reader.readAsText(new Blob([file]));
        });
    }

    private uploadPrivateKeyCertFile(file: File): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            const reader = new FileReader();

            reader.onload = () => {
                const arrayBuffer = reader.result;
                const bytes = new Uint8Array(arrayBuffer as any);

                this.sslCertModel.privateKey = FileUtility.Uint8ToBase64(bytes);

                observer.next(true);
                observer.complete();
            };

            reader.readAsArrayBuffer(new Blob([file]));
        });
    }

    private uploadPublicKeyCertFile(file: File): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            const reader = new FileReader();

            reader.onload = () => {
                const arrayBuffer = reader.result;
                const bytes = new Uint8Array(arrayBuffer as any);

                this.sslCertModel.publicKey = FileUtility.Uint8ToBase64(bytes);

                observer.next(true);
                observer.complete();
            };

            reader.readAsArrayBuffer(new Blob([file]));
        });
    }

    private uploadCACertFile(file: File): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            const reader = new FileReader();

            reader.onload = () => {
                const arrayBuffer = reader.result;
                const bytes = new Uint8Array(arrayBuffer as any);

                const cert = new TLSCertModel();
                cert.data = FileUtility.Uint8ToBase64(bytes);
                cert.name = file.name;

                this.addSubscription(this._securityService.uploadCACert(cert).subscribe(() => {
                    this.addSubscription(this.getCACertName().subscribe(() => {
                        observer.next(true);
                        observer.complete();
                    }));
                }), this.uploadCertProcess);
            };

            reader.readAsArrayBuffer(new Blob([file]));
        });
    }
}
