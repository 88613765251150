<div class="rift-diagnosticspackage-title"
    mat-dialog-title>
    <div fxLayout="row"
        fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="testClass">
                Diagnostics Packages
            </div>            
        </div>
        <div fxFlex></div>        
    </div>
</div>
<div class="rift-diagnosticspackage-content"
    mat-dialog-content
    fxLayout="column">
    <mat-tab-group mat-align-tabs="start" animationDuration="0ms" [selectedIndex]="currentTabIndex" (selectedIndexChange)="tabIndexChanged($event)">
        <mat-tab *ngFor="let tab of deviceTabs" [label]="tab" [disabled]="creatingPackage === true">
            <div fxLayout="row">
                <div fxFlex></div>
                <button (click)="create(tab)"
                [disabled]="downloadProcess.isRunning === true || deleteProcess.isRunning === true || creatingPackage === true"
                mat-raised-button
                color="primary"
                class="create-diag-button">Create Diagnostics Package</button>
            </div>            
            <shr-table [hideMatCard]="true"
                [filterEnabled]="false"
                [dataExportEnabled]="false"
                [dataSource]="dataSource"
                [displayedColumns]="displayedColumns">
                <ng-template #shrTableActions
                    let-vm>
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        <button *ngIf="downloadProcess.isRunning === false"
                            matTooltip="Download"
                            mat-icon-button
                            (click)="download(tab, vm)"
                            [disabled]="creatingPackage === true">
                            <mat-icon>cloud_download</mat-icon>
                        </button>
                        <button *ngIf="deleteProcess.isRunning === false"
                            matTooltip="Delete"
                            mat-icon-button
                            (click)="delete(tab, vm)"
                            [disabled]="creatingPackage === true">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </ng-template>
                <ng-container matColumnDef="packageName">
                    <th mat-header-cell
                        *matHeaderCellDef> Package </th>
                    <td mat-cell
                        *matCellDef="let vm"
                        matTooltip="{{vm.item}}">
                        {{vm.item}}
                    </td>
                </ng-container>        
            </shr-table>
        </mat-tab>
    </mat-tab-group>    
</div>
<div class="rift-diagnosticspackage-actions"
    mat-dialog-actions>
    <button (click)="close()"
        [disabled]="downloadProcess.isRunning === true || deleteProcess.isRunning === true"
        mat-raised-button
        color="primary">Close</button>
    <div fxFlex></div>
</div>