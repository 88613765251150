export enum MQTTTopicIdentifierEnum {
    counts = 0,
    status = 1,
    targets = 2,
    liveCounts = 3,
    deviceInfo = 4,
    events = 5
}

export class MQTTTopicIdentifierEnumHelpers {
    public static fromRestApi(value: string): MQTTTopicIdentifierEnum {
        switch (value) {
            case 'counts':
                return MQTTTopicIdentifierEnum.counts;
            case 'status':
                return MQTTTopicIdentifierEnum.status;
            case 'targets':
                return MQTTTopicIdentifierEnum.targets;
            case 'live_counts':
                return MQTTTopicIdentifierEnum.liveCounts;
            case 'deviceInfo':
                return MQTTTopicIdentifierEnum.deviceInfo;
            case 'events':
                return MQTTTopicIdentifierEnum.events;
        }
    }

    public static toRestApi(value: MQTTTopicIdentifierEnum): string {
        switch (value) {
            case MQTTTopicIdentifierEnum.counts:
                return 'counts';
            case MQTTTopicIdentifierEnum.status:
                return 'status';
            case MQTTTopicIdentifierEnum.targets:
                return 'targets';
            case MQTTTopicIdentifierEnum.liveCounts:
                return 'live_counts';
            case MQTTTopicIdentifierEnum.deviceInfo:
                return 'deviceInfo';
            case MQTTTopicIdentifierEnum.events:
                return 'events';
        }
    }

    public static getIdentifierUrl(value: MQTTTopicIdentifierEnum, friendlySerial: string): string {
        switch (value) {
            case MQTTTopicIdentifierEnum.counts:
                return `irisys/${friendlySerial}/counts`;
            case MQTTTopicIdentifierEnum.status:
                return `irisys/${friendlySerial}/status`;
            case MQTTTopicIdentifierEnum.targets:
                return `irisys/${friendlySerial}/targets`;
            case MQTTTopicIdentifierEnum.liveCounts:
                return `irisys/${friendlySerial}/live_counts`;
            case MQTTTopicIdentifierEnum.deviceInfo:
                return `irisys/${friendlySerial}/deviceInfo`;
            case MQTTTopicIdentifierEnum.events:
                return `irisys/${friendlySerial}/events`;
        }
    }

    public static getDisplayName(value: MQTTTopicIdentifierEnum): string {
        switch (value) {
            case MQTTTopicIdentifierEnum.counts:
                return 'Counts';
            case MQTTTopicIdentifierEnum.status:
                return 'Status';
            case MQTTTopicIdentifierEnum.targets:
                return 'Targets';
            case MQTTTopicIdentifierEnum.liveCounts:
                return 'Live Counts';
            case MQTTTopicIdentifierEnum.deviceInfo:
                return 'Device Info';
            case MQTTTopicIdentifierEnum.events:
                return 'Events';
        }
    }

    public static hasPeriod(value: MQTTTopicIdentifierEnum): boolean {
        switch (value) {
            case MQTTTopicIdentifierEnum.counts:
                return true;
            case MQTTTopicIdentifierEnum.status:
                return false;
            case MQTTTopicIdentifierEnum.targets:
                return false;
            case MQTTTopicIdentifierEnum.liveCounts:
                return false;
            case MQTTTopicIdentifierEnum.deviceInfo:
                return false;
            case MQTTTopicIdentifierEnum.events:
                return false;
        }
    }

    public static hasFilter(value: MQTTTopicIdentifierEnum): boolean {
        switch (value) {
            case MQTTTopicIdentifierEnum.counts:
                return false;
            case MQTTTopicIdentifierEnum.status:
                return false;
            case MQTTTopicIdentifierEnum.targets:
                return false;
            case MQTTTopicIdentifierEnum.liveCounts:
                return false;
            case MQTTTopicIdentifierEnum.deviceInfo:
                return false;
            case MQTTTopicIdentifierEnum.events:
                return true;
        }
    }

    public static getQosDefault(value: MQTTTopicIdentifierEnum): number {
        switch (value) {
            case MQTTTopicIdentifierEnum.counts:
                return 2;
            case MQTTTopicIdentifierEnum.status:
                return 2;
            case MQTTTopicIdentifierEnum.targets:
                return 0;
            case MQTTTopicIdentifierEnum.liveCounts:
                return 1;
            case MQTTTopicIdentifierEnum.deviceInfo:
                return 2;
            case MQTTTopicIdentifierEnum.events:
                return 0;
        }
    }

    public static getRetainDefault(value: MQTTTopicIdentifierEnum): boolean{
        switch (value) {
            case MQTTTopicIdentifierEnum.counts:
                return false;
            case MQTTTopicIdentifierEnum.status:
                return false;
            case MQTTTopicIdentifierEnum.targets:
                return false;
            case MQTTTopicIdentifierEnum.liveCounts:
                return true;
            case MQTTTopicIdentifierEnum.deviceInfo:
                return true;
            case MQTTTopicIdentifierEnum.events:
                return false;
        }
    }

    public static getPeriodDefault(value: MQTTTopicIdentifierEnum): number {
        switch (value) {
            case MQTTTopicIdentifierEnum.counts:
                return 60;
            case MQTTTopicIdentifierEnum.status:
                return null;
            case MQTTTopicIdentifierEnum.targets:
                return null;
            case MQTTTopicIdentifierEnum.liveCounts:
                return null;
            case MQTTTopicIdentifierEnum.deviceInfo:
                return null;
            case MQTTTopicIdentifierEnum.events:
                return null;
        }
    }
}
