<div #container
    *ngIf="show === true && canShow === true"
    class="float-dialog rift-multi-unit-alignment-device-list"
    (click)="onMenuClick()"
    ngResizable
    #resizable="ngResizable"
    (rzStop)="onRzStop($event)"
    (rzStart)="onRzStart($event)"
    (rzResizing)="onRzResizing($event)"
    [rzMinHeight]="minHeight"
    [rzMinWidth]="minWidth"
    [rzMaxWidth]="maxWidth"
    [rzMaxHeight]="maxHeight"
    ngDraggable
    #draggable="ngDraggable"
    (started)="onDrStarted($event)"
    (stopped)="onDrStopped($event)"
    (edge)="onDrEdge($event)"
    (movingOffset)="onDrMovingOffset($event)"
    (endOffset)="onDrEndOffset($event)"
    [handle]="title"
    [bounds]="bounds"
    [position]="position"
    [inBounds]="!isNullOrUndefined(bounds)">
    <div #title
        class="title drag-handle"
        fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex
            fxLayout="row"
            fxLayoutAlign="start center">
            <div fxLayout="column">
                <div class="header">
                    Alignment
                </div>
            </div>
            <div fxFlex></div>
            <div class="actions">
                <button class="flat-icon-button"
                    [disabled]="saveAllChangesProcess.isRunning === true"
                    mat-stroked-button matTooltip="Save" (click)="onSave()">
                    <mat-icon>save</mat-icon>
                </button>
                <button class="flat-icon-button"
                    [disabled]="saveAllChangesProcess.isRunning === true"
                    mat-stroked-button matTooltip="Separate Devices" (click)="onSeparateDevices()">
                    <mat-icon>grid_view</mat-icon>
                </button>
                <button class="flat-icon-button"
                    [disabled]="saveAllChangesProcess.isRunning === true"
                    mat-stroked-button matTooltip="Exit Alignment" (click)="onExit()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="content"
        fxLayout="column">
        <div #deviceList
            class="device-list"
            [ngStyle]="{'height': devicesListHeight + 'px'}">
            <div *ngFor="let vm of devices"
                class="device"
                fxLayout="row"
                fxLayoutAlign="start center"
                (click)="onDeviceSelected(vm)">
                <div fxLayout="column">
                    <div class="header"
                        fxLayout="row"
                        fxLayoutAlign="start center">
                        <div class="value">
                            {{ vm.deviceModel.serialNumber }} ({{ vm.deviceModel.master ? 'Master' : 'Node' }})
                        </div>
                    </div>
                    <div fxLayout="column">
                        <div [hidden]="vm.video.visible === false" fxLayout="row">
                            <mat-label style="font-size: 10px; margin-top:16px;" [hidden]="vm.video.visible === false">Transparency: </mat-label>
                            <div [hidden]="vm.video.visible === false">
                                <mat-slider min="0.25" max="1" step="0.05" value="1" (change)="onAlphaSliderChange(vm, $event)" #alphaSlider></mat-slider>
                            </div>
                        </div>
                        <div fxLayout="row" *ngIf="vm.video.visible === true">
                            <mat-form-field class="position-values">
                                <mat-label>
                                    X:
                                </mat-label>
                                <input riftUnitsOfMeasurementInput #xInput=riftUnitsOfMeasurementInput
                                    [metricMin]="-10000" [metricMax]="10000"
                                    [metricUnit]="UnitOfMeasurementEnum.centimeter" [imperialUnit]="UnitOfMeasurementEnum.inch"
                                    [value]="uomAdjustToCurrentUOM(vm.deviceModel.x)" (change)="onXPosChange(vm, $event)">
                                <span matSuffix>
                                    {{xInput.suffix}}
                                </span>                                
                            </mat-form-field>
                            <mat-form-field class="position-values">
                                <mat-label>
                                    Y:
                                </mat-label>
                                <input riftUnitsOfMeasurementInput #yInput=riftUnitsOfMeasurementInput
                                    [metricMin]="-10000" [metricMax]="10000"
                                    [metricUnit]="UnitOfMeasurementEnum.centimeter" [imperialUnit]="UnitOfMeasurementEnum.inch"
                                    [value]="uomAdjustToCurrentUOM(vm.deviceModel.y)" (change)="onYPosChange(vm, $event)">
                                <span matSuffix>
                                    {{yInput.suffix}}
                                </span>
                            </mat-form-field>
                            <mat-form-field class="position-values">
                                <mat-label>
                                    Yaw:
                                </mat-label>
                                <input type="number" matInput [value]="radToDegree(vm.deviceModel.gVector.yaw)" step="0.1" min="-0.1" max="360" (change)="onYawChange(vm, $event)">
                                <span matSuffix>&#176;</span>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div fxFlex></div>
                <div class="actions">
                    <button
                        (click)="onShowHideDeviceClick(vm, $event)"
                        class="flat-icon-button"
                        mat-stroked-button
                        [matTooltip]="vm.video.visible === true ? 'Hide device' : 'Show device'">
                        <shr-fa [name]="vm.video.visible === true ? 'eye' : 'eye-slash'"></shr-fa>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>