import { Injectable } from '@angular/core';
import { DiscoveredDeviceCollectionModel } from '@rift/models/restapi/DiscoveredDeviceCollection.Model';
import { NodeInfoCollectionModel } from '@rift/models/restapi/NodeInfoCollection.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiWideTrackerService } from '@rift/service/restapi/v1/RestApi.WideTracker.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { ClientConnectionCollectionModel } from '@rift/models/restapi/ClientConnectionCollection.Model';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';

@Injectable()
export class WideTrackerService extends RiftBaseService {

    private _discoveredDevicesLoadingTracker = new ObservableTracker<DiscoveredDeviceCollectionModel>();
    private _getNodeListLoadingTracker = new ObservableTracker<NodeInfoCollectionModel>();
    private _setAsMasterLoadingTracker = new ObservableTracker<ResultModel>();
    private _setAsNodeLoadingTracker = new ObservableTracker<ResultModel>();
    private _setMasterAndNodesLoadingTracker = new ObservableTracker<ResultModel>();
    private _updateConfigLoadingTracker = new ObservableTracker<ResultModel>();
    private _updateNodeListLoadingTracker = new ObservableTracker<ResultModel>();
    private _validateConfigLoadingTracker = new ObservableTracker<boolean>();

    public constructor(
        private readonly _restApiWideTrackerService: RestApiWideTrackerService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
    }

    public discoveredDevices(process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<DiscoveredDeviceCollectionModel> {
        return this._discoveredDevicesLoadingTracker
            .getLoading()
            .observable(this._restApiWideTrackerService.discoveredDevices(process, retryOptions));
    }

    public getNodeList(process?: ProcessMonitorServiceProcess): Observable<NodeInfoCollectionModel> {
        return this._getNodeListLoadingTracker
            .getLoading()
            .observable(this._restApiWideTrackerService.getNodeList(process));
    }

    public setAsMaster(serial: string, process?: ProcessMonitorServiceProcess, clientConnections: ClientConnectionCollectionModel = new ClientConnectionCollectionModel()): Observable<ResultModel> {
        return this._setAsMasterLoadingTracker
            .getLoading(serial)
            .observable(this._restApiWideTrackerService.setAsMaster(serial, clientConnections, process));
    }

    public setAsNode(serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setAsNodeLoadingTracker
            .getLoading(serial)
            .observable(this._restApiWideTrackerService.setAsNode(serial, process));
    }

    public setMasterAndNodes(serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setMasterAndNodesLoadingTracker
            .getLoading(serial)
            .observable(this._restApiWideTrackerService.setMasterAndNodes(serial, process));
    }

    public updateConfig(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateConfigLoadingTracker
            .getLoading()
            .observable(this._restApiWideTrackerService.updateConfig(process));
    }

    public updateNodeList(serials: Array<string>, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateNodeListLoadingTracker
            .getLoading(serials)
            .observable(this._restApiWideTrackerService.updateNodeList(serials, process));
    }

    public validateConfig(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._validateConfigLoadingTracker
            .getLoading()
            .observable(this._restApiWideTrackerService.validateConfig(process));
    }
}
