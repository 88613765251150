import { IRestModel } from '@shared/interface/IRestModel';
import { ITrackPropertyChanges } from '@shared/interface/ITrackPropertyChanges';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

/**
 * A tracked item in the change tracker.
 *
 * @export
 * @class TrackedItem
 * @template T
 */
export class TrackedItem<T extends IRestModel & ITrackPropertyChanges> {
    /**
     * The item.
     *
     * @type {T}
     * @memberof TrackedItem
     */
    public item: T;
    /**
     * The original index of the item.
     *
     * @type {number}
     * @memberof TrackedItem
     */
    public originalIndex: number = -1;
    /**
     * True if the item is new.
     *
     * @type {boolean}
     * @memberof TrackedItem
     */
    public isNew: boolean = false;
    /**
     * True if the item is removed.
     *
     * @type {boolean}
     * @memberof TrackedItem
     */
    public isRemoved: boolean = false;
    /**
     *
     *
     * @readonly
     * @type {boolean}
     * @memberof TrackedItem
     */
    public get hasChanges(): boolean {
        return this.isNew || this.isRemoved;
    }
}

/**
 * Tracks arrays of rest models that implement ITrackPropertyChanges
 *
 * @export
 * @class RestModelChangeTrackerArray
 * @extends {Array<T>}
 * @template T
 */
export class RestModelChangeTrackerArray<T extends IRestModel & ITrackPropertyChanges> extends Array<T>  {
    private _trackedItems: Array<TrackedItem<T>> = [];
    private _originalValuesSet: boolean = false;

    /**
     * Creates an instance of RestModelChangeTrackerArray.
     *
     * @param {...Array<T>} items
     * @memberof RestModelChangeTrackerArray
     */
    public constructor(...items: Array<T>) {
        super();
        Object.setPrototypeOf(this, Object.create(RestModelChangeTrackerArray.prototype));
        if (!isNullOrUndefined(items)) {
            this.push(...items);
            this.originalValuesSet();
        }
    }

    /**
     * True if has changes. else false.
     *
     * @readonly
     * @type {boolean}
     * @memberof RestModelChangeTrackerArray
     */
    public get hasChanges(): boolean {
        return this.removedItems.length > 0 || this.newItems.length > 0 || this.updatedItems.length > 0;
    }

    public get newItems(): Array<T> {
        const newItems: T[] = [];

        const length = this._trackedItems.length;
        for (let index = 0; index < length; index++) {
            const item = this._trackedItems[index];
            if (item.isNew === true) {
                newItems.push(item.item);
            }
        }

        return newItems;
    }

    public get removedItems(): Array<T> {
        const removedItems: T[] = [];

        const length = this._trackedItems.length;
        for (let index = 0; index < length; index++) {
            const item = this._trackedItems[index];
            if (item.isRemoved === true) {
                removedItems.push(item.item);
            }
        }

        return removedItems;
    }

    public get updatedItems(): Array<T> {
        const updatedItems: T[] = [];

        const length = this._trackedItems.length;
        for (let index = 0; index < length; index++) {
            const item = this._trackedItems[index];
            if (item.item.hasChanges === true) {
                updatedItems.push(item.item);
            }
        }

        return updatedItems;
    }

    public get updatedNotNewItems(): Array<T> {
        const updatedNotNewItems: T[] = [];

        const length = this._trackedItems.length;
        for (let index = 0; index < length; index++) {
            const item = this._trackedItems[index];
            if (item.item.hasChanges === true && item.isNew === false) {
                updatedNotNewItems.push(item.item);
            }
        }

        return updatedNotNewItems;
    }

    public isNew(item: T): boolean {
        return this._trackedItems.find(ti => ti.item.uniqueId === item.uniqueId).isNew;
    }

    public isRemoved(item: T): boolean {
        return this._trackedItems.find(ti => ti.item.uniqueId === item.uniqueId).isRemoved;
    }

    public isUpdated(item: T): boolean {
        return this._trackedItems.find(ti => ti.item.uniqueId === item.uniqueId).item.hasChanges;
    }

    public filterOnState(callbackfn: (value: TrackedItem<T>, index: number, array: TrackedItem<T>[]) => T): Array<T> {
        const items: T[] = [];
        const length = this._trackedItems.length;
        for (let index = 0; index < length; index++) {
            const item = this._trackedItems[index];
            if (callbackfn(item, index, this._trackedItems)) {
                items.push(item.item);
            }
        }

        return items;
    }

    public push(...items: Array<T>): number {
        if (this._originalValuesSet === true) {
            const length = items.length;
            for (let index = 0; index < length; index++) {
                this.itemAdded(items[index]);
            }
        }

        return super.push(...items);
    }

    public pop(): T | undefined {
        const removedItem = super.pop();

        if (this._originalValuesSet === true) {
            this.itemRemoved(removedItem);
        }

        return removedItem;
    }

    public shift(): T | undefined {
        const removedItem = super.shift();

        if (this._originalValuesSet === true) {
            this.itemRemoved(removedItem);
        }

        return removedItem;
    }

    public unshift(...items: Array<T>): number {
        if (this._originalValuesSet === true) {
            const length = items.length;
            for (let index = 0; index < length; index++) {
                this.itemAdded(items[index]);
            }
        }

        return super.unshift(...items);
    }

    public splice(start: number, deleteCount?: number, ...items: Array<T>): Array<T> {
        const removedItems = super.splice(start, deleteCount, ...items);

        if (this._originalValuesSet === true) {
            if (!isNullOrUndefined(items)) {
                const length = items.length;
                for (let index = 0; index < length; index++) {
                    this.itemAdded(items[index]);
                }
            }

            if (!isNullOrUndefined(removedItems) && removedItems.length > 0) {
                const length = removedItems.length;
                for (let index = 0; index < length; index++) {
                    this.itemRemoved(removedItems[index]);
                }
            }
        }

        return removedItems;
    }

    public clear(): void {
        this.splice(0, this.length);
    }

    public originalValuesSet(): void {
        this._trackedItems = [];
        const length = this.length;
        for (let index = 0; index < length; index++) {
            this.itemAdded(this[index], index, false);
        }
        this._originalValuesSet = true;
    }

    public clearChanges(): void {

        this._originalValuesSet = false;

        super.splice(0, this.length);

        let trackedItemsLength = this._trackedItems.length;
        let originalTrackedItems: TrackedItem<T>[] = [];
        for (let index = 0; index < trackedItemsLength; index++) {
            const trackedItem = this._trackedItems[index];
            if (trackedItem.isNew === false) {
                originalTrackedItems.push(trackedItem);
            }
        }
        originalTrackedItems = originalTrackedItems.sort((a, b) => a.originalIndex - b.originalIndex);

        const originalTrackedItemsLength = originalTrackedItems.length;
        for (let index = 0; index < originalTrackedItemsLength; index++) {
            const trackedItem = originalTrackedItems[index];
            trackedItem.isRemoved = false;
            trackedItem.isNew = false;
            trackedItem.item.clearChanges();
        }
        this._trackedItems = originalTrackedItems;

        trackedItemsLength = this._trackedItems.length;
        for (let index = 0; index < trackedItemsLength; index++) {
            const titem = this._trackedItems[index];
            super.push(titem.item);
        }

        this._originalValuesSet = true;
    }

    public commitChanges(): void {
        this._originalValuesSet = false;
        const trackedItemsLength = this._trackedItems.length;

        let originalTrackedItems: TrackedItem<T>[] = [];
        for (let index = 0; index < trackedItemsLength; index++) {
            const trackedItem = this._trackedItems[index];
            if (trackedItem.isRemoved === false) {
                originalTrackedItems.push(trackedItem);
            }
        }
        originalTrackedItems = originalTrackedItems.sort((a, b) => a.originalIndex - b.originalIndex);

        const originalTrackedItemsLength = originalTrackedItems.length;
        for (let index = 0; index < originalTrackedItemsLength; index++) {
            const trackedItem = originalTrackedItems[index];
            trackedItem.isRemoved = false;
            trackedItem.isNew = false;
            trackedItem.originalIndex = index;
            trackedItem.item.commitChanges();
        }
        this._trackedItems = originalTrackedItems;

        this._originalValuesSet = true;
    }

    public toRestApiModel(): Array<any> {
        const items: any[] = [];
        const length = this.length;
        for (let i = 0; i < length; i++) {
            items.push(this[i].toRestApiModel());
        }

        return items;
    }

    private itemRemoved(item: T): void {
        if (isNullOrUndefined(item)) {
            throw new Error('Cannot add null or undefined items to ChangeTrackerArray.');
        }

        const trackedItem = this._trackedItems.find(ti => ti.item.uniqueId === item.uniqueId);
        if (!isNullOrUndefined(trackedItem)) {
            if (trackedItem.isNew === true) {
                const trackedItemIndex = this._trackedItems.findIndex(ti => ti.item.uniqueId === item.uniqueId);
                this._trackedItems.splice(trackedItemIndex, 1);
            } else {
                trackedItem.isRemoved = true;
            }
        }
    }

    private itemAdded(item: T, originalIndex: number = -1, isNew: boolean = true): void {
        if (isNullOrUndefined(item)) {
            throw new Error('Cannot add null or undefined items to ChangeTrackerArray.');
        }

        const trackedItem = new TrackedItem<T>();
        trackedItem.item = item;
        trackedItem.isNew = isNew;
        trackedItem.originalIndex = originalIndex;
        this._trackedItems.push(trackedItem);
    }
}
