<div [ngSwitch]="sectionChange.action">
    <div class="action-header"
        *ngSwitchCase="SectionActionEnum.update">
        <ng-template [ngIf]="isIdArray === true">
            Updated {{ ConfigSectionsEnumHelpers.toDisplayName(sectionChange.section) }} at index {{ sectionChange.indexFrom }} to index
            {{ sectionChange.indexTo }}
        </ng-template>
        <ng-template [ngIf]="isNonIdArray === true">
            Updated {{ ConfigSectionsEnumHelpers.toDisplayName(sectionChange.section) }} at index {{ sectionChange.indexFrom }}
        </ng-template>
        <ng-template [ngIf]="isIdArray === false && isNonIdArray === false">
            Update {{ ConfigSectionsEnumHelpers.toDisplayName(sectionChange.section) }}
        </ng-template>
    </div>
    <div class="action-header"
        *ngSwitchCase="SectionActionEnum.add">
        <ng-template [ngIf]="isIdArray === true">
            Updated {{ ConfigSectionsEnumHelpers.toDisplayName(sectionChange.section) }} at index {{ sectionChange.indexFrom }} to index
            {{ sectionChange.indexTo }}
        </ng-template>
        <ng-template [ngIf]="isNonIdArray === true">
            Removed {{ ConfigSectionsEnumHelpers.toDisplayName(sectionChange.section) }}
        </ng-template>
        <ng-template [ngIf]="isIdArray === false && isNonIdArray === false">
            Added {{ ConfigSectionsEnumHelpers.toDisplayName(sectionChange.section) }}
        </ng-template>
    </div>
    <div class="action-header"
        *ngSwitchCase="SectionActionEnum.remove">
        <ng-template [ngIf]="isIdArray === true">
            Removed {{ ConfigSectionsEnumHelpers.toDisplayName(sectionChange.section) }} at index {{ sectionChange.indexFrom }}
        </ng-template>
        <ng-template [ngIf]="isNonIdArray === true">
            Add {{ ConfigSectionsEnumHelpers.toDisplayName(sectionChange.section) }}
        </ng-template>
        <ng-template [ngIf]="isIdArray === false && isNonIdArray === false">
            Add {{ ConfigSectionsEnumHelpers.toDisplayName(sectionChange.section) }}
        </ng-template>
    </div>
</div>