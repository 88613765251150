import { Component, Inject, Injector, OnInit, HostBinding } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, of, zip } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { FormatSystemStorageEnum, FormatSystemStorageEnumHelpers } from '@shared/enum/FormatSystemStorage.Enum';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { Dictionary } from '@shared/generic/Dictionary';


export class FormatSystemStorageDialogData {

}

export class FormatSystemStorageDialogResult {
    public serialNumber: string;
    public formatType: FormatSystemStorageEnum;
    public startFormat: boolean = false;

    public constructor(startFormat: boolean, serialNumber: string, formatType: FormatSystemStorageEnum){
        this.startFormat = startFormat;
        this.formatType = formatType;
        this.serialNumber = serialNumber;
    }
}

export class DeviceInfo{
    public serialNumber: string;
    public master: boolean;
    public unitGen: UnitGenerationEnum;
    public capabilities: Dictionary<DeviceCapabilitiesEnum, boolean>;
}

@Component({
    selector: 'rift-format-system-storage',
    templateUrl: './FormatSystemStorage.Component.html',
    styleUrls: ['./FormatSystemStorage.Component.scss']
})
export class FormatSystemStorageComponent extends RiftBaseComponent implements OnInit, ILoadDate {
    public devices: Array<DeviceInfo> = new Array<DeviceInfo>();
    public FormatSystemStorageEnum = FormatSystemStorageEnum;
    public selectedFormat: FormatSystemStorageEnum = FormatSystemStorageEnum.data;
    public isOnDevice: boolean = null;
    public validFormatTypes: Array<FormatSystemStorageEnum> = [];
    public FormatSystemStorageEnumHelpers = FormatSystemStorageEnumHelpers;

    public get selectedDevice(): string{
        return this._selectedDevice;
    }

    public set selectedDevice(value: string){
        this._selectedDevice = value;

        this.validFormatTypes = [];

        const device = this.devices.find(d => d.serialNumber === this._selectedDevice);

        if (!this.isNullOrUndefined(device)){
            if (this.isOnDevice === true){
                if (device.capabilities.get(DeviceCapabilitiesEnum.powerOverEthernet) === true){
                    this.validFormatTypes.push(FormatSystemStorageEnum.data);
                    this.validFormatTypes.push(FormatSystemStorageEnum.system);
                }
                else{
                    this.validFormatTypes.push(FormatSystemStorageEnum.data);
                }
            }
            else{
                if (device.capabilities.get(DeviceCapabilitiesEnum.powerOverEthernet) === true){
                    this.validFormatTypes.push(FormatSystemStorageEnum.all);
                    this.validFormatTypes.push(FormatSystemStorageEnum.data);
                    this.validFormatTypes.push(FormatSystemStorageEnum.system);
                    this.validFormatTypes.push(FormatSystemStorageEnum.webfiles);
                }
                else{
                    this.validFormatTypes.push(FormatSystemStorageEnum.data);
                }
            }

            this.selectedFormat = FormatSystemStorageEnum.data;
        }
    }

    @HostBinding()
    public id: string = 'rift-format-system-storage';

    private _selectedDevice: string = null;

    public constructor(
        private readonly _eventsService: EventsService,
        @Inject(MAT_DIALOG_DATA) private readonly _data: FormatSystemStorageDialogData,
        private readonly _dialog: MatDialog,
        private readonly _dialogRef: MatDialogRef<FormatSystemStorageComponent>,
        private readonly _deviceService: DeviceService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess('FormatSystemStorageComponent', this.loadDataProcessText);

        this.isOnDevice = this.connectionService.isOnDevice;
        this.initConnectionState();
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public close(): void {
        this._dialogRef.close(new FormatSystemStorageDialogResult(false, null, null));
    }

    public format(): void{
        // Close the dialog with the correct info
        this._dialogRef.close(new FormatSystemStorageDialogResult(true, this.selectedDevice, this.selectedFormat));
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this.devices = [];

        const loadDataSub = zip(
            this._deviceService.getDevices(process).pipe(
                flatMap(devices => {
                    devices.items.sort((a, b) => a.master === true ? -1 : 1).forEach(d => {
                        const newInfo: DeviceInfo = new DeviceInfo();
                        newInfo.serialNumber = d.serialNumber;
                        newInfo.master = d.master;
                        newInfo.unitGen = d.unitGen;
                        newInfo.capabilities = d.capabilities;

                        this.devices.push(newInfo);
                    });

                    this.devices.forEach(d => {
                        if (d.master === true){
                            this.selectedDevice = d.serialNumber;
                        }
                    });

                    return of(true);
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }
}
