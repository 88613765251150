import { Component, Injector, OnInit, HostBinding } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';

@Component({
    selector: 'em-documentation',
    templateUrl: './Documentation.Component.html',
    styleUrls: ['./Documentation.Component.scss']
})
export class DocumentationComponent extends BaseComponent implements OnInit {
    public static className: string = 'DocumentationComponent';

    @HostBinding()
    public id: string = 'em-documentation';

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }
}
