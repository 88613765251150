import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { RestApiService } from '@shared/service/restapi/RestApi.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { BaseModel } from '@shared/base/Base.Model';
import { Directive } from '@angular/core';
import { SortDirectionEnum } from '@shared/enum/SortDirection.Enum';


@Directive()
export abstract class EmRestApiService extends RestApiService {
    public constructor(
        private readonly _emDialogBase: MatDialog,
        private readonly _emUrlBase: string,
        private readonly _emHttpClient: HttpClient) {
        super(_emDialogBase, _emUrlBase, _emHttpClient);
    }

    protected setPaginationOptionsParams(paginationOptions: PaginationOptionsModel, params: HttpParams): HttpParams {
        if (!isNullOrUndefined(paginationOptions)) {
            params = params.append('page', isNullOrUndefined(paginationOptions.page) ? '1' : StringUtility.toString(paginationOptions.page));
            params = params.append('resultsPerPage', isNullOrUndefined(paginationOptions.resultsPerPage) ? '100' : StringUtility.toString(paginationOptions.resultsPerPage));

            if (!isNullOrUndefined(paginationOptions.columnSortingOptions) && paginationOptions.columnSortingOptions.length > 0) {
                const columnSortingOption = paginationOptions.columnSortingOptions[0];
                params = params.append('sortColumn', columnSortingOption.columnName);

                if (!isNullOrUndefined(columnSortingOption.matDirection)){
                    params = params.append('sortDirection', columnSortingOption.matDirection);
                }
                else{
                    params = params.append('sortDirection', columnSortingOption.sortDirection === SortDirectionEnum.ascending ? 'asc' : 'desc');
                }
            }

            if (!isNullOrUndefined(paginationOptions.columnFilterOptions) && paginationOptions.columnFilterOptions.length > 0) {
                const columnFilterOption = paginationOptions.columnFilterOptions[0];
                params = params.append('filterColumn', columnFilterOption.columnName);
                params = params.append('filter', columnFilterOption.filter);
            }
        }
        return params;
    }

    protected handleErrorOrThrow<T>(model: T): T {
        if (model instanceof BaseModel && !isNullOrUndefined(model.userCanceled)) {
            return model;
        }

        if (RestModelUtility.hasError(model)) {
            if (RestModelUtility.hasErrorMessage(model)) {
                throw new Error((model as any).error);
            }
            throw new Error('Rest API error');
        }
        return model;
    }
}
