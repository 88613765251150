<shr-nav-bar-pagination>
    <nav mat-tab-nav-bar>
        <a id="settings-site-link"
            mat-tab-link
            *ngIf="(isHostMaster() | async) === true"
            [routerLink]="siteRelativeUrl"
            routerLinkActive
            #site="routerLinkActive"
            [active]="site.isActive">
            Site
        </a>
        <a id="settings-network-link"
            mat-tab-link
            *ngIf="!(isDeviceType(UnitTypeEnum.relay) | async) === true"
            [routerLink]="networkRelativeUrl"
            routerLinkActive
            #network="routerLinkActive"
            [active]="network.isActive">
            Network
        </a>
        <a id="settings-wideTracker-link"
            mat-tab-link
            *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.multiUnitMk2) | async) === true"
            [routerLink]="wideTrackerRelativeUrl"
            routerLinkActive
            #wideTracker="routerLinkActive"
            [active]="wideTracker.isActive">
            Wide Tracker
        </a>
        <a id="settings-outboundConnections-link"
            mat-tab-link
            *ngIf="(isHostMaster() | async) === true && !(isDeviceType(UnitTypeEnum.relay) | async) === true"
            [routerLink]="outboundConnectionsRelativeUrl"
            routerLinkActive
            #outboundConnections="routerLinkActive"
            [active]="outboundConnections.isActive">
            Outbound Connections
        </a>
        <a id="settings-security-link"
            mat-tab-link
            *ngIf="!(isDeviceType(UnitTypeEnum.relay) | async) === true"
            [routerLink]="securityRelativeUrl"
            routerLinkActive
            #security="routerLinkActive"
            [active]="security.isActive">
            Security
        </a>
        <a id="settings-countLogging-link"
            mat-tab-link
            *ngIf="(isHostMaster() | async) === true"
            [routerLink]="countLoggingRelativeUrl"
            routerLinkActive
            #countLogging="routerLinkActive"
            [active]="countLogging.isActive">
            Count Logging
        </a>
        <a id="settings-bluetooth-link"
            mat-tab-link
            *ngIf="(isHostMaster() | async) === true && (isDeviceCapable(DeviceCapabilitiesEnum.bluetooth) | async) === true"
            [routerLink]="bluetoothRelativeUrl"
            routerLinkActive
            #bluetooth="routerLinkActive"
            [active]="bluetooth.isActive">
            Bluetooth
        </a>
        <a id="settings-httpPost-link"
            mat-tab-link
            *ngIf="(isHostMaster() | async) === true && (isDeviceCapable(DeviceCapabilitiesEnum.httpPost) | async) === true"
            [routerLink]="httpPostRelativeUrl"
            routerLinkActive
            #httpPost="routerLinkActive"
            [active]="httpPost.isActive">
            Http Post
        </a>
        <a id="settings-mqtt-link"
            mat-tab-link
            *ngIf="((isHostMaster() | async) === true && isDeviceCapable(DeviceCapabilitiesEnum.mqttPublish) | async) === true || ((isHostMaster() | async) === true && (isDeviceCapable(DeviceCapabilitiesEnum.mqttTLS) | async) === true) || ((isHostMaster() | async) === true && (isDeviceCapable(DeviceCapabilitiesEnum.mqttTargets) | async) === true)"
            [routerLink]="mqttRelativeUrl"
            routerLinkActive
            #mqtt="routerLinkActive"
            [active]="mqtt.isActive">
            MQTT
        </a>
        <a id="settings-relays-link"
            mat-tab-link
            *ngIf="(isHostMaster() | async) === true && (isDeviceCapable(DeviceCapabilitiesEnum.relayOut) | async) === true || (isDeviceCapable(DeviceCapabilitiesEnum.relayIn) | async) === true || (isDeviceCapable(DeviceCapabilitiesEnum.canIo) | async) === true"
            [routerLink]="relaysRelativeUrl"
            routerLinkActive
            #relays="routerLinkActive"
            [active]="relays.isActive">
            Relays
        </a>
        <a id="settings-counting-link"
            mat-tab-link
            *ngIf="(isHostMaster() | async) === true"
            [routerLink]="countingRelativeUrl"
            routerLinkActive
            #counting="routerLinkActive"
            [active]="counting.isActive">
            Counting
        </a>
        <a id="settings-video-link"
            mat-tab-link
            *ngIf="(isHostMaster() | async) === true && (isDeviceCapable(DeviceCapabilitiesEnum.video) | async) === true"
            [routerLink]="videoRelativeUrl"
            routerLinkActive
            #video="routerLinkActive"
            [active]="video.isActive">
            Video
        </a>
        <a id="settings-bacnet-link"
            mat-tab-link
            *ngIf="(isHostMaster() | async) === true && (isDeviceCapable(DeviceCapabilitiesEnum.bacnet) | async) === true"
            [routerLink]="bacnetRelativeUrl"
            routerLinkActive
            #bacnet="routerLinkActive"
            [active]="bacnet.isActive">
            BACnet
        </a>
        <a mat-tab-link
            *ngIf="(isDeviceGen(UnitGenerationEnum.kestrel) | async) === true || (isDeviceGen(UnitGenerationEnum.falcon) | async) === true"
            [routerLink]="configHistoryRelativeUrl"
            routerLinkActive
            #configHistory="routerLinkActive"
            [active]="configHistory.isActive">
            History
        </a>
    </nav>
</shr-nav-bar-pagination>
<div #mainContent
    id="rift-settings"
    class="settings main-content scroll-content">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>