import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface IVideoSessionProgressModel {
    type: string;
    progress: number;
    sessionId: number;
    isComplete: boolean;
    isQueued: boolean;
    isPaused: boolean;
    isCancel: boolean;
    serial: string;
}

export class VideoSessionProgressModel extends BaseModel implements IVideoSessionProgressModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'syncvideosession_case';

    public isIWebSocketModel: boolean = true;

    public type: string = null;
    public isCancel: boolean = null;
    public isComplete: boolean = null;
    public isPaused: boolean = null;
    public isQueued: boolean = null;
    public packetType: string = null;
    public progress: number = null;
    public sessionId: number = null;
    public serial: string = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        if (!isNullOrUndefined(message) && !isNullOrUndefined(message.data)) {
            this.sessionId = isNullOrUndefined(message.data.Id) ? null : message.data.Id;
            this.progress = isNullOrUndefined(message.data.Progress) ? null : message.data.Progress;
            this.isComplete = isNullOrUndefined(message.data.IsComplete) ? null : message.data.IsComplete;
            this.isQueued = isNullOrUndefined(message.data.IsQueued) ? null : message.data.IsQueued;
            this.isPaused = isNullOrUndefined(message.data.IsPaused) ? null : message.data.IsPaused;
            this.isCancel = isNullOrUndefined(message.data.IsCancel) ? null : message.data.IsCancel;
            this.serial = isNullOrUndefined(message.data.Serial) ? null : message.data.Serial;
        }
    }

    public toWebSocketMessage(): any {
        return {
            ...WebSocketModelUtility.toJson(this),
        };
    }
}
