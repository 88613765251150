import { LicenceItemModel } from '@em/models/restapi/LicenceItem.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { LicenceStateEnum } from '@shared/enum/LicenceState.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';


export class LicenceStatusModel extends BaseModel implements IRestModel {
    public capacity: number = null;
    public expiry: Date = null;
    public isLicensed: boolean = null;
    public licences: Array<LicenceItemModel> = null;
    public readonly isIRestModel = true;
    public state: LicenceStateEnum = null;
    private _expiryText: string = null;
    private _key: string;
    private _stateText: string;

    public constructor() {
        super();
    }

    public get expiryText(): string {
        return this._expiryText;
    }

    public get key(): string {
        return this._key;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-LicenceStatusModel');

        this.licences = RestModelUtility.loadFromArray(restModel.Licences, LicenceItemModel);
        this.expiry = DateTimeNonMomentUtility.fromRestApiDateTime(this.expiry);

        if (isNullOrUndefined(this._expiryText) || DateTimeUtility.isInvalidDate(this._expiryText)) {
            this._expiryText = DateTimeUtility.toShortDate(this.expiry);
        }

        if (isNullOrUndefined(this._stateText)) {
            switch (this.state) {
                case LicenceStateEnum.expiring:
                    this._stateText = 'Expiring';
                    break;
                case LicenceStateEnum.licensed:
                    this._stateText = 'Licensed';
                    break;
                case LicenceStateEnum.serverUnavailable:
                    this._stateText = 'Server Unavailable';
                    break;
                case LicenceStateEnum.unlicensed:
                    this._stateText = 'Unlicensed';
                    break;
            }
        }

        if (isNullOrUndefined(this._key) && !isNullOrUndefined(this.licences) && this.licences.length > 0) {
            this._key = this.licences[0].key;
        }
    }

    public get stateText(): string {
        return this._stateText;
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
