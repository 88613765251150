<ng-template [ngIf]="viewMode === 'button'">
    <button class="em-device-serial-button" mat-raised-button color="primary" (click)="onClick()"
        [shrDeviceInfoTooltip]="getDeviceInfo.bind(this)">{{friendlySerial}}</button>
</ng-template>
<ng-template [ngIf]="viewMode === 'icon'">
    <shr-fa class="em-device-serial-icon" (click)="onClick()" [shrDeviceInfoTooltip]="getDeviceInfo.bind(this)"
        name="plug"></shr-fa>
</ng-template>
<ng-template [ngIf]="viewMode === 'link'">
    <button class="em-device-serial-link" (click)="onClick()" [shrDeviceInfoTooltip]="getDeviceInfo.bind(this)"
        mat-button>{{friendlySerial}}</button>
</ng-template>
