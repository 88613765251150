<div mat-dialog-title>
    Update Connection
</div>
<div mat-dialog-content
    fxLayout="column">
    <form [formGroup]="formGroup">
        <mat-form-field class="spacing-right">
            <input #address
                formControlName="address"
                type="text"
                matInput
                placeholder="Address">
            <mat-error>{{getFormControlErrorMessage(formGroup.controls['address'])}}
            </mat-error>
            <mat-hint align="end">{{address.value.length}} / 2083</mat-hint>
            <span matSuffix>{{ connection.propertyHasChangesText('address') }}</span>
        </mat-form-field>
        <mat-form-field>
            <input #port
                formControlName="port"
                type="number"
                matInput
                placeholder="Port">
            <mat-error>{{getFormControlErrorMessage(formGroup.controls['port'])}}
            </mat-error>
            <span matSuffix>{{ connection.propertyHasChangesText('port') }}</span>
        </mat-form-field>
        <mat-form-field>
            <input #username
                formControlName="username"
                type="text"
                matInput
                placeholder="Username (Vector Optional)">
            <mat-error>{{getFormControlErrorMessage(formGroup.controls['username'])}}
            </mat-error>
            <span matSuffix>{{ connection.propertyHasChangesText('username') }}</span>
        </mat-form-field>
        <mat-form-field>
            <input #password
                formControlName="password"
                type="password"
                matInput
                placeholder="Password (Vector Optional)">
            <mat-error>{{getFormControlErrorMessage(formGroup.controls['password'])}}
            </mat-error>
            <span matSuffix>{{ connection.propertyHasChangesText('password') }}</span>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button [disabled]="!isValidBase || !hasChangesBase "
        (click)="close()"
        mat-raised-button
        color="warn">Update</button>
    <div fxFlex></div>
    <button (click)="cancel()"
        mat-raised-button
        color="warn">Cancel</button>
</div>