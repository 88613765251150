import { Component, Injector, Input, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsCountingBaseComponent } from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ZoneTypeEnum, ZoneTypeEnumHelpers } from '@shared/enum/ZoneType.Enum';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';

@Component({
    selector: 'rift-settings-counting-zone',
    templateUrl: './Settings.Counting.Zone.Component.html',
    styleUrls: ['./Settings.Counting.Zone.Component.scss']
})
export class SettingsCountingZoneComponent extends SettingsCountingBaseComponent implements OnChanges {
    public static className: string = 'SettingsCountingZoneComponent';
    public static zoneTypeDefault: ZoneTypeEnum = ZoneTypeEnum.deferred;

    private _zoneRegister: RegisterBaseModel & { zoneType: ZoneTypeEnum } = null;

    @HostBinding()
    public id: string = 'rift-settings-counting-zone';

    public ZoneTypeEnum = ZoneTypeEnum;

    public form: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    @Input()
    public set register(value: RegisterBaseModel){
        this._zoneRegister = (value as RegisterBaseModel & { zoneType: ZoneTypeEnum });
    }

    public constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsCountingZoneComponent.className, 'Form values change');

        this.form = this._formBuilder.group(
            {
                zoneType: ['', Validators.compose([Validators.required])],
            }
        );

        this.addSubscription(this.observableHandlerBase(this.form.controls.zoneType.valueChanges, this.formValuesChangeProcess).subscribe(value => {
            if (!this.isNullOrUndefined(this._zoneRegister)) {
                this._zoneRegister.zoneType = value;
            }
        }), this.formValuesChangeProcess);

        this.formGroupTracker.track(this.form);

        this.initConnectionState();
    }

    public getSettingsDescription(): string {
        return this.isNullOrUndefined(this._zoneRegister) ? '' : ZoneTypeEnumHelpers.toFullLongName(this._zoneRegister.zoneType);
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this._zoneRegister) ? false : this._zoneRegister.propertyHasChanges('zoneType');
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this._zoneRegister = changes.register.currentValue;
            this.setDefaults();
            this.setFormValues();
        }
    }

    protected offline(): void {
        super.offline();
        this.form.disable();
    }

    protected online(): void {
        super.online();
        this.form.enable();
    }

    private setDefaults(): void {
        if (this.isNullOrUndefined(this._zoneRegister.zoneType)) {
            this._zoneRegister.zoneType = SettingsCountingZoneComponent.zoneTypeDefault;
        }
    }

    private setFormValues(): void {
        this.form.controls.zoneType.setValue(this.isNullOrUndefined(this._zoneRegister.zoneType) ? SettingsCountingZoneComponent.zoneTypeDefault : this._zoneRegister.zoneType, { emitEvent: false });
    }
}
