<div class="rift-settings-relays-limits-title"
    mat-dialog-title>
    Limits Settings
</div>
<div class="rift-settings-relays-limits-content"
    mat-dialog-content>
    <form [formGroup]="limitFormGroup"
        fxLayout="column">
        <div fxLayout="row"
            fxLayoutAlign="start center">
            <mat-checkbox #inputFilterLowerLimitEnabled
                formControlName="inputFilterLowerLimitEnabled"
                class="spacing-right"></mat-checkbox>
            <mat-form-field>
                <input type="number"
                    step="1"
                    #inputFilterLowerLimit
                    matInput
                    formControlName="inputFilterLowerLimit"
                    placeholder="Lower Limit">
                <mat-error>{{getFormControlErrorMessage(limitFormGroup.controls['inputFilterLowerLimit'])}}</mat-error>
            </mat-form-field>
            (ms)
        </div>
        <div fxLayout="row"
            fxLayoutAlign="start center">
            <mat-checkbox #inputFilterUpperLimitEnabled
                formControlName="inputFilterUpperLimitEnabled"
                class="spacing-right"></mat-checkbox>
            <mat-form-field>
                <input type="number"
                    step="1"
                    #inputFilterUpperLimit
                    matInput
                    formControlName="inputFilterUpperLimit"
                    placeholder="Upper Limit">
                <mat-error>{{getFormControlErrorMessage(limitFormGroup.controls['inputFilterUpperLimit'])}}</mat-error>
            </mat-form-field>
            (ms)
        </div>
    </form>
</div>
<div class="rift-settings-relays-limits-actions"
    mat-dialog-actions>
    <button [disabled]="!limitFormGroup.valid"
        (click)="apply()"
        mat-raised-button
        color="primary">Apply</button>
    <div fxFlex></div>
    <button (click)="cancel()"
        mat-raised-button
        color="warn">Cancel</button>
</div>