import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IDeviceAdvancedSettingsModel extends IRestModel {
    deferredInitialization: boolean;
}

export class DeviceAdvancedSettingsModel extends BaseModel implements IDeviceAdvancedSettingsModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public deferredInitialization: boolean = null;

    public constructor() {
        super();

        this.registerProperty('deferredInitialization');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-DeviceAdvancedSettingsModel');

        this.setPropertyOriginalValue('deferredInitialization', this.deferredInitialization);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
