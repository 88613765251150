export enum DeviceCapabilitiesEnum {
    beambreakMode = 1,
    bluetooth = 2,
    canIo = 3,
    configurableServerPort = 4,
    counts = 5,
    diagnostic = 6,
    height = 7,
    histograms = 8,
    httpPost = 9,
    pathMap = 10,
    relayIn = 11,
    relayOut = 12,
    sdi = 13,
    tamperDetect = 14,
    thermal = 15,
    timeOfFlight = 16,
    tool = 17,
    video = 18,
    videoRecording = 19,
    zones = 20,
    markedTargets = 21,
    multiUnitMk2 = 22,
    ntp = 23,
    mqttPublish = 24,
    mqttTLS = 25,
    tilt = 26,
    portalCounting = 27,
    zoneDwellHistogram = 28,
    instantaneousZoneCount = 29,
    occupancy = 30,
    occupancyQueue = 31,
    mqttTargets = 32,
    zoneWaitTime = 33,
    vectorFull = 34,
    mqttQOS = 35,
    staffCallAttend = 36,
    smartBoundary = 37,
    compareRegister = 38,
    correctiveTilt = 39,
    extendedRelayOut = 40,
    mqttLiveCounts = 41,
    activityOnlyRecording = 42,
    httpPostCustomisation = 43,
    registerTags = 44,
    customSSLCerts = 45,
    httpPostHistograms = 46,
    mqttDeviceInfo = 47,
    httpPostLocalTime = 48,
    pulseGeneratorRegister = 49,
    tailgateRegister = 50,
    remoteValueRegister = 51,
    registerCustomActions = 52,
    timeOfFlightFrameRate = 53,
    registerCustomActionsParameters = 54,
    errorStatusRegister = 55,
    remoteRegisterPush = 56,
    httpPostAuthorizationToken = 57,
    wirelessPIR = 58,
    bacnet = 59,
    mQTTRegisterFilter = 60,
    diagnosticPackage = 61,
    mqttEventStream = 62,
    nodeVideoValidation = 63,
    commsAuthentication = 64,
    https = 65,
    sslVerification = 66,
    formatSystemStorage = 67,
    diagnosticsDataRecording = 68,
    powerOverEthernet = 69,
    highResSnapshot = 70,
    bacnetNetworkNumber = 71,
    multiCert = 72,
    mQTTRetain = 73
}

export class DeviceCapabilitiesEnumHelpers {
    public static fromRestApi(value: string): DeviceCapabilitiesEnum {
        switch (value) {
            case 'Thermal':
                return DeviceCapabilitiesEnum.thermal;
            case 'TamperDetect':
                return DeviceCapabilitiesEnum.tamperDetect;
            case 'BeambreakMode':
                return DeviceCapabilitiesEnum.beambreakMode;
            case 'Histograms':
                return DeviceCapabilitiesEnum.histograms;
            case 'PathMap':
                return DeviceCapabilitiesEnum.pathMap;
            case 'HttpPost':
                return DeviceCapabilitiesEnum.httpPost;
            case 'Height':
                return DeviceCapabilitiesEnum.height;
            case 'RelayOut':
                return DeviceCapabilitiesEnum.relayOut;
            case 'Diagnostic':
                return DeviceCapabilitiesEnum.diagnostic;
            case 'Counts':
                return DeviceCapabilitiesEnum.counts;
            case 'TimeOfFlight':
                return DeviceCapabilitiesEnum.timeOfFlight;
            case 'Bluetooth':
                return DeviceCapabilitiesEnum.bluetooth;
            case 'SDI':
                return DeviceCapabilitiesEnum.sdi;
            case 'Tool':
                return DeviceCapabilitiesEnum.tool;
            case 'VideoRecording':
                return DeviceCapabilitiesEnum.videoRecording;
            case 'Video':
                return DeviceCapabilitiesEnum.video;
            case 'CANIO':
                return DeviceCapabilitiesEnum.canIo;
            case 'RelayIn':
                return DeviceCapabilitiesEnum.relayIn;
            case 'ConfigurableServerPort':
                return DeviceCapabilitiesEnum.configurableServerPort;
            case 'Zones':
                return DeviceCapabilitiesEnum.zones;
            case 'MarkedTargets':
                return DeviceCapabilitiesEnum.markedTargets;
            case 'MultiUnitMk2':
                return DeviceCapabilitiesEnum.multiUnitMk2;
            case 'NTP':
                return DeviceCapabilitiesEnum.ntp;
            case 'MQTTPublish':
                return DeviceCapabilitiesEnum.mqttPublish;
            case 'MQTTTLS':
                return DeviceCapabilitiesEnum.mqttTLS;
            case 'Tilt':
                return DeviceCapabilitiesEnum.tilt;
            case 'PortalCounting':
                return DeviceCapabilitiesEnum.portalCounting;
            case 'ZoneDwellHistogram':
                return DeviceCapabilitiesEnum.zoneDwellHistogram;
            case 'InstantaneousZoneCount':
                return DeviceCapabilitiesEnum.instantaneousZoneCount;
            case 'Occupancy':
                return DeviceCapabilitiesEnum.occupancy;
            case 'OccupancyQueue':
                return DeviceCapabilitiesEnum.occupancyQueue;
            case 'MQTTTargets':
                return DeviceCapabilitiesEnum.mqttTargets;
            case 'ZoneWaitTime':
                return DeviceCapabilitiesEnum.zoneWaitTime;
            case 'VectorFull':
                return DeviceCapabilitiesEnum.vectorFull;
            case 'MQTTQOS':
                return DeviceCapabilitiesEnum.mqttQOS;
            case 'StaffCallAttend':
                return DeviceCapabilitiesEnum.staffCallAttend;
            case 'SmartBoundary':
                return DeviceCapabilitiesEnum.smartBoundary;
            case 'CompareRegister':
                return DeviceCapabilitiesEnum.compareRegister;
            case 'CorrectiveTilt':
                return DeviceCapabilitiesEnum.correctiveTilt;
            case 'ExtendedRelayOut':
                return DeviceCapabilitiesEnum.extendedRelayOut;
            case 'MQTTLiveCounts':
                return DeviceCapabilitiesEnum.mqttLiveCounts;
            case 'ActivityOnlyRecording':
                return DeviceCapabilitiesEnum.activityOnlyRecording;
            case 'HttpPostCustomisation':
                return DeviceCapabilitiesEnum.httpPostCustomisation;
            case 'RegisterTags':
                return DeviceCapabilitiesEnum.registerTags;
            case 'CustomSSLCert':
                return DeviceCapabilitiesEnum.customSSLCerts;
            case 'HttpPostHistograms':
                return DeviceCapabilitiesEnum.httpPostHistograms;
            case 'MQTTDeviceInfo':
                return DeviceCapabilitiesEnum.mqttDeviceInfo;
            case 'HttpPostLocalTime':
                return DeviceCapabilitiesEnum.httpPostLocalTime;
            case 'PulseGeneratorRegister':
                return DeviceCapabilitiesEnum.pulseGeneratorRegister;
            case 'TailgateRegister':
                return DeviceCapabilitiesEnum.tailgateRegister;
            case 'RemoteValueRegister':
                return DeviceCapabilitiesEnum.remoteValueRegister;
            case 'RegisterCustomActions':
                return DeviceCapabilitiesEnum.registerCustomActions;
            case 'TimeOfFlightFrameRate':
                return DeviceCapabilitiesEnum.timeOfFlightFrameRate;
            case 'RegisterCustomActionsParameters':
                return DeviceCapabilitiesEnum.registerCustomActionsParameters;
            case 'ErrorStatusRegister':
                return DeviceCapabilitiesEnum.errorStatusRegister;
            case 'RemoteRegisterPush':
                return DeviceCapabilitiesEnum.remoteRegisterPush;
            case 'HttpPostAuthorizationToken':
                return DeviceCapabilitiesEnum.httpPostAuthorizationToken;
            case 'WirelessPIR':
                return DeviceCapabilitiesEnum.wirelessPIR;
            case 'NodeVideoValidation':
                return DeviceCapabilitiesEnum.nodeVideoValidation;
            case 'BACNET':
                return DeviceCapabilitiesEnum.bacnet;
            case 'MQTTRegisterFilter':
                return DeviceCapabilitiesEnum.mQTTRegisterFilter;
            case 'DiagnosticPackage':
                return DeviceCapabilitiesEnum.diagnosticPackage;
            case 'MQTTEventStream':
                return DeviceCapabilitiesEnum.mqttEventStream;
            case 'CommsAuthentication':
                return DeviceCapabilitiesEnum.commsAuthentication;
            case 'Https':
                return DeviceCapabilitiesEnum.https;
            case 'SSLVerification':
                return DeviceCapabilitiesEnum.sslVerification;
            case 'FormatSystemStorage':
                return DeviceCapabilitiesEnum.formatSystemStorage;
            case 'DiagnosticsDataRecording':
                return DeviceCapabilitiesEnum.diagnosticsDataRecording;
            case 'PowerOverEthernet':
                return DeviceCapabilitiesEnum.powerOverEthernet;
            case 'HighResSnapshot':
                return DeviceCapabilitiesEnum.highResSnapshot;
            case 'BACNETNetworkNumber':
                return DeviceCapabilitiesEnum.bacnetNetworkNumber;
            case 'MultiCert':
                return DeviceCapabilitiesEnum.multiCert;
            case 'MQTTRetain':
                return DeviceCapabilitiesEnum.mQTTRetain;
        }
    }
}
