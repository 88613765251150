<div class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                Count Logging
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="countLoggingFormGroup">
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="number"
                                step="1"
                                #countLogPeriod
                                matInput
                                formControlName="countLogPeriod"
                                placeholder="Count Log Period (seconds)">
                            <span matSuffix>{{ countLogConfig?.propertyHasChangesText('countLogPeriod') }}</span>
                            <mat-error>{{getFormControlErrorMessage(countLoggingFormGroup.controls['countLogPeriod'])}}</mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                Count Logging
            </mat-card-title>
            <mat-card-content>
                <span>The count log period determines how often the count value is logged in the devices flash memory.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>