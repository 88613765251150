/**
 * Device Type.
 *
 * @export
 * @enum {number}
 */
export enum DeviceTypeEnum {
    /**
     * 3000 series unit.
     */
    blackfin = 0,

    /**
     * Gazelle unit.
     */
    gazelle = 1,

    /**
     * 3000 series validation camera.
     */
    blackfinValCam = 2,

    /**
     * Kestrel device.
     */
    kestrel = 3,

    /**
     * Falcon device
     */
    falcon = 4
}

export class DeviceTypeEnumHelpers {
    public static toStringHumanized(value: DeviceTypeEnum): string {
        switch (value) {
            case DeviceTypeEnum.blackfinValCam:
                return '3000 Series Val Cam';
            case DeviceTypeEnum.blackfin:
                return '3000 Series';
            case DeviceTypeEnum.gazelle:
                return 'Gazelle';
            case DeviceTypeEnum.kestrel:
                return 'Vector';
            case DeviceTypeEnum.falcon:
                return 'Gemini';
        }
    }
}
