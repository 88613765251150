import { Component, HostBinding, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EndPointModel } from '@em/models/restapi/EndPoint.Model';
import { EndPointCollectionModel } from '@em/models/restapi/EndPointCollection.Model';
import { EndPointTestResponseModel } from '@em/models/restapi/EndPointTestResponse.Model';
import { ProfileResponseModel } from '@em/models/restapi/ProfileResponse.Model';
import { NotificationService } from '@em/service/data/notification/Notification.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { OkCancelDialogComponent, OkCancelDialogData } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


export class ManageLocationsDialogData {
    public constructor() {
    }
}

export class ManageLocationsResult {

    public constructor() { }
}

@Component({
    selector: 'em-settings-outbound-connections-manage-locations',
    templateUrl: './Settings.Notifications.ManageLocations.Component.html',
    styleUrls: ['./Settings.Notifications.ManageLocations.Component.scss']
})
export class SettingsOutboundConnectionsManageLocationsComponent extends BaseComponent implements ILoadDate {
    public static className: string = 'SettingsOutboundConnectionsManageLocationsComponent';

    @HostBinding()
    public id: string = 'em-settings-outbound-connections-manage-locations';

    public saveProcess: ProcessMonitorServiceProcess;
    public deleteProcess: ProcessMonitorServiceProcess;
    public testProcess: ProcessMonitorServiceProcess;
    public infoProcess: ProcessMonitorServiceProcess;

    public endPointCollection: EndPointCollectionModel;
    public selectedEndPoint: EndPointModel;
    public endPointFormGroup: FormGroup;
    public profileResult: ProfileResponseModel;
    public testResult: EndPointTestResponseModel;
    public formNameValuesChangesProcess: ProcessMonitorServiceProcess;
    public formProtocolValuesChangesProcess: ProcessMonitorServiceProcess;
    public formAddressValuesChangesProcess: ProcessMonitorServiceProcess;
    public formContentTypeValuesChangesProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _eventsService: EventsService,
        private readonly _formBuilder: FormBuilder,
        private readonly _notificationService: NotificationService,
        private readonly _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private readonly _data: ManageLocationsDialogData,
        private readonly _dialogRef: MatDialogRef<SettingsOutboundConnectionsManageLocationsComponent>,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsManageLocationsComponent.className, this.loadDataProcessText);
        this.saveProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsManageLocationsComponent.className, 'Saving end point.');
        this.deleteProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsManageLocationsComponent.className, 'Deleting end point.');
        this.testProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsManageLocationsComponent.className, 'Testing end point.');
        this.infoProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsManageLocationsComponent.className, 'Getting end point info.');
        this.formNameValuesChangesProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsManageLocationsComponent.className, 'Form name values change.');
        this.formProtocolValuesChangesProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsManageLocationsComponent.className, 'Form protocol values change.');
        this.formAddressValuesChangesProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsManageLocationsComponent.className, 'Form address values change.');
        this.formContentTypeValuesChangesProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsManageLocationsComponent.className, 'Form contentType values change.');

        this.endPointFormGroup = this._formBuilder.group({
            name: ['', Validators.compose([Validators.required, Validators.maxLength(256), ValidationValidators.unique(this.getNames.bind(this))])],
            protocol: ['', Validators.compose([Validators.required])],
            contentType: ['', Validators.compose([Validators.required])],
            address: ['', Validators.compose([Validators.required, Validators.maxLength(2083)])],
            userName: ['', Validators.compose([])],
            password: ['', Validators.compose([])],
        });

        this.addSubscription(this.observableHandlerBase(this.endPointFormGroup.controls.name.valueChanges, this.formNameValuesChangesProcess).subscribe(() => {
            this.selectedEndPoint.text = this.endPointFormGroup.controls.name.value;
        }));

        this.addSubscription(this.observableHandlerBase(this.endPointFormGroup.controls.protocol.valueChanges, this.formProtocolValuesChangesProcess).subscribe(() => {
            this.selectedEndPoint.protocol = this.endPointFormGroup.controls.protocol.value;
            switch (this.selectedEndPoint.protocol) {
                case 1: // Http
                    this.endPointFormGroup.controls.address.setValidators(Validators.compose([Validators.required, Validators.maxLength(2083), ValidationValidators.httpAndHttpsAddress]));
                    break;
                case 2: // Email
                    this.endPointFormGroup.controls.address.setValidators(Validators.compose([Validators.required, Validators.maxLength(2083), ValidationValidators.emailAddress]));
                    break;
                case 3: // Ftp
                    this.endPointFormGroup.controls.address.setValidators(Validators.compose([Validators.required, Validators.maxLength(2083), ValidationValidators.ftpAndFtpsAddress]));
                    break;
            }
        }));

        this.addSubscription(this.observableHandlerBase(this.endPointFormGroup.controls.contentType.valueChanges, this.formContentTypeValuesChangesProcess).subscribe(() => {
            this.selectedEndPoint.contentType = this.endPointFormGroup.controls.contentType.value;
        }));

        this.addSubscription(this.observableHandlerBase(this.endPointFormGroup.controls.address.valueChanges, this.formAddressValuesChangesProcess).subscribe(() => {
            this.selectedEndPoint.address = this.endPointFormGroup.controls.address.value;
        }));

        this.addSubscription(this.observableHandlerBase(this.endPointFormGroup.controls.userName.valueChanges, this.formAddressValuesChangesProcess).subscribe(() => {
            this.selectedEndPoint.userName = this.endPointFormGroup.controls.userName.value;
        }));

        this.addSubscription(this.observableHandlerBase(this.endPointFormGroup.controls.password.valueChanges, this.formAddressValuesChangesProcess).subscribe(() => {
            this.selectedEndPoint.password = this.endPointFormGroup.controls.password.value;
        }));

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public add(): void {
        this.selectedEndPoint = new EndPointModel();
        this.selectedEndPoint.commitChanges();
        this.clearFormValues();
    }

    public save(): void {
        if (this.isValid === true) {
            if (this.hasChanges === true) {
                if (this.isNullOrUndefined(this.selectedEndPoint.endPointId)) {
                    this.addSubscription(this.observableHandlerBase(this._notificationService.addEndPoint(this.selectedEndPoint, this.saveProcess), this.saveProcess).subscribe(
                        result => {
                            this.selectedEndPoint.endPointId = this.selectedEndPoint.endPointId;
                            this.selectedEndPoint.commitChanges();
                            this.endPointCollection.items.push(this.selectedEndPoint);
                            this.endPointCollection.commitChanges();
                            this._eventsService.changedNotifications();
                        }
                    ), this.saveProcess);
                } else {
                    this.addSubscription(this.observableHandlerBase(this._notificationService.updateEndPoint(this.selectedEndPoint, this.saveProcess), this.saveProcess).subscribe(
                        () => {
                            this.selectedEndPoint.commitChanges();
                            this._eventsService.changedNotifications();
                        }
                    ), this.saveProcess);
                }
            } else {
                this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('No Changes', 'There are no changes to save.', false) });
            }
        } else {
            this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Location Invalid', 'There is one or more invalid configuration.', false) });
        }
    }

    public delete(): void {
        this.addSubscription(this.observableHandlerBase(this._notificationService.canDeleteEndPoint(this.selectedEndPoint.endPointId, this.deleteProcess), this.deleteProcess).subscribe(
            result => {
                if (result.canDelete === true) {
                    this.addSubscription(this.observableHandlerBase(this._notificationService.deleteEndPoint(this.selectedEndPoint.endPointId, this.deleteProcess), this.deleteProcess).subscribe(
                        () => {
                            this.clearFormValues();
                            this._eventsService.changedNotifications();
                            this.loadDataStartBase(this);
                        }
                    ), this.deleteProcess);
                } else {
                    const ref = this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Location In Use', 'Cannot delete location in use in notification rule.', false) });

                    this.addSubscription(this.observableHandlerBase(ref.afterClosed(), this.deleteProcess).subscribe());
                }
            }
        ), this.deleteProcess);
    }

    public test(): void {
        this.profileResult = null;
        this.addSubscription(this.observableHandlerBase(this._notificationService.testEndPoint(this.selectedEndPoint, this.testProcess), this.testProcess).subscribe(
            result => {
                this.testResult = result;
                if (this.testResult.exceptions.some(i => i.indexOf('Parameter name: from') !== -1)) {
                    this.testResult.exceptions = ['Please setup SMTP server in EM settings'];
                }
            }
        ), this.testProcess);
    }

    public info(): void {
        this.testResult = null;

        this.addSubscription(this.observableHandlerBase(this._notificationService.getProfile(null, this.selectedEndPoint.endPointId, this.infoProcess), this.infoProcess).subscribe(
            result => {
                this.profileResult = result;
            }
        ), this.infoProcess);
    }

    public cancel(): void {
        if (!this.isNullOrUndefined(this.selectedEndPoint)) {
            this.selectedEndPoint.clearChanges();
        }
        this._dialogRef.close();
    }

    public onEndPointvalueChange(): void {
        this.onEndPointSelected();
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._notificationService.getEndPoints(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.endPointCollection = result;
                        if (this.endPointCollection.items.length > 0) {
                            this.selectedEndPoint = this.endPointCollection[0];
                            this.onEndPointSelected();
                        } else {
                            this.selectedEndPoint = null;
                            this.onEndPointSelected();
                        }
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this.selectedEndPoint) || this.selectedEndPoint.hasChanges;
    }

    public get isValid(): boolean {
        return this.endPointFormGroup.valid;
    }

    private onEndPointSelected(): void {
        this.setEndPointFormGroupValues();
    }

    private setEndPointFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.selectedEndPoint)) {
            this.endPointFormGroup.controls.name.setValue(this.selectedEndPoint.text);
            this.endPointFormGroup.controls.protocol.setValue(this.selectedEndPoint.protocol);
            this.endPointFormGroup.controls.contentType.setValue(this.selectedEndPoint.contentType);
            this.endPointFormGroup.controls.address.setValue(this.selectedEndPoint.address);
            this.endPointFormGroup.controls.userName.setValue(this.selectedEndPoint.userName);
            this.endPointFormGroup.controls.password.setValue(this.selectedEndPoint.password);
        }
    }

    private clearFormValues(): void {
        this.endPointFormGroup.setValue({ name: '', protocol: '', contentType: '', address: '', userName: '', password: '' });
    }

    private getNames(): string[] {
        return !this.isNullOrUndefined(this.endPointCollection) ? this.endPointCollection.items.filter(i=> this.isNullOrUndefined(this.selectedEndPoint) ? true : i.endPointId !== this.selectedEndPoint.endPointId).map(i => i.text) : [];
    }
}
