import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface IGVectorModel {
    device: string;
    id: number;
    x: number;
    y: number;
    z: number;
    yaw: number;
}

export class GVectorModel extends BaseModel implements IGVectorModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'gvector_case';

    public readonly isIWebSocketModel: boolean = true;
    public packetType: string = null;
    public id: number = null;

    public device: string = null;
    public x: number = null;
    public y: number = null;
    public z: number = null;
    public yaw: number = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-GVectorModel', null);
        this.x = message.data.X;
        this.y = message.data.Y;
        this.z = message.data.Z;
        this.yaw = message.data.Yaw;
    }

    public toWebSocketMessage(): void {
        throw new Error('Method not implemented.');
    }
}
