<div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Auto Play Settings
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div mat-dialog-content fxLayout="column">
    <form [formGroup]="autoSpeedFormGroup">
        <div class="settings" fxLayout="row">
            <mat-form-field fxFlex="50">
                <input type="number" #noTargetsSpeed matInput formControlName="noTargetsSpeed"
                    placeholder="No Target Speed">
                <mat-error>{{getFormControlErrorMessage(autoSpeedFormGroup.controls['noTargetsSpeed'])}}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
                <input type="number" #targetsSpeed matInput formControlName="targetsSpeed" placeholder="Target Speed">
                <mat-error>{{getFormControlErrorMessage(autoSpeedFormGroup.controls['targetsSpeed'])}}</mat-error>
            </mat-form-field>
        </div>
        <div class="settings" fxLayout="row">
            <mat-form-field fxFlex="50">
                <input type="number" #inWindow matInput formControlName="inWindow" placeholder="In Window">
                <mat-error>{{getFormControlErrorMessage(autoSpeedFormGroup.controls['arrowKeyJogMs'])}}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
                <input type="number" #outWindow matInput formControlName="outWindow" placeholder="Out Window">
                <mat-error>{{getFormControlErrorMessage(autoSpeedFormGroup.controls['arrowKeyJogMs'])}}</mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="descriptions" fxLayout="column">
        <div class="description" fxLayout="column">
            <div class="des-title">
                No Targets Speed
            </div>
            <div class="des-text">
                The play speed that will be set when there is no target in view or no targets about to enter the view
                within the in and out window.
            </div>
        </div>
        <div class="description" fxLayout="column">
            <div class="des-title">
                Targets Speed
            </div>
            <div class="des-text">
                The play speed that will be set when there are target in view or targets about to enter the view within
                the in and out window.
            </div>
        </div>
        <div class="description" fxLayout="column">
            <div class="des-title">
                In Window
            </div>
            <div class="des-text">
                The number of seconds before a target enters the view that it will be considered in view.
            </div>
        </div>
        <div class="description" fxLayout="column">
            <div class="des-title">
                Out Window
            </div>
            <div class="des-text">
                The number of seconds after a target has left the view that it will be considered in view.
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="close()" [disabled]="autoSpeedFormGroup.valid === false">Close</button>
    <div fxFlex></div>
</div>
