import { Component, HostBinding, Inject } from '@angular/core';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class CommsRetryDialogData {
    public constructor(public readonly errorMessage?: string) {
    }
}

export class CommsRetryDialogResult {
    public retry: boolean;

    public constructor(retry: boolean) {
        this.retry = retry;
    }
}

@Component({
    selector: 'shr-comms-retry-dialog',
    templateUrl: './CommsRetry.Component.html',
    styleUrls: ['./CommsRetry.Component.scss']
})
export class CommsRetryDialogComponent {
    public static className: string = 'CommsRetryDialogComponent';

    public isNullOrUndefined = isNullOrUndefined;

    @HostBinding()
    public id: string = 'shr-ok-cancel-dialog';

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: CommsRetryDialogData,
        private readonly _dialogRef: MatDialogRef<CommsRetryDialogComponent>) {

        this._dialogRef.disableClose = true;
    }

    public retry(): void {
        this._dialogRef.close(new CommsRetryDialogResult(true));
    }

    public cancel(): void {
        this._dialogRef.close(new CommsRetryDialogResult(false));
    }
}
