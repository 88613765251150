<form [formGroup]="clientConnectionsFormGroup">
    <div formArrayName="connections">
        <ng-template ngFor
            let-clientConnection
            [ngForOf]="clientConnections?.items"
            let-i="index">
            <div [formGroupName]="i"
                class="spacing-bottom"
                fxLayout.gt-sm="row"
                fxLayout.lt-md="column">
                <div ngClass.gt-sm="spacing-right"
                    ngClass.lt-md="spacing-bottom"
                    fxFlex.gt-sm="60"
                    fxFlex.lt-md="100"
                    fxLayout="column">
                    <mat-card fxFlex>
                        <mat-card-title>
                            Client {{ i + 1 }}
                            <div fxFlex></div>
                            <mat-checkbox #enabled
                                formControlName="enabled">
                                Enable{{getFormControlErrorMessage(asFormGroup(asFormArray(clientConnectionsFormGroup.controls['connections']).at(i)).controls['enabled'])}}
                            </mat-checkbox>
                        </mat-card-title>
                        <mat-card-content *ngIf="enabled.checked">
                            <div fxLayout="row">
                                <mat-form-field fxFlex>
                                    <input type="text"
                                        #address
                                        matInput
                                        formControlName="address"
                                        placeholder="Address">
                                    <span matSuffix>{{ clientConnections[i]?.propertyHasChangesText('address') }}</span>
                                    <mat-error>
                                        {{getFormControlErrorMessage(asFormGroup(asFormArray(clientConnectionsFormGroup.controls['connections']).at(i)).controls['address'])}}
                                    </mat-error>
                                    <mat-hint align="end">{{address.value.length}} / 59</mat-hint>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row">
                                <mat-form-field class="spacing-right"
                                    fxFlex="50">
                                    <input type="number"
                                        step="1"
                                        #port
                                        matInput
                                        formControlName="port"
                                        placeholder="Port">
                                    <span matSuffix>{{ clientConnections[i]?.propertyHasChangesText('port') }}</span>
                                    <mat-error>
                                        {{getFormControlErrorMessage(asFormGroup(asFormArray(clientConnectionsFormGroup.controls['connections']).at(i)).controls['port'])}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field fxFlex="50">
                                    <input type="number"
                                        step="1"
                                        #reconnectionInterval
                                        matInput
                                        formControlName="reconnectionInterval"
                                        placeholder="Reconnection Interval">
                                    <span matSuffix>{{ clientConnections[i]?.propertyHasChangesText('reconnectionInterval') }}</span>
                                    <mat-error>
                                        {{getFormControlErrorMessage(asFormGroup(asFormArray(clientConnectionsFormGroup.controls['connections']).at(i)).controls['reconnectionInterval'])}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row"
                                fxLayoutAlign="start center">
                                <div fxFlex></div>
                                <div class="error spacing-right"
                                    *ngIf="!isNullOrUndefined(testError)">
                                    {{ testError }}
                                </div>
                                <div>
                                    <shr-progress-button-spinner [raised]="true"
                                        [diameter]="30"
                                        text="Test Connection"
                                        mode="indeterminate"
                                        *ngIf="(isDeviceGen(UnitGenerationEnum.kestrel) | async) === true || (isDeviceGen(UnitGenerationEnum.falcon) | async) === true"
                                        [disabled]="isReadOnly"
                                        [active]="testConnectionProcess.isRunning"
                                        (shrClick)="onTestConnectionClick(clientConnection)"
                                        color="primary"></shr-progress-button-spinner>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <ng-template [ngIf]="i === 0">
                    <div fxFlex.gt-sm="40"
                        fxFlex.lt-md="100"
                        fxLayout="column">
                        <mat-card class="shr-card-sub help-card"
                            fxFlex>
                            <mat-card-title>
                                Outbound Connections
                            </mat-card-title>
                            <mat-card-content>
                                <span>Client, or outbound, connections can be configured to let the device connect out to a specific IP and port.</span>
                                <span *ngIf="(isDeviceGen(UnitGenerationEnum.gazelle) | async) === true">Up to three simultaneous outbound connections can be supported at any one time.</span>
                                <span class="new-block">Outbound connections communicate over Irisys protocol and, as such, a compatible listener
                                    must be available
                                    to receive the connection. For more information relating to using our APIs please contact support.</span>
                            </mat-card-content>
                            <mat-card-content>
                                <span>Estate Manager</span>
                            </mat-card-content>
                            <mat-card-content>
                                <span>One of the outbound connections can be used to connect to Irisys device management software, Estate
                                    Manager.</span>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </ng-template>
                <ng-template [ngIf]="i !== 0">
                    <div fxFlex.gt-sm="40"
                        fxFlex.lt-md="100"
                        fxLayout="column">

                    </div>
                </ng-template>
            </div>
        </ng-template>
    </div>
</form>