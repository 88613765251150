import { AfterViewInit, Component, HostBinding, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { AddressBookTreeComponent } from '@em/components/shared/addressbooktree/AddressBookTree.Component';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { GroupService } from '@em/service/data/group/Group.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { DeviceGroupEnum } from '@shared/enum/DeviceGroup.Enum';
import { ActivityService } from '@em/service/data/activity/Activity.Service';
import { DeviceActivityActionEnum } from '@shared/enum/DeviceActivityAction.Enum';
import { DeviceActivityModel } from '@em/models/restapi/DeviceActivity.Model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class RestoreBackupFileDialogData {
    public constructor() {
    }
}

export class RestoreBackupFileDialogResult {
    public constructor() {
    }
}

@Component({
    selector: 'em-rift-restore-backup-file-dialog',
    templateUrl: './RestoreBackup.FileDialog.Component.html',
    styleUrls: ['./RestoreBackup.FileDialog.Component.scss'],
})
export class RestoreBackupFileDialogComponent implements AfterViewInit {
    public static className: string = 'RestoreBackupFileDialogComponent';

    public isNullOrUndefined = isNullOrUndefined;
    public devices: Array<DeviceModel>;
    public backups: Array<DeviceActivityModel>;
    public selectedGroup: GroupModel;
    public orphanedDevicesSelected: boolean = false;
    public selectedBackup: any;
    public selectedDevice: DeviceModel;

    // tslint:disable-next-line: no-output-on-prefix
    @Output()
    public onDataLoaded: EventEmitter<Uint8Array> = new EventEmitter<Uint8Array>();

    @HostBinding()
    public id: string = 'em-rift-restore-backup-file-dialog';

    @ViewChild('addressBook', { static: true })
    public tree: AddressBookTreeComponent;

    public constructor(
        private readonly _activityService: ActivityService,
        private readonly _deviceService: DeviceService,
        private readonly _groupService: GroupService,
        @Inject(MAT_DIALOG_DATA) public readonly data: RestoreBackupFileDialogData,
        private readonly _dialogRef: MatDialogRef<RestoreBackupFileDialogResult>) {

        this._dialogRef.disableClose = true;
    }

    public ngAfterViewInit(): void {
        this.getGroups();
    }

    public onBackupFileSelectChange(event: Event): void {
        const inputElement: HTMLInputElement = event.target as HTMLInputElement;
        if (!isNullOrUndefined(inputElement.files) && inputElement.files.length > 0) {
            const dataBuffer = new Blob([inputElement.files[0]]);
            const dataBufferReader = new FileReader();
            dataBufferReader.onload = () => {
                const arrayBuffer = dataBufferReader.result as ArrayBuffer;
                this.onDataLoaded.emit(new Uint8Array(arrayBuffer));
            };
            dataBufferReader.readAsArrayBuffer(dataBuffer);
        }
    }

    public onGroupClicked(group: GroupModel): void {
        this.orphanedDevicesSelected = false;
        this.selectedGroup = group;
        this.getDevices(group.id);
    }

    public onOrphanedDevicesClicked(): void {
        this.orphanedDevicesSelected = true;
        this.selectedGroup = null;
        this.getDevices(DeviceGroupEnum.orphaned);
    }

    public onDeviceClicked(device: DeviceModel): void {
        this.selectedDevice = device;
        this.getBackups(device.friendlySerial);
    }

    public onBackupClicked(backup: any): void {
        this.selectedBackup = backup;
        this.getBackup(backup);
    }

    public onCancelClicked(): void {
        this._dialogRef.close();
    }

    private getGroups(): void {
        this._groupService.getNested().subscribe(
            result => {
                this.tree.setDataSource(result);
            }
        );
    }

    private getDevices(groupId: number | DeviceGroupEnum): void {
        this._deviceService.getByGroup(groupId).subscribe(
            result => {
                this.devices = result.items;
            }
        );
    }

    private getBackups(friendlySerial: string): void {
        this._activityService.getActivityPage(friendlySerial, null, null, [DeviceActivityActionEnum.backup]).subscribe(
            result => {
                this.backups = result.items;
            }
        );
    }

    private getBackup(activity: DeviceActivityModel): void {
        this._deviceService.getBackupData(this.selectedDevice.friendlySerial, activity.id).subscribe(
            result => {
                this.onDataLoaded.emit(this.base64ToArrayBuffer(result));
            }
        );
    }

    private base64ToArrayBuffer(base64): Uint8Array {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    }
}
