import {
    AfterViewInit,
    Component,
    HostBinding,
    HostListener,
    Injector,
    OnDestroy,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSliderChange } from '@angular/material/slider';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { VideoSettingsModel } from '@rift/models/restapi/VideoSettings.Model';
import { VideoStorageCapacityModel } from '@rift/models/restapi/VideoStorageCapacity.Model';
import { VideoModel } from '@rift/models/websocket/Video.Model';
import { AllDataService } from '@rift/service/data/alldata/AllData.Service';
import { RecordingService } from '@rift/service/data/recording/Recording.Service';
import { RecordingControlService } from '@rift/service/data/recording/RecordingControl.Service1';
import { CanvasComponent } from '@shared/component/canvas/Canvas.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { StreamTypeEnum } from '@shared/enum/StreamType.Enum';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

interface IDeviceInfo {
    serial: string;
    isMaster: boolean;
    videoSettings: VideoSettingsModel;
    videoStorageCapacity: VideoStorageCapacityModel;
    cropRectHeight: number;
    cropRectWidth: number;
    cropRectX: number;
    cropRectY: number;
    cropEditingMode: boolean;
    cropSliderVal: number;
    formatVideoStorageProcess: ProcessMonitorServiceProcess;
    resetCroppingWindowProcess: ProcessMonitorServiceProcess;
    croppingCanvas: CanvasComponent;
    videoFrame: HTMLImageElement;
    videoStreamId: number;
}

@Component({
    selector: 'rift-settings-video',
    templateUrl: './Settings.Video.Component.html',
    styleUrls: ['./Settings.Video.Component.scss']
})
export class SettingsVideoComponent extends RiftBaseComponent implements OnDeactivate, OnDestroy, AfterViewInit, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsVideoComponent';

    @HostBinding()
    public id: string = 'rift-settings-video';

    @ViewChildren(CanvasComponent)
    public croppingCanvases: QueryList<CanvasComponent>;

    public frameRates: Array<number> = [];
    public videoMessageReceivedProcess: ProcessMonitorServiceProcess;
    public deviceInfos: IDeviceInfo[] = [];
    private _unitGen: UnitGenerationEnum;

    public constructor(
        private readonly _allDataService: AllDataService,
        private readonly _recordingControlService: RecordingControlService,
        private readonly _recordingService: RecordingService,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.videoMessageReceivedProcess = this.processMonitorService.getProcess(SettingsVideoComponent.className, 'Video message received');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsVideoComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsVideoComponent.className, this.saveAllChangesProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsVideoComponent.className, 'Saving all changes');

        this.addSaveAllAction(this);

        this.addSubscription(this.observableHandlerBase(this.webSocketService.videoMessageReceived, this.videoMessageReceivedProcess).subscribe(message => this.onVideoMessageReceived(message)), this.videoMessageReceivedProcess);

        this.initConnectionState();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public calculateCroppingWindow(event: MatSliderChange, deviceInfo: IDeviceInfo): void {
        const deviceViewWidth: number = 1600;
        const deviceViewHeight: number = 1200;

        const newWidth = (deviceInfo.croppingCanvas.width * (event.value / 100));
        const newHeight = (deviceInfo.croppingCanvas.height * (event.value / 100));

        const widthDiff = deviceInfo.croppingCanvas.width - newWidth;
        const heightDiff = deviceInfo.croppingCanvas.height - newHeight;

        deviceInfo.cropRectX = (widthDiff / 2);
        deviceInfo.cropRectY = (heightDiff / 2);
        deviceInfo.cropRectWidth = newWidth;
        deviceInfo.cropRectHeight = newHeight;

        deviceInfo.videoSettings.cropXPos1 = Math.round((deviceInfo.cropRectX / deviceInfo.croppingCanvas.width) * deviceViewWidth);
        deviceInfo.videoSettings.cropYPos1 = Math.round((deviceInfo.cropRectY / deviceInfo.croppingCanvas.height) * deviceViewHeight);

        deviceInfo.videoSettings.cropXPos2 = Math.round(((deviceInfo.cropRectX + deviceInfo.cropRectWidth) / deviceInfo.croppingCanvas.width) * deviceViewWidth);
        deviceInfo.videoSettings.cropYPos2 = Math.round(((deviceInfo.cropRectY + deviceInfo.cropRectHeight) / deviceInfo.croppingCanvas.height) * deviceViewHeight);

        this.updateSaveAllAction(this);
    }

    public formatVideoStorage(deviceInfo: IDeviceInfo): void {
        this.addSubscription(this.observableHandlerBase(this._recordingService.formatVideoStorage(deviceInfo.serial, deviceInfo.formatVideoStorageProcess), deviceInfo.formatVideoStorageProcess).subscribe(
            () => {
                this.addSubscription(this.observableHandlerBase(this._recordingService.getVideoStorageCapacity(deviceInfo.serial, deviceInfo.formatVideoStorageProcess), deviceInfo.formatVideoStorageProcess).subscribe(
                    videoStorageCapacityResult => {
                        deviceInfo.videoStorageCapacity = videoStorageCapacityResult;
                        this._recordingControlService.reset();
                    }
                ), deviceInfo.formatVideoStorageProcess);
            }
        ), deviceInfo.formatVideoStorageProcess);
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase || this.deviceInfos.some(d => d.videoSettings.hasChanges);
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public onFrameRateSelectionChanged(event: MatSelectChange, deviceInfo: IDeviceInfo): void {
        deviceInfo.videoSettings.frameRate = event.value;
        this.updateSaveAllAction(this);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this.deviceInfos = [];

        const loadDataSub = zip(
            this.getHostDevice(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this._unitGen = result.unitGen;

                        this.frameRates = [];
                        if (result.unitGen === UnitGenerationEnum.kestrel || result.unitGen === UnitGenerationEnum.falcon) {
                            this.frameRates.push(15);
                            this.frameRates.push(30);
                        } else {
                            for (let i = 1; i < 13; i++) {
                                this.frameRates.push(i);
                            }
                        }
                    }
                    return true;
                })
            ),
            this.deviceService.getDevices().pipe(
                flatMap(result => {
                    if (!this.isNullOrUndefined(result)) {
                        return zip(...result.items.map(device => {
                            if (device.isCapable(DeviceCapabilitiesEnum.videoRecording)) {
                                return zip(
                                    this._recordingService.getSettings(device.serialNumber, process),
                                    this._recordingService.getVideoStorageCapacity(device.serialNumber, process),
                                ).pipe(
                                    map(results => {
                                        const videoSettings: VideoSettingsModel = results[0] instanceof VideoSettingsModel ? results[0] as VideoSettingsModel : null;
                                        const videoStorageCapacity: VideoStorageCapacityModel = results[1] instanceof VideoStorageCapacityModel ? results[1] as VideoStorageCapacityModel : null;

                                        this.deviceInfos.push({
                                            serial: device.serialNumber,
                                            isMaster: device.master,
                                            videoSettings,
                                            videoStorageCapacity,
                                            cropRectHeight: 240,
                                            cropRectWidth: 320,
                                            cropRectX: 0,
                                            cropRectY: 0,
                                            cropEditingMode: false,
                                            cropSliderVal: null,
                                            formatVideoStorageProcess: this.processMonitorService.getProcess(SettingsVideoComponent.className, 'Formatting video storage'),
                                            resetCroppingWindowProcess: this.processMonitorService.getProcess(SettingsVideoComponent.className, 'Resetting cropping window'),
                                            croppingCanvas: null,
                                            videoFrame: new Image(),
                                            videoStreamId: null,
                                        });

                                        return true;
                                    }),
                                );
                            } else {
                                return of(true);
                            }
                        })).pipe(map(() => {
                            this.deviceInfos = this.deviceInfos.sort((a, b) => (a.isMaster === b.isMaster) ? 0 : a.isMaster ? -1 : 1);
                            return true;
                        }));
                    }
                    return of(true);
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process).pipe(flatMap(() => {
            this.updateSaveAllAction(this);
            this.startVideoStreams();

            return of(true);
        }));
    }

    public ngAfterViewInit(): void {
        this.croppingCanvases.changes.subscribe(changes => {
            changes.forEach(canvas => {
                const deviceInfo: IDeviceInfo = this.deviceInfos.find(di => di.serial === canvas.serial);
                if (!this.isNullOrUndefined(deviceInfo)) {
                    deviceInfo.croppingCanvas = canvas;
                    this.renderVideo(deviceInfo);
                }
            });
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.stopVideoStreams();
    }

    public resetCroppingWindow(deviceInfo: IDeviceInfo): void {
        this.addSubscription(this.observableHandlerBase(this._recordingService.resetCroppingWindow(deviceInfo.serial, deviceInfo.resetCroppingWindowProcess), deviceInfo.resetCroppingWindowProcess).subscribe(
            () => {
                deviceInfo.cropEditingMode = true;
                deviceInfo.cropSliderVal = 100;
                deviceInfo.cropRectX = 0;
                deviceInfo.cropRectY = 0;
                deviceInfo.cropRectWidth = 320;
                deviceInfo.cropRectHeight = 240;

                this.updateSaveAllAction(this);
            }
        ), deviceInfo.resetCroppingWindowProcess);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            ...this.deviceInfos.filter(s => s.videoSettings.hasChanges).map(
                (deviceInfo: IDeviceInfo) => this._recordingService.setSettings(deviceInfo.videoSettings, deviceInfo.serial, process).pipe(
                        map(() => true)
                    )
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    this.deviceService.clearCache();
                    this._recordingService.clearCache();
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(false);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._recordingService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
        this.stopVideoStreams();
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
    }

    private startVideoStreams(): void {
        this.deviceInfos.forEach(di => {
            if (this.isNullOrUndefined(di.videoStreamId)) {
                di.videoStreamId = this.webSocketService.startStream(StreamTypeEnum.video, di.videoStreamId, di.serial);
            }
        });
    }

    private stopVideoStreams(): void {
        this.deviceInfos.forEach(di => {
            if (!this.isNullOrUndefined(di.videoStreamId)) {
                this.webSocketService.stopStream(StreamTypeEnum.video, di.videoStreamId, di.serial);
                di.videoStreamId = null;
            }
        });
    }

    private onVideoMessageReceived(message: VideoModel): any {
        const deviceInfo = this.deviceInfos.find(di => di.serial === message.device);
        if (!this.isNullOrUndefined(deviceInfo)) {
            deviceInfo.videoFrame.src = `data:image/jpeg;base64,${message.data}`;
        }
    }

    private renderVideo(deviceInfo: IDeviceInfo): void {
        if (!this.isNullOrUndefined(deviceInfo.croppingCanvas)) {
            deviceInfo.videoFrame.onload = function() {
                // Draw our frame
                deviceInfo.croppingCanvas.context.clearRect(0, 0, deviceInfo.croppingCanvas.context.canvas.width, deviceInfo.croppingCanvas.context.canvas.height);

                deviceInfo.croppingCanvas.context.fillStyle = '#fdfdfd';
                deviceInfo.croppingCanvas.context.fillRect(0, 0, deviceInfo.croppingCanvas.context.canvas.width, deviceInfo.croppingCanvas.context.canvas.height);

                deviceInfo.croppingCanvas.context.drawImage(deviceInfo.videoFrame, 0, 0, deviceInfo.croppingCanvas.context.canvas.width, deviceInfo.croppingCanvas.context.canvas.height);

                if (deviceInfo.cropEditingMode === true) {
                    deviceInfo.croppingCanvas.context.fillStyle = 'rgba(247,246,192,0.5)';
                    deviceInfo.croppingCanvas.context.fillRect(deviceInfo.cropRectX, deviceInfo.cropRectY, deviceInfo.cropRectWidth, deviceInfo.cropRectHeight);
                }

                // Request the next frame
                requestAnimationFrame(() => this.renderVideo(deviceInfo));
            }.bind(this);
        }
    }
}
