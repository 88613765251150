export enum RegisterUniversalTriggerPolarity{
    lessThan = 0,
    greaterThanOrEqual = 1
}

export class RegisterUniversalTriggerPolarityEnumHelpers {
    public static fromRestApi(value: string): RegisterUniversalTriggerPolarity {
        switch (value) {
            case 'LessThan':
                return RegisterUniversalTriggerPolarity.lessThan;
            case 'GreaterThanOrEqual':
                return RegisterUniversalTriggerPolarity.greaterThanOrEqual;
        }
    }

    public static toFullName(value: RegisterUniversalTriggerPolarity): string {
        switch (value) {
            case RegisterUniversalTriggerPolarity.lessThan:
                return 'LessThan';
            case RegisterUniversalTriggerPolarity.greaterThanOrEqual:
                return 'GreaterThanOrEqual';
        }
    }
}
