import { NgZone, Directive } from '@angular/core';
import { TimeLineDurationBarBuffer } from '@rift/components/shared/timeline/duration/TimeLine.Duration.Bar.Buffer';
import { TimeLineLoadQueueService } from '@rift/service/timeline/TimeLine.LoadQueueService';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { Settings, MAX_CANVAS_FPS, GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { ColorUtility } from '@shared/utility/Color.Utility';
import { DateTimeUtility, TimeZone } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IntervalTracker } from '@shared/generic/IntervalTracker';

@Directive()
export class TimeLineDurationBar extends DisplayItem {
    public readonly height: number = 27;

    private _fullSegmentCount: number;
    private _fullSegmentWidth: number;
    private _grabBox: createjs.Shape = new createjs.Shape();
    private _isDragging: boolean = false;
    private _labelFont: string = '12px Arial';
    private _labelHeight: number = 12;
    private _labels: Array<createjs.Text> = [];
    private _lastMousePoint: createjs.Point = null;
    private _segment: HTMLImageElement;
    private _segmentHeight: number = 15;
    private _segmentWidth: number = 150;
    private _segments: createjs.Shape = new createjs.Shape();
    private _width: number = 0;
    private _showDurationAsTime: boolean = false;
    private _showDurationAsDeviceTimeZone: boolean = false;
    private _timeZone: TimeZone;
    private _preLoad: TimeLineDurationBarBuffer;
    private _postLoad: TimeLineDurationBarBuffer;
    private _updateLabelsIntervalTracker: IntervalTracker = new IntervalTracker(1000 / MAX_CANVAS_FPS);

    public constructor(
        protected readonly _durationViewContainer: createjs.Container,
        protected readonly _playService: ValidationPlayService,
        protected readonly _loadQueue: TimeLineLoadQueueService,
        protected readonly _zone: NgZone) {
        super(_zone);

        this._zone.runOutsideAngular(() => {

            if (this._loadQueue.isLoaded === true) {
                this.onLoadQueueComplete();
            } else {
                this.addSubscription(this._loadQueue.loaded.subscribe(() => this.onLoadQueueComplete()));
            }

            this._preLoad = new TimeLineDurationBarBuffer(this._playService, this._zone);
            this._preLoad.alpha = 0.5;
            this._preLoad.color = '#ff0000';
            this._postLoad = new TimeLineDurationBarBuffer(this._playService, this._zone);
            this._postLoad.alpha = 0.5;
            this._postLoad.color = '#00ff00';

            this.container.addChild(this._grabBox);
            this.container.addChild(this._segments);

            this._preLoad.container.y = this.height - 4;
            this._postLoad.container.y = this.height - 9;

            this.container.addChild(this._preLoad.container);
            this.container.addChild(this._postLoad.container);

            this.addEventHandlers();
        });
    }

    public get preLoadBuffer(): TimeLineDurationBarBuffer {
        return this._preLoad;
    }

    public get postLoadBuffer(): TimeLineDurationBarBuffer {
        return this._postLoad;
    }

    public bringToFront(displayItem?: IDisplayItem): void {

    }

    public get fullSegmentCount(): number {
        return this._fullSegmentCount;
    }

    public get fullSegmentWidth(): number {
        return this._fullSegmentWidth;
    }

    public get isDragging(): boolean {
        return this._isDragging;
    }

    public get labelHeight(): number {
        return this._labelHeight;
    }

    public get timeZone(): TimeZone {
        return this._timeZone;
    }
    public set timeZone(value: TimeZone) {
        this._timeZone = value;
    }

    public get showDurationAsTime(): boolean {
        return this._showDurationAsTime;
    }
    public set showDurationAsTime(value: boolean) {
        this._showDurationAsTime = value;
        this.update();
    }

    public get showDurationAsDeviceTimeZone(): boolean {
        return this._showDurationAsDeviceTimeZone;
    }
    public set showDurationAsDeviceTimeZone(value: boolean) {
        this._showDurationAsDeviceTimeZone = value;
        this.update();
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();
            this._grabBox.uncache();
            this._segments.uncache();

            this.container.removeAllChildren();

            this._grabBox = null;
            this._segments = null;

            this._preLoad?.onDestroy();
            this._postLoad?.onDestroy();

            this._preLoad = null;
            this._postLoad = null;
        });
    }

    public resize(width: number): void {
        this._zone.runOutsideAngular(() => {
            this.width = width;
            this._fullSegmentCount = Math.floor(this.width / this._segmentWidth);
            this._fullSegmentWidth = this._fullSegmentCount * this._segmentWidth;

            this._preLoad.resize(width, this._fullSegmentWidth);
            this._postLoad.resize(width, this._fullSegmentWidth);

            this.update();
        });
    }

    public set width(value: number) {
        this._zone.runOutsideAngular(() => {
            if (this._width !== value) {
                this._width = value;
            }
        });
    }

    public get width(): number {
        return this._width;
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            if (this._loadQueue.isLoaded === true) {
                this.drawGrabBox();
                this.updateLabels();
                this.updateSegments();
                this.requireStageUpdate.next();
            }
        });
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this.addSubscription(this._playService.onStart.subscribe(() => this.update()));
            this.addSubscription(this._playService.onViewUpdated.subscribe(() => this.playServiceViewUpdated()));

            this.addSubscription(this._postLoad.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
            this.addSubscription(this._preLoad.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));

            this._grabBox.on('click', this.mouseClick.bind(this));
            this._grabBox.on('mousedown', this.mouseDown.bind(this));
            this._grabBox.on('pressmove', this.pressMove.bind(this));
            this._grabBox.on('pressup', this.pressUp.bind(this));
        });
    }

    private drawGrabBox(): void {
        this._zone.runOutsideAngular(() => {
            const graphics = this._grabBox.graphics;

            graphics.clear();
            graphics.beginFill(ColorUtility.hexToRGBA(Settings.grabBox.fillColor, Settings.grabBox.lineAlpha));
            graphics.rect(0, 0, this.width, this.height);

            graphics.endFill();

            this._grabBox.cache(0, 0, this.width, this.height, GLOBAL_CACHE_SCALE);
            this._grabBox.regX = this.width / 2;
            this._grabBox.x = this.width / 2;
        });
    }

    private mouseClick(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (this.isDragging === false) {
                const localMousePoint = this._durationViewContainer.globalToLocal(event.stageX, null);
                this._playService.currentLocation = this._playService.pixelToPlayLocation(localMousePoint.x, this.fullSegmentWidth);
            }
        });
    }

    private mouseDown(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.isMouseDown = true;
            this._lastMousePoint = this._durationViewContainer.globalToLocal(event.stageX, null);
            this.update();
        });
    }

    private onLoadQueueComplete(): void {
        this._zone.runOutsideAngular(() => {
            this._segment = this._loadQueue.getDurationBarSegment();
            this.update();
        });
    }

    private playServiceViewUpdated(): void {
        if (this._playService.duration !== this._playService.viewDuration) {
            this._grabBox.cursor = 'e-resize';
        } else {
            this._grabBox.cursor = 'pointer';
        }
    }

    private pressMove(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (this.isMouseDown === true) {
                this._isDragging = true;
                const pixelsPerMs = this._playService.viewDuration / this.fullSegmentWidth;
                const localMousePoint = this._durationViewContainer.globalToLocal(event.stageX, null);
                const moveRange = this._lastMousePoint.x - localMousePoint.x;
                const duration = moveRange * pixelsPerMs;
                const newViewStartOffset = this._playService.viewStartLocation.offset + duration;
                const newViewEndOffset = this._playService.viewEndLocation.offset + duration;

                this._playService.setViewRange({ offset: newViewStartOffset }, { offset: newViewEndOffset });

                this._lastMousePoint = localMousePoint;

                this.requireStageUpdate.next();
            }
        });
    }

    private pressUp(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.isMouseDown = false;
            this._isDragging = false;
            this._lastMousePoint = null;
        });
    }

    private removeEventHandlers(): void {
        super.onDestroy();
    }

    private updateLabels(): void {
        this._zone.runOutsideAngular(() => {
            if (this._updateLabelsIntervalTracker.tick() === true) {
                const segmentDuration = this._playService.viewDuration / this.fullSegmentCount;

                let length = this._labels.length;
                for (let i = 0; i < length; i++) {
                    const label = this._labels[i];
                    if (!isNullOrUndefined(label)) {
                        this.container.removeChild(label);
                    }
                }

                this._labels = [];
                for (let i = 0; i <= this.fullSegmentCount; i++) {
                    let text: string;

                    if (this.showDurationAsTime === true) {
                        if (this.showDurationAsDeviceTimeZone === true) {
                            text = DateTimeUtility.toLongTime(DateTimeUtility.add(this._playService.startTime, this._playService.viewStartLocation.offset + (i * segmentDuration), 'milliseconds'), this.timeZone);
                        } else {
                            text = DateTimeUtility.toLongTime(DateTimeUtility.add(this._playService.startTime, this._playService.viewStartLocation.offset + (i * segmentDuration), 'milliseconds'));
                        }
                    } else {
                        text = DateTimeUtility.millisecondsToDuration(this._playService.viewStartLocation.offset + (i * segmentDuration));
                    }

                    const label = new createjs.Text(text, this._labelFont);

                    const width = label.getMeasuredWidth();

                    if (i > 0) {
                        label.regX = width / 2;
                        label.x = i * this._segmentWidth;
                    } else {
                        label.x = 0;
                    }

                    label.cache(0, 0, width, this._labelHeight, GLOBAL_CACHE_SCALE);

                    this._labels.push(label);
                }

                length = this._labels.length;
                for (let i = 0; i < length; i++) {
                    const label = this._labels[i];
                    if (!isNullOrUndefined(label)) {
                        this.container.addChild(label);
                    }
                }
            }
        });
    }

    private updateSegments(): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._segment)) {
                const graphics = this._segments.graphics;

                graphics.clear();
                graphics.beginBitmapFill(this._segment, 'repeat-x');
                graphics.drawRect(0, 0, this.width, this._segmentHeight);
                graphics.endFill();

                this._segments.cache(0, 0, this.width, this._segmentHeight, GLOBAL_CACHE_SCALE);
                this._segments.y = this._labelHeight;
            }
        });
    }
}
