<div class="em-settings-servicestatusandconfiguration">
    <mat-card class="spacing-bottom"
        fxLayout="row">
        <div class="spacing-right">
            <i class="fa fa-circle statusIndicator ok spacing-right"></i> Running
        </div>
        <div class="spacing-right">
            <i class="fa fa-circle statusIndicator warning spacing-right"></i>Not seen for 2 minutes
        </div>
        <div>
            <i class="fa fa-circle statusIndicator error spacing-right"></i>Not seen for 10 minutes
        </div>
    </mat-card>
    <div fxLayout="column"
        fxLayoutAlign="start stretch">
        <div class="spacing-bottom"
            fxLayout.gt-sm="row"
            fxLayout.lt-md="column">
            <shr-table [isLoadingData]="loadDataProcess.isRunning"
                [dataRefreshedTrigger]="refreshingServiceStatesProcess.isRunning"
                [filterEnabled]="false"
                [pageEnabled]="false"
                title="Counter Service"
                ngClass.gt-sm="spacing-right"
                ngClass.lt-md="spacing-bottom"
                fxFlex="50"
                [dataSource]="eMCounterServiceDataSource"
                [displayedColumns]="displayedColumns"
                [dataExportEnabled]="true"
                [dataExportGetDataHandler]="dataExportGetData"
                [dataExportGetHeaderHandler]="dataExportGetHeader">
                <ng-template #shrTableActions
                    let-service>
                    <button *ngIf="(userIsSystemAdmin | async) === true"
                        matTooltip="Settings"
                        mat-icon-button
                        (click)="settings(service)">
                        <mat-icon>settings</mat-icon>
                    </button>
                </ng-template>

                <ng-container matColumnDef="state">
                    <th mat-header-cell
                        *matHeaderCellDef></th>
                    <td mat-cell
                        *matCellDef="let service">
                        <i class="fa fa-circle statusIndicator"
                            [class.ok]="service.state === 0"
                            [class.warning]="service.state === 2"
                            [class.error]="service.state === 3"></i>
                    </td>
                </ng-container>

                <ng-container matColumnDef="networkAddress">
                    <th mat-header-cell
                        *matHeaderCellDef> Machine Name </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="networkAddress"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="moduleVersion">
                    <th mat-header-cell
                        *matHeaderCellDef> Version </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="moduleVersionText"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="upTime">
                    <th mat-header-cell
                        *matHeaderCellDef> Uptime </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="upTimeText"></shr-table-cell>
                    </td>
                </ng-container>
            </shr-table>
            <shr-table [isLoadingData]="loadDataProcess.isRunning"
                [dataRefreshedTrigger]="loadDataProcess.isRunning"
                [filterEnabled]="false"
                [pageEnabled]="false"
                title="Data Service"
                fxFlex="50"
                [dataSource]="eMDataServiceDataSource"
                [displayedColumns]="displayedColumns"
                [dataExportEnabled]="true"
                [dataExportGetDataHandler]="dataExportGetData"
                [dataExportGetHeaderHandler]="dataExportGetHeader">
                <ng-container matColumnDef="state">
                    <th mat-header-cell
                        *matHeaderCellDef></th>
                    <td mat-cell
                        *matCellDef="let service">
                        <i class="fa fa-circle statusIndicator"
                            [class.ok]="service.state === 0"
                            [class.warning]="service.state === 2"
                            [class.error]="service.state === 3"></i>
                    </td>
                </ng-container>

                <ng-container matColumnDef="networkAddress">
                    <th mat-header-cell
                        *matHeaderCellDef> Machine Name </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="networkAddress"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="moduleVersion">
                    <th mat-header-cell
                        *matHeaderCellDef> Version </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="moduleVersionText"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="upTime">
                    <th mat-header-cell
                        *matHeaderCellDef> Uptime </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="upTimeText"></shr-table-cell>
                    </td>
                </ng-container>
            </shr-table>
        </div>
        <div class="spacing-bottom"
            fxLayout.gt-sm="row"
            fxLayout.lt-md="column">
            <shr-table [isLoadingData]="loadDataProcess.isRunning"
                [dataRefreshedTrigger]="refreshingServiceStatesProcess.isRunning"
                [filterEnabled]="false"
                [pageEnabled]="false"
                title="Licence Service"
                ngClass.gt-sm="spacing-right"
                ngClass.lt-md="spacing-bottom"
                fxFlex="50"
                [dataSource]="eMLicensingDataSource"
                [displayedColumns]="displayedColumns"
                [dataExportEnabled]="true"
                [dataExportGetDataHandler]="dataExportGetData"
                [dataExportGetHeaderHandler]="dataExportGetHeader">
                <ng-container matColumnDef="state">
                    <th mat-header-cell
                        *matHeaderCellDef></th>
                    <td mat-cell
                        *matCellDef="let service">
                        <i class="fa fa-circle statusIndicator"
                            [class.ok]="service.state === 0"
                            [class.warning]="service.state === 2"
                            [class.error]="service.state === 3"></i>
                    </td>
                </ng-container>

                <ng-container matColumnDef="networkAddress">
                    <th mat-header-cell
                        *matHeaderCellDef> Machine Name </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="networkAddress"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="moduleVersion">
                    <th mat-header-cell
                        *matHeaderCellDef> Version </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="moduleVersionText"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="upTime">
                    <th mat-header-cell
                        *matHeaderCellDef> Uptime </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="upTimeText"></shr-table-cell>
                    </td>
                </ng-container>
            </shr-table>
            <shr-table [isLoadingData]="loadDataProcess.isRunning"
                [dataRefreshedTrigger]="refreshingServiceStatesProcess.isRunning"
                [filterEnabled]="false"
                [pageEnabled]="false"
                title="Schedule Engine"
                fxFlex="50"
                [dataSource]="eMSchedulerDataSource"
                [displayedColumns]="displayedColumns"
                [dataExportEnabled]="true"
                [dataExportGetDataHandler]="dataExportGetData"
                [dataExportGetHeaderHandler]="dataExportGetHeader">
                <ng-container matColumnDef="state">
                    <th mat-header-cell
                        *matHeaderCellDef></th>
                    <td mat-cell
                        *matCellDef="let service">
                        <i class="fa fa-circle statusIndicator"
                            [class.ok]="service.state === 0"
                            [class.warning]="service.state === 2"
                            [class.error]="service.state === 3"></i>
                    </td>
                </ng-container>

                <ng-container matColumnDef="networkAddress">
                    <th mat-header-cell
                        *matHeaderCellDef> Machine Name </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="networkAddress"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="moduleVersion">
                    <th mat-header-cell
                        *matHeaderCellDef> Version </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="moduleVersionText"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="upTime">
                    <th mat-header-cell
                        *matHeaderCellDef> Uptime </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="upTimeText"></shr-table-cell>
                    </td>
                </ng-container>
            </shr-table>
        </div>
        <div class="spacing-bottom"
            fxLayout.gt-sm="row"
            fxLayout.lt-md="column">
            <shr-table [isLoadingData]="loadDataProcess.isRunning"
                [dataRefreshedTrigger]="refreshingServiceStatesProcess.isRunning"
                [filterEnabled]="false"
                [pageEnabled]="false"
                title="Video Download Service"
                ngClass.gt-sm="spacing-right"
                ngClass.lt-md="spacing-bottom"
                fxFlex="50"
                [dataSource]="eMVDLServiceDataSource"
                [displayedColumns]="displayedColumns"
                [dataExportEnabled]="true"
                [dataExportGetDataHandler]="dataExportGetData"
                [dataExportGetHeaderHandler]="dataExportGetHeader">
                <ng-container matColumnDef="state">
                    <th mat-header-cell
                        *matHeaderCellDef></th>
                    <td mat-cell
                        *matCellDef="let service">
                        <i class="fa fa-circle statusIndicator"
                            [class.ok]="service.state === 0"
                            [class.warning]="service.state === 2"
                            [class.error]="service.state === 3"></i>
                    </td>
                </ng-container>

                <ng-container matColumnDef="networkAddress">
                    <th mat-header-cell
                        *matHeaderCellDef> Machine Name </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="networkAddress"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="moduleVersion">
                    <th mat-header-cell
                        *matHeaderCellDef> Version </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="moduleVersionText"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="upTime">
                    <th mat-header-cell
                        *matHeaderCellDef> Uptime </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="upTimeText"></shr-table-cell>
                    </td>
                </ng-container>
            </shr-table>
            <shr-table [isLoadingData]="loadDataProcess.isRunning"
                [dataRefreshedTrigger]="refreshingServiceStatesProcess.isRunning"
                [filterEnabled]="false"
                [pageEnabled]="false"
                title="RIFT Setup Service"
                fxFlex="50"
                [dataSource]="eMRIFTSetupServiceDataSource"
                [displayedColumns]="displayedColumns"
                [dataExportEnabled]="true"
                [dataExportGetDataHandler]="dataExportGetData"
                [dataExportGetHeaderHandler]="dataExportGetHeader">
                <ng-container matColumnDef="state">
                    <th mat-header-cell
                        *matHeaderCellDef></th>
                    <td mat-cell
                        *matCellDef="let service">
                        <i class="fa fa-circle statusIndicator"
                            [class.ok]="service.state === 0"
                            [class.warning]="service.state === 2"
                            [class.error]="service.state === 3"></i>
                    </td>
                </ng-container>

                <ng-container matColumnDef="networkAddress">
                    <th mat-header-cell
                        *matHeaderCellDef> Machine Name </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="networkAddress"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="moduleVersion">
                    <th mat-header-cell
                        *matHeaderCellDef> Version </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="moduleVersionText"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="upTime">
                    <th mat-header-cell
                        *matHeaderCellDef> Uptime </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="upTimeText"></shr-table-cell>
                    </td>
                </ng-container>
            </shr-table>
        </div>
        <div class="spacing-bottom"
            fxLayout.gt-sm="row"
            fxLayout.lt-md="column">
            <shr-table [isLoadingData]="loadDataProcess.isRunning"
                [dataRefreshedTrigger]="refreshingServiceStatesProcess.isRunning"
                [filterEnabled]="false"
                [pageEnabled]="false"
                title="Task Launcher Service"
                ngClass.gt-sm="spacing-right"
                ngClass.lt-md="spacing-bottom"
                fxFlex="50"
                [dataSource]="eMTLServiceDataSource"
                [displayedColumns]="displayedColumns"
                [dataExportEnabled]="true"
                [dataExportGetDataHandler]="dataExportGetData"
                [dataExportGetHeaderHandler]="dataExportGetHeader">
                <ng-container matColumnDef="state">
                    <th mat-header-cell
                        *matHeaderCellDef></th>
                    <td mat-cell
                        *matCellDef="let service">
                        <i class="fa fa-circle statusIndicator"
                            [class.ok]="service.state === 0"
                            [class.warning]="service.state === 2"
                            [class.error]="service.state === 3"></i>
                    </td>
                </ng-container>

                <ng-container matColumnDef="networkAddress">
                    <th mat-header-cell
                        *matHeaderCellDef> Machine Name </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="networkAddress"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="moduleVersion">
                    <th mat-header-cell
                        *matHeaderCellDef> Version </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="moduleVersionText"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="upTime">
                    <th mat-header-cell
                        *matHeaderCellDef> Uptime </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="upTimeText"></shr-table-cell>
                    </td>
                </ng-container>
            </shr-table>
            <shr-table [isLoadingData]="loadDataProcess.isRunning"
                [dataRefreshedTrigger]="refreshingServiceStatesProcess.isRunning"
                [filterEnabled]="false"
                [pageEnabled]="false"
                title="Notification Service"
                fxFlex="50"
                [dataSource]="eMNotificationServiceDataSource"
                [displayedColumns]="displayedColumns"
                [dataExportEnabled]="true"
                [dataExportGetDataHandler]="dataExportGetData"
                [dataExportGetHeaderHandler]="dataExportGetHeader">
                <ng-container matColumnDef="state">
                    <th mat-header-cell
                        *matHeaderCellDef></th>
                    <td mat-cell
                        *matCellDef="let service">
                        <i class="fa fa-circle statusIndicator"
                            [class.ok]="service.state === 0"
                            [class.warning]="service.state === 2"
                            [class.error]="service.state === 3"></i>
                    </td>
                </ng-container>

                <ng-container matColumnDef="networkAddress">
                    <th mat-header-cell
                        *matHeaderCellDef> Machine Name </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="networkAddress"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="moduleVersion">
                    <th mat-header-cell
                        *matHeaderCellDef> Version </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="moduleVersionText"></shr-table-cell>
                    </td>
                </ng-container>

                <ng-container matColumnDef="upTime">
                    <th mat-header-cell
                        *matHeaderCellDef> Uptime </th>
                    <td mat-cell
                        *matCellDef="let service">
                        <shr-table-cell [data]="service"
                            keyPath="upTimeText"></shr-table-cell>
                    </td>
                </ng-container>
            </shr-table>
        </div>
    </div>
</div>