import { Injectable } from '@angular/core';
import { BackupFileModel } from '@rift/models/restapi/BackupFile.Model';
import { DiscriminationModel } from '@rift/models/restapi/Discrimination.Model';
import { ErrorsAndWarningsModel } from '@rift/models/restapi/ErrorsAndWarnings.Model';
import { GlobalModel } from '@rift/models/restapi/Global.Model';
import { ImageSnapShotModel } from '@rift/models/restapi/ImageSnapShot.Model';
import { IPSetupModel } from '@rift/models/restapi/IPSetup.Model';
import { RestoreSummaryInfoModel } from '@rift/models/restapi/RestoreSummaryInfo.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { SyncTimeModel } from '@rift/models/restapi/SyncTime.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiGlobalService } from '@rift/service/restapi/v1/RestApi.Global.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { IFileResponse, IRetryOptions } from '@shared/service/restapi/RestApi.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class GlobalService extends RiftBaseService {
    private _discriminationCache: DiscriminationModel = null;

    private _getBackupLoadingTracker = new ObservableTracker<IFileResponse>();
    private _getDiagnosticsCountsLoadingTracker = new ObservableTracker<ErrorsAndWarningsModel>();
    private _getDiagnosticsLoadingTracker = new ObservableTracker<ErrorsAndWarningsModel>();
    private _getDiscriminationLoadingTracker = new ObservableTracker<DiscriminationModel>();
    private _getGlobalLoadingTracker = new ObservableTracker<GlobalModel>();
    private _getIpSetupLoadingTracker = new ObservableTracker<IPSetupModel>();
    private _getLogFileLoadingTracker = new ObservableTracker<IFileResponse>();
    private _getRestoreSummaryInfoLoadingTracker = new ObservableTracker<RestoreSummaryInfoModel>();
    private _getTimeSetupLoadingTracker = new ObservableTracker<TimeSetupModel>();
    private _globalCache: GlobalModel = null;
    private _iPSetupCache: IPSetupModel = null;
    private _rebootDevicesLoadingTracker = new ObservableTracker<ResultModel>();
    private _resetCountsLoadingTracker = new ObservableTracker<ResultModel>();
    private _restoreDeviceSettingsLoadingTracker = new ObservableTracker<ResultModel>();
    private _syncTimeLoadingTracker = new ObservableTracker<ResultModel>();
    private _timeSetupCache: TimeSetupModel = null;
    private _updateDiscriminationLoadingTracker = new ObservableTracker<ResultModel>();
    private _updateGlobalLoadingTracker = new ObservableTracker<ResultModel>();
    private _updateIpSetupLoadingTracker = new ObservableTracker<ResultModel>();
    private _updateTimeSetupLoadingTracker = new ObservableTracker<ResultModel>();
    private _diagnosticsCountCache: ErrorsAndWarningsModel = null;

    public constructor(
        private readonly _restApiGlobalService: RestApiGlobalService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._globalCache = null;
        this._timeSetupCache = null;
        this._iPSetupCache = null;
        this._discriminationCache = null;
        this._diagnosticsCountCache = null;
    }

    public clearDiagnosticsCountsCache(): void{
        this._diagnosticsCountCache = null;
    }

    public getBackup(process?: ProcessMonitorServiceProcess): Observable<IFileResponse> {
        return this._getBackupLoadingTracker
            .getLoading()
            .observable(this._restApiGlobalService.getBackup(process));
    }

    public getDiagnostics(process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ErrorsAndWarningsModel> {
        return this._getDiagnosticsLoadingTracker
            .getLoading()
            .observable(this._restApiGlobalService.getDiagnostics(process, retryOptions));
    }

    public getDiagnosticsCounts(process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ErrorsAndWarningsModel> {
        if (isNullOrUndefined(this._diagnosticsCountCache)){
            return this._getDiagnosticsCountsLoadingTracker
            .getLoading()
            .observable(this._restApiGlobalService.getDiagnosticsCounts(process, retryOptions).pipe(
                map(result =>{
                    this._diagnosticsCountCache = result;
                    return this._diagnosticsCountCache;
                })
            ));
        }
        else{
            return of(this._diagnosticsCountCache);
        }
    }

    public getDiscrimination(process?: ProcessMonitorServiceProcess): Observable<DiscriminationModel> {
        if (isNullOrUndefined(this._discriminationCache)) {
            return this._getDiscriminationLoadingTracker
                .getLoading()
                .observable(this._restApiGlobalService.getDiscrimination(process).pipe(
                    map(result => {
                        this._discriminationCache = result;
                        return this._discriminationCache;
                    })
                ));
        } else {
            return of(this._discriminationCache);
        }
    }

    public getGlobal(process?: ProcessMonitorServiceProcess): Observable<GlobalModel> {
        if (isNullOrUndefined(this._globalCache)) {
            return this._getGlobalLoadingTracker
                .getLoading()
                .observable(this._restApiGlobalService.getGlobal(process).pipe(
                    map(result => {
                        this._globalCache = result;
                        return this._globalCache;
                    })
                ));
        } else {
            return of(this._globalCache);
        }
    }

    public getImageSnapshot(process?: ProcessMonitorServiceProcess): Observable<ImageSnapShotModel> {
        return this._restApiGlobalService.getImageSnapshot(process);
    }

    public getIpSetup(process?: ProcessMonitorServiceProcess): Observable<IPSetupModel> {
        if (isNullOrUndefined(this._iPSetupCache)) {
            return this._getIpSetupLoadingTracker
                .getLoading()
                .observable(this._restApiGlobalService.getIpSetup(process).pipe(
                    map(result => {
                        this._iPSetupCache = result;
                        return this._iPSetupCache;
                    })
                ));
        } else {
            return of(this._iPSetupCache);
        }
    }

    public getLogFile(process?: ProcessMonitorServiceProcess): Observable<IFileResponse> {
        return this._getLogFileLoadingTracker
            .getLoading()
            .observable(this._restApiGlobalService.getLogFile(process));
    }

    public getRestoreSummaryInfo(backupFile: BackupFileModel, process?: ProcessMonitorServiceProcess): Observable<RestoreSummaryInfoModel> {
        return this._getRestoreSummaryInfoLoadingTracker
            .getLoading()
            .observable(this._restApiGlobalService.getRestoreSummaryInfo(backupFile, process));
    }

    public getTimeSetup(process?: ProcessMonitorServiceProcess): Observable<TimeSetupModel> {
        if (isNullOrUndefined(this._timeSetupCache)) {
            return this._getTimeSetupLoadingTracker
                .getLoading()
                .observable(this._restApiGlobalService.getTimeSetup(process).pipe(
                    map(result => {
                        this._timeSetupCache = result;
                        return this._timeSetupCache;
                    })
                ));
        } else {
            return of(this._timeSetupCache);
        }
    }

    public rebootDevices(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._rebootDevicesLoadingTracker
            .getLoading()
            .observable(this._restApiGlobalService.rebootDevices(process));
    }

    public resetCounts(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._resetCountsLoadingTracker
            .getLoading()
            .observable(this._restApiGlobalService.resetCounts(process));
    }

    public restoreDeviceSettings(backupFile: BackupFileModel, physicalDevice: string, fileDevice: string, restoreIPSettings: boolean, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._restoreDeviceSettingsLoadingTracker
            .getLoading()
            .observable(this._restApiGlobalService.restoreDeviceSettings(backupFile, physicalDevice, fileDevice, restoreIPSettings, process));
    }

    public setCache(globalCache: GlobalModel, timeInfoCache: TimeSetupModel, iPSetupCache: IPSetupModel, discriminationCache: DiscriminationModel): Observable<boolean> {
        this._globalCache = globalCache;
        this._timeSetupCache = timeInfoCache;
        this._iPSetupCache = iPSetupCache;
        this._discriminationCache = discriminationCache;
        return of(true);
    }

    public syncTime(time: SyncTimeModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._syncTimeLoadingTracker
            .getLoading()
            .observable(this._restApiGlobalService.syncTime(time, process));
    }

    public updateDiscrimination(discrimination: DiscriminationModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateDiscriminationLoadingTracker
            .getLoading(discrimination)
            .observable(this._restApiGlobalService.updateDiscrimination(discrimination, process).pipe(tap(() => this._discriminationCache = null)));
    }

    public updateGlobal(global: GlobalModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateGlobalLoadingTracker
            .getLoading(global)
            .observable(this._restApiGlobalService.updateGlobal(global, process).pipe(tap(() => this._globalCache = null)));
    }

    public updateIpSetup(ipSetup: IPSetupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateIpSetupLoadingTracker
            .getLoading(ipSetup)
            .observable(this._restApiGlobalService.updateIpSetup(ipSetup, process).pipe(tap(() => this._iPSetupCache = null)));
    }

    public updateTimeSetup(timeSetup: TimeSetupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateTimeSetupLoadingTracker
            .getLoading(timeSetup)
            .observable(this._restApiGlobalService.updateTimeSetup(timeSetup, process).pipe(tap(() => this._timeSetupCache = null)));
    }
}
