<div class="counting-setting-content">
    <div class="spacing-bottom">
        <mat-checkbox [disabled]="isReadOnly"
            [checked]="enabled"
            (change)="onEnabledChange($event)"
            class="spacing-bottom">Enable</mat-checkbox>
    </div>
    <div *ngIf="enabled === true"
        fxLayout="row"
        fxLayoutAlign="start center">
        <form [formGroup]="form"
            fxFlex>
            <mat-form-field fxFlex="50"
                class="spacing-right">
                <input matInput
                    formControlName="maxDwellDuration"
                    placeholder="Max Dwell Duration"/>    
                <span matSuffix>
                    S
                </span>            
                <mat-error>{{getFormControlErrorMessage(form.controls['maxDwellDuration'])}}</mat-error>
            </mat-form-field>            
        </form>
    </div>
</div>
<div class="error"
    fxLayout="column">
    {{getFormControlErrorMessage(form)}}
</div>