import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class ModuleStatusModel extends BaseModel implements IRestModel {
    public isAvailable: boolean = null;
    public isConfigurable: boolean = null;
    public isOldVersion: boolean = false;
    public lastUpdated: Date = null;
    public moduleDescription: string = null;
    public moduleId: string = null;
    public moduleName: string = null;
    public moduleVersion: string = null;
    public networkAddress: string = null;
    public readonly isIRestModel = true;
    public upTime: number = null;
    private _moduleVersionText: string;
    private _state: number;
    private _upTimeText: string;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ModuleStatusModel');

        this.lastUpdated = DateTimeNonMomentUtility.fromRestApiDateTime(this.lastUpdated);

        if (isNullOrUndefined(this._moduleVersionText)) {
            this._moduleVersionText = this.moduleVersion;
        }

        if (isNullOrUndefined(this._upTimeText) || isNullOrUndefined(this.upTime) || this._upTimeText === 'a few seconds') {
            this._upTimeText = DateTimeUtility.secondsToRelativeDuration(this.upTime);
        }

        if (isNullOrUndefined(this._state) || DateTimeUtility.isInvalidDate(this.lastUpdated as any) || isNullOrUndefined(this.upTime) || this._upTimeText === 'a few seconds') {
            if (DateTimeUtility.isBefore(this.lastUpdated, 24, 'hours')) {
                this._state = 4;
            } else if (DateTimeUtility.isBefore(this.lastUpdated, 10, 'minutes')) {
                this._state = 3;
            } else if (DateTimeUtility.isBefore(this.lastUpdated, 2, 'minutes')) {
                this._state = 2;
            } else if (!this.isAvailable) {
                this._state = 1;
            } else {
                this._state = 0;
            }
        }
    }

    public get moduleVersionText(): string {
        return this._moduleVersionText;
    }

    public get state(): number {
        return this._state;
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }

    public get upTimeText(): string {
        return this._upTimeText;
    }
}
