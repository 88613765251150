import { IChangeTrackerArray } from '@shared/generic/IChangeTrackerArray';
import { UniqueArray } from '@shared/generic/UniqueArray';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class UniqueChangeTrackerArray<T extends string | number | Date> extends UniqueArray<T> implements IChangeTrackerArray<T> {
    private _acceptedValues: Array<T> = null;

    public constructor(...values: Array<T>) {
        super();
        Object.setPrototypeOf(this, Object.create(UniqueChangeTrackerArray.prototype));
        if (!isNullOrUndefined(values)) {
            this.push(...values);
            this.commitChanges();
        }
    }

    public commitChanges(): void {
        this._acceptedValues = [];
        this._acceptedValues.push(...this);
    }

    public get hasChanges(): boolean {
        return this.length !== this._acceptedValues.length || this.some(i => this.isNew(i) || this.isMoved(i) || this.isRemoved(i));
    }

    public isMoved(value: T): boolean {
        const thisIndex = this.findIndex(i => i === value);
        const acceptedIndex = this._acceptedValues.findIndex(i => i === value);
        if (thisIndex !== -1 && acceptedIndex !== -1) {
            return thisIndex !== acceptedIndex;
        } else {
            return false;
        }
    }

    public isNew(value: T): boolean {
        return !this._acceptedValues.some(i => i === value);
    }

    public isRemoved(value: T): boolean {
        const thisIndex = this.findIndex(i => i === value);
        const acceptedIndex = this._acceptedValues.findIndex(i => i === value);
        if (thisIndex === -1 && acceptedIndex !== -1) {
            return true;
        } else {
            return false;
        }
    }

    public getItemOriginalIndex(value: string | number | Date): number {
        const length = this._acceptedValues.length;
        for (let i = 0; i < length; i++) {
            const item = this._acceptedValues[i];
            if (item === value) {
                return i;
            }
        }
        return -1;
    }

    public clearChanges(): void {
        this.splice(0, this.length);
        this.push(...this._acceptedValues);
        this.commitChanges();
    }
}
