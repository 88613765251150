<span [ngStyle]="spanStyle"
    *ngIf="viewMode === ViewModes.default"
    [matTooltip]="value">{{value}}</span>

<span [ngStyle]="spanStyle"
    *ngIf="viewMode === ViewModes.dateTimezone"
    shrTimezoneTooltip
    [shrTimezoneTooltipFormat]="dateFormat"
    [shrTimezoneTooltipTimezone]="timeSetup?.timeZone"
    [shrTimezoneTooltipUTCDate]="dateValue">{{value}} </span>

<span [ngStyle]="spanStyle">{{ hasChangesText }}</span>