import { Injectable } from '@angular/core';
import { SafeSubscriptionBase } from '@shared/base/SafeSubscription.Base';
import { AppHostEnum, IConfigurationService } from '@shared/interface/IConfigurationService';
import { PlotlyConfiguration } from '@shared/service/configuration/Configuration.Plotly';
import { LoggingServiceOptions } from '@shared/service/logging/Logging.Service.Options';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

/**
 * Global configoration
 *
 * @export
 * @class ConfigurationService
 * @extends {SafeSubscriptionBase}
 */
@Injectable()
export class ConfigurationService extends SafeSubscriptionBase implements IConfigurationService {
    public protocol: 'secure' | 'unsecure' = 'unsecure';
    public hostName: string = '';
    public port: number = null;

    public baseTitle: string = '';
    public deviceConnectionTimeOutMs: number = 0;
    public emRestApiBase: string = `${this.protocol === 'secure' ? 'https' : 'http'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/api/em/`;
    public emV1RestApiBase: string = `${this.protocol === 'secure' ? 'https' : 'http'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/api/v1/`;
    public emV2RestApiBase: string = `${this.protocol === 'secure' ? 'https' : 'http'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/api/v2/`;
    public hostApp: AppHostEnum = null;
    public loggingServiceOptions: LoggingServiceOptions = new LoggingServiceOptions();
    public plotlyOptions: PlotlyConfiguration = null;
    public appBase: string = `${this.protocol === 'secure' ? 'https' : 'http'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/`;
    public versionBase: string = this.appBase;
    public riftConnectRedirectPath: string = 'summery';
    public riftConnectRedirectPathOverride: string;
    public riftNavigateToOnFailedConnection: string[] = [''];
    public riftRefreshAllData: boolean = false;
    public riftRestApiBase: string = `${this.protocol === 'secure' ? 'https' : 'http'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/api/`;
    public riftV1RestApiBase: string = `${this.protocol === 'secure' ? 'https' : 'http'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/api/rt/v1/`;
    public riftWebSocket: string = `${this.protocol === 'secure' ? 'wss' : 'ws'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/ws`;

    /**
     * Creates an instance of ConfigurationService.
     *
     * @memberof ConfigurationService
     */
    public constructor() {
        super();
    }
}
