import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { DeviceInfo } from '@shared/component/deviceinfotooltip/DeviceInfoTooltip.Component';

export class DeviceConnectionStatusModel extends BaseModel implements IRestModel {
    public serial: number = null;
    public friendlySerial: string = null;
    public isConnected: boolean = null;
    public lastConnected: Date = null;
    public isLicensed: boolean = null;
    public deviceName: string = null;
    public deviceId: string = null;
    public siteId: string = null;
    public siteName: string = null;
    public firmwareVersion: string = null;
    public iPAddress: string = null;
    public nodeCount: number = null;

    public constructor() {
        super();
    }

    public get deviceInfo(): DeviceInfo {
        return {
            deviceName: this.deviceName,
            deviceId: this.deviceId,
            siteId: this.siteId,
            siteName: this.siteName,
            firmwareVersion: this.firmwareVersion,
            iPAddress: this.iPAddress,
            nodeCount: this.nodeCount,
            friendlySerial: this.friendlySerial,
        };
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-DeviceConnectionStatusModel');

        this.lastConnected = DateTimeNonMomentUtility.fromRestApiDateTime(this.lastConnected);
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
