import { Dictionary } from '@shared/generic/Dictionary';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { IRestModel } from '@shared/interface/IRestModel';
import { ITrackPropertyChanges } from '@shared/interface/ITrackPropertyChanges';
import { isArray, isFunction, isNullOrUndefined, isObject } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';

/**
 * Helper methods for IRestModel.
 *
 * @export
 * @class RestModelUtility
 */
export class RestModelUtility {
    private static GLOBAL_EXCLUDE_FIELDS = ['hasChanges', 'isIRestModel', 'uniqueId', 'error', 'httpError', 'userCanceled'];
    private static lazyLoadFunctionCache = new Dictionary<string, Function>();

    public static returnOrErrorThrow<T>(model: T): T {
        if (RestModelUtility.hasError(model)) {
            if (RestModelUtility.hasErrorMessage(model)) {
                throw new Error((model as any).error);
            }
            throw new Error('Rest API error');
        }
        return model;
    }

    public static hasErrorThrow(model: any): boolean {
        if (RestModelUtility.hasError(model)) {
            if (RestModelUtility.hasErrorMessage(model)) {
                throw new Error(model.error);
            }
            throw new Error('Rest API error');
        }
        return false;
    }

    public static hasError(model: any): boolean {
        if (isNullOrUndefined(model)) {
            return false;
        } else if (!isNullOrUndefined(model) && isNullOrUndefined(model.error)) {
            return false;
        }
        return true;
    }

    public static hasErrorMessage(model: any): boolean {
        return !isNullOrUndefined(model) && !StringUtility.isEmptyOrWhiteSpace(model.error);
    }

    /**
     * Loads and Array<> of IRestModel from the JSON 'array'
     *
     * @static
     * @template TRestModel The type of the model to load.
     * @param {any[]} array The JSON array to load to the models
     * @param {(new () => TRestModel)} modelType The model type.
     * @param {boolean} [upperCase=true] True if the first letter of the from object property name is uppercase. else false
     * @returns {Array<TRestModel>} The IModels.
     * @memberof RestModelUtility
     */
    public static loadFromArray<TRestModel extends IRestModel>(array: any[], modelType: (new () => TRestModel), upperCase: boolean = true): Array<TRestModel> {
        if (!isNullOrUndefined(array)) {
            const items: TRestModel[] = [];

            const length = array.length;
            for (let i = 0; i < length; i++) {
                const data = array[i];
                items.push(this.loadFrom<TRestModel>(data, modelType, upperCase));
            }

            return items;
        } else {
            return null;
        }
    }

    /**
     * Loads and ChangeTrackerArray<> of IRestModel from the JSON 'array'
     *
     * @static
     * @template TRestModel The type of the model to load.
     * @param {any[]} array The JSON array to load to the models
     * @param {(new () => TRestModel)} modelType The model type.
     * @returns {RestModelChangeTrackerArray<TRestModel>} IModels.
     * @param {boolean} [upperCase=true] True if the first letter of the from object property name is uppercase. else false
     * @memberof RestModelUtility
     */
    public static loadFromArrayToChangeTrackerArray<TRestModel extends IRestModel & ITrackPropertyChanges>(array: any[], modelType: (new () => TRestModel), upperCase: boolean = true): RestModelChangeTrackerArray<TRestModel> {
        const items = new RestModelChangeTrackerArray<TRestModel>();

        if (!isNullOrUndefined(array)) {
            const length = array.length;
            for (let i = 0; i < length; i++) {
                const data = array[i];
                items.push(this.loadFrom<TRestModel>(data, modelType, upperCase));
            }

            items.originalValuesSet();
        }

        return items;
    }

    /**
     * Loads an IRestModel from a JSON object.
     *
     * @static
     * @template TRestModel The type of the model to load.
     * @param {*} data The JSON object to load to the IRestModel.
     * @param {(new () => TRestModel)} modelType The model type.
     * @returns {TRestModel} The IRestModel loaded with JSON object data.
     * @param {boolean} [upperCase=true] True if the first letter of the from object property name is uppercase. else false
     * @memberof RestModelUtility
     */
    public static loadFrom<TRestModel extends IRestModel>(data: any, modelType: (new () => TRestModel), upperCase: boolean = true): TRestModel {
        if (!isNullOrUndefined(data)) {
            const model = new modelType();
            model.loadFromRestApiModel(data, upperCase);
            return model as TRestModel;
        } else {
            return null;
        }
    }

    /**
     * Loads property values of IRestModel from a JSON object.
     *
     * @static
     * @template TRestModel The type of the model to load.
     * @param {*} from The JSON object to load the properties from.
     * @param {TRestModel} to The IRestModel to load the properties to.
     * @param {string} toName The name of the IRestModel to load properties to.
     * @param {Array<string>} [excludes] The properties to exclued from the load.
     * @param {boolean} [upperCase=true] True if the first letter of the from object property name is uppercase. else false
     * @memberof RestModelUtility
     */
    public static loadProperties<TRestModel extends IRestModel>(from: any, to: TRestModel, toName: string, excludes?: Array<string>, upperCase: boolean = true): void {
        if (!isNullOrUndefined(from) && !isNullOrUndefined(to)) {
            const cacheName = `${toName}_${upperCase}`;
            const lazyLoadFunctionCache = this.lazyLoadFunctionCache.get(cacheName);

            if (isNullOrUndefined(lazyLoadFunctionCache)) {
                excludes = this.getKeyExcludes(excludes);

                const maps: { toKey: string; fromKey: string }[] = [];
                const keys = this.getKeys(to);
                const keysLength = keys.length;
                for (let i = 0; i < keysLength; i++) {
                    const key = keys[i];
                    if (this.filterKeys(excludes, key, to)) {
                        if (upperCase === true) {
                            maps.push({ toKey: key, fromKey: key.charAt(0).toUpperCase() + key.slice(1) });
                        } else {
                            maps.push({ toKey: key, fromKey: key });
                        }
                    }
                }

                const mappingFunc = (mappings: { toKey: string; fromKey: string }[]) => {
                    const mappingData = mappings;

                    return (fromData, toData) => {
                        const mappingDataLength = mappingData.length;

                        for (let i = 0; i < mappingDataLength; i++) {
                            const map = mappingData[i];

                            if (typeof fromData[map.fromKey] !== 'undefined') {
                                toData[map.toKey] = fromData[map.fromKey];
                            }
                        }
                    };
                };

                const func = mappingFunc(maps);

                this.lazyLoadFunctionCache.addOrUpdate(cacheName, func);

                func(from, to);
            } else {
                lazyLoadFunctionCache(from, to);
            }
        }
    }

    /**
     * Converts and Array<IRestModel> to a JSON Array.
     *
     * @static
     * @template TRestModel The type of the model to load.
     * @param {Array<TRestModel>} array The array of IModels.
     * @returns {Array<any>} The JSON array.
     * @memberof RestModelUtility
     */
    public static toJsonArray<TRestModel extends IRestModel>(array: Array<TRestModel>): Array<any> {
        if (!isNullOrUndefined(array)) {
            const items: any[] = [];

            const length = array.length;
            for (let i = 0; i < length; i++) {
                const data = array[i];
                items.push(data.toRestApiModel());
            }

            return items;
        }
    }

    /**
     * Converts a IRestModel to a JSON object.
     *
     * @static
     * @template TRestModel The type of the model to load.
     * @param {TRestModel} from The IRestModel to convert to JSON.
     * @param {Array<string>} [excludes] The properties to exclued from the conversion.
     * @param {boolean} [sendNull] If true null proprerties will be added to json.
     * @param {boolean} upperCaseFirstLetter If true (default) will apply uppercase to first letter of the json key
     * @returns {*} A JSON object.
     * @memberof RestModelUtility
     */
    public static toJson<TRestModel extends IRestModel>(from: TRestModel, excludes?: Array<string>, sendNull?: boolean, upperCaseFirstLetter: boolean = true): any {
        if (!isNullOrUndefined(from)) {
            excludes = this.getKeyExcludes(excludes);

            const maps: string[] = [];
            const keys = this.getKeys(from);
            const keysLength = keys.length;
            for (let i = 0; i < keysLength; i++) {
                const key = keys[i];
                if (this.filterKeys(excludes, key, from)) {
                    maps.push(key);
                }
            }

            const mapsLength = maps.length;

            const any = {};

            for (let i = 0; i < mapsLength; i++) {
                const fromKey = maps[i];
                const fromValue = from[fromKey];
                if ((!isNullOrUndefined(sendNull) && sendNull === true) || !isNullOrUndefined(fromValue)) {
                    if (upperCaseFirstLetter){
                        any[fromKey.charAt(0).toUpperCase() + fromKey.slice(1)] = fromValue;
                    }
                    else{
                        any[fromKey] = fromValue;
                    }
                }
            }

            return any;
        }
    }

    /**
     * True if object is IRestModel. else false.
     *
     * @static
     * @param {*} object The object to check.
     * @returns {boolean} True if object is IRestModel. else false.
     * @memberof RestModelUtility
     */
    public static isIRestModel(object: any): boolean {
        if (isArray(object)) {
            return object.length > 0 && !isNullOrUndefined(object[0].isIRestModel) && object[0].isIRestModel === true;
        } else {
            return !isNullOrUndefined(object) && !isNullOrUndefined(object.isIRestModel) && object.isIRestModel === true;
        }
    }

    /**
     * Removes properties that should not be mapped.
     *
     * @private
     * @static
     * @memberof RestModelUtility
     */
    private static filterKeys(excludes: Array<string>, key: string, obj: any): boolean {
        return !isObject(obj[key]) && !isArray(obj[key]) && !isFunction(obj[key]) && key.charAt(0) !== '_' && (isNullOrUndefined(excludes) || excludes.indexOf(key) === -1);
    }

    /**
     * Gets all the properties to exclued.
     *
     * @private
     * @static
     * @memberof RestModelUtility
     */
    private static getKeyExcludes(excludes?: Array<string>): Array<string> {
        if (!isNullOrUndefined(excludes)) {
            return this.GLOBAL_EXCLUDE_FIELDS.concat(excludes);
        } else {
            return this.GLOBAL_EXCLUDE_FIELDS;
        }
    }

    /**
     * Gets all the keys of an object.
     *
     * @private
     * @static
     * @memberof RestModelUtility
     */
    private static getKeys(obj: any): Array<string> {
        let objectToInspect;
        let keys: Array<string> = [];

        for (objectToInspect = obj; objectToInspect !== null; objectToInspect = Object.getPrototypeOf(objectToInspect)) {
            keys = keys.concat(Object.getOwnPropertyNames(objectToInspect));
        }

        return keys;
    }
}
