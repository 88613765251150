import { NodeInfoModel } from '@rift/models/restapi/NodeInfo.Model';
import { CollectionModel, ICollectionModel } from '@shared/models/restapi/Collection.Model';

export interface INodeInfoCollectionModel extends ICollectionModel<NodeInfoModel> {

}

export class NodeInfoCollectionModel extends CollectionModel<NodeInfoModel> implements INodeInfoCollectionModel {
    public constructor() {
        super(NodeInfoModel);
    }
}
