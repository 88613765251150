import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IVideoStorageCapacityModel extends IRestModel {
    error: string;
    statusCode: number;
    deviceStorageCapacityKB: number;
    deviceStorageFreeCapacityKB: number;
}

export class VideoStorageCapacityModel extends BaseModel implements IVideoStorageCapacityModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public deviceStorageCapacityKB: number = null;
    public deviceStorageFreeCapacityKB: number = null;

    private _freePercent: number = null;
    private _deviceStorageCapacityKBHumanized: string;
    private _deviceStorageFreeCapacityKBHumanized: string;
    private _storageWarning: boolean = false;
    private _storageError: boolean = false;
    private _noSDCard: boolean = false;
    private _sdCardNotFormatted: boolean = false;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-VideoStorageCapacityModel');

        this._freePercent = this.deviceStorageFreeCapacityKB * (100 / this.deviceStorageCapacityKB);
        this._deviceStorageCapacityKBHumanized = `${(this.deviceStorageCapacityKB / 1024 / 1024).toFixed(2)} GB`;
        this._deviceStorageFreeCapacityKBHumanized = `${(this.deviceStorageFreeCapacityKB / 1024 / 1024).toFixed(2)} GB`;

        if (this.deviceStorageCapacityKB === 0 && this.deviceStorageFreeCapacityKB === 0) {
            this._noSDCard = true;
        } else if (this.deviceStorageCapacityKB === 0 && this.deviceStorageFreeCapacityKB === 1) {
            this._sdCardNotFormatted = true;
        } else if (this.deviceStorageCapacityKB < 200000) {
            if (this.deviceStorageFreeCapacityKB < 2048) {
                this._storageError = true;
            } else {
                this._storageWarning = true;
            }
        }
    }

    public get storageWarning(): boolean {
        return this._storageWarning;
    }

    public get storageError(): boolean {
        return this._storageError;
    }

    public get noSDCard(): boolean {
        return this._noSDCard;
    }

    public get sdCardNotFormatted(): boolean {
        return this._sdCardNotFormatted;
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }

    public get freePercent(): number {
        return this._freePercent;
    }

    public get deviceStorageCapacityKBHumanized(): string {
        return this._deviceStorageCapacityKBHumanized;
    }

    public get deviceStorageFreeCapacityKBHumanized(): string {
        return this._deviceStorageFreeCapacityKBHumanized;
    }
}
