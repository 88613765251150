import { Injectable } from '@angular/core';
import { PageModel } from '@em/models/restapi/Page.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { VideoEntryModel } from '@em/models/restapi/VideoEntry.Model';
import { VideoEntryCountsModel } from '@em/models/restapi/VideoEntryCounts.Model';
import { VideoStateModel } from '@em/models/restapi/VideoState.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiGlobalRecordingsService } from '@em/service/restapi/RestApi.GlobalRecordings.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';


@Injectable()
export class RecordingsService extends EmBaseService {

    private _cancelSyncVideoSessionLoadingTracker = new ObservableTracker<null>();
    private _deleteVideoScheduleLoadingTracker = new ObservableTracker<null>();
    private _deleteVideoSessionLoadingTracker = new ObservableTracker<null>();
    private _deleteVideoSyncedSessionLoadingTracker = new ObservableTracker<null>();
    private _getCountsCache: VideoEntryCountsModel;
    private _getCountsLoadingTracker = new ObservableTracker<VideoEntryCountsModel>();
    private _getFirstVideoFrameLoadingTracker = new ObservableTracker<VideoEntryModel>();
    private _getVideoSchedulesLoadingTracker = new ObservableTracker<PageModel<VideoEntryModel>>();
    private _getVideoSessionStatesLoadingTracker = new ObservableTracker<Array<VideoStateModel>>();
    private _getVideoSessionsLoadingTracker = new ObservableTracker<PageModel<VideoEntryModel>>();
    private _pauseSyncVideoSessionLoadingTracker = new ObservableTracker<null>();
    private _resumeSyncVideoSessionLoadingTracker = new ObservableTracker<null>();
    private _syncVideoSessionLoadingTracker = new ObservableTracker<null>();

    public constructor(
        private readonly _restApiGlobalRecordingsService: RestApiGlobalRecordingsService) {
        super();
    }

    public cancelSyncVideoSession(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this._cancelSyncVideoSessionLoadingTracker
            .getLoading(item)
            .observable(this._restApiGlobalRecordingsService.cancelSyncVideoSession(item, process));
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getCountsCache = null;
    }

    public deleteVideoSchedule(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this._deleteVideoScheduleLoadingTracker
            .getLoading(item)
            .observable(this._restApiGlobalRecordingsService.deleteVideoSchedule(item, process));
    }

    public deleteVideoSession(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this._deleteVideoSessionLoadingTracker
            .getLoading(item)
            .observable(this._restApiGlobalRecordingsService.deleteVideoSession(item, process));
    }

    public deleteVideoSyncedSession(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this._deleteVideoSyncedSessionLoadingTracker
            .getLoading(item)
            .observable(this._restApiGlobalRecordingsService.deleteVideoSyncedSession(item, process));
    }

    public getCounts(process?: ProcessMonitorServiceProcess): Observable<VideoEntryCountsModel> {
        if (isNullOrUndefined(this._getCountsCache)) {
            return this._getCountsLoadingTracker
                .getLoading()
                .observable(this._restApiGlobalRecordingsService.getCounts(process).pipe(
                    map(result => {
                        this._getCountsCache = result;
                        return this._getCountsCache;
                    })
                ));
        } else {
            return of(this._getCountsCache);
        }
    }

    public getFirstVideoFrame(videoDataEntryId: number, process?: ProcessMonitorServiceProcess): Observable<VideoEntryModel> {
        return this._getFirstVideoFrameLoadingTracker
            .getLoading(videoDataEntryId)
            .observable(this._restApiGlobalRecordingsService.getFirstVideoFrame(videoDataEntryId, process));
    }

    public getVideoSchedules(showLocal: boolean, showOnDevice: boolean, showSyncing: boolean, paginationOptions?: PaginationOptionsModel, process?: ProcessMonitorServiceProcess): Observable<PageModel<VideoEntryModel>> {
        return this._getVideoSchedulesLoadingTracker
            .getLoading(showLocal, showOnDevice, paginationOptions)
            .observable(this._restApiGlobalRecordingsService.getVideoSchedules(showLocal, showOnDevice, showSyncing, paginationOptions, process));
    }

    public getVideoSessionStates(videoDataEntryIDs: Array<number>, process?: ProcessMonitorServiceProcess): Observable<Array<VideoStateModel>> {
        return this._getVideoSessionStatesLoadingTracker
            .getLoading(videoDataEntryIDs)
            .observable(this._restApiGlobalRecordingsService.getVideoSessionStates(videoDataEntryIDs, process));
    }

    public getVideoSessions(showLocal: boolean, showOnDevice: boolean, showSyncing: boolean, paginationOptions?: PaginationOptionsModel, process?: ProcessMonitorServiceProcess): Observable<PageModel<VideoEntryModel>> {
        return this._getVideoSessionsLoadingTracker
            .getLoading(showLocal, showOnDevice, paginationOptions)
            .observable(this._restApiGlobalRecordingsService.getVideoSessions(showLocal, showOnDevice, showSyncing, paginationOptions, process));
    }

    public pauseSyncVideoSession(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this._pauseSyncVideoSessionLoadingTracker
            .getLoading(item)
            .observable(this._restApiGlobalRecordingsService.pauseSyncVideoSession(item, process));
    }

    public resumeSyncVideoSession(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this._resumeSyncVideoSessionLoadingTracker
            .getLoading(item)
            .observable(this._restApiGlobalRecordingsService.resumeSyncVideoSession(item, process));
    }

    public syncVideoSession(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this._syncVideoSessionLoadingTracker
            .getLoading(item)
            .observable(this._restApiGlobalRecordingsService.syncVideoSession(item, process));
    }
}
