import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class EndPointTestResponseModel extends BaseModel implements IRestModel {
    public exceptions: Array<string> = null;
    public readonly isIRestModel = true;

    public retries: number = null;
    public success: boolean = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-EndPointTestResponseModel');

        this.exceptions = restModel.Exceptions;
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
