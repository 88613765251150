<div class="em-settings-addressbook-filter">
    <form [formGroup]="searchCriteriaForm">
        <div fxLayout="row"
            fxLayoutAlign="space-between center">
            <span class="and"
                *ngIf="!isFirst">And</span>
            <span class="and"
                *ngIf="isFirst">&nbsp;&nbsp;&nbsp;</span>
            <div class="edit-fields">
                <mat-form-field class="spacing-right"
                    style="width: 130px;">
                    <mat-select #by
                        formControlName="by"
                        placeholder="Filter On"
                        [disabled]="noEdit === true">
                        <mat-optgroup label="Device Settings">
                            <mat-option *ngFor="let filterOnEntry of byDeviceSettingOptions | orderBy: 'text'"
                                [value]="filterOnEntry.value">
                                {{ filterOnEntry.text }}
                            </mat-option>
                        </mat-optgroup>
                        <mat-optgroup label="Meta Data">
                            <mat-option *ngFor="let filterOnEntry of byMetaDataOptions | orderBy: 'text'"
                                [value]="filterOnEntry.value">
                                {{ filterOnEntry.text }}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                    <mat-error>{{getFormControlErrorMessage(searchCriteriaForm.controls['by'])}}</mat-error>
                    <span matSuffix>{{ searchCriteria.propertyHasChangesText('entryId') }}</span>
                </mat-form-field>
                <mat-form-field class="spacing-right"
                    style="width: 100px; min-width: 100px;">
                    <mat-select style="width: 100px; min-width: 100px;"
                        #operator
                        formControlName="operator"
                        placeholder="Where"
                        [disabled]="noEdit === true">
                        <mat-option [value]="ComparisonOperatorEnum.contains">
                            Contains
                        </mat-option>
                        <mat-option [value]="ComparisonOperatorEnum.startsWith">
                            Starts With
                        </mat-option>
                        <mat-option [value]="ComparisonOperatorEnum.endsWith">
                            Ends With
                        </mat-option>
                        <mat-option [value]="ComparisonOperatorEnum.isExactly">
                            Is Exactly
                        </mat-option>
                    </mat-select>
                    <mat-error>{{getFormControlErrorMessage(searchCriteriaForm.controls['operator'])}}</mat-error>
                    <span matSuffix>{{ searchCriteria.propertyHasChangesText('comparisonOperator') }}</span>
                </mat-form-field>
                <mat-form-field>
                    <input #value
                        formControlName="value"
                        matInput
                        placeholder="Value">
                    <mat-error>{{getFormControlErrorMessage(searchCriteriaForm.controls['value'])}}</mat-error>
                    <mat-hint align="end">{{value.value.length}} / {{getMaxLength()}}</mat-hint>
                    <span matSuffix>{{ searchCriteria.propertyHasChangesText('searchParameter') }}</span>
                </mat-form-field>
            </div>
            <button (click)="delete()"
                mat-icon-button
                [disabled]="noEdit === true">
                <mat-icon color="warn">delete</mat-icon>
            </button>
        </div>
    </form>
</div>