import { NgZone, Directive } from '@angular/core';
import { TimeLineDurationBar } from '@rift/components/shared/timeline/duration/TimeLine.Duration.Bar';
import { TimeLineDurationPoint } from '@rift/components/shared/timeline/duration/TimeLine.Duration.Point';
import { BookmarkModel } from '@rift/models/generic/Bookmark.Model';
import { TimeLineLoadQueueService } from '@rift/service/timeline/TimeLine.LoadQueueService';
import { ILocationChangingEventArgs, PlayStateEnum, ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { IDimensions } from '@shared/generic/IDimensions';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';
import { IPlayLocation } from '@rift/service/validation/IPlayLocation';
import { TimeZone } from '@shared/utility/DateTime.Utility';

@Directive()
export class TimeLineDurationView extends DisplayItem {
    public onGlobalBookmarkClick: Subject<BookmarkModel> = new Subject<BookmarkModel>();
    public onSessionBookmarkClick: Subject<BookmarkModel> = new Subject<BookmarkModel>();

    private _durationBar: TimeLineDurationBar;
    private _globalBookmarkPoints: Array<TimeLineDurationPoint> = null;
    private _height: number = 0;
    private _leftPoint: TimeLineDurationPoint = null;
    private _playPoint: TimeLineDurationPoint = null;
    private _playPointPressDownPlayState: PlayStateEnum = null;
    private _rightPoint: TimeLineDurationPoint = null;
    private _sessionBookmarkPoints: Array<TimeLineDurationPoint> = null;
    private _stickyZoom: boolean = false;
    private _width: number = 0;

    public constructor(
        protected readonly _playService: ValidationPlayService,
        protected readonly _loadQueue: TimeLineLoadQueueService,
        protected readonly _zone: NgZone) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            this._durationBar = new TimeLineDurationBar(this.container, this._playService, this._loadQueue, this._zone);
            this.container.addChild(this.bar.container);

            this._leftPoint = new TimeLineDurationPoint(this.container, this.bar, this._playService, this._zone);
            this.leftPoint.color = '#00ff00';
            this.leftPoint.colorDragging = '#ffff00';
            this.leftPoint.colorMouseOver = '#0000ff';
            this.container.addChild(this.leftPoint.container);

            this._rightPoint = new TimeLineDurationPoint(this.container, this.bar, this._playService, this._zone);
            this.rightPoint.color = '#00ff00';
            this.rightPoint.colorDragging = '#ffff00';
            this.rightPoint.colorMouseOver = '#0000ff';
            this.container.addChild(this.rightPoint.container);

            this._playPoint = new TimeLineDurationPoint(this.container, this.bar, this._playService, this._zone);
            this.playPoint.color = '#ff0000';
            this.playPoint.colorDragging = '#ffff00';
            this.playPoint.colorMouseOver = '#0000ff';
            this.container.addChild(this.playPoint.container);

            this.addEventHandlers();
        });
    }

    public get timeZone(): TimeZone {
        return this._durationBar.timeZone;
    }
    public set timeZone(value: TimeZone) {
        this._durationBar.timeZone = value;
        this._leftPoint.timeZone = value;
        this._rightPoint.timeZone = value;
        this._playPoint.timeZone = value;
    }

    public get showDurationAsTime(): boolean {
        return this._durationBar.showDurationAsTime;
    }
    public set showDurationAsTime(value: boolean) {
        this._durationBar.showDurationAsTime = value;
        this._leftPoint.showDurationAsTime = value;
        this._rightPoint.showDurationAsTime = value;
        this._playPoint.showDurationAsTime = value;
    }

    public get showDurationAsDeviceTimeZone(): boolean {
        return this._durationBar.showDurationAsDeviceTimeZone;
    }
    public set showDurationAsDeviceTimeZone(value: boolean) {
        this._durationBar.showDurationAsDeviceTimeZone = value;
        this._leftPoint.showDurationAsDeviceTimeZone = value;
        this._rightPoint.showDurationAsDeviceTimeZone = value;
        this._playPoint.showDurationAsDeviceTimeZone = value;
    }

    public get bar(): TimeLineDurationBar {
        return this._durationBar;
    }

    public bringToFront(displayItem?: IDisplayItem): void {

    }

    public set globalBookmarks(value: Array<BookmarkModel>) {
        this.clearBookmarkPoints(this._globalBookmarkPoints);
        this._globalBookmarkPoints = [];

        if (!isNullOrUndefined(value)) {
            const length = value.length;
            for (let i = 0; i < length; i++) {
                const bookmark = value[i];
                if (!isNullOrUndefined(bookmark)) {
                    const point = new TimeLineDurationPoint(this.container, this.bar, this._playService, this._zone, this.drawBookmarkHead.bind(this));
                    point.dragEnabled = false;
                    point.color = '#9d00ff';
                    point.colorDragging = '#ffff00';
                    point.colorMouseOver = '#c363ff';
                    point.bottomLabelVisible = false;
                    point.playLocation = this._playService.fillPlayLocation({ frameNumber: i });
                    this._globalBookmarkPoints.push(point);
                    this.container.addChildAt(point.container, 0);
                    this.addSubscription(point.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
                    this.addSubscription(point.onClick.subscribe(() => this.onGlobalBookmarkClick.next(bookmark)));
                    point.resize(this.width, this.height - this.bar.labelHeight);
                    point.update();
                }
            }
        }
    }

    public get height(): number {
        return this._height;
    }

    public get leftPoint(): TimeLineDurationPoint {
        return this._leftPoint;
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();
            this.playPoint.onDestroy();
            this.leftPoint.onDestroy();
            this.rightPoint.onDestroy();
            if (!isNullOrUndefined(this._globalBookmarkPoints)) {
                const length = this._globalBookmarkPoints.length;
                for (let i = 0; i < length; i++) {
                    this._globalBookmarkPoints[i].onDestroy();
                }
            }

            if (!isNullOrUndefined(this._sessionBookmarkPoints)) {
                const length = this._sessionBookmarkPoints.length;
                for (let i = 0; i < length; i++) {
                    this._sessionBookmarkPoints[i].onDestroy();
                }
            }
            this.bar.onDestroy();
            this._loadQueue.ngOnDestroy();
        });
    }

    public get playPoint(): TimeLineDurationPoint {
        return this._playPoint;
    }

    public resize(width: number, height: number): void {
        this._zone.runOutsideAngular(() => {
            this._width = width;
            this._height = height;

            this.bar.resize(this.width);
            this.leftPoint.resize(this.width, this.height - this.bar.labelHeight);
            this.playPoint.resize(this.width, this.height - this.bar.labelHeight - 12);
            this.rightPoint.resize(this.width, this.height - this.bar.labelHeight - 24);

            this.bookmarksResize(this.width, this.height);
        });
    }

    public get rightPoint(): TimeLineDurationPoint {
        return this._rightPoint;
    }

    public set sessionBookmarks(value: Array<BookmarkModel>) {
        this.clearBookmarkPoints(this._sessionBookmarkPoints);
        this._sessionBookmarkPoints = [];

        if (!isNullOrUndefined(value)) {
            const length = value.length;
            for (let i = 0; i < length; i++) {
                const bookmark = value[i];
                if (!isNullOrUndefined(bookmark)) {
                    const point = new TimeLineDurationPoint(this.container, this.bar, this._playService, this._zone, this.drawBookmarkHead.bind(this));
                    point.dragEnabled = false;
                    point.color = '#42b6f4';
                    point.colorDragging = '#ffff00';
                    point.colorMouseOver = '#41d6f4';
                    point.bottomLabelVisible = false;
                    point.playLocation = this._playService.fillPlayLocation({ frameNumber: i });
                    this._sessionBookmarkPoints.push(point);
                    this.container.addChildAt(point.container, 0);
                    this.addSubscription(point.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
                    this.addSubscription(point.onClick.subscribe(() => this.onSessionBookmarkClick.next(bookmark)));
                    point.resize(this.width, this.height - this.bar.labelHeight);
                    point.update();
                }
            }
        }
    }

    public get stickyZoom(): boolean {
        return this._stickyZoom;
    }

    public set stickyZoom(value: boolean) {
        this._zone.runOutsideAngular(() => {
            this._stickyZoom = value;
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
        });
    }

    public get width(): number {
        return this._width;
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this.addSubscription(this._playService.onViewUpdated.subscribe(() => this.onPlayServiceViewUpdated()));
            this.addSubscription(this._playService.onLocationChanging.subscribe(event => this.onPlayLocationChanging(event)));
            this.addSubscription(this._playService.onLocationChanged.subscribe(event => this.onPlayLocationChanged(event)));
            this.addSubscription(this._playService.onStart.subscribe(() => this.onPlayServiceStart()));

            this.addSubscription(this.playPoint.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
            this.addSubscription(this.playPoint.onPressUp.subscribe(() => this.onPlayPointPressUp()));
            this.addSubscription(this.playPoint.onPressDown.subscribe(() => this.onPlayPointPressDown()));
            this.addSubscription(this.playPoint.onBeforeDrag.subscribe(event => this.onPlayPointBeforeDrag(event)));

            this.addSubscription(this.leftPoint.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
            this.addSubscription(this.leftPoint.onBeforeDrag.subscribe(event => this.onLeftPointBeforeDrag(event)));

            this.addSubscription(this.rightPoint.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
            this.addSubscription(this.rightPoint.onBeforeDrag.subscribe(event => this.onRightPointBeforeDrag(event)));

            this.addSubscription(this.bar.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
        });
    }

    private bookmarksResize(width: number, height: number): void {
        if (!isNullOrUndefined(this._globalBookmarkPoints)) {
            const length = this._globalBookmarkPoints.length;
            for (let i = 0; i < length; i++) {
                this._globalBookmarkPoints[i].resize(this.width, this.height - this.bar.labelHeight);
            }
        }

        if (!isNullOrUndefined(this._sessionBookmarkPoints)) {
            const length = this._sessionBookmarkPoints.length;
            for (let i = 0; i < length; i++) {
                this._sessionBookmarkPoints[i].resize(this.width, this.height - this.bar.labelHeight);
            }
        }
    }

    private clearBookmarkPoints(points: Array<TimeLineDurationPoint>): void {
        if (!isNullOrUndefined(points)) {
            const length = points.length;
            for (let i = 0; i < length; i++) {
                const point = points[i];
                this.container.removeChild(point.container);
                point.onDestroy();
            }
        }
    }

    private drawBookmarkHead(graphics: createjs.Graphics, point: TimeLineDurationPoint): IDimensions {
        const dimensions: IDimensions = { width: 16, height: 15 };
        const color = point.isDragging ? point.colorDragging : point.isMouseOver ? point.colorMouseOver : point.color;

        graphics.clear();
        graphics.setStrokeStyle(1);
        graphics.beginStroke(color);
        graphics.beginFill(color);
        graphics.moveTo(0, 0);
        graphics.lineTo(dimensions.width, 0);
        graphics.lineTo(dimensions.width, dimensions.height);
        graphics.lineTo(dimensions.width / 2, dimensions.height - 5);
        graphics.lineTo(0, dimensions.height);
        graphics.endFill();
        graphics.endStroke();

        return dimensions;
    }

    private onLeftPointBeforeDrag(event: DisplayItemMouseEvent): void {
        return this._zone.runOutsideAngular(() => {
            if (event.moveTo.x > this.rightPoint.container.x) {
                event.moveTo.x = this._playService.playLocationToPixel({ offset: this._rightPoint.playLocation.offset - this._playService.minViewDuration }, this.bar.fullSegmentWidth);
                return;
            }
            if (event.moveTo.x < 0) {
                event.moveTo.x = 0;
                return;
            }
            if (event.moveTo.x > this.bar.fullSegmentWidth) {
                event.moveTo.x = this.bar.fullSegmentWidth;
                return;
            }
        });
    }

    private onPlayLocationChanged(playLocation: IPlayLocation): void {
        this._zone.runOutsideAngular(() => {
            if (this.playPoint.isDragging === false) {
                this.setPlayPointLocation(playLocation);
            }
        });
    }

    private onPlayLocationChanging(event: ILocationChangingEventArgs): void {
        if (this.playPoint.isDragging === false && this.stickyZoom === true && this._playService.viewDuration !== this._playService.duration && this.playPoint.container.x >= (this.bar.fullSegmentWidth / 2)) {
            const offset = (event.new.offset - event.current.offset);
            const newViewStartOffset = this._playService.viewStartLocation.offset + offset;
            const newViewEndOffset = this._playService.viewEndLocation.offset + offset;

            this._playService.setViewRange({ offset: newViewStartOffset }, { offset: newViewEndOffset });
        }
    }

    private onPlayPointBeforeDrag(event: DisplayItemMouseEvent): void {
        return this._zone.runOutsideAngular(() => {
            if (event.moveTo.x < 0) {
                event.moveTo.x = 0;
                return;
            }
            if (event.moveTo.x > this.bar.fullSegmentWidth) {
                event.moveTo.x = this.bar.fullSegmentWidth;
                return;
            }
        });
    }

    private onPlayPointPressDown(): void {
        this._zone.runOutsideAngular(() => {
            this._playPointPressDownPlayState = this._playService.playState;
        });
    }

    private onPlayPointPressUp(): void {
        this._zone.runOutsideAngular(() => {
            this._playService.currentLocation = this.playPoint.playLocation;
        });
    }

    private onPlayServiceStart(): void {
        this._zone.runOutsideAngular(() => {
            this.setViewPointsLocation();
            this.setPlayPointLocation(this._playService.fillPlayLocation({ offset: 0 }));
        });
    }

    private onPlayServiceViewUpdated(): void {
        this._zone.runOutsideAngular(() => {
            this.setViewPointsLocation();
            this.setPlayPointLocation(this._playService.currentLocation);
        });
    }

    private onRightPointBeforeDrag(event: DisplayItemMouseEvent): void {
        return this._zone.runOutsideAngular(() => {
            if (event.moveTo.x < this._leftPoint.container.x) {
                event.moveTo.x = this._playService.playLocationToPixel({ offset: this._leftPoint.playLocation.offset + this._playService.minViewDuration }, this.bar.fullSegmentWidth);
                return;
            }
            if (event.moveTo.x < 0) {
                event.moveTo.x = 0;
                return;
            }
            if (event.moveTo.x > this.bar.fullSegmentWidth) {
                event.moveTo.x = this.bar.fullSegmentWidth;
                return;
            }
        });
    }

    private removeEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
        });
    }

    private setPlayPointLocation(location: IPlayLocation): void {
        this._zone.runOutsideAngular(() => {
            if (location.offset < this._playService.viewStartLocation.offset || location.offset > this._playService.viewEndLocation.offset) {
                this.playPoint.container.visible = false;
            } else {
                this.playPoint.container.visible = true;
            }
            this.playPoint.playLocation = location;
            this.requireStageUpdate.next();
        });
    }

    private setViewPointsLocation(): void {
        this._zone.runOutsideAngular(() => {
            this.leftPoint.playLocation = this._playService.viewStartLocation;
            this.rightPoint.playLocation = this._playService.viewEndLocation;

            if (!isNullOrUndefined(this._globalBookmarkPoints)) {
                const length = this._globalBookmarkPoints.length;
                for (let i = 0; i < length; i++) {
                    const bookmark = this._globalBookmarkPoints[i];
                    bookmark.playLocation = bookmark.prePlayLocation;
                }
            }

            if (!isNullOrUndefined(this._sessionBookmarkPoints)) {
                const length = this._sessionBookmarkPoints.length;
                for (let i = 0; i < length; i++) {
                    const bookmark = this._sessionBookmarkPoints[i];
                    bookmark.playLocation = bookmark.prePlayLocation;
                }
            }

            this.bar.update();
            this.requireStageUpdate.next();
        });
    }
}
