<mat-accordion>
    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasSettingsCountMode === true">
        <rift-settings-counting-count-mode #countMode [register]="register" [registers]="registers" [lines]="lines"
            [polygons]="polygons" [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-count-mode>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="countMode.isValid === false">
                Count Mode
                <span>{{ isNullOrUndefined(countMode) ? '' : countMode.hasChanges === true ? '*' : '' }}</span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ countMode.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <ng-template ngFor let-config [ngForOf]="registerTypeViewModel?.associatedRegisterConfigs" let-configI="index">
        <mat-expansion-panel [expanded]="configI === 0 ? true : false" *ngIf="!isNullOrUndefined(config)">
            <rift-settings-counting-associate-registers #associate (selectedChange)="onAssociateSelectedChange($event)"
                [config]="config" [register]="register" [registers]="registers" [lines]="lines" [polygons]="polygons"
                [registerTypeViewModel]="registerTypeViewModel">
            </rift-settings-counting-associate-registers>
            <mat-expansion-panel-header>
                <mat-panel-title [class.error]="associate.isValid === false">
                    {{ isNullOrUndefined(config.title) ? 'Associated Registers' : config.title }}
                    <span>
                        {{ isNullOrUndefined(associate) ? '' : associate.hasChanges === true ? '*' : '' }}
                    </span>
                </mat-panel-title>
                <mat-panel-description>
                    <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                        {{ associate.getSettingsDescription() }}
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>
        </mat-expansion-panel>
    </ng-template>

    <ng-template ngFor let-property [ngForOf]="registerTypeViewModel?.simpleProperties">
        <mat-expansion-panel
            *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasSettingsSimpleProperties === true">
            <rift-settings-counting-simple-property #simpleProperty [register]="register" [registers]="registers"
                [lines]="lines" [polygons]="polygons" [registerTypeViewModel]="registerTypeViewModel"
                [property]="property">
            </rift-settings-counting-simple-property>
            <mat-expansion-panel-header>
                <mat-panel-title [class.error]="simpleProperty.isValid === false">
                    {{property.name}}
                    <span>{{ isNullOrUndefined(simpleProperty) ? '' : simpleProperty.hasChanges === true ? '*' : '' }}</span>
                </mat-panel-title>
                <mat-panel-description>
                    <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                        {{ simpleProperty.getSettingsDescription() }}
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>
        </mat-expansion-panel>
    </ng-template>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasMaxDwell === true && (isNullOrUndefined(registerTypeViewModel.registerTypeConfig.maxDwellAllowed) || registerTypeViewModel.registerTypeConfig.maxDwellAllowed(register) === true)">
        <rift-settings-counting-max-dwell #maxDwell [register]="register" [registers]="registers"
            [lines]="lines" [polygons]="polygons" [registerTypeViewModel]="registerTypeViewModel" [minDwellDuration]="register['minDwellDuration']">
        </rift-settings-counting-max-dwell>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="maxDwell.isValid === false">
                Max Dwell Duration
                <span>
                    {{ isNullOrUndefined(maxDwell) ? '' : maxDwell.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ maxDwell.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
        
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasExclusionType === true">
        <rift-settings-counting-exclusion-type #exclusionType [register]="register" [registers]="registers"
            [lines]="lines" [polygons]="polygons" [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-exclusion-type>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="exclusionType.isValid === false">
                Exclusion Type
                <span>
                    {{ isNullOrUndefined(exclusionType) ? '' : exclusionType.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ exclusionType.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasSettingsHeightFiltering === true">
        <rift-settings-counting-height-filtering #heightFiltering [register]="register" [registers]="registers"
            [lines]="lines" [polygons]="polygons" [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-height-filtering>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="heightFiltering.isValid === false">
                Height Filtering
                <span>
                    {{ isNullOrUndefined(heightFiltering) ? '' : heightFiltering.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ heightFiltering.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasSettingsStaffDetection === true">
        <rift-settings-counting-staff-detect #staffDetection [register]="register" [registers]="registers"
            [lines]="lines" [polygons]="polygons" [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-staff-detect>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="staffDetection.isValid === false">
                Staff Detection
                <span>
                    {{ isNullOrUndefined(staffDetection) ? '' : staffDetection.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ staffDetection.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasSettingsZone === true">
        <rift-settings-counting-zone #zone [register]="register" [registers]="registers" [lines]="lines"
            [polygons]="polygons" [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-zone>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="zone.isValid === false">
                Zone
                <span>
                    {{ isNullOrUndefined(zone) ? '' : zone.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ zone.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasTags === true">
        <rift-settings-counting-tags #tags [register]="register" [registers]="registers" [lines]="lines"
            [polygons]="polygons" [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-tags>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="tags.isValid === false">
                Tags
                <span>
                    {{ isNullOrUndefined(tags) ? '' : tags.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ tags.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasSettingsHeightHistogram === true">
        <rift-settings-counting-height-histogram #heightHistogram [register]="register" [registers]="registers"
            [lines]="lines" [polygons]="polygons" [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-height-histogram>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="heightHistogram.isValid === false">
                Height Histogram
                <span>
                    {{ isNullOrUndefined(heightHistogram) ? '' : heightHistogram.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ heightHistogram.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasSettingsDwellHistogram === true && (isNullOrUndefined(registerTypeViewModel.registerTypeConfig.dwellHistogramAllowed) || registerTypeViewModel.registerTypeConfig.dwellHistogramAllowed(register) === true)">
        <rift-settings-counting-dwell-histogram #dwellHistogram [register]="register" [registers]="registers"
            [lines]="lines" [polygons]="polygons" [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-dwell-histogram>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="dwellHistogram.isValid === false">
                Total Wait Time Histogram
                <span>
                    {{ isNullOrUndefined(dwellHistogram) ? '' : dwellHistogram.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ dwellHistogram.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
        
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasSettingsUnattendedTimeDwellHistogram === true">
        <rift-settings-counting-unattended-time-histogram #unattendedTimeHistogram [register]="register"
            [registers]="registers" [lines]="lines" [polygons]="polygons"
            [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-unattended-time-histogram>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="unattendedTimeHistogram.isValid === false">
                Total Unattended Time Histogram
                <span>
                    {{ isNullOrUndefined(unattendedTimeHistogram) ? '' : unattendedTimeHistogram.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ unattendedTimeHistogram.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasSettingsInstantDwellHistogram === true">
        <rift-settings-counting-instant-dwell-histogram #instantDwellHistogram [register]="register"
            [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-instant-dwell-histogram>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="instantDwellHistogram.isValid === false">
                Current Wait Time Histogram
                <span>
                    {{ isNullOrUndefined(instantDwellHistogram) ? '' : instantDwellHistogram.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ instantDwellHistogram.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.isWirelessPIR === true">
        <rift-settings-counting-wireless-pir-devices #wirelessPIR [register]="register"></rift-settings-counting-wireless-pir-devices>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="wirelessPIR.isValid === false">
                Fine Motion Room Sensor PIR devices
                <span>
                    {{ isNullOrUndefined(wirelessPIR) ? '' : wirelessPIR.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ wirelessPIR.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(availableCustomActions) && !isNullOrUndefined(availableCustomActions.items) && availableCustomActions.items.length > 0 && !isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.hasCustomAction === true">
        <rift-settings-counting-custom-action #customAction [availableActions]="availableCustomActions" [register]="register"
            [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-custom-action>
        <mat-expansion-panel-header>
            <mat-panel-title [class.error]="customAction.isValid === false">
                Custom Action
                <span>
                    {{ isNullOrUndefined(customAction) ? '' : customAction.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ customAction.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.isRemote === true">
        <rift-settings-counting-remote #remote [register]="register"
            [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-remote>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Remote Devices And Registers (Value)
                <span>
                    {{ isNullOrUndefined(remote) ? '' : remote.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ remote.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel
        *ngIf="!isNullOrUndefined(registerTypeViewModel) && registerTypeViewModel.isRemotePush === true">
        <rift-settings-counting-remote-push #remotePush [register]="register"
            [registerTypeViewModel]="registerTypeViewModel">
        </rift-settings-counting-remote-push>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Remote Devices And Registers (Push)
                <span>
                    {{ isNullOrUndefined(remotePush) ? '' : remotePush.hasChanges === true ? '*' : '' }}
                </span>
            </mat-panel-title>
            <mat-panel-description>
                <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                    {{ remotePush.getSettingsDescription() }}
                </div>
            </mat-panel-description>
        </mat-expansion-panel-header>
    </mat-expansion-panel>
</mat-accordion>
