import { BaseModel } from '@shared/base/Base.Model';
import { TargetStatusEnum, TargetStatusEnumHelpers } from '@shared/enum/TargetStatus.Enum';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface ITargetModel {
    id: number;
    status: Array<TargetStatusEnum>;
    x: number;
    y: number;
    height: number;
}

export class TargetModel extends BaseModel implements ITargetModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'target_case';

    public readonly isIWebSocketModel: boolean = true;
    public packetType: string = null;
    public id: number = null;

    public status: Array<TargetStatusEnum> = null;
    public x: number = null;
    public y: number = null;
    public height: number = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-TargetModel', ['status']);
        this.status = TargetStatusEnumHelpers.getSelected(message.status);
    }

    public toWebSocketMessage(): void {
        throw new Error('Method not implemented.');
    }
}
