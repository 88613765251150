import { IFrameStore } from '@rift/service/validation/database/syncrecording/IFrameStore';
import { IObjectStore } from '@shared/service/Indexeddb/IObjectStore';

export interface IDatabaseSyncFramesStore extends IFrameStore {
}

export class DatabaseSyncFramesStore implements IObjectStore {
    public static readonly keyPath: string = 'id';
    public static readonly recordingIdBlockIndex: string = 'RecordingIdBlockIndex';
    public static readonly recordingIdFrameNumberIndex: string = 'RecordingIdFrameNumberIndex';
    public static readonly recordingIdIndex: string = 'RecordingIdIndex';
    public static readonly recordingIdTimestamp: string = 'RecordingIdTimestamp';
    public static readonly storeDisplayName: string = 'SyncFrames';
    public static readonly storeName: string = 'syncFrames';

    public get storeName(): string {
        return DatabaseSyncFramesStore.storeName;
    };

    public static toInterface(): IDatabaseSyncFramesStore {
        return {
            storeName: this.storeName,
            keyPath: this.keyPath,
            recordingIdFrameNumberIndex: this.recordingIdFrameNumberIndex,
            recordingIdIndex: this.recordingIdIndex,
            recordingIdBlockIndex: this.recordingIdBlockIndex,
        } as IDatabaseSyncFramesStore;
    }

    public createStores(db: IDBDatabase): void {
        const store: IDBObjectStore = db.createObjectStore(DatabaseSyncFramesStore.storeName, { keyPath: DatabaseSyncFramesStore.keyPath, autoIncrement: true });
        store.createIndex(DatabaseSyncFramesStore.recordingIdFrameNumberIndex, ['recordingId', 'frameNumber'], { unique: true });
        store.createIndex(DatabaseSyncFramesStore.recordingIdBlockIndex, ['recordingId', 'block']);
        store.createIndex(DatabaseSyncFramesStore.recordingIdIndex, 'recordingId');
    }
}
