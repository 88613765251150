import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subscription } from 'rxjs';
import { Directive, OnDestroy, OnInit } from '@angular/core';

/**
 * A safe subscription.
 *
 * @export
 * @class SafeSub
 */
export class SafeSub {
    public process: ProcessMonitorServiceProcess;
    public subscription: Subscription;

    public constructor(
        subscription: Subscription,
        process?: ProcessMonitorServiceProcess
    ) {
        this.subscription = subscription;
        this.process = process;
    }
}

/**
 * Base class to handel clean up of subscriptions.
 *
 * @export
 * @abstract
 * @class SafeSubscriptionBase
 */
@Directive()
export abstract class SafeSubscriptionBase implements OnDestroy, OnInit {
    private _subscriptions: Array<SafeSub> = [];
    private _children: Array<SafeSubscriptionBase> = [];

    /**
     * Creates an instance of SafeSubscriptionBase.
     *
     * @memberof SafeSubscriptionBase
     */
    public constructor() {
    }

    /**
     * Adds a SafeSubscriptionBase to this instance.
     *
     * @param {SafeSubscriptionBase} child The child SafeSubscriptionBase to be added.
     * @memberof SafeSubscriptionBase
     */
    public addChildSubscriber(child: SafeSubscriptionBase): void {
        this._children.push(child);
    }

    /**
     * Adds a subscription to the tracker for cleanup on destroy.
     *
     * @param {Subscription} subscription The subscription to be tracked.
     * @param {ProcessMonitorServiceProcess} [process] The process associated with the subscription.
     * @returns {Subscription} The subscription being added.
     * @memberof SafeSubscriptionBase
     */
    public addSubscription(subscription: Subscription, process?: ProcessMonitorServiceProcess): Subscription {
        this._subscriptions.push(new SafeSub(subscription, process));
        return subscription;
    }

    /**
     * Unsubscribes all subscriptions and child SafeSubscriptionBase subscriptions.
     *
     * @memberof SafeSubscriptionBase
     */
    public unsubscribeAll(): void {
        const subscriptionsLength = this._subscriptions.length;
        for (let index = 0; index < subscriptionsLength; index++) {
            const safeSub = this._subscriptions[index];
            if (!isNullOrUndefined(safeSub)) {
                if (!safeSub.subscription.closed) {
                    if (!isNullOrUndefined(safeSub.subscription)) {
                        safeSub.subscription.unsubscribe();
                    }
                }
                if (!isNullOrUndefined(safeSub.process)) {
                    safeSub.process.terminate();
                }
            }
        }
        const childrenLength = this._children.length;
        for (let index = 0; index < childrenLength; index++) {
            this._children[index].unsubscribeAll();
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll();
    }

    public ngOnInit(): void { }
}
