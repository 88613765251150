export enum DirectionTypeEnum {
    UNSET = 0,
    IN = 1,
    OUT = 2,
}

export class DirectionTypeEnumHelpers {
    public static toFullLongName(value: DirectionTypeEnum): string {
        switch (value) {
            case DirectionTypeEnum.UNSET:
                return 'UNSET';
            case DirectionTypeEnum.IN:
                return 'IN';
            case DirectionTypeEnum.OUT:
                return 'OUT';
        }
    }
}
