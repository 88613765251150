import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Injector, Input, NgZone, OnDestroy, Output, ViewChild, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { TimeLineDragZone } from '@rift/components/shared/timeline/dragzone/TimeLine.DragZone';
import { TimeLineDurationView } from '@rift/components/shared/timeline/duration/TimeLine.Duration.View';
import { TimeLineRow } from '@rift/components/shared/timeline/rows/TimeLine.Row';
import { TimeLineRows } from '@rift/components/shared/timeline/rows/TimeLine.Rows';
import { TimeLineRowsSettings } from '@rift/components/shared/timeline/rows/TimeLine.Rows.Settings';
import { BookmarkModel } from '@rift/models/generic/Bookmark.Model';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { PolygonModel } from '@rift/models/restapi/Polygon.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { TimeLineLoadQueueService } from '@rift/service/timeline/TimeLine.LoadQueueService';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { ISpacing } from '@shared/generic/ISpacing';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';
import { TimeZone } from '@shared/utility/DateTime.Utility';
import { GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';

@Component({
    selector: 'rift-timeline',
    templateUrl: './TimeLine.Component.html',
    styleUrls: ['./TimeLine.Component.scss'],
})
export class TimeLineComponent extends RiftBaseComponent implements AfterViewInit, OnDestroy {
    public static className: string = 'ViewPortComponent';

    @HostBinding()
    public id: string = 'rift-timeline';

    @Output()
    public globalBookmarkClick: EventEmitter<BookmarkModel> = new EventEmitter<BookmarkModel>();
    @Output()
    public sessionBookmarkClick: EventEmitter<BookmarkModel> = new EventEmitter<BookmarkModel>();
    @ViewChild('canvas', { static: true })
    public canvas: ElementRef<HTMLCanvasElement>;

    public onRequireStageUpdate: Subject<boolean> = new Subject<boolean>();

    private _background: createjs.Shape = new createjs.Shape();
    private _dragZone: TimeLineDragZone = null;
    private _durationView: TimeLineDurationView = null;
    private _fillBottom: createjs.Shape = new createjs.Shape();
    private _fillTop: createjs.Shape = new createjs.Shape();
    private _height: number = 0;
    private _padding: ISpacing = { top: 10, bottom: 10, left: 10, right: 10 };
    private _rows: TimeLineRows = null;
    private _spacing: number = 5;
    private _stage: createjs.StageGL = null;
    private _stageUpdateRequired: boolean = false;
    private _tickerListener: Function;
    private _unitOfMeasurement: UnitsOfMeasurementEnum = UnitsOfMeasurementEnum.metric;
    private _width: number = 0;
    private _lastMouseX: number = 0;

    public constructor(
        public readonly playService: ValidationPlayService,
        private readonly _renderBase: Renderer2,
        protected readonly _loadQueue: TimeLineLoadQueueService,
        protected readonly _dialog: MatDialog,
        protected readonly _zone: NgZone,
        protected readonly _injector: Injector) {
        super(_injector, _dialog);

        this._zone.runOutsideAngular(() => {
            this._durationView = new TimeLineDurationView(this.playService, this._loadQueue, this._zone);
            this._rows = new TimeLineRows(this._durationView, this.playService, this._zone);
            this._dragZone = new TimeLineDragZone(this._durationView, this._rows, this.playService, this._zone);
        });
    }

    public get timeZone(): TimeZone {
        return this._durationView.timeZone;
    }
    public set timeZone(value: TimeZone) {
        this._durationView.timeZone = value;
    }

    public get showDurationAsTime(): boolean {
        return this._durationView.showDurationAsTime;
    }
    public set showDurationAsTime(value: boolean) {
        this._durationView.showDurationAsTime = value;
    }

    public get showDurationAsDeviceTimeZone(): boolean {
        return this._durationView.showDurationAsDeviceTimeZone;
    }
    public set showDurationAsDeviceTimeZone(value: boolean) {
        this._durationView.showDurationAsDeviceTimeZone = value;
    }

    public clear(): void {
        this._zone.runOutsideAngular(() => {
            this.rows.clear();
        });
    }

    public addRegister(register: RegisterBaseModel, registers: Array<RegisterBaseModel>, lines?: Array<LineModel>, polygons?: Array<PolygonModel>): void {
        this._zone.runOutsideAngular(() => {
            const row = new TimeLineRow(this._durationView, register, this.playService, this._zone);
            if (!register.isLineHost) {
                this.addSubRegistersToRow(row, register, registers);
            }
            this.rows.add(row);
        });
    }

    public get dragZone(): TimeLineDragZone {
        return this._dragZone;
    }

    public get durationView(): TimeLineDurationView {
        return this._durationView;
    }

    public fillElement(element: ElementRef | HTMLElement): void {
        this._zone.runOutsideAngular(() => {
            const nativeElement = (element instanceof ElementRef ? element.nativeElement as HTMLElement : element);
            const computedStyle = getComputedStyle(nativeElement);

            const paddingX = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
            const paddingY = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);

            this.width = nativeElement.offsetWidth - paddingX;
            this.height = nativeElement.offsetHeight - paddingY;

            this.setCanvasWidthHeight();
        });
    }

    public getRegisterRows(registerIndex: number): Array<TimeLineRow> {
        return this._zone.runOutsideAngular(() => {
            const rows: TimeLineRow[] = [];

            const getRows = (timeLineRows: TimeLineRows): void => {
                const length = timeLineRows.items.length;
                for (let i = 0; i < length; i++) {
                    const row = timeLineRows.items[i];
                    if (!isNullOrUndefined(row)) {
                        if (row.registerIndex === registerIndex) {
                            rows.push(row);
                        }

                        if (row.rows.length > 0) {
                            getRows(row.rows);
                        }
                    }
                }
            };

            getRows(this.rows);

            return rows;
        });
    }

    public get globalBookmarks(): Array<BookmarkModel> {
        return this._zone.runOutsideAngular(() => this.durationView.globalBookmarks);
    }

    @Input()
    public set globalBookmarks(value: Array<BookmarkModel>) {
        this._zone.runOutsideAngular(() => {
            this.durationView.globalBookmarks = value;
        });
    }
    public get height(): number {
        return this._height;
    }

    @Input()
    public set height(value: number) {
        this._zone.runOutsideAngular(() => {
            if (this._height !== value) {
                this._height = value;
                this.setCanvasWidthHeight();
            }
        });
    }

    public ngAfterViewInit(): void {
        this._zone.runOutsideAngular(() => {
            this._stage = new createjs.StageGL(this.canvas.nativeElement, { antialias: true });
            this._stage.enableMouseOver(20);
            createjs.Touch.enable(this._stage);

            this._stage.addChild(this._background);
            this._stage.addChild(this.rows.container);
            this._stage.addChild(this._fillBottom);
            this._stage.addChild(this._fillTop);
            this._stage.addChild(this.durationView.container);
            this._stage.addChild(this.dragZone.container);

            this.addEventHandlers();

            this.setCanvasWidthHeight();
        });
    }

    public ngOnDestroy(): void {
        this._zone.runOutsideAngular(() => {
            this.removeEventHandlers();
            this.durationView.onDestroy();
            this.rows.onDestroy();
            this.dragZone?.onDestroy();
            this._stage.tickEnabled = false;
            this._stage.enableMouseOver(0);
            createjs.Touch.disable(this._stage);
            this._stage.enableDOMEvents(false);
            this._stage.removeAllChildren();
            this._stage.uncache();
            this._stage.purgeTextures();
            this._stage = null;
            this._loadQueue.ngOnDestroy();
        });
        super.ngOnDestroy();
    }

    public requireStageUpdate(): void {
        this._zone.runOutsideAngular(() => {
            this._stageUpdateRequired = true;
            this.onRequireStageUpdate.next();
        });
    }

    public get rows(): TimeLineRows {
        return this._rows;
    }

    public get sessionBookmarks(): Array<BookmarkModel> {
        return this._zone.runOutsideAngular(() => this.durationView.sessionBookmarks);
    }

    @Input()
    public set sessionBookmarks(value: Array<BookmarkModel>) {
        this._zone.runOutsideAngular(() => {
            this.durationView.sessionBookmarks = value;
        });
    }
    public get stickyZoom(): boolean {
        return this.durationView.stickyZoom;
    }

    @Input()
    public set stickyZoom(value: boolean) {
        this._zone.runOutsideAngular(() => {
            this.durationView.stickyZoom = value;
        });
    }
    public get unitOfMeasurement(): UnitsOfMeasurementEnum {
        return this._unitOfMeasurement;
    }

    @Input()
    public set unitOfMeasurement(value: UnitsOfMeasurementEnum) {
        this._zone.runOutsideAngular(() => {
            if (this._unitOfMeasurement !== value) {
                this._unitOfMeasurement = value;
            }
        });
    }
    public get width(): number {
        return this._width;
    }

    @Input()
    public set width(value: number) {
        this._zone.runOutsideAngular(() => {
            if (this._width !== value) {
                this._width = value;
                this.setCanvasWidthHeight();
            }
        });
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this._tickerListener = createjs.Ticker.on('tick', this.onTick.bind(this));

            (this._stage.canvas as HTMLCanvasElement).addEventListener('mousewheel', this.stageMouseWheel.bind(this));
            (this._stage.canvas as HTMLCanvasElement).addEventListener('DOMMouseScroll', this.stageMouseWheel.bind(this));

            this.addSubscription(this.dragZone.requireStageUpdate.subscribe(() => this.requireStageUpdate()));

            this.addSubscription(this.durationView.requireStageUpdate.subscribe(() => this.requireStageUpdate()));
            this.addSubscription(this.durationView.onGlobalBookmarkClick.subscribe(e => this._zone.run(() => this.globalBookmarkClick.emit(e))));
            this.addSubscription(this.durationView.onSessionBookmarkClick.subscribe(e => this._zone.run(() => this.sessionBookmarkClick.emit(e))));

            this.addSubscription(this.rows.requireStageUpdate.subscribe(() => this.requireStageUpdate()));
            this.addSubscription(this.rows.onRowBodyMouseDown.subscribe(event => this.onRowBodyMouseDown(event)));
            this.addSubscription(this.rows.onRowBodyDrag.subscribe(event => this.rowBodyDrag(event)));
            this.addSubscription(this.rows.onRowBodyPressUp.subscribe(event => this.rowBodyPressUp(event)));
            this.addSubscription(this.rows.onExpand.subscribe(event => this.rowExpand(event)));
            this.addSubscription(this.rows.onCollapse.subscribe(event => this.rowCollapse(event)));
        });
    }

    private rowCollapse(event: TimeLineRow): void {
        this.rows.setOffsets();
    }

    private rowExpand(event: TimeLineRow): void {
        this.rows.setOffsets();
    }

    private addSubRegistersToRow(row: TimeLineRow, register: RegisterBaseModel, registers: Array<RegisterBaseModel>): void {
        this._zone.runOutsideAngular(() => {
            if (register.registerIds.length > 0 || register.lineIds.length > 0 || register.polygonIds.length > 0) {
                const associatedRegisters = RegisterBaseUtility.getAssociatedRegisters(register, registers);
                const length = associatedRegisters.length;
                for (let i = 0; i < length; i++) {
                    const associatedRegister = associatedRegisters[i];
                    const subRow = new TimeLineRow(this._durationView, associatedRegister, this.playService, this._zone);
                    subRow.select.enabled = false;
                    if (!associatedRegister.isLineHost) {
                        this.addSubRegistersToRow(subRow, associatedRegister, registers);
                    }
                    row.rows.add(subRow);
                }
            }
        });
    }

    private onTick(event: createjs.TickerEvent): void {
        this._zone.runOutsideAngular(() => {
            const mouseX = this._stage.mouseX;
            if (this._lastMouseX !== mouseX) {
                this.rows.mouseMove(this._stage.mouseX);
                this._lastMouseX = mouseX;
            }

            if (this._stageUpdateRequired === true) {
                this._stage.update();
                this._stageUpdateRequired = false;
            }
        });
    }

    private removeEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            createjs.Ticker.off('tick', this._tickerListener);

            (this._stage.canvas as HTMLCanvasElement).removeEventListener('mousewheel', this.stageMouseWheel.bind(this));
            (this._stage.canvas as HTMLCanvasElement).removeEventListener('DOMMouseScroll', this.stageMouseWheel.bind(this));
        });
    }

    private resize(width: number, height: number): void {
        this._zone.runOutsideAngular(() => {
            const availableWidth: number = width - (this._padding.left + this._padding.right);
            const availableHeight: number = height - (this._padding.top + this._padding.bottom);

            this.rows.resize(availableWidth);

            let rowsHeaderWidth: number = 0;
            let rowsSelectWidth: number = 0;
            if (this.rows.headerVisible) {
                rowsHeaderWidth += TimeLineRowsSettings.header.width + TimeLineRowsSettings.header.spacing;
            }

            if (this.rows.selectVisible) {
                rowsSelectWidth += TimeLineRowsSettings.select.width + TimeLineRowsSettings.select.spacing;
            }

            this.durationView.resize(availableWidth - (rowsHeaderWidth + rowsSelectWidth), availableHeight);
            this.dragZone.resize(availableWidth - (rowsHeaderWidth + rowsSelectWidth), availableHeight);

            this.durationView.container.x = this._padding.left + rowsHeaderWidth;
            this.durationView.container.y = this._padding.top;

            this.dragZone.container.x = this._padding.left + rowsHeaderWidth;
            this.dragZone.container.y = this._padding.top + this.durationView.bar.height + this._spacing;

            this.rows.container.x = this._padding.left;
            this.rows.container.y = this._padding.top + this.durationView.bar.height + this._spacing;

            this._background.graphics.clear();
            this._background.graphics.beginFill('#ffffff');
            this._background.graphics.drawRect(0, 0, width, height);
            this._background.cache(0, 0, width, height, GLOBAL_CACHE_SCALE);

            this._fillTop.graphics.clear();
            this._fillTop.graphics.beginFill('#ffffff');
            this._fillTop.graphics.drawRect(0, 0, width, this.durationView.bar.height + this._padding.top);
            this._fillTop.cache(0, 0, width, this.durationView.bar.height + this._padding.top, GLOBAL_CACHE_SCALE);

            this._fillBottom.graphics.clear();
            this._fillBottom.graphics.beginFill('#ffffff');
            this._fillBottom.graphics.drawRect(0, 0, width, this._padding.bottom + 36);
            this._fillBottom.cache(0, 0, width, this._padding.bottom + 36, GLOBAL_CACHE_SCALE);
            this._fillBottom.y = height - (this._padding.bottom + 36);
        });
    }

    private onRowBodyMouseDown(event: DisplayItemMouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (isNullOrUndefined(this.dragZone.location1)) {
                this.dragZone.location1 = this.playService.pixelToPlayLocation(event.moveTo.x, this.durationView.bar.fullSegmentWidth);
            }
        });
    }

    private rowBodyDrag(event: DisplayItemMouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.dragZone.location2 = this.playService.pixelToPlayLocation(event.moveTo.x, this.durationView.bar.fullSegmentWidth);
            this.dragZone.update();
        });
    }

    private rowBodyPressUp(event: DisplayItemMouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this.dragZone.location2) && !isNullOrUndefined(this.dragZone.location1)) {
                this.playService.setViewRange(this.dragZone.locationStart, this.dragZone.locationEnd);
            }

            this.dragZone.location2 = null;
            this.dragZone.location1 = null;
            this.dragZone.update();
        });
    }

    private setCanvasWidthHeight(): void {
        this._zone.runOutsideAngular(() => {
            if (!this.isNullOrUndefined(this.width) || !this.isNullOrUndefined(this.height)) {
                this._renderBase.setProperty(this.canvas.nativeElement, 'width', this.width.toString());
                this._renderBase.setProperty(this.canvas.nativeElement, 'height', this.height.toString());

                if (!this.isNullOrUndefined(this._stage) && this._stage instanceof createjs.StageGL) {
                    (this._stage as any).updateViewport(this.width, this.height);
                }

                this.resize(this.width, this.height);

                this.requireStageUpdate();
            }
        });
    }

    private stageMouseWheel(event): void {
        this._zone.runOutsideAngular(() => {
            const scrollSpeed = 15;
            const rowsFullHeight = this.rows.getFullHeight();
            const start = this._padding.top + this.durationView.bar.height + this._spacing;
            const minY = rowsFullHeight - TimeLineRowsSettings.row.height;

            if (Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail))) > 0) {
                if (this.rows.container.y < start) {
                    this.rows.container.y += scrollSpeed;
                }
            } else {
                if (this.rows.container.y > minY * -1) {
                    this.rows.container.y -= scrollSpeed;
                }
            }

            this.requireStageUpdate();
        });
    }
}
