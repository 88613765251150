
import { SortDirection } from '@angular/material/sort';
import { SortDirectionEnum } from '@shared/enum/SortDirection.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class ColumnSortingOptionModel extends BaseModel implements IRestModel {
    public columnName: string = null;
    public readonly isIRestModel = true;
    public sortDirection: SortDirectionEnum = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ColumnSortingOptionModel');
    }

    public get matDirection(): SortDirection {
        return isNullOrUndefined(this.sortDirection) ? '' : this.sortDirection === SortDirectionEnum.ascending ? 'asc' : 'desc';
    }

    public set matDirection(direction: SortDirection) {
        this.sortDirection = isNullOrUndefined(direction) || direction === '' ? null : direction === 'asc' ? SortDirectionEnum.ascending : SortDirectionEnum.descending;
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
