<div mat-dialog-title>
    <div fxLayout="row"
        fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Validation Session Sync
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div mat-dialog-content
    fxLayout="column">
    <div class="sync-info">
        The version of the validation session you have locally is out of sync with the remote version. What would you
        like to do.
    </div>
    <div class="sync-info">
        Take Changes - Will overwrite all your local changes with what is on the remote.
    </div>
    <div class="sync-info">
        Merge Changes - Will merge your local version and the remote version.
    </div>
    <div class="sync-info">
        Keep Changes - Will overwrite the remote version with your version.
    </div>
</div>
<div mat-dialog-actions>
    <button (click)="take()"
        mat-raised-button
        color="primary">Take Changes</button>
    <div fxFlex></div>
    <button (click)="merge()"
        mat-raised-button
        color="primary">Merge Changes</button>
    <div fxFlex></div>
    <button (click)="keep()"
        mat-raised-button
        color="primary">Keep Changes</button>

    <button (click)="cancel()"
        mat-raised-button
        color="primary">Cancel</button>
</div>