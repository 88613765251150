import { Routes } from '@angular/router';
import { RiftActivityComponent } from '@em/components/rift/activity/Rift.Activity.Component';
import { RiftHealthComponent } from '@em/components/rift/health/Rift.Health.Component';
import { RiftHomeComponent } from '@em/components/rift/home/Rift.Home.Component';
import { RiftLocationComponent } from '@em/components/rift/location/Rift.Location.Component';
import { RiftMetaDataComponent } from '@em/components/rift/metadata/Rift.MetaData.Component';
import { BaseRiftRoutes } from '@rift/Rift.Routes';
import { IRouteData } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { PendingChangesGuard } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { UserIsAuthenticated } from '@em/service/user/UserIsLevel';
import { RiftConnectionRouteComponent } from './Rift.Routes.Connect.Component';

export const EmRiftRoutes: Routes = [
    {
        path: 'connect',
        canActivate: [UserIsAuthenticated],
        component: RiftConnectionRouteComponent,
    },
    {
        path: 'rift/:protocol/:connectionData',
        canActivate: [UserIsAuthenticated],
        component: RiftHomeComponent,
        data: { breadcrumb: { name: 'em-rift-home', text: 'RIFT' } } as IRouteData,
        children: [
            ...BaseRiftRoutes,
            {
                path: 'metadata',
                canActivate: [UserIsAuthenticated],
                canDeactivate: [PendingChangesGuard],
                component: RiftMetaDataComponent,
                data: { breadcrumb: { name: 'em-rift-metadata', text: 'MetaData' } } as IRouteData,
            },
            {
                path: 'activity',
                canActivate: [UserIsAuthenticated],
                component: RiftActivityComponent,
                data: { breadcrumb: { name: 'em-rift-activity', text: 'Activity' } } as IRouteData,
            },
            {
                path: 'location',
                canActivate: [UserIsAuthenticated],
                canDeactivate: [PendingChangesGuard],
                component: RiftLocationComponent,
                data: { breadcrumb: { name: 'em-rift-location', text: 'Location' } } as IRouteData,
            },
            {
                path: 'health',
                canActivate: [UserIsAuthenticated],
                component: RiftHealthComponent,
                data: { breadcrumb: { name: 'em-rift-health', text: 'Health' } } as IRouteData,
            },
        ]
    },
];
