<div fxLayout="column"
    fxFlex>
    <mat-list-item *ngIf="expanded === false"
        class="item">
        <div class="header expanded-header"
            fxFlex
            fxLayout="row"
            fxLayoutAlign="start center">
            <ng-template [ngTemplateOutlet]="expandItemHeaderCollapsed">
            </ng-template>
            <div fxFlex></div>
            <div>
                <mat-icon (click)="expand()">expand_more</mat-icon>
            </div>
        </div>
    </mat-list-item>

    <mat-list-item *ngIf="expanded === true"
        class="item">
        <div (click)="collapse()"
            class="header collapsed-header"
            fxFlex
            fxLayout="row"
            fxLayoutAlign="start center">
            <ng-template [ngTemplateOutlet]="expandItemHeaderExpanded">
            </ng-template>
            <div fxFlex></div>
            <div>
                <mat-icon (click)="collapse()">expand_less</mat-icon>
            </div>
        </div>
    </mat-list-item>

    <div class="content"
        [@bodyExpansion]="expanded === true ? 'expanded' : 'collapsed'">
        <ng-template [ngTemplateOutlet]="expandItemContent">

        </ng-template>
    </div>
</div>