import { NgZone, Directive } from '@angular/core';
import { ViewPortModeEnum } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { SafeSubscriptionBase } from '@shared/base/SafeSubscription.Base';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { UniqueIdUtility } from '@shared/utility/UniqueId.Utility';
import { Subject, Subscription } from 'rxjs';

@Directive()
export abstract class DisplayItem extends SafeSubscriptionBase implements IDisplayItem {
    /**
     * Fire to request a stage update.
     *
     * @type {Subject<void>}
     * @memberof DisplayItem
     */
    public requireStageUpdate: Subject<void> = new Subject<void>();

    /**
     * The load queue subscription;
     *
     * @protected
     * @type {Subscription}
     * @memberof DisplayItem
     */
    protected loadQueueSubscription: Subscription;
    private _container: createjs.Container = new createjs.Container();
    private _isMouseDown: boolean = false;
    private _isMouseOver: boolean = false;

    private _mode: ViewPortModeEnum = null;
    private _uniqueId: number = null;
    private _unitOfMeasurement: UnitsOfMeasurementEnum = UnitsOfMeasurementEnum.metric;

    /**
     * Creates an instance of DisplayItem.
     *
     * @memberof DisplayItem
     */
    protected constructor(protected readonly _zoneBase: NgZone) {
        super();
        this._uniqueId = UniqueIdUtility.nextId;
    }

    public abstract bringToFront(displayItem?: IDisplayItem): void;

    public abstract update(): void;

    /**
     * Gets the DisplayItem content container.
     *
     * @readonly
     * @type {createjs.Container}
     * @memberof DisplayItem
     */
    public get container(): createjs.Container {
        return this._zoneBase.runOutsideAngular(() => this._container);
    }

    public get isMouseDown(): boolean {
        return this._zoneBase.runOutsideAngular(() => this._isMouseDown);
    }

    public set isMouseDown(value: boolean) {
        this._zoneBase.runOutsideAngular(() => {
            if (this._isMouseDown !== value) {
                this._isMouseDown = value;
                this.isMouseDownSet();
            }
        });
    }

    public get isMouseOver(): boolean {
        return this._zoneBase.runOutsideAngular(() => this._isMouseOver);
    }

    public set isMouseOver(value: boolean) {
        this._zoneBase.runOutsideAngular(() => {
            if (this._isMouseOver !== value) {
                this._isMouseOver = value;
                this.isMouseOverSet();
            }
        });
    }

    public get mode(): ViewPortModeEnum {
        return this._zoneBase.runOutsideAngular(() => this._mode);
    }

    public set mode(value: ViewPortModeEnum) {
        this._zoneBase.runOutsideAngular(() => {
            if (this._mode !== value) {
                this._mode = value;
                this.modeSet();
            }
        });
    }

    public onDestroy(): void {
        return this._zoneBase.runOutsideAngular(() => {
            this.container.removeAllEventListeners();
            this.unsubscribeAll();
        });
    }

    /**
     * Gets the DisplayItem unique identifier.
     *
     * @readonly
     * @type {number}
     * @memberof DisplayItem
     */
    public get uniqueId(): number {
        return this._zoneBase.runOutsideAngular(() => this._uniqueId);
    }

    /**
     * Gets the DisplayItem unit of measurement.
     *
     * @type {UnitsOfMeasurementEnum}
     * @memberof DisplayItem
     */
    public get unitOfMeasurement(): UnitsOfMeasurementEnum {
        return this._zoneBase.runOutsideAngular(() => this._unitOfMeasurement);
    }

    /**
     * Sets the DisplayItem unit of measurement.
     *
     * @memberof DisplayItem
     */
    public set unitOfMeasurement(value: UnitsOfMeasurementEnum) {
        this._zoneBase.runOutsideAngular(() => {
            if (this._unitOfMeasurement !== value) {
                this._unitOfMeasurement = value;
                this.unitOfMeasurementSet();
            }
        });
    }

    protected isMouseDownSet(): void {
    }

    protected isMouseOverSet(): void {
    }

    protected modeSet(): void {
    }

    protected unitOfMeasurementSet(): void {
    }
}
