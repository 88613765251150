<div #container
    *ngIf="show === true && canShow === true"
    class="float-dialog rift-settings-counting-edit-register"
    (click)="onMenuClick()"
    ngDraggable
    #draggable="ngDraggable"
    (started)="onDrStarted($event)"
    (stopped)="onDrStopped($event)"
    (edge)="onDrEdge($event)"
    (movingOffset)="onDrMovingOffset($event)"
    (endOffset)="onDrEndOffset($event)"
    [handle]="title"
    [bounds]="bounds"
    [position]="position"
    [inBounds]="!isNullOrUndefined(bounds)">
    <div #title
        class="title drag-handle"
        fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex
            fxLayout="row"
            fxLayoutAlign="start center">
            <div fxLayout="column">
                <div class="header">
                    Global Height Filter
                </div>
            </div>
            <div fxFlex></div>
            <div class="actions">
                <button class="flat-icon-button"
                    [disabled]="saveAllChangesProcess.isRunning === true"
                    mat-stroked-button
                    matTooltip="Close"
                    (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="edit-content">
            <form [formGroup]="form"
                fxFlex>
                <div fxLayout="row">
                    <mat-form-field fxFlex="50"
                        class="spacing-right">
                        <input riftUnitsOfMeasurementInput
                            #lowerBoundInput=riftUnitsOfMeasurementInput
                            formControlName="lowerBound"
                            placeholder="Lower Bound"
                            [metricMin]="0"
                            [metricMax]="500"
                            [metricUnit]="UnitOfMeasurementEnum.centimeter"
                            [imperialUnit]="UnitOfMeasurementEnum.inch">
                        <span matSuffix>
                            {{lowerBoundInput.suffix}}
                        </span>
                        <mat-error>{{getFormControlErrorMessage(form.controls['lowerBound'])}}</mat-error>
                        <span matSuffix>{{ globalHeightFilter?.propertyHasChangesText('lowerBound') }}</span>
                    </mat-form-field>
                    <mat-form-field fxFlex="50">
                        <input riftUnitsOfMeasurementInput
                            #upperBoundInput=riftUnitsOfMeasurementInput
                            formControlName="upperBound"
                            placeholder="Upper Bound"
                            [metricMin]="0"
                            [metricMax]="500"
                            [metricUnit]="UnitOfMeasurementEnum.centimeter"
                            [imperialUnit]="UnitOfMeasurementEnum.inch">
                        <span matSuffix>
                            {{upperBoundInput.suffix}}
                        </span>
                        <mat-error>{{getFormControlErrorMessage(form.controls['upperBound'])}}</mat-error>
                        <span matSuffix>{{ globalHeightFilter?.propertyHasChangesText('upperBound') }}</span>
                    </mat-form-field>
                </div>
            </form>
        </div>
        <div class="actions">
            <button mat-raised-button
                (click)="onSaveClick()"
                [disabled]="isNullOrUndefined(globalHeightFilter) || isValid === false || hasChanges === false">
                Save Height Filter
            </button>
        </div>
    </div>
</div>