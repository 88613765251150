import { Routes } from '@angular/router';
import { DeviceGroupComponent } from '@em/components/devicegroup/DeviceGroup.Component';
import { DeviceGroupEnum } from '@shared/enum/DeviceGroup.Enum';
import { IRouteData } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { UserIsAuthenticated } from '@em/service/user/UserIsLevel';

export const EmDeviceGroupRoutes: Routes = [
    {
        path: 'devicegroup/:groupId',
        canActivate: [UserIsAuthenticated],
        component: DeviceGroupComponent,
        data: {
            breadcrumb: {
                name: 'em-devicegroup',
                text: [
                    { name: 'em-devicegroup-unlicensed', groupId: DeviceGroupEnum.unlicensed, text: 'Unlicensed Devices' },
                    { name: 'em-devicegroup-new', groupId: DeviceGroupEnum.new, text: 'New Devices' },
                    { name: 'em-devicegroup-connected', groupId: DeviceGroupEnum.connected, text: 'Connected Devices' },
                    { name: 'em-devicegroup-disconnected', groupId: DeviceGroupEnum.disconnected, text: 'Disconnected Devices' },
                    { name: 'em-devicegroup-orphaned', groupId: DeviceGroupEnum.orphaned, text: 'Orphaned Devices' },
                    { lookupSetName: 'addressbook' },
                ]
            }
        } as IRouteData
    },
];
