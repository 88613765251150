export enum RegisterTypeCategoryEnum {
    line = 0,
    zone = 1,
    unknown = 2,
}

export class RegisterTypeCategoryEnumHelpers {
    public static getDisplayName(value: RegisterTypeCategoryEnum): string {
        switch (value) {
            case RegisterTypeCategoryEnum.line:
                return 'Line';
            case RegisterTypeCategoryEnum.zone:
                return 'Zone';
            case RegisterTypeCategoryEnum.unknown:
                return 'Unknown';
        }
    }
}
