import { Dictionary } from '@shared/generic/Dictionary';
import { isNullOrUndefined, isString } from '@shared/utility/General.Utility';

export interface IWebStorageCache {
    key: string;
    value: any;
}

/**
 * Utility class for accessing storage.
 *
 * @export
 * @class WebStorageUtility
 */
export class WebStorageUtility {
    private static _cache: Dictionary<string, IWebStorageCache> = new Dictionary<string, IWebStorageCache>();

    /**
     * Get an object from storage.
     *
     * @static
     * @param {Storage} storage The storage mechanism.
     * @param {string} key The storage key.
     * @returns {*} The item from storage.
     * @memberof WebStorageUtility
     */
    public static get<T>(storage: Storage, key: string): T {
        const storageKey = WebStorageUtility.generateStorageKey(key);
        let cache = this._cache.get(storageKey);
        if (isNullOrUndefined(cache)) {
            cache = { key: storageKey, value: WebStorageUtility.getGettable(storage.getItem(storageKey)) };
            this._cache.addOrUpdate(storageKey, cache);
        }
        return cache.value;
    }

    /**
     * Removes an it at key from storage.
     *
     * @static
     * @param {Storage} storage The storage mechanism.
     * @param {string} key The storage key.
     * @memberof WebStorageUtility
     */
    public static remove(storage: Storage, key: string): void {
        const storageKey = WebStorageUtility.generateStorageKey(key);
        this._cache.remove(storageKey);
        storage.removeItem(storageKey);
    }

    /**
     * Sets an object to storage.
     *
     * @static
     * @param {Storage} storage The storage mechanism.
     * @param {string} key The storage key.
     * @param {*} value The item to add to storage.
     * @memberof WebStorageUtility
     */
    public static set<T>(storage: Storage, key: string, value: T): void {
        const storageKey = WebStorageUtility.generateStorageKey(key);
        let cache = this._cache.get(storageKey);
        if (isNullOrUndefined(cache)) {
            cache = { key: storageKey, value: WebStorageUtility.getGettable(storage.getItem(storageKey)) };
            this._cache.addOrUpdate(storageKey, cache);
        }
        cache.value = value;
        storage.setItem(storageKey, WebStorageUtility.getSettable(value));
    }
    /**
     * Gets a storage key.
     *
     * @static
     * @param {string} key The key postfix.
     * @returns {string} The storage key.
     * @memberof WebStorageUtility
     */
    public static generateStorageKey(key: string): string {
        return `irisys-${key}`;
    }

    /**
     * Converst value to settable json string.
     *
     * @private
     * @static
     * @param {*} value The value to convert to settable json string.
     * @returns {string} The json settable string.
     * @memberof WebStorageUtility
     */
    private static getSettable(value: any): string {
        return isString(value) ? value : JSON.stringify(value);
    }

    /**
     * Phrases the value to an gettable value.
     *
     * @private
     * @static
     * @param {string} value The value to phrase.
     * @returns {*} The phrased value.
     * @memberof WebStorageUtility
     */
    private static getGettable(value: string): any {
        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    }
}
