import { Component, HostBinding, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceSerialLinkComponent } from '@em/components/shared/deviceseriallink/DeviceSerialLink.Component';
import { FavouriteDevicesService, IFavouriteDevice } from '@em/service/favouritedevices/FavouriteDevices.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { ConnectionService } from '@rift/service/connection/Connection.Service';

@Component({
    selector: 'em-navmenu-favourite-devices',
    templateUrl: './NavMenu.FavouriteDevices.Component.html',
    styleUrls: ['./NavMenu.FavouriteDevices.Component.scss']
})
export class NavMenuFavouriteDevicesComponent extends BaseComponent {
    public static className: string = 'NavMenuFavouriteDevicesComponent';

    @HostBinding()
    public id: string = 'em-navmenu-favourite-devices';

    public constructor(
        private readonly _connectionService: ConnectionService,
        private readonly _router: Router,
        private readonly _favouriteDevices: FavouriteDevicesService,
        private readonly _injector: Injector) {
        super(_injector);
    }

    public get devices(): IFavouriteDevice[] {
        return this._favouriteDevices.devices;
    }

    public onDeviceClicked(device: IFavouriteDevice): void {
        DeviceSerialLinkComponent.navigateToDevice(this._router, device.serial, this._connectionService);
    }

    public onDeleteClicked(event: MouseEvent, device: IFavouriteDevice): void {
        this._favouriteDevices.remove(device.serial);
        event.preventDefault();
    }
}
