import { BaseModel } from '@shared/base/Base.Model';
import { DeviceActivityActionEnum } from '@shared/enum/DeviceActivityAction.Enum';
import { DeviceActivityContentTypeEnum } from '@shared/enum/DeviceActivityContentType.Enum';
import { DeviceActivityTypeEnum } from '@shared/enum/DeviceActivityType.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';

export class DeviceActivityModel extends BaseModel implements IRestModel {
    public action: DeviceActivityActionEnum = null;
    public binaryData: Array<any> = null;
    public contentType: DeviceActivityContentTypeEnum = null;
    public deviceSerial: number = null;

    public id: number = null;
    public message: string = null;
    public readonly isIRestModel = true;
    public time: Date = null;
    public type: DeviceActivityTypeEnum = null;
    private _dateText: string;

    private _timeText: string;

    public constructor() {
        super();
    }

    public get dateText(): string {
        return this._dateText;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-DeviceActivityModel');

        this.time = DateTimeNonMomentUtility.fromRestApiDateTime(this.time);
        this.binaryData = restModel.BinaryData;

        this._timeText = DateTimeUtility.toShortTime(this.time);
        this._dateText = DateTimeUtility.toShortDateTime(this.time);
    }

    public get timeText(): string {
        return this._timeText;
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
