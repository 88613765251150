import { ValidationSessionOptionModel } from '@rift/models/restapi/ValidationSessionOption.Model';

export interface IUserCountsKeyBindings {
    increment: string;
    decrement: string;
}

export interface IKeyBindings {
    userCounts: Array<IUserCountsKeyBindings>;
}

export interface IAutoSpeedSettings {
    /**
     * The play speed that will be set when there is no target
     * in view or no targets about to enter the view within the
     * in and out window.
     *
     * @type {number}
     * @memberof IGetAutoSpeedProfileRequest
     */
    noTargetsSpeed: number;
    /**
     * The play speed that will be set when there are target
     * in view or targets about to enter the view within the
     * in and out window.
     *
     * @type {number}
     * @memberof IGetAutoSpeedProfileRequest
     */
    targetsSpeed: number;
    /**
     * The number of seconds before a target enters the view
     * that it will be considered in view.
     *
     * @type {number}
     * @memberof IGetAutoSpeedProfileRequest
     */
    inWindow: number;
    /**
     * The number of seconds after a target has left the view
     * that it will be considered in view.
     *
     * @type {number}
     * @memberof IGetAutoSpeedProfileRequest
     */
    outWindow: number;
}

export const DefaultSessionOptions: Array<ValidationSessionOptionModel> = [
    new ValidationSessionOptionModel('showcountlines', true, false),
    new ValidationSessionOptionModel('showtargets', true, false),
    new ValidationSessionOptionModel('showtargetheight', true, false),
    new ValidationSessionOptionModel('showtargetspaths', true, false),
    new ValidationSessionOptionModel('showtargetstails', true, false),
    new ValidationSessionOptionModel('showtargetsovervideo', true, false),
    new ValidationSessionOptionModel('showsystemcounts', true, false),
    new ValidationSessionOptionModel('fieldofview', true, false),
];

export const DefaultKeyBindings: IKeyBindings = {
    userCounts: [
        { increment: 'Q', decrement: 'A' },
        { increment: 'W', decrement: 'S' },
        { increment: 'E', decrement: 'D' },
        { increment: 'R', decrement: 'F' },
        { increment: 'T', decrement: 'G' },
        { increment: 'Y', decrement: 'H' }
    ]
};

export const DefaultArrowKeyJogMs = 100;

export const DefaultLineReportBucketSize = 60;

export const DefaultShowDurationAsTime = false;

export const DefaultShowDurationAsDeviceTimeZone = false;

export const DefaultHullScaleFactor = 1.4;

export const DefaultAutoSpeedSettings = {
    noTargetsSpeed: 20,
    targetsSpeed: 1,
    inWindow: 2,
    outWindow: 2,
};
