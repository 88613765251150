import { Component, Injector, OnInit, HostBinding } from '@angular/core';
import { UserModel } from '@em/models/restapi/User.Model';
import { BaseComponent } from '@shared/base/Base.Component';
import { AuthTypeEnum } from '@shared/enum/AuthType.Enum';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';

@Component({
    selector: 'em-documentation-restapi',
    templateUrl: './Documentation.RestApi.Component.html',
    styleUrls: ['./Documentation.RestApi.Component.scss']
})
export class DocumentationRestApiComponent extends BaseComponent implements OnInit {
    public static className: string = 'DocumentationRestApiComponent';
    public getRestApiKeyProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'em-documentation-restapi';

    public restApiKey: string;
    public authType: AuthTypeEnum;
    public AuthTypeEnum = AuthTypeEnum;

    public constructor(
        private readonly _currentUserService: UserCurrentService,
        private readonly _injector: Injector) {
        super(_injector);

        this.getRestApiKeyProcess = this.processMonitorService.getProcess(DocumentationRestApiComponent.className, 'Getting rest api key');

        this.addSubscription(this.observableHandlerBase(this._currentUserService.user, this.getRestApiKeyProcess).subscribe(user => {
            this.restApiKey = (user as UserModel).webAPIKey;
            this.authType = (user as UserModel).authType;
        }), this.getRestApiKeyProcess);
    }

    public ngOnInit(): void {
        super.ngOnInit();

    }
}
