import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { getConfigBooleanValue } from '@rift/shared/getConfigBooleanValue';
import { IAssociatedRegisterConfig } from '@rift/shared/IAssociatedRegisterConfig';
import { IRegisterConfig } from '@rift/shared/IRegisterConfig';
import { IRegisterProperty } from '@rift/shared/IRegisterProperty';
import { RegisterTypeConfig } from '@rift/shared/Settings.RegisterConfig';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';

export class RegisterTypeViewModel {

    public hasNameStep: boolean = true;

    public hasSettingsAssociated: boolean = false;
    public associatedRegisterConfigs: Array<IAssociatedRegisterConfig> = null;
    public description: string = null;
    public displayName: string = null;
    public faIconName: string = null;
    public fixedName: string = null;
    public hasSettingsCountMode: boolean = false;
    public hasSettingsDwellHistogram: boolean = false;
    public hasSettingsHeightFiltering: boolean = false;
    public hasSettingsHeightHistogram: boolean = false;
    public hasSettingsInstantDwellHistogram: boolean = false;
    public hasSettingsStaffDetection: boolean = false;
    public hasSettingsStep: boolean = true;
    public hasSettingsUnattendedTimeDwellHistogram: boolean = false;
    public hasSettingsZone: boolean = false;
    public hasExclusionType: boolean = false;
    public hasCustomAction: boolean = false;
    public hasMaxDwell: boolean = false;
    public isRemote: boolean = false;
    public isRemotePush: boolean = true;
    public isWirelessPIR: boolean = false;
    public hasTags: boolean = false;
    public order: number = null;
    public type: RegisterTypeEnum = null;

    public hasSettingsSimpleProperties: boolean = false;
    public simpleProperties: Array<IRegisterProperty> = null;
    public registerTypeConfig: IRegisterConfig = null;

    public constructor(
        private readonly _typeOrRegisterBase: RegisterTypeEnum | RegisterBaseModel,
        private readonly _masterDevice: DeviceModel) {

        if (_typeOrRegisterBase instanceof RegisterBaseModel) {
            this.type = _typeOrRegisterBase.registerType;
        } else {
            this.type = _typeOrRegisterBase;
        }
        this.registerTypeConfig = RegisterTypeConfig[this.type];

        this.order = isNullOrUndefined(this.registerTypeConfig.orderInCategory) ? 999 : this.registerTypeConfig.orderInCategory;
        this.description = this.registerTypeConfig.description;
        this.displayName = this.registerTypeConfig.displayName;
        this.faIconName = this.registerTypeConfig.faIconName;

        this.hasSettingsCountMode = this._masterDevice.isRegisterSettingCompatible(this.type, 'hasCountMode');
        this.hasSettingsAssociated = isNullOrUndefined(this.registerTypeConfig.associatedRegisterConfigs) ? false : this.registerTypeConfig.associatedRegisterConfigs.length > 0;
        this.hasSettingsHeightFiltering = this._masterDevice.isRegisterSettingCompatible(this.type, 'hasHeightFiltering');
        this.hasSettingsHeightHistogram = this._masterDevice.isRegisterSettingCompatible(this.type, 'hasHeightHistogram');
        this.hasSettingsStaffDetection = this._masterDevice.isRegisterSettingCompatible(this.type, 'hasStaffDetection');
        this.hasSettingsZone = this._masterDevice.isRegisterSettingCompatible(this.type, 'hasZone');
        this.hasSettingsDwellHistogram = this._masterDevice.isRegisterSettingCompatible(this.type, 'hasDwellHistogram');
        this.hasSettingsUnattendedTimeDwellHistogram = this._masterDevice.isRegisterSettingCompatible(this.type, 'hasUnattendedTimeDwellHistogram');
        this.hasSettingsInstantDwellHistogram = this._masterDevice.isRegisterSettingCompatible(this.type, 'hasInstantDwellHistogram');
        this.hasTags = this._masterDevice.isRegisterSettingCompatible(this.type, 'hasTags');
        this.hasExclusionType = this._masterDevice.isRegisterSettingCompatible(this.type, 'hasExclusionType');
        this.hasCustomAction = this._masterDevice.isRegisterSettingCompatible(this.type, 'isCustomActionCompatible');
        this.isRemote = this.type === RegisterTypeEnum.remoteValue;
        this.isRemotePush = this._masterDevice.isCapable(DeviceCapabilitiesEnum.remoteRegisterPush) && this.type !== RegisterTypeEnum.remoteReceiver;
        this.isWirelessPIR = this.type === RegisterTypeEnum.wirelessPIR;
        this.hasMaxDwell = this._masterDevice.isRegisterSettingCompatible(this.type, 'hasMaxDwell');

        this.hasSettingsSimpleProperties = !isNullOrUndefined(this.registerTypeConfig.simpleProperties) && this.registerTypeConfig.simpleProperties.length > 0;
        if (this.hasSettingsSimpleProperties) {
            this.simpleProperties = this.registerTypeConfig.simpleProperties;
        }

        if (!isNullOrUndefined(this.registerTypeConfig.associatedRegisterConfigs)) {
            this.associatedRegisterConfigs = this.registerTypeConfig.associatedRegisterConfigs;
        } else {
            this.associatedRegisterConfigs = [];
        }

        this.fixedName = this.registerTypeConfig.fixedName;

        if (!isNullOrUndefined(this.registerTypeConfig.settings)) {
            this.hasNameStep = getConfigBooleanValue(this.registerTypeConfig.settings, 'hideNameStep');
            this.hasSettingsStep = getConfigBooleanValue(this.registerTypeConfig.settings, 'hideSettingsStep');
        }

        if (!this.hasSettingsAssociated &&
            !this.hasSettingsHeightFiltering &&
            !this.hasSettingsHeightHistogram &&
            !this.hasSettingsStaffDetection &&
            !this.hasSettingsCountMode &&
            !this.hasSettingsZone &&
            !this.hasSettingsDwellHistogram &&
            !this.hasSettingsUnattendedTimeDwellHistogram &&
            !this.hasSettingsInstantDwellHistogram &&
            !this.hasTags &&
            !this.hasExclusionType &&
            !this.hasCustomAction &&
            !this.isRemote &&
            !this.isRemotePush &&
            !this.hasSettingsSimpleProperties &&
            (isNullOrUndefined(this.associatedRegisterConfigs) || this.associatedRegisterConfigs.length === 0)) {
            this.hasSettingsStep = false;
        }
    }
}
