<mat-card class="spacing-bottom">
    When adding outbound connections be sure that the device isn't already connecting into Estate Manager as this could cause connection /
    disconnection loops.
</mat-card>
<ng-template ngFor
    let-group
    [ngForOf]="groups">
    <shr-table [isLoadingData]="loadDataProcess.isRunning"
        class="spacing-bottom"
        [dataSource]="group.dataSource"
        [displayedColumns]="displayedColumns"
        (filter)="applyFilter($event, group)"
        [dataExportEnabled]="true"
        [dataExportGetDataHandler]="dataExportGetData"
        [dataExportGetHeaderHandler]="dataExportGetHeader">
        <ng-template #shrTableHeader>
            {{group.item.name}}{{ group.item.propertyHasChangesText('name') }}
            ({{group.connections.length}})
            <ng-template [ngIf]="group.item.name !== 'Ungrouped'">
                <button matTooltip="Edit"
                    mat-icon-button
                    (click)="editGroup(group)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button matTooltip="Delete"
                    mat-icon-button
                    (click)="deleteGroup(group)"
                    color="warn">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>
        </ng-template>

        <ng-template #shrTableActions
            let-connection>
            <button matTooltip="Edit"
                mat-icon-button
                (click)="editConnection(connection, group)">
                <mat-icon>edit</mat-icon>
            </button>
            <button matTooltip="Move"
                mat-icon-button
                (click)="moveConnection(connection, group)">
                <mat-icon>unfold_more</mat-icon>
            </button>
            <button matTooltip="Delete"
                mat-icon-button
                (click)="deleteConnection(connection, group)"
                color="warn">
                <mat-icon>delete</mat-icon>
            </button>
        </ng-template>

        <ng-container matColumnDef="address">
            <th mat-header-cell
                *matHeaderCellDef> Address </th>
            <td mat-cell
                *matCellDef="let connection">
                <shr-table-cell [data]="connection"
                    keyPath="address">
                </shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="port">
            <th mat-header-cell
                *matHeaderCellDef> Port </th>
            <td mat-cell
                *matCellDef="let connection">
                <shr-table-cell [data]="connection"
                    keyPath="port">
                </shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th mat-header-cell
                *matHeaderCellDef> State </th>
            <td mat-cell
                *matCellDef="let connection"
                class="error_description">
                <shr-table-cell [data]="connection"
                    [spanStyle]="{'white-space': 'pre-line'}"
                    keyPath="activeConnection.activeConnectionStateString">
                </shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="lastConnected">
            <th mat-header-cell
                *matHeaderCellDef> Last Connected </th>
            <td mat-cell
                *matCellDef="let connection">
                <shr-table-cell [data]="connection"
                    keyPath="activeConnection.lastConnectionTimeText">
                </shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="serialNumber">
            <th mat-header-cell
                *matHeaderCellDef> Serial Number </th>
            <td mat-cell
                *matCellDef="let connection">
                <em-device-serial-link *ngIf="connection.activeConnection?.activeConnectionState === 2"
                    viewMode="link"
                    [serial]="connection.activeConnection.deviceSerial"></em-device-serial-link>
            </td>
        </ng-container>
    </shr-table>
</ng-template>