import { ActiveConnectionModel } from '@em/models/restapi/ActiveConnection.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class ConnectionModel extends BaseModel implements IRestModel {
    public activeConnection: ActiveConnectionModel = null;
    public address: string = null;
    public connectionId: number = null;
    public errorMessage: string = null;
    public groupId: number = null;
    public port: number = null;
    public username: string = null;
    public password: string = null;
    public readonly isIRestModel = true;

    public success: boolean = null;

    public constructor() {
        super();

        this.registerProperty('address');
        this.registerProperty('port');
        this.registerProperty('groupId');
        this.registerProperty('username');
        this.registerProperty('password');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ConnectionModel');

        this.activeConnection = RestModelUtility.loadFrom(restModel.ActiveConnection, ActiveConnectionModel);

        this.setPropertyOriginalValue('address', this.address);
        this.setPropertyOriginalValue('port', this.port);
        this.setPropertyOriginalValue('groupId', this.groupId);
        this.setPropertyOriginalValue('username', this.username);
        this.setPropertyOriginalValue('password', this.password);
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this)
        };
    }
}
