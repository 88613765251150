import { Injectable, isDevMode } from '@angular/core';
import { SafeSubscriptionBase } from '@shared/base/SafeSubscription.Base';
import { AppHostEnum, IConfigurationService } from '@shared/interface/IConfigurationService';
import { PlotlyConfiguration } from '@shared/service/configuration/Configuration.Plotly';
import { LoggingServiceLevel } from '@shared/service/logging/Logging.Service.Level';
import { LoggingServiceOptions } from '@shared/service/logging/Logging.Service.Options';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { HttpProtocols } from '@shared/types/HttpProtocols';
import { UrlUtility } from '@shared/utility/Url.Utility';


/**
 * Em configuration
 *
 * @export
 * @class ConfigurationService
 * @extends {SafeSubscriptionBase}
 */
@Injectable()
export class EmConfigurationService extends SafeSubscriptionBase implements IConfigurationService {
    public protocol: HttpProtocols = UrlUtility.getProtocol();
    public hostName: string = UrlUtility.getHostname();
    public port: number = Number.isNaN(parseInt(location.port, 10)) ? null : parseInt(location.port, 10) === 4200 ? 54321 : parseInt(location.port, 10);

    public baseTitle: string = 'Estate Manager';
    public deviceConnectionTimeOutMs: number = 5000;
    public emRestApiBase: string = `${this.protocol === 'secure' ? 'https' : 'http'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/api/em/`;
    public emV1RestApiBase: string = `${this.protocol === 'secure' ? 'https' : 'http'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/api/v1/`;
    public emV2RestApiBase: string = `${this.protocol === 'secure' ? 'https' : 'http'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/api/v2/`;
    public hostApp: AppHostEnum = AppHostEnum.em;
    public loggingServiceOptions: LoggingServiceOptions = new LoggingServiceOptions();
    public plotlyOptions: PlotlyConfiguration = new PlotlyConfiguration();
    public appBase: string = `${this.protocol === 'secure' ? 'https' : 'http'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/`;
    public versionBase: string = this.appBase;
    public riftConnectRedirectPath: string = 'summery';
    public riftConnectRedirectPathOverride: string;
    public riftNavigateToOnFailedConnection: string[] = ['dashboard'];
    public riftRefreshAllData: boolean = false;
    public riftRestApiBase: string = `${this.protocol === 'secure' ? 'https' : 'http'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/api/`;
    public riftV1RestApiBase: string = `${this.protocol === 'secure' ? 'https' : 'http'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/api/rt/v1/`;
    public riftWebSocket: string = `${this.protocol === 'secure' ? 'wss' : 'ws'}://${this.hostName}${!isNullOrUndefined(this.port) ? ':' + this.port.toString() : ''}/ws`;

    /**
     * Creates an instance of ConfigurationService.
     *
     * @memberof ConfigurationService
     */
    public constructor() {
        super();

        this.loggingServiceOptions.level = LoggingServiceLevel.Error;
        this.loggingServiceOptions.store = true;
        this.loggingServiceOptions.storeLevel = LoggingServiceLevel.Error;
        this.loggingServiceOptions.storeMax = 10;
        this.loggingServiceOptions.storeAs = 'em.log';
    }
}
