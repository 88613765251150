/**
 * Task to execute states.
 *
 * @export
 * @enum {number}
 */
export enum TaskToExecuteStateEnum {
    /**
     *This task will not be executed.
     */
    notReadyToExecute = 0,

    /**
     *This task is queued.
     */
    readyToExecute = 1, //

    /**
     *This task is executing.
     */
    executing = 2, //

    /**
     *This task has passed execution.
     */
    passedExecution = 3, //

    /**
     *This task has failed execution.
     */
    failedExecution = 4, //

    /**
     *This task has failed execution and has caused the system to pause.
     */
    failedExecutionPaused = 5,

    /**
     *This task couldn't be run because the device was not present.
     */
    disconnected = 6, //
}

export class TaskToExecuteStateEnumHelpers {
    public static toStringHumanized(value: TaskToExecuteStateEnum): string {
        switch (value) {
            case TaskToExecuteStateEnum.readyToExecute:
                return 'Queued';
            case TaskToExecuteStateEnum.executing:
                return 'Executing';
            case TaskToExecuteStateEnum.passedExecution:
                return 'Passed';
            case TaskToExecuteStateEnum.failedExecution:
                return 'Failed';
            case TaskToExecuteStateEnum.failedExecutionPaused:
                return 'Failed';
            case TaskToExecuteStateEnum.disconnected:
                return 'Disconnected';
        }
    }
}
