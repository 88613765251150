import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface ICountModel {
    id: number;
    counts: Array<number>;
}

export class CountModel extends BaseModel implements ICountModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'count_case';

    public readonly isIWebSocketModel: boolean = true;
    public packetType: string = null;
    public id: number = null;
    public counts: Array<number> = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-CountModel');
    }

    public toWebSocketMessage(): void {
        throw new Error('Method not implemented.');
    }
}
