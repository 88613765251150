import { INewValidationSessionDetailsModel } from '@rift/models/restapi/NewValidationSessionDetails.Model';
import { ISyncedVideoSessionModel } from '@rift/models/restapi/SyncedVideoSession.Model';
import { IValidationReportModel } from '@rift/models/restapi/ValidationReport.Model';
import { IValidationReportLineModel } from '@rift/models/restapi/ValidationReportLine.Model';
import { IValidationReportNodeModel } from '@rift/models/restapi/ValidationReportNode.Model';
import { IValidationSessionOptionModel } from '@rift/models/restapi/ValidationSessionOption.Model';
import { IValidationVideoIdentifierModel } from '@rift/models/restapi/ValidationVideoIdentifier.Model';
import { IRecordingModel } from '@rift/service/validation/models/database/syncrecording/Recording.Model';
import { IDbValidationSessionInfoModel } from '@rift/service/validation/models/database/syncsession/IDbValidationSessionInfo.Model';
import { SyncStateEnum } from '@rift/service/validation/models/SyncState.Enum';
import { IValidationRecordingModel } from '@rift/service/validation/models/ValidationRecording.Model';
import { IInitializeBaseRequest } from '@rift/service/validation/models/webworker/generic/IInitializeBase.Request';
import { IBookmark } from '@rift/service/validation/models/webworker/syncsession/IBookmark';
import { IUserCount } from '@rift/service/validation/models/webworker/syncsession/IUserCount';
import { ValidationFrameTypeEnum } from '@shared/enum/ValidationFrameType.Enum';
import { IndexedDBServiceBase } from '@shared/service/Indexeddb/IndexedDBServiceBase';
import { LoggingServiceLevel } from '@shared/service/logging/Logging.Service.Level';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IFrameData } from '@shared/webworker/IFrameData';
import { Observable, Observer, Subject } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { IDeviceModel } from '@rift/models/restapi/Device.Model';
import { IFrameModel } from '@rift/service/validation/models/database/syncrecording/Frame.Model';
import { ISyncedVideoSessionNodeModel, SyncedVideoSessionNodeModel } from '@rift/models/restapi/SyncedVideoSessionNode.Model';

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};


export interface IBaseParams {
    logLevel: LoggingServiceLevel;
    initializeData: IInitializeBaseRequest;
}

export interface IConvertParams extends IBaseParams {
}

export interface IDatabaseParams extends IBaseParams {
    indexedDBService: IndexedDBServiceBase;
    database: IDBDatabase;
}

export interface ISocketParams extends IBaseParams {
    socket: WebSocket;
    onSocketMessage: Subject<MessageEvent>;
}

export interface IXhrParams extends IBaseParams {
}

export class ValidationWorkerShared {
    public static convert_DbValidationSessionInfoModelToVideoIdentifier(params: IConvertParams, validationRecording: IValidationRecordingModel): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`convert_DbValidationSessionInfoModelToVideoIdentifier:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return {
            masterSerial: validationRecording.friendlySerial,
            videoSession: {
                id: validationRecording.id,
                startTime: validationRecording.startTime,
                endTime: validationRecording.endTime,
                frames: validationRecording.frames,
                bytes: validationRecording.bytes,
                timezoneOffsetMins: validationRecording.timezoneOffsetMins,
                isComplete: true,
                nodes: validationRecording.onNodes?.map(n =>
                    ({
                        serial : n.friendlySerial,
                        syncedVideoSessionData: {
                            bytes: n.bytes,
                            endTime: n.endTime,
                            frames: n.frames,
                            id: n.id,
                            startTime: n.startTime,
                            timezoneOffsetMins: n.timezoneOffsetMins
                        }
                    } as ISyncedVideoSessionNodeModel)) ?? []
            } as ISyncedVideoSessionModel,
        } as IValidationVideoIdentifierModel;
    }

    public static convert_FrameDataToIBookmark(params: IConvertParams, data: IFrameData, session: IDbValidationSessionInfoModel): IBookmark {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`convert_FrameDataToIBookmark:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return {
            frameNumber: data.frameNumber,
            comments: !isNullOrUndefined(data.sessionBookmarks) ? data.sessionBookmarks.map(i => i.Comment) : [],
            syncState: SyncStateEnum.ok,
            recordingId: session.recordingId,
            sessionInfoId: session.id,
        } as IBookmark;
    }

    public static convert_FrameDataToIUserCount(params: IConvertParams, frameData: IFrameData, session: IDbValidationSessionInfoModel): IUserCount {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`convert_FrameDataToIUserCount:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return {
            frameNumber: frameData.frameNumber,
            counts: !isNullOrUndefined(frameData.userCounts) ? frameData.userCounts : ArrayUtility.fill(0, new Array<number>(32)),
            recordingId: session.recordingId,
            sessionInfoId: session.id,
        } as IUserCount;
    }

    public static convert_ValidationDataRequestMessageValidationSession(params: IConvertParams, session: IDbValidationSessionInfoModel): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`convert_ValidationDataRequestMessageValidationSession:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return {
            CreationDate: session.creationDate,
            State: session.state,
            ModificationDate: session.modificationDate,
            RegistersToValidate: session.registersToValidate,
            Notes: session.notes,
            Name: session.name,
            Username: session.username,
            Partial: session.partial,
            StartFrame: session.startFrame,
            EndFrame: session.endFrame,
            Report: isNullOrUndefined(session.report) ? null : {
                Lines: session.report.lines.map(i => ({
                        SystemCount: i.systemCount,
                        UserCount: i.userCount,
                        Accuracy: i.accuracy,
                        Name: i.name,
                        Mode: i.mode,
                    })),
                Nodes: session.report.nodes.map(i => ({
                        DeviceName: i.deviceName,
                        SerialNumber: i.serialNumber,
                        DeviceID: i.deviceID,
                    })),
                SiteName: session.report.siteName,
                SiteId: session.report.siteId,
                TimeZone: session.report.timeZone,
                SerialNumber: session.report.serialNumber,
                CreationDate: session.report.creationDate,
                DateRecorded: session.report.dateRecorded,
                Duration: session.report.duration,
            },
            Options: isNullOrUndefined(session.options) ? null : session.options.map(i => ({
                    Name: i.name,
                    Enabled: i.enabled,
                    Locked: i.locked,
                })),
        };
    }

    public static convert_ValidationDataRequestMessageVideoSession(params: IConvertParams, videoSession: ISyncedVideoSessionModel): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`convert_ValidationDataRequestMessageVideoSession:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return {
            Id: videoSession.id,
            StartTime: DateTimeNonMomentUtility.toRestApiDateTime(videoSession.startTime),
            EndTime: DateTimeNonMomentUtility.toRestApiDateTime(videoSession.endTime),
            Frames: videoSession.frames,
            Bytes: videoSession.bytes,
            TimezoneOffsetMins: videoSession.timezoneOffsetMins,
            IsComplete: videoSession.isComplete,
            Nodes: videoSession.nodes
        };
    }

    public static convert_Database_RecordingModelDatesToValueOf(model: IRecordingModel): any {
        const dbRecord = model as any;
        if (!isNullOrUndefined(model)) {
            dbRecord.startTime = model.startTime.valueOf();
            dbRecord.endTime = model.endTime.valueOf();
            dbRecord.created = model.created.valueOf();
        }
        return dbRecord;
    }

    public static convert_Database_RecordingModelValueOfToDates(dbRecord: any): IRecordingModel {
        if (!isNullOrUndefined(dbRecord)) {
            dbRecord.startTime = new Date(dbRecord.startTime);
            dbRecord.endTime = new Date(dbRecord.endTime);
            dbRecord.created = new Date(dbRecord.created);
        }
        return dbRecord as IRecordingModel;
    }

    public static database_Open(params: IDatabaseParams): Observable<IDBDatabase> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Open:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return params.indexedDBService.open(params.initializeData.databaseName, params.initializeData.databaseVersion, null).pipe(
            map(database => database),
        );
    }

    public static database_Recording_AddBookmark(params: IDatabaseParams, bookmark: IBookmark): Observable<IBookmark> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Recording_AddBookmark:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.bookmarkFramesStoreConfig;
        return params.indexedDBService.addRecord<IBookmark>(params.database, store.storeName, bookmark).pipe(
            map(id => {
                bookmark.id = id;
                return bookmark;
            })
        );
    }

    public static database_Recording_AddRecording(params: IDatabaseParams, recording: IRecordingModel): Observable<IRecordingModel> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Recording_AddRecording:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.recordingStoreConfig;
        const dbRecord = this.convert_Database_RecordingModelDatesToValueOf(recording);
        return params.indexedDBService.addRecord<IRecordingModel>(params.database, store.storeName, dbRecord).pipe(
            map(id => {
                recording.id = id;
                return this.convert_Database_RecordingModelValueOfToDates(recording);
            }),
        );
    }

    public static database_Recording_UpdateRecording(params: IDatabaseParams, recording: IRecordingModel): Observable<IRecordingModel> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Recording_AddRecording:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.recordingStoreConfig;
        const dbRecord = this.convert_Database_RecordingModelDatesToValueOf(recording);
        return params.indexedDBService.updateOrAddRecord<IRecordingModel>(params.database, store.storeName, dbRecord).pipe(
            map(() => this.convert_Database_RecordingModelValueOfToDates(recording)),
        );
    }

    public static database_Recording_DeleteBookmark(params: IDatabaseParams, bookmarkId: number): Observable<number> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Recording_DeleteBookmark:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.bookmarkFramesStoreConfig;
        return params.indexedDBService.deleteRecord(params.database, store.storeName, bookmarkId);
    }

    public static database_Recording_GetBookmarkById(params: IDatabaseParams, bookmarkId: number): Observable<IBookmark> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Recording_GetBookmarkById:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.bookmarkFramesStoreConfig;
        return params.indexedDBService.getRecordByKey<IBookmark>(params.database, store.storeName, bookmarkId);
    }

    public static database_Devices_GetRecordingById(params: IDatabaseParams, recordingId: number): Observable<IFrameModel[]> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Devices_GetRecordingById:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.deviceFramesStoreConfig;
        return params.indexedDBService.getRecordsByIndex<IFrameModel>(params.database, store.storeName, store.recordingIdIndex, recordingId);
    }

    public static database_GlobalData_GetRecordingById(params: IDatabaseParams, recordingId: number): Observable<IFrameModel[]> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_GlobalData_GetRecordingById:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.globalDataFramesStoreConfig;
        return params.indexedDBService.getRecordsByIndex<IFrameModel>(params.database, store.storeName, store.recordingIdIndex, recordingId);
    }

    public static database_Lines_GetRecordingById(params: IDatabaseParams, recordingId: number): Observable<IFrameModel[]> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Lines_GetRecordingById:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.lineFramesStoreConfig;
        return params.indexedDBService.getRecordsByIndex<IFrameModel>(params.database, store.storeName, store.recordingIdIndex, recordingId);
    }

    public static database_Polygons_GetRecordingById(params: IDatabaseParams, recordingId: number): Observable<IFrameModel[]> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Polygons_GetRecordingById:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.polygonFramesStoreConfig;
        return params.indexedDBService.getRecordsByIndex<IFrameModel>(params.database, store.storeName, store.recordingIdIndex, recordingId);
    }

    public static database_Registers_GetRecordingById(params: IDatabaseParams, recordingId: number): Observable<IFrameModel[]> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Registers_GetRecordingById:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.registerFramesStoreConfig;
        return params.indexedDBService.getRecordsByIndex<IFrameModel>(params.database, store.storeName, store.recordingIdIndex, recordingId);
    }

    public static database_TimeData_GetRecordingById(params: IDatabaseParams, recordingId: number): Observable<IFrameModel[]> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_TimeData_GetRecordingById:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.timeDataFramesStoreConfig;
        return params.indexedDBService.getRecordsByIndex<IFrameModel>(params.database, store.storeName, store.recordingIdIndex, recordingId);
    }

    public static database_VideoSettings_GetRecordingById(params: IDatabaseParams, recordingId: number): Observable<IFrameModel[]> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_VideoSettings_GetRecordingById:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.videoSettingFramesStoreConfig;
        return params.indexedDBService.getRecordsByIndex<IFrameModel>(params.database, store.storeName, store.recordingIdIndex, recordingId);
    }

    public static database_Recording_GetRecordingById(params: IDatabaseParams, recordingId: number): Observable<IRecordingModel> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Recording_GetRecordingById:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.recordingStoreConfig;
        return params.indexedDBService.getRecordByKey<IRecordingModel>(params.database, store.storeName, recordingId).pipe(
            map((record) => this.convert_Database_RecordingModelValueOfToDates(record))
        );
    }

    public static database_Recording_DeleteRecordingBySerialSessionId(params: IDatabaseParams, serial: string, sessionId: number, startTime: Date): Observable<number> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Recording_DeleteRecordingBySerialSessionId:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.recordingStoreConfig;
        return params.indexedDBService.deleteRecordsByIndex(params.database, store.storeName, store.friendlySerialSessionIndex, [serial, sessionId, startTime.valueOf()]);
    }

    public static database_Recording_GetRecordingBySerialSessionId(params: IDatabaseParams, serial: string, sessionId: number, startTime: Date): Observable<IRecordingModel> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Recording_GetRecordingBySerialSessionId:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.recordingStoreConfig;
        return params.indexedDBService.getRecordByIndex<IRecordingModel>(params.database, store.storeName, store.friendlySerialSessionIndex, [serial, sessionId, startTime.valueOf()]).pipe(
            map((record) => this.convert_Database_RecordingModelValueOfToDates(record))
        );
    }

    public static database_Recording_GetTargetFramesBlockCountByRecordingId(params: IDatabaseParams, recordingId: number, blockIndex: number): Observable<number> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Recording_GetTargetFramesBlockCountByRecordingId:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.targetFramesStoreConfig;
        return params.indexedDBService.getRecordCountByIndex(params.database, store.storeName, store.recordingIdBlockIndex, [recordingId, blockIndex]);
    }

    public static database_Recording_GetVideoFramesBlockCountByRecordingId(params: IDatabaseParams, recordingId: number, blockIndex: number): Observable<number> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Recording_GetVideoFramesBlockCountByRecordingId:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.videoFramesStoreConfig;
        return params.indexedDBService.getRecordCountByIndex(params.database, store.storeName, store.recordingIdBlockIndex, [recordingId, blockIndex]);
    }

    public static database_Recording_GetSyncFramesBlockCountByRecordingId(params: IDatabaseParams, recordingId: number, blockIndex: number): Observable<number> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Recording_GetSyncFramesBlockCountByRecordingId:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.syncFramesStoreConfig;
        return params.indexedDBService.getRecordCountByIndex(params.database, store.storeName, store.recordingIdBlockIndex, [recordingId, blockIndex]);
    }

    public static database_Recording_UpdateBookmark(params: IDatabaseParams, bookmark: IBookmark): Observable<boolean> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Recording_UpdateBookmark:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.bookmarkFramesStoreConfig;
        return params.indexedDBService.updateOrAddRecord<IBookmark>(params.database, store.storeName, bookmark);
    }

    public static database_Session_AddBookmark(params: IDatabaseParams, bookmark: IBookmark): Observable<IBookmark> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_AddBookmark:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseSessionBookmarkConfig;
        return params.indexedDBService.addRecord<IBookmark>(params.database, store.storeName, bookmark).pipe(
            map(
                id => {
                    bookmark.id = id;
                    return bookmark;
                }
            )
        );
    }

    public static database_Session_AddCount(params: IDatabaseParams, count: IUserCount): Observable<IUserCount> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_AddCount:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseUserCountConfig;
        return params.indexedDBService.addRecord<IUserCount>(params.database, store.storeName, count).pipe(
            map(
                id => {
                    count.id = id;
                    return count;
                }
            )
        );
    }

    public static database_Session_AddSession(params: IDatabaseParams, sessionInfo: IDbValidationSessionInfoModel): Observable<IDbValidationSessionInfoModel> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_AddSession:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseSessionInfoConfig;
        sessionInfo.syncState = SyncStateEnum.notSynced;
        return params.indexedDBService.addRecord(params.database, store.storeName, sessionInfo).pipe(
            map(
                id => {
                    sessionInfo.id = id;
                    return sessionInfo;
                }
            )
        );
    }

    public static database_Session_DeleteAllBookmarksForSession(params: IDatabaseParams, sessionInfoId: number): Observable<number> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_DeleteAllBookmarksForSession:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseSessionBookmarkConfig;
        return params.indexedDBService.deleteRecordsByIndex(params.database, store.storeName, store.sessionInfoIdIndex, sessionInfoId);
    }

    public static database_Session_DeleteAllCountsForSession(params: IDatabaseParams, sessionInfoId: number): Observable<number> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_DeleteAllCountsForSession:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseUserCountConfig;
        return params.indexedDBService.deleteRecordsByIndex(params.database, store.storeName, store.sessionInfoIdIndex, sessionInfoId);
    }

    public static database_Session_DeleteBookmark(params: IDatabaseParams, bookmarkId: number): Observable<number> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_DeleteBookmark:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseSessionBookmarkConfig;
        return params.indexedDBService.deleteRecord(params.database, store.storeName, bookmarkId);
    }

    public static database_Session_DeleteCount(params: IDatabaseParams, countId: number): Observable<number> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_DeleteCount:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseUserCountConfig;
        return params.indexedDBService.deleteRecord(params.database, store.storeName, countId);
    }

    public static database_Session_DeleteSession(params: IDatabaseParams, sessionInfo: IDbValidationSessionInfoModel): Observable<number> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_DeleteSession:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return params.indexedDBService.getRecordByIndex<IDbValidationSessionInfoModel>(params.database, params.initializeData.databaseSessionInfoConfig.storeName, params.initializeData.databaseSessionInfoConfig.recordingIdCreationDateIndex, [sessionInfo.recordingId, sessionInfo.creationDate]).pipe(
            flatMap(dbSession => params.indexedDBService.deleteRecord(params.database, params.initializeData.databaseSessionInfoConfig.storeName, dbSession.id).pipe(
                    flatMap((sessionCount) => params.indexedDBService.deleteRecordsByIndex(params.database, params.initializeData.databaseSessionBookmarkConfig.storeName, params.initializeData.databaseSessionBookmarkConfig.sessionInfoIdIndex, dbSession.id).pipe(
                            flatMap((bookmarkCount) => params.indexedDBService.deleteRecordsByIndex(params.database, params.initializeData.databaseUserCountConfig.storeName, params.initializeData.databaseUserCountConfig.sessionInfoIdIndex, dbSession.id).pipe(
                                    map((userCountCount) => sessionCount + bookmarkCount + userCountCount)
                                ))
                        ))
                ))
        );
    }

    public static database_Session_GetBookmarkById(params: IDatabaseParams, bookmarkId: number): Observable<IBookmark> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_GetBookmarkById:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseSessionBookmarkConfig;
        return params.indexedDBService.getRecordByKey<IBookmark>(params.database, store.storeName, bookmarkId);
    }

    public static database_Session_GetBookmarksForSession(params: IDatabaseParams, sessionInfoId: number): Observable<Array<IBookmark>> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_GetBookmarksForSession:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseSessionBookmarkConfig;
        return params.indexedDBService.getRecordsByIndex<IBookmark>(params.database, store.storeName, store.sessionInfoIdIndex, sessionInfoId);
    }

    public static database_Session_GetCountsForSession(params: IDatabaseParams, sessionInfoId: number): Observable<Array<IUserCount>> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_GetCountsForSession:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseUserCountConfig;
        return params.indexedDBService.getRecordsByIndex<IUserCount>(params.database, store.storeName, store.sessionInfoIdIndex, sessionInfoId);
    }

    public static database_Session_GetSessionInfoForRecordingIdCreationDate(params: IDatabaseParams, recordingId: number, creationDate: string): Observable<IDbValidationSessionInfoModel> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_GetSessionInfoForRecordingIdCreationDate:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseSessionInfoConfig;
        return params.indexedDBService.getRecordByIndex<IDbValidationSessionInfoModel>(params.database, store.storeName, store.recordingIdCreationDateIndex, [recordingId, creationDate]);
    }

    public static database_Session_GetSessionInfosForRecordingId(params: IDatabaseParams, recordingId: number): Observable<Array<IDbValidationSessionInfoModel>> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_GetSessionInfosForRecordingId:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseSessionInfoConfig;
        return params.indexedDBService.getRecordsByIndex<IDbValidationSessionInfoModel>(params.database, store.storeName, store.recordingIdIndex, recordingId);
    }

    public static database_Session_UpdateBookmark(params: IDatabaseParams, bookmark: IBookmark): Observable<boolean> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_UpdateBookmark:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseSessionBookmarkConfig;
        return params.indexedDBService.updateOrAddRecord(params.database, store.storeName, bookmark);
    }

    public static database_Session_UpdateCount(params: IDatabaseParams, count: IUserCount): Observable<boolean> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_UpdateCount:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseUserCountConfig;
        return params.indexedDBService.updateOrAddRecord(params.database, store.storeName, count);
    }

    public static database_Session_UpdateSession(params: IDatabaseParams, sessionInfo: IDbValidationSessionInfoModel): Observable<IDbValidationSessionInfoModel> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`database_Session_UpdateSession:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const store = params.initializeData.databaseSessionInfoConfig;
        return params.indexedDBService.getRecordByIndex<IDbValidationSessionInfoModel>(params.database, store.storeName, store.recordingIdCreationDateIndex, [sessionInfo.recordingId, sessionInfo.creationDate]).pipe(
            flatMap(dbSession => {
                dbSession.state = sessionInfo.state;
                dbSession.syncState = sessionInfo.syncState;
                return params.indexedDBService.updateOrAddRecord<IDbValidationSessionInfoModel>(params.database, store.storeName, dbSession).pipe(
                    map(() => dbSession)
                );
            })
        );
    }

    public static errorCheck(logLevel: LoggingServiceLevel, error: Error, location: string, message: string): string {
        const errorMessage = `Error in ${location} ${message} : ${!isNullOrUndefined(error) && !isNullOrUndefined(error.message) ? error.message : 'No Error Message'}`;
        if (logLevel >= LoggingServiceLevel.Error) { console.error(`errorCheck:${errorMessage}`); }
        return errorMessage;
    }

    public static socket_Message_Base(params: ISocketParams, requestId: number): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Message_Base:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const message: any = {
            id: requestId,
            token: params.initializeData.connectionToken,
            secureToken: params.initializeData.secureToken,
            masterSerial: params.initializeData.hostFriendlySerial,
        };

        return message;
    }

    public static socket_Message_Bookmark(params: ISocketParams, global: boolean, requestId: number, validationRecording: IValidationRecordingModel, sessionInfo?: IDbValidationSessionInfoModel, frameNumber?: number, comment?: string, add?: boolean, removeAll?: boolean): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Message_Bookmark:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const videoIdentifier = ValidationWorkerShared.convert_DbValidationSessionInfoModelToVideoIdentifier(params, validationRecording);

        const message: any = {
            ...ValidationWorkerShared.socket_Message_Base(params, requestId),
            packetType: 'validation_data_bookmark',
            videoSession: ValidationWorkerShared.convert_ValidationDataRequestMessageVideoSession(params, videoIdentifier.videoSession),
            global,
        };

        if (!isNullOrUndefined(sessionInfo)) {
            message.validationSession = ValidationWorkerShared.convert_ValidationDataRequestMessageValidationSession(params, sessionInfo);
        }

        if (!isNullOrUndefined(removeAll) && removeAll === true) {
            message.removeAll = true;
        }

        if (!isNullOrUndefined(add)) {
            message.add = add;
        }

        if (!isNullOrUndefined(comment)) {
            message.comment = comment;
        }

        if (!isNullOrUndefined(frameNumber)) {
            message.frameNumber = frameNumber;
        }

        return message;
    }

    public static socket_Message_DataRequest(params: ISocketParams, requestId: number, startFrame: number, endFrame: number, requiredFrameTypes: Array<ValidationFrameTypeEnum>, validationRecording: IValidationRecordingModel, sessionInfo?: IDbValidationSessionInfoModel): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Message_DataRequest:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const videoIdentifier = ValidationWorkerShared.convert_DbValidationSessionInfoModelToVideoIdentifier(params, validationRecording);

        const message: any = {
            ...ValidationWorkerShared.socket_Message_Base(params, requestId),
            packetType: 'validation_data_request_message',
            startFrame,
            endFrame,
            requiredFrameTypes,
            videoSession: ValidationWorkerShared.convert_ValidationDataRequestMessageVideoSession(params, videoIdentifier.videoSession),
            serial: isNullOrUndefined(validationRecording.friendlySerial) ? null : validationRecording.friendlySerial,
        };

        if (!isNullOrUndefined(sessionInfo)) {
            message.validationSession = ValidationWorkerShared.convert_ValidationDataRequestMessageValidationSession(params, sessionInfo);
        }

        return message;
    }

    public static socket_Message_UserCount(params: ISocketParams, requestId: number, validationRecording: IValidationRecordingModel, sessionInfo: IDbValidationSessionInfoModel, registerIndex?: number, increment?: boolean, frameNumber?: number, remove?: boolean, removeAll?: boolean): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Message_UserCount:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        const videoIdentifier = ValidationWorkerShared.convert_DbValidationSessionInfoModelToVideoIdentifier(params, validationRecording);

        const message: any = {
            ...ValidationWorkerShared.socket_Message_Base(params, requestId),
            packetType: 'validation_data_user_count',
            validationSession: ValidationWorkerShared.convert_ValidationDataRequestMessageValidationSession(params, sessionInfo),
            videoSession: ValidationWorkerShared.convert_ValidationDataRequestMessageVideoSession(params, videoIdentifier.videoSession),
        };

        if (!isNullOrUndefined(removeAll) && removeAll === true) {
            message.removeAll = true;
        }

        if (!isNullOrUndefined(remove) && remove === true) {
            message.remove = true;
        }

        if (!isNullOrUndefined(frameNumber)) {
            message.frameNumber = frameNumber;
        }

        if (!isNullOrUndefined(increment)) {
            message.increase = increment;
        }

        if (!isNullOrUndefined(registerIndex)) {
            message.registerNumber = registerIndex;
        }

        return message;
    }

    public static socket_Ping_Pong(messageSubject: Subject<MessageEvent>, messageEvent: MessageEvent, params: ISocketParams): void {
        const socketResponse = JSON.parse(messageEvent.data);
        if (socketResponse.packetType === 'ping') {
            const pong = { packetType: 'pong' };
            params.socket.send(JSON.stringify(pong));
        } else {
            messageSubject.next(messageEvent);
        }
    }

    public static socket_Open(params: ISocketParams, requestId: number): Observable<WebSocket> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Open:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return new Observable((observer: Observer<WebSocket>) => {
            params.socket.onopen = () => {
                params.socket.onmessage = (messageEvent: MessageEvent) => {
                    params.socket.onerror = null;

                    if (messageEvent.type === 'message') {
                        const reply = JSON.parse(messageEvent.data);
                        if (reply.status === 'ok' && reply.id === requestId) {
                            observer.next(params.socket);
                            observer.complete();
                        } else if (reply.status !== 'ok' && reply.id === requestId) {
                            ValidationWorkerShared.errorCheck(params.logLevel, null, 'socket_open', `websocket device connection failed ${reply.status}`);
                            observer.error(new Error('Websocket device connection failed'));
                        }
                    }
                };

                params.socket.onerror = (event: Event) => {
                    params.socket.onopen = null;
                    params.socket.onmessage = null;
                    params.socket.onerror = null;
                    ValidationWorkerShared.errorCheck(params.logLevel, null, 'socket_open', `websocket connection failed`);
                    observer.error(new Error('Websocket connection failed'));
                };

                const request = {
                    packetType: 'connect',
                    id: requestId,
                    token: params.initializeData.connectionToken,
                    secureToken: params.initializeData.secureToken,
                };
                params.socket.send(JSON.stringify(request));
            };
        });
    }

    public static socket_Recording_AddBookmark(params: ISocketParams, requestId: number, comment: string, frameNumber: number, validationRecording: IValidationRecordingModel): Observable<boolean> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Recording_AddBookmark:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return ValidationWorkerShared.socket_Send_Command(
            params,
            ValidationWorkerShared.socket_Message_Bookmark(params, true, requestId, validationRecording, null, frameNumber, comment, true),
            'general_response',
        );
    }

    public static socket_Recording_DeleteBookmark(params: ISocketParams, requestId: number, comment: string, frameNumber: number, validationRecording: IValidationRecordingModel): Observable<boolean> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Recording_DeleteBookmark:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return ValidationWorkerShared.socket_Send_Command(
            params,
            ValidationWorkerShared.socket_Message_Bookmark(params, true, requestId, validationRecording, null, frameNumber, comment, false),
            'general_response',
        );
    }

    public static socket_Send_Command(params: ISocketParams, command: { id: number }, expectedPacketType: string): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            const sub = params.onSocketMessage.subscribe(
                (messageEvent: MessageEvent) => {
                    if (messageEvent.type === 'message') {
                        const socketResponse = JSON.parse(messageEvent.data);
                        if (socketResponse.packetType === expectedPacketType && socketResponse.id === command.id) {
                            if (socketResponse.status === 'ok') {
                                observer.next(true);
                                observer.complete();
                                if (!isNullOrUndefined(sub)) {
                                    sub.unsubscribe();
                                }
                            } else {
                                observer.error(new Error(`socket_Send_Command "${ValidationWorkerShared.safePrintArguments(command)}" failed`));
                                if (!isNullOrUndefined(sub)) {
                                    sub.unsubscribe();
                                }
                            }
                        } else if (socketResponse.type === 'error') {
                            observer.error(new Error(`socket_Send_Command "${ValidationWorkerShared.safePrintArguments(command)}" failed`));
                            if (!isNullOrUndefined(sub)) {
                                sub.unsubscribe();
                            }
                        }
                    }
                },
                (error) => {
                    observer.error(error);
                    if (!isNullOrUndefined(sub)) {
                        sub.unsubscribe();
                    }
                },
            );

            params.socket.send(JSON.stringify(command));
        });
    }

    public static socket_Send_Request<TResponse>(params: ISocketParams, request: { id: number }, expectedPacketType: string): Observable<TResponse> {
        return new Observable((observer: Observer<TResponse>) => {
            const sub = params.onSocketMessage.subscribe(
                (messageEvent: MessageEvent) => {
                    if (messageEvent.type === 'message') {
                        const socketResponse = JSON.parse(messageEvent.data);
                        if (socketResponse.packetType === expectedPacketType && socketResponse.id === request.id) {
                            if (socketResponse.type === 'data') {
                                observer.next(socketResponse.data as TResponse);
                            } else if (socketResponse.type === 'final') {
                                observer.complete();
                                if (!isNullOrUndefined(sub)) {
                                    sub.unsubscribe();
                                }
                            }
                        } else if (socketResponse.type === 'error') {
                            observer.error(new Error(`socket_Send_Request "${ValidationWorkerShared.safePrintArguments(request)}" failed`));
                            if (!isNullOrUndefined(sub)) {
                                sub.unsubscribe();
                            }
                        }
                    }
                },
                error => {
                    observer.error(error);
                    if (!isNullOrUndefined(sub)) {
                        sub.unsubscribe();
                    }
                }
            );

            params.socket.send(JSON.stringify(request));
        });
    }

    public static socket_Session_AddBookmark(params: ISocketParams, requestId: number, comment: string, frameNumber: number, validationRecording: IValidationRecordingModel, sessionInfo: IDbValidationSessionInfoModel): Observable<boolean> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Session_AddBookmark:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return ValidationWorkerShared.socket_Send_Command(
            params,
            ValidationWorkerShared.socket_Message_Bookmark(params, false, requestId, validationRecording, sessionInfo, frameNumber, comment, true),
            'general_response',
        );
    }

    public static socket_Session_AddCount(params: ISocketParams, requestId: number, registerIndex: number, increment: boolean, frameNumber: number, validationRecording: IValidationRecordingModel, sessionInfo: IDbValidationSessionInfoModel): Observable<boolean> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Session_AddCount:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return ValidationWorkerShared.socket_Send_Command(
            params,
            ValidationWorkerShared.socket_Message_UserCount(params, requestId, validationRecording, sessionInfo, registerIndex, increment, frameNumber),
            'validation_data_response_user_count',
        );
    }

    public static socket_Session_DeleteAllBookmarksForSession(params: ISocketParams, requestId: number, validationRecording: IValidationRecordingModel, sessionInfo: IDbValidationSessionInfoModel): Observable<boolean> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Session_DeleteAllBookmarksForSession:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return ValidationWorkerShared.socket_Send_Command(
            params,
            ValidationWorkerShared.socket_Message_Bookmark(params, false, requestId, validationRecording, sessionInfo, null, null, null, true),
            'general_response',
        );
    }

    public static socket_Session_DeleteAllUserCountForSession(params: ISocketParams, requestId: number, validationRecording: IValidationRecordingModel, sessionInfo: IDbValidationSessionInfoModel): Observable<boolean> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Session_DeleteAllUserCountForSession:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return ValidationWorkerShared.socket_Send_Command(
            params,
            ValidationWorkerShared.socket_Message_UserCount(params, requestId, validationRecording, sessionInfo, null, null, null, null, true),
            'validation_data_response_user_count',
        );
    }

    public static socket_Session_DeleteBookmark(params: ISocketParams, requestId: number, comment: string, frameNumber: number, validationRecording: IValidationRecordingModel, sessionInfo: IDbValidationSessionInfoModel): Observable<boolean> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Session_DeleteBookmark:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return ValidationWorkerShared.socket_Send_Command(
            params,
            ValidationWorkerShared.socket_Message_Bookmark(params, false, requestId, validationRecording, sessionInfo, frameNumber, comment, false),
            'general_response',
        );
    }

    public static socket_Session_DeleteUserCount(params: ISocketParams, requestId: number, registerIndex: number, frameNumber: number, validationRecording: IValidationRecordingModel, sessionInfo: IDbValidationSessionInfoModel): Observable<boolean> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Session_DeleteUserCount:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return ValidationWorkerShared.socket_Send_Command(
            params,
            ValidationWorkerShared.socket_Message_UserCount(params, requestId, validationRecording, sessionInfo, registerIndex, null, frameNumber, true),
            'validation_data_response_user_count',
        );
    }

    public static socket_Session_ValidationDataRequest(params: ISocketParams, requestId: number, validationRecording: IValidationRecordingModel, sessionInfo: IDbValidationSessionInfoModel): Observable<IFrameData> {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`socket_Session_ValidationDataRequest:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return ValidationWorkerShared.socket_Send_Request<IFrameData>(
            params,
            ValidationWorkerShared.socket_Message_DataRequest(params, requestId, 0, validationRecording.frames, [ValidationFrameTypeEnum.userCounts, ValidationFrameTypeEnum.bookmarks], validationRecording, sessionInfo),
            'validation_data_response_message',
        );
    }

    public static xhr_NewValidationSessionDetailsModelToPost(params: IXhrParams, newSession: INewValidationSessionDetailsModel, videoIdentifier: IValidationVideoIdentifierModel): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`xhr_NewValidationSessionDetailsModelToPost:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return isNullOrUndefined(newSession) ? null : {
            ...ValidationWorkerShared.xhr_ValidationVideoIdentifierModelToPost(params, videoIdentifier),
            Options: ValidationWorkerShared.xhr_ValidationSessionOptionModelToPost(params, newSession.options),
            RegistersToValidate: newSession.registersToValidate,
            Notes: newSession.notes,
            Name: newSession.name,
            Username: newSession.username,
            Partial: isNullOrUndefined(newSession.partial) ? false : newSession.partial,
            StartFrame: newSession.startFrame,
            EndFrame: newSession.endFrame,
        };
    }

    public static xhr_ResponseToIValidationSessionInfoModel(params: IXhrParams, data: any): IDbValidationSessionInfoModel {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`xhr_ResponseToIValidationSessionInfoModel:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return {
            state: data.State,
            creationDate: data.CreationDate,
            modificationDate: data.ModificationDate,
            registersToValidate: data.RegistersToValidate,
            notes: data.Notes,
            name: data.Name,
            username: data.Username,
            partial: data.Partial,
            startFrame: data.StartFrame,
            endFrame: data.EndFrame,
            report: isNullOrUndefined(data.Report) ? null : {
                lines: data.Report.Lines.map(i => ({
                        systemCount: i.SystemCount,
                        userCount: i.UserCount,
                        accuracy: i.Accuracy,
                        name: i.Name,
                        mode: i.Mode,
                    } as IValidationReportLineModel)),
                nodes: data.Report.Nodes.map(i => ({
                        deviceName: i.DeviceName,
                        serialNumber: i.SerialNumber,
                        deviceID: i.DeviceID,
                    } as IValidationReportNodeModel)),
                siteName: data.Report.SiteName,
                siteId: data.Report.SiteId,
                timeZone: data.Report.TimeZone,
                serialNumber: data.Report.SerialNumber,
                creationDate: data.Report.CreationDate,
                dateRecorded: data.Report.DateRecorded,
                duration: data.Report.Duration,
            } as IValidationReportModel,
            options: isNullOrUndefined(data.Options) ? null : data.Options.map(i => ({
                    name: i.Name,
                    enabled: i.Enabled,
                    locked: i.Locked,
                } as IValidationSessionOptionModel)),
        } as IDbValidationSessionInfoModel;
    }

    public static xhr_ValidationReportLineModelToPost(params: IXhrParams, lines: Array<IValidationReportLineModel>): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`xhr_ValidationReportLineModelToPost:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return isNullOrUndefined(lines) ? null : lines.map(i => ({
                SystemCount: i.systemCount,
                UserCount: i.userCount,
                Accuracy: i.accuracy,
                Name: i.name,
                Mode: i.mode,
            }));
    }

    public static xhr_ValidationReportModelToPost(params: IXhrParams, report: IValidationReportModel): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`xhr_ValidationReportModelToPost:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return isNullOrUndefined(report) ? null : {
            Lines: ValidationWorkerShared.xhr_ValidationReportLineModelToPost(params, report.lines),
            Nodes: ValidationWorkerShared.xhr_ValidationReportNodeModelToPost(params, report.nodes),
            SiteName: report.siteName,
            SiteId: report.siteId,
            TimeZone: report.timeZone,
            SerialNumber: report.serialNumber,
            CreationDate: report.creationDate,
            DateRecorded: report.dateRecorded,
            Duration: report.duration,
        };
    }

    public static xhr_ValidationReportNodeModelToPost(params: IXhrParams, nodes: Array<IValidationReportNodeModel>): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`xhr_ValidationReportNodeModelToPost:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return isNullOrUndefined(nodes) ? null : nodes.map(i => ({
                DeviceName: i.deviceName,
                SerialNumber: i.serialNumber,
                DeviceID: i.deviceID,
            }));
    }

    public static xhr_ValidationSessionIdentifierToPost(params: IXhrParams, session: IDbValidationSessionInfoModel, videoIdentifier: IValidationVideoIdentifierModel): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`xhr_ValidationSessionIdentifierToPost:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return isNullOrUndefined(session) ? null : {
            ...ValidationWorkerShared.xhr_ValidationVideoIdentifierModelToPost(params, videoIdentifier),
            SessionInfo: {
                Options: ValidationWorkerShared.xhr_ValidationSessionOptionModelToPost(params, session.options),
                CreationDate: session.creationDate,
                ModificationDate: session.modificationDate,
                RegistersToValidate: session.registersToValidate,
                Notes: session.notes,
                Name: session.name,
                Username: session.username,
                Partial: isNullOrUndefined(session.partial) ? false : session.partial,
                StartFrame: session.startFrame,
                EndFrame: session.endFrame,
                Report: ValidationWorkerShared.xhr_ValidationReportModelToPost(params, session.report),
            }
        };
    }

    public static xhr_ValidationSessionOptionModelToPost(params: IXhrParams, options: Array<IValidationSessionOptionModel>): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`xhr_ValidationSessionOptionModelToPost:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return isNullOrUndefined(options) ? null : options.map(i => ({
                Locked: i.locked,
                Name: i.name,
                Enabled: i.enabled,
            }));
    }

    public static xhr_ValidationSessionUpdatedStateToPost(params: IXhrParams, session: IDbValidationSessionInfoModel, videoIdentifier: IValidationVideoIdentifierModel): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`xhr_ValidationSessionUpdatedStateToPost:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return isNullOrUndefined(session) ? null : {
            State: session.state,
            Report: ValidationWorkerShared.xhr_ValidationReportModelToPost(params, session.report),
            ...ValidationWorkerShared.xhr_ValidationSessionIdentifierToPost(params, session, videoIdentifier),
        };
    }

    public static xhr_ValidationVideoIdentifierModelToPost(params: IXhrParams, videoIdentifier: IValidationVideoIdentifierModel): any {
        if (params.logLevel >= LoggingServiceLevel.Debug) { console.info(`xhr_ValidationVideoIdentifierModelToPost:${ValidationWorkerShared.safePrintArguments(arguments)}`); }

        return isNullOrUndefined(videoIdentifier) ? null : {
            MasterSerial: videoIdentifier.masterSerial,
            VideoSession: {
                StartTime: DateTimeNonMomentUtility.toRestApiDateTime(videoIdentifier.videoSession.startTime),
                EndTime: DateTimeNonMomentUtility.toRestApiDateTime(videoIdentifier.videoSession.endTime),
                IsComplete: videoIdentifier.videoSession.isComplete,
                Id: videoIdentifier.videoSession.id,
                Frames: videoIdentifier.videoSession.frames,
                Bytes: videoIdentifier.videoSession.bytes,
                TimezoneOffsetMins: videoIdentifier.videoSession.timezoneOffsetMins,
            }
        };
    }

    private static safePrintArguments(args: any): string{
        return JSON.stringify(args, getCircularReplacer());
    }
}
