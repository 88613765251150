import { Component, EventEmitter, Injector, HostBinding } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { IBottomInfoChildComponent } from '@shared/component/bottominfo/IBottomInfoChild.Component';
import { BottomInfoStateEnum } from '@shared/component/bottominfo/BottomInfo.State.Enum';
import { of, Observable } from 'rxjs';

/**
 * Basic message for bottom info component.
 *
 * @export
 * @class BottomInfoMessageComponent
 * @extends {RiftBaseComponent}
 * @implements {IBottomInfoChildComponent}
 */
@Component({
    selector: 'shr-bottominfo-message-component',
    templateUrl: './BottomInfo.Message.Component.html',
    styleUrls: ['./BottomInfo.Message.Component.scss'],
})
export class BottomInfoMessageComponent extends RiftBaseComponent implements IBottomInfoChildComponent {
    public close: EventEmitter<any> = new EventEmitter<any>();
    public stateChanged: EventEmitter<BottomInfoStateEnum> = new EventEmitter<BottomInfoStateEnum>();
    public instanceId: number = null;

    @HostBinding()
    public id: string = 'shr-bottominfo-message-component';

    /**
     * The message test.
     *
     * @type {string}
     * @memberof BottomInfoMessageComponent
     */
    public message: string = null;

    private _state: BottomInfoStateEnum = BottomInfoStateEnum.info;
    public get state(): BottomInfoStateEnum {
        return this._state;
    }
    public set state(value: BottomInfoStateEnum) {
        this._state = value;
        this.stateChanged.emit(this._state);
    }

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
    }

    public get notificationCount(): Observable<number> {
        return of(1);
    }
}
