import { IObjectStore } from '@shared/service/Indexeddb/IObjectStore';

export interface IDatabaseSessionInfoStore {
    readonly storeName: string;
    readonly storeDisplayName: string;
    readonly keyPath: string;
    readonly recordingIdIndex: string;
    readonly recordingIdCreationDateIndex: string;
}

export class DatabaseSessionInfoStore implements IObjectStore {
    public static readonly keyPath: string = 'id';
    public static readonly recordingIdCreationDateIndex: string = 'RecordingIdCreationDateIndex';
    public static readonly recordingIdIndex: string = 'RecordingIdIndex';
    public static readonly storeDisplayName: string = 'Session Info';
    public static readonly storeName: string = 'sessioninfo';

    public get storeName(): string {
        return DatabaseSessionInfoStore.storeName;
    };

    public static toInterface(): IDatabaseSessionInfoStore {
        return {
            storeName: this.storeName,
            keyPath: this.keyPath,
            recordingIdIndex: this.recordingIdIndex,
            recordingIdCreationDateIndex: this.recordingIdCreationDateIndex,
        } as IDatabaseSessionInfoStore;
    }

    public createStores(db: IDBDatabase): void {
        const store: IDBObjectStore = db.createObjectStore(DatabaseSessionInfoStore.storeName, { keyPath: DatabaseSessionInfoStore.keyPath, autoIncrement: true });
        store.createIndex(DatabaseSessionInfoStore.recordingIdIndex, 'recordingId', { unique: false });
        store.createIndex(DatabaseSessionInfoStore.recordingIdCreationDateIndex, ['recordingId', 'creationDate'], { unique: false });
    }
}
