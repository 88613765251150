import { Component, Inject, HostBinding } from '@angular/core';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * Data for the UnsavedChangesDialogComponent.
 *
 * @export
 * @class UnsavedChangesDialogDialogData
 */
export class UnsavedChangesDialogDialogData {
    /**
     * The message to show in the dialog
     *
     * @type {string}
     * @memberof UnsavedChangesDialogDialogData
     */
    public message: string = 'There are unsaved changes.';
    /**
     * The title of the dialog
     *
     * @type {string}
     * @memberof UnsavedChangesDialogDialogData
     */
    public title: string = 'Unsaved Changes';

    /**
     * Creates an instance of UnsavedChangesDialogDialogData.
     *
     * @param {string} [message] The message to show in the dialog
     * @memberof UnsavedChangesDialogDialogData
     */
    public constructor(message?: string) {
        if (!isNullOrUndefined(message)) {
            this.message = message;
        }
    }
}

/**
 * Results data for the UnsavedChangesDialogComponent.
 *
 * @export
 * @class UnsavedChangesDialogResult
 */
export class UnsavedChangesDialogResult {
    public save: boolean = false;
    public discard: boolean = false;
    public stay: boolean = false;

    public constructor() {
    }
}

/**
 * Shows a unsaved changes dialog.
 *
 * @export
 * @class UnsavedChangesDialogComponent
 * @extends {BaseComponent}
 */
@Component({
    selector: 'shr-unsaved-changes-dialog',
    templateUrl: './UnsavedChanges.Dialog.Component.html',
    styleUrls: ['./UnsavedChanges.Dialog.Component.scss']
})
export class UnsavedChangesDialogComponent {
    public static className: string = 'UnsavedChangesDialogComponent';

    @HostBinding()
    public id: string = 'shr-unsaved-changes-dialog';

    public isNullOrUndefined = isNullOrUndefined;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: UnsavedChangesDialogDialogData,
        private readonly _dialogRef: MatDialogRef<UnsavedChangesDialogComponent>) {

        this._dialogRef.disableClose = true;
    }

    public save(): void {
        const result = new UnsavedChangesDialogResult();
        result.save = true;
        this._dialogRef.close(result);
    }

    public discard(): void {
        const result = new UnsavedChangesDialogResult();
        result.discard = true;
        this._dialogRef.close(result);
    }

    public stay(): void {
        const result = new UnsavedChangesDialogResult();
        result.stay = true;
        this._dialogRef.close(result);
    }
}
