import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BluetoothModel } from '@rift/models/restapi/Bluetooth.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class RestApiBluetoothService extends RiftRestApiService {
    private _controller = 'bluetooth';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public getSettings(process?: ProcessMonitorServiceProcess): Observable<BluetoothModel> {
        return this.get<BluetoothModel>(`${this._controller}/settings`, BluetoothModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateSettings(settings: BluetoothModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<BluetoothModel, ResultModel>(`${this._controller}/settings`, settings, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
