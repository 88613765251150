import { NgZone, Directive } from '@angular/core';
import { AddPointBase } from '@rift/components/shared/viewport/base/AddPointBase';
import { JointBase } from '@rift/components/shared/viewport/base/JointBase';
import { JointCollectionBase } from '@rift/components/shared/viewport/base/JointCollectionBase';
import { SegmentBase } from '@rift/components/shared/viewport/base/SegmentBase';
import { SegmentCollectionBase } from '@rift/components/shared/viewport/base/SegmentCollectionBase';
import { Register } from '@rift/components/shared/viewport/registers/Register';
import { ViewPortHelpers } from '@rift/components/shared/viewport/ViewPort.Helpers';
import { ViewPortModeEnum } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { PointModel } from '@rift/models/restapi/Point.Model';
import { ShapeModel } from '@rift/models/restapi/ShapeModel';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';

@Directive()
export abstract class ShapeBase extends DisplayItem {
    public click: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public requireStageUpdate: Subject<void> = new Subject<void>();
    public updated: Subject<ShapeBase> = new Subject<ShapeBase>();

    protected _joints: JointCollectionBase = null;
    protected _segments: SegmentCollectionBase = null;
    private _editEnabled: boolean = true;

    protected constructor(
        private readonly _zoneShapeBase: NgZone,
        protected readonly _showArrow: boolean,
        protected readonly _maxPoints: number,
        protected readonly _minPoints: number,
        protected readonly _registerBase: any,
        protected readonly _loadQueueBase: ViewPortLoadQueueService,
        protected readonly _shapeModelBase: ShapeModel) {
        super(_zoneShapeBase);
        this._segments = new SegmentCollectionBase(this._zoneShapeBase, this._loadQueueBase);
        this._joints = new JointCollectionBase(this._zoneShapeBase, this._loadQueueBase);

        this._zoneShapeBase.runOutsideAngular(() => {
            this.container.addChild(this._segments.container);
            this.container.addChild(this._joints.container);

            this.addEventHandlers();
        });
    }

    public abstract loadPoints(): void;

    public bringToFront(displayItem: JointBase | SegmentBase): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (displayItem instanceof JointBase) {
                this._joints.bringToFront(displayItem);
            } else if (displayItem instanceof SegmentBase) {
                this._segments.bringToFront(displayItem);
            }
        });
    }

    public get editEnabled(): boolean {
        return this._editEnabled;
    }
    public set editEnabled(value: boolean) {
        this._editEnabled = value;
        this._joints.forEach(j => {
            j.editEnabled = value;
        });
        this._segments.forEach(s => {
            s.editEnabled = value;
            s.addPoint.editEnabled = value;
        });
    }

    public getPointModels(): PointModel[] {
        const firstJoint = this._joints[0];
        const jointsInOrder: JointBase[] = [firstJoint];

        let firstSegment: boolean = true;
        let nextSegment: SegmentBase = firstJoint.toSegment;
        while (nextSegment !== null) {
            if (firstSegment === false && firstJoint.uniqueId === nextSegment.toJoint.uniqueId) {
                // Break out if this is continuous shape.
                break;
            } else if (!isNullOrUndefined(nextSegment.toJoint)) {
                jointsInOrder.push(nextSegment.toJoint);
                firstSegment = false;
            }

            nextSegment = isNullOrUndefined(nextSegment.toJoint) ? null : nextSegment.toJoint.toSegment;
        }

        const newPoints = jointsInOrder.map(j => {
            const model = new PointModel();
            model.x = j.x;
            model.y = j.y;
            return model;
        });

        this.shapeModel.points = newPoints;

        return newPoints;
    }

    public onDestroy(): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();

            this.container.removeAllChildren();

            this._segments.onDestroy();
            this._joints.onDestroy();
        });
    }

    public get register(): Register {
        return this._zoneShapeBase.runOutsideAngular(() => this._registerBase);
    }
    public get shapeModel(): ShapeModel {
        return this._zoneShapeBase.runOutsideAngular(() => this._shapeModelBase);
    }

    public update(): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            this._joints.update();
            this._segments.update();
        });
    }

    protected addAddPointEventHandlers(addPoint: AddPointBase): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            this.addSubscription(addPoint.click.subscribe(event => this.onAddPointClick(event)));
        });
    }

    protected addEventHandlers(): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            this.container.on('click', this.onClick.bind(this));
        });
    }

    protected addJoint(joint: JointBase): void {
        this._zoneShapeBase.runOutsideAngular(() => {

            this.addJointEventHandlers(joint);
            this._joints.push(joint);

            if (this._shapeModelBase.points.length < this._joints.length) {
                this.updated.next(this);
            }
        });
    }

    protected addJointEventHandlers(joint: JointBase): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            this.addSubscription(joint.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
            this.addSubscription(joint.click.subscribe(event => this.onJointClick(event)));
            this.addSubscription(joint.doubleClick.subscribe(event => this.onJointDoubleClick(event)));
            this.addSubscription(joint.drag.subscribe(event => this.onJointDrag(event)));
            this.addSubscription(joint.pressUp.subscribe(event => this.onJointPressUp(event)));
        });
    }

    protected addSegment(segment: SegmentBase): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            this.addSegmentEventHandlers(segment);

            const addPoint = new AddPointBase(this._zoneShapeBase, this, this._loadQueueBase, this.shapeModel);
            this.addAddPointEventHandlers(addPoint);
            segment.addPoint = addPoint;
            addPoint.segment = segment;
            addPoint.update();

            this._segments.push(segment);
        });
    }

    protected addSegmentEventHandlers(segment: SegmentBase): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            this.addSubscription(segment.mouseOver.subscribe(event => this.onSegmentMouseOver(event)));
            this.addSubscription(segment.mouseOut.subscribe(event => this.onSegmentMouseOut(event)));
            this.addSubscription(segment.mouseDown.subscribe(event => this.onSegmentMouseDown(event)));
            this.addSubscription(segment.pressUp.subscribe(event => this.onSegmentPressUp(event)));
            this.addSubscription(segment.drag.subscribe(event => this.onSegmentDrag(event)));
            this.addSubscription(segment.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
        });
    }

    protected modeSet(): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            this._joints.mode = this.mode;
            this._segments.mode = this.mode;
        });
    }

    protected onAddPointClick(event: DisplayItemMouseEvent): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (this.editEnabled === true && this.mode === ViewPortModeEnum.edit && this._joints.length < this._maxPoints) {
                const localMousePoint = this.container.globalToLocal(event.mouseEvent.stageX, event.mouseEvent.stageY);
                const devicePoint = ViewPortHelpers.viewPortPointToDevicePoint(localMousePoint);

                const oldAddPoint = (event.displayItem as AddPointBase);
                const oldSegment = oldAddPoint.segment;
                const oldSegmentFromJoint = oldSegment.fromJoint;
                const oldSegmentToJoint = oldSegment.toJoint;

                this.removeSegment(oldSegment);

                const joint = new JointBase(this._zoneShapeBase, this, this._showArrow, this._loadQueueBase);
                joint.x = devicePoint.x;
                joint.y = devicePoint.y;

                const toSegment = new SegmentBase(this._zoneShapeBase, this, this._loadQueueBase, this._shapeModelBase);
                toSegment.fromJoint = oldSegmentFromJoint;
                toSegment.toJoint = joint;
                joint.fromSegment = toSegment;
                oldSegmentFromJoint.toSegment = toSegment;

                this.addSegment(toSegment);

                const fromSegment = new SegmentBase(this._zoneShapeBase, this, this._loadQueueBase, this._shapeModelBase);
                fromSegment.fromJoint = joint;
                fromSegment.toJoint = oldSegmentToJoint;
                joint.toSegment = fromSegment;
                oldSegmentToJoint.fromSegment = fromSegment;

                this.addSegment(fromSegment);

                this.addJoint(joint);
            }
        });
    }

    protected onClick(event: createjs.MouseEvent): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.click.next(new DisplayItemMouseEvent(this, event));
            }
        });
    }

    protected onJointClick(event: DisplayItemMouseEvent): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                if (this.editEnabled === true && !isNullOrUndefined(event.mouseEvent.nativeEvent) && !isNullOrUndefined(event.mouseEvent.nativeEvent.ctrlKey) && event.mouseEvent.nativeEvent.ctrlKey === true) {
                    this.removeJoint(event.displayItem as JointBase);
                }
            }
        });
    }

    protected onJointDoubleClick(event: DisplayItemMouseEvent): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                if (this.editEnabled === true && !isNullOrUndefined(event.mouseEvent.nativeEvent)) {
                    this.removeJoint(event.displayItem as JointBase);
                }
            }
        });
    }

    protected onJointDrag(event: DisplayItemMouseEvent): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (this.editEnabled === true && this.mode === ViewPortModeEnum.edit) {
                const length = this._joints.length;
                for (let index = 0; index < length; index++) {
                    const joint = this._joints[index];
                    if (joint.uniqueId !== event.displayItem.uniqueId) {
                        joint.update();
                    }
                }
            }
        });
    }

    protected onJointPressUp(event: DisplayItemMouseEvent): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (this.editEnabled === true && this.mode === ViewPortModeEnum.edit) {
                this.updated.next(this);
            }
        });
    }

    protected onSegmentDrag(event: DisplayItemMouseEvent): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (this.editEnabled === true && this.mode === ViewPortModeEnum.edit) {
                const jointsLength = this._joints.length;
                for (let index = 0; index < jointsLength; index++) {
                    const joint = this._joints[index];
                    joint.x = joint.x - (event.lastDragLocation.x - event.mouseEvent.localX);
                    joint.y = joint.y - ((event.lastDragLocation.y - event.mouseEvent.localY) * -1);
                    joint.update();
                }

                const segmentsLength = this._segments.length;
                for (let index = 0; index < segmentsLength; index++) {
                    const segment = this._segments[index];
                    const eventSegment: SegmentBase = event.displayItem as SegmentBase;
                    if (segment.uniqueId !== event.displayItem.uniqueId) {
                        segment.isDragging = eventSegment.isDragging;
                    }
                    segment.update();
                    if (!isNullOrUndefined(segment.addPoint)) {
                        segment.addPoint.update();
                    }
                }
            }
        });
    }

    protected onSegmentMouseDown(event: DisplayItemMouseEvent): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (this.editEnabled === true && this.mode === ViewPortModeEnum.edit) {
                const length = this._segments.length;
                for (let index = 0; index < length; index++) {
                    const segment = this._segments[index];
                    if (segment.uniqueId !== event.displayItem.uniqueId) {
                        segment.isMouseDown = event.displayItem.isMouseDown;
                        segment.update();
                    }
                }
            }
        });
    }

    protected onSegmentMouseOut(event: DisplayItemMouseEvent): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                const length = this._segments.length;
                for (let index = 0; index < length; index++) {
                    const segment = this._segments[index];
                    if (segment.uniqueId !== event.displayItem.uniqueId) {
                        segment.isMouseOver = event.displayItem.isMouseOver;
                        segment.update();
                    }
                }
            }
        });
    }

    protected onSegmentMouseOver(event: DisplayItemMouseEvent): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                const length = this._segments.length;
                for (let index = 0; index < length; index++) {
                    const segment = this._segments[index];
                    if (segment.uniqueId !== event.displayItem.uniqueId) {
                        segment.isMouseOver = event.displayItem.isMouseOver;
                        segment.update();
                    }
                }
            }
        });
    }

    protected onSegmentPressUp(event: DisplayItemMouseEvent): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                const length = this._segments.length;
                for (let index = 0; index < length; index++) {
                    const segment = this._segments[index];
                    if (segment.uniqueId !== event.displayItem.uniqueId) {
                        segment.isMouseDown = event.displayItem.isMouseDown;
                        segment.isDragging = false;
                        segment.update();
                    }
                }
                this.updated.next(this);
            }
        });
    }

    protected removeAddPointEventHandlers(addPoint: AddPointBase): void {
    }

    protected removeEventHandlers(): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            const jointsLength = this._joints.length;
            for (let jointIndex = 0; jointIndex < jointsLength; jointIndex++) {
                this.removeJointEventHandlers(this._joints[jointIndex]);
            }

            const segmentsLength = this._segments.length;
            for (let segmentIndex = 0; segmentIndex < segmentsLength; segmentIndex++) {
                this.removeSegmentEventHandlers(this._segments[segmentIndex]);
            }
        });
    }

    protected removeJoint(joint: JointBase): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (this.shapeModel.points.length > this._minPoints) {
                this._joints.remove(joint);

                if (isNullOrUndefined(joint.toSegment)) {
                    joint.fromSegment.fromJoint.toSegment = null;
                    this.removeSegmentEventHandlers(joint.fromSegment);
                    this._segments.remove(joint.fromSegment);
                } else {
                    if (!isNullOrUndefined(joint.fromSegment)) {
                        joint.fromSegment.toJoint = joint.toSegment.toJoint;
                        joint.toSegment.toJoint.fromSegment = joint.fromSegment;
                    } else {
                        joint.toSegment.toJoint.fromSegment = null;
                    }

                    this.removeSegmentEventHandlers(joint.toSegment);
                    this._segments.remove(joint.toSegment);
                }

                this.update();
                this.requireStageUpdate.next();
                this.updated.next(this);
            }
        });
    }

    protected removeJointEventHandlers(joint: JointBase): void {
    }

    protected removeSegment(segment: SegmentBase): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            this.removeSegmentEventHandlers(segment);
            this._segments.remove(segment);
        });
    }

    protected removeSegmentEventHandlers(segment: SegmentBase): void {
        this._zoneShapeBase.runOutsideAngular(() => {
            if (!isNullOrUndefined(segment.addPoint)) {
                this.removeAddPointEventHandlers(segment.addPoint);
            }
        });
    }
}
