import { Component, HostBinding, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MyAccountChangePasswordComponent } from '@em/components/myaccount/changepassword/MyAccount.ChangePassword.Component';
import { MyAccountEditDetailsComponent } from '@em/components/myaccount/editdetails/MyAccount.EditDetails.Component';
import { MyAccountRestApiKeyComponent } from '@em/components/myaccount/restapikey/MyAccount.RestApiKey.Component';
import { UserModel } from '@em/models/restapi/User.Model';
import { BaseComponent } from '@shared/base/Base.Component';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import * as FileSaver from 'file-saver';
import { UrlUtility } from '@shared/utility/Url.Utility';
import { FileUtility } from '@shared/utility/File.Utility';
import { MatDialog } from '@angular/material/dialog';
import { AuthTypeEnum } from '@shared/enum/AuthType.Enum';

@Component({
    selector: 'em-my-account',
    templateUrl: './MyAccount.Component.html',
    styleUrls: ['./MyAccount.Component.scss']
})
export class MyAccountComponent extends BaseComponent implements OnDestroy, OnInit {
    public static className: string = 'MyAccountComponent';
    public getUserProcess: ProcessMonitorServiceProcess;
    public hostname: string;
    public logOffAction: NavBarAction;
    public logOffActionProcess: ProcessMonitorServiceProcess;
    public logOffServiceProcess: ProcessMonitorServiceProcess;
    public AuthTypeEnum = AuthTypeEnum;

    @HostBinding()
    public id: string = 'em-my-account';
    public port: number;

    public resetApiKeyProcess: ProcessMonitorServiceProcess;
    public getApiKeyProcess: ProcessMonitorServiceProcess;

    public user: UserModel;

    public constructor(
        private readonly _zone: NgZone,
        private readonly _router: Router,
        private readonly _navBarService: NavBarActionService,
        private readonly _dialog: MatDialog,
        private readonly _userCurrentService: UserCurrentService,
        private readonly _injector: Injector) {
        super(_injector);

        this.hostname = location.hostname;
        this.port = UrlUtility.getAbsolutePort();

        this.resetApiKeyProcess = this.processMonitorService.getProcess(MyAccountComponent.className, 'Reset Api Key');
        this.getApiKeyProcess = this.processMonitorService.getProcess(MyAccountComponent.className, 'Get Api Key');
        this.getUserProcess = this.processMonitorService.getProcess(MyAccountComponent.className, 'Getting user.');
        this.logOffActionProcess = this.processMonitorService.getProcess(MyAccountComponent.className, 'Log off action.');
        this.logOffServiceProcess = this.processMonitorService.getProcess(MyAccountComponent.className, 'User service log off.');

        this.addSubscription(this.observableHandlerBase(this._userCurrentService.user, this.getUserProcess).subscribe(user => {
            this.user = user as UserModel;
        }), this.getUserProcess);

        this.logOffAction = new NavBarAction();
        this.logOffAction.name = 'logoff';
        this.logOffAction.text = 'Sign Out';
        this.addSubscription(this.observableHandlerBase(this.logOffAction.onButtonClick, this.logOffActionProcess).subscribe(() => {
            this.addSubscription(this.observableHandlerBase(this._userCurrentService.logoff(), this.logOffServiceProcess).subscribe(() => {
                this._zone.run(() => {
                    this._router.navigate(['/', 'login']);
                });
            }), this.logOffServiceProcess);
        }), this.logOffServiceProcess);
    }

    public changePassword(): void {
        this._dialog.open(MyAccountChangePasswordComponent, { minWidth: 400, disableClose: true });
    }

    public downloadSetupFile(): void {
        this.addSubscription(this.observableHandlerBase(this._userCurrentService.getQuickSetupFile(location.hostname, UrlUtility.getAbsolutePort(), this.resetApiKeyProcess), this.resetApiKeyProcess).subscribe(
            result => {
                const blob = new Blob([result], { type: 'text/plain;charset=utf-8' });
                FileSaver.saveAs(blob, FileUtility.sanitize(`QuickSetup_${this.hostname}_File.xml`));
            }
        ), this.resetApiKeyProcess);
    }

    public editDetails(): void {
        this._dialog.open(MyAccountEditDetailsComponent, { data: this.user, minWidth: 400, disableClose: true });
    }

    public getRestApiKey(): void {
        const dialogRef = this._dialog.open(MyAccountRestApiKeyComponent, { maxWidth: 400, disableClose: true });
        this.addSubscription(dialogRef.componentInstance.keyReset.subscribe((key: string) => this.user.webAPIKey = key), this.getApiKeyProcess);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        if (!this.isNullOrUndefined(this._navBarService) && !this.isNullOrUndefined(this.logOffAction)) {
            this._navBarService.removeAction(this.logOffAction);
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
        if (!this.isNullOrUndefined(this._navBarService) && !this.isNullOrUndefined(this.logOffAction)) {
            this._navBarService.addAction(this.logOffAction);
        }
    }
}
