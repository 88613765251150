import { ConnectionOptionPropertyModel, IConnectionOptionPropertyModel } from '@rift/models/restapi/ConnectionOptionProperty.Model';
import { IResultModel } from '@rift/models/restapi/Result.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IConnectionOptionModel extends IRestModel {
    name: string;
    properties: Array<IConnectionOptionPropertyModel>;
}

export class ConnectionOptionModel extends BaseModel implements IConnectionOptionModel, IResultModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public name: string = null;
    public properties: Array<ConnectionOptionPropertyModel> = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ConnectionOptionModel');

        this.properties = RestModelUtility.loadFromArray(restModel.Properties, ConnectionOptionPropertyModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
