export enum SyncStateEnum {
    ok = 0,
    adding = 1,
    deleting = 2,
    notSynced = 4,
}

export enum SyncActionEnum {
    take = 0,
    merge = 1,
    keep = 2,
}
