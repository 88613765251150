import { isNullOrUndefined, isFunction } from '@shared/utility/General.Utility';
import { IFillHeightParent } from '@shared/interface/IFillHeightParent';
import { StringUtility } from '@shared/utility/String.Utility';
import { Renderer2 } from '@angular/core';
import { timer } from 'rxjs';

export class FillHeightUtility {
    public static setComponentHeight(parent: IFillHeightParent, render: Renderer2): void {
        if (parent.viewInit === true && !isNullOrUndefined(parent.fillHeightComponent) && !isNullOrUndefined(parent.fillHeightComponent.mainContent)) {
            // We have timer he as some times the function will get called before the page layout has completed and
            // the incorrect size gets set. So we give it some time to render and retry.
            let resizeCount: number = 0;
            let resizeSub = timer(100, 100).subscribe(() => {
                resizeCount++;
                if (resizeCount < 5) {

                    let mainContentPad = 0;
                    const thisAny = (parent as any);
                    if (!isNullOrUndefined(thisAny.mainContent)) {
                        const mainContentElement: HTMLElement = thisAny.mainContent.nativeElement;

                        const mainContentStyle = window.getComputedStyle(mainContentElement);
                        const mainContentMarginTop = parseInt(mainContentStyle.marginTop, 10);
                        const mainContentPaddingTop = parseInt(mainContentStyle.paddingTop, 10);

                        mainContentPad = mainContentMarginTop + mainContentPaddingTop;
                    }

                    if (!isNullOrUndefined(parent.fillHeightComponent) && !isNullOrUndefined(parent.fillHeightComponent.mainContent) && !isNullOrUndefined(parent.fillHeightComponent.mainContent.nativeElement)) {
                        const fillHeightComponent = parent.fillHeightComponent;
                        const fillHeightElement: HTMLElement = fillHeightComponent.mainContent.nativeElement;

                        const bounds = fillHeightElement.getBoundingClientRect();
                        const style = window.getComputedStyle(fillHeightElement);
                        const marginTop = StringUtility.isEmptyOrWhiteSpace(style.marginTop) ? 0 : parseInt(style.marginTop, 10);
                        const marginBottom = StringUtility.isEmptyOrWhiteSpace(style.marginBottom) ? 0 : parseInt(style.marginBottom, 10);
                        // const paddingTop = StringUtility.isEmptyOrWhiteSpace(style.paddingTop) ? 0 : parseInt(style.paddingTop, 0);
                        // const paddingBottom = StringUtility.isEmptyOrWhiteSpace(style.paddingBottom) ? 0 : parseInt(style.paddingBottom, 0);

                        parent.height = (window.innerHeight - bounds.top - (marginTop + marginBottom) - mainContentPad) - (isNullOrUndefined(parent.offsetTop) ? 0 : parent.offsetTop);

                        if (!Number.isNaN(parent.height)) {
                            render.setStyle(fillHeightElement, 'height', `${parent.height}px`);
                        }

                        if (isFunction((fillHeightComponent as any).setComponentHeight)) {
                            (fillHeightComponent as any).setComponentHeight();
                        }

                        if (isFunction(fillHeightComponent.filledHeight)) {
                            fillHeightComponent.filledHeight(parent.height);
                        }
                    }
                } else {
                    resizeCount = 0;
                    if (!isNullOrUndefined(resizeSub)) {
                        resizeSub.unsubscribe();
                    }
                    resizeSub = null;
                }
            });
        }
    }
}
