import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Color } from '@angular-material-components/color-picker';

export class ColorUtility {
    /**
     * Converts a hex color '#014533' to rgb(0,0,0) or rgba(0,0,0,0)
     *
     * @static
     * @param {string} hex The hex color.
     * @param {number} [alpha] The alpha value.
     * @returns {string} Th rgb or rgba color.
     * @memberof ColorUtility
     */
    public static hexToRGBA(hex: string, alpha?: number): string {
        if (!isNullOrUndefined(hex)) {
            const rgba = this.hexToRGBAStrut(hex, alpha);

            if (isNullOrUndefined(alpha)) {
                rgba.a = alpha;
            }

            return this.RGBAStrutToRGBA(rgba);
        }
    }

    public static hexToRGBAStrut(hex: string, alpha?: number): { r: number; g: number; b: number; a?: number } {
        if (!isNullOrUndefined(hex)) {
            const r = parseInt(hex.slice(1, 3), 16);
            const g = parseInt(hex.slice(3, 5), 16);
            const b = parseInt(hex.slice(5, 7), 16);

            if (isNullOrUndefined(alpha)) {
                return { r, g, b };
            } else {
                return { r, g, b, a: alpha };
            }
        }
    }

    public static hexToColor(hex: string, alpha?: number): Color {
        if (!isNullOrUndefined(hex)) {
            const rgba = this.hexToRGBAStrut(hex, alpha);
            if (!isNullOrUndefined(rgba)) {
                return new Color(rgba.r, rgba.g, rgba.b, rgba.a);
            }
        }
    }

    public static RGBAToStrut(rgba: string): { r: number; g: number; b: number; a?: number } {
        if (!isNullOrUndefined(rgba)) {
            const match = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
            if (!isNullOrUndefined(match)) {
                const r = parseInt(match[1], 10);
                const g = parseInt(match[2], 10);
                const b = parseInt(match[3], 10);
                const a = parseInt(match[4], 10);
                return { r, g, b, a };
            }

        }
    }

    public static RGBAStrutToRGBA(rgba: { r: number; g: number; b: number; a?: number }): string {
        if (!isNullOrUndefined(rgba.a)) {
            return 'rgba(' + rgba.r + ', ' + rgba.g + ', ' + rgba.b + ', ' + rgba.a + ')';
        } else {
            return 'rgb(' + rgba.r + ', ' + rgba.g + ', ' + rgba.b + ')';
        }
    }

    public static RGBAToColor(rgba: string): Color {
        if (!isNullOrUndefined(rgba)) {
            const rgbaSt = this.RGBAToStrut(rgba);
            if (!isNullOrUndefined(rgbaSt)) {
                return new Color(rgbaSt.r, rgbaSt.g, rgbaSt.b, rgbaSt.a);
            }
        }
    }

    public static ColorToRGBAStrut(color: Color): { r: number; g: number; b: number; a: number } {
        if (!isNullOrUndefined(color)) {
            return { r: color.r, g: color.g, b: color.b, a: color.a };
        }
    }

    public static ColorToRGBA(color: Color): string {
        if (!isNullOrUndefined(color)) {
            const rgba = this.ColorToRGBAStrut(color);
            if (!isNullOrUndefined(rgba)) {
                return this.RGBAStrutToRGBA(rgba);
            }
        }
    }
}
