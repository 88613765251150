import { Injectable } from '@angular/core';
import { WebStorageUtility } from '@shared/utility/WebStorage.Utility';

/**
 *
 *
 * @export
 * @class WebStorageService
 */
export class WebStorageService {
    /**
     * Creates an instance of WebStorageService.
     *
     * @param {Storage} storage The storage mechinisum.
     * @memberof WebStorageService
     */
    public constructor(private storage: Storage) {

    }

    /**
     * Clears the storage.
     *
     * @memberof WebStorageService
     */
    public clear(): void {
        this.storage.clear();
    }

    /**
     * Gets a value from the storage.
     *
     * @template T The type of the item to get.
     * @param {string} key The storage key.
     * @returns {*} The item from storage.
     * @memberof WebStorageService
     */
    public get<T>(key: string): T {
        return WebStorageUtility.get(this.storage, key);
    }

    /**
     * Removes a item from the storage.
     *
     * @param {string} key The storage key.
     * @memberof WebStorageService
     */
    public remove(key: string): void {
        WebStorageUtility.remove(this.storage, key);
    }

    /**
     * Sets a value in the storage.
     *
     * @template T The type of the item to set.
     * @param {string} key The storage key.
     * @param {*} value The item from storage.
     * @memberof WebStorageService
     */
    public set<T>(key: string, value: T): void {
        WebStorageUtility.set(this.storage, key, value);
    }
}

/**
 * The local storage service.
 *
 * @export
 * @class LocalStorageService
 * @extends {WebStorageService}
 */
@Injectable()
export class LocalStorageService extends WebStorageService {
    /**
     * Creates an instance of LocalStorageService.
     *
     * @memberof LocalStorageService
     */
    public constructor() {
        super(localStorage);
    }
}

/**
 * The session storage service.
 *
 * @export
 * @class SessionStorageService
 * @extends {WebStorageService}
 */
@Injectable()
export class SessionStorageService extends WebStorageService {
    /**
     * Creates an instance of SessionStorageService.
     *
     * @memberof SessionStorageService
     */
    public constructor() {
        super(sessionStorage);
    }
}
