import { Component, HostListener, Injector, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeyValuePairModel } from '@em/models/restapi/KeyValuePair.Model';
import { SettingService } from '@em/service/data/setting/Setting.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'em-settings-options-notifications',
    templateUrl: './Settings.Options.Notifications.Component.html',
    styleUrls: ['./Settings.Options.Notifications.Component.scss']
})
export class SettingsOptionsNotificationsComponent extends BaseComponent implements ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsOptionsNotificationsComponent';

    @HostBinding()
    public id: string = 'em-settings-options-notifications';
    public notificationsFormGroup: FormGroup;
    public settingSmtpFrom: KeyValuePairModel;
    public settingSmtpHost: KeyValuePairModel;
    public settingSmtpPassword: KeyValuePairModel;

    public settingSmtpPort: KeyValuePairModel;
    public settingSmtpUserName: KeyValuePairModel;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _formBuilder: FormBuilder,
        private readonly _settingService: SettingService,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsOptionsNotificationsComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsOptionsNotificationsComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsOptionsNotificationsComponent.className, this.saveAllChangesProcessText);

        this.addSaveAllAction(this);

        this.notificationsFormGroup = this._formBuilder.group({
            smtpHost: ['', Validators.compose([ValidationValidators.ipAddressOrHostName])],
            smtpPort: ['', Validators.compose([ValidationValidators.port])],
            smtpFrom: ['', Validators.compose([ValidationValidators.emailAddress])],
            smtpUserName: ['', Validators.compose([])],
            smtpPassword: ['', Validators.compose([])],
        });
        this.formGroupTracker.track(this.notificationsFormGroup);

        this.addSubscription(this.observableHandlerBase(this.notificationsFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.onFormGroupValueChanges()), this.formValuesChangeProcess);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._settingService.getSetting('NotificationSmtpPort', 'NotificationSettings', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingSmtpPort = result;
                        this.changeTracker.track(result);
                        this.notificationsFormGroup.controls.smtpPort.setValue(result.value);
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('NotificationSmtpHost', 'NotificationSettings', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingSmtpHost = result;
                        this.changeTracker.track(result);
                        this.notificationsFormGroup.controls.smtpHost.setValue(result.value);
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('NotificationSmtpFrom', 'NotificationSettings', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingSmtpFrom = result;
                        this.changeTracker.track(result);
                        this.notificationsFormGroup.controls.smtpFrom.setValue(result.value);
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('NotificationSmtpUserName', 'NotificationSettings', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingSmtpUserName = result;
                        this.changeTracker.track(result);
                        this.notificationsFormGroup.controls.smtpUserName.setValue(result.value);
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('NotificationSmtpPassword', 'NotificationSettings', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingSmtpPassword = result;
                        this.changeTracker.track(result);
                        this.notificationsFormGroup.controls.smtpPassword.setValue(result.value);
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.settingSmtpPort.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('NotificationSmtpPort', 'NotificationSettings', this.settingSmtpPort.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingSmtpHost.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('NotificationSmtpHost', 'NotificationSettings', this.settingSmtpHost.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingSmtpFrom.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('NotificationSmtpFrom', 'NotificationSettings', this.settingSmtpFrom.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingSmtpUserName.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('NotificationSmtpUserName', 'NotificationSettings', this.settingSmtpUserName.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingSmtpPassword.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('NotificationSmtpPassword', 'NotificationSettings', this.settingSmtpPassword.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(result);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._settingService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    private onFormGroupValueChanges(): void {
        this.updateModels();
        this.updateSaveAllAction(this);
    }

    private updateModels(): void {
        const formModel = this.notificationsFormGroup.value;

        if (!this.isNullOrUndefined(this.settingSmtpPort) && !this.isNullOrUndefined(formModel.smtpPort)) {
            this.settingSmtpPort.value = formModel.smtpPort;
        }
        if (!this.isNullOrUndefined(this.settingSmtpHost) && !this.isNullOrUndefined(formModel.smtpHost)) {
            this.settingSmtpHost.value = formModel.smtpHost;
        }
        if (!this.isNullOrUndefined(this.settingSmtpFrom) && !this.isNullOrUndefined(formModel.smtpFrom)) {
            this.settingSmtpFrom.value = formModel.smtpFrom;
        }
        if (!this.isNullOrUndefined(this.settingSmtpUserName) && !this.isNullOrUndefined(formModel.smtpUserName)) {
            this.settingSmtpUserName.value = formModel.smtpUserName;
        }
        if (!this.isNullOrUndefined(this.settingSmtpPassword) && !this.isNullOrUndefined(formModel.smtpPassword)) {
            this.settingSmtpPassword.value = formModel.smtpPassword;
        }
    }
}
