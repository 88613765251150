export enum ScheduleTypeEnum {
    /**
     *The time based
     */
    timeBased = 1,
    /**
     *The count based
     */
    countBased = 2
}
