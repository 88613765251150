import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class UniqueArray<T extends string | number | Date> extends Array<T> implements Array<T> {
    public constructor(...items: Array<T>) {
        super();
        Object.setPrototypeOf(this, Object.create(UniqueArray.prototype));
        this.push(...items);
    }

    public push(...items: Array<T>): number {
        this.itemExists('push', ...items);
        return super.push(...items);
    }

    public splice(start: number, deleteCount: number, ...items: Array<T>): Array<T> {
        this.itemExists('splice', ...items);
        return super.splice(start, deleteCount, ...items);
    }

    public unshift(...items: Array<T>): number {
        this.itemExists('unshift', ...items);
        return super.unshift(...items);
    }

    protected itemExists(methodName: string, ...items: Array<T>): void {
        if (!isNullOrUndefined(items)) {
            const itemsLength = items.length;
            for (let index = 0; index < itemsLength; index++) {
                const addItem = items[index];
                const myIndex: number = this.indexOf(addItem);
                if (myIndex !== -1) {
                    throw new Error(`${UniqueArray}:${methodName}:Item already exists at index (${index})`);
                }
            }
        }
    }
}
