<div class="bottom-info-component shr-bottominfo-file-download-component" fxLayout="column">
    <div class="video" fxLayout="row" fxLayoutAlign="start center">
        <div class="fine-name-text spacing-right">
            {{fileName}}
        </div>
        <div class="progress">
            <mat-progress-bar mode="determinate" [value]="downloadProgress"></mat-progress-bar>
        </div>
        <div class="actions">
            <button *ngIf="showCancel === true" matTooltip="Cancel Download" mat-icon-button (click)="cancelDownload()">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </div>
</div>
