import { Component, EventEmitter, HostBinding, Injector, NgZone } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { VideoViewModel } from '@rift/service/data/recording/Video.ViewModel1';
import { BottomInfoStateEnum } from '@shared/component/bottominfo/BottomInfo.State.Enum';
import { IBottomInfoChildComponent } from '@shared/component/bottominfo/IBottomInfoChild.Component';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'rift-syncing-video-sessions',
    templateUrl: './SyncingVideoSessions.Component.html',
    styleUrls: ['./SyncingVideoSessions.Component.scss'],
})
export class SyncingVideoSessionsComponent extends RiftBaseComponent implements IBottomInfoChildComponent {
    public static className: string = 'SyncingVideoSessionsComponent';

    public cancelDownloadSessionClicked: Subject<VideoViewModel> = new Subject();
    public pauseDownloadSessionClicked: Subject<VideoViewModel> = new Subject();
    public resumeDownloadSessionClicked: Subject<VideoViewModel> = new Subject();

    @HostBinding()
    public id: string = 'rift-syncing-video-sessions';

    public close: EventEmitter<any> = new EventEmitter<any>();
    public stateChanged: EventEmitter<BottomInfoStateEnum> = new EventEmitter<BottomInfoStateEnum>();
    public instanceId: number = null;

    private _downloadingDeviceSessions: Observable<VideoViewModel[]>;

    private _state: BottomInfoStateEnum = BottomInfoStateEnum.info;
    public get state(): BottomInfoStateEnum {
        return this._state;
    }
    public set state(value: BottomInfoStateEnum) {
        this._state = value;
        this.stateChanged.emit(this._state);
    }

    public constructor(
        private readonly _zone: NgZone,
        private readonly _injector: Injector) {
        super(_injector);
    }

    public get notificationCount(): Observable<number> {
        return this.downloadingDeviceSessions.pipe(
            map(results => this.isNullOrUndefined(results) ? 0 : results.length)
        );
    }

    public get downloadingDeviceSessions(): Observable<Array<VideoViewModel>> {
        return this._downloadingDeviceSessions;
    }
    public set downloadingDeviceSessions(value: Observable<Array<VideoViewModel>>) {
        this._downloadingDeviceSessions = value;
    }

    public cancelDownloadSession(vm: VideoViewModel): void {
        this.cancelDownloadSessionClicked.next(vm);
    }

    public pauseDownloadSession(vm: VideoViewModel): void {
        this.pauseDownloadSessionClicked.next(vm);
    }

    public resumeDownloadSession(vm: VideoViewModel): void {
        this.resumeDownloadSessionClicked.next(vm);
    }
}
