import { NgZone } from '@angular/core';
import { DisplayItemCollection } from '@shared/generic/canvas/DisplayItemCollection';
import { Target } from '@rift/components/shared/viewport/targets/Target';
import { TargetLocation } from '@rift/components/shared/viewport/targets/TargetLocation';
import { ViewPortHelpers } from '@rift/components/shared/viewport/ViewPort.Helpers';
import { TargetModel } from '@rift/models/websocket/Target.Model';
import { UnitsOfMeasurementService } from '@rift/service/unitsofmeasurement/UnitsOfMeasurement.Service';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class TargetCollection extends DisplayItemCollection<Target> {
    private _ids: Array<number> = [];
    private _isLive: boolean = null;
    private _heightTextVisible: boolean = true;
    private _tailsVisible: boolean = true;
    private _pathsVisible: boolean = true;
    private _markedTargetsEnabled: boolean = false;

    public constructor(
        protected readonly _zone: NgZone,
        protected readonly _unitsOfMeasurementService: UnitsOfMeasurementService,
        protected readonly _loadQueue: ViewPortLoadQueueService) {
        super(_zone);
        Object.setPrototypeOf(this, Object.create(TargetCollection.prototype));
    }

    public set markedTargetsEnabled(value: boolean) {
        this._markedTargetsEnabled = value;
    }

    public set heightTextVisible(value: boolean) {
        this._heightTextVisible = value;
        this._zone.runOutsideAngular(() => {
            for (let index = this.minIndex; index <= this.maxIndex; index++) {
                const target = this[index];
                if (!isNullOrUndefined(target)) {
                    target.heightTextVisible = value;
                }
            }
        });
    }

    public get heightTextVisible(): boolean {
        return this._heightTextVisible;
    }

    public set tailsVisible(value: boolean) {
        this._tailsVisible = value;
        this._zone.runOutsideAngular(() => {
            for (let index = this.minIndex; index <= this.maxIndex; index++) {
                const target = this[index];
                if (!isNullOrUndefined(target)) {
                    target.tailsVisible = value;
                }
            }
        });
    }

    public get tailsVisible(): boolean {
        return this._tailsVisible;
    }

    public set pathsVisible(value: boolean) {
        this._pathsVisible = value;
        this._zone.runOutsideAngular(() => {
            for (let index = this.minIndex; index <= this.maxIndex; index++) {
                const target = this[index];
                if (!isNullOrUndefined(target)) {
                    target.pathsVisible = value;
                }
            }
        });
    }

    public get pathsVisible(): boolean {
        return this._pathsVisible;
    }

    /**
     * Push and keep only live targets in collection.
     * New targets in {targets} are pushed to the collection. Targets in {targets}
     * and in the collection are updated and targets in the collection but
     * not in {targets} are removed
     *
     * Used for live view of targets.
     *
     * isLive true.
     *
     * @param {Array<TargetModel>} targets
     * @memberof TargetCollection
     */
    public pushLifetimeTargets(targets: Array<TargetModel>): void {
        this._zone.runOutsideAngular(() => {
            if (this._isLive === true) {
                const targetModelsLength = targets.length;

                for (let targetModelIndex = 0; targetModelIndex < targetModelsLength; targetModelIndex++) {
                    const targetModel = targets[targetModelIndex];
                    const targetId = targetModel.id;
                    let target: Target = this[targetId];

                    if (!isNullOrUndefined(target)) {
                        target.markedTargetsEnabled = this._markedTargetsEnabled;
                        target.locations.push(new TargetLocation(ViewPortHelpers.devicePointToViewPortPoint({ x: targetModel.x, y: targetModel.y }), targetModel.height, 0, targetModel.status));
                    } else {
                        target = new Target(this._zone, this._unitsOfMeasurementService, true, this._loadQueue, targetModel, 0, this._heightTextVisible);
                        this.initItem(target);
                        target.markedTargetsEnabled = this._markedTargetsEnabled;
                        this[targetId] = target;
                        this._ids.push(targetId);
                        this.setIndexRanges(targetId);
                    }
                }

                let idsLength = this._ids.length;
                if (idsLength !== targetModelsLength) {
                    const length = this._ids.length;
                    for (let idIndex = 0; idIndex < length; idIndex++) {
                        const id = this._ids[idIndex];
                        if (targets.findIndex(t => t.id === id) === -1) {
                            const deadTarget = this[id];
                            if (!isNullOrUndefined(deadTarget)) {
                                this.removeEventHandlers(deadTarget);
                                this.container.removeChild(deadTarget.container);

                                this[id] = undefined;
                                this._ids.splice(idIndex, 1);

                                idIndex--;
                                idsLength = this._ids.length;
                            }
                        }
                    }
                }
            }
        });
    }

    public removePersistentTargets(windowStart: number, windowEnd: number): void {
        this._zone.runOutsideAngular(() => {
            if (this._isLive === false) {
                for (let index = this.minIndex; index <= this.maxIndex; index++) {
                    const target = this[index];
                    if (!isNullOrUndefined(target) && (target.lastFrame < windowStart || target.firstFrame > windowEnd)) {
                        this.destroyItem(target);
                        delete this[index];
                    }
                }
            }
        });
    }

    /**
     * Push and persist targets in collection.
     * New targets in {targets} are pushed to the collection.
     *
     * Used for validation playback maintenance of targets.
     *
     * isPersistent true.
     *
     * @param {Array<TargetModel>} targets
     * @memberof TargetCollection
     */
    public pushPersistentTargets(frameNumber: number, targets: Array<TargetModel>): void {
        this._zone.runOutsideAngular(() => {
            if (this._isLive === false) {
                const targetModelsLength = targets.length;
                for (let targetModelIndex = 0; targetModelIndex < targetModelsLength; targetModelIndex++) {
                    const targetModel = targets[targetModelIndex];
                    const targetId = targetModel.id;
                    let target: Target = this[targetId];

                    if (!isNullOrUndefined(target)) {
                        target.markedTargetsEnabled = this._markedTargetsEnabled;
                        target.locations.push(new TargetLocation(ViewPortHelpers.devicePointToViewPortPoint({ x: targetModel.x, y: targetModel.y }), targetModel.height, frameNumber, targetModel.status));
                    } else {
                        target = new Target(this._zone, this._unitsOfMeasurementService, false, this._loadQueue, targetModel, frameNumber, this._heightTextVisible);
                        target.markedTargetsEnabled = this._markedTargetsEnabled;
                        target.firstFrame = frameNumber;
                        this.initItem(target);
                        this[targetId] = target;
                        this.setIndexRanges(targetId);
                    }

                    if (frameNumber > target.lastFrame) {
                        target.lastFrame = frameNumber;
                    }

                    if (frameNumber < target.firstFrame) {
                        target.targetModel = targetModel;
                        target.firstFrame = frameNumber;
                    }
                }
            }
        });
    }

    public setPersistentFrameNumber(frameNumber: number): void {
        for (let index = this.minIndex; index <= this.maxIndex; index++) {
            const target = this[index];
            if (!isNullOrUndefined(target)) {
                target.setPersistentFrameNumber(frameNumber);
            }
        }
    }

    public bringToFront(displayItem: Target): void {
        this._zone.runOutsideAngular(() => {
            this.container.setChildIndex(displayItem.container, this.container.children.length - 1);
            this.requireStageUpdate.next();
        });
    }

    public get isLive(): boolean {
        return this._zone.runOutsideAngular(() => this._isLive);
    }

    public get isPersistent(): boolean {
        return this._zone.runOutsideAngular(() => this._isLive === null ? null : this._isLive === false);
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
        });
    }

    public push(...targets: Array<Target>): number {
        throw new Error('TargetCollection: use pushPersistentTargets or pushLifetimeTargets.');
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            if (this.visible) {
                for (let index = this.minIndex; index <= this.maxIndex; index++) {
                    const target = this[index];
                    if (!isNullOrUndefined(target)) {
                        target.update();
                    }
                }
            }
        });
    }

    public setLive(): void {
        this._zone.runOutsideAngular(() => {
            this._isLive = true;
        });
    }

    public setPersistent(): void {
        this._zone.runOutsideAngular(() => {
            this._isLive = false;
        });
    }

    protected initItem(target: Target): void {
        this._zone.runOutsideAngular(() => {
            super.initItem(target);
        });
    }
}
