import { Component, Injector, Input, OnInit, HostBinding } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';
import { isNumber } from '@shared/utility/General.Utility';

/**
 * Font awesome icon component.
 *
 * @export
 * @class FontAwesomeComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
    selector: 'shr-fa',
    templateUrl: './FontAwesome.Component.html',
    styleUrls: ['./FontAwesome.Component.scss']
})
export class FontAwesomeComponent extends BaseComponent implements OnInit {
    public static className: string = 'FontAwesomeComponent';

    @HostBinding()
    public id: string = 'shr-fa';

    /**
     * Can be any one of lg, 2x, 3x, 4x, 5x
     *
     * @type {string}
     * @memberof FontAwesomeComponent
     */
    @Input()
    public size?: 'lg' | '2x' | '3x' | '4x' | '5x';

    /**
     * The name of the fontawesome icon.
     *
     * @type {string}
     * @memberof FontAwesomeComponent
     */
    @Input()
    public name: string;

    /**
     * can be any one of 90, 180, 270, horizontal, vertical.
     *
     * @type {(string | number)}
     * @memberof FontAwesomeComponent
     */
    @Input()
    public rotate?: string | number;

    /**
     * can be spin, pulse.
     *
     * @type {string}
     * @memberof FontAwesomeComponent
     */
    @Input()
    public animation?: 'spin' | 'pulse';

    /**
     * true if fixed else false.
     *
     * @type {boolean}
     * @memberof FontAwesomeComponent
     */
    @Input()
    public fixed?: boolean;

    /**
     * true if inverse else false.
     *
     * @type {boolean}
     * @memberof FontAwesomeComponent
     */
    @Input()
    public inverse?: boolean;

    @Input()
    public fontSet: 'fas'| 'fab' = 'fas';

    public classes: string;

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        if (this.size) {
            this.classes += ` fa-${this.size}`;
        }

        if (this.fixed) {
            this.classes += ` fa-fw`;
        }

        if (this.animation) {
            this.classes += ` fa-${this.animation}`;
        }

        if (this.rotate) {
            const rotateClass = (isNumber(this.rotate)) ? ` fa-rotate-${this.rotate}` : ` fa-flip-${this.rotate}`;
            this.classes += rotateClass;
        }

        if (this.inverse) {
            this.classes += ` fa-inverse`;
        }
    }
}
