<div class="em-settings-options-temp-store"
    fxLayout="column">    
    <form [formGroup]="tempStoreFormGroup">
        <mat-card class="header">            
            <mat-card-title>
                <span class="mat-title">Shared Temporary Storage Location</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span class="mat-subheading-1">This is where Estate Manager will store temporary files that are shared between services (services must be restarted for new paths to be applied).</span>
            </mat-card-subtitle>
            <mat-card-content>
                <mat-form-field style="width: 400px;">
                    <input type="text"
                        #tempStoreRootPath
                        matInput
                        formControlName="tempStoreRootPath"
                        placeholder="Storage URI">
                    <span matSuffix>{{ settingTempStoreRootPath?.propertyHasChangesText('value') }}</span>
                    <mat-error>{{getFormControlErrorMessage(tempStoreFormGroup.controls['tempStoreRootPath'])}}</mat-error>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </form>
</div>