import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { ErrorWarningSummaryStateEnum } from '@shared/enum/ErrorWarningSummaryState.Enum';

export class ErrorWarningSummaryModel extends BaseModel implements IRestModel {

    public entryState: ErrorWarningSummaryStateEnum = null;
    public description: string = null;
    public readonly isIRestModel = true;
    public numDevices: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ErrorWarningSummaryModel');
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
