import { AfterContentChecked, AfterViewInit, Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

/**
 * Navigation tab bar pagination component.
 *
 * @export
 * @class NavBarPaginationComponent
 * @implements {AfterViewInit}
 */
@Component({
    selector: 'shr-nav-bar-pagination',
    templateUrl: 'NavBarPagination.Component.html',
    styleUrls: ['NavBarPagination.Component.scss'],
})
export class NavBarPaginationComponent implements AfterViewInit, AfterContentChecked {
    public static className: string = 'NavBarPaginationComponent';

    @HostBinding()
    public id: string = 'shr-nav-bar-pagination';

    @ViewChild('labelContainer', { static: true })
    public labelContainer: ElementRef;

    public leftDisabled: boolean = false;
    public rightDisabled: boolean = false;
    public showScrollButtons = false;

    private _hasChildren: boolean = false;
    private _htmlElement: HTMLElement = null;
    private _htmlElementSecondChild: Element = null;

    public constructor(
        private readonly _elementRef: ElementRef) {
    }

    public left() {
        if (this.leftDisabled === false) {
            const el = this._elementRef.nativeElement.querySelector('.mat-tab-label-container');
            el.scrollLeft -= 120;
            this.updateDisabledState(el);
        }
    }

    public ngAfterViewInit() {
        this.labelContainer.nativeElement.querySelector('.mat-tab-links').style.display = 'flex';
    }

    public ngAfterContentChecked(): void {
        this.updateShowScrollButtons();
    }

    public onResized(): void {
        this.updateLabelContainer();
        this.updateShowScrollButtons();
        this.updateDisabledState(this._elementRef.nativeElement.querySelector('.mat-tab-label-container'));
    }

    public right() {
        if (this.rightDisabled === false) {
            const el = this._elementRef.nativeElement.querySelector('.mat-tab-label-container');
            el.scrollLeft += 120;
            this.updateDisabledState(el);
        }
    }

    private updateDisabledState(element: any): void {
        if (element.scrollLeft === 0) {
            this.leftDisabled = true;
            this.rightDisabled = false;
        } else if (element.scrollLeft >= (this._htmlElementSecondChild.clientWidth - this._htmlElement.clientWidth)) {
            this.leftDisabled = false;
            this.rightDisabled = true;
        } else {
            this.leftDisabled = false;
            this.rightDisabled = false;
        }
    }

    private updateLabelContainer(): void {
        if (!isNullOrUndefined(this.labelContainer)) {
            if (isNullOrUndefined(this._htmlElement)) {
                this._htmlElement = this.labelContainer.nativeElement as HTMLDivElement;
                this._hasChildren = this._htmlElement.children.length > 2;
                if (this._hasChildren) {
                    this._htmlElementSecondChild = this._htmlElement.children[1];
                }
            }
        }
    }

    private updateShowScrollButtons(): void {
        if (!isNullOrUndefined(this._htmlElement) && this._hasChildren === true) {
            const space = this._htmlElement.clientWidth - this._htmlElementSecondChild.clientWidth;
            if (space > 0) {
                this.showScrollButtons = false;
            } else {
                this.showScrollButtons = true;
            }
        }
    }
}
