<div class="counting-setting-content">
    <form [formGroup]="form">
        <ng-template [ngIf]="property.inputType === 'unitsOfMeasurement'">
            <mat-form-field class="spacing-right">
                <input riftUnitsOfMeasurementInput
                    #uomInput=riftUnitsOfMeasurementInput
                    formControlName="value"
                    [placeholder]="property.placeholder"
                    [metricMin]="property.min"
                    [metricMax]="property.max"
                    [metricUnit]="isNullOrUndefined(property.metricUnit) ? UnitOfMeasurementEnum.centimeter : property.metricUnit"
                    [imperialUnit]="isNullOrUndefined(property.imperialUnit) ? UnitOfMeasurementEnum.inch : property.imperialUnit">
                <span matSuffix>
                    {{uomInput.suffix}}
                </span>
                <mat-error>{{getFormControlErrorMessage(form.controls['value'])}}</mat-error>
                <span matSuffix>{{ register?.propertyHasChangesText(property.propertyName) }}</span>
            </mat-form-field>
        </ng-template>

        <ng-template [ngIf]="property.inputType !== 'unitsOfMeasurement' && property.inputType !== 'checkbox'">
            <mat-form-field class="spacing-right">
                <input matInput
                    [step]="property.step"
                    [type]="property.inputType"
                    [placeholder]="property.placeholder"
                    formControlName="value">
                <span *ngIf="!isNullOrUndefined(property.matSuffix)"
                    matSuffix>
                    {{property.matSuffix}}
                </span>
                <mat-error>{{getFormControlErrorMessage(form.controls['value'])}}</mat-error>
                <span matSuffix>{{ register?.propertyHasChangesText(property.propertyName) }}</span>
            </mat-form-field>
        </ng-template>

        <ng-template [ngIf]="property.inputType === 'checkbox'">
            <div class="spacing-right">
                <mat-checkbox
                    formControlName="value">
                    {{property.placeholder}}
                </mat-checkbox>
                <span *ngIf="!isNullOrUndefined(property.matSuffix)"
                    matSuffix>
                    {{property.matSuffix}}
                </span>
            </div>
        </ng-template>
    </form>
</div>