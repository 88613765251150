<div class="rift-settings-network-select">
    <shr-nav-bar-pagination *ngIf="devices?.length > 1">
        <nav mat-tab-nav-bar>
            <a mat-tab-link *ngFor="let device of devices | orderBy: 'device.master' : true" [routerLink]="device.url"
                routerLinkActive #deviceLink="routerLinkActive" [active]="deviceLink.isActive">
                {{ device.model.serialNumber }} ({{ device.model.master === true ? 'Master' : 'Node' }})
            </a>
        </nav>
    </shr-nav-bar-pagination>
    <div class="content">
        <router-outlet (deactivate)="onDeactivated()" (activate)="onActivated()"></router-outlet>
    </div>
</div>
