import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetricsModel } from '@em/models/restapi/Metrics.Model';
import { EmRestApiService } from '@em/service/restapi/base/EmRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';

@Injectable()
export class RestApiHomeService extends EmRestApiService {
    private _controller = 'home/';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.emRestApiBase, _httpClient);
    }

    public getMetrics(process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<MetricsModel> {
        return this.get<MetricsModel>(`${this._controller}metrics`, MetricsModel, null, process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
