import { Component, HostBinding, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionReportDialogComponent, SessionReportDialogData } from '@rift/components/validation/sessionreport/SessionReport.Dialog.Component';
import { DefaultSessionOptions } from '@rift/components/validation/Validation.Defaults';
import { ValidationModeEnum } from '@rift/components/validation/Validation.Mode.Enum';
import { ValidationSessionOptionModel } from '@rift/models/restapi/ValidationSessionOption.Model';
import { DbValidationSessionInfoModel } from '@rift/service/validation/models/database/syncsession/IDbValidationSessionInfo.Model';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { ValidationSessionStateEnum } from '@shared/enum/ValidationSessionState.Enum';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { CountModel } from '@rift/models/websocket/Count.Model';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { GlobalModel } from '@rift/models/restapi/Global.Model';
import { IValidationRecordingModel } from '@rift/service/validation/models/ValidationRecording.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

export class SessionDetailsDialogData {
    public constructor(
        public readonly showDurationAsTime: boolean,
        public readonly lineReportBucketSize: number,
        public readonly session: DbValidationSessionInfoModel,
        public readonly registers: Array<RegisterBaseModel>,
        public readonly lines: Array<LineModel>,
        public readonly systemCounts: Array<CountModel>,
        public readonly devices: Array<DeviceModel>,
        public readonly timeSetup: TimeSetupModel,
        public readonly globalData: GlobalModel,
        public readonly validationRecording: IValidationRecordingModel) {

    }
}

export class SessionDetailsDialogResult {
    public constructor(public readonly session?: DbValidationSessionInfoModel, public readonly goToMode?: ValidationModeEnum, public readonly deleteSession?: boolean) {

    }
}

@Component({
    selector: 'rift-session-details-dialog',
    templateUrl: './SessionDetails.Dialog.Component.html',
    styleUrls: ['./SessionDetails.Dialog.Component.scss']
})
export class SessionDetailsDialogComponent extends BaseComponent {
    public static className: string = 'SessionDetailsDialogComponent';

    public DateTimeUtility = DateTimeUtility;

    public sessionFormGroup: FormGroup;
    public session: DbValidationSessionInfoModel;
    public ValidationSessionStateEnum = ValidationSessionStateEnum;
    public start: string;
    public end: string;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'rift-session-details-dialog';

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: SessionDetailsDialogData,
        private readonly _dialogRef: MatDialogRef<SessionDetailsDialogComponent>,
        private readonly _formBuilder: FormBuilder,
        private readonly _playService: ValidationPlayService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SessionDetailsDialogComponent.className, 'Form values change');

        this.session = data.session;

        if (!isNullOrUndefined(data.session) && isNullOrUndefined(data.session.id)) {
            this.session.options = DefaultSessionOptions.map(od => {
                const no = new ValidationSessionOptionModel();
                no.enabled = od.enabled;
                no.locked = od.locked;
                no.name = od.name;
                return no;
            });
        }

        if (!isNullOrUndefined(data.session) && !isNullOrUndefined(this.session.startFrame) && !isNullOrUndefined(this.session.endFrame)) {
            const plStart = this._playService.fillPlayLocation({ frameNumber: this.session.startFrame });
            const plEnd = this._playService.fillPlayLocation({ frameNumber: this.session.endFrame });
            this.start = DateTimeUtility.millisecondsToDuration(plStart.offset);
            this.end = DateTimeUtility.millisecondsToDuration(plEnd.offset);
        }

        this.sessionFormGroup = this._formBuilder.group({
            name: [this.session.name, Validators.compose([Validators.maxLength(256)])],
            username: [this.session.username, Validators.compose([Validators.maxLength(256)])],
            notes: [this.session.notes, Validators.compose([Validators.maxLength(2056)])],
        });
        this.formGroupTracker.track(this.sessionFormGroup);

        this.addSubscription(this.observableHandlerBase(this.sessionFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesSessionFormGroup()), this.formValuesChangeProcess);

        if (!isNullOrUndefined(this.session.state)) {
            this.sessionFormGroup.disable();
        }

        this.setSessionFormGroupValues();
    }

    public close(): void {
        this._dialogRef.close(new SessionDetailsDialogResult());
    }

    public delete(): void {
        this.addSubscription(this.openOkCancelDialog('Delete Validation Session', 'Are you sure you want to delete this validation session').afterClosed().subscribe(
            result => {
                if (!this.isNullOrUndefined(result) && result.ok === true) {
                    this._dialogRef.close(new SessionDetailsDialogResult(this.session, ValidationModeEnum.preview, true));
                }
            }
        ));
    }

    public report(): void {
        this._dialogRef.close(new SessionDetailsDialogResult(this.session, ValidationModeEnum.report));
    }

    public validate(): void {
        this._dialogRef.close(new SessionDetailsDialogResult(this.session, ValidationModeEnum.validation));
    }

    public saveValidate(): void {
        this._dialogRef.close(new SessionDetailsDialogResult(this.session, ValidationModeEnum.validation));
    }

    public savePreview(): void {
        this._dialogRef.close(new SessionDetailsDialogResult(this.session, ValidationModeEnum.preview));
    }

    public review(): void {
        this._dialogRef.close(new SessionDetailsDialogResult(this.session, ValidationModeEnum.review));
    }

    public getDisplayName(option: ValidationSessionOptionModel): string {
        switch (option.name) {
            case 'showcountlines':
                return 'Show Visuals';
            case 'showtargets':
                return 'Show Targets';
            case 'showtargetheight':
                return 'Show Targets Height';
            case 'showtargetspaths':
                return 'Show Targets Paths';
            case 'showtargetstails':
                return 'Show Targets Tails';
            case 'showtargetsovervideo':
                return 'Show Targets Over Video';
            case 'showsystemcounts':
                return 'Show System Counts';
            case 'fieldofview':
                return 'Show Field Of View';
        }
    }

    private setSessionFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.session)) {
            this.sessionFormGroup.setValue({
                name: this.session.name,
                username: this.session.username,
                notes: this.session.notes,
            });
        }
    }

    private updateModelValuesSessionFormGroup(): void {
        if (!this.isNullOrUndefined(this.session)) {
            const formValues = this.sessionFormGroup.value;

            this.session.name = formValues.name;
            this.session.username = formValues.username;
            this.session.notes = formValues.notes;
        }
    }
}
