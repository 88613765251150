import { Component, EventEmitter, HostBinding, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { BottomInfoStateEnum } from '@shared/component/bottominfo/BottomInfo.State.Enum';
import { IBottomInfoChildComponent } from '@shared/component/bottominfo/IBottomInfoChild.Component';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'rift-bottominfo-device-issues',
    templateUrl: './DeviceIssues.Component.html',
    styleUrls: ['./DeviceIssues.Component.scss'],
})
export class DeviceIssuesComponent extends RiftBaseComponent implements IBottomInfoChildComponent {
    public close: EventEmitter<any> = new EventEmitter<any>();
    public stateChanged: EventEmitter<BottomInfoStateEnum> = new EventEmitter<BottomInfoStateEnum>();
    public instanceId: number = null;
    public total: number = null;

    @HostBinding()
    public id: string = 'rift-bottominfo-device-issues';

    private _state: BottomInfoStateEnum = BottomInfoStateEnum.info;
    public get state(): BottomInfoStateEnum {
        return this._state;
    }
    public set state(value: BottomInfoStateEnum) {
        this._state = value;
        this.stateChanged.emit(this._state);
    }

    public constructor(
        private readonly _router: Router,
        private readonly _injector: Injector) {
        super(_injector);
    }

    public get notificationCount(): Observable<number> {
        return of(1);
    }

    public onClicked(): void {
        this.connectionService.getRouteToEndOfConnectionData().subscribe(
            (paths) => {
                this._router.navigate([...paths, 'diagnostics']);
            }
        );
    }
}
