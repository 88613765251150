import { IObjectStore } from '@shared/service/Indexeddb/IObjectStore';
import { IFrameStore } from '@rift/service/validation/database/syncrecording/IFrameStore';

export interface IDatabaseSessionBookmarkStore extends IFrameStore {
    sessionInfoIdIndex: string;
    sessionInfoIdFrameNumberIndex: string;
}

export class DatabaseSessionBookmarkStore implements IObjectStore {
    public static readonly keyPath: string = 'id';
    public static readonly recordingIdIndex: string = 'RecordingIdIndex';
    public static readonly sessionInfoIdIndex: string = 'SessionInfoIdIndex';
    public static readonly sessionInfoIdFrameNumberIndex: string = 'SessionInfoIdFrameNumberIndex';
    public static readonly storeDisplayName: string = 'Bookmark';
    public static readonly storeName: string = 'bookmark';

    public get storeName(): string {
        return DatabaseSessionBookmarkStore.storeName;
    };

    public static toInterface(): IDatabaseSessionBookmarkStore {
        return {
            storeName: this.storeName,
            keyPath: this.keyPath,
            recordingIdIndex: this.recordingIdIndex,
            sessionInfoIdIndex: this.sessionInfoIdIndex,
            sessionInfoIdFrameNumberIndex: this.sessionInfoIdFrameNumberIndex,
        } as IDatabaseSessionBookmarkStore;
    }

    public createStores(db: IDBDatabase): void {
        const store: IDBObjectStore = db.createObjectStore(DatabaseSessionBookmarkStore.storeName, { keyPath: DatabaseSessionBookmarkStore.keyPath, autoIncrement: true });
        store.createIndex(DatabaseSessionBookmarkStore.sessionInfoIdFrameNumberIndex, ['sessionInfoId', 'frameNumber'], { unique: true });
        store.createIndex(DatabaseSessionBookmarkStore.recordingIdIndex, 'recordingId');
        store.createIndex(DatabaseSessionBookmarkStore.sessionInfoIdIndex, 'sessionInfoId');
    }
}
