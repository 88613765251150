import { AfterViewInit, Component, HostBinding, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker, MapMarkerClusterer } from '@angular/google-maps';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { BaseComponent } from '@shared/base/Base.Component';
import { DeviceStateEnum, DeviceStateEnumHelpers } from '@shared/enum/DeviceState.Enum';
import { timer } from 'rxjs';

interface IDeviceGroup {
    latitude: number;
    longitude: number;
    friendlySerials: Array<string>;
    maxState: DeviceStateEnum;
    markerIcon: string;
    maxStateText: string;
}

@Component({
    selector: 'em-device-map',
    templateUrl: './DeviceMap.Component.html',
    styleUrls: ['./DeviceMap.Component.scss']
})
export class DeviceMapComponent extends BaseComponent implements OnInit, AfterViewInit {
    public static className: string = 'DeviceMapComponent';
    public defaultZoom: number = 8;
    public defaultLatitude: number = 52.2397844;
    public defaultLongitude: number = -0.8803982;
    public deviceGroups: IDeviceGroup[] = [];
    public selectedDeviceGroup: IDeviceGroup = null;
    public clusterStyle: any[] = [
        {
            url: './assets/maps/1.png',
            height: 52,
            width: 52,
            anchor: [16, 0],
            textColor: '#000000',
            textSize: 20,
        },
        {
            url: './assets/maps/2.png',
            height: 52,
            width: 52,
            anchor: [16, 0],
            textColor: '#000000',
            textSize: 20,
        },
        {
            url: './assets/maps/3.png',
            height: 52,
            width: 52,
            anchor: [16, 0],
            textColor: '#000000',
            textSize: 20,
        },
    ];

    @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

    @ViewChild(GoogleMap) map: GoogleMap;

    @ViewChild(MapMarkerClusterer) clusterer: MapMarkerClusterer;

    @HostBinding()
    public id: string = 'em-device-map';

    private _devices: Array<DeviceModel>;
    private _mapBounds: google.maps.LatLngBounds = null;

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);

        if (typeof google === 'object'){
            this._mapBounds = new google.maps.LatLngBounds();
        }
    }

    public openInfoWindow(marker: MapMarker, deviceGroup: IDeviceGroup) {
        this.selectedDeviceGroup = deviceGroup;
        this.infoWindow.open(marker);
    }

    public calculator: Calculator = (markers: google.maps.Marker[], clusterIconStylesCount: number): ClusterIconInfo => {
        let clusterCount = 0;

        // Using the Maker.title to get the state and the count this is the only way I can
        // get the data to this function.
        let maxState = DeviceStateEnum.ok;
        const length = markers.length;
        for (let i = 0; i < length; i++) {
            const marker = markers[i];
            if (marker) {
                const label = marker.getLabel();
                if (label) {
                    const title = ((marker as any).title as string);
                    const countMatches = title.match(/([0-9]*)/g);
                    if (countMatches.length > 0) {
                        clusterCount += parseInt(countMatches[0], 10);
                    }

                    let state = DeviceStateEnum.ok;
                    if (title.indexOf(DeviceStateEnumHelpers.toDisplayName(DeviceStateEnum.couldNotConnect).toLocaleLowerCase()) !== -1) {
                        state = DeviceStateEnum.couldNotConnect;
                    } else if (title.indexOf(DeviceStateEnumHelpers.toDisplayName(DeviceStateEnum.error).toLocaleLowerCase()) !== -1) {
                        state = DeviceStateEnum.error;
                    } else if (title.indexOf(DeviceStateEnumHelpers.toDisplayName(DeviceStateEnum.ok).toLocaleLowerCase()) !== -1) {
                        state = DeviceStateEnum.ok;
                    } else if (title.indexOf(DeviceStateEnumHelpers.toDisplayName(DeviceStateEnum.warning).toLocaleLowerCase()) !== -1) {
                        state = DeviceStateEnum.warning;
                    }

                    if (state > maxState) {
                        maxState = state;
                    }
                }
            }
        }

        // the index is the number of the png icon file
        let index = 0;
        switch (maxState) {
            case DeviceStateEnum.ok:
                index = 1;
                break;
            case DeviceStateEnum.warning:
                index = 2;
                break;
            default:
                index = 3;
                break;
        }

        const retVal: ClusterIconInfo = {
            index,
            text: clusterCount.toString(),
            title: `${clusterCount} device${clusterCount > 1 ? 's' : ''} at ${DeviceStateEnumHelpers.toDisplayName(maxState).toLocaleLowerCase()} or better`
        };

        return retVal;
    };

    @Input()
    public get devices(): Array<DeviceModel> {
        return this._devices;
    }
    public set devices(value: Array<DeviceModel>) {
        if (!this.isNullOrUndefined(value) && this.areIncomingDevicesDifferent(value)){
            this._devices = value;
            this._mapBounds = new google.maps.LatLngBounds();

            if (!this.isNullOrUndefined(this.deviceGroups) && this.deviceGroups.length > 0){
                this.deviceGroups.splice(0, this.deviceGroups.length);
            }
            else{
                this.deviceGroups = [];
            }

            // Sort out the map bounds first
            if (!this.isNullOrUndefined(value) && value.length > 0) {
                const length = value.length;
                for (let i = 0; i < length; i++) {
                    const device = value[i];

                    if (!(device.latitude === 0 && device.longitude === 0)){
                        const position = new google.maps.LatLng(device.latitude, device.longitude);
                        this._mapBounds.extend(position);
                    }
                }
            }

            if (!this.isNullOrUndefined(this.map)){
                if (this._mapBounds.isEmpty() && !this.isNullOrUndefined(this.map.googleMap)){
                    this.map.googleMap.setCenter(new google.maps.LatLng(this.defaultLatitude, this.defaultLongitude));
                    this.map.googleMap.setZoom(this.defaultZoom);
                }
                else{
                    this.map.fitBounds(this._mapBounds);
                }
            }

            const tempGroups = [];
            // Now sort out the grouping
            if (!this.isNullOrUndefined(value) && value.length > 0) {
                const length = value.length;
                for (let i = 0; i < length; i++) {
                    const device = value[i];

                    if (!(device.latitude === 0 && device.longitude === 0)){
                        let group = tempGroups.find(dg => dg.latitude === device.latitude && dg.longitude === device.longitude);

                        if (this.isNullOrUndefined(group)) {
                            group = {
                                latitude: device.latitude,
                                longitude: device.longitude,
                                markerIcon: this.getMarkerIcon(device.state),
                                maxState: device.state,
                                maxStateText: DeviceStateEnumHelpers.toDisplayName(device.state).toLocaleLowerCase(),
                                friendlySerials: new Array(device.friendlySerial)
                            };
                            tempGroups.push(group);
                        } else if (group.friendlySerials.indexOf(device.friendlySerial) === -1) {
                            group.friendlySerials.push(device.friendlySerial);
                            if (device.state > group.maxState) {
                                group.maxState = device.state;
                                group.maxStateText = DeviceStateEnumHelpers.toDisplayName(device.state).toLocaleLowerCase();
                                group.markerIcon = this.getMarkerIcon(device.state);
                            }
                        }
                    }
                }

                tempGroups.forEach(v => this.deviceGroups.push(v));

                timer(1).subscribe(()=>{
                    this.clusterer?.markerClusterer?.repaint();
                });
            }
        }
    }

    ngAfterViewInit() {
    }

    public getTitle(group: IDeviceGroup): string {
        return `${group.friendlySerials.length} device${group.friendlySerials.length > 1 ? 's' : ''} at ${group.maxStateText} or better`;
    }

    public getMarkerIcon(state: DeviceStateEnum): string {
        switch (state) {
            case DeviceStateEnum.ok:
                return './assets/maps/m1.png';
            case DeviceStateEnum.warning:
                return './assets/maps/m2.png';
            default:
                return './assets/maps/m3.png';
        }
    }

    private areIncomingDevicesDifferent(value: Array<DeviceModel>): boolean{
        if (this.isNullOrUndefined(this._devices) || this.isNullOrUndefined(value)){
            return true;
        }

        if (this._devices.length !== value.length){
            return true;
        }

        const length = this._devices.length;

        // Only check the values this component is interested in
        for (let i = 0; i < length; i++){
            if (this._devices[i].friendlySerial !== value[i].friendlySerial ||
                this._devices[i].state !== value[i].state ||
                this._devices[i].longitude !== value[i].longitude ||
                this._devices[i].latitude !== value[i].latitude){
                return true;
            }
        }

        return false;
    }
}
