import { AnyUtility } from '@shared/utility/Any.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, Subject } from 'rxjs';
import { finalize, share, map, tap } from 'rxjs/operators';
import { UniqueIdUtility } from '@shared/utility/UniqueId.Utility';

export class ObservableLoading<TResult> {
    public id: number;

    private _complete: Subject<any> = new Subject<any>();
    private _isLoading: boolean = false;
    private _loadingObservable: Observable<TResult> = new Observable<TResult>();


    public constructor(public readonly params: Array<any>) {
        this.id = UniqueIdUtility.getNext('ObservableLoading');
    }

    /**
     * Gets the current observable or creates a new observable and returns it.
     *
     * @param {Observable<TResult>} observable
     * @returns {Observable<TResult>}
     * @memberof ObservableLoading
     */
    public observable(observable: Observable<TResult>): Observable<TResult> {
        if (!isNullOrUndefined(observable)) {
            if (this._isLoading === true) {
                return this._loadingObservable;
            } else {
                this._isLoading = true;
                this._loadingObservable = observable.pipe(
                    share(),
                    tap(() => {
                        this.complected();
                    }),
                );
                return this._loadingObservable;
            }
        }
    }

    /**
     * Fires when the observable finalize
     *
     * @readonly
     * @type {Subject<any>}
     * @memberof ObservableLoading
     */
    public get complete(): Subject<any> {
        return this._complete;
    }

    /**
     * True if the observable has completed. else false.
     *
     * @readonly
     * @type {boolean}
     * @memberof ObservableLoading
     */
    public get isLoading(): boolean {
        return this._isLoading;
    }

    private complected(): void {
        this._isLoading = false;
        this.complete.next(null);
    }
}

/**
 * Ensures that if an observable is still executing that same observable is handed back
 * to any further request instead of creating a new one.
 *
 * @export
 * @class ObservableTracker
 * @template TResult
 */
export class ObservableTracker<TResult> {
    private _observableLoadings: ObservableLoading<TResult>[] = [];

    /**
     * Get a executing observable with the matching parameters or creates new if none present.
     *
     * @param {...Array<any>} params
     * @returns {ObservableLoading<TResult>}
     * @memberof ObservableTracker
     */
    public getLoading(...params: Array<any>): ObservableLoading<TResult> {
        return this.getParamsStore(this._observableLoadings, params) as ObservableLoading<TResult>;
    }

    public clear(): void {
        this._observableLoadings = [];
    }

    private getParamsStore(observableLoadings: Array<ObservableLoading<TResult>>, ...params: Array<any>): ObservableLoading<TResult> {
        let index = this.getIndexOfParamsStoreByParams(observableLoadings, params);
        if (index === -1) {
            const observableLoading = new ObservableLoading<TResult>(params);
            index = (observableLoadings.push(observableLoading) - 1);
            observableLoading.complete.subscribe(() => {
                observableLoadings.splice(index, 1);
            });
            return observableLoading;
        } else {
            return observableLoadings[index];
        }
    }

    private getIndexOfParamsStoreByParams(observableLoadings: Array<ObservableLoading<TResult>>, params: Array<any>): number {
        const length = observableLoadings.length;
        for (let li = 0; li < length; li++) {
            const loading = observableLoadings[li];
            if (isNullOrUndefined(loading.params) && isNullOrUndefined(params)) {
                return li;
            } else if (loading.params.length === params.length) {
                const paramsLength = loading.params.length;
                let match = true;
                for (let pi = 0; pi < paramsLength; pi++) {
                    if (!AnyUtility.equal(loading.params[pi], params[pi])) {
                        match = false;
                        break;
                    }
                }

                if (match) {
                    return li;
                }
            }
        }

        return -1;
    }
}
