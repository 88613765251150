import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface IPongModel {
}

export class PongModel extends BaseModel implements IPongModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'pong';

    public isIWebSocketModel: boolean = true;
    public packetType: string = 'pong';
    public id: number = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-PongModel');
    }

    public toWebSocketMessage(): any {
        return {
            ...WebSocketModelUtility.toJson(this),
        };
    }
}
