import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { LineCollectionModel } from '@rift/models/restapi/LineCollection.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class RestApiLinesService extends RiftRestApiService {
    private _controller = 'lines';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public getLine(id: number, process?: ProcessMonitorServiceProcess): Observable<LineModel> {
        return this.get<LineModel>(`${this._controller}/${id.toString()}`, LineModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getLines(process?: ProcessMonitorServiceProcess): Observable<LineCollectionModel> {
        return this.get<LineCollectionModel>(`${this._controller}`, LineCollectionModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateLine(line: LineModel, id: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<LineModel, ResultModel>(`${this._controller}/${id.toString()}`, line, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateLines(lines: Array<LineModel>, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<Array<LineModel>, ResultModel>(`${this._controller}`, lines, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
