<div class="rift-validate-counting-network-unique-master-select-dialog-title" mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Select Unique Master
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div class="rift-validate-counting-network-unique-master-select-dialog-content" mat-dialog-content fxLayout="column" style="background-color: #fafafa;">
    <mat-card class="spacing-bottom spacing-top">
        <mat-card-content>
            Each counting network must have a unique master device.
            <br>
            Please select a device from the list below to become the master.
        </mat-card-content>
    </mat-card>
    <mat-card class="spacing-top spacing-bottom">
        <mat-card-content>
            <mat-radio-group class="radio-group" fxLayout="column" [(ngModel)]="master">
                <ng-template ngFor let-device [ngForOf]="data.devices" let-i="index">
                    <mat-radio-button class="radio-button" [value]="device">{{ device.serialNumber }} ({{ device.master === true ? 'Master' : 'Node' }}) {{device.isLocalUnit === true ? '(Local)' : ''}}</mat-radio-button>
                </ng-template>
            </mat-radio-group>
        </mat-card-content>
    </mat-card>
</div>
<div class="rift-validate-counting-network-unique-master-select-dialog-actions" mat-dialog-actions>
    <div fxFlex></div>
    <button id="Save" mat-raised-button color="primary" [disabled]="isValid === false"
        (click)="onSaveClick()">Save</button>
</div>
