import { Component, HostBinding, HostListener, Injector, Renderer2, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsCountingMenuBaseComponent } from '@rift/components/settings/counting/base/Settings.Counting.MenuBase.Component';
import { DeviceAdvancedSettingsModel } from '@rift/models/restapi/DeviceAdvancedSettings.Model';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { IPosition } from 'angular2-draggable';
import { ISize } from 'angular2-draggable/lib/models/size';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'rift-settings-counting-advanced-settings',
    templateUrl: './Settings.Counting.AdvancedSettings.Component.html',
    styleUrls: ['./Settings.Counting.AdvancedSettings.Component.scss']
})
export class SettingsCountingAdvancedSettingsComponent extends SettingsCountingMenuBaseComponent implements ILoadDate, ISaveAllChanges {
    public static className: string = 'SettingsCountingAdvancedSettingsComponent';

    @Input()
    public get bounds(): HTMLElement {
        return this._bounds;
    }
    public set bounds(value: HTMLElement) {
        this._bounds = value;
        this.checkPosition();
    }

    @Input()
    public get zIndex(): number {
        return this._zIndex;
    }
    public set zIndex(value: number) {
        this._zIndex = value;
    }

    @HostBinding()
    public id: string = 'rift-settings-counting-advanced-settings';

    @LocalStorage(SettingsCountingAdvancedSettingsComponent.className, 'position')
    public position: IPosition;

    @LocalStorage(SettingsCountingAdvancedSettingsComponent.className, 'show')
    public show: boolean;

    public size: ISize;

    public advancedSettings: DeviceAdvancedSettingsModel;
    public form: FormGroup = null;
    public formValuesChangedProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _render: Renderer2,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_render, _injector, _dialog);

        this.formValuesChangedProcess = this.processMonitorService.getProcess(SettingsCountingAdvancedSettingsComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingAdvancedSettingsComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsCountingAdvancedSettingsComponent.className, this.saveAllChangesProcessText);

        this.form = this._formBuilder.group({
            deferredInitialization: ['', Validators.compose([Validators.required])],
        });

        this.addSubscription(
            this.observableHandlerBase(this.form.valueChanges, this.formValuesChangedProcess).subscribe(() => {
                if (!this.isNullOrUndefined(this.advancedSettings)) {
                    this.advancedSettings.deferredInitialization = this.form.controls.deferredInitialization.value;
                }
            })
            , this.formValuesChangedProcess);

        this.minWidth = 250;

        this.initConnectionState();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return true;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.deviceService.getAdvancedSettings(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.advancedSettings = result;
                        this.changeTracker.track(this.advancedSettings);
                        this.setFormValues(this.advancedSettings);
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public onSaveClick(): void {
        this.saveAllChangesStartBase(this, this.openPleaseWaitSavingDialog());
    }

    public reset(): void {
        this.changeTracker.clearChanges();
        this.advancedSettings = null;
        this.form.reset();
        this.changeTracker.clear();
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            this.deviceService.updateAdvancedSettings(this.advancedSettings, process).pipe(
                map(() => true)
            )
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process);
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this.deviceService.clearCache();
            return of(true);
        });
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this);
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this);
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.form.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.form.enable();
    }

    private setFormValues(settings: DeviceAdvancedSettingsModel): void {
        this.form.controls.deferredInitialization.setValue(settings.deferredInitialization, { emitEvent: false });
    }
}
