import { Injectable } from '@angular/core';
import { MQTTModel } from '@rift/models/restapi/MQTT.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiMQTTService } from '@rift/service/restapi/v1/RestApi.MQTT.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RestApiGlobalService } from '@rift/service/restapi/v1/RestApi.Global.Service';
import { TestConnectionResultModel } from '@rift/models/restapi/TestConnectionResult.Model';
import { TestConnectionDataModel } from '@rift/models/restapi/TestConnectionData.Model';

@Injectable()
export class MQTTService extends RiftBaseService {

    private _getMQTTLoadingTracker = new ObservableTracker<MQTTModel>();
    private _mqttCache: MQTTModel;
    private _setMQTTLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiGlobalService: RestApiGlobalService,
        private readonly _restApiMQTTService: RestApiMQTTService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._mqttCache = null;
    }
    public getMQTT(process?: ProcessMonitorServiceProcess): Observable<MQTTModel> {
        if (isNullOrUndefined(this._mqttCache)) {
            return this._getMQTTLoadingTracker
                .getLoading()
                .observable(this._restApiMQTTService.getMQTT(process).pipe(
                    map(result => {
                        this._mqttCache = result;
                        return this._mqttCache;
                    })
                ));
        } else {
            return of(this._mqttCache);
        }
    }

    public setCache(mqttCache: MQTTModel): void {
        this._mqttCache = mqttCache;
    }

    public setMQTT(mqtt: MQTTModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setMQTTLoadingTracker
            .getLoading(mqtt)
            .observable(this._restApiMQTTService.setMQTT(mqtt, process).pipe(tap(() => this._mqttCache = null)));
    }
}
