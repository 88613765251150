import { RegisterAlternativeModel } from '@rift/models/restapi/RegisterAlternative.Model';
import { RegisterBasicModel } from '@rift/models/restapi/RegisterBasic.Model';
import { RegisterBasicExternalModel } from '@rift/models/restapi/RegisterBasicExternal.Model';
import { RegisterBeamBreakModel } from '@rift/models/restapi/RegisterBeamBreak.Model';
import { RegisterCompareGreaterThanModel } from '@rift/models/restapi/RegisterCompareGreaterThan.Model';
import { RegisterCompareLessThanModel } from '@rift/models/restapi/RegisterCompareLessThan.Model';
import { RegisterCustomModel } from '@rift/models/restapi/RegisterCustom.Model';
import { RegisterInvalidModel } from '@rift/models/restapi/RegisterInvalid.Model';
import { RegisterLineExclusionModel } from '@rift/models/restapi/RegisterLineExclusion.Model';
import { RegisterOccupancyModel } from '@rift/models/restapi/RegisterOccupancy.Model';
import { RegisterOccupancyMasterModel } from '@rift/models/restapi/RegisterOccupancyMaster.Model';
import { RegisterOccupancyResetModel } from '@rift/models/restapi/RegisterOccupancyReset.Model';
import { RegisterOccupancyWaitModel } from '@rift/models/restapi/RegisterOccupancyWait.Model';
import { RegisterPortalModel } from '@rift/models/restapi/RegisterPortal.Model';
import { RegisterPulseGeneratorModel } from '@rift/models/restapi/RegisterPulseGenerator.Model';
import { RegisterQueueWaitModel } from '@rift/models/restapi/RegisterQueueWait.Model';
import { RegisterQueueWaitMasterModel } from '@rift/models/restapi/RegisterQueueWaitMaster.Model';
import { RegisterRemoteValueModel } from '@rift/models/restapi/RegisterRemoteValue.Model';
import { RegisterSequentialModel } from '@rift/models/restapi/RegisterSequential.Model';
import { RegisterSmartBoundaryModel } from '@rift/models/restapi/RegisterSmartBoundary.Model';
import { RegisterStaffAttendModel } from '@rift/models/restapi/RegisterStaffAttend.Model';
import { RegisterStaffCallModel } from '@rift/models/restapi/RegisterStaffCall.Model';
import { RegisterSummationModel } from '@rift/models/restapi/RegisterSummation.Model';
import { RegisterTailgateModel } from '@rift/models/restapi/RegisterTailgate.Model';
import { RegisterTamperModel } from '@rift/models/restapi/RegisterTamper.Model';
import { RegisterUniversalTriggerModel } from '@rift/models/restapi/RegisterUniversalTrigger.Model';
import { RegisterZoneModel } from '@rift/models/restapi/RegisterZone.Model';
import { RegisterZoneOccupancyModel } from '@rift/models/restapi/RegisterZoneOccupancy.Model';
import { RegisterZoneWaitTimeModel } from '@rift/models/restapi/RegisterZoneWaitTime.Model';
import { RegisterZoneWaitTimeSumModel } from '@rift/models/restapi/RegisterZoneWaitTimeSum.model';
import { RegisterTypeConfig } from '@rift/shared/Settings.RegisterConfig';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { RegisterFIFOOccupancyModel } from '@rift/models/restapi/RegisterFIFOOccupancy.Model';
import { RegisterFIFOOccupancyTimeoutModel } from '@rift/models/restapi/RegisterFIFOOccupancyTimeout.Model';
import { RegisterFIFOOccupancyMasterModel } from '@rift/models/restapi/RegisterFIFOOccupancyMaster.Model';
import { RegisterErrorStatusModel } from '@rift/models/restapi/RegisterErrorStatus.Model';
import { RegisterRemoteReceiverModel} from '@rift/models/restapi/RegisterRemoteReceiver.Model';
import { RegisterWirelessPIRModel } from '@rift/models/restapi/RegisterWirelessPIR.Model';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ZoneTypeEnum } from '@shared/enum/ZoneType.Enum';

export function SetRegisterNewConfigs(): void {
    RegisterTypeConfig[RegisterTypeEnum.invalid].new = () => new RegisterInvalidModel();
    RegisterTypeConfig[RegisterTypeEnum.invalid].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterInvalidModel);

    RegisterTypeConfig[RegisterTypeEnum.alternative].new = () => new RegisterAlternativeModel();
    RegisterTypeConfig[RegisterTypeEnum.alternative].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterAlternativeModel);;

    RegisterTypeConfig[RegisterTypeEnum.basicExternal].new = () => new RegisterBasicExternalModel();
    RegisterTypeConfig[RegisterTypeEnum.basicExternal].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterBasicExternalModel);

    RegisterTypeConfig[RegisterTypeEnum.basic].new = () => new RegisterBasicModel();
    RegisterTypeConfig[RegisterTypeEnum.basic].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterBasicModel);

    RegisterTypeConfig[RegisterTypeEnum.beamBreak].new = () => new RegisterBeamBreakModel();
    RegisterTypeConfig[RegisterTypeEnum.beamBreak].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterBeamBreakModel);

    RegisterTypeConfig[RegisterTypeEnum.custom].new = () => new RegisterCustomModel();
    RegisterTypeConfig[RegisterTypeEnum.custom].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterCustomModel);

    RegisterTypeConfig[RegisterTypeEnum.sequential].new = () => new RegisterSequentialModel();
    RegisterTypeConfig[RegisterTypeEnum.sequential].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterSequentialModel);

    RegisterTypeConfig[RegisterTypeEnum.summation].new = () => new RegisterSummationModel();
    RegisterTypeConfig[RegisterTypeEnum.summation].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterSummationModel);

    RegisterTypeConfig[RegisterTypeEnum.tamper].new = () => new RegisterTamperModel();
    RegisterTypeConfig[RegisterTypeEnum.tamper].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterTamperModel);

    RegisterTypeConfig[RegisterTypeEnum.heightLine].new = () => new RegisterBasicModel();
    RegisterTypeConfig[RegisterTypeEnum.heightLine].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterBasicModel);

    RegisterTypeConfig[RegisterTypeEnum.zone].new = () => new RegisterZoneModel();
    RegisterTypeConfig[RegisterTypeEnum.zone].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterZoneModel);
    RegisterTypeConfig[RegisterTypeEnum.zone].maxDwellAllowed = (value) => {
        const reg: RegisterZoneModel = value as RegisterZoneModel;

        if(!isNullOrUndefined(reg)){
            if(reg.zoneType === ZoneTypeEnum.deferred){
                return true;
            }
        }

        return false;
    };
    RegisterTypeConfig[RegisterTypeEnum.zone].dwellHistogramAllowed = (value) => {
        const reg: RegisterZoneModel = value as RegisterZoneModel;

        if(!isNullOrUndefined(reg)){
            if(reg.zoneType === ZoneTypeEnum.deferred){
                return true;
            }
        }

        return false;
    };

    RegisterTypeConfig[RegisterTypeEnum.zoneOccupancy].new = () => new RegisterZoneOccupancyModel();
    RegisterTypeConfig[RegisterTypeEnum.zoneOccupancy].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterZoneOccupancyModel);

    RegisterTypeConfig[RegisterTypeEnum.lineExclusion].new = () => new RegisterLineExclusionModel();
    RegisterTypeConfig[RegisterTypeEnum.lineExclusion].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterLineExclusionModel);

    RegisterTypeConfig[RegisterTypeEnum.portal].new = () => new RegisterPortalModel();
    RegisterTypeConfig[RegisterTypeEnum.portal].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterPortalModel);

    RegisterTypeConfig[RegisterTypeEnum.staffCall].new = () => new RegisterStaffCallModel();
    RegisterTypeConfig[RegisterTypeEnum.staffCall].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterStaffCallModel);

    RegisterTypeConfig[RegisterTypeEnum.staffAttend].new = () => new RegisterStaffAttendModel();
    RegisterTypeConfig[RegisterTypeEnum.staffAttend].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterStaffAttendModel);

    RegisterTypeConfig[RegisterTypeEnum.occupancyMaster].new = () => new RegisterOccupancyMasterModel();
    RegisterTypeConfig[RegisterTypeEnum.occupancyMaster].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterOccupancyMasterModel);

    RegisterTypeConfig[RegisterTypeEnum.occupancy].new = () => new RegisterOccupancyModel();
    RegisterTypeConfig[RegisterTypeEnum.occupancy].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterOccupancyModel);

    RegisterTypeConfig[RegisterTypeEnum.occupancyReset].new = () => new RegisterOccupancyResetModel();
    RegisterTypeConfig[RegisterTypeEnum.occupancyReset].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterOccupancyResetModel);

    RegisterTypeConfig[RegisterTypeEnum.occupancyWait].new = () => new RegisterOccupancyWaitModel();
    RegisterTypeConfig[RegisterTypeEnum.occupancyWait].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterOccupancyWaitModel);

    RegisterTypeConfig[RegisterTypeEnum.queueWaitMaster].new = () => new RegisterQueueWaitMasterModel();
    RegisterTypeConfig[RegisterTypeEnum.queueWaitMaster].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterQueueWaitMasterModel);

    RegisterTypeConfig[RegisterTypeEnum.queueWait].new = () => new RegisterQueueWaitModel();
    RegisterTypeConfig[RegisterTypeEnum.queueWait].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterQueueWaitModel);

    RegisterTypeConfig[RegisterTypeEnum.smartBoundary].new = () => new RegisterSmartBoundaryModel();
    RegisterTypeConfig[RegisterTypeEnum.smartBoundary].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterSmartBoundaryModel);

    RegisterTypeConfig[RegisterTypeEnum.zoneWaitTime].new = () => new RegisterZoneWaitTimeModel();
    RegisterTypeConfig[RegisterTypeEnum.zoneWaitTime].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterZoneWaitTimeModel);

    RegisterTypeConfig[RegisterTypeEnum.greaterThanCompare].new = () => new RegisterCompareGreaterThanModel();
    RegisterTypeConfig[RegisterTypeEnum.greaterThanCompare].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterCompareGreaterThanModel);

    RegisterTypeConfig[RegisterTypeEnum.lessThanCompare].new = () => new RegisterCompareLessThanModel();
    RegisterTypeConfig[RegisterTypeEnum.lessThanCompare].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterCompareLessThanModel);

    RegisterTypeConfig[RegisterTypeEnum.zoneWaitTimeSum].new = () => new RegisterZoneWaitTimeSumModel();
    RegisterTypeConfig[RegisterTypeEnum.zoneWaitTimeSum].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterZoneWaitTimeSumModel);

    RegisterTypeConfig[RegisterTypeEnum.pulseGenerator].new = () => new RegisterPulseGeneratorModel();
    RegisterTypeConfig[RegisterTypeEnum.pulseGenerator].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterPulseGeneratorModel);

    RegisterTypeConfig[RegisterTypeEnum.tailgate].new = () => new RegisterTailgateModel();
    RegisterTypeConfig[RegisterTypeEnum.tailgate].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterTailgateModel);

    RegisterTypeConfig[RegisterTypeEnum.remoteValue].new = () => new RegisterRemoteValueModel();
    RegisterTypeConfig[RegisterTypeEnum.remoteValue].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterRemoteValueModel);

    RegisterTypeConfig[RegisterTypeEnum.universalTrigger].new = () => new RegisterUniversalTriggerModel();
    RegisterTypeConfig[RegisterTypeEnum.universalTrigger].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterUniversalTriggerModel);

    RegisterTypeConfig[RegisterTypeEnum.fifoOccupancy].new = () => new RegisterFIFOOccupancyModel();
    RegisterTypeConfig[RegisterTypeEnum.fifoOccupancy].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterFIFOOccupancyModel);

    RegisterTypeConfig[RegisterTypeEnum.fifoOccupancyTimeout].new = () => new RegisterFIFOOccupancyTimeoutModel();
    RegisterTypeConfig[RegisterTypeEnum.fifoOccupancyTimeout].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterFIFOOccupancyTimeoutModel);

    RegisterTypeConfig[RegisterTypeEnum.fifoOccupancyMaster].new = () => new RegisterFIFOOccupancyMasterModel();
    RegisterTypeConfig[RegisterTypeEnum.fifoOccupancyMaster].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterFIFOOccupancyMasterModel);

    RegisterTypeConfig[RegisterTypeEnum.errorStatus].new = () => new RegisterErrorStatusModel();
    RegisterTypeConfig[RegisterTypeEnum.errorStatus].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterErrorStatusModel);

    RegisterTypeConfig[RegisterTypeEnum.remoteReceiver].new = () => new RegisterRemoteReceiverModel();
    RegisterTypeConfig[RegisterTypeEnum.remoteReceiver].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterRemoteReceiverModel);

    RegisterTypeConfig[RegisterTypeEnum.wirelessPIR].new = () => new RegisterWirelessPIRModel();
    RegisterTypeConfig[RegisterTypeEnum.wirelessPIR].loadFromRestApi = (restModel) => RestModelUtility.loadFrom(restModel, RegisterWirelessPIRModel);

}
