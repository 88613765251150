import { Injectable } from '@angular/core';
import { BaseService } from '@shared/base/Base.Service';
import { ConnectionStatusEnum } from '@shared/enum/ConnectionStatus.Enum';
import { Subject } from 'rxjs';

/**
 * The navigation bar service.
 *
 * @export
 * @class NavBarService
 * @extends {BaseService}
 */
@Injectable()
export class NavBarService extends BaseService {

    /**
     * Fired when a request to chenge the connection state of rift is made.
     *
     * @type {Subject<ConnectionStatusEnum>}
     * @memberof NavBarService
     */
    public riftConnectionStateRequest: Subject<ConnectionStatusEnum> = new Subject<ConnectionStatusEnum>();

    /**
     * Fired when the connected / disconnected state of device changes.
     *
     * @type {Subject<boolean>}
     * @memberof NavBarService
     */
    public emDeviceConnectedChange: Subject<boolean> = new Subject<boolean>();

    /**
     * Fires when the connection count of the rift device changes.
     *
     * @type {Subject<number>}
     * @memberof NavBarService
     */
    public riftConnectionCountChange: Subject<number> = new Subject<number>();

    /**
     * Fires when the help tour icon is clicked.
     *
     * @type {Subject<null>}
     * @memberof NavBarService
     */
    public helpTourClicked: Subject<null> = new Subject<null>();

    /**
     * Fires when the riftConnectionState changes.
     *
     * @type {Subject<ConnectionStatusEnum>}
     * @memberof NavBarService
     */
    public riftConnectionStateChange: Subject<ConnectionStatusEnum> = new Subject<ConnectionStatusEnum>();
    /**
     * Fires when the showRiftConnectionState changes.
     *
     * @type {Subject<boolean>}
     * @memberof NavBarService
     */
    public showRiftInfoChange: Subject<boolean> = new Subject<boolean>();
    /**
     * Fires when the connection state is forced.
     *
     * @type {Subject<ConnectionStatusEnum>}
     * @memberof NavBarService
     */
    public forcedRiftConnectionStateChanged: Subject<ConnectionStatusEnum> = new Subject<ConnectionStatusEnum>();
    /**
     * Fires when the licenseServerUnavailable state is changed
     *
     * @memberof NavBarService
     */
    public emLicenseServerUnavailableChanged: Subject<boolean> = new Subject<boolean>();
    /**
     * Fires when the emUnLicensed state is changed
     *
     * @memberof NavBarService
     */
    public emUnLicensedChanged: Subject<boolean> = new Subject<boolean>();
    /**
     * Fires when the deviceUnLicensed state is changed
     *
     * @memberof NavBarService
     */
    public emDeviceUnLicensedChanged: Subject<boolean> = new Subject<boolean>();

    public postBreadCrumbTextChanged: Subject<string> = new Subject<string>();
    public preBreadCrumbTextChanged: Subject<string> = new Subject<string>();
    public emLastConnectedChanged: Subject<Date> = new Subject<Date>();

    private _showRiftInfo: boolean = false;
    private _riftConnectionCount: number = null;
    private _riftConnectionState: ConnectionStatusEnum;
    private _riftDeviceConnected: boolean;
    private _emLicenseServerUnavailable: boolean = false;
    private _emUnLicensed: boolean = false;
    private _emDeviceUnLicensed: boolean = false;
    private _preBreadCrumbText: string;
    private _postBreadCrumbText: string;
    private _emLastConnected: Date;

    /**
     * Creates an instance of NavBarService.
     *
     * @memberof NavBarService
     */
    public constructor() {
        super();
    }

    public get postBreadCrumbText(): string {
        return this._postBreadCrumbText;
    }
    public set postBreadCrumbText(value: string) {
        if (this._postBreadCrumbText !== value) {
            this._postBreadCrumbText = value;
            this.postBreadCrumbTextChanged.next(value);
        }
    }

    public get preBreadCrumbText(): string {
        return this._preBreadCrumbText;
    }
    public set preBreadCrumbText(value: string) {
        if (this._preBreadCrumbText !== value) {
            this._preBreadCrumbText = value;
            this.preBreadCrumbTextChanged.next(value);
        }
    }

    public get emLicenseServerUnavailable(): boolean {
        return this._emLicenseServerUnavailable;
    }
    public set emLicenseServerUnavailable(value: boolean) {
        if (this._emLicenseServerUnavailable !== value) {
            this._emLicenseServerUnavailable = value;
            this.emLicenseServerUnavailableChanged.next(value);
        }
    }

    public get emUnLicensed(): boolean {
        return this._emUnLicensed;
    }
    public set emUnLicensed(value: boolean) {
        if (this._emUnLicensed !== value) {
            this._emUnLicensed = value;
            this.emUnLicensedChanged.next(value);
        }
    }

    public get emDeviceUnLicensed(): boolean {
        return this._emDeviceUnLicensed;
    }
    public set emDeviceUnLicensed(value: boolean) {
        if (this._emDeviceUnLicensed !== value) {
            this._emDeviceUnLicensed = value;
            this.emDeviceUnLicensedChanged.next(value);
        }
    }

    public get emLastConnected(): Date{
        return this._emLastConnected;
    }
    public set emLastConnected(value: Date){
        if(this._emLastConnected !== value){
            this._emLastConnected = value;
            this.emLastConnectedChanged.next(value);
        }
    }


    /**
     * Gets the current ridf device connection count.
     *
     * @type {number}
     * @memberof NavBarService
     */
    public get riftConnectionCount(): number {
        return this._riftConnectionCount;
    }
    public set riftConnectionCount(value: number) {
        if (this._riftConnectionCount !== value) {
            this._riftConnectionCount = value;
            this.riftConnectionCountChange.next(value);
        }
    }

    /**
     * Force the connection state.
     *
     * @param {ConnectionStatusEnum} state
     * @memberof NavBarService
     */
    public forceRiftConnectionState(state: ConnectionStatusEnum): void {
        this.forcedRiftConnectionStateChanged.next(state);
    }

    /**
     * Resuest for connection state of rift to be changed.
     *
     * @param {ConnectionStatusEnum} state
     * @memberof NavBarService
     */
    public setRiftConnectionState(state: ConnectionStatusEnum): void {
        this.riftConnectionStateRequest.next(state);
    }

    /**
     * Sets a value that indicates if the rift state should be shown.
     *
     * @memberof NavBarService
     */
    public set showRiftInfo(value: boolean) {
        if (this._showRiftInfo !== value) {
            this._showRiftInfo = value;
            this.showRiftInfoChange.next(value);
        }
    }
    public get showRiftInfo(): boolean {
        return this._showRiftInfo;
    }

    /**
     * Sets the current connected / disconnected state of em device.
     *
     * @memberof NavBarService
     */
    public set emDeviceConnected(value: boolean) {
        if (this._riftDeviceConnected !== value) {
            this._riftDeviceConnected = value;
            this.emDeviceConnectedChange.next(value);
        }
    }
    public get emDeviceConnected(): boolean {
        return this._riftDeviceConnected;
    }

    /**
     * Sets the current connection state of rift.
     *
     * @memberof NavBarService
     */
    public set riftConnectionState(value: ConnectionStatusEnum) {
        if (this._riftConnectionState !== value) {
            this._riftConnectionState = value;
            this.riftConnectionStateChange.next(value);
        }
    }

    /**
     * Sets the current ridf device connection count.
     *
     * @memberof NavBarService
     */

    /**
     * The current connection state of rift.
     *
     * @type {ConnectionStatusEnum}
     * @memberof NavBarService
     */
    public get riftConnectionState(): ConnectionStatusEnum {
        return this._riftConnectionState;
    }
}
