import { Component, HostBinding, Injector, OnChanges, SimpleChanges, Input } from '@angular/core';
import { SettingsCountingBaseComponent } from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { LineExclusionTypeEnum, LineExclusionTypeEnumHelpers } from '@shared/enum/LineExclusionRegisterType.Enum';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';

@Component({
    selector: 'rift-settings-counting-exclusion-type',
    templateUrl: './Settings.Counting.ExclusionType.Component.html',
    styleUrls: ['./Settings.Counting.ExclusionType.Component.scss']
})
export class SettingsCountingExclusionTypeComponent extends SettingsCountingBaseComponent implements OnChanges {
    public static className: string = 'SettingsCountingExclusionTypeComponent';
    public static lineExclusionRegisterTypeDefault: LineExclusionTypeEnum = LineExclusionTypeEnum.noCountIfEntered;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    private _exclusionRegister: RegisterBaseModel & { lineExclusionRegisterType: LineExclusionTypeEnum } = null;

    @HostBinding()
    public id: string = 'rift-settings-counting-exclusion-type';

    public form: FormGroup;

    public LineExclusionTypeEnum = LineExclusionTypeEnum;
    public LineExclusionTypeEnumHelpers = LineExclusionTypeEnumHelpers;

    @Input()
    public set register(value: RegisterBaseModel){
        this._exclusionRegister = (value as RegisterBaseModel & { lineExclusionRegisterType: LineExclusionTypeEnum });
    }

    public constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsCountingExclusionTypeComponent.className, 'Form values change');

        this.form = this._formBuilder.group(
            {
                lineExclusionRegisterType: [''],
            }
        );

        this.addSubscription(this.observableHandlerBase(this.form.controls.lineExclusionRegisterType.valueChanges, this.formValuesChangeProcess).subscribe(value => {
            if (!this.isNullOrUndefined(this._exclusionRegister)) {
                this._exclusionRegister.lineExclusionRegisterType = value;
            }
        }), this.formValuesChangeProcess);

        this.formGroupTracker.track(this.form);

        this.initConnectionState();
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this._exclusionRegister) ? false : this._exclusionRegister.propertyHasChanges('lineExclusionRegisterType');
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this._exclusionRegister = changes.register.currentValue;
            this.setDefaults();
            this.setFormValues();
        }
    }

    public getSettingsDescription(): string {
        return this.isNullOrUndefined(this._exclusionRegister) ? '' : LineExclusionTypeEnumHelpers.toFullLongName(this._exclusionRegister.lineExclusionRegisterType);
    }

    private setDefaults(): void {
        if (this.isNullOrUndefined(this._exclusionRegister.lineExclusionRegisterType)) {
            this._exclusionRegister.lineExclusionRegisterType = SettingsCountingExclusionTypeComponent.lineExclusionRegisterTypeDefault;
            this._exclusionRegister.commitChanges();
        }
    }

    private setFormValues(): void {
        this.form.controls.lineExclusionRegisterType.setValue(this.isNullOrUndefined(this._exclusionRegister.lineExclusionRegisterType) ? SettingsCountingExclusionTypeComponent.lineExclusionRegisterTypeDefault : this._exclusionRegister.lineExclusionRegisterType, { emitEvent: false });
    }
}
