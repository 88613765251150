import { Component, HostListener, Injector, NgZone, OnDestroy, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { GlobalModel } from '@rift/models/restapi/Global.Model';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { TimeModel } from '@rift/models/websocket/Time.Model';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { StreamTypeEnum } from '@shared/enum/StreamType.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility, TimeZone } from '@shared/utility/DateTime.Utility';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { SyncTimeModel } from '@rift/models/restapi/SyncTime.Model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';


@Component({
    selector: 'rift-settings-site',
    templateUrl: './Settings.Site.Component.html',
    styleUrls: ['./Settings.Site.Component.scss']
})
export class SettingsSiteComponent extends RiftBaseComponent implements OnDeactivate, OnDestroy, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsSiteComponent';

    @HostBinding()
    public id: string = 'rift-settings-site';

    public global: GlobalModel;
    public timeZones: Array<TimeZone> = DateTimeUtility.timeZones;

    public siteDetailsFormGroup: FormGroup;
    public localizationFormGroup: FormGroup;
    public selectedTimeZone: TimeZone;
    public syncTimeProcess: ProcessMonitorServiceProcess;
    public time: TimeModel;
    public timeCurrent: string;
    public timeSelected: string;
    public timeSetup: TimeSetupModel;
    public timeUTC: string;
    public timeZoneUTC: TimeZone = this.timeZones.find(i => i.index === 0);
    public formValuesChangeProcess: ProcessMonitorServiceProcess;
    public isDeviceGenProcess: ProcessMonitorServiceProcess;

    private _timeStreamId: number;

    public constructor(
        private readonly _zone: NgZone,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsSiteComponent.className, 'Form values change');
        this.isDeviceGenProcess = this.processMonitorService.getProcess(SettingsSiteComponent.className, 'Is device gen');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsSiteComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsSiteComponent.className, this.saveAllChangesProcessText);
        this.syncTimeProcess = this.processMonitorService.getProcess(SettingsSiteComponent.className, 'Syncing time');

        this.addSaveAllAction(this);

        this.addSubscription(this.observableHandlerBase(this.webSocketService.timeMessageReceived, this.formValuesChangeProcess).subscribe(message => this.onTimeMessageReceived(message)), this.formValuesChangeProcess);

        this.siteDetailsFormGroup = this._formBuilder.group({
            deviceId: ['', Validators.compose([Validators.required, Validators.maxLength(159)])],
            deviceName: ['', Validators.compose([Validators.required, Validators.maxLength(59)])],
            userString: ['', Validators.compose([Validators.required, Validators.maxLength(59)])],
            siteId: ['', Validators.compose([Validators.required, Validators.maxLength(59)])],
            siteName: ['', Validators.compose([Validators.required, Validators.maxLength(59)])],
        });
        this.formGroupTracker.track(this.siteDetailsFormGroup);

        this.addSubscription(this.observableHandlerBase(this.siteDetailsFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesSiteDetailsFormGroup()), this.formValuesChangeProcess);

        this.localizationFormGroup = this._formBuilder.group({
            locale: ['', Validators.compose([Validators.required, Validators.maxLength(59)])],
            timezone: ['', Validators.compose([Validators.required])],
            autoDstAdjust: ['', Validators.compose([])],
            nTPServer: ['', Validators.compose([Validators.maxLength(400)])]
        });
        this.formGroupTracker.track(this.localizationFormGroup);

        this.addSubscription(this.observableHandlerBase(this.localizationFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesLocalizationFormGroup()), this.formValuesChangeProcess);

        this.addSubscription(this.observableHandlerBase(this.localizationFormGroup.controls.timezone.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            if (this.localizationFormGroup.controls.timezone.value !== null && this.localizationFormGroup.controls.timezone.value !== '') {
                this.selectedTimeZone = this.localizationFormGroup.controls.timezone.value;
                if (this.selectedTimeZone.supportsDST === true) {
                    if(this.isReadOnly === true){
                        this.localizationFormGroup.controls.autoDstAdjust.disable();
                    }
                    else{
                        this.localizationFormGroup.controls.autoDstAdjust.enable();
                    }
                } else {
                    this.localizationFormGroup.controls.autoDstAdjust.setValue(false);
                    this.localizationFormGroup.controls.autoDstAdjust.disable();
                }
                this.updateTimes();
            }
        }), this.formValuesChangeProcess);

        this.initConnectionState();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.globalService.getGlobal(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.global = result;
                        this.changeTracker.track(this.global);
                        this.setSiteDetailsFormGroupValues();
                    }
                    return true;
                })
            ),
            this.globalService.getTimeSetup(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.timeSetup = result;
                        this.changeTracker.track(this.timeSetup);
                        this.setLocalizationFormGroupValues();
                    }
                    return true;
                })
            )
        ).pipe(
            tap(() => {
                this.addSubscription(this.observableHandlerBase(this.isDeviceAnyGen([UnitGenerationEnum.gen4, UnitGenerationEnum.gazelle]), this.isDeviceGenProcess).subscribe(result => {
                    if (this.isReadOnly || !result) {
                        this.localizationFormGroup.controls.locale.disable();
                    } else {
                        this.localizationFormGroup.controls.locale.enable();
                    }
                }), this.isDeviceGenProcess);
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.webSocketService.stopStream(StreamTypeEnum.time, this._timeStreamId);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.global.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this.globalService.updateGlobal(this.global, process).pipe(
                            map(() => true)
                        );
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.timeSetup.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this.globalService.updateTimeSetup(this.timeSetup, process).pipe(
                            map(() => true)
                        );
                    } else {
                        return of(true);
                    }
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(false);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this.globalService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    public syncTime(): void {
        const syncTime = new SyncTimeModel();
        syncTime.time = new Date();
        this.addSubscription(this.observableHandlerBase(this.globalService.syncTime(syncTime, this.syncTimeProcess), this.syncTimeProcess).subscribe(result=>{
            if(!this.isNullOrUndefined(result) && this.isNullOrUndefined(result.error)){
                this.openOkCancelDialog('Time Sync', 'Time sync successful', false);
            }
            else{
                this.openOkCancelDialog('Time Sync', 'Time sync failed', false);
            }
        }), this.syncTimeProcess);
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
        this.webSocketService.stopStream(StreamTypeEnum.time, this._timeStreamId);
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
        this.global = null;
        this.timeSetup = null;
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
        this._timeStreamId = this.webSocketService.startStream(StreamTypeEnum.time);
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.siteDetailsFormGroup.disable();
        this.localizationFormGroup.disable();
        this.localizationFormGroup.controls.autoDstAdjust.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.siteDetailsFormGroup.enable();
        this.localizationFormGroup.enable();

        if(!this.isNullOrUndefined(this.selectedTimeZone)){
            if (this.selectedTimeZone.supportsDST === true) {
                this.localizationFormGroup.controls.autoDstAdjust.enable();
            } else {
                this.localizationFormGroup.controls.autoDstAdjust.setValue(false);
                this.localizationFormGroup.controls.autoDstAdjust.disable();
            }
        }
    }

    private onTimeMessageReceived(message: TimeModel): any {
        this._zone.run(() => {
            this.time = message;
            this.updateTimes();
        });
    }

    private updateTimes(): void {
        if (!this.isNullOrUndefined(this.time) && !this.isNullOrUndefined(this.time.data)) {
            this.timeSelected = DateTimeUtility.toLongDateTime(this.time.data, this.isNullOrUndefined(this.selectedTimeZone) ? null : this.selectedTimeZone);
            this.timeCurrent = DateTimeUtility.toLongDateTime(this.time.data, this.isNullOrUndefined(this.timeSetup) ? null : this.timeZones.find(i => i.index === this.timeSetup.getPropertyOriginalValue('timezoneIrisysIndex')));
            this.timeUTC = DateTimeUtility.toLongDateTime(this.time.data, this.timeZoneUTC);
        }
    }

    private setLocalizationFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.timeSetup)) {
            this.localizationFormGroup.setValue({
                locale: this.timeSetup.locale,
                timezone: this.timeZones.find(i => i.index === this.timeSetup.timezoneIrisysIndex),
                autoDstAdjust: this.timeSetup.autoDstAdjust,
                nTPServer: this.timeSetup.nTPServer,
            });
        }
    }

    private setSiteDetailsFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.global)) {
            this.siteDetailsFormGroup.setValue({
                deviceId: this.global.deviceId,
                deviceName: this.global.deviceName,
                userString: this.global.userString,
                siteId: this.global.siteId,
                siteName: this.global.siteName,
            });
        }
    }

    private updateModelValuesLocalizationFormGroup(): void {
        if (!this.isNullOrUndefined(this.timeSetup) && this.isReadOnly === false) {
            const formValues = this.localizationFormGroup.getRawValue();

            this.timeSetup.locale = this.isNullOrUndefined(formValues.locale) ? '' : formValues.locale;
            this.timeSetup.timezoneIrisysIndex = formValues.timezone.index;
            this.timeSetup.autoDstAdjust = formValues.autoDstAdjust;
            this.timeSetup.nTPServer = formValues.nTPServer;

            this.updateSaveAllAction(this);
        }
    }

    private updateModelValuesSiteDetailsFormGroup(): void {
        if (!this.isNullOrUndefined(this.global) && this.isReadOnly === false) {
            const formValues = this.siteDetailsFormGroup.value;

            this.global.deviceId = formValues.deviceId;
            this.global.deviceName = formValues.deviceName;
            this.global.userString = formValues.userString;
            this.global.siteId = formValues.siteId;
            this.global.siteName = formValues.siteName;

            this.updateSaveAllAction(this);
        }
    }
}
