import { isNullOrUndefined } from '@shared/utility/General.Utility';

export interface ISanitizeOptions {
    replacement?: string;
}

export class FileUtility {
    private static _illegalRe = /[\/\?<>\\:\*\|"]/g;
    private static _controlRe = /[\x00-\x1f\x80-\x9f]/g;
    private static _reservedRe = /^\.+$/;
    private static _windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
    private static _windowsTrailingRe = /[\. ]+$/;

    public static Uint8ToBase64(u8Arr: Uint8Array): string {
        const CHUNK_SIZE = 0x8000;
        let index = 0;
        const length = u8Arr.length;
        let result = '';
        let slice;
        while (index < length) {
            slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
            result += String.fromCharCode.apply(null, slice);
            index += CHUNK_SIZE;
        }
        return btoa(result);
    }

    public static sanitize(value: string, options?: ISanitizeOptions): string {
        if (!isNullOrUndefined(value)) {
            const replacement = (options && options.replacement) || '';
            return value
                .replace(this._illegalRe, replacement)
                .replace(this._controlRe, replacement)
                .replace(this._reservedRe, replacement)
                .replace(this._windowsReservedRe, replacement)
                .replace(this._windowsTrailingRe, replacement);
        }

        return value;
    }
}
