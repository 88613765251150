import { IDatabaseSettings } from '@rift/service/validation/database/IDatabaseSettings';
import { DatabaseSessionBookmarkStore } from '@rift/service/validation/database/syncsession/Database.SessionBookmarks.Store';
import { DatabaseSessionInfoStore } from '@rift/service/validation/database/syncsession/Database.SessionInfo.Store';
import { DatabaseUserCountStore } from '@rift/service/validation/database/syncsession/Database.UserCount.Store';

export const DatabaseSettings: IDatabaseSettings = {
    databaseVersion: 5,
    databaseName: 'session',
    databaseStores: [
        DatabaseSessionInfoStore,
        DatabaseUserCountStore,
        DatabaseSessionBookmarkStore,
    ],
} as IDatabaseSettings;
