import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface IConnectionResponseModel {
    status: string;
}

export class ConnectionResponseModel extends BaseModel implements IConnectionResponseModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'general_response';

    public isIWebSocketModel: boolean = true;
    public packetType: string = null;
    public id: number = null;

    public status: string = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-ConnectionResponseModel');
    }

    public toWebSocketMessage(): any {
        return {
            ...WebSocketModelUtility.toJson(this),
        };
    }
}
