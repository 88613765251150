export enum TargetStatusEnum {
    unknown = 0,
    valid = 1,
    couple = 2,
    new = 4,
    updated = 8,
    dead = 16,
    marked = 32
}

export class TargetStatusEnumHelpers {
    public static toString(status: TargetStatusEnum): string {
        switch (status) {
            case TargetStatusEnum.couple:
                return 'couple';
            case TargetStatusEnum.dead:
                return 'dead';
            case TargetStatusEnum.marked:
                return 'marked';
            case TargetStatusEnum.new:
                return 'new';
            case TargetStatusEnum.unknown:
                return 'unknown';
            case TargetStatusEnum.updated:
                return 'updated';
            case TargetStatusEnum.valid:
                return 'valid';
        }
    }

    public static getSelected(status: number): Array<TargetStatusEnum> {
        if (status === TargetStatusEnum.unknown) { return []; }

        const selected: TargetStatusEnum[] = [];

        for (const item in TargetStatusEnum) {
            if (!Number.isNaN(Number(item))) {
                const val = Number(item);
                // tslint:disable-next-line:no-bitwise
                if (val !== 0 && (status & val) === val) {
                    selected.push(val as TargetStatusEnum);
                }
            }
        }

        return selected;
    }
}
