import { Component, Inject, Injector, HostBinding } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { SyncActionEnum } from '@rift/service/validation/models/SyncState.Enum';
import { ISessionSyncState } from '@rift/service/validation/models/webworker/syncsession/ISessionSyncState';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

export class SessionSyncResolveData {
    public constructor(public readonly sessionSyncState: ISessionSyncState) { }
}

export class SessionSyncResolveResult {
    public constructor(public readonly syncAction?: SyncActionEnum) { }
}

@Component({
    selector: 'rift-session-sync-resolve',
    templateUrl: './Session.Sync.Resolve.Component.html',
    styleUrls: ['./Session.Sync.Resolve.Component.scss']
})
export class SessionSyncResolveComponent extends RiftBaseComponent {

    @HostBinding()
    public id: string = 'rift-session-sync-resolve';

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: SessionSyncResolveData,
        private readonly _dialog: MatDialog,
        private readonly _dialogRef: MatDialogRef<SessionSyncResolveComponent>,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess('SessionSyncResolveComponent', this.loadDataProcessText);

        this.initConnectionState();
    }

    public take(): void {
        this._dialogRef.close(new SessionSyncResolveResult(SyncActionEnum.take));
    }

    public merge(): void {
        this._dialogRef.close(new SessionSyncResolveResult(SyncActionEnum.merge));
    }

    public keep(): void {
        this._dialogRef.close(new SessionSyncResolveResult(SyncActionEnum.keep));
    }

    public cancel(): void {
        this._dialogRef.close(new SessionSyncResolveResult());
    }
}
