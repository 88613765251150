import { Component, HostListener, Injector, OnDestroy, OnInit, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeyValuePairModel } from '@em/models/restapi/KeyValuePair.Model';
import { SettingService } from '@em/service/data/setting/Setting.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { BooleanUtility } from '@shared/utility/Boolean.Utility';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'em-settings-options-tasklauncher',
    templateUrl: './Settings.Options.TaskLauncher.Component.html',
    styleUrls: ['./Settings.Options.TaskLauncher.Component.scss']
})
export class SettingsOptionsTaskLauncherComponent extends BaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsOptionsTaskLauncherComponent';

    @HostBinding()
    public id: string = 'em-settings-options-tasklauncher';
    public settingTaskLauncherEnabled: KeyValuePairModel;

    public settingTaskLauncherRootPath: KeyValuePairModel;
    public taskLauncherFormGroup: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _formBuilder: FormBuilder,
        private readonly _settingService: SettingService,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsOptionsTaskLauncherComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsOptionsTaskLauncherComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsOptionsTaskLauncherComponent.className, this.saveAllChangesProcessText);

        this.addSaveAllAction(this);

        this.taskLauncherFormGroup = this._formBuilder.group({
            taskLauncherEnabled: ['', Validators.compose([])],
            taskLauncherRootPath: ['', Validators.compose([Validators.required])],
        });
        this.formGroupTracker.track(this.taskLauncherFormGroup);

        this.addSubscription(this.observableHandlerBase(this.taskLauncherFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.onFormGroupValueChanges()), this.formValuesChangeProcess);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._settingService.getSetting('TaskLauncherEnabled', 'TaskLauncherSettings', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingTaskLauncherEnabled = result;
                        this.changeTracker.track(result);
                        this.taskLauncherFormGroup.controls.taskLauncherEnabled.setValue(BooleanUtility.toBoolean(result.value));
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('TaskLauncherRootPath', 'TaskLauncherSettings', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingTaskLauncherRootPath = result;
                        this.changeTracker.track(result);
                        this.taskLauncherFormGroup.controls.taskLauncherRootPath.setValue(result.value);
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.settingTaskLauncherEnabled.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('TaskLauncherEnabled', 'TaskLauncherSettings', this.settingTaskLauncherEnabled.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingTaskLauncherRootPath.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('TaskLauncherRootPath', 'TaskLauncherSettings', this.settingTaskLauncherRootPath.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(result);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._settingService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    private onFormGroupValueChanges(): void {
        this.updateModels();
        this.updateSaveAllAction(this);
    }

    private updateModels(): void {
        const formModel = this.taskLauncherFormGroup.value;

        if (!this.isNullOrUndefined(this.settingTaskLauncherEnabled) && !this.isNullOrUndefined(formModel.taskLauncherEnabled)) {
            if (formModel.taskLauncherEnabled === true) {
                this.settingTaskLauncherEnabled.value = 'True';
            } else {
                this.settingTaskLauncherEnabled.value = 'False';
            }
        }
        if (!this.isNullOrUndefined(this.settingTaskLauncherRootPath) && !this.isNullOrUndefined(formModel.taskLauncherRootPath)) {
            this.settingTaskLauncherRootPath.value = formModel.taskLauncherRootPath;
        }
    }
}
