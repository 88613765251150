<div id="em-dashboard-default"
    #mainContent>
    <div #healthContent
        shrResized
        (resized)="onHealthContentResized($event)">
        <mat-card class="health">
            <shr-background-load-spinner [trigger]="loadDataProcess.isRunning"
                class="led">
            </shr-background-load-spinner>
            <div fxLayout="row"
                fxLayoutAlign="center center"
                class="block-title">
                Devices Health
            </div>
            <div fxLayout="row">
                <div routerLink="/dashboard/devicehealth/1"
                    class="item-column"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    [style.color]="deviceHealthService.getLevelColor(1)">
                    <span ngClass.gt-sm="devices-in-level"
                        ngClass.lt-md="devices-in-level-small">{{ healthCounts?.overall?.level1Percentage | number : '1.0-0' }}%</span>
                    <i ngClass.gt-sm="fa-3x"
                        ngClass.lt-md="fa-2x"
                        class="icon-overall fa fa-thumbs-up"></i>
                </div>
                <mat-divider class="divider"
                    [vertical]="true"
                    style="height: 70px;"></mat-divider>
                <div routerLink="/dashboard/devicehealth/2"
                    class="item-column"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    [style.color]="deviceHealthService.getLevelColor(2)">
                    <span ngClass.gt-sm="devices-in-level"
                        ngClass.lt-md="devices-in-level-small">{{ healthCounts?.overall?.level2Percentage | number : '1.0-0' }}%</span>
                    <i ngClass.gt-sm="fa-3x"
                        ngClass.lt-md="fa-2x"
                        class="icon-overall fa fa-thumbs-up"></i>
                </div>
                <mat-divider class="divider"
                    [vertical]="true"
                    style="height: 70px;"></mat-divider>
                <div routerLink="/dashboard/devicehealth/3"
                    class="item-column"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    [style.color]="deviceHealthService.getLevelColor(3)">
                    <span ngClass.gt-sm="devices-in-level"
                        ngClass.lt-md="devices-in-level-small">{{ healthCounts?.overall?.level3Percentage | number : '1.0-0' }}%</span>
                    <i ngClass.gt-sm="fa-3x"
                        ngClass.lt-md="fa-2x"
                        class="icon-overall fa fa-thumbs-down"></i>
                </div>
            </div>
            <div class="bottom-text">
                Click a device health to view additional information about the devices in that group.
            </div>
        </mat-card>
    </div>
    <div #mapContent *ngIf="googleMapsPresent() === true">
        <em-device-map class="mat-elevation-z4"
            [devices]="devices"></em-device-map>
    </div>
</div>