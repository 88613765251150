import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IFOVCoveragePointModel extends IRestModel {
    x: number;
    y: number;
}

export class FOVCoveragePointModel extends BaseModel implements IFOVCoveragePointModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public x: number = null;
    public y: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-FOVCoveragePointModel');
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }

    public toInterface(): IFOVCoveragePointModel {
        return {
            x: this.x,
            y: this.y,
        } as IFOVCoveragePointModel;
    }
}
