import { Injectable } from '@angular/core';
import { ApplyLicenceResultModel } from '@em/models/restapi/ApplyLicenceResult.Model';
import { LicenceStatusModel } from '@em/models/restapi/LicenceStatus.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiLicenceService } from '@em/service/restapi/RestApi.Licence.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ShortLicenceStatusModel } from '@em/models/restapi/ShortLicenceStatus.Model';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';


@Injectable()
export class LicenceService extends EmBaseService {

    private _applyLicenceKeyLoadingTracker = new ObservableTracker<ApplyLicenceResultModel>();
    private _downloadCLPLoadingTracker = new ObservableTracker<string>();
    private _downloadReleaseCLPLoadingTracker = new ObservableTracker<string>();
    private _getShortStatusLoadingTracker = new ObservableTracker<ShortLicenceStatusModel>();
    private _getStatusCache: LicenceStatusModel;
    private _getStatusLoadingTracker = new ObservableTracker<LicenceStatusModel>();
    private _releaseLicenceLoadingTracker = new ObservableTracker<ApplyLicenceResultModel>();
    private _validateLicenceKeyLoadingTracker = new ObservableTracker<boolean>();

    public constructor(
        private readonly _restApiLicenceService: RestApiLicenceService) {
        super();
    }

    public applyLicenceKey(key: string, process?: ProcessMonitorServiceProcess): Observable<ApplyLicenceResultModel> {
        return this._applyLicenceKeyLoadingTracker
            .getLoading(key)
            .observable(this._restApiLicenceService.applyLicenceKey(key, process).pipe(tap(() => this.clearCache())));
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getStatusCache = null;
    }

    public downloadCLP(key: string, process?: ProcessMonitorServiceProcess): Observable<string> {
        return this._downloadCLPLoadingTracker
            .getLoading(key)
            .observable(this._restApiLicenceService.downloadCLP(key, process));
    }

    public downloadReleaseCLP(key: string, process?: ProcessMonitorServiceProcess): Observable<string> {
        return this._downloadReleaseCLPLoadingTracker
            .getLoading(key)
            .observable(this._restApiLicenceService.downloadReleaseCLP(key, process).pipe(tap(() => this.clearCache())));
    }

    public getShortStatus(process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ShortLicenceStatusModel> {
        return this._getShortStatusLoadingTracker
            .getLoading()
            .observable(this._restApiLicenceService.getShortStatus(process, retryOptions));
    }

    public getStatus(process?: ProcessMonitorServiceProcess): Observable<LicenceStatusModel> {
        if (isNullOrUndefined(this._getStatusCache)) {
            return this._getStatusLoadingTracker
                .getLoading()
                .observable(
                    this._restApiLicenceService.getStatus(process).pipe(
                        map(result => {
                            this._getStatusCache = result;
                            return this._getStatusCache;
                        })
                    ));
        } else {
            return of(this._getStatusCache);
        }
    }

    public releaseLicence(process?: ProcessMonitorServiceProcess): Observable<ApplyLicenceResultModel> {
        return this._releaseLicenceLoadingTracker
            .getLoading()
            .observable(this._restApiLicenceService.releaseLicence(process).pipe(tap(() => this.clearCache())));
    }

    public uploadILP(file: File, process?: ProcessMonitorServiceProcess): Observable<ApplyLicenceResultModel> {
        return this._restApiLicenceService.uploadILP(file, process).pipe(
            map(result => {
                this.clearCache();
                return result;
            })
        );
    }

    public validateLicenceKey(key: string, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._validateLicenceKeyLoadingTracker
            .getLoading(key)
            .observable(this._restApiLicenceService.validateLicenceKey(key, process));
    }
}
