import { DiscoveredDeviceRegisterModel, IDiscoveredDeviceRegisterModel } from '@rift/models/restapi/DiscoveredDeviceRegister.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IDiscoveredDeviceModel extends IRestModel {
    serialNumber: string;
    master: boolean;
    iPAddress: string;
    nodes: string[];
    productID: string;
    registers: IDiscoveredDeviceRegisterModel[];
    errors: string[];
    warnings: string[];
}

export class DiscoveredDeviceModel extends BaseModel implements IDiscoveredDeviceModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public serialNumber: string = null;
    public master: boolean = null;
    public iPAddress: string = null;
    public nodes: string[] = null;
    public productID: string = null;
    public canBeSelected: boolean = false;
    public registers: DiscoveredDeviceRegisterModel[] = null;
    public errors: string[] = null;
    public warnings: string[] = null;


    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-DiscoveredDeviceModel', ['canBeSelected']);

        this.registers = RestModelUtility.loadFromArray(restModel.Registers, DiscoveredDeviceRegisterModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, ['canBeSelected']),
        };
    }
}
