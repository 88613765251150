import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { CountLogModel } from '@rift/models/restapi/CountLog.Model';

export interface ICountLogPageModel extends IRestModel {
    deviceSerial: number;
    friendlyDeviceSerial: string;
    nextIndex: number;
}

export class CountLogPageModel extends BaseModel implements ICountLogPageModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public deviceSerial: number = null;
    public friendlyDeviceSerial: string = null;
    public nextIndex: number = null;

    public countLogs: Array<CountLogModel> = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-CountLogPageModel');

        this.countLogs = RestModelUtility.loadFromArray(restModel.CountLogs, CountLogModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
