<div class="em-settings-schedules-addedit-title"
    mat-dialog-title>
    {{ mode === 'add' ? 'Add ' : 'Edit '}}Schedule
</div>
<div class="em-settings-schedules-addedit-content"
    mat-dialog-content
    fxLayout="column">
    <mat-horizontal-stepper fxShow
        fxHide.lt-sm="true"
        [linear]="mode === 'add'"
        #stepper>
        <mat-step [stepControl]="descriptionFormGroup">
            <form [formGroup]="descriptionFormGroup">
                <div fxLayout="column">
                    <ng-template matStepLabel>Description</ng-template>
                    <mat-form-field>
                        <input type="text"
                            #description
                            matInput
                            formControlName="description"
                            placeholder="Description"
                            autofocus>
                        <span matSuffix>{{ schedule?.propertyHasChangesText('description') }}</span>
                        <mat-error>{{getFormControlErrorMessage(descriptionFormGroup.controls['description'])}}
                        </mat-error>
                        <mat-hint align="end">{{description.value.length}} / 200</mat-hint>
                    </mat-form-field>
                    <div class="step-actions"
                        fxFlex
                        fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <div></div>
                        <div fxLayout="row">
                            <button style="margin-right: 10px;"
                                mat-raised-button
                                matStepperNext>Next</button>
                            <div fxLayout="row">
                                <shr-progress-button-spinner *ngIf="mode === 'edit'"
                                    [disabled]="descriptionFormGroup.valid === false || deviceOrGroupFormGroup.valid === false || whenFormGroup.valid === false || actionsFormGroup.valid === false"
                                    style="margin-right: 10px;"
                                    [raised]="true"
                                    [diameter]="30"
                                    text="Save"
                                    mode="indeterminate"
                                    [active]="saveProcess.isRunning === true"
                                    (shrClick)="saveAllChanges()"
                                    color="primary"></shr-progress-button-spinner>
                                <div fxFlex></div>
                                <button [disabled]="saveProcess.isRunning === true"
                                    (click)="cancel()"
                                    mat-raised-button
                                    color="warn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="deviceOrGroupFormGroup">
            <form [formGroup]="deviceOrGroupFormGroup">
                <div fxLayout="column">
                    <ng-template matStepLabel>Devices or group</ng-template>
                    <span class="row-text">Operate on</span>
                    <div fxLayout="row">
                        <mat-form-field>
                            <mat-select #operatesOn
                                formControlName="operatesOn"
                                placeholder="On">
                                <mat-option [value]="ScheduleGranularityEnum.all">
                                    All Devices
                                </mat-option>
                                <mat-option [value]="ScheduleGranularityEnum.folder">
                                    Group
                                </mat-option>
                                <mat-option [value]="ScheduleGranularityEnum.device">
                                    Device
                                </mat-option>
                            </mat-select>
                            <mat-error>{{getFormControlErrorMessage(deviceOrGroupFormGroup.controls['operatesOn'])}}
                            </mat-error>
                            <span matSuffix>{{ schedule?.propertyHasChangesText('allDevices') }}</span>
                        </mat-form-field>
                        <ng-template [ngIf]="deviceOrGroupFormGroup.controls['operatesOn'].value === ScheduleGranularityEnum.folder">
                            <mat-form-field class="group-field">
                                <em-address-book-auto-complete #folderId
                                    formControlName="folderId">
                                </em-address-book-auto-complete>
                                <span matSuffix>{{ schedule?.propertyHasChangesText('operatesOn') }}</span>
                                <mat-error>{{getFormControlErrorMessage(deviceOrGroupFormGroup.controls['operatesOn'])}}
                                </mat-error>
                            </mat-form-field>
                        </ng-template>
                        <ng-template [ngIf]="deviceOrGroupFormGroup.controls['operatesOn'].value === ScheduleGranularityEnum.device">
                            <mat-form-field class="friendlySerialNumber-field">
                                <em-device-autocomplete #friendlySerialNumber
                                    formControlName="friendlySerialNumber">
                                </em-device-autocomplete>
                                <span matSuffix>{{ schedule?.propertyHasChangesText('friendlySerialNumber') }}</span>
                                <mat-error>
                                    {{getFormControlErrorMessage(deviceOrGroupFormGroup.controls['friendlySerialNumber'])}}
                                </mat-error>
                            </mat-form-field>
                        </ng-template>
                    </div>
                    <div class="step-actions"
                        fxFlex
                        fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <button mat-raised-button
                            matStepperPrevious>Back</button>
                        <div fxLayout="row">
                            <button style="margin-right: 10px;"
                                mat-raised-button
                                matStepperNext>Next</button>
                            <div fxLayout="row">
                                <shr-progress-button-spinner *ngIf="mode === 'edit'"
                                    [disabled]="descriptionFormGroup.valid === false || deviceOrGroupFormGroup.valid === false || whenFormGroup.valid === false || actionsFormGroup.valid === false"
                                    style="margin-right: 10px;"
                                    [raised]="true"
                                    [diameter]="30"
                                    text="Save"
                                    mode="indeterminate"
                                    [active]="saveProcess.isRunning === true"
                                    (shrClick)="saveAllChanges()"
                                    color="primary">
                                </shr-progress-button-spinner>
                                <div fxFlex></div>
                                <button [disabled]="saveProcess.isRunning === true"
                                    (click)="cancel()"
                                    mat-raised-button
                                    color="warn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="whenFormGroup">
            <form [formGroup]="whenFormGroup">
                <ng-template matStepLabel>When to execute</ng-template>
                <div fxLayout="column">
                    <div fxLayout="column">
                        <div fxLayout="row"
                            fxLayoutAlign="start center">
                            <span class="row-text">Execute</span>
                            <mat-form-field>
                                <mat-select #execute
                                    formControlName="execute">
                                    <mat-option value="immediately">
                                        Immediately
                                    </mat-option>
                                    <mat-option value="attime">
                                        At Time
                                    </mat-option>
                                    <mat-option value="when">
                                        When
                                    </mat-option>
                                </mat-select>
                                <span matSuffix>{{ schedule?.propertyHasChangesText('executeOnEvent') }}</span>
                                <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['execute'])}}</mat-error>
                            </mat-form-field>
                            <ng-template [ngIf]="whenFormGroup.controls['execute'].value === 'when'">
                                <ng-template [ngIf]="whenFormGroup.controls['action'].value === 'schedulecompletes'">
                                    <span class="row-text"
                                        style="min-width: 22px">the</span>
                                    <mat-form-field>
                                        <mat-select #scheduleCompletes
                                            formControlName="scheduleCompletes"
                                            placeholder="Schedule">
                                            <mat-option *ngFor="let schedule of scheduleOverviews | orderBy: 'description'"
                                                [value]="schedule?.id">
                                                {{schedule?.description}}
                                            </mat-option>
                                        </mat-select>
                                        <span matSuffix>{{ schedule?.propertyHasChangesText('scheduleCompletes') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(whenFormGroup.controls['scheduleCompletes'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-template>
                                <mat-form-field>
                                    <mat-select #action
                                        formControlName="action"
                                        placeholder="Action">
                                        <mat-option value="connects">
                                            a device connects
                                        </mat-option>
                                        <mat-option value="disconnects">
                                            a device disconnects
                                        </mat-option>
                                        <mat-option value="rediscovers">
                                            a device rediscovers
                                        </mat-option>
                                        <mat-option value="settingschanged">
                                            a device flash setting changes
                                        </mat-option>
                                        <mat-option value="schedulecompletes">
                                            schedule completes
                                        </mat-option>
                                    </mat-select>
                                    <span matSuffix>{{ schedule?.propertyHasChangesText('action') }}</span>
                                    <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['action'])}}
                                    </mat-error>
                                </mat-form-field>
                            </ng-template>
                            <ng-template [ngIf]="whenFormGroup.controls['execute'].value === 'attime'">
                                <span class="row-text">starting</span>
                                <mat-form-field>
                                    <input [owlDateTimeTrigger]="datetimeSelect"
                                        [owlDateTime]="datetimeSelect"
                                        #datetime
                                        matInput
                                        formControlName="datetime"
                                        placeholder="Date Time">
                                    <span matSuffix>{{ schedule?.propertyHasChangesText('startTime') }}</span>
                                    <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['datetime'])}}
                                    </mat-error>
                                </mat-form-field>
                                <owl-date-time #datetimeSelect></owl-date-time>
                            </ng-template>
                        </div>
                        <ng-template [ngIf]="whenFormGroup.controls['execute'].value === 'attime'">
                            <div fxLayout="row"
                                fxLayoutAlign="start center">
                                <span class="row-text">and</span>
                                <mat-form-field>
                                    <mat-select #repeats
                                        formControlName="repeats"
                                        placeholder="Repeats">
                                        <mat-option value="never">
                                            Never Repeats
                                        </mat-option>
                                        <mat-option value="repeats">
                                            Repeats
                                        </mat-option>
                                    </mat-select>
                                    <span matSuffix>{{ schedule?.propertyHasChangesText('repeats') }}</span>
                                    <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['repeats'])}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row"
                                fxLayoutAlign="start center">
                                <ng-template [ngIf]="whenFormGroup.controls['repeats'].value === 'repeats'">
                                    <span class="row-text">every</span>
                                    <mat-form-field>
                                        <input type="number"
                                            #every
                                            matInput
                                            formControlName="every">
                                        <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['every'])}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-select #everyRange
                                            formControlName="everyRange">
                                            <mat-option value="minutes">
                                                Minutes
                                            </mat-option>
                                            <mat-option value="hours">
                                                Hours
                                            </mat-option>
                                            <mat-option value="days">
                                                Days
                                            </mat-option>
                                            <mat-option value="weeks">
                                                Weeks
                                            </mat-option>
                                            <mat-option value="months">
                                                Months
                                            </mat-option>
                                        </mat-select>
                                        <span matSuffix>{{ schedule?.propertyHasChangesText('everyRange') }}</span>
                                        <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['everyRange'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-template>
                            </div>
                            <div fxLayout="row"
                                fxLayoutAlign="start center">
                                <ng-template [ngIf]="whenFormGroup.controls['repeats'].value === 'repeats'">
                                    <span class="row-text">for</span>
                                    <ng-template [ngIf]="whenFormGroup.controls['forRange'].value !== 'ever'">
                                        <mat-form-field>
                                            <input type="number"
                                                #for
                                                matInput
                                                formControlName="for">
                                            <span matSuffix>{{ schedule?.propertyHasChangesText('for') }}</span>
                                            <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['for'])}}
                                            </mat-error>
                                        </mat-form-field>
                                    </ng-template>
                                    <mat-form-field>
                                        <mat-select #forRange
                                            formControlName="forRange">
                                            <mat-option value="hours">
                                                Hours
                                            </mat-option>
                                            <mat-option value="days">
                                                Days
                                            </mat-option>
                                            <mat-option value="weeks">
                                                Weeks
                                            </mat-option>
                                            <mat-option value="months">
                                                Months
                                            </mat-option>
                                            <mat-option value="ever">
                                                Ever
                                            </mat-option>
                                        </mat-select>
                                        <span matSuffix>{{ schedule?.propertyHasChangesText('forRange') }}</span>
                                        <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['forRange'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-template>
                            </div>
                        </ng-template>
                    </div>
                    <div class="step-actions"
                        fxFlex
                        fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <button mat-raised-button
                            matStepperPrevious>Back</button>
                        <div fxLayout="row">
                            <button style="margin-right: 10px;"
                                mat-raised-button
                                matStepperNext>Next</button>
                            <div fxLayout="row">
                                <shr-progress-button-spinner *ngIf="mode === 'edit'"
                                    [disabled]="descriptionFormGroup.valid === false || deviceOrGroupFormGroup.valid === false || whenFormGroup.valid === false || actionsFormGroup.valid === false"
                                    style="margin-right: 10px;"
                                    [raised]="true"
                                    [diameter]="30"
                                    text="Save"
                                    mode="indeterminate"
                                    [active]="saveProcess.isRunning === true"
                                    (shrClick)="saveAllChanges()"
                                    color="primary">
                                </shr-progress-button-spinner>
                                <div fxFlex></div>
                                <button [disabled]="saveProcess.isRunning === true"
                                    (click)="cancel()"
                                    mat-raised-button
                                    color="warn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="actionsFormGroup">
            <form [formGroup]="actionsFormGroup">
                <ng-template matStepLabel>Actions</ng-template>
                <div fxLayout="column">
                    <mat-form-field>
                        <mat-select #action
                            formControlName="action"
                            placeholder="Action">
                            <mat-option *ngFor="let workflow of workflows | orderBy: 'workflowName'"
                                [value]="workflow.workflowName">
                                {{workflow.description}}
                            </mat-option>
                        </mat-select>
                        <span matSuffix>{{ schedule?.propertyHasChangesText('action') }}</span>
                        <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['action'])}}
                        </mat-error>
                    </mat-form-field>
                    <div class="step-actions"
                        fxFlex
                        fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <button mat-raised-button
                            matStepperPrevious>Back</button>
                        <div fxLayout="row">
                            <button *ngIf="nextShow === true"
                                style="margin-right: 10px;"
                                mat-raised-button
                                matStepperNext>Next</button>
                            <div fxLayout="row">
                                <shr-progress-button-spinner *ngIf="mode === 'edit'"
                                    [disabled]="descriptionFormGroup.valid === false || deviceOrGroupFormGroup.valid === false || whenFormGroup.valid === false || actionsFormGroup.valid === false"
                                    style="margin-right: 10px;"
                                    [raised]="true"
                                    [diameter]="30"
                                    text="Save"
                                    mode="indeterminate"
                                    [active]="saveProcess.isRunning === true"
                                    (shrClick)="saveAllChanges()"
                                    color="primary">
                                </shr-progress-button-spinner>
                                <div fxFlex></div>
                                <button [disabled]="saveProcess.isRunning === true"
                                    (click)="cancel()"
                                    mat-raised-button
                                    color="warn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Summary</ng-template>
            <div>{{ getSummery() }}.</div>
            <div class="step-actions"
                fxFlex
                fxLayout="row"
                fxLayoutAlign="space-between center">
                <button [disabled]="saveProcess.isRunning === true"
                    mat-raised-button
                    matStepperPrevious>Back</button>
                <div fxLayout="row">
                    <shr-progress-button-spinner
                        [disabled]="descriptionFormGroup.valid === false || deviceOrGroupFormGroup.valid === false || whenFormGroup.valid === false || actionsFormGroup.valid === false"
                        style="margin-right: 10px;"
                        [raised]="true"
                        [diameter]="30"
                        text="Save"
                        mode="indeterminate"
                        [active]="saveProcess.isRunning === true"
                        (shrClick)="saveAllChanges()"
                        color="primary">
                    </shr-progress-button-spinner>
                    <div fxFlex></div>
                    <button [disabled]="saveProcess.isRunning === true"
                        (click)="cancel()"
                        mat-raised-button
                        color="warn">Cancel</button>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
    <mat-vertical-stepper fxHide
        fxShow.lt-sm="true"
        [linear]="mode === 'add'"
        #stepper>
        <mat-step [stepControl]="descriptionFormGroup">
            <form [formGroup]="descriptionFormGroup">
                <div fxLayout="column">
                    <ng-template matStepLabel>Description</ng-template>
                    <mat-form-field>
                        <input type="text"
                            #description
                            matInput
                            formControlName="description"
                            placeholder="Description"
                            autofocus>
                        <span matSuffix>{{ schedule?.propertyHasChangesText('description') }}</span>
                        <mat-error>{{getFormControlErrorMessage(descriptionFormGroup.controls['description'])}}
                        </mat-error>
                        <mat-hint align="end">{{description.value.length}} / 200</mat-hint>
                    </mat-form-field>
                    <div class="step-actions"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="space-between center">
                        <div></div>
                        <div fxLayout="column">
                            <button style="margin-right: 10px;"
                                mat-raised-button
                                matStepperNext>Next</button>
                            <div fxLayout="row">
                                <shr-progress-button-spinner *ngIf="mode === 'edit'"
                                    [disabled]="descriptionFormGroup.valid === false || deviceOrGroupFormGroup.valid === false || whenFormGroup.valid === false || actionsFormGroup.valid === false"
                                    style="margin-right: 10px;"
                                    [raised]="true"
                                    [diameter]="30"
                                    text="Save"
                                    mode="indeterminate"
                                    [active]="saveProcess.isRunning === true"
                                    (shrClick)="saveAllChanges()"
                                    color="primary"></shr-progress-button-spinner>
                                <div fxFlex></div>
                                <button [disabled]="saveProcess.isRunning === true"
                                    (click)="cancel()"
                                    mat-raised-button
                                    color="warn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="deviceOrGroupFormGroup">
            <form [formGroup]="deviceOrGroupFormGroup">
                <div fxLayout="column">
                    <ng-template matStepLabel>Devices or group</ng-template>
                    <span class="row-text">Operate on</span>
                    <div fxLayout="column">
                        <mat-form-field>
                            <mat-select #operatesOn
                                formControlName="operatesOn"
                                placeholder="On">
                                <mat-option [value]="ScheduleGranularityEnum.all">
                                    All Devices
                                </mat-option>
                                <mat-option [value]="ScheduleGranularityEnum.folder">
                                    Group
                                </mat-option>
                                <mat-option [value]="ScheduleGranularityEnum.device">
                                    Device
                                </mat-option>
                            </mat-select>
                            <mat-error>{{getFormControlErrorMessage(deviceOrGroupFormGroup.controls['operatesOn'])}}
                            </mat-error>
                            <span matSuffix>{{ schedule?.propertyHasChangesText('allDevices') }}</span>
                        </mat-form-field>
                        <ng-template [ngIf]="deviceOrGroupFormGroup.controls['operatesOn'].value === ScheduleGranularityEnum.folder">
                            <mat-form-field class="group-field">
                                <em-address-book-auto-complete #folderId
                                    formControlName="folderId">
                                </em-address-book-auto-complete>
                                <span matSuffix>{{ schedule?.propertyHasChangesText('operatesOn') }}</span>
                                <mat-error>{{getFormControlErrorMessage(deviceOrGroupFormGroup.controls['operatesOn'])}}
                                </mat-error>
                            </mat-form-field>
                        </ng-template>
                        <ng-template [ngIf]="deviceOrGroupFormGroup.controls['operatesOn'].value === ScheduleGranularityEnum.device">
                            <mat-form-field class="friendlySerialNumber-field">
                                <em-device-autocomplete #friendlySerialNumber
                                    formControlName="friendlySerialNumber">
                                </em-device-autocomplete>
                                <span matSuffix>{{ schedule?.propertyHasChangesText('friendlySerialNumber') }}</span>
                                <mat-error>
                                    {{getFormControlErrorMessage(deviceOrGroupFormGroup.controls['friendlySerialNumber'])}}
                                </mat-error>
                            </mat-form-field>
                        </ng-template>
                    </div>
                    <div class="step-actions"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="space-between center">
                        <button mat-raised-button
                            matStepperPrevious>Back</button>
                        <div fxLayout="row">
                            <button style="margin-right: 10px;"
                                mat-raised-button
                                matStepperNext>Next</button>
                            <div fxLayout="column">
                                <shr-progress-button-spinner *ngIf="mode === 'edit'"
                                    [disabled]="descriptionFormGroup.valid === false || deviceOrGroupFormGroup.valid === false || whenFormGroup.valid === false || actionsFormGroup.valid === false"
                                    style="margin-right: 10px;"
                                    [raised]="true"
                                    [diameter]="30"
                                    text="Save"
                                    mode="indeterminate"
                                    [active]="saveProcess.isRunning === true"
                                    (shrClick)="saveAllChanges()"
                                    color="primary">
                                </shr-progress-button-spinner>
                                <div fxFlex></div>
                                <button [disabled]="saveProcess.isRunning === true"
                                    (click)="cancel()"
                                    mat-raised-button
                                    color="warn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="whenFormGroup">
            <form [formGroup]="whenFormGroup">
                <ng-template matStepLabel>When to execute</ng-template>
                <div fxLayout="column">
                    <div fxLayout="column">
                        <div fxLayout="column"
                            fxLayoutAlign="start center">
                            <span class="row-text">Execute</span>
                            <mat-form-field>
                                <mat-select #execute
                                    formControlName="execute">
                                    <mat-option value="immediately">
                                        Immediately
                                    </mat-option>
                                    <mat-option value="attime">
                                        At Time
                                    </mat-option>
                                    <mat-option value="when">
                                        When
                                    </mat-option>
                                </mat-select>
                                <span matSuffix>{{ schedule?.propertyHasChangesText('executeOnEvent') }}</span>
                                <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['execute'])}}</mat-error>
                            </mat-form-field>
                            <ng-template [ngIf]="whenFormGroup.controls['execute'].value === 'when'">
                                <ng-template [ngIf]="whenFormGroup.controls['action'].value === 'schedulecompletes'">
                                    <span class="row-text"
                                        style="min-width: 22px">the</span>
                                    <mat-form-field>
                                        <mat-select #scheduleCompletes
                                            formControlName="scheduleCompletes"
                                            placeholder="Schedule">
                                            <mat-option *ngFor="let schedule of scheduleOverviews | orderBy: 'description'"
                                                [value]="schedule?.id">
                                                {{schedule?.description}}
                                            </mat-option>
                                        </mat-select>
                                        <span matSuffix>{{ schedule?.propertyHasChangesText('scheduleCompletes') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(whenFormGroup.controls['scheduleCompletes'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-template>
                                <mat-form-field>
                                    <mat-select #action
                                        formControlName="action"
                                        placeholder="Action">
                                        <mat-option value="connects">
                                            a device connects
                                        </mat-option>
                                        <mat-option value="disconnects">
                                            a device disconnects
                                        </mat-option>
                                        <mat-option value="rediscovers">
                                            a device rediscovers
                                        </mat-option>
                                        <mat-option value="settingschanged">
                                            a device flash setting changes
                                        </mat-option>
                                        <mat-option value="schedulecompletes">
                                            schedule completes
                                        </mat-option>
                                    </mat-select>
                                    <span matSuffix>{{ schedule?.propertyHasChangesText('action') }}</span>
                                    <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['action'])}}
                                    </mat-error>
                                </mat-form-field>
                            </ng-template>
                            <ng-template [ngIf]="whenFormGroup.controls['execute'].value === 'attime'">
                                <span class="row-text">starting</span>
                                <mat-form-field>
                                    <input [owlDateTimeTrigger]="datetimeSelect"
                                        [owlDateTime]="datetimeSelect"
                                        #datetime
                                        matInput
                                        formControlName="datetime"
                                        placeholder="Date Time">
                                    <span matSuffix>{{ schedule?.propertyHasChangesText('startTime') }}</span>
                                    <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['datetime'])}}
                                    </mat-error>
                                </mat-form-field>
                                <owl-date-time #datetimeSelect></owl-date-time>
                            </ng-template>
                        </div>
                        <ng-template [ngIf]="whenFormGroup.controls['execute'].value === 'attime'">
                            <div fxLayout="column"
                                fxLayoutAlign="start center">
                                <span class="row-text">and</span>
                                <mat-form-field>
                                    <mat-select #repeats
                                        formControlName="repeats"
                                        placeholder="Repeats">
                                        <mat-option value="never">
                                            Never Repeats
                                        </mat-option>
                                        <mat-option value="repeats">
                                            Repeats
                                        </mat-option>
                                    </mat-select>
                                    <span matSuffix>{{ schedule?.propertyHasChangesText('repeats') }}</span>
                                    <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['repeats'])}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start center">
                                <ng-template [ngIf]="whenFormGroup.controls['repeats'].value === 'repeats'">
                                    <span class="row-text">every</span>
                                    <mat-form-field>
                                        <input type="number"
                                            #every
                                            matInput
                                            formControlName="every">
                                        <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['every'])}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-select #everyRange
                                            formControlName="everyRange">
                                            <mat-option value="minutes">
                                                Minutes
                                            </mat-option>
                                            <mat-option value="hours">
                                                Hours
                                            </mat-option>
                                            <mat-option value="days">
                                                Days
                                            </mat-option>
                                            <mat-option value="weeks">
                                                Weeks
                                            </mat-option>
                                            <mat-option value="months">
                                                Months
                                            </mat-option>
                                        </mat-select>
                                        <span matSuffix>{{ schedule?.propertyHasChangesText('everyRange') }}</span>
                                        <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['everyRange'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-template>
                            </div>
                            <div fxLayout="column"
                                fxLayoutAlign="start center">
                                <ng-template [ngIf]="whenFormGroup.controls['repeats'].value === 'repeats'">
                                    <span class="row-text">for</span>
                                    <ng-template [ngIf]="whenFormGroup.controls['forRange'].value !== 'ever'">
                                        <mat-form-field>
                                            <input type="number"
                                                #for
                                                matInput
                                                formControlName="for">
                                            <span matSuffix>{{ schedule?.propertyHasChangesText('for') }}</span>
                                            <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['for'])}}
                                            </mat-error>
                                        </mat-form-field>
                                    </ng-template>
                                    <mat-form-field>
                                        <mat-select #forRange
                                            formControlName="forRange">
                                            <mat-option value="hours">
                                                Hours
                                            </mat-option>
                                            <mat-option value="days">
                                                Days
                                            </mat-option>
                                            <mat-option value="weeks">
                                                Weeks
                                            </mat-option>
                                            <mat-option value="months">
                                                Months
                                            </mat-option>
                                            <mat-option value="ever">
                                                Ever
                                            </mat-option>
                                        </mat-select>
                                        <span matSuffix>{{ schedule?.propertyHasChangesText('forRange') }}</span>
                                        <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['forRange'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-template>
                            </div>
                        </ng-template>
                    </div>
                    <div class="step-actions"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="space-between center">
                        <button mat-raised-button
                            matStepperPrevious>Back</button>
                        <div fxLayout="row">
                            <button style="margin-right: 10px;"
                                mat-raised-button
                                matStepperNext>Next</button>
                            <div fxLayout="column">
                                <shr-progress-button-spinner *ngIf="mode === 'edit'"
                                    [disabled]="descriptionFormGroup.valid === false || deviceOrGroupFormGroup.valid === false || whenFormGroup.valid === false || actionsFormGroup.valid === false"
                                    style="margin-right: 10px;"
                                    [raised]="true"
                                    [diameter]="30"
                                    text="Save"
                                    mode="indeterminate"
                                    [active]="saveProcess.isRunning === true"
                                    (shrClick)="saveAllChanges()"
                                    color="primary">
                                </shr-progress-button-spinner>
                                <div fxFlex></div>
                                <button [disabled]="saveProcess.isRunning === true"
                                    (click)="cancel()"
                                    mat-raised-button
                                    color="warn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="actionsFormGroup">
            <form [formGroup]="actionsFormGroup">
                <ng-template matStepLabel>Actions</ng-template>
                <div fxLayout="column">
                    <mat-form-field>
                        <mat-select #action
                            formControlName="action"
                            placeholder="Action">
                            <mat-option *ngFor="let workflow of workflows | orderBy: 'workflowName'"
                                [value]="workflow.workflowName">
                                {{workflow.description}}
                            </mat-option>
                        </mat-select>
                        <span matSuffix>{{ schedule?.propertyHasChangesText('action') }}</span>
                        <mat-error>{{getFormControlErrorMessage(whenFormGroup.controls['action'])}}
                        </mat-error>
                    </mat-form-field>
                    <div class="step-actions"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="space-between center">
                        <button mat-raised-button
                            matStepperPrevious>Back</button>
                        <div fxLayout="row">
                            <button *ngIf="nextShow === true"
                                style="margin-right: 10px;"
                                mat-raised-button
                                matStepperNext>Next</button>
                            <div fxLayout="column">
                                <shr-progress-button-spinner *ngIf="mode === 'edit'"
                                    [disabled]="descriptionFormGroup.valid === false || deviceOrGroupFormGroup.valid === false || whenFormGroup.valid === false || actionsFormGroup.valid === false"
                                    style="margin-right: 10px;"
                                    [raised]="true"
                                    [diameter]="30"
                                    text="Save"
                                    mode="indeterminate"
                                    [active]="saveProcess.isRunning === true"
                                    (shrClick)="saveAllChanges()"
                                    color="primary">
                                </shr-progress-button-spinner>
                                <div fxFlex></div>
                                <button [disabled]="saveProcess.isRunning === true"
                                    (click)="cancel()"
                                    mat-raised-button
                                    color="warn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Summary</ng-template>
            <div>{{ getSummery() }}.</div>
            <div class="step-actions"
                fxFlex
                fxLayout="column"
                fxLayoutAlign="space-between center">
                <button [disabled]="saveProcess.isRunning === true"
                    mat-raised-button
                    matStepperPrevious>Back</button>
                <div fxLayout="row">
                    <shr-progress-button-spinner
                        [disabled]="descriptionFormGroup.valid === false || deviceOrGroupFormGroup.valid === false || whenFormGroup.valid === false || actionsFormGroup.valid === false"
                        style="margin-right: 10px;"
                        [raised]="true"
                        [diameter]="30"
                        text="Save"
                        mode="indeterminate"
                        [active]="saveProcess.isRunning === true"
                        (shrClick)="saveAllChanges()"
                        color="primary">
                    </shr-progress-button-spinner>
                    <div fxFlex></div>
                    <button [disabled]="saveProcess.isRunning === true"
                        (click)="cancel()"
                        mat-raised-button
                        color="warn">Cancel</button>
                </div>
            </div>
        </mat-step>
    </mat-vertical-stepper>
</div>