/**
 * Licence status enumeration.
 *
 * @export
 * @enum {number}
 */
export enum LicenceStateEnum {
    /**
     * Unlicensed state.
     */
    unlicensed = 0,

    /**
     * Licensed state.
     */
    licensed = 1,

    /**
     * Expiring state.
     */
    expiring = 2,

    /**
     * Server unavailable state.
     */
    serverUnavailable = 3
}
