export enum ZoneTypeEnum {
    deferred = 0,
    instantaneous = 1,
}

export class ZoneTypeEnumHelpers {
    public static toFullLongName(value: ZoneTypeEnum): string {
        switch (value) {
            case ZoneTypeEnum.deferred:
                return 'On Exit';
            case ZoneTypeEnum.instantaneous:
                return 'On Enter';
        }
    }
}
