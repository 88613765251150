import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ScheduleEventTypeEnum } from '@shared/enum/ScheduleEventType.Enum';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';


const EXCLUDE_FIELDS = ['lastExecutedText', 'nextExecutionText', 'endExecutionText', 'operatesOnText', 'executionWaitPeriodText', 'friendlySerialNumber', 'scheduledDevice'];

export class ScheduleModel extends BaseModel implements IRestModel {
    public allDevices: boolean = null;
    public description: string = null;
    public endTime: Date = null;
    public executeOnEvent: ScheduleEventTypeEnum = null;

    public id: number = null;
    public isCurrentlyExecuting: boolean = null;
    public iterationPeriod: number = null;
    public nextExecTime: Date = null;
    public parentSchedule: ScheduleModel = null;
    public readonly isIRestModel = true;
    public scheduledDevices: Array<string> = null;
    public scheduledGroup: GroupModel = null;
    public startTime: Date = null;
    public workflowName: string = null;

    private _scheduledDevice: string = null;

    public constructor() {
        super();

        this.registerProperty('description');
        this.registerProperty('allDevices');
        this.registerProperty('scheduledDevice', false, false);
        this.registerProperty('scheduledGroup');
        this.registerProperty('executeOnEvent');
        this.registerProperty('parentSchedule');
        this.registerProperty('startTime');
        this.registerProperty('iterationPeriod');
        this.registerProperty('endTime');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ScheduleModel', EXCLUDE_FIELDS);

        this.nextExecTime = DateTimeNonMomentUtility.fromRestApiDateTime(this.nextExecTime);
        this.endTime = DateTimeNonMomentUtility.fromRestApiDateTime(this.endTime);
        this.startTime = DateTimeNonMomentUtility.fromRestApiDateTime(this.startTime);

        this.scheduledDevices = restModel.ScheduledDevices;
        this.parentSchedule = RestModelUtility.loadFrom(restModel.ParentSchedule, ScheduleModel);

        this.setPropertyOriginalValue('description', this.description);
        this.setPropertyOriginalValue('allDevices', this.allDevices);
        this.setPropertyOriginalValue('scheduledDevice', !isNullOrUndefined(this.scheduledDevices) && this.scheduledDevices.length > 0 ? this.scheduledDevices[0] : '');
        this.setPropertyOriginalValue('scheduledGroup', this.scheduledGroup);
        this.setPropertyOriginalValue('executeOnEvent', this.executeOnEvent);
        this.setPropertyOriginalValue('parentSchedule', this.parentSchedule);
        this.setPropertyOriginalValue('startTime', this.startTime);
        this.setPropertyOriginalValue('iterationPeriod', this.iterationPeriod);
        this.setPropertyOriginalValue('endTime', this.endTime);
    }

    public get scheduledDevice(): string {
        return this._scheduledDevice;
    }

    public set scheduledDevice(value: string) {
        if (this._scheduledDevice !== value) {
            this._scheduledDevice = value;
            if (isNullOrUndefined(value)) {
                this.scheduledDevices = null;
            } else {
                this.scheduledDevices = [value];
            }
            this.onPropertyChanged('scheduledDevice', value);
        }
    }

    public toRestApiModel(): any {
        const dataModel: any = {
            Id: this.id,
            AllDevices: this.allDevices,
            Description: this.description,
            EndTime: DateTimeNonMomentUtility.toRestApiDateTime(this.endTime),
            ExecuteOnEvent: this.executeOnEvent,
            StartTime:  DateTimeNonMomentUtility.toRestApiDateTime(this.startTime),
            WorkflowName: this.workflowName,
        };

        if (!isNullOrUndefined(this.iterationPeriod)) {
            dataModel.IterationPeriod = this.iterationPeriod;
        }

        if (!isNullOrUndefined(this.scheduledDevices)) {
            dataModel.ScheduledDevices = this.scheduledDevices;
        }

        if (!isNullOrUndefined(this.scheduledGroup)) {
            dataModel.ScheduledGroup = this.scheduledGroup.toRestApiModel();
        }

        if (!isNullOrUndefined(this.parentSchedule)) {
            dataModel.ParentSchedule = this.parentSchedule.toRestApiModel();
        }

        return dataModel;
    }
}
