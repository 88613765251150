import { Directive, HostListener, Input, Renderer2 } from '@angular/core';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { MatDialog } from '@angular/material/dialog';
@Directive({
    selector: 'button[shrPrint]'
})
export class PrintDirective {

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _renderer: Renderer2
    ) {

    }

    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @Input()
    set printStyle(values: { [key: string]: { [key: string]: string } }) {
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                this._printStyle.push((key + JSON.stringify(values[key])).replace(/['"]+/g, ''));
            }
        }
        this.returnStyleValues();
    }

    /**
     * @memberof NgxPrintDirective
     * @param cssList
     */
    @Input()
    set styleSheetFile(cssList: string) {
        const linkTagFn = (cssFileName) => `<link rel="stylesheet" type="text/css" href="${cssFileName}">`;
        if (cssList.indexOf(',') !== -1) {
            const valueArr = cssList.split(',');
            for (const val of valueArr) {
                this._styleSheetFile = this._styleSheetFile + linkTagFn(val);
            }
        } else {
            this._styleSheetFile = linkTagFn(cssList);
        }
    }

    public _printStyle = [];

    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @Input() printSectionId: string;

    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @Input() printTitle: string;

    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @Input() useExistingCss = false;

    /**
     * A delay in milliseconds to force the print dialog to wait before opened. Default: 0
     *
     * @memberof NgxPrintDirective
     */
    @Input() printDelay: number = 0;

    /**
     *
     *
     * @returns html for the given tag
     *
     * @memberof NgxPrintDirective
     */
    private _styleSheetFile = '';

    /**
     *
     *
     * @returns the string that create the stylesheet which will be injected
     * later within <style></style> tag.
     *
     * -join/replace to transform an array objects to css-styled string
     *
     * @memberof NgxPrintDirective
     */
    public returnStyleValues() {
        return `<style> ${this._printStyle.join(' ').replace(/,/g, ';')} </style>`;
    }

    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @HostListener('click')
    public print(): void {
        let styles = '';
        let links = '';

        if (this.useExistingCss) {
            styles = this.getElementTag('style');
            links = this.getElementTag('link');
        }

        const printContents = this.getHtmlContents();
        const contentDiv: HTMLDivElement = this._renderer.createElement('div');
        contentDiv.id = 'print-content';
        contentDiv.innerHTML = printContents;

        const length = contentDiv.children.length;
        for (let i = 0; i < length; i++) {
            const element = contentDiv.children[i];
            if (!isNullOrUndefined(element)) {
                element.className = '';
            }
        }

        contentDiv.style.zIndex = '9999';
        contentDiv.style.position = 'absolute';
        contentDiv.style.backgroundColor = 'white';
        contentDiv.style.top = '0';
        contentDiv.style.left = '0';
        contentDiv.style.right = '0';
        contentDiv.style.bottom = '0';

        this._renderer.appendChild(document.body, contentDiv);

        this._dialog.closeAll();
        window.print();

        this._renderer.removeChild(document.body, contentDiv);
    }

    /**
     * @returns string which contains the link tags containing the css which will
     * be injected later within <head></head> tag.
     *
     */
    private returnStyleSheetLinkTags() {
        return this._styleSheetFile;
    }
    private getElementTag(tag: keyof HTMLElementTagNameMap): string {
        const html: string[] = [];
        const elements = document.getElementsByTagName(tag);
        const length = elements.length;
        for (let index = 0; index < length; index++) {
            html.push(elements[index].outerHTML);
        }
        return html.join('\r\n');
    }

    /**
     * @returns html section to be printed along with some associated inputs
     *
     */
    private getHtmlContents() {
        const printContents = document.getElementById(this.printSectionId);
        const innards = printContents.getElementsByTagName('input');
        const length = innards.length;
        for (let i = 0; i < length; i++) {
            innards[i].defaultValue = innards[i].value;
        }
        return printContents.innerHTML;
    }
}
