import { DeviceLensTypeEnum } from '@shared/enum/DeviceLensType.Enum';

export interface ILensRanges {
    lens: DeviceLensTypeEnum;
    warningMin: number;
    warningMax: number;
    overallMin: number;
    overallMax: number;
}

// These must stay in order of DeviceLensTypeEnum
export const HeightRanges: Array<ILensRanges> = [
    {
        lens: DeviceLensTypeEnum.d60,
        warningMin: 250,
        warningMax: 450,
        overallMin: 220,
        overallMax: 480
    },
    {
        lens: DeviceLensTypeEnum.d40,
        warningMin: 400,
        warningMax: 700,
        overallMin: 350,
        overallMax: 750
    },
    {
        lens: DeviceLensTypeEnum.d50,
        warningMin: 320,
        warningMax: 550,
        overallMin: 280,
        overallMax: 590
    },
    {
        lens: DeviceLensTypeEnum.d20,
        warningMin: 800,
        warningMax: 1450,
        overallMin: 700,
        overallMax: 1550
    },
    {
        lens: DeviceLensTypeEnum.d90,
        warningMin: 250,
        warningMax: 450,
        overallMin: 225,
        overallMax: 475
    },
    {
        lens: DeviceLensTypeEnum.d75,
        warningMin: 200,
        warningMax: 450,
        overallMin: 200,
        overallMax: 450
    },
];
