import { Injectable } from '@angular/core';
import { IAutoSpeedSettings } from '@rift/components/validation/Validation.Defaults';
import { IBookmarkModel } from '@rift/models/generic/Bookmark.Model';
import { ConnectionService } from '@rift/service/connection/Connection.Service';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { DatabaseCountFramesStore } from '@rift/service/validation/database/syncrecording/Database.CountFrames.Store';
import { DatabaseDeviceFramesStore } from '@rift/service/validation/database/syncrecording/Database.DeviceFrames.Store';
import {
    DatabaseGlobalBookmarkFramesStore,
} from '@rift/service/validation/database/syncrecording/Database.GlobalBookmark.Store';
import {
    DatabaseGlobalDataFramesStore,
} from '@rift/service/validation/database/syncrecording/Database.GlobalDataFrames.Store';
import { DatabaseLineFramesStore } from '@rift/service/validation/database/syncrecording/Database.LineFrames.Store';
import { DatabasePolygonFramesStore } from '@rift/service/validation/database/syncrecording/Database.PolygonFrames.Store';
import { DatabaseRecordingStore } from '@rift/service/validation/database/syncrecording/Database.Recording.Store';
import { DatabaseRegisterFramesStore } from '@rift/service/validation/database/syncrecording/Database.RegisterFrames.Store';
import { DatabaseSettings } from '@rift/service/validation/database/syncrecording/Database.Settings';
import { DatabaseTargetFramesStore } from '@rift/service/validation/database/syncrecording/Database.TargetFrames.Store';
import { DatabaseTimeDataFramesStore } from '@rift/service/validation/database/syncrecording/Database.TimeDataFrames.Store';
import { DatabaseVideoFramesStore } from '@rift/service/validation/database/syncrecording/Database.VideoFrames.Store';
import {
    DatabaseVideoSettingFramesStore,
} from '@rift/service/validation/database/syncrecording/Database.VideoSettings.Store';
import { IFrameModel } from '@rift/service/validation/models/database/syncrecording/Frame.Model';
import { IValidatableRecordingModel } from '@rift/service/validation/models/ValidatableRecording.Model';
import { IValidationRecordingModel } from '@rift/service/validation/models/ValidationRecording.Model';
import { IStopRequest } from '@rift/service/validation/models/webworker/generic/IStop.Request';
import { IAddBookmarkRequest } from '@rift/service/validation/models/webworker/syncrecording/IAddBookmark.Request';
import { IAddBookmarkResponse } from '@rift/service/validation/models/webworker/syncrecording/IAddBookmark.Response';
import { ICancelLoadRequest } from '@rift/service/validation/models/webworker/syncrecording/ICancelLoad.Request';
import { ICancelLoadResponse } from '@rift/service/validation/models/webworker/syncrecording/ICancelLoad.Response';
import { IDeleteBookmarkRequest } from '@rift/service/validation/models/webworker/syncrecording/IDeleteBookmark.Request';
import { IDeleteBookmarkResponse } from '@rift/service/validation/models/webworker/syncrecording/IDeleteBookmark.Response';
import {
    FrameType,
    IGetAllFramesRequest,
} from '@rift/service/validation/models/webworker/syncrecording/IGetAllFrames.Request';
import { IGetAllFramesResponse } from '@rift/service/validation/models/webworker/syncrecording/IGetAllFrames.Response';
import {
    IGetBlockLoadStatusResponse,
} from '@rift/service/validation/models/webworker/syncrecording/IGetBlockLoadStatus.Response';
import { IGetFrameRequest } from '@rift/service/validation/models/webworker/syncrecording/IGetFrame.Request';
import { IGetFrameResponse } from '@rift/service/validation/models/webworker/syncrecording/IGetFrame.Response';
import { IGetKeyFramesRequest } from '@rift/service/validation/models/webworker/syncrecording/IGetKeyFrames.Request';
import { IGetKeyFramesResponse } from '@rift/service/validation/models/webworker/syncrecording/IGetKeyFrames.Response';
import {
    IGetValidationRecordingRequest,
} from '@rift/service/validation/models/webworker/syncrecording/IGetValidationRecording.Request';
import {
    IGetValidationRecordingResponse,
} from '@rift/service/validation/models/webworker/syncrecording/IGetValidationRecording.Response';
import { IInitializeRequest } from '@rift/service/validation/models/webworker/syncrecording/IInitialize.Request';
import { IProgressUpdateResponse } from '@rift/service/validation/models/webworker/syncrecording/IProgressUpdate.Response';
import { BaseService } from '@shared/base/Base.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IResponse } from '@shared/webworker/IResponse';
import { ResponseTypesEnum } from '@shared/webworker/ResponseTypes.Enum';
import { WebWorkerClient } from '@shared/webworker/WebWorker';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatabaseSyncFramesStore } from './database/syncrecording/Database.SyncFrames.Store';
import { IGetBlockLoadStatusRequest } from './models/webworker/syncrecording/IGetBlockLoadStatus.Request';

export enum FramesTypesEnum {
    video = 0,
    counts = 1,
    targets = 2,
}

@Injectable()
export class ValidationSyncRecordingWebWorkerService extends BaseService {

    public onError: Subject<Error> = new Subject<Error>();
    public onProgressUpdate: Subject<IProgressUpdateResponse> = new Subject<IProgressUpdateResponse>();
    private _client: WebWorkerClient;

    public constructor(
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _configurationService: ConfigurationService,
        private readonly _connectionService: ConnectionService) {
        super();
    }

    public cancel(recordingId: number, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<ICancelLoadRequest, ICancelLoadResponse>({
                action: 'cancelLoad',
                recordingId,
            }, process).pipe(
                map((response) => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return true;
                    }

                    return false;
                })
            );
        }
    }

    public getBlockLoadStatus(recordingId: number, process?: ProcessMonitorServiceProcess): Observable<IGetBlockLoadStatusResponse> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IGetBlockLoadStatusRequest, IGetBlockLoadStatusResponse>({
                action: 'getBlockLoadStatus',
                recordingId,
            }, process).pipe(
                map((response) => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response;
                    }

                    return null;
                })
            );
        }
    }

    public getKeyFrames(recordingId: number, targetsRequired: boolean, syncFramesRequired: boolean, process?: ProcessMonitorServiceProcess): Observable<IGetKeyFramesResponse> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IGetKeyFramesRequest, IGetKeyFramesResponse>({
                action: 'getKeyFrames',
                keyFrameEvery: 30,
                recordingId,
                targetsRequired,
                syncFramesRequired
            }, process).pipe(
                map((response) => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response;
                    }

                    return null;
                })
            );
        }
    }

    public addBookmark(frameNumber: number, bookmark: IBookmarkModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<IBookmarkModel> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IAddBookmarkRequest, IAddBookmarkResponse>({
                action: 'addBookmark',
                frameNumber,
                bookmark,
                validationRecording,
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response.bookmark;
                    }

                    return null;
                })
            );
        }
    }

    public deleteBookmark(frameNumber: number, bookmark: IBookmarkModel, validationRecording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IDeleteBookmarkRequest, IDeleteBookmarkResponse>({
                action: 'deleteBookmark',
                frameNumber,
                bookmark,
                validationRecording,
            }, process).pipe(
                map((response) => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return true;
                    }

                    return null;
                })
            );
        }
    }

    public getBookmarks(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<IFrameModel>> {
        return this.getAllFramesRequest('bookmark', recording, process);
    }

    public getCounts(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<IFrameModel>> {
        return this.getAllFramesRequest('counts', recording, process);
    }

    public getDevices(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<IFrameModel>> {
        return this.getAllFramesRequest('device', recording, process);
    }

    public getSyncFrames(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<IFrameModel>>{
        return this.getAllFramesRequest('syncFrames', recording, process);
    }

    public getFrames(autoSpeedSettings: IAutoSpeedSettings, startFrame: number, frameCount: number, recordingId: number, process?: ProcessMonitorServiceProcess): Observable<IGetFrameResponse> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IGetFrameRequest, IGetFrameResponse>({
                action: 'getFrames',
                startFrame,
                frameCount,
                recordingId,
                autoSpeedSettings,
            }, process).pipe(
                map((response) => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response;
                    }
                    else {
                        this.onError.next(new Error(response.errorMessage));
                    }
                })
            );
        }
    }

    public getGlobalData(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<IFrameModel>> {
        return this.getAllFramesRequest('globaldata', recording, process);
    }

    public getLines(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<IFrameModel>> {
        return this.getAllFramesRequest('line', recording, process);
    }

    public getPolygons(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<IFrameModel>> {
        return this.getAllFramesRequest('polygon', recording, process);
    }

    public getRegisters(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<IFrameModel>> {
        return this.getAllFramesRequest('register', recording, process);
    }

    public getTimeData(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<IFrameModel>> {
        return this.getAllFramesRequest('timedata', recording, process);
    }

    public getValidationRecording(validatableRecording: IValidatableRecordingModel, preLoadOnly: boolean, process?: ProcessMonitorServiceProcess): Observable<IValidationRecordingModel> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IGetValidationRecordingRequest, IGetValidationRecordingResponse>({
                action: 'getValidationRecording',
                validatableRecording,
                preLoadOnly
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response.validationRecording;
                    }
                    else {
                        this.onError.next(new Error(response.errorMessage));
                    }
                })
            );
        }
    }

    public getVideoSettings(recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<IFrameModel>> {
        return this.getAllFramesRequest('videosetting', recording);
    }

    public start(process?: ProcessMonitorServiceProcess): void {
        this._client = new WebWorkerClient(new Worker(new URL('@rift/workers/validation-sync-recording.worker', import.meta.url), { type: 'module' }));
        this.addSubscription(this._client.progressUpdate.subscribe(update => this.onProgressUpdate.next(update)));
        this.addSubscription(this._client.error.subscribe(error => this.onError.next(error.error)));

        this.addSubscription(this._client.sendMessage<IInitializeRequest, IResponse>({
            action: 'initialize',

            hostFriendlySerial: this._connectionService.hostFriendlySerial,

            socketUrl: this._configurationService.riftWebSocket,
            connectionToken: this._connectionTokenService.connectionToken,
            secureToken: this._connectionTokenService.secureToken,

            databaseName: DatabaseSettings.databaseName,
            databaseVersion: DatabaseSettings.databaseVersion,

            countFramesStoreConfig: DatabaseCountFramesStore.toInterface(),
            recordingStoreConfig: DatabaseRecordingStore.toInterface(),
            targetFramesStoreConfig: DatabaseTargetFramesStore.toInterface(),
            videoFramesStoreConfig: DatabaseVideoFramesStore.toInterface(),
            videoSettingFramesStoreConfig: DatabaseVideoSettingFramesStore.toInterface(),
            deviceFramesStoreConfig: DatabaseDeviceFramesStore.toInterface(),
            lineFramesStoreConfig: DatabaseLineFramesStore.toInterface(),
            polygonFramesStoreConfig: DatabasePolygonFramesStore.toInterface(),
            registerFramesStoreConfig: DatabaseRegisterFramesStore.toInterface(),
            globalDataFramesStoreConfig: DatabaseGlobalDataFramesStore.toInterface(),
            timeDataFramesStoreConfig: DatabaseTimeDataFramesStore.toInterface(),
            bookmarkFramesStoreConfig: DatabaseGlobalBookmarkFramesStore.toInterface(),
            syncFramesStoreConfig: DatabaseSyncFramesStore.toInterface()
        }, process).subscribe(
            response => {
                if (response.type === ResponseTypesEnum.error) {
                    this.onError.next(new Error('Unable to start web worker'));
                }
            },
            error => {
                this.onError.next(new Error(`Unable to start web worker:${error}`));
            }
        ));
    }

    public stop(): void {
        if (!isNullOrUndefined(this._client)) {
            this.addSubscription(this._client.sendMessage<IStopRequest, IResponse>({ action: 'stop' }).subscribe(
                () => this._client.terminate()
            ));
        }
    }

    private getAllFramesRequest(frameType: FrameType, recording: IValidationRecordingModel, process?: ProcessMonitorServiceProcess): Observable<Array<IFrameModel>> {
        if (!isNullOrUndefined(this._client)) {
            return this._client.sendMessage<IGetAllFramesRequest, IGetAllFramesResponse>({
                action: 'getAllFrames',
                frameType,
                recording,
            }, process).pipe(
                map(response => {
                    if (response.type !== ResponseTypesEnum.error) {
                        return response.frames;
                    } else {
                        this.onError.next(new Error(response.errorMessage));
                    }
                })
            );
        }
    }
}
