<div class="em-settings-address-book"
    fxLayout="column">
    <ng-template #addChildGroupStepContent>
        Adds a device group to the root of the address book tree.
    </ng-template>
    <div fxLayout="row">
        <div fxFlex
            ngClass.gt-sm="spacing-right">
            <mat-card class="address-book">
                <mat-card-title fxLayout="row"
                    fxLayoutAlign="start center">
                    <span>Address Book</span>
                    <div fxFlex></div>
                    <button mat-raised-button
                        (click)="addChildGroup()"
                        *ngIf="rootTreeGroup?.noEdit === false">Add To
                        Group</button>
                </mat-card-title>
                <em-address-book-tree mode="edit"
                    [inlineNameEditing]="false"
                    [showItemEditIcon]="showItemEditIcon"
                    [selectedGroup]="selectedGroup"
                    (editItemClicked)="editGroup($event)"
                    (moveClicked)="moveGroup($event)"
                    (deleteClicked)="deleteGroup($event)"
                    (moveUpClicked)="moveGroupUp($event)"
                    (moveDownClicked)="moveGroupDown($event)"
                    (addChildClicked)="addChildGroup($event)"
                    (itemClicked)="groupSelected($event)"
                    (editNameCompleteClicked)="editGroupNameComplete($event)"
                    [root]="rootTreeGroup"
                    #addressBook
                    [badgeHidden]="true">
                </em-address-book-tree>
            </mat-card>
        </div>
        <div fxShow.gt-sm="true"
            fxShow.lt-md="false"
            fxFlex>
            <div *ngIf="!isNullOrUndefined(this.selectedGroup)"
                shrScrollFixed>
                <mat-card class="spacing-bottom">
                    <mat-card-title>
                        <span>
                            Group Edit
                        </span>
                    </mat-card-title>
                    <div fxLayout="column"
                        fxLayoutAlign="start stretch">
                        <mat-form-field *ngIf="!isNullOrUndefined(addressBook.selectedGroup)">
                            <input matInput
                                [disabled]="addressBook.selectedGroup.noEdit === true"
                                (keyup)="nameChanged()"
                                placeholder="Group Name"
                                [(ngModel)]="addressBook.selectedGroup.name">
                        </mat-form-field>
                    </div>
                </mat-card>
                <em-settings-address-book-edit (filterValidChanged)="filterValidChanged()"
                    (filterChanged)="filterChanged()"
                    (deleteCriteria)="deleteCriteria($event)"
                    (addCriteria)="addCriteria($event)"
                    (addCriteriaGroup)="addCriteriaGroup($event)"
                    [addressBook]="addressBook"
                    [selectedGroup]="selectedGroup"
                    [filterOnEntriesSettings]="filterOnEntriesSettings"
                    [filterOnEntriesMeta]="filterOnEntriesMeta">
                </em-settings-address-book-edit>
            </div>
        </div>
    </div>
</div>