import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IConnectionRequestModel extends IRestModel {
    connectionProviderToken: string;
    connectionType: string;
    friendlySerial: string;
    comPort: string;
    hostname: string;
    port: string;
    live: boolean;
    username: string;
    password: string;
}

export class ConnectionRequestModel extends BaseModel implements IConnectionRequestModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public connectionProviderToken: string = null;
    public connectionType: string = null;
    public friendlySerial: string = null;
    public comPort: string = null;
    public hostname: string = null;
    public port: string = null;
    public live: boolean = null;
    public username: string = null;
    public password: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ConnectionRequestModel');
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, ['friendlySerial']),
            Device: this.friendlySerial,
        };
    }
}
