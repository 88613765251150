import { Component, HostBinding, Inject, Injector, NgZone, Output, EventEmitter } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { IValidatableRecordingModel } from '@rift/service/validation/models/ValidatableRecording.Model';
import { IProgressUpdateResponse } from '@rift/service/validation/models/webworker/syncrecording/IProgressUpdate.Response';
import { ValidationService } from '@rift/service/validation/Validation.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PRE_LOAD_BLOCK_SIZE } from '@rift/workers/validation-sync-recording.worker';

export class LoadValidationRecordingData {
    public constructor(public readonly validatableRecording: IValidatableRecordingModel) { }
}

@Component({
    selector: 'rift-load-validation-recording',
    templateUrl: './LoadValidationRecording.Component.html',
    styleUrls: ['./LoadValidationRecording.Component.scss']
})
export class LoadValidationRecordingComponent extends RiftBaseComponent {
    public static className: string = 'LoadValidationRecordingComponent';

    @Output()
    public cancelled: EventEmitter<null> = new EventEmitter<null>();

    @HostBinding()
    public id: string = 'rift-load-validation-recording';

    public percentageComplete: number = 0;
    public atFrame: number = 0;
    public totalFramesCount: number = 0;
    public cancelProcess: ProcessMonitorServiceProcess;
    public recordingId: number = null;
    public isCancelled: boolean = false;
    public updating: string = null;
    public updatingText: string = null;

    private _framePercent: number = 0;

    public constructor(
        @Inject(MAT_DIALOG_DATA) private readonly _data: LoadValidationRecordingData,
        private readonly _validationService: ValidationService,
        private readonly _dialog: MatDialog,
        private readonly _dialogRef: MatDialogRef<LoadValidationRecordingComponent>,
        private readonly _zone: NgZone,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess(LoadValidationRecordingComponent.className, this.loadDataProcessText);
        this.cancelProcess = this.processMonitorService.getProcess(LoadValidationRecordingComponent.className, 'Cancelling validation recording sync');

        this.totalFramesCount = this._data.validatableRecording.frames + (this._data.validatableRecording.frames < PRE_LOAD_BLOCK_SIZE ? this._data.validatableRecording.frames : PRE_LOAD_BLOCK_SIZE);
        this._framePercent = (100 / this.totalFramesCount);
    }

    public onUpdate(update: IProgressUpdateResponse): void {
        if (update.updating === 'preload' || update.updating === 'postload' || update.updating === 'keyframes') {
            this._zone.run(() => {
                if (this.updating !== update.updating) {
                    this.updating = update.updating;
                    this.percentageComplete = 0;

                    switch (update.updating) {
                        case 'preload':
                            this.updatingText = 'Counts and device data';
                            this.totalFramesCount = this._data.validatableRecording.frames;
                            break;
                        case 'postload':
                            this.updatingText = 'Initial video and targets';
                            this.totalFramesCount = (this._data.validatableRecording.frames < PRE_LOAD_BLOCK_SIZE ? this._data.validatableRecording.frames : PRE_LOAD_BLOCK_SIZE);
                            break;
                        case 'keyframes':
                            this.updatingText = 'Key Frames';
                            this.totalFramesCount = this._data.validatableRecording.frames;
                            break;
                    }

                    this._framePercent = (100 / this.totalFramesCount);
                }

                this.atFrame = update.atFrame;
                this.recordingId = update.recordingId;

                this.percentageComplete = update.atFrame * this._framePercent;
            });
        }
    }

    public onCancelClick(): void {
        this.addSubscription(this.observableHandlerBase(this._validationService.cancel(this.recordingId), this.cancelProcess).subscribe(
            cancelled => {
                if (cancelled === true) {
                    this.isCancelled = true;
                    this.cancelled.emit(null);
                }
            }
        ), this.cancelProcess);
    }
}
