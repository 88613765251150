import { ElementTypes } from '@shared/types/ElementTypes';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class IdUtil {
    public static getId(pagePrefix: string, element: string, type: ElementTypes): string {
        if (isNullOrUndefined(type)) {
            return `${pagePrefix}-${element}`;
        } else {
            return `${pagePrefix}-${element}-${type.toString()}`;
        }
    }
}
