<div class="em-documentation-restapi">
    <mat-card>
        <mat-card-title fxLayout="row"
            fxLayoutAlign="start center">
            <span class="mat-display-1">Estate Manager Device API</span>
            <div fxFlex></div>
            <a class="mat-raised-button spacing-right"
                href="https://github.com/irisysuk"
                target="_blank">
                GitHub
            </a>
            <a class="mat-raised-button"
                href="/api-docs"
                target="_blank">Swagger API</a>
        </mat-card-title>
        <mat-card-content>
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Connect To A Device Through Estate Manager
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div fxLayout="column">
                    <span>In this quick start we will call the API to request a connection token that will be used for all further
                        requests that require device interaction.</span>
                    <br />
                    <span class="mat-title">Retrieving The API Authorization Token</span>
                    <div *ngIf="authType === AuthTypeEnum.system">
                        <br />
                        <span>A POST request will first need to be made to “/api/rt/v1/tokenprovider/get_token” to retrieve the API
                            authorization token. This token must be included in the HTTP Authorization header for all other API calls.</span>
                        <br />
                        <span>Request Data:</span>
                        <br />
                        <div class="code">
                            "{{restApiKey}}"
                        </div>
                        <br />
                        <span>Response Data:</span>
                        <br />
                        <div class="code">
                            "bearer dfgfdgdfgggdfh45345rg34t353n4t34jthb3jth34t"
                        </div>
                    </div>
                    <div *ngIf="authType === AuthTypeEnum.openIDConnect">
                        <br />
                        <span>A Bearer token must be retrieved from the identity provider using OAuth2 or OpenID Connect. 
                            This token must be included in the HTTP Authorization header for all other API calls.</span>
                    </div>
                    <br />
                    <span class="mat-title">Connect To The Device</span>
                    <br />
                    <span>A POST request will then need to be made to “/api/rt/v1/connection/create” to connect to the device.
                    </span>
                    <br />
                    <span>The "Device" field should be set to the serial number of the device you want to connect to. </span>
                    <br />
                    <span>The "Live" field should be set to true to talk directly to the device and false to extract data from
                        the Estate Manager database about this device. </span>
                    <br />
                    <span>We recommend setting to false unless you absolutely have to connect to the device directly. When connecting
                        directly you will need to poll the status of the connection until it returns as connected.</span>
                    <br />
                    <br />
                    <span>Request Data:</span>
                    <br />
                    <div class="code">
                        {{ '{'}}
                        <br> "Device": "N100014",
                        <br> "Live": false
                        <br> {{ '}'}}
                    </div>
                    <br />
                    <span>Response Data:</span>
                    <br />
                    <div class="code">
                        {{ '{'}}
                        <br> "Token": "bd8b330da3ec4d3c94cb409f8642f510",
                        <br> "SecureToken": "2cdef94e7ee7426da765a72d4b578f63"
                        <br> {{ '}'}}
                    </div>
                    <br />
                    <div class="info">
                        <i class="fa fa-info-circle"></i>Any further API calls that require device interaction will need the “Token” sending in the
                        query
                        string as “token”.
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-card-content>
    </mat-card>
</div>