import { Component, HostBinding, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsTaskLauncherUploadComponent, SettingsTaskLauncherUploadDialogData } from '@em/components/settings/tasklauncher/tasklauncherupload/Settings.TaskLauncher.Upload.Component';
import { TaskDescriptionModel } from '@em/models/restapi/TaskDescription.Model';
import { TaskToExecuteModel } from '@em/models/restapi/TaskToExecute.Model';
import { GroupService } from '@em/service/data/group/Group.Service';
import { SettingService } from '@em/service/data/setting/Setting.Service';
import { TaskService } from '@em/service/data/task/Task.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { DeviceGroupEnum } from '@shared/enum/DeviceGroup.Enum';
import { TaskToExecuteStateEnum } from '@shared/enum/TaskToExecuteState.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { isNumber } from '@shared/utility/General.Utility';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


export class SettingsTaskLauncherRunDialogData {
    public constructor(public readonly friendlySerial?: string, public readonly groupId?: DeviceGroupEnum | number) {

    }
}

export class SettingsTaskLauncherRunDialogResult {

    public constructor() { }
}

@Component({
    selector: 'em-settings-task-launcher-run',
    templateUrl: './Settings.TaskLauncher.Run.Component.html',
    styleUrls: ['./Settings.TaskLauncher.Run.Component.scss']
})
export class SettingsTaskLauncherRunComponent extends BaseComponent implements ILoadDate {
    public static className: string = 'SettingsTaskLauncherRunComponent';

    @HostBinding()
    public id: string = 'em-settings-task-launcher-run';

    public addTaskProcess: ProcessMonitorServiceProcess;
    public getGroupProcess: ProcessMonitorServiceProcess;
    public runTaskFormGroup: FormGroup;
    public packageList: Array<TaskDescriptionModel>;
    public task: TaskToExecuteModel;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;
    public enabled: boolean = false;
    public warning: string = null;
    public error: string = null;

    public constructor(
        private readonly _settingService: SettingService,
        private readonly _dialog: MatDialog,
        private readonly _groupService: GroupService,
        private readonly _eventsService: EventsService,
        private readonly _taskService: TaskService,
        private readonly _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private readonly _data: SettingsTaskLauncherRunDialogData,
        private readonly _dialogRef: MatDialogRef<SettingsTaskLauncherRunComponent>,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsTaskLauncherRunComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsTaskLauncherRunComponent.className, this.loadDataProcessText);
        this.addTaskProcess = this.processMonitorService.getProcess(SettingsTaskLauncherRunComponent.className, 'Adding task.');
        this.getGroupProcess = this.processMonitorService.getProcess(SettingsTaskLauncherRunComponent.className, 'Getting group.');

        this.task = new TaskToExecuteModel();

        this.runTaskFormGroup = this._formBuilder.group({
            package: ['', Validators.compose([Validators.required])],
            operatesOn: ['', Validators.compose([Validators.required])],
            folderId: ['', Validators.compose([])],
            friendlySerialNumber: ['', Validators.compose([])],
        });

        this.addSubscription(this.observableHandlerBase(this.runTaskFormGroup.controls.operatesOn.valueChanges, this.formValuesChangeProcess).subscribe(this.onOperatesOnChanged.bind(this)), this.formValuesChangeProcess);
        this.addSubscription(this.observableHandlerBase(this.runTaskFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModel(this.task)), this.formValuesChangeProcess);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public onUploadClicked(): void {
        this.addSubscription(this._dialog.open(SettingsTaskLauncherUploadComponent, { data: new SettingsTaskLauncherUploadDialogData() }).afterClosed().subscribe(
            () => {
                this._taskService.clearCache();
                this.getTaskPackageList().subscribe();
            }
        ));
    }

    public onOperatesOnChanged(value: string): void {
        switch (value) {
            case 'all':
                this.runTaskFormGroup.controls.folderId.setValidators(Validators.compose([]));
                this.runTaskFormGroup.controls.friendlySerialNumber.setValidators(Validators.compose([]));

                this.runTaskFormGroup.controls.folderId.setValue('');
                this.runTaskFormGroup.controls.friendlySerialNumber.setValue('');

                break;
            case 'folder':
                this.runTaskFormGroup.controls.folderId.setValidators(Validators.compose([Validators.required]));
                this.runTaskFormGroup.controls.friendlySerialNumber.setValidators(Validators.compose([]));

                this.runTaskFormGroup.controls.folderId.setValue('');
                this.runTaskFormGroup.controls.friendlySerialNumber.setValue('');

                break;
            case 'device':
                this.runTaskFormGroup.controls.folderId.setValidators(Validators.compose([]));
                this.runTaskFormGroup.controls.friendlySerialNumber.setValidators(Validators.compose([Validators.required]));

                this.runTaskFormGroup.controls.folderId.setValue('');
                this.runTaskFormGroup.controls.friendlySerialNumber.setValue('');

                break;
        }
    }

    public cancel(): void {
        this._dialogRef.close();
    }

    public add(): void {
        const taskModel = this.runTaskFormGroup.value;

        switch (taskModel.operatesOn) {
            case 'all':
                this.addAll();
                break;
            case 'folder':
                this.addFolder();
                break;
            case 'device':
                this.addDevice();
                break;
        }
    }

    public addAll(): void {
        this.addSubscription(this.observableHandlerBase(this._taskService.addTaskForAllDevices(this.task, this.addTaskProcess), this.addTaskProcess).subscribe(
            result => {
                this._eventsService.changedTasks();
                this._dialogRef.close();
            }
        ), this.addTaskProcess);
    }

    public addFolder(): void {
        const taskModel = this.runTaskFormGroup.value;

        this.addSubscription(this.observableHandlerBase(this._groupService.get(taskModel.folderId, this.getGroupProcess), this.getGroupProcess).subscribe(
            group => {
                this.addSubscription(this.observableHandlerBase(this._taskService.addTaskForGroup(this.task, group, this.addTaskProcess), this.addTaskProcess).subscribe(
                    result => {
                        this._eventsService.changedTasks();
                        this._dialogRef.close();
                    }
                ), this.addTaskProcess);
            }
        ), this.getGroupProcess);
    }

    public addDevice(): void {
        this.addSubscription(this.observableHandlerBase(this._taskService.addTaskForDevice(this.task, this.addTaskProcess), this.addTaskProcess).subscribe(
            result => {
                this._eventsService.changedTasks();
                this._dialogRef.close();
            }
        ), this.addTaskProcess);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.getTaskPackageList(process),
            this._settingService.getSetting('TaskLauncherEnabled', 'TaskLauncherSettings', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.enabled = (result.value.toLocaleLowerCase() === 'true');
                        if (this.enabled === true) {
                            this.warning = null;
                        } else {
                            this.warning = 'Task Manager is disabled in the Options';
                        }
                    }
                    return true;
                })
            ),
        ).pipe(
            tap(() => {
                if (!this.isNullOrUndefined(this._data.friendlySerial)) {
                    this.runTaskFormGroup.controls.operatesOn.setValue('device');
                    this.runTaskFormGroup.controls.friendlySerialNumber.setValue(this._data.friendlySerial);
                } else if (!this.isNullOrUndefined(this._data.groupId)) {
                    if (this._data.groupId === DeviceGroupEnum.all) {
                        this.runTaskFormGroup.controls.operatesOn.setValue('all');
                    } else {
                        this.runTaskFormGroup.controls.operatesOn.setValue('folder');
                        this.runTaskFormGroup.controls.folderId.setValue(parseInt(this._data.groupId as any, 10));
                    }
                }
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    private getTaskPackageList(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._taskService.getTaskPackageList(process).pipe(
            map(result => {
                if (!this.isNullOrUndefined(result)) {
                    this.packageList = result;
                }
                return true;
            })
        );
    }

    private updateModel(task: TaskToExecuteModel): void {
        const taskModel = this.runTaskFormGroup.value;

        task.taskState = TaskToExecuteStateEnum.readyToExecute;

        if (!this.isNullOrUndefined(taskModel.package)) {
            task.taskName = (taskModel.package as TaskDescriptionModel).taskFilename;
        } else {
            task.taskName = null;
        }

        if (taskModel.operatesOn === 'device') {
            task.friendlyDeviceSerial = taskModel.friendlySerialNumber;
        } else if (taskModel.operatesOn === 'all') {
            task.friendlyDeviceSerial = '';
        } else {
            task.friendlyDeviceSerial = null;
        }
    }
}
