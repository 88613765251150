import { Component, HostBinding, Inject } from '@angular/core';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * Data for the OkCancelDialogComponent.
 *
 * @export
 * @class OkCancelDialogData
 */
export class OkCancelDialogData {
    /**
     * The cancel button test
     *
     * @type {string}
     * @memberof OkCancelDialogData
     */
    public cancelText: string;
    /**
     * The message to show in the dialog
     *
     * @type {(string | Array<string>)}
     * @memberof OkCancelDialogData
     */
    public message: string | Array<string>;
    /**
     * The html message to show in the dialog
     *
     * @type {string}
     * @memberof OkCancelDialogData
     */
    public messageHtml: string;
    /**
     * The ok button text
     *
     * @type {string}
     * @memberof OkCancelDialogData
     */
    public okText: string;
    /**
     * If true cancel button will be shown.
     *
     * @type {boolean}
     * @memberof OkCancelDialogData
     */
    public showCancel: boolean;
    /**
     * If true ok button will be shown.
     *
     * @type {boolean}
     * @memberof OkCancelDialogData
     */
    public showOk: boolean;
    /**
     * The title of the dialog.
     *
     * @type {string}
     * @memberof OkCancelDialogData
     */
    public title: string;

    /**
     * Creates an instance of OkCancelDialogData.
     *
     * @param {string} title The title of the dialog.
     * @param {(string | Array<string>)} message The message to show in the dialog
     * @param {boolean} [showCancel] If true cancel button will be shown.
     * @memberof OkCancelDialogData
     */
    public constructor(title: string, message: string | Array<string>, showCancel?: boolean, showOk?: boolean) {
        this.title = title;
        this.message = message;
        this.showCancel = isNullOrUndefined(showCancel) ? true : showCancel;
        this.showOk = isNullOrUndefined(showOk) ? true : showOk;
    }
}

/**
 * Result data for the OkCancelDialogComponent.
 *
 * @export
 * @class OkCancelDialogResult
 */
export class OkCancelDialogResult {
    public ok: boolean;

    public constructor(ok: boolean) {
        this.ok = ok;
    }
}

/**
 * Show an ok cancel dialog.
 *
 * @export
 * @class OkCancelDialogComponent
 * @extends {BaseComponent}
 */
@Component({
    selector: 'shr-ok-cancel-dialog',
    templateUrl: './OkCancel.Dialog.Component.html',
    styleUrls: ['./OkCancel.Dialog.Component.scss']
})
export class OkCancelDialogComponent {
    public static className: string = 'OkCancelDialogComponent';

    public isNullOrUndefined = isNullOrUndefined;
    public okClicked = false;
    public show = true;

    @HostBinding()
    public id: string = 'shr-ok-cancel-dialog';

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: OkCancelDialogData,
        private readonly _dialogRef: MatDialogRef<OkCancelDialogComponent>) {

        this._dialogRef.disableClose = true;
    }

    public onOkClicked(): void {
        this.okClicked = true;
        this._dialogRef.close(new OkCancelDialogResult(true));
    }

    public onCancelClicked(): void {
        this._dialogRef.close(new OkCancelDialogResult(false));
    }
}
