import { IValidationReportLineModel, ValidationReportLineModel } from '@rift/models/restapi/ValidationReportLine.Model';
import { IValidationReportNodeModel, ValidationReportNodeModel } from '@rift/models/restapi/ValidationReportNode.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export interface IValidationReportModel extends IRestModel {
    lines: Array<IValidationReportLineModel>;
    nodes: Array<IValidationReportNodeModel>;
    siteName: string;
    siteId: string;
    timeZone: string;
    serialNumber: string;
    creationDate: string;
    dateRecorded: string;
    duration: string;
}

export class ValidationReportModel extends BaseModel implements IValidationReportModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public lines: Array<ValidationReportLineModel> = null;
    public nodes: Array<ValidationReportNodeModel> = null;
    public siteName: string = null;
    public siteId: string = null;
    public timeZone: string = null;
    public serialNumber: string = null;
    public creationDate: string = null;
    public dateRecorded: string = null;
    public duration: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any, upperCase: boolean = true): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ValidationReportModel', null, upperCase);
        this.lines = RestModelUtility.loadFromArray(restModel.Lines, ValidationReportLineModel, upperCase);
        this.nodes = RestModelUtility.loadFromArray(restModel.Nodes, ValidationReportNodeModel, upperCase);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }

    public toInterface(): IValidationReportModel {
        return {
            lines: isNullOrUndefined(this.lines) ? null : this.lines.map(i => i.toInterface()),
            nodes: isNullOrUndefined(this.nodes) ? null : this.nodes.map(i => i.toInterface()),
            siteName: this.siteName,
            siteId: this.siteId,
            timeZone: this.timeZone,
            serialNumber: this.serialNumber,
            creationDate: this.creationDate,
            dateRecorded: this.dateRecorded,
            duration: this.duration,
        } as IValidationReportModel;
    }
}
