<div mat-dialog-title>
    {{ rule.id === -1 ? 'Add' : 'Update'}} Rule
</div>
<div mat-dialog-content
    fxLayout="column">
    <form [formGroup]="formGroup">
        <ng-template [ngIf]="rule.isGlobalRule === true">
            <span>All Devices (Global)</span>
        </ng-template>
        <ng-template [ngIf]="rule.isGroupRule === true">
            <mat-form-field>
                <em-address-book-auto-complete #groupId
                    formControlName="groupId">
                </em-address-book-auto-complete>
                <span matSuffix>{{ rule.propertyHasChangesText('groupId') }}</span>
                <mat-error>
                    {{getFormControlErrorMessage(formGroup.controls['groupId'])}}
                </mat-error>
            </mat-form-field>
        </ng-template>
        <ng-template [ngIf]="rule.isDeviceRule === true">
            <mat-form-field>
                <em-device-autocomplete #friendlySerialNumber
                    formControlName="friendlySerialNumber">
                </em-device-autocomplete>
                <span matSuffix>{{ rule.propertyHasChangesText('friendlySerialNumber') }}</span>
                <mat-error>
                    {{getFormControlErrorMessage(formGroup.controls['friendlySerialNumber'])}}
                </mat-error>
            </mat-form-field>
        </ng-template>

        <div formArrayName="destinations">
            <ng-template ngFor
                let-destination
                [ngForOf]="rule.destinations"
                let-i="index">
                <div fxLayout="row">
                    <div [formGroupName]="i">
                        <mat-form-field class="ip-field">
                            <input #iPAddress
                                formControlName="iPAddress"
                                type="text"
                                matInput
                                placeholder="IP Address">
                            <mat-error>
                                {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['destinations']).at(i)).controls['iPAddress'])}}
                            </mat-error>
                            <mat-hint align="end">{{iPAddress.value.length}} / 15</mat-hint>
                            <span matSuffix>{{ destination.propertyHasChangesText('iPAddress') }}</span>
                        </mat-form-field>
                        <mat-form-field class="port-field">
                            <input #port
                                formControlName="port"
                                type="number"
                                matInput
                                placeholder="Port">
                            <mat-error>
                                {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['destinations']).at(i)).controls['port'])}}
                            </mat-error>
                            <mat-hint align="end">{{port.value.length}} / 5</mat-hint>
                            <span matSuffix>{{ destination.propertyHasChangesText('port') }}</span>
                        </mat-form-field>
                        <button mat-icon-button
                            [disabled]="true"
                            *ngIf="i !== (rule.destinations.length - 1)">
                        </button>
                        <button matTooltip="Add"
                            mat-icon-button
                            (click)="addDestination()"
                            *ngIf="i === (rule.destinations.length - 1)">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button *ngIf="rule.destinations.length > 1"
                            matTooltip="Delete"
                            mat-icon-button
                            (click)="deleteDestination(destination)"
                            color="warn">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>

        <mat-form-field class="retry-field">
            <input #retryPeriod
                formControlName="retryPeriod"
                type="number"
                matInput
                placeholder="Retry Period (s)">
            <mat-error>
                {{getFormControlErrorMessage(formGroup.controls['retryPeriod'])}}
            </mat-error>
            <mat-hint align="end">{{retryPeriod.value.length}} / 4</mat-hint>
            <span matSuffix>{{ rule.propertyHasChangesText('retryPeriod') }}</span>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button [disabled]="!isValidBase || !hasChangesBase "
        (click)="close()"
        mat-raised-button
        color="warn">{{ rule.id === -1 ? 'Add' : 'Update'}}</button>
    <div fxFlex></div>
    <button (click)="cancel()"
        mat-raised-button
        color="warn">Cancel</button>
</div>