import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class RoleModel extends BaseModel implements IRestModel {
    public description: string = null;
    public name: string = null;
    public order: number = null;
    public readonly isIRestModel = true;
    public roleId: number = null;

    public constructor() {
        super();
    }

    public get isSystemAdmin(): boolean {
        return this.name === 'SystemAdmin';
    }

    public get isSystemManager(): boolean {
        return this.name === 'SystemManager';
    }

    public get isAdmin(): boolean {
        return this.name === 'Admin';
    }

    public get isInstaller(): boolean {
        return this.name === 'Installer';
    }

    public get isUser(): boolean {
        return this.name === 'User';
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-RoleModel');
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
