import { LineModel } from '@rift/models/restapi/Line.Model';
import { CollectionModel, ICollectionModel } from '@shared/models/restapi/Collection.Model';

export interface ILineCollectionModel extends ICollectionModel<LineModel> {

}

export class LineCollectionModel extends CollectionModel<LineModel> implements ILineCollectionModel {
    public constructor() {
        super(LineModel);
    }
}
