import { ValidationReportModel } from '@rift/models/restapi/ValidationReport.Model';
import { ValidationReportLineModel } from '@rift/models/restapi/ValidationReportLine.Model';
import { ValidationReportNodeModel } from '@rift/models/restapi/ValidationReportNode.Model';
import { IValidationSessionInfoModel, ValidationSessionInfoModel } from '@rift/models/restapi/ValidationSessionInfo.Model';
import { ValidationSessionOptionModel } from '@rift/models/restapi/ValidationSessionOption.Model';
import { IPlayLocation } from '@rift/service/validation/IPlayLocation';
import { SyncStateEnum } from '@rift/service/validation/models/SyncState.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IDbValidationSessionInfoModel extends IValidationSessionInfoModel {
    id?: number;
    recordingId: number;
    syncState: SyncStateEnum;
    lastLocation: IPlayLocation;
}

export class DbValidationSessionInfoModel extends ValidationSessionInfoModel implements IDbValidationSessionInfoModel, IRestModel {
    public readonly isIRestModel = true;

    public id: number = null;
    public recordingId: number = null;
    public syncState: SyncStateEnum = null;
    public lastLocation: IPlayLocation = null;

    public constructor() {
        super();
    }

    public loadFromInterface(value: IDbValidationSessionInfoModel): void {
        RestModelUtility.loadProperties(value, this, 'RIFT-DbValidationSessionInfoModel', ['completed', 'creationDateDate', 'modificationDateDate', 'passed'], false);
        this.options = RestModelUtility.loadFromArray(value.options, ValidationSessionOptionModel, false);
        this.report = RestModelUtility.loadFrom(value.report, ValidationReportModel, false);
        this.lastLocation = value.lastLocation;
        if (!isNullOrUndefined(value.report)) {
            if (!isNullOrUndefined(value.report.lines)) {
                this.report.lines = RestModelUtility.loadFromArray(value.report.lines, ValidationReportLineModel, false);
            }
            if (!isNullOrUndefined(value.report.nodes)) {
                this.report.nodes = RestModelUtility.loadFromArray(value.report.nodes, ValidationReportNodeModel, false);
            }
        }
    }

    public toInterface(): IDbValidationSessionInfoModel {
        return {
            ...super.toInterface(),
            id: this.id,
            recordingId: this.recordingId,
            lastLocation: this.lastLocation,
        } as IDbValidationSessionInfoModel;
    }
}
