import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';

export class DeviceTimeZoneInfoModel extends BaseModel implements IRestModel {
    public applyDST: boolean = null;
    public displayName: string = null;
    public id: string = null;
    public offset: number = null;
    public readonly isIRestModel = true;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-DeviceTimeZoneInfoModel');
    }

    public toRestApiModel(): any {
        throw new Error('Method not implemented.');
    }
}
