<div class="em-settings-tasklaucher-view-title"
    mat-dialog-title>
    Task Results
</div>
<div class="em-settings-tasklaucher-view-content"
    mat-dialog-content
    fxLayout="column">
    <shr-table [hideMatCard]="true"
        [pageEnabled]="false"
        [filterEnabled]="false"
        (rowClicked)="resultSelected($event)"
        [dataSource]="dataSource"
        [displayedColumns]="displayedColumns">
        <ng-template #shrTableActions
            let-result>
            <button matTooltip="Download Log"
                mat-icon-button
                (click)="downloadResultLog(result)">
                <mat-icon>cloud_download</mat-icon>
            </button>
        </ng-template>

        <ng-container matColumnDef="finished">
            <th mat-header-cell
                *matHeaderCellDef> Finished </th>
            <td mat-cell
                *matCellDef="let result">
                <shr-table-cell [data]="result"
                    keyPath="timeFinishedText"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="duration">
            <th mat-header-cell
                *matHeaderCellDef> Duration </th>
            <td mat-cell
                *matCellDef="let result">
                <shr-table-cell [data]="result"
                    keyPath="durationText"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell
                *matHeaderCellDef> Status </th>
            <td mat-cell
                *matCellDef="let result">
                <span matTooltip="{{result.taskState}}">{{ TaskToExecuteStateEnumHelpers.toStringHumanized(result.state) }}
                </span>
            </td>
        </ng-container>
    </shr-table>
    <div class="log">
        <div class="mat-subheading-1">
            View Log
        </div>
        <div class="text">
            <ng-template [ngIf]="hasLog === false">
                No log available
            </ng-template>
            <br/>
            <ng-template [ngIf]="hasLog === true">
                <pre class="result">
                {{resultLog}}
                </pre>
            </ng-template>
        </div>
    </div>
</div>
<div class="em-settings-tasklaucher-view-actions"
    mat-dialog-actions>
    <div fxFlex></div>
    <button (click)="cancel()"
        mat-raised-button>Close</button>
</div>