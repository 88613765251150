import { DeviceErrorModel, IDeviceErrorModel } from '@rift/models/restapi/DeviceError.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IErrorsAndWarningsModel extends IRestModel {
    errors: Array<IDeviceErrorModel>;
    warnings: Array<IDeviceErrorModel>;
    information: Array<IDeviceErrorModel>;
    errorsCount: number;
    warningsCount: number;
    informationCount: number;
    nextIndex: number;
}

export class ErrorsAndWarningsModel extends BaseModel implements IErrorsAndWarningsModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public errors: Array<DeviceErrorModel> = null;
    public warnings: Array<DeviceErrorModel> = null;
    public information: Array<DeviceErrorModel> = null;
    public errorsCount: number = null;
    public warningsCount: number = null;
    public informationCount: number = null;
    public nextIndex: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ErrorsAndWarningsModel');

        this.errors = RestModelUtility.loadFromArray(restModel.Errors, DeviceErrorModel);
        this.warnings = RestModelUtility.loadFromArray(restModel.Warnings, DeviceErrorModel);
        this.information = RestModelUtility.loadFromArray(restModel.Information, DeviceErrorModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
