import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplyLicenceResultModel } from '@em/models/restapi/ApplyLicenceResult.Model';
import { LicenceStatusModel } from '@em/models/restapi/LicenceStatus.Model';
import { EmRestApiService } from '@em/service/restapi/base/EmRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { ShortLicenceStatusModel } from '@em/models/restapi/ShortLicenceStatus.Model';
import { map, retry } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MatDialog } from '@angular/material/dialog';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';

@Injectable()
export class RestApiLicenceService extends EmRestApiService {
    private _controller = 'licence/';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.emRestApiBase, _httpClient);
    }

    public applyLicenceKey(key: string, process?: ProcessMonitorServiceProcess): Observable<ApplyLicenceResultModel> {
        let params = new HttpParams();
        params = params.append('licenceKey', key);

        return this.get<ApplyLicenceResultModel>(`${this._controller}applylicencekey`, ApplyLicenceResultModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public downloadCLP(key: string, process?: ProcessMonitorServiceProcess): Observable<string> {
        let params = new HttpParams();
        params = params.append('licenceKey', key);

        const url = `${this.urlBase}${this._controller}downloadclp`;
        const req = new HttpRequest('GET', url, {
            responseType: 'application/x-please-download-me',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return (this.request<string, null>(req, 'string', null, process, false) as Observable<string>).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public downloadReleaseCLP(key: string, process?: ProcessMonitorServiceProcess): Observable<string> {
        let params = new HttpParams();
        params = params.append('licenceKey', key);

        const url = `${this.urlBase}${this._controller}downloadreleaseclp`;
        const req = new HttpRequest('GET', url, {
            responseType: 'application/x-please-download-me',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return (this.request<string, null>(req, 'string', null, process, false) as Observable<string>).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getShortStatus(process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ShortLicenceStatusModel> {
        return this.get<ShortLicenceStatusModel>(`${this._controller}status`, ShortLicenceStatusModel, null, process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getStatus(process?: ProcessMonitorServiceProcess): Observable<LicenceStatusModel> {
        return this.get<LicenceStatusModel>(`${this._controller}rawstatus`, LicenceStatusModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public releaseLicence(process?: ProcessMonitorServiceProcess): Observable<ApplyLicenceResultModel> {
        return this.get<ApplyLicenceResultModel>(`${this._controller}releaselicence`, ApplyLicenceResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public uploadILP(file: File, process?: ProcessMonitorServiceProcess): Observable<ApplyLicenceResultModel> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.post<FormData, ApplyLicenceResultModel>(`${this._controller}uploadilp`, formData, ApplyLicenceResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public validateLicenceKey(key: string, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        let params = new HttpParams();
        params = params.append('licenceKey', key);

        return this.get<boolean>(`${this._controller}validatelicencekey`, 'boolean', params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
