import { Injectable } from '@angular/core';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { SyncedVideoSessionModel } from '@rift/models/restapi/SyncedVideoSession.Model';
import { SyncedVideoSessionInfoModel } from '@rift/models/restapi/SyncedVideoSessionInfo.Model';
import { VideoFrameImageModel } from '@rift/models/restapi/VideoFrameImage.Model';
import { VideoScheduleModel } from '@rift/models/restapi/VideoSchedule.Model';
import { VideoScheduleInfoModel } from '@rift/models/restapi/VideoScheduleInfo.Model';
import { VideoSessionModel } from '@rift/models/restapi/VideoSession.Model';
import { VideoSessionInfoModel } from '@rift/models/restapi/VideoSessionInfo.Model';
import { VideoSettingsModel } from '@rift/models/restapi/VideoSettings.Model';
import { VideoStatusModel } from '@rift/models/restapi/VideoStatus.Model';
import { VideoStorageCapacityModel } from '@rift/models/restapi/VideoStorageCapacity.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiRecordingsService } from '@rift/service/restapi/v1/RestApi.Recordings.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class RecordingService extends RiftBaseService {

    private _addVideoScheduleLoadingTracker = new ObservableTracker<ResultModel>();
    private _cancelSynchroniseVideoSessionLoadingTracker = new ObservableTracker<ResultModel>();
    private _deleteSyncedVideoSessionLoadingTracker = new ObservableTracker<ResultModel>();
    private _deleteVideoScheduleLoadingTracker = new ObservableTracker<ResultModel>();
    private _deleteVideoSessionLoadingTracker = new ObservableTracker<ResultModel>();
    private _formatVideoStorageLoadingTracker = new ObservableTracker<ResultModel>();
    private _getSettingsLoadingTracker = new ObservableTracker<VideoSettingsModel>();
    private _getSyncedVideoSessionFirstFrameLoadingTracker = new ObservableTracker<VideoFrameImageModel>();
    private _getSyncedVideoSessionLoadingTracker = new ObservableTracker<SyncedVideoSessionModel>();
    private _getSyncedVideoSessionsLoadingTracker = new ObservableTracker<SyncedVideoSessionInfoModel>();
    private _getVideoSchedulesLoadingTracker = new ObservableTracker<VideoScheduleInfoModel>();
    private _getVideoSessionFirstFrameLoadingTracker = new ObservableTracker<VideoFrameImageModel>();
    private _getVideoSessionsLoadingTracker = new ObservableTracker<VideoSessionInfoModel>();
    private _getVideoStatusLoadingTracker = new ObservableTracker<VideoStatusModel>();
    private _getVideoStorageCapacityLoadingTracker = new ObservableTracker<VideoStorageCapacityModel>();
    private _pauseSynchroniseVideoSessionLoadingTracker = new ObservableTracker<ResultModel>();
    private _reportSyncStatesLoadingTracker = new ObservableTracker<ResultModel>();
    private _resetCroppingWindowLoadingTracker = new ObservableTracker<ResultModel>();
    private _resumeSynchroniseVideoSessionLoadingTracker = new ObservableTracker<ResultModel>();
    private _setSettingsLoadingTracker = new ObservableTracker<ResultModel>();
    private _synchroniseVideoSessionLoadingTracker = new ObservableTracker<ResultModel>();
    private _updateSyncedVideoSessionLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiRecordingsService: RestApiRecordingsService) {
        super();
    }

    public addVideoSchedule(schedule: VideoScheduleModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._addVideoScheduleLoadingTracker
            .getLoading(schedule, serial)
            .observable(this._restApiRecordingsService.addVideoSchedule(schedule, serial, process));
    }

    public cancelSynchroniseVideoSession(session: VideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._cancelSynchroniseVideoSessionLoadingTracker
            .getLoading(session, serial)
            .observable(this._restApiRecordingsService.cancelSynchroniseVideoSession(session, serial, process));
    }

    public clearCache(): void {
        this.clearObservableTrackers();
    }

    public deleteSyncedVideoSession(syncedSession: SyncedVideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._deleteSyncedVideoSessionLoadingTracker
            .getLoading(syncedSession, serial)
            .observable(this._restApiRecordingsService.deleteSyncedVideoSession(syncedSession, serial, process));
    }

    public deleteVideoSchedule(schedule: VideoScheduleModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._deleteVideoScheduleLoadingTracker
            .getLoading(schedule, serial)
            .observable(this._restApiRecordingsService.deleteVideoSchedule(schedule, serial, process));
    }

    public deleteVideoSession(session: VideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._deleteVideoSessionLoadingTracker
            .getLoading(session, serial)
            .observable(this._restApiRecordingsService.deleteVideoSession(session, serial, process));
    }

    public formatVideoStorage(serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._formatVideoStorageLoadingTracker
            .getLoading(serial)
            .observable(this._restApiRecordingsService.formatVideoStorage(serial, process));
    }

    public getSettings(serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoSettingsModel> {
        return this._getSettingsLoadingTracker
            .getLoading(serial)
            .observable(this._restApiRecordingsService.getSettings(serial, process));
    }

    public getSyncedVideoSession(sessionId: number, startTime: Date, serial: string, process?: ProcessMonitorServiceProcess): Observable<SyncedVideoSessionModel> {
        return this._getSyncedVideoSessionLoadingTracker
            .getLoading(sessionId, startTime, serial)
            .observable(this.getSyncedVideoSessions(serial, process).pipe(
                map(sessions => sessions.sessions.find(i => i.id === sessionId && i.startTime.toString() === startTime.toString()))
            ));
    }

    public getSyncedVideoSessionFirstFrame(session: SyncedVideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoFrameImageModel> {
        return this._getSyncedVideoSessionFirstFrameLoadingTracker
            .getLoading(session, serial)
            .observable(this._restApiRecordingsService.getSyncedVideoSessionFirstFrame(session, serial, process).pipe(
                catchError((err: Error, caught: Observable<VideoFrameImageModel>) => {
                    if (err.message.indexOf('Exceeded retry limit') !== -1) {
                        return of(new VideoFrameImageModel());
                    }
                    throw err;
                })
            ));
    }

    public getSyncedVideoSessions(serial: string, process?: ProcessMonitorServiceProcess): Observable<SyncedVideoSessionInfoModel> {
        return this._getSyncedVideoSessionsLoadingTracker
            .getLoading(serial)
            .observable(this._restApiRecordingsService.getSyncedVideoSessions(serial, process));
    }

    public getVideoSchedules(serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoScheduleInfoModel> {
        return this._getVideoSchedulesLoadingTracker
            .getLoading(serial)
            .observable(this._restApiRecordingsService.getVideoSchedules(serial, process));
    }

    public getVideoSessionFirstFrame(session: VideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoFrameImageModel> {
        return this._getVideoSessionFirstFrameLoadingTracker
            .getLoading(session, serial)
            .observable(this._restApiRecordingsService.getVideoSessionFirstFrame(session, serial, process).pipe(
                catchError((err: Error, caught: Observable<VideoFrameImageModel>) => {
                    if (err.message.indexOf('Exceeded retry limit') !== -1) {
                        return of(new VideoFrameImageModel());
                    }
                    throw err;
                })
            ));
    }

    public getVideoSessions(serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoSessionInfoModel> {
        return this._getVideoSessionsLoadingTracker
            .getLoading(serial)
            .observable(this._restApiRecordingsService.getVideoSessions(serial, process));
    }

    public getVideoStatus(serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoStatusModel> {
        return this._getVideoStatusLoadingTracker
            .getLoading(serial)
            .observable(this._restApiRecordingsService.getVideoStatus(serial, process));
    }

    public getVideoStorageCapacity(serial: string, process?: ProcessMonitorServiceProcess): Observable<VideoStorageCapacityModel> {
        return this._getVideoStorageCapacityLoadingTracker
            .getLoading(serial)
            .observable(this._restApiRecordingsService.getVideoStorageCapacity(serial, process));
    }

    public pauseSynchroniseVideoSession(session: VideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._pauseSynchroniseVideoSessionLoadingTracker
            .getLoading(session, serial)
            .observable(this._restApiRecordingsService.pauseSynchroniseVideoSession(session, serial, process));
    }

    public reportSyncStates(serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._reportSyncStatesLoadingTracker
            .getLoading(serial)
            .observable(this._restApiRecordingsService.reportSyncStates(serial, process));
    }

    public resetCroppingWindow(serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._resetCroppingWindowLoadingTracker
            .getLoading(serial)
            .observable(this._restApiRecordingsService.resetCroppingWindow(serial, process));
    }

    public resumeSynchroniseVideoSession(session: VideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._resumeSynchroniseVideoSessionLoadingTracker
            .getLoading(session, serial)
            .observable(this._restApiRecordingsService.resumeSynchroniseVideoSession(session, serial, process));
    }

    public setCache(videoSettingsCache: VideoSettingsModel): Observable<boolean> {
        return of(true);
    }

    public setSettings(settings: VideoSettingsModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setSettingsLoadingTracker
            .getLoading(settings, serial)
            .observable(this._restApiRecordingsService.setSettings(settings, serial, process));
    }

    public synchroniseVideoSession(session: VideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._synchroniseVideoSessionLoadingTracker
            .getLoading(session, serial)
            .observable(this._restApiRecordingsService.synchroniseVideoSession(session, serial, process));
    }

    public updateSyncedVideoSession(syncedSession: SyncedVideoSessionModel, serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateSyncedVideoSessionLoadingTracker
            .getLoading(syncedSession, serial)
            .observable(this._restApiRecordingsService.updateSyncedVideoSession(syncedSession, serial, process));
    }
}
