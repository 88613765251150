import { FormControl } from '@angular/forms';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ValidationMessageConfig } from '@shared/validation/Validation.MessageConfig';

export class ValidationMessages {
    private static validationMessageConfigs: ValidationMessageConfig[] = [
        new ValidationMessageConfig('unique', 'Must be unique', 1),
        new ValidationMessageConfig('required', 'Required', 1),
        new ValidationMessageConfig('email', 'Invalid email address', 2),
        new ValidationMessageConfig('emailAddress', 'Invalid email address', 2),
        new ValidationMessageConfig('password', 'Must contain digits, upper and lower case letters and special characters', 2),
        new ValidationMessageConfig('licencekey', 'Invalid licence key', 2),
        new ValidationMessageConfig('ipAddress', 'Invalid IP address', 2),
        new ValidationMessageConfig('httpAndHttpsAddress', 'Invalid HTTP address', 2),
        new ValidationMessageConfig('ftpAndFtpsAddress', 'Invalid FTP address', 2),
        new ValidationMessageConfig('ipAddressOrHostName', 'Invalid host name', 2),
        new ValidationMessageConfig('port', 'Invalid port 0 to 65535', 2),
        new ValidationMessageConfig('uUID', 'Invalid UUID', 2),
        new ValidationMessageConfig('mismatchedPasswords', 'Passwords must match', 2),
        new ValidationMessageConfig('minlength', 'Minimum length', 2, ValidationMessages.formatMinLengthMessage),
        new ValidationMessageConfig('maxlength', 'Maximum length', 2, ValidationMessages.formatMaxLengthMessage),
        new ValidationMessageConfig('minCombined', 'Minimum', 2, ValidationMessages.formatMinCombinedMessage),
        new ValidationMessageConfig('maxCombined', 'Maximum', 2, ValidationMessages.formatMaxCombinedMessage),
        new ValidationMessageConfig('minDate', 'Minimum date', 2, ValidationMessages.formatMinDateMessage),
        new ValidationMessageConfig('maxDate', 'Maximum date', 2, ValidationMessages.formatMaxDateMessage),
        new ValidationMessageConfig('min', 'Minimum', 2, ValidationMessages.formatMinMessage),
        new ValidationMessageConfig('max', 'Maximum', 2, ValidationMessages.formatMaxMessage),
        new ValidationMessageConfig('unitsOfMeasurementMin', 'Minimum', 2, ValidationMessages.formatMinMessage),
        new ValidationMessageConfig('unitsOfMeasurementMax', 'Maximum', 2, ValidationMessages.formatMaxMessage),
        new ValidationMessageConfig('registerName', 'Invalid Register Name', 2),
        new ValidationMessageConfig('certName', 'upper or lower case letters only', 2)
    ];

    public static addValidationMessageConfig(config: ValidationMessageConfig): void {
        if (!ValidationMessages.validationMessageConfigs.some(i => i.validatorName === config.validatorName)) {
            ValidationMessages.validationMessageConfigs.push(config);
        }
    }

    public static formatMaxCombinedMessage(config: ValidationMessageConfig, control: FormControl, validator?: any): string {
        if (!isNullOrUndefined(validator.message)) {
            return validator.message;
        } else {
            return config.message + ' ' + validator.max;
        }
    }

    public static formatMaxDateMessage(config: ValidationMessageConfig, control: FormControl, validator?: any): string {
        if (!isNullOrUndefined(validator.message)) {
            return validator.message;
        } else {
            return config.message + ' ' + validator.max;
        }
    }

    public static formatMaxLengthMessage(config: ValidationMessageConfig, control: FormControl, validator?: any): string {
        if (!isNullOrUndefined(validator.message)) {
            return validator.message;
        } else {
            return config.message + ' ' + validator.requiredLength;
        }
    }

    public static formatMaxMessage(config: ValidationMessageConfig, control: FormControl, validator?: any): string {
        if (!isNullOrUndefined(validator.message)) {
            return validator.message;
        } else {
            return config.message + ' ' + validator.max;
        }
    }

    public static formatMinCombinedMessage(config: ValidationMessageConfig, control: FormControl, validator?: any): string {
        if (!isNullOrUndefined(validator.message)) {
            return validator.message;
        } else {
            return config.message + ' ' + validator.min;
        }
    }

    public static formatMinDateMessage(config: ValidationMessageConfig, control: FormControl, validator?: any): string {
        if (!isNullOrUndefined(validator.message)) {
            return validator.message;
        } else {
            return config.message + ' ' + validator.min;
        }
    }

    public static formatMinLengthMessage(config: ValidationMessageConfig, control: FormControl, validator?: any): string {
        if (!isNullOrUndefined(validator.message)) {
            return validator.message;
        } else {
            return config.message + ' ' + validator.requiredLength;
        }
    }

    public static formatMinMessage(config: ValidationMessageConfig, control: FormControl, validator?: any): string {
        if (!isNullOrUndefined(validator.message)) {
            return validator.message;
        } else {
            return config.message + ' ' + validator.min;
        }
    }

    public static getValidatorConfig(validatorName: string): ValidationMessageConfig {
        const config = ValidationMessages.validationMessageConfigs.find(i => i.validatorName === validatorName);
        return config;
    }
}
