import { Component, Inject, Injector, OnDestroy, OnInit, HostBinding } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ConnectionModel } from '@em/models/restapi/Connection.Model';
import { ConnectionCollectionModel } from '@em/models/restapi/ConnectionCollection.Model';
import { ConnectionGroupCollectionModel } from '@em/models/restapi/ConnectionGroupCollection.Model';
import { OutboundConnectionService } from '@em/service/data/outboundconnection/OutboundConnection.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class AddConnectionDialogData {

}

export class AddConnectionDialogResult {
    public success: boolean;
    public connection?: ConnectionModel | Array<ConnectionModel>;

    public constructor(success: boolean, connection?: ConnectionModel | Array<ConnectionModel>) {
        this.success = success;
        this.connection = connection;
    }
}

@Component({
    selector: 'em-settings-outbound-connections-add-connection',
    templateUrl: './Settings.OutboundConnections.AddConnection.Component.html',
    styleUrls: ['./Settings.OutboundConnections.AddConnection.Component.scss']
})
export class SettingsOutboundConnectionsAddConnectionComponent extends BaseComponent implements OnDestroy, OnInit, ILoadDate {
    public static className: string = 'SettingsOutboundConnectionsAddConnectionComponent';

    @HostBinding()
    public id: string = 'em-settings-outbound-connections-add-connection';

    public connectionFormGroup: FormGroup;
    public connectionsFormGroup: FormGroup;
    public connection: ConnectionModel;
    public errorText: string;
    public connections: ConnectionCollectionModel;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    private _groups: ConnectionGroupCollectionModel;

    public constructor(
        private readonly _outboundConnectionService: OutboundConnectionService,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private readonly _data: AddConnectionDialogData,
        private readonly _dialogRef: MatDialogRef<SettingsOutboundConnectionsAddConnectionComponent>,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsAddConnectionComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsAddConnectionComponent.className, this.loadDataProcessText);

        this.connection = new ConnectionModel();

        this.connectionFormGroup = this._formBuilder.group({
            address: ['', Validators.compose([Validators.required, ValidationValidators.ipAddressOrHostName])],
            port: ['', Validators.compose([Validators.required, ValidationValidators.port])],
            username: [''],
            password: ['']
        }, {validators: SettingsOutboundConnectionsAddConnectionComponent.addressPortPairValidation(this.getAddresses.bind(this))});

        this.addSubscription(this.observableHandlerBase(this.connectionFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            if ((!this.isNullOrUndefined(this.connectionFormGroup.controls.address.value) && !this.isEmptyOrWhiteSpace(this.connectionFormGroup.controls.address.value)) ||
                (!this.isNullOrUndefined(this.connectionFormGroup.controls.port.value) && this.connectionFormGroup.controls.port.value !== '')) {
                this.connectionsFormGroup.disable({ emitEvent: false });
            } else {
                this.connectionsFormGroup.enable({ emitEvent: false });
            }

            this.connection.address = this.connectionFormGroup.controls.address.value;
            this.connection.port = this.connectionFormGroup.controls.port.value;
            this.connection.username = this.connectionFormGroup.controls.username.value;
            this.connection.password = this.connectionFormGroup.controls.password.value;
        }), this.formValuesChangeProcess);

        this.connectionsFormGroup = this._formBuilder.group({
            multiples: ['', Validators.compose([])],
        });

        this.addSubscription(this.observableHandlerBase(this.connectionsFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            if (!this.isNullOrUndefined(this.connectionsFormGroup.controls.multiples.value) && !this.isEmptyOrWhiteSpace(this.connectionsFormGroup.controls.multiples.value)) {
                this.connectionFormGroup.disable({ emitEvent: false });
            } else {
                this.connectionFormGroup.enable({ emitEvent: false });
            }
        }), this.formValuesChangeProcess);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public static addressPortPairValidation(getValues: (() => [string, number][]), message?: string): ValidatorFn {
        return (formGroup: FormGroup): ValidationErrors | null => {
            const address = formGroup.get('address').value;
            const port = formGroup.get('port').value;
            const values = getValues();
            const valuesLength = values.length;
            if (!isNullOrUndefined(address) && !isNullOrUndefined(port) && valuesLength > 0) {
                for(let i = 0; i < valuesLength; i++){
                    if(values[i][0] === address && values[i][1] === port){
                        // clash
                        return {
                            addressPortPairValidation:{message}
                        };
                    }
                }
            }
            return null;
        };
    }

    public getAddresses(): [string, number][] {
        const addresses: [string, number][] = [];
        if (!this.isNullOrUndefined(this.connections)) {
            const itemsLength = this.connections.items.length;
            for (let itemIndex = 0; itemIndex < itemsLength; itemIndex++) {
                addresses.push([this.connections.items[itemIndex].address, this.connections.items[itemIndex].port]);
            }
        }
        return addresses;
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public add(): void {
        if (this.connectionsFormGroup.disabled === true) {
            this.addSingel();
        } else {
            this.addMultiples();
        }
    }

    public onSelectCsvFile(event): void {
        const files = event.target.files;
        const length = files.length;

        for (let i = 0; i < length; i++) {
            const file = files[i];
            const reader = new FileReader();

            reader.onload = (function(theFile) {
                return function(e) {
                    const data = e.target.result.split(',');
                    const base64 = data[1];
                    const csv = atob(base64);

                    const result = this.loadFromCSV(csv, theFile.name);
                    if (result.hasErrors === true) {
                        this.errorText = result.error;
                    } else {
                        this._dialogRef.close(new AddConnectionDialogResult(true, result.connections));
                    }
                }.bind(this);
            }.bind(this))(file);

            reader.readAsDataURL(file);
        }
    }

    public cancel(): void {
        this._dialogRef.close(new AddConnectionDialogResult(false));
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._outboundConnectionService.getGroups(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this._groups = result;
                    }
                    return true;
                })
            ),
            this._outboundConnectionService.getConnections(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.connections = result;
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    private addSingel(): void {
        this._dialogRef.close(new AddConnectionDialogResult(true, this.connection));
    }

    private addMultiples(): void {
        const result = this.loadFromCSV(this.connectionsFormGroup.controls.multiples.value);
        if (result.hasErrors === true) {
            this.errorText = result.error;
        } else {
            this._dialogRef.close(new AddConnectionDialogResult(true, result.connections));
        }
    }

    private loadFromCSV(csv: string, fileName?: string): any {
        const haveFile = !this.isNullOrUndefined(fileName) && fileName !== '';
        const errorPrefix = haveFile ? 'File ' + fileName + ' line ' : 'Line ';

        const connections: Array<ConnectionModel> = [];
        const result = { hasErrors: false, error: '', connections };

        const linesAndSpaces = csv.split('\n');
        const length = linesAndSpaces.length;
        const lines: string[] = [];
        for (let index = 0; index < length; index++) {
            const c = linesAndSpaces[index];
            if (c !== '') {
                lines.push(c);
            }
        }
        const linesLength = lines.length;

        const addresses = this.getAddresses();

        for (let i = 0; i < linesLength; i++) {

            const line = lines[i].replace('\r', '');
            const values = line.split(',');

            if (values.length === 4 || values.length === 5) {
                const connection: ConnectionModel = new ConnectionModel();

                const addressVal: string = values[0];
                const portVal: string = values[1];
                const username: string = values[2];
                const password: string = values[3];
                const groupNameVal: string = values[4];

                if (ValidationValidators.ipAddressOrHostNamePattern.test(addressVal)) {
                    if (!addresses.some(v => v[0].toLocaleLowerCase() === addressVal.toLocaleLowerCase() && v[1].toString() === portVal) || result.connections.some(v => v.address.toLocaleLowerCase() === addressVal.toLocaleLowerCase() && v.port.toString() === portVal)) {
                        connection.address = addressVal;
                    } else {
                        result.hasErrors = true;
                        result.error = errorPrefix + (i + 1) + ' address already exists';
                        return result;
                    }
                } else {
                    result.hasErrors = true;
                    result.error = errorPrefix + (i + 1) + ' has incorrect address value';
                    return result;
                }

                if (ValidationValidators.portPattern.test(portVal)) {
                    connection.port = parseInt(portVal, 10);
                } else {
                    result.hasErrors = true;
                    result.error = errorPrefix + (i + 1) + ' has incorrect port value';
                    return result;
                }

                connection.username = username;
                connection.password = password;

                if (values.length === 5) {
                    const group = this._groups.items.find(g => g.name.toLocaleLowerCase() === groupNameVal.toLocaleLowerCase());
                    if (!this.isNullOrUndefined(group)) {
                        connection.groupId = group.connectionGroupId;
                    } else {
                        result.hasErrors = true;
                        result.error = errorPrefix + (i + 1) + ' group name not found';
                        return result;
                    }
                }

                result.connections.push(connection);
            } else {
                result.hasErrors = true;
                result.error = errorPrefix + (i + 1) + ' has incorrect number of values';
                return result;
            }
        }

        return result;
    }
}
