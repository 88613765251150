<div class="rift-recordings-addschedule-title" mat-dialog-title>
    Add Schedule
</div>
<div class="rift-recordings-addschedule-content"
    mat-dialog-content>
    <div *ngIf="data.inGroupRecording === true" style="margin-bottom: 10px;">
        Group recording will be added in each devices local time.
        <br>
        Make sure the selected time has not passed in each timezone or recordings will not occur.
        <br>
        We recommend scheduling at least a day into the future in multi timezone groups.
    </div>
    <div fxLayout="column">
        <div fxLayout="row">
            <mat-form-field>
                <mat-select #type placeholder="Type" [(value)]="durationType"
                    (selectionChange)="onTypesSlectionChange($event)">
                    <mat-option value="duration">
                        Duration
                    </mat-option>
                    <mat-option value="counts">
                        Counts
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div fxFlex></div>
            <div *ngIf="data.inGroupRecording === false">
                <ng-template [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.noSDCard === false && videoStorageCapacity.sdCardNotFormatted === false">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-progress-bar [mode]="isNullOrUndefined(videoStorageCapacity) ? 'indeterminate' : 'determinate'"
                                        [value]="100 - videoStorageCapacity?.freePercent"></mat-progress-bar>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        {{ videoStorageCapacity?.deviceStorageFreeCapacityKBHumanized + ' (free) / ' + videoStorageCapacity?.deviceStorageCapacityKBHumanized + ' (total)' }}
                    </div>
                </ng-template>
                <ng-template [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.storageWarning === true">
                    <div class="warning">Warning: Storage is getting low</div>
                </ng-template>
                <ng-template [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.noSDCard === true">
                    <div class="error">No video storage present</div>
                </ng-template>
                <ng-template [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.sdCardNotFormatted === true">
                    <div class="error">Video storage not formatted </div>
                </ng-template>
                <ng-template [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.storageError === true">
                    <div class="error">Not enough storage to record</div>
                </ng-template>
            </div>
        </div>
        <ng-template [ngIf]="type.value === 'duration'">
            <form [formGroup]="durationFormGroup" fxLayout="column">
                <div fxLayout="row" class="spacing-top spacing-bottom">
                    <div fxFlex="50">
                        From
                    </div>
                    <div fxFlex="50">
                        For
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="50">
                        <mat-form-field fxFlex class="spacing-right">
                            <input [owlDateTimeTrigger]="durationDTSelect" [owlDateTime]="durationDTSelect"
                                #startDateTime matInput formControlName="startDateTime" placeholder="Record From">
                            <mat-error>{{getFormControlErrorMessage(durationFormGroup.controls['startDateTime'])}}
                            </mat-error>
                        </mat-form-field>
                        <owl-date-time #durationDTSelect></owl-date-time>
                    </div>
                    <div fxFlex="50">
                        <mat-form-field fxFlex="50" class="spacing-right">
                            <input type="number" #hours matInput formControlName="hours" placeholder="Hours">
                            <mat-error>{{getFormControlErrorMessage(durationFormGroup.controls['hours'])}}</mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input type="number" #minutes matInput formControlName="minutes" placeholder="Minutes">
                            <mat-error>{{getFormControlErrorMessage(durationFormGroup.controls['minutes'])}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex>
                    <mat-error>{{getFormControlErrorMessage(durationFormGroup)}}</mat-error>
                </div>
            </form>
        </ng-template>
        <ng-template [ngIf]="type.value === 'counts'">
            <form [formGroup]="countsFormGroup" fxLayout="column">
                <div fxLayout="row" class="spacing-top spacing-bottom">
                    <div fxFlex="50">
                        From
                    </div>
                    <div fxFlex="50">
                        Until
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="50">
                        <mat-form-field fxFlex class="spacing-right">
                            <input [owlDateTimeTrigger]="countsDTSelect" [owlDateTime]="countsDTSelect" #startDateTime
                                matInput formControlName="startDateTime" placeholder="Record From">
                            <mat-error>{{getFormControlErrorMessage(countsFormGroup.controls['startDateTime'])}}
                            </mat-error>
                        </mat-form-field>
                        <owl-date-time #countsDTSelect></owl-date-time>
                    </div>
                    <div fxFlex="50">
                        <mat-form-field fxFlex>
                            <input type="number" #counts matInput formControlName="counts" placeholder="Counts">
                            <mat-error>{{getFormControlErrorMessage(countsFormGroup.controls['counts'])}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxFlex>
                    <mat-error>{{getFormControlErrorMessage(countsFormGroup)}}</mat-error>
                </div>
            </form>
        </ng-template>
        <div fxLayout="row" class="spacing-top spacing-bottom" *ngIf="durationType === 'duration' && data.inGroupRecording === false && masterDevice.isCapable(DeviceCapabilitiesEnum.nodeVideoValidation) && !isNullOrUndefined(nodes) && nodes.length > 0">
            <div>
                Nodes to record from
            </div>
        </div>
        <div fxLayout="row wrap" class="spacing-top spacing-bottom" style="max-width: 500px;" *ngIf="durationType === 'duration' && data.inGroupRecording === false && masterDevice.isCapable(DeviceCapabilitiesEnum.nodeVideoValidation)">
            <mat-checkbox class="spacing-right" *ngFor="let node of nodes" 
                [disabled]="node.isCapable(DeviceCapabilitiesEnum.nodeVideoValidation) === false"
                [checked]="selectedNodes.indexOf(node.serialNumber) !== -1"
                (change)="nodeSelectionChanged(node.serialNumber)">{{node.serialNumber}}</mat-checkbox>
        </div>
        <ng-template [ngIf]="showAdvancedOptions === true">
            <div fxLayout="row">
                <mat-checkbox [(ngModel)]="diagData">Include diagnostic data in recording.</mat-checkbox>
                &nbsp;
                <mat-checkbox *ngIf="activityOnlyDataCapable" [(ngModel)]="activityOnlyData">Only Capture Activity.
                </mat-checkbox>
            </div>
        </ng-template>
    </div>
</div>
<div class="rift-recordings-addschedule-actions" mat-dialog-actions>
    <button
        [disabled]="(durationFormGroup.enabled === true && durationFormGroup.valid === false) || (countsFormGroup.enabled === true && countsFormGroup.valid === false)"
        (click)="add()" mat-raised-button color="primary">Add</button>
    <button *ngIf="diagDataCapable" (click)="showAdvancedOptions = !showAdvancedOptions" mat-raised-button
        color="primary">{{showAdvancedOptions === false ? 'Show Advanced Options' : 'Hide Advanced Options'}}</button>
    <div fxFlex></div>
    <button (click)="cancel()" mat-raised-button color="warn">Cancel</button>
</div>
