import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, HostBinding } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';

/**
 * Button with progress spinner indicator.
 *
 * @export
 * @class ProgressButtonSpinnerComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
    selector: 'shr-progress-button-spinner',
    templateUrl: './ProgressButton.Spinner.Component.html',
    styleUrls: ['./ProgressButton.Spinner.Component.scss']
})
export class ProgressButtonSpinnerComponent extends BaseComponent implements OnInit, OnChanges {
    public static className: string = 'ProgressButtonSpinnerComponent';

    @HostBinding()
    public id: string = 'shr-progress-button-spinner';

    /**
     * The text to be displayed in the button.
     *
     * @type {string}
     * @memberof ProgressButtonSpinnerComponent
     */
    @Input()
    public text: string;

    @Output()
    public shrClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    /**
     * The button color.
     *
     * @type {string}
     * @memberof ProgressButtonSpinnerComponent
     */
    @Input()
    public color: string;
    /**
     * The current complete progress value when in determinate mode.
     *
     * @memberof ProgressButtonSpinnerComponent
     */
    @Input()
    public value = 0;
    /**
     * The diameter of the spinner.
     *
     * @memberof ProgressButtonSpinnerComponent
     */
    @Input()
    public diameter = 5;
    /**
     * The mode of the spinner.
     * determinate: Standard progress indicator, fills from 0% to 100%
     * indeterminate: Indicates that something is happening without conveying a discrete progress.
     *
     * @type {('determinate' | 'indeterminate')}
     * @memberof ProgressButtonSpinnerComponent
     */
    @Input()
    public mode: 'determinate' | 'indeterminate' = 'indeterminate';
    /**
     * True if disabled. else false.
     *
     * @type {boolean}
     * @memberof ProgressButtonSpinnerComponent
     */
    @Input()
    public disabled: boolean = false;
    /**
     * True if raised button display.
     *
     * @type {boolean}
     * @memberof ProgressButtonSpinnerComponent
     */
    @Input()
    public raised: boolean = true;
    /**
     * True if the progress spinner is visible. else false
     *
     * @type {boolean}
     * @memberof ProgressButtonSpinnerComponent
     */
    @Input()
    public active: boolean = false;

    public constructor(
        private readonly _elementRef: ElementRef,
        private readonly _injector: Injector) {
        super(_injector);
    }

    public clicked(event: MouseEvent): void {
        if ((this.isNullOrUndefined(this.disabled) || this.disabled === false) && (this.isNullOrUndefined(this.active) || this.active === false)) {
            this.shrClick.emit(event);
        } else {
            event.stopPropagation();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }
}
