import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';

export interface IDiscoveredDeviceRegisterModel extends IRestModel {
    registerUUID: string;
    registerName: string;
    registerIndex: number;
    registerRule: string;
    register: IRegisterBaseModel;
}

export class DiscoveredDeviceRegisterModel extends BaseModel implements IDiscoveredDeviceRegisterModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public registerUUID: string = null;
    public registerName: string = null;
    public registerIndex: number = null;
    public registerRule: string = null;
    public register: RegisterBaseModel = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-DiscoveredDeviceRegisterModel');

        this.register = RegisterBaseUtility.getRegisterTypeFromRestApiModel(restModel.Register);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
