import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IPointModel extends IRestModel {
    x: number;
    y: number;
}

export class PointModel extends BaseModel implements IPointModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public x: number = null;
    public y: number = null;

    public constructor() {
        super();

        this.registerProperty('x');
        this.registerProperty('y');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-PointModel');

        this.setPropertyOriginalValue('x', this.x);
        this.setPropertyOriginalValue('y', this.y);
    }

    public toRestApiModel(): any {
        return {
            X: parseInt(this.x.toString(), 10),
            Y: parseInt(this.y.toString(), 10),
        };
    }
}
