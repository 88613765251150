<mat-card class="edit">
    <div fxLayout="column"
        fxLayoutAlign="start stretch">
        <span>
            Filters
        </span>
    </div>
    <div fxLayout="column"
        fxLayoutAlign="start stretch">
        <ng-template *ngIf="selectedGroup"
            ngFor
            let-criteriaGroup
            [ngForOf]="selectedGroup.searchCriteriaGroups"
            let-cgIndex="index">
            <div class="group">
                <ng-template ngFor
                    let-criteria
                    [ngForOf]="criteriaGroup.searchCriteria"
                    let-cIndex="index"
                    let-isFirst="first">
                    <em-settings-address-book-filter #filter (validChanged)="onFilterValidChanged($event)"
                        (valueChanges)="onFilterChanged()"
                        (deleteClicked)="onDeleteCriteria($event, criteriaGroup)"
                        [searchCriteria]="criteria"
                        [byDeviceSettingOptions]="filterOnEntriesSettings"
                        [byMetaDataOptions]="filterOnEntriesMeta"
                        [isFirst]="isFirst"
                        [noEdit]="selectedGroup.noEdit">
                    </em-settings-address-book-filter>
                </ng-template>
                <div fxLayout="row"
                    fxLayoutAlign="center center"
                    class="spacing-top spacing-bottom">
                    <button [disabled]="selectedGroup.noEdit === true" (click)="onAddCriteria(criteriaGroup)"
                        mat-raised-button>Add And Condition</button>
                </div>
            </div>
            <div *ngIf="cgIndex < (selectedGroup.searchCriteriaGroups.length - 1)"
                fxLayout="row"
                fxLayoutAlign="center center">
                <span class="or">Or</span>
            </div>
        </ng-template>
        <div class="addorcondition"
            fxLayout="row"
            fxLayoutAlign="center center">
            <button [disabled]="isNullOrUndefined(selectedGroup) || selectedGroup.noEdit === true"
                (click)="onAddCriteriaGroup(selectedGroup)"
                mat-raised-button>Add Or Condition</button>
        </div>
    </div>
</mat-card>