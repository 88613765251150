import { AssociatedRegisterViewModel } from '@rift/components/settings/counting/viewmodels/AssociatedRegister.ViewModel';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IAssociatedRegisterConfig } from '@rift/shared/IAssociatedRegisterConfig';
import { RegisterTypeConfig } from '@rift/shared/Settings.RegisterConfig';
import { RegisterTypeEnum, RegisterTypeEnumHelpers } from '@shared/enum/RegisterType.Enum';
import { isArray, isNullOrUndefined } from '@shared/utility/General.Utility';

export interface IDependents {
    registers: Array<RegisterBaseModel>;
}

export class RegisterBaseUtility {
    /**
     * Gets the register view models that are available for association to [register].
     *
     * @static
     * @param {IAssociatedRegisterConfig} config The registers configuration.
     * @param {RegisterBaseModel} register The register to get available associated registers for.
     * @param {Array<RegisterBaseModel>} allRegisters All the device registers.
     * @param {Array<LineModel>} allLines All the device lines.
     * @returns {Array<AssociatedRegisterViewModel>} The available associated register view models.
     * @memberof RegisterBaseUtility
     */
    public static getAvailableAssociatedRegisterVMs(config: IAssociatedRegisterConfig, register: RegisterBaseModel, allRegisters: Array<RegisterBaseModel>, allLines: Array<LineModel>): Array<AssociatedRegisterViewModel> {
        if (!isNullOrUndefined(config.getAvailableAssociatedRegisterVMs)) {
            return config.getAvailableAssociatedRegisterVMs(config, register, allRegisters, allLines);
        } else {
            const vms: Array<AssociatedRegisterViewModel> = [];
            const registersLength = allRegisters.length;
            for (let index = 0; index < registersLength; index++) {
                const availableRegister = allRegisters[index];
                if (register.registerIndex !== availableRegister.registerIndex) {
                    if (
                        (
                            isNullOrUndefined(config.associatedRequireLineCountMode)
                            ||
                            availableRegister.lineIds.every(lineId => {
                                const line = allLines.find(l => l.iD === lineId);
                                return !isNullOrUndefined(line) && config.associatedRequireLineCountMode.indexOf(line.countMode) !== -1;
                            })
                        )
                        &&
                        (
                            isNullOrUndefined(config.associatedRequireRegisterTypes)
                            ||
                            config.associatedRequireRegisterTypes.indexOf(availableRegister.registerType) !== -1
                        )
                    ) {
                        vms.push(new AssociatedRegisterViewModel(availableRegister));
                    }
                }
            }
            return vms;
        }
    }

    /**
     * Gets the register view models that are associated to [register].
     *
     * @export
     * @param {IAssociatedRegisterConfig} config The registers configuration.
     * @param {RegisterBaseModel} register The register to get associated registers for.
     * @param {Array<RegisterBaseModel>} allRegisters All the device registers.
     * @param {Array<LineModel>} allLines All the device lines.
     * @returns {Array<AssociatedRegisterViewModel>} The associated register view models.
     */
    public static getAssociatedRegisterVMs(config: IAssociatedRegisterConfig, register: RegisterBaseModel, allRegisters: Array<RegisterBaseModel>, allLines: Array<LineModel>): Array<AssociatedRegisterViewModel> {
        if (!isNullOrUndefined(config)) {
            if (!isNullOrUndefined(config.getAssociatedRegisterVMs)) {
                return config.getAssociatedRegisterVMs(config, register, allRegisters, allLines);
            } else {
                const vms: Array<AssociatedRegisterViewModel> = [];
                if (config.isRegisterStore === true && !isNullOrUndefined(config.registerStorePropertyName)) {
                    const associatedRegister = allRegisters.find(r => r.registerIndex === register[config.registerStorePropertyName]);
                    if (!isNullOrUndefined(associatedRegister)) {
                        vms.push(new AssociatedRegisterViewModel(associatedRegister));
                    }
                } else if (config.isRegistersStore === true && !isNullOrUndefined(config.registersStorePropertyName) && isArray(register[config.registersStorePropertyName])) {
                    const associatedRegisters = allRegisters.filter(r => register[config.registersStorePropertyName].indexOf(r.registerIndex) !== -1);
                    if (!isNullOrUndefined(associatedRegisters)) {
                        vms.push(...associatedRegisters.map(r => new AssociatedRegisterViewModel(r)));
                    }

                } else if (config.isLineStore === true && !isNullOrUndefined(config.lineStorePropertyName)) {
                    const associatedRegisters = allRegisters.filter(r => r.isLineHost === true && !isNullOrUndefined(r.lineIds) && r.lineIds.some(id => id === register[config.lineStorePropertyName]));
                    if (!isNullOrUndefined(associatedRegisters)) {
                        vms.push(...associatedRegisters.map(r => new AssociatedRegisterViewModel(r)));
                    }

                } else if (config.isLinesStore === true && !isNullOrUndefined(config.linesStorePropertyName) && isArray(register[config.linesStorePropertyName])) {
                    const associatedRegisters = allRegisters.filter(r => r.isLineHost && !isNullOrUndefined(r.lineIds) && r.lineIds.some(id => !isNullOrUndefined(register[config.linesStorePropertyName]) && register[config.linesStorePropertyName].some((lid: number) => lid === id)));
                    if (!isNullOrUndefined(associatedRegisters)) {
                        vms.push(...associatedRegisters.map(r => new AssociatedRegisterViewModel(r)));
                    }
                }

                return vms;
            }
        }

        return [];
    }

    public static getLineHostRegisters(lineIds: Array<number>, allRegisters: Array<RegisterBaseModel>): Array<RegisterBaseModel> {
        const result: RegisterBaseModel[] = [];

        const registersLength = allRegisters.length;
        for (let ri = 0; ri < registersLength; ri++) {
            const register = allRegisters[ri];
            if (register.isLineHost === true && register.lineIds.some(lineId => lineIds.indexOf(lineId) !== -1)) {
                result.push(register);
            }
        }

        return result;
    }

    public static getRegistersById(registerIds: Array<number>, allRegisters: Array<RegisterBaseModel>): Array<RegisterBaseModel> {
        const result: RegisterBaseModel[] = [];

        const registersLength = allRegisters.length;
        for (let ri = 0; ri < registersLength; ri++) {
            const register = allRegisters[ri];
            if (registerIds.indexOf(register.registerIndex) !== -1) {
                result.push(register);
            }
        }

        return result;
    }

    public static getAssociatedRegisters(parent: RegisterBaseModel, allRegisters: Array<RegisterBaseModel>): Array<RegisterBaseModel> {
        const result: RegisterBaseModel[] = [];

        if (parent.hasAssociated === true) {
            if (!isNullOrUndefined(parent.lineIds)) {
                result.push(...this.getLineHostRegisters(parent.lineIds, allRegisters));
            }

            if (!isNullOrUndefined(parent.registerIds)) {
                result.push(...this.getRegistersById(parent.registerIds, allRegisters));
            }
        }

        return result;
    }

    public static getDependents(dependantOn: RegisterBaseModel, allRegisters: Array<RegisterBaseModel>): IDependents {
        const registersLength = allRegisters.length;
        const result = { registers: [] };

        for (let ri = 0; ri < registersLength; ri++) {
            const isRegister = allRegisters[ri];
            if (dependantOn.registerIndex !== isRegister.registerIndex) {
                if (this.isDependentOn(isRegister, dependantOn, allRegisters) === true) {
                    result.registers.push(isRegister);
                }
            }
        }
        return result.registers.length > 0 ? result : null;
    }

    public static getRegisterTypeFromRestApiModel(restModel: any): RegisterBaseModel {
        const registerType = RegisterTypeEnumHelpers.fromRestApi(restModel.RegisterType);
        return RegisterTypeConfig[registerType].loadFromRestApi(restModel);
    }

    public static isDependentOn(isRegister: RegisterBaseModel, dependantOn: RegisterBaseModel, allRegisters: Array<RegisterBaseModel>): boolean {
        if (isRegister.isLineHost === true && dependantOn.isLineHost === true) {
            return false;
        } else if (isRegister.hasAssociated === true && dependantOn.isLineHost === true) {
            const dependantOnAssociated = this.getAssociatedRegisters(isRegister, allRegisters);
            return dependantOnAssociated.some(i => i.registerIndex === dependantOn.registerIndex);
        } else if (isRegister.hasAssociated === true && dependantOn.hasAssociated === true) {
            const isRegisterAssociated = this.getAssociatedRegisters(isRegister, allRegisters);
            return isRegisterAssociated.some(di => di.registerIndex === dependantOn.registerIndex);
        }

        return false;
    }

    public static parentHoldsSettings(registerToTest: RegisterBaseModel, allRegisters: Array<RegisterBaseModel>): [boolean, RegisterBaseModel] {
        return [false, null];
    }
}
