import { RegisterBaseModel, REGISTER_BASE_EXCLUDE_PROPERTIES } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterWirelessPIRModel extends IRegisterBaseModel {
    instanceIndex: number;
    deviceIdentifier: string;
}

export class RegisterWirelessPIRModel extends RegisterBaseModel implements IRegisterWirelessPIRModel, IRestModel {

    public instanceIndex: number = null;
    public deviceIdentifier: string = null;

    public constructor() {
        super();

        this.registerProperty('deviceIdentifier');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterWirelessPIRModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('deviceIdentifier', this.deviceIdentifier);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], [], []);
    }
}
