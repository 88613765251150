import { BaseModel } from '@shared/base/Base.Model';
import { DeviceActivityActionEnum } from '@shared/enum/DeviceActivityAction.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isArray, isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';


export class DeviceActivitySummaryModel extends BaseModel implements IRestModel {

    public dates: Array<Date>;
    public readonly isIRestModel = true;
    public types: Array<DeviceActivityActionEnum>;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-DeviceActivitySummaryModel');

        if (!isNullOrUndefined(restModel.Dates) && isArray(restModel.Dates)) {
            this.dates = restModel.Dates.map(i => DateTimeNonMomentUtility.fromRestApiDateTime(i));
        }

        this.types = restModel.Types;
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
