<shr-table [isLoadingData]="loadDataProcess.isRunning"
    matSort
    (filter)="applyFilter($event)"
    [dataSource]="dataSource"
    [displayedColumns]="displayedColumns"
    [dataExportEnabled]="true"
    [dataExportGetDataHandler]="dataExportGetData"
    [dataExportGetHeaderHandler]="dataExportGetHeader">
    <ng-template #shrTableHeader>
        <mat-form-field class="spacing-right"
            style="max-width: 250px;">
            <mat-select placeholder="Notification"
                (valueChange)="onNotificationSelected($event)"
                [value]="selectedNotification">
                <mat-option *ngFor="let notification of notificationCollection?.items | orderBy: 'text'"
                    [value]="notification">
                    {{ notification.text }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-template>

    <ng-template #shrTableActions
        let-rule>
        <button matTooltip="Edit"
            mat-icon-button
            (click)="editRule(rule)">
            <mat-icon>edit</mat-icon>
        </button>
        <button matTooltip="Delete"
            mat-icon-button
            (click)="deleteRule(rule)"
            color="warn">
            <mat-icon>delete</mat-icon>
        </button>
    </ng-template>

    <ng-container matColumnDef="ruleTypeText">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Operate On</th>
        <td mat-cell
            *matCellDef="let rule">
            <ng-template [ngIf]="rule.isGroupRule === true">
                Group:{{getGroupName(rule.value1)}}
            </ng-template>
            <ng-template [ngIf]="rule.isDeviceRule === true">
                Device:{{rule.value1}}
            </ng-template>
            <ng-template [ngIf]="rule.isGlobalRule === true">
                All Devices
            </ng-template>
        </td>
    </ng-container>

    <ng-container matColumnDef="sendTo">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Send To Locations</th>
        <td mat-cell
            *matCellDef="let rule">
            <div fxLayout="column">
                <ng-template ngFor
                    let-endPoint
                    [ngForOf]="rule.endPoints">
                    <div class="subrow"
                        fxLayout="row"
                        fxLayoutAlign="start center">
                        <div fxFlex="15">
                            {{endPoint?.text}}
                        </div>
                        <div fxFlex="5">
                            {{endPoint?.protocolText}}
                        </div>
                        <div fxFlex="5">
                            {{endPoint?.contentTypeText}}
                        </div>
                        <div fxFlex="75">
                            {{endPoint?.address}}
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="isNullOrUndefined(rule.endPoints) || rule.endPoints.length === 0">
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        None Configured
                    </div>
                </ng-template>
            </div>
        </td>
    </ng-container>
</shr-table>