import { UserModel } from '@em/models/restapi/User.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';

export class UpdateUserModel extends BaseModel implements IRestModel {
    public password: string = null;
    public readonly isIRestModel = true;
    public success: boolean = null;
    public user: UserModel = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-UpdateUserModel');
        this.user = RestModelUtility.loadFrom<UserModel>(restModel.User, UserModel);
    }

    public toRestApiModel(): void {
        throw new Error('Method not implemented.');
    }
}
