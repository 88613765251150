import { Component, Inject, Injector, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigDataModel } from '@em/models/restapi/ConfigData.Model';
import { ModuleStatusModel } from '@em/models/restapi/ModuleStatus.Model';
import { SettingService } from '@em/service/data/setting/Setting.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { OkCancelDialogComponent, OkCancelDialogData } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ChangeTrackerCollection } from '@shared/generic/ChangeTrackerCollection';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { BooleanUtility } from '@shared/utility/Boolean.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';


export class SettingsCounterServiceSettingsDialogData {
    public moduleStatus: ModuleStatusModel;

    public constructor(moduleStatus: ModuleStatusModel) {
        this.moduleStatus = moduleStatus;
    }
}

export class SettingsCounterServiceSettingsDialogResult {

    public constructor() { }
}

@Component({
    selector: 'em-settings-counter-service-settings',
    templateUrl: './Settings.CounterServiceSettings.Component.html',
    styleUrls: ['./Settings.CounterServiceSettings.Component.scss']
})
export class SettingsCounterServiceSettingsComponent extends BaseComponent implements ILoadDate {
    public static className: string = 'SettingsCounterServiceSettingsComponent';

    @HostBinding()
    public id: string = 'em-settings-counter-service-settings';

    public saveProcess: ProcessMonitorServiceProcess;
    public formValueChangesProcess: ProcessMonitorServiceProcess;
    public settingsFormGroup: FormGroup;
    public settings: ConfigDataModel;
    public addresses: Array<string>;
    public settingsTracker: ChangeTrackerCollection = new ChangeTrackerCollection();

    public constructor(
        @Inject(MAT_DIALOG_DATA) private readonly _data: SettingsCounterServiceSettingsDialogData,
        private readonly _dialog: MatDialog,
        private readonly _dialogRef: MatDialogRef<SettingsCounterServiceSettingsComponent>,
        private readonly _formBuilder: FormBuilder,
        private readonly _settingService: SettingService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.formValueChangesProcess = this.processMonitorService.getProcess(SettingsCounterServiceSettingsComponent.className, 'Form values change.');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCounterServiceSettingsComponent.className, this.loadDataProcessText);
        this.saveProcess = this.processMonitorService.getProcess(SettingsCounterServiceSettingsComponent.className, 'Saving counter service settings.');

        this.settingsFormGroup = this._formBuilder.group({
            address: ['', Validators.compose([Validators.required])],
            port: ['', Validators.compose([Validators.required, ValidationValidators.port])],
            slnatAddress: ['', Validators.compose([Validators.required, Validators.maxLength(254), ValidationValidators.ipAddress])],
            slnatOn: ['', Validators.compose([Validators.required])],
            startPort: ['', Validators.compose([Validators.required, ValidationValidators.port])],
            endPort: ['', Validators.compose([Validators.required, ValidationValidators.port])],
            commTimeout: ['', Validators.compose([Validators.required, Validators.max(32768)])],
        });

        this.addSubscription(this.observableHandlerBase(this.settingsFormGroup.valueChanges, this.formValueChangesProcess).subscribe(() => {
            this.onSettingsFormGroupValueChanges();
        }), this.formValueChangesProcess);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public save(): void {
        this.saveAllChanges();
    }

    public cancel(): void {
        this._dialogRef.close();
    }

    public get hasChanges(): boolean {
        return this.settingsTracker.hasChanges;
    }

    public get isValid(): boolean {
        return this.settingsFormGroup.valid === true;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._settingService.getModuleConfig(this._data.moduleStatus.moduleId, process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settings = result;

                        this.settingsTracker.clear();
                        this.settingsTracker.track(this.settings.counterServerAddress);
                        this.settingsTracker.track(this.settings.counterServerPort);
                        this.settingsTracker.track(this.settings.sLNATAddress);
                        this.settingsTracker.track(this.settings.sLNATAddressOn);
                        this.settingsTracker.track(this.settings.toolServerStartPort);
                        this.settingsTracker.track(this.settings.toolServerEndPort);
                        this.settingsTracker.track(this.settings.commsTimeout);

                        this.addresses = this.settings.counterServerAddress.validValues;
                        this.setSettingsFormGroupValues();
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }
    private saveAllChanges(): void {
        if (this.isValid === true) {
            if (this.hasChanges === true) {
                this.addSubscription(this.observableHandlerBase(this._settingService.setModuleConfig(this._data.moduleStatus.moduleId, this.settings, this.saveProcess), this.saveProcess).subscribe(
                    () => {
                        this.loadDataStartBase(this);
                    }
                ), this.saveProcess);
            } else {
                this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('No Changes', 'There are no changes to save.', false) });
            }
        } else {
            this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Settings Invalid', 'There is one or more invalid setting.', false) });
        }
    }

    private setSettingsFormGroupValues(): void {
        this.settingsFormGroup.setValue({
            address: this.settings.counterServerAddress.value,
            port: this.settings.counterServerPort.value,
            slnatAddress: this.settings.sLNATAddress.value,
            slnatOn: BooleanUtility.toBoolean(this.settings.sLNATAddressOn.value),
            startPort: this.settings.toolServerStartPort.value,
            endPort: this.settings.toolServerEndPort.value,
            commTimeout: this.settings.commsTimeout.value,
        });
    }

    private setModelValues(): void {
        const viewModel = this.settingsFormGroup.value;

        this.settings.counterServerAddress.value = StringUtility.toString(viewModel.address);
        this.settings.counterServerPort.value = StringUtility.toString(viewModel.port);
        this.settings.sLNATAddress.value = StringUtility.toString(viewModel.slnatAddress);
        this.settings.sLNATAddressOn.value = viewModel.slnatOn ? 'True' : 'False';
        this.settings.toolServerStartPort.value = StringUtility.toString(viewModel.startPort);
        this.settings.toolServerEndPort.value = StringUtility.toString(viewModel.endPort);
        this.settings.commsTimeout.value = StringUtility.toString(viewModel.commTimeout);
    }

    private onSettingsFormGroupValueChanges(): void {
        this.setModelValues();
    }
}
