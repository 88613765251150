import { ComparisonOperatorEnum } from '@shared/enum/ComparisonOperator.Enum';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

const EXCLUDE_FIELDS = ['isValid'];

export class SearchCriteriaModel extends BaseModel implements IRestModel {
    public comparisonOperator: ComparisonOperatorEnum = null;
    public entryId: string = null;
    public isMetaData: boolean = null;

    public get isValid(): boolean {
        return this._isValid;
    }
    public set isValid(value: boolean) {
        this._isValid = value;
    }

    public readonly isIRestModel = true;
    public searchParameter: string = null;

    private _isValid: boolean = true;

    public constructor() {
        super();

        this.registerProperty('entryId');
        this.registerProperty('searchParameter');
        this.registerProperty('comparisonOperator');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-SearchCriteriaModel', EXCLUDE_FIELDS);

        this.setPropertyOriginalValue('entryId', this.entryId);
        this.setPropertyOriginalValue('searchParameter', this.searchParameter);
        this.setPropertyOriginalValue('comparisonOperator', this.comparisonOperator);
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this, EXCLUDE_FIELDS),
            EntryId: Number.isNaN(parseInt(this.entryId.toString(), 10)) ? this.entryId : parseInt(this.entryId.toString(), 10),
        };
    }
}
