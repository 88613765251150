import { Injectable } from '@angular/core';
import { DeviceHealthCountsModel } from '@em/models/restapi/DeviceHealthCounts.Model';
import { DeviceHealthCountsCollectionModel } from '@em/models/restapi/DeviceHealthCountsCollectionModel';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiDeviceService } from '@em/service/restapi/RestApi.Device.Service';
import { IPStatusEnum } from '@shared/enum/IPStatus.Enum';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { UniqueIdUtility } from '@shared/utility/UniqueId.Utility';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';


export enum DurationRangesEnum {
    day,
    week,
    oneMonth,
    twoMonths,
    fourMonths,
    sixMonths,
    year,
}

export class OverallCounts {
    public color: string;
    public level1Count: number = 0;
    public level1Percentage: number = 0;
    public level2Count: number = 0;
    public level2Percentage: number = 0;
    public level3Count: number = 0;
    public level3Percentage: number = 0;
    public level: number;
    public total: number = 0;
    public uniqueId: number;

    public constructor() {
        this.uniqueId = UniqueIdUtility.nextId;
    }
}

export class HealthCounts {
    public created: Date;
    public devices: Array<DeviceCounts>;
    public overall: OverallCounts = new OverallCounts();
    public range: Range;
    public uniqueId: number;
    recommendedAction: string;

    public constructor() {
        this.uniqueId = UniqueIdUtility.nextId;
    }
}

export class Counts {
    public uniqueId: number;
    public count: number;
    public level: number;
    public color: string;

    public constructor() {
        this.uniqueId = UniqueIdUtility.nextId;
    }
}

export class TraceRouteCounts extends Counts {
    public roundTripTimeAvarage: number;
    public roundTripTimeTotal: number;
    public traces: Trace[] = [];

    public constructor() {
        super();
    }
}

export class DeviceCounts {
    public uniqueId: number;
    public serial: string;
    public overall: Counts = new Counts();
    public connects: Counts = new Counts();
    public failedSchedules: Counts = new Counts();
    public logError: Counts = new Counts();
    public lastActivityResult: Counts = new Counts();
    public traceRoute: TraceRouteCounts = new TraceRouteCounts();
    public isConnected: boolean;
    public lastActivity: Date;
    public recommendedAction: string;

    private _lastActivityText: string;

    public constructor() {
        this.uniqueId = UniqueIdUtility.nextId;
    }

    public get lastActivityText(): string {
        if (isNullOrUndefined(this._lastActivityText)) {
            this._lastActivityText = DateTimeUtility.toRelativeDateTime(this.lastActivity);
        }
        return this._lastActivityText;
    }
}

export class Trace {
    public uniqueId: number;
    public deviceSerial: string;
    public time: Date;
    public timeDisplay: string;
    public roundtripTimeTotal: number;
    public locations: TraceLocation[] = [];
    public level: number;
    public color: string;

    public constructor() {
        this.uniqueId = UniqueIdUtility.nextId;
    }
}

export class TraceLocation {
    public uniqueId: number;
    public hostName: string;
    public iPAddress: string;
    public roundtripTime: number;
    public roundtripTimeDisplay: string;
    public iPStatus: IPStatusEnum;
    public iPStatusDisplay: string;
    public latitude: number;
    public longitude: number;
    public errorMessage: string;
    public level: number;
    public color: string;

    public constructor() {
        this.uniqueId = UniqueIdUtility.nextId;
    }
}

export class Range {
    public to: Date;
    public from: Date;
    public durationHours: number;
}

class OverallHealthCache {
    public cache: HealthCounts;
    public durationRange: DurationRangesEnum;
}

class DeviceHealthCache {
    public cache: HealthCounts;
    public friendlySerial: string;
}

@Injectable()
export class DeviceHealthService extends EmBaseService {
    private _getOverallHealthCache: Array<OverallHealthCache> = [];
    private _getDeviceHealthCache: Array<DeviceHealthCache> = [];

    private _getDeviceHealthLoadingTracker = new ObservableTracker<HealthCounts>();

    private _getOverallHealthLoadingTracker = new ObservableTracker<HealthCounts>();

    public constructor(
        private readonly _restApiDeviceService: RestApiDeviceService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getOverallHealthCache = [];
        this._getDeviceHealthCache = [];
    }

    public getDeviceHealth(friendlySerial: string, durationRange: DurationRangesEnum, process?: ProcessMonitorServiceProcess): Observable<HealthCounts> {
        if (!this._getDeviceHealthCache.some(i => i.friendlySerial === friendlySerial)) {
            const healthCounts = new HealthCounts();
            healthCounts.created = new Date();
            healthCounts.devices = [];
            healthCounts.range = this.durationRangesToRange(durationRange);

            const deviceResult = new DeviceCounts();
            deviceResult.serial = friendlySerial;

            healthCounts.devices.push(deviceResult);

            const getZip = zip(
                this._restApiDeviceService.getConnectsCount(friendlySerial, healthCounts.range.from, healthCounts.range.to, process).pipe(
                    map(result => {
                        if (!isNullOrUndefined(result)) {
                            const counts = new Counts();
                            counts.count = result;
                            counts.level = this.getLevelForConnectsInCount(counts.count, healthCounts.range.durationHours);
                            counts.color = this.getLevelColor(counts.level);

                            deviceResult.connects = counts;

                            return true;
                        } else {
                            return false;
                        }
                    })
                ),
                this._restApiDeviceService.getFailedScheduleCount(friendlySerial, healthCounts.range.from, healthCounts.range.to, process).pipe(
                    map(result => {
                        if (!isNullOrUndefined(result)) {
                            const counts = new Counts();
                            counts.count = result;
                            counts.level = this.getLevelForConnectsInCount(counts.count, healthCounts.range.durationHours);
                            counts.color = this.getLevelColor(counts.level);

                            deviceResult.failedSchedules = counts;

                            return true;
                        } else {
                            return false;
                        }
                    })
                ),
                this._restApiDeviceService.getErrorCount(friendlySerial, healthCounts.range.from, healthCounts.range.to, process).pipe(
                    map(result => {
                        if (!isNullOrUndefined(result)) {
                            const counts = new Counts();
                            counts.count = result;
                            counts.level = this.getLevelForConnectsInCount(counts.count, healthCounts.range.durationHours);
                            counts.color = this.getLevelColor(counts.level);

                            deviceResult.logError = counts;

                            return true;
                        } else {
                            return false;
                        }
                    })
                ),
                this._restApiDeviceService.getTraceRoute(friendlySerial, healthCounts.range.from, healthCounts.range.to, process).pipe(
                    map(result => {
                        if (!isNullOrUndefined(result)) {
                            const counts = new TraceRouteCounts();
                            counts.roundTripTimeTotal = 0;
                            counts.level = this.getLevelForConnectsInCount(counts.count, healthCounts.range.durationHours);
                            counts.color = this.getLevelColor(counts.level);

                            const deviceTraceLength = result.items.length;
                            for (let deviceTraceIndex = 0; deviceTraceIndex < deviceTraceLength; deviceTraceIndex++) {
                                const deviceTrace = result.items[deviceTraceIndex];
                                let traceRoundtripTimeTotal = 0;

                                const trace = new Trace();

                                trace.deviceSerial = deviceTrace.deviceSerial;
                                trace.time = deviceTrace.time;
                                trace.timeDisplay = DateTimeUtility.toShortDateTime(deviceTrace.time);

                                const deviceTraceLocationLength = deviceTrace.locations.length;
                                for (let deviceTraceLocationIndex = 0; deviceTraceLocationIndex < deviceTraceLocationLength; deviceTraceLocationIndex++) {
                                    const deviceTraceLocation = deviceTrace.locations[deviceTraceLocationIndex];
                                    const location = new TraceLocation();

                                    location.hostName = deviceTraceLocation.hostName;
                                    location.iPAddress = deviceTraceLocation.iPAddress;
                                    location.roundtripTime = deviceTraceLocation.roundtripTime;
                                    location.roundtripTimeDisplay = deviceTraceLocation.roundtripTime.toString() + 'ms';
                                    location.iPStatus = deviceTraceLocation.iPStatus;
                                    location.iPStatusDisplay = this.iPStatusToDisplay(deviceTraceLocation.iPStatus);
                                    location.latitude = deviceTraceLocation.latitude;
                                    location.longitude = deviceTraceLocation.longitude;
                                    location.errorMessage = deviceTraceLocation.errorMessage;

                                    location.level = this.getLevelForRoundtripTime(location.roundtripTime);
                                    location.color = this.getLevelColor(location.level);

                                    trace.locations.push(location);

                                    traceRoundtripTimeTotal += location.roundtripTime;
                                }

                                trace.roundtripTimeTotal = traceRoundtripTimeTotal;
                                counts.roundTripTimeTotal += traceRoundtripTimeTotal;

                                trace.level = this.getLevelForRoundtripTimeTotal(traceRoundtripTimeTotal);
                                trace.color = this.getLevelColor(trace.level);

                                counts.traces.push(trace);
                            }

                            counts.roundTripTimeAvarage = counts.roundTripTimeTotal / counts.traces.length;
                            deviceResult.traceRoute = counts;

                            return true;
                        } else {
                            return false;
                        }
                    })
                ),
                this._restApiDeviceService.getDeviceConnectionStatus(friendlySerial).pipe(
                    map(result => {
                        if (!isNullOrUndefined(result)) {
                            deviceResult.isConnected = result.isConnected;
                            deviceResult.lastActivity = result.lastConnected;
                            deviceResult.lastActivityResult.level = this.getLevelForLastActivity(deviceResult.lastActivity);
                            deviceResult.lastActivityResult.color = this.getLevelColor(deviceResult.lastActivityResult.level);
                            return true;
                        } else {
                            return false;
                        }
                    })
                ),
            );

            return this._getDeviceHealthLoadingTracker
                .getLoading(friendlySerial, durationRange)
                .observable(
                    getZip.pipe(
                        map(result => {
                            if (result.every(i => i === true)) {
                                const deviceCounts = healthCounts.devices[0];
                                healthCounts.overall.level = this.getLevelForDeviceOverall(deviceCounts.logError.count, deviceCounts.failedSchedules.count, deviceCounts.connects.count, Number.isNaN(deviceCounts.traceRoute.roundTripTimeAvarage) ? 9999 : 0, healthCounts.range.durationHours, deviceCounts.lastActivity);
                                healthCounts.overall.color = this.getLevelColor(healthCounts.overall.level);

                                healthCounts.recommendedAction = this.getActionFromResult(deviceCounts);

                                const cache = new DeviceHealthCache();
                                cache.cache = healthCounts;
                                cache.friendlySerial = deviceCounts.serial;
                                this._getDeviceHealthCache.push(cache);

                                return healthCounts;
                            } else {
                                return null;
                            }
                        })
                    )
                );
        } else {
            return of(this._getDeviceHealthCache.find(i => i.friendlySerial === friendlySerial).cache);
        }
    }

    public getOverallHealth(durationRange: DurationRangesEnum, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<HealthCounts> {
        if (!this._getOverallHealthCache.some(i => i.durationRange === durationRange)) {
            const result = new HealthCounts();
            result.created = new Date();
            result.devices = [];
            result.range = this.durationRangesToRange(durationRange);

            return this._getOverallHealthLoadingTracker
                .getLoading(durationRange)
                .observable(
                    this.getAllCountsPages(result.range.from, result.range.to, 0, result.devices, result.range.durationHours, process, retryOptions).pipe(
                        map(() => {
                            result.overall.total = result.devices.length;

                            const devicesLength = result.devices.length;
                            for (let deviceIndex = 0; deviceIndex < devicesLength; deviceIndex++) {
                                result.overall['level' + result.devices[deviceIndex].overall.level + 'Count']++;
                            }

                            result.overall.level1Percentage = result.overall.level1Count * (100 / result.overall.total);
                            result.overall.level2Percentage = result.overall.level2Count * (100 / result.overall.total);
                            result.overall.level3Percentage = result.overall.level3Count * (100 / result.overall.total);

                            if (Number.isNaN(result.overall.level1Percentage)) {
                                result.overall.level1Percentage = 0;
                            }
                            if (Number.isNaN(result.overall.level2Percentage)) {
                                result.overall.level2Percentage = 0;
                            }
                            if (Number.isNaN(result.overall.level3Percentage)) {
                                result.overall.level3Percentage = 0;
                            }

                            const cache = new OverallHealthCache();
                            cache.cache = result;
                            cache.durationRange = durationRange;
                            this._getOverallHealthCache.push(cache);

                            return cache.cache;
                        })
                    )
                );
        } else {
            return of(this._getOverallHealthCache.find(i => i.durationRange === durationRange).cache);
        }
    }

    public getLevelColor(level) {
        switch (level) {
            case 1:
                return 'green';
            case 2:
                return 'orange';
            case 3:
                return 'red';
        }
    }

    public getLevelName(level) {
        switch (level) {
            case 1:
                return 'Green';
            case 2:
                return 'Orange';
            case 3:
                return 'Red';
        }
    }

    private getAllCountsPages(from: Date, to: Date, pageIndex: number, lastitems: Array<DeviceCounts>, durationHours: number, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<DeviceHealthCountsCollectionModel> {
        const sub = this._restApiDeviceService.getHealth(from, to, pageIndex, process, retryOptions);

        return sub.pipe(
            flatMap(result => {
                if (!isNullOrUndefined(result)) {
                    const resultLength = result.items.length;

                    const devicesLength = result.items.length;
                    for (let deviceIndex = 0; deviceIndex < devicesLength; deviceIndex++) {
                        lastitems.push(this.loadDevicesHealthCounts(result.items[deviceIndex], durationHours));
                    }

                    if (resultLength === 50) {
                        return this.getAllCountsPages(from, to, pageIndex + 1, lastitems, durationHours, process, retryOptions);
                    } else {
                        return of(lastitems.concat(lastitems));
                    }
                } else {
                    return of(null);
                }
            })
        );
    }

    private loadDevicesHealthCounts(device: DeviceHealthCountsModel, durationHours: number): DeviceCounts {
        const deviceCounts = new DeviceCounts();

        deviceCounts.serial = device.serial;
        deviceCounts.isConnected = device.isConnected;
        deviceCounts.lastActivity = DateTimeUtility.toBrowserTimeZone(device.lastActivity);
        deviceCounts.lastActivityResult.level = this.getLevelForLastActivity(deviceCounts.lastActivity);
        deviceCounts.lastActivityResult.color = this.getLevelColor(deviceCounts.lastActivityResult.level);

        deviceCounts.connects.count = device.connects;
        deviceCounts.connects.level = this.getLevelForConnectsInCount(deviceCounts.connects.count, durationHours);
        deviceCounts.connects.color = this.getLevelColor(deviceCounts.connects.level);

        deviceCounts.failedSchedules.count = device.schedules;
        deviceCounts.failedSchedules.level = this.getLevelForFailedSchedulesInCount(deviceCounts.failedSchedules.count, durationHours);
        deviceCounts.failedSchedules.color = this.getLevelColor(deviceCounts.failedSchedules.level);

        deviceCounts.logError.count = device.errors;
        deviceCounts.logError.level = this.getLevelForErrorsInCount(deviceCounts.logError.count, durationHours);
        deviceCounts.logError.color = this.getLevelColor(deviceCounts.logError.level);

        deviceCounts.traceRoute.roundTripTimeAvarage = device.tripTime === null ? 9999 : device.tripTime;
        deviceCounts.traceRoute.level = this.getLevelForRoundtripTimeTotal(deviceCounts.traceRoute.roundTripTimeAvarage);
        deviceCounts.traceRoute.color = this.getLevelColor(deviceCounts.traceRoute.level);

        deviceCounts.overall.level = this.getLevelForDeviceOverall(deviceCounts.logError.count, deviceCounts.failedSchedules.count, deviceCounts.connects.count, deviceCounts.traceRoute.roundTripTimeAvarage, durationHours, deviceCounts.lastActivity);
        deviceCounts.overall.color = this.getLevelColor(deviceCounts.overall.level);

        deviceCounts.recommendedAction = this.getActionFromResult(deviceCounts);

        return deviceCounts;
    }

    private durationRangesToRange(durationRange: DurationRangesEnum): Range {
        const range = new Range();
        const to: Date = new Date();
        let from: Date = null;

        switch (durationRange) {
            case DurationRangesEnum.day:
                from = DateTimeUtility.subtract(to, 1, 'days');
                break;
            case DurationRangesEnum.week:
                from = DateTimeUtility.subtract(to, 1, 'weeks');
                break;
            case DurationRangesEnum.oneMonth:
                from = DateTimeUtility.subtract(to, 1, 'months');
                break;
            case DurationRangesEnum.twoMonths:
                from = DateTimeUtility.subtract(to, 2, 'months');
                break;
            case DurationRangesEnum.fourMonths:
                from = DateTimeUtility.subtract(to, 4, 'months');
                break;
            case DurationRangesEnum.sixMonths:
                from = DateTimeUtility.subtract(to, 6, 'months');
                break;
            case DurationRangesEnum.year:
                from = DateTimeUtility.subtract(to, 1, 'years');
                break;
        }

        range.from = from;
        range.to = to;
        range.durationHours = (to.valueOf() - from.valueOf()) * 0.000000277778;

        return range;
    }

    private iPStatusToDisplay(iPStatus: number) {
        switch (iPStatus) {
            case -1:
                return 'The ICMP echo request failed for an unknown reason';
            case 0:
                return 'The ICMP echo request succeeded';
            case 11002:
                return 'The ICMP echo request failed because the network that contains the destination computer is not reachable.';
            case 11003:
                return 'The ICMP echo request failed because the destination computer is not reachable.';
            case 11004:
                return 'The ICMP echo request failed because the destination computer that is specified in an ICMP echo message is not reachable, because it does not support the packets protocol. ';
            case 11005:
                return 'The ICMP echo request failed because the port on the destination computer is not available.';
            case 11006:
                return 'The ICMP echo request failed because of insufficient network resources.';
            case 11007:
                return 'The ICMP echo request failed because it contains an invalid option.';
            case 11008:
                return 'The ICMP echo request failed because of a hardware error.';
            case 11009:
                return 'The ICMP echo request failed because the packet containing the request is larger than the maximum transmission unit (MTU) of a node (router or gateway) located between the source and destination. The MTU defines the maximum size of a transmittable packet.';
            case 11010:
                return 'The ICMP echo Reply was not received within the allotted time.';
            case 11012:
                return 'The ICMP echo request failed because there is no valid route between the source and destination computers.';
            case 11013:
                return 'The ICMP echo request failed because its Time to Live (TTL) value reached zero, causing the forwarding node (router or gateway) to discard the packet.';
            case 11014:
                return 'The ICMP echo request failed because the packet was divided into fragments for transmission and all of the fragments were not received within the time allotted for reassembly.';
            case 11015:
                return 'The ICMP echo request failed because a node (router or gateway) encountered problems while processing the packet header. This is the status if, for example, the header contains invalid field data or an unrecognized option.';
            case 11016:
                return 'The ICMP echo request failed because the packet was discarded. This occurs when the source computers output queue has insufficient storage space, or when packets arrive at the destination too quickly to be processed.';
            case 11018:
                return 'The ICMP echo request failed because the destination IP address cannot receive ICMP echo requests or should never appear in the destination address field of any IP datagram.';
            case 11040:
                return 'The ICMP echo request failed because the destination computer that is specified in an ICMP echo message is not reachable; the exact cause of problem is unknown.';
            case 11041:
                return 'The ICMP echo request failed because its Time to Live (TTL) value reached zero, causing the forwarding node (router or gateway) to discard the packet.';
            case 11042:
                return 'The ICMP echo request failed because the header is invalid.';
            case 11043:
                return 'The ICMP echo request failed because the Next Header field does not contain a recognized value. The Next Header field indicates the extension header type (if present) or the protocol above the IP layer, for example, TCP or UDP.';
            case 11044:
                return 'The ICMP echo request failed because of an ICMP protocol error.';
            case 11045:
                return 'The ICMP echo request failed because the source address and destination address that are specified in an ICMP echo message are not in the same scope. This is typically caused by a router forwarding a packet using an interface that is outside the scope of the source address. Address scopes (link-local, site-local, and global scope) determine where on the network an address is valid.';
        }
    }

    private getActionFromResult(deviceModel: DeviceCounts) {
        // Work out the recommended action based on the levels, only have one action displayed at a time
        // if one gets resolved then another might get displayed later based on the new data we have
        // try and group stuff together so that one action could cause many problems

        if (deviceModel.logError.level >= 2) {
            return 'This device has a high number of errors in its logs, please investigate the diagnostics logs to see what is causing the issues.';
        } else if (deviceModel.lastActivityResult.level >= 3) {
            return 'This device has been disconnected for an extended period of time, please investigate why the device is not connected to the system';
        } else if (deviceModel.failedSchedules.level >= 2 && !deviceModel.isConnected) {
            return 'This device is failing schedules due to being disconnected, please investigate why this device is not connected to the system';
        } else if (deviceModel.failedSchedules.level > 2 && deviceModel.isConnected && deviceModel.traceRoute.level > 1) {
            return 'This device is failing schedules and has a high latency, please investigate the network connectivity between this device and Estate Manager';
        } else if (deviceModel.failedSchedules.level >= 2) {
            return 'This device is failing schedules, please investigate the network connectivity between this device and Estate Manager';
        } else if (deviceModel.lastActivityResult.level >= 2) {
            return 'This device has been disconnected for longer than usually expected, please investigate why this device is not connected to the system';
        } else if (deviceModel.connects.level >= 2) {
            return 'This device keeps disconnecting from Estate Manager which indicates an unstable network connection.';
        } else if (deviceModel.traceRoute.level >= 2) {
            return 'This device has high network latency, please investigate the network connectivity between this device and Estate Manager';
        }

        return 'Based on the data collected we have no recommended action';
    }

    private getLevelForDeviceOverall(errorsCount: number, failedSchedulesCount: number, connectsCount: number, traceRouteRoundtripTimeAvarage: number, durationHours: number, lastActivity: Date) {
        const errorsLevel = this.getLevelForErrorsInCount(errorsCount, durationHours);
        const failedSchedulesLevel = this.getLevelForFailedSchedulesInCount(failedSchedulesCount, durationHours);
        const connectsLevel = this.getLevelForConnectsInCount(connectsCount, durationHours);
        const roundtripTimeAvarageLevel = this.getLevelForRoundtripTimeTotal(traceRouteRoundtripTimeAvarage);
        const lastActivityLevel = this.getLevelForLastActivity(lastActivity);

        const value = errorsLevel + failedSchedulesLevel + connectsLevel + roundtripTimeAvarageLevel + lastActivityLevel;

        if (value <= 5) {
            return 1;
        } else if (value > 5 && value <= 8) {
            return 2;
        } else if (value > 8) {
            return 3;
        }
    }

    private getLevelForRoundtripTimeTotal(value: number): number {
        if (value <= 200) {
            return 1;
        } else if (value > 200 && value <= 300) {
            return 2;
        } else if (value > 300) {
            return 3;
        }
    }

    private getLevelForErrorsInCount(value: number, durationHours: number): number {
        if (value <= (durationHours * 0.25)) {
            return 1;
        } else if (value > (durationHours * 0.25) && value <= (durationHours * 0.35)) {
            return 2;
        } else if (value > (durationHours * 0.35)) {
            return 3;
        }
    }

    private getLevelForFailedSchedulesInCount(value: number, durationHours: number): number {
        if (value <= (durationHours * 1.05)) {
            return 1;
        } else if (value > (durationHours * 1.05) && value <= (durationHours * 1.4)) {
            return 2;
        } else if (value > (durationHours * 1.4)) {
            return 3;
        }
    }

    private getLevelForConnectsInCount(value: number, durationHours: number): number {
        if (value <= (durationHours * 0.25)) {
            return 1;
        } else if (value > (durationHours * 0.25) && value <= (durationHours * 0.35)) {
            return 2;
        } else if (value > (durationHours * 0.35)) {
            return 3;
        }
    }

    private getLevelForRoundtripTime(value: number): number {
        if (value <= 35) {
            return 1;
        } else if (value > 35 && value <= 55) {
            return 2;
        } else if (value > 55) {
            return 3;
        }
    }

    private getLevelForLastActivity(value: Date): number {
        const now = new Date();
        const nowSubTwoHours = DateTimeUtility.subtract(now, 2, 'hours');
        const nowSubTwoDays = DateTimeUtility.subtract(now, 2, 'days');
        if (value >= nowSubTwoHours) {
            return 1;
        } else if (value < nowSubTwoHours && value >= nowSubTwoDays) {
            return 2;
        } else if (value < nowSubTwoDays) {
            return 3;
        }
    }
}
