import { AfterViewInit, Component, ElementRef, HostBinding, Injector, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import {
    DeviceCounts,
    DeviceHealthService,
    DurationRangesEnum,
    HealthCounts,
    Trace,
    TraceLocation,
} from '@em/service/data/devicehealth/DeviceHealth.Service';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ITableRowClicked } from '@shared/component/table/Table.Component';
import { ResizedEvent } from '@shared/directive/resized/Resized.Directive.ResizedEvent';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { ILoadDate } from '@shared/interface/ILoadData';
import { IViewModel } from '@shared/interface/IViewModel';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { Observable, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

declare const google: any;
declare const Plotly: any;

class TraceViewModel implements IViewModel {
    public isSelected: boolean = false;

    public constructor(public readonly item: Trace) {
        this.item = item;
    }
}

@Component({
    selector: 'em-rift-health',
    templateUrl: './Rift.Health.Component.html',
    styleUrls: ['./Rift.Health.Component.scss']
})
export class RiftHealthComponent extends RiftBaseComponent implements IFillHeight, OnDestroy, ILoadDate, AfterViewInit {
    public static className: string = 'RiftHealthComponent';

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @HostBinding()
    public id: string = 'em-rift-health';

    public tracesDataSource: MatTableDataSource<TraceViewModel> = new MatTableDataSource<TraceViewModel>();
    public locationsDataSource: MatTableDataSource<TraceLocation> = new MatTableDataSource<TraceLocation>();
    public tracesDisplayedColumns: Array<string> = ['time', 'total'];
    public locationsDisplayedColumns: Array<string> = ['hostname', 'ipaddress', 'roundtriptime', 'ipstatus'];
    public DurationRangesEnum = DurationRangesEnum;
    public defaultLatitude: number = 52.2397844;
    public selectedRange: DurationRangesEnum = DurationRangesEnum.day;

    public traceRoutePolyline: google.maps.LatLngLiteral[] = [];

    public defaultLongitude: number = -0.8803982;
    public defaultZoom: number = 8;
    public deviceCounts: DeviceCounts;
    public healthCounts: HealthCounts;

    private _lineChartDataLoaded: boolean = false;
    private _chartOptions: any;
    private _lineChartBuilt: boolean = false;
    private _lineChartData: Array<any> = [
        {
            x: [],
            xcalendar: 'gregorian',
            y: [],
            name: 'total',
            mode: 'lines+markers',
            marker: {
                color: [],
                size: 5
            }
        }
    ];
    private _lineChartLayout: any = {
        autosize: true,
        xaxis: {
            type: 'date',
            title: 'Date & Time',
            autorange: true,
        },
        yaxis: {
            title: 'Ms'
        },
    };
    private _viewInit: boolean = false;

    public constructor(
        private readonly _deviceHealthService: DeviceHealthService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._chartOptions = this.configurationService.plotlyOptions.defaultOptions;

        this.loadDataProcess = this.processMonitorService.getProcess(RiftHealthComponent.className, this.loadDataProcessText);

        this.initConnectionState();
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.deviceService.getHostDevice(this.connectionService.connectedToFriendlySerial).pipe(
                flatMap((hostDevice) => this._deviceHealthService.getDeviceHealth(hostDevice.serialNumber, this.selectedRange, process).pipe(
                        map(result => {
                            if (!this.isNullOrUndefined(result)) {
                                this.healthCounts = result;
                                this.deviceCounts = result.devices[0];

                                this.tracesDataSource.data = this.deviceCounts.traceRoute.traces.map(i => new TraceViewModel(i));

                                this._lineChartDataLoaded = true;
                                this.initTraceRouteData();
                                this.buildLineChart();

                            }
                            return true;
                        })
                    )),
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngAfterViewInit(): void {
        this._viewInit = true;
        if (!this._lineChartBuilt) {
            this.buildLineChart();
        }
    }

    public onRoundTripTimeLineChartResized(event: ResizedEvent): void {
        if (this._lineChartBuilt === true) {
            Plotly.relayout('roundtriptime-linechart', { width: event.newWidth - 35 });
        }
    }

    public selectedRangeChange(event: MatSelectChange): void {
        this.selectedRange = event.value;
        this._deviceHealthService.clearCache();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public traceSelected(event: ITableRowClicked<TraceViewModel>): void {
        this.tracesDataSource.data.forEach(i => i.isSelected = false);
        if (!this.isNullOrUndefined(event) && !this.isNullOrUndefined(event.data)) {
            event.data.isSelected = true;
            this.locationsDataSource.data = event.data.item.locations;
        } else {
            this.locationsDataSource.data = [];
        }
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    private buildLineChart(): void {
        if (this._viewInit === true && this._lineChartDataLoaded === true) {
            Plotly.newPlot('roundtriptime-linechart', this._lineChartData, this._lineChartLayout, this._chartOptions).then(() => {
                this._lineChartBuilt = true;
            });
        }
    }

    private initTraceRouteData(): void {
        this.traceSelected(null);
        this._lineChartData[0].x = [];
        this._lineChartData[0].y = [];
        this._lineChartData[0].marker.color = [];

        const tracesLength = this.deviceCounts.traceRoute.traces.length;
        for (let traceIndex = 0; traceIndex < tracesLength; traceIndex++) {
            const trace = this.deviceCounts.traceRoute.traces[traceIndex];

            const locationData: google.maps.LatLngLiteral[] = [];

            const locationsLength = trace.locations.length;
            for (let locationIndex = 0; locationIndex < locationsLength; locationIndex++) {
                const location = trace.locations[locationIndex];
                if (!this.isNullOrUndefined(location.latitude) && !this.isNullOrUndefined(location.longitude)) {
                    locationData.push({
                        lat: location.latitude,
                        lng: location.longitude
                    });
                }
            }

            this.traceRoutePolyline = locationData;

            this._lineChartData[0].x.push(DateTimeUtility.toBrowserTimeZone(trace.time).valueOf());
            this._lineChartData[0].y.push(trace.roundtripTimeTotal);
            this._lineChartData[0].marker.color.push(trace.color);
        }

        if (this.deviceCounts.traceRoute.traces.length > 0) {
            this.traceSelected({ data: this.tracesDataSource.data[0], event: null });
        }
    }
}
