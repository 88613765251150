import { WorkflowOperationTypeEnum } from '@shared/enum/WorkflowOperationType.Enum';
import { WorkflowArgumentModel } from '@em/models/restapi/WorkflowArgument.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class WorkflowModel extends BaseModel implements IRestModel {
    public arguments: Array<WorkflowArgumentModel> = null;
    public description: string = null;
    public deviceCapabilities: Array<string> = null;
    public external: boolean = null;
    public operationType: WorkflowOperationTypeEnum = null;
    public readonly isIRestModel = true;
    public workflowName: string = null;

    private _operationTypeText: string;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-WorkflowModel');

        this.arguments = RestModelUtility.loadFromArray(restModel.Arguments, WorkflowArgumentModel);
        this.deviceCapabilities = restModel.DeviceCapabilities;
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
