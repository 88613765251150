import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { PageModel } from '@em/models/restapi/Page.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { EmRestApiService } from '@em/service/restapi/base/EmRestApi.Service';
import { BaseModel } from '@shared/base/Base.Model';
import { DeviceGroupEnum, DeviceGroupEnumHelpers } from '@shared/enum/DeviceGroup.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNumber, isNullOrUndefined } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { ColumnSortingOptionModel } from '@em/models/restapi/ColumnSortingOption.Model';
import { SortDirectionEnum } from '@shared/enum/SortDirection.Enum';
import { MatDialog } from '@angular/material/dialog';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';
import { ErrorWarningSummaryCollectionModel } from '@em/models/restapi/ErrorWarningSummaryCollection.Model';


class GetSerialsResultModel extends BaseModel implements IRestModel {
    public readonly isIRestModel: boolean = true;

    public serials: Array<string> = null;
    public success: boolean = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        this.success = restModel.Success;
        this.serials = restModel.Serials;
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}

@Injectable()
export class RestApiGroupService extends EmRestApiService {
    private _controller = 'group/';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.emRestApiBase, _httpClient);
    }

    public getGroup(groupId: number | DeviceGroupEnum, process?: ProcessMonitorServiceProcess): Observable<GroupModel> {
        return this.get<GroupModel>(`${this._controller}${groupId}`, GroupModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getErrorWarningSummary(process?: ProcessMonitorServiceProcess): Observable<ErrorWarningSummaryCollectionModel> {
        return this.get<ErrorWarningSummaryCollectionModel>(`${this._controller}errorandwarningsummary`, ErrorWarningSummaryCollectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getSerials(groupId: number | DeviceGroupEnum, process?: ProcessMonitorServiceProcess): Observable<Array<string>> {
        return this.get<GetSerialsResultModel>(`${this._controller}listserial/${groupId}`, GetSerialsResultModel, null, process).pipe(map(
            result => {
                if (result.success === true) {
                    return result.serials;
                } else {
                    throw new Error('Unable to retrieve serials.');
                }
            }
        )).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getDevices(groupId: number | DeviceGroupEnum, paginationOptions?: PaginationOptionsModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<PageModel<DeviceModel>> {
        if (isNullOrUndefined(paginationOptions)) {
            paginationOptions = new PaginationOptionsModel();
        }

        if (isNullOrUndefined(paginationOptions.columnSortingOptions)) {
            paginationOptions.columnSortingOptions = [];
            const serialSort = new ColumnSortingOptionModel();
            serialSort.sortDirection = SortDirectionEnum.ascending;
            serialSort.columnName = 'FriendlyDeviceSerial';
            paginationOptions.columnSortingOptions.push(serialSort);
        }

        paginationOptions.columnSortingOptions.forEach(val=>{
            if(val.columnName === 'SiteId'){
                val.columnName = 'SiteID';
            }
            else if(val.columnName === 'DeviceId'){
                val.columnName = 'DeviceID';
            }
        });

        let params = new HttpParams();
        params = this.setPaginationOptionsParams(paginationOptions, params);

        return this.getPage<DeviceModel, PageModel<DeviceModel>>(`${this._controller}list/${groupId}`, DeviceModel, PageModel, params, process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getErrorWarningDevices(message: string, paginationOptions?: PaginationOptionsModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<PageModel<DeviceModel>> {
        if (isNullOrUndefined(paginationOptions)) {
            paginationOptions = new PaginationOptionsModel();
        }

        let params = new HttpParams();
        params = this.setPaginationOptionsParams(paginationOptions, params);
        params = params.append('message', message);

        return this.getPage<DeviceModel, PageModel<DeviceModel>>(`${this._controller}errorandwarningdevices`, DeviceModel, PageModel, params, process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
