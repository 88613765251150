<div #mainContent
    id="em-rift-health"
    class="em-rift-health main-content scroll-content"
    fxLayout="column">
    <mat-card class="spacing-bottom">
        <mat-card-title>
            {{ healthCounts?.recommendedAction }}
        </mat-card-title>
    </mat-card>
    <div fxLayout.gt-md="row"
        fxLayout.lt-lg="column">
        <mat-card fxFlex.gt-md="20"
            fxFlex.lt-lg="100"
            ngClass.gt-md="spacing-bottom spacing-right"
            ngClass.lt-lg="spacing-bottom">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="center center">
                <mat-icon [ngStyle]="{'color': healthCounts?.overall.color}">
                    {{ healthCounts?.overall.level === 1 || healthCounts?.overall.level === 2 ? 'thumb_up' : 'thumb_down'}}
                </mat-icon>
            </mat-card-title>
            <mat-card-content fxLayout="row"
                fxLayoutAlign="center center">
                <mat-form-field>
                    <mat-select [(value)]="selectedRange"
                        (selectionChange)="selectedRangeChange($event)">
                        <mat-option [value]="DurationRangesEnum.day"> Day </mat-option>
                        <mat-option [value]="DurationRangesEnum.week"> Week </mat-option>
                        <mat-option [value]="DurationRangesEnum.oneMonth"> Month </mat-option>
                        <mat-option [value]="DurationRangesEnum.twoMonths"> 2 Months </mat-option>
                        <mat-option [value]="DurationRangesEnum.fourMonths"> 4 Months </mat-option>
                        <mat-option [value]="DurationRangesEnum.sixMonths"> 6 Months </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
        <mat-card fxFlex.gt-md="20"
            fxFlex.lt-lg="100"
            ngClass.gt-md="spacing-bottom spacing-right"
            ngClass.lt-lg="spacing-bottom">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="center center">
                <div [ngStyle]="{'color': deviceCounts?.connects.color}"
                    class="title">{{deviceCounts?.connects.count}}
                </div>
            </mat-card-title>
            <mat-card-content fxLayout="row"
                fxLayoutAlign="center center">
                Connects
            </mat-card-content>
        </mat-card>
        <mat-card fxFlex.gt-md="20"
            fxFlex.lt-lg="100"
            ngClass.gt-md="spacing-bottom spacing-right"
            ngClass.lt-lg="spacing-bottom">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="center center">
                <div [ngStyle]="{'color': deviceCounts?.failedSchedules.color}"
                    class="title">
                    {{deviceCounts?.failedSchedules.count}}</div>
            </mat-card-title>
            <mat-card-content fxLayout="row"
                fxLayoutAlign="center center">
                Failed Schedules
            </mat-card-content>
        </mat-card>
        <mat-card fxFlex.gt-md="20"
            fxFlex.lt-lg="100"
            ngClass.gt-md="spacing-bottom spacing-right"
            ngClass.lt-lg="spacing-bottom">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="center center">
                <div [ngStyle]="{'color': deviceCounts?.logError.color}"
                    class="title">{{deviceCounts?.logError.count}}
                </div>
            </mat-card-title>
            <mat-card-content fxLayout="row"
                fxLayoutAlign="center center">
                Log Errors
            </mat-card-content>
        </mat-card>
        <mat-card fxFlex.gt-md="20"
            fxFlex.lt-lg="100"
            ngClass.gt-md="spacing-bottom"
            ngClass.lt-lg="spacing-bottom">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="center center">
                <div [ngStyle]="{'color': deviceCounts?.lastActivityResult.color}"
                    class="title">{{deviceCounts?.lastActivityText}}
                </div>
            </mat-card-title>
            <mat-card-content fxLayout="row"
                fxLayoutAlign="center center">
                Last Activity
            </mat-card-content>
        </mat-card>
    </div>
    <mat-card shrResized
        (resized)="onRoundTripTimeLineChartResized($event)"
        class="spacing-bottom"
        style="height: 500px; min-height: 500px;">
        <mat-card-title>
            Round Trip Time
        </mat-card-title>
        <mat-card-content>
            <div id="roundtriptime-linechart"
                fxFlex>
            </div>
        </mat-card-content>
    </mat-card>
    <div fxLayout.gt-md="row"
        fxLayout.lt-lg="column">
        <shr-table [isLoadingData]="loadDataProcess.isRunning"
            fxFlex.gt-md="25"
            fxFlex.lt-lg="100"
            ngClass.gt-md="spacing-bottom spacing-right"
            ngClass.lt-lg="spacing-bottom"
            [rowMouseHover]="true"
            title="Trace"
            [filterEnabled]="false"
            (rowClicked)="traceSelected($event)"
            [dataSource]="tracesDataSource"
            [displayedColumns]="tracesDisplayedColumns"
            [dataExportEnabled]="false">

            <ng-container matColumnDef="time">
                <th mat-header-cell
                    *matHeaderCellDef> Time </th>
                <td mat-cell
                    *matCellDef="let trace">
                    <shr-table-cell [data]="trace"
                        keyPath="item.timeDisplay"></shr-table-cell>
                </td>
            </ng-container>

            <ng-container matColumnDef="total">
                <th mat-header-cell
                    *matHeaderCellDef> Trip Total </th>
                <td mat-cell
                    *matCellDef="let trace">
                    <shr-table-cell [data]="trace"
                        keyPath="item.roundtripTimeTotal"></shr-table-cell>
                </td>
            </ng-container>
        </shr-table>
        <shr-table [isLoadingData]="loadDataProcess.isRunning"
            fxFlex.gt-md="75"
            fxFlex.lt-lg="100"
            ngClass.gt-md="spacing-bottom"
            ngClass.lt-lg=""
            class="spacing-bottom"
            title="Route"
            [filterEnabled]="false"
            [dataSource]="locationsDataSource"
            [displayedColumns]="locationsDisplayedColumns"
            [dataExportEnabled]="false">

            <ng-container matColumnDef="hostname">
                <th mat-header-cell
                    *matHeaderCellDef> Host Name </th>
                <td mat-cell
                    *matCellDef="let location">
                    <shr-table-cell [data]="location"
                        keyPath="hostName"></shr-table-cell>
                </td>
            </ng-container>

            <ng-container matColumnDef="ipaddress">
                <th mat-header-cell
                    *matHeaderCellDef> IP Address </th>
                <td mat-cell
                    *matCellDef="let location">
                    <shr-table-cell [data]="location"
                        keyPath="iPAddress"></shr-table-cell>
                </td>
            </ng-container>

            <ng-container matColumnDef="roundtriptime">
                <th mat-header-cell
                    *matHeaderCellDef> Round Trip Time </th>
                <td mat-cell
                    *matCellDef="let location">
                    <i class="icon-clock fa far fa-clock"
                        [ngStyle]="{'color': location.color}"></i>
                    <shr-table-cell [data]="location"
                        keyPath="roundtripTimeDisplay"></shr-table-cell>
                </td>
            </ng-container>

            <ng-container matColumnDef="ipstatus">
                <th mat-header-cell
                    *matHeaderCellDef> Status </th>
                <td mat-cell
                    *matCellDef="let location">
                    <shr-table-cell [data]="location"
                        keyPath="iPStatusDisplay"></shr-table-cell>
                </td>
            </ng-container>
        </shr-table>
    </div>
    <google-map class="gmaps"
        width="100%"
        [center]="{lat:defaultLatitude, lng:defaultLongitude}"
        [zoom]="defaultZoom"
        style="height: 700px;">
        <map-polyline [path]="traceRoutePolyline">            
        </map-polyline>
    </google-map>
</div>