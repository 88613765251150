import { NgZone } from '@angular/core';
import { Line } from '@rift/components/shared/viewport/lines/Line';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { DisplayItemCollection } from '@shared/generic/canvas/DisplayItemCollection';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class LineCollection extends DisplayItemCollection<Line> {
    public constructor(
        protected readonly _zone: NgZone,
        protected readonly _loadQueue: ViewPortLoadQueueService) {
        super(_zone);
        Object.setPrototypeOf(this, Object.create(LineCollection.prototype));
    }

    public bringToFront(displayItem?: Line): void {
        this._zone.runOutsideAngular(() => {
            this.forEach(i => this.container.setChildIndex(i.container, this.container.children.length - 1));
            this.requireStageUpdate.next();
        });
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            const length = this.length;
            for (let index = 0; index < length; index++) {
                const line = this[index];
                if (!isNullOrUndefined(line) && !isNullOrUndefined(line.requireStageUpdate)) {
                    line.requireStageUpdate.unsubscribe();
                }
            }

            super.onDestroy();
        });
    }

    public push(...lines: Array<Line>): number {
        return this._zone.runOutsideAngular(() => {
            const length = lines.length;
            for (let index = 0; index < length; index++) {
                const line = lines[index];
                this.initItem(line);
                super.push(line);
            }

            return this.length;
        });
    }

    public remove(line: Line): void {
        this._zone.runOutsideAngular(() => {
            const index = this.findIndex(i => i.uniqueId === line.uniqueId);
            if (index !== -1) {
                this.splice(index, 1);
            }
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            const length = this.length;
            for (let index = 0; index < length; index++) {
                this[index].update();
            }
        });
    }

    protected initItem(line: Line): void {
        this._zone.runOutsideAngular(() => {
            super.initItem(line);
            line.loadPoints();
        });
    }
}
