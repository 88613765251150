import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DiscoveredDeviceCollectionModel } from '@rift/models/restapi/DiscoveredDeviceCollection.Model';
import { NodeInfoCollectionModel } from '@rift/models/restapi/NodeInfoCollection.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MatDialog } from '@angular/material/dialog';
import { ClientConnectionCollectionModel } from '@rift/models/restapi/ClientConnectionCollection.Model';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';

@Injectable()
export class RestApiWideTrackerService extends RiftRestApiService {
    private _controller = 'widetracker';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public discoveredDevices(process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<DiscoveredDeviceCollectionModel> {
        return this.get(`${this._controller}/discovered_devices`, DiscoveredDeviceCollectionModel, this.getTokenParams(), process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getNodeList(process?: ProcessMonitorServiceProcess): Observable<NodeInfoCollectionModel> {
        return this.get(`${this._controller}/node_list`, NodeInfoCollectionModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setAsMaster(serial: string, clientConnections: ClientConnectionCollectionModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post(`${this._controller}/set_as_master`, clientConnections, ResultModel, this.getTokenParams(['serial', serial]), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setAsNode(serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post(`${this._controller}/set_as_node`, null, ResultModel, this.getTokenParams(['serial', serial]), process, {excludeStatusCodes : [504]}).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setMasterAndNodes(serial: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post(`${this._controller}/set_master_and_nodes`, null, ResultModel, this.getTokenParams(['serial', serial]), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateConfig(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.get(`${this._controller}/update_widetracker`, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateNodeList(serials: Array<string>, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post(`${this._controller}/node_list`, serials, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public validateConfig(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.get(`${this._controller}/validate_widetracker`, 'boolean', this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result as boolean))
        );
    }
}
