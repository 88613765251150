import { isNullOrUndefined } from '@shared/utility/General.Utility';

export interface IBrowser {
    identifiers: string[];
    displayName: string;
    compatibleFrom?: number;
    downloadLink?: string;
    logoImg?: string;
}

export class BrowserUtility {
    private static _browser: IBrowser;
    private static _version: number;
    private static _hasInit: boolean = false;
    private static _userAgent: string;

    private static readonly _compatibleBrowsers: IBrowser[] = [
        { identifiers: ['Edge', 'Edg'], displayName: 'Microsoft Edge', compatibleFrom: 79, logoImg: 'assets/logos/edge.png' },
        { identifiers: ['Chrome'], displayName: 'Google Chrome', compatibleFrom: 78, downloadLink: 'https://www.google.co.uk/chrome/', logoImg: 'assets/logos/google_chrome_icon.png' },
        { identifiers: ['Firefox'], displayName: 'Firefox', compatibleFrom: 70, downloadLink: 'https://www.mozilla.org/en-GB/firefox/new/', logoImg: 'assets/logos/firefox.png' },
        { identifiers: ['Opera', 'OPR'], displayName: 'Opera', compatibleFrom: 65, downloadLink: 'https://www.opera.com/computer', logoImg: 'assets/logos/opera.png' },
        { identifiers: ['Safari'], displayName: 'Safari', logoImg: 'assets/logos/safari.png' },
    ];

    public static get compatibleBrowsers(): IBrowser[] {
        return this._compatibleBrowsers;
    }

    public static get userAgent(): string {
        if (this._hasInit === false) {
            this.init();
        }
        return this._userAgent;
    }

    public static get browser(): IBrowser {
        if (this._hasInit === false) {
            this.init();
        }
        return this._browser;
    }

    public static get version(): number {
        if (this._hasInit === false) {
            this.init();
        }
        return this._version;
    }

    public static get isCompatible(): boolean {
        if (this._hasInit === false) {
            this.init();
        }
        return isNullOrUndefined(this._browser) ? false : isNullOrUndefined(this._browser.compatibleFrom) ? true : this._version >= this._browser.compatibleFrom;
    }

    private static init(): void {
        this._userAgent =  window.navigator.userAgent;
        this._browser = this.getBrowser();
        this._version = this.getVersion(window.navigator.userAgent) || this.getVersion(window.navigator.appVersion);
        this._hasInit = true;
    }

    private static getBrowser(): IBrowser {
        const length = this._compatibleBrowsers.length;
        for (let i = 0; i < length; i++) {
            const compatibleBrowser = this._compatibleBrowsers[i];
            if (compatibleBrowser.identifiers.some(id => this._userAgent.indexOf(id) !== -1)) {
                return compatibleBrowser;
            }
        }
    }

    private static getVersion(data: string): number {
        if (!isNullOrUndefined(this._browser)) {
            const length = this._browser.identifiers.length;
            for (let i = 0; i < length; i++) {
                const identifier = this._browser.identifiers[i];
                const index = data.indexOf(identifier);
                if (index === -1) {
                    continue;
                }

                const rv = data.indexOf('rv:');
                if (identifier === 'Trident' && rv !== -1) {
                    return parseFloat(data.substring(rv + 3));
                } else {
                    return parseFloat(data.substring(index + identifier.length + 1));
                }
            }
        }
    }
}
