import { Component } from '@angular/core';

@Component({
    selector: 'shr-image-inspect',
    templateUrl: './NotFound.Component.html',
    styleUrls: ['./NotFound.Component.scss'],
})
export class NotFoundComponent {
    public static className: string = 'NotFoundComponent';

    public constructor() {
    }
}
