import { Component, Injector, Input, HostBinding } from '@angular/core';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { CountModel } from '@rift/models/websocket/Count.Model';
import { DbValidationSessionInfoModel } from '@rift/service/validation/models/database/syncsession/IDbValidationSessionInfo.Model';
import { BaseComponent } from '@shared/base/Base.Component';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

@Component({
    selector: 'rift-validation-counts-menu',
    templateUrl: './CountsMenu.Component.html',
    styleUrls: ['./CountsMenu.Component.scss']
})
export class CountsMenuComponent extends BaseComponent {

    @Input()
    public showSystemCounts: boolean = true;

    @HostBinding()
    public id: string = 'rift-validation-counts-menu';

    private _firstSystemCount: CountModel = null;
    private _registers: Array<RegisterBaseModel> = null;
    private _systemCount: CountModel = null;
    private _userCount: CountModel = null;
    private _validationSession: DbValidationSessionInfoModel = null;

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
    }

    public get displayRegisters(): Array<RegisterBaseModel> {
        return this.isNullOrUndefined(this._registers) ? [] : this._registers.filter(i => this.isNullOrUndefined(this._validationSession) || this._validationSession.registersToValidate.indexOf(i.registerIndex) !== -1);
    }

    public get firstSystemCount(): CountModel {
        return this._firstSystemCount;
    }

    public set firstSystemCount(value: CountModel) {
        this._firstSystemCount = value;
    }

    public getSystemCount(registerIndex: number): number {
        return isNullOrUndefined(this.systemCount) ? 0 : this.systemCount.counts[registerIndex] - this.firstSystemCount.counts[registerIndex];
    }

    public getUserCount(registerIndex: number): number {
        return isNullOrUndefined(this.userCount) ? 0 : this.userCount.counts[registerIndex];
    }

    public get registers(): Array<RegisterBaseModel> {
        return this._registers;
    }

    @Input()
    public set registers(value: Array<RegisterBaseModel>) {
        this._registers = value;
    }

    public get systemCount(): CountModel {
        return this._systemCount;
    }

    public set systemCount(value: CountModel) {
        this._systemCount = value;
    }

    public get userCount(): CountModel {
        return this._userCount;
    }

    public set userCount(value: CountModel) {
        this._userCount = value;
    }

    @Input()
    public set validationSession(value: DbValidationSessionInfoModel) {
        this._validationSession = value;
    }
}
