import { registerLocaleData } from '@angular/common';
import { loadTranslations, ɵMessageId, ɵTargetMessage } from '@angular/localize';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { from, Observable, Observer, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';

const supportedLanguages = ['en', 'de', 'fr', 'pt', 'bg', 'ja', 'nl', 'hu', 'es', 'hu', 'sv', 'ar'];

export interface ParsedTranslationBundle {
    locale: string;
    translations: Record<ɵMessageId, ɵTargetMessage>;
}

export interface BrowserLanguage {
    short: string;
    full: string;
}

export interface ProjectConfig {
    project: 'em' | 'rift' | 'safe-count' | 'nrf' | 'installplanner' | 'shared';
    pathToI18n?: string;
    fileName?: string;
}

export class TranslationsUtility {
    public static setTranslations(assetsBase: string, projects: ProjectConfig[]): Observable<boolean> {
        let result: Observable<Record<ɵMessageId, ɵTargetMessage>> = of({});

        const browserLanguage = this.getFirstBrowserLanguage();
        let useTranslationLanguage = browserLanguage;

        if (isNullOrUndefined(useTranslationLanguage) || !supportedLanguages.some(l => l === useTranslationLanguage.short)) {
            useTranslationLanguage = { short: 'en', full: 'en-GB' };
        }

        const length = projects.length;
        for (let i = 0; i < length; i++) {
            const project = projects[i];
            result = result.pipe(
                flatMap((translations) => this.getTranslations(`${assetsBase}/${isNullOrUndefined(project.pathToI18n) ? 'assets/i18n' : project.pathToI18n}/${project.project}.${useTranslationLanguage.short}.json`).pipe(
                        map((parsedTranslationBundle) => ({ ...translations, ...parsedTranslationBundle.translations })),
                        catchError(() => of({ ...translations })),
                    ))
            );
        }

        return result.pipe(
            flatMap((translations) => {
                loadTranslations(translations);
                if (!isNullOrUndefined(useTranslationLanguage.full) && useTranslationLanguage.full === 'en-US') {
                    return of(true);
                } else {
                    return from(this.localeInitializer(browserLanguage)).pipe(
                        map(() => true)
                    );
                }
            })
        );
    }

    public static localeInitializer(useLanguage: BrowserLanguage): Promise<any> {
        return import(
            /* webpackInclude: /\.mjs$/ */
            `../../../../node_modules/@angular/common/locales/${isNullOrUndefined(useLanguage.full) ? useLanguage.short : useLanguage.full}.mjs`
        ).then(module => {
            registerLocaleData(module.default);
        })
        .catch((err) => {
            useLanguage.full = null;
            this.localeInitializer(useLanguage);
        });
    }

    public static getFirstBrowserLanguageLocal(): string {
        const browserLanguage = this.getFirstBrowserLanguage();
        if (isNullOrUndefined(browserLanguage)) {
            return 'en-GB';
        } else if (!isNullOrUndefined(browserLanguage.full)) {
            return browserLanguage.full;
        } else {
            return browserLanguage.short;
        }
    }

    private static getFirstBrowserLanguage(): BrowserLanguage {
        const nav = window.navigator;
        const browserLanguagePropertyKeys: string[] = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
        let language: string;
        let len: number;

        // support for HTML 5.1 "navigator.languages"
        if (Array.isArray(nav.languages)) {
            const navLanguagesLength = nav.languages.length;
            for (let i = 0; i < navLanguagesLength; i++) {
                language = nav.languages[i];
                len = language.length;
                if (len <= 2) {
                    return { short: language, full: null };
                }
                if (language && len > 2) {
                    return { short: language.split('-')[0], full: language };
                }
            }
        }

        // support for other well known properties in browsers
        const browserLanguageLength = browserLanguagePropertyKeys.length;
        for (let i = 0; i < browserLanguageLength; i++) {
            language = nav[browserLanguagePropertyKeys[i]];
            if (language == null) { continue; }
            len = language.length;
            if (len <= 2) {
                return { short: language, full: null };
            }
            if (language && len > 2) {
                return { short: language.split('-')[0], full: language };
            }
        }

        return { short: null, full: null };
    }

    private static parseTranslations(fileContent: string): ParsedTranslationBundle {
        let data: ParsedTranslationBundle;
        if (
            /^[\],:{}\s]*$/.test(
                fileContent
                    .replace(/\\["\\\/bfnrtu]/g, '@')
                    .replace(
                        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                        ']'
                    )
                    .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
            )
        ) {
            data = JSON.parse(fileContent);
        } else {
            throw new Error('Only JSON translation files are supported at the moment');
        }
        return data;
    }

    private static getTranslations(url, method: 'GET' | 'POST' = 'GET', headers: { [key: string]: string } = {}): Observable<ParsedTranslationBundle> {
        return new Observable((observer: Observer<ParsedTranslationBundle>) => {
            const xhr = new XMLHttpRequest();

            xhr.onload = () => {
                try {
                    const res = this.parseTranslations(xhr.responseText);
                    observer.next(res);
                    observer.complete();
                } catch (e) {
                    observer.error(e);
                }
            };

            xhr.onerror = () => {
                observer.error(new Error('Network request failed'));
            };

            xhr.ontimeout = () => {
                observer.error(new Error('Network request timeout'));
            };

            xhr.onabort = () => {
                observer.error(new Error('Network request aborted'));
            };

            Object.keys(headers).forEach(key =>
                xhr.setRequestHeader(key, headers[key])
            );
            xhr.open(method, url, true);
            xhr.send();
        });
    }
}
