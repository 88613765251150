import { TreeControl } from '@angular/cdk/tree';
import { GroupModel } from '@em/models/restapi/Group.Model';

/**
 * Helper methods for angular materials tree.
 *
 * @export
 * @class TreeUtility
 */
export class TreeUtility {
    /**
     * Expands all tree nodes in tree.
     *
     * @static
     * @param {TreeControl<any>} tree The tree control.
     * @param {GroupModel} root The root tree element.
     * @memberof TreeUtility
     */
    public static expandAll(tree: TreeControl<any>, root: GroupModel): void {
        if (root.children.length > 0) {
            tree.expand(root);
            const length = root.children.length;
            for (let i = 0; i < length; i++) {
                TreeUtility.expandAll(tree, root.children[i]);
            }
        }
    }
}
