import { Routes } from '@angular/router';
import { DeviceGroupEnum } from '@shared/enum/DeviceGroup.Enum';
import { IRouteData } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { UserIsAuthenticated } from '@em/service/user/UserIsLevel';
import { DeviceGroupErrorWarningComponent } from './DeviceGroupErrorWarning.Component';

export const EmDeviceGroupErrorWarningRoutes: Routes = [
    {
        path: 'devicegrouperrorwarning/:groupId',
        canActivate: [UserIsAuthenticated],
        component: DeviceGroupErrorWarningComponent,
        data: {
            breadcrumb: {
                name: 'em-devicegroup-error-warning',
                text: [
                    { name: 'em-devicegroup-error-warning-inError', groupId: DeviceGroupEnum.inError, text: 'Devices In Error' },
                    { name: 'em-devicegroup-error-warning-inWarning', groupId: DeviceGroupEnum.inWarning, text: 'Devices In Warning' },
                    { lookupSetName: 'addressbook' },
                ]
            }
        } as IRouteData
    },
];
