import { RegisterBaseModel, REGISTER_BASE_EXCLUDE_PROPERTIES } from '@rift/models/restapi/RegisterBase.Model';
import { MarkedTargetEnum } from '@shared/enum/MarkedTarget.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterBasicModel extends IRegisterBaseModel {
    line: number;
    markedTarget: MarkedTargetEnum;
    lowerBound: number;
    upperBound: number;
}

/**
 * Represents a basic device register.
 * also handles RegisterTypeEnum.heightLine see
 * {setRegisterType} for type switching
 *
 * @export
 * @class RegisterBasicModel
 * @extends {RegisterBaseModel}
 * @implements {IRegisterBasicModel}
 * @implements {IRestModel}
 */
export class RegisterBasicModel extends RegisterBaseModel implements IRegisterBasicModel, IRestModel {

    public line: number = null;
    public markedTarget: MarkedTargetEnum = MarkedTargetEnum.none;

    private _lowerBound: number = null; // RegisterTypeEnum.heightLine
    private _upperBound: number = null; // RegisterTypeEnum.heightLine

    public constructor() {
        super();

        this.registerProperty('line');
        this.registerProperty('lowerBound', false, false);
        this.registerProperty('upperBound', false, false);
        this.registerProperty('markedTarget');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterBasicModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('line', this.line);
        this.setPropertyOriginalValue('lowerBound', this._lowerBound);
        this.setPropertyOriginalValue('upperBound', this._upperBound);
        this.setPropertyOriginalValue('markedTarget', this.markedTarget);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([this.line], [], []);
    }

    public get lowerBound(): number {
        return this._lowerBound;
    }

    public set lowerBound(value: number) {
        if (this._lowerBound !== value) {
            this._lowerBound = value;
            this.onPropertyChanged('lowerBound', value);
            this.setRegisterType();
        }
    }

    public get upperBound(): number {
        return this._upperBound;
    }

    public set upperBound(value: number) {
        if (this._upperBound !== value) {
            this._upperBound = value;
            this.onPropertyChanged('upperBound', value);
            this.setRegisterType();
        }
    }

    public setRegisterType(): void {
        if (isNullOrUndefined(this.lowerBound) && isNullOrUndefined(this.upperBound)) {
            this.registerType = RegisterTypeEnum.basic;
        } else if ((!isNullOrUndefined(this.lowerBound) && !isNullOrUndefined(this.upperBound))) {
            this.registerType = RegisterTypeEnum.heightLine;
        }
    }
}
