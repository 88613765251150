<div class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                BACnet
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="formGroup">
                    <div fxLayout="row" style="margin-bottom: 5px;">
                        <mat-checkbox id="site-deviceName-input"                     
                            formControlName="enabled"
                            (change)="updateModelValuesFormGroup()">
                            Enabled
                        </mat-checkbox>
                    </div>
                    <div fxLayout="row" *ngIf="isNullOrUndefined(bacnet) ? false : bacnet.enabled">
                        <mat-form-field fxFlex="50">
                            <input id="bacnet-deviceid-input"
                                type="number"                                
                                matInput
                                formControlName="deviceId"
                                placeholder="Device ID">
                            <span matSuffix>{{ bacnet?.propertyHasChangesText('deviceId') }}</span>
                            <mat-error>{{getFormControlErrorMessage(formGroup.controls['deviceId'])}}</mat-error>                            
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" *ngIf="isNullOrUndefined(bacnet) ? false : bacnet.enabled">
                        <mat-form-field fxFlex="50"
                            class="spacing-right">
                            <input id="bacnet-port-input"
                                type="number"                                
                                matInput
                                formControlName="port"
                                placeholder="Port">
                            <span matSuffix>{{ bacnet?.propertyHasChangesText('port') }}</span>
                            <mat-error>{{getFormControlErrorMessage(formGroup.controls['port'])}}</mat-error>                            
                        </mat-form-field>                        
                    </div>
                    <div *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.bacnetNetworkNumber) | async) === true && (isNullOrUndefined(bacnet) ? false : bacnet.enabled)">
                        Network
                    </div>
                    <div fxLayout="column" *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.bacnetNetworkNumber) | async) === true && (isNullOrUndefined(bacnet) ? false : bacnet.enabled)">
                        <mat-radio-group [disabled]="isReadOnly" fxLayout="column" (change)="networkTypeChanged()" [(ngModel)]="networkNumberType" [ngModelOptions]="{standalone: true}">
                            <mat-radio-button [value]="BACNETNetworkNumber.localOnly">Local</mat-radio-button>
                            <mat-radio-button [value]="BACNETNetworkNumber.broadCast">Broadcast</mat-radio-button>
                            <mat-radio-button [value]="BACNETNetworkNumber.specific">Specific</mat-radio-button>                            
                        </mat-radio-group>
                        <div fxLayout="row">
                            <mat-form-field *ngIf="networkNumberType === BACNETNetworkNumber.specific" 
                                fxFlex="50"
                                class="spacing-right">
                                <input id="bacnet-networkNumber-input"
                                    type="number"                                
                                    matInput
                                    formControlName="networkNumber"
                                    placeholder="Network Number">
                                <span matSuffix>{{ bacnet?.propertyHasChangesText('networkNumber') }}</span>
                                <mat-error>{{getFormControlErrorMessage(formGroup.controls['networkNumber'])}}</mat-error>                            
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                BACnet
            </mat-card-title>
            <mat-card-content>
                <span>Device ID, is the BACnet device identifier, a unique number assigned to each device on a BACnet network </span>
                <br>
                <br>
                <span class="new-block">Port, The UDP port BACnet traffic should be sent over</span>
                <br>
                <br>
                <span class="new-block" *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.bacnetNetworkNumber) | async) === true">Network, Settings to determine how BACnet traffic is routed.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>