import { Component, HostBinding, Injector, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ISectionChange } from '@rift/service/configjsoncompare/ConfigJsonCompare.Service';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigHistoryBaseComponent } from '@rift/components/settings/confighistory/ConfigHistory.Base.Component';

@Component({
    selector: 'rift-config-history-action-header',
    templateUrl: './ConfigHistory.ActionHeader.Component.html',
})
export class ConfigHistoryActionHeaderComponent extends ConfigHistoryBaseComponent {

    @HostBinding()
    public id: string = 'rift-config-history-action-header';

    @Input()
    public sectionChange: ISectionChange;

    @Input()
    public isIdArray: boolean = false;

    @Input()
    public isNonIdArray: boolean = false;

    public constructor(
        private readonly _sanitizer: DomSanitizer,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_sanitizer, _injector, _dialog);
    }
}
