import { isNullOrUndefined, isString, isArray } from '@shared/utility/General.Utility';
import { ArgumentOutOfRangeError } from '@shared/generic/errors/ArgumentOutOfRangeError';

/**
 * Helper methods for string.
 *
 * @export
 * @class StringUtility
 */
export class StringUtility {
    /**
     * A empty string.
     *
     * @static
     * @memberof StringUtility
     */
    public static readonly empty = '';

    /**
     * True if the value is empty. else false.
     *
     * @static
     * @param {string} value The value to check.
     * @returns {boolean} True if the value is empty. else false.
     * @memberof StringUtility
     */
    public static isEmpty(value: string): boolean {
        return isNullOrUndefined(value) || !isString(value) || value === '';
    }

    /**
     * True if value is empty or whitespace or not a string or null or undefined.
     *
     * @static
     * @param {string} value The value to check.
     * @returns {boolean} True if value is empty or whitespace or not a string or null or undefined.
     * @memberof StringUtility
     */
    public static isEmptyOrWhiteSpace(value: string): boolean {
        return isNullOrUndefined(value) || !isString(value) || value.trim() === '';
    }

    public static toString(value: number | boolean): string {
        if (!isNullOrUndefined(value)) {
            return value.toString();
        } else {
            return '';
        }
    }

    /**
     * Converts first char to upper case.
     *
     * @param value The value to convert.
     * @returns
     * @memberof StringUtil
     */
    public static firstCharToUpperCase(value: string): string {
        if (isNullOrUndefined(value)) {
            throw new ArgumentOutOfRangeError('StringUtility', 'firstCharToUpperCase', 'value');
        }
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    /**
     * Converts first char to lower case.
     *
     * @param value The value to convert.
     * @returns
     * @memberof StringUtil
     */
    public static firstCharToLowerCase(value: string): string {
        if (isNullOrUndefined(value)) {
            throw new ArgumentOutOfRangeError('StringUtility', 'firstCharToLowerCase', 'value');
        }
        return value.charAt(0).toLowerCase() + value.slice(1);
    }

    /**
     * Trims the {trim} from the start and end.
     *
     * @param value The value to trim.
     * @param trim The value to trim from.
     * @returns
     * @memberof StringUtil
     */
    public static trim(value: string, trim: string): string {
        if (isNullOrUndefined(value)) {
            throw new ArgumentOutOfRangeError('StringUtility', 'trim', 'value');
        }
        if (isNullOrUndefined(trim)) {
            throw new ArgumentOutOfRangeError('StringUtility', 'trim', 'trim');
        }

        value = this.trimStart(value, trim);
        value = this.trimEnd(value, trim);

        return value;
    }

    /**
     * Trims the start of a string of the specified string
     *
     * @param value The string to trim
     * @param trim Th value to remove
     * @returns The modified string
     * @memberof StringUtil
     */
    public static trimStart(value: string, trim: string): string {
        if (isNullOrUndefined(value)) {
            throw new ArgumentOutOfRangeError('StringUtility', 'trimStart', 'value');
        }
        if (isNullOrUndefined(trim)) {
            throw new ArgumentOutOfRangeError('StringUtility', 'trimStart', 'trim');
        }

        let length = value.length;
        for (let i = 0; i < length; i++) {
            const chr = value[i];
            if (chr === trim) {
                value = value.substring(1);
                length--;
                i--;
            } else {
                break;
            }
        }

        return value;
    }

    /**
     * Trims the end of a string of the specified string
     *
     * @param value The string to trim
     * @param trim Th value to remove
     * @returns The modified string
     * @memberof StringUtil
     */
    public static trimEnd(value: string, trim: string): string {
        if (isNullOrUndefined(value)) {
            throw new ArgumentOutOfRangeError('StringUtility', 'trimEnd', 'value');
        }
        if (isNullOrUndefined(trim)) {
            throw new ArgumentOutOfRangeError('StringUtility', 'trimEnd', 'trim');
        }

        let length = value.length;
        for (let i = length - 1; i >= 0; i--) {
            const chr = value[i];
            if (chr === trim) {
                value = value.substring(0, i);
                length--;
                i--;
            } else {
                break;
            }
        }

        return value;
    }

    /**
     * Replaces matches of searchValue in value with replaceValue.
     *
     * @param value The value to search.
     * @param searchValue The value to search for.
     * @param with.
     * @returns The updated value.
     * @memberof StringUtil
     */
    public static replace(value: string, searchValue: string | RegExp | string[], replaceValue: string): string {
        if (isNullOrUndefined(value)) {
            throw new ArgumentOutOfRangeError('StringUtility', 'replace', 'value');
        }
        if (isNullOrUndefined(searchValue)) {
            throw new ArgumentOutOfRangeError('StringUtility', 'replace', 'searchValue');
        }
        if (isNullOrUndefined(replaceValue)) {
            throw new ArgumentOutOfRangeError('StringUtility', 'replace', 'replaceValue');
        }

        if (isArray(searchValue)) {
            const length = searchValue.length;
            for (let i = 0; i < length; i++) {
                value = value.replace(searchValue[i], replaceValue);
            }
            return value;
        } else {
            value = value.replace(searchValue, replaceValue);
            // This is here as when using string.replace with searchValue of string it only replaces the first
            // occurrence of the searchValue.
            if (isString(searchValue) && value.indexOf(searchValue) !== -1) {
                return this.replace(value, searchValue, replaceValue);
            } else {
                return value;
            }
        }
    }
}
