import { filter, reduce, map, flatMap } from 'rxjs/operators';
import { ComponentType } from '@angular/cdk/portal';
import { Component, ComponentRef, HostBinding, Injector, ViewChild, ViewContainerRef, EventEmitter, Output } from '@angular/core';
import { SafeSubscriptionBase } from '@shared/base/SafeSubscription.Base';
import { BottomInfoStateEnum } from '@shared/component/bottominfo/BottomInfo.State.Enum';
import { IBottomInfoChildComponent } from '@shared/component/bottominfo/IBottomInfoChild.Component';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { isNullOrUndefined, isNumber } from '@shared/utility/General.Utility';
import { Observable, of, from } from 'rxjs';

/**
 * Show notifications at bottom right of page.
 *
 * @export
 * @class BottomInfoComponent
 * @extends {SafeSubscriptionBase}
 */
@Component({
    selector: 'shr-bottom-info',
    templateUrl: './BottomInfo.Component.html',
    styleUrls: ['./BottomInfo.Component.scss']
})
export class BottomInfoComponent extends SafeSubscriptionBase {
    public static className: string = 'BottomInfoComponent';

    @Output()
    public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public countChange: EventEmitter<number> = new EventEmitter<number>();

    @HostBinding()
    public id: string = 'shr-bottom-info';

    @ViewChild('childHost', { static: true, read: ViewContainerRef })
    public childHost: ViewContainerRef;

    public BottomInfoStateEnum = BottomInfoStateEnum;
    public childComponents: ComponentRef<IBottomInfoChildComponent>[] = [];
    public currentState: BottomInfoStateEnum = BottomInfoStateEnum.info;
    public collapsed: boolean = false;

    private _setVisible: boolean = true;
    private _isVisible: boolean = false;
    private _isVisibleCount: number = 0;

    public constructor(
        private readonly _injector: Injector) {
        super();
    }

    public get stateStyle(): string {
        switch (this.currentState) {
            case BottomInfoStateEnum.info:
                return 'shr-bottom-state-info';
            case BottomInfoStateEnum.warn:
                return 'shr-bottom-state-warn';
            case BottomInfoStateEnum.error:
                return 'shr-bottom-state-error';
            default:
                return '';
        }
    }

    public toggleShowHide(): void {
        this._setVisible = !this._setVisible;
    }

    public show(): void {
        this._setVisible = true;
    }

    public hide(): void {
        this._setVisible = false;
    }

    public get visible(): boolean {
        const length = this.childComponents.length;
        if (this._setVisible !== this._isVisible || this._isVisibleCount !== length) {
            this._isVisible = this.childComponents.length > 0 && this._setVisible;
            this._isVisibleCount = length;
            this.visibleChange.emit(this._isVisible);
            this.countChange.emit(this._isVisibleCount);
        }
        return this._isVisible;
    }

    /**
     * The count of notifications show.
     *
     * @readonly
     * @type {Observable<number>}
     * @memberof BottomInfoComponent
     */
    public get notificationCount(): Observable<number> {
        if (isNullOrUndefined(this.childComponents) || this.childComponents.length === 0) {
            return of(0);
        } else {
            return from(this.childComponents).pipe(
                filter(componentRef => !isNullOrUndefined(componentRef) && !isNullOrUndefined(componentRef.instance)),
                flatMap(componentRef => componentRef.instance.notificationCount),
                reduce((acc, one) => acc + one)
            );
        }
    }

    /**
     * Checks if any instance of {instanceIdOrComponentType} is contained in the child components.
     *
     * @template T
     * @param {(number | ComponentType<T>)} instanceIdOrComponentType The component type or id to check for.
     * @returns {boolean}
     * @memberof BottomInfoComponent
     */
    public containsComponent<T extends IBottomInfoChildComponent>(instanceIdOrComponentType: number | ComponentType<T>): boolean {
        if (!isNullOrUndefined(this.childHost)) {
            if (isNumber(instanceIdOrComponentType)) {
                return this.childComponents.some(component => component.instance.instanceId === instanceIdOrComponentType);
            } else {
                return this.childComponents.some(component => component.instance instanceof instanceIdOrComponentType);
            }
        }
    }

    /**
     * Get all components of type {TComponent} from child components.
     *
     * @template TComponent
     * @param {ComponentType<TComponent>} componentType
     * @returns {Array<ComponentRef<IBottomInfoChildComponent>>}
     * @memberof BottomInfoComponent
     */
    public getComponents<TComponent extends IBottomInfoChildComponent>(componentType: ComponentType<TComponent>): Array<ComponentRef<IBottomInfoChildComponent>> {
        if (!isNullOrUndefined(this.childHost)) {
            return this.childComponents.filter(component => component.instance instanceof componentType);
        }
    }

    /**
     * Adds a {IBottomInfoChildComponent} to child components.
     *
     * @template TComponent
     * @param {ComponentType<TComponent>} componentType
     * @returns {ComponentRef<TComponent>}
     * @memberof BottomInfoComponent
     */
    public addComponent<TComponent extends IBottomInfoChildComponent>(componentType: ComponentType<TComponent>): ComponentRef<TComponent> {
        if (!isNullOrUndefined(this.childHost)) {
            // Create component dynamically inside the ng-template
            const component = this.childHost.createComponent(componentType);

            // Push and instanceId the component so that we can keep track of which components are created
            component.instance.instanceId = this.childComponents.length;
            this.childComponents.push(component);

            if (component.instance.state > this.currentState) {
                this.currentState = component.instance.state;
            }

            this.addSubscription(component.instance.close.subscribe(() => {
                this.removeComponent(component.instance.instanceId);
            }));

            this.addSubscription(component.instance.stateChanged.subscribe((state) => {
                if (state > this.currentState) {
                    this.currentState = state;
                }
            }));

            return component;
        }
    }

    /**
     * Removes all components of {TComponent} from child components
     *
     * @template TComponent
     * @param {ComponentType<TComponent>} componentType
     * @memberof BottomInfoComponent
     */
    public removeAllComponentTypeInstances<TComponent extends IBottomInfoChildComponent>(componentType: ComponentType<TComponent>): void {
        if (!isNullOrUndefined(this.childHost)) {
            this.childComponents.filter((component) => component.instance instanceof componentType).forEach(
                componentRef => {
                    this.removeComponent(componentRef.instance.instanceId);
                }
            );
        }
    }

    /**
     * Removes a component by its instance id from child components.
     *
     * @param {number} instanceId
     * @memberof BottomInfoComponent
     */
    public removeComponent(instanceId: number): void {
        if (!isNullOrUndefined(this.childHost)) {
            const componentIndex = this.childComponents.findIndex(component => component.instance.instanceId === instanceId);
            if (componentIndex !== -1) {
                const componentRef = this.childComponents[componentIndex];
                const removeIndex = this.childHost.indexOf(componentRef.hostView);
                if (removeIndex !== -1) {
                    this.childHost.remove(removeIndex);
                    this.childComponents.splice(componentIndex, 1);

                    if (this.childComponents.length === 0) {
                        this.currentState = BottomInfoStateEnum.info;
                    } else {
                        if (this.currentState === componentRef.instance.state && !this.childComponents.some(i => i.instance.state === this.currentState)) {
                            const maxStateComponent = ArrayUtility.max(this.childComponents.map(c => c.instance), 'state');
                            if (!isNullOrUndefined(maxStateComponent) && !isNullOrUndefined(maxStateComponent.state)) {
                                this.currentState = maxStateComponent.state;
                            }
                        }
                    }
                }
            }
        }
    }
}
