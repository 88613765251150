import { RelayDefaultStateEnum } from '@shared/enum/RelayDefaultState.Enum';
import { RelayLocationEnum } from '@shared/enum/RelayLocation.Enum';
import { RelayOperationTypeEnum } from '@shared/enum/RelayOperationType.Enum';
import { RelayRegisterModel, IRelayRegisterModel } from '@rift/models/restapi/RelayRegister.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';

export interface IRelayChannelModel extends IRestModel {
    channelName: number;
    channelID: number;
    validOperationTypes: Array<number>;
    operationType: number;
    defaultState: number;
    pullupEnabled: boolean;
    relayLocation: number;
    heartbeatPulseWidth: number;
    inputFilterLowerLimit: number;
    inputFilterUpperLimit: number;
    debouncePeriod: number;
    relayRegisters: Array<IRelayRegisterModel>;
    externalRelayIdentifier: string;
}

export class RelayChannelModel extends BaseModel implements IRelayChannelModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public channelName: number = null;
    public channelID: number = null;
    public operationType: RelayOperationTypeEnum = null;
    public defaultState: RelayDefaultStateEnum = null;
    public pullupEnabled: boolean = null;
    public relayLocation: RelayLocationEnum = null;
    public heartbeatPulseWidth: number = null;
    public inputFilterLowerLimit: number = null;
    public inputFilterUpperLimit: number = null;
    public debouncePeriod: number = null;
    public relayRegisters: RestModelChangeTrackerArray<RelayRegisterModel> = new RestModelChangeTrackerArray<RelayRegisterModel>();
    public validOperationTypes: Array<RelayOperationTypeEnum> = null;
    public externalRelayIdentifier: string = null;

    public constructor() {
        super();

        this.registerProperty('operationType');
        this.registerProperty('defaultState');
        this.registerProperty('pullupEnabled');
        this.registerProperty('relayLocation');
        this.registerProperty('heartbeatPulseWidth');
        this.registerProperty('inputFilterLowerLimit');
        this.registerProperty('inputFilterUpperLimit');
        this.registerProperty('debouncePeriod');
        this.registerProperty('externalRelayIdentifier');
        this.registerChangeTrackerArray('relayRegisters');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RelayChannelModel');

        this.validOperationTypes = restModel.ValidOperationTypes;

        this.relayRegisters = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.RelayRegisters, RelayRegisterModel);

        this.setPropertyOriginalValue('operationType', this.operationType);
        this.setPropertyOriginalValue('defaultState', this.defaultState);
        this.setPropertyOriginalValue('pullupEnabled', this.pullupEnabled);
        this.setPropertyOriginalValue('relayLocation', this.relayLocation);
        this.setPropertyOriginalValue('heartbeatPulseWidth', this.heartbeatPulseWidth);
        this.setPropertyOriginalValue('inputFilterLowerLimit', this.inputFilterLowerLimit);
        this.setPropertyOriginalValue('inputFilterUpperLimit', this.inputFilterUpperLimit);
        this.setPropertyOriginalValue('debouncePeriod', this.debouncePeriod);
        this.setPropertyOriginalValue('relayRegisters', this.relayRegisters);
        this.setPropertyOriginalValue('externalRelayIdentifier', this.externalRelayIdentifier);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, null, true),
            ValidOperationTypes: this.validOperationTypes,
            RelayRegisters: RestModelUtility.toJsonArray(this.relayRegisters),
        };
    }
}
