import { Injectable, NgZone } from '@angular/core';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { SyncedVideoSessionModel } from '@rift/models/restapi/SyncedVideoSession.Model';
import { VideoScheduleModel } from '@rift/models/restapi/VideoSchedule.Model';
import { VideoSessionModel } from '@rift/models/restapi/VideoSession.Model';
import { VideoSessionProgressModel } from '@rift/models/websocket/VideoSessionProgress.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { RecordingService } from '@rift/service/data/recording/Recording.Service';
import { LocalViewModel, OnDeviceViewModel, ScheduleViewModel, SessionTypes, VideoViewModel } from '@rift/service/data/recording/Video.ViewModel1';
import { WebSocketService } from '@rift/service/websocket/WebSocket.Service';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { StreamTypeEnum } from '@shared/enum/StreamType.Enum';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { ProcessMonitorService } from '@shared/service/processmonitor/ProcessMonitor.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UserNotificationService } from '@shared/service/usernotification/UserNotification.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ObservableUtility } from '@shared/utility/Observable.Utility';
import { Observable, of, Subscription, timer, zip, Subject, merge, pipe, scheduled, concat } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

interface IVideoInfo {
    models: VideoScheduleModel[] | VideoSessionModel[] | SyncedVideoSessionModel[];
    device: DeviceModel;
};

interface IVideoScheduleInfo extends IVideoInfo {
    models: VideoScheduleModel[];
    device: DeviceModel;
};

interface IVideoSessionInfo extends IVideoInfo {
    models: VideoSessionModel[];
    device: DeviceModel;
};

interface ISyncedVideoSessionInfo extends IVideoInfo {
    models: SyncedVideoSessionModel[];
    device: DeviceModel;
};

interface IScheduledVideoCheckStatusWatch {
    sub: Subscription;
    videoViewModel: VideoViewModel;
}

interface IVideosChangedEvent {
    sessionType: SessionTypes;
}

@Injectable()
export class RecordingControlService extends RiftBaseService {
    public static readonly className = 'RecordingControlService';

    public videosChanged: Subject<IVideosChangedEvent> = new Subject<IVideosChangedEvent>();

    private _devices: DeviceModel[];
    private _isRunning: boolean = false;
    private _master: DeviceModel;
    private _nodes: DeviceModel[];
    private _videos: VideoViewModel[];
    private _progressMessageReceivedSub: Subscription;
    private _progressMessageStreamId: number = null;
    private _scheduledVideo_CheckStatus_Watchs: IScheduledVideoCheckStatusWatch[] = [];
    private _checkStatusWatchDelay: Subscription = null;

    private readonly _scheduledVideo_CheckStatus_Watch_Due: number = 0;
    private readonly _scheduledVideo_CheckStatus_Watch_Period: number = 10000;

    public constructor(
        private readonly _deviceService: DeviceService,
        private readonly _dataPollingService: DataPollingService,
        private readonly _processMonitorService: ProcessMonitorService,
        private readonly _zone: NgZone,
        private readonly _userNotificationService: UserNotificationService,
        private readonly _webSocketService: WebSocketService,
        private readonly _recordingService: RecordingService) {
        super();
    }

    public scheduledVideo_Add(masterVideo: VideoViewModel, process?: ProcessMonitorServiceProcess): void {
        if (this.isRunning === true) {
            this.getVideosData().subscribe(() => {
                this.createMasterNodesZip<boolean>(
                    masterVideo,
                    true,
                    (videoViewModel) => this._recordingService.addVideoSchedule(videoViewModel.schedule.item, videoViewModel.schedule.device.serialNumber, process).pipe(map(this.resultModelToBoolean))
                ).subscribe((results) => {
                    if (this.isZipResultSuccess(results)) {
                        return this.refreshVideosData_Schedules().subscribe();
                    }
                });
            });
        }
    }

    public scheduledVideo_Cancel(masterVideo: VideoViewModel, process?: ProcessMonitorServiceProcess): void {
        if (this.isRunning === true) {
            this.createMasterNodesZip<boolean>(
                masterVideo,
                true,
                (videoViewModel) => {
                    if (videoViewModel.devicePresent){
                        return this._recordingService.deleteVideoSchedule(videoViewModel.schedule.item, videoViewModel.schedule.device.serialNumber, process).pipe(map(this.resultModelToBoolean));
                    }

                    return of(true);
                }
            ).subscribe((results) => {
                if (this.isZipResultSuccess(results)) {
                    this.refreshVideosData_OnDevice().subscribe(
                        (refreshVideos) => {
                            if (refreshVideos === true) {
                                return this.refreshVideosData_Schedules().subscribe();
                            }
                        }
                    );
                }
            });
        }
    }

    public onDevice_Delete(masterVideo: VideoViewModel, process?: ProcessMonitorServiceProcess): void {
        if (this.isRunning === true) {
            this.createMasterNodesZip<boolean>(
                masterVideo,
                true,
                (videoViewModel) => {
                    if (videoViewModel.devicePresent && videoViewModel.recordingPresent){
                        return this._recordingService.deleteVideoSession(videoViewModel.onDevice.item, videoViewModel.onDevice.device.serialNumber, process).pipe(map(this.resultModelToBoolean));
                    }

                    return of(true);
                }
            ).subscribe((results) => {
                if (this.isZipResultSuccess(results)) {
                    this.refreshVideosData_OnDevice().subscribe();
                }
            });
        }
    }

    public onDevice_Download(masterVideo: VideoViewModel, process?: ProcessMonitorServiceProcess): void {
        if (this.isRunning === true) {
            this.createMasterNodesZip<boolean>(
                masterVideo,
                true,
                (videoViewModel) => {
                    if (videoViewModel.recordingPresent && videoViewModel.devicePresent){
                        videoViewModel.onDevice.isQueued = true;

                        return this._recordingService.synchroniseVideoSession(videoViewModel.onDevice.item, videoViewModel.onDevice.device.serialNumber, process).pipe(map(this.resultModelToBoolean));
                    }
                }
            ).subscribe();
        }
    }

    public onDevice_Download_Pause(masterVideo: VideoViewModel, process?: ProcessMonitorServiceProcess): void {
        if (this.isRunning === true) {
            this.createMasterNodesZip<boolean>(
                masterVideo,
                true,
                (videoViewModel) => this._recordingService.pauseSynchroniseVideoSession(videoViewModel.onDevice.item, videoViewModel.onDevice.device.serialNumber, process).pipe(map(this.resultModelToBoolean))
            ).subscribe((results) => {
                if (this.isZipResultSuccess(results)) {
                    if (masterVideo.onDevice.isActive === true){
                        masterVideo.onDevice.isActive = false;
                        masterVideo.onDevice.isPaused = true;
                    }

                    masterVideo.nodeVideos.forEach(n => {
                        if (n.onDevice.isActive === true){
                            n.onDevice.isActive = false;
                            n.onDevice.isPaused = true;
                        }
                    });

                    this.refreshVideosData_OnDevice().subscribe(
                        (refreshVideos) => {
                            if (refreshVideos === true) {
                                return this.refreshVideosData_Local().subscribe();
                            }
                        }
                    );
                }
            });
        }
    }

    public onDevice_Download_Cancel(masterVideo: VideoViewModel, process?: ProcessMonitorServiceProcess): void {
        if (this.isRunning === true) {
            this.createMasterNodesZip<boolean>(
                masterVideo,
                true,
                (videoViewModel) => this._recordingService.cancelSynchroniseVideoSession(videoViewModel.onDevice.item, videoViewModel.onDevice.device.serialNumber, process).pipe(map(this.resultModelToBoolean))
            ).subscribe((results) => {
                if (this.isZipResultSuccess(results)) {
                    this.refreshVideosData_OnDevice().subscribe(
                        (refreshVideos) => {
                            if (refreshVideos === true) {
                                return this.refreshVideosData_Local().subscribe();
                            }
                        }
                    );
                } else {
                    return of(false);
                }
            });
        }
    }

    public onDevice_Download_Resume(masterVideo: VideoViewModel, process?: ProcessMonitorServiceProcess): void {
        if (this.isRunning === true) {
            this.createMasterNodesZip<boolean>(
                masterVideo,
                true,
                (videoViewModel) => this._recordingService.resumeSynchroniseVideoSession(videoViewModel.onDevice.item, videoViewModel.onDevice.device.serialNumber, process).pipe(map(this.resultModelToBoolean))
            ).subscribe(
                (results) => {
                    if (this.isZipResultSuccess(results)) {
                        if (masterVideo.onDevice.isPaused === true){
                            masterVideo.onDevice.isPaused = false;
                            masterVideo.onDevice.isActive = true;
                        }

                        masterVideo.nodeVideos.forEach(n => {
                            if (n.onDevice.isPaused === true){
                                n.onDevice.isPaused = false;
                                n.onDevice.isActive = true;
                            }
                        });

                        this.refreshVideosData_OnDevice().subscribe(
                            (refreshVideos) => {
                                if (refreshVideos === true) {
                                    this.refreshVideosData_Local().subscribe();
                                }
                            }
                        );
                    }
                },
            );
        }
    }

    public local_Delete(masterVideo: VideoViewModel, process?: ProcessMonitorServiceProcess): void {
        if (this.isRunning === true) {
            this.createMasterNodesZip<boolean>(
                masterVideo,
                true,
                (videoViewModel) => {
                    if (!isNullOrUndefined(videoViewModel.local) && !isNullOrUndefined(videoViewModel.local.item)){
                        return this._recordingService.deleteSyncedVideoSession(videoViewModel.local.item, videoViewModel.local.device.serialNumber, process).pipe(map(this.resultModelToBoolean));
                    }

                    // If we don't have a local model then
                    // there wasn't anything to delete for that device
                    // so just return true
                    return of(true);
                }
            ).subscribe(
                (results) => {
                    if (this.isZipResultSuccess(results)) {
                        return this.refreshVideosData_Local().subscribe(
                            (refreshVideos) => {
                                if (refreshVideos === true) {
                                    return this.refreshVideosData_OnDevice().subscribe();
                                } else {
                                    return of(false);
                                }
                            }
                        );
                    } else {
                        return of(false);
                    }
                },
            );
        }
    }

    public clearVideosCache(){
        this._videos = null;
    }

    public get videos(): Observable<VideoViewModel[]> {
        return this.getVideosData();
    }

    public get videos_AnyActive(): boolean {
        if (this.isRunning === true) {
            return this._videos?.some(video => video.isAnyActive) ?? false;
        }
        return false;
    }

    public get isRunning(): boolean {
        return this._isRunning;
    }

    public reset(): void {
        this.stop();
        this.start();
    }

    public start(): void {
        if (this.isRunning === false) {
            this._isRunning = true;

            this.startProgressMessageStream();
        }
    }

    public stop(): void {
        if (this.isRunning === true) {
            this._isRunning = false;

            this.stopProgressMessageStream();

            this._videos = null;
            this._devices = null;
            this._master = null;
            this._nodes = null;

            if (!isNullOrUndefined(this._checkStatusWatchDelay)){
                this._checkStatusWatchDelay.unsubscribe();
            }
            this._checkStatusWatchDelay = null;

            if (!isNullOrUndefined(this._scheduledVideo_CheckStatus_Watchs)){
                this._scheduledVideo_CheckStatus_Watchs.forEach(watch => {
                    watch.sub.unsubscribe();
                });
            }
            this._scheduledVideo_CheckStatus_Watchs = [];
        }
    }

    private startProgressMessageStream(): void {
        if (this.isRunning === true) {
            this._progressMessageReceivedSub = this._webSocketService.videoSessionProgressMessageReceived.subscribe(message => this.onProgressMessageReceived(message));
            this._progressMessageStreamId = this._webSocketService.startStream(StreamTypeEnum.syncVideoSession);
        }
    }

    private stopProgressMessageStream(): void {
        if (this.isRunning === false) {
            if (!isNullOrUndefined(this._progressMessageReceivedSub)) {
                this._progressMessageReceivedSub.unsubscribe();
                this._progressMessageReceivedSub = null;
            }
            if (!isNullOrUndefined(this._progressMessageStreamId)) {
                this._webSocketService.stopStream(StreamTypeEnum.syncVideoSession, this._progressMessageStreamId);
            }
        }
    }

    private onProgressMessageReceived(message: VideoSessionProgressModel): void {
        const videoViewModel = this.videoViewModel_FindByDeviceAndSessionId(this._videos, message.serial, message.sessionId);
        if (!isNullOrUndefined(videoViewModel) && videoViewModel.isOnDevice === true) {
            this._zone.run(() => {
                videoViewModel.onDevice.progress = message.progress;
                videoViewModel.onDevice.isComplete = message.isComplete;
                videoViewModel.onDevice.isPaused = message.isPaused;
                videoViewModel.onDevice.isQueued = message.isQueued;
                videoViewModel.onDevice.isActive = (message.isComplete === false && message.isCancel === false && message.isPaused === false && message.isQueued === false);

                if (videoViewModel.onDevice.isComplete === true){
                    return this.refreshVideosData_Local().subscribe(
                        (refreshVideos) => {
                            if (refreshVideos === true) {
                                this.refreshVideosData_OnDevice().subscribe();
                            }
                        },
                    );
                }
            });
        }
    }

    /**
     * Preforms First time load of video data.
     *
     * @private
     * @memberof RecordingControlService
     */
    private getVideosData(): Observable<VideoViewModel[]> {
        if (isNullOrUndefined(this._videos)) {
            this._videos = [];
            return this.getDevices().pipe(
                flatMap((devices) => {
                    if (!isNullOrUndefined(devices) && devices.length > 0) {
                        return this.devicesVideoStorageValid().pipe(
                            flatMap((isValid) => {
                                if (isValid === true) {
                                    return zip(
                                        this.getVideosData_Schedules(),
                                        this.getVideosData_OnDevice(),
                                        this.getVideosData_Local(),
                                    ).pipe(
                                        map((videoInfos) => {
                                            const videoScheduleInfos = videoInfos[0];
                                            const videoSessionInfos = videoInfos[1];
                                            const syncedVideoSessionInfos = videoInfos[2];

                                            this.load_VideoInfos(videoScheduleInfos, 'schedule');
                                            this.load_VideoInfos(videoSessionInfos, 'onDevice');
                                            this.load_VideoInfos(syncedVideoSessionInfos, 'local');

                                            return this._videos;
                                        }),
                                    );
                                } else {
                                    return of(this._videos);
                                }
                            })
                        );
                    } else {
                        return of(this._videos);
                    }
                }),
            );
        } else {
            return of(this._videos);
        }
    }

    private load_VideoInfos(videoInfos: IVideoInfo[], sessionType: SessionTypes): void {
        const masterVideoInfo = this.videoInfos_FindByDevice(videoInfos, this._master);
        let videosLength = this._videos.length;

        if (!isNullOrUndefined(masterVideoInfo)) {
            const masterVideoInfoLength = masterVideoInfo.models.length;
            if (masterVideoInfoLength > 0) {
                const nodesLength = this._nodes.length;

                const firstFrameObs: Observable<boolean>[] = [];
                // Go through all the master VideoInfo and add or update masterVideoViewModel
                for (let masterVideoInfoI = 0; masterVideoInfoI < masterVideoInfoLength; masterVideoInfoI++) {
                    const masterSession = masterVideoInfo.models[masterVideoInfoI];

                    // Find the masterVideo for the master and masterSession.
                    let masterVideo = this.videoViewModel_FindByDeviceAndSession(this._videos, this._master, masterSession);
                    if (isNullOrUndefined(masterVideo)) {
                        // There is no VideoViewModel for this masterSession create one add add it.
                        masterVideo = new VideoViewModel(this._master);
                        this._videos.push(masterVideo);
                    }

                    if (masterVideo.isSessionType(sessionType) === false) {
                        // The masterVideoViewModel has no VideoViewModel for the sessionType add one.
                        this.videoViewModel_NewSessionType(masterVideo, sessionType, masterSession, this._master);
                        if (sessionType === 'schedule') {
                            this.scheduledVideo_Start_CheckStatus_Watch(masterVideo);
                        }
                    }

                    this.videoViewModel_UpdateSessionType(masterVideo, sessionType, masterSession);
                    firstFrameObs.push(this.getSessionFirstFrame(masterVideo));

                    switch (sessionType) {
                        case 'local':
                            const local = masterSession as SyncedVideoSessionModel;
                            const numNodes = local.nodes.length;

                            for (let i = 0; i < numNodes; i++){
                                const node = local.nodes[i];

                                // See if the physical node is present
                                let physicalNode = this._nodes.find(n => n.serialNumber === node.serial);
                                let nodeVideoViewModel = this.videoViewModel_FindByDeviceAndGroupIdentifier(masterVideo.nodeVideos, node.serial, node.syncedVideoSessionData?.groupRecordingIdentifier);

                                if (isNullOrUndefined(nodeVideoViewModel)){
                                    if (isNullOrUndefined(physicalNode)){
                                        // If the node isn't currently around
                                        // then we need to create a fake one and we'll need
                                        // a flag in the view model to indicate this is the case
                                        physicalNode = new DeviceModel();
                                        physicalNode.serialNumber = node.serial;

                                        nodeVideoViewModel = new VideoViewModel(physicalNode);
                                        nodeVideoViewModel.devicePresent = false;
                                    }
                                    else{
                                        nodeVideoViewModel = new VideoViewModel(physicalNode);
                                    }

                                    if (!masterVideo.nodeVideos.some(v => v.device.serialNumber === node.serial)){
                                        masterVideo.nodeVideos.push(nodeVideoViewModel);
                                    }
                                }

                                if (nodeVideoViewModel.isSessionType(sessionType) === false) {
                                    // The nodeVideoViewModel has no VideoViewModel for the sessionType add one.
                                    this.videoViewModel_NewSessionType(nodeVideoViewModel, sessionType, node.syncedVideoSessionData as SyncedVideoSessionModel, physicalNode);
                                }

                                this.videoViewModel_UpdateSessionType(nodeVideoViewModel, sessionType, node.syncedVideoSessionData as SyncedVideoSessionModel);
                            }

                            break;
                        case 'onDevice':
                            const session = masterSession as VideoSessionModel;
                            const numNodeSessions = session.nodes.length;

                            for (let i = 0; i < numNodeSessions; i++){
                                const node = session.nodes[i];

                                // See if the physical node is present
                                let physicalNode = this._nodes.find(n => n.serialNumber === node);
                                let nodeVideoViewModel = null;
                                let nodeVideoSession: VideoSessionModel = null;

                                if (isNullOrUndefined(physicalNode)){
                                    nodeVideoViewModel = this.videoViewModel_FindByDeviceAndGroupIdentifier(masterVideo.nodeVideos, node, masterSession.groupRecordingIdentifier);

                                    // Blank session as we don't know anything about it
                                    nodeVideoSession = new VideoSessionModel();
                                    nodeVideoSession.endTime = masterSession.endTime;
                                    nodeVideoSession.startTime = masterSession.startTime;
                                }
                                else{
                                    // Need to find the recording info from the
                                    // device that is connected
                                    const nodeVideoInfo = this.videoInfos_FindByDevice(videoInfos, physicalNode);
                                    if (!isNullOrUndefined(nodeVideoInfo)) {
                                        const nodeVideoInfoLength = nodeVideoInfo.models.length;

                                        for (let nodeVideoInfoI = 0; nodeVideoInfoI < nodeVideoInfoLength; nodeVideoInfoI++) {
                                            const nodeSession = nodeVideoInfo.models[nodeVideoInfoI];

                                            if (VideoViewModel.match(masterSession, nodeSession)) {
                                                nodeVideoViewModel = this.videoViewModel_FindByDeviceAndSession(masterVideo.nodeVideos, physicalNode, nodeSession);

                                                nodeVideoSession = nodeSession as VideoSessionModel;
                                            }
                                        }
                                    }
                                }

                                if (isNullOrUndefined(nodeVideoViewModel)){
                                    if (isNullOrUndefined(physicalNode)){
                                        // If the node isn't currently around
                                        // then we need to create a fake one and we'll need
                                        // a flag in the view model to indicate this is the case
                                        physicalNode = new DeviceModel();
                                        physicalNode.serialNumber = node;

                                        nodeVideoViewModel = new VideoViewModel(physicalNode);
                                        nodeVideoViewModel.devicePresent = false;
                                    }
                                    else{
                                        nodeVideoViewModel = new VideoViewModel(physicalNode);
                                    }

                                    if (!masterVideo.nodeVideos.some(v => v.device.serialNumber === node)){
                                        masterVideo.nodeVideos.push(nodeVideoViewModel);
                                    }
                                }

                                nodeVideoViewModel.schedulePresent = true;

                                if (isNullOrUndefined(nodeVideoSession)){
                                    nodeVideoViewModel.recordingPresent = false;
                                }
                                else{
                                    nodeVideoViewModel.recordingPresent = true;
                                }

                                if (nodeVideoViewModel.isSessionType(sessionType) === false) {
                                    // The nodeVideoViewModel has no VideoViewModel for the sessionType add one.
                                    this.videoViewModel_NewSessionType(nodeVideoViewModel, sessionType, nodeVideoSession, physicalNode);
                                }

                                this.videoViewModel_UpdateSessionType(nodeVideoViewModel, sessionType, nodeVideoSession);
                            }

                            break;
                        case 'schedule':
                            const schedule = masterSession as VideoScheduleModel;
                            const numNodeSchedules = schedule.nodes.length;

                            for (let i = 0; i < numNodeSchedules; i++){
                                const node = schedule.nodes[i];

                                // See if the physical node is present
                                let physicalNode = this._nodes.find(n => n.serialNumber === node);
                                let nodeVideoViewModel = null;
                                let nodeVideoSchedule: VideoScheduleModel = null;

                                if (isNullOrUndefined(physicalNode)){
                                    nodeVideoViewModel = this.videoViewModel_FindByDeviceAndGroupIdentifier(masterVideo.nodeVideos, node, masterSession.groupRecordingIdentifier);

                                    // Blank schedule as we don't know anything about it
                                    nodeVideoSchedule = new VideoScheduleModel();
                                    nodeVideoSchedule.endTime = masterSession.endTime;
                                    nodeVideoSchedule.startTime = masterSession.startTime;
                                }
                                else{
                                    // Need to find the recording info from the
                                    // device that is connected
                                    const nodeVideoInfo = this.videoInfos_FindByDevice(videoInfos, physicalNode);
                                    if (!isNullOrUndefined(nodeVideoInfo)) {
                                        const nodeVideoInfoLength = nodeVideoInfo.models.length;

                                        for (let nodeVideoInfoI = 0; nodeVideoInfoI < nodeVideoInfoLength; nodeVideoInfoI++) {
                                            const nodeSession = nodeVideoInfo.models[nodeVideoInfoI];

                                            if (VideoViewModel.match(masterSession, nodeSession)) {
                                                nodeVideoViewModel = this.videoViewModel_FindByDeviceAndSession(masterVideo.nodeVideos, physicalNode, nodeSession);

                                                nodeVideoSchedule = nodeSession as VideoScheduleModel;
                                            }
                                        }
                                    }
                                }

                                if (isNullOrUndefined(nodeVideoViewModel)){
                                    if (isNullOrUndefined(physicalNode)){
                                        // If the node isn't currently around
                                        // then we need to create a fake one and we'll need
                                        // a flag in the view model to indicate this is the case
                                        physicalNode = new DeviceModel();
                                        physicalNode.serialNumber = node;

                                        nodeVideoViewModel = new VideoViewModel(physicalNode);
                                        nodeVideoViewModel.devicePresent = false;
                                    }
                                    else{
                                        nodeVideoViewModel = new VideoViewModel(physicalNode);
                                    }

                                    if (!masterVideo.nodeVideos.some(v => v.device.serialNumber === node)){
                                        masterVideo.nodeVideos.push(nodeVideoViewModel);
                                    }
                                }

                                if (isNullOrUndefined(nodeVideoSchedule)){
                                    nodeVideoViewModel.schedulePresent = false;
                                }
                                else{
                                    nodeVideoViewModel.schedulePresent = true;
                                }

                                if (nodeVideoViewModel.isSessionType(sessionType) === false) {
                                    // The nodeVideoViewModel has no VideoViewModel for the sessionType add one.
                                    this.videoViewModel_NewSessionType(nodeVideoViewModel, sessionType, nodeVideoSchedule, physicalNode);

                                    if (sessionType === 'schedule' && nodeVideoViewModel.schedulePresent === true) {
                                        this.scheduledVideo_Start_CheckStatus_Watch(nodeVideoViewModel);
                                    }
                                }

                                this.videoViewModel_UpdateSessionType(nodeVideoViewModel, sessionType, nodeVideoSchedule);
                            }

                            break;
                    }
                }

                this.addSubscription(concat(...firstFrameObs).subscribe());
            }

            // Go through all the videos and remove any that don't have videoInfo
            for (let videosI = 0; videosI < videosLength; videosI++) {
                const masterVideo = this._videos[videosI];

                let masterVideoNodesLength = masterVideo.nodeVideos.length;
                if (masterVideoNodesLength > 0) {
                    for (let nodeI = 0; nodeI < masterVideoNodesLength; nodeI++) {
                        const nodeVideo = masterVideo.nodeVideos[nodeI];
                        const nodeVideoInfo = this.videoInfos_FindByDevice(videoInfos, nodeVideo.device);

                        let nodeFound = false;

                        if (!isNullOrUndefined(nodeVideoInfo) && nodeVideo.devicePresent) {
                            const nodeVideoInfoLength = nodeVideoInfo.models.length;
                            for (let nodeVideoInfoI = 0; nodeVideoInfoI < nodeVideoInfoLength; nodeVideoInfoI++) {
                                const nodeSession = nodeVideoInfo.models[nodeVideoInfoI];

                                if (VideoViewModel.match(nodeVideo, nodeSession, nodeVideo.device)) {
                                    nodeFound = true;
                                    break;
                                }
                            }
                        }

                        if (!nodeFound && nodeVideo.devicePresent && (nodeVideo.isOnDevice ? nodeVideo.recordingPresent : true) && (nodeVideo.isSchedule ? nodeVideo.schedulePresent : true)) {
                            this.videoViewModel_DeleteSessionType(nodeVideo, sessionType);

                            if (nodeVideo.isOnDevice === false && nodeVideo.isLocal === false && nodeVideo.isSchedule === false) {
                                // This VideoViewModel is done with it has no SessionTypes left remove it.
                                masterVideo.nodeVideos.splice(nodeI, 1);
                                masterVideoNodesLength = masterVideo.nodeVideos.length;
                                nodeI--;
                            }
                        }
                    }
                }

                let masterFound = false;
                for (let masterVideoInfoI = 0; masterVideoInfoI < masterVideoInfoLength; masterVideoInfoI++) {
                    const masterSession = masterVideoInfo.models[masterVideoInfoI];

                    if (VideoViewModel.match(masterVideo, masterSession, masterVideo.device)) {
                        masterFound = true;
                    }
                }

                if (!masterFound) {
                    this.videoViewModel_DeleteSessionType(masterVideo, sessionType);

                    if (masterVideo.isOnDevice === false && masterVideo.isLocal === false && masterVideo.isSchedule === false) {
                        // This VideoViewModel is done with it has no SessionTypes left remove it.
                        this._videos.splice(videosI, 1);
                        videosLength = this._videos.length;
                        videosI--;
                    }
                }
            }

            this.videosChanged.next({ sessionType });
        }
    }

    private videoViewModel_DeleteSessionType(videoViewModel: VideoViewModel, sessionType: SessionTypes): void {
        if (videoViewModel.isSessionType(sessionType) === true) {
            switch (sessionType) {
                case 'schedule':
                    this.scheduledVideo_Stop_CheckStatus_Watch(videoViewModel);
                    delete videoViewModel.schedule;
                    break;
                case 'onDevice':
                    delete videoViewModel.onDevice;
                    break;
                case 'local':
                    delete videoViewModel.local;
                    if (videoViewModel.isOnDevice === true) {
                        videoViewModel.onDevice.isComplete = false;
                        videoViewModel.onDevice.isPaused = false;
                        videoViewModel.onDevice.isProcessing = false;
                        videoViewModel.onDevice.isQueued = false;
                        videoViewModel.onDevice.isActive = false;
                        videoViewModel.onDevice.progress = 0;
                    }
                    break;
            }
        }
    }

    private videoViewModel_NewSessionType(videoViewModel: VideoViewModel, sessionType: SessionTypes, session: VideoScheduleModel | VideoSessionModel | SyncedVideoSessionModel, device: DeviceModel): void {
        if (videoViewModel.isSessionType(sessionType) === false) {
            switch (sessionType) {
                case 'schedule':
                    videoViewModel.schedule = new ScheduleViewModel(session as VideoScheduleModel, device);
                    break;
                case 'onDevice':
                    videoViewModel.onDevice = new OnDeviceViewModel(session as VideoSessionModel, device);
                    break;
                case 'local':
                    videoViewModel.local = new LocalViewModel(session as SyncedVideoSessionModel, device);
                    break;
            }
        }
    }

    private videoViewModel_UpdateSessionType(videoViewModel: VideoViewModel, sessionType: SessionTypes, session: VideoScheduleModel | VideoSessionModel | SyncedVideoSessionModel): void {
        this._zone.run(() => {
            if (videoViewModel.isSessionType(sessionType) === true) {
                switch (sessionType) {
                    case 'schedule':
                        const schedule = session as VideoScheduleModel;
                        break;
                    case 'onDevice':
                        const onDevice = session as VideoSessionModel;
                        if (videoViewModel.onDevice.isComplete === true){
                            videoViewModel.onDevice.isPaused = false;
                            videoViewModel.onDevice.isProcessing = false;
                            videoViewModel.onDevice.isQueued = false;
                            videoViewModel.onDevice.isActive = false;
                            videoViewModel.onDevice.progress = 0;
                        }
                        else if (videoViewModel.isLocal === false) {
                            videoViewModel.onDevice.isComplete = false;
                            videoViewModel.onDevice.isPaused = false;
                            videoViewModel.onDevice.isProcessing = false;
                            videoViewModel.onDevice.isQueued = false;
                            videoViewModel.onDevice.isActive = false;
                            videoViewModel.onDevice.progress = 0;
                        } else {
                            videoViewModel.onDevice.isComplete = videoViewModel.local.isComplete;
                        }
                        break;
                    case 'local':
                        if (!isNullOrUndefined(session)){
                            const local = session as SyncedVideoSessionModel;
                            videoViewModel.local.isComplete = local.isComplete;
                        }
                        else{
                            videoViewModel.local.isComplete = false;
                        }

                        if (videoViewModel.isOnDevice === true) {
                            videoViewModel.onDevice.isComplete = videoViewModel.local.isComplete;
                        }
                        break;
                }
            }
        });
    }

    private videoViewModel_FindByDeviceAndSessionId(videoViewModels: VideoViewModel[], serialNumber: string, sessionId: number): VideoViewModel {
        if (!isNullOrUndefined(videoViewModels) && !isNullOrUndefined(serialNumber) && !isNullOrUndefined(sessionId)) {
            const length = videoViewModels.length;
            for (let i = 0; i < length; i++) {
                const videoViewModel = videoViewModels[i];
                if (videoViewModel.device.serialNumber === serialNumber) {
                    if ((videoViewModel.isOnDevice === true || videoViewModel.isLocal === true) && (videoViewModel.isOnDevice === false || videoViewModel.onDevice.item.id === sessionId) && (videoViewModel.isLocal === false || videoViewModel.local.item.id === sessionId)) {
                        return videoViewModel;
                    }
                }
                const nodeVideo = this.videoViewModel_FindByDeviceAndSessionId(videoViewModel.nodeVideos, serialNumber, sessionId);
                if (!isNullOrUndefined(nodeVideo)) {
                    return nodeVideo;
                }
            }
        }
    }

    private videoViewModel_FindByDeviceAndSessionStartTime(videoViewModels: VideoViewModel[], serialNumber: string, sessionStart: Date): VideoViewModel {
        if (!isNullOrUndefined(videoViewModels) && !isNullOrUndefined(serialNumber) && !isNullOrUndefined(sessionStart)) {
            const length = videoViewModels.length;
            for (let i = 0; i < length; i++) {
                const videoViewModel = videoViewModels[i];
                if (videoViewModel.device.serialNumber === serialNumber) {
                    if ((videoViewModel.isOnDevice === true || videoViewModel.isLocal === true) && (videoViewModel.isOnDevice === false || videoViewModel.onDevice.item.startTime.valueOf() === sessionStart.valueOf()) && (videoViewModel.isLocal === false || videoViewModel.local.item.startTime.valueOf() === sessionStart.valueOf())) {
                        return videoViewModel;
                    }
                }
                const nodeVideo = this.videoViewModel_FindByDeviceAndSessionStartTime(videoViewModel.nodeVideos, serialNumber, sessionStart);
                if (!isNullOrUndefined(nodeVideo)) {
                    return nodeVideo;
                }
            }
        }
    }

    private videoViewModel_FindByDeviceAndGroupIdentifier(videoViewModels: VideoViewModel[], serialNumber: string, groupId: string): VideoViewModel {
        if (!isNullOrUndefined(videoViewModels) && !isNullOrUndefined(serialNumber) && !isNullOrUndefined(groupId)) {
            const length = videoViewModels.length;
            for (let i = 0; i < length; i++) {
                const videoViewModel = videoViewModels[i];
                if (videoViewModel.device.serialNumber === serialNumber) {
                    if ((videoViewModel.isOnDevice === true && videoViewModel.onDevice?.item?.groupRecordingIdentifier === groupId) || (videoViewModel.isLocal === true && videoViewModel.local?.item?.groupRecordingIdentifier === groupId)) {
                        return videoViewModel;
                    }
                }
                const nodeVideo = this.videoViewModel_FindByDeviceAndGroupIdentifier(videoViewModel.nodeVideos, serialNumber, groupId);
                if (!isNullOrUndefined(nodeVideo)) {
                    return nodeVideo;
                }
            }
        }
    }

    private videoViewModel_FindByDeviceAndSession(videoViewModels: VideoViewModel[], device: DeviceModel, session: VideoScheduleModel | VideoSessionModel | SyncedVideoSessionModel): VideoViewModel {
        if (!isNullOrUndefined(videoViewModels) && videoViewModels.length > 0) {
            return videoViewModels.find((videoViewModel) => VideoViewModel.match(videoViewModel, session, device));
        }
    }

    private videoInfos_FindByDevice<T extends IVideoInfo>(videoInfos: T[], device: DeviceModel): T {
        return isNullOrUndefined(device) || isNullOrUndefined(videoInfos) || videoInfos.length === 0 ? null : videoInfos.find(videoInfo => videoInfo.device.serialNumber === device.serialNumber);
    }

    private refreshVideosData_Schedules(): Observable<boolean> {
        return this.getVideosData_Schedules().pipe(
            map((videoScheduleInfos) => {
                this.load_VideoInfos(videoScheduleInfos, 'schedule');
                return true;
            }),
        );
    }

    private getVideosData_Schedules(): Observable<IVideoScheduleInfo[]> {
        if (!isNullOrUndefined(this._devices) && this._devices.length > 0) {
            return zip(
                ...this._devices.filter(d => d.isCapable(DeviceCapabilitiesEnum.videoRecording)).map((device) => this._recordingService.getVideoSchedules(device.serialNumber).pipe(
                    map((videoScheduleInfo) => ({ models: videoScheduleInfo.schedules, device })),
                )),
            );
        } else {
            return of([]);
        }
    }

    private refreshVideosData_OnDevice(): Observable<boolean> {
        return this.getVideosData_OnDevice().pipe(
            map((videoScheduleInfos) => {
                this.load_VideoInfos(videoScheduleInfos, 'onDevice');
                return true;
            }),
        );
    }

    private getVideosData_OnDevice(): Observable<IVideoSessionInfo[]> {
        if (!isNullOrUndefined(this._devices) && this._devices.length > 0) {
            return zip(
                ...this._devices.filter(d => d.isCapable(DeviceCapabilitiesEnum.videoRecording)).map((device) => this._recordingService.getVideoSessions(device.serialNumber).pipe(
                    map((videoSessionInfo) => ({ models: videoSessionInfo.sessions, device })),
                )),
            );
        } else {
            return of([]);
        }
    }

    private refreshVideosData_Local(): Observable<boolean> {
        return this.getVideosData_Local().pipe(
            map((videoScheduleInfos) => {
                this.load_VideoInfos(videoScheduleInfos, 'local');
                return true;
            }),
        );
    }

    private getVideosData_Local(): Observable<ISyncedVideoSessionInfo[]> {
        if (!isNullOrUndefined(this._devices) && this._devices.length > 0) {
            return zip(
                ...this._devices.filter(d => d.isCapable(DeviceCapabilitiesEnum.videoRecording)).map((device) => this._recordingService.getSyncedVideoSessions(device.serialNumber).pipe(
                    map((syncedVideoSessionInfo) => ({ models: syncedVideoSessionInfo.sessions, device })),
                )),
            );
        } else {
            return of([]);
        }
    }

    private devicesVideoStorageValid(): Observable<boolean> {
        if (!isNullOrUndefined(this._devices) && this._devices.length > 0) {
            return zip(
                ...this._devices.filter(d => d.isCapable(DeviceCapabilitiesEnum.videoRecording)).map((device) => this._recordingService.getVideoStorageCapacity(device.serialNumber).pipe(
                    map((videoStorageCapacity) => videoStorageCapacity.noSDCard === false && videoStorageCapacity.sdCardNotFormatted === false),
                ))
            ).pipe(map((results) => results.every(r => r === true)));
        } else {
            return of(false);
        }
    }

    private getDevices(): Observable<DeviceModel[]> {
        if (isNullOrUndefined(this._devices)) {
            return this._deviceService.getDevices().pipe(
                map((devices) => {
                    const master = devices.items.find(i => i.master === true);
                    if (master.isCapable(DeviceCapabilitiesEnum.videoRecording)) {
                        this._devices = devices.items;
                        this._master = master;
                        this._nodes = this._devices.filter(i => i.master === false);
                        return this._devices;
                    } else {
                        return [];
                    }
                }),
            );
        } else {
            return of(this._devices);
        }
    }

    private resultModelToBoolean(resultModel: ResultModel): boolean {
        if (isNullOrUndefined(resultModel) || isNullOrUndefined(resultModel.error)) {
            return true;
        } else {
            return false;
        }
    }

    private createMasterNodesZip<TResult>(video: VideoViewModel, defaultValue: TResult, command: (videoViewModel: VideoViewModel) => Observable<TResult>): Observable<TResult[]> {
        return zip(...ObservableUtility.zipArrayCheck(this.createMasterNodesSubs(video, command), of(defaultValue)));
    }

    private createMasterNodesSubs<TResult>(video: VideoViewModel, command: (videoViewModel: VideoViewModel) => Observable<TResult>): Observable<TResult>[] {
        const subs: Observable<TResult>[] = [];

        subs.push(command(video));
        const nodeVideosLength = video.nodeVideos.length;
        for (let i = 0; i < nodeVideosLength; i++) {
            subs.push(command(video.nodeVideos[i]));
        }

        return subs;
    }

    private scheduledVideo_Stop_CheckStatus_Watch(video: VideoViewModel): void {
        if (!isNullOrUndefined(video)) {
            const stopWatch = this._scheduledVideo_CheckStatus_Watchs.find(watch => VideoViewModel.match(watch.videoViewModel, video, watch.videoViewModel.device));
            if (!isNullOrUndefined(stopWatch)) {
                stopWatch.sub.unsubscribe();
            }
        }
    }

    private scheduledVideo_Start_CheckStatus_Watch(video: VideoViewModel): void {
        if (!isNullOrUndefined(video) && !isNullOrUndefined(video.startTime)) {
            const now = new Date();
            const valueOfStartTime = video.startTime.valueOf();
            const valueOfNow = now.valueOf();
            const delay: number = valueOfStartTime - valueOfNow > 0 ? valueOfStartTime - valueOfNow : 0;

            let futureTimeOk: boolean = true;

            if (!isNullOrUndefined(video.endTime)) {
                const valueOfEndTime = video.endTime.valueOf();
                futureTimeOk = valueOfNow < (valueOfEndTime + 240000);
            }
            else {
                futureTimeOk = true;
            }

            if (delay < 2147483647 && futureTimeOk) {
                this._checkStatusWatchDelay = this.addSubscription(timer(delay).subscribe(
                    () => {
                        if (this.isRunning){
                            this._scheduledVideo_CheckStatus_Watchs.push({
                                videoViewModel: video,
                                sub: timer(this._scheduledVideo_CheckStatus_Watch_Due, this._scheduledVideo_CheckStatus_Watch_Period).subscribe(() => {
                                    if (this.isRunning){
                                        this.scheduledVideo_CheckStatus(video).subscribe();
                                    }
                                })
                            });
                        }
                    }
                ));
            }
        }
    }

    private scheduledVideo_CheckStatus(video: VideoViewModel): Observable<boolean> {
        if (!isNullOrUndefined(video)) {
            return this._recordingService.getVideoStatus(video.device.serialNumber).pipe(
                flatMap((status) => {
                    if (!isNullOrUndefined(video) && !isNullOrUndefined(video.schedule)){
                        if (video.schedule.isActive === true && status.isRecording === false) {
                            video.schedule.isActive = status.isRecording;
                            video.schedule.progress = status.recordingProgress;

                            this.scheduledVideo_Stop_CheckStatus_Watch(video);

                            if (video.isAnySessionTypeActive('schedule') === false) {
                                return this.refreshVideosData_Schedules().pipe(
                                    flatMap((refreshVideos) => {
                                        if (refreshVideos === true) {
                                            return this.refreshVideosData_OnDevice();
                                        } else {
                                            return of(false);
                                        }
                                    })
                                );
                            } else {
                                return of(true);
                            }
                        } else if (video.schedule.isActive === false && status.isRecording === true) {
                            // Recording just started
                            video.schedule.isActive = status.isRecording;
                            video.schedule.progress = status.recordingProgress;

                            return this.refreshVideosData_Schedules();

                        } else {
                            video.schedule.isActive = status.isRecording;
                            video.schedule.progress = status.recordingProgress;
                        }
                    }

                    return of(true);
                })
            );
        } else {
            return of(false);
        }
    }

    private getSessionFirstFrame(vm: VideoViewModel): Observable<boolean> {
        vm.getFirstFrame = (): Observable<boolean> => {
            const retVal = new Observable<boolean>((sub) => {
                const process = this._processMonitorService.getProcess(RecordingControlService.className, 'Loading video session first frame');
                if (isNullOrUndefined(vm.frameResultLoadingSub) && isNullOrUndefined(vm.imageDataUri)) {
                    vm.frameResultLoadingProcess = process;
                    if (!isNullOrUndefined(vm.local) && !isNullOrUndefined(vm.local.item)) {
                        process.started();
                        vm.frameResultLoadingSub = this.addSubscription(this._recordingService.getSyncedVideoSessionFirstFrame(vm.local.item, this._master.serialNumber, process).subscribe(frameResult => {
                            vm.imageDataUri = frameResult.dataUri;
                            vm.frameResultLoadingSub = null;
                            process.completed();
                            sub.complete();
                        }));
                    } else if (!isNullOrUndefined(vm.onDevice) && !isNullOrUndefined(vm.onDevice.item)) {
                        process.started();
                        vm.frameResultLoadingSub = this.addSubscription(this._recordingService.getVideoSessionFirstFrame(vm.onDevice.item, this._master.serialNumber, process).subscribe(frameResult => {
                            vm.imageDataUri = frameResult.dataUri;
                            vm.frameResultLoadingSub = null;
                            process.completed();
                            sub.complete();
                        }));
                    }
                }
                else{
                    sub.complete();
                }
            });

            return retVal;
        };

        return vm.getFirstFrame();
    }
}
