<div>
    <div id="print-section">
        <div mat-dialog-title>
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column">
                    <div class="header">
                        Session Report
                    </div>
                </div>
                <div fxFlex></div>
            </div>
        </div>
        <div mat-dialog-content fxLayout="column">
            <div class="mat-headline">
                People Counter Network Validation Report
            </div>
            <div class="mat-title">
                Session
                ({{ session.state === ValidationSessionStateEnum.incomplete ? 'Incomplete' : session.state === ValidationSessionStateEnum.rejected ? 'Rejected' : 'Accepted' }})
            </div>
            <div fxLayout="column" class="info-block">
                <div fxLayout="row" class="info-row">
                    <div class="info-title">
                        Serial Number:
                    </div>
                    <div>
                        {{accuracyReport?.serialNumber}}
                    </div>
                </div>
                <div fxLayout="row" class="info-row">
                    <div class="info-title">
                        Date Created:
                    </div>
                    <div>
                        {{accuracyReport?.creationDate}}
                    </div>
                </div>
                <div fxLayout="row" class="info-row">
                    <div class="info-title">
                        Date Recorded:
                    </div>
                    <div>
                        {{accuracyReport?.dateRecorded}}
                    </div>
                </div>
                <div fxLayout="row" class="info-row">
                    <div class="info-title">
                        Duration:
                    </div>
                    <div>
                        {{accuracyReport?.duration}}
                    </div>
                </div>
            </div>
            <div class="mat-title">
                Installation Settings
            </div>
            <div fxLayout="column" class="info-block">
                <div fxLayout="row" class="info-row">
                    <div class="info-title">
                        Site Name:
                    </div>
                    <div>
                        {{accuracyReport?.siteName}}
                    </div>
                </div>
                <div fxLayout="row" class="info-row">
                    <div class="info-title">
                        Site ID:
                    </div>
                    <div>
                        {{accuracyReport?.siteId}}
                    </div>
                </div>
                <div fxLayout="row" class="info-row">
                    <div class="info-title">
                        Date Recorded:
                    </div>
                    <div>
                        {{accuracyReport?.dateRecorded}}
                    </div>
                </div>
                <div fxLayout="row" class="info-row">
                    <div class="info-title">
                        Time Zone:
                    </div>
                    <div>
                        {{accuracyReport?.timeZone}}
                    </div>
                </div>
            </div>
            <div class="mat-title">
                Line Accuracy
            </div>
            <div fxLayout="column" class="info-block accuracy">
                <ng-template [ngIf]="!isNullOrUndefined(accuracyReport)">
                    <table>
                        <tr>
                            <td>Name</td>
                            <td>Mode</td>
                            <td>System Count</td>
                            <td>User Count</td>
                            <td>Accuracy</td>
                        </tr>
                        <tr *ngFor="let line of accuracyReport.lines">
                            <td>
                                {{ line.name }}
                            </td>
                            <td>
                                {{ line.mode }}
                            </td>
                            <td>
                                {{ line.systemCount }}
                            </td>
                            <td>
                                {{ line.userCount }}
                            </td>
                            <td>
                                {{ line.accuracy }}&#37;
                            </td>
                        </tr>
                    </table>
                </ng-template>
            </div>
            <div class="mat-title">
                Line Aggregation
            </div>
            <div fxLayout="column" class="info-block aggregation">
                <ng-template [ngIf]="!isNullOrUndefined(aggregationReport)">
                    <table>
                        <tr>
                            <td>Time</td>
                            <td *ngFor="let line of aggregationReport.lines">
                                System {{ line.name }}
                            </td>
                            <td *ngFor="let line of aggregationReport.lines">
                                User {{ line.name }}
                            </td>
                        </tr>
                        <tr *ngFor="let bucket of aggregationReport.buckets">
                            <td style="text-align: left">
                                {{ formatStart(bucket.start.offset) }}
                            </td>
                            <td *ngFor="let line of aggregationReport.lines">
                                {{ line.buckets[bucket.index].systemCount }}
                            </td>
                            <td *ngFor="let line of aggregationReport.lines">
                                {{ line.buckets[bucket.index].userCount }}
                            </td>
                        </tr>
                    </table>
                </ng-template>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button (click)="close()">Close</button>
        <div fxFlex></div>
        <button mat-raised-button printSectionId="print-section" shrPrint>Print</button>
    </div>
</div>
