import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RIFTUserIsRIFTRole implements CanActivate {
    public constructor(
        private readonly _userCurrentService: UserCurrentService,
        private readonly _router: Router) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._userCurrentService.isUser.pipe(
            map(result => {
                if (result === true) {
                    return true;
                } else {
                    return this._router.createUrlTree(['/']);
                }
            })
        );
    }
}
