import { NgZone } from '@angular/core';
import { JointBase } from '@rift/components/shared/viewport/base/JointBase';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { DisplayItemCollection } from '@shared/generic/canvas/DisplayItemCollection';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { SegmentBase } from '@rift/components/shared/viewport/base/SegmentBase';
import { PointModel } from '@rift/models/restapi/Point.Model';

export class JointCollectionBase extends DisplayItemCollection<JointBase> {

    public constructor(
        protected readonly _zone: NgZone,
        protected readonly _loadQueueBase: ViewPortLoadQueueService,
        ...jointsBase: Array<JointBase>) {
        super(_zone);
        Object.setPrototypeOf(this, Object.create(JointCollectionBase.prototype));
    }

    public bringToFront(displayItem: JointBase): void {
        this._zone.runOutsideAngular(() => {
            this.container.setChildIndex(displayItem.container, this.container.children.length - 1);
            this.requireStageUpdate.next();
        });
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
        });
    }

    public push(...joints: Array<JointBase>): number {
        return this._zone.runOutsideAngular(() => {
            const length = joints.length;
            for (let index = 0; index < length; index++) {
                const joint = joints[index];
                this.initItem(joint);
                joint.update();
                super.push(joint);
            }

            return this.length;
        });
    }

    public remove(joint: JointBase): void {
        this._zone.runOutsideAngular(() => {
            const index = this.findIndex(i => i.uniqueId === joint.uniqueId);
            if (index !== -1) {
                this.splice(index, 1);
            }
        });
    }

    public splice(start: number, deleteCount: number, ...joints: Array<JointBase>): Array<JointBase> {
        return this._zone.runOutsideAngular(() => {
            if (joints.length > 0) {
                throw new Error('Splice insert in not supported on JointCollection');
            }

            const removed: Array<JointBase> = [];

            for (let i = start; i < start + deleteCount; i++) {
                const remove = this[i];
                if(!isNullOrUndefined(remove)){
                    this.container.removeChild(remove.container);
                }

                super.splice(i, 1);
                removed.push(remove);
            }

            return removed;
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            const length = this.length;
            for (let index = 0; index < length; index++) {
                this[index].update();
            }
        });
    }
}
