import { Injectable } from '@angular/core';
import { MetricsModel } from '@em/models/restapi/Metrics.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiHomeService } from '@em/service/restapi/RestApi.Home.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';


@Injectable()
export class HomeService extends EmBaseService {
    private _getMetricsCache: MetricsModel;

    private _getMetricsLoadingTracker = new ObservableTracker<MetricsModel>();

    public constructor(
        private readonly _restApiHomeService: RestApiHomeService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getMetricsCache = null;
    }
    public getMetrics(process?: ProcessMonitorServiceProcess): Observable<MetricsModel> {
        if (isNullOrUndefined(this._getMetricsCache)) {
            return this._getMetricsLoadingTracker
                .getLoading()
                .observable(this._restApiHomeService.getMetrics(process).pipe(
                    map(result => {
                        this._getMetricsCache = result;
                        return this._getMetricsCache;
                    })
                ));
        } else {
            return of(this._getMetricsCache);
        }
    }
}
