import { NgZone } from '@angular/core';
import { ViewPortModeEnum } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { UniqueIdUtility } from '@shared/utility/UniqueId.Utility';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export abstract class DisplayItemCollection<T extends IDisplayItem> extends Array<T> implements IDisplayItem {
    public requireStageUpdate: Subject<void> = new Subject<void>();

    protected minIndex: number = Number.MAX_VALUE;
    protected maxIndex: number = Number.MIN_VALUE;

    private _container: createjs.Container = new createjs.Container();
    private _isMouseDown: boolean = false;
    private _isMouseOver: boolean = false;
    private _mode: ViewPortModeEnum = null;
    private _uniqueId: number = null;
    private _unitOfMeasurement: UnitsOfMeasurementEnum = UnitsOfMeasurementEnum.metric;

    protected constructor(protected readonly _zone: NgZone) {
        super();
        Object.setPrototypeOf(this, Object.create(DisplayItemCollection.prototype));
        this._uniqueId = UniqueIdUtility.nextId;
    }

    public abstract bringToFront(displayItem: T): void;

    public abstract update(): void;

    public clear(): void {
        this._zone.runOutsideAngular(() => {
            this.splice(0, this.length);
        });
    }

    public get container(): createjs.Container {
        return this._zone.runOutsideAngular(() => this._container);
    }

    public get isMouseDown(): boolean {
        return this._zone.runOutsideAngular(() => this._isMouseDown);
    }
    public set isMouseDown(value: boolean) {
        this._zone.runOutsideAngular(() => {
            if (this._isMouseDown !== value) {
                this._isMouseDown = value;
                this.isMouseDownSet();
            }
        });
    }

    public get isMouseOver(): boolean {
        return this._zone.runOutsideAngular(() => this._isMouseOver);
    }
    public set isMouseOver(value: boolean) {
        this._zone.runOutsideAngular(() => {
            if (this._isMouseOver !== value) {
                this._isMouseOver = value;
                this.isMouseOverSet();
            }
        });
    }

    public get mode(): ViewPortModeEnum {
        return this._zone.runOutsideAngular(() => this._mode);
    }



    public set mode(value: ViewPortModeEnum) {
        this._zone.runOutsideAngular(() => {
            if (this._mode !== value) {
                this._mode = value;
                this.modeSet();
            }
        });
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            this.splice(0, this.length);
        });
    }

    public splice(start: number, deleteCount?: number, ...items: Array<T>): Array<T> {
        return this._zone.runOutsideAngular(() => {
            const removedItems = super.splice(start, deleteCount, ...items);

            if (removedItems.length > 0) {
                const removedItemsLength = removedItems.length;
                for (let index = 0; index < removedItemsLength; index++) {
                    this.destroyItem(removedItems[index]);
                }
            }

            return removedItems;
        });
    }

    public get uniqueId(): number {
        return this._zone.runOutsideAngular(() => this._uniqueId);
    }

    public get unitOfMeasurement(): UnitsOfMeasurementEnum {
        return this._zone.runOutsideAngular(() => this._unitOfMeasurement);
    }

    public set unitOfMeasurement(value: UnitsOfMeasurementEnum) {
        this._zone.runOutsideAngular(() => {
            if (this._unitOfMeasurement !== value) {
                this._unitOfMeasurement = value;
                this.unitOfMeasurementSet();
            }
        });
    }
    public get visible(): boolean {
        return this.container.visible;
    }

    public set visible(value: boolean) {
        this._zone.runOutsideAngular(() => {
            if (this.container.visible !== value) {
                this.container.visible = value;
            }
        });
    }

    protected setIndexRanges(index: number): void {
        if (this.minIndex > index) {
            this.minIndex = index;
        }
        if (this.maxIndex < index) {
            this.maxIndex = index;
        }
    }

    protected addEventHandlers(item: T): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(item)) {
                item.requireStageUpdate.subscribe(() => this.requireStageUpdate.next());
            }
        });
    }

    protected destroyItem(displayItem: T): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(displayItem)) {
                this.container.removeChild(displayItem.container);
                this.removeEventHandlers(displayItem);
                displayItem.onDestroy();
            }
        });
    }

    protected initItem(item: T): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(item)) {
                item.mode = this.mode;
                item.unitOfMeasurement = this.unitOfMeasurement;
                this.addEventHandlers(item);
                this.container.addChild(item.container);
            }
        });
    }

    protected isMouseDownSet(): void {
    }

    protected isMouseOverSet(): void {
    }

    protected modeSet(): void {
        this._zone.runOutsideAngular(() => {
            const length = this.length;
            for (let index = 0; index < length; index++) {
                this[index].mode = this.mode;
            }
        });
    }

    protected removeEventHandlers(item: T): void {
    }

    protected unitOfMeasurementSet(): void {
        this._zone.runOutsideAngular(() => {
            const length = this.length;
            for (let index = 0; index < length; index++) {
                const item = this[index];
                if (!isNullOrUndefined(item)) {
                    item.unitOfMeasurement = this.unitOfMeasurement;
                }
            }
        });
    }
}
