import { Component, HostBinding, HostListener, Inject, Injector } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { IListDevice } from '@rift/components/settings/widetracker/Settings.WideTracker.IListDevice';
import { ClientConnectionModel } from '@rift/models/restapi/ClientConnection.Model';
import { ClientConnectionCollectionModel } from '@rift/models/restapi/ClientConnectionCollection.Model';
import { TestConnectionDataModel } from '@rift/models/restapi/TestConnectionData.Model';
import { OutboundConnectionsService } from '@rift/service/data/outboundconnections/OutboundConnections.Service';
import { WideTrackerService } from '@rift/service/data/widetracker/WideTracker.Service';
import { OkCancelDialogComponent, OkCancelDialogData } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

@Component({
    selector: 'rift-settings-wide-tracker-convert-device',
    templateUrl: './Settings.WideTracker.ConvertDevice.Component.html',
    styleUrls: ['./Settings.WideTracker.ConvertDevice.Component.scss']
})
export class SettingsWideTrackerConvertDeviceComponent extends RiftBaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsWideTrackerConvertDeviceComponent';

    @HostBinding()
    public id: string = 'rift-settings-wide-tracker-convert-device';

    public useMasterDetails: boolean = true;
    public clientConnections: ClientConnectionCollectionModel;
    public clientConnectionsFormGroup: FormGroup;
    public testConnectionProcess: ProcessMonitorServiceProcess;
    public addClientConnectionProcess: ProcessMonitorServiceProcess;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;
    public testError: string = null;

    public constructor(
        private readonly _outboundConnectionsService: OutboundConnectionsService,
        private readonly _wideTrackerService: WideTrackerService,
        private readonly _dialogRef: MatDialogRef<SettingsWideTrackerConvertDeviceComponent>,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private readonly _data: IListDevice,
        _navBarService: NavBarActionService,
        _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this._dialogRef.disableClose = true;

        this.addClientConnectionProcess = this.processMonitorService.getProcess(SettingsWideTrackerConvertDeviceComponent.className, 'Add client connection');
        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsWideTrackerConvertDeviceComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsWideTrackerConvertDeviceComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsWideTrackerConvertDeviceComponent.className, this.saveAllChangesProcessText);
        this.testConnectionProcess = this.processMonitorService.getProcess(SettingsWideTrackerConvertDeviceComponent.className, 'Testing outbound connection');

        this.clientConnectionsFormGroup = this._formBuilder.group({
            connections: this._formBuilder.array([]),
        });
        this.formGroupTracker.track(this.clientConnectionsFormGroup);

        this.initConnectionState();
    }

    public onTestConnectionClick(clientConnection: ClientConnectionModel): void {
        this.testError = null;
        const data = new TestConnectionDataModel();
        data.address = clientConnection.address;
        data.port = clientConnection.port;
        this.addSubscription(this.observableHandlerBase(this.deviceService.testConnection(this._data.serial, data), this.testConnectionProcess).subscribe(
            result => {
                if (!this.isNullOrUndefined(result) && this.isNullOrUndefined(result.error)) {
                    const dialogData = new OkCancelDialogData(`Outbound Connection Test (${clientConnection.address}:${clientConnection.port})`, null, false);
                    dialogData.messageHtml = result.details.map(detail => `<span>${detail}</span><br>`).join('');

                    this._dialog.open(OkCancelDialogComponent, { data: dialogData });
                } else {
                    this.testError = result.error;
                }
            }
        ), this.testConnectionProcess);
    }

    public loadData(pleaseWaitDialogRequest?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._outboundConnectionsService.getClientConnections(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        const connectionsFormArray = this.clientConnectionsFormGroup.controls.connections as FormArray;
                        const itemsLength = result.items.length;

                        if (connectionsFormArray.length === 0) {
                            for (let index = 0; index < itemsLength; index++) {
                                this.addClientConnectionFormGroup(result.items[index]);
                            }
                        } else {
                            for (let index = 0; index < itemsLength; index++) {
                                this.addClientConnectionFormGroupSubs(result.items[index], connectionsFormArray.at(index) as FormGroup);
                            }
                        }

                        this.clientConnections = result;
                        this.changeTracker.track(this.clientConnections);
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRequest, process);
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public get hasChanges(): boolean {
        return true;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => this.loadData(this.openPleaseWaitLoadingDialog()));
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            this._wideTrackerService.setAsMaster(this._data.serial, process, this.clientConnections).pipe(
                map(() => true)
            )
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (result === true) {
                    this._dialogRef.close(true);
                }

                this._wideTrackerService.clearCache();

                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(false);
                }
            })
        );
    }

    public close(): void {
        this._dialogRef.close(false);
    }

    public convert(): void {
        this.saveAllChangesStartBase(this, this.openPleaseWaitSavingDialog());
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.clientConnectionsFormGroup.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.clientConnectionsFormGroup.enable();
        const fArray = this.clientConnectionsFormGroup.controls.connections as FormArray;
        const controlsLength = fArray.controls.length;
        for (let index = 0; index < controlsLength; index++) {
            const formGroup = fArray.controls[index] as FormGroup;
            if (formGroup.controls.enabled.value === true) {
                formGroup.enable();
            } else {
                formGroup.controls.enabled.enable();
            }
        }
    }

    private addClientConnectionFormGroup(clientConnection: ClientConnectionModel, formArray?: FormArray): void {
        const fArray = this.isNullOrUndefined(formArray) ? this.clientConnectionsFormGroup.controls.connections as FormArray : formArray;
        if (!this.isNullOrUndefined(fArray)) {
            const form = this.createClientConnectionFormGroup(clientConnection);
            this.setClientConnectionFormGroupValues(clientConnection, form);
            fArray.push(form);
        }
    }

    private addClientConnectionFormGroupSubs(clientConnection: ClientConnectionModel, formGroup: FormGroup): void {
        this.addSubscription(this.observableHandlerBase(formGroup.controls.enabled.valueChanges, this.addClientConnectionProcess).subscribe(() => {
            if (this.isReadOnly === false) {
                if (formGroup.controls.enabled.value === true) {
                    formGroup.controls.address.enable();
                    formGroup.controls.port.enable();
                    formGroup.controls.reconnectionInterval.enable();
                } else {
                    formGroup.controls.address.disable();
                    formGroup.controls.port.disable();
                    formGroup.controls.reconnectionInterval.disable();

                    clientConnection.clearChanges();

                    formGroup.controls.address.setValue(clientConnection.address, { emitEvent: false });
                    formGroup.controls.port.setValue(clientConnection.port, { emitEvent: false });
                    formGroup.controls.reconnectionInterval.setValue(clientConnection.reconnectionInterval, { emitEvent: false });
                }
            }
        }), this.addClientConnectionProcess);

        this.addSubscription(this.observableHandlerBase(formGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            this.updateModelValuesClientConnectionFormGroup(formGroup, clientConnection);
        }), this.formValuesChangeProcess);
    }

    private createClientConnectionFormGroup(clientConnection: ClientConnectionModel): FormGroup {
        const formGroup = this._formBuilder.group({
            enabled: ['', Validators.compose([])],
            address: ['', Validators.compose([Validators.required, ValidationValidators.ipAddressOrHostName])],
            port: ['', Validators.compose([Validators.required, ValidationValidators.port])],
            reconnectionInterval: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(3600), Validators.pattern('[0-9]*')])],
        });

        if (this.isReadOnly === true) {
            formGroup.disable();
        }

        this.addClientConnectionFormGroupSubs(clientConnection, formGroup);

        return formGroup;
    }

    private setClientConnectionFormGroupValues(clientConnection: ClientConnectionModel, formGroup: FormGroup): void {
        if (!this.isNullOrUndefined(clientConnection)) {
            formGroup.setValue({
                enabled: clientConnection.enabled,
                address: clientConnection.address,
                port: clientConnection.port,
                reconnectionInterval: clientConnection.reconnectionInterval,
            });
        }
    }

    private updateModelValuesClientConnectionFormGroup(formGroup: FormGroup, clientConnection: ClientConnectionModel): void {
        if (!this.isNullOrUndefined(clientConnection) && this.isReadOnly === false) {
            const formValues = formGroup.value;

            clientConnection.enabled = formValues.enabled;
            if (formValues.enabled === true) {
                clientConnection.address = formValues.address;
                clientConnection.port = formValues.port;
                clientConnection.reconnectionInterval = formValues.reconnectionInterval;
            }
        }
    }
}
