function HullFilter(videoDevice) {
    this.Filter_constructor();

    this._videoDevice = videoDevice;

    this.usesContext = true;

    this.FRAG_SHADER_BODY = (
        "uniform sampler2D uAlphaSampler;" +
        "void main(void) {" +
        "   vec4 color = texture2D(uSampler, vRenderCoord);" +
        "   vec4 alphaMap = texture2D(uAlphaSampler, vTextureCoord);" +
        "   gl_FragColor = vec4(color.rgb, color.a * alphaMap.a);" +
        "}"
    );

    this.mask = new createjs.Shape();
    this.mask.graphics.clear();
    this.mask.graphics.beginFill('#ff0000');

    const scaleX = this._videoDevice.videoSettings.flattenedWidth / this._videoDevice.videoSettings.requiredWidth;
    const scaleY = this._videoDevice.videoSettings.flattenedHeight / this._videoDevice.videoSettings.requiredHeight;

    const offsetX = (this._videoDevice.videoSettings.requiredWidth * scaleX / 2) + (this._videoDevice.videoSettings.coefficients.ox * 100 * (this._videoDevice.videoSettings.coefficients.pixelPitch * 100)) - (this._videoDevice.deviceModel.x * scaleX);
    const offsetY = (this._videoDevice.videoSettings.requiredHeight * scaleY / 2) + (this._videoDevice.videoSettings.coefficients.oy * 100 * (this._videoDevice.videoSettings.coefficients.pixelPitch * 100)) - (this._videoDevice.deviceModel.y * -1 * scaleY);

    this.mask.graphics.moveTo((this._videoDevice.videoSettings.hullPoints[0].x * scaleX) + offsetX, (this._videoDevice.videoSettings.hullPoints[0].y * -1 * scaleY) + offsetY);
    var length = this._videoDevice.videoSettings.hullPoints.length;
    for (var i = 1; i < length; i++) {
        this.mask.graphics.lineTo((this._videoDevice.videoSettings.hullPoints[i].x * scaleX) + offsetX, (this._videoDevice.videoSettings.hullPoints[i].y * -1 * scaleY) + offsetY);
    }
    this.mask.graphics.closePath();

    this.mask.cache(
        0,
        0,
        this._videoDevice.videoSettings.requiredWidth,
        this._videoDevice.videoSettings.requiredHeight,
        1,
    );
}
var p = HullFilter.prototype = Object.create(createjs.Filter.prototype);
p.constructor = HullFilter;

p.toString = function () {
    return "[AlphaMaskFilter]";
};

p.clone = function () {
    return new HullFilter(this._videoDevice);
};

p.shaderParamSetup = function (gl, stage, shaderProgram) {
    if (!this._mapTexture) {
        this._mapTexture = gl.createTexture();
    }

    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, this._mapTexture);
    stage.setTextureParams(gl);
    try {
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, this.mask.cacheCanvas);
    } catch (err) { }

    gl.uniform1i(gl.getUniformLocation(shaderProgram, "uAlphaSampler"), 1);
};

p.applyFilter = function (ctx, x, y, width, height, targetCtx, targetX, targetY) {
    if (!this.mask) {
        return true;
    }
    targetCtx = targetCtx || ctx;
    if (targetX == null) {
        targetX = x;
    }
    if (targetY == null) {
        targetY = y;
    }

    targetCtx.save();
    if (ctx != targetCtx) {
        return false;
    }

    if (this.mask instanceof HTMLImageElement) {
        targetCtx.globalCompositeOperation = "destination-in";
        targetCtx.drawImage(this.mask, targetX, targetY);
        targetCtx.restore();
    }

    return true;
};

p.destroy = function () {
    if (this.mask) {
        this.mask.uncache();
    }
};

var hullFilter = createjs.promote(HullFilter, "Filter");

module.exports.HullFilter = hullFilter;
