import { animate, AnimationTriggerMetadata, keyframes, state, style, transition, trigger, AnimationEvent } from '@angular/animations';
import { ChangeDetectorRef, Component, Inject, InjectionToken } from '@angular/core';

export const DEVICE_INFO = new InjectionToken<{}>('DEVICE_INFO');

export type DeviceInfoTooltipVisibility = 'initial' | 'visible' | 'hidden';

export interface DeviceInfo {
    deviceName?: string;
    deviceId?: string;
    siteId?: string;
    siteName?: string;
    firmwareVersion?: string;
    iPAddress?: string;
    nodeCount?: number;
    friendlySerial: string;
}

const deviceInfoTooltipAnimations: {
    readonly tooltipState: AnimationTriggerMetadata;
} = {
    /** Animation that transitions a tooltip in and out. */
    tooltipState: trigger('state', [
        state('initial, void, hidden', style({ opacity: 0, transform: 'scale(0)' })),
        state('visible', style({ transform: 'scale(1)' })),
        transition('* => visible', animate('200ms cubic-bezier(0, 0, 0.2, 1)', keyframes([
            style({ opacity: 0, transform: 'scale(0)', offset: 0 }),
            style({ opacity: 0.5, transform: 'scale(0.99)', offset: 0.5 }),
            style({ opacity: 1, transform: 'scale(1)', offset: 1 })
        ]))),
        transition('* => hidden', animate('100ms cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 0 }))),
    ])
};

@Component({
    selector: 'shr-device-info-tooltip',
    templateUrl: './DeviceInfoTooltip.Component.html',
    styleUrls: ['./DeviceInfoTooltip.Component.scss'],
    animations: [deviceInfoTooltipAnimations.tooltipState],
    host: {
        '[style.zoom]': '_visibility === "visible" ? 1 : null',
        '(body:click)': 'this.handleBodyInteraction()',
        'aria-hidden': 'true',
    }
})
export class DeviceInfoTooltipComponent {

    public visibility: DeviceInfoTooltipVisibility = 'initial';

    /** The timeout ID of any current timer set to show the tooltip */
    _showTimeoutId: any;

    /** The timeout ID of any current timer set to hide the tooltip */
    _hideTimeoutId: any;

    /** Whether interactions on the page should close the tooltip */
    private _closeOnInteraction: boolean = false;

    public constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef,
        @Inject(DEVICE_INFO) public readonly deviceInfo: DeviceInfo) {
    }

    public handleBodyInteraction(): void {
        if (this._closeOnInteraction) {
            this.hide(0);
        }
    }

    public animationStart(): void {
        this._closeOnInteraction = false;
    }

    public animationDone(event: AnimationEvent): void {
        const toState = event.toState as DeviceInfoTooltipVisibility;

        if (toState === 'visible' || toState === 'hidden') {
            this._closeOnInteraction = true;
        }
    }

    public isVisible(): boolean {
        return this.visibility === 'visible';
    }

    public show(delay: number): void {
        // Cancel the delayed hide if it is scheduled
        if (this._hideTimeoutId) {
            clearTimeout(this._hideTimeoutId);
            this._hideTimeoutId = null;
        }

        // Body interactions should cancel the tooltip if there is a delay in showing.
        this._closeOnInteraction = true;
        this._showTimeoutId = setTimeout(() => {
            this.visibility = 'visible';
            this._showTimeoutId = null;

            // Mark for check so if any parent component has set the
            // ChangeDetectionStrategy to OnPush it will be checked anyway
            this.markForCheck();
        }, delay);
    }

    public hide(delay: number): void {
        // Cancel the delayed show if it is scheduled
        if (this._showTimeoutId) {
            clearTimeout(this._showTimeoutId);
            this._showTimeoutId = null;
        }

        this._hideTimeoutId = setTimeout(() => {
            this.visibility = 'hidden';
            this._hideTimeoutId = null;

            // Mark for check so if any parent component has set the
            // ChangeDetectionStrategy to OnPush it will be checked anyway
            this.markForCheck();
        }, delay);
    }

    private markForCheck(): void {
        this._changeDetectorRef.markForCheck();
    }
}
