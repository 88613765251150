/**
 * Helper methods for JSON.
 *
 * @export
 * @class JsonUtility
 */
export class JsonUtility {

    /**
     * Converts JSON to pretty HTML.
     *
     * @static
     * @param {*} obj The object to convert.
     * @returns HTML representation of the JSON.
     * @memberof JsonUtility
     */
    public static prettyPrint(obj: any) {
        const jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
        return JSON.stringify(obj, null, 3)
            .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
            .replace(/</g, '&lt;').replace(/>/g, '&gt;')
            .replace(jsonLine, JsonUtility.replace);
    }

    /**
     * Replaces JSON tags with HTML elements.
     *
     * @static
     * @memberof JsonUtility
     */
    public static replace(match: any, pIndent: any, pKey: any, pVal: any, pEnd: any): any {
        const key = '<span class=json-key>';
        const val = '<span class=json-value>';
        const str = '<span class=json-string>';
        let r = pIndent || '';
        if (pKey) {
            r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
        }
        if (pVal) {
            r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>';
        }
        return r + (pEnd || '');
    }
}
