<div class="counting-setting-content">
    <div fxLayout="row">
        <div fxFlex="60" class="spacing-right" fxLayout="column">
            <mat-form-field>
                <mat-select #deviceSelect (selectionChange)="deviceSelected($event)" placeholder="Devices" [(ngModel)] = "selectedDevice">
                    <mat-option *ngFor="let device of devices" [value]="device">
                        {{device}}
                    </mat-option>
                </mat-select>
                <div *ngIf="loading === true">
                    (Loading...)
                </div>
            </mat-form-field>
        </div>        
    </div>
</div>