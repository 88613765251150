<div class="em-myaccount-restapikey-title"
    mat-dialog-title>
    Reset API Key
</div>
<div class="em-myaccount-restapikey-content"
    mat-dialog-content>
    <span *ngIf="resetComplete === false">Once reset, any programs or tools that use your current API key will no longer work.</span>
    <span *ngIf="resetComplete === true">Your API key has successfully been reset.</span>
</div>
<div class="em-myaccount-restapikey-actions"
    mat-dialog-actions>
    <button
        *ngIf="resetComplete === false"
        [disabled]="resetApiKeyProcess.isRunning === true"
        mat-raised-button
        color="primary"
        (click)="cancel()">Cancel</button>
    <div fxFlex></div>
    <shr-progress-button-spinner
        *ngIf="resetComplete === false"
        [raised]="true"
        [diameter]="30"
        text="Reset"
        mode="indeterminate"
        [active]="resetApiKeyProcess.isRunning === true"
        (shrClick)="reset()"
        color="warn"></shr-progress-button-spinner>
    <button
        *ngIf="resetComplete === true"
        mat-raised-button
        color="warn"
        (click)="cancel()">Close</button>
</div>