<shr-table [isLoadingData]="loadDataProcess.isRunning"
    matSort
    #sort="matSort"
    [sort]="sort"
    title="Connection Forwarding"
    (filter)="applyFilter($event)"
    [dataSource]="dataSource"
    [displayedColumns]="displayedColumns">
    <ng-template #shrTableActions
        let-rule>
        <button matTooltip="Edit"
            mat-icon-button
            (click)="edit(rule)">
            <mat-icon>edit</mat-icon>
        </button>
        <button matTooltip="Delete"
            mat-icon-button
            (click)="delete(rule)"
            color="warn">
            <mat-icon>delete</mat-icon>
        </button>
    </ng-template>

    <ng-container matColumnDef="operateOn">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> Operate On </th>
        <td mat-cell
            *matCellDef="let rule"
            style="width: 170px;">
            <ng-template [ngIf]="rule.isGlobalRule === true">
                <span>All Devices (Global)</span>
            </ng-template>
            <ng-template [ngIf]="rule.isGroupRule === true">
                Group:{{getGroupName(rule.groupId)}} {{ rule.propertyHasChangesText('groupId') }}
            </ng-template>
            <ng-template [ngIf]="rule.isDeviceRule === true">
                Device:
                <em-device-serial-link [serial]="rule.friendlySerialNumber"></em-device-serial-link>
                {{ rule.propertyHasChangesText('friendlySerialNumber') }}
            </ng-template>
        </td>
    </ng-container>

    <ng-container matColumnDef="destinations">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> Forward Destinations </th>
        <td mat-cell
            *matCellDef="let rule"
            style="width: 30%;">
            <div class="destinations"
                fxLayout="column">
                <ng-template ngFor
                    let-destination
                    [ngForOf]="rule.destinations"
                    let-i="index">
                    <div class="subrow"
                        fxLayout="row">
                        <span matTooltip="{{destination.iPAddress}}:{{destination.port}}">
                            {{destination.iPAddress}}:{{destination.port}} {{
                                                            destination.propertyHasChanges('iPAddress') ||
                                                            destination.propertyHasChanges('port') ? '*' : '' }}</span>

                    </div>
                </ng-template>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="retryPeriod">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> Retry Period (s)</th>
        <td mat-cell
            *matCellDef="let rule"
            style="width: 30%;">
            <span matTooltip="{{rule.retryPeriod}}">{{rule.retryPeriod}}
                {{ rule.propertyHasChangesText('retryPeriod') }}</span>
        </td>
    </ng-container>
</shr-table>