import { Injector, Directive, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SettingsCountingBaseComponent } from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { HistogramConfigModel } from '@rift/models/restapi/HistogramConfig.Model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';

@Directive()
export abstract class SettingsCountingHistogramBaseComponent extends SettingsCountingBaseComponent {
    public static className: string = 'SettingsCountingHistogramBaseComponent';

    public form: FormGroup;
    public enabled: boolean;

    protected _hadHistogramConfig: boolean;
    protected _histogramConfig: HistogramConfigModel;

    @Input()
    public register: RegisterBaseModel = null;

    protected constructor(
        private readonly _settingsHistBaseInjector: Injector) {
        super(_settingsHistBaseInjector);
    }

    public abstract getSettingsDescription(): string;

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this._histogramConfig) ? false : this.enabled !== this._hadHistogramConfig || (this.enabled === true && (this._histogramConfig.propertyHasChanges('binWidth') || this._histogramConfig.propertyHasChanges('numberOfBins') || this._histogramConfig.propertyHasChanges('minimumValue')));
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public onEnabledChange(event: MatCheckboxChange): void {
        this.enabled = event.checked;
        if (!this.isNullOrUndefined(this.register) && !this.isNullOrUndefined(this._histogramConfig)) {
            if (this.enabled) {
                this.register.histogramConfigs.push(this._histogramConfig);
            } else {
                const index = this.register.histogramConfigs.findIndex(i => i.uniqueId === this._histogramConfig.uniqueId);
                if (index !== -1) {
                    this.register.histogramConfigs.splice(index, 1);
                }
            }
            this.register.setFlags();
        }
        this.setFormState();
    }

    protected setFormState(): void {
        if (this.enabled) {
            this.form.enable({ emitEvent: false });
        } else {
            this.form.disable({ emitEvent: false });
        }
    }

    protected setFormValues(binWidthDefault: number, numberOfBinsDefault: number, minimumValueDefault: number): void {
        this.form.controls.binWidth.setValue(this.isNullOrUndefined(this._histogramConfig.binWidth) ? binWidthDefault : this._histogramConfig.binWidth, { emitEvent: false });
        this.form.controls.numberOfBins.setValue(this.isNullOrUndefined(this._histogramConfig.numberOfBins) ? numberOfBinsDefault : this._histogramConfig.numberOfBins, { emitEvent: false });
        this.form.controls.minimumValue.setValue(this.isNullOrUndefined(this._histogramConfig.minimumValue) ? minimumValueDefault : this._histogramConfig.minimumValue, { emitEvent: false });
    }
}
