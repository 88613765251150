import { REGISTER_BASE_EXCLUDE_PROPERTIES, RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterTailgateModel extends IRegisterBaseModel {
    accessEventRegisterIndex: number;
    entryCountingRegisterIndex: number;
    timeout: number;
    instanceIndex: number;
}

/**
 * Represents a
 *
 * @export
 * @class RegisterTailgateModel
 * @extends {RegisterBaseModel}
 * @implements {IRegisterTailgateModel}
 * @implements {IRestModel}
 */
export class RegisterTailgateModel extends RegisterBaseModel implements IRegisterTailgateModel, IRestModel {

    public accessEventRegisterIndex: number = null;
    public entryCountingRegisterIndex: number = null;
    public timeout: number = null;
    public instanceIndex: number = null;

    public constructor() {
        super();

        this.registerProperty('accessEventRegisterIndex');
        this.registerProperty('entryCountingRegisterIndex');
        this.registerProperty('timeout');
        this.registerProperty('instanceIndex');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterTailgateModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('accessEventRegisterIndex', this.accessEventRegisterIndex);
        this.setPropertyOriginalValue('entryCountingRegisterIndex', this.entryCountingRegisterIndex);
        this.setPropertyOriginalValue('timeout', this.timeout);
        this.setPropertyOriginalValue('instanceIndex', this.instanceIndex);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
        };
    }

    public setIndexes(): void {
        const registerIds: number[] = [];

        if(!isNullOrUndefined(this.entryCountingRegisterIndex)){
            registerIds.push(this.entryCountingRegisterIndex);
        }

        if(!isNullOrUndefined(this.accessEventRegisterIndex)){
            registerIds.push(this.accessEventRegisterIndex);
        }

        super.setIndexesBase([], registerIds, []);
    }

}
