<div class="counting-setting-content">
    <form [formGroup]="form" fxFlex>
        <mat-form-field>
            <mat-select formControlName="zoneType" placeholder="Mode">
                <mat-option [value]="ZoneTypeEnum.deferred">
                    On Exit
                </mat-option>
                <mat-option [value]="ZoneTypeEnum.instantaneous">
                    On Enter
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>