import { AfterViewInit, Component, EventEmitter, HostBinding, Injector, Input, OnChanges, Output, SimpleChanges, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsCountingMenuBaseComponent } from '@rift/components/settings/counting/base/Settings.Counting.MenuBase.Component';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { DeviceLensTypeEnumHelpers } from '@shared/enum/DeviceLensType.Enum';
import { EventsService } from '@shared/service/events/Events.Service';
import { IPosition } from 'angular2-draggable';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { ISize } from 'angular2-draggable/lib/models/size';

export class DeviceViewModel {
    public device: DeviceModel;
    public selected: boolean = false;

    public constructor(private readonly _device: DeviceModel) {
        this.device = _device;
    }
}

@Component({
    selector: 'rift-settings-counting-devices',
    templateUrl: './Settings.Counting.Devices.Component.html',
    styleUrls: ['./Settings.Counting.Devices.Component.scss'],
})
export class SettingsCountingDevicesComponent extends SettingsCountingMenuBaseComponent implements AfterViewInit, OnChanges {
    public static className: string = 'SettingsCountingDevicesComponent';

    @HostBinding()
    public id: string = 'rift-settings-counting-devices';

    public deviceVMs: Array<DeviceViewModel> = null;
    public devicesListHeight: number = 0;

    public DeviceLensTypeEnumHelpers = DeviceLensTypeEnumHelpers;

    @Input()
    public get bounds(): HTMLElement {
        return this._bounds;
    }
    public set bounds(value: HTMLElement) {
        this._bounds = value;
        this.checkPosition();
    }

    @Input()
    public get zIndex(): number {
        return this._zIndex;
    }
    public set zIndex(value: number) {
        this._zIndex = value;
    }

    @Input()
    public getDeviceVideoPlayState: (serialNumber: string) => boolean = null;

    @Input()
    public devices: Array<DeviceModel> = null;

    @Input()
    public selectedDevice: DeviceModel = null;

    @Input()
    public editDeviceDisabled: boolean = false;

    @Input()
    public editingDevice: DeviceModel = null;

    @Output()
    public deviceSelected: EventEmitter<DeviceModel> = new EventEmitter<DeviceModel>();

    @Output()
    public editDevice: EventEmitter<DeviceModel> = new EventEmitter<DeviceModel>();

    @Output()
    public toggleDeviceVideo: EventEmitter<DeviceModel> = new EventEmitter<DeviceModel>();

    @LocalStorage(SettingsCountingDevicesComponent.className, 'position')
    public position: IPosition;

    @LocalStorage(SettingsCountingDevicesComponent.className, 'show')
    public show: boolean;

    @LocalStorage(SettingsCountingDevicesComponent.className, 'size')
    public size: ISize;

    public constructor(
        private readonly _render: Renderer2,
        private readonly _eventsService: EventsService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_render, _injector, _dialog);

        this.minHeight = 200;
        this.minWidth = 350;

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingDevicesComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsCountingDevicesComponent.className, this.saveAllChangesProcessText);

        this.initConnectionState();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (!this.isNullOrUndefined(this.size)) {
            this.setDevicesListHeight(this.size);
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.devices) && !this.isNullOrUndefined(changes.devices.currentValue)) {
            this.deviceVMs = changes.devices.currentValue.map(i => {
                const vm = new DeviceViewModel(i);
                if (!this.isNullOrUndefined(this.selectedDevice) && this.selectedDevice.serialNumber === i.serialNumber) {
                    vm.selected = true;
                    this.selectedDevice = vm.device;
                }
                return vm;
            });
        }
    }

    public onRzResizing(event: IResizeEvent): void {
        super.onRzResizing(event);
        this.setDevicesListHeight(event.size);
    }

    public onRzStop(event: IResizeEvent): void {
        super.onRzStop(event);
        this.setDevicesListHeight(event.size);
    }

    public onDeviceSelectedClick(vm: DeviceViewModel, e: MouseEvent): void {
        const deviceVMsLength = this.deviceVMs.length;
        for (let deviceIndex = 0; deviceIndex < deviceVMsLength; deviceIndex++) {
            this.deviceVMs[deviceIndex].selected = false;
        }

        if (this.isNullOrUndefined(this.selectedDevice) || this.selectedDevice.serialNumber !== vm.device.serialNumber) {
            vm.selected = true;
            this.selectedDevice = vm.device;
            this.deviceSelected.next(this.selectedDevice);
        } else {
            this.deviceSelected.next(null);
            this.selectedDevice = null;
        }
        e.stopPropagation();
    }

    public onEditDeviceClick(vm: DeviceViewModel, e: MouseEvent): void {
        this.selectedDevice = vm.device;
        this.editDevice.next(vm.device);
        e.stopPropagation();
    }

    public onToggleDeviceVideoClick(vm: DeviceViewModel, e: MouseEvent): void {
        this.toggleDeviceVideo.next(vm.device);
        e.stopPropagation();
    }

    private setDevicesListHeight(size: ISize): void {
        this.devicesListHeight = size.height - 73;
    }
}
