<google-map fxFill
    width="100%"
    height="100%"
    class="gmaps"
    [center]="{lat: defaultLatitude, lng: defaultLongitude}"
    [zoom]="defaultZoom">
    <map-marker-clusterer imagePath="./assets/maps/c"
        [calculator]="calculator">
        <map-marker *ngFor="let group of deviceGroups;"
            #marker="mapMarker"
            [icon]="group.markerIcon"
            [position]="{lat: group.latitude, lng: group.longitude}"
            [title]="getTitle(group)"
            [label]="{text: group.friendlySerials.length.toString(), color: 'black', fontSize: '12px', fontWeight: 'bold'}"
            (mapClick)="openInfoWindow(marker, group)">            
        </map-marker>
    </map-marker-clusterer>
    <map-info-window>
        <div fxLayout="column">
            <div *ngFor="let friendlySerial of selectedDeviceGroup?.friendlySerials;"
                class="device-link">
                <em-device-serial-link [serial]="friendlySerial"
                    viewMode="link"></em-device-serial-link>
            </div>
        </div>
    </map-info-window>
</google-map>