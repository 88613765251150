import { Component, HostBinding, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingsCountingBaseComponent } from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { MarkedTargetEnum, MarkedTargetEnumHelpers } from '@shared/enum/MarkedTarget.Enum';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';

@Component({
    selector: 'rift-settings-counting-staff-detect',
    templateUrl: './Settings.Counting.StaffDetect.Component.html',
    styleUrls: ['./Settings.Counting.StaffDetect.Component.scss']
})
export class SettingsCountingStaffDetectComponent extends SettingsCountingBaseComponent implements OnChanges {
    public static className: string = 'SettingsCountingStaffDetectComponent';
    public static markedTargetDefault: MarkedTargetEnum = MarkedTargetEnum.none;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    private _staffRegister: RegisterBaseModel & { markedTarget: MarkedTargetEnum } = null;

    @HostBinding()
    public id: string = 'rift-settings-counting-staff-detect';

    public MarkedTargetEnum = MarkedTargetEnum;

    public form: FormGroup;

    @Input()
    public set register(value: RegisterBaseModel){
        this._staffRegister = (value as RegisterBaseModel & { markedTarget: MarkedTargetEnum });
    };

    public constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsCountingStaffDetectComponent.className, 'Form values change');

        this.form = this._formBuilder.group(
            {
                markedTarget: [''],
            }
        );

        this.addSubscription(this.observableHandlerBase(this.form.controls.markedTarget.valueChanges, this.formValuesChangeProcess).subscribe(value => {
            if (!this.isNullOrUndefined(this._staffRegister)) {
                this._staffRegister.markedTarget = value;
            }
        }), this.formValuesChangeProcess);

        this.formGroupTracker.track(this.form);

        this.initConnectionState();
    }

    public getSettingsDescription(): string {
        return this.isNullOrUndefined(this._staffRegister) ? '' : MarkedTargetEnumHelpers.toFullLongName(this._staffRegister.markedTarget);
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this._staffRegister) ? false : this._staffRegister.propertyHasChanges('markedTarget');
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this._staffRegister = changes.register.currentValue;
            this.setDefaults();
            this.setFormValues();
        }
    }

    protected offline(): void {
        super.offline();
        this.form.disable();
    }

    protected online(): void {
        super.online();
        this.form.enable();
    }

    private setDefaults(): void {
        if (this.isNullOrUndefined(this._staffRegister.markedTarget)) {
            this._staffRegister.markedTarget = SettingsCountingStaffDetectComponent.markedTargetDefault;
        }
    }

    private setFormValues(): void {
        this.form.controls.markedTarget.setValue(this.isNullOrUndefined(this._staffRegister.markedTarget) ? SettingsCountingStaffDetectComponent.markedTargetDefault : this._staffRegister.markedTarget, { emitEvent: false });
    }
}
