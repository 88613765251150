import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { ScheduleOverviewModel } from '@em/models/restapi/ScheduleOverview.Model';
import { BaseComponent } from '@shared/base/Base.Component';

@Component({
    selector: 'em-settings-task-launcher',
    templateUrl: './Settings.TaskLauncher.Component.html',
    styleUrls: ['./Settings.TaskLauncher.Component.scss']
})
export class SettingsTaskLauncherComponent extends BaseComponent implements OnInit {
    public static className: string = 'SettingsTaskLauncherComponent';
    public schedules: ScheduleOverviewModel[];

    @HostBinding()
    public id: string = 'em-settings-task-launcher';

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }
}
