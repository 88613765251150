import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

const EXCLUDE_FIELDS = ['startDateText', 'startTimeText', 'durationText', 'endTimeText'];

export interface IVideoSessionModel extends IRestModel {
    id: number;
    startTime: Date;
    endTime: Date;
    frames: number;
    bytes: number;
    timezoneOffsetMins: number;
    nodes: Array<string>;
    groupRecordingIdentifier: string;
}

export class VideoSessionModel extends BaseModel implements IVideoSessionModel, IRestModel {

    public error: string = null;

    public id: number = null;
    public bytes: number = null;
    public endTime: Date = null;
    public frames: number = null;
    public readonly isIRestModel = true;
    public startTime: Date = null;
    public statusCode: number = null;
    public timezoneOffsetMins: number = null;
    public nodes: Array<string> = null;
    public groupRecordingIdentifier: string = null;

    private _startDateText: string = null;
    private _durationText: string = null;
    private _endTimeText: string = null;
    private _startTimeText: string = null;

    public constructor() {
        super();
    }

    public get durationText(): string {
        return this._durationText;
    }

    public get endTimeText(): string {
        return this._endTimeText;
    }

    public get startDateText(): string {
        return this._startDateText;
    }

    public get startTimeText(): string {
        return this._startTimeText;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-VideoSessionModel', EXCLUDE_FIELDS);

        this.startTime = DateTimeNonMomentUtility.fromRestApiDateTime(this.startTime);
        this.endTime = DateTimeNonMomentUtility.fromRestApiDateTime(this.endTime);

        this.setTimeText();
    }

    public setTimeText(): any {
        if (isNullOrUndefined(this._startDateText) || DateTimeUtility.isInvalidDate(this._startDateText)) {
            this._startDateText = DateTimeUtility.toShortDate(this.startTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.timezoneOffsetMins));
        }

        if (isNullOrUndefined(this._startTimeText) || DateTimeUtility.isInvalidDate(this._startTimeText)) {
            this._startTimeText = DateTimeUtility.toShortTime(this.startTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.timezoneOffsetMins));
        }

        if (isNullOrUndefined(this._durationText) || this._durationText === 'NaN:NaN:NaN') {
            this._durationText = DateTimeUtility.toDuration(this.startTime, this.endTime);
        }

        if (isNullOrUndefined(this._endTimeText) || DateTimeUtility.isInvalidDate(this._endTimeText)) {
            this._endTimeText = DateTimeUtility.toShortTime(this.endTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.timezoneOffsetMins));
        }
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, EXCLUDE_FIELDS),
            StartTime: this.startTime,
            EndTime: this.endTime,
            Nodes: this.nodes
        };
    }
}
