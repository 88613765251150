import { Component, HostListener, Injector, OnDestroy, OnInit, ViewChild, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsNotificationsAddEditComponent, SettingsNotificationsAddEditData, SettingsNotificationsAddEditResult } from '@em/components/settings/notifications/addedit/Settings.Notifications.AddEdit.Component';
import { ManageLocationsDialogData, SettingsOutboundConnectionsManageLocationsComponent } from '@em/components/settings/notifications/managelocations/Settings.Notifications.ManageLocations.Component';
import { EndPointModel } from '@em/models/restapi/EndPoint.Model';
import { EndPointCollectionModel } from '@em/models/restapi/EndPointCollection.Model';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { NotificationModel } from '@em/models/restapi/Notification.Model';
import { NotificationCollectionModel } from '@em/models/restapi/NotificationCollection.Model';
import { NotificationRuleModel } from '@em/models/restapi/NotificationRule.Model';
import { GroupService } from '@em/service/data/group/Group.Service';
import { NotificationService } from '@em/service/data/notification/Notification.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { EventsService } from '@shared/service/events/Events.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { from, Observable, of, Subscription, zip } from 'rxjs';
import { flatMap, map, tap, zipAll } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'em-settings-notifications',
    templateUrl: './Settings.Notifications.Component.html',
    styleUrls: ['./Settings.Notifications.Component.scss']
})
export class SettingsNotificationsComponent extends BaseComponent implements OnDeactivate, OnInit, OnDestroy, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsNotificationsComponent';

    public addDeviceAction: NavBarAction;
    public addGlobalAction: NavBarAction;
    public addGroupAction: NavBarAction;
    public dataSource = new MatTableDataSource<NotificationRuleModel>();
    public displayedColumns = ['ruleTypeText', 'sendTo'];
    public endPointCollection: EndPointCollectionModel;
    public groups: Array<GroupModel>;
    public manageLocationsClickProcess: ProcessMonitorServiceProcess;
    public addGlobalRuleClickProcess: ProcessMonitorServiceProcess;
    public addGroupRuleClickProcess: ProcessMonitorServiceProcess;
    public addDeviceRuleClickProcess: ProcessMonitorServiceProcess;

    @ViewChild(MatPaginator, { static: true })
    public paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    public sort: MatSort;
    public manageLocationsAction: NavBarAction;

    @HostBinding()
    public id: string = 'em-settings-notifications';
    public notificationCollection: NotificationCollectionModel;
    public selectedNotification: NotificationModel;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _eventsService: EventsService,
        private readonly _formBuilder: FormBuilder,
        private readonly _groupService: GroupService,
        private readonly _navBarService: NavBarActionService,
        private readonly _notificationService: NotificationService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.manageLocationsClickProcess = this.processMonitorService.getProcess(SettingsNotificationsComponent.className, 'Manage Locations Click');
        this.addGlobalRuleClickProcess = this.processMonitorService.getProcess(SettingsNotificationsComponent.className, 'Add Global Rule Click');
        this.addGroupRuleClickProcess = this.processMonitorService.getProcess(SettingsNotificationsComponent.className, 'Add Group Rule Click');
        this.addDeviceRuleClickProcess = this.processMonitorService.getProcess(SettingsNotificationsComponent.className, 'Add Device Rule Click');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsNotificationsComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsNotificationsComponent.className, this.saveAllChangesProcessText);

        this.manageLocationsAction = new NavBarAction();
        this.manageLocationsAction.name = 'managelocations';
        this.manageLocationsAction.text = 'Manage Locations';
        this.addSubscription(this.observableHandlerBase(this.manageLocationsAction.onButtonClick, this.manageLocationsClickProcess).subscribe(() => { this.manageLocations(); }), this.manageLocationsClickProcess);

        this.addGlobalAction = new NavBarAction();
        this.addGlobalAction.name = 'addglobalrule';
        this.addGlobalAction.text = 'Add Global Rule';
        this.addSubscription(this.observableHandlerBase(this.addGlobalAction.onButtonClick, this.addGlobalRuleClickProcess).subscribe(() => { this.addGlobalRule(); }), this.addGlobalRuleClickProcess);

        this.addGroupAction = new NavBarAction();
        this.addGroupAction.name = 'addgrouprule';
        this.addGroupAction.text = 'Add Group Rule';
        this.addSubscription(this.observableHandlerBase(this.addGroupAction.onButtonClick, this.addGroupRuleClickProcess).subscribe(() => { this.addGroupRule(); }), this.addGroupRuleClickProcess);

        this.addDeviceAction = new NavBarAction();
        this.addDeviceAction.name = 'adddevicerule';
        this.addDeviceAction.text = 'Add Device Rule';
        this.addSubscription(this.observableHandlerBase(this.addDeviceAction.onButtonClick, this.addDeviceRuleClickProcess).subscribe(() => { this.addDeviceRule(); }), this.addDeviceRuleClickProcess);

        this.addSaveAllAction(this);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public dataExportGetData = (displayedColumn: string, data: NotificationRuleModel) => {
        switch (displayedColumn) {
            case 'ruleTypeText':
                return data.isGroupRule === true ? `Group:${this.getGroupName(data.value1)}` : data.isDeviceRule === true ? `Device:${data.value1}` : data.isGlobalRule === true ? 'All Devices' : '';
            case 'sendTo':
                return !this.isNullOrUndefined(data.endPoints) ? data.endPoints.map(i => `${i.text}:${i.protocolText}:${i.contentTypeText}:${i.address}`).join(',') : '';
        }
    };

    public dataExportGetHeader = (displayedColumn: string) => {
        switch (displayedColumn) {
            case 'ruleTypeText':
                return 'operate on';
            case 'sendTo':
                return 'send to locations';
        }
    };

    public addDeviceRule(): void {
        const rule = new NotificationRuleModel();
        rule.ruleType = 'DeviceRule';
        this.addRule(rule);
    }

    public addGlobalRule(): void {
        const rule = new NotificationRuleModel();
        rule.ruleType = 'AllRule';
        this.addRule(rule);
    }

    public addGroupRule(): void {
        const rule = new NotificationRuleModel();
        rule.ruleType = 'AddressBookRule';
        this.addRule(rule);
    }

    public addRule(rule: NotificationRuleModel): void {
        rule.ruleId = -1;
        rule.notificationId = this.selectedNotification.notificationId;

        this.addSubscription(this._dialog.open(SettingsNotificationsAddEditComponent, { data: new SettingsNotificationsAddEditData(rule, this.endPointCollection), disableClose: true }).afterClosed().subscribe(
            (result: SettingsNotificationsAddEditResult) => {
                if (!this.isNullOrUndefined(result) && !this.isNullOrUndefined(result.rule)) {
                    this.selectedNotification.rules.push(rule);
                    this.dataSource.data = this.selectedNotification.rules;
                    this.updateSaveAllAction(this);
                }
            }
        ));
    }

    public applyFilter(filterValue: string) {
        if (!this.isNullOrUndefined(this.dataSource.data) && this.dataSource.data.length > 0) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            this.dataSource.filter = filterValue;
        }
    }

    public dataSorceFilter(rule: NotificationRuleModel, filter: string): boolean {
        return true;
    }

    public deleteRule(rule: NotificationRuleModel): void {
        const ruleIndex = this.selectedNotification.rules.findIndex(i => i.uniqueId === rule.uniqueId);
        if (ruleIndex !== -1) {
            this.selectedNotification.rules.splice(ruleIndex, 1);
        }
        this.dataSource.data = this.selectedNotification.rules;
        this.updateSaveAllAction(this);
    }

    public editRule(rule: NotificationRuleModel): void {
        this.addSubscription(this._dialog.open(SettingsNotificationsAddEditComponent, { data: new SettingsNotificationsAddEditData(rule, this.endPointCollection), disableClose: true }).afterClosed().subscribe(
            (result: SettingsNotificationsAddEditResult) => {
                if (!this.isNullOrUndefined(result) && this.isNullOrUndefined(result.rule)) {
                    rule.clearChanges();
                }

                this.updateSaveAllAction(this);
            }
        ));
    }

    public getGroupName(groupIdStr: string): string {
        if (!this.isNullOrUndefined(this.groups) && !this.isNullOrUndefined(groupIdStr)) {
            const groupId = parseInt(groupIdStr, 10);
            if (!this.isNullOrUndefined(groupId) && !Number.isNaN(groupId)) {
                const group = this.groups.find(i => i.id === groupId);
                if (!this.isNullOrUndefined(group)) {
                    return group.name;
                }
            }
        }
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._notificationService.getNotifications(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.notificationCollection = result;
                        this.changeTracker.track(this.notificationCollection);
                        if (this.notificationCollection.items.length > 0) {
                            this.onNotificationSelected(this.notificationCollection.items[0]);
                        }
                    }
                    return true;
                })
            ),
            this._notificationService.getEndPoints(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.endPointCollection = result;
                        this.changeTracker.track(this.endPointCollection);
                    }
                    return true;
                })
            ),
            this._groupService.getNested(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.groups = ArrayUtility.flatten([], result);
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public manageLocations(): void {
        this._dialog.open(SettingsOutboundConnectionsManageLocationsComponent, { data: new ManageLocationsDialogData(), disableClose: true, minWidth: 600 }).afterClosed().subscribe(r => {
            this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this._navBarService.removeAction(this.manageLocationsAction);
        this._navBarService.removeAction(this.addGlobalAction);
        this._navBarService.removeAction(this.addGroupAction);
        this._navBarService.removeAction(this.addDeviceAction);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this._navBarService.addAction(this.manageLocationsAction);
        this._navBarService.addAction(this.addGlobalAction);
        this._navBarService.addAction(this.addGroupAction);
        this._navBarService.addAction(this.addDeviceAction);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.filterPredicate = this.dataSorceFilter.bind(this);
    }

    public onNotificationSelected(notification: NotificationModel): void {
        this.selectedNotification = notification;
        if (!this.isNullOrUndefined(this.selectedNotification.rules)) {
            this.dataSource.data = this.selectedNotification.rules;
            this.changeTracker.track(this.selectedNotification);
        } else {
            this.dataSource.data = [];
        }
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            from(this.notificationCollection.items).pipe(
                map(notification => of(notification.rules.newItems).pipe(
                        map(newItems => {
                            if (newItems.length > 0) {
                                return from(newItems).pipe(
                                    map(newIten => this._notificationService.addRule(newIten, process).pipe(
                                            map(result => true)
                                        )),
                                    zipAll(),
                                    map(result => this.isZipResultSuccess(result)),
                                );
                            } else {
                                return of(true);
                            }
                        }),
                        zipAll(),
                        map(result => this.isZipResultSuccess(result)),
                    )),
                zipAll(),
                map(result => this.isZipResultSuccess(result)),
            ),
            from(this.notificationCollection.items).pipe(
                map(notification => of(notification.rules.removedItems).pipe(
                        map(removedItems => {
                            if (removedItems.length > 0) {
                                return from(removedItems).pipe(
                                    map(removedItem => this._notificationService.deleteRule(removedItem.ruleId, process).pipe(
                                            map(result => true)
                                        )),
                                    zipAll(),
                                    map(result => this.isZipResultSuccess(result)),
                                );
                            } else {
                                return of(true);
                            }
                        }),
                        zipAll(),
                        map(result => this.isZipResultSuccess(result)),
                    )),
                zipAll(),
                map(result => this.isZipResultSuccess(result)),
            ),
            from(this.notificationCollection.items).pipe(
                map(notification => of(notification.rules.updatedItems).pipe(
                        map(updatedItems => {
                            if (updatedItems.length > 0) {
                                return from(updatedItems).pipe(
                                    map(updatedItem => this._notificationService.updateRule(updatedItem, process).pipe(
                                            map(result => true)
                                        )),
                                    zipAll(),
                                    map(result => this.isZipResultSuccess(result)),
                                );
                            } else {
                                return of(true);
                            }
                        }),
                        zipAll(),
                        map(result => this.isZipResultSuccess(result)),
                    )),
                zipAll(),
                map(result => this.isZipResultSuccess(result)),
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process).pipe(
                        tap(() => {
                            this._eventsService.changedNotifications();
                        })
                    );
                } else {
                    return of(result);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._notificationService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }
}
