import { Injectable } from '@angular/core';
import { ChangePasswordModel } from '@rift/models/restapi/ChangePassword.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { WebAPIKeyModel } from '@rift/models/restapi/WebAPIKey.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiSecurityService } from '@rift/service/restapi/v1/RestApi.Security.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TLSCertModel } from '@rift/models/restapi/TLSCert.Model';
import { SSLCertModel } from '@rift/models/restapi/SSLCert.Model';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';
import { HttpEnabledDataModel } from '@rift/models/restapi/HttpEnabledData.Model';
import { AuthenticationEnabledDataModel } from '@rift/models/restapi/AuthenticationEnabledData.Model';

@Injectable()
export class SecurityService extends RiftBaseService {

    private _changePasswordLoadingTracker = new ObservableTracker<ResultModel>();
    private _getWebAPIKeyLoadingTracker = new ObservableTracker<WebAPIKeyModel>();
    private _regenerateWebAPIKeyLoadingTracker = new ObservableTracker<ResultModel>();
    private _uploadCACertLoadingTracker = new ObservableTracker<ResultModel>();
    private _removeCACertLoadingTracker = new ObservableTracker<ResultModel>();
    private _uploadCertPairLoadingTracker = new ObservableTracker<ResultModel>();
    private _removeCertPairLoadingTracker = new ObservableTracker<ResultModel>();
    private _getHttpEnabledTracker = new ObservableTracker<HttpEnabledDataModel>();
    private _setHttpEnabledTracker = new ObservableTracker<ResultModel>();
    private _getAuthenticationEnabledTracker = new ObservableTracker<AuthenticationEnabledDataModel>();
    private _setAuthenticationEnabledTracker = new ObservableTracker<ResultModel>();

    private _webAPIKeyCache: WebAPIKeyModel;

    public constructor(
        private readonly _restApiSecurityService: RestApiSecurityService) {
        super();
    }
    public changePassword(changePassword: ChangePasswordModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ResultModel> {
        return this._changePasswordLoadingTracker
            .getLoading(changePassword)
            .observable(this._restApiSecurityService.changePassword(changePassword, process, retryOptions));
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._webAPIKeyCache = null;
    }

    public getWebAPIKey(process?: ProcessMonitorServiceProcess): Observable<WebAPIKeyModel> {
        if (isNullOrUndefined(this._webAPIKeyCache)) {
            return this._getWebAPIKeyLoadingTracker
                .getLoading()
                .observable(this._restApiSecurityService.getWebAPIKey(process).pipe(
                    map(result => {
                        this._webAPIKeyCache = result;
                        return this._webAPIKeyCache;
                    })
                ));
        } else {
            return of(this._webAPIKeyCache);
        }
    }

    public regenerateWebAPIKey(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._regenerateWebAPIKeyLoadingTracker
            .getLoading()
            .observable(this._restApiSecurityService.regenerateWebAPIKey(process).pipe(tap(this._webAPIKeyCache = null)));
    }

    public getCACertNames(process?: ProcessMonitorServiceProcess): Observable<Array<string>> {
        return this._restApiSecurityService.getCACertNames(process);
    }

    public getCertPairNames(process?: ProcessMonitorServiceProcess): Observable<Array<string>> {
        return this._restApiSecurityService.getCertPairNames(process);
    }

    public uploadCACert(cert: TLSCertModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._uploadCACertLoadingTracker
            .getLoading(cert)
            .observable(this._restApiSecurityService.uploadCACert(cert, process));
    }

    public uploadCertPair(cert: SSLCertModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ResultModel> {
        return this._uploadCertPairLoadingTracker
            .getLoading(cert)
            .observable(this._restApiSecurityService.uploadCertPair(cert, process, retryOptions));
    }

    public removeCACert(cert: TLSCertModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._removeCACertLoadingTracker
            .getLoading(cert)
            .observable(this._restApiSecurityService.removeCACert(cert, process));
    }

    public removeCertPair(cert: SSLCertModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ResultModel> {
        return this._removeCertPairLoadingTracker
            .getLoading(cert)
            .observable(this._restApiSecurityService.removeCertPair(cert, process, retryOptions));
    }

    public getHttpEnabled(serialNumber: string, process?: ProcessMonitorServiceProcess): Observable<HttpEnabledDataModel> {
        return this._getHttpEnabledTracker
            .getLoading(serialNumber)
            .observable(this._restApiSecurityService.getHttpEnabled(serialNumber, process));
    }

    public setHttpEnabled(serialNumber: string, httpEnabledData: HttpEnabledDataModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setHttpEnabledTracker
            .getLoading(serialNumber, httpEnabledData)
            .observable(this._restApiSecurityService.setHttpEnabled(serialNumber, httpEnabledData, process));
    }

    public getAuthenticationEnabled(serialNumber: string, process?: ProcessMonitorServiceProcess): Observable<AuthenticationEnabledDataModel> {
        return this._getAuthenticationEnabledTracker
            .getLoading(serialNumber)
            .observable(this._restApiSecurityService.getAuthenticationEnabled(serialNumber, process));
    }

    public setAuthenticationEnabled(serialNumber: string, authenticationEnabledData: AuthenticationEnabledDataModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setAuthenticationEnabledTracker
            .getLoading(serialNumber, authenticationEnabledData)
            .observable(this._restApiSecurityService.setAuthenticationEnabled(serialNumber, authenticationEnabledData, process));
    }
}
