import { REGISTER_BASE_EXCLUDE_PROPERTIES, RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MarkedTargetEnum } from '@shared/enum/MarkedTarget.Enum';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterZoneWaitTimeSumModel extends IRegisterBaseModel {
    polygon: number;
    minDwellDuration: number;
    lowerBound: number;
    upperBound: number;
    heightFilterEnabled: boolean;
    markedTarget: MarkedTargetEnum;
    maxDwellDuration: number;
    maxDwellEnabled: boolean;
}

/**
 * Represents a
 *
 * @export
 * @class RegisterZoneWaitTimeSumModel
 * @extends {RegisterBaseModel}
 * @implements {IRegisterZoneWaitTimeModel}
 * @implements {IRestModel}
 */
export class RegisterZoneWaitTimeSumModel extends RegisterBaseModel implements IRegisterZoneWaitTimeSumModel, IRestModel {
    public polygon: number = null;
    public minDwellDuration: number = null;
    public lowerBound: number = null;
    public upperBound: number = null;
    public heightFilterEnabled: boolean = null;
    public markedTarget: MarkedTargetEnum = null;
    public maxDwellDuration: number = null;
    public maxDwellEnabled: boolean = null;

    public constructor() {
        super();

        this.registerProperty('polygon');
        this.registerProperty('minDwellDuration');
        this.registerProperty('markedTarget');
        this.registerProperty('lowerBound');
        this.registerProperty('upperBound');
        this.registerProperty('heightFilterEnabled');
        this.registerProperty('maxDwellDuration');
        this.registerProperty('maxDwellEnabled');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterZoneWaitTimeSumModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('polygon', this.polygon);
        this.setPropertyOriginalValue('minDwellDuration', this.minDwellDuration);
        this.setPropertyOriginalValue('markedTarget', this.markedTarget);
        this.setPropertyOriginalValue('lowerBound', this.lowerBound);
        this.setPropertyOriginalValue('upperBound', this.upperBound);
        this.setPropertyOriginalValue('heightFilterEnabled', this.heightFilterEnabled);
        this.setPropertyOriginalValue('maxDwellDuration', this.maxDwellDuration);
        this.setPropertyOriginalValue('maxDwellEnabled', this.maxDwellEnabled);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], [], [this.polygon]);
    }

}
