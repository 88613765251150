import { IResultModel } from '@rift/models/restapi/Result.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

const EXCLUDE_FIELDS = [];

export interface IAuthenticationEnabledDataModel extends IRestModel {
    authenticationEnabled: boolean;
}

export class AuthenticationEnabledDataModel extends BaseModel implements IAuthenticationEnabledDataModel, IResultModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public authenticationEnabled: boolean = null;

    public constructor() {
        super();

        this.registerProperty('authenticationEnabled');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-AuthenticationEnabledDataModel', EXCLUDE_FIELDS);

        this.setPropertyOriginalValue('authenticationEnabled', this.authenticationEnabled);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, EXCLUDE_FIELDS),
        };
    }
}
