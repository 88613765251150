import { IRegisterConfig } from '@rift/shared/IRegisterConfig';
import { IRegisterSettingsConfig } from '@rift/shared/IRegisterProperty';
import { IRegisterViewPortConfig } from '@rift/shared/IRegisterViewPortConfig';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

/**
 * Gets a config boolean value or defaultValue if the config is null or undefined.
 *
 * @export
 * @template TConfig The type of the config.
 * @param {TConfig} config The config to get a value for.
 * @param {keyof TConfig} key The property name of the config to get.
 * @param {boolean} [defaultValue=false] The default value to return if the config key is null or undefined.
 * @returns {boolean} The config key value or defaultValue if the config is null or undefined.
 */
export function getConfigBooleanValue<TConfig extends IRegisterConfig | IRegisterSettingsConfig | IRegisterViewPortConfig>(config: TConfig, key: keyof TConfig, defaultValue: boolean = false): boolean {
    return isNullOrUndefined(config) ? defaultValue : isNullOrUndefined(config[key]) ? defaultValue : config[key] as unknown as boolean;
}
