import {
    IRegisterRemoteReceiverModel,
    RegisterRemoteReceiverModel,
} from '@rift/models/restapi/RegisterRemoteReceiver.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';

export interface IRegisterPushEntryModel extends IRestModel {
    remoteRegister: IRegisterRemoteReceiverModel;
    devices: string[];
}

/**
 * Represents a
 *
 * @export
 * @class RegisterPushEntryModel
 * @extends {BaseModel}
 * @implements {IRegisterOccupancyMasterModel}
 * @implements {IRestModel}
 */
export class RegisterPushEntryModel extends BaseModel implements IRegisterPushEntryModel, IRestModel {

    public error: string = null;
    public statusCode: number = null;
    public remoteRegister: RegisterRemoteReceiverModel = null;
    public devices: string[] = null;

    public constructor() {
        super();

        this.registerProperty('remoteRegister');
        this.registerProperty('devices');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterPushEntryModel');

        this.remoteRegister = RegisterBaseUtility.getRegisterTypeFromRestApiModel(restModel.RemoteRegister) as RegisterRemoteReceiverModel;

        this.setPropertyOriginalValue('remoteRegister', this.remoteRegister);
        this.setPropertyOriginalValue('devices', this.devices);
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
            RemoteRegister: RestModelUtility.toJson(this.remoteRegister),
            Devices: this.devices,
        };
    }
}
