import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

const EXCLUDE_FIELDS = [];

export class TaskDescriptionModel extends BaseModel implements IRestModel {
    public description: string = null;

    public id: number = null;
    public name: string = null;
    public readonly isIRestModel = true;
    public taskFilename: string = null;
    public version: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-TaskDescriptionModel');
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this, EXCLUDE_FIELDS),
        };
    }
}
