import { Injectable } from '@angular/core';
import { ClientConnectionCollectionModel } from '@rift/models/restapi/ClientConnectionCollection.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiOutboundService } from '@rift/service/restapi/v1/RestApi.Outbound.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OutboundConnectionsService extends RiftBaseService {
    private _clientConnectionsCache: ClientConnectionCollectionModel;

    private _getClientConnectionsLoadingTracker = new ObservableTracker<ClientConnectionCollectionModel>();

    private _updateClientConnectionsLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiOutboundService: RestApiOutboundService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._clientConnectionsCache = null;
    }

    public getClientConnections(process?: ProcessMonitorServiceProcess): Observable<ClientConnectionCollectionModel> {
        if (isNullOrUndefined(this._clientConnectionsCache)) {
            return this._getClientConnectionsLoadingTracker
                .getLoading()
                .observable(this._restApiOutboundService.getClientConnections(process).pipe(
                    map(result => {
                        this._clientConnectionsCache = result;
                        return this._clientConnectionsCache;
                    })
                ));
        } else {
            return of(this._clientConnectionsCache);
        }
    }

    public setCache(clientConnectionsCache: ClientConnectionCollectionModel): Observable<boolean> {
        this._clientConnectionsCache = clientConnectionsCache;
        return of(true);
    }
    public updateClientConnections(clientConnections: ClientConnectionCollectionModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateClientConnectionsLoadingTracker
            .getLoading()
            .observable(this._restApiOutboundService.updateClientConnections(clientConnections, process));
    }
}
