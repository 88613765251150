import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router,
                private _zone: NgZone) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
        return next.handle(req).pipe(catchError(x => this.handleAuthError(x))); // here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        // handle auth error
        if (err.status === 401 || err.status === 403) {
            const url = new URL(err.url);

            // Don't redirect to the login if it was the login that failed as
            // we'll already be on that page and doing so would stop any additional
            // failure information from being sent to the login front end.
            if (url.pathname.toLowerCase() !== '/account/login'){
                this._zone.run(() => {this.router.navigate(['login']); });
                // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
                return EMPTY;
            }
        }
        return throwError(err);
    }
}
