import { Component, HostListener, Injector, HostBinding } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { ClientConnectionModel } from '@rift/models/restapi/ClientConnection.Model';
import { ClientConnectionCollectionModel } from '@rift/models/restapi/ClientConnectionCollection.Model';
import { AllDataService } from '@rift/service/data/alldata/AllData.Service';
import { OutboundConnectionsService } from '@rift/service/data/outboundconnections/OutboundConnections.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { TestConnectionDataModel } from '@rift/models/restapi/TestConnectionData.Model';
import { OkCancelDialogData, OkCancelDialogComponent } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'rift-settings-outbound-connections',
    templateUrl: './Settings.OutboundConnections.Component.html',
    styleUrls: ['./Settings.OutboundConnections.Component.scss']
})
export class SettingsOutboundConnectionsComponent extends RiftBaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsOutboundConnectionsComponent';

    @HostBinding()
    public id: string = 'rift-settings-outbound-connections';

    public clientConnections: ClientConnectionCollectionModel;
    public clientConnectionsFormGroup: FormGroup;
    public testConnectionProcess: ProcessMonitorServiceProcess;
    public addClientConnectionProcess: ProcessMonitorServiceProcess;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;
    public testError: string = null;
    public hostDevice: DeviceModel;

    public constructor(
        private readonly _outboundConnectionsService: OutboundConnectionsService,
        private readonly _allDataService: AllDataService,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.addClientConnectionProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsComponent.className, 'Add client connection');
        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsComponent.className, this.saveAllChangesProcessText);
        this.testConnectionProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsComponent.className, 'Testing outbound connection');

        this.addSaveAllAction(this);

        this.clientConnectionsFormGroup = this._formBuilder.group({
            connections: this._formBuilder.array([]),
        });
        this.formGroupTracker.track(this.clientConnectionsFormGroup);

        this.initConnectionState();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public onTestConnectionClick(clientConnection: ClientConnectionModel): void {
        this.testError = null;
        const data = new TestConnectionDataModel();
        data.address = clientConnection.address;
        data.port = clientConnection.port;
        this.addSubscription(this.observableHandlerBase(this.deviceService.testConnection(this.hostDevice.serialNumber, data), this.testConnectionProcess).subscribe(
            result => {
                if (!this.isNullOrUndefined(result) && this.isNullOrUndefined(result.error)) {
                    const dialogData = new OkCancelDialogData(`Outbound Connection Test (${clientConnection.address}:${clientConnection.port})`, null, false);
                    dialogData.messageHtml = result.details.map(detail => `<span>${detail}</span><br>`).join('');

                    this._dialog.open(OkCancelDialogComponent, { data: dialogData });
                } else {
                    this.testError = result.error;
                }
            }
        ), this.testConnectionProcess);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.getHostDevice().pipe(
                map(device => {
                    if (!this.isNullOrUndefined(device)) {
                        this.hostDevice = device;
                    }
                    return true;
                })
            ),
            this._outboundConnectionsService.getClientConnections(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        const connectionsFormArray = this.clientConnectionsFormGroup.controls.connections as FormArray;
                        const itemsLength = result.items.length;

                        if (connectionsFormArray.length === 0) {
                            for (let index = 0; index < itemsLength; index++) {
                                this.addClientConnectionFormGroup(result.items[index]);
                            }
                        } else {
                            for (let index = 0; index < itemsLength; index++) {
                                this.addClientConnectionFormGroupSubs(result.items[index], connectionsFormArray.at(index) as FormGroup);
                            }
                        }

                        this.clientConnections = result;
                        this.changeTracker.track(this.clientConnections);
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.clientConnections.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._outboundConnectionsService.updateClientConnections(this.clientConnections, process).pipe(
                            map(() => true)
                        );
                    } else {
                        return of(true);
                    }
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    this.eventsService.changedOutboundConnections();
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(false);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._outboundConnectionsService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.clientConnectionsFormGroup.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.clientConnectionsFormGroup.enable();
        const fArray = this.clientConnectionsFormGroup.controls.connections as FormArray;
        const controlsLength = fArray.controls.length;
        for (let index = 0; index < controlsLength; index++) {
            const formGroup = fArray.controls[index] as FormGroup;
            if (formGroup.controls.enabled.value === true) {
                formGroup.enable();
            } else {
                formGroup.controls.enabled.enable();
            }
        }
    }

    private addClientConnectionFormGroup(clientConnection: ClientConnectionModel, formArray?: FormArray): void {
        const fArray = this.isNullOrUndefined(formArray) ? this.clientConnectionsFormGroup.controls.connections as FormArray : formArray;
        if (!this.isNullOrUndefined(fArray)) {
            const form = this.createClientConnectionFormGroup(clientConnection);
            this.setClientConnectionFormGroupValues(clientConnection, form);
            fArray.push(form);
        }
    }

    private addClientConnectionFormGroupSubs(clientConnection: ClientConnectionModel, formGroup: FormGroup): void {
        this.addSubscription(this.observableHandlerBase(formGroup.controls.enabled.valueChanges, this.addClientConnectionProcess).subscribe(() => {
            if (this.isReadOnly === false) {
                if (formGroup.controls.enabled.value === true) {
                    formGroup.controls.address.enable();
                    formGroup.controls.port.enable();
                    formGroup.controls.reconnectionInterval.enable();
                } else {
                    formGroup.controls.address.disable();
                    formGroup.controls.port.disable();
                    formGroup.controls.reconnectionInterval.disable();

                    clientConnection.clearChanges();

                    formGroup.controls.address.setValue(clientConnection.address, { emitEvent: false });
                    formGroup.controls.port.setValue(clientConnection.port, { emitEvent: false });
                    formGroup.controls.reconnectionInterval.setValue(clientConnection.reconnectionInterval, { emitEvent: false });
                }
            }
        }), this.addClientConnectionProcess);

        this.addSubscription(this.observableHandlerBase(formGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            this.updateModelValuesClientConnectionFormGroup(formGroup, clientConnection);
        }), this.formValuesChangeProcess);
    }

    private createClientConnectionFormGroup(clientConnection: ClientConnectionModel): FormGroup {
        const formGroup = this._formBuilder.group({
            enabled: ['', Validators.compose([])],
            address: ['', Validators.compose([Validators.required, ValidationValidators.ipAddressOrHostName])],
            port: ['', Validators.compose([Validators.required, ValidationValidators.port])],
            reconnectionInterval: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(3600), Validators.pattern('[0-9]*')])],
        });

        if (this.isReadOnly === true) {
            formGroup.disable();
        }

        this.addClientConnectionFormGroupSubs(clientConnection, formGroup);

        return formGroup;
    }

    private setClientConnectionFormGroupValues(clientConnection: ClientConnectionModel, formGroup: FormGroup): void {
        if (!this.isNullOrUndefined(clientConnection)) {
            formGroup.setValue({
                enabled: clientConnection.enabled,
                address: clientConnection.address,
                port: clientConnection.port,
                reconnectionInterval: clientConnection.reconnectionInterval,
            });
        }
    }

    private updateModelValuesClientConnectionFormGroup(formGroup: FormGroup, clientConnection: ClientConnectionModel): void {
        if (!this.isNullOrUndefined(clientConnection) && this.isReadOnly === false) {
            const formValues = formGroup.value;

            clientConnection.enabled = formValues.enabled;
            if (formValues.enabled === true) {
                clientConnection.address = formValues.address;
                clientConnection.port = formValues.port;
                clientConnection.reconnectionInterval = formValues.reconnectionInterval;
            }

            this.updateSaveAllAction(this);
        }
    }
}
