import { VideoScheduleModel, IVideoScheduleModel } from '@rift/models/restapi/VideoSchedule.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IVideoScheduleInfoModel extends IRestModel {
    schedules: Array<IVideoScheduleModel>;
}

export class VideoScheduleInfoModel extends BaseModel implements IVideoScheduleInfoModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public schedules: Array<VideoScheduleModel> = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-VideoScheduleInfoModel');

        this.schedules = RestModelUtility.loadFromArray(restModel.Schedules, VideoScheduleModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
