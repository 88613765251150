import { IMQTTPublishConfigEntryModel, MQTTPublishConfigEntryModel } from '@rift/models/restapi/MQTTPublishConfigEntry.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IMQTTModel extends IRestModel {
    publishFormat: string;
    publishEntries: Array<IMQTTPublishConfigEntryModel>;
}

export class MQTTModel extends BaseModel implements IMQTTModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public publishFormat: string = null;
    public publishEntries: RestModelChangeTrackerArray<MQTTPublishConfigEntryModel> = new RestModelChangeTrackerArray<MQTTPublishConfigEntryModel>();

    public constructor() {
        super();

        this.registerChangeTrackerArray('publishEntries');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-MQTTModel');

        this.publishEntries = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.PublishEntries, MQTTPublishConfigEntryModel);

        this.setPropertyOriginalValue('publishEntries', this.publishEntries);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            PublishEntries: RestModelUtility.toJsonArray(this.publishEntries),
        };
    }
}
