import { AfterViewInit, Component, ComponentRef, ElementRef, HostBinding, Injector, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestoreBackupFileSelectComponent } from '@em/components/rift/restorebackup/fileselect/RestoreBackup.FileSelect.Component';
import { SchedulesAddEditDialogData, SettingsSchedulesAddEditComponent } from '@em/components/settings/schedules/scheduleaddedit/Settings.Schedules.AddEdit.Component';
import { SettingsTaskLauncherRunComponent, SettingsTaskLauncherRunDialogData } from '@em/components/settings/tasklauncher/tasklauncherrun/Settings.TaskLauncher.Run.Component';
import { BridgeAddressModel } from '@em/models/restapi/BridgeAddress.Model';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { FavouriteDevicesService } from '@em/service/favouritedevices/FavouriteDevices.Service';
import { EmRiftService } from '@em/service/rift/Em.Rift.Service';
import { HomeComponentBase } from '@rift/components/base/HomeComponentBase';
import { RestoreBackupComponent, RestoreBackupDialogData } from '@rift/components/restorebackup/RestoreBackup.Component';
import { DeviceIssuesComponent } from '@rift/components/shared/bottominfo/deviceIssues/DeviceIssues.Component';
import { UnitsOfMeasurementMenuSelectComponent } from '@rift/components/shared/unitsofmeasurementmenuselect/UnitsOfMeasurementMenuSelect.Component';
import { ManageSynchronizedRecordingsComponent, ManageSynchronizedRecordingsDialogData } from '@rift/components/validation/managesynchronizedrecordings/ManageSynchronizedRecordings.component';
import { ConnectionRequestModel } from '@rift/models/restapi/ConnectionRequest.Model';
import { ConnectionService } from '@rift/service/connection/Connection.Service';
import { AllDataService } from '@rift/service/data/alldata/AllData.Service';
import { RecordingControlService } from '@rift/service/data/recording/RecordingControl.Service1';
import { ValidationService } from '@rift/service/validation/Validation.Service';
import { WebSocketService } from '@rift/service/websocket/WebSocket.Service';
import { OkCancelDialogComponent, OkCancelDialogData, OkCancelDialogResult } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { ConnectionStatusEnum } from '@shared/enum/ConnectionStatus.Enum';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { BreadCrumbService } from '@shared/service/breadcrumb/BreadCrumb.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { NavBarService } from '@shared/service/navbar/NavBar.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { FileUtility } from '@shared/utility/File.Utility';
import { UrlUtility } from '@shared/utility/Url.Utility';
import * as FileSaver from 'file-saver';
import { merge, of } from 'rxjs';
import { MatTabNav } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { BottomInfoStateEnum } from '@shared/component/bottominfo/BottomInfo.State.Enum';
import { map, catchError } from 'rxjs/operators';
import { BottomInfoFileDownloadComponent } from '@shared/component/bottominfo/file-download/BottomInfo.FileDownload.Component';
import { SyncingVideoSessionsComponent } from '@rift/components/shared/bottominfo/syncingvideosessions/SyncingVideoSessions.Component';
import { DiagnosticsPackageComponent, DiagnosticsPackageDialogData } from '@rift/components/diagnosticspackage/DiagnosticsPackage.Component';
import { RiftDeleteLiveDataComponent } from '../deletelivedata/Rift.DeleteLiveData.Component';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { FormatSystemStorageComponent, FormatSystemStorageDialogData } from '@rift/components/formatsystemstorage/FormatSystemStorage.Component';

@Component({
    selector: 'em-rift-home',
    templateUrl: './Rift.Home.Component.html',
    styleUrls: ['./Rift.Home.Component.scss'],
})
export class RiftHomeComponent extends HomeComponentBase implements OnDestroy, AfterViewInit, OnInit {
    public static className: string = 'RiftHomeComponent';

    public activityRelativeUrl: string = null;
    public addScheduleAction: NavBarAction = null;
    public addScheduleActionClickProcess: ProcessMonitorServiceProcess;
    public backupAction: NavBarAction = null;
    public backupActionClickProcess: ProcessMonitorServiceProcess;
    public cancelConnectionProcess: ProcessMonitorServiceProcess;
    public changeConnectionStateProcess: ProcessMonitorServiceProcess;
    public connectedProcess: ProcessMonitorServiceProcess;
    public connectedToolsCountProcess: ProcessMonitorServiceProcess;
    public connectionStateChangeProcess: ProcessMonitorServiceProcess;
    public connectProcess: ProcessMonitorServiceProcess;
    public countsRelativeUrl: string = null;
    public deleteDeviceAction: NavBarAction = null;
    public deleteDeviceActionClickProcess: ProcessMonitorServiceProcess;
    public diagnosticsAction: NavBarAction = null;
    public diagnosticsPackageAction: NavBarAction = null;
    public formatSystemStorageAction: NavBarAction = null;
    public diagnosticsPackagesProcess: ProcessMonitorServiceProcess;
    public formatSystemStorageProcess: ProcessMonitorServiceProcess;
    public diagnosticsActionClickProcess: ProcessMonitorServiceProcess;
    public diagnosticsRelativeUrl: string = null;
    public disconnectedProcess: ProcessMonitorServiceProcess;
    public elementRef: ElementRef;
    public emDeviceDataPollingProcess: ProcessMonitorServiceProcess;
    public errorConnectingProcess: ProcessMonitorServiceProcess;
    public favouriteAction: NavBarAction = null;
    public getBackupFileProcess: ProcessMonitorServiceProcess = null;
    public getBridgeConnectionDetailsProcess: ProcessMonitorServiceProcess;
    public getDiagnosticsCountsProcess: ProcessMonitorServiceProcess;
    public globalDiagnosticsCountsPollingProcess: ProcessMonitorServiceProcess = null;
    public healthRelativeUrl: string = null;
    public locationRelativeUrl: string = null;
    public manageValidationAction: NavBarAction = null;
    public manageValidationActionClickProcess: ProcessMonitorServiceProcess;
    public metaDataRelativeUrl: string = null;
    public offlineProcess: ProcessMonitorServiceProcess;
    public onlineOfflineSwitchProcess: ProcessMonitorServiceProcess;
    public parsedConnectionParamsProcess: ProcessMonitorServiceProcess;
    public rebootAction: NavBarAction = null;
    public rebootActionClickProcess: ProcessMonitorServiceProcess;
    public recordingsRelativeUrl: string = null;
    public restoreAction: NavBarAction = null;
    public restoreActionClickProcess: ProcessMonitorServiceProcess;
    public settingsRelativeUrl: string = null;
    public setupEmRiftServiceProcess: ProcessMonitorServiceProcess;
    public summaryRelativeUrl: string = null;
    public taskLauncherAction: NavBarAction = null;
    public taskLauncherActionClickProcess: ProcessMonitorServiceProcess;
    public unitsOfMeasurementMenuAction: NavBarAction = null;
    public validateDevicesProcess: ProcessMonitorServiceProcess;
    public validationRelativeUrl: string = null;
    public getDevicesProcess: ProcessMonitorServiceProcess;
    public deleteLiveDataAction: NavBarAction = null;
    public deleteLiveDataClickProcess: ProcessMonitorServiceProcess;

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @ViewChild('matTabNav', { static: true })
    public matTabNav: MatTabNav;

    @HostBinding()
    public class: string = 'em-rift-home';

    @HostBinding()
    public id: string = 'em-rift-home';

    private _deviceIssuesBottomInfoMessageComponent: ComponentRef<DeviceIssuesComponent> = null;
    private _diagnosticsCountsDataPollingEvent: DataPollingEvent = null;
    private _emDeviceDataPollingEvent: DataPollingEvent = null;

    public constructor(
        private readonly _favouriteDevices: FavouriteDevicesService,
        private readonly _emRiftService: EmRiftService,
        private readonly _renderer: Renderer2,
        private readonly _allDataService: AllDataService,
        private readonly _zone: NgZone,
        private readonly _dataPollingService: DataPollingService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _breadCrumbService: BreadCrumbService,
        private readonly _configurationService: ConfigurationService,
        private readonly _connectionService: ConnectionService,
        private readonly _deviceService: DeviceService,
        private readonly _dialog: MatDialog,
        private readonly _elementRef: ElementRef,
        private readonly _navBarService: NavBarService,
        private readonly _navBarActionService: NavBarActionService,
        private readonly _router: Router,
        private readonly _webSocketService: WebSocketService,
        private readonly _validationService: ValidationService,
        private readonly _recordingControlService: RecordingControlService,
        private readonly _injector: Injector) {
        super(_zone, _renderer, _allDataService, _configurationService, _dialog, _activatedRoute, _router, _injector);

        this.elementRef = this._elementRef;

        this.connectionService.emConnection = true;

        this.createTabRoutes();

        this.connectProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Connecting to device');
        this.changeConnectionStateProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Changing connection state');
        this.globalDiagnosticsCountsPollingProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Getting Diagnostics Counts data');
        this.getBackupFileProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Getting backup file data');
        this.errorConnectingProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Error connecting');
        this.cancelConnectionProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Cancel connection');
        this.connectedToolsCountProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Tool connection count');
        this.disconnectedProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Disconnect');
        this.offlineProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Offline');
        this.connectedProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Connected');
        this.onlineOfflineSwitchProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Online off line switch');
        this.connectionStateChangeProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Connection state change');
        this.setupEmRiftServiceProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Setting up em rift service');
        this.validateDevicesProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Validating device');
        this.rebootActionClickProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Reboot action clicked');
        this.backupActionClickProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Backup action clicked');
        this.restoreActionClickProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Restore action clicked');
        this.taskLauncherActionClickProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Task launcher action clicked');
        this.manageValidationActionClickProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Manage validation action clicked');
        this.addScheduleActionClickProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Add schedule action clicked');
        this.deleteDeviceActionClickProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Delete device action clicked');
        this.diagnosticsActionClickProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Diagnostics action clicked');
        this.getBridgeConnectionDetailsProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Getting bridge connection details');
        this.parsedConnectionParamsProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Phrasing Connection Params');
        this.getDiagnosticsCountsProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Getting Diagnostics Counts');
        this.diagnosticsPackagesProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Diagnostics Packages');
        this.formatSystemStorageProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Format System Storage');
        this.getDevicesProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Getting devices');
        this.deleteLiveDataClickProcess = this.processMonitorService.getProcess(RiftHomeComponent.className, 'Delete Live Data');

        this._emDeviceDataPollingEvent = new DataPollingEvent('RiftHomeComponent:EmDeviceData', 10000, 10000, this.setupEmRiftServiceProcess);
        this._diagnosticsCountsDataPollingEvent = new DataPollingEvent('RiftHomeComponent:DiagnosticsCounts', 0, 20000, this.getDiagnosticsCountsProcess);

        this.addSubscription(this.observableHandlerBase(this.errorConnecting, this.errorConnectingProcess).subscribe(() => this.navigateToOnError()), this.errorConnectingProcess);
        this.addSubscription(this.observableHandlerBase(this.cancelConnection, this.cancelConnectionProcess).subscribe(() => this.navigateToOnError()), this.errorConnectingProcess);
        this.addSubscription(this.observableHandlerBase(this.errorGettingDeviceData, this.cancelConnectionProcess).subscribe(() => this.navigateToOnError()), this.errorConnectingProcess);

        this.addSubscription(this.eventsService.onOutboundConnectionsChanged.subscribe(() => this.getEMDeviceData()));

        this.addSubscription(merge(this._favouriteDevices.added, this._favouriteDevices.removed).subscribe(() => this.setFavouriteActionText()));

        this.createNavBarActions();
        this.addDeviceSerialToBreadCrumb();
        this.addRiftConnectionStateToNavBar();
        this.addOnlineOfflineSwitchNotification();
        this.startEmDeviceStateDataPolling();
        this.addDeviceConnectionCount();
        this.userNotificationService.showBottomInfo();
        this.startDiagnosticsCountsDataPolling();

        this.connect();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this.stopEmDeviceStateDataPolling();
        this.stopDiagnosticsCountsDataPolling();

        this._navBarService.riftConnectionState = ConnectionStatusEnum.offline;
        this._navBarService.showRiftInfo = false;
        this._navBarService.emDeviceConnected = null;

        this.clearNavBarActions();

        this._validationService.stopWorkers();
        this._recordingControlService.stop();

        if (!this.isNullOrUndefined(this._deviceIssuesBottomInfoMessageComponent)) {
            this.userNotificationService.removeComponent(this._deviceIssuesBottomInfoMessageComponent.instance.instanceId);
            this._deviceIssuesBottomInfoMessageComponent = null;
        }

        this.userNotificationService.removeAllComponentType(BottomInfoFileDownloadComponent);
        this.userNotificationService.removeAllComponentType(SyncingVideoSessionsComponent);

        this.connectionService.disconnect();
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.matTabNav._handleKeydown = () => { };
    }

    public onHomeClick(): void {

    }

    protected addActionAddScheduleAction(): void {
        this.userCurrentService.isSystemAdmin.subscribe(isSystemAdmin => {
            if (isSystemAdmin === true) {
                this._navBarActionService.addAction(this.addScheduleAction);
            }
        });
    }

    protected addFormatSystemStorageAction(): void {
        this.addSubscription(this.observableHandlerBase(this.deviceService.getDevices(), this.getDevicesProcess).subscribe((result)=>{
            if (result.items.every(d => d.isCapable(DeviceCapabilitiesEnum.formatSystemStorage))){
                this.userCurrentService.isAdmin.subscribe(isAdmin => {
                    if (isAdmin === true) {
                        this._navBarActionService.addAction(this.formatSystemStorageAction);
                    }
                });
            }
        }));
    }

    protected addActionDeleteDeviceAction(): void {
        this.userCurrentService.isAdmin.subscribe(isInstaller => {
            if (isInstaller === true) {
                this._navBarActionService.addAction(this.deleteDeviceAction);
            }
        });
    }

    protected addActionRestoreAction(): void {
        this.userCurrentService.isInstaller.subscribe(isInstaller => {
            if (isInstaller === true) {
                this._navBarActionService.addAction(this.restoreAction);
            }
        });
    }

    protected addActionTaskLauncherAction(): void {
        this.userCurrentService.isAdmin.subscribe(isAdmin => {
            if (isAdmin === true) {
                this._navBarActionService.addAction(this.taskLauncherAction);
            }
        });
    }

    protected addActionBridgeFileAction(): void {
        this.userCurrentService.isInstaller.subscribe(isInstaller => {
            if (isInstaller === true) {
                this._navBarActionService.addAction(this.diagnosticsAction);
            }
        });
    }

    protected offline(): void {
        super.offline();
        this.setAtOfflineOnline();

        this.setFavouriteActionText();

        this.clearNavBarActions();
        this._navBarActionService.addAction(this.favouriteAction);
        this._navBarActionService.addAction(this.manageValidationAction);
        this._navBarActionService.addAction(this.unitsOfMeasurementMenuAction);
        this.addActionTaskLauncherAction();
        this.addActionAddScheduleAction();
        this.addActionDeleteDeviceAction();
        this.addActionBridgeFileAction();
        this.addSubscription(this.observableHandlerBase(this.getHostDevice(), this.getDevicesProcess).subscribe((result) => {
            if (result.unitGen === UnitGenerationEnum.kestrel || result.unitGen === UnitGenerationEnum.falcon){
                this.userCurrentService.isAdmin.subscribe(isAdmin => {
                    if (isAdmin === true) {
                        this._navBarActionService.addAction(this.deleteLiveDataAction);
                    }
                });
            }
        }), this.getDevicesProcess);

        this.getEMDeviceData();
    }

    protected onConnected(): void {
        super.onConnected();
        this._validationService.startWorkers();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.setAtOfflineOnline();

        this.setFavouriteActionText();

        this.clearNavBarActions();
        this._navBarActionService.addAction(this.favouriteAction);
        this._navBarActionService.addAction(this.rebootAction);
        this._navBarActionService.addAction(this.manageValidationAction);
        this._navBarActionService.addAction(this.unitsOfMeasurementMenuAction);
        this._navBarActionService.addAction(this.backupAction);
        this.addActionRestoreAction();
        this.addActionTaskLauncherAction();
        this.addActionAddScheduleAction();
        this.addActionDeleteDeviceAction();
        this.addActionBridgeFileAction();
        this.addFormatSystemStorageAction();

        this.addSubscription(this.observableHandlerBase(this.getHostDevice(), this.getDevicesProcess).subscribe((result) => {
            if (result.unitGen === UnitGenerationEnum.kestrel || result.unitGen === UnitGenerationEnum.falcon){
                this.userCurrentService.isAdmin.subscribe(isAdmin => {
                    if (isAdmin === true) {
                        this._navBarActionService.addAction(this.deleteLiveDataAction);
                    }
                });
            }
        }), this.getDevicesProcess);

        this.addSubscription(this.observableHandlerBase(this.deviceService.getDevices(), this.getDevicesProcess).subscribe((result) => {
            if (result.items.every(d => d.isCapable(DeviceCapabilitiesEnum.diagnosticPackage))){
                this._navBarActionService.addAction(this.diagnosticsPackageAction);
            }
        }), this.getDevicesProcess);

        this.getEMDeviceData();
    }

    private addDeviceConnectionCount(): void {
        this.addSubscription(this.observableHandlerBase(this._webSocketService.connectedToolsCountMessageReceived, this.connectedToolsCountProcess).subscribe(result => {
            if (this._connectionService.isOnline === true) {
                this._zone.run(() => this._navBarService.riftConnectionCount = result.numTools);
            }
        }), this.connectedToolsCountProcess);

        this.addSubscription(this.observableHandlerBase(this._connectionService.disconnected, this.disconnectedProcess).subscribe(result => {
            this._navBarService.riftConnectionCount = null;
        }), this.disconnectedProcess);

        this.addSubscription(this.observableHandlerBase(this._connectionService.offline, this.offlineProcess).subscribe(result => {
            this._navBarService.riftConnectionCount = null;
        }), this.offlineProcess);
    }

    private addDeviceSerialToBreadCrumb(): void {
        this.addSubscription(this.observableHandlerBase(this.connectionService.connected, this.connectedProcess).subscribe(() => {
            const crumb = this._breadCrumbService.getBreadCrumbByName('em-rift-home');
            if (!this.isNullOrUndefined(this.connectionOptions)) {
                crumb.text = this.connectionOptions.friendlySerial;

                this._breadCrumbService.refreshBreadCrumbs();
            }
        }), this.connectedProcess);
    }

    private addOnlineOfflineSwitchNotification(): void {
        this.addSubscription(this.eventsService.onConfirmRiftGoOffline.subscribe(
            canGoOffline => {
                if (canGoOffline === true) {
                    this.addSubscription(this.observableHandlerBase(this.connectionService.goOffline(this.changeConnectionStateProcess), this.onlineOfflineSwitchProcess).subscribe(() => {
                    }), this.changeConnectionStateProcess);
                } else {
                    this._navBarService.forceRiftConnectionState(ConnectionStatusEnum.online);
                }
            }
        ), this.changeConnectionStateProcess);

        this.addSubscription(this.observableHandlerBase(this._navBarService.riftConnectionStateRequest, this.onlineOfflineSwitchProcess).subscribe(stateRequest => {
            switch (stateRequest) {
                case ConnectionStatusEnum.offline:
                    this.eventsService.beforeRiftGoOffline();
                    break;
                case ConnectionStatusEnum.online:
                    this.addSubscription(this.observableHandlerBase(this.connectionService.goOnline(this.changeConnectionStateProcess), this.onlineOfflineSwitchProcess).subscribe(() => {
                    }), this.changeConnectionStateProcess);
                    break;
            }
        }), this.onlineOfflineSwitchProcess);
    }

    private addRiftConnectionStateToNavBar(): void {
        this.addSubscription(this.observableHandlerBase(this.connectionService.connectionStateChange, this.connectionStateChangeProcess).subscribe(value => {
            this._navBarService.riftConnectionState = value;
        }), this.connectionStateChangeProcess);
    }

    private addSchedule(): void {
        this._dialog.open(SettingsSchedulesAddEditComponent, { data: new SchedulesAddEditDialogData('add'), disableClose: true, maxWidth: 850 });
    }

    private backup(): void {
        this.addSubscription(this.observableHandlerBase(this.globalService.getBackup(this.getBackupFileProcess), this.getBackupFileProcess).subscribe(
            result => {
                const blob = new Blob([result.data], { type: 'text/plain;charset=utf-8' });
                FileSaver.saveAs(blob, FileUtility.sanitize(result.fileName));
            }
        ), this.getBackupFileProcess);
    }

    private clearNavBarActions(): void {
        this._navBarActionService.removeAction(this.favouriteAction);
        this._navBarActionService.removeAction(this.rebootAction);
        this._navBarActionService.removeAction(this.manageValidationAction);
        this._navBarActionService.removeAction(this.unitsOfMeasurementMenuAction);
        this._navBarActionService.removeAction(this.backupAction);
        this._navBarActionService.removeAction(this.restoreAction);
        this._navBarActionService.removeAction(this.taskLauncherAction);
        this._navBarActionService.removeAction(this.addScheduleAction);
        this._navBarActionService.removeAction(this.deleteDeviceAction);
        this._navBarActionService.removeAction(this.diagnosticsAction);
        this._navBarActionService.removeAction(this.diagnosticsPackageAction);
        this._navBarActionService.removeAction(this.deleteLiveDataAction);
        this._navBarActionService.removeAction(this.formatSystemStorageAction);
    }

    private createNavBarActions(): void {
        this.favouriteAction = new NavBarAction();
        this.favouriteAction.name = 'rift-favourite';
        this.favouriteAction.text = 'favourites';
        this.favouriteAction.faIconName = 'heart';
        this.favouriteAction.order = 0;
        this.addSubscription(this.observableHandlerBase(this.favouriteAction.onButtonClick, this.rebootActionClickProcess).subscribe(() => { this.toggleFavourite(); }), this.rebootActionClickProcess);

        this.rebootAction = new NavBarAction();
        this.rebootAction.name = 'rift-reboot';
        this.rebootAction.text = 'Reboot Device';
        this.rebootAction.faIconName = 'power-off';
        this.rebootAction.order = 1;
        this.addSubscription(this.observableHandlerBase(this.rebootAction.onButtonClick, this.rebootActionClickProcess).subscribe(() => { this.reboot(); }), this.rebootActionClickProcess);

        this.backupAction = new NavBarAction();
        this.backupAction.name = 'rift-backup';
        this.backupAction.text = 'Backup';
        this.backupAction.order = 2;
        this.backupAction.faIconName = 'save';
        this.addSubscription(this.observableHandlerBase(this.backupAction.onButtonClick, this.backupActionClickProcess).subscribe(() => { this.backup(); }), this.backupActionClickProcess);

        this.restoreAction = new NavBarAction();
        this.restoreAction.name = 'rift-restore';
        this.restoreAction.text = 'Restore';
        this.restoreAction.order = 3;
        this.restoreAction.faIconName = 'folder-open';
        this.addSubscription(this.observableHandlerBase(this.restoreAction.onButtonClick, this.restoreActionClickProcess).subscribe(() => { this.restore(); }), this.restoreActionClickProcess);

        this.diagnosticsPackageAction = new NavBarAction();
        this.diagnosticsPackageAction.name = 'diagnostics-packages';
        this.diagnosticsPackageAction.text = 'Diagnostics Packages';
        this.diagnosticsPackageAction.order = 4;
        this.diagnosticsPackageAction.faIconName = 'stethoscope';
        this.addSubscription(this.observableHandlerBase(this.diagnosticsPackageAction.onButtonClick, this.diagnosticsPackagesProcess).subscribe(() => { this.diagnosticPackages(); }), this.diagnosticsPackagesProcess);

        this.formatSystemStorageAction = new NavBarAction();
        this.formatSystemStorageAction.name = 'format-system-storage';
        this.formatSystemStorageAction.text = 'Format System Storage';
        this.formatSystemStorageAction.order = 5;
        this.formatSystemStorageAction.faIconName = 'memory';
        this.addSubscription(this.observableHandlerBase(this.formatSystemStorageAction.onButtonClick, this.formatSystemStorageProcess).subscribe(() => { this.openFormatSystemStorage(); }), this.formatSystemStorageProcess);

        this.taskLauncherAction = new NavBarAction();
        this.taskLauncherAction.name = 'rift-task-launcher';
        this.taskLauncherAction.text = 'Task Launcher';
        this.taskLauncherAction.order = 6;
        this.taskLauncherAction.faIconName = 'rocket';
        this.addSubscription(this.observableHandlerBase(this.taskLauncherAction.onButtonClick, this.taskLauncherActionClickProcess).subscribe(() => { this.taskLauncher(); }), this.taskLauncherActionClickProcess);

        this.manageValidationAction = new NavBarAction();
        this.manageValidationAction.name = 'rift-managesynchronizedrecordings';
        this.manageValidationAction.text = 'Manage Validations';
        this.manageValidationAction.order = 7;
        this.manageValidationAction.faIconName = 'film';
        this.addSubscription(this.observableHandlerBase(this.manageValidationAction.onButtonClick, this.manageValidationActionClickProcess).subscribe(() => { this.manageValidations(); }), this.manageValidationActionClickProcess);

        this.addScheduleAction = new NavBarAction();
        this.addScheduleAction.name = 'em-add-schedule';
        this.addScheduleAction.text = 'Add Schedule';
        this.addScheduleAction.order = 8;
        this.addSubscription(this.observableHandlerBase(this.addScheduleAction.onButtonClick, this.addScheduleActionClickProcess).subscribe(() => { this.addSchedule(); }), this.addScheduleActionClickProcess);

        this.deleteDeviceAction = new NavBarAction();
        this.deleteDeviceAction.name = 'em-delete-device';
        this.deleteDeviceAction.text = 'Delete Device';
        this.deleteDeviceAction.order = 9;
        this.addSubscription(this.observableHandlerBase(this.deleteDeviceAction.onButtonClick, this.deleteDeviceActionClickProcess).subscribe(() => { this.deleteDevice(); }), this.deleteDeviceActionClickProcess);

        this.diagnosticsAction = new NavBarAction();
        this.diagnosticsAction.name = 'em-irisys-diagnostics';
        this.diagnosticsAction.text = 'Irisys Bridge File';
        this.diagnosticsAction.order = 10;
        this.addSubscription(this.observableHandlerBase(this.diagnosticsAction.onButtonClick, this.diagnosticsActionClickProcess).subscribe(() => { this.diagnostics(); }), this.diagnosticsActionClickProcess);

        this.deleteLiveDataAction = new NavBarAction();
        this.deleteLiveDataAction.name = 'em-delete-live-data';
        this.deleteLiveDataAction.text = 'Delete Device Data';
        this.deleteLiveDataAction.order = 11;
        this.addSubscription(this.observableHandlerBase(this.deleteLiveDataAction.onButtonClick, this.deleteLiveDataClickProcess).subscribe(() => {this.deleteLiveData(); }), this.deleteLiveDataClickProcess);

        this.unitsOfMeasurementMenuAction = new NavBarAction();
        this.unitsOfMeasurementMenuAction.name = 'rift-unitsofmeasurement-menu';
        this.unitsOfMeasurementMenuAction.text = 'Units Of Measurement';
        this.unitsOfMeasurementMenuAction.faIconName = 'folder-open';
        this.unitsOfMeasurementMenuAction.order = 12;
        this.unitsOfMeasurementMenuAction.menuComponent = UnitsOfMeasurementMenuSelectComponent;
    }

    private createTabRoutes(): void {
        this.summaryRelativeUrl = 'summary';
        this.settingsRelativeUrl = 'settings';
        this.diagnosticsRelativeUrl = 'diagnostics';
        this.metaDataRelativeUrl = 'metadata';
        this.countsRelativeUrl = 'counts';
        this.recordingsRelativeUrl = 'recordings';
        this.validationRelativeUrl = 'validation';
        this.activityRelativeUrl = 'activity';
        this.locationRelativeUrl = 'location';
        this.healthRelativeUrl = 'health';
    }

    private deleteDevice(): void {
        const data = new OkCancelDialogData('Delete Device', null, true);
        data.messageHtml = 'Are you sure you want to schedule the deletion of ' + this.connectionService.hostFriendlySerial + '? This will remove all data associated with this device.<br>' +
            'A delete will not block the device from connecting to Estate Manager. If the unit reconnects quickly the delete will be cancelled and none of the data will be erased.';
        this.addSubscription(this._dialog.open(OkCancelDialogComponent, { data, maxWidth: 450, disableClose: true }).afterClosed().subscribe(
            (result: OkCancelDialogResult) => {
                if (!this.isNullOrUndefined(result)) {
                    if (result.ok) {
                        this.addSubscription(this._deviceService.deleteDevice(this.connectionService.hostFriendlySerial).subscribe(
                            (deleteResult) => {
                                if (!this.isNullOrUndefined(deleteResult) && this.isNullOrUndefined(deleteResult.errorMessage)) {
                                    this._router.navigate(['/']);
                                }
                            },
                        ));
                    }
                }
            },
        ));
    }

    private deleteLiveData(): void{
        this._dialog.open(RiftDeleteLiveDataComponent, {minWidth: 850, disableClose: true });
    }

    private diagnostics(): void {
        const address = new BridgeAddressModel();
        address.address = `${location.protocol}//${location.host}`;
        this.addSubscription(this._deviceService.getBridgeConnectionDetails(this.connectionService.hostFriendlySerial, address, this.getBridgeConnectionDetailsProcess).subscribe(
            (result) => {
                const blob = new Blob([JSON.stringify(result.toRestApiModel())], { type: 'text/json;charset=utf-8' });
                FileSaver.saveAs(blob, FileUtility.sanitize(`${this.connectionService.hostFriendlySerial}_connectionInfo.irisysbridge`));
            },
        ), this.getBridgeConnectionDetailsProcess);
    }

    private getEMDeviceData(): void {
        this.addSubscription(this.parsedConnectionParams().subscribe((options: ConnectionRequestModel) => {
            this.addSubscription(this._deviceService.getDeviceConnectionStatus(options.friendlySerial, null, {disabled: true}).pipe(
                map(device => {
                    if (!this.isNullOrUndefined(device)) {
                        const crumb = this._breadCrumbService.getBreadCrumbByName('em-rift-home');
                        crumb.deviceInfo = device.deviceInfo;


                        this._emRiftService.masterDeviceId = device.serial;
                        this._navBarService.emDeviceUnLicensed = !device.isLicensed;
                        if (device.isConnected === true) {
                            this._navBarService.emDeviceConnected = true;
                            this._navBarService.showRiftInfo = true;
                        } else {
                            this._navBarService.emDeviceConnected = false;
                            this._navBarService.showRiftInfo = false;
                            this._navBarService.emLastConnected = device.lastConnected;
                        }

                        this._breadCrumbService.refreshBreadCrumbs();
                    }
                }),
                catchError((err: any) => {
                    console.log(err);
                    return of(true);
                })
            ).subscribe(), this.setupEmRiftServiceProcess);
        }), this.parsedConnectionParamsProcess);
    }

    private manageValidations(): void {
        this._dialog.open(ManageSynchronizedRecordingsComponent, { data: new ManageSynchronizedRecordingsDialogData(), minWidth: 850, disableClose: true });
    }

    private navigateToOnError(): void {
        this._zone.run(() => {
            this._router.navigate(['dashboard']);
        });
    }

    private restore(): void {
        this._dialog.open(RestoreBackupComponent, { data: new RestoreBackupDialogData(RestoreBackupFileSelectComponent), minWidth: '750px', disableClose: true });
    }

    private diagnosticPackages(): void{
        this._dialog.open(DiagnosticsPackageComponent, {data: new DiagnosticsPackageDialogData(), minWidth: 850, disableClose: true });
    }

    private openFormatSystemStorage(): void{
        this._dialog.open(FormatSystemStorageComponent, {data: new FormatSystemStorageDialogData(), minWidth: 850, disableClose: true }).afterClosed().subscribe(result => {
            if (result != null && result.startFormat === true){
                this.formatSystemStorage(result.serialNumber, result.formatType);
            }
        });
    }

    private setAtOfflineOnline(): void {
        this.addSubscription(this.isDeviceCapable(DeviceCapabilitiesEnum.videoRecording).subscribe(
            isVideoRecording => {
                if (isVideoRecording === true) {
                    this._recordingControlService.reset();
                }
            }
        ));
    }

    private setFavouriteActionText(): void {
        this.addSubscription(this._favouriteDevices.exists(this.connectionService.connectedToFriendlySerial).subscribe((exists) => {
            this.favouriteAction.text = `${exists ? 'Remove from' : 'Add to'} favourites`;
        }));
    }

    private startDiagnosticsCountsDataPolling(): void {
        this.subDiagnosticsCountsDataPolling();
        this._dataPollingService.startEvent(this._diagnosticsCountsDataPollingEvent);
    }

    private startEmDeviceStateDataPolling(): void {
        this.subEmDeviceStateDataPolling();
        this._dataPollingService.startEvent(this._emDeviceDataPollingEvent);
    }

    private stopDiagnosticsCountsDataPolling(): void {
        this._dataPollingService.stopEvent(this._diagnosticsCountsDataPollingEvent);
    }

    private stopEmDeviceStateDataPolling(): void {
        this._dataPollingService.stopEvent(this._emDeviceDataPollingEvent);
    }

    private subDiagnosticsCountsDataPolling(): void {
        this.addSubscription(this._diagnosticsCountsDataPollingEvent.poll.subscribe(() => {
            this.globalService.clearDiagnosticsCountsCache();
            this.addSubscription(this.globalService.getDiagnosticsCounts(this.getDiagnosticsCountsProcess, {disabled: true}).pipe(
                map(errorsAndWarnings => {
                    const total = errorsAndWarnings.errorsCount + errorsAndWarnings.warningsCount;

                    if (total > 0) {
                        if (this.isNullOrUndefined(this._deviceIssuesBottomInfoMessageComponent)) {
                            this._deviceIssuesBottomInfoMessageComponent = this.userNotificationService.addComponent(DeviceIssuesComponent);
                            this._deviceIssuesBottomInfoMessageComponent.instance.state = errorsAndWarnings.errorsCount > 0 ? BottomInfoStateEnum.error : BottomInfoStateEnum.warn;
                        }

                        this._deviceIssuesBottomInfoMessageComponent.instance.total = total;
                    } else {
                        if (!this.isNullOrUndefined(this._deviceIssuesBottomInfoMessageComponent)) {
                            this.userNotificationService.removeComponent(this._deviceIssuesBottomInfoMessageComponent.instance.instanceId);
                            this._deviceIssuesBottomInfoMessageComponent = null;
                        }
                    }
                }),
                catchError((err: any) => {
                    console.log(err);
                    return of(true);
                })
            ).subscribe(), this.getDiagnosticsCountsProcess);
        }), this.globalDiagnosticsCountsPollingProcess);
    }

    private subEmDeviceStateDataPolling(): void {
        this.addSubscription(this._emDeviceDataPollingEvent.poll.subscribe(() => {
            this.getEMDeviceData();
        }), this.emDeviceDataPollingProcess);
    }

    private taskLauncher(): void {
        this._dialog.open(SettingsTaskLauncherRunComponent, { data: new SettingsTaskLauncherRunDialogData(this.connectionService.hostFriendlySerial), minWidth: 500, maxWidth: 500, disableClose: true });
    }

    private toggleFavourite(): void {
        this.addSubscription(this._deviceService.getDevice(this.connectionService.hostFriendlySerial).subscribe(
            device => {
                this.addSubscription(this._favouriteDevices.exists(this.connectionService.connectedToFriendlySerial).subscribe((exists) => {
                    if (exists) {
                        this._favouriteDevices.remove(device.friendlySerial);
                    } else {
                        this._favouriteDevices.add(device.deviceInfo);
                    }
                    this.setFavouriteActionText();
                }));
            }
        ));
    }
}
