export enum ViewPortModeEnum {
    /**
     * Standard device fov, registers and video view mode.
     */
    view = 0,
    /**
     * Standard device fov, registers edit mode.
     */
    edit = 1,
    /**
     * Device graphics only.
     */
    deviceGraphics = 2,
}

export class ViewPortModeEnumHelpers {
    public static toString(mode: ViewPortModeEnum): string {
        switch (mode) {
            case ViewPortModeEnum.edit:
                return 'edit';
            case ViewPortModeEnum.view:
                return 'view';
            case ViewPortModeEnum.deviceGraphics:
                return 'deviceGraphics';
        }
    }
}
