import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BackgroundLoadSpinnerComponent } from '@shared/component/backgroundloadspinner/BackgroundLoadSpinner.Component';
import { BottomInfoComponent } from '@shared/component/bottominfo/BottomInfo.Component';
import {
    BottomInfoFileDownloadComponent,
} from '@shared/component/bottominfo/file-download/BottomInfo.FileDownload.Component';
import { BottomInfoMessageComponent } from '@shared/component/bottominfo/message/BottomInfo.Message.Component';
import { BreadCrumbComponent } from '@shared/component/breadcrumb/BreadCrumb.Component';
import { BrowserIncompatibleComponent } from '@shared/component/browserincompatible/BrowserIncompatible.component';
import { CanvasComponent } from '@shared/component/canvas/Canvas.Component';
import { DeviceInfoTooltipComponent } from '@shared/component/deviceinfotooltip/DeviceInfoTooltip.Component';
import { CommsRetryDialogComponent } from '@shared/component/dialog/commsretry/CommsRetry.Component';
import { ErrorDialogComponent } from '@shared/component/dialog/error/Error.Dialog.Component';
import { OkCancelDialogComponent } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { UnsavedChangesDialogComponent } from '@shared/component/dialog/unsavedchanges/UnsavedChanges.Dialog.Component';
import { FontAwesomeComponent } from '@shared/component/fontawesome/FontAwesome.Component';
import { ImageInspectComponent } from '@shared/component/imageinspect/ImageInspect.Component';
import { LoginComponent } from '@shared/component/login/Login.Component';
import { NavBarComponent } from '@shared/component/navbar/NavBar.Component';
import { NavBarPaginationComponent } from '@shared/component/navbarpagination/NavBarPagination.Component';
import { NotFoundComponent } from '@shared/component/notfound/NotFound.Component';
import { ProcessRunningComponent } from '@shared/component/processrunning/ProcessRunning.Component';
import { ProgressButtonBarComponent } from '@shared/component/progressbutton/ProgressButton.Bar.Component';
import { ProgressButtonSpinnerComponent } from '@shared/component/progressbutton/ProgressButton.Spinner.Component';
import { TableCellComponent } from '@shared/component/table/Table.Cell.Component';
import { TableComponent } from '@shared/component/table/Table.Component';
import { TableActionsTrayComponent } from '@shared/component/tableactionstray/Table.ActionsTray.Component';
import { ClickOutsideDirective } from '@shared/directive/clickoutside/ClickOutside.Directive';
import { DeviceInfoTooltipDirective } from '@shared/directive/deviceinfotooltip/Device.Info.Tooltip.Directive';
import { ImageInspectDirective } from '@shared/directive/imageinspect/ImageInspect.Directive';
import { MatchHeightDirective } from '@shared/directive/matchheight/MatchHeight.Directive';
import { ResizedDirective } from '@shared/directive/resized/Resized.Directive';
import { TimeZoneTooltipDirective } from '@shared/directive/timezonetooltip/TimeZoneTooltip.Directive';
import { VarDirective } from '@shared/directive/var/Var.Directive';
import {
    SafeCountCompatiblityCheckService,
} from '@shared/service/safecountcapabilitycheck/SafeCountCapabilityCheck.Service';
import { OrderModule } from 'ngx-order-pipe';
import { PrintDirective } from '@shared/directive/print/Print.Directive';

@NgModule({
    exports: [
        BackgroundLoadSpinnerComponent,
        BottomInfoComponent,
        BottomInfoFileDownloadComponent,
        BottomInfoMessageComponent,
        BreadCrumbComponent,
        BrowserIncompatibleComponent,
        CanvasComponent,
        ClickOutsideDirective,
        CommsRetryDialogComponent,
        DeviceInfoTooltipComponent,
        DeviceInfoTooltipDirective,
        ErrorDialogComponent,
        FontAwesomeComponent,
        ImageInspectComponent,
        ImageInspectDirective,
        LoginComponent,
        MatchHeightDirective,
        NavBarComponent,
        NavBarPaginationComponent,
        NotFoundComponent,
        OkCancelDialogComponent,
        PleaseWaitDialogComponent,
        PrintDirective,
        ProcessRunningComponent,
        ProgressButtonBarComponent,
        ProgressButtonSpinnerComponent,
        ResizedDirective,
        TableActionsTrayComponent,
        TableCellComponent,
        TableComponent,
        TimeZoneTooltipDirective,
        UnsavedChangesDialogComponent,
        VarDirective,
    ],
    declarations: [
        BackgroundLoadSpinnerComponent,
        BottomInfoComponent,
        BottomInfoFileDownloadComponent,
        BottomInfoMessageComponent,
        BreadCrumbComponent,
        BrowserIncompatibleComponent,
        CanvasComponent,
        ClickOutsideDirective,
        CommsRetryDialogComponent,
        DeviceInfoTooltipComponent,
        DeviceInfoTooltipDirective,
        ErrorDialogComponent,
        FontAwesomeComponent,
        ImageInspectComponent,
        ImageInspectDirective,
        LoginComponent,
        MatchHeightDirective,
        NavBarComponent,
        NavBarPaginationComponent,
        NotFoundComponent,
        OkCancelDialogComponent,
        PleaseWaitDialogComponent,
        ProcessRunningComponent,
        ProgressButtonBarComponent,
        ProgressButtonSpinnerComponent,
        ResizedDirective,
        TableActionsTrayComponent,
        TableCellComponent,
        TableComponent,
        TimeZoneTooltipDirective,
        UnsavedChangesDialogComponent,
        VarDirective,
        PrintDirective,
    ],
    providers: [
        SafeCountCompatiblityCheckService,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        HttpClientModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        OrderModule,
        RouterModule,
    ]
})
export class SharedModule { }
