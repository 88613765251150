<div class="counting-setting-content">
    <form [formGroup]="form" fxFlex>
        <mat-form-field>
            <mat-select formControlName="markedTarget" placeholder="Mode">
                <mat-option [value]="MarkedTargetEnum.none">
                    Off
                </mat-option>
                <mat-option [value]="MarkedTargetEnum.exclude">
                    Exclude Staff
                </mat-option>
                <mat-option [value]="MarkedTargetEnum.only">
                    Staff only
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>