import { Component, HostBinding, Injector, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SettingsCountingHistogramBaseComponent } from '@rift/components/settings/counting/shared/settings/base/SettingsCountingHistogramBase.Component';
import { HistogramConfigModel } from '@rift/models/restapi/HistogramConfig.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';

@Component({
    selector: 'rift-settings-counting-unattended-time-histogram',
    templateUrl: './Settings.Counting.UnattendedTimeHistogram.Component.html',
    styleUrls: ['./Settings.Counting.UnattendedTimeHistogram.Component.scss']
})
export class SettingsCountingUnattendedTimeHistogramComponent extends SettingsCountingHistogramBaseComponent implements OnChanges {
    public static binWidthDefault: number = 0;
    public static className: string = 'SettingsCountingUnattendedTimeHistogramComponent';
    public static minimumValueDefault: number = 0;
    public static numberOfBinsDefault: number = 0;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'rift-settings-counting-unattended-time-histogram';

    public constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsCountingUnattendedTimeHistogramComponent.className, 'Form values change');

        this.form = this._formBuilder.group(
            {
                minimumValue: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(3600)])],
                binWidth: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(3600)])],
                numberOfBins: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(3600)])],
            }
        );

        this.addSubscription(this.observableHandlerBase(this.form.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            if (!this.isNullOrUndefined(this._histogramConfig)) {
                this._histogramConfig.minimumValue = parseFloat(this.form.controls.minimumValue.value) * 10;
                this._histogramConfig.binWidth = parseFloat(this.form.controls.binWidth.value) * 10;
                this._histogramConfig.numberOfBins = parseFloat(this.form.controls.numberOfBins.value);
            }
        }), this.formValuesChangeProcess);

        this.formGroupTracker.track(this.form);
        this.setFormState();

        this.initConnectionState();
    }

    public getSettingsDescription(): string {
        return this.enabled === true ? 'Enabled' : 'Disabled';
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this.register = changes.register.currentValue;

            this.enabled = this._hadHistogramConfig = this.register.hasUnattendedTimeHistogram;
            if (this.enabled) {
                this._histogramConfig = this.register.unattendedTimeHistograms[0];
            } else {
                this._histogramConfig = new HistogramConfigModel();
                this._histogramConfig.binWidth = SettingsCountingUnattendedTimeHistogramComponent.binWidthDefault;
                this._histogramConfig.numberOfBins = SettingsCountingUnattendedTimeHistogramComponent.numberOfBinsDefault;
                this._histogramConfig.minimumValue = SettingsCountingUnattendedTimeHistogramComponent.minimumValueDefault;
                this._histogramConfig.expression = `I${this.register.polygonIds[0]},D;`;
                this._histogramConfig.commitChanges();
            }

            this.setFormState();
            this.setFormValues(
                SettingsCountingUnattendedTimeHistogramComponent.binWidthDefault,
                SettingsCountingUnattendedTimeHistogramComponent.numberOfBinsDefault,
                SettingsCountingUnattendedTimeHistogramComponent.minimumValueDefault
            );
        }
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this._histogramConfig) ? false :
            this.register.propertyHasChanges('binWidth') ||
            this.register.propertyHasChanges('numberOfBins') ||
            this.register.propertyHasChanges('minimumValue') ||
            this.register.propertyHasChanges('expression');
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    protected offline(): void {
        super.offline();
        this.form.disable();
    }

    protected online(): void {
        super.online();
        this.form.enable();
    }

    protected setFormValues(binWidthDefault: number, numberOfBinsDefault: number, minimumValueDefault: number): void {
        this.form.controls.binWidth.setValue(this.isNullOrUndefined(this._histogramConfig.binWidth) ? binWidthDefault / 10 : this._histogramConfig.binWidth / 10, { emitEvent: false });
        this.form.controls.numberOfBins.setValue(this.isNullOrUndefined(this._histogramConfig.numberOfBins) ? numberOfBinsDefault : this._histogramConfig.numberOfBins, { emitEvent: false });
        this.form.controls.minimumValue.setValue(this.isNullOrUndefined(this._histogramConfig.minimumValue) ? minimumValueDefault : this._histogramConfig.minimumValue / 10, { emitEvent: false });
    }
}
