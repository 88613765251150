import { Component, HostBinding, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
    SettingsConnectionForwardingAddEditComponent,
    SettingsConnectionForwardingAddEditData,
    SettingsConnectionForwardingAddEditResult,
} from '@em/components/settings/connectionforwarding/addedit/Settings.ConnectionForwarding.AddEdit.Component';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { RoutingRuleModel } from '@em/models/restapi/RoutingRule.Model';
import { RoutingRuleCollectionModel } from '@em/models/restapi/RoutingRuleCollection.Model';
import { GroupService } from '@em/service/data/group/Group.Service';
import { RoutingRuleService } from '@em/service/data/routingrule/RoutingRule.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { EventsService } from '@shared/service/events/Events.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';


@Component({
    selector: 'em-settings-connection-forwarding',
    templateUrl: './Settings.ConnectionForwarding.Component.html',
    styleUrls: ['./Settings.ConnectionForwarding.Component.scss']
})
export class SettingsConnectionForwardingComponent extends BaseComponent implements OnDeactivate, OnInit, OnDestroy, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsConnectionForwardingComponent';

    public addDeviceAction: NavBarAction;
    public addDeviceActionClickedProcess: ProcessMonitorServiceProcess;
    public addGlobalAction: NavBarAction;

    public addGlobalActionClickedProcess: ProcessMonitorServiceProcess;
    public addGroupAction: NavBarAction;
    public addGroupActionClickedProcess: ProcessMonitorServiceProcess;
    public dataSource = new MatTableDataSource();
    public displayedColumns = ['operateOn', 'destinations', 'retryPeriod'];

    @HostBinding()
    public id: string = 'em-settings-connection-forwarding';
    public routingRules: RoutingRuleCollectionModel;

    private _flatGroups: Array<GroupModel>;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _eventsService: EventsService,
        private readonly _formBuilder: FormBuilder,
        private readonly _groupService: GroupService,
        private readonly _navBarService: NavBarActionService,
        private readonly _routingRuleService: RoutingRuleService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsConnectionForwardingComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsConnectionForwardingComponent.className, this.saveAllChangesProcessText);
        this.addGlobalActionClickedProcess = this.processMonitorService.getProcess(SettingsConnectionForwardingComponent.className, 'Add global rule clicked');
        this.addGroupActionClickedProcess = this.processMonitorService.getProcess(SettingsConnectionForwardingComponent.className, 'Add group rule clicked');
        this.addDeviceActionClickedProcess = this.processMonitorService.getProcess(SettingsConnectionForwardingComponent.className, 'Add device rule clicked');

        this.addGlobalAction = new NavBarAction();
        this.addGlobalAction.name = 'addglobalrule';
        this.addGlobalAction.text = 'Add Global Rule';
        this.addSubscription(this.observableHandlerBase(this.addGlobalAction.onButtonClick, this.addGlobalActionClickedProcess).subscribe(() => { this.addGlobal(); }), this.addGlobalActionClickedProcess);

        this.addGroupAction = new NavBarAction();
        this.addGroupAction.name = 'addgrouprule';
        this.addGroupAction.text = 'Add Group Rule';
        this.addSubscription(this.observableHandlerBase(this.addGroupAction.onButtonClick, this.addGroupActionClickedProcess).subscribe(() => { this.addGroup(); }), this.addGroupActionClickedProcess);

        this.addDeviceAction = new NavBarAction();
        this.addDeviceAction.name = 'adddevicerule';
        this.addDeviceAction.text = 'Add Device Rule';
        this.addSubscription(this.observableHandlerBase(this.addDeviceAction.onButtonClick, this.addDeviceActionClickedProcess).subscribe(() => { this.addDevice(); }), this.addDeviceActionClickedProcess);

        this.addSaveAllAction(this);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public addDevice(): void {
        const newRule = new RoutingRuleModel();
        newRule.isDeviceRule = true;
        this.addRule(newRule);
        this.setGlogalActionDisabledState();
        this.updateSaveAllAction(this);
    }

    public addGlobal(): void {
        const newRule = new RoutingRuleModel();
        newRule.isGlobalRule = true;
        this.addRule(newRule);
    }

    public addGroup(): void {
        const newRule = new RoutingRuleModel();
        newRule.isGroupRule = true;
        this.addRule(newRule);
    }

    public addRule(rule: RoutingRuleModel): void {
        rule.id = -1;

        this.addSubscription(this._dialog.open(SettingsConnectionForwardingAddEditComponent, { data: new SettingsConnectionForwardingAddEditData(rule, this.routingRules), disableClose: true }).afterClosed().subscribe(
            (result: SettingsConnectionForwardingAddEditResult) => {
                if (!this.isNullOrUndefined(result) && !this.isNullOrUndefined(result.rule)) {
                    this.routingRules.items.push(result.rule);
                    this.dataSource.data = this.routingRules.items;

                    this.setGlogalActionDisabledState();
                    this.updateSaveAllAction(this);
                }
            }
        ));
    }

    public applyFilter(filterValue: string) {
        if (!this.isNullOrUndefined(this.dataSource.data) && this.dataSource.data.length > 0) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            this.dataSource.filter = filterValue;
        }
    }

    public dataSorceFilter(data: RoutingRuleModel, filter: string): boolean {
        return (StringUtility.toString(data.retryPeriod).toLocaleLowerCase().indexOf(filter) === 0) ||
            (data.isGlobalRule === true && 'all devices (global)'.indexOf(filter) === 0) ||
            (data.isDeviceRule === true && data.friendlySerialNumber.toLocaleLowerCase().indexOf(filter) === 0) ||
            (data.isGroupRule === true && this.getGroupName(data.groupId).toLocaleLowerCase().indexOf(filter) === 0) ||
            data.destinations.some(d => d.iPAddress.indexOf(filter) === 0 || StringUtility.toString(d.port).toLocaleLowerCase().indexOf(filter) === 0);
    }

    public delete(rule: RoutingRuleModel): void {
        const index = this.routingRules.items.findIndex(i => i.uniqueId === rule.uniqueId);
        if (!this.isNullOrUndefined(index) && index !== -1) {
            this.routingRules.items.splice(index, 1);
            this.dataSource.data = this.routingRules.items;
            this.setGlogalActionDisabledState();
            this.updateSaveAllAction(this);
        }
    }

    public edit(rule: RoutingRuleModel): void {
        this.addSubscription(this._dialog.open(SettingsConnectionForwardingAddEditComponent, { data: new SettingsConnectionForwardingAddEditData(rule, this.routingRules), disableClose: true }).afterClosed().subscribe(
            (result: SettingsConnectionForwardingAddEditResult) => {
                if (!this.isNullOrUndefined(result) && !this.isNullOrUndefined(result.rule)) {
                    this.setGlogalActionDisabledState();
                    this.updateSaveAllAction(this);
                } else {
                    rule.clearChanges();
                }
            }
        ));
    }

    public getGroup(groupId: number): GroupModel {
        if (!this.isNullOrUndefined(this._flatGroups)) {
            const group = this._flatGroups.find(i => i.id === groupId);
            if (!this.isNullOrUndefined(group)) {
                return group;
            }
        }
    }

    public getGroupName(groupId: number): string {
        const group = this.getGroup(groupId);
        if (!this.isNullOrUndefined(group)) {
            return group.name;
        }
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._groupService.getNested(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this._flatGroups = ArrayUtility.flatten([], result);
                    }
                    return true;
                })
            ),
            this._routingRuleService.get(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.routingRules = result;
                        this.changeTracker.track(this.routingRules);
                        this.setGlogalActionDisabledState();
                        this.dataSource.data = this.routingRules.items;
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this._navBarService.removeAction(this.addGlobalAction);
        this._navBarService.removeAction(this.addGroupAction);
        this._navBarService.removeAction(this.addDeviceAction);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this._navBarService.addAction(this.addGlobalAction);
        this._navBarService.addAction(this.addGroupAction);
        this._navBarService.addAction(this.addDeviceAction);

        this.dataSource.filterPredicate = this.dataSorceFilter.bind(this);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            this._routingRuleService.saveAllChanges(this.routingRules, process).pipe(
                map(() => true)
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(saveAllChangesResult => {
                if (this.isZipResultSuccess(saveAllChangesResult)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process).pipe(
                        tap(() => {
                            this._eventsService.changedConnectionForwarding();
                        })
                    );
                } else {
                    return of(saveAllChangesResult);
                }
            })
        );
    }

    public setGlogalActionDisabledState(): void {
        this.addGlobalAction.disabled = this.routingRules.items.some(i => i.isGlobalRule === true);
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._routingRuleService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }
}
