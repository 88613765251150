<div #container
    *ngIf="show === true && canShow === true"
    class="float-dialog rift-settings-counting-registers"
    (click)="onMenuClick()"
    ngResizable
    #resizable="ngResizable"
    (rzStop)="onRzStop($event)"
    (rzStart)="onRzStart($event)"
    (rzResizing)="onRzResizing($event)"
    [rzMinHeight]="minHeight"
    [rzMinWidth]="minWidth"
    [rzMinWidth]="minWidth"
    [rzMinHeight]="minHeight"
    [rzMaxWidth]="maxWidth"
    [rzMaxHeight]="maxHeight"
    ngDraggable
    #draggable="ngDraggable"
    (started)="onDrStarted($event)"
    (stopped)="onDrStopped($event)"
    (edge)="onDrEdge($event)"
    (movingOffset)="onDrMovingOffset($event)"
    (endOffset)="onDrEndOffset($event)"
    [handle]="title"
    [bounds]="bounds"
    [position]="position"
    [inBounds]="!isNullOrUndefined(bounds)">
    <div #title
        class="title drag-handle"
        fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex
            fxLayout="row"
            fxLayoutAlign="start center">
            <div fxLayout="column">
                <div class="header">
                    Registers
                </div>
            </div>
            <div fxFlex></div>
            <div class="actions">
                <button class="flat-icon-button"
                    mat-stroked-button
                    matTooltip="Show All Register"
                    (click)="onShowAllRegistersClick()">
                    <shr-fa name="eye"></shr-fa>
                </button>
                <button class="flat-icon-button"
                    mat-stroked-button
                    matTooltip="Hide All Register"
                    (click)="onHideAllRegistersClick()">
                    <shr-fa name="eye-slash"></shr-fa>
                </button>
                <button *ngIf="isReadOnly === false"
                    [disabled]="addRegisterDisabled"
                    class="flat-icon-button"
                    mat-stroked-button
                    matTooltip="Add Register"
                    (click)="onAddRegisterClick()">
                    <mat-icon>add</mat-icon>
                </button>
                <button class="flat-icon-button"
                    [disabled]="saveAllChangesProcess.isRunning === true"
                    mat-stroked-button
                    matTooltip="Close"
                    (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="content"
        fxLayout="column">
        <div #resetCount
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <mat-form-field class="count-select-form-field">
                <mat-select [disabled]="isReadOnly === true"
                    #countTypeSelect
                    [value]="CountTypeEnum.user">
                    <mat-option [value]="CountTypeEnum.user">
                        User
                    </mat-option>
                    <mat-option [value]="CountTypeEnum.system">
                        System
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button [disabled]="isReadOnly === true"
                (click)="onResetCountsClick(countTypeSelect.value)"
                mat-button>Reset
                Counts</button>
        </div>
        <div #registerList
            class="register-list"
            [ngStyle]="{'height': registerListHeight + 'px'}">
            <div *ngFor="let vm of registerVMs | orderBy: 'register.registerIndex'"
                class="register"
                fxLayout="row"
                fxLayoutAlign="start center"
                [ngStyle]="{'border-left-color': vm.register.fillColor}"
                (click)="onRegisterSelectedClick(vm, $event)"
                [class.selected]="vm.selected === true">
                <div class="name-count"
                    fxLayout="column"
                    [ngStyle]="{'color': vm.register.isViewPortVisibleInViewPort === true ? (vm.visible === false ? '#909090' : '#000000') : '#000000' }">
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        <rift-register-icon-name [registerBase]="vm.register"></rift-register-icon-name>
                        <mat-icon class="edit-icon"
                            *ngIf="!isNullOrUndefined(editingRegister) && RegisterBaseModel.equal(editingRegister, vm.register)">
                            edit</mat-icon>
                    </div>
                    <div *ngIf="connectionService.isOnline === true"
                        class="count"
                        [ngStyle]="{'font-size': countFontSize + 'px'}">
                        {{ counts.getCount(countTypeSelect.value, vm.register) }}
                    </div>
                    <div *ngIf="connectionService.isOffline === true"
                        class="count-db">
                        Database view no counts available
                    </div>
                </div>
                <div fxFlex></div>
                <div class="actions">
                    <button *ngIf="vm.register.histogramConfigs?.length > 0"
                        (click)="onShowHideHistogramClick(vm, $event)"
                        class="flat-icon-button"
                        mat-stroked-button
                        matTooltip="Show / Hide Histogram">
                        <mat-icon>show_chart</mat-icon>
                    </button>
                    <button *ngIf="vm.register.isViewPortVisibleInViewPort"
                        (click)="onShowHideRegisterClick(vm, $event)"
                        class="flat-icon-button"
                        mat-stroked-button
                        [matTooltip]="vm.visible === false ? 'Show Register' : 'Hide Register'">
                        <shr-fa [name]="vm.visible === true ? 'eye' : 'eye-slash'"></shr-fa>
                    </button>
                    <button *ngIf="vm.canEdit === true"
                        (click)="onEditRegisterClick(vm, $event)"
                        [disabled]="editRegisterDisabled"
                        class="flat-icon-button"
                        mat-stroked-button
                        matTooltip="Edit Register">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>