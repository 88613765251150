import { DeviceTimeZoneInfoModel } from '@em/models/restapi/DeviceTimeZoneInfo.Model';
import { ExtendedDataModel } from '@em/models/restapi/ExtendedData.Model';
import { MetaDataMapModel } from '@em/models/restapi/MetaDataMap.Model';
import { RegisterModel } from '@em/models/restapi/Register.Model';
import { SettingModel } from '@em/models/restapi/Setting.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { DeviceCapabilitiesEnum, DeviceCapabilitiesEnumHelpers } from '@shared/enum/DeviceCapabilities.Enum';
import { DeviceStateEnum } from '@shared/enum/DeviceState.Enum';
import { DeviceTypeEnum } from '@shared/enum/DeviceType.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isDate, isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { DeviceInfo } from '@shared/component/deviceinfotooltip/DeviceInfoTooltip.Component';


export class DeviceModel extends BaseModel implements IRestModel {
    public capabilities: Array<DeviceCapabilitiesEnum> = null;

    public dateAdded: Date = null;
    public deviceId: string = null;
    public deviceName: string = null;
    public deviceStorageCapacityKB: number = null;
    public deviceStorageFreeCapacityKB: number = null;
    public deviceTimeZoneInfo: DeviceTimeZoneInfoModel = null;
    public errorCount: number = null;
    public extendedData: Array<ExtendedDataModel> = null;
    public firmwareVersion: string = null;
    public friendlySerial: string = null;
    public iPAddress: string = null;
    public isConnected: boolean = null;
    public isLicensed: boolean = null;
    public isVideoCapable: number = null;
    public lastConnected: Date = null;
    public lastSeenText: string = null;
    public latitude: number = null;
    public location: string = null;
    public longitude: number = null;
    public mACAddress: string = null;
    public master: DeviceModel = null;
    public metaData: Array<MetaDataMapModel> = null;
    public nodeCount: number = null;
    public nodes: Array<DeviceModel> = null;
    public readonly isIRestModel = true;
    public registers: Array<RegisterModel> = null;
    public remoteEndPointAddress: string = null;
    public serial: number = null;
    public settings: Array<SettingModel> = null;
    public siteId: string = null;
    public siteName: string = null;
    public state: DeviceStateEnum = null;
    public toolCount: number = null;
    public type: DeviceTypeEnum = null;
    public upTime: number = null;
    public userString: string = null;
    public warningCount: number = null;

    public _productID: string = null;

    public constructor() {
        super();
    }

    public get productID(): string {
        return this._productID;
    }

    public get deviceInfo(): DeviceInfo {
        return {
            deviceName: this.deviceName,
            deviceId: this.deviceId,
            siteId: this.siteId,
            siteName: this.siteName,
            firmwareVersion: this.firmwareVersion,
            iPAddress: this.iPAddress,
            nodeCount: this.nodeCount,
            friendlySerial: this.friendlySerial,
        };
    }

    public getMetaDataValue(id: number): string {
        if (!isNullOrUndefined(this.metaData) && this.metaData.length > 0) {
            const map = this.metaData.find(thinMetaDataMap => thinMetaDataMap.metaDataKey.metaDataKeyId === id);
            if (!isNullOrUndefined(map) && !isNullOrUndefined(map.value)) {
                return map.value;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public hasCapability(capability: DeviceCapabilitiesEnum): boolean {
        const result = this.capabilities.some(i => i === capability);
        return result;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-DeviceModel', ['']);

        this.dateAdded = DateTimeNonMomentUtility.fromRestApiDateTime(this.dateAdded);
        this.lastConnected = DateTimeNonMomentUtility.fromRestApiDateTime(this.lastConnected);

        this.deviceTimeZoneInfo = new DeviceTimeZoneInfoModel();
        this.deviceTimeZoneInfo.loadFromRestApiModel(restModel.DeviceTimeZoneInfo);

        this.capabilities = [];
        if (!isNullOrUndefined(restModel.Capabilities)) {
            this.capabilities = restModel.Capabilities.map(c => DeviceCapabilitiesEnumHelpers.fromRestApi(c.Name));
        }

        this.metaData = RestModelUtility.loadFromArray<MetaDataMapModel>(restModel.MetaData, MetaDataMapModel);
        this.registers = RestModelUtility.loadFromArray<RegisterModel>(restModel.Registers, RegisterModel);
        this.settings = RestModelUtility.loadFromArray<SettingModel>(restModel.Settings, SettingModel);
        this.extendedData = RestModelUtility.loadFromArray<ExtendedDataModel>(restModel.ExtendedData, ExtendedDataModel);

        if (!isNullOrUndefined(this.lastConnected) && isDate(this.lastConnected)) {
            if (this.lastConnected.getFullYear() < 2000) {
                this.lastSeenText = 'Never';
            } else if (DateTimeUtility.add(this.lastConnected, 1, 'minutes') > new Date()) {
                if (this.isConnected) {
                    this.lastSeenText = 'Connected';
                } else {
                    this.lastSeenText = DateTimeUtility.nowToRelativeDuration(this.lastConnected);
                }
            } else {
                this.lastSeenText = DateTimeUtility.nowToRelativeDuration(this.lastConnected);
            }
        } else {
            this.lastSeenText = 'Never';
        }

        this.setProductID();
    }

    public toRestApiModel(): any {
        throw new Error('Method not implemented.');
    }

    private setProductID(): void {
        if (!isNullOrUndefined(this.settings)) {
            const productIDSetting = this.settings.find(s => s.iD === '/config/GeneralSection/ProductID');
            if (!isNullOrUndefined(productIDSetting)) {
                this._productID = productIDSetting.value;
            }
        }
        this._productID = '';
    }
}
