<div [ngStyle]="{'visibility': visible === true ? 'visible' : 'hidden' }"
    [ngClass]="stateStyle"
    class="shr-bottom-info"
    fxLayout="column"
    fxFlex="none">
    <div fxFlex="none"
        fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex>Notifications ({{ notificationCount | async }})</div>
        <button mat-icon-button
            (click)="collapsed = !collapsed"
            [matTooltip]="collapsed === false ? 'Collapse' : 'Expand'">
            <mat-icon class="expand-collapse">{{ collapsed === false ? 'expand_more' : 'expand_less'}}</mat-icon>
        </button>
    </div>
    <div [style.display]="collapsed === true ? 'none' : 'inherit'"
        fxLayout="column" fxFlex="none">
        <ng-template #childHost></ng-template>
    </div>
</div>