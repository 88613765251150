<div class="em-addressbookselect-title"
    mat-dialog-title>
    Please Select An Address Book Group
</div>
<div class="em-addressbookselect-content"
    mat-dialog-content>
    <em-address-book-tree [badgeHidden]="true"
        [showRoot]="true"
        [selectedGroup]="selectedGroup"
        (itemClicked)="groupSelected($event)"
        [root]="rootTreeGroup"></em-address-book-tree>
</div>
<div class="em-myaccount-editdetails-actions"
    mat-dialog-actions>
    <button [disabled]="isNullOrUndefined(selectedGroup) || selectedGroup.noEdit === true"
        (click)="select()"
        mat-raised-button>Select</button>
    <div fxFlex></div>
    <button (click)="cancel()"
        mat-raised-button
        color="warn">Cancel</button>
</div>