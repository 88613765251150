import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class ChangeUserPasswordModel extends BaseModel implements IRestModel {
    public newPassword: string = '';
    public oldPassword: string = '';
    public readonly isIRestModel = true;

    public constructor() {
        super();

        this.registerProperty('newPassword');
        this.registerProperty('oldPassword');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'SHR-ChangeUserPasswordModel');

        this.setPropertyOriginalValue('newPassword', this.newPassword);
        this.setPropertyOriginalValue('oldPassword', this.oldPassword);
    }

    public toRestApiModel() {
        return {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
        };
    }
}
