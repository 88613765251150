import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface IVideoModel {
    data: string;
}

export class VideoModel extends BaseModel implements IVideoModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'video_case';

    public isIWebSocketModel: boolean = true;
    public data: string = null;
    public device: string = null;
    public id: number = null;
    public packetType: string = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-VideoModel');
    }

    public toWebSocketMessage(): any {
        return {
            ...WebSocketModelUtility.toJson(this),
        };
    }
}
