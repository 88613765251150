import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';

export class LicenceItemModel extends BaseModel implements IRestModel {
    public capacity: number = null;
    public expiry: Date = null;
    public key: string = null;
    public readonly isIRestModel = true;

    private _expiryText: string = null;

    public get expiryText(): string {
        return this._expiryText;
    }

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-LicenceItemModel');

        if (isNullOrUndefined(this._expiryText) || DateTimeUtility.isInvalidDate(this._expiryText)) {
            this._expiryText = DateTimeUtility.toShortDate(this.expiry);
        }
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
