import { Component, ElementRef, HostBinding, Injector, OnInit, ViewChild } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { DeviceErrorModel } from '@rift/models/restapi/DeviceError.Model';
import { ErrorsAndWarningsModel } from '@rift/models/restapi/ErrorsAndWarnings.Model';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ConnectionStatusEnum } from '@shared/enum/ConnectionStatus.Enum';
import { DeviceErrorClassEnum } from '@shared/enum/DeviceErrorClass.Enum';
import { DiagnosticsLogRangeEnum } from '@shared/enum/DiagnosticsLogRange.Enum';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { ILoadDate } from '@shared/interface/ILoadData';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { FileUtility } from '@shared/utility/File.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import * as FileSaver from 'file-saver';
import { EMPTY, Observable, of, timer, zip } from 'rxjs';
import { expand, flatMap, map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

class DeviceLogs {
    public device: DeviceModel;
    public historicalLogs: Array<DeviceErrorModel> = [];

    public constructor(device: DeviceModel) {
        this.device = device;
    }
}

class GlobalLog extends DeviceErrorModel {
    public linksToDevicesFriendlySerialNumber: Array<string>;
}

@Component({
    selector: 'rift-diagnostics',
    templateUrl: './Diagnostics.Component.html',
    styleUrls: ['./Diagnostics.Component.scss']
})
export class DiagnosticsComponent extends RiftBaseComponent implements IFillHeight, OnInit, ILoadDate {
    public static className: string = 'DiagnosticsComponent';

    @HostBinding()
    public id: string = 'rift-diagnostics';

    public getLogFileProcess: ProcessMonitorServiceProcess;
    public getHistoricalLogsProcess: ProcessMonitorServiceProcess;
    public getGlobalLogsProcess: ProcessMonitorServiceProcess;
    public resetLogProcess: ProcessMonitorServiceProcess;
    public globalPollProcess: ProcessMonitorServiceProcess;

    public readonly DateTimeUtility = DateTimeUtility;

    public nodes: Array<DeviceModel>;
    public master: DeviceModel;
    public deviceLogs: Array<DeviceLogs> = [];
    public globalLogs: Array<GlobalLog> = [];
    public selectedDeviceLogs: DeviceLogs;
    public timeSetup: TimeSetupModel;
    public selectedDevice: DeviceModel;
    public historicalDataSource: MatTableDataSource<DeviceErrorModel> = new MatTableDataSource<DeviceErrorModel>();
    public globalDataSource: MatTableDataSource<GlobalLog> = new MatTableDataSource<GlobalLog>();
    public displayedColumnsGlobal: Array<string> = ['class', 'friendlySerialNumber', 'typeText', 'message'];
    public displayedColumns: Array<string> = ['class', 'shortDateTimeText', 'typeText', 'message'];
    public DeviceErrorClassEnum = DeviceErrorClassEnum;

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    private _cancel: boolean = false;
    private _LastState: ConnectionStatusEnum = null;
    private _paused: boolean = false;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(DiagnosticsComponent.className, this.loadDataProcessText);
        this.getLogFileProcess = this.processMonitorService.getProcess(DiagnosticsComponent.className, 'Getting log file data');
        this.getHistoricalLogsProcess = this.processMonitorService.getProcess(DiagnosticsComponent.className, 'Getting historical log data');
        this.getGlobalLogsProcess = this.processMonitorService.getProcess(DiagnosticsComponent.className, 'Getting global log data');
        this.resetLogProcess = this.processMonitorService.getProcess(DiagnosticsComponent.className, 'Resetting log data');
        this.globalPollProcess = this.processMonitorService.getProcess(DiagnosticsComponent.className, 'Global polling');

        this.initConnectionState();
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public deviceSerialSelected(friendlySerialNumber: string): void {
        if (this.master.serialNumber === friendlySerialNumber) {
            this.deviceSelected(this.master);
        } else {
            this.deviceSelected(this.nodes.find(i => i.serialNumber === friendlySerialNumber));
        }
    }

    public deviceSelected(device: DeviceModel): void {
        if (this.isNullOrUndefined(this.selectedDeviceLogs) || device.serialNumber !== this.selectedDeviceLogs.device.serialNumber) {
            let deviceLogs = this.deviceLogs.find(i => i.device.serialNumber === device.serialNumber);

            this.historicalDataSource.data = [];

            if (this.isNullOrUndefined(deviceLogs)) {
                deviceLogs = new DeviceLogs(device);
                this.deviceLogs.push(deviceLogs);
                this.getLogs(deviceLogs);
            }

            this.historicalDataSource.data = deviceLogs.historicalLogs;

            this.selectedDevice = device;
            this.selectedDeviceLogs = deviceLogs;
        }
    }

    public applyFilter(filterValue: string, dataSource: MatTableDataSource<any>) {
        if (!this.isNullOrUndefined(dataSource.data) && dataSource.data.length > 0) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            dataSource.filter = filterValue;
        }
    }

    public resetLogs(): void {
        this._paused = true;
        this.addSubscription(this.observableHandlerBase(this.deviceService.resetLogs(this.getLogFileProcess), this.resetLogProcess).subscribe(
            result => {
                const oldDevice = this.selectedDevice;
                this._paused = false;
                this._cancel = true;
                this.deviceLogs = [];
                this.globalLogs = [];
                this.historicalDataSource.data = [];
                this.globalDataSource.data = [];
                this.selectedDeviceLogs = null;
                this.deviceSelected(oldDevice);
                this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
            }
        ), this.resetLogProcess);
    }

    public downloadLogs(): void {
        this._paused = true;
        this.addSubscription(this.observableHandlerBase(this.globalService.getLogFile(this.getLogFileProcess), this.getLogFileProcess).subscribe(
            result => {
                const blob = new Blob([result.data], { type: 'text/plain;charset=utf-8' });
                FileSaver.saveAs(blob, FileUtility.sanitize(`${this.master.serialNumber}_${DateTimeUtility.format(new Date(), 'yyyyMMdd')}.iew`));
                this._paused = false;
            }
        ), this.getLogFileProcess);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.deviceService.getNodeDevices(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.nodes = result;
                    }
                    return true;
                })
            ),
            this.getHostDevice(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.master = result;
                    }
                    return true;
                })
            ),
            this.globalService.getTimeSetup(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.timeSetup = result;
                    }
                    return true;
                })
            ),
        ).pipe(
            map(result => {
                if (this.isZipResultSuccess(result) === true) {
                    this.deviceSelected(this.isNullOrUndefined(this.selectedDevice) ? this.master : this.selectedDevice);
                    const timerSub = this.addSubscription(this.observableHandlerBase(timer(20000, 20000), this.globalPollProcess).subscribe(() => {
                        this.addSubscription(this.observableHandlerBase(this.globalRecursive(), this.getGlobalLogsProcess).subscribe());
                    }), this.globalPollProcess);
                }
                return result;
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
        this.deviceLogs = [];
        this.nodes = null;
        this.master = null;
        this.deviceLogs = [];
        this.selectedDeviceLogs = null;
        this.timeSetup = null;
    }

    protected online(): void {
        super.online();
        if (this._LastState !== null && this._LastState !== ConnectionStatusEnum.online) {
            this._cancel = true;
            this.deviceLogs = [];
            this.globalLogs = [];
            this.historicalDataSource.data = [];
            this.globalDataSource.data = [];
            this.selectedDeviceLogs = null;
        } else {
            this._cancel = false;
        }
        this._LastState = ConnectionStatusEnum.online;
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected offline(): void {
        super.offline();
        if (this._LastState !== null && this._LastState !== ConnectionStatusEnum.offline) {
            this._cancel = true;
            this.deviceLogs = [];
            this.globalLogs = [];
            this.historicalDataSource.data = [];
            this.globalDataSource.data = [];
            this.selectedDeviceLogs = null;
        } else {
            this._cancel = false;
        }
        this._LastState = ConnectionStatusEnum.offline;
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    private errorsInit(errorsAndWarnings: ErrorsAndWarningsModel): void {
        if (!this.isNullOrUndefined(errorsAndWarnings.errors) && !this.isNullOrUndefined(this.timeSetup)) {
            const errorLength = errorsAndWarnings.errors.length;
            for (let errorIndex = 0; errorIndex < errorLength; errorIndex++) {
                const error = errorsAndWarnings.errors[errorIndex];
                error.setTextDates(this.timeSetup.timeZone);
                error.class = DeviceErrorClassEnum.error;
            }
        }
    }

    private warningsInit(errorsAndWarnings: ErrorsAndWarningsModel): void {
        if (!this.isNullOrUndefined(errorsAndWarnings.warnings) && !this.isNullOrUndefined(this.timeSetup)) {
            const warningLength = errorsAndWarnings.warnings.length;
            for (let warningIndex = 0; warningIndex < warningLength; warningIndex++) {
                const warning = errorsAndWarnings.warnings[warningIndex];
                warning.setTextDates(this.timeSetup.timeZone);
                warning.class = DeviceErrorClassEnum.warning;
            }
        }
    }

    private informationsInit(errorsAndWarnings: ErrorsAndWarningsModel): void {
        if (!this.isNullOrUndefined(errorsAndWarnings.information) && !this.isNullOrUndefined(this.timeSetup)) {
            const informationLength = errorsAndWarnings.information.length;
            for (let informationIndex = 0; informationIndex < informationLength; informationIndex++) {
                const information = errorsAndWarnings.information[informationIndex];
                information.setTextDates(this.timeSetup.timeZone);
                information.class = DeviceErrorClassEnum.information;
            }
        }
    }

    private getLogs(deviceLogs: DeviceLogs): void {
        this._cancel = false;
        this.addSubscription(this.observableHandlerBase(this.globalRecursive(), this.getGlobalLogsProcess).subscribe());

        this.addSubscription(this.observableHandlerBase(this.historicalRecursive(deviceLogs, null).pipe(
            expand((nextIndex) => {
                if (!isNullOrUndefined(nextIndex)) {
                    return this.waitForUnPaused().pipe(flatMap(() => this.historicalRecursive(deviceLogs, nextIndex)));
                }
                return EMPTY;
            })
        ), this.getHistoricalLogsProcess).subscribe());
    }

    private waitForUnPaused(): Observable<boolean> {
        if (this._paused === true) {
            return timer(1000).pipe(flatMap(() => this.waitForUnPaused()));
        } else {
            return of(true);
        }
    }

    private historicalRecursive(deviceLogs: DeviceLogs, nextIndex: number): Observable<number> {
        return this.deviceService.getDiagnosticsByRange(deviceLogs.device.serialNumber, 100, nextIndex, DiagnosticsLogRangeEnum.all, true, this.getHistoricalLogsProcess).pipe(
            map(result => {
                if (this._cancel === false) {
                    this.errorsInit(result);
                    this.warningsInit(result);
                    this.informationsInit(result);

                    deviceLogs.historicalLogs.push(...result.errors);
                    deviceLogs.historicalLogs.push(...result.warnings);
                    deviceLogs.historicalLogs.push(...result.information);

                    deviceLogs.historicalLogs.sort((a, b) => b.id - a.id);

                    if (this.selectedDeviceLogs.device.serialNumber === deviceLogs.device.serialNumber) {
                        this.historicalDataSource.data = deviceLogs.historicalLogs;
                    }

                    if (result.nextIndex !== -1) {
                        return result.nextIndex;
                    }
                }
                return null;
            })
        );
    }

    private globalRecursive(): Observable<ErrorsAndWarningsModel> {
        return this.globalService.getDiagnostics(this.getGlobalLogsProcess).pipe(
            flatMap(result => {
                if (this._cancel === false) {
                    this.globalLogs = [];

                    this.errorsInit(result);
                    this.warningsInit(result);
                    this.informationsInit(result);

                    this.globalLogs.push(...result.errors as Array<GlobalLog>);
                    this.globalLogs.push(...result.warnings as Array<GlobalLog>);
                    this.globalLogs.push(...result.information as Array<GlobalLog>);

                    const removeSerials: number[] = [];
                    this.globalLogs.forEach(log => {
                        if (log.friendlySerialNumber === this.master.serialNumber && log.linksToDevices.length > 0) {
                            removeSerials.push(...log.linksToDevices);
                            log.linksToDevicesFriendlySerialNumber = [];
                            log.linksToDevicesFriendlySerialNumber.push(...log.linksToDevices.map(i => this.globalLogs.find(gl => gl.serialNumber === i).friendlySerialNumber));
                        }
                    });

                    this.globalLogs = this.globalLogs.filter(log => removeSerials.indexOf(log.serialNumber) === -1);

                    this.globalLogs.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf());

                    this.globalDataSource.data = this.globalLogs;
                }
                return of(null);
            })
        );
    }
}
