import { Component, Inject, Injector, OnInit, ViewChild, HostBinding, ViewEncapsulation } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { IViewModel } from '@shared/interface/IViewModel';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, timer, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { FileUtility } from '@shared/utility/File.Utility';
import * as FileSaver from 'file-saver';


export class DiagnosticsPackageDialogData {

}

export class DiagnosticsPackageDialogResult {

}

class DiagnosticsPackageViewModel implements IViewModel {
    public item: string;

    public constructor(diagnosticsPackage: string) {
        this.item = diagnosticsPackage;
    }
}

@Component({
    selector: 'rift-diagnostics-package',
    templateUrl: './DiagnosticsPackage.Component.html',
    styleUrls: ['./DiagnosticsPackage.Component.scss']
})
export class DiagnosticsPackageComponent extends RiftBaseComponent implements OnInit, ILoadDate {
    public downloadProcess: ProcessMonitorServiceProcess;
    public deleteProcess: ProcessMonitorServiceProcess;
    public createProcess: ProcessMonitorServiceProcess;
    public listPackagesProcess: ProcessMonitorServiceProcess;
    public deviceTabs: Array<string> = [];
    public currentTabIndex: number = 0;
    public creatingPackage: boolean = false;

    public displayedColumns = ['packageName'];
    public dataSource: MatTableDataSource<DiagnosticsPackageViewModel> = new MatTableDataSource<DiagnosticsPackageViewModel>();

    @HostBinding()
    public id: string = 'rift-diagnostics-package';

    public constructor(
        private readonly _eventsService: EventsService,
        @Inject(MAT_DIALOG_DATA) private readonly _data: DiagnosticsPackageDialogData,
        private readonly _dialog: MatDialog,
        private readonly _dialogRef: MatDialogRef<DiagnosticsPackageComponent>,
        private readonly _deviceService: DeviceService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess('DiagnosticsPackageComponent', this.loadDataProcessText);
        this.downloadProcess = this.processMonitorService.getProcess('DiagnosticsPackageComponent', 'Downloading Diagnostics Package.');
        this.deleteProcess = this.processMonitorService.getProcess('DiagnosticsPackageComponent', 'Delete Diagnostics Package.');
        this.createProcess = this.processMonitorService.getProcess('DiagnosticsPackageComponent', 'Create Diagnostics Package.');
        this.listPackagesProcess = this.processMonitorService.getProcess('DiagnosticsPackageComponent', 'List Diagnostics Packages');

        this.initConnectionState();
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public tabIndexChanged(e: number){
        this.currentTabIndex = e;

        this.getDevicePackageLists(this.deviceTabs[this.currentTabIndex]);
    }

    public close(): void {
        this._dialogRef.close(new DiagnosticsPackageDialogResult());
    }

    public download(device: string, vm: DiagnosticsPackageViewModel) {
        this.addSubscription(this.observableHandlerBase(this._deviceService.getDiagnosticsPackage(device, vm.item), this.downloadProcess).subscribe(
            result => {
                const blob = new Blob([result.data], { type: 'octet/stream' });
                FileSaver.saveAs(blob, FileUtility.sanitize(result.fileName));
            }
        ), this.downloadProcess);
    }

    public delete(device: string, vm: DiagnosticsPackageViewModel){
        this.creatingPackage = true;
        this.addSubscription(this.observableHandlerBase(this._deviceService.deleteDiagnosticsPackage(device, vm.item), this.deleteProcess).subscribe(()=> {
            const timerSub = this.addSubscription(this.observableHandlerBase(timer(1, 1000), this.listPackagesProcess).subscribe(()=>{
                this.getDevicePackageLists(this.deviceTabs[this.currentTabIndex]);
                if(this.creatingPackage === false){
                    timerSub.unsubscribe();
                }
            }), this.listPackagesProcess);
        }), this.deleteProcess);
    }

    public create(device: string){
        this.creatingPackage = true;
        this.addSubscription(this.observableHandlerBase(this._deviceService.createDiagnosticsPackage(device), this.createProcess).subscribe(()=> {
            const timerSub = this.addSubscription(this.observableHandlerBase(timer(1, 1000), this.listPackagesProcess).subscribe(()=>{
                this.getDevicePackageLists(this.deviceTabs[this.currentTabIndex]);
                if(this.creatingPackage === false){
                    timerSub.unsubscribe();
                }
            }), this.listPackagesProcess);
        }), this.createProcess);
    }

    public getDevicePackageLists(device: string){
        this.addSubscription(this.observableHandlerBase(this._deviceService.listDiagnosticsPackage(device), this.listPackagesProcess).subscribe((results)=>{
            if(this.creatingPackage === true){
                if(this.dataSource.data.length !== results.length){
                    this.creatingPackage = false;
                }
            }

            this.dataSource.data = [];
            if (!this.isNullOrUndefined(results)) {
                this.dataSource.data = results
                    .map(i => new DiagnosticsPackageViewModel(i));
            }

        }), this.listPackagesProcess);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._deviceService.getDevices(process).pipe(
                flatMap(devices => {
                    devices.items.sort((a,b)=> a.master === true ? -1 : 1).forEach(d=>{
                        this.deviceTabs.push(d.serialNumber);
                    });

                    return this._deviceService.listDiagnosticsPackage(this.deviceTabs[0], process).pipe(
                        map(result => {
                            if (!this.isNullOrUndefined(result)) {
                                this.dataSource.data = [];
                                this.dataSource.data = result
                                    .map(i => new DiagnosticsPackageViewModel(i));
                            }
                            return true;
                        })
                    );
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }
}
