import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';
import { IValidationSyncFrameEntryModel, ValidationSyncFrameEntryModel } from './ValidationSyncFrameEntry.Model';

export interface IValidationSyncFrameEntryCollectionModel {
    syncFrames: Array<IValidationSyncFrameEntryModel>;
}

export class ValidationSyncFrameEntryCollectionModel extends BaseModel implements IValidationSyncFrameEntryCollectionModel, IWebSocketModel {
    public readonly isIWebSocketModel: boolean = true;
    public packetType: string = null;
    public id: number = null;

    public syncFrames: Array<IValidationSyncFrameEntryModel>;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-ValidationSyncFrameEntryCollectionModel');

        this.syncFrames = WebSocketModelUtility.loadFromArray(message, ValidationSyncFrameEntryModel, true);
    }

    public toWebSocketMessage(): void {
        throw new Error('Method not implemented.');
    }
}
