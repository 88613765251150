import { RoleModel } from '@em/models/restapi/Role.Model';
import { UserDeviceDisplayColumnModel } from '@em/models/restapi/UserDeviceDisplayColumn.Model';
import { AuthTypeEnum } from '@shared/enum/AuthType.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { UserBaseModel } from '@shared/models/restapi/UserBase.Model';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';


export class UserModel extends UserBaseModel implements IRestModel {
    public canViewOrphanedDevices: boolean = null;
    public deviceDisplayColumns: Array<UserDeviceDisplayColumnModel> = null;
    public disabled: boolean = false;
    public email: string = null;
    public forcePasswordChange: boolean = null;
    public jobTitle: string = null;
    public lastActivityDate: Date = null;
    public lastLoginDate: Date = null;
    public markForRemoval: boolean = null;
    public organisation: string = null;
    public passwordHash: string = null;
    public readonly isIRestModel = true;
    public realName: string = null;
    public roles: Array<RoleModel> = null;
    public salt: string = null;
    public userId: number = null;
    public username: string = null;
    public webAPIKey: string = null;
    public authType: AuthTypeEnum = null;
    public sub: string = null;
    public iss: string = null;
    public fullyInit: boolean = null;

    private _lastActivityText: string;
    private _roleText: string;

    public constructor() {
        super();

        this.registerProperty('realName');
        this.registerProperty('jobTitle');
        this.registerProperty('organisation');
        this.registerProperty('email');
        this.registerProperty('canViewOrphanedDevices');
    }

    public get lastActivityText(): string {
        return this._lastActivityText;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-UserModel');

        this.lastActivityDate = DateTimeNonMomentUtility.fromRestApiDateTime(this.lastActivityDate);
        this.lastLoginDate = DateTimeNonMomentUtility.fromRestApiDateTime(this.lastLoginDate);

        this.deviceDisplayColumns = RestModelUtility.loadFromArray<UserDeviceDisplayColumnModel>(restModel.DeviceDisplayColumns, UserDeviceDisplayColumnModel);
        this.roles = RestModelUtility.loadFromArray<RoleModel>(restModel.Roles, RoleModel);

        if (!isNullOrUndefined(this.lastActivityDate) && isNullOrUndefined(this._lastActivityText)) {
            if (this.lastActivityDate.getFullYear() < 2000) {
                this._lastActivityText = 'Never logged in';
            } else if (this.lastActivityDate.valueOf() + 180000 > new Date().valueOf()) {
                this._lastActivityText = 'Online Now';
            } else {
                this._lastActivityText = DateTimeUtility.toRelativeDateTime(this.lastActivityDate);
            }

        }

        if (!isNullOrUndefined(this.roles) && isNullOrUndefined(this._roleText)) {
            if (this.disabled === true) {
                this._roleText = 'Disabled';
            } else {
                if (this.roles.length > 1) {
                    this._roleText = this.roles.map(i => i.name).join(', ');
                } else {
                    this._roleText = this.roles[0].name;
                }
            }
        }

        this.setPropertyOriginalValue('realName', this.realName);
        this.setPropertyOriginalValue('jobTitle', this.jobTitle);
        this.setPropertyOriginalValue('organisation', this.organisation);
        this.setPropertyOriginalValue('email', this.email);
        this.setPropertyOriginalValue('canViewOrphanedDevices', this.canViewOrphanedDevices);
    }

    public get role(): RoleModel {
        return isNullOrUndefined(this.roles) ? null : this.roles.length > 0 ? this.roles[0] : null;
    }
    public set role(value: RoleModel) {
        this.roles = [value];
    }

    public get roleText(): string {
        return this._roleText;
    }


    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            Roles: RestModelUtility.toJsonArray(this.roles),
            DeviceDisplayColumns: RestModelUtility.toJsonArray(this.deviceDisplayColumns)
        };
    }
}
