import { Component, EventEmitter, HostBinding, Inject } from '@angular/core';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * Data form PleaseWaitDialogComponent.
 *
 * @export
 * @class PleaseWaitDialogData
 */
export class PleaseWaitDialogData {
    /**
     * Fires when the cancel button is clicked.
     *
     * @type {EventEmitter<void>}
     * @memberof PleaseWaitDialogData
     */
    public cancel: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Creates an instance of PleaseWaitDialogData.
     *
     * @param {string} title The title of the dialog.
     * @param {boolean} [canCancel=false] if true the process can be canceled.
     * @memberof PleaseWaitDialogData
     */
    public constructor(
        public readonly title: string,
        public readonly canCancel: boolean = false) {
    }
}

/**
 * Shows a please wait dialog.
 *
 * @export
 * @class PleaseWaitDialogComponent
 */
@Component({
    selector: 'shr-please-wait-dialog',
    templateUrl: './PleaseWait.Dialog.Component.html',
    styleUrls: ['./PleaseWait.Dialog.Component.scss']
})
export class PleaseWaitDialogComponent {
    public static className: string = 'PleaseWaitDialogComponent';

    public isNullOrUndefined = isNullOrUndefined;
    public data: PleaseWaitDialogData = null;
    public errorMessage: string = null;
    public actionMessage: string = null;

    @HostBinding()
    public id: string = 'shr-please-wait-dialog';

    public constructor(
        @Inject(MAT_DIALOG_DATA) private readonly _data: PleaseWaitDialogData,
        private readonly _dialogRef: MatDialogRef<PleaseWaitDialogComponent>) {

        this._dialogRef.disableClose = true;
        this.data = _data;
    }

    public onCancelClick(): void {
        this.data.cancel.next();
        this._dialogRef.close();
    }

    public setErrorMessage(message: string): void {
        this.errorMessage = message;
    }

    public setActionMessage(message: string): void {
        this.actionMessage = message;
    }
}
