/**
 * Generic class for mat-select option
 *
 * @export
 * @class SelectOption
 * @template T The value type of the option.
 */
export class SelectOption<T> {
    /**
     * The display text of the option.
     *
     * @type {string}
     * @memberof SelectOption
     */
    public text: string;
    /**
     * The value of the option.
     *
     * @type {T}
     * @memberof SelectOption
     */
    public value: T;

    /**
     *Creates an instance of SelectOption.
     *
     * @param {T} value
     * @param {string} text
     * @memberof SelectOption
     */
    public constructor(value: T, text: string) {
        this.value = value;
        this.text = text;
    }
}
