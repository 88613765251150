import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined, isNumber, isDate, isString, isBoolean } from '@shared/utility/General.Utility';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';

/**
 * Table cell data type.
 *
 * @enum {number}
 */
export enum DataTypes {
    nullOrUndefined = 0,
    number = 1,
    date = 2,
    string = 3,
    unknown = 4,
    boolean = 5,
}

/**
 * Table cell view mode.
 *
 * @enum {number}
 */
export enum ViewModes {
    default = 0,
    dateTimezone = 1,
}

export interface IDisplayValue {
    dataType: DataTypes;
    viewMode: ViewModes;
    value: string;
    dateValue: Date;
}

export class IGetDisplayValueOptions {
    dateFormat?: string;
    timeSetup?: TimeSetupModel;
    preFix?: string;
    postFix?: string;
    fractionDigits?: number;
}

export class ValueUtility {
    public static getDisplayValue(value: any, options: IGetDisplayValueOptions): IDisplayValue {
        const displayValue: IDisplayValue = { dataType: DataTypes.nullOrUndefined, viewMode: ViewModes.default, value: '', dateValue: null };

        if (isNullOrUndefined(value)) {
            displayValue.dataType = DataTypes.nullOrUndefined;
            displayValue.value = '';
        } else if (isNumber(value)) {
            displayValue.dataType = DataTypes.number;
            displayValue.value = value.toFixed(options.fractionDigits || 0);
        } else if (isDate(value)) {
            displayValue.dataType = DataTypes.date;

            const format = isNullOrUndefined(options.dateFormat) ? null : DateTimeUtility[options.dateFormat] || options.dateFormat;

            if (!isNullOrUndefined(options.timeSetup)) {
                displayValue.dateValue = value;
                displayValue.viewMode = ViewModes.dateTimezone;
                if (!isNullOrUndefined(format)) {
                    displayValue.value = DateTimeUtility.format(value, format, options.timeSetup.timeZone);
                } else {
                    displayValue.value = DateTimeUtility.format(value, DateTimeUtility.shortDateTimeFormat, options.timeSetup.timeZone);
                }
            } else {
                if (!isNullOrUndefined(format)) {
                    displayValue.value = DateTimeUtility.format(value, format);
                } else {
                    displayValue.value = DateTimeUtility.toShortDateTime(value);
                }
            }
        } else if (isString(value)) {
            displayValue.dataType = DataTypes.string;
            displayValue.value = value;
        } else if (isBoolean(value)) {
            displayValue.dataType = DataTypes.boolean;
            displayValue.value = value.toString();
        } else {
            displayValue.dataType = DataTypes.unknown;
            try {
                displayValue.value = JSON.stringify(value);
            } catch {
                displayValue.value = value.toString();
            }
        }

        if (!isNullOrUndefined(options.preFix)) {
            displayValue.value = options.preFix + displayValue.value;
        }

        if (!isNullOrUndefined(options.postFix)) {
            displayValue.value = displayValue.value + options.postFix;
        }

        return displayValue;
    }
}
