import { INewValidationSessionDetailsModel, NewValidationSessionDetailsModel } from '@rift/models/restapi/NewValidationSessionDetails.Model';
import { IValidationReportModel, ValidationReportModel } from '@rift/models/restapi/ValidationReport.Model';
import { ValidationSessionOptionModel } from '@rift/models/restapi/ValidationSessionOption.Model';
import { ValidationSessionStateEnum } from '@shared/enum/ValidationSessionState.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IValidationSessionInfoModel extends INewValidationSessionDetailsModel {
    state: ValidationSessionStateEnum;
    creationDate: string;
    modificationDate: string;
    report: IValidationReportModel;
}

export class ValidationSessionInfoModel extends NewValidationSessionDetailsModel implements IValidationSessionInfoModel, IRestModel {

    public error: string = null;
    public creationDate: string = null;
    public modificationDate: string = null;
    public readonly isIRestModel = true;
    public report: ValidationReportModel = null;
    public state: ValidationSessionStateEnum = null;

    public constructor() {
        super();
    }

    public get completed(): boolean {
        return this.state === ValidationSessionStateEnum.rejected || this.state === ValidationSessionStateEnum.validated;
    }

    public get creationDateDate(): Date {
        return DateTimeNonMomentUtility.fromRestApiDateTime(this.creationDate);
    }

    public get modificationDateDate(): Date {
        return DateTimeNonMomentUtility.fromRestApiDateTime(this.modificationDate);
    }

    public get passed(): boolean {
        return this.state === ValidationSessionStateEnum.validated;
    }

    public loadFromRestApiModel(restModel: any, upperCase: boolean = true): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ValidationSessionInfoModel', null, upperCase);
        this.options = RestModelUtility.loadFromArray(restModel.Options, ValidationSessionOptionModel);
        this.report = RestModelUtility.loadFrom(restModel.Report, ValidationReportModel);
    }

    public toInterface(): IValidationSessionInfoModel {
        return {
            ...super.toInterface(),
            options: this.options.map(i => i.toInterface()),
            state: this.state,
            creationDate: this.creationDate,
            modificationDate: this.modificationDate,
            report: !isNullOrUndefined(this.report) ? this.report.toInterface() : null,
        } as IValidationSessionInfoModel;
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            Options: RestModelUtility.toJsonArray(this.options),
            Report: this.report.toRestApiModel(),
        };
    }
}
