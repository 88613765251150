import { Component, Injector, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { IValidatableRecordingModel } from '@rift/service/validation/models/ValidatableRecording.Model';
import { BaseComponent } from '@shared/base/Base.Component';

@Component({
    selector: 'rift-validation-device-selector',
    templateUrl: './DeviceSelector.Component.html',
    styleUrls: ['./DeviceSelector.Component.scss']
})
export class DeviceSelectorComponent extends BaseComponent {

    @HostBinding()
    public id: string = 'rift-validation-device-selector';

    public devices: Array<string> = [];

    private _currentDevice: string;

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
    }

    @Input()
    public set validationSession(value: IValidatableRecordingModel) {
        if(!this.isNullOrUndefined(value)){
            this.devices.push(value.friendlySerial);

            const nodesLength = value.onNodes.length;
            for(let i = 0; i < nodesLength; i++){
                this.devices.push(value.onNodes[i].friendlySerial);
            }
        }
        else{
            this.devices = [];
        }
    }

    @Input()
    public set currentVideoDevice(value: string){
        this._currentDevice = value;
    }

    @Output()
    public deviceSelected = new EventEmitter<string>();

    public getDeviceVideoPlayState(device: string): boolean{
        return device === this._currentDevice;
    }

    public onToggleDeviceVideoClick(device: string, e: MouseEvent): void {
        this.deviceSelected.emit(device);
    }
}
