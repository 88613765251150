import { IFrameStore } from '@rift/service/validation/database/syncrecording/IFrameStore';
import { IObjectStore } from '@shared/service/Indexeddb/IObjectStore';

export interface IDatabaseDeviceFramesStore extends IFrameStore {
}

export class DatabaseDeviceFramesStore implements IObjectStore {
    public static readonly keyPath: string = 'id';
    public static readonly recordingIdBlockIndex: string = 'RecordingIdBlockIndex';
    public static readonly recordingIdFrameNumberIndex: string = 'RecordingIdFrameNumberIndex';
    public static readonly recordingIdIndex: string = 'RecordingIdIndex';
    public static readonly storeDisplayName: string = 'Counts';
    public static readonly storeName: string = 'device';

    public get storeName(): string {
        return DatabaseDeviceFramesStore.storeName;
    };

    public static toInterface(): IDatabaseDeviceFramesStore {
        return {
            storeName: this.storeName,
            keyPath: this.keyPath,
            recordingIdFrameNumberIndex: this.recordingIdFrameNumberIndex,
            recordingIdIndex: this.recordingIdIndex,
            recordingIdBlockIndex: this.recordingIdBlockIndex,
        } as IDatabaseDeviceFramesStore;
    }

    public createStores(db: IDBDatabase): void {
        const store: IDBObjectStore = db.createObjectStore(DatabaseDeviceFramesStore.storeName, { keyPath: DatabaseDeviceFramesStore.keyPath, autoIncrement: true });
        store.createIndex(DatabaseDeviceFramesStore.recordingIdFrameNumberIndex, ['recordingId', 'frameNumber'], { unique: true });
        store.createIndex(DatabaseDeviceFramesStore.recordingIdBlockIndex, ['recordingId', 'block']);
        store.createIndex(DatabaseDeviceFramesStore.recordingIdIndex, 'recordingId');
    }
}
