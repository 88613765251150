export enum MarkedTargetEnum {
    none = 0,
    exclude = 1,
    only = 2
}

export class MarkedTargetEnumHelpers {
    public static toFullLongName(value: MarkedTargetEnum): string {
        switch (value) {
            case MarkedTargetEnum.none:
                return 'Disabled';
            case MarkedTargetEnum.exclude:
                return 'Exclude staff';
            case MarkedTargetEnum.only:
                return 'Staff only';
        }
    }
}
