import { Component, HostBinding, Inject, ApplicationRef, Optional, NgZone } from '@angular/core';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isArray, isNullOrUndefined } from '@shared/utility/General.Utility';
import { JsonUtility } from '@shared/utility/Json.Utility';
import * as ErrorStackParser from 'error-stack-parser';
import * as FileSaver from 'file-saver';
import { FileUtility } from '@shared/utility/File.Utility';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import * as html2canvas from 'html2canvas';
import { map } from 'rxjs/operators';
import { VersionService } from '@shared/service/version/Version.Service';

interface ElementItem {
    nodeName: string;
    children: ElementItem[];
}

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

/**
 * Dialog data
 *
 * @export
 * @class ErrorDialogData
 */
export class ErrorDialogData {
    /**
     * The thrown error
     *
     * @type {Error}
     * @memberof ErrorDialogData
     */
    public error: Error;
    /**
     * The error message
     *
     * @type {string}
     * @memberof ErrorDialogData
     */
    public errorMessage: string;
    /**
     * The processes that are running.
     *
     * @type {Array<ProcessMonitorServiceProcess>}
     * @memberof ErrorDialogData
     */
    public processes: Array<ProcessMonitorServiceProcess> = [];
    /**
     * The title of the dialog.
     *
     * @type {string}
     * @memberof ErrorDialogData
     */
    public title: string;

    public stackFrames: Array<StackFrame>;
    public errorJson: string;
    public caughtByGlobal: boolean = false;
    public serial: string = null;

    /**
     * Creates an instance of ErrorDialogData.
     *
     * @param {string} [title] The title of the dialog.
     * @param {Error} [error] The thrown error
     * @param {string} [errorMessage] The error message
     * @param {(ProcessMonitorServiceProcess | Array<ProcessMonitorServiceProcess>)} [processes] The processes that are running.
     * @memberof ErrorDialogData
     */
    public constructor(title?: string, error?: Error, errorMessage?: string, processes?: ProcessMonitorServiceProcess | Array<ProcessMonitorServiceProcess>, caughtByGlobal?: boolean, serial?: string) {
        this.processes = isNullOrUndefined(processes) ? [] : isArray(processes) ? processes as Array<ProcessMonitorServiceProcess> : [processes as ProcessMonitorServiceProcess];
        this.error = isNullOrUndefined(error) ? null : error;
        this.errorMessage = errorMessage;
        this.title = title;
        if (!isNullOrUndefined(caughtByGlobal)) {
            this.caughtByGlobal = caughtByGlobal;
        }
        if (!isNullOrUndefined(serial)) {
            this.serial = serial;
        }
    }
}

/**
 * Dialog result
 *
 * @export
 * @class ErrorDialogResult
 */
export class ErrorDialogResult {
    /**
     * True if ok clicked.
     *
     * @type {boolean}
     * @memberof ErrorDialogResult
     */
    public ok: boolean;

    /**
     * Creates an instance of ErrorDialogResult.
     *
     * @param {boolean} ok
     * @memberof ErrorDialogResult
     */
    public constructor(ok: boolean) {
        this.ok = ok;
    }
}

/**
 * Dialog component for showing errors.
 *
 * @export
 * @class ErrorDialogComponent
 */
@Component({
    selector: 'shr-error-dialog',
    templateUrl: './Error.Dialog.Component.html',
    styleUrls: ['./Error.Dialog.Component.scss'],
})
export class ErrorDialogComponent {
    public static className: string = 'ErrorDialogComponent';

    public isNullOrUndefined = isNullOrUndefined;
    public errors: ErrorDialogData[] = [];
    public okClicked = false;
    public saveClicked = false;

    @HostBinding()
    public id: string = 'shr-error-dialog';

    /**
     * Creates an instance of ErrorDialogComponent.
     *
     * @param {ErrorDialogData} data The dialog data.
     * @param {MatDialogRef<ErrorDialogComponent>} _dialogRef The dialog ref.
     * @param {DataPollingService} _dataPollingService The data polling service.
     * @memberof ErrorDialogComponent
     */
    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: ErrorDialogData,
        private readonly _versionService: VersionService,
        private readonly _app: ApplicationRef,
        private readonly _router: Router,
        private readonly _dialogRef: MatDialogRef<ErrorDialogComponent>) {

        this._dialogRef.disableClose = true;
        this.addError(data);
    }

    public ok(): void {
        window.location.reload();
    }

    public save(): void {
        this.getScreenShotDataUrl().subscribe((screenDataUrl) => {
            const elementTree = this.getElementTree((window as any).getAllAngularRootElements());

            let hostDeviceSerialNumber = null;
            if (!isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.serial)) {
                hostDeviceSerialNumber = this.data.serial;
            }

            const data = {
                screenDataUrl,
                caughtByGlobal: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.caughtByGlobal) ? this.data.caughtByGlobal : null,
                hostDeviceSerialNumber,
                url: this._router.url,
                elementTree,
                errors: [],
                appVersion: this._versionService.appVersion,
                uiVersion: this._versionService.uiVersion,
            };

            const errorsLength = this.errors.length;
            for (let ei = 0; ei < errorsLength; ei++) {
                const errorData = this.errors[ei];

                const processes = [];
                const processesLength = errorData.processes.length;
                for (let pi = 0; pi < processesLength; pi++) {
                    const process = errorData.processes[pi];
                    if (!isNullOrUndefined(process)) {
                        processes.push({
                            name: process.name,
                            id: process.id,
                            completedTime: process.completedTime,
                            duration: process.duration,
                            hasError: process.hasError,
                            isRunning: process.isRunning,
                            key: process.key,
                            percentage: process.percentage,
                            startedTime: process.startedTime,
                            status: process.status,
                            errorMessages: process.errorMessages,
                            inName: process.inName,
                            errors: !isNullOrUndefined(process.lastError) ? this.stringifyError(process.lastError) : null,
                            restApi: {
                                downloadPercent: !isNullOrUndefined(process.restApi) ? process.restApi.downloadPercent : null,
                                downloadProgress: !isNullOrUndefined(process.restApi) ? process.restApi.downloadProgress : null,
                                uploadPercent: !isNullOrUndefined(process.restApi) ? process.restApi.uploadPercent : null,
                                uploadProgress: !isNullOrUndefined(process.restApi) ? process.restApi.uploadProgress : null,
                            }
                        });
                    }
                }

                data.errors.push({
                    error: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.error) ? this.stringifyError(this.data.error) : null,
                    message: !isNullOrUndefined(errorData.error) ? errorData.error.message : null,
                    stackFrames: !isNullOrUndefined(errorData.stackFrames) ? errorData.stackFrames : null,
                    processes,
                });
            }

            const blob = new Blob([JSON.stringify(data, getCircularReplacer())], { type: 'text/plain;charset=utf-8' });
            FileSaver.saveAs(blob, FileUtility.sanitize(`errorlog_${DateTimeUtility.toFileNameDateTime(new Date())}.json`));
        });
    }

    public addError(data: ErrorDialogData): void {
        if (!this.isNullOrUndefined(data)) {
            this.tryParseError(data);
            this.errors.push(data);
        }
    }

    private tryParseError(data: ErrorDialogData): void {
        try {
            data.stackFrames = ErrorStackParser.parse(data.error);
        } catch { }

        try {
            data.errorJson = JsonUtility.prettyPrint(data.error);
        } catch { }

        if (isNullOrUndefined(data.errorMessage)){
            data.errorMessage = '';
        }
    }

    private stringifyError(error: Error): string {
        if (!isNullOrUndefined(error)) {
            const plainObject = {};
            Object.getOwnPropertyNames(error).forEach((key) => plainObject[key] = error[key]);
            return JSON.stringify(plainObject, getCircularReplacer());
        }
    }

    private getElementTree(rootElements: any[]): ElementItem[] {
        const elements: ElementItem[] = [];

        const length = rootElements.length;
        for (let i = 0; i < length; i++) {
            const ele = rootElements[i];
            elements.push({
                nodeName: ele.nodeName,
                children: [...this.getElementTree(rootElements[i].children)]
            });
        }

        return elements;
    }

    private getScreenShotDataUrl(): Observable<string> {
        const elements = document.getElementsByClassName('error-screen-shot-from');
        if (!isNullOrUndefined(elements) && elements.length > 0) {
            const element: HTMLElement = elements[0] as HTMLElement;
            return from(html2canvas.default(element)).pipe(
                map(canvas => canvas.toDataURL())
            );
        }
        return of('');
    }
}
