import { Component, Injector, OnDestroy, OnInit, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LicenceStatusModel } from '@em/models/restapi/LicenceStatus.Model';
import { LicenceService } from '@em/service/data/licence/Licence.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { OkCancelDialogComponent, OkCancelDialogData, OkCancelDialogResult } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { EventsService } from '@shared/service/events/Events.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import * as FileSaver from 'file-saver';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { LicenceItemModel } from '@em/models/restapi/LicenceItem.Model';
import { FileUtility } from '@shared/utility/File.Utility';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'em-settings-licence',
    templateUrl: './Settings.Licence.Component.html',
    styleUrls: ['./Settings.Licence.Component.scss']
})
export class SettingsLicenceComponent extends BaseComponent implements OnInit, OnDestroy, ILoadDate {
    public static className: string = 'SettingsLicenceComponent';
    public applyAction: NavBarAction;
    public applyActionClickedProcess: ProcessMonitorServiceProcess;
    public applyKeyError: string;

    public applyKeyProcess: ProcessMonitorServiceProcess;

    public applyLicenceFileProcess: ProcessMonitorServiceProcess;
    public cancelApplyAction: NavBarAction;
    public cancelApplyActionClickedProcess: ProcessMonitorServiceProcess;
    public dataSource = new MatTableDataSource<LicenceItemModel>();
    public displayedColumns = ['key', 'capacity', 'expires'];
    public downloadLicenceAndReleaseFileProcess: ProcessMonitorServiceProcess;
    public downloadLicenceFileProcess: ProcessMonitorServiceProcess;
    public keyFormGroup: FormGroup;
    public licenceChangedProcess: ProcessMonitorServiceProcess;
    public licences: LicenceStatusModel;

    public mode: 'view' | 'apply' | 'applyfail' | 'applyoffline' | 'releasefail' | 'releaseoffline' = 'view';

    @HostBinding()
    public id: string = 'em-settings-licence';
    public releaseAction: NavBarAction;

    public releaseActionClickedProcess: ProcessMonitorServiceProcess;
    public releaseLicenceProcess: ProcessMonitorServiceProcess;
    public selectedLicence: LicenceStatusModel;

    public constructor(
        private readonly _eventsService: EventsService,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _licenceService: LicenceService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsLicenceComponent.className, this.loadDataProcessText);
        this.applyKeyProcess = this.processMonitorService.getProcess(SettingsLicenceComponent.className, 'Applying licence key.');
        this.releaseLicenceProcess = this.processMonitorService.getProcess(SettingsLicenceComponent.className, 'Releasing licence key.');
        this.applyLicenceFileProcess = this.processMonitorService.getProcess(SettingsLicenceComponent.className, 'Applying licence file.');
        this.downloadLicenceFileProcess = this.processMonitorService.getProcess(SettingsLicenceComponent.className, 'Downloading licence file.');
        this.downloadLicenceAndReleaseFileProcess = this.processMonitorService.getProcess(SettingsLicenceComponent.className, 'Releasing and downloading licence file.');
        this.licenceChangedProcess = this.processMonitorService.getProcess(SettingsLicenceComponent.className, 'Licence changed.');
        this.releaseActionClickedProcess = this.processMonitorService.getProcess(SettingsLicenceComponent.className, 'Release licence clicked');
        this.applyActionClickedProcess = this.processMonitorService.getProcess(SettingsLicenceComponent.className, 'Apply licence clicked');
        this.cancelApplyActionClickedProcess = this.processMonitorService.getProcess(SettingsLicenceComponent.className, 'Hide licence form clicked');

        this.addSubscription(this.observableHandlerBase(this._eventsService.onLicenceChanged, this.licenceChangedProcess).subscribe(() => {
            this.loadDataStartBase(this);
        }), this.licenceChangedProcess);

        this.keyFormGroup = this._formBuilder.group({
            key: ['',
                Validators.compose([Validators.required, Validators.maxLength(200)]),
                Validators.composeAsync([ValidationValidators.licenceKeyValidatorAsync(this._licenceService).bind(this)])
            ],
        });

        this.releaseAction = new NavBarAction();
        this.releaseAction.name = 'licencerelease';
        this.releaseAction.text = 'Release Licence';
        this.addSubscription(this.observableHandlerBase(this.releaseAction.onButtonClick, this.releaseActionClickedProcess).subscribe(() => { this.release(); }), this.releaseActionClickedProcess);

        this.applyAction = new NavBarAction();
        this.applyAction.name = 'licenceapply';
        this.applyAction.text = 'Apply Licence';
        this.addSubscription(this.observableHandlerBase(this.applyAction.onButtonClick, this.applyActionClickedProcess).subscribe(() => { this.applyMode(); }), this.applyActionClickedProcess);

        this.cancelApplyAction = new NavBarAction();
        this.cancelApplyAction.name = 'licencecancelapply';
        this.cancelApplyAction.text = 'Hide Licence Form';
        this.addSubscription(this.observableHandlerBase(this.cancelApplyAction.onButtonClick, this.cancelApplyActionClickedProcess).subscribe(() => { this.viewMode(); }), this.cancelApplyActionClickedProcess);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public dataExportGetData = (displayedColumn: string, data: LicenceItemModel) => {
        switch (displayedColumn) {
            case 'expires':
                return data.expiryText;
            default:
                return data[displayedColumn];
        }
    };

    public dataExportGetHeader = (displayedColumn: string) => displayedColumn;

    public apply(key: string): void {
        this.applyKeyError = null;

        this.addSubscription(this.observableHandlerBase(this._licenceService.applyLicenceKey(key, this.applyKeyProcess), this.applyKeyProcess).subscribe(
            result => {
                if (result === null || !result.online) {
                    this.applyOfflineMode();
                } else if (!result.result) {
                    this.applyFailMode();
                } else if (result.result === true) {
                    this.viewMode();
                    this._eventsService.changedLicence();
                } else {
                    this.applyKeyError = result.message;
                }
            }
        ), this.applyKeyProcess);
    }

    public applyFailMode(): void {
        this.mode = 'applyfail';
    }

    public applyMode(): void {
        this.mode = 'apply';
        this._navBarService.addAction(this.cancelApplyAction);
        this._navBarService.removeAction(this.applyAction);
    }

    public applyOfflineMode(): void {
        this.mode = 'applyoffline';
    }

    public downloadLicenceAndReleaseFile(): void {
        this.addSubscription(this.observableHandlerBase(this._licenceService.downloadReleaseCLP(this.licences.licences[0].key, this.downloadLicenceAndReleaseFileProcess), this.downloadLicenceAndReleaseFileProcess).subscribe(
            result => {
                const blob = new Blob([result], { type: 'text/plain;charset=utf-8' });
                FileSaver.saveAs(blob, FileUtility.sanitize(`EstateManager.clp`));

                this.viewMode();
                this._eventsService.changedLicence();
            }
        ), this.downloadLicenceAndReleaseFileProcess);
    }

    public downloadLicenceFile(): void {
        this.addSubscription(this.observableHandlerBase(this._licenceService.downloadCLP(this.keyFormGroup.controls.key.value, this.downloadLicenceFileProcess), this.downloadLicenceFileProcess).subscribe(
            result => {
                const blob = new Blob([result], { type: 'text/plain;charset=utf-8' });
                FileSaver.saveAs(blob, FileUtility.sanitize(`EstateManager.clp`));
            }
        ), this.downloadLicenceFileProcess);
    }

    public licenceSelected(licence: LicenceStatusModel): void {
        this.selectedLicence = licence;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._licenceService.getStatus(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this._navBarService.removeAction(this.releaseAction);
                        this._navBarService.removeAction(this.applyAction);
                        this._navBarService.removeAction(this.cancelApplyAction);

                        this.licences = result;
                        this.dataSource.data = this.licences.licences;

                        if (this.licences.isLicensed) {
                            this._navBarService.addAction(this.applyAction);
                            this._navBarService.addAction(this.releaseAction);
                        } else {
                            this._navBarService.addAction(this.applyAction);
                        }
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this._navBarService.removeAction(this.releaseAction);
        this._navBarService.removeAction(this.applyAction);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this._navBarService.addAction(this.releaseAction);
        this._navBarService.addAction(this.applyAction);
    }

    public onApplyLicenceFileChange(event): void {
        const fileList: FileList = event.target.files;
        if (fileList.length === 1) {
            const file: File = fileList[0];

            this.addSubscription(this.observableHandlerBase(this._licenceService.uploadILP(file, this.applyLicenceFileProcess), this.applyLicenceFileProcess).subscribe(
                result => {
                    if (result.result === true) {
                        this.viewMode();
                        this._eventsService.changedLicence();
                    } else {
                        this.applyKeyError = result.message;
                    }
                }
            ), this.applyLicenceFileProcess);

        }
    }

    public release(): void {
        const dialogRef = this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData(`Release licences`, `Are you sure you wish to release all applied licences?`), disableClose: true });

        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.releaseLicenceProcess).subscribe((result: OkCancelDialogResult) => {
            if (!this.isNullOrUndefined(result) && result.ok === true) {
                this.addSubscription(this.observableHandlerBase(this._licenceService.releaseLicence(this.releaseLicenceProcess), this.releaseLicenceProcess).subscribe(
                    applyResult => {
                        if (applyResult === null || !applyResult.online) {
                            this.releaseOfflineMode();
                        } else if (applyResult.result === false) {
                            this.releaseFailMode();
                        } else if (applyResult.result === true) {
                            this._eventsService.changedLicence();
                        }
                    }
                ), this.releaseLicenceProcess);
            }
        }), this.releaseLicenceProcess);
    }

    public releaseFailMode(): void {
        this.mode = 'releasefail';
    }

    public releaseOfflineMode(): void {
        this.mode = 'releaseoffline';
    }

    public viewMode(): void {
        this.mode = 'view';
        this._navBarService.removeAction(this.cancelApplyAction);
        this._navBarService.addAction(this.applyAction);
    }
}
