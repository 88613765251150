import { BaseModel } from '@shared/base/Base.Model';
import { LicenceStateEnum } from '@shared/enum/LicenceState.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';


export class ShortLicenceStatusModel extends BaseModel implements IRestModel {
    public capacity: number = null;
    public expiry: Date = null;

    public isLicensed: boolean = null;
    public readonly isIRestModel = true;
    public state: LicenceStateEnum = null;
    public statusText: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ShortLicenceStatusModel');
        this.expiry = DateTimeNonMomentUtility.fromRestApiDateTime(this.expiry);
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
