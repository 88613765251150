import { LoggingServiceLevel } from '@shared/service/logging/Logging.Service.Level';

/**
 * Config options for the logging service.
 *
 * @export
 * @class LoggingServiceOptions
 */
export class LoggingServiceOptions {
    /**
     * The level of logs to display in the console.
     *
     * @type {LoggingServiceLevel}
     * @memberof LoggingServiceOptions
     */
    public level: LoggingServiceLevel;
    /**
     * The level of logs to store in local storage.
     *
     * @type {LoggingServiceLevel}
     * @memberof LoggingServiceOptions
     */
    public storeLevel: LoggingServiceLevel;
    /**
     * The maximum number of logs to store in local storage.
     *
     * @type {number}
     * @memberof LoggingServiceOptions
     */
    public storeMax: number;
    /**
     * The name of the key to use for storing the logs to local storage.
     *
     * @type {string}
     * @memberof LoggingServiceOptions
     */
    public storeAs: string;
    /**
     * True if errors should be stored to local storage.
     *
     * @type {boolean}
     * @memberof LoggingServiceOptions
     */
    public store: boolean;
}
