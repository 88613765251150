import { ISpriteSheetMap } from '@shared/generic/ISpriteSheetMap';

export const ViewPortSpriteSheetMap: Array<ISpriteSheetMap> = [
    {
        name: 'joint-edit-mouseover-28',
        x: 0,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-29',
        x: 0,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-30',
        x: 121,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-31',
        x: 121,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-0',
        x: 0,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-1',
        x: 121,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-2',
        x: 242,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-3',
        x: 242,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-4',
        x: 242,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-5',
        x: 0,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-6',
        x: 121,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-7',
        x: 242,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-8',
        x: 363,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-9',
        x: 363,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-10',
        x: 363,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-11',
        x: 363,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-12',
        x: 0,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-13',
        x: 121,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-14',
        x: 242,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-15',
        x: 363,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-16',
        x: 484,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-17',
        x: 484,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-18',
        x: 484,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-19',
        x: 484,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-20',
        x: 484,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-21',
        x: 0,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-22',
        x: 121,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-23',
        x: 242,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-24',
        x: 363,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-25',
        x: 484,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-26',
        x: 605,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-27',
        x: 605,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-28',
        x: 605,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-29',
        x: 605,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-30',
        x: 605,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'joint-view-31',
        x: 605,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'settings',
        x: 153,
        y: 1815,
        width: 40,
        height: 40
    },
    {
        name: 'addpoint-0',
        x: 0,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'target-valid',
        x: 1694,
        y: 1694,
        width: 50,
        height: 50
    },
    {
        name: 'target-marked',
        x: 1694,
        y: 1745,
        width: 50,
        height: 50
    },
    {
        name: 'target-new',
        x: 1745,
        y: 1745,
        width: 50,
        height: 50
    },
    {
        name: 'target-dead',
        x: 1745,
        y: 1694,
        width: 50,
        height: 50
    },
    {
        name: 'target-unknown',
        x: 0,
        y: 1815,
        width: 50,
        height: 50
    },
    {
        name: 'addpoint-1',
        x: 121,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-5',
        x: 242,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'target-updated',
        x: 51,
        y: 1815,
        width: 50,
        height: 50
    },
    {
        name: 'addpoint-6',
        x: 363,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-9',
        x: 484,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-4',
        x: 605,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-11',
        x: 726,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-3',
        x: 726,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-14',
        x: 726,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-8',
        x: 726,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-10',
        x: 726,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-7',
        x: 726,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-2',
        x: 726,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'target-couple',
        x: 102,
        y: 1815,
        width: 50,
        height: 50
    },
    {
        name: 'addpoint-17',
        x: 0,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-16',
        x: 121,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-15',
        x: 242,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-19',
        x: 363,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-12',
        x: 484,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-20',
        x: 605,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-21',
        x: 726,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-13',
        x: 847,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-27',
        x: 847,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-22',
        x: 847,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-23',
        x: 847,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-28',
        x: 847,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-25',
        x: 847,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-30',
        x: 847,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-0',
        x: 847,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-31',
        x: 0,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-29',
        x: 121,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-4',
        x: 242,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-3',
        x: 363,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-9',
        x: 484,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-6',
        x: 605,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-11',
        x: 726,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-8',
        x: 847,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-2',
        x: 968,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-26',
        x: 968,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-18',
        x: 968,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-12',
        x: 968,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-24',
        x: 968,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-5',
        x: 968,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-10',
        x: 968,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-1',
        x: 968,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-7',
        x: 968,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-20',
        x: 0,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-16',
        x: 121,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-14',
        x: 242,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-19',
        x: 363,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-22',
        x: 484,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-18',
        x: 605,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-13',
        x: 726,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-15',
        x: 847,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-17',
        x: 968,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-21',
        x: 1089,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-23',
        x: 1089,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-24',
        x: 1089,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-25',
        x: 1089,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-26',
        x: 1089,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-27',
        x: 1089,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-28',
        x: 1089,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-29',
        x: 1089,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-30',
        x: 1089,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'addpoint-mouseover-31',
        x: 1089,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-0',
        x: 0,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-1',
        x: 121,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-2',
        x: 242,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-3',
        x: 363,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-4',
        x: 484,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-5',
        x: 605,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-6',
        x: 726,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-7',
        x: 847,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-8',
        x: 968,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-9',
        x: 1089,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-10',
        x: 1210,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-11',
        x: 1210,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-12',
        x: 1210,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-13',
        x: 1210,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-14',
        x: 1210,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-15',
        x: 1210,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-16',
        x: 1210,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-17',
        x: 1210,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-18',
        x: 1210,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-19',
        x: 1210,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-20',
        x: 1210,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-21',
        x: 0,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-22',
        x: 121,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-23',
        x: 242,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-24',
        x: 363,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-25',
        x: 484,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-26',
        x: 605,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-27',
        x: 726,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-28',
        x: 847,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-29',
        x: 968,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-30',
        x: 1089,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-arrow-31',
        x: 1210,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-0',
        x: 1331,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-1',
        x: 1331,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-2',
        x: 1331,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-3',
        x: 1331,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-4',
        x: 1331,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-5',
        x: 1331,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-6',
        x: 1331,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-7',
        x: 1331,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-8',
        x: 1331,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-9',
        x: 1331,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-10',
        x: 1331,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-11',
        x: 1331,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-12',
        x: 0,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-13',
        x: 121,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-14',
        x: 242,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-15',
        x: 363,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-16',
        x: 484,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-17',
        x: 605,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-18',
        x: 726,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-19',
        x: 847,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-20',
        x: 968,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-21',
        x: 1089,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-22',
        x: 1210,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-23',
        x: 1331,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-24',
        x: 1452,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-25',
        x: 1452,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-26',
        x: 1452,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-27',
        x: 1452,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-28',
        x: 1452,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-29',
        x: 1452,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-30',
        x: 1452,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-31',
        x: 1452,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-0',
        x: 1452,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-1',
        x: 1452,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-2',
        x: 1452,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-3',
        x: 1452,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-4',
        x: 1452,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-5',
        x: 0,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-6',
        x: 121,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-7',
        x: 242,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-8',
        x: 363,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-9',
        x: 484,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-10',
        x: 605,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-11',
        x: 726,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-12',
        x: 847,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-13',
        x: 968,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-14',
        x: 1089,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-15',
        x: 1210,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-16',
        x: 1331,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-17',
        x: 1452,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-18',
        x: 1573,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-19',
        x: 1573,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-20',
        x: 1573,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-21',
        x: 1573,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-22',
        x: 1573,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-23',
        x: 1573,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-24',
        x: 1573,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-25',
        x: 1573,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-26',
        x: 1573,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-27',
        x: 1573,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-28',
        x: 1573,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-29',
        x: 1573,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-30',
        x: 1573,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-dragging-31',
        x: 1573,
        y: 1573,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-0',
        x: 0,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-1',
        x: 121,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-2',
        x: 242,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-3',
        x: 363,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-4',
        x: 484,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-5',
        x: 605,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-6',
        x: 726,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-7',
        x: 847,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-8',
        x: 968,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-9',
        x: 1089,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-10',
        x: 1210,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-11',
        x: 1331,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-12',
        x: 1452,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-13',
        x: 1573,
        y: 1694,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-14',
        x: 1694,
        y: 0,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-15',
        x: 1694,
        y: 121,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-16',
        x: 1694,
        y: 242,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-17',
        x: 1694,
        y: 363,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-18',
        x: 1694,
        y: 484,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-19',
        x: 1694,
        y: 605,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-20',
        x: 1694,
        y: 726,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-21',
        x: 1694,
        y: 847,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-22',
        x: 1694,
        y: 968,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-23',
        x: 1694,
        y: 1089,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-24',
        x: 1694,
        y: 1210,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-25',
        x: 1694,
        y: 1331,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-26',
        x: 1694,
        y: 1452,
        width: 120,
        height: 120
    },
    {
        name: 'joint-edit-mouseover-27',
        x: 1694,
        y: 1573,
        width: 120,
        height: 120
    }
];
