<div class="settings-counting-exclusion-type">
    <form [formGroup]="form"
        fxFlex>
        <mat-form-field>
            <mat-select formControlName="lineExclusionRegisterType"
                placeholder="Mode">
                <mat-option [value]="LineExclusionTypeEnum.noCountIfEntered">
                    {{ LineExclusionTypeEnumHelpers.toFullLongName(LineExclusionTypeEnum.noCountIfEntered) }}
                </mat-option>
                <mat-option [value]="LineExclusionTypeEnum.countIfEntered">
                    {{ LineExclusionTypeEnumHelpers.toFullLongName(LineExclusionTypeEnum.countIfEntered) }}
                </mat-option>
                <mat-option [value]="LineExclusionTypeEnum.countIfExits">
                    {{ LineExclusionTypeEnumHelpers.toFullLongName(LineExclusionTypeEnum.countIfExits) }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>