import { REGISTER_BASE_EXCLUDE_PROPERTIES, RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { RegisterUniversalTriggerPolarity, RegisterUniversalTriggerPolarityEnumHelpers } from '@shared/enum/RegisterUniversalTriggerPolarity.Enum';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';



export interface IRegisterUniversalTriggerModel extends IRegisterBaseModel {
    sourceRegisterIndex: number;
    thresholdValue: number;
    thresholdOffset: number;
    polarity: RegisterUniversalTriggerPolarity;
    holdOff: number;
    repeatPeriod: number;
}

/**
 * Represents a
 *
 * @export
 * @class RegisterUniversalTriggerModel
 * @extends {RegisterBaseModel}
 * @implements {IRegisterUniversalTriggerModel}
 * @implements {IRestModel}
 */
export class RegisterUniversalTriggerModel extends RegisterBaseModel implements IRegisterUniversalTriggerModel, IRestModel {

    public sourceRegisterIndex: number = null;
    public thresholdValue: number = null;
    public thresholdOffset: number = null;
    public polarity: RegisterUniversalTriggerPolarity = null;
    public holdOff: number = null;
    public repeatPeriod: number = null;

    public get polarityBool(): boolean{
        return this.polarity === RegisterUniversalTriggerPolarity.greaterThanOrEqual;
    }

    public set polarityBool(value: boolean){
        if(value){
            this.polarity = RegisterUniversalTriggerPolarity.greaterThanOrEqual;
        }
        else{
            this.polarity = RegisterUniversalTriggerPolarity.lessThan;
        }
    }

    public constructor() {
        super();

        this.registerProperty('sourceRegisterIndex');
        this.registerProperty('thresholdValue');
        this.registerProperty('thresholdOffset');
        this.registerProperty('polarity');
        this.registerProperty('holdOff');
        this.registerProperty('repeatPeriod');
        this.registerProperty('polarityBool');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterUniversalTriggerModel', ['registerType', 'polarityBool', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('sourceRegisterIndex', this.sourceRegisterIndex);
        this.setPropertyOriginalValue('thresholdValue', this.thresholdValue);
        this.setPropertyOriginalValue('thresholdOffset', this.thresholdOffset);
        this.setPropertyOriginalValue('polarity', this.polarity);
        this.setPropertyOriginalValue('holdOff', this.holdOff);
        this.setPropertyOriginalValue('repeatPeriod', this.repeatPeriod);

        this.polarityBool = this.polarity === RegisterUniversalTriggerPolarity.greaterThanOrEqual;
        this.setPropertyOriginalValue('polarityBool', this.polarityBool);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel()
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], isNullOrUndefined(this.sourceRegisterIndex) ? [] : [this.sourceRegisterIndex], []);
    }

}
