import { Component, HostListener, Injector, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { IPSetupModel } from '@rift/models/restapi/IPSetup.Model';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, of, zip, Subject, timer } from 'rxjs';
import { flatMap, map, timeout } from 'rxjs/operators';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { OkCancelDialogResult } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { UnitTypeEnum } from '@shared/enum/UnitType.Enum';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'rift-settings-network',
    templateUrl: './Settings.Network.Component.html',
    styleUrls: ['./Settings.Network.Component.scss']
})
export class SettingsNetworkComponent extends RiftBaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsNetworkComponent';
    public deviceCapableProcess: ProcessMonitorServiceProcess;

    public dnsSetupFormGroup: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;
    public iPSetup: IPSetupModel;
    public updateModelValuesProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'rift-settings-network';
    public networkSetupFormGroup: FormGroup;
    public routeParamsProcess: ProcessMonitorServiceProcess;
    public serverPortFormGroup: FormGroup;
    public hostDevice: DeviceModel;

    private _serial: string;

    public constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsNetworkComponent.className, 'Form values change');
        this.routeParamsProcess = this.processMonitorService.getProcess(SettingsNetworkComponent.className, 'Router params change');
        this.deviceCapableProcess = this.processMonitorService.getProcess(SettingsNetworkComponent.className, 'Device compatibility');
        this.updateModelValuesProcess = this.processMonitorService.getProcess(SettingsNetworkComponent.className, 'Update model values');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsNetworkComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsNetworkComponent.className, this.saveAllChangesProcessText);

        this.addSaveAllAction(this);

        this.networkSetupFormGroup = this._formBuilder.group({
            hostname: ['', Validators.compose([Validators.required, ValidationValidators.ipAddressOrHostName])],
            iPV4: ['', Validators.compose([Validators.required, ValidationValidators.ipAddress])],
            subnet: ['', Validators.compose([Validators.required, ValidationValidators.ipAddress])],
            gateway: ['', Validators.compose([Validators.required, ValidationValidators.ipAddress])],
            dhcp: ['', Validators.compose([Validators.required])],
        });
        this.formGroupTracker.track(this.networkSetupFormGroup);

        this.addSubscription(this.observableHandlerBase(this.networkSetupFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesNetworkSetupFormGroup()), this.formValuesChangeProcess);

        this.dnsSetupFormGroup = this._formBuilder.group({
            dNS1: ['', Validators.compose([ValidationValidators.ipAddress])],
            dNS2: ['', Validators.compose([ValidationValidators.ipAddress])],
            dNS3: ['', Validators.compose([ValidationValidators.ipAddress])],
        });
        this.formGroupTracker.track(this.dnsSetupFormGroup);

        this.addSubscription(this.observableHandlerBase(this.dnsSetupFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesDnsSetupFormGroup()), this.formValuesChangeProcess);

        this.serverPortFormGroup = this._formBuilder.group({
            serverPort: ['', Validators.compose([Validators.required, ValidationValidators.port])],
        });
        this.addSubscription(this.observableHandlerBase(this.isDeviceCapable(this.DeviceCapabilitiesEnum.configurableServerPort), this.deviceCapableProcess).subscribe(result => {
            if (result === true) {
                this.serverPortFormGroup.enable();
            } else {
                this.serverPortFormGroup.disable();
            }
        }), this.deviceCapableProcess);
        this.formGroupTracker.track(this.serverPortFormGroup);

        this.addSubscription(this.observableHandlerBase(this.serverPortFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesServerPortFormGroup()), this.formValuesChangeProcess);

        this.addSubscription(this.observableHandlerBase(this.networkSetupFormGroup.controls.dhcp.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            if (this.isReadOnly === false) {
                if (this.networkSetupFormGroup.controls.dhcp.value === true) {
                    this.networkSetupFormGroup.controls.hostname.enable();
                    this.networkSetupFormGroup.controls.iPV4.disable();
                    this.networkSetupFormGroup.controls.subnet.disable();
                    this.networkSetupFormGroup.controls.gateway.disable();
                    this.dnsSetupFormGroup.controls.dNS1.disable();
                    this.dnsSetupFormGroup.controls.dNS2.disable();
                    this.dnsSetupFormGroup.controls.dNS3.disable();
                } else {
                    this.networkSetupFormGroup.controls.hostname.enable();
                    this.networkSetupFormGroup.controls.iPV4.enable();
                    this.networkSetupFormGroup.controls.subnet.enable();
                    this.networkSetupFormGroup.controls.gateway.enable();
                    this.dnsSetupFormGroup.controls.dNS1.enable();
                    this.dnsSetupFormGroup.controls.dNS2.enable();
                    this.dnsSetupFormGroup.controls.dNS3.enable();
                }
            }
        }), this.formValuesChangeProcess);

        this.addSubscription(this.observableHandlerBase(this._activatedRoute.params, this.routeParamsProcess).subscribe(params => {
            this.serial = params.serial;
        }), this.routeParamsProcess);

        this.initConnectionState();
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this.changeTracker.clear();

        const loadDataSub = zip(
            this.getHostDevice().pipe(
                map(result => {
                    this.hostDevice = result;
                    return true;
                })
            ),
            this.deviceService.getIPSettings(this.serial, process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.iPSetup = result;
                        this.changeTracker.track(this.iPSetup);
                        this.setDnsFormGroupValues();
                        this.setNetworkSetupFormGroupValues();
                        this.setServerPortFormGroupValues();
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.connectionService.applyNetworkSetup(this.iPSetup, this.serial, process).pipe(
            map((applyNetworkSetupResult) => {
                if (applyNetworkSetupResult.error === false) {
                    if (applyNetworkSetupResult.updated === true) {
                        this.changeTracker.commitChanges();
                    } else if (applyNetworkSetupResult.canceled === true) {
                        this.changeTracker.clearChanges();
                        this.loadDataStartBase(this);
                    }

                    if (!this.isNullOrUndefined(pleaseWaitDialogRef)) {
                        pleaseWaitDialogRef.close();
                    }
                    if (!this.isNullOrUndefined(this.saveAllAction)) {
                        this.updateSaveAllAction(this);
                    }

                    return true;
                } else {
                    return false;
                }
            }),
        );
    }

    public get serial(): string {
        return this._serial;
    }
    public set serial(value: string) {
        this._serial = value;
        if (this.connectionService.isConnected === true) {
            this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
        }
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => this.loadData(this.openPleaseWaitLoadingDialog()));
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
        this.iPSetup = null;
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.networkSetupFormGroup.disable();
        this.dnsSetupFormGroup.disable();
        this.serverPortFormGroup.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.networkSetupFormGroup.enable();
        this.dnsSetupFormGroup.enable();
        this.serverPortFormGroup.enable();
    }

    private setDnsFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.iPSetup) && !this.isNullOrUndefined(this.dnsSetupFormGroup)) {
            this.dnsSetupFormGroup.setValue({
                dNS1: this.iPSetup.dNS1 === '0.0.0.0' ? '' : this.iPSetup.dNS1,
                dNS2: this.iPSetup.dNS2 === '0.0.0.0' ? '' : this.iPSetup.dNS2,
                dNS3: this.iPSetup.dNS3 === '0.0.0.0' ? '' : this.iPSetup.dNS3,
            });
        }
    }

    private setNetworkSetupFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.iPSetup) && !this.isNullOrUndefined(this.networkSetupFormGroup)) {
            this.networkSetupFormGroup.setValue({
                hostname: this.iPSetup.hostname,
                iPV4: this.iPSetup.iPV4,
                subnet: this.iPSetup.subnet,
                gateway: this.iPSetup.gateway,
                dhcp: this.iPSetup.dhcp,
            });
        }
    }

    private setServerPortFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.iPSetup) && !this.isNullOrUndefined(this.serverPortFormGroup)) {
            this.serverPortFormGroup.setValue({
                serverPort: this.iPSetup.serverPort,
            });
        }
    }

    private updateModelValuesDnsSetupFormGroup(): void {
        if (!this.isNullOrUndefined(this.iPSetup) && this.isReadOnly === false) {
            const formValues = this.dnsSetupFormGroup.value;

            this.addSubscription(this.observableHandlerBase(this.isDeviceAnyGen([UnitGenerationEnum.kestrel, UnitGenerationEnum.falcon]), this.updateModelValuesProcess).subscribe(result => {
                if (result) {
                    this.iPSetup.dNS1 = this.dnsSetupFormGroup.controls.dNS1.disabled ? this.iPSetup.dNS1 : this.isNullOrUndefined(formValues.dNS1) || this.isEmptyOrWhiteSpace(formValues.dNS1) ? '' : formValues.dNS1;
                    this.iPSetup.dNS2 = this.dnsSetupFormGroup.controls.dNS2.disabled ? this.iPSetup.dNS2 : this.isNullOrUndefined(formValues.dNS2) || this.isEmptyOrWhiteSpace(formValues.dNS2) ? '' : formValues.dNS2;
                    this.iPSetup.dNS3 = this.dnsSetupFormGroup.controls.dNS3.disabled ? this.iPSetup.dNS3 : this.isNullOrUndefined(formValues.dNS3) || this.isEmptyOrWhiteSpace(formValues.dNS3) ? '' : formValues.dNS3;
                } else {
                    this.iPSetup.dNS1 = this.dnsSetupFormGroup.controls.dNS1.disabled ? this.iPSetup.dNS1 : this.isNullOrUndefined(formValues.dNS1) || this.isEmptyOrWhiteSpace(formValues.dNS1) ? '0.0.0.0' : formValues.dNS1;
                    this.iPSetup.dNS2 = this.dnsSetupFormGroup.controls.dNS2.disabled ? this.iPSetup.dNS2 : this.isNullOrUndefined(formValues.dNS2) || this.isEmptyOrWhiteSpace(formValues.dNS2) ? '0.0.0.0' : formValues.dNS2;
                    this.iPSetup.dNS3 = this.dnsSetupFormGroup.controls.dNS3.disabled ? this.iPSetup.dNS3 : this.isNullOrUndefined(formValues.dNS3) || this.isEmptyOrWhiteSpace(formValues.dNS3) ? '0.0.0.0' : formValues.dNS3;
                }
            }), this.updateModelValuesProcess);

            this.updateSaveAllAction(this);
        }
    }

    private updateModelValuesNetworkSetupFormGroup(): void {
        if (!this.isNullOrUndefined(this.iPSetup) && this.isReadOnly === false) {
            const formValues = this.networkSetupFormGroup.value;

            this.iPSetup.hostname = this.networkSetupFormGroup.controls.hostname.disabled ? this.iPSetup.hostname : formValues.hostname;
            this.iPSetup.iPV4 = this.networkSetupFormGroup.controls.iPV4.disabled ? this.iPSetup.iPV4 : formValues.iPV4;
            this.iPSetup.subnet = this.networkSetupFormGroup.controls.subnet.disabled ? this.iPSetup.subnet : formValues.subnet;
            this.iPSetup.gateway = this.networkSetupFormGroup.controls.gateway.disabled ? this.iPSetup.gateway : formValues.gateway;
            this.iPSetup.dhcp = formValues.dhcp;

            this.updateSaveAllAction(this);
        }
    }

    private updateModelValuesServerPortFormGroup(): void {
        if (!this.isNullOrUndefined(this.iPSetup) && this.isReadOnly === false) {
            const formValues = this.serverPortFormGroup.value;

            this.iPSetup.serverPort = formValues.serverPort;

            this.updateSaveAllAction(this);
        }
    }
}
