import { Component, ElementRef, HostBinding, Injector, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FillHeightParentComponentBase } from '@em/components/base/FillHeightParentComponentBase';
import { IFillHeight } from '@shared/interface/IFillHeight';

@Component({
    selector: 'em-dashboard',
    templateUrl: './Dashboard.Component.html',
    styleUrls: ['./Dashboard.Component.scss']
})
export class DashboardComponent extends FillHeightParentComponentBase implements OnInit, IFillHeight {
    public static className: string = 'DashboardComponent';

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @HostBinding()
    public id: string = 'em-dashboard';

    public constructor(
        private readonly _renderer: Renderer2,
        private readonly _injector: Injector) {
        super(_renderer, _injector);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }
}
