import { Component, EventEmitter, HostBinding, Injector, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IGroupedConfigSection } from '@rift/service/configjsoncompare/ConfigJsonCompare.Service';
import { ConfigHistoryBaseComponent } from '@rift/components/settings/confighistory/ConfigHistory.Base.Component';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'rift-config-history-section-select',
    templateUrl: './ConfigHistory.Section.Select.Component.html',
})
export class ConfigHistorySectionSelectComponent extends ConfigHistoryBaseComponent {
    @HostBinding()
    public id: string = 'rift-config-history-section-select';

    @Input()
    public parentSectionVM: IGroupedConfigSection;

    @Input()
    public sectionVMs: IGroupedConfigSection[];

    @Output()
    public sectionShowChanged: EventEmitter<null> = new EventEmitter();

    public selectedSectionVM: IGroupedConfigSection;

    public constructor(
        private readonly _sanitizer: DomSanitizer,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_sanitizer, _injector, _dialog);
    }

    public onSectionSelected(event: MatSelectChange): void {
        this.selectedSectionVM = event.value;
    }

    public onSectionShowChanged(event: MatCheckboxChange, sectionVM: IGroupedConfigSection): void {
        sectionVM.show = event.checked;
        this.sectionShowChanged.emit();
    }
}
