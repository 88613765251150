import { Injectable } from '@angular/core';
import { BluetoothModel } from '@rift/models/restapi/Bluetooth.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiBluetoothService } from '@rift/service/restapi/v1/RestApi.Bluetooth.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class BluetoothService extends RiftBaseService {

    private _getSettingsLoadingTracker = new ObservableTracker<BluetoothModel>();
    private _settingsCache: BluetoothModel;
    private _updateSettingsLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiBluetoothService: RestApiBluetoothService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._settingsCache = null;
    }

    public getSettings(process?: ProcessMonitorServiceProcess): Observable<BluetoothModel> {
        if (isNullOrUndefined(this._settingsCache)) {
            return this._getSettingsLoadingTracker
                .getLoading()
                .observable(this._restApiBluetoothService.getSettings(process).pipe(
                    map(result => {
                        this._settingsCache = result;
                        return this._settingsCache;
                    })
                ));
        } else {
            return of(this._settingsCache);
        }
    }

    public setCache(settingsCache: BluetoothModel): Observable<boolean> {
        this._settingsCache = settingsCache;
        return of(true);
    }

    public updateSettings(settings: BluetoothModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateSettingsLoadingTracker
            .getLoading(settings)
            .observable(this._restApiBluetoothService.updateSettings(settings, process).pipe(tap(() => this._settingsCache = null)));
    }
}
