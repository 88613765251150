import { Directive, ElementRef, OnDestroy, OnInit, AfterContentInit, Input, Renderer2 } from '@angular/core';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { fromEvent, Subscription, timer } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

const BREAK_POINTS = ['lt-sm', 'lt-md', 'lt-lg', 'lt-xl', 'gt-sm', 'gt-md', 'gt-lg', 'gt-xl'];

/**
 * The element to be fixed in position.
 * Use shrScrollFixedHost as contains directive.
 *
 * @export
 * @class ScrollFixedDirective
 */
@Directive({
    selector: '[shrScrollFixed]'
})
export class ScrollFixedDirective implements OnDestroy {

    @Input()
    public 'disable.lt-sm': boolean;
    @Input()
    public 'disable.lt-md': boolean;
    @Input()
    public 'disable.lt-lg': boolean;
    @Input()
    public 'disable.lt-xl': boolean;
    @Input()
    public 'disable.gt-sm': boolean;
    @Input()
    public 'disable.gt-md': boolean;
    @Input()
    public 'disable.gt-lg': boolean;
    @Input()
    public 'disable.gt-xl': boolean;

    private _scrollParent: HTMLElement;
    private _scrollSub: Subscription;
    private _disable: boolean = false;

    public constructor(
        private readonly _mediaObserver: MediaObserver,
        private readonly _render: Renderer2,
        private readonly _elementRef: ElementRef,
    ) {

        this._mediaObserver.asObservable().subscribe((change: MediaChange[]) => {
            const length = BREAK_POINTS.length;
            for (let i = 0; i < length; i++) {
                const bp = BREAK_POINTS[i];
                if (this._mediaObserver.isActive(bp)) {
                    const disable = this[`disable.${BREAK_POINTS[i]}`];
                    if (!isNullOrUndefined(disable)) {
                        this._disable = coerceBooleanProperty(disable);
                    }
                }
            }


            this.init();
        });

        this.init();
    }

    public ngOnDestroy(): void {
        if (!isNullOrUndefined(this._scrollSub)) {
            this._scrollSub.unsubscribe();
        }
    }

    private init(): void {
        if (!isNullOrUndefined(this._elementRef)) {
            if (this._disable === false) {
                this._scrollParent = this.getScrollParent(this._elementRef.nativeElement);

                if (isNullOrUndefined(this._scrollParent)) {
                    timer(500).subscribe(() => this.init());
                } else {
                    const height = this._scrollParent.clientHeight;
                    let paddingTop = parseInt((this._elementRef.nativeElement as HTMLElement).style.paddingTop, 10);
                    let paddingBottom = parseInt((this._elementRef.nativeElement as HTMLElement).style.paddingBottom, 10);

                    paddingTop = Number.isNaN(paddingTop) ? 0 : paddingTop;
                    paddingBottom = Number.isNaN(paddingBottom) ? 0 : paddingBottom;

                    this._render.setStyle(this._elementRef.nativeElement, 'max-height', `${(height - (paddingTop + paddingBottom)) - 20}px`);
                    this._render.setStyle(this._elementRef.nativeElement, 'overflow', 'auto');
                    this._render.setStyle(this._elementRef.nativeElement, 'padding-bottom', '10px');

                    this._scrollSub = fromEvent(this._scrollParent, 'scroll').subscribe(() => {
                        this.onScroll();
                    });
                }
            } else {
                if (!isNullOrUndefined(this._scrollSub)) {
                    this._scrollSub.unsubscribe();
                    this._scrollSub = null;
                }
                this._render.removeStyle(this._elementRef.nativeElement, 'max-height');
                this._render.removeStyle(this._elementRef.nativeElement, 'overflow');
                this._render.removeStyle(this._elementRef.nativeElement, 'marginTop');
                this._render.removeStyle(this._elementRef.nativeElement, 'padding-bottom');
            }
        }
    }

    private onScroll(): void {
        if (this._disable === false && !isNullOrUndefined(this._elementRef) && !isNullOrUndefined(this._scrollParent)) {
            this._render.setStyle(this._elementRef.nativeElement, 'marginTop', `${this._scrollParent.scrollTop}px`);
        }
    }

    private getScrollParent(node: any): any {
        if (!isNullOrUndefined(node)) {
            if (node.scrollHeight > node.clientHeight) {
                return node;
            } else {
                return this.getScrollParent(node.parentNode);
            }
        }
    }
}
