import { SearchCriteriaGroupModel } from '@em/models/restapi/SearchCriteriaGroup.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';

const EXCLUDE_FIELDS = ['isExpanded', 'isNew', 'disabled', 'visable', 'grayed', 'treeMessage', 'treeError', 'isRoot'];

export class GroupModel extends BaseModel implements IRestModel {
    public deviceCount: number = null;
    public devicesInErrorCount: number = null;
    public devicesWithWarningsCount: number = null;
    public disabled: boolean = false;
    public grayed: boolean = false;
    public hasVideoRecordingDevices: number = null;
    public id: number = null;
    public isNew: boolean = false;
    public parent: GroupModel = null;
    public readonly isIRestModel = true;
    public visable: boolean = true;
    public isExpanded: boolean = false;
    public treeMessage: string = null;
    public treeError: string = null;
    public noEdit: boolean = null;
    public isRoot: boolean = null;

    private _parentId: number = null;
    private _name: string = null;
    private _order: number = null;
    private _searchCriteriaGroups: RestModelChangeTrackerArray<SearchCriteriaGroupModel> = new RestModelChangeTrackerArray<SearchCriteriaGroupModel>();
    private _children: RestModelChangeTrackerArray<GroupModel> = new RestModelChangeTrackerArray<GroupModel>();

    public constructor() {
        super();

        this.registerChangeTrackerArray('searchCriteriaGroups');
        this.registerChangeTrackerArray('children');
        this.registerProperty('order');
        this.registerProperty('name');
        this.registerProperty('parentId');
    }

    public get parentId(): number {
        return this._parentId;
    }
    public set parentId(value: number) {
        this._parentId = value;
    }

    public get order(): number {
        return this._order;
    }
    public set order(value: number) {
        this._order = value;
    }

    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }

    public get children(): RestModelChangeTrackerArray<GroupModel> {
        return this._children;
    }
    public set children(value: RestModelChangeTrackerArray<GroupModel>) {
        this._children = value;
    }

    public get searchCriteriaGroups(): RestModelChangeTrackerArray<SearchCriteriaGroupModel> {
        return this._searchCriteriaGroups;
    }

    public set searchCriteriaGroups(value: RestModelChangeTrackerArray<SearchCriteriaGroupModel>) {
        this._searchCriteriaGroups = value;
    }

    public get hasErrors(): boolean {
        return this.devicesInErrorCount > 0;
    }

    public get hasWarnings(): boolean {
        return this.devicesWithWarningsCount > 0;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-GroupModel', EXCLUDE_FIELDS);

        this.searchCriteriaGroups = RestModelUtility.loadFromArrayToChangeTrackerArray<SearchCriteriaGroupModel>(restModel.SearchCriteriaGroups, SearchCriteriaGroupModel);
        this.children = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.Children, GroupModel);

        this.setPropertyOriginalValue('searchCriteriaGroups', this.searchCriteriaGroups);
        this.setPropertyOriginalValue('children', this.children);
        this.setPropertyOriginalValue('order', this.order);
        this.setPropertyOriginalValue('name', this.name);

        this.isRoot = false;
    }

    public toRestApiModel() {
        const data = {
            ...RestModelUtility.toJson(this, EXCLUDE_FIELDS),
            Parent: RestModelUtility.toJson(this.parent),
            SearchCriteriaGroups: RestModelUtility.toJsonArray(this.searchCriteriaGroups),
        };
        return data;
    }
}
