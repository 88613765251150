import { BaseModel } from '@shared/base/Base.Model';
import { RelayModeEnum } from '@shared/enum/RelayMode.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IRelayRegisterModel extends IRestModel {
    registerName: string;
    registerID: number;
    pulseWidth: number;
    relayMode: number;
}

export class RelayRegisterModel extends BaseModel implements IRelayRegisterModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public registerName: string = null;
    public registerID: number = null;
    public pulseWidth: number = null;
    public relayMode: RelayModeEnum = null;

    public constructor() {
        super();

        this.registerProperty('registerName');
        this.registerProperty('registerID');
        this.registerProperty('pulseWidth');
        this.registerProperty('relayMode');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RelayRegisterModel');

        this.setPropertyOriginalValue('registerName', this.registerName);
        this.setPropertyOriginalValue('registerID', this.registerID);
        this.setPropertyOriginalValue('pulseWidth', this.pulseWidth);
        this.setPropertyOriginalValue('relayMode', this.relayMode);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
