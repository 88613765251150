<div class="counting-setting-content">
    <div *ngIf="!isNullOrUndefined(config)">
        {{ config.helpText }}
    </div>
    <div class="associate-register"
        fxLayout="row"
        fxLayoutAlign="start center"
        *ngFor="let vm of availableAssociated | orderBy: 'sequentialOrder'">
        <mat-checkbox [disabled]="isReadOnly"
            (change)="onAssociateRegisterSelectedChanged(vm)"
            [(ngModel)]="vm.selected"
            [labelPosition]="'after'">
            <rift-register-icon-name [registerBase]="vm.register"></rift-register-icon-name>
        </mat-checkbox>
        <div class="spacing-left"
            *ngIf=" vm.selected === true && config.isAddSubtract === true">
            <mat-slide-toggle [disabled]="isReadOnly"
                [(ngModel)]="vm.summationAdd"
                (change)="onSummationAddChange(vm)">
                {{ vm.summationAdd === true ? 'Add' : 'Subtract' }}
            </mat-slide-toggle>
        </div>
        <div class="spacing-left"
            *ngIf="vm.selected === true && config.isOrderable === true">
            <button mat-icon-button
                (click)="onSeqMoveUp(vm)"
                [disabled]="isReadOnly || selectedCount === 1 || vm.sequentialOrder === null || vm.sequentialOrder === 1">
                <mat-icon>keyboard_arrow_up</mat-icon>
            </button>
            <button mat-icon-button
                (click)="onSeqMoveDown(vm)"
                [disabled]="isReadOnly || selectedCount === 1 || vm.sequentialOrder === null || vm.sequentialOrder === selectedCount">
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
        </div>
    </div>
</div>
<div class="error"
    fxLayout="column">
    <div *ngIf="selectedCount < config.associatedMin">
        Please select {{config.associatedMin}} or more registers
    </div>
    <div *ngIf="selectedCount > config.associatedMax">
        Please select a maximum of {{config.associatedMax}} registers
    </div>
</div>