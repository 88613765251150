<div class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                <div fxLayout="row">
                    <div fxLayout="column">
                        <div class="header">
                            IP Settings
                        </div>
                    </div>
                    <div fxFlex></div>
                    <div>

                    </div>
                </div>
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="networkSetupFormGroup">
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="text"
                                #hostname
                                matInput
                                formControlName="hostname"
                                placeholder="Host Name">
                            <span matSuffix>{{ iPSetup?.propertyHasChangesText('hostname') }}</span>
                            <mat-error>{{getFormControlErrorMessage(networkSetupFormGroup.controls['hostname'])}}
                            </mat-error>
                            <mat-hint align="end">{{hostname.value.length}} / 59</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="text"
                                #iPV4
                                matInput
                                formControlName="iPV4"
                                placeholder="IP Address">
                            <span matSuffix>{{ iPSetup?.propertyHasChangesText('iPV4') }}</span>
                            <mat-error>{{getFormControlErrorMessage(networkSetupFormGroup.controls['iPV4'])}}
                            </mat-error>
                            <mat-hint align="end">{{iPV4.value.length}} / 15</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="text"
                                #subnet
                                matInput
                                formControlName="subnet"
                                placeholder="Netmask">
                            <span matSuffix>{{ iPSetup?.propertyHasChangesText('subnet') }}</span>
                            <mat-error>{{getFormControlErrorMessage(networkSetupFormGroup.controls['subnet'])}}
                            </mat-error>
                            <mat-hint align="end">{{subnet.value.length}} / 15</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="text"
                                #gateway
                                matInput
                                formControlName="gateway"
                                placeholder="Gateway">
                            <span matSuffix>{{ iPSetup?.propertyHasChangesText('gateway') }}</span>
                            <mat-error>{{getFormControlErrorMessage(networkSetupFormGroup.controls['gateway'])}}
                            </mat-error>
                            <mat-hint align="end">{{gateway.value.length}} / 15</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-checkbox #dhcp
                            formControlName="dhcp">Enable DHCP{{
                                iPSetup?.propertyHasChangesText('dhcp') }}</mat-checkbox>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                Enable DHCP
            </mat-card-title>
            <mat-card-content>
                <span>Correct IP settings are vital for communicating with a device once installed. Both
                    static IP
                    address and hostnames
                    are supported</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                DNS Settings
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="dnsSetupFormGroup">
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="text"
                                #dNS1
                                matInput
                                formControlName="dNS1"
                                placeholder="DNS 1">
                            <span matSuffix>{{ iPSetup?.propertyHasChangesText('dNS1') }}</span>
                            <mat-error>{{getFormControlErrorMessage(dnsSetupFormGroup.controls['dNS1'])}}
                            </mat-error>
                            <mat-hint align="end">{{dNS1.value.length}} / 15</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="text"
                                #dNS2
                                matInput
                                formControlName="dNS2"
                                placeholder="DNS 2">
                            <span matSuffix>{{ iPSetup?.propertyHasChangesText('dNS2') }}</span>
                            <mat-error>{{getFormControlErrorMessage(dnsSetupFormGroup.controls['dNS2'])}}
                            </mat-error>
                            <mat-hint align="end">{{dNS2.value.length}} / 15</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="text"
                                #dNS3
                                matInput
                                formControlName="dNS3"
                                placeholder="DNS 3">
                            <span matSuffix>{{ iPSetup?.propertyHasChangesText('dNS3') }}</span>
                            <mat-error>{{getFormControlErrorMessage(dnsSetupFormGroup.controls['dNS3'])}}
                            </mat-error>
                            <mat-hint align="end">{{dNS3.value.length}} / 15</mat-hint>
                        </mat-form-field>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                DNS Settings
            </mat-card-title>
            <mat-card-content>
                <span>Correct DNS settings are required when using host names for outbound connections.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div class="spacing-bottom"
    *ngIf="isDeviceCapable(DeviceCapabilitiesEnum.configurableServerPort) | async"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                Server Port
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="serverPortFormGroup">
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="number"
                                step="1"
                                #serverPort
                                matInput
                                formControlName="serverPort"
                                placeholder="Server Port">
                            <span matSuffix>{{ iPSetup?.propertyHasChangesText('serverPort') }}</span>
                            <mat-error>{{getFormControlErrorMessage(serverPortFormGroup.controls['serverPort'])}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                Server Port
            </mat-card-title>
            <mat-card-content>
                <span>This setting allows you to change the port you talk to a device on. Once this setting has
                    changed you will need reboot the device and then reconnect to talk to this device.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>