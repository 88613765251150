import { Component, HostListener, Injector, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeyValuePairModel } from '@em/models/restapi/KeyValuePair.Model';
import { SettingService } from '@em/service/data/setting/Setting.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { SelectOption } from '@shared/generic/SelectOption';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { BooleanUtility } from '@shared/utility/Boolean.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'em-settings-options-video',
    templateUrl: './Settings.Options.Video.Component.html',
    styleUrls: ['./Settings.Options.Video.Component.scss']
})
export class SettingsOptionsVideoComponent extends BaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsOptionsVideoComponent';

    public cullingPeriods: Array<SelectOption<number>> = [
        new SelectOption<number>(7, '1 week'),
        new SelectOption<number>(14, '2 weeks'),
        new SelectOption<number>(28, '4 weeks')
    ];

    @HostBinding()
    public id: string = 'em-settings-options-video';
    public settingAutoDownload: KeyValuePairModel;

    public settingCullingPeriod: KeyValuePairModel;
    public settingDownloadRootPath: KeyValuePairModel;
    public videoFormGroup: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _formBuilder: FormBuilder,
        private readonly _settingService: SettingService,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsOptionsVideoComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsOptionsVideoComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsOptionsVideoComponent.className, this.saveAllChangesProcessText);

        this.addSaveAllAction(this);

        this.videoFormGroup = this._formBuilder.group({
            cullingPeriod: ['', Validators.compose([])],
            autoDownload: ['', Validators.compose([])],
            downloadRootPath: ['', Validators.compose([Validators.required])],
        });
        this.formGroupTracker.track(this.videoFormGroup);

        this.addSubscription(this.observableHandlerBase(this.videoFormGroup.controls.autoDownload.valueChanges, this.formValuesChangeProcess).subscribe(() => this.onAutoDownloadChanges()), this.formValuesChangeProcess);
        this.addSubscription(this.observableHandlerBase(this.videoFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.onFormGroupValueChanges()), this.formValuesChangeProcess);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._settingService.getSetting('AutoDownloadCutoffPeriod', 'VideoDownloadSettings', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingCullingPeriod = result;
                        this.changeTracker.track(result);
                        this.videoFormGroup.controls.cullingPeriod.setValue(parseInt(result.value, 10));
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('AutoDownload', 'VideoDownloadSettings', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingAutoDownload = result;
                        this.changeTracker.track(result);
                        this.videoFormGroup.controls.autoDownload.setValue(BooleanUtility.toBoolean(result.value));
                    }
                    return true;
                })
            ),
            this._settingService.getSetting('DownloadRootPath', 'VideoDownloadSettings', process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settingDownloadRootPath = result;
                        this.changeTracker.track(result);
                        this.videoFormGroup.controls.downloadRootPath.setValue(result.value);
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.settingCullingPeriod.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('AutoDownloadCutoffPeriod', 'VideoDownloadSettings', this.settingCullingPeriod.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingAutoDownload.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('AutoDownload', 'VideoDownloadSettings', this.settingAutoDownload.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
            of(this.settingDownloadRootPath.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._settingService.setSetting('DownloadRootPath', 'VideoDownloadSettings', this.settingDownloadRootPath.value, process);
                    } else {
                        return of(true);
                    }
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(result);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._settingService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    private onAutoDownloadChanges(): void {
        const value = this.videoFormGroup.controls.autoDownload.value;
        if (value === true) {
            this.videoFormGroup.controls.cullingPeriod.setValidators(Validators.compose([Validators.required]));
            this.videoFormGroup.controls.cullingPeriod.enable();
        } else {
            this.videoFormGroup.controls.cullingPeriod.setValidators(Validators.compose([]));
            this.videoFormGroup.controls.cullingPeriod.disable();
        }
    }

    private onFormGroupValueChanges(): void {
        this.updateModels();
        this.updateSaveAllAction(this);
    }

    private updateModels(): void {
        const formModel = this.videoFormGroup.value;

        if (!this.isNullOrUndefined(this.settingCullingPeriod) && !this.isNullOrUndefined(formModel.cullingPeriod)) {
            this.settingCullingPeriod.value = StringUtility.toString(formModel.cullingPeriod);
        }
        if (!this.isNullOrUndefined(this.settingAutoDownload) && !this.isNullOrUndefined(formModel.autoDownload)) {
            if (formModel.autoDownload === true) {
                this.settingAutoDownload.value = 'True';
            } else {
                this.settingAutoDownload.value = 'False';
            }
        }
        if (!this.isNullOrUndefined(this.settingDownloadRootPath) && !this.isNullOrUndefined(formModel.downloadRootPath)) {
            this.settingDownloadRootPath.value = formModel.downloadRootPath;
        }
    }
}
