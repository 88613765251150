<div mat-dialog-title>
    <div fxLayout="row"
        fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Register Summary
            </div>
        </div>
    </div>
</div>
<div mat-dialog-content
    fxLayout="column">
    <rift-settings-counting-register-summary [registerType]="data.registerType"
        [register]="data.register">
    </rift-settings-counting-register-summary>
</div>
<div mat-dialog-actions>
    <button matTooltip="Close"
        (click)="onCloseClick()"
        mat-raised-button
        color="primary">Close</button>
    <div fxFlex></div>
</div>