import { NgZone, Directive } from '@angular/core';
import { AddPointBase } from '@rift/components/shared/viewport/base/AddPointBase';
import { JointBase } from '@rift/components/shared/viewport/base/JointBase';
import { ViewPortHelpers } from '@rift/components/shared/viewport/ViewPort.Helpers';
import { ViewPortModeEnum } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { ShapeModel } from '@rift/models/restapi/ShapeModel';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { Settings, GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { ColorUtility } from '@shared/utility/Color.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';

@Directive()
export class SegmentBase extends DisplayItem {
    public click: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public drag: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();

    public editEnabled: boolean = true;
    public mouseDown: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public mouseOut: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public mouseOver: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public pressMove: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public pressUp: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();

    protected _addPoint: AddPointBase = null;
    protected _base: createjs.Shape = new createjs.Shape();
    protected _fromJoint: JointBase = null;
    protected _isDragging: boolean = false;
    protected _lastDragLocation: createjs.Point = null;
    protected _toJoint: JointBase = null;

    public constructor(
        private readonly _zoneSegmentBase: NgZone,
        protected readonly _shapeBase: any,
        protected readonly _loadQueueBase: ViewPortLoadQueueService,
        protected readonly _shapeModel: ShapeModel) {
        super(_zoneSegmentBase);

        this._zoneSegmentBase.runOutsideAngular(() => {
            if (this._loadQueueBase.isLoaded === true) {
                this.onLoadQueueComplete();
            } else {
                this.addSubscription(this._loadQueueBase.loaded.subscribe(() => this.onLoadQueueComplete()));
            }

            this.container.addChild(this._base);

            this.addEventHandlers();
        });
    }

    public get addPoint(): AddPointBase {
        return this._zoneSegmentBase.runOutsideAngular(() => this._addPoint);
    }

    public set addPoint(value: AddPointBase) {
        this._zoneSegmentBase.runOutsideAngular(() => {
            if (this._addPoint !== value) {
                if (!isNullOrUndefined(this._addPoint)) {
                    this.container.removeChild(this._addPoint.container);
                }
                this._addPoint = value;
                this._addPoint.mode = this.mode;
                this._addPoint.fromJoint = this.fromJoint;
                this._addPoint.toJoint = this.toJoint;
                this.container.addChild(this._addPoint.container);
                this.addSubscription(this._addPoint.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));
            }
        });
    }

    public bringToFront(): void {
    }

    public get fromJoint(): JointBase {
        return this._zoneSegmentBase.runOutsideAngular(() => this._fromJoint);
    }

    public set fromJoint(value: JointBase) {
        this._zoneSegmentBase.runOutsideAngular(() => {
            if (this._fromJoint !== value) {
                this._fromJoint = value;
                if (!isNullOrUndefined(this.addPoint)) {
                    this.addPoint.fromJoint = value;
                }
            }
        });
    }

    public get isDragging(): boolean {
        return this._zoneSegmentBase.runOutsideAngular(() => this._isDragging);
    }

    public set isDragging(value: boolean) {
        this._zoneSegmentBase.runOutsideAngular(() => {
            if (this._isDragging !== value) {
                this._isDragging = value;
            }
        });
    }

    public onDestroy(): void {
        this._zoneSegmentBase.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();
            this.container.removeAllChildren();
            this._base.uncache();

            this._base = null;
        });
    }

    public get shapeModel(): ShapeModel {
        return this._zoneSegmentBase.runOutsideAngular(() => this._shapeModel);
    }

    public get toJoint(): JointBase {
        return this._zoneSegmentBase.runOutsideAngular(() => this._toJoint);
    }

    public set toJoint(value: JointBase) {
        this._zoneSegmentBase.runOutsideAngular(() => {
            if (this._toJoint !== value) {
                this._toJoint = value;
                if (!isNullOrUndefined(this.addPoint)) {
                    this.addPoint.toJoint = value;
                }
            }
        });
    }

    public update(): void {
        this._zoneSegmentBase.runOutsideAngular(() => {
            this._base.graphics.clear();
            if (!isNullOrUndefined(this.fromJoint) && !isNullOrUndefined(this.toJoint)) {
                const pa = ViewPortHelpers.devicePointToViewPortPoint(this.fromJoint.point);
                const pb = ViewPortHelpers.devicePointToViewPortPoint(this.toJoint.point);

                let subLineColor: string;
                if (this.isDragging === true) {
                    subLineColor = '#00296d';
                } else if (this.isMouseOver === true) {
                    subLineColor = '#4286f4';
                } else {
                    subLineColor = '#ffffff';
                }

                const shapeSettings = Settings.register.colors[this._shapeBase.register.registerBaseModel.registerIndex];
                const grabSettings = Settings.grabBox;

                // Draw everything onto the same shape
                // this should save a lot of memory when caching stuff.

                const lineWidth = 30; // This is the width of the grab line, max value
                const halfLine = (lineWidth / 2);
                const width = Math.abs(pa.x - pb.x) + lineWidth;
                const height = Math.abs(pa.y - pb.y) + lineWidth;

                this._base.graphics.clear();

                this.drawLine(this._base, pa, pb, 6, shapeSettings.fillColor, shapeSettings.segment.lineAlpha);

                if (this.mode === ViewPortModeEnum.edit) {
                    this.drawLine(this._base, pa, pb, 2, subLineColor, 1);
                }

                this.drawLine(this._base, pa, pb, 30, grabSettings.fillColor, grabSettings.lineAlpha);

                this._base.cache(Math.min(pa.x, pb.x) - halfLine, Math.min(pa.y, pb.y) - halfLine, width, height, GLOBAL_CACHE_SCALE);

                this.requireStageUpdate.next();
            }
        });
    }

    protected addEventHandlers(): void {
        this._zoneSegmentBase.runOutsideAngular(() => {
            this._base.on('click', this.onClick.bind(this));
            this._base.on('mouseover', this.onMouseOver.bind(this));
            this._base.on('mouseout', this.onMouseOut.bind(this));
            this._base.on('mousedown', this.onMouseDown.bind(this));
            this._base.on('pressmove', this.onPressMove.bind(this));
            this._base.on('pressup', this.onPressUp.bind(this));
        });
    }

    protected drawLine(shape: createjs.Shape, pa: createjs.Point, pb: createjs.Point, lineWidth: number, lineColor: string, lineAlpha: number): void {
        this._zoneSegmentBase.runOutsideAngular(() => {
            const halfLine = (lineWidth / 2);
            const width = Math.abs(pa.x - pb.x) + lineWidth;
            const height = Math.abs(pa.y - pb.y) + lineWidth;

            shape.graphics.setStrokeStyle(lineWidth);
            shape.graphics.beginStroke(ColorUtility.hexToRGBA(lineColor, lineAlpha));
            shape.graphics.moveTo(pa.x, pa.y);
            shape.graphics.lineTo(pb.x, pb.y);
        });
    }

    protected modeSet(): void {
        this._zoneSegmentBase.runOutsideAngular(() => {
            this.update();
            if (!isNullOrUndefined(this.addPoint)) {
                this.addPoint.mode = this.mode;
            }
            super.modeSet();
        });
    }

    protected onClick(event: createjs.MouseEvent): void {
        this._zoneSegmentBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.click.next(new DisplayItemMouseEvent(this, event));
            }
        });
    }

    protected onLoadQueueComplete(): void {
    }

    protected onMouseDown(event: createjs.MouseEvent): void {
        this._zoneSegmentBase.runOutsideAngular(() => {
            if (this.editEnabled === true && this.mode === ViewPortModeEnum.edit) {
                this.isMouseDown = true;
                this.update();

                this._lastDragLocation = new createjs.Point(event.localX, event.localY);

                const segmentEvent = new DisplayItemMouseEvent(this, event);
                segmentEvent.lastDragLocation = this._lastDragLocation;
                this.mouseDown.next(segmentEvent);
            }
        });
    }

    protected onMouseOut(event: createjs.MouseEvent): void {
        this._zoneSegmentBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.isMouseOver = false;
                this.update();
                this.mouseOut.next(new DisplayItemMouseEvent(this, event));
            }
        });
    }

    protected onMouseOver(event: createjs.MouseEvent): void {
        this._zoneSegmentBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.isMouseOver = true;
                this.update();
                this.mouseOver.next(new DisplayItemMouseEvent(this, event));
            }
        });
    }

    protected onPressMove(event: createjs.MouseEvent): void {
        this._zoneSegmentBase.runOutsideAngular(() => {
            if (this.editEnabled === true && this.mode === ViewPortModeEnum.edit) {
                if (this.isMouseDown === true) {
                    this.isDragging = true;

                    const segmentEvent = new DisplayItemMouseEvent(this, event);
                    segmentEvent.lastDragLocation = this._lastDragLocation;
                    this.drag.next(segmentEvent);

                    this._lastDragLocation = new createjs.Point(event.localX, event.localY);
                }
                else {
                    this.isDragging = false;
                }

                this.pressMove.next(new DisplayItemMouseEvent(this, event));
            }
        });
    }

    protected onPressUp(event: createjs.MouseEvent): void {
        this._zoneSegmentBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this._lastDragLocation = null;
                this.isMouseDown = false;
                this.isDragging = false;
                this.update();
                this.pressUp.next(new DisplayItemMouseEvent(this, event));
            }
        });
    }

    protected removeEventHandlers(): void {
        this._zoneSegmentBase.runOutsideAngular(() => {
            this._base.removeAllEventListeners();
        });
    }
}
