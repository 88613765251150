// If you update this also update in syncrecording-worker.ts
export enum ValidationFrameTypeEnum {
    video = 0,
    counts = 1,
    targets = 2,
    deviceInfo = 3,
    videoCalibration = 4,
    userCounts = 5,
    bookmarks = 6,
    syncFrameInfo = 7
}
