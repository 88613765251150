export const MAX_CANVAS_FPS = 10;

export const GLOBAL_CACHE_SCALE = 1;

export interface IRegLineSegmentSettings {
    lineAlpha: number;
}

export interface IRegLineColorSettings {
    fillColor: string;
    lineColor: string;
    segment: IRegLineSegmentSettings;
}

export interface IRegLineSettings {
    colors: Array<IRegLineColorSettings>;
}

export interface IGrabBoxSettings {
    fillColor: string;
    lineAlpha: number;
}

export class Settings {
    public static readonly grabBox: IGrabBoxSettings = { fillColor: '#ffffff', lineAlpha: 0.01 };

    public static readonly register: IRegLineSettings = {
        colors: [
            { fillColor: '#11644D', lineColor: '#11644D', segment: { lineAlpha: 1 } },
            { fillColor: '#CE1C37', lineColor: '#CE1C37', segment: { lineAlpha: 1 } },
            { fillColor: '#333333', lineColor: '#333333', segment: { lineAlpha: 1 } },
            { fillColor: '#EEEEEE', lineColor: '#EEEEEE', segment: { lineAlpha: 1 } },
            { fillColor: '#1E90FF', lineColor: '#1E90FF', segment: { lineAlpha: 1 } },
            { fillColor: '#FFFF00', lineColor: '#FFFF00', segment: { lineAlpha: 1 } },
            { fillColor: '#800080', lineColor: '#800080', segment: { lineAlpha: 1 } },
            { fillColor: '#FF8C00', lineColor: '#FF8C00', segment: { lineAlpha: 1 } },
            { fillColor: '#808080', lineColor: '#808080', segment: { lineAlpha: 1 } },
            { fillColor: '#800000', lineColor: '#800000', segment: { lineAlpha: 1 } },
            { fillColor: '#000080', lineColor: '#000080', segment: { lineAlpha: 1 } },
            { fillColor: '#808000', lineColor: '#808000', segment: { lineAlpha: 1 } },
            { fillColor: '#33FFCC', lineColor: '#33FFCC', segment: { lineAlpha: 1 } },
            { fillColor: '#FF00FF', lineColor: '#FF00FF', segment: { lineAlpha: 1 } },
            { fillColor: '#008080', lineColor: '#008080', segment: { lineAlpha: 1 } },
            { fillColor: '#00FF00', lineColor: '#00FF00', segment: { lineAlpha: 1 } },
            { fillColor: '#11644D', lineColor: '#11644D', segment: { lineAlpha: 1 } },
            { fillColor: '#CE1C37', lineColor: '#CE1C37', segment: { lineAlpha: 1 } },
            { fillColor: '#333333', lineColor: '#333333', segment: { lineAlpha: 1 } },
            { fillColor: '#9b0096', lineColor: '#9b0096', segment: { lineAlpha: 1 } },
            { fillColor: '#1E90FF', lineColor: '#1E90FF', segment: { lineAlpha: 1 } },
            { fillColor: '#FFFF00', lineColor: '#FFFF00', segment: { lineAlpha: 1 } },
            { fillColor: '#800080', lineColor: '#800080', segment: { lineAlpha: 1 } },
            { fillColor: '#FF8C00', lineColor: '#FF8C00', segment: { lineAlpha: 1 } },
            { fillColor: '#808080', lineColor: '#808080', segment: { lineAlpha: 1 } },
            { fillColor: '#800000', lineColor: '#800000', segment: { lineAlpha: 1 } },
            { fillColor: '#000080', lineColor: '#000080', segment: { lineAlpha: 1 } },
            { fillColor: '#808000', lineColor: '#808000', segment: { lineAlpha: 1 } },
            { fillColor: '#33FFCC', lineColor: '#33FFCC', segment: { lineAlpha: 1 } },
            { fillColor: '#FF00FF', lineColor: '#FF00FF', segment: { lineAlpha: 1 } },
            { fillColor: '#008080', lineColor: '#008080', segment: { lineAlpha: 1 } },
            { fillColor: '#00FF00', lineColor: '#00FF00', segment: { lineAlpha: 1 } }
        ],
    };

    //public static readonly grabBox: IGrabBoxSettings = { fillColor: '#00ff00', lineAlpha: 0.05 };
}
