import { SafeSubscriptionBase } from '@shared/base/SafeSubscription.Base';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { isArray, isBoolean } from '@shared/utility/General.Utility';
import { Directive } from '@angular/core';

/**
 * The base service class.
 *
 * @export
 * @abstract
 * @class BaseService
 * @extends {SafeSubscriptionBase}
 */
@Directive()
export abstract class BaseService extends SafeSubscriptionBase {
    public readonly genericProcessText = 'Process';

    protected clearObservableTrackers(): void {
        const keys = Object.keys(this);
        const keysLength = keys.length;
        for (let i = 0; i < keysLength; i++) {
            const value = this[keys[i]];
            if (value instanceof ObservableTracker) {
                value.clear();
            }
        }
    }

    protected isZipResultSuccess(result: boolean | Array<boolean>): boolean {
        if ((isArray(result) && (result as Array<boolean>).every(i => i === true)) || (isBoolean(result) && result === true)) {
            return true;
        } else {
            return false;
        }
    }
}
