import { Component, HostBinding, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    SettingsCountingBaseComponent,
} from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import {
    UnitsOfMeasurementInputValidators,
} from '@rift/directives/unitsofmeasurementinput/UnitsOfMeasurementInput.Directive';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { UnitsOfMeasurementService } from '@rift/service/unitsofmeasurement/UnitsOfMeasurement.Service';
import { IRegisterProperty } from '@rift/shared/IRegisterProperty';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';

@Component({
    selector: 'rift-settings-counting-simple-property',
    templateUrl: './Settings.Counting.SimpleProperty.Component.html',
    styleUrls: ['./Settings.Counting.SimpleProperty.Component.scss']
})
export class SettingsCountingSimplePropertyComponent extends SettingsCountingBaseComponent implements OnChanges {
    public static className: string = 'SettingsCountingSimplePropertyComponent';

    public form: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'rift-settings-counting-reset-timeout';

    @Input()
    public register: RegisterBaseModel = null;

    @Input()
    public get property(): IRegisterProperty {
        return this._property;
    }
    public set property(value: IRegisterProperty) {
        if (this._property !== value) {
            this._property = value;
            if (!this.isNullOrUndefined(this._property) && !this.isNullOrUndefined(this.form)) {
                const validators = [];
                if (!this.isNullOrUndefined(this._property.required) && this._property.required === true) {
                    validators.push(Validators.required);
                }
                if (!this.isNullOrUndefined(this._property.min)) {
                    if (this._property.inputType === 'unitsOfMeasurement') {
                        validators.push(UnitsOfMeasurementInputValidators.min());
                    } else if (this._property.inputType === 'number') {
                        validators.push(Validators.min(this._property.min));
                    } else if (this._property.inputType === 'text') {
                        validators.push(Validators.minLength(this._property.min));
                    }
                }
                if (!this.isNullOrUndefined(this._property.max)) {
                    if (this._property.inputType === 'unitsOfMeasurement') {
                        validators.push(UnitsOfMeasurementInputValidators.max());
                    } else if (this._property.inputType === 'number') {
                        validators.push(Validators.max(this._property.max));
                    } else if (this._property.inputType === 'text') {
                        validators.push(Validators.maxLength(this._property.min));
                    }
                }
                this.form.controls.value.setValidators(validators);
            }
        }
    }

    private _property: IRegisterProperty;

    public constructor(
        private readonly _unitsOfMeasurementService: UnitsOfMeasurementService,
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsCountingSimplePropertyComponent.className, 'Form values change');

        this.form = this._formBuilder.group({ value: [] });

        this.addSubscription(this.observableHandlerBase(this.form.controls.value.valueChanges, this.formValuesChangeProcess).subscribe(value => {
            if (!this.isNullOrUndefined(this.register)) {
                if (!this.isNullOrUndefined(this.property.toModelValue)) {
                    this.register[this.property.propertyName] = this.property.toModelValue(value);
                } else {
                    switch (this.property.inputType) {
                        case 'unitsOfMeasurement':
                            this.register[this.property.propertyName] = parseFloat(value);
                            break;
                        case 'number':
                            this.register[this.property.propertyName] = parseFloat(value);
                            break;
                        case 'text':
                            this.register[this.property.propertyName] = value.toString();
                            break;
                        case 'checkbox':
                            this.register[this.property.propertyName] = value;
                            break;
                    }
                }
            }
        }), this.formValuesChangeProcess);

        this.formGroupTracker.track(this.form);

        this.initConnectionState();
    }

    public getSettingsDescription(): string {
        if (this.isNullOrUndefined(this.register) || this.isNullOrUndefined(this.property)) {
            return '';
        } else {
            const value = this.getFromModel();
            if (!this.isNullOrUndefined(value) && !Number.isNaN(value)) {
                switch (this.property.inputType) {
                    case 'number':
                    case 'text':
                        if (!this.isNullOrUndefined(this.property.matSuffix)) {
                            return value.toString() + ' ' + this.property.matSuffix;
                        } else {
                            return value;
                        }
                    case 'unitsOfMeasurement':
                        return this._unitsOfMeasurementService.parseUOMString(`uom:{ "metricValue": ${value}, "metricUnit": ${this.property.metricUnit}, "imperialUnit": ${this.property.imperialUnit}, "unitShortName": true }`);
                }
            }
            return '';
        }
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this.register) ? false : this.register.propertyHasChanges('value');
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this.register = changes.register.currentValue;
            this.setDefaults();
            this.setFormValues();
        }
    }

    protected offline(): void {
        super.offline();
        this.form.disable();
    }

    protected online(): void {
        super.online();
        this.form.enable();
    }

    private setDefaults(): void {
        if (!this.isNullOrUndefined(this.property.defaultModelValue) && this.isNullOrUndefined(this.register[this.property.propertyName])) {
            if (!this.isNullOrUndefined(this.property.toModelValue)) {
                this.register[this.property.propertyName] = this.property.toModelValue(this.property.defaultModelValue);
            } else {
                this.register[this.property.propertyName] = this.property.defaultModelValue;
            }
        }
    }

    private setFormValues(): void {
        this.form.controls.value.setValue(this.getFromModel(), { emitEvent: false });
    }

    private getFromModel(): any {
        const modelValue = this.register[this.property.propertyName];
        if (!this.isNullOrUndefined(this.property.defaultModelValue) && this.isNullOrUndefined(modelValue)) {
            if (!this.isNullOrUndefined(this.property.fromModelValue)) {
                return this.property.fromModelValue(this.property.defaultModelValue);
            } else {
                return this.property.defaultModelValue;
            }
        } else if (!this.isNullOrUndefined(modelValue)) {
            if (!this.isNullOrUndefined(this.property.fromModelValue)) {
                return this.property.fromModelValue(modelValue);
            } else {
                switch (this.property.inputType) {
                    case 'unitsOfMeasurement':
                        return parseFloat(modelValue);
                    case 'number':
                        return parseFloat(modelValue);
                    case 'text':
                        return modelValue.toString();
                    case 'checkbox':
                        return modelValue;
                }
            }
        }
    }
}
