import { DeviceModel, IDeviceModel } from '@rift/models/restapi/Device.Model';
import { LineModel, ILineModel } from '@rift/models/restapi/Line.Model';
import { RegisterBaseCollectionModel, IRegisterBaseCollectionModel } from '@rift/models/restapi/RegisterBaseCollection.Model';
import { TargetModel, ITargetModel } from '@rift/models/websocket/Target.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { ValidationFrameTypeEnum } from '@shared/enum/ValidationFrameType.Enum';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';
import { GlobalModel, IGlobalModel } from '@rift/models/restapi/Global.Model';
import { TimeSetupModel, ITimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { VideoSettingsModel, IVideoSettingsModel } from '@rift/models/websocket/VideoSettings.Model';
import { IValidationSyncFrameEntryModel, ValidationSyncFrameEntryModel } from './ValidationSyncFrameEntry.Model';

export interface IValidationDataFrameModel {
    frameType: Array<number>;
    frameNumber: number;
    targets: Array<ITargetModel>;
    counts: Array<number>;
    userCounts: Array<number>;
    video: string;
    devices: Array<IDeviceModel>;
    globalData: IGlobalModel;
    timeData: ITimeSetupModel;
    lines: Array<ILineModel>;
    registers: IRegisterBaseCollectionModel;
    videoSettings: IVideoSettingsModel;
    polygons: any;
    globalBookmarks: any;
    sessionBookmarks: any;
    syncFrames: Array<IValidationSyncFrameEntryModel>;
}

export class ValidationDataFrameModel extends BaseModel implements IWebSocketModel {
    public readonly isIWebSocketModel: boolean = true;

    public frameType: Array<ValidationFrameTypeEnum> = null;
    public frameNumber: number = null;

    public targets: Array<TargetModel> = null;
    public counts: Array<number> = null;
    public userCounts: Array<number> = null;
    public video: string = null;
    public devices: Array<DeviceModel> = null;
    public globalData: GlobalModel = null;
    public timeData: TimeSetupModel = null;
    public lines: Array<LineModel> = null;
    public registers: RegisterBaseCollectionModel = null;
    public videoSettings: VideoSettingsModel = null;
    public polygons: any = null;
    public globalBookmarks: any = null;
    public sessionBookmarks: any = null;
    public syncFrames: Array<IValidationSyncFrameEntryModel> = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-ValidationDataFrameModel');

        this.frameType = message.frameType;
        this.counts = message.counts;
        this.userCounts = message.userCounts;

        this.targets = WebSocketModelUtility.loadFromArray(message.targets, TargetModel);

        this.devices = RestModelUtility.loadFromArray(message.targets, DeviceModel);
        this.globalData = RestModelUtility.loadFrom(message.globalData, GlobalModel);
        this.timeData = RestModelUtility.loadFrom(message.timeData, TimeSetupModel);
        this.lines = RestModelUtility.loadFromArray(message.lines, LineModel);
        this.registers = RestModelUtility.loadFrom(message.registers, RegisterBaseCollectionModel);
        this.videoSettings = WebSocketModelUtility.loadFrom(message.videoSettings, VideoSettingsModel, true);
        this.syncFrames = WebSocketModelUtility.loadFromArray(message.syncFrames, ValidationSyncFrameEntryModel, true);
    }

    public toWebSocketMessage(): void {
        throw new Error('Method not implemented.');
    }
}
