import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class MetaDataKeyModel extends BaseModel implements IRestModel {

    public metaDataKeyId: number = null;
    public name: string = null;
    public readonly isIRestModel = true;

    public constructor() {
        super();

        this.registerProperty('name');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-MetaDataKeyModel');

        this.setPropertyOriginalValue('name', this.name);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
