import { Subject } from 'rxjs';
import { ITrackChanges } from '@shared/interface/ITrackChanges';

/**
 * The state of a change tracked property.
 *
 * @export
 * @class PropertyState
 */
export class PropertyState {

    /**
     * True if the property value is an ChangeTrackerArray. else false.
     *
     * @type {boolean}
     * @memberof PropertyState
     */
    public isChangeTrackerArray: boolean = false;

    /**
     * True if the property value implements ITrackPropertyChanges. else false.
     *
     * @type {boolean}
     * @memberof PropertyState
     */
    public isChangeTrackerModel: boolean = false;
    /**
     * The original value of the property.
     *
     * @type {*}
     * @memberof PropertyState
     */
    public originalValue: any = null;
    /**
     * True if the property has change. elsel false.
     *
     * @type {boolean}
     * @memberof PropertyState
     */
    public hasChanged: boolean = false;
    /**
     * True if the property should be auto proxy by base class.
     *
     * @type {boolean}
     * @memberof PropertyState
     */
    public proxy: boolean = true;

    private _propertyName: string = null;

    /**
     * Creates an instance of PropertyState.
     *
     * @param {string} propertyName
     * @memberof PropertyState
     */
    public constructor(propertyName: string) {
        this._propertyName = propertyName;
    }
    /**
     * The name of the property being tracked.
     *
     * @readonly
     * @type {string}
     * @memberof PropertyState
     */
    public get propertyName(): string { return this._propertyName; }
}

/**
 * Interface for implementing change tracking into models.
 *
 * @export
 * @interface ITrackPropertyChanges
 */
export interface ITrackPropertyChanges extends ITrackChanges {
    /**
     * Fired when to property changes.
     *
     * @type {Subject<string>}
     * @memberof ITrackPropertyChanges
     */
    propertyChanged: Subject<string>;
    /**
     * Registers a property for change tracking.
     *
     * @param {string} propertyName The name of the tracked property.
     * @param {boolean} isChangeTrackerArray
     * @param {boolean} proxy True if property should be auto proxy by base
     * @memberof ITrackPropertyChanges
     */
    registerProperty(propertyName: string, isChangeTrackerArray: boolean, proxy: boolean): void;
    /**
     * Registers a {ChangeTrackerArray} property for change tracking.
     *
     * @param {string} propertyName The name of the tracked property.
     * @param {boolean} proxy True if property should be auto proxy by base
     * @memberof ITrackPropertyChanges
     */
    registerChangeTrackerArray(propertyName: string, proxy: boolean): void;
    /**
     * Registers a {ITrackPropertyChanges} property for change tracking.
     *
     * @param {string} propertyName
     * @param {boolean} proxy
     * @memberof ITrackPropertyChanges
     */
    registerChangeTrackerModel(propertyName: string, proxy: boolean): void;
    /**
     * Sets the original value of a tracked property.
     *
     * @param {string} propertyName The name of the tracked property.
     * @param {*} value The property value.
     * @memberof ITrackPropertyChanges
     */
    setPropertyOriginalValue(propertyName: string, value: any): void;
    /**
     * Returns true if the propertyName has changes. else false.
     *
     * @param {string} propertyName The name of the tracked property.
     * @returns {boolean} true if the propertyName has changes. else false.
     * @memberof ITrackPropertyChanges
     */
    propertyHasChanges(propertyName: string): boolean;
    /**
     * Returns '*' if the propertyName has changes. else ''.
     *
     * @param {string} propertyName The name of the tracked property.
     * @returns {string} '*' if the propertyName has changes. else ''.
     * @memberof ITrackPropertyChanges
     */
    propertyHasChangesText(propertyName: string): string;
    /**
     * Gets the original value of a property.
     *
     * @param {string} propertyName The name of the tracked property.
     * @returns {*} The original property value.
     * @memberof ITrackPropertyChanges
     */
    getPropertyOriginalValue(propertyName: string): any;
    /**
     * Handels the property changing.
     *
     * @param {string} propertyName The name of the tracked property.
     * @param {*} newValue The new property value.
     * @memberof ITrackPropertyChanges
     */
    onPropertyChanged(propertyName: string, newValue: any): void;

    /**
     * Checks for changes excluding propertyNames.
     *
     * @param {...Array<string>} propertyNames
     * @returns {boolean}
     * @memberof ITrackPropertyChanges
     */
    hasChangesExcluding(...propertyNames: Array<string>): boolean;
}
