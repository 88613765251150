import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { MatchedPropertyModel } from '@em/models/restapi/MatchedProperty.Model';

export class AdvancedSearchResultModel extends BaseModel implements IRestModel {
    public device: DeviceModel = null;
    public message: string = null;
    public propertiesMatched: Array<MatchedPropertyModel> = null;
    public readonly isIRestModel = true;

    public result: boolean = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any, upperCase?: boolean): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-AdvancedSearchResultModel', null, upperCase);
        this.device = RestModelUtility.loadFrom(restModel.Device, DeviceModel);
        this.propertiesMatched = RestModelUtility.loadFromArray(restModel.PropertiesMatched, MatchedPropertyModel);
    }

    public toRestApiModel() {
        return RestModelUtility.toJson(this);
    }
}
