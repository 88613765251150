import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';

/**
 * Converts larke number to K, M
 * 5000 = 5K
 * 5000000 = 5M
 *
 * @export
 * @class LargeNumberPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'largeNumber' })
export class LargeNumberPipe implements PipeTransform {
    /**
     * Transforms the value to large number formated string.
     *
     * @param {number} value The value to convert.
     * @param {number} [fractionDigits] The fraction to round to.
     * @returns {string} The large number representation of the value.
     * @memberof LargeNumberPipe
     */
    public transform(value: number, fractionDigits?: number): string {
        if (isNullOrUndefined(fractionDigits)) {
            fractionDigits = 0;
        }

        if (value > 1000 && value < 1000000) {
            return (value / 1000).toFixed(fractionDigits) + 'K';
        } else if (value > 1000000) {
            return (value / 1000000).toFixed(fractionDigits) + 'M';
        }

        return isNullOrUndefined(value) ? '' : StringUtility.toString(value);
    }
}
