import { AdvancedSearchResultModel } from '@em/models/restapi/AdvancedSearchResult.Model';

export enum ResultTypeEnum {
    device = 0,
    internal = 1,
}

export interface ISearchItem {
    id: number;
    groupId: number;
    name: string;
    description: string;
    values?: Array<string>;
    routerLink?: string;
    matIcon?: string;
    faIcon?: string;
    isAdmin?: boolean;
    isInstaller?: boolean;
    isSystemAdmin?: boolean;
    isUser?: boolean;
}

export interface IResultGroup {
    id: number;
    name: string;
}

export interface IResult {
    id: string | number;
    type: ResultTypeEnum;
    groupId: number;
    deviceSearchResult?: AdvancedSearchResultModel;
    searchItem?: ISearchItem;
}


export const DevicesGroup: IResultGroup = { id: 1, name: 'Devices' };
export const SettingsGroup: IResultGroup = { id: 2, name: 'Settings' };
export const Groups: Array<IResultGroup> = [
    DevicesGroup,
    SettingsGroup
];

export const SearchItems: Array<ISearchItem> = [
    { id: 0, groupId: SettingsGroup.id, isAdmin: true, matIcon: 'import_contacts', name: 'Address Book', description: 'Add, edit and remove groups from the Address Book', routerLink: '/settings/addressbook' },
    { id: 1, groupId: SettingsGroup.id, isAdmin: true, matIcon: 'person', name: 'Manage Users', description: 'Create and manage users of the system', routerLink: '/settings/manageusers' },
    { id: 2, groupId: SettingsGroup.id, isAdmin: true, matIcon: 'info', name: 'Meta Data', description: 'Advanced global options for Estate Manager', routerLink: '/settings/metadata' },
    { id: 3, groupId: SettingsGroup.id, isAdmin: true, faIcon: 'external-link-alt', name: 'Connection Forwarding', description: 'Configure forwarding destinations for counters', routerLink: '/settings/connectionforwarding' },
    { id: 4, groupId: SettingsGroup.id, matIcon: 'access_time', name: 'Schedules', description: 'Schedules can regularly collect data from devices', routerLink: '/settings/schedules/list/active' },
    { id: 5, groupId: SettingsGroup.id, isAdmin: true, matIcon: 'lock', name: 'Licence', description: 'Licence Server', routerLink: '/settings/licence' },
    { id: 6, groupId: SettingsGroup.id, faIcon: 'sort-numeric-down', name: 'Firmware Version Summary', description: 'View a summary of all devices firmware', routerLink: '/settings/firmwarevirsionsummary' },
    { id: 7, groupId: SettingsGroup.id, isAdmin: true, faIcon: 'rocket', name: 'Task Launcher', description: 'View and run tasks such as firmware upgrader', routerLink: '/settings/tasklaucher/list/active' },
    { id: 8, groupId: SettingsGroup.id, isAdmin: true, matIcon: 'settings', name: 'Options', description: 'Advanced global options for Estate Manager', routerLink: '/settings/options/dataculling' },
    { id: 9, groupId: SettingsGroup.id, isAdmin: true, matIcon: 'notifications', name: 'Notifications', description: 'Configure custom and system notifications for Estate Manager', routerLink: '/settings/notifications' },
    { id: 10, groupId: SettingsGroup.id, faIcon: 'thermometer-empty', name: 'Service Status And Configuration', description: 'Reports service status and allows configuration', routerLink: '/settings/servicestatusandconfiguration' },
    { id: 11, groupId: SettingsGroup.id, isAdmin: true, faIcon: 'plug', name: 'Outbound connections', description: 'Outbound device connection configuration', routerLink: '/settings/outboundconnections' },
];
