import { DeviceModel } from '@em/models/restapi/Device.Model';
import { ScheduleResultModel } from '@em/models/restapi/ScheduleResult.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { DeviceResultTypeEnum } from '@shared/enum/DeviceResultType.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';


export class ScheduleDeviceResultModel extends BaseModel implements IRestModel {
    public device: DeviceModel = null;
    public type: DeviceResultTypeEnum = null;
    public resultDescription: string = null;
    public scheduleResult: ScheduleResultModel = null;
    public readonly isIRestModel = true;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ScheduleDeviceResultModel');

        this.device = RestModelUtility.loadFrom(restModel.Device, DeviceModel);
        this.scheduleResult = RestModelUtility.loadFrom(restModel.ScheduleResult, ScheduleResultModel);
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
