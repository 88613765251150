import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

export class ObservableUtility {
    public static zipArrayCheck(observables: Observable<any>[], defaultObservable: Observable<any>): Observable<any>[] {
        return isNullOrUndefined(observables) || observables.length === 0 ? [defaultObservable] : observables;
    }

    public static callNext<TOutput>(subs: Observable<TOutput>[], index: number): Observable<boolean> {
        if (!isNullOrUndefined(subs) && subs.length > 0) {
            return subs[index].pipe(
                flatMap(() => {
                    if (index < (subs.length - 1)) {
                        return ObservableUtility.callNext(subs, (index + 1));
                    } else {
                        return of(true);
                    }
                }),
            );
        } else {
            return of(true);
        }
    }

    public static callNextWhen<TItem>(subs: Observable<TItem>[], checkInterval: number, whenClauseHandler: (item: TItem, index: number) => boolean, index: number = null): Observable<boolean> {
        index = index || 0;
        if (!isNullOrUndefined(subs) && subs.length > 0 && index < subs.length) {
            return subs[index].pipe(
                flatMap(item => {
                    if (whenClauseHandler(item, index) === true) {
                        return ObservableUtility.callNextWhen(subs, checkInterval, whenClauseHandler, (index + 1));
                    } else {
                        return ObservableUtility.waitUntilWhen(checkInterval, item, ObservableUtility.callNextWhen(subs, checkInterval, whenClauseHandler, (index + 1)), whenClauseHandler, index);
                    }
                }),
            );
        }
        return of(true);
    }

    private static waitUntilWhen<TItem, TOut>(checkInterval: number, item: TItem, completeAction: Observable<TOut>, whenClauseHandler: (item: TItem, index: number) => boolean, index: number): Observable<TOut> {
        if (whenClauseHandler(item, index) === true) {
            return completeAction;
        } else {
            return timer(checkInterval).pipe(
                flatMap(() => this.waitUntilWhen(checkInterval, item, completeAction, whenClauseHandler, index)),
            );
        }
    }
}
