import { Component, HostBinding, HostListener, Injector, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsCountingMenuBaseComponent } from '@rift/components/settings/counting/base/Settings.Counting.MenuBase.Component';
import { SettingsCountingRegisterSettingsComponent } from '@rift/components/settings/counting/shared/settings/Settings.Counting.RegisterSettings.Component';
import { SettingsCountingRegisterSummaryComponent } from '@rift/components/settings/counting/shared/summary/Settings.Counting.RegisterSummary.Component';
import { RegisterTypeViewModel } from '@rift/components/settings/counting/viewmodels/RegisterType.ViewModel';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { PolygonModel } from '@rift/models/restapi/Polygon.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { LineService } from '@rift/service/data/line/Line.Service';
import { PolygonService } from '@rift/service/data/polygon/Polygon.Service';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { RegisterTypeConfig } from '@rift/shared/Settings.RegisterConfig';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { CountModeEnum } from '@shared/enum/CountMode.Enum';
import { RegisterTypeEnum, RegisterTypeEnumHelpers } from '@shared/enum/RegisterType.Enum';
import { RegisterTypeCategoryEnum } from '@shared/enum/RegisterTypeCategoryEnum';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isFunction, isNullOrUndefined } from '@shared/utility/General.Utility';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { IPosition } from 'angular2-draggable';
import { ISize } from 'angular2-draggable/lib/models/size';
import { from, Observable, of, zip } from 'rxjs';
import { flatMap, map, zipAll } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { getRegisterTypeInCategory } from '@rift/shared/getRegisterTypeInCategory';

@Component({
    selector: 'rift-settings-counting-add-register',
    templateUrl: './Settings.Counting.AddRegister.Component.html',
    styleUrls: ['./Settings.Counting.AddRegister.Component.scss'],
})
export class SettingsCountingAddRegisterComponent extends SettingsCountingMenuBaseComponent implements OnChanges, ISaveAllChanges {
    public static readonly className: string = 'SettingsCountingAddRegisterComponent';

    @Input()
    public get bounds(): HTMLElement {
        return this._bounds;
    }
    public set bounds(value: HTMLElement) {
        this._bounds = value;
        this.checkPosition();
    }

    @Input()
    public get zIndex(): number {
        return this._zIndex;
    }
    public set zIndex(value: number) {
        this._zIndex = value;
    }

    @Input()
    public lines: Array<LineModel> = null;

    @Input()
    public masterDevice: DeviceModel = null;

    @Input()
    public polygons: Array<PolygonModel> = null;

    @Input()
    public registers: Array<RegisterBaseModel> = null;
    public addLines: Array<LineModel> = null;
    public addPolygons: Array<PolygonModel> = null;

    public addRegister: RegisterBaseModel = null;

    @ViewChild('settings', { static: true })
    public settings: SettingsCountingRegisterSettingsComponent;

    @ViewChild('stepper', { static: true })
    public stepper: MatStepper;

    @ViewChild('summary', { static: false })
    public summary: SettingsCountingRegisterSummaryComponent;

    public lineRegisterTypes: Array<RegisterTypeViewModel> = null;
    public nameFormGroup: FormGroup = null;

    @HostBinding()
    public id: string = 'rift-settings-counting-add-register';
    public selectedRegisterError: string = null;
    public selectedRegisterType: RegisterTypeViewModel = null;
    public selectedRegisterValid: boolean = false;

    public size: ISize;

    @LocalStorage(SettingsCountingAddRegisterComponent.className, 'position')
    public position: IPosition;

    @LocalStorage(SettingsCountingAddRegisterComponent.className, 'show')
    public show: boolean;

    public zoneRegisterTypes: Array<RegisterTypeViewModel> = null;
    public formValuesChangedProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _render: Renderer2,
        private readonly _eventsService: EventsService,
        private readonly _formBuilder: FormBuilder,
        private readonly _lineService: LineService,
        private readonly _polygonService: PolygonService,
        private readonly _registerService: RegisterService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_render, _injector, _dialog);

        this.formValuesChangedProcess = this.processMonitorService.getProcess(SettingsCountingAddRegisterComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingAddRegisterComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsCountingAddRegisterComponent.className, this.saveAllChangesProcessText);

        this.nameFormGroup = this._formBuilder.group({
            name: ['', Validators.compose([Validators.required, ValidationValidators.registerName,
                (control: AbstractControl) => {
                    let maxLength = 23;

                    if(!isNullOrUndefined(this.addRegister) && (this.addRegister.registerType === RegisterTypeEnum.occupancyMaster || this.addRegister.registerType === RegisterTypeEnum.queueWaitMaster || this.addRegister.registerType === RegisterTypeEnum.fifoOccupancyMaster)){
                        maxLength = 17;
                    }

                    return Validators.maxLength(maxLength)(control);
                }
            ])],
        });

        this.addSubscription(this.observableHandlerBase(this.nameFormGroup.controls.name.valueChanges, this.formValuesChangedProcess).subscribe(value => {
            if (!this.isNullOrUndefined(this.addRegister)) {
                this.addRegister.registerName = value;
            }
        }), this.formValuesChangedProcess);

        this.formGroupTracker.track(this.nameFormGroup);

        this.minWidth = 250;
        this.maxWidth = 650;
        this.minHeight = 430;

        this.initConnectionState();
    }

    public getRegisterNames(): Array<string> {
        return this.registers.map(i => i.registerName.toLocaleLowerCase());
    }

    public get registerNameMaxLength(): number{
        let maxLength = 23;

        if(!isNullOrUndefined(this.addRegister) && (this.addRegister.registerType === RegisterTypeEnum.occupancyMaster || this.addRegister.registerType === RegisterTypeEnum.queueWaitMaster || this.addRegister.registerType === RegisterTypeEnum.fifoOccupancyMaster)){
            maxLength = 17;
        }

        return maxLength;
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.show ? (this.isNullOrUndefined(this.settings) || this.settings.isValid) && this.isValidBase : true;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.masterDevice) && !this.isNullOrUndefined(changes.masterDevice.currentValue)) {
            this.zoneRegisterTypes = [];
            const zoneInCategory = getRegisterTypeInCategory(RegisterTypeCategoryEnum.zone);
            const zoneInCategoryLength = zoneInCategory.length;
            for (let index = 0; index < zoneInCategoryLength; index++) {
                const item = zoneInCategory[index];
                if (RegisterTypeConfig[item].isUserAddable && this.masterDevice.isRegisterTypeCompatible(item)) {
                    this.zoneRegisterTypes.push(new RegisterTypeViewModel(item, this.masterDevice));
                }
            }

            this.lineRegisterTypes = [];
            const lineInCategory = getRegisterTypeInCategory(RegisterTypeCategoryEnum.line);
            const lineInCategoryLength = lineInCategory.length;
            for (let index = 0; index < lineInCategoryLength; index++) {
                const item = lineInCategory[index];
                if (RegisterTypeConfig[item].isUserAddable && this.masterDevice.isRegisterTypeCompatible(item)) {
                    this.lineRegisterTypes.push(new RegisterTypeViewModel(item, this.masterDevice));
                }
            }

            this.nameFormGroup.controls.name.setValidators(Validators.compose([Validators.required, Validators.maxLength(23), ValidationValidators.registerName, ValidationValidators.unique(this.getRegisterNames.bind(this))]));
        }
    }

    public onAddRegisterClick(): void {
        this.saveAllChangesStartBase(this, this.openPleaseWaitSavingDialog());
    }

    public onAssociateSelectedChange(): void {
        if (!this.isNullOrUndefined(this.summary)) {
            this.summary.setAssociatedRegisterConfigsVMs();
        }
    }

    public onRegisterTypeSelected(vm: RegisterTypeViewModel): void {
        this.selectedRegisterType = vm;
        this.addRegister = null;
        this.nameFormGroup.reset();
        this.changeTracker.clear();
        this.validateSelectedRegisterType();
        if (this.selectedRegisterValid === true) {
            this.addRegister = RegisterTypeConfig[this.selectedRegisterType.type].new();
            this.addRegister.registerType = this.selectedRegisterType.type;
            this.addRegister.countMode = CountModeEnum.deferred;
            this.addRegister.tags = [];

            if(this.selectedRegisterType.hasSettingsHeightFiltering){
                const addRegisterAny = this.addRegister as any;
                addRegisterAny.heightFilterEnabled = false;
            }

            this.addRegister.setIndexes();
            this.addRegister.setFlags();
            this.addRegister.commitChanges();
            this.changeTracker.track(this.addRegister);
        }
    }

    public reset(): void {
        this.changeTracker.clearChanges();
        this.addRegister = null;
        this.selectedRegisterType = null;
        this.selectedRegisterError = null;
        this.nameFormGroup.reset();
        this.changeTracker.clear();
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._registerService.addRegister(this.addRegister, process).pipe(
                            map(() => true)
                        );
                    } else {
                        if (process.hasError) {
                            return of(false);
                        } else {
                            return of(true);
                        }
                    }
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(saveAllChangesResult => {
                if (this.isZipResultSuccess(saveAllChangesResult)) {
                    return this._registerService.getRegisters(process).pipe(
                        flatMap(registersResult => {

                            const addedRegister = registersResult.items.find(i => i.registerName === this.addRegister.registerName);

                            this._lineService.clearCache();
                            this._polygonService.clearCache();

                            if (!this.isNullOrUndefined(addedRegister) && addedRegister.isCountModeCompatible === true) {
                                return this._lineService.getLines(process).pipe(
                                    flatMap(linesResult => {

                                        const addLines: LineModel[] = [];
                                        const length = linesResult.items.length;
                                        for (let index = 0; index < length; index++) {
                                            const item = linesResult.items[index];
                                            if (addedRegister.lineIds.indexOf(item.iD) !== -1) {
                                                addLines.push(item);
                                            }
                                        }

                                        return from(addLines).pipe(
                                            map(line => {
                                                if (line.countMode !== this.addRegister.countMode) {
                                                    line.countMode = this.addRegister.countMode;
                                                    return this._lineService.updateLine(line, line.iD, process).pipe(
                                                        map(() => true)
                                                    );
                                                } else {
                                                    return of(true);
                                                }
                                            }),
                                            zipAll(),
                                            flatMap(result => {
                                                if (this.isZipResultSuccess(result)) {
                                                    this.addRegister = null;
                                                    this._eventsService.addedDeviceRegister();

                                                    this.close();

                                                    return of(true);
                                                } else {
                                                    return of(false);
                                                }
                                            }),
                                        );
                                    }),
                                );
                            } else {
                                this.addRegister = null;
                                this._eventsService.addedDeviceRegister();

                                this.close();

                                return of(true);
                            }
                        })
                    );
                } else {
                    return of(saveAllChangesResult);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._registerService.clearCache();
            return of(true);
        });
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    protected offline(): void {
        super.offline();
    }

    protected online(): void {
        super.online();
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.nameFormGroup.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.nameFormGroup.enable();
    }

    private validateSelectedRegisterType(): void {
        const registersLength = this.registers.length;
        this.selectedRegisterError = null;
        this.selectedRegisterValid = true;

        if (isFunction(this.selectedRegisterType.registerTypeConfig.validateSelectedRegisterType)) {
            this.selectedRegisterError = this.selectedRegisterType.registerTypeConfig.validateSelectedRegisterType(this.selectedRegisterType.registerTypeConfig, this.registers, this.lines);
            if (!this.isNullOrUndefined(this.selectedRegisterError)) {
                this.selectedRegisterValid = false;
            } else {
                this.selectedRegisterValid = true;
            }
        }

        if (registersLength >= 32) {
            this.selectedRegisterValid = false;
            this.selectedRegisterError = 'No more registers can be added';
        }
    }
}
