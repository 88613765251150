import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IVideoCroppingWindowModel extends IRestModel {
    xPos1: number;
    yPos1: number;
    xPos2: number;
    yPos2: number;
}

export class VideoCroppingWindowModel extends BaseModel implements IVideoCroppingWindowModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public xPos1: number = null;
    public yPos1: number = null;
    public xPos2: number = null;
    public yPos2: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-VideoCroppingWindowModel');
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }

    public toInterface(): IVideoCroppingWindowModel {
        return {
            xPos1: this.xPos1,
            yPos1: this.yPos1,
            xPos2: this.xPos2,
            yPos2: this.yPos2,
        } as IVideoCroppingWindowModel;
    }
}
