import { Component, Injector, OnDestroy, OnInit, ViewChild, HostBinding, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { SettingsManageUsersAddComponent, SettingsManageUsersAddData, SettingsManageUsersAddResult } from '@em/components/settings/manageusers/add/Settings.ManageUsers.Add.Component';
import { SettingsManageUsersEditComponent, SettingsManageUsersEditData, SettingsManageUsersEditResult } from '@em/components/settings/manageusers/edit/Settings.ManageUsers.Edit.Component';
import { RoleModel } from '@em/models/restapi/Role.Model';
import { UserModel } from '@em/models/restapi/User.Model';
import { UserCollectionModel } from '@em/models/restapi/UserCollection.Model';
import { RoleService } from '@em/service/data/role/Role.Service';
import { UserService } from '@em/service/data/user/User.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { OkCancelDialogComponent, OkCancelDialogData, OkCancelDialogResult } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthTypeEnum } from '@shared/enum/AuthType.Enum';
import { RestApiAccountService } from '@em/service/restapi/RestApi.Account.Service';

@Component({
    selector: 'em-settings-manage-users',
    templateUrl: './Settings.ManageUsers.Component.html',
    styleUrls: ['./Settings.ManageUsers.Component.scss']
})
export class SettingsManageUsersComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit, ILoadDate {
    public static className: string = 'SettingsManageUsersEditComponent';

    public activeUsers: Array<UserModel>;

    public addNewAction: NavBarAction;
    public currentUserId: number;
    public currentUser: UserModel;

    public systemDataSource = new MatTableDataSource();
    public oidcDataSource = new MatTableDataSource();
    public deleteProcess: ProcessMonitorServiceProcess;
    public unlockProcess: ProcessMonitorServiceProcess;
    public systemDisplayedColumns = ['username', 'email', 'realName', 'roleText', 'lastActivityText', 'state', 'jobTitle', 'organisation'];
    public oidcDisplayedColumns = ['email', 'realName', 'roleText', 'lastActivityText',  'state', 'jobTitle', 'organisation'];

    @ViewChild(MatPaginator, { static: true })
    public paginator: MatPaginator;

    @ViewChildren(MatSort) allSorts: QueryList<MatSort>;

    @HostBinding()
    public id: string = 'em-settings-manage-users';
    public removedUsers: Array<UserModel>;

    public addNewButtonClickProcess: ProcessMonitorServiceProcess;
    public addUserProcess: ProcessMonitorServiceProcess;
    public deleteUserProcess: ProcessMonitorServiceProcess;
    public editUserProcess: ProcessMonitorServiceProcess;
    public getUserProcess: ProcessMonitorServiceProcess;
    public resetApiKeyProcess: ProcessMonitorServiceProcess;
    public resetPasswordProcess: ProcessMonitorServiceProcess;
    public roles: Array<RoleModel>;
    public setEnabledStateProcess: ProcessMonitorServiceProcess;
    public AuthTypeEnum = AuthTypeEnum;

    public get systemAuthOnly(): boolean{
        if (!this.isNullOrUndefined(this._authTypes) && this._authTypes.some(v => v !== AuthTypeEnum.system)){
            return false;
        }

        return true;
    }

    private _emails: Array<string>;
    private _authTypes: Array<AuthTypeEnum> = [];

    public constructor(
        private readonly _eventsService: EventsService,
        private readonly _dialog: MatDialog,
        private readonly _userCurrentService: UserCurrentService,
        private readonly _navBarService: NavBarActionService,
        private readonly _userService: UserService,
        private readonly _roleService: RoleService,
        private readonly _accountService: RestApiAccountService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsManageUsersComponent.className, this.loadDataProcessText);
        this.resetApiKeyProcess = this.processMonitorService.getProcess(SettingsManageUsersComponent.className, 'Resetting user API key');
        this.deleteProcess = this.processMonitorService.getProcess(SettingsManageUsersComponent.className, 'Deleting user');
        this.setEnabledStateProcess = this.processMonitorService.getProcess(SettingsManageUsersComponent.className, 'Setting user enabled state');
        this.resetPasswordProcess = this.processMonitorService.getProcess(SettingsManageUsersComponent.className, 'Resetting use password');
        this.addNewButtonClickProcess = this.processMonitorService.getProcess(SettingsManageUsersComponent.className, 'Add user button click.');
        this.getUserProcess = this.processMonitorService.getProcess(SettingsManageUsersComponent.className, 'Getting user');
        this.addUserProcess = this.processMonitorService.getProcess(SettingsManageUsersComponent.className, 'Add user');
        this.deleteUserProcess = this.processMonitorService.getProcess(SettingsManageUsersComponent.className, 'Delete user');
        this.editUserProcess = this.processMonitorService.getProcess(SettingsManageUsersComponent.className, 'Edit user');
        this.unlockProcess = this.processMonitorService.getProcess(SettingsManageUsersComponent.className, 'Unlock user');

        this.addNewAction = new NavBarAction();
        this.addNewAction.name = 'createuser';
        this.addNewAction.text = 'Create User';
        this.addSubscription(this.observableHandlerBase(this.addNewAction.onButtonClick, this.addNewButtonClickProcess).subscribe(() => { this.add(); }), this.addNewButtonClickProcess);

        this.addSubscription(this.observableHandlerBase(this._userCurrentService.user, this.getUserProcess).subscribe(currentUser => {
            this.currentUser = (currentUser as UserModel);
            this.currentUserId = (currentUser as UserModel).userId;
        }), this.getUserProcess);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public add(): void {
        this.addSubscription(this.userCurrentService.user.subscribe(currentUser => {
            const data = new SettingsManageUsersAddData();
            data.roles = this.roles.filter(r => currentUser.canAddRole(r.name));
            data.emails = this._emails;
            data.authTypes = this._authTypes;

            const dialogRef = this._dialog.open(SettingsManageUsersAddComponent, { data, disableClose: true });
            this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.addUserProcess).subscribe((result: SettingsManageUsersAddResult) => {
                if (!this.isNullOrUndefined(result) && result.ok === true) {
                    this._eventsService.changedUsers();
                    if (!result.user.roles.some(role => role.name.toLocaleLowerCase() === 'systemmanager')) {
                        this.edit(result.user);
                    }
                    this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
                }
            }));
        }));
    }

    public canEditUser(user: UserModel): boolean {
        return user.userId !== this.currentUserId && user.role.order > this.currentUser.role.order;
    }

    public applySystemFilter(filterValue: string) {
        if (!this.isNullOrUndefined(this.systemDataSource.data) && this.systemDataSource.data.length > 0) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            this.systemDataSource.filter = filterValue;
        }
    }

    public applyOidcFilter(filterValue: string) {
        if (!this.isNullOrUndefined(this.oidcDataSource.data) && this.oidcDataSource.data.length > 0) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            this.oidcDataSource.filter = filterValue;
        }
    }

    public delete(user: UserModel): void {
        const dialogRef = this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Confirm User Deletion', `Are you sure you want to delete ${user.realName}?`), disableClose: true });
        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.deleteUserProcess).subscribe((r: OkCancelDialogResult) => {
            if (!this.isNullOrUndefined(r) && r.ok === true) {
                this.addSubscription(this.observableHandlerBase(this._userService.delete(user.username, this.deleteProcess), this.deleteProcess).subscribe(
                    () => {
                        const index = this.activeUsers.findIndex(i => i.uniqueId === user.uniqueId);
                        this.activeUsers.splice(index, 1);
                        this.removedUsers.push(user);
                        user.markForRemoval = true;

                        this.systemDataSource.data = this.activeUsers.filter(u => u.authType === AuthTypeEnum.system);
                        this.oidcDataSource.data = this.activeUsers.filter(u => u.authType === AuthTypeEnum.openIDConnect);
                    }
                ), this.deleteProcess);
            }
        }), this.deleteUserProcess);
    }

    public unlock(user: UserModel): void {
        this.addSubscription(this.observableHandlerBase(this._userService.unlockAccount(user.username, this.unlockProcess), this.unlockProcess).subscribe(
            () => {
            }
        ), this.unlockProcess);
    }

    public edit(user: UserModel): void {
        this.addSubscription(this.userCurrentService.user.subscribe(currentUser => {
            const data = new SettingsManageUsersEditData();
            data.user = user;
            data.roles = this.roles.filter(r => currentUser.canAddRole(r.name));

            const dialogRef = this._dialog.open(SettingsManageUsersEditComponent, { data, disableClose: true, maxWidth: 650 });
            this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.editUserProcess).subscribe((result: SettingsManageUsersEditResult) => {
                this._eventsService.changedUsers();
                this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
            }));
        }));
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._roleService.get(process).pipe(
                map(result => {
                    this.roles = this.removeSysAdmin(result);
                    return true;
                })
            ),
            this._userService.get(process).pipe(
                map(result => {
                    this.activeUsers = this.removeMarkForRemoval(result);
                    this.removedUsers = this.removeActive(result);
                    this.systemDataSource.data = this.activeUsers.filter(u => u.authType === AuthTypeEnum.system);
                    this.oidcDataSource.data = this.activeUsers.filter(u => u.authType === AuthTypeEnum.openIDConnect);
                    this._emails = this.activeUsers.map(i => i.email);
                    return true;
                })
            ),
            this._accountService.availableAuth(process).pipe(
                map(result => {
                    this._authTypes = result.result;
                    return true;
                })
            )
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this._navBarService.removeAction(this.addNewAction);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this._navBarService.addAction(this.addNewAction);

        this.systemDataSource.paginator = this.paginator;
        this.oidcDataSource.paginator = this.paginator;
    }

    public ngAfterViewInit(): void{
        super.ngAfterViewInit();

        this.systemDataSource.sort = this.allSorts.find(item => item.sortables.size === this.systemDisplayedColumns.length);

        this.oidcDataSource.sort = this.allSorts.find(item => item.sortables.size === this.oidcDisplayedColumns.length);
    }

    public removeActive(users: UserCollectionModel): Array<UserModel> {
        const items: UserModel[] = [];
        const length = users.items.length;
        for (let index = 0; index < length; index++) {
            const item = users.items[index];
            if (item.markForRemoval === true) {
                items.push(item);
            }
        }
        return items;
    }

    public removeMarkForRemoval(users: UserCollectionModel): Array<UserModel> {
        const items: UserModel[] = [];
        const length = users.items.length;
        for (let index = 0; index < length; index++) {
            const item = users.items[index];
            if (item.markForRemoval === false) {
                items.push(item);
            }
        }
        return items;
    }

    public removeSysAdmin(roles: Array<RoleModel>): Array<RoleModel> {
        const items: RoleModel[] = [];
        const length = roles.length;
        for (let index = 0; index < length; index++) {
            const item = roles[index];
            if (item.name !== 'SystemAdmin') {
                items.push(item);
            }
        }
        return items;
    }

    public resetApiKey(user: UserModel): void {
        const dialogRef = this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Confirm Reset API Key', `Are you sure you want to reset ${user.realName} API key?`), disableClose: true });
        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.resetApiKeyProcess).subscribe((r: OkCancelDialogResult) => {
            if (!this.isNullOrUndefined(r) && r.ok === true) {
                this.addSubscription(this.observableHandlerBase(this._userService.resetApiKey(user.username, this.resetApiKeyProcess), this.resetApiKeyProcess).subscribe(
                    result => {
                        user.webAPIKey = result;
                    }
                ), this.resetApiKeyProcess);
            }
        }), this.resetApiKeyProcess);
    }

    public resetPassword(user: UserModel): void {
        const dialogRef = this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Confirm Password Reset', `Are you sure you want to reset ${user.realName} password?`), disableClose: true });
        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.resetPasswordProcess).subscribe((r: OkCancelDialogResult) => {
            if (!this.isNullOrUndefined(r) && r.ok === true) {
                this.addSubscription(this.observableHandlerBase(this._userService.resetPassword(user.username, this.resetPasswordProcess), this.resetPasswordProcess).subscribe(
                    result => {
                        if (result !== null) {
                            const dialogData = new OkCancelDialogData('New Password', null);
                            dialogData.messageHtml = `The new password, including spaces, is: <br> <b>${result}</b> <br> <br>Please provide the new password to the user.`;
                            dialogData.showCancel = false;
                            dialogData.okText = 'Close';

                            this._dialog.open(OkCancelDialogComponent, { data: dialogData, disableClose: true });
                        } else {
                            const dialogData = new OkCancelDialogData('Password change failed', null);
                            dialogData.messageHtml = `Failed to change the password`;
                            dialogData.showCancel = false;
                            dialogData.okText = 'Close';

                            this._dialog.open(OkCancelDialogComponent, { data: dialogData, disableClose: true });
                        }
                    }
                ), this.resetPasswordProcess);
            }
        }), this.resetPasswordProcess);
    }

    public toggelDisabled(user: UserModel): void {
        this.addSubscription(this.observableHandlerBase(this._userService.setEnabledState(user.username, user.disabled, this.setEnabledStateProcess), this.setEnabledStateProcess).subscribe(result => {
            this._userService.clearCache();
            this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
        }), this.setEnabledStateProcess);
    }
}
