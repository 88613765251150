import { Component, HostBinding, Injector, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';

@Component({
    selector: 'rift-register-icon-name',
    templateUrl: './Register.IconName.Component.html',
    styleUrls: ['./Register.IconName.Component.scss']
})
export class RegisterIconNameComponent extends RiftBaseComponent {
    public static className: string = 'RegisterIconNameComponent';

    @HostBinding()
    public id: string = 'rift-register-icon-name';

    @Input()
    public registerBase: RegisterBaseModel;

    public constructor(
        private readonly _injector: Injector,
        private readonly _dialog: MatDialog,
    ) {
        super(_injector, _dialog);
    }
}
