import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';

export class AssociatedRegisterViewModel {
    public register: RegisterBaseModel = null;
    public selected: boolean = false;
    public sequentialOrder: number = null;
    public summationAdd: boolean = true;

    public constructor(private readonly _model: RegisterBaseModel) {
        this.register = _model;
    }
}
