import { DeviceTypeEnum } from '@shared/enum/DeviceType.Enum';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class FirmwareSummaryModel extends BaseModel implements IRestModel {

    public firmwareVersion: string = null;
    public numberOfDevices: string = null;
    public readonly isIRestModel = true;
    public type: DeviceTypeEnum = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-FirmwareSummaryModel');
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
