import { Injectable } from '@angular/core';
import { CountLogConfigModel } from '@rift/models/restapi/CountLogConfig.Model';
import { CountLogPageModel } from '@rift/models/restapi/CountLogPage.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiCountsService } from '@rift/service/restapi/v1/RestApi.Counts.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class CountsService extends RiftBaseService {
    private _countLogConfigCache: CountLogConfigModel;

    private _getConfigLoadingTracker = new ObservableTracker<CountLogConfigModel>();

    private _getCountLogPageLoadingTracker = new ObservableTracker<CountLogPageModel>();

    private _setConfigLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiCountsService: RestApiCountsService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._countLogConfigCache = null;
    }

    public getConfig(process?: ProcessMonitorServiceProcess): Observable<CountLogConfigModel> {
        if (isNullOrUndefined(this._countLogConfigCache)) {
            return this._getConfigLoadingTracker
                .getLoading()
                .observable(this._restApiCountsService.getConfig(process).pipe(
                    map(result => {
                        this._countLogConfigCache = result;
                        return this._countLogConfigCache;
                    }))
                );
        } else {
            return of(this._countLogConfigCache);
        }
    }

    public getCountLogPage(nextIndex?: number, newestFirst?: boolean, process?: ProcessMonitorServiceProcess): Observable<CountLogPageModel> {
        return this._getCountLogPageLoadingTracker
            .getLoading(nextIndex, newestFirst)
            .observable(this._restApiCountsService.getCountLogPage(nextIndex, newestFirst, process));
    }

    public setCache(countLogConfigCache: CountLogConfigModel): Observable<boolean> {
        this._countLogConfigCache = countLogConfigCache;
        return of(true);
    }

    public setConfig(config: CountLogConfigModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setConfigLoadingTracker
            .getLoading(config)
            .observable(this._restApiCountsService.setConfig(config, process).pipe(tap(result => this._countLogConfigCache = null)));
    }
}
