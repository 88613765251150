export enum DeviceLensTypeEnum {
    d60 = 0,
    d40 = 1,
    d50 = 2,
    d20 = 3,
    d90 = 4,
    d75 = 5,
}

export class DeviceLensTypeEnumHelpers {
    public static fromRestApi(value: string): DeviceLensTypeEnum {
        switch (value) {
            case '60':
                return DeviceLensTypeEnum.d60;
            case '40':
                return DeviceLensTypeEnum.d40;
            case '50':
                return DeviceLensTypeEnum.d50;
            case '20':
                return DeviceLensTypeEnum.d20;
            case '90':
                return DeviceLensTypeEnum.d90;
            case '75':
                return DeviceLensTypeEnum.d75;
        }
    }

    public static toString(value: DeviceLensTypeEnum): string {
        switch (value) {
            case DeviceLensTypeEnum.d60:
                return '60°';
            case DeviceLensTypeEnum.d40:
                return '40°';
            case DeviceLensTypeEnum.d50:
                return '50°';
            case DeviceLensTypeEnum.d20:
                return '20°';
            case DeviceLensTypeEnum.d90:
                return '90°';
            case DeviceLensTypeEnum.d75:
                return '75°';
        }
    }
}
