import { PathMapPointModel } from '@rift/models/restapi/PathMapPoint.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IRestModel } from '@shared/interface/IRestModel';

export interface IPathMapModel extends IRestModel {
    bitmap: Array<number>;
    boundaries: Array<Array<PathMapPointModel>>;
    width: number;
    height: number;
    origin: PathMapPointModel;
}

export class PathMapModel extends BaseModel implements IPathMapModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public bitmap: Array<number> = null;
    public boundaries: Array<Array<PathMapPointModel>> = null;
    public width: number = null;
    public height: number = null;
    public origin: PathMapPointModel = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-PathMapModel');

        this.bitmap = restModel.Bitmap;

        this.origin = RestModelUtility.loadFrom(restModel.Origin, PathMapPointModel);

        this.boundaries = [];
        const length = restModel.Boundaries.length;
        for (let i = 0; i < length; i++) {
            this.boundaries[i] = RestModelUtility.loadFromArray(restModel.Boundaries[i], PathMapPointModel);
        }
    }

    public toRestApiModel(): any {
        return {
        };
    }
}
