import { AfterViewInit, Component, HostBinding, Injector, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import {
    RegisterSummaryDialogData,
    SettingsCountingRegisterSummaryDialogComponent,
} from '@rift/components/settings/counting/shared/summary/Settings.Counting.RegisterSummary.Dialog.Component';
import { AssociatedRegisterViewModel } from '@rift/components/settings/counting/viewmodels/AssociatedRegister.ViewModel';
import { RegisterTypeViewModel } from '@rift/components/settings/counting/viewmodels/RegisterType.ViewModel';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { GlobalHeightFilterModel } from '@rift/models/restapi/GlobalHeightFilter.Model';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { RegisterSummationModel } from '@rift/models/restapi/RegisterSummation.Model';
import { HeightService } from '@rift/service/data/height/Height.Service';
import { LineService } from '@rift/service/data/line/Line.Service';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { getDisplayName } from '@rift/shared/getDisplayName';
import { IAssociatedRegisterConfig } from '@rift/shared/IAssociatedRegisterConfig';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { CountModeEnum, CountModeEnumHelpers } from '@shared/enum/CountMode.Enum';
import { LineExclusionTypeEnumHelpers } from '@shared/enum/LineExclusionRegisterType.Enum';
import { MarkedTargetEnum } from '@shared/enum/MarkedTarget.Enum';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { UnitOfMeasurementEnum } from '@shared/enum/UnitOfMeasurement.Enum';
import { ZoneTypeEnum } from '@shared/enum/ZoneType.Enum';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isFunction, isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';

interface IAssociatedRegisterConfigsVM {
    config: IAssociatedRegisterConfig;
    associatedRegisterVMs: AssociatedRegisterViewModel[];
}

@Component({
    selector: 'rift-settings-counting-register-summary',
    templateUrl: './Settings.Counting.RegisterSummary.Component.html',
    styleUrls: ['./Settings.Counting.RegisterSummary.Component.scss']
})
export class SettingsCountingRegisterSummaryComponent extends RiftBaseComponent implements ILoadDate, AfterViewInit {
    public static className: string = 'SettingsCountingRegisterSummaryComponent';

    @Input()
    public registerNamePostfix: string;

    @Input()
    public registerType: RegisterTypeViewModel;

    @Input()
    public get register(): RegisterBaseModel {
        return this._register;
    }
    public set register(value: RegisterBaseModel) {
        if (this._register !== value) {
            this._register = value;
            this.setRegisterTypeViewModel();
            this.setRegisterLine();
            this.setAssociatedRegisterConfigsVMs();
        }
    }

    public CountModeEnum = CountModeEnum;
    public CountModeEnumHelpers = CountModeEnumHelpers;
    public MarkedTargetEnum = MarkedTargetEnum;
    public isFunction = isFunction;

    public RegisterTypeEnum = RegisterTypeEnum;
    public UnitOfMeasurementEnum = UnitOfMeasurementEnum;
    public ZoneTypeEnum = ZoneTypeEnum;
    public associatedRegisterConfigsVMs: IAssociatedRegisterConfigsVM[];
    public globalHeightFilter: GlobalHeightFilterModel;
    public line: LineModel;
    public LineExclusionTypeEnumHelpers = LineExclusionTypeEnumHelpers;
    public getDisplayName = getDisplayName;

    @HostBinding()
    public id: string = 'rift-settings-counting-register-summary';

    private _lines: Array<LineModel>;
    private _masterDevice: DeviceModel;
    private _register: RegisterBaseModel;
    private _registers: RestModelChangeTrackerArray<RegisterBaseModel>;

    public constructor(
        private readonly _lineService: LineService,
        private readonly _registerService: RegisterService,
        private readonly _heightService: HeightService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingRegisterSummaryComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsCountingRegisterSummaryComponent.className, this.saveAllChangesProcessText);

        this.initConnectionState();
    }

    public ngAfterViewInit(): void {
        this.setRegisterLine();
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._heightService.getGlobalHeightFilter(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.globalHeightFilter = result;
                    }
                    return true;
                })
            ),
            this.getHostDevice(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this._masterDevice = result;
                    }
                    return true;
                })
            ),
            this._lineService.getLines(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this._lines = result.items;
                    }
                    return true;
                })
            ),
            this._registerService.getRegisters(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this._registers = result.items;
                    }
                    return true;
                })
            ),
        ).pipe(
            tap(() => {
                this.setRegisterTypeViewModel();
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public onAssociatedRegisterClicked(vm: AssociatedRegisterViewModel): void {
        this._dialog.open(SettingsCountingRegisterSummaryDialogComponent, { data: new RegisterSummaryDialogData(vm.register, new RegisterTypeViewModel(vm.register, this._masterDevice)), maxWidth: 600 });
    }

    public setAssociatedRegisterConfigsVMs(): void {
        if (!this.isNullOrUndefined(this.registerType) && !this.isNullOrUndefined(this.registerType.registerTypeConfig) && !this.isNullOrUndefined(this.registerType.registerTypeConfig.associatedRegisterConfigs)) {
            this.associatedRegisterConfigsVMs = [];
            const length = this.registerType.registerTypeConfig.associatedRegisterConfigs.length;
            for (let i = 0; i < length; i++) {
                const config = this.registerType.registerTypeConfig.associatedRegisterConfigs[i];
                this.associatedRegisterConfigsVMs.push({ config, associatedRegisterVMs: RegisterBaseUtility.getAssociatedRegisterVMs(config, this.register, this._registers, this._lines) });
            }
        }
    }

    public getSummationAdd(vm: AssociatedRegisterViewModel): boolean {
        if (this.register instanceof RegisterSummationModel) {
            const sumRegister = this.register.registers.find(r => r.register === vm.register.registerIndex);
            if (!this.isNullOrUndefined(sumRegister)) {
                return !sumRegister.subtraction;
            }
        }
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this);
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this);
    }

    private setRegisterLine(): void {
        if (!this.isNullOrUndefined(this.register) && !this.isNullOrUndefined(this._lines)) {
            if (this.register.isLineHost) {
                if (this.isNullOrUndefined(this.register.countMode)) {
                    const line = this._lines.find(l => this.register.lineIds.indexOf(l.iD) !== -1);
                    if (!isNullOrUndefined(line)) {
                        this.line = line;
                    }
                }
            }
        }
    }

    private setRegisterTypeViewModel(): void {
        if (!this.isNullOrUndefined(this.register) && !this.isNullOrUndefined(this._masterDevice)) {
            this.registerType = new RegisterTypeViewModel(this.register, this._masterDevice);
        }
    }
}
