import { Component, HostBinding, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsCountingBaseComponent } from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { UnitsOfMeasurementInputValidators } from '@rift/directives/unitsofmeasurementinput/UnitsOfMeasurementInput.Directive';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UnitsOfMeasurementService } from '@rift/service/unitsofmeasurement/UnitsOfMeasurement.Service';
import { UnitOfMeasurementEnum } from '@shared/enum/UnitOfMeasurement.Enum';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'rift-settings-counting-height-filtering',
    templateUrl: './Settings.Counting.HeightFiltering.Component.html',
    styleUrls: ['./Settings.Counting.HeightFiltering.Component.scss']
})
export class SettingsCountingHeightFilteringComponent extends SettingsCountingBaseComponent implements OnChanges {
    public static className: string = 'SettingsCountingHeightFilteringComponent';
    public static lowerBoundDefault: number = 125;
    public static upperBoundDefault: number = 200;

    private _heightFilterRegister: RegisterBaseModel & { lowerBound: number; upperBound: number; heightFilterEnabled: boolean } = null;

    @HostBinding()
    public id: string = 'rift-settings-counting-height-filtering';

    @Input()
    public get enabled(): boolean {
        return this._enabled;
    }
    public set enabled(value: boolean) {
        this._enabled = value;
    }

    @Input()
    public set register(value: RegisterBaseModel){
        this._heightFilterRegister = (value as RegisterBaseModel & { lowerBound: number; upperBound: number; heightFilterEnabled: boolean });
    }

    public form: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    private _enabled: boolean = false;

    public constructor(
        private readonly _unitsOfMeasurementService: UnitsOfMeasurementService,
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsCountingHeightFilteringComponent.className, 'Form values change');

        this.form = this._formBuilder.group(
            {
                lowerBound: ['', Validators.compose([Validators.required, UnitsOfMeasurementInputValidators.min(), UnitsOfMeasurementInputValidators.max()])],
                upperBound: ['', Validators.compose([Validators.required, UnitsOfMeasurementInputValidators.min(), UnitsOfMeasurementInputValidators.max()])],
            },
            {
                validator: Validators.compose([
                    ValidationValidators.graterThan('upperBound', 'lowerBound', 'Upper bounds must be greater than lower bound'),
                    ValidationValidators.lessThan('lowerBound', 'upperBound', 'Upper bounds must be greater than lower bound')
                ]),
            } as AbstractControlOptions
        );

        this.addSubscription(this.observableHandlerBase(this.form.controls.lowerBound.valueChanges, this.formValuesChangeProcess).subscribe(value => {
            if (!this.isNullOrUndefined(this._heightFilterRegister)) {
                this._heightFilterRegister.lowerBound = parseInt(value, 10);
                this.setHeightFilteringEnabled();
            }
        }), this.formValuesChangeProcess);

        this.addSubscription(this.observableHandlerBase(this.form.controls.upperBound.valueChanges, this.formValuesChangeProcess).subscribe(value => {
            if (!this.isNullOrUndefined(this._heightFilterRegister)) {
                this._heightFilterRegister.upperBound = parseInt(value, 10);
                this.setHeightFilteringEnabled();
            }
        }), this.formValuesChangeProcess);

        this.formGroupTracker.track(this.form);
        this.setFormState();

        this.initConnectionState();
    }

    public getSettingsDescription(): string {
        return this.isNullOrUndefined(this._heightFilterRegister) ? '' :
            (this.isNullOrUndefined(this._heightFilterRegister.lowerBound) && this.isNullOrUndefined(this._heightFilterRegister.upperBound) || Number.isNaN(this._heightFilterRegister.lowerBound) || Number.isNaN(this._heightFilterRegister.upperBound) || (this._heightFilterRegister.lowerBound === 0 && this._heightFilterRegister.upperBound === 0)) ?
                'Disabled' :
                this._unitsOfMeasurementService.parseUOMString(`From uom:{ "metricValue": ${this._heightFilterRegister.lowerBound}, "metricUnit": ${UnitOfMeasurementEnum.centimeter}, "imperialUnit": ${UnitOfMeasurementEnum.feet}, "unitShortName": true } to uom:{ "metricValue": ${this._heightFilterRegister.upperBound}, "metricUnit": ${UnitOfMeasurementEnum.centimeter}, "imperialUnit": ${UnitOfMeasurementEnum.feet}, "unitShortName": true }`);
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this._heightFilterRegister) ? false : this._heightFilterRegister.propertyHasChanges('lowerBound') || this._heightFilterRegister.propertyHasChanges('upperBound') || this._heightFilterRegister.propertyHasChanges('heightFilterEnabled');
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this._heightFilterRegister = changes.register.currentValue;
            this.enabled = this._heightFilterRegister.heightFilterEnabled;
            this.setFormValues();
        }
    }

    public onEnabledChange(event: MatCheckboxChange): void {
        this.enabled = event.checked;
        if (!this.isNullOrUndefined(this._heightFilterRegister)) {
            if (this.enabled) {
                this._heightFilterRegister.lowerBound = SettingsCountingHeightFilteringComponent.lowerBoundDefault;
                this._heightFilterRegister.upperBound = SettingsCountingHeightFilteringComponent.upperBoundDefault;
            } else {
                this._heightFilterRegister.lowerBound = null;
                this._heightFilterRegister.upperBound = null;
            }
        }
        this.setHeightFilteringEnabled();
        this.setFormState();
    }

    protected offline(): void {
        super.offline();
        this.form.disable();
    }

    protected online(): void {
        super.online();
        this.form.enable();
    }

    private setHeightFilteringEnabled(): void {
        const registerAny = this._heightFilterRegister as any;
        if (!this.isNullOrUndefined(registerAny.heightFilterEnabled)) {
            if (this._heightFilterRegister.lowerBound > 0 && this._heightFilterRegister.upperBound > 0) {
                registerAny.heightFilterEnabled = true;
            } else {
                registerAny.heightFilterEnabled = false;
            }
        }
    }

    private setFormState(): void {
        if (this.enabled) {
            this.form.enable({ emitEvent: false });
        } else {
            this.form.disable({ emitEvent: false });
        }
    }

    private setFormValues(): void {
        this.enabled = this._heightFilterRegister.lowerBound > 0 && this._heightFilterRegister.upperBound > 0;
        this.form.controls.lowerBound.setValue(this.isNullOrUndefined(this._heightFilterRegister.lowerBound) || this._heightFilterRegister.lowerBound === 0 ? SettingsCountingHeightFilteringComponent.lowerBoundDefault : this._heightFilterRegister.lowerBound, { emitEvent: false });
        this.form.controls.upperBound.setValue(this.isNullOrUndefined(this._heightFilterRegister.upperBound) || this._heightFilterRegister.upperBound === 0 ? SettingsCountingHeightFilteringComponent.upperBoundDefault : this._heightFilterRegister.upperBound, { emitEvent: false });
    }
}
