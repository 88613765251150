import { NgZone, Directive } from '@angular/core';
import { SegmentBase } from '@rift/components/shared/viewport/base/SegmentBase';
import { ViewPortHelpers } from '@rift/components/shared/viewport/ViewPort.Helpers';
import { ViewPortModeEnum } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { PointModel } from '@rift/models/restapi/Point.Model';
import { ShapeModel } from '@rift/models/restapi/ShapeModel';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ImageUtility } from '@shared/utility/Image.Utility';
import { Subject, Observable, zip, empty, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive()
export class JointBase extends DisplayItem {
    public beforeDrag: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public click: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public doubleClick: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public drag: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();

    public editEnabled: boolean = true;
    public mouseDown: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public mouseOut: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public mouseOver: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public pressUp: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();

    protected _arrowBitmap: createjs.Bitmap = new createjs.Bitmap(ImageUtility.BlankImagePath);

    protected _fromSegment: SegmentBase = null;
    protected _isDragging: boolean = false;
    protected _jointBitmap: createjs.Bitmap = new createjs.Bitmap(ImageUtility.BlankImagePath);
    protected _lastDragLocation: createjs.Point = null;
    protected _toSegment: SegmentBase = null;
    protected _x: number = null;
    protected _y: number = null;

    public constructor(
        private readonly _zoneJointBase: NgZone,
        protected readonly _shapeBase: any,
        protected readonly _showArrow: boolean,
        protected readonly _loadQueueBase: ViewPortLoadQueueService,
    ) {
        super(_zoneJointBase);

        this._zoneJointBase.runOutsideAngular(() => {
            if (this._loadQueueBase.isLoaded === true) {
                this.onLoadQueueComplete();
            } else {
                this.addSubscription(this._loadQueueBase.loaded.subscribe(() => this.onLoadQueueComplete()));
            }

            if (this._showArrow === true) {
                this._arrowBitmap.scaleX = this._arrowBitmap.scaleY = 0.3;
                this.container.addChild(this._arrowBitmap);
            }

            this._jointBitmap.scaleX = this._jointBitmap.scaleY = 0.3;
            this.container.addChild(this._jointBitmap);

            this.addEventHandlers();
        });
    }

    public bringToFront(): void {
    }

    public get fromSegment(): SegmentBase {
        return this._zoneJointBase.runOutsideAngular(() => this._fromSegment);
    }

    public set fromSegment(value: SegmentBase) {
        this._zoneJointBase.runOutsideAngular(() => {
            if (this._fromSegment !== value) {
                this._fromSegment = value;
            }
        });
    }

    public get isDragging(): boolean {
        return this._zoneJointBase.runOutsideAngular(() => this._isDragging);
    }

    public set isDragging(value: boolean) {
        this._zoneJointBase.runOutsideAngular(() => {
            if (this._isDragging !== value) {
                this._isDragging = value;
            }
        });
    }

    public onDestroy(): void {
        this._zoneJointBase.runOutsideAngular(() => {
            super.onDestroy();
            this.container.removeAllChildren();

            this._arrowBitmap = null;
            this._jointBitmap = null;
        });
    }

    public get point(): createjs.Point {
        return new createjs.Point(this.x, this.y);
    }

    public get toSegment(): SegmentBase {
        return this._zoneJointBase.runOutsideAngular(() => this._toSegment);
    }

    public set toSegment(value: SegmentBase) {
        this._zoneJointBase.runOutsideAngular(() => {
            if (this._toSegment !== value) {
                this._toSegment = value;
            }
        });
    }

    public update(): void {
        this._zoneJointBase.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._loadQueueBase) && !isNullOrUndefined(this.mode) && this._loadQueueBase.isLoaded === true) {
                if (this._showArrow === true && !isNullOrUndefined(this._arrowBitmap)) {
                    const arrowBitmapBounds = this._arrowBitmap.getBounds();
                    if (!isNullOrUndefined(arrowBitmapBounds)) {
                        this._arrowBitmap.regX = arrowBitmapBounds.width / 2;
                        this._arrowBitmap.regY = arrowBitmapBounds.height;
                    }
                }

                if(!isNullOrUndefined(this._jointBitmap)){
                    const jointBitmapBounds = this._jointBitmap.getBounds();

                    if (!isNullOrUndefined(jointBitmapBounds)) {
                        const point = ViewPortHelpers.devicePointToViewPortPoint(this.point);
                        this._jointBitmap.regX = jointBitmapBounds.width / 2;
                        this._jointBitmap.regY = jointBitmapBounds.height / 2;

                        this.container.x = point.x;
                        this.container.y = point.y;

                        // Rotate arrow
                        if (this._showArrow === true) {
                            const origin = this.point;
                            let p1: createjs.Point = null;
                            let p2: createjs.Point = null;

                            if (isNullOrUndefined(this.fromSegment)) {
                                p1 = this.toSegment.toJoint.point;
                                p2 = this.point;
                            } else if (isNullOrUndefined(this.toSegment)) {
                                p1 = this.point;
                                p2 = this.fromSegment.fromJoint.point;
                            } else {
                                p1 = this.toSegment.toJoint.point;
                                p2 = this.fromSegment.fromJoint.point;
                            }

                            let dx = (p2.x - p1.x);
                            let dy = (p2.y - p1.y);
                            const mag = Math.sqrt(dx * dx + dy * dy);

                            dx = dx / mag;
                            dy = dy / mag;

                            const end = { x: origin.x + dy, y: origin.y - dx };

                            const angleDeg = Math.atan2(end.y - origin.y, end.x - origin.x) * 180 / Math.PI;

                            this._arrowBitmap.rotation = 180 - (angleDeg - 90);
                        }

                        this.requireStageUpdate.next();
                    }
                }
            }
        });
    }

    public get x(): number {
        return this._x;
    }
    public set x(value: number) {
        this._x = value;
    }

    public get y(): number {
        return this._y;
    }
    public set y(value: number) {
        this._y = value;
    }

    protected addEventHandlers(): void {
        this._zoneJointBase.runOutsideAngular(() => {
            this.container.on('click', this.onClick.bind(this));
            this.container.on('dblclick', this.onDoubleClick.bind(this));
            this.container.on('mouseover', this.onMouseOver.bind(this));
            this.container.on('mouseout', this.onMouseOut.bind(this));
            this.container.on('mousedown', this.onMouseDown.bind(this));
            this.container.on('pressmove', this.onPressMove.bind(this));
            this.container.on('pressup', this.onPressUp.bind(this));
        });
    }

    protected modeSet(): void {
        this._zoneJointBase.runOutsideAngular(() => {
            this.loadBitmaps().subscribe(()=>{
                this.update();
            });
        });
    }

    protected onClick(event: createjs.MouseEvent): void {
        this._zoneJointBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.click.next(new DisplayItemMouseEvent(this, event));
            }
        });
    }

    protected onDoubleClick(event: createjs.MouseEvent): void {
        this._zoneJointBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.doubleClick.next(new DisplayItemMouseEvent(this, event));
            }
        });
    }

    protected onLoadQueueComplete(): void {
        this._zoneJointBase.runOutsideAngular(() => {
            this.loadBitmaps().subscribe(()=>{
                this.update();
            });
        });
    }

    protected onMouseDown(event: createjs.MouseEvent): void {
        this._zoneJointBase.runOutsideAngular(() => {
            if (this.editEnabled === true && this.mode === ViewPortModeEnum.edit) {
                this.isMouseDown = true;
                this.update();

                this._lastDragLocation = new createjs.Point(event.localX, event.localY);

                const segmentEvent = new DisplayItemMouseEvent(this, event);
                segmentEvent.lastDragLocation = this._lastDragLocation;
                this.mouseDown.next(segmentEvent);
            }
        });
    }

    protected onMouseOut(event: createjs.MouseEvent): void {
        this._zoneJointBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.isMouseOver = false;
                this.loadBitmaps().subscribe(()=>{
                    this.update();
                    this.mouseOut.next(new DisplayItemMouseEvent(this, event));
                });
            }
        });
    }

    protected onMouseOver(event: createjs.MouseEvent): void {
        this._zoneJointBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.isMouseOver = true;
                this.loadBitmaps().subscribe(()=>{
                    this.update();
                    this.mouseOver.next(new DisplayItemMouseEvent(this, event));
                });
            }
        });
    }

    protected onPressMove(event: createjs.MouseEvent): void {
        this._zoneJointBase.runOutsideAngular(() => {
            if (this.editEnabled === true && this.mode === ViewPortModeEnum.edit) {
                if (this.isMouseDown === true) {
                    this.isDragging = true;

                    const moveToX: number = this.x - (this._lastDragLocation.x - event.localX);
                    const moveToY: number = this.y - ((this._lastDragLocation.y - event.localY) * -1);

                    const beforeDragEvent = new DisplayItemMouseEvent(this, event);
                    beforeDragEvent.lastDragLocation = this._lastDragLocation;
                    beforeDragEvent.moveTo = new createjs.Point(moveToX, moveToY);
                    this.beforeDrag.next(beforeDragEvent);

                    if (beforeDragEvent.isDefaultPrevented === false) {
                        this.x = moveToX;
                        this.y = moveToY;

                        const dragEvent = new DisplayItemMouseEvent(this, event);
                        dragEvent.lastDragLocation = this._lastDragLocation;
                        this.drag.next(dragEvent);

                        this.update();

                        if (!isNullOrUndefined(this.fromSegment)) {
                            this.fromSegment.update();
                            if (!isNullOrUndefined(this.fromSegment.addPoint)) {
                                this.fromSegment.addPoint.update();
                            }
                        }
                        if (!isNullOrUndefined(this.toSegment)) {
                            this.toSegment.update();
                            if (!isNullOrUndefined(this.toSegment.addPoint)) {
                                this.toSegment.addPoint.update();
                            }
                        }

                        this._lastDragLocation = new createjs.Point(event.localX, event.localY);
                    }
                }
            }
        });
    }

    protected onPressUp(event: createjs.MouseEvent): void {
        this._zoneJointBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this._lastDragLocation = null;
                this.isMouseDown = false;
                this.isDragging = false;
                this.update();
                this.pressUp.next(new DisplayItemMouseEvent(this, event));
            }
        });
    }

    protected removeEventHandlers(): void {
        this._zoneJointBase.runOutsideAngular(() => {
            this.container.removeAllEventListeners();
        });
    }

    private loadBitmaps(): Observable<any> {
        return this._zoneJointBase.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._loadQueueBase) && !isNullOrUndefined(this.mode) && this._loadQueueBase.isLoaded === true) {
                const arrowLoad = this._loadQueueBase.getJointArrow(this._shapeBase.register.registerBaseModel.registerIndex).pipe(
                    map((image) => {
                        if(!isNullOrUndefined(this._arrowBitmap)){
                            this._arrowBitmap.image = image;
                        }
                    })
                );

                const jointLoad = this._loadQueueBase.getJoint(this.mode, this._shapeBase.register.registerBaseModel.registerIndex, this.isMouseOver, this.isDragging).pipe(
                    map((image) => {
                        if(!isNullOrUndefined(this._jointBitmap)){
                            this._jointBitmap.image = image;
                        }
                    })
                );

                return zip(arrowLoad, jointLoad);
            }

            return EMPTY;
        });
    }
}
