import { Injectable } from '@angular/core';
import { BaseService } from '@shared/base/Base.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';

/**
 * The navigation bar action service.
 * Used to manage actions on the NavBar component
 *
 * @export
 * @class NavBarActionService
 * @extends {BaseService}
 */
@Injectable()
export class NavBarActionService extends BaseService {
    /**
     *
     * Fires when a action is removed from the navigation bar.
     *
     * @type {Subject<NavBarAction>}
     * @memberof NavBarActionService
     */
    public actionRemoved: Subject<NavBarAction> = new Subject();
    /**
     * Fires when a action changes.
     *
     * @type {Subject<Array<NavBarAction>>}
     * @memberof NavBarActionService
     */
    public actionsChanged: Subject<Array<NavBarAction>> = new Subject();

    /**
     * Fires when a action is added to the navigation bar.
     *
     * @type {Subject<NavBarAction>}
     * @memberof NavBarActionService
     */
    public actionAdded: Subject<NavBarAction> = new Subject();
    /**
     * Fires when the actions are cleared.
     *
     * @type {Subject<null>}
     * @memberof NavBarActionService
     */
    public actionsCleard: Subject<null> = new Subject();

    private _actions: Array<NavBarAction> = [];
    private _showRiftConnectionState: boolean = false;

    /**
     * Creates an instance of NavBarActionService.
     *
     * @memberof NavBarActionService
     */
    public constructor() {
        super();
    }

    /**
     * Adds an action to the nav bar.
     *
     * @param {NavBarAction} action
     * @memberof NavBarActionService
     */
    public addAction(action: NavBarAction): void {
        if (!isNullOrUndefined(action) && this._actions.findIndex(i => i.name === action.name) === -1) {
            this._actions.push(action);
            this.actionsChanged.next(this._actions);
            this.actionAdded.next(action);
        }
    }

    /**
     * Clears all the nav bar actions.
     *
     * @memberof NavBarActionService
     */
    public clearActions(): void {
        this._actions = [];
        this.actionsChanged.next(this._actions);
        this.actionsCleard.next();
    }

    /**
     * Removes an action from the nav bar.
     *
     * @param {NavBarAction} action
     * @memberof NavBarActionService
     */
    public removeAction(action: NavBarAction): void {
        if (!isNullOrUndefined(action)) {
            const index = this._actions.findIndex(i => i.name === action.name);
            if (index !== -1) {
                this._actions.splice(index, 1);
                this.actionsChanged.next(this._actions);
                this.actionRemoved.next(action);
            }
        }
    }

    /**
     * The nav bar actions.
     *
     * @readonly
     * @type {Array<NavBarAction>}
     * @memberof NavBarActionService
     */
    public get actions(): Array<NavBarAction> {
        return this._actions;
    }
}
