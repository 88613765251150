export enum BluetoothTxPowerEnum {
    minus20 = -20,
    minus16 = -16,
    minus12 = -12,
    minus8 = -8,
    minus4 = -4,
    zero = 0,
    plus4 = 4,
    plus8 = 8
}
