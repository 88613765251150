import { SearchCriteriaModel } from '@em/models/restapi/SearchCriteria.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';

export class SearchCriteriaGroupModel extends BaseModel implements IRestModel {
    public readonly isIRestModel = true;

    private _searchCriteria: RestModelChangeTrackerArray<SearchCriteriaModel> = new RestModelChangeTrackerArray<SearchCriteriaModel>();

    public constructor() {
        super();

        this.registerChangeTrackerArray('searchCriteria');
    }

    public get searchCriteria(): RestModelChangeTrackerArray<SearchCriteriaModel> {
        return this._searchCriteria;
    }

    public set searchCriteria(value: RestModelChangeTrackerArray<SearchCriteriaModel>) {
        this._searchCriteria = value;
    }

    public loadFromRestApiModel(restModel: any): void {
        this.searchCriteria = RestModelUtility.loadFromArrayToChangeTrackerArray<SearchCriteriaModel>(restModel.SearchCriteria, SearchCriteriaModel);

        this.setPropertyOriginalValue('searchCriteria', this.searchCriteria);
    }

    public toRestApiModel() {
        return {
            SearchCriteria: RestModelUtility.toJsonArray<SearchCriteriaModel>(this.searchCriteria),
        };
    }
}
