import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';

export class UserDeviceDisplayColumnModel extends BaseModel implements IRestModel {
    public columnName: string = null;
    public metaDataKeyId?: number = null;
    public readonly isIRestModel = true;
    public sorted: boolean = null;
    public sortedAsc: boolean = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-UserDeviceDisplayColumnModel');
    }

    public toRestApiModel() {
        return {
            ColumnName: this.columnName,
            MetaDataKeyId: this.metaDataKeyId,
            Sorted: this.sorted,
            SortedAsc: this.sortedAsc,
        };
    }
}
