import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangePasswordModel } from '@rift/models/restapi/ChangePassword.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { WebAPIKeyModel } from '@rift/models/restapi/WebAPIKey.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { TLSCertModel } from '@rift/models/restapi/TLSCert.Model';
import { MatDialog } from '@angular/material/dialog';
import { SSLCertModel } from '@rift/models/restapi/SSLCert.Model';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';
import { HttpEnabledDataModel } from '@rift/models/restapi/HttpEnabledData.Model';
import { AuthenticationEnabledDataModel } from '@rift/models/restapi/AuthenticationEnabledData.Model';

@Injectable()
export class RestApiSecurityService extends RiftRestApiService {
    private _controller = 'security';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public changePassword(changePassword: ChangePasswordModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ResultModel> {
        return this.put<ChangePasswordModel, ResultModel>(`${this._controller}/password_change`, changePassword, ResultModel, this.getTokenParams(), process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getWebAPIKey(process?: ProcessMonitorServiceProcess): Observable<WebAPIKeyModel> {
        return this.get<WebAPIKeyModel>(`${this._controller}/webapikey`, WebAPIKeyModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public regenerateWebAPIKey(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<null, ResultModel>(`${this._controller}/regenerate_webapikey`, null, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getCACertNames(process?: ProcessMonitorServiceProcess): Observable<Array<string>> {
        return this.get(`${this._controller}/tls_cert_names`, 'string', this.getTokenParams(), process).pipe(
            map(result => result as Array<string>)
        );
    }

    public uploadCACert(cert: TLSCertModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post(`${this._controller}/tls_cert_upload`, cert, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public removeCACert(cert: TLSCertModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post(`${this._controller}/tls_cert_remove`, cert, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public uploadCertPair(cert: SSLCertModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ResultModel> {
        return this.post(`${this._controller}/ssl_cert_upload`, cert, ResultModel, this.getTokenParams(), process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getCertPairNames(process?: ProcessMonitorServiceProcess): Observable<Array<string>> {
        return this.get(`${this._controller}/ssl_cert_names`, 'string', this.getTokenParams(), process).pipe(
            map(result => result as Array<string>)
        );
    }

    public removeCertPair(cert: SSLCertModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ResultModel> {
        return this.post(`${this._controller}/ssl_cert_remove`, cert, ResultModel, this.getTokenParams(), process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getHttpEnabled(serialNumber: string, process?: ProcessMonitorServiceProcess): Observable<HttpEnabledDataModel> {
        return this.get<HttpEnabledDataModel>(`${this._controller}/${serialNumber}/httpenabled`, HttpEnabledDataModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setHttpEnabled(serialNumber: string, httpEnabled: HttpEnabledDataModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ResultModel> {
        return this.put<HttpEnabledDataModel, ResultModel>(`${this._controller}/${serialNumber}/httpenabled`, httpEnabled, ResultModel, this.getTokenParams(), process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getAuthenticationEnabled(serialNumber: string, process?: ProcessMonitorServiceProcess): Observable<AuthenticationEnabledDataModel> {
        return this.get<AuthenticationEnabledDataModel>(`${this._controller}/${serialNumber}/authenticationenabled`, AuthenticationEnabledDataModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setAuthenticationEnabled(serialNumber: string, authenticationEnabled: AuthenticationEnabledDataModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<ResultModel> {
        return this.put<AuthenticationEnabledDataModel, ResultModel>(`${this._controller}/${serialNumber}/authenticationenabled`, authenticationEnabled, ResultModel, this.getTokenParams(), process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
