import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BaseService } from '@shared/base/Base.Service';
import { BreadCrumb } from '@shared/service/breadcrumb/BreadCrumb.Service.Crumb';
import { BreadCrumbLookupSet } from '@shared/service/breadcrumb/BreadCrumb.Service.LookupSet';
import { BreadCrumbIParameterText, BreadCrumbIRouteData } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { isArray, isNullOrUndefined } from '@shared/utility/General.Utility';
import { RoutesParams, RoutesUtility } from '@shared/utility/Routes.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class BreadCrumbService extends BaseService {
    public crumbs: Array<BreadCrumb> = [];
    public onCrumbsChanged: Observable<Array<BreadCrumb>>;

    private _onCrumbsChangedSubject: Subject<Array<BreadCrumb>>;
    private _lookupSets: Array<BreadCrumbLookupSet> = [];

    /**
     * Creates an instance of BreadCrumbService.
     *
     * @param {ActivatedRoute} _activatedRoute The activated route service.
     * @param {Router} _router The router service.
     * @param {Title} _title The title service.
     * @memberof BreadCrumbService
     */
    public constructor(
        private readonly _configurationService: ConfigurationService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _title: Title) {
        super();

        this._onCrumbsChangedSubject = new Subject<Array<BreadCrumb>>();
        this.onCrumbsChanged = this._onCrumbsChangedSubject.asObservable();

        this.addSubscription(this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.onNavigationEnded(event);
            }
        }));
    }

    /**
     * Adds a lookup set to the service.
     *
     * @param {BreadCrumbLookupSet} lookupSet The lookup set  to add.
     * @memberof BreadCrumbService
     */
    public addLookupSet(lookupSet: BreadCrumbLookupSet): void {
        this._lookupSets.push(lookupSet);
        this.buildBreadCrumbs();
    }

    /**
     * Adds the lookup set if not present else updates existing set.
     *
     * @param {BreadCrumbLookupSet} lookupSet The lookup set to Add or Update.
     * @memberof BreadCrumbService
     */
    public addUpdateLookupSet(lookupSet: BreadCrumbLookupSet): void {
        const index = this._lookupSets.findIndex(i => i.name === lookupSet.name);
        if (index === -1) {
            this.addLookupSet(lookupSet);
        } else {
            const updateLookupSet = this._lookupSets[index];
            updateLookupSet.lookups = lookupSet.lookups;
        }
        this.buildBreadCrumbs();
    }

    /**
     * Gets a bread crumb by its name.
     *
     * @param {string} name The event name.
     * @returns {BreadCrumb} The bread crumb matching the name.
     * @memberof BreadCrumbService
     */
    public getBreadCrumbByName(name: string): BreadCrumb {
        return this.crumbs.find(i => i.name === name);
    }

    /**
     * Removes a lookup set from the service.
     *
     * @param {BreadCrumbLookupSet} lookupSet The lookup set to remove.
     * @memberof BreadCrumbService
     */
    public removeLookupSet(lookupSet: BreadCrumbLookupSet): void {
        const index = this._lookupSets.findIndex(i => i.name === lookupSet.name);
        if (index !== -1) {
            this._lookupSets.splice(index, 1);
        }
        this.buildBreadCrumbs();
    }

    /**
     *
     */
    public refreshBreadCrumbs(){
        this.buildBreadCrumbs();
    }

    /**
     * Adds or updates a breadcrumb to be active in route.
     *
     * @private
     * @param {number} routeIndex The index of the breadcrumb in the route.
     * @param {string} name The name of the breadcrumb.
     * @param {string} text The display text of the breadcrumb.
     * @param {string} routerLink The router link of the breadcrumb.
     * @param {boolean} [isCustomEvent=false] True if this breadcrumb is custom event.
     * @returns {BreadCrumb} The added or updated breadcrumb.
     * @memberof BreadCrumbService
     */
    private addOrUpdateBreadCrumb(routeIndex: number, name: string, text: string, routerLink: string, isCustomEvent: boolean = false): BreadCrumb {
        let breadCrumb: BreadCrumb = this.crumbs.find(i => i.name === name);

        if (isNullOrUndefined(breadCrumb)) {
            breadCrumb = new BreadCrumb(name, text, routerLink, isCustomEvent);
            this.crumbs.push(breadCrumb);
        }

        breadCrumb.routeIndex = routeIndex;
        breadCrumb.inRoute = true;
        breadCrumb.routerLink = routerLink;

        return breadCrumb;
    }

    /**
     * Builds the bread crumb trail at the navigation end event.
     *
     * @private
     * @memberof BreadCrumbService
     */
    private buildBreadCrumbs() {
        this.addSubscription(RoutesUtility.getRoutesAndParams(this._activatedRoute).subscribe(routesParams => {
            const length = this.crumbs.length;
            for (let index = 0; index < length; index++) {
                const crumb = this.crumbs[index];
                crumb.inRoute = false;
                crumb.routeIndex = 0;
                crumb.isLast = false;
            }

            let routeIndex = 0;

            const routesLength = routesParams.routes.length;
            let url = '';

            let lastAddedBreadCrumb: BreadCrumb;
            for (let ri = 0; ri < routesLength; ri++) {
                const route = routesParams.routes[ri];
                const routeConfig = route.routeConfig;
                const routeData = routeConfig.data;

                if (!isNullOrUndefined(routeData) && !StringUtility.isEmptyOrWhiteSpace(routeConfig.path) && !isNullOrUndefined(routeData.breadcrumb)) {
                    const routeDataBreadCrumb = routeData.breadcrumb as BreadCrumbIRouteData;
                    const isCustomEvent: boolean = isNullOrUndefined(routeDataBreadCrumb.isCustomEvent) ? false : routeDataBreadCrumb.isCustomEvent;
                    const name: string = routeDataBreadCrumb.name;

                    if (isNullOrUndefined(name)) {
                        throw new Error(`BreadCrumb route config must include unique name: ${JSON.stringify(routeDataBreadCrumb)}`);
                    }

                    if (!isArray(routeDataBreadCrumb.text)) {
                        // only the one config just add it. data: { breadcrumb: { text: 'Text Here' } }

                        if (!isNullOrUndefined(routeDataBreadCrumb.overridePath)) {
                            url += '/' + this.replacePathParamWithParamValue(routeDataBreadCrumb.overridePath, routesParams);
                        } else {
                            url += '/' + this.replacePathParamWithParamValue(routeConfig.path, routesParams);
                        }

                        lastAddedBreadCrumb = this.addOrUpdateBreadCrumb(routeIndex, name, isNullOrUndefined(routeDataBreadCrumb.text) ? routeDataBreadCrumb.getText() as string : routeDataBreadCrumb.text as string, isCustomEvent ? '' : url, isCustomEvent);
                        routeIndex++;
                    } else {
                        // have multiple config to match. data: { breadcrumb: { text: [ { paramterName: paramterValue, text: 'Text Here' } ] } }
                        const lookups = this.getLookups(routeDataBreadCrumb);
                        const lookupsLength = lookups.length;

                        const paramsKeys = Object.keys(routesParams.params);
                        const paramsKeysLength = paramsKeys.length;

                        let matchParamText = false;
                        let crumbText = '';
                        let crumbName = null;

                        for (let lui = 0; lui < lookupsLength; lui++) {
                            const lookup = lookups[lui];

                            for (let pki = 0; pki < paramsKeysLength; pki++) {
                                const paramsKey = paramsKeys[pki];
                                if (!isNullOrUndefined(lookup[paramsKey])) {

                                    const paramsKeyValue = routesParams.params[paramsKey];
                                    const lookupKeyValue = lookup[paramsKey];

                                    if (StringUtility.toString(paramsKeyValue) === lookupKeyValue) {
                                        crumbText = isNullOrUndefined(lookup.text) ? lookup.getText() : lookup.text;
                                        crumbName = lookup.name;

                                        if (!isNullOrUndefined(lookup.overridePath)) {
                                            url += '/' + this.replacePathParamWithParamValue(lookup.overridePath, routesParams);
                                        } else {
                                            url += '/' + this.replacePathParamWithParamValue(routeConfig.path, routesParams);
                                        }

                                        matchParamText = true;
                                        break;
                                    }

                                } else {
                                    break;
                                }
                            }
                        }

                        if (matchParamText === true) {
                            lastAddedBreadCrumb = this.addOrUpdateBreadCrumb(routeIndex, crumbName, crumbText, isCustomEvent ? '' : url, isCustomEvent);
                            routeIndex++;
                        }
                    }
                }
                else if(!StringUtility.isEmptyOrWhiteSpace(routeConfig.path)){
                    // This is for part of a route that might not have any
                    // breadcrumb info but is required to make a proper
                    // route link
                    url += '/' + this.replacePathParamWithParamValue(routeConfig.path, routesParams);
                }
            }

            if (!isNullOrUndefined(lastAddedBreadCrumb)) {
                lastAddedBreadCrumb.isLast = true;
            }

            let pageTitle = '';
            const crumbsLength = this.crumbs.length;
            for (let ci = 0; ci < crumbsLength; ci++) {
                const crumb = this.crumbs[ci];
                if (crumb.inRoute === true) {
                    pageTitle += crumb.text;
                }
            }
            this._title.setTitle(`${this._configurationService.baseTitle} - ${StringUtility.trimEnd(pageTitle.trim(), '-')}`);

            this._onCrumbsChangedSubject.next(this.crumbs);
        }));
    }

    /**
     * Combines lookup sets and fixed route configuration lookups
     * to single array.
     *
     * @private
     * @param {BreadCrumbIRouteData} routeDataBreadCrumb The data from the route configuration
     * @returns {Array<BreadCrumbIParameterText>} A array of route match parameters and config.
     * @memberof BreadCrumbService
     */
    private getLookups(routeDataBreadCrumb: BreadCrumbIRouteData): Array<BreadCrumbIParameterText> {
        const parameterTextsBase = (routeDataBreadCrumb.text as Array<BreadCrumbIParameterText>);

        const lookupSetNames: string[] = [];
        const parameterTexts: BreadCrumbIParameterText[] = [];
        const parameterTextsBaseLength = parameterTextsBase.length;
        for (let index = 0; index < parameterTextsBaseLength; index++) {
            const item = parameterTextsBase[index];
            if (!isNullOrUndefined(item.lookupSetName)) {
                lookupSetNames.push(item.lookupSetName);
            } else {
                parameterTexts.push(item);
            }
        }

        const lookupSets: BreadCrumbLookupSet[] = [];
        const lookupSetsLength = this._lookupSets.length;
        for (let index = 0; index < lookupSetsLength; index++) {
            const item = this._lookupSets[index];
            if (lookupSetNames.indexOf(item.name) !== -1) {
                lookupSets.push(item);
            }
        }

        const lookups = parameterTexts;

        const setsLength = lookupSets.length;
        for (let i = 0; i < setsLength; i++) {
            lookups.push(...lookupSets[i].lookups);
        }

        return lookups;
    }

    /**
     * Handles the navigation end event.
     *
     * @private
     * @param {NavigationEnd} event The navigation end event args.
     * @memberof BreadCrumbService
     */
    private onNavigationEnded(event: NavigationEnd): void {
        this.buildBreadCrumbs();
    }

    private replacePathParamWithParamValue(path: string, routesParams: RoutesParams): string {
        let pathWithParams: string = path;
        const paramsKeys = Object.keys(routesParams.params);
        const paramsKeysLength = paramsKeys.length;

        for (let pki = 0; pki < paramsKeysLength; pki++) {
            const paramsKey = paramsKeys[pki];
            if (!isNullOrUndefined(routesParams.params[paramsKey])) {
                pathWithParams = pathWithParams.replace(`:${paramsKey}`, routesParams.params[paramsKey]);
            }
        }

        return pathWithParams;
    }
}
