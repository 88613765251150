import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { TaskToExecuteModel } from '@em/models/restapi/TaskToExecute.Model';
import { VideoEntryModel } from '@em/models/restapi/VideoEntry.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { IRestPage } from '@shared/interface/IRestPage';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { DeviceActivityModel } from '@em/models/restapi/DeviceActivity.Model';


export class PageModel<TModel extends IRestModel> extends BaseModel implements IRestPage<TModel> {
    public isIPage = true;
    public items: Array<TModel> = null;

    public options: PaginationOptionsModel = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        if (!isNullOrUndefined(restModel.Activity)) {
            this.items = RestModelUtility.loadFromArray<TModel>(restModel.Activity, DeviceActivityModel as any);
        }

        if (!isNullOrUndefined(restModel.Options)) {
            this.options = RestModelUtility.loadFrom<PaginationOptionsModel>(restModel.Options, PaginationOptionsModel);
        }

        if (!isNullOrUndefined(restModel.PaginationOptions)) {
            this.options = RestModelUtility.loadFrom<PaginationOptionsModel>(restModel.PaginationOptions, PaginationOptionsModel);
        }

        if (!isNullOrUndefined(restModel.data)) {
            this.items = RestModelUtility.loadFromArray<TModel>(restModel.data, VideoEntryModel as any);
            this.options = new PaginationOptionsModel();
            this.options.totalResults = restModel.totalResults;
        }

        if (!isNullOrUndefined(restModel.Connection)) {
            this.items = RestModelUtility.loadFromArray<TModel>(restModel.Connection, DeviceModel as any);
        }

        if (!isNullOrUndefined(restModel.TaskList)) {
            this.items = RestModelUtility.loadFromArray<TModel>(restModel.TaskList, TaskToExecuteModel as any);
        }
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
