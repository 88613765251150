import { Component, HostBinding, HostListener, Inject, Injector, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UnitsOfMeasurementInputValidators } from '@rift/directives/unitsofmeasurementinput/UnitsOfMeasurementInput.Directive';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { HeightRanges } from '@rift/shared/Settings.Device.Height';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { AutoHeightDetectionStateEnum, AutoHeightDetectionStateEnumHelpers } from '@shared/enum/AutoHeightDetectionState.Enum';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { UnitOfMeasurementEnum } from '@shared/enum/UnitOfMeasurement.Enum';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { from, Observable, zip } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

export class PositionDialogData {
    public constructor(public readonly devices: Array<DeviceModel>) {
    }
}

export class PositionDialogResult {
    public constructor(public readonly deviceDataUpdate: boolean = false) {
    }
}

@Component({
    selector: 'rift-validate-heights-position-dialog',
    templateUrl: './Position.Dialog.Component.html',
    styleUrls: ['./Position.Dialog.Component.scss'],
})
export class PositionDialogComponent extends BaseComponent implements ISaveAllChanges, OnDestroy {
    public static className: string = 'PositionDialogComponent';

    @HostBinding()
    public id: string = 'rift-validate-heights-position-dialog';

    public autoDetectHeightProcess: ProcessMonitorServiceProcess;
    public UnitOfMeasurementEnum = UnitOfMeasurementEnum;
    public AutoHeightDetectionStateEnumHelpers = AutoHeightDetectionStateEnumHelpers;
    public DeviceCapabilitiesEnum = DeviceCapabilitiesEnum;
    public HeightRanges = HeightRanges;
    public AutoHeightDetectionStateEnum = AutoHeightDetectionStateEnum;
    public autoEnabled: boolean;
    public form: FormGroup = null;
    public updateFormProcess: ProcessMonitorServiceProcess;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: PositionDialogData,
        private readonly _dialogRef: MatDialogRef<PositionDialogComponent>,
        private readonly _formBuilder: FormBuilder,
        private readonly _deviceService: DeviceService,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this._dialogRef.disableClose = true;

        this.saveAllChangesProcess = this.processMonitorService.getProcess(PositionDialogComponent.className, this.saveAllChangesProcessText);
        this.autoDetectHeightProcess = this.processMonitorService.getProcess(PositionDialogComponent.className, 'Auto detecting device height');
        this.updateFormProcess = this.processMonitorService.getProcess(PositionDialogComponent.className, 'Update form');
        this.formValuesChangeProcess = this.processMonitorService.getProcess(PositionDialogComponent.className, 'Form values change');

        this.form = this._formBuilder.group({
            devices: this._formBuilder.array(this.data.devices.map(d => this.getDeviceForm(d)))
        });
        this.formGroupTracker.track(this.form);

        this.addSubscription(this.observableHandlerBase(_dialogRef.afterOpened(), this.updateFormProcess).subscribe(() => this.updateForm()), this.updateFormProcess);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            from(this.data.devices).pipe(
                flatMap(device => this._deviceService.updateDevice(device, process).pipe(
                        map(() => true)
                    ))
            )
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            tap(() => {
                this._dialogRef.close(new PositionDialogResult(true));
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this);
    }

    public markFormGroupTouched(formGroup: FormGroup): void {
        (Object as any).values(formGroup.controls).forEach((control: FormGroup) => {
            control.markAsTouched();
            control.markAsDirty();
            control.updateValueAndValidity();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    public updateForm(): void {
        (this.form.controls.devices as FormArray).controls.forEach(formGroup => {
            this.markFormGroupTouched((formGroup as FormGroup));
        });
        this.form.updateValueAndValidity();
    }

    public onAutoDetectClick(device: DeviceModel, forGroup: FormGroup): void {
        this.addSubscription(
            this.observableHandlerBase(this._deviceService.autoDetectHeight(device, this.autoDetectHeightProcess), this.autoDetectHeightProcess)
                .subscribe(result => {
                    forGroup.controls.height.setValue(device.actualHeight, {
                        emitEvent: false
                    });
                }),
            this.autoDetectHeightProcess
        );
    }

    public onSaveClick(): void {
        this.saveAllChangesStartBase(this, this.openPleaseWaitSavingDialog());
    }

    private getDeviceForm(device: DeviceModel): FormGroup {
        this.changeTracker.track(device);

        const deviceForm = this._formBuilder.group({
            x: [device.x, Validators.compose([Validators.required, UnitsOfMeasurementInputValidators.min(), UnitsOfMeasurementInputValidators.max()])],
            y: [device.y, Validators.compose([Validators.required, UnitsOfMeasurementInputValidators.min(), UnitsOfMeasurementInputValidators.max()])],
            height: [device.actualHeight, Validators.compose([Validators.required, UnitsOfMeasurementInputValidators.min(), UnitsOfMeasurementInputValidators.max()])],
            autoEnabled: [device.autoHeightEnabled]
        });

        this.addSubscription(
            this.observableHandlerBase(deviceForm.valueChanges, this.formValuesChangeProcess).subscribe(() => {
                if (!this.isNullOrUndefined(device)) {
                    device.x = parseInt(deviceForm.controls.x.value, 10);
                    device.y = parseInt(deviceForm.controls.y.value, 10);
                    if (this.autoEnabled === true) {
                        device.height = 0;
                    } else {
                        device.height = parseInt(deviceForm.controls.height.value, 10);
                    }
                }
            }),
            this.formValuesChangeProcess);

        this.addSubscription(
            this.observableHandlerBase(deviceForm.controls.autoEnabled.valueChanges, this.formValuesChangeProcess).subscribe(() => {
                if (!this.isNullOrUndefined(device)) {
                    const newValue = deviceForm.controls.autoEnabled.value;

                    if (!deviceForm.disable) {
                        if (newValue) {
                            deviceForm.controls.height.disable();
                            if (!this.isNullOrUndefined(this.autoEnabled)) {
                                this.onAutoDetectClick(device, deviceForm);
                            }
                        } else {
                            deviceForm.controls.height.enable();
                        }
                    }

                    this.autoEnabled = newValue;
                }
            }),
            this.formValuesChangeProcess);

        return deviceForm;
    }
}
