import { Injectable } from '@angular/core';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiAccountService } from '@rift/service/restapi/RestApiAccountService';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { LoginAttemptResultModel } from '@shared/models/restapi/LoginAttemptResult.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';

@Injectable()
export class AccountService extends RiftBaseService {

    private _loginLoadingTracker = new ObservableTracker<LoginAttemptResultModel>();

    public constructor(
        private readonly _restApiAccountService: RestApiAccountService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
    }

    public login(userName: string, password: string, process?: ProcessMonitorServiceProcess): Observable<LoginAttemptResultModel> {
        return this._loginLoadingTracker
            .getLoading(userName, password)
            .observable(this._restApiAccountService.login(userName, password, process));
    }
}
