import { RecordingStateEnum } from '@shared/enum/RecordingState.Enum';
import { SynchronizationStateEnum } from '@shared/enum/SynchronizationState.Enum';
import { VideoDownloadStateEnum } from '@shared/enum/VideoDownloadState.Enum';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';

export class VideoStateModel extends BaseModel implements IRestModel {
    public downloadState: VideoDownloadStateEnum = null;
    public hasImageData: boolean = null;
    public percentageDownloaded: number = null;
    public readonly isIRestModel = true;
    public recordingState: RecordingStateEnum = null;

    public sessionID: number = null;
    public synchronizationState: SynchronizationStateEnum = null;
    public videoDataEntryID: number = null;
    public nodes: RestModelChangeTrackerArray<VideoStateModel> = new RestModelChangeTrackerArray<VideoStateModel>();

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-VideoStateModel');

        this.nodes = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.Nodes, VideoStateModel);
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
