import { Component, Injector, HostBinding, OnDestroy } from '@angular/core';
import { SettingsCounterServiceSettingsComponent, SettingsCounterServiceSettingsDialogData } from '@em/components/settings/servicestatusandconfiguration/counterservicesettings/Settings.CounterServiceSettings.Component';
import { ModuleStatusModel } from '@em/models/restapi/ModuleStatus.Model';
import { ServiceStatusAndConfigurationModel } from '@em/models/restapi/ServiceStatusAndConfiguration.Model';
import { SettingService } from '@em/service/data/setting/Setting.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'em-settings-service-status-and-configuration',
    templateUrl: './Settings.ServiceStatusAndConfiguration.Component.html',
    styleUrls: ['./Settings.ServiceStatusAndConfiguration.Component.scss']
})
export class SettingsServiceStatusAndConfigurationComponent extends BaseComponent implements ILoadDate, OnDestroy {
    public static className: string = 'SettingsServiceStatusAndConfigurationComponent';

    public displayedColumns = ['state', 'networkAddress', 'moduleVersion', 'upTime'];
    public eMCounterServiceDataSource = new MatTableDataSource<ModuleStatusModel>();
    public eMDataServiceDataSource = new MatTableDataSource<ModuleStatusModel>();
    public eMLicensingDataSource = new MatTableDataSource<ModuleStatusModel>();
    public eMNotificationServiceDataSource = new MatTableDataSource<ModuleStatusModel>();
    public eMRIFTSetupServiceDataSource = new MatTableDataSource<ModuleStatusModel>();
    public eMSchedulerDataSource = new MatTableDataSource<ModuleStatusModel>();
    public eMTLServiceDataSource = new MatTableDataSource<ModuleStatusModel>();
    public eMVDLServiceDataSource = new MatTableDataSource<ModuleStatusModel>();

    @HostBinding()
    public id: string = 'em-settings-service-status-and-configuration';
    public refreshingServiceStatesProcess: ProcessMonitorServiceProcess;

    public serviceStatusAndConfiguration: ServiceStatusAndConfigurationModel;
    public formValueChangesProcess: ProcessMonitorServiceProcess;

    private _dataPollingEvent: DataPollingEvent;

    public constructor(
        private readonly _dataPollingService: DataPollingService,
        private readonly _dialog: MatDialog,
        private readonly _settingService: SettingService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.formValueChangesProcess = this.processMonitorService.getProcess(SettingsServiceStatusAndConfigurationComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsServiceStatusAndConfigurationComponent.className, this.loadDataProcessText);
        this.refreshingServiceStatesProcess = this.processMonitorService.getProcess(SettingsServiceStatusAndConfigurationComponent.className, 'Refreshing service states');

        this._dataPollingEvent = new DataPollingEvent('SettingsServiceStatusAndConfigurationComponent', 0, 10000, this.loadDataProcess);

        this.startDataPolling();

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public dataExportGetData = (displayedColumn: string, data: ModuleStatusModel) => {
        switch (displayedColumn) {
            case 'state':
                switch (data.state) {
                    case 0:
                        return 'Running';
                    case 2:
                        return 'Not seen for 2 minutes';
                    case 3:
                        return 'Not seen for 10 minutes';
                    default:
                        return '';
                }
            case 'upTime':
                return data.upTimeText;
            default:
                return data[displayedColumn];
        }
    };

    public dataExportGetHeader = (displayedColumn: string) => displayedColumn;

    public ngOnDestroy(): void {
        this.stopDataPolling();
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._settingService.getServiceStatusAndConfiguration(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.serviceStatusAndConfiguration = result;

                        this.checkVersionNumbers('EMCounterService');
                        this.checkVersionNumbers('EMDataService');
                        this.checkVersionNumbers('EMLicensing');
                        this.checkVersionNumbers('EMScheduler');
                        this.checkVersionNumbers('EMVDLService');
                        this.checkVersionNumbers('EM RIFT Setup Service');
                        this.checkVersionNumbers('EMTLService');
                        this.checkVersionNumbers('EM Notification Service');

                        const eMCounterServiceDataSourceData: ModuleStatusModel[] = [];
                        const eMDataServiceDataSourceData: ModuleStatusModel[] = [];
                        const eMLicensingDataSourceData: ModuleStatusModel[] = [];
                        const eMSchedulerDataSourceData: ModuleStatusModel[] = [];
                        const eMVDLServiceDataSourceData: ModuleStatusModel[] = [];
                        const eMRIFTSetupServiceDataSourceData: ModuleStatusModel[] = [];
                        const eMTLServiceDataSourceData: ModuleStatusModel[] = [];
                        const eMNotificationServiceDataSourceData: ModuleStatusModel[] = [];

                        const length = this.serviceStatusAndConfiguration.moduleData.length;
                        for (let index = 0; index < length; index++) {
                            const m = this.serviceStatusAndConfiguration.moduleData[index];
                            if (m.isOldVersion === false) {
                                switch (m.moduleName) {
                                    case 'EMCounterService':
                                        eMCounterServiceDataSourceData.push(m);
                                        break;
                                    case 'EMDataService':
                                        eMDataServiceDataSourceData.push(m);
                                        break;
                                    case 'EMLicensing':
                                        eMLicensingDataSourceData.push(m);
                                        break;
                                    case 'EMScheduler':
                                        eMSchedulerDataSourceData.push(m);
                                        break;
                                    case 'EMVDLService':
                                        eMVDLServiceDataSourceData.push(m);
                                        break;
                                    case 'EM RIFT Setup Service':
                                        eMRIFTSetupServiceDataSourceData.push(m);
                                        break;
                                    case 'EMTLService':
                                        eMTLServiceDataSourceData.push(m);
                                        break;
                                    case 'EM Notification Service':
                                        eMNotificationServiceDataSourceData.push(m);
                                        break;
                                }
                            }
                        }

                        this.eMCounterServiceDataSource.data = eMCounterServiceDataSourceData;
                        this.eMDataServiceDataSource.data = eMDataServiceDataSourceData;
                        this.eMLicensingDataSource.data = eMLicensingDataSourceData;
                        this.eMSchedulerDataSource.data = eMSchedulerDataSourceData;
                        this.eMVDLServiceDataSource.data = eMVDLServiceDataSourceData;
                        this.eMRIFTSetupServiceDataSource.data = eMRIFTSetupServiceDataSourceData;
                        this.eMTLServiceDataSource.data = eMTLServiceDataSourceData;
                        this.eMNotificationServiceDataSource.data = eMNotificationServiceDataSourceData;
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public settings(moduleStatus: ModuleStatusModel): void {
        this._dialog.open(SettingsCounterServiceSettingsComponent, { data: new SettingsCounterServiceSettingsDialogData(moduleStatus), disableClose: true });
    }

    private checkVersionNumbers(moduleName: string): void {
        let maxModuleVersion = null;

        const moduleDataLength = this.serviceStatusAndConfiguration.moduleData.length;

        for (let service1Index = 0; service1Index < moduleDataLength; service1Index++) {
            const service1 = this.serviceStatusAndConfiguration.moduleData[service1Index];
            for (let service2Index = 0; service2Index < moduleDataLength; service2Index++) {
                const service2 = this.serviceStatusAndConfiguration.moduleData[service2Index];
                if (service1.uniqueId !== service2.uniqueId && service1.moduleName === moduleName && service2.moduleName === moduleName) {
                    if (this.compareVersionNumbers(service2.moduleVersion, service1.moduleVersion) > 0) {
                        maxModuleVersion = service2.moduleVersion;
                    }
                }
            }
        }

        for (let serviceIndex = 0; serviceIndex < moduleDataLength; serviceIndex++) {
            const service = this.serviceStatusAndConfiguration.moduleData[serviceIndex];
            if (service.moduleName === moduleName) {
                if (this.compareVersionNumbers(service.moduleVersion, maxModuleVersion) < 0) {
                    service.isOldVersion = true;
                } else {
                    service.isOldVersion = false;
                }
            }
        }
    }

    private compareVersionNumbers(v1: string, v2: string): number {
        if (this.isNullOrUndefined(v1) || this.isNullOrUndefined(v2)) {
            return 0;
        }
        const v1parts = v1.split('.');
        const v2parts = v2.split('.');

        // First, validate both numbers are true version numbers
        function validateParts(parts) {
            const length = parts.length;
            for (let i = 0; i < length; ++i) {
                // is positive integer
                if (!/^\d+$/.test(parts[i])) {
                    return false;
                }
            }
            return true;
        }

        if (!validateParts(v1parts) || !validateParts(v2parts)) {
            return NaN;
        }

        for (let i = 0; i < v1parts.length; ++i) {
            if (v2parts.length === i) {
                return 1;
            }

            if (v1parts[i] === v2parts[i]) {
                continue;
            }
            if (v1parts[i] > v2parts[i]) {
                return 1;
            }
            return -1;
        }

        if (v1parts.length !== v2parts.length) {
            return -1;
        }

        return 0;
    }

    private subDataPolling(): void {
        this.addSubscription(this.observableHandlerBase(this._dataPollingEvent.poll, this.refreshingServiceStatesProcess).subscribe(() => {
            this.loadDataStartBase(this, null);
        }), this.refreshingServiceStatesProcess);
    }

    private startDataPolling(): void {
        this.subDataPolling();
        this._dataPollingService.startEvent(this._dataPollingEvent);
    }

    private stopDataPolling(): void {
        this._dataPollingService.stopEvent(this._dataPollingEvent);
    }
}
