import { Injectable } from '@angular/core';
import { SyncedVideoSessionModel } from '@rift/models/restapi/SyncedVideoSession.Model';
import { ValidationSessionInfoModel } from '@rift/models/restapi/ValidationSessionInfo.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiValidationService } from '@rift/service/restapi/v1/RestApi.Validation.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';

@Injectable()
export class ValidationDataService extends RiftBaseService {

    private _getAllSessionsForAllVideosLoadingTracker = new ObservableTracker<Array<[SyncedVideoSessionModel, Array<ValidationSessionInfoModel>]>>();

    public constructor(
        private readonly _restApiValidationService: RestApiValidationService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
    }

    public getAllSessionsForAllVideos(friendlySerial: string, process?: ProcessMonitorServiceProcess): Observable<Array<[SyncedVideoSessionModel, Array<ValidationSessionInfoModel>]>> {
        return this._getAllSessionsForAllVideosLoadingTracker
            .getLoading(friendlySerial)
            .observable(this._restApiValidationService.getAllSessionsForAllVideos(friendlySerial, process));
    }
}
