import { NgZone, Directive } from '@angular/core';
import { Device } from '@rift/components/shared/viewport/devices/Device';
import { Grid } from '@rift/components/shared/viewport/grid/Grid';
import { FlattenFilter } from '@rift/components/shared/viewport/video/FlattenFilter';
import { HullFilter } from '@rift/components/shared/viewport/video/HullFilter';
import { ViewPortModeEnum } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { IVideoModel } from '@rift/models/websocket/Video.Model';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ImageUtility } from '@shared/utility/Image.Utility';
import { IntervalTracker } from '@shared/generic/IntervalTracker';
import { GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';


@Directive()
export class Video extends DisplayItem {
    public static MaxFPS = 10;
    public devices: Array<Device> = null;
    public hullScaleFactor: number = null;

    private _frameBitmap: createjs.Bitmap = null;
    private _hullFilter: HullFilter = null;
    private _hullShape: createjs.Shape = null;
    private _isFirstFrame: boolean = true;
    private _messageText: createjs.Text = new createjs.Text('', '30px Arial', '#ff0000');
    private _visible: boolean = null;
    private _lastFrameLoaded: boolean = true;
    private _fpsIntervalTracker: IntervalTracker = new IntervalTracker(1000 / Video.MaxFPS);

    public constructor(
        private readonly _zone: NgZone,
        private readonly _videoDevice: Device) {
        super(_zone);

        this._zoneBase.runOutsideAngular(() => {
            this.visible = true;
        });
    }

    public setCurrentFrame(frame: IVideoModel, manualAlpha?: number): void {
        this._zoneBase.runOutsideAngular(() => {
            if (this._lastFrameLoaded === true && this._fpsIntervalTracker.tick() === true) {
                if (this._videoDevice.deviceModel.autoHeightStateVideoOK === true) {
                    if (!isNullOrUndefined(this._frameBitmap) && this.visible === true && this.mode === ViewPortModeEnum.view) {
                        const image = new Image();
                        image.onload = () => {
                            image.onload = null;

                            if (this._isFirstFrame === true) {
                                this._videoDevice.createVideoSettings(image.width, image.height, this.devices);
                                if (!isNullOrUndefined(this.hullScaleFactor)) {
                                    this._videoDevice.videoSettings.hullScaleFactor = this.hullScaleFactor;
                                }
                                this.centerFrame();

                                if (!isNullOrUndefined(this._hullFilter)) {
                                    this._hullFilter.destroy();
                                }

                                this._hullFilter = new HullFilter(this._videoDevice);

                                const noVideoSettings = isNullOrUndefined(this._videoDevice.videoSettings);
                                const requiredWidth = noVideoSettings ? 0 : this._videoDevice.videoSettings.requiredWidth;
                                const requiredHeight = noVideoSettings ? 0 : this._videoDevice.videoSettings.requiredHeight;

                                if (!isNullOrUndefined(this._frameBitmap)) {
                                    this._frameBitmap.cache(
                                        0,
                                        0,
                                        requiredWidth,
                                        requiredHeight,
                                        GLOBAL_CACHE_SCALE,
                                        { useGL: 'stage' }
                                    );
                                }

                                // this.debugHull();

                                this._isFirstFrame = false;
                            }

                            this.updateFilters(image, manualAlpha);

                            this._lastFrameLoaded = true;
                        };
                        image.onerror = () => {
                            this._lastFrameLoaded = true;
                        };

                        this._lastFrameLoaded = false;
                        image.src = 'data:image/png;base64,' + frame.data;
                    }
                }
            }
        });
    }

    public bringToFront(): void {
    }

    public onDestroy(): void {
        this._zoneBase.runOutsideAngular(() => {
            super.onDestroy();

            this.container.removeAllChildren();

            this._hullFilter?.destroy();
            this._hullShape?.uncache();
            this._frameBitmap?.uncache();

            this._hullFilter = null;
            this._hullShape = null;
            this._frameBitmap = null;
        });
    }

    public update(): void {
        this.updateMessageText();
        this.requireStageUpdate.next();
    }

    public updateFilters(image: HTMLImageElement, manualAlpha?: number): void {
        if (!isNullOrUndefined(this._frameBitmap) && !isNullOrUndefined(this._frameBitmap.image)) {
            let flattenFilter = new FlattenFilter(image, this._videoDevice.videoSettings, manualAlpha);

            this._frameBitmap.filters = [
                flattenFilter,
                this._hullFilter,
            ];

            this._frameBitmap.updateCache();

            if (!isNullOrUndefined(flattenFilter)) {
                flattenFilter.destroy();
                flattenFilter = null;
            }

            if (!isNullOrUndefined(this.requireStageUpdate) && !this.requireStageUpdate.closed) {
                this.requireStageUpdate.next();
            }
        }
    }

    public updateMessageText(): void {
        if (this._videoDevice.deviceModel.autoHeightStateVideoOK === true) {
            this.container.removeChild(this._messageText);
            this.container.addChild(this._frameBitmap);
        } else {
            if (this._messageText.text !== this._videoDevice.deviceModel.autoHeightMessage) {
                this.container.addChild(this._messageText);
                this.container.removeChild(this._frameBitmap);

                this._messageText.text = 'No Video: ' + this._videoDevice.deviceModel.autoHeightMessage;

                const width = this._messageText.getMeasuredWidth();
                const height = this._messageText.getMeasuredHeight();

                this._messageText.regX = width / 2;
                this._messageText.regY = height / 2;
                this._messageText.x = (Grid.Width / 2) + this._videoDevice.deviceModel.centerPoint.x;
                this._messageText.y = (Grid.Height / 2) + this._videoDevice.deviceModel.centerPoint.y;

                this._messageText.cache(0, 0, width, height, GLOBAL_CACHE_SCALE);

                this.requireStageUpdate.next();
            }
        }
    }

    public get visible(): boolean {
        return this._zoneBase.runOutsideAngular(() => this._visible);
    }

    public set visible(value: boolean) {
        this._zoneBase.runOutsideAngular(() => {
            if (this._visible !== value) {
                this._visible = value;
                if (value === false) {
                    this.container.removeChild(this._frameBitmap);
                    this._frameBitmap = null;
                } else {
                    this._frameBitmap = new createjs.Bitmap(ImageUtility.BlankImagePath);
                    this._isFirstFrame = true;
                    this.container.addChild(this._frameBitmap);
                }
            }
        });
    }

    protected modeSet(): void {
        this._zoneBase.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.visible = false;
            } else {
                this.visible = true;
            }
        });
    }

    private centerFrame(): void {
        this._zoneBase.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._frameBitmap) && !isNullOrUndefined(this._videoDevice.videoSettings)) {
                this._frameBitmap.scaleX = this._videoDevice.videoSettings.requiredWidth / this._videoDevice.videoSettings.flattenedWidth;
                this._frameBitmap.scaleY = this._videoDevice.videoSettings.requiredHeight / this._videoDevice.videoSettings.flattenedHeight;

                this._frameBitmap.x = (Grid.Width / 2) + (-(this._videoDevice.videoSettings.requiredWidth / 2) + (this._videoDevice.videoSettings.coefficients.ox * 100 * (this._videoDevice.videoSettings.coefficients.pixelPitch * 100)) + this._videoDevice.deviceModel.x);
                this._frameBitmap.y = (Grid.Height / 2) + (-(this._videoDevice.videoSettings.requiredHeight / 2) + (this._videoDevice.videoSettings.coefficients.oy * 100 * (this._videoDevice.videoSettings.coefficients.pixelPitch * 100)) + (this._videoDevice.deviceModel.y * -1));
            }
        });
    }

    private debugHull(): void {
        if (isNullOrUndefined(this._hullShape)) {
            const vs = this._videoDevice.videoSettings;
            this._hullShape = new createjs.Shape();
            const g = this._hullShape.graphics;
            g.clear();
            g.beginFill('#ff0000');
            const length = vs.hullPoints.length;
            for (let i = 0; i < length; i++) {
                const point = vs.hullPoints[i];
                if (i === 0) {
                    g.moveTo(point.x, point.y);
                } else {
                    g.lineTo(point.x, point.y);
                }
            }
            const p = vs.hullPoints[0];
            g.lineTo(p.x, p.y);

            this._hullShape.x = ((Grid.Width / 2) + (-(this._videoDevice.videoSettings.coefficients.ox * 100 * (this._videoDevice.videoSettings.coefficients.pixelPitch * 100))));
            this._hullShape.y = ((Grid.Height / 2) + (-(this._videoDevice.videoSettings.coefficients.oy * 100 * (this._videoDevice.videoSettings.coefficients.pixelPitch * 100))));

            this._hullShape.cache(-800, -800, 1600, 1600, GLOBAL_CACHE_SCALE);

            this.container.addChild(this._hullShape);
        }
    }
}
