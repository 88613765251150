import { DeviceCapabilitiesEnum, DeviceCapabilitiesEnumHelpers } from '@shared/enum/DeviceCapabilities.Enum';
import { IResultModel } from '@rift/models/restapi/Result.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IDeviceModel, DeviceModel } from '@rift/models/restapi/Device.Model';

export interface IRestoreSummaryInfoModel extends IRestModel {
    devices: Array<IDeviceModel>;
    capabilities: Array<DeviceCapabilitiesEnum>;
}

export class RestoreSummaryInfoModel extends BaseModel implements IRestoreSummaryInfoModel, IResultModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public devices: Array<DeviceModel> = null;
    public capabilities: Array<DeviceCapabilitiesEnum> = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RestoreSummaryInfoModel');

        this.devices = RestModelUtility.loadFromArray(restModel.Devices, DeviceModel);
        this.capabilities = restModel.Capabilities.map(i => DeviceCapabilitiesEnumHelpers.fromRestApi(i));
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
