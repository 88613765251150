import { Component, HostBinding, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { HttpPostModel } from '@rift/models/restapi/HttpPost.Model';
import { HTTP_POST_ENTRY_MODEL_FORMAT_OPTIONS, HttpPostEntryModel, IHttpOptionFormat } from '@rift/models/restapi/HttpPostEntry.Model';
import { TestConnectionDataModel } from '@rift/models/restapi/TestConnectionData.Model';
import { HttpPostService } from '@rift/service/data/httppost/HttpPost.Service';
import { OkCancelDialogComponent, OkCancelDialogData } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { UniqueChangeTrackerArray } from '@shared/generic/UniqueChangeTrackerArray';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

@Component({
    selector: 'rift-settings-httppost',
    templateUrl: './Settings.HttpPost.Component.html',
    styleUrls: ['./Settings.HttpPost.Component.scss']
})
export class SettingsHttpPostComponent extends RiftBaseComponent implements OnDestroy, OnDeactivate, OnInit, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsHttpPostComponent';

    @HostBinding()
    public id: string = 'rift-settings-httppost';

    public minPosts: number = 3;
    public formGroup: FormGroup;
    public httpPost: HttpPostModel;
    public testConnectionProcess: ProcessMonitorServiceProcess[];
    public HTTP_POST_ENTRY_MODEL_FORMAT_OPTIONS = HTTP_POST_ENTRY_MODEL_FORMAT_OPTIONS;
    public filteredFormatOptions: { value: string; displayName: string }[] = [];
    public formValuesChangeProcess: ProcessMonitorServiceProcess;
    public formatCols: number = 5;
    public hostDevice: DeviceModel;

    public constructor(
        private readonly _mediaObserver: MediaObserver,
        private readonly _httpPostService: HttpPostService,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.addSubscription(this._mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
            if (!isNullOrUndefined(changes)){
                changes.forEach(change => {
                    switch (change.mqAlias) {
                        case 'xs':
                            this.formatCols = 2;
                            break;
                        case 'md':
                            this.formatCols = 4;
                            break;
                        default:
                            this.formatCols = 5;
                            break;
                    }
                });
            }
        }));

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsHttpPostComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsHttpPostComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsHttpPostComponent.className, this.saveAllChangesProcessText);
        this.testConnectionProcess = [];

        this.addSaveAllAction(this);

        this.formGroup = this._formBuilder.group({
            posts: this._formBuilder.array([]),
        });
        this.formGroupTracker.track(this.formGroup);

        this.initConnectionState();
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase && ((isNullOrUndefined(this.httpPost) || isNullOrUndefined(this.httpPost.entries) || this.httpPost.entries.length > 0) && this.httpPost.entries.every(e => (e.enabled === false) || (!isNullOrUndefined(e.format) && e.format.length > 0)));
    }

    public filterFormatOptions(): void {
        this.filteredFormatOptions = this.HTTP_POST_ENTRY_MODEL_FORMAT_OPTIONS;

        this.addSubscription(this.isDeviceCapable(DeviceCapabilitiesEnum.httpPostHistograms).subscribe(
            httpPostHistogramsCapable => {
                if (!httpPostHistogramsCapable) {
                    this.filteredFormatOptions = this.filteredFormatOptions.filter(val => val.value !== 'Histograms' && val.value !== 'LogPeriodHistograms');
                }
            })
        );

        this.addSubscription(this.isDeviceCapable(DeviceCapabilitiesEnum.httpPostLocalTime).subscribe(
            httpPostLocalTimeCapable => {
                if (!httpPostLocalTimeCapable) {
                    this.filteredFormatOptions = this.filteredFormatOptions.filter(val => val.value !== 'LocalTime');
                }
            })
        );

        this.addSubscription(this.isDeviceCapable(DeviceCapabilitiesEnum.httpPostAuthorizationToken).subscribe(
            httpPostAuthorizationTokenCapable => {
                if (!httpPostAuthorizationTokenCapable) {
                    this.filteredFormatOptions = this.filteredFormatOptions.filter(val => val.value !== 'Authorization');
                }
            })
        );
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.removeDisabledEntries(true);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._httpPostService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    public onFormatOptionChange(option: IHttpOptionFormat, post: HttpPostEntryModel): void {
        if (this.isNullOrUndefined(post.format)) {
            post.format = new UniqueChangeTrackerArray();
            post.format.commitChanges();
        }
        const curIndex = post.format.indexOf(option.value);
        const oriIndex = post.format.getItemOriginalIndex(option.value);
        if (curIndex === -1) {
            post.format.splice(oriIndex, 0, option.value);
        } else {
            post.format.splice(curIndex, 1);
        }
        this.updateSaveAllAction(this);
    }

    public onTestConnectionClick(post: HttpPostEntryModel, i: number): void {
        if (this.isNullOrUndefined(this.testConnectionProcess[i])) {
            this.testConnectionProcess[i] = this.processMonitorService.getProcess(SettingsHttpPostComponent.className, 'Testing outbound connection');
        }
        const splits = post.url.split(/^((\w+):)?(\/\/((\w+)?(:(\w+))?@)?([^\/\?:]+)(:(\d+))?)?(\/?([^\/\?#][^\?#]*)?)?(\?([^#]+))?(#(\w*))?/);

        const data = new TestConnectionDataModel();

        if (splits[2] === 'http' && typeof (splits[10]) === 'undefined') {
            data.address = splits[8];
            data.port = 80;
        } else if (splits[2] === 'http' && typeof (splits[10]) !== 'undefined') {
            data.address = splits[8];
            data.port = parseInt(splits[10], 10);
        } else if (splits[2] === 'https' && typeof (splits[10]) === 'undefined') {
            data.address = splits[8];
            data.port = 443;
        } else if (splits[2] === 'https' && typeof (splits[10]) !== 'undefined') {
            data.address = splits[8];
            data.port = parseInt(splits[10], 10);
        }

        this.addSubscription(this.observableHandlerBase(this.deviceService.testConnection(this.hostDevice.serialNumber, data), this.testConnectionProcess[i]).subscribe(
            result => {
                const dialogData = new OkCancelDialogData(`HTTP Post Test (${post.url})`, null, false);
                dialogData.messageHtml = result.details.map(detail => `<span>${detail}</span><br>`).join('');

                this._dialog.open(OkCancelDialogComponent, { data: dialogData });
            }
        ), this.testConnectionProcess[i]);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this.changeTracker.clear();

        const loadDataSub = zip(
            this.getHostDevice().pipe(
                map(device => {
                    if (!this.isNullOrUndefined(device)) {
                        this.hostDevice = device;
                    }
                    return true;
                })
            ),
            this._httpPostService.getHttpPost(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        const postsFormArray = this.formGroup.controls.posts as FormArray;
                        postsFormArray.clear();

                        this.removeDisabledEntries(false);

                        if (result.entries.length < this.minPosts) {
                            const add = this.minPosts - result.entries.length;
                            for (let i = 0; i < add; i++) {
                                const post = new HttpPostEntryModel();
                                post.enabled = false;
                                post.authenticationTokenSet = false;
                                post.authorizationTokenSet = false;
                                result.entries.push(post);
                            }
                            result.commitChanges();
                        }

                        const postsLength = result.entries.length;
                        for (let i = 0; i < postsLength; i++) {
                            this.addEntryFormGroup(result.entries[i], postsFormArray);
                        }

                        this.httpPost = result;
                        this.changeTracker.track(this.httpPost);

                        if (this.isReadOnly === true) {
                            this.setPostsFormArrayDisable();
                        }
                    }
                    return true;
                })
            ),
        ).pipe(
            tap(() => {
                this.filterFormatOptions();
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.httpPost.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._httpPostService.updateHttpPost(this.httpPost, process).pipe(
                            map(() => true)
                        );
                    } else {
                        return of(true);
                    }
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    this.removeDisabledEntries(false);
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(false);
                }
            })
        );
    }

    public changeAuthorizationToken(entry: HttpPostEntryModel){
        entry.authorizationTokenSet = false;
        this.setPostsFormArrayEnabled();
        this.updateSaveAllAction(this);
    }

    public changeAuthenticationToken(entry: HttpPostEntryModel){
        entry.authenticationTokenSet = false;
        this.setPostsFormArrayEnabled();
        this.updateSaveAllAction(this);
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();

    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.setPostsFormArrayDisable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.setPostsFormArrayEnabled();
    }

    private removeDisabledEntries(commitChanges: boolean): void {
        if (!this.isNullOrUndefined(this.httpPost)) {
            if (!this.isNullOrUndefined(this.httpPost.entries) && this.httpPost.entries.length > 0) {
                let length = this.httpPost.entries.length;
                for (let i = 0; i < length; i++) {
                    const entry = this.httpPost.entries[i];
                    if (entry.enabled === false) {
                        this.httpPost.entries.splice(i, 1);
                        i--;
                        length--;
                    }
                }
            }

            if (commitChanges === true) {
                this.httpPost.commitChanges();
            }
        }
    }

    private addEntryFormGroup(entry: HttpPostEntryModel, formArray?: FormArray): void {
        const fArray = this.isNullOrUndefined(formArray) ? this.formGroup.controls.posts as FormArray : formArray;
        if (!this.isNullOrUndefined(fArray)) {
            const form = this.createEntryFormGroup(entry);
            fArray.push(form);
        }
    }

    private createEntryFormGroup(entry: HttpPostEntryModel): FormGroup {
        const formGroup = this._formBuilder.group({
            enabled: ['', Validators.compose([Validators.required])],
            url: ['', Validators.compose([Validators.required, ValidationValidators.httpAndHttpsAddress])],
            timeout: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(120), Validators.pattern('[0-9]*')])],
            maxToPost: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(2000), Validators.pattern('[0-9]*')])],
            maxHistory: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(2147483647), Validators.pattern('[0-9]*')])],
            maxAttempts: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(10), Validators.pattern('[0-9]*')])],
            authenticationToken: ['', Validators.compose([])],
            authorizationToken: ['', Validators.compose([])],
        });
        this.setEntryFormGroupValues(entry, formGroup);
        this.setPostFormGroupEnabledDisable(entry, formGroup);
        this.addEntryFormGroupSubscriptions(entry, formGroup);

        return formGroup;
    }

    private addEntryFormGroupSubscriptions(entry: HttpPostEntryModel, formGroup: FormGroup): void {
        this.addSubscription(this.observableHandlerBase(formGroup.controls.enabled.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            this.setPostFormGroupEnabledDisable(entry, formGroup);
        }), this.formValuesChangeProcess);

        this.addSubscription(this.observableHandlerBase(formGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            this.updateModelValuesEntryFormGroup(formGroup, entry);
        }), this.formValuesChangeProcess);
    }

    private setPostsFormArrayEnabled(): void {
        const fArray = this.formGroup.controls.posts as FormArray;
        const length = fArray.length;
        for (let i = 0; i < length; i++) {
            this.setPostFormGroupEnabledDisable(this.httpPost.entries[i], fArray.at(i) as FormGroup);
        }
    }

    private setPostFormGroupEnabledDisable(entry: HttpPostEntryModel, formGroup: FormGroup): void {
        const options = { emitEvent: false };
        if (this.isReadOnly === false) {
            formGroup.controls.enabled.enable(options);
            if (formGroup.controls.enabled.value === true) {
                formGroup.controls.url.enable(options);
                formGroup.controls.timeout.enable(options);
                formGroup.controls.maxToPost.enable(options);
                formGroup.controls.maxHistory.enable(options);
                formGroup.controls.maxAttempts.enable(options);

                if (entry.authenticationTokenSet === true){
                    formGroup.controls.authenticationToken.disable(options);
                }
                else{
                    formGroup.controls.authenticationToken.enable(options);
                }

                if (entry.authorizationTokenSet === true){
                    formGroup.controls.authorizationToken.disable(options);
                }
                else{
                    formGroup.controls.authorizationToken.enable(options);
                }

            } else {
                formGroup.controls.url.disable(options);
                formGroup.controls.timeout.disable(options);
                formGroup.controls.maxToPost.disable(options);
                formGroup.controls.maxHistory.disable(options);
                formGroup.controls.maxAttempts.disable(options);
                formGroup.controls.authenticationToken.disable(options);
                formGroup.controls.authorizationToken.disable(options);
            }
        } else {
            this.formGroup.disable(options);
        }
    }

    private setPostsFormArrayDisable(): void {
        const fArray = this.formGroup.controls.posts as FormArray;
        const length = fArray.length;
        for (let i = 0; i < length; i++) {
            this.setPostFormGroupEnabledDisable(this.httpPost.entries[i], fArray.at(i) as FormGroup);
        }
    }

    private setEntryFormGroupValues(entry: HttpPostEntryModel, formGroup: FormGroup): void {
        if (!this.isNullOrUndefined(entry)) {
            formGroup.setValue({
                enabled: entry.enabled,
                url: entry.url,
                timeout: entry.timeout,
                maxToPost: entry.maxToPost,
                maxHistory: entry.maxHistory,
                maxAttempts: entry.maxAttempts,
                authenticationToken: entry.authenticationToken,
                authorizationToken: entry.authorizationToken,
            }, { emitEvent: false });
        }
    }

    private updateModelValuesEntryFormGroup(formGroup: FormGroup, entry: HttpPostEntryModel): void {
        if (!this.isNullOrUndefined(entry) && this.isReadOnly === false) {
            const formValues = formGroup.value;

            entry.enabled = formValues.enabled;
            if (entry.enabled === true) {
                entry.url = this.isNullOrUndefined(formValues.url) ? null : formValues.url;
                entry.timeout = this.isNullOrUndefined(formValues.timeout) ? null : formValues.timeout;
                entry.maxToPost = this.isNullOrUndefined(formValues.maxToPost) ? null : formValues.maxToPost;
                entry.maxHistory = this.isNullOrUndefined(formValues.maxHistory) ? null : formValues.maxHistory;
                entry.maxAttempts = this.isNullOrUndefined(formValues.maxAttempts) ? null : formValues.maxAttempts;
                entry.authenticationToken = this.isNullOrUndefined(formValues.authenticationToken) ? null : formValues.authenticationToken;
                entry.authorizationToken = this.isNullOrUndefined(formValues.authorizationToken) ? null : formValues.authorizationToken;
            } else {
                entry.url = null;
                entry.timeout = null;
                entry.maxToPost = null;
                entry.maxHistory = null;
                entry.maxAttempts = null;
                entry.authenticationToken = null;
                entry.authorizationToken = null;
                entry.format = new UniqueChangeTrackerArray();
                formGroup.setValue({ enabled: false, url: null, timeout: null, maxToPost: null, maxHistory: null, maxAttempts: null, authenticationToken: null, authorizationToken: null }, { emitEvent: false });
            }

            this.updateSaveAllAction(this);
        }
    }
}
