import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Injector, Input, OnDestroy, StaticProvider, ValueProvider } from '@angular/core';
import { isNullOrUndefined, isFunction } from '@shared/utility/General.Utility';
import { DeviceInfoTooltipComponent, DEVICE_INFO, DeviceInfo } from '@shared/component/deviceinfotooltip/DeviceInfoTooltip.Component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export type GetDeviceInfoType = () => Observable<DeviceInfo>;

@Directive({
    selector: '[shrDeviceInfoTooltip]'
})
export class DeviceInfoTooltipDirective implements OnDestroy {

    @Input()
    public set shrDeviceInfoTooltip(value: DeviceInfo | GetDeviceInfoType) {
        if (!isNullOrUndefined(value)) {
            if (isFunction(value)) {
                const newVal = value as GetDeviceInfoType;

                this._deviceInfoFunction = newVal;
            } else {
                const newVal = value as DeviceInfo;

                if (!isNullOrUndefined(this._deviceInfo) && !isNullOrUndefined(newVal) &&
                    (this._deviceInfo.deviceId !== newVal.deviceId ||
                    this._deviceInfo.deviceName !== newVal.deviceName ||
                    this._deviceInfo.firmwareVersion !== newVal.firmwareVersion ||
                    this._deviceInfo.friendlySerial !== newVal.friendlySerial ||
                    this._deviceInfo.iPAddress !== newVal.iPAddress ||
                    this._deviceInfo.nodeCount !== newVal.nodeCount ||
                    this._deviceInfo.siteId !== newVal.siteId ||
                    this._deviceInfo.siteName !== newVal.siteName)){
                    if (!isNullOrUndefined(this._overlayRef)) {
                        this._overlayRef.detach();
                        this._overlayRef = null;
                    }
                }

                this._deviceInfo = newVal;
            }
        }
    }

    private _overlayRef: OverlayRef;
    private _isTooltipOpen: boolean = false;
    private _isMouseOver: boolean = false;
    private _deviceInfoFunction: GetDeviceInfoType = null;
    private _deviceInfo: DeviceInfo = null;
    private _compRef: ComponentRef<DeviceInfoTooltipComponent>;

    public constructor(
        private readonly _injector: Injector,
        private readonly _overlay: Overlay,
        private readonly _elementRef: ElementRef,
    ) {
    }

    public ngOnDestroy(): void {
        if (!isNullOrUndefined(this._overlayRef)) {
            this._overlayRef.detach();
            this._overlayRef = null;
        }
    }

    @HostListener('mouseenter', ['$event'])
    public onMouseEnter(event: MouseEvent) {
        this._isMouseOver = true;
        this.getDeviceInfo().subscribe(deviceInfo => {
            if (this._isMouseOver === true && !isNullOrUndefined(deviceInfo)) {
                if (isNullOrUndefined(this._overlayRef)) {
                    this.createOverlay();
                }
                if (!isNullOrUndefined(this._compRef) && !isNullOrUndefined(this._compRef.instance)) {
                    this._compRef.instance.show(0);
                }
            }
        });
    }

    @HostListener('mouseleave', ['$event'])
    public onMouseLeave(event: MouseEvent) {
        this._isMouseOver = false;
        if (!isNullOrUndefined(this._deviceInfo)) {
            if (!isNullOrUndefined(this._compRef) && !isNullOrUndefined(this._compRef.instance)) {
                this._compRef.instance.hide(0);
            }
        }
    }

    private getDeviceInfo(): Observable<DeviceInfo> {
        if (isNullOrUndefined(this._deviceInfo) && !isNullOrUndefined(this._deviceInfoFunction)) {
            return this._deviceInfoFunction().pipe(
                map(deviceInfo => {
                    this._deviceInfo = deviceInfo;
                    return deviceInfo;
                })
            );
        } else {
            return of(this._deviceInfo);
        }
    }

    private createOverlay(): void {
        const connectedToElementRef = this._elementRef;
        const overlayPosition = this._overlay.position();
        const connectedPositionStrategy = overlayPosition
            .flexibleConnectedTo(connectedToElementRef)
            .withPositions([{ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 5 }]);

        this._overlayRef = this._overlay.create({
            width: connectedToElementRef.nativeElement.width,
            positionStrategy: connectedPositionStrategy,
            scrollStrategy: this._overlay.scrollStrategies.reposition(),
            hasBackdrop: false,
        });

        this._isTooltipOpen = true;
        const componentPortal = new ComponentPortal(DeviceInfoTooltipComponent, null, this.createInjector(this._deviceInfo));
        this._compRef = this._overlayRef.attach(componentPortal);
    }

    private createInjector(deviceInfo: DeviceInfo): Injector {
        const retVal = Injector.create({providers: [{provide: DEVICE_INFO, useValue: deviceInfo}], parent: this._injector});

        return retVal;
    }
}
