export enum RelayModeEnum {
    pulse = 0,
    holdHigh = 1,
    holdLow = 2,
}

export class RelayModeEnumHelpers {
    public static toDisplayString(value: RelayModeEnum): string {
        switch (value) {
            case RelayModeEnum.holdHigh:
                return 'Hold High';
            case RelayModeEnum.holdLow:
                return 'Hold Low';
            case RelayModeEnum.pulse:
                return 'Pulse';
        }
    }
}
