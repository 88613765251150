<div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Convert Device
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div mat-dialog-content fxLayout="column" style="background-color: #fafafa;">
    <mat-card class="spacing-bottom spacing-top">
        <mat-card-content>
            This dialog will allow you to convert the selected node device into a master.
            <br>
            <br>
            Note if you do not assign the new master outbound connection settings then
            <br>
            it will become inaccessible to Estate Manager.
        </mat-card-content>
    </mat-card>

    <form [formGroup]="clientConnectionsFormGroup">
        <div formArrayName="connections">
            <ng-template ngFor let-clientConnection [ngForOf]="clientConnections?.items" let-i="index">
                <div [formGroupName]="i" class="spacing-bottom" fxLayout.gt-sm="row" fxLayout.lt-md="column">

                    <mat-card fxFlex>
                        <mat-card-title>
                            Client {{ i + 1 }}
                            <div fxFlex></div>
                            <mat-checkbox #enabled formControlName="enabled">
                                Enable{{getFormControlErrorMessage(asFormGroup(asFormArray(clientConnectionsFormGroup.controls['connections']).at(i)).controls['enabled'])}}
                            </mat-checkbox>
                        </mat-card-title>
                        <mat-card-content *ngIf="enabled.checked">
                            <div fxLayout="row">
                                <mat-form-field fxFlex>
                                    <input type="text" #address matInput formControlName="address"
                                        placeholder="Address">
                                    <span matSuffix>{{ clientConnections[i]?.propertyHasChangesText('address') }}</span>
                                    <mat-error>
                                        {{getFormControlErrorMessage(asFormGroup(asFormArray(clientConnectionsFormGroup.controls['connections']).at(i)).controls['address'])}}
                                    </mat-error>
                                    <mat-hint align="end">{{address.value.length}} / 59</mat-hint>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row">
                                <mat-form-field class="spacing-right" fxFlex="50">
                                    <input type="number" step="1" #port matInput formControlName="port"
                                        placeholder="Port">
                                    <span matSuffix>{{ clientConnections[i]?.propertyHasChangesText('port') }}</span>
                                    <mat-error>
                                        {{getFormControlErrorMessage(asFormGroup(asFormArray(clientConnectionsFormGroup.controls['connections']).at(i)).controls['port'])}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field fxFlex="50">
                                    <input type="number" step="1" #reconnectionInterval matInput
                                        formControlName="reconnectionInterval" placeholder="Reconnection Interval">
                                    <span
                                        matSuffix>{{ clientConnections[i]?.propertyHasChangesText('reconnectionInterval') }}</span>
                                    <mat-error>
                                        {{getFormControlErrorMessage(asFormGroup(asFormArray(clientConnectionsFormGroup.controls['connections']).at(i)).controls['reconnectionInterval'])}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex></div>
                                <div class="error spacing-right" *ngIf="!isNullOrUndefined(testError)">
                                    {{ testError }}
                                </div>
                                <div>
                                    <shr-progress-button-spinner [raised]="true" [diameter]="30" text="Test Connection"
                                        mode="indeterminate"
                                        *ngIf="(isDeviceGen(UnitGenerationEnum.kestrel) | async) === true || (isDeviceGen(UnitGenerationEnum.falcon) | async) === true"
                                        [disabled]="isReadOnly || isValid === false" [active]="testConnectionProcess.isRunning"
                                        (shrClick)="onTestConnectionClick(clientConnection)" color="primary">
                                    </shr-progress-button-spinner>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </ng-template>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button 
        color="primary" 
        (click)="convert()"
        [disabled]="isValid === false || isReadOnly">Convert</button>
    <div fxFlex></div>
    <button mat-raised-button (click)="close()">Close</button>
</div>