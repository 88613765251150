import { BaseModel } from '@shared/base/Base.Model';
import { MQTTTopicIdentifierEnum, MQTTTopicIdentifierEnumHelpers } from '@shared/enum/MQTTTopicIdentifier.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

const Exclude_Properties = ['enabled', 'hasPeriod', 'filterEnabled', 'hasFilter'];

export interface IMQTTTopicConfigModel extends IRestModel {
    identifier: MQTTTopicIdentifierEnum;
    topicAddress: string;
    period: number;
    qOS: number;
    retain: boolean;
    filter: Array<string>;
}

export class MQTTTopicConfigModel extends BaseModel implements IMQTTTopicConfigModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public enabled: boolean = true;
    public hasPeriod: boolean = true;
    public identifier: MQTTTopicIdentifierEnum = null;
    public topicAddress: string = null;
    public period: number = null;
    public qOS: number = null;
    public retain: boolean = null;
    public hasFilter: boolean = false;
    public filter: Array<string> = null;

    public get filterEnabled(): boolean{
        if(isNullOrUndefined(this.filter) || this.filter.length === 0){
            return false;
        }

        return true;
    }

    public constructor() {
        super();

        this.registerProperty('enabled');
        this.registerProperty('identifier');
        this.registerProperty('topicAddress');
        this.registerProperty('period');
        this.registerProperty('qOS');
        this.registerProperty('filter');
        this.registerProperty('filterEnabled');
        this.registerProperty('retain');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-MQTTTopicConfigModel', Exclude_Properties);

        this.identifier = MQTTTopicIdentifierEnumHelpers.fromRestApi(restModel.Identifier);

        this.setPropertyOriginalValue('enabled', this.identifier);
        this.setPropertyOriginalValue('identifier', this.identifier);
        this.setPropertyOriginalValue('topicAddress', this.topicAddress);
        this.setPropertyOriginalValue('period', this.period);
        this.setPropertyOriginalValue('qOS', this.qOS);
        this.setPropertyOriginalValue('filter', this.filter.filter(r=>true)); // Make a copy of the array
        this.setPropertyOriginalValue('filterEnabled', this.filterEnabled);
        this.setPropertyOriginalValue('retain', this.retain);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, Exclude_Properties),
            Identifier: MQTTTopicIdentifierEnumHelpers.toRestApi(this.identifier),
            Filter: isNullOrUndefined(this.filter) ? [] : this.filter
        };
    }
}
