<div class="em-settings-manageusers-edit-title"
    mat-dialog-title>
    Edit User - {{user.realName}}
</div>
<div class="em-settings-manageusers-edit-content"
    mat-dialog-content
    fxLayout="column">
    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="User Permissions">
            <div class="tab-content"
                fxLayout="column">
                <p class="mat-subheading-1">Select the user permission level. This will affect what a user can do in the system.</p>
                <mat-radio-group [(ngModel)]="userRoleId"
                    fxLayout="column">
                    <ng-template ngFor
                        let-role
                        [ngForOf]="roles">
                        <mat-radio-button [value]="role.roleId">{{role.name}}</mat-radio-button>
                    </ng-template>
                </mat-radio-group>
            </div>
        </mat-tab>
        <mat-tab [disabled]="isRoleIdSystemManagerOrAbove(userRoleId) === true" label="Group Permissions">
            <p class="mat-subheading-1">Access to groups is granted in a top down fashion. As such if a user has access to a folder that has children,
                the user will implicitly be given access to those children.</p>
            <p class="mat-subheading-1">If a user is given access to a child group they will be able to see that group's parent structure signified by
                grey colouring, but not the devices within it.</p>
            <div class="orphaned-devices">
                <mat-checkbox [(ngModel)]="user.canViewOrphanedDevices">Can see orphaned devices</mat-checkbox>
            </div>
            <div fxLayout="column">
                <div class="tab-content"
                    fxLayout="row">
                    <div class="system-tree"
                        fxFlex
                        fxLayout="column">
                        <span>Available Groups</span>
                        <div>
                            <em-address-book-tree #systemtree
                                [showRoot]="true"
                                [badgeHidden]="true"
                                [root]="rootTreeGroup"
                                (itemClicked)="systemGroupSelected($event)"></em-address-book-tree>
                        </div>
                    </div>
                    <div class="user-tree"
                        fxFlex
                        fxLayout="column">
                        <span>User Groups</span>
                        <div>
                            <em-address-book-tree #usertree
                                [showRoot]="true"
                                [badgeHidden]="true"
                                [disabledEnabled]="true"
                                [visableEnabled]="true"
                                [grayedEnabled]="true"
                                [root]="rootTreeGroup"
                                (itemClicked)="userGroupSelected($event)"></em-address-book-tree>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="em-settings-manageusers-edit-actions"
    mat-dialog-actions>
    <shr-progress-button-spinner [raised]="true"
        [diameter]="30"
        text="Save"
        mode="indeterminate"
        [active]="saveProcess.isRunning === true"
        (shrClick)="save()"
        color="primary"></shr-progress-button-spinner>
    <div fxFlex></div>
    <button [disabled]="saveProcess.isRunning === true"
        (click)="cancel()"
        mat-raised-button
        color="warn">Cancel</button>
</div>