import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';

export class VideoEntryFilterModel extends BaseModel implements IRestModel {
    public readonly isIRestModel = true;

    public showLocal: boolean = null;
    public showOnDevice: boolean = null;
    public showSyncing: boolean = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-VideoEntryFilterModel');
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
