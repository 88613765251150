import { AfterViewInit, Component, HostBinding, Inject, Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { RegisterTypeViewModel } from '@rift/components/settings/counting/viewmodels/RegisterType.ViewModel';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

export class RegisterSummaryDialogData {
    public constructor(public readonly register: RegisterBaseModel, public readonly registerType: RegisterTypeViewModel) { }
}

export class RegisterSummaryDialogResult {
    public constructor() { }
}

@Component({
    selector: 'rift-settings.counting-registersummary-dialog',
    templateUrl: './Settings.Counting.RegisterSummary.Dialog.Component.html',
    styleUrls: ['./Settings.Counting.RegisterSummary.Dialog.Component.scss'],
})
export class SettingsCountingRegisterSummaryDialogComponent extends RiftBaseComponent implements AfterViewInit {
    public static className: string = 'SettingsCountingRegisterSummaryDialogComponent';

    @HostBinding()
    public id: string = 'rift-settings.counting-register-summary-dialog';

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: RegisterSummaryDialogData,
        private readonly _dialogRef: MatDialogRef<SettingsCountingRegisterSummaryDialogComponent>,
        private readonly _dialog: MatDialog,
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingRegisterSummaryDialogComponent.className, this.loadDataProcessText);

        this.initConnectionState();
    }

    public onCloseClick(): void {
        this._dialogRef.close(new RegisterSummaryDialogResult());
    }
}
