import { RegisterBaseModel, REGISTER_BASE_EXCLUDE_PROPERTIES } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterSequentialModel extends IRegisterBaseModel {
    lines: Array<number>;
}

export class RegisterSequentialModel extends RegisterBaseModel implements IRegisterSequentialModel, IRestModel {

    public lines: Array<number> = null;

    public constructor() {
        super();

        this.registerProperty('lines');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterSequentialModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('lines', this.lines);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
            Lines: this.lines,
        };
    }

    public setIndexes(): void {
        super.setIndexesBase(this.lines, [], []);
    }
}
