import { DeviceErrorClassEnum } from '@shared/enum/DeviceErrorClass.Enum';
import { DeviceErrorTypeEnum, DeviceErrorTypeEnumHelpers } from '@shared/enum/DeviceErrorType.Enum';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeUtility, TimeZone } from '@shared/utility/DateTime.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IDeviceErrorModel extends IRestModel {
    id: number;
    serialNumber: number;
    linksToDevices: Array<number>;
    friendlyLinksToDevices: Array<string>;
    friendlySerialNumber: string;
    timestamp: Date;
    type: number;
    message: string;
    isInFirst: boolean;
    isInOldest: boolean;
    class: number;
}

export class DeviceErrorModel extends BaseModel implements IDeviceErrorModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public id: number = null;
    public serialNumber: number = null;
    public linksToDevices: Array<number> = null;
    public friendlyLinksToDevices: Array<string> = null;
    public friendlySerialNumber: string = null;
    public timestamp: Date = null;
    public type: DeviceErrorTypeEnum = null;
    public message: string = null;
    public isInFirst: boolean = null;
    public isInOldest: boolean = null;
    public class: DeviceErrorClassEnum;

    private _shortDateTimeText: string;
    private _typeText: string;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-DeviceErrorModel');

        this.timestamp = new Date(this.timestamp);

        this.linksToDevices = restModel.LinksToDevices;
        this.friendlyLinksToDevices = restModel.FriendlyLinksToDevices;

        this._typeText = DeviceErrorTypeEnumHelpers.toStringHumanized(this.type);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }

    public setTextDates(timeZone: TimeZone): any {
        this._shortDateTimeText = DateTimeUtility.toShortDateTime(this.timestamp, timeZone);
    }

    public get shortDateTimeText(): string {
        return this._shortDateTimeText;
    }

    public get typeText(): string {
        return this._typeText;
    }
}
