<div class="shr-unsavedchanges-dialog">
  <div class="title" mat-dialog-title fxLayout="row" fxLayoutAlign="center center">
    {{data.title}}
  </div>
  <div class="content" mat-dialog-content fxLayout="row" fxLayoutAlign="center center">
    {{data.message}}
  </div>
  <div class="actions" fxLayout="row" fxLayoutAlign="space-around center" mat-dialog-actions>
    <button class="spacing-right" (click)="save()" mat-raised-button color="primary">Save Changes</button>
    <button class="spacing-right" (click)="discard()" mat-raised-button color="warn">Discard Changes</button>
    <button (click)="stay()" mat-raised-button>Cancel</button>
  </div>
</div>
