import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IMQTTCredentialsModel extends IRestModel {
    username: string;
    password: string;
}

export class MQTTCredentialsModel extends BaseModel implements IMQTTCredentialsModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public username: string = null;
    public password: string = null;

    public constructor() {
        super();

        this.registerProperty('username');
        this.registerProperty('password');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-MQTTCredentialsModel');

        this.setPropertyOriginalValue('username', this.username);
        this.setPropertyOriginalValue('password', this.password);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
