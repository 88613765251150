import { Injector, OnDestroy, Directive } from '@angular/core';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { ConnectionService } from '@rift/service/connection/Connection.Service';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { GlobalService } from '@rift/service/data/global/Global.Service';
import { DeviceValidationService } from '@rift/service/devicevalidation/DeviceValidation.Service';
import { UnitsOfMeasurementService } from '@rift/service/unitsofmeasurement/UnitsOfMeasurement.Service';
import { WebSocketService } from '@rift/service/websocket/WebSocket.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { UnitGenerationEnum, UnitGenerationEnumHelpers } from '@shared/enum/UnitGeneration.Enum';
import { UnitOfMeasurementEnum } from '@shared/enum/UnitOfMeasurement.Enum';
import { UnitTypeEnum } from '@shared/enum/UnitType.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { EventsService } from '@shared/service/events/Events.Service';
import { NavBarService } from '@shared/service/navbar/NavBar.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isBoolean, isFunction, isNullOrUndefined } from '@shared/utility/General.Utility';
import { UniqueIdUtility } from '@shared/utility/UniqueId.Utility';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, flatMap, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface IDeviceDataError {
    message?: string;
    error?: Error;
}

@Directive()
export abstract class RiftBaseComponent extends BaseComponent implements OnDestroy {
    public static className: string = 'RiftBaseComponent';

    public DeviceCapabilitiesEnum = DeviceCapabilitiesEnum;
    public UnitGenerationEnum = UnitGenerationEnum;
    public UnitOfMeasurementEnum = UnitOfMeasurementEnum;
    public UnitTypeEnum = UnitTypeEnum;
    public beforeGoOfflineProcess: ProcessMonitorServiceProcess;
    public connectedProcess: ProcessMonitorServiceProcess;
    public connectionService: ConnectionService;
    public deviceService: DeviceService;
    public deviceValidationService: DeviceValidationService;
    public disconnectedProcess: ProcessMonitorServiceProcess;
    public eventsService: EventsService;
    public globalService: GlobalService;
    public isReadOnly: boolean = false;
    public offlineProcess: ProcessMonitorServiceProcess;
    public onlineProcess: ProcessMonitorServiceProcess;
    public unitsOfMeasurementService: UnitsOfMeasurementService;
    public webSocketService: WebSocketService;
    public navBarService: NavBarService;

    public errorGettingDeviceData: Subject<IDeviceDataError> = new Subject<IDeviceDataError>();

    private _onBeforeRiftGoOfflineId: number = null;

    public constructor(
        private readonly _injectorRiftBase: Injector,
        private readonly _dialogRiftBase?: MatDialog,
        private readonly _navBarServiceRiftBase?: NavBarActionService) {
        super(_injectorRiftBase, _dialogRiftBase, _navBarServiceRiftBase);

        this.deviceValidationService = _injectorRiftBase.get(DeviceValidationService);
        this.connectionService = _injectorRiftBase.get(ConnectionService);
        this.webSocketService = _injectorRiftBase.get(WebSocketService);
        this.globalService = _injectorRiftBase.get(GlobalService);
        this.deviceService = _injectorRiftBase.get(DeviceService);
        this.unitsOfMeasurementService = _injectorRiftBase.get(UnitsOfMeasurementService);
        this.eventsService = _injectorRiftBase.get(EventsService);
        this.navBarService = _injectorRiftBase.get(NavBarService);

        this.connectionService.addPreOfflineHandler(this.preOffline.bind(this));
        this.connectionService.addPreOnlineHandler(this.preOnline.bind(this));

        this._onBeforeRiftGoOfflineId = UniqueIdUtility.getNext('onBeforeRiftGoOffline');

        this.connectedProcess = this.processMonitorService.getProcess(RiftBaseComponent.className, 'Handle device connect');
        this.disconnectedProcess = this.processMonitorService.getProcess(RiftBaseComponent.className, 'Handle device disconnect');
        this.onlineProcess = this.processMonitorService.getProcess(RiftBaseComponent.className, 'Handle device online');
        this.offlineProcess = this.processMonitorService.getProcess(RiftBaseComponent.className, 'Handle device offline');
        this.beforeGoOfflineProcess = this.processMonitorService.getProcess(RiftBaseComponent.className, 'Handle before device offline');

        this.addSubscription(this.observableHandlerBase(this.connectionService.connected, this.connectedProcess).subscribe(() => this.onConnected()), this.connectedProcess);
        this.addSubscription(this.observableHandlerBase(this.connectionService.disconnected, this.disconnectedProcess).subscribe(() => this.onDisconnected()), this.disconnectedProcess);
        this.addSubscription(this.observableHandlerBase(this.connectionService.online, this.onlineProcess).subscribe(() => this.online()), this.onlineProcess);
        this.addSubscription(this.observableHandlerBase(this.connectionService.offline, this.offlineProcess).subscribe(() => this.offline()), this.offlineProcess);
        this.addSubscription(this.observableHandlerBase(this.eventsService.onBeforeRiftGoOffline(this._onBeforeRiftGoOfflineId), this.beforeGoOfflineProcess).subscribe(() => this.onBeforeRiftGoOffline()));
    }

    /**
     * Gets the master device if connected via a network or the connected device if connected to single node.
     *
     * @param {ProcessMonitorServiceProcess} [process] The process.
     * @returns {Observable<DeviceModel>}
     * @memberof RiftBaseComponent
     */
    public getHostDevice(process?: ProcessMonitorServiceProcess): Observable<DeviceModel> {
        if (this.connectionService.isConnected) {
            return this.deviceService.getHostDevice(this.connectionService.connectedToFriendlySerial, process).pipe(
                catchError((error: Error, caught: Observable<DeviceModel>) => {
                    this.errorGettingDeviceData.next({ message: !isNullOrUndefined(error) && !isNullOrUndefined(error.message) ? error.message : 'Error getting device data', error });
                    return of(null);
                })
            );
        } else {
            return of(null);
        }
    }

    public isHostMaster(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (this.connectionService.isConnected) {
            return this.getHostDevice(process).pipe(
                map(device => isNullOrUndefined(device) ? false : device.master === true)
            );
        } else {
            return of(null);
        }
    }

    public getDeviceGenString(process?: ProcessMonitorServiceProcess): Observable<string> {
        if (this.connectionService.isConnected) {
            return this.getHostDevice(process).pipe(
                map(device => isNullOrUndefined(device) ? '' : UnitGenerationEnumHelpers.toString(device.unitGen))
            );
        } else {
            return of('');
        }
    }

    public isDeviceCapable(capability: DeviceCapabilitiesEnum, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (this.connectionService.isConnected) {
            return this.getHostDevice(process).pipe(
                map(device => isNullOrUndefined(device) ? false : device.isCapable(capability))
            );
        } else {
            return of(false);
        }
    }

    public isDeviceGen(gen: UnitGenerationEnum, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (this.connectionService.isConnected) {
            return this.getHostDevice(process).pipe(
                map(device => isNullOrUndefined(device) ? false : device.isGen(gen))
            );
        } else {
            return of(false);
        }
    }

    public isDeviceAnyGen(gen: Array<UnitGenerationEnum>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (this.connectionService.isConnected) {
            return this.getHostDevice(process).pipe(
                map(device => {
                    if (isNullOrUndefined(device)){
                        return false;
                    }

                    for (let i = 0; i < gen.length; i++){
                        if (device.isGen(gen[i]) === true){
                            return true;
                        }
                    }

                    return false;
                })
            );
        } else {
            return of(false);
        }
    }

    public isDeviceType(type: UnitTypeEnum, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (this.connectionService.isConnected) {
            return this.getHostDevice(process).pipe(
                map(device => isNullOrUndefined(device) ? false : device.isType(type))
            );
        } else {
            return of(false);
        }
    }

    public isIPEnabled(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (this.connectionService.isConnected) {
            return this.getHostDevice(process).pipe(
                map(device => isNullOrUndefined(device) ? false : device.isIPEnabled)
            );
        } else {
            return of(false);
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.eventsService.unregisterConfirmRiftGoOffline(this._onBeforeRiftGoOfflineId);

        this.connectionService.removePreOfflineHandler(this.preOffline);
        this.connectionService.removePreOnlineHandler(this.preOnline);
    }

    protected observableHandlerBase<T>(action: Observable<T>, process: ProcessMonitorServiceProcess): Observable<T> {
        super.registerProcess(process);

        return of(null).pipe(
            flatMap(
                () => {
                    if (!isNullOrUndefined(process)) {
                        process.started();
                    }
                    return action.pipe(
                        catchError(error => {
                            this.openErrorDialog('Error', null, error, process, this.connectionService.hostFriendlySerial);
                            return action;
                        }),
                        finalize(() => {
                            if (!isNullOrUndefined(process)) {
                                process.completed();
                            }
                        }),
                    );
                }
            )
        );
    }

    /**
     * Executed in the pre online pipe before online is called.
     * Override with.
     * protected preOnline(): Observable<boolean> {
     *   return super.preOnline().pipe(
     *       flatMap(() => Your code here)
     *   );
     * }
     *
     * @protected
     * @returns {Observable<boolean>}
     * @memberof RiftBaseComponent
     */
    protected preOffline(): Observable<boolean> {
        return of(true);
    }

    /**
     * Executed in the pre offline pipe before offline is called.
     * Override with.
     * protected preOnline(): Observable<boolean> {
     *   return super.preOnline().pipe(
     *       flatMap(() => Your code here)
     *   );
     * }
     *
     * @protected
     * @returns {Observable<boolean>}
     * @memberof RiftBaseComponent
     */
    protected preOnline(): Observable<boolean> {
        return of(true);
    }

    protected loadDataStartBase(iLoadDate: ILoadDate, pleaseWaitDialogRequest?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): void {
        super.loadDataStartBase(iLoadDate, pleaseWaitDialogRequest, process);
    }

    protected openPleaseWaitLoadingDialog(): MatDialogRef<PleaseWaitDialogComponent> {
        if (this.isNullOrUndefined(this.connectionService.connectDialogRef) && this.isNullOrUndefined(this.connectionService.goOffLineDialogRef) && this.isNullOrUndefined(this.connectionService.goOnLineDialogRef)) {
            return super.openPleaseWaitLoadingDialog();
        } else {
            return null;
        }
    }

    protected fakeConnectionEvents(): void {
        this.connectionService.fakeConnectionEvents();
    }

    protected initConnectionState(): void {
        if (this.connectionService.isConnected === true) {
            this.onConnected();
            if (this.connectionService.isOnline === true) {
                this.online();
            }
            if (this.connectionService.isOffline === true) {
                this.offline();
            }
        }
    }

    /**
     * The device is connected and is in offline mode.
     *
     * @protected
     * @memberof RiftBaseComponent
     */
    protected offline(): void {
        this.changeTracker.clearChanges();
        this.setReadOnly();
    }

    /**
     * Fired when the device is connected.
     * Note: device data will not be loaded here if you
     * need to get data do it in online offline.
     *
     * @protected
     * @memberof RiftBaseComponent
     */
    protected onConnected(): void {
    }

    /**
     * Fired when the device is disconnected.
     *
     * @protected
     * @memberof RiftBaseComponent
     */
    protected onDisconnected(): void {
    }

    /**
     * The device is connected and is in online mode.
     *
     * @protected
     * @memberof RiftBaseComponent
     */
    protected online(): void {
        this.addSubscription(this.observableHandlerBase(this.userCurrentService.isInstaller, this.onlineProcess).subscribe(isInstaller => {
            if (isInstaller) {
                this.setReadWrite();
            } else {
                this.setReadOnly();
            }
        }), this.onlineProcess);
    }

    protected setReadOnly(): void {
        this.isReadOnly = true;
    }

    protected setReadWrite(): void {
        this.isReadOnly = false;
    }

    protected updateSaveAllAction(iSaveAllChanges: ISaveAllChanges): void {
        if (this.connectionService.isOnline === true) {
            super.updateSaveAllAction(iSaveAllChanges);
        } else {
            this.saveAllAction.disabled = true;
        }
    }

    private onBeforeRiftGoOffline(): void {
        const thisAny = (this as any);
        if (isFunction(thisAny.deactivate)) {
            const subOrValue: boolean | Observable<boolean> = thisAny.deactivate();

            if (isBoolean(subOrValue)) {
                if (subOrValue === false) {
                    this.addSubscription(thisAny.showSaveChangesWarning().subscribe(
                        result => {
                            this.eventsService.confirmRiftGoOffline(result, this._onBeforeRiftGoOfflineId);
                        }
                    ));
                } else {
                    this.eventsService.confirmRiftGoOffline(subOrValue, this._onBeforeRiftGoOfflineId);
                }
            } else {
                this.addSubscription(subOrValue.subscribe(
                    (canDeactivate: boolean) => {
                        if (canDeactivate === false) {
                            this.addSubscription(thisAny.showSaveChangesWarning().subscribe(
                                result => {
                                    this.eventsService.confirmRiftGoOffline(result, this._onBeforeRiftGoOfflineId);
                                }
                            ));
                        } else {
                            this.eventsService.confirmRiftGoOffline(canDeactivate, this._onBeforeRiftGoOfflineId);
                        }
                    }
                ));
            }
        } else {
            this.eventsService.confirmRiftGoOffline(true, this._onBeforeRiftGoOfflineId);
        }
    }
}
