<div class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                Bluetooth Classic
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="classicFormGroup">
                    <div fxLayout="row">
                        <mat-checkbox #macHarvestingEnabled
                            formControlName="macHarvestingEnabled">MAC Harvesting</mat-checkbox>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <mat-select #serialTimeout
                                formControlName="serialTimeout"
                                placeholder="Serial Timeout">
                                <mat-option [value]="0">Connection Disabled</mat-option>
                                <mat-option [value]="180000">3 minutes</mat-option>
                                <mat-option [value]="300000">5 minutes</mat-option>
                                <mat-option [value]="600000">10 minutes</mat-option>
                            </mat-select>
                            <span matSuffix>{{ settings?.propertyHasChangesText('serialTimeout') }}</span>
                            <mat-error>{{getFormControlErrorMessage(classicFormGroup.controls['serialTimeout'])}}</mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                Bluetooth Classic
            </mat-card-title>
            <mat-card-content>
                <span>Configure MAC Address harvesting and serial timeout.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                Bluetooth Smart (BTLE)
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="smartFormGroup">
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <mat-select #mode
                                formControlName="mode"
                                placeholder="Serial Timeout">
                                <mat-option [value]="0">Disabled</mat-option>
                                <mat-option [value]="1">iBeacon</mat-option>
                            </mat-select>
                            <span matSuffix>{{ settings?.propertyHasChangesText('mode') }}</span>
                            <mat-error>{{getFormControlErrorMessage(smartFormGroup.controls['mode'])}}</mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                Bluetooth Smart (BTLE)
            </mat-card-title>
            <mat-card-content>
                <span>Enable iBeacon support.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div *ngIf="settings?.mode === BluetoothLowEnergyEnum.iBeacon"
    class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                iBeacon Settings
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="iBeconFormGroup">
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="string"
                                #uUID
                                matInput
                                formControlName="uUID"
                                placeholder="UUID">
                            <span matSuffix>{{ settings?.propertyHasChangesText('uUID') }}</span>
                            <mat-error>{{getFormControlErrorMessage(iBeconFormGroup.controls['uUID'])}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="number"
                                #majorId
                                matInput
                                formControlName="majorId"
                                placeholder="Major ID">
                            <span matSuffix>{{ settings?.propertyHasChangesText('majorId') }}</span>
                            <mat-error>{{getFormControlErrorMessage(iBeconFormGroup.controls['majorId'])}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input type="number"
                                #minorId
                                matInput
                                formControlName="minorId"
                                placeholder="minor ID">
                            <span matSuffix>{{ settings?.propertyHasChangesText('minorId') }}</span>
                            <mat-error>{{getFormControlErrorMessage(iBeconFormGroup.controls['minorId'])}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        Low <mat-slider class="spacing-left spacing-right"
                            fxFlex
                            min="0"
                            max="7"
                            step="1"
                            #transmitPower
                            formControlName="transmitPower"></mat-slider> High
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                iBeacon Settings
            </mat-card-title>
            <mat-card-content>
                <span>Configure iBeacon settings and transmit power. Higher transmit power provides greater signal distance.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>