export interface ITimeLineRowHeaderSettings {
    width: number;
    spacing: number;
    backgroundStartAlpha: number;
    backgroundEndAlpha: number;
    indent: number;
}

export interface ITimeLineRowBodySettings {
    backgroundStartAlpha: number;
    backgroundMinAlpha: number;
    backgroundEndAlpha: number;
}

export interface ITimeLineRowSelectSettings {
    width: number;
    spacing: number;
    backgroundStartAlpha: number;
    backgroundEndAlpha: number;
}

export interface ITimeLineRowSettings {
    height: number;
    spacing: number;
}

export interface ITimeLineRowsSettings {
    row: ITimeLineRowSettings;
    header: ITimeLineRowHeaderSettings;
    body: ITimeLineRowBodySettings;
    select: ITimeLineRowSelectSettings;
}

export const TimeLineRowsSettings: ITimeLineRowsSettings = {
    row: {
        height: 40,
        spacing: 5,
    },
    header: {
        width: 50,
        spacing: 5,
        backgroundStartAlpha: 0.5,
        backgroundEndAlpha: 1,
        indent: 5,
    },
    body: {
        backgroundStartAlpha: 0.5,
        backgroundMinAlpha: 1,
        backgroundEndAlpha: 0.5,
    },
    select: {
        width: 50,
        spacing: 5,
        backgroundStartAlpha: 0.5,
        backgroundEndAlpha: 1,
    },
};
