import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeUtility, TimeZone } from '@shared/utility/DateTime.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface ITimeSetupModel extends IRestModel {
    time: string;
    timezone: number;
    timezoneIrisysID: string;
    timezoneIrisysString: string;
    timezoneIrisysIndex: number;
    locale: string;
    autoDstAdjust: boolean;
    nTPServer: string;
}

export class TimeSetupModel extends BaseModel implements ITimeSetupModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public time: string = null;
    public timezone: number = null;
    public timezoneIrisysID: string = null;
    public timezoneIrisysString: string = null;
    public timezoneIrisysIndex: number = null;
    public locale: string = null;
    public autoDstAdjust: boolean = null;
    public nTPServer: string = null;

    private _timeZone: TimeZone;

    public constructor() {
        super();

        this.registerProperty('timezoneIrisysIndex');
        this.registerProperty('locale');
        this.registerProperty('autoDstAdjust');
        this.registerProperty('nTPServer');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-TimeModel');

        this.setPropertyOriginalValue('timezoneIrisysIndex', this.timezoneIrisysIndex);
        this.setPropertyOriginalValue('locale', this.locale);
        this.setPropertyOriginalValue('autoDstAdjust', this.autoDstAdjust);
        this.setPropertyOriginalValue('nTPServer', this.nTPServer);

        this.setFlags();
    }

    public setFlags(): void {
        this._timeZone = DateTimeUtility.timeZones.find(i => i.index === this.timezoneIrisysIndex);
    }

    public get timeZone(): TimeZone {
        return this._timeZone;
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
