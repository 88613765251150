/**
 * Web worker response message types.
 *
 * @export
 * @enum {number}
 */
export enum ResponseTypesEnum {
    /**
     * The request completed
     */
    complete = 0,
    /**
     * Update on request status
     */
    update = 1,
    /**
     * An error accrued
     */
    error = 3,
    /**
     * Block of data from request more to follow
     */
    block = 4,
    /**
     * The recording data is invalid
     */
    dataValidationFailed = 5
}
