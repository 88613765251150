import { Component, HostBinding, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsCountingBaseComponent } from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

@Component({
    selector: 'rift-settings-counting-max-dwell',
    templateUrl: './Settings.Counting.MaxDwell.Component.html',
    styleUrls: ['./Settings.Counting.MaxDwell.Component.scss']
})
export class SettingsCountingMaxDwellComponent extends SettingsCountingBaseComponent implements OnChanges {
    public static className: string = 'SettingsCountingMaxDwellComponent';

    private _maxDwellRegister: RegisterBaseModel & { minDwellDuration: number; maxDwellDuration: number; maxDwellEnabled: boolean } = null;

    @HostBinding()
    public id: string = 'rift-settings-counting-max-dwell';

    @Input()
    public get enabled(): boolean {
        return this._enabled;
    }
    public set enabled(value: boolean) {
        this._enabled = value;
    }

    @Input()
    public set register(value: RegisterBaseModel){
        this._maxDwellRegister = (value as RegisterBaseModel & { minDwellDuration: number; maxDwellDuration: number; maxDwellEnabled: boolean });
    }

    @Input()
    public get minDwellDuration(): number{
        return this._minDwellDuration;
    }
    public set minDwellDuration(value: number){
        this._minDwellDuration = value / 10;
        this.form.controls.maxDwellDuration.updateValueAndValidity();
    }

    public form: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    private _enabled: boolean = false;
    private _minDwellDuration: number = 0;

    public constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsCountingMaxDwellComponent.className, 'Form values change');

        this.form = this._formBuilder.group(
            {
                maxDwellDuration: ['', Validators.compose([
                    Validators.required,
                    (control: AbstractControl) => {
                        let minValue = 0;

                        if(isNullOrUndefined(this._maxDwellRegister) || isNullOrUndefined(this._maxDwellRegister.minDwellDuration) || isNullOrUndefined(this.enabled) || this.enabled === false){
                            minValue = 0;
                        }
                        else{
                            minValue = (this._maxDwellRegister.minDwellDuration / 10) + 1;
                        }

                        return Validators.min(minValue)(control);
                    },
                    Validators.max(3600)])]
            }
        );

        this.addSubscription(this.observableHandlerBase(this.form.controls.maxDwellDuration.valueChanges, this.formValuesChangeProcess).subscribe(value => {
            if (!this.isNullOrUndefined(this._maxDwellRegister) && !this.isNullOrUndefined(value) && value !== '') {
                this._maxDwellRegister.maxDwellDuration = parseInt(value, 10) * 10;
            }
        }), this.formValuesChangeProcess);

        this.formGroupTracker.track(this.form);
        this.setFormState();

        this.initConnectionState();
    }

    public getSettingsDescription(): string {
        return this.isNullOrUndefined(this._maxDwellRegister) ? '' :
            (this.isNullOrUndefined(this._maxDwellRegister.maxDwellDuration) && this.isNullOrUndefined(this._maxDwellRegister.maxDwellEnabled) || Number.isNaN(this._maxDwellRegister.maxDwellDuration)) || this._maxDwellRegister.maxDwellEnabled === false ?
                'Disabled' :
                `${this._maxDwellRegister.maxDwellDuration / 10} S`;
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this._maxDwellRegister) ? false : this._maxDwellRegister.propertyHasChanges('maxDwellDuration') || this._maxDwellRegister.propertyHasChanges('maxDwellEnabled');
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this._maxDwellRegister = changes.register.currentValue;
            this.enabled = this._maxDwellRegister.maxDwellEnabled;
            this.setFormValues();
        }
    }

    public onEnabledChange(event: MatCheckboxChange): void {
        this.enabled = event.checked;
        if (!this.isNullOrUndefined(this._maxDwellRegister)) {
            if (this.enabled) {
                this._maxDwellRegister.maxDwellDuration = 0;
                this._maxDwellRegister.maxDwellEnabled = true;
            } else {
                this._maxDwellRegister.maxDwellDuration = null;
                this._maxDwellRegister.maxDwellEnabled = false;
            }
        }

        this.setFormState();
    }

    protected offline(): void {
        super.offline();
        this.form.disable();
    }

    protected online(): void {
        super.online();
        this.form.enable();
    }

    private setFormState(): void {
        if (this.enabled) {
            this.form.enable({ emitEvent: false });
        } else {
            this.form.disable({ emitEvent: false });
        }
    }

    private setFormValues(): void {
        this.enabled = this._maxDwellRegister.maxDwellEnabled;
        this.form.controls.maxDwellDuration.setValue(this.isNullOrUndefined(this._maxDwellRegister.maxDwellDuration) ? 0 : this._maxDwellRegister.maxDwellDuration / 10, { emitEvent: false });
    }
}
