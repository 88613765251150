import { PolygonModel } from '@rift/models/restapi/Polygon.Model';
import { CollectionModel, ICollectionModel } from '@shared/models/restapi/Collection.Model';

export interface IPolygonCollectionModel extends ICollectionModel<PolygonModel> {

}

export class PolygonCollectionModel extends CollectionModel<PolygonModel> implements IPolygonCollectionModel {
    public constructor() {
        super(PolygonModel);
    }
}
