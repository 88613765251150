import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';


export class EndPointModel extends BaseModel implements IRestModel {
    public address: string = null;
    public contentType: number = null;
    public endPointId: number = null;
    public maxRetrys: number = null;
    public password: string = null;
    public protocol: number = null;
    public readonly isIRestModel = true;
    public retryDelay: number = null;
    public text: string = null;
    public userName: string = null;
    private _contentTypeText: string = null;
    private _protocolText: string = null;

    public constructor() {
        super();

        this.registerProperty('text');
        this.registerProperty('address');
        this.registerProperty('protocol');
        this.registerProperty('contentType');
        this.registerProperty('userName');
        this.registerProperty('password');
    }

    public get contentTypeText(): string {
        return this._contentTypeText;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-EndPointModel');

        this.setPropertyOriginalValue('text', this.text);
        this.setPropertyOriginalValue('address', this.address);
        this.setPropertyOriginalValue('protocol', this.protocol);
        this.setPropertyOriginalValue('contentType', this.contentType);
        this.setPropertyOriginalValue('userName', this.contentType);
        this.setPropertyOriginalValue('password', this.contentType);

        this.setTextValues();
    }

    public get protocolText(): string {
        return this._protocolText;
    }

    public setTextValues(): void {
        if (isNullOrUndefined(this._protocolText)) {
            switch (this.protocol) {
                case 1:
                    this._protocolText = 'Http';
                    break;
                case 2:
                    this._protocolText = 'Email';
                    break;
                case 3:
                    this._protocolText = 'Ftp';
                    break;
            }
        }

        if (isNullOrUndefined(this._contentTypeText)) {
            switch (this.contentType) {
                case 1:
                    this._contentTypeText = 'XML';
                    break;
                case 2:
                    this._contentTypeText = 'JSON';
                    break;
            }
        }
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
