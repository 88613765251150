import { MarkedTargetEnum } from '@shared/enum/MarkedTarget.Enum';
import { REGISTER_BASE_EXCLUDE_PROPERTIES, RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterSmartBoundaryModel extends IRegisterBaseModel {
    line: number;
    markedTarget: MarkedTargetEnum;
    minimumHeight: number;
    maximumHeight: number;
    entryTimeout: number;
    exitTimeout: number;
    maxGroupSize: number;
    maxTimeBetweenTargets: number;
    maxDistanceBetweenTargets: number;
    instanceIndex: number;
}

/**
 * Represents a
 *
 * @export
 * @class RegisterSmartBoundaryModel
 * @extends {RegisterBaseModel}
 * @implements {IRegisterSmartBoundaryModel}
 * @implements {IRestModel}
 */
export class RegisterSmartBoundaryModel extends RegisterBaseModel implements IRegisterSmartBoundaryModel, IRestModel {
    public line: number = null;
    public markedTarget: MarkedTargetEnum = null;
    public minimumHeight: number = null;
    public maximumHeight: number = null;
    public entryTimeout: number = null;
    public exitTimeout: number = null;
    public maxGroupSize: number = null;
    public maxTimeBetweenTargets: number = null;
    public maxDistanceBetweenTargets: number = null;
    public instanceIndex: number = null;

    public constructor() {
        super();

        this.registerProperty('line');
        this.registerProperty('markedTarget');
        this.registerProperty('minimumHeight');
        this.registerProperty('maximumHeight');
        this.registerProperty('entryTimeout');
        this.registerProperty('exitTimeout');
        this.registerProperty('maxGroupSize');
        this.registerProperty('maxTimeBetweenTargets');
        this.registerProperty('maxDistanceBetweenTargets');
        this.registerProperty('instanceIndex');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterSmartBoundaryModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('line', this.line);
        this.setPropertyOriginalValue('markedTarget', this.markedTarget);
        this.setPropertyOriginalValue('minimumHeight', this.minimumHeight);
        this.setPropertyOriginalValue('maximumHeight', this.maximumHeight);
        this.setPropertyOriginalValue('entryTimeout', this.entryTimeout);
        this.setPropertyOriginalValue('exitTimeout', this.exitTimeout);
        this.setPropertyOriginalValue('maxGroupSize', this.maxGroupSize);
        this.setPropertyOriginalValue('maxTimeBetweenTargets', this.maxTimeBetweenTargets);
        this.setPropertyOriginalValue('maxDistanceBetweenTargets', this.maxDistanceBetweenTargets);
        this.setPropertyOriginalValue('instanceIndex', this.instanceIndex);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([this.line], [], []);
    }

}
