import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, Inject, Injector, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { RestoreBackupSelectBaseComponent } from '@rift/components/restorebackup/base/RestoreBackup.SelectBase.Component';
import { ViewPortComponent } from '@rift/components/shared/viewport/ViewPort.Component';
import { ViewPortModeEnum } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { BackupFileModel } from '@rift/models/restapi/BackupFile.Model';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { GlobalModel } from '@rift/models/restapi/Global.Model';
import { AllDataService } from '@rift/service/data/alldata/AllData.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export class RestoreBackupDialogData {
    public constructor(public readonly selectComponent: Type<RestoreBackupSelectBaseComponent>) {

    }
}

export class RestoreBackupDialogResult {

}

@Component({
    selector: 'rift-restore-backup',
    templateUrl: './RestoreBackup.Component.html',
    styleUrls: ['./RestoreBackup.Component.scss'],
})
export class RestoreBackupComponent extends RiftBaseComponent implements ILoadDate, AfterViewInit, OnInit {
    public static className: string = 'RestoreBackupComponent';

    @HostBinding()
    public id: string = 'rift-restore-backup';

    public restoreBackupProcess: ProcessMonitorServiceProcess;
    public setLiveViewPortDataProcess: ProcessMonitorServiceProcess;
    public setBackupViewPortDataProcess: ProcessMonitorServiceProcess;
    public setFileSelectComponentProcess: ProcessMonitorServiceProcess;
    public getRestoreSummaryInfoProcess: ProcessMonitorServiceProcess;

    public message: string = null;
    public canRestore: boolean = false;
    public liveDevices: Array<DeviceModel> = null;
    public backupDevices: Array<DeviceModel> = null;
    public backupCapabilities: Array<DeviceCapabilitiesEnum> = null;
    public selectedBackupDevice: DeviceModel = null;
    public selectedLiveDevice: DeviceModel = null;
    public globalData: GlobalModel = null;
    public restoreIPConfig: boolean = false;
    public error: string = null;

    @ViewChild('backupFileSelectHost', { static: true, read: ViewContainerRef })
    public backupFileSelectHost: ViewContainerRef;

    @ViewChild('backupViewPort', { static: true })
    public backupViewPort: ViewPortComponent;

    @ViewChild('liveViewPort', { static: true })
    public liveViewPort: ViewPortComponent;

    private _viewInit: boolean = false;
    private _backupFile: BackupFileModel = null;
    private _restoreCompleteLoadingDialog: MatDialogRef<PleaseWaitDialogComponent> = null;

    public constructor(
        @Inject(MAT_DIALOG_DATA) private readonly _data: RestoreBackupDialogData,
        private readonly _dialogRef: MatDialogRef<RestoreBackupComponent>,
        private readonly _dialog: MatDialog,
        private readonly _allDataService: AllDataService,
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess(RestoreBackupComponent.className, this.loadDataProcessText);
        this.restoreBackupProcess = this.processMonitorService.getProcess(RestoreBackupComponent.className, 'Restoring backup.');
        this.setLiveViewPortDataProcess = this.processMonitorService.getProcess(RestoreBackupComponent.className, 'Set live view port data.');
        this.setBackupViewPortDataProcess = this.processMonitorService.getProcess(RestoreBackupComponent.className, 'Set backup view port data.');
        this.setFileSelectComponentProcess = this.processMonitorService.getProcess(RestoreBackupComponent.className, 'Set file select component.');
        this.getRestoreSummaryInfoProcess = this.processMonitorService.getProcess(RestoreBackupComponent.className, 'Get restore summary info.');

        this.initConnectionState();
    }

    public ngOnInit(): void{
        super.ngOnInit();

        this.setFileSelectComponent();
    }

    public ngAfterViewInit() {
        super.ngAfterViewInit();
        this._viewInit = true;

        this.backupViewPort.mode = ViewPortModeEnum.deviceGraphics;
        this.liveViewPort.mode = ViewPortModeEnum.deviceGraphics;

        this.setLiveViewPortData();
        this.setBackupViewPortData();
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.deviceService.getDevices(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.liveDevices = result.items.sort((a, b) => a.x - b.x);
                    }
                    return true;
                })
            ),
            this.globalService.getGlobal(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.globalData = result;
                    }
                    return true;
                })
            ),
        ).pipe(
            tap(() => {
                this.validateDevices();
                this.setLiveViewPortData();
                this.setBackupViewPortData();
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public onBackupDeviceClick(clickedDevice: DeviceModel): void {
        const length = this.backupViewPort.devices.length;
        for (let i = 0; i < length; i++) {
            const device = this.backupViewPort.devices[i];
            if (device.deviceModel.serialNumber === clickedDevice.serialNumber) {
                device.selected = true;
            } else {
                device.selected = false;
            }
        }

        this.canRestore = false;
        this.selectedBackupDevice = clickedDevice;

        this.validateDevices();
    }

    public onLiveDeviceClick(clickedDevice: DeviceModel): void {
        const length = this.liveViewPort.devices.length;
        for (let i = 0; i < length; i++) {
            const device = this.liveViewPort.devices[i];
            if (device.deviceModel.serialNumber === clickedDevice.serialNumber) {
                device.selected = true;
            } else {
                device.selected = false;
            }
        }

        this.canRestore = false;
        this.selectedLiveDevice = clickedDevice;

        this.validateDevices();
    }

    public onCloseClick(): void {
        this._dialogRef.close();
    }

    public onRestoreClick(): void {
        this.addSubscription(this.observableHandlerBase(this.globalService.restoreDeviceSettings(this._backupFile, this.selectedLiveDevice.serialNumber, this.selectedBackupDevice.serialNumber, this.restoreIPConfig, this.restoreBackupProcess), this.restoreBackupProcess).subscribe(
            () => {
                this.canRestore = false;
                this.message = 'Device restore completed.';

                this.openOkCancelDialog('Device restored from file', `The backup file device ${this.selectedBackupDevice.serialNumber} was restored to live device ${this.selectedLiveDevice.serialNumber}`, false).afterClosed().subscribe(
                    () => {
                        this._restoreCompleteLoadingDialog = this.openPleaseWaitLoadingDialog();

                        const liveLength = this.backupViewPort.devices.length;
                        for (let i = 0; i < liveLength; i++) {
                            this.backupViewPort.devices[i].selected = false;
                        }

                        const backupLength = this.liveViewPort.devices.length;
                        for (let i = 0; i < backupLength; i++) {
                            this.liveViewPort.devices[i].selected = false;
                        }

                        this.selectedLiveDevice = null;
                        this.selectedBackupDevice = null;

                        this.liveViewPort.clear();
                        this.backupViewPort.clear();

                        this._allDataService.clearAllCache();
                        this.fakeConnectionEvents();
                    }
                );
            },
            error => {
                this.message = error.error.Error;
                this.canRestore = false;
            }
        ), this.restoreBackupProcess);
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();

    }

    protected online(): void {
        super.online();

        if(!this.isNullOrUndefined(this._restoreCompleteLoadingDialog)){
            this._restoreCompleteLoadingDialog.close();
        }

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    private setLiveViewPortData(): void {
        if (this._viewInit === true && !this.isNullOrUndefined(this.liveDevices)) {
            this.liveViewPort.clear();
            this.liveViewPort.addDevices(this.liveDevices);
            this.liveViewPort.center();
            this.liveViewPort.mode = ViewPortModeEnum.deviceGraphics;

            this.liveViewPort.devices.forEach(d =>
                this.addSubscription(this.observableHandlerBase(d.deviceClick, this.setLiveViewPortDataProcess).subscribe(e => {
                    this.onLiveDeviceClick(d.deviceModel);
                    this._changeDetectorRef.detectChanges();
                }), this.setLiveViewPortDataProcess)
            );
        }
    }

    private setBackupViewPortData(): void {
        if (this._viewInit === true && !this.isNullOrUndefined(this.backupDevices)) {
            this.backupViewPort.clear();
            this.backupViewPort.addDevices(this.backupDevices);
            this.backupViewPort.center();

            this.backupViewPort.devices.forEach(d => {
                if (!this.isNullOrUndefined(this.selectedBackupDevice) && this.selectedBackupDevice.serialNumber === d.deviceModel.serialNumber) {
                    this.onBackupDeviceClick(d.deviceModel);
                }
                this.addSubscription(this.observableHandlerBase(d.deviceClick, this.setBackupViewPortDataProcess).subscribe(e => {
                    this.onBackupDeviceClick(d.deviceModel);
                    this._changeDetectorRef.detectChanges();
                }), this.setBackupViewPortDataProcess);
            }
            );
        }
    }

    private validateDevices(): void {
        if (!this.isNullOrUndefined(this.backupDevices) && this.backupDevices.length > 0) {
            if (!this.isNullOrUndefined(this.selectedBackupDevice) && !this.isNullOrUndefined(this.selectedLiveDevice)) {
                if (this.selectedBackupDevice.unitGen === this.selectedLiveDevice.unitGen) {
                    if (this.selectedBackupDevice.lensType === this.selectedLiveDevice.lensType) {
                        // Need to make sure that the device has at least all the capabilities of the
                        // device in the file
                        const isDeviceCapable = this.backupCapabilities.every(cap => this.globalData.capabilities.indexOf(cap) !== -1 || this.selectedLiveDevice.isCapable(cap));

                        if (isDeviceCapable) {
                            if (this.selectedBackupDevice.master === this.selectedLiveDevice.master) {
                                this.canRestore = true;
                                this.message = 'Please click Restore to finalize the device restore';
                            } else {
                                this.canRestore = false;
                                this.message = 'Cannot restore master to node.';
                            }
                        } else {
                            this.canRestore = false;
                            this.message = 'The selected devices have incompatible features.';
                        }
                    } else {
                        this.canRestore = false;
                        this.message = 'The selected devices have incompatible lenses.';
                    }
                } else {
                    this.canRestore = false;
                    this.message = 'The selected devices are not the same generation.';
                }
            } else {
                this.canRestore = false;
                this.message = 'Please select a device from file and live to restore.';
            }
        } else {
            this.canRestore = false;
            this.message = 'Please select a backup file to restore.';
        }
    }

    private setFileSelectComponent(): void {
        if (!this.isNullOrUndefined(this._data.selectComponent)) {
            const component = this.backupFileSelectHost.createComponent(this._data.selectComponent);
            if (!isNullOrUndefined(component.instance) && !isNullOrUndefined(component.instance.onDataLoaded)) {
                this.addSubscription(this.observableHandlerBase(component.instance.onDataLoaded, this.setFileSelectComponentProcess).subscribe(data => {
                    this.getRestoreSummaryInfo(data);
                }), this.setFileSelectComponentProcess);
            }
        }
    }

    private getRestoreSummaryInfo(data: Uint8Array): void {
        this._backupFile = new BackupFileModel();
        this._backupFile.data = this.Uint8ToBase64(data);

        this.addSubscription(this.globalService.getRestoreSummaryInfo(this._backupFile, this.getRestoreSummaryInfoProcess).subscribe(
            summary => {
                if (this.isNullOrUndefined(summary.error)) {
                    this.error = null;
                    this.backupDevices = this.deviceService.filterGen4(summary.devices).sort((a, b) => a.x - b.x);
                    this.backupCapabilities = summary.capabilities;

                    this.validateDevices();
                    this.setBackupViewPortData();
                } else {
                    this.error = summary.error;
                }
            },
            error => {
                this.message = error.error.Error;
                this.canRestore = false;
            }
        ), this.getRestoreSummaryInfoProcess);
    }

    private Uint8ToBase64(u8Arr: any): string {
        const CHUNK_SIZE = 0x8000;
        const length = u8Arr.length;

        let index: number = 0;
        let result: string = '';
        let slice: number;

        while (index < length) {
            slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
            result += String.fromCharCode.apply(null, slice);
            index += CHUNK_SIZE;
        }

        return btoa(result);
    }
}
