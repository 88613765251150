import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule, NgZone } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipDefaultOptions, MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { DashboardComponent } from '@em/components/dashboard/Dashboard.Component';
import { DashboardDefaultComponent } from '@em/components/dashboard/default/Dashboard.Default.Component';
import { DashboardDeviceHealthComponent } from '@em/components/dashboard/devicehealth/Dashboard.DeviceHealth.Component';
import { DashboardRecordingsComponent } from '@em/components/dashboard/recordings/Dashboard.Recordings.Component';
import { DeviceGroupComponent } from '@em/components/devicegroup/DeviceGroup.Component';
import { DocumentationComponent } from '@em/components/documentation/Documentation.Component';
import { DocumentationRestApiComponent } from '@em/components/documentation/restapi/Documentation.RestApi.Component';
import { EmComponent } from '@em/components/em/Em.Component';
import { HomeComponent } from '@em/components/home/Home.Component';
import {
    MyAccountChangePasswordComponent,
} from '@em/components/myaccount/changepassword/MyAccount.ChangePassword.Component';
import { MyAccountEditDetailsComponent } from '@em/components/myaccount/editdetails/MyAccount.EditDetails.Component';
import { MyAccountComponent } from '@em/components/myaccount/MyAccount.Component';
import { MyAccountRestApiKeyComponent } from '@em/components/myaccount/restapikey/MyAccount.RestApiKey.Component';
import { NavMenuExpandItemComponent } from '@em/components/navmenu/expanditem/NavMenu.ExpandItem.Component';
import {
    NavMenuFavouriteDevicesComponent,
} from '@em/components/navmenu/favouritedevices/NavMenu.FavouriteDevices.Component';
import { NavMenuComponent } from '@em/components/navmenu/NavMenu.Component';
import { RiftActivityComponent } from '@em/components/rift/activity/Rift.Activity.Component';
import { RiftHealthComponent } from '@em/components/rift/health/Rift.Health.Component';
import { RiftHomeComponent } from '@em/components/rift/home/Rift.Home.Component';
import { RiftLocationComponent } from '@em/components/rift/location/Rift.Location.Component';
import { RiftMetaDataComponent } from '@em/components/rift/metadata/Rift.MetaData.Component';
import {
    RestoreBackupFileDialogComponent,
} from '@em/components/rift/restorebackup/filedialog/RestoreBackup.FileDialog.Component';
import {
    RestoreBackupFileSelectComponent,
} from '@em/components/rift/restorebackup/fileselect/RestoreBackup.FileSelect.Component';
import { RiftConnectionRouteComponent } from '@em/components/rift/Rift.Routes.Connect.Component';
import {
    SettingsAddressBookEditComponent,
} from '@em/components/settings/addressbook/edit/Settings.AddressBook.Edit.Component';
import {
    SettingsAddressBookEditDialogComponent,
} from '@em/components/settings/addressbook/edit/Settings.AddressBook.Edit.Dialog.Component';
import {
    SettingsAddressBookFilterComponent,
} from '@em/components/settings/addressbook/filter/Settings.AddressBook.Filter.Component';
import { SettingsAddressBookComponent } from '@em/components/settings/addressbook/Settings.AddressBook.Component';
import {
    SettingsConnectionForwardingAddEditComponent,
} from '@em/components/settings/connectionforwarding/addedit/Settings.ConnectionForwarding.AddEdit.Component';
import {
    SettingsConnectionForwardingComponent,
} from '@em/components/settings/connectionforwarding/Settings.ConnectionForwarding.Component';
import {
    SettingsFirmwareVersionSummaryComponent,
} from '@em/components/settings/firmwareversionsummary/Settings.FirmwareVersionSummary.Component';
import { SettingsHomeComponent } from '@em/components/settings/home/Settings.Home.Component';
import { SettingsLicenceComponent } from '@em/components/settings/licence/Settings.Licence.Component';
import { SettingsManageUsersAddComponent } from '@em/components/settings/manageusers/add/Settings.ManageUsers.Add.Component';
import {
    SettingsManageUsersEditComponent,
} from '@em/components/settings/manageusers/edit/Settings.ManageUsers.Edit.Component';
import { SettingsManageUsersComponent } from '@em/components/settings/manageusers/Settings.ManageUsers.Component';
import { SettingsMetaDataKeyComponent } from '@em/components/settings/metadata/key/Settings.MetaData.Key.Component';
import { SettingsMetaDataComponent } from '@em/components/settings/metadata/Settings.MetaData.Component';
import {
    SettingsNotificationsAddEditComponent,
} from '@em/components/settings/notifications/addedit/Settings.Notifications.AddEdit.Component';
import {
    SettingsOutboundConnectionsManageLocationsComponent,
} from '@em/components/settings/notifications/managelocations/Settings.Notifications.ManageLocations.Component';
import { SettingsNotificationsComponent } from '@em/components/settings/notifications/Settings.Notifications.Component';
import {
    SettingsOptionsDataCullingComponent,
} from '@em/components/settings/options/dataculling/Settings.Options.DataCulling.Component';
import {
    SettingsOptionsNotificationsComponent,
} from '@em/components/settings/options/notifications/Settings.Options.Notifications.Component';
import { SettingsOptionsComponent } from '@em/components/settings/options/Settings.Options.Component';
import {
    SettingsOptionsTaskLauncherComponent,
} from '@em/components/settings/options/tasklauncher/Settings.Options.TaskLauncher.Component';
import {
    SettingsOptionsTempStoreComponent,
} from '@em/components/settings/options/tempstorage/Settings.Options.TempStore.Component';
import { SettingsOptionsVideoComponent } from '@em/components/settings/options/video/Settings.Options.Video.Component';
import {
    SettingsOutboundConnectionsAddConnectionComponent,
} from '@em/components/settings/outboundconnections/addconnection/Settings.OutboundConnections.AddConnection.Component';
import {
    SettingsOutboundConnectionsAddEditRemoveSelectGroupComponent,
} from '@em/components/settings/outboundconnections/addeditremoveselectgroup/Settings.OutboundConnections.AddEditRemoveSelectGroup.Component';
import {
    SettingsOutboundConnectionsEditConnectionComponent,
} from '@em/components/settings/outboundconnections/editconnection/Settings.OutboundConnections.EditConnection.Component';
import {
    SettingsOutboundConnectionsComponent,
} from '@em/components/settings/outboundconnections/Settings.OutboundConnections.Component';
import {
    SettingsSchedulesAddEditComponent,
} from '@em/components/settings/schedules/scheduleaddedit/Settings.Schedules.AddEdit.Component';
import {
    SettingsSchedulesListComponent,
} from '@em/components/settings/schedules/schedulelist/Settings.Schedules.List.Component';
import {
    SettingsSchedulesViewComponent,
} from '@em/components/settings/schedules/scheduleview/Settings.Schedules.View.Component';
import { SettingsSchedulesComponent } from '@em/components/settings/schedules/Settings.Schedules.Component';
import {
    SettingsCounterServiceSettingsComponent,
} from '@em/components/settings/servicestatusandconfiguration/counterservicesettings/Settings.CounterServiceSettings.Component';
import {
    SettingsServiceStatusAndConfigurationComponent,
} from '@em/components/settings/servicestatusandconfiguration/Settings.ServiceStatusAndConfiguration.Component';
import { SettingsComponent } from '@em/components/settings/Settings.Component';
import { SettingsTaskLauncherComponent } from '@em/components/settings/tasklauncher/Settings.TaskLauncher.Component';
import {
    SettingsTaskLauncherDeleteComponent,
} from '@em/components/settings/tasklauncher/tasklauncherdelete/Settings.TaskLauncher.Delete.Component';
import {
    SettingsTaskLauncherListComponent,
} from '@em/components/settings/tasklauncher/tasklauncherlist/Settings.TaskLauncher.List.Component';
import {
    SettingsTaskLauncherRunComponent,
} from '@em/components/settings/tasklauncher/tasklauncherrun/Settings.TaskLauncher.Run.Component';
import {
    SettingsTaskLauncherUploadComponent,
} from '@em/components/settings/tasklauncher/tasklauncherupload/Settings.TaskLauncher.Upload.Component';
import {
    SettingsTaskLauncherViewComponent,
} from '@em/components/settings/tasklauncher/tasklauncherview/Settings.TaskLauncher.View.Component';
import {
    AddressBookAutocompleteComponent,
} from '@em/components/shared/addressbookautocomplete/AddressBookAutocomplete.Component';
import {
    AddressBookAutocompleteTreeContainerComponent,
} from '@em/components/shared/addressbookautocomplete/treecontainer/AddressBookAutocomplete.TreeContainer.Component';
import { AddressBookSelectComponent } from '@em/components/shared/addressbookselect/AddressBookSelect.Component';
import { AddressBookTreeComponent } from '@em/components/shared/addressbooktree/AddressBookTree.Component';
import { DeviceAutocompleteComponent } from '@em/components/shared/deviceautocomplete/DeviceAutocomplete.Component';
import { DeviceListComponent } from '@em/components/shared/devicelist/DeviceList.Component';
import {
    DeviceListSelectColumnsComponent,
} from '@em/components/shared/devicelist/selectcolumns/DeviceList.SelectColumns.Component';
import { DeviceMapComponent } from '@em/components/shared/devicemap/DeviceMap.Component';
import { DeviceSerialLinkComponent } from '@em/components/shared/deviceseriallink/DeviceSerialLink.Component';
import { SearchComponent } from '@em/components/shared/search/Search.Component';
import { SearchResultsComponent } from '@em/components/shared/search/Search.Results.Component';
import { EmRoutes } from '@em/Em.Routes';
import { EmConfigurationService } from '@em/service/configuration/EmConfiguration.Service';
import { ActivityService } from '@em/service/data/activity/Activity.Service';
import { AllDataService } from '@em/service/data/alldata/AllData.Service';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { DeviceVideoDownloadService } from '@em/service/data/device/Device.Video.Download.Service';
import { DeviceHealthService } from '@em/service/data/devicehealth/DeviceHealth.Service';
import { GroupService } from '@em/service/data/group/Group.Service';
import { HomeService } from '@em/service/data/home/Home.Service';
import { LicenceService } from '@em/service/data/licence/Licence.Service';
import { MetaDataKeysService } from '@em/service/data/metadatakeys/MetaDataKeys.Service';
import { NotificationService } from '@em/service/data/notification/Notification.Service';
import { OutboundConnectionService } from '@em/service/data/outboundconnection/OutboundConnection.Service';
import { RecordingsService } from '@em/service/data/recordings/Recordings.Service';
import { RoleService } from '@em/service/data/role/Role.Service';
import { RoutingRuleService } from '@em/service/data/routingrule/RoutingRule.Service';
import { ScheduleService } from '@em/service/data/schedule/Schedule.Service';
import { SettingService } from '@em/service/data/setting/Setting.Service';
import { TaskService } from '@em/service/data/task/Task.Service';
import { UserDeviceListColumnService } from '@em/service/data/user/User.DeviceList.Column.Service';
import { UserService } from '@em/service/data/user/User.Service';
import { FavouriteDevicesService } from '@em/service/favouritedevices/FavouriteDevices.Service';
import { RestApiAccountService } from '@em/service/restapi/RestApi.Account.Service';
import { RestApiAddressBookService } from '@em/service/restapi/RestApi.AddressBook.Service';
import { RestApiDeviceService } from '@em/service/restapi/RestApi.Device.Service';
import { RestApiGlobalRecordingsService } from '@em/service/restapi/RestApi.GlobalRecordings.Service';
import { RestApiGroupService } from '@em/service/restapi/RestApi.Group.Service';
import { RestApiHomeService } from '@em/service/restapi/RestApi.Home.Service';
import { RestApiInfoService } from '@em/service/restapi/RestApi.Info.Service';
import { RestApiLicenceService } from '@em/service/restapi/RestApi.Licence.Service';
import { RestApiNotificationsService } from '@em/service/restapi/RestApi.Notifications.Service';
import { RestApiOutboundConnectionService } from '@em/service/restapi/RestApi.OutboundConnection.Service';
import { RestApiSchedulesService } from '@em/service/restapi/RestApi.Schedules.Service';
import { RestApiSettingsService } from '@em/service/restapi/RestApi.Settings.Service';
import { RestApiTaskService } from '@em/service/restapi/RestApi.Task.Service';
import { RestApiUserService } from '@em/service/restapi/RestApi.User.Service';
import { EmRiftService } from '@em/service/rift/Em.Rift.Service';
import { EmUserCurrentService } from '@em/service/user/User.Current.Service';
import {
    UserIsAdmin,
    UserIsAuthenticated,
    UserIsInstaller,
    UserIsSystemAdmin,
    UserIsSystemManager,
    UserIsUser,
} from '@em/service/user/UserIsLevel';
import { RiftSharedModule } from '@rift/Rift.Shared.Module';
import { SetRegisterNewConfigs } from '@rift/shared/Settings.RegisterConfig.New';
import { DeviceInfoTooltipComponent } from '@shared/component/deviceinfotooltip/DeviceInfoTooltip.Component';
import { ScrollFixedDirective } from '@shared/directive/scrollfixed/ScrollFixed.Directive';
import { JsonPrettyPipe } from '@shared/pipe/jsonpretty/JsonPretty.Pipe';
import { LargeNumberPipe } from '@shared/pipe/largenumber/LargeNumber.Pipe';
import { BreadCrumbService } from '@shared/service/breadcrumb/BreadCrumb.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { EventsService } from '@shared/service/events/Events.Service';
import { GlobalErrorHandler } from '@shared/service/globalerrorhandler/GlobalErrorHandler.Service';
import { LoggingService } from '@shared/service/logging/Logging.Service';
import { NavBarService } from '@shared/service/navbar/NavBar.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { PendingChangesGuard } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorService } from '@shared/service/processmonitor/ProcessMonitor.Service';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { VersionService } from '@shared/service/version/Version.Service';
import { LocalStorageService, SessionStorageService } from '@shared/service/webstorage/WebStorage.Service';
import { SharedModule } from '@shared/Shared.Module';
import { TranslationsUtility } from '@shared/utility/Translations.Utility';
import { FileUploadModule } from 'ng2-file-upload';
import { OrderModule } from 'ngx-order-pipe';
import {GoogleMapsModule} from '@angular/google-maps';
import { AuthInterceptor } from '@shared/interceptor/AuthInterceptor';
import { RiftDeleteLiveDataComponent } from './components/rift/deletelivedata/Rift.DeleteLiveData.Component';
import { EMLoginComponent } from './components/emlogin/EMLogin.Component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { DeviceGroupErrorWarningComponent } from './components/devicegrouperrorwarning/DeviceGroupErrorWarning.Component';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 1500,
    disableTooltipInteractivity: true
};

@NgModule({
    declarations: [
        AddressBookAutocompleteComponent,
        AddressBookAutocompleteTreeContainerComponent,
        AddressBookSelectComponent,
        AddressBookTreeComponent,
        DashboardComponent,
        DashboardDefaultComponent,
        DashboardDeviceHealthComponent,
        DashboardRecordingsComponent,
        DeviceAutocompleteComponent,
        DeviceGroupComponent,
        DeviceGroupErrorWarningComponent,
        DeviceListComponent,
        DeviceListSelectColumnsComponent,
        DeviceMapComponent,
        DeviceSerialLinkComponent,
        DocumentationComponent,
        DocumentationRestApiComponent,
        EmComponent,
        HomeComponent,
        JsonPrettyPipe,
        LargeNumberPipe,
        MyAccountChangePasswordComponent,
        MyAccountComponent,
        MyAccountEditDetailsComponent,
        MyAccountRestApiKeyComponent,
        NavMenuComponent,
        NavMenuExpandItemComponent,
        NavMenuFavouriteDevicesComponent,
        RestoreBackupFileDialogComponent,
        RestoreBackupFileSelectComponent,
        RiftActivityComponent,
        RiftHealthComponent,
        RiftHomeComponent,
        RiftLocationComponent,
        RiftMetaDataComponent,
        ScrollFixedDirective,
        SearchComponent,
        SearchResultsComponent,
        SettingsAddressBookComponent,
        SettingsAddressBookEditComponent,
        SettingsAddressBookEditDialogComponent,
        SettingsAddressBookFilterComponent,
        SettingsComponent,
        SettingsConnectionForwardingAddEditComponent,
        SettingsConnectionForwardingComponent,
        SettingsCounterServiceSettingsComponent,
        SettingsFirmwareVersionSummaryComponent,
        SettingsHomeComponent,
        SettingsLicenceComponent,
        SettingsManageUsersAddComponent,
        SettingsManageUsersComponent,
        SettingsManageUsersEditComponent,
        SettingsMetaDataComponent,
        SettingsMetaDataKeyComponent,
        SettingsNotificationsAddEditComponent,
        SettingsNotificationsComponent,
        SettingsOptionsComponent,
        SettingsOptionsDataCullingComponent,
        SettingsOptionsNotificationsComponent,
        SettingsOptionsTaskLauncherComponent,
        SettingsOptionsTempStoreComponent,
        SettingsOptionsVideoComponent,
        SettingsOutboundConnectionsAddConnectionComponent,
        SettingsOutboundConnectionsAddEditRemoveSelectGroupComponent,
        SettingsOutboundConnectionsComponent,
        SettingsOutboundConnectionsEditConnectionComponent,
        SettingsOutboundConnectionsManageLocationsComponent,
        SettingsSchedulesAddEditComponent,
        SettingsSchedulesComponent,
        SettingsSchedulesListComponent,
        SettingsSchedulesViewComponent,
        SettingsServiceStatusAndConfigurationComponent,
        SettingsTaskLauncherComponent,
        SettingsTaskLauncherDeleteComponent,
        SettingsTaskLauncherListComponent,
        SettingsTaskLauncherRunComponent,
        SettingsTaskLauncherUploadComponent,
        SettingsTaskLauncherViewComponent,
        RiftConnectionRouteComponent,
        RiftDeleteLiveDataComponent,
        EMLoginComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        FileUploadModule,
        FlexLayoutModule,
        FormsModule,
        HttpClientModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        GoogleMapsModule,
        OrderModule,
        OverlayModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        PlatformModule,
        ReactiveFormsModule,
        RiftSharedModule,
        RouterModule.forRoot(EmRoutes, { enableTracing: false, relativeLinkResolution: 'legacy' }),
        SharedModule,
    ],
    providers: [
        { provide: ConfigurationService, useClass: EmConfigurationService },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: LOCALE_ID, useValue: TranslationsUtility.getFirstBrowserLanguageLocal() },
        { provide: MAT_DATE_LOCALE, useValue: TranslationsUtility.getFirstBrowserLanguageLocal() },
        { provide: OWL_DATE_TIME_LOCALE, useValue: TranslationsUtility.getFirstBrowserLanguageLocal() },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
        { provide: UserCurrentService, useClass: EmUserCurrentService },
        ActivityService,
        AllDataService,
        BreadCrumbService,
        DataPollingService,
        DeviceHealthService,
        DeviceService,
        DeviceVideoDownloadService,
        EmRiftService,
        EventsService,
        FavouriteDevicesService,
        GroupService,
        HomeService,
        LicenceService,
        LocalStorageService,
        LoggingService,
        MetaDataKeysService,
        NavBarActionService,
        NavBarService,
        NotificationService,
        OutboundConnectionService,
        PendingChangesGuard,
        ProcessMonitorService,
        RecordingsService,
        RestApiAccountService,
        RestApiAddressBookService,
        RestApiDeviceService,
        RestApiGlobalRecordingsService,
        RestApiGroupService,
        RestApiHomeService,
        RestApiInfoService,
        RestApiLicenceService,
        RestApiNotificationsService,
        RestApiOutboundConnectionService,
        RestApiSchedulesService,
        RestApiSettingsService,
        RestApiTaskService,
        RestApiUserService,
        RoleService,
        RoutingRuleService,
        ScheduleService,
        SessionStorageService,
        SettingService,
        TaskService,
        UserDeviceListColumnService,
        UserIsAdmin,
        UserIsAuthenticated,
        UserIsInstaller,
        UserIsSystemAdmin,
        UserIsSystemManager,
        UserIsUser,
        UserService,
        VersionService,
        {
            provide: HTTP_INTERCEPTORS,
            useFactory(router: Router, zone: NgZone) {
                return new AuthInterceptor(router, zone);
            },
            multi: true,
            deps: [Router, NgZone]
        }
    ],
    bootstrap: [
        EmComponent
    ]
})
export class EmModule {
    public constructor() {
        SetRegisterNewConfigs();
    }
}
