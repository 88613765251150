import { Injectable } from '@angular/core';
import { PageModel } from '@em/models/restapi/Page.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { ScheduleModel } from '@em/models/restapi/Schedule.Model';
import { ScheduleDeviceResultCollectionModel } from '@em/models/restapi/ScheduleDeviceResultCollection.Model';
import { ScheduleOverviewModel } from '@em/models/restapi/ScheduleOverview.Model';
import { ScheduleResultCollectionModel } from '@em/models/restapi/ScheduleResultCollection.Model';
import { WorkflowCollectionModel } from '@em/models/restapi/WorkflowCollection.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiSchedulesService } from '@em/service/restapi/RestApi.Schedules.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { BreadCrumbService } from '@shared/service/breadcrumb/BreadCrumb.Service';
import { BreadCrumbLookupSet } from '@shared/service/breadcrumb/BreadCrumb.Service.LookupSet';
import { BreadCrumbIParameterText } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable, of } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';


@Injectable()
export class ScheduleService extends EmBaseService {

    private _addLoadingTracker = new ObservableTracker<number>();
    private _deleteLoadingTracker = new ObservableTracker<ResultModel>();
    private _getAllScheduleOverviewsCache: Array<ScheduleOverviewModel>;
    private _getAllScheduleOverviewsLoadingTracker = new ObservableTracker<Array<ScheduleOverviewModel>>();
    private _getScheduleLoadingTracker = new ObservableTracker<ScheduleModel>();
    private _getScheduleOverviewLoadingTracker = new ObservableTracker<ScheduleOverviewModel>();
    private _getScheduleOverviewResultsLoadingTracker = new ObservableTracker<ScheduleResultCollectionModel>();
    private _getSchedulesOverviewPageLoadingTracker = new ObservableTracker<PageModel<ScheduleOverviewModel>>();
    private _getWorkflowNamesCache: WorkflowCollectionModel;
    private _getWorkflowsLoadingTracker = new ObservableTracker<WorkflowCollectionModel>();
    private _setEnabledStateLoadingTracker = new ObservableTracker<ResultModel>();
    private _updateLoadingTracker = new ObservableTracker<boolean>();
    private _getScheduleDeviceResultsLoadingTracker = new ObservableTracker<ScheduleDeviceResultCollectionModel>();

    public constructor(
        private readonly _breadCrumbService: BreadCrumbService,
        private readonly _restApiSchedulesService: RestApiSchedulesService) {
        super();
    }

    public add(schedule: ScheduleModel, process?: ProcessMonitorServiceProcess): Observable<number> {
        return this._addLoadingTracker
            .getLoading(schedule)
            .observable(this._restApiSchedulesService.addSchedule(schedule, process).pipe(tap(() => this.clearCache())));
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getAllScheduleOverviewsCache = null;
        this._getWorkflowNamesCache = null;
    }

    public delete(scheduleId: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._deleteLoadingTracker
            .getLoading(scheduleId)
            .observable(this._restApiSchedulesService.deleteSchedule(scheduleId, process).pipe(tap(() => this.clearCache())));
    }

    public getAllScheduleOverviews(process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<Array<ScheduleOverviewModel>> {
        if (isNullOrUndefined(this._getAllScheduleOverviewsCache)) {
            return this._getAllScheduleOverviewsLoadingTracker
                .getLoading()
                .observable(this.getAllScheduleOverviewPages(1, [], process, retryOptions).pipe(map(result => {
                    this._getAllScheduleOverviewsCache = result;

                    const lookupset = new BreadCrumbLookupSet('schedules');

                    const schedulesLength = result.length;
                    for (let i = 0; i < schedulesLength; i++) {
                        const schedule = result[i];
                        lookupset.lookups.push(({ name: `schedules-schedule-${schedule.id}`, scheduleId: StringUtility.toString(schedule.id), text: schedule.description } as BreadCrumbIParameterText));
                    }

                    this._breadCrumbService.addUpdateLookupSet(lookupset);

                    return this._getAllScheduleOverviewsCache;
                })));
        } else {
            return of(this._getAllScheduleOverviewsCache);
        }
    }

    public getSchedule(scheduleId: number, process?: ProcessMonitorServiceProcess): Observable<ScheduleModel> {
        return this._getScheduleLoadingTracker
            .getLoading(scheduleId)
            .observable(this._restApiSchedulesService.getSchedule(scheduleId, process));
    }

    public getScheduleOverview(scheduleId: number, process?: ProcessMonitorServiceProcess): Observable<ScheduleOverviewModel> {
        if (isNullOrUndefined(this._getAllScheduleOverviewsCache)) {
            return this._getScheduleOverviewLoadingTracker
                .getLoading(scheduleId)
                .observable(this._restApiSchedulesService.getScheduleOverview(scheduleId, process));
        } else {
            const schedule = this._getAllScheduleOverviewsCache.find(i => i.id === scheduleId);
            if (isNullOrUndefined(this._getAllScheduleOverviewsCache)) {
                return of(schedule);
            } else {
                return this._getScheduleOverviewLoadingTracker
                    .getLoading(scheduleId)
                    .observable(this._restApiSchedulesService.getScheduleOverview(scheduleId, process));
            }
        }
    }

    public getScheduleDeviceResults(scheduleId: number, scheduleResultId: number, process?: ProcessMonitorServiceProcess): Observable<ScheduleDeviceResultCollectionModel> {
        return this._getScheduleDeviceResultsLoadingTracker
            .getLoading(scheduleId, scheduleResultId)
            .observable(this._restApiSchedulesService.getScheduleDeviceResults(scheduleId, scheduleResultId, process));
    }

    public getScheduleOverviewResults(scheduleId: number, paginationOptions: PaginationOptionsModel, process?: ProcessMonitorServiceProcess): Observable<ScheduleResultCollectionModel> {
        return this._getScheduleOverviewResultsLoadingTracker
            .getLoading(scheduleId, paginationOptions)
            .observable(this._restApiSchedulesService.getScheduleOverviewResults(scheduleId, paginationOptions, process));
    }

    public getSchedulesOverviewPage(paginationOptions: PaginationOptionsModel, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<PageModel<ScheduleOverviewModel>> {
        paginationOptions.resultsPerPage = 400;
        return this._getSchedulesOverviewPageLoadingTracker
            .getLoading(paginationOptions)
            .observable(this._restApiSchedulesService.getSchedulesPage(paginationOptions, process, retryOptions).pipe(map(result => {
                result.options = new PaginationOptionsModel(paginationOptions.page);
                result.options.resultsPerPage = 400;
                return result;
            })));
    }

    public getWorkflows(process?: ProcessMonitorServiceProcess): Observable<WorkflowCollectionModel> {
        if (isNullOrUndefined(this._getWorkflowNamesCache)) {
            return this._getWorkflowsLoadingTracker
                .getLoading()
                .observable(this._restApiSchedulesService.getWorkflowNames(process).pipe(map(result => {
                    this._getWorkflowNamesCache = result;
                    return this._getWorkflowNamesCache;
                })));
        } else {
            return of(this._getWorkflowNamesCache);
        }
    }

    public setEnabledState(scheduleId: number, enabled: boolean, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setEnabledStateLoadingTracker
            .getLoading()
            .observable(this._restApiSchedulesService.setScheduleEnabledState(scheduleId, enabled, process).pipe(tap(() => this.clearCache())));
    }

    public update(schedule: ScheduleModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._updateLoadingTracker
            .getLoading(schedule)
            .observable(this._restApiSchedulesService.updateSchedule(schedule, process).pipe(tap(() => this.clearCache())));
    }

    private getAllScheduleOverviewPages(page: number, lastitems: Array<ScheduleOverviewModel>, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<Array<ScheduleOverviewModel>> {
        const sub = this.getSchedulesOverviewPage(new PaginationOptionsModel(page), process, retryOptions);

        return sub.pipe(
            flatMap(result => {
                if (result.items.length === result.options.resultsPerPage) {
                    return this.getAllScheduleOverviewPages(page + 1, lastitems.concat(result.items), process, retryOptions);
                } else {
                    return of(lastitems.concat(result.items));
                }
            })
        );
    }
}
