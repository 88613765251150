export enum StreamTypeEnum {
    time = 1,
    video = 2,
    syncVideoSession = 3,
    validationDataRequestMessage = 4,
    target = 5,
    count = 6,
    task = 7,
    gvector = 8,
}

export class StreamTypeEnumHelpers {
    public static toRestApi(stream: StreamTypeEnum) {
        switch (stream) {
            case StreamTypeEnum.time:
                return 'time';
            case StreamTypeEnum.video:
                return 'video';
            case StreamTypeEnum.syncVideoSession:
                return 'syncvideosession';
            case StreamTypeEnum.validationDataRequestMessage:
                return 'validation_data_request_message';
            case StreamTypeEnum.target:
                return 'target';
            case StreamTypeEnum.count:
                return 'count';
            case StreamTypeEnum.task:
                return 'task';
            case StreamTypeEnum.gvector:
                return 'gvector';
        }
    }
}
