export enum UnitGenerationEnum {
    gen4 = 1,
    gazelle = 2,
    kestrel = 3,
    falcon = 4
}

export class UnitGenerationEnumHelpers {
    public static fromRestApi(value: string): UnitGenerationEnum {
        switch (value) {
            case 'Gen4':
                return UnitGenerationEnum.gen4;
            case 'Gazelle':
                return UnitGenerationEnum.gazelle;
            case 'Kestrel':
                return UnitGenerationEnum.kestrel;
            case 'Falcon':
                return UnitGenerationEnum.falcon;
        }
    }

    public static toString(value: UnitGenerationEnum): string {
        switch (value) {
            case UnitGenerationEnum.gen4:
                return 'gen4';
            case UnitGenerationEnum.gazelle:
                return 'gazelle';
            case UnitGenerationEnum.kestrel:
                return 'kestrel';
            case UnitGenerationEnum.falcon:
                return 'falcon';
        }
    }
}
