<mat-grid-list cols="2"
    gutterSize="7"
    rowHeight="85px">
    <mat-grid-tile *ngIf="(userIsAdmin | async) === true">
        <mat-card fxFlex
            routerLink="/settings/addressbook">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <mat-icon class="spacing-right">import_contacts</mat-icon>
                <span>Address Book</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span>Add, edit and remove groups from the Address Book</span>
            </mat-card-subtitle>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="(userIsSystemManager | async) === true">
        <mat-card fxFlex
            routerLink="/settings/metadata">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <mat-icon class="spacing-right">info</mat-icon>
                <span>Meta Data</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span>Advanced global options for Estate Manager</span>
            </mat-card-subtitle>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="(userIsAdmin | async) === true">
        <mat-card fxFlex
            routerLink="/settings/manageusers">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <mat-icon class="spacing-right">person</mat-icon>
                <span>Manage Users</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span>Create and manage users of the system</span>
            </mat-card-subtitle>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="(userIsSystemAdmin | async) === true">
        <mat-card fxFlex
            routerLink="/settings/connectionforwarding">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <shr-fa class="spacing-right"
                    name="external-link-alt">external-link-altk</shr-fa>
                <span>Connection Forwarding</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span>Configure forwarding destinations for counters</span>
            </mat-card-subtitle>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card fxFlex
            routerLink="/settings/schedules/list/active">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <mat-icon class="spacing-right">access_time</mat-icon>
                <span>Schedules</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span>Schedules can regularly collect data from devices</span>
            </mat-card-subtitle>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="(userIsSystemAdmin | async) === true">
        <mat-card fxFlex
            routerLink="/settings/licence">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <mat-icon class="spacing-right">lock</mat-icon>
                <span>Licence</span>
            </mat-card-title>
            <mat-card-subtitle class="licence-details">
                <span *ngIf="licence?.state === 0">Unlicensed</span>
                <span *ngIf="licence?.state === 1">Licensed for {{licence?.capacity}} devices until
                    {{licence?.expiryText}}</span>
                <span *ngIf="licence?.state === 2">Licensed for {{licence?.capacity}} devices until
                    {{licence?.expiryText}}</span>
                <span *ngIf="licence?.state === 3">Licence Server Unavailable</span>
            </mat-card-subtitle>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card fxFlex
            routerLink="/settings/firmwarevirsionsummary">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <shr-fa class="spacing-right"
                    name="sort-numeric-down">sort-numeric-down</shr-fa>
                <span>Firmware Version Summary</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span>View a summary of all device's firmware</span>
            </mat-card-subtitle>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="(userIsAdmin | async) === true">
        <mat-card fxFlex
            routerLink="/settings/tasklaucher/list/active">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <shr-fa class="spacing-right"
                    name="rocket">rocket</shr-fa>
                <span>Task Launcher</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span>View and run tasks such as firmware upgrader</span>
            </mat-card-subtitle>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="(userIsSystemAdmin | async) === true">
        <mat-card fxFlex
            routerLink="/settings/options/dataculling">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <mat-icon class="spacing-right">settings</mat-icon>
                <span>Options</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span>Advanced global options for Estate Manager</span>
            </mat-card-subtitle>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="(userIsSystemAdmin | async) === true">
        <mat-card fxFlex
            routerLink="/settings/notifications">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <mat-icon class="spacing-right">notifications</mat-icon>
                <span>Notifications</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span>Configure custom and system notifications for Estate Manager</span>
            </mat-card-subtitle>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card fxFlex
            routerLink="/settings/servicestatusandconfiguration">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <shr-fa class="spacing-right"
                    name="thermometer-empty">thermometer-empty</shr-fa>
                <span>Service Status And Configuration</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span>Reports service status and allows configuration</span>
            </mat-card-subtitle>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="(userIsAdmin | async) === true">
        <mat-card fxFlex
            routerLink="/settings/outboundconnections">
            <mat-card-title fxLayout="row"
                fxLayoutAlign="start center">
                <shr-fa class="spacing-right"
                    name="plug">plug</shr-fa>
                <span>Outbound connections</span>
            </mat-card-title>
            <mat-card-subtitle>
                <span>Outbound device connection configuration</span>
            </mat-card-subtitle>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>