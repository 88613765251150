<div class="spacing-bottom" fxLayout.gt-sm="row" fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right" ngClass.lt-md="spacing-bottom" fxFlex.gt-sm="60" fxFlex.lt-md="100"
        fxLayout="column">
        <form [formGroup]="formGroup" fxFlex>
            <div formArrayName="posts">
                <ng-template ngFor let-post [ngForOf]="httpPost?.entries" let-i="index">
                    <div [formGroupName]="i" class="spacing-bottom" fxLayout="row">
                        <mat-card fxFlex>
                            <mat-card-title>
                                Http Post {{ i + 1 }}
                                <div fxFlex></div>
                                <mat-checkbox #enabled formControlName="enabled">
                                    Enable{{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['posts']).at(i)).controls['enabled'])}}
                                </mat-checkbox>
                            </mat-card-title>
                            <mat-card-content>
                                <div fxLayout="row">
                                    <mat-form-field class="spacing-right" fxFlex>
                                        <input type="text" #url matInput formControlName="url" placeholder="URL">
                                        <span matSuffix>{{ httpPost?.entries[i]?.propertyHasChangesText('url') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['posts']).at(i)).controls['url'])}}
                                        </mat-error>
                                    </mat-form-field>
                                    <shr-progress-button-spinner [raised]="true" [diameter]="30" mode="indeterminate"
                                        [disabled]="isReadOnly === true || enabled.checked === false || asFormGroup(asFormArray(formGroup.controls['posts']).at(i)).controls['url'].valid === false"
                                        [active]="testConnectionProcess[i]?.isRunning"
                                        (shrClick)="onTestConnectionClick(post, i)" color="primary">
                                        <span fxHide fxShow.lt-sm="true">Test</span>
                                        <span fxHide fxShow.gt-xs="true">Test Connection</span>
                                    </shr-progress-button-spinner>
                                </div>
                                <div fxLayout.lt-sm="column" fxLayout.gt-xs="row">
                                    <mat-form-field fxFlex.gt-xs="50" class="spacing-right">
                                        <input type="number" step="1" #timeout matInput formControlName="timeout"
                                            placeholder="Timeout (seconds)">
                                        <span
                                            matSuffix>{{ httpPost?.entries[i]?.propertyHasChangesText('timeout') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['posts']).at(i)).controls['timeout'])}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex.gt-xs="50">
                                        <input type="number" step="1" #maxAttempts matInput
                                            formControlName="maxAttempts" placeholder="Max Attempts">
                                        <span
                                            matSuffix>{{ httpPost?.entries[i]?.propertyHasChangesText('maxAttempts') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['posts']).at(i)).controls['maxAttempts'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayout="row">
                                    <mat-form-field fxFlex.gt-xs="50" class="spacing-right">
                                        <input type="number" step="1" #maxHistory matInput formControlName="maxHistory"
                                            placeholder="Max History">
                                        <span
                                            matSuffix>{{ httpPost?.entries[i]?.propertyHasChangesText('maxHistory') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['posts']).at(i)).controls['maxHistory'])}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex.gt-xs="50">
                                        <input type="number" step="1" #maxToPost matInput formControlName="maxToPost"
                                            placeholder="Max Logs Post">
                                        <span
                                            matSuffix>{{ httpPost?.entries[i]?.propertyHasChangesText('maxToPost') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['posts']).at(i)).controls['maxToPost'])}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row">
                                    <mat-form-field *ngIf="httpPost?.entries[i].authenticationTokenSet === false" fxFlex="100" class="spacing-right">
                                        <input type="text" #authenticationToken matInput
                                            formControlName="authenticationToken" placeholder="Authentication Token">
                                        <span
                                            matSuffix>{{ httpPost?.entries[i]?.propertyHasChangesText('authenticationToken') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['posts']).at(i)).controls['authenticationToken'])}}
                                        </mat-error>
                                    </mat-form-field>
                                    <div *ngIf="httpPost?.entries[i].authenticationTokenSet === true">                                    
                                        <button mat-button mat-raised-button style="margin-bottom: 5px;" (click)="changeAuthenticationToken(httpPost?.entries[i])" [disabled]="isReadOnly">Authentication Token secured click to change</button>
                                    </div>
                                </div>
                                <div fxLayout="row"
                                    *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.httpPostAuthorizationToken) | async) === true">
                                    <mat-form-field *ngIf="httpPost?.entries[i].authorizationTokenSet === false" fxFlex="100" class="spacing-right">
                                        <input type="text" #authorizationToken matInput
                                            formControlName="authorizationToken" placeholder="Authorization Token">
                                        <span
                                            matSuffix>{{ httpPost?.entries[i]?.propertyHasChangesText('authorizationToken') }}</span>
                                        <mat-error>
                                            {{getFormControlErrorMessage(asFormGroup(asFormArray(formGroup.controls['posts']).at(i)).controls['authorizationToken'])}}
                                        </mat-error>
                                    </mat-form-field>
                                    <div *ngIf="httpPost?.entries[i].authorizationTokenSet === true">                                    
                                        <button mat-button mat-raised-button style="margin-bottom: 5px;" (click)="changeAuthorizationToken(httpPost?.entries[i])" [disabled]="isReadOnly">Authorization Token secured click to change</button>
                                    </div>
                                </div>
                                <div fxLayout="row"
                                    *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.httpPostCustomisation) | async) === true">
                                    Format ({{ (isNullOrUndefined(post.format) || post.format.length === 0 ) ? 'Please select at least one' : post.format.length }})
                                </div>
                                <mat-grid-list rowHeight="24px"
                                    *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.httpPostCustomisation) | async) === true"
                                    [cols]="formatCols">
                                    <mat-grid-tile *ngFor="let option of filteredFormatOptions">
                                        <div fxFlex fxLayoutAlign="start center">
                                            <mat-checkbox style="font-size: 11px;" [disabled]="isReadOnly === true || enabled.checked === false"
                                                [checked]="!isNullOrUndefined(post.format) && post.format.indexOf(option.value) !== -1"
                                                (change)="onFormatOptionChange(option, post)">{{option.displayName}}
                                            </mat-checkbox>
                                        </div>
                                    </mat-grid-tile>
                                </mat-grid-list>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </ng-template>
            </div>
        </form>
    </div>
    <div class="spacing-bottom" fxFlex.gt-sm="40" fxFlex.lt-md="100" fxLayout="column">
        <mat-card class="shr-card-sub help-card" fxFlex>
            <mat-card-title>
                Http Post
            </mat-card-title>
            <mat-card-subtitle>
                Send To Location
            </mat-card-subtitle>
            <mat-card-content>
                <span>Configure the post of count logs to a location.</span>
            </mat-card-content>
            <mat-card-subtitle>
                URL
            </mat-card-subtitle>
            <mat-card-content>
                <span>URL the device will post the count data to.</span>
            </mat-card-content>
            <mat-card-subtitle>
                Timeout
            </mat-card-subtitle>
            <mat-card-content>
                <span>Communications timeout when attempting to post data.</span>
            </mat-card-content>
            <mat-card-subtitle>
                Max Attempts
            </mat-card-subtitle>
            <mat-card-content>
                <span>The maximum number of times the device will try and post the data.</span>
            </mat-card-content>
            <mat-card-subtitle>
                Max History
            </mat-card-subtitle>
            <mat-card-content>
                <span>The maximum number of count log events the device will hold in its list of data to
                    post.</span>
            </mat-card-content>
            <mat-card-subtitle>
                Max Logs Post
            </mat-card-subtitle>
            <mat-card-content>
                <span>The maximum number of count log events the device will post on each attempt.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>
