import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IRegisterCustomActionModel extends IRestModel {
    action: string;
    trigger: string;
    parameters: string;
}

export class RegisterCustomActionModel extends BaseModel implements IRegisterCustomActionModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public action: string = null;
    public trigger: string = null;
    public parameters: string = null;

    public constructor() {
        super();

        this.registerProperty('action');
        this.registerProperty('trigger');
        this.registerProperty('parameters');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterCustomActionModel');

        this.setPropertyOriginalValue('action', this.action);
        this.setPropertyOriginalValue('trigger', this.trigger);
        this.setPropertyOriginalValue('parameters', this.parameters);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, null, true),
        };
    }
}
