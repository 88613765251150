import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';
import { DeviceTraceLocationModel } from '@em/models/restapi/DeviceTraceLocationModel';

export class DeviceTraceModel extends BaseModel implements IRestModel {

    public deviceSerial: string = null;
    public locations: Array<DeviceTraceLocationModel> = null;
    public readonly isIRestModel = true;
    public time: Date = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-DeviceTraceModel');

        this.locations = RestModelUtility.loadFromArray(restModel.Locations, DeviceTraceLocationModel);
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
