import { DiscoveredDeviceModel } from '@rift/models/restapi/DiscoveredDevice.Model';
import { CollectionModel, ICollectionModel } from '@shared/models/restapi/Collection.Model';

export interface IDiscoveredDeviceCollectionModel extends ICollectionModel<DiscoveredDeviceModel> {

}

export class DiscoveredDeviceCollectionModel extends CollectionModel<DiscoveredDeviceModel> implements IDiscoveredDeviceCollectionModel {
    public constructor() {
        super(DiscoveredDeviceModel);
    }
}
