import { NgZone, Directive } from '@angular/core';
import { TimeLineDurationBar } from '@rift/components/shared/timeline/duration/TimeLine.Duration.Bar';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { Settings, MAX_CANVAS_FPS, GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { IDimensions } from '@shared/generic/IDimensions';
import { ColorUtility } from '@shared/utility/Color.Utility';
import { DateTimeUtility, TimeZone } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';
import { IPlayLocation } from '@rift/service/validation/IPlayLocation';
import { IntervalTracker } from '@shared/generic/IntervalTracker';

@Directive()
export class TimeLineDurationPoint extends DisplayItem {
    public onBeforeDrag: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onClick: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onDrag: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onDragEnd: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onPressDown: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onPressUp: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();

    private _bottomLabel: createjs.Text;
    private _bottomLabelFont: string = '12px Arial';
    private _bottomLabelHeight: number = 12;
    private _bottomLabelMeetLeft: boolean = true;
    private _bottomLabelMeetRight: boolean = true;
    private _color: string = '#ff0000';
    private _colorDragging: string = '#ffff00';
    private _colorMouseOver: string = '#0000ff';
    private _dragEnabled: boolean = true;
    private _grabBox: createjs.Shape = new createjs.Shape();
    private _head: createjs.Shape = new createjs.Shape();
    private _headDimensions: IDimensions = { width: 15, height: 15 };
    private _height: number = 0;
    private _isDragging: boolean = false;
    private _line: createjs.Shape = new createjs.Shape();
    private _lineDimensions: IDimensions = { width: 15, height: 15 };
    private _prePlayLocation: IPlayLocation;
    private _width: number = 20;
    private _showDurationAsTime: boolean = false;
    private _showDurationAsDeviceTimeZone: boolean = false;
    private _timeZone: TimeZone;
    private _setBottomLabelTextIntervalTracker: IntervalTracker = new IntervalTracker(1000 / MAX_CANVAS_FPS);

    public constructor(
        protected readonly _durationViewContainer: createjs.Container,
        protected readonly _durationBar: TimeLineDurationBar,
        protected readonly _playService: ValidationPlayService,
        protected readonly _zone: NgZone,
        protected readonly _drawHeadCallBack?: (graphics: createjs.Graphics, point: TimeLineDurationPoint) => IDimensions,
        protected readonly _drawLineCallBack?: (graphics: createjs.Graphics, point: TimeLineDurationPoint) => IDimensions) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            this._bottomLabel = new createjs.Text('', this._bottomLabelFont);

            this.container.addChild(this._grabBox);
            this.container.addChild(this._head);
            this.container.addChild(this._line);
            this.container.addChild(this._bottomLabel);

            this._grabBox.cursor = 'pointer';

            this.addEventHandlers();
        });
    }

    public get showDurationAsDeviceTimeZone(): boolean {
        return this._showDurationAsDeviceTimeZone;
    }
    public set showDurationAsDeviceTimeZone(value: boolean) {
        this._showDurationAsDeviceTimeZone = value;
        this.update();
        this.setBottomLabelText();
    }

    public get timeZone(): TimeZone {
        return this._timeZone;
    }
    public set timeZone(value: TimeZone) {
        this._timeZone = value;
    }

    public get showDurationAsTime(): boolean {
        return this._showDurationAsTime;
    }
    public set showDurationAsTime(value: boolean) {
        this._showDurationAsTime = value;
        this.update();
        this.setBottomLabelText();
    }

    public get bottomLabelMeetLeft(): boolean {
        return this._bottomLabelMeetLeft;
    }

    public set bottomLabelMeetLeft(value: boolean) {
        this._zone.runOutsideAngular(() => {
            if (this._bottomLabelMeetLeft !== value) {
                this._bottomLabelMeetLeft = value;
            }
        });
    }

    public get bottomLabelMeetRight(): boolean {
        return this._bottomLabelMeetRight;
    }

    public set bottomLabelMeetRight(value: boolean) {
        this._zone.runOutsideAngular(() => {
            if (this._bottomLabelMeetRight !== value) {
                this._bottomLabelMeetRight = value;
            }
        });
    }

    public get bottomLabelText(): string {
        return this._bottomLabel.text;
    }

    public set bottomLabelText(value: string) {
        this._zone.runOutsideAngular(() => {
            if (this._setBottomLabelTextIntervalTracker.tick() === true) {
                if (this._bottomLabel.text !== value) {
                    this._bottomLabel.text = value;
                    this.updateBottomLabel();
                    this.requireStageUpdate.next();
                }
            }
        });
    }

    public get bottomLabelVisible(): boolean {
        return this._bottomLabel.visible;
    }

    public set bottomLabelVisible(value: boolean) {
        this._zone.runOutsideAngular(() => {
            if (this._bottomLabel.visible !== value) {
                this._bottomLabel.visible = value;
                this.requireStageUpdate.next();
            }
        });
    }

    public bringToFront(displayItem?: IDisplayItem): void {

    }

    public get color(): string {
        return this._color;
    }

    public set color(value: string) {
        this._zone.runOutsideAngular(() => {
            if (this._color !== value) {
                this._color = value;
            }
        });
    }

    public get colorDragging(): string {
        return this._colorDragging;
    }

    public set colorDragging(value: string) {
        this._zone.runOutsideAngular(() => {
            if (this._colorDragging !== value) {
                this._colorDragging = value;
            }
        });
    }

    public get colorMouseOver(): string {
        return this._colorMouseOver;
    }

    public set colorMouseOver(value: string) {
        this._zone.runOutsideAngular(() => {
            if (this._colorMouseOver !== value) {
                this._colorMouseOver = value;
            }
        });
    }

    public get dragEnabled(): boolean {
        return this._dragEnabled;
    }

    public set dragEnabled(value: boolean) {
        this._zone.runOutsideAngular(() => {
            this._dragEnabled = value;
        });
    }

    public get height(): number {
        return this._height;
    }

    public set height(value: number) {
        this._zone.runOutsideAngular(() => {
            if (this._height !== value) {
                this._height = value;
            }
        });
    }

    public get isDragging(): boolean {
        return this._isDragging;
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();
            this._grabBox.uncache();
            this._head.uncache();
            this._line.uncache();
        });
    }

    public get playLocation(): IPlayLocation {
        return this._playService.pixelToPlayLocation(this.container.x, this._durationBar.fullSegmentWidth);
    }

    public set playLocation(value: IPlayLocation) {
        this._zone.runOutsideAngular(() => {
            this.container.x = this._playService.playLocationToPixel(value, this._durationBar.fullSegmentWidth);
            this._prePlayLocation = value;
            this.setBottomLabelText();
        });
    }

    public get prePlayLocation(): IPlayLocation {
        return this._prePlayLocation;
    }

    public resize(width: number, height: number): void {
        this._zone.runOutsideAngular(() => {
            this.container.y = this._durationBar.labelHeight;
            this.height = height;
            this.playLocation = this.playLocation;
            this.update();
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            this.drawGrabBox();
            this.drawHead();
            this.drawLine();
            this.updateBottomLabel();
            this.container.regX = this.width / 2;
            this.requireStageUpdate.next();
        });
    }

    public get width(): number {
        return this._width;
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this._grabBox.on('click', this.click.bind(this));
            this._grabBox.on('mouseover', this.mouseOver.bind(this));
            this._grabBox.on('mouseout', this.mouseOut.bind(this));
            this._grabBox.on('mousedown', this.mouseDown.bind(this));
            this._grabBox.on('pressmove', this.pressMove.bind(this));
            this._grabBox.on('pressup', this.pressUp.bind(this));
        });
    }

    private click(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.onClick.next(new DisplayItemMouseEvent(this, event));
        });
    }

    private drawGrabBox(): void {
        this._zone.runOutsideAngular(() => {
            const graphics = this._grabBox.graphics;

            graphics.clear();
            graphics.beginFill(ColorUtility.hexToRGBA(Settings.grabBox.fillColor, Settings.grabBox.lineAlpha));
            graphics.rect(0, 0, this.width, this.height);
            graphics.endFill();

            this._grabBox.cache(0, 0, this.width, this.height, GLOBAL_CACHE_SCALE);
            this._grabBox.regX = this.width / 2;
            this._grabBox.x = this.width / 2;
        });
    }

    private drawHead(): void {
        this._zone.runOutsideAngular(() => {
            const graphics = this._head.graphics;
            const color = this.isDragging ? this.colorDragging : this.isMouseOver ? this.colorMouseOver : this.color;
            this._headDimensions = { width: 16, height: 15 };

            if (!isNullOrUndefined(this._drawHeadCallBack)) {
                this._headDimensions = this._drawHeadCallBack(graphics, this);
            } else {
                graphics.clear();
                graphics.setStrokeStyle(1);
                graphics.beginStroke(color);
                graphics.beginFill(color);
                graphics.moveTo(0, 0);
                graphics.lineTo(this._headDimensions.width, 0);
                graphics.lineTo(this._headDimensions.width / 2, this._headDimensions.height);
                graphics.endFill();
                graphics.endStroke();
            }

            this._head.regX = this._headDimensions.width / 2;
            this._head.x = this.width / 2;
            this._head.cache(0, 0, this._headDimensions.width, this._headDimensions.height, GLOBAL_CACHE_SCALE);
        });
    }

    private drawLine(): void {
        this._zone.runOutsideAngular(() => {
            const graphics = this._line.graphics;
            const color = this.isDragging ? this.colorDragging : this.isMouseOver ? this.colorMouseOver : this.color;
            this._lineDimensions = { width: 2, height: this.height - this._headDimensions.height - (this.bottomLabelVisible ? this._bottomLabelHeight : 0) };

            if (!isNullOrUndefined(this._drawLineCallBack)) {
                this._lineDimensions = this._drawLineCallBack(graphics, this);
            } else {
                graphics.clear();
                graphics.beginFill(color);
                graphics.rect(0, 0, this._lineDimensions.width, this._lineDimensions.height);
                graphics.endFill();
            }

            this._line.regX = this._lineDimensions.width / 2;
            this._line.x = this.width / 2;
            this._line.y = this._headDimensions.height;
            this._line.cache(0, 0, this._lineDimensions.width, this._lineDimensions.height, GLOBAL_CACHE_SCALE);
        });
    }

    private mouseDown(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.isMouseDown = true;
            this.update();
            this.onPressDown.next(new DisplayItemMouseEvent(this, event));
        });
    }

    private mouseOut(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.isMouseOver = false;
            this.update();
        });
    }

    private mouseOver(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.isMouseOver = true;
            this.update();
        });
    }

    private pressMove(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (this.isMouseDown === true && this.dragEnabled === true) {
                this._isDragging = true;

                const moveTo = this._durationViewContainer.globalToLocal(event.stageX, null);

                const beforeDragEvent = new DisplayItemMouseEvent(this, event);
                beforeDragEvent.moveTo = moveTo;
                this.onBeforeDrag.next(beforeDragEvent);

                if (!beforeDragEvent.isDefaultPrevented) {
                    this.container.x = moveTo.x;
                    this.setBottomLabelText();
                    this.requireStageUpdate.next();

                    const dragEvent = new DisplayItemMouseEvent(this, event);
                    dragEvent.moveTo = moveTo;
                    this.onDrag.next(dragEvent);
                }
            }
        });
    }

    private pressUp(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.isMouseDown = false;
            if (this._isDragging === true) {
                this.onDragEnd.next(new DisplayItemMouseEvent(this, event));
            }
            this._isDragging = false;
            this.onPressUp.next(new DisplayItemMouseEvent(this, event));
        });
    }

    private removeEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
        });
    }

    private setBottomLabelText(): void {
        this._zone.runOutsideAngular(() => {
            if (this.bottomLabelVisible === true) {
                if (!isNullOrUndefined(this._playService.startTime) && this.showDurationAsTime === true) {
                    if (this.showDurationAsDeviceTimeZone === true) {
                        this.bottomLabelText = DateTimeUtility.toLongTime(DateTimeUtility.add(this._playService.startTime, this.playLocation.offset, 'milliseconds'), this.timeZone);
                    } else {
                        this.bottomLabelText = DateTimeUtility.toLongTime(DateTimeUtility.add(this._playService.startTime, this.playLocation.offset, 'milliseconds'));
                    }
                } else {
                    if (!Number.isNaN(this.playLocation.offset)){
                        this.bottomLabelText = DateTimeUtility.millisecondsToDuration(this.playLocation.offset);
                    }
                    else{
                        this.bottomLabelText = '';
                    }
                }
                this.updateBottomLabel();
            }
        });
    }

    private updateBottomLabel(): void {
        this._zone.runOutsideAngular(() => {
            const width = this._bottomLabel.getMeasuredWidth();
            const halfWidth = width / 2;

            this._bottomLabel.regX = width / 2;
            this._bottomLabel.x = this.width / 2;
            this._bottomLabel.y = this.height - this._bottomLabelHeight;
            this._bottomLabel.cache(0, 0, width, this._bottomLabelHeight, GLOBAL_CACHE_SCALE);

            if (this._bottomLabelMeetLeft === true && this.container.x - halfWidth < 0) {
                this._bottomLabel.x = width - (halfWidth + this.container.x);
            }

            if (this._bottomLabelMeetRight === true && this.container.x + halfWidth > this._durationBar.width) {
                this._bottomLabel.x = (this.width / 2) + (this._durationBar.width - (this.container.x + halfWidth));
            }
        });
    }
}
