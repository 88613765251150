import { Component, ElementRef, HostBinding, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import {
    AddScheduleData,
    AddScheduleResult,
    RecordingsAddScheduleComponent,
} from '@rift/components/recordings/addschedule/Recordings.AddSchedule.Component';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { VideoScheduleModel } from '@rift/models/restapi/VideoSchedule.Model';
import { VideoStorageCapacityModel } from '@rift/models/restapi/VideoStorageCapacity.Model';
import { ConnectionService } from '@rift/service/connection/Connection.Service';
import { RecordingService } from '@rift/service/data/recording/Recording.Service';
import { RecordingControlService } from '@rift/service/data/recording/RecordingControl.Service1';
import { ScheduleViewModel, SessionTypes, VideoViewModel } from '@rift/service/data/recording/Video.ViewModel1';
import { OkCancelDialogResult } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { AutoHeightDetectionStateEnum } from '@shared/enum/AutoHeightDetectionState.Enum';
import { ScheduleTypeEnum } from '@shared/enum/ScheduleType.Enum';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { ILoadDate } from '@shared/interface/ILoadData';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { UrlUtility } from '@shared/utility/Url.Utility';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ISessionType {
    type: 'schedule' | 'onDevice' | 'local';
    title: string;
    displayedColumns: string[];
    dataSource: MatTableDataSource<VideoViewModel>;
}

@Component({
    selector: 'rift-recordings',
    templateUrl: './Recordings.Component.html',
    styleUrls: ['./Recordings.Component.scss']
})
export class RecordingsComponent extends RiftBaseComponent implements IFillHeight, OnInit, OnDestroy, ILoadDate {
    public static className: string = 'RecordingsComponent';

    public readonly DateTimeUtility = DateTimeUtility;
    public readonly ScheduleTypeEnum = ScheduleTypeEnum;

    public addScheduleProcess: ProcessMonitorServiceProcess;
    public cancelScheduleProcess: ProcessMonitorServiceProcess;
    public deleteLocalProcess: ProcessMonitorServiceProcess;
    public deleteOnDeviceProcess: ProcessMonitorServiceProcess;
    public downloadOnDeviceProcess: ProcessMonitorServiceProcess;
    public downloadCancelOnDeviceProcess: ProcessMonitorServiceProcess;
    public downloadPauseOnDeviceProcess: ProcessMonitorServiceProcess;
    public downloadResumeOnDeviceProcess: ProcessMonitorServiceProcess;

    public hostDevice: DeviceModel;
    public nodes: DeviceModel[] = [];
    public timeSetup: TimeSetupModel;
    public videoStorageCapacity: VideoStorageCapacityModel;

    public installerUser: boolean = null;

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @HostBinding()
    public id: string = 'rift-recordings';

    public readonly showSessionTypes: ISessionType[] = [
        {
            type: 'schedule',
            title: 'Device Scheduled Recordings',
            displayedColumns: ['state', 'devices', 'progress', 'date', 'start', 'duration', 'end'],
            dataSource: new MatTableDataSource<VideoViewModel>(),
        },
        {
            type: 'onDevice',
            title: 'Device Recordings',
            displayedColumns: ['state', 'devices', 'download', 'progress', 'date', 'start', 'duration', 'end', 'thumb'],
            dataSource: new MatTableDataSource<VideoViewModel>(),
        },
        {
            type: 'local',
            title: 'Local Recordings',
            displayedColumns: ['state', 'devices', 'download', 'progress', 'date', 'start', 'duration', 'end', 'thumb'],
            dataSource: new MatTableDataSource<VideoViewModel>(),
        }
    ];

    public constructor(
        private readonly _recordingService: RecordingService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _dialog: MatDialog,
        private readonly _recordingControlService: RecordingControlService,
        private readonly _connectionService: ConnectionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(RecordingsComponent.className, this.loadDataProcessText);
        this.addScheduleProcess = this.processMonitorService.getProcess(RecordingsComponent.className, 'Adding schedule');
        this.cancelScheduleProcess = this.processMonitorService.getProcess(RecordingsComponent.className, 'Canceling schedule');
        this.deleteLocalProcess = this.processMonitorService.getProcess(RecordingsComponent.className, 'Deleting Local session');
        this.deleteOnDeviceProcess = this.processMonitorService.getProcess(RecordingsComponent.className, 'Deleting on device session');
        this.downloadOnDeviceProcess = this.processMonitorService.getProcess(RecordingsComponent.className, 'Downloading on device session');
        this.downloadCancelOnDeviceProcess = this.processMonitorService.getProcess(RecordingsComponent.className, 'Canceling on device download');
        this.downloadPauseOnDeviceProcess = this.processMonitorService.getProcess(RecordingsComponent.className, 'Pausing on device download');
        this.downloadResumeOnDeviceProcess = this.processMonitorService.getProcess(RecordingsComponent.className, 'Resuming on device download');

        this.addSubscription(this._recordingControlService.videosChanged.subscribe(
            (event) => {
                this.addSubscription(this.getVideoViewModels(event.sessionType).subscribe());
            }
        ));

        this.initConnectionState();
    }

    public applyFilter(filterValue: string, dataSource: MatTableDataSource<VideoViewModel>) {
        if (!this.isNullOrUndefined(dataSource.data)) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            dataSource.filter = filterValue;
        }
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.deviceService.getDevices().pipe(
                map(result => {
                    this.nodes = [];
                    if (!this.isNullOrUndefined(result)) {
                        result.items.forEach(device => {
                            if (device.master === true) {
                                this.hostDevice = device;
                            } else {
                                this.nodes.push(device);
                            }
                        });
                    }
                    return true;
                })
            ),
            this.globalService.getTimeSetup(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.timeSetup = result;
                    }
                    return true;
                })
            ),
            this._recordingService.getVideoStorageCapacity(this._connectionService.hostFriendlySerial, process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.videoStorageCapacity = result;
                    }
                    return true;
                })
            ),
            this.getVideoViewModels(),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public local_Delete(masterVideo: VideoViewModel): void {
        if (!this.isNullOrUndefined(masterVideo)) {
            this.addSubscription(this.openOkCancelDialog('Delete Local Session', 'Are you sure you want to delete this local session?', true).afterClosed().subscribe(
                (result: OkCancelDialogResult) => {
                    if (!this.isNullOrUndefined(result) && result.ok === true) {
                        this._recordingControlService.local_Delete(masterVideo, this.deleteLocalProcess);
                    }
                }
            ));
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public onDevice_Delete(masterVideo: VideoViewModel): void {
        if (!this.isNullOrUndefined(masterVideo)) {
            this.addSubscription(this.openOkCancelDialog('Delete Session', 'Are you sure you want to delete this session?', true).afterClosed().subscribe(
                (result: OkCancelDialogResult) => {
                    if (!this.isNullOrUndefined(result) && result.ok === true) {
                        this._recordingControlService.onDevice_Delete(masterVideo, this.deleteOnDeviceProcess);
                    }
                }
            ));
        }
    }

    public onDevice_Download(masterVideo: VideoViewModel): void {
        if (!this.isNullOrUndefined(masterVideo)) {
            this._recordingControlService.onDevice_Download(masterVideo, this.downloadOnDeviceProcess);
        }
    }

    public onDevice_Download_Cancel(masterVideo: VideoViewModel): void {
        if (!this.isNullOrUndefined(masterVideo)) {
            this._recordingControlService.onDevice_Download_Cancel(masterVideo, this.downloadCancelOnDeviceProcess);
        }
    }

    public onDevice_Download_Pause(masterVideo: VideoViewModel): void {
        if (!this.isNullOrUndefined(masterVideo)) {
            this._recordingControlService.onDevice_Download_Pause(masterVideo, this.downloadPauseOnDeviceProcess);
        }
    }

    public onDevice_Download_Resume(masterVideo: VideoViewModel): void {
        if (!this.isNullOrUndefined(masterVideo)) {
            this._recordingControlService.onDevice_Download_Resume(masterVideo, this.downloadResumeOnDeviceProcess);
        }
    }

    public get videos_AnyActive(): boolean {
        return this._recordingControlService.videos_AnyActive;
    }

    public scheduledVideo_Add(): void {
        if ([this.hostDevice, ...this.nodes].every(device => device.autoHeightState === AutoHeightDetectionStateEnum.croppedOrientation || device.autoHeightState === AutoHeightDetectionStateEnum.failedOrientation || device.autoHeightState === AutoHeightDetectionStateEnum.autoValid || device.autoHeightState === AutoHeightDetectionStateEnum.preset)) {
            this.addSubscription(this._dialog.open(RecordingsAddScheduleComponent, { data: new AddScheduleData(this.hostDevice.serialNumber), disableClose: true }).afterClosed().subscribe(
                (result: AddScheduleResult) => {
                    if (!this.isNullOrUndefined(result) && result.ok === true) {
                        const videoViewModel = new VideoViewModel(this.hostDevice);
                        videoViewModel.schedule = new ScheduleViewModel(result.schedule, this.hostDevice);

                        this._recordingControlService.scheduledVideo_Add(videoViewModel, this.addScheduleProcess);
                    }
                }
            ));
        } else {
            this.openOkCancelDialog('Add Schedule', 'There is currently a device in the network with invalid height', false);
        }
    }

    public scheduledVideo_Cancel(masterVideo: VideoViewModel): void {
        if (!this.isNullOrUndefined(masterVideo)) {
            this.addSubscription(this.openOkCancelDialog('Cancel Schedule', 'Are you sure you want to cancel this schedule?', true).afterClosed().subscribe(
                (result: OkCancelDialogResult) => {
                    if (!this.isNullOrUndefined(result) && result.ok === true) {
                        this._recordingControlService.scheduledVideo_Cancel(masterVideo, this.cancelScheduleProcess);
                    }
                }
            ));
        }
    }

    public scheduledVideo_Validate(masterVideo: VideoViewModel): void {
        if (this.isNullOrUndefined(masterVideo.local)){
            this._router.navigateByUrl(UrlUtility.getRelativeUrl(['validation', masterVideo.onDevice.item.id.toString(), masterVideo.onDevice.item.startTime.toString()], this._router, this._activatedRoute, ['recordings']));
        }
        else{
            this._router.navigateByUrl(UrlUtility.getRelativeUrl(['validation', masterVideo.local.item.id.toString(), masterVideo.local.item.startTime.toString()], this._router, this._activatedRoute, ['recordings']));
        }
    }

    protected offline(): void {
        super.offline();
        this._recordingControlService.clearVideosCache();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this._recordingControlService.clearVideosCache();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    private getVideoViewModels(sessionType?: SessionTypes): Observable<boolean> {
        return this._recordingControlService.videos.pipe(
            map(videos => {
                if (!this.isNullOrUndefined(videos)) {
                    const showSessionTypesLength = this.showSessionTypes.length;
                    for (let i = 0; i < showSessionTypesLength; i++) {
                        const showSessionType = this.showSessionTypes[i];
                        if (this.isNullOrUndefined(sessionType) || showSessionType.type === sessionType) {
                            const filteredData = videos.filter(video => video.isSessionType(showSessionType.type));
                            showSessionType.dataSource.data = [];
                            showSessionType.dataSource.data = filteredData;
                        }
                    }
                }
                return true;
            })
        );
    }
}
