import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Injector,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    SettingsCountingMenuBaseComponent,
} from '@rift/components/settings/counting/base/Settings.Counting.MenuBase.Component';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { CountModel } from '@rift/models/websocket/Count.Model';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { RegisterTypeConfig } from '@rift/shared/Settings.RegisterConfig';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { StreamTypeEnum } from '@shared/enum/StreamType.Enum';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IPosition } from 'angular2-draggable';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { ISize } from 'angular2-draggable/lib/models/size';
import { Subscription } from 'rxjs';

export enum CountTypeEnum {
    system = 0,
    user = 1,
}

export interface CountChangedEvent {
    oldCount: number;
    newCount: number;
    index: number;
}

export class Counts {
    public countChanged: EventEmitter<CountChangedEvent> = new EventEmitter<CountChangedEvent>();

    private _userFirst: Array<number> = null;
    private _system: Array<number> = null;
    private _isFirstSet: boolean = true;

    public constructor(private readonly _length: number) {
        this._system = new Array<number>(this._length);
        this._userFirst = new Array<number>(this._length);
        this._userFirst.fill(0);
    }

    public getCount(type: CountTypeEnum, register: RegisterBaseModel): number {
        const count = register.instantaneous === true ? this.system(register.registerIndex) : type === CountTypeEnum.system ? this.system(register.registerIndex) : this.user(register.registerIndex);
        return Number.isNaN(count) ? 0 : count;
    }

    public resetSystemCounts(): void {
        const length = this._system.length;
        for (let index = 0; index < length; index++) {
            this._userFirst[index] = 0;
            this._system[index] = 0;
        }
    }

    public resetUserCounts(): void {
        const length = this._system.length;
        for (let index = 0; index < length; index++) {
            this._userFirst[index] = this._system[index];
        }
    }

    public setCounts(systemCounts: Array<number>): void {
        const systemCountsLength = systemCounts.length;
        for (let i = 0; i < systemCountsLength; i++) {
            if (this._system[i] !== systemCounts[i]) {
                this.countChanged.next({ oldCount: this._system[i], newCount: systemCounts[i], index: i });
            }
            this._system[i] = systemCounts[i];
            if (this._isFirstSet === true) {
                this._userFirst[i] = systemCounts[i];
            }
        }
        this._isFirstSet = false;
    }

    public system(registerIndex: number): number {
        return this._system[registerIndex];
    }

    public user(registerIndex: number): number {
        return this._system[registerIndex] - this._userFirst[registerIndex];
    }
}

export class RegisterBaseViewModel {
    public register: RegisterBaseModel;
    public visible: boolean = true;
    public selected: boolean = false;
    public canEdit: boolean = true;

    public constructor(private readonly _register: RegisterBaseModel) {
        this.register = _register;
        const config = RegisterTypeConfig[this.register.registerType];
        if (!isNullOrUndefined(config)) {
            this.canEdit = config.userEditInRegistersList;
        }
    }
}

@Component({
    selector: 'rift-settings-counting-registers',
    templateUrl: './Settings.Counting.Registers.Component.html',
    styleUrls: ['./Settings.Counting.Registers.Component.scss'],
})
export class SettingsCountingRegistersComponent extends SettingsCountingMenuBaseComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
    public static className: string = 'SettingsCountingRegistersComponent';

    @HostBinding()
    public id: string = 'rift-settings-counting-registers';

    public registerVMs: Array<RegisterBaseViewModel> = null;
    public counts: Counts = null;
    public registerListHeight: number = 0;
    public countFontSize: number = 20;
    public resetSystemCountsProcess: ProcessMonitorServiceProcess;
    public countsChangedProcess: ProcessMonitorServiceProcess;
    public countMessageReceivedProcess: ProcessMonitorServiceProcess;

    public CountTypeEnum = CountTypeEnum;
    public RegisterBaseModel = RegisterBaseModel;

    @Input()
    public get bounds(): HTMLElement {
        return this._bounds;
    }
    public set bounds(value: HTMLElement) {
        this._bounds = value;
        this.checkPosition();
    }

    @Input()
    public get zIndex(): number {
        return this._zIndex;
    }
    public set zIndex(value: number) {
        this._zIndex = value;
    }

    @Input()
    public selectedRegister: RegisterBaseModel;

    @Input()
    public registers: Array<RegisterBaseModel> = null;

    @Input()
    public addRegisterDisabled: boolean = false;

    @Input()
    public editRegisterDisabled: boolean = false;

    @Input()
    public editingRegister: RegisterBaseModel;

    @Output()
    public countChanged: EventEmitter<CountChangedEvent> = new EventEmitter<CountChangedEvent>();

    @Output()
    public showAllRegister: EventEmitter<null> = new EventEmitter<null>();

    @Output()
    public hideAllRegister: EventEmitter<null> = new EventEmitter<null>();

    @Output()
    public showHideRegister: EventEmitter<RegisterBaseModel> = new EventEmitter<RegisterBaseModel>();

    @Output()
    public showHideHistogram: EventEmitter<RegisterBaseModel> = new EventEmitter<RegisterBaseModel>();

    @Output()
    public editRegister: EventEmitter<RegisterBaseModel> = new EventEmitter<RegisterBaseModel>();

    @Output()
    public registerSelected: EventEmitter<RegisterBaseModel> = new EventEmitter<RegisterBaseModel>();

    @Output()
    public addRegister: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('title', { static: true })
    public titleElement: ElementRef;

    @ViewChild('resetCount', { static: true })
    public resetCountElement: ElementRef;

    @ViewChild('registerList', { static: true })
    public registerListElement: ElementRef;

    @LocalStorage(SettingsCountingRegistersComponent.className, 'position')
    public position: IPosition;

    @LocalStorage(SettingsCountingRegistersComponent.className, 'size')
    public size: ISize;

    @LocalStorage(SettingsCountingRegistersComponent.className, 'show')
    public show: boolean;

    private _countStreamId: number = null;
    private _countMessageReceivedSub: Subscription = null;
    private _countFontSizeMax: number = 140;
    private _countFontSizeMin: number = 20;
    private _countChangeSub: Subscription = null;

    public constructor(
        private readonly _render: Renderer2,
        private readonly _zone: NgZone,
        private readonly _eventsService: EventsService,
        private readonly _registerService: RegisterService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_render, _injector, _dialog);

        this.minHeight = 300;
        this.minWidth = 350;

        this.countsChangedProcess = this.processMonitorService.getProcess(SettingsCountingRegistersComponent.className, 'Counts Changed');
        this.countMessageReceivedProcess = this.processMonitorService.getProcess(SettingsCountingRegistersComponent.className, 'Count Message Received');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingRegistersComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsCountingRegistersComponent.className, this.saveAllChangesProcessText);
        this.resetSystemCountsProcess = this.processMonitorService.getProcess(SettingsCountingRegistersComponent.className, 'Resetting system counts');

        this.initConnectionState();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (!this.isNullOrUndefined(this.size)) {
            this.setCountsFontSize(this.size);
            this.setRegistersListHeight(this.size);
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        this.stopStreams();
        this.counts = null;
        super.ngOnDestroy();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.registers) && !this.isNullOrUndefined(changes.registers.currentValue)) {
            if (changes.registers.currentValue.length > 0) {
                this.registerVMs = changes.registers.currentValue
                    .filter((registerBase: RegisterBaseModel) => RegisterTypeConfig[registerBase.registerType].showInRegistersList)
                    .map(
                        (registerBase: RegisterBaseModel) => {
                            const oldVM = this.isNullOrUndefined(this.registerVMs) ? null : this.registerVMs.find((rvm) => RegisterBaseModel.equal(rvm.register, registerBase));
                            const vm = new RegisterBaseViewModel(registerBase);

                            if (!this.isNullOrUndefined(oldVM)) {
                                vm.visible = oldVM.visible;
                                vm.selected = oldVM.selected;
                            } else {
                                vm.visible = true;
                            }

                            return vm;
                        }
                    );
            } else {
                this.registerVMs = [];
            }

            this.counts = new Counts(this.registers.length);

            if (!this.isNullOrUndefined(this._countChangeSub)) {
                this._countChangeSub.unsubscribe();
                this._countChangeSub = null;
            }

            this._countChangeSub = this.addSubscription(this.observableHandlerBase(this.counts.countChanged, this.countsChangedProcess).subscribe(
                (event: CountChangedEvent) => this.countChanged.next(event)
            ), this.countsChangedProcess);

            if (!this.isNullOrUndefined(this._countMessageReceivedSub)) {
                this._countMessageReceivedSub.unsubscribe();
            }

            this._countMessageReceivedSub = this.addSubscription(this.observableHandlerBase(this.webSocketService.countMessageReceived, this.countMessageReceivedProcess).subscribe(
                countModel => this.onCountMessageReceived(countModel)
            ), this.countMessageReceivedProcess);
        }
    }

    public getRegisterCount(register: RegisterBaseModel): number {
        return this.counts.getCount(CountTypeEnum.system, register);
    }

    public onRzResizing(event: IResizeEvent): void {
        super.onRzResizing(event);
        this.setCountsFontSize(event.size);
        this.setRegistersListHeight(event.size);
    }

    public onAddRegisterClick(): void {
        this.addRegister.next();
    }

    public onShowAllRegistersClick(): void {
        this.registerVMs.forEach(vm => vm.visible = true);
        this.showAllRegister.emit(null);
    }

    public onHideAllRegistersClick(): void {
        this.registerVMs.forEach(vm => vm.visible = false);
        this.hideAllRegister.emit(null);
    }

    public onShowHideHistogramClick(vm: RegisterBaseViewModel, e: MouseEvent): void {
        this.showHideHistogram.next(vm.register);
        e.stopPropagation();
    }

    public onShowHideRegisterClick(vm: RegisterBaseViewModel, e: MouseEvent): void {
        vm.visible = !vm.visible;
        this.showHideRegister.next(vm.register);
        e.stopPropagation();
    }

    public onEditRegisterClick(vm: RegisterBaseViewModel, e: MouseEvent): void {
        const registerToEdit = RegisterBaseUtility.parentHoldsSettings(vm.register, this.registers);

        if (registerToEdit[0]) {
            this.editRegister.next(registerToEdit[1]);
        } else {
            this.editRegister.next(vm.register);
        }

        e.stopPropagation();
    }

    public onRegisterSelectedClick(registerBaseView: RegisterBaseViewModel, e: MouseEvent): void {
        const registersLength = this.registerVMs.length;
        for (let registerIndex = 0; registerIndex < registersLength; registerIndex++) {
            this.registerVMs[registerIndex].selected = false;
        }

        if (this.isNullOrUndefined(this.selectedRegister) || this.selectedRegister.registerIndex !== registerBaseView.register.registerIndex) {
            registerBaseView.selected = true;
            this.selectedRegister = registerBaseView.register;
            this.registerSelected.emit(this.selectedRegister);
        } else {
            this.selectedRegister = null;
            this.registerSelected.emit(null);
        }
        e.stopPropagation();
    }

    public onResetCountsClick(type: CountTypeEnum): void {
        switch (type) {
            case CountTypeEnum.user:
                this.counts.resetUserCounts();
                break;
            case CountTypeEnum.system:
                this.addSubscription(this.openOkCancelDialog('System counts reset', 'Are you sure you want to reset the device system counts?', true).afterClosed().subscribe(
                    result => {
                        if (!this.isNullOrUndefined(result) && result.ok === true) {
                            this.addSubscription(this.observableHandlerBase(this.globalService.resetCounts(this.resetSystemCountsProcess), this.resetSystemCountsProcess).subscribe(
                                () => {
                                    this.counts.resetSystemCounts();
                                }
                            ), this.resetSystemCountsProcess);
                        }
                    }
                ));

                break;
        }
    }

    public open(): void {
        this.startStreams();
        super.open();
    }

    public close(): void {
        this.stopStreams();
        super.close();
    }

    protected offline(): void {
        super.offline();
        this.stopStreams();
    }

    protected online(): void {
        super.online();
        if (this.show === true) {
            this.startStreams();
        }
    }

    private setCountsFontSize(size: ISize): void {
        let countFontSize = size.width - this.minWidth;
        if (countFontSize < this._countFontSizeMin) {
            countFontSize = this._countFontSizeMin;
        }
        if (countFontSize > this._countFontSizeMax) {
            countFontSize = this._countFontSizeMax;
        }
        this.countFontSize = countFontSize;
    }

    private setRegistersListHeight(size: ISize): void {
        this.registerListHeight = size.height - 135.5;
    }

    private onCountMessageReceived(countModel: CountModel): void {
        this._zone.run(() => {
            this.counts.setCounts(countModel.counts);
        });
    }

    private startStreams(): void {
        if (this.connectionService.isOnline === true) {
            this._countStreamId = this.webSocketService.startStream(StreamTypeEnum.count);
        }
    }

    private stopStreams(): void {
        this.webSocketService.stopStream(StreamTypeEnum.count, this._countStreamId);
    }
}
