import { Injectable } from '@angular/core';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { WirelessPIRDataModel } from '@rift/models/restapi/WirelessPIRData.Model';
import { RestApiWirelessPIRService } from '@rift/service/restapi/v1/RestAPI.WirelessPIR.Service';

@Injectable()
export class WirelessPIRService extends RiftBaseService {

    private _wirelessPIRDataLoadingTracker = new ObservableTracker<WirelessPIRDataModel>();

    public constructor(
        private readonly _restApiWirelessPIRService: RestApiWirelessPIRService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
    }

    public getWirelessPIRData(serial: string, process?: ProcessMonitorServiceProcess): Observable<WirelessPIRDataModel> {
        return this._wirelessPIRDataLoadingTracker
            .getLoading()
            .observable(this._restApiWirelessPIRService.getWirelessPIRData(serial, process));
    }

}
