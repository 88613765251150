<div class="counting-setting-content">
    <div fxLayout="row">
        <div fxFlex="60" class="spacing-right" fxLayout="column">
            <mat-form-field>
                <mat-select #deviceSelect (selectionChange)="deviceSelected($event)" placeholder="Devices">
                    <mat-option *ngFor="let device of devices" [value]="device">
                        {{device.serial}} - Selected Registers ({{device.selectedRegisterCount}})
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="40" class="spacing-right" fxLayout="column">
            <mat-form-field>
                <mat-select [value]="selectedRegisters" (selectionChange)="registerSelected($event)" placeholder="Registers" multiple>
                    <mat-option
                        *ngFor="let deviceRegister of isNullOrUndefined(deviceSelect.value) ? [] : deviceSelect.value.registers"
                        [value]="deviceRegister">
                        {{deviceRegister.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>