import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormControlName, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { ConnectComponent } from '@rift/components/connect/Connect.Component';
import { CountsComponent } from '@rift/components/counts/Counts.Component';
import {
    DeviceValidateErrorDialogComponent,
} from '@rift/components/devicevalidation/validatecountingnetwork/Error.Dialog.Component';
import {
    UniqueMasterSelectDialogComponent,
} from '@rift/components/devicevalidation/validatecountingnetwork/UniqueMasterSelect.Dialog.Component';
import { PositionDialogComponent } from '@rift/components/devicevalidation/validateheights/Position.Dialog.Component';
import {
    NetworkChangeDetectedDialogComponent,
} from '@rift/components/devicevalidation/validatewidetrackerconfig/NetworkChangeDetected.Dialog.Component';
import {
    BuildVersionErrorDialogComponent,
} from '@rift/components/devicevalidation/validationbuildversion/BuildVersionError.Dialog.Component';
import { DiagnosticsComponent } from '@rift/components/diagnostics/Diagnostics.Component';
import { DisconnectComponent } from '@rift/components/disconnect/Disconnect.Component';
import { HomeComponent } from '@rift/components/home/Home.Component';
import { RecordingsAddScheduleComponent } from '@rift/components/recordings/addschedule/Recordings.AddSchedule.Component';
import { RecordingsComponent } from '@rift/components/recordings/Recordings.Component';
import {
    RestoreBackupFileSelectComponent,
} from '@rift/components/restorebackup/fileselect/RestoreBackup.FileSelect.Component';
import { RestoreBackupComponent } from '@rift/components/restorebackup/RestoreBackup.Component';
import { RiftComponent } from '@rift/components/rift/Rift.Component';
import { SettingsBluetoothComponent } from '@rift/components/settings/bluetooth/Settings.Bluetooth.Component';
import { ConfigHistoryComponent } from '@rift/components/settings/confighistory/ConfigHistory.Component';
import {
    ConfigHistorySectionSelectComponent,
} from '@rift/components/settings/confighistory/ConfigHistory.Section.Select.Component';
import {
    ConfigHistoryActionHeaderComponent,
} from '@rift/components/settings/confighistory/sections/ConfigHistory.ActionHeader.Component';
import {
    ConfigHistorySectionComponent,
} from '@rift/components/settings/confighistory/sections/ConfigHistory.Section.Component';
import {
    ConfigHistoryValueOutputComponent,
} from '@rift/components/settings/confighistory/sections/ConfigHistory.ValueOutput.Component';
import {
    SettingsCountingAddRegisterComponent,
} from '@rift/components/settings/counting/addregister/Settings.Counting.AddRegister.Component';
import {
    SettingsCountingAdvancedSettingsComponent,
} from '@rift/components/settings/counting/advancedsettings/Settings.Counting.AdvancedSettings.Component';
import {
    SettingsCountingDevicesComponent,
} from '@rift/components/settings/counting/devices/Settings.Counting.Devices.Component';
import {
    SettingsCountingEditDeviceComponent,
} from '@rift/components/settings/counting/editdevice/Settings.Counting.EditDevice.Component';
import {
    SettingsCountingEditRegisterComponent,
} from '@rift/components/settings/counting/editregister/Settings.Counting.EditRegister.Component';
import {
    SettingsCountingRegisterNameComponent,
} from '@rift/components/settings/counting/editregister/Settings.Counting.RegisterName.Component';
import {
    SettingsCountingGlobalHeightFilterComponent,
} from '@rift/components/settings/counting/globalheightfilter/Settings.Counting.GlobalHeightFilter.Component';
import {
    SettingsCountingHistogramChartComponent,
} from '@rift/components/settings/counting/histogramchart/Settings.Counting.HistogramChart.Component';
import {
    SettingsCountingRegistersComponent,
} from '@rift/components/settings/counting/registers/Settings.Counting.Registers.Component';
import { SettingsCountingComponent } from '@rift/components/settings/counting/Settings.Counting.Component';
import {
    SettingsCountingAssociateRegistersComponent,
} from '@rift/components/settings/counting/shared/settings/associateregisters/Settings.Counting.AssociateRegisters.Component';
import {
    SettingsCountingCountModeComponent,
} from '@rift/components/settings/counting/shared/settings/countmode/Settings.Counting.CountMode.Component';
import {
    SettingsCountingCustomActionComponent,
} from '@rift/components/settings/counting/shared/settings/customaction/Settings.Counting.CustomAction.Component';
import {
    SettingsCountingDwellHistogramComponent,
} from '@rift/components/settings/counting/shared/settings/dwellhistogram/Settings.Counting.DwellHistogram.Component';
import {
    SettingsCountingExclusionTypeComponent,
} from '@rift/components/settings/counting/shared/settings/exclusiontype/Settings.Counting.ExclusionType.Component';
import {
    SettingsCountingHeightFilteringComponent,
} from '@rift/components/settings/counting/shared/settings/heightfiltering/Settings.Counting.HeightFiltering.Component';
import {
    SettingsCountingHeightHistogramComponent,
} from '@rift/components/settings/counting/shared/settings/heighthistogram/Settings.Counting.HeightHistogram.Component';
import {
    SettingsCountingInstantDwellHistogramComponent,
} from '@rift/components/settings/counting/shared/settings/instantdwellhistogram/Settings.Counting.InstantDwellHistogram.Component';
import {
    SettingsCountingRemotePushComponent,
} from '@rift/components/settings/counting/shared/settings/remote-push/Settings.Counting.RemotePush.Component';
import {
    SettingsCountingRemoteComponent,
} from '@rift/components/settings/counting/shared/settings/remote/Settings.Counting.Remote.Component';
import {
    SettingsCountingRegisterSettingsComponent,
} from '@rift/components/settings/counting/shared/settings/Settings.Counting.RegisterSettings.Component';
import {
    SettingsCountingSimplePropertyComponent,
} from '@rift/components/settings/counting/shared/settings/simpleproperty/Settings.Counting.SimpleProperty.Component';
import {
    SettingsCountingStaffDetectComponent,
} from '@rift/components/settings/counting/shared/settings/staffdetection/Settings.Counting.StaffDetect.Component';
import {
    SettingsCountingTagsComponent,
} from '@rift/components/settings/counting/shared/settings/tags/Settings.Counting.Tags.Component';
import {
    SettingsCountingUnattendedTimeHistogramComponent,
} from '@rift/components/settings/counting/shared/settings/unattendedtimehistogram/Settings.Counting.UnattendedTimeHistogram.Component';
import {
    SettingsCountingZoneComponent,
} from '@rift/components/settings/counting/shared/settings/zone/Settings.Counting.Zone.Component';
import {
    SettingsCountingRegisterSummaryComponent,
} from '@rift/components/settings/counting/shared/summary/Settings.Counting.RegisterSummary.Component';
import {
    SettingsCountingRegisterSummaryDialogComponent,
} from '@rift/components/settings/counting/shared/summary/Settings.Counting.RegisterSummary.Dialog.Component';
import {
    SettingsCountingTrackingComponent,
} from '@rift/components/settings/counting/tracking/Settings.Counting.Tracking.Component';
import { SettingsCountLoggingComponent } from '@rift/components/settings/countlogging/Settings.CountLogging.Component';
import { SettingsHttpPostComponent } from '@rift/components/settings/httppost/Settings.HttpPost.Component';
import { SettingsMQTTComponent } from '@rift/components/settings/mqtt/Settings.MQTT.Component';
import { SettingsNetworkComponent } from '@rift/components/settings/network/Settings.Network.Component';
import { SettingsNetworkSelectComponent } from '@rift/components/settings/network/Settings.Network.Select.Component';
import {
    SettingsOutboundConnectionsComponent,
} from '@rift/components/settings/outboundconnections/Settings.OutboundConnections.Component';
import { SettingsRelaysLimitsComponent } from '@rift/components/settings/relays/limits/Settings.Relays.Limits.Component';
import {
    SettingsRelaysPulseWidthComponent,
} from '@rift/components/settings/relays/pulsewidth/Settings.Relays.PulseWidth.Component';
import { SettingsRelaysComponent } from '@rift/components/settings/relays/Settings.Relays.Component';
import { SettingsSecurityComponent } from '@rift/components/settings/security/Settings.Security.Component';
import { SettingsComponent } from '@rift/components/settings/Settings.Component';
import { SettingsSiteComponent } from '@rift/components/settings/site/Settings.Site.Component';
import { SettingsVideoComponent } from '@rift/components/settings/video/Settings.Video.Component';
import {
    ListDeviceFilter,
    SettingsWideTrackerComponent,
} from '@rift/components/settings/widetracker/Settings.WideTracker.Component';
import {
    AutoConfigRemoteDeviceSelectDialogComponent,
} from '@rift/components/shared/auto-config/dialog/RemoteDeviceSelect.Dialog.Component';
import { DeviceIssuesComponent } from '@rift/components/shared/bottominfo/deviceIssues/DeviceIssues.Component';
import {
    SyncingVideoSessionsComponent,
} from '@rift/components/shared/bottominfo/syncingvideosessions/SyncingVideoSessions.Component';
import { HistogramChartComponent } from '@rift/components/shared/histogram/chart/Histogram.Chart.Component';
import { RegisterIconNameComponent } from '@rift/components/shared/register/iconname/Register.IconName.Component';
import { TimeLineComponent } from '@rift/components/shared/timeline/TimeLine.Component';
import {
    UnitsOfMeasurementLabelComponent,
} from '@rift/components/shared/unitsofmeasurementlabel/UnitsOfMeasurementLabel.Component';
import {
    UnitsOfMeasurementMenuSelectComponent,
} from '@rift/components/shared/unitsofmeasurementmenuselect/UnitsOfMeasurementMenuSelect.Component';
import { ViewPortComponent } from '@rift/components/shared/viewport/ViewPort.Component';
import { SummaryComponent } from '@rift/components/summary/Summary.Component';
import { TaskLauncherComponent } from '@rift/components/tasklauncher/TaskLauncher.Component';
import { TaskRunnerComponent } from '@rift/components/tasklauncher/TaskRunner.Component';
import { TaskUploaderComponent } from '@rift/components/tasklauncher/TaskUploader.Component';
import {
    AutoSpeedSettingsDialogComponent,
} from '@rift/components/validation/autospeedsettings/AutoSpeedSettings.Dialog.Component';
import {
    BookmarkDetailsDialogComponent,
} from '@rift/components/validation/bookmarkdetails/BookmarkDetails.Dialog.Component';
import { CountsMenuComponent } from '@rift/components/validation/countsmenu/CountsMenu.Component';
import {
    LoadValidationRecordingComponent,
} from '@rift/components/validation/loadvalidationrecording/LoadValidationRecording.Component';
import {
    ManageSynchronizedRecordingsComponent,
} from '@rift/components/validation/managesynchronizedrecordings/ManageSynchronizedRecordings.component';
import {
    SelectValidationRecordingComponent,
} from '@rift/components/validation/selectrecording/SelectValidationRecording.Component';
import { SessionDetailsDialogComponent } from '@rift/components/validation/sessiondetails/SessionDetails.Dialog.Component';
import { SessionReportDialogComponent } from '@rift/components/validation/sessionreport/SessionReport.Dialog.Component';
import { SessionsMenuComponent } from '@rift/components/validation/sessionsmenu/SessionsMenu.Component';
import { SessionSyncResolveComponent } from '@rift/components/validation/sessionsyncresolve/Session.Sync.Resolve.Component';
import { ValidationSettingsDialogComponent } from '@rift/components/validation/settings/Settings.Dialog.Component';
import { UserCountMenuComponent } from '@rift/components/validation/usercountmenu/UserCountMenu.Component';
import { ValidationComponent } from '@rift/components/validation/Validation.Component';
import {
    UnitsOfMeasurementInputDirective,
} from '@rift/directives/unitsofmeasurementinput/UnitsOfMeasurementInput.Directive';
import { ConfigJsonCompareService } from '@rift/service/configjsoncompare/ConfigJsonCompare.Service';
import { ConnectionService } from '@rift/service/connection/Connection.Service';
import { ConnectionStateService } from '@rift/service/connection/ConnectionState.Service';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { AccountService } from '@rift/service/data/account/Account.Service';
import { AllDataService } from '@rift/service/data/alldata/AllData.Service';
import { BluetoothService } from '@rift/service/data/bluetooth/Bluetooth.Service';
import { CountsService } from '@rift/service/data/counts/Counts.Service';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { GlobalService } from '@rift/service/data/global/Global.Service';
import { HeightService } from '@rift/service/data/height/Height.Service';
import { HistogramService } from '@rift/service/data/histogram/Histogram.Service';
import { HttpPostService } from '@rift/service/data/httppost/HttpPost.Service';
import { LineService } from '@rift/service/data/line/Line.Service';
import { MQTTService } from '@rift/service/data/mqtt/MQTT.Service';
import { OutboundConnectionsService } from '@rift/service/data/outboundconnections/OutboundConnections.Service';
import { PathMapService } from '@rift/service/data/pathmap/PathMap.Service';
import { PolygonService } from '@rift/service/data/polygon/Polygon.Service';
import { RecordingService } from '@rift/service/data/recording/Recording.Service';
import { RecordingControlService } from '@rift/service/data/recording/RecordingControl.Service1';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { RelayService } from '@rift/service/data/relay/Relay.Service';
import { SecurityService } from '@rift/service/data/security/Security.Service';
import { StoreDataService } from '@rift/service/data/storedata/StoreData.Service';
import { TaskLauncherService } from '@rift/service/data/tasklauncher/TaskLauncher.Service';
import { ValidationDataService } from '@rift/service/data/validation/Validation.Service';
import { WideTrackerService } from '@rift/service/data/widetracker/WideTracker.Service';
import { AutoConfigService } from '@rift/service/device/auto-config/AutoConfig.Service';
import { DeviceValidationService } from '@rift/service/devicevalidation/DeviceValidation.Service';
import { RestApiAccountService } from '@rift/service/restapi/RestApiAccountService';
import { RestApiBluetoothService } from '@rift/service/restapi/v1/RestApi.Bluetooth.Service';
import { RestApiConnectionService } from '@rift/service/restapi/v1/RestApi.Connection.Service';
import { RestApiConnectionProviderService } from '@rift/service/restapi/v1/RestApi.ConnectionProvider.Service';
import { RestApiCountsService } from '@rift/service/restapi/v1/RestApi.Counts.Service';
import { RestApiDevicesService } from '@rift/service/restapi/v1/RestApi.Devices.Service';
import { RestApiDisconnectService } from '@rift/service/restapi/v1/RestApi.Disconnect.Service';
import { RestApiGlobalService } from '@rift/service/restapi/v1/RestApi.Global.Service';
import { RestApiHeightService } from '@rift/service/restapi/v1/RestApi.Height.Service';
import { RestApiHistogramsService } from '@rift/service/restapi/v1/RestApi.Histograms.Service';
import { RestApiLinesService } from '@rift/service/restapi/v1/RestApi.Lines.Service';
import { RestApiLocalService } from '@rift/service/restapi/v1/RestApi.Local.Service';
import { RestApiMQTTService } from '@rift/service/restapi/v1/RestApi.MQTT.Service';
import { RestApiOutboundService } from '@rift/service/restapi/v1/RestApi.Outbound.Service';
import { RestApiPathMapService } from '@rift/service/restapi/v1/RestApi.PathMap.Service';
import { RestApiPolygonsService } from '@rift/service/restapi/v1/RestApi.Polygons.Service';
import { RestApiRecordingsService } from '@rift/service/restapi/v1/RestApi.Recordings.Service';
import { RestApiRegistersService } from '@rift/service/restapi/v1/RestApi.Registers.Service';
import { RestApiRelaysService } from '@rift/service/restapi/v1/RestApi.Relays.Service';
import { RestApiSecurityService } from '@rift/service/restapi/v1/RestApi.Security.Service';
import { RestApiTaskLauncherService } from '@rift/service/restapi/v1/RestApi.TaskLauncher.Service';
import { RestApiValidationService } from '@rift/service/restapi/v1/RestApi.Validation.Service';
import { RestApiWideTrackerService } from '@rift/service/restapi/v1/RestApi.WideTracker.Service';
import { TimeLineLoadQueueService } from '@rift/service/timeline/TimeLine.LoadQueueService';
import { UnitsOfMeasurementService } from '@rift/service/unitsofmeasurement/UnitsOfMeasurement.Service';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { ValidationReportService } from '@rift/service/validation/Validation.Report.Service';
import { ValidationService } from '@rift/service/validation/Validation.Service';
import {
    ValidationSyncRecordingWebWorkerService,
} from '@rift/service/validation/Validation.SyncRecording.WebWorker.Service';
import { ValidationSyncSessionWorkerService } from '@rift/service/validation/Validation.SyncSession.WebWorker.Service';
import { ValidationUserCountsWebWorkerService } from '@rift/service/validation/Validation.UserCounts.WebWorker.Service';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { WebSocketService } from '@rift/service/websocket/WebSocket.Service';
import { BottomInfoComponent } from '@shared/component/bottominfo/BottomInfo.Component';
import { BottomInfoMessageComponent } from '@shared/component/bottominfo/message/BottomInfo.Message.Component';
import { BreadCrumbService } from '@shared/service/breadcrumb/BreadCrumb.Service';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { EventsService } from '@shared/service/events/Events.Service';
import { IndexedDBService } from '@shared/service/Indexeddb/IndexedDB.Service';
import { LoggingService } from '@shared/service/logging/Logging.Service';
import { NavBarService } from '@shared/service/navbar/NavBar.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { PendingChangesGuard } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorService } from '@shared/service/processmonitor/ProcessMonitor.Service';
import { UserNotificationService } from '@shared/service/usernotification/UserNotification.Service';
import { VersionService } from '@shared/service/version/Version.Service';
import { SharedModule } from '@shared/Shared.Module';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { AngularDraggableModule } from 'angular2-draggable';
import { FileUploadModule } from 'ng2-file-upload';
import { OrderModule } from 'ngx-order-pipe';
import { DiagnosticsPackageComponent } from './components/diagnosticspackage/DiagnosticsPackage.Component';
import { FormatSystemStorageComponent } from './components/formatsystemstorage/FormatSystemStorage.Component';
import { ReconnectComponent } from './components/reconnect/Reconnect.Component';
import { SettingsBACNETComponent } from './components/settings/bacnet/Settings.BACNET.Component';
import { MultiUnitAlignmentComponent } from './components/settings/counting/alignment/Settings.Counting.Alignment.Component';
import { SettingsCountingAlignmentDeviceListComponent } from './components/settings/counting/alignment/Settings.Counting.Alignment.DeviceList.Component';
import { SettingsCountingMaxDwellComponent } from './components/settings/counting/shared/settings/maxdwell/Settings.Counting.MaxDwell.Component';
import { SettingsWirelessPIRDevicesComponent } from './components/settings/counting/shared/settings/wireless-pir-devices/Settings.Counting.WirelessPIRDevices.Component';

import {
    SettingsWideTrackerConvertDeviceComponent,
} from './components/settings/widetracker/convertdevice/Settings.WideTracker.ConvertDevice.Component';
import { DeviceSelectorComponent } from './components/validation/deviceselector/DeviceSelector.Component';
import { RIFTUserIsRIFTRole } from './RiftRouteGuard';
import { BACNETService } from './service/data/bacnet/BACNET.Service';
import { WirelessPIRService } from './service/data/wireless_pir/WirelessPIR.Service';
import { RestApiBACNETService } from './service/restapi/v1/RestApi.BACNET.Service';
import { RestApiWirelessPIRService } from './service/restapi/v1/RestAPI.WirelessPIR.Service';

/**
 * This is used for adding nativeElement to form control for validators.
 * UnitsOfMeasurementInputValidators
 */
const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function() {
    try {
        const result = originFormControlNameNgOnChanges.apply(this, arguments);
        if (!isNullOrUndefined(this.valueAccessor) && !isNullOrUndefined(this.valueAccessor._elementRef) && !isNullOrUndefined(this.valueAccessor._elementRef.nativeElement)) {
            this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
        }
        return result;
    } catch {

    }
};

@NgModule({
    exports: [
        HistogramChartComponent,
        ViewPortComponent,
    ],
    declarations: [
        AutoSpeedSettingsDialogComponent,
        BookmarkDetailsDialogComponent,
        BuildVersionErrorDialogComponent,
        ConfigHistoryActionHeaderComponent,
        ConfigHistoryComponent,
        ConfigHistorySectionComponent,
        ConfigHistorySectionSelectComponent,
        ConfigHistoryValueOutputComponent,
        ConnectComponent,
        CountsComponent,
        CountsMenuComponent,
        DeviceSelectorComponent,
        DeviceIssuesComponent,
        DiagnosticsComponent,
        DisconnectComponent,
        DeviceValidateErrorDialogComponent,
        HistogramChartComponent,
        HomeComponent,
        ReconnectComponent,
        ListDeviceFilter,
        LoadValidationRecordingComponent,
        ManageSynchronizedRecordingsComponent,
        DiagnosticsPackageComponent,
        FormatSystemStorageComponent,
        NetworkChangeDetectedDialogComponent,
        PositionDialogComponent,
        RecordingsAddScheduleComponent,
        RecordingsComponent,
        RegisterIconNameComponent,
        RestoreBackupComponent,
        RestoreBackupFileSelectComponent,
        RiftComponent,
        SelectValidationRecordingComponent,
        SessionDetailsDialogComponent,
        SessionReportDialogComponent,
        SessionsMenuComponent,
        SessionSyncResolveComponent,
        SettingsBluetoothComponent,
        SettingsComponent,
        SettingsCountingAddRegisterComponent,
        SettingsCountingAdvancedSettingsComponent,
        SettingsCountingAssociateRegistersComponent,
        SettingsCountingComponent,
        SettingsCountingCountModeComponent,
        SettingsCountingCustomActionComponent,
        SettingsCountingDevicesComponent,
        SettingsCountingDwellHistogramComponent,
        SettingsCountingEditDeviceComponent,
        SettingsCountingEditRegisterComponent,
        SettingsCountingExclusionTypeComponent,
        SettingsCountingGlobalHeightFilterComponent,
        SettingsCountingHeightFilteringComponent,
        SettingsCountingMaxDwellComponent,
        SettingsCountingHeightHistogramComponent,
        SettingsCountingHistogramChartComponent,
        SettingsCountingInstantDwellHistogramComponent,
        SettingsCountingRegisterNameComponent,
        SettingsCountingRegistersComponent,
        SettingsCountingRegisterSettingsComponent,
        SettingsCountingRegisterSummaryComponent,
        SettingsCountingRegisterSummaryDialogComponent,
        SettingsCountingRemoteComponent,
        SettingsCountingRemotePushComponent,
        SettingsWirelessPIRDevicesComponent,
        SettingsCountingSimplePropertyComponent,
        SettingsCountingStaffDetectComponent,
        SettingsCountingTagsComponent,
        SettingsCountingTrackingComponent,
        SettingsCountingUnattendedTimeHistogramComponent,
        SettingsCountingZoneComponent,
        SettingsCountLoggingComponent,
        SettingsHttpPostComponent,
        SettingsMQTTComponent,
        SettingsBACNETComponent,
        SettingsNetworkComponent,
        SettingsNetworkSelectComponent,
        SettingsOutboundConnectionsComponent,
        SettingsRelaysComponent,
        SettingsRelaysLimitsComponent,
        SettingsRelaysPulseWidthComponent,
        SettingsSecurityComponent,
        SettingsSiteComponent,
        SettingsVideoComponent,
        SettingsWideTrackerComponent,
        SettingsWideTrackerConvertDeviceComponent,
        SummaryComponent,
        SyncingVideoSessionsComponent,
        TaskLauncherComponent,
        TaskRunnerComponent,
        TaskUploaderComponent,
        TimeLineComponent,
        UniqueMasterSelectDialogComponent,
        UnitsOfMeasurementInputDirective,
        UnitsOfMeasurementLabelComponent,
        UnitsOfMeasurementMenuSelectComponent,
        UserCountMenuComponent,
        ValidationComponent,
        ValidationSettingsDialogComponent,
        ViewPortComponent,
        AutoConfigRemoteDeviceSelectDialogComponent,
        MultiUnitAlignmentComponent,
        SettingsCountingAlignmentDeviceListComponent
    ],
    imports: [
        AngularDraggableModule,
        BrowserAnimationsModule,
        BrowserModule,
        FileUploadModule,
        FlexLayoutModule,
        FormsModule,
        HttpClientModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        OrderModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
    ],
    providers: [
        AutoConfigService,
        AccountService,
        AllDataService,
        BluetoothService,
        BreadCrumbService,
        ConfigJsonCompareService,
        ConnectionService,
        ConnectionStateService,
        ConnectionTokenService,
        CountsService,
        DataPollingService,
        DeviceService,
        DeviceValidationService,
        EventsService,
        GlobalService,
        HeightService,
        HistogramService,
        HttpPostService,
        IndexedDBService,
        LineService,
        LoggingService,
        MQTTService,
        BACNETService,
        NavBarActionService,
        NavBarService,
        OutboundConnectionsService,
        PathMapService,
        PendingChangesGuard,
        PolygonService,
        ProcessMonitorService,
        RecordingControlService,
        RecordingService,
        RegisterService,
        RelayService,
        WirelessPIRService,
        RestApiAccountService,
        RestApiBluetoothService,
        RestApiConnectionProviderService,
        RestApiConnectionService,
        RestApiCountsService,
        RestApiDevicesService,
        RestApiDisconnectService,
        RestApiGlobalService,
        RestApiHeightService,
        RestApiHistogramsService,
        RestApiLinesService,
        RestApiLocalService,
        RestApiMQTTService,
        RestApiBACNETService,
        RestApiOutboundService,
        RestApiPathMapService,
        RestApiPolygonsService,
        RestApiRecordingsService,
        RestApiRegistersService,
        RestApiRelaysService,
        RestApiSecurityService,
        RestApiTaskLauncherService,
        RestApiValidationService,
        RestApiWideTrackerService,
        RestApiWirelessPIRService,
        SecurityService,
        StoreDataService,
        TaskLauncherService,
        TimeLineLoadQueueService,
        UnitsOfMeasurementService,
        UserNotificationService,
        ValidationDataService,
        ValidationPlayService,
        ValidationReportService,
        ValidationService,
        ValidationSyncRecordingWebWorkerService,
        ValidationSyncSessionWorkerService,
        ValidationUserCountsWebWorkerService,
        VersionService,
        ViewPortLoadQueueService,
        WebSocketService,
        WideTrackerService,
        RIFTUserIsRIFTRole,
    ],
    bootstrap: [
        RiftComponent,
    ]
})
export class RiftSharedModule { }
