
/**
 * The status of the schedule result.
 *
 * @export
 * @enum {number}
 */
export enum ScheduleResultStatusTypeEnum {
    /**
     * Schedule failed.
     */
    fail = 0,

    /**
     * Schedule passed.
     */
    pass = 1,

    /**
     * Schedule aborted.
     */
    abort = 2,

    /**
     * Schedule threw an exception.
     */
    exception = 3,

    /**
     * Schedule is executing.
     */
    executing = 4,
}
