export class PlotlyConfiguration {
    public defaultOptions = {
        modeBarButtonsToRemove: [
            'sendDataToCloud',
            'hoverClosestCartesian',
            'hoverCompareCartesian',
            'lasso2d',
            'select2d'
        ],
        displaylogo: false,
        showTips: true,
        responsive: true,
    };
}
