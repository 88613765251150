<div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Validation Settings
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div mat-dialog-content fxLayout="column">
    <div class="subheader">
        User Count Key Mapping
    </div>
    <div class="description">
        Place mouse over register increment or decrement button and press a key to map it.
    </div>
    <div class="settings" fxLayout="row">
        <div *ngFor="let userCountKeyBinding of data.userCountKeyBindings; let i = index;" class="card">
            <div class="title">
                Register {{ i + 1 }}
            </div>
            <div class="button-container" (mouseenter)="mouseEnter(userCountKeyBinding, 'increment')"
                (mouseleave)="mouseLeave()" (keydown)="mapKey($event)">
                <button mat-button>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <shr-fa class="fas" size="2x" name="chevron-circle-up"></shr-fa>
                        <div class="key">{{ userCountKeyBinding.increment }}</div>
                    </div>
                </button>
            </div>
            <div class="button-container" (mouseenter)="mouseEnter(userCountKeyBinding, 'decrement')"
                (mouseleave)="mouseLeave()" (keydown)="mapKey($event)">
                <button mat-button>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <shr-fa class="fas" size="2x" name="chevron-circle-down"></shr-fa>
                        <div class="key">{{ userCountKeyBinding.decrement }}</div>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="subheader">
        Arrow Keys Jog
    </div>
    <div class="settings" fxLayout="row">
        <form [formGroup]="arrowKeyJogFormGroup">
            <mat-form-field fxFlex="50">
                <input type="number" #arrowKeyJogMs matInput formControlName="arrowKeyJogMs" placeholder="Ms">
                <mat-error>{{getFormControlErrorMessage(arrowKeyJogFormGroup.controls['arrowKeyJogMs'])}}</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="subheader">
        Line Report
    </div>
    <div class="settings" fxLayout="row">
        <form [formGroup]="lineReportFormGroup">
            <mat-form-field fxFlex="50">
                <input type="number" #bucketSize matInput formControlName="bucketSize"
                    placeholder="Aggregation Bucket Size">
                <mat-error>{{getFormControlErrorMessage(lineReportFormGroup.controls['bucketSize'])}}</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="subheader">
        Duration
    </div>
    <div class="settings" fxLayout="row">
        Duration range<mat-slide-toggle class="spacing-left" [checked]="data.showDurationAsTime"
            (change)="durationTimeChange($event)">{{ data.showDurationAsTime === true ? 'Time' : 'Duration' }}
        </mat-slide-toggle>
    </div>
    <div *ngIf="data.showDurationAsTime === true" class="subheader">
        Duration In Device Timezone
    </div>
    <div *ngIf="data.showDurationAsTime === true" class="settings" fxLayout="row">
        Time Zone<mat-slide-toggle class="spacing-left" [checked]="data.showDurationAsDeviceTimeZone"
            (change)="durationAsDeviceTimeZoneChange($event)">
            {{ data.showDurationAsDeviceTimeZone === true ? 'Device Time Zone' : 'UTC' }}</mat-slide-toggle>
    </div>
    <div class="subheader">
        Video Clipping (Please close and reopen validation for changes to take effect)
    </div>
    <div class="settings" fxLayout="row">
        <mat-slider  fxFlex="50" [max]="3" [min]="1" [step]="0.1" [tickInterval]="1" [(ngModel)]="data.hullScaleFactor">
        </mat-slider>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="close()">Close</button>
    <div fxFlex></div>
</div>