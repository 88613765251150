import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchedulesAddEditDialogData, SettingsSchedulesAddEditComponent } from '@em/components/settings/schedules/scheduleaddedit/Settings.Schedules.AddEdit.Component';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { ScheduleDeviceResultModel } from '@em/models/restapi/ScheduleDeviceResult.Model';
import { ScheduleOverviewModel } from '@em/models/restapi/ScheduleOverview.Model';
import { ScheduleResultModel } from '@em/models/restapi/ScheduleResult.Model';
import { ScheduleResultCollectionModel } from '@em/models/restapi/ScheduleResultCollection.Model';
import { ScheduleService } from '@em/service/data/schedule/Schedule.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { OkCancelDialogComponent, OkCancelDialogData, OkCancelDialogResult } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ResizedEvent } from '@shared/directive/resized/Resized.Directive.ResizedEvent';
import { DeviceResultTypeEnum } from '@shared/enum/DeviceResultType.Enum';
import { WorkflowOperationTypeEnum } from '@shared/enum/WorkflowOperationType.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { BreadCrumbService } from '@shared/service/breadcrumb/BreadCrumb.Service';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { EventsService } from '@shared/service/events/Events.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { ScheduleResultStatusTypeEnum } from '@shared/enum/ScheduleResultStatusType.Enum';
import { ITableRowClicked } from '@shared/component/table/Table.Component';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

declare const Plotly: any;

@Component({
    selector: 'em-settings-schedules-view',
    templateUrl: './Settings.Schedules.View.Component.html',
    styleUrls: ['./Settings.Schedules.View.Component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class SettingsSchedulesViewComponent extends BaseComponent implements OnInit, OnDestroy, ILoadDate {
    public static className: string = 'SettingsSchedulesViewComponent';
    public DeviceResultType = DeviceResultTypeEnum;
    public WorkflowOperationTypeEnum = WorkflowOperationTypeEnum;

    public ScheduleResultStatusTypeEnum = ScheduleResultStatusTypeEnum;

    public crumbsChangedProcess: ProcessMonitorServiceProcess;
    public dataPollingProcess: ProcessMonitorServiceProcess;

    public deleteAction: NavBarAction;
    public deleteScheduleProcess: ProcessMonitorServiceProcess;
    public deviceResultsError: Array<string>;
    public deviceResultsInvalid: Array<string>;
    public deviceResultsNotPresent: Array<string>;
    public deviceResultsPlaceholder: Array<string>;
    public deviceResultsSuccess: Array<string>;

    public deviceResultsWarning: Array<string>;
    public disableAction: NavBarAction;
    public displayedColumns = ['date', 'success', 'coverage', 'duration'];
    public editAction: NavBarAction;
    public editProcess: ProcessMonitorServiceProcess;
    public expandedScheduleResult: ScheduleResultModel | null;
    public getPageResultsProcess: ProcessMonitorServiceProcess;
    public hoverScheduleResult: ScheduleResultModel;
    public isNA: boolean = false;
    public loadDataProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'em-settings-schedules-view';
    public paramsChangeProcess: ProcessMonitorServiceProcess;
    public queryPramsChangeProcess: ProcessMonitorServiceProcess;
    public resultsDataSource = new MatTableDataSource<ScheduleResultModel>();
    public resultsPageOptions: PaginationOptionsModel = new PaginationOptionsModel(1, 10);
    public returnPath: string;
    public schedule: ScheduleOverviewModel;
    public scheduleId: number;
    public scheduleResults: ScheduleResultCollectionModel;
    public schedulesChangedProcess: ProcessMonitorServiceProcess;
    public toggelDisableScheduleProcess: ProcessMonitorServiceProcess;

    private _deviceBarchartLayout: any = {
        autosize: false,
        height: 156,
        margin: {
            t: 10,
            r: 10,
            b: 10,
            l: 10,
            pad: 10,
        },
        xaxis: {
            // showticklabels: false,
            autorange: true,
            fixedrange: true,
        },
        yaxis: {
            showticklabels: false,
            autorange: false,
            fixedrange: true,
            range: [0, 105],
        },
    };
    private _deviceBarchartOptions: any;
    private _deviceCoverageBarchartData: any;
    private _deviceSuccessRateBarchartData: any;
    private _lastScheduleResultId: number;
    private _dataPollingEvent: DataPollingEvent;
    private _scheduleDeviceResults: ScheduleDeviceResultModel[];

    public constructor(
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _breadCrumbService: BreadCrumbService,
        private readonly _dataPollingService: DataPollingService,
        private readonly _dialog: MatDialog,
        private readonly _eventsService: EventsService,
        private readonly _navBarService: NavBarActionService,
        private readonly _router: Router,
        private readonly _scheduleService: ScheduleService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsSchedulesViewComponent.className, this.loadDataProcessText);
        this.deleteScheduleProcess = this.processMonitorService.getProcess(SettingsSchedulesViewComponent.className, 'Deleting schedule');
        this.toggelDisableScheduleProcess = this.processMonitorService.getProcess(SettingsSchedulesViewComponent.className, 'Setting schedule disabled state');
        this.getPageResultsProcess = this.processMonitorService.getProcess(SettingsSchedulesViewComponent.className, 'Getting page of results');
        this.editProcess = this.processMonitorService.getProcess(SettingsSchedulesViewComponent.className, 'Edit schedule');
        this.crumbsChangedProcess = this.processMonitorService.getProcess(SettingsSchedulesViewComponent.className, 'Breadcrumbs changed');
        this.paramsChangeProcess = this.processMonitorService.getProcess(SettingsSchedulesViewComponent.className, 'Route params changed');
        this.queryPramsChangeProcess = this.processMonitorService.getProcess(SettingsSchedulesViewComponent.className, 'Query params changed');
        this.dataPollingProcess = this.processMonitorService.getProcess(SettingsSchedulesViewComponent.className, 'Data polling');
        this.schedulesChangedProcess = this.processMonitorService.getProcess(SettingsSchedulesViewComponent.className, 'Schedules changed');

        this._dataPollingEvent = new DataPollingEvent('SettingsSchedulesViewComponent', 0, 10000, this.loadDataProcess);

        this._deviceBarchartOptions = this.configurationService.plotlyOptions.defaultOptions;
        this._deviceBarchartOptions.modeBarButtonsToRemove.push('zoom2d');
        this._deviceBarchartOptions.modeBarButtonsToRemove.push('pan2d');
        this._deviceBarchartOptions.modeBarButtonsToRemove.push('select2d');
        this._deviceBarchartOptions.modeBarButtonsToRemove.push('lasso2d');
        this._deviceBarchartOptions.modeBarButtonsToRemove.push('zoomIn2d');
        this._deviceBarchartOptions.modeBarButtonsToRemove.push('zoomOut2d');
        this._deviceBarchartOptions.modeBarButtonsToRemove.push('autoScale2d');
        this._deviceBarchartOptions.modeBarButtonsToRemove.push('resetScale2d');
        this._deviceBarchartOptions.modeBarButtonsToRemove.push('hoverClosestCartesian');
        this._deviceBarchartOptions.modeBarButtonsToRemove.push('hoverCompareCartesian');
        this._deviceBarchartOptions.modeBarButtonsToRemove.push('toggleSpikelines');

        this.disableAction = new NavBarAction();
        this.disableAction.name = 'disableschedule';
        this.disableAction.text = 'Disable Schedule';
        this.addSubscription(this.observableHandlerBase(this.disableAction.onButtonClick, this.toggelDisableScheduleProcess).subscribe(() => { this.toggelDisable(); }), this.toggelDisableScheduleProcess);

        this.editAction = new NavBarAction();
        this.editAction.name = 'editschedule';
        this.editAction.text = 'Edit Schedule';
        this.addSubscription(this.observableHandlerBase(this.editAction.onButtonClick, this.editProcess).subscribe(() => { this.edit(); }), this.editProcess);

        this.deleteAction = new NavBarAction();
        this.deleteAction.name = 'deleteschedule';
        this.deleteAction.text = 'Delete Schedule';
        this.addSubscription(this.observableHandlerBase(this.deleteAction.onButtonClick, this.deleteScheduleProcess).subscribe(() => { this.delete(); }), this.deleteScheduleProcess);

        this.addSubscription(this.observableHandlerBase(this._breadCrumbService.onCrumbsChanged, this.crumbsChangedProcess).subscribe(() => {
            this.subscribeToSettingsSchedulesBreadCrumbClicked();
        }), this.crumbsChangedProcess);

        this.subscribeToSettingsSchedulesBreadCrumbClicked();

        this.addSubscription(this.observableHandlerBase(this._activatedRoute.params, this.paramsChangeProcess).subscribe(params => {
            this.scheduleId = params.scheduleId;
            this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
        }), this.paramsChangeProcess);

        this.addSubscription(this.observableHandlerBase(this._activatedRoute.queryParams, this.queryPramsChangeProcess).subscribe(params => {
            this.returnPath = params.returnPath;
            this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
        }), this.queryPramsChangeProcess);

        this.addSubscription(this.observableHandlerBase(this._eventsService.onSchedulesChanged, this.schedulesChangedProcess).subscribe(() => {
            this._dataPollingService.pushBack(this._dataPollingEvent);
            this.loadDataStartBase(this);
        }), this.schedulesChangedProcess);

        this.startDataPolling();
    }

    public dataExportGetData = (displayedColumn: string, data: ScheduleResultModel) => {
        switch (displayedColumn) {
            case 'date':
                return data.executedText;
            case 'success':
                return this.schedule.isNA === true ? 'N/A' : data.successRatePercentage;
            case 'coverage':
                return this.schedule.isNA === true ? 'N/A' : data.coveragePercentage;
            case 'duration':
                return data.executionTimeText;
            default:
                return data[displayedColumn];
        }
    };

    public dataExportGetHeader = (displayedColumn: string) => displayedColumn;

    public buildDeviceCoverageBarchart(): void {
        this._deviceCoverageBarchartData = [
            {
                x: Array.from(Array(this.resultsPageOptions.resultsPerPage), (d, i) => i),
                y: Array.from(Array(this.resultsPageOptions.resultsPerPage), (d, i) => 0),
                fill: 'tonexty',
                line: { simplify: false },
            }
        ];

        Plotly.newPlot('device-coverage-barchart', this._deviceCoverageBarchartData, this._deviceBarchartLayout, this._deviceBarchartOptions).then(
            element => {
                element.on('plotly_hover', function(data) {
                    const length = data.points.length;
                    for (let i = 0; i < length; i++) {
                        if (!this.isNullOrUndefined(this.scheduleResults)) {
                            const result = this.scheduleResults[data.points[i].x];
                            if (!this.isNullOrUndefined(result)) {
                                this.hoverScheduleResult = result;
                                break;
                            }
                        }
                    }
                }.bind(this));

                element.on('plotly_unhover', function(data) {
                    this.hoverScheduleResult = null;
                }.bind(this));
            },
            () => { },
        );
    }

    public buildDeviceSuccessRateBarchart(): void {
        this._deviceSuccessRateBarchartData = [
            {
                x: Array.from(Array(this.resultsPageOptions.resultsPerPage), (d, i) => i),
                y: Array.from(Array(this.resultsPageOptions.resultsPerPage), (d, i) => 0),
                fill: 'tonexty',
                line: { simplify: false },
            }
        ];

        Plotly.newPlot('device-success-rate-barchart', this._deviceSuccessRateBarchartData, this._deviceBarchartLayout, this._deviceBarchartOptions).then(element => {
            element.on('plotly_hover', function(data) {
                const length = data.points.length;
                for (let i = 0; i < length; i++) {
                    if (!this.isNullOrUndefined(this.scheduleResults)) {
                        const result = this.scheduleResults[data.points[i].x];
                        if (!this.isNullOrUndefined(result)) {
                            this.hoverScheduleResult = result;
                            break;
                        }
                    }
                }
            }.bind(this));

            element.on('plotly_unhover', function(data) {
                this.hoverScheduleResult = null;
            }.bind(this));
        });
    }

    public delete(): void {
        const dialogRef = this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData(`Delete this schedule`, `Are you sure you want to delete this schedule?`) });

        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.deleteScheduleProcess).subscribe((result: OkCancelDialogResult) => {
            if (!this.isNullOrUndefined(result) && result.ok === true) {
                this.addSubscription(this.observableHandlerBase(this._scheduleService.delete(this.schedule.id, this.deleteScheduleProcess), this.deleteScheduleProcess).subscribe(
                    () => {
                        this._eventsService.changedSchedules();
                        this._router.navigateByUrl(this.returnPath);
                    }
                ), this.deleteScheduleProcess);
            }
        }), this.deleteScheduleProcess);
    }

    public edit(): void {
        this._dialog.open(SettingsSchedulesAddEditComponent, { data: new SchedulesAddEditDialogData('edit', this.schedule.id), disableClose: true, maxWidth: 850 });
    }

    public getResultsPage(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._scheduleService.getScheduleOverviewResults(this.scheduleId, this.resultsPageOptions, process).pipe(
            map(result => {
                this.scheduleResults = result;

                this.resultsDataSource.data = this.scheduleResults.items;

                this.updateDeviceSuccessRateBarchart();
                this.updateDeviceCoverageBarchart();

                return true;
            })
        );
    }

    public getScheduleDeviceResults(scheduleResultId: number): Observable<Array<ScheduleDeviceResultModel>> {
        if (this.isNullOrUndefined(this._scheduleDeviceResults)) {
            return this._scheduleService.getScheduleDeviceResults(this.scheduleId, scheduleResultId).pipe(
                map(deviceResults => {
                    this._scheduleDeviceResults = deviceResults.items;
                    return this._scheduleDeviceResults;
                })
            );
        } else {
            return of(this._scheduleDeviceResults);
        }
    }

    public getSerialTypes(scheduleResultId: number, resultType: DeviceResultTypeEnum): Observable<Array<string>> {
        return this.getScheduleDeviceResults(scheduleResultId).pipe(
            map(deviceResults => deviceResults.filter(i => !isNullOrUndefined(i) && !isNullOrUndefined(i.device) && i.type === resultType).map(i => i.device.friendlySerial))
        );
    }

    public getWorkflows(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._scheduleService.getWorkflows(process).pipe(
            map(WorkflowsResult => {
                this.schedule.workflow = WorkflowsResult.items.find(i => i.workflowName === this.schedule.name);
                return true;
            })
        );
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._scheduleService.getScheduleOverview(this.scheduleId, process).pipe(
                flatMap(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.schedule = result;

                        if (!this.schedule.isUserSchedule) {
                            this.editAction.disabled = true;
                            this.deleteAction.disabled = true;
                        }

                        if (this.schedule.isDisabled) {
                            this.disableAction.text = 'Enable Schedule';
                        } else {
                            this.disableAction.text = 'Disable Schedule';
                        }

                        if (this.schedule.isFinished) {
                            this.editAction.disabled = true;
                            this.disableAction.disabled = true;
                            this.deleteAction.disabled = false;
                        }

                        this.resultsPageOptions.totalResults = this.schedule.numScheduleResults;

                        const scheduleSub = zip(
                            this.getResultsPage(process),
                            this.getWorkflows(process),
                        );

                        return scheduleSub.pipe(
                            map(scheduleSubResult => this.isZipResultSuccess(scheduleSubResult))
                        );
                    }
                    return of(true);
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this.stopDataPolling();

        this._navBarService.removeAction(this.disableAction);
        this._navBarService.removeAction(this.editAction);
        this._navBarService.removeAction(this.deleteAction);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.userCurrentService.isSystemAdmin.subscribe(isSystemAdmin => {
            if (isSystemAdmin === true) {
                this._navBarService.addAction(this.disableAction);
                this._navBarService.addAction(this.editAction);
                this._navBarService.addAction(this.deleteAction);
            }
        });

        this.buildDeviceSuccessRateBarchart();
        this.buildDeviceCoverageBarchart();
    }

    public onDeviceCoverageResized(event: ResizedEvent): void {
        Plotly.relayout('device-coverage-barchart', { width: event.newWidth - 32 });
    }

    public onDeviceSuccessRateResized(event: ResizedEvent): void {
        Plotly.relayout('device-success-rate-barchart', { width: event.newWidth - 32 });
    }

    public onPageChanged(event: PageEvent): void {
        this.resultsPageOptions.page = (event.pageIndex + 1);
        this.addSubscription(this.observableHandlerBase(this.getResultsPage(), this.getPageResultsProcess).subscribe(), this.getPageResultsProcess);
    }

    public onRowClicked(event: ITableRowClicked<ScheduleResultModel>): void {
        const scheduleResult = event.data;
        this.expandedScheduleResult = !this.isNullOrUndefined(this.expandedScheduleResult) && this.expandedScheduleResult.scheduleResultId === scheduleResult.scheduleResultId ? null : scheduleResult;
        if (!this.isNullOrUndefined(this.expandedScheduleResult) && this._lastScheduleResultId !== this.expandedScheduleResult.scheduleResultId) {
            this._scheduleDeviceResults = null;
            this._lastScheduleResultId = this.expandedScheduleResult.scheduleResultId;
            this.getSerialTypes(this.expandedScheduleResult.scheduleResultId, DeviceResultTypeEnum.error).subscribe(i => this.deviceResultsError = i);
            this.getSerialTypes(this.expandedScheduleResult.scheduleResultId, DeviceResultTypeEnum.invalid).subscribe(i => this.deviceResultsInvalid = i);
            this.getSerialTypes(this.expandedScheduleResult.scheduleResultId, DeviceResultTypeEnum.notPresent).subscribe(i => this.deviceResultsNotPresent = i);
            this.getSerialTypes(this.expandedScheduleResult.scheduleResultId, DeviceResultTypeEnum.placeholder).subscribe(i => this.deviceResultsPlaceholder = i);
            this.getSerialTypes(this.expandedScheduleResult.scheduleResultId, DeviceResultTypeEnum.success).subscribe(i => this.deviceResultsSuccess = i);
            this.getSerialTypes(this.expandedScheduleResult.scheduleResultId, DeviceResultTypeEnum.warning).subscribe(i => this.deviceResultsWarning = i);
        }
    }

    public subscribeToSettingsSchedulesBreadCrumbClicked(): void {
        const schedulesBreadCrumb = this._breadCrumbService.getBreadCrumbByName('em-settings-schedules');
        if (!this.isNullOrUndefined(schedulesBreadCrumb)) {
            this.addSubscription(this.observableHandlerBase(schedulesBreadCrumb.clicked, this.crumbsChangedProcess).subscribe(() => {
                this._router.navigateByUrl(this.returnPath);
            }), this.crumbsChangedProcess);
        }
    }

    public toggelDisable(): void {
        const dialogRef = this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData(`${this.schedule.isDisabled === true ? 'Enable' : 'Disable'} this schedule`, `Are you sure you want to ${this.schedule.isDisabled === true ? 'enable' : 'disable'} this schedule?`) });

        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.toggelDisableScheduleProcess).subscribe((result: OkCancelDialogResult) => {
            if (!this.isNullOrUndefined(result) && result.ok === true) {
                this.addSubscription(this.observableHandlerBase(this._scheduleService.setEnabledState(this.schedule.id, this.schedule.isDisabled, this.toggelDisableScheduleProcess), this.toggelDisableScheduleProcess).subscribe(
                    () => {
                        this._eventsService.changedSchedules();
                    }
                ), this.toggelDisableScheduleProcess);
            }
        }), this.toggelDisableScheduleProcess);
    }

    public updateDeviceCoverageBarchart(): void {
        this._deviceCoverageBarchartData[0].y = [];
        this._deviceCoverageBarchartData[0].x = [];

        const length = this.scheduleResults.items.length;
        for (let i = 0; i < length; i++) {
            const result = this.scheduleResults.items[i];
            this._deviceCoverageBarchartData[0].y.push(result.coveragePercentage);
            this._deviceCoverageBarchartData[0].x.push(i);
        }

        Plotly.animate('device-coverage-barchart',
            {
                data: this._deviceCoverageBarchartData,
                layout: this._deviceBarchartLayout,
            },
            {
                transition: {
                    duration: 500,
                    traces: [0],
                    easing: 'cubic-in-out',
                }
            });
    }

    public updateDeviceSuccessRateBarchart(): void {
        this._deviceSuccessRateBarchartData[0].y = [];
        this._deviceSuccessRateBarchartData[0].x = [];

        const length = this.scheduleResults.items.length;
        for (let i = 0; i < length; i++) {
            const result = this.scheduleResults.items[i];
            this._deviceSuccessRateBarchartData[0].y.push(result.successRatePercentage);
            this._deviceSuccessRateBarchartData[0].x.push(i);
        }

        Plotly.animate('device-success-rate-barchart',
            {
                data: this._deviceSuccessRateBarchartData,
                traces: [0],
                layout: this._deviceBarchartLayout,
            },
            {
                transition: {
                    duration: 500,
                    easing: 'cubic-in-out',
                }
            });
    }

    private subDataPolling(): void {
        this.addSubscription(this.observableHandlerBase(this._dataPollingEvent.poll, this.dataPollingProcess).subscribe(() => {
            this._scheduleService.clearCache();
            this.loadDataStartBase(this);
        }), this.dataPollingProcess);
    }

    private startDataPolling(): void {
        this.subDataPolling();
        this._dataPollingService.startEvent(this._dataPollingEvent);

    }

    private stopDataPolling(): void {
        this._dataPollingService.stopEvent(this._dataPollingEvent);
    }
}
