import { Injector, Input, Directive } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { RegisterTypeViewModel } from '@rift/components/settings/counting/viewmodels/RegisterType.ViewModel';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { PolygonModel } from '@rift/models/restapi/Polygon.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { MatDialog } from '@angular/material/dialog';

@Directive()
export abstract class SettingsCountingBaseComponent extends RiftBaseComponent {
    public static className: string = 'SettingsCountingBaseComponent';

    @Input()
    public lines: Array<LineModel> = null;

    @Input()
    public polygons: Array<PolygonModel> = null;

    @Input()
    public registerTypeViewModel: RegisterTypeViewModel = null;

    @Input()
    public registers: Array<RegisterBaseModel> = null;

    public constructor(
        private readonly _settBaseInjector: Injector,
        private readonly _dialogCounting?: MatDialog) {
        super(_settBaseInjector, _dialogCounting);
    }

    public abstract get hasChanges(): boolean;
}
