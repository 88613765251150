import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export class PhysicalNetworkChangeModel extends BaseModel implements IWebSocketModel {
    public static readonly expectedPacketType: string = 'physical_network_change';

    public readonly isIWebSocketModel: boolean = true;
    public packetType: string = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-PhysicalNetworkChangeModel');
    }

    public toWebSocketMessage(): void {
        throw new Error('Method not implemented.');
    }
}
