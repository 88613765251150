import { Grid } from '@rift/components/shared/viewport/grid/Grid';
import { PointModel } from '@rift/models/restapi/Point.Model';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class ViewPortHelpers {
    public static devicePointToViewPortPoint(point: createjs.Point | PointModel | { x: number; y: number }): createjs.Point {
        let deviceX: number = null;
        let deviceY: number = null;

        if (point instanceof createjs.Point) {
            deviceX = point.x;
            deviceY = point.y;
        }
        if (point instanceof PointModel) {
            deviceX = point.x;
            deviceY = point.y;
        }
        if (!isNullOrUndefined(point.x) && !isNullOrUndefined(point.y)) {
            deviceX = point.x;
            deviceY = point.y;
        }

        return new createjs.Point(
            (Grid.Width / 2) + deviceX,
            (Grid.Height / 2) + (deviceY * -1),
        );
    }

    public static viewPortPointToDevicePoint(point: createjs.Point | createjs.DisplayObject | { x: number; y: number }): createjs.Point {
        let viewPortX: number = null;
        let viewPortY: number = null;

        if (point instanceof createjs.Point) {
            viewPortX = point.x;
            viewPortY = point.y;
        }
        if (point instanceof createjs.DisplayObject) {
            viewPortX = point.x;
            viewPortY = point.y;
        }
        if (!isNullOrUndefined(point.x) && !isNullOrUndefined(point.y)) {
            viewPortX = point.x;
            viewPortY = point.y;
        }

        return new createjs.Point(
            viewPortX - (Grid.Width / 2),
            (viewPortY - (Grid.Height / 2)) * -1,
        );
    }
}
