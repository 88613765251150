import { Component, HostBinding, HostListener, Inject, Injector } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { ChangeUserPasswordModel } from '@shared/models/restapi/ChangeUserPassword.Model';
import { UserPasswordModel } from '@shared/models/restapi/UserPassword.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export class MyAccountChangePasswordData {
    public constructor(public readonly allowCancel: boolean = true, public readonly title: string, public readonly subTitle: string) {

    }
}

@Component({
    selector: 'em-my-account-change-password',
    templateUrl: './MyAccount.ChangePassword.Component.html',
    styleUrls: ['./MyAccount.ChangePassword.Component.scss']
})
export class MyAccountChangePasswordComponent extends BaseComponent implements ISaveAllChanges {
    public static className: string = 'MyAccountChangePasswordComponent';

    public changePasswordForm: FormGroup;
    public changeUserPassword: ChangeUserPasswordModel;
    public formValueChangesProcess: ProcessMonitorServiceProcess;
    public result: UserPasswordModel;

    @HostBinding()
    public id: string = 'em-my-account-change-password';

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: MyAccountChangePasswordData,
        private readonly _dialog: MatDialog,
        private readonly _userCurrentService: UserCurrentService,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialogRef: MatDialogRef<MyAccountChangePasswordComponent>,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.saveAllChangesProcess = this.processMonitorService.getProcess(MyAccountChangePasswordComponent.className, this.saveAllChangesProcessText);
        this.formValueChangesProcess = this.processMonitorService.getProcess(MyAccountChangePasswordComponent.className, 'Form group values change.');

        this.changeUserPassword = new ChangeUserPasswordModel();
        this.changeTracker.track(this.changeUserPassword);

        this.changePasswordForm = this._formBuilder.group(
            {
                oldPassword: ['', Validators.compose([Validators.required])],
                newPassword: ['', Validators.compose([Validators.required, Validators.minLength(8), ValidationValidators.password])],
                confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(8), ValidationValidators.password])]
            },
            {
                validator: ValidationValidators.passwordMatch('newPassword', 'confirmPassword', 'Passwords do not match')
            } as AbstractControlOptions
        );
        this.formGroupTracker.track(this.changePasswordForm);

        this.addSubscription(this.observableHandlerBase(this.changePasswordForm.valueChanges, this.formValueChangesProcess).subscribe(() => {
            this.setModelValuesFromFormGroup();
        }), this.formValueChangesProcess);

        this.setFormValues();
    }

    public cancel(): void {
        this._dialogRef.close();
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public save(): void {
        this.saveAllChangesStartBase(this, this.openPleaseWaitSavingDialog());
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            this._userCurrentService.changePassword(this.changeUserPassword, process).pipe(
                map(result => {
                    this.result = result;
                    return true;
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            tap(() => {
                if (this.result.changed === true) {
                    this._dialogRef.close();
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this);
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    private setFormValues(): void {
        this.changePasswordForm.setValue({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        });
    }

    private setModelValuesFromFormGroup(): void {
        const formModel = this.changePasswordForm.value;

        this.changeUserPassword.oldPassword = formModel.oldPassword;
        this.changeUserPassword.newPassword = formModel.newPassword;
    }
}
