import { CollectionModel, ICollectionModel } from '@shared/models/restapi/Collection.Model';
import { RegisterActionInfoModel } from './RegisterActionInfo.Model';

export interface IRegisterActionInfoCollectionModel extends ICollectionModel<RegisterActionInfoModel> {

}

export class RegisterActionInfoCollectionModel extends CollectionModel<RegisterActionInfoModel> implements IRegisterActionInfoCollectionModel {
    public constructor() {
        super(RegisterActionInfoModel);
    }
}
