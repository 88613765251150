import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountLogConfigModel } from '@rift/models/restapi/CountLogConfig.Model';
import { CountLogPageModel } from '@rift/models/restapi/CountLogPage.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class RestApiCountsService extends RiftRestApiService {
    private _controller = 'counts';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public reset(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put(`${this._controller}/reset`, null, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getConfig(process?: ProcessMonitorServiceProcess): Observable<CountLogConfigModel> {
        return this.get<CountLogConfigModel>(`${this._controller}/config`, CountLogConfigModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getCountLogPage(nextIndex?: number, newestFirst?: boolean, process?: ProcessMonitorServiceProcess): Observable<CountLogPageModel> {
        let params = this.getTokenParams();

        if (!isNullOrUndefined(nextIndex)) {
            params = params.append('nextIndex', nextIndex.toString());
        }
        if (!isNullOrUndefined(newestFirst)) {
            params = params.append('newestFirst', newestFirst.toString());
        }

        return this.get<CountLogPageModel>(`${this._controller}/countspage`, CountLogPageModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setConfig(config: CountLogConfigModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<CountLogConfigModel, ResultModel>(`${this._controller}/config`, config, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
