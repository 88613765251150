<div #mainContent id="rift-counts"
    class="main-content scroll-content">
    <div fxLayout="column">
        <mat-card shrResized
            (resized)="onCountsLineChartResized($event)">
            <mat-card-title>
                <div fxLayout="row">
                    <div fxLayout="column">
                        <div>
                            Device Counts
                        </div>
                        <div class="timezone-text">
                            Time Zone ({{ isNullOrUndefined(timeSetup) ? '' : timeSetup.timeZone.timeOffsetShortText }})
                        </div>
                        <div *ngIf="loadCountsProcess.isRunning"
                            class="loading-in-background-text text-pulsate">
                            Data Loading In Background Please Wait...
                        </div>
                    </div>
                </div>
            </mat-card-title>
            <mat-card-content>
                <div id="counts-linechart"
                    fxFlex>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
