<div shrResized (resized)="onResized()" class="mat-tab-nav-bar-pagination">
    <div [class.disabled]="leftDisabled" *ngIf="showScrollButtons" (click)="left()"
        class="mat-tab-header-pagination mat-tab-header-pagination-before mat-ripple" mat-ripple="">
        <div [class.disabled]="leftDisabled" class="mat-tab-header-pagination-chevron"></div>
    </div>
    <div class="mat-tab-label-container" #labelContainer>
        <div class="mat-tab-nav-bar mat-tab-header" style="flex-grow: 1"></div>
        <ng-content></ng-content>
        <div class="mat-tab-nav-bar mat-tab-header" style="flex-grow: 1"></div>
    </div>
    <div [class.disabled]="rightDisabled" *ngIf="showScrollButtons" (click)="right()"
        class="mat-tab-header-pagination mat-tab-header-pagination-after mat-ripple" mat-ripple="">
        <div [class.disabled]="rightDisabled" class="mat-tab-header-pagination-chevron"></div>
    </div>
</div>
