import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { AfterContentInit, AfterViewInit, Component, ContentChild, ContentChildren, EventEmitter, HostBinding, Injector, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatColumnDef, MatHeaderRowDef, MatRowDef, MatTable, MatTableDataSource, MatHeaderCellDef, MatHeaderCell, MatRow } from '@angular/material/table';
import { BaseComponent } from '@shared/base/Base.Component';
import { IUniqueIdentity } from '@shared/interface/IUniqueIdentity';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import * as FileSaver from 'file-saver';
import { isString, isNumber, isFunction, isNullOrUndefined } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { FileUtility } from '@shared/utility/File.Utility';

export const ActionsColumnName: string = 'actions';

export interface ITableRowClicked<T> {
    event: MouseEvent;
    data: T;
}

/**
 * Data table component.
 *
 * @export
 * @class TableComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {AfterContentInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 * @template T
 */
@Component({
    selector: 'shr-table',
    templateUrl: './Table.Component.html'
})
export class TableComponent<T extends IUniqueIdentity> extends BaseComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {

    @HostBinding()
    public id: string = 'shr-table';

    /**
     * The id to prefix
     *
     * @type {string}
     * @memberof TableComponent
     */
    @Input()
    public idPreFix: string = '';

    /**
     * The table title.
     *
     * @type {string}
     * @memberof TableComponent
     */
    @Input()
    public title: string;

    /**
     * The root class to apply to the mat-card.
     *
     * @type {string}
     * @memberof TableComponent
     */
    @Input()
    public class: string;

    /**
     * Class to add to each row.
     *
     * @type {string}
     * @memberof TableComponent
     */
    @Input()
    public rowClass: string;

    /**
     * The row header class.
     *
     * @type {string}
     * @memberof TableComponent
     */
    @Input()
    public rowHeaderClass: string;

    /**
     * The devices time setup.
     *
     * @readonly
     * @type {TimeSetupModel}
     * @memberof TableComponent
     */
    @Input()
    public get timeSetup(): TimeSetupModel {
        return this._timeSetup;
    }
    public set timeSetup(value: TimeSetupModel) {
        this._timeSetup = value;
    }

    /**
     * If true the rows style will change on hover.
     *
     * @readonly
     * @type {boolean}
     * @memberof TableComponent
     */
    @Input()
    public get rowMouseHover(): boolean {
        return this._rowMouseHover;
    }
    public set rowMouseHover(value: boolean) {
        this._rowMouseHover = coerceBooleanProperty(value);
    }

    /**
     * If true the mat-card will be hidden.
     *
     * @readonly
     * @type {boolean}
     * @memberof TableComponent
     */
    @Input()
    public get hideMatCard(): boolean {
        return this._hideMatCard;
    }
    public set hideMatCard(value: boolean) {
        this._hideMatCard = coerceBooleanProperty(value);
    }

    /**
     * If true the actions column will be shown.
     *
     * @readonly
     * @type {boolean}
     * @memberof TableComponent
     */
    @Input()
    public get actionsEnabled(): boolean {
        return this._actionsEnabled;
    }
    public set actionsEnabled(value: boolean) {
        this._actionsEnabled = coerceBooleanProperty(value);
        this.setDisplayedColumns(this.displayedColumns);
    }

    // Data source inputs
    /**
     * The columns to show.
     *
     * @readonly
     * @type {Array<string>}
     * @memberof TableComponent
     */
    @Input()
    public get displayedColumns(): Array<string> {
        return this._displayedColumns;
    }
    public set displayedColumns(value: Array<string>) {
        this.setDisplayedColumns(value);
    }

    /**
     * The data source to bind to the table.
     *
     * @readonly
     * @type {MatTableDataSource<any>}
     * @memberof TableComponent
     */
    @Input()
    public get dataSource(): MatTableDataSource<any> {
        return this._dataSource;
    }
    public set dataSource(value: MatTableDataSource<any>) {
        this._dataSource = value;
    }

    // Paging inputs
    /**
     * If true pagination is enabled.
     *
     * @readonly
     * @type {boolean}
     * @memberof TableComponent
     */
    @Input()
    public get pageEnabled(): boolean {
        return this._pageEnabled;
    }
    public set pageEnabled(value: boolean) {
        this._pageEnabled = coerceBooleanProperty(value);
    }

    /**
     * The current page index.
     *
     * @readonly
     * @type {number}
     * @memberof TableComponent
     */
    @Input()
    public get pageIndex(): number {
        return this._pageIndex;
    }
    public set pageIndex(value: number) {
        this._pageIndex = coerceNumberProperty(value);
    }

    /**
     * The total count of records.
     *
     * @readonly
     * @type {number}
     * @memberof TableComponent
     */
    @Input()
    public get recordLength(): number {
        return this._recordLength;
    }
    public set recordLength(value: number) {
        this._recordLength = coerceNumberProperty(value);
    }

    /**
     * The current page size selected.
     *
     * @readonly
     * @type {number}
     * @memberof TableComponent
     */
    @Input()
    public get pageSize(): number {
        return this._pageSize;
    }
    public set pageSize(value: number) {
        this._pageSize = coerceNumberProperty(value);
    }

    /**
     * The available page size options.
     *
     * @type {Array<number>}
     * @memberof TableComponent
     */
    @Input()
    public pageSizeOptions: Array<number> = [5, 10, 15, 20, 50];

    /**
     * If true skip to fist and last pages button will be shown.
     *
     * @readonly
     * @type {boolean}
     * @memberof TableComponent
     */
    @Input()
    public get pageShowFirstLastButtons(): boolean {
        return this._pageShowFirstLastButtons;
    }
    public set pageShowFirstLastButtons(value: boolean) {
        this._pageShowFirstLastButtons = coerceBooleanProperty(value);
    }

    /**
     * True if sever side paging is enabled.
     *
     * @readonly
     * @type {boolean}
     * @memberof TableComponent
     */
    @Input()
    public get serverPage(): boolean {
        return this._serverPage;
    }
    public set serverPage(value: boolean) {
        this._serverPage = coerceBooleanProperty(value);
    }

    /**
     * True if server side sorting is enabled.
     *
     * @readonly
     * @type {boolean}
     * @memberof TableComponent
     */
    @Input()
    public get serverSort(): boolean {
        return this._serverSort;
    }
    public set serverSort(value: boolean) {
        this._serverSort = coerceBooleanProperty(value);
    }

    // Filter inputs
    /**
     * True if record filtering is enabled.
     *
     * @readonly
     * @type {boolean}
     * @memberof TableComponent
     */
    @Input()
    public get filterEnabled(): boolean {
        return this._filterEnabled;
    }
    public set filterEnabled(value: boolean) {
        this._filterEnabled = coerceBooleanProperty(value);
    }

    // Sort inputs
    /**
     * To pass mat sort directive in. *matSort #sort="matSort" [sort]="sort"
     *
     * @type {MatSort}
     * @memberof TableComponent
     */
    @Input()
    public sort: MatSort;

    // Loading inputs
    /**
     * True if data is loading in background.
     * Spinner will be shown in top right when background loading.
     *
     * @readonly
     * @type {boolean}
     * @memberof TableComponent
     */
    @Input()
    public get isLoadingInBackground(): boolean {
        return this._isLoadingInBackground;
    }
    public set isLoadingInBackground(value: boolean) {
        this._isLoadingInBackground = coerceBooleanProperty(value);
    }

    /**
     * True if data is currently loading.
     * Spinner will show hiding current rows.
     *
     * @readonly
     * @type {boolean}
     * @memberof TableComponent
     */
    @Input()
    public get isLoadingData(): boolean {
        return this._isLoadingData;
    }
    public set isLoadingData(value: boolean) {
        this._isLoadingData = coerceBooleanProperty(value);
    }

    /**
     * Triggers display of spinner in top right.
     *
     * @readonly
     * @type {boolean}
     * @memberof TableComponent
     */
    @Input()
    public get dataRefreshedTrigger(): boolean {
        return this._dataRefreshedTrigger;
    }
    public set dataRefreshedTrigger(value: boolean) {
        this._dataRefreshedTrigger = coerceBooleanProperty(value);
    }

    /**
     * Enables expandable rows
     *
     * @readonly
     * @type {boolean}
     * @memberof TableComponent
     */
    @Input()
    public get multiDataRows(): boolean {
        return this._multiDataRows;
    }
    public set multiDataRows(value: boolean) {
        this._multiDataRows = coerceBooleanProperty(value);
    }

    @Input()
    public get dataExportEnabled(): boolean {
        return this._dataExportEnabled;
    }
    public set dataExportEnabled(value: boolean) {
        this._dataExportEnabled = value;
    }

    @Input()
    public get dataExportFileName(): string {
        return this._dataExportFileName;
    }
    public set dataExportFileName(value: string) {
        this._dataExportFileName = value;
    }

    @Input()
    public get dataExportGetDataHandler(): (displayedColumn: string, data: T) => string | number {
        return this._dataExportGetDataHandler;
    }
    public set dataExportGetDataHandler(value: (displayedColumn: string, data: T) => string | number) {
        this._dataExportGetDataHandler = value;
    }

    @Input()
    public get dataExportGetHeaderHandler(): (displayedColumn: string) => string {
        return this._dataExportGetHeaderHandler;
    }
    public set dataExportGetHeaderHandler(value: (displayedColumn: string) => string) {
        this._dataExportGetHeaderHandler = value;
    }

    /**
     * Fires when page action requires reload.
     *
     * @type {EventEmitter<PageEvent>}
     * @memberof TableComponent
     */
    @Output()
    public page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

    /**
     * Fires when filter value updated.
     *
     * @type {EventEmitter<string>}
     * @memberof TableComponent
     */
    @Output()
    public filter: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Fires when row clicked.
     *
     * @type {EventEmitter<T>}
     * @memberof TableComponent
     */
    @Output()
    public rowClicked: EventEmitter<ITableRowClicked<T>> = new EventEmitter<ITableRowClicked<T>>();

    @ContentChildren(MatHeaderRowDef)
    public headerRowDefs: QueryList<MatHeaderRowDef>;

    @ContentChildren(MatRowDef)
    public rowDefs: QueryList<MatRowDef<T>>;

    @ContentChildren(MatColumnDef)
    public columnDefs: QueryList<MatColumnDef>;

    @ContentChild('shrTableHeaderPreFilterActions', { static: true })
    public get shrTableHeaderPreFilterActions(): TemplateRef<any> {
        return this._shrTableHeaderPreFilterActions;
    }
    public set shrTableHeaderPreFilterActions(value: TemplateRef<any>) {
        this._shrTableHeaderPreFilterActions = value;
    }

    @ContentChild('shrTableHeader', { static: true })
    public get shrTableHeader(): TemplateRef<any> {
        return this._shrTableHeader;
    }
    public set shrTableHeader(value: TemplateRef<any>) {
        this._shrTableHeader = value;
    }

    @ContentChild('shrTableHeaderActions', { static: true })
    public get shrTableHeaderActions(): TemplateRef<any> {
        return this._shrTableHeaderActions;
    }
    public set shrTableHeaderActions(value: TemplateRef<any>) {
        this._shrTableHeaderActions = value;
    }

    @ContentChild('shrTableActions', { static: true })
    public get shrTableActions(): TemplateRef<any> {
        return this._shrTableActions;
    }
    public set shrTableActions(value: TemplateRef<any>) {
        this._shrTableActions = value;
    }

    @ViewChild(MatTable, { static: true })
    public table: MatTable<T>;

    @ViewChild(MatPaginator, { static: false })
    public paginator: MatPaginator;

    private _actionsEnabled: boolean = true;
    private _dataRefreshedTrigger: boolean = false;
    private _dataSource: MatTableDataSource<any>;
    private _displayedColumns: Array<string> = [ActionsColumnName];
    private _filterEnabled: boolean = true;
    private _hideMatCard: boolean = false;
    private _isLoadingData: boolean = false;
    private _isLoadingInBackground: boolean = false;
    private _pageEnabled: boolean = true;
    private _pageIndex: number;
    private _pageShowFirstLastButtons: boolean = true;
    private _pageSize: number = 10;
    private _recordLength: number;
    private _rowMouseHover: boolean = false;
    private _serverPage: boolean = false;
    private _serverSort: boolean = false;
    private _shrTableActions: TemplateRef<any>;
    private _shrTableHeader: TemplateRef<any>;
    private _shrTableHeaderActions: TemplateRef<any>;
    private _shrTableHeaderPreFilterActions: TemplateRef<any>;
    private _timeSetup: TimeSetupModel;
    private _multiDataRows: boolean = false;
    private _dataExportEnabled: boolean = true;
    private _dataExportFileName: string = null;
    private _dataExportGetDataHandler: (displayedColumn: string | number, data: T) => any = null;
    private _dataExportGetHeaderHandler: (displayedColumn: string) => any = null;

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
    }

    public ngOnInit(): void {
        if (this.serverSort === false && !this.isNullOrUndefined(this.sort)) {
            this.dataSource.sort = this.sort;
        }
    }

    public ngAfterContentInit(): void {
        if (!this.isNullOrUndefined(this.table)) {
            this.columnDefs.changes.subscribe((queryList: QueryList<MatColumnDef>) => {
                queryList.forEach(columnDef => this.table.addColumnDef(columnDef));
            });

            this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
            this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
            this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));

            this.setDisplayedColumns(this.displayedColumns);
        }
    }

    public ngAfterViewInit(): void {
        if (this.serverPage === false && !this.isNullOrUndefined(this.paginator)) {
            this.dataSource.paginator = this.paginator;
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public setDisplayedColumns(value: Array<string>): void {
        const displayedColumns = value.filter(i => i !== ActionsColumnName);
        if (this._actionsEnabled === true && !this.isNullOrUndefined(this.shrTableActions)) {
            displayedColumns.splice(0, 0, ActionsColumnName);
        }
        this._displayedColumns = displayedColumns;
    }

    public onRowClicked(event: MouseEvent, data: T): void {
        this.rowClicked.emit({ event, data });
    }

    public onFilterUpdated(event: KeyboardEvent){
        const filterValue: HTMLInputElement = event.target as HTMLInputElement;

        this.filter.emit(filterValue.value);
    }

    public downloadToCSV(): void {
        if (!this.isNullOrUndefined(this.dataExportEnabled)) {
            let csvHeader = '';
            let csvData = '';

            if (!this.isNullOrUndefined(this.dataExportGetHeaderHandler)) {
                this.displayedColumns.forEach((displayedColumn) => {
                    if (!this.isNullOrUndefined(displayedColumn) && displayedColumn !== 'actions') {
                        csvHeader += `${this.dataExportGetHeaderHandler(displayedColumn).toLocaleLowerCase()},`;
                    }
                });
            } else {
                this.displayedColumns.forEach((displayedColumn) => {
                    if (!this.isNullOrUndefined(displayedColumn) && displayedColumn !== 'actions') {
                        csvHeader += `${displayedColumn.toLocaleLowerCase()},`;
                    }
                });
            }
            csvHeader += '\n';

            this.dataSource.data.forEach((data) => {
                if (!this.isNullOrUndefined(data)) {
                    this.displayedColumns.forEach((displayedColumn) => {
                        if (!this.isNullOrUndefined(displayedColumn) && displayedColumn !== 'actions') {
                            let value = null;
                            if (!this.isNullOrUndefined(this.dataExportGetDataHandler)) {
                                value = this.dataExportGetDataHandler(displayedColumn, data);
                            }

                            if (this.isNullOrUndefined(this.dataExportGetDataHandler) || this.isNull(value)) {
                                value = data[displayedColumn];
                            }

                            if (isString(value)) {
                                csvData += `"${StringUtility.replace(value, /"/g, '""')}",`;
                            } else if (isNumber(value)) {
                                csvData += `${value},`;
                            } else {
                                csvData += `,`;
                            }
                        }
                    });
                    csvData += '\n';
                }
            });

            const blob = new Blob([csvHeader + csvData], { type: 'text/plain;charset=utf-8' });
            FileSaver.saveAs(blob, FileUtility.sanitize(`${this.isNullOrUndefined(this.dataExportFileName) ? this.isNullOrUndefined(this.title) ? 'data' : this.title.toLocaleLowerCase() : this.dataExportFileName}-${DateTimeUtility.toFileNameDateTime(new Date())}.csv`));
        }
    }
}
