import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class BridgeConnectionModel extends BaseModel implements IRestModel {

    public connectionInfo: string = null;
    public readonly isIRestModel = true;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-BridgeConnectionModel');
    }

    public toRestApiModel() {
        return RestModelUtility.toJson(this);
    }
}
