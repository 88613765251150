import { Component, HostBinding, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsTaskLauncherRunComponent, SettingsTaskLauncherRunDialogData } from '@em/components/settings/tasklauncher/tasklauncherrun/Settings.TaskLauncher.Run.Component';
import { DeviceModel } from '@em/models/restapi/Device.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { DeviceGroupEnum } from '@shared/enum/DeviceGroup.Enum';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined, isNumber, isNumeric } from '@shared/utility/General.Utility';
import { NumberUtility } from '@shared/utility/Number.Utility';
import { RecordingsAddScheduleComponent, AddScheduleData, AddScheduleResult } from '@rift/components/recordings/addschedule/Recordings.AddSchedule.Component';
import { ScheduleTypeEnum } from '@shared/enum/ScheduleType.Enum';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { MatDialog } from '@angular/material/dialog';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';

@Component({
    selector: 'em-device-group',
    templateUrl: './DeviceGroup.Component.html',
    styleUrls: ['./DeviceGroup.Component.scss']
})
export class DeviceGroupComponent extends BaseComponent implements OnInit, OnDestroy {
    public static className: string = 'DeviceGroupComponent';

    @HostBinding()
    public id: string = 'em-device-group';

    @LocalStorage(DeviceGroupComponent.className, 'pageOptions')
    public pageOptions: PaginationOptionsModel;

    public refreshingDevicesProcess: ProcessMonitorServiceProcess;
    public taskLauncherAction: NavBarAction;
    public addScheduleAction: NavBarAction;
    public deviceQuickLinksPollProcess: ProcessMonitorServiceProcess;
    public devices: Array<DeviceModel>;
    public getDevicesProcess: ProcessMonitorServiceProcess;
    public getGroupIdProcess: ProcessMonitorServiceProcess;
    public addScheduledProcess: ProcessMonitorServiceProcess;

    private _groupId: DeviceGroupEnum;
    private _dataPollingEvent: DataPollingEvent;

    public constructor(
        private readonly _navBarActionService: NavBarActionService,
        private readonly _dialog: MatDialog,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _dataPollingService: DataPollingService,
        private readonly _deviceService: DeviceService,
        private readonly _injector: Injector) {
        super(_injector);

        if (this.isNullOrUndefined(this.pageOptions)){
            this.pageOptions = new PaginationOptionsModel();
            this.pageOptions.page = 1;
            this.pageOptions.resultsPerPage = 10;
        }

        this.getDevicesProcess = this.processMonitorService.getProcess(DeviceGroupComponent.className, 'Getting devices in group');
        this.refreshingDevicesProcess = this.processMonitorService.getProcess(DeviceGroupComponent.className, 'Refreshing devices in group');
        this.getGroupIdProcess = this.processMonitorService.getProcess(DeviceGroupComponent.className, 'Getting group id from router');
        this.deviceQuickLinksPollProcess = this.processMonitorService.getProcess(DeviceGroupComponent.className, 'Device quick links poll');
        this.addScheduledProcess = this.processMonitorService.getProcess(DeviceGroupComponent.className, 'Add schedule');

        this._dataPollingEvent = new DataPollingEvent('DeviceGroupComponent:DeviceQuickLinks', 0, 20000, this.refreshingDevicesProcess);

        this.taskLauncherAction = new NavBarAction();
        this.taskLauncherAction.name = 'task-launcher';
        this.taskLauncherAction.text = 'Task Launcher';
        this.taskLauncherAction.faIconName = 'rocket';
        this.addSubscription(this.taskLauncherAction.onButtonClick.subscribe(() => { this.taskLauncher(); }));


        this.addScheduleAction = new NavBarAction();
        this.addScheduleAction.name = 'add-schedule';
        this.addScheduleAction.text = 'Add Schedule';
        this.addScheduleAction.faIconName = 'video';
        this.addSubscription(this.addScheduleAction.onButtonClick.subscribe(() => { this.addSchedule(); }));
    }

    public googleMapsPresent(): boolean{
        return typeof google === 'object';
    }

    public ngOnDestroy(): void {
        this.stopDataPolling();
        this.removeActionTaskLauncherAction();
        this.removeAddScheduleAction();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.addSubscription(this.observableHandlerBase(this._activatedRoute.params, this.getGroupIdProcess).subscribe(params => {
            if (isNullOrUndefined(params.groupId)) {
                this._groupId = DeviceGroupEnum.all;
            } else {
                this._groupId = params.groupId;
            }

            if (isNumeric(this._groupId)) {
                this.addActionTaskLauncherAction();
                this.addAddScheduleAction();
            } else {
                this.removeActionTaskLauncherAction();
                this.removeAddScheduleAction();
            }

            this.pageOptions.page = 1;
            this.getDevicesPage(this.getDevicesProcess);
        }), this.getGroupIdProcess);

        this.startDataPolling();
    }

    public onPageOptionsChanged(pageOptions: PaginationOptionsModel): void {
        this.pageOptions = pageOptions;
        this.getDevicesPage(this.getDevicesProcess);
    }

    private addActionTaskLauncherAction(): void {
        this.userCurrentService.isInstaller.subscribe(isInstaller => {
            if (isInstaller === true) {
                this._navBarActionService.addAction(this.taskLauncherAction);
            } else {
                this.removeActionTaskLauncherAction();
            }
        });
    }

    private removeActionTaskLauncherAction(): void {
        this._navBarActionService.removeAction(this.taskLauncherAction);
    }

    private addAddScheduleAction(): void {
        this._navBarActionService.addAction(this.addScheduleAction);
    }

    private removeAddScheduleAction(): void {
        this._navBarActionService.removeAction(this.addScheduleAction);
    }

    private getDevicesPage(process: ProcessMonitorServiceProcess): void {
        this.addSubscription(this.observableHandlerBase(this._deviceService.getByGroup(this._groupId, this.pageOptions, process), process).subscribe(
            page => {
                this.devices = page.items;
                this.pageOptions = page.options;
            }
        ), process);
    }

    private subDataPolling(): void {
        this.addSubscription(this._dataPollingEvent.poll.subscribe(() => {
            this.addSubscription(this._deviceService.getByGroup(this._groupId, this.pageOptions, this.refreshingDevicesProcess, {disabled: true}).pipe(
                map(page => {
                    this.devices = page.items;
                    this.pageOptions = page.options;
                }),
                catchError((err: any) => {
                    console.log(err);
                    return of(true);
                })
            ).subscribe(), this.refreshingDevicesProcess);

        }), this.deviceQuickLinksPollProcess);
    }

    private startDataPolling(): void {
        this.subDataPolling();
        this._dataPollingService.startEvent(this._dataPollingEvent);
    }

    private stopDataPolling(): void {
        this._dataPollingService.stopEvent(this._dataPollingEvent);
    }

    private taskLauncher(): void {
        this._dialog.open(SettingsTaskLauncherRunComponent, { data: new SettingsTaskLauncherRunDialogData(null, this._groupId), minWidth: 500, maxWidth: 500, disableClose: true });
    }

    private addSchedule(): void {
        const dialogRef = this._dialog.open(RecordingsAddScheduleComponent, { data: new AddScheduleData(null, true), disableClose: true });

        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.addScheduledProcess).subscribe((dialogResult: AddScheduleResult) => {
            if (!this.isNullOrUndefined(dialogResult) && dialogResult.ok === true) {
                const schedule = dialogResult.schedule;
                this.addSubscription(this._deviceService.addGroupRecording(this._groupId, schedule.startTime, schedule.type === ScheduleTypeEnum.timeBased ? DateTimeUtility.toDurationMinutes(schedule.startTime, schedule.endTime) : schedule.endCount, schedule.type === ScheduleTypeEnum.timeBased).subscribe());
            }
        }), this.addScheduledProcess);
    }
}
