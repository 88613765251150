<div class="em-settings-manageusers">
    <shr-table [isLoadingData]="loadDataProcess.isRunning"
        title="Manage System Users"
        matSort        
        (filter)="applySystemFilter($event)"
        [dataSource]="systemDataSource"
        [displayedColumns]="systemDisplayedColumns">

        <ng-template #shrTableActions
            let-user>
            <div *ngIf="canEditUser(user)">
                <button matTooltip="Edit"
                    mat-icon-button
                    (click)="edit(user)">
                    <mat-icon>settings</mat-icon>
                </button>
                <button *ngIf="user?.authType === AuthTypeEnum.system" matTooltip="Reset Password"
                    mat-icon-button
                    (click)="resetPassword(user)">
                    <mat-icon>lock</mat-icon>
                </button>
                <button *ngIf="user?.authType === AuthTypeEnum.system" matTooltip="Reset API Key"
                    mat-icon-button
                    (click)="resetApiKey(user)"
                    color="warn">
                    <mat-icon>vpn_key</mat-icon>
                </button>
                <button [matTooltip]="user.disabled ? 'Enable' : 'Disable'"
                    mat-icon-button
                    (click)="toggelDisabled(user)"
                    color="warn">
                    <mat-icon>{{user.disabled ? 'done' : 'not_interested'}}</mat-icon>
                </button>
                <button matTooltip="Delete"
                    mat-icon-button
                    (click)="delete(user)"
                    color="warn">
                    <mat-icon>delete</mat-icon>
                </button>
                <button matTooltip="Unlock"
                    mat-icon-button
                    (click)="unlock(user)"
                    color="warn">
                    <mat-icon>lock_open</mat-icon>
                </button>
            </div>
        </ng-template>

        <ng-container matColumnDef="username">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header> User Name </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="username"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header="disabled"> State </th>
            <td mat-cell
                *matCellDef="let user">
                {{ user.disabled === true ? 'Disabled' : 'Enabled' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="realName">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header> Name </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="realName"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="jobTitle">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header> Job Title </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="jobTitle"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="organisation">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header> Organisation </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="organisation"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header> Email </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="email"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="lastActivityText">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header="lastActivityDate"> Last Online </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="lastActivityText"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="roleText">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header> Role </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="roleText"></shr-table-cell>
            </td>
        </ng-container>

    </shr-table>

    <!--OIDC users-->
    <shr-table [hidden]="systemAuthOnly === true" [isLoadingData]="loadDataProcess.isRunning"
        title="Manage OpenID Connect Users"
        matSort        
        (filter)="applyOidcFilter($event)"
        [dataSource]="oidcDataSource"
        [displayedColumns]="oidcDisplayedColumns">

        <ng-template #shrTableActions
            let-user>
            <div *ngIf="canEditUser(user)">
                <button matTooltip="Edit"
                    mat-icon-button
                    (click)="edit(user)">
                    <mat-icon>settings</mat-icon>
                </button>
                <button *ngIf="user?.authType === AuthTypeEnum.system" matTooltip="Reset Password"
                    mat-icon-button
                    (click)="resetPassword(user)">
                    <mat-icon>lock</mat-icon>
                </button>
                <button *ngIf="user?.authType === AuthTypeEnum.system" matTooltip="Reset API Key"
                    mat-icon-button
                    (click)="resetApiKey(user)"
                    color="warn">
                    <mat-icon>vpn_key</mat-icon>
                </button>
                <button [matTooltip]="user.disabled ? 'Enable' : 'Disable'"
                    mat-icon-button
                    (click)="toggelDisabled(user)"
                    color="warn">
                    <mat-icon>{{user.disabled ? 'done' : 'not_interested'}}</mat-icon>
                </button>
                <button matTooltip="Delete"
                    mat-icon-button
                    (click)="delete(user)"
                    color="warn">
                    <mat-icon>delete</mat-icon>
                </button>
                <button matTooltip="Unlock"
                    mat-icon-button
                    (click)="unlock(user)"
                    color="warn">
                    <mat-icon>lock_open</mat-icon>
                </button>
            </div>
        </ng-template>        

        <ng-container matColumnDef="state">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header="disabled"> State </th>
            <td mat-cell
                *matCellDef="let user">
                {{ user.disabled === true ? 'Disabled' : 'Enabled' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="realName">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header> Name </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="realName"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="jobTitle">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header> Job Title </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="jobTitle"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="organisation">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header> Organisation </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="organisation"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header> Email </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="email"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="lastActivityText">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header="lastActivityDate"> Last Online </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="lastActivityText"></shr-table-cell>
            </td>
        </ng-container>

        <ng-container matColumnDef="roleText">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header> Role </th>
            <td mat-cell
                *matCellDef="let user">
                <shr-table-cell [data]="user"
                    keyPath="roleText"></shr-table-cell>
            </td>
        </ng-container>

    </shr-table>
</div>