import { BaseModel } from '@shared/base/Base.Model';
import { StreamCommandEnum } from '@shared/enum/StreamCommand.Enum';
import { StreamTypeEnum, StreamTypeEnumHelpers } from '@shared/enum/StreamType.Enum';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface IStreamRequestModel {
    command: StreamCommandEnum;
    secureToken: string;
    stream: Array<StreamTypeEnum>;
    token: string;
}

export class StreamRequestModel extends BaseModel implements IStreamRequestModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'time_case';

    public isIWebSocketModel: boolean = true;
    public packetType: string = 'stream_request_message';
    public id: number = null;

    public command: StreamCommandEnum = null;
    public secureToken: string = null;
    public stream: Array<StreamTypeEnum> = null;
    public token: string = null;
    public specificDevice: string = null;
    public taskName: string = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-StreamRequestModel');
    }

    public toWebSocketMessage(): any {
        return {
            ...WebSocketModelUtility.toJson(this, ['command']),
            command: this.command === StreamCommandEnum.on ? 'on' : 'off',
            stream: this.stream.map(i => StreamTypeEnumHelpers.toRestApi(i)),
        };
    }
}
