import { Component, HostListener, Injector, HostBinding } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, zip, of } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BACNETModel } from '@rift/models/restapi/BACNET.Model';
import { BACNETService } from '@rift/service/data/bacnet/BACNET.Service';

export enum BACNETNetworkNumber{
    localOnly = 0,
    broadCast = 1,
    specific = 2
}

@Component({
    selector: 'rift-settings-bacnet',
    templateUrl: './Settings.BACNET.Component.html',
    styleUrls: ['./Settings.BACNET.Component.scss']
})
export class SettingsBACNETComponent extends RiftBaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsBACNETComponent';

    @HostBinding()
    public id: string = 'rift-settings-bacnet';

    public bacnet: BACNETModel = new BACNETModel();
    public formGroup: FormGroup;
    public hostDevice: DeviceModel;
    public networkNumberType: BACNETNetworkNumber = BACNETNetworkNumber.localOnly;
    public BACNETNetworkNumber = BACNETNetworkNumber;

    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    private originalNetworkNumberType: BACNETNetworkNumber = null;

    public constructor(
        private readonly _bacnetService: BACNETService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsBACNETComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsBACNETComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsBACNETComponent.className, this.saveAllChangesProcessText);

        this.addSaveAllAction(this);

        this.formGroup = this._formBuilder.group({
            deviceId: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(4194302) ])],
            port: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(65535)])],
            enabled: ['', null],
            networkNumber: [{disabled: true, value: ''}, Validators.compose([Validators.required, Validators.min(1), Validators.max(65534)])]
        });

        this.formGroupTracker.track(this.formGroup);

        this.formGroup.setValue({
            deviceId: 663700,
            port: 47808,
            enabled: false,
            networkNumber: null
        });

        this.addSubscription(this.observableHandlerBase(this.formGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesFormGroup()), this.formValuesChangeProcess);

        this.initConnectionState();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._bacnetService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase || (!this.isNullOrUndefined(this.originalNetworkNumberType) && this.originalNetworkNumberType !== this.networkNumberType);
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this.changeTracker.clear();
        this._bacnetService.clearCache();

        const loadDataSub = zip(
            this.getHostDevice().pipe(
                map(device => {
                    if (!this.isNullOrUndefined(device)) {
                        this.hostDevice = device;
                    }
                    return true;
                })
            ),
            this._bacnetService.getBACNET(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.bacnet = result;
                        this.changeTracker.track(this.bacnet);

                        this.formGroup.setValue({
                            deviceId: this.bacnet.deviceId,
                            port: this.bacnet.port,
                            enabled: this.bacnet.enabled,
                            networkNumber: null
                        });

                        if (this.bacnet.networkNumber === 0){
                            this.originalNetworkNumberType = BACNETNetworkNumber.localOnly;
                            this.networkNumberType = BACNETNetworkNumber.localOnly;

                            this.formGroup.controls.networkNumber.disable();
                        }
                        else if (this.bacnet.networkNumber === 65535){
                            this.originalNetworkNumberType = BACNETNetworkNumber.broadCast;
                            this.networkNumberType = BACNETNetworkNumber.broadCast;

                            this.formGroup.controls.networkNumber.disable();
                        }
                        else{
                            this.originalNetworkNumberType = BACNETNetworkNumber.specific;
                            this.networkNumberType = BACNETNetworkNumber.specific;

                            this.formGroup.setValue({
                                deviceId: this.bacnet.deviceId,
                                port: this.bacnet.port,
                                enabled: this.bacnet.enabled,
                                networkNumber: this.bacnet.networkNumber
                            });

                            if (this.isReadOnly === false){
                                this.formGroup.controls.networkNumber.enable();
                            }
                            else{
                                this.formGroup.controls.networkNumber.disable();
                            }
                        }
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (this.networkNumberType === BACNETNetworkNumber.localOnly){
            this.bacnet.networkNumber = 0;
        }
        else if (this.networkNumberType === BACNETNetworkNumber.broadCast){
            this.bacnet.networkNumber = 65535;
        }

        const saveAllSub = zip(
            of(this.bacnet.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._bacnetService.setBACNET(this.bacnet, process).pipe(
                            map(() => true)
                        );
                    } else {
                        return of(true);
                    }
                }
                )
            )
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    this._bacnetService.clearCache();
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(false);
                }
            })
        );
    }

    public networkTypeChanged() {
        if(this.networkNumberType === BACNETNetworkNumber.broadCast ||
            this.networkNumberType === BACNETNetworkNumber.localOnly){
            this.formGroup.controls.networkNumber.disable();
        }
        else{
            this.formGroup.controls.networkNumber.enable();
        }

        this.updateSaveAllAction(this);
    }

    public updateModelValuesFormGroup(): void {
        if (!this.isNullOrUndefined(this.bacnet) && this.isReadOnly === false) {
            const formValues = this.formGroup.value;

            this.bacnet.enabled = formValues.enabled;

            if(this.bacnet.enabled){
                this.bacnet.deviceId = formValues.deviceId;
                this.bacnet.port = formValues.port;

                if (this.networkNumberType === BACNETNetworkNumber.specific && !this.isNullOrUndefined(formValues.networkNumber)){
                    this.bacnet.networkNumber = formValues.networkNumber;
                }
            }
            else{
                this.bacnet.deviceId = 663700;
                this.bacnet.port = 47808;
                this.bacnet.networkNumber = 0;
            }

            this.updateSaveAllAction(this);
        }
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.formGroup.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.formGroup.enable();
    }
}
