export enum CountModeEnum {
    immediate = 1,
    immediateAntiDither = 2,
    deferred = 3,
    deferredCountUTurns = 4,
    deferredOnlyCountUTurns = 5,
    notSet = 6,
}

export class CountModeEnumHelpers {
    public static fromRestApi(value: string): CountModeEnum {
        switch (value) {
            case 'IMMEDIATE':
                return CountModeEnum.immediate;
            case 'IMMEDIATE_ANTI_DITHER':
                return CountModeEnum.immediateAntiDither;
            case 'DEFERRED':
                return CountModeEnum.deferred;
            case 'DEFERRED_COUNT_U_TURNS':
                return CountModeEnum.deferredCountUTurns;
            case 'DEFERRED_ONLY_COUNT_U_TURNS':
                return CountModeEnum.deferredOnlyCountUTurns;
            case 'NOT_SET':
                return CountModeEnum.notSet;
        }
    }

    public static toRestApi(value: CountModeEnum): string {
        switch (value) {
            case CountModeEnum.immediate:
                return 'IMMEDIATE';
            case CountModeEnum.immediateAntiDither:
                return 'IMMEDIATE_ANTI_DITHER';
            case CountModeEnum.deferred:
                return 'DEFERRED';
            case CountModeEnum.deferredCountUTurns:
                return 'DEFERRED_COUNT_U_TURNS';
            case CountModeEnum.deferredOnlyCountUTurns:
                return 'DEFERRED_ONLY_COUNT_U_TURNS';
            case CountModeEnum.notSet:
                return 'NOT_SET';
        }
    }

    public static toFullLongName(value: CountModeEnum): string {
        switch (value) {
            case CountModeEnum.immediate:
                return 'Immediate anti-dither disabled';
            case CountModeEnum.immediateAntiDither:
                return 'Immediate anti-dither enabled';
            case CountModeEnum.deferred:
                return 'Deferred ignore u-turns';
            case CountModeEnum.deferredCountUTurns:
                return 'Deferred count u-turns';
            case CountModeEnum.deferredOnlyCountUTurns:
                return 'Deferred count u-turns only';
            case CountModeEnum.notSet:
                return 'Not set';
        }
    }

    public static toShortName(value: CountModeEnum): string {
        switch (value) {
            case CountModeEnum.immediate:
                return '(I) Immediate';
            case CountModeEnum.immediateAntiDither:
                return '(I) AntiDither';
            case CountModeEnum.deferred:
                return '(D) Ignore U-turns';
            case CountModeEnum.deferredCountUTurns:
                return '(D) Count U-turns';
            case CountModeEnum.deferredOnlyCountUTurns:
                return '(D) U-turns Only';
            case CountModeEnum.notSet:
                return 'Not set';
        }
    }
}
