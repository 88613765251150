<div class="em-settings-manageusers-add-title"
    mat-dialog-title>
    Add User
</div>
<form [formGroup]="addUserForm">
    <div class="em-settings-manageusers-add-content"
        mat-dialog-content
        fxLayout="column">
        <mat-form-field>
            <input type="text"
                #userName
                matInput
                formControlName="userName"
                placeholder="User Name"
                autofocus>
            <mat-error>{{getFormControlErrorMessage(addUserForm.controls['userName'])}}</mat-error>
        </mat-form-field>        
        <mat-form-field>
            <input type="text"
                #realName
                matInput
                formControlName="realName"
                placeholder="Real Name"
                autofocus>
            <mat-error>{{getFormControlErrorMessage(addUserForm.controls['realName'])}}</mat-error>
            <mat-hint align="end">{{realName.value.length}} / 100</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <input type="text"
                #jobTitle
                matInput
                formControlName="jobTitle"
                placeholder="Job Title">
            <mat-error>{{getFormControlErrorMessage(addUserForm.controls['jobTitle'])}}</mat-error>
            <mat-hint align="end">{{jobTitle.value.length}} / 254</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <input type="text"
                #organisation
                matInput
                formControlName="organisation"
                placeholder="Organisation">
            <mat-error>{{getFormControlErrorMessage(addUserForm.controls['organisation'])}}</mat-error>
            <mat-hint align="end">{{organisation.value.length}} / 254</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <input type="email"
                #email
                matInput
                formControlName="email"
                placeholder="Email Address">
            <mat-error>{{getFormControlErrorMessage(addUserForm.controls['email'])}}</mat-error>
            <mat-hint align="end">{{email.value.length}} / 254</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <mat-select #userType
                formControlName="userType"
                placeholder="User Type">
                <ng-template ngFor
                    let-role
                    [ngForOf]="roles | orderBy: 'order'">
                    <mat-option [value]="role">
                        {{role.name}}
                    </mat-option>
                </ng-template>
            </mat-select>
            <mat-error>{{getFormControlErrorMessage(addUserForm.controls['userType'])}}</mat-error>
        </mat-form-field>
    </div>
    <div class="em-settings-manageusers-add-actions"
        mat-dialog-actions>
        <shr-progress-button-spinner [raised]="true"
            [diameter]="30"
            text="Add"
            mode="indeterminate"
            [active]="addProcess.isRunning === true"
            [disabled]="!addUserForm.valid"
            (shrClick)="add()"
            color="primary"></shr-progress-button-spinner>
        <div fxFlex></div>
        <button [disabled]="addProcess.isRunning === true"
            (click)="cancel()"
            mat-raised-button
            color="warn">Cancel</button>
    </div>
</form>