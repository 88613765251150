import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[shrClickOutside]'
})
export class ClickOutsideDirective {
    /**
     * Fires when click outside of component.
     *
     * @memberof ClickOutsideDirective
     */
    @Output()
    public shrClickOutside = new EventEmitter();

    public constructor(private _elementRef: ElementRef) {
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: any) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.shrClickOutside.emit(null);
        }
    }
}
