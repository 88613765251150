import { Component, Inject, Injector, OnInit, HostBinding } from '@angular/core';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { BaseComponent } from '@shared/base/Base.Component';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'em-address-book-select',
    templateUrl: './AddressBookSelect.Component.html',
    styleUrls: ['./AddressBookSelect.Component.scss']
})
export class AddressBookSelectComponent extends BaseComponent implements OnInit {
    public static className: string = 'AddressBookSelectComponent';

    public addressBookGroupsProcess: ProcessMonitorServiceProcess;

    @HostBinding()
    public id: string = 'em-address-book-select';
    public rootTreeGroup: GroupModel;
    public selectedGroup: GroupModel;

    public constructor(
        @Inject(MAT_DIALOG_DATA) private readonly _root: GroupModel,
        private readonly _dialogRef: MatDialogRef<AddressBookSelectComponent>,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.rootTreeGroup = this._root;
    }

    public cancel(): void {
        this._dialogRef.close();
    }

    public groupSelected(group: GroupModel): void {
        this.selectedGroup = group;
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public select(): void {
        this._dialogRef.close(this.selectedGroup);
    }
}
