import { Component, ElementRef, HostBinding, HostListener, Injector, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MetaDataMapModel } from '@em/models/restapi/MetaDataMap.Model';
import { MetaDataMapCollectionModel } from '@em/models/restapi/MetaDataMapCollection.Model';
import { MetaDataKeysService } from '@em/service/data/metadatakeys/MetaDataKeys.Service';
import { UserDeviceListColumnService } from '@em/service/data/user/User.DeviceList.Column.Service';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { EmRiftService } from '@em/service/rift/Em.Rift.Service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'em-rift-metadata',
    templateUrl: './Rift.MetaData.Component.html',
    styleUrls: ['./Rift.MetaData.Component.scss']
})
export class RiftMetaDataComponent extends RiftBaseComponent implements IFillHeight, OnDeactivate, OnDestroy, ILoadDate, ISaveAllChanges {
    public static className: string = 'RiftLocationComponent';
    public addMetaDataKeysFormGroupProcess: ProcessMonitorServiceProcess;

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;
    public metaDataKeysFormGroup: FormGroup;
    public metaDataMapCollection: MetaDataMapCollectionModel;
    public hostDevice: DeviceModel;

    @HostBinding()
    public id: string = 'em-rift-metadata';

    public constructor(
        private readonly _emRiftService: EmRiftService,
        private readonly _dialog: MatDialog,
        private readonly _formBuilder: FormBuilder,
        private readonly _metaDataKeysService: MetaDataKeysService,
        private readonly _userDeviceListColumnService: UserDeviceListColumnService,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this._metaDataKeysService.clearCache();
        this._userDeviceListColumnService.clearCache();

        this.loadDataProcess = this.processMonitorService.getProcess(RiftMetaDataComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(RiftMetaDataComponent.className, this.saveAllChangesProcessText);
        this.addMetaDataKeysFormGroupProcess = this.processMonitorService.getProcess(RiftMetaDataComponent.className, 'Adding meta data keys');

        this.addSaveAllAction(this);

        this.metaDataKeysFormGroup = this._formBuilder.group({
            metaDataKeys: this._formBuilder.array([]),
        });
        this.formGroupTracker.track(this.metaDataKeysFormGroup);

        this.initConnectionState();
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.deviceService.getHostDevice(this.connectionService.connectedToFriendlySerial).pipe(
                flatMap((hostDevice) => {
                    this.hostDevice = hostDevice;
                    return this._metaDataKeysService.getDeviceValues(this.hostDevice.serialNumber, process).pipe(
                        map(result => {
                            if (!this.isNullOrUndefined(result)) {
                                const formArray = this.metaDataKeysFormGroup.controls.metaDataKeys as FormArray;

                                while (formArray.length !== 0) {
                                    formArray.removeAt(0);
                                }

                                const itemsLength = result.items.length;
                                for (let itemIndex = 0; itemIndex < itemsLength; itemIndex++) {
                                    this.addMetaDataKeysFormGroup(result.items[itemIndex], formArray);
                                }

                                this.metaDataMapCollection = result;
                                this.changeTracker.track(this.metaDataMapCollection);

                                this.addSubscription(this.observableHandlerBase(this.userIsInstaller, null).subscribe(isInstaller => {
                                    if(!isInstaller){
                                        this.metaDataKeysFormGroup.disable();
                                    }
                                }), null);
                            }
                            return true;
                        })
                    );
                }),
            )
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.metaDataMapCollection.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._metaDataKeysService.updateDeviceValues(this.metaDataMapCollection, this._emRiftService.masterDeviceId, process).pipe(
                            map(() => true)
                        );
                    } else {
                        return of(true);
                    }
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(result);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this);
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.metaDataKeysFormGroup.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.metaDataKeysFormGroup.enable();
    }

    protected updateSaveAllAction(): void {
        if (this.hasChanges === true) {
            this.saveAllAction.disabled = false;
        } else {
            this.saveAllAction.disabled = true;
        }
    }

    private addMetaDataKeysFormGroup(metaDataMap: MetaDataMapModel, formArray?: FormArray): void {
        const fArray = this.isNullOrUndefined(formArray) ? this.metaDataKeysFormGroup.controls.metaDataKeys as FormArray : formArray;
        if (!this.isNullOrUndefined(formArray)) {
            const formGroup = this.createMetaDataKeysFormGroup(metaDataMap);
            this.setMetaDataKeysFormGroupValues(metaDataMap, formGroup);

            this.addSubscription(this.observableHandlerBase(formGroup.valueChanges, this.addMetaDataKeysFormGroupProcess).subscribe(() => {
                this.updateModelValuesMetaDataKeysFormGroup(formGroup, metaDataMap);
            }), this.addMetaDataKeysFormGroupProcess);

            formArray.push(formGroup);
        }
    }

    private createMetaDataKeysFormGroup(metaDataMap: MetaDataMapModel): FormGroup {
        const formGroup = this._formBuilder.group({
            value: ['', Validators.compose([Validators.maxLength(1000)])],
        });

        return formGroup;
    }

    private setMetaDataKeysFormGroupValues(metaDataMap: MetaDataMapModel, formGroup: FormGroup): void {
        if (!this.isNullOrUndefined(metaDataMap)) {
            formGroup.setValue({
                value: metaDataMap.value,
            });
        }
    }

    private updateModelValuesMetaDataKeysFormGroup(formGroup: FormGroup, metaDataMap: MetaDataMapModel): void {
        const formValues = formGroup.value;

        metaDataMap.value = formValues.value;

        this.updateSaveAllAction();
    }
}
