import { NgZone, Directive } from '@angular/core';
import { JointBase } from '@rift/components/shared/viewport/base/JointBase';
import { SegmentBase } from '@rift/components/shared/viewport/base/SegmentBase';
import { ViewPortHelpers } from '@rift/components/shared/viewport/ViewPort.Helpers';
import { ViewPortModeEnum } from '@rift/components/shared/viewport/ViewPortMode.Enum';
import { ShapeModel } from '@rift/models/restapi/ShapeModel';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ImageUtility } from '@shared/utility/Image.Utility';
import { Subject, Observable, empty, zip, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive()
export class AddPointBase extends DisplayItem {
    public click: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();

    public editEnabled: boolean = true;
    public mouseOut: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public mouseOver: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();

    protected _addPointBitmap: createjs.Bitmap = new createjs.Bitmap(ImageUtility.BlankImagePath);
    protected _fromJoint: JointBase = null;
    protected _segment: SegmentBase = null;
    protected _toJoint: JointBase = null;

    public constructor(
        private readonly _zone: NgZone,
        protected readonly _shapeBase: any,
        protected readonly _loadQueueBase: ViewPortLoadQueueService,
        protected readonly _shapeModelBase: ShapeModel,
    ) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            if (this._loadQueueBase.isLoaded === true) {
                this.onLoadQueueComplete();
            } else {
                this.addSubscription(this._loadQueueBase.loaded.subscribe(() => this.onLoadQueueComplete()));
            }

            this._addPointBitmap.scaleX = this._addPointBitmap.scaleY = 0.3;
            this.container.addChild(this._addPointBitmap);

            this.addEventHandlers();
        });
    }

    public bringToFront(): void {
    }
    /**
     * Gets the joint the add point comes from.
     *
     * @type {JointBase}
     * @memberof AddPointBase
     */
    public get fromJoint(): JointBase {
        return this._zone.runOutsideAngular(() => this._fromJoint);
    }

    /**
     * Sets the joint the add point comes from.
     *
     * @memberof AddPointBase
     */
    public set fromJoint(value: JointBase) {
        this._zone.runOutsideAngular(() => {
            if (this._fromJoint !== value) {
                this._fromJoint = value;
            }
        });
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();

            this.container.removeAllChildren();

            this._addPointBitmap = null;
        });
    }
    /**
     * Gets the segment this add point is in.
     *
     * @type {SegmentBase}
     * @memberof AddPointBase
     */
    public get segment(): SegmentBase {
        return this._zone.runOutsideAngular(() => this._segment);
    }

    /**
     * Sets the segment this add point is in.
     *
     * @memberof AddPointBase
     */
    public set segment(value: SegmentBase) {
        this._zone.runOutsideAngular(() => {
            if (this._segment !== value) {
                this._segment = value;
            }
        });
    }

    /**
     * The shape model from the rest api.
     *
     * @readonly
     * @type {ShapeModel}
     * @memberof AddPointBase
     */
    public get shapeModel(): ShapeModel {
        return this._zone.runOutsideAngular(() => this._shapeModelBase);
    }
    /**
     * Gets the joint the add point goes to.
     *
     * @type {JointBase}
     * @memberof AddPointBase
     */
    public get toJoint(): JointBase {
        return this._zone.runOutsideAngular(() => this._toJoint);
    }

    /**
     * Sets the joint the add point goes to.
     *
     * @memberof AddPointBase
     */
    public set toJoint(value: JointBase) {
        this._zone.runOutsideAngular(() => {
            if (this._toJoint !== value) {
                this._toJoint = value;
            }
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._loadQueueBase) && !isNullOrUndefined(this.fromJoint) && !isNullOrUndefined(this.toJoint) && this._loadQueueBase.isLoaded === true) {
                const fromPoint = ViewPortHelpers.devicePointToViewPortPoint(this.fromJoint.point);
                const toPoint = ViewPortHelpers.devicePointToViewPortPoint(this.toJoint.point);
                const point = new createjs.Point((fromPoint.x + toPoint.x) / 2, (fromPoint.y + toPoint.y) / 2);

                const jointBitmapBounds = this._addPointBitmap.getBounds();
                if (!isNullOrUndefined(jointBitmapBounds)) {
                    this._addPointBitmap.regX = jointBitmapBounds.width / 2;
                    this._addPointBitmap.regY = jointBitmapBounds.height / 2;

                    this.container.x = point.x;
                    this.container.y = point.y;

                    this.requireStageUpdate.next();
                }
            }
        });
    }

    protected addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this.container.on('click', this.onClick.bind(this));
            this.container.on('mouseover', this.onMouseOver.bind(this));
            this.container.on('mouseout', this.onMouseOut.bind(this));
        });
    }

    protected modeSet(): void {
        this._zone.runOutsideAngular(() => {
            this.container.visible = this.mode === ViewPortModeEnum.view ? false : true;
            this.loadBitmaps().subscribe(()=>{
                this.update();
                super.modeSet();
            });
        });
    }

    protected onClick(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.click.next(new DisplayItemMouseEvent(this, event));
            }
        });
    }

    protected onLoadQueueComplete(): void {
        this._zone.runOutsideAngular(() => {
            this.loadBitmaps().subscribe(()=>{
                this.update();
            });
        });
    }

    protected onMouseOut(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.isMouseOver = false;
                this.loadBitmaps().subscribe(()=>{
                    this.update();
                    this.mouseOut.next(new DisplayItemMouseEvent(this, event));
                });
            }
        });
    }

    protected onMouseOver(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            if (this.mode === ViewPortModeEnum.edit) {
                this.isMouseOver = true;
                this.loadBitmaps().subscribe(()=>{
                    this.update();
                    this.mouseOver.next(new DisplayItemMouseEvent(this, event));
                });
            }
        });
    }

    private loadBitmaps(): Observable<any> {
        return this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._loadQueueBase) && !isNullOrUndefined(this.fromJoint) && !isNullOrUndefined(this.toJoint) && this._loadQueueBase.isLoaded === true) {
                const addPointLoad = this._loadQueueBase.getAddPoint(this._shapeBase.register.registerBaseModel.registerIndex, this.isMouseOver).pipe(
                    map((image) => {
                        this._addPointBitmap.image = image;
                    })
                );

                return zip(addPointLoad);
            }

            return EMPTY;
        });
    }
}
