<div fxLayout="column"
    class="devices-menu">
    <div class="header">
        Devices
    </div>
    <div class="content"
        fxLayout="column">
        <div #deviceList class="device-list">
            <div *ngFor="let vm of devices" class="device" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column">
                    <div class="header" fxLayout="row" fxLayoutAlign="start center">
                        <div class="value">
                            {{ vm }}
                        </div>
                    </div>
                </div>
                <div fxFlex></div>
                <div class="actions">
                    <button
                        (click)="onToggleDeviceVideoClick(vm, $event)"
                        [disabled]="getDeviceVideoPlayState(vm)" class="mini-fab-icon-button"
                        mat-mini-fab
                        matTooltip="{{getDeviceVideoPlayState(vm) ? 'Playing Video' : 'Play Video'}}">
                        <mat-icon>{{ getDeviceVideoPlayState(vm) ? 'videocam' : 'play_arrow' }}
                        </mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>