import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IValidationSessionOptionModel extends IRestModel {
    name: string;
    enabled: boolean;
    locked: boolean;
}

export class ValidationSessionOptionModel extends BaseModel implements IValidationSessionOptionModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public name: string = null;
    public enabled: boolean = null;
    public locked: boolean = null;

    public constructor(name?: string, enabled?: boolean, locked?: boolean) {
        super();
        this.name = name;
        this.enabled = enabled;
        this.locked = locked;
    }

    public loadFromRestApiModel(restModel: any, upperCase: boolean = true): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ValidationSessionOptionModel', null, upperCase);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }

    public toInterface(): IValidationSessionOptionModel {
        return {
            name: this.name,
            enabled: this.enabled,
            locked: this.locked,
        } as IValidationSessionOptionModel;
    }
}
