import { Component, HostBinding, HostListener, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { BluetoothModel } from '@rift/models/restapi/Bluetooth.Model';
import { BluetoothService } from '@rift/service/data/bluetooth/Bluetooth.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { BluetoothLowEnergyEnum } from '@shared/enum/BluetoothLowEnergy.Enum';
import { BluetoothTxPowerEnum } from '@shared/enum/BluetoothTxPower.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'rift-settings-bluetooth',
    templateUrl: './Settings.Bluetooth.Component.html',
    styleUrls: ['./Settings.Bluetooth.Component.scss']
})
export class SettingsBluetoothComponent extends RiftBaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsBluetoothComponent';

    @HostBinding()
    public id: string = 'rift-settings-bluetooth';

    public BluetoothLowEnergyEnum = BluetoothLowEnergyEnum;
    public bluetoothTxPowerSteps = [
        { step: 0, power: BluetoothTxPowerEnum.minus20 },
        { step: 1, power: BluetoothTxPowerEnum.minus16 },
        { step: 2, power: BluetoothTxPowerEnum.minus12 },
        { step: 3, power: BluetoothTxPowerEnum.minus8 },
        { step: 4, power: BluetoothTxPowerEnum.minus4 },
        { step: 5, power: BluetoothTxPowerEnum.zero },
        { step: 6, power: BluetoothTxPowerEnum.plus4 },
        { step: 7, power: BluetoothTxPowerEnum.plus8 }
    ];
    public classicFormGroup: FormGroup;
    public iBeconFormGroup: FormGroup;
    public settings: BluetoothModel;
    public smartFormGroup: FormGroup;
    public formValuesChangedProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _bluetoothService: BluetoothService,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.formValuesChangedProcess = this.processMonitorService.getProcess(SettingsBluetoothComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsBluetoothComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsBluetoothComponent.className, this.saveAllChangesProcessText);

        this.addSaveAllAction(this);

        this.classicFormGroup = this._formBuilder.group({
            macHarvestingEnabled: ['', Validators.compose([Validators.required])],
            serialTimeout: ['', Validators.compose([Validators.required])],
        });
        this.formGroupTracker.track(this.classicFormGroup);

        this.addSubscription(this.observableHandlerBase(this.classicFormGroup.valueChanges, this.formValuesChangedProcess).subscribe(() => this.updateModelValuesClassicFormGroup()), this.formValuesChangedProcess);

        this.smartFormGroup = this._formBuilder.group({
            mode: ['', Validators.compose([Validators.required])],
        });
        this.formGroupTracker.track(this.smartFormGroup);

        this.addSubscription(this.observableHandlerBase(this.smartFormGroup.valueChanges, this.formValuesChangedProcess).subscribe(() => this.updateModelValuesSmartFormGroup()), this.formValuesChangedProcess);

        this.iBeconFormGroup = this._formBuilder.group({
            uUID: ['', Validators.compose([Validators.required, ValidationValidators.uUID])],
            majorId: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(65535)])],
            minorId: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(65535)])],
            transmitPower: ['', Validators.compose([Validators.required])],
        });
        this.formGroupTracker.track(this.iBeconFormGroup);

        this.addSubscription(this.observableHandlerBase(this.iBeconFormGroup.valueChanges, this.formValuesChangedProcess).subscribe(() => this.updateModelValuesIBeconFormGroup()), this.formValuesChangedProcess);

        this.initConnectionState();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._bluetoothService.getSettings(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.settings = result;
                        this.changeTracker.track(this.settings);

                        this.setClassicFormGroupValues();
                        this.setSmartFormGroupValues();
                        this.setIBeconFormGroupValues();
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.settings.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._bluetoothService.updateSettings(this.settings, process).pipe(
                            map(() => true)
                        );
                    } else {
                        return of(true);
                    }
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(result);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._bluetoothService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.classicFormGroup.disable();
        this.smartFormGroup.disable();
        this.iBeconFormGroup.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.classicFormGroup.enable();
        this.smartFormGroup.enable();
        this.iBeconFormGroup.enable();
    }

    private setClassicFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.settings)) {
            this.classicFormGroup.setValue({
                macHarvestingEnabled: this.settings.macHarvestingEnabled,
                serialTimeout: this.settings.serialTimeout,
            });
        }
    }

    private setIBeconFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.settings)) {
            this.iBeconFormGroup.setValue({
                uUID: this.settings.uUID,
                majorId: this.settings.majorId,
                minorId: this.settings.minorId,
                transmitPower: this.bluetoothTxPowerSteps.find(i => i.power === this.settings.transmitPower).step,
            });
        }
    }

    private setSmartFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.settings)) {
            this.smartFormGroup.setValue({
                mode: this.settings.mode,
            });
        }
    }

    private updateModelValuesClassicFormGroup(): void {
        if (!this.isNullOrUndefined(this.settings) && this.isReadOnly === false) {
            const formValues = this.classicFormGroup.value;

            this.settings.macHarvestingEnabled = formValues.macHarvestingEnabled;
            this.settings.serialTimeout = formValues.serialTimeout;

            this.updateSaveAllAction(this);
        }
    }

    private updateModelValuesIBeconFormGroup(): void {
        if (!this.isNullOrUndefined(this.settings) && this.isReadOnly === false) {
            if (this.iBeconFormGroup.enabled === true) {
                const formValues = this.iBeconFormGroup.value;

                this.settings.uUID = formValues.uUID;
                this.settings.majorId = formValues.majorId;
                this.settings.minorId = formValues.minorId;
                this.settings.transmitPower = this.bluetoothTxPowerSteps.find(i => i.step === formValues.transmitPower).power;

                this.updateSaveAllAction(this);
            }
        }
    }

    private updateModelValuesSmartFormGroup(): void {
        if (!this.isNullOrUndefined(this.settings) && this.isReadOnly === false) {
            const formValues = this.smartFormGroup.value;

            this.settings.mode = formValues.mode;

            if (this.settings.mode === BluetoothLowEnergyEnum.none) {
                this.iBeconFormGroup.disable({ emitEvent: false });
            } else {
                this.iBeconFormGroup.enable({ emitEvent: false });
            }

            this.updateSaveAllAction(this);
        }
    }
}
