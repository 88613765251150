import { Component, HostBinding, Injector } from '@angular/core';
import { LicenceStatusModel } from '@em/models/restapi/LicenceStatus.Model';
import { LicenceService } from '@em/service/data/licence/Licence.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'em-settings-home',
    templateUrl: './Settings.Home.Component.html',
    styleUrls: ['./Settings.Home.Component.scss']
})
export class SettingsHomeComponent extends BaseComponent implements ILoadDate {
    public static className: string = 'SettingsHomeComponent';

    public licence: LicenceStatusModel;

    @HostBinding()
    public id: string = 'em-settings-home';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _licenceService: LicenceService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsHomeComponent.className, this.loadDataProcessText);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._licenceService.getStatus(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.licence = result;
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }
}
