import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { GroupModel } from '@em/models/restapi/Group.Model';

export class GroupUtil {
    public static isChildOf(parent: GroupModel, childOf: GroupModel): boolean {
        return !isNullOrUndefined(parent.children) &&
            parent.children.some(
                (child) => {
                    const idsMatch = child.id === childOf.id;
                    const childHasChildren = isNullOrUndefined(child.children) || child.children.length > 0;
                    const childSomeChildrenChildOf = child.children.some(gc => GroupUtil.isChildOf(gc, childOf));
                    return idsMatch || (childHasChildren && childSomeChildrenChildOf);
                }
            );
    }
}
