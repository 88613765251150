<div>
    <mat-drawer-container style="height:92vh;">
        <mat-drawer #drawer mode="side" opened style="width:325px;">    
            <div class="em-device-group-error-warning">
                <mat-card
                    *ngFor="let summaryEntry of summary"
                    (click)="summarySelected(summaryEntry)"
                    class="card"
                    [class.selected]="selectedSummary?.messageMatch === summaryEntry.messageMatch">
                    <mat-card-content>
                        {{ summaryEntry.messageDisplay }}: ({{summaryEntry.numDevices}})
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-drawer>
        <mat-drawer-content>
            <div fxLayout="row" style="margin-top: 5px;">
                <button mat-icon-button (click)="drawer.toggle()">
                    <mat-icon *ngIf="drawer.opened === true">chevron_left</mat-icon>
                    <mat-icon *ngIf="drawer.opened === false">chevron_right</mat-icon>
                </button>
                <div fxFlex style="padding-bottom: 10px; padding-right: 40px;">
                    <mat-card *ngIf="isNullOrUndefined(selectedSummary) !== true">
                        <mat-card-content>
                            {{selectedSummary.userDescription}}
                            <div *ngIf="isNullOrUndefined(selectedSummary.helpURL) !== true">
                                <br/>
                                For more information visit <a href="{{selectedSummary.helpURL}}" target="_blank">Irisys Partner Portal</a>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card *ngIf="isNullOrUndefined(selectedSummary) === true && isNullOrUndefined(summary) === false && summary?.length > 0">
                        <mat-card-content>
                            Select a category to see details of devices being impacted by the issue.
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div style="padding-left:10px; padding-right:10px;">
                <em-device-list *ngIf="isNullOrUndefined(selectedSummary) !== true"
                    (pageOptionsChanged)="onPageOptionsChanged($event)"
                    [isLoadingData]="getDevicesProcess.isRunning"
                    [dataRefreshedTrigger]="refreshingDevicesProcess.isRunning"
                    [devices]="devices"
                    [pageOptions]="pageOptions">
                </em-device-list>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>