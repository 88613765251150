import { AbstractControl, FormControl } from '@angular/forms';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class ValidationMessageConfig {
    public formatter: (config: ValidationMessageConfig, control: FormControl | AbstractControl, validator?: any) => string;
    public message: string;
    public priority: number;
    public validatorName: string;

    public constructor(validatorName: string, message: string, priority?: number, formatter?: (config: ValidationMessageConfig, control: FormControl, validator?: any) => string) {
        this.validatorName = validatorName;
        this.message = message;
        this.priority = priority;
        this.formatter = formatter;
    }

    public getMessage(control: FormControl | AbstractControl, validator?: any): string {
        if (!isNullOrUndefined(this.formatter)) {
            return this.formatter(this, control, validator);
        } else {
            return this.message;
        }
    }
}
