<div class="counting-setting-content">
    <mat-checkbox [disabled]="isReadOnly"
        [checked]="enabled"
        (change)="onEnabledChange($event)"
        class="spacing-bottom">Enable</mat-checkbox>
    <div *ngIf="enabled === true"
        fxLayout="row"
        fxLayoutAlign="start center">
        <form [formGroup]="form"
            style="max-width: 500px;">
            <mat-form-field fxFlex="33"
                class="spacing-right">
                <input matInput
                    formControlName="minimumValue"
                    placeholder="Min Value">
                <mat-error>{{getFormControlErrorMessage(form.controls['minimumValue'])}}</mat-error>
                <span matTooltip="seconds"
                    matSuffix>
                    s
                </span>
            </mat-form-field>
            <mat-form-field fxFlex="33"
                class="spacing-right">
                <input matInput
                    formControlName="binWidth"
                    placeholder="Bin Width">
                <mat-error>{{getFormControlErrorMessage(form.controls['binWidth'])}}</mat-error>
                <span matTooltip="seconds"
                    matSuffix>
                    s
                </span>
            </mat-form-field>
            <mat-form-field fxFlex="33">
                <input matInput
                    type="number"
                    formControlName="numberOfBins"
                    placeholder="Number Of Bins">
                <mat-error>{{getFormControlErrorMessage(form.controls['numberOfBins'])}}</mat-error>
            </mat-form-field>
        </form>
    </div>
</div>