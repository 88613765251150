<div class="rift-format-system-storage-title"
    mat-dialog-title>
    <div fxLayout="row"
        fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="testClass">
                Format System Storage
            </div>            
        </div>
        <div fxFlex></div>        
    </div>
</div>
<div class="rift-format-system-storage-content"
    mat-dialog-content
    fxLayout="column">
    <div fxLayout="column">
        <div>
            In some circumstances it may be a requirement to format the SD card on device to resolve issues, this dialog will allow you to do this.
            <br>
            <br>            
            <b>Warning</b>: This dialog should only be used if Irisys support have specifically recommended it to resolve an issue. 
            <br>
            <br>
            Formatting SD cards can cause data collection issues with Estate Manager.
            <br>
            <br>
            <b>Warning</b>: Performing a 'System' format will require a firmware upgrade task to be applied to the device immediately afterwards
            <br>
            <br>
            The device will reboot as part of this operation.
            <br>
            <br>
            <b>Select which device and format type to perform:</b>
            <br>
            <br>
        </div>
        <div fxLayout="row">
            <mat-form-field style="min-width: 220px;">
                <mat-label>Device:</mat-label>
                <mat-select [(value)]="selectedDevice" [disabled]="isReadOnly">
                    <mat-option *ngFor="let device of devices" [value]="device.serialNumber">
                        {{device.serialNumber}} ({{device.master === true? 'Master' : 'Node'}})
                    </mat-option>
                </mat-select>
            </mat-form-field> 
            <br>
            <br>          
        </div>
        <div fxLayout="row">
            <mat-form-field>
                <mat-label>Format Type: </mat-label>                
                <mat-select [(value)]="selectedFormat" [disabled]="isReadOnly">                    
                    <mat-option *ngFor="let formatOption of validFormatTypes" [value]="formatOption">
                        {{FormatSystemStorageEnumHelpers.toOptionLabel(formatOption)}}
                    </mat-option>
                </mat-select>
            </mat-form-field>            
        </div>        
    </div>
</div>
<div class="rift-format-system-storage-actions"
    mat-dialog-actions>
    <button (click)="close()"
        mat-raised-button
        color="primary">Close</button>
    <div fxFlex></div>
    <button [disabled]="isReadOnly" mat-raised-button color="primary" (click)="format()">Format</button>
</div>