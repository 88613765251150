<div class="rift-validate-heights-position-dialog-title"
    mat-dialog-title>
    <div fxLayout="row"
        fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Configure Positions
            </div>
            <div class="subheader">
                Device heights and or positions require configuration
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div class="rift-validate-heights-position-dialog-content"
    mat-dialog-content
    fxLayout="column">
    <form [formGroup]="form"
        fxFlex>
        <div formArrayName="devices"
            style="height: 200px;">
            <ng-template ngFor
                let-device
                [ngForOf]="data.devices"
                let-i="index">
                <div [formGroupName]="i">
                    <div class="spacing-bottom spacing-top"
                        fxLayout="row">
                        {{ device.serialNumber }} ({{ device.master === true ? 'Master' : 'Node' }})
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex="50"
                            class="spacing-right">
                            <input id="{{i + '_x'}}"
                                riftUnitsOfMeasurementInput
                                #xInput=riftUnitsOfMeasurementInput
                                formControlName="x"
                                placeholder="X"
                                [metricMin]="-10000"
                                [metricMax]="10000"
                                [metricUnit]="UnitOfMeasurementEnum.centimeter"
                                [imperialUnit]="UnitOfMeasurementEnum.inch">
                            <span matSuffix>
                                {{xInput.suffix}}
                            </span>
                            <mat-error>{{getFormControlErrorMessage(asFormGroup(asFormArray(form.controls['devices']).at(i)).controls['x'])}}
                            </mat-error>
                            <span matSuffix>{{ device?.propertyHasChangesText('x') }}</span>
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input id="{{i + '_y'}}"
                                riftUnitsOfMeasurementInput
                                #yInput=riftUnitsOfMeasurementInput
                                formControlName="y"
                                placeholder="Y"
                                [metricMin]="-10000"
                                [metricMax]="10000"
                                [metricUnit]="UnitOfMeasurementEnum.centimeter"
                                [imperialUnit]="UnitOfMeasurementEnum.inch">
                            <span matSuffix>
                                {{yInput.suffix}}
                            </span>
                            <mat-error>{{getFormControlErrorMessage(asFormGroup(asFormArray(form.controls['devices']).at(i)).controls['y'])}}
                            </mat-error>
                            <span matSuffix>{{ device?.propertyHasChangesText('y') }}</span>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field [fxFlex]="device.isCapable(DeviceCapabilitiesEnum.height) ? 50 : 100">
                            <input id="{{i + '_height'}}"
                                riftUnitsOfMeasurementInput
                                #heightInput=riftUnitsOfMeasurementInput
                                formControlName="height"
                                placeholder="Height"
                                [metricMin]="HeightRanges[device.lensType].overallMin"
                                [metricMax]="HeightRanges[device.lensType].overallMax"
                                [metricUnit]="UnitOfMeasurementEnum.centimeter"
                                [imperialUnit]="UnitOfMeasurementEnum.inch">
                            <span matSuffix>
                                {{heightInput.suffix}}
                            </span>
                            <mat-error>{{getFormControlErrorMessage(asFormGroup(asFormArray(form.controls['devices']).at(i)).controls['height'])}}
                            </mat-error>
                            <span matSuffix>{{ device?.propertyHasChangesText('height') }}</span>
                        </mat-form-field>
                        <div class="spacing-left"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            *ngIf="device.isCapable(DeviceCapabilitiesEnum.height)">
                            <mat-checkbox id="{{i + '_autoEnabled'}}"
                                formControlName="autoEnabled">Auto Height
                            </mat-checkbox>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div class="height-detect"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            *ngIf="device.isCapable(DeviceCapabilitiesEnum.height)">
                            <shr-progress-button-spinner [raised]="true"
                                [diameter]="30"
                                text="Auto Detect"
                                mode="indeterminate"
                                [disabled]="autoEnabled === false"
                                [active]="device.autoHeightState === AutoHeightDetectionStateEnum.autoInitializing"
                                (shrClick)="onAutoDetectClick(device, asFormGroup(asFormArray(form.controls['devices']).at(i)))"
                                color="primary">
                            </shr-progress-button-spinner>
                            <div class="spacing-left">
                                {{ AutoHeightDetectionStateEnumHelpers.toString(device?.autoHeightState) }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </form>
</div>
<div class="rift-validate-heights-position-dialog-actions"
    mat-dialog-actions>
    <!-- [disabled]="isValid == false || hasChanges == true" -->
    <div fxFlex></div>
    <button id="Save"
        mat-raised-button
        color="primary"
        [disabled]="isValid === false"
        (click)="onSaveClick()">Save</button>
</div>