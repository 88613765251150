import { isNullOrUndefined } from '@shared/utility/General.Utility';

const TRANSFORM_TRANSLATE_REGEXP = new RegExp(/translate\((-?[0-9]*)px,\W(-?[0-9]*)px\)/i);

export class ElementStyleUtility {
    public static getTransformTranslate(element: HTMLElement): createjs.Point | { x: number; y: number } {
        const match = TRANSFORM_TRANSLATE_REGEXP.exec(element.style.transform);
        if (!isNullOrUndefined(match)) {
            return new createjs.Point(parseInt(match[1], 10), parseInt(match[2], 10));
        }
    }

    public static setTransformTranslate(element: HTMLElement, point: createjs.Point | { x: number; y: number }): void {
        element.style.transform = `translate(${point.x}px, ${point.y}px)`;
    }
}
