import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IGVectorModel extends IRestModel {
    x: number;
    y: number;
    z: number;
    yaw: number;
}

export class GVectorModel extends BaseModel implements IGVectorModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public x: number = null;
    public y: number = null;
    public z: number = null;
    public yaw: number = null;

    public constructor() {
        super();
        this.registerProperty('yaw');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-GVectorModel');

        this.yaw = parseFloat(this.yaw.toString());

        this.setPropertyOriginalValue('yaw', this.yaw);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
