import { Injectable } from '@angular/core';
import { SafeSubscriptionBase } from '@shared/base/SafeSubscription.Base';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { LoggingServiceLevel } from '@shared/service/logging/Logging.Service.Level';
import { LoggingServiceLog } from '@shared/service/logging/Logging.Service.Log';
import { LoggingServiceOptions } from '@shared/service/logging/Logging.Service.Options';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNull, isNullOrUndefined, isObject, isString } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';

/**
 * Logs messages to the console.
 *
 * @export
 * @class LoggingService
 */
@Injectable()
export class LoggingService extends SafeSubscriptionBase {

    @LocalStorage('LoggingService', 'loggs')
    private _loggs: Array<LoggingServiceLog> = [];
    private readonly _level: LoggingServiceLevel;
    private readonly _store: boolean;
    private readonly _storeAs: string;
    private readonly _storeLevel: LoggingServiceLevel;
    private readonly _storeMax: number;

    /**
     * Creates an instance of LoggingService.
     *
     * @param {ConfigurationService} _configurationService The configuration service.
     * @memberof LoggingService
     */
    public constructor() {
        super();

        this._level = LoggingServiceLevel.Off; //this._configurationService.loggingServiceOptions.level;
        this._store = false; //this._configurationService.loggingServiceOptions.store;
        this._storeAs = null; //this._configurationService.loggingServiceOptions.storeAs;
        this._storeMax = LoggingServiceLevel.Off; //this._configurationService.loggingServiceOptions.storeMax;
        this._storeLevel = LoggingServiceLevel.Off; //this._configurationService.loggingServiceOptions.storeLevel;

        if (this._store === false) {
            this._loggs = [];
        }
    }

    /**
     * Gets a boolean indecating if the specified log level is enabled.
     *
     * @memberof LoggingService
     */
    public get isDebugEnabled(): boolean {
        return this._level >= LoggingServiceLevel.Debug;
    }

    /**
     * Gets a boolean indecating if the specified log level is enabled.
     *
     * @memberof LoggingService
     */
    public get isErrorEnabled(): boolean {
        return this._level >= LoggingServiceLevel.Error;
    }

    /**
     * Gets a boolean indecating if the specified log level is enabled.
     *
     * @memberof LoggingService
     */
    public get isInfoEnabled(): boolean {
        return this._level >= LoggingServiceLevel.Info;
    }

    /**
     * Gets a boolean indecating if the specified log level is enabled.
     *
     * @memberof LoggingService
     */
    public get isLogEnabled(): boolean {
        return this._level >= LoggingServiceLevel.Log;
    }

    /**
     * Gets a boolean indecating if the specified log level is enabled.
     *
     * @memberof LoggingService
     */
    public get isWarnEnabled(): boolean {
        return this._level >= LoggingServiceLevel.Warn;
    }

    /**
     * Gets a boolean indecating if the specified log level is enabled.
     *
     * @param {string} level The log level
     * @returns {boolean} True if enabled else false.
     * @memberof LoggingService
     */
    public isLevelEnabled(level: string): boolean {
        switch (level) {
            case 'debug':
                return this.isDebugEnabled;
            case 'warn':
                return this.isWarnEnabled;
            case 'info':
                return this.isInfoEnabled;
            case 'error':
                return this.isErrorEnabled;
        }
    }

    /**
     * Gets a boolean indecating if the specified log level storing is enabled.
     *
     * @memberof LoggingService
     */
    public get isDebugStoreEnabled(): boolean {
        return this._storeLevel >= LoggingServiceLevel.Debug;
    }

    /**
     * Gets a boolean indecating if the specified log level storing is enabled.
     *
     * @memberof LoggingService
     */
    public get isErrorStoreEnabled(): boolean {
        return this._storeLevel >= LoggingServiceLevel.Error;
    }

    /**
     * Gets a boolean indecating if the specified log level storing is enabled.
     *
     * @memberof LoggingService
     */
    public get isInfoStoreEnabled(): boolean {
        return this._storeLevel >= LoggingServiceLevel.Info;
    }

    /**
     * Gets a boolean indecating if the specified log level storing is enabled.
     *
     * @memberof LoggingService
     */
    public get isLogStoreEnabled(): boolean {
        return this._storeLevel >= LoggingServiceLevel.Log;
    }

    /**
     * Gets a boolean indecating if the specified log level storing is enabled.
     *
     * @memberof LoggingService
     */
    public get isWarnStoreEnabled(): boolean {
        return this._storeLevel >= LoggingServiceLevel.Warn;
    }

    /**
     * Gets a boolean indecating if the specified log level storing is enabled.
     *
     * @param {string} level The log level
     * @returns {boolean} True if enabled else false.
     * @memberof LoggingService
     */
    public isLevelStoreEnabled(level: string): boolean {
        switch (level) {
            case 'debug':
                return this.isDebugStoreEnabled;
            case 'warn':
                return this.isWarnStoreEnabled;
            case 'info':
                return this.isInfoStoreEnabled;
            case 'error':
                return this.isErrorStoreEnabled;
        }
    }

    /**
     * Gets the stored logs.
     *
     * @readonly
     * @type {Array<LoggingServiceLog>}
     * @memberof LoggingService
     */
    public get LogStore(): Array<LoggingServiceLog> {
        return this._loggs;
    }

    /**
     * Writes a debug message with process info.
     *
     * @param {string} name The name of the class calling the method.
     * @param {ProcessMonitorServiceProcess} [process] The process logging was called during.
     * @param {string} message The message to write.
     * @param {...Array<any>} optionalParams The params to replace in the message.
     * @memberof LoggingService
     */
    public debugProcess(name: string, process: ProcessMonitorServiceProcess, message: string, ...optionalParams: Array<any>): void {
        this.logBase('debug', name, process, message, null, optionalParams);
    }

    public errorProcess(name: string, process: ProcessMonitorServiceProcess, message: string, error: Error, ...optionalParams: Array<any>): void {
        this.logBase('error', name, process, message, error, optionalParams);
    }

    /**
     * Writes a debug message.
     *
     * @param {string} name The name of the class calling the method.
     * @param {string} message The message to write.
     * @param {...Array<any>} optionalParams The params to replace in the message.
     * @memberof LoggingService
     */
    public debug(name: string, message: string, ...optionalParams: Array<any>): void {
        this.logBase('debug', name, null, message, null, optionalParams);
    }

    /**
     * Writes a error message.
     *
     * @param {string} name The name of the class calling the method.
     * @param {string} message The message to write.
     * @param {Error} error The error to write.
     * @param {...Array<any>} optionalParams The params to replace in the message.
     * @memberof LoggingService
     */
    public error(name: string, message: string, error: Error, ...optionalParams: Array<any>): void {
        this.logBase('error', name, null, message, error, optionalParams);
    }

    /**
     * Writes a info message.
     *
     * @param {string} name The name of the class calling the method.
     * @param {string} message The message to write.
     * @param {...Array<any>} optionalParams The params to replace in the message.
     * @memberof LoggingService
     */
    public info(name: string, message: string, ...optionalParams: Array<any>): void {
        this.logBase('info', name, null, message, null, optionalParams);
    }

    /**
     * Writes a warn message.
     *
     * @param {string} name The name of the class calling the method.
     * @param {string} message The message to write. can contain {0} {1...} to be replaced by optionalParams
     * @param {...Array<any>} optionalParams The params to replace in the message.
     * @memberof LoggingService
     */
    public warn(name: string, message: string, ...optionalParams: Array<any>): void {
        this.logBase('warn', name, null, message, null, optionalParams);
    }

    /**
     * Formats the message replacing {0} {1...} with the optionalParams.
     *
     * @private
     * @param {string} message The message to write. can contain {0} {1...} to be replaced by optionalParams
     * @param {...Array<string>} optionalParams The params to replace in the message.
     * @returns {string} The formated message.
     * @memberof LoggingService
     */
    private format(message: string, optionalParams: Array<string>): string {
        if (!isNullOrUndefined(message) && isString(message)) {
            return message.replace(/{(\d+)}/g,
                (match, number) => {
                    if (!isNullOrUndefined(optionalParams[number])) {
                        if (isObject(optionalParams[number])) {
                            return JSON.stringify(optionalParams[number]);
                        } else {
                            return optionalParams[number];
                        }
                    } else {
                        return match;
                    }
                });
        } else {
            return StringUtility.empty;
        }
    }

    /**
     * Writes the logg to the console and adds to local storage.
     *
     * @private
     * @param {string} level The level of the log.
     * @param {string} name The name of the logger.
     * @param {ProcessMonitorServiceProcess} process The process running when logging.
     * @param {string} message The message to show.
     * @param {Error} error The system error.
     * @param {Array<any>} optionalParams The parameters to replace in the 'message'
     * @memberof LoggingService
     */
    private logBase(level: string, name: string, process: ProcessMonitorServiceProcess, message: string, error: Error, optionalParams: Array<any>): void {
        if (this.isLevelEnabled(level)) {
            const formatedMessage = this.format(message, optionalParams);

            if (!isNullOrUndefined(process) && !isNullOrUndefined(process.completedTime) && !isNullOrUndefined(process.startedTime)) {
                const duration = process.completedTime - process.startedTime;
                if (duration > -1) {
                    console[level](`${name}:${formatedMessage} in ${process.completedTime - process.startedTime}ms`);
                } else {
                    console[level](`${name}:${formatedMessage}`);
                }
            } else {
                console[level](`${name}:${formatedMessage}`);
            }

            if (!isNullOrUndefined(error)) {
                if (!isNullOrUndefined(error.message)) {
                    console[level](`${name}:message:${error.message}`);
                }
                if (!isNullOrUndefined(error.stack)) {
                    console[level](`${name}:stack:${error.stack}`);
                }
            }

            if (this.isLevelStoreEnabled(level)) {
                const log = new LoggingServiceLog(level, name, formatedMessage);

                if (!isNull(process)) {
                    log.processCompletedTime = process.completedTime;
                    log.processErrorMessages = process.errorMessages;
                    log.processHasError = process.hasError;
                    log.processPercentage = process.percentage;
                    log.processStartedTime = process.startedTime;
                    log.processStatus = process.status;
                    log.error = error;
                }

                this._loggs.push(log);

                if (this._loggs.length > this._storeMax) {
                    const removeCount = this._loggs.length - this._storeMax;
                    this._loggs = this._loggs.splice(0, removeCount);
                }
            }
        }
    }
}

export const offLoggerProviders: Array<any> = [{ provide: LoggingServiceOptions, useValue: { level: LoggingServiceLevel.Off } }, LoggingService];
export const errorLoggerProviders: Array<any> = [{ provide: LoggingServiceOptions, useValue: { level: LoggingServiceLevel.Error } }, LoggingService];
export const warnLoggerProviders: Array<any> = [{ provide: LoggingServiceOptions, useValue: { level: LoggingServiceLevel.Warn } }, LoggingService];
export const infoLoggerProviders: Array<any> = [{ provide: LoggingServiceOptions, useValue: { level: LoggingServiceLevel.Info } }, LoggingService];
export const debugLoggerProviders: Array<any> = [{ provide: LoggingServiceOptions, useValue: { level: LoggingServiceLevel.Debug } }, LoggingService];
export const logLoggerProviders: Array<any> = [{ provide: LoggingServiceOptions, useValue: { level: LoggingServiceLevel.Log } }, LoggingService];
