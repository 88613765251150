import { HttpErrorResponse } from '@angular/common/http';
import { Dictionary } from '@shared/generic/Dictionary';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { ICanceled, IRestModel } from '@shared/interface/IRestModel';
import { ITrackChanges } from '@shared/interface/ITrackChanges';
import { ITrackPropertyChanges, PropertyState } from '@shared/interface/ITrackPropertyChanges';
import { isDate, isNullOrUndefined, isUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { UniqueIdUtility } from '@shared/utility/UniqueId.Utility';
import { Subject } from 'rxjs';

/**
 * The base rest api model class.
 *
 * @export
 * @abstract
 * @class BaseModel
 * @implements {ITrackPropertyChanges}
 */
export abstract class BaseModel implements ITrackPropertyChanges, IRestModel {
    public propertyChanged: Subject<string> = new Subject();
    public isIRestModel = true;
    public error?: string;
    public httpError?: HttpErrorResponse;
    public userCanceled?: ICanceled;

    private _propertyStates: Dictionary<string, PropertyState> = new Dictionary<string, PropertyState>();
    private _uniqueId: number;

    /**
     * Creates an instance of BaseModel.
     *
     * @memberof BaseModel
     */
    protected constructor() {
        this._uniqueId = UniqueIdUtility.nextId;
        return new Proxy(this, {
            set: (model: this, propertyName: string, value: any): boolean => {
                const propertyState = this._propertyStates.get(propertyName);
                if (!isNullOrUndefined(propertyState) && propertyState.proxy === true) {
                    if (Reflect.get(model, propertyName) !== value) {
                        const result = Reflect.set(model, propertyName, value);
                        model.onPropertyChanged(propertyName.toString(), value);
                        return result;
                    }
                    return true;
                }
                return Reflect.set(model, propertyName, value);
            }
        });
    }

    /**
     * Loads the models data from a REST API response.
     *
     * @param {*} restModel
     * @param {boolean} [upperCase=true]
     * @memberof BaseModel
     */
    public loadFromRestApiModel(restModel: any, upperCase: boolean = true): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-BaseModel', null, upperCase);
    }

    /**
     * Converts the model to a REST API object.
     *
     * @returns {*}
     * @memberof BaseModel
     */
    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }

    public clearChanges(): void {
        const keys = this._propertyStates.keys;
        const length = keys.length;
        for (let i = 0; i < length; i++) {
            const propertyState = this._propertyStates.get(keys[i]);
            if (propertyState.isChangeTrackerArray === true) {
                const trackerArray = (this[propertyState.propertyName] as ITrackChanges);
                if (!isNullOrUndefined(trackerArray)) {
                    trackerArray.clearChanges();
                }
            } else {
                this[propertyState.propertyName] = propertyState.originalValue;
                propertyState.hasChanged = false;
                if (propertyState.isChangeTrackerModel === true) {
                    const trackerModel = (this[propertyState.propertyName] as ITrackChanges);
                    if (!isNullOrUndefined(trackerModel)) {
                        trackerModel.clearChanges();
                    }
                }
            }
        }
    }

    public commitChanges(): void {
        const keys = this._propertyStates.keys;
        const length = keys.length;
        for (let i = 0; i < length; i++) {
            const propertyState = this._propertyStates.get(keys[i]);
            const propertyValue = this[propertyState.propertyName];
            if (propertyState.isChangeTrackerArray === true) {
                const trackerArray = (propertyValue as ITrackChanges);
                if (!isNullOrUndefined(trackerArray)) {
                    trackerArray.commitChanges();
                }
            } else {
                propertyState.originalValue = propertyValue;
                propertyState.hasChanged = false;
                if (propertyState.isChangeTrackerModel === true && !isNullOrUndefined(propertyValue)) {
                    const trackerModel = (propertyValue as ITrackChanges);
                    if (!isNullOrUndefined(trackerModel)) {
                        trackerModel.commitChanges();
                    }
                }
            }
        }
    }

    public get hasChanges(): boolean {
        const keys = this._propertyStates.keys;
        const length = keys.length;
        for (let index = 0; index < length; index++) {
            const propertyState = this._propertyStates.get(keys[index]);
            if (this.propertyStateHasChanges(propertyState)) {
                return true;
            }
        }
        return false;
    }

    public get uniqueId(): number {
        return this._uniqueId;
    }

    public getPropertyOriginalValue(propertyName: string): any {
        const propertyState = this._propertyStates.get(propertyName);
        if (!isNullOrUndefined(propertyState)) {
            return propertyState.originalValue;
        } else {
            throw new Error(`BaseModel: getPropertyOriginalValue property ${propertyName} is not in tracker`);
        }
    }

    public hasChangesExcluding(...propertyNames: Array<string>): boolean {
        const keys = this._propertyStates.keys;
        const length = keys.length;
        for (let index = 0; index < length; index++) {
            const propertyState = this._propertyStates.get(keys[index]);
            if (propertyNames.indexOf(propertyState.propertyName) === -1) {
                if (this.propertyStateHasChanges(propertyState)) {
                    return true;
                }
            }
        }

        return false;
    }

    public onPropertyChanged(propertyName: string, newValue: any): void {
        const propertyState = this._propertyStates.get(propertyName);
        if (!isNullOrUndefined(propertyState)) {
            if (isDate(newValue) && isDate(propertyState.originalValue)) {
                if (newValue.valueOf() !== propertyState.originalValue.valueOf()) {
                    propertyState.hasChanged = true;
                } else {
                    propertyState.hasChanged = false;
                }
            } else {
                if (newValue !== propertyState.originalValue) {
                    propertyState.hasChanged = true;
                } else {
                    propertyState.hasChanged = false;
                }
            }
        }

        this.propertyChanged.next(propertyName);
    }

    public propertyHasChanges(propertyName: string): boolean {
        const propertyState = this._propertyStates.get(propertyName);
        if (!isNullOrUndefined(propertyState)) {
            return this.propertyStateHasChanges(propertyState);
        }
    }

    public propertyHasChangesText(propertyName: string): string {
        const propertyState = this._propertyStates.get(propertyName);
        if (!isNullOrUndefined(propertyState)) {
            return this.propertyStateHasChanges(propertyState) === true ? '*' : '';
        }
    }

    public registerChangeTrackerArray(propertyName: string): void {
        if (!isUndefined(this[propertyName])) {
            const propertyState = new PropertyState(propertyName);
            propertyState.isChangeTrackerArray = true;
            this._propertyStates.addOrUpdate(propertyName, propertyState);
            const changeTrackerArray = (this[propertyName] as ITrackChanges);
            if (!isNullOrUndefined(changeTrackerArray)) {
                if (changeTrackerArray instanceof RestModelChangeTrackerArray) {
                    changeTrackerArray.originalValuesSet();
                }
            } else {
                throw new Error(`BaseModel: registerChangeTrackerArray change tracker needs to be initialized`);
            }
        }
    }

    public registerChangeTrackerModel(propertyName: string): void {
        if (!isUndefined(this[propertyName])) {
            const propertyState = new PropertyState(propertyName);
            propertyState.isChangeTrackerModel = true;
            this._propertyStates.addOrUpdate(propertyName, propertyState);
        }
    }

    public registerProperty(propertyName: string, isChangeTrackerArray: boolean = false, proxy: boolean = true): void {
        if (!isUndefined(this[propertyName])) {
            const propertyState = new PropertyState(propertyName);
            propertyState.proxy = proxy;
            propertyState.originalValue = null;
            propertyState.hasChanged = false;
            propertyState.isChangeTrackerArray = isChangeTrackerArray;
            this._propertyStates.addOrUpdate(propertyName, propertyState);
        } else {
            throw new Error(`BaseModel: registerProperty propertyName:${propertyName} is undefined on object`);
        }
    }

    public setPropertyOriginalValue(propertyName: string, value: any): void {
        const propertyState = this._propertyStates.get(propertyName);
        if (!isNullOrUndefined(propertyState)) {
            if (propertyState.isChangeTrackerArray === true) {
            } else {
                propertyState.originalValue = value;
                propertyState.hasChanged = false;
            }
        } else {
            throw new Error(`BaseModel: setPropertyOriginalValue property ${propertyName} is not in tracker`);
        }
    }

    private propertyStateHasChanges(propertyState: PropertyState): boolean {
        if (propertyState.isChangeTrackerArray === true) {
            const trackerArray = (this[propertyState.propertyName] as ITrackChanges);
            return isNullOrUndefined(trackerArray) ? false : trackerArray.hasChanges;
        } else if (propertyState.isChangeTrackerModel === true) {
            const trackerModel = (this[propertyState.propertyName] as ITrackPropertyChanges);
            return isNullOrUndefined(trackerModel) ? propertyState.hasChanged : propertyState.hasChanged || trackerModel.hasChanges;
        } else {
            return propertyState.hasChanged;
        }
    }
}
