export enum FormatSystemStorageEnum {
    all = 0,
    data = 1,
    system = 2,
    webfiles = 3
}

export class FormatSystemStorageEnumHelpers {
    public static toOptionLabel(value: FormatSystemStorageEnum): string {
        switch (value){
            case FormatSystemStorageEnum.all:
                return 'All';
            case FormatSystemStorageEnum.data:
                return 'Data';
            case FormatSystemStorageEnum.system:
                return 'System';
            case FormatSystemStorageEnum.webfiles:
                return 'Webfiles';
        }
    }
}
