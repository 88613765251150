import { SyncedVideoSessionModel } from '@rift/models/restapi/SyncedVideoSession.Model';
import { IValidationSessionOptionModel, ValidationSessionOptionModel } from '@rift/models/restapi/ValidationSessionOption.Model';
import { ValidationVideoIdentifierModel } from '@rift/models/restapi/ValidationVideoIdentifier.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface INewValidationSessionDetailsModel extends IRestModel {
    options: Array<IValidationSessionOptionModel>;
    registersToValidate: Array<number>;
    notes: string;
    name: string;
    username: string;
    partial: boolean;
    startFrame: number;
    endFrame: number;
}

export class NewValidationSessionDetailsModel extends ValidationVideoIdentifierModel implements INewValidationSessionDetailsModel, IRestModel {

    public error: string = null;
    public endFrame: number = null;
    public name: string = null;
    public notes: string = null;
    public options: Array<ValidationSessionOptionModel> = null;
    public partial: boolean = null;
    public readonly isIRestModel = true;
    public registersToValidate: Array<number> = null;
    public startFrame: number = null;
    public username: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any, upperCase: boolean = true): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-NewValidationSessionDetailsModel', null, upperCase);
        this.videoSession = RestModelUtility.loadFrom(restModel.VideoSession, SyncedVideoSessionModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            VideoSession: this.videoSession.toRestApiModel(),
        };
    }

    public toInterface(): INewValidationSessionDetailsModel {
        return {
            options: this.options.map(i => i.toInterface()),
            registersToValidate: this.registersToValidate,
            notes: this.notes,
            name: this.name,
            username: this.username,
            partial: this.partial,
            startFrame: this.startFrame,
            endFrame: this.endFrame,
        } as INewValidationSessionDetailsModel;
    }
}
