import { Component, HostBinding, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';
import { BreadCrumbService } from '@shared/service/breadcrumb/BreadCrumb.Service';
import { BreadCrumb } from '@shared/service/breadcrumb/BreadCrumb.Service.Crumb';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';


/**
 * Shows bread crumbs from the BreadCrumbService.
 *
 * @export
 * @class BreadCrumbComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
    selector: 'shr-breadcrumb',
    templateUrl: './BreadCrumb.Component.html',
    styleUrls: ['./BreadCrumb.Component.scss']
})
export class BreadCrumbComponent extends BaseComponent implements OnInit {
    public static className: string = 'BreadCrumbComponent';

    @HostBinding()
    public id: string = 'shr-breadcrumb';

    public crumbs: Array<BreadCrumb>;
    crumbsChangedProcess: ProcessMonitorServiceProcess;

    public  constructor(
        private readonly _injector: Injector,
        private readonly _breadCrumbService: BreadCrumbService) {
        super(_injector);

        this.crumbsChangedProcess = this.processMonitorService.getProcess(BreadCrumbComponent.className, 'Crumbs Changed');

        this.addSubscription(this.observableHandlerBase(this._breadCrumbService.onCrumbsChanged, this.crumbsChangedProcess).subscribe(crumbs => {
            const inRouteCrumbs: BreadCrumb[] = [];

            const length = crumbs.length;
            for (let index = 0; index < length; index++) {
                const item = crumbs[index];
                if (item.inRoute === true) {
                    inRouteCrumbs.push(item);
                }
            }

            if (!this.isNullOrUndefined(this.crumbs)){
                this.crumbs.splice(0, this.crumbs.length);
            }

            this.crumbs = inRouteCrumbs.sort((a, b) => a.routeIndex - b.routeIndex);
        }), this.crumbsChangedProcess);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public onClicked(crumb: BreadCrumb): void {
        if (!this.isNullOrUndefined(crumb.clicked)) {
            crumb.clicked.next();
        }
    }
}
