import { Dictionary } from '@shared/generic/Dictionary';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { isArray, isFunction, isNullOrUndefined, isObject } from '@shared/utility/General.Utility';

/**
 * Helper methods for IWebSocketModel.
 *
 * @export
 * @class WebSocketModelUtility
 */
export class WebSocketModelUtility {
    private static GLOBAL_EXCLUDE_FIELDS = ['hasChanges', 'isIWebSocketModel', 'isIRestModel', 'uniqueId', 'expectedPacketType'];
    private static lazyLoadFunctionCache = new Dictionary<string, Function>();

    /**
     * Converts a IWebSocketModel to a JSON object.
     *
     * @static
     * @template TWebSocketModel The type of the model to load.
     * @param {TWebSocketModel} from The IWebSocketModel to convert to JSON.
     * @param {Array<string>} [excludes] The properties to exclued from the conversion.
     * @returns {*} A JSON object.
     * @memberof WebSocketModelUtility
     */
    public static toJson<TWebSocketModel extends IWebSocketModel>(from: TWebSocketModel, excludes?: Array<string>): any {
        if (!isNullOrUndefined(from)) {
            excludes = this.getKeyExcludes(excludes);

            const maps: string[] = [];
            const keys = this.getKeys(from);
            const keysLength = keys.length;
            for (let i = 0; i < keysLength; i++) {
                const key = keys[i];
                if (this.filterKeys(excludes, key, from)) {
                    maps.push(key);
                }
            }

            const mapsLength = maps.length;

            const any = {};

            for (let i = 0; i < mapsLength; i++) {
                const fromKey = maps[i];
                const fromValue = from[fromKey];
                if (!isNullOrUndefined(fromValue)) {
                    any[fromKey] = fromValue;
                }
            }

            return any;
        }
    }

    /**
     * Converts and Array<IWebSocketModel> to a JSON Array.
     *
     * @static
     * @template TWebSocketModel The type of the model to load.
     * @param {Array<TWebSocketModel>} array The array of IModels.
     * @returns {Array<any>} The JSON array.
     * @memberof WebSocketModelUtility
     */
    public static toJsonArray<TWebSocketModel extends IWebSocketModel>(array: Array<TWebSocketModel>): Array<any> {
        if (!isNullOrUndefined(array)) {
            const items: any[] = [];

            const length = array.length;
            for (let i = 0; i < length; i++) {
                items.push(array[i].toWebSocketMessage());
            }

            return items;
        }
    }

    /**
     * Loads an IWebSocketModel from a JSON object.
     *
     * @static
     * @template TWebSocketModel The type of the model to load.
     * @param {*} message The message to load to the IWebSocketModel.
     * @param {(new () => TWebSocketModel)} modelType The model type.
     * @param {boolean} [upperCase=false] True if the first letter of the from object property name is uppercase. else false
     * @returns {TWebSocketModel} The IWebSocketModel loaded with JSON object data.
     * @memberof WebSocketModelUtility
     */
    public static loadFrom<TWebSocketModel extends IWebSocketModel>(message: any, modelType: (new () => TWebSocketModel), upperCase: boolean = false): TWebSocketModel {
        if (!isNullOrUndefined(message)) {
            const model = new modelType();
            model.loadFromWebSocketMessage(message, upperCase);
            return model as TWebSocketModel;
        }
    }

    /**
     * Loads and Array<> of IWebSocketModel from the JSON 'array'
     *
     * @static
     * @template TWebSocketModel The type of the model to load.
     * @param {any[]} array The JSON array to load to the models
     * @param {(new () => TWebSocketModel)} modelType The model type.
     * @param {boolean} [upperCase=false] True if the first letter of the from object property name is uppercase. else false
     * @returns {Array<TWebSocketModel>} The IModels.
     * @memberof WebSocketModelUtility
     */
    public static loadFromArray<TWebSocketModel extends IWebSocketModel>(array: any[], modelType: (new () => TWebSocketModel), upperCase: boolean = false): Array<TWebSocketModel> {
        if (!isNullOrUndefined(array)) {
            const items: TWebSocketModel[] = [];

            const length = array.length;
            for (let i = 0; i < length; i++) {
                items.push(this.loadFrom<TWebSocketModel>(array[i], modelType, upperCase));
            }

            return items;
        }
    }

    /**
     * Loads property values of IWebSocketModel from a JSON object.
     *
     * @static
     * @template TWebSocketModel The type of the model to load.
     * @param {*} from The JSON object to load the properties from.
     * @param {TWebSocketModel} to The IWebSocketModel to load the properties to.
     * @param {string} toName The name of the IWebSocketModel to load properties to.
     * @param {Array<string>} [excludes] The properties to exclued from the load.
     * @param {boolean} [upperCase=false] True if the first letter of the from object property name is uppercase. else false
     * @memberof WebSocketModelUtility
     */
    public static loadProperties<TWebSocketModel extends IWebSocketModel>(from: any, to: TWebSocketModel, toName: string, excludes?: Array<string>, upperCase: boolean = false): void {
        if (!isNullOrUndefined(from) && !isNullOrUndefined(to)) {
            const cacheName = `${toName}_${upperCase}`;
            const lazyLoadFunctionCache = this.lazyLoadFunctionCache.get(cacheName);

            if (isNullOrUndefined(lazyLoadFunctionCache)) {
                excludes = this.getKeyExcludes(excludes);

                const maps: { toKey: string; fromKey: string }[] = [];
                const keys = this.getKeys(to);
                const keysLength = keys.length;
                for (let i = 0; i < keysLength; i++) {
                    const key = keys[i];
                    if (this.filterKeys(excludes, key, to)) {
                        if (upperCase === true) {
                            maps.push({ toKey: key, fromKey: key.charAt(0).toUpperCase() + key.slice(1) });
                        } else {
                            maps.push({ toKey: key, fromKey: key });
                        }
                    }
                }

                const mappingFunc = (mappings: { toKey: string; fromKey: string }[]) => {
                    const mappingData = mappings;

                    return (fromData, toData) => {
                        const mappingDataLength = mappingData.length;

                        for (let i = 0; i < mappingDataLength; i++) {
                            const map = mappingData[i];

                            if (typeof fromData[map.fromKey] !== 'undefined') {
                                toData[map.toKey] = fromData[map.fromKey];
                            }
                        }
                    };
                };

                const func = mappingFunc(maps);

                this.lazyLoadFunctionCache.addOrUpdate(cacheName, func);

                func(from, to);
            } else {
                lazyLoadFunctionCache(from, to);
            }
        }
    }

    /**
     * True if object is IWebSocketModel. else false.
     *
     * @static
     * @param {*} object The object to check.
     * @returns {boolean} True if object is IWebSocketModel. else false.
     * @memberof WebSocketModelUtility
     */
    public static isIWebSocketModel(object: any): boolean {
        if (isArray(object)) {
            return object.length > 0 && !isNullOrUndefined(object[0].isIWebSocketModel) && object[0].isIWebSocketModel === true;
        } else {
            return !isNullOrUndefined(object) && !isNullOrUndefined(object.isIWebSocketModel) && object.isIWebSocketModel === true;
        }
    }

    /**
     * Gets all the keys of an object.
     *
     * @private
     * @static
     * @memberof WebSocketModelUtility
     */
    private static getKeys(obj: any): Array<string> {
        let objectToInspect;
        let keys: Array<string> = [];

        for (objectToInspect = obj; objectToInspect !== null; objectToInspect = Object.getPrototypeOf(objectToInspect)) {
            keys = keys.concat(Object.getOwnPropertyNames(objectToInspect));
        }

        return keys;
    }

    /**
     * Gets all the properties to exclued.
     *
     * @private
     * @static
     * @memberof WebSocketModelUtility
     */
    private static getKeyExcludes(excludes?: Array<string>): Array<string> {
        if (!isNullOrUndefined(excludes)) {
            return this.GLOBAL_EXCLUDE_FIELDS.concat(excludes);
        } else {
            return this.GLOBAL_EXCLUDE_FIELDS;
        }
    }

    /**
     * Removes properties that should not be mapped.
     *
     * @private
     * @static
     * @memberof WebSocketModelUtility
     */
    private static filterKeys(excludes: Array<string>, key: string, obj: any): boolean {
        return !isObject(obj[key]) && !isArray(obj[key]) && !isFunction(obj[key]) && key.charAt(0) !== '_' && (isNullOrUndefined(excludes) || excludes.indexOf(key) === -1);
    }
}
