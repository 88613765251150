import { Component, Inject, Injector, OnDestroy, OnInit, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { RelayChannelModel } from '@rift/models/restapi/RelayChannel.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class SettingsRelaysLimitsData {
    public relayChannel: RelayChannelModel;

    public constructor(relayChannel: RelayChannelModel) {
        this.relayChannel = relayChannel;
    }
}

export class SettingsRelaysLimitsResult {
    public ok: boolean = false;

    public inputFilterLowerLimitEnabled: boolean;
    public inputFilterUpperLimitEnabled: boolean;
    public inputFilterLowerLimit: number;
    public inputFilterUpperLimit: number;

    public constructor(ok: boolean, inputFilterLowerLimitEnabled?: boolean, inputFilterUpperLimitEnabled?: boolean, inputFilterLowerLimit?: number, inputFilterUpperLimit?: number) {
        this.inputFilterLowerLimitEnabled = inputFilterLowerLimitEnabled;
        this.inputFilterUpperLimitEnabled = inputFilterUpperLimitEnabled;
        this.inputFilterLowerLimit = inputFilterLowerLimit;
        this.inputFilterUpperLimit = inputFilterUpperLimit;
        this.ok = ok;
    }
}

@Component({
    selector: 'rift-settings-relays-limits',
    templateUrl: './Settings.Relays.Limits.Component.html',
    styleUrls: ['./Settings.Relays.Limits.Component.scss']
})
export class SettingsRelaysLimitsComponent extends RiftBaseComponent implements OnInit, OnDestroy {
    public static className: string = 'SettingsRelaysLimitsComponent';

    @HostBinding()
    public id: string = 'rift-settings-relays-limits';

    public limitFormGroup: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private readonly _data: SettingsRelaysLimitsData,
        private readonly _dialogRef: MatDialogRef<SettingsRelaysLimitsComponent>,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsRelaysLimitsComponent.className, 'Form values change');

        this.limitFormGroup = this._formBuilder.group({
            inputFilterLowerLimit: [this._data.relayChannel.inputFilterLowerLimit, Validators.compose([Validators.required, Validators.min(1), Validators.max(65534), Validators.pattern('[0-9]*')])],
            inputFilterUpperLimit: [this._data.relayChannel.inputFilterUpperLimit, Validators.compose([Validators.required, Validators.min(1), Validators.max(65534), Validators.pattern('[0-9]*')])],
            inputFilterLowerLimitEnabled: [false, Validators.compose([Validators.required])],
            inputFilterUpperLimitEnabled: [false, Validators.compose([Validators.required])],
        });

        this.addSubscription(this.observableHandlerBase(this.limitFormGroup.controls.inputFilterLowerLimit.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            const value = this.limitFormGroup.controls.inputFilterLowerLimit.value;
            this.limitFormGroup.controls.inputFilterUpperLimit.setValidators(Validators.compose([Validators.required, Validators.min(value), Validators.max(65534), Validators.pattern('[0-9]*')]));
            this.limitFormGroup.controls.inputFilterUpperLimit.updateValueAndValidity({ emitEvent: false });
        }), this.formValuesChangeProcess);

        this.addSubscription(this.observableHandlerBase(this.limitFormGroup.controls.inputFilterUpperLimit.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            const value = this.limitFormGroup.controls.inputFilterUpperLimit.value;
            this.limitFormGroup.controls.inputFilterLowerLimit.setValidators(Validators.compose([Validators.required, Validators.min(1), Validators.max(value), Validators.pattern('[0-9]*')]));
            this.limitFormGroup.controls.inputFilterLowerLimit.updateValueAndValidity({ emitEvent: false });
        }), this.formValuesChangeProcess);

        this.addSubscription(this.observableHandlerBase(this.limitFormGroup.controls.inputFilterLowerLimitEnabled.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            if (this.limitFormGroup.controls.inputFilterLowerLimitEnabled.value) {
                this.limitFormGroup.controls.inputFilterLowerLimit.enable();
            } else {
                this.limitFormGroup.controls.inputFilterLowerLimit.disable();
            }
        }), this.formValuesChangeProcess);

        this.addSubscription(this.observableHandlerBase(this.limitFormGroup.controls.inputFilterUpperLimitEnabled.valueChanges, this.formValuesChangeProcess).subscribe(() => {
            if (this.limitFormGroup.controls.inputFilterUpperLimitEnabled.value) {
                this.limitFormGroup.controls.inputFilterUpperLimit.enable();
            } else {
                this.limitFormGroup.controls.inputFilterUpperLimit.disable();
            }
        }), this.formValuesChangeProcess);

        this.limitFormGroup.controls.inputFilterLowerLimitEnabled.setValue(!this.isNullOrUndefined(this._data.relayChannel.inputFilterLowerLimit));
        this.limitFormGroup.controls.inputFilterUpperLimitEnabled.setValue(!this.isNullOrUndefined(this._data.relayChannel.inputFilterUpperLimit));
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public apply(): void {
        this._dialogRef.close(new SettingsRelaysLimitsResult(
            true,
            this.limitFormGroup.controls.inputFilterLowerLimitEnabled.value,
            this.limitFormGroup.controls.inputFilterUpperLimitEnabled.value,
            this.limitFormGroup.controls.inputFilterLowerLimit.value,
            this.limitFormGroup.controls.inputFilterUpperLimit.value,
        ));
    }

    public cancel(): void {
        this._dialogRef.close(new SettingsRelaysLimitsResult(false));
    }
}
