import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ScheduleResultStatusTypeEnum } from '@shared/enum/ScheduleResultStatusType.Enum';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';


export class ScheduleResultModel extends BaseModel implements IRestModel {
    public coveragePercentage: number = null;
    public errorString: string = null;
    public executed: Date = null;
    public executionTime: string = null;
    public readonly isIRestModel = true;
    public scheduleResultId: number = null;
    public successRatePercentage: number = null;
    public type: ScheduleResultStatusTypeEnum = null;

    private _executedText: string;
    private _executionTimeText: string;

    public constructor() {
        super();
    }

    public get executedText(): string {
        return this._executedText;
    }

    public get executionTimeText(): string {
        return this._executionTimeText;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-ScheduleResultModel');

        this.executed = DateTimeNonMomentUtility.fromRestApiDateTime(this.executed);

        this.setText();
    }

    public setText(): void {
        this._executedText = null;
        this._executionTimeText = null;

        if (isNullOrUndefined(this._executedText) || DateTimeUtility.isInvalidDate(this._executedText)) {
            this._executedText = DateTimeUtility.toShortDateTime(this.executed);
        }

        if (isNullOrUndefined(this._executionTimeText) || DateTimeUtility.isInvalidDate(this._executionTimeText)) {
            this._executionTimeText = DateTimeUtility.timeSpanToRelativeDuration(this.executionTime);
        }
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
