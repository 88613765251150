import { Component, HostBinding, Injector, OnInit, Input, ɵbypassSanitizationTrustStyle } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@shared/base/Base.Component';
import { LoginModel } from '@shared/models/restapi/Login.Model';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import {
    SafeCountCompatiblityCheckService,
} from '@shared/service/safecountcapabilitycheck/SafeCountCapabilityCheck.Service';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { BrowserUtility } from '@shared/utility/Browser.Utility';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestApiAccountService } from '@em/service/restapi/RestApi.Account.Service';
import { AuthTypeEnum } from '@shared/enum/AuthType.Enum';
import { environment } from '@em/environments/environment';

@Component({
    selector: 'em-login',
    templateUrl: './EMLogin.Component.html',
    styleUrls: ['./EMLogin.Component.scss']
})
export class EMLoginComponent extends BaseComponent implements OnInit {
    public static className: string = 'EMLoginComponent';

    @HostBinding()
    public id: string = 'em-login';

    @Input()
    public backgroundColor: string = '#fdfdfd';

    @Input()
    public showBaseTitle: boolean = true;

    public loginProcess: ProcessMonitorServiceProcess;
    public preAuthCheckProcess: ProcessMonitorServiceProcess;
    public preAuthProcess: ProcessMonitorServiceProcess;
    public error: string;
    public loginModel: LoginModel;
    public loadTestComplete: boolean;

    public get systemAuthOnly(): boolean{
        if (!isNullOrUndefined(this._authTypes) && this._authTypes.some(v => v !== AuthTypeEnum.system)){
            return false;
        }

        return true;
    }

    private _authTypes: Array<AuthTypeEnum> = [];

    public constructor(
        private readonly _dataPollingService: DataPollingService,
        private readonly _matDialog: MatDialog,
        private readonly _currentUserService: UserCurrentService,
        private readonly _routerService: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _accountService: RestApiAccountService,
        private readonly _injector: Injector) {
        super(_injector);

        this._matDialog.closeAll();

        if (!BrowserUtility.isCompatible) {
            this._routerService.navigate(['/', 'browser-incompatible'], { queryParamsHandling: 'preserve' });
        }

        this._dataPollingService.stopAll();

        this._currentUserService.refresh();

        this.loginProcess = this.processMonitorService.getProcess(EMLoginComponent.className, 'Login user in');
        this.preAuthCheckProcess = this.processMonitorService.getProcess(EMLoginComponent.className, 'Pre-auth check');
        this.preAuthProcess = this.processMonitorService.getProcess(EMLoginComponent.className, 'Pre-auth');

        this._matDialog.closeAll();

        this.loginModel = new LoginModel();

        this.loadTestComplete = false;

        this.preAuth();
        this.onLoadLoginTest();
    }

    public loginWithOpenIDConnect(): void {
        this.error = '';
        window.setTimeout(t => {
            if (environment.production){
                window.location.href = window.location.origin + '/api/em/account/post_auth';
            }
            else{
                window.location.href = 'https://localhost:54321/api/em/account/post_auth';
            }
            return false;
        }, 0);
    }

    public login(): void {
        this.error = '';
        this.processMonitorService.started(this.loginProcess);

        this.addSubscription(this.observableHandlerBase(this._currentUserService.login(this.loginModel, this.loginProcess), this.loginProcess).subscribe(
            result => {
                if (result.success === true) {
                    this._routerService.navigate(['/'], { queryParamsHandling: 'preserve' });
                } else {
                    this.error = result.error;
                }
            }
        ), this.loginProcess);
    }

    public onKeyDown(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.login();
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.addSubscription(this._activatedRoute.data.subscribe(data => {
            if (!isNullOrUndefined(data) && !isNullOrUndefined(data.backgroundColor)) {
                this.backgroundColor = data.backgroundColor;
            }
            if (!isNullOrUndefined(data) && !isNullOrUndefined(data.showBaseTitle)) {
                this.showBaseTitle = data.showBaseTitle;
            }
        }));

        this.addSubscription(this._activatedRoute.queryParams.subscribe(data => {
            if (!isNullOrUndefined(data.error)){
                this.error = data.error;
            }
        }));
    }

    private onLoadLoginTest() {
        this.processMonitorService.started(this.loginProcess);

        this.addSubscription(this.observableHandlerBase(this._currentUserService.login(this.loginModel, this.loginProcess), this.loginProcess).subscribe(
            result => {
                this.loadTestComplete = true;

                if (result.success === true) {
                    this._routerService.navigate(['/'], { queryParamsHandling: 'preserve' });
                }
            }
        ), this.loginProcess);
    }

    private preAuth(){
        this.processMonitorService.started(this.preAuthProcess);

        this.addSubscription(this.observableHandlerBase(this._accountService.preAuth(this.preAuthProcess), this.preAuthProcess).subscribe(
            result => {
                this._authTypes = result.result;
            }
        ), this.preAuthProcess);
    }
}
