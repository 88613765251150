import { Component, HostBinding, Inject, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@shared/base/Base.Component';
import { AppHostEnum } from '@shared/interface/IConfigurationService';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class BuildVersionErrorDialogData {
    public constructor() {
    }
}

@Component({
    selector: 'rift-validate-build-version-error-dialog',
    templateUrl: './BuildVersionError.Dialog.Component.html',
    styleUrls: ['./BuildVersionError.Dialog.Component.scss'],
})
export class BuildVersionErrorDialogComponent extends BaseComponent {
    public static className: string = 'BuildVersionErrorDialogComponent';

    @HostBinding()
    public id: string = 'rift-validate-build-version-error-dialog';

    public isNullOrUndefined = isNullOrUndefined;

    public constructor(
        @Inject(MAT_DIALOG_DATA) private readonly _data: BuildVersionErrorDialogData,
        private readonly _zone: NgZone,
        private readonly _dialogRef: MatDialogRef<BuildVersionErrorDialogComponent>,
        private readonly _router: Router,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;
    }

    public onDisconnectClick(): void {
        this._zone.run(() => {
            this._dialogRef.close();
            if (this.configurationService.hostApp === AppHostEnum.rift) {
                this._router.navigate(['connect']);
            } else {
                this._router.navigate(['dashboard']);
            }
        });
    }
}
