export enum LineExclusionTypeEnum {
    noCountIfEntered = 0,
    countIfEntered = 1,
    countIfExits = 2
}

export class LineExclusionTypeEnumHelpers {
    public static toFullLongName(value: LineExclusionTypeEnum): string {
        switch (value) {
            case LineExclusionTypeEnum.noCountIfEntered:
                return 'Do not count if zone entered';
            case LineExclusionTypeEnum.countIfEntered:
                return 'Count if zone entered';
            case LineExclusionTypeEnum.countIfExits:
                return 'Count if zone exited';
        }
    }
}
