import { TaskToExecuteResultModel } from '@em/models/restapi/TaskToExecuteResult.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { TaskToExecuteStateEnum } from '@shared/enum/TaskToExecuteState.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';


const EXCLUDE_FIELDS = ['timeAddedText', 'timeStartedText', 'timeFinishedText', 'timeStarted', 'timeFinished', 'durationText', 'isCurrentlyExecuting'];

export class TaskToExecuteModel extends BaseModel implements IRestModel {
    public dateAdded: Date = null;

    public deviceSerial: number = null;
    public friendlyDeviceSerial: string = null;
    public readonly isIRestModel = true;
    public results: Array<TaskToExecuteResultModel> = null;
    public taskEntryID: number = null;
    public taskName: string = null;
    public taskState: TaskToExecuteStateEnum = null;

    private _durationText: string = null;
    private _duration: number = null;
    private _timeAddedText: string = null;
    private _timeFinished: Date = null;
    private _timeFinishedText: any = null;
    private _timeStarted: Date = null;
    private _timeStartedText: string = null;

    public constructor() {
        super();
    }

    public get durationText(): string {
        return this._durationText;
    }

    public get duration(): number {
        return this._duration;
    }

    public get isCurrentlyExecuting(): boolean {
        return this.taskState === TaskToExecuteStateEnum.executing;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-TaskToExecuteModel', EXCLUDE_FIELDS);

        this.results = RestModelUtility.loadFromArray(restModel.Results, TaskToExecuteResultModel);
        this.dateAdded = DateTimeNonMomentUtility.fromRestApiDateTime(this.dateAdded);

        if (isNullOrUndefined(this._timeAddedText) || DateTimeUtility.isInvalidDate(this._timeAddedText)) {
            this._timeAddedText = DateTimeUtility.toShortDateTime(this.dateAdded);
        }

        if (isNullOrUndefined(this._timeStarted)) {
            if (!isNullOrUndefined(this.results) && this.results.length > 0) {
                this._timeStarted = this.results[this.results.length - 1].timeStarted;
            }
        }

        if (isNullOrUndefined(this._timeFinished)) {
            if (!isNullOrUndefined(this.results) && this.results.length > 0) {
                this._timeFinished = this.results[this.results.length - 1].timeFinished;
            }
        }

        if (isNullOrUndefined(this._timeStartedText) || DateTimeUtility.isInvalidDate(this._timeStartedText)) {
            this._timeStartedText = DateTimeUtility.toShortDateTime(this.timeStarted);
        }

        if (isNullOrUndefined(this._timeFinishedText) || DateTimeUtility.isInvalidDate(this._timeFinishedText)) {
            this._timeFinishedText = DateTimeUtility.toShortDateTime(this.timeFinished);
        }

        if (isNullOrUndefined(this._durationText) && !isNullOrUndefined(this.timeStarted) && !isNullOrUndefined(this.timeFinished)) {
            this._duration = DateTimeUtility.toDurationMilliseconds(this.timeStarted, this.taskState !== TaskToExecuteStateEnum.executing ? this.timeFinished : new Date());
            this._durationText = DateTimeUtility.millisecondsToHumanizeDuration(this._duration);
        }

        if (this.taskState === TaskToExecuteStateEnum.readyToExecute) {
            this._durationText = 'Not started';
        }

        if (!isNullOrUndefined(this.results) && this.results.length > 0) {
            if (this.results[0].state !== TaskToExecuteStateEnum.executing && !isNullOrUndefined(this.results[0].duration)) {
                this._duration = this.results[0].duration;
            } else if (this.results.length > 1 && !isNullOrUndefined(this.results[1].duration)) {
                this._duration = this.results[1].duration;
            }
            this._durationText = DateTimeUtility.millisecondsToHumanizeDuration(this._duration);
        }
    }

    public get timeAddedText(): string {
        return this._timeAddedText;
    }

    public get timeFinished(): Date {
        return this._timeFinished;
    }

    public get timeFinishedText(): string {
        return this._timeFinishedText;
    }

    public get timeStarted(): Date {
        return this._timeStarted;
    }

    public get timeStartedText(): string {
        return this._timeStartedText;
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this, EXCLUDE_FIELDS),
        };
    }
}
