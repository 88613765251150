<div class="spacing-bottom"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                Site Details
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="siteDetailsFormGroup">
                    <div fxLayout="row">
                        <mat-form-field fxFlex="50"
                            class="spacing-right">
                            <input id="site-deviceId-input"
                                type="text"
                                #deviceId
                                matInput
                                formControlName="deviceId"
                                placeholder="Device ID">
                            <span matSuffix>{{ global?.propertyHasChangesText('deviceId') }}</span>
                            <mat-error>{{getFormControlErrorMessage(siteDetailsFormGroup.controls['deviceId'])}}</mat-error>
                            <mat-hint align="end">{{deviceId.value.length}} / 159</mat-hint>
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input id="site-deviceName-input"
                                type="text"
                                #deviceName
                                matInput
                                formControlName="deviceName"
                                placeholder="Device Name">
                            <span matSuffix>{{ global?.propertyHasChangesText('deviceName') }}</span>
                            <mat-error>{{getFormControlErrorMessage(siteDetailsFormGroup.controls['deviceName'])}}</mat-error>
                            <mat-hint align="end">{{deviceName.value.length}} / 59</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <input id="site-userString-input"
                                type="text"
                                #userString
                                matInput
                                formControlName="userString"
                                placeholder="User String">
                            <span matSuffix>{{ global?.propertyHasChangesText('userString') }}</span>
                            <mat-error>{{getFormControlErrorMessage(siteDetailsFormGroup.controls['userString'])}}</mat-error>
                            <mat-hint align="end">{{userString.value.length}} / 59</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex="50"
                            class="spacing-right">
                            <input id="site-siteId-input"
                                type="text"
                                #siteId
                                matInput
                                formControlName="siteId"
                                placeholder="Site ID">
                            <span matSuffix>{{ global?.propertyHasChangesText('siteId') }}</span>
                            <mat-error>{{getFormControlErrorMessage(siteDetailsFormGroup.controls['siteId'])}}</mat-error>
                            <mat-hint align="end">{{siteId.value.length}} / 59</mat-hint>
                        </mat-form-field>
                        <mat-form-field fxFlex="50">
                            <input id="site-siteName-input"
                                type="text"
                                #siteName
                                matInput
                                formControlName="siteName"
                                placeholder="Site Name">
                            <span matSuffix>{{ global?.propertyHasChangesText('siteName') }}</span>
                            <mat-error>{{getFormControlErrorMessage(siteDetailsFormGroup.controls['siteName'])}}</mat-error>
                            <mat-hint align="end">{{siteName.value.length}} / 59</mat-hint>
                        </mat-form-field>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                Site Details
            </mat-card-title>
            <mat-card-content>
                <span>These strings set in the Site Information panel help to identify this device at a later date.
                    Ensure they are filled
                    out correctly so that, when connecting remotely in the future, it is easy to understand where
                    it is located.</span>
                <br>
                <span class="new-block">These strings can also be read by Estate Manager and used to help identify
                    the device and, if set up correctly,
                    automatically filter devices into the groups in the Address Book.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div fxLayout.gt-sm="row"
    fxLayout.lt-md="column">
    <div ngClass.gt-sm="spacing-right"
        ngClass.lt-md="spacing-bottom"
        fxFlex.gt-sm="60"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card fxFlex>
            <mat-card-title>
                Localization
            </mat-card-title>
            <mat-card-content>
                <form [formGroup]="localizationFormGroup">
                    <div *ngIf="(isDeviceGen(UnitGenerationEnum.gen4) | async) === true || (isDeviceGen(UnitGenerationEnum.gazelle) | async) === true"
                        fxLayout="row">
                        <mat-form-field fxFlex>
                            <input id="site-local-input"
                                type="text"
                                #locale
                                matInput
                                formControlName="locale"
                                placeholder="Locale">
                            <span matSuffix>{{ timeSetup?.propertyHasChangesText('locale') }}</span>
                            <mat-error>{{getFormControlErrorMessage(localizationFormGroup.controls['locale'])}}</mat-error>
                            <mat-hint align="end">{{locale.value.length}} / 59</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <mat-select id="site-timezone-select"
                                #timezone
                                formControlName="timezone"
                                placeholder="Timezone">
                                <mat-option *ngFor="let timeZone of timeZones | orderBy: 'timeOffsetText'"
                                    [value]="timeZone">
                                    {{ timeZone.timeOffsetText }}
                                </mat-option>
                            </mat-select>
                            <span matSuffix>{{ timeSetup?.propertyHasChangesText('timezoneIrisysIndex') }}</span>
                            <mat-error>{{getFormControlErrorMessage(localizationFormGroup.controls['scheduleResultsCullingPeriod'])}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row">
                        <mat-checkbox id="site-autoDst-checkbox"
                            #autoDstAdjust
                            formControlName="autoDstAdjust">Automatically adjust for DST
                        </mat-checkbox>
                        <div fxFlex></div>
                        <shr-progress-button-spinner [disabled]="isReadOnly === true"
                            [raised]="true"
                            [diameter]="30"
                            text="Sync Time"
                            mode="indeterminate"
                            color="primary"
                            [active]="syncTimeProcess.isRunning === true"
                            (shrClick)="syncTime()">
                        </shr-progress-button-spinner>
                    </div>
                    <div fxLayout="row"
                        *ngIf="(isDeviceCapable(DeviceCapabilitiesEnum.ntp) | async) === true">
                        <mat-form-field fxFlex>
                            <input id="site-ntpServer-input"
                                type="text"
                                #nTPServer
                                matInput
                                formControlName="nTPServer"
                                placeholder="NTP Server">
                            <span matSuffix>{{ timeSetup?.propertyHasChangesText('nTPServer') }}</span>
                            <mat-error>{{getFormControlErrorMessage(localizationFormGroup.controls['nTPServer'])}}</mat-error>
                            <mat-hint align="end">{{nTPServer.value.length}} / 400</mat-hint>
                        </mat-form-field>
                    </div>
                </form>
            </mat-card-content>
            <ng-template [ngIf]="connectionService.isOnline">
                <mat-card-title>
                    Device Time
                </mat-card-title>
                <mat-card-content>
                    <div class="time-row"
                        fxLayout="row">
                        <div class="label">
                            Selected
                        </div>
                        <div class="value">
                            {{ isNullOrUndefined(timeSelected) ? 'Loading' : timeSelected }} - {{
                                isNullOrUndefined(selectedTimeZone) ? '' : selectedTimeZone.timeOffsetText
                                }}
                        </div>
                    </div>
                    <div class="time-row"
                        fxLayout="row">
                        <div class="label">
                            Current
                        </div>
                        <div class="value">
                            {{ isNullOrUndefined(timeCurrent) ? 'Loading' : timeCurrent }} - {{
                                isNullOrUndefined(timeSetup) || isNullOrUndefined(timeSetup.timeZone)
                                ? '' : timeSetup.timeZone.timeOffsetText }}
                        </div>
                    </div>
                    <div class="time-row"
                        fxLayout="row">
                        <div class="label">
                            UTC
                        </div>
                        <div class="value">
                            {{ isNullOrUndefined(timeUTC) ? 'Loading' : timeUTC }} - {{
                                isNullOrUndefined(timeZoneUTC) ? '' : timeZoneUTC.timeOffsetText
                                }}
                        </div>
                    </div>
                </mat-card-content>
            </ng-template>
        </mat-card>
    </div>
    <div fxFlex.gt-sm="40"
        fxFlex.lt-md="100"
        fxLayout="column">
        <mat-card class="shr-card-sub help-card"
            fxFlex>
            <mat-card-title>
                Localization
            </mat-card-title>
            <mat-card-content>
                <span>All device data is stored in UTC. The locale settings here are used by the tools to convert
                    the UTC time into the
                    local time for display or reporting purposes.</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>