import { IResultModel } from '@rift/models/restapi/Result.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { TimeOfFlightFrameRateEnum, TimeOfFlightFrameRateEnumHelpers } from '@shared/enum/TimeOfFlightFrameRate.Enum';

export interface ITimeOfFlightConfigDataModel extends IRestModel {
    frameRate: TimeOfFlightFrameRateEnum;
}

export class TimeOfFlightConfigDataModel extends BaseModel implements ITimeOfFlightConfigDataModel, IResultModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public frameRate: TimeOfFlightFrameRateEnum = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-TimeOfFlightConfigDataModel');

        this.frameRate = TimeOfFlightFrameRateEnumHelpers.fromRestApi(restModel.FrameRate);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            FrameRate: TimeOfFlightFrameRateEnumHelpers.toFullName(this.frameRate)
        };
    }
}
