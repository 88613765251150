import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isArray } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';

/**
 * A data polling event.
 *
 * @export
 * @class DataPollingEvent
 */
export class DataPollingEvent {

    /**
     * The delay befor the event first fires in ms.
     *
     * @type {number}
     * @memberof DataPollingEvent
     */
    public delay: number;

    /**
     * The interval at witch the event will fire in ms
     *
     * @type {number}
     * @memberof DataPollingEvent
     */
    public interval: number;

    /**
     * The name of the data polling event.
     *
     * @type {string}
     * @memberof DataPollingEvent
     */
    public name: string;

    /**
     * The unique identifier of the event.
     *
     * @type {number}
     * @memberof DataPollingEvent
     */
    public id: number;

    /**
     * The processes associated with the data polling event
     *
     * @memberof DataPollingEvent
     */
    public processes: ProcessMonitorServiceProcess[];

    /**
     * The data poll event.
     *
     * @memberof DataPollingEvent
     */
    public poll: Subject<DataPollingEvent> = new Subject<DataPollingEvent>();

    /**
     * Creates an instance of DataPollingEvent.
     *
     * @param {string} name The name of the data polling event.
     * @param {number} delay The delay befor the event first fires in ms.
     * @param {number} interval The interval at witch the event will fire in ms
     * @memberof DataPollingEvent
     */
    public constructor(name: string, delay: number, interval: number, process: ProcessMonitorServiceProcess | ProcessMonitorServiceProcess[]) {
        this.name = name;
        this.delay = delay;
        this.interval = interval;
        this.processes = isArray(process) ? process : [process];
    }
}
