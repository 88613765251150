import { Injectable } from '@angular/core';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { RoutingRuleCollectionModel } from '@em/models/restapi/RoutingRuleCollection.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiSettingsService } from '@em/service/restapi/RestApi.Settings.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable()
export class RoutingRuleService extends EmBaseService {
    private _get: RoutingRuleCollectionModel;

    private _getLoadingTracker = new ObservableTracker<RoutingRuleCollectionModel>();

    private _saveAllChangesLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiSettingsService: RestApiSettingsService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._get = null;
    }
    public get(process?: ProcessMonitorServiceProcess): Observable<RoutingRuleCollectionModel> {
        if (isNullOrUndefined(this._get)) {
            return this._getLoadingTracker
                .getLoading()
                .observable(this._restApiSettingsService.getRoutingRules(process).pipe(
                    map(i => {
                        this._get = i;
                        return i;
                    })
                ));
        } else {
            return of(this._get);
        }
    }
    public saveAllChanges(rules: RoutingRuleCollectionModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._saveAllChangesLoadingTracker
            .getLoading(rules)
            .observable(this._restApiSettingsService.saveAllRoutingRules(rules, process).pipe(tap(result => this.clearCache())));
    }
}
