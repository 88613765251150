import { IShapeModel, ShapeModel } from '@rift/models/restapi/ShapeModel';
import { CountModeEnum, CountModeEnumHelpers } from '@shared/enum/CountMode.Enum';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface ILineModel extends IShapeModel {
    iD: number;
    enabled: boolean;
    countMode: CountModeEnum;
    name: string;
}

export class LineModel extends ShapeModel implements ILineModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public iD: number = null;
    public enabled: boolean = null;
    public countMode: CountModeEnum = null;
    public name: string = null;

    public constructor() {
        super();

        this.registerProperty('iD');
        this.registerProperty('enabled');
        this.registerProperty('countMode');
        this.registerProperty('name');
    }

    public loadFromRestApiModel(restModel: any): void {
        super.loadFromRestApiModel(restModel);

        RestModelUtility.loadProperties(restModel, this, 'RIFT-LineModel', ['countMode']);

        this.countMode = CountModeEnumHelpers.fromRestApi(restModel.CountMode);

        this.setPropertyOriginalValue('iD', this.iD);
        this.setPropertyOriginalValue('enabled', this.enabled);
        this.setPropertyOriginalValue('countMode', this.countMode);
        this.setPropertyOriginalValue('name', this.name);
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
            CountMode: CountModeEnumHelpers.toRestApi(this.countMode),
        };
    }
}
