import { Injectable } from '@angular/core';
import { ConnectionModel } from '@em/models/restapi/Connection.Model';
import { ConnectionCollectionModel } from '@em/models/restapi/ConnectionCollection.Model';
import { ConnectionGroupModel } from '@em/models/restapi/ConnectionGroup.Model';
import { ConnectionGroupCollectionModel } from '@em/models/restapi/ConnectionGroupCollection.Model';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiOutboundConnectionService } from '@em/service/restapi/RestApi.OutboundConnection.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';


@Injectable()
export class OutboundConnectionService extends EmBaseService {

    private _addGroupLoadingTracker = new ObservableTracker<ConnectionGroupModel>();

    private _deleteConnectionLoadingTracker = new ObservableTracker<ResultModel>();

    private _deleteGroupLoadingTracker = new ObservableTracker<ResultModel>();
    private _getConnectionsCache: ConnectionCollectionModel;

    private _getConnectionsLoadingTracker = new ObservableTracker<ConnectionCollectionModel>();
    private _getGroupsCache: ConnectionGroupCollectionModel;

    private _getGroupsLoadingTracker = new ObservableTracker<ConnectionGroupCollectionModel>();

    private _updateConnectionLoadingTracker = new ObservableTracker<ResultModel>();

    private _updateGroupLoadingTracker = new ObservableTracker<ResultModel>();

    private _updateRuleLoadingTracker = new ObservableTracker<ConnectionModel>();

    public constructor(
        private readonly _restApiOutboundConnectionService: RestApiOutboundConnectionService) {
        super();
    }
    public addConnection(connection: ConnectionModel, process?: ProcessMonitorServiceProcess): Observable<ConnectionModel> {
        return this._updateRuleLoadingTracker
            .getLoading(connection)
            .observable(this._restApiOutboundConnectionService.addConnection(connection, process).pipe(
                map(result => {
                    if (!isNullOrUndefined(result)) {
                        connection.connectionId = result.connectionId;
                        return connection;
                    }
                }),
            ));
    }
    public addGroup(group: ConnectionGroupModel, process?: ProcessMonitorServiceProcess): Observable<ConnectionGroupModel> {
        return this._addGroupLoadingTracker
            .getLoading(group)
            .observable(this._restApiOutboundConnectionService.addGroup(group, process).pipe(
                map(result => {
                    if (!isNullOrUndefined(result)) {
                        group.connectionGroupId = result.connectionGroupId;
                        return group;
                    }
                }),
            ));
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getConnectionsCache = null;
        this._getGroupsCache = null;
    }
    public deleteConnection(connectionId: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._deleteConnectionLoadingTracker
            .getLoading(connectionId)
            .observable(this._restApiOutboundConnectionService.deleteConnection(connectionId, process).pipe(tap(() => this.clearCache())));
    }
    public deleteGroup(groupId: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._deleteGroupLoadingTracker
            .getLoading(groupId)
            .observable(this._restApiOutboundConnectionService.deleteGroup(groupId, process).pipe(tap(() => this.clearCache())));
    }
    public getConnections(process?: ProcessMonitorServiceProcess): Observable<ConnectionCollectionModel> {
        if (isNullOrUndefined(this._getConnectionsCache)) {
            return this._getConnectionsLoadingTracker
                .getLoading()
                .observable(this._restApiOutboundConnectionService.getConnections(process).pipe(
                    map(result => {
                        this._getConnectionsCache = result;
                        return this._getConnectionsCache;
                    })
                ));
        } else {
            return of(this._getConnectionsCache);
        }
    }
    public getGroups(process?: ProcessMonitorServiceProcess): Observable<ConnectionGroupCollectionModel> {
        if (isNullOrUndefined(this._getGroupsCache)) {
            return this._getGroupsLoadingTracker
                .getLoading()
                .observable(this._restApiOutboundConnectionService.getGroups(process).pipe(
                    map(result => {
                        this._getGroupsCache = result;
                        return this._getGroupsCache;
                    })
                ));
        } else {
            return of(this._getGroupsCache);
        }
    }
    public updateConnection(connection: ConnectionModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateConnectionLoadingTracker
            .getLoading(connection)
            .observable(this._restApiOutboundConnectionService.updateConnection(connection, process).pipe(tap(() => this.clearCache())));
    }
    public updateGroup(group: ConnectionGroupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._updateGroupLoadingTracker
            .getLoading(group)
            .observable(this._restApiOutboundConnectionService.updateGroup(group, process).pipe(tap(() => this.clearCache())));
    }
}
