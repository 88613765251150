import { Component, Inject, Injector, OnDestroy, OnInit, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConnectionGroupModel } from '@em/models/restapi/ConnectionGroup.Model';
import { ConnectionGroupCollectionModel } from '@em/models/restapi/ConnectionGroupCollection.Model';
import { OutboundConnectionService } from '@em/service/data/outboundconnection/OutboundConnection.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class AddEditRemoveSelectGroupDialogData {
    public group?: ConnectionGroupModel;
    public mode: 'add' | 'edit' | 'remove' | 'select';

    public constructor(mode: 'add' | 'edit' | 'remove' | 'select', group?: ConnectionGroupModel) {
        this.mode = mode;
        this.group = group;
    }
}

export class AddEditRemoveSelectGroupDialogResult {
    public success: boolean;
    public group?: ConnectionGroupModel;

    public constructor(success: boolean, group?: ConnectionGroupModel) {
        this.success = success;
        this.group = group;
    }
}

@Component({
    selector: 'em-settings-outbound-connections-add-edit-remove-group',
    templateUrl: './Settings.OutboundConnections.AddEditRemoveSelectGroup.Component.html',
    styleUrls: ['./Settings.OutboundConnections.AddEditRemoveSelectGroup.Component.scss']
})
export class SettingsOutboundConnectionsAddEditRemoveSelectGroupComponent extends BaseComponent implements OnDestroy, OnInit {
    public static className: string = 'SettingsOutboundConnectionsAddEditRemoveSelectGroupComponent';

    @HostBinding()
    public id: string = 'em-settings-outbound-connections-add-edit-remove-group';

    public mode: 'add' | 'edit' | 'remove' | 'select';
    public addFormGroup: FormGroup;
    public editFormGroup: FormGroup;
    public removeFormGroup: FormGroup;
    public selectFormGroup: FormGroup;

    public baseName: string;

    public group: ConnectionGroupModel;
    public groups: ConnectionGroupCollectionModel;

    public getGroupsProcess: ProcessMonitorServiceProcess;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    public constructor(
        private readonly _eventsService: EventsService,
        private readonly _outboundConnectionService: OutboundConnectionService,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private readonly _data: AddEditRemoveSelectGroupDialogData,
        private readonly _dialogRef: MatDialogRef<SettingsOutboundConnectionsAddEditRemoveSelectGroupComponent>,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsAddEditRemoveSelectGroupComponent.className, 'Form values change');

        this.mode = this._data.mode;

        switch (this.mode) {
            case 'add':
                this.group = new ConnectionGroupModel();

                this.addFormGroup = this._formBuilder.group({
                    name: ['', Validators.compose([Validators.required, Validators.maxLength(128)])],
                });

                this.addFormGroup.controls.name.setValue(this.group.name);

                this.addSubscription(this.observableHandlerBase(this.addFormGroup.controls.name.valueChanges, this.formValuesChangeProcess).subscribe(() => {
                    this.group.name = this.addFormGroup.controls.name.value;
                }));
                break;
            case 'edit':
                this.group = this._data.group;
                this.baseName = this._data.group.name;

                this.editFormGroup = this._formBuilder.group({
                    name: ['', Validators.compose([Validators.required, Validators.maxLength(128)])],
                });

                this.editFormGroup.controls.name.setValue(this.group.name);

                this.addSubscription(this.observableHandlerBase(this.editFormGroup.controls.name.valueChanges, this.formValuesChangeProcess).subscribe(() => {
                    this.group.name = this.editFormGroup.controls.name.value;
                }));

                break;
            case 'remove':

                this.getGroupsProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsAddEditRemoveSelectGroupComponent.className, 'Getting Groups.');

                this.removeFormGroup = this._formBuilder.group({
                    group: ['', Validators.compose([Validators.required])],
                });

                this.getGroups();
                break;
            case 'select':

                this.getGroupsProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsAddEditRemoveSelectGroupComponent.className, 'Getting Groups.');

                this.selectFormGroup = this._formBuilder.group({
                    group: ['', Validators.compose([Validators.required])],
                });

                this.getGroups();
                break;
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public cancel(): void {
        if (!this.isNullOrUndefined(this.group)) {
            this.group.name = this.baseName;
        }
        this._dialogRef.close(new AddEditRemoveSelectGroupDialogResult(false));
    }

    public add(): void {
        this._dialogRef.close(new AddEditRemoveSelectGroupDialogResult(true, this.group));
    }

    public save(): void {
        this._dialogRef.close(new AddEditRemoveSelectGroupDialogResult(true, this.group));
    }

    public remove(): void {
        this._dialogRef.close(new AddEditRemoveSelectGroupDialogResult(true, this.removeFormGroup.controls.group.value));
    }

    public select(): void {
        this._dialogRef.close(new AddEditRemoveSelectGroupDialogResult(true, this.selectFormGroup.controls.group.value === 'ungrouped' ? null : this.selectFormGroup.controls.group.value));
    }

    private getGroups(): void {
        this.addSubscription(this.observableHandlerBase(this._outboundConnectionService.getGroups(this.getGroupsProcess), this.getGroupsProcess).subscribe(
            result => {
                this.groups = result;
            }
        ), this.getGroupsProcess);
    }
}
