import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    AutoConfigRemoteDeviceSelectDialogComponent,
    RemoteDeviceSelectDialogData,
    RemoteDeviceSelectDialogResult,
} from '@rift/components/shared/auto-config/dialog/RemoteDeviceSelect.Dialog.Component';
import { HistogramConfigModel } from '@rift/models/restapi/HistogramConfig.Model';
import { InputSourceModel } from '@rift/models/restapi/InputSource.Model';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { PointModel } from '@rift/models/restapi/Point.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { RegisterPushEntryModel } from '@rift/models/restapi/RegisterPushEntry.Model';
import { RelayRegisterModel } from '@rift/models/restapi/RelayRegister.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { LineService } from '@rift/service/data/line/Line.Service';
import { PolygonService } from '@rift/service/data/polygon/Polygon.Service';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { RelayService } from '@rift/service/data/relay/Relay.Service';
import { AutoConfig } from '@rift/service/device/auto-config/AutoConfig';
import { RegisterConfig } from '@rift/service/device/auto-config/IRegisterConfig';
import { RelayConfig } from '@rift/service/device/auto-config/RelayConfig';
import { IAssociatedRegisterConfig } from '@rift/shared/IAssociatedRegisterConfig';
import { IRegisterConfig } from '@rift/shared/IRegisterConfig';
import { RegisterTypeConfig } from '@rift/shared/Settings.RegisterConfig';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';
import { HistogramTypeEnum } from '@shared/enum/HistogramType.Enum';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { RelayOperationTypeEnum } from '@shared/enum/RelayOperationType.Enum';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isFunction, isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

export type AutoConfigResult = {
    complete: boolean;
    errorMessage?: string;
    skip?: number;
};

export type GetInputSourcesHandler = (newRegister: RegisterBaseModel, typeConfig: IRegisterConfig, registerConfig: RegisterConfig<any>) => InputSourceModel[];
export type GetPushEntriesHandler = (newRegister: RegisterBaseModel, typeConfig: IRegisterConfig, registerConfig: RegisterConfig<any>) => RegisterPushEntryModel[];

// Registers that have a parent master register that should be used for
// deletion and change of settings
export const DO_NOT_AUTO_DELETE = [
    RegisterTypeEnum.basicExternal,
    RegisterTypeEnum.occupancy,
    RegisterTypeEnum.occupancyReset,
    RegisterTypeEnum.occupancyWait,
    RegisterTypeEnum.queueWait,
    RegisterTypeEnum.occupancyReset,
    RegisterTypeEnum.fifoOccupancy,
    RegisterTypeEnum.fifoOccupancyTimeout,
];

@Injectable()
export class AutoConfigService extends RiftBaseService {

    public constructor(
        private readonly _deviceService: DeviceService,
        private readonly _registersService: RegisterService,
        private readonly _lineService: LineService,
        private readonly _polygonService: PolygonService,
        private readonly _relayService: RelayService,
        private readonly _dialog: MatDialog,
    ) {
        super();
    }

    public setConfig(autoConfig: AutoConfig, getInputSourcesHandler?: GetInputSourcesHandler, getPushEntriesHandler?: GetPushEntriesHandler, process?: ProcessMonitorServiceProcess): Observable<AutoConfigResult> {
        return this.removeAllRegisters().pipe(
            flatMap((removeAllRegistersResult => {
                if (removeAllRegistersResult.complete === true) {
                    return this.addRegisters(autoConfig.master.registers, getInputSourcesHandler, getPushEntriesHandler, process).pipe(
                        flatMap((addRegistersResult) => {
                            if (addRegistersResult.complete === true) {
                                return this.setRelays(autoConfig.master.relays, process).pipe(
                                    map((setRelaysResult) => {
                                        this._deviceService.clearCache();
                                        this._registersService.clearCache();
                                        this._lineService.clearCache();
                                        this._polygonService.clearCache();
                                        this._relayService.clearCache();

                                        return setRelaysResult;
                                    })
                                );
                            } else {
                                return of(addRegistersResult);
                            }
                        }),
                    );
                } else {
                    return of(removeAllRegistersResult);
                }
            })),
        );
    }

    private setRelays(relayConfig: RelayConfig, process?: ProcessMonitorServiceProcess): Observable<AutoConfigResult> {
        if (!isNullOrUndefined(relayConfig)) {
            return this._relayService.getSettings(process).pipe(
                flatMap((deviceDisableRelaySettings) => {
                    if (isNullOrUndefined(deviceDisableRelaySettings) || isNullOrUndefined(deviceDisableRelaySettings.error)) {
                        const dRelayChannelsLength = deviceDisableRelaySettings.relayChannels.length;
                        for (let drcI = 0; drcI < dRelayChannelsLength; drcI++) {
                            const relayChannel = deviceDisableRelaySettings.relayChannels[drcI];
                            relayChannel.operationType = RelayOperationTypeEnum.disabled;
                            relayChannel.relayRegisters = new RestModelChangeTrackerArray<RelayRegisterModel>();
                        }

                        return this._relayService.setSettings(deviceDisableRelaySettings, process).pipe(
                            flatMap((disableRelaysResult) => {
                                this._registersService.clearCache();
                                this._lineService.clearCache();
                                this._polygonService.clearCache();
                                if (isNullOrUndefined(disableRelaysResult) || isNullOrUndefined(disableRelaysResult.error)) {
                                    return this._relayService.getSettings(process).pipe(
                                        flatMap((deviceSetRelaySettings) => this._registersService.getRegisters(process).pipe(
                                                flatMap((allRegistersResult) => {
                                                    if (isNullOrUndefined(allRegistersResult.error)) {
                                                        if (isNullOrUndefined(deviceSetRelaySettings) || isNullOrUndefined(deviceSetRelaySettings.error)) {
                                                            let needUpdate = false;
                                                            if (deviceSetRelaySettings.relayChannels.length === relayConfig.channels.length) {

                                                                if (!isNullOrUndefined(relayConfig.cANIOEnabled)) {
                                                                    deviceSetRelaySettings.cANIOEnabled = relayConfig.cANIOEnabled;
                                                                    needUpdate = true;
                                                                }

                                                                if (!isNullOrUndefined(relayConfig.globalHeartbeatPeriod)) {
                                                                    deviceSetRelaySettings.globalHeartbeatPeriod = relayConfig.globalHeartbeatPeriod;
                                                                    needUpdate = true;
                                                                }


                                                                const cRelayChannelsLength = relayConfig.channels.length;
                                                                for (let rcI = 0; rcI < cRelayChannelsLength; rcI++) {
                                                                    const cRelayChannel = relayConfig.channels[rcI];
                                                                    const dRelayChannel = deviceSetRelaySettings.relayChannels[rcI];

                                                                    if (!isNullOrUndefined(cRelayChannel.operationType)) {
                                                                        dRelayChannel.operationType = cRelayChannel.operationType;
                                                                        needUpdate = true;
                                                                    }
                                                                    if (!isNullOrUndefined(cRelayChannel.defaultState)) {
                                                                        dRelayChannel.defaultState = cRelayChannel.defaultState;
                                                                        needUpdate = true;
                                                                    }
                                                                    if (!isNullOrUndefined(cRelayChannel.pullupEnabled)) {
                                                                        dRelayChannel.pullupEnabled = cRelayChannel.pullupEnabled;
                                                                        needUpdate = true;
                                                                    }
                                                                    if (!isNullOrUndefined(cRelayChannel.heartbeatPulseWidth)) {
                                                                        dRelayChannel.heartbeatPulseWidth = cRelayChannel.heartbeatPulseWidth;
                                                                        needUpdate = true;
                                                                    }
                                                                    if (!isNullOrUndefined(cRelayChannel.inputFilterLowerLimit)) {
                                                                        dRelayChannel.inputFilterLowerLimit = cRelayChannel.inputFilterLowerLimit;
                                                                        needUpdate = true;
                                                                    }
                                                                    if (!isNullOrUndefined(cRelayChannel.inputFilterUpperLimit)) {
                                                                        dRelayChannel.inputFilterUpperLimit = cRelayChannel.inputFilterUpperLimit;
                                                                        needUpdate = true;
                                                                    }
                                                                    if (!isNullOrUndefined(cRelayChannel.operationType)) {
                                                                        dRelayChannel.operationType = cRelayChannel.operationType;
                                                                        needUpdate = true;
                                                                    }

                                                                    dRelayChannel.relayRegisters = new RestModelChangeTrackerArray<RelayRegisterModel>();

                                                                    if (!isNullOrUndefined(cRelayChannel.registers) && cRelayChannel.registers.length > 0) {
                                                                        const cRelayRegistersLength = cRelayChannel.registers.length;
                                                                        const dAllRegistersLength = allRegistersResult.items.length;
                                                                        for (let crI = 0; crI < cRelayRegistersLength; crI++) {
                                                                            const cRelayRegisterConfig = cRelayChannel.registers[crI];
                                                                            const newRelayRegisterModel = new RelayRegisterModel();

                                                                            let foundRegister = false;
                                                                            for (let drI = 0; drI < dAllRegistersLength; drI++) {
                                                                                const dRegister = allRegistersResult.items[drI];

                                                                                if (dRegister.registerName === cRelayRegisterConfig.registerName) {
                                                                                    newRelayRegisterModel.registerID = dRegister.registerIndex;
                                                                                    foundRegister = true;
                                                                                    break;
                                                                                }
                                                                            }

                                                                            if (foundRegister === false) {
                                                                                newRelayRegisterModel.registerName = cRelayRegisterConfig.registerName;
                                                                            }

                                                                            if (!isNullOrUndefined(cRelayRegisterConfig.pulseWidth)) {
                                                                                newRelayRegisterModel.pulseWidth = cRelayRegisterConfig.pulseWidth;
                                                                            }
                                                                            if (!isNullOrUndefined(cRelayRegisterConfig.relayMode)) {
                                                                                newRelayRegisterModel.relayMode = cRelayRegisterConfig.relayMode;
                                                                            }

                                                                            dRelayChannel.relayRegisters.push(newRelayRegisterModel);
                                                                            needUpdate = true;
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            if (needUpdate === true) {
                                                                return this._relayService.setSettings(deviceSetRelaySettings, process).pipe(
                                                                    map((updateRelaysResult) => {
                                                                        this._relayService.clearCache();
                                                                        if (isNullOrUndefined(updateRelaysResult) || isNullOrUndefined(updateRelaysResult.error)) {
                                                                            return { complete: true };
                                                                        } else {
                                                                            return { complete: false, errorMessage: `Unable to update relays. API result ${updateRelaysResult.error}` };
                                                                        }
                                                                    }),
                                                                );
                                                            } else {
                                                                return of({ complete: true });
                                                            }
                                                        } else {
                                                            return of({ complete: false, errorMessage: `Unable to get relays. API result ${deviceSetRelaySettings.error}` });
                                                        }
                                                    } else {
                                                        return of({ complete: false, errorMessage: `Unable to get device registers. API result ${allRegistersResult.error}` });
                                                    }
                                                }),
                                            ))
                                    );
                                } else {
                                    return of({ complete: false, errorMessage: `Unable to disable relays. API result ${disableRelaysResult.error}` });
                                }
                            }),
                        );
                    } else {
                        return of({ complete: false, errorMessage: `Unable to get device relays. API result ${deviceDisableRelaySettings.error}` });
                    }
                }),
            );
        } else {
            return of({ complete: true });
        }
    }

    private addRegisterConfigsDependencyOrdered(parent: RegisterConfig<any>, registerConfigs: RegisterConfig<any>[], ordered: RegisterConfig<any>[]): void {
        if (isNullOrUndefined(parent.associatedRegisters)) {
            if (!ordered.some(i => i.properties.registerName === parent.properties.registerName)) {
                ordered.push(parent);
            }
        } else {
            const associatedRegistersLength = parent.associatedRegisters.length;
            const registerConfigsLength = registerConfigs.length;

            for (let ari = 0; ari < associatedRegistersLength; ari++) {
                const associatedRegisterConfig = parent.associatedRegisters[ari];
                for (let rci = 0; rci < registerConfigsLength; rci++) {
                    const registerConfig = registerConfigs[rci];

                    if (associatedRegisterConfig.registerName === registerConfig.properties.registerName) {
                        this.addRegisterConfigsDependencyOrdered(registerConfig, registerConfigs, ordered);
                        if (!ordered.some(i => i.properties.registerName === parent.properties.registerName)) {
                            ordered.push(parent);
                        }
                        break;
                    }
                }
            }
        }
    }

    private addRegisters(registerConfigs: RegisterConfig<any>[], getInputSourcesHandler?: GetInputSourcesHandler, getPushEntriesHandler?: GetPushEntriesHandler, process?: ProcessMonitorServiceProcess): Observable<AutoConfigResult> {
        if (!isNullOrUndefined(registerConfigs)) {
            const registerConfigsDependencyOrdered: RegisterConfig<any>[] = [];

            const registerConfigsLength = registerConfigs.length;
            for (let i = 0; i < registerConfigsLength; i++) {
                const registerConfig = registerConfigs[i];
                this.addRegisterConfigsDependencyOrdered(registerConfig, registerConfigs, registerConfigsDependencyOrdered);
            }

            let addRegistersTreeObs: Observable<AutoConfigResult> = of({ complete: true });
            const length = registerConfigsDependencyOrdered.length;
            for (let i = 0; i < length; i++) {
                addRegistersTreeObs = addRegistersTreeObs.pipe(
                    flatMap((lastResult) => {
                        if (lastResult.complete === true) {
                            return this._registersService.getRegisters(process).pipe(
                                flatMap((allRegistersResult) => {
                                    if (isNullOrUndefined(allRegistersResult.error)) {
                                        return this._lineService.getLines(process).pipe(
                                            flatMap((allLinesResult) => {
                                                if (isNullOrUndefined(allLinesResult.error)) {
                                                    const registerConfig = registerConfigsDependencyOrdered[i];
                                                    return this.addRegister(registerConfig, allRegistersResult.items, allLinesResult.items, getInputSourcesHandler, getPushEntriesHandler, process);
                                                } else {
                                                    return of({ complete: false, errorMessage: `Unable to get device lines. API result ${allLinesResult.error}` });
                                                }
                                            }),
                                        );
                                    } else {
                                        return of({ complete: false, errorMessage: `Unable to get device registers. API result ${allRegistersResult.error}` });
                                    }
                                }),
                            );
                        } else {
                            return of(lastResult);
                        }
                    }),
                );
            }

            return addRegistersTreeObs;
        } else {
            return of({ complete: true });
        }
    }

    private addRegister(registerConfig: RegisterConfig<any>, allRegisters?: RegisterBaseModel[], allLines?: LineModel[], getInputSourcesHandler?: GetInputSourcesHandler, getPushEntriesHandler?: GetPushEntriesHandler, process?: ProcessMonitorServiceProcess): Observable<AutoConfigResult> {
        const typeConfig: IRegisterConfig = RegisterTypeConfig[registerConfig.type];
        const newRegister: RegisterBaseModel = RegisterTypeConfig[registerConfig.type].new();
        newRegister.registerType = registerConfig.type;
        newRegister.setIndexes();
        newRegister.setFlags();

        const propertyKeys = Object.keys(registerConfig.properties);
        const propertyKeysLength = propertyKeys.length;
        for (let pI = 0; pI < propertyKeysLength; pI++) {
            const key = propertyKeys[pI];
            newRegister[key] = registerConfig.properties[key];
        }

        return this.setRegisterHistogramConfigs(newRegister, typeConfig, registerConfig, process).pipe(
            flatMap((registerHistogramResult) => {
                if (registerHistogramResult.complete === true) {
                    return this.setRegisterAssociatedRegisters(newRegister, typeConfig, registerConfig, allRegisters, allLines, process).pipe(
                        flatMap((associatedRegistersResult) => {
                            if (associatedRegistersResult.complete === true) {
                                return this.setRegisterInputSources(newRegister, typeConfig, registerConfig, getInputSourcesHandler, process).pipe(
                                    flatMap((registerInputSourcesResult) => {
                                        if (registerInputSourcesResult.complete === true) {
                                            return this.setRegisterPushEntries(newRegister, typeConfig, registerConfig, getPushEntriesHandler, process).pipe(
                                                flatMap((pushEntriesResult) => {
                                                    if (pushEntriesResult.complete === true) {
                                                        return this._registersService.addRegister(newRegister, process).pipe(
                                                            flatMap((addRegisterResult) => {
                                                                this._registersService.clearCache();
                                                                this._lineService.clearCache();
                                                                this._polygonService.clearCache();

                                                                if (isNullOrUndefined(addRegisterResult) || isNullOrUndefined(addRegisterResult.error)) {
                                                                    if (!isNullOrUndefined(registerConfig.line)) {
                                                                        return this._registersService.getRegisters(process).pipe(
                                                                            flatMap((allRegistersResult) => {
                                                                                if (!isNullOrUndefined(allRegistersResult) && isNullOrUndefined(allRegistersResult.error)) {
                                                                                    return this._lineService.getLines(process).pipe(
                                                                                        flatMap((allLinesResult) => {
                                                                                            if (!isNullOrUndefined(allLinesResult) && isNullOrUndefined(allLinesResult.error)) {
                                                                                                const addedRegister = allRegistersResult.items.find(i => i.registerName === newRegister.registerName);
                                                                                                if (!isNullOrUndefined(addedRegister)) {
                                                                                                    const line = allLinesResult.items.find(i => i.iD === addedRegister.lineIds[0]);
                                                                                                    if (!isNullOrUndefined(line)) {
                                                                                                        let needUpdate = false;

                                                                                                        if (!isNullOrUndefined(registerConfig.line.countMode)) {
                                                                                                            line.countMode = registerConfig.line.countMode;
                                                                                                            needUpdate = true;
                                                                                                        }

                                                                                                        if (!isNullOrUndefined(registerConfig.line.points) && registerConfig.line.points.length > 0) {
                                                                                                            line.points = registerConfig.line.points.map(p => {
                                                                                                                const point = new PointModel();
                                                                                                                point.x = p.x;
                                                                                                                point.y = p.y;
                                                                                                                return point;
                                                                                                            });
                                                                                                            needUpdate = true;
                                                                                                        }

                                                                                                        if (needUpdate === true) {
                                                                                                            return this._lineService.updateLine(line, line.iD, process).pipe(
                                                                                                                map((updateLineResult) => {
                                                                                                                    if (isNullOrUndefined(updateLineResult) || isNullOrUndefined(updateLineResult.error)) {
                                                                                                                        return { complete: true };
                                                                                                                    } else {
                                                                                                                        return { complete: false, errorMessage: `Unable to update line ${newRegister.registerName}. API result ${updateLineResult.error}` };
                                                                                                                    }
                                                                                                                }),
                                                                                                            );
                                                                                                        } else {
                                                                                                            return of({ complete: true });
                                                                                                        }
                                                                                                    } else {
                                                                                                        return of({ complete: false, errorMessage: `Unable to fined added register line ${newRegister.registerName} not on device` });
                                                                                                    }
                                                                                                } else {
                                                                                                    return of({ complete: false, errorMessage: `Unable to fined added register ${newRegister.registerName} not on device` });
                                                                                                }
                                                                                            } else {
                                                                                                return of({ complete: false, errorMessage: `Unable to get device lines. API result ${allLinesResult.error}` });
                                                                                            }
                                                                                        }),
                                                                                    );
                                                                                } else {
                                                                                    return of({ complete: false, errorMessage: `Unable to get device registers. API result ${allRegistersResult.error}` });
                                                                                }
                                                                            }),
                                                                        );
                                                                    } else {
                                                                        return of({ complete: true });
                                                                    }
                                                                } else {
                                                                    return of({ complete: false, errorMessage: `Unable to add ${newRegister.registerName}. API result ${addRegisterResult.error}` });
                                                                }
                                                            }),
                                                        );
                                                    } else {
                                                        return of(pushEntriesResult);
                                                    }
                                                }),
                                            );
                                        } else {
                                            return of(registerInputSourcesResult);
                                        }
                                    }),
                                );
                            } else {
                                return of(associatedRegistersResult);
                            }
                        }),
                    );
                } else {
                    return of(registerHistogramResult);
                }
            }),
        );
    }

    private setRegisterHistogramConfigs(newRegister: RegisterBaseModel, typeConfig: IRegisterConfig, registerConfig: RegisterConfig<any>, process?: ProcessMonitorServiceProcess): Observable<AutoConfigResult> {
        if (!isNullOrUndefined(registerConfig) && !isNullOrUndefined(registerConfig.histograms) && registerConfig.histograms.length > 0) {
            const histogramsLength = registerConfig.histograms.length;
            for (let hI = 0; hI < histogramsLength; hI++) {
                const histogram = registerConfig.histograms[hI];

                const histogramConfig = new HistogramConfigModel();
                histogramConfig.minimumValue = histogram.minimumValue;
                histogramConfig.binWidth = histogram.binWidth;
                histogramConfig.numberOfBins = histogram.numberOfBins;

                switch (histogram.type) {
                    case HistogramTypeEnum.dwell:
                        histogramConfig.expression = `X,D;`;
                        break;
                    case HistogramTypeEnum.height:
                        histogramConfig.expression = `h;`;
                        break;
                    case HistogramTypeEnum.instantDwell:
                        histogramConfig.expression = `I,D;`;
                        break;
                    case HistogramTypeEnum.unattendedTime:
                        histogramConfig.expression = `I,D;`;
                        break;
                }

                newRegister.histogramConfigs.push(histogramConfig);
            }

            return of({ complete: true });
        } else {
            return of({ complete: true });
        }
    }

    private setRegisterPushEntries(newRegister: RegisterBaseModel, typeConfig: IRegisterConfig, registerConfig: RegisterConfig<any>, getPushEntriesHandler?: GetPushEntriesHandler, process?: ProcessMonitorServiceProcess): Observable<AutoConfigResult> {
        if (!isNullOrUndefined(registerConfig) && !isNullOrUndefined(registerConfig.pushEntries)) {
            if (isNullOrUndefined(getPushEntriesHandler)) {
                // TODO: need to add dialog at some point only used by safe count currently and not needed
                return of({ complete: true });
            } else {
                (newRegister as any).registerPushEntries = getPushEntriesHandler(newRegister, typeConfig, registerConfig);
                return of({ complete: true });
            }
        } else {
            return of({ complete: true });
        }
    }

    private setRegisterInputSources(newRegister: RegisterBaseModel, typeConfig: IRegisterConfig, registerConfig: RegisterConfig<any>, getInputSourcesHandler?: GetInputSourcesHandler, process?: ProcessMonitorServiceProcess): Observable<AutoConfigResult> {
        if (!isNullOrUndefined(registerConfig) && !isNullOrUndefined(registerConfig.inputSources)) {
            if (isNullOrUndefined(getInputSourcesHandler)) {
                return this._dialog.open(AutoConfigRemoteDeviceSelectDialogComponent, { data: new RemoteDeviceSelectDialogData(registerConfig, newRegister), disableClose: true }).afterClosed().pipe(
                    flatMap((selectRemoteDevicesResult: RemoteDeviceSelectDialogResult) => {
                        if (!isNullOrUndefined(selectRemoteDevicesResult) && !isNullOrUndefined(selectRemoteDevicesResult.inputSources) && selectRemoteDevicesResult.inputSources.length > 0) {
                            (newRegister as any).inputSources = selectRemoteDevicesResult.inputSources;
                        }

                        return of({ complete: true });
                    }),
                );
            } else {
                (newRegister as any).inputSources = getInputSourcesHandler(newRegister, typeConfig, registerConfig);
                return of({ complete: true });
            }
        } else {
            return of({ complete: true });
        }
    }

    private setRegisterAssociatedRegisters(newRegister: RegisterBaseModel, typeConfig: IRegisterConfig, registerConfig: RegisterConfig<any>, allRegisters?: RegisterBaseModel[], allLines?: LineModel[], process?: ProcessMonitorServiceProcess): Observable<AutoConfigResult> {
        if (!isNullOrUndefined(registerConfig) && !isNullOrUndefined(registerConfig.associatedRegisters) && !isNullOrUndefined(allRegisters)) {
            if (allRegisters.length >= registerConfig.associatedRegisters.length && registerConfig.associatedRegisters.every(ar => allRegisters.some(al => al.registerName === ar.registerName))) {
                if (!isNullOrUndefined(typeConfig.associatedRegisterConfigs) && typeConfig.associatedRegisterConfigs.length > 0) {
                    const rcStoreInCounts: { storeIn: string; count: number; tcAssociatedConfig: IAssociatedRegisterConfig }[] = [];

                    const rcLength = registerConfig.associatedRegisters.length;
                    for (let rcI = 0; rcI < rcLength; rcI++) {
                        const rcAssociatedConfig = registerConfig.associatedRegisters[rcI];
                        let tcUseAssociatedConfig: IAssociatedRegisterConfig = null;

                        const tcLength = typeConfig.associatedRegisterConfigs.length;
                        for (let tcI = 0; tcI < tcLength; tcI++) {
                            const tcAssociatedConfig = typeConfig.associatedRegisterConfigs[tcI];

                            if (rcAssociatedConfig.storeIn === tcAssociatedConfig.registerStorePropertyName ||
                                rcAssociatedConfig.storeIn === tcAssociatedConfig.registersStorePropertyName ||
                                rcAssociatedConfig.storeIn === tcAssociatedConfig.linesStorePropertyName ||
                                rcAssociatedConfig.storeIn === tcAssociatedConfig.lineStorePropertyName) {
                                tcUseAssociatedConfig = tcAssociatedConfig;
                                break;
                            }
                        }

                        if (!isNullOrUndefined(tcUseAssociatedConfig)) {
                            const linkRegister = allRegisters.find(r => r.registerName === rcAssociatedConfig.registerName && r.registerType === rcAssociatedConfig.registerType);
                            if (!isNullOrUndefined(linkRegister)) {
                                if (!isNullOrUndefined(tcUseAssociatedConfig.associatedRequireLineCountMode)) {
                                    if (!isNullOrUndefined(allLines)) {
                                        const line = allLines.find(l => l.iD === linkRegister.lineIds[0]);
                                        if (!isNullOrUndefined(allLines)) {
                                            if (!tcUseAssociatedConfig.associatedRequireLineCountMode.some(i => i === line.countMode)) {
                                                return of({ complete: false, errorMessage: `${newRegister.registerName} Attempt to associate with associatedRequireLineCountMode with line that has incorrect count mode` });
                                            }
                                        } else {
                                            return of({ complete: false, errorMessage: `${newRegister.registerName} Attempt to associate with associatedRequireLineCountMode line not found` });
                                        }
                                    } else {
                                        return of({ complete: false, errorMessage: `${newRegister.registerName} Attempt to associate with associatedRequireLineCountMode with no line data` });
                                    }
                                }

                                if (!isNullOrUndefined(tcUseAssociatedConfig.associatedRequireRegisterTypes) && !tcUseAssociatedConfig.associatedRequireRegisterTypes.some(i => i === linkRegister.registerType)) {
                                    return of({ complete: false, errorMessage: `${newRegister.registerName} Attempt to associate a register with an invalid type` });
                                }

                                const rcStoreInCount = rcStoreInCounts.find(sic => sic.storeIn === rcAssociatedConfig.storeIn);
                                if (!isNullOrUndefined(rcStoreInCount)) {
                                    rcStoreInCount.count++;
                                } else {
                                    rcStoreInCounts.push({ storeIn: rcAssociatedConfig.storeIn as string, count: 1, tcAssociatedConfig: tcUseAssociatedConfig });
                                }

                                if (isFunction(tcUseAssociatedConfig.onAutoConfigAddAssociatedRegister)) {
                                    tcUseAssociatedConfig.onAutoConfigAddAssociatedRegister(tcUseAssociatedConfig, rcAssociatedConfig, linkRegister, newRegister);
                                } else {
                                    if (tcUseAssociatedConfig.isLinesStore === true) {
                                        if (isNullOrUndefined(newRegister[tcUseAssociatedConfig.linesStorePropertyName])) {
                                            newRegister[tcUseAssociatedConfig.linesStorePropertyName] = [];
                                        }
                                        newRegister[tcUseAssociatedConfig.linesStorePropertyName].push(linkRegister.lineIds[0]);
                                    } else if (tcUseAssociatedConfig.isLineStore === true) {
                                        newRegister[tcUseAssociatedConfig.lineStorePropertyName] = linkRegister.lineIds[0];
                                    } else if (tcUseAssociatedConfig.isRegistersStore === true) {
                                        if (isNullOrUndefined(newRegister[tcUseAssociatedConfig.registersStorePropertyName])) {
                                            newRegister[tcUseAssociatedConfig.registersStorePropertyName] = [];
                                        }
                                        newRegister[tcUseAssociatedConfig.registersStorePropertyName].push(linkRegister.registerIndex);
                                    } else if (tcUseAssociatedConfig.isRegisterStore === true) {
                                        newRegister[tcUseAssociatedConfig.registerStorePropertyName] = linkRegister.registerIndex;
                                    }
                                }
                            } else {
                                return of({ complete: false, errorMessage: `Attempt to associate ${newRegister.registerName} to register not on device` });
                            }
                        } else {
                            return of({ complete: false, errorMessage: `No ${newRegister.registerName} associatedRegisterConfig found matching storeIn` });
                        }
                    }

                    const storeInCountsLength = rcStoreInCounts.length;
                    for (let scI = 0; scI < storeInCountsLength; scI++) {
                        const storeInCount = rcStoreInCounts[scI];
                        if ((isNullOrUndefined(storeInCount.tcAssociatedConfig.associatedMax) || storeInCount.count > storeInCount.tcAssociatedConfig.associatedMax) || (isNullOrUndefined(storeInCount.tcAssociatedConfig.associatedMin) || storeInCount.count < storeInCount.tcAssociatedConfig.associatedMin)) {
                            return of({ complete: false, errorMessage: `${newRegister.registerName} invalid number of associated registers` });
                        }
                    }

                    return of({ complete: true });
                } else {
                    return of({ complete: false, errorMessage: `Attempt to associate ${newRegister.registerName} with register that has no associatedRegisterConfigs` });
                }
            } else {
                return of({ complete: false, errorMessage: `Attempt to associate ${newRegister.registerName} to register not on device` });
            }
        } else {
            return of({ complete: true });
        }
    }

    private removeAllRegisters(process?: ProcessMonitorServiceProcess): Observable<AutoConfigResult> {
        return this._registersService.getRegisters(process).pipe(
            flatMap((registers) => this.removeRegisters(registers.items, process)),
        );
    }

    private removeRegisters(registers: RegisterBaseModel[], process?: ProcessMonitorServiceProcess, skip?: number): Observable<AutoConfigResult> {
        let deleteSubTree: Observable<AutoConfigResult> = of({ complete: true });

        const registersLength = registers.length;
        const startAt = !isNullOrUndefined(skip) ? skip : 0;
        for (let rI = startAt; rI < registersLength; rI++) {
            const register = registers[rI];
            if (!DO_NOT_AUTO_DELETE.some(rt => rt === register.registerType)) {
                const dependents = RegisterBaseUtility.getDependents(register, registers);
                if (isNullOrUndefined(dependents) || dependents.registers.length === 0) {
                    deleteSubTree = this._registersService.deleteRegister(register.registerIndex, process).pipe(
                        map(((deleteResult) => {
                            if (isNullOrUndefined(deleteResult) || isNullOrUndefined(deleteResult.error)) {
                                return { complete: true };
                            } else if (!isNullOrUndefined(deleteResult.error) && deleteResult.error.toLocaleLowerCase() === 'can not remove register as it has dependants') {
                                return { complete: true, skip: !isNullOrUndefined(skip) ? skip + 1 : 1 };
                            } else {
                                return { complete: false, errorMessage: `Unable to delete ${register.registerName}. API result ${deleteResult.error}` };
                            }
                        }))
                    );
                    break;
                }
            }
        }

        return deleteSubTree.pipe(
            flatMap((deleteResult) => {
                if (deleteResult.complete === true) {
                    return this._registersService.getRegisters(process).pipe(
                        flatMap((unDeletedRegisters) => {
                            const unDeletedRegistersExe = [];
                            const unDeletedRegistersLength = unDeletedRegisters.items.length;
                            for (let rI = 0; rI < unDeletedRegistersLength; rI++) {
                                const register = unDeletedRegisters.items[rI];
                                if (!DO_NOT_AUTO_DELETE.some(rt => rt === register.registerType)) {
                                    unDeletedRegistersExe.push(register);
                                }
                            }

                            if (unDeletedRegistersExe.length === 0) {
                                this._registersService.clearCache();
                                this._lineService.clearCache();
                                this._polygonService.clearCache();
                                return of({ complete: true });
                            } else {
                                return this.removeRegisters(unDeletedRegisters.items, process, deleteResult.skip);
                            }
                        }),
                    );
                } else {
                    return of(deleteResult);
                }
            })
        );
    }
}
