import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class RegisterCountLogModel extends BaseModel implements IRestModel {
    public deviceSerial: number = null;
    public logEntryID: number = null;
    public readonly isIRestModel = true;
    public registerId: number = null;
    public timestamp: Date = null;
    public value: number = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-RegisterCountLogModel');

        this.timestamp = DateTimeNonMomentUtility.fromRestApiDateTime(this.timestamp);
    }

    public toRestApiModel(): any {
        throw new Error('Method not implemented.');
    }
}
