import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from '@rift/service/connection/Connection.Service';

@Component({template: ''})
export class RiftConnectionRouteComponent {
  constructor(private _connectionService: ConnectionService,
              private _router: Router) {
    this._router.navigate(['rift', 'em', this._connectionService.hostFriendlySerial, 'summary']);
  }
}
