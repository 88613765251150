import { ComponentType } from '@angular/cdk/portal';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';
import { ComponentRef } from '@angular/core';
import { MatMenuPanel } from '@angular/material/menu';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * Dialogs must implement this interface if they are to be opened from
 * the nav bar.
 *
 * @export
 * @interface INavBarDialogComponent
 */
export interface INavBarDialogComponent {
    closeDialog: Subject<null>;
}

/**
 * Components must implement this interface if they are to be shown in
 * the nav bar.
 *
 * @export
 * @interface INavBarMenuComponent
 */
export interface INavBarMenuComponent {
    closeMenu: Subject<null>;
    parentMenu?: MatMenuPanel | undefined;
}

/**
 * A nav bar action
 *
 * @export
 * @class NavBarAction
 */
export class NavBarAction {

    /**
     * True if this is save changes action. else false.
     * will be added to side of actions menu.
     *
     * @type {boolean}
     * @memberof NavBarAction
     */
    public isSaveChanges: boolean = false;
    /**
     * Fired when the nav bar action is clicked.
     *
     * @type {Subject<null>}
     * @memberof NavBarAction
     */
    public onButtonClick: Subject<null> = new Subject();
    /**
     * The component to add to the nav bar.
     *
     * @type {ComponentType<INavBarMenuComponent>}
     * @memberof NavBarAction
     */
    public menuComponent: ComponentType<INavBarMenuComponent>;
    /**
     * The menu component ref
     *
     * @type {ComponentRef<INavBarMenuComponent>}
     * @memberof NavBarAction
     */
    public menuComponentRef: ComponentRef<INavBarMenuComponent>;
    /**
     * The dialog component to show when the action is clicked.
     *
     * @type {ComponentType<INavBarDialogComponent>}
     * @memberof NavBarAction
     */
    public dialogComponent: ComponentType<INavBarDialogComponent>;
    /**
     * The dialog component ref.
     *
     * @type {MatDialogRef<INavBarDialogComponent>}
     * @memberof NavBarAction
     */
    public dialogComponentRef: MatDialogRef<INavBarDialogComponent>;
    /**
     * The name of the action.
     *
     * @type {string}
     * @memberof NavBarAction
     */
    public name: string;
    /**
     * The name of the font awsom icon to show
     *
     * @type {string}
     * @memberof NavBarAction
     */
    public faIconName: string;
    /**
     * The name of the materials icon to show
     *
     * @type {string}
     * @memberof NavBarAction
     */
    public matIconName: string;
    /**
     * The order location for this action.
     *
     * @type {number}
     * @memberof NavBarAction
     */
    public order: number;
    /**
     * The text to show in the nav bar.
     *
     * @type {string}
     * @memberof NavBarAction
     */
    public text: string;
    /**
     * True if the nav bar button is disabled else false.
     *
     * @type {boolean}
     * @memberof NavBarAction
     */
    public disabled: boolean = false;

    /**
     *Creates an instance of NavBarAction.
     *
     * @memberof NavBarAction
     */
    public constructor() {
    }

    /**
     * True if the action has a dialog component. else false.
     *
     * @readonly
     * @type {boolean}
     * @memberof NavBarAction
     */
    public get hasDialogComponent(): boolean {
        return !isNullOrUndefined(this.dialogComponent);
    }

    /**
     * True if the action has a display component. else false.
     *
     * @readonly
     * @type {boolean}
     * @memberof NavBarAction
     */
    public get hasMenuComponent(): boolean {
        return !isNullOrUndefined(this.menuComponent);
    }
}
