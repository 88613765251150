import { IResultModel } from '@rift/models/restapi/Result.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { ITrackPropertyChanges } from '@shared/interface/ITrackPropertyChanges';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface ICollectionModel<TRestModel extends IRestModel & ITrackPropertyChanges> {
    items: Array<TRestModel>;
}

export abstract class CollectionModel<TRestModel extends IRestModel & ITrackPropertyChanges> extends BaseModel implements ICollectionModel<TRestModel>, IResultModel, IRestModel {

    public error: string = null;

    public items: Array<TRestModel> = [];
    public readonly isIRestModel = true;
    public statusCode: number = null;
    private _modelType: (new () => TRestModel);

    public constructor(modelType: (new () => TRestModel)) {
        super();
        this._modelType = modelType;
    }

    public loadFromRestApiModel(restModel: any): void {
        if (!isNullOrUndefined(restModel.Error)) {
            this.error = restModel.Error;
        }
        if (!isNullOrUndefined(restModel.StatusCode)) {
            this.error = restModel.StatusCode;
        }

        this.items = RestModelUtility.loadFromArray(restModel, this._modelType);
    }

    public toRestApiModel(): any {
        return RestModelUtility.toJsonArray(this.items);
    }
}
