import { BaseModel } from '@shared/base/Base.Model';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { IRestModel } from '@shared/interface/IRestModel';
import { ITrackPropertyChanges } from '@shared/interface/ITrackPropertyChanges';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IChangeTrackedCollectionModel<TRestModel extends IRestModel & ITrackPropertyChanges> {
    items: RestModelChangeTrackerArray<TRestModel>;
}

export class ChangeTrackedCollectionModel<TRestModel extends IRestModel & ITrackPropertyChanges> extends BaseModel implements IRestModel {

    public error: string = null;
    public items: RestModelChangeTrackerArray<TRestModel> = new RestModelChangeTrackerArray<TRestModel>();
    public readonly isIRestModel = true;
    public statusCode: number = null;

    private _modelType: (new () => TRestModel);

    public constructor(modelType: (new () => TRestModel)) {
        super();
        this._modelType = modelType;
        this.registerChangeTrackerArray('items');
    }

    public loadFromRestApiModel(restModel: any): void {
        if (!isNullOrUndefined(restModel.Error)) {
            this.error = restModel.Error;
        }
        if (!isNullOrUndefined(restModel.StatusCode)) {
            this.error = restModel.StatusCode;
        }

        this.items = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel, this._modelType);

        this.setPropertyOriginalValue('items', this.items);
    }

    public toRestApiModel(): any {
        return RestModelUtility.toJsonArray(this.items);
    }
}
