import { RegisterTypeConfig } from '@rift/shared/Settings.RegisterConfig';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { RegisterTypeCategoryEnum } from '@shared/enum/RegisterTypeCategoryEnum';
import { isNullOrUndefined } from '@shared/utility/General.Utility';


export const getRegisterTypeInCategory = (category: RegisterTypeCategoryEnum): Array<RegisterTypeEnum> => {
    const inCategory: RegisterTypeEnum[] = [];

    const length = RegisterTypeConfig.length;
    for (let index = 0; index < length; index++) {
        const config = RegisterTypeConfig[index];
        if (!isNullOrUndefined(config)) {
            if (config.category === category) {
                inCategory.push(index as RegisterTypeEnum);
            }
        }
    }
    return inCategory;
};
