import { Component, ElementRef, HostBinding, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { ConfigJsonCompareService, IConfigChanges, IGroupedConfigSection, ISectionChange } from '@rift/service/configjsoncompare/ConfigJsonCompare.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { DiagnosticsLogRangeEnum } from '@shared/enum/DiagnosticsLogRange.Enum';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { ILoadDate } from '@shared/interface/ILoadData';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isArray, isNull } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { EMPTY, Observable, Subject, zip } from 'rxjs';
import { expand, map } from 'rxjs/operators';
import { ConfigHistoryBaseComponent } from '@rift/components/settings/confighistory/ConfigHistory.Base.Component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PageEvent } from '@angular/material/paginator';

interface IKeySearchOptions {
    searchValue: string;
    includeUnChanged?: boolean;
}

@Component({
    selector: 'rift-config-history',
    templateUrl: './ConfigHistory.Component.html',
    styleUrls: ['./ConfigHistory.Component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigHistoryComponent extends ConfigHistoryBaseComponent implements IFillHeight, ILoadDate {

    public getHistoricalLogsProcess: ProcessMonitorServiceProcess;

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @HostBinding()
    public id: string = 'rift-config-history';

    public master: DeviceModel;
    public timeSetup: TimeSetupModel;
    public changeCount: number = 0;
    public pageIndex: number = 0;
    public pageSize: number = 5;
    public sectionVMs: IGroupedConfigSection[] = [];
    public isLoadingLogs: boolean = false;
    public searchFormGroup: FormGroup;
    public includeUnChanged: boolean = true;

    private _changes: IConfigChanges[] = [];
    private _filterSub: Subject<IConfigChanges[]> = new Subject();

    public constructor(
        private readonly _sanitizer: DomSanitizer,
        private readonly _formBuilder: FormBuilder,
        private readonly _configJsonCompareService: ConfigJsonCompareService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_sanitizer, _injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(ConfigHistoryComponent.className, this.loadDataProcessText);
        this.getHistoricalLogsProcess = this.processMonitorService.getProcess(ConfigHistoryComponent.className, 'Getting historical log data');

        this.searchFormGroup = this._formBuilder.group({
            fromDate: ['', Validators.compose([])],
            toDate: ['', Validators.compose([])],
            keyValue: ['', Validators.compose([])],
        });

        this.initConnectionState();

        this.sectionVMs = this.ConfigSectionsEnumHelpers.getGrouped();
        this.sectionVMs.forEach(vm => this.setSectionVMsFlags(vm));

        this.addSubscription(this._configJsonCompareService.changeFound.subscribe((change) => {
            this._changes.push(change);
            this.filter();
        }));
    }

    public includeUnChangedChanged(event: MatCheckboxChange): void {
        this.includeUnChanged = event.checked;
    }

    public clearSearch(): void {
        this.searchFormGroup.controls.fromDate.setValue('');
        this.searchFormGroup.controls.toDate.setValue('');
        this.searchFormGroup.controls.keyValue.setValue('');
        this.filter();
    }

    public search(): void {
        if (this.formGroupTracker.valid === true) {
            this.filter();
        }
    }

    public setSectionVMsFlags(vm: IGroupedConfigSection): void {
        vm.show = true;
        vm.text = this.ConfigSectionsEnumHelpers.toDisplayName(vm.section);
        if (!this.isNullOrUndefined(vm.children) && vm.children.length > 0) {
            vm.children.forEach(cvm => this.setSectionVMsFlags(cvm));
        }
    }

    public sectionShowChanged() {
        this.filter();
    }

    public toggleShowSection(event: MatCheckboxChange, sectionVM: IGroupedConfigSection): void {
        sectionVM.show = event.checked;
        this.filter();
    }

    public pageChange(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.filter();
    }

    public filter(): void {
        const start = this.pageIndex * this.pageSize;
        const end = start + this.pageSize;

        const filtered = this._changes.filter(
            (change) => this.filterIncludeChange(change)
        );
        this.changeCount = filtered.length;

        this._filterSub.next(filtered.sort((a, b) => {
            const aToDate = new Date(a.toDate);
            const bToDate = new Date(b.toDate);
            return aToDate > bToDate ? -1 : aToDate < bToDate ? 1 : 0;
        }).slice(start, end));
    }

    public filterIncludeChange(change: IConfigChanges): boolean {
        const length = change.sectionsChanged.length;

        let keySearch = null;
        if (this.searchFormGroup.valid === true) {
            const fromDate = new Date(this.searchFormGroup.controls.fromDate.value);
            const toDate = new Date(this.searchFormGroup.controls.toDate.value);
            if (!this.DateTimeUtility.isInvalidDate(fromDate) && !this.DateTimeUtility.isInvalidDate(toDate)) {
                toDate.setHours(23, 59, 59);
                if (!(change.toDate >= fromDate && change.toDate <= toDate)) {
                    return false;
                }
            }

            if (!this.isNullOrUndefined(this.searchFormGroup.controls.keyValue.value) && !StringUtility.isEmptyOrWhiteSpace(this.searchFormGroup.controls.keyValue.value)) {
                keySearch = this.searchFormGroup.controls.keyValue.value;
            }
        }

        for (let i = 0; i < length; i++) {
            if (this.filterIncludeSection(change.sectionsChanged[i], this.sectionVMs, { searchValue: keySearch, includeUnChanged: this.includeUnChanged }) === true) {
                return true;
            }
        }
        return false;
    }

    public filterIncludeSection(sectionChanged: ISectionChange, parentSectionVM: IGroupedConfigSection | IGroupedConfigSection[], keySearchOptions?: IKeySearchOptions): boolean {
        const sectionVM = this.getSectionVM(sectionChanged.section, parentSectionVM);
        if (sectionVM.show === true) {
            let keyFound = null;
            if (!this.isNullOrUndefined(keySearchOptions) && !this.isNullOrUndefined(keySearchOptions.searchValue) && !StringUtility.isEmptyOrWhiteSpace(keySearchOptions.searchValue) && !this.isNullOrUndefined(sectionChanged.settingsChanged) && sectionChanged.settingsChanged.length > 0) {
                keyFound = false;
                const updatedLength = sectionChanged.settingsChanged.length;
                for (let i = 0; i < updatedLength; i++) {
                    const settingChanged = sectionChanged.settingsChanged[i];
                    if (settingChanged.valueFrom === keySearchOptions.searchValue || settingChanged.valueTo === keySearchOptions.searchValue) {
                        keyFound = true;
                        break;
                    }
                }

                if (!this.isNullOrUndefined(keySearchOptions) && !this.isNullOrUndefined(keySearchOptions.includeUnChanged) && keySearchOptions.includeUnChanged === true) {
                    const configJsonKeys = Object.keys(sectionChanged.itemFrom);
                    const configJsonKeysLength = configJsonKeys.length;

                    for (let keyI = 0; keyI < configJsonKeysLength; keyI++) {
                        const key = configJsonKeys[keyI];
                        const changedFrom = sectionChanged.itemFrom[key];

                        if (changedFrom === keySearchOptions.searchValue) {
                            keyFound = true;
                            break;
                        }
                    }
                }
            }

            if (this.isNullOrUndefined(sectionChanged.settingsChanged) || sectionChanged.settingsChanged.length === 0) {
                if (!this.isNullOrUndefined(sectionChanged.sectionsChanged)) {
                    const length = sectionChanged.sectionsChanged.length;
                    for (let i = 0; i < length; i++) {
                        const childSectionChanged = sectionChanged.sectionsChanged[i];
                        if (this.filterIncludeSection(childSectionChanged, sectionVM, keySearchOptions) === true) {
                            return true;
                        }
                    }
                }
                return false;
            }

            return isNull(keyFound) ? true : keyFound;
        }
        return false;
    }

    public get changes(): Observable<IConfigChanges[]> {
        return this._filterSub;
    }

    public setSectionsShowState(sectionVM: IGroupedConfigSection | IGroupedConfigSection[], show: boolean): void {
        let sectionVMs;
        if (isArray(sectionVM)) {
            sectionVMs = sectionVM;
        } else {
            sectionVMs = [sectionVM];
        }

        const length = sectionVMs.length;
        for (let i = 0; i < length; i++) {
            const childSection = sectionVMs[i];
            childSection.show = show;
            if (!this.isNullOrUndefined(childSection.children) && childSection.children.length > 0) {
                this.setSectionsShowState(childSection.children, show);
            }
        }
    }

    public showAllSections(sectionVM: IGroupedConfigSection | IGroupedConfigSection[]): void {
        this.setSectionsShowState(sectionVM, true);
        this.filter();
    }

    public hideAllSections(sectionVM: IGroupedConfigSection | IGroupedConfigSection[]): void {
        this.setSectionsShowState(sectionVM, false);
        this.filter();
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.getHostDevice(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.master = result;
                    }
                    return true;
                })
            ),
            this.globalService.getTimeSetup(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.timeSetup = result;
                    }
                    return true;
                })
            ),
        ).pipe(
            map(result => {
                if (this.isZipResultSuccess(result) === true) {
                    this.getLogs();
                }
                return result;
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
        this.master = null;
        this.timeSetup = null;
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    private getLogs(): void {
        this.isLoadingLogs = true;
        this.addSubscription(this.observableHandlerBase(this.historicalRecursive(null).pipe(
            expand((nextIndex) => {
                if (!this.isNullOrUndefined(nextIndex)) {
                    return this.historicalRecursive(nextIndex);
                }
                this.isLoadingLogs = false;
                return EMPTY;
            })
        ), this.getHistoricalLogsProcess).subscribe());
    }

    private historicalRecursive(nextIndex: number): Observable<number> {
        return this.deviceService.getDiagnosticsByRange(this.master.serialNumber, 100, nextIndex, DiagnosticsLogRangeEnum.all, true, this.getHistoricalLogsProcess).pipe(
            map(result => {
                this._configJsonCompareService.checkLog(this.master, result);
                if (result.nextIndex !== -1) {
                    return result.nextIndex;
                }
                return null;
            })
        );
    }
}
