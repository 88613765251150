<ng-template ngFor
    let-deviceInfo
    [ngForOf]="deviceInfos">

    <div class="spacing-bottom"
        fxLayout.gt-sm="row"
        fxLayout.lt-md="column">
        <div ngClass.gt-sm="spacing-right"
            ngClass.lt-md="spacing-bottom"
            fxFlex.gt-sm="100"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card fxFlex>
                <mat-card-title>
                    {{deviceInfo.serial}} ({{ deviceInfo.isMaster ? 'Master' : 'Node' }})
                </mat-card-title>
            </mat-card>
        </div>
    </div>
    <div class="spacing-bottom"
        fxLayout.gt-sm="row"
        fxLayout.lt-md="column">
        <div ngClass.gt-sm="spacing-right"
            ngClass.lt-md="spacing-bottom"
            fxFlex.gt-sm="60"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card fxFlex>
                <mat-card-title>
                    Video Settings
                </mat-card-title>
                <mat-card-content>
                    <div fxLayout="row">
                        <mat-form-field fxFlex>
                            <mat-select [disabled]="isReadOnly === true" placeholder="Frame Rate"
                                (selectionChange)="onFrameRateSelectionChanged($event, deviceInfo)"
                                [value]="deviceInfo?.videoSettings?.frameRate">
                                <mat-option *ngFor="let frameRate of frameRates | orderBy: 'frameRate'"
                                    [value]="frameRate">
                                    {{frameRate}} fps
                                </mat-option>
                            </mat-select>
                            <span matSuffix>{{ deviceInfo?.videoSettings?.propertyHasChangesText('frameRate') }}</span>
                        </mat-form-field>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex.gt-sm="40"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card class="shr-card-sub help-card"
                fxFlex>
                <mat-card-title>
                    Video Settings
                </mat-card-title>
                <mat-card-content>
                    <span>Frame rate allows you to modify the the frame rate of the video device this can be used to reduce the size of video
                        downloads.
                    </span>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="spacing-bottom"
        fxLayout.gt-sm="row"
        fxLayout.lt-md="column">
        <div ngClass.gt-sm="spacing-right"
            ngClass.lt-md="spacing-bottom"
            fxFlex.gt-sm="60"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card fxFlex>
                <mat-card-title>
                    Video Storage
                </mat-card-title>
                <mat-card-content>
                    <div fxLayout="row"
                        fxLayoutAlign="start center">
                        <div class="spacing-right"
                            fxFlex
                            fxLayout="column">
                            <ng-template
                                [ngIf]="!isNullOrUndefined(deviceInfo.videoStorageCapacity) && deviceInfo.videoStorageCapacity.noSDCard === false && deviceInfo.videoStorageCapacity.sdCardNotFormatted === false">
                                <div fxLayout="row"
                                    fxLayoutAlign="start center">
                                    <mat-progress-bar [mode]="isNullOrUndefined(deviceInfo.videoStorageCapacity) ? 'indeterminate' : 'determinate'"
                                        [value]="100 - deviceInfo.videoStorageCapacity?.freePercent"></mat-progress-bar>
                                </div>
                                <div fxLayout="row"
                                    fxLayoutAlign="start center">
                                    {{ isNullOrUndefined(deviceInfo.videoStorageCapacity) ? 'Loading' : deviceInfo.videoStorageCapacity.deviceStorageFreeCapacityKBHumanized + ' (free) / ' + deviceInfo.videoStorageCapacity.deviceStorageCapacityKBHumanized + ' (total)' }}
                                </div>
                            </ng-template>
                            <ng-template
                                [ngIf]="!isNullOrUndefined(deviceInfo.videoStorageCapacity) && deviceInfo.videoStorageCapacity.storageWarning === true">
                                <div class="warning">Warning: Storage is getting low</div>
                            </ng-template>
                            <ng-template
                                [ngIf]="!isNullOrUndefined(deviceInfo.videoStorageCapacity) && deviceInfo.videoStorageCapacity.noSDCard === true">
                                <div class="error">No video storage present</div>
                            </ng-template>
                            <ng-template
                                [ngIf]="!isNullOrUndefined(deviceInfo.videoStorageCapacity) && deviceInfo.videoStorageCapacity.sdCardNotFormatted === true">
                                <div class="error">Video storage not formatted </div>
                            </ng-template>
                            <ng-template
                                [ngIf]="!isNullOrUndefined(deviceInfo.videoStorageCapacity) && deviceInfo.videoStorageCapacity.storageError === true">
                                <div class="error">Not enough storage to record</div>
                            </ng-template>
                        </div>
                        
                        <div fxLayout="row">
                            <shr-progress-button-spinner [disabled]="isReadOnly === true" [raised]="true" [diameter]="30"
                                text="Remove Videos" mode="indeterminate" color="primary"
                                [active]="deviceInfo.formatVideoStorageProcess.isRunning === true" (shrClick)="formatVideoStorage(deviceInfo)">
                            </shr-progress-button-spinner>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex.gt-sm="40" fxFlex.lt-md="100" fxLayout="column">
            <mat-card class="shr-card-sub help-card" fxFlex>
                <mat-card-title>
                    Video Storage
                </mat-card-title>
                <mat-card-content>
                    <span>See how much available storage is on the device and remove all videos from the storage if required.</span>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div *ngIf="(isDeviceGen(UnitGenerationEnum.gen4) | async) === true || (isDeviceGen(UnitGenerationEnum.gazelle) | async) === true"
        class="spacing-bottom"
        fxLayout.gt-sm="row"
        fxLayout.lt-md="column">
        <div ngClass.gt-sm="spacing-right"
            ngClass.lt-md="spacing-bottom"
            fxFlex.gt-sm="60"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card fxFlex>
                <mat-card-title>
                    Cropping
                </mat-card-title>
                <mat-card-content>
                    <div fxLayout="row">
                        <div>
                            <shr-canvas [serial]="deviceInfo.serial"
                                #croppingCanvas
                                [width]="320"
                                [height]="240"></shr-canvas>
                        </div>
                        <div *ngIf="deviceInfo.cropEditingMode === true"
                            fxFlex>
                            <mat-slider min="50"
                                max="100"
                                fxFlex
                                [value]="deviceInfo.cropSliderVal"
                                (change)="calculateCroppingWindow($event, deviceInfo)"
                                (input)="calculateCroppingWindow($event, deviceInfo)"></mat-slider>
                        </div>
                        <div *ngIf="deviceInfo.cropEditingMode === false"
                            fxFlex></div>
                        <div *ngIf="deviceInfo.cropEditingMode === false">
                            <shr-progress-button-spinner [disabled]="isReadOnly === true"
                                [raised]="true"
                                [diameter]="30"
                                text="Edit Crop"
                                mode="indeterminate"
                                color="primary"
                                [active]="deviceInfo.resetCroppingWindowProcess.isRunning === true"
                                (shrClick)="resetCroppingWindow(deviceInfo)">
                            </shr-progress-button-spinner>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex.gt-sm="40"
            fxFlex.lt-md="100"
            fxLayout="column">
            <mat-card class="shr-card-sub help-card"
                fxFlex>
                <mat-card-title>
                    Cropping
                </mat-card-title>
                <mat-card-content>
                    <span>You can crop the video to have a kind of digital zoom.</span>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</ng-template>
