import { Directive, ElementRef, EventEmitter, OnInit, Output, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { ResizedEvent } from '@shared/directive/resized/Resized.Directive.ResizedEvent';
import { ResizeSensor } from 'css-element-queries';

/**
 * Directive for capturing element resize.
 *
 * @export
 * @class ResizedDirective
 * @implements {OnInit}
 */
@Directive({
    selector: '[shrResized]'
})
export class ResizedDirective implements OnInit, AfterViewInit, OnDestroy {

    /**
     * Fired when the element resizes.
     *
     * @memberof ResizedDirective
     */
    @Output()
    readonly resized = new EventEmitter<ResizedEvent>();

    private _oldWidth: number;
    private _oldHeight: number;
    private _sensor: ResizeSensor;

    /**
     * Creates an instance of ResizedDirective.
     *
     * @param {ElementRef} element
     * @memberof ResizedDirective
     */
    public constructor(
        private readonly render2: Renderer2,
        private readonly element: ElementRef) {
    }

    public ngAfterViewInit(): void {
        this.onResized();
    }

    public ngOnInit(): void {
        // this.render2.setStyle(this.element.nativeElement, 'height', '100%');
        // tslint:disable-next-line: no-unused-expression
        this._sensor = new ResizeSensor(this.element.nativeElement, x => this.onResized());
        this.onResized();
    }

    public ngOnDestroy(): void {
        this._sensor.detach();
    }

    /**
     * Handles the element resize.
     *
     * @private
     * @returns {void}
     * @memberof ResizedDirective
     */
    private onResized(): void {
        const newWidth = this.element.nativeElement.clientWidth;
        const newHeight = this.element.nativeElement.clientHeight;

        if (newWidth === this._oldWidth && newHeight === this._oldHeight) {
            return;
        }

        const event = new ResizedEvent(this.element, newWidth, newHeight, this._oldWidth, this._oldHeight);

        this._oldWidth = this.element.nativeElement.clientWidth;
        this._oldHeight = this.element.nativeElement.clientHeight;

        this.resized.next(event);
    }

}
