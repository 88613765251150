import { Device } from '@rift/components/shared/viewport/devices/Device';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { FOVCoveragePointModel } from '@rift/models/restapi/FOVCoveragePoint.Model';
import { IGVector } from '@rift/components/settings/counting/editdevice/Settings.Counting.EditDevice.Component';
import { Grid } from '@rift/components/shared/viewport/grid/Grid';

export interface ICoefficients {
    k: number;
    a: number;
    e: number;
    ox: number;
    oy: number;
    pixelPitch: number;
}

export class VideoSettings {
    public readonly model: { vertex: Array<number>; indices: Array<number>; textureCoords: Array<number> } = {
        vertex: [
            -1.0, -1.0, 0.0,
            -1.0, 1.0, 0.0,
            1.0, 1.0, 0.0,
            1.0, -1.0, 0.0
        ],
        indices: [
            0, 1, 2,
            0, 2, 3,
            2, 1, 0,
            3, 2, 0
        ],
        textureCoords: [
            0.0, 0.0,
            0.0, 0.1,
            1.0, 1.0,
            0.1, 0.1
        ]
    };

    public readonly _lens: { a: number; b: number; fx: number; fy: number; scale: number } = {
        a: 0.937,
        b: 0.992,
        fx: 0.21,
        fy: 0.21,
        scale: 1.01
    };

    public readonly _fov: { x: number; y: number } = {
        x: 1.0,
        y: 1.0
    };

    private _coefficients: ICoefficients = null;
    private _flattenedHeight: number = null;
    private _flattenedWidth: number = null;
    private _fragCropWindowHeight: number = null;
    private _fragCropWindowWidth: number = null;
    private _fragOffsetX: number = null;
    private _fragOffsetY: number = null;
    private _fragScale: number = null;
    private _normalizedWindowHeight: number = null;
    private _normalizedWindowWidth: number = null;
    private _requiredHeight: number = null;
    private _requiredWidth: number = null;
    private _scale: number = null;
    private _winHeight: number = null;
    private _winWidth: number = null;
    private _hullPoints: Array<createjs.Point> = null;
    private _hullScaleFactor: number = 1.4;
    private _fragTiltRotation: number[] = null;
    private _fragDeviceHeight: number = null;
    private _videoOffsetCenter: createjs.Point = null;

    public constructor(
        private readonly _frameWidth: number,
        private readonly _frameHeight: number,
        private readonly _videoDevice: Device,
        private readonly _devices: Array<Device>) {
        this.update();
    }

    public get videoOffsetCenter(): createjs.Point {
        return this._videoOffsetCenter;
    }

    public get fragTiltRotation(): number[] {
        return this._fragTiltRotation;
    }

    public get fragDeviceHeight(): number {
        return this._fragDeviceHeight;
    }

    public get videoDevice(): Device {
        return this._videoDevice;
    }

    public get devices(): Array<Device> {
        return this._devices;
    }

    public get frameWidth(): number {
        return this._frameWidth;
    }

    public get frameHeight(): number {
        return this._frameHeight;
    }

    public get hullScaleFactor(): number {
        return this._hullScaleFactor;
    }

    public set hullScaleFactor(value: number) {
        if (this._hullScaleFactor !== value) {
            this._hullScaleFactor = value;
            this.setHullPoints();
        }
    }

    public get hullPoints(): Array<createjs.Point> {
        return this._hullPoints;
    }

    public get coefficients(): ICoefficients {
        return this._coefficients;
    }

    public get flattenedHeight(): number {
        return this._flattenedHeight;
    }

    public get flattenedWidth(): number {
        return this._flattenedWidth;
    }

    public get fragCropWindowHeight(): number {
        return this._fragCropWindowHeight;
    }

    public get fragCropWindowWidth(): number {
        return this._fragCropWindowWidth;
    }

    public get fragOffsetX(): number {
        return this._fragOffsetX;
    }

    public get fragOffsetY(): number {
        return this._fragOffsetY;
    }

    public get fragScale(): number {
        return this._fragScale;
    }

    public get normalizedWindowHeight(): number {
        return this._normalizedWindowHeight;
    }

    public get normalizedWindowWidth(): number {
        return this._normalizedWindowWidth;
    }

    public get requiredHeight(): number {
        return this._requiredHeight;
    }

    public get requiredWidth(): number {
        return this._requiredWidth;
    }

    public get scale(): number {
        return this._scale;
    }

    public get winHeight(): number {
        return this._winHeight;
    }

    public get winWidth(): number {
        return this._winWidth;
    }

    public update(outerCoverage?: Array<FOVCoveragePointModel>, innerCoverage?: Array<FOVCoveragePointModel>, gVector?: IGVector): void {
        this._coefficients = null;
        this._flattenedHeight = null;
        this._flattenedWidth = null;
        this._fragCropWindowHeight = null;
        this._fragCropWindowWidth = null;
        this._fragOffsetX = null;
        this._fragOffsetY = null;
        this._fragScale = null;
        this._normalizedWindowHeight = null;
        this._normalizedWindowWidth = null;
        this._requiredHeight = null;
        this._requiredWidth = null;
        this._scale = null;
        this._winHeight = null;
        this._winWidth = null;
        this._hullPoints = null;
        this._fragTiltRotation = null;
        this._fragDeviceHeight = null;
        this._videoOffsetCenter = null;

        if (!isNullOrUndefined(outerCoverage)) {
            this.videoDevice.deviceModel.outerCoverage = outerCoverage;
        }
        if (!isNullOrUndefined(innerCoverage)) {
            this.videoDevice.deviceModel.innerCoverage = innerCoverage;
        }
        if (!isNullOrUndefined(gVector)) {
            this.videoDevice.deviceModel.gVector.x = gVector.x;
            this.videoDevice.deviceModel.gVector.y = gVector.y;
            this.videoDevice.deviceModel.gVector.z = gVector.z;
            this.videoDevice.deviceModel.gVector.yaw = gVector.yaw;
        }

        this.setCoefficients();
        this.setVideoCenterPoint();
        this.setWinSize();
        this.setScale();
        this.setNormalizedWindowSize();
        this.setFlattenedSize();
        this.setRequiredSize();
        this.setFragScale();
        this.setFragOffsets();
        this.setFragCropWindowSize();
        this.setHullPoints();
        this.setFragDeviceHeight();
        this.setFragTiltRotation();

        // this.outputToConsole();
    }

    private outputToConsole(): void {
        const data = {
            coefficients: this.coefficients,
            flattenedHeight: this.flattenedHeight,
            flattenedWidth: this.flattenedWidth,
            fragCropWindowHeight: this.fragCropWindowHeight,
            fragCropWindowWidth: this.fragCropWindowWidth,
            fragOffsetX: this.fragOffsetX,
            fragOffsetY: this.fragOffsetY,
            fragScale: this.fragScale,
            normalizedWindowHeight: this.normalizedWindowHeight,
            normalizedWindowWidth: this.normalizedWindowWidth,
            requiredHeight: this.requiredHeight,
            requiredWidth: this.requiredWidth,
            scale: this.scale,
            winHeight: this.winHeight,
            winWidth: this.winWidth,
            hullPoints: this.hullPoints,
            hullScaleFactor: this.hullScaleFactor,
            fragTiltRotation: this.fragTiltRotation,
            fragDeviceHeight: this.fragDeviceHeight,
            videoOffsetCenter: this.videoOffsetCenter,
        };
        console.log(JSON.stringify(data));
    }

    private setVideoCenterPoint(): void {
        this._videoOffsetCenter = new createjs.Point(
            this.coefficients.ox * 100 * (this.coefficients.pixelPitch * 100),
            this.coefficients.oy * 100 * (this.coefficients.pixelPitch * 100),
        );
    }

    private setCoefficients(): void {
        switch (this.videoDevice.deviceModel.unitGen) {
            case UnitGenerationEnum.gen4:
            case UnitGenerationEnum.gazelle:
                this._coefficients = {
                    k: 0.9922547024493846,
                    a: 0.9371410975251819,
                    e: 0.035,
                    ox: -0.03,
                    oy: 0.0,
                    pixelPitch: 0.02,
                };
                break;
            case UnitGenerationEnum.kestrel:
                this._coefficients = {
                    k: 9.646454769396088e-01,
                    a: 8.792869899888502e-01,
                    e: 2.3265e-02,
                    ox: -0.03,
                    oy: 0.0,
                    pixelPitch: 0.02,
                };
                break;
            case UnitGenerationEnum.falcon:
                this._coefficients = {
                    k: 9.646454769396088e-01,
                    a: 8.792869899888502e-01,
                    e: 2.3265e-02,
                    ox: -0.03,
                    oy: 0.0,
                    pixelPitch: 0.02,
                };
                break;
        }

    }

    private setFragTiltRotation(): void {
        const g = this.videoDevice.deviceModel.gVector;

        const cosTheta = Math.sqrt(1.0 - g.y * g.y);

        // populate world to camera/device rotation matrix
        if (cosTheta > 0.0) {
            const sinPsi = Math.sin(g.yaw);
            const cosPsi = Math.cos(g.yaw);

            const rotation = [
                (-(g.z * cosPsi) + (g.x * g.y * sinPsi)) / cosTheta, -cosTheta * sinPsi, ((g.x * cosPsi) + (g.y * g.z * sinPsi)) / cosTheta,
                (-(g.z * sinPsi) - (g.x * g.y * cosPsi)) / cosTheta, cosTheta * cosPsi, ((g.x * sinPsi) - (g.y * g.z * cosPsi)) / cosTheta,
                -g.x, -g.y, -g.z
            ];

            this._fragTiltRotation = rotation;
        } else {
            // vertical orientation (should be prevented by pitch validation) so set identity matrix
            const rotation = [
                1, 0, 0,
                0, 1, 0,
                0, 0, 1
            ];

            this._fragTiltRotation = rotation;
        }
    }

    private setFragDeviceHeight(): void {
        this._fragDeviceHeight = this.videoDevice.deviceModel.actualHeight / 100;
    }

    private setWinSize(): void {
        // Derive and allocate ground plane image
        this._winWidth = this.videoDevice.deviceModel.videoCroppingWindow.xPos2 - this.videoDevice.deviceModel.videoCroppingWindow.xPos1;
        this._winHeight = this.videoDevice.deviceModel.videoCroppingWindow.yPos2 - this.videoDevice.deviceModel.videoCroppingWindow.yPos1;
    }

    private setScale(): void {
        this._scale = this.coefficients.a * ((this.videoDevice.deviceModel.actualHeight / 100) - this.coefficients.e) / this.coefficients.k;
    }

    private setNormalizedWindowSize(): void {
        const Wx = this.winWidth / 1600.0; // normalized window width
        const Wy = this.winHeight / 1600.0; // normalized window height

        // NOTE: video is assumed to be a downscale version of a
        // centralized (winWidth, winHeight) window in the full
        // 1,600x1,200 native sensor image
        this._normalizedWindowWidth = Wx - Math.abs(this.videoDevice.deviceModel.videoOffsets[0]) / 800.0 - 0.5 * Wx / (this.frameWidth / 2);
        this._normalizedWindowHeight = Wy - Math.abs(this.videoDevice.deviceModel.videoOffsets[1]) / 800.0 - 0.5 * Wy / (this.frameHeight / 2);
    }

    private setFlattenedSize(): void {
        const pointsToUse = [];
        let p = 0;

        // Init the outerCoverageScaled
        const devicesLength = this.devices.length;
        for (let d = 0; d < devicesLength; d++) {
            const device = this.devices[d];
            const outerCoverageLength = device.deviceModel.outerCoverage.length;

            let averageX = 0.0;
            let averageY = 0.0;
            let numPoints = 0.0;

            for (p = 0; p < outerCoverageLength; p++) {
                const outerCoverage = device.deviceModel.outerCoverage[p];
                averageX += outerCoverage.x;
                averageY += outerCoverage.y;

                numPoints++;
            }

            averageX /= numPoints;
            averageY /= numPoints;

            device.deviceModel.outerCoverageScaled = [];

            for (p = 0; p < outerCoverageLength; p++) {
                const outerCoverage = device.deviceModel.outerCoverage[p];

                const point = new FOVCoveragePointModel();
                point.x = ((outerCoverage.x - averageX) * 1.2) + averageX;
                point.y = ((outerCoverage.y - averageY) * 1.2) + averageY;

                device.deviceModel.outerCoverageScaled.push(point);
            }
        }


        const outerCoverageScaledLength = this.videoDevice.deviceModel.outerCoverageScaled.length;
        for (p = 0; p < outerCoverageScaledLength; p++) {
            const scaledPoint = this.videoDevice.deviceModel.outerCoverageScaled[p];

            pointsToUse.push({ x: scaledPoint.x, y: scaledPoint.y });
        }

        if (devicesLength > 1) {
            // Do the overlap checks
            for (let d = 0; d < devicesLength; d++) {
                const testDevice = this.devices[d];

                if (testDevice.deviceModel === this.videoDevice.deviceModel) {
                    continue;
                }

                if (this.testOverlap(this.videoDevice.deviceModel.outerCoverage, testDevice.deviceModel.outerCoverage)) {
                    const testOuterCoverageScaledLength = testDevice.deviceModel.outerCoverageScaled.length;
                    for (let pt = 0; pt < testOuterCoverageScaledLength; pt++) {
                        const testScaledPoint = testDevice.deviceModel.outerCoverageScaled[pt];

                        pointsToUse.push({ x: testScaledPoint.x, y: testScaledPoint.y });
                    }
                }
            }
        }

        let maxX = -999999;
        let maxY = -999999;
        let minX = 999999;
        let minY = 999999;

        const pointsLength = pointsToUse.length;
        for (let i = 0; i < pointsLength; i++) {
            const point = pointsToUse[i];

            if (point.x > maxX) {
                maxX = point.x;
            }

            if (point.x < minX) {
                minX = point.x;
            }

            if (point.y > maxY) {
                maxY = point.y;
            }

            if (point.y < minY) {
                minY = point.y;
            }
        }

        this._flattenedWidth = (maxX - minX);
        this._flattenedHeight = (maxY - minY);
    }

    private testOverlap(polygon1: FOVCoveragePointModel[], polygon2: FOVCoveragePointModel[]): boolean {
        const polygon2Length = polygon2.length;
        for (let i = 0; i < polygon2Length; i++) {
            if (this.isPointInPolygon(polygon1, polygon2[i])) {
                return true;
            }
        }

        if (this.isPointInPolygon(polygon2, polygon1[0])) {
            return true;
        }

        return false;
    }

    private isPointInPolygon(polygon: FOVCoveragePointModel[], point: FOVCoveragePointModel): boolean {
        const firstResult = this.pointInLeftSemiPlane(polygon[polygon.length - 1], polygon[0], point);

        for (let i = 1; i < polygon.length; i++) {
            if (this.pointInLeftSemiPlane(polygon[i - 1], polygon[i], point) !== firstResult) {
                return false;
            }
        }

        return true;
    }

    private pointInLeftSemiPlane(vertex1: FOVCoveragePointModel, vertex2: FOVCoveragePointModel, point: FOVCoveragePointModel): boolean {
        const vector1 = { x: vertex2.x - vertex1.x, y: vertex2.y - vertex1.y };
        const vector2 = { x: point.x - vertex1.x, y: point.y - vertex1.y };

        const check = (vector1.x * vector2.y) - (vector1.y * vector2.x);

        if (check < 0) {
            return true;
        }

        return false;
    }

    private setRequiredSize(): void {
        this._requiredWidth = this.flattenedWidth * (this.coefficients.pixelPitch * 100);
        this._requiredHeight = this.flattenedHeight * (this.coefficients.pixelPitch * 100);
    }

    private setFragScale(): void {
        this._fragScale = this.coefficients.k / (this.coefficients.a * ((this.videoDevice.deviceModel.actualHeight / 100) - this.coefficients.e));
    }

    private setFragOffsets(): void {
        this._fragOffsetX = this.videoDevice.deviceModel.videoOffsets[0] / 800;
        this._fragOffsetY = this.videoDevice.deviceModel.videoOffsets[1] / 800;
    }

    private setFragCropWindowSize(): void {
        this._fragCropWindowWidth = this.videoDevice.deviceModel.videoCroppingWindow.xPos2 - this.videoDevice.deviceModel.videoCroppingWindow.xPos1;
        this._fragCropWindowHeight = this.videoDevice.deviceModel.videoCroppingWindow.yPos2 - this.videoDevice.deviceModel.videoCroppingWindow.yPos1;
    }

    private setHullPoints(): void {
        const pointSet: number[][] = [];

        const devicesLength = this.devices.length;
        for (let index = 0; index < devicesLength; index++) {
            const device = this.devices[index];
            const outerCoverageLength = device.deviceModel.outerCoverage.length;

            let averageX = 0.0;
            let averageY = 0.0;
            let numPoints = 0.0;

            for (let p = 0; p < outerCoverageLength; p++) {
                averageX += device.deviceModel.outerCoverage[p].x;
                averageY += device.deviceModel.outerCoverage[p].y;

                numPoints++;
            }

            averageX /= numPoints;
            averageY /= numPoints;

            this.addDevicePoints(pointSet, device, outerCoverageLength, averageX, averageY);
        }

        this._hullPoints = hull(pointSet, Infinity).map(p => new createjs.Point(p[0], p[1]));
    }

    private addDevicePoints(pointSet: Array<Array<number>>, device: Device, outerCoverageLength: number, averageX: number, averageY: number): void {
        const scaleFactor = isNullOrUndefined(this.hullScaleFactor) ? 1 : this.hullScaleFactor;

        const offsetX = ((Grid.Width / 2) + (-(this.coefficients.ox * 100 * (this.coefficients.pixelPitch * 100))));
        const offsetY = ((Grid.Height / 2) + (-(this.coefficients.oy * 100 * (this.coefficients.pixelPitch * 100))));

        for (let p = 0; p < outerCoverageLength; p++) {
            const outerCoverage1 = device.deviceModel.outerCoverage[p];
            let outerCoverage2 = null;
            const devPoint1 = { x: outerCoverage1.x, y: outerCoverage1.y };
            let devPoint2 = null;

            if (p === outerCoverageLength - 1) {
                outerCoverage2 = device.deviceModel.outerCoverage[0];
            } else {
                outerCoverage2 = device.deviceModel.outerCoverage[p + 1];
            }

            devPoint2 = { x: outerCoverage2.x, y: outerCoverage2.y };

            // Scale the points correctly
            devPoint1.x = ((devPoint1.x - averageX) * scaleFactor) + averageX;
            devPoint1.y = ((devPoint1.y - averageY) * scaleFactor) + averageY;

            devPoint2.x = ((devPoint2.x - averageX) * scaleFactor) + averageX;
            devPoint2.y = ((devPoint2.y - averageY) * scaleFactor) + averageY;

            // calc the vector between the points
            const vector = {
                x: devPoint2.x - devPoint1.x,
                y: devPoint2.y - devPoint1.y
            };

            // We have a vector using the points above so
            // now paramatise that into a line and walk
            // along the line
            for (let t = 0; t < 1.0; t += 0.1) {
                const x = devPoint1.x + (t * vector.x);
                const y = devPoint1.y + (t * vector.y);



                pointSet.push([x, y]);
            }
        }
    }
}
