import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { CollectionModel, ICollectionModel } from '@shared/models/restapi/Collection.Model';

export interface IDeviceCollectionModel extends ICollectionModel<DeviceModel> {

}

export class DeviceCollectionModel extends CollectionModel<DeviceModel> implements IDeviceCollectionModel {
    public constructor() {
        super(DeviceModel);
    }
}
