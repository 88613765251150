import { ProcessMonitorService } from '@shared/service/processmonitor/ProcessMonitor.Service';
import { ProcessMonitorServiceRestApi } from '@shared/service/processmonitor/ProcessMonitor.Service.RestApi';

/**
 * Process info.
 *
 * @export
 * @class ProcessMonitorServiceProcess
 */
export class ProcessMonitorServiceProcess {

    public hasError: boolean;
    public id: number;
    public name: string;
    public restApi: ProcessMonitorServiceRestApi = new ProcessMonitorServiceRestApi();
    public completedTime: number;
    public errorMessages: Array<string> = [];
    public inName: string;
    public key: string;
    public percentage: number;
    public processMonitorService: ProcessMonitorService;
    public startedTime: number;
    public status: string;
    public hostDeviceSerialNumber: string;
    public lastError: Error;

    private _isRunning: boolean;

    /**
     * Creates an instance of ProcessMonitorServiceProcess.
     *
     * @param {string} inName The name of the class or bjvect the process is running.
     * @param {number} id The id of the process.
     * @param {string} name The name of the process.
     * @param {string} [key] The process key.
     * @memberof ProcessMonitorServiceProcess
     */
    public constructor(inName: string, id: number, name: string, key?: string) {
        this.inName = inName;
        this.id = id;
        this.name = name;
        this._isRunning = false;
        this.key = key;
    }

    /**
     * Adds a error or errors to the process.
     *
     * @param {Array<string>} errorMessages The error message.
     * @param {Error} error The error.
     * @memberof ProcessMonitorServiceProcess
     */
    public error(errorMessages: string | Array<string>, error?: Error) {
        this.lastError = error;
        this.processMonitorService.error(this, errorMessages, error);
    }

    /**
     * Completes the process.
     *
     * @memberof ProcessMonitorServiceProcess
     */
    public completed() {
        this.processMonitorService.completed(this);
    }

    /**
     * Gets the duration the process has been running.
     *
     * @readonly
     * @type {number}
     * @memberof ProcessMonitorServiceProcess
     */
    public get duration(): number {
        return (new Date().getTime() - this.startedTime);
    }

    /**
     * Gets the running state of the process.
     *
     * @type {boolean}
     * @memberof ProcessMonitorServiceProcess
     */
    public get isRunning(): boolean {
        return this._isRunning;
    }

    /**
     * Sets the running state of the process.
     *
     * @memberof ProcessMonitorServiceProcess
     */
    public set isRunning(value: boolean) {
        this._isRunning = value;
        if (value) {
            this.startedTime = new Date().getTime();
        } else {
            this.completedTime = new Date().getTime();
        }
    }

    /**
     * Starts the process.
     *
     * @memberof ProcessMonitorServiceProcess
     */
    public started() {
        this.processMonitorService.started(this);
    }

    /**
     * Terminates the process.
     *
     * @memberof ProcessMonitorServiceProcess
     */
    public terminate() {
        this.processMonitorService.terminate(this);
    }

    /**
     * Updates the complete percentage of the process.
     *
     * @param {number} percentage
     * @memberof ProcessMonitorServiceProcess
     */
    public reportProgress(percentage: number) {
        this.processMonitorService.reportProgress(this, percentage);
    }

    /**
     * Updates the status of the process.
     *
     * @param {string} status
     * @memberof ProcessMonitorServiceProcess
     */
    public updateStatus(status: string) {
        this.processMonitorService.updateStatus(this, status);
    }
}
