<div #container
    *ngIf="show === true && canShow === true"
    class="float-dialog rift-settings-counting-edit-register"
    (click)="onMenuClick()"
    ngDraggable
    #draggable="ngDraggable"
    (started)="onDrStarted($event)"
    (stopped)="onDrStopped($event)"
    (edge)="onDrEdge($event)"
    (movingOffset)="onDrMovingOffset($event)"
    (endOffset)="onDrEndOffset($event)"
    [handle]="title"
    [bounds]="bounds"
    [position]="position"
    [inBounds]="!isNullOrUndefined(bounds)">
    <div #title
        class="title drag-handle"
        fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex
            fxLayout="row"
            fxLayoutAlign="start center">
            <div fxLayout="column">
                <div class="header">
                    Edit Register ({{ editRegister?.registerName }})
                </div>
                <div *ngIf="!isNullOrUndefined(error)"
                    class="error sub-header">
                    {{error}}
                </div>
            </div>
            <div fxFlex></div>
            <div class="actions">
                <button [disabled]="saveAllChangesProcess.isRunning === true"
                    id="close_menu"
                    class="flat-icon-button"
                    mat-stroked-button
                    matTooltip="Close"
                    (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="body">
            <div fxLayout="row"
                fxLayoutAlign="start center">
                <div class="name">
                    <form [formGroup]="nameFormGroup">
                        <mat-form-field>
                            <input matInput
                                #name
                                formControlName="name"
                                placeholder="Name"
                                maxlength="{{registerNameMaxLength}}">
                            <mat-error>{{getFormControlErrorMessage(nameFormGroup.controls['name'])}}</mat-error>
                            <span matSuffix>{{ editRegister?.propertyHasChangesText('registerName') }}</span>
                            <mat-hint align="end">{{name.value.length}} / {{registerNameMaxLength}}</mat-hint>
                        </mat-form-field>
                    </form>
                </div>
                <div fxFlex></div>
                <div class="actions">
                    <button mat-mini-fab
                        class="spacing-right"
                        matTooltip="Summary"
                        (click)="onSummaryClick()">
                        <shr-fa class="icon"
                            name="info"></shr-fa>
                    </button>
                    <button [disabled]="isReadOnly"
                        mat-mini-fab
                        class="spacing-right"
                        matTooltip="Copy"
                        *ngIf="editRegister?.isCopyable === true"
                        (click)="onCopyClick()">
                        <shr-fa class="icon"
                            name="clone"></shr-fa>
                    </button>
                    <button [disabled]="isReadOnly"
                        mat-mini-fab
                        class="spacing-right"
                        matTooltip="Reset"
                        *ngIf="editRegister?.isViewPortVisibleInViewPort && editRegister?.lineIds.length > 0"
                        (click)="onResetClick()">
                        <shr-fa class="icon"
                            name="sync"></shr-fa>
                    </button>
                    <button [disabled]="isReadOnly"
                        mat-mini-fab
                        class="spacing-right"
                        matTooltip="Flip"
                        *ngIf="editRegister?.isViewPortVisibleInViewPort && editRegister?.lineIds.length > 0"
                        (click)="onFlipClick()">
                        <shr-fa class="icon"
                            name="random"></shr-fa>
                    </button>
                    <button [disabled]="isReadOnly || registers.length <= 1"
                        mat-mini-fab
                        matTooltip="Delete"
                        (click)="onDeleteClick()">
                        <mat-icon class="icon">delete</mat-icon>
                    </button>
                </div>
            </div>
            <div class="settings"
                fxLayout="column">
                <rift-settings-counting-register-settings #settings
                    (associateSelectedChange)="onAssociateSelectedChange($event)"
                    [register]="editRegister"
                    [registers]="registers"
                    [lines]="lines"
                    [polygons]="polygons"
                    [registerTypeViewModel]="editRegisterType"></rift-settings-counting-register-settings>
            </div>
        </div>
        <div class="actions">
            <button mat-raised-button
                (click)="onSaveRegisterClick()"
                [disabled]="isNullOrUndefined(editRegister) || nameFormGroup.valid === false || settings.isValid === false || changeTracker.hasChanges === false">
                Save Register
            </button>
        </div>
    </div>
</div>