import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Injectable } from '@angular/core';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { GroupCollectionModel } from '@em/models/restapi/GroupCollection.Model';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { UpdateUserModel } from '@em/models/restapi/UpdateUser.Model';
import { UserModel } from '@em/models/restapi/User.Model';
import { UserCollectionModel } from '@em/models/restapi/UserCollection.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiUserService } from '@em/service/restapi/RestApi.User.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ProcessMonitorService } from '@shared/service/processmonitor/ProcessMonitor.Service';
import { ObservableTracker } from '@shared/generic/ObservableLoading';


@Injectable()
export class UserService extends EmBaseService {
    public static readonly className = 'UserService';

    public updateOrAddProcess: ProcessMonitorServiceProcess;

    private _addToGroupLoadingTracker = new ObservableTracker<ResultModel>();
    private _deleteLoadingTracker = new ObservableTracker<ResultModel>();
    private _getCache: UserCollectionModel;
    private _getGroupsLoadingTracker = new ObservableTracker<GroupCollectionModel>();
    private _getLoadingTracker = new ObservableTracker<UserCollectionModel>();
    private _removeFromGroupLoadingTracker = new ObservableTracker<ResultModel>();
    private _resetApiKeyLoadingTracker = new ObservableTracker<string>();
    private _resetPasswordLoadingTracker = new ObservableTracker<string>();
    private _setEnabledStateLoadingTracker = new ObservableTracker<ResultModel>();
    private _updateOrAddLoadingTracker = new ObservableTracker<UpdateUserModel>();
    private _unlockAccountLoadingTracker = new ObservableTracker<boolean>();

    public constructor(
        private readonly _processMonitorService: ProcessMonitorService,
        private readonly _userCurrentService: UserCurrentService,
        private readonly _restApiUserService: RestApiUserService) {
        super();

        this.updateOrAddProcess = this._processMonitorService.getProcess(UserService.className, 'Update or add');
    }

    public addToGroup(user: UserModel, group: GroupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._addToGroupLoadingTracker
            .getLoading(user, group)
            .observable(this._restApiUserService.addGroupPermissions(user, group, process));
    }

    public clearCache(): void {
        this.clearObservableTrackers();
        this._getCache = null;
    }

    public delete(userName: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._deleteLoadingTracker
            .getLoading(userName)
            .observable(this._restApiUserService.deleteUser(userName, process));
    }

    public get(process?: ProcessMonitorServiceProcess): Observable<UserCollectionModel> {
        if (isNullOrUndefined(this._getCache)) {
            return this._getLoadingTracker
                .getLoading()
                .observable(this._restApiUserService.getUsers(process).pipe(
                    map(result => {
                        this._getCache = result;
                        return this._getCache;
                    })
                ));
        } else {
            return of(this._getCache);
        }
    }

    public getGroups(userId: number, process?: ProcessMonitorServiceProcess): Observable<GroupCollectionModel> {
        return this._getGroupsLoadingTracker
            .getLoading(userId)
            .observable(this._restApiUserService.getGroupPermissions(userId, process));
    }

    public removeFromGroup(user: UserModel, group: GroupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._removeFromGroupLoadingTracker
            .getLoading(user, group)
            .observable(this._restApiUserService.deleteGroupPermissions(user, group, process));
    }

    public resetApiKey(userName: string, process?: ProcessMonitorServiceProcess): Observable<string> {
        return this._resetApiKeyLoadingTracker
            .getLoading(userName)
            .observable(this._restApiUserService.resetApiKey(userName, process));
    }

    public unlockAccount(userName: string, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._unlockAccountLoadingTracker
            .getLoading(userName)
            .observable(this._restApiUserService.unlockAccount(userName, process));
    }

    public resetPassword(userName: string, process?: ProcessMonitorServiceProcess): Observable<string> {
        return this._resetPasswordLoadingTracker
            .getLoading(userName)
            .observable(this._restApiUserService.resetPassword(userName, process));
    }

    public setEnabledState(userName: string, enabled: boolean, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._setEnabledStateLoadingTracker
            .getLoading(userName, enabled)
            .observable(this._restApiUserService.setEnabledState(userName, enabled, process));
    }

    public updateOrAdd(user: UserModel, process?: ProcessMonitorServiceProcess): Observable<UpdateUserModel> {
        return this._updateOrAddLoadingTracker
            .getLoading(user)
            .observable(this._restApiUserService.updateOrAdd(user, process).pipe(
                map(updateUser => {
                    if (!isNullOrUndefined(updateUser.success) && updateUser.success === true) {
                        this.addSubscription(this._userCurrentService.user.subscribe(currentUser => {
                            const emUser = currentUser as UserModel;
                            if (!isNullOrUndefined(updateUser.user)) {
                                if (emUser.userId === updateUser.user.userId) {
                                    this._userCurrentService.refresh();
                                }
                            } else {
                                if (emUser.userId === user.userId) {
                                    this._userCurrentService.refresh();
                                }
                            }


                        }), this.updateOrAddProcess);
                    }

                    this.clearCache();

                    return updateUser;
                })
            ));
    }
}
