<div fxLayout="row"
    fxLayoutAlign="start center">
    <mat-form-field class="spacing-right">
        <mat-label>{{ isNullOrUndefined(parentSectionVM) ? 'Section' : parentSectionVM.text }}</mat-label>
        <mat-select (selectionChange)="onSectionSelected($event)">
            <mat-option *ngFor="let sectionVM of sectionVMs"
                [value]="sectionVM">
                {{sectionVM.text}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-checkbox class="spacing-right"
        *ngIf="!isNullOrUndefined(selectedSectionVM)"
        (change)="onSectionShowChanged($event, selectedSectionVM)"
        [checked]="selectedSectionVM.show">Show Changes</mat-checkbox>
    <rift-config-history-section-select (sectionShowChanged)="sectionShowChanged.emit()" *ngIf="!isNullOrUndefined(selectedSectionVM) && !isNullOrUndefined(selectedSectionVM.children)"
        [parentSectionVM]="selectedSectionVM"
        [sectionVMs]="selectedSectionVM.children">
    </rift-config-history-section-select>
</div>