import { ChangeDetectorRef, Component, HostListener, Injectable, Injector, Pipe, PipeTransform, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { DiscoveredDeviceModel } from '@rift/models/restapi/DiscoveredDevice.Model';
import { NodeInfoModel } from '@rift/models/restapi/NodeInfo.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { WideTrackerService } from '@rift/service/data/widetracker/WideTracker.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, of, zip, merge, timer, throwError } from 'rxjs';
import { flatMap, map, tap, catchError } from 'rxjs/operators';
import { OkCancelDialogResult } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { SettingsWideTrackerConvertDeviceComponent } from './convertdevice/Settings.WideTracker.ConvertDevice.Component';
import { IListDevice } from './Settings.WideTracker.IListDevice';
import { RestApiError } from '@shared/types/RestApi.Error';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MasterModeEnum } from '@shared/enum/MasterMode.Enum';

@Pipe({
    name: 'listDeviceFilter'
})
@Injectable()
export class ListDeviceFilter implements PipeTransform {
    transform(devices: Array<IListDevice>, assigned: boolean): any {
        return isNullOrUndefined(devices) ? [] : devices.filter(d => d.assigned === assigned);
    }
}

@Component({
    selector: 'rift-settings-wide-tracker',
    templateUrl: './Settings.WideTracker.Component.html',
    styleUrls: ['./Settings.WideTracker.Component.scss']
})
export class SettingsWideTrackerComponent extends RiftBaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsWideTrackerComponent';

    @HostBinding()
    public id: string = 'rift-settings-wide-tracker';

    public settingsFormGroup: FormGroup;
    public countingNetworkFormGroup: FormGroup;
    public connectedDevice: DeviceModel;
    public devices: Array<IListDevice>;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;
    public RestApiError = RestApiError;

    private _nodeList: NodeInfoModel[];
    private _discoveredNodes: DiscoveredDeviceModel[];
    private _nodes: DeviceModel[];
    private _convertDeviceDialogRef: MatDialogRef<SettingsWideTrackerConvertDeviceComponent>;
    private _convertDialogProcess: ProcessMonitorServiceProcess;
    private _onlineForceDiscoveryRefreshProcess: ProcessMonitorServiceProcess;
    private _convertOccurred: boolean = false;

    public constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _wideTrackerService: WideTrackerService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _router: Router,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsWideTrackerComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsWideTrackerComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsWideTrackerComponent.className, this.saveAllChangesProcessText);
        this._convertDialogProcess = this.processMonitorService.getProcess(SettingsWideTrackerComponent.className, 'Convert dialog process');
        this._onlineForceDiscoveryRefreshProcess = this.processMonitorService.getProcess(SettingsWideTrackerComponent.className, 'Force rediscover process');

        this.addSaveAllAction(this);

        this.settingsFormGroup = this._formBuilder.group({
            setToMaster: ['', Validators.compose([Validators.required])],
        });
        this.formGroupTracker.track(this.settingsFormGroup);
        this.addSubscription(this.observableHandlerBase(this.settingsFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesSettingsFormGroup()), this.formValuesChangeProcess);

        this.countingNetworkFormGroup = this._formBuilder.group({
        });
        this.formGroupTracker.track(this.countingNetworkFormGroup);
        this.addSubscription(this.observableHandlerBase(this.countingNetworkFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesCountingNetworkFormGroup()), this.formValuesChangeProcess);

        this.initConnectionState();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => this.loadData(this.openPleaseWaitLoadingDialog()));
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase || (!this.isNullOrUndefined(this.devices) && this.devices.some(i => i.assigned !== i.assignedOrg));
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this.changeTracker.clear();

        const loadDataSub = zip(
            this.getHostDevice(process).pipe(
                flatMap(host => {
                    if (!this.isNullOrUndefined(host)) {
                        this.connectedDevice = host;
                        this.changeTracker.track(this.connectedDevice);
                        this.setSettingsFormGroupValues();
                        this.setCountingNetworkFormGroupValues();

                        if(this.connectedDevice.masterMode === MasterModeEnum.uberMaster){
                            this.settingsFormGroup.disable();
                        }
                        else if(!this.isReadOnly){
                            this.settingsFormGroup.enable();
                        }

                        if (host.master === true) {
                            return this._wideTrackerService.getNodeList(process).pipe(
                                map(result => {
                                    this._nodeList = result.items;
                                    return true;
                                })
                            );
                        } else {
                            return of(true);
                        }
                    }
                    return of(true);
                })
            ),
            this.deviceService.getNodeDevices().pipe(
                map(nodes => {
                    if (!this.isNullOrUndefined(nodes)) {
                        this._nodes = nodes;
                    }
                    return true;
                })
            ),
        ).pipe(
            tap(() => {
                this.addSubscription(this.getDiscoveredDevices(process).subscribe(result => {
                    this.mergeDevices();
                }));
            }),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this.connectedDevice.propertyHasChanges('setToMaster')).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        let setAs: (serial: string, process: ProcessMonitorServiceProcess) => Observable<ResultModel>;

                        if (this.connectedDevice.setToMaster === true) {
                            setAs = this._wideTrackerService.setAsMaster.bind(this._wideTrackerService);
                        } else {
                            setAs = this._wideTrackerService.setAsNode.bind(this._wideTrackerService);
                        }

                        return setAs(this.connectedDevice.serialNumber, process).pipe(
                            catchError((error, caught) =>{
                                if(error instanceof RestApiError){
                                    if(error.httpCode === 504){
                                        return of(false);
                                    }
                                }

                                return throwError(error);
                            }),
                            map(() => {
                                this.deviceService.clearCache();
                                return true;
                            })
                        );
                    } else {
                        return of(true);
                    }
                })
            ),
            of((!this.isNullOrUndefined(this.devices) && this.devices.some(i => i.assigned !== i.assignedOrg))).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._wideTrackerService.updateNodeList(this.devices.filter(i => i.assigned === true).map(i => i.serial)).pipe(
                            map(() => true)
                        );
                    } else {
                        return of(true);
                    }
                })
            )
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                this._wideTrackerService.clearCache();

                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(false);
                }
            })
        );
    }

    public refreshDiscoveredDevices(): void {
        this.addSubscription(this.getDiscoveredDevices().subscribe(() => this.mergeDevices()));
    }

    public getDiscoveredDevices(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this._wideTrackerService.clearCache();
        return this._wideTrackerService.discoveredDevices(process).pipe(
            map(result => {
                this._discoveredNodes = result.items.filter(d => d.master === false && !this._nodes.some(n => n.serialNumber === d.serialNumber));

                this._discoveredNodes.forEach(d => {
                    d.canBeSelected = !result.items.some(dd => dd.nodes.indexOf(d.serialNumber) !== -1) && this.doesProductTypeMatch(this.connectedDevice.productID, d.productID);
                });

                return true;
            })
        );
    }

    public onConvert(event: MouseEvent, device: IListDevice): void {
        event.stopImmediatePropagation();

        this._convertOccurred = false;

        this._convertDeviceDialogRef = this._dialog.open(SettingsWideTrackerConvertDeviceComponent, { data: device, minWidth: 400, disableClose: true });

        this.addSubscription(this.observableHandlerBase(this._convertDeviceDialogRef.afterClosed(), this._convertDialogProcess).subscribe((result: boolean) => {
            this._convertOccurred = result;
        }), this._convertDialogProcess);
    }

    public onNodeClick(device: IListDevice, acton: 'add' | 'remove'): void {
        if (!this.isReadOnly) {
            const tmp = this.devices;
            this.devices = null;
            this._changeDetectorRef.detectChanges();

            if (acton === 'add') {
                device.assigned = true;
            } else {
                device.assigned = false;
            }

            this.devices = tmp;
            this._changeDetectorRef.detectChanges();

            this.updateSaveAllAction(this);
        }
    }

    public deviceTrackByFn(index: number, item: IListDevice): string {
        return item.serial + item.assigned.toString();
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());

        if(this._convertOccurred){
            this._convertOccurred = false;
            let refreshCount = 5;

            const subs = this.addSubscription(this.observableHandlerBase(timer(1000, 1000), this._onlineForceDiscoveryRefreshProcess).subscribe(() => {
                refreshCount--;

                if(refreshCount <= 0){
                    if (!this.isNullOrUndefined(subs)) {
                        subs.unsubscribe();
                    }
                }

                this.refreshDiscoveredDevices();

            }), this._onlineForceDiscoveryRefreshProcess);
        }
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.settingsFormGroup.disable();
        this.countingNetworkFormGroup.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();

        if(!isNullOrUndefined(this.connectedDevice) && this.connectedDevice.masterMode !== MasterModeEnum.uberMaster){
            this.settingsFormGroup.enable();
        }

        this.countingNetworkFormGroup.enable();
    }

    private setSettingsFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.connectedDevice) && !this.isNullOrUndefined(this.settingsFormGroup)) {
            this.settingsFormGroup.setValue({
                setToMaster: this.connectedDevice.setToMaster,
            });
        }
    }

    private updateModelValuesSettingsFormGroup(): void {
        if (!this.isNullOrUndefined(this.connectedDevice) && this.isReadOnly === false) {
            const formValues = this.settingsFormGroup.value;

            if(this.connectedDevice.setToMaster !== formValues.setToMaster && !formValues.setToMaster){
                const confirmDialogRef = this.openOkCancelDialog('Confirm Wide Tracker change', 'Changing a master to a node will mean Estate Manager will no longer be able to communicate with the device directly, if a master device is present a widetracker network can be created otherwise you will lose connectivity with this device. Do you want to change this device into a node?', true);

                confirmDialogRef.afterClosed().subscribe(
                    (result: OkCancelDialogResult) => {
                        if (!this.isNullOrUndefined(result) && result.ok) {
                            this.connectedDevice.setToMaster = formValues.setToMaster;

                            this.updateSaveAllAction(this);
                        }
                        else{
                            this.settingsFormGroup.setValue({setToMaster : this.connectedDevice.setToMaster});
                        }
                    }
                );
            }
            else{
                this.connectedDevice.setToMaster = formValues.setToMaster;

                this.updateSaveAllAction(this);
            }
        }
    }

    private setCountingNetworkFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.connectedDevice) && !this.isNullOrUndefined(this.countingNetworkFormGroup)) {
            this.countingNetworkFormGroup.setValue({
            });
        }
    }

    private doesProductTypeMatch(masterType: string, nodeType: string): boolean {
        const regPattern = /^IRC6\d{3}(-|S)\w{2}$/;
        const productIDRegEx = new RegExp(regPattern);

        if (productIDRegEx.test(masterType) === productIDRegEx.test(nodeType)) {
            return true;
        }

        return false;
    }

    private updateModelValuesCountingNetworkFormGroup(): void {

    }

    private mergeDevices(): void {
        this.devices = [
            ...this.isNullOrUndefined(this._nodeList) ? [] : this._nodeList.map(i => ({ serial: i.serialNumber, iPAddress: i.iPAddress, assigned: true, assignedOrg: true, master: false } as IListDevice)),
            ...this.isNullOrUndefined(this._discoveredNodes) ? [] : this._discoveredNodes.map(i => ({ serial: i.serialNumber, iPAddress: i.iPAddress, assigned: false, assignedOrg: false, master: i.master, canBeSelected: i.canBeSelected } as IListDevice))
        ];
    }
}
