import { Injectable } from '@angular/core';
import { AllDataModel } from '@rift/models/restapi/AllData.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { BluetoothService } from '@rift/service/data/bluetooth/Bluetooth.Service';
import { CountsService } from '@rift/service/data/counts/Counts.Service';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { GlobalService } from '@rift/service/data/global/Global.Service';
import { HeightService } from '@rift/service/data/height/Height.Service';
import { HistogramService } from '@rift/service/data/histogram/Histogram.Service';
import { HttpPostService } from '@rift/service/data/httppost/HttpPost.Service';
import { LineService } from '@rift/service/data/line/Line.Service';
import { OutboundConnectionsService } from '@rift/service/data/outboundconnections/OutboundConnections.Service';
import { PathMapService } from '@rift/service/data/pathmap/PathMap.Service';
import { PolygonService } from '@rift/service/data/polygon/Polygon.Service';
import { RecordingService } from '@rift/service/data/recording/Recording.Service';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { RelayService } from '@rift/service/data/relay/Relay.Service';
import { SecurityService } from '@rift/service/data/security/Security.Service';
import { WideTrackerService } from '@rift/service/data/widetracker/WideTracker.Service';
import { RestApiGlobalService } from '@rift/service/restapi/v1/RestApi.Global.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, zip, of, Subject } from 'rxjs';
import { flatMap, map, finalize } from 'rxjs/operators';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { MQTTService } from '@rift/service/data/mqtt/MQTT.Service';
import { isNullOrUndefined, isFunction } from '@shared/utility/General.Utility';
import { ValidationDataService } from '@rift/service/data/validation/Validation.Service';

@Injectable()
export class AllDataService extends RiftBaseService {
    private _getAllDataLoadingTracker = new ObservableTracker<AllDataModel>();
    private _dataServices: RiftBaseService[] = [];

    public constructor(
        private readonly _validationDataService: ValidationDataService,
        private readonly _bluetoothService: BluetoothService,
        private readonly _countsService: CountsService,
        private readonly _deviceService: DeviceService,
        private readonly _globalService: GlobalService,
        private readonly _heightService: HeightService,
        private readonly _histogramService: HistogramService,
        private readonly _httpPostService: HttpPostService,
        private readonly _lineService: LineService,
        private readonly _mQTTService: MQTTService,
        private readonly _outboundConnectionsService: OutboundConnectionsService,
        private readonly _pathMapService: PathMapService,
        private readonly _polygonService: PolygonService,
        private readonly _recordingService: RecordingService,
        private readonly _registerService: RegisterService,
        private readonly _relayService: RelayService,
        private readonly _securityService: SecurityService,
        private readonly _WideTrackerService: WideTrackerService,
        private readonly _restApiGlobalService: RestApiGlobalService) {
        super();

        this._dataServices.push(this._bluetoothService);
        this._dataServices.push(this._countsService);
        this._dataServices.push(this._deviceService);
        this._dataServices.push(this._globalService);
        this._dataServices.push(this._heightService);
        this._dataServices.push(this._histogramService);
        this._dataServices.push(this._httpPostService);
        this._dataServices.push(this._lineService);
        this._dataServices.push(this._mQTTService);
        this._dataServices.push(this._outboundConnectionsService);
        this._dataServices.push(this._pathMapService);
        this._dataServices.push(this._polygonService);
        this._dataServices.push(this._recordingService);
        this._dataServices.push(this._registerService);
        this._dataServices.push(this._relayService);
        this._dataServices.push(this._securityService);
        this._dataServices.push(this._WideTrackerService);
        this._dataServices.push(this._validationDataService);
    }

    public clearAllCache(): void {
        this._dataServices.forEach(dataService => {
            if (!isNullOrUndefined(dataService.clearCache) && isFunction(dataService.clearCache)) {
                dataService.clearCache();
            }
        });
    }

    public getAllData(process?: ProcessMonitorServiceProcess, retryDisabled: boolean = false): Observable<AllDataModel> {
        return this._getAllDataLoadingTracker
            .getLoading()
            .observable(
                of(true).pipe(
                    flatMap(() => this._restApiGlobalService.getAllData(process, retryDisabled).pipe(
                            flatMap((allData: AllDataModel) => {
                                if (isNullOrUndefined(allData)){
                                    return of(new AllDataModel());
                                }

                                return zip(
                                    this._heightService.setCache(allData.globalHeightFilterData),
                                    this._outboundConnectionsService.setCache(allData.clientConnectionData),
                                    this._deviceService.setCache(allData.deviceData, allData.deviceAdvancedSettingsData),
                                    this._globalService.setCache(allData.globalData, allData.timeData, allData.iPData, allData.discriminationData),
                                    this._countsService.setCache(allData.countLogConfig),
                                    this._recordingService.setCache(allData.videoSettingsData),
                                    this._relayService.setCache(allData.relayData),
                                    this._registerService.setCache(allData.registersData),
                                    this._histogramService.setCache(allData.histogramLogConfig),
                                    this._httpPostService.setCache(allData.httpPostData),
                                    this._bluetoothService.setCache(allData.bluetoothData),
                                    this._lineService.setCache(allData.lineData),
                                    this._polygonService.setCache(allData.polygonData),
                                ).pipe(map(() => allData));
                            }),
                        )),
                )
            );
    }
}
