import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { UIVersion } from './UIVersion';

@Injectable()
export class VersionService {

    private readonly _fileName: string = 'package.json';
    private readonly _fallBackUiVersion: string = '0.0.14';
    private readonly _fallBackAppVersion: string = '0.0.0';
    private _uiVersion: string = null;
    private _appVersion: string = null;

    public constructor(
        private readonly _config: ConfigurationService,
        private readonly _httpClientBase: HttpClient) {
        this.getVersions();
    }

    public getVersions(): void {
        this.getUiVersion().subscribe(version => this._uiVersion = version);
        this.getAppVersion().subscribe(version => this._appVersion = version);
    }

    public get uiVersion(): string {
        return this._uiVersion;
    }

    public get appVersion(): string {
        return this._appVersion;
    }

    private getUiVersion(): Observable<string> {
        return this._httpClientBase.get(`${this._config.versionBase}${this._fileName}`).pipe(
            map((response: UIVersion) => {
                if (!isNullOrUndefined(response) && !isNullOrUndefined(response.version)) {
                    return response.version;
                }
                return this._fallBackUiVersion;
            }),
            catchError((error: Error, caught: Observable<any>) => of(this._fallBackUiVersion)),
        );
    }

    private getAppVersion(): Observable<string> {
        return this._httpClientBase.get(`${this._config.appBase}api/rt/v1/local/version_number`, { withCredentials: true }).pipe(
            map((response: string) => {
                if (!isNullOrUndefined(response)) {
                    const matches = response.match(/([0-9]*\.[0-9]*\.[0-9]*\.[0-9]*)/g);
                    if (!isNullOrUndefined(matches) && matches.length > 0) {
                        return matches[0];
                    }
                }
                return this._fallBackAppVersion;
            }),
            catchError((error: Error, caught: Observable<any>) => of(this._fallBackAppVersion)),
        );
    }
}
