/**
 * Device activity actions.
 *
 * @export
 * @enum {number}
 */
export enum DeviceActivityActionEnum {
    connect = 0,
    disconnect = 1,
    reDiscover = 2,
    forwardConnection = 3,
    systemSchedule = 4,
    userSchedule = 5,
    flashChange = 6,
    snapshot = 7,
    backup = 8,
    errorState = 9,
    forcedRediscovery = 10
}

export class DeviceActivityActionEnumHelpers {
    public static getDisplayName(value: DeviceActivityActionEnum): string {
        switch (value) {
            case DeviceActivityActionEnum.connect:
                return 'Connect';
            case DeviceActivityActionEnum.disconnect:
                return 'Disconnect';
            case DeviceActivityActionEnum.reDiscover:
                return 'Rediscover';
            case DeviceActivityActionEnum.forwardConnection:
                return 'Forward Connection';
            case DeviceActivityActionEnum.systemSchedule:
                return 'System Schedule';
            case DeviceActivityActionEnum.userSchedule:
                return 'User Schedule';
            case DeviceActivityActionEnum.flashChange:
                return 'Flash Change';
            case DeviceActivityActionEnum.snapshot:
                return 'Snap Shot';
            case DeviceActivityActionEnum.backup:
                return 'Backup';
            case DeviceActivityActionEnum.errorState:
                return 'Error State';
            case DeviceActivityActionEnum.forcedRediscovery:
                return 'Forced Rediscovery';
        }
    }

    public static toRestApi(value: DeviceActivityActionEnum): string {
        switch (value) {
            case DeviceActivityActionEnum.connect:
                return 'Connect';
            case DeviceActivityActionEnum.disconnect:
                return 'Disconnect';
            case DeviceActivityActionEnum.reDiscover:
                return 'ReDiscover';
            case DeviceActivityActionEnum.forwardConnection:
                return 'ForwardConnection';
            case DeviceActivityActionEnum.systemSchedule:
                return 'SystemSchedule';
            case DeviceActivityActionEnum.userSchedule:
                return 'UserSchedule';
            case DeviceActivityActionEnum.flashChange:
                return 'FlashChange';
            case DeviceActivityActionEnum.snapshot:
                return 'Snapshot';
            case DeviceActivityActionEnum.backup:
                return 'Backup';
            case DeviceActivityActionEnum.errorState:
                return 'ErrorState';
            case DeviceActivityActionEnum.forcedRediscovery:
                return 'ForcedRediscovery';
        }
    }
}
