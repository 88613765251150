import { Component, Injector, Input, HostBinding } from '@angular/core';
import { UnitsOfMeasurementService } from '@rift/service/unitsofmeasurement/UnitsOfMeasurement.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { UnitOfMeasurementEnum } from '@shared/enum/UnitOfMeasurement.Enum';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';

@Component({
    selector: 'rift-units-of-measurement-label',
    templateUrl: './UnitsOfMeasurementLabel.Component.html',
    styleUrls: ['./UnitsOfMeasurementLabel.Component.scss'],
})
export class UnitsOfMeasurementLabelComponent extends BaseComponent {
    public static className: string = 'UnitsOfMeasurementLabelComponent';

    @HostBinding()
    public id: string = 'rift-units-of-measurement-label';
    public suffix: string = null;

    public units: UnitsOfMeasurementEnum = null;
    public value: number = null;
    public unitsOfMeasurementChangeProcess: ProcessMonitorServiceProcess;
    private _imperialFractionDigits: number = 2;
    private _imperialUnit: UnitOfMeasurementEnum;
    private _metricUnit: UnitOfMeasurementEnum;
    private _metricValue: number = null;
    private _unitShortName: boolean = true;

    public constructor(
        private readonly _unitsOfMeasurementService: UnitsOfMeasurementService,
        private readonly _injector: Injector) {
        super(_injector);

        this.unitsOfMeasurementChangeProcess = this.processMonitorService.getProcess(UnitsOfMeasurementLabelComponent.className, 'Units of measurement change');

        this.units = this._unitsOfMeasurementService.units;
        this.convertValue();

        this.addSubscription(this.observableHandlerBase(this._unitsOfMeasurementService.unitsChange, this.unitsOfMeasurementChangeProcess).subscribe(units => {
            this.units = units;
            this.convertValue();
        }), this.unitsOfMeasurementChangeProcess);
    }

    public convertValue(): void {
        if (!isNullOrUndefined(this.metricValue) && !isNullOrUndefined(this.metricUnit) && !isNullOrUndefined(this.imperialUnit)) {
            if (this.units === UnitsOfMeasurementEnum.metric) {
                this.value = this.metricValue;
            } else {
                this.value = this._unitsOfMeasurementService.convertToImperial(this.metricValue, this.metricUnit, this.imperialUnit, this.imperialFractionDigits);
            }
            this.suffix = this._unitsOfMeasurementService.getSuffix(this.metricUnit, this.imperialUnit, this.unitShortName);
        }
    }

    @Input()
    public get imperialFractionDigits(): number {
        return this._imperialFractionDigits;
    }
    public set imperialFractionDigits(value: number) {
        if (this._imperialFractionDigits !== value) {
            this._imperialFractionDigits = value;
            this.convertValue();
        }
    }

    @Input()
    public get imperialUnit(): UnitOfMeasurementEnum {
        return this._imperialUnit;
    }
    public set imperialUnit(value: UnitOfMeasurementEnum) {
        if (this._imperialUnit !== value) {
            this._imperialUnit = value;
            this.convertValue();
        }
    }


    @Input()
    public get metricUnit(): UnitOfMeasurementEnum {
        return this._metricUnit;
    }
    public set metricUnit(value: UnitOfMeasurementEnum) {
        if (this._metricUnit !== value) {
            this._metricUnit = value;
            this.convertValue();
        }
    }

    @Input()
    public get metricValue(): number {
        return this._metricValue;
    }
    public set metricValue(value: number) {
        if (this._metricValue !== value) {
            this._metricValue = value;
            this.convertValue();
        }
    }

    @Input()
    public get unitShortName(): boolean {
        return this._unitShortName;
    }
    public set unitShortName(value: boolean) {
        if (this._unitShortName !== value) {
            this._unitShortName = value;
            this.convertValue();
        }
    }
}
