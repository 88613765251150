import { Component, HostListener, Injector, OnDestroy, OnInit, HostBinding } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AddConnectionDialogData, AddConnectionDialogResult, SettingsOutboundConnectionsAddConnectionComponent } from '@em/components/settings/outboundconnections/addconnection/Settings.OutboundConnections.AddConnection.Component';
import { AddEditRemoveSelectGroupDialogData, AddEditRemoveSelectGroupDialogResult, SettingsOutboundConnectionsAddEditRemoveSelectGroupComponent } from '@em/components/settings/outboundconnections/addeditremoveselectgroup/Settings.OutboundConnections.AddEditRemoveSelectGroup.Component';
import { SettingsOutboundConnectionsEditConnectionComponent, SettingsOutboundConnectionsEditData, SettingsOutboundConnectionsEditResult } from '@em/components/settings/outboundconnections/editconnection/Settings.OutboundConnections.EditConnection.Component';
import { GroupViewModel } from '@em/components/settings/outboundconnections/GroupViewModel';
import { ConnectionModel } from '@em/models/restapi/Connection.Model';
import { ConnectionCollectionModel } from '@em/models/restapi/ConnectionCollection.Model';
import { ConnectionGroupCollectionModel } from '@em/models/restapi/ConnectionGroupCollection.Model';
import { OutboundConnectionService } from '@em/service/data/outboundconnection/OutboundConnection.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { EventsService } from '@shared/service/events/Events.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isArray } from '@shared/utility/General.Utility';
import { from, Observable, of, zip } from 'rxjs';
import { flatMap, map, tap, zipAll } from 'rxjs/operators';
import { DataPollingEvent } from '@shared/service/datapolling/DataPolling.Service.Event';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'em-settings-outbound-connections',
    templateUrl: './Settings.OutboundConnections.Component.html',
    styleUrls: ['./Settings.OutboundConnections.Component.scss']
})
export class SettingsOutboundConnectionsComponent extends BaseComponent implements OnDeactivate, OnDestroy, OnInit, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsOutboundConnectionsComponent';
    public addConnectionAction: NavBarAction;
    public addGroupAction: NavBarAction;
    public displayedColumns = ['address', 'port', 'state', 'lastConnected', 'serialNumber'];
    public moveConnectionProcess: ProcessMonitorServiceProcess;
    public addGroupProcess: ProcessMonitorServiceProcess;
    public addConnectionProcess: ProcessMonitorServiceProcess;
    public dataPollingProcess: ProcessMonitorServiceProcess;

    public groups: Array<GroupViewModel>;

    @HostBinding()
    public id: string = 'em-settings-outbound-connections';
    private _connectionCollection: ConnectionCollectionModel;

    private _connectionGroupCollection: ConnectionGroupCollectionModel;
    private _dataPollingEvent: DataPollingEvent;

    public constructor(
        private readonly _eventsService: EventsService,
        private readonly _dialog: MatDialog,
        private readonly _formBuilder: FormBuilder,
        private readonly _navBarService: NavBarActionService,
        private readonly _dataPollingService: DataPollingService,
        private readonly _outboundConnectionService: OutboundConnectionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.moveConnectionProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsComponent.className, 'Move Connection');
        this.addGroupProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsComponent.className, 'Add Group');
        this.addConnectionProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsComponent.className, 'Add Connection');
        this.dataPollingProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsComponent.className, 'Data polling');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsOutboundConnectionsComponent.className, this.saveAllChangesProcessText);

        this._dataPollingEvent = new DataPollingEvent('SettingsOutboundConnectionsComponent', 0, 30000, this.loadDataProcess);

        this.addGroupAction = new NavBarAction();
        this.addGroupAction.name = 'addgroupoutboundconnections';
        this.addGroupAction.text = 'Add Group';
        this.addSubscription(this.observableHandlerBase(this.addGroupAction.onButtonClick, this.addGroupProcess).subscribe(() => { this.addGroup(); }), this.addGroupProcess);

        this.addConnectionAction = new NavBarAction();
        this.addConnectionAction.name = 'addconnectionoutboundconnections';
        this.addConnectionAction.text = 'Add Connection';
        this.addSubscription(this.observableHandlerBase(this.addConnectionAction.onButtonClick, this.addConnectionProcess).subscribe(() => { this.addConnection(); }), this.addConnectionProcess);

        this.addSaveAllAction(this);
        this.startDataPolling();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public dataExportGetData = (displayedColumn: string, data: ConnectionModel) => {
        switch (displayedColumn) {
            case 'state':
                return this.isNullOrUndefined(data.activeConnection) ? '' : data.activeConnection.activeConnectionStateString;
            case 'lastConnected':
                return this.isNullOrUndefined(data.activeConnection) ? '' : data.activeConnection.lastConnectionTimeText;
            case 'serialNumber':
                return this.isNullOrUndefined(data.activeConnection) ? '' : data.activeConnection.deviceSerial;
            default:
                return data[displayedColumn];
        }
    };

    public dataExportGetHeader = (displayedColumn: string) => displayedColumn;

    public addConnection(): void {
        const ref = this._dialog.open(SettingsOutboundConnectionsAddConnectionComponent, { data: new AddConnectionDialogData(), disableClose: true });

        this.addSubscription(this.observableHandlerBase(ref.afterClosed(), this.addConnectionProcess).subscribe((result: AddConnectionDialogResult) => {
            if (!this.isNullOrUndefined(result) && result.success === true) {
                if (isArray(result.connection)) {
                    const connections: Array<ConnectionModel> = result.connection as Array<ConnectionModel>;
                    const connectionsLength = connections.length;

                    for (let connectionIndex = 0; connectionIndex < connectionsLength; connectionIndex++) {
                        const connection = connections[connectionIndex];

                        const group = this.groups.find(i => i.item.connectionGroupId === connection.groupId);
                        if (!this.isNullOrUndefined(group)) {
                            group.connections.push(connection);
                            group.dataSource.data = group.connections;
                        } else {
                            const unGrouped = this.groups.find(i => i.item.name === 'Ungrouped');
                            unGrouped.connections.push(connection);
                            unGrouped.dataSource.data = unGrouped.connections;
                        }

                        this._connectionCollection.items.push(connection);
                    }
                } else {
                    const unGrouped = this.groups.find(i => i.item.name === 'Ungrouped');
                    unGrouped.connections.push(result.connection as ConnectionModel);
                    unGrouped.dataSource.data = unGrouped.connections;

                    this._connectionCollection.items.push(result.connection as ConnectionModel);
                }

                this.updateSaveAllAction(this);
            }
        }), this.addConnectionProcess);
    }

    public addGroup(): void {
        const ref = this._dialog.open(SettingsOutboundConnectionsAddEditRemoveSelectGroupComponent, { data: new AddEditRemoveSelectGroupDialogData('add'), disableClose: true });

        this.addSubscription(this.observableHandlerBase(ref.afterClosed(), this.addGroupProcess).subscribe((result: AddEditRemoveSelectGroupDialogResult) => {
            if (!this.isNullOrUndefined(result) && result.success === true) {
                const groupViewModel = new GroupViewModel(result.group);
                this.groups.push(groupViewModel);
                this._connectionGroupCollection.items.push(result.group);

                this.updateSaveAllAction(this);
            }
        }), this.addGroupProcess);
    }

    public applyFilter(filterValue: string, group: GroupViewModel) {
        if (!this.isNullOrUndefined(group.dataSource.data) && group.dataSource.data.length > 0) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            group.dataSource.filter = filterValue;
        }
    }

    public deleteConnection(connection: ConnectionModel, group: GroupViewModel): void {
        const removeVMIndex = group.connections.findIndex(i => i.uniqueId === connection.uniqueId);
        if (removeVMIndex !== -1) {
            group.connections.splice(removeVMIndex, 1);
            group.dataSource.data = group.connections;
        }

        const removeMIndex = this._connectionCollection.items.findIndex(i => i.uniqueId === connection.uniqueId);
        if (removeMIndex !== -1) {
            this._connectionCollection.items.splice(removeMIndex, 1);
        }

        this.updateSaveAllAction(this);
    }

    public deleteGroup(group: GroupViewModel): void {
        const removeVMIndex = this.groups.findIndex(i => i.item.uniqueId === group.item.uniqueId);
        if (removeVMIndex !== -1) {
            this.groups.splice(removeVMIndex, 1);
        }
        const removeMIndex = this._connectionGroupCollection.items.findIndex(i => i.uniqueId === group.item.uniqueId);
        if (removeMIndex !== -1) {
            this._connectionGroupCollection.items.splice(removeMIndex, 1);
        }

        this.updateSaveAllAction(this);
    }

    public editConnection(connection: ConnectionModel, group: GroupViewModel): void {
        this.addSubscription(this._dialog.open(SettingsOutboundConnectionsEditConnectionComponent, { data: new SettingsOutboundConnectionsEditData(connection, this.groups) }).afterClosed().subscribe(
            (result: SettingsOutboundConnectionsEditResult) => {
                if (!this.isNullOrUndefined(result)) {
                    if (this.isNullOrUndefined(result.connection)) {
                        connection.clearChanges();
                    }

                    this.updateSaveAllAction(this);
                    group.dataSource.data = [];
                    group.dataSource.data = group.connections;
                }
            }
        ));
    }

    public editGroup(group: GroupViewModel): void {
        this.addSubscription(this._dialog.open(SettingsOutboundConnectionsAddEditRemoveSelectGroupComponent, { data: new AddEditRemoveSelectGroupDialogData('edit', group.item), disableClose: true }).afterClosed().subscribe(
            () => {
                this.updateSaveAllAction(this);
            }
        ));
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._outboundConnectionService.getGroups(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this._connectionGroupCollection = result;
                        this.changeTracker.track(this._connectionGroupCollection);
                    }
                    return true;
                })
            ),
            this._outboundConnectionService.getConnections(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this._connectionCollection = result;
                        this.changeTracker.track(this._connectionCollection);
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process).pipe(
            tap(() => {
                this.initGroupViewModels();
            })
        );
    }

    public moveConnection(connection: ConnectionModel, group: GroupViewModel): void {
        const ref = this._dialog.open(SettingsOutboundConnectionsAddEditRemoveSelectGroupComponent, { data: new AddEditRemoveSelectGroupDialogData('select'), disableClose: true });

        this.addSubscription(this.observableHandlerBase(ref.afterClosed(), this.moveConnectionProcess).subscribe((result: AddEditRemoveSelectGroupDialogResult) => {
            if (!this.isNullOrUndefined(result) && result.success === true) {
                const moveToIndex = this.isNullOrUndefined(result.group) ? this.groups.findIndex(i => i.item.name === 'Ungrouped') : this.groups.findIndex(i => i.item.connectionGroupId === result.group.connectionGroupId);
                if (moveToIndex !== -1) {
                    const removeIndex = group.connections.findIndex(i => i.uniqueId === connection.uniqueId);

                    const move = group.connections[removeIndex];
                    group.connections.splice(removeIndex, 1);
                    group.dataSource.data = group.connections;

                    const moveToGroup = this.groups[moveToIndex];
                    moveToGroup.connections.push(move);
                    moveToGroup.dataSource.data = moveToGroup.connections;

                    connection.groupId = this.isNullOrUndefined(result.group) ? null : result.group.connectionGroupId;

                    this.updateSaveAllAction(this);
                }
            }
        }), this.moveConnectionProcess);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this.stopDataPolling();

        this._navBarService.removeAction(this.addGroupAction);
        this._navBarService.removeAction(this.addConnectionAction);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this._navBarService.addAction(this.addGroupAction);
        this._navBarService.addAction(this.addConnectionAction);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            of(this._connectionCollection.items.removedItems).pipe(
                flatMap(removedItems => {
                    if (removedItems.length > 0) {
                        return from(removedItems).pipe(
                            map(removedItem => this._outboundConnectionService.deleteConnection(removedItem.connectionId, process).pipe(
                                    map(result => true)
                                )),
                            zipAll(),
                            map(result => this.isZipResultSuccess(result)),
                        );
                    } else {
                        return of(true);
                    }
                }),
            ),
            of(this._connectionCollection.items.newItems).pipe(
                flatMap(newItems => {
                    if (newItems.length > 0) {
                        return from(newItems).pipe(
                            map(newItem => this._outboundConnectionService.addConnection(newItem, process).pipe(
                                    map(result => true)
                                )),
                            zipAll(),
                            map(result => this.isZipResultSuccess(result)),
                        );
                    } else {
                        return of(true);
                    }
                }),
            ),
            of(this._connectionCollection.items.updatedNotNewItems).pipe(
                flatMap(updatedNotNewItems => {
                    if (updatedNotNewItems.length > 0) {
                        return from(updatedNotNewItems).pipe(
                            map(updatedItem => this._outboundConnectionService.updateConnection(updatedItem, process).pipe(
                                    map(result => true)
                                )),
                            zipAll(),
                            map(result => this.isZipResultSuccess(result)),
                        );
                    } else {
                        return of(true);
                    }
                }),
            ),
            of(this._connectionGroupCollection.items.removedItems).pipe(
                flatMap(removedItems => {
                    if (removedItems.length > 0) {
                        return from(removedItems).pipe(
                            map(removedItem => this._outboundConnectionService.deleteGroup(removedItem.connectionGroupId, process).pipe(
                                    map(result => true)
                                )),
                            zipAll(),
                            map(result => this.isZipResultSuccess(result)),
                        );
                    } else {
                        return of(true);
                    }
                }),
            ),
            of(this._connectionGroupCollection.items.newItems).pipe(
                flatMap(newItems => {
                    if (newItems.length > 0) {
                        return from(newItems).pipe(
                            map(newItem => this._outboundConnectionService.addGroup(newItem, process).pipe(
                                    map(result => true)
                                )),
                            zipAll(),
                            map(result => this.isZipResultSuccess(result)),
                        );
                    } else {
                        return of(true);
                    }
                }),
            ),
            of(this._connectionGroupCollection.items.updatedNotNewItems).pipe(
                flatMap(updatedNotNewItems => {
                    if (updatedNotNewItems.length > 0) {
                        return from(updatedNotNewItems).pipe(
                            map(updatedItem => this._outboundConnectionService.updateGroup(updatedItem, process).pipe(
                                    map(result => true)
                                )),
                            zipAll(),
                            map(result => this.isZipResultSuccess(result)),
                        );
                    } else {
                        return of(true);
                    }
                }),
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap(result => {
                if (this.isZipResultSuccess(result)) {
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process).pipe(
                        tap(() => {
                            this._eventsService.changedOutboundConnections();
                            this.updateSaveAllAction(this);
                        })
                    );
                } else {
                    return of(result);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._outboundConnectionService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    private initGroupViewModels(): void {
        this.groups = [];

        const viewUngrouped = new GroupViewModel('Ungrouped');
        this.groups.push(viewUngrouped);

        const connectionsLength = this._connectionCollection.items.length;
        for (let connectionIndex = 0; connectionIndex < connectionsLength; connectionIndex++) {
            const connection = this._connectionCollection.items[connectionIndex];
            if (!this.isNullOrUndefined(connection) && this.isNullOrUndefined(connection.groupId)) {
                viewUngrouped.connections.push(connection);
            }
        }

        const groupsLength = this._connectionGroupCollection.items.length;
        for (let groupIndex = 0; groupIndex < groupsLength; groupIndex++) {
            const group = this._connectionGroupCollection.items[groupIndex];
            const viewGroup = new GroupViewModel(group);
            this.groups.push(viewGroup);

            for (let connectionIndex = 0; connectionIndex < connectionsLength; connectionIndex++) {
                const connection = this._connectionCollection.items[connectionIndex];
                if (!this.isNullOrUndefined(connection.groupId) && connection.groupId === group.connectionGroupId) {
                    viewGroup.connections.push(connection);
                }
            }
        }

        const vmGroupsLength = this.groups.length;
        for (let vmGroupIndex = 0; vmGroupIndex < vmGroupsLength; vmGroupIndex++) {
            const vmGroup = this.groups[vmGroupIndex];
            vmGroup.dataSource.data = vmGroup.connections;
        }
    }

    private subDataPolling(): void {
        this.addSubscription(this.observableHandlerBase(this._dataPollingEvent.poll, this.dataPollingProcess).subscribe(() => {
            if (this.hasChanges === false) {
                this._outboundConnectionService.clearCache();
                this.loadDataStartBase(this);
            }
        }), this.dataPollingProcess);
    }

    private startDataPolling(): void {
        this.subDataPolling();
        this._dataPollingService.startEvent(this._dataPollingEvent);
    }

    private stopDataPolling(): void {
        this._dataPollingService.stopEvent(this._dataPollingEvent);
    }
}
