import { AssignedIPInformationModel, IAssignedIPInformationModel } from '@rift/models/restapi/AssignedIPInformation.Model';
import { BluetoothModel, IBluetoothModel } from '@rift/models/restapi/Bluetooth.Model';
import { ClientConnectionCollectionModel, IClientConnectionCollectionModel } from '@rift/models/restapi/ClientConnectionCollection.Model';
import { CountLogConfigModel, ICountLogConfigModel } from '@rift/models/restapi/CountLogConfig.Model';
import { DeviceAdvancedSettingsModel, IDeviceAdvancedSettingsModel } from '@rift/models/restapi/DeviceAdvancedSettings.Model';
import { DeviceCollectionModel, IDeviceCollectionModel } from '@rift/models/restapi/DeviceCollection.Model';
import { DiscriminationModel, IDiscriminationModel } from '@rift/models/restapi/Discrimination.Model';
import { GlobalModel, IGlobalModel } from '@rift/models/restapi/Global.Model';
import { GlobalHeightFilterModel, IGlobalHeightFilterModel } from '@rift/models/restapi/GlobalHeightFilter.Model';
import { HistogramLogConfigModel, IHistogramLogConfigModel } from '@rift/models/restapi/HistogramLogConfig.Model';
import { HttpPostModel, IHttpPostModel } from '@rift/models/restapi/HttpPost.Model';
import { IIPSetupModel, IPSetupModel } from '@rift/models/restapi/IPSetup.Model';
import { ILineCollectionModel, LineCollectionModel } from '@rift/models/restapi/LineCollection.Model';
import { PolygonCollectionModel } from '@rift/models/restapi/PolygonCollection.Model';
import { IRegisterBaseCollectionModel, RegisterBaseCollectionModel } from '@rift/models/restapi/RegisterBaseCollection.Model';
import { IRelayModel, RelayModel } from '@rift/models/restapi/Relay.Model';
import { IResultModel } from '@rift/models/restapi/Result.Model';
import { ITimeSetupModel, TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { IVideoSettingsModel, VideoSettingsModel } from '@rift/models/restapi/VideoSettings.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MQTTModel } from '@rift/models/restapi/MQTT.Model';

export interface IAllDataModel extends IRestModel {
    globalData: IGlobalModel;
    iPData: IIPSetupModel;
    assignedIPInfo: IAssignedIPInformationModel;
    timeData: ITimeSetupModel;
    bluetoothData: IBluetoothModel;
    deviceAdvancedSettingsData: IDeviceAdvancedSettingsModel;
    countLogConfig: ICountLogConfigModel;
    histogramLogConfig: IHistogramLogConfigModel;
    httpPostData: IHttpPostModel;
    globalHeightFilterData: IGlobalHeightFilterModel;
    relayData: IRelayModel;
    videoSettingsData: IVideoSettingsModel;
    discriminationData: IDiscriminationModel;
    clientConnectionData: IClientConnectionCollectionModel;
    registersData: IRegisterBaseCollectionModel;
    deviceData: IDeviceCollectionModel;
    lineData: ILineCollectionModel;
    mQTTData: MQTTModel;
}

export class AllDataModel extends BaseModel implements IAllDataModel, IResultModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;

    public globalData: GlobalModel = null;
    public iPData: IPSetupModel = null;
    public assignedIPInfo: AssignedIPInformationModel = null;
    public timeData: TimeSetupModel = null;
    public bluetoothData: BluetoothModel = null;
    public deviceAdvancedSettingsData: DeviceAdvancedSettingsModel = null;
    public countLogConfig: CountLogConfigModel = null;
    public histogramLogConfig: HistogramLogConfigModel = null;
    public httpPostData: HttpPostModel = null;
    public globalHeightFilterData: GlobalHeightFilterModel = null;
    public relayData: RelayModel = null;
    public videoSettingsData: VideoSettingsModel = null;
    public discriminationData: DiscriminationModel = null;
    public clientConnectionData: ClientConnectionCollectionModel = null;
    public registersData: RegisterBaseCollectionModel = null;
    public deviceData: DeviceCollectionModel = null;
    public lineData: LineCollectionModel = null;
    public polygonData: PolygonCollectionModel = null;
    public mQTTData: MQTTModel = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-AllDataModel');

        this.globalData = RestModelUtility.loadFrom(restModel.GlobalData, GlobalModel);
        this.iPData = RestModelUtility.loadFrom(restModel.IPData, IPSetupModel);
        this.assignedIPInfo = RestModelUtility.loadFrom(restModel.AssignedIPInfo, AssignedIPInformationModel);
        this.timeData = RestModelUtility.loadFrom(restModel.TimeData, TimeSetupModel);
        this.bluetoothData = RestModelUtility.loadFrom(restModel.BluetoothData, BluetoothModel);
        this.deviceAdvancedSettingsData = RestModelUtility.loadFrom(restModel.DeviceAdvancedSettingsData, DeviceAdvancedSettingsModel);
        this.countLogConfig = RestModelUtility.loadFrom(restModel.CountLogConfig, CountLogConfigModel);
        this.histogramLogConfig = RestModelUtility.loadFrom(restModel.HistogramLogConfig, HistogramLogConfigModel);
        this.httpPostData = RestModelUtility.loadFrom(restModel.HttpPostData, HttpPostModel);
        this.globalHeightFilterData = RestModelUtility.loadFrom(restModel.GlobalHeightFilterData, GlobalHeightFilterModel);
        this.relayData = RestModelUtility.loadFrom(restModel.RelayData, RelayModel);
        this.videoSettingsData = RestModelUtility.loadFrom(restModel.VideoSettingsData, VideoSettingsModel);
        this.discriminationData = RestModelUtility.loadFrom(restModel.DiscriminationData, DiscriminationModel);
        this.clientConnectionData = RestModelUtility.loadFrom(restModel.ClientConnectionData, ClientConnectionCollectionModel);
        this.registersData = RestModelUtility.loadFrom(restModel.RegistersData, RegisterBaseCollectionModel);
        this.deviceData = RestModelUtility.loadFrom(restModel.DeviceData, DeviceCollectionModel);
        this.lineData = RestModelUtility.loadFrom(restModel.LineData, LineCollectionModel);
        this.polygonData = RestModelUtility.loadFrom(restModel.PolygonData, PolygonCollectionModel);
        this.mQTTData = RestModelUtility.loadFrom(restModel.MQTTData, MQTTModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
