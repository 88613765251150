import { Injectable } from '@angular/core';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { ILoadQueue, IManifest } from '@shared/interface/ILoadQueue';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject, Observable, Observer } from 'rxjs';

@Injectable()
export class TimeLineLoadQueueService extends RiftBaseService implements ILoadQueue {
    public loaded: Subject<void> = new Subject<void>();

    private _isLoaded: boolean = false;

    private readonly _loadManifest: IManifest[] = [
        {
            id: 'durationbar-segment',
            src: './assets/timeline/durationbar/segment.png'
        },
    ];

    public constructor() {
        super();
        this.load();
    }

    public get isLoaded(): boolean {
        return this._isLoaded;
    }

    public getDurationBarSegment(): HTMLImageElement {
        if (this.isLoaded === true) {
            return this.getImage('durationbar-segment');
        } else {
            throw new Error('ViewPortLoadQueueService: Attempt to load image before load queue complete.');
        }
    }

    private getImage(name: string): HTMLImageElement {
        const manifest: IManifest = this._loadManifest.find(i => i.id === name);
        if (!isNullOrUndefined(manifest) && !isNullOrUndefined(manifest.image)) {
            return manifest.image;
        } else {
            throw new Error(`TimeLineLoadQueueService: image ${name} not found.`);
        }
    }

    private load(): void {
        if (!this.isLoaded) {
            this.loadImage(this._loadManifest[0]).subscribe(() => {
                this._isLoaded = true;
                this.loaded.next();
            });
        }
    }

    private loadImage(manifest: IManifest): Observable<IManifest> {
        return new Observable((observer: Observer<IManifest>) => {
            const image = new Image();

            image.onload = () => {
                manifest.image = image;
                observer.next(manifest);
                observer.complete();
                image.onload = null;
            };

            image.onerror = (e) => {
                console.error(e);
                observer.error(e);
            };

            image.src = manifest.src;
        });
    }
}
