<div class="em-settings-task-launcher-delete-title" mat-dialog-title>
    Delete Task Package
    <div fxFlex></div>
</div>
<form [formGroup]="deleteTaskFormGroup">
    <div class="em-settings-task-launcher-delete-content" mat-dialog-content fxLayout="column">
        <div fxLayout="column">            
            <div class="mat-subheading-1">
                Select the task package to delete.
            </div>
            <div fxLayout="column" *ngIf="!isNullOrUndefined(packageList) && packageList.length > 0">
                <mat-form-field>
                    <mat-select #package formControlName="package" placeholder="Task Package">
                        <mat-option *ngFor="let package of packageList | orderBy: 'name'" [value]="package">
                            {{package.name}} ({{package.version}})
                        </mat-option>
                    </mat-select>
                    <mat-error>{{getFormControlErrorMessage(deleteTaskFormGroup.controls['package'])}}</mat-error>
                </mat-form-field>
                <span class="description" *ngIf="selectedPackage !== null">{{package?.value?.description}}
                    {{!isNullOrUndefined(package) && !isNullOrUndefined(package.value) && !isNullOrUndefined(package.value.version) ? '(' + package?.value?.version + ')' : ''}}
                </span>
            </div>
            <div fxLayout="column" *ngIf="isNullOrUndefined(packageList) || packageList.length === 0">
                No packages present
            </div>

        </div>

    </div>
    <div class="em-settings-task-launcher-delete-actions" mat-dialog-actions>        
        <shr-progress-button-spinner [disabled]="deleteTaskFormGroup.valid === false || isNullOrUndefined(selectedPackage)"
            [raised]="true"
            [diameter]="30"
            text="Delete"
            mode="indeterminate"
            [active]="deleteTaskProcess.isRunning === true"
            (shrClick)="delete()"
            color="primary"></shr-progress-button-spinner>
        <div fxFlex></div>
        <button (click)="cancel()" mat-raised-button color="warn">Cancel</button>
    </div>
</form>