import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HistogramLogConfigModel } from '@rift/models/restapi/HistogramLogConfig.Model';
import { HistogramLogPageModel } from '@rift/models/restapi/HistogramLogPage.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRetryOptions } from '@shared/service/restapi/RestApi.Service';

@Injectable()
export class RestApiHistogramsService extends RiftRestApiService {
    private _controller = 'histograms';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public getConfig(process?: ProcessMonitorServiceProcess): Observable<HistogramLogConfigModel> {
        return this.get<HistogramLogConfigModel>(`${this._controller}/config`, HistogramLogConfigModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getHistogramsCounts(from?: Date, process?: ProcessMonitorServiceProcess, retryOptions?: IRetryOptions): Observable<HistogramLogPageModel> {
        let params = this.getTokenParams();
        if (!isNullOrUndefined(from)) {
            params = params.append('deviceMidnight', DateTimeNonMomentUtility.toRestApiDateTime(from));
        }

        return this.get<HistogramLogPageModel>(`${this._controller}/histogramscounts`, HistogramLogPageModel, params, process, retryOptions).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setConfig(config: HistogramLogConfigModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<HistogramLogConfigModel, ResultModel>(`${this._controller}/config`, config, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
