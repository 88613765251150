import { IAutoSpeedSettings } from '@rift/components/validation/Validation.Defaults';
import { IFrameStore } from '@rift/service/validation/database/syncrecording/IFrameStore';
import { IFrameModel } from '@rift/service/validation/models/database/syncrecording/Frame.Model';
import { IRecordingModel } from '@rift/service/validation/models/database/syncrecording/Recording.Model';
import { SyncStateEnum } from '@rift/service/validation/models/SyncState.Enum';
import { IValidationRecordingModel } from '@rift/service/validation/models/ValidationRecording.Model';
import { IAddBookmarkRequest } from '@rift/service/validation/models/webworker/syncrecording/IAddBookmark.Request';
import { IAddBookmarkResponse } from '@rift/service/validation/models/webworker/syncrecording/IAddBookmark.Response';
import { ICancelLoadRequest } from '@rift/service/validation/models/webworker/syncrecording/ICancelLoad.Request';
import { ICancelLoadResponse } from '@rift/service/validation/models/webworker/syncrecording/ICancelLoad.Response';
import { IDeleteBookmarkRequest } from '@rift/service/validation/models/webworker/syncrecording/IDeleteBookmark.Request';
import { IDeleteBookmarkResponse } from '@rift/service/validation/models/webworker/syncrecording/IDeleteBookmark.Response';
import { IGetAllFramesRequest } from '@rift/service/validation/models/webworker/syncrecording/IGetAllFrames.Request';
import { IGetAllFramesResponse } from '@rift/service/validation/models/webworker/syncrecording/IGetAllFrames.Response';
import { IGetFrameRequest } from '@rift/service/validation/models/webworker/syncrecording/IGetFrame.Request';
import { IGetFrameResponse } from '@rift/service/validation/models/webworker/syncrecording/IGetFrame.Response';
import { IGetKeyFramesRequest } from '@rift/service/validation/models/webworker/syncrecording/IGetKeyFrames.Request';
import { IGetKeyFramesResponse } from '@rift/service/validation/models/webworker/syncrecording/IGetKeyFrames.Response';
import { IGetValidationRecordingRequest } from '@rift/service/validation/models/webworker/syncrecording/IGetValidationRecording.Request';
import { IGetValidationRecordingResponse } from '@rift/service/validation/models/webworker/syncrecording/IGetValidationRecording.Response';
import { IInitializeRequest } from '@rift/service/validation/models/webworker/syncrecording/IInitialize.Request';
import { IProgressUpdateResponse } from '@rift/service/validation/models/webworker/syncrecording/IProgressUpdate.Response';
import { IBookmark } from '@rift/service/validation/models/webworker/syncsession/IBookmark';
import { IConvertParams, IDatabaseParams, ISocketParams, IXhrParams, ValidationWorkerShared } from '@rift/workers/validation.worker.shared';
import { IFramesBlock } from '@rift/workers/validationsyncrecording/IFramesBlock';
import { ILoadConfig } from '@rift/workers/validationsyncrecording/ILoadConfig';
import { IMoveToFrameControl } from '@rift/workers/validationsyncrecording/IMoveToFrameControl';
import { IStoreConfig } from '@rift/workers/validationsyncrecording/IStoreConfig';
import { IStoresConfig } from '@rift/workers/validationsyncrecording/IStoresConfig';
import { ValidationFrameTypeEnum } from '@shared/enum/ValidationFrameType.Enum';
import { IndexedDBServiceBase } from '@shared/service/Indexeddb/IndexedDBServiceBase';
import { LoggingServiceLevel } from '@shared/service/logging/Logging.Service.Level';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IFrameData } from '@shared/webworker/IFrameData';
import { IRequest } from '@shared/webworker/IRequest';
import { IResponse } from '@shared/webworker/IResponse';
import { ResponseTypesEnum } from '@shared/webworker/ResponseTypes.Enum';
import { WebWorkerServiceManager } from '@shared/webworker/WebWorkerServiceManager';
import { Observable, Observer, of, Subject, Subscription, timer, zip, throwError } from 'rxjs';
import { catchError, first, flatMap, last, map, tap, filter, switchMap } from 'rxjs/operators';
import { IStopRequest } from '@rift/service/validation/models/webworker/generic/IStop.Request';
import { FrameTrackerStates } from './validationsyncrecording/FrameTrackerStates.Enum';
import { IGetBlockLoadStatusRequest } from '@rift/service/validation/models/webworker/syncrecording/IGetBlockLoadStatus.Request';
import { IGetBlockLoadStatusResponse } from '@rift/service/validation/models/webworker/syncrecording/IGetBlockLoadStatus.Response';
import { IRecordingDataValidation } from '@rift/workers/validationsyncrecording/IRecordingDataValidation';
import { ValidationResultEnum } from '@shared/enum/ValidationResult.Enum';
import { AnyUtility, PropertyModeEnum } from '@shared/utility/Any.Utility';


export const POST_LOAD_BLOCK_SIZE: number = 5000;
export const PRE_LOAD_BLOCK_SIZE: number = 10000;

const sendMessage: any = self.postMessage;
const logLevel: LoggingServiceLevel = LoggingServiceLevel.Error;

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

let lastSocketRequestId: number = 0;

function getNextSocketRequestId(): number {
    lastSocketRequestId++;
    return lastSocketRequestId;
}

interface ISocketHandler{
    handler: ((socketResponse: any) => void);
    errorHandler: ((ev: Event) => void);
}

class SyncRecordingFramesWorker {
    private _cancelLoad: Subject<ICancelLoadRequest> = new Subject<ICancelLoadRequest>();
    private _data: IInitializeRequest = null;
    private _database: IDBDatabase = null;
    private _databaseParams: IDatabaseParams = null;
    private _indexedDBService: IndexedDBServiceBase = null;
    private _isLoadingBlocks: boolean = false;
    private _onSocketMessage: Subject<MessageEvent> = new Subject<MessageEvent>();
    private _preLoadConfigs: Array<ILoadConfig> = new Array<ILoadConfig>();
    private _postLoadConfigs: Array<ILoadConfig> = new Array<ILoadConfig>();
    private _reportEvery: number = 100;
    private _socket: WebSocket = null;
    private _socketHandlers: Map<number, ISocketHandler> = new Map<number, ISocketHandler>();
    private _socketParams: ISocketParams = null;
    private _startFrameBlockerCancelled: Subject<ILoadConfig>;

    public constructor() { }

    public addBookmark(request: IAddBookmarkRequest): void {
        if (logLevel >= LoggingServiceLevel.Info) { console.info(`addBookmark:request:${this.safePrintArguments(arguments)}`); }

        const addBookmark: IBookmark = {
            frameNumber: request.frameNumber,
            data: [{ Comment: request.bookmark.comment }],
            syncState: SyncStateEnum.adding,
            recordingId: request.validationRecording.recordingId,
        };

        ValidationWorkerShared.database_Recording_AddBookmark(this._databaseParams, addBookmark).subscribe(
            (addedBookmark) => {
                ValidationWorkerShared.socket_Recording_AddBookmark(this._socketParams, getNextSocketRequestId(), request.bookmark.comment, request.frameNumber, request.validationRecording).subscribe(
                    () => {
                        addBookmark.syncState = SyncStateEnum.ok;
                        ValidationWorkerShared.database_Recording_UpdateBookmark(this._databaseParams, addedBookmark).subscribe(
                            () => {
                                this.sendMessageLog('addBookmark:response', { id: request.id, type: ResponseTypesEnum.complete, bookmark: { id: addedBookmark.id, comment: addedBookmark.data[0].Comment, frameNumber: addedBookmark.frameNumber } } as IAddBookmarkResponse, LoggingServiceLevel.Debug);
                            },
                            (error) => {
                                this.sendMessageLog('addBookmark:response', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'addBookmark', 'updating bookmark in database') } as IAddBookmarkResponse, LoggingServiceLevel.Debug);
                            },
                        );
                    },
                    (error) => {
                        this.sendMessageLog('addBookmark:response', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'addBookmark', 'socket request') } as IAddBookmarkResponse, LoggingServiceLevel.Debug);
                    },
                );
            },
            error => {
                this.sendMessageLog('addBookmark:response', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'addBookmark', 'adding bookmark to database') } as IAddBookmarkResponse, LoggingServiceLevel.Debug);
            }
        );
    }

    public cancelLoad(request: ICancelLoadRequest): void {
        if (logLevel >= LoggingServiceLevel.Info) { console.info(`cancelLoad:request:${this.safePrintArguments(arguments)}`); }

        if (this._isLoadingBlocks === true) {
            this._cancelLoad.next(request);
        } else {
            this.sendMessageLog('cancelLoad:response:', { id: request.id, type: ResponseTypesEnum.complete } as ICancelLoadResponse, LoggingServiceLevel.Debug);
        }
    }

    public deleteBookmark(request: IDeleteBookmarkRequest): void {
        if (logLevel >= LoggingServiceLevel.Info) { console.info(`deleteBookmark:request:${this.safePrintArguments(arguments)}`); }

        const store = this._data.bookmarkFramesStoreConfig;

        ValidationWorkerShared.database_Recording_GetBookmarkById(this._databaseParams, request.bookmark.id).subscribe(
            dbBookmark => {
                dbBookmark.syncState = SyncStateEnum.deleting;
                ValidationWorkerShared.database_Recording_UpdateBookmark(this._databaseParams, dbBookmark).subscribe(
                    () => {
                        this.sendMessageLog('deleteBookmark:response', { id: request.id, type: ResponseTypesEnum.complete } as IDeleteBookmarkResponse, LoggingServiceLevel.Debug);

                        ValidationWorkerShared.socket_Recording_DeleteBookmark(this._socketParams, getNextSocketRequestId(), request.bookmark.comment, request.frameNumber, request.validationRecording).subscribe(
                            () => {
                                this._indexedDBService.deleteRecord(this._database, store.storeName, dbBookmark.id).subscribe(
                                    () => { },
                                    (error) => {
                                        this.sendMessageLog('deleteBookmark:response', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'deleteBookmark', 'deleting bookmark from database') } as IDeleteBookmarkResponse, LoggingServiceLevel.Debug);
                                    },
                                );
                            },
                            (error) => {
                                this.sendMessageLog('deleteBookmark:response', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'deleteBookmark', 'socked delete bookmark') } as IDeleteBookmarkResponse, LoggingServiceLevel.Debug);
                            },
                        );
                    },
                    error => {
                        this.sendMessageLog('deleteBookmark:response', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'deleteBookmark', 'updating bookmark in database') } as IDeleteBookmarkResponse, LoggingServiceLevel.Debug);
                    },
                );
            },
            error => {
                this.sendMessageLog('deleteBookmark:response', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'deleteBookmark', 'getting bookmark from database') } as IDeleteBookmarkResponse, LoggingServiceLevel.Debug);
            }
        );
    }

    public getBlockLoadStatus(request: IGetBlockLoadStatusRequest): void {
        if (logLevel >= LoggingServiceLevel.Info) { console.info(`getBlockLoadStatus:request:${this.safePrintArguments(arguments)}`); }

        ValidationWorkerShared.database_Recording_GetRecordingById(this._databaseParams, request.recordingId).subscribe(
            (recording: IRecordingModel) => {
                this.sendMessageLog('getBlockLoadStatus:response', {
                    id: request.id,
                    type: ResponseTypesEnum.complete,
                    isComplete: recording.preloadComplete === true && recording.postLoadCompleted === true,
                    preloadComplete: recording.preloadComplete,
                    postLoadCompleted: recording.postLoadCompleted,
                    preloadFrames: recording.preloadFrames,
                    postLoadFrames: recording.postLoadFrames,
                } as IGetBlockLoadStatusResponse, LoggingServiceLevel.Debug);
            },
            (error) => {
                this.sendMessageLog('getBlockLoadStatus:response', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'getBlockLoadStatus', 'getting block load status') } as IGetBlockLoadStatusResponse, LoggingServiceLevel.Debug);
            },
        );
    }

    public getAllFrames(request: IGetAllFramesRequest): void {
        if (logLevel >= LoggingServiceLevel.Info) { console.info(`getAllFrames:request:${this.safePrintArguments(arguments)}`); }

        let store: IFrameStore = null;
        switch (request.frameType) {
            case 'device':
                store = this._data.deviceFramesStoreConfig;
                break;
            case 'videosetting':
                store = this._data.videoSettingFramesStoreConfig;
                break;
            case 'line':
                store = this._data.lineFramesStoreConfig;
                break;
            case 'polygon':
                store = this._data.polygonFramesStoreConfig;
                break;
            case 'register':
                store = this._data.registerFramesStoreConfig;
                break;
            case 'timedata':
                store = this._data.timeDataFramesStoreConfig;
                break;
            case 'globaldata':
                store = this._data.globalDataFramesStoreConfig;
                break;
            case 'counts':
                store = this._data.countFramesStoreConfig;
                break;
            case 'bookmark':
                store = this._data.bookmarkFramesStoreConfig;
                break;
            case 'syncFrames':
                store = this._data.syncFramesStoreConfig;
                break;
        }

        this._indexedDBService.getRecordsByIndex(this._database, store.storeName, store.recordingIdIndex, request.recording.recordingId).subscribe(
            frames => {
                this.sendMessageLog('getAllFrames:response:', { id: request.id, type: ResponseTypesEnum.complete, frames } as IGetAllFramesResponse, LoggingServiceLevel.Debug);
            },
            error => {
                this.sendMessageLog('getAllFrames:response:', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'getAllFrames', 'getDevices') } as IGetAllFramesResponse, LoggingServiceLevel.Debug);
            },
        );
    }

    public getFrames(request: IGetFrameRequest): void {
        if (logLevel >= LoggingServiceLevel.Info) { console.info(`getFrames:request:${this.safePrintArguments(arguments)}`); }

        ValidationWorkerShared.database_Recording_GetRecordingById(this._databaseParams, request.recordingId).subscribe(
            (recording: IRecordingModel) => {
                const startFrameLowerBound: number = request.startFrame;
                const startFrameUpperBound: number = request.startFrame + request.frameCount;

                let haveFrame: boolean = recording.postLoadCompleted;
                if (haveFrame === false) {
                    haveFrame = true;
                    for (let i = startFrameLowerBound; i <= startFrameUpperBound; i++) {
                        const frameState = recording.postLoadFrames[i];
                        if (!isNullOrUndefined(frameState) && frameState === FrameTrackerStates.notDownloaded) {
                            haveFrame = false;
                            break;
                        }
                    }
                }

                if (haveFrame === true) {
                    this.getFramesFromDb(request.recordingId, request.startFrame, startFrameLowerBound, startFrameUpperBound, request.frameCount, this._data.videoFramesStoreConfig).subscribe(
                        videoFrames => {
                            this.getFramesFromDb(request.recordingId, request.startFrame, startFrameLowerBound, startFrameUpperBound, request.frameCount, this._data.targetFramesStoreConfig).subscribe(
                                targetFrames => {
                                    this.getFramesFromDb(request.recordingId, request.startFrame, startFrameLowerBound, startFrameUpperBound, request.frameCount, this._data.syncFramesStoreConfig).subscribe(
                                        syncFrames => {
                                            this.sendMessageLog('getFrames:response:', {
                                                id: request.id,
                                                type: ResponseTypesEnum.complete,
                                                video: videoFrames,
                                                targets: targetFrames,
                                                sync: syncFrames,
                                                autoSpeedProfile: this.getAutoSpeedProfile(targetFrames, request.startFrame, request.startFrame + request.frameCount, recording, request.autoSpeedSettings)
                                            } as IGetFrameResponse, LoggingServiceLevel.Debug);
                                        },
                                        error => {
                                            this.sendMessageLog('getFrames:response:', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'getFrame', 'getting sync frames from database') } as IGetFrameResponse, LoggingServiceLevel.Debug);
                                        }
                                    );
                                },
                                error => {
                                    this.sendMessageLog('getFrames:response:', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'getFrame', 'getting target frames from database') } as IGetFrameResponse, LoggingServiceLevel.Debug);
                                },
                            );

                        },
                        error => {
                            this.sendMessageLog('getFrames:response:', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'getFrame', 'getting video frames from database') } as IGetFrameResponse, LoggingServiceLevel.Debug);
                        },
                    );
                } else {
                    const config = this._postLoadConfigs.find(c => c.recording?.id === request.recordingId);

                    if (!isNullOrUndefined(config)){
                        config.moveTo.frameNumber = request.startFrame;
                        config.moveTo.endFrameNumber = request.startFrame + request.frameCount;
                        config.moveTo.request = true;
                        config.moveTo.returned = false;
                        config.moveTo.iRequest = request;
                    }
                    else{
                        this.sendMessageLog('getFrames:response:', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, null, 'getFrame', 'Post load config could not be found') } as IGetFrameResponse, LoggingServiceLevel.Debug);
                    }
                }
            },
            (error) => {
                this.sendMessageLog('getFrames:response:', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'getFrame', 'getting recording from database') } as IGetFrameResponse, LoggingServiceLevel.Debug);
            },
        );
    }

    public getKeyFrames(request: IGetKeyFramesRequest): void {
        if (logLevel >= LoggingServiceLevel.Info) { console.info(`getKeyFrames:request:${this.safePrintArguments(arguments)}`); }

        let checkAndSendSub: Subscription = null;
        let checkAndSendTimerSub: Subscription = null;

        if (!isNullOrUndefined(checkAndSendSub)) {
            checkAndSendSub.unsubscribe();
        }
        if (!isNullOrUndefined(checkAndSendTimerSub)) {
            checkAndSendTimerSub.unsubscribe();
        }

        const videoFramesStore = this._data.videoFramesStoreConfig;
        const targetFramesStore = this._data.targetFramesStoreConfig;
        const syncFramesStore = this._data.syncFramesStoreConfig;

        const keyFrameEvery: number = request.keyFrameEvery;
        const recordingId: number = request.recordingId;

        ValidationWorkerShared.database_Recording_GetRecordingById(this._databaseParams, recordingId).subscribe(
            recording => {
                const lastBlockIndex = this.getFrameBlockIndex(recording.frames, POST_LOAD_BLOCK_SIZE);

                const videoSentBlocks: number[] = [];
                const targetSentBlocks: number[] = [];
                const syncSentBlocks: number[] = [];

                const checkBlocksAndSend = () => {
                    const videoCountSubs: Observable<{ count: number, blockIndex: number }>[] = [];
                    const targetCountSubs: Observable<{ count: number, blockIndex: number }>[] = [];
                    const syncCountSubs: Observable<{ count: number, blockIndex: number }>[] = [];

                    for (let blockIndex = 0; blockIndex <= lastBlockIndex; blockIndex++) {
                        if (videoSentBlocks.indexOf(blockIndex) === -1) {
                            videoCountSubs.push(ValidationWorkerShared.database_Recording_GetVideoFramesBlockCountByRecordingId(this._databaseParams, recordingId, blockIndex).pipe(
                                map(count => {
                                    return { count, blockIndex };
                                })
                            ));
                        }
                        
                        if (targetSentBlocks.indexOf(blockIndex) === -1 && request.targetsRequired === true) {
                            targetCountSubs.push(ValidationWorkerShared.database_Recording_GetTargetFramesBlockCountByRecordingId(this._databaseParams, recordingId, blockIndex).pipe(
                                map(count => {
                                    return { count, blockIndex };
                                })
                            ));
                        }

                        if (syncSentBlocks.indexOf(blockIndex) === -1 && request.syncFramesRequired === true) {
                            syncCountSubs.push(ValidationWorkerShared.database_Recording_GetSyncFramesBlockCountByRecordingId(this._databaseParams, recordingId, blockIndex).pipe(
                                map(count => {
                                    return {count, blockIndex };
                                })
                            ));
                        }
                    }

                    let videoSub: Observable<boolean> = of(true);
                    if (videoCountSubs.length > 0) {
                        videoSub = zip(...videoCountSubs).pipe(
                            flatMap(blockCounts => {
                                return this.getKeyFramesForBlockAndSend('video', keyFrameEvery, videoSentBlocks, request.id, videoFramesStore, recordingId, blockCounts);
                            })
                        );
                    }

                    let targetSub: Observable<boolean> = of(true);
                    if (targetCountSubs.length > 0) {
                        targetSub = zip(...targetCountSubs).pipe(
                            flatMap(blockCounts => {
                                return this.getKeyFramesForBlockAndSend('targets', keyFrameEvery, targetSentBlocks, request.id, targetFramesStore, recordingId, blockCounts);
                            })
                        );
                    }

                    let syncSub: Observable<boolean> = of(true);
                    if (syncCountSubs.length > 0){
                        syncSub = zip(...syncCountSubs).pipe(
                            flatMap(blockCounts => {
                                return this.getKeyFramesForBlockAndSend('syncFrames', keyFrameEvery, syncSentBlocks, request.id, syncFramesStore, recordingId, blockCounts);
                            })
                        );
                    }


                    checkAndSendSub = zip(videoSub, targetSub, syncSub).subscribe(
                        results => {
                            if (videoSentBlocks.length < lastBlockIndex || (targetSentBlocks.length < lastBlockIndex && request.targetsRequired === true) || (syncSentBlocks.length < lastBlockIndex && request.syncFramesRequired === true)) {
                                checkAndSendTimerSub = timer(4000).subscribe(() => checkBlocksAndSend());
                            } else {
                                this.sendMessageLog('getKeyFrames:response:', { id: request.id, type: ResponseTypesEnum.complete } as IGetKeyFramesResponse, LoggingServiceLevel.Debug);                                
                            }
                        },
                        (error) => {
                            this.sendMessageLog('getKeyFrames:response:', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'getKeyFrames', 'getting frames from database') } as IGetKeyFramesResponse, LoggingServiceLevel.Debug);
                        },
                    );
                };

                checkBlocksAndSend();
            }
        );
    }

    public getValidationRecording(request: IGetValidationRecordingRequest): void {
        if (logLevel >= LoggingServiceLevel.Info) { console.info(`getValidationRecording:request:${this.safePrintArguments(arguments)}`); }

        ValidationWorkerShared.database_Recording_GetRecordingBySerialSessionId(this._databaseParams, request.validatableRecording.friendlySerial, request.validatableRecording.id, request.validatableRecording.startTime).subscribe(
            dbRecording => {
                let validationRecordingFunc: Observable<{ validation: IValidationRecordingModel, recording: IRecordingModel }> = null;

                if (isNullOrUndefined(dbRecording)) {
                    validationRecordingFunc = this.getNewValidationRecording(request).pipe(
                        flatMap(result => {
                            return this.loadValidationRecording(request, result.recording);
                        })
                    );
                } else if (dbRecording.frames === 0) {
                    validationRecordingFunc = ValidationWorkerShared.database_Recording_DeleteRecordingBySerialSessionId(this._databaseParams, request.validatableRecording.friendlySerial, request.validatableRecording.id, request.validatableRecording.startTime).pipe(
                        flatMap(() => {
                            return this.getNewValidationRecording(request).pipe(
                                flatMap(result => {
                                    return this.loadValidationRecording(request, result.recording);
                                })
                            );
                        }),
                    );
                } else {
                    validationRecordingFunc = this.loadValidationRecording(request, dbRecording);
                }

                validationRecordingFunc.subscribe(
                    result => {
                        this.sendMessageLog('getValidationRecording:response:', { id: request.id, type: ResponseTypesEnum.complete, validationRecording: result.validation } as IGetValidationRecordingResponse, LoggingServiceLevel.Debug);
                    },
                    error => {
                        this.sendMessageLog('getValidationRecording:response:', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'getValidationRecording', 'getting creating recording') } as IGetValidationRecordingResponse, LoggingServiceLevel.Debug);
                    },
                );
            },
            error => {
                this.sendMessageLog('getValidationRecording:response:', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'getValidationRecording', 'getting recording from database') } as IGetValidationRecordingResponse, LoggingServiceLevel.Debug);
            },
        );
    }

    public stop(request: IStopRequest): void {
        this._socket.close();
    }

    public initialize(request: IInitializeRequest): void {
        if (logLevel >= LoggingServiceLevel.Info) { console.info(`initialize:request:${this.safePrintArguments(arguments)}`); }

        this._data = request;
        this._indexedDBService = new IndexedDBServiceBase();
        this._socket = new WebSocket(this._data.socketUrl);

        this.initParams();

        const open = zip(
            ValidationWorkerShared.database_Open(this._databaseParams).pipe(
                tap(database => {
                    this._database = database;
                    this._databaseParams.database = database;
                })
            ),
            ValidationWorkerShared.socket_Open(this._socketParams, getNextSocketRequestId()).pipe(
                tap(socket => {
                    this._socket = socket;
                    this._socketParams.socket = socket;
                    this._socket.onmessage = (e: MessageEvent) => {
                        if (e.type === 'message') {
                            const socketResponse = JSON.parse(e.data);

                            if (socketResponse.packetType === 'ping') {
                                const pong = { packetType: 'pong' };
                                this._socket.send(JSON.stringify(pong));
                            }
                            else{
                                this._socketHandlers.forEach(h => {
                                    h.handler(socketResponse);
                                });
                            }
                        }
                    };

                    this._socket.onerror = (ev: Event) =>{
                        this._socketHandlers.forEach(h => {
                            h.errorHandler(ev);
                        });
                    };
                })
            ),
        );

        open.subscribe(
            () => { },
            error => {
                this.sendMessageLog('initialize:response:', { id: request.id, type: ResponseTypesEnum.error, errorMessage: ValidationWorkerShared.errorCheck(logLevel, error, 'initialize', 'Error opening database and web socket') } as IResponse, LoggingServiceLevel.Debug);
            },
        );
    }

    private addFramesDataToDatabase(storeConfig: IStoreConfig, block: IFramesBlock): Observable<boolean> {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`addFramesDataToDatabase:${this.safePrintArguments(arguments)}`); }

        let recordingId = null;

        if (!isNullOrUndefined(storeConfig.cachedFrames)) {
            const length = storeConfig.cachedFrames.length;
            if (length > 0) {
                for (let i = 0; i < length; i++) {
                    const frame = storeConfig.cachedFrames[i];
                    frame.syncState = SyncStateEnum.ok;
                    recordingId = frame.recordingId;
                }

                return this._indexedDBService.getRecordsByIndex<IFrameData>(this._database, storeConfig.frameStore.storeName, storeConfig.frameStore.recordingIdBlockIndex, [recordingId, block.blockIndex]).pipe(
                    flatMap(
                        blockFrames => {
                            const lastBlockFrame = ArrayUtility.max(blockFrames, 'frameNumber');

                            let addFrames: IFrameModel[] = [];

                            if (isNullOrUndefined(lastBlockFrame) || isNullOrUndefined(lastBlockFrame.frameNumber)) {
                                addFrames = storeConfig.cachedFrames;
                            } else {
                                for (let i = 0; i < length; i++) {
                                    const frame = storeConfig.cachedFrames[i];
                                    if (!isNullOrUndefined(frame) && frame.frameNumber > lastBlockFrame.frameNumber) {
                                        addFrames.push(frame);
                                    }
                                }
                            }

                            return this._indexedDBService.addRecordsBulk(this._database, storeConfig.frameStore.storeName, addFrames).pipe(
                                map(() => {
                                    storeConfig.cachedFrames = [];
                                    return true;
                                }),
                                catchError((error, caught) => {
                                    // console.error(`storeName:${storeConfig.frameStore.storeName}:recordingId:${recordingId}:block.blockIndex:${block.blockIndex}:error:${error}`);
                                    return of(true);
                                }),
                            );
                        }
                    ),
                );
            }
        }

        return of(true);
    }

    private buildPostLoadConfigConfig(): ILoadConfig {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`buildPostLoadConfigConfig:${this.safePrintArguments(arguments)}`); }

        const newConfig = {
            frameTypes: [ValidationFrameTypeEnum.video, ValidationFrameTypeEnum.targets, ValidationFrameTypeEnum.syncFrameInfo],
            name: 'postload',
            blockSize: POST_LOAD_BLOCK_SIZE,
            cacheFrameTracker: [],
            canceled: false,
            recording: null,
            moveTo: {
                frameNumber: null,
                request: false,
                returned: true,
            } as IMoveToFrameControl,
            stores: {
                video: {
                    cachedFrames: [],
                    frameStore: this._data.videoFramesStoreConfig,
                    canAddToCache: () => true,
                } as IStoreConfig,
                targets: {
                    cachedFrames: [],
                    frameStore: this._data.targetFramesStoreConfig,
                    canAddToCache: (data: any) => data.length > 0,
                },
                syncFrames: {
                    cachedFrames: [],
                    frameStore: this._data.syncFramesStoreConfig,
                    canAddToCache: () => true,
                }
            } as IStoresConfig,
            updateRecording: (isLastBlock: boolean, recording: IRecordingModel) => {
                recording.postLoadCompleted = isLastBlock;
            },
        };

        return newConfig;
    }

    private buildPreLoadConfigConfig(): ILoadConfig {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`buildPreLoadConfigConfig:${this.safePrintArguments(arguments)}`); }

        const newConfig = {
            frameTypes: [ValidationFrameTypeEnum.deviceInfo, ValidationFrameTypeEnum.videoCalibration, ValidationFrameTypeEnum.bookmarks, ValidationFrameTypeEnum.counts],
            name: 'preload',
            blockSize: PRE_LOAD_BLOCK_SIZE,
            cacheFrameTracker: [],
            canceled: false,
            recording: null,
            moveTo: {
                frameNumber: null,
                request: false,
                returned: true,
            } as IMoveToFrameControl,
            stores: {
                counts: {
                    cachedFrames: [],
                    frameStore: this._data.countFramesStoreConfig,
                    canAddToCache: () => true,
                },
                devices: {
                    cachedFrames: [],
                    frameStore: this._data.deviceFramesStoreConfig,
                    canAddToCache: () => true,
                },
                lines: {
                    cachedFrames: [],
                    frameStore: this._data.lineFramesStoreConfig,
                    canAddToCache: () => true,
                },
                polygons: {
                    cachedFrames: [],
                    frameStore: this._data.polygonFramesStoreConfig,
                    canAddToCache: () => true,
                },
                registers: {
                    cachedFrames: [],
                    frameStore: this._data.registerFramesStoreConfig,
                    canAddToCache: () => true,
                },
                globalData: {
                    cachedFrames: [],
                    frameStore: this._data.globalDataFramesStoreConfig,
                    canAddToCache: () => true,
                },
                timeData: {
                    cachedFrames: [],
                    frameStore: this._data.timeDataFramesStoreConfig,
                    canAddToCache: () => true,
                },
                videoSettings: {
                    cachedFrames: [],
                    frameStore: this._data.videoSettingFramesStoreConfig,
                    canAddToCache: () => true,
                },
                globalBookmarks: {
                    cachedFrames: [],
                    frameStore: this._data.bookmarkFramesStoreConfig,
                    canAddToCache: () => true,
                }
            } as IStoresConfig,
            updateRecording: (isLastBlock: boolean, recording: IRecordingModel) => {
                recording.preloadComplete = isLastBlock;
            },
        };

        return newConfig;
    }

    private getAutoSpeedProfile(targetFrames: Array<IFrameModel>, startFrameNumber: number, endFrameNumber: number, recording: IRecordingModel, autoSpeedSettings: IAutoSpeedSettings): Array<number> {
        const recordingFrames = recording.frames;

        if (endFrameNumber > recordingFrames) {
            endFrameNumber = recordingFrames;
        }

        const frameCount = endFrameNumber - startFrameNumber;

        const hasTargetProfile: boolean[] = ArrayUtility.fill(false, [], frameCount);
        const targetsLength = targetFrames.length;
        for (let targetI = 0; targetI < targetsLength; targetI++) {
            const target = targetFrames[targetI];
            if (!isNullOrUndefined(target) && target.frameNumber >= startFrameNumber && target.frameNumber <= endFrameNumber && target.data.length > 0) {
                hasTargetProfile[target.frameNumber] = true;
            }
        }

        const autoSpeedProfile: number[] = [];

        const outWindowFrameCount = Math.round(autoSpeedSettings.outWindow * recording.fps);
        const inWindowFrameCount = Math.round(autoSpeedSettings.inWindow * recording.fps);

        for (let frameNumber = startFrameNumber; frameNumber <= endFrameNumber; frameNumber++) {
            let windowStartFrameNumber = frameNumber - inWindowFrameCount;
            windowStartFrameNumber = windowStartFrameNumber < 0 ? 0 : windowStartFrameNumber;

            let windowEndFrameNumber = frameNumber + outWindowFrameCount;
            windowEndFrameNumber = windowEndFrameNumber > recordingFrames ? recordingFrames : windowEndFrameNumber;

            let targetInWindow = false;

            for (let windowFrameNumber = windowStartFrameNumber; windowFrameNumber <= windowEndFrameNumber; windowFrameNumber++) {
                if (hasTargetProfile[windowFrameNumber] === true) {
                    targetInWindow = true;
                    break;
                }
            }

            if (targetInWindow === true) {
                autoSpeedProfile[frameNumber] = autoSpeedSettings.targetsSpeed;
            } else {
                autoSpeedProfile[frameNumber] = autoSpeedSettings.noTargetsSpeed;
            }
        }

        return autoSpeedProfile;
    }

    private getFrameBlock(loadConfig: ILoadConfig, storeNames: Array<string>, block: IFramesBlock, validation: IValidationRecordingModel, recording: IRecordingModel, requiredFrameTypes: Array<ValidationFrameTypeEnum>, request: IRequest): Observable<IFramesBlock> {
        if (logLevel >= LoggingServiceLevel.Info) { console.info(`getFrameBlock:${loadConfig.name}:block:${this.safePrintArguments(block)}requiredFrameTypes:${this.safePrintArguments(requiredFrameTypes)}request:${this.safePrintArguments(request)}`); }

        return new Observable((observer: Observer<IFramesBlock>) => {
            let frameCount: number = 0;
            const socketRequestId = getNextSocketRequestId();
            const storeNamesLength = storeNames.length;

            let lastFrameNumber: number = null;
            let cancel: boolean = false;

            const onCancelSub = this._cancelLoad.subscribe(
                cancelLoadRequest => {
                    if (cancelLoadRequest.recordingId === recording.id) {
                        cancel = true;
                        this.sendMessageLog('cancelLoad:response:', { id: cancelLoadRequest.id, type: ResponseTypesEnum.complete } as ICancelLoadResponse, LoggingServiceLevel.Debug);
                    } else {
                        this.sendMessageLog('cancelLoad:response:', { id: cancelLoadRequest.id, type: ResponseTypesEnum.complete } as ICancelLoadResponse, LoggingServiceLevel.Debug);
                    }
                }
            );

            const removeSocketHandler = (handlerId: number) => {
                this._socketHandlers.delete(handlerId);
            };

            const socketHandler =
                (socketResponse: any) => {
                    if (cancel === true) {
                        removeSocketHandler(socketRequestId);

                        if (!isNullOrUndefined(onCancelSub)) {
                            onCancelSub.unsubscribe();
                        }
                        observer.complete();
                    } else {
                        if (socketResponse.packetType === 'validation_data_response_message' && socketResponse.id === socketRequestId) {
                            if (socketResponse.type === 'data') {
                                const data = socketResponse.data as IFrameData;
                                const frameNumber = data.frameNumber;

                                if (frameNumber >= block.startFrame && frameNumber <= block.endFrame) {
                                    if (isNullOrUndefined(lastFrameNumber)) {
                                        if (block.startFrame === 0) {
                                            loadConfig.completedFrameTracker[0] = FrameTrackerStates.notDownloaded;
                                        }
                                        lastFrameNumber = block.startFrame;
                                    }

                                    if (loadConfig.name === 'postload' && loadConfig.moveTo.request === true) {
                                        removeSocketHandler(socketRequestId);

                                        if (!isNullOrUndefined(onCancelSub)) {
                                            onCancelSub.unsubscribe();
                                        }

                                        loadConfig.moveTo.request = false;
                                        loadConfig.moveTo.returned = false;

                                        loadConfig.cacheFrameTracker = [];

                                        for (let i = 0; i < storeNamesLength; i++) {
                                            loadConfig.stores[storeNames[i]].cachedFrames = [];
                                        }

                                        for (let i = block.startFrame; i <= block.endFrame; i++) {
                                            // We are moving to another block so set all the completedFrameTracker to notDownloaded as they wont get added to db.
                                            loadConfig.completedFrameTracker[i] = FrameTrackerStates.notDownloaded;
                                        }

                                        observer.next(this.getNextFramesBlock(loadConfig.moveTo.frameNumber, loadConfig.blockSize, loadConfig.completedFrameTracker, recording));
                                    } else {
                                        for (let i = 0; i < storeNamesLength; i++) {
                                            const storeName: string = storeNames[i];
                                            const storeData = data[storeName];
                                            if (!isNullOrUndefined(storeData)) {
                                                const storeConfig: IStoreConfig = loadConfig.stores[storeName];

                                                if (storeConfig.canAddToCache(storeData) === true) {
                                                    const frameModel = {
                                                        recordingId: recording.id,
                                                        block: block.blockIndex,
                                                        frameNumber: data.frameNumber,
                                                        data: storeData,
                                                    } as IFrameModel;

                                                    storeConfig.cachedFrames.push(frameModel);
                                                }

                                                loadConfig.cacheFrameTracker[frameNumber] = true;
                                            }
                                        }
                                    }

                                    frameCount++;

                                    if ((frameCount / this._reportEvery) % 1 === 0) {
                                        this.sendFramesUpdate(request, frameNumber, loadConfig.name, 'getFrameBlock', recording.id);
                                    }

                                    if (frameNumber - lastFrameNumber > 1) {
                                        // There was a gap in the frames make sure we fill the completedFrameTracker with false.
                                        for (let i = lastFrameNumber + 1; i < frameNumber; i++) {
                                            loadConfig.completedFrameTracker[i] = FrameTrackerStates.downloadedWithNoData;
                                        }
                                    }
                                    loadConfig.completedFrameTracker[frameNumber] = FrameTrackerStates.downloadedWithData;

                                    lastFrameNumber = frameNumber;
                                }
                            } else if (socketResponse.type === 'final') {
                                loadConfig.cacheFrameTracker = [];
                                removeSocketHandler(socketRequestId);

                                // Fill in the completedFrameTracker with downloadedWithNoData that we missed
                                for (let i = block.startFrame; i <= block.endFrame; i++) {
                                    const frameTracker = loadConfig.completedFrameTracker[i];
                                    if (frameTracker === FrameTrackerStates.notDownloaded) {
                                        loadConfig.completedFrameTracker[i] = FrameTrackerStates.downloadedWithNoData;
                                    }
                                }

                                const addFramesDataToDatabase: any[] = [];
                                for (let i = 0; i < storeNamesLength; i++) {
                                    const frameType = storeNames[i];
                                    const storeConfig: IStoreConfig = loadConfig.stores[frameType];
                                    addFramesDataToDatabase.push(this.addFramesDataToDatabase(storeConfig, block));
                                }

                                zip(...isNullOrUndefined(addFramesDataToDatabase) || addFramesDataToDatabase.length === 0 ? [of(true)] : addFramesDataToDatabase).subscribe(
                                    () => {
                                        const nextBlock = this.getNextFramesBlock(block.endFrame + 1, loadConfig.blockSize, loadConfig.completedFrameTracker, recording);
                                        const isLastBlock = isNullOrUndefined(nextBlock.startFrame) && isNullOrUndefined(nextBlock.endFrame);

                                        loadConfig.updateRecording(isLastBlock, recording);

                                        const dbRecord = ValidationWorkerShared.convert_Database_RecordingModelDatesToValueOf(recording);
                                        this._indexedDBService.updateOrAddRecord(this._database, this._data.recordingStoreConfig.storeName, dbRecord).subscribe(
                                            () => {
                                                if (loadConfig.name === 'postload' && loadConfig.moveTo.returned === false && nextBlock.startFrame > loadConfig.moveTo.endFrameNumber) {
                                                    loadConfig.moveTo.returned = true;
                                                    this.getFrames(loadConfig.moveTo.iRequest);
                                                }

                                                if (!isLastBlock) {
                                                    this.sendFramesUpdate(request, nextBlock.startFrame, loadConfig.name, 'getFrameBlock', recording.id);
                                                    observer.next(nextBlock);
                                                } else {
                                                    this.sendFramesUpdate(request, recording.frames, loadConfig.name, 'getFrameBlock', recording.id);

                                                    if (!isNullOrUndefined(onCancelSub)) {
                                                        onCancelSub.unsubscribe();
                                                    }
                                                    observer.complete();
                                                }
                                            },
                                            error => {
                                                removeSocketHandler(socketRequestId);

                                                if (!isNullOrUndefined(onCancelSub)) {
                                                    onCancelSub.unsubscribe();
                                                }
                                                ValidationWorkerShared.errorCheck(logLevel, error, 'getFrameBlock', `${loadConfig.name} update recording in database`);
                                                observer.error(error);
                                            },
                                        );
                                    },
                                    error => {
                                        removeSocketHandler(socketRequestId);

                                        if (!isNullOrUndefined(onCancelSub)) {
                                            onCancelSub.unsubscribe();
                                        }
                                        ValidationWorkerShared.errorCheck(logLevel, error, 'getFrameBlock', `${loadConfig.name} add frames data to database`);
                                        observer.error(error);
                                    },
                                );
                            } else if (socketResponse.type === 'error') {
                                removeSocketHandler(socketRequestId);

                                if (!isNullOrUndefined(onCancelSub)) {
                                    onCancelSub.unsubscribe();
                                }
                                ValidationWorkerShared.errorCheck(logLevel, null, 'getFrameBlock', `${loadConfig.name} receiving socket message: ${JSON.stringify(socketResponse)}`);
                                observer.error(new Error(`${loadConfig.name} receiving socket message: ${JSON.stringify(socketResponse)}`));
                            }
                        }
                    }
                };

            const errorHandler =
                error => {
                    removeSocketHandler(socketRequestId);

                    if (!isNullOrUndefined(onCancelSub)) {
                        onCancelSub.unsubscribe();
                    }
                    ValidationWorkerShared.errorCheck(logLevel, new Error('websocket failed'), 'getFrameBlock', `${loadConfig.name} receiving socket message`);
                    observer.error(error);
                };

            this._socketHandlers.set(socketRequestId, {handler: socketHandler, errorHandler: errorHandler});

            this._socket.send(JSON.stringify(ValidationWorkerShared.socket_Message_DataRequest(this._socketParams, socketRequestId, block.startFrame, block.endFrame, requiredFrameTypes, validation)));
        });
    }

    private getFrameBlockIndex(frameNumber: number, blockSize: number): number {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`getFrameBlockIndex:${this.safePrintArguments(arguments)}`); }

        return Math.floor(frameNumber / blockSize);
    }

    private getFramesFromDb<TItem extends IFrameModel>(recordingId: number, startFrame: number, startFrameLowerBound: number, startFrameUpperBound: number, frameCount: number, frameStore: IFrameStore): Observable<Array<TItem>> {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`getFramesFromDb:${this.safePrintArguments(arguments)}`); }

        return new Observable((observer: Observer<Array<TItem>>) => {

            const transaction: IDBTransaction = this._database.transaction(frameStore.storeName, 'readonly');
            const store: IDBObjectStore = transaction.objectStore(frameStore.storeName);

            const index = store.index(frameStore.recordingIdFrameNumberIndex);
            const lowerBound: Array<IDBValidKey> = [recordingId, startFrameLowerBound];
            const upperBound: Array<IDBValidKey> = [recordingId, startFrameUpperBound];

            const request = index.openCursor(IDBKeyRange.bound(lowerBound, upperBound));

            IndexedDBServiceBase.getAllFromCursor<TItem>(request).subscribe(            
                (items: TItem[]) => {
                    const results: TItem[] = [];

                    let firstFrame: number = null;
                    let lastFrame: number = null;

                    const length = items.length;
                    for (let i = 0; i < length; i++) {
                        const frame = items[i];

                        if (isNullOrUndefined(firstFrame) && isNullOrUndefined(lastFrame) && frame.frameNumber > startFrame) {
                            const previous = items[i - 1];
                            const current = frame;
                            if (!isNullOrUndefined(previous)) {
                                firstFrame = Math.abs(previous.frameNumber - startFrame) < Math.abs(current.frameNumber - startFrame) ? previous.frameNumber : current.frameNumber;
                                lastFrame = firstFrame + frameCount;
                            } else {
                                firstFrame = current.frameNumber;
                                lastFrame = firstFrame + frameCount;
                            }
                        }

                        if (firstFrame !== null && lastFrame !== null) {
                            results[frame.frameNumber] = frame;
                            if (frame.frameNumber > lastFrame) {
                                break;
                            }
                        }
                    }

                    observer.next(results);
                }
            );

            // Error.
            request.onerror = () => {
                ValidationWorkerShared.errorCheck(logLevel, request.error, 'getFramesFromDb', 'getting frames from db');
                observer.error(request.error);
                observer.complete();
            };

            // IDBTransaction
            // Success.
            transaction.oncomplete = () => {
                observer.complete();
            };
            // Error.
            transaction.onerror = () => {
                ValidationWorkerShared.errorCheck(logLevel, request.error, 'getFramesFromDb', 'getting frames from db');
                observer.error(transaction.error);
                observer.complete();
            };            
        });
    }

    private getKeyFramesForBlockAndSend(dataType: string, keyFrameEvery: number, sentBlocks: Array<number>, requestId: number, store: IFrameStore, recordingId: number, blockCounts: Array<{ count: number, blockIndex: number }>): Observable<boolean> {
        let sendSubs: Observable<boolean> = of(true);
        const countsLength = blockCounts.length;

        for (let i = 0; i < countsLength; i++) {
            const count = blockCounts[i];
            if (count.count > 0) {
                sendSubs = sendSubs.pipe(
                    flatMap(() => {
                        return this._indexedDBService.getRecordsByIndex<IFrameModel>(this._database, store.storeName, store.recordingIdBlockIndex, [recordingId, count.blockIndex]).pipe(
                            map(
                                frameModels => {
                                    const data: IFrameModel[] = [];

                                    const length = frameModels.length;
                                    for (let fi = 0; fi < length; fi += keyFrameEvery) {
                                        const frame = frameModels[fi];
                                        if (!isNullOrUndefined(frame)) {
                                            data[frame.frameNumber] = frame;
                                        }
                                    }

                                    const message: IGetKeyFramesResponse = { id: requestId, type: ResponseTypesEnum.block };
                                    message[dataType] = data;
                                    this.sendMessageLog('getKeyFramesForBlockAndSend:response:', message, LoggingServiceLevel.Debug);

                                    sentBlocks.push(count.blockIndex);
                                    return true;
                                }
                            )
                        )
                    })
                );
            }
        }

        return sendSubs;
    }

    private getNewValidationRecording(request: IGetValidationRecordingRequest): Observable<{ validation: IValidationRecordingModel, recording: IRecordingModel }> {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`getNewValidationRecording:${this.safePrintArguments(arguments)}`); }

        if (request.validatableRecording.frames === 0) {
            return throwError(new Error('Recording has 0 frames'));
        }

        const addRecording = {
            sessionId: request.validatableRecording.id,
            startTime: request.validatableRecording.startTime,
            endTime: request.validatableRecording.endTime,
            frames: request.validatableRecording.frames,
            bytes: request.validatableRecording.bytes,
            timezoneOffsetMins: request.validatableRecording.timezoneOffsetMins,
            friendlySerial: request.validatableRecording.friendlySerial,
            created: new Date(),
            preloadFrames: ArrayUtility.fill(FrameTrackerStates.notDownloaded, [], request.validatableRecording.frames + 1),
            preloadComplete: false,
            postLoadFrames: ArrayUtility.fill(FrameTrackerStates.notDownloaded, [], request.validatableRecording.frames + 1),
            postLoadCompleted: false,
            fps: request.validatableRecording.frames / ((request.validatableRecording.endTime.valueOf() - request.validatableRecording.startTime.valueOf()) / 1000),
            syncState: SyncStateEnum.ok,
            nodes: request.validatableRecording.onNodes?.map(n => {
                return {
                    friendlySerial: n.friendlySerial,
                    sessionId: n.id,
                    startTime: n.startTime,
                    endTime: n.endTime,
                    timezoneOffsetMins: n.timezoneOffsetMins,
                    bytes: n.bytes,
                    frames: n.frames
                }}) ?? []
        } as IRecordingModel;

        const validation = {
            fps: addRecording.fps,
        } as IValidationRecordingModel;

        return ValidationWorkerShared.database_Recording_AddRecording(this._databaseParams, addRecording).pipe(
            map(recording => {
                validation.recordingId = recording.id;
                return { validation, recording };
            }),
        );
    }

    private getNextFramesBlock(startFrame: number, blockSize: number, frameTrackArray: Array<FrameTrackerStates>, recording: IRecordingModel): IFramesBlock {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`getNextFramesBlock:${this.safePrintArguments(arguments)}`); }

        let nextBlock = { startFrame: null, endFrame: null, blockIndex: 0 } as IFramesBlock;

        const totalFrames = recording.frames;

        if (startFrame >= totalFrames) {
            startFrame = 0;
        }

        for (let frameNumber = startFrame; frameNumber <= totalFrames; frameNumber++) {
            const trackedFrame = frameTrackArray[frameNumber];
            if (trackedFrame === FrameTrackerStates.notDownloaded) {
                if (isNullOrUndefined(nextBlock.startFrame)) {
                    nextBlock.startFrame = frameNumber;
                    nextBlock.endFrame = frameNumber;
                } else {
                    nextBlock.endFrame = frameNumber;
                }
            } else if (!isNullOrUndefined(nextBlock.startFrame)) {
                break;
            }
        }

        const foundBlock = (!isNullOrUndefined(nextBlock.startFrame) && !isNullOrUndefined(nextBlock.endFrame));

        if (foundBlock === true) {
            if ((nextBlock.endFrame - nextBlock.startFrame) > blockSize) {
                // The block is oversized just set it to max block size
                nextBlock.endFrame = nextBlock.startFrame + blockSize;
            }

            nextBlock.blockIndex = this.getFrameBlockIndex(nextBlock.startFrame, blockSize);
        } else if (ArrayUtility.someIsNullOrUndefined(frameTrackArray, totalFrames)) {
            // No block was found lets try again from frame 0
            nextBlock = this.getNextFramesBlock(0, blockSize, frameTrackArray, recording);
        }

        if (isNullOrUndefined(nextBlock.startFrame) || isNullOrUndefined(nextBlock.endFrame)) {
            nextBlock.startFrame = null;
            nextBlock.endFrame = null;
        }

        return nextBlock;
    }

    private getLoadFramesCancelIfRunning(config: ILoadConfig, validation: IValidationRecordingModel, recording: IRecordingModel, request: IRequest): Observable<boolean> {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`getLoadFrames:${this.safePrintArguments(arguments)}`); }

        let obs: Observable<boolean> = of(true);

        if (!isNullOrUndefined(config.recording) && config.recording.id !== recording.id) {
            this._startFrameBlockerCancelled = new Subject();
            config.canceled = true;

            this._startFrameBlockerCancelled.pipe(flatMap((val)=>{
                return of(val);
            })).subscribe();
            obs = obs.pipe(
                flatMap(() => this._startFrameBlockerCancelled),
                filter((loadConfig) => {
                    return loadConfig.name === config.name;
                }),
                flatMap(() => {
                    config.recording = null;
                    return this.getLoadFramesStart(config, validation, recording, request);
                }),
            );
        } else {
            obs = obs.pipe(
                flatMap(() => {
                    return this.getLoadFramesStart(config, validation, recording, request);
                }),
            );
        }

        return obs;
    }

    private getLoadFramesStart(config: ILoadConfig, validation: IValidationRecordingModel, recording: IRecordingModel, request: IRequest): Observable<boolean> {
        let obs: Observable<boolean> = of(true);

        if (isNullOrUndefined(config.recording)) {
            config.recording = recording;
            switch (config.name) {
                case 'preload':
                    config.completedFrameTracker = recording.preloadFrames;
                    break;
                case 'postload':
                    config.completedFrameTracker = recording.postLoadFrames;
                    break;
            }

            obs = obs.pipe(
                flatMap(() => {
                    return this.startFrameBlocker(
                        config,
                        0,
                        validation,
                        recording,
                        request,
                    ).pipe(map(() => {
                        return true;
                    }));
                }),
            );
        }

        return obs;
    }

    private getPostloadFrames(validation: IValidationRecordingModel, recording: IRecordingModel, request: IRequest): Observable<boolean> {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`getPostloadFrames:${this.safePrintArguments(arguments)}`); }

        // Find the post load config for this recording, or create a new one
        let config = this._postLoadConfigs.find(c => c.recording?.id === recording.id);

        if (isNullOrUndefined(config)){
            // Create a new config
            config = this.buildPostLoadConfigConfig();
            this._postLoadConfigs.push(config);
        }

        return this.getLoadFramesCancelIfRunning(config, validation, recording, request);
    }

    private getPreloadFrames(validation: IValidationRecordingModel, recording: IRecordingModel, request: IRequest): Observable<boolean> {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`getPreloadFrames:${this.safePrintArguments(arguments)}`); }

        // Find the pre load config for this recording, or create a new one
        let config = this._preLoadConfigs.find(c => c.recording?.id === recording.id);

        if (isNullOrUndefined(config)){
            // Create a new config
            config = this.buildPreLoadConfigConfig();
            this._preLoadConfigs.push(config);
        }

        return this.getLoadFramesCancelIfRunning(config, validation, recording, request);
    }

    private initParams(): void {
        const base = { logLevel, initializeData: this._data };
        this._databaseParams = { ...base, indexedDBService: this._indexedDBService, database: this._database };
        this._socketParams = { ...base, socket: this._socket, onSocketMessage: this._onSocketMessage };
    }

    private loadValidationRecording(request: IGetValidationRecordingRequest, recording: IRecordingModel): Observable<{ validation: IValidationRecordingModel, recording: IRecordingModel }> {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`loadValidationRecording:${this.safePrintArguments(arguments)}`); }

        const validation = {
            bytes: recording.bytes,
            recordingId: recording.id,
            id: recording.sessionId,
            startTime: recording.startTime,
            endTime: recording.endTime,
            frames: recording.frames,
            timezoneOffsetMins: recording.timezoneOffsetMins,
            fps: recording.fps,
            friendlySerial: recording.friendlySerial,
            dataValidation: recording.dataValidation,
            onNodes: recording.nodes?.map(n => {
                return {
                    friendlySerial: n.friendlySerial,
                    bytes: n.bytes,
                    endTime: n.endTime,
                    frames: n.frames,
                    id: n.sessionId,
                    startTime: n.startTime,
                    timezoneOffsetMins: n.timezoneOffsetMins,
                }}) ?? []
        } as IValidationRecordingModel;

        if (!isNullOrUndefined(recording.dataValidation) && recording.dataValidation.valid === false) {
            return of({ validation, recording });
        }

        let getPostLoadObs: Observable<{ validation: IValidationRecordingModel, recording: IRecordingModel }>;
        if (recording.postLoadCompleted === true) {
            // If we have completed PostLoad just return immediately.
            getPostLoadObs = of({ validation, recording });
        } else if (this.postload_FirstBlockHasDownloaded(recording) === true) {
            // If we have not completed postLoad but all ready completed first postLoad block just return immediately
            // and resume postload.
            getPostLoadObs = of({ validation, recording }).pipe(tap(() => {
                this.getPostloadFrames(validation, recording, request).subscribe();
            }));
        } else {
            // Otherwise we need to make you wait for first video block to complete so there is something to play.
            getPostLoadObs = this.getPostloadFrames(validation, recording, request).pipe(
                first(),
                map(
                    () => {
                        return { validation, recording };
                    }
                ),
            );
        }

        if (recording.preloadComplete === false) {
            return this.getPreloadFrames(validation, recording, request).pipe(
                last(),
                flatMap(
                    () => {
                        return this.preload_ValidateRecordingData(recording).pipe(
                            flatMap((validationResult) => {
                                recording.dataValidation = validationResult;
                                validation.dataValidation = validationResult;
                                return ValidationWorkerShared.database_Recording_UpdateRecording(this._databaseParams, recording).pipe(
                                    flatMap(() => {
                                        if (validationResult.valid === true) {
                                            if (recording.postLoadCompleted === false) {
                                                if (request.preLoadOnly === true){
                                                    return of({ validation, recording });
                                                }
                                                else{
                                                    return getPostLoadObs;
                                                }
                                            } else {
                                                return of({ validation, recording });
                                            }
                                        } else {
                                            return of({ validation, recording });
                                        }
                                    })
                                );
                            })
                        );
                    }
                ),
            );
        } else if (recording.postLoadCompleted === false) {
            return getPostLoadObs;
        } else {
            return of({ validation, recording });
        }
    }

    private preload_ValidateRecordingData(recording: IRecordingModel): Observable<IRecordingDataValidation> {
        return ValidationWorkerShared.database_Devices_GetRecordingById(this._databaseParams, recording.id).pipe(
            flatMap((devices) => {
                const devicesValid = this.preload_Validate_Device_Data(devices);
                if (devicesValid.valid === true) {
                    return ValidationWorkerShared.database_Lines_GetRecordingById(this._databaseParams, recording.id).pipe(
                        flatMap((lines) => {
                            const linesValid = this.preload_Validate_Line_Data(lines);
                            if (linesValid.valid === true) {
                                return ValidationWorkerShared.database_Polygons_GetRecordingById(this._databaseParams, recording.id).pipe(
                                    flatMap((polygons) => {
                                        const polygonsValid = this.preload_Validate_Polygons_Data(polygons);
                                        if (polygonsValid.valid === true) {
                                            return ValidationWorkerShared.database_Registers_GetRecordingById(this._databaseParams, recording.id).pipe(
                                                flatMap((registers) => {
                                                    const registersValid = this.preload_Validate_Registers_Data(registers);
                                                    if (registersValid.valid === true) {
                                                        return ValidationWorkerShared.database_VideoSettings_GetRecordingById(this._databaseParams, recording.id).pipe(
                                                            map((videoSettings) => {
                                                                return this.preload_Validate_VideoSettings_Data(videoSettings);
                                                            })
                                                        );

                                                    } else {
                                                        return of(registersValid);
                                                    }
                                                })
                                            );
                                        } else {
                                            return of(polygonsValid);
                                        }
                                    })
                                );
                            } else {
                                return of(linesValid);
                            }
                        })
                    );
                } else {
                    return of(devicesValid);
                }
            })
        )
    }

    private preload_Validate_VideoSettings_Data(frameData: IFrameModel[]): IRecordingDataValidation {
        const length = frameData.length;
        for (let afdI = 0; afdI < length; afdI++) {
            const frameDataA = frameData[afdI];
            for (let bfdI = 0; bfdI < length; bfdI++) {
                const frameDataB = frameData[bfdI];
                const result = AnyUtility.equalInfo(frameDataA.data, frameDataB.data);
                if (result.equal === false) {
                    return { blockName: 'Video Settings', valid: false, propertyName: result.propertyName }
                }
            }
        }

        return { blockName: 'Video Settings', valid: true };
    }

    private preload_Validate_Registers_Data(frameData: IFrameModel[]): IRecordingDataValidation {
        return { blockName: 'Registers', ...this.preload_Validate_Frame_Data(frameData, (itemA: any, itemB: any) => itemA.RegisterIndex === itemB.RegisterIndex && itemA.RegisterType === itemB.RegisterType) };
    }

    private preload_Validate_Polygons_Data(frameData: IFrameModel[]): IRecordingDataValidation {
        return { blockName: 'Polygons', ...this.preload_Validate_Frame_Data(frameData, (itemA: any, itemB: any) => itemA.ID === itemB.ID) };
    }

    private preload_Validate_Line_Data(frameData: IFrameModel[]): IRecordingDataValidation {
        return { blockName: 'Lines', ...this.preload_Validate_Frame_Data(frameData, (itemA: any, itemB: any) => itemA.ID === itemB.ID) };
    }

    private preload_Validate_Device_Data(frameData: IFrameModel[]): IRecordingDataValidation {
        const includes = [
            'X',
            'Y',
        ];

        return { blockName: 'Devices', ...this.preload_Validate_Frame_Data(frameData, (itemA: any, itemB: any) => itemA.SerialNumber === itemB.SerialNumber, includes, PropertyModeEnum.include) };
    }

    private preload_Validate_Frame_Data(frameData: IFrameModel[], itemMatcher: (itemA: any, itemB: any) => boolean, propertyNames?: string[], propertyMode?: PropertyModeEnum): IRecordingDataValidation {
        const length = frameData.length;
        for (let afdI = 0; afdI < length; afdI++) {
            const frameDataA = frameData[afdI];
            const frameDataAL = frameDataA.data.length;
            for (let adI = 0; adI < frameDataAL; adI++) {
                const aItem = frameDataA.data[adI];
                for (let bfdI = 0; bfdI < length; bfdI++) {
                    const frameDataB = frameData[bfdI];
                    const frameDataBL = frameDataB.data.length;
                    for (let bdI = 0; bdI < frameDataBL; bdI++) {
                        const bItem = frameDataB.data[bdI];
                        if (itemMatcher(aItem, bItem) === true) {
                            const result = AnyUtility.equalInfo(aItem, bItem, propertyNames, propertyMode);
                            if (result.equal === false) {
                                return { valid: false, propertyName: result.propertyName }
                            }
                        }
                    }
                }
            }
        }

        return { valid: true };
    }

    private postload_FirstBlockHasDownloaded(recording: IRecordingModel): boolean {
        const length = recording.frames > POST_LOAD_BLOCK_SIZE ? POST_LOAD_BLOCK_SIZE : recording.frames;
        let firstBlockHasDownloaded = true;
        for (let i = 0; i < length; i++) {
            if (recording.postLoadFrames[i] === FrameTrackerStates.notDownloaded) {
                firstBlockHasDownloaded = false;
            }
        }
        return firstBlockHasDownloaded;
    }

    private sendFramesUpdate(request: IRequest, atFrame: number, blockName: string, fromAction: string, recordingId?: number): void {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`sendFramesUpdate:${this.safePrintArguments(arguments)}`); }

        const message = {
            id: request.id,
            type: ResponseTypesEnum.update,
            atFrame,
            recordingId,
            updating: blockName,
        } as IProgressUpdateResponse;

        this.sendMessageLog('sendFramesUpdate:response:', message, LoggingServiceLevel.Debug);
    }

    private sendMessageLog(prefix: string, message: any, logAtLevel: LoggingServiceLevel): void {
        if (logLevel >= logAtLevel) {
            console.info(`${prefix}:${this.safePrintArguments(message)}`);
        }
        sendMessage(message);
    }

    private startFrameBlocker(loadConfig: ILoadConfig, startFrame: number, validation: IValidationRecordingModel, recording: IRecordingModel, request: IRequest): Observable<IFramesBlock> {
        if (logLevel >= LoggingServiceLevel.Debug) { console.info(`startFrameBlocker:${this.safePrintArguments(arguments)}`); }

        return new Observable((observer: Observer<IFramesBlock>) => {
            const storeNames = Object.keys(loadConfig.stores);
            let atBlock = this.getNextFramesBlock(startFrame, loadConfig.blockSize, loadConfig.completedFrameTracker, recording);

            const getBlock = () => {
                this._isLoadingBlocks = true;
                this.getFrameBlock(
                    loadConfig,
                    storeNames,
                    atBlock,
                    validation,
                    recording,
                    loadConfig.frameTypes,
                    request,
                ).subscribe(
                    nextBlock => {
                        observer.next(atBlock);
                        atBlock = nextBlock;
                        if (loadConfig.canceled === false) {
                            getBlock();
                        } else {
                            this._isLoadingBlocks = false;
                            observer.next(atBlock);
                            this._startFrameBlockerCancelled.next(loadConfig);
                            observer.complete();
                        }
                    },
                    error => {
                        this._isLoadingBlocks = false;
                        ValidationWorkerShared.errorCheck(logLevel, error, 'startFrameBlocker', 'getting frame block');
                        observer.error(error);
                    },
                    () => {
                        this._isLoadingBlocks = false;
                        observer.next(atBlock);
                        observer.complete();

                        loadConfig.recording = null;
                    },
                );
            };

            getBlock();
        });
    }

    private safePrintArguments(args: any): string{
        return JSON.stringify(args, getCircularReplacer());
    }
}

const manager = new WebWorkerServiceManager();
manager.service = new SyncRecordingFramesWorker();
self.onmessage = (m) => manager.onmessage(m);
