<div class="main-content">
    <div class="gmaps-container mat-elevation-z4 spacing-bottom" *ngIf="googleMapsPresent() === true">
        <em-device-map [devices]="devices"></em-device-map>
    </div>
    <em-device-list (pageOptionsChanged)="onPageOptionsChanged($event)"
        [isLoadingData]="getDevicesProcess.isRunning"
        [dataRefreshedTrigger]="refreshingDevicesProcess.isRunning"
        [devices]="devices"
        [pageOptions]="pageOptions">
    </em-device-list>
</div>