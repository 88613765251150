import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class DeviceHealthCountsModel extends BaseModel implements IRestModel {

    public connects: number = null;
    public errors: number = null;
    public isConnected: boolean = null;
    public lastActivity: Date = null;
    public readonly isIRestModel = true;
    public schedules: number = null;
    public serial: string = null;
    public tripTime: number = null;

    public constructor() {
        super();
    }

    loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-DeviceHealthCountsModel');

        this.lastActivity = DateTimeNonMomentUtility.fromRestApiDateTime(this.lastActivity);
    }
    toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
