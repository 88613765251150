import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IVideoSettingsModel extends IRestModel {
    frameRate: number;
    cropXPos1: number;
    cropYPos1: number;
    cropXPos2: number;
    cropYPos2: number;
}

export class VideoSettingsModel extends BaseModel implements IVideoSettingsModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public frameRate: number = null;
    public cropXPos1: number = null;
    public cropYPos1: number = null;
    public cropXPos2: number = null;
    public cropYPos2: number = null;

    public constructor() {
        super();

        this.registerProperty('frameRate');
        this.registerProperty('cropXPos1');
        this.registerProperty('cropYPos1');
        this.registerProperty('cropXPos2');
        this.registerProperty('cropYPos2');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-VideoSettingsModel');

        this.setPropertyOriginalValue('frameRate', this.frameRate);
        this.setPropertyOriginalValue('cropXPos1', this.cropXPos1);
        this.setPropertyOriginalValue('cropYPos1', this.cropYPos1);
        this.setPropertyOriginalValue('cropXPos2', this.cropXPos2);
        this.setPropertyOriginalValue('cropYPos2', this.cropYPos2);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
