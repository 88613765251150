import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export class LoginModel extends BaseModel implements IRestModel {
    public password: string = null;
    public readonly isIRestModel = true;
    public userName: string = null;

    public constructor(private readonly _username?: string, private readonly _password?: string) {
        super();
        this.userName = _username;
        this.password =_password;
    }

    public isEmpty(): boolean{
        if (isNullOrUndefined(this.userName) || isNullOrUndefined(this.password)){
            return true;
        }

        if (this.userName === '' || this.password === ''){
            return true;
        }

        return false;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'SHR-LoginModel');

        if (!isNullOrUndefined(restModel.UserName)) {
            this.userName = restModel.UserName;
        }

        if (!isNullOrUndefined(restModel.Username)) {
            this.userName = restModel.Username;
        }
    }

    public toRestApiModel() {
        return {
            UserName: this.userName,
            Password: this.password,
        };
    }
}
