import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface ICountLogConfigModel extends IRestModel {
    countLogPeriod: number;
}

export class CountLogConfigModel extends BaseModel implements ICountLogConfigModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public countLogPeriod: number = null;

    public constructor() {
        super();

        this.registerProperty('countLogPeriod');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-CountLogConfigModel');

        this.setPropertyOriginalValue('countLogPeriod', this.countLogPeriod);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
