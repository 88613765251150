import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { Directive, DoCheck, ElementRef, Inject, Input, NgZone, OnChanges, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgControl, NgForm, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UnitsOfMeasurementService } from '@rift/service/unitsofmeasurement/UnitsOfMeasurement.Service';
import { UnitOfMeasurementEnum, UnitOfMeasurementEnumHelpers } from '@shared/enum/UnitOfMeasurement.Enum';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject, Subscription } from 'rxjs';
import { ErrorStateMatcher, mixinErrorState, CanUpdateErrorState } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

let nextUniqueId = 0;

export class UnitsOfMeasurementInputValidators {

    public static max(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!isNullOrUndefined(control.value)) {
                const htmlElement = ((control as any).nativeElement as HTMLElement);
                if (!isNullOrUndefined(htmlElement) && !isNullOrUndefined((htmlElement as any).max)) {
                    const max = parseFloat((htmlElement as any).max);
                    if (!isNaN(max)) {
                        if (parseFloat(control.value) > max) {
                            return { unitsOfMeasurementMax: { max } };
                        }
                    }
                }
            }
            return null;
        };
    }

    public static min(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!isNullOrUndefined(control.value)) {
                const htmlElement = ((control as any).nativeElement as HTMLElement);
                if (!isNullOrUndefined(htmlElement) && !isNullOrUndefined((htmlElement as any).min)) {
                    const min = parseFloat((htmlElement as any).min);
                    if (!isNaN(min)) {
                        if (parseFloat(control.value) < min) {
                            return { unitsOfMeasurementMin: { min } };
                        }
                    }
                }
            }
            return null;
        };
    }
}

class MatInputBase {
    constructor(public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl) { }
}

export const _MatInputMixinBase = mixinErrorState(MatInputBase);

// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
@Directive({
    selector: 'input[riftUnitsOfMeasurementInput]',
    exportAs: 'riftUnitsOfMeasurementInput',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'mat-input-element mat-form-field-autofill-control', '[class.mat-input-server]': '_isServer',
        '[attr.id]': 'id',
        '[attr.placeholder]': 'placeholder',
        '[disabled]': 'disabled',
        '[required]': 'required',
        '[attr.readonly]': 'readonly && !_isNativeSelect || null',
        '[attr.aria-describedby]': '_ariaDescribedby || null',
        '[attr.aria-invalid]': 'errorState',
        '[attr.aria-required]': 'required.toString()',
        '[min]': 'minConverted',
        '[max]': 'maxConverted',
        '(blur)': '_focusChanged(false)',
        '(focus)': '_focusChanged(true)',
        '(input)': '_onInput()',
    },
    providers: [
        { provide: MatFormFieldControl, useExisting: UnitsOfMeasurementInputDirective },
    ],
})
export class UnitsOfMeasurementInputDirective extends _MatInputMixinBase implements MatFormFieldControl<number>, OnChanges, OnDestroy, OnInit, DoCheck, CanUpdateErrorState {

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    @Input()
    public get disabled(): boolean {
        if (this.ngControl && this.ngControl.disabled !== null) {
            return this.ngControl.disabled;
        }
        return this._disabled;
    }
    public set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);

        // Browsers may not fire the blur event if the input is disabled too quickly.
        // Reset from here to ensure that the element doesn't become stuck.
        if (this.focused) {
            this.focused = false;
            this.stateChanges.next();
        }
    }

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    @Input()
    public get id(): string { return this._id; }
    public set id(value: string) { this._id = value || this._uid; }

    @Input()
    public get metricMin(): number {
        return this._metricMin;
    }
    public set metricMin(value: number) {
        if (this._metricMin !== value) {
            this._metricMin = coerceNumberProperty(value);
            this.convert();
            this.stateChanges.next();
        }
    }

    @Input()
    public get metricMax(): number {
        return this._metricMax;
    }
    public set metricMax(value: number) {
        if (this._metricMax !== value) {
            this._metricMax = coerceNumberProperty(value);
            this.convert();
            this.stateChanges.next();
        }
    }

    @Input()
    public get metricUnit(): UnitOfMeasurementEnum {
        return this._metricUnit;
    }
    public set metricUnit(value: UnitOfMeasurementEnum) {
        if (this._metricUnit !== value) {
            this._metricUnit = value;
            this.convert();
            this.stateChanges.next();
        }
    }

    @Input()
    public get imperialUnit(): UnitOfMeasurementEnum {
        return this._imperialUnit;
    }
    public set imperialUnit(value: UnitOfMeasurementEnum) {
        if (this._imperialUnit !== value) {
            this._imperialUnit = value;
            this.convert();
            this.stateChanges.next();
        }
    }

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    @Input()
    get required(): boolean { return this._required; }
    set required(value: boolean) { this._required = coerceBooleanProperty(value); }

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    @Input()
    get value(): number { return this._metricValue; }
    set value(value: number) {
        if (value !== this.value) {
            this._inputValueAccessor.value = value;
            this.convertValue();
            this.stateChanges.next();
        }
    }

    /** Whether the element is readonly. */
    @Input()
    get readonly(): boolean { return this._readonly; }
    set readonly(value: boolean) { this._readonly = coerceBooleanProperty(value); }

    public get suffix(): string {
        return this.units === UnitsOfMeasurementEnum.metric ?
            this.unitShortName === true ? UnitOfMeasurementEnumHelpers.toShortName(this.metricUnit) : UnitOfMeasurementEnumHelpers.toLongName(this.metricUnit)
            :
            this.unitShortName === true ? UnitOfMeasurementEnumHelpers.toShortName(this.imperialUnit) : UnitOfMeasurementEnumHelpers.toLongName(this.imperialUnit);
    }

    public get metricValue(): number {
        return this._metricValue;
    }

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    get empty(): boolean {
        return !this._elementRef.nativeElement.value && !this._isBadInput() && !this.autofilled;
    }

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    public errorState: boolean = false;

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    public focused: boolean = false;

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    readonly stateChanges: Subject<void> = new Subject<void>();

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    public controlType: string = 'rift-unitsofmeasurement-input';

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    public autofilled = false;

    public units: UnitsOfMeasurementEnum = null;
    public minConverted: number;
    public maxConverted: number;

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    @Input()
    public placeholder: string;

    @Input()
    public unitShortName: boolean = true;

    @Input()
    public metricFractionDigits: number = 0;

    @Input()
    public imperialFractionDigits: number = 2;

    /** An object used to control when error messages are shown. */
    @Input()
    public errorStateMatcher: ErrorStateMatcher;

    protected _uid = `mat-input-${nextUniqueId++}`;
    protected _previousNativeValue: any;
    protected _disabled = false;
    protected _id: string;
    protected _required = false;

    /** The aria-describedby attribute on the input for improved a11y. */
    private _ariaDescribedby: string;

    /** Whether the component is being rendered on the server. */
    private _isServer = false;

    /** Whether the component is a native html select. */
    private _isNativeSelect = false;

    private _inputValueAccessor: { value: any };

    private _metricValue: number = null;
    private _fromUnits: UnitsOfMeasurementEnum = null;
    private _unitsChangeSubscription: Subscription = null;
    private _readonly = false;
    private _metricMin: number = null;
    private _metricMax: number = null;
    private _metricUnit: UnitOfMeasurementEnum;
    private _imperialUnit: UnitOfMeasurementEnum;

    constructor(
        private readonly _unitsOfMeasurementService: UnitsOfMeasurementService,
        protected  _elementRef: ElementRef<HTMLInputElement>,
        protected _platform: Platform,
        /** @docs-private */
        @Optional() @Self() public ngControl: NgControl,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() @Self() @Inject(MAT_INPUT_VALUE_ACCESSOR) inputValueAccessor: any,
        private _autofillMonitor: AutofillMonitor,
        ngZone: NgZone) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

        this.units = this._unitsOfMeasurementService.units;
        this._fromUnits = UnitsOfMeasurementEnum.metric;

        const element = this._elementRef.nativeElement;

        (this._elementRef.nativeElement as HTMLInputElement).type = 'number';

        // If no input value accessor was explicitly specified, use the element as the input value
        // accessor.
        this._inputValueAccessor = inputValueAccessor || element;

        this._previousNativeValue = this.value;

        // Force setter to be called in case id was not specified.
        this.id = this.id;

        // On some versions of iOS the caret gets stuck in the wrong place when holding down the delete
        // key. In order to get around this we need to "jiggle" the caret loose. Since this bug only
        // exists on iOS, we only bother to install the listener on iOS.
        if (_platform.IOS) {
            ngZone.runOutsideAngular(() => {
                _elementRef.nativeElement.addEventListener('keyup', (event: Event) => {
                    const el = event.target as HTMLInputElement;
                    if (!el.value && !el.selectionStart && !el.selectionEnd) {
                        // Note: Just setting `0, 0` doesn't fix the issue. Setting
                        // `1, 1` fixes it for the first time that you type text and
                        // then hold delete. Toggling to `1, 1` and then back to
                        // `0, 0` seems to completely fix it.
                        el.setSelectionRange(1, 1);
                        el.setSelectionRange(0, 0);
                    }
                });
            });
        }

        this._isServer = !this._platform.isBrowser;
        this._isNativeSelect = element.nodeName.toLowerCase() === 'select';

        this._unitsChangeSubscription = this._unitsOfMeasurementService.unitsChange.subscribe(units => {
            this._fromUnits = this.units;
            this.units = units;
            this.convert();
            this.stateChanges.next();
        });

        this.convert();
    }

    convert(): void {
        this.convertMaxTo();
        this.convertMinTo();
        this.convertValue();
        this.stateChanges.next();
    }

    ngOnInit() {
        if (this._platform.isBrowser) {
            this._autofillMonitor.monitor(this._elementRef.nativeElement).subscribe(event => {
                this.autofilled = event.isAutofilled;
                this.stateChanges.next();
            });
        }
    }

    ngOnChanges() {
        this.convertValue();
        this.stateChanges.next();
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this._unitsChangeSubscription.unsubscribe();

        if (this._platform.isBrowser) {
            this._autofillMonitor.stopMonitoring(this._elementRef.nativeElement);
        }
    }

    ngDoCheck() {
        if (this.ngControl) {
            // We need to re-evaluate this on every change detection cycle, because there are some
            // error triggers that we can't subscribe to (e.g. parent form submissions). This means
            // that whatever logic is in here has to be super lean or we risk destroying the performance.
            this.updateErrorState();

            if (isNullOrUndefined(this.value) && !isNullOrUndefined(this.ngControl.value)) {
                this._metricValue = parseFloat(this.ngControl.value);
                this.convert();
            }
        }

        // We need to dirty-check the native element's value, because there are some cases where
        // we won't be notified when it changes (e.g. the consumer isn't using forms or they're
        // updating the value using `emitEvent: false`).
        this._dirtyCheckNativeValue();
    }

    /** Focuses the input. */
    focus(): void { this._elementRef.nativeElement.focus(); }

    /** Callback for the cases where the focused state of the input changes. */
    _focusChanged(isFocused: boolean) {
        if (isFocused !== this.focused && !this.readonly) {
            this.focused = isFocused;
            this.stateChanges.next();
        }
    }

    _onInput() {
        this.convertValue();
        // This is a noop function and is used to let Angular know whenever the value changes.
        // Angular will run a new change detection each time the `input` event has been dispatched.
        // It's necessary that Angular recognizes the value change, because when floatingLabel
        // is set to false and Angular forms aren't used, the placeholder won't recognize the
        // value changes and will not disappear.
        // Listening to the input event wouldn't be necessary when the input is using the
        // FormsModule or ReactiveFormsModule, because Angular forms also listens to input events.
    }

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    setDescribedByIds(ids: string[]) { this._ariaDescribedby = ids.join(' '); }

    /**
     * Implemented as part of MatFormFieldControl.
     *
     * @docs-private
     */
    onContainerClick() {
        // Do not re-focus the input element if the element is already focused. Otherwise it can happen
        // that someone clicks on a time input and the cursor resets to the "hours" field while the
        // "minutes" field was actually clicked. See: https://github.com/angular/material2/issues/12849
        if (!this.focused) {
            this.focus();
        }
    }

    /** Does some manual dirty checking on the native input `value` property. */
    protected _dirtyCheckNativeValue() {
        const newValue = this._elementRef.nativeElement.value;

        if (this._previousNativeValue !== newValue) {
            this._previousNativeValue = newValue;
            this.stateChanges.next();
        }
    }

    /** Checks whether the input is invalid based on the native validation. */
    protected _isBadInput() {
        // The `validity` property won't be present on platform-server.
        const validity = (this._elementRef.nativeElement as HTMLInputElement).validity;
        return validity && validity.badInput;
    }

    private convertMinTo(): void {
        if (!isNullOrUndefined(this.metricMin) && !isNullOrUndefined(this.metricUnit) && !isNullOrUndefined(this.imperialUnit)) {
            if (this._fromUnits !== this.units && this.units === UnitsOfMeasurementEnum.imperial) {
                this.minConverted = this._unitsOfMeasurementService.convertToImperial(this.metricMin, this.metricUnit, this.imperialUnit, this.imperialFractionDigits);
            } else {
                this.minConverted = this.metricMin;
            }
        }
    }

    private convertMaxTo(): void {
        if (!isNullOrUndefined(this.metricMax) && !isNullOrUndefined(this.metricUnit) && !isNullOrUndefined(this.imperialUnit)) {
            if (this._fromUnits !== this.units && this.units === UnitsOfMeasurementEnum.imperial) {
                this.maxConverted = this._unitsOfMeasurementService.convertToImperial(this.metricMax, this.metricUnit, this.imperialUnit, this.imperialFractionDigits);
            } else {
                this.maxConverted = this.metricMax;
            }
        }
    }

    private convertValue(): void {
        if (!isNullOrUndefined(this.value) && !isNullOrUndefined(this.metricUnit) && !isNullOrUndefined(this.imperialUnit)) {
            if (this._fromUnits === this.units) {
                if (this.units === UnitsOfMeasurementEnum.metric) {
                    this._metricValue = this._inputValueAccessor.value;
                } else {
                    this._metricValue = this._unitsOfMeasurementService.convertToMetric(this._inputValueAccessor.value, this.metricUnit, this.imperialUnit, this.metricFractionDigits);
                }
            } else {
                if (this._fromUnits === UnitsOfMeasurementEnum.imperial && this.units === UnitsOfMeasurementEnum.metric) {
                    this._inputValueAccessor.value = this._unitsOfMeasurementService.convertToMetric(this._inputValueAccessor.value, this.metricUnit, this.imperialUnit, this.metricFractionDigits);
                } else if (this._fromUnits === UnitsOfMeasurementEnum.metric && this.units === UnitsOfMeasurementEnum.imperial) {
                    this._inputValueAccessor.value = this._unitsOfMeasurementService.convertToImperial(this._inputValueAccessor.value, this.metricUnit, this.imperialUnit, this.imperialFractionDigits);
                } else if (this._fromUnits === null && this.units === UnitsOfMeasurementEnum.imperial) {
                    this._inputValueAccessor.value = this._unitsOfMeasurementService.convertToImperial(this._inputValueAccessor.value, this.metricUnit, this.imperialUnit, this.imperialFractionDigits);
                }
                this._fromUnits = this.units;
            }
        }
    }
}
