import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserIsAuthenticated } from '@em/service/user/UserIsLevel';
import { ConnectComponent } from '@rift/components/connect/Connect.Component';
import { CountsComponent } from '@rift/components/counts/Counts.Component';
import { DiagnosticsComponent } from '@rift/components/diagnostics/Diagnostics.Component';
import { DisconnectComponent } from '@rift/components/disconnect/Disconnect.Component';
import { HomeComponent } from '@rift/components/home/Home.Component';
import { RecordingsComponent } from '@rift/components/recordings/Recordings.Component';
import { RiftSettingsRoutes } from '@rift/components/settings/Settings.Routes';
import { SummaryComponent } from '@rift/components/summary/Summary.Component';
import { ValidationComponent } from '@rift/components/validation/Validation.Component';
import { LoginComponent } from '@shared/component/login/Login.Component';
import { IRouteData } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { BrowserIncompatibleComponent } from '@shared/component/browserincompatible/BrowserIncompatible.component';
import { RIFTUserIsRIFTRole } from './RiftRouteGuard';
import { ReconnectComponent } from './components/reconnect/Reconnect.Component';

export const BaseRiftRoutes: Routes = [
    {
        path: 'summary',
        component: SummaryComponent,
        data: { breadcrumb: { name: 'rift-summary', text: 'Summary' } } as IRouteData
    },
    ...RiftSettingsRoutes,
    {
        path: 'diagnostics',
        component: DiagnosticsComponent,
        data: { breadcrumb: { name: 'rift-diagnostics', text: 'Diagnostics' } } as IRouteData
    },
    {
        path: 'counts',
        component: CountsComponent,
        data: { breadcrumb: { name: 'rift-counts', text: 'Counts Graph' } } as IRouteData
    },
    {
        path: 'recordings',
        component: RecordingsComponent,
        data: { breadcrumb: { name: 'rift-recordings', text: 'Recordings' } } as IRouteData
    },
    {
        path: 'validation',
        component: ValidationComponent,
        data: { breadcrumb: { name: 'rift-validation', text: 'Validation' } } as IRouteData
    },
    {
        path: 'validation/:sessionId/:startTime',
        component: ValidationComponent,
        data: { breadcrumb: { name: 'rift-validation', text: 'Validation' } } as IRouteData
    },
];

export const RiftRoutes: Routes = [
    {
        path: '',
        redirectTo: 'connect',
        pathMatch: 'full'
    },
    {
        path: 'browser-incompatible',
        component: BrowserIncompatibleComponent,
    },
    {
        path: 'reconnect/:protocol/:connectionData/:subPage',
        canActivate: [UserIsAuthenticated, RIFTUserIsRIFTRole],
        component: ReconnectComponent
    },
    {
        path: ':protocol/:connectionData',
        canActivate: [UserIsAuthenticated, RIFTUserIsRIFTRole],
        component: HomeComponent,
        children: BaseRiftRoutes
    },
    {
        path: 'connect',
        canActivate: [UserIsAuthenticated, RIFTUserIsRIFTRole],
        component: ConnectComponent
    },
    {
        path: 'disconnect',
        component: DisconnectComponent
    },
    {
        path: 'login',
        component: LoginComponent,
        data: { breadcrumb: { name: 'rift-login', text: 'Login' }, safeCountCheckRequired: true } as IRouteData
    },
];

@NgModule({
    imports: [RouterModule.forRoot(RiftRoutes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class RiftRoutingModule { }
