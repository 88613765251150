import { Component, Inject, Injector, OnInit, HostBinding } from '@angular/core';
import { TaskToExecuteModel } from '@em/models/restapi/TaskToExecute.Model';
import { TaskToExecuteResultModel } from '@em/models/restapi/TaskToExecuteResult.Model';
import { TaskService } from '@em/service/data/task/Task.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { TaskToExecuteStateEnumHelpers } from '@shared/enum/TaskToExecuteState.Enum';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import * as FileSaver from 'file-saver';
import { FileUtility } from '@shared/utility/File.Utility';
import { ITableRowClicked } from '@shared/component/table/Table.Component';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class SettingsTaskLauncherViewDialogData {
    public task: TaskToExecuteModel;

    public constructor(task: TaskToExecuteModel) {
        this.task = task;
    }
}

export class SettingsTaskLauncherViewDialogResult {
    public constructor() { }
}

@Component({
    selector: 'em-settings-task-launcher-view',
    templateUrl: './Settings.TaskLauncher.View.Component.html',
    styleUrls: ['./Settings.TaskLauncher.View.Component.scss']
})
export class SettingsTaskLauncherViewComponent extends BaseComponent implements OnInit {
    public static className: string = 'SettingsTaskLauncherViewComponent';

    @HostBinding()
    public id: string = 'em-settings-task-launcher-view';

    public getResultLogProcess: ProcessMonitorServiceProcess;
    public downloadResultLogProcess: ProcessMonitorServiceProcess;

    public hasLog: boolean = true;
    public task: TaskToExecuteModel;
    public results: Array<TaskToExecuteResultModel>;
    public resultLog: string;
    public dataSource = new MatTableDataSource<TaskToExecuteResultModel>();
    public TaskToExecuteStateEnumHelpers = TaskToExecuteStateEnumHelpers;
    public displayedColumns = ['finished', 'duration', 'status'];

    public constructor(
        private readonly _taskService: TaskService,
        @Inject(MAT_DIALOG_DATA) private readonly _data: SettingsTaskLauncherViewDialogData,
        private readonly _dialogRef: MatDialogRef<SettingsTaskLauncherViewComponent>,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.getResultLogProcess = this.processMonitorService.getProcess(SettingsTaskLauncherViewComponent.className, 'Getting result log.');
        this.downloadResultLogProcess = this.processMonitorService.getProcess(SettingsTaskLauncherViewComponent.className, 'Downloading result log.');

        this.task = this._data.task;
        this.results = this.task.results;
        this.dataSource.data = this.results;
    }

    public ngOnInit(): void {
        super.ngOnInit();

        if (!this.isNullOrUndefined(this.results) && this.results.length > 0) {
            this.resultSelected({ data: this.results[0], event: null });
        }
    }

    public resultSelected(event: ITableRowClicked<TaskToExecuteResultModel>): void {
        this.getResultLog(event.data);
    }

    public cancel(): void {
        this._dialogRef.close();
    }

    public downloadResultLog(result: TaskToExecuteResultModel): void {
        this.addSubscription(this.observableHandlerBase(this._taskService.getTaskResultLog(this.task.taskEntryID, result.resultID, this.downloadResultLogProcess), this.downloadResultLogProcess).subscribe(
            logResult => {
                if (!this.isNullOrUndefined(logResult) && !this.isEmptyOrWhiteSpace(logResult)) {
                    const blob = new Blob([logResult], { type: 'text/plain;charset=utf-8' });
                    FileSaver.saveAs(blob, FileUtility.sanitize(`${this.task.taskName}_${DateTimeUtility.toFileNameDateTime(this.task.timeFinished)}.log`));
                }
            }
        ), this.downloadResultLogProcess);
    }

    private getResultLog(result: TaskToExecuteResultModel): void {
        this.addSubscription(this.observableHandlerBase(this._taskService.getTaskResultLog(this.task.taskEntryID, result.resultID, this.getResultLogProcess), this.getResultLogProcess).subscribe(
            logResult => {
                if (this.isNullOrUndefined(logResult) || this.isEmptyOrWhiteSpace(logResult)) {
                    this.hasLog = false;
                } else {
                    this.hasLog = true;
                    this.resultLog = logResult;
                }
            },
            () => {
                this.hasLog = false;
            }
        ), this.getResultLogProcess);
    }
}
