import { ClientConnectionModel } from '@rift/models/restapi/ClientConnection.Model';
import { ChangeTrackedCollectionModel, IChangeTrackedCollectionModel } from '@shared/models/restapi/ChangeTrackedCollection.Model';

export interface IClientConnectionCollectionModel extends IChangeTrackedCollectionModel<ClientConnectionModel> {

}

export class ClientConnectionCollectionModel extends ChangeTrackedCollectionModel<ClientConnectionModel> implements IClientConnectionCollectionModel {
    public constructor() {
        super(ClientConnectionModel);
    }
}
