import { Component, HostBinding, HostListener, Injector, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { SettingsRelaysLimitsComponent, SettingsRelaysLimitsData, SettingsRelaysLimitsResult } from '@rift/components/settings/relays/limits/Settings.Relays.Limits.Component';
import { SettingsRelaysPulseWidthComponent, SettingsRelaysPulseWidthData, SettingsRelaysPulseWidthResult } from '@rift/components/settings/relays/pulsewidth/Settings.Relays.PulseWidth.Component';
import { RegisterBaseCollectionModel } from '@rift/models/restapi/RegisterBaseCollection.Model';
import { RelayModel } from '@rift/models/restapi/Relay.Model';
import { RelayChannelModel } from '@rift/models/restapi/RelayChannel.Model';
import { RelayRegisterModel } from '@rift/models/restapi/RelayRegister.Model';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { RelayService } from '@rift/service/data/relay/Relay.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { RelayDefaultStateEnum } from '@shared/enum/RelayDefaultState.Enum';
import { RelayLocationEnum } from '@shared/enum/RelayLocation.Enum';
import { RelayModeEnum, RelayModeEnumHelpers } from '@shared/enum/RelayMode.Enum';
import { RelayOperationTypeEnum } from '@shared/enum/RelayOperationType.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { OnDeactivate } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, of, zip } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { UnitTypeEnum } from '@shared/enum/UnitType.Enum';
import { MatInput } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
    selector: 'rift-settings-relays',
    templateUrl: './Settings.Relays.Component.html',
    styleUrls: ['./Settings.Relays.Component.scss']
})
export class SettingsRelaysComponent extends RiftBaseComponent implements OnDeactivate, ISaveAllChanges, ILoadDate {
    public static className: string = 'SettingsRelaysComponent';
    public RelayDefaultStateEnum = RelayDefaultStateEnum;
    public RelayLocationEnum = RelayLocationEnum;
    public RelayModeEnum = RelayModeEnum;
    public RelayModeEnumHelpers = RelayModeEnumHelpers;
    public UnitGenerationEnum = UnitGenerationEnum;

    public RelayOperationTypeEnum = RelayOperationTypeEnum;
    public displayedColumns: Array<string> = ['channelName', 'operation', 'limits', 'pullUp', 'defaultState', 'registers', 'mode', 'pulseWidth'];
    public editRelayChannelLimitsProcess: ProcessMonitorServiceProcess;
    public editRelayRegisterPulseWidthProcess: ProcessMonitorServiceProcess;
    public canIOCapable: boolean;
    public unitGen: UnitGenerationEnum;

    @ViewChildren('registerName')
    public registerNames: QueryList<MatInput>;

    @HostBinding()
    public id: string = 'rift-settings-relays';
    public registerBaseCollection: RegisterBaseCollectionModel;

    public relayChannelsDataSource: MatTableDataSource<RelayChannelModel> = new MatTableDataSource<RelayChannelModel>();
    public relays: RelayModel;

    public globalHeartbeatFormGroup: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    public get globalHeartbeatPeriodEnabled(): boolean {
        return this.relays.relayChannels.some((val) => val.relayRegisters.some((reg) => reg.registerID === -2));
    }

    public constructor(
        private readonly _relayService: RelayService,
        private readonly _registerService: RegisterService,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsRelaysComponent.className, 'Form values change');
        this.editRelayChannelLimitsProcess = this.processMonitorService.getProcess(SettingsRelaysComponent.className, 'Edit relay channel limits');
        this.editRelayRegisterPulseWidthProcess = this.processMonitorService.getProcess(SettingsRelaysComponent.className, 'Edit relay register pulse width');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsRelaysComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsRelaysComponent.className, this.saveAllChangesProcessText);

        this.addSubscription(this.observableHandlerBase(this.getHostDevice(), null).subscribe(
            hostDevice => {
                this.unitGen = hostDevice.unitGen;
                if ((hostDevice.unitGen === UnitGenerationEnum.gen4 || hostDevice.unitGen === UnitGenerationEnum.gazelle) && hostDevice.unitType !== UnitTypeEnum.relay) {
                    this.canIOCapable = true;
                }
                else {
                    this.canIOCapable = false;
                }
            }
        ), null);

        this.addSaveAllAction(this);

        this.globalHeartbeatFormGroup = this._formBuilder.group({
            globalHeartbeatPeriod: ['', Validators.compose([Validators.required, Validators.min(10), Validators.max(600)])],
        });
        this.formGroupTracker.track(this.globalHeartbeatFormGroup);

        this.addSubscription(this.observableHandlerBase(this.globalHeartbeatFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesGlobalHeartbeatFormGroup()), this.formValuesChangeProcess);

        this.initConnectionState();
    }

    public addRelayRegisterSelectionChange(event: MatSelectChange, relayChannel: RelayChannelModel): void {
        const select = event.source;
        if (event.value === -2 && !relayChannel.relayRegisters.some(i => i.registerID === -2)) {
            const relayRegister = new RelayRegisterModel();
            this.setToHeartbeatRelayRegister(relayRegister);
            relayChannel.relayRegisters.push(relayRegister);
            relayChannel.heartbeatPulseWidth = 400;

            if (this.relays.globalHeartbeatPeriod === null) {
                this.relays.globalHeartbeatPeriod = 10;
                this.setGlobalHeartbeatFormGroupValues();
            }

        } else if (event.value !== -2) {
            const register = this.registerBaseCollection.items.find(i => i.registerIndex === event.value);
            if (!this.isNullOrUndefined(register)) {
                const relayRegister = new RelayRegisterModel();
                relayRegister.registerID = register.registerIndex;
                relayRegister.registerName = register.registerName;
                relayChannel.relayRegisters.push(relayRegister);
            }
        }
        select.value = null;
        this.updateSaveAllAction(this);
    }

    public canIOEnabledChanged(event: MatSlideToggleChange): void {
        this.relays.cANIOEnabled = event.checked;
        this.setRelayChannelsDataSourceData();
        this.updateSaveAllAction(this);
    }

    public deleteRelayRegister(relayChannel: RelayChannelModel, relayRegister: RelayRegisterModel): void {
        const index = relayChannel.relayRegisters.findIndex(i => i.registerID === relayRegister.registerID);
        if (index !== -1) {
            if (relayRegister.registerID === -2) {
                relayChannel.heartbeatPulseWidth = undefined;
            }
            relayChannel.relayRegisters.splice(index, 1);
        }

        const globalHeartbeatRequired = this.relays.relayChannels.some((val) => val.relayRegisters.some((reg) => reg.registerID === -2));

        if (!globalHeartbeatRequired) {
            this.relays.globalHeartbeatPeriod = null;
            this.updateModelValuesGlobalHeartbeatFormGroup();
        }

        this.updateSaveAllAction(this);
    }

    public editRelayChannelLimits(relayChannel: RelayChannelModel): void {
        const dialogRef = this._dialog.open(SettingsRelaysLimitsComponent, { data: new SettingsRelaysLimitsData(relayChannel), disableClose: true });

        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.editRelayChannelLimitsProcess).subscribe((result: SettingsRelaysLimitsResult) => {
            if (!this.isNullOrUndefined(result) && result.ok) {
                relayChannel.inputFilterLowerLimit = result.inputFilterLowerLimitEnabled ? result.inputFilterLowerLimit : null;
                relayChannel.inputFilterUpperLimit = result.inputFilterUpperLimitEnabled ? result.inputFilterUpperLimit : null;
                this.updateSaveAllAction(this);
            }
        }), this.editRelayChannelLimitsProcess);
    }

    public editRelayRegisterPulseWidth(relayRegister: RelayRegisterModel, relayChannel: RelayChannelModel): void {
        const dialogRef = this._dialog.open(SettingsRelaysPulseWidthComponent, { data: new SettingsRelaysPulseWidthData(relayRegister), disableClose: true });

        this.addSubscription(this.observableHandlerBase(dialogRef.afterClosed(), this.editRelayRegisterPulseWidthProcess).subscribe((result: SettingsRelaysPulseWidthResult) => {
            if (!this.isNullOrUndefined(result) && result.ok) {
                if (relayRegister.registerID === -2) {
                    relayChannel.heartbeatPulseWidth = result.pulseWidth;
                } else {
                    relayRegister.pulseWidth = result.pulseWidth;
                }
                this.updateSaveAllAction(this);
            }
        }), this.editRelayRegisterPulseWidthProcess);
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public isRegisterUsed(regiserIndex: number): boolean {
        return this.relays.relayChannels.some(relayChannel => {
            if (relayChannel.operationType === RelayOperationTypeEnum.output){
                return relayChannel.relayRegisters.some(relayRegister => relayRegister.registerID === regiserIndex);
            }

            return false;
        });
    }

    public get isValid(): boolean {
        const valid = this.relays.relayChannels.every(relayChannel => {
            let relayChannelValid = false;

            switch (relayChannel.operationType) {
                case RelayOperationTypeEnum.disabled:
                    relayChannelValid = true;
                    break;
                case RelayOperationTypeEnum.input:
                    relayChannelValid = (
                        relayChannel.relayRegisters.length === 1 &&
                        !this.isNullOrUndefined(relayChannel.relayRegisters[0].registerName) &&
                        relayChannel.relayRegisters[0].registerName.length >= 1 &&
                        relayChannel.relayRegisters[0].registerName.length <= 23 &&
                        !relayChannel.relayRegisters[0].registerName.includes('_') &&
                        !relayChannel.relayRegisters[0].registerName.includes('|') &&
                        !this.registerBaseCollection.items.some(r => relayChannel.relayRegisters[0].hasChanges === true && (this.isNullOrUndefined(relayChannel.relayRegisters) || this.isNullOrUndefined(relayChannel.relayRegisters[0])) && r.registerName === relayChannel.relayRegisters[0].registerName.toLocaleLowerCase()) &&
                        !this.relays.relayChannels.some(rc => rc.uniqueId !== relayChannel.uniqueId && rc.relayRegisters.some(rr => rr.hasChanges === true && (this.isNullOrUndefined(relayChannel.relayRegisters) || this.isNullOrUndefined(relayChannel.relayRegisters[0])) && rr.registerName.toLocaleLowerCase() === relayChannel.relayRegisters[0].registerName.toLocaleLowerCase()))
                    );
                    break;
                case RelayOperationTypeEnum.output:
                    relayChannelValid = (
                        relayChannel.relayRegisters.length === 0
                        ||
                        (
                            relayChannel.relayRegisters.length > 0 &&
                            relayChannel.relayRegisters.every(relayRegister =>
                                (
                                    relayRegister.registerID === -2 && !this.isNullOrUndefined(relayChannel.heartbeatPulseWidth)
                                )
                                ||
                                (
                                    relayRegister.registerID !== -2 && !this.isNullOrUndefined(relayRegister.pulseWidth)
                                )
                                ||
                                (
                                    relayRegister.relayMode === RelayModeEnum.holdHigh
                                )
                                ||
                                (
                                    relayRegister.relayMode === RelayModeEnum.holdLow
                                )
                            )
                        )
                    );
                    break;
            }

            return relayChannelValid;
        });

        let globalHeartbeatPeriodValid = true;

        if (this.globalHeartbeatPeriodEnabled && (this.isNullOrUndefined(this.relays.globalHeartbeatPeriod) || this.relays.globalHeartbeatPeriod < 10 || this.relays.globalHeartbeatPeriod > 600)) {
            globalHeartbeatPeriodValid = false;
        }

        return valid && globalHeartbeatPeriodValid;
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._registerService.getRegisters(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.registerBaseCollection = result;
                        this.changeTracker.track(this.registerBaseCollection);
                    }
                    return true;
                })
            ),
            this._relayService.getSettings(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.relays = result;
                        this.changeTracker.track(this.relays);
                        this.setGlobalHeartbeatFormGroupValues();
                    }
                    return true;
                })
            )
        ).pipe(
            tap(() => {
                const relayChannelsLength = this.relays.relayChannels.length;
                for (let relayChannelIndex = 0; relayChannelIndex < relayChannelsLength; relayChannelIndex++) {
                    const relayChannel = this.relays.relayChannels[relayChannelIndex];
                    if (relayChannel.operationType === this.RelayOperationTypeEnum.input && relayChannel.relayRegisters.length === 0) {
                        const input = new RelayRegisterModel();
                        input.loadFromRestApiModel({});
                        relayChannel.relayRegisters.push(input);
                    }

                    const relayRegistersLength = relayChannel.relayRegisters.length;
                    for (let relayRegisterIndex = 0; relayRegisterIndex < relayRegistersLength; relayRegisterIndex++) {
                        const relayRegister = relayChannel.relayRegisters[relayRegisterIndex];
                        const register = this.registerBaseCollection.items.find(reg => reg.registerIndex === relayRegister.registerID);
                        if (!this.isNullOrUndefined(register)) {
                            relayRegister.registerName = register.registerName;
                            relayRegister.commitChanges();
                        }
                    }
                }

                this.addHeartbeatRegisters();

                this.setRelayChannelsDataSourceData();
            })
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public onDefaultStateChange(): void {
        this.updateSaveAllAction(this);
    }

    public onModeChange(): void {
        this.updateSaveAllAction(this);
    }

    public onPullUpChange(event: MatCheckboxChange, relayChannel: RelayChannelModel): void {
        relayChannel.pullupEnabled = event.checked;
        this.updateSaveAllAction(this);
    }

    public operationSelectionChange(event: MatSelectChange, relayChannel: RelayChannelModel): void {
        // If we're disabling a fet input then we need to remove that fet register from any of the relay outputs
        // otherwise we can have an inconsistent configuration
        if (relayChannel.validOperationTypes.some(v => v === RelayOperationTypeEnum.input) && event.value === RelayOperationTypeEnum.disabled){
            relayChannel.relayRegisters.forEach(r => {
                this.relays.relayChannels.forEach(l => {
                    if (relayChannel.channelID !== l.channelID){
                        const val = l.relayRegisters.find(reg => reg.registerID === r.registerID);

                        if (!this.isNullOrUndefined(val)){
                            this.deleteRelayRegister(l, val);
                        }
                    }
                });
            });
        }

        relayChannel.relayRegisters.clear();
        switch (event.value) {
            case RelayOperationTypeEnum.input:
                relayChannel.relayRegisters.push(new RelayRegisterModel());
                break;
        }
        this.updateSaveAllAction(this);
    }

    public registerNameKeyup(event: KeyboardEvent, relayRegister: RelayRegisterModel): void {
        relayRegister.registerName = (event.target as HTMLInputElement).value;
        this.updateSaveAllAction(this);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this.removeHeartbeatRegisters();

        const saveAllSub = zip(
            of(this.relays.hasChanges).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._relayService.setSettings(this.relays).pipe(
                            map(() => true)
                        );
                    } else {
                        return of(true);
                    }
                })
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            flatMap((result) => {
                if (this.isZipResultSuccess(result)) {
                    this._registerService.clearCache();
                    this._relayService.clearCache();
                    return this.loadData(this.openPleaseWaitLoadingDialog(), process);
                } else {
                    return of(false);
                }
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._relayService.clearCache();
            return this.loadData(this.openPleaseWaitLoadingDialog());
        });
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.globalHeartbeatFormGroup.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.globalHeartbeatFormGroup.enable();
    }

    private addHeartbeatRegisters(): void {
        const relayChannelsLength = this.relays.relayChannels.length;
        for (let relayChannelIndex = 0; relayChannelIndex < relayChannelsLength; relayChannelIndex++) {
            const relayChannel = this.relays.relayChannels[relayChannelIndex];
            if (!this.isNullOrUndefined(relayChannel.heartbeatPulseWidth) && !relayChannel.relayRegisters.some(relayRegister => relayRegister.registerID === -2)) {
                const heartbeat = new RelayRegisterModel();
                this.setToHeartbeatRelayRegister(heartbeat);
                relayChannel.relayRegisters.push(heartbeat);
                relayChannel.commitChanges();
            }
        }
    }

    private setGlobalHeartbeatFormGroupValues(): void {
        if (!this.isNullOrUndefined(this.relays.globalHeartbeatPeriod)) {
            this.globalHeartbeatFormGroup.setValue({
                globalHeartbeatPeriod: this.relays.globalHeartbeatPeriod,
            });
        }
    }

    private updateModelValuesGlobalHeartbeatFormGroup(): void {
        if (!this.isNullOrUndefined(this.relays) && this.isReadOnly === false) {
            const formValues = this.globalHeartbeatFormGroup.value;

            this.relays.globalHeartbeatPeriod = formValues.globalHeartbeatPeriod;

            this.updateSaveAllAction(this);
        }
    }

    private removeHeartbeatRegisters(): void {
        const relayChannelsLength = this.relays.relayChannels.length;
        for (let relayChannelIndex = 0; relayChannelIndex < relayChannelsLength; relayChannelIndex++) {
            const relayChannel = this.relays.relayChannels[relayChannelIndex];
            const index = relayChannel.relayRegisters.findIndex(relayRegister => relayRegister.registerID === -2);
            if (index !== -1) {
                relayChannel.relayRegisters.splice(index, 1);
            }
        }
    }

    private setRelayChannelsDataSourceData() {
        const data: RelayChannelModel[] = [];
        const length = this.relays.relayChannels.length;
        for (let index = 0; index < length; index++) {
            const item = this.relays.relayChannels[index];
            if (((this.relays.cANIOEnabled === false && item.relayLocation !== RelayLocationEnum.canIo) || this.relays.cANIOEnabled === true)) {
                data.push(item);
            }
        }
        this.relayChannelsDataSource.data = data;
    }

    private setToHeartbeatRelayRegister(relayRegister: RelayRegisterModel): void {
        relayRegister.registerID = -2;
        relayRegister.registerName = 'Heartbeat';
        relayRegister.relayMode = RelayModeEnum.pulse;
    }
}
