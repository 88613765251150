import { Component, HostBinding, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IUserCountsKeyBindings } from '@rift/components/validation/Validation.Defaults';
import { BaseComponent } from '@shared/base/Base.Component';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

export class ValidationSettingsDialogData {
    public constructor(
        public readonly userCountKeyBindings: Array<IUserCountsKeyBindings>,
        public arrowKeyJogMs: number,
        public lineReportBucketSize: number,
        public showDurationAsTime: boolean,
        public showDurationAsDeviceTimeZone: boolean,
        public hullScaleFactor: number,
    ) { }
}

export class ValidationSettingsDialogResult {
    public constructor(
        public readonly userCountKeyBindings: Array<IUserCountsKeyBindings>,
        public arrowKeyJogMs: number,
        public lineReportBucketSize: number,
        public showDurationAsTime: boolean,
        public showDurationAsDeviceTimeZone: boolean,
        public hullScaleFactor: number,
    ) { }
}

@Component({
    selector: 'rift-validation-settings-dialog',
    templateUrl: './Settings.Dialog.Component.html',
    styleUrls: ['./Settings.Dialog.Component.scss']
})
export class ValidationSettingsDialogComponent extends BaseComponent {
    public static className: string = 'SessionsMenuComponent';

    @HostBinding()
    public id: string = 'rift-validation-settings-dialog';

    public arrowKeyJogFormGroup: FormGroup;
    public lineReportFormGroup: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    private _keyBinding: IUserCountsKeyBindings;
    private _direction: keyof IUserCountsKeyBindings;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: ValidationSettingsDialogData,
        private readonly _dialogRef: MatDialogRef<ValidationSettingsDialogComponent>,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.formValuesChangeProcess = this.processMonitorService.getProcess(ValidationSettingsDialogComponent.className, 'Form values change');

        this.arrowKeyJogFormGroup = this._formBuilder.group({
            arrowKeyJogMs: [this.data.arrowKeyJogMs, Validators.compose([Validators.required, Validators.min(1), Validators.max(50000)])],
        });
        this.formGroupTracker.track(this.arrowKeyJogFormGroup);

        this.addSubscription(this.observableHandlerBase(this.arrowKeyJogFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesArrowKeyJogFormGroup()), this.formValuesChangeProcess);

        this.lineReportFormGroup = this._formBuilder.group({
            bucketSize: [this.data.lineReportBucketSize, Validators.compose([Validators.required, Validators.min(1), Validators.max(50000)])],
        });
        this.formGroupTracker.track(this.lineReportFormGroup);

        this.addSubscription(this.observableHandlerBase(this.lineReportFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.updateModelValuesLineReportFormGroup()), this.formValuesChangeProcess);
    }

    public durationTimeChange(event: MatSlideToggleChange): void {
        this.data.showDurationAsTime = event.checked;
    }

    public durationAsDeviceTimeZoneChange(event: MatSlideToggleChange): void {
        this.data.showDurationAsDeviceTimeZone = event.checked;
    }

    public close(): void {
        this._dialogRef.close(new ValidationSettingsDialogResult(this.data.userCountKeyBindings, this.data.arrowKeyJogMs, this.data.lineReportBucketSize, this.data.showDurationAsTime, this.data.showDurationAsDeviceTimeZone, this.data.hullScaleFactor));
    }

    public mouseEnter(mouseOverKeyBinding: IUserCountsKeyBindings, mouseOverDirection: keyof IUserCountsKeyBindings): void {
        this._keyBinding = mouseOverKeyBinding;
        this._direction = mouseOverDirection;
    }

    public mouseLeave(): void {
        this._keyBinding = null;
        this._direction = null;
    }

    public mapKey(event: KeyboardEvent): void {
        if (!isNullOrUndefined(this._keyBinding) && !isNullOrUndefined(this._direction)) {
            const length = this.data.userCountKeyBindings.length;
            const key = String.fromCharCode(event.keyCode).toUpperCase();

            for (let i = 0; i < length; i++) {
                if (this.data.userCountKeyBindings[i].increment === key) {
                    this.data.userCountKeyBindings[i].increment = this._keyBinding[this._direction];
                }
                if (this.data.userCountKeyBindings[i].decrement === key) {
                    this.data.userCountKeyBindings[i].decrement = this._keyBinding[this._direction];
                }
            }

            this._keyBinding[this._direction] = key;
        }
    }

    private updateModelValuesArrowKeyJogFormGroup(): void {
        if (!this.isNullOrUndefined(this.data.arrowKeyJogMs)) {
            const formValues = this.arrowKeyJogFormGroup.getRawValue();

            this.data.arrowKeyJogMs = formValues.arrowKeyJogMs;
        }
    }

    private updateModelValuesLineReportFormGroup(): void {
        if (!this.isNullOrUndefined(this.data.lineReportBucketSize)) {
            const formValues = this.lineReportFormGroup.getRawValue();

            this.data.lineReportBucketSize = formValues.bucketSize;
        }
    }
}
