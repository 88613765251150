import { Component, Inject, Injector, OnInit, HostBinding } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';
import { EventsService } from '@shared/service/events/Events.Service';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class SettingsTaskLauncherUploadDialogData {
    public constructor() { }
}

export class SettingsTaskLauncherUploadDialogResult {

    public constructor() { }
}

@Component({
    selector: 'em-settings-task-launcher-upload',
    templateUrl: './Settings.TaskLauncher.Upload.Component.html',
    styleUrls: ['./Settings.TaskLauncher.Upload.Component.scss']
})
export class SettingsTaskLauncherUploadComponent extends BaseComponent implements OnInit {
    public static className: string = 'SettingsTaskLauncherUploadComponent';

    @HostBinding()
    public id: string = 'em-settings-task-launcher-upload';

    public dataSource = new MatTableDataSource<FileItem>();
    public displayedColumns = ['name', 'size', 'progress', 'status'];
    public hasDropZoneFile: boolean = false;
    public uploader: FileUploader;

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly _data: SettingsTaskLauncherUploadDialogData,
        private readonly _dialogRef: MatDialogRef<SettingsTaskLauncherUploadComponent>,
        private readonly _eventsService: EventsService,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.uploader = new FileUploader({ url: `${this.configurationService.emRestApiBase}task/uploadtaskpackage` });
        this.dataSource.data = this.uploader.queue;
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public cancel(): void {
        this._eventsService.changedTasks();
        this._dialogRef.close();
    }

    public updateDataSource(): void {
        this.dataSource.data = this.uploader.queue;
    }
}
