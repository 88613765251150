import { BaseModel } from '@shared/base/Base.Model';
import { ValidationResultEnum } from '@shared/enum/ValidationResult.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { isNumber } from '@shared/utility/General.Utility';

export interface IValidationResultModel extends IRestModel {
    result: ValidationResultEnum;
}

export class ValidationResultModel extends BaseModel implements IValidationResultModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public result: ValidationResultEnum = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        if (isNumber(restModel)) {
            this.result = restModel;
        } else {
            RestModelUtility.loadProperties(restModel, this, 'RIFT-ValidationResultModel');
        }
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }
}
