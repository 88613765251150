import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientConnectionCollectionModel } from '@rift/models/restapi/ClientConnectionCollection.Model';
import { HttpPostModel } from '@rift/models/restapi/HttpPost.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class RestApiOutboundService extends RiftRestApiService {
    private _controller = 'outbound';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public getClientConnections(process?: ProcessMonitorServiceProcess): Observable<ClientConnectionCollectionModel> {
        return this.get<ClientConnectionCollectionModel>(`${this._controller}/tools`, ClientConnectionCollectionModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getHttpPost(process?: ProcessMonitorServiceProcess): Observable<HttpPostModel> {
        return this.get<HttpPostModel>(`${this._controller}/httppost`, HttpPostModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateClientConnections(clientConnections: ClientConnectionCollectionModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<ClientConnectionCollectionModel, ResultModel>(`${this._controller}/tools`, clientConnections, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateHttpPost(httpPost: HttpPostModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<HttpPostModel, ResultModel>(`${this._controller}/httppost`, httpPost, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
