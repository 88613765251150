export enum UnitTypeEnum {
    canNode = 1,
    ip = 2,
    relay = 3,
    canMaster = 4,
    unknown = 5,
    valCam = 6,
    ipUnverified = 7,
    videoIPMaster = 8,
    ipMaster = 9,
    node = 10,
    vector4D = 11,
    falcon = 12
}

export class UnitTypeEnumHelpers {
    public static fromRestApi(value: string): UnitTypeEnum {
        switch (value) {
            case 'CAN Node':
                return UnitTypeEnum.canNode;
            case 'IP':
                return UnitTypeEnum.ip;
            case 'Relay':
                return UnitTypeEnum.relay;
            case 'CAN Master':
                return UnitTypeEnum.canMaster;
            case 'Unknown':
                return UnitTypeEnum.unknown;
            case 'ValCam':
                return UnitTypeEnum.valCam;
            case 'IP (Unverified)':
                return UnitTypeEnum.ipUnverified;
            case 'Video IP Master':
                return UnitTypeEnum.videoIPMaster;
            case 'IP Master':
                return UnitTypeEnum.ipMaster;
            case 'Node':
                return UnitTypeEnum.node;
            case 'Vector 4D':
                return UnitTypeEnum.vector4D;
            case 'Falcon':
                return UnitTypeEnum.falcon;
        }
    }

    public static toFullName(value: UnitTypeEnum): string {
        switch (value) {
            case UnitTypeEnum.canNode:
                return 'CAN Node';
            case UnitTypeEnum.ip:
                return 'IP';
            case UnitTypeEnum.relay:
                return 'Relay';
            case UnitTypeEnum.canMaster:
                return 'CAN Master';
            case UnitTypeEnum.unknown:
                return 'Unknown';
            case UnitTypeEnum.valCam:
                return 'ValCam';
            case UnitTypeEnum.ipUnverified:
                return 'IP (Unverified)';
            case UnitTypeEnum.videoIPMaster:
                return 'Video IP Master';
            case UnitTypeEnum.ipMaster:
                return 'IP Master';
            case UnitTypeEnum.node:
                return 'Node';
            case UnitTypeEnum.vector4D:
                return 'Vector 4D';
            case UnitTypeEnum.falcon:
                return 'Gemini';
            default:
                return 'Unknown';
        }
    }
}
