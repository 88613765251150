import { ITrackPropertyChanges } from '@shared/interface/ITrackPropertyChanges';

/**
 * Manages collections of ITrackPropertyChanges implementing objects.
 *
 * @export
 * @class ChangeTrackerCollection
 */
export class ChangeTrackerCollection {
    private _iTrackPropertyChanges: Array<ITrackPropertyChanges> = [];

    /**
     * Creates an instance of ChangeTrackerCollection.
     *
     * @memberof ChangeTrackerCollection
     */
    public constructor() {
    }

    /**
     * Add a ITrackPropertyChanges object to the tracker.
     *
     * @param {ITrackPropertyChanges} item
     * @memberof ChangeTrackerCollection
     */
    public track(...item: Array<ITrackPropertyChanges>): void {
        this._iTrackPropertyChanges.push(...item);
    }

    /**
     * Checks for changes excluding propertyNames.
     *
     * @param {...Array<string>} propertyNames
     * @returns {boolean}
     * @memberof ChangeTrackerCollection
     */
    public hasChangesExcluding(...propertyNames: Array<string>): boolean {
        return this._iTrackPropertyChanges.some(i => i.hasChangesExcluding(...propertyNames));
    }

    /**
     * Clear the tracked items.
     *
     * @memberof ChangeTrackerCollection
     */
    public clear(): void {
        this._iTrackPropertyChanges = [];
    }

    /**
     * Clears any uncommitted changes from tracked properties.
     *
     * @memberof ChangeTrackerCollection
     */
    public clearChanges(): void {
        const length = this._iTrackPropertyChanges.length;
        for (let index = 0; index < length; index++) {
            this._iTrackPropertyChanges[index].clearChanges();
        }
    }

    /**
     * True if the tracks objects have changes. else false.
     *
     * @type {boolean}
     * @memberof ChangeTrackerCollection
     */
    public get hasChanges(): boolean {
        return this._iTrackPropertyChanges.some(i => i.hasChanges);
    }

    /**
     * Marks all tracked properties as changes committed and sets original values.
     *
     * @memberof ChangeTrackerCollection
     */
    public commitChanges(): void {
        const length = this._iTrackPropertyChanges.length;
        for (let index = 0; index < length; index++) {
            this._iTrackPropertyChanges[index].commitChanges();
        }
    }
}
