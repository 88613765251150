import { NgZone, Directive } from '@angular/core';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';

@Directive()
export class Grid extends DisplayItem {
    public static readonly Height: number = 3000;
    public static readonly Width: number = 3000;

    /**
     * Fired when the mouse is down in the DisplayItem.container hitArea.
     *
     * @type {Subject<DisplayItemMouseEvent>}
     * @memberof DisplayItem
     */
    public mouseDown: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    /**
     * Fired when the mouse is pressed in the DisplayItem.container hitArea and moved.
     *
     * @type {Subject<DisplayItemMouseEvent>}
     * @memberof DisplayItem
     */
    public pressMove: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    /**
     * Fired when the mouse press is released in the DisplayItem.container hitArea.
     *
     * @type {Subject<DisplayItemMouseEvent>}
     * @memberof DisplayItem
     */
    public pressUp: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();

    // private _grid: createjs.Bitmap = new createjs.Bitmap(ImageUtility.BlankImagePath);
    private _gridShape: createjs.Shape = new createjs.Shape();


    public constructor(
        private readonly _zone: NgZone,
        private readonly _loadQueue: ViewPortLoadQueueService) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            if (this._loadQueue.isLoaded === true) {
                this.onLoadQueueComplete();
            } else {
                this.addSubscription(this._loadQueue.loaded.subscribe(() => this.onLoadQueueComplete()));
            }

            this.addEventHandlers();
        });
    }

    public bringToFront(): void {
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();

            this.container.removeAllChildren();

            this._gridShape.uncache();
            this._gridShape = null;
        });
    }

    public scale(scale: number): void {
    }

    public update(): void {
    }

    protected addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this._gridShape.on('mousedown', this.onGridMouseDown.bind(this));
            this._gridShape.on('pressmove', this.onGridPressMove.bind(this));
            this._gridShape.on('pressup', this.onGridPressUp.bind(this));
        });
    }

    protected removeEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this._gridShape.removeAllEventListeners();
        });
    }

    protected onGridMouseDown(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.mouseDown.next(new DisplayItemMouseEvent(this, event));
        });
    }

    protected onGridPressMove(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.pressMove.next(new DisplayItemMouseEvent(this, event));
        });
    }

    protected onGridPressUp(event: createjs.MouseEvent): void {
        this._zone.runOutsideAngular(() => {
            this.pressUp.next(new DisplayItemMouseEvent(this, event));
        });
    }

    protected unitOfMeasurementSet(): void {
        this._zone.runOutsideAngular(() => {
            this.drawGrid();
        });
    }

    private onLoadQueueComplete(): void {
        this._zone.runOutsideAngular(() => {
            this.drawGrid();
        });
    }

    private drawGrid(): void {
        this._zone.runOutsideAngular(() => {
            if (this._loadQueue.isLoaded === true) {
                this.container.children.forEach(element => {
                    element.uncache();
                });
                this.container.removeAllChildren();
                this.container.addChild(this._gridShape);

                const graphics = this._gridShape.graphics;
                const textPostFix = isNullOrUndefined(this.unitOfMeasurement) || this.unitOfMeasurement === UnitsOfMeasurementEnum.metric ? 'M' : 'Y';
                const majorStep = isNullOrUndefined(this.unitOfMeasurement) || this.unitOfMeasurement === UnitsOfMeasurementEnum.metric ? 100 : 91.44;
                const offset = isNullOrUndefined(this.unitOfMeasurement) || this.unitOfMeasurement === UnitsOfMeasurementEnum.metric ? 0 : 36.576;
                const majorLineThickness = 4;
                const majorLineHalfThickness = majorLineThickness / 2;
                const minorStep = majorStep / 2;
                const minorLineThickness = 2;
                const minorLineHalfThickness = minorLineThickness / 2;
                const halfHeight = Grid.Height / 2;
                const halfWidth = Grid.Width / 2;
                const labelFont = 'bold 12px Arial';
                const labelColor = '#d3d3d3';
                const labelPadding = 5;

                graphics.clear();
                graphics.beginFill('#6CB7CE');
                graphics.drawRect(0, 0, Grid.Width, Grid.Height);

                let isMajor = true;
                let isMinor = false;
                for (let x = offset; x <= Grid.Width; x += minorStep) {
                    if (isMajor === true) {
                        // Major step
                        const text = `${((x - halfWidth) / majorStep).toFixed(1)}${textPostFix}`;
                        const label = new createjs.Text(text, labelFont, labelColor);
                        const labelWidth = label.getMeasuredWidth();
                        const labelHeight = label.getMeasuredHeight();

                        label.x = x + labelPadding;
                        label.y = halfWidth + labelPadding;
                        this.container.addChild(label);

                        label.cache(0, 0, labelWidth, labelHeight, GLOBAL_CACHE_SCALE);

                        graphics.beginStroke('#ffffff');
                        graphics.setStrokeStyle(majorLineHalfThickness);
                        graphics.moveTo(x - majorLineHalfThickness, 0);
                        graphics.lineTo(x - majorLineHalfThickness, Grid.Height);

                        isMajor = false;
                        isMinor = true;
                    } else if (isMinor === true) {
                        // Minor step
                        graphics.beginStroke('#d9ecf2');
                        graphics.setStrokeStyle(minorLineHalfThickness);
                        graphics.moveTo(x - minorLineHalfThickness, 0);
                        graphics.lineTo(x - minorLineHalfThickness, Grid.Height);

                        isMajor = true;
                        isMinor = false;
                    }
                }

                isMajor = true;
                isMinor = false;
                for (let y = offset; y <= Grid.Height; y += minorStep) {
                    if (isMajor === true) {
                        // Major step
                        const text = `${(((y - halfHeight) / majorStep) * -1).toFixed(1)}${textPostFix}`;
                        const label = new createjs.Text(text, labelFont, labelColor);
                        const labelWidth = label.getMeasuredWidth();
                        const labelHeight = label.getMeasuredHeight();

                        label.x = halfWidth + labelPadding;
                        label.y = y + labelPadding;
                        this.container.addChild(label);

                        label.cache(0, 0, labelWidth, labelHeight, GLOBAL_CACHE_SCALE);

                        graphics.beginStroke('#ffffff');
                        graphics.setStrokeStyle(majorLineHalfThickness);
                        graphics.moveTo(0, y - majorLineHalfThickness);
                        graphics.lineTo(Grid.Width, y - majorLineHalfThickness);

                        isMajor = false;
                        isMinor = true;
                    } else if (isMinor === true) {
                        // Minor step
                        graphics.beginStroke('#d9ecf2');
                        graphics.setStrokeStyle(minorLineHalfThickness);
                        graphics.moveTo(0, y - minorLineHalfThickness);
                        graphics.lineTo(Grid.Width, y - minorLineHalfThickness);

                        isMajor = true;
                        isMinor = false;
                    }
                }

                this._gridShape.cache(0, 0, Grid.Width, Grid.Height, GLOBAL_CACHE_SCALE * 0.25);
                this.requireStageUpdate.next();
            }
        });
    }
}
