import { isUndefined } from '@shared/utility/General.Utility';

export interface IDictionary<TKey extends string | number | symbol, TValue> {
    readonly keys: Array<TKey>;
    addOrUpdate(key: TKey, value: TValue): void;
    remove(key: TKey): void;
    containsKey(key: TKey): boolean;
    get(key: TKey): TValue;
    forEach(callbackfn: (key: TKey, value: TValue) => void): void;
    some(callbackfn: (key: TKey, value: TValue) => boolean): boolean;
    every(callbackfn: (key: TKey, value: TValue) => boolean): boolean;
    filter(callbackfn: (key: TKey, value: TValue) => boolean): Array<{ key: TKey; value: TValue }>;
}

/**
 * A dictionary of unique key.
 *
 * @export
 * @class Dictionary
 * @implements {IDictionary<TKey, TValue>}
 * @template TKey
 * @template TValue
 */
export class Dictionary<TKey extends string | number | symbol, TValue> implements IDictionary<TKey, TValue> {
    public readonly iDictionary: boolean = true;

    private _store: any = {};

    /**
     * Determines whether all the members of an list satisfy the specified test.
     *
     * @param {(key: TKey, value: TValue) => boolean} callbackfn The every method calls the callbackfn function for each element in dictionary until the callbackfn returns false, or until the end of the dictionary.
     * @returns {boolean}
     * @memberof Dictionary
     */
    public every(callbackfn: (key: TKey, value: TValue) => boolean): boolean {
        return this.keys.every(key => callbackfn(key as TKey, this.get(key as TKey)));
    }

    /**
     * Determines whether the specified callback function returns true for any element of an list.
     *
     * @param {(key: TKey, value: TValue) => boolean} callbackfn The some method calls the callbackfn function for each element in dictionary until the callbackfn returns true, or until the end of the dictionary.
     * @returns {boolean}
     * @memberof Dictionary
     */
    public some(callbackfn: (key: TKey, value: TValue) => boolean): boolean {
        return this.keys.some(key => callbackfn(key as TKey, this.get(key as TKey)));
    }

    /**
     * Performs the specified action for each element in an list.
     *
     * @param {(key: TKey, value: TValue) => void} callbackfn forEach calls the callbackfn function one time for each element in the dictionary.
     * @memberof Dictionary
     */
    public forEach(callbackfn: (key: TKey, value: TValue) => void): void {
        this.keys.forEach(key => callbackfn(key as TKey, this.get(key as TKey)));
    }

    /**
     * Returns the elements of an dictionary that meet the condition specified in a callback function.
     *
     * @param {(key: TKey, value: TValue) => boolean} callbackfn The filter method calls the callbackfn function one time for each element in the dictionary.
     * @returns {List<{ key: TKey, value: TValue }>}
     * @memberof Dictionary
     */
    public filter(callbackfn: (key: TKey, value: TValue) => boolean): Array<{ key: TKey; value: TValue }> {
        return this.keys.filter(key => callbackfn(key as TKey, this.get(key as TKey))).map(k => ({ key: k as TKey, value: this.get(k as TKey) }));
    }

    public addOrUpdate(key: TKey, value: TValue): void {
        if (!isUndefined(key) && !isUndefined(value)) {
            this._store[key.toString()] = value;
        }
    }

    public containsKey(key: TKey): boolean {
        return !isUndefined(this._store[key]);
    }

    public get keys(): Array<TKey> {
        return Object.keys(this._store) as Array<TKey>;
    }

    public get(key: TKey): TValue {
        return this._store[key];
    }

    public remove(key: TKey): void {
        this._store[key] = undefined;
    }
}
