import { Routes } from '@angular/router';
import { SettingsBluetoothComponent } from '@rift/components/settings/bluetooth/Settings.Bluetooth.Component';
import { SettingsCountingComponent } from '@rift/components/settings/counting/Settings.Counting.Component';
import { SettingsCountLoggingComponent } from '@rift/components/settings/countlogging/Settings.CountLogging.Component';
import { SettingsHttpPostComponent } from '@rift/components/settings/httppost/Settings.HttpPost.Component';
import { SettingsMQTTComponent } from '@rift/components/settings/mqtt/Settings.MQTT.Component';
import { SettingsNetworkComponent } from '@rift/components/settings/network/Settings.Network.Component';
import { SettingsNetworkSelectComponent } from '@rift/components/settings/network/Settings.Network.Select.Component';
import { SettingsOutboundConnectionsComponent } from '@rift/components/settings/outboundconnections/Settings.OutboundConnections.Component';
import { SettingsRelaysComponent } from '@rift/components/settings/relays/Settings.Relays.Component';
import { SettingsSecurityComponent } from '@rift/components/settings/security/Settings.Security.Component';
import { SettingsComponent } from '@rift/components/settings/Settings.Component';
import { SettingsSiteComponent } from '@rift/components/settings/site/Settings.Site.Component';
import { SettingsVideoComponent } from '@rift/components/settings/video/Settings.Video.Component';
import { SettingsWideTrackerComponent } from '@rift/components/settings/widetracker/Settings.WideTracker.Component';
import { IRouteData } from '@shared/service/breadcrumb/BreadCrumb.Service.RouteData';
import { PendingChangesGuard } from '@shared/service/pendingchangesguard/PendingChangesGuard.Service';
import { ConfigHistoryComponent } from '@rift/components/settings/confighistory/ConfigHistory.Component';
import { SettingsBACNETComponent } from './bacnet/Settings.BACNET.Component';

export const RiftSettingsRoutes: Routes = [
    {
        path: 'settings',
        component: SettingsComponent,
        data: { breadcrumb: { name: 'rift-settings', text: 'Settings' } } as IRouteData,
        children: [
            {
                path: '',
                redirectTo: 'site',
                pathMatch: 'full',
            },
            {
                path: 'site',
                canDeactivate: [PendingChangesGuard],
                component: SettingsSiteComponent,
                data: { breadcrumb: { name: 'rift-settings-site', text: 'Site' } } as IRouteData,
            },
            {
                path: 'network',
                component: SettingsNetworkSelectComponent,
                data: { breadcrumb: { name: 'rift-settings-network-select', text: 'Network' } } as IRouteData,
                children: [
                    {
                        path: ':serial',
                        canDeactivate: [PendingChangesGuard],
                        component: SettingsNetworkComponent,
                    },
                ]
            },
            {
                path: 'widetracker',
                canDeactivate: [PendingChangesGuard],
                component: SettingsWideTrackerComponent,
                data: { breadcrumb: { name: 'rift-settings-widetracker', text: 'Wide Tracker' } } as IRouteData,
            },
            {
                path: 'outboundconnections',
                canDeactivate: [PendingChangesGuard],
                component: SettingsOutboundConnectionsComponent,
                data: { breadcrumb: { name: 'rift-settings-outboundconnections', text: 'Outbound Connections' } } as IRouteData,
            },
            {
                path: 'security',
                canDeactivate: [PendingChangesGuard],
                component: SettingsSecurityComponent,
                data: { breadcrumb: { name: 'rift-settings-security', text: 'Security' } } as IRouteData,
            },
            {
                path: 'countlogging',
                canDeactivate: [PendingChangesGuard],
                component: SettingsCountLoggingComponent,
                data: { breadcrumb: { name: 'rift-settings-countlogging', text: 'Count Logging' } } as IRouteData,
            },
            {
                path: 'relays',
                canDeactivate: [PendingChangesGuard],
                component: SettingsRelaysComponent,
                data: { breadcrumb: { name: 'rift-settings-relays', text: 'Relays' } } as IRouteData,
            },
            {
                path: 'counting',
                canDeactivate: [PendingChangesGuard],
                component: SettingsCountingComponent,
                data: { breadcrumb: { name: 'rift-settings-counting', text: 'Counting' } } as IRouteData,
            },
            {
                path: 'video',
                canDeactivate: [PendingChangesGuard],
                component: SettingsVideoComponent,
                data: { breadcrumb: { name: 'rift-settings-video', text: 'Video' } } as IRouteData,
            },
            {
                path: 'bluetooth',
                canDeactivate: [PendingChangesGuard],
                component: SettingsBluetoothComponent,
                data: { breadcrumb: { name: 'rift-settings-bluetooth', text: 'Bluetooth' } } as IRouteData,
            },
            {
                path: 'httppost',
                canDeactivate: [PendingChangesGuard],
                component: SettingsHttpPostComponent,
                data: { breadcrumb: { name: 'rift-settings-httppost', text: 'Http Post' } } as IRouteData,
            },
            {
                path: 'mqtt',
                canDeactivate: [PendingChangesGuard],
                component: SettingsMQTTComponent,
                data: { breadcrumb: { name: 'rift-settings-mqtt', text: 'MQTT' } } as IRouteData,
            },
            {
                path: 'bacnet',
                canDeactivate: [PendingChangesGuard],
                component: SettingsBACNETComponent,
                data: { breadcrumb: { name: 'rift-settings-bacnet', text: 'BACnet' }} as IRouteData,
            },
            {
                path: 'history',
                component: ConfigHistoryComponent,
                data: { breadcrumb: { name: 'rift-settings-history', text: 'History' } } as IRouteData
            },
        ]
    }
];
