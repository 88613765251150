import { Injectable } from '@angular/core';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { DeviceErrorModel } from '@rift/models/restapi/DeviceError.Model';
import { ErrorsAndWarningsModel } from '@rift/models/restapi/ErrorsAndWarnings.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { UnitGenerationEnum } from '@shared/enum/UnitGeneration.Enum';
import { AnyUtility } from '@shared/utility/Any.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';

// Device config interfaces

export interface IHttpPostConfig {
    authenticationToken: string;
    format: string[];
    maxAttempts: number;
    maxHistory: number;
    maxToPost: number;
    timeout: number;
    url: string;
}

export interface IPointConfig {
    x: number;
    y: number;
}

export interface ILineConfig {
    countMode: string;
    countOptions: string;
    description: string;
    id: number;
    points: IPointConfig[];
    statusActive: boolean;
    statusReserved: boolean;
}

export interface IPolygonConfig {
    id: number;
    description: string;
    points: IPointConfig[];
    statusActive: boolean;
}

export interface IHistogramConfig {
    binWidth: number;
    varExpr: string;
    minVal: number;
    nBins: number;
    logPeriod: number;
}

export interface IRegisterConfig {
    description: string;
    enabled: boolean;
    histograms: IHistogramConfig[];
    id: number;
    instantaneous: boolean;
    relayEnabled: boolean;
    rule: string;
    tags: string[];
    uuid: string;
}

export interface ICounterConfig {
    httpPost: IHttpPostConfig[];
    lines: ILineConfig[];
    polygons: IPolygonConfig[];
    registers: IRegisterConfig[];
}

export interface ITimeZoneConfig {
    enableDST: boolean;
    zone: string;
}

export interface INtpConfig {
    serverAddress: string;
}

export interface IGeneralConfig {
    deviceId: string;
    deviceName: string;
    productId: string;
    siteId: string;
    siteName: string;
    time: ITimeZoneConfig;
    userString: string;
    ntp: INtpConfig;
}

export interface IClientConfig {
    address: string;
    interval: number;
    port: number;
    timeout: number;
}

export interface IHttpConfig {
    enabled: boolean;
}

export interface IServerConfig {
    address: string;
    port: number;
}

export interface IIpConfig {
    client: IClientConfig[];
    defaultGateway: string;
    dhcpEnable: boolean;
    dnsServers: string[];
    gatewayPrefixLength: number;
    hostname: string;
    http: IHttpConfig;
    ipAddress: string;
    mtu: number;
    prefixLength: number;
    server: IServerConfig;
    subnetMask: string;
}

export interface ILogConfig {
    maxLogFileSize: number;
    logPeriod: number;
}

export interface ILogsConfig {
    application: ILogConfig;
    counter: ILogConfig;
    counts: ILogConfig;
    histograms: ILogConfig;
}

export interface IMqttTlsConfig {
    enabled: boolean;
    insecure: boolean;
}

export interface IMqttTopicConfig {
    period: number;
    topic: string;
    qos: number;
}

export interface IMqttBrokerConfig {
    address: string;
    keepAlive: number;
    maxHistory: number;
    maxSize: number;
    port: number;
    username: string;
    password: string;
    tls: IMqttTlsConfig;
    counts: IMqttTopicConfig;
    status: IMqttTopicConfig;
    targets: IMqttTopicConfig;
    live_counts: IMqttTopicConfig;
}

export interface IMqttPublishConfig {
    brokers: IMqttBrokerConfig[];
    format: string;
}

export interface IMqttConfig {
    publish: IMqttPublishConfig[];
}

export interface ICameraParamsConfig {
    mountingHeight: number;
    worldX: number;
    worldY: number;
    yaw: number;
}

export interface ITrackerConfig {
    lowerHeightLimit: number;
    upperHeightLimit: number;
    acceptableFrameBacklog: number;
    advancedFloorModel: boolean;
    cameraParams: ICameraParamsConfig;
    fakeTargetMode: number;
    heightWarningThreshold: number;
    masterMode: string;
    nodeList: string[];
}

export interface IConfig {
    counter: ICounterConfig;
    general: IGeneralConfig;
    ip: IIpConfig;
    logs: ILogsConfig;
    mqtt: IMqttConfig;
    tracker: ITrackerConfig;
}

// Result interfaces

export type sectionTypes =
    ICounterConfig |
    IClientConfig |
    IGeneralConfig |
    IHttpConfig |
    IHttpPostConfig |
    IIpConfig |
    ILineConfig |
    ILogConfig |
    ILogsConfig |
    IMqttBrokerConfig |
    IMqttConfig |
    IMqttTopicConfig |
    IMqttPublishConfig |
    IMqttTlsConfig |
    IPointConfig |
    IPolygonConfig |
    IRegisterConfig |
    IServerConfig |
    ITimeZoneConfig |
    ITrackerConfig |
    ICameraParamsConfig;


export enum ConfigSectionsEnum {
    counter,
    counter_HttpPost,
    counter_Line,
    counter_Line_Point,
    counter_Polygon,
    counter_Polygon_Point,
    counter_Register,
    counter_Register_Histogram,
    general,
    general_TimeZone,
    ip,
    ip_Client,
    ip_Server,
    ip_HttpServer,
    log,
    log_Application,
    log_Counter,
    log_Counts,
    log_Histograms,
    mqtt,
    mqtt_Publish,
    mqtt_Publish_Broker,
    mqtt_Publish_Broker_Tls,
    mqtt_Publish_Broker_Counts,
    mqtt_Publish_Broker_Status,
    mqtt_Publish_Broker_Targets,
    mqtt_Publish_Broker_LiveCounts,
    tracker,
    tracker_CameraParams
}

export interface IValueProperty {
    key: string;
    name: string;
}

export interface IGroupedConfigSection {
    section: ConfigSectionsEnum;
    children?: IGroupedConfigSection[];
    text?: string;
    show?: boolean;
    valueProperties?: IValueProperty[];
}

export class ConfigSectionsEnumHelpers {
    public static toArray(): ConfigSectionsEnum[] {
        return Object.keys(ConfigSectionsEnum).filter(key => !isNaN(Number(ConfigSectionsEnum[key]))).map(key => ConfigSectionsEnum[key]);
    }

    public static getGrouped(): IGroupedConfigSection[] {
        return [
            {
                section: ConfigSectionsEnum.counter,
                children: [
                    {
                        section: ConfigSectionsEnum.counter_HttpPost,
                        valueProperties: [
                            {
                                key: 'authenticationToken',
                                name: 'Authentication Token',
                            },
                            {
                                key: 'format',
                                name: 'format',
                            },
                            {
                                key: 'maxAttempts',
                                name: 'Max Attempts',
                            },
                            {
                                key: 'maxHistory',
                                name: 'Max History',
                            },
                            {
                                key: 'maxToPost',
                                name: 'Max To Post',
                            },
                            {
                                key: 'timeout',
                                name: 'Timeout',
                            },
                            {
                                key: 'url',
                                name: 'Url',
                            },
                        ]
                    },
                    {
                        section: ConfigSectionsEnum.counter_Line,
                        valueProperties: [
                            {
                                key: 'countMode',
                                name: 'Count Mode',
                            },
                            {
                                key: 'countOptions',
                                name: 'Count Options',
                            },
                            {
                                key: 'description',
                                name: 'Description',
                            },
                            {
                                key: 'statusActive',
                                name: 'Status Active',
                            },
                            {
                                key: 'statusReserved',
                                name: 'Status Reserved',
                            },
                        ],
                        children: [
                            {
                                section: ConfigSectionsEnum.counter_Line_Point,
                                valueProperties: [
                                    {
                                        key: 'x',
                                        name: 'X',
                                    },
                                    {
                                        key: 'y',
                                        name: 'Y',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        section: ConfigSectionsEnum.counter_Polygon,
                        valueProperties: [
                            {
                                key: 'description',
                                name: 'Description',
                            },
                            {
                                key: 'statusActive',
                                name: 'Status Active',
                            },
                        ],
                        children: [
                            {
                                section: ConfigSectionsEnum.counter_Polygon_Point,
                                valueProperties: [
                                    {
                                        key: 'x',
                                        name: 'X',
                                    },
                                    {
                                        key: 'y',
                                        name: 'Y',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        section: ConfigSectionsEnum.counter_Register,
                        valueProperties: [
                            {
                                key: 'description',
                                name: 'Description',
                            },
                            {
                                key: 'enabled',
                                name: 'Enabled',
                            },
                            {
                                key: 'instantaneous',
                                name: 'Instantaneous',
                            },
                            {
                                key: 'relayEnabled',
                                name: 'Relay Enabled',
                            },
                            {
                                key: 'rule',
                                name: 'rule',
                            },
                            {
                                key: 'tags',
                                name: 'Tags',
                            },
                            {
                                key: 'uuid',
                                name: 'UUID',
                            },
                        ],
                        children: [
                            {
                                section: ConfigSectionsEnum.counter_Register_Histogram,
                                valueProperties: [
                                    {
                                        key: 'binWidth',
                                        name: 'Bin Width',
                                    },
                                    {
                                        key: 'varExpr',
                                        name: 'Var Expr',
                                    },
                                    {
                                        key: 'minVal',
                                        name: 'Min Value',
                                    },
                                    {
                                        key: 'nBins',
                                        name: 'Number Of Bins',
                                    },
                                    {
                                        key: 'logPeriod',
                                        name: 'Log Period',
                                    },
                                ]
                            },
                        ],
                    },
                ]
            },
            {
                section: ConfigSectionsEnum.general,
                children: [
                    {
                        section: ConfigSectionsEnum.general_TimeZone,
                        valueProperties: [
                            {
                                key: 'enableDST',
                                name: 'Enable DST',
                            },
                            {
                                key: 'zone',
                                name: 'Zone',
                            },
                        ]
                    },
                ],
                valueProperties: [
                    {
                        key: 'deviceId',
                        name: 'Device Id',
                    },
                    {
                        key: 'deviceName',
                        name: 'Device Name',
                    },
                    {
                        key: 'productId',
                        name: 'Product Id',
                    },
                    {
                        key: 'siteId',
                        name: 'Site Id',
                    },
                    {
                        key: 'siteName',
                        name: 'Site Name',
                    },
                    {
                        key: 'userString',
                        name: 'User String',
                    },
                ],
            },
            {
                section: ConfigSectionsEnum.ip,
                valueProperties: [
                    {
                        key: 'defaultGateway',
                        name: 'Default Gateway',
                    },
                    {
                        key: 'dhcpEnable',
                        name: 'DHCP Enable',
                    },
                    {
                        key: 'dnsServers',
                        name: 'DNS Servers',
                    },
                    {
                        key: 'gatewayPrefixLength',
                        name: 'Gateway Prefix Length',
                    },
                    {
                        key: 'hostname',
                        name: 'Hostname',
                    },
                    {
                        key: 'ipAddress',
                        name: 'IP Address',
                    },
                    {
                        key: 'mtu',
                        name: 'MTU',
                    },
                    {
                        key: 'prefixLength',
                        name: 'Prefix Length',
                    },
                    {
                        key: 'subnetMask',
                        name: 'Subnet Mask',
                    },
                ],
                children: [
                    {
                        section: ConfigSectionsEnum.ip_Client,
                        valueProperties: [
                            {
                                key: 'address',
                                name: 'Address',
                            },
                            {
                                key: 'interval',
                                name: 'Interval',
                            },
                            {
                                key: 'port',
                                name: 'Port',
                            },
                            {
                                key: 'timeout',
                                name: 'Timeout',
                            },
                        ],
                    },
                    {
                        section: ConfigSectionsEnum.ip_Server,
                        valueProperties: [
                            {
                                key: 'address',
                                name: 'Address',
                            },
                            {
                                key: 'port',
                                name: 'Port',
                            },
                        ],
                    },
                    {
                        section: ConfigSectionsEnum.ip_HttpServer,
                        valueProperties: [
                            {
                                key: 'enabled',
                                name: 'Enabled',
                            },
                        ]
                    },
                ],
            },
            {
                section: ConfigSectionsEnum.log,
                children: [
                    {
                        section: ConfigSectionsEnum.log_Application,
                        valueProperties: [
                            {
                                key: 'maxLogFileSize',
                                name: 'Max Log File Size',
                            },
                            {
                                key: 'logPeriod',
                                name: 'Log Period',
                            },
                        ],
                    },
                    {
                        section: ConfigSectionsEnum.log_Counter,
                        valueProperties: [
                            {
                                key: 'maxLogFileSize',
                                name: 'Max Log File Size',
                            },
                            {
                                key: 'logPeriod',
                                name: 'Log Period',
                            },
                        ],
                    },
                    {
                        section: ConfigSectionsEnum.log_Counts,
                        valueProperties: [
                            {
                                key: 'maxLogFileSize',
                                name: 'Max Log File Size',
                            },
                            {
                                key: 'logPeriod',
                                name: 'Log Period',
                            },
                        ],
                    },
                    {
                        section: ConfigSectionsEnum.log_Histograms,
                        valueProperties: [
                            {
                                key: 'maxLogFileSize',
                                name: 'Max Log File Size',
                            },
                            {
                                key: 'logPeriod',
                                name: 'Log Period',
                            },
                        ],
                    },
                ],
            },
            {
                section: ConfigSectionsEnum.mqtt,
                children: [
                    {
                        section: ConfigSectionsEnum.mqtt_Publish,
                        valueProperties: [
                            {
                                key: 'format',
                                name: 'Format',
                            },
                        ],
                        children: [
                            {
                                section: ConfigSectionsEnum.mqtt_Publish_Broker,
                                valueProperties: [
                                    {
                                        key: 'address',
                                        name: 'Address',
                                    },
                                    {
                                        key: 'keepAlive',
                                        name: 'Keep Alive',
                                    },
                                    {
                                        key: 'maxHistory',
                                        name: 'Max History',
                                    },
                                    {
                                        key: 'maxSize',
                                        name: 'Max Size',
                                    },
                                    {
                                        key: 'port',
                                        name: 'Port',
                                    },
                                    {
                                        key: 'username',
                                        name: 'Username',
                                    },
                                    {
                                        key: 'password',
                                        name: 'Password',
                                    },
                                ],
                                children: [
                                    {
                                        section: ConfigSectionsEnum.mqtt_Publish_Broker_Counts,
                                        valueProperties: [
                                            {
                                                key: 'period',
                                                name: 'Period',
                                            },
                                            {
                                                key: 'topic',
                                                name: 'Topic',
                                            },
                                            {
                                                key: 'qos',
                                                name: 'QOS',
                                            },
                                        ],
                                    },
                                    {
                                        section: ConfigSectionsEnum.mqtt_Publish_Broker_LiveCounts,
                                        valueProperties: [
                                            {
                                                key: 'period',
                                                name: 'Period',
                                            },
                                            {
                                                key: 'topic',
                                                name: 'Topic',
                                            },
                                            {
                                                key: 'qos',
                                                name: 'QOS',
                                            },
                                        ],
                                    },
                                    {
                                        section: ConfigSectionsEnum.mqtt_Publish_Broker_Status,
                                        valueProperties: [
                                            {
                                                key: 'period',
                                                name: 'Period',
                                            },
                                            {
                                                key: 'topic',
                                                name: 'Topic',
                                            },
                                            {
                                                key: 'qos',
                                                name: 'QOS',
                                            },
                                        ],
                                    },
                                    {
                                        section: ConfigSectionsEnum.mqtt_Publish_Broker_Targets,
                                        valueProperties: [
                                            {
                                                key: 'period',
                                                name: 'Period',
                                            },
                                            {
                                                key: 'topic',
                                                name: 'Topic',
                                            },
                                            {
                                                key: 'qos',
                                                name: 'QOS',
                                            },
                                        ],
                                    },
                                    {
                                        section: ConfigSectionsEnum.mqtt_Publish_Broker_Tls,
                                        valueProperties: [
                                            {
                                                key: 'enabled',
                                                name: 'Enabled',
                                            },
                                            {
                                                key: 'insecure',
                                                name: 'Insecure',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                section: ConfigSectionsEnum.tracker,
                children: [
                    {
                        section: ConfigSectionsEnum.tracker_CameraParams,
                        valueProperties: [
                            {
                                key: 'mountingHeight',
                                name: 'Mounting Height',
                            },
                            {
                                key: 'worldX',
                                name: 'X',
                            },
                            {
                                key: 'worldY',
                                name: 'Y',
                            },
                            {
                                key: 'yaw',
                                name: 'Yaw',
                            },
                        ]
                    },
                ],
                valueProperties: [
                    {
                        key: 'lowerHeightLimit',
                        name: 'lower Height Limit',
                    },
                    {
                        key: 'upperHeightLimit',
                        name: 'Upper Height Limit',
                    },
                    {
                        key: 'acceptableFrameBacklog',
                        name: 'Acceptable Frame Backlog',
                    },
                    {
                        key: 'advancedFloorModel',
                        name: 'Advanced Floor Model',
                    },
                    {
                        key: 'fakeTargetMode',
                        name: 'Fake Target Mode',
                    },
                    {
                        key: 'heightWarningThreshold',
                        name: 'Height Warning Threshold',
                    },
                    {
                        key: 'masterMode',
                        name: 'Master Mode',
                    },
                    {
                        key: 'nodeList',
                        name: 'Node List',
                    },
                ],
            },
        ];
    }

    public static toDisplayName(value: ConfigSectionsEnum): string {
        switch (value) {
            case ConfigSectionsEnum.counter:
                return 'Counter';
            case ConfigSectionsEnum.counter_HttpPost:
                return 'Http Post';
            case ConfigSectionsEnum.counter_Line:
                return 'Line';
            case ConfigSectionsEnum.counter_Line_Point:
                return 'Point';
            case ConfigSectionsEnum.counter_Polygon:
                return 'Polygon';
            case ConfigSectionsEnum.counter_Polygon_Point:
                return 'Point';
            case ConfigSectionsEnum.counter_Register:
                return 'Register';
            case ConfigSectionsEnum.counter_Register_Histogram:
                return 'Histogram';
            case ConfigSectionsEnum.general:
                return 'General';
            case ConfigSectionsEnum.general_TimeZone:
                return 'Time Zone';
            case ConfigSectionsEnum.ip:
                return 'IP';
            case ConfigSectionsEnum.ip_Client:
                return 'Client';
            case ConfigSectionsEnum.ip_Server:
                return 'Server';
            case ConfigSectionsEnum.ip_HttpServer:
                return 'HTTP Server';
            case ConfigSectionsEnum.log:
                return 'Logs';
            case ConfigSectionsEnum.log_Application:
                return 'Application';
            case ConfigSectionsEnum.log_Counter:
                return 'Counter';
            case ConfigSectionsEnum.log_Counts:
                return 'Counts';
            case ConfigSectionsEnum.log_Histograms:
                return 'Histograms';
            case ConfigSectionsEnum.mqtt:
                return 'MQTT';
            case ConfigSectionsEnum.mqtt_Publish:
                return 'Publish';
            case ConfigSectionsEnum.mqtt_Publish_Broker:
                return 'Broker';
            case ConfigSectionsEnum.mqtt_Publish_Broker_Tls:
                return 'Tls';
            case ConfigSectionsEnum.mqtt_Publish_Broker_Counts:
                return 'Counts';
            case ConfigSectionsEnum.mqtt_Publish_Broker_LiveCounts:
                return 'Live Counts';
            case ConfigSectionsEnum.mqtt_Publish_Broker_Status:
                return 'Status';
            case ConfigSectionsEnum.mqtt_Publish_Broker_Targets:
                return 'Targets';
            case ConfigSectionsEnum.tracker:
                return 'Tracker';
            case ConfigSectionsEnum.tracker_CameraParams:
                return 'Camera Params';
        }
    }

    public static toGroupedDisplayName(value: ConfigSectionsEnum): string {
        switch (value) {
            case ConfigSectionsEnum.counter:
                return 'Counter';
            case ConfigSectionsEnum.counter_HttpPost:
                return 'Counter / Http Post';
            case ConfigSectionsEnum.counter_Line:
                return 'Counter / Line';
            case ConfigSectionsEnum.counter_Line_Point:
                return 'Counter / Line / Point';
            case ConfigSectionsEnum.counter_Polygon:
                return 'Counter / Polygon';
            case ConfigSectionsEnum.counter_Polygon_Point:
                return 'Counter / Polygon / Point';
            case ConfigSectionsEnum.counter_Register:
                return 'Counter / Register';
            case ConfigSectionsEnum.counter_Register_Histogram:
                return 'Counter / Register / Histogram';
            case ConfigSectionsEnum.general:
                return 'General';
            case ConfigSectionsEnum.general_TimeZone:
                return 'General / Time Zone';
            case ConfigSectionsEnum.ip:
                return 'IP';
            case ConfigSectionsEnum.ip_Client:
                return 'IP / Client';
            case ConfigSectionsEnum.ip_Server:
                return 'IP / Server';
            case ConfigSectionsEnum.ip_HttpServer:
                return 'IP / HTTP Server';
            case ConfigSectionsEnum.log:
                return 'Logs';
            case ConfigSectionsEnum.log_Application:
                return 'Logs / Application';
            case ConfigSectionsEnum.log_Counter:
                return 'Logs / Counter';
            case ConfigSectionsEnum.log_Counts:
                return 'Logs / Counts';
            case ConfigSectionsEnum.log_Histograms:
                return 'Logs / Histograms';
            case ConfigSectionsEnum.mqtt:
                return 'MQTT';
            case ConfigSectionsEnum.mqtt_Publish:
                return 'MQTT / Publish';
            case ConfigSectionsEnum.mqtt_Publish_Broker:
                return 'MQTT / Publish / Broker';
            case ConfigSectionsEnum.mqtt_Publish_Broker_Tls:
                return 'MQTT / Publish / Broker / Tls';
            case ConfigSectionsEnum.mqtt_Publish_Broker_Counts:
                return 'MQTT / Publish / Broker / Counts';
            case ConfigSectionsEnum.mqtt_Publish_Broker_LiveCounts:
                return 'MQTT / Publish / Broker / Live Counts';
            case ConfigSectionsEnum.mqtt_Publish_Broker_Status:
                return 'MQTT / Publish / Broker / Status';
            case ConfigSectionsEnum.mqtt_Publish_Broker_Targets:
                return 'MQTT / Publish / Broker / Targets';
            case ConfigSectionsEnum.tracker:
                return 'Tracker';
            case ConfigSectionsEnum.tracker_CameraParams:
                return 'Tracker / Camera Params';
        }
    }
}

export enum SectionActionEnum {
    update,
    add,
    remove,
    noChange,
}

export class SectionActionEnumHelpers {
    public static toDisplayName(value: SectionActionEnum): string {
        switch (value) {
            case SectionActionEnum.update:
                return 'updated';
            case SectionActionEnum.add:
                return 'added';
            case SectionActionEnum.remove:
                return 'removed';
            case SectionActionEnum.noChange:
                return 'no change';
        }
    }
}

export interface IArrayLengths {
    equal: boolean;
    fromLength: number;
    toLength: number;
}

export interface ISettingChange {
    key?: string;
    valueFrom?: any;
    valueTo?: any;
}

export interface ISectionChange {
    action: SectionActionEnum;
    indexFrom?: number;
    indexTo?: number;
    itemFrom?: sectionTypes;
    itemTo?: sectionTypes;
    section: ConfigSectionsEnum;
    sectionsChanged?: ISectionChange[];
    settingsChanged?: ISettingChange[];
    settingsUnChanged?: ISettingChange[];
}

export interface IConfigChanges {
    fromDate: Date;
    sectionsChanged: ISectionChange[];
    toDate: Date;
}

// Internal interfaces
interface ISubSectionOption {
    idKey?: string;
    isArray?: boolean;
    key: string;
    section: ConfigSectionsEnum;
    subSectionOptions?: ISubSectionOption[];
}

@Injectable()
export class ConfigJsonCompareService extends RiftBaseService {

    public changeFound: Subject<IConfigChanges> = new Subject();

    private _configTo: IConfig = null;
    private _configToDate: Date = null;

    public constructor() {
        super();
    }

    public checkLog(device: DeviceModel, logs: ErrorsAndWarningsModel, newestToOldest: boolean = true): void {
        if (device.isGen(UnitGenerationEnum.kestrel) || device.isGen(UnitGenerationEnum.falcon)) {
            const length = logs.information.length;
            for (let i = 0; i < length; i++) {
                const log = logs.information[i];
                if (log.message.indexOf('ConfigManager updated: config.json:') !== -1) {
                    this.loadConfigManagerLog(log, device, newestToOldest);
                }
            }
        }
    }

    private getCounterDeferences(from: ICounterConfig, to: ICounterConfig): ISectionChange[] {
        const sectionChange = this.getSectionChange(from, to, ConfigSectionsEnum.counter, [
            {
                key: 'httpPost',
                isArray: true,
                section: ConfigSectionsEnum.counter_HttpPost,
            },
            {
                key: 'lines',
                idKey: 'id',
                isArray: true,
                section: ConfigSectionsEnum.counter_Line,
                subSectionOptions: [
                    {
                        key: 'points',
                        isArray: true,
                        section: ConfigSectionsEnum.counter_Line_Point,
                    }
                ],
            },
            {
                key: 'polygons',
                idKey: 'id',
                isArray: true,
                section: ConfigSectionsEnum.counter_Polygon,
                subSectionOptions: [
                    {
                        key: 'points',
                        isArray: true,
                        section: ConfigSectionsEnum.counter_Polygon_Point,
                    }
                ],
            },
            {
                key: 'registers',
                idKey: 'id',
                isArray: true,
                section: ConfigSectionsEnum.counter_Register,
                subSectionOptions: [
                    {
                        key: 'histograms',
                        isArray: true,
                        section: ConfigSectionsEnum.counter_Register_Histogram,
                    }
                ],
            },
        ]);

        if (!isNullOrUndefined(sectionChange)) {
            return [sectionChange];
        }
        return [];
    }

    private getTrackerDeferences(from: ITrackerConfig, to: ITrackerConfig): ISectionChange[] {
        const sectionChange = this.getSectionChange(from, to, ConfigSectionsEnum.tracker, [
            {
                key: 'cameraParams',
                section: ConfigSectionsEnum.tracker_CameraParams
            }
        ]);

        if (!isNullOrUndefined(sectionChange)) {
            return [sectionChange];
        }
        return [];
    }

    private getGeneralDeferences(from: IGeneralConfig, to: IGeneralConfig): ISectionChange[] {
        const sectionChange = this.getSectionChange(from, to, ConfigSectionsEnum.general, [
            {
                key: 'time',
                section: ConfigSectionsEnum.general_TimeZone
            }
        ]);

        if (!isNullOrUndefined(sectionChange)) {
            return [sectionChange];
        }
        return [];
    }

    private getIpDeferences(from: IIpConfig, to: IIpConfig): ISectionChange[] {
        const sectionChange = this.getSectionChange(from, to, ConfigSectionsEnum.ip, [
            {
                key: 'client',
                isArray: true,
                section: ConfigSectionsEnum.ip_Client
            },
            {
                key: 'server',
                section: ConfigSectionsEnum.ip_Server
            },
        ]);

        if (!isNullOrUndefined(sectionChange)) {
            return [sectionChange];
        }
        return [];
    }

    private getLogDeferences(from: ILogsConfig, to: ILogsConfig): ISectionChange[] {
        const sectionChange = this.getSectionChange(from, to, ConfigSectionsEnum.log, [
            {
                key: 'application',
                section: ConfigSectionsEnum.log_Application
            },
            {
                key: 'counter',
                section: ConfigSectionsEnum.log_Counter
            },
            {
                key: 'counts',
                section: ConfigSectionsEnum.log_Counts
            },
            {
                key: 'histograms',
                section: ConfigSectionsEnum.log_Histograms
            },
        ]);

        if (!isNullOrUndefined(sectionChange)) {
            return [sectionChange];
        }
        return [];
    }

    private getMqttDeferences(from: IMqttConfig, to: IMqttConfig): ISectionChange[] {
        const sectionChange = this.getSectionChange(from, to, ConfigSectionsEnum.mqtt, [
            {
                key: 'publish',
                section: ConfigSectionsEnum.mqtt_Publish,
                subSectionOptions: [
                    {
                        key: 'brokers',
                        section: ConfigSectionsEnum.mqtt_Publish_Broker,
                        subSectionOptions: [
                            {
                                key: 'publish',
                                section: ConfigSectionsEnum.mqtt_Publish_Broker_Counts,
                            },
                            {
                                key: 'counts',
                                section: ConfigSectionsEnum.mqtt_Publish_Broker_LiveCounts,
                            },
                            {
                                key: 'status',
                                section: ConfigSectionsEnum.mqtt_Publish_Broker_Status,
                            },
                            {
                                key: 'targets',
                                section: ConfigSectionsEnum.mqtt_Publish_Broker_Targets,
                            },
                            {
                                key: 'live_counts',
                                section: ConfigSectionsEnum.mqtt_Publish_Broker_Tls,
                            },
                        ]
                    },
                ],
            }
        ]);

        if (!isNullOrUndefined(sectionChange)) {
            return [sectionChange];
        }
        return [];
    }

    private getSectionsChanges(from: IConfig, to: IConfig): ISectionChange[] {
        return [
            ...this.getCounterDeferences(from.counter, to.counter),
            ...this.getTrackerDeferences(from.tracker, to.tracker),
            ...this.getGeneralDeferences(from.general, to.general),
            ...this.getIpDeferences(from.ip, to.ip),
            ...this.getLogDeferences(from.logs, to.logs),
            ...this.getMqttDeferences(from.mqtt, to.mqtt),
        ];
    }

    private getFromToLengths(from: any[], to: any[]): IArrayLengths {
        const result = { fromLength: 0, toLength: 0, equal: false };
        if (!isNullOrUndefined(from)) {
            result.fromLength = from.length;
        }
        if (!isNullOrUndefined(to)) {
            result.toLength = to.length;
        }
        result.equal = result.fromLength === result.toLength;
        return result;
    }

    private getIdArraySectionChanges<TSection extends { id: number } & sectionTypes>(from: TSection[], to: TSection[], section: ConfigSectionsEnum, subSectionOptions?: ISubSectionOption[]): ISectionChange[] {
        if (!isNullOrUndefined(from) && !isNullOrUndefined(to)) {
            const sectionChanges: ISectionChange[] = [];
            const lengths = this.getFromToLengths(from, to);

            if (lengths.equal === true) {
                for (let indexFrom = 0; indexFrom < lengths.fromLength; indexFrom++) {
                    const itemFrom = from[indexFrom];
                    for (let indexTo = 0; indexTo < lengths.toLength; indexTo++) {
                        const itemTo = to[indexTo];

                        if (itemFrom.id === itemTo.id) {
                            const sectionChange = this.getSectionChange(itemFrom, itemTo, section, subSectionOptions);
                            if (!isNullOrUndefined(sectionChange)) {
                                sectionChange.indexFrom = indexFrom;
                                sectionChange.indexTo = indexTo;
                                sectionChanges.push(sectionChange);
                            }
                            break;
                        }
                    }
                }
            } else {
                const matchedIds: number[] = [];

                if (lengths.fromLength > lengths.toLength) {
                    for (let indexFrom = 0; indexFrom < lengths.fromLength; indexFrom++) {
                        const itemFrom = from[indexFrom];
                        let matched = false;

                        for (let indexTo = 0; indexTo < lengths.toLength; indexTo++) {
                            const itemTo = to[indexTo];

                            if (itemFrom.id === itemTo.id) {
                                matched = true;
                                if (matchedIds.indexOf(itemFrom.id) === -1) {
                                    const sectionChange = this.getSectionChange(itemFrom, itemTo, section, subSectionOptions);
                                    if (!isNullOrUndefined(sectionChange)) {
                                        sectionChange.indexFrom = indexFrom;
                                        sectionChange.indexTo = indexTo;
                                        sectionChanges.push(sectionChange);
                                    }
                                    matchedIds.push(itemFrom.id);
                                }
                                break;
                            }
                        }

                        if (matched === false) {
                            sectionChanges.push({ itemFrom, indexFrom, section, action: SectionActionEnum.remove });
                        }
                    }
                }

                if (lengths.fromLength < lengths.toLength) {
                    for (let indexFrom = 0; indexFrom < lengths.toLength; indexFrom++) {
                        const itemFrom = to[indexFrom];
                        let matched = false;

                        for (let indexTo = 0; indexTo < lengths.fromLength; indexTo++) {
                            const itemTo = from[indexTo];

                            if (itemTo.id === itemFrom.id) {
                                matched = true;
                                if (matchedIds.indexOf(itemTo.id) === -1) {
                                    const sectionChange = this.getSectionChange(itemFrom, itemTo, section, subSectionOptions);
                                    if (!isNullOrUndefined(sectionChange)) {
                                        sectionChange.indexFrom = indexFrom;
                                        sectionChange.indexTo = indexTo;
                                        sectionChanges.push(sectionChange);
                                    }

                                    matchedIds.push(itemTo.id);
                                }
                                break;
                            }
                        }

                        if (matched === false) {
                            sectionChanges.push({ itemTo: itemFrom, indexTo: indexFrom, section, action: SectionActionEnum.add });
                        }
                    }
                }
            }

            return sectionChanges;
        }
    }

    private getNonIdArraySectionChanges<TSection extends sectionTypes>(from: TSection[], to: TSection[], section: ConfigSectionsEnum, subSectionOptions?: ISubSectionOption[]): ISectionChange[] {
        const sectionChanges: ISectionChange[] = [];

        if (!isNullOrUndefined(from) && !isNullOrUndefined(to)) {
            const lengths = this.getFromToLengths(from, to);

            const length = lengths.toLength > lengths.fromLength ? lengths.toLength : lengths.fromLength;
            for (let i = 0; i < length; i++) {
                const itemFrom = from[i];
                const itemTo = to[i];
                if (!isNullOrUndefined(itemFrom) && !isNullOrUndefined(itemTo)) {
                    const sectionChange = this.getSectionChange(itemFrom, itemTo, section, subSectionOptions);
                    if (!isNullOrUndefined(sectionChange)) {
                        sectionChange.indexFrom = i;
                        sectionChange.indexTo = i;
                        sectionChanges.push(sectionChange);
                    }
                }
            }

            const removedCount = (lengths.fromLength - lengths.toLength);
            if (removedCount > 0) {
                const start = (lengths.fromLength - 1);
                const end = (lengths.toLength - 1);
                for (let i = start; i > end; i--) {
                    sectionChanges.push({ itemFrom: from[i], section, action: SectionActionEnum.remove });
                }
            }

            const addCount = (lengths.toLength - lengths.fromLength);
            if (addCount > 0) {
                const start = (lengths.toLength - 1);
                const end = (lengths.fromLength - 1);
                for (let i = start; i > end; i--) {
                    sectionChanges.push({ itemTo: to[i], section, action: SectionActionEnum.add });
                }
            }
        }

        return sectionChanges;
    }

    private getSectionChange(itemFrom: sectionTypes, itemTo: sectionTypes, section: ConfigSectionsEnum, subSectionOptions?: ISubSectionOption[]): ISectionChange {
        if (!isNullOrUndefined(itemFrom) && !isNullOrUndefined(itemTo)) {
            const sectionChange: ISectionChange = { itemFrom, itemTo, section, action: SectionActionEnum.update };
            const settingsChanged: ISettingChange[] = [];
            const configJsonKeys = Object.keys(itemFrom);
            const configJsonKeysLength = configJsonKeys.length;
            const checkForSubKeys = !isNullOrUndefined(subSectionOptions) && subSectionOptions.length > 0;

            for (let keyI = 0; keyI < configJsonKeysLength; keyI++) {
                const key = configJsonKeys[keyI];
                const changedFrom = itemFrom[key];
                const changedTo = itemTo[key];

                let subKeyFound: boolean = false;
                if (checkForSubKeys === true) {
                    const subSectionOption = subSectionOptions.find(o => o.key === key);
                    if (!isNullOrUndefined(subSectionOption)) {
                        if (isNullOrUndefined(sectionChange.sectionsChanged)) {
                            sectionChange.sectionsChanged = [];
                        }

                        if (isNullOrUndefined(subSectionOption.isArray) || subSectionOption.isArray === true) {
                            if (isNullOrUndefined(subSectionOption.idKey)) {
                                sectionChange.sectionsChanged.push(...this.getNonIdArraySectionChanges(changedFrom, changedTo, subSectionOption.section, subSectionOption.subSectionOptions));
                            } else {
                                sectionChange.sectionsChanged.push(...this.getIdArraySectionChanges(changedFrom, changedTo, subSectionOption.section, subSectionOption.subSectionOptions));
                            }
                        } else {
                            const subSectionChange = this.getSectionChange(changedFrom, changedTo, subSectionOption.section, subSectionOption.subSectionOptions);
                            if (!isNullOrUndefined(subSectionChange)) {
                                sectionChange.sectionsChanged.push(subSectionChange);
                            }
                        }

                        subKeyFound = true;
                    }
                }

                if (subKeyFound === false) {
                    if (!AnyUtility.equal(changedFrom, changedTo)) {
                        settingsChanged.push({ key, valueFrom: JSON.stringify(changedFrom), valueTo: JSON.stringify(changedTo) });
                    }
                }
            }

            if (settingsChanged.length > 0 || (!isNullOrUndefined(sectionChange.sectionsChanged) && sectionChange.sectionsChanged.length > 0)) {
                sectionChange.settingsChanged = settingsChanged;
                return sectionChange;
            }
        }
    }

    private loadConfigManagerLog(log: DeviceErrorModel, device: DeviceModel, newestToOldest: boolean): void {
        const configFrom: IConfig = JSON.parse(log.message.replace('ConfigManager updated: config.json:', ''));

        if (!isNullOrUndefined(this._configTo)) {
            const sectionsChanged = newestToOldest === true ? this.getSectionsChanges(configFrom, this._configTo) : this.getSectionsChanges(this._configTo, configFrom);
            if (sectionsChanged.length > 0) {
                const configChange: IConfigChanges = {
                    sectionsChanged,
                    fromDate: log.timestamp,
                    toDate: this._configToDate,
                };
                this.changeFound.next(configChange);
            }
        }

        this._configTo = configFrom;
        this._configToDate = log.timestamp;
    }
}
