import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, ElementRef, HostBinding, Injector, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceActivityModel } from '@em/models/restapi/DeviceActivity.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { ActivityService } from '@em/service/data/activity/Activity.Service';
import { DeviceService } from '@em/service/data/device/Device.Service';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { DeviceActivityActionEnum, DeviceActivityActionEnumHelpers } from '@shared/enum/DeviceActivityAction.Enum';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { ILoadDate } from '@shared/interface/ILoadData';
import { IViewModel } from '@shared/interface/IViewModel';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import * as FileSaver from 'file-saver';
import { BehaviorSubject, Observable, of, zip, timer } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { FileUtility } from '@shared/utility/File.Utility';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

class DeviceActivityViewModel implements IViewModel {
    public dayText: string;
    public isDay: boolean = false;
    public isMonth: boolean = false;
    public isTime: boolean = false;
    public isYear: boolean = false;
    public item: DeviceActivityModel;
    public monthText: string;
    public snapshotSrc: string;
    public yearText: string;

    public constructor(item?: DeviceActivityModel) {
        this.item = item;
    }
}

class DateRangeViewModel {
    public isSelected: boolean = true;
    public date: Date;
    public isYear: boolean = false;
    public isMonth: boolean = false;
    public yearText: string;
    public monthText: string;

    public constructor(date?: Date) {
        this.date = date;
    }
}

class ActivityActionViewModel {
    public isSelected: boolean = true;
    public text: string;

    public constructor(public readonly value: DeviceActivityActionEnum) {
        this.text = DeviceActivityActionEnumHelpers.getDisplayName(value);
    }
}

@Component({
    selector: 'em-rift-activity',
    templateUrl: './Rift.Activity.Component.html',
    styleUrls: ['./Rift.Activity.Component.scss']
})
export class RiftActivityComponent extends RiftBaseComponent implements IFillHeight, ILoadDate {
    public static className: string = 'RiftActivityComponent';

    public readonly AllTypeFilters: Array<DeviceActivityActionEnum> = [DeviceActivityActionEnum.backup, DeviceActivityActionEnum.connect, DeviceActivityActionEnum.disconnect, DeviceActivityActionEnum.errorState, DeviceActivityActionEnum.flashChange, DeviceActivityActionEnum.forcedRediscovery, DeviceActivityActionEnum.forwardConnection, DeviceActivityActionEnum.reDiscover, DeviceActivityActionEnum.snapshot, DeviceActivityActionEnum.systemSchedule, DeviceActivityActionEnum.userSchedule];

    public dateRanges: Array<DateRangeViewModel> = [];
    public obsActivityVMs = new BehaviorSubject<Array<DeviceActivityViewModel>>([]);
    public activities: Array<ActivityActionViewModel> = this.AllTypeFilters.map(i => new ActivityActionViewModel(i));
    public getActivityProcess: ProcessMonitorServiceProcess;
    public getDeviceBinaryDataProcess: ProcessMonitorServiceProcess;
    public monthFilterSelectedProcess: ProcessMonitorServiceProcess;
    public typeFilterSelectedToggleProcess: ProcessMonitorServiceProcess;
    public scrolledIndexChangeProcess: ProcessMonitorServiceProcess;
    public height: number = 0;
    public activityLoading: boolean = false;
    public isSmall: boolean = false;
    public showFilters: boolean = true;

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @ViewChild('virtualViewport', { static: true })
    public virtualViewport: CdkVirtualScrollViewport;

    @HostBinding()
    public id: string = 'em-rift-activity';

    public DeviceActivityActionEnum = DeviceActivityActionEnum;

    private _pageOptions = new PaginationOptionsModel();
    private _lastActivity: DeviceActivityModel;
    private _activityVMs: Array<DeviceActivityViewModel> = [];
    private _lastScrollIndex: number = 0;
    private _selectedMonthFilter: DateRangeViewModel = null;

    public constructor(
        private readonly _mediaObserver: MediaObserver,
        private readonly _sanitizer: DomSanitizer,
        private readonly _deviceService: DeviceService,
        private readonly _activityService: ActivityService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.addSubscription(this._mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
            if (!isNullOrUndefined(changes)){
                changes.forEach(change => {
                    if (change.mqAlias === 'xs' || change.mqAlias === 'sm') {
                        this.isSmall = true;
                    } else {
                        this.isSmall = false;
                    }
                });
            }
        }));

        this.loadDataProcess = this.processMonitorService.getProcess(RiftActivityComponent.className, this.loadDataProcessText);
        this.getActivityProcess = this.processMonitorService.getProcess(RiftActivityComponent.className, 'Getting activity page');
        this.getDeviceBinaryDataProcess = this.processMonitorService.getProcess(RiftActivityComponent.className, 'Getting device binary data');
        this.monthFilterSelectedProcess = this.processMonitorService.getProcess(RiftActivityComponent.className, 'Month filter selected');
        this.typeFilterSelectedToggleProcess = this.processMonitorService.getProcess(RiftActivityComponent.className, 'Type filter selected');
        this.scrolledIndexChangeProcess = this.processMonitorService.getProcess(RiftActivityComponent.className, 'Scrolled index changed');

        this._pageOptions.resultsPerPage = 100;
        this._pageOptions.page = 1;

        this.initConnectionState();
    }

    public toggleFilters(): void {
        this.showFilters = !this.showFilters;
    }

    public noneSelected(): boolean {
        return !this.activities.some(a => a.isSelected === true);
    }

    public filledHeight(height: number): void {
        if (this.height !== height) {
            this.height = height;
        }
        this.virtualViewport.checkViewportSize();
    }

    public onDownloadBackupClick(vm: DeviceActivityViewModel): void {
        this.addSubscription(this.observableHandlerBase(this.getHostDevice(), this.getDeviceBinaryDataProcess).subscribe(
            hostDevice => {
                this.addSubscription(this._deviceService.getBackup(hostDevice.serialNumber, vm.item.id, this.getDeviceBinaryDataProcess).subscribe(
                    backup => {
                        const blob = new Blob([backup.data], { type: 'text/plain;charset=utf-8' });
                        FileSaver.saveAs(blob, FileUtility.sanitize(backup.fileName));
                    }
                ), this.getDeviceBinaryDataProcess);
            }
        ), this.getDeviceBinaryDataProcess);
    }

    public scrolledIndexChange(index: number): void {
        if (index > this._lastScrollIndex + 90) {
            this._lastScrollIndex = index;
            this._pageOptions.page++;
            this.addSubscription(this.observableHandlerBase(this.getActivity(this.getActivityProcess), this.scrolledIndexChangeProcess).subscribe(), this.scrolledIndexChangeProcess);
        }
    }

    public typeFilterSelectAll(): void {
        if (this.activityLoading === false) {
            this._lastScrollIndex = 0;

            this.activities.forEach(i => i.isSelected = true);

            this._lastActivity = null;
            this._pageOptions.page = 1;
            this._activityVMs = [];
            this.obsActivityVMs.next([]);

            this.addSubscription(this.observableHandlerBase(this.getActivity(this.getActivityProcess), this.typeFilterSelectedToggleProcess).subscribe(), this.typeFilterSelectedToggleProcess);
        }
    }

    public typeFilterDeselectAll(): void {
        if (this.activityLoading === false) {
            this._lastScrollIndex = 0;

            this.activities.forEach(i => i.isSelected = false);

            this._lastActivity = null;
            this._pageOptions.page = 1;
            this._activityVMs = [];
            this.obsActivityVMs.next([]);
        }
    }

    public typeFilterSelectedToggle(activity: ActivityActionViewModel): void {
        if (this.activityLoading === false) {
            this._lastScrollIndex = 0;
            activity.isSelected = !activity.isSelected;

            const selected = this.activities.filter(i => i.isSelected === true);

            if (isNullOrUndefined(selected) || selected.length === 0) {
                activity.isSelected = true;
            }

            this._lastActivity = null;
            this._pageOptions.page = 1;
            this._activityVMs = [];
            this.obsActivityVMs.next([]);

            this.addSubscription(this.observableHandlerBase(this.getActivity(this.getActivityProcess), this.typeFilterSelectedToggleProcess).subscribe(), this.typeFilterSelectedToggleProcess);
        }
    }

    public monthFilterSelected(value: DateRangeViewModel): void {
        this._lastScrollIndex = 0;
        this.dateRanges.forEach(dr => dr.date.valueOf() < value.date.valueOf() ? dr.isSelected = true : dr.isSelected = false);
        value.isSelected = true;

        this._lastActivity = null;
        this._pageOptions.page = 1;
        this._activityVMs = [];
        this._selectedMonthFilter = value;
        this.obsActivityVMs.next([]);

        this.addSubscription(this.observableHandlerBase(this.getActivity(this.getActivityProcess), this.monthFilterSelectedProcess).subscribe(), this.monthFilterSelectedProcess);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this._activityService.clearCache();

        const loadDataSub = zip(
            this.deviceService.getHostDevice(this.connectionService.connectedToFriendlySerial).pipe(
                flatMap((hostDevice) => this._activityService.getActivitySummary(hostDevice.serialNumber, process).pipe(
                        map(result => {
                            if (!this.isNullOrUndefined(result)) {
                                let lastDate: Date;
                                if (!this.isNullOrUndefined(result.dates)) {
                                    const datesLength = result.dates.length;
                                    for (let dateIndex = 0; dateIndex < datesLength; dateIndex++) {
                                        const date = result.dates[dateIndex];

                                        let year: DateRangeViewModel;
                                        if (!this.isNullOrUndefined(date)) {
                                            if (this.isNullOrUndefined(lastDate)) {
                                                year = new DateRangeViewModel(new Date(new Date(date.getFullYear(), 12).valueOf() - 1));
                                                year.isYear = true;
                                                year.yearText = date.getFullYear().toString();
                                                this.dateRanges.push(year);
                                            } else if (lastDate.getFullYear() !== date.getFullYear()) {
                                                year = new DateRangeViewModel(new Date(new Date(date.getFullYear(), 12).valueOf() - 1));
                                                year.isYear = true;
                                                year.yearText = date.getFullYear().toString();
                                                this.dateRanges.push(year);
                                            }

                                            const month = new DateRangeViewModel(new Date(new Date(date.getFullYear(), date.getMonth() + 1).valueOf() - 1));
                                            month.isMonth = true;
                                            month.monthText = DateTimeUtility.toLongMonthName(date);
                                            this.dateRanges.push(month);
                                        }

                                        lastDate = date;
                                    }
                                }

                            }
                            return true;
                        })
                    ))
            )
        );

        this.addSubscription(this.observableHandlerBase(this.getActivity(this.getActivityProcess), this.getActivityProcess).subscribe(), this.getActivityProcess);

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    private getActivity(process: ProcessMonitorServiceProcess): Observable<boolean> {
        this.activityLoading = true;

        let date: Date = null;
        if (!this.isNullOrUndefined(this._selectedMonthFilter)) {
            date = this._selectedMonthFilter.date;
        }
        return this.deviceService.getHostDevice(this.connectionService.connectedToFriendlySerial).pipe(
            flatMap((hostDevice) => this._activityService.getActivityPage(hostDevice.serialNumber, this._pageOptions, date, this.activities.filter(i => i.isSelected).map(i => i.value), process).pipe(
                    flatMap(activityPageResult => {

                        if (!this.isNullOrUndefined(activityPageResult.items)) {
                            const itemsLength = activityPageResult.items.length;
                            for (let itemIndex = 0; itemIndex < itemsLength; itemIndex++) {
                                const deviceActivity = activityPageResult.items[itemIndex];
                                if (this.isNullOrUndefined(this._lastActivity)) {
                                    this.addYearActivity(deviceActivity);
                                    this.addMonthActivity(deviceActivity);
                                    this.addDayActivity(deviceActivity);
                                } else {
                                    if (deviceActivity.time.getFullYear() !== this._lastActivity.time.getFullYear()) {
                                        this.addYearActivity(deviceActivity);
                                    }
                                    if (deviceActivity.time.getMonth() !== this._lastActivity.time.getMonth()) {
                                        this.addMonthActivity(deviceActivity);
                                    }
                                    if (deviceActivity.time.getDate() !== this._lastActivity.time.getDate()) {
                                        this.addDayActivity(deviceActivity);
                                    }
                                }

                                const vm = new DeviceActivityViewModel(deviceActivity);
                                vm.isTime = true;
                                this._activityVMs.push(vm);

                                if (deviceActivity.action === DeviceActivityActionEnum.snapshot) {
                                    vm.snapshotSrc = `${this.configurationService.emRestApiBase}device/getdeviceactivitybinarydata/${hostDevice.serialNumber}/${deviceActivity.id}`;
                                }

                                this._lastActivity = deviceActivity;
                            }
                        }

                        const data = [];
                        data.push(...this._activityVMs);

                        this.obsActivityVMs.next(data);
                        this.activityLoading = false;

                        return of(true);
                    })
                )));
    }

    private addYearActivity(deviceActivity: DeviceActivityModel): void {
        const year = new DeviceActivityViewModel();
        year.isYear = true;
        year.yearText = deviceActivity.time.getFullYear().toString();
        this._activityVMs.push(year);
    }

    private addMonthActivity(deviceActivity: DeviceActivityModel): void {
        const month = new DeviceActivityViewModel();
        month.isMonth = true;
        month.monthText = DateTimeUtility.toShortMonthName(deviceActivity.time);
        this._activityVMs.push(month);
    }

    private addDayActivity(deviceActivity: DeviceActivityModel): void {
        const day = new DeviceActivityViewModel();
        day.isDay = true;
        day.dayText = DateTimeUtility.toDayOfMonth(deviceActivity.time);
        this._activityVMs.push(day);
    }
}
