import { REGISTER_BASE_EXCLUDE_PROPERTIES, RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterCompareLessThanModel extends IRegisterBaseModel {
    lessThanRegister: number;
    lessThanValue: number;
}

export class RegisterCompareLessThanModel extends RegisterBaseModel implements IRegisterCompareLessThanModel, IRestModel {
    public lessThanRegister: number = null;
    public lessThanValue: number = null;

    public constructor() {
        super();

        this.registerProperty('lessThanRegister');
        this.registerProperty('lessThanValue');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterCompareLessThanModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('lessThanRegister', this.lessThanRegister);
        this.setPropertyOriginalValue('lessThanValue', this.lessThanValue);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], [this.lessThanRegister], []);
    }
}
