import { NgZone, Directive } from '@angular/core';
import { TimeLineDurationView } from '@rift/components/shared/timeline/duration/TimeLine.Duration.View';
import { TimeLineRow, IUserCountClickEvent } from '@rift/components/shared/timeline/rows/TimeLine.Row';
import { TimeLineRowSelect } from '@rift/components/shared/timeline/rows/TimeLine.Row.Select';
import { TimeLineRowsSettings } from '@rift/components/shared/timeline/rows/TimeLine.Rows.Settings';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

@Directive()
export class TimeLineRows extends DisplayItem {
    public onHeaderVisibleUpdate: Subject<boolean> = new Subject<boolean>();
    public onRowBodyMouseDown: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onRowBodyDrag: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onRowBodyPressUp: Subject<DisplayItemMouseEvent> = new Subject<DisplayItemMouseEvent>();
    public onRowSelectedChanged: Subject<TimeLineRowSelect> = new Subject<TimeLineRowSelect>();
    public onSelectVisibleUpdate: Subject<boolean> = new Subject<boolean>();
    public onUserCountClick: Subject<IUserCountClickEvent> = new Subject<IUserCountClickEvent>();
    public onExpand: Subject<TimeLineRow> = new Subject<TimeLineRow>();
    public onCollapse: Subject<TimeLineRow> = new Subject<TimeLineRow>();

    private _headerVisible: boolean = true;
    private _indent: number = 0;
    private _isCollapsed: boolean = false;
    private _rows: Array<TimeLineRow> = [];
    private _selectVisible: boolean = true;
    private _systemCountsVisible: boolean = true;

    private _width: number = 0;

    public constructor(
        protected readonly _durationView: TimeLineDurationView,
        protected readonly _playService: ValidationPlayService,
        protected readonly _zone: NgZone) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            this.container.y = TimeLineRowsSettings.row.height + TimeLineRowsSettings.row.spacing;
            this.addEventHandlers();
        });
    }

    public add(row: TimeLineRow): void {
        row.rows.indent = this.indent + TimeLineRowsSettings.header.indent;

        if (row.rows.length === 0) {
            row.header.plusVisible = false;
            row.header.minusVisible = false;
        }

        row.resize(this.width);
        row.update();

        this.addSubscription(row.requireStageUpdate.subscribe(() => this.requireStageUpdate.next()));

        this.addSubscription(row.onCollapse.subscribe(event => this.onCollapse.next(event)));
        this.addSubscription(row.onExpand.subscribe(event => this.onExpand.next(event)));

        this.addSubscription(row.onBodyMouseDown.subscribe(event => this.onRowBodyMouseDown.next(event)));
        this.addSubscription(row.onBodyDrag.subscribe(event => this.onRowBodyDrag.next(event)));
        this.addSubscription(row.onBodyPressUp.subscribe(event => this.onRowBodyPressUp.next(event)));
        this.addSubscription(row.onUserCountClick.subscribe(event => this.onUserCountClick.next(event)));

        this.addSubscription(row.select.onSelectedChanged.subscribe(event => this.onRowSelectedChanged.next(event)));

        this._rows.push(row);
        this.container.addChild(row.container);
        this.requireStageUpdate.next();
    }

    public bringToFront(displayItem?: IDisplayItem): void {

    }

    public clear(): void {
        this._zone.runOutsideAngular(() => {
            const length = this._rows.length;
            for (let i = 0; i < length; i++) {
                const row = this._rows[i];
                row.onDestroy();
                this.container.removeChild(row.container);
            }
            this._rows = [];
        });
    }

    public collapse(): void {
        this._isCollapsed = true;
        this.container.visible = false;
        this.requireStageUpdate.next();
    }

    public expand(): void {
        this._isCollapsed = false;
        this.container.visible = true;
        this.requireStageUpdate.next();
    }

    public getFullHeight(): number {
        let height = 0;

        if (!this.isCollapsed) {
            const length = this.length;
            height += length * TimeLineRowsSettings.row.height;
            for (let i = 0; i < length; i++) {
                const rows = this.items[i].rows;
                if (rows.length > 0) {
                    height += rows.getFullHeight();
                }
            }
        }

        return height;
    }

    public get headerVisible(): boolean {
        return this._headerVisible;
    }

    public set headerVisible(value: boolean) {
        if (this._headerVisible !== value) {
            this._headerVisible = value;

            const length = this._rows.length;
            for (let i = 0; i < length; i++) {
                const row = this._rows[i];
                if (!isNullOrUndefined(row)) {
                    row.header.visible = value;
                }
            }

            this.onHeaderVisibleUpdate.next(value);
        }
    }

    public get indent(): number {
        return this._indent;
    }

    public set indent(value: number) {
        if (this._indent !== value) {
            this._indent = value;

            const length = this._rows.length;
            for (let i = 0; i < length; i++) {
                const row = this._rows[i];
                if (!isNullOrUndefined(row)) {
                    row.header.indent = value;
                }
            }
        }
    }

    public get isCollapsed(): boolean {
        return this._isCollapsed;
    }

    public get items(): Array<TimeLineRow> {
        return this._rows;
    }

    public get length(): number {
        return this._rows.length;
    }

    public mouseMove(stageX: number): void {
        const length = this.items.length;
        for (let i = 0; i < length; i++) {
            const row = this.items[i];
            if (!isNullOrUndefined(row)) {
                row.mouseMove(stageX);
            }
        }
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();

            this.clear();
        });
    }

    public resize(width: number): void {
        this._zone.runOutsideAngular(() => {
            this._width = width;
            const length = this._rows.length;
            for (let i = 0; i < length; i++) {
                const row = this._rows[i];
                if (!isNullOrUndefined(row)) {
                    row.resize(width);
                }
            }
        });
    }

    public get selectVisible(): boolean {
        return this._selectVisible;
    }

    public set selectVisible(value: boolean) {
        if (this._selectVisible !== value) {
            this._selectVisible = value;
            this.onSelectVisibleUpdate.next(value);
        }
    }

    public setOffsets(): number {
        const length = this._rows.length;
        let height = 0;
        for (let i = 0; i < length; i++) {
            const row = this._rows[i];
            if (row.visible === true) {
                row.container.y = height;
                height += TimeLineRowsSettings.row.height + TimeLineRowsSettings.row.spacing;
                if (row.rows.length > 0 && row.rows.container.visible === true) {
                    height += row.rows.setOffsets();
                }
            }
        }

        return height;
    }

    public setSelectEnabled(enabled: boolean): void {
        const length = this.length;
        for (let i = 0; i < length; i++) {
            this.items[i].select.enabled = enabled;
        }
    }

    public get systemCountsVisible(): boolean {
        return this._systemCountsVisible;
    }

    public set systemCountsVisible(value: boolean) {
        this._systemCountsVisible = value;
        const length = this._rows.length;
        for (let i = 0; i < length; i++) {
            const row = this._rows[i];
            row.body.systemCountsVisible = value;
            row.rows.systemCountsVisible = value;
        }
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            const length = this._rows.length;
            for (let i = 0; i < length; i++) {
                const row = this._rows[i];
                if (!isNullOrUndefined(row)) {
                    row.update();
                }
            }
            this.requireStageUpdate.next();
        });
    }

    public set userCountsVisible(value: boolean) {
        const length = this._rows.length;
        for (let i = 0; i < length; i++) {
            this._rows[i].body.userCountsVisible = value;
        }
    }

    public get width(): number {
        return this._width;
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
        });
    }

    private removeEventHandlers(): void {
        super.onDestroy();
    }
}
