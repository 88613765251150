import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { RegisterBaseCollectionModel } from '@rift/models/restapi/RegisterBaseCollection.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable } from 'rxjs';
import { last, map } from 'rxjs/operators';
import { RegisterActionInfoModel } from '@rift/models/restapi/RegisterActionInfo.Model';
import { RegisterActionInfoCollectionModel } from '@rift/models/restapi/RegisterActionInfoCollection.Model';

@Injectable()
export class RestApiRegistersService extends RiftRestApiService {
    private _controller = 'registers';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public getActions(process?: ProcessMonitorServiceProcess): Observable<RegisterActionInfoCollectionModel> {
        return this.get(`${this._controller}/actions`, RegisterActionInfoCollectionModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public addRegister(register: RegisterBaseModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post(`${this._controller}`, register, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        ).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public copyRegister(id: number, name: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<null, ResultModel>(`${this._controller}/copyregister`, null, ResultModel, this.getTokenParams(['registerIndex', id.toString(), 'name', name]), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteRegister(id: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.delete(`${this._controller}/${id.toString()}`, ResultModel, this.getTokenParams(), process, { excludeStatusCodes: [422] }).pipe(
            map(result => result)
        );
    }

    public getRegister(id: number, process?: ProcessMonitorServiceProcess): Observable<RegisterBaseModel> {
        const url = `${this._config.riftV1RestApiBase}${this._controller}/${id.toString()}`;
        const req = new HttpRequest('GET', url, {
            responseType: 'json',
            params: this.getTokenParams(),
            reportProgress: true,
            withCredentials: true
        });

        return this._httpClient.request(req).pipe(
            map((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Response:
                        return RegisterBaseUtility.getRegisterTypeFromRestApiModel(event.body);
                    case HttpEventType.DownloadProgress:
                        if (!isNullOrUndefined(process)) {
                            this.handleDownloadProgress(event, process);
                        }
                        break;
                    case HttpEventType.UploadProgress:
                        if (!isNullOrUndefined(process)) {
                            this.handleUploadProgress(event, process);
                        }
                        break;
                }
            }),
            last()
        ).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getRegisters(process?: ProcessMonitorServiceProcess): Observable<RegisterBaseCollectionModel> {
        return this.get<RegisterBaseCollectionModel>(`${this._controller}`, RegisterBaseCollectionModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateRegisters(registers: RegisterBaseModel[], process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put(`${this._controller}`, registers, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateRegister(register: RegisterBaseModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put(`${this._controller}/${register.registerIndex.toString()}`, register, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public modifyRegisterValue(id: number, incrementDecrement: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<number, ResultModel>(`${this._controller}/${id.toString()}/artificially_modify_value`, incrementDecrement, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
