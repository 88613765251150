import { MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, Output, HostBinding } from '@angular/core';
import { RestoreBackupSelectBaseComponent } from '@rift/components/restorebackup/base/RestoreBackup.SelectBase.Component';
import { RestoreBackupFileDialogComponent, RestoreBackupFileDialogData } from '@em/components/rift/restorebackup/filedialog/RestoreBackup.FileDialog.Component';

@Component({
    selector: 'em-rift-restore-backup-file-select',
    templateUrl: './RestoreBackup.FileSelect.Component.html',
    styleUrls: ['./RestoreBackup.FileSelect.Component.scss'],
})
export class RestoreBackupFileSelectComponent extends RestoreBackupSelectBaseComponent {
    public static className: string = 'RestoreBackupFileSelectComponent';

    @HostBinding()
    public id: string = 'em-rift-restore-backup-file-select';

    // tslint:disable-next-line: no-output-on-prefix
    @Output()
    public onDataLoaded: EventEmitter<Uint8Array> = new EventEmitter<Uint8Array>();

    public constructor(
        private readonly _dialog: MatDialog,
    ) {
        super();
    }

    public onSelectFileClick(): void {
        const dialogRef = this._dialog.open(RestoreBackupFileDialogComponent, { data: new RestoreBackupFileDialogData(), disableClose: true, maxWidth: 600, minWidth: 600, maxHeight: 600 });
        dialogRef.componentInstance.onDataLoaded.subscribe(result => {
            dialogRef.close();
            this.onDataLoaded.emit(result);
        });
    }
}
