import { FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class FormGroupTrackerCollection {
    private _formGroups: Array<FormGroup> = [];

    public constructor() {
    }

    public clear(): void {
        this._formGroups = [];
    }

    public get forms(): Array<FormGroup> {
        return this._formGroups;
    }

    public markAllAsTouched(): void {
        if (!isNullOrUndefined(this.forms)) {
            this.forms.forEach(f => f.markAllAsTouched());
        }
    }

    public track(item: FormGroup): void {
        this._formGroups.push(item);
        item.markAllAsTouched();
    }

    public get valid(): boolean {
        return this._formGroups.every(formGroup => formGroup.disabled === true || this.formGroupValid(formGroup) === true);
    }

    private abstractControlValid(abstractControl: AbstractControl): boolean {
        if (abstractControl.disabled === true) {
            return true;
        }

        if (abstractControl instanceof FormArray) {
            return this.formArrayValid(abstractControl);
        } else if (abstractControl instanceof FormGroup) {
            return this.formGroupValid(abstractControl);
        } else if (abstractControl instanceof FormControl) {
            return this.formControlValid(abstractControl);
        } else {
            return false;
        }
    }

    private formArrayValid(formArray: FormArray): boolean {
        if (formArray.disabled === true) {
            return true;
        }

        const length = formArray.length;
        for (let i = 0; i < length; i++) {
            if (this.abstractControlValid(formArray.at(i)) === false) {
                return false;
            }
        }

        return true;
    }

    private formControlValid(formControl: FormControl): boolean {
        return (formControl.disabled === true || formControl.valid === true);
    }

    private formGroupValid(formGroup: FormGroup): boolean {
        return formGroup.disabled === true || (formGroup.valid === true && Object.keys(formGroup.controls).every(controlName => this.abstractControlValid(formGroup.controls[controlName]) === true));
    }
}
