<div #mainContent
id="em-rift-location"
    class="em-rift-location main-content scroll-content"
    fxLayout="column">
    <mat-card class="spacing-bottom">
        <mat-card-title>
            Location
        </mat-card-title>
        <mat-card-content>
            <form [formGroup]="locationFormGroup">
                <mat-form-field class="spacing-top"
                    fxFlex>
                    <input type="text"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        matInput
                        #location
                        formControlName="location"
                        placeholder="e.g. Tithe Barn Way, Northampton, UK"
                        #search>
                    <span matSuffix>{{ updateLocation?.propertyHasChangesText('location') }}</span>
                    <mat-error>{{getFormControlErrorMessage(locationFormGroup.controls['location'])}}</mat-error>
                </mat-form-field>
            </form>
        </mat-card-content>
    </mat-card>
    <google-map *ngIf="googleMapsPresent()" 
        class="gmaps"
        width=100%        
        [center]="{lat: isNullOrUndefined(updateLocation) ? defaultLatitude : updateLocation?.latitude, lng: isNullOrUndefined(updateLocation) ? defaultLongitude : updateLocation?.longitude}"
        [zoom]="defaultZoom"
        style="height: 700px;">
        <map-marker [position]="{lat: updateLocation?.latitude, lng: updateLocation?.longitude}"></map-marker>
    </google-map>
</div>