import { AfterViewInit, Component, ComponentFactoryResolver, EventEmitter, HostBinding, Injector, Input, NgZone, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';
import { BottomInfoStateEnum } from '@shared/component/bottominfo/BottomInfo.State.Enum';
import { ConnectionStatusEnum } from '@shared/enum/ConnectionStatus.Enum';
import { BreadCrumbService } from '@shared/service/breadcrumb/BreadCrumb.Service';
import { NavBarService } from '@shared/service/navbar/NavBar.Service';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { VersionService } from '@shared/service/version/Version.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';

/**
 * The navigation bar component.
 *
 * @export
 * @class NavBarComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 * @implements {AfterViewInit}
 */
@Component({
    selector: 'shr-navbar',
    templateUrl: './NavBar.Component.html',
    styleUrls: ['./NavBar.Component.scss'],
})
export class NavBarComponent extends BaseComponent implements OnInit, AfterViewInit {
    public static className: string = 'NavBarComponent';

    @HostBinding()
    public id: string = 'shr-navbar';

    /**
     * The count of connection to device in rift.
     *
     * @type {number}
     * @memberof NavBarComponent
     */
    @Input()
    public connectionCount: number = null;

    /**
     * The text the add after the breadcrumbs.
     *
     * @type {string}
     * @memberof NavBarComponent
     */
    @Input()
    public postBreadCrumbText: string = null;

    /**
     * The text the add before the breadcrumbs.
     *
     * @type {string}
     * @memberof NavBarComponent
     */
    @Input()
    public preBreadCrumbText: string = null;

    /**
     * True if the device is connected to em. else false.
     *
     * @type {boolean}
     * @memberof NavBarComponent
     */
    @Input()
    public emDeviceConnected: boolean;

    /**
     * The material icon to use to the actions menu.
     *
     * @type {boolean}
     * @memberof NavBarComponent
     */
    @Input()
    public actionsMatIcon: string;

    /**
     * True if the home icon should be shown.
     *
     * @type {boolean}
     * @memberof NavBarComponent
     */
    @Input()
    public get showHomeIcon(): boolean {
        return this._showHomeIcon;
    }
    public set showHomeIcon(value: boolean) {
        this._showHomeIcon = coerceBooleanProperty(value);
    }

    @Input()
    public get showLogo(): boolean {
        return this._showLogo;
    }
    public set showLogo(value: boolean) {
        this._showLogo = coerceBooleanProperty(value);
    }

    @Input()
    public get logoSrc(): string {
        return this._logoSrc;
    }
    public set logoSrc(value: string) {
        this._logoSrc = value;
    }

    @Input()
    public get logoWidth(): number {
        return this._logoWidth;
    }
    public set logoWidth(value: number) {
        this._logoWidth = coerceNumberProperty(value);
    }

    @Input()
    public get logoHeight(): number {
        return this._logoHeight;
    }
    public set logoHeight(value: number) {
        this._logoHeight = coerceNumberProperty(value);
    }

    /**
     * True if the menu draw toggle should be shown.
     *
     * @type {boolean}
     * @memberof NavBarComponent
     */
    @Input()
    public showMenuDrawerToggle: boolean = true;

    /**
     * True if rift is currently open. will show rift info on the nav bar.
     *
     * @type {boolean}
     * @memberof NavBarComponent
     */
    @Input()
    public get showRiftInfo(): boolean {
        return this._showRiftInfo;
    }
    public set showRiftInfo(value: boolean) {
        this._showRiftInfo = coerceBooleanProperty(value);
    }

    /**
     * True if rift is currently open. will show rift show Connection Count on the nav bar.
     *
     * @type {boolean}
     * @memberof NavBarComponent
     */
    @Input()
    public get showConnectionCount(): boolean {
        return this._showConnectionCount;
    }
    public set showConnectionCount(value: boolean) {
        this._showConnectionCount = coerceBooleanProperty(value);
    }

    /**
     * The title text.
     *
     * @type {string}
     * @memberof NavBarComponent
     */
    @Input()
    public text: string;

    /**
     * Fires when the draw toggle is clicked.
     *
     * @memberof NavBarComponent
     */
    @Output()
    public drawerToggleClicked = new EventEmitter();

    /**
     * Fires when the home icon or title is clicked.
     *
     * @memberof NavBarComponent
     */
    @Output()
    public homeClick = new EventEmitter();

    @ViewChild('actionMenu', { static: true })
    public actionMenu: MatMenu;

    @ViewChild('actionHost', { static: true, read: ViewContainerRef })
    public actionHost: ViewContainerRef;

    @ViewChild(MatMenuTrigger, { static: false })
    public actionMenuTrigger: MatMenuTrigger;

    public actions: Array<NavBarAction> = [];
    public actionButtonShow: boolean = false;
    public actionMenuShow: boolean = false;
    public showNotification: boolean = false;
    public showRiftInfoChangeProcess: ProcessMonitorServiceProcess;
    public riftConnectionStateChangeProcess: ProcessMonitorServiceProcess;
    public emDeviceConnectedChangeProcess: ProcessMonitorServiceProcess;
    public riftConnectionCountChangeProcess: ProcessMonitorServiceProcess;
    public forcedRiftConnectionStateChangedProcess: ProcessMonitorServiceProcess;
    public actionAddedProcess: ProcessMonitorServiceProcess;
    public actionRemovedProcess: ProcessMonitorServiceProcess;
    public addActionComponentProcess: ProcessMonitorServiceProcess;
    public ConnectionStatusEnum = ConnectionStatusEnum;
    public riftConnectionState: ConnectionStatusEnum;
    public BottomInfoStateEnum = BottomInfoStateEnum;
    public showMenuVersion: boolean = true;
    public showVersion: boolean = true;
    public emUnLicensed: boolean = false;
    public emLicenseServerUnavailable: boolean = false;
    public emDeviceUnLicensed: boolean = false;
    public emLastConnectedText: string;

    private _menuComponents: Array<any> = [];
    private _showHomeIcon: boolean = true;
    private _logoHeight: number;
    private _logoWidth: number;
    private _showLogo: boolean = false;
    private _logoSrc: string;
    private _showRiftInfo: boolean;
    private _showConnectionCount: boolean;

    public constructor(
        public readonly breadCrumbService: BreadCrumbService,
        public readonly versionService: VersionService,
        private readonly _mediaObserver: MediaObserver,
        private readonly _navBarService: NavBarService,
        private readonly _matDialog: MatDialog,
        private readonly _navBarActionService: NavBarActionService,
        private readonly _zone: NgZone,
        private readonly _injector: Injector) {
        super(_injector);

        this.addSubscription(this._mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
            if (!isNullOrUndefined(changes)){
                changes.forEach(change => {
                    if (change.mqAlias === 'xl') {
                        this.showMenuVersion = false;
                        this.showVersion = true;
                    } else {
                        this.showMenuVersion = true;
                        this.showVersion = false;
                    }
                });
            }
        }));

        this.showRiftInfoChangeProcess = this.processMonitorService.getProcess(NavBarComponent.className, 'Show Rift Info Change');
        this.riftConnectionStateChangeProcess = this.processMonitorService.getProcess(NavBarComponent.className, 'Rift Connection State Change');
        this.emDeviceConnectedChangeProcess = this.processMonitorService.getProcess(NavBarComponent.className, 'Em Device Connected Change');
        this.riftConnectionCountChangeProcess = this.processMonitorService.getProcess(NavBarComponent.className, 'Rift Connection Count Change');
        this.forcedRiftConnectionStateChangedProcess = this.processMonitorService.getProcess(NavBarComponent.className, 'Forced Rift Connection State Changed');
        this.actionAddedProcess = this.processMonitorService.getProcess(NavBarComponent.className, 'Action Added');
        this.actionRemovedProcess = this.processMonitorService.getProcess(NavBarComponent.className, 'Action Removed');
        this.addActionComponentProcess = this.processMonitorService.getProcess(NavBarComponent.className, 'Add Action Component');

        this.addSubscription(
            this.userNotificationService.bottomInfoCountChange.subscribe(
                count => {
                    this.showNotification = count > 0;
                }
            )
        );
    }

    public get bottomInfoStateColor(): string {
        switch (this.userNotificationService.bottomInfoCurrentState) {
            case BottomInfoStateEnum.info:
                return '#ffffff';
            case BottomInfoStateEnum.warn:
                return '#ffff00';
            case BottomInfoStateEnum.error:
                return '#ff0000';
            default:
                return '#ffffff';
        }
    }

    public onHelpTourClicked(): void {
        this._navBarService.helpTourClicked.next(null);
    }

    public actionButtonClicked(event: any, action: NavBarAction): void {
        if (action.hasDialogComponent) {
            action.dialogComponentRef = this._matDialog.open(action.dialogComponent);
        } else {
            action.onButtonClick.next();
        }
    }

    public actionClicked(): void {
        if (this.autoActions.length === 1 && this.autoActions[0].hasDialogComponent) {
            this.autoActions[0].dialogComponentRef = this._matDialog.open(this.autoActions[0].dialogComponent);
        } else if (this.autoActions.length === 1 && this.autoActions[0].hasMenuComponent) {
            if (!isNullOrUndefined(this.actionMenuTrigger)) {
                this.actionMenuTrigger.openMenu();
            }
        } else if (this.autoActions.length === 1) {
            this.autoActions[0].onButtonClick.next();
        } else if (this.autoActions.length > 1) {
            if (!isNullOrUndefined(this.actionMenuTrigger)) {
                this.actionMenuTrigger.openMenu();
            }
        }
    }

    public get actionButtons(): Array<NavBarAction> {
        const actionButtons: NavBarAction[] = [];

        const length = this.actions.length;
        for (let index = 0; index < length; index++) {
            const item = this.actions[index];
            if (item.hasDialogComponent === false && item.hasMenuComponent === false && item.isSaveChanges === false) {
                actionButtons.push(item);
            }
        }

        return actionButtons.sort((a, b) => a.order - b.order);
    }

    public get autoActions(): Array<NavBarAction> {
        const autoActions: NavBarAction[] = [];

        const length = this.actions.length;
        for (let index = 0; index < length; index++) {
            const item = this.actions[index];
            if (item.isSaveChanges === false) {
                autoActions.push(item);
            }
        }

        return autoActions;
    }

    public get firstAutoAction(): NavBarAction {
        const actions = this.autoActions;
        if (actions.length > 0) {
            return actions[0];
        } else {
            return null;
        }
    }

    public get saveAllChangesAction(): NavBarAction {
        return this.actions.find(i => i.isSaveChanges === true);
    }

    public menuDrawerToggle(): void {
        this.drawerToggleClicked.next();
    }

    public ngAfterViewInit() {
        if (this._menuComponents.length !== this.actions.length) {
            this.loadActionComponents();
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.showRiftInfo = this._navBarService.showRiftInfo;
        this.riftConnectionState = this._navBarService.riftConnectionState;
        this.emDeviceConnected = this._navBarService.emDeviceConnected;
        this.connectionCount = this._navBarService.riftConnectionCount;
        this.emLastConnectedText = DateTimeUtility.nowToRelativeDuration(this._navBarService.emLastConnected);

        const length = this._navBarActionService.actions.length;
        for (let index = 0; index < length; index++) {
            const action = this._navBarActionService.actions[index];
            this.actions.push(action);
            this.addActionComponent(action);
            this.onActionsChanged();
        }

        this.addSubscription(this.observableHandlerBase(this._navBarService.showRiftInfoChange, this.showRiftInfoChangeProcess).subscribe(value => {
            this._zone.run(() =>{
                this.showRiftInfo = value;
            });
        }));

        this.addSubscription(this.observableHandlerBase(this._navBarService.riftConnectionStateChange, this.riftConnectionStateChangeProcess).subscribe(value => {
            this.riftConnectionState = value;
        }));

        this.addSubscription(this.observableHandlerBase(this._navBarService.emDeviceConnectedChange, this.emDeviceConnectedChangeProcess).subscribe(value => {
            this.emDeviceConnected = value;
        }));

        this.addSubscription(this.observableHandlerBase(this._navBarService.riftConnectionCountChange, this.riftConnectionCountChangeProcess).subscribe(value => {
            this.connectionCount = value;
        }));

        this.addSubscription(this.observableHandlerBase(this._navBarService.forcedRiftConnectionStateChanged, this.forcedRiftConnectionStateChangedProcess).subscribe(value => {
            this.riftConnectionState = value;
        }));

        this.addSubscription(this._navBarService.postBreadCrumbTextChanged.subscribe(value => {
            this.postBreadCrumbText = value;
        }));

        this.addSubscription(this._navBarService.preBreadCrumbTextChanged.subscribe(value => {
            this.preBreadCrumbText = value;
        }));

        this.addSubscription(this._navBarService.emUnLicensedChanged.subscribe(
            value => {
                this.emUnLicensed = value;
            }
        ));

        this.addSubscription(this._navBarService.emLicenseServerUnavailableChanged.subscribe(
            value => {
                this.emLicenseServerUnavailable = value;
            }
        ));

        this.addSubscription(this._navBarService.emDeviceUnLicensedChanged.subscribe(
            value => {
                this.emDeviceUnLicensed = value;
            }
        ));

        this.addSubscription(this._navBarService.emLastConnectedChanged.subscribe(
            value => {
                this.emLastConnectedText = DateTimeUtility.nowToRelativeDuration(value);
            }
        ));

        this.addSubscription(this.observableHandlerBase(this._navBarActionService.actionAdded, this.actionAddedProcess).subscribe(
            added => {
                this.actions.push(added);
                this.addActionComponent(added);
                this.onActionsChanged();
            }
        ));

        this.addSubscription(this.observableHandlerBase(this._navBarActionService.actionRemoved, this.actionRemovedProcess).subscribe(
            removed => {
                const index = this.actions.findIndex(i => i.name === removed.name);
                if (index !== -1) {
                    this.actions.splice(index, 1);
                    this.removeActionComponent(removed);
                    this.onActionsChanged();
                }
            }
        ));
    }

    public onHomeClick(): void {
        this.homeClick.next();
    }

    public riftConnectionStateToggle(event: MatSlideToggleChange): void {
        if (event.checked === true) {
            this._navBarService.setRiftConnectionState(ConnectionStatusEnum.online);
        } else {
            this._navBarService.setRiftConnectionState(ConnectionStatusEnum.offline);
        }
    }

    public saveAllChangesClicked(): void {
        this.saveAllChangesAction.onButtonClick.next();
    }

    private addActionComponent(action: NavBarAction) {
        if (action.hasMenuComponent) {
            if (!isNullOrUndefined(this.actionHost)) {
                // Create component dynamically inside the ng-template
                const component = this.actionHost.createComponent(action.menuComponent);
                if (!this.isNullOrUndefined(component.instance)) {
                    component.instance.parentMenu = this.actionMenu;
                }
                action.menuComponentRef = component;

                // Push the component so that we can keep track of which components are created
                this._menuComponents.push(component);

                if (!isNullOrUndefined(component.instance.closeMenu)) {
                    this.observableHandlerBase(component.instance.closeMenu, this.addActionComponentProcess).subscribe(() => {
                        if (!isNullOrUndefined(this.actionMenuTrigger)) {
                            this.actionMenuTrigger.closeMenu();
                        }
                    });
                }
            }
        }
    }

    private loadActionComponents(): void {
        const actionsLength = this.actions.length;
        for (let ai = 0; ai < actionsLength; ai++) {
            const action = this.actions[ai];
            if (!isNullOrUndefined(action.menuComponent)) {
                const componentRef = this._menuComponents.find((component) => component.instance instanceof action.menuComponent);
                const componentIndex = this._menuComponents.indexOf(componentRef);

                if (componentIndex === -1) {
                    this.addActionComponent(action);
                }
            }
        }
    }

    private onActionsChanged(): void {
        const actionsLength = this.actions.length;
        if (actionsLength === 0) {
            this.actionButtonShow = false;
            this.actionMenuShow = false;
        } else if (actionsLength === 1) {
            const action = this.actions[0];
            if (action.hasDialogComponent) {
                this.actionButtonShow = true;
                this.actionMenuShow = false;
            } else if (action.hasMenuComponent) {
                this.actionButtonShow = false;
                this.actionMenuShow = true;
            } else {
                this.actionButtonShow = false;
                this.actionMenuShow = false;
            }
        } else if (actionsLength > 1) {
            this.actionButtonShow = false;
            this.actionMenuShow = true;
        }
    }

    private removeActionComponent(action: NavBarAction) {
        if (action.menuComponent) {
            if (!isNullOrUndefined(this.actionHost)) {
                const componentRef = this._menuComponents.find((component) => component.instance instanceof action.menuComponent);
                const componentIndex = this._menuComponents.indexOf(componentRef);

                if (componentIndex !== -1) {
                    // Remove component from both view and array
                    this.actionHost.remove(this.actionHost.indexOf(componentRef.hostView));
                }

                this._menuComponents.splice(componentIndex, 1);
            }
        }
    }
}
