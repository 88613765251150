<div (shrClickOutside)="onClickOutside()"
    class="search-results">
    <div class="group"
        *ngFor="let group of groupsWithResults">
        <div class="header">
            {{ group.name }}
        </div>
        <div class="result"
            *ngFor="let result of getGroupResults(group)">
            <ng-template [ngIf]="!isNullOrUndefined(result.deviceSearchResult)">
                <mat-card class="card device"
                    fxFlex
                    (click)="deviceClick(result.deviceSearchResult.device)">
                    <mat-card-title fxLayout="row"
                        fxLayoutAlign="start center">
                        <shr-fa matTooltip="Connected"
                            class="spacing-right"
                            *ngIf="result.deviceSearchResult.device.isConnected === true"
                            name="sign-in-alt"></shr-fa>
                        <shr-fa matTooltip="Has Error"
                            class="spacing-right error"
                            *ngIf="result.deviceSearchResult.device.state === DeviceStateEnum.error"
                            name="exclamation-triangle"></shr-fa>
                        <span>{{result.deviceSearchResult.device.friendlySerial}}</span>
                    </mat-card-title>
                    <mat-card-content>
                        <div>
                            Matched Data Items
                        </div>
                        <div class="data-item-matches"
                            *ngFor="let match of result.deviceSearchResult.propertiesMatched"
                            fxLayout="row">
                            <div class="di-header">
                                {{AdvSearchSettingPropertyEnumHelpers.toDisplayString(match.type)}}
                            </div>
                            <div class="separator">:</div>
                            <div class="value">
                                {{match.valueMatched}}
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-template>
            <ng-template [ngIf]="!isNullOrUndefined(result.searchItem)">
                <mat-card class="card search-item"
                    fxFlex
                    (click)="searchItemClick(result.searchItem)">
                    <mat-card-title fxLayout="row"
                        fxLayoutAlign="start center">
                        <mat-icon *ngIf="!isNullOrUndefined(result.searchItem.matIcon)"
                            class="spacing-right">
                            {{result.searchItem.matIcon}}</mat-icon>
                        <shr-fa *ngIf="!isNullOrUndefined(result.searchItem.faIcon)"
                            class="spacing-right"
                            [name]="result.searchItem.faIcon"></shr-fa>
                        <span>{{result.searchItem.name}}</span>
                    </mat-card-title>
                    <mat-card-subtitle>
                        <span>{{result.searchItem.description}}</span>
                    </mat-card-subtitle>
                </mat-card>
            </ng-template>
        </div>
    </div>
</div>