import { isBoolean, isNumber, isString } from '@shared/utility/General.Utility';

/**
 * Helper methods for boolean.
 *
 * @export
 * @class BooleanUtility
 */
export class BooleanUtility {
    /**
     * Converts a string boolean to boolean
     *
     * @static
     * @param {any} value The value to convert to boolean.
     * @returns {boolean} The boolean value
     * @memberof BooleanUtility
     */
    public static toBoolean(value: any): boolean {
        if (isBoolean(value)) {
            return value;
        } else if (isString(value)) {
            const lowerValue = value.toLocaleLowerCase();
            switch (lowerValue) {
                case '1':
                case 'true':
                    return true;
                case '0':
                case 'false':
                    return false;
            }
        } else if (isNumber(value)) {
            switch (value) {
                case 1:
                    return true;
                case 0:
                    return false;
            }
        }
    }
}
