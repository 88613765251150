import { EventPreventDefaultBase } from '@shared/base/EventPreventDefaultBase';
import { IDisplayItem, IDisplayItemMouseEvent } from '@shared/generic/canvas/IDisplayItem';

export class DisplayItemMouseEvent extends EventPreventDefaultBase implements IDisplayItemMouseEvent {
    public displayItem: IDisplayItem = null;
    public lastDragLocation: createjs.Point = null;
    public mouseEvent: createjs.MouseEvent = null;
    public moveTo: createjs.Point = null;

    public constructor(displayItem: IDisplayItem, event: createjs.MouseEvent) {
        super();
        this.displayItem = displayItem;
        this.mouseEvent = event;
    }
}
