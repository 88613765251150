import { Component, EventEmitter, Injector, HostBinding } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { IBottomInfoChildComponent } from '@shared/component/bottominfo/IBottomInfoChild.Component';
import { BottomInfoStateEnum } from '@shared/component/bottominfo/BottomInfo.State.Enum';
import { of, Observable } from 'rxjs';

/**
 * File download info component.
 *
 * @export
 * @class BottomInfoFileDownloadComponent
 * @extends {RiftBaseComponent}
 * @implements {IBottomInfoChildComponent}
 */
@Component({
    selector: 'shr-bottominfo-file-download-component',
    templateUrl: './BottomInfo.FileDownload.Component.html',
    styleUrls: ['./BottomInfo.FileDownload.Component.scss'],
})
export class BottomInfoFileDownloadComponent extends RiftBaseComponent implements IBottomInfoChildComponent {
    public close: EventEmitter<any> = new EventEmitter<any>();
    public cancel: EventEmitter<any> = new EventEmitter<any>();
    public stateChanged: EventEmitter<BottomInfoStateEnum> = new EventEmitter<BottomInfoStateEnum>();
    public instanceId: number = null;

    @HostBinding()
    public id: string = 'shr-bottominfo-file-download-component';

    public fileName: string;
    public downloadProgress: number = 0;
    public showCancel: boolean = true;

    private _state: BottomInfoStateEnum = BottomInfoStateEnum.info;
    public get state(): BottomInfoStateEnum {
        return this._state;
    }
    public set state(value: BottomInfoStateEnum) {
        this._state = value;
        this.stateChanged.emit(this._state);
    }

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
    }

    public get notificationCount(): Observable<number> {
        return of(1);
    }

    public cancelDownload(): void {
        this.cancel.emit();
    }
}
