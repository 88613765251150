import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseService } from '@shared/base/Base.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';

export interface INewConnectionSettings {
    hostname?: string;
    ip?: string;
    port?: number;
    dhcp?: boolean;
    paths?: string[];
    pleaseWaitDialogs?: MatDialogRef<PleaseWaitDialogComponent>[];
}

@Injectable()
export class EventsService extends BaseService {
    public onAddressBookChanged: Subject<void> = new Subject();
    public onConfirmRiftGoOffline: Subject<boolean> = new Subject<boolean>();
    public onConnectionForwardingChanged: Subject<void> = new Subject();
    public onDeviceRegisterAdded: Subject<void> = new Subject();
    public onDeviceRegisterCopied: Subject<void> = new Subject();
    public onDeviceRegisterDeleted: Subject<void> = new Subject();
    public onDeviceRegisterFlip: Subject<void> = new Subject();
    public onDeviceRegisterReset: Subject<void> = new Subject();
    public onDeviceRegisterSnapped: Subject<void> = new Subject();
    public onDeviceRegisterUpdated: Subject<void> = new Subject();
    public onDeviceUpdated: Subject<void> = new Subject();
    public onLicenceChanged: Subject<void> = new Subject();
    public onMetaDataKeysChanged: Subject<void> = new Subject();
    public onNotificationsChanged: Subject<void> = new Subject();
    public onOutboundConnectionsChanged: Subject<void> = new Subject();
    public onSchedulesChanged: Subject<void> = new Subject();
    public onTasksChanged: Subject<void> = new Subject();
    public onUsersChanged: Subject<void> = new Subject();
    public onNetworkSettingsChanged: Subject<void> = new Subject();
    public onNetworkSettingsConnectionChanged: Subject<INewConnectionSettings> = new Subject();

    private _registeredConfirmRiftGoOffline: Array<{ id: number; response: boolean }> = [];
    private _onBeforeRiftGoOffline: Subject<void> = new Subject();

    public constructor() {
        super();
    }

    public beforeRiftGoOffline(): void {
        this._onBeforeRiftGoOffline.next();
    }

    public unregisterConfirmRiftGoOffline(id: number): void {
        const index = this._registeredConfirmRiftGoOffline.findIndex(i => i.id === id);
        if (index !== -1) {
            this._registeredConfirmRiftGoOffline.splice(index, 1);
        }
    }

    public onBeforeRiftGoOffline(id: number): Subject<void> {
        this._registeredConfirmRiftGoOffline.push({ id, response: null });
        return this._onBeforeRiftGoOffline;
    }

    public confirmRiftGoOffline(canGoOffline: boolean, id: number): void {
        const state = this._registeredConfirmRiftGoOffline.find(i => i.id === id);
        state.response = canGoOffline;

        if (!this._registeredConfirmRiftGoOffline.some(i => isNullOrUndefined(i.response))) {
            const finalCanGoOffline = this._registeredConfirmRiftGoOffline.every(i => i.response === true);
            this._registeredConfirmRiftGoOffline.forEach(i => i.response = null);
            this.onConfirmRiftGoOffline.next(finalCanGoOffline);
        }
    }

    public changedNetworkSettingsConnection(newSettings: INewConnectionSettings): void {
        this.onNetworkSettingsConnectionChanged.next(newSettings);
    }

    public changedNetworkSettings(): void {
        this.onNetworkSettingsChanged.next();
    }

    public addedDeviceRegister(): void {
        this.onDeviceRegisterAdded.next();
    }

    public changedAddressBook(): void {
        this.onAddressBookChanged.next();
    }

    public changedConnectionForwarding(): void {
        this.onConnectionForwardingChanged.next();
    }

    public changedLicence(): void {
        this.onLicenceChanged.next();
    }

    public changedMetaDataKeys(): void {
        this.onAddressBookChanged.next();
    }

    public changedNotifications(): void {
        this.onNotificationsChanged.next();
    }

    public changedOutboundConnections(): void {
        this.onOutboundConnectionsChanged.next();
    }

    public changedSchedules(): void {
        this.onSchedulesChanged.next();
    }

    public changedTasks(): void {
        this.onTasksChanged.next();
    }

    public changedUsers(): void {
        this.onUsersChanged.next();
    }

    public copyDeviceRegister(): any {
        this.onDeviceRegisterCopied.next();
    }

    public deleteDeviceRegister(): any {
        this.onDeviceRegisterDeleted.next();
    }

    public deviceUpdated(): void {
        this.onDeviceUpdated.next();
    }

    public flipDeviceRegister(): any {
        this.onDeviceRegisterFlip.next();
    }

    public resetDeviceRegister(): any {
        this.onDeviceRegisterDeleted.next();
    }

    public updatedDeviceRegister(): void {
        this.onDeviceRegisterUpdated.next();
    }

    public snappedDeviceRegister(): void {
        this.onDeviceRegisterSnapped.next();
    }
}
