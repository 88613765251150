import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanDeleteEndPointModel } from '@em/models/restapi/CanDeleteEndPoint.Model';
import { EndPointModel } from '@em/models/restapi/EndPoint.Model';
import { EndPointCollectionModel } from '@em/models/restapi/EndPointCollection.Model';
import { EndPointTestResponseModel } from '@em/models/restapi/EndPointTestResponse.Model';
import { NotificationCollectionModel } from '@em/models/restapi/NotificationCollection.Model';
import { NotificationRuleModel } from '@em/models/restapi/NotificationRule.Model';
import { ProfileResponseModel } from '@em/models/restapi/ProfileResponse.Model';
import { EmRestApiService } from '@em/service/restapi/base/EmRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class RestApiNotificationsService extends EmRestApiService {
    private _controller = 'notifications/';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.emRestApiBase, _httpClient);
    }

    public addEndPoint(endPoint: EndPointModel, process?: ProcessMonitorServiceProcess): Observable<EndPointModel> {
        return this.post<EndPointModel, EndPointModel>(`${this._controller}endpoints`, endPoint, EndPointModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public addRule(rule: NotificationRuleModel, process?: ProcessMonitorServiceProcess): Observable<NotificationRuleModel> {
        return this.post<NotificationRuleModel, NotificationRuleModel>(`${this._controller}rules`, rule, NotificationRuleModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public canDeleteEndPoint(endPointId: number, process?: ProcessMonitorServiceProcess): Observable<CanDeleteEndPointModel> {
        let params = new HttpParams();
        params = params.append('endPointId', StringUtility.toString(endPointId));

        return this.get<CanDeleteEndPointModel>(`${this._controller}candeleteendpoint`, CanDeleteEndPointModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public clearFailureCache(notificationName: string, endPointId: number, process?: ProcessMonitorServiceProcess): Observable<null> {
        let params = new HttpParams();
        params = params.append('notificationName', notificationName);
        params = params.append('endPointId', StringUtility.toString(endPointId));

        return this.post<null, null>(`${this._controller}endpointtest`, null, null, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteEndPoint(endPointId: number, process?: ProcessMonitorServiceProcess): Observable<null> {
        let params = new HttpParams();
        params = params.append('endPointId', StringUtility.toString(endPointId));

        return this.delete(`${this._controller}endpoints`, 'null', params, process);
    }

    public deleteRule(ruleId: number, process?: ProcessMonitorServiceProcess): Observable<null> {
        let params = new HttpParams();
        params = params.append('ruleId', StringUtility.toString(ruleId));

        return this.delete(`${this._controller}rules`, 'null', params, process);
    }

    public getEndPoints(process?: ProcessMonitorServiceProcess): Observable<EndPointCollectionModel> {
        return this.get<EndPointCollectionModel>(`${this._controller}endpoints`, EndPointCollectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getNotifications(process?: ProcessMonitorServiceProcess): Observable<NotificationCollectionModel> {
        return this.get<NotificationCollectionModel>(`${this._controller}`, NotificationCollectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getProfile(notificationName: string, endPointId: number, process?: ProcessMonitorServiceProcess): Observable<ProfileResponseModel> {
        let params = new HttpParams();
        params = params.append('notificationName', notificationName);
        params = params.append('endPointId', StringUtility.toString(endPointId));

        return this.get<ProfileResponseModel>(`${this._controller}getprofile`, ProfileResponseModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public testEndPoint(endPoint: EndPointModel, process?: ProcessMonitorServiceProcess): Observable<EndPointTestResponseModel> {
        return this.post<EndPointModel, EndPointTestResponseModel>(`${this._controller}endpointtest`, endPoint, EndPointTestResponseModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateEndPoint(endPoint: EndPointModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this.put<EndPointModel, null>(`${this._controller}endpoints`, endPoint, 'null', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateRule(rule: NotificationRuleModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this.put<NotificationRuleModel, null>(`${this._controller}rules`, rule, 'null', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
