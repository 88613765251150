<div #container
    *ngIf="show === true && canShow === true"
    class="float-dialog rift-settings-counting-edit-register"
    (click)="onMenuClick()"
    ngDraggable
    #draggable="ngDraggable"
    (started)="onDrStarted($event)"
    (stopped)="onDrStopped($event)"
    (edge)="onDrEdge($event)"
    (movingOffset)="onDrMovingOffset($event)"
    (endOffset)="onDrEndOffset($event)"
    [handle]="title"
    [bounds]="bounds"
    [position]="position"
    [inBounds]="!isNullOrUndefined(bounds)">
    <div #title
        class="title drag-handle"
        fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex
            fxLayout="row"
            fxLayoutAlign="start center">
            <div fxLayout="column">
                <div class="header">
                    Tracking
                </div>
            </div>
            <div fxFlex></div>
            <div class="actions">
                <button class="flat-icon-button"
                    [disabled]="saveAllChangesProcess.isRunning === true"
                    mat-stroked-button
                    matTooltip="Close"
                    (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="edit-content"
            fxLayout="column">
            <div style="align-content: center; text-align: center;">
                <canvas id="integrity-canvas"
                    width="{{settings.canvas.width}}"
                    height="{{settings.canvas.height}}"></canvas>
            </div>
            <div fxLayout="row"
                fxLayoutAlign="center center">
                Grouping
            </div>
            <div fxLayout="row"
                fxLayoutAlign="center center">
                <mat-slider [(ngModel)]="state.sliderValue"
                    (change)="onIntegrityChange($event)"
                    (input)="onIntegrityChange($event)"
                    [min]="settings.range.min"
                    [max]="settings.range.max"
                    [step]="settings.slider.step"
                    [disabled]="isReadOnly === true"
                    fxFlex>
                </mat-slider>
                {{isNullOrUndefined(discrimination) ? '0.0' : discrimination.integrity.toFixed(0)}}
            </div>
            <form [formGroup]="form"
                fxFlex>
                <div fxLayout="row"
                    fxLayoutAlign="center center">
                    Extended Range
                </div>
                <div fxLayout="row"
                    fxLayoutAlign="center center">
                    <div>
                        Disabled
                    </div>
                    <div class="slide">
                        <mat-slide-toggle formControlName="isExtended"></mat-slide-toggle>
                    </div>
                    <div>
                        Enabled
                    </div>
                </div>
                <div fxLayout="row"
                    fxLayoutAlign="center center">
                    Couple Counting
                </div>
                <div fxLayout="row"
                    fxLayoutAlign="center center">
                    <div>
                        Disabled
                    </div>
                    <div class="slide">
                        <mat-slide-toggle formControlName="isCoupling"></mat-slide-toggle>
                    </div>
                    <div>
                        Enabled
                    </div>
                </div>
            </form>
        </div>
        <div class="actions">
            <button mat-raised-button
                (click)="onSaveClick()"
                [disabled]="isNullOrUndefined(discrimination) || isValid === false || hasChanges === false">
                Save Tracking
            </button>
        </div>
    </div>
</div>