import { NgZone, Directive } from '@angular/core';
import { TimeLineRowsSettings } from '@rift/components/shared/timeline/rows/TimeLine.Rows.Settings';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { IRegLineColorSettings, Settings, GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { ColorUtility } from '@shared/utility/Color.Utility';
import { Subject } from 'rxjs';

@Directive()
export class TimeLineRowSelect extends DisplayItem {
    public onSelectedChanged: Subject<TimeLineRowSelect> = new Subject<TimeLineRowSelect>();

    private _background: createjs.Shape = new createjs.Shape();
    private _enabled: boolean = true;
    private _grabBox: createjs.Shape = new createjs.Shape();
    private _regLineColorSettings: IRegLineColorSettings = null;
    private _selected: createjs.Bitmap = new createjs.Bitmap('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAVoGAAFaBgBBlhoRwAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4xNzNun2MAAAG5SURBVFhH7ZfLKkVRHIcPiVxChqYGSgaGLmXgDTyBFzAUE6Vcy8iAidsbGDIwlEyYMBBJXsFAcuf7tnadTvucjbM2pf3VV5aW5bfXf6299irk5ASgBjtxGvdwP6C7OI7t+CUMcoRv+J6Br7iDLZiKM2KQO5zFiYAu4D06/himYml8AoNkwQY6/lrUSsHa2tknyYIVdPz1qJXCvw7ThO7QmD8L04MHOIi1/gL+JEw/nqJjHWK8lX89TDdeoOPcYC/GpfrVMAY5Rse4wgEMuma60JdUXdQqj6WJZ+Qa+7A4iFQVxlqf4AtOYiMm4WKN14gzYpAkqgrj1lzCJ3zAGWzAYobxHP0710hpaYqpukzOziY+4yNOYT2KM2IQz5tLLF6sSQRZwK3oQM6Q/3gOR/AM7W+QIawURIKEkWb0gPMzwJLdon1Lt28lgoWRNtxCZ8h+Sdu3EkHDiDtqHi1N0vatRPAwYsk6Pn/8FpmE+Sl5mHJ8K4zXCTsvRq3wbKPjr0atFLzX+B7xK96PZ58klAbx/eQ5N4qpeMHyXpPVvckgyxgfJ6l4FvnJ4NvW2obS0jgjXw6Sk1OeQuEDvpT5IhkCCfEAAAAASUVORK5CYII=');
    private _unselected: createjs.Bitmap = new createjs.Bitmap('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwQAADsEBuJFr7QAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4xNzNun2MAAADVSURBVFhH7ZcrDkIxFESLQSHQ7IoVsAEkEkUCAvUEGEjYARIEC2BFhCD4zam/6ROtm5Mc0aRppr2mk4ypwEBO5FJe5a2iFzmXY9kLgtzlV/4a+JFnOZJFeBGCPORKLiq6lk/J+TNZhNFwA4K04Cg5f59XBZgtm7lJCzrJ+Ye8KuAwEQ4T4TARDhPhMBEOE+EwEQ4T4TAR1Ak2b/KqPifJ+bu8KkCvoU7wi+fzzE1qSZCXfMupLELBote06k0E2cqh7AUFi15DnWC2tWQ0vEjvIMbEpPQH3WjIe4Lps6YAAAAASUVORK5CYII=');

    public constructor(
        protected readonly _registerBase: RegisterBaseModel,
        protected readonly _playService: ValidationPlayService,
        protected readonly _zone: NgZone) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            this._regLineColorSettings = Settings.register.colors[this._registerBase.registerIndex];

            if (this.enabled) {
                this._selected.visible = false;
                this._unselected.visible = true;
            } else {
                this._selected.visible = false;
                this._unselected.visible = false;
            }

            this._selected.image.onload = () => {
                const bounds = this._selected.getBounds();
                this._selected.regX = bounds.width / 2;
                this._selected.regY = bounds.height / 2;
                this._selected.x = TimeLineRowsSettings.select.width / 2;
                this._selected.y = TimeLineRowsSettings.row.height / 2;
                this._selected.image.onload = null;
            };

            this._unselected.image.onload = () => {
                const bounds = this._unselected.getBounds();
                this._unselected.regX = bounds.width / 2;
                this._unselected.regY = bounds.height / 2;
                this._unselected.x = TimeLineRowsSettings.select.width / 2;
                this._unselected.y = TimeLineRowsSettings.row.height / 2;
                this._unselected.image.onload = null;
            };

            this.container.addChild(this._grabBox);
            this.container.addChild(this._background);
            this.container.addChild(this._selected);
            this.container.addChild(this._unselected);

            this.addEventHandlers();
        });
    }

    public bringToFront(displayItem?: IDisplayItem): void {

    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();

            this.container.removeAllChildren();

            this._background.uncache();
            this._grabBox.uncache();

            this._background = null;
            this._grabBox = null;
            this._selected = null;
            this._unselected = null;
        });
    }

    public set enabled(value: boolean) {
        if (this._enabled !== value) {
            this._enabled = value;
            if (this.enabled) {
                this.isSelected = this.isSelected;
            } else {
                this._selected.visible = false;
                this._unselected.visible = false;
            }
            if (this.enabled) {
                this._grabBox.cursor = 'pointer';
            } else {
                this._grabBox.cursor = null;
            }
            this.requireStageUpdate.next();
        }
    }

    public set isSelected(value: boolean) {
        if (value === true) {
            this._selected.visible = true;
            this._unselected.visible = false;
        } else if (value === false) {
            this._selected.visible = false;
            this._unselected.visible = true;
        }
        this.requireStageUpdate.next();
        this.onSelectedChanged.next(this);
    }

    public set visible(value: boolean) {
        if (this.container.visible !== value) {
            this.container.visible = value;
            this.requireStageUpdate.next();
        }
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            this.drawBackground();
            this.drawGrabBox();
            this.requireStageUpdate.next();
        });
    }
    public get enabled(): boolean {
        return this._enabled;
    }
    public get isSelected(): boolean {
        return this._selected.visible;
    }
    public get visible(): boolean {
        return this.container.visible;
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this._grabBox.on('click', this.click.bind(this));
        });
    }

    private click(): void {
        if (this.enabled) {
            this.isSelected = !this.isSelected;
        }
    }

    private drawBackground(): void {
        const graphics = this._background.graphics;
        const startColor = ColorUtility.hexToRGBA(this._regLineColorSettings.fillColor, TimeLineRowsSettings.select.backgroundStartAlpha);
        const endColor = ColorUtility.hexToRGBA(this._regLineColorSettings.fillColor, TimeLineRowsSettings.select.backgroundEndAlpha);

        graphics.clear();
        graphics.beginRadialGradientFill([startColor, endColor], [1, 0], TimeLineRowsSettings.select.width / 2, TimeLineRowsSettings.row.height / 2, 0, TimeLineRowsSettings.select.width / 2, TimeLineRowsSettings.row.height / 2, TimeLineRowsSettings.select.width);
        graphics.drawRoundRect(0, 0, TimeLineRowsSettings.select.width, TimeLineRowsSettings.row.height, 6);
        graphics.endFill();

        this._background.cache(0, 0, TimeLineRowsSettings.select.width, TimeLineRowsSettings.row.height, GLOBAL_CACHE_SCALE);
    }

    private drawGrabBox(): void {
        const graphics = this._grabBox.graphics;

        graphics.clear();
        graphics.beginFill(ColorUtility.hexToRGBA(Settings.grabBox.fillColor, Settings.grabBox.lineAlpha));
        graphics.rect(0, 0, TimeLineRowsSettings.select.width, TimeLineRowsSettings.row.height);
        graphics.endFill();

        this._grabBox.cache(0, 0, TimeLineRowsSettings.select.width, TimeLineRowsSettings.row.height, GLOBAL_CACHE_SCALE);
    }

    private removeEventHandlers(): void {
        super.onDestroy();
    }
}
