import { Component, Inject, Injector, OnInit, ViewChild, HostBinding } from '@angular/core';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { IValidatableRecordingModel } from '@rift/service/validation/models/ValidatableRecording.Model';
import { ValidationService } from '@rift/service/validation/Validation.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { IViewModel } from '@shared/interface/IViewModel';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IViewModelUtility } from '@shared/utility/IViewModel.Utility';
import { combineLatest, concat, forkJoin, Observable, of, zip } from 'rxjs';
import { concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';


export class ManageSynchronizedRecordingsDialogData {

}

export class ManageSynchronizedRecordingsDialogResult {

}

class SynchronizedRecordingViewModel implements IViewModel {
    public item: IValidatableRecordingModel;
    public isSelected: boolean = false;
    public status: string = null;
    public isDeleting: boolean = false;
    public startDate: Date = null;
    public startTime: Date = null;
    public endTime: Date = null;
    public nodes: Array<SynchronizedRecordingViewModel> = [];

    private _startDateText: string = null;
    private _startTimeText: string = null;
    private _durationText: string = null;
    private _endTimeText: string = null;

    public constructor(validatableRecording: IValidatableRecordingModel) {
        this.item = validatableRecording;
        this.item.onNodes?.forEach(n => {
            this.nodes.push(new SynchronizedRecordingViewModel(n));
        });

        this.setFlags();
        this.setText();
    }

    public setFlags(): void {
        this.startDate = this.item.startTime;
        this.startTime = this.item.startTime;
        this.endTime = this.item.endTime;
    }

    public setText(): any {
        this._startDateText = null;
        this._startTimeText = null;
        this._durationText = null;
        this._endTimeText = null;


        if (isNullOrUndefined(this.item.preloadComplete) || this.item.preloadComplete === false) {
            this.status = 'Preloading data';
        } else if (!isNullOrUndefined(this.item.preloadComplete) && this.item.preloadComplete === true && (isNullOrUndefined(this.item.postLoadCompleted) || this.item.postLoadCompleted === false)) {
            this.status = 'Validatable (Background syncing)';
        } else if (!isNullOrUndefined(this.item.preloadComplete) && this.item.preloadComplete === true && (isNullOrUndefined(this.item.postLoadCompleted) || this.item.postLoadCompleted === true)) {
            this.status = 'Validatable (Fully synced)';
        }

        if (isNullOrUndefined(this._startDateText) || DateTimeUtility.isInvalidDate(this._startDateText)) {
            this._startDateText = DateTimeUtility.toShortDate(this.item.startTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.item.timezoneOffsetMins));
        }

        if (isNullOrUndefined(this._startTimeText) || DateTimeUtility.isInvalidDate(this._startTimeText)) {
            this._startTimeText = DateTimeUtility.toShortTime(this.item.startTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.item.timezoneOffsetMins));
        }

        if (isNullOrUndefined(this._durationText) || this._durationText === 'NaN:NaN:NaN') {
            this._durationText = DateTimeUtility.toDuration(this.item.startTime, this.item.endTime);
        }

        if (isNullOrUndefined(this._endTimeText) || DateTimeUtility.isInvalidDate(this._endTimeText)) {
            this._endTimeText = DateTimeUtility.toShortTime(this.item.endTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.item.timezoneOffsetMins));
        }
    }

    public get startDateText(): string {
        return this._startDateText;
    }

    public get startTimeText(): string {
        return this._startTimeText;
    }

    public get durationText(): string {
        return this._durationText;
    }

    public get endTimeText(): string {
        return this._endTimeText;
    }
}

@Component({
    selector: 'rift-manage-synchronized-recordings',
    templateUrl: './ManageSynchronizedRecordings.Component.html',
    styleUrls: ['./ManageSynchronizedRecordings.Component.scss']
})
export class ManageSynchronizedRecordingsComponent extends RiftBaseComponent implements OnInit, ILoadDate {
    public deleteNodesProcess: ProcessMonitorServiceProcess;
    public deleteMasterProcess: ProcessMonitorServiceProcess;
    public deleteAllProcess: ProcessMonitorServiceProcess;
    public cancelProcess: ProcessMonitorServiceProcess;

    public displayedColumns = ['friendlySerial', 'date', 'start', 'duration', 'end', 'status'];
    public dataSource: MatTableDataSource<SynchronizedRecordingViewModel> = new MatTableDataSource<SynchronizedRecordingViewModel>();
    public allDevices: boolean = false;

    @ViewChild('tableSort', { static: true })
    public tableSort: MatSort;

    @HostBinding()
    public id: string = 'rift-manage-synchronized-recordings';

    public constructor(
        private readonly _eventsService: EventsService,
        @Inject(MAT_DIALOG_DATA) private readonly _data: ManageSynchronizedRecordingsDialogData,
        private readonly _dialog: MatDialog,
        private readonly _dialogRef: MatDialogRef<ManageSynchronizedRecordingsComponent>,
        private readonly _validationService: ValidationService,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.loadDataProcess = this.processMonitorService.getProcess('ManageSynchronizedRecordingsComponent', this.loadDataProcessText);
        this.deleteNodesProcess = this.processMonitorService.getProcess('ManageSynchronizedRecordingsComponent', 'Deleting nodes synced recording.');
        this.deleteMasterProcess = this.processMonitorService.getProcess('ManageSynchronizedRecordingsComponent', 'Deleting master synced recording.');
        this.deleteAllProcess = this.processMonitorService.getProcess('ManageSynchronizedRecordingsComponent', 'Deleting all synced recordings.');
        this.cancelProcess = this.processMonitorService.getProcess('ManageSynchronizedRecordingsComponent', 'Canceling synced recordings.');

        this.initConnectionState();
    }

    public onDeviceSelectChange(event: MatSlideToggleChange): void {
        this.allDevices = event.checked;
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.dataSource.sort = this.tableSort;

        this.dataSource.sortingDataAccessor = IViewModelUtility.getDataSourceSortingDataAccessor(this.dataSource);
        this.setDataSourceFilterPredicate(this.dataSource);
    }

    public setDataSourceFilterPredicate(dataSource: MatTableDataSource<SynchronizedRecordingViewModel>): void {
        dataSource.filterPredicate = (data: SynchronizedRecordingViewModel, filter: string) =>
            data.item.friendlySerial.indexOf(filter) !== -1 ||
            data.startDateText.toString().indexOf(filter) !== -1 ||
            data.startTimeText.toString().indexOf(filter) !== -1 ||
            data.durationText.toString().indexOf(filter) !== -1 ||
            data.endTimeText.toString().indexOf(filter) !== -1;
    }

    public applyFilter(filterValue: string) {
        if (!this.isNullOrUndefined(this.dataSource.data) && this.dataSource.data.length > 0) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            this.dataSource.filter = filterValue;
        }
    }

    public close(): void {
        this._dialogRef.close(new ManageSynchronizedRecordingsDialogResult());
    }

    public delete(vm: SynchronizedRecordingViewModel) {
        // Delete nodes first, otherwise the dialog can appear
        // locked up when in realty you're still deleting nodes
        // in the background but the master entry was already deleted.
        const nodeCalls: Array<Observable<boolean>> = [];
        nodeCalls.push(of(true));

        if(vm.nodes !== null){
            vm.nodes.forEach(n => {
                const deleteIndividualNodeProcess: ProcessMonitorServiceProcess = this.processMonitorService.getProcess('ManageSynchronizedRecordingsComponent', 'Deleting individual node synced recording.');
                const cancelIndividualNodeProcess: ProcessMonitorServiceProcess = this.processMonitorService.getProcess('ManageSynchronizedRecordingsComponent', 'Cancel individual node synced recording.');
                const nodeCall = this.observableHandlerBase(this._validationService.cancel(n.item.recordingId), cancelIndividualNodeProcess).pipe(
                    mergeMap(nodeCancelled => {
                        if (nodeCancelled === true){
                            vm.isDeleting = true;

                            return this.observableHandlerBase(
                                this._validationService.clearCache(
                                    n.item.recordingId,
                                    deleteIndividualNodeProcess,
                                    (name: string, count: number, deleted: number) => {
                                        vm.status = `${name} ${deleted} of ${count}`;
                                    }
                                ), deleteIndividualNodeProcess);
                        }

                        return of(true);
                    })
                );

                nodeCalls.push(nodeCall);
            });
        }

        this.addSubscription(this.observableHandlerBase(forkJoin(nodeCalls), this.deleteNodesProcess).subscribe(() => {
            // Now delete the master entry
            this.addSubscription(this.observableHandlerBase(this._validationService.cancel(vm.item.recordingId), this.cancelProcess).subscribe(
                canceled => {
                    if (canceled === true) {
                        vm.isDeleting = true;
                        this.addSubscription(this.observableHandlerBase(
                            this._validationService.clearCache(
                                vm.item.recordingId,
                                this.deleteMasterProcess,
                                (name: string, count: number, deleted: number) => {
                                    vm.status = `${name} ${deleted} of ${count}`;
                                }
                            ), this.deleteMasterProcess).subscribe(
                                () => {
                                    const data = this.dataSource.data;
                                    const index = data.findIndex(i => i.item.id === vm.item.id);
                                    data.splice(index, 1);
                                    this.dataSource.data = data;
                                }
                            ),
                            this.deleteMasterProcess
                        );
                    }
                }
            ), this.cancelProcess);
        }), this.deleteNodesProcess);
    }

    public deleteAll(): void {
        this.addSubscription(this.observableHandlerBase(this._validationService.removeDatabase(), this.deleteAllProcess).subscribe(
            () => {
                const data = this.dataSource.data;
                data.splice(0, data.length);
                this.dataSource.data = data;
            }
        ), this.deleteAllProcess);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._validationService.getRecordings(this.allDevices === true ? null : this.connectionService.hostFriendlySerial, process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.dataSource.data = [];
                        this.dataSource.data = result
                            .filter(i => !this.isNullOrUndefined(i.recordingId))
                            .map(i => new SynchronizedRecordingViewModel(i));
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }
}
