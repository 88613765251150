import { NgZone, Directive } from '@angular/core';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { Device } from '@rift/components/shared/viewport/devices/Device';
import { Grid } from '@rift/components/shared/viewport/grid/Grid';
import { PathMapModel } from '@rift/models/restapi/PathMap.Model';
import { ImageUtility } from '@shared/utility/Image.Utility';
import { PointUtility } from '@shared/utility/Point.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

@Directive()
export class PathMap extends DisplayItem {
    public masterDevice: Device = null;

    private _bitmap: createjs.Bitmap = new createjs.Bitmap(ImageUtility.BlankImagePath);
    private _centered: boolean = false;
    private _visible: boolean = false;

    public constructor(
        private readonly _zone: NgZone) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            this.container.addChild(this._bitmap);
        });
    }

    public get visible(): boolean {
        return this._zone.runOutsideAngular(() => this._visible);
    }
    public set visible(value: boolean) {
        this._zone.runOutsideAngular(() => {
            if (this._visible !== value) {
                this._visible = value;
                this._bitmap.visible = value;
            }
        });
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();

            this.container.removeAllChildren();

            this._bitmap.uncache();
            this._bitmap = null;
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            this.requireStageUpdate.next();
        });
    }

    public bringToFront(): void {
    }

    public setPathMapModel(pathMapModel: PathMapModel): void {
        this._zone.runOutsideAngular(() => {
            if (this.visible === true) {
                if (pathMapModel.width > 0 && pathMapModel.height > 0) {
                    const convertedImage = new Image();

                    const convertCanvas = document.createElement('canvas');
                    const convertContext = convertCanvas.getContext('2d');

                    convertCanvas.width = pathMapModel.width;
                    convertCanvas.height = pathMapModel.height;

                    const imageData = convertContext.createImageData(pathMapModel.width, pathMapModel.height);
                    imageData.data.set(pathMapModel.bitmap);

                    convertContext.putImageData(imageData, 0, 0);

                    convertedImage.src = convertCanvas.toDataURL();

                    convertedImage.onload = function() {
                        // transform boundaries
                        const pointBoundaries: createjs.Point[][] = [];
                        const boundariesLength = pathMapModel.boundaries.length;
                        for (let bl = 0; bl < boundariesLength; bl++) {
                            const subPointBoundaries: createjs.Point[] = [];
                            const boundary = pathMapModel.boundaries[bl];
                            const boundaryLength = boundary.length;

                            for (let bp = 0; bp < boundaryLength; bp++) {
                                const pathMapPoint = boundary[bp];
                                subPointBoundaries.push(new createjs.Point(pathMapPoint.x * 10, pathMapPoint.y * 10));
                            }

                            pointBoundaries.push(subPointBoundaries);
                        }

                        // Convert the boundaries to image
                        const boundariesImage = new Image();
                        const boundariesCanvas = document.createElement('canvas');
                        const boundariesContext = boundariesCanvas.getContext('2d');
                        const width = pathMapModel.width * 2;
                        const height = pathMapModel.height * 2;

                        boundariesCanvas.width = width;
                        boundariesCanvas.height = height;

                        boundariesContext.strokeStyle = 'black';
                        boundariesContext.lineWidth = 1;

                        const pointBoundariesLength = pointBoundaries.length;
                        for (let bl = 0; bl < pointBoundariesLength; bl++) {
                            boundariesContext.stroke(PointUtility.getPolygonPath(pointBoundaries[bl], 3));
                        }

                        boundariesImage.src = boundariesCanvas.toDataURL();

                        boundariesImage.onload = function() {
                            // flip scale and add boundaries to the path map
                            const transImage = new Image();
                            const transCanvas = document.createElement('canvas');
                            const transContext = transCanvas.getContext('2d');

                            transCanvas.width = width;
                            transCanvas.height = height;

                            transContext.scale(1, -1);
                            transContext.drawImage(convertedImage, 0, height * -1, width, height);
                            transContext.drawImage(boundariesImage, 2, (height * -1) + 2, width, height);

                            transImage.src = transCanvas.toDataURL();

                            this._bitmap.image = transImage;

                            if (!this._centered) {
                                this._bitmap.x = (Grid.Width / 2) + pathMapModel.origin.x;
                                this._bitmap.y = (Grid.Height / 2) + (pathMapModel.origin.y * -1);

                                this._centered = true;
                            }

                            this.update();
                        }.bind(this);
                    }.bind(this);
                }
            }
        });
    }
}
