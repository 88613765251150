export enum TimeOfFlightFrameRateEnum {
    unknown = 0,
    fifteenTwentySix = 1,
    elevenFortyFour = 2
}

export class TimeOfFlightFrameRateEnumHelpers {
    public static fromRestApi(value: string): TimeOfFlightFrameRateEnum {
        switch (value) {
            case 'Unknown':
                return TimeOfFlightFrameRateEnum.unknown;
            case 'FifthteenTwentySix':
                return TimeOfFlightFrameRateEnum.fifteenTwentySix;
            case 'ElevenFortyFour':
                return TimeOfFlightFrameRateEnum.elevenFortyFour;
        }
    }

    public static toFullName(value: TimeOfFlightFrameRateEnum): string {
        switch (value) {
            case TimeOfFlightFrameRateEnum.unknown:
                return 'Unknown';
            case TimeOfFlightFrameRateEnum.fifteenTwentySix:
                return 'FifthteenTwentySix';
            case TimeOfFlightFrameRateEnum.elevenFortyFour:
                return 'ElevenFortyFour';
        }
    }
}
