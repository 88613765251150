import { EmConnectionModel, IEmConnectionModel } from '@rift/models/restapi/EmConnection.Model';
import { IpConnectionModel, IIpConnectionModel } from '@rift/models/restapi/IpConnection.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IStoreDataModel extends IRestModel {
    ipConnections: Array<IIpConnectionModel>;
    emConnections: Array<IEmConnectionModel>;
}

export class StoreDataModel extends BaseModel implements IStoreDataModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public ipConnections: Array<IpConnectionModel> = null;
    public emConnections: Array<EmConnectionModel> = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-StoreDataModel');

        this.ipConnections = RestModelUtility.loadFromArray(restModel.IpConnections, IpConnectionModel);
        this.emConnections = RestModelUtility.loadFromArray(restModel.EmConnections, EmConnectionModel);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
            IpConnections: RestModelUtility.toJsonArray(this.ipConnections),
            EmConnections: RestModelUtility.toJsonArray(this.emConnections),
        };
    }
}
