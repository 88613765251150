import { PlotlyConfiguration } from '@shared/service/configuration/Configuration.Plotly';
import { LoggingServiceOptions } from '@shared/service/logging/Logging.Service.Options';
import { HttpProtocols } from '@shared/types/HttpProtocols';

export enum AppHostEnum {
    rift = 0,
    em = 1,
    nrf = 2,
    safeCount = 3,
}

export interface IConfigurationService {
    /**
     * The app hosting the config service.
     *
     * @type {('em' | 'rift')}
     * @memberof IConfigurationService
     */
    hostApp: AppHostEnum;
    /**
     * The path to redirect to when connected.
     *
     * @type {string}
     * @memberof IConfigurationService
     */
    riftConnectRedirectPath: string;
    /**
     * If set before connect will override riftConnectRedirectPath.
     *
     * @type {string}
     * @memberof IConfigurationService
     */
    riftConnectRedirectPathOverride?: string;
    /**
     * True if all data should be reloaded on save changes. else false.
     *
     * @type {boolean}
     * @memberof IConfigurationService
     */
    riftRefreshAllData: boolean;
    /**
     * Plotly config
     *
     * @type {PlotlyConfiguration}
     * @memberof IConfigurationService
     */
    plotlyOptions: PlotlyConfiguration;
    /**
     * The number of ms to wait before timing out device connection attempt.
     *
     * @type {number}
     * @memberof IConfigurationService
     */
    deviceConnectionTimeOutMs: number;
    /**
     * The base title
     *
     * @type {string}
     * @memberof IConfigurationService
     */
    baseTitle: string;

    /**
     * The path to navigat to when connection to device fails
     *
     * @type {string[]}
     * @memberof IConfigurationService
     */
    riftNavigateToOnFailedConnection: string[];

    /**
     * The connection protacol to use.
     *
     * @type {string}
     * @memberof IConfigurationService
     */
    protocol: HttpProtocols;

    /**
     * The host name.
     *
     * @type {string}
     * @memberof IConfigurationService
     */
    hostName: string;

    /**
     * The port;
     *
     * @type {number}
     * @memberof IConfigurationService
     */
    port: number;

    /**
     * The rift web socket base path.
     *
     * @type {string}
     * @memberof riftWebSocket
     */
    riftWebSocket: string;

    /**
     * The base url of rift.
     *
     * @type {string}
     * @memberof IConfigurationService
     */
    appBase: string;

    /**
     * The base url for getting app version info
     *
     * @type {string}
     * @memberof IConfigurationService
     */
    versionBase: string;

    /**
     * The base path to the V1 rest api.
     *
     * @type {string}
     * @memberof ConfigurationService
     */
    riftV1RestApiBase: string;

    /**
     * The base path to the rest api.
     *
     * @type {string}
     * @memberof ConfigurationService
     */
    riftRestApiBase: string;

    /**
     * The base path to the rest api.
     *
     * @type {string}
     * @memberof ConfigurationService
     */
    emRestApiBase: string;

    /**
     * The base path to the V1 rest api.
     *
     * @type {string}
     * @memberof ConfigurationService
     */
    emV1RestApiBase: string;

    /**
     * The base path to the V2 rest api.
     *
     * @type {string}
     * @memberof ConfigurationService
     */
    emV2RestApiBase: string;

    /**
     * Logging service config.
     *
     * @type {LoggingServiceOptions}
     * @memberof ConfigurationService
     */
    loggingServiceOptions: LoggingServiceOptions;
}
