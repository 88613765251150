import { IDatabaseSettings } from '@rift/service/validation/database/IDatabaseSettings';
import { DatabaseCountFramesStore } from '@rift/service/validation/database/syncrecording/Database.CountFrames.Store';
import { DatabaseDeviceFramesStore } from '@rift/service/validation/database/syncrecording/Database.DeviceFrames.Store';
import { DatabaseGlobalBookmarkFramesStore } from '@rift/service/validation/database/syncrecording/Database.GlobalBookmark.Store';
import { DatabaseGlobalDataFramesStore } from '@rift/service/validation/database/syncrecording/Database.GlobalDataFrames.Store';
import { DatabaseLineFramesStore } from '@rift/service/validation/database/syncrecording/Database.LineFrames.Store';
import { DatabasePolygonFramesStore } from '@rift/service/validation/database/syncrecording/Database.PolygonFrames.Store';
import { DatabaseRecordingStore } from '@rift/service/validation/database/syncrecording/Database.Recording.Store';
import { DatabaseRegisterFramesStore } from '@rift/service/validation/database/syncrecording/Database.RegisterFrames.Store';
import { DatabaseTargetFramesStore } from '@rift/service/validation/database/syncrecording/Database.TargetFrames.Store';
import { DatabaseTimeDataFramesStore } from '@rift/service/validation/database/syncrecording/Database.TimeDataFrames.Store';
import { DatabaseVideoFramesStore } from '@rift/service/validation/database/syncrecording/Database.VideoFrames.Store';
import { DatabaseVideoSettingFramesStore } from '@rift/service/validation/database/syncrecording/Database.VideoSettings.Store';
import { DatabaseSyncFramesStore } from './Database.SyncFrames.Store';

export const DatabaseSettings: IDatabaseSettings = {
    databaseVersion: 6,
    databaseName: 'recording',
    databaseStores: [
        DatabaseRecordingStore,
        DatabaseCountFramesStore,
        DatabaseTargetFramesStore,
        DatabaseVideoFramesStore,
        DatabaseVideoSettingFramesStore,
        DatabaseDeviceFramesStore,
        DatabaseLineFramesStore,
        DatabasePolygonFramesStore,
        DatabaseRegisterFramesStore,
        DatabaseGlobalDataFramesStore,
        DatabaseTimeDataFramesStore,
        DatabaseGlobalBookmarkFramesStore,
        DatabaseSyncFramesStore
    ],
} as IDatabaseSettings;
