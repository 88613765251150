import { SyncedVideoSessionModel } from '@rift/models/restapi/SyncedVideoSession.Model';
import { VideoSessionModel } from '@rift/models/restapi/VideoSession.Model';
import { IValidatableRecordingModel } from '@rift/service/validation/models/ValidatableRecording.Model';

export function toSyncedVideoSessionModel(validatableRecording: IValidatableRecordingModel): SyncedVideoSessionModel {
    const videoSessionModel = new SyncedVideoSessionModel();
    videoSessionModel.id = validatableRecording.id;
    videoSessionModel.bytes = validatableRecording.bytes;
    videoSessionModel.startTime = validatableRecording.startTime;
    videoSessionModel.endTime = validatableRecording.endTime;
    videoSessionModel.frames = validatableRecording.frames;
    videoSessionModel.bytes = validatableRecording.bytes;
    videoSessionModel.timezoneOffsetMins = validatableRecording.timezoneOffsetMins;
    videoSessionModel.isComplete = validatableRecording.isSynced;
    return videoSessionModel;
}

export function fromSyncedVideoSessionModel(syncedVideoSession: SyncedVideoSessionModel): IValidatableRecordingModel {
    return {
        id: syncedVideoSession.id,
        startTime: syncedVideoSession.startTime,
        endTime: syncedVideoSession.endTime,
        frames: syncedVideoSession.frames,
        bytes: syncedVideoSession.bytes,
        timezoneOffsetMins: syncedVideoSession.timezoneOffsetMins,
        isSynced: syncedVideoSession.isComplete,
        onNodes: syncedVideoSession.nodes?.map(n=> ({
                friendlySerial: n.serial,
                bytes: n.syncedVideoSessionData?.bytes,
                endTime: n.syncedVideoSessionData?.endTime,
                frames: n.syncedVideoSessionData?.frames,
                id: n.syncedVideoSessionData?.id,
                startTime: n.syncedVideoSessionData?.startTime,
                timezoneOffsetMins: n.syncedVideoSessionData?.timezoneOffsetMins,
                isSynced: n.syncedVideoSessionData?.isComplete ?? false
            })) ?? []
    } as IValidatableRecordingModel;
}

export function toVideoSessionModel(validatableRecording: IValidatableRecordingModel): VideoSessionModel {
    const videoSessionModel = new VideoSessionModel();
    videoSessionModel.id = validatableRecording.id;
    videoSessionModel.bytes = validatableRecording.bytes;
    videoSessionModel.startTime = validatableRecording.startTime;
    videoSessionModel.endTime = validatableRecording.endTime;
    videoSessionModel.frames = validatableRecording.frames;
    videoSessionModel.bytes = validatableRecording.bytes;
    videoSessionModel.timezoneOffsetMins = validatableRecording.timezoneOffsetMins;
    videoSessionModel.nodes = validatableRecording.onNodes?.map(n=>n.friendlySerial) ?? [];
    return videoSessionModel;
}

export function fromVideoSessionModel(videoSession: VideoSessionModel): IValidatableRecordingModel {
    return {
        id: videoSession.id,
        startTime: videoSession.startTime,
        endTime: videoSession.endTime,
        frames: videoSession.frames,
        bytes: videoSession.bytes,
        timezoneOffsetMins: videoSession.timezoneOffsetMins,
        onNodes: videoSession.nodes?.map(n=> ({
                friendlySerial: n
            })) ?? []
    } as IValidatableRecordingModel;
}
