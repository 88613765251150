import { NgZone, Directive } from '@angular/core';
import { TimeLineDurationView } from '@rift/components/shared/timeline/duration/TimeLine.Duration.View';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IPlayLocation } from '@rift/service/validation/IPlayLocation';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IntervalTracker } from '@shared/generic/IntervalTracker';
import { MAX_CANVAS_FPS, GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';
import { ArrayUtility } from '@shared/utility/Array.Utility';

interface ICount {
    count: number;
    stroke: any;
}

@Directive()
export class TimeLineRowBodyCounts extends DisplayItem {
    private _color: string = '#000000';
    private _colorMouseOver: string = '#00ff00';
    private _counts: Array<ICount> = [];
    private _height: number = 0;
    private _mouseOverCount: number;
    private _mouseOverCountFrameNumber: number;
    private _mouseOverEnabled: boolean = true;
    private _mouseOverEndLocation: IPlayLocation;
    private _mouseOverStartLocation: IPlayLocation;
    private _shape: createjs.Shape = new createjs.Shape();
    private _width: number = 0;
    private _lastStartFrame: number = null;
    private _lastEndFrame: number = null;
    private _viewUpdatedIntervalTracker: IntervalTracker = new IntervalTracker(1000 / MAX_CANVAS_FPS);

    public constructor(
        protected readonly _type: 's' | 'u',
        protected readonly _durationView: TimeLineDurationView,
        protected readonly _playService: ValidationPlayService,
        protected readonly _zone: NgZone,
        protected readonly _registerBase: RegisterBaseModel) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            this.container.addChild(this._shape);
            this.addEventHandlers();
        });
    }

    public setCounts(counts: number[]): void {
        this._counts = counts.map(c => ({ count: c, stroke: null }));
    }

    public addUpdate(count: number, frameNumber: number): void {
        this._counts[frameNumber] = { count, stroke: null };
    }

    public bringToFront(displayItem?: IDisplayItem): void {

    }

    public clear(): void {
        this._counts = [];
        this._lastEndFrame = null;
        this._lastStartFrame = null;
    }

    public get color(): string {
        return this._color;
    }

    public set color(value: string) {
        if (this._color !== value) {
            this._color = value;
        }
    }

    public get colorMouseOver(): string {
        return this._colorMouseOver;
    }

    public set colorMouseOver(value: string) {
        if (this._colorMouseOver !== value) {
            this._colorMouseOver = value;
        }
    }

    public mouseMove(stageX: number): void {
        if (this.mouseOverEnabled) {
            this._mouseOverCount = 0;
            this._mouseOverCountFrameNumber = null;
            const localMouse: createjs.Point = this._durationView.container.globalToLocal(stageX, null);
            const mouseLocation = this._playService.pixelToPlayLocation(localMouse.x, this._durationView.bar.fullSegmentWidth);
            const percent = (1 * this._playService.viewDuration / 100);
            const startOffset = mouseLocation.offset - percent;
            const endOffset = mouseLocation.offset + percent;
            this._mouseOverStartLocation = this._playService.fillPlayLocation({ offset: startOffset });
            this._mouseOverEndLocation = this._playService.fillPlayLocation({ offset: endOffset });

            for (let i = this._playService.viewStartLocation.frameNumber; i <= this._playService.viewEndLocation.frameNumber; i++) {
                const iCount = this._counts[i];
                if (!isNullOrUndefined(iCount) && !isNullOrUndefined(iCount.stroke)) {
                    if (i >= this._mouseOverStartLocation.frameNumber && i <= this._mouseOverEndLocation.frameNumber) {
                        iCount.stroke.style = this.colorMouseOver;
                        this._mouseOverCount++;
                        this._mouseOverCountFrameNumber = i;
                    } else {
                        iCount.stroke.style = this.color;
                    }
                }
            }

            if (this._mouseOverCount === 0) {
                this._mouseOverStartLocation = null;
                this._mouseOverEndLocation = null;
                this._mouseOverCountFrameNumber = null;
            }

            this.updateCountsCache();
            this.requireStageUpdate.next();
        }
    }

    public mouseOut(event: createjs.MouseEvent): void {
        if (this.mouseOverEnabled) {
            this.removeMouseOver();
        }
    }

    public get mouseOverCount(): number {
        return this._mouseOverCount;
    }

    public get mouseOverCountFrameNumber(): number {
        return this._mouseOverCountFrameNumber;
    }

    public get mouseOverEnabled(): boolean {
        return this._mouseOverEnabled;
    }

    public set mouseOverEnabled(value: boolean) {
        if (this._mouseOverEnabled !== value) {
            this._mouseOverEnabled = value;
            if (value === false) {
                this.removeMouseOver();
            }
        }
    }

    public get mouseOverEndLocation(): IPlayLocation {
        return this._mouseOverEndLocation;
    }

    public get mouseOverStartLocation(): IPlayLocation {
        return this._mouseOverStartLocation;
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();
            this._shape.uncache();
        });
    }

    public remove(frameNumber: number): void {
        this._counts[frameNumber] = undefined;
    }

    public resize(width: number, height: number): void {
        this._zone.runOutsideAngular(() => {
            this._width = width;
            this._height = height;
            this.update();
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            this.drawCounts();
            this.requireStageUpdate.next();
        });
    }

    public get visible(): boolean {
        return this.container.visible;
    }

    public set visible(value: boolean) {
        if (this.container.visible !== value) {
            this.container.visible = value;
        }
    }

    public get width(): number {
        return this._width;
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this.addSubscription(this._playService.onViewUpdated.pipe().subscribe(() => this.onPlayServiceViewUpdated()));
            this.addSubscription(this._playService.onStart.subscribe(() => this.onPlayServiceStart()));
        });
    }

    private drawCounts(): void {
        const startFrame = this._playService.viewStartLocation.frameNumber;
        const endFrame = this._playService.viewEndLocation.frameNumber;

        if (startFrame !== this._lastStartFrame || endFrame !== this._lastEndFrame || startFrame === 0) {
            // console.log(`register:${this._registerBase.registerName}:type:${this._type}:start:${startFrame}:end:${endFrame}:${new Date().valueOf()}`);

            const graphics = this._shape.graphics;
            graphics.clear();
            graphics.setStrokeStyle(1);

            let lastCount: ICount = null;

            if (startFrame > 0) {
                lastCount = ArrayUtility.previousNotNullOrUndefined(this._counts, startFrame);
            }

            for (let i = startFrame; i <= endFrame; i++) {
                const currentCount = this._counts[i];

                if (!isNullOrUndefined(currentCount)) {
                    if (!isNullOrUndefined(lastCount) && currentCount.count !== lastCount.count) {
                        const x = Math.round(this._playService.playLocationToPixel({ frameNumber: i }, this._durationView.bar.fullSegmentWidth));
                        currentCount.stroke = graphics.beginStroke(this.color).command;
                        graphics.moveTo(x, 0);
                        graphics.lineTo(x, this._height);
                        graphics.endStroke();
                    }
                    lastCount = currentCount;
                }
            }

            this._shape.cache(0, 0, this._durationView.bar.fullSegmentWidth, this._height, GLOBAL_CACHE_SCALE);

            this._lastStartFrame = startFrame;
            this._lastEndFrame = endFrame;
        }
    }

    private onPlayServiceStart(): void {
        this.update();
    }

    private onPlayServiceViewUpdated(): void {
        if (this._viewUpdatedIntervalTracker.tick() === true) {
            this.update();
        }
    }

    private removeEventHandlers(): void {
        super.onDestroy();
    }

    private removeMouseOver(): void {
        this._mouseOverStartLocation = null;
        this._mouseOverEndLocation = null;

        for (let i = this._playService.viewStartLocation.frameNumber; i <= this._playService.viewEndLocation.frameNumber; i++) {
            const iCount = this._counts[i];
            if (!isNullOrUndefined(iCount) && !isNullOrUndefined(iCount.stroke)) {
                iCount.stroke.style = this.color;
            }
        }

        this.updateCountsCache();
        this.requireStageUpdate.next();
    }

    private updateCountsCache(): void {
        this._shape.updateCache();
    }
}
