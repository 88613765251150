import { AfterViewInit, Component, ElementRef, Injector, Input, OnInit, ViewChild, HostBinding } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';

/**
 * Shows canvas.
 *
 * @export
 * @class CanvasComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
    selector: 'shr-canvas',
    templateUrl: './Canvas.Component.html',
    styleUrls: ['./Canvas.Component.scss']
})
export class CanvasComponent extends BaseComponent implements OnInit, AfterViewInit {
    public static className: string = 'CanvasComponent';

    @HostBinding()
    public id: string = 'shr-canvas';

    @Input()
    public height: number = 400;

    @Input()
    public width: number = 400;

    @Input()
    public serial: string;

    @ViewChild('canvas', { static: true })
    public canvasElement: ElementRef;

    public canvas: HTMLCanvasElement;
    public context: CanvasRenderingContext2D;

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
    }

    public ngAfterViewInit(): void {
        // get the context
        this.canvas = this.canvasElement.nativeElement;
        this.context = this.canvas.getContext('2d');

        // set the width and height
        this.canvas.width = this.width;
        this.canvas.height = this.height;
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }
}
