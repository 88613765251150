import { Injectable } from '@angular/core';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { ConnectionStatusEnum } from '@shared/enum/ConnectionStatus.Enum';

@Injectable()
export class ConnectionStateService extends RiftBaseService {
    public connectionStatus: ConnectionStatusEnum = null;

    public constructor() {
        super();
    }

    public get isOffline(): boolean {
        return this.connectionStatus === ConnectionStatusEnum.offline;
    }

    public get isOnline(): boolean {
        return this.connectionStatus === ConnectionStatusEnum.online;
    }
}
