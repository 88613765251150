import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RestApiService } from '@shared/service/restapi/RestApi.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { BaseModel } from '@shared/base/Base.Model';
import { RestApiError } from '@shared/types/RestApi.Error';
import { Directive } from '@angular/core';

@Directive()
export abstract class RiftRestApiService extends RestApiService {
    public constructor(
        private readonly _riftDialogBase: MatDialog,
        private readonly _riftUrlBase: string,
        private readonly _riftHttpClient: HttpClient,
        private readonly _riftConnectionTokenService?: ConnectionTokenService) {
        super(_riftDialogBase, _riftUrlBase, _riftHttpClient);
    }

    protected getTokenParams(paramArray?: string[]): HttpParams {
        let params = new HttpParams();
        if (!isNullOrUndefined(this._riftConnectionTokenService) && !isNullOrUndefined(this._riftConnectionTokenService.connectionToken)) {
            params = params.append('token', this._riftConnectionTokenService.connectionToken);
            if (!isNullOrUndefined(paramArray)) {
                const paramArrayLength = paramArray.length;
                for (let i: number = 0; i < paramArrayLength; i += 2) {
                    params = params.append(paramArray[i], paramArray[i + 1]);
                }
            }
        }
        return params;
    }

    protected handleErrorOrThrow<T>(model: T): T {
        if (model instanceof BaseModel && !isNullOrUndefined(model.userCanceled)) {
            return model;
        }

        if (RestModelUtility.hasError(model)) {
            if (RestModelUtility.hasErrorMessage(model)) {
                const errorMessage = ((model as any).error as string);
                if (errorMessage.toLocaleLowerCase().indexOf('invalid token') !== -1) {
                    window.location.reload();
                } else {
                    return model;
                }
            }

            if (model instanceof BaseModel && !isNullOrUndefined(model.httpError)){
                throw new RestApiError('Rest API error', model.httpError.status);
            }

            throw new Error('Rest API error');
        }

        return model;
    }
}
