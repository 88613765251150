import { IResultModel } from '@rift/models/restapi/Result.Model';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

const EXCLUDE_FIELDS = ['confirmPassword'];

export interface IChangePasswordModel extends IRestModel {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export class ChangePasswordModel extends BaseModel implements IChangePasswordModel, IResultModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public oldPassword: string = null;
    public newPassword: string = null;
    public confirmPassword: string = null;

    public constructor() {
        super();

        this.registerProperty('oldPassword');
        this.registerProperty('newPassword');
        this.registerProperty('confirmPassword');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ChangePasswordModel', EXCLUDE_FIELDS);

        this.setPropertyOriginalValue('oldPassword', this.oldPassword);
        this.setPropertyOriginalValue('newPassword', this.newPassword);
        this.setPropertyOriginalValue('confirmPassword', this.confirmPassword);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this, EXCLUDE_FIELDS),
        };
    }
}
