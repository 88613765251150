<div fxLayout="column"
    class="counts-menu">
    <div class="header">
        Counts
    </div>
    <div class="content"
        fxLayout="column">
        <div fxLayout="row"
            class="row">
            <div class="cell fill">
            </div>
            <div *ngIf="showSystemCounts === true"
                class="cell"
                fxFlex>
                System
            </div>
            <div class="cell"
                fxFlex>
                User
            </div>
        </div>
        <div fxLayout="row"
            class="row"
            *ngFor="let register of displayRegisters">
            <div class="cell fill"
                [ngStyle]="{'background-color': register.fillColor}">

            </div>
            <div *ngIf="showSystemCounts === true"
                class="cell"
                fxFlex>
                {{ getSystemCount(register.registerIndex) }}
            </div>
            <div class="cell"
                fxFlex>
                {{ getUserCount(register.registerIndex) }}
            </div>
        </div>
    </div>
    <div class="actions">
    </div>
</div>