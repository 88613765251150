<div class="em-settings-tasklaucher-run-title" mat-dialog-title>
    Run Task
    <div fxFlex></div>
    <button mat-raised-button (click)="onUploadClicked()" *ngIf="(userIsSystemAdmin | async) === true">Upload</button>
</div>
<form [formGroup]="runTaskFormGroup">
    <div class="em-settings-tasklaucher-run-content" mat-dialog-content fxLayout="column">
        <div fxLayout="column">
            <div class="mat-subheading-2">
                Select Task Package
            </div>
            <div class="mat-subheading-1">
                Select the task package to run.
            </div>
            <div fxLayout="column">
                <mat-form-field>
                    <mat-select #package formControlName="package" placeholder="Task Package">
                        <mat-option [matTooltip]="package.description"
                            *ngFor="let package of packageList | orderBy: 'name'" [value]="package">
                            {{package.name}} ({{package.version}})
                        </mat-option>
                    </mat-select>
                    <mat-error>{{getFormControlErrorMessage(runTaskFormGroup.controls['package'])}}</mat-error>
                </mat-form-field>
                <span class="description">{{package?.value?.description}}
                    {{!isNullOrUndefined(package) && !isNullOrUndefined(package.value) && !isNullOrUndefined(package.value.version) ? '(' + package?.value?.version + ')' : ''}}
                </span>
            </div>

            <div class="mat-subheading-2">
                Operates on
            </div>
            <div class="mat-subheading-1">
                Select the device that will run this task package.
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field fxFlex="50" class="operates-on">
                    <mat-select #operatesOn formControlName="operatesOn" placeholder="Operates On">
                        <mat-option *ngIf="(userIsSystemManager | async) === true" value="all">
                            All Devices
                        </mat-option>
                        <mat-option value="folder">
                            Group
                        </mat-option>
                        <mat-option value="device">
                            Device
                        </mat-option>
                    </mat-select>
                    <mat-error>{{getFormControlErrorMessage(runTaskFormGroup.controls['operatesOn'])}}</mat-error>
                </mat-form-field>
                <ng-template [ngIf]="runTaskFormGroup.controls['operatesOn'].value === 'folder'">
                    <mat-form-field fxFlex class="group-field">
                        <em-address-book-auto-complete #folderId formControlName="folderId">
                        </em-address-book-auto-complete>
                        <mat-error>{{getFormControlErrorMessage(runTaskFormGroup.controls['folderId'])}}</mat-error>
                    </mat-form-field>
                </ng-template>
                <ng-template [ngIf]="runTaskFormGroup.controls['operatesOn'].value === 'device'">
                    <mat-form-field fxFlex class="friendlySerialNumber-field">
                        <em-device-autocomplete #friendlySerialNumber formControlName="friendlySerialNumber">
                        </em-device-autocomplete>
                        <mat-error>{{getFormControlErrorMessage(runTaskFormGroup.controls['friendlySerialNumber'])}}
                        </mat-error>
                    </mat-form-field>
                </ng-template>
            </div>
        </div>

    </div>
    <div class="em-settings-tasklaucher-run-actions" mat-dialog-actions>
        <shr-progress-button-spinner [disabled]="enabled === false || runTaskFormGroup.valid === false" [raised]="true"
            [diameter]="30" text="Queue" mode="indeterminate" [active]="addTaskProcess.isRunning === true"
            (shrClick)="add()" color="primary"></shr-progress-button-spinner>
        <div *ngIf="!isNullOrUndefined(warning)" fxFlex class="disabled-text spacing-left spacing-right">
            {{ warning }}
        </div>
        <div *ngIf="!isNullOrUndefined(error)" fxFlex class="error spacing-left spacing-right">
            {{ error }}
        </div>
        <div *ngIf="isNullOrUndefined(warning) && isNullOrUndefined(error)" fxFlex></div>
        <button (click)="cancel()" mat-raised-button color="warn">Cancel</button>
    </div>
</form>