<input #serialInput
    [disabled]="disabled"
    matInput
    (optionSelected)="onOptionSelected($event)"
    [placeholder]="placeholder"
    [matAutocomplete]="deviceSerials"
    [formControl]="deviceSerialsCtrl"
    (blur)="onTouched($event)">
<mat-autocomplete #deviceSerials="matAutocomplete">
    <mat-option *ngFor="let serial of filteredDeviceSerials | async"
        [value]="serial">
        <span>{{ serial }}</span>
    </mat-option>
</mat-autocomplete>