import { Injectable } from '@angular/core';
import { BaseService } from '@shared/base/Base.Service';
import { ChangeUserPasswordModel } from '@shared/models/restapi/ChangeUserPassword.Model';
import { LoginModel } from '@shared/models/restapi/Login.Model';
import { UserBaseModel } from '@shared/models/restapi/UserBase.Model';
import { UserPasswordModel } from '@shared/models/restapi/UserPassword.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, Subject } from 'rxjs';
import { UserModel } from '@em/models/restapi/User.Model';
import { UpdateCurrentUserResponseModel } from '@em/models/restapi/UpdateCurrentUserResponse.Model';

export class UserLoginResult {
    public error: string;
    public success: boolean;
}

export class UserLogoffResult {
    public success: boolean;
    public error: string;
}

@Injectable()
export class UserCurrentService extends BaseService {
    public userChanged: Subject<UserBaseModel> = new Subject();


    /**
     * Creates an instance of UserCurrentService.
     *
     * @memberof UserCurrentService
     */
    public constructor() {
        super();
    }

    public get isSystemAdmin(): Observable<boolean> {
        throw new Error('UserCurrentService: isSystemAdmin not implemented');
    }

    public get isSystemManager(): Observable<boolean> {
        throw new Error('UserCurrentService: isSystemManager not implemented');
    }

    public get isAdmin(): Observable<boolean> {
        throw new Error('UserCurrentService: isAdmin not implemented');
    }

    public get isInstaller(): Observable<boolean> {
        throw new Error('UserCurrentService: isInstaller not implemented');
    }

    public get isUser(): Observable<boolean> {
        throw new Error('UserCurrentService: isUser not implemented');
    }

    public get user(): Observable<UserBaseModel> {
        throw new Error('UserCurrentService: user not implemented');
    }

    public get isAuthenticated(): Observable<boolean> {
        throw new Error('UserCurrentService: isAuthenticated not implemented');
    }

    public refresh(): void {
        throw new Error('UserCurrentService: refresh not implemented');
    }

    public login(login: LoginModel, process?: ProcessMonitorServiceProcess): Observable<UserLoginResult> {
        throw new Error('UserCurrentService: login not implemented');
    }

    public logoff(process?: ProcessMonitorServiceProcess): Observable<UserLogoffResult> {
        throw new Error('UserCurrentService: logoff not implemented');
    }

    public changePassword(passwords: ChangeUserPasswordModel, process?: ProcessMonitorServiceProcess): Observable<UserPasswordModel> {
        throw new Error('UserCurrentService: changePassword not implemented');
    }

    public resetAPIKey(process?: ProcessMonitorServiceProcess): Observable<string> {
        throw new Error('UserCurrentService: resetAPIKey not implemented');
    }

    public getQuickSetupFile(hostName: string, port: number, process?: ProcessMonitorServiceProcess): Observable<string> {
        throw new Error('UserCurrentService: getQuickSetupFile not implemented');
    }

    public saveCurrentUser(user: UserModel, process?: ProcessMonitorServiceProcess): Observable<UpdateCurrentUserResponseModel> {
        throw new Error('UserCurrentService: saveCurrentUser not implemented');
    }
}
