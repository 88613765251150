<div class="em-settings-licence">
    <div fxLayout="column">
        <mat-card class="status">
            <div fxLayout="row">
                <ng-template [ngIf]="mode === 'view'">
                    <div class="item-column"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center center">
                        <div class="mat-subheading-2"
                            style="text-align: center;">
                            Licence Status
                        </div>
                        <div ngClass.gt-sm="value"
                            ngClass.lt-md="value-small">
                            {{licences !== null ? licences?.stateText : ''}}
                        </div>
                    </div>
                    <mat-divider class="divider"
                        [vertical]="true"
                        style="height: 122px;"></mat-divider>
                    <div class="item-column"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center center">
                        <div class="mat-subheading-2"
                            style="text-align: center;">
                            Current Device Capacity
                        </div>
                        <div ngClass.gt-sm="value"
                            ngClass.lt-md="value-small">
                            {{licences !== null ? licences?.isLicensed ? licences?.capacity : 'N/A' : ''}}
                        </div>
                    </div>
                    <mat-divider class="divider"
                        [vertical]="true"
                        style="height: 122px;"></mat-divider>
                    <div class="item-column"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center center">
                        <div class="mat-subheading-2"
                            style="text-align: center;">
                            Licence Expires
                        </div>
                        <div ngClass.gt-sm="value"
                            ngClass.lt-md="value-small">
                            {{licences !== null ? licences?.isLicensed ? licences?.expiryText : 'Expired' : ''}}
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="mode === 'apply'">
                    <div class="item-column"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center center">
                        <div class="mat-subheading-2 head2">
                            Activate Licence Key
                        </div>
                        <div class="mat-subheading-1 head1">
                            <span *ngIf="isNullOrUndefined(applyKeyError)">Enter a valid licence key</span>
                            <span *ngIf="!isNullOrUndefined(applyKeyError)"
                                class="error">{{applyKeyError}}</span>
                        </div>
                        <form [formGroup]="keyFormGroup">
                            <div class="input"
                                fxLayout="row"
                                fxLayoutAlign="space-around center">
                                <mat-form-field style="width: 370px;">
                                    <input type="text"
                                        #key
                                        matInput
                                        formControlName="key"
                                        autocomplete="key"
                                        placeholder="key"
                                        autofocus>
                                    <mat-error>{{getFormControlErrorMessage(keyFormGroup.controls['key'])}}</mat-error>
                                    <mat-error>{{applyKeyError}}</mat-error>
                                    <mat-hint align="end">{{key.value.length}} / 39</mat-hint>
                                </mat-form-field>
                                <shr-progress-button-spinner style="margin-left: 20px;"
                                    [raised]="true"
                                    [diameter]="30"
                                    text="Apply Key"
                                    mode="indeterminate"
                                    [active]="applyKeyProcess.isRunning === true"
                                    [disabled]="!keyFormGroup.valid"
                                    (shrClick)="apply(key.value)">
                                </shr-progress-button-spinner>
                            </div>
                        </form>
                    </div>
                    <mat-divider class="divider"
                        [vertical]="true"
                        style="height: 122px;"></mat-divider>
                    <div class="item-column"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center center">
                        <div class="mat-subheading-2 head2">
                            Licence File
                        </div>
                        <div class="mat-subheading-1 head1">
                            Upload and apply a valid licence file
                        </div>
                        <div class="input"
                            fxLayout="row"
                            fxLayoutAlign="center center">
                            <input style="display: none;"
                                type="file"
                                id="avatar"
                                (change)="onApplyLicenceFileChange($event)"
                                #applyLicenceFileInput>
                            <shr-progress-button-spinner [raised]="true"
                                [diameter]="30"
                                text="Apply Licence File"
                                mode="indeterminate"
                                [active]="applyLicenceFileProcess.isRunning === true"
                                (shrClick)="applyLicenceFileInput.click()"></shr-progress-button-spinner>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="mode === 'applyfail'">
                    <div class="item-column"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center center">
                        <div class="mat-subheading-2 head2">
                            Activating Key Failed
                        </div>
                        <div class="error">
                            Unable to contact Irisys Licence Server. Please retry or continue offline.
                        </div>
                        <div class="input"
                            fxLayout="row"
                            fxLayoutAlign="center center">
                            <button mat-raised-button
                                (click)="applyMode()"
                                style="margin-right: 10px;">Retry</button>
                            <button mat-raised-button
                                (click)="applyOfflineMode()">Continue Offline</button>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="mode === 'applyoffline'">
                    <div class="item-column"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center center">
                        <div class="mat-subheading-2 head2">
                            Activate Licence Offline
                        </div>
                        <div class="mat-subheading-1 head1">
                            If the Irisys licence server cannot be contacted for automatic licence validation the
                            process can be performed manually by
                            downloading the Licence File and sending it to
                            <a href="mailto:counting.support@irisys.co.uk ">counting.support@irisys.co.uk </a>
                        </div>
                        <div class="error">
                            {{applyKeyError}}
                        </div>
                        <div class="input"
                            fxLayout="row"
                            fxLayoutAlign="center center">
                            <input style="display: none;"
                                type="file"
                                id="avatar"
                                (change)="onApplyLicenceFileChange($event)"
                                #applyLicenceFileInput>
                            <shr-progress-button-spinner style="margin-right: 10px;"
                                [raised]="true"
                                [diameter]="30"
                                text="Download Licence File"
                                mode="indeterminate"
                                [active]="downloadLicenceFileProcess.isRunning === true"
                                (shrClick)="downloadLicenceFile()"></shr-progress-button-spinner>
                            <shr-progress-button-spinner [raised]="true"
                                [diameter]="30"
                                text="Apply Licence File"
                                mode="indeterminate"
                                [active]="applyLicenceFileProcess.isRunning === true"
                                (shrClick)="applyLicenceFileInput.click()"></shr-progress-button-spinner>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="mode === 'releasefail'">
                    <div class="item-column"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center center">
                        <div class="mat-subheading-2 head2">
                            Failed to Release Licences
                        </div>
                        <div class="error">
                            The Irisys Licence Server could not be contacted. Retry or continue offline.
                        </div>
                        <div class="input"
                            fxLayout="row"
                            fxLayoutAlign="center center">
                            <shr-progress-button-spinner style="margin-right: 10px;"
                                [raised]="true"
                                [diameter]="30"
                                text="Retry"
                                mode="indeterminate"
                                [active]="releaseLicenceProcess.isRunning === true"
                                (shrClick)="release()"></shr-progress-button-spinner>
                            <button mat-raised-button
                                (click)="releaseOfflineMode()">Continue Offline</button>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="mode === 'releaseoffline'">
                    <div class="item-column"
                        fxFlex
                        fxLayout="column"
                        fxLayoutAlign="center center">
                        <div class="mat-subheading-2 head2">
                            Release Licence Offline
                        </div>
                        <div class="mat-subheading-1 head1">
                            If the Irisys licence server cannot be contacted for automatic licence release the process
                            can be performed manually by downloading
                            the Licence File and sending it to
                            <a href="mailto:counting.support@irisys.co.uk ">counting.support@irisys.co.uk</a>
                        </div>
                        <div class="error">
                            {{applyKeyError}}
                        </div>
                        <div class="input"
                            fxLayout="row"
                            fxLayoutAlign="center center">
                            <shr-progress-button-spinner style="margin-right: 10px;"
                                [raised]="true"
                                [diameter]="30"
                                text="Erase my licence and download release file"
                                mode="indeterminate"
                                [active]="downloadLicenceAndReleaseFileProcess.isRunning === true"
                                (shrClick)="downloadLicenceAndReleaseFile()"></shr-progress-button-spinner>
                        </div>
                    </div>
                </ng-template>
            </div>
        </mat-card>
        <shr-table [isLoadingData]="loadDataProcess.isRunning"
            title="Licence Details"
            class="details"
            [dataSource]="dataSource"
            [displayedColumns]="displayedColumns"
            [filterEnabled]="false"
            [dataExportEnabled]="true"
            [dataExportGetDataHandler]="dataExportGetData"
            [dataExportGetHeaderHandler]="dataExportGetHeader">

            <ng-container matColumnDef="key">
                <th mat-header-cell
                    *matHeaderCellDef> Licence Key </th>
                <td mat-cell
                    *matCellDef="let licence">
                    <shr-table-cell [data]="licence"
                        keyPath="key"></shr-table-cell>
                </td>
            </ng-container>

            <ng-container matColumnDef="capacity">
                <th mat-header-cell
                    *matHeaderCellDef> Capacity </th>
                <td mat-cell
                    *matCellDef="let licence">
                    <shr-table-cell [data]="licence"
                        keyPath="capacity"></shr-table-cell>
                </td>
            </ng-container>

            <ng-container matColumnDef="expires">
                <th mat-header-cell
                    *matHeaderCellDef> Expires </th>
                <td mat-cell
                    *matCellDef="let licence">
                    <shr-table-cell [data]="licence"
                        keyPath="expiryText"></shr-table-cell>
                </td>
            </ng-container>
        </shr-table>
        <mat-card class="faq">
            <mat-card-title>
                <span class="mat-title">FAQ</span>
            </mat-card-title>
            <mat-card-content>
                <div fxLayout="row">
                    <div fxFlex
                        fxLayout="column">
                        <div class="faq-section">
                            <div class="title">
                                Schedules
                            </div>
                            <div class="content">
                                <span>When unlicensed, no schedules will be executed, no data will be collected, no
                                    devices will
                                    be monitored and their connections won't be forwarded on.</span>
                            </div>
                        </div>
                        <div class="faq-section">
                            <div class="title">
                                Data
                            </div>
                            <div class="content">
                                <span>The data Estate Manager collects is always available regardless of whether Estate
                                    Manager
                                    is licensed or not.</span>
                            </div>
                        </div>
                    </div>
                    <div fxFlex
                        fxLayout="column">
                        <div class="faq-section">
                            <div class="title">
                                Devices
                            </div>
                            <div class="content">
                                <span>Each licence has a device capacity associated with it. If the number of devices
                                    connecting
                                    exceeds this limit then any new devices will not be licensed.</span>
                                <span>In this scenario normal operating procedures will apply for the licensed units but
                                    not for
                                    any unlicensed units. If this occurs a new quick-view link will appear on the bottom
                                    left of the screen.</span>
                            </div>
                        </div>
                        <div class="faq-section">
                            <div class="title">
                                Licence Service
                            </div>
                            <div class="content">
                                <span>The Licence Service must be running for Estate Manager to verify its licence. If
                                    it is unavailable
                                    Estate Manager will act as if it is unlicensed.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>