<form [formGroup]="metaDataForm"
    class="em-settings-metadata-key"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="start center">
    <mat-form-field>
        <input #name
            matInput
            type="text"
            formControlName="name"
            placeholder="Name">
        <mat-error>{{getFormControlErrorMessage(metaDataForm.controls['name'])}}</mat-error>
        <mat-hint align="end">{{name.value.length}} / 100</mat-hint>
        <span matSuffix>{{ metaData.propertyHasChangesText('name') }}</span>
    </mat-form-field>
    <div fxFlex></div>
    <button matTooltip="Delete"
        (click)="delete()"
        color="warn"
        mat-icon-button>
        <mat-icon>delete</mat-icon>
    </button>
</form>