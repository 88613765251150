import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilterOnEntry } from '@em/components/settings/addressbook/Settings.AddressBook.Component';
import { SearchCriteriaModel } from '@em/models/restapi/SearchCriteria.Model';
import { BaseComponent } from '@shared/base/Base.Component';
import { ComparisonOperatorEnum } from '@shared/enum/ComparisonOperator.Enum';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';

@Component({
    selector: 'em-settings-address-book-filter',
    templateUrl: './Settings.AddressBook.Filter.Component.html',
    styleUrls: ['./Settings.AddressBook.Filter.Component.scss']
})
export class SettingsAddressBookFilterComponent extends BaseComponent implements OnInit, OnChanges {
    public static className: string = 'SettingsAddressBookFilterComponent';

    @Output()
    public deleteClicked: EventEmitter<SearchCriteriaModel> = new EventEmitter();

    @Output()
    public validChanged: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public valueChanges: EventEmitter<void> = new EventEmitter();

    @Input()
    public byDeviceSettingOptions: Array<FilterOnEntry>;

    @Input()
    public byMetaDataOptions: Array<FilterOnEntry>;

    @Input()
    public searchCriteria: SearchCriteriaModel;

    @Input()
    public noEdit: boolean = false;

    @Input()
    public isFirst: boolean = false;
    public ComparisonOperatorEnum = ComparisonOperatorEnum;
    public formGroupStatusChangesProcess: ProcessMonitorServiceProcess;
    public formGroupValueChangesProcess: ProcessMonitorServiceProcess;
    public isValid: boolean = true;

    @HostBinding()
    public id: string = 'em-settings-address-book-filter';

    public searchCriteriaForm: FormGroup;

    private _filterOnEntryCombined: Array<FilterOnEntry>;
    private _setValues: boolean = false;

    public constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector);

        this.formGroupStatusChangesProcess = this.processMonitorService.getProcess(SettingsAddressBookFilterComponent.className, 'Form group status changed');
        this.formGroupValueChangesProcess = this.processMonitorService.getProcess(SettingsAddressBookFilterComponent.className, 'Form group values changed');

        this.searchCriteriaForm = this._formBuilder.group({
            by: ['', Validators.compose([Validators.required])],
            operator: ['', Validators.compose([Validators.required])],
            value: ['', Validators.compose([Validators.required, ValidationValidators.maxLength(this.getMaxLength.bind(this))])],
        });

        this.addSubscription(this.observableHandlerBase(this.searchCriteriaForm.statusChanges, this.formGroupStatusChangesProcess).subscribe(status => {
            if (this._setValues === true) {
                if (status === 'VALID') {
                    this.isValid = true;
                    this.updateModel();
                    this.searchCriteria.isValid = true;
                    this.validChanged.emit(true);
                    this.valueChanges.emit();
                } else {
                    this.isValid = false;
                    this.searchCriteria.isValid = false;
                    this.validChanged.emit(false);
                }
            }
        }), this.formGroupStatusChangesProcess);

        this.addSubscription(this.observableHandlerBase(this.searchCriteriaForm.valueChanges, this.formGroupValueChangesProcess).subscribe(() => {
            if (this._setValues === true) {
                this.updateModel();
                this.valueChanges.emit();
            }
        }), this.formGroupValueChangesProcess);
    }

    public delete(): void {
        this.deleteClicked.emit(this.searchCriteria);
    }

    public getMaxLength(): number {
        if (!this.isNullOrUndefined(this.searchCriteriaForm) && !this.isNullOrUndefined(this.searchCriteriaForm)) {
            const formModel = this.searchCriteriaForm.value;

            if (this.isNullOrUndefined(this._filterOnEntryCombined)) {
                this._filterOnEntryCombined = this.byDeviceSettingOptions.concat(this.byMetaDataOptions);
            }
            const filter = this._filterOnEntryCombined.find(i => i.value === (formModel.by as string));
            return this.isNullOrUndefined(filter) ? 0 : filter.valueMaxLength;
        } else {
            return 0;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.searchCriteria) && !this.isNullOrUndefined(changes.searchCriteria.currentValue)) {
            this.setFormValues(changes.searchCriteria.currentValue);
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    private setFormValues(searchCriteria: SearchCriteriaModel): void {
        this.searchCriteriaForm.setValue({
            by: this.isNullOrUndefined(searchCriteria.entryId) ? '' : searchCriteria.entryId,
            operator: this.isNullOrUndefined(searchCriteria.comparisonOperator) ? '' : searchCriteria.comparisonOperator,
            value: this.isNullOrUndefined(searchCriteria.searchParameter) ? '' : searchCriteria.searchParameter,
        });
        this._setValues = true;

        if(this.noEdit === true){
            this.searchCriteriaForm.controls.value.disable();
        }
        else{
            this.searchCriteriaForm.controls.value.enable();
        }
    }

    private updateModel(): void {
        const formModel = this.searchCriteriaForm.value;

        if (!this.isNullOrUndefined(formModel.by)) {
            this.searchCriteria.entryId = formModel.by;
        }

        if (!this.isNullOrUndefined(formModel.operator)) {
            this.searchCriteria.comparisonOperator = formModel.operator;
        }

        if (!this.isNullOrUndefined(formModel.value)) {
            this.searchCriteria.searchParameter = formModel.value;
        }

        if (!this.isNullOrUndefined(this.byMetaDataOptions) && this.byMetaDataOptions.length > 0) {
            const option = this.byMetaDataOptions.find(o => o.value === formModel.by);
            if (!this.isNullOrUndefined(option)) {
                this.searchCriteria.isMetaData = true;
            } else {
                this.searchCriteria.isMetaData = false;
            }
        } else {
            this.searchCriteria.isMetaData = false;
        }
    }
}
