import { Injectable, Output } from '@angular/core';
import { UserModel } from '@em/models/restapi/User.Model';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { DeviceInfo } from '@shared/component/deviceinfotooltip/DeviceInfoTooltip.Component';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { UserCurrentService } from '@shared/service/user/User.Current.Service';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IFavouriteDevice {
    serial: string;
    userName: string;
    deviceInfo: DeviceInfo;
}

@Injectable()
export class FavouriteDevicesService extends EmBaseService {
    public static readonly className = 'FavouriteDevicesService';

    @Output()
    public added: Subject<string> = new Subject();

    @Output()
    public removed: Subject<string> = new Subject();

    @LocalStorage('FavouriteDevicesService', 'devices')
    public allDevices: IFavouriteDevice[];

    private _user: UserModel;

    public get devices(): IFavouriteDevice[] {
        if (!isNullOrUndefined(this._user)) {
            return this.allDevices.filter(i => i.userName === this._user.username);
        }
        return [];
    }

    public constructor(private readonly _userCurrentService: UserCurrentService) {
        super();

        this._userCurrentService.userChanged.subscribe((user: UserModel) => this._user = user);
        this._userCurrentService.user.subscribe((user: UserModel) => this._user = user);

        if (isNullOrUndefined(this.allDevices)) {
            this.allDevices = [];
        }
    }

    public add(deviceInfo: DeviceInfo): void {
        this._userCurrentService.user.subscribe((user: UserModel) => {
            this.allDevices.push({ deviceInfo, serial: deviceInfo.friendlySerial, userName: user.username });
            this.added.next(deviceInfo.friendlySerial);
        });
    }

    public remove(serial: string): void {
        this._userCurrentService.user.subscribe((user: UserModel) => {
            const index = this.allDevices.findIndex(d => d.serial === serial && d.userName === user.username);
            if (index !== -1) {
                this.allDevices.splice(index, 1);
                this.removed.next(serial);
            }
        });
    }

    public exists(serial: string): Observable<boolean> {
        return this._userCurrentService.user.pipe(
            map((user: UserModel) => this.allDevices.findIndex(d => d.serial === serial && d.userName === user.username) !== -1)
        );
    }
}
