import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigDataModel } from '@em/models/restapi/ConfigData.Model';
import { GroupModel } from '@em/models/restapi/Group.Model';
import { MetaDataKeyCollectionModel } from '@em/models/restapi/MetaDataKeyCollectionModel';
import { ResultModel } from '@em/models/restapi/Result.Model';
import { RoutingRuleCollectionModel } from '@em/models/restapi/RoutingRuleCollection.Model';
import { ServiceStatusAndConfigurationModel } from '@em/models/restapi/ServiceStatusAndConfiguration.Model';
import { EmRestApiService } from '@em/service/restapi/base/EmRestApi.Service';
import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MatDialog } from '@angular/material/dialog';

class SetSettingModel extends BaseModel implements IRestModel {

    public category: string = null;
    public name: string = null;
    public readonly isIRestModel: boolean = true;
    public value: string = null;

    public constructor(category: string, name: string, value: string) {
        super();

        this.category = category;
        this.name = name;
        this.value = value;
    }

    public loadFromRestApiModel(restModel: any): void {
        throw new Error('Method not implemented.');
    }

    public toRestApiModel() {
        return {
            category: this.category,
            name: this.name,
            value: this.value,
        };
    }
}

class SetModuleConfigModel extends BaseModel implements IRestModel {
    public readonly isIRestModel: boolean = true;

    public configData: ConfigDataModel = null;
    public moduleId: string = null;

    public constructor(moduleId: string, configData: ConfigDataModel) {
        super();

        this.moduleId = moduleId;
        this.configData = configData;
    }

    public loadFromRestApiModel(restModel: any): void {
        throw new Error('Method not implemented.');
    }

    public toRestApiModel() {
        return {
            configData: this.configData.toRestApiModel(),
            moduleId: this.moduleId
        };
    }
}

@Injectable()
export class RestApiSettingsService extends EmRestApiService {
    private _controller = 'settings/';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.emRestApiBase, _httpClient);
    }

    public getServiceStatusAndConfiguration(process?: ProcessMonitorServiceProcess): Observable<ServiceStatusAndConfigurationModel> {
        return this.get<ServiceStatusAndConfigurationModel>(`${this._controller}getservicestatusandconfiguration`, ServiceStatusAndConfigurationModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getModuleConfig(moduleId: string, process?: ProcessMonitorServiceProcess): Observable<ConfigDataModel> {
        return this.get<ConfigDataModel>(`${this._controller}getmoduleconfig/${moduleId}`, ConfigDataModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setModuleConfig(moduleId: string, configData: ConfigDataModel, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.post<SetModuleConfigModel, boolean>(`${this._controller}setmoduleconfig`, new SetModuleConfigModel(moduleId, configData), 'boolean', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updateGroup(group: GroupModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<GroupModel, ResultModel>(`${this._controller}editgroup`, group, ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteGroup(groupId: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<number, ResultModel>(`${this._controller}deletegroup`, groupId, ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public addGroup(parentId: number, name: string, process?: ProcessMonitorServiceProcess): Observable<number> {
        return this.post<any, number>(`${this._controller}addgroup`, { name, parent: { id: parentId } }, 'number', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public saveAllMetaDataChanges(metaDataKeys: MetaDataKeyCollectionModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<MetaDataKeyCollectionModel, ResultModel>(`${this._controller}metadata`, metaDataKeys, ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getRoutingRules(process?: ProcessMonitorServiceProcess): Observable<RoutingRuleCollectionModel> {
        return this.get<RoutingRuleCollectionModel>(`${this._controller}getconnectionforwardingasjson`, RoutingRuleCollectionModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public saveAllRoutingRules(rules: RoutingRuleCollectionModel, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.post<RoutingRuleCollectionModel, ResultModel>(`${this._controller}connectionforwarding`, rules, ResultModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public setSetting(name: string, category: string, value: string, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this.post<SetSettingModel, boolean>(`${this._controller}setsetting`, new SetSettingModel(category, name, value), 'boolean', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
