<div class="counting-setting-content">
    <div class="spacing-bottom">
        <mat-checkbox [disabled]="isReadOnly"
            [checked]="enabled"
            (change)="onEnabledChange($event)">Enable</mat-checkbox>
    </div>
    <div *ngIf="enabled === true"
        fxLayout="row"
        fxLayoutAlign="start center">
        <form [formGroup]="form"
            style="max-width: 500px;">
            <mat-form-field fxFlex="33"
                class="spacing-right">
                <input riftUnitsOfMeasurementInput
                    #minimumValueInput=riftUnitsOfMeasurementInput
                    formControlName="minimumValue"
                    placeholder="Min Value"
                    [metricMin]="0"
                    [metricUnit]="UnitOfMeasurementEnum.centimeter"
                    [imperialUnit]="UnitOfMeasurementEnum.inch">
                <span matSuffix>
                    {{minimumValueInput.suffix}}
                </span>
                <mat-error>{{getFormControlErrorMessage(form.controls['minimumValue'])}}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="33"
                class="spacing-right">
                <input riftUnitsOfMeasurementInput
                    #binWidthInput=riftUnitsOfMeasurementInput
                    formControlName="binWidth"
                    placeholder="Bin Width"
                    [metricMin]="1"
                    [metricUnit]="UnitOfMeasurementEnum.centimeter"
                    [imperialUnit]="UnitOfMeasurementEnum.inch">
                <span matSuffix>
                    {{binWidthInput.suffix}}
                </span>
                <mat-error>{{getFormControlErrorMessage(form.controls['binWidth'])}}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="33">
                <input matInput
                    type="number"
                    formControlName="numberOfBins"
                    placeholder="Number Of Bins">
                <mat-error>{{getFormControlErrorMessage(form.controls['numberOfBins'])}}</mat-error>
            </mat-form-field>
        </form>
    </div>
</div>