<div #container
    *ngIf="show === true && canShow === true"
    class="float-dialog rift-settings-counting-add-register"
    (click)="onMenuClick()"
    ngDraggable
    #draggable="ngDraggable"
    (started)="onDrStarted($event)"
    (stopped)="onDrStopped($event)"
    (edge)="onDrEdge($event)"
    (movingOffset)="onDrMovingOffset($event)"
    (endOffset)="onDrEndOffset($event)"
    [handle]="title"
    [bounds]="bounds"
    [position]="position"
    [inBounds]="!isNullOrUndefined(bounds)">
    <div #title
        class="title drag-handle"
        fxLayout="row"
        fxLayoutAlign="start center">
        <div fxFlex
            fxLayout="row"
            fxLayoutAlign="start center">
            <div fxLayout="column">
                <div class="header">
                    Add Register
                </div>
            </div>
            <div fxFlex></div>
            <div class="actions">
                <button class="flat-icon-button"
                    [disabled]="saveAllChangesProcess.isRunning === true"
                    mat-stroked-button
                    matTooltip="Close"
                    (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="content">
        <mat-stepper #stepper
            orientation="horizontal"
            [linear]="true">
            <mat-step>
                <ng-template matStepLabel>Type</ng-template>
                <div class="step-content">
                    <div *ngIf="!isNullOrUndefined(lineRegisterTypes) && lineRegisterTypes.length > 0"
                        class="type-title">Lines</div>
                    <mat-card (click)="onRegisterTypeSelected(lineRegisterType)"
                        [class.selected]="lineRegisterType === selectedRegisterType"
                        class="spacing-bottom type-card"
                        *ngFor="let lineRegisterType of lineRegisterTypes | orderBy: 'order'">
                        <mat-card-header>
                            <div mat-card-avatar>
                                <shr-fa [name]="lineRegisterType.faIconName">settings</shr-fa>
                            </div>
                            <mat-card-title>
                                {{lineRegisterType.displayName}}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p>
                                {{ lineRegisterType.description }}
                            </p>
                        </mat-card-content>
                    </mat-card>
                    <div *ngIf="!isNullOrUndefined(zoneRegisterTypes) && zoneRegisterTypes.length > 0"
                        class="type-title">Zones</div>
                    <mat-card (click)="onRegisterTypeSelected(zoneRegisterType)"
                        [class.selected]="zoneRegisterType === selectedRegisterType"
                        class="spacing-bottom type-card"
                        *ngFor="let zoneRegisterType of zoneRegisterTypes | orderBy: 'order'">
                        <mat-card-header>
                            <div mat-card-avatar>
                                <shr-fa [name]="zoneRegisterType.faIconName">settings</shr-fa>
                            </div>
                            <mat-card-title>
                                {{zoneRegisterType.displayName}}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p>
                                {{ zoneRegisterType.description }}
                            </p>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div *ngIf="!isNullOrUndefined(selectedRegisterError)"
                    class="step-error error"
                    fxLayout="column">
                    {{selectedRegisterError}}
                </div>
                <div class="step-actions">
                    <button [disabled]="selectedRegisterValid === false"
                        mat-raised-button
                        matStepperNext>Next</button>
                </div>
            </mat-step>
            <mat-step *ngIf="!isNullOrUndefined(selectedRegisterType) && selectedRegisterType.hasNameStep === true"
                [stepControl]="nameFormGroup">
                <form [formGroup]="nameFormGroup">
                    <ng-template matStepLabel>Name</ng-template>
                    <div class="step-content"
                        fxLayout="row"
                        fxLayoutAlign="start stretch">
                        <mat-form-field>
                            <input #name
                                formControlName="name"
                                maxlength="{{registerNameMaxLength}}"
                                matInput
                                placeholder="Name">
                            <mat-error>{{getFormControlErrorMessage(nameFormGroup.controls['name'])}}</mat-error>
                            <span matSuffix>{{ addRegister?.propertyHasChangesText('registerName') }}</span>
                            <mat-hint align="end">{{name.value.length}} / {{registerNameMaxLength}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="step-actions">
                        <button mat-raised-button
                            matStepperPrevious
                            class="spacing-right">Back</button>
                        <button mat-raised-button
                            matStepperNext
                            [disabled]="nameFormGroup.valid === false">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step *ngIf="!isNullOrUndefined(selectedRegisterType) && selectedRegisterType.hasSettingsStep === true"
                [completed]="settings.isValid === true && nameFormGroup.valid === true">
                <ng-template matStepLabel>Settings</ng-template>
                <div class="settings-content"
                    fxLayout="column">
                    <rift-settings-counting-register-settings #settings
                        (associateSelectedChange)="onAssociateSelectedChange()"
                        [register]="addRegister"
                        [registers]="registers"
                        [lines]="lines"
                        [polygons]="polygons"
                        [registerTypeViewModel]="selectedRegisterType">
                    </rift-settings-counting-register-settings>
                </div>
                <div class="step-actions">
                    <button mat-raised-button
                        matStepperPrevious
                        class="spacing-right">Back</button>
                    <button mat-raised-button
                        matStepperNext
                        [disabled]="settings.isValid === false">Next</button>
                </div>
            </mat-step>
            <mat-step *ngIf="!isNullOrUndefined(selectedRegisterType) && !isNullOrUndefined(addRegister)">
                <ng-template matStepLabel>Summary</ng-template>
                <div class="step-content"
                    fxLayout="column">
                    <rift-settings-counting-register-summary #summary
                        [register]="addRegister"
                        registerNamePostfix="will be added">
                    </rift-settings-counting-register-summary>
                </div>
                <div class="step-actions">
                    <button mat-raised-button
                        matStepperPrevious
                        class="spacing-right">Back</button>
                    <button mat-raised-button
                        (click)="onAddRegisterClick()">Add Register</button>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>