import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionOptionsModel } from '@rift/models/restapi/ConnectionOptions.Model';
import { ConnectionProviderOptionModel } from '@rift/models/restapi/ConnectionProviderOption.Model';
import { ConnectionRequestModel } from '@rift/models/restapi/ConnectionRequest.Model';
import { IPSetupModel } from '@rift/models/restapi/IPSetup.Model';
import { ConnectionErrorModel } from '@rift/models/websocket/ConnectionError.Model';
import { ConnectionStatusModel } from '@rift/models/websocket/ConnectionStatus.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { ConnectionStateService } from '@rift/service/connection/ConnectionState.Service';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { AllDataService } from '@rift/service/data/alldata/AllData.Service';
import { DeviceService } from '@rift/service/data/device/Device.Service';
import { DeviceValidationService } from '@rift/service/devicevalidation/DeviceValidation.Service';
import { RestApiConnectionService } from '@rift/service/restapi/v1/RestApi.Connection.Service';
import { RestApiConnectionProviderService } from '@rift/service/restapi/v1/RestApi.ConnectionProvider.Service';
import { RestApiDisconnectService } from '@rift/service/restapi/v1/RestApi.Disconnect.Service';
import { WebSocketService } from '@rift/service/websocket/WebSocket.Service';
import { ConnectionUtility } from '@rift/utility/Connection.Utility';
import { OkCancelDialogComponent, OkCancelDialogData, OkCancelDialogResult } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import {
    PleaseWaitDialogComponent,
    PleaseWaitDialogData,
} from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ConnectionStatusEnum } from '@shared/enum/ConnectionStatus.Enum';
import { ValidationResultEnum } from '@shared/enum/ValidationResult.Enum';
import { ChangeTrackerCollection } from '@shared/generic/ChangeTrackerCollection';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { EventsService, INewConnectionSettings } from '@shared/service/events/Events.Service';
import { NavBarAction } from '@shared/service/navbaraction/NavBarAction.Service.Action';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RoutesUtility } from '@shared/utility/Routes.Utility';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, finalize, flatMap, map, mergeMap, retryWhen, tap } from 'rxjs/operators';

export interface IConnectionError {
    message?: string;
    attempt?: number;
}

@Injectable()
export class ConnectionService extends RiftBaseService {
    public connected: Subject<null> = new Subject<null>();
    public connectedCancelled: Subject<null> = new Subject<null>();
    public connectedError: Subject<IConnectionError> = new Subject<IConnectionError>();
    public connectionStateChange: Subject<ConnectionStatusEnum> = new Subject<ConnectionStatusEnum>();
    public connectionRetry: Subject<number> = new Subject<number>();
    public connectionRetriesExceeded: Subject<null> = new Subject<null>();
    public disconnected: Subject<{ errorMessage: string }> = new Subject<{ errorMessage: string }>();
    public offline: Subject<null> = new Subject<null>();
    public online: Subject<null> = new Subject<null>();
    public showVideoButtonChanged: Subject<boolean> = new Subject<boolean>();

    public emConnection: boolean = false;
    public isOnDevice: boolean = false;
    public isChangingConnectionState: boolean = false;

    private _connectDialogRef: MatDialogRef<PleaseWaitDialogComponent>;
    private _connected: boolean = null;
    private _connectedToFriendlySerial: string = null;
    private _goOffLineDialogRef: MatDialogRef<PleaseWaitDialogComponent>;
    private _goOnLineDialogRef: MatDialogRef<PleaseWaitDialogComponent>;
    private _hostFriendlySerial: string = null;
    private _options: ConnectionOptionsModel = null;
    private _providerToken: string = null;
    private _request: ConnectionRequestModel = null;
    private _showVideoButtonOnConnectComponent: boolean = false;
    private _preOfflineHandlers: Array<() => Observable<boolean>> = [];
    private _preOnlineHandlers: Array<() => Observable<boolean>> = [];

    private readonly ConnectionOptionName: string = 'Server';

    public constructor(
        protected readonly _eventsService: EventsService,
        protected readonly _dialog: MatDialog,
        protected readonly _deviceValidationService: DeviceValidationService,
        protected readonly _dataPollingService: DataPollingService,
        protected readonly _zone: NgZone,
        protected readonly _deviceService: DeviceService,
        protected readonly _allDataService: AllDataService,
        protected readonly _connectionStateService: ConnectionStateService,
        protected readonly _connectionTokenService: ConnectionTokenService,
        protected readonly _restApiConnectionProviderService: RestApiConnectionProviderService,
        protected readonly _restApiConnectionService: RestApiConnectionService,
        protected readonly _restApiDisconnectService: RestApiDisconnectService,
        protected readonly _webSocketService: WebSocketService,
        protected readonly _activatedRoute: ActivatedRoute,
        protected readonly _router: Router
    ) {
        super();

        this.addSubscription(
            this._webSocketService.connectionErrorMessageReceived.subscribe(
                message => this.onConnectionError(message)
            )
        );
        this.addSubscription(
            this._webSocketService.connectionStatusMessageReceived.subscribe(
                message => this.onConnectionStatusMessageReceived(message)
            )
        );
    }

    public applyNetworkSetup(iPSetup: IPSetupModel, serialNumber: string, process?: ProcessMonitorServiceProcess, paths?: string[], staticOnDevicePaths?: string[]): Observable<{ updated: boolean; canceled: boolean; error: boolean }> {
        const result = { updated: false, canceled: false, error: false };
        const forceReconnectWaitTime = 1500;

        if (iPSetup.hasChanges === true) {
            const connectionDataChanged = iPSetup.connectionDataChanged();
            if (connectionDataChanged === true) {
                if (iPSetup.dhcp === true) {
                    const dhcpConfirmDialogRef = this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Confirm Changes', 'Changing to DHCP IP mode may cause the device to disconnect. if this happens you will need to manually re-establish a connection using the devices new DHCP assigned IP', true), disableClose: true });

                    return dhcpConfirmDialogRef.afterClosed().pipe(
                        flatMap((dhcpResult: OkCancelDialogResult) => {
                            if (!isNullOrUndefined(dhcpResult) && dhcpResult.ok === true) {

                                if (this.isOnDevice === true) {
                                    timer(forceReconnectWaitTime).subscribe(() => {
                                        window.location.assign(`http://${iPSetup.hostname}/${!isNullOrUndefined(staticOnDevicePaths) && staticOnDevicePaths.length > 0 ? staticOnDevicePaths.join('/') : ''}`);
                                        // this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Reconnect To Device', 'Please enter the devices new DHCP assigned IP into the browsers address bar', false, false), disableClose: true });
                                    });
                                }

                                return this._deviceService.setIPSettings(serialNumber, iPSetup, process).pipe(
                                    map((setIPSettingsResult) => {
                                        if (!isNullOrUndefined(setIPSettingsResult) && !isNullOrUndefined(setIPSettingsResult.error)) {
                                            result.error = true;
                                            return result;
                                        } else {
                                            if (this.isOnDevice === false) {
                                                this._eventsService.changedNetworkSettings();
                                                this._eventsService.changedNetworkSettingsConnection({ ip: iPSetup.iPV4, hostname: iPSetup.hostname, port: iPSetup.serverPort, dhcp: iPSetup.dhcp, paths });

                                                result.updated = true;
                                                return result;
                                            }
                                        }
                                    }),
                                );
                            } else {
                                iPSetup.clearChanges();
                                result.canceled = true;
                                return of(result);
                            }
                        }),
                    );
                } else {
                    const staticConfirmDialogRef = this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Confirm Changes', 'Changing to devices network settings may cause the device to disconnect. If this happens you may need to manually reconnect', true), disableClose: true });

                    return staticConfirmDialogRef.afterClosed().pipe(
                        flatMap((staticResult: OkCancelDialogResult) => {
                            if (!isNullOrUndefined(staticResult) && staticResult.ok === true) {
                                if (this.isOnDevice === true) {
                                    timer(forceReconnectWaitTime).subscribe(() => {
                                        window.location.assign(`http://${iPSetup.iPV4}/${!isNullOrUndefined(staticOnDevicePaths) && staticOnDevicePaths.length > 0 ? staticOnDevicePaths.join('/') : ''}`);
                                    });
                                }

                                return this._deviceService.setIPSettings(serialNumber, iPSetup, process).pipe(
                                    map((setIPSettingsResult) => {
                                        if (!isNullOrUndefined(setIPSettingsResult) && !isNullOrUndefined(setIPSettingsResult.error)) {
                                            result.error = true;
                                            return result;
                                        } else {
                                            if (this.isOnDevice === false) {
                                                this._eventsService.changedNetworkSettings();
                                                this._eventsService.changedNetworkSettingsConnection({ ip: iPSetup.iPV4, hostname: iPSetup.hostname, port: iPSetup.serverPort, dhcp: iPSetup.dhcp, paths });

                                                result.updated = true;
                                                return result;
                                            }
                                        }
                                    }),
                                );
                            } else {
                                iPSetup.clearChanges();

                                result.canceled = true;
                                return of(result);
                            }
                        }),
                    );
                }
            } else {
                return this._deviceService.setIPSettings(serialNumber, iPSetup, process).pipe(
                    map((setIPSettingsResult) => {
                        if (!isNullOrUndefined(setIPSettingsResult) && !isNullOrUndefined(setIPSettingsResult.error)) {
                            result.error = true;
                            return result;
                        } else {
                            this._eventsService.changedNetworkSettings();
                            result.updated = true;
                            return result;
                        }
                    }),
                );
            }
        } else {
            return of(result);
        }
    }

    public networkSetupApplied(newConnectionSettings: INewConnectionSettings, router: Router, process?: ProcessMonitorServiceProcess, navigateAndConnectComplete?: () => void): void {
        if (!isNullOrUndefined(this.connectionRequest)) {
            const connectionType = this.connectionRequest.connectionType;
            if (connectionType === 'IP') {
                let protocol: string = null;
                let connectionData: string = null;

                this.addSubscription(this.disconnectAsync().subscribe(() => {
                    if (!isNullOrUndefined(newConnectionSettings.ip) || !isNullOrUndefined(newConnectionSettings.hostname)) {
                        protocol = 'ip';
                        if (!isNullOrUndefined(newConnectionSettings.dhcp) && newConnectionSettings.dhcp === true) {
                            connectionData = `${newConnectionSettings.hostname || newConnectionSettings.ip}:${newConnectionSettings.port}`;
                        } else {
                            connectionData = `${newConnectionSettings.ip || newConnectionSettings.hostname}:${newConnectionSettings.port}`;
                        }
                    }

                    if (!isNullOrUndefined(newConnectionSettings.pleaseWaitDialogs) && newConnectionSettings.pleaseWaitDialogs.length > 0) {
                        newConnectionSettings.pleaseWaitDialogs.forEach(d => d.close());
                    }

                    this.addSubscription(ConnectionUtility.navigateAndReconnect(protocol, connectionData, router, isNullOrUndefined(newConnectionSettings.paths) ? ['summary'] : newConnectionSettings.paths).subscribe(
                        () => {
                            if (!isNullOrUndefined(navigateAndConnectComplete)) {
                                navigateAndConnectComplete();
                            }
                        }
                    ));
                }), process);
            } else {
                if (!isNullOrUndefined(newConnectionSettings.pleaseWaitDialogs) && newConnectionSettings.pleaseWaitDialogs.length > 0) {
                    newConnectionSettings.pleaseWaitDialogs.forEach(d => d.close());
                }
            }
        }
    }

    public connect(request: ConnectionRequestModel, timeOutMs?: number, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        this._dataPollingService.disabled = true;

        if (this.isConnected === false) {
            this.createConnectDialogRef();

            return this._zone.runOutsideAngular(() => {
                this.isChangingConnectionState = true;

                this.setDialogsActionMessage($localize`Getting device connection`);

                this._request = request;
                return this.getConnectionProviderToken().pipe(
                    flatMap(connectionProviderToken => {
                        request.connectionProviderToken = connectionProviderToken;

                        return this._restApiConnectionService
                            .connectToDevice(request, process)
                            .pipe(
                                flatMap(result => {
                                    if (!isNullOrUndefined(result) && isNullOrUndefined(result.error)) {
                                        this._connectionTokenService.connectionToken = result.token;
                                        this._connectionTokenService.secureToken = result.secureToken;

                                        return this._webSocketService.connect(timeOutMs, process).pipe(
                                            flatMap(connectResult => {
                                                if (connectResult === true) {

                                                    this.setDialogsActionMessage($localize`Getting network devices`);
                                                    return this._deviceService.getDevices(process).pipe(
                                                        flatMap(
                                                            allDevices => {
                                                                if (!isNullOrUndefined(allDevices) && isNullOrUndefined(allDevices.error)) {
                                                                    const masterDevice = allDevices.items.find(d => d.master === true);
                                                                    if (!isNullOrUndefined(masterDevice)) {
                                                                        this._hostFriendlySerial = masterDevice.serialNumber;
                                                                    }

                                                                    switch (request.connectionType) {
                                                                        case 'IP':
                                                                            allDevices.items.forEach(device => {
                                                                                if (device.isLocalUnit) {
                                                                                    this._connectedToFriendlySerial = device.serialNumber;
                                                                                }
                                                                            });

                                                                            return this.setConnectionState(true);
                                                                        case 'Estate Manager':
                                                                            this._connectedToFriendlySerial = request.friendlySerial;
                                                                            return this.setConnectionState(true);
                                                                        case 'serial':
                                                                            if (!isNullOrUndefined(masterDevice)) {
                                                                                this._connectedToFriendlySerial = masterDevice.serialNumber;
                                                                            }
                                                                            return this.setConnectionState(true);
                                                                        default:
                                                                            return throwError(new Error('Unknown connection type'));
                                                                    }
                                                                }
                                                                return throwError(new Error(!isNullOrUndefined(allDevices) ? allDevices.error : 'Unknown devices data error'));
                                                            }
                                                        ),
                                                    );
                                                } else {
                                                    return throwError(new Error('Unknown socket connection error'));
                                                }
                                            }),
                                            catchError((err: any, caught: Observable<boolean>) => this._restApiDisconnectService.disconnect(this._connectionTokenService.connectionToken, process).pipe(flatMap(() => throwError(err)))),
                                        );
                                    }

                                    // Detect if the reason for the failure was authentication, if so show a message
                                    // and then go back to the connection dialog
                                    if(result.error.includes('Failed Authentication')){
                                        this._zone.run(() => {
                                            this._router.navigate(['connect'], { queryParams: { reason: 'Username or Password Incorrect'} });
                                        });
                                    }

                                    return throwError(new Error(!isNullOrUndefined(result) ? result.error : 'Unknown connection error'));
                                }),
                            );
                    }),
                    retryWhen(this._connectionRetryStrategy()),
                    tap(() => {
                        this._dataPollingService.disabled = false;
                        this.isChangingConnectionState = false;
                    })
                );
            });
        } else {
            this._dataPollingService.disabled = false;
            this.isChangingConnectionState = false;
            return of(false);
        }
    }

    public get connectDialogRef(): MatDialogRef<PleaseWaitDialogComponent> {
        return this._connectDialogRef;
    }

    public get connectedToFriendlySerial(): string {
        return this._connectedToFriendlySerial;
    }

    public get connectionOptions(): ConnectionOptionsModel {
        return this._options;
    }
    public set connectionOptions(value: ConnectionOptionsModel) {
        this._options = value;
    }

    public get connectionRequest(): ConnectionRequestModel {
        return this._request;
    }

    public disconnect(): void {
        this.disconnectAsync().subscribe();
    }

    public addPreOfflineHandler(handler: () => Observable<boolean>): void {
        this._preOfflineHandlers.push(handler);
    }

    public removePreOfflineHandler(handler: () => Observable<boolean>): void {
        const index = this._preOfflineHandlers.findIndex(h => h === handler);
        if (index !== -1) {
            this._preOfflineHandlers.splice(index, 1);
        }
    }

    public addPreOnlineHandler(handler: () => Observable<boolean>): void {
        this._preOnlineHandlers.push(handler);
    }

    public removePreOnlineHandler(handler: () => Observable<boolean>): void {
        const index = this._preOnlineHandlers.findIndex(h => h === handler);
        if (index !== -1) {
            this._preOnlineHandlers.splice(index, 1);
        }
    }

    public disconnectAsync(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        if (this.isConnected === true) {
            this._preOnlineHandlers = [];
            this._preOfflineHandlers = [];

            return this.setConnectionState(false).pipe(
                flatMap(() => {
                    this._allDataService.clearAllCache();

                    return this._restApiDisconnectService
                        .disconnect(this._connectionTokenService.connectionToken, process).pipe(
                            catchError(() => of(null)),
                            map(() => {
                                this._webSocketService.disconnect();

                                this._connectionTokenService.connectionToken = null;
                                this._connectionTokenService.secureToken = null;
                                this._connectionStateService.connectionStatus = null;
                                this._connectedToFriendlySerial = null;
                                this._hostFriendlySerial = null;
                                this._options = null;
                                this._request = null;
                                this._showVideoButtonOnConnectComponent = false;
                                this._connected = null;
                                this._providerToken = null;

                                return true;
                            }),
                            finalize(() => {
                                this._webSocketService.disconnect();
                            })
                        );
                }),
                finalize(() => {
                    this._webSocketService.disconnect();
                })
            );
        }

        return of(true);
    }

    public fakeConnected(): void {
        this._zone.run(() => {
            this.connected.next();
        });
    }

    public fakeConnectionEvents(): void {
        if (this.isConnected === true) {
            this.createConnectDialogRef('Getting network devices');
            this.fakeConnected();
            if (this.isOnline === true) {
                this.fakeOnline();
            }
            if (this.isOffline === true) {
                this.fakeOffline();
            }
        } else {
            this.fakeDisconnected();
        }
    }

    public fakeDisconnected(): void {
        this._zone.run(() => {
            this.disconnected.next();
        });
    }

    public fakeOffline(): void {
        this._zone.run(() => {
            this.preOffline().subscribe(() => {
                if (!isNullOrUndefined(this._connectDialogRef)) {
                    this._connectDialogRef.close();
                }
                this.offline.next();
            });
        });
    }

    public fakeOnline(): void {
        this._zone.run(() => {
            this.preOnline().subscribe(result => {
                if (result === true) {
                    if (!isNullOrUndefined(this._connectDialogRef)) {
                        this._connectDialogRef.close();
                    }
                    this.online.next();
                } else {
                    this.fakeOffline();
                }
            });
        });
    }

    public getConnectionOptions(process?: ProcessMonitorServiceProcess): Observable<ConnectionOptionsModel> {
        return this._zone.runOutsideAngular(() => {
            if (isNullOrUndefined(this._options)) {
                return this.getConnectionProviderToken(process).pipe(
                    flatMap(connectionProviderToken => this._restApiConnectionService
                            .getConnectionOptions(connectionProviderToken, process).pipe(
                                map(result => {
                                    this._options = result;
                                    return this._options;
                                })
                            ))
                );
            } else {
                return of(this._options);
            }
        });
    }

    public getConnectionProviderToken(process?: ProcessMonitorServiceProcess): Observable<string> {
        return this._zone.runOutsideAngular(() => {
            const options = new ConnectionProviderOptionModel();
            options.name = this.ConnectionOptionName;

            return this._restApiConnectionProviderService.getConnectionProvider(options, process).pipe(
                map(result => {
                    this._providerToken = result.providerToken;
                    return this._providerToken;
                })
            );
        });
    }

    public getRouteToEndOfConnectionData(): Observable<Array<string>> {
        return RoutesUtility.getRoutesAndParams(this._activatedRoute).pipe(
            map(
                (routesParams) => {
                    const segments: string[] = [];
                    const routesLength = routesParams.routes.length;

                    for (let ri = 0; ri < routesLength; ri++) {
                        const route = routesParams.routes[ri];
                        const routeConfig = route.routeConfig;

                        if (!StringUtility.isEmptyOrWhiteSpace(routeConfig.path)) {
                            const paths = routeConfig.path.split('/');
                            const pathsLength = paths.length;
                            for (let pi = 0; pi < pathsLength; pi++) {
                                const path = paths[pi];
                                if (path.indexOf(':') !== -1) {
                                    segments.push(routesParams.params[path.replace(':', '')]);
                                    if (path === ':connectionData') {
                                        return segments;
                                    }
                                } else {
                                    segments.push(path);
                                }
                            }
                        }
                    }

                    return segments;
                }
            )
        );
    }

    public get goOffLineDialogRef(): MatDialogRef<PleaseWaitDialogComponent> {
        return this._goOffLineDialogRef;
    }

    public goOffline(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._zone.runOutsideAngular(() => {

            this._goOffLineDialogRef = this._dialog.open(PleaseWaitDialogComponent, { data: new PleaseWaitDialogData('Please Wait Switching To Database View'), disableClose: true, minWidth: 250 });
            this._goOffLineDialogRef.afterClosed().subscribe(() => this._goOffLineDialogRef = null);

            this.setDialogsActionMessage('Disconnecting from device');

            this._dataPollingService.disabled = true;
            this.isChangingConnectionState = true;

            return this._restApiConnectionService.goOffline(process).pipe(
                map(() => {
                    this._allDataService.clearAllCache();

                    return true;
                })
            );
        });
    }

    public get goOnLineDialogRef(): MatDialogRef<PleaseWaitDialogComponent> {
        return this._goOnLineDialogRef;
    }

    public goOnline(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._zone.runOutsideAngular(() => {
            this._goOnLineDialogRef = this._dialog.open(PleaseWaitDialogComponent, { data: new PleaseWaitDialogData('Please Wait Switching To Live View'), disableClose: true, minWidth: 250 });
            this._goOnLineDialogRef.afterClosed().subscribe(() => this._goOnLineDialogRef = null);

            this.setDialogsActionMessage('Getting device connection');

            this._dataPollingService.disabled = true;
            this.isChangingConnectionState = true;

            return this._restApiConnectionService.goOnline(process).pipe(
                map(() => {
                    this._allDataService.clearAllCache();

                    return true;
                })
            );
        });
    }

    public get hostFriendlySerial(): string {
        if(!isNullOrUndefined(this._hostFriendlySerial)){
            return this._hostFriendlySerial;
        }

        return this._connectedToFriendlySerial;
    }

    public get isConnected(): boolean {
        return isNullOrUndefined(this._connected) ? false : this._connected;
    }

    public get isDisconnected(): boolean {
        return (this._connectionStateService.connectionStatus === ConnectionStatusEnum.disconnected);
    }

    public get isOffline(): boolean {
        return this._connectionStateService.isOffline;
    }

    public get isOnline(): boolean {
        return this._connectionStateService.isOnline;
    }

    public get isProxyed(): boolean {
        return this._hostFriendlySerial !== this._connectedToFriendlySerial;
    }


    public onConnectionError(error: ConnectionErrorModel): void {
        this._zone.run(() => {
            this.disconnected.next({ errorMessage: error.details });
        });
    }

    public parseConnectionParams(protocol: 'ip' | 'serial' | 'em' | 'direct', connectionData: string): ConnectionRequestModel {
        return this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(protocol) && !isNullOrUndefined(connectionData)) {
                const options = new ConnectionRequestModel();

                if (protocol === 'direct') {
                    this.isOnDevice = true;
                }

                switch (protocol) {
                    case 'direct':
                    case 'ip':
                        options.connectionType = 'IP';

                        const dataIP = connectionData.split(':');
                        if (dataIP.length === 2) {
                            options.hostname = dataIP[0];
                            options.port = dataIP[1];
                            options.live = true;
                        }

                        return options;
                    case 'serial':
                        options.connectionType = 'serial';
                        options.comPort = connectionData;
                        return options;
                    case 'em':
                        options.connectionType = 'Estate Manager';
                        options.friendlySerial = connectionData;
                        return options;
                    case 'direct':
                        options.connectionType = 'Estate Manager';
                        options.friendlySerial = connectionData;
                        return options;
                    default:
                        return null;
                }
            } else {
                return null;
            }
        });
    }

    public get showVideoButtonOnConnectComponent(): boolean {
        return this._showVideoButtonOnConnectComponent;
    }
    public set showVideoButtonOnConnectComponent(value: boolean) {
        if (this._showVideoButtonOnConnectComponent !== value) {
            this._showVideoButtonOnConnectComponent = value;
            this.showVideoButtonChanged.next(value);
        }
    }

    private onConnectionStatusMessageReceived(message: ConnectionStatusModel): void {
        this._connectionStateService.connectionStatus = message.connectionStatus;

        if (this._connected !== null) {
            this.setConnectionStatus(message.connectionStatus).subscribe();
        }
    }

    private preOffline(): Observable<boolean> {
        this.setDialogsActionMessage('Getting device database data');
        return this._allDataService.getAllData().pipe(
            flatMap(() => {
                if (!isNullOrUndefined(this._preOfflineHandlers) && this._preOfflineHandlers.length > 0) {
                    let obs = of(true);
                    this._preOfflineHandlers.forEach(preOfflineHandler => {
                        obs = obs.pipe(
                            flatMap(() => preOfflineHandler())
                        );
                    });
                    return obs;
                } else {
                    return of(true);
                }
            }),
            tap(() => {
                this._dataPollingService.disabled = false;
                this.isChangingConnectionState = false;
            })
        );
    }

    private preOnline(): Observable<boolean> {
        this.setDialogsActionMessage('Validating device network');
        return this._deviceValidationService.validateDevices(this._hostFriendlySerial).pipe(
            flatMap(validateResult => {
                if (validateResult.state !== ValidationResultEnum.noDevices) {
                    if (validateResult.valid === true) {
                        this.setDialogsActionMessage('Getting device data');
                        this._allDataService.clearAllCache();
                        return this._allDataService.getAllData(null, true).pipe(
                            flatMap(() => this._deviceValidationService.validateWideTrackerConfig(this._hostFriendlySerial).pipe(
                                    flatMap(() => {
                                        if (!isNullOrUndefined(this._preOnlineHandlers) && this._preOnlineHandlers.length > 0) {
                                            let obs = of(true);
                                            this._preOnlineHandlers.forEach(preOnlineHandler => {
                                                obs = obs.pipe(
                                                    flatMap(() => preOnlineHandler())
                                                );
                                            });
                                            return obs;
                                        } else {
                                            return of(true);
                                        }
                                    }),
                                ))
                        );
                    } else {
                        return this._allDataService.getAllData().pipe(
                            flatMap(() => {
                                if (!isNullOrUndefined(this._preOnlineHandlers)) {
                                    let obs = of(true);
                                    this._preOnlineHandlers.forEach(preOnlineHandler => {
                                        obs = obs.pipe(
                                            flatMap(() => preOnlineHandler())
                                        );
                                    });
                                    return obs;
                                } else {
                                    return of(true);
                                }
                            }),
                        );
                    }
                } else {
                    // if ValidationResultEnum.noDevices then the device disconnected during validation
                    // return false for failed go online attempt.
                    return of(false);
                }
            }),
            tap(() => {
                this._dataPollingService.disabled = false;
                this.isChangingConnectionState = false;
            })
        );
    }

    private setConnectDialogActionMessage(message: string): void {
        if (!isNullOrUndefined(this._connectDialogRef) && !isNullOrUndefined(this._connectDialogRef.componentInstance)) {
            this._zone.run(() => {
                this._connectDialogRef.componentInstance.setActionMessage(message);
            });
        }
    }

    private setConnectionState(connected: boolean): Observable<boolean> {
        if (this._connected !== connected) {
            if (this._connected === null && this._connectionStateService.connectionStatus === null) {
                // This is to deal with when setConnectionState is called after onConnectionStatusMessageReceived
                // to ensure that online and offline are always called after connected
                this._connected = connected;
                if (connected === true) {
                    this._zone.run(() => {
                        this.connected.next();
                    });
                    if (this.isOnline) {
                        return this.setConnectionStatus(ConnectionStatusEnum.online);
                    } else if (this.isOffline) {
                        return this.setConnectionStatus(ConnectionStatusEnum.offline);
                    }
                } else {
                    this._zone.run(() => {
                        this.disconnected.next();
                    });
                    return this.setConnectionStatus(ConnectionStatusEnum.disconnected);
                }
            } else {
                this._connected = connected;
                if (connected === true) {
                    this._zone.run(() => {
                        this.connected.next();
                    });
                    return this.setConnectionStatus(this._connectionStateService.connectionStatus);
                } else {
                    this._zone.run(() => {
                        this.disconnected.next();
                    });
                    return this.setConnectionStatus(ConnectionStatusEnum.disconnected);
                }
            }
        }

        return of(true);
    }

    private setConnectionStatus(connectionStatus: ConnectionStatusEnum): Observable<boolean> {
        this._connectionStateService.connectionStatus = connectionStatus;
        this.connectionStateChange.next(this._connectionStateService.connectionStatus);

        switch (this._connectionStateService.connectionStatus) {
            case ConnectionStatusEnum.online:
                return this._zone.run(() => this.preOnline().pipe(
                        flatMap((result) => {
                            if (!isNullOrUndefined(this._connectDialogRef)) {
                                this._zone.run(() => {
                                    this._connectDialogRef.close();
                                });
                            }
                            if (!isNullOrUndefined(this._goOnLineDialogRef)) {
                                this._zone.run(() => {
                                    this._goOnLineDialogRef.close();
                                });
                            }

                            if (result === true) {
                                this.online.next();
                                return of(true);
                            } else {
                                return this.goOffline();
                            }
                        }),
                    ));
            case ConnectionStatusEnum.offline:
                return this._zone.run(() => this.preOffline().pipe(
                        tap(() => {
                            if (!isNullOrUndefined(this._connectDialogRef)) {
                                this._zone.run(() => {
                                    this._connectDialogRef.close();
                                });
                            }
                            if (!isNullOrUndefined(this._goOffLineDialogRef)) {
                                this._zone.run(() => {
                                    this._goOffLineDialogRef.close();
                                });
                            }

                            this.offline.next();
                        })
                    ));

            case ConnectionStatusEnum.disconnected:
                this._zone.run(() => {
                    this.disconnected.next();
                });
                return of(true);
        }
    }

    private setDialogsActionMessage(message: string): void {
        this.setConnectDialogActionMessage(message);
        this.setGoOnLineDialogActionMessage(message);
        this.setGoOffLineDialogActionMessage(message);
    }

    private setGoOffLineDialogActionMessage(message: string): void {
        if (!isNullOrUndefined(this._goOffLineDialogRef) && !isNullOrUndefined(this._goOffLineDialogRef.componentInstance)) {
            this._zone.run(() => {
                this._goOffLineDialogRef.componentInstance.setActionMessage(message);
            });
        }
    }

    private setGoOnLineDialogActionMessage(message: string): void {
        if (!isNullOrUndefined(this._goOnLineDialogRef) && !isNullOrUndefined(this._goOnLineDialogRef.componentInstance)) {
            this._zone.run(() => {
                this._goOnLineDialogRef.componentInstance.setActionMessage(message);
            });
        }
    }

    private _connectionRetryStrategy = ({
        maxRetryAttempts = 6,
        scalingDuration = 1000,
    }: {
        maxRetryAttempts?: number;
        scalingDuration?: number;
    } = {}) => (attempts: Observable<any>) => attempts.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;

                if (retryAttempt > maxRetryAttempts) {
                    if (!isNullOrUndefined(this._connectDialogRef)) {
                        this._zone.run(() => {
                            this._connectDialogRef.close();
                        });
                    }
                    this.connectionRetriesExceeded.next(null);
                    return throwError(error);
                }

                this.setDialogsActionMessage(`Attempt ${retryAttempt}`);
                this.connectionRetry.next(retryAttempt);

                return timer(10 * (retryAttempt * scalingDuration));
            })
        );

    private createConnectDialogRef(message?: string): void {
        this._zone.run(() => {
            const dialogData = new PleaseWaitDialogData(isNullOrUndefined(message) ? $localize`Connecting To Device` : message, true);
            this._connectDialogRef = this._dialog.open(PleaseWaitDialogComponent, { data: dialogData, disableClose: true, minWidth: 250 });
            this._connectDialogRef.afterClosed().subscribe(() => this._connectDialogRef = null);
            dialogData.cancel.subscribe(() => this.connectedCancelled.next());
        });
    }
}
