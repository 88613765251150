import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionProviderModel } from '@rift/models/restapi/ConnectionProvider.Model';
import { ConnectionProviderOptionModel } from '@rift/models/restapi/ConnectionProviderOption.Model';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

@Injectable()
export class RestApiConnectionProviderService extends RiftRestApiService {
    private _controller = 'connection_provider';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient);
    }

    public getConnectionProvider(options: ConnectionProviderOptionModel, process?: ProcessMonitorServiceProcess): Observable<ConnectionProviderModel> {
        return this.post<ConnectionProviderOptionModel, ConnectionProviderModel>(`${this._controller}`, options, ConnectionProviderModel, null, process, { disabled: true}).pipe(
            retry(2)
        ).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
