<div class="counting-setting-content">
    <form [formGroup]="form" fxFlex fxLayout="column">
        <mat-form-field>
            <mat-select formControlName="directionType" placeholder="Direction">
                <mat-option [value]="DirectionTypeEnum.UNSET">
                    Not Set
                </mat-option>
                <mat-option [value]="DirectionTypeEnum.IN">
                    IN
                </mat-option>
                <mat-option [value]="DirectionTypeEnum.OUT">
                    OUT
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="50">
            <input matInput formControlName="groupName" placeholder="Group Name">
            <mat-error>{{getFormControlErrorMessage(form.controls['groupName'])}}</mat-error>
        </mat-form-field>
    </form>
</div>