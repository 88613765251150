import { Component, Injector, Input, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsCountingBaseComponent } from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { DirectionTypeEnumHelpers, DirectionTypeEnum } from '@shared/enum/DirectionType.Enum';

@Component({
    selector: 'rift-settings-counting-tags',
    templateUrl: './Settings.Counting.Tags.Component.html',
    styleUrls: ['./Settings.Counting.Tags.Component.scss']
})
export class SettingsCountingTagsComponent extends SettingsCountingBaseComponent implements OnChanges {
    public static className: string = 'SettingsCountingTagsComponent';
    public static directionTypeDefault: DirectionTypeEnum = DirectionTypeEnum.UNSET;

    @HostBinding()
    public id: string = 'rift-settings-counting-tags';

    public DirectionTypeEnum = DirectionTypeEnum;

    public form: FormGroup;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    @Input()
    public register: RegisterBaseModel = null;

    public constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsCountingTagsComponent.className, 'Form values change');

        this.form = this._formBuilder.group(
            {
                directionType: ['', Validators.compose([Validators.required])],
                groupName : ['', Validators.compose([])]
            }
        );

        this.addSubscription(this.observableHandlerBase(this.form.controls.directionType.valueChanges, this.formValuesChangeProcess).subscribe(value => {
            if (!this.isNullOrUndefined(this.register)) {
                this.register.tagDirection = value;
            }
        }), this.formValuesChangeProcess);

        this.addSubscription(this.observableHandlerBase(this.form.controls.groupName.valueChanges, this.formValuesChangeProcess).subscribe(value => {
            if (!this.isNullOrUndefined(this.register)) {
                this.register.tagGroup = value;
            }
        }), this.formValuesChangeProcess);

        this.formGroupTracker.track(this.form);

        this.initConnectionState();
    }

    public getSettingsDescription(): string {
        return '';
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this.register) ? false : this.register.propertyHasChanges('tagDirection') || this.register.propertyHasChanges('tagGroup');
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this.register = changes.register.currentValue;
            this.setDefaults();
            this.setFormValues();
        }
    }

    protected offline(): void {
        super.offline();
        this.form.disable();
    }

    protected online(): void {
        super.online();
        this.form.enable();
    }

    private setDefaults(): void {
        if (this.isNullOrUndefined(this.register.tagDirection)) {
            this.register.tagDirection = SettingsCountingTagsComponent.directionTypeDefault;
        }

        if(this.isNullOrUndefined(this.register.tagGroup)){
            this.register.tagGroup = null;
        }
    }

    private setFormValues(): void {
        this.form.controls.directionType.setValue(this.isNullOrUndefined(this.register.tagDirection) ? SettingsCountingTagsComponent.directionTypeDefault : this.register.tagDirection, { emitEvent: false });
        this.form.controls.groupName.setValue(this.isNullOrUndefined(this.register.tagGroup) ? null : this.register.tagGroup, { emitEvent: false });
    }
}
