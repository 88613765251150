<div #mainContent
    id="em-rift-activity"
    class="em-rift-activity main-content scroll-content"
    fxLayout="column">
    <div class="top-left">
        <button (click)="toggleFilters()"
            class="spacing-right"
            mat-icon-button>
            <shr-fa [name]="showFilters === true ? 'chevron-up' : 'chevron-down'"
                matTooltip="Show hide filters"></shr-fa>
        </button>
        <button (click)="typeFilterSelectAll()"
            class="spacing-right"
            mat-icon-button>
            <shr-fa name="eye"
                matTooltip="Select All"></shr-fa>
        </button>
        <button (click)="typeFilterDeselectAll()"
            mat-icon-button>
            <shr-fa name="eye-slash"
                matTooltip="Deselect All"></shr-fa>
        </button>
        <ul *ngIf="showFilters === true">
            <li *ngFor="let activity of activities"
                [class.selected]="activity.isSelected"
                (click)="typeFilterSelectedToggle(activity)">
                <div>{{ activity.text }}</div>
            </li>
        </ul>
    </div>
    <div *ngIf="showFilters === true"
        class="top-right">
        <ul>
            <ng-template ngFor
                let-vm
                [ngForOf]="dateRanges">
                <ng-template [ngIf]="vm.isYear">
                    <li [class.selected]="vm.isSelected"
                        (click)="monthFilterSelected(vm)">
                        <div>{{vm.yearText}}</div>
                    </li>
                </ng-template>
                <ng-template [ngIf]="vm.isMonth">
                    <li [class.selected]="vm.isSelected"
                        (click)="monthFilterSelected(vm)">
                        <div class="spacing-right">{{vm.monthText}}</div>
                    </li>
                </ng-template>
            </ng-template>
        </ul>
    </div>
    <div class="spacing-right">
        <mat-progress-bar mode="indeterminate"
            *ngIf="activityLoading === true"></mat-progress-bar>
    </div>
    <cdk-virtual-scroll-viewport #virtualViewport
        itemSize="100"
        [style.height.px]="height"
        class="timeline"
        (scrolledIndexChange)="scrolledIndexChange($event)">
        <div [ngStyle]="{ 'width': isSmall === true ? '320px' : '650px', 'height': isSmall === true ? '200px' : '100px' }"
            #virtualFor
            *ngFor="let vm of obsActivityVMs | async; let isEven = even;"
            class="timeline-item">
            <ng-template [ngIf]="vm.isYear">
                <div class="timeline-item-year">
                    <span>{{vm.yearText}}</span>
                </div>
            </ng-template>

            <ng-template [ngIf]="vm.isMonth">
                <div class="timeline-item-month">
                    <span>{{vm.monthText}}</span>
                </div>
            </ng-template>

            <ng-template [ngIf]="vm.isDay">
                <div class="timeline-item-day">
                    <span>{{vm.dayText}}</span>
                </div>
            </ng-template>

            <ng-template [ngIf]="vm.isTime">
                <div class="timeline-item-time">
                    <span>{{vm.item.timeText}}</span>
                </div>

                <div class="timeline-item-info"
                    [class.timeline-item-info-left]="isEven === true"
                    [ngStyle]="{ 'width': isSmall === true ? '114px' : '250px' }"
                    fxLayout="column"
                    fxLayoutAlign="center center">
                    <div fxLayout="row"
                        fxLayoutAlign="center center">
                        {{vm.item.message}}

                        <!-- Snap Shot -->
                        <img shrImageInspectTitle="Device Snapshot"
                            class="snapshot"
                            [ngStyle]="{ 'width': isSmall === true ? '40px' : '70px' }"
                            *ngIf="vm.item.action === DeviceActivityActionEnum.snapshot"
                            [src]="isNullOrUndefined(vm.snapshotSrc) ? 'assets/blank.png' : vm.snapshotSrc">


                        <!-- Backup -->
                        <button mat-raised-button
                            (click)="onDownloadBackupClick(vm)"
                            *ngIf="vm.item.action === DeviceActivityActionEnum.backup">Download</button>
                    </div>
                </div>
            </ng-template>
        </div>
    </cdk-virtual-scroll-viewport>
</div>