<div class="counting-setting-content">
    <div class="spacing-bottom">
        <mat-checkbox [disabled]="isReadOnly"
            [checked]="enabled"
            (change)="onEnabledChange($event)"
            class="spacing-bottom">Enable</mat-checkbox>
    </div>
    <div *ngIf="enabled === true"
        fxLayout="row"
        fxLayoutAlign="start center">
        <form [formGroup]="form"
            fxFlex>
            <mat-form-field fxFlex="50"
                class="spacing-right">
                <input riftUnitsOfMeasurementInput
                    #lowerBoundInput=riftUnitsOfMeasurementInput
                    formControlName="lowerBound"
                    placeholder="Lower Bound"
                    [metricMin]="1"
                    [metricMax]="500"
                    [metricUnit]="UnitOfMeasurementEnum.centimeter"
                    [imperialUnit]="UnitOfMeasurementEnum.inch">
                <span matSuffix>
                    {{lowerBoundInput.suffix}}
                </span>
                <mat-error>{{getFormControlErrorMessage(form.controls['lowerBound'])}}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
                <input riftUnitsOfMeasurementInput
                    #upperBoundInput=riftUnitsOfMeasurementInput
                    formControlName="upperBound"
                    placeholder="Upper Bound"
                    [metricMin]="1"
                    [metricMax]="500"
                    [metricUnit]="UnitOfMeasurementEnum.centimeter"
                    [imperialUnit]="UnitOfMeasurementEnum.inch">
                <span matSuffix>
                    {{upperBoundInput.suffix}}
                </span>
                <mat-error>{{getFormControlErrorMessage(form.controls['upperBound'])}}</mat-error>
            </mat-form-field>
        </form>
    </div>
</div>
<div class="error"
    fxLayout="column">
    {{getFormControlErrorMessage(form)}}
</div>