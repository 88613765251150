import { NgZone } from '@angular/core';
import { DisplayItemCollection } from '@shared/generic/canvas/DisplayItemCollection';
import { Register } from '@rift/components/shared/viewport/registers/Register';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { RegisterBasicModel } from '@rift/models/restapi/RegisterBasic.Model';
import { MarkedTargetEnum } from '@shared/enum/MarkedTarget.Enum';
import { RegisterLineExclusionModel } from '@rift/models/restapi/RegisterLineExclusion.Model';
import { RegisterPortalModel } from '@rift/models/restapi/RegisterPortal.Model';
import { RegisterSmartBoundaryModel } from '@rift/models/restapi/RegisterSmartBoundary.Model';
import { RegisterZoneModel } from '@rift/models/restapi/RegisterZone.Model';
import { RegisterZoneOccupancyModel } from '@rift/models/restapi/RegisterZoneOccupancy.Model';
import { RegisterZoneWaitTimeSumModel } from '@rift/models/restapi/RegisterZoneWaitTimeSum.model';

export class RegisterCollection extends DisplayItemCollection<Register> {
    public constructor(
        protected readonly _zone: NgZone,
        protected readonly _loadQueue: ViewPortLoadQueueService) {
        super(_zone);
        Object.setPrototypeOf(this, Object.create(RegisterCollection.prototype));
    }

    public push(...registers: Array<Register>): number {
        return this._zone.runOutsideAngular(() => {
            const length = registers.length;
            for (let index = 0; index < length; index++) {
                const register = registers[index];
                this.initItem(register);
                register.update();
                super.push(register);
            }

            return this.length;
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            const length = this.length;
            for (let index = 0; index < length; index++) {
                this[index].update();
            }
        });
    }

    public bringToFront(displayItem: Register): void {
        this._zone.runOutsideAngular(() => {
            this.container.setChildIndex(displayItem.container, this.container.children.length - 1);
            this.requireStageUpdate.next();
        });
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
        });
    }

    public markedTargetsEnabled(): boolean{
        return this._zone.runOutsideAngular(() => {
            const length = this.length;
            for (let index = 0; index < length; index++) {
                const reg = this[index];

                if(reg.registerBaseModel.registerType === RegisterTypeEnum.basic ||
                    reg.registerBaseModel.registerType === RegisterTypeEnum.heightLine){
                        if((reg.registerBaseModel as RegisterBasicModel).markedTarget !== MarkedTargetEnum.none){
                            return true;
                        }
                }
                else if(reg.registerBaseModel.registerType === RegisterTypeEnum.lineExclusion){
                    if((reg.registerBaseModel as RegisterLineExclusionModel).markedTarget !== MarkedTargetEnum.none){
                        return true;
                    }
                }
                else if(reg.registerBaseModel.registerType === RegisterTypeEnum.portal){
                    if((reg.registerBaseModel as RegisterPortalModel).markedTarget !== MarkedTargetEnum.none){
                        return true;
                    }
                }
                else if(reg.registerBaseModel.registerType === RegisterTypeEnum.smartBoundary){
                    if((reg.registerBaseModel as RegisterSmartBoundaryModel).markedTarget !== MarkedTargetEnum.none){
                        return true;
                    }
                }
                else if(reg.registerBaseModel.registerType === RegisterTypeEnum.zone){
                    if((reg.registerBaseModel as RegisterZoneModel).markedTarget !== MarkedTargetEnum.none){
                        return true;
                    }
                }
                else if(reg.registerBaseModel.registerType === RegisterTypeEnum.zoneOccupancy){
                    if((reg.registerBaseModel as RegisterZoneOccupancyModel).markedTarget !== MarkedTargetEnum.none){
                        return true;
                    }
                }
                else if(reg.registerBaseModel.registerType === RegisterTypeEnum.zoneWaitTimeSum){
                    if((reg.registerBaseModel as RegisterZoneWaitTimeSumModel).markedTarget !== MarkedTargetEnum.none){
                        return true;
                    }
                }
            }

            return false;
        });
    }
}
