import { isNullOrUndefined, isFunction } from '@shared/utility/General.Utility';

export class DateTimeNonMomentUtility {
    /**
     * Converts a rest api date string to a Date.
     *
     * @static
     * @param {*} dateTime The rest api date to convert.
     * @returns {Date} The Date representation of the rest api date.
     * @memberof DateTimeUtility
     */
    public static fromRestApiDateTime(dateTime: any): Date {
        let timestamp = Date.parse(dateTime);
        if (Number.isNaN(timestamp) === false) {
            return new Date(timestamp);
        } else {
            timestamp = Date.parse(dateTime);
            if (Number.isNaN(timestamp) === false) {
                return new Date(timestamp);
            }
        }
    }

    /**
     * Converts a Date to a rest api date string.
     *
     * @static
     * @param {Date} dateTime The Date to convert.
     * @returns {string} The rest api date string.
     * @memberof DateTimeUtility
     */
    public static toRestApiDateTime(dateTime: Date): string {
        if (!isNullOrUndefined(dateTime) && !isNullOrUndefined(dateTime.toJSON) && isFunction(dateTime.toJSON)) {
            return dateTime.toJSON();
        } else {
            return null;
        }
    }
}
