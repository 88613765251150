import { RegisterBaseModel, REGISTER_BASE_EXCLUDE_PROPERTIES } from '@rift/models/restapi/RegisterBase.Model';
import { MarkedTargetEnum } from '@shared/enum/MarkedTarget.Enum';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { LineExclusionTypeEnum } from '@shared/enum/LineExclusionRegisterType.Enum';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterLineExclusionModel extends IRegisterBaseModel {
    polygon: number;
    line: number;
    markedTarget: MarkedTargetEnum;
    heightFilterEnabled: boolean;
    lowerBound: number;
    upperBound: number;
}

export class RegisterLineExclusionModel extends RegisterBaseModel implements IRegisterLineExclusionModel, IRestModel {

    public polygon: number = null;
    public line: number = null;
    public markedTarget: MarkedTargetEnum = MarkedTargetEnum.none;
    public lowerBound: number = null;
    public upperBound: number = null;
    public heightFilterEnabled: boolean = null;
    public lineExclusionRegisterType: LineExclusionTypeEnum = null;

    public constructor() {
        super();

        this.registerProperty('polygon');
        this.registerProperty('line');
        this.registerProperty('lowerBound');
        this.registerProperty('upperBound');
        this.registerProperty('markedTarget');
        this.registerProperty('heightFilterEnabled');
        this.registerProperty('lineExclusionRegisterType');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterLineExclusionModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('polygon', this.polygon);
        this.setPropertyOriginalValue('line', this.line);
        this.setPropertyOriginalValue('lowerBound', this.lowerBound);
        this.setPropertyOriginalValue('upperBound', this.upperBound);
        this.setPropertyOriginalValue('markedTarget', this.markedTarget);
        this.setPropertyOriginalValue('heightFilterEnabled', this.heightFilterEnabled);
        this.setPropertyOriginalValue('lineExclusionRegisterType', this.lineExclusionRegisterType);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([this.line], [], [this.polygon]);
    }
}
