import { Component, HostBinding, HostListener, Inject, Injector, OnDestroy } from '@angular/core';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { WideTrackerService } from '@rift/service/data/widetracker/WideTracker.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { NavBarActionService } from '@shared/service/navbaraction/NavBarAction.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

export class UniqueMasterSelectDialogData {
    public constructor(public readonly devices: Array<DeviceModel>) {
    }
}

export class UniqueMasterSelectDialogResult {
}

@Component({
    selector: 'rift-validate-counting-network-unique-master-select-dialog',
    templateUrl: './UniqueMasterSelect.Dialog.Component.html',
    styleUrls: ['./UniqueMasterSelect.Dialog.Component.scss'],
})
export class UniqueMasterSelectDialogComponent extends BaseComponent implements ISaveAllChanges, OnDestroy {
    public static className: string = 'RelayMasterSelectDialogComponent';

    @HostBinding()
    public id: string = 'rift-validate-counting-network-unique-master-select-dialog';

    public master: DeviceModel = null;

    public isNullOrUndefined = isNullOrUndefined;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: UniqueMasterSelectDialogData,
        private readonly _dialogRef: MatDialogRef<UniqueMasterSelectDialogComponent>,
        private readonly _wideTrackerService: WideTrackerService,
        private readonly _dialog: MatDialog,
        private readonly _navBarService: NavBarActionService,
        private readonly _injector: Injector) {
        super(_injector, _dialog, _navBarService);

        this._dialogRef.disableClose = true;

        this.saveAllChangesProcess = this.processMonitorService.getProcess(UniqueMasterSelectDialogComponent.className, this.saveAllChangesProcessText);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public get hasChanges(): boolean {
        return !isNullOrUndefined(this.master);
    }

    public get isValid(): boolean {
        return !isNullOrUndefined(this.master);
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            this._wideTrackerService.setMasterAndNodes(this.master.serialNumber, process).pipe(
                map(() => true)
            ),
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            tap(() => {
                this._dialogRef.close(new UniqueMasterSelectDialogResult());
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this);
    }

    public onSaveClick(): void {
        this.saveAllChangesStartBase(this, this.openPleaseWaitSavingDialog());
    }
}
