<div class="spacing-bottom" fxLayout="row">
    <shr-background-load-spinner [trigger]="loadDataProcess.isRunning" class="led"></shr-background-load-spinner>
    <nav fxFlex mat-tab-nav-bar fxLayout="row" fxLayoutAlign="center center">
        <a mat-tab-link routerLink="/settings/schedules/list/active" routerLinkActive #active="routerLinkActive"
            [active]="active.isActive">
            Active {{numActive > 0 ? "(" + numActive + ")" : ""}}
        </a>
        <a mat-tab-link routerLink="/settings/schedules/list/finished" routerLinkActive #finished="routerLinkActive"
            [active]="finished.isActive">
            Finished {{numFinished > 0 ? "(" + numFinished + ")" : ""}}
        </a>
        <a mat-tab-link routerLink="/settings/schedules/list/disabled" routerLinkActive #disabled="routerLinkActive"
            [active]="disabled.isActive">
            Disabled {{numDisabled > 0 ? "(" + numDisabled + ")" : ""}}
        </a>
        <a mat-tab-link routerLink="/settings/schedules/list/system" routerLinkActive #system="routerLinkActive"
            [active]="system.isActive">
            System {{numSystem > 0 ? "(" + numSystem + ")" : ""}}
        </a>
    </nav>
</div>

<shr-table matSort #sort="matSort" [sort]="sort" (filter)="applyFilter($event)" [dataSource]="dataSource"
    [displayedColumns]="displayedColumns" [isLoadingData]="loadDataProcess.isRunning"
    [dataRefreshedTrigger]="refreshDataProcess.isRunning" [dataExportEnabled]="true"
    [dataExportGetDataHandler]="dataExportGetData" [dataExportGetHeaderHandler]="dataExportGetHeader">
    <ng-template #shrTableHeader>
        {{ active.isActive ? 'Active Schedules' : finished.isActive ? 'Finished Schedules' : disabled.isActive ? 'Disabled Schedules'
          : system.isActive ? 'System Schedules' : 'Schedules'}}
    </ng-template>

    <ng-template #shrTableActions let-schedule>
        <button matTooltip="View" mat-icon-button (click)="view(schedule)">
            <mat-icon>pageview</mat-icon>
        </button>
        <button *ngIf="(state === 'active' || state === 'disabled') && (userIsSystemAdmin | async) === true"
            matTooltip="Edit" mat-icon-button (click)="edit(schedule)">
            <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="(state === 'active' || state === 'disabled') && (userIsSystemAdmin | async) === true"
            matTooltip="{{schedule.isDisabled === false ? 'Disable' : 'Enable'}}" mat-icon-button
            (click)="disable(schedule)" color="warn">
            <mat-icon>{{schedule.isDisabled === false ? 'not_interested' : 'done'}}</mat-icon>
        </button>
        <button
            *ngIf="(state === 'active' || state === 'disabled' || state === 'finished') && (userIsSystemAdmin | async) === true && schedule.isUserSchedule === true"
            matTooltip="Delete" mat-icon-button (click)="delete(schedule)" color="warn">
            <mat-icon>delete</mat-icon>
        </button>
    </ng-template>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 100px;"> Progress </th>
        <td mat-cell *matCellDef="let schedule" style="min-width: 100px;">
            <mat-progress-bar *ngIf="schedule.isCurrentlyExecuting === true" mode="determinate"
                [value]="schedule.currentProgress"></mat-progress-bar>
            <div *ngIf="schedule.isCurrentlyExecuting === false">
                Idle
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let schedule">
            <shr-table-cell [data]="schedule" keyPath="description"></shr-table-cell>
        </td>
    </ng-container>

    <ng-container matColumnDef="schedule">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Schedule </th>
        <td mat-cell *matCellDef="let schedule">
            <shr-table-cell [data]="schedule" keyPath="name"></shr-table-cell>
        </td>
    </ng-container>

    <ng-container matColumnDef="operatesOn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Operates On </th>
        <td mat-cell *matCellDef="let schedule">
            <shr-table-cell [data]="schedule" keyPath="operatesOnText"></shr-table-cell>
        </td>
    </ng-container>

    <ng-container matColumnDef="lastExecuted">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="lastExecuted"> Last Executed </th>
        <td mat-cell *matCellDef="let schedule">
            <shr-table-cell [data]="schedule" keyPath="lastExecutedText"></shr-table-cell>
        </td>
    </ng-container>

    <ng-container matColumnDef="nextExecution">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="nextExecution"> Next Execution </th>
        <td mat-cell *matCellDef="let schedule">
            <span
                matTooltip="{{schedule.isCurrentlyExecuting === true ? 'Running...' : schedule.nextExecutionText}}">{{schedule.isCurrentlyExecuting === true ? 'Running...' : schedule.nextExecutionText}}
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="success">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Success </th>
        <td mat-cell *matCellDef="let schedule">
            <div *ngIf="schedule?.isNA === true">N/A</div>
            <shr-table-cell *ngIf="schedule?.isNA === false" [data]="schedule" keyPath="successRatePercentage"
                postFix="%"></shr-table-cell>
        </td>
    </ng-container>

    <ng-container matColumnDef="coverage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Coverage </th>
        <td mat-cell *matCellDef="let schedule">
            <div *ngIf="schedule?.isNA === true">N/A</div>
            <shr-table-cell *ngIf="schedule?.isNA === false" [data]="schedule" keyPath="coveragePercentage" postFix="%">
            </shr-table-cell>
        </td>
    </ng-container>
</shr-table>
