import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';
import { BaseModel } from '@shared/base/Base.Model';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { isArray, isNullOrUndefined } from '@shared/utility/General.Utility';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterBaseCollectionModel extends IRestModel {
    items: Array<IRegisterBaseModel>;
}

export class RegisterBaseCollectionModel extends BaseModel implements IRegisterBaseCollectionModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public statusCode: number = null;
    public items: RestModelChangeTrackerArray<RegisterBaseModel> = new RestModelChangeTrackerArray<RegisterBaseModel>();

    public constructor() {
        super();

        this.registerChangeTrackerArray('items');
    }

    public loadFromRestApiModel(restModel: any): void {
        if (!isNullOrUndefined(restModel.Error)) {
            this.error = restModel.Error;
        }
        if (!isNullOrUndefined(restModel.StatusCode)) {
            this.error = restModel.StatusCode;
        }

        if (!isNullOrUndefined(restModel) && isArray(restModel)) {
            this.items = new RestModelChangeTrackerArray<RegisterBaseModel>(...(restModel as Array<any>).map(data => RegisterBaseUtility.getRegisterTypeFromRestApiModel(data)));
        }

        this.setPropertyOriginalValue('items', this.items);
    }

    public toRestApiModel(): any {
        return RestModelUtility.toJsonArray(this.items);
    }
}
