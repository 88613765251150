import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IValidationReportLineModel extends IRestModel {
    systemCount: number;
    userCount: number;
    accuracy: number;
    name: string;
    mode: string;
}

export class ValidationReportLineModel extends BaseModel implements IValidationReportLineModel, IRestModel {
    public readonly isIRestModel = true;

    public error: string = null;
    public systemCount: number = null;
    public userCount: number = null;
    public accuracy: number = null;
    public name: string = null;
    public mode: string = null;

    public constructor() {
        super();
    }

    public loadFromRestApiModel(restModel: any, upperCase: boolean = true): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-ValidationReportLineModel', null, upperCase);
    }

    public toRestApiModel(): any {
        return {
            ...RestModelUtility.toJson(this),
        };
    }

    public toInterface(): IValidationReportLineModel {
        return {
            systemCount: this.systemCount,
            userCount: this.userCount,
            accuracy: this.accuracy,
            name: this.name,
            mode: this.mode,
        } as IValidationReportLineModel;
    }
}
