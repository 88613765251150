<div fxLayout="row">
    <div *ngFor="let validateRegister of validateRegisters; let i = index" class="card">
        <div class="title" [style.background-color]="validateRegister.register.fillColor"
            [matTooltip]="validateRegister.register.registerName">
            {{validateRegister.displayName}}
        </div>
        <div class="button-container">
            <button [disabled]="disabled" mat-button (click)="incrementClick(validateRegister)" [class.ping]="pingRegister === validateRegister && pingDirection === 'increment'">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <shr-fa class="fas" size="2x" name="chevron-circle-up"></shr-fa>
                    <div class="key">{{ keyBindings[i].increment }}</div>
                </div>
            </button>
        </div>
        <div class="button-container">
            <button [disabled]="disabled" mat-button (click)="decrementClick(validateRegister)" [class.ping]="pingRegister === validateRegister && pingDirection === 'decrement'">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <shr-fa class="fas" size="2x" name="chevron-circle-down"></shr-fa>
                    <div class="key">{{ keyBindings[i].decrement }}</div>
                </div>
            </button>
        </div>
    </div>
</div>
