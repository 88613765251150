import { Component, HostBinding, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import {
    SettingsCountingBaseComponent,
} from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { DiscoveredDeviceRegisterModel } from '@rift/models/restapi/DiscoveredDeviceRegister.Model';
import { RegisterRemoteValueModel } from '@rift/models/restapi/RegisterRemoteValue.Model';
import { WideTrackerService } from '@rift/service/data/widetracker/WideTracker.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { MarkedTargetEnum } from '@shared/enum/MarkedTarget.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { InputSourceModel } from '@rift/models/restapi/InputSource.Model';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';

export interface IRegisterRemote {
    name: string;
    selected: boolean;
    register: DiscoveredDeviceRegisterModel;
}

export interface IDeviceRemote {
    serial: string;
    registers: IRegisterRemote[];
    selectedRegisterCount: number;
}

@Component({
    selector: 'rift-settings-counting-remote',
    templateUrl: './Settings.Counting.Remote.Component.html',
    styleUrls: ['./Settings.Counting.Remote.Component.scss']
})
export class SettingsCountingRemoteComponent extends SettingsCountingBaseComponent implements OnChanges, ILoadDate {
    public static className: string = 'SettingsCountingRemoteComponent';

    private _remoteRegister: RegisterRemoteValueModel = null;

    @HostBinding()
    public id: string = 'rift-settings-counting-remote';

    public MarkedTargetEnum = MarkedTargetEnum;

    @Input()
    public set register(value: RegisterBaseModel){
        this._remoteRegister = (value as RegisterRemoteValueModel);
    }

    public devices: IDeviceRemote[];
    public selectedDevice: IDeviceRemote;
    public selectedRegisters: IRegisterRemote[];

    public constructor(
        private readonly _wideTrackerService: WideTrackerService,
        private readonly _dialog: MatDialog,
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingRemoteComponent.className, this.loadDataProcessText);

        this.initConnectionState();
    }

    public loadData(pleaseWaitDialogRequest?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._wideTrackerService.discoveredDevices(process).pipe(
                map((result) => {
                    this.devices = result.items.filter(d => d.master === true).map(d => ({
                            serial: d.serialNumber,
                            selected: false,
                            registers: d.registers.map(r => ({
                                    name: r.registerName,
                                    selected: false,
                                    register: r,
                                })),
                            selectedRegisterCount: 0,
                        }));
                    this.getInputSources();
                    return true;
                })
            )
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRequest, process);
    }

    public deviceSelected(event: MatSelectChange): void {
        this.selectedDevice = event.value;
        this.selectedRegisters = this.selectedDevice.registers.filter(r => r.selected === true);
    }

    public registerSelected(event: MatSelectChange): void {
        if (!this.isNullOrUndefined(this.selectedDevice)) {
            this.selectedDevice.registers.forEach(dr => {
                dr.selected = false;
            });
            event.value.forEach(sr => {
                sr.selected = true;
            });
            this.selectedDevice.selectedRegisterCount = ArrayUtility.count(this.selectedDevice.registers, (r) => r.selected === true);
            this.setInputSources();
        }
    }

    public getSettingsDescription(): string {
        return this.isNullOrUndefined(this._remoteRegister) ? '' : '';
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this._remoteRegister) ? false : this._remoteRegister.propertyHasChanges('remoteValueIndex') || this._remoteRegister.propertyHasChanges('type') || this._remoteRegister.propertyHasChanges('inputSources');
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this._remoteRegister = changes.register.currentValue;
            this.getInputSources();
        }
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this);
    }

    private setInputSources(): void {
        this._remoteRegister.inputSources = this.devices.filter(d => d.selectedRegisterCount > 0).map(d => {
            const inputSource = new InputSourceModel();
            inputSource.deviceSerial = d.serial;
            inputSource.registers = d.registers.filter(r => r.selected === true).map(r => r.register.registerUUID);
            return inputSource;
        });
    }

    private getInputSources(): void {
        if (!isNullOrUndefined(this.devices)) {
            this.devices.forEach(i => {
                i.selectedRegisterCount = 0;
            });

            if (!isNullOrUndefined(this._remoteRegister) && !isNullOrUndefined(this._remoteRegister.inputSources)) {
                this._remoteRegister.inputSources.forEach(inputSource => {
                    this.devices.forEach(device => {
                        if (device.serial === inputSource.deviceSerial) {
                            device.selectedRegisterCount = 0;
                            device.registers.forEach(deviceRegister => {
                                inputSource.registers.forEach(rUuid => {
                                    if (deviceRegister.register.registerUUID === rUuid) {
                                        deviceRegister.selected = true;
                                        device.selectedRegisterCount++;
                                    }
                                });
                            });
                        }
                    });
                });
            }
        }
    }
}
