import { Component, HostBinding, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import {
    SettingsCountingBaseComponent,
} from '@rift/components/settings/counting/shared/settings/base/SettingsCountingBase.Component';
import { DiscoveredDeviceRegisterModel } from '@rift/models/restapi/DiscoveredDeviceRegister.Model';
import { WideTrackerService } from '@rift/service/data/widetracker/WideTracker.Service';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { MarkedTargetEnum } from '@shared/enum/MarkedTarget.Enum';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { Observable, zip } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RegisterPushEntryModel } from '@rift/models/restapi/RegisterPushEntry.Model';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';
import { RegisterRemoteReceiverModel } from '@rift/models/restapi/RegisterRemoteReceiver.Model';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { DiscoveredDeviceModel } from '@rift/models/restapi/DiscoveredDevice.Model';

export interface IRegisterRemote {
    name: string;
    selected: boolean;
    used: boolean;
    register: DiscoveredDeviceRegisterModel;
}

export interface IDeviceRemote {
    serial: string;
    registers: IRegisterRemote[];
    selectedRegisterCount: number;
}

@Component({
    selector: 'rift-settings-counting-remote-push',
    templateUrl: './Settings.Counting.RemotePush.Component.html',
    styleUrls: ['./Settings.Counting.RemotePush.Component.scss']
})
export class SettingsCountingRemotePushComponent extends SettingsCountingBaseComponent implements OnChanges, ILoadDate {
    public static className: string = 'SettingsCountingRemotePushComponent';

    @HostBinding()
    public id: string = 'rift-settings-counting-remote-push';

    @Input()
    public register: RegisterBaseModel = null;

    public MarkedTargetEnum = MarkedTargetEnum;

    public devices: IDeviceRemote[];
    public selectedDevice: IDeviceRemote;
    public selectedRegisters: IRegisterRemote[];

    public constructor(
        private readonly _registerService: RegisterService,
        private readonly _wideTrackerService: WideTrackerService,
        private readonly _dialog: MatDialog,
        private readonly _formBuilder: FormBuilder,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingRemotePushComponent.className, this.loadDataProcessText);

        this.initConnectionState();
    }

    public loadData(pleaseWaitDialogRequest?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this._registerService.getRegisters(process).pipe(
                flatMap((registersResult) => this._wideTrackerService.discoveredDevices(process).pipe(
                        map((result) => {
                            this.devices = result.items.filter(d => d.master === true).map(d => ({
                                    serial: d.serialNumber,
                                    selected: false,
                                    registers: d.registers.filter(r => r.register.registerType === RegisterTypeEnum.remoteReceiver).map(r => ({
                                            name: r.registerName,
                                            selected: false,
                                            register: r,
                                            used: this.isLinkedToPushRegister(registersResult.items, r, d.serialNumber)
                                        })),
                                    selectedRegisterCount: 0,
                                }));
                            this.getRegisterPushEntries();
                            return true;
                        })
                    )),
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRequest, process);
    }

    public isLinkedToPushRegister(registers: RegisterBaseModel[], discoveredRegister: DiscoveredDeviceRegisterModel, serialNumber: string): boolean {
        if(!isNullOrUndefined(this.register)){
            return registers.some(r => r.registerIndex !== this.register.registerIndex && r.registerPushEntries.some(pe => pe.devices.some(d => d === serialNumber) && pe.remoteRegister.remoteReceiverIndex === (discoveredRegister.register as any).remoteReceiverIndex));
        }

        return false;
    }

    public deviceSelected(event: MatSelectChange): void {
        this.selectedDevice = event.value;
        this.selectedRegisters = this.selectedDevice.registers.filter(r => r.selected === true);
    }

    public registerSelected(event: MatSelectChange): void {
        if (!this.isNullOrUndefined(this.selectedDevice)) {
            this.selectedDevice.registers.forEach(dr => {
                dr.selected = false;
            });
            event.value.forEach(sr => {
                sr.selected = true;
            });
            this.selectedDevice.selectedRegisterCount = ArrayUtility.count(this.selectedDevice.registers, (r) => r.selected === true);
            this.setRegisterPushEntries();
        }
    }

    public getSettingsDescription(): string {
        return this.isNullOrUndefined(this.register) ? '' : '';
    }

    public get hasChanges(): boolean {
        return this.isNullOrUndefined(this.register) ? false : this.register.propertyHasChanges('remoteValueIndex') || this.register.propertyHasChanges('type') || this.register.propertyHasChanges('inputSources');
    }

    public get isValid(): boolean {
        return this.isValidBase;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.register) && !this.isNullOrUndefined(changes.register.currentValue)) {
            this.register = changes.register.currentValue;
            this.getRegisterPushEntries();
        }
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this);
    }

    private setRegisterPushEntries(): void {
        const entries: RegisterPushEntryModel[] = [];

        const remoteDevicesLength = this.devices.length;
        for (let dI = 0; dI < remoteDevicesLength; dI++) {
            const remoteDevice = this.devices[dI];

            const remoteRegistersLength = remoteDevice.registers.length;
            for (let rrI = 0; rrI < remoteRegistersLength; rrI++) {
                const remoteRegister = remoteDevice.registers[rrI];

                if (remoteRegister.selected === true) {
                    let entry = entries.find(i => i.remoteRegister.remoteReceiverIndex === (remoteRegister.register.register as any).remoteReceiverIndex);
                    if (isNullOrUndefined(entry)) {
                        entry = new RegisterPushEntryModel();
                        entry.devices = [remoteDevice.serial];
                        entry.remoteRegister = remoteRegister.register.register as RegisterRemoteReceiverModel;
                        entries.push(entry);
                    } else {
                        entry.devices.push(remoteDevice.serial);
                    }
                }
            }
        }

        this.register.registerPushEntries = entries;
    }

    private getRegisterPushEntries(): void {
        if (!isNullOrUndefined(this.devices)) {
            this.devices.forEach(i => {
                i.selectedRegisterCount = 0;
            });
            if (!isNullOrUndefined(this.register) && !isNullOrUndefined(this.register.registerPushEntries)) {
                const pushEntriesLength = this.register.registerPushEntries.length;
                for (let peI = 0; peI < pushEntriesLength; peI++) {
                    const pushEntry = this.register.registerPushEntries[peI];

                    const remoteDevicesLength = this.devices.length;
                    for (let dI = 0; dI < remoteDevicesLength; dI++) {
                        const remoteDevice = this.devices[dI];

                        if (pushEntry.devices.some(s => s === remoteDevice.serial)) {
                            remoteDevice.registers.forEach(remoteRegister => {
                                if ((remoteRegister.register.register as any).remoteReceiverIndex === pushEntry.remoteRegister.remoteReceiverIndex) {
                                    remoteRegister.selected = true;
                                    remoteDevice.selectedRegisterCount++;
                                }
                            });
                        }
                    }
                }
            }
        }
    }
}
