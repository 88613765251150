import { Component, EventEmitter, HostBinding, HostListener, Injector, Input, NgZone, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsCountingMenuBaseComponent } from '@rift/components/settings/counting/base/Settings.Counting.MenuBase.Component';
import { UnitsOfMeasurementInputValidators } from '@rift/directives/unitsofmeasurementinput/UnitsOfMeasurementInput.Directive';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { HeightRanges } from '@rift/shared/Settings.Device.Height';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { AutoHeightDetectionStateEnum, AutoHeightDetectionStateEnumHelpers } from '@shared/enum/AutoHeightDetectionState.Enum';
import { DeviceCapabilitiesEnum } from '@shared/enum/DeviceCapabilities.Enum';
import { DeviceLensTypeEnumHelpers } from '@shared/enum/DeviceLensType.Enum';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { IPosition } from 'angular2-draggable';
import { ISize } from 'angular2-draggable/lib/models/size';
import { Observable, of, zip, timer } from 'rxjs';
import { map, tap, flatMap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TimeOfFlightFrameRateEnum } from '@shared/enum/TimeOfFlightFrameRate.Enum';
import { TimeOfFlightConfigDataModel } from '@rift/models/restapi/TimeOfFlightConfigData.Model';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';
import { UnitOfMeasurementEnum } from '@shared/enum/UnitOfMeasurement.Enum';

export interface IGVector {
    x: number;
    y: number;
    z: number;
    yaw: number;
    device: string;
}

export enum MoveStatesEnum {
    notMoving = 0,
    moving = 1,
    moved = 2,
}

export class ToFFrameRates {
    public id: TimeOfFlightFrameRateEnum;
    public name: string;
}

@Component({
    selector: 'rift-settings-counting-edit-device',
    templateUrl: './Settings.Counting.EditDevice.Component.html',
    styleUrls: ['./Settings.Counting.EditDevice.Component.scss'],
})
export class SettingsCountingEditDeviceComponent extends SettingsCountingMenuBaseComponent implements OnChanges, ISaveAllChanges, OnDestroy {
    public static className: string = 'SettingsCountingEditDeviceComponent';

    @Output()
    public realTimeUpdate: EventEmitter<IGVector> = new EventEmitter<IGVector>();

    @Input()
    public editDevice: DeviceModel;

    @HostBinding()
    public id: string = 'rift-settings-counting-edit-device';

    @LocalStorage(SettingsCountingEditDeviceComponent.className, 'position')
    public position: IPosition;

    @LocalStorage(SettingsCountingEditDeviceComponent.className, 'show')
    public show: boolean;

    public AutoHeightDetectionStateEnum = AutoHeightDetectionStateEnum;
    public AutoHeightDetectionStateEnumHelpers = AutoHeightDetectionStateEnumHelpers;
    public DeviceLensTypeEnumHelpers = DeviceLensTypeEnumHelpers;
    public HeightRanges = HeightRanges;
    public autoDetectHeightProcess: ProcessMonitorServiceProcess;
    public form: FormGroup = null;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;
    public pitch: string = null;
    public realtimeYawAdjustmentEnabled: boolean = false;
    public refreshTiltProcess: ProcessMonitorServiceProcess;
    public roll: string = null;
    public size: ISize;
    public yaw: string = null;
    public tofFrameRates: ToFFrameRates[] = [
        { id: TimeOfFlightFrameRateEnum.fifteenTwentySix, name: 'Default' },
        { id: TimeOfFlightFrameRateEnum.elevenFortyFour, name: 'Reduced Frame Rate' }
    ];

    private _isTiltCapable: boolean;

    public constructor(
        private readonly _render: Renderer2,
        private readonly _zone: NgZone,
        private readonly _formBuilder: FormBuilder,
        private readonly _eventsService: EventsService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_render, _injector, _dialog);
        this.setAutoStates();

        this.minWidth = 460;

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsCountingEditDeviceComponent.className, 'Form values change');
        this.autoDetectHeightProcess = this.processMonitorService.getProcess(SettingsCountingEditDeviceComponent.className, 'Auto detecting device height');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingEditDeviceComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsCountingEditDeviceComponent.className, this.saveAllChangesProcessText);
        this.refreshTiltProcess = this.processMonitorService.getProcess(SettingsCountingEditDeviceComponent.className, 'Refresh tilt.');

        this.form = this._formBuilder.group({
            x: ['', Validators.compose([Validators.required, UnitsOfMeasurementInputValidators.min(), UnitsOfMeasurementInputValidators.max()])],
            y: ['', Validators.compose([Validators.required, UnitsOfMeasurementInputValidators.min(), UnitsOfMeasurementInputValidators.max()])],
            height: ['', Validators.compose([Validators.required, UnitsOfMeasurementInputValidators.min(), UnitsOfMeasurementInputValidators.max()])],
            yaw: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(360)])],
            timeOfFlightFrameRate: ['', Validators.nullValidator]
        });

        this.addSubscription(
            this.observableHandlerBase(this.form.valueChanges, this.formValuesChangeProcess).subscribe(() => {
                if (!this.isNullOrUndefined(this.editDevice)) {
                    if (this.isTiltCapable) {
                        const yaw = parseFloat(this.form.controls.yaw.value);
                        if (!Number.isNaN(yaw)) {
                            this.editDevice.gVector.yaw = yaw * (Math.PI / 180);
                        }
                    }
                    this.editDevice.x = parseInt(this.form.controls.x.value, 10);
                    this.editDevice.y = parseInt(this.form.controls.y.value, 10);
                }
            })
            , this.formValuesChangeProcess);

        this.addSubscription(
            this.observableHandlerBase(this.form.controls.height.valueChanges, this.formValuesChangeProcess).subscribe(() => {
                if (!this.isNullOrUndefined(this.editDevice)) {
                    let height = null;
                    if (this.unitsOfMeasurementService.units === UnitsOfMeasurementEnum.imperial) {
                        height = this.unitsOfMeasurementService.convertToMetric(this.form.controls.height.value, UnitOfMeasurementEnum.centimeter, UnitOfMeasurementEnum.inch, 2);
                    } else {
                        height = this.form.controls.height.value;
                    }
                    this.editDevice.height = parseInt(height, 10);
                }
            })
            , this.formValuesChangeProcess);

        this.formGroupTracker.track(this.form);

        this.initConnectionState();
    }

    @Input()
    public get bounds(): HTMLElement {
        return this._bounds;
    }
    public set bounds(value: HTMLElement) {
        this._bounds = value;
        this.checkPosition();
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isTiltCapable(): boolean {
        if (!this.isNullOrUndefined(this.editDevice)) {
            if (this.isNullOrUndefined(this._isTiltCapable)) {
                this._isTiltCapable = this.editDevice.isCapable(DeviceCapabilitiesEnum.tilt) || this.editDevice.isCapable(DeviceCapabilitiesEnum.correctiveTilt);
            }
            return this._isTiltCapable;
        }
        return false;
    }

    public get isValid(): boolean {
        return this.show ? this.isValidBase : true;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.editDevice) && this.isNullOrUndefined(changes.editDevice.currentValue)) {
            this.close();
        }

        if (!this.isNullOrUndefined(changes.editDevice) && !this.isNullOrUndefined(changes.editDevice.currentValue)) {
            this.setAutoStates();

            const device: DeviceModel = changes.editDevice.currentValue;
            this.changeTracker.clear();
            this.changeTracker.track(device);
            if (this.isTiltCapable) {
                this.changeTracker.track(device.gVector);
            }
            this.setFormValues(device);
            this.setFormYaw(device);
            this.setYawPitchRoll(device.gVector);
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public onAutoDetectClick(): void {
        this.autoDetectHeight();
    }

    public onRefreshTiltClick(): void {
        this.refreshTilt();
    }

    public onSaveDeviceClick(): void {
        this.saveAllChangesStartBase(this, this.openPleaseWaitSavingDialog());
    }

    public reset(): void {
        this.changeTracker.clearChanges();
        this.editDevice = null;
        this.form.reset();
        this.changeTracker.clear();
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const tofData: TimeOfFlightConfigDataModel = new TimeOfFlightConfigDataModel();
        tofData.frameRate = this.editDevice.timeOfFlightFrameRate;

        const saveAllSub = zip(
            this.deviceService.updateDevice(this.editDevice, process).pipe(
                map(() => true)
            ),
            of(this.editDevice.isCapable(DeviceCapabilitiesEnum.timeOfFlightFrameRate)).pipe(
                flatMap(isCapable => {
                    if (isCapable) {
                        return this.deviceService.setTimeOfFlightFrameRate(this.editDevice.serialNumber, tofData, process).pipe(
                            map(() => true)
                        );
                    } else {
                        return of(true);
                    }
                })
            )
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            tap(() => {
                this.deviceService.clearCache();
                this._eventsService.deviceUpdated();
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this.deviceService.clearCache();
            return of(true);
        });
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    @Input()
    public get zIndex(): number {
        return this._zIndex;
    }
    public set zIndex(value: number) {
        this._zIndex = value;
    }

    protected offline(): void {
        super.offline();
    }

    protected online(): void {
        super.online();
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.form.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.form.enable();
    }

    private autoDetectHeight(): void {
        this.addSubscription(
            this.observableHandlerBase(this.deviceService.autoDetectHeight(this.editDevice, this.autoDetectHeightProcess), this.autoDetectHeightProcess)
                .subscribe(() => {
                    let height = null;

                    if (this.unitsOfMeasurementService.units === UnitsOfMeasurementEnum.imperial) {
                        height = this.unitsOfMeasurementService.convertToImperial(this.editDevice.actualHeight, UnitOfMeasurementEnum.centimeter, UnitOfMeasurementEnum.inch, 2);
                    } else {
                        height = this.editDevice.actualHeight;
                    }

                    this.form.controls.height.setValue(height, {
                        emitEvent: false
                    });

                    this.setYawPitchRoll(this.editDevice.gVector);

                    this.deviceService.clearCache();
                    this._eventsService.deviceUpdated();
                }),
            this.autoDetectHeightProcess
        );
    }

    private refreshTilt(): void {
        this.addSubscription(
            this.observableHandlerBase(this.deviceService.refreshDeviceTilt(this.editDevice, this.refreshTiltProcess), this.refreshTiltProcess)
                .subscribe(() => {
                    this.setYawPitchRoll(this.editDevice.gVector);

                    this.deviceService.clearCache();
                    this._eventsService.deviceUpdated();
                }),
            this.refreshTiltProcess
        );
    }

    private setAutoStates(): void {
        this._isTiltCapable = null;
    }

    private setFormValues(device: DeviceModel): void {
        this.form.controls.x.setValue(device.x, { emitEvent: false });
        this.form.controls.y.setValue(device.y, { emitEvent: false });

        let height = null;
        if (this.unitsOfMeasurementService.units === UnitsOfMeasurementEnum.imperial) {
            height = this.unitsOfMeasurementService.convertToImperial(device.actualHeight, UnitOfMeasurementEnum.centimeter, UnitOfMeasurementEnum.inch, 2);
        } else {
            height = this.editDevice.actualHeight;
        }
        this.form.controls.height.setValue(height, { emitEvent: false });
    }

    private setFormYaw(device: DeviceModel): void {
        if (this.isTiltCapable) {
            this.form.controls.yaw.setValue(parseFloat((device.gVector.yaw * (180 / Math.PI)).toFixed(1)), { emitEvent: false });
        } else {
            this.form.controls.yaw.disable({ emitEvent: false });
        }
    }

    private setYawPitchRoll(gvector: { x: number; y: number; z: number; yaw: number }): void {
        if (this.isTiltCapable) {
            this.pitch = (Math.asin(-gvector.y) * (180 / Math.PI)).toFixed(1);
            this.roll = (Math.atan2(gvector.x, -gvector.z) * (180 / Math.PI)).toFixed(1);
            this.yaw = (gvector.yaw * (180 / Math.PI)).toFixed(1);
        }
    }
}
