import { BaseModel } from '@shared/base/Base.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { NotificationRuleModel } from '@em/models/restapi/NotificationRule.Model';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';

export class NotificationModel extends BaseModel implements IRestModel {
    public description: boolean = null;
    public name: boolean = null;

    public notificationId: number = null;
    public readonly isIRestModel = true;
    public rules: RestModelChangeTrackerArray<NotificationRuleModel> = new RestModelChangeTrackerArray<NotificationRuleModel>();
    public text: boolean = null;

    public constructor() {
        super();

        this.registerChangeTrackerArray('rules');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-NotificationModel');

        this.rules = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.Rules, NotificationRuleModel);

        this.setPropertyOriginalValue('rules', this.rules);
    }

    public toRestApiModel() {
        throw new Error('Method not implemented.');
    }
}
