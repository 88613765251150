import { BaseModel } from '@shared/base/Base.Model';
import { RecordingStateEnum } from '@shared/enum/RecordingState.Enum';
import { ScheduleTypeEnum } from '@shared/enum/ScheduleType.Enum';
import { SynchronizationStateEnum } from '@shared/enum/SynchronizationState.Enum';
import { VideoDownloadStateEnum } from '@shared/enum/VideoDownloadState.Enum';
import { RestModelChangeTrackerArray } from '@shared/generic/RestModelChangeTrackerArray';
import { IRestModel } from '@shared/interface/IRestModel';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { DateTimeNonMomentUtility } from '@shared/utility/DateTimeNonMoment.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { NodeVideoEntryModel } from './NodeVideoEntry.Model';


export class VideoEntryModel extends BaseModel implements IRestModel {
    public deviceSerial: number = null;
    public downloadState: VideoDownloadStateEnum = null;
    public endTime: Date = null;
    public friendlyDeviceSerial: string = null;
    public imageDataUri: string = null;
    public percentageDownloaded: number = null;
    public readonly isIRestModel = true;
    public recordingState: RecordingStateEnum = null;
    public sessionID: number = null;
    public groupRecordingIdentifier: string = null;
    public endCount: number = null;
    public recordingType: number = null;
    public nodes: RestModelChangeTrackerArray<NodeVideoEntryModel> = new RestModelChangeTrackerArray<NodeVideoEntryModel>();

    public startTime: Date = null;
    public synchronizationState: SynchronizationStateEnum = null;
    public timezoneOffsetMins: number = null;
    public videoDataEntryID: number = null;
    private _durationText: string = null;
    private _endTimeText: string = null;

    private _startDateText: string = null;
    private _startTimeText: string = null;

    public constructor() {
        super();

        this.registerChangeTrackerArray('nodes');
    }

    public get durationText(): string {
        return this._durationText;
    }

    public get endTimeText(): string {
        return this._endTimeText;
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'EM-VideoEntryModel');

        this.nodes = RestModelUtility.loadFromArrayToChangeTrackerArray(restModel.Nodes, NodeVideoEntryModel);

        this.setPropertyOriginalValue('nodes', this.nodes);

        this.startTime = DateTimeNonMomentUtility.fromRestApiDateTime(this.startTime);
        this.endTime = DateTimeNonMomentUtility.fromRestApiDateTime(this.endTime);

        if (isNullOrUndefined(this._startDateText) || DateTimeUtility.isInvalidDate(this._startDateText)) {
            this._startDateText = DateTimeUtility.toShortDate(this.startTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.timezoneOffsetMins));
        }

        if (isNullOrUndefined(this._startTimeText) || DateTimeUtility.isInvalidDate(this._startTimeText)) {
            this._startTimeText = DateTimeUtility.toShortTime(this.startTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.timezoneOffsetMins));
        }

        if (isNullOrUndefined(this._durationText) || this._durationText === 'NaN:NaN:NaN') {
            if (this.recordingType === 0){
                this._durationText = DateTimeUtility.toDuration(this.startTime, this.endTime);
            }
            else if (!isNullOrUndefined(this.endCount)){
                this._durationText = this.endCount.toString() + ' Counts';
            }
        }

        if (isNullOrUndefined(this._endTimeText) || DateTimeUtility.isInvalidDate(this._endTimeText)) {
            if (this.recordingType === 0){
                this._endTimeText = DateTimeUtility.toShortTime(this.endTime, DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.timezoneOffsetMins));
            }
            else{
                this._endTimeText = '';
            }
        }
    }

    public get startDateText(): string {
        return this._startDateText;
    }

    public get startTimeText(): string {
        return this._startTimeText;
    }

    public toRestApiModel() {
        return {
            ...RestModelUtility.toJson(this),
            Nodes: RestModelUtility.toJsonArray(this.nodes)
        };
    }
}
