import { Component, Injector, OnInit, HostBinding } from '@angular/core';
import { BaseComponent } from '@shared/base/Base.Component';

@Component({
    selector: 'em-settings',
    templateUrl: './Settings.Component.html',
    styleUrls: ['./Settings.Component.scss']
})
export class SettingsComponent extends BaseComponent implements OnInit {
    public static className: string = 'SettingsComponent';

    @HostBinding()
    public id: string = 'em-settings';

    public constructor(
        private readonly _injector: Injector) {
        super(_injector);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }
}
