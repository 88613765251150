import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { EmModule } from '@em/Em.Module';
import { environment } from '@em/environments/environment';
import { ProjectConfig, TranslationsUtility } from '@shared/utility/Translations.Utility';

const projectConfigs: ProjectConfig[] = [
    {
        project: 'em',
    },
    {
        project: 'rift',
    },
    {
        project: 'shared',
    },
];

let assetsBase = 'http://localhost:4200';

if (environment.production) {
    enableProdMode();
    assetsBase = '';
}

TranslationsUtility.setTranslations(assetsBase, projectConfigs).subscribe(() => {
    platformBrowserDynamic()
        .bootstrapModule(EmModule)
        .catch(err => console.error(err));
});
