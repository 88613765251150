import { Component, HostBinding, HostListener, Injector, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsCountingMenuBaseComponent } from '@rift/components/settings/counting/base/Settings.Counting.MenuBase.Component';
import { RegisterNameDialogData, RegisterNameDialogResult, SettingsCountingRegisterNameComponent } from '@rift/components/settings/counting/editregister/Settings.Counting.RegisterName.Component';
import { SettingsCountingRegisterSettingsComponent } from '@rift/components/settings/counting/shared/settings/Settings.Counting.RegisterSettings.Component';
import { RegisterSummaryDialogData, SettingsCountingRegisterSummaryDialogComponent } from '@rift/components/settings/counting/shared/summary/Settings.Counting.RegisterSummary.Dialog.Component';
import { AssociatedRegisterViewModel } from '@rift/components/settings/counting/viewmodels/AssociatedRegister.ViewModel';
import { RegisterTypeViewModel } from '@rift/components/settings/counting/viewmodels/RegisterType.ViewModel';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { PolygonModel } from '@rift/models/restapi/Polygon.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { GlobalService } from '@rift/service/data/global/Global.Service';
import { LineService } from '@rift/service/data/line/Line.Service';
import { PolygonService } from '@rift/service/data/polygon/Polygon.Service';
import { RegisterService } from '@rift/service/data/register/Register.Service';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';
import { OkCancelDialogComponent, OkCancelDialogData, OkCancelDialogResult } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { EventsService } from '@shared/service/events/Events.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ValidationValidators } from '@shared/validation/Validation.Validators';
import { IPosition } from 'angular2-draggable';
import { ISize } from 'angular2-draggable/lib/models/size';
import { from, Observable, of, zip } from 'rxjs';
import { flatMap, map, zipAll, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { RegisterTypeEnum } from '@shared/enum/RegisterType.Enum';


@Component({
    selector: 'rift-settings-counting-edit-register',
    templateUrl: './Settings.Counting.EditRegister.Component.html',
    styleUrls: ['./Settings.Counting.EditRegister.Component.scss'],
})
export class SettingsCountingEditRegisterComponent extends SettingsCountingMenuBaseComponent implements OnChanges, ISaveAllChanges {
    public static className: string = 'SettingsCountingEditRegisterComponent';
    public error: string;

    @Input()
    public get bounds(): HTMLElement {
        return this._bounds;
    }
    public set bounds(value: HTMLElement) {
        this._bounds = value;
        this.checkPosition();
    }

    @Input()
    public get zIndex(): number {
        return this._zIndex;
    }
    public set zIndex(value: number) {
        this._zIndex = value;
    }

    @HostBinding()
    public id: string = 'rift-settings-counting-edit-register';

    @Input()
    public lines: Array<LineModel> = null;

    @Input()
    public masterDevice: DeviceModel;

    @Input()
    public polygons: Array<PolygonModel> = null;

    @Input()
    public registers: Array<RegisterBaseModel> = null;

    @LocalStorage(SettingsCountingEditRegisterComponent.className, 'position')
    public position: IPosition;

    public size: ISize;

    @ViewChild('settings', { static: true })
    public settings: SettingsCountingRegisterSettingsComponent;

    public resetLineProcess: ProcessMonitorServiceProcess = null;

    public show: boolean = false;
    public copyRegisterProcess: ProcessMonitorServiceProcess = null;
    public deleteRegisterProcess: ProcessMonitorServiceProcess = null;
    public editRegisterType: RegisterTypeViewModel = null;
    public flipLineProcess: ProcessMonitorServiceProcess = null;
    public nameFormGroup: FormGroup = null;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    private _editRegister: RegisterBaseModel;
    private _position: IPosition;

    public constructor(
        private readonly _render: Renderer2,
        private readonly _globalService: GlobalService,
        private readonly _eventsService: EventsService,
        private readonly _formBuilder: FormBuilder,
        private readonly _lineService: LineService,
        private readonly _polygonService: PolygonService,
        private readonly _registerService: RegisterService,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector
    ) {
        super(_render, _injector, _dialog);

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsCountingEditRegisterComponent.className, 'Form values change');
        this.copyRegisterProcess = this.processMonitorService.getProcess(SettingsCountingEditRegisterComponent.className, 'Copy register');
        this.resetLineProcess = this.processMonitorService.getProcess(SettingsCountingEditRegisterComponent.className, 'Reset register');
        this.flipLineProcess = this.processMonitorService.getProcess(SettingsCountingEditRegisterComponent.className, 'Flip register');
        this.deleteRegisterProcess = this.processMonitorService.getProcess(SettingsCountingEditRegisterComponent.className, 'Deleting register');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingEditRegisterComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsCountingEditRegisterComponent.className, this.saveAllChangesProcessText);

        this.minWidth = 500;
        this.maxWidth = 600;

        this.nameFormGroup = this._formBuilder.group({
            name: [
                '',
                Validators.compose([Validators.required, ValidationValidators.registerName,
                    (control: AbstractControl) => {
                        let maxLength = 23;

                        if(!isNullOrUndefined(this._editRegister) && (this._editRegister.registerType === RegisterTypeEnum.occupancyMaster || this._editRegister.registerType === RegisterTypeEnum.queueWaitMaster || this._editRegister.registerType === RegisterTypeEnum.fifoOccupancyMaster)){
                            maxLength = 17;
                        }

                        return Validators.maxLength(maxLength)(control);
                    }])
            ]
        });

        this.addSubscription(
            this.observableHandlerBase(this.nameFormGroup.controls.name.valueChanges, this.formValuesChangeProcess).subscribe(value => {
                if (!this.isNullOrUndefined(this.editRegister)) {
                    this.editRegister.registerName = value;
                }
            })
            , this.formValuesChangeProcess);

        this.formGroupTracker.track(this.nameFormGroup);

        this.initConnectionState();
    }

    public close(): void {
        super.close();
        this.error = null;
    }

    public open(): void {
        super.open();
        this.error = null;
    }

    public onResized(): void {
        this.checkPosition();
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    @Input()
    public get editRegister(): RegisterBaseModel {
        return this._editRegister;
    }
    public set editRegister(value: RegisterBaseModel) {
        this.changeTracker.clear();
        this._editRegister = value;
        if (!this.isNullOrUndefined(this._editRegister)) {
            this.changeTracker.track(this._editRegister);

            const linesLength = this.lines.length;
            for (let index = 0; index < linesLength; index++) {
                const line = this.lines[index];
                if (this._editRegister.lineIds.indexOf(line.iD) !== -1) {
                    this.changeTracker.track(line);
                }
            }

            const polygonsLength = this.polygons.length;
            for (let index = 0; index < polygonsLength; index++) {
                const polygon = this.polygons[index];
                if (this._editRegister.polygonIds.indexOf(polygon.iD) !== -1) {
                    this.changeTracker.track(polygon);
                }
            }

            this.editRegisterType = new RegisterTypeViewModel(
                this._editRegister,
                this.masterDevice
            );
            this.nameFormGroup.controls.name.setValue(
                this._editRegister.registerName,
                { emitEvent: false }
            );
        }
    }

    public get registerNameMaxLength(): number{
        let maxLength = 23;

        if(!isNullOrUndefined(this._editRegister) && (this._editRegister.registerType === RegisterTypeEnum.occupancyMaster || this._editRegister.registerType === RegisterTypeEnum.queueWaitMaster || this._editRegister.registerType === RegisterTypeEnum.fifoOccupancyMaster)){
            maxLength = 17;
        }

        return maxLength;
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return this.show
            ? (this.isNullOrUndefined(this.settings) || this.settings.isValid) &&
            this.isValidBase
            : true;
    }

    public getRegisterNames(): Array<string> {
        if (this.isNullOrUndefined(this.editRegister)) {
            return [];
        }

        const items: string[] = [];
        const length = this.registers.length;
        for (let index = 0; index < length; index++) {
            const item = this.registers[index];
            if (item.registerIndex !== this.editRegister.registerIndex) {
                items.push(item.registerName);
            }
        }

        return items;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.editRegister) && this.isNullOrUndefined(changes.editRegister.currentValue)) {
            this.close();
        }

        if (!this.isNullOrUndefined(changes.masterDevice) && !this.isNullOrUndefined(changes.masterDevice.currentValue)) {
            this.nameFormGroup.controls.name.setValidators(
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(23),
                    ValidationValidators.registerName,
                    ValidationValidators.unique(this.getRegisterNames.bind(this))
                ])
            );
        }
    }

    public onAssociateSelectedChange(selected: Array<AssociatedRegisterViewModel>): void { }

    public onSummaryClick(): void {
        this._dialog.open(SettingsCountingRegisterSummaryDialogComponent, { data: new RegisterSummaryDialogData(this.editRegister, this.editRegisterType), maxWidth: 600 });
    }

    public onCopyClick(): void {
        this._dialog.open(SettingsCountingRegisterNameComponent, { data: new RegisterNameDialogData(this.registers), disableClose: true }).afterClosed().subscribe(
            (result: RegisterNameDialogResult) => {
                if (!this.isNullOrUndefined(result) && !this.isNullOrUndefined(result.name)) {
                    this.addSubscription(
                        this.observableHandlerBase(this._registerService.copyRegister(this.editRegister.registerIndex, result.name, this.copyRegisterProcess), this.copyRegisterProcess)
                            .subscribe(() => {
                                this._lineService.clearCache();
                                this._polygonService.clearCache();
                                this._eventsService.copyDeviceRegister();
                            }),
                        this.copyRegisterProcess
                    );
                }
            }
        );
    }

    public onDeleteClick(): void {
        if(this.registers.length <= 1){
            const noDeleteDialog = new OkCancelDialogData('Delete Register', 'Device must have at least one configured register', false, true);
            this._dialog.open(OkCancelDialogComponent, { data: noDeleteDialog, maxWidth: 450 });
            return;
        }

        const data = new OkCancelDialogData('Delete Register', null, true);
        data.messageHtml = 'Are you sure you want to delete this register?';
        this.addSubscription(this._dialog.open(OkCancelDialogComponent, { data, maxWidth: 450 }).afterClosed().subscribe(
            (result: OkCancelDialogResult) => {
                if (!this.isNullOrUndefined(result)) {
                    if (result.ok) {
                        const dependents = RegisterBaseUtility.getDependents(this.editRegister, this.registers);
                        if (!this.isNullOrUndefined(dependents)) {
                            const dialogData = new OkCancelDialogData(`Dependent Registers`, null, false);
                            dialogData.messageHtml = `<span>${this.editRegister.registerName} has the following dependencies</span><br>` + dependents.registers.map(dr => `<span>${dr.registerName}</span><br>`).join('') + '<span>Please remove the association or delete these first</span><br>';
                            this._dialog.open(OkCancelDialogComponent, { data: dialogData });
                        } else {
                            this.error = null;
                            this.addSubscription(
                                this.observableHandlerBase(this._registerService.deleteRegister(this.editRegister.registerIndex, this.deleteRegisterProcess), this.deleteRegisterProcess)
                                    .subscribe(deleteResult => {
                                        if (this.isNullOrUndefined(deleteResult) || this.isNullOrUndefined(deleteResult.error)) {
                                            this.addSubscription(this.observableHandlerBase(this._globalService.resetCounts(this.deleteRegisterProcess), this.deleteRegisterProcess)
                                                .subscribe(() => {
                                                    this._lineService.clearCache();
                                                    this._polygonService.clearCache();

                                                    this._eventsService.deleteDeviceRegister();

                                                    this.close();
                                                }),
                                                this.deleteRegisterProcess
                                            );
                                        } else {
                                            this.error = deleteResult.error;
                                        }
                                    }),
                                this.deleteRegisterProcess
                            );
                        }
                    }
                }
            },
        ));
    }

    public onFlipClick(): void {
        const line = this.lines.find(l => l.iD === this.editRegister.lineIds[0]);
        if (!this.isNullOrUndefined(line)) {
            line.points.reverse();
        }
        this.addSubscription(
            this.observableHandlerBase(this._lineService.updateLine(line, line.iD, this.flipLineProcess), this.flipLineProcess)
                .subscribe(result => {
                    this._eventsService.flipDeviceRegister();
                }),
            this.flipLineProcess
        );
    }

    public onResetClick(): void {
        const line = this.lines.find(l => l.iD === this.editRegister.lineIds[0]);
        if (!this.isNullOrUndefined(line)) {
            const first = line.points[0];
            const last = line.points[line.points.length - 1];
            line.points.splice(0, line.points.length);
            line.points.push(...[first, last]);
        }
        this.addSubscription(
            this.observableHandlerBase(this._lineService.updateLine(line, line.iD, this.resetLineProcess), this.flipLineProcess)
                .subscribe(result => {
                    this._eventsService.resetDeviceRegister();
                }),
            this.resetLineProcess
        );
    }

    public onSaveRegisterClick(): void {
        this.saveAllChangesStartBase(this, this.openPleaseWaitSavingDialog());
    }

    public reset(): void {
        this.changeTracker.clearChanges();
        this.editRegister = null;
        this.editRegisterType = null;
        this.nameFormGroup.reset();
        this.changeTracker.clear();
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const hasChangesExcludingCountMode = this.changeTracker.hasChangesExcluding('countMode');
        const hasChangesCountMode = this.editRegister.propertyHasChanges('countMode');

        const saveAllSub = zip(
            of(hasChangesExcludingCountMode).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._registerService.updateRegister(this.editRegister, process).pipe(map(() => true));
                    } else {
                        return of(true);
                    }
                })
            ),
            of(!hasChangesExcludingCountMode && hasChangesCountMode).pipe(
                flatMap(hasChanges => {
                    if (hasChanges) {
                        return this._lineService.getLines(process).pipe(
                            flatMap(linesResult => {
                                const editLines: LineModel[] = [];
                                const length = linesResult.items.length;
                                for (let index = 0; index < length; index++) {
                                    const line = linesResult.items[index];
                                    if (this.editRegister.lineIds.indexOf(line.iD) !== -1) {
                                        editLines.push(line);
                                    }
                                }

                                return from(editLines).pipe(
                                    map(line => this._lineService
                                            .updateLine(line, line.iD, process)
                                            .pipe(map(() => true))),
                                    zipAll(),
                                    flatMap(() => of(true))
                                );
                            })
                        );
                    } else {
                        return of(true);
                    }
                })
            )
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process).pipe(
            tap(() => {
                this._eventsService.updatedDeviceRegister();
            })
        );
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this._registerService.clearCache();
            return of(true);
        });
    }

    protected offline(): void {
        super.offline();
    }

    protected online(): void {
        super.online();
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.nameFormGroup.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.nameFormGroup.enable();
    }
}
