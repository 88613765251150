<div class="rift-select-validation-recording-title" mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Select Recording To Validate
            </div>
        </div>
        <div fxFlex></div>
    </div>
    <ng-template [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.storageWarning === true">
        <div class="warning">Warning: Storage is getting low</div>
    </ng-template>
    <ng-template [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.noSDCard === true">
        <div class="error">No video storage present</div>
    </ng-template>
    <ng-template [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.sdCardNotFormatted === true">
        <div class="error">Video storage not formatted </div>
    </ng-template>
    <ng-template [ngIf]="!isNullOrUndefined(videoStorageCapacity) && videoStorageCapacity.storageError === true">
        <div class="error">Not enough storage to record</div>
    </ng-template>
</div>
<div class="rift-select-validation-recording-content" mat-dialog-content fxLayout="column">
    <shr-table [hideMatCard]="true" (rowClicked)="selected($event)" [rowMouseHover]="true" matSort #sort="matSort"
        [sort]="sort" (filter)="applyFilter($event)" [pageSize]="5"
        [pageSizeOptions]="[5]" [dataSource]="dataSource" [displayedColumns]="displayedColumns"
        [dataExportEnabled]="false">
        <ng-container matColumnDef="frame">
            <th mat-header-cell *matHeaderCellDef> Snap Shot </th>
            <td mat-cell *matCellDef="let vm">
                <img *ngIf="!isNullOrUndefined(vm.dataUri)" class="snap-shot"
                    [src]="isNullOrUndefined(vm.dataUri) ? 'assets/blank.png' : vm.dataUri" />
            </td>
        </ng-container>

        <ng-container matColumnDef="startDateText">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="startDate"> Date </th>
            <td mat-cell *matCellDef="let vm" matTooltip="{{vm.startDateText}}">
                {{vm.startDateText}}
            </td>
        </ng-container>

        <ng-container matColumnDef="startTimeText">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="startTime"> Start </th>
            <td mat-cell *matCellDef="let vm" matTooltip="{{vm.startTimeText}}">
                {{vm.startTimeText}}
            </td>
        </ng-container>

        <ng-container matColumnDef="durationText">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="durationText"> Duration </th>
            <td mat-cell *matCellDef="let vm" matTooltip="{{vm.durationText}}">
                {{vm.durationText}}
            </td>
        </ng-container>

        <ng-container matColumnDef="endTimeText">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="endTime"> End </th>
            <td mat-cell *matCellDef="let vm" matTooltip="{{vm.endTimeText}}">
                {{vm.endTimeText}}
            </td>
        </ng-container>

        <ng-container matColumnDef="nodes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="nodeCount"> Nodes </th>
            <td mat-cell *matCellDef="let vm" matTooltip="{{vm.nodeCount}}">
                {{vm.nodeCount}}
            </td>
        </ng-container>

        <ng-container matColumnDef="sessions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="sessionsCount"> Sessions </th>
            <td mat-cell *matCellDef="let vm" matTooltip="{{vm.sessionsCount}}">
                {{vm.sessionsCount}}
            </td>
        </ng-container>

        <ng-container matColumnDef="open">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="openSessionsCount"> Open </th>
            <td mat-cell *matCellDef="let vm" matTooltip="{{vm.openSessionsCount}}">
                {{vm.openSessionsCount}}
            </td>
        </ng-container>

        <ng-container matColumnDef="completed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="completedSessionsCount"> Completed </th>
            <td mat-cell *matCellDef="let vm" matTooltip="{{vm.completedSessionsCount}}">
                {{vm.completedSessionsCount}}
            </td>
        </ng-container>

        <ng-container matColumnDef="passed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="passedSessionsCount"> Passed </th>
            <td mat-cell *matCellDef="let vm" matTooltip="{{vm.passedSessionsCount}}">
                {{vm.passedSessionsCount}}
            </td>
        </ng-container>

        <ng-container matColumnDef="failed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="failedSessionsCount"> Failed </th>
            <td mat-cell *matCellDef="let vm" matTooltip="{{vm.failedSessionsCount}}">
                {{vm.failedSessionsCount}}
            </td>
        </ng-container>
    </shr-table>
</div>
<div class="rift-select-validation-recording-actions" mat-dialog-actions>
    <button (click)="close()" mat-raised-button color="primary">Back</button>
    <div fxFlex></div>
    <button (click)="validateRecording()"
        [disabled]="isNullOrUndefined(videoStorageCapacity) || videoStorageCapacity.noSDCard === true || videoStorageCapacity.sdCardNotFormatted === true || isNullOrUndefined(selectedVM)"
        mat-raised-button color="primary">Open</button>
</div>
