import { ActivatedRoute, Params } from '@angular/router';
import { Observable, of } from 'rxjs';

export class RoutesParams {
    public params: Params;
    public routes: Array<ActivatedRoute> = [];
}

export class RoutesUtility {
    /**
     * Gets all the routes and params from the activated route service.
     *
     * @private
     * @returns {Observable<RoutesParams>} The routes and params
     * @memberof BreadCrumbService
     */
    public static getRoutesAndParams(activatedRoute: ActivatedRoute): Observable<RoutesParams> {
        const routesParams = new RoutesParams();

        let route = activatedRoute;
        while (route.firstChild) {
            route.firstChild.params.subscribe(params => {
                routesParams.params = {
                    ...routesParams.params,
                    ...params,
                };
            });

            routesParams.routes.push(route.firstChild);
            route = route.firstChild;
        }

        return of(routesParams);
    }
}
