import { Location } from '@angular/common';
import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Injector,
    NgZone,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatToolbar } from '@angular/material/toolbar';
import { ActivatedRoute, Router } from '@angular/router';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { IUserCountClickEvent } from '@rift/components/shared/timeline/rows/TimeLine.Row';
import { TimeLineRowSelect } from '@rift/components/shared/timeline/rows/TimeLine.Row.Select';
import { TimeLineComponent } from '@rift/components/shared/timeline/TimeLine.Component';
import { ViewPortComponent } from '@rift/components/shared/viewport/ViewPort.Component';
import {
    AutoSpeedSettingsDialogComponent,
    AutoSpeedSettingsDialogData,
    AutoSpeedSettingsDialogResult,
} from '@rift/components/validation/autospeedsettings/AutoSpeedSettings.Dialog.Component';
import {
    BookmarkDetailsDialogComponent,
    BookmarkDetailsDialogData,
    BookmarkDetailsDialogResult,
} from '@rift/components/validation/bookmarkdetails/BookmarkDetails.Dialog.Component';
import { CountsMenuComponent } from '@rift/components/validation/countsmenu/CountsMenu.Component';
import {
    LoadValidationRecordingComponent,
    LoadValidationRecordingData,
} from '@rift/components/validation/loadvalidationrecording/LoadValidationRecording.Component';
import {
    ManageSynchronizedRecordingsComponent,
    ManageSynchronizedRecordingsDialogData,
} from '@rift/components/validation/managesynchronizedrecordings/ManageSynchronizedRecordings.component';
import {
    SelectValidationRecordingComponent,
    SelectValidationRecordingData,
    SelectValidationRecordingResult,
} from '@rift/components/validation/selectrecording/SelectValidationRecording.Component';
import {
    SessionDetailsDialogComponent,
    SessionDetailsDialogData,
    SessionDetailsDialogResult,
} from '@rift/components/validation/sessiondetails/SessionDetails.Dialog.Component';
import { SessionsMenuComponent } from '@rift/components/validation/sessionsmenu/SessionsMenu.Component';
import {
    SessionSyncResolveComponent,
    SessionSyncResolveData,
    SessionSyncResolveResult,
} from '@rift/components/validation/sessionsyncresolve/Session.Sync.Resolve.Component';
import {
    ValidationSettingsDialogComponent,
    ValidationSettingsDialogData,
    ValidationSettingsDialogResult,
} from '@rift/components/validation/settings/Settings.Dialog.Component';
import { UserCountMenuComponent } from '@rift/components/validation/usercountmenu/UserCountMenu.Component';
import {
    DefaultArrowKeyJogMs,
    DefaultAutoSpeedSettings,
    DefaultHullScaleFactor,
    DefaultKeyBindings,
    DefaultLineReportBucketSize,
    DefaultSessionOptions,
    DefaultShowDurationAsDeviceTimeZone,
    DefaultShowDurationAsTime,
    IAutoSpeedSettings,
    IUserCountsKeyBindings,
} from '@rift/components/validation/Validation.Defaults';
import { ValidationModeEnum } from '@rift/components/validation/Validation.Mode.Enum';
import { BookmarkModel } from '@rift/models/generic/Bookmark.Model';
import { DeviceModel } from '@rift/models/restapi/Device.Model';
import { GlobalModel } from '@rift/models/restapi/Global.Model';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { PolygonModel } from '@rift/models/restapi/Polygon.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IValidationSessionOptionModel } from '@rift/models/restapi/ValidationSessionOption.Model';
import { CountModel } from '@rift/models/websocket/Count.Model';
import { VideoModel } from '@rift/models/websocket/Video.Model';
import { IPlayLocation } from '@rift/service/validation/IPlayLocation';
import {
    DbValidationSessionInfoModel,
} from '@rift/service/validation/models/database/syncsession/IDbValidationSessionInfo.Model';
import { SyncActionEnum } from '@rift/service/validation/models/SyncState.Enum';
import { IValidatableRecordingModel } from '@rift/service/validation/models/ValidatableRecording.Model';
import { IValidationRecordingModel } from '@rift/service/validation/models/ValidationRecording.Model';
import { PlayStateEnum, ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { ValidationReportService } from '@rift/service/validation/Validation.Report.Service';
import { IFramesData, IKeyFramesData, ValidationService } from '@rift/service/validation/Validation.Service';
import { RegisterBaseUtility } from '@rift/utility/RegisterBase.Utility';
import {
    OkCancelDialogComponent,
    OkCancelDialogData,
    OkCancelDialogResult,
} from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { ValidationSessionStateEnum } from '@shared/enum/ValidationSessionState.Enum';
import { DisplayItemMouseEvent } from '@shared/generic/canvas/DisplayItemMouseEvent';
import { IntervalTracker } from '@shared/generic/IntervalTracker';
import { IFillHeight } from '@shared/interface/IFillHeight';
import { TimeSetupModel } from '@shared/models/restapi/TimeSetup.Model';
import { DataPollingService } from '@shared/service/datapolling/DataPolling.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { DateTimeUtility, TimeZone } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ResponseTypesEnum } from '@shared/webworker/ResponseTypes.Enum';
import { combineLatest, EMPTY, forkJoin, Observable, of, Subject, Subscriber, Subscription, timer, zip } from 'rxjs';
import { catchError, combineAll, concat, concatAll, concatMap, expand, finalize, flatMap, last, map, merge, mergeAll, mergeMap, tap } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SessionReportDialogComponent, SessionReportDialogData } from '@rift/components/validation/sessionreport/SessionReport.Dialog.Component';
import { ValidationSyncFrameEntryCollectionModel } from '@rift/models/websocket/ValidationSyncFrameEntryCollection.Model';
import { IValidationSyncFrameEntryModel } from '@rift/models/websocket/ValidationSyncFrameEntry.Model';
import { IFrameData } from '@shared/webworker/IFrameData';
import { NUMPAD_MINUS } from '@angular/cdk/keycodes';
import { Device } from '../shared/viewport/devices/Device';
import { ISyncFrameBuffer } from '@rift/service/validation/ISyncFrameBuffer';

interface IVideoFrameBuffers{
    serial: string;
    video: Array<VideoModel>;
}

interface IFramesBuffer {
    start: number;
    end: number;
    nextStart: number;
    nextEnd: number;
    videoBuffers: Array<IVideoFrameBuffers>;
    fetchBlockSize: number;
    fetchBeforeEnd: number;
}

interface ILoadRecordingHelperData {
    validationRecordingModel: IValidationRecordingModel;
    nodeValidationRecordingModels: Array<IValidationRecordingModel>;
}

/**
 *
 * Url Params
 * {number} sessionId The synced video session id to open.
 * Query Params
 *
 * @export
 * @class ValidationComponent
 * @extends {RiftBaseComponent}
 * @implements {IFillHeight}
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'rift-validation',
    templateUrl: './Validation.Component.html',
    styleUrls: ['./Validation.Component.scss']
})
export class ValidationComponent extends RiftBaseComponent implements IFillHeight, OnInit, AfterViewInit, OnDestroy, AfterContentInit {
    public static className: string = 'ValidationComponent';

    public addBookmarkProcess: ProcessMonitorServiceProcess;
    public addRecordingBookmarkProcess: ProcessMonitorServiceProcess;
    public addSessionBookmarkProcess: ProcessMonitorServiceProcess;
    public addSessionProcess: ProcessMonitorServiceProcess;
    public autoSpeedEnabled: boolean = false;
    public autoSpeedSettingOpenProcess: ProcessMonitorServiceProcess;
    public bookmarkDetailsDialogClosedProcess: ProcessMonitorServiceProcess;
    public checkSessionSyncStateProcess: ProcessMonitorServiceProcess;
    public dateText: string = null;
    public decrementUserCountProcess: ProcessMonitorServiceProcess;
    public deleteRecordingBookmarkProcess: ProcessMonitorServiceProcess;
    public deleteSessionBookmarkProcess: ProcessMonitorServiceProcess;
    public deleteSessionProcess: ProcessMonitorServiceProcess;
    public durationText: string = null;
    public endText: string = null;
    public getFramesProcess: ProcessMonitorServiceProcess;
    public getRecordingFramesProcess: ProcessMonitorServiceProcess;
    public getRecordingKeyFramesProcess: ProcessMonitorServiceProcess;
    public getRecordingProcess: ProcessMonitorServiceProcess;
    public getSessionBookmarksProcess: ProcessMonitorServiceProcess;
    public getSessionProcess: ProcessMonitorServiceProcess;
    public getSessionSyncStateProcess: ProcessMonitorServiceProcess;
    public getSessionUserCountsProcess: ProcessMonitorServiceProcess;
    public getValidatableRecordingProcess: ProcessMonitorServiceProcess;
    public globalBookmarkClickProcess: ProcessMonitorServiceProcess;
    public incrementUserCountProcess: ProcessMonitorServiceProcess;
    public loadDuration: number = 0;
    public modeText: string = null;
    public newSessionErrorMessage: string = null;
    public newSessionValid: boolean = false;
    public onLocationChangedProcess: ProcessMonitorServiceProcess;
    public onValidationRecordingChangedProcess: ProcessMonitorServiceProcess;
    public playPointDragProcess: ProcessMonitorServiceProcess;
    public PlayStateEnum = PlayStateEnum;
    public requireStageUpdateProcess: ProcessMonitorServiceProcess;
    public rowSelectedProcess: ProcessMonitorServiceProcess;
    public sessionBookmarkClickProcess: ProcessMonitorServiceProcess;
    public sessionOptions: Array<IValidationSessionOptionModel> = null;
    public settingsClickProcess: ProcessMonitorServiceProcess;
    public setValidationSessionProcess: ProcessMonitorServiceProcess;
    public showSelectValidationRecordingProcess: ProcessMonitorServiceProcess;
    public showSystemCounts: boolean = true;
    public startText: string = null;
    public timeZone: TimeZone = null;
    public updateSessionStateProcess: ProcessMonitorServiceProcess;
    public userCountClickProcess: ProcessMonitorServiceProcess;
    public ValidationModeEnum = ValidationModeEnum;

    @ViewChild('countsMenu', { static: true })
    public countsMenu: CountsMenuComponent;

    @ViewChild('mainContent', { static: true })
    public mainContent: ElementRef;

    @ViewChild('playControlsContent', { static: true })
    public playControlsContent: ElementRef;

    @ViewChild('sessionsMenu', { static: true })
    public sessionsMenu: SessionsMenuComponent;

    @ViewChild('timeLine', { static: true })
    public timeLine: TimeLineComponent;

    @ViewChild('timeLineContent', { static: true })
    public timeLineContent: ElementRef;

    @ViewChild('toolbar', { static: true })
    public toolbar: MatToolbar;

    @ViewChild('userCountMenu', { static: true })
    public userCountMenu: UserCountMenuComponent;

    @ViewChild('viewPort', { static: true })
    public viewPort: ViewPortComponent;

    @ViewChild('viewPortContent', { static: true })
    public viewPortContent: ElementRef;

    @ViewChildren('option')
    public options: QueryList<MatButton>;

    @HostBinding()
    public id: string = 'rift-validation';

    @LocalStorage(ValidationComponent.className, 'arrowKeyJogMs')
    public arrowKeyJogMs: number;

    @LocalStorage(ValidationComponent.className, 'autoSpeedSettings')
    public autoSpeedSettings: IAutoSpeedSettings;

    @LocalStorage(ValidationComponent.className, 'countsKeyBindings')
    public countsKeyBindings: Array<IUserCountsKeyBindings>;

    @LocalStorage(ValidationComponent.className, 'lineReportBucketSize')
    public lineReportBucketSize: number;

    @LocalStorage(ValidationComponent.className, 'showAsDuration')
    public showDurationAsTime: boolean;

    @LocalStorage(ValidationComponent.className, 'showDurationAsDeviceTimeZone')
    public showDurationAsDeviceTimeZone: boolean;

    @LocalStorage(ValidationComponent.className, 'hullScaleFactor')
    public hullScaleFactor: number;

    private _autoSpeedProfile: Array<number> = null;
    private _autoSpeedSettingsDialogRef: MatDialogRef<AutoSpeedSettingsDialogComponent>;
    private _bookmarkDetailsDialogRef: MatDialogRef<BookmarkDetailsDialogComponent>;
    private _devices: Array<DeviceModel>;
    private _frameBuffer: IFramesBuffer = { fetchBlockSize: 2000, start: 0, end: 0, videoBuffers: [], nextStart: null, nextEnd: null, fetchBeforeEnd: 1000 };
    private _getFramesSub: Subscription = null;
    private _globalBookmarks: Array<BookmarkModel>;
    private _globalData: GlobalModel;
    private _height: number = null;
    private _keyVideoFrames: Array<IVideoFrameBuffers> = null;
    private _lines: Array<LineModel>;
    private _loadValidationRecordingDialogRef: MatDialogRef<LoadValidationRecordingComponent>;
    private _loadedFromParam: boolean = false;
    private _mode: ValidationModeEnum = ValidationModeEnum.preview;
    private _playPointDragIntervalTracker = new IntervalTracker(100);
    private _polygons: Array<PolygonModel>;
    private _registers: Array<RegisterBaseModel>;
    private _selectValidationRecordingRef: MatDialogRef<SelectValidationRecordingComponent>;
    private _sessionBookmarks: Array<BookmarkModel>;
    private _sessionId: number = null;
    private _startTime: Date = null;
    private _sessionSyncResolveDialogRef: MatDialogRef<SessionSyncResolveComponent>;
    private _sessions: Array<DbValidationSessionInfoModel>;
    private _systemCounts: Array<CountModel>;
    private _timeSetup: TimeSetupModel;
    private _userCounts: Array<CountModel>;
    private _validatableRecording: IValidatableRecordingModel = null;
    private _validationRecording: IValidationRecordingModel = null;
    private _validationSession: DbValidationSessionInfoModel = null;
    private _validationSettingsDialogRef: MatDialogRef<ValidationSettingsDialogComponent>;
    private _downloadStatusTimer: Subscription;
    private _timeLinePlayPointDragPaused: boolean = false;
    private _timeLinePlayPointDragging: boolean = false;
    private _syncFramesBuffer: Array<ISyncFrameBuffer> = [];

    public constructor(
        public readonly playService: ValidationPlayService,
        private readonly _renderBase: Renderer2,
        private readonly _location: Location,
        private readonly _zone: NgZone,
        private readonly _dialog: MatDialog,
        private readonly _validationService: ValidationService,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _reportService: ValidationReportService,
        private readonly _router: Router,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this.userNotificationService.hideBottomInfo();

        this.loadDataProcess = this.processMonitorService.getProcess(ValidationComponent.className, this.loadDataProcessText);
        this.getFramesProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Getting frame data');
        this.userCountClickProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'User count clicked');
        this.showSelectValidationRecordingProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Showing select validation');
        this.getSessionUserCountsProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Getting session user counts');
        this.getSessionBookmarksProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Getting session bookmarks');
        this.checkSessionSyncStateProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'checking session sync state');
        this.getSessionProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Getting session');
        this.getSessionSyncStateProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Getting session sync state');
        this.onValidationRecordingChangedProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Validation recording changed');
        this.getRecordingKeyFramesProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Getting recording key frames');
        this.getValidatableRecordingProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Getting validatable recording');
        this.getRecordingProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Getting recording');
        this.getRecordingFramesProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Getting recording frames');
        this.bookmarkDetailsDialogClosedProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Bookmark details dialog closed');
        this.addRecordingBookmarkProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Adding recording bookmark');
        this.deleteRecordingBookmarkProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Deleting recording bookmark');
        this.addSessionBookmarkProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Adding session bookmark');
        this.deleteSessionBookmarkProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Deleting session bookmark');
        this.onLocationChangedProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Play location changed');
        this.sessionBookmarkClickProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Session bookmarks clicked');
        this.setValidationSessionProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Setting validation session');
        this.deleteSessionProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Deleting session');
        this.updateSessionStateProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Updating session state');
        this.addSessionProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Adding session');
        this.incrementUserCountProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Incrementing user count');
        this.globalBookmarkClickProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Global bookmark clicked');
        this.decrementUserCountProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Decrement user count');
        this.autoSpeedSettingOpenProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Opening auto speed settings');
        this.addBookmarkProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Adding bookmark');
        this.requireStageUpdateProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Stage update required');
        this.playPointDragProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Play point dragged');
        this.rowSelectedProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Row selected');
        this.settingsClickProcess = this.processMonitorService.getProcess(ValidationComponent.className, 'Settings clicked');

        this.addEventHandlers();

        this.onModeUpdated();
        this.initConnectionState();
    }

    public get timeLinePlayPointDragging(): boolean {
        return this._timeLinePlayPointDragging;
    }

    public addBookmark(): void {
        this._bookmarkDetailsDialogRef = this._dialog.open(BookmarkDetailsDialogComponent, { data: new BookmarkDetailsDialogData('new') });
        this.addSubscription(this.observableHandlerBase(this._bookmarkDetailsDialogRef.afterClosed(), this.addBookmarkProcess).subscribe(
            (result: BookmarkDetailsDialogResult) => {
                if (!this.isNullOrUndefined(result)) {
                    this.bookmarkDetailsDialogClosed(result, !isNullOrUndefined(this._validationSession) ? 'session' : 'global');
                }
                this._bookmarkDetailsDialogRef = null;
            }
        ), this.addBookmarkProcess);
    }

    public autoSpeedSettingOpen(): void {
        if (this.isNullOrUndefined(this._autoSpeedSettingsDialogRef)) {
            this._autoSpeedSettingsDialogRef = this._dialog.open(AutoSpeedSettingsDialogComponent, { data: new AutoSpeedSettingsDialogData(this.autoSpeedSettings), maxWidth: 500 });
            this.addSubscription(this.observableHandlerBase(this._autoSpeedSettingsDialogRef.afterClosed(), this.autoSpeedSettingOpenProcess).subscribe(
                (result: AutoSpeedSettingsDialogResult) => {
                    if (!this.isNullOrUndefined(result)) {
                        this.autoSpeedSettings = result.autoSpeedSettings;

                        this._validationService.getRecordingFrames(this.autoSpeedSettings, this.playService.currentLocation.frameNumber, this._frameBuffer.fetchBlockSize, this.validationRecording.recordingId).subscribe(
                            (framesData: IFramesData) => {
                                this.loadAutoSpeedProfile(framesData.autoSpeedProfile);
                            }
                        );
                    }
                    this._autoSpeedSettingsDialogRef = null;
                }
            ), this.autoSpeedSettingOpenProcess);
        }
    }

    public backClick(): void {
        if (!isNullOrUndefined(this.validationRecording)){
            this.addSubscription(this._validationService.cancel(this.validationRecording.recordingId).subscribe());
        }

        switch (this.mode) {
            case ValidationModeEnum.createSession:
                this.addSubscription(this.setValidationSession(null, false).subscribe((result) => {
                    if (result === true) {
                        this.mode = ValidationModeEnum.preview;
                    }
                }));
                break;
            case ValidationModeEnum.preview:
                if (this._loadedFromParam === false) {
                    this.playService.pause();
                    this.showSelectValidationRecording();
                } else {
                    this._location.back();
                }
                break;
            case ValidationModeEnum.review:
                this.addSubscription(this.setValidationSession(null, false).subscribe((result) => {
                    if (result === true) {
                        this.mode = ValidationModeEnum.preview;
                    }
                }));
                break;
        }
    }

    public centerViewPort(): void {
        if (!this.isNullOrUndefined(this.viewPort)) {
            this.viewPort.center();
        }
    }

    public closeSessionClick(): void {
        this.addSubscription(this.observableHandlerBase(this.setValidationSession(null, false), this.setValidationSessionProcess).subscribe((result) => {
            if (result === true) {
                this.mode = ValidationModeEnum.preview;
            }
        }), this.setValidationSessionProcess);
    }

    public completeClick(): void {
        if (!isNullOrUndefined(this._validationSession)) {
            this._validationSession.report = this._reportService.generateAccuracyReport(this._registers, this._lines, this._userCounts, this._systemCounts, this._devices.find(i => i.master === true), this._devices.filter(i => i.master !== true), this._timeSetup, this._globalData, this._validationRecording, this._validationSession);
            this._validationSession.state = ValidationSessionStateEnum.validated;
            this.addSubscription(this.observableHandlerBase(this.updateSession(), this.updateSessionStateProcess).subscribe(
                () => {
                    const session = this.validationSession;
                    this.addSubscription(this.observableHandlerBase(this.setValidationSession(null, false), this.setValidationSessionProcess).subscribe((clearResult) => {
                        if (clearResult === true) {
                            this.addSubscription(this.observableHandlerBase(this.setValidationSession(session, false), this.setValidationSessionProcess).subscribe((setResult) => {
                                if (setResult === true) {
                                    this.mode = ValidationModeEnum.review;
                                }
                            }), this.setValidationSessionProcess);
                        }
                    }), this.setValidationSessionProcess);
                }
            ), this.updateSessionStateProcess);
        }
    }

    public get devices(): Array<DeviceModel> {
        return this._devices;
    }

    public filledHeight(height: number): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this.viewPortContent) && !isNullOrUndefined(this.viewPort) && !isNullOrUndefined(this.timeLineContent) && !isNullOrUndefined(this.timeLine)) {
                this._height = height;
                const toolbarStyle = window.getComputedStyle(this.toolbar._elementRef.nativeElement);
                const playControlsStyle = window.getComputedStyle(this.playControlsContent.nativeElement);

                let toolbarHeight = parseInt(toolbarStyle.height, 10) + 20;
                let playControlsHeight = parseInt(playControlsStyle.height, 10);

                if (Number.isNaN(toolbarHeight)) {
                    toolbarHeight = 50;
                }

                if (Number.isNaN(playControlsHeight)) {
                    playControlsHeight = 50;
                }

                const viewPortTimelineHeightPer = (this._height - (toolbarHeight + playControlsHeight)) / 100;
                let viewPortHeight = 0;
                let timelineHeight = 0;

                switch (this.mode) {
                    case ValidationModeEnum.preview:
                        viewPortHeight = 70 * viewPortTimelineHeightPer;
                        timelineHeight = 30 * viewPortTimelineHeightPer;
                        break;
                    case ValidationModeEnum.createSession:
                        viewPortHeight = 30 * viewPortTimelineHeightPer;
                        timelineHeight = 70 * viewPortTimelineHeightPer;
                        break;
                    case ValidationModeEnum.validation:
                        viewPortHeight = 75 * viewPortTimelineHeightPer;
                        timelineHeight = 25 * viewPortTimelineHeightPer;
                        break;
                    case ValidationModeEnum.review:
                        viewPortHeight = 75 * viewPortTimelineHeightPer;
                        timelineHeight = 25 * viewPortTimelineHeightPer;
                        break;
                }

                this._renderBase.setStyle(this.viewPortContent.nativeElement, 'height', `${viewPortHeight}px`);
                this.viewPort.fillElement(this.viewPortContent);
                this._renderBase.setStyle(this.timeLineContent.nativeElement, 'height', `${timelineHeight}px`);
                this.timeLine.fillElement(this.timeLineContent);
            }
        });
    }

    public globalBookmarkClick(bookmark: BookmarkModel): void {
        this._bookmarkDetailsDialogRef = this._dialog.open(BookmarkDetailsDialogComponent, { data: new BookmarkDetailsDialogData('view', bookmark) });
        this.addSubscription(this.observableHandlerBase(this._bookmarkDetailsDialogRef.afterClosed(), this.globalBookmarkClickProcess).subscribe(
            (result: BookmarkDetailsDialogResult) => {
                if (!this.isNullOrUndefined(result)) {
                    this.bookmarkDetailsDialogClosed(result, 'global');
                }
                this._bookmarkDetailsDialogRef = null;
            }
        ), this.globalBookmarkClickProcess);
    }

    public get globalBookmarks(): Array<BookmarkModel> {
        return this._globalBookmarks;
    }

    public get globalData(): GlobalModel {
        return this._globalData;
    }

    public incrementUserCount(register: RegisterBaseModel): void {
        if (this._timeLinePlayPointDragging === false) {
            const frameNumber = this.playService.currentLocation.frameNumber;
            const registerIndex = register.registerIndex;
            this.addSubscription(this.observableHandlerBase(this._validationService.userCountsIncrement(frameNumber, registerIndex, this.validationSession, this._validationRecording, this.incrementUserCountProcess), this.incrementUserCountProcess).subscribe(
                updatedUserCounts => {
                    this.updateUserCount(updatedUserCounts, registerIndex, frameNumber);
                }
            ), this.incrementUserCountProcess);
        }
    }

    public decrementUserCount(register: RegisterBaseModel): void {
        if (this._timeLinePlayPointDragging === false) {
            const frameNumber = this.playService.currentLocation.frameNumber;
            const registerIndex = register.registerIndex;
            this.addSubscription(this.observableHandlerBase(this._validationService.userCountsDecrement(frameNumber, registerIndex, this.validationSession, this._validationRecording, this.decrementUserCountProcess), this.decrementUserCountProcess).subscribe(
                updatedUserCounts => {
                    this.updateUserCount(updatedUserCounts, registerIndex, frameNumber);
                }
            ), this.decrementUserCountProcess);
        }
    }

    public userCountClick(event: IUserCountClickEvent): void {
        const frameNumber = event.frameNumber;
        const registerIndex = event.registerIndex;
        this.addSubscription(this.observableHandlerBase(this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Delete User Count', 'Are you sure you want to delete the user count.') }).afterClosed(), this.userCountClickProcess).subscribe(
            (result: OkCancelDialogResult) => {
                if (!this.isNullOrUndefined(result) && result.ok) {
                    this.addSubscription(this.observableHandlerBase(this._validationService.userCountsDelete(frameNumber, registerIndex, this.validationSession, this.validationRecording, this.userCountClickProcess), this.userCountClickProcess).subscribe(
                        updatedUserCounts => {
                            this.updateUserCount(updatedUserCounts, registerIndex, frameNumber);
                        }
                    ), this.userCountClickProcess);
                }
            }
        ), this.userCountClickProcess);
    }

    public get lines(): Array<LineModel> {
        return this._lines;
    }

    public get mode(): ValidationModeEnum {
        return this._mode;
    }

    public set mode(value: ValidationModeEnum) {
        this._mode = value;
        this.onModeUpdated();
    }

    public newSession(): void {
        this.mode = ValidationModeEnum.createSession;
    }

    public nextClick(): void {
        switch (this.mode) {
            case ValidationModeEnum.createSession:
                const newSession = new DbValidationSessionInfoModel();
                newSession.startFrame = this.timeLine.durationView.leftPoint.playLocation.frameNumber;
                newSession.endFrame = this.timeLine.durationView.rightPoint.playLocation.frameNumber;
                newSession.registersToValidate = [];

                const rowsLength = this.timeLine.rows.length;
                for (let i = 0; i < rowsLength; i++) {
                    const row = this.timeLine.rows.items[i];
                    if (row.select.isSelected) {
                        newSession.registersToValidate.push(row.registerIndex);
                    }
                }

                this.addSubscription(this.observableHandlerBase(this._dialog.open(SessionDetailsDialogComponent, { data: new SessionDetailsDialogData(this.showDurationAsTime, this.lineReportBucketSize, newSession, this.registers, this.lines, this.systemCounts, this.devices, this.timeSetup, this.globalData, this.validationRecording) }).afterClosed(), this.addSessionProcess).subscribe(
                    (result: SessionDetailsDialogResult) => {
                        if (!this.isNullOrUndefined(result) && !this.isNullOrUndefined(result.session)) {
                            this.addSubscription(this.observableHandlerBase(this._validationService.addSession(result.session, this._validationRecording, this.addSessionProcess), this.addSessionProcess).subscribe(
                                dBSession => {
                                    this._sessions.push(dBSession);
                                    if (!isNullOrUndefined(result.goToMode)) {
                                        this.addSubscription(this.observableHandlerBase(this.setValidationSession(null, false), this.setValidationSessionProcess).subscribe((clearResult) => {
                                            if (clearResult === true) {
                                                this.addSubscription(this.observableHandlerBase(this.setValidationSession(dBSession, false), this.setValidationSessionProcess).subscribe((setResult) => {
                                                    if (setResult === true) {
                                                        this.mode = result.goToMode;
                                                    }
                                                }), this.setValidationSessionProcess);
                                            }
                                        }), this.setValidationSessionProcess);
                                    }
                                }
                            ), this.addSessionProcess);
                        }
                    }
                ), this.addSessionProcess);
                break;
        }
    }

    public ngAfterContentInit(): void {
        this.addSubscription(timer(200).subscribe(() => {
            const style = window.getComputedStyle(this.mainContent.nativeElement);
            this.filledHeight(parseInt(style.height, 10));
        }));
    }

    public ngAfterViewInit(): void {
        this.addSubscription(this.observableHandlerBase(this.timeLine.onRequireStageUpdate, this.requireStageUpdateProcess).subscribe(() => this.viewPort.requireStageUpdate()), this.requireStageUpdateProcess);
        this.addSubscription(this.observableHandlerBase(this.timeLine.durationView.playPoint.onDrag, this.playPointDragProcess).subscribe(event => this.onTimeLinePlayPointDrag(event)), this.playPointDragProcess);
        this.addSubscription(this.observableHandlerBase(this.timeLine.durationView.playPoint.onDragEnd, this.playPointDragProcess).subscribe(event => this.onTimeLinePlayPointDragEnd(event)), this.playPointDragProcess);
        this.addSubscription(this.observableHandlerBase(this.timeLine.rows.onRowSelectedChanged, this.rowSelectedProcess).subscribe(event => this.rowSelectedChanged(event)), this.rowSelectedProcess);
        this.addSubscription(this.observableHandlerBase(this.timeLine.rows.onUserCountClick, this.userCountClickProcess).subscribe(event => this.userCountClick(event)), this.userCountClickProcess);
        this.setOptionStates(DefaultSessionOptions);
    }

    public ngOnDestroy(): void {
        this.userNotificationService.showBottomInfo();
        this.playService.stop();
        if (!this.isNullOrUndefined(this._selectValidationRecordingRef)) {
            this._selectValidationRecordingRef.close();
        }

        if (!isNullOrUndefined(this.validationRecording)){
            this.addSubscription(this._validationService.cancel(this.validationRecording.recordingId).subscribe());
        }

        this._polygons?.splice(0, this._polygons.length);
        this._registers?.splice(0, this.registers.length);
        this._sessionBookmarks?.splice(0, this._sessionBookmarks.length);
        this._systemCounts?.splice(0, this._systemCounts.length);
        this._userCounts?.splice(0, this._userCounts.length);
        this._devices?.splice(0, this._devices.length);
        this._lines?.splice(0, this._lines.length);

        const keyFrameBufferLength = this._keyVideoFrames?.length;
        for (let i = 0; i < keyFrameBufferLength; i++){
            const keyFrameBuffer = this._keyVideoFrames[i];

            keyFrameBuffer.video.splice(0, keyFrameBuffer.video.length);
        }

        this._keyVideoFrames?.splice(0, this._keyVideoFrames.length);

        const videoBuffersLength = this._frameBuffer?.videoBuffers.length;
        for (let i = 0; i < videoBuffersLength; i++){
            const videoBuffer = this._frameBuffer?.videoBuffers[i];

            videoBuffer.video.splice(0, videoBuffer.video.length);
        }
        this._frameBuffer?.videoBuffers.splice(0, this._frameBuffer.videoBuffers.length);

        this._syncFramesBuffer?.splice(0, this._syncFramesBuffer?.length);

        super.ngOnDestroy();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        if (isNullOrUndefined(this.countsKeyBindings) || this.countsKeyBindings.length !== DefaultKeyBindings.userCounts.length) {
            this.countsKeyBindings = DefaultKeyBindings.userCounts;
        }

        if (isNullOrUndefined(this.arrowKeyJogMs)) {
            this.arrowKeyJogMs = DefaultArrowKeyJogMs;
        }

        if (isNullOrUndefined(this.lineReportBucketSize)) {
            this.lineReportBucketSize = DefaultLineReportBucketSize;
        }

        if (isNullOrUndefined(this.showDurationAsTime)) {
            this.showDurationAsTime = DefaultShowDurationAsTime;
        }

        if (isNullOrUndefined(this.showDurationAsDeviceTimeZone)) {
            this.showDurationAsDeviceTimeZone = DefaultShowDurationAsDeviceTimeZone;
        }

        if (isNullOrUndefined(this.hullScaleFactor)) {
            this.hullScaleFactor = DefaultHullScaleFactor;
        }

        if (!isNullOrUndefined(this.timeLine)) {
            this.timeLine.showDurationAsTime = this.showDurationAsTime;
            this.timeLine.showDurationAsDeviceTimeZone = this.showDurationAsDeviceTimeZone;
        }

        if (isNullOrUndefined(this.autoSpeedSettings)) {
            this.autoSpeedSettings = DefaultAutoSpeedSettings;
        }
    }

    public get registers(): Array<RegisterBaseModel> {
        return this._registers;
    }

    public rejectClick(): void {
        if (!isNullOrUndefined(this._validationSession)) {
            this._validationSession.report = this._reportService.generateAccuracyReport(this._registers, this._lines, this._userCounts, this._systemCounts, this._devices.find(i => i.master === true), this._devices.filter(i => i.master !== true), this._timeSetup, this._globalData, this._validationRecording, this._validationSession);
            this._validationSession.state = ValidationSessionStateEnum.rejected;
            this.addSubscription(this.observableHandlerBase(this.updateSession(), this.updateSessionStateProcess).subscribe(
                () => {
                    const session = this.validationSession;
                    this.addSubscription(this.observableHandlerBase(this.setValidationSession(null, false), this.setValidationSessionProcess).subscribe((clearResult) => {
                        if (clearResult === true) {
                            this.addSubscription(this.observableHandlerBase(this.setValidationSession(session, false), this.setValidationSessionProcess).subscribe((setResult) => {
                                if (setResult === true) {
                                    this.mode = ValidationModeEnum.review;
                                }
                            }), this.setValidationSessionProcess);
                        }
                    }), this.setValidationSessionProcess);
                }
            ), this.updateSessionStateProcess);
        }
    }

    public sessionAction(event: SessionDetailsDialogResult): void {
        if (!isNullOrUndefined(event.session)) {
            if (event.deleteSession === true) {
                this.addSubscription(this.observableHandlerBase(this._validationService.deleteSession(event.session, this._validationRecording, this.deleteSessionProcess), this.deleteSessionProcess).subscribe(
                    result => {
                        if (result) {
                            const index = this._sessions.findIndex(i => i.id === event.session.id);
                            this._sessions.splice(index, 1);
                        }
                    }
                ), this.deleteSessionProcess);
            } else if (!isNullOrUndefined(event.goToMode)) {
                this.addSubscription(this.observableHandlerBase(this.setValidationSession(null, false), this.setValidationSessionProcess).subscribe((result) => {
                    if (result === true) {
                        this.addSubscription(this.observableHandlerBase(this.setValidationSession(event.session, true), this.setValidationSessionProcess).subscribe((setResult) => {
                            if (setResult === true) {
                                if (event.goToMode === ValidationModeEnum.report) {
                                    this._dialog.open(SessionReportDialogComponent, { data: new SessionReportDialogData(this.showDurationAsTime, this.lineReportBucketSize, event.session, this.registers, this.lines, this.systemCounts, this.devices, this.timeSetup, this.globalData, this.validationRecording) });
                                } else {
                                    this.mode = event.goToMode;
                                }
                            }
                        }), this.setValidationSessionProcess);
                    }
                }), this.setValidationSessionProcess);

            }
        }
    }

    public sessionBookmarkClick(bookmark: BookmarkModel): void {
        this._bookmarkDetailsDialogRef = this._dialog.open(BookmarkDetailsDialogComponent, { data: new BookmarkDetailsDialogData('view', bookmark) });
        this.addSubscription(this.observableHandlerBase(this._bookmarkDetailsDialogRef.afterClosed(), this.sessionBookmarkClickProcess).subscribe(
            (result: BookmarkDetailsDialogResult) => {
                if (!this.isNullOrUndefined(result)) {
                    this.bookmarkDetailsDialogClosed(result, 'session');
                }
                this._bookmarkDetailsDialogRef = null;
            }
        ), this.sessionBookmarkClickProcess);
    }

    public get sessionBookmarks(): Array<BookmarkModel> {
        return this._sessionBookmarks;
    }

    public get sessionId(): number {
        return this._sessionId;
    }

    public set sessionId(value: number) {
        this._sessionId = value;
    }

    public get startTime(): Date {
        return this._startTime;
    }

    public set startTime(value: Date) {
        this._startTime = value;
    }

    public get sessions(): Array<DbValidationSessionInfoModel> {
        return this._sessions;
    }

    public setToggleOption(optionName: string, selected?: boolean): void {
        const element: MatButton = this.options.find(e => e._elementRef.nativeElement.id === optionName);
        if (!isNullOrUndefined(element)) {
            switch (optionName) {
                case 'showcountlines':
                    this.viewPort.registers.visible = isNullOrUndefined(selected) ? !this.viewPort.registers.visible : selected;
                    selected = this.viewPort.registers.visible;
                    this.viewPort.registers.update();
                    break;
                case 'showtargets':
                    this.viewPort.targets.visible = isNullOrUndefined(selected) ? !this.viewPort.targets.visible : selected;
                    selected = this.viewPort.targets.visible;
                    this.viewPort.targets.update();
                    break;
                case 'showtargetheight':
                    this.viewPort.targets.heightTextVisible = isNullOrUndefined(selected) ? !this.viewPort.targets.heightTextVisible : selected;
                    selected = this.viewPort.targets.heightTextVisible;
                    this.viewPort.targets.update();
                    break;
                case 'showtargetspaths':
                    this.viewPort.targets.pathsVisible = isNullOrUndefined(selected) ? !this.viewPort.targets.pathsVisible : selected;
                    selected = this.viewPort.targets.pathsVisible;
                    this.viewPort.targets.update();
                    break;
                case 'showtargetstails':
                    this.viewPort.targets.tailsVisible = isNullOrUndefined(selected) ? !this.viewPort.targets.tailsVisible : selected;
                    selected = this.viewPort.targets.tailsVisible;
                    this.viewPort.targets.update();
                    break;
                case 'showtargetsovervideo':
                    this.viewPort.showTargetsOverVideo = isNullOrUndefined(selected) ? !this.viewPort.showTargetsOverVideo : selected;
                    selected = this.viewPort.showTargetsOverVideo;
                    this.viewPort.update();
                    break;
                case 'showsystemcounts':
                    this.timeLine.rows.systemCountsVisible = isNullOrUndefined(selected) ? !this.timeLine.rows.systemCountsVisible : selected;
                    selected = this.timeLine.rows.systemCountsVisible;
                    this.countsMenu.showSystemCounts = selected;
                    this.timeLine.rows.update();
                    break;
                case 'fieldofview':
                    this.viewPort.devices.showFieldOfView = isNullOrUndefined(selected) ? !this.viewPort.devices.showFieldOfView : selected;
                    selected = this.viewPort.devices.showFieldOfView;
                    this.viewPort.devices.update();
                    break;
            }

            if (selected) {
                this._renderBase.addClass(element._elementRef.nativeElement, 'flat-icon-button-selected');
            } else {
                this._renderBase.removeClass(element._elementRef.nativeElement, 'flat-icon-button-selected');
            }

            this.viewPort.requireStageUpdate();
        }
    }

    public settingsClick(): void {
        this._validationSettingsDialogRef = this._dialog.open(ValidationSettingsDialogComponent, { data: new ValidationSettingsDialogData(this.countsKeyBindings, this.arrowKeyJogMs, this.lineReportBucketSize, this.showDurationAsTime, this.showDurationAsDeviceTimeZone, this.hullScaleFactor) });
        this.addSubscription(this.observableHandlerBase(this._validationSettingsDialogRef.afterClosed(), this.settingsClickProcess).subscribe(
            (result: ValidationSettingsDialogResult) => {
                if (!this.isNullOrUndefined(result)) {
                    this.arrowKeyJogMs = result.arrowKeyJogMs;
                    this.lineReportBucketSize = result.lineReportBucketSize;
                    this.showDurationAsTime = result.showDurationAsTime;
                    this.showDurationAsDeviceTimeZone = result.showDurationAsDeviceTimeZone;

                    this.timeLine.timeZone = this.timeZone;

                    this.timeLine.showDurationAsTime = this.showDurationAsTime;
                    this.timeLine.showDurationAsDeviceTimeZone = this.showDurationAsDeviceTimeZone;

                    this.hullScaleFactor = result.hullScaleFactor;

                    this.viewPort.videoDevice.videoSettings.hullScaleFactor = this.hullScaleFactor;
                    this.viewPort.videoDevice.videoSettings.update();
                    // this.viewPort.videoDevice.video.updateFilters();
                }
                this._validationSettingsDialogRef = null;
            }
        ));
    }

    @HostListener('window:keydown', ['$event'])
    public keyDown(event: KeyboardEvent): void {
        if (this._dialog.openDialogs.length === 0) {
            switch (event.key) {
                case 'ArrowLeft': // Left
                    this.playService.stepBackward(this.arrowKeyJogMs);
                    break;
                case 'ArrowRight': // Right
                    this.playService.stepForward(this.arrowKeyJogMs);
                    break;
            }
        }
    }

    public get systemCounts(): Array<CountModel> {
        return this._systemCounts;
    }

    public get timeSetup(): TimeSetupModel {
        return this._timeSetup;
    }

    public get userCounts(): Array<CountModel> {
        return this._userCounts;
    }

    public get validatableRecording(): IValidatableRecordingModel {
        return this._validatableRecording;
    }

    public set validatableRecording(value: IValidatableRecordingModel) {
        if (this._validationRecording !== value) {
            this._validatableRecording = value;
        }
    }

    public get validationRecording(): IValidationRecordingModel {
        return this._validationRecording;
    }

    public get validationSession(): DbValidationSessionInfoModel {
        return this._validationSession;
    }

    public get currentVideoDevice(): string{
        return this.viewPort?.videoDevice?.deviceModel?.serialNumber;
    }

    public userSelectedVideoDevice(device: string){
        this.viewPort.setVideoDevice(device);

        this.viewPort.devices.forEach(d => {
            if (d.deviceModel.serialNumber === device){
                d.selected = true;
            }
            else{
                d.selected = false;
            }
        });

        this.onPlayLocationChanged(this.playService.currentLocation);
    }

    public zoomInViewRange(): void {
        this.playService.setViewRange(this.timeLine.durationView.leftPoint.playLocation, this.timeLine.durationView.rightPoint.playLocation);
    }

    public zoomOutViewRange(): void {
        this.playService.setViewRange({ offset: 0 }, { offset: this.playService.duration });
    }

    public onStickyZoomChange(event: MatCheckboxChange): void {
        if (event.checked === false) {
            this.playService.speedScale = 1;
        }
    }

    protected offline(): void {
        super.offline();
        if (this.isNullOrUndefined(this.sessionId)) {
            this.showSelectValidationRecording();
        }
    }

    protected onConnected(): void {
        super.onConnected();
    }

    protected onDisconnected(): void {
        super.onDisconnected();
    }

    protected online(): void {
        super.online();
        if (this.isNullOrUndefined(this.sessionId)) {
            this.showSelectValidationRecording();
        }
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
            this.addSubscription(this._validationService.onError.subscribe(error => this.onValidationServiceError(error)));
            this.addSubscription(this.observableHandlerBase(this.playService.onLocationChanged, this.onLocationChangedProcess).subscribe(playLocation => this.onPlayLocationChanged(playLocation)), this.onLocationChangedProcess);

            this.addSubscription(this._validationService.onProgressUpdate.subscribe(
                progress => {
                    if (!isNullOrUndefined(this._loadValidationRecordingDialogRef) && !isNullOrUndefined(this._loadValidationRecordingDialogRef.componentInstance)) {
                        this._loadValidationRecordingDialogRef.componentInstance.onUpdate(progress);
                    }
                }
            ));
        });
    }

    private bookmarkDetailsDialogClosed(result: BookmarkDetailsDialogResult, type: 'session' | 'global'): void {
        let obs: Observable<boolean> = null;
        if (type === 'session') {
            if (result.action === 'delete') {
                const frameNumber = result.bookmark.frameNumber;
                obs = this._validationService.deleteSessionBookmark(frameNumber, result.bookmark, this._validationSession, this._validationRecording, this.deleteSessionBookmarkProcess).pipe(
                    tap(() => {
                        this.sessionBookmarks[frameNumber] = undefined;
                    })
                );
            } else if (result.action === 'add') {
                const frameNumber = this.playService.currentLocation.frameNumber;
                this.sessionBookmarks[frameNumber] = result.bookmark;
                obs = this._validationService.addSessionBookmark(frameNumber, result.bookmark, this._validationSession, this._validationRecording, this.addSessionBookmarkProcess);
            }
        } else {
            if (result.action === 'delete') {
                const frameNumber = result.bookmark.frameNumber;
                obs = this._validationService.deleteRecordingBookmark(frameNumber, result.bookmark, this._validationRecording, this.deleteRecordingBookmarkProcess).pipe(
                    tap(() => {
                        this.globalBookmarks[frameNumber] = undefined;
                    })
                );
            } else if (result.action === 'add') {
                const frameNumber = this.playService.currentLocation.frameNumber;
                obs = this._validationService.addRecordingBookmark(frameNumber, result.bookmark, this._validationRecording, this.addRecordingBookmarkProcess).pipe(
                    map((bookmark) => {
                        this.globalBookmarks[frameNumber] = bookmark;
                        return true;
                    })
                );
            }
        }
        if (!isNullOrUndefined(obs)) {
            this.addSubscription(this.observableHandlerBase(obs, this.bookmarkDetailsDialogClosedProcess).subscribe(
                () => {
                    this.timeLine.globalBookmarks = this.globalBookmarks;
                    this.timeLine.sessionBookmarks = this.sessionBookmarks;
                }
            ), this.bookmarkDetailsDialogClosedProcess);
        }
    }

    private getCombinedDeveceData(devices: Array<DeviceModel>): Array<DeviceModel> {
        const results: DeviceModel[] = [];
        const length = devices.length;
        for (let ai = 0; ai < length; ai++) {
            const deviceA = devices[ai];

            if (deviceA.serialNumber !== '@000000') {
                if (results.findIndex(i => i.serialNumber === deviceA.serialNumber) === -1) {
                    for (let bi = 0; bi < length; bi++) {
                        const deviceB = devices[bi];

                        if (deviceA.serialNumber === deviceB.serialNumber) {
                            deviceA.setNullOrUndefinedFromDeviceModel(deviceB);
                            if (deviceA.height === 0 && !this.isNullOrUndefined(deviceB.height)) {
                                deviceA.height = deviceB.height;
                            }
                        }
                    }

                    results.push(deviceA);
                }
            }
        }
        return results;
    }

    private getValidatableRecording(): void {
        const pleaseWaitDialogRef = this.openPleaseWaitLoadingDialog();
        this.addSubscription(this.observableHandlerBase(this._validationService.getValidatableRecording(this.connectionService.hostFriendlySerial, this.sessionId, this.startTime, this.getValidatableRecordingProcess), this.getValidatableRecordingProcess).subscribe(
            validatableRecording => {
                if (!isNullOrUndefined(validatableRecording)) {
                    this.validatableRecording = validatableRecording;

                    this.addSubscription(pleaseWaitDialogRef.afterClosed().subscribe(
                        () => {
                            this._loadValidationRecordingDialogRef = this._dialog.open(LoadValidationRecordingComponent, { data: new LoadValidationRecordingData(validatableRecording), minWidth: 400, disableClose: true });

                            this.addSubscription(this._loadValidationRecordingDialogRef.afterClosed().subscribe(
                                () => this._loadValidationRecordingDialogRef = null
                            ));

                            this.addSubscription(this._loadValidationRecordingDialogRef.afterOpened().subscribe(
                                () => {
                                    this.preLoadRecordingHelper(validatableRecording);
                                }
                            ));
                        }
                    ));

                    pleaseWaitDialogRef?.close();

                } else {
                    this.validatableRecording = null;
                    pleaseWaitDialogRef?.close();

                    // Show error saying the file couldn't be opened and then bounce to the validation list
                    this.addSubscription(this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Validation Recording Error', `Could not open validation file`, false), disableClose: true }).afterClosed().subscribe(() => {
                        this.displaySessionSelector();
                    }));
                }
            },
            () => {
                if (!this.isNullOrUndefined(this._loadValidationRecordingDialogRef)) {
                    this._loadValidationRecordingDialogRef.close();
                }
                if (!this.isNullOrUndefined(pleaseWaitDialogRef)) {
                    pleaseWaitDialogRef.close();
                }
            },
        ), this.getValidatableRecordingProcess);
    }

    private preLoadRecordingHelper(validatableRecording: IValidatableRecordingModel){
        this.addSubscription(this.loadRecordingHelper(validatableRecording, true).subscribe(() => {
            this.postLoadRecordingHelper(validatableRecording);
        }), this.getRecordingProcess);
    }

    private postLoadRecordingHelper(validatableRecording: IValidatableRecordingModel){
        this.addSubscription(this.loadRecordingHelper(validatableRecording, false).subscribe((loadRecordingHelperData) => {
            if (isNullOrUndefined(loadRecordingHelperData.validationRecordingModel.dataValidation) || loadRecordingHelperData.validationRecordingModel.dataValidation.valid === true) {
                this._validationRecording = loadRecordingHelperData.validationRecordingModel;

                // Populate the node data correctly
                this.validatableRecording.onNodes.forEach(n => {
                    const nodeVal = loadRecordingHelperData.nodeValidationRecordingModels.find(f => f.friendlySerial === n.friendlySerial);

                    if (nodeVal !== null){
                        n.recordingId = nodeVal.recordingId;
                    }
                });

                this.onValidationRecordingChanged();
            } else {
                this._loadValidationRecordingDialogRef.close();
                this.addSubscription(this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Validation Recording Invalid', `The ${loadRecordingHelperData.validationRecordingModel.dataValidation.blockName} ${loadRecordingHelperData.validationRecordingModel.dataValidation.propertyName} has changed during the recording`, false), disableClose: true }).afterClosed().subscribe(() => {
                    this.displaySessionSelector();
                }));
            }
        }), this.getRecordingProcess);
    }

    private loadRecordingHelper(validatableRecording: IValidatableRecordingModel, preLoadOnly: boolean): Observable<ILoadRecordingHelperData>{
        let cancelled = false;

        this.addSubscription(this._loadValidationRecordingDialogRef.componentInstance.cancelled.subscribe(
            () => {
                cancelled = true;
            }
        ));

        const numberOfNodes = validatableRecording.onNodes.length;
        const nodeValidationRecordingModels: Array<IValidationRecordingModel> = new Array<IValidationRecordingModel>();

        const allNodeDataRequests: Array<Observable<IValidationRecordingModel>> = [];

        if (numberOfNodes > 0){
            for (let i = 0; i < numberOfNodes; i++){
                const node = validatableRecording.onNodes[i];
                const nodeRequest = this.loadNodeRecordingHelper(node, preLoadOnly);

                allNodeDataRequests.push(nodeRequest);
            }
        }
        else{
            allNodeDataRequests.push(of(null));
        }

        const getNodeData = zip(...allNodeDataRequests);

        const retVal: Subject<ILoadRecordingHelperData> = new Subject();

        // Get the node data before we get the master
        this.addSubscription(getNodeData.subscribe(
            allNodeData => {
                nodeValidationRecordingModels.push(...allNodeData.filter(n => !isNullOrUndefined(n)));

                // load the master
                this.addSubscription(this._validationService.getRecording(validatableRecording, preLoadOnly, this.getRecordingProcess).subscribe(
                    validationRecording => {
                        if (cancelled === false) {
                            retVal.next({
                                validationRecordingModel: validationRecording,
                                nodeValidationRecordingModels
                            });

                            retVal.complete();
                        } else {
                            this.displaySessionSelector();
                            this._loadValidationRecordingDialogRef.close();
                        }
                    },
                    () => {
                        this._loadValidationRecordingDialogRef.close();
                        retVal.error('Failed to load validation');
                    },
                ));
            }
        ));

        return retVal;
    }

    private loadNodeRecordingHelper(node: IValidatableRecordingModel, preLoadOnly: boolean): Observable<IValidationRecordingModel>{
        return this._validationService.getRecording(node, preLoadOnly, this.getRecordingProcess).pipe(
            map(validationRecording => {
                if (isNullOrUndefined(validationRecording.dataValidation) || validationRecording.dataValidation.valid === true) {
                    // Everything worked
                    return validationRecording;
                } else {
                    this._loadValidationRecordingDialogRef.close();
                    this.addSubscription(this._dialog.open(OkCancelDialogComponent, { data: new OkCancelDialogData('Validation Recording Invalid', `The ${validationRecording.dataValidation.blockName} ${validationRecording.dataValidation.propertyName} has changed during the recording`, false), disableClose: true }).afterClosed().subscribe(() => {
                        this.displaySessionSelector();
                    }));

                    return null;
                }
            }
        ));
    }

    private loadAutoSpeedProfile(autoSpeedProfile: Array<number>): void {
        if (isNullOrUndefined(this._autoSpeedProfile)) {
            this._autoSpeedProfile = [];
        }

        const start = ArrayUtility.indexOfNextNotNullOrUndefined(autoSpeedProfile);
        const end = ArrayUtility.indexOfPreviousNotNullOrUndefined(autoSpeedProfile);

        for (let i = start; i <= end; i++) {
            this._autoSpeedProfile[i] = autoSpeedProfile[i];
        }
    }

    private onModeUpdated(): void {
        this.newSessionValid = false;
        this.newSessionErrorMessage = null;

        switch (this.mode) {
            case ValidationModeEnum.preview:
                this.modeText = 'Recording Preview';
                if (!isNullOrUndefined(this.timeLine) && !isNullOrUndefined(this.timeLine.rows)) {
                    this.playService.pause();
                    this.viewPort.registers.forEach(i => i.visible = true);
                    this.timeLine.rows.items.forEach(i => i.visible = true);
                    this.timeLine.rows.userCountsVisible = false;
                    this.timeLine.rows.setSelectEnabled(false);
                }
                break;
            case ValidationModeEnum.createSession:
                this.modeText = 'Validation Session Creation';
                if (!isNullOrUndefined(this.timeLine) && !isNullOrUndefined(this.timeLine.rows)) {
                    this.playService.pause();
                    this.viewPort.registers.forEach(i => i.visible = true);
                    this.timeLine.rows.items.forEach(i => i.visible = true);
                    this.timeLine.rows.userCountsVisible = false;
                    this.timeLine.rows.setSelectEnabled(true);
                }
                this.newSessionErrorMessage = 'Please select a register';
                break;
            case ValidationModeEnum.validation:
                this.modeText = 'Recording Validation';
                if (!isNullOrUndefined(this.timeLine) && !isNullOrUndefined(this.timeLine.rows)) {
                    this.playService.pause();
                    this.setViewPortRegistersVisible();
                    this.timeLine.rows.items.forEach(i => i.visible = this._validationSession.registersToValidate.indexOf(i.registerIndex) !== -1);
                    this.timeLine.rows.userCountsVisible = true;
                    this.timeLine.rows.setSelectEnabled(false);
                }
                break;
            case ValidationModeEnum.review:
                this.modeText = `Validation Review (${this._validationSession.passed === true ? 'Passed' : 'Rejected'})`;
                if (!isNullOrUndefined(this.timeLine) && !isNullOrUndefined(this.timeLine.rows)) {
                    this.playService.pause();
                    this.setViewPortRegistersVisible();
                    this.timeLine.rows.items.forEach(i => i.visible = this._validationSession.registersToValidate.indexOf(i.registerIndex) !== -1);
                    this.timeLine.rows.userCountsVisible = true;
                    this.timeLine.rows.setSelectEnabled(false);
                }
                break;
        }

        if (!isNullOrUndefined(this.timeLine) && !isNullOrUndefined(this.timeLine.rows)) {
            this.timeLine.rows.setOffsets();
        }
        this.filledHeight(this._height);

        this.setSystemCountsMenuCounts(this.playService.currentLocation.frameNumber);
        this.setUserCountsMenuCounts(this.playService.currentLocation.frameNumber);
    }

    private setViewPortRegistersVisible(): void {
        const registersLength = this.registers.length;

        for (let i = 0; i < registersLength; i++) {
            const register = this.registers[i];
            const registerIndex = register.registerIndex;

            const vpRegister = this.viewPort.registers.find(vpr => vpr.registerBaseModel.registerIndex === registerIndex);
            if (!this.isNullOrUndefined(vpRegister)) {
                vpRegister.visible = this._validationSession.registersToValidate.indexOf(registerIndex) !== -1;
            }
        }

        for (let i = 0; i < registersLength; i++) {
            const register = this.registers[i];
            const registerIndex = register.registerIndex;

            if (register.hasAssociated === true) {
                if (this._validationSession.registersToValidate.indexOf(registerIndex) !== -1) {
                    const associatedRegisters = RegisterBaseUtility.getAssociatedRegisters(register, this.registers);

                    if (!this.isNullOrUndefined(associatedRegisters) && associatedRegisters.length > 0) {
                        const associatedLength = associatedRegisters.length;
                        for (let ai = 0; ai < associatedLength; ai++) {
                            const associatedRegister = associatedRegisters[ai];
                            if (!this.isNullOrUndefined(associatedRegister)) {
                                const associatedVpRegister = this.viewPort.registers.find(vpr => vpr.registerBaseModel.registerIndex === associatedRegister.registerIndex);
                                if (!this.isNullOrUndefined(associatedVpRegister)) {
                                    associatedVpRegister.visible = true;
                                }
                            }
                        }
                    }
                } else {
                    const vpRegister = this.viewPort.registers.find(vpr => vpr.registerBaseModel.registerIndex === registerIndex);
                    if (!this.isNullOrUndefined(vpRegister)) {
                        vpRegister.visible = false;
                    }
                }
            }
        }
    }

    private getSyncFrameForNode(deviceSerial: string, frameNumber: number): IValidationSyncFrameEntryModel{
        // Not every device will have a sync frame for every frame so when finding the sync frames
        // we need to make sure that we are finding one for the correct device otherwise
        // we can pick the wrong frame to display
        const syncData = ArrayUtility.closestNotNullOrUndefined(this._syncFramesBuffer, frameNumber);

        if (!isNullOrUndefined(syncData)){
            const nodeData = syncData.syncFrames.find(n => n.serial === deviceSerial);

            if (!isNullOrUndefined(nodeData)){
                return nodeData;
            }
            else{
                // We need to move through the syncFrameBuffer array until we find
                // an entry that supports the node
                const index = ArrayUtility.indexOfClosestNotNullOrUndefined(this._syncFramesBuffer, frameNumber);
                const syncFrameLength = this._syncFramesBuffer.length;

                for (let i = index; i < syncFrameLength; i++){
                    const currentSyncFrame = this._syncFramesBuffer[i];

                    if (!isNullOrUndefined(currentSyncFrame)){
                        const currentNodeData = currentSyncFrame.syncFrames.find(n => n.serial === deviceSerial);

                        if (!isNullOrUndefined(currentNodeData)){
                            return currentNodeData;
                        }
                    }
                }
            }
        }

        return null;
    }

    private setCurrentFrameForLocation(playLocation: IPlayLocation): void {
        // The play location will always be the master frame number
        // if we are currently on a node then we need to use
        // the sync frame info to work out which frame on the node should
        // be displayed
        let frameNumber = playLocation.frameNumber;
        const displayDeviceSerial = this.viewPort.videoDevice.deviceModel.serialNumber;

        if (displayDeviceSerial !== this.validationRecording.friendlySerial){
            // node
            const nodeData = this.getSyncFrameForNode(displayDeviceSerial, frameNumber);

            if (!isNullOrUndefined(nodeData)){
                frameNumber = nodeData.frameNumber;
            }
        }

        // Might need to see how it performs always looking for the buffer, if it's bad
        // we'll need something that tracks which device is currently being displayed
        // and that will have to deal with modifying which buffer is displayed, similar
        // might need to happen in the caching although that caches groups of
        // frames so it might be ok
        const videoBuffer = this._frameBuffer.videoBuffers.find(b => b.serial === displayDeviceSerial);

        const frame = ArrayUtility.closestNotNullOrUndefined(videoBuffer.video, frameNumber);
        if (!isNullOrUndefined(frame)) {
            this.viewPort.videoDevice.video.setCurrentFrame(frame);
        }
    }

    private setCurrentKeyFrameForLocation(playLocation: IPlayLocation): void {
        let frameNumber = playLocation.frameNumber;
        const displayDeviceSerial = this.viewPort.videoDevice.deviceModel.serialNumber;

        if (displayDeviceSerial !== this.validationRecording.friendlySerial){
            // node
            const nodeData = this.getSyncFrameForNode(displayDeviceSerial, frameNumber);

            if (!isNullOrUndefined(nodeData)){
                frameNumber = nodeData.frameNumber;
            }
        }

        const keyFrameBuffer = this._keyVideoFrames.find(b => b.serial === displayDeviceSerial);

        const frame = ArrayUtility.closestNotNullOrUndefined(keyFrameBuffer.video, frameNumber);
        if (!isNullOrUndefined(frame)) {
            this.viewPort.videoDevice.video.setCurrentFrame(frame);
        }
    }

    private inBufferRange(playLocation: IPlayLocation): boolean {
        return playLocation.frameNumber >= this._frameBuffer.start && playLocation.frameNumber <= this._frameBuffer.end;
    }

    private overBufferPreFetchRange(playLocation: IPlayLocation): boolean {
        return this._frameBuffer.end < (playLocation.frameNumber + this._frameBuffer.fetchBeforeEnd);
    }

    private getFrames(playLocation: IPlayLocation, startFrame: number, showFirstVideoFrame: boolean = false): Observable<boolean> {
        const allDevices: Array<Observable<boolean>> = [];

        const masterRequest = this._validationService.getRecordingFrames(this.autoSpeedSettings, startFrame, this._frameBuffer.fetchBlockSize, this.validationRecording.recordingId, this.getRecordingFramesProcess).pipe(
            mergeMap(
                (framesData: IFramesData) => {
                    this.loadTargets(framesData);
                    this.loadAutoSpeedProfile(framesData.autoSpeedProfile);
                    this.loadVideoToBuffer(framesData, this.validationRecording.friendlySerial);
                    this.loadSyncFramesToBuffer(framesData);

                    const numNodes = this.validatableRecording.onNodes.length;
                    for (let i = 0; i < numNodes; i++){
                        const nodeData = this.validatableRecording.onNodes[i];
                        const syncData = ArrayUtility.closestNotNullOrUndefined(this._syncFramesBuffer, startFrame);
                        let nodeStartFrame = startFrame;

                        if (!isNullOrUndefined(syncData)){
                            const nodeDataSyncFrames = syncData.syncFrames.find(n => n.serial === nodeData.friendlySerial);

                            if (!isNullOrUndefined(nodeDataSyncFrames)){
                                nodeStartFrame = nodeDataSyncFrames.frameNumber;
                            }
                        }

                        const nodeRequest = this._validationService.getRecordingFrames(this.autoSpeedSettings, nodeStartFrame, this._frameBuffer.fetchBlockSize, nodeData.recordingId, this.getRecordingFramesProcess).pipe(
                            map(
                                (nodeFramesData: IFramesData) => {
                                    this.loadVideoToBuffer(nodeFramesData, nodeData.friendlySerial);

                                    return true;
                                }
                            )
                        );

                        allDevices.push(nodeRequest);
                    }

                    if (numNodes === 0){
                        allDevices.push(of(true));
                    }

                    return combineLatest([zip(...allDevices).pipe(concatAll())]);
                }
            ),
            combineAll(),
            flatMap(result => {
                if (showFirstVideoFrame === true) {
                    const firstFrameNumber = 0;
                    const videoBuffer = this._frameBuffer.videoBuffers.find(b => b.serial === this.validationRecording.friendlySerial);

                    this.setCurrentFrameForLocation(this.playService.fillPlayLocation({ frameNumber: firstFrameNumber }));
                } else {
                    this.setCurrentFrameForLocation(playLocation);
                }
                this.viewPort.setPersistentTargetsFrameNumber(playLocation.frameNumber);

                return of(true);
            })
        );

        return masterRequest;
    }

    private loadKeyFramesBlock(keyFramesBlock: IKeyFramesData, deviceSerial: string): void {
        if (isNullOrUndefined(this._keyVideoFrames)) {
            this._keyVideoFrames = [];
        }

        if (!isNullOrUndefined(keyFramesBlock.video)) {
            const length = keyFramesBlock.video.length;

            let keyFrameBuffer = this._keyVideoFrames.find(v => v.serial === deviceSerial);

            if (isNullOrUndefined(keyFrameBuffer)){
                keyFrameBuffer = {
                    serial: deviceSerial,
                    video: []
                };

                this._keyVideoFrames.push(keyFrameBuffer);
            }

            for (let i = 0; i < length; i++) {
                const items = keyFramesBlock.video[i];
                if (!isNullOrUndefined(items)) {
                    keyFrameBuffer.video[i] = items;
                }
            }
        }

        if (!isNullOrUndefined(keyFramesBlock.targets)) {
            this.loadTargets(keyFramesBlock);
        }

        if(!isNullOrUndefined(keyFramesBlock.syncFrames)){
            this.loadSyncFramesToBuffer(keyFramesBlock);
        }
    }

    private loadTargets(framesData: IFramesData | IKeyFramesData): void {
        if (!isNullOrUndefined(framesData.targets)) {
            const keys = Object.keys(framesData.targets);
            const keysLength = keys.length;

            if (keysLength > 1) {
                const start = parseInt(keys[0], 10);
                const end = parseInt(keys[keysLength - 1], 10);

                for (let i = start; i <= end; i++) {
                    const targets = framesData.targets[i];
                    if (!isNullOrUndefined(targets)) {
                        this.viewPort.addPersistentTargets(i, targets.items);
                    }
                }
            }
        }
    }

    private loadSyncFramesToBuffer(framesData: IFramesData | IKeyFramesData): void{
        if (!isNullOrUndefined(framesData.syncFrames) && framesData.syncFrames.length > 0){
            const keys = Object.keys(framesData.syncFrames);
            const keysLength = keys.length;

            if (keysLength > 1) {
                const start = parseInt(keys[0], 10);
                const end = parseInt(keys[keysLength - 1], 10);

                for (let i = start; i <= end; i++) {
                    const syncFrames = framesData.syncFrames[i];

                    if (!isNullOrUndefined(syncFrames)) {
                        if (isNullOrUndefined(this._syncFramesBuffer[i])){
                            this._syncFramesBuffer[i] = {
                                syncFrames : syncFrames.syncFrames
                            };
                        }
                        else{
                            this._syncFramesBuffer[i].syncFrames.push(...syncFrames.syncFrames);
                        }
                    }
                }
            }

        }
    }

    private loadVideoToBuffer(framesData: IFramesData, deviceSerial: string): void {
        const framesDataKeys = Object.keys(framesData.video);
        const framesDataKeysLength = framesDataKeys.length;

        if (framesDataKeysLength > 0) {
            const start = parseInt(framesDataKeys[0], 10);
            const end = parseInt(framesDataKeys[framesDataKeysLength - 1], 10);

            let videoBuffer = this._frameBuffer.videoBuffers.find(v => v.serial === deviceSerial);

            if (isNullOrUndefined(videoBuffer)){
                videoBuffer = {
                    serial: deviceSerial,
                    video: []
                };

                this._frameBuffer.videoBuffers.push(videoBuffer);
            }

            for (let i = start; i <= end; i++) {
                const video = framesData.video[i];
                if (!isNullOrUndefined(video)) {
                    videoBuffer.video[i] = video;
                }
            }
        }
    }

    private onPlayLocationChanged(playLocation: IPlayLocation): void {
        this._zone.runOutsideAngular(() => {
            if (this.autoSpeedEnabled === true && !isNullOrUndefined(this._autoSpeedProfile)) {
                const speed = this._autoSpeedProfile[playLocation.frameNumber];
                if (!isNullOrUndefined(speed)) {
                    this.playService.speedScale = this._autoSpeedProfile[playLocation.frameNumber];
                }
            }

            if (isNullOrUndefined(this._getFramesSub)) {
                let getFrames: boolean = false;
                let preLoadFrames: boolean = false;
                let pausePlay: boolean = false;
                let dialogRef: MatDialogRef<PleaseWaitDialogComponent> = null;
                let startFrameNumber: number = null;
                let endFrameNumber: number = null;

                if (!this.inBufferRange(playLocation)) {
                    dialogRef = this.openPleaseWaitDialog('Frame Data Loading');

                    if (this.playService.playState === PlayStateEnum.playing) {
                        this.playService.pause();
                        pausePlay = true;
                    }

                    startFrameNumber = playLocation.frameNumber;
                    endFrameNumber = playLocation.frameNumber + this._frameBuffer.fetchBlockSize;

                    this._frameBuffer.nextStart = startFrameNumber;
                    this._frameBuffer.nextEnd = endFrameNumber > this.validationRecording.frames ? this.validationRecording.frames : endFrameNumber;

                    getFrames = true;
                }

                if (getFrames === false && this.overBufferPreFetchRange(playLocation)) {
                    startFrameNumber = this._frameBuffer.end;
                    endFrameNumber = startFrameNumber + this._frameBuffer.fetchBlockSize;

                    this._frameBuffer.nextStart = startFrameNumber;
                    this._frameBuffer.nextEnd = endFrameNumber > this.validationRecording.frames ? this.validationRecording.frames : endFrameNumber;

                    getFrames = true;
                    preLoadFrames = true;
                }

                if (getFrames === true) {
                    this._getFramesSub = this.addSubscription(this.observableHandlerBase(this.getFrames(playLocation, startFrameNumber, false), this.getFramesProcess).subscribe(
                        () => {
                            if (!isNullOrUndefined(this._frameBuffer.nextStart) && !isNullOrUndefined(this._frameBuffer.nextEnd)) {

                                if (preLoadFrames === true) {
                                    this._frameBuffer.start = this._frameBuffer.nextEnd - (2 * this._frameBuffer.fetchBlockSize);
                                    this._frameBuffer.end = this._frameBuffer.nextEnd;
                                } else {
                                    this._frameBuffer.start = this._frameBuffer.nextStart;
                                    this._frameBuffer.end = this._frameBuffer.nextEnd;
                                }

                                this.viewPort.removePersistentTargets(this._frameBuffer.start, this._frameBuffer.end);

                                for (let j = 0; j < this._frameBuffer.videoBuffers.length; j++){
                                    const videoBuffer = this._frameBuffer.videoBuffers[j];

                                    // adjust the frame buffer start value for the device
                                    let bufferStart = this._frameBuffer.start;
                                    let bufferEnd = this._frameBuffer.end;

                                    if (videoBuffer.serial !== this.validationRecording.friendlySerial){
                                        const syncStartNodeData = this.getSyncFrameForNode(videoBuffer.serial, this._frameBuffer.start);
                                        const syncEndNodeData = this.getSyncFrameForNode(videoBuffer.serial, this._frameBuffer.end);

                                        if (!isNullOrUndefined(syncStartNodeData)){
                                            bufferStart = syncStartNodeData.frameNumber;
                                        }

                                        if (!isNullOrUndefined(syncEndNodeData)){
                                            bufferEnd = syncEndNodeData.frameNumber;
                                        }
                                    }

                                    for (let i = 0; i < bufferStart; i++) {
                                        delete videoBuffer.video[i];
                                    }

                                    for (let i = bufferEnd; i < videoBuffer.video.length; i++){
                                        delete videoBuffer.video[i];
                                    }
                                }

                                this._frameBuffer.nextStart = null;
                                this._frameBuffer.nextEnd = null;
                            }

                            if (!isNullOrUndefined(dialogRef)) {
                                dialogRef.close();
                            }

                            if (pausePlay === true && this.playService.playState === PlayStateEnum.paused) {
                                this.playService.play();
                            }

                            this._getFramesSub = null;
                        }
                    ), this.getFramesProcess);
                } else {
                    this.setCurrentFrameForLocation(playLocation);
                    this.viewPort.setPersistentTargetsFrameNumber(playLocation.frameNumber);
                }
            } else {
                this.setCurrentFrameForLocation(playLocation);
                this.viewPort.setPersistentTargetsFrameNumber(playLocation.frameNumber);
            }

            this.setSystemCountsMenuCounts(playLocation.frameNumber);
            this.setUserCountsMenuCounts(playLocation.frameNumber);
        });
    }

    private onSessionIdUpdated(): void {
        this._validatableRecording = null;
        this._validationRecording = null;
        this._validationSession = null;
        this.getValidatableRecording();
    }

    private onTimeLinePlayPointDrag(event: DisplayItemMouseEvent): void {
        this._timeLinePlayPointDragging = true;
        if (this.playService.playState === PlayStateEnum.playing) {
            this.playService.pause();
            this._timeLinePlayPointDragPaused = true;
        }
        if (this._playPointDragIntervalTracker.tick() === true) {
            if (this.timeLine.durationView.playPoint.isDragging === true) {
                const playLocation = this.timeLine.durationView.playPoint.playLocation;
                if (playLocation.frameNumber >= this._frameBuffer.start && (playLocation.frameNumber <= this._frameBuffer.end || playLocation.frameNumber <= this._frameBuffer.nextEnd)) {
                    this.playService.currentLocation = playLocation;
                } else {
                    this.setSystemCountsMenuCounts(playLocation.frameNumber);
                    this.setUserCountsMenuCounts(playLocation.frameNumber);
                    this.setCurrentKeyFrameForLocation(playLocation);
                    this.viewPort.setPersistentTargetsFrameNumber(playLocation.frameNumber);
                    this.viewPort.update();
                }
            }
        }
    }

    private onTimeLinePlayPointDragEnd(event: DisplayItemMouseEvent): void {
        this._timeLinePlayPointDragging = false;
        if (this._timeLinePlayPointDragPaused === true) {
            this.playService.play();
            this._timeLinePlayPointDragPaused = false;
        }
    }

    private getAllDeviceRecordingKeyFrames(atKeyFrame: number): Observable<boolean>{
        const allDevicesKeyFrames: Array<Observable<boolean>> = [];

        const numNodes = this.validatableRecording.onNodes.length;

        const masterRequest = this._validationService.getRecordingKeyFrames(this.validationRecording.recordingId, true, numNodes > 0, this.getRecordingKeyFramesProcess).pipe(
            map((keyFramesBlock) => {
                if (!isNullOrUndefined(this.validationRecording)){
                    this.loadKeyFramesBlock(keyFramesBlock, this.validationRecording.friendlySerial);

                    if (!isNullOrUndefined(keyFramesBlock.video)) {
                        const length = keyFramesBlock.video.length;
                        if (length > atKeyFrame) {
                            atKeyFrame = keyFramesBlock.video.length;
                        }
                    } else if (!isNullOrUndefined(keyFramesBlock.targets)) {
                        const length = keyFramesBlock.targets.length;
                        if (length > atKeyFrame) {
                            atKeyFrame = keyFramesBlock.targets.length;
                        }
                    }

                    if (!isNullOrUndefined(this._loadValidationRecordingDialogRef) && !isNullOrUndefined(this._loadValidationRecordingDialogRef.componentInstance)) {
                        this._loadValidationRecordingDialogRef.componentInstance.onUpdate({ responseType: 'upd', atFrame: atKeyFrame, updating: 'keyframes', id: 0, type: ResponseTypesEnum.update, fromAction: '' });
                    }
                }

                return true;
            })
        );

        for (let i = 0; i < numNodes; i++){
            const nodeData = this.validatableRecording.onNodes[i];

            const nodeRequest = this._validationService.getRecordingKeyFrames(nodeData.recordingId, false, false, this.getRecordingKeyFramesProcess).pipe(
                map((keyFramesBlock) => {
                    this.loadKeyFramesBlock(keyFramesBlock, nodeData.friendlySerial);

                    return true;
                })
            );

            allDevicesKeyFrames.push(nodeRequest);
        }

        allDevicesKeyFrames.push(masterRequest);

        return of(...allDevicesKeyFrames).pipe(
            combineAll(),
            mergeAll(),
            catchError((err: any) => {
                console.log(err);

                return EMPTY;
            })
        );
    }

    private onValidationRecordingChanged(): void {
        if (!isNullOrUndefined(this.validationRecording)) {
            this.viewPort.clear();
            this.timeLine.clear();

            this.timeZone = DateTimeUtility.getTimeZoneByTimeOffsetMinutes(this.validationRecording.timezoneOffsetMins);
            this.dateText = DateTimeUtility.toShortDate(this.validationRecording.startTime, this.timeZone);
            this.startText = DateTimeUtility.toShortTime(this.validationRecording.startTime, this.timeZone);
            this.endText = DateTimeUtility.toShortTime(this.validationRecording.endTime, this.timeZone);
            this.durationText = DateTimeUtility.toDuration(this.validationRecording.startTime, this.validationRecording.endTime);

            this.timeLine.timeZone = this.timeZone;

            this._frameBuffer.start = 0;
            this.setFrameBuffer(0);

            this.playService.start(this.validationRecording);

            this._keyVideoFrames = null;

            if (!isNullOrUndefined(this._downloadStatusTimer)) {
                this._downloadStatusTimer.unsubscribe();
                this._downloadStatusTimer = null;
            }

            this._downloadStatusTimer = this.addSubscription(timer(100, 1500).subscribe(
                () => {
                    if (!isNullOrUndefined(this.validationRecording)){
                        this.addSubscription(this._validationService.getRecordingDownloadStatus(this.validationRecording.recordingId).subscribe(
                            (status) => {
                                if (!this.isNullOrUndefined(this.timeLine) && !this.isNullOrUndefined(this.timeLine.durationView) && !this.isNullOrUndefined(this.timeLine.durationView.bar) && !this.isNullOrUndefined(this.timeLine.durationView.bar.postLoadBuffer) && !this.isNullOrUndefined(this.timeLine.durationView.bar.postLoadBuffer)) {
                                    this.timeLine.durationView.bar.preLoadBuffer.setState(status.preloadFrames, status.preloadComplete);
                                    this.timeLine.durationView.bar.postLoadBuffer.setState(status.postLoadFrames, status.postLoadCompleted);
                                }

                                if (status.isComplete === true) {
                                    this._downloadStatusTimer.unsubscribe();
                                }
                            }
                        ));
                    }
                }
            ));

            const atKeyFrame = 0;
            if (!isNullOrUndefined(this._loadValidationRecordingDialogRef) && !isNullOrUndefined(this._loadValidationRecordingDialogRef.componentInstance)) {
                this._loadValidationRecordingDialogRef.componentInstance.onUpdate({ responseType: 'upd', atFrame: atKeyFrame, updating: 'keyframes', id: 0, type: ResponseTypesEnum.update, fromAction: '' });
            }

            this.addSubscription(this.observableHandlerBase(this.getAllDeviceRecordingKeyFrames(atKeyFrame), this.getRecordingKeyFramesProcess).subscribe(), this.getRecordingKeyFramesProcess);

            const nodesGetRecordingDevices: Array<Observable<DeviceModel[]>> = new Array<Observable<DeviceModel[]>>();
            const numNodes = this.validationRecording.onNodes.length;

            for (let i = 0; i < numNodes; i++){
                const nodeData = this.validationRecording.onNodes[i];

                const validatableNode = this._validatableRecording.onNodes.find(d => d.friendlySerial === nodeData.friendlySerial);

                if (!isNullOrUndefined(validatableNode)){
                    const nodeRecordingModel = validatableNode as IValidationRecordingModel;

                    const nodeObservable = this._validationService.getRecordingDevices(nodeRecordingModel, this.onValidationRecordingChangedProcess);

                    nodesGetRecordingDevices.push(nodeObservable);
                }
            }

            const getData = zip(
                this._validationService.getRecordingDevices(this.validationRecording, this.onValidationRecordingChangedProcess),
                this._validationService.getRecordingLines(this.validationRecording, this.onValidationRecordingChangedProcess),
                this._validationService.getRecordingPolygons(this.validationRecording, this.onValidationRecordingChangedProcess),
                this._validationService.getRecordingRegisters(this.validationRecording, this.onValidationRecordingChangedProcess),
                this._validationService.getRecordingTimeData(this.validationRecording, this.onValidationRecordingChangedProcess),
                this._validationService.getRecordingGlobalData(this.validationRecording, this.onValidationRecordingChangedProcess),
                this._validationService.getRecordingCounts(this.validationRecording, this.onValidationRecordingChangedProcess),
                this._validationService.getRecordingBookmarks(this.validationRecording, this.onValidationRecordingChangedProcess),
                this._validationService.getSessions(this.validationRecording, this.onValidationRecordingChangedProcess),
                ...nodesGetRecordingDevices
            );

            this.addSubscription(this.observableHandlerBase(getData, this.onValidationRecordingChangedProcess).subscribe(
                results => {
                    const resultLength = (results as Array<unknown>).length;

                    const deviceArray: Array<DeviceModel> = new Array<DeviceModel>();
                    const masterResult = results[0] as Array<DeviceModel>;

                    deviceArray.push(...masterResult);

                    for (let i = 9; i < resultLength; i++){
                        deviceArray.push(...(results[i] as Array<DeviceModel>));
                    }

                    this._globalBookmarks = new Array<BookmarkModel>(this.validatableRecording.frames);
                    this._devices = this.getCombinedDeveceData(deviceArray);
                    this._lines = ArrayUtility.distinct(results[1], 'iD') as Array<LineModel>;
                    this._polygons = ArrayUtility.distinct(results[2], 'iD') as Array<PolygonModel>;
                    this._registers = (ArrayUtility.distinct(results[3], 'registerIndex') as Array<RegisterBaseModel>).map(i => { i.setFlags(); return i; });
                    this._timeSetup = results[4][0] as TimeSetupModel;
                    this._globalData = results[5][0] as GlobalModel;
                    this._systemCounts = results[6] as Array<CountModel>;
                    this._globalBookmarks = results[7] as Array<BookmarkModel>;
                    this._sessions = results[8] as Array<DbValidationSessionInfoModel>;

                    if (isNullOrUndefined(this._syncFramesBuffer)){
                        this._syncFramesBuffer = [];
                    }
                    else{
                        this._syncFramesBuffer.splice(0, this._syncFramesBuffer.length);
                    }

                    const master = this._devices.find(d => d.master === true);

                    this.viewPort.targets.setPersistent();
                    this.viewPort.addDevices(this._devices);
                    this.viewPort.addRegisters(this._registers, this._lines, this._polygons);
                    this.viewPort.targets.markedTargetsEnabled = this.viewPort.registers.markedTargetsEnabled();
                    this.viewPort.center();
                    this.viewPort.setVideoDevice(master.serialNumber);

                    this.viewPort.registers.forEach(r => {
                        r.mouseEnabled = false;
                    });

                    this.viewPort.devices.forEach(d => {
                        if (d.deviceModel.serialNumber === master.serialNumber){
                            d.selected = true;
                        }
                        else{
                            d.selected = false;
                        }

                        if (this.validatableRecording.onNodes.some(n => n.friendlySerial === d.deviceModel.serialNumber) || this.validatableRecording.friendlySerial === d.deviceModel.serialNumber){
                            this.addSubscription(this.observableHandlerBase(d.deviceClick, null).subscribe(e => {
                                this.userSelectedVideoDevice(e.deviceModel.serialNumber);
                            }), null);
                        }
                    });

                    this.viewPort.videoDevice.video.hullScaleFactor = this.hullScaleFactor;

                    const length = this._registers.length;
                    for (let i = 0; i < length; i++) {
                        const register = this._registers[i];
                        this.timeLine.addRegister(register, this._registers, this._lines, this._polygons);
                    }
                    this.timeLine.rows.setOffsets();
                    this.timeLine.globalBookmarks = this.globalBookmarks;

                    this.loadCounts(this._systemCounts, 'systemCounts');

                    const firstIndex = ArrayUtility.indexOfNextNotNullOrUndefined(this._systemCounts);
                    this.countsMenu.firstSystemCount = this._systemCounts[firstIndex];

                    this.setFrameBuffer(0);
                    this.addSubscription(this.observableHandlerBase(this.getFrames({ offset: 0, frameNumber: 0 }, 0, true), this.getFramesProcess).subscribe(
                        () => {
                            this.onModeUpdated();
                            this._loadValidationRecordingDialogRef.close();
                        },
                    ), this.getFramesProcess);
                },
                error => {
                    this._loadValidationRecordingDialogRef.close();
                }
            ), this.onValidationRecordingChangedProcess);
        }
    }

    private onValidationServiceError(error: Error): void {
        if (!isNullOrUndefined(this._autoSpeedSettingsDialogRef)) {
            this._autoSpeedSettingsDialogRef.close();
        }
        if (!isNullOrUndefined(this._bookmarkDetailsDialogRef)) {
            this._bookmarkDetailsDialogRef.close();
        }
        if (!isNullOrUndefined(this._loadValidationRecordingDialogRef)) {
            this._loadValidationRecordingDialogRef.close();
        }
        if (!isNullOrUndefined(this._selectValidationRecordingRef)) {
            this._selectValidationRecordingRef.close();
        }
        if (!isNullOrUndefined(this._sessionSyncResolveDialogRef)) {
            this._sessionSyncResolveDialogRef.close();
        }
        if (!isNullOrUndefined(this._validationSettingsDialogRef)) {
            this._validationSettingsDialogRef.close();
        }

        if (!isNullOrUndefined(error) && !isNullOrUndefined(error.message) && error.message.indexOf('QuotaExceededError') !== -1) {
            this.addSubscription(this.openOkCancelDialog('Validation Storage Exceeded', 'You have exceeded the validation storage limit please remove some in Manage Validations', false).afterClosed().subscribe(
                (result: OkCancelDialogResult) => {
                    if (!this.isNullOrUndefined(result) && result.ok === true) {
                        this._dialog.open(ManageSynchronizedRecordingsComponent, { data: new ManageSynchronizedRecordingsDialogData(), minWidth: 850, disableClose: true });
                    } else {
                        this._location.back();
                    }
                }
            ));
        }

        console.log(error.message);
        this.openOkCancelDialog('Validation Error', 'Error processing validation file', false);
    }

    private rowSelectedChanged(event: TimeLineRowSelect): void {
        const length = this.timeLine.rows.length;
        let selectedCount = 0;
        for (let i = 0; i < length; i++) {
            if (this.timeLine.rows.items[i].select.isSelected) {
                selectedCount++;
            }
        }

        this._zone.run(() => {
            if (selectedCount <= 0) {
                this.newSessionErrorMessage = 'Please select a register';
                this.newSessionValid = false;
            } else if (selectedCount > 6) {
                this.newSessionErrorMessage = 'You can only select 6 registers';
                this.newSessionValid = false;
            } else {
                this.newSessionErrorMessage = null;
                this.newSessionValid = true;
            }
        });
    }

    private saveSessionState(): Observable<boolean> {
        if (!this.isNullOrUndefined(this._validationSession)) {
            this._validationSession.lastLocation = this.playService.currentLocation;
            return this.updateSession();
        } else {
            return of(true);
        }
    }

    private setSystemCountsMenuCounts(frameNumber: number): void {
        this._zone.run(() => {
            if (!this.isNullOrUndefined(this.countsMenu)) {
                if (!isNullOrUndefined(this._systemCounts)) {
                    this.countsMenu.systemCount = ArrayUtility.previousNotNullOrUndefined(this._systemCounts, frameNumber);
                } else {
                    this.countsMenu.systemCount = null;
                }
            }
        });
    }

    private setUserCountsMenuCounts(frameNumber: number): void {
        this._zone.run(() => {
            if (!this.isNullOrUndefined(this.countsMenu)) {
                if (!isNullOrUndefined(this._userCounts)) {
                    this.countsMenu.userCount = ArrayUtility.previousNotNullOrUndefined(this._userCounts, frameNumber);
                } else {
                    this.countsMenu.userCount = null;
                }
            }
        });
    }

    private setFrameBuffer(startFrame: number): void {
        const end = startFrame + this._frameBuffer.fetchBlockSize;
        this._frameBuffer.start = startFrame;
        this._frameBuffer.end = end > this.validationRecording.frames ? this.validationRecording.frames : end;
    }

    private setOptionStates(options: Array<IValidationSessionOptionModel>): void {
        if (!isNullOrUndefined(options)) {
            const optionsLength = options.length;
            for (let oi = 0; oi < optionsLength; oi++) {
                const option = options[oi];
                const element: MatButton = this.options.find(e => e._elementRef.nativeElement.id === option.name);
                if (!isNullOrUndefined(element)) {
                    element.disabled = option.locked;
                    this.setToggleOption(option.name, option.enabled);
                }
            }
        } else {
            this.options.forEach(e => e.disabled = false);
        }
    }

    private setSessionIdWhenConnected(sessionId: number, startTime: Date): Observable<boolean> {
        if (this.connectionService.isConnected === true && (this.connectionService.isOnline === true || this.connectionService.isOffline === true)) {
            this.sessionId = sessionId;
            this.startTime = startTime;

            this.onSessionIdUpdated();

            return of(true);
        } else {
            return timer(100).pipe(flatMap(() => this.setSessionIdWhenConnected(sessionId, startTime)));
        }
    }

    private getSession(session: DbValidationSessionInfoModel, syncAction?: SyncActionEnum): Observable<boolean> {
        const dialogRef = this.openPleaseWaitDialog('Session Loading');
        return this._validationService.getSession(session.creationDate, this.validationRecording, syncAction, this.getSessionProcess).pipe(
            flatMap(
                sessionInfo => {
                    if (this.isNullOrUndefined(this._validationSession) || this._validationSession.id !== sessionInfo.id) {
                        this._validationSession = sessionInfo;
                        const index = this._sessions.findIndex(i => i.id === this._validationSession.id);
                        this._sessions[index] = this._validationSession;

                        this.setOptionStates(this._validationSession.options);

                        const getData = zip(
                            this._validationService.openUserCountsSession(this._validationSession.id, this.validationRecording.recordingId),
                            this._validationService.getSessionBookmarks(this._validationSession.id, this.getSessionBookmarksProcess),
                            this._validationService.getSessionUserCounts(this._validationSession.id, this.getSessionUserCountsProcess),
                        );

                        return getData.pipe(
                            map(
                                results => {
                                    this._sessionBookmarks = new Array<BookmarkModel>(this.validatableRecording.frames);

                                    this._sessionBookmarks = results[1] as Array<BookmarkModel>;
                                    this._userCounts = results[2] as Array<CountModel>;

                                    this.timeLine.sessionBookmarks = this.sessionBookmarks;

                                    this.loadCounts(this._userCounts, 'userCounts');

                                    if (!this.isNullOrUndefined(this._validationSession.lastLocation)) {
                                        this.playService.currentLocation = this._validationSession.lastLocation;
                                    }

                                    return true;
                                }
                            )
                        );
                    } else {
                        return of(true);
                    }
                }
            ),
            tap(() => dialogRef.close())
        );
    }

    private setValidationSession(session: DbValidationSessionInfoModel, checkSyncState: boolean): Observable<boolean> {
        if (!isNullOrUndefined(session)) {
            return (checkSyncState === true ? this._validationService.getSessionSyncState(session.creationDate, this.validationRecording, this.getSessionSyncStateProcess) : of({ ok: true, bookmarkData: true, userCountData: true })).pipe(
                flatMap(
                    syncStateSessionInfo => {
                        if (syncStateSessionInfo.ok) {
                            return this.getSession(session);
                        } else {
                            this._sessionSyncResolveDialogRef = this._dialog.open(SessionSyncResolveComponent, { data: new SessionSyncResolveData(syncStateSessionInfo), maxWidth: '550px', disableClose: true });
                            return this._sessionSyncResolveDialogRef.afterClosed().pipe(
                                flatMap(
                                    (result: SessionSyncResolveResult) => {
                                        if (!this.isNullOrUndefined(result) && !this.isNullOrUndefined(result.syncAction)) {
                                            this._sessionSyncResolveDialogRef = null;
                                            return this.getSession(session, !this.isNullOrUndefined(result) ? result.syncAction : null);
                                        } else {
                                            return of(false);
                                        }
                                    }
                                )
                            );
                        }
                    }
                ),
            );
        } else {
            const dialogRef = this.openPleaseWaitDialog('Session Closing');

            return this.saveSessionState().pipe(
                flatMap(
                    () => this._validationService.closeUserCountsSession().pipe(
                            map(() => {
                                this.setOptionStates(DefaultSessionOptions);
                                this._validationSession = null;
                                this._sessionBookmarks = null;
                                this._userCounts = null;
                                this.timeLine.sessionBookmarks = null;
                                this.timeLine.rows.items.forEach(i => {
                                    i.body.userCounts.clear();
                                    i.body.userCounts.update();
                                });

                                return true;
                            })
                        )
                )
            ).pipe(
                finalize(() => dialogRef.close())
            );
        }
    }

    private showSelectValidationRecording(): void {
        if (this.isNullOrUndefined(this._selectValidationRecordingRef)) {
            this.addSubscription(this.observableHandlerBase(this.observableHandlerBase(this._activatedRoute.params, this.showSelectValidationRecordingProcess), this.showSelectValidationRecordingProcess).subscribe(params => {
                this.addSubscription(this.observableHandlerBase(this.observableHandlerBase(this._activatedRoute.queryParams, this.showSelectValidationRecordingProcess), this.showSelectValidationRecordingProcess).subscribe(queryParams => {
                    if (isNullOrUndefined(params.sessionId)) {
                        this.displaySessionSelector();
                    } else {
                        this._loadedFromParam = true;
                        this.setSessionIdWhenConnected(parseInt(params.sessionId, 10), params.startTime).subscribe();
                    }
                }), this.showSelectValidationRecordingProcess);
            }), this.showSelectValidationRecordingProcess);
        };
    }

    private displaySessionSelector(): void{
        this._selectValidationRecordingRef = this._dialog.open(SelectValidationRecordingComponent, { data: new SelectValidationRecordingData(), hasBackdrop: true, disableClose: true });
        this.addSubscription(this.observableHandlerBase(this._selectValidationRecordingRef
            .afterClosed(), this.showSelectValidationRecordingProcess).subscribe(
                (result: SelectValidationRecordingResult) => {
                    if (!this.isNullOrUndefined(result)) {
                        if (this.isNullOrUndefined(result.sessionId)) {
                            if (this.isNullOrUndefined(this.sessionId)) {
                                this._location.back();
                            }
                            else{
                                this._router.navigate(['connect']);
                            }
                        } else {
                            this._loadedFromParam = false;
                            this.sessionId = result.sessionId;
                            this.startTime = result.startTime;

                            this.onSessionIdUpdated();
                        }
                    }
                    this._selectValidationRecordingRef = null;
                }
            )
            , this.showSelectValidationRecordingProcess);
    }

    private updateSession(): Observable<boolean> {
        return this._validationService.updateSessionState(this._validationSession, this._validationRecording, this.updateSessionStateProcess).pipe(map(() => true));
    }

    private updateUserCount(updatedUserCounts: number[][], updatedRegisterIndex: number, updatedFrameNumber: number): void {
        this._userCounts = [];
        this.timeLine.getRegisterRows(updatedRegisterIndex).forEach(row => row.body.userCounts.clear());

        const length = updatedUserCounts.length;
        for (let frameNumber = 0; frameNumber < length; frameNumber++) {
            const counts = updatedUserCounts[frameNumber];
            if (!isNullOrUndefined(counts)) {
                const countModel = new CountModel();
                countModel.counts = counts;
                this._userCounts[frameNumber] = countModel;
            }
        }

        this.timeLine.getRegisterRows(updatedRegisterIndex).forEach(row => {
            row.body.userCounts.setCounts(this._userCounts.map((uc) => uc.counts[updatedRegisterIndex]));
            row.body.userCounts.update();
        });

        this.setUserCountsMenuCounts(this.playService.currentLocation.frameNumber);
    }

    private loadCounts(countsFrames: Array<CountModel>, countType: 'userCounts' | 'systemCounts'): void {
        const framesDataKeys = Object.keys(countsFrames);
        const framesDataKeysLength = framesDataKeys.length;

        if (framesDataKeysLength > 0) {
            const start = parseInt(framesDataKeys[0], 10);
            const end = parseInt(framesDataKeys[framesDataKeysLength - 1], 10);

            for (let fi = start; fi <= end; fi++) {
                const count = countsFrames[fi];

                if (!isNullOrUndefined(count)) {
                    Object.keys(count.counts).forEach(registerIndexKey => {
                        const registerIndex = parseInt(registerIndexKey, 10);
                        this.timeLine.getRegisterRows(registerIndex).forEach(row => {
                            row.body[countType].addUpdate(count.counts[registerIndex], fi);
                        });
                    });
                }
            }
        }

        this.timeLine.rows.update();
    }
}
