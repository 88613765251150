import { Injectable } from '@angular/core';
import { SafeSubscriptionBase } from '@shared/base/SafeSubscription.Base';
import { LoggingService } from '@shared/service/logging/Logging.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { isArray, isNullOrUndefined, isNumber, isString } from '@shared/utility/General.Utility';
import { StringUtility } from '@shared/utility/String.Utility';

/**
 * Monitors running processes.
 *
 * @export
 * @class ProcessMonitorService
 * @extends {SafeSubscriptionBase}
 */
@Injectable()
export class ProcessMonitorService extends SafeSubscriptionBase {
    public loggingOn = true;

    private _processes: Array<ProcessMonitorServiceProcess>;
    private _lastId: number;
    private _running: Array<ProcessMonitorServiceProcess>;

    /**
     * Creates an instance of ProcessMonitorService.
     *
     * @param {LoggingService} _logger The logging service
     * @memberof ProcessMonitorService
     */
    public constructor(
        private readonly _logger: LoggingService
    ) {
        super();

        this._running = [];
        this._processes = [];
        this._lastId = 0;
    }

    /**
     * Adds an error to a process.
     *
     * @param {(number | ProcessMonitorServiceProcess)} idOrProcess The process name or an istance of a process.
     * @param {(string | Array<string>)} errorMessages The error or erros to add to the process.
     * @param {Error} [error] The error.
     * @memberof ProcessMonitorService
     */
    public error(idOrProcess: number | ProcessMonitorServiceProcess, errorMessages: string | Array<string>, error?: Error): void {
        let process: ProcessMonitorServiceProcess;

        if (isNumber(idOrProcess)) {
            process = this.getRunningProcess(idOrProcess as number);
        } else {
            process = idOrProcess as ProcessMonitorServiceProcess;
        }

        if (!isNullOrUndefined(process)) {
            if (!isNullOrUndefined(error)) {
                process.lastError = error;
            }
            process.hasError = true;

            this.removeRunning(process);

            if (isArray(errorMessages)) {
                process.errorMessages.push(...(errorMessages as Array<string>));
            } else {
                process.errorMessages.push((errorMessages as string));
            }

            this._logger.errorProcess(
                process.inName,
                process,
                'Process Id:{0} Name:{1}',
                error,
                StringUtility.toString(process.id),
                process.name
            );
        }
    }

    /**
     * Completes the process.
     *
     * @param {(number | ProcessMonitorServiceProcess)} idOrProcess The process name or an istance of a process.
     * @memberof ProcessMonitorService
     */
    public completed(idOrProcess: number | ProcessMonitorServiceProcess): void {
        let process: ProcessMonitorServiceProcess = null;

        if (isNumber(idOrProcess)) {
            process = this.getRunningProcess(idOrProcess);
        } else {
            process = idOrProcess as ProcessMonitorServiceProcess;
        }

        if (!isNullOrUndefined(process)) {
            this.removeRunning(process);
            this._logger.debugProcess(
                ProcessMonitorService.name,
                process,
                'Process completed Id:{0}',
                StringUtility.toString(process.id)
            );
        }
    }

    /**
     * Gets a process.
     *
     * @param {string} inName The name of the class object the process is running in.
     * @param {string} name The name of the process.
     * @param {string} [key] The process key.
     * @returns {ProcessMonitorServiceProcess}  The process.
     * @memberof ProcessMonitorService
     */
    public getProcess(inName: string, name: string, key?: string): ProcessMonitorServiceProcess {
        let process: ProcessMonitorServiceProcess = null;

        if (!isNullOrUndefined(key)) {
            process = this._processes.find(p => p.name === name && p.key === key);
        }

        if (isNullOrUndefined(process)) {
            const id = this.getNextId();
            process = new ProcessMonitorServiceProcess(inName, id, name, key);
            process.processMonitorService = this;

            if (!isNullOrUndefined(key)) {
                this._processes.push(process);
            }
        }

        return process;
    }

    /**
     * Gets all the runnig processes.
     *
     * @readonly
     * @type {Array<ProcessMonitorServiceProcess>}
     * @memberof ProcessMonitorService
     */
    public get running(): Array<ProcessMonitorServiceProcess> {
        return this._running;
    }

    /**
     * Starts a process monitor.
     *
     * @param {(string | ProcessMonitorServiceProcess)} idOrProcess The process name or an istance of a process.
     * @returns {ProcessMonitorServiceProcess} The process.
     * @memberof ProcessMonitorService
     */
    public started(idOrProcess: string | ProcessMonitorServiceProcess, inName?: string): ProcessMonitorServiceProcess {
        let process: ProcessMonitorServiceProcess;

        if (isString(idOrProcess)) {
            process = this.getProcess(inName, idOrProcess as string);
        } else {
            process = idOrProcess as ProcessMonitorServiceProcess;
        }

        this.addRunning(process);
        this._logger.debugProcess(
            ProcessMonitorService.name,
            process,
            'Process started for Id:{0} Name:{1}',
            StringUtility.toString(process.id),
            process.name
        );

        return process;
    }

    /**
     * Terminates the process.
     *
     * @param {(number | ProcessMonitorServiceProcess)} idOrProcess The process name or an istance of a process.
     * @memberof ProcessMonitorService
     */
    public terminate(idOrProcess: number | ProcessMonitorServiceProcess): void {
        let process: ProcessMonitorServiceProcess = null;

        if (isNumber(idOrProcess)) {
            process = this.getRunningProcess(idOrProcess);
        } else {
            process = idOrProcess as ProcessMonitorServiceProcess;
        }

        if (!isNullOrUndefined(process)) {
            this.removeRunning(process);
            this._logger.debugProcess(
                ProcessMonitorService.name,
                process,
                'Process terminated Id:{0}',
                StringUtility.toString(process.id)
            );
        }

        process.completed();
    }

    /**
     * Updates process state.
     *
     * @param {(number | ProcessMonitorServiceProcess)} idOrProcess The process name or an istance of a process.
     * @param {string} status The status to update to.
     * @memberof ProcessMonitorService
     */
    public updateStatus(idOrProcess: number | ProcessMonitorServiceProcess, status: string): void {
        let process: ProcessMonitorServiceProcess;

        if (isNumber(idOrProcess)) {
            process = this.getRunningProcess(idOrProcess as number);
        } else {
            process = idOrProcess as ProcessMonitorServiceProcess;
        }

        if (!isNullOrUndefined(process)) {
            process.status = status;
            this._logger.debugProcess(
                ProcessMonitorService.name,
                process,
                'Process status updated Id:{0} Name:{1} Status:{2}',
                StringUtility.toString(process.id),
                process.name,
                status
            );
        }
    }

    /**
     * Updates the progress of a process.
     *
     * @param {(number | ProcessMonitorServiceProcess)} idOrProcess The process name or an istance of a process.
     * @param {number} percentage The complete percentage.
     * @memberof ProcessMonitorService
     */
    public reportProgress(idOrProcess: number | ProcessMonitorServiceProcess, percentage: number): void {
        let process: ProcessMonitorServiceProcess;

        if (isNumber(idOrProcess)) {
            process = this.getRunningProcess(idOrProcess as number);
        } else {
            process = idOrProcess as ProcessMonitorServiceProcess;
        }

        if (!isNullOrUndefined(process)) {
            process.percentage = percentage;
            this._logger.debugProcess(
                ProcessMonitorService.name,
                process,
                'Process progress updated Id:{0} Name:{1} Percentage:{2}',
                StringUtility.toString(process.id),
                process.name,
                StringUtility.toString(percentage)
            );
        }
    }

    private addRunning(process: ProcessMonitorServiceProcess): void {
        if (!isNullOrUndefined(process)) {
            process.isRunning = true;
            if (!this._running.some(i => i.id === process.id)) {
                this._running.push(process);
            }
        }
    }

    private removeRunning(process: ProcessMonitorServiceProcess): void {
        if (!isNullOrUndefined(process)) {
            process.isRunning = false;
            const index: number = this.getRunningProcessIndex(process.id);
            if (index !== -1) {
                this._running.splice(index, 1);
            }
        }
    }

    /**
     * Gets a running process.
     *
     * @private
     * @param {number} id The process id.
     * @returns {ProcessMonitorServiceProcess} The process.
     * @memberof ProcessMonitorService
     */
    private getRunningProcess(id: number): ProcessMonitorServiceProcess {
        return this._running.find(p => p.id === id);
    }

    /**
     * Gets the index of a process.
     *
     * @private
     * @param {number} id The process id.
     * @returns {number} The index.
     * @memberof ProcessMonitorService
     */
    private getRunningProcessIndex(id: number): number {
        return this._running.findIndex(p => p.id === id);
    }

    /**
     * Gets the next free process id.
     *
     * @private
     * @returns {number} The process id.
     * @memberof ProcessMonitorService
     */
    private getNextId(): number {
        this._lastId++;
        return this._lastId;
    }
}
