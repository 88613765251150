import { BaseModel } from '@shared/base/Base.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface IValidationSyncFrameEntryModel {
    serial: string;
    frameNumber: number;
}

export class ValidationSyncFrameEntryModel extends BaseModel implements IValidationSyncFrameEntryModel, IWebSocketModel {
    public readonly isIWebSocketModel: boolean = true;
    public packetType: string = null;
    public id: number = null;

    public serial: string = null;
    public frameNumber: number = null;

    public constructor() {
        super();
    }

    public loadFromWebSocketMessage(message: any): void {
        WebSocketModelUtility.loadProperties(message, this, 'RIFT-WS-ValidationSyncFrameEntryModel', [], true);
    }

    public toWebSocketMessage(): void {
        throw new Error('Method not implemented.');
    }
}
