import { Injectable, ComponentRef } from '@angular/core';
import { EmBaseService } from '@em/service/base/EmBase.Service';
import { RestApiDeviceService } from '@em/service/restapi/RestApi.Device.Service';
import { ProcessMonitorService } from '@shared/service/processmonitor/ProcessMonitor.Service';
import { UserNotificationService } from '@shared/service/usernotification/UserNotification.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { BottomInfoFileDownloadComponent } from '@shared/component/bottominfo/file-download/BottomInfo.FileDownload.Component';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import * as FileSaver from 'file-saver';
import { FileUtility } from '@shared/utility/File.Utility';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export interface IVideoDownload {
    friendlySerial: string;
    videoEntryId: number;
    startTime: Date;
    process: ProcessMonitorServiceProcess;
    fileDownload: ComponentRef<BottomInfoFileDownloadComponent>;
    fileName: string;
    getVideoSub?: Subscription;
}

@Injectable()
export class DeviceVideoDownloadService extends EmBaseService {
    private _videoDownloads: IVideoDownload[] = [];

    public constructor(
        private readonly _userNotificationService: UserNotificationService,
        private readonly _processMonitorService: ProcessMonitorService,
        private readonly _restApiDeviceService: RestApiDeviceService) {
        super();
    }

    public startVideoDownload(friendlySerial: string, videoEntryId: number, startTime: Date): void {
        const videoDownload: IVideoDownload = {
            friendlySerial,
            videoEntryId,
            startTime,
            process: this._processMonitorService.getProcess('DeviceVideoDownloadService', 'Downloading Video'),
            fileDownload: this._userNotificationService.addComponent(BottomInfoFileDownloadComponent),
            fileName: `Video_${friendlySerial}_${DateTimeUtility.toFileNameDateTime(startTime, 'yyyy-MM-dd_HH-mm')}.zip`,
        };
        this._videoDownloads.push(videoDownload);

        videoDownload.fileDownload.instance.fileName = videoDownload.fileName;

        videoDownload.process.restApi.downloadProgressChange.subscribe(
            (downloadProgress) => {
                videoDownload.fileDownload.instance.downloadProgress = downloadProgress;
            }
        );

        videoDownload.getVideoSub = this._restApiDeviceService.getVideo(videoDownload.friendlySerial, videoDownload.videoEntryId, videoDownload.process).subscribe(
            (file) => {
                const blob = new Blob([file.data], { type: 'octet/stream' });
                FileSaver.saveAs(blob, FileUtility.sanitize(videoDownload.fileName));
                this.remove(videoDownload);
            }
        );

        videoDownload.fileDownload.instance.cancel.subscribe(() => {
            if (!isNullOrUndefined(videoDownload.getVideoSub)) {
                videoDownload.getVideoSub.unsubscribe();
                this.remove(videoDownload);
            }
        });
    }

    private remove(videoDownload: IVideoDownload): void {
        const index = this._videoDownloads.findIndex(i => i.friendlySerial === videoDownload.friendlySerial && i.videoEntryId === videoDownload.videoEntryId && i.startTime === videoDownload.startTime);
        if (index !== -1) {
            this._userNotificationService.removeComponent(videoDownload.fileDownload.instance.instanceId);
            this._videoDownloads.splice(index, 1);
        }
    }
}
