import { Injectable } from '@angular/core';
import { PathMapModel } from '@rift/models/restapi/PathMap.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftBaseService } from '@rift/service/base/RiftBase.Service';
import { RestApiPathMapService } from '@rift/service/restapi/v1/RestApi.PathMap.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { ObservableTracker } from '@shared/generic/ObservableLoading';
import { map } from 'rxjs/operators';

@Injectable()
export class PathMapService extends RiftBaseService {

    private _getPathMapLoadingTracker = new ObservableTracker<PathMapModel>();
    private _resetLoadingTracker = new ObservableTracker<ResultModel>();

    public constructor(
        private readonly _restApiPathMapService: RestApiPathMapService) {
        super();
    }

    public clearCache(): void {
        this.clearObservableTrackers();
    }

    public getPathMap(process?: ProcessMonitorServiceProcess): Observable<PathMapModel> {
        return this._getPathMapLoadingTracker
            .getLoading()
            .observable(this._restApiPathMapService.getPathMap(process));
    }

    public reset(process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this._resetLoadingTracker
            .getLoading()
            .observable(this._restApiPathMapService.reset(process));
    }
}
