import { NgZone, Directive } from '@angular/core';
import { TimeLineDurationView } from '@rift/components/shared/timeline/duration/TimeLine.Duration.View';
import { TimeLineRows } from '@rift/components/shared/timeline/rows/TimeLine.Rows';
import { ValidationPlayService } from '@rift/service/validation/Validation.Play.Service';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { IDisplayItem } from '@shared/generic/canvas/IDisplayItem';
import { ColorUtility } from '@shared/utility/Color.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { IPlayLocation } from '@rift/service/validation/IPlayLocation';
import { GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';

@Directive()
export class TimeLineDragZone extends DisplayItem {
    private _location1: IPlayLocation = null;
    private _height: number = 0;
    private _location2: IPlayLocation = null;
    private _zoneShape: createjs.Shape = new createjs.Shape();

    public constructor(
        protected readonly _durationView: TimeLineDurationView,
        protected readonly _rows: TimeLineRows,
        protected readonly _playService: ValidationPlayService,
        protected readonly _zone: NgZone) {
        super(_zone);

        this._zone.runOutsideAngular(() => {
            this.container.addChild(this._zoneShape);
            this.addEventHandlers();
        });
    }

    public bringToFront(displayItem?: IDisplayItem): void {

    }

    public get locationStart(): IPlayLocation {
        return !isNullOrUndefined(this._location1) && !isNullOrUndefined(this._location2) ? this._location1.frameNumber < this._location2.frameNumber ? this._location1 : this._location2 : null;
    }

    public get locationEnd(): IPlayLocation {
        return !isNullOrUndefined(this._location1) && !isNullOrUndefined(this._location2) ? this._location1.frameNumber > this._location2.frameNumber ? this._location1 : this._location2 : null;
    }

    public get location2(): IPlayLocation {
        return this._location2;
    }
    public set location2(value: IPlayLocation) {
        this._location2 = value;
    }

    public get location1(): IPlayLocation {
        return this._location1;
    }
    public set location1(value: IPlayLocation) {
        this._location1 = value;
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
            this.removeEventHandlers();
            this._zoneShape.uncache();

            this.container.removeAllChildren();
        });
    }

    public resize(width: number, height: number): void {
        this._height = height;
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            this.updateZoneShape();
            this.requireStageUpdate.next();
        });
    }

    private addEventHandlers(): void {
        this._zone.runOutsideAngular(() => {
        });
    }

    private removeEventHandlers(): void {
        super.onDestroy();
    }

    private updateZoneShape(): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._location2) && !isNullOrUndefined(this._location1)) {
                this._zoneShape.visible = true;

                const graphics = this._zoneShape.graphics;

                const startX = this._playService.playLocationToPixel(this.locationStart, this._durationView.bar.fullSegmentWidth);
                const endX = this._playService.playLocationToPixel(this.locationEnd, this._durationView.bar.fullSegmentWidth);

                graphics.clear();

                graphics.beginStroke('#ff0000');
                graphics.setStrokeStyle(1);
                graphics.setStrokeDash([10, 10], 0);

                graphics.moveTo(startX, 0);
                graphics.lineTo(startX, this._height);

                graphics.moveTo(endX, 0);
                graphics.lineTo(endX, this._height);

                graphics.endStroke();

                graphics.beginFill(ColorUtility.hexToRGBA('#00ff00', 0.5));
                graphics.drawRect(startX, 0, endX - startX, this._height);
                graphics.endFill();

                this._zoneShape.cache(0, 0, endX + 1, this._height, GLOBAL_CACHE_SCALE);
            } else {
                this._zoneShape.visible = false;
            }
        });
    }
}
