<div class="rift-validate-counting-network-dialog-title" mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                {{ title }}
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div class="rift-validate-counting-network-dialog-content" mat-dialog-content fxLayout="column">
    For support with this error please select download log and send to Irisys support.
</div>
<div class="rift-managesynchronizedrecordings-actions" mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onDownloadLogClicked()" >Download Log</button>
</div>
