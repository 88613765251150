import { RegisterBaseModel, REGISTER_BASE_EXCLUDE_PROPERTIES } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { ZoneTypeEnum } from '@shared/enum/ZoneType.Enum';
import { MarkedTargetEnum } from '@shared/enum/MarkedTarget.Enum';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';

export interface IRegisterZoneModel extends IRegisterBaseModel {
    polygon: number;
    zoneType: ZoneTypeEnum;
    minDwellDuration: number;
    markedTarget: MarkedTargetEnum;
    heightFilterEnabled: boolean;
    lowerBound: number;
    upperBound: number;
    maxDwellDuration: number;
    maxDwellEnabled: boolean;
}

export class RegisterZoneModel extends RegisterBaseModel implements IRegisterZoneModel, IRestModel {
    public polygon: number = null;
    public zoneType: ZoneTypeEnum = null;
    public minDwellDuration: number = null;
    public markedTarget: MarkedTargetEnum = null;
    public heightFilterEnabled: boolean = null;
    public lowerBound: number = null;
    public upperBound: number = null;
    public maxDwellDuration: number = null;
    public maxDwellEnabled: boolean = null;

    public constructor() {
        super();

        this.registerProperty('polygon');
        this.registerProperty('zoneType');
        this.registerProperty('minDwellDuration');
        this.registerProperty('markedTarget');
        this.registerProperty('heightFilterEnabled');
        this.registerProperty('lowerBound');
        this.registerProperty('upperBound');
        this.registerProperty('maxDwellDuration');
        this.registerProperty('maxDwellEnabled');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterHeightLineModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.setPropertyOriginalValue('polygon', this.polygon);
        this.setPropertyOriginalValue('zoneType', this.zoneType);
        this.setPropertyOriginalValue('minDwellDuration', this.minDwellDuration);
        this.setPropertyOriginalValue('markedTarget', this.markedTarget);
        this.setPropertyOriginalValue('heightFilterEnabled', this.heightFilterEnabled);
        this.setPropertyOriginalValue('lowerBound', this.lowerBound);
        this.setPropertyOriginalValue('upperBound', this.upperBound);
        this.setPropertyOriginalValue('maxDwellDuration', this.maxDwellDuration);
        this.setPropertyOriginalValue('maxDwellEnabled', this.maxDwellEnabled);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        // Before we save off if we are a certain zone type
        // then we need to disable max dwell time
        if(this.zoneType === ZoneTypeEnum.instantaneous){
            this.maxDwellEnabled = false;
            this.maxDwellDuration = 0;

            this.dwellHistograms.forEach(h=>{
                const index = this.histogramConfigs.findIndex(i => i.uniqueId === h.uniqueId);

                if(index !== -1){
                    this.histogramConfigs.splice(index, 1);
                }
            });

            this.dwellHistograms.splice(0, this.dwellHistograms.length);
        }

        return {
            ...super.toRestApiModel(),
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], [], [this.polygon]);
    }
}
