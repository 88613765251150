<em-navmenu-expand-item>
    <ng-template #expandItemHeaderCollapsed>
        Show Favourite Devices ({{devices.length}})
    </ng-template>
    <ng-template #expandItemHeaderExpanded>
        Hide Favourite Devices ({{devices.length}})
    </ng-template>
    <ng-template #expandItemContent>
        <ul class="devices">
            <li [shrDeviceInfoTooltip]="device.deviceInfo" class="mat-tree-node node" *ngFor="let device of devices"
                (click)="onDeviceClicked(device)">
                <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <div>
                        {{ device.serial }}
                    </div>
                    <div fxFlex></div>
                    <div class="delete">
                        <mat-icon (click)="onDeleteClicked($event, device)">delete</mat-icon>
                    </div>
                </div>
            </li>
        </ul>
    </ng-template>
</em-navmenu-expand-item>
