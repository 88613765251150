import { AfterViewInit, Component, HostBinding, HostListener, Injector, OnChanges, OnDestroy, Renderer2, SimpleChanges, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsCountingMenuBaseComponent } from '@rift/components/settings/counting/base/Settings.Counting.MenuBase.Component';
import { DiscriminationModel } from '@rift/models/restapi/Discrimination.Model';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { LocalStorage } from '@shared/decorator/WebStorage.Decorator';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ISaveAllChanges } from '@shared/interface/ISaveAllChanges';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { IPosition } from 'angular2-draggable';
import { ISize } from 'angular2-draggable/lib/models/size';
import { Observable, of, timer, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSliderChange } from '@angular/material/slider';

class Point {
    x: number;
    y: number;
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}

class MovePath {
    from: number | Point;
    value: number;

    constructor(from: number | Point, value: number) {
        this.from = from;
        this.value = value;
    }
}

class Blob {
    center: Point;
    movePaths: Array<MovePath>;

    constructor(center: Point) {
        this.center = center;
        this.movePaths = [];
    }
}

class Target {
    center: Point;
    id: number;
    movePaths: Array<MovePath>;
    blobs: Array<Blob>;
    diedAtValue: number;

    constructor(id: number, center: Point) {
        this.id = id;
        this.center = center;
        this.blobs = [new Blob(new Point(center.x, center.y))];
        this.movePaths = [];
        this.diedAtValue = null;
    }
}

class Drawing {
    canvas: HTMLCanvasElement;
    context: CanvasRenderingContext2D;
    targets: Array<Target>;

    constructor(targets: Array<Target>) {
        this.targets = targets;
    }
}

class State {
    integrityOldValue: number;
    integrity: number;
    sliderValue: number;
    sliderLastValue: number;

    constructor() {
        this.integrityOldValue = 99;
        this.integrity = 99;
        this.sliderValue = 1;
        this.sliderLastValue = 1;
    }
}

class Settings {
    range: {
        min: number;
        max: number;
        scale: number;
    };
    slider: {
        step: number;
    };
    canvas: {
        width: number;
        height: number;
        backgroundColor: string;
    };
    blob: {
        radius: number;
        innerRadius: number;
        fillColor: string;
    };
    target: {
        color: string;
        fillColor: string;
        radius: number;
    };

    constructor() {
        this.range = { min: 1, max: 99, scale: 0.5 };
        this.slider = { step: 1 };
        this.canvas = { width: 210, height: 180, backgroundColor: '#b0c5e8' };
        this.blob = { radius: 10, innerRadius: 2, fillColor: '#ff0000' };
        this.target = { color: 'rgba(0,0,0,0.5)', fillColor: 'rgba(251,180,28,0.5)', radius: 10 };
    }
}

@Component({
    selector: 'rift-settings-counting-tracking',
    templateUrl: './Settings.Counting.Tracking.Component.html',
    styleUrls: ['./Settings.Counting.Tracking.Component.scss'],
})
export class SettingsCountingTrackingComponent extends SettingsCountingMenuBaseComponent implements OnDestroy, AfterViewInit, OnChanges, ILoadDate, ISaveAllChanges {
    public static className: string = 'SettingsCountingTrackingComponent';

    @Input()
    public get bounds(): HTMLElement {
        return this._bounds;
    }
    public set bounds(value: HTMLElement) {
        this._bounds = value;
        this.checkPosition();
    }

    @Input()
    public get zIndex(): number {
        return this._zIndex;
    }
    public set zIndex(value: number) {
        this._zIndex = value;
    }

    @HostBinding()
    public id: string = 'rift-settings-counting-tracking';

    public discrimination: DiscriminationModel;
    public form: FormGroup = null;
    public settings: Settings = null;
    public state: State = null;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;

    @LocalStorage(SettingsCountingTrackingComponent.className, 'position')
    public position: IPosition;

    @LocalStorage(SettingsCountingTrackingComponent.className, 'show')
    public show: boolean;

    public size: ISize;

    private _drawing: Drawing = null;

    public constructor(
        private readonly _render: Renderer2,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_render, _injector, _dialog);

        this.minWidth = 250;

        this.setupCanvas();

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsCountingTrackingComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsCountingTrackingComponent.className, this.loadDataProcessText);
        this.saveAllChangesProcess = this.processMonitorService.getProcess(SettingsCountingTrackingComponent.className, this.saveAllChangesProcessText);

        this.form = this._formBuilder.group({
            isExtended: ['', Validators.compose([Validators.required])],
            isCoupling: ['', Validators.compose([Validators.required])],
        });

        this.addSubscription(timer(2000, 2000).subscribe(() => this.render()));

        this.addSubscription(
            this.observableHandlerBase(this.form.valueChanges, this.formValuesChangeProcess).subscribe(() => {
                this.onValuesChange();
            })
            , this.formValuesChangeProcess);

        this.initConnectionState();
    }

    public onValuesChange(): void {
        if (!this.isNullOrUndefined(this.discrimination)) {
            const noEvent = { emitEvent: false };

            const isCoupling = this.isNullOrUndefined(this.form.controls.isCoupling.value) || this.form.controls.isCoupling.value === '' ? false : this.form.controls.isCoupling.value as boolean;
            const isExtended = this.isNullOrUndefined(this.form.controls.isExtended.value) || this.form.controls.isExtended.value === '' ? false : this.form.controls.isExtended.value as boolean;

            this.discrimination.isCoupling = isCoupling;
            this.discrimination.isExtended = isExtended;

            if (isCoupling === true) {
                this.form.controls.isCoupling.enable(noEvent);
                this.form.controls.isExtended.disable(noEvent);

                this.form.controls.isExtended.setValue(false, noEvent);

                this.discrimination.isExtended = false;
            } else if (isExtended === true) {
                this.form.controls.isCoupling.disable(noEvent);
                this.form.controls.isExtended.enable(noEvent);

                this.form.controls.isCoupling.setValue(false, noEvent);

                this.discrimination.isCoupling = false;
            } else if (isCoupling === false && isExtended === false) {
                this.form.controls.isCoupling.enable(noEvent);
                this.form.controls.isExtended.enable(noEvent);

                this.form.controls.isCoupling.setValue(false, noEvent);
                this.form.controls.isExtended.setValue(false, noEvent);

                this.discrimination.isCoupling = false;
                this.discrimination.isExtended = false;
            }
        }
    }

    public setupCanvas(): void {
        this._drawing = new Drawing([
            new Target(8, new Point(85, 60)),
            new Target(7, new Point(60, 50)),
            new Target(9, new Point(35, 31)),

            new Target(1, new Point(170, 120)),
            new Target(2, new Point(160, 155)),
            new Target(3, new Point(140, 140)),

            new Target(4, new Point(200, 210)),
            new Target(5, new Point(230, 220)),
            new Target(6, new Point(200, 250))
        ]);
        this.settings = new Settings();
        this.state = new State();
    }

    public onIntegrityChange(event: MatSliderChange): void {
        this.integrityChange(this.discrimination.integrity, event.value);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.render();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isNullOrUndefined(changes.show) && !this.isNullOrUndefined(changes.show.currentValue) && changes.show.currentValue === true) {
            this.render();
        }
    }

    public reset(): void {
        this.changeTracker.clearChanges();
        this.discrimination = null;
        this._drawing.canvas = null;
        this._drawing.context = null;
        this.form.reset();
        this.changeTracker.clear();
    }

    public onSaveClick(): void {
        this.saveAllChangesStartBase(this, this.openPleaseWaitSavingDialog());
    }

    @HostListener('window:beforeunload')
    public deactivate(): Observable<boolean> {
        return this.deactivateBase(this);
    }

    public showSaveChangesWarning(): Observable<boolean> {
        return this.showSaveChangesWarningBase(this, () => {
            this.deviceService.clearCache();
            return of(true);
        });
    }

    public get hasChanges(): boolean {
        return this.hasChangesBase;
    }

    public get isValid(): boolean {
        return true;
    }

    public saveAllChanges(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const saveAllSub = zip(
            this.globalService.updateDiscrimination(this.discrimination, process).pipe(
                map(() => true)
            )
        );

        return super.saveAllChangesBase(this, saveAllSub, pleaseWaitDialogRef, process);
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.globalService.getDiscrimination(process).pipe(
                map(result => {
                    if (!this.isNullOrUndefined(result)) {
                        this.discrimination = result;
                        this.changeTracker.track(this.discrimination);
                        this.setFormValues(this.discrimination);
                        this.addSubscription(this.observableHandlerBase(timer(500), process).subscribe(() => {
                            this.render();
                            this.onValuesChange();
                        }), process);
                    }
                    return true;
                })
            ),
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    public open(): void {
        super.open();
        this.loadDataStartBase(this);
    }

    protected offline(): void {
        super.offline();
        this.loadDataStartBase(this);
    }

    protected online(): void {
        super.online();
        this.loadDataStartBase(this);
    }

    protected setReadOnly(): void {
        super.setReadOnly();
        this.form.disable();
    }

    protected setReadWrite(): void {
        super.setReadWrite();
        this.form.enable();
    }

    private integrityChange(oldValue: number, newValue: number): void {
        this.state.sliderLastValue = this.state.sliderValue;
        this.state.sliderValue = newValue;

        this.state.integrity = Math.abs(newValue - 100);
        this.state.integrityOldValue = Math.abs(oldValue - 100);

        this.discrimination.integrity = newValue;

        this.collisionDetection();
        this.render();
    }

    private setFormValues(discrimination: DiscriminationModel): void {
        this.integrityChange(99, discrimination.integrity);
        this.form.controls.isCoupling.setValue(discrimination.isCoupling, { emitEvent: false });
        this.form.controls.isExtended.setValue(discrimination.isExtended, { emitEvent: false });
    }

    private initCanvas(): boolean {
        if (this.isNullOrUndefined(this._drawing.canvas)) {
            this._drawing.canvas = (document.getElementById('integrity-canvas') as HTMLCanvasElement);
            if (!this.isNullOrUndefined(this._drawing.canvas)) {
                this._drawing.context = this._drawing.canvas.getContext('2d');
                return true;
            }
            return false;
        }
        return true;
    }

    private render(): void {
        if (this.initCanvas() === true && this.show === true) {
            this.drawClearSurface();
            this.drawBackground();
            this.drawBlobs();
            this.drawTargets();
        }
    }

    private drawClearSurface(): void {
        this._drawing.context.clearRect(0, 0, this.settings.canvas.width, this.settings.canvas.height);
    }

    private drawBackground(): void {
        this._drawing.context.fillStyle = this.settings.canvas.backgroundColor;
        this._drawing.context.fillRect(0, 0, this.settings.canvas.width, this.settings.canvas.height);
    }

    private drawBlobs(): void {
        const targetsLength = this._drawing.targets.length;
        for (let targetIndex = 0; targetIndex < targetsLength; targetIndex++) {
            const target = this._drawing.targets[targetIndex];
            const blobsLength = target.blobs.length;
            for (let blobIndex = 0; blobIndex < blobsLength; blobIndex++) {
                this.drawBlob(target.blobs[blobIndex]);
            }
        }
    }

    private drawBlob(blob: Blob): void {
        this.drawBlobBody(blob.center.x, blob.center.y);
    }

    private drawBlobBody(x: number, y: number): void {
        this._drawing.context.beginPath();

        const gradient = this._drawing.context.createRadialGradient(x, y, this.settings.blob.innerRadius, x, y, this.settings.blob.radius);
        gradient.addColorStop(0, this.settings.blob.fillColor);
        gradient.addColorStop(1, this.settings.canvas.backgroundColor);

        this._drawing.context.arc(x, y, this.settings.blob.radius, 0, Math.PI * 2, true);

        this._drawing.context.closePath();

        this._drawing.context.fillStyle = gradient;
        this._drawing.context.fill();
    }

    private drawTargets(): void {
        const targetsLength = this._drawing.targets.length;
        for (let targetIndex = 0; targetIndex < targetsLength; targetIndex++) {
            const target = this._drawing.targets[targetIndex];
            if (target.diedAtValue === null) {
                this.drawTarget(target);
            }
        }
    }

    private drawTarget(target: Target): void {
        this.drawTargetBody(target.center.x, target.center.y);
    }

    private drawTargetBody(x: number, y: number): void {
        this._drawing.context.beginPath();

        this._drawing.context.arc(x, y, this.settings.target.radius + this.getScaledTargetRadius(this.state.integrity), 0, Math.PI * 2, true);

        this._drawing.context.closePath();

        this._drawing.context.fillStyle = this.settings.target.fillColor;
        this._drawing.context.fill();

        this._drawing.context.strokeStyle = this.settings.target.color;

        this._drawing.context.stroke();


        this._drawing.context.beginPath();

        this._drawing.context.arc(x, y, 1, 0, Math.PI * 2, true);

        this._drawing.context.closePath();

        this._drawing.context.fillStyle = this.settings.target.color;
        this._drawing.context.fill();
    }

    private collisionDetection(): void {
        if (this.state.integrity < this.state.integrityOldValue) {
            this.reversCollisions();
        }

        if (this.state.integrity > this.state.integrityOldValue) {
            this.detectCollisions();
        }

        this.state.integrityOldValue = this.state.integrity;
    }

    private detectCollisions(): void {
        for (let integrity = this.state.integrityOldValue; integrity <= this.state.integrity; integrity++) {
            for (let targetAIndex = this._drawing.targets.length - 1; targetAIndex > -1; targetAIndex--) {
                const targetA: Target = this._drawing.targets[targetAIndex];

                if (targetA.diedAtValue === null) {
                    if (targetA.blobs.length > 0) {
                        for (let targetBIndex = this._drawing.targets.length - 1; targetBIndex > -1; targetBIndex--) {
                            const targetB: Target = this._drawing.targets[targetBIndex];

                            if (targetB.diedAtValue === null) {
                                if (targetA.id !== targetB.id) {
                                    if (targetB.blobs.length === 1) {
                                        let pushedBlob = false;

                                        for (let blobIndex = targetB.blobs.length - 1; blobIndex > -1; blobIndex--) {
                                            const targetBBlob = targetB.blobs[blobIndex];

                                            const distance = this.getDistance(targetA, targetBBlob);
                                            if (distance < this.getScaledTargetRadius(integrity) + this.settings.target.radius) {
                                                targetBBlob.movePaths.push(new MovePath(targetB.id, integrity));

                                                targetA.blobs.push(targetBBlob);
                                                targetB.blobs.pop();

                                                if (targetB.blobs.length <= 0) {
                                                    targetB.diedAtValue = integrity;
                                                }

                                                pushedBlob = true;
                                            }
                                        }

                                        if (targetA.blobs.length > 1 && pushedBlob === true) {
                                            const collidedPoints = [];
                                            const blobsLength = targetA.blobs.length;
                                            for (let blobAIndex = 0; blobAIndex < blobsLength; blobAIndex++) {
                                                collidedPoints.push(targetA.blobs[blobAIndex].center);
                                            }

                                            const centerPoint = this.getCenterPoint(collidedPoints);

                                            targetA.movePaths.push(new MovePath(new Point(targetA.center.x, targetA.center.y), integrity));
                                            targetA.center.x = centerPoint.x;
                                            targetA.center.y = centerPoint.y;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private reversCollisions(): void {
        for (let integrity = this.state.integrityOldValue; integrity >= this.state.integrity; integrity--) {

            for (let targetIndex = this._drawing.targets.length - 1; targetIndex > -1; targetIndex--) {
                const target = this._drawing.targets[targetIndex];

                if (target.diedAtValue === integrity) {
                    target.diedAtValue = null;
                }
                let movePathIndex = 0;
                for (movePathIndex = target.movePaths.length - 1; movePathIndex > -1; movePathIndex--) {
                    const targetMovePath = target.movePaths[movePathIndex];

                    if (targetMovePath.value === integrity) {
                        target.center.x = (targetMovePath.from as Point).x;
                        target.center.y = (targetMovePath.from as Point).y;

                        target.movePaths.pop();
                    }
                }

                for (let blobIndex = target.blobs.length - 1; blobIndex > -1; blobIndex--) {
                    const blob = target.blobs[blobIndex];

                    movePathIndex = 0;
                    for (movePathIndex = blob.movePaths.length - 1; movePathIndex > -1; movePathIndex--) {
                        const blobMovePath = blob.movePaths[movePathIndex];

                        if (blobMovePath.value === integrity) {
                            for (let blobToTargetIndex = this._drawing.targets.length - 1; blobToTargetIndex > -1; blobToTargetIndex--) {
                                const blobToTarget = this._drawing.targets[blobToTargetIndex];

                                if (blobToTarget.id === blobMovePath.from) {
                                    blobToTarget.blobs.push(blob);
                                    target.blobs.pop();
                                }
                            }

                            blob.movePaths.pop();
                        }
                    }
                }
            }
        }
    }

    private getDistance(a: Target | Blob, b: Target | Blob): number {
        return Math.sqrt((b.center.x - a.center.x) * (b.center.x - a.center.x) + (b.center.y - a.center.y) * (b.center.y - a.center.y));
    }

    private getCenterPoint(points: Array<Point>): Point {
        let totalX = 0;
        let totalY = 0;

        const pointsLength = points.length;
        for (let i = 0; i < pointsLength; i++) {
            const p = points[i];
            totalX += p.x;
            totalY += p.y;
        }

        return new Point(totalX / points.length, totalY / points.length);
    }

    private getScaledTargetRadius(radius: number): number {
        return radius * this.settings.range.scale;
    }
}
