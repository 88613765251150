<nav class="spacing-bottom"
    mat-tab-nav-bar
    fxLayout="row"
    fxLayoutAlign="center center">
    <a mat-tab-link
        routerLink="/settings/options/dataculling"
        routerLinkActive
        #dataculling="routerLinkActive"
        [active]="dataculling.isActive">
        Data culling
    </a>
    <a mat-tab-link
        routerLink="/settings/options/video"
        routerLinkActive
        #video="routerLinkActive"
        [active]="video.isActive">
        Video
    </a>
    <a mat-tab-link
        routerLink="/settings/options/tasklauncher"
        routerLinkActive
        #tasklauncher="routerLinkActive"
        [active]="tasklauncher.isActive">
        Task Launcher
    </a>
    <a mat-tab-link
        routerLink="/settings/options/notifications"
        routerLinkActive
        #notifications="routerLinkActive"
        [active]="notifications.isActive">
        Notifications
    </a>
    <a mat-tab-link
        routerLink="/settings/options/tempstore"
        routerLinkActive
        #tempStore="routerLinkActive"
        [active]="tempStore.isActive">
        Shared Temporary Storage
    </a>
</nav>
<router-outlet></router-outlet>