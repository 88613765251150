import { Component, Inject, Injector, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaskDescriptionModel } from '@em/models/restapi/TaskDescription.Model';
import { TaskService } from '@em/service/data/task/Task.Service';
import { BaseComponent } from '@shared/base/Base.Component';
import { PleaseWaitDialogComponent } from '@shared/component/dialog/pleasewait/PleaseWait.Dialog.Component';
import { ILoadDate } from '@shared/interface/ILoadData';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OkCancelDialogData, OkCancelDialogComponent, OkCancelDialogResult } from '@shared/component/dialog/okcancel/OkCancel.Dialog.Component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export class SettingsTaskLauncherDeleteDialogData {
    public constructor() { }
}

@Component({
    selector: 'em-settings-task-launcher-delete',
    templateUrl: './Settings.TaskLauncher.Delete.Component.html',
    styleUrls: ['./Settings.TaskLauncher.Delete.Component.scss']
})
export class SettingsTaskLauncherDeleteComponent extends BaseComponent implements ILoadDate {
    public static className: string = 'SettingsTaskLauncherDeleteComponent';

    @HostBinding()
    public id: string = 'em-settings-task-launcher-delete';

    public getGroupProcess: ProcessMonitorServiceProcess;
    public deleteTaskProcess: ProcessMonitorServiceProcess;
    public formValuesChangeProcess: ProcessMonitorServiceProcess;
    public deleteTaskFormGroup: FormGroup;
    public packageList: Array<TaskDescriptionModel>;
    public selectedPackage: TaskDescriptionModel;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _taskService: TaskService,
        private readonly _formBuilder: FormBuilder,
        private readonly _dialogRef: MatDialogRef<SettingsTaskLauncherDeleteComponent>,
        private readonly _injector: Injector) {
        super(_injector, _dialog);

        this._dialogRef.disableClose = true;

        this.formValuesChangeProcess = this.processMonitorService.getProcess(SettingsTaskLauncherDeleteComponent.className, 'Form values change');
        this.loadDataProcess = this.processMonitorService.getProcess(SettingsTaskLauncherDeleteComponent.className, this.loadDataProcessText);
        this.deleteTaskProcess = this.processMonitorService.getProcess(SettingsTaskLauncherDeleteComponent.className, 'Delete Task Package.');

        this.deleteTaskFormGroup = this._formBuilder.group({
            package: ['', Validators.compose([Validators.required])]
        });

        this.addSubscription(this.observableHandlerBase(this.deleteTaskFormGroup.valueChanges, this.formValuesChangeProcess).subscribe(() => this.update()), this.formValuesChangeProcess);

        this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
    }

    public cancel(): void {
        this._dialogRef.close();
    }

    public delete(): void {
        const data = new OkCancelDialogData('Delete Task Package', null, true);
        data.messageHtml = 'Are you sure you want to delete this task package?';
        this.addSubscription(this._dialog.open(OkCancelDialogComponent, { data, maxWidth: 450 }).afterClosed().subscribe(
            (resultDialog: OkCancelDialogResult) => {
                if (!this.isNullOrUndefined(resultDialog)) {
                    if (resultDialog.ok) {
                        // Lets delete that package
                        this.addSubscription(this.observableHandlerBase(this._taskService.deleteTaskPackage(this.selectedPackage, this.deleteTaskProcess), this.deleteTaskProcess).subscribe(
                            (resultDelete) => {
                                if (!this.isNullOrUndefined(resultDelete) && resultDelete) {
                                    // All went well
                                    // Refresh the display
                                    this._taskService.clearCache();
                                    this.selectedPackage = null;
                                    this.loadDataStartBase(this, this.openPleaseWaitLoadingDialog());
                                }
                                else {
                                    // something failed
                                    // Need an error dialog
                                }
                            }
                        ), this.deleteTaskProcess);
                    }
                }
            }));
    }

    public loadData(pleaseWaitDialogRef?: MatDialogRef<PleaseWaitDialogComponent>, process?: ProcessMonitorServiceProcess): Observable<boolean> {
        const loadDataSub = zip(
            this.getTaskPackageList(process)
        );

        return this.loadDataBase(this, loadDataSub, pleaseWaitDialogRef, process);
    }

    private update() {
        const formData = this.deleteTaskFormGroup.value;

        this.selectedPackage = formData.package;
    }

    private getTaskPackageList(process?: ProcessMonitorServiceProcess): Observable<boolean> {
        return this._taskService.getTaskPackageList(process).pipe(
            map(result => {
                if (!this.isNullOrUndefined(result)) {
                    this.packageList = result;
                }

                return true;
            })
        );
    }
}
