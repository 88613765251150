import { ActivatedRoute, Params, Router, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET, UrlSegment } from '@angular/router';
import { HttpProtocols } from '@shared/types/HttpProtocols';
import { isNullOrUndefined } from '@shared/utility/General.Utility';

export class UrlUtility {
    public static getRelativeUrl(paths: Array<string>, router: Router, activatedRoute: ActivatedRoute, remove?: Array<string>, queryParams?: Params): string {
        if (paths.every(i => !isNullOrUndefined(i))) {
            let url = router.createUrlTree(paths, { relativeTo: activatedRoute, queryParams }).toString();

            if (!isNullOrUndefined(remove)) {
                remove.forEach(r => url = url.replace(`/${r}`, ''));
            }

            return url;
        }
    }

    public static getURLArray(router: Router, skip: number = 0): string[] {
        const tree: UrlTree = router.parseUrl(router.routerState.snapshot.url);
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g.segments;

        const paths = [];
        const length = s.length;
        for (let i = skip; i < length; i++) {
            paths.push(s[i].path);
        }

        return paths;
    }

    public static getAbsolutePort(): number {
        const port = parseInt(location.port, 10);
        if (Number.isNaN(port)) {
            return location.protocol === 'https:' ? 443 : 80;
        } else {
            return port;
        }
    }

    public static getPort(): number {
        const port = parseInt(location.port, 10);
        if (Number.isNaN(port)) {
            return null;
        } else {
            return port;
        }
    }

    public static getProtocol(): HttpProtocols {
        return location.protocol === 'https:' ? 'secure' : 'unsecure';
    }

    public static getHostname(): string {
        return location.hostname;
    }
}
