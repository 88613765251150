import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageModel } from '@em/models/restapi/Page.Model';
import { PaginationOptionsModel } from '@em/models/restapi/PaginationOptions.Model';
import { VideoEntryModel } from '@em/models/restapi/VideoEntry.Model';
import { VideoEntryCountsModel } from '@em/models/restapi/VideoEntryCounts.Model';
import { VideoEntryFilterModel } from '@em/models/restapi/VideoEntryFilter.Model';
import { VideoStateModel } from '@em/models/restapi/VideoState.Model';
import { EmRestApiService } from '@em/service/restapi/base/EmRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { StringUtility } from '@shared/utility/String.Utility';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class RestApiGlobalRecordingsService extends EmRestApiService {
    private _controller = 'globalrecordings/';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.emRestApiBase, _httpClient);
        this.throwOnError = false;
    }

    public cancelSyncVideoSession(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this.post<VideoEntryModel, null>(`${this._controller}cancelsyncvideosession`, item, 'null', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public deleteVideoSchedule(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this.deleteWithBody(`${this._controller}videoschedule`, item, null, null, process);
    }

    public deleteVideoSession(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this.deleteWithBody(`${this._controller}videosession`, item, null, null, process);
    }

    public deleteVideoSyncedSession(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this.deleteWithBody(`${this._controller}videosyncedsession`, item, null, null, process);
    }

    public getCounts(process?: ProcessMonitorServiceProcess): Observable<VideoEntryCountsModel> {
        return this.get<VideoEntryCountsModel>(`${this._controller}counts`, VideoEntryCountsModel, null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getFirstVideoFrame(videoDataEntryId: number, process?: ProcessMonitorServiceProcess): Observable<VideoEntryModel> {
        let params = new HttpParams();
        params = params.append('videoDataEntryID', StringUtility.toString(videoDataEntryId));

        return this.get<VideoEntryModel>(`${this._controller}firstvideoframe`, VideoEntryModel, params, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getVideoSchedules(showLocal: boolean, showOnDevice: boolean, showSyncing: boolean, paginationOptions?: PaginationOptionsModel, process?: ProcessMonitorServiceProcess): Observable<PageModel<VideoEntryModel>> {
        const filter = new VideoEntryFilterModel();
        filter.showLocal = showLocal;
        filter.showOnDevice = showOnDevice;
        filter.showSyncing = showSyncing;

        let params = new HttpParams();
        params = params.append('sortDirection', '0');
        params = this.setPaginationOptionsParams(paginationOptions, params);

        const url = `${this._config.emRestApiBase}${this._controller}videoschedules`;
        const req = new HttpRequest('POST', url, filter.toRestApiModel(), {
            responseType: 'json',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return (this.request<VideoEntryModel, PageModel<VideoEntryModel>>(req, VideoEntryModel, PageModel, process, false) as Observable<PageModel<VideoEntryModel>>).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getVideoSessionStates(videoDataEntryIDs: Array<number>, process?: ProcessMonitorServiceProcess): Observable<Array<VideoStateModel>> {
        const url = `${this._config.emRestApiBase}${this._controller}videosessionstates`;

        const req = new HttpRequest('POST', url, videoDataEntryIDs, {
            responseType: 'json',
            params: null,
            reportProgress: true,
            withCredentials: true
        });

        return (this.request<VideoStateModel, null>(req, VideoStateModel, null, process, true) as Observable<Array<VideoStateModel>>).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getVideoSessions(showLocal: boolean, showOnDevice: boolean, showSyncing: boolean, paginationOptions?: PaginationOptionsModel, process?: ProcessMonitorServiceProcess): Observable<PageModel<VideoEntryModel>> {
        const filter = new VideoEntryFilterModel();
        filter.showLocal = showLocal;
        filter.showOnDevice = showOnDevice;
        filter.showSyncing = showSyncing;

        let params = new HttpParams();
        params = params.append('sortDirection', '0');
        params = this.setPaginationOptionsParams(paginationOptions, params);

        const url = `${this._config.emRestApiBase}${this._controller}videosessions`;
        const req = new HttpRequest('POST', url, filter.toRestApiModel(), {
            responseType: 'json',
            params,
            reportProgress: true,
            withCredentials: true
        });

        return (this.request<VideoEntryModel, PageModel<VideoEntryModel>>(req, VideoEntryModel, PageModel, process, false) as Observable<PageModel<VideoEntryModel>>).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public pauseSyncVideoSession(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this.post<VideoEntryModel, null>(`${this._controller}pausesyncvideosession`, item, 'null', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public resumeSyncVideoSession(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this.post<VideoEntryModel, null>(`${this._controller}resumesyncvideosession`, item, 'null', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public syncVideoSession(item: VideoEntryModel, process?: ProcessMonitorServiceProcess): Observable<null> {
        return this.post<VideoEntryModel, null>(`${this._controller}syncvideosession`, item, 'null', null, process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
