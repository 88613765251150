<div class="em-settings-counterservicesettings">
</div>
<div class="em-settings-counterservicesettings-title"
    mat-dialog-title>
    Counter Service Settings
</div>
<form [formGroup]="settingsFormGroup">
    <div class="em-settings-counterservicesettings-content"
        mat-dialog-content
        fxLayout="column">
        <mat-form-field>
            <mat-select #address
                formControlName="address"
                placeholder="Address">
                <mat-option *ngFor="let address of addresses | orderBy: 'address'"
                    [value]="address">
                    {{address}}
                </mat-option>
            </mat-select>
            <span matSuffix>{{ settings?.counterServerAddress?.propertyHasChangesText('value') }}</span>
        </mat-form-field>
        <mat-form-field>
            <input type="number"
                #port
                matInput
                formControlName="port"
                placeholder="Port">
            <span matSuffix>{{ settings?.counterServerPort?.propertyHasChangesText('value') }}</span>
            <mat-error>{{getFormControlErrorMessage(settingsFormGroup.controls['port'])}}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <input type="text"
                #slnatAddress
                matInput
                formControlName="slnatAddress"
                placeholder="SLNAT Address">
            <span matSuffix>{{ settings?.sLNATAddress?.propertyHasChangesText('value') }}</span>
            <mat-error>{{getFormControlErrorMessage(settingsFormGroup.controls['slnatAddress'])}}</mat-error>
        </mat-form-field>
        <mat-checkbox #slnatOn
            formControlName="slnatOn"
            class="spacing-bottom">SLNAT Address On{{ settings?.sLNATAddressOn?.propertyHasChangesText('value') }}</mat-checkbox>
        <mat-form-field>
            <input type="number"
                #startPort
                matInput
                formControlName="startPort"
                placeholder="Start Port">
            <span matSuffix>{{ settings?.toolServerStartPort?.propertyHasChangesText('value') }}</span>
            <mat-error>{{getFormControlErrorMessage(settingsFormGroup.controls['startPort'])}}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <input type="number"
                #endPort
                matInput
                formControlName="endPort"
                placeholder="End Port">
            <span matSuffix>{{ settings?.toolServerEndPort?.propertyHasChangesText('value') }}</span>
            <mat-error>{{getFormControlErrorMessage(settingsFormGroup.controls['endPort'])}}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <input type="number"
                #commTimeout
                matInput
                formControlName="commTimeout"
                placeholder="Comms Timeout">
            <span matSuffix>{{ settings?.commsTimeout?.propertyHasChangesText('value') }}</span>
            <mat-error>{{getFormControlErrorMessage(settingsFormGroup.controls['commTimeout'])}}</mat-error>
        </mat-form-field>
    </div>
    <div class="em-myaccount-editdetails-actions"
        mat-dialog-actions>
        <shr-progress-button-spinner [raised]="true"
            [diameter]="30"
            text="Save"
            mode="indeterminate"
            [active]="saveProcess.isRunning === true"
            [disabled]="!settingsFormGroup.valid || settingsTracker.hasChanges === false"
            (shrClick)="save()"
            color="primary"></shr-progress-button-spinner>
        <div fxFlex></div>
        <button [disabled]="saveProcess.isRunning === true"
            (click)="cancel()"
            mat-raised-button
            color="warn">Cancel</button>
    </div>
</form>