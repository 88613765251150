import { NgZone } from '@angular/core';
import { DisplayItemCollection } from '@shared/generic/canvas/DisplayItemCollection';
import { Polygon } from '@rift/components/shared/viewport/polygons/Polygon';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';

export class PolygonCollection extends DisplayItemCollection<Polygon> {
    public constructor(
        protected readonly _zone: NgZone,
        protected readonly _loadQueue: ViewPortLoadQueueService) {
        super(_zone);
        Object.setPrototypeOf(this, Object.create(PolygonCollection.prototype));
    }

    public bringToFront(displayItem?: Polygon): void {
        this._zone.runOutsideAngular(() => {
            this.forEach(i => this.container.setChildIndex(i.container, this.container.children.length - 1));
            this.requireStageUpdate.next();
        });
    }

    public onDestroy(): void {
        this._zone.runOutsideAngular(() => {
            super.onDestroy();
        });
    }

    public push(...polygons: Array<Polygon>): number {
        return this._zone.runOutsideAngular(() => {
            const length = polygons.length;
            for (let index = 0; index < length; index++) {
                const polygon = polygons[index];
                this.initItem(polygon);
                super.push(polygon);
            }

            return this.length;
        });
    }

    public update(): void {
        this._zone.runOutsideAngular(() => {
            const length = this.length;
            for (let index = 0; index < length; index++) {
                this[index].update();
            }
        });
    }

    protected initItem(polygon: Polygon): void {
        this._zone.runOutsideAngular(() => {
            super.initItem(polygon);
            polygon.loadPoints();
        });
    }
}
