import { ITargetModel, TargetModel } from '@rift/models/websocket/Target.Model';
import { IWebSocketModel } from '@shared/interface/IWebSocketModel';
import { UniqueIdUtility } from '@shared/utility/UniqueId.Utility';
import { WebSocketModelUtility } from '@shared/utility/WebSocketModel.Utility';

export interface ITargetCollectionModel {
    items: Array<ITargetModel>;
}

export class TargetCollectionModel extends Array<TargetModel> implements ITargetCollectionModel, IWebSocketModel {
    public static readonly expectedPacketType: string = 'target_case';

    public readonly isIWebSocketModel: boolean = true;
    public packetType: string = null;
    public id: number = null;

    public items: Array<TargetModel> = null;

    private _uniqueId: number;

    public constructor() {
        super();
        Object.setPrototypeOf(this, Object.create(TargetCollectionModel.prototype));
        this._uniqueId = UniqueIdUtility.nextId;
    }

    public get uniqueId(): number {
        return this._uniqueId;
    }

    public loadFromWebSocketMessage(message: any): void {
        this.items = WebSocketModelUtility.loadFromArray(message.targets, TargetModel, false);
    }

    public toWebSocketMessage(): void {
        throw new Error('Method not implemented.');
    }
}
