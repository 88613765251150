import { NgZone, Directive } from '@angular/core';
import { DisplayItem } from '@shared/generic/canvas/DisplayItem';
import { Grid } from '@rift/components/shared/viewport/grid/Grid';
import { TargetLocation } from '@rift/components/shared/viewport/targets/TargetLocation';
import { TargetLocationChangeEvent, TargetLocationCollection } from '@rift/components/shared/viewport/targets/TargetLocationCollection';
import { ViewPortHelpers } from '@rift/components/shared/viewport/ViewPort.Helpers';
import { TargetModel } from '@rift/models/websocket/Target.Model';
import { UnitsOfMeasurementService } from '@rift/service/unitsofmeasurement/UnitsOfMeasurement.Service';
import { ViewPortLoadQueueService } from '@rift/service/viewport/ViewPort.LoadQueue.Service';
import { UnitOfMeasurementEnum } from '@shared/enum/UnitOfMeasurement.Enum';
import { UnitsOfMeasurementEnum } from '@shared/enum/UnitsOfMeasurement.Enum';
import { ColorUtility } from '@shared/utility/Color.Utility';
import { ImageUtility } from '@shared/utility/Image.Utility';
import { PointUtility } from '@shared/utility/Point.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { ArrayUtility } from '@shared/utility/Array.Utility';
import { TargetStatusEnum } from '@shared/enum/TargetStatus.Enum';
import { GLOBAL_CACHE_SCALE } from '@rift/shared/Settings';

@Directive()
export class Target extends DisplayItem {
    private _locations: TargetLocationCollection = null;
    private _targetBitmap: createjs.Bitmap = new createjs.Bitmap(ImageUtility.BlankImagePath);
    private _heightText: createjs.Text = new createjs.Text('', '18px Arial', '#ffffff');
    private _heightBackground: createjs.Shape = new createjs.Shape();

    private _tailShape: createjs.Shape = new createjs.Shape();
    private _tailGraphic: createjs.Graphics = null;
    private _tailMaxLength: number = 30;
    private _pathShape: createjs.Shape = new createjs.Shape();
    private _pathGraphic: createjs.Graphics = null;
    private _pathMaxLength: number = 30;
    private _statusChanged: boolean = true;
    private _lastFrame: number = 0;
    private _firstFrame: number = 0;
    private _targetModel: TargetModel;
    private _markedTargetsEnabled: boolean = false;

    public constructor(
        private readonly _zone: NgZone,
        private readonly _unitsOfMeasurementService: UnitsOfMeasurementService,
        private readonly _isLive: boolean,
        private readonly _loadQueue: ViewPortLoadQueueService,
        private readonly _targetModelBase: TargetModel,
        private readonly _frameNumber: number,
        protected readonly _heightTextVisible: boolean = true,
        protected readonly _tailsVisible: boolean = true,
        protected readonly _pathsVisible: boolean = true) {
        super(_zone);

        this._zoneBase.runOutsideAngular(() => {
            this._targetModel = _targetModelBase;

            if (this._loadQueue.isLoaded === true) {
                this.onLoadQueueComplete();
            } else {
                this.addSubscription(this._loadQueue.loaded.subscribe(() => this.onLoadQueueComplete()));
            }

            this._locations = new TargetLocationCollection(this._zone, this._isLive);

            this._targetBitmap.scaleX = this._targetBitmap.scaleY = 0.5;

            this.container.addChild(this._tailShape);
            this.container.addChild(this._pathShape);
            this.container.addChild(this._targetBitmap);
            this.container.addChild(this._heightBackground);
            this.container.addChild(this._heightText);

            if (this.isPersistent === true) {
                this.container.visible = false;
            }

            this._locations.push(new TargetLocation(ViewPortHelpers.devicePointToViewPortPoint({ x: this.targetModel.x, y: this.targetModel.y }), this.targetModel.height, this._frameNumber, this.targetModel.status));

            this.addEventHandlers();

            this.container.regX = 12.5;
            this.container.regY = 12.5;

            this._tailGraphic = this._tailShape.graphics;
            this._tailShape.x = 12.5;
            this._tailShape.y = 12.5;

            this._pathGraphic = this._pathShape.graphics;
            this._pathShape.x = 12.5;
            this._pathShape.y = 12.5;

            this.heightTextVisible = this._heightTextVisible;
            this.tailsVisible = this._tailsVisible;
            this.pathsVisible = this._pathsVisible;
        });
    }

    public get heightTextVisible(): boolean {
        return this._heightText.visible;
    }

    public set heightTextVisible(value: boolean) {
        this._heightText.visible = value;
    }

    public get tailsVisible(): boolean {
        return this._tailShape.visible;
    }

    public set tailsVisible(value: boolean) {
        this._tailShape.visible = value;
    }

    public get pathsVisible(): boolean {
        return this._pathShape.visible;
    }

    public set pathsVisible(value: boolean) {
        this._pathShape.visible = value;
    }

    public get firstFrame(): number {
        return this._firstFrame;
    }

    public set firstFrame(value: number) {
        this._firstFrame = value;
    }

    public get lastFrame(): number {
        return this._lastFrame;
    }
    public set lastFrame(value: number) {
        this._lastFrame = value;
    }

    public set markedTargetsEnabled(value: boolean) {
        this._markedTargetsEnabled = value;
    }

    public get isLive(): boolean {
        return this._zoneBase.runOutsideAngular(() => this._isLive);
    }

    public get isPersistent(): boolean {
        return this._zoneBase.runOutsideAngular(() => this._isLive === null ? null : this._isLive === false);
    }

    public get locations(): TargetLocationCollection {
        return this._zoneBase.runOutsideAngular(() => this._locations);
    }

    public get targetModel(): TargetModel {
        return this._zoneBase.runOutsideAngular(() => this._targetModel);
    }

    public set targetModel(value: TargetModel) {
        this._zoneBase.runOutsideAngular(() => {
            this._targetModel = value;;
        });
    }

    public onDestroy(): void {
        this._zoneBase.runOutsideAngular(() => {
            super.onDestroy();

            this.container.removeAllChildren();

            this._tailShape.uncache();
            this._heightBackground.uncache();
            this._pathShape.uncache();
            this._targetBitmap.uncache();

            this._tailShape = null;
            this._heightBackground = null;
            this._pathShape = null;
            this._targetBitmap = null;
        });
    }

    public update(): void {
        this._zoneBase.runOutsideAngular(() => {
            const location = this._locations.currentLocation;
            if (!isNullOrUndefined(location)) {
                this.container.y = this._locations.currentLocation.point.y;
                this.container.x = this._locations.currentLocation.point.x;
            }
        });
    }

    public setPersistentFrameNumber(frameNumber: number): void {
        if (frameNumber >= this.firstFrame && frameNumber <= this.lastFrame) {
            this.container.visible = true;
            const index = ArrayUtility.indexOfClosestValue(this.locations, 'frame', frameNumber);
            if (index > -1) {
                this.locations.setCurrentLocation(this.locations[index], index);
            }
        } else {
            this.container.visible = false;
        }
    }

    public bringToFront(): void {
    }

    protected addEventHandlers(): void {
        this._zoneBase.runOutsideAngular(() => {
            this.addSubscription(this._locations.currentLocationChange.subscribe(i => this.onCurrentLocationChange(i)));
        });
    }

    protected onLoadQueueComplete(): void {
        this._zoneBase.runOutsideAngular(() => {
            if (this._markedTargetsEnabled) {
                this._loadQueue.getTarget(this._targetModel.status).subscribe((mTargetImg) => {
                    this._targetBitmap.image = mTargetImg;
                });
            }
            else {
                const status = this._targetModel.status;

                if (status[status.length - 1] === TargetStatusEnum.marked) {
                    status[status.length - 1] = TargetStatusEnum.valid;
                }

                this._loadQueue.getTarget(status).subscribe((targetImg) => {
                    if(!isNullOrUndefined(this._targetBitmap)){
                        this._targetBitmap.image = targetImg;
                    }
                });
            }
        });
    }

    private onCurrentLocationChange(event: TargetLocationChangeEvent): void {
        this._zone.runOutsideAngular(() => {
            if (event.location.status !== this.targetModel.status && this._loadQueue.isLoaded === true) {
                if (this._targetModel.status !== event.location.status) {
                    this._targetModel.status = event.location.status;
                    this._statusChanged = true;
                }
                this.updateTargetBitmap();
            }
            this.updateHeightText(event.location, event.index);
            this.updateTail(event.location, event.index);
            this.updatePath(event.location, event.index);
            this.update();
        });
    }

    private updateTargetBitmap(): void {
        this._zone.runOutsideAngular(() => {
            if (this._statusChanged === true) {
                if (this._markedTargetsEnabled) {
                    const targetImg = this._loadQueue.getTarget(this._targetModel.status).subscribe((mTargetImg) => {
                        this._targetBitmap.image = mTargetImg;
                    });
                }
                else {
                    const status = this._targetModel.status;

                    if (status[status.length - 1] === TargetStatusEnum.marked) {
                        status[status.length - 1] = TargetStatusEnum.valid;
                    }

                    this._loadQueue.getTarget(status).subscribe((targetImg) => {
                        if(!isNullOrUndefined(this._targetBitmap)){
                            this._targetBitmap.image = targetImg;
                        }
                    });
                }

                this._statusChanged = false;
            }
        });
    }

    private updateHeightText(location: TargetLocation, index: number): void {
        this._zone.runOutsideAngular(() => {
            if (this.heightTextVisible === true) {
                let heightText: string = null;
                if (!isNullOrUndefined(location.height)) {
                    heightText = (this._unitsOfMeasurementService.units === UnitsOfMeasurementEnum.metric ? location.height.toFixed(2) : this._unitsOfMeasurementService.convertToImperial(location.height, UnitOfMeasurementEnum.meter, UnitOfMeasurementEnum.feet, 2).toFixed(2)) + this._unitsOfMeasurementService.getSuffix(UnitOfMeasurementEnum.meter, UnitOfMeasurementEnum.feet, true);
                } else {
                    heightText = '';
                }

                if (this._heightText.text !== heightText) {
                    this._heightText.text = heightText;

                    const width = this._heightText.getMeasuredWidth();

                    this._heightText.regX = width / 2;
                    this._heightText.x = 12.5;
                    this._heightText.y = -16;
                    this._heightText.cache(0, 0, width, 18, GLOBAL_CACHE_SCALE);

                    this._heightBackground.regX = width / 2;
                    this._heightBackground.x = 13.5;
                    this._heightBackground.y = -17;

                    this._heightBackground.graphics.clear();
                    this._heightBackground.graphics.beginFill('#000000');
                    this._heightBackground.graphics.drawRoundRect(0, 0, width, 18, 3);

                    this._heightBackground.cache(0, 0, width, 20, GLOBAL_CACHE_SCALE);
                }
            }
        });
    }

    private updateTail(currentLocation: TargetLocation, index: number): void {
        this._zone.runOutsideAngular(() => {
            const points: createjs.Point[] = [];

            this._tailGraphic.clear();
            this._tailGraphic.setStrokeStyle(2);
            this._tailGraphic.beginStroke(ColorUtility.hexToRGBA('#000000', 1));

            const start = index;
            const end = index - this._tailMaxLength < 0 ? 0 : index - this._tailMaxLength;

            for (let i = start; i >= end; i--) {
                const location = this.locations[i];

                const point = new createjs.Point(
                    location.point.x - (Grid.Width / 2),
                    location.point.y - (Grid.Height / 2)
                );

                if (i === start) {
                    this._tailGraphic.moveTo(point.x, point.y);
                } else {
                    this._tailGraphic.lineTo(point.x, point.y);
                }

                points.push(point);
            }

            this._tailGraphic.endStroke();

            const bounds = PointUtility.getBounds(points);
            this._tailShape.cache(bounds.x - 4, bounds.y - 4, bounds.width + 8, bounds.height + 8, GLOBAL_CACHE_SCALE);

            this._tailShape.regX = currentLocation.point.x - (Grid.Width / 2);
            this._tailShape.regY = currentLocation.point.y - (Grid.Height / 2);
        });
    }

    private updatePath(currentLocation: TargetLocation, index: number): void {
        this._zone.runOutsideAngular(() => {
            const points: createjs.Point[] = [];

            this._pathGraphic.clear();
            this._pathGraphic.setStrokeStyle(2);
            this._pathGraphic.beginStroke(ColorUtility.hexToRGBA('#0000ff', 1));

            const start = index;
            const end = this.locations.length < start + this._pathMaxLength ? this.locations.length : start + this._pathMaxLength;

            for (let i = start; i < end; i++) {
                const location = this.locations[i];

                const point = new createjs.Point(
                    location.point.x - (Grid.Width / 2),
                    location.point.y - (Grid.Height / 2)
                );

                if (i === start) {
                    this._pathGraphic.moveTo(point.x, point.y);
                } else {
                    this._pathGraphic.lineTo(point.x, point.y);
                }

                points.push(point);
            }

            this._pathGraphic.endStroke();

            const bounds = PointUtility.getBounds(points);
            this._pathShape.cache(bounds.x - 4, bounds.y - 4, bounds.width + 8, bounds.height + 8, GLOBAL_CACHE_SCALE);

            this._pathShape.regX = currentLocation.point.x - (Grid.Width / 2);
            this._pathShape.regY = currentLocation.point.y - (Grid.Height / 2);
        });
    }
}
