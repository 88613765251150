import { IInputSourceModel, InputSourceModel } from '@rift/models/restapi/InputSource.Model';
import { IRegisterBaseModel } from '@rift/models/restapi/IRegisterBase.Model';
import { REGISTER_BASE_EXCLUDE_PROPERTIES, RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { IRestModel } from '@shared/interface/IRestModel';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';

export interface IRegisterRemoteValueModel extends IRegisterBaseModel {
    remoteValueIndex: number;
    type: string;
    inputSources: IInputSourceModel[];
}

export class RegisterRemoteValueModel extends RegisterBaseModel implements IRegisterRemoteValueModel, IRestModel {
    public error: string = null;
    public statusCode: number = null;
    public remoteValueIndex: number = null;
    public type: string = null;
    public inputSources: InputSourceModel[] = null;

    public constructor() {
        super();

        this.registerProperty('inputSources');
    }

    public loadFromRestApiModel(restModel: any): void {
        RestModelUtility.loadProperties(restModel, this, 'RIFT-RegisterRemoteValueModel', ['registerType', ...REGISTER_BASE_EXCLUDE_PROPERTIES]);
        super.loadFromRestApiModel(restModel);

        this.inputSources = RestModelUtility.loadFromArray(restModel.InputSources, InputSourceModel);

        this.setPropertyOriginalValue('inputSources', this.inputSources);

        this.setIndexes();
    }

    public toRestApiModel(): any {
        return {
            ...super.toRestApiModel(),
            InputSources: RestModelUtility.toJsonArray(this.inputSources),
            Type: 'summation',
        };
    }

    public setIndexes(): void {
        super.setIndexesBase([], [], []);
    }
}
