<div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
            <div class="header">
                Please Select The Remote Devices For {{data.newRegister.registerName}}
            </div>
        </div>
        <div fxFlex></div>
    </div>
</div>
<div mat-dialog-content>
    <mat-selection-list (selectionChange)="deviceSelected($event)" #shoes>
        <mat-list-option [disabled]="remoteDevice.registersValid === false" [selected]="remoteDevice.selected"
            [value]="remoteDevice" *ngFor="let remoteDevice of remoteDevices">
            {{remoteDevice.serial}} ({{ remoteDevice.ip }})
        </mat-list-option>
    </mat-selection-list>
</div>
<div mat-dialog-actions>
    <button class="spacing-right" mat-raised-button color="primary"
        (click)="continue()">Continue</button>
    <div fxFlex></div>
</div>
