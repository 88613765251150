import { ElementRef } from '@angular/core';

/**
 * Event fired when element resizes.
 * must add directive (resized)
 *
 * @export
 * @class ResizedEvent
 */
export class ResizedEvent {
    /**
     * Creates an instance of ResizedEvent.
     *
     * @param {ElementRef} element The resized element.
     * @param {number} newWidth The new width.
     * @param {number} newHeight The new height.
     * @param {number} oldWidth The old width.
     * @param {number} oldHeight the old height.
     * @memberof ResizedEvent
     */
    public constructor(
        public readonly element: ElementRef,
        public readonly newWidth: number,
        public readonly newHeight: number,
        public readonly oldWidth: number,
        public readonly oldHeight: number
    ) { }
}
