import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';

@Injectable()
export class RestApiDisconnectService extends RiftRestApiService {
    private _controller = 'disconnect';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient);
    }

    public disconnect(connectionToken: string, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        let params = new HttpParams();
        params = params.append('token', connectionToken);

        return this.get<ResultModel>(`${this._controller}`, null, params, process, { disabled: true });
    }
}
