import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LineCollectionModel } from '@rift/models/restapi/LineCollection.Model';
import { PolygonModel } from '@rift/models/restapi/Polygon.Model';
import { PolygonCollectionModel } from '@rift/models/restapi/PolygonCollection.Model';
import { ResultModel } from '@rift/models/restapi/Result.Model';
import { ConnectionTokenService } from '@rift/service/connection/ConnectionToken.Service';
import { RiftRestApiService } from '@rift/service/restapi/base/RiftRestApi.Service';
import { ConfigurationService } from '@shared/service/configuration/Configuration.Service';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestModelUtility } from '@shared/utility/RestModel.Utility';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class RestApiPolygonsService extends RiftRestApiService {
    private _controller = 'polygons';

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _connectionTokenService: ConnectionTokenService,
        private readonly _config: ConfigurationService,
        private readonly _httpClient: HttpClient) {
        super(_dialog, _config.riftV1RestApiBase, _httpClient, _connectionTokenService);
    }

    public getPolygon(id: number, process?: ProcessMonitorServiceProcess): Observable<PolygonModel> {
        return this.get<PolygonModel>(`${this._controller}/${id.toString()}`, PolygonModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public getPolygons(process?: ProcessMonitorServiceProcess): Observable<PolygonCollectionModel> {
        return this.get<LineCollectionModel>(`${this._controller}`, LineCollectionModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updatePolygon(polygon: PolygonModel, id: number, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<PolygonModel, ResultModel>(`${this._controller}/${id.toString()}`, polygon, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }

    public updatePolygons(polygons: Array<PolygonModel>, process?: ProcessMonitorServiceProcess): Observable<ResultModel> {
        return this.put<Array<PolygonModel>, ResultModel>(`${this._controller}`, polygons, ResultModel, this.getTokenParams(), process).pipe(
            map(result => this.handleErrorOrThrow(result))
        );
    }
}
