import { IFrameStore } from '@rift/service/validation/database/syncrecording/IFrameStore';
import { IObjectStore } from '@shared/service/Indexeddb/IObjectStore';

export interface IDatabaseTimeDataFramesStore extends IFrameStore {
}

export class DatabaseTimeDataFramesStore implements IObjectStore {
    public static readonly keyPath: string = 'id';
    public static readonly recordingIdBlockIndex: string = 'RecordingIdBlockIndex';
    public static readonly recordingIdFrameNumberIndex: string = 'RecordingIdFrameNumberIndex';
    public static readonly recordingIdIndex: string = 'RecordingIdIndex';
    public static readonly storeDisplayName: string = 'Counts';
    public static readonly storeName: string = 'timedata';

    public get storeName(): string {
        return DatabaseTimeDataFramesStore.storeName;
    };

    public static toInterface(): IDatabaseTimeDataFramesStore {
        return {
            storeName: this.storeName,
            keyPath: this.keyPath,
            recordingIdFrameNumberIndex: this.recordingIdFrameNumberIndex,
            recordingIdIndex: this.recordingIdIndex,
            recordingIdBlockIndex: this.recordingIdBlockIndex,
        } as IDatabaseTimeDataFramesStore;
    }

    public createStores(db: IDBDatabase): void {
        const store: IDBObjectStore = db.createObjectStore(DatabaseTimeDataFramesStore.storeName, { keyPath: DatabaseTimeDataFramesStore.keyPath, autoIncrement: true });
        store.createIndex(DatabaseTimeDataFramesStore.recordingIdFrameNumberIndex, ['recordingId', 'frameNumber'], { unique: true });
        store.createIndex(DatabaseTimeDataFramesStore.recordingIdBlockIndex, ['recordingId', 'block']);
        store.createIndex(DatabaseTimeDataFramesStore.recordingIdIndex, 'recordingId');
    }
}
