/**
 * Schedule event types.
 *
 * @export
 * @enum {number}
 */
export enum ScheduleEventTypeEnum {
    /**
     * Represents when no event type is being used.
     */
    period = 0,

    /**
     * When a unit has connected.
     */
    unitConnected = 1,

    /**
     * When a unit disconnected.
     */
    unitDisconnected = 2,

    /**
     * When a unit rediscovered.
     */
    unitRediscovered = 3,

    /**
     * When flash settings changed on a unit.
     */
    unitFlashSettingsChanged = 4,

    /**
     * When another schedule completes.
     */
    scheduleCompleted = 5,
}
