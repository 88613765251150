import { Component, Inject, Injector, OnDestroy, OnInit, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RiftBaseComponent } from '@rift/components/base/RiftBaseComponent';
import { RelayRegisterModel } from '@rift/models/restapi/RelayRegister.Model';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class SettingsRelaysPulseWidthData {
    public relayRegister: RelayRegisterModel;

    public constructor(relayRegister: RelayRegisterModel) {
        this.relayRegister = relayRegister;
    }
}

export class SettingsRelaysPulseWidthResult {
    public ok: boolean = false;

    public pulseWidth: number;

    public constructor(ok: boolean, pulseWidth?: number) {
        this.ok = ok;
        this.pulseWidth = pulseWidth;
    }
}

@Component({
    selector: 'rift-settings-relays-pulse-width',
    templateUrl: './Settings.Relays.PulseWidth.Component.html',
    styleUrls: ['./Settings.Relays.PulseWidth.Component.scss']
})
export class SettingsRelaysPulseWidthComponent extends RiftBaseComponent implements OnInit, OnDestroy {
    public static className: string = 'SettingsRelaysPulseWidthComponent';

    @HostBinding()
    public id: string = 'rift-settings-relays-pulse-width';

    public pulseWidthFormGroup: FormGroup;

    public constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private readonly _data: SettingsRelaysPulseWidthData,
        private readonly _dialogRef: MatDialogRef<SettingsRelaysPulseWidthComponent>,
        private readonly _injector: Injector) {
        super(_injector);

        this._dialogRef.disableClose = true;

        this.pulseWidthFormGroup = this._formBuilder.group({
            pulseWidth: [this._data.relayRegister.pulseWidth, Validators.compose([Validators.required, Validators.min(0), Validators.max(1000), Validators.pattern('[0-9]*')])],
        });
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public apply(): void {
        this._dialogRef.close(new SettingsRelaysPulseWidthResult(true, this.pulseWidthFormGroup.controls.pulseWidth.value));
    }

    public cancel(): void {
        this._dialogRef.close(new SettingsRelaysPulseWidthResult(false));
    }
}
