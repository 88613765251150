import { MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, HostListener, Injector, Input, Output, HostBinding } from '@angular/core';
import { IUserCountsKeyBindings } from '@rift/components/validation/Validation.Defaults';
import { LineModel } from '@rift/models/restapi/Line.Model';
import { RegisterBaseModel } from '@rift/models/restapi/RegisterBase.Model';
import { DbValidationSessionInfoModel } from '@rift/service/validation/models/database/syncsession/IDbValidationSessionInfo.Model';
import { BaseComponent } from '@shared/base/Base.Component';
import { CountModeEnumHelpers } from '@shared/enum/CountMode.Enum';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subscription, timer } from 'rxjs';
import { ProcessMonitorServiceProcess } from '@shared/service/processmonitor/ProcessMonitor.Service.Process';

interface IValidateRegister {
    register: RegisterBaseModel;
    displayName: string;
}

@Component({
    selector: 'rift-validation-user-count-menu',
    templateUrl: './UserCountMenu.Component.html',
    styleUrls: ['./UserCountMenu.Component.scss']
})
export class UserCountMenuComponent extends BaseComponent {
    public static className: string = 'UserCountMenuComponent';

    @Output()
    public decrement: EventEmitter<RegisterBaseModel> = new EventEmitter<RegisterBaseModel>();
    @Output()
    public increment: EventEmitter<RegisterBaseModel> = new EventEmitter<RegisterBaseModel>();
    @Input()
    public keyBindings: Array<IUserCountsKeyBindings>;
    @Input()
    public disabled: boolean = false;

    @HostBinding()
    public id: string = 'rift-validation-user-count-menu';

    public pingDirection: string;
    public pingRegister: IValidateRegister;
    public pingTimerProcess: ProcessMonitorServiceProcess;
    public validateRegisters: Array<IValidateRegister>;

    private _lines: Array<LineModel>;
    private _pingTimerSub: Subscription;
    private _registers: Array<RegisterBaseModel>;
    private _validationSession: DbValidationSessionInfoModel;

    public constructor(
        private readonly _dialog: MatDialog,
        private readonly _injector: Injector) {
        super(_injector);

        this.pingTimerProcess = this.processMonitorService.getProcess(UserCountMenuComponent.className, 'Ping Timer');
    }

    public decrementClick(register: IValidateRegister): void {
        this.decrement.emit(register.register);
    }

    public incrementClick(register: IValidateRegister): void {
        this.increment.emit(register.register);
    }
    public get lines(): Array<LineModel> {
        return this._lines;
    }

    @Input()
    public set lines(value: Array<LineModel>) {
        this._lines = value;
        this.setIRegisters();
    }

    public get registers(): Array<RegisterBaseModel> {
        return this._registers;
    }

    @Input()
    public set registers(value: Array<RegisterBaseModel>) {
        this._registers = value;
        this.setIRegisters();
    }

    @HostListener('window:keydown', ['$event'])
    public keyDown(event: KeyboardEvent): void {
        if (this.disabled === false && this._dialog.openDialogs.length === 0) {
            const key = String.fromCharCode(event.keyCode).toUpperCase();
            const lengthKeyBindings = this.keyBindings.length;
            for (let i = 0; i < lengthKeyBindings; i++) {
                const keyBinding = this.keyBindings[i];
                const validateRegister = this.validateRegisters[i];
                if (!isNullOrUndefined(validateRegister)) {
                    if (keyBinding.increment === key) {
                        this.increment.emit(validateRegister.register);
                        this.pingRegister = validateRegister;
                        this.pingDirection = 'increment';
                        this.setPingTimer();
                        break;
                    } else if (keyBinding.decrement === key) {
                        this.decrement.emit(validateRegister.register);
                        this.pingRegister = validateRegister;
                        this.pingDirection = 'decrement';
                        this.setPingTimer();
                        break;
                    }
                }
            }
        }
    }

    public get validationSession(): DbValidationSessionInfoModel {
        return this._validationSession;
    }

    @Input()
    public set validationSession(value: DbValidationSessionInfoModel) {
        this._validationSession = value;
        this.setIRegisters();
    }

    private setIRegisters(): void {
        if (!isNullOrUndefined(this.lines) && !isNullOrUndefined(this.registers) && !isNullOrUndefined(this.validationSession) && !isNullOrUndefined(this.validationSession.registersToValidate)) {
            this.validateRegisters = [];
            const registersToValidateLength = this.validationSession.registersToValidate.length;
            for (let rvi = 0; rvi < registersToValidateLength; rvi++) {
                const registerIndex = this.validationSession.registersToValidate[rvi];
                const register = this.registers[registerIndex];

                if (!this.isNullOrUndefined(register)) {
                    this.validateRegisters.push({
                        register,
                        displayName: register.isLineHost === false ? register.typeDisplayName : isNullOrUndefined(register.lineIds) || register.lineIds.length === 0 ? '' : CountModeEnumHelpers.toShortName(this.lines.find(l => l.iD === register.lineIds[0]).countMode),
                    } as IValidateRegister);
                }
            }
        }
    }

    private setPingTimer(): void {
        if (!isNullOrUndefined(this._pingTimerSub)) {
            this._pingTimerSub.unsubscribe();
            this._pingTimerSub = null;
        }
        this._pingTimerSub = this.addSubscription(this.observableHandlerBase(timer(500), this.pingTimerProcess).subscribe(() => {
            this.pingRegister = null;
            this.pingDirection = null;
        }), this.pingTimerProcess);
    }
}
