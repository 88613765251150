import { Injectable, NgZone } from '@angular/core';
import { IPlayLocation } from '@rift/service/validation/IPlayLocation';
import { IValidationRecordingModel } from '@rift/service/validation/models/ValidationRecording.Model';
import { BaseService } from '@shared/base/Base.Service';
import { DateTimeUtility } from '@shared/utility/DateTime.Utility';
import { isNullOrUndefined } from '@shared/utility/General.Utility';
import { Subject } from 'rxjs';

export enum PlayStateEnum {
    playing = 0,
    paused = 1,
}

export interface ILocationChangingEventArgs {
    readonly current: IPlayLocation;
    readonly new: IPlayLocation;
}

@Injectable()
export class ValidationPlayService extends BaseService {
    public onLocationChanged: Subject<IPlayLocation> = new Subject<IPlayLocation>();
    public onLocationChanging: Subject<ILocationChangingEventArgs> = new Subject<ILocationChangingEventArgs>();
    public onPlayStateChanged: Subject<PlayStateEnum> = new Subject<PlayStateEnum>();
    public onStart: Subject<ValidationPlayService> = new Subject<ValidationPlayService>();
    public onViewUpdated: Subject<ValidationPlayService> = new Subject<ValidationPlayService>();

    public readonly minViewDuration = 500;

    private _currentLocation: IPlayLocation = { offset: 0, frameNumber: 0 };
    private _duration: number = 0;
    private _speedScale: number = 1;
    private _state: PlayStateEnum = PlayStateEnum.paused;
    private _validationRecording: IValidationRecordingModel = null;
    private _viewDuration: number = 0;
    private _viewEndLocation: IPlayLocation = { offset: 0, frameNumber: 0 };
    private _viewStartLocation: IPlayLocation = { offset: 0, frameNumber: 0 };

    public constructor(
        private readonly _zone: NgZone) {
        super();

        createjs.Ticker.on('tick', this.onTick.bind(this));
    }

    public get currentLocation(): IPlayLocation {
        return this._currentLocation;
    }

    public set currentLocation(value: IPlayLocation) {
        if (this._currentLocation !== value) {
            if (!isNullOrUndefined(value.offset) || !isNullOrUndefined(value.frameNumber)) {
                const newLocation: IPlayLocation = this.fillPlayLocation(value);
                if (this._currentLocation.frameNumber !== newLocation.frameNumber) {
                    this.onLocationChanging.next({ current: this.currentLocation, new: newLocation });
                    this._currentLocation = newLocation;
                    this.onLocationChanged.next(this._currentLocation);
                } else {
                    this._currentLocation = newLocation;
                }
            }
        }
    }

    public get duration(): number {
        return this._duration;
    }

    public get frames(): number {
        if (!isNullOrUndefined(this._validationRecording)) {
            return this._validationRecording.frames;
        }
    }

    public get startTime(): Date {
        if (!isNullOrUndefined(this._validationRecording)) {
            return this._validationRecording.startTime;
        }
    }

    public fillPlayLocation(location: IPlayLocation): IPlayLocation {
        return this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(location.offset) || !isNullOrUndefined(location.frameNumber)) {
                return {
                    offset: isNullOrUndefined(location.offset) ? this.frameToOffset(location.frameNumber) : location.offset,
                    frameNumber: isNullOrUndefined(location.frameNumber) ? this.offsetToFrame(location.offset) : location.frameNumber,
                };
            }
            return null;
        });
    }

    public get fps(): number {
        return this._validationRecording.fps;
    }

    public frameToOffset(frameNumber: number): number {
        return this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._validationRecording)) {
                return frameNumber * (1000 / this._validationRecording.fps);
            }
            return 0;
        });
    }

    public goToEnd(): void {
        this._zone.runOutsideAngular(() => {
            this.currentLocation = { offset: this.duration };
        });
    }

    public goToStart(): void {
        this._zone.runOutsideAngular(() => {
            this.currentLocation = { offset: 0 };
        });
    }

    public offsetToFrame(offset: number): number {
        return this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(this._validationRecording)) {
                return Math.round((offset / 1000) * this._validationRecording.fps);
            }
            return 0;
        });
    }

    public pause(): void {
        this._zone.runOutsideAngular(() => {
            this._state = PlayStateEnum.paused;
            createjs.Ticker.paused = true;
            this.onPlayStateChanged.next(this.playState);
        });
    }

    public pixelToPlayLocation(pixel: number, width: number): IPlayLocation {
        return this._zone.runOutsideAngular(() => {
            const pixelsPerMs = this.viewDuration / width;
            const offset = this.viewStartLocation.offset + (pixel * pixelsPerMs);
            return { offset, frameNumber: this.offsetToFrame(offset) };
        });
    }

    public play(): void {
        this._zone.runOutsideAngular(() => {
            this._state = PlayStateEnum.playing;
            createjs.Ticker.paused = false;
            this.onPlayStateChanged.next(this.playState);
        });
    }

    public playLocationToPixel(location: IPlayLocation, width: number): number {
        return this._zone.runOutsideAngular(() => {
            const fillLocation = this.fillPlayLocation(location);
            const viewOffset: number = fillLocation.offset - this.viewStartLocation.offset;
            const msPerPixel = width / this.viewDuration;
            return viewOffset * msPerPixel;
        });
    }

    public get playState(): PlayStateEnum {
        return this._state;
    }

    public stop(): void {
        this._zone.runOutsideAngular(() => {
            createjs.Ticker.paused = true;
            this._currentLocation = { offset: 0, frameNumber: 0 };
            this._duration = 0;
            this._speedScale = 1;
            this._state = PlayStateEnum.paused;
            this._validationRecording = null;
            this._viewDuration = 0;
            this._viewEndLocation = { offset: 0, frameNumber: 0 };
            this._viewStartLocation = { offset: 0, frameNumber: 0 };
        });
    }

    public setViewRange(viewStart: IPlayLocation, viewEnd: IPlayLocation): void {
        this._zone.runOutsideAngular(() => {
            if (viewStart.offset > -1 && viewEnd.offset <= this.duration) {
                this.setViewStartLocation(viewStart);
                this.setViewEndLocation(viewEnd);
                this.onViewUpdated.next(this);
            }
        });
    }

    public get speedScale(): number {
        return this._speedScale;
    }

    public set speedScale(value: number) {
        if (this._speedScale !== value) {
            this._speedScale = value;
        }
    }

    public start(validationRecording: IValidationRecordingModel): void {
        this._zone.runOutsideAngular(() => {
            this._currentLocation = { offset: 0, frameNumber: 0 };
            this._duration = 0;
            this._speedScale = 1;
            this._state = PlayStateEnum.paused;
            this._validationRecording = null;
            this._viewDuration = 0;
            this._viewEndLocation = { offset: 0, frameNumber: 0 };
            this._viewStartLocation = { offset: 0, frameNumber: 0 };

            this._validationRecording = validationRecording;
            this._duration = DateTimeUtility.toDurationMilliseconds(this._validationRecording.startTime, this._validationRecording.endTime);

            createjs.Ticker.framerate = validationRecording.fps;
            createjs.Ticker.paused = true;

            this.setViewRange({ offset: 0 }, { offset: this.duration });

            this.onStart.next(this);
        });
    }

    public stepBackward(duration: number): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(duration)) {
                if (this.currentLocation.offset - duration > 0) {
                    this.currentLocation = { offset: this.currentLocation.offset - duration };
                } else {
                    this.currentLocation = { offset: 0 };
                }
            } else {
                this.currentLocation = { offset: 0 };
            }
        });
    }

    public stepForward(duration: number): void {
        this._zone.runOutsideAngular(() => {
            if (!isNullOrUndefined(duration)) {
                if (this.currentLocation.offset + duration <= this.duration) {
                    this.currentLocation = { offset: this.currentLocation.offset + duration };
                } else {
                    this.currentLocation = { offset: this.duration };
                }
            } else {
                this.currentLocation = { offset: this.duration };
            }
        });
    }

    public togglePlayState(): void {
        this._zone.runOutsideAngular(() => {
            this.playState === PlayStateEnum.playing ? this.pause() : this.play();
        });
    }

    public get viewDuration(): number {
        return this._viewDuration;
    }

    public get viewEndLocation(): IPlayLocation {
        return this._viewEndLocation;
    }

    public get viewStartLocation(): IPlayLocation {
        return this._viewStartLocation;
    }

    private onTick(event: createjs.TickerEvent): void {
        this._zone.runOutsideAngular(() => {
            if (!event.paused) {
                const offset = this.currentLocation.offset + (this._speedScale * event.delta);
                if (offset <= this.duration) {
                    this.currentLocation = { offset };
                }
            }
        });
    }

    private setViewEndLocation(value: IPlayLocation) {
        this._zone.runOutsideAngular(() => {
            if (this._viewEndLocation !== value) {
                let location: IPlayLocation = this.fillPlayLocation(value);
                if (!isNullOrUndefined(location)) {
                    if (location.offset > this.duration) {
                        location = { offset: this.duration, frameNumber: this.offsetToFrame(this.duration) };
                    }

                    if (location.offset - this.viewStartLocation.offset > this.minViewDuration) {
                        const newOffset = location.offset;
                        const oldOffset = this._viewEndLocation.offset;

                        this._viewEndLocation = location;

                        if (newOffset > oldOffset) {
                            this._viewDuration += (newOffset - oldOffset);
                        } else if (newOffset < oldOffset) {
                            this._viewDuration -= (oldOffset - newOffset);
                        }
                    }
                }
            }
        });
    }

    private setViewStartLocation(value: IPlayLocation) {
        this._zone.runOutsideAngular(() => {
            if (this._viewStartLocation !== value) {
                let location: IPlayLocation = this.fillPlayLocation(value);
                if (!isNullOrUndefined(location)) {

                    if (location.frameNumber < 0 || location.offset < 0) {
                        location = { offset: 0, frameNumber: 0 };
                    }

                    if (this.viewEndLocation.offset - location.offset > this.minViewDuration) {
                        const newOffset = location.offset;
                        const oldOffset = this._viewStartLocation.offset;

                        this._viewStartLocation = location;

                        if (newOffset > oldOffset) {
                            this._viewDuration -= (newOffset - oldOffset);
                        } else if (newOffset < oldOffset) {
                            this._viewDuration += (oldOffset - newOffset);
                        }
                    }
                }
            }
        });
    }
}
